import FileUploader from "@amodv/react-file-uploader";
import React, { useEffect, useState } from "react";
import { FileModel, FileUploadModel, FileUploadTypeModel } from "../../../models/FileUploadModel";
import { initUploadDocuments } from "../../../models/startup/StartupModel";
import { UploadDocumentsModel } from "../../../models/startup/UploadDocumentsModel";
import { requestType, StartupUploadTypeEnum, StepperStartupEnum } from "../../../utils/Enums";
import { convertToByteArray, downloadDocument } from "../../../utils/CommonMethods";
import { useErrorStateValue } from "../../../providers/ErrorProvider";
import ValidationMessage from "../../common/controls/ValidationMessage";
import { EVALUATION_FILE, STARTUP_FILE_REMOVE, STARTUP_FILE_UPLOAD, NEW_EVALUATION_FILE, NODE_URL } from "../../../constants/api";
import { get, post } from "../../../effects/api/api.common";
import { Link, useHistory } from "react-router-dom";
import error from '../../../images/error.svg';
// import fileDownload from 'js-file-download';
import sample from '../../../images/banner-image.svg';
import ConfirmationDialog from '../../common/controls/ConfirmationDialog';
import { getSessionStorageValue } from "../../../effects/utils/session-storage";
import pdf from "../../../images/PDF.svg";
import xls from "../../../images/xls.svg";
import doc from "../../../images/doc.svg";
import Allround from "../../../images/Allround.svg";
interface IUploadDocumentProps {
    stepper: any;
    uploadDocumentsModel: UploadDocumentsModel;
    tabIndex: number;
    onStepSave: (uploadDocuments: UploadDocumentsModel, stepIndex: number, request: number) => void;
    onChildUpdate: (uploadDocuments: UploadDocumentsModel) => void;
    onPreviousClick: () => void;
    errors: Array<string>;
    applicationId: string;
    apiCalled: boolean;
    disabledForm: any
}

interface IUploadDocumentState { }

interface IIKeyValueIndexer {
    [key: string]: boolean;
}

/** Render start-up application screen */
function UploadDocumentsComponent(props: IUploadDocumentProps) {

    const {
        stepper,
        onStepSave,
        onChildUpdate,
        uploadDocumentsModel,
        applicationId,
        apiCalled,
        disabledForm
    } = props;

    // @ts-ignore
    const [{ errors }, dispatchError] = useErrorStateValue();

    const [uploadDocuments, setUploadDocuments] = useState<UploadDocumentsModel>(
        initUploadDocuments
    );

    const [openUploadBlock, setOpenUploadBlock] = useState<IIKeyValueIndexer>();
    const [loader, setLoader] = useState(false);
    const [PPTPath, setPPTPath] = useState('');
    const [agreeTermsBlock, setAgreeTermsBlock] = useState(false);
    const [pitchFile, setPitchFile] = useState('');
    const [exceedFileSize, setExceedFileSize] = useState(false)
    useEffect(() => {
        setUploadDocuments(uploadDocumentsModel);


    }, [apiCalled, applicationId]);
    useEffect(() => {
        getExcelFilePath();
    }, [])
    const getExcelFilePath = () => {
        get(NEW_EVALUATION_FILE).then((response: any) => {
            // console.log(response.data)
            setPitchFile(response.data.Sample_Pitch_Deck)
            if (!response.data) return;
            //   setPPTPath(response.data.Sample_Pitch_Deck);
        });
    }
    // triggers when user clicks on either 'save' or 'submit'
    const onSave = (submitType: string) => {
        let updatedUploadDocuments = uploadDocuments as UploadDocumentsModel;

        if (submitType === "submit") {
            updatedUploadDocuments.action = "submit";
        }
        onStepSave(updatedUploadDocuments, StepperStartupEnum.UploadDocuments, submitType === "submit" ? requestType.submit : requestType.save);
    };

    // triggers whenever file(s) are uploaded
    // const onFileUploadComplete = (files: Array<File>, field: string) => {
    //     let updatedUploadDocuments: any = uploadDocuments;

    //     let updatedOtherDetail = Object.assign({}, uploadDocuments);
    //     for (const file of files) {
    //         // if file already exists bypass
    //         if (
    //             updatedUploadDocuments[field].filter(
    //                 (fileModel: FileModel) => fileModel.file_name === file.name
    //             ).length === 0
    //         ) {
    //             const formData = new FormData();
    //             formData.append("file", file, file.name);
    //             formData.append("application_id", applicationId);
    //             formData.append("file_type", field);
    //             post(STARTUP_FILE_UPLOAD, formData).then((response: any) => {
    //                 if (!response.data) return;

    //                 switch (field) {
    //                     case StartupUploadTypeEnum.UploadPitchDeck:
    //                         convertToByteArray(file).then((buffer: any) => {
    //                             updatedUploadDocuments = {
    //                                 ...updatedUploadDocuments,
    //                                 [field]: [
    //                                     ...updatedUploadDocuments[field],
    //                                     new FileModel(response.data.result.file_id, file.name, buffer, "", file.type),
    //                                 ],
    //                             };
    //                             setLocalStateAndUpdateParent(updatedUploadDocuments);
    //                         });

    //                         break;
    //                         case StartupUploadTypeEnum.UploadVideoForProduct:
    //                             convertToByteArray(file).then((buffer: any) => {
    //                                 updatedUploadDocuments = {
    //                                     ...updatedUploadDocuments,
    //                                     [field]: [
    //                                         ...updatedUploadDocuments[field],
    //                                         new FileModel(response.data.result.file_id, file.name, buffer, "", file.type),
    //                                     ],
    //                                 };
    //                                 setLocalStateAndUpdateParent(updatedUploadDocuments);
    //                             });

    //                             break;
    //                             case StartupUploadTypeEnum.UploadRelaventDocumentOther:
    //                                 convertToByteArray(file).then((buffer: any) => {
    //                                     updatedUploadDocuments = {
    //                                         ...updatedUploadDocuments,
    //                                         [field]: [
    //                                             ...updatedUploadDocuments[field],
    //                                             new FileModel(response.data.result.file_id, file.name, buffer, "", file.type),
    //                                         ],
    //                                     };
    //                                     setLocalStateAndUpdateParent(updatedUploadDocuments);
    //                                 });

    //                                 break;
    //                 }

    //                 // de-allocate references.
    //                 formData.delete("file");
    //                 formData.delete("application_id");
    //                 formData.delete("file_type");

    //             });
    //         }


    //     }
    // };

    // const downloadDocument = () => {
    //     use the js-file-download package to download the file
    //     fileDownload(require('../../../files/Sample_Pitch_Deck.pdf'), 'Sample Pitch Deck.pdf');
    //   };
    const history = useHistory();
    const [popUpName, setPopUpName] = useState("");
    const setMeetingModal = (fieldName: string) => {
        setPopUpName(fieldName);
    };
    const applicationPage = () => {
        history.push({
            pathname: "/startup_status",
        });
    }
    // let  files1:any = (files.length < 1 && type =='upload_pitch_deck')?files[0]:files
    // console.log(files1,"files1")

    const onFileUploadComplete = (files: Array<File>, type: string) => {
        // console.log("sri@@@@")
        let indexes: Array<string> = [];
        let updatedUploadDocuments = Object.assign({}, uploadDocuments);


        for (const file of files) {
            // if already upladed then skip
            // const fileData = uploadDocuments as any;
            let updatedFileData: Array<FileUploadTypeModel> = [];
            // const fileExists = fileData[type].findIndex(
            //     (uf: FileUploadTypeModel) => uf.file.name === file.name && uf.file_type == type
            // );
            const user: any = getSessionStorageValue("user");
            const userInfo = JSON.parse(user)
            // if (fileExists) continue;
            const formData = new FormData();
            // Update the formData object
            formData.append("file", file);
            formData.append("application_id", applicationId.toString());
            formData.append("file_type", type);
            formData.append("logged_user_id", userInfo.id)
            // let updatedOtherDetail = JSON.parse(JSON.stringify(otherDetail));


            //   console.log("updatedotherdetail2", updatedOtherDetail)s

            setLoader(true);

            post(STARTUP_FILE_UPLOAD, formData).then((response: any) => {
                setLoader(false);

                if (!response.data) return;
                // let updatedOtherDetail = JSON.parse(JSON.stringify(otherDetail))


                if (response.data.result.message == "Only single file should be uploaded for pitch deck." || response.data.result.message == "File already exists.") {

                    setFailDialogData({
                        "header": "Oops",
                        "text": response.data.result.message
                    })
                    setShowIncubatorFailureModal1(true);
                    return;
                } else {
                    switch (type) {
                        case StartupUploadTypeEnum.UploadPitchDeck:

                            updatedFileData = [
                                ...updatedUploadDocuments.upload_pitch_deck.map((uf: any): FileUploadTypeModel => { return new FileUploadTypeModel(uf.file_id, uf.file_type, uf.file) }),
                                new FileUploadTypeModel(response.data.result.file_id, StartupUploadTypeEnum.UploadPitchDeck, file)
                            ];
                            updatedUploadDocuments.upload_pitch_deck = updatedFileData
                            // updatedOtherDetail.upload_pitch_deck = updatedFileData;
                            // setOtherDetail(updatedOtherDetail);
                            setUploadDocuments(prevState => {
                                const updatedFileOther = {
                                    ...prevState,
                                    relavent_document_excel: [
                                        ...prevState.upload_pitch_deck.map((uf: any): FileUploadTypeModel => { return new FileUploadTypeModel(uf.file_id, uf.file_type, uf.file) }),
                                        new FileUploadTypeModel(response.data.result.file_id, StartupUploadTypeEnum.UploadPitchDeck, file)
                                    ]
                                }
                                return updatedFileOther;
                            });
                            setLocalStateAndUpdateParent(updatedUploadDocuments);

                            break;

                        case StartupUploadTypeEnum.UploadVideoForProduct:
                            updatedFileData = [
                                ...updatedUploadDocuments.upload_video_for_product.map((uf: any): FileUploadTypeModel => { return new FileUploadTypeModel(uf.file_id, uf.file_type, uf.file) }),
                                new FileUploadTypeModel(response.data.result.file_id, StartupUploadTypeEnum.UploadVideoForProduct, file)
                            ];
                            updatedUploadDocuments.upload_video_for_product = updatedFileData
                            setUploadDocuments(prevState => {
                                const updatedFileOther = {
                                    ...prevState,
                                    relavent_document_excel: [
                                        ...prevState.upload_video_for_product.map((uf: any): FileUploadTypeModel => { return new FileUploadTypeModel(uf.file_id, uf.file_type, uf.file) }),
                                        new FileUploadTypeModel(response.data.result.file_id, StartupUploadTypeEnum.UploadVideoForProduct, file)
                                    ]
                                }
                                return updatedFileOther;
                            });
                            setLocalStateAndUpdateParent(updatedUploadDocuments);

                            break;

                        case StartupUploadTypeEnum.UploadRelaventDocumentOther:
                            updatedFileData = [
                                ...updatedUploadDocuments.upload_relavent_document_other.map((uf: any): FileUploadTypeModel => { return new FileUploadTypeModel(uf.file_id, uf.file_type, uf.file) }),
                                new FileUploadTypeModel(response.data.result.file_id, StartupUploadTypeEnum.UploadRelaventDocumentOther, file)
                            ];
                            updatedUploadDocuments.upload_relavent_document_other = updatedFileData
                            // updatedOtherDetail.upload_relavent_document_other = updatedFileData;
                            // setOtherDetail(updatedOtherDetail);
                            setUploadDocuments(prevState => {
                                const updatedFileOther = {
                                    ...prevState,
                                    relavent_document_excel: [
                                        ...prevState.upload_relavent_document_other.map((uf: any): FileUploadTypeModel => { return new FileUploadTypeModel(uf.file_id, uf.file_type, uf.file) }),
                                        new FileUploadTypeModel(response.data.result.file_id, StartupUploadTypeEnum.UploadRelaventDocumentOther, file)
                                    ]
                                }
                                return updatedFileOther;
                            });
                            setLocalStateAndUpdateParent(updatedUploadDocuments);

                            break;


                        default:
                            setLocalStateAndUpdateParent(updatedUploadDocuments);
                            break;
                    }

                    // onStepSave(
                    //   StepperEnum.OtherDetail,
                    //   updatedOtherDetail,
                    //   requestType.propertyChange
                    // );


                    // de-allocate references.
                    formData.delete("file");
                    formData.delete("application_id");
                    formData.delete("file_type");

                    // setPdfOnly({ ...pdfOnly, [type]: false });
                    setLoader(false);
                }
            });
        }

        if (indexes.length) {
            indexes.map((ind) => {
                const position: number = files.findIndex((file) => file.name === ind);
                files.splice(position, 1);
            });
        }
    };

    const onFileUploadCompleteRelevent = (files: Array<File>, type: string) => {
        // console.log("testing", files)
        let indexes: Array<string> = [];
        let updatedUploadDocuments = Object.assign({}, uploadDocuments);

        if (files.length > 10) {
            setShowIncubatorFailureModal(true);
            return;
        } else if (files.length <= 10) {
            for (const file of files) {
                // if already upladed then skip
                // const fileData = uploadDocuments as any;
                let updatedFileData: Array<FileUploadTypeModel> = [];
                // const fileExists = fileData[type].findIndex(
                //     (uf: FileUploadTypeModel) => uf.file.name === file.name && uf.file_type == type
                // );

                // if (fileExists > -1) continue;
                // console.log(file,"file123")
                const formData = new FormData();
                // console.log(applicationId,"applicationID")
                const user: any = getSessionStorageValue("user");
                const userInfo = JSON.parse(user)
                // Update the formData object
                formData.append("file", file);
                formData.append("application_id", applicationId.toString());
                formData.append("file_type", type);
                formData.append("logged_user_id", userInfo.id)
                // let updatedOtherDetail = JSON.parse(JSON.stringify(otherDetail));


                //   console.log("updatedotherdetail2", updatedOtherDetail)

                setLoader(true);

                post(STARTUP_FILE_UPLOAD, formData).then((response: any) => {
                    setLoader(false);

                    if (!response.data) return;
                    // let updatedOtherDetail = JSON.parse(JSON.stringify(otherDetail))
                    if (response.data.result.message == "File already exists.") {

                        // setFailDialogData({
                        //     "header":"Oops",
                        //     "text":response.data.result.message     
                        //   })
                        //   setShowIncubatorFailureModal1(true);  
                        //   return;

                    } else {

                        switch (type) {
                            case StartupUploadTypeEnum.UploadPitchDeck:
                                updatedFileData = [
                                    ...updatedUploadDocuments.upload_pitch_deck.map((uf: any): FileUploadTypeModel => { return new FileUploadTypeModel(uf.file_id, uf.file_type, uf.file) }),
                                    new FileUploadTypeModel(response.data.result.file_id, StartupUploadTypeEnum.UploadPitchDeck, file)
                                ];
                                updatedUploadDocuments.upload_pitch_deck = updatedFileData
                                // updatedOtherDetail.upload_pitch_deck = updatedFileData;
                                // setOtherDetail(updatedOtherDetail);
                                setUploadDocuments(prevState => {
                                    const updatedFileOther = {
                                        ...prevState,
                                        relavent_document_excel: [
                                            ...prevState.upload_pitch_deck.map((uf: any): FileUploadTypeModel => { return new FileUploadTypeModel(uf.file_id, uf.file_type, uf.file) }),
                                            new FileUploadTypeModel(response.data.result.file_id, StartupUploadTypeEnum.UploadPitchDeck, file)
                                        ]
                                    }
                                    return updatedFileOther;
                                });
                                setLocalStateAndUpdateParent(updatedUploadDocuments);

                                break;

                            case StartupUploadTypeEnum.UploadVideoForProduct:
                                updatedFileData = [
                                    ...updatedUploadDocuments.upload_video_for_product.map((uf: any): FileUploadTypeModel => { return new FileUploadTypeModel(uf.file_id, uf.file_type, uf.file) }),
                                    new FileUploadTypeModel(response.data.result.file_id, StartupUploadTypeEnum.UploadVideoForProduct, file)
                                ];
                                updatedUploadDocuments.upload_video_for_product = updatedFileData
                                setUploadDocuments(prevState => {
                                    const updatedFileOther = {
                                        ...prevState,
                                        relavent_document_excel: [
                                            ...prevState.upload_video_for_product.map((uf: any): FileUploadTypeModel => { return new FileUploadTypeModel(uf.file_id, uf.file_type, uf.file) }),
                                            new FileUploadTypeModel(response.data.result.file_id, StartupUploadTypeEnum.UploadVideoForProduct, file)
                                        ]
                                    }
                                    return updatedFileOther;
                                });
                                setLocalStateAndUpdateParent(updatedUploadDocuments);

                                break;

                            case StartupUploadTypeEnum.UploadRelaventDocumentOther:
                                updatedFileData = [
                                    ...updatedUploadDocuments.upload_relavent_document_other.map((uf: any): FileUploadTypeModel => { return new FileUploadTypeModel(uf.file_id, uf.file_type, uf.file) }),
                                    new FileUploadTypeModel(response.data.result.file_id, StartupUploadTypeEnum.UploadRelaventDocumentOther, file)
                                ];
                                updatedUploadDocuments.upload_relavent_document_other = updatedFileData
                                // updatedOtherDetail.upload_relavent_document_other = updatedFileData;
                                // setOtherDetail(updatedOtherDetail);
                                setUploadDocuments(prevState => {
                                    const updatedFileOther = {
                                        ...prevState,
                                        relavent_document_excel: [
                                            ...prevState.upload_relavent_document_other.map((uf: any): FileUploadTypeModel => { return new FileUploadTypeModel(uf.file_id, uf.file_type, uf.file) }),
                                            new FileUploadTypeModel(response.data.result.file_id, StartupUploadTypeEnum.UploadRelaventDocumentOther, file)
                                        ]
                                    }
                                    return updatedFileOther;
                                });
                                setLocalStateAndUpdateParent(updatedUploadDocuments);

                                break;


                            default:
                                setLocalStateAndUpdateParent(updatedUploadDocuments);
                                break;
                        }

                        // onStepSave(
                        //   StepperEnum.OtherDetail,
                        //   updatedOtherDetail,
                        //   requestType.propertyChange
                        // );


                        // de-allocate references.
                        formData.delete("file");
                        formData.delete("application_id");
                        formData.delete("file_type");

                        // setPdfOnly({ ...pdfOnly, [type]: false });
                        setLoader(false);
                    }
                });
            }
        }


        if (indexes.length) {
            indexes.map((ind) => {
                const position: number = files.findIndex((file) => file.name === ind);
                files.splice(position, 1);
            });
        }
    };

    const [showIncubatorFailureModal, setShowIncubatorFailureModal] = useState(false);
    const [showIncubatorFailureModal1, setShowIncubatorFailureModal1] =
        useState(false);
    const [failDialogData, setFailDialogData] = useState({
        header: '',
        text: '',
    });
    const dismissModal = () => {
        setShowIncubatorFailureModal(false);
        setExceedFileSize(false)
    };

    const getFileDetails = (file: any, key: string) => {
        let file_name = '';
        file_name =
            file != undefined && file != null && file.length > 1
                ? file.substr(file.lastIndexOf('/') + 1, file.length)
                : '';

        const file_type = file != undefined && file != null && file.length > 1
            ? file.substr(
                file.lastIndexOf('.') + 1,
                file.length
            ) : '';

        if (key == 'name') return file_name;
        if (key == 'file') return file != undefined ? file.toString() : '';
        if (key == 'type') return file != undefined ? file_type.toString() : '';
    };
    // on file delete
    // const onFileDelete = (file: FileModel, index: number, field: string) => {
    //     const updatedUploadDocuments: any = uploadDocuments;
    //     const remainingFiles = updatedUploadDocuments[field].filter(
    //         (file: FileUploadModel, idx: number) => idx !== index
    //     );

    //     updatedUploadDocuments[field] = remainingFiles;
    //     setLocalStateAndUpdateParent(updatedUploadDocuments);
    // };
    // Triggers when delete file is clicked
    const onFileDelete = (file: File, type: string) => {
        console.log(file.name, "laaaankeeee")
        const {
            upload_pitch_deck,
            upload_video_for_product,
            upload_relavent_document_other
        } = uploadDocuments;
        let remainingFiles: Array<FileUploadTypeModel> = [];
        let fileToBeDeleted: any;

        switch (type) {
            case StartupUploadTypeEnum.UploadPitchDeck:
                fileToBeDeleted = upload_pitch_deck.find(
                    (uploadedFile: FileUploadTypeModel) =>
                        getFileDetails(uploadedFile.file, 'name') === file.name
                    // console.log(uploadedFile)
                );

                if (!fileToBeDeleted) return;
                remainingFiles = upload_pitch_deck.filter(
                    (uf: FileUploadTypeModel) =>
                        uf.file !== fileToBeDeleted.file
                    // console.log(uf.file,"2222222222222222222",file,"44444444444444444",fileToBeDeleted.file)
                );

                uploadDocuments.upload_pitch_deck = remainingFiles;
                setLocalStateAndUpdateParent(uploadDocuments);
                break;

            case StartupUploadTypeEnum.UploadVideoForProduct:
                fileToBeDeleted = upload_video_for_product.find(
                    (uploadedFile: FileUploadTypeModel) => getFileDetails(uploadedFile.file, 'name') === file.name
                );

                if (!fileToBeDeleted) return;
                remainingFiles = upload_video_for_product.filter(
                    (uf: FileUploadTypeModel) => uf.file !== fileToBeDeleted.file
                );
                uploadDocuments.upload_video_for_product = remainingFiles;
                setLocalStateAndUpdateParent(uploadDocuments);
                break;

            case StartupUploadTypeEnum.UploadRelaventDocumentOther:
                fileToBeDeleted = upload_relavent_document_other.find(
                    (uploadedFile: FileUploadTypeModel) => getFileDetails(uploadedFile.file, 'name') === file.name
                );

                if (!fileToBeDeleted) return;
                remainingFiles = upload_relavent_document_other.filter(
                    (uf: FileUploadTypeModel) =>
                        // console.log(uf.file,"2222222222222222222",file,"44444444444444444",fileToBeDeleted.file)
                        uf.file !== fileToBeDeleted.file
                );
                uploadDocuments.upload_relavent_document_other = remainingFiles;
                setLocalStateAndUpdateParent(uploadDocuments);
                break;

        }
        // delete the file
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user)
        setLoader(true);
        post(STARTUP_FILE_REMOVE, {
            application_id: applicationId,
            file_id: fileToBeDeleted.file_id,
            "logged_user_id": userInfo.id
        }).then((response: any) => {
            if (response.data) {
                setLoader(false);
            }
        });
        // setOtherDetail(JSON.parse(JSON.stringify(otherDetail)));
    };

    // triggers whenever user makes any changes in the form for select and input type
    const handleChange = (
        evt: React.ChangeEvent<
            HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
        >
    ) => {
        const name = evt.target.name;
        const value = evt.target.value;

        let updatedUploadDocuments: UploadDocumentsModel = initUploadDocuments;

        updatedUploadDocuments = {
            ...uploadDocuments,
            [name]: value,
        };
        setLocalStateAndUpdateParent(updatedUploadDocuments);
    };

    // update the local storage and parent component with the updated object
    const setLocalStateAndUpdateParent = (
        updatedUploadDocuments: UploadDocumentsModel
    ) => {
        setUploadDocuments(updatedUploadDocuments);
        setTimeout(() => {
            onChildUpdate(updatedUploadDocuments);
        }, 0);
    };

    // handle all the check-box event
    const handleOnCheck = (e: any) => {
        const isChecked = e.target.checked;
        setAgreeTermsBlock(isChecked);
    };

    return (
        <>
            {loader &&
                <div className="spinner-border custom-spinner" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            }
            <fieldset disabled={loader}>
                <div className="form-content">

                    <div className="form-group required row">
                        <label className="col-sm-4 col-form-label">
                            Upload Pitch deck of your Business Idea
                        </label>
                        <div className="col-sm-8">
                            <div className="form-row">
                                <div className="col">

                                    <div className="d-inline-flex upload-pitch">
                                        <span className="link-orange-text link-underline"
                                        // onClick={() =>
                                        //     downloadDocument1(PPTPath, 'pitch_deck')
                                        // }
                                        >
                                            {/* <u>Download Sample Pitch Deck</u> */}

                                            <a style={{ color: '#e26640' }}
                                                href={`${NODE_URL}` + pitchFile}
                                            >
                                                <u>Download Sample Pitch Deck</u>
                                            </a>
                                        </span>
                                    </div>

                                    <div className="">
                                        <fieldset disabled={disabledForm}>
                                            <button
                                                className="btn btn-sisfs btn-transparent-primary"
                                                onClick={() =>
                                                    setOpenUploadBlock({ RelevantUpload: true })
                                                }
                                            >
                                                Upload
                                            </button>

                                            {((openUploadBlock &&
                                                openUploadBlock["RelevantUpload"] &&
                                                uploadDocuments.upload_pitch_deck.length === 0) ||
                                                uploadDocuments.upload_pitch_deck.length > 0) && (
                                                    <>
                                                        <span
                                                            className="close"
                                                            onClick={() =>
                                                                setOpenUploadBlock({ RelevantUpload: false })
                                                            }
                                                        >
                                                            <i className="fa fa-close"></i>
                                                        </span>
                                                        {/* <FileUploader
                                                    key={StartupUploadTypeEnum.UploadPitchDeck}
                                                    id={StartupUploadTypeEnum.UploadPitchDeck}
                                                    // key="UploadPitchDeck"
                                                    // id="UploadPitchDeck"
                                                    information="Drag & Drop the file or Browse"
                                                    fileType="application/pdf"
                                                    buttonLabel="Browse"
                                                    multiple={true}
                                                    enablePreview
                                                    IconComponent={() => (
                                                        <span className="fa fa-cloud-upload"></span>
                                                    )}
                                                    removeIconClass=""
                                                    existingFiles={uploadDocuments.upload_pitch_deck.map(
                                                        (uploadedFile: FileUploadModel) => uploadedFile.file
                                                    )}
                                                    onUploadFinish={(files: Array<File>) => {
                                                        onFileUploadComplete(
                                                            files,
                                                            StartupUploadTypeEnum.UploadPitchDeck
                                                        );
                                                    }}
                                                    // onFileDelete={(file: File) => { }}
                                                    onFileDelete={(deletedFile: File) =>
                                                        onFileDelete(
                                                          deletedFile,
                                                          StartupUploadTypeEnum.UploadPitchDeck
                                                        )
                                                      }
                                               
                                                /> */}

                                                        <FileUploader
                                                            key={Math.random().toString()}
                                                            id={StartupUploadTypeEnum.UploadPitchDeck}
                                                            information="Drag & Drop the file or Browse"
                                                            fileType="application/pdf"
                                                            buttonLabel="Browse"
                                                            multiple={false}
                                                            enablePreview
                                                            IconComponent={() => (
                                                                <span className="fa fa-cloud-upload"></span>
                                                            )}
                                                            removeIconClass=""
                                                            existingFiles={
                                                                uploadDocuments.upload_pitch_deck.filter((item) => (item.file.size) <= 15 * 1024 * 1024).map(
                                                                    (uploadedFile: FileUploadTypeModel) =>
                                                                    ({
                                                                        name: getFileDetails(uploadedFile.file, 'name') || uploadedFile.file.name,
                                                                        url: uploadedFile.file
                                                                    })
                                                                    // uploadedFile.file
                                                                )
                                                            }

                                                            onUploadFinish={(files: Array<File>) => {
                                                                for (const file of files) {
                                                                    if (file.size <= 15 * 1024 * 1024) {
                                                                        onFileUploadComplete(
                                                                            files,
                                                                            StartupUploadTypeEnum.UploadPitchDeck
                                                                        );
                                                                    } else {
                                                                        setExceedFileSize(true);
                                                                        return;
                                                                    }
                                                                }

                                                            }}
                                                            onFileDelete={(deletedFile: File) =>
                                                                onFileDelete(
                                                                    deletedFile,
                                                                    StartupUploadTypeEnum.UploadPitchDeck
                                                                )
                                                            }
                                                        />
                                                    </>
                                                )}
                                        </fieldset>

                                        {(uploadDocuments.upload_pitch_deck.length > 0 && uploadDocuments.upload_pitch_deck[0].file.size == undefined) ? (
                                            <a
                                                className="incubator-details-link"
                                                data-toggle="modal"
                                                data-target="#showMorePitchDocuments"
                                                onClick={() => setMeetingModal("Upload PitchDeck")}
                                            >
                                                View uploaded Documents
                                            </a>
                                        ) : (
                                            ""
                                        )}
                                        <small>Supported file format - PDF only  |   Max. size: 15 MB</small>
                                    </div>
                                </div>
                            </div>
                            {errors &&
                                errors.UploadDocuments &&
                                errors.UploadDocuments["upload_pitch_deck"].length > 0 && (
                                    <ValidationMessage
                                        className="error"
                                        message={errors.UploadDocuments["upload_pitch_deck"]}
                                    />
                                )}
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label">
                            Video URL showcasing your product and/or business model
                            <small className="pt-1">( Optional )</small>
                        </label>

                        <div className="col-sm-8">
                            <input
                                type="text"
                                name="video_url_for_product"
                                className={
                                    errors &&
                                        errors.UploadDocuments &&
                                        errors.UploadDocuments["video_url_for_product"].length > 0
                                        ? "form-control error"
                                        : "form-control"
                                }
                                placeholder="Enter Video URL"
                                value={uploadDocuments.video_url_for_product}
                                disabled={disabledForm}
                                onChange={handleChange}
                                required={true}
                                maxLength={255}
                            />
                            {errors &&
                                errors.UploadDocuments &&
                                errors.UploadDocuments["video_url_for_product"].length > 0 && (
                                    <ValidationMessage
                                        className="error"
                                        message={errors.UploadDocuments["video_url_for_product"]}
                                    />
                                )}
                        </div>

                    </div>

                    {/* <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                        Upload a video showcasing your product and/or business model
              <small className="pt-1">( Optional )</small>
                    </label>
                    <div className="col-sm-8">
                        <div className="form-row">
                            <div className="col">

                                <div className="">
                                    <button
                                        className="btn btn-sisfs btn-transparent-primary"
                                        onClick={() =>
                                            setOpenUploadBlock({ ProductVideoUpload: true })
                                        }
                                    >
                                        Upload
                                    </button>

                                    {((openUploadBlock &&
                                        openUploadBlock["ProductVideoUpload"] &&
                                        uploadDocuments.upload_video_for_product.length === 0) ||
                                        uploadDocuments.upload_video_for_product.length > 0) && (
                                            <>
                                                <span
                                                    className="close"
                                                    onClick={() =>
                                                        setOpenUploadBlock({ ProductVideoUpload: false })
                                                    }
                                                >
                                                    <i className="fa fa-close"></i>
                                                </span>
                                              
                                                <FileUploader
                                                    key={Math.random().toString()}
                                                    id={StartupUploadTypeEnum.UploadVideoForProduct}
                                                    information="Drag & Drop the file or Browse"
                                                    fileType="application/pdf"
                                                    buttonLabel="Browse"
                                                    multiple={false}
                                                    enablePreview
                                                    IconComponent={() => (
                                                        <span className="fa fa-cloud-upload"></span>
                                                    )}
                                                    removeIconClass=""
                                                    existingFiles={uploadDocuments.upload_video_for_product.map(
                                                        (uploadedFile: FileUploadTypeModel) => uploadedFile.file
                                                    )}
                                                    onUploadFinish={(files: Array<File>) => {
                                                        onFileUploadComplete(
                                                            files,
                                                            StartupUploadTypeEnum.UploadVideoForProduct
                                                        );
                                                    }}
                                                    onFileDelete={(deletedFile: File) =>
                                                        onFileDelete(
                                                            deletedFile,
                                                            StartupUploadTypeEnum.UploadVideoForProduct
                                                        )
                                                    }
                                                />
                                            </>
                                        )}
                                    <small>Supported file format - PDF only  |   Max. size: 15 MB</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label">
                            Upload any other relevant document which can help us decide better
                            <small className="pt-1">( Optional )</small>
                        </label>
                        <div className="col-sm-8">
                            <div className="form-row">
                                <div className="col">

                                    <div className="">
                                        <fieldset disabled={disabledForm}>
                                            <button
                                                className="btn btn-sisfs btn-transparent-primary"
                                                onClick={() =>
                                                    setOpenUploadBlock({ RelevantOtherUpload: true })
                                                }
                                            >
                                                Upload
                                            </button>

                                            {((openUploadBlock &&
                                                openUploadBlock["RelevantOtherUpload"] &&
                                                uploadDocuments.upload_relavent_document_other.length === 0) ||
                                                uploadDocuments.upload_relavent_document_other.length > 0) && (
                                                    <>
                                                        <span
                                                            className="close"
                                                            onClick={() =>
                                                                setOpenUploadBlock({ RelevantOtherUpload: false })
                                                            }
                                                        >
                                                            <i className="fa fa-close"></i>
                                                        </span>
                                                        <FileUploader
                                                            key={Math.random().toString()}
                                                            id={StartupUploadTypeEnum.UploadRelaventDocumentOther}
                                                            information="Drag & Drop the file or Browse"
                                                            fileType="application/pdf"
                                                            buttonLabel="Browse"
                                                            multiple={true}
                                                            enablePreview
                                                            IconComponent={() => (
                                                                <span className="fa fa-cloud-upload"></span>
                                                            )}
                                                            removeIconClass=""
                                                            existingFiles={uploadDocuments.upload_relavent_document_other.filter((item) => (item.file.size) <= 15 * 1024 * 1024).map(
                                                                (uploadedFile: FileUploadTypeModel) =>
                                                                ({
                                                                    name: getFileDetails(uploadedFile.file, 'name') || uploadedFile.file.name,
                                                                    url: uploadedFile.file
                                                                })
                                                            )}
                                                            onUploadFinish={(files: Array<File>) => {
                                                                for (const file of files) {
                                                                    if (file.size <= 15 * 1024 * 1024) {
                                                                        onFileUploadCompleteRelevent(
                                                                            files,
                                                                            StartupUploadTypeEnum.UploadRelaventDocumentOther
                                                                        );
                                                                    } else {
                                                                        setExceedFileSize(true);
                                                                        return;
                                                                    }
                                                                }
                                                            }}
                                                            onFileDelete={(deletedFile: File) =>
                                                                onFileDelete(
                                                                    deletedFile,
                                                                    StartupUploadTypeEnum.UploadRelaventDocumentOther
                                                                )
                                                            }
                                                        />
                                                    </>
                                                )}
                                        </fieldset>

                                        {(uploadDocuments.upload_relavent_document_other.length > 0 && uploadDocuments.upload_relavent_document_other[0].file.size == undefined) ? (
                                            <a
                                                className="incubator-details-link"
                                                data-toggle="modal"
                                                data-target="#showMorePitchDocuments"
                                                onClick={() => setMeetingModal("Upload Relavant Doc")}
                                            >
                                                View uploaded Documents
                                            </a>
                                        ) : (
                                            ""
                                        )}
                                        <small>Supported file format - PDF only  |   Max. size: 15 MB</small>
                                        {/* {errors &&
                                            errors.UploadDocuments &&
                                            errors.UploadDocuments["upload_relavent_document_other"].length > 0 && (
                                                <ValidationMessage
                                                    className="error"
                                                    message={errors.UploadDocuments["upload_relavent_document_other"]}
                                                />
                                            )} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <fieldset disabled={disabledForm}>
                        <div className="form-group row">
                            <div className="col-sm-12">
                                <div className="agree-confirm-box">
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="agreeincubatorapplication"
                                            name="agreeincubatorapplication"
                                            checked={agreeTermsBlock}
                                            onChange={handleOnCheck}
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor="agreeincubatorapplication"
                                        >
                                            We are in compliance with the provisions of the various
                                            Acts, Rules, Regulations, Guidelines, Standards applicable
                                            to the entity from time to time. All information provided by
                                            us in the application is true, correct and complete and no
                                            information material to the subject matter of this form has
                                            been suppressed or concealed. If there is any change in this
                                            information or compliance status, I will duly inform Startup
                                            India within 15 days on{" "}
                                            <a href="mailto:dipp-startups@nic.in">
                                                <u className="link-orange-text">dipp-startups@nic.in</u>
                                            </a>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>

                </div>
                <fieldset disabled={disabledForm}>
                    <div className="stepper-footer-section">
                        <div
                            className="btn-toolbar justify-content-between"
                            role="toolbar"
                            aria-label="Toolbar with button groups"
                        >
                            <button className="btn btn-sisfs btn-transparent-primary" onClick={() => applicationPage()}>
                                Cancel
                            </button>
                            <span className="stepper-page-count">Step {props.tabIndex} / 7 </span>
                            <div className="btn-group">
                                {props.tabIndex != 1 && (
                                    <button
                                        className="btn btn-sisfs btn-transparent-primary"
                                        onClick={props.onPreviousClick}
                                    >
                                        Previous
                                    </button>
                                )}
                                <button
                                    type="button"
                                    className="btn btn-sisfs btn-yellow-primary"
                                    // onClick={() =>
                                    //     props.onSave(uploadDocuments, StepperStartupEnum.UploadDocuments)
                                    // }
                                    onClick={() => onSave("")}
                                >
                                    {props.tabIndex == 7 ? "Save" : "Save And Next"}
                                </button>
                                {props.tabIndex == 7 && (
                                    <button
                                        className="btn btn-sisfs btn-yellow-primary"
                                        disabled={!agreeTermsBlock}
                                        // onClick={() => props.onSubmit} 
                                        onClick={() => onSave("submit")}
                                    >
                                        Submit
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </fieldset>
            </fieldset>
            {/* duplicate file  */}
            {showIncubatorFailureModal1 && (
                <ConfirmationDialog
                    headerText={failDialogData.header}
                    bodyText={failDialogData.text}
                    // bodyNote={failDialogData.note}        
                    onDialogClose={() => setShowIncubatorFailureModal1(false)}
                    iconClass="showImage"
                />
            )}
            {/* fileUpload Limt Model         */}
            {showIncubatorFailureModal && (
                <div
                    className="modal align-middle modal-sisfs d-block"
                    id="FormSubmissionError"
                >
                    <div className="modal-dialog modal-dialog-centered modal-md">
                        <div className="modal-content">
                            <div className="modal-body text-center">
                                <img
                                    src={error}
                                    className="search mb-2"
                                    alt="search"

                                />
                                <h4 className="message-orange" style={{ fontWeight: "bold", fontFamily: "Roboto Slab" }}>
                                    Oops!!!
                                </h4>
                                <h4 className="message-orange" style={{ fontWeight: "bold", fontFamily: "Roboto Slab" }}>
                                    Please Try Again !!!<br /><br />
                                    Only 10 files can be uploaded once
                                </h4>
                                <div className="modal-action">
                                    <button
                                        className="btn btn-sisfs btn-yellow-primary"
                                        onClick={dismissModal}
                                    >
                                        OK !
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {exceedFileSize && (
                <div
                    className="modal align-middle modal-sisfs d-block"
                    id="FormSubmissionError"
                >
                    <div className="modal-dialog modal-dialog-centered modal-md">
                        <div className="modal-content">
                            <div className="modal-body text-center">
                                <img
                                    src={error}
                                    className="search mb-2"
                                    alt="search"
                                    style={{ width: '2rem' }}
                                />
                                <h4 className="message-orange" style={{ fontFamily: "Roboto Slab" }}>
                                    Oops!!!
                                </h4>
                                <h5 className="message-orange" style={{ fontFamily: "Roboto Slab" }}>
                                    File size should not exceed 15Mb<br /><br />
                                    Please upload again.
                                </h5>
                                <div className="modal-action">
                                    <button
                                        className="btn btn-sisfs btn-yellow-primary"
                                        onClick={dismissModal}
                                    >
                                        OK !
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/* Show More  */}
            <div
                className="modal fade"
                id="showMorePitchDocuments"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-centered modal-md"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header modal-header-border-none ml-18px">
                            <h5 className="modal-title " id="exampleModalLongTitle">
                                {popUpName} <span className="eac-name-pop-up"></span>
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {/* <div className=""> */}
                            {/* 1St Application Documents */}

                            {/* General Details documents */}
                            {popUpName == "Upload PitchDeck" ? (
                                uploadDocuments.upload_pitch_deck != undefined ? (
                                    uploadDocuments.upload_pitch_deck.map(
                                        (file: any, index: any) => (
                                            <div className="row">
                                                <div className="col-md-2">
                                                    {getFileDetails(file.file, "type") == "pdf" ? (
                                                        <img src={pdf} alt="SISFS Logo" />
                                                    ) : getFileDetails(file.file, "type") == "doc" ? (
                                                        <img src={doc} alt="SISFS Logo" />
                                                    ) : getFileDetails(file.file, "type") == "xls" ? (
                                                        <img src={xls} alt="SISFS Logo" />
                                                    ) : (
                                                        <img src={Allround} alt="SISFS Logo" />
                                                    )}
                                                </div>
                                                <div className="col-md-9 left-align">
                                                    <span className="document-label-value-wrap">
                                                        {getFileDetails(file.file, "name") || file.file.name}
                                                    </span>
                                                    <br />
                                                    <a
                                                        // href={file.file}
                                                        href={file.file}
                                                        target="_blank"
                                                        //download="annual_report"
                                                        className="incubator-details-link"
                                                    >
                                                        View
                                                    </a>
                                                    {/* /
                            <a
                              href="#"
                              //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                                                </div>
                                            </div>
                                        )
                                    )
                                ) : (
                                    <span className="incubator-label-value">-</span>
                                )
                            ) : (
                                ""
                            )}
                            {popUpName == "Upload Relavant Doc" ? (
                                uploadDocuments.upload_relavent_document_other != undefined ? (
                                    uploadDocuments.upload_relavent_document_other.map(
                                        (file: any, index: any) => (
                                            <div className="row">
                                                <div className="col-md-2">
                                                    {getFileDetails(file.file, "type") == "pdf" ? (
                                                        <img src={pdf} alt="SISFS Logo" />
                                                    ) : getFileDetails(file.file, "type") == "doc" ? (
                                                        <img src={doc} alt="SISFS Logo" />
                                                    ) : getFileDetails(file.file, "type") == "xls" ? (
                                                        <img src={xls} alt="SISFS Logo" />
                                                    ) : (
                                                        <img src={Allround} alt="SISFS Logo" />
                                                    )}
                                                </div>
                                                <div className="col-md-9 left-align">
                                                    <span className="document-label-value-wrap">
                                                        {getFileDetails(file.file, "name") || file.file.name}
                                                    </span>
                                                    <br />
                                                    <a
                                                        // href={file.file}
                                                        href={file.file}
                                                        target="_blank"
                                                        //download="annual_report"
                                                        className="incubator-details-link"
                                                    >
                                                        View
                                                    </a>
                                                    {/* /
                            <a
                              href="#"
                              //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                                                </div>
                                            </div>
                                        )
                                    )
                                ) : (
                                    <span className="incubator-label-value">-</span>
                                )
                            ) : (
                                ""
                            )}
                            <div className="row">
                                <div className="col-md-12 centerText">
                                    <div className="btn-group mt-1-5 ">
                                        <button
                                            className="tertiary  btn-yellow-primary"
                                            data-dismiss="modal"
                                        >
                                            OK
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UploadDocumentsComponent;