import { FileUploadModel } from '../../../models/FileUploadModel';
import { TeamDetails } from '../../../models/incubator/IncubatorTeamModel';

export class ReapplyApplicationGeneralDetails {
  constructor(
    public Audited_statement_expenditure_Upload: any="",
    public Audited_statement_expenditure_Upload_get:any = "",
    public Audited_statement_expenditure_Upload_slice:any = "",
    public Convertible_Debentures: any = '',
    public Corporate_connects_facilitated: any= '',
    public Cumulative_External_Funding: any= '',
    public Cumulative_Monetary_Return: any= '',
    public Current_Incubator_Team_Upload: any = "",
    public Current_Incubator_Team_Upload_get: any = "",
    public Current_Incubator_Team_Upload_slice: any = "",
    // public Debentures_Disbursed_Interest_Earned: any= '',
    public Debt: any= '',
    // public Debt_Disbursed_Interest_Earned: any= '',
    public Debt_Linked_Instruments: any= '',
    public Declaration_violation_SISFS_Upload: any = "",
    public Details_Interest_earned: any= '',
    public Events_stakeholder_engagements: any= '',
    public Fund_Utilization_Details: any= '',
    public GST_Filed_Startups: any= '',
    public Grant_Disbursed: any= '',
    public Grants: any= '',
    public Investment_agreements_signed: any = '',
    public List_significant_facilities: any= '',
    public List_significant_facilities_upload: any = "",
    public List_significant_facilities_upload_slice:any = "",
    public List_significant_facilities_upload_get:any ="",
    public No_full_time_employees_upload:any = "",
    public No_full_time_employees_upload_get:any = "",
    public No_full_time_employees_upload_slice:any = "",
    public Name_Background_current: any= '',
    public No_People_Employed_Startups: any= '',
    public No_Products_Services_Startups: any= '',
    public No_Registration_Requests_Filed: any= '',
    public No_Startup_Applications_Allotted: any= '',
    public No_Startup_Applications_Cancelled: any= '',
    public No_Startup_Applications_Closed: any= '',
    public No_Startup_Applications_Open: any= '',
    public No_Startup_Applications_Received: any= '',
    public No_Startup_Applications_Reviewed: any= '',
    public No_Startup_Applications_Selected: any= " ",
    public No_Startup_whom_Agreements: any= "",
    public No_States_represented: any= " ",
    public No_Testimonials_Success: any= "",
    public No_full_time_employees: any= " ",
    public No_individuals: any= "",
    public No_industries_represented: any= " ",
    public No_mentors_actively: any= "",
    public No_patents_copyrights: any= " ",
    public No_patents_registered: any= "",
    public No_startups_crossed: any= " ",
    public No_startups_graduated: any= "",
    public No_startups_incubated: any= " ",
    public No_startups_invested: any= "",
    public No_startups_raised: any= " ",
    public No_startups_undergoing: any= "",
    public No_trademarks_registered: any= " ",
    public Number_startups_Field_Trial: any= "",
    public Number_startups_Market: any= " ",
    public Number_startups_Other_Stage: any= " ",
    public Number_startups_PoC_stage: any= " ",
    public Number_startups_Product_Development: any= " ",
    public Number_startups_Prototype: any= " ",
    public Participation_Event: any= '',
    public Principal_Amount_Returned: any= " ",
    public Total_Interest_Debt_Bank: any= " ",
    public Total_Interest_Debt_Due: any= " ",
    public Turnover_Startups_financial: any= " ",
    public Utilization_Certificate_Upload: any = "",
    public Utilization_Certificate_Upload_get:any = "",
    public Utilization_Certificate_Upload_slice:any = "",
    public allocated_incubatees: any = "",
    public allocated_startup_month: any= '',
    public city: any= '',
    public dpiit_comments: any= '',
    public external_sources: any = '',
    public id: any = '',
    public incubator_application_no: any = '',
    public incubator_name: any = '',
    public state: any ="",
    public status_id: any ="",
    public supported_year_One: any ="",
    public supported_year_Three: any ="",
    public supported_year_Two: any ="",
    public survival_rate_startups: any ="",
    public representative_name:any ="",
    public representative_designation:any = "",
    public representative_mobile_no:any = "",
    public representative_email:any = "",
    public address:any ="",
    public registered_name:any = "",
    public website_url:any = "",
    public facebook_link:any = "",
    public linkedin_link:any = "",
    public twitter_link:any = "",
    public youtube_link:any = "",

    public Debt_Disbursed:any = "",
    public Debt_Interest:any = "",
    public Debt_Other_Earned:any = "",
    public Debentures_Disbursed:any = "",
    public Debentures_Interest_Earned:any = "",
    public Debentures_Other_Earned:any = "",
    public Funds_disbursed_Grants:any = "",
    public disbursed_Convertible_Debentures:any = "",
    public disbursed_Debt:any = "",
    public disbursed_Debt_Linked_Instruments:any = "",

    public uploaded_documents: ReapplyOthersModel,

    public fulltime_employees_re_apply:any="",
    public pitchdeck_upload_fpath:any="",
    public Performance_Upload_fpath:any="",
    public Other_doc_upload_fpath:any="",
    public quantum_fund_required_re_apply:any="",
    public no_fund_benifitiaries_1yr_re_apply:any="",
    public no_fund_benifitiaries_2yr_re_apply:any="",
    public no_fund_benifitiaries_3yr_re_apply:any="",
    public industryData: Array<IndustryData> = [],
    public sectorData: Array<SectorData> = [],
    public teamsData: Array<TeamDetails>,

  ) {
     this.Audited_statement_expenditure_Upload = Audited_statement_expenditure_Upload;
     this. Audited_statement_expenditure_Upload_get = Audited_statement_expenditure_Upload_get;
     this. Audited_statement_expenditure_Upload_slice = Audited_statement_expenditure_Upload_slice;
     this.Convertible_Debentures = Convertible_Debentures;
     this.Corporate_connects_facilitated = Corporate_connects_facilitated;
     this.Cumulative_External_Funding = Cumulative_External_Funding;
     this.Cumulative_Monetary_Return = Cumulative_Monetary_Return;
     this.Current_Incubator_Team_Upload = Current_Incubator_Team_Upload;
     this.Current_Incubator_Team_Upload_get = Current_Incubator_Team_Upload_get;
     this.Current_Incubator_Team_Upload_slice = Current_Incubator_Team_Upload_slice;
    //  this.Debentures_Disbursed_Interest_Earned = Debentures_Disbursed_Interest_Earned;
     this.Debt = Debt;
    //  this.Debt_Disbursed_Interest_Earned = Debt_Disbursed_Interest_Earned;
     this.Debt_Linked_Instruments = Debt_Linked_Instruments;
     this.Declaration_violation_SISFS_Upload = Declaration_violation_SISFS_Upload;
     this.Details_Interest_earned = Details_Interest_earned;
     this.Events_stakeholder_engagements = Events_stakeholder_engagements;
     this.Fund_Utilization_Details = Fund_Utilization_Details;
     this.GST_Filed_Startups = GST_Filed_Startups;
     this.Grant_Disbursed = Grant_Disbursed;
     this.Grants = Grants;
     this.Investment_agreements_signed = Investment_agreements_signed;
     this.List_significant_facilities = List_significant_facilities;
     this.List_significant_facilities_upload = List_significant_facilities_upload;
     this.List_significant_facilities_upload_slice = List_significant_facilities_upload_slice;
     this.List_significant_facilities_upload_get = List_significant_facilities_upload_get;
     this.No_full_time_employees_upload = No_full_time_employees_upload;
     this. No_full_time_employees_upload_get = No_full_time_employees_upload_get;
     this. No_full_time_employees_upload_slice = No_full_time_employees_upload_slice;
     this.Name_Background_current = Name_Background_current;
     this.No_People_Employed_Startups = No_People_Employed_Startups;
     this.No_Products_Services_Startups = No_Products_Services_Startups;
     this.No_Registration_Requests_Filed = No_Registration_Requests_Filed;
     this.No_Startup_Applications_Allotted = No_Startup_Applications_Allotted;
     this.No_Startup_Applications_Cancelled = No_Startup_Applications_Cancelled;
     this.No_Startup_Applications_Closed = No_Startup_Applications_Closed;
     this.No_Startup_Applications_Open = No_Startup_Applications_Open;
     this.No_Startup_Applications_Received = No_Startup_Applications_Received;
     this.No_Startup_Applications_Reviewed = No_Startup_Applications_Reviewed;
     this.No_Startup_Applications_Selected = No_Startup_Applications_Selected;
     this.No_Startup_whom_Agreements = No_Startup_whom_Agreements;
     this.No_States_represented = No_States_represented;
     this.No_Testimonials_Success = No_Testimonials_Success;
     this.No_full_time_employees = No_full_time_employees;
     this.No_individuals = No_individuals;
     this.No_industries_represented = No_industries_represented;
     this.No_mentors_actively = No_mentors_actively;
     this.No_patents_copyrights = No_patents_copyrights;
     this.No_patents_registered = No_patents_registered;
     this.No_startups_crossed = No_startups_crossed;
     this.No_startups_graduated = No_startups_graduated;
     this.No_startups_incubated = No_startups_incubated;
     this.No_startups_invested = No_startups_invested;
     this.No_startups_raised = No_startups_raised;
     this.No_startups_undergoing = No_startups_undergoing;
     this.No_trademarks_registered = No_trademarks_registered;
     this.Number_startups_Field_Trial = Number_startups_Field_Trial;
     this.Number_startups_Market = Number_startups_Market;
     this.Number_startups_Other_Stage = Number_startups_Other_Stage;
     this.Number_startups_PoC_stage = Number_startups_PoC_stage;
     this.Number_startups_Product_Development = Number_startups_Product_Development;
     this.Number_startups_Prototype = Number_startups_Prototype;
     this.Participation_Event = Participation_Event;
     this.Principal_Amount_Returned = Principal_Amount_Returned;
     this.Total_Interest_Debt_Bank = Total_Interest_Debt_Bank;
     this.Total_Interest_Debt_Due = Total_Interest_Debt_Due;
     this.Turnover_Startups_financial = Turnover_Startups_financial;
     this.Utilization_Certificate_Upload = Utilization_Certificate_Upload;
     this. Utilization_Certificate_Upload_get = Utilization_Certificate_Upload_get;
     this. Utilization_Certificate_Upload_slice = Utilization_Certificate_Upload_slice;
     this.allocated_incubatees = allocated_incubatees;
     this.allocated_startup_month = allocated_startup_month;
     this.city = city;
     this.id = id;
     this.incubator_application_no = incubator_application_no;
     this.incubator_name = incubator_name;
     this.state = state;
     this.status_id = status_id;
     this.supported_year_One = supported_year_One;
     this.supported_year_Three = supported_year_Three;
     this.supported_year_Two = supported_year_Two;
     this.survival_rate_startups = survival_rate_startups;
    this.representative_name = representative_name; 
    this.representative_designation = representative_designation;
    this.representative_mobile_no = representative_mobile_no;
    this.representative_email = representative_email; 
    this.address = address;
     this.registered_name = registered_name;
     this.website_url = website_url;
     this.facebook_link =facebook_link;
     this.linkedin_link =linkedin_link;
     this.twitter_link =twitter_link;
     this.youtube_link =youtube_link;
     
      this.Debt_Disbursed= Debt_Disbursed;
      this.Debt_Interest= Debt_Interest;
      this.Debt_Other_Earned= Debt_Other_Earned;
      this.Debentures_Disbursed= Debentures_Disbursed;
      this.Debentures_Interest_Earned= Debentures_Interest_Earned;
      this.Debentures_Other_Earned= Debentures_Other_Earned;
      this.Funds_disbursed_Grants= Funds_disbursed_Grants;
      this.disbursed_Convertible_Debentures= disbursed_Convertible_Debentures;
      this.disbursed_Debt= disbursed_Debt;
      this.disbursed_Debt_Linked_Instruments= disbursed_Debt_Linked_Instruments;

      this.uploaded_documents = uploaded_documents;

      this.fulltime_employees_re_apply = fulltime_employees_re_apply;
      this.pitchdeck_upload_fpath = pitchdeck_upload_fpath;
      this.Performance_Upload_fpath = Performance_Upload_fpath;
      this.Other_doc_upload_fpath = Other_doc_upload_fpath;
      this.quantum_fund_required_re_apply = quantum_fund_required_re_apply;
      this.no_fund_benifitiaries_1yr_re_apply = no_fund_benifitiaries_1yr_re_apply;
      this.no_fund_benifitiaries_2yr_re_apply = no_fund_benifitiaries_2yr_re_apply;
      this.no_fund_benifitiaries_3yr_re_apply = no_fund_benifitiaries_3yr_re_apply;
      this.teamsData = teamsData;
      this.industryData = industryData;
      this.sectorData = sectorData;
  }
}

export class ApplicationGeneralDetails {
  constructor(
    public id: number = 0,
    public user_id: number = 0,
    public registered_name: string = '',
    public address_state_id: number = 0,
    public address_city_id: number = 0,
    public address: string = '',
    public representative_name: string = '',
    public representative_designation: string = '',
    public representative_mobile_no: number = 0,
    public representative_email: string = '',
    public type_legal_entity_id: string = '',
    public registration_date: string = '',
    public corporate_id_no: number = 0,
    public operationalization_date: string = '',
    public seating_capacity_individulas: number = 0,
    public no_startups_incubation: number = 0,
    public fund_disburse_using_3rd_party: number = 0,
    public central_incubator_far_id: Array<string> = [],
    public nature_assistance_incubator: string = '',
    public ceo_firstname: string = '',
    public ceo_lastname: string = '',
    public ceo_description: string = '',
    public fulltime_employees_no: number = 0,
    public list_facilities: string = '',
    public startups_no_incubated: number = 0,
    public startups_no_graduated: number = 0,
    public startups_no_investments: number = 0,
    public startups_no_revenue_year: number = 0,
    public startups_survival_rate: number = 0,
    public investments_between_incubator_startups: number = 0,
    public startups_invested: number = 0,
    public startups_invested_applicable: string = "",
    public startups_invested_total_corpus: number = 0,
    public startups_invested_total_corpus_applicable: string = "",
    public total_investments_raised_incubatees: number = 0,
    public total_investments_raised_incubatees_applicable: string = "",
    public mentors_no_engaged: number = 0,
    public mentors_no_engaged_applicable: string = "",
    public mentors_average_hours: number = 0,
    public mentors_average_hours_applicable: string = "",
    public mentors_no_of_ip: number = 0,
    public mentors_no_of_ip_applicable: string = "",
    public corporate_facilitated: number = 0,
    public corporate_facilitated_applicable: string = "",
    public event_engagements: number = 0,
    public event_engagements_applicable: string = "",
    public participation_other_events: number = 0,
    public participation_other_events_applicable: string = "",
    public quantum_fund_required: number = 0,
    public no_fund_benifitiaries_1yr: number = 0,
    public no_fund_benifitiaries_2yr: number = 0,
    public no_fund_benifitiaries_3yr: number = 0,
    public status_id: number = 0,
    public submitted_at: string = '',
    public no_of_tranches: number = 0,
    public date_of_evaluation: number = 0,
    public total_approved_amt: number = 0,
    public total_disbursed_amt: number = 0,
    public dpiit_comments: number = 0,
    public is_active: boolean = false,
    public created_by: string = '',
    public updated_by: string = '',
    public created_at: string = '',
    public updated_at: string = '',
    public application_status: string = '',
    public state: string = '',
    public city: string = '',
    public legal_entity: string = '',
    public central_incubator_far: string = '',
    public industryData: Array<IndustryData> = [],
    public sectorData: Array<SectorData> = [],
    public teamsData: Array<TeamDetails>,
    public uploaded_documents: OthersModel,
    public incubator_name: string,
    public website_url: string,
    public facebook_link: string,
    public twitter_link: string,
    public linkedin_link: string,
    public youtube_link: string,
    public no_of_patents: number,
    public no_of_copyrights: number,
    public no_of_trademarks: number,
    public no_of_copyrights_applicable: string,

    public no_of_patents_applicable: string,

    public no_of_trademarks_applicable: string,

    public pan_card_number: string,
  ) {
    this.id = id;
    this.user_id = user_id;
    this.registered_name = registered_name;
    this.address_state_id = address_state_id;
    this.address_city_id = address_city_id;
    this.address = address;
    this.representative_name = representative_name;
    this.representative_designation = representative_designation;
    this.representative_mobile_no = representative_mobile_no;
    this.representative_email = representative_email;
    this.type_legal_entity_id = type_legal_entity_id;
    this.registration_date = registration_date;
    this.corporate_id_no = corporate_id_no;
    this.operationalization_date = operationalization_date;
    this.seating_capacity_individulas = seating_capacity_individulas;
    this.no_startups_incubation = no_startups_incubation;
    this.fund_disburse_using_3rd_party = fund_disburse_using_3rd_party;
    this.central_incubator_far_id = central_incubator_far_id;
    this.nature_assistance_incubator = nature_assistance_incubator;
    this.ceo_firstname = ceo_firstname;
    this.ceo_lastname = ceo_lastname;
    this.ceo_description = ceo_description;
    this.fulltime_employees_no = fulltime_employees_no;
    this.list_facilities = list_facilities;
    this.startups_no_incubated = startups_no_incubated;
    this.startups_no_graduated = startups_no_graduated;
    this.startups_no_investments = startups_no_investments;
    this.startups_no_revenue_year = startups_no_revenue_year;
    this.startups_survival_rate = startups_survival_rate;
    this.investments_between_incubator_startups = investments_between_incubator_startups;
    this.startups_invested = startups_invested;
    this.startups_invested_applicable = startups_invested_applicable;
    this.startups_invested_total_corpus = startups_invested_total_corpus;
    this.startups_invested_total_corpus_applicable = startups_invested_total_corpus_applicable;
    this.total_investments_raised_incubatees = total_investments_raised_incubatees;
    this.total_investments_raised_incubatees_applicable = total_investments_raised_incubatees_applicable;
    this.mentors_no_engaged = mentors_no_engaged;
    this.mentors_no_engaged_applicable = mentors_no_engaged_applicable;
    this.mentors_average_hours = mentors_average_hours;
    this.mentors_average_hours_applicable = mentors_average_hours_applicable;
    this.mentors_no_of_ip = mentors_no_of_ip;
    this.mentors_no_of_ip_applicable = mentors_no_of_ip_applicable;
    this.corporate_facilitated = corporate_facilitated;
    this.corporate_facilitated_applicable = corporate_facilitated_applicable;
    this.event_engagements = event_engagements;
    this.event_engagements_applicable = event_engagements_applicable;
    this.participation_other_events = participation_other_events;
    this.participation_other_events_applicable = participation_other_events_applicable;
    this.quantum_fund_required = quantum_fund_required;
    this.no_fund_benifitiaries_1yr = no_fund_benifitiaries_1yr;
    this.no_fund_benifitiaries_2yr = no_fund_benifitiaries_2yr;
    this.no_fund_benifitiaries_3yr = no_fund_benifitiaries_3yr;
    this.status_id = status_id;
    this.submitted_at = submitted_at;
    this.no_of_tranches = no_of_tranches;
    this.date_of_evaluation = date_of_evaluation;
    this.total_approved_amt = total_approved_amt;
    this.total_disbursed_amt = total_disbursed_amt;
    this.dpiit_comments = dpiit_comments;
    this.is_active = is_active;
    this.created_by = created_by;
    this.updated_by = updated_by;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.application_status = application_status;
    this.state = state;
    this.city = city;
    this.legal_entity = legal_entity;
    this.central_incubator_far = central_incubator_far;
    this.industryData = industryData;
    this.sectorData = sectorData;
    this.teamsData = teamsData;
    this.uploaded_documents = uploaded_documents;
    this.incubator_name = incubator_name;
    this.website_url = website_url;
    this.facebook_link = facebook_link;
    this.twitter_link = twitter_link;
    this.linkedin_link = linkedin_link;
    this.youtube_link = youtube_link;
    this.no_of_patents = no_of_patents;
    this.no_of_copyrights = no_of_copyrights;
    this.no_of_trademarks = no_of_trademarks;
    this.no_of_patents_applicable = no_of_patents_applicable;
    this.no_of_copyrights_applicable = no_of_copyrights_applicable;
    this.no_of_trademarks_applicable = no_of_trademarks_applicable;

    this.pan_card_number = pan_card_number;
  }
}

export class IndustryData {
  constructor(
    public application_industry_id: number = 0,
    public industry_name: string = ''
  ) {
    this.application_industry_id = application_industry_id;
    this.industry_name = industry_name;
  }
}

export class SectorData {
  constructor(
    public application_sector_id: number = 0,
    public industry_name: string = ''
  ) {
    this.application_sector_id = application_sector_id;
    this.industry_name = industry_name;
  }
}

export class OthersModel {
  constructor(
   
    public annual_report: Array<FileDownloadModel>,
    public facilities_pictures: Array<FileDownloadModel>,
    public incorporation_certificate: Array<FileDownloadModel>,
    public proof_assistance: Array<FileDownloadModel>,
    public relavent_document_excel: Array<FileDownloadModel>,
    public relavent_document_pitchdeck: Array<FileDownloadModel>,
    public relavent_document_other: Array<FileDownloadModel>,
    public team_members_cv: Array<FileDownloadModel>,

    public pan_card_upload: Array<FileUploadModel>,
    public authorisation_letter_upload: Array<FileUploadModel>,
    public adhaar_card_upload: Array<FileUploadModel>,
    public startup_incubation_upload: Array<FileUploadModel>,
    public self_declaration_upload: Array<FileUploadModel>,
  ) {
    
    this.annual_report = annual_report;
    this.facilities_pictures = facilities_pictures;
    this.incorporation_certificate = incorporation_certificate;
    this.proof_assistance = proof_assistance;
    this.relavent_document_excel = relavent_document_excel;
    this.relavent_document_pitchdeck = relavent_document_pitchdeck;
    this.relavent_document_other = relavent_document_other;
    this.team_members_cv = team_members_cv;
    
    this.pan_card_upload = pan_card_upload;
    this.authorisation_letter_upload = authorisation_letter_upload;
    this.adhaar_card_upload = adhaar_card_upload;
    this.startup_incubation_upload = startup_incubation_upload;
    this.self_declaration_upload = self_declaration_upload;
  }
}

export class ReapplyOthersModel {
  constructor(
    // public Audited_statement_expenditure_Upload: Array<FileDownloadModel>,
    public Audited_statement_expenditure_Upload: any,
    public Audited_statement_expenditure_Upload_get:any,
    public Audited_statement_expenditure_Upload_slice:any,

    public Current_Incubator_Team_Upload: any,
    public Current_Incubator_Team_Upload_get: any,
    public Current_Incubator_Team_Upload_slice: any,
    public List_significant_facilities_upload: any,
    public No_full_time_employees_upload: any,
    public Utilization_Certificate_Upload: any,
    public Utilization_Certificate_Upload_get : any,
    public Utilization_Certificate_Upload_slice : any,
  ) {
    
    this.Audited_statement_expenditure_Upload = Audited_statement_expenditure_Upload;
    this. Audited_statement_expenditure_Upload_get = Audited_statement_expenditure_Upload_get;
    this. Audited_statement_expenditure_Upload_slice = Audited_statement_expenditure_Upload_slice;

    this.Current_Incubator_Team_Upload = Current_Incubator_Team_Upload;
    this.Current_Incubator_Team_Upload_get = Current_Incubator_Team_Upload_get;
    this.Current_Incubator_Team_Upload_slice = Current_Incubator_Team_Upload_slice;
    this.List_significant_facilities_upload = List_significant_facilities_upload;
    this.No_full_time_employees_upload = No_full_time_employees_upload;
    this.Utilization_Certificate_Upload = Utilization_Certificate_Upload;
    this. Utilization_Certificate_Upload_get = Utilization_Certificate_Upload_get;
    this. Utilization_Certificate_Upload_slice = Utilization_Certificate_Upload_slice;
  }
}

export class FileDownloadModel {
  constructor(
    public file_id: number,
    public file_type_id: number,
    public file: string,
    public file_name: string,
    public file_type: string
  ) {
    this.file_id = file_id;
    this.file_type_id = file_type_id;
    this.file = file;
    this.file_name = file_name;
    this.file_type = file_type;
  }
}

export class ApplicationStatus {
  constructor(public label: string = '', public value: number = 0) {
    this.label = label;
    this.value = value;
  }
}

export class QuickEditPopupData {
  constructor(
    public application_comments: string = '',
    public application_id: string = '',
    public application_status: string = '',
    public corporate_id_no: string = '',
    public incubator_name: string = '',
    public user_id: string = '',
    public incubator_application_no:string = ''
  ) {
    this.application_comments = application_comments;
    this.application_id = application_id;
    this.application_status = application_status;
    this.corporate_id_no = corporate_id_no;
    this.incubator_name = application_comments;
    this.user_id = user_id;
    this.incubator_application_no = incubator_application_no;
  }
}

export class CommonType {
  constructor(public id: number = 0, public name: string = '') {
    this.id = id;
    this.name = name;
  }
}
