import * as XLSX from 'xlsx';

export const exportToExcel = (jsonData: any,ExcelName : any) => {
    const workbook = XLSX.utils.book_new();
    if(jsonData.length > 0){
        const worksheet = XLSX.utils.json_to_sheet(jsonData, { header: [], skipHeader: true });
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    } 
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const excelBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const fileName = ExcelName;
    const excelUrl = URL.createObjectURL(excelBlob);
    const a = document.createElement('a');
    a.href = excelUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(excelUrl);
  };
export const exportToExcelMultipleSheets = (data1: any[], data2: any[],Sheet1Name : any,Sheet2Name: any,ExcelName:any) => {
    const workbook = XLSX.utils.book_new(); 
    if(data1.length > 0){
        const worksheet1 = XLSX.utils.json_to_sheet(data1, {header:[], skipHeader:true});
        XLSX.utils.book_append_sheet(workbook, worksheet1, Sheet1Name); // Sheet1 for the first array
    } 
    if(data2.length > 0){
        const worksheet2 = XLSX.utils.json_to_sheet(data2, {header:[], skipHeader:true});
        XLSX.utils.book_append_sheet(workbook, worksheet2, Sheet2Name); // Sheet2 for the second array
    }
    
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const excelBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const fileName = ExcelName;
      const excelUrl = URL.createObjectURL(excelBlob);
      const a = document.createElement('a');
      a.href = excelUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(excelUrl);
  };
