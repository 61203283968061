import React, { useEffect, useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { RESET_PASSWORD, UI_URL } from '../../../constants/api';
import { post } from '../../../effects/api/api.common';
import SeedFundSchemeLogo from '../../../images/SeedFundSchemeLogo.svg';
import { passwordValidate } from '../../../utils/CommonMethods';
import ConfirmationDialog from '../controls/ConfirmationDialog';

function ConfirmPasswordComponent(props: any) {
    const history = useHistory();
    const [payload, setPayload] = useState({
        email: '',
        token: ''
    });
    const [error, setError] = useState(false)
    const [validateError, setValidateError] = useState(false)
    const [password, setPassword] = useState({
        newPassword: '',
        confirmPassword: ''
    });
    const [showIncubatorSuccessModal, setShowIncubatorSuccessModal] = useState(false);
    const [showIncubatorFailureModal, setShowIncubatorFailureModal] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [dialogData, setDialogData] = useState({
        "header": '',
        "text": ''
    });
    const [failDialogData, setFailDialogData] = useState({
        "header": '',
        "text": ''
    });

    useEffect(() => {
        window.scrollTo(0, 0)
        setPayload({
            email: props.match.params.email,
            token: props.match.params.token
        })
        // console.log(props.location.search)
    }, []);
    const onChange = (e: any) => {
        if (e.target.name == "new_password") {
            setPassword((prevState => ({
                ...prevState,
                newPassword: e.target.value
            })))
        }
        if (e.target.name == "confirm_password") {
            setPassword((prevState => ({
                ...prevState,
                confirmPassword: e.target.value
            })))
            if (password.newPassword == e.target.value) {
                setError(false);
                if (passwordValidate(e.target.value)) {
                    setValidateError(false)
                }
            }
            
        }

    }

    const checkPassword = () => {
        if (password.newPassword == password.confirmPassword) {
            if (!passwordValidate(password.newPassword)) {
                setValidateError(true)
                return;
            }
            setError(false);
            const requestPayload = {
                "email": payload.email,
                "password": password.newPassword,
                "password_confirmation": password.confirmPassword,
                "token": payload.token
            }
            // console.log(requestPayload)
            post(RESET_PASSWORD, requestPayload).then((response: any) => {
                if (!response || !response.data) return;
                // console.log(response.data)
                setDisabled(false)
                if (response.data.result == "success") {
                    setDialogData({
                        "header": "Thank You!",
                        "text": response.data.message
                    })
                    setShowIncubatorSuccessModal(true)
                }
                else {
                    setFailDialogData({
                        "header": "",
                        "text": response.data.message
                    })
                    setShowIncubatorFailureModal(true);
                }
            });
        }
        else if (password.newPassword != password.confirmPassword) {
            setError(true)
        }
    }
    const redirect = () => {
        window.location.href = UI_URL;
    }
    return (
        <main role="main">
            <div className="container">
                <div className="confirm-page-wrapper">
                    <div className="form-signin">

                        <div className="text-center mb-4">
                            <img src={SeedFundSchemeLogo} className="img-fluid text-center" alt="SISFS Logo" />
                        </div>

                        <h1 className="login-heading text-center mb-4">Create New  Password</h1>
                        {/* <div className="form-group row text-center">
                            <div className="col-sm-12">
                                <span>
                                    Please enter the email address you'd like your password reset information to be sent to
                          </span>

                            </div>
                        </div> */}
                        <div className="form-group row  mt-4">
                            <div className="col-sm-12">
                                <input
                                    type="password"
                                    id="new_password"
                                    name="new_password"
                                    className="form-control input-height"
                                    placeholder="New Password"
                                    value={password.newPassword}
                                    required
                                    onChange={onChange}

                                />
                            </div>
                        </div>

                        <div className="form-group row  mt-2">
                            <div className="col-sm-12">
                                <input
                                    type="password"
                                    id="confirm_password"
                                    name="confirm_password"
                                    className="form-control input-height"
                                    placeholder="Confirm Password"
                                    value={password.confirmPassword}
                                    required
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                        {error && <label className="error-message">Password does not match!!</label>}
                        {validateError&&<div className="form-group row text-center mt-4">
                            <div className="col-sm-12">
                                <span className="error-message">
                                    The password should contain an uppercase Letter (A-Z), a lowercase letter and one number. The length should be between 8-25
                          </span>

                            </div>
                        </div>}

                        <div className="form-group row mt-4 mb-3">
                            <div className="col-sm-12">
                                <button
                                    id="btnLogIn"
                                    className="btn w-100 btn-sisfs btn-yellow-primary"
                                    name="button"
                                    value="login"
                                    type="button"
                                    onClick={checkPassword}
                                > Create New Password
                    </button>
                            </div>
                        </div>
                        <div className="form-group row mt-5 text-center">
                            <div className="col-sm-12">
                                <a className="link-orange-text" href={UI_URL}>
                                    <span className=" ml-2">
                                        Back To Home
                                    </span>
                                </a>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showIncubatorSuccessModal && (
                <ConfirmationDialog
                    headerText={dialogData.header}
                    bodyText={dialogData.text}
                    onDialogClose={redirect}
                />
            )}
            {showIncubatorFailureModal && (
                <ConfirmationDialog
                    headerText={failDialogData.header}
                    bodyText={failDialogData.text}
                    onDialogClose={() => setShowIncubatorFailureModal(false)}
                    iconClass="showImage"
                />
            )}
        </main>
    );
}

export default ConfirmPasswordComponent;
