import FileUploader from '@amodv/react-file-uploader';
import { useEffect, useState } from 'react';
import { MEETING_FILE_REMOVE, MEETING_FILE_UPLOAD, NODE_URL, TRANCHE_FILE_REMOVE, TRANCHE_UPLOAD_SANCTION } from '../../../constants/api';
import { post } from '../../../effects/api/api.common';
import { getSessionStorageValue } from '../../../effects/utils/session-storage';
import { FileUploadModel } from '../../../models/FileUploadModel';
import { TrancheData, TrancheDetail } from '../../../models/funddetails/TrancheDetails';
import { IncubatorOthersModel } from '../../../models/incubator/IncubatorOthersModel';
import { UploadTypeEnum } from '../../../utils/Enums';

interface ITrancheProps {
  trancheDetailsModel: Array<TrancheDetail>;
}

function TrancheInfoReapplyComponent(props: ITrancheProps) {
  const { trancheDetailsModel } = props;
  const [otherDetail, setOtherDetail] = useState<IncubatorOthersModel>(
    new IncubatorOthersModel("", [], [], [], [], false, false, false, false, [])
  );
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [trancheId, setTrancheId] = useState("");
  const [sanctionOrderPath, setSanctionOrderPath] = useState("")
  const [loggedInRole, setLoggedInRole] = useState("");

  useEffect(() => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    setLoggedInRole(userInfo.roles);
    // console.log("userInfo.role", userInfo.roles)
  }, []);

  const onDialogOpen = (data: any) => {
    setTrancheId(data.tranch_id);
    setSanctionOrderPath(data.sanction_order_location)
    setOpenUploadDialog(true);
  }
  const onDialogClose = () => {
    setTrancheId("");
    setSanctionOrderPath("");
    setOpenUploadDialog(false);
  }

  const onFileUpload = (files: Array<File>) => {
    let indexes: Array<string> = [];
    setUploadedFile([]);
    for (let file of files) {
      if (file.type === "application/pdf") {
        const formData = new FormData();
        // Update the formData object
        formData.append("file", file, file.name);
        formData.append("tranche_id", trancheId);
        formData.append("application_index", 'F1');
        post(TRANCHE_UPLOAD_SANCTION, formData).then((response: any) => {
          if (!response.data) return;
          if (response.data.result.status == true) {
            setOpenUploadDialog(false);
            window.location.reload();
          } else {

          }
        });
      } else {
        indexes.push(file.name);
      }
    }
  };
  const onFileDelete = () => {

  };

  return (
    <>
      <div>
        {trancheDetailsModel != undefined &&
          trancheDetailsModel.length > 1
          ?
          <div className="row mt-1-5 justify-content-center">
            <div id="tranchestepper" className="col-md-12 bs-stepper">
              <div className="bs-stepper-header" role="tablist">
                {trancheDetailsModel.map((item, index) => (
                  <div className={item.tranches_status_label == "Disbursed" ? "step active" : "step"} key={index}>
                    <button
                      type="button"
                      className="step-trigger"
                      role="button"
                      id="stepper1trigger1"
                      aria-controls="tranche1-expand"
                    >
                      <span className="bs-stepper-circle tooltip tooltip-overwrite">
                        <span className="tooltiptext">
                          {' '}

                          <span> Released Amount: &#8377;{item.funds_requested > 0 ? item.funds_requested : 0} </span>

                          <br />

                          <span> Requested Date: {item.tranche_requested_date != null ? item.tranche_requested_date : "-"}</span>

                        </span>
                      </span>
                    </button>
                    {index + 1 == trancheDetailsModel.length ? (
                      ''
                    ) : (
                      <div className="bs-stepper-line"></div>
                    )}

                    <div className={item.tranches_status_label == "Disbursed" ? "stepper-label-bold" : "stepper-label"}
                    >{item.tranche_number}</div>

                    {item.tranches_status_label == "Disbursed" && loggedInRole == 'secretariat' ? (
                      <>
                        <span className="md-tooltip--top" data-md-tooltip="Upload Sanction Order">
                          <i
                            className={item.sanction_order ? "fa fa-2x fa-paperclip text-success" : "fa fa-2x fa-paperclip"}
                            onClick={() => onDialogOpen(item)} aria-hidden="true"
                          >
                          </i>
                        </span>
                      </>) : null}
                      
                    {item.tranches_status_label == "Disbursed" && loggedInRole == 'incubator' && item.sanction_order_location !== null ? (
                      <>
                        <span className="md-tooltip--top" data-md-tooltip="Download Sanction Order">
                          <a href={`${NODE_URL}`+ (item.sanction_order_location).substr(1)} target="_blank">
                            <i
                              className={"fa fa-lg fa-download"} aria-hidden="true"
                            >
                            </i>
                          </a>
                        </span>
                      </>) : null}
                  </div>
                ))
                }

              </div>
            </div>
          </div>
          : ''}
      </div>
      {openUploadDialog ? (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal align-middle modal-sisfs d-block"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <div className="modal-text">Upload Document</div>
                  <FileUploader
                    key={"tranche_file" + trancheId}
                    id={"tranche_file" + trancheId}
                    information="Drag & Drop the file or Browse"
                    fileType="application/pdf"
                    buttonLabel="Browse"
                    multiple={false}
                    enablePreview
                    IconComponent={() => (
                      <span className="fa fa-cloud-upload"></span>
                    )}
                    removeIconClass=""
                    existingFiles={uploadedFile}
                    onUploadFinish={(files: Array<File>) => {
                      onFileUpload(files);
                    }}
                    onFileDelete={(deletedFile: File) => onFileDelete()}
                  />
                  {sanctionOrderPath != "" ? (
                    <>
                      <label className="col-form-head mt-1 pl-4">
                        Uploaded Document
                      </label>
                      <br />
                      <div className="doc-div  text-center">
                        <span className="view-all">
                          <a
                            href={sanctionOrderPath}
                            className="view-all"
                            target="_blank"
                          >
                            View
                          </a>
                        </span>
                        <br />
                        <iframe scrolling="no" src={sanctionOrderPath} />
                      </div>
                    </>
                  ) : null}
                  <div className="modal-action">
                    <button className="btn btn-sisfs btn-yellow-primary"
                      onClick={onDialogClose}><a >Ok</a></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}


    </>

  );
}

export default TrancheInfoReapplyComponent;



