import React, { Component, createRef, useState } from "react";
import homeseedfundneed from "../../images/seedfund-need.svg";
import whatsstartupindia from "../../images/whatsstartupindia.svg";
import seedfund from "../../images/seedfund.svg";
import objectives from "../../images/objectives.svg";
import Toparrow from "../../images/Toparrow.svg";
import Plus from "../../images/Plus.svg";
import Minus from "../../images/Minus.svg";
import { Link } from "react-scroll";
import logo from "./logo.svg";
import "../../styles/component.css";
import { Link as Route } from "react-router-dom";

function Startup() {
  const [activeIndex, setActiveIndex] = useState("");
  const changeActive = (id: string) => {
    setActiveIndex(id);
  };
  const changeActiveBack = () => {
    setActiveIndex("");
  };
  return (
    <div className="">
      <div className="row about-content sticky-content" id="startup">
        <div className="col-sm-12 col-md-4 sticky-dropdown-section">
          <div className="d-block d-sm-block d-md-none">
            <div className="sticky-dropdown">
              <div className="dropdown">
                <button
                  className="btn btn-large btn-sisfs btn-transparent-primary dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                >
                  The Scheme and Eligibility Criteria
                  <span className="caret"></span>
                </button>
                <ul className="about-list dropdown-menu">
                  <li className="active">
                    <Link
                      activeClass="active"
                      to="what-startup-1"
                      offset={-125}
                      spy={true}
                      smooth={true}
                    >
                      The Scheme and Eligibility Criteria
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeClass="active"
                      to="what-startup-2"
                      offset={-125}
                      spy={true}
                      smooth={true}
                    >
                      Application Process
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeClass="active"
                      to="what-startup-3"
                      offset={-125}
                      spy={true}
                      smooth={true}
                    >
                      Evaluation Process
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeClass="active"
                      to="what-startup-4"
                      offset={-125}
                      spy={true}
                      smooth={true}
                    >
                      After Selection of Startups
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <ul className="about-list d-none d-sm-none d-md-block about-page">
            <li className="active">
              <Link
                activeClass="active"
                to="what-startup-1"
                offset={-130}
                spy={true}
                smooth={true}
              >
                The Scheme and Eligibility Criteria
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="what-startup-2"
                offset={-130}
                spy={true}
                smooth={true}
              >
                Application Process
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="what-startup-3"
                offset={-130}
                spy={true}
                smooth={true}
              >
                Evaluation Process
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="what-startup-4"
                offset={-130}
                spy={true}
                smooth={true}
              >
                After Selection of Startups
              </Link>
            </li>
          </ul>
        </div>
        <div className="right-cont col-xs-12 col-sm-12 col-md-8">
          <div id="what-startup-1" className="right-cols">
            <div className="main-heading right-heading">
              The Scheme and Eligibility Criteria
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-1"
                  className={
                    activeIndex == "1"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  What is Startup India Seed Fund Scheme?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "1" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-1"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-1"
                      className="hide-icon pull-right"
                      id="show-1"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-1"
                      onClick={() => changeActive("1")}
                      data-toggle="collapse"
                      data-target="#question-1"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-1"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-1">
                Startup India Seed Fund Scheme (SISFS) provides financial
                assistance to startups for proof of concept, prototype
                development, product trials, market-entry, and
                commercialization. Eligible startups can apply for the scheme on
                the Startup India portal. The Seed Fund will be disbursed to
                selected startups through eligible incubators across India.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-2"
                  className={
                    activeIndex == "2"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  Who can apply to SISFS?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "2" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-2"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-2"
                      className="hide-icon pull-right"
                      id="show-2"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-2"
                      onClick={() => changeActive("2")}
                      data-toggle="collapse"
                      data-target="#question-2"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-2"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-2">
                A startup, recognized by DPIIT, incorporated not more than 2
                years ago at the time of application is invited to apply for the
                scheme. Detailed eligibility criteria can be found at{" "}
                <a
                  href="https://seedfund.startupindia.gov.in/about"
                  target="_blank"
                >
                  <u className="link-orange-text">
                    https://seedfund.startupindia.gov.in/about
                  </u>
                </a>
                .<br /> To get DPIIT-recognized, please visit{" "}
                <a
                  href="https://www.startupindia.gov.in/content/sih/en/startupgov/startup-recognition-page.html"
                  target="_blank"
                >
                  <u className="link-orange-text">
                    https://www.startupindia.gov.in/content/sih/en/startupgov/startup-recognition-page.html
                  </u>
                </a>{" "}
              </div>
            </div>
            {/* <div className='question-tab'><div className="row"><div id="q-3" className={activeIndex=="3"?"para-text question-heading-orange col-sm-11":"para-text question-heading col-sm-11"} >What is the quantum and type of seed fund offered to startups under the scheme?</div><div className="col-sm-1">{activeIndex=="3"?<div  onClick={changeActiveBack} data-toggle="collapse" data-target="#question-3" role="button" aria-expanded="false" aria-controls="question-3" className="hide-icon pull-right" id="show-3"><img src={Minus} className="img-fluid" alt="SISFS Logo" /></div>:<div id="hide-3"  onClick={() => changeActive("3")} data-toggle="collapse" data-target="#question-3" role="button" aria-expanded="false" aria-controls="question-3" className="expand-icon pull-right"><img src={Plus} className="img-fluid" alt="SISFS Logo" /></div>}</div></div><div className="collapse para-text help-content" id="question-3">N/A</div></div> */}
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-4"
                  className={
                    activeIndex == "4"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  How much seed funding can a startup receive under the scheme?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "4" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-4"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-4"
                      className="hide-icon pull-right"
                      id="show-4"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-4"
                      onClick={() => changeActive("4")}
                      data-toggle="collapse"
                      data-target="#question-4"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-4"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-4">
                Seed Fund to an eligible startup by the incubator shall be
                disbursed as follows:<br></br>
                <div className="ordered-list wrapper">
                  <ol>
                    <li>
                      Up to Rs. 20 Lakhs as grant for validation of Proof of
                      Concept, or prototype development, or product trials. The
                      grant shall be disbursed in milestone-based installments.
                      These milestones can be related to development of
                      prototype, product testing, building a product ready for
                      market launch, etc.
                    </li>
                    <li>
                      Up to Rs. 50 Lakhs of investment for market entry,
                      commercialization, or scaling up through convertible
                      debentures or debt or debt-linked instruments
                    </li>
                    <li>
                      A startup applicant can avail seed support in the form of
                      grant and debt/convertible debentures each once as per the
                      guidelines of the scheme
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-5"
                  className={
                    activeIndex == "5"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  Can I apply for the scheme as an individual entrepreneur, or
                  do I need a team?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "5" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-5"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-5"
                      className="hide-icon pull-right"
                      id="show-5"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-5"
                      onClick={() => changeActive("5")}
                      data-toggle="collapse"
                      data-target="#question-5"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-5"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-5">
                No, individual entrepreneurs are not eligible to apply for
                support under the scheme. Only DPIIT recognized startups can
                apply for the SISFS. To get DPIIT-recognized, please visit{" "}
                <a
                  href="https://www.startupindia.gov.in/content/sih/en/startupgov/startup-recognition-page.html"
                  target="_blank"
                >
                  <u className="link-orange-text">
                    https://www.startupindia.gov.in/content/sih/en/startupgov/startup-recognition-page.html
                  </u>
                </a>
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-6"
                  className={
                    activeIndex == "6"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  Does the scheme support startups from specific sectors?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "6" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-6"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-6"
                      className="hide-icon pull-right"
                      id="show-6"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-6"
                      onClick={() => changeActive("6")}
                      data-toggle="collapse"
                      data-target="#question-6"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-6"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-6">
                SISFS is a sector agnostic scheme, which means that startups
                from any sector can apply for the scheme. However, preference
                would be given to startups creating innovative solutions in
                sectors such as social impact, waste management, water
                management, financial inclusion, education, agriculture, food
                processing, biotechnology, healthcare, energy, mobility,
                defense, space, railways, oil and gas, textiles, etc. This list
                of sectors is indicative and not exhaustive.
              </div>
            </div>
            {/* <div className='question-tab'><div className="row"><div id="q-7" className={activeIndex=="7"?"para-text question-heading-orange col-sm-11":"para-text question-heading col-sm-11"}>Do I have a disadvantage if I am not from any of the mentioned sectors (social impact, waste management, water management, financial inclusion, education, agriculture, food processing, biotechnology, healthcare, energy, mobility, defense, space, railways, oil and gas, textiles, etc.)?</div><div className="col-sm-1">{activeIndex=="7"?<div  onClick={changeActiveBack} data-toggle="collapse" data-target="#question-7" role="button" aria-expanded="false" aria-controls="question-7" className="hide-icon pull-right" id="show-7"><img src={Minus} className="img-fluid" alt="SISFS Logo" /></div>:<div id="hide-7"  onClick={() => changeActive("7")} data-toggle="collapse" data-target="#question-7" role="button" aria-expanded="false" aria-controls="question-7" className="expand-icon pull-right"><img src={Plus} className="img-fluid" alt="SISFS Logo" /></div>}</div></div><div className="collapse para-text help-content" id="question-7">The List of sectors is indicative and not exhaustive. The SISFS is a sector agnostic scheme. </div></div> */}
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-8"
                  className={
                    activeIndex == "8"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  Are there any minimum education qualification criteria for
                  founders to apply for SISFS?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "8" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-8"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-8"
                      className="hide-icon pull-right"
                      id="show-8"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-8"
                      onClick={() => changeActive("8")}
                      data-toggle="collapse"
                      data-target="#question-8"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-8"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-8">
                There is no minimum education qualification required for
                founders to apply for SISFS
              </div>
            </div>

            <div className="question-tab">
              <div className="row">
                <div
                  id="q-9"
                  className={
                    activeIndex == "9"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  Are there any exemptions to any of the eligibility criteria?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "9" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-9"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-9"
                      className="hide-icon pull-right"
                      id="show-9"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-9"
                      onClick={() => changeActive("9")}
                      data-toggle="collapse"
                      data-target="#question-9"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-9"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-9">
                No, there are no exemptions to any of the eligibility criteria.
                All the criteria must be met on the date of application
                submission.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-10"
                  className={
                    activeIndex == "10"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  What is a DPIIT-recognized startup?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "10" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-10"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-10"
                      className="hide-icon pull-right"
                      id="show-10"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-10"
                      onClick={() => changeActive("10")}
                      data-toggle="collapse"
                      data-target="#question-10"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-10"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-10">
                An entity shall be considered a “Startup” –
                <div className="ordered-list wrapper">
                  <ol>
                    {" "}
                    <li>
                      If it’s incorporated as either Private Limited Company or
                      Registered Partnership Firm or Limited Liability
                      Partnership. A sole proprietorship or a public limited
                      company is not eligible as startup
                    </li>
                    <li>
                      If it is up to 10 years from the date of its
                      incorporation/ registration
                    </li>
                    <li>
                      If its turnover for any of the financial years has not
                      exceeded INR 100 crore
                    </li>
                    <li>
                      If it is working towards innovation, development or
                      improvement of products or processes or services, or if it
                      is a scalable business model with a high potential of
                      employment generation or wealth creation
                    </li>
                    <li>
                      Should not have been formed by splitting up or
                      reconstruction of a business already in existence.
                    </li>
                  </ol>
                </div>
                To get DPIIT-recognized, please visit <br />
                <a
                  href="https://www.startupindia.gov.in/content/sih/en/startupgov/startup-recognition-page.html "
                  target="_blank"
                >
                  <u className="link-orange-text">
                    https://www.startupindia.gov.in/content/sih/en/startupgov/startup-recognition-page.html{" "}
                  </u>
                </a>
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-11"
                  className={
                    activeIndex == "11"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  What all can I use the seed fund for?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "11" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-11"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-11"
                      className="hide-icon pull-right"
                      id="show-11"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-11"
                      onClick={() => changeActive("11")}
                      data-toggle="collapse"
                      data-target="#question-11"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-11"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-11">
                Seed fund shall strictly not be used by startups for creation of
                any facilities and shall be utilized for the purpose it has been
                granted for. A grant can be used for validation of Proof of
                Concept, or prototype development, or product trials. A debt/
                convertible debenture can be used for Market entry,
                Commercialization, or Scaling up.{" "}
              </div>
            </div>
          </div>
          <div id="what-startup-2" className="right-cols">
            <div className="main-heading right-heading">
              Application Process
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-12"
                  className={
                    activeIndex == "12"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  How can I apply to SISFS?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "12" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-12"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-12"
                      className="hide-icon pull-right"
                      id="show-12"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-12"
                      onClick={() => changeActive("12")}
                      data-toggle="collapse"
                      data-target="#question-12"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-12"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-12">
                An online call for applications is hosted on an ongoing basis on
                the Startup India portal will be opened soon. DPIIT-recognized
                startups can login using the credentials used during the startup
                recognition process to apply for the scheme.
              </div>
            </div>
            {/* <div className='question-tab'><div className="row"><div id="q-13" className={activeIndex == "13" ? "para-text question-heading-orange col-sm-11" : "para-text question-heading col-sm-11"}>What is the documentation required for the SISFS application?</div><div className="col-sm-1">{activeIndex == "13" ? <div onClick={changeActiveBack} data-toggle="collapse" data-target="#question-13" role="button" aria-expanded="false" aria-controls="question-13" className="hide-icon pull-right" id="show-13"><img src={Minus} className="img-fluid" alt="SISFS Logo" /></div> : <div id="hide-13" onClick={() => changeActive("13")} data-toggle="collapse" data-target="#question-13" role="button" aria-expanded="false" aria-controls="question-13" className="expand-icon pull-right"><img src={Plus} className="img-fluid" alt="SISFS Logo" /></div>}</div></div><div className="collapse para-text help-content" id="question-13">The application form requires startups to give information about their business idea, how technology is being used in the business, monetary support received previously from the government, etc. Apart from these form fields, below is the list of documents you will need to furnish as part of your application: proofread after application form finalized<br></br><div className="ordered-list wrapper">
                            <ol>                         <li>Pitch deck – A presentation with information such as vision and value proposition, the problem being solved, target market and size of the opportunity, the solution, revenue and business model, product-market fit, Team, Financials, Roadmap, and utilization of fund with a timeline</li>
                                <li>Current Cap Table</li>
                                <li>Patent and IP proof (if any)</li>
                                <li>Financial statements (if any)</li>
                                <li>Any other required document</li></ol></div>
                            The document submission is completely online, and no physical submission of documents is required.
</div></div> */}
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-14"
                  className={
                    activeIndex == "14"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  Is the SISFS application process completely online?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "14" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-14"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-14"
                      className="hide-icon pull-right"
                      id="show-14"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-14"
                      onClick={() => changeActive("14")}
                      data-toggle="collapse"
                      data-target="#question-14"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-14"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-14">
                The application submission is completely online, and no physical
                submission of documents is required.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-15"
                  className={
                    activeIndex == "15"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  Is there a fee for applying to this scheme?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "15" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-15"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-15"
                      className="hide-icon pull-right"
                      id="show-15"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-15"
                      onClick={() => changeActive("15")}
                      data-toggle="collapse"
                      data-target="#question-15"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-15"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-15">
                There are no application fees for the scheme. Even after the
                selection of a startup by an incubator for assistance under this
                scheme, the startup shall not be charged any fees. The incubator
                or any of its staff members shall not charge any fee in cash or
                in kind from applicants or beneficiaries under the scheme for
                any process of selection, disbursement, incubation, or
                monitoring.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-16"
                  className={
                    activeIndex == "16"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  Why is the application form allowing me to apply to 3
                  incubators?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "16" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-16"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-16"
                      className="hide-icon pull-right"
                      id="show-16"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-16"
                      onClick={() => changeActive("16")}
                      data-toggle="collapse"
                      data-target="#question-16"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-16"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-16">
                The scheme aims to maximize the chances of each startup
                applicant getting supported through seed funds. It also aims to
                give startups an opportunity to get supported by a relevant
                incubator that can give the necessary guidance to them. Thus, we
                give startups an option to apply to 3 different incubators
                according to their preference. For example, if incubators at
                Preference 1 and Preference 2 both select a startup, the funding
                shall be given by Preference 1 incubator. If Preference 1
                incubator rejects and Preference 2 incubator selects, the
                funding shall be given by incubator at Preference 2, and so on.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-17"
                  className={
                    activeIndex == "17"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  How do I choose the incubators to apply to?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "17" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-17"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-17"
                      className="hide-icon pull-right"
                      id="show-17"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-17"
                      onClick={() => changeActive("17")}
                      data-toggle="collapse"
                      data-target="#question-17"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-17"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-17">
                The incubator preference should be filled at the startup
                applicants’ discretion. Applicants can choose incubators basis
                their sector, stage, business needs, and strategic goals.
                Details of the incubators which are part of the scheme will be
                available on Seed Fund Portal soon.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-18"
                  className={
                    activeIndex == "18"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  I can see an option to apply either for a grant or convertible
                  debenture or loan instruments. What is the difference between
                  these? How do I choose?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "18" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-18"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-18"
                      className="hide-icon pull-right"
                      id="show-18"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-18"
                      onClick={() => changeActive("18")}
                      data-toggle="collapse"
                      data-target="#question-18"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-18"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-18">
                A grant and debt/convertible debenture are different financing
                instruments to cater different startup needs. The following
                table can help an applicant startup decide which instrument
                shall suit their needs better. The final decision on this should
                be at the startup applicants’ discretion.
                <br></br>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Parameter</th>
                        <th>Grant</th>
                        <th>Debt/Convertible Debenture</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Stage</td>
                        <td>Ideation Stage</td>
                        <td>Commercialization & Scale-up stage</td>
                      </tr>
                      <tr>
                        <td>Need Catered by the fund</td>
                        <td>
                          Validation of Proof of Concept, or Prototype
                          development, or Product trials
                        </td>
                        <td>Market entry, Commercialization, or Scaling up</td>
                      </tr>
                      <tr>
                        <td>Max. funding amount</td>
                        <td>Upto Rs. 20 Lakhs</td>
                        <td>Upto Rs. 50 Lakhs</td>
                      </tr>
                      <tr>
                        <td>Financing Terms</td>
                        <td>
                          Under this scheme, the grant will be disbursed in
                          milestone-based installments. These milestones can be
                          related to development of prototype, product testing,
                          building a product ready for market launch, etc.
                        </td>
                        <td>
                          For startups being supported through convertible
                          debentures, or debt, or debt-linked instruments, funds
                          shall be provided at a rate of interest of not more
                          than prevailing repo rate. The tenure should be fixed
                          at the time of sanctioning the loan by the incubator,
                          which shall be not more than 60 months (5 years). A
                          moratorium of up to 12 months may be provided for the
                          startups. Because of the early stage of the startups,
                          this shall be unsecured and no guarantee from promoter
                          or third-party will be required.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-19"
                  className={
                    activeIndex == "19"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  What are the terms and conditions of seed fund under the
                  scheme?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "19" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-19"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-19"
                      className="hide-icon pull-right"
                      id="show-19"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-19"
                      onClick={() => changeActive("19")}
                      data-toggle="collapse"
                      data-target="#question-19"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-19"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-19">
                The scheme guidelines are available at{" "}
                <a
                  href="https://www.startupindia.gov.in/content/dam/invest-india/Templates/public/Guidelines%20for%20Startup%20India%20Seed%20Fund%20Scheme.pdf"
                  target="_blank"
                >
                  <u className="link-orange-text">
                    https://www.startupindia.gov.in/content/dam/invest-india/Templates/public/Guidelines%20for%20Startup%20India%20Seed%20Fund%20Scheme.pdf{" "}
                  </u>
                </a>
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-20"
                  className={
                    activeIndex == "20"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  Is the information provided by me in the application form kept
                  confidential?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "20" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-20"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-20"
                      className="hide-icon pull-right"
                      id="show-20"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-20"
                      onClick={() => changeActive("20")}
                      data-toggle="collapse"
                      data-target="#question-20"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-20"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-20">
                We maintain confidentiality of all the proposals we receive
                under the scheme. Your application is only shared with the
                incubators you apply to for the purpose of evaluation, and with
                the EAC for the purpose of monitoring.{" "}
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-21"
                  className={
                    activeIndex == "21"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  Do I need to be physically present at an incubator’s’ premises
                  to avail seed funds under this scheme?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "21" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-21"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-21"
                      className="hide-icon pull-right"
                      id="show-21"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-21"
                      onClick={() => changeActive("21")}
                      data-toggle="collapse"
                      data-target="#question-21"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-21"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-21">
                To apply for the scheme, it is not mandatory to be physically
                present at the incubator’s location. For startups being
                monitored virtually by the incubator, it is required for both
                parties to touch base every 30 days. This is to ensure that an
                incubator will be able to keep a check on the progress of the
                startup and a startup will be able to take guidance for their
                business from the incubator.
              </div>
            </div>

            <div className="question-tab">
              <div className="row">
                <div
                  id="q-22"
                  className={
                    activeIndex == "22"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  Can I apply to the Startup India Seed Fund Scheme again after
                  receiving a rejection?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "22" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-22"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-22"
                      className="hide-icon pull-right"
                      id="show-22"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-22"
                      onClick={() => changeActive("22")}
                      data-toggle="collapse"
                      data-target="#question-22"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-22"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-22">
                Yes, you can apply to the SISFS again after 6 Weeks of
                receiving a rejection. This buffer time is given to ensure that
                you have worked on the feedback received from incubators and are
                ready to be considered again.
              </div>
            </div>
          </div>
          <div id="what-startup-3" className="right-cols">
            <div className="main-heading right-heading">Evaluation Process</div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-23"
                  className={
                    activeIndex == "23"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  Who is going to review my application?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "23" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-23"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-23"
                      className="hide-icon pull-right"
                      id="show-23"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-23"
                      onClick={() => changeActive("23")}
                      data-toggle="collapse"
                      data-target="#question-23"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-23"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-23">
                Application of each startup will be reviewed by an Incubator
                Seed Management Committee (ISMC) formed by the incubators you
                apply to. The committee will also be responsible for future
                assessment of the performance of the startup and disbursement of
                further tranches. Each ISMC constitutes of the following
                members:
                <div className="ordered-list wrapper">
                  <ol>
                    <li> Nominee of Incubator (Chairman)</li>
                    <li>
                      {" "}
                      A representative from the State Government’s Startup Nodal
                      Team
                    </li>
                    <li>
                      {" "}
                      A representative of a Venture Capital Fund or Angel
                      Network
                    </li>
                    <li> A domain expert from Industry</li>
                    <li> A domain expert from academia</li>
                    <li> Two successful Entrepreneurs</li>
                    <li> Any other relevant Stakeholder</li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-24"
                  className={
                    activeIndex == "24"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  What are the parameters for evaluation of startup applicants?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "24" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-24"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-24"
                      className="hide-icon pull-right"
                      id="show-24"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-24"
                      onClick={() => changeActive("24")}
                      data-toggle="collapse"
                      data-target="#question-24"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-24"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-24">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Criteria</th>
                        <th>Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Is there a need for this Idea?</td>
                        <td>
                          Market size, what market gap is it filling, does it
                          solve a real-world problem?
                        </td>
                      </tr>
                      <tr>
                        <td>Feasibility</td>
                        <td>
                          Feasibility and reasonability of the technical claims,
                          the methodology used/ to be used for PoC and
                          validation, a roadmap for product development
                        </td>
                      </tr>
                      <tr>
                        <td>Potential Impact</td>
                        <td>
                          Customer demographic & the technology’s effect on
                          these, national importance (if any)
                        </td>
                      </tr>
                      <tr>
                        <td>Novelty</td>
                        <td>USP of the technology, associated IP </td>
                      </tr>
                      <tr>
                        <td>Team</td>
                        <td>
                          Strength of the team, Technical and business expertise
                        </td>
                      </tr>
                      <tr>
                        <td>Fund Utilization Plan</td>
                        <td>Roadmap of money utilization</td>
                      </tr>
                      <tr>
                        <td>Additional Parameters</td>
                        <td>
                          Any additional parameters considered appropriate by
                          the incubator
                        </td>
                      </tr>
                      <tr>
                        <td>Presentation</td>
                        <td>Overall assessment</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-25"
                  className={
                    activeIndex == "25"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  What is the process of evaluation?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "25" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-25"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-25"
                      className="hide-icon pull-right"
                      id="show-25"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-25"
                      onClick={() => changeActive("25")}
                      data-toggle="collapse"
                      data-target="#question-25"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-25"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-25">
                The startups shall be selected through an open, transparent and
                fair process, comprising, inter-alia:
                <div className="ordered-list wrapper">
                  <ol>
                    <li>
                      Startups submit their application on the Startup India
                      portal and an email confirmation is shared with them.
                      Applicants can apply for seed fund to any three incubators
                      selected as disbursing partners for this scheme in order
                      of their preference.
                    </li>
                    <li>
                      All applications received will be shared online with
                      respective incubators for further evaluation.
                    </li>
                    <li>
                      For all incomplete applications, a prompt of resubmission
                      will be sent to the startup.{" "}
                    </li>
                    <li>
                      The incubators shall shortlist applicants as per
                      eligibility criteria
                    </li>
                    <li>
                      Eligible applications will be evaluated by Incubator Seed
                      Management Committee (ISMC) using the following criteria:
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Criteria</th>
                              <th>Details</th>
                              <th>Weightage (%)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>Is there a need for this Idea?</td>
                              <td>
                                Market size, what market gap is it filling, does
                                it solve a real-world problem?
                              </td>
                              <td>p</td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td>Feasibility</td>
                              <td>
                                Feasibility and reasonability of the technical
                                claims, methodology used/ to be used for PoC and
                                validation, roadmap for product development
                              </td>
                              <td>q</td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td>Potential Impact</td>
                              <td>
                                Customer demographic & the technology’s effect
                                on these, national importance (if any)
                              </td>
                              <td>r</td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td>Novelty</td>
                              <td>USP of the technology, associated IP </td>
                              <td>s</td>
                            </tr>
                            <tr>
                              <td>5</td>
                              <td>Team</td>
                              <td>
                                Strength of the team, Technical and business
                                expertise{" "}
                              </td>
                              <td>t</td>
                            </tr>
                            <tr>
                              <td>6</td>
                              <td>Fund Utilization Plan</td>
                              <td>Roadmap of money utilization </td>
                              <td>u</td>
                            </tr>
                            <tr>
                              <td>7</td>
                              <td>Additional Parameters</td>
                              <td>
                                Any additional parameters considered appropriate
                                by incubator{" "}
                              </td>
                              <td>v</td>
                            </tr>
                            <tr>
                              <td>8</td>
                              <td>Presentation</td>
                              <td>USP of the technology, associated IP </td>
                              <td>Overall assessment</td>
                            </tr>
                            <tr>
                              <td colSpan={3}></td>
                              <td>100%</td>
                            </tr>
                            <tr>
                              <td colSpan={4}>
                                Weightages for criteria (p, q, r, s, t, u, v, w)
                                may be assigned by each incubator differently
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </li>
                    <li>
                      Incubator may shortlist applicants based on their
                      evaluation for a presentation before ISMC
                    </li>
                    <li>
                      ISMC shall evaluate applicants based on their submissions
                      and presentations and select startups for Seed Fund within
                      45 days of receipt of application
                    </li>
                    <li>
                      Selected startups shall receive seed funding under the
                      respective incubator that selects them as beneficiaries as
                      per their preference shared during application (for
                      example, if incubators at Preference 1 and Preference 2
                      both select a startup, the funding shall be given by
                      Preference 1 incubator. If Preference 1 incubator rejects
                      and Preference 2 incubator selects, the funding shall be
                      given by incubator at Preference 2, and so on.){" "}
                    </li>
                    <li>
                      All applicants will be able to track the progress of their
                      application on the Startup India portal on a real-time
                      basis
                    </li>
                    <li>
                      Applicants who are rejected will also be notified through
                      email
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-26"
                  className={
                    activeIndex == "26"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  How much time will it take for my application to get
                  processed?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "26" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-26"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-26"
                      className="hide-icon pull-right"
                      id="show-26"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-26"
                      onClick={() => changeActive("26")}
                      data-toggle="collapse"
                      data-target="#question-26"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-26"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-26">
                After the receipt of the application, each Incubator shall
                evaluate applicants based on their submissions and presentations
                and select startups for Seed Fund within 45 days
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-27"
                  className={
                    activeIndex == "27"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  How can I track the status of my application?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "27" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-27"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-27"
                      className="hide-icon pull-right"
                      id="show-27"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-27"
                      onClick={() => changeActive("27")}
                      data-toggle="collapse"
                      data-target="#question-27"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-2"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-27">
                Once the startup has submitted its seed fund scheme application,
                a dashboard can be accessed using their login credential to
                check the real-time application status
              </div>
            </div>
          </div>
          <div id="what-startup-4" className="right-cols">
            <div className="main-heading right-heading">
              After Selection of Startups
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-28"
                  className={
                    activeIndex == "28"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  I had filled an order of preference for incubators while
                  applying. Can I change this preference order after
                  application?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "28" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-28"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-28"
                      className="hide-icon pull-right"
                      id="show-28"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-28"
                      onClick={() => changeActive("28")}
                      data-toggle="collapse"
                      data-target="#question-28"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-28"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-28">
                Applications once filled and submitted are final. The preference
                order of the incubator cannot be changed at a later stage.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-29"
                  className={
                    activeIndex == "29"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  I have been selected for seed fund, but the quantum of fund/
                  terms and conditions being set by the incubator are not
                  agreeable to me. What should I do?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "29" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-29"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-29"
                      className="hide-icon pull-right"
                      id="show-29"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-29"
                      onClick={() => changeActive("29")}
                      data-toggle="collapse"
                      data-target="#question-29"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-29"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-29">
                The quantum and terms for the seed fund assistance are to be
                negotiated between the startup and the incubator only. The
                mandatory elements of the scheme can be seen in the Guidelines
                on{" "}
                <a
                  href="https://dipp.gov.in/sites/default/files/Guidelines-FundScheme-Startup-29January2021.pdf"
                  target="_blank"
                >
                  <u className="link-orange-text">
                    https://dipp.gov.in/sites/default/files/Guidelines-FundScheme-Startup-29January2021.pdf
                  </u>
                </a>
                , anything beyond this can be negotiated. In case of any issue,
                kindly reach out to us using grievance form at{" "}
                <Route
                  to={{
                    pathname: "/contact",
                    state: { selected_tab: "grievance" },
                  }}
                >
                  <u className="link-orange-text">
                    https://seedfund.startupindia.gov.in/contact
                  </u>{" "}
                </Route>
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-30"
                  className={
                    activeIndex == "30"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  I need to hire a legal or financial consultant for my seed
                  fund discussions with the incubator. Who will bear the cost of
                  these services?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "30" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-30"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-30"
                      className="hide-icon pull-right"
                      id="show-30"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-30"
                      onClick={() => changeActive("30")}
                      data-toggle="collapse"
                      data-target="#question-30"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-30"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-30">
                The startup shall bear the cost of any consultants required to
                guide them through documentation, negotiations with incubator,
                signing of agreement with incubator, reporting progress after
                approval of seed fund, etc. Hiring such an external consultant
                is not mandatory at all, and shall be at the discretion of the
                startup.{" "}
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-31"
                  className={
                    activeIndex == "31"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  I have been selected for seed fund. Is it mandatory to sign a
                  legal agreement with the incubator?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "31" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-31"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-31"
                      className="hide-icon pull-right"
                      id="show-31"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-31"
                      onClick={() => changeActive("31")}
                      data-toggle="collapse"
                      data-target="#question-31"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-31"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-31">
                Yes, it is mandatory to sign a legal agreement with the
                incubator to avail the seed fund you have been granted.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-32"
                  className={
                    activeIndex == "32"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  I had applied for a certain amount of seed fund, but the
                  approved amount is lower/higher. Can I negotiate this?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "32" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-32"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-32"
                      className="hide-icon pull-right"
                      id="show-32"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-32"
                      onClick={() => changeActive("32")}
                      data-toggle="collapse"
                      data-target="#question-32"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-32"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-32">
                The applicant can discuss the quantum of seed fund and the
                milestones associated with the tranches to be disbursed with the
                Incubator Seed Management Committee. The decision of the
                Committee shall be final.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-33"
                  className={
                    activeIndex == "33"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  Will my living expenses be borne if I want to be physically
                  incubated at the incubator that is funding me?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "33" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-33"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-33"
                      className="hide-icon pull-right"
                      id="show-33"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-33"
                      onClick={() => changeActive("33")}
                      data-toggle="collapse"
                      data-target="#question-33"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-33"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-33">
                The selected incubator shall provide physical infrastructure to
                the selected startups for regular functioning, support for
                testing and validating ideas, mentoring for prototype or product
                development or commercialization, and developing capacities in
                finance, human resources, legal compliances, and other
                functions. They are also expected to provide networking with
                investors and opportunities for showcasing in various national
                and international events. Any other expenses, including living
                expenses, shall be borne by the startup.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-34"
                  className={
                    activeIndex == "34"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  How much time will it take for the first installment of the
                  seed fund to be released to me?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "34" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-34"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-34"
                      className="hide-icon pull-right"
                      id="show-34"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-34"
                      onClick={() => changeActive("34")}
                      data-toggle="collapse"
                      data-target="#question-34"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-34"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-34">
                For grants, the first installment to any selected startup shall
                be released not more than 60 days from receipt of application
                from the startup. The startup shall submit the interim progress
                update and utilization certificate to initiate the release of
                subsequent installment of grant. For debt or convertible
                debentures, a similar timeline will be aimed but it is
                understood that due diligence and documentation in these cases
                can be sometimes time-consuming.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-35"
                  className={
                    activeIndex == "35"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  Where can I receive the seed funds?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "35" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-35"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-35"
                      className="hide-icon pull-right"
                      id="show-35"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-35"
                      onClick={() => changeActive("35")}
                      data-toggle="collapse"
                      data-target="#question-35"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-35"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-35">
                Startups will receive the funds in their company bank accounts.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-36"
                  className={
                    activeIndex == "36"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  How often do I have to report back my progress after receiving
                  seed fund?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "36" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-36"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-36"
                      className="hide-icon pull-right"
                      id="show-36"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-36"
                      onClick={() => changeActive("36")}
                      data-toggle="collapse"
                      data-target="#question-36"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-36"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-36">
                Each startup will be required to touch base with the incubator
                team and share updates with them at least once in 15 days via
                videoconferences or physical meetings. These updates should be
                shared on the scheme dashboard at least on a monthly basis. In
                case of milestone-based disbursements, startup shall submit the
                interim progress update and utilisation certificate to initiate
                the release of subsequent installment of grant. Startup shall
                also submit final report and audited utilisation certificate at
                the end of the project duration.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-37"
                  className={
                    activeIndex == "37"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  I have been selected for seed fund, but I would not like to
                  take the process forward. What do I do?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "37" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-37"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-37"
                      className="hide-icon pull-right"
                      id="show-37"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-37"
                      onClick={() => changeActive("37")}
                      data-toggle="collapse"
                      data-target="#question-37"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-37"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-37">
                A startup can choose to not avail the support being offered to
                them under the scheme. The procedure for the same depends on the
                stage of the startup in the application process.
                <div className="ordered-list wrapper">
                  <ol>
                    <li>
                      If the startup has been selected and the seed funding has
                      been approved, they will be required to write a short
                      letter mentioning the reason to pull out of the process.
                      Post the acknowledgment of the letter, they will find a
                      cancel application button on the application tracker tab
                      of their dashboard which will allow them to cancel their
                      application
                    </li>
                    <li>
                      If the startup has already received any seed fund, it will
                      be required to write a short letter mentioning the reason
                      to pull out of the process. Post acknowledgment of the
                      letter, they will be required to return the fund within 15
                      days. Post the acknowledgment of refund, they will find a
                      cancel application button on the application tracker tab
                      of their dashboard
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-38"
                  className={
                    activeIndex == "38"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  What if my startup fails after receiving the seed fund? Will
                  there be any liability on me or my company?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "38" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-38"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-38"
                      className="hide-icon pull-right"
                      id="show-38"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-38"
                      onClick={() => changeActive("38")}
                      data-toggle="collapse"
                      data-target="#question-38"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-38"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-38">
                It is understood that every startup cannot be successful. The
                legal agreement that you sign with the incubator will have
                provisions related to failure of startups supported under the
                scheme. For failed ventures, the entrepreneur shall share
                his/her learnings and the reasons for failure in the report and
                submit this along with the utilization certificate for the fund
                amount.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-39"
                  className={
                    activeIndex == "39"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  I have some complaints regarding the incubator funding me. Who
                  shall I reach out to?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "39" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-39"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-39"
                      className="hide-icon pull-right"
                      id="show-39"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-39"
                      onClick={() => changeActive("39")}
                      data-toggle="collapse"
                      data-target="#question-39"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-39"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-39">
                In case of any issue, kindly reach out to us using grievance
                form at{" "}
                <Route
                  to={{
                    pathname: "/contact",
                    state: { selected_tab: "grievance" },
                  }}
                >
                  <u className="link-orange-text">
                    https://seedfund.startupindia.gov.in/contact
                  </u>{" "}
                </Route>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Startup;
