
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { get, post } from "../../effects/api/api.common";
import FooterComponent from "../common/footer/FooterComponent";
import HeaderComponent from "../common/header/HeaderComponent";
function ErrorGlobal() {
    const history = useHistory(); 
    return (
        <>
        <HeaderComponent />
       <div className="container">
           <div className="error-wrapper">
               <h2 className="error-text">404 Page Not Found</h2>
           </div>
       </div>
        {/* <button onClick={() => history.goBack()}>Back</button> */}
        <FooterComponent />
        </>
    )
};

export default ErrorGlobal;