import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { post } from '../../../effects/api/api.common';
import {NEWSLETTER_SUBSCRIBE} from "../../../constants/api";
import ConfirmationDialog from '../controls/ConfirmationDialog';
declare var $: any;
function FooterComponent() {
    
  const [showIncubatorFailureModal, setShowIncubatorFailureModal] = useState(false);  
  const [showIncubatorSuccessModal, setShowIncubatorSuccessModal] = useState(false);
  const [dialogData, setDialogData] = useState({
    "header": '',
    "text": ''
  });
  const [failDialogData, setFailDialogData] = useState({
    "header": '',
    "text": ''
  });
    const [email, setEmail] = useState('');
    useEffect(() => {
      }, []);

      const handleSubscribe = (e:any) => {
          setEmail(e.target.value);        
      }

      const subscribe = () => {
        // console.log(email);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const CheckEmail = emailRegex.test(email)
        // console.log(CheckEmail);
        // return;
        if(CheckEmail == false){
            $('#Subscrib_Validation_Poppup').modal('show');
        }else{
            post(NEWSLETTER_SUBSCRIBE, {"email":email}).then(
                (response: any) => {
                  if (!response || !response.data) return;
                  if(response.data.result=="success"){
                      setDialogData({
                        "header": "Thank You",
                        "text": response.data.message
                      })
                      setShowIncubatorSuccessModal(true);
    
                  }
                  else{
                    setFailDialogData({
                        "header": "Sorry",
                        "text": response.data.message
                      })
                      setShowIncubatorFailureModal(true);
                  }
                })
        }
        
      }
      const dismissModal = () => {
        setShowIncubatorSuccessModal(false);
        setShowIncubatorFailureModal(false);
        setEmail('')
    
      }
    return (
        <footer className="app-footer">
            <div className="container">
                <div className="master-footer">

                    <div className="row footer-top">
                        <div className="col-xs-12 col-sm-6 col-lg-3">
                            <div className="footer-links">
                                <h6 className="footer-heading">About</h6>
                                <ul>
                                    <li><Link to={{ pathname: "/about", state: { selected_tab: "whatstartup" } }}>
                                        Startup India   </Link></li>
                                    <li><Link to={{ pathname: "/about", state: { selected_tab: "sfs" } }}>
                                        Startup India Seed Fund Scheme (SISFS)  </Link></li>
                                    <li><Link to={{ pathname: "/about", state: { selected_tab: "objective" } }}>
                                        Objectives   </Link></li>
                                    <li><Link to={{ pathname: "/about", state: { selected_tab: "expert" } }}>
                                        Experts Advisory Committee (EAC)   </Link></li>
                                    <li><Link to={{ pathname: "/about", state: { selected_tab: "startup" } }}>
                                        Eligibility Criteria   </Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6 col-lg-2">
                            <div className="footer-links">
                                <h6 className="footer-heading">Help</h6>
                                <ul>
                                    <li>
                                        <Link role="menuitem" to="/faq">F.A.Q.</Link>
                                    </li>
                                    <li>                                      
                                        <Link role="menuitem" to="/glossary">Glossary</Link>
                                    </li>
                                    <li>
                                        <Link role="menuitem" to="/contact">Contact Us</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6 col-lg-2">
                            <div className="footer-links">
                                <h6 className="footer-heading">Join</h6>
                                <ul>
                                    <li><a data-toggle="modal" data-target="#ApplyNowModal">Startup</a></li>
                                    <li><a data-toggle="modal" data-target="#ApplyNowModal">Incubator</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6 col-lg-4">
                            <div className="footer-links">
                                <div className="footer-subscribe mb-4">
                                    <h6 className="footer-heading mb-3">Subscribe to our Newsletters</h6>
                                    <div className="form-inline">
                                        <div className="form-group">
                                            <input type="text" name="email" id="email" onChange={handleSubscribe} value={email} className="form-control" placeholder="email@seedfund.com" />
                                            <button className="btn btn-link" onClick={subscribe}>Subscribe!</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="footer-socialicons">
                                    <h6 className="footer-heading">Follow us</h6>
                                    <ul>
                                        <li
                                        ><a aria-label="Twitter, Startup India Seed Fund Scheme" className="s-tw" href="https://twitter.com/startupindia?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor" target="_blank">
                                                <span>&nbsp;</span> </a></li>
                                        <li><a aria-label="Facebook, Startup India Seed Fund Scheme" className="s-fb" href="https://www.facebook.com/StartupIndia/" target="_blank"><span>&nbsp;</span> </a></li>
                                        <li><a aria-label="Youtube, Startup India Seed Fund Scheme" className="s-yt" href="https://www.youtube.com/channel/UCqFb-ynHTJ6_YRbdn-sFUvg/featured" target="_blank"><span>&nbsp;</span> </a></li>
                                    </ul>
                                </div>
                                <div className="footer-links">
                                <ul style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',flexWrap:'wrap'}}>
                                    <li><a href='https://www.startupindia.gov.in/content/sih/en/terms-of-use.html' target='_blank'>Terms of Use</a></li>
                                    <li><a href='https://www.startupindia.gov.in/content/sih/en/privacy-policy.html' target='_blank'>Privacy Policy</a></li>
                                    <li><a href='https://www.startupindia.gov.in/content/sih/en/disclaimer.html' target='_blank'>Disclaimer</a></li>
                                </ul>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className="row footer-bottom">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                            <div className="footer-copyright">
                                Copyright © 2021 Startup India Seed Fund Scheme. All rights reserved.
                            </div>
                        </div>

                        {/* <div className="col-xs-12 col-sm-6 col-lg-5">
                            <div className="footer-privacylinks clearfix">
                                <ul className="pull-right">
                                    <li><a href="">Privacy Policy</a></li>
                                    <li><a href="">Terms of Use</a></li>
                                    <li><a href="">Cookie Notice</a></li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

{/* Footer Subscrib Validation Poppup */}
            <div className="modal fade" id="Subscrib_Validation_Poppup" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal align-middle modal-sisfs d-block" id="FormSubmissionError">
                  <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                      <div style={{ textAlign: 'right', }}>
                        <i className="fa fa-close" data-dismiss="modal" style={{ cursor: 'pointer', fontSize: '30px', marginRight: '15px', marginTop: '15px', color: '#D3D3D3' }}></i>
                      </div>
                      <div className="modal-body text-center pt-0">
                        <h4 className="modal-message" style={{ color: '#2b3f88', }}>
                          {/* message-orange */}
                          Email Invalid
                        </h4>
                        <div className="modal-action">
                          <button className="btn btn-sisfs btn-yellow-primary" data-dismiss="modal">
                            OK!
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

            {showIncubatorFailureModal && (
        // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
        // <>
        //   <div className="modal-backdrop fade show"></div>
        //   <div
        //     className="modal align-middle modal-sisfs d-block"
        //     id="FormSubmissionError"
        //   >
        //     <div className="modal-dialog modal-dialog-centered modal-md">
        //       <div className="modal-content">
        //         <div className="modal-body text-center">
        //         <img
        //       src={error}
        //       className="search mb-2"
        //       alt="search"

        //     />
        //           <h3 className="modal-message">
        //             {failDialogData.header}
        //                       </h3>
        //           <h3 className="modal-text">
        //           {failDialogData.text}
        //                       </h3>
        //           <div className="modal-action">
        //             <button className="btn btn-sisfs btn-yellow-primary" onClick={() => setShowIncubatorFailureModal(false)}>
        //               OK!
        //                       </button>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // </>
        <ConfirmationDialog
          headerText={failDialogData.header}
          bodyText={failDialogData.text}
          onDialogClose={() => setShowIncubatorFailureModal(false)}
          iconClass="test"
        />
      )}
      {showIncubatorSuccessModal && (
        // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
        // <>
        //   <div className="modal-backdrop fade show"></div>

        //   <div
        //     className="modal align-middle modal-sisfs d-block"
        //     id="FormSubmissionSuccess"
        //   >
        //     <div className="modal-dialog modal-dialog-centered modal-md">
        //       <div className="modal-content">
        //         <div className="modal-body text-center">
        //           <i className="fa fa-check-circle-o"></i>
        //           <h3 className="modal-message">Thank You!</h3>
        //           <div className="modal-text">
        //             Your Meeting Has been Scheduled
        //                         </div>
        //           <div className="modal-action">
        //             <button className="btn btn-sisfs btn-yellow-primary" onClick={dismissModal}>
        //               Ok!!
        //                         </button>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // </>

        
        <ConfirmationDialog
          headerText={dialogData.header}
          bodyText={dialogData.text}
          onDialogClose={dismissModal}
        />
      )}

        </footer>
    )
}

export default FooterComponent;