import axios from "axios";
import { GET_ALL_DISTRICTS, GET_ALL_STATES, LOGIN } from "../../constants/api";
import { LoginModel } from "../../models/LoginModel";
import UserInfoModel from "../../models/UserInfoModel";
import {
  getSessionStorageValue,
  setSessionStorageValue,
} from "../utils/session-storage";

// urls which are to be excluded from authentiction process
const noAuth = ["/login"];

// Add a request interceptor
axios.interceptors.request.use(
  (config: any) => {
    const isAuthRequired: any = noAuth.find((item: any) =>
      config.url.includes(item)
    );

    if (!isAuthRequired) {
      const user = getSessionStorageValue("user");
      if (user) {
        const userInfo = JSON.parse(user) as UserInfoModel;
        config.headers.Authorization = "Bearer " + userInfo?.accessToken;
      }
    }
    
    return config;
  },
  (err) => {
    console.error("Error status code:", err.response.status);
    return err;
  }
);

// perform login
// export const login = (payload: LoginModel) => {
//     return axios.post(LOGIN, payload).then(resp => {
//             console.log(resp);

//             const sessionStorageUserData = resp.data.access_token;

//             setSessionStorageValue('access_token', sessionStorageUserData);

//             return resp;

//         }, err => {

//             console.error(err);

//             return err;
//         })

// }

/** get http verb request */
export const get = (url: string) => {
  return axios.get(url).then(
    (resp) => {
      return resp;
    },
    (err) => {
      return err;
    }
  );
};

/**  post request using http verb */
export const post = (url: string, payload: any) => {
  return axios.post(url, payload).then(
    (resp) => {
      // console.log(resp.status);

      // const sessionStorageUserData = resp.data.access_token;

      // setSessionStorageValue('access_token', sessionStorageUserData);

      return resp;
    },
    (err) => {
      console.error(err);
      console.log(err.response.status,err.response)
      if(err.response.status == 406){
        alert('Session expired. Please re-login to continue.')
        window.location.href = '/home';
        localStorage.removeItem("user");
      }
      return err;
    }
  );
};

export const getAllDistrict = (state_id: string) => {
  return axios.get(GET_ALL_DISTRICTS + state_id).then(
    (resp) => {
      return resp;
    },
    (err) => {
      return err;
    }
  );
};
