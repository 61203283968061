
import { useEffect, useState } from "react";
import {
    GET_ALL_STATES, GET_ALL_DISTRICTS, CONTACT_US, CONTACT_STATE, CONTACT_CITY
} from "../../constants/api";
import { get, post } from "../../effects/api/api.common";
import error from '../../images/error.svg';
function ContactUsComponent() {
    const [countryStates, setCountryStates] = useState([]);
    const [showIncubatorFailureModal, setShowIncubatorFailureModal] = useState(false);
    const [showIncubatorSuccessModal, setShowIncubatorSuccessModal] = useState(false);
    const [stateDistricts, setStateDistricts] = useState([]);
    const [showOthers, setShowOthers] = useState(false);
    const [loader, setLoader] = useState(false);

    const [contactData, setContactData] = useState({
        entity_type: '',
        entity_name: '',
        user_name: '',
        email_id: '',
        location_state: '',
        location_city: '',
        query_type: '',
        message: '',
        others: ''
    });
    const [validationFlag, setValidationFlag] = useState({
        entity_type: false,
        entity_name: false,
        user_name: false,
        email_id: false,
        location_state: false,
        location_city: false,
        query_type: false,
        message: false,
        others: false

    });
    useEffect(() => {
        getAllStates();
    }, []);
    const getAllStates = () => {
        get(CONTACT_STATE).then((response: any) => {
            if (!response || !response.data) return;

            const states = response.data.data.map((item: any) => ({
                label: item.name,
                value: item.id,
            }));
            setCountryStates(states);
        });
    };

    function validateEmail(emailField: string) {
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

        if (reg.test(emailField) == false) {
            return false;
        }

        return true;

    }
    const getAllDistrictsByStateId = (stateId: string) => {


        get(`${CONTACT_CITY}/${stateId}`).then((response: any) => {
            if (!response || !response?.data?.data) {
                setStateDistricts([]);
                return;
            }

            const districts = response?.data?.data.map((item: any) => ({
                label: item?.name,
                value: item?.id,
            }));
            setStateDistricts(districts);
        });
    };
    const handleChange = (
        evt: React.ChangeEvent<
            HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
        >
    ) => {
        const name = evt.target.name;
        const value = evt.target.value;
        if (name == "stateId") {
            getAllDistrictsByStateId(value);
            setContactData((values) => ({
                ...values,
                location_state: value,
            }));
        }
        else if (name == "entity_type") {
            setContactData((values) => ({
                ...values,
                entity_type: value,
            }));

        }
        else if (name == "email_id") {

            setContactData((values) => ({
                ...values,
                email_id: value.replace(/[^a-zA-Z@. |`~]/ig, ''),
            }));


        }
        else if (name == "entity_name") {
            setContactData((values) => ({
                ...values,
                entity_name: value.replace(/[^a-zA-Z-.&() |`~]/ig, ''),
            }));

        }
        else if (name == 'user_name') {
            setContactData((values) => ({
                ...values,
                user_name: value.replace(/[^a-zA-Z-.&() |`~]/ig, ''),
            }));
        }
        else if (name == "cityId") {
            setContactData((values) => ({
                ...values,
                location_city: value,
            }));
        }
        else if (name == 'query_type') {
            if (value == "others") {
                setShowOthers(true);
                setContactData((values) => ({
                    ...values,
                    query_type: value,
                }));
                setValidationFlag((values) => ({
                    ...values,
                    query_type: false,
                }));
                setValidationFlag((values) => ({
                    ...values,
                    others: true,
                }));
            }
            else {
                setContactData((values) => ({
                    ...values,
                    others: '',
                }));
                setValidationFlag((values) => ({
                    ...values,
                    others: false,
                }));
                setShowOthers(false);
                setContactData((values) => ({
                    ...values,
                    query_type: value,
                }));
            }
        }
        else if (name == "others") {
            setContactData((values) => ({
                ...values,
                others: value,
            }));
        }
        else if (name == 'message') {
            setContactData((values) => ({
                ...values,
                message: value,
            }));
        }
    }
    const handleValidate = (evt: React.ChangeEvent<
        HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
    ) => {
        const name = evt.target.name;
        const value = evt.target.value;
        if (name == "stateId") {
            if (contactData.location_state == "") {
                setValidationFlag((values) => ({
                    ...values,
                    location_state: true,
                }));
            }
            else {
                setValidationFlag((values) => ({
                    ...values,
                    location_state: false,
                }));
            }
        }
        else if (name == "entity_type") {
            if (contactData.entity_type == "") {
                setValidationFlag((values) => ({
                    ...values,
                    entity_type: true,
                }));
            }
            else {
                setValidationFlag((values) => ({
                    ...values,
                    entity_type: false,
                }));
            }

        }
        else if (name == "entity_name") {
            if (contactData.entity_name == "") {
                setValidationFlag((values) => ({
                    ...values,
                    entity_name: true,
                }));
            }
            else {
                setValidationFlag((values) => ({
                    ...values,
                    entity_name: false,
                }));
            }
        }
        else if (name == 'user_name') {
            if (contactData.user_name == "") {
                setValidationFlag((values) => ({
                    ...values,
                    user_name: true,
                }));
            }
            else {
                setValidationFlag((values) => ({
                    ...values,
                    user_name: false,
                }));
            }
        }
        else if (name == "cityId") {
            if (contactData.location_city == "") {
                setValidationFlag((values) => ({
                    ...values,
                    location_city: true,
                }));
            }
            else {
                setValidationFlag((values) => ({
                    ...values,
                    location_city: false,
                }));
            }
        }
        else if (name == "email_id") {
            if (contactData.email_id == "" || validateEmail(value) == false) {
                setValidationFlag((values) => ({
                    ...values,
                    email_id: true,
                }));
            }
            else {
                setValidationFlag((values) => ({
                    ...values,
                    email_id: false,
                }));
            }
        }
        else if (name == 'query_type') {
            if (contactData.query_type == "") {
                setValidationFlag((values) => ({
                    ...values,
                    query_type: true,
                }));
            }
            else {
                setValidationFlag((values) => ({
                    ...values,
                    query_type: false,
                }));
            }
        }
        else if (name == 'message') {
            if (contactData.message == "") {
                setValidationFlag((values) => ({
                    ...values,
                    message: true,
                }));
            }
            else {
                setValidationFlag((values) => ({
                    ...values,
                    message: false,
                }));
            }
        }
        else if (name == 'others') {
            if (contactData.others == "") {
                setValidationFlag((values) => ({
                    ...values,
                    others: true,
                }));
                setValidationFlag((values) => ({
                    ...values,
                    query_type: false,
                }));
            }
            else {
                setValidationFlag((values) => ({
                    ...values,
                    others: false,
                }));
            }
        }

    }
    const submitContactUsForm = () => {
        if (contactData.email_id != "" && validationFlag.email_id == false && contactData.entity_name != "" && contactData.entity_type != "" && contactData.location_city != "" && contactData.location_state != "" && contactData.message != "" && contactData.query_type != "" && validationFlag.others == false && contactData.user_name != "") {
            const payload = {

                entity_type: contactData.entity_type,
                entity_name: contactData.entity_name,
                user_name: contactData.user_name,
                email_id: contactData.email_id,
                location_state: contactData.location_state,
                location_city: contactData.location_city,
                query_type: contactData.others == "" ? contactData.query_type : contactData.others,
                message: contactData.message

            }
            setLoader(true);
            post(CONTACT_US, payload).then((response: any) => {
                if (!response || !response.data) return;
              //  console.log(response.data.result)
                if (response.data.result == "success") {
                    setShowIncubatorSuccessModal(true);
                    setContactData({
                        entity_type: '',
                        entity_name: '',
                        user_name: '',
                        email_id: '',
                        location_state: '',
                        location_city: '',
                        query_type: '',
                        message: '',
                        others: ''
                    })
                    setValidationFlag({
                        entity_type: false,
                        entity_name: false,
                        user_name: false,
                        email_id: false,
                        location_state: false,
                        location_city: false,
                        query_type: false,
                        message: false,
                        others: false

                    })
                }
                else {
                    setShowIncubatorFailureModal(true)
                }
                setLoader(false)
            })
            //console.log(payload)
        }
        else {
            if (contactData.email_id == "" || validateEmail(contactData.email_id) == false) {
                setValidationFlag((values) => ({
                    ...values,
                    email_id: true,
                }));
            }
            if (contactData.entity_name == "") {
                setValidationFlag((values) => ({
                    ...values,
                    entity_name: true,
                }));
            }
            if (contactData.entity_type == "") {
                setValidationFlag((values) => ({
                    ...values,
                    entity_type: true,
                }));
            }
            if (contactData.location_city == "") {
                setValidationFlag((values) => ({
                    ...values,
                    location_city: true,
                }));
            }
            if (contactData.location_state == "") {
                setValidationFlag((values) => ({
                    ...values,
                    location_state: true,
                }));
            }
            if (contactData.message == "") {
                setValidationFlag((values) => ({
                    ...values,
                    message: true,
                }));
            }

            if (contactData.user_name == "") {
                setValidationFlag((values) => ({
                    ...values,
                    user_name: true,
                }));
            }
            if (contactData.others == "") {
                setValidationFlag((values) => ({
                    ...values,
                    others: true,
                }));
            }
            if (contactData.query_type == "") {
                setValidationFlag((values) => ({
                    ...values,
                    query_type: true,
                }));
            }
        }
    }
    const dismissModal = () => {
        setShowIncubatorSuccessModal(false);
        setShowIncubatorFailureModal(false);

    }
    return (
        <>
            {loader &&
                <div className="spinner-border custom-spinner" role="status">
                    <span className="sr-only">Loading...</span>
                </div>}
            <div className="container inside-container">

                <div className="app-inside-content app-inside-full">

                    <div className="row justify-content-center">
                        <div className="col-sm-10">
                            <fieldset disabled={loader}>
                                <div className="form-wrapper contact-form">

                                    <div className="form-group row">
                                        <label className="col-sm-12 col-md-4 col-form-label">
                                            Entity Type
                                        </label>
                                        <div className="col-sm-12 col-md-8">
                                            <div className="select-custom">
                                                <select className="form-control" onChange={handleChange} onBlur={handleValidate} name="entity_type" value={contactData.entity_type} aria-label="Select Entity Type">
                                                    <option value="" >Select</option>
                                                    {/* selected */}
                                                    <option value="Startups">Startups</option>
                                                    <option value="Incubators">Incubators</option>
                                                    {/* <option value="3">Three</option> */}
                                                </select>
                                            </div>
                                            {validationFlag.entity_type == true ? <label className="error-message">Please select Entity Type</label> : null}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-12 col-md-4 col-form-label">
                                            Name of the Entity
                                        </label>
                                        <div className="col-sm-12 col-md-8">
                                            <input type="text" onChange={handleChange} onBlur={handleValidate} name="entity_name" value={contactData.entity_name} className="form-control" placeholder="Enter" />
                                            {validationFlag.entity_name == true ? <label className="error-message">Please select entity name</label> : null}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-12 col-md-4 col-form-label">
                                            Name
                                        </label>
                                        <div className="col-sm-12 col-md-8">
                                            <input type="text" onChange={handleChange} onBlur={handleValidate} name="user_name" value={contactData.user_name} className="form-control" placeholder="Enter" />
                                            {validationFlag.user_name == true ? <label className="error-message">Please enter name</label> : null}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-12 col-md-4 col-form-label">
                                            Email ID
                                        </label>
                                        <div className="col-sm-12 col-md-8">
                                            <input type="text" onChange={handleChange} onBlur={handleValidate} name="email_id" className="form-control" value={contactData.email_id} placeholder="Enter" />
                                            {validationFlag.email_id == true ? <label className="error-message">Please enter Email ID</label> : null}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-12 col-md-4 col-form-label">
                                            Location
                                        </label>
                                        <div className="col-sm-12 col-md-8">
                                            <div className="form-row">
                                                <div className="col">
                                                    {/* <select className="form-control" aria-label="Select State">
                                                    <option selected>Select State</option>
                                                    {/* <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option> 
                                                </select> */}
                                                    <div className="select-custom">
                                                        <select
                                                            className="form-control"
                                                            aria-label="Select State"
                                                            id="State"
                                                            name="stateId"
                                                            value={contactData.location_state}
                                                            onChange={handleChange}
                                                            onBlur={handleValidate}
                                                        >
                                                            <option value="">Select State</option>
                                                            {countryStates.map(({ label, value }) => (
                                                                <option key={label} value={value}>
                                                                    {label}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    {validationFlag.location_state == true ? <label className="error-message">Please select State</label> : null}
                                                </div>
                                                <div className="col">
                                                    <div className="select-custom">
                                                        <select
                                                            className="form-control"
                                                            aria-label="Select City"
                                                            id="City"
                                                            name="cityId"
                                                            onChange={handleChange}
                                                            onBlur={handleValidate}
                                                            value={contactData.location_city}

                                                        >
                                                            <option value="">Select City</option>
                                                            {stateDistricts.map(({ label, value }) => (
                                                                <option key={value} value={value}>
                                                                    {label}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    {validationFlag.location_city == true ? <label className="error-message">Please select city</label> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-12 col-md-4 col-form-label">
                                            Query Type
                                        </label>
                                        <div className="col-sm-12 col-md-8">
                                            <div className="select-custom">
                                                <select className="form-control" onBlur={handleValidate} onChange={handleChange} value={contactData.query_type} name="query_type" aria-label="Select Industry">
                                                    <option value="" >Select</option>
                                                    {/* selected */}
                                                    <option value="Login Issue">Login Issue</option>
                                                    <option value="Other Technical Issue">Other Technical Issue</option>
                                                    <option value="Eligibility Criteria">Eligibility Criteria</option>
                                                    <option value="Information about Application Process">Information about Application Process</option>
                                                    <option value="Information about Selection Process">Information about Selection Process</option>
                                                    <option value="Information about Seed Fund Offered">Information about Seed Fund Offered</option>
                                                    <option value="others">Others</option>
                                                </select>
                                            </div>
                                            {validationFlag.query_type == true ? <label className="error-message">Please select query type</label> : null}
                                        </div>

                                        {showOthers == true ? <div className="offset-4 col-sm-8 mt-2">

                                            <input type="text" onChange={handleChange} onBlur={handleValidate} name="others" className="form-control" value={contactData.others} placeholder="Enter" />
                                            {validationFlag.others == true ? <label className="error-message">Please enter query type</label> : null}
                                        </div> : null

                                        }
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-12 col-md-4 col-form-label">
                                            Message
                                        </label>
                                        <div className="col-sm-12 col-md-8">
                                            <textarea className="form-control" value={contactData.message} onBlur={handleValidate} onChange={handleChange} name="message" rows={3} placeholder="Enter"></textarea>
                                            {validationFlag.message == true ? <label className="error-message">Please enter message</label> : null}
                                        </div>
                                    </div>

                                    <div className="form-group row action-section">
                                        <label className="col-sm-12 col-md-4 col-form-label">

                                        </label>
                                        <div className="col-sm-12 col-md-8">
                                            <div className="btn-group">
                                                <button className="btn btn-sisfs btn-yellow-primary" onClick={submitContactUsForm}>Submit</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>
            {showIncubatorSuccessModal && (
                // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
                <>
                    <div className="modal-backdrop fade show"></div>

                    <div
                        className="modal align-middle modal-sisfs d-block"
                        id="FormSubmissionSuccess"
                    >
                        <div className="modal-dialog modal-dialog-centered modal-md">
                            <div className="modal-content">
                                <div className="modal-body text-center">
                                    <i className="fa fa-check-circle-o"></i>
                                    <h3 className="modal-message">Thank You!</h3>
                                    <div className="modal-text">
                                        Your query has been sent to SeedFund support team Successfully.
                                    </div>
                                    <div className="modal-action">
                                        <button className="btn btn-sisfs btn-yellow-primary" onClick={dismissModal}>
                                            Ok!!
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {showIncubatorFailureModal && (
                // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
                <>
                    <div className="modal-backdrop fade show"></div>
                    <div
                        className="modal align-middle modal-sisfs d-block"
                        id="FormSubmissionError"
                    >
                        <div className="modal-dialog modal-dialog-centered modal-md">
                            <div className="modal-content">
                                <div className="modal-body text-center">
                                    <img
                                        src={error}
                                        className="search mb-2"
                                        alt="search"

                                    />
                                    <h3 className="modal-message">
                                        Oops! Something is Wrong
                                    </h3><br />
                                    <h3 className="modal-text">
                                        Please Try Again!!!
                                    </h3>
                                    <div className="modal-action">
                                        <button className="btn btn-sisfs btn-yellow-primary" onClick={dismissModal}>
                                            OK!
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}


        </>
    )
};

export default ContactUsComponent;