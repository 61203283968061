
export default class UserInfoModel {
    constructor(public name: string = '',
        public email: string = '',
        public id: string = '',
        public roles: string = '',
        public accessToken : string ='',
        public allowCreation?: boolean
    ) {
        this.name = name;
        this.email = email;
        this.id = id;
        this.roles = roles;
        this.accessToken = accessToken;
        this.allowCreation = allowCreation;
    }
}