import React, { Component, useEffect, useState } from "react";
import Stepper from "bs-stepper";
import "bs-stepper/dist/css/bs-stepper.min.css";
import StepperHeaderReviewComponent from "./StepperHeaderReviewComponent";
import ReviewStepWrapperComponent from "./ReviewStepWrapperComponent";
import {
  GET_EAC_EVALUATION_QUESTIONS,
  SAVE_EAC_EVALUATION_QUESTIONS,
} from "../../../constants/api";
import { get, post } from "../../../effects/api/api.common";
import {
  IQuestionModel,
  QuestionModel,
} from "../../../models/eac/QuestionModel";
import ReviewScoreModel from "./ReviewScoreModel";
import ConfirmationDialog from "../../common/controls/ConfirmationDialog";
import { useLocation } from "react-router-dom";

interface IReviewProps {
  location: any;
}

interface IReviewState { }

/** Render eac's review screen */
function ReviewContainer(props: IReviewProps) {
  const [stepper, setStepper] = useState<Stepper>();
  const [questions, setQuestions] = useState<any>(null);
  const [incubatorID, setIncubatorID] = useState("");
  const [incubaorName, setIncubatorName] = useState("");
  const [incubatorAddress, setIncubatorAddress] = useState("");

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(
    false
  );
  const [openSubmitSummary, setOpenSubmitSummary] = useState<boolean>(false);
  const location = useLocation();
  useEffect(() => {
    setStepper(
      new Stepper(document.querySelector("#reviewstepper") as Element, {
        linear: false,
        animation: true,
      })
    );

    // set incubator id from props
    if (typeof props.location.state != "undefined") {
      setIncubatorID(props.location.state.incubator_application_id);
    }

    // call an api to bind the step
    getEACQuestions();
  }, []);

  // fetch the questions and it's details to bind the component
  const getEACQuestions = () => {
    // replae hard code with actual application_id
    post(GET_EAC_EVALUATION_QUESTIONS, {
      incubator_id: props.location.state.incubator_application_id,
    }).then((response: any) => {
      if (!response.data) return;
      setIncubatorName(response.data.incubator_name);
      setIncubatorAddress(response.data.incubator_address);
      if (!response.data && !response.data.data) {
        return;
      }
      const questionList = response.data.data;
      setQuestions(questionList);
    });
  };

  // triggers whenever user clicks on 'save' or 'submit' buttons
  // in case of submit the requestType would be 'submitted' else null
  const onSave = (questionModel?: IQuestionModel, requestType?: string) => {
    if (!questionModel) return;
    stepper && stepper.next();
    const requestModel = { ...questions, questionModel };
    // delete unlikely added object
    delete requestModel["questionModel"];

    post(SAVE_EAC_EVALUATION_QUESTIONS, {
      incubator_id: props.location.state.incubator_application_id,
      data: requestModel,
    }).then((response: any) => {
      if (!response.data) return;

      if (requestType && requestType === "submitted") {
        // show dialog
        setOpenConfirmationDialog(true);
      }
    });

    
  };

  // trigers when clicked on previois button
  const onPreviousClick = () => {
    stepper && stepper.previous();
  };

  const onChildUpdate = (questionModel: IQuestionModel) => {
    if (!questionModel) return;

    const updatedObject = { ...questions, questionModel };
    delete updatedObject["questionModel"];
    setQuestions(updatedObject);
  };

  // submits the form from the confirmation dialog
  const onSubmit = (questionModel: IQuestionModel) => {
    if (!questionModel) return;
    const requestModel = { ...questions, questionModel };
    delete requestModel["questionModel"];
    setQuestions(requestModel);
    setOpenSubmitSummary(true);
  };

  return (
    <>
      <div className="app-inside app-incubator">
        <div className="container-fluid about-heading app-banner-inside">
          <div className="text-center main-heading">{incubaorName}</div>
          <span className="text-center d-block city-name">
            {incubatorAddress}
          </span>

          <div className="text-center sub-heading">
            All form fields are <b>mandatory</b>, unless mentioned as{" "}
            <b>‘optional’.</b>
            <br />
            Each questions has Score / Weightage instructions
          </div>
        </div>

        <div className="container stepper-container">
          <div className="app-inside-content">
            <div className="row justify-content-center">
              <div className="col-xs-12 col-sm-12">
                <div id="reviewstepper" className="bs-stepper">
                  {/* Incubator For Stepper Steps Header */}
                  <StepperHeaderReviewComponent />

                  {/* Incubator For Stepper Steps Content */}
                  <div className="bs-stepper-content">
                    <div
                      id="review-step-1"
                      role="tabpanel"
                      className="bs-stepper-pane content"
                      aria-labelledby="review-step1"
                    >
                      <div className="form-wrapper stepper-form stepper-form-1">
                        <ReviewStepWrapperComponent
                          data={questions ? questions["1"] : null}
                          tabIndex={1}
                          onChildUpdate={onChildUpdate}
                          onSave={onSave}
                        // onPreviousClick={onPreviousClick}
                        />
                      </div>
                    </div>

                    <div
                      id="review-step-2"
                      role="tabpanel"
                      className="bs-stepper-pane content"
                      aria-labelledby="review-step2"
                    >
                      <div className="form-wrapper stepper-form stepper-form-1">
                        <ReviewStepWrapperComponent
                          data={questions ? questions["2"] : null}
                          tabIndex={2}
                          onChildUpdate={onChildUpdate}
                          onSave={onSave}
                          onPreviousClick={onPreviousClick}
                        />
                      </div>
                    </div>

                    <div
                      id="review-step-3"
                      role="tabpanel"
                      className="bs-stepper-pane content"
                      aria-labelledby="review-step3"
                    >
                      <div className="form-wrapper stepper-form stepper-form-1">
                        <ReviewStepWrapperComponent
                          data={questions ? questions["3"] : null}
                          tabIndex={3}
                          onChildUpdate={onChildUpdate}
                          onSave={onSave}
                          onPreviousClick={onPreviousClick}
                        />
                      </div>
                    </div>

                    <div
                      id="review-step-4"
                      role="tabpanel"
                      className="bs-stepper-pane content"
                      aria-labelledby="review-step4"
                    >
                      <div className="form-wrapper stepper-form stepper-form-1">
                        <ReviewStepWrapperComponent
                          data={questions ? questions["4"] : null}
                          tabIndex={4}
                          onChildUpdate={onChildUpdate}
                          onSave={onSave}
                          onPreviousClick={onPreviousClick}
                        />
                      </div>
                    </div>

                    <div
                      id="review-step-5"
                      role="tabpanel"
                      className="bs-stepper-pane content"
                      aria-labelledby="review-step5"
                    >
                      <div className="form-wrapper stepper-form stepper-form-1">
                        <ReviewStepWrapperComponent
                          data={questions ? questions["5"] : null}
                          tabIndex={5}
                          onChildUpdate={onChildUpdate}
                          onSave={onSave}
                          onPreviousClick={onPreviousClick}
                        />
                      </div>
                    </div>

                    <div
                      id="review-step-6"
                      role="tabpanel"
                      className="bs-stepper-pane content"
                      aria-labelledby="review-step6"
                    >
                      <div className="form-wrapper stepper-form stepper-form-1">
                        <ReviewStepWrapperComponent
                          data={questions ? questions["6"] : null}
                          tabIndex={6}
                          onChildUpdate={onChildUpdate}
                          onSave={onSave}
                          onPreviousClick={onPreviousClick}
                        />
                      </div>
                    </div>

                    <div
                      id="review-step-7"
                      role="tabpanel"
                      className="bs-stepper-pane content"
                      aria-labelledby="review-step7"
                    >
                      <div className="form-wrapper stepper-form stepper-form-1">
                        <ReviewStepWrapperComponent
                          data={questions ? questions["7"] : null}
                          tabIndex={7}
                          onChildUpdate={onChildUpdate}
                          onSave={onSave}
                          onPreviousClick={onPreviousClick}
                        />
                      </div>
                    </div>

                    <div
                      id="review-step-8"
                      role="tabpanel"
                      className="bs-stepper-pane content"
                      aria-labelledby="review-step8"
                    >
                      <div className="form-wrapper stepper-form stepper-form-1">
                        <ReviewStepWrapperComponent
                          data={questions ? questions["8"] : null}
                          tabIndex={8}
                          onChildUpdate={onChildUpdate}
                          onSave={onSave}
                          onPreviousClick={onPreviousClick}
                          // onSubmit={onSubmit}
                        />
                      </div>
                    </div>

                    <div
                      id="review-step-9"
                      role="tabpanel"
                      className="bs-stepper-pane content"
                      aria-labelledby="review-step9"
                    >
                      <div className="form-wrapper stepper-form stepper-form-1">
                        <ReviewStepWrapperComponent
                          data={questions ? questions["9"] : null}
                          tabIndex={9}
                          onChildUpdate={onChildUpdate}
                          onSave={onSave}
                          onPreviousClick={onPreviousClick}
                          onSubmit={onSubmit}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {(openSubmitSummary || questions) && (
          <ReviewScoreModel
            questions={questions}
            onReviewSubmit={(questionModel: IQuestionModel) =>
              onSave(questionModel, "submitted")
            }
          />
        )}
        {openConfirmationDialog && (
          <ConfirmationDialog
            headerText="Thank You !"
            bodyText="Evaluation Score was successfully submitted.
Thank you for your review"
            navigateTo="/view_eac_review"
            onDialogClose={() => setOpenConfirmationDialog(false)}
          />
        )}
      </div>
    </>
  );
}

export default ReviewContainer;
