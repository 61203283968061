import React, { useEffect, useState } from 'react';
import fileupload from '../../../images/fileupload.svg'
import pdfImg from '../../../images/pdfimg.svg';
import error from '../../../images/error.svg';
import xlsx from '../../../images/xlsx.svg';
import '../../../styles/component.css';
import { getSessionStorageValue } from '../../../effects/utils/session-storage';
import { post } from '../../../effects/api/api.common';
import { INC_TRANCHE1_UPLOAD, INC_TRANCHE1_GETFILES, SEC_UPDATE_STATUS, SEC_TRANCHE1_SUBMIT, NODE_URL, UPDATE_FILE_STATUS, secgreybox, STORE_TRANCHE_REQUEST } from '../../../constants/api';
import { useHistory, useParams } from 'react-router-dom';
import AuthContext, { AuthContextType } from '../../../Contexts/AuthContext';
import ConfirmationDialog from '../../common/controls/ConfirmationDialog';
import moment from 'moment';
import { verify } from 'crypto';
import axios from 'axios';
import JSZip from 'jszip';
import { saveAs } from "file-saver";
import pako from 'pako';



const file_path_names = ["ECS_Mandate_file_path", "pfms_file_path", "proof_Of_Darpan_file_path"];

const file_names = ["ECS_Mandate_file_path_file_name", "pfms_file_path_file_name", "proof_Of_Darpan_file_path_file_name"];

const statuses = ["pfms_status_ind", "proof_Of_Darpan_status_ind", "ECS_Mandate_status_ind"];

const comments = ["pfms_comments", "proof_Of_Darpan_comments", "ECS_Mandate_comments"];

const upload_names = ["pfms", "darpan", "ecsMandate"];

const colorIndication = ["Submittedbg", "Readbg", "Acceptedbg", "Rejectedbg"];

interface IIKeyValueIndexer {
    [key: string]: boolean;
}
function FirstTrancheSubsequent() {
    const history = useHistory();
    const [openUploadBlock, setOpenUploadBlock] = useState<IIKeyValueIndexer>({});
    const [loggedInRole, setLoggedInRole] = useState('');
    const [loader, setLoader] = useState(false);
    const [getUserInfo, setUserInfo] = useState<any>();
    const [errorMessage, setErrorMessage] = useState<any>('');
    const [showSubmitFailureModal, setSubmitFailureModal] = useState(false);
    const [showSubmitSuccessModal, setSubmitSuccessModal] = useState(false);
    const [showEditSuccessModal, setShowSuccessModal] = useState(false);
    const [showUploadFailureModal, setShowUploadFailureModal] = useState(false);
    const [showEditFailureModal, setShowFailureModal] = useState(false);
    const [all_IncubatorFiles, setAllIncubatorFiles] = useState<any>([])
    const [failDialogData, setFailDialogData] = useState({
        "header": '',
        "text": ''
    });
    const [currentFileDetails, setCurrentFileDetails] = useState({
        name: "",
        status: "",
        action: "",
        file_no: "",
        comment: []
    });
    const [rejectedText, setRejectedText] = useState<any>();

    const [getFilesData, setGetFilesData] = useState({
        id: '',
        incubator_id: '',
        pfms_file_path_file_name: '',
        pfms_file_path: '',
        pfms_status_ind: '',
        pfms_comments: '',
        proof_Of_Darpan_file_path_file_name: '',
        proof_Of_Darpan_file_path: '',
        proof_Of_Darpan_status_ind: '',
        proof_Of_Darpan_comments: '',
        TRA_Accounts_file_path_file_name: '',
        TRA_Accounts_file_path: '',
        TRA_Accounts_status_ind: '',
        TRA_Accounts_comments: '',
        ECS_Mandate_file_path_file_name: '',
        ECS_Mandate_file_path: '',
        ECS_Mandate_status_ind: '',
        ECS_Mandate_comments: '',
        status_ind: '',
        created_at: '',
        updated_at: '',
        application_ind: ''
    });
    const [confirmationPopup, setConfirmationPopup] = useState(false);
    const [stGetBackgrounds, setStBackgrounds] = useState<any>([]);
    const [confirmationSubmitPopup, setConfirmationSubmitPopup] = useState(false);
    const [confirmationFilePopup, setConfirmationFilePopup] = useState(false);
    const [readFileFirst, setReadFileFirst] = useState<any>();
    const [checkStatus, setCheckStatus] = useState(false);
    const [showInputField, setShowInputField] = useState(false);
    const [showInputField1, setShowInputField1] = useState(false);
    const [checkPfmsFile, setCheckPfmsFile] = useState("");
    const [exceedFileSize, setExceedFileSize] = useState(false);
    const [getAllZipFiles, setGetZipFile] = useState(false);
    const [getIncZipFile,setIncZipFile] = useState();
    const [pfmsInput, setPfmsInput] = useState('');
    const [enableInput, setEnableInput] = useState(false);
    const [enableInput1, setEnableInput1] = useState(false);
    const [darpanInput, setDarpanInput] = useState('');
    const [getGreyboxDtls, setGetGreyboxDtls] = useState<any>();
    const [getAppNumber, setAppNumber] = useState();
    const [tranche_message, setMessage] = useState("");
    const [filename, setFilename] = useState("");
    const params: any = useParams();

   
    useEffect(() => {
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user)
        const userId = userInfo.id
        setUserInfo(userInfo)
        setLoggedInRole(userInfo.roles);
        post(secgreybox, { user_id: params.id ? params.id : userId }).then(async (response: any) => {
            // console.log(response.data.Data)
            for (let i = 0; i < response.data.Data.length; i++) {
                if (response.data.Data[i].application_index == params.app_index) {
                    // console.log(response.data.Data[i])
                    setAppNumber(response.data.Data[i].incubator_application_no)
                    setGetGreyboxDtls(response.data.Data[i])
                } 
            }
            // setGetGreyboxDtls(response.data.Data[0])
        })
        getAllFileUploads();
        // console.log(params)
    }, [getAppNumber]);


    useEffect(() => {
        // console.log(getFilesData)
    }, [getFilesData])

    const setModalPopup = (name: string) => {
        setFilename(name)
    }
    const setSubmitStatus = (data: any) => {
        // console.log(data, "########")
        let getStatusData: any = [];
        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < statuses.length; j++) {
                if (data[i][statuses[j]] == 0 || data[i][statuses[j]] == 1 || data[i][statuses[j]] == 2 || data[i][statuses[j]] == 3 || data[i][statuses[j]] == 4 || data[i][statuses[j]] == null) {
                    getStatusData.push(data[i][statuses[j]])
                }
                if (data[i][statuses[0]] == 0 || data[i][statuses[0]] == 1 || data[i][statuses[0]] == null) {
                    setShowInputField(true);
                }
                if (data[i][statuses[1]] == 0 || data[i][statuses[1]] == 1 || data[i][statuses[1]] == null) {
                    setShowInputField1(true);
                }
            }
        }

        if (getStatusData.includes(0)) {
            setCheckStatus(false);
        } else if (getStatusData.includes(2) && !getStatusData.includes(1)) {
            setCheckStatus(false);
        } else if (!getStatusData.includes(null) && getStatusData.includes(1)) {
            setCheckStatus(true);
        }

        // if (getStatusData.includes(0) || getStatusData.includes(1) || getStatusData.includes(null)) {
        //     setShowInputField(true);
        // }
        // console.log(getStatusData, "*******************")
        // const anyFieldBetween1And3 = data.some((item: any) => statuses.some(status => item[status] == 1 && item[status] !== 0));
        // setCheckStatus(anyFieldBetween1And3);
    }
    // console.log(checkStatus, "$$$$$$$$$$$$$$$$$$$$$$$$$$$")
    const getAllFileUploads = () => {
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user)
        // console.log(getAppNumber,"1111111111111111")
        post(INC_TRANCHE1_GETFILES, { userId: params.id ? params.id : userInfo.id, application_ind: params.app_index,TrancheNo: params.tranche_number,incubator_application_no: getAppNumber }).then((response: any) => {
            // console.log(response.data, "%%%%%%%%%%%%%%%%%")
            setIncZipFile(response.data.incubatorfilesZip)
            if (!response || !response.data) return;
            setGetFilesData((prevState) => ({
                ...prevState,
                ...response.data.data[0]
            }));
            setSubmitStatus(response.data.data);
            if (response.data.data[0].status_ind == 4) {
                setGetZipFile(true)
            }

            let allFilepaths: any = [];
            let filesArray: any = [];
            let stBackgroundColors: any = [];

            let tranche_label = localStorage.getItem("trancheLabel")



            for (let i = 0; i < statuses.length; i++) {

                // console.log(response.data.data[0], loggedInRole, "$%%%%%%%%$$$###@#@#")
                if (response?.data?.data[0][statuses[i]] == 0) {
                    if (tranche_label == "Verification of Documents Completed" && loggedInRole != "secretariat") {
                        stBackgroundColors.push(`Acceptedbg`);
                    } else {
                        stBackgroundColors.push(`Rejectedbg`);
                    }
                }
                if (response?.data?.data[0][statuses[i]] == 1) {
                    if (tranche_label == "Verification of Documents Completed" && loggedInRole != "secretariat") {
                        stBackgroundColors.push(`Acceptedbg`);
                    } else {
                        stBackgroundColors.push(`savedbg`);
                    }
                }
                if (response?.data?.data[0][statuses[i]] == 2) {
                    stBackgroundColors.push(`Submittedbg`);
                }
                if (response?.data?.data[0][statuses[i]] == 3) {
                    if (tranche_label == "Verification of Documents Completed" && loggedInRole != "secretariat") {
                        stBackgroundColors.push(`Acceptedbg`);
                    } else {
                        stBackgroundColors.push(`Readbg`);
                    }
                }
                if (response?.data?.data[0][statuses[i]] == 4) {
                    if (tranche_label == "Verification of Documents Completed" && loggedInRole != "secretariat") {
                        stBackgroundColors.push(`Acceptedbg`);
                    } else {
                        // console.log("RRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR", i)
                        stBackgroundColors.push(`Acceptedbg`);
                    }
                }
                if (response.data.data[0][file_path_names[i]] != "No FIle provided by user") {
                    // console.log(response.data.data[0])                    
                    allFilepaths.push({ filename: response.data.data[0][file_names[i]], url: NODE_URL + response.data.data[0][file_path_names[i]] })
                }
            }
            if (response.data.data[0].status_ind !== null) {
                setStBackgrounds(stBackgroundColors);
            }
            setAllIncubatorFiles(allFilepaths);
            // console.log(response.data.data[0]);
        })

    }
    const submitInput = (name: any, value: any, file_no: any) => {
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user)
        if (value == '') {
            return;
        }

        // console.log(name, value, file_no);
        const formData = new FormData();

        formData.append("Text", value);
        formData.append("user_id", userInfo.id);
        formData.append("TrancheNumber", params.tranche_number);
        formData.append("application_ind", params.app_index);
        formData.append("appplication_id", params.application_id);
        formData.append("FileNo", file_no);

        post(INC_TRANCHE1_UPLOAD, formData).then((response: any) => {
            // console.log(response.response.status);
            if (response?.response?.status == 500) {
                setErrorMessage(response.response.data.message)

                setSubmitFailureModal(true);
                return;
            }
            if (name == "pfms") {
                setEnableInput(true);
            } else if (name == "Darpan") {
                setEnableInput1(true);
            }
            getAllFileUploads();
            // setSubmitStatus(response.data.data);
        })
    }
    const editBtn = (name: any) => {
        if (name == "pfms") {
            setEnableInput(false);
        } else if (name == "Darpan") {
            setEnableInput1(false);
        }
    }
    const handleChange = (evt: React.ChangeEvent<
        HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >) => {

        const name = evt.target.name;
        const value = evt.target.value;

        if (name == "pfms") {
            setPfmsInput(value);
            setGetFilesData({
                ...getFilesData,
                pfms_file_path_file_name: evt.target.value,
            });
        }
        if (name == "Darpan") {
            setDarpanInput(value);
            setGetFilesData({
                ...getFilesData,
                proof_Of_Darpan_file_path_file_name: evt.target.value,
            });
        }
    }
    const handleFileChange = (event: any, file_no: any) => {
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user)
        // console.log(event.target.files[0])
        // console.log(file_no)
        // console.log(event.target.name)

        const name = event.target.value;
        const value = event.target.value;
        const file = event.target.files[0];

        if (event.target.files[0].size > 5 * 1024 * 1024) {
            setExceedFileSize(true)
            return;
        }

        const formData = new FormData();

        formData.append("file", file);
        formData.append("user_id", userInfo.id);
        formData.append("TrancheNumber", params.tranche_number);
        formData.append("application_ind", params.app_index);
        formData.append("appplication_id", params.application_id);
        formData.append("FileNo", file_no)
        // console.log(formData)
        setLoader(true);
        post(INC_TRANCHE1_UPLOAD, formData).then((response: any) => {
            setLoader(false);
            if (response.data !== undefined) {

                getAllFileUploads();
            } else {
                setFailDialogData({
                    "header": "",
                    "text": response.response.data.message
                })
                setShowUploadFailureModal(true);
                return;
            }
            // setSubmitStatus(response.data.data);
        })
    }
    const dismissModal = () => {
        setSubmitFailureModal(false);
        setShowFailureModal(false);
        setConfirmationFilePopup(false);
        setExceedFileSize(false);
    }
    const downloadAsZip = async () => {
        // console.log(all_IncubatorFiles);
        try {
            setLoader(true);
            const zip = new JSZip();
            const groupName = "Incubator"; // Custom name for the group
            const groupFolder = zip.folder(groupName);

            if (groupFolder != null) {
                await Promise.all(
                    all_IncubatorFiles.map(async (photo: any) => {
                        const response = await fetch(photo.url);
                        const blob = await response.blob();
                        groupFolder.file(photo.filename, blob, { binary: true });
                    })
                );
            }

            zip.generateAsync({ type: "blob" }).then((blob) => {
                saveAs(blob, `${getGreyboxDtls.incubator_application_no}_${params.tranche_number}.zip`);
            });
        } catch (error) {
            // console.error('Error during download:', error);
        } finally {
            setLoader(false);
        }

    }
    const submit = () => {

        setConfirmationSubmitPopup(true);
    }
    const confirmSubmit = () => {
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user)

        setConfirmationSubmitPopup(false);

        if (loggedInRole == "incubator") {
            const payload = {
                userId: userInfo.id,
                application_ind: params.app_index,
                status: "2",
                TrancheNo: params.tranche_number,
                incubator_application_no: getAppNumber
            }
            // console.log(payload,"1111111111111111")
            post(INC_TRANCHE1_GETFILES, payload).then((response: any) => {
                getAllFileUploads();
                // setSubmitStatus(response.data.data);
                // console.log(response);
            })

            const data_input = {
                application_id: params.application_id,
                tranche_id: params.tranche_id,
                funds_requested: params.fund_request,
                cash_in_hand: params.cash_in_hand,
                logged_user_id: userInfo.id,
                application_ind: params.app_index
            };
            setLoader(true);
            post(STORE_TRANCHE_REQUEST, data_input).then((response: any) => {
                setLoader(false);
                if (!response || !response.data || response.data == '') return;
                if (response.data != undefined && response.data != '') {
                    if (response.data.result == 'success') {
                        setMessage("Tranche Request Submitted Successfully !!");
                        setShowSuccessModal(true);

                    } else {
                        setShowFailureModal(true);
                    }
                }
            });
        }
        else {
            const payload = {
                id: params.id,
                statusNo: "4",
                trancheAppId: params.application_id,
                application_ind: params.app_index,
                tranche_id: params.trancheID
            }

            post(SEC_TRANCHE1_SUBMIT, payload).then((response: any) => {
                if (response.data.result == 500) {
                    setErrorMessage(response.data.message)
                    setConfirmationPopup(false);
                    setSubmitFailureModal(true);
                    return;
                } else {
                    setErrorMessage(response.data.data)
                    setConfirmationSubmitPopup(false);
                    setSubmitSuccessModal(true);
                }
            })
        }
    }
    const havePfmsFile = (key: any) => {
        setCheckPfmsFile(key);
    }
    const noPfmsFile = (key: any) => {
        setCheckPfmsFile(key)
    }

    const fileCommentsPopup = (name: any, file_status: any, action: any, fileNo: any, comment: any) => {
        let splitComments = comment && comment.split('//**//').filter((str: any) => str.trim() !== "");
        setCurrentFileDetails({
            name: name,
            status: file_status,
            action: action,
            file_no: fileNo,
            comment: splitComments
        })
        setReadFileFirst("");
        setRejectedText("");
        setConfirmationFilePopup(true)
    }
    const removeFilePopup = (name: any, file_status: any, action: any, fileNo: any, comment: any) => {
        setCurrentFileDetails({
            name: name,
            status: file_status,
            action: action,
            file_no: fileNo,
            comment: comment
        })
        setReadFileFirst("");
        setRejectedText("");
        setConfirmationFilePopup(true)
    }
    const acceptFilePopup = (name: any, file_status: any, action: any, fileNo: any, comment: any) => {
        setCurrentFileDetails({
            name: name,
            status: file_status,
            action: action,
            file_no: fileNo,
            comment: comment
        })
        setReadFileFirst("");
        setRejectedText("");
        setConfirmationFilePopup(true)
    }
    const hanndleCmntChange = (event: any) => {
        const name = event.target.name;
        const value = event.target.value;
        setRejectedText(value)
    }
    useEffect(() => {

    }, [rejectedText])
    const removeFileUpload = (file_no: any, comment: any) => {
        if (rejectedText == "") {
            return
        }
        if (params.id) {
            var payload = {
                id: params.id,
                FileNo: file_no,
                comment: comment,
                TrancheNumber: params.tranche_number,
                trancheAppId: params.application_id,
                application_ind: params.app_index,
                tranche_id: params.trancheID
            }

            // return;
            post(SEC_UPDATE_STATUS, payload).then((response: any) => {
                if (response.data.result == 500) {
                    setReadFileFirst(response.data.message)
                    return;
                } else {
                    setReadFileFirst("")
                }
                getAllFileUploads();
                setConfirmationFilePopup(false)
            })
        }
    }
    const acceptFileUpload = (file_no: any, comment: any) => {
        if (rejectedText == "") {
            return
        }
        if (params.id) {
            var payload = {
                id: params.id,
                FileNo: file_no,
                Accept: comment,
                TrancheNumber: params.tranche_number,
                trancheAppId: params.application_id,
                application_ind: params.app_index,
                tranche_id: params.trancheID
            }

            // return;
            post(SEC_UPDATE_STATUS, payload).then((response: any) => {
                if (response.data.result == 500) {
                    setReadFileFirst(response.data.message)
                    return;
                } else {
                    setReadFileFirst("")
                }
                // if(!)
                getAllFileUploads();
                setConfirmationFilePopup(false)
            })
        }
    }
    const getFileVisibiltyStatus = (id: any) => {

        if (params.id) {
            var payload = {
                id: params.id,
                FileNo: id,
                statusNo: 3,
                trancheAppId: params.application_id,
                application_ind: params.app_index,
                tranche_id: params.trancheID
            }
            // console.log(payload,"%%%%%%%%%%%%%%%")
            post(SEC_UPDATE_STATUS, payload).then((response: any) => {
                // console.log(response);
                getAllFileUploads();
            })
        }

    }
    const goToFundDetailsTab = () => {
        history.push({
            pathname: '/incubator_view_funddetails',

        });
    }
    const backToFundDetails = () => {
        // return;
        if (loggedInRole == "incubator") history.push('/incubator_view_funddetails')
        if (loggedInRole == "secretariat") {
            history.replace({
                pathname: "/merged_Incubator_Details_list",
                state: { application_id: getGreyboxDtls.incubator_application_no, isFundDetails: false, userId: getGreyboxDtls.user_id, id: getGreyboxDtls.application_id, activeTab: '1', status_id: getGreyboxDtls.status_id, flag: "status" },
            });
        }
    }
    return (
        <>
            {loader &&
                <div className="spinner-border custom-spinner" role="status">
                    <span className="sr-only">Loading...</span>
                </div>}
            {/* {console.log(getFilesData)} */}
            <fieldset disabled={loader}>
                <div className="row">
                    <div className="col-1"></div>
                    <div className="col-10">
                        <div className="row">
                            <span className="tranche-head1" >Request for the First Tranche:</span>
                        </div>
                        {loggedInRole == "incubator" &&
                            <div className="row mt-2 mt-3 mb-2">
                                <div className="col-12 p-0 d-flex ">
                                    <div className='sNo' style={{ color: 'red', fontWeight: 'bold' }} >All fields are mandatory, file size should be less than or equal to 5MB</div>
                                </div>
                            </div>}
                        {getFilesData && getFilesData.status_ind >= '3' && <div className="row mb-2">
                            <div className="col-12 indicationSection">
                                {
                                    loggedInRole == "secretariat" ?
                                        colorIndication.map((item: any, i) => (
                                            <div key={i} className='indicationAlign'>
                                                <div className="indicationBox ml-3">
                                                    <div className={item + " indicationDot"}>

                                                    </div>
                                                </div>
                                                <div>
                                                    &nbsp;&nbsp; {item.slice(0, -2)}
                                                </div>
                                            </div>
                                        )) :
                                        colorIndication.filter((res: any) => (res == "Submittedbg" || res == "Acceptedbg" || res == "Rejectedbg")).map((item: any) => (
                                            <div className='indicationAlign'>
                                                <div className="indicationBox ml-3">
                                                    <div className={item + " indicationDot"}>

                                                    </div>
                                                </div>
                                                <div>
                                                    &nbsp;&nbsp; {item.slice(0, -2)}
                                                </div>
                                            </div>
                                        ))

                                }
                            </div>
                        </div>}
                        <div className="row section-bg mb-2 p-0">
                            <div className={(stGetBackgrounds) ? stGetBackgrounds[0] + " col-6 p-3" : "col-6 p-3"} style={{ height: 'auto' }}>
                                <div className='d-flex'>
                                    <span className='s-no'>1.</span>
                                    <span className='section-head'>PFMS ID
                                        <span className='required'>*</span>
                                        <span className='Info-circle ml-2'
                                            data-target="#fileInfo"
                                            data-toggle="modal"
                                            onClick={() => setModalPopup("PFMS")}
                                        >
                                            ℹ️
                                        </span>
                                        <span>
                                            &nbsp;
                                            <span className='fileRemove text-center'>
                                                <span >{getFilesData.pfms_status_ind == '4' ? <span className='acceptFile1'>Accepted</span> : (getFilesData.status_ind > '2' && getFilesData.pfms_status_ind == '0') ? <span className='rejectFile1'>Rejected</span> : ""}</span>
                                            </span>
                                        </span>
                                    </span>
                                </div>
                                <div className="row">
                                    {getFilesData.pfms_status_ind != '4' && loggedInRole == "incubator" &&
                                        <>
                                            <div className="col-6 text-left pt-4">


                                                {<span className='mt-3'>Are you Registered on PFMS before?</span>}
                                            </div>
                                            <div className="col-6 m-auto text-left p-0 pfmsBtns pt-4">

                                                {<button className="btn btn-sisfs btn-yellow-primary"
                                                    onClick={() => { havePfmsFile("yes") }}
                                                    disabled={getFilesData.pfms_status_ind > '1'}
                                                >
                                                    Yes
                                                </button>}
                                                {< button className="btn btn-sisfs btn-transparent-primary ml-2"
                                                    onClick={() => { noPfmsFile("no") }}
                                                    disabled={enableInput || getFilesData.pfms_status_ind > '1'}
                                                >
                                                    No
                                                </button>}
                                            </div>
                                        </>
                                    }
                                    {/* || loggedInRole == "incubator"  */}
                                    {((loggedInRole == "secretariat") && (getFilesData.pfms_file_path == 'No FIle provided by user')) &&
                                        <span className='col-12 text-left pt-4' style={{ paddingLeft: '27px' }}>
                                            {
                                                <label htmlFor="">
                                                    PFMS Registered ID :
                                                </label>
                                            }
                                            <span className='regText'>
                                                {getFilesData.pfms_file_path_file_name}
                                            </span>
                                            {
                                                <>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                  {(loggedInRole == "secretariat" && params.tranche_status != "Incomplete") &&
                                                  <>
                                                    <span className='fileRemove text-center' onClick={() => (getFilesData.pfms_status_ind == '2' || getFilesData.pfms_status_ind == '3') && acceptFilePopup(getFilesData.pfms_file_path_file_name, getFilesData.pfms_status_ind, "accept", "0", getFilesData.pfms_comments)}>

                                                        <span className='acceptFile'>{(getFilesData.pfms_status_ind == '2' || getFilesData.pfms_status_ind == '3') ? <span onClick={() => getFileVisibiltyStatus(0)} >Accept</span> : ""}</span>
                                                    </span>
                                                    <span className='fileRemove text-center ml-2' onClick={() => (getFilesData.pfms_status_ind == '2' || getFilesData.pfms_status_ind >= '3') && removeFilePopup(getFilesData.pfms_file_path_file_name, getFilesData.pfms_status_ind, "reject", "0", getFilesData.pfms_comments)}>

                                                        <span className='rejectFile'>{
                                                            (params.tranche_status == "Disbursed" || params.tranche_status == "Disbursement in Process" || params.tranche_status == "Incomplete") ? "" :
                                                                (getFilesData.pfms_status_ind == '2' || getFilesData.pfms_status_ind >= '3') ? <span onClick={() => getFileVisibiltyStatus(0)} >Reject</span> : ""}</span>
                                                    </span>
                                                  </>
                                                    }
                                                    {(getFilesData.pfms_status_ind == '0' || getFilesData.pfms_status_ind == '4') &&
                                                <span className='fileRemove text-center ml-2' onClick={() => fileCommentsPopup(getFilesData.pfms_file_path_file_name, getFilesData.pfms_status_ind, "comment", "0", getFilesData.pfms_comments)}>
                                                    <span className='commentsFile'>Comments</span>
                                                </span>}
                                                </> 
                                            }
                                        </span>

                                    }


                                    {(checkPfmsFile == "yes" || (loggedInRole == "incubator" && checkPfmsFile != "no" && getFilesData.pfms_file_path != '' && getFilesData.pfms_file_path == 'No FIle provided by user')) &&
                                        <>
                                            {/* <div className="col"></div> */}
                                            <div className="col-12 d-flex text-center pt-4 pfmsInput">
                                                <div>

                                                    <input type="text"
                                                        id='pfms'
                                                        name="pfms"
                                                        value={getFilesData.pfms_file_path == 'No FIle provided by user' ? getFilesData.pfms_file_path_file_name : pfmsInput}
                                                        readOnly={enableInput || getFilesData.pfms_status_ind > '1'}
                                                        placeholder='Enter PFMS ID'
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                                <div className="text-left pfmsBtns pl-2">
                                                    {enableInput && <button className="btn btn-sisfs btn-yellow-primary "
                                                        onClick={() => { editBtn("pfms") }}

                                                    >
                                                        Edit
                                                    </button>}
                                                    {!enableInput && getFilesData.pfms_status_ind != '4' && <button className="btn btn-sisfs btn-yellow-primary "
                                                        onClick={() => { submitInput("pfms", pfmsInput, 0) }}
                                                    >
                                                        Save
                                                    </button>}
                                                </div>
                                                {loggedInRole == "incubator" &&
                                                    (getFilesData.pfms_status_ind == '0' || getFilesData.pfms_status_ind == '4') &&
                                                    <span className='fileRemove text-center ml-2' onClick={() => fileCommentsPopup(getFilesData.pfms_file_path_file_name, getFilesData.pfms_status_ind, "comment", "0", getFilesData.pfms_comments)}>
                                                        <span className='commentsFile'>Comments</span>
                                                    </span>
                                                }
                                                {/* {
                                                    (loggedInRole == "secretariat" && getFilesData.pfms_file_path == 'No FIle provided by user') &&
                                                    <>

                                                        {
                                                            (getFilesData.pfms_status_ind == '0' || getFilesData.pfms_status_ind == '4') &&
                                                            <span className='fileRemove text-center ml-2' onClick={() => fileCommentsPopup(getFilesData.pfms_file_path_file_name, getFilesData.pfms_status_ind, "comment", "0", getFilesData.pfms_comments)}>
                                                                <span className='commentsFile'>Comments</span>
                                                            </span>
                                                        }
                                                    </>
                                                } */}
                                            </div>
                                            {/* <div className="col"></div> */}
                                        </>
                                    }
                                    <div className="col-12 text-center">



                                    </div>
                                    {
                                        ((checkPfmsFile != '' && checkPfmsFile == "no") || (checkPfmsFile != "yes" && getFilesData.pfms_file_path != '' && getFilesData.pfms_file_path != 'No FIle provided by user')) &&
                                        <div className="fileDiv p-2">
                                            {loggedInRole == "incubator" &&
                                                <>
                                                    <button
                                                        className="btn btn-sisfs btn-transparent-primary"
                                                        onClick={() => setOpenUploadBlock({ pfms: true })}
                                                    >
                                                        Upload
                                                    </button><span style={{ fontSize: '12px', color: '#25429c', fontWeight: '650' }}><i>  File Format - Excel only</i></span>
                                                </>}
                                            {((openUploadBlock &&
                                                (openUploadBlock["pfms"] == true || getFilesData.pfms_file_path_file_name))) && (
                                                    <>
                                                        {loggedInRole == "incubator" && <span
                                                            className="close"
                                                            onClick={() => setOpenUploadBlock({ pfms: false })}
                                                        >
                                                            {!getFilesData.pfms_file_path_file_name && <i className="fa fa-close"></i>}
                                                        </span>}

                                                        <fieldset className='fileUploadSec mt-2'>
                                                            <div className="" style={{ width: '100%' }}>
                                                                {loggedInRole == "incubator" && <div className="filebox">
                                                                    {/* <div className='mt-3'>Drag & Drop file or Browse</div> */}
                                                                    <div className='mt-2'><img src={fileupload} alt="" /></div>

                                                                    <input disabled={getFilesData.pfms_status_ind > '1'} type="file" accept='.xlsx' id="pfms" name="pfmsFile" onChange={(e) => handleFileChange(e, 0)} hidden />
                                                                    <label htmlFor="pfms" className="btn btn-sisfs btn-transparent-primary mt-1" >Browse</label>
                                                                    <br />
                                                                </div>}
                                                                {getFilesData.pfms_file_path !== null && getFilesData.pfms_file_path !== "" && getFilesData.pfms_file_path != 'No FIle provided by user' &&
                                                                    <div className={loggedInRole == "secretariat" ? 'previewDiv mb-3 filebox1 filebox1Sec' : 'previewDiv mb-3 filebox1'}>

                                                                        <div className='fileSection'>
                                                                            {getFilesData.pfms_file_path_file_name && <div>
                                                                                <span className='ml-3'>
                                                                                    <img src={xlsx} alt="" />
                                                                                </span>
                                                                                <span className='ml-3'>
                                                                                    {getFilesData.pfms_file_path_file_name ?
                                                                                        <a href={`${NODE_URL + getFilesData.pfms_file_path}`}
                                                                                            onClick={() => getFileVisibiltyStatus(0)}
                                                                                            target='_blank'
                                                                                        >
                                                                                            {getFilesData.pfms_file_path && getFilesData.pfms_file_path != "No FIle provided by user" ?

                                                                                                (getFilesData.pfms_file_path_file_name.length > 15 ?
                                                                                                    getFilesData.pfms_file_path_file_name.slice(0, 15) + "..." : getFilesData.pfms_file_path_file_name)
                                                                                                : ''
                                                                                            }
                                                                                        </a> :
                                                                                        ""
                                                                                    }
                                                                                </span>
                                                                            </div>}
                                                                            <div className='d-flex'>
                                                                                {
                                                                                    loggedInRole == "secretariat" && params.tranche_status != "Incomplete" &&
                                                                                    <>
                                                                                        <span className='fileRemove text-center' onClick={() => (getFilesData.pfms_status_ind == '2' || getFilesData.pfms_status_ind == '3') && acceptFilePopup(getFilesData.pfms_file_path_file_name, getFilesData.pfms_status_ind, "accept", "0", getFilesData.pfms_comments)}>

                                                                                            <span className='acceptFile'>{(getFilesData.pfms_status_ind == '2' || getFilesData.pfms_status_ind == '3') ? "Accept" : ""}</span>
                                                                                        </span>
                                                                                        <span className='fileRemove text-center ml-2' onClick={() => (getFilesData.pfms_status_ind == '2' || getFilesData.pfms_status_ind >= '3') && removeFilePopup(getFilesData.pfms_file_path_file_name, getFilesData.pfms_status_ind, "reject", "0", getFilesData.pfms_comments)}>

                                                                                            <span className='rejectFile'>{
                                                                                                (params.tranche_status == "Disbursed" || params.tranche_status == "Disbursement in Process" || params.tranche_status == "Incomplete") ? "" :
                                                                                                    (getFilesData.pfms_status_ind == '2' || getFilesData.pfms_status_ind >= '3') ? "Reject" : ""}</span>
                                                                                        </span>
                                                                                    </>
                                                                                }
                                                                                {
                                                                                    (getFilesData.pfms_status_ind == '0' || getFilesData.pfms_status_ind == '4') &&
                                                                                    <span className='fileRemove text-center ml-2' onClick={() => fileCommentsPopup(getFilesData.pfms_file_path_file_name, getFilesData.pfms_status_ind, "comment", "0", getFilesData.pfms_comments)}>
                                                                                        <span className='commentsFile'>Comments</span>
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                    </div>}
                                                            </div>
                                                        </fieldset>
                                                    </>
                                                )}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={(stGetBackgrounds) ? stGetBackgrounds[1] + " col-6 p-3" : "col-6 p-3"} style={{ height: 'auto' }}>
                                <div className='d-flex'>
                                    <span className='s-no'>2.</span>
                                    <span className='section-head'>DARPAN
                                        <span className='required'>*</span>
                                        <span className='Info-circle ml-2'
                                            data-target="#fileInfo"
                                            data-toggle="modal"
                                            onClick={() => setModalPopup("DARPAN")}
                                        >
                                            ℹ️
                                        </span>
                                        <span>
                                            &nbsp;
                                            <span className='fileRemove text-center'>
                                                <span >{getFilesData.proof_Of_Darpan_status_ind == '4' ? <span className='acceptFile1'>Accepted</span> : (getFilesData.status_ind > '2' && getFilesData.proof_Of_Darpan_status_ind == '0') ? <span className='rejectFile1'>Rejected</span> : ""}</span>
                                            </span>
                                        </span>
                                    </span>
                                </div>

                                <div className="row mt-4">
                                    {/* <div className="col"></div> */}
                                    <div className="col-12 text-left d-flex m-auto" style={{ alignItems: 'baseline', paddingLeft: '27px' }}>
                                        {
                                            <label htmlFor="">
                                                Darpan ID :
                                            </label>
                                        }
                                        <div className="d-flex" style={{ alignItems: 'center' }}>
                                            {loggedInRole == "incubator" &&
                                                <input
                                                    type="text"
                                                    name="Darpan"
                                                    readOnly={enableInput1 || getFilesData.proof_Of_Darpan_status_ind > '1'}
                                                    value={getFilesData.proof_Of_Darpan_file_path_file_name}
                                                    placeholder='Enter Darpan ID'
                                                    onChange={handleChange}
                                                    className='ml-2'
                                                    required
                                                />}
                                            {
                                                loggedInRole == "secretariat" && 
                                                <span className='regText'>
                                                    {getFilesData.proof_Of_Darpan_file_path_file_name}
                                                </span>
                                            }
                                            {loggedInRole == "incubator" &&
                                                <div className='pfmsBtns'>
                                                    {getFilesData.proof_Of_Darpan_status_ind != '4' && loggedInRole == "incubator" &&
                                                        <>
                                                            {enableInput1 && <button className="btn btn-sisfs btn-yellow-primary ml-2"
                                                                onClick={() => { editBtn("Darpan") }}
                                                                disabled={getFilesData.proof_Of_Darpan_status_ind > '1'}
                                                            >
                                                                Edit
                                                            </button>}
                                                            {!enableInput1 &&
                                                                <button className="btn btn-sisfs btn-yellow-primary ml-2"
                                                                    onClick={() => { submitInput("Darpan", darpanInput, 1) }}
                                                                    disabled={getFilesData.proof_Of_Darpan_status_ind > '1'}
                                                                >
                                                                    Save
                                                                </button>
                                                            }
                                                        </>
                                                    }
                                                </div>}
                                            {loggedInRole == "incubator" &&
                                                (getFilesData.proof_Of_Darpan_status_ind == '0' || getFilesData.proof_Of_Darpan_status_ind == '4') &&
                                                <span className='fileRemove text-center ml-2' onClick={() => fileCommentsPopup(getFilesData.proof_Of_Darpan_file_path_file_name, getFilesData.proof_Of_Darpan_status_ind, "comment", "1", getFilesData.proof_Of_Darpan_comments)}>
                                                    <span className='commentsFile'>Comments</span>
                                                </span>
                                            }
                                            {
                                                loggedInRole == "secretariat" && 
                                                <>
                                                    <div className='ml-5'>
                                                       {loggedInRole == "secretariat" && params.tranche_status != "Incomplete" &&
                                                       <>
                                                        <span className='fileRemove text-center' onClick={() => (getFilesData.proof_Of_Darpan_status_ind == '2' || getFilesData.proof_Of_Darpan_status_ind == '3') && acceptFilePopup(getFilesData.proof_Of_Darpan_file_path_file_name, getFilesData.proof_Of_Darpan_status_ind, "accept", "1", getFilesData.proof_Of_Darpan_comments)}>

                                                            <span className='acceptFile'>{(getFilesData.proof_Of_Darpan_status_ind == '2' || getFilesData.proof_Of_Darpan_status_ind == '3') ? <span onClick={() => getFileVisibiltyStatus(1)} >Accept</span> : ""}</span>
                                                        </span>
                                                        <span className='fileRemove text-center ml-2' onClick={() => (getFilesData.proof_Of_Darpan_status_ind == '2' || getFilesData.proof_Of_Darpan_status_ind >= '3') && removeFilePopup(getFilesData.proof_Of_Darpan_file_path_file_name, getFilesData.proof_Of_Darpan_status_ind, "reject", "1", getFilesData.proof_Of_Darpan_comments)}>

                                                            <span className='rejectFile'>{
                                                                (params.tranche_status == "Disbursed" || params.tranche_status == "Disbursement in Process" || params.tranche_status == "Incomplete") ? "" :
                                                                    (getFilesData.proof_Of_Darpan_status_ind == '2' || getFilesData.proof_Of_Darpan_status_ind >= '3') ? <span onClick={() => getFileVisibiltyStatus(1)} >Reject</span> : ""}</span>
                                                        </span>
                                                       </>
                                                        }
                                                        {
                                                            (getFilesData.proof_Of_Darpan_status_ind == '0' || getFilesData.proof_Of_Darpan_status_ind == '4') &&
                                                            <span className='fileRemove text-center ml-2' onClick={() => fileCommentsPopup(getFilesData.proof_Of_Darpan_file_path_file_name, getFilesData.proof_Of_Darpan_status_ind, "comment", "1", getFilesData.proof_Of_Darpan_comments)}>
                                                                <span className='commentsFile'>Comments</span>
                                                            </span>
                                                        }
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                    {/* <div className="col"></div> */}
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center">


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={stGetBackgrounds ? stGetBackgrounds[2] + " row section-bg mb-2 p-0" : "row section-bg mb-2 p-0"} style={{ height: 'auto' }}>

                            <div className="col-6 p-3" >
                                <div className='d-flex'>
                                    <span className='s-no'>3.</span>
                                    <span className='section-head'>ECS Mandate Form
                                        <span className='required'>*</span>
                                        <span className='Info-circle ml-2'
                                            data-target="#fileInfo"
                                            data-toggle="modal"
                                            onClick={() => setModalPopup("ECS_Mandate")}
                                        >
                                            ℹ️
                                        </span>
                                        <span>
                                            &nbsp;
                                            <span className='fileRemove text-center'>
                                                <span >{getFilesData.ECS_Mandate_status_ind == '4' ? <span className='acceptFile1'>Accepted</span> : (getFilesData.status_ind > '2' && getFilesData.ECS_Mandate_status_ind == '0') ? <span className='rejectFile1'>Rejected</span> : ""}</span>
                                            </span>
                                        </span>
                                    </span>
                                </div>
                                <div className="row">
                                    <div className="fileDiv p-2 ml-2 mr-2">
                                        {loggedInRole == "incubator" && <button
                                            className="btn btn-sisfs btn-transparent-primary"
                                            onClick={() => setOpenUploadBlock({ ecs: true })}
                                        >
                                            Upload
                                        </button>}
                                        {((openUploadBlock &&
                                            (openUploadBlock["ecs"] == true || getFilesData.ECS_Mandate_file_path_file_name))) && (
                                                <>
                                                    {!getFilesData.ECS_Mandate_file_path_file_name && <span
                                                        className="close"
                                                        onClick={() => setOpenUploadBlock({ ecs: false })}
                                                    >
                                                        <i className="fa fa-close"></i>
                                                    </span>}

                                                    <fieldset className='fileUploadSec mt-2'>
                                                        <div className="" style={{ width: '100%' }}>
                                                            {loggedInRole == "incubator" &&
                                                                <div className="filebox">
                                                                    {/* <div className='mt-3'>Drag & Drop file or Browse</div> */}
                                                                    <div className='mt-2'><img src={fileupload} style={{ width: '2rem' }} alt="" /></div>

                                                                    <input disabled={getFilesData.ECS_Mandate_status_ind > '1'} type="file" accept='.pdf' id="ecs" name="ecsFile" onChange={(e) => handleFileChange(e, 2)} hidden />
                                                                    <label htmlFor="ecs" className="btn btn-sisfs btn-transparent-primary mt-1" >Browse</label>

                                                                    {getFilesData.ECS_Mandate_status_ind == '0' && <p style={{ fontSize: '12px', color: 'red', fontWeight: 'bold' }}>* Your file was rejected, please read the comments before upload</p>}
                                                                </div>}
                                                            {getFilesData.ECS_Mandate_file_path_file_name &&
                                                                <div className={loggedInRole == "secretariat" ? 'previewDiv mb-3 filebox1 filebox1Sec' : 'previewDiv mb-3 filebox1'} >
                                                                    <div className='fileSection'>
                                                                        <div>
                                                                            <span className='ml-3'>
                                                                                <img src={pdfImg} alt="" />
                                                                            </span>
                                                                            <span className='ml-3'>
                                                                                {getFilesData.ECS_Mandate_file_path_file_name ?
                                                                                    <a href={`${NODE_URL + getFilesData.ECS_Mandate_file_path}`}
                                                                                        onClick={() => getFileVisibiltyStatus('2')}
                                                                                        target='_blank'
                                                                                    >
                                                                                        {getFilesData.ECS_Mandate_file_path_file_name && getFilesData.ECS_Mandate_file_path_file_name.length > 15 ? getFilesData.ECS_Mandate_file_path_file_name.slice(0, 15) + "..." : getFilesData.ECS_Mandate_file_path_file_name && getFilesData.ECS_Mandate_file_path_file_name}
                                                                                    </a> :
                                                                                    ""
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                        <div className='d-flex'>

                                                                            {
                                                                                loggedInRole == "secretariat" && params.tranche_status != "Incomplete" &&
                                                                                <>
                                                                                    <span className='fileRemove text-center' onClick={() => (getFilesData.ECS_Mandate_status_ind == '2' || getFilesData.ECS_Mandate_status_ind == '3') && acceptFilePopup(getFilesData.ECS_Mandate_file_path_file_name, getFilesData.ECS_Mandate_status_ind, "accept", "2", getFilesData.ECS_Mandate_comments)}>

                                                                                        <span className='acceptFile'>{(getFilesData.ECS_Mandate_status_ind == '2' || getFilesData.ECS_Mandate_status_ind == '3') ? "Accept" : ""}</span>
                                                                                    </span>
                                                                                    <span className='fileRemove text-center ml-2' onClick={() => (getFilesData.ECS_Mandate_status_ind == '2' || getFilesData.ECS_Mandate_status_ind >= '3') && removeFilePopup(getFilesData.ECS_Mandate_file_path_file_name, getFilesData.ECS_Mandate_status_ind, "reject", "2", getFilesData.ECS_Mandate_comments)}>

                                                                                        <span className='rejectFile'>{
                                                                                            (params.tranche_status == "Disbursed" || params.tranche_status == "Disbursement in Process" || params.tranche_status == "Incomplete") ? "" :
                                                                                                (getFilesData.ECS_Mandate_status_ind == '2' || getFilesData.ECS_Mandate_status_ind >= '3') ? "Reject" : ""}</span>
                                                                                    </span>
                                                                                </>
                                                                            }
                                                                            {
                                                                                (getFilesData.ECS_Mandate_status_ind == '0' || getFilesData.ECS_Mandate_status_ind == '4') &&
                                                                                <span className='fileRemove text-center ml-2' onClick={() => fileCommentsPopup(getFilesData.ECS_Mandate_file_path_file_name, getFilesData.ECS_Mandate_status_ind, "comment", "2", getFilesData.ECS_Mandate_comments)}>
                                                                                    <span className='commentsFile'>Comments</span>
                                                                                </span>
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                </div>}
                                                        </div>
                                                    </fieldset>
                                                </>
                                            )}
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row mt-4 mb-4">
                            <div className="col-12 text-right">
                                <button className="btn btn-sisfs btn-yellow-primary mr-4"
                                    onClick={backToFundDetails}
                                >
                                    Back to fund details
                                </button>
                                {
                                    loggedInRole == "secretariat"
                                    &&
                                    getAllZipFiles &&
                                    < button className="btn btn-sisfs btn-yellow-primary mr-5"
                                        // onClick={downloadAsZip}
                                    >
                                        <a href={NODE_URL+'/'+getIncZipFile} target="_blank" rel="noopener noreferrer" style={{textDecoration:'none',color:'#25429c'}}>Download All Files</a> 
                                      
                                    </button>}
                                {
                                    loggedInRole == "secretariat" ?

                                        <button className="btn btn-sisfs btn-yellow-primary"

                                            disabled={(params.tranche_status == "Disbursed" || params.tranche_status == "Disbursement in Process" || params.tranche_status == "Incomplete")}
                                            onClick={submit}
                                        >
                                            Submit
                                        </button> :

                                        <button className="btn btn-sisfs btn-yellow-primary"
                                            disabled={(loggedInRole == "incubator" && (!checkStatus))}
                                            onClick={submit}
                                        >
                                            Submit
                                        </button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-1"></div>
                </div >


            </fieldset >

            {showSubmitSuccessModal &&
                <>
                    <div className="modal-backdrop fade show"></div>
                    <div
                        className="modal align-middle modal-sisfs d-block"
                        id="FormSubmissionError"
                    >
                        <div className="modal-dialog modal-dialog-centered modal-md">
                            <div className="modal-content">
                                <div className="modal-body text-center">
                                    {/* <i className="fa fa-times-circle-o"></i> */}
                                    <h3 className="modal-message message-orange">
                                        {errorMessage}
                                    </h3>
                                    <div className="modal-action">
                                        <button
                                            className="btn btn-sisfs btn-yellow-primary"
                                            onClick={backToFundDetails}
                                        >
                                            OK !
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }

            {
                showSubmitFailureModal && (
                    <>
                        <div className="modal-backdrop fade show"></div>
                        <div
                            className="modal align-middle modal-sisfs d-block"
                            id="FormSubmissionError"
                        >
                            <div className="modal-dialog modal-dialog-centered modal-md">
                                <div className="modal-content">
                                    <div className="modal-body text-center">
                                        {/* <i className="fa fa-times-circle-o"></i> */}
                                        <h3 className="modal-message message-orange">
                                            {errorMessage}
                                        </h3>
                                        <div className="modal-action">
                                            <button
                                                className="btn btn-sisfs btn-yellow-primary"
                                                onClick={dismissModal}
                                            >
                                                OK !
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }

            {
                showEditSuccessModal && (
                    <>
                        <div className="modal-backdrop fade show"></div>

                        <div
                            className="modal align-middle modal-sisfs d-block"
                            id="FormSubmissionSuccess"
                        >
                            <div className="modal-dialog modal-dialog-centered modal-md">
                                <div className="modal-content">
                                    <div className="modal-body text-center">
                                        <i className="fa fa-check-circle-o"></i>
                                        <h3 className="modal-message">Thank You !</h3>
                                        <div className="modal-text">
                                            {tranche_message}
                                        </div>
                                        <div className="modal-action">
                                            <button
                                                className="btn btn-sisfs btn-yellow-primary"
                                                // onClick={() => window.location.reload()}
                                                onClick={goToFundDetailsTab}
                                            >
                                                Ok !!
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
            {/* confirmation popup */}
            {
                confirmationSubmitPopup && (
                    <div
                        className="modal align-middle modal-sisfs d-block"
                        id="FormSubmissionError"
                    >
                        <div className="modal-dialog modal-dialog-centered modal-md">
                            <div className="modal-content">
                                <div className="modal-body text-center">

                                    <h3 className="modal-message mt-2">
                                        Are you sure you want to submit.
                                    </h3>
                                    <h6 className=" message-orange">
                                        Note: Once you submit, you are no longer able to edit again.
                                    </h6>
                                    <div className="modal-action mt-2">
                                        <button
                                            className="btn btn-sisfs btn-transparent-primary"
                                            onClick={() => setConfirmationSubmitPopup(false)}>
                                            NO
                                        </button>
                                        <button
                                            className="btn btn-sisfs btn-yellow-primary ml-2"
                                            onClick={confirmSubmit}
                                        >
                                            YES
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                confirmationFilePopup && (
                    <div
                        className="modal align-middle modal-sisfs d-block"
                        id="FormSubmissionError"
                        data-backdrop="static"
                        data-keyboard="false"
                    >
                        <div className={currentFileDetails.action == "comment" ? "modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable" : "modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable"}>
                            <div className="modal-content">
                                <div className="modal-body ">

                                    <h6 className="modal-message mt-2">
                                        {/* {console.log(currentFileDetails)}
                                    {currentFileDetails.name} <br />
                                    {currentFileDetails.status} <br />
                                    {currentFileDetails.file_no} */}
                                        {currentFileDetails.action == "accept" ?
                                            "Are you sure you want to accept the file?" :
                                            currentFileDetails.action == "reject" ? "Are you sure you want to reject the file?" :
                                                "Comments"
                                        }

                                    </h6>
                                    {<span className="text-danger">{readFileFirst}</span>}
                                    {currentFileDetails.action == "comment" &&

                                        <>
                                            {currentFileDetails.comment == null ? "No Comments" :
                                                <div style={{ maxHeight: '40vh', overflow: 'scroll' }}>
                                                    <table className='table-bordered mt-0'>
                                                        <thead>
                                                            <th className='text-center'>Status</th>
                                                            <th className='text-center'>Comment</th>
                                                            <th className='text-center'>Date</th>
                                                        </thead>
                                                        <tbody>
                                                            {currentFileDetails.comment && currentFileDetails.comment.map((item: any, i) => (

                                                                <>
                                                                    {
                                                                        <tr key={i}>
                                                                            <td style={{ verticalAlign: 'baseline', textAlign: 'center' }} >{item.split(':')[0]}</td>
                                                                            <td className='commentsColumn' style={{ textAlign: 'center' }}>{item.split('ted:')[1].split('Time$#')[0]}</td>
                                                                            <td style={{ verticalAlign: 'baseline', textAlign: 'center' }}>{moment(item.split('Time$#')[1]).format("DD-MM-YYYY hh:mm:a")}</td>
                                                                        </tr>
                                                                    }
                                                                </>

                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            }
                                        </>
                                    }
                                    {currentFileDetails.action != "comment" &&
                                        <>
                                            <div className='rejectInput'>
                                                <textarea
                                                    className={!rejectedText ? "errorVal" : ""}
                                                    onChange={hanndleCmntChange}
                                                    name="reject"
                                                    placeholder={"Please add comment for" + `${currentFileDetails.action == "accept" ? " Acceptance" : " Rejection"}`}
                                                    maxLength={2000}
                                                />
                                            </div>
                                            {/* {!rejectedText && <><small className="text-danger" style={{fontSize:'14px',fontWeight:'500'}}>Comment is required</small> <br /></>  }  */}

                                            <small><i>Max.characters - 2000</i></small>
                                        </>
                                    }
                                    {/* <h6 className=" message-orange">
                                    Note: Once you submit, you are no longer able to edit again.
                                </h6> */}
                                    <div className="modal-action mt-3 text-center">

                                        <button
                                            className="btn btn-sisfs btn-transparent-primary"
                                            // onClick={() => setConfirmationPopup(false)}
                                            onClick={dismissModal}
                                        >
                                            {currentFileDetails.action == "comment" ? "OK" : "NO"}
                                        </button>
                                        {currentFileDetails.action != "comment" && <button
                                            className="btn btn-sisfs btn-yellow-primary ml-2"
                                            // onClick={confirmSubmit}
                                            onClick={() => currentFileDetails.action == "reject" ? removeFileUpload(currentFileDetails.file_no, rejectedText) : acceptFileUpload(currentFileDetails.file_no, rejectedText)}
                                        >
                                            YES
                                        </button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            <div
                className="modal fade"
                id="fileInfo"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-centered modal-lg"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header modal-header-border-none pb-0">
                            <div className='text-center'>
                                {/* <h5 className="modal-title " id="exampleModalLongTitle">

                                    <span>ISFS Subsequent Tranche SOP – Do's and Don’ts
                                    </span>
                                </h5> */}
                            </div>

                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {/* <div className="row mb-3">
                                <div className="col-12 text-center">
                                    <h5 className="modal-title " id="exampleModalLongTitle">

                                        <span>SISFS Subsequent Tranche SOP – Do's and Don’ts
                                        </span>
                                    </h5>
                                </div>
                            </div> */}
                            <div className="pl-3">
                                {filename == "PFMS" &&
                                    <>
                                        <span className='Info-subhead1'>Enter your PFMS (Public Financial Management System) ID. In case, the incubator is not registered on PFMS, please provide the details for registration in the attached Excel based Agency Registration template.</span>
                                        <span>
                                            <br />
                                            <a className='sampleDoc' href={'http://35.154.181.26:8585/Downloads/Excel_Based_Agency_Registration.xls'}>Download Sample Excel based Registration Template</a>
                                        </span>
                                    </>}
                                {filename == "DARPAN" &&
                                    <>
                                        <span className='Info-subhead1'>Get registered on the Darpan Portal of NITI Aayog (<a href="https://www.ngodarpan.gov.in" target='_blank'>www.ngodarpan.gov.in</a>)
                                            and share the DARPAN ID</span> </>}
                                {filename == "ECS_Mandate" &&
                                    <>
                                        <span className='Info-subhead1'>Open a Trust and Retention Account (TRA) in any Nationalised bank and share the bank account details as per ECS Bank Mandate form attached</span>
                                        <span>
                                            <br />
                                            <a className='' href={'http://35.154.181.26:8585/Downloads/ECS_Bank_Mandate_form.pdf'} target='_blank' >Download Sample ECS Mandate</a>
                                        </span>
                                    </>}
                            </div>


                            {/* <FileUploadInfo fileName={filename} /> */}
                            <div className="row pt-3 mb-3">
                                <div className="col-md-12 centerText"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                showUploadFailureModal && (
                    <ConfirmationDialog
                        headerText={failDialogData.header}
                        bodyText={failDialogData.text}
                        onDialogClose={() => setShowUploadFailureModal(false)}
                        iconClass="showImage"
                    />
                )
            }
            {
                showEditFailureModal && (
                    <>
                        <div className="modal-backdrop fade show"></div>
                        <div
                            className="modal align-middle modal-sisfs d-block"
                            id="FormSubmissionError"
                        >
                            <div className="modal-dialog modal-dialog-centered modal-md">
                                <div className="modal-content">
                                    <div className="modal-body text-center">
                                        {/* <i className="fa fa-times-circle-o"></i> */}
                                        <h3 className="modal-message message-orange">
                                            Oops! Something is Wrong
                                        </h3>
                                        <br />
                                        <h3 className="modal-message message-orange">
                                            Please Try Again !!!
                                        </h3>
                                        <div className="modal-action">
                                            <button
                                                className="btn btn-sisfs btn-yellow-primary"
                                                onClick={dismissModal}
                                            >
                                                OK !
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
            {
                exceedFileSize && (
                    <div
                        className="modal align-middle modal-sisfs d-block"
                        id="FormSubmissionError"
                    >
                        <div className="modal-dialog modal-dialog-centered modal-md">
                            <div className="modal-content">
                                <div className="modal-body text-center">
                                    <img
                                        src={error}
                                        className="search mb-2"
                                        alt="search"
                                        style={{ width: '2rem' }}
                                    />
                                    <h4 className="message-orange" style={{ fontFamily: "Roboto Slab" }}>
                                        Oops!!!
                                    </h4>
                                    <h5 className="message-orange" style={{ fontFamily: "Roboto Slab" }}>
                                        File size should not exceed 5Mb<br /><br />
                                        Please upload again.
                                    </h5>
                                    <div className="modal-action">
                                        <button
                                            className="btn btn-sisfs btn-yellow-primary"
                                            onClick={dismissModal}
                                        >
                                            OK !
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )

}
export default FirstTrancheSubsequent;