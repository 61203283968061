document.onreadystatechange = function () {
    var Hostname = window.location.host;
    // var Hostname = 'http://localhost:3000/';
    // var Hostname = 'https://seedfund.startupindia.gov.in/';
    console.log(Hostname,"Hostname")
    var lngDDL = document.getElementById("P9LngDdl");
    var lang = getMoxCookie('lang');
    console.log(lang,"readystate")
    console.log(lngDDL.value,lang,"lngDDL")
    if (lang) {
        lngDDL.value = lang;
    } else {
        lngDDL.value = "english";
    }
    // console.log("initiall")
}

function removeCookie(name, domainName) {
    var date = new Date();
    date.setTime(date.getTime() - (24 * 60 * 60 * 1000)); 
    var expires = "expires=" + date.toUTCString();

    if (domainName == '') {
        document.cookie = name + "=" + ";expires=" + expires + ";path=/";
    } else {
        document.cookie = name + "=" + ";expires=" + expires + "; path=/;domain=" + domainName;
    }
}
function getMoxCookie(key) {
    var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
    return keyValue ? keyValue[2] : null;
}

function setCookie(name, value) {
    var cookieExist = getMoxCookie(name)
    console.log(cookieExist,"cookieE")
    if (cookieExist != '') {
                    //remove all existing cookie with 'MOXLanguage' and then set new cookie
                    removeCookie("lang", ".https://seedfund.startupindia.gov.in/");
                }
    var date = new Date();
    date.setTime(date.getTime() + (1 * 24 * 60 * 60 * 1000));
    var expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
    console.log(document.cookie,"44")
  }
//   function setCookie(key, value) {
//     var cookieExist = getMoxCookie(key)
//         if (cookieExist != '') {
//             //remove all existing cookie with 'MOXLanguage' and then set new cookie
//             removeCookie("lang", ".<Your Domain>");
//         }
//         var expires = new Date();
//     expires.setTime(expires.getTime() + 86400000);
//     document.cookie = key + '=' + value + '; path=/;domain=.<Your Domain>;expires=' + expires.toUTCString();
// }

export function ChangeLanguage(dropMenu) {
    // var strLang = dropMenu.options[dropMenu.selectedIndex].value;
    var strLang = dropMenu
console.log(strLang,"strLang")
    // if (strLang == "english"){
    //     removeCookie("lang", ".https://seedfund.startupindia.gov.in/");
    // }else{
        setCookie('lang', strLang);
    window.location.reload(true);
    // }
}





// ******************
// document.onreadystatechange = function () {
//     var Hostname = location.host;
//     var lngDDL = document.getElementById("P9LngDdl");
//     var lang = getMoxCookie('lang');
//     if (lang) {
//         lngDDL.value = lang;
//     } else {
//         lngDDL.value = "English";
//     }
// }

// function removeCookie(name, domainName) {
//     if (domainName == '') {
//         document.cookie = name + '="";expires=Thu, 2 Aug 2001 20:47:11 UTC;path=/';
//     } else {
//         document.cookie = name + '="";expires=Thu, 2 Aug 2001 20:47:11 UTC; path=/;domain=' + domainName;
//     }
// }
// function getMoxCookie(key) {
//     var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
//     return keyValue ? keyValue[2] : null;
// }

// function setCookie(key, value) {
//     var cookieExist = getMoxCookie(key)
//         if (cookieExist != '') {
//             //remove all existing cookie with 'MOXLanguage' and then set new cookie
//             removeCookie("lang", ".<Your Domain>");
//         }
//         var expires = new Date();
//     expires.setTime(expires.getTime() + 86400000);
//     document.cookie = key + '=' + value + '; path=/;domain=.<Your Domain>;expires=' + expires.toUTCString();
// }

// export function ChangeLanguage(dropMenu) {
//     var strLang = dropMenu.options[dropMenu.selectedIndex].value;
//     if (strLang == "English")
//         removeCookie("lang", ".<Your Domain>");
//     else
//         setCookie('lang', strLang);
//     window.location.reload(true);
// }

//************************************ */














// document.onreadystatechange = function () {
//     var Hostname = window.location.host;
//     var lngDDL = document.getElementById("P9LngDdl");
//     var lang = getMoxCookie('lang');
//     if (lang) {
//         lngDDL.value = lang;
//     } else {
//         lngDDL.value = "English";
//     }
//     // console.log(lang,lngDDL.value,"lang")
// }

// function removeCookie(name, domainName) {
//     if (domainName == '') {
//         document.cookie = name + '="";expires=Thu, 2 Aug 2001 20:47:11 UTC;path=/';
//     } else {
//         document.cookie = name + '="";expires=Thu, 2 Aug 2001 20:47:11 UTC; path=/;domain=' + domainName;
//     }
// }
// function getMoxCookie(key) {
//     var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
//     return keyValue ? keyValue[2] : null;
// }

// function setCookie(key, value) {
//     var cookieExist = getMoxCookie(key)
//         if (cookieExist != '') {
//             //remove all existing cookie with 'MOXLanguage' and then set new cookie
//             removeCookie("lang", ".seedfund.startupindia.gov.in/");
//         }
//         var expires = new Date();
//     expires.setTime(expires.getTime() + 86400000);
//     document.cookie = key + '=' + value + '; path=/;domain=.seedfund.startupindia.gov.in/;expires=' + expires.toUTCString();
// }

// export function ChangeLanguage(dropMenu) {
//     var strLang = dropMenu
//     // .options[dropMenu.selectedIndex].value;
//     console.log(strLang)

//     if (strLang == "English")
//         removeCookie("lang", ".seedfund.startupindia.gov.in/");
//     else
//         setCookie('lang', strLang);
//     window.location.reload(true);
// }