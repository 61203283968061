import React from "react";
import { Link } from "react-router-dom";
import eligibilityfeatures from '../../../../images/features-small.svg';
import seedfundhelp from '../../../../images/seedfund-help.svg';

/** Render Application Status for Incubator screen */
function GetApplicationInfoComponent() {
    return (
        <>
            <section className="section-container">
                <div className="container">
                    <div className="section-eligibility">

                        <div className="section-heading pb-4">
                            <h2>Helpful Resources</h2>
                        </div>

                        <div className="row justify-content-center pt-3 pb-3">

                            <div className="col-xs-12 col-lg-5">
                                <div className="eligibility-wrapper text-center">
                                    <div className="section-image">
                                        <div className="item-center height-250">
                                            <img className="img-fluid" src={eligibilityfeatures} alt="Features of SISFS" />
                                        </div>
                                    </div>
                                    <div className="content-section">
                                        <div className="content-heading">
                                            Eligibility Criteria
                                            </div>

                                        <div className="content-body">
                                            <p>
                                                Incubator should be operational for at least two
                                                years on the date of application to the scheme
                                                </p>
                                            <div className="text-center mt-3 mb-3 pt-1">
                                                {/* <button type="button" className="btn btn-sisfs btn-transparent-primary">More Details</button> */}
                                                <Link className="btn btn-sisfs btn-transparent-primary" to={{ pathname: "/about", state: { selected_tab: "incubator" } }}>
                                                    More Details
                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xs-12 col-lg-5">
                                <div className="eligibility-wrapper text-center">
                                    <div className="section-image">
                                        <div className="item-center">
                                            <img className="img-fluid" src={seedfundhelp} alt="Seed Fund Need" />
                                        </div>
                                    </div>
                                    <div className="content-section">
                                        <div className="content-heading">
                                            F.A.Q.
                                            </div>

                                        <div className="content-body">
                                            <p>
                                                The most frquently asked questions about the
                                                application process, evaluations process and much
                                                more…
                                                </p>
                                            <div className="text-center mt-3 mb-3 pt-1">
                                                <Link className="btn btn-sisfs btn-transparent-primary" to={{ pathname: "/faq", state: { selected_tab: "incubator" } }} >Find Them Here</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default GetApplicationInfoComponent;