import { count } from "console";
import { stat } from "fs";
import { FileDataModel } from "../common/FileDataModel";
import { FileUploadModel } from "../FileUploadModel";

export class GeneralDetailModel {
  constructor(
    public registeredName: string,
    //public incubatorName: string,
    public industryIds: Array<string>,
    public sectorIds: Array<string>,
    public stateId: any,
    public cityId: any,
    public address: string,
    public representative_name: string,
    public representative_designation: string,
    public representative_mobile_no: string,
    public representative_email: string,
    public typeLegalEntityId: number,
    public registrationDate: any,
    public corporateId: string,
    public operationalization_date: any,
    public seating_capacity_individulas: number,
    public no_startups_incubation: number,
    public fund_disburse_using_3rd_party: string,
    public central_incubator_far_id: Array<number>,
    public nature_assistance_incubator: string,
    public annual_report: Array<FileUploadModel>,
    public proof_assistance: Array<FileUploadModel>,
    public incorporation_certificate: Array<FileUploadModel>,
    public facebook_link: string,
    public twitter_link: string,
    public linkedin_link: string,
    public youtube_link: string,
    public website_url: string,
    public incubator_name: string,
    public facebook_enable: boolean,
    public twitter_enable: boolean,
    public linkedin_enable: boolean,
    public youtube_enable: boolean,
    public pan_card_number: string,
    public pan_card_upload: Array<FileUploadModel>,
    public authorisation_letter_upload: Array<FileUploadModel>,
    public adhaar_card_upload: Array<FileUploadModel>,
    public startup_incubation_upload: Array<FileUploadModel>,
  ) {
    this.registeredName = registeredName;
    //this.incubatorName = incubatorName;
    this.industryIds = industryIds;
    this.sectorIds = sectorIds;
    this.stateId = stateId;
    this.cityId = cityId;
    this.address = address;
    this.representative_name = representative_name;
    this.representative_designation = representative_designation;
    this.representative_mobile_no = representative_mobile_no;
    this.representative_email = representative_email;
    this.typeLegalEntityId = typeLegalEntityId;
    this.registrationDate = registrationDate;
    this.corporateId = corporateId;
    this.operationalization_date = operationalization_date;
    this.seating_capacity_individulas = seating_capacity_individulas;
    this.no_startups_incubation = no_startups_incubation;
    this.fund_disburse_using_3rd_party = fund_disburse_using_3rd_party;
    this.central_incubator_far_id = central_incubator_far_id;
    this.nature_assistance_incubator = nature_assistance_incubator;
    this.annual_report = annual_report;
    this.proof_assistance = proof_assistance;
    this.incorporation_certificate = incorporation_certificate;
    this.facebook_link = facebook_link;
    this.twitter_link = twitter_link;
    this.linkedin_link = linkedin_link;
    this.youtube_link = youtube_link;
    this.website_url = website_url;
    this.incubator_name = incubator_name;
    this.facebook_enable = facebook_enable;
    this.linkedin_enable = linkedin_enable;
    this.twitter_enable = twitter_enable;
    this.youtube_enable = youtube_enable;
    this.pan_card_number = pan_card_number;
    this.pan_card_upload = pan_card_upload;
    this.authorisation_letter_upload = authorisation_letter_upload;
    this.adhaar_card_upload = adhaar_card_upload;
    this.startup_incubation_upload = startup_incubation_upload;
  }
}
