import { useEffect, useRef, useState } from "react";
import {
  GET_APPLICATION_LIST,
  GET_APPLICATION_VIEW,
  REPLACE_FILE,
  DELETE_APPLICATION,
  EVALUATION_FILE,
  STARTUP_ALL_APPLICATIONS,
  GET_GRIEVANCE_LIST,
  USER_LIST,
  USER_ACTIVE_INACTIVE,
  GET_STARTUP_LISTING,
  GET_STARTUP_LISTING_DUPLICATE_API,
} from "../../../constants/api";
import { get, post } from "../../../effects/api/api.common";
// import ContactSectionComponent from "./ContactSectionComponent";
// import GetApplicationInfoComponent from "./GetApplicationInfoComponenet";
import { useHistory, NavLink } from "react-router-dom";
import search from "../../../images/search.svg";
import { getSessionStorageValue } from "../../../effects/utils/session-storage";
import {
  changeNumberFormat,
  filterByValue,
} from "../../../utils/CommonMethods";
// import { FormatDate } from "../../../../utils/DateFormat";
// import { downloadDocument } from "../../../../utils/CommonMethods";
/** Render Application Status for Incubator screen */

import {Modal} from 'react-bootstrap'
import { StartUpReportComponent } from "../../incubator/startuplisting/startupdetails/StartUpReportComponent";
import EacHeaderComponent from "../common/EacHeaderComponent";
import DashboardFooterComponent from "../../common/footer/DashboardFooterComponent";

 
function EacStartupListingComponent() {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [activeIndex, setActiveIndex] = useState("grievances");
  const [activeTab, setActiveTab] = useState("grievances");
  const [noDataFilter, setNoDataFilter] = useState("");
  const [showToEac, setShowToEac] = useState(false);
  const [userList, setUserList] = useState([]);
  const [userListSelected, setUserListSelected] = useState([]);
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [filteredUserListSelected, setFilteredUserListSelected] = useState([]);
  const [loggedInRole, setLoggedInRole] = useState("");
  const [showReport, setShowReport] = useState(false);
  const user: any = getSessionStorageValue("user");
  const userInfo = JSON.parse(user);
  

  useEffect(() => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    setLoggedInRole(userInfo.roles);
    window.scrollTo(0, 0);
    // getIncubatorCount();
    getIncubatorCountSelected();
    // getUserList();
  }, []);
  const changeActive = (tabs: any) => {
    setActiveIndex(tabs);
  };
  // get initial count of incubators
  const getIncubatorCountSelected = () => {
    // setLoader(true);
    post(GET_STARTUP_LISTING_DUPLICATE_API, { status_id: [7], logged_user_id :userInfo.id, userRole :userInfo.roles }).then((response: any) => {
      if (!response || !response.data) return;

      if (response.data.applications.length != 0) {
        setUserListSelected(response.data.applications);
      } else {
        setUserListSelected([]);
      }
      // setLoader(false);
      getIncubatorCount();
    });
  };
  const count = useRef(0);
  // get initial count of incubators
  const getIncubatorCount = () => {
    setLoader(true);
    post(GET_STARTUP_LISTING_DUPLICATE_API, {status_id: "0", logged_user_id : userInfo.id, userRole : userInfo.roles}).then((response: any) => {
    setLoader(false);
      if (!response || !response.data) return;

      if (response.data.applications.length != 0) {
        setUserList(response.data.applications);
      } else {
        setUserList([]);
      }
    });
  };
  // const getUserList = () => {
  //     post(USER_LIST, '').then((response: any) => {
  //         if (!response || !response.data) return;
  //         setUserList(response.data.data)
  //     });
  // };

  const searchList = (e: any) => {
    setNoDataFilter(e.target.value);
    const filteredData = filterByValue(userList, e.target.value);
    const filteredSelectedDate = filterByValue(
      userListSelected,
      e.target.value
    );
    //console.log(filteredData);
    setFilteredUserList(filteredData);
    setFilteredUserListSelected(filteredSelectedDate);

    // //console.log(filteredData)
  };

  const handleRowChange = (id: any) => {
    let flag = false;
    if (activeIndex == "tranche") {
      flag = true;
    } else {
      flag = false;
    }
    if (loggedInRole == "dpiit") {
      history.replace({
        pathname: "/dpiit_startup_details",
        state: { application_id: id, logged_in_role: "dpiit" },
      });
    } else if (loggedInRole == "secretariat") {
      history.replace({
        pathname: "/secretariat_startup_details",
        state: { application_id: id, logged_in_role: "secretariat" },
      });
    }
  };

  return (<>
  

    <EacHeaderComponent />
    <div className="app-inside app-apply-incubator">
      
      <div className="status-list-wrapper mt-4">
        {loader && (
          <div className="spinner-border custom-spinner" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}
        <fieldset disabled={loader}>
          <div className="container">
            <div className="row">
              <div className="col-7 text-left pt-4">
                {/* <p className="dashboard">Startups</p> */}
                <ul className="nav nav-tabs chart-nav" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#tabs-2"
                      role="tab"
                    >
                      Selected Startups
                    </a>
                  </li>
                  <div className="vertical-dash"></div>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#tabs-1"
                      role="tab"
                    >
                      All Startups
                    </a>
                  </li>
                  <li>
                    <div>
                      <button
                        className="tertiary btn-yellow-primary"
                        onClick={() => {
                          setShowReport(true);
                        }}
                      >
                        Download Report
                      </button>
                    </div>
                  </li>
                </ul>

              </div>
              <div className="ml-auto text-right">
                <div className="d-flex searchDiv align-self-end mr-3 mt-3">
                  {" "}
                  <img src={search} className="search" alt="search" />
                  <input
                    className="searchBar"
                    type="text"
                    placeholder="Search.."
                    name="search"
                    onKeyUp={searchList}
                  />
                </div>
              </div>
            </div>
            {/* <div className="row text-right">

                        <div className="ml-auto text-right">
                            <div className="d-flex searchDiv align-self-end mr-3 mt-3"> <img
                                src={search}
                                className="search"
                                alt="search"

                            />
                                <input
                                    className="searchBar"
                                    type="text"
                                    placeholder="Search.."
                                    name="search"
                                    onKeyUp={searchList}
                                /></div>

                        </div>

                    </div> */}

            <div className="app-inside-content">
              <div className="tab-content mb-4">
                <div className="tab-pane" id="tabs-1">
                  {/* <div className="row incubator-heading-review mt-3">
                                        <div className="col-md-6 text-left">
                                            
                                        </div>
                                        
                                    </div>    */}

                  <div className="table-scroll mb-4">
                    <table className="reviewTable userListTable">
                      <thead>
                        <tr>
                          <th className="text-left table-heading custom-td-left table-heading ">
                            Startup Name
                          </th>
                          <th className="text-left table-heading">Industry</th>
                          <th className="text-left table-heading">Sector</th>
                          <th className="text-left table-heading">
                            Associated Incubator
                          </th>
                          <th className="text-left table-heading">
                            {" "}
                            Funds Approved (&#8377;)
                          </th>
                          <th className="text-left table-heading">
                            {" "}
                            Funds Disbursed (&#8377;)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {userList.length == 0 ||
                          (filteredUserList.length == 0 && noDataFilter != "") ? (
                          <tr>
                            <td className="text-center" colSpan={5}>
                              No data
                            </td>
                          </tr>
                        ) : filteredUserList.length == 0 ? (
                          userList.map((item: any) => (
                            <tr onClick={() => handleRowChange(item.id)}>
                              {/* {console.log(item)} */}
                              <td className="text-left custom-td">
                                {item.name_of_entity}
                              </td>
                              <td className="text-left custom-td">
                                {" "}
                                {JSON.parse(item.industries).map(
                                  (industry: any) =>
                                    industry.industry_name + " "
                                )}{" "}
                              </td>
                              <td className="text-left custom-td">
                                {" "}
                                {JSON.parse(item.sectors).map(
                                  (industry: any) => industry.sector_name + " "
                                )}{" "}
                              </td>
                              <td className="text-left custom-td">
                              {item.associated_incubators.lenth!=0 ? 
                                (JSON.parse(item.associated_incubators).map(
                                  (industry: any) => industry.registered_name + " "
                                ) )
                                :"-"}
                              </td>
                              <td className="text-center custom-td">
                                {item.total_applroved_amount != null
                                  ? changeNumberFormat(
                                    Number(item.total_applroved_amount),
                                    1,
                                    0
                                  )
                                  : 0}
                              </td>
                              <td>
                                {item.total_disbursed_amount != ""
                                  ? changeNumberFormat(
                                    Number(item.total_disbursed_amount),
                                    1,
                                    0
                                  )
                                  : 0}
                              </td>
                            </tr>
                          ))
                        ) : (
                          filteredUserList.map((item: any) => (
                            <tr onClick={() => handleRowChange(item.id)}>
                              <td className="text-left custom-td">
                                {item.name_of_entity}
                              </td>
                              <td className="text-left custom-td">
                                {" "}
                                {JSON.parse(item.industries).map(
                                  (industry: any) =>
                                    industry.industry_name + " "
                                )}{" "}
                              </td>
                              <td className="text-left custom-td">
                                {" "}
                                {JSON.parse(item.sectors).map(
                                  (industry: any) => industry.sector_name + " "
                                )}{" "}
                              </td>
                              <td className="text-left custom-td">
                              {item.associated_incubators.lenth!=0 ? 
                                (JSON.parse(item.associated_incubators).map(
                                  (industry: any) => industry.registered_name + " "
                                ) )
                                :"-"}
                              </td>
                              <td className="text-center custom-td">
                                {item.total_applroved_amount != null
                                  ? changeNumberFormat(
                                    Number(item.total_applroved_amount),
                                    1,
                                    0
                                  )
                                  : 0}
                              </td>
                              <td>
                                {item.total_disbursed_amount != ""
                                  ? changeNumberFormat(
                                    Number(item.total_disbursed_amount),
                                    1,
                                    0
                                  )
                                  : 0}
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="tab-pane active" id="tabs-2">
                  <div className="table-scroll mb-4">
                    <table className="reviewTable userListTable">
                      <thead>
                        <tr>
                          <th className="text-left table-heading custom-td-left table-heading ">
                            Startup Name
                          </th>
                          <th className="text-left table-heading">Industry</th>
                          <th className="text-left table-heading">Sector</th>
                          <th className="text-left table-heading">
                            Associated Incubator
                          </th>
                          <th className="text-left table-heading">
                            {" "}
                            Funds Approved (&#8377;)
                          </th>
                          <th className="text-left table-heading">
                            {" "}
                            Funds Disbursed (&#8377;)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {userListSelected.length == 0 ||
                          (filteredUserListSelected.length == 0 &&
                            noDataFilter != "") ? (
                          <tr>
                            <td className="text-center" colSpan={5}>
                              No data
                            </td>
                          </tr>
                        ) : filteredUserListSelected.length == 0 ? (
                          userListSelected.map((item: any) => (
                            <tr onClick={() => handleRowChange(item.id)}>
                              <td className="text-left custom-td">
                                {item.name_of_entity}
                              </td>
                              <td className="text-left custom-td">
                                {" "}
                                {JSON.parse(item.industries).map(
                                  (industry: any) =>
                                    industry.industry_name + " "
                                )}{" "}
                              </td>
                              <td className="text-left custom-td">
                                {" "}
                                {JSON.parse(item.sectors).map(
                                  (industry: any) => industry.sector_name + " "
                                )}{" "}
                              </td>
                              <td className="text-left custom-td">
                              {item.associated_incubators.lenth!=0 ? 
                                (JSON.parse(item.associated_incubators).map(
                                  (industry: any) => industry.registered_name + " "
                                ) )
                                :"-"}
                              </td>
                              <td className="text-center custom-td">
                                {item.total_applroved_amount != null
                                  ? changeNumberFormat(
                                    Number(item.total_applroved_amount),
                                    1,
                                    0
                                  )
                                  : 0}
                              </td>
                              <td>
                                {item.total_disbursed_amount != ""
                                  ? changeNumberFormat(
                                    Number(item.total_disbursed_amount),
                                    1,
                                    0
                                  )
                                  : 0}
                              </td>
                            </tr>
                          ))
                        ) : (
                          filteredUserListSelected.map((item: any) => (
                            <tr onClick={() => handleRowChange(item.id)}>
                              <td className="text-left custom-td">
                                {item.name_of_entity}
                              </td>
                              <td className="text-left custom-td">
                                {" "}
                                {JSON.parse(item.industries).map(
                                  (industry: any) =>
                                    industry.industry_name + " "
                                )}{" "}
                              </td>
                              <td className="text-left custom-td">
                                {" "}
                                {JSON.parse(item.sectors).map(
                                  (industry: any) => industry.sector_name + " "
                                )}{" "}
                              </td>
                              <td className="text-left custom-td">
                              {item.associated_incubators.lenth!=0 ? 
                                (JSON.parse(item.associated_incubators).map(
                                  (industry: any) => industry.registered_name + " "
                                ) )
                                :"-"}
                              </td>
                              <td className="text-center custom-td">
                                {item.total_applroved_amount != null
                                  ? changeNumberFormat(
                                    Number(item.total_applroved_amount),
                                    1,
                                    0
                                  )
                                  : 0}
                              </td>
                              <td>
                                {item.total_disbursed_amount != ""
                                  ? changeNumberFormat(
                                    Number(item.total_disbursed_amount),
                                    1,
                                    0
                                  )
                                  : 0}
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                <Modal
                show={showReport}
                onHide={() => setShowReport(false)}
                dialogClassName="modal-90w modal-xl modal-dialog-scrollable table-bordered table-hover"
                aria-labelledby="example-custom-modal-styling-title"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-custom-modal-styling-title">
                    Download StartUp Excel Report
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <StartUpReportComponent countRef={count} />
                </Modal.Body>
              </Modal>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
    <DashboardFooterComponent />
    </>
  );
}

export default EacStartupListingComponent;
