import React, { useEffect, useState } from "react";
import { isTemplateExpression } from "typescript";
import {
  Documents,
  FileUploadModelTranche,
  TrancheDetail,
  TrancheRequest,
  UpdateTranche,
} from "../../../models/funddetails/TrancheDetails";
import Plus from "../../../images/Plus.svg";
import Minus from "../../../images/Minus.svg";
import pdf from "../../../images/PDF.svg";
import xls from "../../../images/xls.svg";
import doc from "../../../images/doc.svg";
import xlsx from "../../../images/xls.svg";
import Allround from "../../../images/Allround.svg";
import { UploadTypeEnum } from "../../../utils/Enums";
import {
  ApplicationStatus,
  FileDownloadModel,
} from "../../dpiit/incubator/ApplicationGeneralDetails";
import { changeNumberFormat, createFile } from "../../../utils/CommonMethods";
import { get, post } from "../../../effects/api/api.common";
import {
  DPIIT_TRANCHE,
  GET_ALL_TRANCHES,
  GET_APPLICATION_STATUS,
  GET_TRANCHE_REQUEST,
  GET_TRANCHE_STATUS,
  STORE_TRANCHE_REQUEST,
  TRANCHE_REMOVE_DOCUMENT,
  TRANCHE_UPLOAD_DOCUMENT,
  UPDATE_TRANCHE,
  File_node_url,
  Get_St_Application_File,
  GET_SUBSEQUENT_UPLOADS,
} from "../../../constants/api";
import {
  FileUploadModel,
  TrancheFileUploadModel,
} from "../../../models/FileUploadModel";
import FileUploader from "@amodv/react-file-uploader";
import { getSessionStorageValue } from "../../../effects/utils/session-storage";
import UserInfoModel from "../../../models/UserInfoModel";
import Calendar from "../../common/controls/Calendar";
import { Link, useHistory } from "react-router-dom";

const st_status_fields = [
  "ca_file_path_status_ind",
  "uc_file_path_status_ind",
  "pr_file_path_status_ind",
  "legal_file_path_status_ind",
];

const statusfields = [
  "utilization_certificate_status_ind",
  "audited_statement_status_ind",
  "interest_earned_status_ind",
  "ismsc_meetings_status_ind",
  "proof_of_achievement_status_ind",
  "instrument_type_details_status_ind",
  "grant_disbursed_dtls_status_ind",
  "selected_startup_declation_status_ind",
  "List_of_Startups_status_ind",
  "proof_of_pfms_status_ind",
  "previous_sanction_order_status_ind",
  "tra_agreement_status_ind",
  "optional_other_1_status_ind",
  "optional_other_2_status_ind",
  "optional_other_3_status_ind",
  "optional_other_4_status_ind",
  "optional_other_5_status_ind",
];

interface ITrancheProps {
  trancheDetailsModel: Array<TrancheDetail>;
  total_grant_approved: number;
  RequestedTranche: any;
}
interface IIKeyValueIndexer {
  [key: string]: boolean;
}

function TrancheWiseDetails(props: ITrancheProps) {
  const { trancheDetailsModel, total_grant_approved, RequestedTranche } = props;
  // console.log("##",trancheDetailsModel)

  const [activeIndex, setActiveIndex] = useState(0);
  const user: any = getSessionStorageValue("user");
  const userInfo = JSON.parse(user);
  const changeActive = (id: number) => {
    setActiveIndex(id);
  };
  const changeActiveBack = () => {
    setActiveIndex(0);
  };
  const [openUploadBlock, setOpenUploadBlock] = useState<IIKeyValueIndexer>();

  const [isOpenUploadBlock, setIsOpenUploadBlock] = useState<
    Array<ApplicationStatus>
  >([]);

  const formatDate = (date: string) => {
    var month = new Array();
    month[0] = "Jan";
    month[1] = "Feb";
    month[2] = "Mar";
    month[3] = "April";
    month[4] = "May";
    month[5] = "Jun";
    month[6] = "Jul";
    month[7] = "Aug";
    month[8] = "Sep";
    month[9] = "Oct";
    month[10] = "Nov";
    month[11] = "Dec";

    var d = new Date(date),
      monthn = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (monthn.length < 2) monthn = "0" + monthn;
    if (day.length < 2) day = "0" + day;

    return [day, month[d.getMonth()], year].join(" ");
  };

  const [trancheData, setTrancheData] = useState<Array<TrancheDetail>>([
    new TrancheDetail(
      0,
      0,
      "",
      0,
      0,
      "",
      "",
      new Documents([], [], []),
      "",
      0,
      0,
      "",
      "",
      "",
      "",
      "",
      "",
      false,
      false,
      false,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    ),
  ]);
  const [trancheRequest, setTrancheRequest] = useState<TrancheRequest>(
    new TrancheRequest(0, 0, "", 0, "", [], [], "")
  );

  const handleChangeTrancheRequest = (
    evt: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const name = evt.target.name;
    const value = evt.target.value;

    let trancheDetails = JSON.parse(JSON.stringify(trancheRequest));
    trancheDetails[name] = evt.target.value;
    setTrancheRequest(trancheDetails);
  };
  useEffect(() => {
    // getAllFileUploads();
  }, []);

  const [currentTrancheStatus, setCurrentTrancheStatus] = useState<any>("");
  const getAllFileUploads = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    const userId = userInfo.id;
    let getAllUploads: any = [];
    const st_uploads = post(Get_St_Application_File, { userId: userId }).then(
      (response: any) => {
        // let getIncDtls:any;
        let inc_uploads = post(GET_SUBSEQUENT_UPLOADS, { userId: userId }).then(
          (data: any) => {
            console.log(data.data.data[0].status_ind);
            let getIncDtls = data.data.data[0].status_ind;
            let fields = [response.data.data, data.data.data].flat();
            console.log(fields);

            for (let i = 0; i < fields.length; i++) {
              for (let j = 0; j < st_status_fields.length; j++) {
                if (
                  fields[i][st_status_fields[j]] == 0 ||
                  fields[i][st_status_fields[j]] == 1 ||
                  fields[i][st_status_fields[j]] == 2 ||
                  fields[i][st_status_fields[j]] == 3 ||
                  fields[i][st_status_fields[j]] == 4
                ) {
                  getAllUploads.push(fields[i][st_status_fields[j]]);
                }
              }
              for (let k = 0; k < statusfields.length; k++) {
                if (
                  fields[i][statusfields[k]] == 0 ||
                  fields[i][statusfields[k]] == 1 ||
                  fields[i][statusfields[k]] == 2 ||
                  fields[i][statusfields[k]] == 3 ||
                  fields[i][statusfields[k]] == 4
                ) {
                  getAllUploads.push(fields[i][statusfields[k]]);
                }
              }
            }

            if (getAllUploads.includes(3) && getIncDtls == 2) {
              setCurrentTrancheStatus("Documents verification in Progress");
            } else if (getAllUploads.includes(2) && getIncDtls == 2) {
              setCurrentTrancheStatus("Requested");
            } else if (getAllUploads.includes(0) && getIncDtls == 3) {
              setCurrentTrancheStatus("incomplete");
            } else if (getAllUploads.includes(4) && getIncDtls == 4) {
              setCurrentTrancheStatus("completed");
            }
          }
        );
      }
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setTrancheData(trancheDetailsModel);
    getTrancheStatus();
    getUserRoleAccess();
    setApprovedAmountLimit();
    if (typeof trancheDetailsModel != "undefined") {
      setTimeout(() => {
        setManualTrancheDetails();
      }, 1200);

      return;
    }
    // setManualTrancheDetails();
  }, [trancheDetailsModel]);

  const [applicationStatus, setApplicationStatus] = useState<
    Array<ApplicationStatus>
  >([]);

  const [is_dpiit_user, setIsDpiit] = useState(false);
  const [is_incubator_user, setIsIncubator] = useState(false);

  const [tranche1_approved_limit, setApprovedLimit] = useState(0);
  const [showLimitMessage, setLimitMessage] = useState(false);
  const [loggedInRoleName, setLoggedInRoleName] = useState("");
  const setApprovedAmountLimit = () => {
    if (total_grant_approved > 0) {
      let amount_per = 40 / 100;
      let amount_limit = amount_per * total_grant_approved;
      setApprovedLimit(amount_limit);
    }
  };

  const getUserRoleAccess = () => {
    const user = getSessionStorageValue("user");
    if (user) {
      const userInfo = JSON.parse(user) as UserInfoModel;
      setLoggedInRoleName(userInfo.roles);
      if (userInfo.roles == "dpiit") {
        setIsDpiit(true);
      }
      if (userInfo.roles == "secretariat") {
        setIsDpiit(true);
      }
      if (userInfo.roles == "incubator") {
        setIsIncubator(true);
      }
    }
  };

  const setManualTrancheDetails = () => {
    var trancheDetailsData: Array<TrancheDetail> = trancheDetailsModel;

    //isNextDisbursed
    for (var i = trancheDetailsData.length - 1; i >= 0; i--) {
      var tranche_details = trancheDetailsData[i];

      tranche_details.proofDisbursementFlag = false;

      if (i == trancheDetailsData.length - 1) {
        tranche_details.isNextDisbursed = false;
      }
      if (
        i - 1 >= 0 &&
        (trancheDetailsData[i - 1].tranches_status_label.trimEnd() ==
          "Disbursed" ||
          trancheDetailsData[i - 1].tranches_status_label.trimEnd() ==
            "Disbursement in Process") &&
        (trancheDetailsData[i].tranches_status_label.trimEnd() == "Disbursed" ||
          trancheDetailsData[i + 1].tranches_status_label.trimEnd() ==
            "Disbursement in Process")
      ) {
        tranche_details.isNextDisbursed = true;
      } else {
        tranche_details.isNextDisbursed = false;
      }

      trancheDetailsData[i] = tranche_details;
    }

    //isPrevDisbursed
    for (var i = trancheDetailsData.length - 1; i >= 0; i--) {
      var tranche_details = trancheDetailsData[i];

      if (
        i + 1 <= trancheDetailsData.length - 1 &&
        (trancheDetailsData[i + 1].tranches_status_label.trimEnd() ==
          "Disbursed" ||
          trancheDetailsData[i + 1].tranches_status_label.trimEnd() ==
            "Disbursement in Process") &&
        trancheDetailsData[i].tranches_status_label.trimEnd() != "Not Requested"
      ) {
        tranche_details.isPrevDisbursed = true;
      } else {
        tranche_details.isPrevDisbursed = false;
      }
      if (
        i == trancheDetailsData.length - 1 &&
        (trancheDetailsData[i].tranches_status_label.trimEnd() == "Disbursed" ||
          trancheDetailsData[i].tranches_status_label.trimEnd() ==
            "Requested" ||
          trancheDetailsData[i].tranches_status_label.trimEnd() ==
            "Disbursement in Process" ||
          trancheDetailsData[i].tranches_status_label.trimEnd() ==
            "Incomplete" ||
          trancheDetailsData[i].tranches_status_label.trimEnd() ==
            "Verification of Documents in Process")
      ) {
        tranche_details.isPrevDisbursed = true;
      }

      trancheDetailsData[i] = tranche_details;
    }

    setTrancheData(trancheDetailsData);
  };
  // trrigers on date change
  const handleDateChange = (date: Date, field: string) => {
    let trancheDetails = JSON.parse(JSON.stringify(updateTranche));
    trancheDetails[field] = date;
    setUpdateTranche(trancheDetails);
  };

  //get application status
  const getTrancheStatus = () => {
    get(GET_TRANCHE_STATUS).then((response: any) => {
      if (!response || !response.data) return;
      if (response.data.data != undefined) {
        const status = response.data.data.map((item: any) => ({
          label: item.label,
          value: item.id,
        }));
        setApplicationStatus(status);
      }
    });
  };

  const downloadDocument = (file: any, file_name: any) => {
    fetch(file).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = file_name;
        a.click();
      });
    });
  };

  const getDisburseentDate = (requested_date: string, key: string) => {
    if (key == "date" && requested_date != "") {
      let req_date = new Date(requested_date);
      req_date.setDate(new Date(requested_date).getDate() + 30);

      return formatDate(new Date(req_date).toLocaleDateString());
    }

    if (key == "day") {
      let day = 30;
      if (requested_date != "") {
        let req_date = new Date(requested_date);
        req_date.setDate(new Date(requested_date).getDate() + 30);

        let disbursementDue = formatDate(
          new Date(req_date).toLocaleDateString()
        );

        var date1 = new Date();
        var date2 = req_date;

        var Difference_In_Time = date2.getTime() - date1.getTime();

        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        day = Difference_In_Days;
      }
      return Number(day).toFixed(0);
    }
  };
  const getFileDetails = (file: any, key: string) => {
    let file_name = "";
    file_name =
      file != undefined && file != null && file.length > 1
        ? file.substr(file.lastIndexOf("/") + 1, file.length)
        : "";
    if (key == "name") return file_name;
    if (key == "file") return file != undefined ? file.toString() : "";
  };

  const [loader, setLoader] = useState(false);

  const setDocumentFlag = (index: number, flag: boolean) => {
    setLoader(true);
    var trancheDetailsData = trancheData;
    for (var i = 0; i < trancheDetailsData.length; i++) {
      var tranche_details = trancheDetailsData[i];
      if (i == index) {
        trancheDetailsData[i].proofDisbursementFlag = flag;
      }

      trancheDetailsData[i] = tranche_details;
    }
    setTrancheData(trancheDetailsData);
    setTimeout(() => {
      setLoader(false);
    }, 4000);
  };

  const getDocumentFlag = (index: number, flag: boolean) => {
    var key = "ProofDisbursement" + index;
    return key;
  };
  const [editName, setEditName] = useState("");
  const [nameProp, setNameProp] = useState("");
  const [prevComment, setPrevComment] = useState("");
  const [prevStatus, setPrevStatus] = useState("");
  const setModalDetails = (
    field_name: string,
    tranche_id: number,
    application_id: number,
    value: string,
    status?: string,
    comment?: string
  ) => {
    if (field_name == "approved_amount") {
      setEditName("Fund Approved");
      setNameProp("approved_amount");

      if (status == "Tranche 1") {
        if (tranche1_approved_limit == 0) {
          setApprovedAmountLimit();
        }
        setLimitMessage(true);
      } else {
        setLimitMessage(false);
      }
    }
    if (field_name == "fund_released") {
      setEditName("Fund Disbursed");
      setNameProp("fund_released");
    }
    if (field_name == "tranch_comment") {
      setEditName("Tranche Comments");
      setNameProp("tranch_comment");
    }
    if (field_name == "tranche_requested_date") {
      setEditName("Tranche Requested Date");
      setNameProp("tranche_requested_date");
    }
    if (field_name == "funds_requested") {
      setEditName("Fund Requested");
      setNameProp("funds_requested");
    }
    if (field_name == "date_of_release") {
      setEditName("Date of Fund Disbursed");
      setNameProp("date_of_release");
    }

    if (field_name == "cash_in_hand") {
      setEditName("Cash in hand");
      setNameProp("cash_in_hand");
    }

    if (field_name == "tranches_status_label") {
      setEditName("Tranche Status");
      setNameProp("tranches_status_label");
    }

    setTrancheId(tranche_id);
    setApplicationId(application_id);

    let trancheDetails = JSON.parse(JSON.stringify(updateTranche));
    if (
      field_name == "funds_requested" ||
      field_name == "fund_released" ||
      field_name == "approved_amount"
    ) {
      trancheDetails[field_name] = Number(value).toFixed();
    } else {
      trancheDetails[field_name] = value;
    }
    if (field_name == "tranch_comment") {
      comment = value;
    }

    if (field_name == "date_of_release") {
      trancheDetails["date_of_release"] = new Date();
    }

    trancheDetails["tranche_id"] = tranche_id;
    trancheDetails["application_id"] = application_id;
    if (status != null) {
      trancheDetails["tranche_status_id"] = applicationStatus.find(
        (option) => option.label === status
      )?.value;
      trancheDetails["tranch_comment"] = comment;
      setPrevStatus(trancheDetails["tranche_status_id"]);
      setPrevComment(comment != undefined ? comment : "");
    }

    setUpdateTranche(trancheDetails);

    let trancheRequestDetails = JSON.parse(JSON.stringify(trancheRequest));
    trancheRequestDetails[field_name] = value;
    trancheRequestDetails["tranche_id"] = tranche_id;
    trancheRequestDetails["application_id"] = application_id;
    setTrancheRequest(trancheRequestDetails);
  };

  const [updateTranche, setUpdateTranche] = useState<UpdateTranche>(
    new UpdateTranche(0, 0, 0, 0, 0, "", "", 0)
  );
  const [tranche_id, setTrancheId] = useState(0);
  const [application_id, setApplicationId] = useState(0);

  const updateTrancheDetails = (updateProp: string) => {
    if (updateProp == "cash_in_hand") {
      const data_input = {
        application_id: application_id,
        tranche_id: trancheRequest.tranche_id,
        funds_requested: trancheRequest.amount_required,
        cash_in_hand: trancheRequest.cash_in_hand,
      };
      post(STORE_TRANCHE_REQUEST, data_input).then((response: any) => {
        if (!response || !response.data || response.data == "") return;
        if (response.data != undefined && response.data != "") {
          if (response.data.result == "success") {
            //getTrancheRequest(application_id);
            setShowSuccessModal(true);
          } else {
            setShowFailureModal(true);
          }
          getTrancheRequest(application_id);
        }
      });
    } else {
      let trancheDetails = JSON.parse(JSON.stringify(updateTranche));
      trancheDetails["tranche_id"] = tranche_id;
      trancheDetails["application_id"] = application_id;
      trancheDetails["tranch_comment"] = "";
      setUpdateTranche(trancheDetails);

      let update_tranche = {
        tranche_id: tranche_id,
        application_id: application_id,
        logged_user_id: userInfo.id,
        logged_user_role: userInfo.roles,
        funds_requested:
          updateTranche.funds_requested == 0
            ? 0
            : updateTranche.funds_requested,
        approved_amount:
          updateTranche.approved_amount == 0
            ? 0
            : updateTranche.approved_amount,
        tranche_status_id:
          updateTranche.tranche_status_id == undefined
            ? 0
            : updateTranche.tranche_status_id,
        tranch_comment:
          updateTranche.tranch_comment == "" || undefined
            ? 0
            : updateTranche.tranch_comment,
        fund_released:
          updateTranche.fund_released == 0 ? 0 : updateTranche.fund_released,
        date_of_release:
          updateTranche.date_of_release == ""
            ? 0
            : updateTranche.date_of_release,
        application_ind: "F1",
      };

      // if (updateProp == 'tranche_status_id')
      //   update_tranche = {
      //     tranche_id: tranche_id,
      //     application_id: application_id,
      //     tranche_status_id: updateTranche.tranche_status_id,
      //     tranch_comment: updateTranche.tranch_comment,
      //   };

      // if (updateProp == 'date_of_release')
      //   update_tranche = {
      //     tranche_id: tranche_id,
      //     application_id: application_id,
      //     date_of_release: updateTranche.date_of_release,
      //   };

      // if (updateProp == 'fund_released')
      //   update_tranche = {
      //     tranche_id: tranche_id,
      //     application_id: application_id,
      //     fund_released: updateTranche.fund_released,
      //   };

      // if (updateProp == 'approved_amount')
      //   update_tranche = {
      //     tranche_id: tranche_id,
      //     application_id: application_id,
      //     approved_amount: updateTranche.approved_amount,
      //   };

      // if (updateProp == 'tranch_comment')
      //   update_tranche = {
      //     tranche_id: tranche_id,
      //     application_id: application_id,
      //     tranch_comment: updateTranche.tranch_comment,
      //   };

      // if (updateProp == 'funds_requested')
      //   update_tranche = {
      //     tranche_id: tranche_id,
      //     application_id: application_id,
      //     funds_requested: updateTranche.funds_requested,
      //   };

      post(`${UPDATE_TRANCHE}`, update_tranche).then((response: any) => {
        if (!response || !response.data) return;

        if (response.data.result == "success") {
          //
          setShowSuccessModal(true);
        } else {
          setShowFailureModal(true);
        }
        getTrancheRequest(application_id);
        // history.push({
        //   pathname: '/dpiit_view_incubator',
        //   state: { application_id: application_id ,isFundDetails:true},
        // });
        // window.location.reload();
      });
    }
  };

  const updateTrancheCommentByStatus = () => {
    let trancheDetails = JSON.parse(JSON.stringify(updateTranche));
    let addCommentsByStatus = {
      application_id: application_id,
      tranch_comment: trancheDetails.tranch_comment,
      tranche_id: trancheDetails.tranche_id,
      tranche_status_id: trancheDetails.tranche_status_id,
      logged_user_id: userInfo.id,
      application_ind: "F1",
    };
    trancheDetails["tranche_id"] = tranche_id;
    trancheDetails["application_id"] = application_id;
    //trancheDetails['tranch_comment'] = application_id;
    setUpdateTranche(trancheDetails);

    post(DPIIT_TRANCHE, addCommentsByStatus).then((response: any) => {
      if (!response || !response.data) return;
      if (response.data.result == "success") {
        setShowSuccessModal(true);
      } else {
        setShowFailureModal(true);
      }
      getTrancheRequest(application_id);
    });
  };

  const getTrancheRequest = (app_id: number) => {
    let tranche_parameters = {
      application_id: app_id,
      order_by: "DESC",
    };

    post(`${GET_ALL_TRANCHES}`, tranche_parameters).then((response: any) => {
      if (!response || !response.data) return;

      var trancheDetailsData: Array<TrancheDetail> = response.data.data;

      for (var i = 0; i < trancheDetailsData.length; i++) {
        //setTrancheDetails(trancheDetailsData[i]);
        var tranche_details = trancheDetailsData[i];

        const file_data = tranche_details.documents;

        if (file_data) {
          Object.keys(file_data).length &&
            Object.keys(file_data).map((key: string, index) => {
              switch (key) {
                case UploadTypeEnum.ProofDisbursement:
                  file_data[key].map((data: any) => {
                    createFile(data.file).then((blob: any) => {
                      blob.lastModifiedDate = new Date();
                      const src = data.file as string;
                      blob.name = src.substr(
                        src.lastIndexOf("/") + 1,
                        src.length
                      );

                      const index = file_data.proof_of_disbursement.findIndex(
                        (el) => el.file_id === data.file_id
                      );
                      file_data.proof_of_disbursement[index] = {
                        file_id: data.file_id,
                        file_type_id: data.file_type_id,
                        file: blob as File,
                        ext: data.ext,
                        fileName: blob.name,
                      };
                    });
                  });

                  break;

                case UploadTypeEnum.QuarterlyReport:
                  file_data[key].map((data: any) => {
                    createFile(data.file).then((blob: any) => {
                      blob.lastModifiedDate = new Date();
                      const src = data.file as string;
                      blob.name = src.substr(
                        src.lastIndexOf("/") + 1,
                        src.length
                      );

                      const index = file_data.quarterly_reports.findIndex(
                        (el) => el.file_id === data.file_id
                      );
                      file_data.quarterly_reports[index] = {
                        file_id: data.file_id,
                        file_type_id: data.file_type_id,
                        file: blob as File,
                        ext: data.ext,
                        fileName: data.file,
                      };
                    });
                  });

                  break;

                case UploadTypeEnum.SupportingDocuments:
                  file_data[key].map((data: any) => {
                    createFile(data.file).then((blob: any) => {
                      blob.lastModifiedDate = new Date();
                      const src = data.file as string;
                      blob.name = src.substr(
                        src.lastIndexOf("/") + 1,
                        src.length
                      );

                      const index = file_data.supporting_documents.findIndex(
                        (el) => el.file_id === data.file_id
                      );
                      file_data.supporting_documents[index] = {
                        file_id: data.file_id,
                        file_type_id: data.file_type_id,
                        file: blob as File,
                        ext: data.ext,
                        fileName: data.file,
                      };
                    });
                  });

                  break;
              }
            });
        }
        tranche_details.documents = file_data;
        trancheDetailsData[i] = tranche_details;
      }

      for (var i = trancheDetailsData.length - 1; i >= 0; i--) {
        var tranche_details = trancheDetailsData[i];

        tranche_details.proofDisbursementFlag = false;
        //isNextDisbursed
        if (i == trancheDetailsData.length - 1) {
          tranche_details.isNextDisbursed = false;
        }
        if (
          i - 1 >= 0 &&
          (trancheDetailsData[i - 1].tranches_status_label.trimEnd() ==
            "Disbursed" ||
            trancheDetailsData[i - 1].tranches_status_label.trimEnd() ==
              "Disbursement in Process") &&
          (trancheDetailsData[i].tranches_status_label.trimEnd() ==
            "Disbursed" ||
            trancheDetailsData[i + 1].tranches_status_label.trimEnd() ==
              "Disbursement in Process")
        ) {
          tranche_details.isNextDisbursed = true;
        } else {
          tranche_details.isNextDisbursed = false;
        }
        trancheDetailsData[i] = tranche_details;
      }

      //isPrevDisbursed

      for (var i = trancheDetailsData.length - 1; i >= 0; i--) {
        var tranche_details = trancheDetailsData[i];

        if (
          i + 1 <= trancheDetailsData.length - 1 &&
          (trancheDetailsData[i + 1].tranches_status_label.trimEnd() ==
            "Disbursed" ||
            trancheDetailsData[i + 1].tranches_status_label.trimEnd() ==
              "Disbursement in Process") &&
          trancheDetailsData[i].tranches_status_label.trimEnd() !=
            "Not Requested"
        ) {
          tranche_details.isPrevDisbursed = true;
        } else {
          tranche_details.isPrevDisbursed = false;
        }
        if (
          i == trancheDetailsData.length - 1 &&
          (trancheDetailsData[i].tranches_status_label.trimEnd() ==
            "Disbursed" ||
            trancheDetailsData[i].tranches_status_label.trimEnd() ==
              "Requested" ||
            trancheDetailsData[i].tranches_status_label.trimEnd() ==
              "Disbursement in Process" ||
            trancheDetailsData[i].tranches_status_label.trimEnd() ==
              "Incomplete" ||
            trancheDetailsData[i].tranches_status_label.trimEnd() ==
              "Verification of Documents in Process")
        ) {
          tranche_details.isPrevDisbursed = true;
        }

        trancheDetailsData[i] = tranche_details;
      }

      setTrancheData(trancheDetailsData);
      // setManualTrancheDetails();
    });
  };

  const handleChange = (
    evt: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const name = evt.target.name;
    const value = evt.target.value;

    if (name == "tranche_status_id") {
      let trancheDetails = JSON.parse(JSON.stringify(updateTranche));
      if (prevStatus == evt.target.value) {
        trancheDetails["tranch_comment"] = prevComment;
      } else {
        trancheDetails["tranch_comment"] = "";
      }
      trancheDetails[name] = evt.target.value;
      setUpdateTranche(trancheDetails);
    } else {
      let trancheDetails = JSON.parse(JSON.stringify(updateTranche));
      if (name == "fund_released" || name == "approved_amount") {
        const numStr = String(evt.target.value);
        var count = 0;
        if (numStr.includes(".")) {
          count = numStr.split(".")[1].length;
        }
        if (count > 2) {
          trancheDetails[name] = Number(evt.target.value).toFixed(2);
        } else {
          trancheDetails[name] = evt.target.value;
        }
      } else {
        trancheDetails[name] = evt.target.value;
      }

      setUpdateTranche(trancheDetails);
    }
  };
  const history = useHistory();
  /** upload the files */
  const onFileUpload = (
    files: Array<File>,
    type: string,
    item: TrancheDetail,
    index: number
  ) => {
    let indexes: Array<string> = [];

    for (const file of files) {
      let updatedOtherDetail = Object.assign(
        {},
        item.documents.proof_of_disbursement
      );
      const fileData = item.documents.proof_of_disbursement;

      const index = fileData.findIndex((el: any) => el.file.name === file.name);

      if (index == -1) {
        if (file.type === "application/pdf") {
          const formData = new FormData();
          // Update the formData object
          formData.append("file", file, file.name);
          formData.append("tranche_id", item.tranch_id.toString());
          formData.append("file_type", type);
          formData.append("user_Role", userInfo.roles);
          formData.append("logged_user_id", userInfo.id);
          post(TRANCHE_UPLOAD_DOCUMENT, formData).then((response: any) => {
            if (!response.data) return;

            setApplicationId(item.application_id);

            if (response.data.result.status == true) {
              setShowSuccessModal(true);
            } else {
              setShowFailureModal(true);
            }

            getTrancheRequest(item.application_id);
          });
        }
      }
    }
  };

  const [showEditSuccessModal, setShowSuccessModal] = useState(false);
  const [showEditFailureModal, setShowFailureModal] = useState(false);
  const dismissModal = () => {
    setShowSuccessModal(false);
    setShowFailureModal(false);
  };

  // Triggers when delete file is clicked
  const onFileDelete = (file: File, type: string, item: TrancheDetail) => {
    const { proof_of_disbursement, quarterly_reports, supporting_documents } =
      item.documents;
    let remainingFiles: Array<FileUploadModelTranche> = [];
    let fileToBeDeleted: any;

    switch (type) {
      case UploadTypeEnum.ProofDisbursement:
        fileToBeDeleted = proof_of_disbursement.find(
          (uploadedFile: FileUploadModelTranche) => uploadedFile.file === file
        );

        if (!fileToBeDeleted) return;
        remainingFiles = proof_of_disbursement.filter(
          (uf: FileUploadModelTranche) => uf.file !== file
        );

        break;
      case UploadTypeEnum.QuarterlyReport:
        fileToBeDeleted = quarterly_reports.find(
          (uploadedFile: FileUploadModelTranche) => uploadedFile.file === file
        );

        if (!fileToBeDeleted) return;
        remainingFiles = quarterly_reports.filter(
          (uf: FileUploadModelTranche) => uf.file !== file
        );

        break;

      case UploadTypeEnum.SupportingDocuments:
        fileToBeDeleted = supporting_documents.find(
          (uploadedFile: FileUploadModelTranche) => uploadedFile.file === file
        );

        if (!fileToBeDeleted) return;
        remainingFiles = proof_of_disbursement.filter(
          (uf: FileUploadModelTranche) => uf.file !== file
        );

        break;
    }
    // delete the file
    post(TRANCHE_REMOVE_DOCUMENT, {
      application_id: item.application_id,
      file_id: fileToBeDeleted.file_id,
      tranche_id: item.tranch_id,
    }).then((response: any) => {
      if (response.data.result.status == true) {
        setShowSuccessModal(true);
      } else {
        setShowFailureModal(true);
      }
      getTrancheRequest(item.application_id);
    });
  };
  const goToSubsequentTranch = () => {
    history.push("/subsequent_tranche");
  };
  return (
    <div>
      <div className="row">
        {/* {console.log("@@##",trancheData)} */}
        <div className="col-md-12">
          {trancheData != undefined && trancheData.length > 0
            ? trancheData.map((item, index: any) => (
                <div>
                  <div className="row">
                    <div
                      id={`q-${index + 1}`}
                      className={
                        activeIndex == index + 1
                          ? "tranche-heading col-sm-10 left-align"
                          : "reports-heading col-sm-10 left-align"
                      }
                    >
                      {/* {console.log(currentTrancheStatus)} */}
                      <span
                        className={
                          activeIndex == index + 1
                            ? "tranche-heading "
                            : "reports-heading "
                        }
                      >
                        {item.tranche_number}
                      </span>
                      &nbsp;&nbsp; &nbsp;&nbsp;
                      {item.tranche_requested_date != null &&
                      is_incubator_user == false ? (
                        <span
                          className={
                            activeIndex == index + 1
                              ? "tranche-subheading "
                              : "reports-subheading "
                          }
                        >
                          {item.tranches_status_label === "Disbursed"
                            ? "Disbursed"
                            : "Disbursement due within"}{" "}
                          {item.tranches_status_label != "Disbursed"
                            ? getDisburseentDate(
                                item.tranche_requested_date,
                                "day by "
                              )
                            : ""}
                          {item.tranches_status_label != "Disbursed"
                            ? " days by "
                            : ""}
                          {item.tranches_status_label != "Disbursed"
                            ? getDisburseentDate(
                                item.tranche_requested_date,
                                "date"
                              )
                            : ""}
                        </span>
                      ) : (
                        ""
                      )}
                      {is_incubator_user === true ? (
                        <span
                          className={
                            activeIndex == index + 1
                              ? "tranche-subheading "
                              : "reports-subheading "
                          }
                        >
                          {item.tranches_status_label == "Incomplete" ? (
                            <>
                              Marked Incomplete by Secretariat
                              <span
                                className="Info-circle-incomplete ml-2"
                                // data-target="#fileInfo"
                                // data-toggle="modal"
                                // onClick={() => setModalPopup("Sanction")}
                              >
                                ℹ️
                              </span>
                            </>
                          ) : item.tranches_status_label ==
                            "Verification of Documents Completed" ? (
                            <>
                              Verification of Documents Completed
                              <span
                                className="Info-circle-complete ml-2"
                                // data-target="#fileInfo"
                                // data-toggle="modal"
                                // onClick={() => setModalPopup("Sanction")}
                              >
                                ℹ️
                              </span>
                            </>
                          ) : (
                            item.tranches_status_label
                          )}
                          {/* {index!=1 ?  item.tranches_status_label : currentTrancheStatus} */}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-sm-1">
                      {/* {is_incubator_user == true &&
                      item.tranches_status_label == 'Incomplete' ? (
                      <a
                        className="incubator-details-link "
                        data-target="#requestTranche"
                        data-toggle="modal"
                      >
                        Edit Tranche
                      </a>
                    ) : (
                      ''
                    )} */}
                    </div>
                    <div className="col-sm-1">
                      {activeIndex == index + 1 ? (
                        <div
                          onClick={changeActiveBack}
                          data-toggle="collapse"
                          data-target={`#tranche-expand${index + 1}`}
                          role="button"
                          aria-expanded="false"
                          aria-controls={`tranche-expand${index + 1}`}
                          className="hide-icon pull-right hide-icon-details"
                          id={`show-${index + 1}`}
                        >
                          <img
                            src={Minus}
                            className="img-fluid"
                            alt="SISFS Logo"
                          />
                        </div>
                      ) : (
                        <div
                          onClick={() => changeActive(index + 1)}
                          id={`hide-${index + 1}`}
                          data-toggle="collapse"
                          data-target={`#tranche-expand${index + 1}`}
                          role="button"
                          aria-expanded="false"
                          aria-controls={`tranche-expand${index + 1}`}
                          className="expand-icon pull-right expand-icon-details"
                        >
                          <img
                            src={Plus}
                            className="img-fluid"
                            alt="SISFS Logo"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {activeIndex == index + 1 ? (
                    <div
                      className="collapse" //{activeIndex == index + 1 ? "collapse show" :"collapse"}
                      id={`tranche-expand${index + 1}`}
                    >
                      <div className="">
                        <div className="row mt-05">
                          <div className="col-md-6 left-align">
                            <span className="incubator-label">
                              Date of requested
                            </span>
                            <br />
                            <span className="incubator-label-value">
                              {item.tranche_requested_date != null
                                ? formatDate(item.tranche_requested_date)
                                : "-"}
                            </span>

                            {/* {is_incubator_user == true ?
                          <a
                          className="incubator-details-link "
                          onClick={() =>
                            setModalDetails(
                              'tranche_requested_date',
                              item.tranch_id,
                              item.application_id,
                              item.tranche_requested_date.toString()
                            )
                          }
                          data-target="#editTranche"
                          data-toggle="modal"
                        >
                          Update
                        </a>
                          :""} */}
                          </div>
                          <div className="col-md-6 left-align">
                            <span className="incubator-label">Status</span>
                            <br />
                            {item.tranches_status_label != null ? (
                              <span className="incubator-label-value-wrap">
                                {" "}
                                {item.tranches_status_label == "Incomplete"
                                  ? "Marked incomplete by secretariat"
                                  : item.tranches_status_label}
                                {/* {currentTrancheStatus} */}
                              </span>
                            ) : (
                              <span className="incubator-label-value-wrap">
                                {" "}
                                {"-"}{" "}
                              </span>
                            )}{" "}
                            {is_dpiit_user == true ? (
                              // && item.isNextDisbursed == false &&
                              // item.isPrevDisbursed == true
                              <a
                                className="incubator-details-link "
                                onClick={() =>
                                  setModalDetails(
                                    "tranches_status_label",
                                    item.tranch_id,
                                    item.application_id,
                                    item.tranches_status_label == null
                                      ? ""
                                      : item.tranches_status_label.toString(),
                                    item.tranches_status_label,
                                    item.tranche_status_comment
                                  )
                                }
                                data-target="#editTrancheStatus"
                                data-toggle="modal"
                              >
                                Update
                              </a>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div className="row mt-05">
                          <div className="col-md-6 left-align">
                            <span className="incubator-label">
                              Fund Requested
                            </span>
                            <br />
                            {item.funds_requested != null ? (
                              <span className="incubator-label-value">
                                &#8377;{" "}
                                {changeNumberFormat(
                                  Number(item.funds_requested),
                                  2,
                                  1
                                )}
                              </span>
                            ) : (
                              <span className="incubator-label-value">
                                {"-"}
                              </span>
                            )}
                            {/* {is_incubator_user == true &&
                            item.tranches_status_label == 'Incomplete' ? (
                              <a
                                className="incubator-details-link "
                                // onClick={() =>
                                //   setModalDetails(
                                //     'funds_requested',
                                //     item.tranch_id,
                                //     item.application_id,
                                //     item.funds_requested.toString()
                                //   )
                                // }
                                data-target="#requestTranche"
                                data-toggle="modal"
                              >
                                Edit
                              </a>
                            ) : (
                              ''
                            )} */}
                          </div>
                          <div className="col-md-6 left-align">
                            <span className="incubator-label">
                              {item.tranches_status_label == "Incomplete" ? (
                                <span className="By_rejected_Flag">
                                  Supporting Documents
                                </span>
                              ) : (
                                <span>Supporting Documents</span>
                              )}
                            </span>
                            {console.log("AMITHAAAAAA", item)}
                            <br />
                            {new Date(item.tranche_requested_date) >
                              new Date("2023-12-20T00:00:00.000Z") && (
                              <>
                                {item.tranche_number == "Tranche 1" ? (
                                  <>
                                    {item.tranches_status_label ==
                                    "Not Requested" ? (
                                      ""
                                    ) : item.tranches_status_label ==
                                        "Verification of Documents in Process" ||
                                      item.tranches_status_label ==
                                        "Requested" ||
                                      item.tranches_status_label ==
                                        "Verification of Documents Completed" ? (
                                      <a style={{ fontSize: "13px" }}>
                                        Click here to change/ View all documents
                                      </a>
                                    ) : (
                                      <Link
                                        onClick={() =>
                                          localStorage.setItem(
                                            "trancheLabel",
                                            item.tranches_status_label
                                          )
                                        }
                                        to={{
                                          pathname: `/first_subsequent_tranche/${item.application_id}/${item.tranch_id}/${item.funds_requested}/${item.cash_in_hand}/${item.tranche_number}/F1/${item.incubator_application_no}`,
                                        }}
                                        style={{
                                          color: "#E26640",
                                          fontSize: "13px",
                                        }}
                                      >
                                        Click here to change/ View all documents
                                      </Link>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {item.tranches_status_label ==
                                    "Not Requested" ? (
                                      ""
                                    ) : item.tranches_status_label ==
                                        "Verification of Documents in Process" ||
                                      item.tranches_status_label ==
                                        "Requested" ||
                                      item.tranches_status_label ==
                                        "Verification of Documents Completed" ? (
                                      <a style={{ fontSize: "13px" }}>
                                        Click here to change/ View all documents
                                      </a>
                                    ) : (
                                      <Link
                                        onClick={() =>
                                          localStorage.setItem(
                                            "trancheLabel",
                                            item.tranches_status_label
                                          )
                                        }
                                        to={{
                                          pathname: `/subsequent_tranche/${item.application_id}/${item.tranch_id}/${item.funds_requested}/${item.cash_in_hand}/${item.tranche_number}/F1/${item.incubator_application_no}`,
                                        }}
                                        style={{
                                          color: "#E26640",
                                          fontSize: "13px",
                                        }}
                                      >
                                        Click here to change/ View all documents
                                      </Link>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </div>

                        <div className="row mt-05">
                          <div className="col-md-6 left-align">
                            <span className="incubator-label">
                              Fund Approved
                            </span>
                            <br />
                            {item.approved_amount != null ? (
                              <span className="incubator-label-value">
                                &#8377;{" "}
                                {changeNumberFormat(
                                  Number(item.approved_amount),
                                  2,
                                  1
                                )}
                              </span>
                            ) : (
                              <span className="incubator-label-value">
                                {"-"}
                              </span>
                            )}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {is_dpiit_user == true &&
                            item.isNextDisbursed == false ? (
                              <a
                                className="incubator-details-link "
                                onClick={() =>
                                  setModalDetails(
                                    "approved_amount",
                                    item.tranch_id,
                                    item.application_id,
                                    item.approved_amount == null
                                      ? ""
                                      : item.approved_amount.toString(),
                                    item.tranche_number.trimEnd()
                                  )
                                }
                                data-target="#editTranche"
                                data-toggle="modal"
                              >
                                Edit
                              </a>
                            ) : (
                              ""
                            )}
                            <br />
                            {Number(item.fund_released) >
                            Number(item.approved_amount) ? (
                              <span className="error-message">
                                Fund release amount is greater than approved
                                amount, Please check again..
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-6 left-align">
                            <span className="incubator-label">
                              Proof of Disbursement
                            </span>
                            <br />
                            <div className="row">
                              <div className="col-md-8 left-align">
                                {item.documents.proof_of_disbursement !=
                                  undefined &&
                                item.documents.proof_of_disbursement.length !=
                                  0 ? (
                                  ""
                                ) : (
                                  <label className="document-label-value">
                                    {" "}
                                    -
                                  </label>
                                )}
                                {item.documents.proof_of_disbursement !=
                                  undefined &&
                                  item.documents.proof_of_disbursement.length !=
                                    0 &&
                                  item.documents.proof_of_disbursement.map(
                                    (fileObject, index) => (
                                      <div className="row">
                                        <div className="col-md-12">
                                          <span className="document-label-value">
                                            {/* {fileObject.fileName} */}
                                            {getFileDetails(
                                              fileObject.fileName,
                                              "name"
                                            )}
                                          </span>{" "}
                                          &nbsp;
                                          <a
                                            href={
                                              `${File_node_url}` +
                                              fileObject.fileName
                                            }
                                            target="_blank"
                                            className="incubator-details-link"
                                          >
                                            View
                                          </a>
                                        </div>
                                      </div>

                                      // })
                                    )
                                  )}
                              </div>
                            </div>
                            {/* &nbsp;&nbsp;&nbsp;&nbsp; */}
                            {is_dpiit_user == true &&
                            (item.tranches_status_label == "Disbursed" ||
                              item.tranches_status_label ==
                                "Disbursement in Process") &&
                            item.isNextDisbursed == false ? (
                              <a
                                className="incubator-details-link "
                                onClick={
                                  () => setDocumentFlag(index, true)
                                  // setOpenUploadBlock({ ProofDisbursement: true })
                                }
                              >
                                Upload Proof
                              </a>
                            ) : (
                              ""
                            )}
                            {loader ? (
                              <div
                                className="spinner-border custom-spinner loader-position"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              item &&
                              item.proofDisbursementFlag == true && (
                                // item.documents.proof_of_disbursement.length ===
                                //   0) ||
                                //   (item.documents.proof_of_disbursement.length >
                                //   0) && (
                                <>
                                  <span
                                    className="close"
                                    onClick={
                                      () => setDocumentFlag(index, false)
                                      // setOpenUploadBlock({
                                      //   ProofDisbursement: false,
                                      // })
                                    }
                                  >
                                    <i className="fa fa-close"></i>
                                  </span>

                                  <FileUploader
                                    key={Math.random().toString()}
                                    id={`ProofDisbursement${index}`}
                                    information="Drag & Drop the file or Browse"
                                    fileType="application/pdf"
                                    buttonLabel="Browse"
                                    multiple
                                    enablePreview
                                    IconComponent={() => (
                                      <span className="fa fa-cloud-upload"></span>
                                    )}
                                    removeIconClass=""
                                    existingFiles={item.documents.proof_of_disbursement.map(
                                      (uploadedFile: FileUploadModelTranche) =>
                                        uploadedFile.file
                                    )}
                                    onUploadFinish={(files: Array<File>) => {
                                      onFileUpload(
                                        files,
                                        UploadTypeEnum.ProofDisbursement,
                                        item,
                                        index
                                      );
                                    }}
                                    onFileDelete={(deletedFile: File) =>
                                      onFileDelete(
                                        deletedFile,
                                        UploadTypeEnum.ProofDisbursement,
                                        item
                                      )
                                    }
                                  />
                                </>
                              )
                            )}
                          </div>
                        </div>

                        <div className="row mt-05">
                          <div className="col-md-6 left-align">
                            <span className="incubator-label">
                              Fund Disbursed
                            </span>
                            <br />
                            {item.fund_released != null ? (
                              <span className="incubator-label-value">
                                &#8377;{" "}
                                {changeNumberFormat(
                                  Number(item.fund_released),
                                  2,
                                  1
                                )}
                              </span>
                            ) : (
                              <span className="incubator-label-value">
                                {"-"}
                              </span>
                            )}
                            {/* <span className="incubator-label-value">NA</span> */}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {is_dpiit_user == true &&
                            (item.tranches_status_label == "Disbursed" ||
                              item.tranches_status_label ==
                                "Disbursement in Process") &&
                            item.isNextDisbursed == false ? (
                              <a
                                className="incubator-details-link "
                                onClick={() =>
                                  setModalDetails(
                                    "fund_released",
                                    item.tranch_id,
                                    item.application_id,
                                    item.fund_released == null
                                      ? ""
                                      : item.fund_released.toString()
                                  )
                                }
                                data-target="#editTranche"
                                data-toggle="modal"
                              >
                                Edit
                              </a>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-6 left-align">
                            <span className="incubator-label">
                              Date of Fund Disbursed
                            </span>
                            <br />
                            {item.date_of_release != null ? (
                              <span className="incubator-label-value">
                                {formatDate(item.date_of_release)}
                              </span>
                            ) : (
                              <span className="incubator-label-value">
                                {"-"}
                              </span>
                            )}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {is_dpiit_user == true &&
                            (item.tranches_status_label == "Disbursed" ||
                              item.tranches_status_label ==
                                "Disbursement in Process") &&
                            item.isNextDisbursed == false ? (
                              <a
                                className="incubator-details-link "
                                onClick={() =>
                                  setModalDetails(
                                    "date_of_release",
                                    item.tranch_id,
                                    item.application_id,
                                    item.date_of_release
                                  )
                                }
                                data-target="#editTranche"
                                data-toggle="modal"
                              >
                                Edit
                              </a>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        {item.tranche_number != "Tranche 1" && (
                          <div className="row mt-05">
                            <div className="col-md-6 left-align">
                              <span className="incubator-label">
                                Interest Accumulated on Funds
                              </span>
                              <br />
                              {item.interest_accumulated_on_funds != null &&
                              item.interest_accumulated_on_funds.toString() !=
                                "" ? (
                                <span className="incubator-label-value">
                                  &#8377;{" "}
                                  {changeNumberFormat(
                                    Number(item.interest_accumulated_on_funds),
                                    2,
                                    1
                                  )}
                                </span>
                              ) : (
                                <span className="incubator-label-value">
                                  {"-"}
                                </span>
                              )}
                            </div>
                            <div className="col-md-6 left-align">
                              <span className="fund-label">
                                Is cash-in-hand of the incubator below 10% of
                                the total commitment by EAC?
                              </span>
                              <br />
                              <span className="incubator-label-value">
                                {item.cash_in_hand == "1"
                                  ? "Yes"
                                  : item.tranches_status_label ==
                                      "Not Requested" &&
                                    item.cash_in_hand == "0"
                                  ? "-"
                                  : "No"}
                              </span>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="application-incubator-hr">
                    <hr />
                  </div>
                </div>
              ))
            : ""}
        </div>
      </div>
      {/* Add Comment Pop Up */}
      <div
        className="modal fade"
        id="addEditCommentTranche"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none">
              <h5 className="modal-title " id="exampleModalLongTitle">
                Add Tranche Comment <span className="eac-name-pop-up"></span>
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12 left">
                  <textarea
                    className="form-control"
                    name="comment"
                    value={updateTranche.tranch_comment}
                    rows={3}
                    placeholder="Enter Comment"
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 text-right">
                  <div className="btn-group mt-1-5 ">
                    <button
                      className="tertiary   btn-transparent-primary"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>

                    <button
                      type="button"
                      className="btn btn-sisfs btn-yellow-primary ml-2"
                      onClick={() => updateTrancheCommentByStatus()}
                      data-dismiss="modal"
                    >
                      Add Comment{" "}
                    </button>
                  </div>
                </div>
              </div>

              <div className="row pt-3 mb-3">
                <div className="col-md-12 centerText"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Amount Pop Up*/}
      <div
        className="modal fade"
        id="editTranche"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none">
              <h5 className="modal-title " id="exampleModalLongTitle">
                {editName} <span className="eac-name-pop-up"></span>
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {nameProp == "date_of_release" ||
              nameProp == "tranche_requested_date" ? (
                <div>
                  <div className="row">
                    <div className="col-md-12 text-left">
                      <span className="col-form-head">Select Date</span>
                    </div>
                  </div>
                  <div className="row mt-1-5">
                    <div className="col-md-12 text-left">
                      <Calendar
                        id="date_of_release"
                        name={
                          nameProp == "date_of_release" ? "date_of_release" : ""
                        }
                        className="form-control input-height"
                        format="dd/MM/yyyy"
                        selectedDate={
                          updateTranche.date_of_release != null
                            ? updateTranche.date_of_release
                            : new Date()
                        }
                        onDateChange={(date: Date, field: string) =>
                          handleDateChange(date, field)
                        }
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-md-12 center">
                    {nameProp == "funds_requested" ? (
                      <input
                        type="number"
                        className="form-control"
                        name={nameProp}
                        value={updateTranche.funds_requested}
                        placeholder={editName}
                        onChange={handleChange}
                      ></input>
                    ) : (
                      ""
                    )}

                    {nameProp == "approved_amount" ||
                    nameProp == "fund_released" ? (
                      <input
                        type="number"
                        className="form-control"
                        name={nameProp}
                        value={
                          nameProp == "approved_amount"
                            ? updateTranche.approved_amount
                            : nameProp == "fund_released"
                            ? updateTranche.fund_released
                            : updateTranche.tranch_comment
                        }
                        placeholder={editName}
                        onChange={handleChange}
                      ></input>
                    ) : nameProp == "tranch_comment" ? (
                      <textarea
                        className="form-control"
                        name={nameProp}
                        value={updateTranche.tranch_comment}
                        rows={4}
                        placeholder={"Type Comment"}
                        onChange={handleChange}
                      ></textarea>
                    ) : (
                      ""
                    )}
                    {nameProp == "cash_in_hand" ? (
                      <div>
                        <label className="col-form-head">
                          Is your Cash-in -Hand less than 10% of the total
                          commitment by EAC(including the interest accumulated
                          and net returns)?
                        </label>
                        <div className="toggle-btn-group">
                          <div className="btn-group btn-group-toggle ">
                            <label
                              className={
                                trancheRequest.cash_in_hand === "1"
                                  ? "btn active"
                                  : "btn"
                              }
                            >
                              <input
                                type="radio"
                                name="cash_in_hand"
                                id="cash_in_hand_1"
                                value="1"
                                onChange={handleChangeTrancheRequest}
                                checked={trancheRequest.cash_in_hand === "1"}
                              />
                              Yes
                            </label>
                            <label
                              className={
                                trancheRequest.cash_in_hand === "0"
                                  ? "btn active"
                                  : "btn"
                              }
                            >
                              <input
                                type="radio"
                                name="cash_in_hand"
                                id="cash_in_hand_2"
                                value="0"
                                onChange={handleChangeTrancheRequest}
                                checked={trancheRequest.cash_in_hand === "0"}
                              />
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
              {showLimitMessage == true &&
              updateTranche.approved_amount > tranche1_approved_limit &&
              (loggedInRoleName == "incubator" ||
                loggedInRoleName == "startup") ? (
                <div className="row">
                  <div className="col-md-12 text-left">
                    <span className="error-message">
                      You cannot approve more than 40% of total grant approved
                      amount(that is more than {tranche1_approved_limit}).
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="row">
                <div className="col-md-12 text-right">
                  <div className="btn-group mt-1-5 ">
                    <button
                      className="tertiary   btn-transparent-primary"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>

                    {showLimitMessage == true &&
                    updateTranche.approved_amount > tranche1_approved_limit &&
                    (loggedInRoleName == "incubator" ||
                      loggedInRoleName == "startup") ? (
                      <button
                        type="button"
                        disabled
                        className="btn btn-sisfs btn-yellow-primary ml-2"
                        onClick={() =>
                          nameProp == "tranch_comment"
                            ? updateTrancheCommentByStatus()
                            : updateTrancheDetails(nameProp)
                        }
                        data-dismiss="modal"
                      >
                        Update{" "}
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-sisfs btn-yellow-primary ml-2"
                        onClick={() =>
                          nameProp == "tranch_comment"
                            ? updateTrancheCommentByStatus()
                            : updateTrancheDetails(nameProp)
                        }
                        data-dismiss="modal"
                      >
                        Update{" "}
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className="row pt-3 mb-3">
                <div className="col-md-12 centerText"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Status Pop Up*/}
      <div
        className="modal fade"
        id="editTrancheStatus"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none">
              <h5 className="modal-title " id="exampleModalLongTitle">
                Update Tranches Status <span className="eac-name-pop-up"></span>
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12 text-left">
                  <span className="col-form-head">Tranche Status</span>
                </div>
              </div>
              <div className="row mt-1-5">
                <div className="col-md-12 text-left">
                  <div className="select-custom">
                    <select
                      className="form-control"
                      aria-label="Select Status"
                      id="tranche_status_id"
                      name="tranche_status_id"
                      value={updateTranche.tranche_status_id}
                      onChange={handleChange}
                    >
                      {applicationStatus.map((item) =>
                        item.label != "Requested" ? (
                          <option key={item.label} value={item.value}>
                            {item.label}
                          </option>
                        ) : (
                          ""
                        )
                      )}
                    </select>
                  </div>
                </div>
              </div>

              <div className="row  mt-1-5">
                <div className="col-md-12 text-left">
                  <span className="col-form-head">Tranche Comment</span>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <textarea
                    className="form-control"
                    name="tranch_comment"
                    value={updateTranche.tranch_comment}
                    rows={4}
                    placeholder="Type Comment"
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-right">
                  <div className="btn-group mt-1-5 ">
                    <button
                      className="tertiary   btn-transparent-primary"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>

                    <button
                      type="button"
                      className="btn btn-sisfs btn-yellow-primary ml-2"
                      onClick={() => updateTrancheDetails("tranche_status_id")}
                      data-dismiss="modal"
                    >
                      Update{" "}
                    </button>
                  </div>
                </div>
              </div>

              <div className="row pt-3 mb-3">
                <div className="col-md-12 centerText"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showEditSuccessModal && (
        <>
          <div className="modal-backdrop fade show"></div>

          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionSuccess"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <i className="fa fa-check-circle-o"></i>
                  <h3 className="modal-message">Thank You !</h3>
                  <div className="modal-text">
                    Tranche Details Updated Successfully !!
                  </div>
                  <div className="modal-action">
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={dismissModal}
                    >
                      Ok !!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showEditFailureModal && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionError"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  {/* <i className="fa fa-times-circle-o"></i> */}
                  <h3 className="modal-message message-orange">
                    Oops! Something is Wrong
                  </h3>
                  <br />
                  <h3 className="modal-message message-orange">
                    Please Try Again !!!
                  </h3>
                  <div className="modal-action">
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={dismissModal}
                    >
                      OK !
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default TrancheWiseDetails;
