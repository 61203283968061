import React, { useEffect, useState } from 'react';
import { FULL_REPORT } from "../../../constants/api";
import { get, post } from '../../../effects/api/api.common';
import { changeNumberFormat } from '../../../utils/CommonMethods';
import { getSessionStorageValue } from '../../../effects/utils/session-storage';
interface IReportProps {
  role: string;
}
function FullIncubatorDetailModal(props: IReportProps) {
  const { role } = props;
  const [fullReportDetails, setFullReportDetails] = useState({
    average_convertible_debentures_debt_per_startup: "",
    average_grant_per_startup: "",
    incubators_that_have_funded_atleast_one_startup: "",
    incubators_that_have_funded_not_funded_any_startup: "",
    no_of_incubators_to_whom_funds_have_been_disbursed: "",
    no_of_startups_to_whom_funds_have_been_disbursed: "",
    percentage_of_funds_disbursed_to_startups: "",
    quantum_of_funds_disbursed_to_startups: "",
    quantum_of_given_to_startups_as_convertible_debentures: "",
    quantum_of_given_to_startups_as_debt: "",
    quantum_of_given_to_startups_as_grants: "",
    total_amount_of_funds_disbursed_to_incubators: "",
    total_funds_committed_to_incubators: "",
    total_unique_incubators_funded_till_date: "",
    average_debt_per_startup: '',
    no_of_startup_users: '',
    number_of_startup_given_convertible_debentures: '',
    number_of_startup_given_debt: '',
    number_of_startup_given_grant: ''
  });
  const [fullReportDetailsIncubator, setFullReportDetailsIncubator] = useState({
    startup_engagement_status_due_diligence_ongoing: '',
    startup_engagement_status_incomplete: '',
    startup_engagement_status_on_hold: '',
    startup_engagement_status_pending_review: '',
    startup_engagement_status_submitted: ''
  })

  useEffect(() => {
    getFullReport();
  }, []);
  const getFullReport = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    var payload ={
      "roles": userInfo.roles,
      "user_id":userInfo.id
    } 
    post(FULL_REPORT,payload).then((response: any) => {
      if (!response || !response.data) return;
      if (role == "dpiit" || role == "eac") {
        setFullReportDetails({
          average_convertible_debentures_debt_per_startup: response.data.data.average_convertible_debentures_per_startup,
          average_grant_per_startup: response.data.data.average_grant_per_startup,
          incubators_that_have_funded_atleast_one_startup: response.data.data.incubators_that_have_funded_atleast_one_startup,
          incubators_that_have_funded_not_funded_any_startup: response.data.data.incubators_that_have_funded_not_funded_any_startup,
          no_of_incubators_to_whom_funds_have_been_disbursed: response.data.data.no_of_incubators_to_whom_funds_have_been_disbursed,
          no_of_startups_to_whom_funds_have_been_disbursed: response.data.data.no_of_startups_to_whom_funds_have_been_disbursed,
          percentage_of_funds_disbursed_to_startups: response.data.data.percentage_of_funds_disbursed_to_startups,
          quantum_of_funds_disbursed_to_startups: response.data.data.quantum_of_funds_disbursed_to_startups,
          quantum_of_given_to_startups_as_convertible_debentures: response.data.data.quantum_of_given_to_startups_as_convertible_debentures,
          quantum_of_given_to_startups_as_debt: response.data.data.quantum_of_given_to_startups_as_debt,
          quantum_of_given_to_startups_as_grants: response.data.data.quantum_of_given_to_startups_as_grants,
          total_amount_of_funds_disbursed_to_incubators: response.data.data.total_amount_of_funds_disbursed_to_incubators,
          total_funds_committed_to_incubators: response.data.data.total_funds_committed_to_incubators,
          total_unique_incubators_funded_till_date: response.data.data.total_unique_incubators_funded_till_date,
          average_debt_per_startup: response.data.data.average_debt_per_startup,
          no_of_startup_users: response.data.data.no_of_startup_users,
          number_of_startup_given_convertible_debentures: response.data.data.number_of_startup_given_convertible_debentures,
          number_of_startup_given_debt: response.data.data.number_of_startup_given_debt,
          number_of_startup_given_grant: response.data.data.number_of_startup_given_grant
        })
      }
      else if (role == "incubator") {
        setFullReportDetailsIncubator({
          startup_engagement_status_due_diligence_ongoing: response.data.data.startup_engagement_status_due_diligence_ongoing,
          startup_engagement_status_incomplete: response.data.data.startup_engagement_status_incomplete,
          startup_engagement_status_on_hold: response.data.data.startup_engagement_status_on_hold,
          startup_engagement_status_pending_review: response.data.data.startup_engagement_status_pending_review,
          startup_engagement_status_submitted: response.data.data.startup_engagement_status_submitted
        })
      }

    });
  };
  return (
    <div className="modal fade full-modal" id="exampleModalCenter" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header modal-header-bottom">
            <h5 className="modal-title" id="exampleModalLongTitle">Full Report</h5>

            <div className="ml-auto">

              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>

              {/* <a className="btn-sisfs btn-transparent-primary float-right mb-1 mt-1">Download PDF</a> */}
            </div>
          </div>
          <div className="modal-body">
            {(role == "dpiit" || role == "eac") && <div className="row">

              <div className="col-md-4 pr-4">
                <div className="row report-title no-gutters pb-4">
                  <div className="col-md-12">
                    {/* <span className="card-count count-bg">104</span><br/> */}
                    <span className="card-count count-bg">{changeNumberFormat(Number(fullReportDetails.no_of_incubators_to_whom_funds_have_been_disbursed), 2, 1)}</span><br />
                    <span className="modal-text">No. of Incubators to whom funds have been disbursed</span>
                  </div>
                </div>
                <div className="row report-list no-gutters">
                  <div className="col-md-9">
                    <span className="modal-text">Total unique incubators funded till date</span>
                  </div>
                  <div className="col-md-3 text-right">
                    {/* <span className="card-count">232</span> */}
                    <span className="card-count">{changeNumberFormat(Number(fullReportDetails.total_unique_incubators_funded_till_date), 2, 1)}</span>
                  </div>
                </div>

                <div className="row report-list no-gutters">
                  <div className="col-md-9">
                    <span className="modal-text">Total funds committed to incubators</span>
                  </div>
                  <div className="col-md-3 text-right">
                    {/* <span className="card-count">&#8377;1.5 Cr</span> */}
                    <span className="card-count">{changeNumberFormat(Number(fullReportDetails.total_funds_committed_to_incubators), 2, 1)}</span>
                  </div>
                </div>

                <div className="row report-list no-gutters ">
                  <div className="col-md-9">
                    <span className="modal-text">Incubators that have funded atleast one startup</span>
                  </div>
                  <div className="col-md-3 text-right">
                    {/* <span className="card-count">38</span> */}
                    <span className="card-count">{changeNumberFormat(Number(fullReportDetails.incubators_that_have_funded_atleast_one_startup), 2, 1)}</span>
                  </div>
                </div>

                <div className="row report-list no-gutters ">
                  <div className="col-md-9">
                    <span className="modal-text">Incubators that have not funded any startup</span>
                  </div>
                  <div className="col-md-3 text-right">
                    {/* <span className="card-count">19</span> */}
                    <span className="card-count">{changeNumberFormat(Number(fullReportDetails.incubators_that_have_funded_not_funded_any_startup), 2, 1)}</span>
                  </div>
                </div>

              </div>

              <div className="col-md-8 pr-4">

                <div className="row report-title no-gutters pb-4">
                  <div className="col-md-12">
                    {/* <span className="card-count count-small">&#8377;</span><span className="card-count count-bg">12.6 </span><span className="card-count count-small">Cr</span><br/> */}
                    <span className="card-count count-bg">{changeNumberFormat(Number(fullReportDetails.total_amount_of_funds_disbursed_to_incubators), 2, 1)}</span><br />
                    <span className="modal-text">Total amount of funds disbursed to Incubators</span>
                  </div>
                </div>
                <div className="row pb-4">
                  <div className="col-md-6 pr-4">
                    <div className="row report-list no-gutters ">
                      <div className="col-md-9">
                        <span className="modal-text">No. of startups to whom funds have been disbursed</span>
                      </div>
                      <div className="col-md-3 text-right">
                        {/* <span className="card-count">125</span> */}
                        <span className="card-count">{changeNumberFormat(Number(fullReportDetails.no_of_startups_to_whom_funds_have_been_disbursed), 2, 1)}</span>
                      </div>
                    </div>

                    <div className="row report-list no-gutters ">
                      <div className="col-md-9">
                        <span className="modal-text">Percentage of funds disbursed to Startups</span>
                      </div>
                      <div className="col-md-3 text-right">
                        {/* <span className="card-count">45%</span> */}
                        <span className="card-count">{Number(fullReportDetails.percentage_of_funds_disbursed_to_startups).toFixed(2)}</span>
                      </div>
                    </div>

                    <div className="row report-list no-gutters ">
                      <div className="col-md-9">
                        <span className="modal-text">Quantum of funds disbursed to Startups</span>
                      </div>
                      <div className="col-md-3 text-right">
                        {/* <span className="card-count">&#8377;1.1 Cr</span> */}
                        <span className="card-count">{changeNumberFormat(Number(fullReportDetails.quantum_of_funds_disbursed_to_startups), 2, 1)}</span>
                      </div>
                    </div>
                    <div className="row report-list no-gutters ">
                      <div className="col-md-9">
                        <span className="modal-text">Quantum of funds given to Startups as Funds</span>
                      </div>
                      <div className="col-md-3 text-right">
                        {/* <span className="card-count">&#8377;55 L</span> */}
                        <span className="card-count">{changeNumberFormat(Number(fullReportDetails.quantum_of_given_to_startups_as_grants), 2, 1)}</span>
                      </div>
                    </div>
                    <div className="row report-list no-gutters ">
                      <div className="col-md-9">
                        <span className="modal-text">Average debt per startup</span>
                      </div>
                      <div className="col-md-3 text-right">
                        {/* <span className="card-count">&#8377;55 L</span> */}
                        <span className="card-count">{changeNumberFormat(Number(fullReportDetails.average_debt_per_startup), 2, 1)}</span>
                      </div>
                    </div>
                    <div className="row report-list no-gutters ">
                      <div className="col-md-9">
                        <span className="modal-text">No of startup users</span>
                      </div>
                      <div className="col-md-3 text-right">
                        {/* <span className="card-count">&#8377;55 L</span> */}
                        <span className="card-count">{changeNumberFormat(Number(fullReportDetails.no_of_startup_users), 2, 1)}</span>
                      </div>
                    </div>

                  </div>

                  <div className="col-md-6 pr-4">

                    <div className="row report-list no-gutters ">
                      <div className="col-md-9">
                        <span className="modal-text">Quantum of funds given to Startups as Debts</span>
                      </div>
                      <div className="col-md-3 text-right">
                        {/* <span className="card-count">&#8377;28 L</span> */}
                        <span className="card-count">{changeNumberFormat(Number(fullReportDetails.quantum_of_given_to_startups_as_debt), 2, 1)}</span>
                      </div>
                    </div>
                    <div className="row report-list no-gutters ">
                      <div className="col-md-9">
                        <span className="modal-text">Quantum of funds given to Startups as Convertible Debentures</span>
                      </div>
                      <div className="col-md-3 text-right">
                        {/* <span className="card-count">&#8377;33 L</span> */}
                        <span className="card-count">{changeNumberFormat(Number(fullReportDetails.quantum_of_given_to_startups_as_convertible_debentures), 2, 1)}</span>
                      </div>
                    </div>

                    <div className="row report-list no-gutters ">
                      <div className="col-md-9">
                        <span className="modal-text">Average Converteble Debentures/Debt per Startups</span>
                      </div>
                      <div className="col-md-3 text-right">
                        {/* <span className="card-count">&#8377;30 L</span> */}
                        <span className="card-count">{changeNumberFormat(Number(fullReportDetails.average_convertible_debentures_debt_per_startup), 2, 1)}</span>
                      </div>
                    </div>

                    <div className="row report-list no-gutters ">
                      <div className="col-md-9">
                        <span className="modal-text">Average Grant per Startup</span>
                      </div>
                      <div className="col-md-3 text-right">
                        {/* <span className="card-count">&#8377;45 L</span> */}
                        <span className="card-count">{changeNumberFormat(Number(fullReportDetails.average_grant_per_startup), 2, 1)}</span>
                      </div>
                    </div>
                    <div className="row report-list no-gutters ">
                      <div className="col-md-9">
                        <span className="modal-text">Number of startup given convertible debentures</span>
                      </div>
                      <div className="col-md-3 text-right">
                        {/* <span className="card-count">&#8377;55 L</span> */}
                        <span className="card-count">{changeNumberFormat(Number(fullReportDetails.number_of_startup_given_convertible_debentures), 2, 1)}</span>
                      </div>
                    </div>
                    <div className="row report-list no-gutters ">
                      <div className="col-md-9">
                        <span className="modal-text">Number of startup given debt</span>
                      </div>
                      <div className="col-md-3 text-right">
                        {/* <span className="card-count">&#8377;55 L</span> */}
                        <span className="card-count">{changeNumberFormat(Number(fullReportDetails.number_of_startup_given_debt), 2, 1)}</span>
                      </div>
                    </div>
                    <div className="row report-list no-gutters ">
                      <div className="col-md-9">
                        <span className="modal-text">Number of startup given grant</span>
                      </div>
                      <div className="col-md-3 text-right">
                        {/* <span className="card-count">&#8377;55 L</span> */}
                        <span className="card-count">{changeNumberFormat(Number(fullReportDetails.number_of_startup_given_grant), 2, 1)}</span>
                      </div>
                    </div>

                  </div>
                </div>

              </div>

            </div>}
            {role == "incubator" && <div className="row">

              <div className="col-md-6 pr-4">
                {/* <div className="row report-title no-gutters pb-4">
                  <div className="col-md-12">
                    <span className="card-count count-bg">{changeNumberFormat(Number(fullReportDetails.no_of_incubators_to_whom_funds_have_been_disbursed), 2, 1)}</span><br />
                    <span className="modal-text">No. of Incubators to whom funds have been disbursed</span>
                  </div>
                </div> */}
                <div className="row report-list no-gutters">
                  <div className="col-md-9">
                    <span className="modal-text">Startup engagement status due diligence ongoing</span>
                  </div>
                  <div className="col-md-3 text-right">
                    {/* <span className="card-count">232</span> */}
                    <span className="card-count">{changeNumberFormat(Number(fullReportDetailsIncubator.startup_engagement_status_due_diligence_ongoing), 2, 1)}</span>
                  </div>
                </div>

                <div className="row report-list no-gutters">
                  <div className="col-md-9">
                    <span className="modal-text">Startup engagement status due diligence ongoing</span>
                  </div>
                  <div className="col-md-3 text-right">
                    {/* <span className="card-count">&#8377;1.5 Cr</span> */}
                    <span className="card-count">{changeNumberFormat(Number(fullReportDetailsIncubator.startup_engagement_status_due_diligence_ongoing), 2, 1)}</span>
                  </div>
                </div>

                <div className="row report-list no-gutters">
                  <div className="col-md-9">
                    <span className="modal-text">Startup engagement status submitted</span>
                  </div>
                  <div className="col-md-3 text-right">
                    {/* <span className="card-count">&#8377;1.5 Cr</span> */}
                    <span className="card-count">{changeNumberFormat(Number(fullReportDetailsIncubator.startup_engagement_status_submitted), 2, 1)}</span>
                  </div>
                </div>



              </div>

              <div className="col-md-6 pr-4">
                <div className="row report-list no-gutters">
                  <div className="col-md-9">
                    <span className="modal-text">Startup engagement status on hold</span>
                  </div>
                  <div className="col-md-3 text-right">
                    {/* <span className="card-count">232</span> */}
                    <span className="card-count">{changeNumberFormat(Number(fullReportDetailsIncubator.startup_engagement_status_on_hold), 2, 1)}</span>
                  </div>
                </div>

                <div className="row report-list no-gutters">
                  <div className="col-md-9">
                    <span className="modal-text">Startup engagement status pending review</span>
                  </div>
                  <div className="col-md-3 text-right">
                    {/* <span className="card-count">&#8377;1.5 Cr</span> */}
                    <span className="card-count">{changeNumberFormat(Number(fullReportDetailsIncubator.startup_engagement_status_pending_review), 2, 1)}</span>
                  </div>
                </div>

              </div>

            </div>}
            <div className="row pt-3 pb-3">
              <div className="col-md-12 centerText">
                <button className="tertiary  btn-yellow-primary" data-dismiss="modal" >
                  OK GOT IT!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FullIncubatorDetailModal;