import '../incubator/incubator.css';
import { ApplicationGeneralDetails } from './ApplicationGeneralDetails';

interface IContactProps {
  generalDetailsModel: ApplicationGeneralDetails;
}

function ContactComponent(props: IContactProps) {
  const { generalDetailsModel } = props;
  //console.log(generalDetailsModel, 'IContactProps');

  return (
    <div className="">
      <div className="row left-align mt-05">
        <div className="col-md-4">
          <span className="incubator-label">Name of the Representative</span>
          <br />
          <span className="incubator-label-value">
            {generalDetailsModel.representative_name == '' ||
            generalDetailsModel.representative_name == null
              ? '-'
              : generalDetailsModel.representative_name}
          </span>
        </div>
        <div className="col-md-4">
          <span className="incubator-label">Designation</span>
          <br />
          <span className="incubator-label-value">
            {generalDetailsModel.representative_designation == '' ||
            generalDetailsModel.representative_designation == null
              ? '-'
              : generalDetailsModel.representative_designation}
          </span>
        </div>
      </div>

      <div className="row left-align mt-05">
        <div className="col-md-4">
          <span className="incubator-label">Mobile No.</span>
          <br />
          <span className="incubator-label-value">
            {generalDetailsModel.representative_mobile_no == null ||
            generalDetailsModel.representative_mobile_no == null
              ? '-'
              : generalDetailsModel.representative_mobile_no}
          </span>
        </div>
        <div className="col-md-4">
          <span className="incubator-label">Email</span>
          <br />

          {generalDetailsModel.representative_email == '' ||
          generalDetailsModel.representative_email == null ? (
            ' <span className="incubator-label-value">-</span>'
          ) : (
            <a
              className="incubator-email-value"
              href={`mailto:${generalDetailsModel.representative_email}`}
            >
              {generalDetailsModel.representative_email}
            </a>
          )}
        </div>
      </div>

      <div className="row left-align mt-05">
        <div className="col-md-4">
          <span className="label-lh-28">Incubator Address</span>
          <br />
          <span className="value-lh-20">
            {generalDetailsModel.address == '' ||
            generalDetailsModel.address == null
              ? '-'
              : generalDetailsModel.address}
          </span>
        </div>
        <div className="col-md-4"></div>
      </div>
      <div className="row left-align">
        <div className="col-md-4"></div>
        <div className="col-md-4"></div>
      </div>
    </div>
  );
}
export default ContactComponent;
