import React from 'react';

function HomeOperationsComponent(){
    return(
        <section className="section-container section-bg-grey">
            <div className="container">
                <div className="section-operation">  
                    <div className="section-heading">
                        <h2>How Startup India Seed Fund Will Operate</h2>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xs-12 col-sm-12 col-md-6 section-content">
                            <p>The Seed Fund will be disbursed to eligible startups 
                                through eligible incubators across India</p>
                        </div>
                    </div>
                    <div className="operation-workflow">
                        <div className="clearfix">
                            <div className="circle">
                                <div className="bd-placeholder-img rounded-circle">
                                    <span>DPIIT</span>
                                </div>
                                <div className="bd-placeholder-content">
                                    <h4>Department for Promotion of Industry and Internal Trade</h4>
                                    <p>Nodal Department</p> 
                                </div>                                  
                            </div>

                            <div className="circle">
                                <div className="bd-placeholder-img rounded-circle">
                                    <span>EAC</span>
                                </div>
                                <div className="bd-placeholder-content">
                                    <h4>Experts Advisory Committee</h4>
                                    <p>Government Representatives & Industry Experts</p>   
                                </div>                                
                            </div>

                            <div className="circle">
                                <div className="bd-placeholder-img rounded-circle">
                                    <span>Incubators</span>
                                </div>
                                <div className="bd-placeholder-content">
                                    <h4>Govt assisted/Not-Govt assisted Incubators</h4>
                                    <p>Operational for atleast 2-3 years</p>    
                                </div>                               
                            </div>

                            <div className="circle">
                                <div className="bd-placeholder-img rounded-circle">
                                    <span>Startups</span>
                                </div>
                                <div className="bd-placeholder-content">
                                    <h4>DPIIT-recognised Startups</h4>
                                    <p>Incorporated less than 2 years ago</p> 
                                </div>                                  
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>
        </section>
    )
}

export default HomeOperationsComponent;