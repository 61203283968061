import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

interface ICalendarProps {
  id: string;
  name: string;
  className: string;
  selectedDate: any;
  format?: string;
  onDateChange: (date: Date, field: string) => void;
  maxDate?: any;
  minDate?:any;
}
/** Renders Calendar dropdown */
function Calendar({
  id,
  name,
  selectedDate,
  className,
  format,
  onDateChange,
  maxDate,
  minDate
}: ICalendarProps) {
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    setStartDate(selectedDate);
  }, []);
  return (
    <ReactDatePicker
      id={id}
      name={name}
      selected={startDate}
      className={className}
      dateFormat={format}
      onChange={(date: Date) => {
        setStartDate(date);
        onDateChange(date, name);
      }}
      value={selectedDate}
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      maxDate = {maxDate}
      minDate={minDate}
    />
  );
}

export default Calendar;
