import FileUploader from "@amodv/react-file-uploader";
import "../../../../src/styles/component.css";
import { SubsequentEnum } from "../../../utils/Enums";
import { useEffect, useState } from "react";
import { SubsequentFileUploadModel } from "../../../models/incubator/SubsequentFileUploadModel";
import { FileUploadModel } from "../../../models/FileUploadModel";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { AuthContextType, AuthContext } from "../../../Contexts/AuthContext";
import React from "react";
import fileupload from "../../../images/fileupload.svg";
import pdfImg from "../../../images/pdfimg.svg";
import FileUploadInfo from "./FileUploadInfo";
import error from "../../../images/error.svg";
import {
  GET_SUBSEQUENT_UPLOADS,
  NODE_URL,
  SUBSEQUENT_UPLOAD,
  UPDATE_FILE_STATUS,
  secgreybox,
} from "../../../constants/api";
import { post } from "../../../effects/api/api.common";
import { getSessionStorageValue } from "../../../effects/utils/session-storage";
import ConfirmationDialog from "../../common/controls/ConfirmationDialog";
import moment from "moment";

const colorIndication = ["Submittedbg", "Readbg", "Acceptedbg", "Rejectedbg"];
const statusfields = [
  "utilization_certificate_status_ind",
  "audited_statement_status_ind",
  "interest_earned_status_ind",
  "ismsc_meetings_status_ind",
  "proof_of_achievement_status_ind",
  "instrument_type_details_status_ind",
  "grant_disbursed_dtls_status_ind",
  "selected_startup_declation_status_ind",
  "List_of_Startups_status_ind",
  "proof_of_pfms_status_ind",
  "previous_sanction_order_status_ind",
  "tra_agreement_status_ind",
];

const commentFields = [];
interface IIKeyValueIndexer {
  [key: string]: boolean;
}

function SubsequentTranche() {
  const history = useHistory();
  const [openUploadBlock, setOpenUploadBlock] = useState<IIKeyValueIndexer>();
  const [openAllFileUploadBlock, setopenAllFileUploadBlock] =
    useState<IIKeyValueIndexer>();
  const { utilizationFile, setUtizationFile } = React.useContext(
    AuthContext
  ) as AuthContextType;
  const { auditedFile, setAuditedFile } = React.useContext(
    AuthContext
  ) as AuthContextType;
  const { interestFile, setInterestFile } = React.useContext(
    AuthContext
  ) as AuthContextType;
  const { ismcFile, setIsmcFile } = React.useContext(
    AuthContext
  ) as AuthContextType;
  const { achievementFile, setachievementFile } = React.useContext(
    AuthContext
  ) as AuthContextType;
  const { debentureFile, setdebentureFile } = React.useContext(
    AuthContext
  ) as AuthContextType;
  const { disbursedFile, setdisbursedFile } = React.useContext(
    AuthContext
  ) as AuthContextType;
  const { declarationFile, setdeclarationFile } = React.useContext(
    AuthContext
  ) as AuthContextType;
  // const { legalAgreementFile, setlegalAgreementFile } = React.useContext(AuthContext) as AuthContextType;
  const { listStartups, setListStartups } = React.useContext(
    AuthContext
  ) as AuthContextType;
  const { pfmsFile, setpfmsFile } = React.useContext(
    AuthContext
  ) as AuthContextType;
  const { sanctionFile, setsanctionFile } = React.useContext(
    AuthContext
  ) as AuthContextType;
  const { traFIle, settraFIle } = React.useContext(
    AuthContext
  ) as AuthContextType;
  const initialVal = localStorage.getItem("errorKey");
  const [filename, setFilename] = useState("");
  const [errorBox, setErrorBox] = useState(initialVal);
  const [exceedFileSize, setExceedFileSize] = useState(false);
  const [loader, setLoader] = useState(false);
  const [getFilesData, setGetFilesData] = useState<Record<string, any> | null>(
    null
  );
  const location = useLocation();
  const [propsData, setPropsData] = useState<any>();
  const [showRejectedText, setShowrejectedText] = useState(false);
  const [failDialogData, setFailDialogData] = useState({
    header: "",
    text: "",
  });
  const [showUploadFailureModal, setShowUploadFailureModal] = useState(false);
  const [readFileFirst, setReadFileFirst] = useState<any>();
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [currentFileDetails, setCurrentFileDetails] = useState({
    name: "",
    status: "",
    action: "",
    file_no: "",
    comment: [],
  });
  const [rejectedText, setRejectedText] = useState<any>();
  const [loggedInRole, setLoggedInRole] = useState("");
  const [loggedInId, setLoggedInId] = useState("");
  const [getBackgroundColor, setBackgroundColor] = useState<any>();
  const [getGreyboxDtls, setGetGreyboxDtls] = useState<any>();
  const [inc_app_num, setIncAppNum] = useState();
  const params: any = useParams();
  useEffect(() => {
    getFileUploads();
    // saveDraft('0');
    console.log(params);
    window.scrollTo(0, 0);
    setPropsData(location.state);
  }, []);
  useEffect(() => {
    if (getSessionStorageValue("user")) {
      const user: any = getSessionStorageValue("user");
      const userInfo = JSON.parse(user);
      setLoggedInRole(userInfo.roles);
      setLoggedInId(userInfo.id);
    }
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    const userId = params?.id ? params?.id : userInfo.id;
  }, []);
  const getFileUploads = async () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    const userId = params?.id ? params?.id : userInfo.id;

    let inc_application_num: any;
    setLoader(true);
    await post(secgreybox, { user_id: userId }).then(async (response: any) => {
      setLoader(false);
      for (let i = 0; i < response.data.Data.length; i++) {
        if (response.data.Data[i].application_index === params.app_index) {
          setGetGreyboxDtls(response.data.Data[i]);
          inc_application_num = response.data.Data[i].incubator_application_no;
        } else {
          setGetGreyboxDtls(response.data.Data[i]);
          inc_application_num = response.data.Data[i].incubator_application_no;
        }
      }
      // setGetGreyboxDtls(response.data.Data[0])
    });

    setLoader(true);
    setTimeout(() => {
      const payloaad = {
        userId: userId,
        TrancheNumber: params.tranche_number,
        application_ind: params.app_index,
        incubator_application_no: inc_application_num,
      };
      // console.log(payloaad)
      post(GET_SUBSEQUENT_UPLOADS, payloaad).then((response: any) => {
        setLoader(false);
        if (!response || !response?.data?.data) return;
        // console.log(response?.data?.data[0],"detailsss")
        setGetFilesData(response?.data?.data[0]);

        setUtizationFile(
          response?.data?.data[0].utilization_certificate_file_name
        );
        setAuditedFile(response?.data?.data[0].audited_statement_file_name);
        setInterestFile(response?.data?.data[0].interest_earned_file_name);
        setIsmcFile(response?.data?.data[0].ismsc_meetings_file_name);
        setachievementFile(
          response?.data?.data[0].proof_of_achievement_file_name
        );
        setdebentureFile(
          response?.data?.data[0].instrument_type_details_file_name
        );
        setdisbursedFile(
          response?.data?.data[0].grant_disbursed_dtls_file_name
        );
        setdeclarationFile(
          response?.data?.data[0].selected_startup_declation_file_name
        );
        // setlegalAgreementFile(response?.data?.data[0].legal_agreements_file_name)
        setListStartups(response.data.data[0].List_of_Startups_file_name);
        setpfmsFile(response?.data?.data[0].proof_of_pfms_file_name);
        setsanctionFile(
          response?.data?.data[0].previous_sanction_order_file_name
        );
        settraFIle(response?.data?.data[0].tra_agreement_file_name);

        let backgroundColors: any = [];
        let tranche_label = localStorage.getItem("trancheLabel");
        if (tranche_label == "Verification of Documents Completed")
          setShowrejectedText(true);
        for (let i = 0; i < statusfields.length; i++) {
          if (response?.data?.data[0][statusfields[i]] == 0) {
            if (
              tranche_label == "Verification of Documents Completed" &&
              loggedInRole != "secretariat"
            ) {
              backgroundColors.push(`Acceptedbg`);
            } else {
              backgroundColors.push(`Rejectedbg`);
            }
          }
          if (response?.data?.data[0][statusfields[i]] == 3) {
            if (
              tranche_label == "Verification of Documents Completed" &&
              loggedInRole != "secretariat"
            ) {
              backgroundColors.push(`Acceptedbg`);
            } else {
              backgroundColors.push(`Readbg`);
            }
          }
          if (response?.data?.data[0][statusfields[i]] == 4) {
            backgroundColors.push(`Acceptedbg`);
          }
          if (response?.data?.data[0][statusfields[i]] == 2) {
            if (
              tranche_label == "Verification of Documents Completed" &&
              loggedInRole != "secretariat"
            ) {
              backgroundColors.push(`Acceptedbg`);
            } else {
              backgroundColors.push(`Submittedbg`);
            }
          }
          if (response?.data?.data[0][statusfields[i]] == 1) {
            if (
              tranche_label == "Verification of Documents Completed" &&
              loggedInRole != "secretariat"
            ) {
              backgroundColors.push(`Acceptedbg`);
            } else {
              backgroundColors.push(`savedbg`);
            }
          }
        }
        if (response?.data.data[0].status_ind !== null) {
          setBackgroundColor(backgroundColors);
        }
      });
    }, 1500);
  };
  const dismissModal = () => {
    setExceedFileSize(false);
    setConfirmationPopup(false);
    setReadFileFirst("");
  };
  const handleFileChange = (event: any) => {
    const name = event.target.name;
    if (event.target.files[0].size > 10 * 1024 * 1024) {
      setExceedFileSize(true);
      return;
    }
    const file = event.target.files[0];
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);

    console.log(userInfo.roles);

    if (userInfo.roles != "secretariat") {
      const fileUpload = (file: any, file_no: any) => {
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user);
        const userId = userInfo.id;
        const formData = new FormData();
        formData.append("file", file);
        formData.append("FileNo", file_no);
        formData.append("user_id", userId);
        formData.append("status", "1");
        formData.append("TrancheNumber", params.tranche_number);
        formData.append("appplication_id", params.application_id);
        formData.append("application_ind", params.app_index);
        formData.append("userRole", userInfo.roles);
        setLoader(true);
        post(SUBSEQUENT_UPLOAD, formData).then((response: any) => {
          setLoader(false);
          // console.log(response)
          if (!response?.data) {
            setFailDialogData({
              header: "",
              text: response.response.data.message,
            });
            setShowUploadFailureModal(true);
          }
        });
      };
      if (name == "Utilization") {
        // setUtizationFile(file);
        fileUpload(file, 0);
        getFileUploads();
      }
      if (name == "Audited") {
        // setAuditedFile(file)
        fileUpload(file, 1);
        getFileUploads();
      }
      if (name == "Interest") {
        // setInterestFile(file)
        fileUpload(file, 2);
        getFileUploads();
      }
      if (name == "Ismc") {
        // setIsmcFile(file)
        fileUpload(file, 3);
        getFileUploads();
      }
      if (name == "Achievement") {
        // setachievementFile(file)
        fileUpload(file, 4);
        getFileUploads();
      }
      if (name == "Debenture") {
        // setdebentureFile(file)
        fileUpload(file, 5);
        getFileUploads();
      }
      if (name == "Disbursed") {
        // setdisbursedFile(file)
        fileUpload(file, 6);
        getFileUploads();
      }
      if (name == "Declaration") {
        // setdeclarationFile(file)
        fileUpload(file, 7);
        getFileUploads();
      }
      // if (name == 'LegalAgreement') {
      //     setlegalAgreementFile(file)
      //     fileUpload(file, 8)
      // getFileUploads();
      // }
      if (name == "listStartup") {
        // setListStartups(file)
        fileUpload(file, 8);
        getFileUploads();
      }
      if (name == "Pfms") {
        // setpfmsFile(file)
        fileUpload(file, 9);
        getFileUploads();
      }
      if (name == "Sanction") {
        // setsanctionFile(file)
        fileUpload(file, 10);
        getFileUploads();
      }
      if (name == "Tra") {
        // settraFIle(file)
        fileUpload(file, 11);
        getFileUploads();
      }
    } else {
      // if (getFilesData) {
      //   console.log(
      //     "getFilesData",
      //     getFilesData.utilization_certificate_file_path
      //   );
      // }
      console.log("INTO ELSE",getFilesData);
      const fileUpload = (file: any, file_no: any) => {
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user);
        const userId = params?.id ? params?.id : userInfo.id;
        const formData = new FormData();
        formData.append("file", file);
        formData.append("FileNo", file_no);
        formData.append("user_id", userId);
        formData.append("status", "1");
        formData.append("TrancheNumber", params.tranche_number);
        formData.append("appplication_id", params.application_id);
        formData.append("application_ind", params.app_index);
        formData.append("userRole", userInfo.roles);
        setLoader(true);
        console.log(formData)
        post(SUBSEQUENT_UPLOAD, formData).then((response: any) => {
          setLoader(false);
          // console.log(response)
          if (!response?.data) {
            setFailDialogData({
              header: "",
              text: response.response.data.message,
            });
            setShowUploadFailureModal(true);
          }
        });
      };
      if (
        getFilesData &&
        getFilesData.utilization_certificate_file_path == null
      ) {
        fileUpload(file, 0);
        getFileUploads();
      }
      if (getFilesData && getFilesData.audited_statement_file_path == null) {
        // setAuditedFile(file)
        fileUpload(file, 1);
        getFileUploads();
      }
      if (getFilesData && getFilesData.interest_earned_file_path == null) {
        // setInterestFile(file)
        fileUpload(file, 2);
        getFileUploads();
      }
      if (getFilesData && getFilesData.ismsc_meetings_file_path == null) {
        // setIsmcFile(file)
        fileUpload(file, 3);
        getFileUploads();
      }
      if (getFilesData && getFilesData.proof_of_achievement_file_path == null) {
        // setachievementFile(file)
        fileUpload(file, 4);
        getFileUploads();
      }
      if (
        getFilesData &&
        getFilesData.instrument_type_details_file_name == null
      ) {
        // setdebentureFile(file)
        fileUpload(file, 5);
        getFileUploads();
      }
      if (getFilesData && getFilesData.grant_disbursed_dtls_file_path == null) {
        // setdisbursedFile(file)
        fileUpload(file, 6);
        getFileUploads();
      }
      if (
        getFilesData &&
        getFilesData.selected_startup_declation_file_path == null
      ) {
        // setdeclarationFile(file)
        fileUpload(file, 7);
        getFileUploads();
      }
      if (getFilesData && getFilesData.List_of_Startups_path_name == null) {
        // setListStartups(file)
        fileUpload(file, 8);
        getFileUploads();
      }
      if (getFilesData && getFilesData.proof_of_pfms_file_path == null) {
        // setpfmsFile(file)
        fileUpload(file, 9);
        getFileUploads();
      }
      if (
        getFilesData &&
        getFilesData.previous_sanction_order_file_path == null
      ) {
        // setsanctionFile(file)
        fileUpload(file, 10);
        getFileUploads();
      }
      if (getFilesData && getFilesData.tra_agreement_file_path == null) {
        // settraFIle(file)
        fileUpload(file, 11);
        getFileUploads();
      }
    }
  };
  const hanndleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setRejectedText(value);
  };
  useEffect(() => {}, [rejectedText]);
  const removeFileUpload = (file_no: any, comment: any) => {
    if (rejectedText == "") {
      return;
    }
    if (params.id) {
      var payload = {
        type: "INCUBATOR",
        user_id: params.id,
        FileNo: file_no,
        comment: comment,
        TrancheNumber: params.tranche_number,
        trancheAppId: params.application_id,
        application_ind: params.app_index,
        logid: loggedInId,
        tranche_id: params.trancheID,
      };

      // return;
      post(UPDATE_FILE_STATUS, payload).then((response: any) => {
        if (response.data.result == 500) {
          setReadFileFirst(response.data.message);
          return;
        } else {
          setReadFileFirst("");
        }
        getFileUploads();
        setConfirmationPopup(false);
      });
    }
  };
  const acceptFileUpload = (file_no: any, comment: any) => {
    if (rejectedText == "") {
      return;
    }
    if (params.id) {
      var payload = {
        type: "INCUBATOR",
        user_id: params.id,
        FileNo: file_no,
        Accept: comment,
        TrancheNumber: params.tranche_number,
        trancheAppId: params.application_id,
        application_ind: params.app_index,
        logid: loggedInId,
        tranche_id: params.trancheID,
      };

      // return;
      post(UPDATE_FILE_STATUS, payload).then((response: any) => {
        if (response.data.result == 500) {
          setReadFileFirst(response.data.message);
          return;
        } else {
          setReadFileFirst("");
        }
        // if(!)
        getFileUploads();
        setConfirmationPopup(false);
      });
    }
  };
  const getFileVisibiltyStatus = (id: any) => {
    if (params.id) {
      var payload = {
        type: "INCUBATOR",
        user_id: params.id,
        FileNo: id,
        statusNo: 3,
        TrancheNumber: params.tranche_number,
        trancheAppId: params.application_id,
        application_ind: params.app_index,
        logid: loggedInId,
        tranche_id: params.trancheID,
      };

      post(UPDATE_FILE_STATUS, payload).then((response: any) => {
        getFileUploads();
      });
    }
  };
  const fileCommentsPopup = (
    name: any,
    file_status: any,
    action: any,
    fileNo: any,
    comment: any
  ) => {
    let splitComments =
      comment &&
      comment.split("//**//").filter((str: any) => str.trim() !== "");
    setCurrentFileDetails({
      name: name,
      status: file_status,
      action: action,
      file_no: fileNo,
      comment: splitComments,
    });
    setReadFileFirst("");
    setRejectedText("");
    setConfirmationPopup(true);
  };
  const removeFilePopup = (
    name: any,
    file_status: any,
    action: any,
    fileNo: any,
    comment: any
  ) => {
    setCurrentFileDetails({
      name: name,
      status: file_status,
      action: action,
      file_no: fileNo,
      comment: comment,
    });
    setReadFileFirst("");
    setRejectedText("");
    setConfirmationPopup(true);
  };
  const acceptFilePopup = (
    name: any,
    file_status: any,
    action: any,
    fileNo: any,
    comment: any
  ) => {
    setCurrentFileDetails({
      name: name,
      status: file_status,
      action: action,
      file_no: fileNo,
      comment: comment,
    });
    setReadFileFirst("");
    setRejectedText("");
    setConfirmationPopup(true);
  };
  const saveDraft = (id: any) => {
    if (id == "1") {
      // post(SUBSEQUENT_UPLOAD,{"status" : "1"}).then((response: any) => {
      //     console.log(response)
      // })
      // return;

      history.push({
        pathname: params?.id
          ? `/secretariat/subsequent_tranche_two/${params?.id}/${params.tranche_number}/${params.app_index}/${params.application_id}/${params.trancheID}/${params.tranche_status}/${params.incubator_application_no}`
          : `/subsequent_tranche_two/${params.application_id}/${params.tranche_id}/${params.fund_request}/${params.cash_in_hand}/${params.tranche_number}/${params.app_index}/${params.incubator_application_no}`,
      });
    }
    localStorage.removeItem("errorKey");
  };
  const backToFundDetails = () => {
    // return;
    if (loggedInRole == "incubator")
      history.push("/incubator_view_funddetails");
    if (loggedInRole == "secretariat") {
      history.replace({
        pathname: "/merged_Incubator_Details_list",
        state: {
          application_id: getGreyboxDtls.incubator_application_no,
          isFundDetails: false,
          userId: getGreyboxDtls.user_id,
          id: getGreyboxDtls.application_id,
          activeTab: "1",
          status_id: getGreyboxDtls.status_id,
          flag: "status",
        },
      });
    }
  };

  const setModalPopup = (name: string) => {
    setFilename(name);
  };

  return (
    <>
      {loader && (
        <div className="spinner-border custom-spinner" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}
      <fieldset disabled={loader}>
        <div className="row">
          <div className="col-1"></div>
          <div className="col-10">
            <div className="row">
              <span className="tranche-head1">
                Request for the Subsequent Tranche (1/2) :
              </span>
            </div>
            {loggedInRole == "incubator" && (
              <div className="row mt-2 mt-3 mb-2">
                <div className="col-12 p-0 d-flex ">
                  <div
                    className="sNo"
                    style={{ color: "red", fontWeight: "bold" }}
                  >
                    All fields are mandatory, file size should be less than or
                    equal to 10MB
                  </div>
                </div>
              </div>
            )}
            {loggedInRole === "secretariat" && (
              <div className="row mt-2 mt-3 mb-2">
                <div className="col-12 p-0">
                  <div
                    className="sNo"
                    style={{ float: "right", color: "red", fontWeight: "bold" }}
                  >
                    <button
                      className="btn btn-sisfs btn-transparent-primary"
                      onClick={() => setopenAllFileUploadBlock({ all: true })}
                    >
                      Upload Dummy File's
                    </button>
                  </div>
                </div>
                <div className="fileDiv p-2">
                  {openAllFileUploadBlock?.all && (
                    <>
                      <fieldset className="fileUploadSec mt-2">
                        <div style={{ float: "right", width: "50%" }}>
                          <div className="filebox">
                            <div className="mt-2">
                              <img src={fileupload} alt="Upload" />
                            </div>
                            <input
                              type="file"
                              accept=".pdf"
                              id="all"
                              name="all"
                              onChange={handleFileChange}
                              hidden
                            />
                            <label
                              htmlFor="all"
                              className="btn btn-sisfs btn-transparent-primary mt-1"
                            >
                              Browse
                            </label>
                          </div>
                        </div>
                      </fieldset>
                    </>
                  )}
                </div>
              </div>
            )}

            {getFilesData && getFilesData?.status_ind >= 3 && (
              <div className="row mb-2">
                <div className="col-12 indicationSection">
                  {loggedInRole == "secretariat"
                    ? colorIndication.map((item: any) => (
                        <div className="indicationAlign">
                          <div className="indicationBox ml-3">
                            <div className={item + " indicationDot"}></div>
                          </div>
                          <div>&nbsp;&nbsp; {item.slice(0, -2)}</div>
                        </div>
                      ))
                    : colorIndication
                        .filter(
                          (res: any) =>
                            res == "Submittedbg" ||
                            res == "Acceptedbg" ||
                            res == "Rejectedbg"
                        )
                        .map((item: any) => (
                          <div className="indicationAlign">
                            <div className="indicationBox ml-3">
                              <div className={item + " indicationDot"}></div>
                            </div>
                            <div>&nbsp;&nbsp; {item.slice(0, -2)}</div>
                          </div>
                        ))}
                </div>
              </div>
            )}
            <div className="form-group row section-bg subsqnt p-0">
              <div
                className={
                  getBackgroundColor
                    ? getBackgroundColor[0] + " col-6 bgColumn pr-3"
                    : "col-6 bgColumn pl-3"
                }
              >
                <div
                  className={
                    errorBox &&
                    utilizationFile?.name == null &&
                    getFilesData?.utilization_certificate_file_name == null
                      ? "row alignContents p-3"
                      : "row p-3"
                  }
                  style={{ height: "100%" }}
                >
                  <div className="col-12 alignContents-col">
                    <div className="d-flex">
                      <span className="s-no">1. </span>
                      <span className="section-head">
                        Utilization certificate of previous installment and
                        audited statement of expenditure separately for
                        management fee and seed fund.
                        <span className="required">*</span>
                        <span
                          className="Info-circle ml-2"
                          data-target="#fileInfo"
                          data-toggle="modal"
                          onClick={() => setModalPopup("Utilization")}
                        >
                          ℹ️
                        </span>
                        <span>
                          &nbsp;
                          <span className="fileRemove text-center">
                            <span>
                              {getFilesData?.utilization_certificate_status_ind ==
                              "4" ? (
                                <span className="acceptFile1">Accepted</span>
                              ) : getFilesData?.status_ind > 2 &&
                                getFilesData?.utilization_certificate_status_ind ==
                                  "0" ? (
                                <span className="rejectFile1">Rejected</span>
                              ) : (
                                ""
                              )}
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>
                    <div className="fileDiv p-2">
                      {loggedInRole == "incubator" && (
                        <button
                          className="btn btn-sisfs btn-transparent-primary"
                          onClick={() =>
                            setOpenUploadBlock({ Utilization: true })
                          }
                        >
                          Upload
                        </button>
                      )}
                      {((openUploadBlock &&
                        openUploadBlock["Utilization"] == true) ||
                        getFilesData?.utilization_certificate_file_name ||
                        utilizationFile?.size) && (
                        <>
                          {loggedInRole == "incubator" && (
                            <span
                              className="close"
                              onClick={() =>
                                setOpenUploadBlock({ Utilization: false })
                              }
                            >
                              {!getFilesData?.utilization_certificate_file_name && (
                                <i className="fa fa-close"></i>
                              )}
                            </span>
                          )}
                          <fieldset className="fileUploadSec mt-2">
                            <div className="" style={{ width: "100%" }}>
                              {loggedInRole == "incubator" && (
                                <div className="filebox">
                                  {/* <div className='mt-3'>Drag & Drop file or Browse</div> */}
                                  <div className="mt-2">
                                    <img src={fileupload} alt="" />
                                  </div>
                                  <input
                                    disabled={
                                      getFilesData?.utilization_certificate_status_ind ==
                                      4
                                    }
                                    type="file"
                                    accept=".pdf"
                                    id="Utilization"
                                    name="Utilization"
                                    onChange={handleFileChange}
                                    hidden
                                  />
                                  <label
                                    htmlFor="Utilization"
                                    className="btn btn-sisfs btn-transparent-primary mt-1"
                                  >
                                    Browse
                                  </label>
                                  {loggedInRole == "incubator" &&
                                    !showRejectedText &&
                                    getFilesData?.status_ind > 2 &&
                                    getFilesData?.utilization_certificate_status_ind ==
                                      0 && (
                                      <p
                                        style={{
                                          fontSize: "12px",
                                          color: "red",
                                          fontWeight: "bold",
                                          textAlign: "center",
                                        }}
                                      >
                                        * Your file was rejected, please read
                                        the comments before upload
                                      </p>
                                    )}
                                </div>
                              )}
                              {getFilesData?.utilization_certificate_file_name && (
                                <div
                                  className={
                                    loggedInRole == "secretariat"
                                      ? "previewDiv mb-3 filebox1 filebox1Sec"
                                      : "previewDiv mb-3 filebox1"
                                  }
                                >
                                  <div className="fileSection">
                                    <div>
                                      <span className="ml-3">
                                        <img src={pdfImg} alt="" />
                                      </span>
                                      <span className="ml-3">
                                        {getFilesData?.utilization_certificate_file_name ? (
                                          <a
                                            href={`${
                                              NODE_URL +
                                              getFilesData?.utilization_certificate_file_path
                                            }`}
                                            onClick={() =>
                                              getFileVisibiltyStatus(0)
                                            }
                                            target="_blank"
                                          >
                                            {getFilesData
                                              ?.utilization_certificate_file_name
                                              .length > 15
                                              ? getFilesData?.utilization_certificate_file_name.slice(
                                                  0,
                                                  15
                                                ) + "..."
                                              : getFilesData?.utilization_certificate_file_name}
                                          </a>
                                        ) : (
                                          <a>
                                            {utilizationFile &&
                                              utilizationFile?.name}
                                          </a>
                                        )}
                                      </span>
                                    </div>
                                    <div className="d-flex">
                                      {loggedInRole == "secretariat" &&
                                        params.tranche_status !=
                                          "Incomplete" && (
                                          <>
                                            <span
                                              className="fileRemove text-center"
                                              onClick={() =>
                                                (getFilesData?.utilization_certificate_status_ind ==
                                                  2 ||
                                                  getFilesData?.utilization_certificate_status_ind ==
                                                    3) &&
                                                acceptFilePopup(
                                                  getFilesData?.utilization_certificate_file_name,
                                                  getFilesData?.utilization_certificate_status_ind,
                                                  "accept",
                                                  "0",
                                                  getFilesData?.utilization_certificate_comments
                                                )
                                              }
                                            >
                                              <span className="acceptFile">
                                                {getFilesData?.utilization_certificate_status_ind ==
                                                  2 ||
                                                getFilesData?.utilization_certificate_status_ind ==
                                                  3
                                                  ? "Accept"
                                                  : ""}
                                              </span>
                                            </span>
                                            <span
                                              className="fileRemove text-center ml-2"
                                              onClick={() =>
                                                (getFilesData?.utilization_certificate_status_ind ==
                                                  2 ||
                                                  getFilesData?.utilization_certificate_status_ind >=
                                                    3) &&
                                                removeFilePopup(
                                                  getFilesData?.utilization_certificate_file_name,
                                                  getFilesData?.utilization_certificate_status_ind,
                                                  "reject",
                                                  "0",
                                                  getFilesData?.utilization_certificate_comments
                                                )
                                              }
                                            >
                                              <span className="rejectFile">
                                                {params.tranche_status ==
                                                  "Disbursed" ||
                                                params.tranche_status ==
                                                  "Disbursement in Process" ||
                                                params.tranche_status ==
                                                  "Incomplete"
                                                  ? ""
                                                  : getFilesData?.utilization_certificate_status_ind ==
                                                      2 ||
                                                    getFilesData?.utilization_certificate_status_ind >=
                                                      3
                                                  ? "Reject"
                                                  : ""}
                                              </span>
                                            </span>
                                            {/* <span className='fileRemove text-center ml-2' onClick={() => fileCommentsPopup(getFilesData?.utilization_certificate_file_name, getFilesData?.utilization_certificate_status_ind, "comment", "2", getFilesData?.utilization_certificate_comments)}>
                                                                                        {getFilesData?.utilization_certificate_status_ind == 0 && <span className='commentsFile'>CC</span>}
                                                                                    </span> */}
                                          </>
                                        )}
                                      {(getFilesData?.utilization_certificate_status_ind ==
                                        0 ||
                                        getFilesData?.utilization_certificate_status_ind ==
                                          4) && (
                                        <span
                                          className="fileRemove text-center ml-2"
                                          onClick={() =>
                                            fileCommentsPopup(
                                              getFilesData?.utilization_certificate_file_name,
                                              getFilesData?.utilization_certificate_status_ind,
                                              "comment",
                                              "2",
                                              getFilesData?.utilization_certificate_comments
                                            )
                                          }
                                        >
                                          <span className="commentsFile">
                                            Comments
                                          </span>
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </fieldset>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  getBackgroundColor
                    ? getBackgroundColor[1] + " col-6 bgColumn pl-3"
                    : "col-6 bgColumn pl-3"
                }
              >
                <div
                  className={
                    errorBox &&
                    auditedFile?.name == null &&
                    getFilesData?.audited_statement_file_name == null
                      ? "row alignContents p-3"
                      : "row p-3"
                  }
                  style={{ height: "100%" }}
                >
                  <div className="col-12 alignContents-col">
                    <div className="d-flex">
                      <span className="s-no">2. </span>
                      <span className="section-head">
                        Bank Statement of TRA account of incubator
                        <span className="required">*</span>
                        <span
                          className="Info-circle ml-2"
                          data-target="#fileInfo"
                          data-toggle="modal"
                          onClick={() => setModalPopup("Audited")}
                        >
                          ℹ️
                        </span>
                        <span>
                          &nbsp;
                          <span className="fileRemove text-center">
                            <span>
                              {getFilesData?.audited_statement_status_ind ==
                              "4" ? (
                                <span className="acceptFile1">Accepted</span>
                              ) : getFilesData?.status_ind > 2 &&
                                getFilesData?.audited_statement_status_ind ==
                                  "0" ? (
                                <span className="rejectFile1">Rejected</span>
                              ) : (
                                ""
                              )}
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>
                    <div className="fileDiv p-2">
                      {loggedInRole == "incubator" && (
                        <button
                          className="btn btn-sisfs btn-transparent-primary"
                          onClick={() => setOpenUploadBlock({ Audited: true })}
                        >
                          Upload
                        </button>
                      )}
                      {((openUploadBlock &&
                        openUploadBlock["Audited"] == true) ||
                        getFilesData?.audited_statement_file_name ||
                        auditedFile?.size) && (
                        <>
                          {loggedInRole == "incubator" && (
                            <span
                              className="close"
                              onClick={() =>
                                setOpenUploadBlock({ Audited: false })
                              }
                            >
                              {!getFilesData?.audited_statement_file_name && (
                                <i className="fa fa-close"></i>
                              )}
                            </span>
                          )}
                          <fieldset className="fileUploadSec mt-2">
                            <div className="" style={{ width: "100%" }}>
                              {loggedInRole == "incubator" && (
                                <div className="filebox">
                                  {/* <div className='mt-3'>Drag & Drop file or Browse</div> */}
                                  <div className="mt-2">
                                    <img src={fileupload} alt="" />
                                  </div>
                                  <input
                                    disabled={
                                      getFilesData?.audited_statement_status_ind ==
                                      4
                                    }
                                    type="file"
                                    accept=".pdf"
                                    id="Audited"
                                    name="Audited"
                                    onChange={handleFileChange}
                                    hidden
                                  />
                                  <label
                                    htmlFor="Audited"
                                    className="btn btn-sisfs btn-transparent-primary mt-1"
                                  >
                                    Browse
                                  </label>
                                  {loggedInRole == "incubator" &&
                                    getFilesData?.status_ind > 2 &&
                                    getFilesData?.audited_statement_status_ind ==
                                      0 && (
                                      <p
                                        style={{
                                          fontSize: "12px",
                                          color: "red",
                                          fontWeight: "bold",
                                          textAlign: "center",
                                        }}
                                      >
                                        * Your file was rejected, please read
                                        the comments before upload
                                      </p>
                                    )}
                                </div>
                              )}
                              {getFilesData?.audited_statement_file_name && (
                                <div
                                  className={
                                    loggedInRole == "secretariat"
                                      ? "previewDiv mb-3 filebox1 filebox1Sec"
                                      : "previewDiv mb-3 filebox1"
                                  }
                                >
                                  <div className="fileSection">
                                    <div>
                                      <span className="ml-3">
                                        <img src={pdfImg} alt="" />
                                      </span>
                                      <span className="ml-3">
                                        {getFilesData?.audited_statement_file_name ? (
                                          <a
                                            href={`${
                                              NODE_URL +
                                              getFilesData?.audited_statement_file_path
                                            }`}
                                            onClick={() =>
                                              getFileVisibiltyStatus(1)
                                            }
                                            target="_blank"
                                          >
                                            {getFilesData
                                              ?.audited_statement_file_name
                                              .length > 15
                                              ? getFilesData?.audited_statement_file_name.slice(
                                                  0,
                                                  15
                                                ) + "..."
                                              : getFilesData?.audited_statement_file_name}
                                          </a>
                                        ) : (
                                          <a>
                                            {auditedFile && auditedFile?.name}
                                          </a>
                                        )}
                                      </span>
                                    </div>
                                    <div className="d-flex">
                                      {loggedInRole == "secretariat" &&
                                        params.tranche_status !=
                                          "Incomplete" && (
                                          <>
                                            <span
                                              className="fileRemove text-center"
                                              onClick={() =>
                                                (getFilesData?.audited_statement_status_ind ==
                                                  2 ||
                                                  getFilesData?.audited_statement_status_ind ==
                                                    3) &&
                                                acceptFilePopup(
                                                  getFilesData?.audited_statement_file_name,
                                                  getFilesData?.audited_statement_status_ind,
                                                  "accept",
                                                  "1",
                                                  getFilesData?.audited_statement_comments
                                                )
                                              }
                                            >
                                              <span className="acceptFile">
                                                {getFilesData?.audited_statement_status_ind ==
                                                  2 ||
                                                getFilesData?.audited_statement_status_ind ==
                                                  3
                                                  ? "Accept"
                                                  : ""}
                                              </span>
                                            </span>
                                            <span
                                              className="fileRemove text-center ml-2"
                                              onClick={() =>
                                                (getFilesData?.audited_statement_status_ind ==
                                                  2 ||
                                                  getFilesData?.audited_statement_status_ind >=
                                                    3) &&
                                                removeFilePopup(
                                                  getFilesData?.audited_statement_file_name,
                                                  getFilesData?.audited_statement_status_ind,
                                                  "reject",
                                                  "1",
                                                  getFilesData?.audited_statement_comments
                                                )
                                              }
                                            >
                                              <span className="rejectFile">
                                                {params.tranche_status ==
                                                  "Disbursed" ||
                                                params.tranche_status ==
                                                  "Disbursement in Process" ||
                                                params.tranche_status ==
                                                  "Incomplete"
                                                  ? ""
                                                  : getFilesData?.audited_statement_status_ind ==
                                                      2 ||
                                                    getFilesData?.audited_statement_status_ind >=
                                                      3
                                                  ? "Reject"
                                                  : ""}
                                              </span>
                                            </span>
                                          </>
                                        )}
                                      {(getFilesData?.audited_statement_status_ind ==
                                        0 ||
                                        getFilesData?.audited_statement_status_ind ==
                                          4) && (
                                        <span
                                          className="fileRemove text-center ml-2"
                                          onClick={() =>
                                            fileCommentsPopup(
                                              getFilesData?.audited_statement_file_name,
                                              getFilesData?.audited_statement_status_ind,
                                              "comment",
                                              "2",
                                              getFilesData?.audited_statement_comments
                                            )
                                          }
                                        >
                                          <span className="commentsFile">
                                            Comments
                                          </span>
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </fieldset>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group row section-bg subsqnt p-0">
              {/* style={{ backdropFilter: getFilesData?.interest_earned_status_ind == 2 ? "contrast(0.9)" : "0" }} */}
              <div
                className={
                  getBackgroundColor
                    ? getBackgroundColor[2] + " col-6 bgColumn pr-3"
                    : "col-6 bgColumn pl-3"
                }
              >
                <div
                  className={
                    errorBox &&
                    interestFile?.name == null &&
                    getFilesData?.interest_earned_file_name == null
                      ? "row alignContents p-3"
                      : "row p-3"
                  }
                  style={{ height: "100%" }}
                >
                  <div className="col-12 alignContents-col">
                    <div className="d-flex">
                      <span className="s-no">3. </span>
                      <span className="section-head">
                        Details of interest earned on unutilized funds, if any
                        <span className="required">*</span>
                        <span
                          className="Info-circle ml-2"
                          data-target="#fileInfo"
                          data-toggle="modal"
                          onClick={() => setModalPopup("Interest")}
                        >
                          ℹ️
                        </span>
                        <span>
                          &nbsp;
                          <span className="fileRemove text-center">
                            <span>
                              {getFilesData?.interest_earned_status_ind ==
                              "4" ? (
                                <span className="acceptFile1">Accepted</span>
                              ) : getFilesData?.status_ind > 2 &&
                                getFilesData?.interest_earned_status_ind ==
                                  "0" ? (
                                <span className="rejectFile1">Rejected</span>
                              ) : (
                                ""
                              )}
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>
                    <div className="fileDiv p-2">
                      {loggedInRole == "incubator" && (
                        <button
                          className="btn btn-sisfs btn-transparent-primary"
                          onClick={() => setOpenUploadBlock({ Interest: true })}
                        >
                          Upload
                        </button>
                      )}
                      {((openUploadBlock &&
                        openUploadBlock["Interest"] == true) ||
                        getFilesData?.interest_earned_file_name ||
                        interestFile?.size) && (
                        <>
                          {loggedInRole == "incubator" && (
                            <span
                              className="close"
                              onClick={() =>
                                setOpenUploadBlock({ Interest: false })
                              }
                            >
                              {!getFilesData?.interest_earned_file_name && (
                                <i className="fa fa-close"></i>
                              )}
                            </span>
                          )}
                          <fieldset className="fileUploadSec mt-2">
                            <div className="" style={{ width: "100%" }}>
                              {loggedInRole == "incubator" && (
                                <div className="filebox">
                                  {/* <div className='mt-3'>Drag & Drop file or Browse</div> */}
                                  <div className="mt-2">
                                    <img src={fileupload} alt="" />
                                  </div>
                                  <input
                                    disabled={
                                      getFilesData?.interest_earned_status_ind ==
                                      4
                                    }
                                    type="file"
                                    accept=".pdf"
                                    id="Interest"
                                    name="Interest"
                                    onChange={handleFileChange}
                                    hidden
                                  />
                                  <label
                                    htmlFor="Interest"
                                    className="btn btn-sisfs btn-transparent-primary mt-1"
                                  >
                                    Browse
                                  </label>
                                  {loggedInRole == "incubator" &&
                                    getFilesData?.status_ind > 2 &&
                                    getFilesData?.interest_earned_status_ind ==
                                      0 && (
                                      <p
                                        style={{
                                          fontSize: "12px",
                                          color: "red",
                                          fontWeight: "bold",
                                          textAlign: "center",
                                        }}
                                      >
                                        * Your file was rejected, please read
                                        the comments before upload
                                      </p>
                                    )}
                                </div>
                              )}
                              {getFilesData?.interest_earned_file_name && (
                                <div
                                  className={
                                    loggedInRole == "secretariat"
                                      ? "previewDiv mb-3 filebox1 filebox1Sec"
                                      : "previewDiv mb-3 filebox1"
                                  }
                                >
                                  <div className="fileSection">
                                    <div>
                                      <span className="ml-3">
                                        <img src={pdfImg} alt="" />
                                      </span>
                                      <span className="ml-3">
                                        {getFilesData?.interest_earned_file_name ? (
                                          <a
                                            href={`${
                                              NODE_URL +
                                              getFilesData?.interest_earned_file_path
                                            }`}
                                            onClick={() =>
                                              getFileVisibiltyStatus(2)
                                            }
                                            target="_blank"
                                          >
                                            {getFilesData
                                              ?.interest_earned_file_name
                                              .length > 15
                                              ? getFilesData?.interest_earned_file_name.slice(
                                                  0,
                                                  15
                                                ) + "..."
                                              : getFilesData?.interest_earned_file_name}
                                          </a>
                                        ) : (
                                          <a>
                                            {interestFile && interestFile?.name}
                                          </a>
                                        )}
                                      </span>
                                    </div>
                                    <div className="d-flex">
                                      {loggedInRole == "secretariat" &&
                                        params.tranche_status !=
                                          "Incomplete" && (
                                          <>
                                            <span
                                              className="fileRemove text-center"
                                              onClick={() =>
                                                (getFilesData?.interest_earned_status_ind ==
                                                  2 ||
                                                  getFilesData?.interest_earned_status_ind ==
                                                    3) &&
                                                acceptFilePopup(
                                                  getFilesData?.interest_earned_file_name,
                                                  getFilesData?.interest_earned_status_ind,
                                                  "accept",
                                                  "2",
                                                  getFilesData?.interest_earned_comments
                                                )
                                              }
                                            >
                                              <span className="acceptFile">
                                                {getFilesData?.interest_earned_status_ind ==
                                                  2 ||
                                                getFilesData?.interest_earned_status_ind ==
                                                  3
                                                  ? "Accept"
                                                  : ""}
                                              </span>
                                            </span>
                                            <span
                                              className="fileRemove text-center ml-2"
                                              onClick={() =>
                                                (getFilesData?.interest_earned_status_ind ==
                                                  2 ||
                                                  getFilesData?.interest_earned_status_ind >=
                                                    3) &&
                                                removeFilePopup(
                                                  getFilesData?.interest_earned_file_name,
                                                  getFilesData?.interest_earned_status_ind,
                                                  "reject",
                                                  "2",
                                                  getFilesData?.interest_earned_comments
                                                )
                                              }
                                            >
                                              <span className="rejectFile">
                                                {params.tranche_status ==
                                                  "Disbursed" ||
                                                params.tranche_status ==
                                                  "Disbursement in Process" ||
                                                params.tranche_status ==
                                                  "Incomplete"
                                                  ? ""
                                                  : getFilesData?.interest_earned_status_ind ==
                                                      2 ||
                                                    getFilesData?.interest_earned_status_ind >=
                                                      3
                                                  ? "Reject"
                                                  : ""}
                                              </span>
                                            </span>
                                          </>
                                        )}
                                      {(getFilesData?.interest_earned_status_ind ==
                                        0 ||
                                        getFilesData?.interest_earned_status_ind ==
                                          4) && (
                                        <span
                                          className="fileRemove text-center ml-2"
                                          onClick={() =>
                                            fileCommentsPopup(
                                              getFilesData?.interest_earned_file_name,
                                              getFilesData?.interest_earned_status_ind,
                                              "comment",
                                              "2",
                                              getFilesData?.interest_earned_comments
                                            )
                                          }
                                        >
                                          <span className="commentsFile">
                                            Comments
                                          </span>
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </fieldset>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  getBackgroundColor
                    ? getBackgroundColor[3] + " col-6 bgColumn pl-3"
                    : "col-6 bgColumn pl-3"
                }
              >
                <div
                  className={
                    errorBox &&
                    ismcFile?.name == null &&
                    getFilesData?.ismsc_meetings_file_name == null
                      ? "row alignContents p-3"
                      : "row p-3"
                  }
                  style={{ height: "100%" }}
                >
                  <div className="col-12 alignContents-col">
                    <div className="">
                      <span className="s-no">4. </span>
                      <span className="section-head">
                        Minutes of ISMC meetings
                        <span className="required">*</span>
                        <span
                          className="Info-circle ml-2"
                          data-target="#fileInfo"
                          data-toggle="modal"
                          onClick={() => setModalPopup("Ismc")}
                        >
                          ℹ️
                        </span>
                        <span>
                          &nbsp;
                          <span className="fileRemove text-center">
                            <span>
                              {getFilesData?.ismsc_meetings_status_ind ==
                              "4" ? (
                                <span className="acceptFile1">Accepted</span>
                              ) : getFilesData?.status_ind > 2 &&
                                getFilesData?.ismsc_meetings_status_ind ==
                                  "0" ? (
                                <span className="rejectFile1">Rejected</span>
                              ) : (
                                ""
                              )}
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>
                    <div className="fileDiv p-2">
                      {loggedInRole == "incubator" && (
                        <button
                          className="btn btn-sisfs btn-transparent-primary"
                          onClick={() => setOpenUploadBlock({ ISMC: true })}
                        >
                          Upload
                        </button>
                      )}
                      {((openUploadBlock && openUploadBlock["ISMC"] == true) ||
                        getFilesData?.ismsc_meetings_file_name ||
                        ismcFile?.name) && (
                        <>
                          {loggedInRole == "incubator" && (
                            <span
                              className="close"
                              onClick={() =>
                                setOpenUploadBlock({ ISMC: false })
                              }
                            >
                              {!getFilesData?.ismsc_meetings_file_name && (
                                <i className="fa fa-close"></i>
                              )}
                            </span>
                          )}
                          <fieldset className="fileUploadSec mt-2">
                            <div className="" style={{ width: "100%" }}>
                              {loggedInRole == "incubator" && (
                                <div className="filebox">
                                  {/* <div className='mt-3'>Drag & Drop file or Browse</div> */}
                                  <div className="mt-2">
                                    <img src={fileupload} alt="" />
                                  </div>
                                  <input
                                    disabled={
                                      getFilesData?.ismsc_meetings_status_ind ==
                                      4
                                    }
                                    type="file"
                                    accept=".pdf"
                                    id="Ismc"
                                    name="Ismc"
                                    onChange={handleFileChange}
                                    hidden
                                  />
                                  <label
                                    htmlFor="Ismc"
                                    className="btn btn-sisfs btn-transparent-primary mt-1"
                                  >
                                    Browse
                                  </label>
                                  {loggedInRole == "incubator" &&
                                    getFilesData?.status_ind > 2 &&
                                    getFilesData?.ismsc_meetings_status_ind ==
                                      0 && (
                                      <p
                                        style={{
                                          fontSize: "12px",
                                          color: "red",
                                          fontWeight: "bold",
                                          textAlign: "center",
                                        }}
                                      >
                                        * Your file was rejected, please read
                                        the comments before upload
                                      </p>
                                    )}
                                </div>
                              )}
                              {getFilesData?.ismsc_meetings_file_name && (
                                <div
                                  className={
                                    loggedInRole == "secretariat"
                                      ? "previewDiv mb-3 filebox1 filebox1Sec"
                                      : "previewDiv mb-3 filebox1"
                                  }
                                >
                                  <div className="fileSection">
                                    <div>
                                      <span className="ml-3">
                                        <img src={pdfImg} alt="" />
                                      </span>
                                      <span className="ml-3">
                                        {getFilesData?.ismsc_meetings_file_name ? (
                                          <a
                                            href={`${
                                              NODE_URL +
                                              getFilesData?.ismsc_meetings_file_path
                                            }`}
                                            onClick={() =>
                                              getFileVisibiltyStatus(3)
                                            }
                                            target="_blank"
                                          >
                                            {getFilesData
                                              ?.ismsc_meetings_file_name
                                              .length > 15
                                              ? getFilesData?.ismsc_meetings_file_name.slice(
                                                  0,
                                                  15
                                                ) + "..."
                                              : getFilesData?.ismsc_meetings_file_name}
                                          </a>
                                        ) : (
                                          <a>{ismcFile && ismcFile?.name}</a>
                                        )}
                                      </span>
                                    </div>
                                    <div className="d-flex">
                                      {loggedInRole == "secretariat" &&
                                        params.tranche_status !=
                                          "Incomplete" && (
                                          <>
                                            <span
                                              className="fileRemove text-center"
                                              onClick={() =>
                                                (getFilesData?.ismsc_meetings_status_ind ==
                                                  2 ||
                                                  getFilesData?.ismsc_meetings_status_ind ==
                                                    3) &&
                                                acceptFilePopup(
                                                  getFilesData?.ismsc_meetings_file_name,
                                                  getFilesData?.ismsc_meetings_status_ind,
                                                  "accept",
                                                  "3",
                                                  getFilesData?.ismsc_meetings_comments
                                                )
                                              }
                                            >
                                              <span className="acceptFile">
                                                {getFilesData?.ismsc_meetings_status_ind ==
                                                  2 ||
                                                getFilesData?.ismsc_meetings_status_ind ==
                                                  3
                                                  ? "Accept"
                                                  : ""}
                                              </span>
                                            </span>
                                            <span
                                              className="fileRemove text-center ml-2"
                                              onClick={() =>
                                                (getFilesData?.ismsc_meetings_status_ind ==
                                                  2 ||
                                                  getFilesData?.ismsc_meetings_status_ind >=
                                                    3) &&
                                                removeFilePopup(
                                                  getFilesData?.ismsc_meetings_file_name,
                                                  getFilesData?.ismsc_meetings_status_ind,
                                                  "reject",
                                                  "3",
                                                  getFilesData?.ismsc_meetings_comments
                                                )
                                              }
                                            >
                                              <span className="rejectFile">
                                                {params.tranche_status ==
                                                  "Disbursed" ||
                                                params.tranche_status ==
                                                  "Disbursement in Process" ||
                                                params.tranche_status ==
                                                  "Incomplete"
                                                  ? ""
                                                  : getFilesData?.ismsc_meetings_status_ind ==
                                                      2 ||
                                                    getFilesData?.ismsc_meetings_status_ind >=
                                                      3
                                                  ? "Reject"
                                                  : ""}
                                              </span>
                                            </span>
                                          </>
                                        )}
                                      {(getFilesData?.ismsc_meetings_status_ind ==
                                        0 ||
                                        getFilesData?.ismsc_meetings_status_ind ==
                                          4) && (
                                        <span
                                          className="fileRemove text-center ml-2"
                                          onClick={() =>
                                            fileCommentsPopup(
                                              getFilesData?.ismsc_meetings_file_name,
                                              getFilesData?.ismsc_meetings_status_ind,
                                              "comment",
                                              "2",
                                              getFilesData?.ismsc_meetings_comments
                                            )
                                          }
                                        >
                                          <span className="commentsFile">
                                            Comments
                                          </span>
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </fieldset>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group row section-bg subsqnt p-0">
              <div
                className={
                  getBackgroundColor
                    ? getBackgroundColor[4] + " col-6 bgColumn pr-3"
                    : "col-6 bgColumn pl-3"
                }
              >
                <div
                  className={
                    errorBox &&
                    achievementFile?.name == null &&
                    getFilesData?.proof_of_achievement_file_name == null
                      ? "row alignContents p-3"
                      : "row p-3"
                  }
                  style={{ height: "100%" }}
                >
                  <div className="col-12 alignContents-col">
                    <div className="d-flex">
                      <span className="s-no">5. </span>
                      <span className="section-head">
                        Proof of achievement of milestones as decided by EAC on
                        monthly/quarterly basis (para-5.4)
                        <span className="required">*</span>
                        <span
                          className="Info-circle ml-2"
                          data-target="#fileInfo"
                          data-toggle="modal"
                          onClick={() => setModalPopup("Achievement")}
                        >
                          ℹ️
                        </span>
                        <span>
                          &nbsp;
                          <span className="fileRemove text-center">
                            <span>
                              {getFilesData?.proof_of_achievement_status_ind ==
                              "4" ? (
                                <span className="acceptFile1">Accepted</span>
                              ) : getFilesData?.status_ind > 2 &&
                                getFilesData?.proof_of_achievement_status_ind ==
                                  "0" ? (
                                <span className="rejectFile1">Rejected</span>
                              ) : (
                                ""
                              )}
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>
                    <div className="fileDiv p-2">
                      {loggedInRole == "incubator" && (
                        <button
                          className="btn btn-sisfs btn-transparent-primary"
                          onClick={() =>
                            setOpenUploadBlock({ achievement: true })
                          }
                        >
                          Upload
                        </button>
                      )}
                      {((openUploadBlock &&
                        openUploadBlock["achievement"] == true) ||
                        getFilesData?.proof_of_achievement_file_name ||
                        achievementFile) && (
                        <>
                          {loggedInRole == "incubator" && (
                            <span
                              className="close"
                              onClick={() =>
                                setOpenUploadBlock({ achievement: false })
                              }
                            >
                              {!getFilesData?.proof_of_achievement_file_name && (
                                <i className="fa fa-close"></i>
                              )}
                            </span>
                          )}
                          <fieldset className="fileUploadSec mt-2">
                            <div className="" style={{ width: "100%" }}>
                              {loggedInRole == "incubator" && (
                                <div className="filebox">
                                  {/* <div className='mt-3'>Drag & Drop file or Browse</div> */}
                                  <div className="mt-2">
                                    <img src={fileupload} alt="" />
                                  </div>
                                  <input
                                    disabled={
                                      getFilesData?.proof_of_achievement_status_ind ==
                                      4
                                    }
                                    type="file"
                                    accept=".pdf"
                                    id="Achievement"
                                    name="Achievement"
                                    onChange={handleFileChange}
                                    hidden
                                  />
                                  <label
                                    htmlFor="Achievement"
                                    className="btn btn-sisfs btn-transparent-primary mt-1"
                                  >
                                    Browse
                                  </label>
                                  {loggedInRole == "incubator" &&
                                    getFilesData?.status_ind > 2 &&
                                    getFilesData?.proof_of_achievement_status_ind ==
                                      0 && (
                                      <p
                                        style={{
                                          fontSize: "12px",
                                          color: "red",
                                          fontWeight: "bold",
                                          textAlign: "center",
                                        }}
                                      >
                                        * Your file was rejected, please read
                                        the comments before upload
                                      </p>
                                    )}
                                </div>
                              )}
                              {getFilesData?.proof_of_achievement_file_name && (
                                <div
                                  className={
                                    loggedInRole == "secretariat"
                                      ? "previewDiv mb-3 filebox1 filebox1Sec"
                                      : "previewDiv mb-3 filebox1"
                                  }
                                >
                                  <div className="fileSection">
                                    <div>
                                      <span className="ml-3">
                                        <img src={pdfImg} alt="" />
                                      </span>
                                      <span className="ml-3">
                                        {getFilesData?.proof_of_achievement_file_name ? (
                                          <a
                                            href={`${
                                              NODE_URL +
                                              getFilesData?.proof_of_achievement_file_path
                                            }`}
                                            onClick={() =>
                                              getFileVisibiltyStatus(4)
                                            }
                                            target="_blank"
                                          >
                                            {getFilesData
                                              ?.proof_of_achievement_file_name
                                              .length > 15
                                              ? getFilesData?.proof_of_achievement_file_name.slice(
                                                  0,
                                                  15
                                                ) + "..."
                                              : getFilesData?.proof_of_achievement_file_name}
                                          </a>
                                        ) : (
                                          <a>
                                            {achievementFile &&
                                              achievementFile?.name}
                                          </a>
                                        )}
                                      </span>
                                    </div>
                                    <div className="d-flex">
                                      {loggedInRole == "secretariat" &&
                                        params.tranche_status !=
                                          "Incomplete" && (
                                          <>
                                            <span
                                              className="fileRemove text-center"
                                              onClick={() =>
                                                (getFilesData?.proof_of_achievement_status_ind ==
                                                  2 ||
                                                  getFilesData?.proof_of_achievement_status_ind ==
                                                    3) &&
                                                acceptFilePopup(
                                                  getFilesData?.proof_of_achievement_file_name,
                                                  getFilesData?.proof_of_achievement_status_ind,
                                                  "accept",
                                                  "4",
                                                  getFilesData?.proof_of_achievement_comments
                                                )
                                              }
                                            >
                                              <span className="acceptFile">
                                                {getFilesData?.proof_of_achievement_status_ind ==
                                                  2 ||
                                                getFilesData?.proof_of_achievement_status_ind ==
                                                  3
                                                  ? "Accept"
                                                  : ""}
                                              </span>
                                            </span>
                                            <span
                                              className="fileRemove text-center ml-2"
                                              onClick={() =>
                                                (getFilesData?.proof_of_achievement_status_ind ==
                                                  2 ||
                                                  getFilesData?.proof_of_achievement_status_ind >=
                                                    3) &&
                                                removeFilePopup(
                                                  getFilesData?.proof_of_achievement_file_name,
                                                  getFilesData?.proof_of_achievement_status_ind,
                                                  "reject",
                                                  "4",
                                                  getFilesData?.proof_of_achievement_comments
                                                )
                                              }
                                            >
                                              <span className="rejectFile">
                                                {params.tranche_status ==
                                                  "Disbursed" ||
                                                params.tranche_status ==
                                                  "Disbursement in Process" ||
                                                params.tranche_status ==
                                                  "Incomplete"
                                                  ? ""
                                                  : getFilesData?.proof_of_achievement_status_ind ==
                                                      2 ||
                                                    getFilesData?.proof_of_achievement_status_ind >=
                                                      3
                                                  ? "Reject"
                                                  : ""}
                                              </span>
                                            </span>
                                          </>
                                        )}
                                      {(getFilesData?.proof_of_achievement_status_ind ==
                                        0 ||
                                        getFilesData?.proof_of_achievement_status_ind ==
                                          4) && (
                                        <span
                                          className="fileRemove text-center ml-2"
                                          onClick={() =>
                                            fileCommentsPopup(
                                              getFilesData?.proof_of_achievement_file_name,
                                              getFilesData?.proof_of_achievement_status_ind,
                                              "comment",
                                              "2",
                                              getFilesData?.proof_of_achievement_comments
                                            )
                                          }
                                        >
                                          <span className="commentsFile">
                                            Comments
                                          </span>
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </fieldset>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  getBackgroundColor
                    ? getBackgroundColor[5] + " col-6 bgColumn pl-3"
                    : "col-6 bgColumn pl-3"
                }
              >
                <div
                  className={
                    errorBox &&
                    debentureFile?.name == null &&
                    getFilesData?.instrument_type_details_file_name == null
                      ? "row alignContents p-3"
                      : "row p-3"
                  }
                  style={{ height: "100%" }}
                >
                  <div className="col-12 alignContents-col">
                    <div className="d-flex">
                      <span className="s-no">6. </span>
                      <span className="section-head">
                        Details of convertible debentures, or debt, or
                        debt-linked instruments along with interest and return
                        on investment for each Startup
                        <span className="required">*</span>
                        <span
                          className="Info-circle ml-2"
                          data-target="#fileInfo"
                          data-toggle="modal"
                          onClick={() => setModalPopup("Debenture")}
                        >
                          ℹ️
                        </span>
                        <span>
                          &nbsp;
                          <span className="fileRemove text-center">
                            <span>
                              {getFilesData?.instrument_type_details_status_ind ==
                              "4" ? (
                                <span className="acceptFile1">Accepted</span>
                              ) : getFilesData?.status_ind > 2 &&
                                getFilesData?.instrument_type_details_status_ind ==
                                  "0" ? (
                                <span className="rejectFile1">Rejected</span>
                              ) : (
                                ""
                              )}
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>
                    <div className="fileDiv p-2">
                      {loggedInRole == "incubator" && (
                        <button
                          className="btn btn-sisfs btn-transparent-primary"
                          onClick={() =>
                            setOpenUploadBlock({ debenture: true })
                          }
                        >
                          Upload
                        </button>
                      )}
                      {((openUploadBlock &&
                        openUploadBlock["debenture"] == true) ||
                        getFilesData?.instrument_type_details_file_name ||
                        debentureFile) && (
                        <>
                          {loggedInRole == "incubator" && (
                            <span
                              className="close"
                              onClick={() =>
                                setOpenUploadBlock({ debenture: false })
                              }
                            >
                              {!getFilesData?.instrument_type_details_file_name && (
                                <i className="fa fa-close"></i>
                              )}
                            </span>
                          )}
                          <fieldset className="fileUploadSec mt-2">
                            <div className="" style={{ width: "100%" }}>
                              {loggedInRole == "incubator" && (
                                <div className="filebox">
                                  {/* <div className='mt-3'>Drag & Drop file or Browse</div> */}
                                  <div className="mt-2">
                                    <img src={fileupload} alt="" />
                                  </div>
                                  <input
                                    disabled={
                                      getFilesData?.instrument_type_details_status_ind ==
                                      4
                                    }
                                    type="file"
                                    accept=".pdf"
                                    id="Debenture"
                                    name="Debenture"
                                    onChange={handleFileChange}
                                    hidden
                                  />
                                  <label
                                    htmlFor="Debenture"
                                    className="btn btn-sisfs btn-transparent-primary mt-1"
                                  >
                                    Browse
                                  </label>
                                  {loggedInRole == "incubator" &&
                                    getFilesData?.status_ind > 2 &&
                                    getFilesData?.instrument_type_details_status_ind ==
                                      0 && (
                                      <p
                                        style={{
                                          fontSize: "12px",
                                          color: "red",
                                          fontWeight: "bold",
                                          textAlign: "center",
                                        }}
                                      >
                                        * Your file was rejected, please read
                                        the comments before upload
                                      </p>
                                    )}
                                </div>
                              )}
                              {getFilesData?.instrument_type_details_file_name && (
                                <div
                                  className={
                                    loggedInRole == "secretariat"
                                      ? "previewDiv mb-3 filebox1 filebox1Sec"
                                      : "previewDiv mb-3 filebox1"
                                  }
                                >
                                  <div className="fileSection">
                                    <div>
                                      <span className="ml-3">
                                        <img src={pdfImg} alt="" />
                                      </span>
                                      <span className="ml-3">
                                        {getFilesData?.instrument_type_details_file_name ? (
                                          <a
                                            href={`${
                                              NODE_URL +
                                              getFilesData?.instrument_type_details_file_path
                                            }`}
                                            onClick={() =>
                                              getFileVisibiltyStatus(5)
                                            }
                                            target="_blank"
                                          >
                                            {getFilesData
                                              ?.instrument_type_details_file_name
                                              .length > 15
                                              ? getFilesData?.instrument_type_details_file_name.slice(
                                                  0,
                                                  15
                                                ) + "..."
                                              : getFilesData?.instrument_type_details_file_name}
                                          </a>
                                        ) : (
                                          <a>
                                            {debentureFile &&
                                              debentureFile?.name}
                                          </a>
                                        )}
                                      </span>
                                    </div>
                                    <div className="d-flex">
                                      {loggedInRole == "secretariat" &&
                                        params.tranche_status !=
                                          "Incomplete" && (
                                          <>
                                            <span
                                              className="fileRemove text-center"
                                              onClick={() =>
                                                (getFilesData?.instrument_type_details_status_ind ==
                                                  2 ||
                                                  getFilesData?.instrument_type_details_status_ind ==
                                                    3) &&
                                                acceptFilePopup(
                                                  getFilesData?.instrument_type_details_file_name,
                                                  getFilesData?.instrument_type_details_status_ind,
                                                  "accept",
                                                  "5",
                                                  getFilesData?.instrument_type_details_comments
                                                )
                                              }
                                            >
                                              <span className="acceptFile">
                                                {getFilesData?.instrument_type_details_status_ind ==
                                                  2 ||
                                                getFilesData?.instrument_type_details_status_ind ==
                                                  3
                                                  ? "Accept"
                                                  : ""}
                                              </span>
                                            </span>
                                            <span
                                              className="fileRemove text-center ml-2"
                                              onClick={() =>
                                                (getFilesData?.instrument_type_details_status_ind ==
                                                  2 ||
                                                  getFilesData?.instrument_type_details_status_ind >=
                                                    3) &&
                                                removeFilePopup(
                                                  getFilesData?.instrument_type_details_file_name,
                                                  getFilesData?.instrument_type_details_status_ind,
                                                  "reject",
                                                  "5",
                                                  getFilesData?.instrument_type_details_comments
                                                )
                                              }
                                            >
                                              <span className="rejectFile">
                                                {params.tranche_status ==
                                                  "Disbursed" ||
                                                params.tranche_status ==
                                                  "Disbursement in Process" ||
                                                params.tranche_status ==
                                                  "Incomplete"
                                                  ? ""
                                                  : getFilesData?.instrument_type_details_status_ind ==
                                                      2 ||
                                                    getFilesData?.instrument_type_details_status_ind >=
                                                      3
                                                  ? "Reject"
                                                  : ""}
                                              </span>
                                            </span>
                                          </>
                                        )}
                                      {(getFilesData?.instrument_type_details_status_ind ==
                                        0 ||
                                        getFilesData?.instrument_type_details_status_ind ==
                                          4) && (
                                        <span
                                          className="fileRemove text-center ml-2"
                                          onClick={() =>
                                            fileCommentsPopup(
                                              getFilesData?.instrument_type_details_file_name,
                                              getFilesData?.instrument_type_details_status_ind,
                                              "comment",
                                              "2",
                                              getFilesData?.instrument_type_details_comments
                                            )
                                          }
                                        >
                                          <span className="commentsFile">
                                            Comments
                                          </span>
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </fieldset>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group row section-bg subsqnt p-0">
              <div
                className={
                  getBackgroundColor
                    ? getBackgroundColor[6] + " col-6 bgColumn pr-3"
                    : "col-6 bgColumn pl-3"
                }
              >
                <div
                  className={
                    errorBox &&
                    disbursedFile?.name == null &&
                    getFilesData?.grant_disbursed_dtls_file_name == null
                      ? "row alignContents p-3"
                      : "row p-3"
                  }
                  style={{ height: "100%" }}
                >
                  <div className="col-12 alignContents-col">
                    <div className="d-flex">
                      <span className="s-no">7. </span>
                      <span className="section-head">
                        Details of grant disbursed and return on investment for
                        each startup <span className="required">*</span>
                        <span
                          className="Info-circle ml-2"
                          data-target="#fileInfo"
                          data-toggle="modal"
                          onClick={() => setModalPopup("Disbursed")}
                        >
                          ℹ️
                        </span>
                        <span>
                          &nbsp;
                          <span className="fileRemove text-center">
                            <span>
                              {getFilesData?.grant_disbursed_dtls_status_ind ==
                              "4" ? (
                                <span className="acceptFile1">Accepted</span>
                              ) : getFilesData?.status_ind > 2 &&
                                getFilesData?.grant_disbursed_dtls_status_ind ==
                                  "0" ? (
                                <span className="rejectFile1">Rejected</span>
                              ) : (
                                ""
                              )}
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>
                    <br />
                    <div className="fileDiv p-2">
                      {loggedInRole == "incubator" && (
                        <button
                          className="btn btn-sisfs btn-transparent-primary"
                          onClick={() =>
                            setOpenUploadBlock({ disbursed: true })
                          }
                        >
                          Upload
                        </button>
                      )}
                      {((openUploadBlock &&
                        openUploadBlock["disbursed"] == true) ||
                        getFilesData?.grant_disbursed_dtls_file_name ||
                        disbursedFile) && (
                        <>
                          {loggedInRole == "incubator" && (
                            <span
                              className="close"
                              onClick={() =>
                                setOpenUploadBlock({ disbursed: false })
                              }
                            >
                              {!getFilesData?.grant_disbursed_dtls_file_name && (
                                <i className="fa fa-close"></i>
                              )}
                            </span>
                          )}
                          <fieldset className="fileUploadSec mt-2">
                            <div className="" style={{ width: "100%" }}>
                              {loggedInRole == "incubator" && (
                                <div className="filebox">
                                  {/* <div className='mt-3'>Drag & Drop file or Browse</div> */}
                                  <div className="mt-2">
                                    <img src={fileupload} alt="" />
                                  </div>
                                  <input
                                    disabled={
                                      getFilesData?.grant_disbursed_dtls_status_ind ==
                                      4
                                    }
                                    type="file"
                                    accept=".pdf"
                                    id="Disbursed"
                                    name="Disbursed"
                                    onChange={handleFileChange}
                                    hidden
                                  />
                                  <label
                                    htmlFor="Disbursed"
                                    className="btn btn-sisfs btn-transparent-primary mt-1"
                                  >
                                    Browse
                                  </label>
                                  {loggedInRole == "incubator" &&
                                    getFilesData?.status_ind > 2 &&
                                    getFilesData?.grant_disbursed_dtls_status_ind ==
                                      0 && (
                                      <p
                                        style={{
                                          fontSize: "12px",
                                          color: "red",
                                          fontWeight: "bold",
                                          textAlign: "center",
                                        }}
                                      >
                                        * Your file was rejected, please read
                                        the comments before upload
                                      </p>
                                    )}
                                </div>
                              )}
                              {getFilesData?.grant_disbursed_dtls_file_name && (
                                <div
                                  className={
                                    loggedInRole == "secretariat"
                                      ? "previewDiv mb-3 filebox1 filebox1Sec"
                                      : "previewDiv mb-3 filebox1"
                                  }
                                >
                                  <div className="fileSection">
                                    <div>
                                      <span className="ml-3">
                                        <img src={pdfImg} alt="" />
                                      </span>
                                      <span className="ml-3">
                                        {getFilesData?.grant_disbursed_dtls_file_name ? (
                                          <a
                                            href={`${
                                              NODE_URL +
                                              getFilesData?.grant_disbursed_dtls_file_path
                                            }`}
                                            onClick={() =>
                                              getFileVisibiltyStatus(6)
                                            }
                                            target="_blank"
                                          >
                                            {getFilesData
                                              ?.grant_disbursed_dtls_file_name
                                              .length > 15
                                              ? getFilesData?.grant_disbursed_dtls_file_name.slice(
                                                  0,
                                                  15
                                                ) + "..."
                                              : getFilesData?.grant_disbursed_dtls_file_name}
                                          </a>
                                        ) : (
                                          <a>
                                            {disbursedFile &&
                                              disbursedFile?.name}
                                          </a>
                                        )}
                                      </span>
                                    </div>
                                    <div className="d-flex">
                                      {loggedInRole == "secretariat" &&
                                        params.tranche_status !=
                                          "Incomplete" && (
                                          <>
                                            <span
                                              className="fileRemove text-center"
                                              onClick={() =>
                                                (getFilesData?.grant_disbursed_dtls_status_ind ==
                                                  2 ||
                                                  getFilesData?.grant_disbursed_dtls_status_ind ==
                                                    3) &&
                                                acceptFilePopup(
                                                  getFilesData?.grant_disbursed_dtls_file_name,
                                                  getFilesData?.grant_disbursed_dtls_status_ind,
                                                  "accept",
                                                  "6",
                                                  getFilesData?.grant_disbursed_dtls_comments
                                                )
                                              }
                                            >
                                              <span className="acceptFile">
                                                {getFilesData?.grant_disbursed_dtls_status_ind ==
                                                  2 ||
                                                getFilesData?.grant_disbursed_dtls_status_ind ==
                                                  3
                                                  ? "Accept"
                                                  : ""}
                                              </span>
                                            </span>
                                            <span
                                              className="fileRemove text-center ml-2"
                                              onClick={() =>
                                                (getFilesData?.grant_disbursed_dtls_status_ind ==
                                                  2 ||
                                                  getFilesData?.grant_disbursed_dtls_status_ind >=
                                                    3) &&
                                                removeFilePopup(
                                                  getFilesData?.grant_disbursed_dtls_file_name,
                                                  getFilesData?.grant_disbursed_dtls_status_ind,
                                                  "reject",
                                                  "6",
                                                  getFilesData?.grant_disbursed_dtls_comments
                                                )
                                              }
                                            >
                                              <span className="rejectFile">
                                                {params.tranche_status ==
                                                  "Disbursed" ||
                                                params.tranche_status ==
                                                  "Disbursement in Process" ||
                                                params.tranche_status ==
                                                  "Incomplete"
                                                  ? ""
                                                  : getFilesData?.grant_disbursed_dtls_status_ind ==
                                                      2 ||
                                                    getFilesData?.grant_disbursed_dtls_status_ind >=
                                                      3
                                                  ? "Reject"
                                                  : ""}
                                              </span>
                                            </span>
                                          </>
                                        )}
                                      {(getFilesData?.grant_disbursed_dtls_status_ind ==
                                        0 ||
                                        getFilesData?.grant_disbursed_dtls_status_ind ==
                                          4) && (
                                        <span
                                          className="fileRemove text-center ml-2"
                                          onClick={() =>
                                            fileCommentsPopup(
                                              getFilesData?.grant_disbursed_dtls_file_name,
                                              getFilesData?.grant_disbursed_dtls_status_ind,
                                              "comment",
                                              "2",
                                              getFilesData?.grant_disbursed_dtls_comments
                                            )
                                          }
                                        >
                                          <span className="commentsFile">
                                            Comments
                                          </span>
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </fieldset>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  getBackgroundColor
                    ? getBackgroundColor[7] + " col-6 bgColumn pl-3"
                    : "col-6 bgColumn pl-3"
                }
              >
                <div
                  className={
                    errorBox &&
                    declarationFile?.name == null &&
                    getFilesData?.selected_startup_declation_file_name == null
                      ? "row alignContents p-3"
                      : "row p-3"
                  }
                  style={{ height: "100%" }}
                >
                  <div className="col-12 alignContents-col">
                    <div className="d-flex">
                      <span className="s-no">8. </span>
                      <span className="section-head">
                        A declaration that selected startup by an incubator for
                        assistance under this scheme has not been charged any
                        fees<span className="required">*</span>
                        <span
                          className="Info-circle ml-2"
                          data-target="#fileInfo"
                          data-toggle="modal"
                          onClick={() => setModalPopup("Declaration")}
                        >
                          ℹ️
                        </span>
                        <span>
                          &nbsp;
                          <span className="fileRemove text-center">
                            <span>
                              {getFilesData?.selected_startup_declation_status_ind ==
                              "4" ? (
                                <span className="acceptFile1">Accepted</span>
                              ) : getFilesData?.status_ind > 2 &&
                                getFilesData?.selected_startup_declation_status_ind ==
                                  "0" ? (
                                <span className="rejectFile1">Rejected</span>
                              ) : (
                                ""
                              )}
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>
                    <div className="fileDiv p-2">
                      {loggedInRole == "incubator" && (
                        <button
                          className="btn btn-sisfs btn-transparent-primary"
                          onClick={() =>
                            setOpenUploadBlock({ declaration: true })
                          }
                        >
                          Upload
                        </button>
                      )}
                      {((openUploadBlock &&
                        openUploadBlock["declaration"] == true) ||
                        getFilesData?.selected_startup_declation_file_name ||
                        declarationFile) && (
                        <>
                          {loggedInRole == "incubator" && (
                            <span
                              className="close"
                              onClick={() =>
                                setOpenUploadBlock({ declaration: false })
                              }
                            >
                              {!getFilesData?.selected_startup_declation_file_name && (
                                <i className="fa fa-close"></i>
                              )}
                            </span>
                          )}
                          <fieldset className="fileUploadSec mt-2">
                            <div className="" style={{ width: "100%" }}>
                              {loggedInRole == "incubator" && (
                                <div className="filebox">
                                  {/* <div className='mt-3'>Drag & Drop file or Browse</div> */}

                                  <div className="mt-2">
                                    <img src={fileupload} alt="" />
                                  </div>
                                  <input
                                    disabled={
                                      getFilesData?.selected_startup_declation_status_ind ==
                                      4
                                    }
                                    type="file"
                                    accept=".pdf"
                                    id="Declaration"
                                    name="Declaration"
                                    onChange={handleFileChange}
                                    hidden
                                  />
                                  <label
                                    htmlFor="Declaration"
                                    className="btn btn-sisfs btn-transparent-primary mt-1"
                                  >
                                    Browse
                                  </label>
                                  {loggedInRole == "incubator" &&
                                    getFilesData?.status_ind > 2 &&
                                    getFilesData?.selected_startup_declation_status_ind ==
                                      0 && (
                                      <p
                                        style={{
                                          fontSize: "12px",
                                          color: "red",
                                          fontWeight: "bold",
                                          textAlign: "center",
                                        }}
                                      >
                                        * Your file was rejected, please read
                                        the comments before upload
                                      </p>
                                    )}
                                </div>
                              )}
                              {getFilesData?.selected_startup_declation_file_name && (
                                <div
                                  className={
                                    loggedInRole == "secretariat"
                                      ? "previewDiv mb-3 filebox1 filebox1Sec"
                                      : "previewDiv mb-3 filebox1"
                                  }
                                >
                                  <div className="fileSection">
                                    <div>
                                      <span className="ml-3">
                                        <img src={pdfImg} alt="" />
                                      </span>
                                      <span className="ml-3">
                                        {getFilesData?.selected_startup_declation_file_name ? (
                                          <a
                                            href={`${
                                              NODE_URL +
                                              getFilesData?.selected_startup_declation_file_path
                                            }`}
                                            onClick={() =>
                                              getFileVisibiltyStatus(7)
                                            }
                                            target="_blank"
                                          >
                                            {getFilesData
                                              ?.selected_startup_declation_file_name
                                              .length > 15
                                              ? getFilesData?.selected_startup_declation_file_name.slice(
                                                  0,
                                                  15
                                                ) + "..."
                                              : getFilesData?.selected_startup_declation_file_name}
                                          </a>
                                        ) : (
                                          <a>
                                            {" "}
                                            {declarationFile &&
                                              declarationFile?.name}
                                          </a>
                                        )}
                                      </span>
                                    </div>
                                    <div className="d-flex">
                                      {loggedInRole == "secretariat" &&
                                        params.tranche_status !=
                                          "Incomplete" && (
                                          <>
                                            <span
                                              className="fileRemove text-center"
                                              onClick={() =>
                                                (getFilesData?.selected_startup_declation_status_ind ==
                                                  2 ||
                                                  getFilesData?.selected_startup_declation_status_ind ==
                                                    3) &&
                                                acceptFilePopup(
                                                  getFilesData?.selected_startup_declation_file_name,
                                                  getFilesData?.selected_startup_declation_status_ind,
                                                  "accept",
                                                  "7",
                                                  getFilesData?.selected_startup_declation_comments
                                                )
                                              }
                                            >
                                              <span className="acceptFile">
                                                {getFilesData?.selected_startup_declation_status_ind ==
                                                  2 ||
                                                getFilesData?.selected_startup_declation_status_ind ==
                                                  3
                                                  ? "Accept"
                                                  : ""}
                                              </span>
                                            </span>
                                            <span
                                              className="fileRemove text-center ml-2"
                                              onClick={() =>
                                                (getFilesData?.selected_startup_declation_status_ind ==
                                                  2 ||
                                                  getFilesData?.selected_startup_declation_status_ind >=
                                                    3) &&
                                                removeFilePopup(
                                                  getFilesData?.selected_startup_declation_file_name,
                                                  getFilesData?.selected_startup_declation_status_ind,
                                                  "reject",
                                                  "7",
                                                  getFilesData?.selected_startup_declation_comments
                                                )
                                              }
                                            >
                                              <span className="rejectFile">
                                                {params.tranche_status ==
                                                  "Disbursed" ||
                                                params.tranche_status ==
                                                  "Disbursement in Process" ||
                                                params.tranche_status ==
                                                  "Incomplete"
                                                  ? ""
                                                  : getFilesData?.selected_startup_declation_status_ind ==
                                                      2 ||
                                                    getFilesData?.selected_startup_declation_status_ind >=
                                                      3
                                                  ? "Reject"
                                                  : ""}
                                              </span>
                                            </span>
                                          </>
                                        )}
                                      {(getFilesData?.selected_startup_declation_status_ind ==
                                        0 ||
                                        getFilesData?.selected_startup_declation_status_ind ==
                                          4) && (
                                        <span
                                          className="fileRemove text-center ml-2"
                                          onClick={() =>
                                            fileCommentsPopup(
                                              getFilesData?.selected_startup_declation_file_name,
                                              getFilesData?.selected_startup_declation_status_ind,
                                              "comment",
                                              "2",
                                              getFilesData?.selected_startup_declation_comments
                                            )
                                          }
                                        >
                                          <span className="commentsFile">
                                            Comments
                                          </span>
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </fieldset>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group row section-bg subsqnt p-0">
              <div
                className={
                  getBackgroundColor
                    ? getBackgroundColor[8] + " col-6 bgColumn pr-3"
                    : "col-6 bgColumn pl-3"
                }
              >
                <div
                  className={
                    errorBox &&
                    listStartups?.name == null &&
                    getFilesData?.List_of_Startups_file_name == null
                      ? "row alignContents p-3"
                      : "row p-3"
                  }
                  style={{ height: "100%" }}
                >
                  <div className="col-12 alignContents-col">
                    <div className="d-flex">
                      <span className="s-no">9. </span>
                      <span className="section-head">
                        List of Startups with DPIIT recognition, registration
                        number, registration date, company bank account details,
                        funds disbursed to each startup
                        <span className="required">*</span>
                        <span
                          className="Info-circle ml-2"
                          data-target="#fileInfo"
                          data-toggle="modal"
                          onClick={() => setModalPopup("ListStartups")}
                        >
                          ℹ️
                        </span>
                        <span>
                          &nbsp;
                          <span className="fileRemove text-center">
                            <span>
                              {getFilesData?.List_of_Startups_status_ind ==
                              "4" ? (
                                <span className="acceptFile1">Accepted</span>
                              ) : getFilesData?.status_ind > 2 &&
                                getFilesData?.List_of_Startups_status_ind ==
                                  "0" ? (
                                <span className="rejectFile1">Rejected</span>
                              ) : (
                                ""
                              )}
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>
                    <div className="fileDiv p-2">
                      {loggedInRole == "incubator" && (
                        <button
                          className="btn btn-sisfs btn-transparent-primary"
                          onClick={() =>
                            setOpenUploadBlock({ listStartup: true })
                          }
                        >
                          Upload
                        </button>
                      )}
                      {((openUploadBlock &&
                        openUploadBlock["listStartup"] == true) ||
                        getFilesData?.legal_agreements_file_name ||
                        listStartups) && (
                        <>
                          {loggedInRole == "incubator" && (
                            <span
                              className="close"
                              onClick={() =>
                                setOpenUploadBlock({ listStartup: false })
                              }
                            >
                              {!getFilesData?.List_of_Startups_file_name && (
                                <i className="fa fa-close"></i>
                              )}
                            </span>
                          )}
                          <fieldset className="fileUploadSec mt-2">
                            <div className="" style={{ width: "100%" }}>
                              {loggedInRole == "incubator" && (
                                <div className="filebox">
                                  {/* <div className='mt-3'>Drag & Drop file or Browse</div> */}
                                  <div className="mt-2">
                                    <img src={fileupload} alt="" />
                                  </div>
                                  <input
                                    disabled={
                                      getFilesData?.List_of_Startups_status_ind ==
                                      4
                                    }
                                    type="file"
                                    accept=".pdf"
                                    id="listStartup"
                                    name="listStartup"
                                    onChange={handleFileChange}
                                    hidden
                                  />
                                  <label
                                    htmlFor="listStartup"
                                    className="btn btn-sisfs btn-transparent-primary mt-1"
                                  >
                                    Browse
                                  </label>
                                  {loggedInRole == "incubator" &&
                                    getFilesData?.status_ind > 2 &&
                                    getFilesData?.List_of_Startups_status_ind ==
                                      0 && (
                                      <p
                                        style={{
                                          fontSize: "12px",
                                          color: "red",
                                          fontWeight: "bold",
                                          textAlign: "center",
                                        }}
                                      >
                                        * Your file was rejected, please read
                                        the comments before upload
                                      </p>
                                    )}
                                </div>
                              )}
                              {getFilesData?.List_of_Startups_file_name && (
                                <div
                                  className={
                                    loggedInRole == "secretariat"
                                      ? "previewDiv mb-3 filebox1 filebox1Sec"
                                      : "previewDiv mb-3 filebox1"
                                  }
                                >
                                  <div className="fileSection">
                                    <div>
                                      <span className="ml-3">
                                        <img src={pdfImg} alt="" />
                                      </span>
                                      <span className="ml-3">
                                        {getFilesData?.List_of_Startups_file_name ? (
                                          <a
                                            href={`${
                                              NODE_URL +
                                              getFilesData?.List_of_Startups_path_name
                                            }`}
                                            onClick={() =>
                                              getFileVisibiltyStatus(8)
                                            }
                                            target="_blank"
                                          >
                                            {getFilesData
                                              ?.List_of_Startups_file_name
                                              .length > 15
                                              ? getFilesData?.List_of_Startups_file_name.slice(
                                                  0,
                                                  15
                                                ) + "..."
                                              : getFilesData?.List_of_Startups_file_name}
                                          </a>
                                        ) : (
                                          <a>
                                            {listStartups && listStartups?.name}
                                          </a>
                                        )}
                                      </span>
                                    </div>
                                    <div className="d-flex">
                                      {loggedInRole == "secretariat" &&
                                        params.tranche_status !=
                                          "Incomplete" && (
                                          <>
                                            <span
                                              className="fileRemove text-center"
                                              onClick={() =>
                                                (getFilesData?.List_of_Startups_status_ind ==
                                                  2 ||
                                                  getFilesData?.List_of_Startups_status_ind ==
                                                    3) &&
                                                acceptFilePopup(
                                                  getFilesData?.List_of_Startups_file_name,
                                                  getFilesData?.List_of_Startups_status_ind,
                                                  "accept",
                                                  "8",
                                                  getFilesData?.List_of_Startups_comments
                                                )
                                              }
                                            >
                                              <span className="acceptFile">
                                                {getFilesData?.List_of_Startups_status_ind ==
                                                  2 ||
                                                getFilesData?.List_of_Startups_status_ind ==
                                                  3
                                                  ? "Accept"
                                                  : ""}
                                              </span>
                                            </span>
                                            <span
                                              className="fileRemove text-center ml-2"
                                              onClick={() =>
                                                (getFilesData?.List_of_Startups_status_ind ==
                                                  2 ||
                                                  getFilesData?.List_of_Startups_status_ind >=
                                                    3) &&
                                                removeFilePopup(
                                                  getFilesData?.List_of_Startups_file_name,
                                                  getFilesData?.List_of_Startups_status_ind,
                                                  "reject",
                                                  "8",
                                                  getFilesData?.List_of_Startups_comments
                                                )
                                              }
                                            >
                                              <span className="rejectFile">
                                                {params.tranche_status ==
                                                  "Disbursed" ||
                                                params.tranche_status ==
                                                  "Disbursement in Process" ||
                                                params.tranche_status ==
                                                  "Incomplete"
                                                  ? ""
                                                  : getFilesData?.List_of_Startups_status_ind ==
                                                      2 ||
                                                    getFilesData?.List_of_Startups_status_ind >=
                                                      3
                                                  ? "Reject"
                                                  : ""}
                                              </span>
                                            </span>
                                          </>
                                        )}
                                      {(getFilesData?.List_of_Startups_status_ind ==
                                        0 ||
                                        getFilesData?.List_of_Startups_status_ind ==
                                          4) && (
                                        <span
                                          className="fileRemove text-center ml-2"
                                          onClick={() =>
                                            fileCommentsPopup(
                                              getFilesData?.List_of_Startups_file_name,
                                              getFilesData?.List_of_Startups_status_ind,
                                              "comment",
                                              "2",
                                              getFilesData?.List_of_Startups_comments
                                            )
                                          }
                                        >
                                          <span className="commentsFile">
                                            Comments
                                          </span>
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </fieldset>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  getBackgroundColor
                    ? getBackgroundColor[9] + " col-6 bgColumn pl-3"
                    : "col-6 bgColumn pl-3"
                }
              >
                <div
                  className={
                    errorBox &&
                    pfmsFile?.name == null &&
                    getFilesData?.proof_of_pfms_file_name == null
                      ? "row alignContents p-3"
                      : "row p-3"
                  }
                  style={{ height: "100%" }}
                >
                  <div className="col-12 alignContents-col">
                    <div className="d-flex">
                      <span className="s-no">10. </span>
                      <span className="section-head">
                        PFMS Report on available balance
                        <span className="required">*</span>
                        <span
                          className="Info-circle ml-2"
                          data-target="#fileInfo"
                          data-toggle="modal"
                          onClick={() => setModalPopup("Pfms")}
                        >
                          ℹ️
                        </span>
                        <span>
                          &nbsp;
                          <span className="fileRemove text-center">
                            <span>
                              {getFilesData?.proof_of_pfms_status_ind == "4" ? (
                                <span className="acceptFile1">Accepted</span>
                              ) : getFilesData?.status_ind > 2 &&
                                getFilesData?.proof_of_pfms_status_ind ==
                                  "0" ? (
                                <span className="rejectFile1">Rejected</span>
                              ) : (
                                ""
                              )}
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>
                    <div className="fileDiv p-2">
                      {loggedInRole == "incubator" && (
                        <button
                          className="btn btn-sisfs btn-transparent-primary"
                          onClick={() => setOpenUploadBlock({ pfms: true })}
                        >
                          Upload
                        </button>
                      )}
                      {((openUploadBlock && openUploadBlock["pfms"] == true) ||
                        getFilesData?.proof_of_pfms_file_name ||
                        pfmsFile) && (
                        <>
                          {loggedInRole == "incubator" && (
                            <span
                              className="close"
                              onClick={() =>
                                setOpenUploadBlock({ pfms: false })
                              }
                            >
                              {!getFilesData?.proof_of_pfms_file_name && (
                                <i className="fa fa-close"></i>
                              )}
                            </span>
                          )}
                          <fieldset className="fileUploadSec mt-2">
                            <div className="" style={{ width: "100%" }}>
                              {loggedInRole == "incubator" && (
                                <div className="filebox">
                                  {/* <div className='mt-3'>Drag & Drop file or Browse</div> */}
                                  <div className="mt-2">
                                    <img src={fileupload} alt="" />
                                  </div>
                                  <input
                                    disabled={
                                      getFilesData?.proof_of_pfms_status_ind ==
                                      4
                                    }
                                    type="file"
                                    accept=".pdf"
                                    id="Pfms"
                                    name="Pfms"
                                    onChange={handleFileChange}
                                    hidden
                                  />
                                  <label
                                    htmlFor="Pfms"
                                    className="btn btn-sisfs btn-transparent-primary mt-1"
                                  >
                                    Browse
                                  </label>
                                  {loggedInRole == "incubator" &&
                                    getFilesData?.status_ind > 2 &&
                                    getFilesData?.proof_of_pfms_status_ind ==
                                      0 && (
                                      <p
                                        style={{
                                          fontSize: "12px",
                                          color: "red",
                                          fontWeight: "bold",
                                          textAlign: "center",
                                        }}
                                      >
                                        * Your file was rejected, please read
                                        the comments before upload
                                      </p>
                                    )}
                                </div>
                              )}
                              {getFilesData?.proof_of_pfms_file_name && (
                                <div
                                  className={
                                    loggedInRole == "secretariat"
                                      ? "previewDiv mb-3 filebox1 filebox1Sec"
                                      : "previewDiv mb-3 filebox1"
                                  }
                                >
                                  <div className="fileSection">
                                    <div>
                                      <span className="ml-3">
                                        <img src={pdfImg} alt="" />
                                      </span>
                                      <span className="ml-3">
                                        {getFilesData?.proof_of_pfms_file_name ? (
                                          <a
                                            href={`${
                                              NODE_URL +
                                              getFilesData?.proof_of_pfms_file_path
                                            }`}
                                            onClick={() =>
                                              getFileVisibiltyStatus(9)
                                            }
                                            target="_blank"
                                          >
                                            {getFilesData
                                              ?.proof_of_pfms_file_name.length >
                                            15
                                              ? getFilesData?.proof_of_pfms_file_name.slice(
                                                  0,
                                                  15
                                                ) + "..."
                                              : getFilesData?.proof_of_pfms_file_name}
                                          </a>
                                        ) : (
                                          <a>{pfmsFile && pfmsFile?.name}</a>
                                        )}
                                      </span>
                                    </div>
                                    <div className="d-flex">
                                      {loggedInRole == "secretariat" &&
                                        params.tranche_status !=
                                          "Incomplete" && (
                                          <>
                                            <span
                                              className="fileRemove text-center"
                                              onClick={() =>
                                                (getFilesData?.proof_of_pfms_status_ind ==
                                                  2 ||
                                                  getFilesData?.proof_of_pfms_status_ind ==
                                                    3) &&
                                                acceptFilePopup(
                                                  getFilesData?.proof_of_pfms_file_name,
                                                  getFilesData?.proof_of_pfms_status_ind,
                                                  "accept",
                                                  "9",
                                                  getFilesData?.proof_of_pfms_comments
                                                )
                                              }
                                            >
                                              <span className="acceptFile">
                                                {getFilesData?.proof_of_pfms_status_ind ==
                                                  2 ||
                                                getFilesData?.proof_of_pfms_status_ind ==
                                                  3
                                                  ? "Accept"
                                                  : ""}
                                              </span>
                                            </span>
                                            <span
                                              className="fileRemove text-center ml-2"
                                              onClick={() =>
                                                (getFilesData?.proof_of_pfms_status_ind ==
                                                  2 ||
                                                  getFilesData?.proof_of_pfms_status_ind >=
                                                    3) &&
                                                removeFilePopup(
                                                  getFilesData?.proof_of_pfms_file_name,
                                                  getFilesData?.proof_of_pfms_status_ind,
                                                  "reject",
                                                  "9",
                                                  getFilesData?.proof_of_pfms_comments
                                                )
                                              }
                                            >
                                              <span className="rejectFile">
                                                {params.tranche_status ==
                                                  "Disbursed" ||
                                                params.tranche_status ==
                                                  "Disbursement in Process" ||
                                                params.tranche_status ==
                                                  "Incomplete"
                                                  ? ""
                                                  : getFilesData?.proof_of_pfms_status_ind ==
                                                      2 ||
                                                    getFilesData?.proof_of_pfms_status_ind >=
                                                      3
                                                  ? "Reject"
                                                  : ""}
                                              </span>
                                            </span>
                                          </>
                                        )}
                                      {(getFilesData?.proof_of_pfms_status_ind ==
                                        0 ||
                                        getFilesData?.proof_of_pfms_status_ind ==
                                          4) && (
                                        <span
                                          className="fileRemove text-center ml-2"
                                          onClick={() =>
                                            fileCommentsPopup(
                                              getFilesData?.proof_of_pfms_file_name,
                                              getFilesData?.proof_of_pfms_status_ind,
                                              "comment",
                                              "2",
                                              getFilesData?.proof_of_pfms_comments
                                            )
                                          }
                                        >
                                          <span className="commentsFile">
                                            Comments
                                          </span>
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </fieldset>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group row section-bg subsqnt p-0">
              <div
                className={
                  getBackgroundColor
                    ? getBackgroundColor[10] + " col-6 bgColumn pr-3"
                    : "col-6 bgColumn pl-3"
                }
              >
                <div
                  className={
                    errorBox &&
                    sanctionFile?.name == null &&
                    getFilesData?.previous_sanction_order_file_name == null
                      ? "row alignContents p-3"
                      : "row p-3"
                  }
                  style={{ height: "100%" }}
                >
                  <div className="col-12 alignContents-col">
                    <div className="d-flex">
                      <span className="s-no">11. </span>
                      <span className="section-head">
                        Previous sanction order
                        <span className="required">*</span>
                        <span
                          className="Info-circle ml-2"
                          data-target="#fileInfo"
                          data-toggle="modal"
                          onClick={() => setModalPopup("Sanction")}
                        >
                          ℹ️
                        </span>
                        <span>
                          &nbsp;
                          <span className="fileRemove text-center">
                            <span>
                              {getFilesData?.previous_sanction_order_status_ind ==
                              "4" ? (
                                <span className="acceptFile1">Accepted</span>
                              ) : getFilesData?.status_ind > 2 &&
                                getFilesData?.previous_sanction_order_status_ind ==
                                  "0" ? (
                                <span className="rejectFile1">Rejected</span>
                              ) : (
                                ""
                              )}
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>
                    <div className="fileDiv p-2">
                      {loggedInRole == "incubator" && (
                        <button
                          className="btn btn-sisfs btn-transparent-primary"
                          onClick={() => setOpenUploadBlock({ sanction: true })}
                        >
                          Upload
                        </button>
                      )}
                      {((openUploadBlock &&
                        openUploadBlock["sanction"] == true) ||
                        getFilesData?.previous_sanction_order_file_name ||
                        sanctionFile) && (
                        <>
                          {loggedInRole == "incubator" && (
                            <span
                              className="close"
                              onClick={() =>
                                setOpenUploadBlock({ sanction: false })
                              }
                            >
                              {!getFilesData?.previous_sanction_order_file_name && (
                                <i className="fa fa-close"></i>
                              )}
                            </span>
                          )}
                          <fieldset className="fileUploadSec mt-2">
                            <div className="" style={{ width: "100%" }}>
                              {loggedInRole == "incubator" && (
                                <div className="filebox">
                                  {/* <div className='mt-3'>Drag & Drop file or Browse</div> */}
                                  <div className="mt-2">
                                    <img src={fileupload} alt="" />
                                  </div>
                                  <input
                                    disabled={
                                      getFilesData?.previous_sanction_order_status_ind ==
                                      4
                                    }
                                    type="file"
                                    accept=".pdf"
                                    id="Sanction"
                                    name="Sanction"
                                    onChange={handleFileChange}
                                    hidden
                                  />
                                  <label
                                    htmlFor="Sanction"
                                    className="btn btn-sisfs btn-transparent-primary mt-1"
                                  >
                                    Browse
                                  </label>
                                  {loggedInRole == "incubator" &&
                                    getFilesData?.status_ind > 2 &&
                                    getFilesData?.previous_sanction_order_status_ind ==
                                      0 && (
                                      <p
                                        style={{
                                          fontSize: "12px",
                                          color: "red",
                                          fontWeight: "bold",
                                          textAlign: "center",
                                        }}
                                      >
                                        * Your file was rejected, please read
                                        the comments before upload
                                      </p>
                                    )}
                                </div>
                              )}
                              {getFilesData?.previous_sanction_order_file_name && (
                                <div
                                  className={
                                    loggedInRole == "secretariat"
                                      ? "previewDiv mb-3 filebox1 filebox1Sec"
                                      : "previewDiv mb-3 filebox1"
                                  }
                                >
                                  <div className="fileSection">
                                    <div>
                                      <span className="ml-3">
                                        <img src={pdfImg} alt="" />
                                      </span>
                                      <span className="ml-3">
                                        {getFilesData?.previous_sanction_order_file_name ? (
                                          <a
                                            href={`${
                                              NODE_URL +
                                              getFilesData?.previous_sanction_order_file_path
                                            }`}
                                            onClick={() =>
                                              getFileVisibiltyStatus(10)
                                            }
                                            target="_blank"
                                          >
                                            {getFilesData
                                              ?.previous_sanction_order_file_name
                                              .length > 15
                                              ? getFilesData?.previous_sanction_order_file_name.slice(
                                                  0,
                                                  15
                                                ) + "..."
                                              : getFilesData?.previous_sanction_order_file_name}
                                          </a>
                                        ) : (
                                          <a>
                                            {sanctionFile && sanctionFile?.name}
                                          </a>
                                        )}
                                      </span>
                                    </div>
                                    <div className="d-flex">
                                      {loggedInRole == "secretariat" &&
                                        params.tranche_status !=
                                          "Incomplete" && (
                                          <>
                                            <span
                                              className="fileRemove text-center"
                                              onClick={() =>
                                                (getFilesData?.previous_sanction_order_status_ind ==
                                                  2 ||
                                                  getFilesData?.previous_sanction_order_status_ind ==
                                                    3) &&
                                                acceptFilePopup(
                                                  getFilesData?.previous_sanction_order_file_name,
                                                  getFilesData?.previous_sanction_order_status_ind,
                                                  "accept",
                                                  "10",
                                                  getFilesData?.previous_sanction_order_comments
                                                )
                                              }
                                            >
                                              <span className="acceptFile">
                                                {getFilesData?.previous_sanction_order_status_ind ==
                                                  2 ||
                                                getFilesData?.previous_sanction_order_status_ind ==
                                                  3
                                                  ? "Accept"
                                                  : ""}
                                              </span>
                                            </span>
                                            <span
                                              className="fileRemove text-center ml-2"
                                              onClick={() =>
                                                (getFilesData?.previous_sanction_order_status_ind ==
                                                  2 ||
                                                  getFilesData?.previous_sanction_order_status_ind >=
                                                    3) &&
                                                removeFilePopup(
                                                  getFilesData?.previous_sanction_order_file_name,
                                                  getFilesData?.previous_sanction_order_status_ind,
                                                  "reject",
                                                  "10",
                                                  getFilesData?.previous_sanction_order_comments
                                                )
                                              }
                                            >
                                              <span className="rejectFile">
                                                {params.tranche_status ==
                                                  "Disbursed" ||
                                                params.tranche_status ==
                                                  "Disbursement in Process" ||
                                                params.tranche_status ==
                                                  "Incomplete"
                                                  ? ""
                                                  : getFilesData?.previous_sanction_order_status_ind ==
                                                      2 ||
                                                    getFilesData?.previous_sanction_order_status_ind >=
                                                      3
                                                  ? "Reject"
                                                  : ""}
                                              </span>
                                            </span>
                                          </>
                                        )}
                                      {(getFilesData?.previous_sanction_order_status_ind ==
                                        0 ||
                                        getFilesData?.previous_sanction_order_status_ind ==
                                          4) && (
                                        <span
                                          className="fileRemove text-center ml-2"
                                          onClick={() =>
                                            fileCommentsPopup(
                                              getFilesData?.previous_sanction_order_file_name,
                                              getFilesData?.previous_sanction_order_status_ind,
                                              "comment",
                                              "2",
                                              getFilesData?.previous_sanction_order_comments
                                            )
                                          }
                                        >
                                          <span className="commentsFile">
                                            Comments
                                          </span>
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </fieldset>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  getBackgroundColor
                    ? getBackgroundColor[11] + " col-6 bgColumn pl-3"
                    : "col-6 bgColumn pl-3"
                }
              >
                <div
                  className={
                    errorBox &&
                    traFIle?.name == null &&
                    getFilesData?.tra_agreement_file_name == null
                      ? "row alignContents p-3"
                      : "row p-3"
                  }
                  style={{ height: "100%" }}
                >
                  <div className="col-12 alignContents-col">
                    <div className="d-flex">
                      <span className="s-no">12. </span>
                      <span className="section-head">
                        TRA Agreement
                        <span className="required">*</span>
                        <span
                          className="Info-circle ml-2"
                          data-target="#fileInfo"
                          data-toggle="modal"
                          onClick={() => setModalPopup("Tra")}
                        >
                          ℹ️
                        </span>
                        <span>
                          &nbsp;
                          <span className="fileRemove text-center">
                            <span>
                              {getFilesData?.tra_agreement_status_ind == "4" ? (
                                <span className="acceptFile1">Accepted</span>
                              ) : getFilesData?.status_ind > 2 &&
                                getFilesData?.tra_agreement_status_ind ==
                                  "0" ? (
                                <span className="rejectFile1">Rejected</span>
                              ) : (
                                ""
                              )}
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>
                    <div className="fileDiv p-2">
                      {loggedInRole == "incubator" && (
                        <button
                          className="btn btn-sisfs btn-transparent-primary"
                          onClick={() =>
                            setOpenUploadBlock({ traAgreement: true })
                          }
                        >
                          Upload
                        </button>
                      )}
                      {((openUploadBlock &&
                        openUploadBlock["traAgreement"] == true) ||
                        getFilesData?.tra_agreement_file_name ||
                        traFIle) && (
                        <>
                          {loggedInRole == "incubator" && (
                            <span
                              className="close"
                              onClick={() =>
                                setOpenUploadBlock({ traAgreement: false })
                              }
                            >
                              {!getFilesData?.tra_agreement_file_name && (
                                <i className="fa fa-close"></i>
                              )}
                            </span>
                          )}
                          <fieldset className="fileUploadSec mt-2">
                            <div className="" style={{ width: "100%" }}>
                              {loggedInRole == "incubator" && (
                                <div className="filebox">
                                  {/* <div className='mt-3'>Drag & Drop file or Browse</div> */}
                                  <div className="mt-2">
                                    <img src={fileupload} alt="" />
                                  </div>
                                  <input
                                    disabled={
                                      getFilesData?.tra_agreement_status_ind ==
                                      4
                                    }
                                    type="file"
                                    accept=".pdf"
                                    id="Tra"
                                    name="Tra"
                                    onChange={handleFileChange}
                                    hidden
                                  />
                                  <label
                                    htmlFor="Tra"
                                    className="btn btn-sisfs btn-transparent-primary mt-1"
                                  >
                                    Browse
                                  </label>
                                  {loggedInRole == "incubator" &&
                                    getFilesData?.status_ind > 2 &&
                                    getFilesData?.tra_agreement_status_ind ==
                                      0 && (
                                      <p
                                        style={{
                                          fontSize: "12px",
                                          color: "red",
                                          fontWeight: "bold",
                                          textAlign: "center",
                                        }}
                                      >
                                        * Your file was rejected, please read
                                        the comments before upload
                                      </p>
                                    )}
                                </div>
                              )}
                              {getFilesData?.tra_agreement_file_name && (
                                <div
                                  className={
                                    loggedInRole == "secretariat"
                                      ? "previewDiv mb-3 filebox1 filebox1Sec"
                                      : "previewDiv mb-3 filebox1"
                                  }
                                >
                                  <div className="fileSection">
                                    <div>
                                      <span className="ml-3">
                                        <img src={pdfImg} alt="" />
                                      </span>
                                      <span className="ml-3">
                                        {getFilesData?.tra_agreement_file_name ? (
                                          <a
                                            href={`${
                                              NODE_URL +
                                              getFilesData?.tra_agreement_file_path
                                            }`}
                                            onClick={() =>
                                              getFileVisibiltyStatus(11)
                                            }
                                            target="_blank"
                                          >
                                            {getFilesData
                                              ?.tra_agreement_file_name.length >
                                            15
                                              ? getFilesData?.tra_agreement_file_name.slice(
                                                  0,
                                                  15
                                                ) + "..."
                                              : getFilesData?.tra_agreement_file_name}
                                          </a>
                                        ) : (
                                          <a>{traFIle && traFIle?.name}</a>
                                        )}
                                      </span>
                                    </div>
                                    <div className="d-flex">
                                      {loggedInRole == "secretariat" &&
                                        params.tranche_status !=
                                          "Incomplete" && (
                                          <>
                                            <span
                                              className="fileRemove text-center"
                                              onClick={() =>
                                                (getFilesData?.tra_agreement_status_ind ==
                                                  2 ||
                                                  getFilesData?.tra_agreement_status_ind ==
                                                    3) &&
                                                acceptFilePopup(
                                                  getFilesData?.tra_agreement_file_name,
                                                  getFilesData?.tra_agreement_status_ind,
                                                  "accept",
                                                  "11",
                                                  getFilesData?.tra_agreement_comments
                                                )
                                              }
                                            >
                                              <span className="acceptFile">
                                                {getFilesData?.tra_agreement_status_ind ==
                                                  2 ||
                                                getFilesData?.tra_agreement_status_ind ==
                                                  3
                                                  ? "Accept"
                                                  : ""}
                                              </span>
                                            </span>
                                            <span
                                              className="fileRemove text-center ml-2"
                                              onClick={() =>
                                                (getFilesData?.tra_agreement_status_ind ==
                                                  2 ||
                                                  getFilesData?.tra_agreement_status_ind >=
                                                    3) &&
                                                removeFilePopup(
                                                  getFilesData?.tra_agreement_file_name,
                                                  getFilesData?.tra_agreement_status_ind,
                                                  "reject",
                                                  "11",
                                                  getFilesData?.tra_agreement_comments
                                                )
                                              }
                                            >
                                              <span className="rejectFile">
                                                {params.tranche_status ==
                                                  "Disbursed" ||
                                                params.tranche_status ==
                                                  "Disbursement in Process" ||
                                                params.tranche_status ==
                                                  "Incomplete"
                                                  ? ""
                                                  : getFilesData?.tra_agreement_status_ind ==
                                                      2 ||
                                                    getFilesData?.tra_agreement_status_ind >=
                                                      3
                                                  ? "Reject"
                                                  : ""}
                                              </span>
                                            </span>
                                          </>
                                        )}
                                      {(getFilesData?.tra_agreement_status_ind ==
                                        0 ||
                                        getFilesData?.tra_agreement_status_ind ==
                                          4) && (
                                        <span
                                          className="fileRemove text-center ml-2"
                                          onClick={() =>
                                            fileCommentsPopup(
                                              getFilesData?.tra_agreement_file_name,
                                              getFilesData?.tra_agreement_status_ind,
                                              "comment",
                                              "2",
                                              getFilesData?.tra_agreement_comments
                                            )
                                          }
                                        >
                                          <span className="commentsFile">
                                            Comments
                                          </span>
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </fieldset>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-right">
                <button
                  className="btn btn-sisfs btn-yellow-primary mr-4"
                  onClick={backToFundDetails}
                >
                  Back to fund details
                </button>
                <button
                  className="btn btn-sisfs btn-yellow-primary"
                  onClick={() => saveDraft("1")}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
          <div className="col-1"></div>
        </div>
      </fieldset>

      <div
        className="modal fade"
        id="fileInfo"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none pb-0">
              <div className="text-center">
                {/* <h5 className="modal-title " id="exampleModalLongTitle">

                                    <span>ISFS Subsequent Tranche SOP – Do's and Don’ts
                                    </span>
                                </h5> */}
              </div>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {/* <div className="row mb-3">
                                <div className="col-12 text-center">
                                    <h5 className="modal-title " id="exampleModalLongTitle">

                                        <span>SISFS Subsequent Tranche SOP – Do's and Don’ts
                                        </span>
                                    </h5>
                                </div>
                            </div> */}
              <div className="pl-3">
                {filename == "Utilization" && (
                  <>
                    <span className="Info-subhead">
                      Utilization Certificate of previous instalment and Audited
                      Statement of Expenditure (SoE) separately for Management
                      fee and Seed Fund.
                    </span>{" "}
                  </>
                )}
                {filename == "Audited" && (
                  <>
                    <span className="Info-subhead">
                      Bank Statement of TRA account of incubator
                    </span>{" "}
                  </>
                )}
                {filename == "Interest" && (
                  <>
                    <span className="Info-subhead">
                      Details of interest earned on unutilized funds, if any
                    </span>{" "}
                  </>
                )}
                {filename == "Ismc" && (
                  <>
                    <span className="Info-subhead">
                      Minutes of ISMC meetings
                    </span>{" "}
                  </>
                )}
                {filename == "Achievement" && (
                  <>
                    <span className="Info-subhead">
                      Proof of achievement of milestones as decided by EAC –
                    </span>{" "}
                  </>
                )}
                {filename == "Debenture" && (
                  <>
                    <span className="Info-subhead">
                      Details of convertible debentures, or debt, or debt-linked
                      instruments along with interest and return on investment
                      for each Startup{" "}
                    </span>{" "}
                  </>
                )}
                {filename == "Disbursed" && (
                  <>
                    <span className="Info-subhead">
                      Details of grant disbursed and return on investment for
                      each startup
                    </span>{" "}
                  </>
                )}
                {filename == "Declaration" && (
                  <>
                    <span className="Info-subhead">
                      A declaration that selected startups have not been charged
                      any fees by the incubator for assistance under the scheme.
                    </span>{" "}
                  </>
                )}
                {filename == "ListStartups" && (
                  <>
                    <span className="Info-subhead">
                      List of Startups Selected
                    </span>{" "}
                  </>
                )}
                {filename == "Pfms" && (
                  <>
                    <span className="Info-subhead">
                      PFMS Report on available balance
                    </span>{" "}
                  </>
                )}
                {filename == "Sanction" && (
                  <>
                    <span className="Info-subhead">
                      Please provide scanned copy of the Sanction Order for your
                      previous tranches
                    </span>{" "}
                  </>
                )}
                {filename == "Tra" && (
                  <>
                    <span className="Info-subhead">
                      Please provide scanned copy of the signed tripartite
                      agreement / TRA agreement
                    </span>{" "}
                  </>
                )}
              </div>

              <FileUploadInfo fileName={filename} />
              <div className="row pt-3 mb-3">
                <div className="col-md-12 centerText"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showUploadFailureModal && (
        <ConfirmationDialog
          headerText={failDialogData.header}
          bodyText={failDialogData.text}
          onDialogClose={() => setShowUploadFailureModal(false)}
          iconClass="showImage"
        />
      )}
      {/* confirmation popup */}
      {confirmationPopup && (
        <div
          className="modal align-middle modal-sisfs d-block"
          id="FormSubmissionError"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div
            className={
              currentFileDetails.action == "comment"
                ? "modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable"
                : "modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable"
            }
          >
            <div className="modal-content">
              <div className="modal-body ">
                <h6 className="modal-message mt-2">
                  {/* {console.log(currentFileDetails)}
                                    {currentFileDetails.name} <br />
                                    {currentFileDetails.status} <br />
                                    {currentFileDetails.file_no} */}
                  {currentFileDetails.action == "accept"
                    ? "Are you sure you want to accept the file?"
                    : currentFileDetails.action == "reject"
                    ? "Are you sure you want to reject the file?"
                    : "Comments"}
                </h6>
                {<span className="text-danger">{readFileFirst}</span>}
                {currentFileDetails.action == "comment" && (
                  <>
                    {currentFileDetails.comment == null ? (
                      <div
                        className="row text-center"
                        style={{ width: "100%" }}
                      >
                        <div
                          className="col-12 text-center"
                          style={{ width: "100%" }}
                        >
                          <span className="">No Comments</span>
                        </div>
                      </div>
                    ) : (
                      <div style={{ maxHeight: "40vh", overflow: "scroll" }}>
                        <table className="table-bordered mt-0">
                          <thead>
                            <th className="text-center">Status</th>
                            <th className="text-center">Comment</th>
                            <th className="text-center">Date</th>
                          </thead>
                          <tbody>
                            {currentFileDetails.comment &&
                              currentFileDetails.comment.map((item: any, i) => (
                                <>
                                  {
                                    <tr key={i}>
                                      <td
                                        style={{
                                          verticalAlign: "baseline",
                                          textAlign: "center",
                                        }}
                                      >
                                        {item.split(":")[0]}
                                      </td>
                                      <td
                                        className="commentsColumn"
                                        style={{ textAlign: "center" }}
                                      >
                                        {
                                          item
                                            .split("ted:")[1]
                                            .split("Time$#")[0]
                                        }
                                      </td>
                                      <td
                                        style={{
                                          verticalAlign: "baseline",
                                          textAlign: "center",
                                        }}
                                      >
                                        {moment(item.split("Time$#")[1]).format(
                                          "DD-MM-YYYY hh:mm:a"
                                        )}
                                      </td>
                                    </tr>
                                  }
                                </>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </>
                )}
                {currentFileDetails.action != "comment" && (
                  <>
                    <div className="rejectInput">
                      <textarea
                        className={!rejectedText ? "errorVal" : ""}
                        onChange={hanndleChange}
                        name="reject"
                        placeholder={
                          "Please add comment for" +
                          `${
                            currentFileDetails.action == "accept"
                              ? " Acceptance"
                              : " Rejection"
                          }`
                        }
                        maxLength={2000}
                      />
                    </div>
                    {/* {!rejectedText && <><small className="text-danger" style={{fontSize:'14px',fontWeight:'500'}}>Comment is required</small> <br /></>  }  */}

                    <small>
                      <i>Max.characters - 2000</i>
                    </small>
                  </>
                )}
                {/* <h6 className=" message-orange">
                                    Note: Once you submit, you are no longer able to edit again.
                                </h6> */}
                <div className="modal-action mt-3 text-center">
                  <button
                    className="btn btn-sisfs btn-transparent-primary"
                    // onClick={() => setConfirmationPopup(false)}
                    onClick={dismissModal}
                  >
                    {currentFileDetails.action == "comment" ? "OK" : "NO"}
                  </button>
                  {currentFileDetails.action != "comment" && (
                    <button
                      className="btn btn-sisfs btn-yellow-primary ml-2"
                      // onClick={confirmSubmit}
                      onClick={() =>
                        currentFileDetails.action == "reject"
                          ? removeFileUpload(
                              currentFileDetails.file_no,
                              rejectedText
                            )
                          : acceptFileUpload(
                              currentFileDetails.file_no,
                              rejectedText
                            )
                      }
                    >
                      YES
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {exceedFileSize && (
        <div
          className="modal align-middle modal-sisfs d-block"
          id="FormSubmissionError"
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img
                  src={error}
                  className="search mb-2"
                  alt="search"
                  style={{ width: "2rem" }}
                />
                <h4
                  className="message-orange"
                  style={{ fontFamily: "Roboto Slab" }}
                >
                  Oops!!!
                </h4>
                <h5
                  className="message-orange"
                  style={{ fontFamily: "Roboto Slab" }}
                >
                  File size should not exceed 10Mb
                  <br />
                  <br />
                  Please upload again.
                </h5>
                <div className="modal-action">
                  <button
                    className="btn btn-sisfs btn-yellow-primary"
                    onClick={dismissModal}
                  >
                    OK !
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SubsequentTranche;
