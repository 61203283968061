import React, { useEffect, useState } from "react";

interface IIncrementDecrementProps {
  name: string;
  value: number;
  /** Minimum value, When passed the value would not go below lower limit  */
  minValue?: number;
  /** Maximum value, when passed the value would not exceed the upper limit */
  maxValue?: number;
  onChange: (name: string, value: number) => void;
  disabled?: boolean;
}

/** Renders the increment/ decrement control  */
function IncrementDecrement(props: IIncrementDecrementProps) {
  const { name, value, minValue, maxValue, onChange, disabled } = props;

  const [inputValue, setInputValue] = useState<number>(1);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  // increment or decrement value as per user click event
  const incrementDecrement = (
    e: React.MouseEvent<HTMLSpanElement>,
    name: string
  ) => {
    let value: number = 0;

    if (name === "minus") {
      if (minValue && inputValue <= minValue) {
        value = minValue;
      } else if ((minValue && inputValue > minValue) || !minValue) {
        value = inputValue - 1;
        if (value <= 0)
          value = 0
      }
    } else {
      console.log(maxValue,"mohaaan")
      if(maxValue == 0){
        return
      }
      if (maxValue && inputValue >= maxValue) {
        value = maxValue;
      } else if ((maxValue && inputValue < maxValue) || !maxValue) {
        value = inputValue + 1;
      }
    }

    setInputValue(value);
    onChange(name, value);
  };

  return (
    <fieldset disabled={disabled}>
      <div className="number">
        <span
          className="minus"
          onClick={(e: React.MouseEvent<HTMLSpanElement>) =>
            incrementDecrement(e, "minus")
          }
        >
          <i className="fa fa-minus"></i>
        </span>
        <input
          type="text"
          name={name}
          value={inputValue}
          min={0}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            let value = parseInt(e.target.value)
            if (minValue && value < minValue) {
              value = minValue
            }
            if ((maxValue && value > maxValue) || (maxValue==0 && value > maxValue))
              value = maxValue
            else if (value)
            setInputValue(value ? value : 0);
            onChange(name, value ? value : 0);
          }}
          // disabled={maxValue == 0 && inputValue === 0}
        />
        <span
          className="plus"
          onClick={(e: React.MouseEvent<HTMLSpanElement>) =>
            incrementDecrement(e, "plus")
          }
        >
          <i className="fa fa-plus"></i>
        </span>
      </div>
    </fieldset>
  );
}

export default IncrementDecrement;
