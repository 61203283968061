import * as React from 'react';
import IncubatorHeaderComponent from '../common/IncubatorHeaderComponent';
import DashboardFooterComponent from '../../common/footer/DashboardFooterComponent';
import '../../../styles/dashboard.css';
import ScheduleMeeting from '../../../images/Schedule-Meeting.svg';
import DashboardDurationComponent from '../../dashboard/DashboardDurationComponent';
import { Link } from 'react-router-dom';
import { Line, HorizontalBar, Bar } from 'react-chartjs-2';
import schedulemeetingarrow from '../../../images/schedulemeetingarrow.svg';
import { useEffect, useState } from 'react';
import { GET_DPIIT_DASHBOARD_CHART_COUNT, GET_ALL_INCUBATORS, GET_MEETING_DETAILS, GRAPH_NODE, GET_TOP_INCUBATORS, GET_DPIIT_DASHBOARD_COUNT, GET_ALL_INCUBATORS_NODE, INSTRUMENT_ANALYSIS_GRAPH } from "../../../constants/api";
import { get, post } from '../../../effects/api/api.common';
import FullIncubatorDetailModal from '../../common/modal/FullIncubatorDetailModal';
import { formatTime } from '../../../utils/DateFormat';
import Calendar from '../../common/controls/Calendar';
import { changeNumberFormat } from '../../../utils/CommonMethods';
import { userInfo } from 'os';
import { getSessionStorageValue } from '../../../effects/utils/session-storage';

const options_horizontal = {
  scales: {
    xAxes: [{
      gridLines: {
        color: "rgba(0, 0, 0, 0)",
        drawBorder: false,
        zeroLineColor: 'transparent'
      },
      display: false
    }],
    yAxes: [{
      ticks: {
        fontSize: 16,
        fontFamily: "Open Sans"
      },
      barPercentage: 1.0,
      categoryPercentage: 0.6,
      gridLines: {
        color: "rgba(0, 0, 0, 0)",
        drawBorder: false,
        zeroLineColor: 'transparent'
      }
    }],

  },
  legend: {
    display: false
  }
}
const state_horizontal = {
  labels: ['Submitted', 'Incomplete', 'To be Reviewed', 'On Hold', 'Rejected', 'Approved'],
  datasets: [
    {
      backgroundColor: '#647FC2',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(255,99,132,0.4)',
      hoverBorderColor: 'rgba(255,99,132,1)',
      data: [1567, 124, 121, 88, 456, 998]
    }
  ]

}
const state_Bar = {
  labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
  datasets: [
    {
      label: "Funds Approved",
      type: "bar",
      backgroundColor: "rgba(159,207,239,1)",
      data: ["6100000", "4900000", "3100000", "3800000"],
      stack: 1
    }, {
      label: "Grants",
      backgroundColor: "rgba(107,132,198,1)",
      data: ["600000", "610000", "600000", "600000"],
      stack: 2,
    }, {
      label: "Debenture",
      backgroundColor: "rgba(179,189,235,1)",
      data: ["2400000", "2500000", "2600000", "2700000"],
      stack: 2
    }, {
      label: "Debt",
      backgroundColor: "rgba(218,223,245,1)",
      data: ["1000000", "600000", "700000", "200000"],
      stack: 2
    }]
};
const options_Bar = {
  legend: {
    position: "top",
    align: "end",
    labels: {
      usePointStyle: true,
      boxWidth: 6
    }
  },
  scales: {
    yAxes: [{
      id: "stacked_testY",
      type: 'linear',
      position: "left",
      stacked: true,
      display: true,
      barPercentage: 0.7,
      scaleLabel: {
        display: true,
        labelString: 'Cost',
        fontSize: 14,
        fontFamily: "Roboto Slab",
        fontColor: '#333333'
      }
    }],
    xAxes: [{
      position: "bottom",
      stacked: true,
      display: true,
      barPercentage: 0.7,
      scaleLabel: {
        display: true,
        labelString: 'Weeks',
        fontSize: 14,
        fontFamily: "Roboto Slab",
        fontColor: '#333333'
      }
    }]
  }
}
// const state_Line = {

//   labels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20"],
//   datasets: [
//     {
//       label: 'Funds Disbursed', // Name the series
//       data: [800000, 900000, 1100000, 1300000, 2000000, 1500000, 900000, 600000, 400000, 1500000, 2400000, 1600000, 900000, 1700000, 2500000, 1200000, 1000000, 200000, 400000, 600000], // Specify the data values array
//       fill: true,
//       borderColor: '#647FC2', // Add custom color border (Line)
//       backgroundColor: 'rgba(228,215,240,0.6)', // Add custom color background (Points and Fill)
//       borderWidth: 1 // Specify bar border width
//     },
//     {
//       label: 'Funds Approved', // Name the series
//       data: [1500000, 1600000, 1900000, 2400000, 3100000, 2700000, 2500000, 1300000, 900000, 3000000, 3800000, 2600000, 2000000, 2500000, 3000000, 2200000, 1500000, 900000, 600000, 500000], // Specify the data values array
//       fill: true,
//       borderColor: '#2196f3', // Add custom color border (Line)
//       backgroundColor: 'rgba(83,168,226,0.1)', // Add custom color background (Points and Fill)
//       borderWidth: 1 // Specify bar border width  
//     },
//   ]
// };
const options_Line = {
  tooltips: {
    mode: 'index',
    intersect: false,
  },
  hover: {
    mode: 'index',
    intersect: false
  },
  elements: {
    point: {
      radius: 0
    }
  },
  legend: {
    position: "top",
    align: "end",
    labels: {
      usePointStyle: true,
      boxWidth: 6
    }
  },
  scales: {
    yAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Amount Disbursed',
        fontSize: 14,
        fontFamily: "Roboto Slab",
        fontColor: '#333333'
      }
    }],
    xAxes: [{
      ticks: {
        display: false //this will remove only the label
      },
      scaleLabel: {
        display: true,
        labelString: 'Days',
        fontSize: 14,
        fontFamily: "Roboto Slab",
        fontColor: '#333333'
      }
    }],
  },
  responsive: true, // Instruct chart js to respond nicely.
  maintainAspectRatio: true, // Add to prevent default behaviour of full-width/height 
}
const options_Line_inst = {
  tooltips: {
    mode: 'index',
    intersect: false,
  },
  hover: {
    mode: 'index',
    intersect: false
  },
  elements: {
    point: {
      radius: 0
    }
  },
  legend: {
    position: "top",
    align: "end",
    labels: {
      usePointStyle: true,
      boxWidth: 6
    }
  },
  scales: {
    yAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Amount Disbursed',
        fontSize: 14,
        fontFamily: "Roboto Slab",
        fontColor: '#333333'
      }
    }],
    xAxes: [{
      ticks: {
        display: false //this will remove only the label
      },
      scaleLabel: {
        display: true,
        labelString: 'Days',
        fontSize: 14,
        fontFamily: "Roboto Slab",
        fontColor: '#333333'
      }
    }],
  },
  responsive: true, // Instruct chart js to respond nicely.
  maintainAspectRatio: true, // Add to prevent default behaviour of full-width/height 
}
function IncubatorDashboardContainer() {
  const [activeChart, setActiveChart] = useState("tillDate");
  const [loader, setLoader] = useState(false);
  const [incubatorDetails, setIncubatorDetails] = useState<any>([]);
  const [IncubatorDashboardCount, setIncubatorDashboardCount] = useState({
    submittedStartupForms: '0',
    approvedStartupForms: '0',
    fundDisburdedToStartups: '0',

  });
  const [upcomingMeetingData, setupcomingMeetingData] = useState({
    meeting_date: '',
    meeting_from_time: '',
    meeting_to_time: '',
    meeting_id: '',
    meeting_type: ''
  });
  const [activeChartSelect, setActiveChartSelect] = useState("till_date");
  const [stateHorizontal, setStateHorizontal] = useState({
    labels: ['Submitted', 'Incomplete', 'To be Reviewed', 'On Hold', 'Rejected', 'Approved', 'Closed'],
    datasets: [
      {
        backgroundColor: '#647FC2',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        data: [1567, 124, 121, 88, 456, 998, 900]
      }
    ]
  });
  const [stateLine, setStateLine] = useState({
    // labels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20"],
    labels: [],
    datasets: [
      {
        label: 'Funds Disbursed', // Name the series
        // data: [800000, 900000, 1100000, 1300000, 2000000, 1500000, 900000, 600000, 400000, 1500000, 2400000, 1600000, 900000, 1700000, 2500000, 1200000, 1000000, 200000, 400000, 600000], // Specify the data values array
        data: [],
        fill: true,
        borderColor: '#647FC2', // Add custom color border (Line)
        backgroundColor: 'rgba(228,215,240,0.6)', // Add custom color background (Points and Fill)
        borderWidth: 1 // Specify bar border width
      },
      {
        label: 'Funds Approved', // Name the series
        // data: [1500000, 1600000, 1900000, 2400000, 3100000, 2700000, 2500000, 1300000, 900000, 3000000, 3800000, 2600000, 2000000, 2500000, 3000000, 2200000, 1500000, 900000, 600000, 500000], // Specify the data values array
        data: [],
        fill: true,
        borderColor: '#2196f3', // Add custom color border (Line)
        backgroundColor: 'rgba(83,168,226,0.1)', // Add custom color background (Points and Fill)
        borderWidth: 1 // Specify bar border width  
      },
    ]
  });
  const [InstrumentLine, setInstrumentLine] = useState({
    // labels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20"],
    labels: [],
    datasets: [
      {
        label: 'Grants',
        data: [],
        fill: true,
        borderColor: '#647FC2', // Add custom color border (Line)
        backgroundColor: 'rgba(228,215,240,0.6)', // Add custom color background (Points and Fill)
        borderWidth: 1 // Specify bar border width
      },
      {
        label: 'Debt',
        data: [],
        fill: true,
        borderColor: '#2196f3', // Add custom color border (Line)
        backgroundColor: 'rgba(83,168,226,0.1)', // Add custom color background (Points and Fill)
        borderWidth: 1 // Specify bar border width  
      },
      {
        label: 'Debenture',
        data: [],
        fill: true,
        borderColor: '#647FC2', // Add custom color border (Line)
        backgroundColor: 'rgba(228,215,240,0.6)', // Add custom color background (Points and Fill)
        borderWidth: 1 // Specify bar border width
      },
      {
        label: 'Debt-Linked',
        data: [],
        fill: true,
        borderColor: '#2196f3', // Add custom color border (Line)
        backgroundColor: 'rgba(83,168,226,0.1)', // Add custom color background (Points and Fill)
        borderWidth: 1 // Specify bar border width  
      },
    ]
  });
  const [topIncubators, setTopIncubators] = useState([]);
  const [loogedtopIncubators, setloogedtopIncubators] = useState([]);
  const [activeMainSelect, setActiveMainSelect] = useState("till_date");

  const [activeTopIncubator, setActiveTopIncubator] = useState("till_date");
  useEffect(() => {
    window.scrollTo(0, 0)
    getDashboardChartCount('till_date');
    // getChartCount('till_date');
    // getMeetingDetails();    
    // getTopIncubators('till_date');
    // getDashboardCount('till_date');
  }, []);

  const getDashboardChartCount = (duration: string) => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    var payload = {
      "user_id": userInfo.id
    }
    setLoader(true);
    post(`${GET_DPIIT_DASHBOARD_CHART_COUNT}?duration=${duration}`, payload).then((response: any) => {
      setLoader(false);
      if (!response || !response.data) return;
      if (response.data.data.length != 0) {
        setStateHorizontal({
          labels: ['Submitted', 'Incomplete', 'To be Reviewed', 'On Hold', 'Rejected', 'Selected', 'Allotted', 'Closed', 'Cancelled'],
          datasets: [
            {
              backgroundColor: '#647FC2',
              borderWidth: 1,
              hoverBackgroundColor: 'rgba(255,99,132,0.4)',
              hoverBorderColor: 'rgba(255,99,132,1)',
              data: [parseInt(response.data.data[0].submitted), parseInt(response.data.data[0].incomplete), parseInt(response.data.data[0].pending_review), parseInt(response.data.data[0].on_hold), parseInt(response.data.data[0].rejected), parseInt(response.data.data[0].selected), parseInt(response.data.data[0].Allotted), parseInt(response.data.data[0].close), parseInt(response.data.data[0].cancelled)]
            }
          ]
        })
      }
    })
    // .catch((error: any) => {
    //   console.error("ADHI");
    //   getChartCount('till_date');
    // });
    getChartCount('till_date');
    getInstrumentChartCount('till_date');
  };

  const getChartCount = (duration: string) => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    var payload = {
      "logged_user_id": userInfo.id,
      "roles": userInfo.roles
    }
    setLoader(true)
    post(`${GRAPH_NODE}?duration=${duration}`, payload).then((response: any) => {
      setLoader(false)
      if (!response || !response.data) return;
      // console.log(response.data.data)
      setStateLine({
        labels: response.data.data.labels,
        datasets: [
          {
            label: 'Funds Disbursed', // Name the series
            data: response.data.data.funds_disbursed, // Specify the data values array
            fill: true,
            borderColor: '#647FC2', // Add custom color border (Line)
            backgroundColor: 'rgba(228,215,240,0.6)', // Add custom color background (Points and Fill)
            borderWidth: 1 // Specify bar border width
          },
          {
            label: 'Funds Approved', // Name the series
            data: response.data.data.funds_approved, // Specify the data values array
            fill: true,
            borderColor: '#2196f3', // Add custom color border (Line)
            backgroundColor: 'rgba(83,168,226,0.1)', // Add custom color background (Points and Fill)
            borderWidth: 1 // Specify bar border width  
          },
        ]
      });
    });
    getMeetingDetails();
  };
  const getInstrumentChartCount = (duration: string) => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    var payload = {
      "logged_user_id": userInfo.id,
      "roles": userInfo.roles
    }
    setLoader(true);
    post(`${INSTRUMENT_ANALYSIS_GRAPH}?duration=${duration}`, payload).then((response: any) => {
      setLoader(false);
      if (!response || !response.data) return;
      // console.log(response.data.data)
      setInstrumentLine({
        labels: response.data.data.labels,
        datasets: [
          {
            label: 'Grants', // Name the series
            data: response.data.data.funding_grant, // Specify the data values array
            fill: true,
            borderColor: '#647FC2', // Add custom color border (Line)
            backgroundColor: 'rgba(228,215,240,0.6)', // Add custom color background (Points and Fill)
            borderWidth: 1 // Specify bar border width
          },
          {
            label: 'Debt', // Name the series
            data: response.data.data.funding_debt, // Specify the data values array
            fill: true,
            borderColor: '#2196f3', // Add custom color border (Line)
            backgroundColor: 'rgba(83,168,226,0.1)', // Add custom color background (Points and Fill)
            borderWidth: 1 // Specify bar border width  
          },
          {
            label: 'Debenture', // Name the series
            data: response.data.data.funding_debenture, // Specify the data values array
            fill: true,
            borderColor: '#647FC2', // Add custom color border (Line)
            backgroundColor: 'rgba(228,215,240,0.6)', // Add custom color background (Points and Fill)
            borderWidth: 1 // Specify bar border width
          },
          {
            label: 'Debt-Linked', // Name the series
            data: response.data.data.funding_debt_linked, // Specify the data values array
            fill: true,
            borderColor: '#2196f3', // Add custom color border (Line)
            backgroundColor: 'rgba(83,168,226,0.1)', // Add custom color background (Points and Fill)
            borderWidth: 1 // Specify bar border width  
          },
        ]
      });
    });
    // getMeetingDetails(); 
  };
  const getMeetingDetails = () => {
    setLoader(true);
    get(GET_MEETING_DETAILS).then((response: any) => {
      setLoader(false);
      // console.log(response.data.upcoming)
      if (!response || !response.data) return;
      setupcomingMeetingData({
        meeting_date: response.data.upcoming.length != 0 ? response.data.upcoming[0].meeting_date : '',
        meeting_id: response.data.upcoming.length != 0 ? response.data.upcoming[0].id : '',
        meeting_from_time: response.data.upcoming.length != 0 ? response.data.upcoming[0].meeting_from_time : '',
        meeting_to_time: response.data.upcoming.length != 0 ? response.data.upcoming[0].meeting_to_time : '',
        meeting_type: response.data.upcoming.length != 0 ? response.data.upcoming[0].meeting_type : ''
      });
    });
    getTopIncubators('till_date');
  };

  const getTopIncubators = (duration: string) => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    var payload = {
      "roles": userInfo.roles,
      "user_id": userInfo.id
    }
    setLoader(true);
    post(`${GET_TOP_INCUBATORS}?duration=${duration}`, payload).then((response: any) => {
      setLoader(false);
      if (!response || !response.data) return;
      setTopIncubators(response.data.data)
      setloogedtopIncubators(response.data.loggedIncData)
    })
    getDashboardCount('till_date');
  };
  const getDashboardCount = (duration: string) => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    const payload = {
      duration: duration,
      "roles": userInfo.roles,
      "logged_user_id": userInfo.id
    }
    setLoader(true);
    post(GET_DPIIT_DASHBOARD_COUNT, payload).then((response: any) => {
      setLoader(false);
      if (!response || !response.data) return;
      const dataCount = {
        submittedStartupForms: response.data.Data.submittedStartupForms,
        approvedStartupForms: response.data.Data.approvedStartupForms,
        fundDisburdedToStartups: response.data.Data.fundDisburdedToStartups,
      }
      setIncubatorDashboardCount(dataCount);
    });
  };


  const changeClassChart = (duration: string) => {
    getChartCount(duration);
    getInstrumentChartCount(duration);
    setActiveChart(duration);

  }

  const getDashboardChartCountActive = (duration: string) => {
    getDashboardChartCount(duration);
    setActiveChartSelect(duration);

  }
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const getIncubatorList = (meeting_id: any) => {
    setLoader(true);
    get(`${GET_ALL_INCUBATORS}/${meeting_id}`).then((response: any) => {
      setLoader(false);
      // console.log(response)
      // console.log(response.data)
      // console.log(response.data.meetingId)
      // console.log(response.data.meetingId,"incubatordtls")
      if (!response || !response.data) return;
      setIncubatorDetails(response.data.meetingId);
      // console.log(incubatorDetails)
    });
  }
  // const getIncubatorList = (upcomingMeetingData: any) => {  
  //   console.log(upcomingMeetingData,"asdfg");
  //   const payload={
  //     "meeting_date":upcomingMeetingData.meeting_date,
  //     "from_time":upcomingMeetingData.meeting_from_time,
  //     "to_time":upcomingMeetingData.meeting_to_time
  //   }
  //   console.log(payload,"asdfg")
  //   post(GET_ALL_INCUBATORS_NODE, payload).then(
  //     (response: any) => {
  //       if (!response || !response.data) return;
  //       // console.log(response.data.Data);
  //       setIncubatorDetails(response.data.Data);
  //     }
  //   );
  // };
  const handleDateChange = (date: Date, field: string) => {
  };
  const getDashboardCountActive = (duration: string) => {
    getDashboardCount(duration);
    setActiveMainSelect(duration);

  }
  const getTopIncubatorActive = (duration: string) => {
    getTopIncubators(duration);
    setActiveTopIncubator(duration);

  }
  return (
    <>
      {loader && (
        <div className="spinner-border custom-spinner" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}
      <fieldset disabled={loader}>
        {/* Dashboard body Component */}
        <div className="container dashboardContainer pt-5">
          <div className="row mb-3">
            <div className="col-md-6 text-left">
              <p className="dashboard pt-2">Incubator Dashboard</p>
            </div>
            <div className="col-md-6 text-right">
              <span onClick={() => getDashboardCountActive('1day')} className={activeMainSelect == '1day' ? "chartDuration  active" : "chartDuration"}>1 D</span>
              <span onClick={() => getDashboardCountActive('1week')} className={activeMainSelect == '1week' ? "chartDuration  active" : "chartDuration"}>1 W</span>
              <span onClick={() => getDashboardCountActive('1month')} className={activeMainSelect == '1month' ? "chartDuration  active" : "chartDuration"}>1 M</span>
              <span onClick={() => getDashboardCountActive('3months')} className={activeMainSelect == '3months' ? "chartDuration  active" : "chartDuration"}>3 M</span>
              <span onClick={() => getDashboardCountActive('6months')} className={activeMainSelect == '6months' ? "chartDuration  active" : "chartDuration"}>6 M</span>
              <span onClick={() => getDashboardCountActive('1year')} className={activeMainSelect == '1year' ? "chartDuration  active" : "chartDuration"}>1 Y</span>
              <span onClick={() => getDashboardCountActive('till_date')} className={activeMainSelect == 'till_date' ? "chartDuration chartDurationTill  active" : "chartDuration chartDurationTill"}>Till Date</span>
            </div>
          </div>

          <div className="row cardDiv incubatorCardDiv text-center">
            <div className="col-md-4">
              <div className="rectangle-incubator pt-3">
                <span className="incubator-numeric">{IncubatorDashboardCount.submittedStartupForms}</span>
                <br />
                <span className="incubator-applicatio ">
                  Startup Application Submitted
                </span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="rectangle-incubator pt-3">
                <span className="incubator-numeric">{IncubatorDashboardCount.approvedStartupForms}</span>
                <br />
                <span className="incubator-applicatio ">
                  Startup Application Selected
                </span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="rectangle-incubator pt-3">
                <span className="incubator-numeric">&#8377; {changeNumberFormat(Number(IncubatorDashboardCount.fundDisburdedToStartups), 1, 0)}</span>
                <br />
                <span className="incubator-applicatio ">
                  Total Funds Disbursed to Startups
                </span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-8">
                  {/* disabled */}
                  <fieldset>
                    <ul className="nav nav-tabs chart-nav" role="tablist">
                      <li className="nav-item">
                        {/* custom-disabled */}
                        <a
                          className="nav-link active "
                          data-toggle="tab"
                          href="#tabs-1"
                          role="tab"
                        >
                          Startup Analysis
                        </a>
                      </li>
                      <div className="vertical-dash"></div>
                      <li className="nav-item">
                        {/* custom-disabled */}
                        <a
                          className="nav-link"
                          data-toggle="tab"
                          href="#tabs-2"
                          role="tab"
                        >
                          Instrument Analysis
                        </a>
                      </li>
                    </ul>
                  </fieldset>
                </div>
                <div className="col-md-4 text-right pt-2 pr-4">
                  {/* <a
                    className="view-button" data-toggle="modal" data-target="#exampleModalCenter"
                    role="tab"
                  >
                    View Full Report
                  </a> */}
                </div>
                {/* custom-disabled */}
                <div className="col-md-6 ">
                  <span onClick={() => changeClassChart('1month')} className={activeChart == '1month' ? "chartDuration  active" : "chartDuration"} >1 M</span>
                  <span onClick={() => changeClassChart('3months')} className={activeChart == '3months' ? "chartDuration  active" : "chartDuration"}>3 M</span>
                  <span onClick={() => changeClassChart('6months')} className={activeChart == '6months' ? "chartDuration  active" : "chartDuration"}>6 M</span>
                  <span onClick={() => changeClassChart('1year')} className={activeChart == '1year' ? "chartDuration  active" : "chartDuration"}>1 Y</span>
                  <span onClick={() => changeClassChart('tillDate')} className={activeChart == 'tillDate' ? "chartDuration chartDurationTill  active" : "chartDuration chartDurationTill"}>Till Date</span>
                </div>
              </div>
              {/* custom-disabled */}
              <div id="dashboard_line_chart" className="tab-content lineChartDiv">
                <div className="tab-pane active" id="tabs-1">
                  {/* line */}
                  <div>
                    <Line
                      data={stateLine}
                      options={options_Line}
                    />
                  </div>

                </div>
                <div className="tab-pane" id="tabs-2">
                  {/* bar chart*/}
                  <div>
                    <Line
                      data={InstrumentLine}
                      options={options_Line_inst}
                    />
                  </div>

                </div>
              </div>
            </div>
            {/* <div className="col-md-2">
            <a
              className="view-full-report"
              data-toggle="tab"
              href="#pop-up"
              role="tab"
            >
              View Full Report
            </a>
          </div> */}
            <div className="col-md-4" style={{display:'none'}}>
              <div className="scheduleMeeting">
                <div className="row pt-2">
                  <div className="col-md-8 pl-3 mx-2">
                    <p className="upcoming-meeting ml-4">Upcoming Meeting</p>
                  </div>
                  <div className="col-md-4 text-right pr-4">

                    {/* <a
                  className="view-all"
                  data-toggle="tab"
                  href="#pop-up"
                  role="tab"
                >
                  View All
                </a> */}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 centerText pt-4">
                    <img
                      src={ScheduleMeeting}
                      className="img-fluid"
                      alt="Schedule Logo"
                    />
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-md-12 centerText">
                    <br />
                    {console.log("@@@@@@@@@", upcomingMeetingData)}
                    <div className="upcomingMeetingClick" onClick={() => getIncubatorList(upcomingMeetingData.meeting_id)} data-toggle="modal" data-target="#quickMeetingEdit">
                      <span className="eacDateNumber">{upcomingMeetingData.meeting_date != '' ? new Date(upcomingMeetingData.meeting_date).getDate() : null}</span>
                      <span className="eacDateMonth">{upcomingMeetingData.meeting_date != '' ? monthNames[new Date(upcomingMeetingData.meeting_date).getMonth()] : null}</span>


                      <br />

                      {upcomingMeetingData.meeting_date != '' ? <><span className="eacMeetTime">{formatTime(upcomingMeetingData.meeting_from_time)} - {formatTime(upcomingMeetingData.meeting_to_time)}</span><img className="blue-arrow" src={schedulemeetingarrow} alt="Schedule Arrow.." /></> : null}
                    </div>
                    {upcomingMeetingData.meeting_date != '' ? <span className="eac-performance-revi">
                      {upcomingMeetingData.meeting_type == "2" ? "EAC Performance Review Meeting" : "Incubator Selection Meeting"}
                      <br />
                      <br />
                    </span> : <span className="eac-performance-revi">
                      No Upcoming Meetings
                      <br />
                      <br />
                    </span>}
                    <Link className="view-all" to="/incubator_meeting">
                      View All
                    </Link>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="row mt-3">
            <div className="col-md-8">
              <div className="rectangle-incubator-bar">
                <div className="row mb-3">
                  <div className="col-md-8 pl-4 pt-4">
                    <span className="incubator-applicatio ml-2">
                      Startup Applications
                    </span>
                  </div>
                  {/* <div className="col-md-4 pt-2 text-right pr-4">
                  <a
                    className="col-md-4 view-all"
                    data-toggle="tab"
                    href="#pop-up"
                    role="tab"
                  >
                    View All
              </a>
                </div> */}
                </div>

                <div className="pl-2">

                  <span onClick={() => getDashboardChartCountActive('1month')} className={activeChartSelect == '1month' ? "chartDuration  active" : "chartDuration"}>1M</span>
                  <span onClick={() => getDashboardChartCountActive('3months')} className={activeChartSelect == '3months' ? "chartDuration  active" : "chartDuration"}>3M</span>
                  <span onClick={() => getDashboardChartCountActive('6months')} className={activeChartSelect == '6months' ? "chartDuration  active" : "chartDuration"}>6M</span>
                  <span onClick={() => getDashboardChartCountActive('1year')} className={activeChartSelect == '1year' ? "chartDuration  active" : "chartDuration"}>1Y</span>
                  <span onClick={() => getDashboardChartCountActive('till_date')} className={activeChartSelect == 'till_date' ? "chartDuration chartDurationTill active" : "chartDuration chartDurationTill"}>Till Date</span>
                </div>
                <div className="hori-bar pt-4 pl-2 pr-2">
                  <HorizontalBar data={stateHorizontal} options={options_horizontal} />
                </div>
              </div>
            </div>



            <div className=" col-md-4 ">
              <fieldset >
                <div className="rectangle-incubator-bar">
                  <div className="row mb-3">
                    <div className="col-md-8 pl-4 pt-4">
                      <span className="incubator-applicatio ml-2">
                        Top Incubators
                      </span>
                    </div>
                  </div>

                  <div className="pl-2 mb-4">

                    <span onClick={() => getTopIncubatorActive('1month')} className={activeTopIncubator == '1month' ? "chartDuration  active" : "chartDuration"}>1M</span>
                    <span onClick={() => getTopIncubatorActive('3months')} className={activeTopIncubator == '3months' ? "chartDuration  active" : "chartDuration"}>3M</span>
                    <span onClick={() => getTopIncubatorActive('6months')} className={activeTopIncubator == '6months' ? "chartDuration  active" : "chartDuration"}>6M</span>
                    <span onClick={() => getTopIncubatorActive('1year')} className={activeTopIncubator == '1year' ? "chartDuration  active" : "chartDuration"}>1Y</span>
                    <span onClick={() => getTopIncubatorActive('till_date')} className={activeTopIncubator == 'till_date' ? "chartDuration chartDurationTill  active" : "chartDuration chartDurationTill"}>Till Date</span>
                  </div>
                  <div className="hori-bar pt-4 pl-2 pr-2">
                    {topIncubators.length != 0 ? topIncubators && topIncubators.map((item: any, index: any) => (
                      <><div className="row pr-5 pl-3 pb-3" key={index}>
                        <div className="col-md-10">
                          <span className="burns-industries">{item.incubator_name}</span>
                          <br />
                          <span className="mumbai-maharashtra">

                          </span>
                        </div>
                        <div className="col-md-2">
                          <span className="card-count pl-4">{item.startup_count}</span>

                          <br />
                          <span className="mumbai-maharashtra">Startups</span>
                        </div>
                      </div>
                        <div className="hr"><hr /></div></>

                    )) : <div className="row pr-5 pl-3 pb-3">
                      <div className="col-md-10">
                        <span className="burns-industries">No top incubator's</span>

                      </div>

                    </div>}


                    {loogedtopIncubators.length != 0 ? loogedtopIncubators && loogedtopIncubators.map((item: any, index: any) => (
                      <>
                        <div className="row pr-5 pl-3 pb-3 topIncSelf" key={index}>
                          <div className="col-md-10">
                            <span className="burns-industries" style={{ color: '#25429c' }}><b>{item.incubator_name}</b></span>
                            <br />
                            <span className="mumbai-maharashtra">

                            </span>
                          </div>
                          <div className="col-md-2">
                            <span className="card-count pl-4" style={{ color: '#25429c' }}><b>{item.startup_count}</b></span>

                            <br />
                            <span className="mumbai-maharashtra">Startups</span>
                          </div>
                        </div>
                        <div className="hr"><hr /></div></>

                    )) : <div className="row pr-5 pl-3 pb-3">
                      <div className="col-md-10">
                        <span className="burns-industries">No Supporting Startup's</span>

                      </div>

                    </div>}

                    {/* <div className="row pr-5 pl-3 pb-3">
                  <div className="col-md-10">
                    <span className="burns-industries">Burns Industries</span>
                    <br />
                    <span className="mumbai-maharashtra">
                      Mumbai, Maharashtra
                  </span>
                  </div>
                  <div className="col-md-2">
                    <span className="card-count pl-4">41</span>

                    <br />
                    <span className="mumbai-maharashtra">Startups</span>
                  </div>
                </div>
                <div className="hr"><hr /></div>
                <div className="row  pr-5 pl-3 pb-3">
                  <div className="col-md-10">
                    <span className="burns-industries">Dunder Mifflin, Inc.</span>
                    <br />
                    <span className="mumbai-maharashtra">
                      Chennai, Tamil Nadu
                  </span>
                  </div>
                  <div className="col-md-2">
                    <span className="card-count pl-4">33</span>

                    <br />
                    <span className="mumbai-maharashtra">Startups</span>
                  </div>
                </div>
                <div className="hr"><hr /></div>
                <div className="row pr-5 pl-3 pb-3">
                  <div className="col-md-10">
                    <span className="burns-industries">Stark Industries</span>
                    <br />
                    <span className="mumbai-maharashtra">
                      Mumbai, Maharashtra
                  </span>
                  </div>
                  <div className="col-md-2">
                    <span className="card-count pl-4">29</span>

                    <br />
                    <span className="mumbai-maharashtra">Startups</span>
                  </div>
                </div>
                <div className="hr"><hr /></div>
                <div className="row pr-5 pl-3 pb-3">
                  <div className="col-md-10">
                    <span className="burns-industries">
                      Green Earth Planet Ecosystems
                  </span>
                    <br />
                    <span className="mumbai-maharashtra">
                      Banglaru, Maharashtra
                  </span>
                  </div>
                  <div className="col-md-2">
                    <span className="card-count pl-4">15</span>

                    <br />
                    <span className="mumbai-maharashtra">Startups</span>
                  </div>
                </div> */}
                  </div>
                </div>
              </fieldset>
            </div>

          </div>
          {/* <!-- Tab panes --> */}
          {/* <div className="tab-content"> */}
          {/*           
                <DashboardHeaderComponent />
               
                <DashboardFooterComponent /> */}
          {/* </div> */}
        </div>

        {/* Modal */}
        <FullIncubatorDetailModal role={"incubator"} />
        {/* quick meeting popop */}
        <div className="modal fade" id="quickMeetingEdit" role="dialog" aria-labelledby="exampleModalLabel1" aria-hidden="true">
          <div className="modal-dialog modal-dialog-slideout modal-md" role="document">
            <div className="modal-content">
              <div className="modal-header modal-header-bottom pr-5">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body pl-5 pr-5 pt-5">
                <div className="popup-head mb-5 ml-3">
                  <div className="main-heading">{upcomingMeetingData.meeting_type == "2" ? "EAC Performance Review Meeting" : "Incubator Selection Meeting"}</div>

                </div>



                <label className="col-sm-4 col-form-head">
                  Date
                </label>
                <div className="col-md-6 mb-4">
                  <fieldset disabled={true}>
                    <Calendar
                      id="registrationDate"
                      name="registrationDate"
                      className="form-control input-height"
                      format="dd/MM/yyyy"
                      selectedDate={upcomingMeetingData.meeting_date ? upcomingMeetingData.meeting_date : new Date()}
                      onDateChange={(date: Date, field: string) =>
                        handleDateChange(date, field)
                      }
                    />
                  </fieldset>
                </div>
                <div className="row pl-3 mb-5">
                  <div className="col-md-6">
                    <label className="col-form-head">
                      From Time
                    </label>
                    <input type="time" disabled value={upcomingMeetingData.meeting_from_time} name="fromTime" className="form-control input-height" />
                  </div>
                  <div className="col-md-6">
                    <label className="col-form-head">
                      To Time
                    </label>
                    <input type="time" disabled value={upcomingMeetingData.meeting_to_time} name="toTime" className="form-control input-height" />
                  </div>
                </div>
                <div className="list-dashboard pl-3">
                  <label className="col-form-head">
                    {incubatorDetails.length + " Incubators"}
                  </label>
                  <div className="list-scroll">
                    <ul>
                      {incubatorDetails.map((item: any, index: any) => (
                        <li key={index}>
                          <span className="tableDataHead">{item.incubator_application_name}</span>
                          <br />
                          <span className="tableDataApplication">
                            {"No: " + item.incubator_application_id}
                          </span>
                        </li>
                      ))}

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </>
  );
}
export default IncubatorDashboardContainer;
