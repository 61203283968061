
import * as React from 'react';
import "../../../styles/component.css";
import { GET_MEETINGS, GET_ALL_INCUBATORS, REMOVE_INCUBATOR, GET_STARTUP_MEETINGS } from "../../../constants/api";
import { get, post } from '../../../effects/api/api.common';
import { useEffect, useState } from 'react';
import Calendar from '../../common/controls/Calendar';
import { Link, useHistory } from 'react-router-dom';
import CalendarIcon from '../../../images/calendar.svg';
import download from '../../../images/download.svg';
import search from '../../../images/search.svg';
import { FormatDate } from '../../../utils/DateFormat'
import { formatTime } from '../../../utils/DateFormat'
import { filterByValue } from '../../../utils/CommonMethods';
import { getSessionStorageValue } from '../../../effects/utils/session-storage';


function StartupMeetingContainer() {
    const history = useHistory();
    const [upcomingMeetingData, setupcomingMeetingData] = useState([]);
    const [filteredUpcomingMeetingData, setFilteredUpcomingMeetingData] = useState([]);
    const [pastMeetingData, setPastMeetingData] = useState([]);
    const [filteredPastMeetingData, setFilteredPastMeetingData] = useState([]);
    const [incubatorDetails, setIncubatorDetails] = useState([]);
    const [modalDate, setModalDate] = useState();
    const [modalTime, setModalTime] = useState();
    const [meetingID, setMeetingID] = useState();
    const [noDataFilter, setNoDataFilter] = useState('');

    useEffect(() => {
        getMeetingDetails();
    }, []);
    // get states in dropdown
    const getMeetingDetails = () => {
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user)
        var payload ={
          "userRole": userInfo.roles,
          "logged_user_id":userInfo.id
        } 
        post(GET_STARTUP_MEETINGS,payload).then((response: any) => {
            if (!response || !response.data) return;
            setupcomingMeetingData(response.data.data.upcoming);
            // setFilteredUpcomingMeetingData([]);
            setPastMeetingData(response.data.data.past);
            // setFilteredPastMeetingData([])
        });
    };
    // const getIncubatorDetails = (id: any, date: any, time: any) => {
    //     get(`${GET_ALL_INCUBATORS}?meeting_id=${id}`).then((response: any) => {
    //         if (!response || !response.data) return;
    //         console.log(response.data)
    //         setIncubatorDetails(response.data.result.data)
    //         setModalDate(date);
    //         setModalTime(time);
    //         setMeetingID(id);
    //     });
    // };


    const handleRowChange = (id: any) => {
        history.replace({
            pathname: '/eac_view_incubator',
            state: { application_id: id },
        });
    };

    // const evaluate = (id: any, count: any, date: any, time: any) => {
    //     history.replace({
    //         pathname: '/view_selection_meeting',
    //         state: { meeting_id: id, incubator_count: count, time_string: time, date_string: date }
    //     });
    // };
    const handleDateChange = (date: Date, field: string) => {
    };

    const searchList = (e: any) => {
        setNoDataFilter(e.target.value)
        const filteredData = filterByValue(upcomingMeetingData, e.target.value);
        const filteredPastData = filterByValue(pastMeetingData, e.target.value);
        setFilteredUpcomingMeetingData(filteredData)
        setFilteredPastMeetingData(filteredPastData)
    }
    return (
        <>
            <div className="container container-min pt-5">
                <div className="row mb-4">
                    <div className="col-md-6">
                        <p className="dashboard pt-2 pl-3">Meetings</p>
                    </div>
                </div>

                <div className="row startup-heading-review">
                    <div className="col-md-5 text-left">
                        <ul className="nav nav-tabs chart-nav" role="tablist">
                            <li className="nav-item">
                                <a
                                    className="nav-link active"
                                    data-toggle="tab"
                                    href="#tabs-1"
                                    role="tab"
                                >
                                    Upcoming Meetings
                </a>
                            </li>
                            <div className="vertical-dash"></div>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    data-toggle="tab"
                                    href="#tabs-2"
                                    role="tab"
                                >
                                    Past Meetings
                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-7 text-right search-left">
                        <div className="ml-auto text-right-wrap">
                            {/* <label className="link-orange-text link-underline d-inline-flex">Download Incubator Deck</label> */}

                            <div className="d-inline-flex searchDiv"><img
                                src={search}
                                className="search"
                                alt="search"

                            />
                                <input
                                    className="searchBar"
                                    type="text"
                                    placeholder="Search.."
                                    name="search"
                                    onKeyUp={searchList}
                                />
                                {/* <img src={CalendarIcon} className="calendar-icon review-calendar-icon" alt="Calendar icon to select date" /> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-content mb-4 mt-4">
                    <div className="tab-pane active" id="tabs-1">
                        <div className="table-scroll">
                            <table className="reviewTable">
                                <thead>
                                    <tr>
                                        <th className="text-left table-heading custom-td-left table-heading ">Meeting Type</th>
                                        <th className="text-left table-heading">Date & Time</th>
                                        {/* <th className="text-left table-heading">Date Of Application Submitted</th> */}

                                    </tr>
                                </thead>
                                <tbody>
                                    {upcomingMeetingData.length == 0 || (filteredUpcomingMeetingData.length == 0 && noDataFilter != '') ? <tr><td className="text-center" colSpan={5}>No data</td></tr> :
                                        (filteredUpcomingMeetingData.length == 0 ?
                                            upcomingMeetingData.map((item: any, index:any) => (
                                                <tr key={index}>
                                                    <td className="text-left custom-td-left">{item.meeting_type_name}<br />{item.incubator_name}</td>
                                                    <td className="text-left custom-td"><div >{FormatDate(item.meeting_date)}</div><span className="tableDataApplication">{formatTime(item.meeting_from_time)} - {formatTime(item.meeting_to_time)}</span></td>
                                                    {/* <td className="text-left custom-td">{item.application_submitted_date    }</td> */}

                                                </tr>
                                            )) :
                                            filteredUpcomingMeetingData.map((item: any, index:any) => (
                                                <tr key={index}>
                                                    <td className="text-left custom-td-left">{item.meeting_type_name}<br />{item.incubator_name}</td>
                                                    <td className="text-left custom-td"><div >{FormatDate(item.meeting_date)}</div><span className="tableDataApplication">{formatTime(item.meeting_from_time)} - {formatTime(item.meeting_to_time)}</span></td>
                                                    {/* <td className="text-left custom-td">{item.application_submitted_date}</td> */}

                                                </tr>
                                            ))
                                        )}
                                    {/* <tr>
                                                <td className="text-left custom-td-left">Incubator Selection</td>
                                                <td className="text-left custom-td"><div >12 March 2021</div><span className="tableDataApplication">10:00am - 11:00pm</span></td>
                                                <td className="text-left custom-td">12-mar-2020</td>
                                                <td></td>
                                                <td className="text-right"></td>
                                            </tr> */}


                                </tbody>

                            </table>
                        </div>


                    </div>
                    <div className="tab-pane" id="tabs-2">
                        <div className="table-scroll mb-5">
                            <table className="reviewTable">
                                <thead>
                                    <tr>
                                        <th className="text-left table-heading custom-td-left table-heading">Meeting Type</th>
                                        <th className="text-left table-heading">Date & Time</th>
                                        {/* <th className="text-left">Funds Approved (&#8377;)</th>
                                        <th className="text-center">Status</th> */}
                                        <th className="text-left table-heading">Next Performance Review Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pastMeetingData.length == 0 || (filteredPastMeetingData.length == 0 && noDataFilter != '') ? <tr><td className="text-center" colSpan={5}>No data</td></tr> :
                                        (filteredPastMeetingData.length == 0 ?
                                            pastMeetingData.map((item: any, index:any) => (
                                                <tr key={index}>
                                                    <td className="text-left custom-td-left">{item.meeting_type_name}<br />{item.incubator_name}</td>
                                                    <td className="text-left custom-td"><div >{FormatDate(item.meeting_date)}</div><span className="tableDataApplication">{formatTime(item.meeting_from_time)} - {formatTime(item.meeting_to_time)}</span></td>
                                                    {/* <td className="text-left">{item.funds_approved}<br />{item.no_tranches + ' Tranches'}</td>
                                                    <td className="text-center"><div className="badge-grey">{item.meeting_status}</div>
                                                        <span className="tableDataApplication">
                                                            {item.meeting_status_change_date}
                                                        </span></td> */}
                                                    <td className="text-left custom-td">{FormatDate(item.next_meeting_date)}</td>
                                                </tr>
                                            )) :
                                            filteredPastMeetingData.map((item: any, index:any) => (
                                                <tr key={index}>
                                                    <td className="text-left custom-td-left">{item.meeting_type_name}<br />{item.incubator_name}</td>
                                                    <td className="text-left custom-td"><div >{FormatDate(item.meeting_date)}</div><span className="tableDataApplication">{formatTime(item.meeting_from_time)} - {formatTime(item.meeting_to_time)}</span></td>
                                                    {/* <td className="text-left">{item.funds_approved}<br />{item.no_tranches + ' Tranches'}</td>
                                                    <td className="text-center"><div className="badge-grey">{item.meeting_status}</div>
                                                        <span className="tableDataApplication">
                                                            {item.meeting_status_change_date}
                                                        </span></td> */}
                                                    <td className="text-left custom-td">{FormatDate(item.next_meeting_date)}</td>
                                                </tr>
                                            ))

                                        )}
                                    {/* <tr>
                                                <td className="text-left">"Incubator Selection"</td>
                                                <td className="text-left custom-td"><div >14 Mar 2010</div><span className="tableDataApplication">10:00pm - 9:00pm</span></td>
                                                {/* <td className="text-center"><span className="status-badge green-badge">14 Mar 2010</span><br/><span className="tableDataApplication">10:00pm - 9:00pm</span></td> 
                                                <td className="text-left custom-td">12 march 2021</td>
                                            </tr> */}


                                </tbody>

                            </table>
                        </div>

                    </div>
                </div>
                {/* Modal */}
                {/* <div className="modal fade" id="viewIncubator" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header modal-header-bottom mt-3 mb-3">
                                <h5 className="modal-title pl-2" id="exampleModalLongTitle">Incubators <span>(Selection Meeting)</span></h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-6 text-left">
                                            <span className="modal-text">Date & Time</span><br />
                                            <span className="modal-text">{FormatDate(modalDate)} | {modalTime}</span>
                                        </div>



                                        <div className="col-md-6 text-right">
                                            <div className="d-flex searchDiv"> <img
                                                src={search}
                                                className="search"
                                                alt="search"

                                            />
                                                <input
                                                    className="searchBar"
                                                    type="text"
                                                    placeholder="Search.."
                                                    name="search"
                                                /></div>
                                        </div>

                                        <div className="table-scroll mb-5">
                                            <table id="example" >
                                                <thead>
                                                    <tr>
                                                        <th className="custom-td-left">Incubator</th>
                                                        <th className="text-left">Application Date</th>
                                                        <th className="text-left">No. Of Statrtup Supported</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {incubatorDetails.map((item: any) => (
                                                        <tr>
                                                            <td className="text-left custom-td-left" onClick={() => handleRowChange(item.incubator_application_id)} data-dismiss="modal"><u className="link-orange-text">{item.incubator_application_name}</u></td>
                                                            <td className="text-left">{item.incubator_application_submitted_date}</td>
                                                            <td className="text-left">32</td>

                                                            <td className="text-left"></td>

                                                        </tr>
                                                    ))}




                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="row pt-3 mb-3">
                                    <div className="col-md-12 centerText">
                                        <button className="tertiary  btn-yellow-primary" data-dismiss="modal">
                                            OK
                </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* schedule a meeting tab */}
                <div className="modal fade" id="scheduleMeeting" role="dialog" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-slideout modal-sm" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body pl-5 pr-5 pt-5">
                                <div className="popup-head mb-5 ml-3">
                                    <div className="main-heading">Schedule a Meeting</div>
                                    <div className="count-text">2 Incubators Selected</div>
                                </div>

                                <label className="col-sm-4 col-form-head">
                                    Meeting Type
                        </label>
                                <div className="col-md-12 mb-4">
                                    <select className="form-control" aria-label="Select Entity Type" >
                                        <option value="1">Incubator Selection</option>
                                        <option value="2">Performance Review Meeting</option>
                                    </select>
                                </div>
                                <label className="col-sm-4 col-form-head">
                                    Date & Time
                        </label>
                                <div className="col-md-6 mb-4">
                                    <Calendar
                                        id="registrationDate"
                                        name="registrationDate"
                                        className="form-control"
                                        format="dd/MM/yyyy"
                                        selectedDate={new Date()}
                                        onDateChange={(date: Date, field: string) =>
                                            handleDateChange(date, field)
                                        }
                                    />
                                </div>
                                <div className="row pl-3 mb-5">
                                    <div className="col-md-6">
                                        <label className="col-form-head">
                                            From Time
                        </label>
                                        <input type="time" name="fromTime" className="form-control" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="col-form-head">
                                            To Time
                        </label>
                                        <input type="time" name="toTime" className="form-control" />
                                    </div>
                                </div>
                                <div className="row pl-5">
                                    <button className="btn btn-sisfs btn-transparent-primary" data-dismiss="modal">
                                        CANCEL
              </button>
                                    <button className="tertiary  btn-yellow-primary ml-3" >
                                        SCHEDULE
                </button>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default StartupMeetingContainer;
