import React from 'react';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import useAuth from './Routelock';
interface RequireAuthProps {
    children: React.ReactNode;
}

interface AuthContextType {
    auth: any;  // or whatever type `auth` is in your `useAuth` hook.
}



const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
    const { auth } = useAuth() as AuthContextType;  // Add a type assertion if useAuth does not have its return type defined.
    const location = useLocation();
    const history = useHistory();

    if (auth) {
        return <>{children}</>;
    } else {
        // alert('Please Login');
        history.replace('/home');  // This line ensures the navigation replaces the current entry in the history stack
        return <Redirect to={{
            pathname: '/home',
            state: { from: location }
        }} />;
    }
}

export default RequireAuth;
