import { useEffect, useState } from "react";
import { IQuestionModel, Question } from "../../../models/eac/QuestionModel";
import { IReviewStepProps } from "./ReviewStepWrapperComponent";

interface IReviewScoreModel {
  questions: IQuestionModel;
  onReviewSubmit: (questionModel: any) => void;
}

function SubmitQuestionSummary(questions: any) {
  let question = questions.questions;
  return (
    <>
      <ol>
        {question &&
          Object.keys(question).map((key: string) => (
            <>
              {question[key] && (
                <li>
                  <h6>{question[key].label}</h6>
                  <span>
                    {isNaN(
                      question[key].question
                        .map((quest: any) =>
                          quest.weighted_score
                            ? parseFloat(quest.weighted_score)
                            : 0
                        )
                        .reduce((a: any, b: any) => a + b, 0)
                    )
                      ? 0
                      : question[key].question
                          .map((quest: any) =>
                            quest.weighted_score
                              ? parseFloat(quest.weighted_score)
                              : 0
                          )
                          .reduce((a: any, b: any) => a + b, 0)
                          ?.toFixed(2)}
                  </span>
                </li>
              )}
            </>
          ))}
      </ol>
    </>
  );
}

/** Renders the  review summary modal dialog */
function ReviewScoreModel(props: IReviewScoreModel) {
  const { questions, onReviewSubmit } = props;

  // calculate the aggregated sum for question weights
  const calculateWeight = () => {
    const totalWeight = Object.keys(props.questions)
      .map((key: string) =>
        props.questions[key].question
          .map((quest: Question) =>
            quest.weighted_score ? parseFloat(quest.weighted_score) : 0
          )
          .reduce((a, b) => a + b, 0)
      )
      .reduce((a, b) => a + b, 0);

    return totalWeight.toFixed(2);
  };

  return (
    <div
      className="modal fade"
      id="ReviewScoreModal"
      role="dialog"
      aria-labelledby="ReviewScoreModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-slideout modal-md" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div className="pl-4">
              <h5>{calculateWeight()}</h5>
              <span>Total Score (Out of 5)</span>
            </div>
            <button
              type="button"
              className="close pr-5"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div className="modal-body">
            <div className="score-list">
              {questions && <SubmitQuestionSummary questions={questions} />}
            </div>
          </div>

          <div className="modal-footer">
            <button
              className="btn btn-sisfs btn-yellow-primary mr-auto ml-4"
              onClick={() => props.onReviewSubmit(questions)}
              data-dismiss="modal"
            >
              Submit Score
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewScoreModel;
