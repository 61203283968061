import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  BsFileEarmarkExcelFill,
  BsClockFill,
  BsListStars,
} from "react-icons/bs";
import Calendar from "../common/controls/Calendar";
import { MONTHLY_REPORT, VIEW_HISTORY, NODE_URL } from "../../constants/api";
import { post, get } from "../../effects/api/api.common";
import { getSessionStorageValue } from "../../effects/utils/session-storage";
interface HistoryItem {
  i_ts?: string; // Adjust this to the actual property name in your response
  // Add other properties that exist on your items here
}

export default function MonthlyReportComponent() {
  const [selectedDate, setSelectedDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [error, setError] = useState({
    startDate: false,
    endDate: false,
  });
  const [MonthlyReportData, setMonthlyReportData] = useState([]);
  const [showmesage, setshowmesage] = useState(false);
  const [ViewHistoryData, setViewHistoryData] = useState([]);
  const [lastItem, setLastItem] = useState<HistoryItem | null>(null); // Properly typed

  const handleDateChange = (date: any, dateType: "startDate" | "endDate") => {
    setSelectedDate((prevDates) => ({
      ...prevDates,
      [dateType]: date,
    }));
    if (dateType === "startDate" && date)
      setError((prev) => ({ ...prev, startDate: false }));
    if (dateType === "endDate" && date)
      setError((prev) => ({ ...prev, endDate: false }));
  };
  const formatDate = (date: any) => {
    if (!date) return null;
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const user: any = getSessionStorageValue("user");
  const userInfo = JSON.parse(user);
  const validateAndFetchReport = () => {
    // Check if both dates are selected
    const { startDate, endDate } = selectedDate;
    let hasError = false;

    if (!startDate) {
      setError((prev) => ({ ...prev, startDate: true }));
      hasError = true;
    }
    if (!endDate) {
      setError((prev) => ({ ...prev, endDate: true }));
      hasError = true;
    }

    // If no validation errors, call the API
    if (!hasError) {
      fetchMonthlyReport(startDate, endDate);
    }
  };
  const fetchMonthlyReport = async (startDate: any, endDate: any) => {
    const payload = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      logged_user_id: userInfo.id,
    };

    try {
      const response: any = await post(MONTHLY_REPORT, payload);

      if (!response || !response.data) {
        setshowmesage(true); // Show message if response is empty or undefined
        return;
      }
      if (
        response.result === "Success" &&
        response.message === "No data available for the selected period"
      ) {
        setshowmesage(true); // Set message to show if no data is available
        return;
      }
      const filePath = response.data.filePath;
      if (!filePath) {
        setshowmesage(true); // Show message if filePath is undefined
        return;
      }
      setMonthlyReportData(filePath);
      console.log(filePath);

      // Fetch the file as a Blob
      const fileResponse = await fetch(`${NODE_URL}/${filePath}`, {
        method: "GET",
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // Specify content type
        },
      });

      if (!fileResponse.ok) {
        throw new Error("Failed to download file");
      }

      const blob = await fileResponse.blob();
      const downloadLink = document.createElement("a");
      const url = window.URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = `Startup_Monthly_Report.xlsx`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(url);
      setshowmesage(false);
    } catch (error) {
      console.error("Error fetching monthly report:", error);
      setshowmesage(true);
    }
  };

  const fetchLastMonthReport = async () => {
    const today = new Date();
    // Get the previous month
    const lastMonth = today.getMonth() === 0 ? 11 : today.getMonth() - 1; // January is 0
    const year =
      lastMonth === 11 ? today.getFullYear() - 1 : today.getFullYear();
    // Get the first day of the previous month
    const startDate = new Date(year, lastMonth, 1);
    // Get the last day of the previous month
    const endDate = new Date(year, lastMonth + 1, 0); // 0 gives the last day of the month
    // Format the dates
    const payload = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      logged_user_id: userInfo.id,
    };
    console.log(payload);

    try {
      const response: any = await post(MONTHLY_REPORT, payload);

      if (!response || !response.data) {
        setshowmesage(true); // Show message if response is empty or undefined
        return;
      }

      // Check for no data available
      if (
        response.result === "Success" &&
        response.message === "No data available for the selected period"
      ) {
        setshowmesage(true); // Set message to show if no data is available
        return;
      }

      const filePath = response.data.filePath;
      if (!filePath) {
        setshowmesage(true); // Show message if filePath is undefined
        return;
      }
      setMonthlyReportData(filePath);
      console.log(filePath);

      // Fetch the file as a Blob
      const fileResponse = await fetch(`${NODE_URL}/${filePath}`, {
        method: "GET",
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // Specify content type
        },
      });

      if (!fileResponse.ok) {
        throw new Error("Failed to download file");
      }

      const blob = await fileResponse.blob();
      const downloadLink = document.createElement("a");
      const url = window.URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = `Startup_Monthly_Report.xlsx`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(url);

      // Optionally, you can set the message state to false since data was retrieved
      setshowmesage(false);
    } catch (error) {
      console.error("Error fetching monthly report:", error);
      setshowmesage(true); // Show message on error as well
    }
  };

  useEffect(() => {
    ViewHistory();
  }, []);
  const ViewHistory = () => {
    get(VIEW_HISTORY).then((response: any) => {
      if (!response || !response.data) return;
      console.log(response.data.data);
      const historyData = response.data.data;

      if (response?.data?.result === "success") {
        setViewHistoryData(historyData);
        // Get the last item directly from the response data, not the state
        const lastItemFromResponse =
          historyData.length > 0 ? historyData.slice(-1)[0] : null;
        setLastItem(lastItemFromResponse); // Set the last item in the state
      } else {
        setViewHistoryData([]);
        setLastItem(null);
      }
    });
  };
  const formatDate1 = (timestamp: any) => {
    const date = new Date(timestamp);

    // Get components of the date and time using UTC
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getUTCDate()).padStart(2, "0");
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");

    // Return formatted date and time in UTC
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  return (
    <>
      <br />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 text-left my-auto mt-3">
            <p className="dashboard mb-0 ml-1">
              <Link
                to="/secretariatreports"
                style={{
                  textDecoration: "none",
                  display: "inline",
                }}
              >
                <span style={{ textDecoration: "underline" }}>Back</span>
              </Link>
            </p>
          </div>

          <div className="col-md-6 text-right mt-3">
            <div className="d-flex justify-content-end">
              <p className="mb-0" style={{ color: "#254297" }}>
                <b>
                  {" "}
                  <BsClockFill /> &nbsp; Last Report Generated On: &nbsp;
                </b>
                <span style={{ fontWeight: 600 }}>
                  {" "}
                  {lastItem ? formatDate1(lastItem.i_ts) : "No Data Available"}
                </span>
              </p>
            </div>
          </div>
        </div>
        <br /> <br />
        <div className="row col-md-12 justify-content-between align-items-center">
          <div className="col-md-6">
            <div className="d-flex justify-content-between align-items-center">
              <div className="mb-3 me-2 flex-grow-1">
                <label
                  htmlFor="startDate"
                  className="form-label"
                  style={{ fontWeight: "600" }}
                >
                  Start Date
                </label>
                <div>
                  <Calendar
                    id="startDate"
                    name="startDate"
                    className="form-control input-height"
                    format="dd/MM/yyyy"
                    maxDate={new Date()}
                    selectedDate={selectedDate.startDate}
                    onDateChange={(date) => handleDateChange(date, "startDate")}
                  />
                  {error.startDate && (
                    <span className="text-danger">Start Date is required.</span>
                  )}
                </div>
              </div>
              <div className="mb-3 me-2 flex-grow-1 ml-2">
                <label
                  htmlFor="endDate"
                  className="form-label"
                  style={{ fontWeight: "600" }}
                >
                  End Date
                </label>
                <div>
                  <Calendar
                    id="endDate"
                    name="endDate"
                    className="form-control input-height"
                    format="dd/MM/yyyy"
                    minDate={selectedDate.startDate || null}
                    maxDate={new Date()}
                    selectedDate={selectedDate.endDate}
                    onDateChange={(date) => handleDateChange(date, "endDate")}
                  />
                  {error.endDate && (
                    <span className="text-danger">End Date is required.</span>
                  )}
                </div>
              </div>

              {/* Generate Report Button */}
              <button
                className="btn   btn-yellow-primary mt-3  ml-2 flex-shrink-0"
                style={{
                  borderRadius: "10px",
                  color: "#25429c",
                  fontWeight: "700",
                  fontSize: "13px",
                }}
                onClick={validateAndFetchReport}
              >
                <BsFileEarmarkExcelFill
                  style={{
                    width: "24px",
                    height: "auto",
                    marginRight: "8px",
                    borderRadius: "8px !important",
                  }}
                />
                Generate Report
              </button>
            </div>
          </div>

          <div className="col-md-3 text-center mt-3">
            <button
              className="btn  btn-yellow-primary"
              style={{
                borderRadius: "10px",
                color: "#25429c",
                fontWeight: "700",
                fontSize: "13px",
              }}
              data-target="#ViewHistoryPopUp"
              data-toggle="modal"
            >
              <BsListStars
                style={{
                  width: "24px",
                  height: "auto",
                  marginRight: "8px",
                }}
              />
              View History
            </button>
          </div>
          <div className="col-md-3 text-center mt-3">
            <button
              className="btn  btn-yellow-primary"
              style={{
                borderRadius: "10px",
                color: "#25429c",
                fontWeight: "700",
                fontSize: "13px",
              }}
              onClick={fetchLastMonthReport}
            >
              <BsFileEarmarkExcelFill
                style={{ width: "24px", height: "auto", marginRight: "8px" }}
              />
              Last Month Report
            </button>
          </div>
        </div>
        <br />
        <div className="app-inside-content">
          <div className="d-flex flex-wrap">
            {[
              "Application No.",
              "Name of Startup",
              "Recognition Number",
              "Submitted Date",
              "Evaluation Date",
              "Final Status",
              "Sector",
              "Industry",
              "City",
              "State",
              "Associated Incubator",
              "Funding Instrument",
              "Total Funds Approved",
              "Total Funds Disbursed",
              "Date of Cancellation",
            ].map((title, index) => (
              <div
                key={index}
                className="card my-2 mx-1"
                style={{
                  flex: "1 1 18%",
                  padding: "10px",
                  borderRadius: "10px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <h6 style={{ fontWeight: "600", textAlign: "center" }}>
                  {title}
                </h6>
              </div>
            ))}
          </div>
        </div>{" "}
        {showmesage && (
          <h3 className="text-center pt-5 pb-5" style={{ color: "red" }}>
            No Data Found
          </h3>
        )}
        <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
        <div
          className="modal fade"
          id="ViewHistoryPopUp"
          role="dialog"
          aria-labelledby="exampleModalLabel1"
          aria-hidden="true"
          style={{ overflowY: "scroll" }}
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body modal-body-bgclr pl-3 pr-3 pt-0">
                <div className="popup-head mb-3">
                  <div className="row cancel-main-heading">
                    <div className="col-11 p-0">
                      <div className="text-center mb-2 mt-3 ">
                        <h3 className="Cancellation_heading pb-3">History</h3>
                      </div>
                    </div>
                    <div className="col-1">
                      <div
                        className="text-right modal_close_btn"
                        data-dismiss="modal"
                        aria-label="Close"
                        style={{ top: "-22px", right: "-16px" }}
                      >
                        <span aria-hidden="true" className="close_Btn">
                          ×
                        </span>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="p-3">
                    <table className="table-bordered">
                      <thead>
                        <th
                          className="text-center"
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            backgroundColor: "#25429C",
                            color: "#FFFF",
                          }}
                        >
                          Requested By
                        </th>
                        <th
                          className="text-center"
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            backgroundColor: "#25429C",
                            color: "#FFFF",
                          }}
                        >
                          Requestee Email
                        </th>
                        <th
                          className="text-center"
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            backgroundColor: "#25429C",
                            color: "#FFFF",
                          }}
                        >
                          Requested On
                        </th>
                      </thead>
                      <tbody style={{ overflowY: "scroll" }}>
                        { ViewHistoryData && ViewHistoryData.map((item: any, i) => (
                          <tr key={i}>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{formatDate1(item.i_ts)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
