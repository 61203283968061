import React, { useEffect, useState } from "react";
import { DropdownModel } from "../../../models/DropdownModel";
import ClickAwayListener from "./ClickAwayListener";

interface IMultiSelectProps {
  id: string;
  options: Array<DropdownModel>;
  selectedValues: Array<string>;
  className?: string;
  errorText?: string;
  hasError?: boolean;
  disbaled?: boolean;
  defaultText: string;
  searchable?: boolean;
  // events
  onClose: (selectedItems: Array<string>) => void;
}

/** Renders multi-select dropdown */
function MultiSelect(props: IMultiSelectProps) {
  // destructuring props
  const {
    id,
    options,
    defaultText,
    selectedValues,
    className,
    hasError,
    errorText,
    disbaled,
    searchable,
    onClose,
  } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<Array<string>>([]);
  const [optionList, setOptionList] = useState<Array<DropdownModel>>([]);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    setSelectedOptions(selectedValues);
    setOptionList(options);
  }, [options, selectedValues]);

  // triggers whenever user tries to change the value of  the checkbox
  const onCheckboxChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    let updatedOptions =
      selectedOptions && selectedOptions.length === 0 ? [] : selectedOptions;
    const value = evt.currentTarget.value;
    const checked = evt.currentTarget.checked;

    if (checked && !selectedOptions.includes(value)) {
      updatedOptions = [...updatedOptions, value];
    } else {
      updatedOptions = selectedOptions.filter(
        (option: string) => option !== value
      );
    }
    setSelectedOptions(updatedOptions);
    onClose(updatedOptions);
  };

  // triggers when dropdown is clicked to be opened and close vice versa
  const onDropdownClick = (evt: React.MouseEvent<HTMLDivElement>) => {
    setOpen((prevState: boolean) => !prevState);
  };

  // triggers when user clicks away from the dropdown
  const onClickAway = () => {
    setOpen(false);
  };

  // triggers whenever user tries to delete any pills
  const onPillDelete = (value: string) => {
    const updatedOptions = selectedOptions.filter(
      (option: string) => option !== value
    );
    setSelectedOptions(updatedOptions);
    // update gthe parent component
    onClose(updatedOptions);
  };

  // triggers when user searches for filter criteria
  const onSearch = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const filterOptions = options?.filter((option: DropdownModel) =>
      option.text.toLowerCase().includes(evt.target.value?.toLowerCase())
    );

    setSearch(evt.target.value);
    setOptionList(filterOptions);
  };

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <fieldset id={id} disabled={disbaled ? true : false} >
        <div className="ms-wrapper" style={{ position: "relative" }}>
          <div
            className={`form-control form-select ${className}`}
            onClick={onDropdownClick} style={{border: '2px solid #3B3B3B'}}
          >
            {" "}
            {defaultText}
            <span className="icon arrow"></span>
          </div>

          {open && (
            <div className="ms-dropdown">
              <ul className="list-unstyled dropdown_content">
                {
                  // if searchable is true then show the textbox
                  searchable ? (
                    <div className="search-wrap">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        value={search}
                        onChange={onSearch} 
                      />
                      <i className="search-icon"></i>
                    </div>
                  ) : (
                    <></>
                  )
                }
                {
                  // show list of options
                  optionList?.map((option: DropdownModel, index: number) => (
                    <li key={`${option.id}_${index}`}>
                      <div className="custom-control custom-checkbox">
                        <input
                          name={option.id}
                          id={option.id}
                          type="checkbox"
                          className="custom-control-input"
                          checked={selectedOptions.includes(option.id)}
                          value={option.id}
                          onChange={onCheckboxChange}
                        />
                        <label
                          htmlFor={option.id}
                          className="custom-control-label"
                        >
                          {option.text}
                        </label>
                      </div>
                    </li>
                  ))
                }
              </ul>
            </div>
          )}
          {
            // show pills
            selectedOptions?.length > 0 && (
              <div className="pills">
                {optionList
                  ?.filter((option: DropdownModel) =>
                    selectedOptions.includes(option.id)
                  )
                  .map((option: DropdownModel) => (
                    <span key={option.id} className="pill">
                      {option.text}
                      <span
                        className="pill-close close"
                        onClick={() => onPillDelete(option.id)}
                      >
                        <i className="fa fa-close"></i>
                      </span>
                    </span>
                  ))}
              </div>
            )
          }
          {
            // if error show error message
            hasError && errorText ? (
              <p className="text-danger">{errorText}</p>
            ) : (
              <></>
            )
          }
        </div>
      </fieldset>
    </ClickAwayListener>
  );
}

export default MultiSelect;
