export class FundingDetailsModel {
    constructor(
        public received_monetary_support_under_scheme: string,
        public instrument_applying_for: string,
        public quantum_funds_required: number,
        public fund_expense_data: Array<any>,
        public prior_funds_applicable:string,
        public prior_fund_data:Array<PriorDetails>    
    ) {
        this.received_monetary_support_under_scheme = received_monetary_support_under_scheme;
        this.instrument_applying_for = instrument_applying_for;
        this.quantum_funds_required = quantum_funds_required;
        this.fund_expense_data = fund_expense_data;    
        this.prior_funds_applicable=prior_funds_applicable  
        this.prior_fund_data=prior_fund_data
    }
}

export class FundExpenseDetails {
    constructor(
      public fund_expense_id: number,
      public expense_bucket: string,
      public expense_bucket_other:string,
      public expense_amount: number,
      public deployment_start_date: Date,
      public deployment_end_date: Date,
    ) {
      this.fund_expense_id = fund_expense_id;
      this.expense_bucket = expense_bucket;
      this.expense_bucket_other = expense_bucket_other;
      this.expense_amount = expense_amount;
      this.deployment_start_date = deployment_start_date;
      this.deployment_end_date = deployment_end_date; 
    }
  }

  export class PriorDetails {
    constructor(
      public fund_id: number,
      public fund_date: string,
      public amount: any,
      public financial_instrument:string,
      public funding_agency_name:string,
      public funding_agency_type:string
    ) {
      this.fund_id = fund_id;
      this.fund_date = fund_date;
      this.amount = amount;
      this.financial_instrument=financial_instrument;
      this.funding_agency_name=funding_agency_name;
      this.funding_agency_type=funding_agency_type;
    }
  }