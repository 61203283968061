import * as React from "react";
import "../../../styles/component.css";
import {
  GET_MEETING_DETAILS,
  GET_ALL_INCUBATORS,
  REMOVE_INCUBATOR,
} from "../../../constants/api";
import { get, post } from "../../../effects/api/api.common";
import { useEffect, useState } from "react";
import Calendar from "../../common/controls/Calendar";
import { Link, useHistory } from "react-router-dom";
import CalendarIcon from "../../../images/calendar.svg";
import download from "../../../images/download.svg";
import search from "../../../images/search.svg";
import { FormatDate } from "../../../utils/DateFormat";
import { formatTime } from "../../../utils/DateFormat";
import { downloadDocument, filterByValue } from "../../../utils/CommonMethods";

function ViewReviewComponent() {
  const history = useHistory();
  const [upcomingMeetingData, setupcomingMeetingData] = useState([]);
  const [filteredUpcomingMeetingData, setFilteredUpcomingMeetingData] =
    useState([]);
  const [pastMeetingData, setPastMeetingData] = useState([]);
  const [filteredIncubatorDetails, setFilteredIncubatorDetails] = useState([]);
  const [filteredPastMeetingData, setFilteredPastMeetingData] = useState([]);
  const [incubatorDetails, setIncubatorDetails] = useState([]);
  const [modalDate, setModalDate] = useState();
  const [modalTime, setModalTime] = useState();
  const [meetingID, setMeetingID] = useState();
  const [noDataFilter, setNoDataFilter] = useState("");
  const [noDataFilterIncubator, setNoDataFilterIncubator] = useState("");

  useEffect(() => {
    getMeetingDetails();
  }, []);
  // get states in dropdown
  const getMeetingDetails = () => {
    get(GET_MEETING_DETAILS).then((response: any) => {
      if (!response || !response.data) return;
      // console.log(response)
      // console.log(response.data.past)
      // console.log(response.data.upcoming)
      setupcomingMeetingData(response.data.upcoming);
      setFilteredUpcomingMeetingData([]);
      setPastMeetingData(response.data.past);
      setFilteredPastMeetingData([]);
    });
  };
  const getIncubatorDetails = (id: any, date: any, time: any) => {
    // setIncubatorDetails([]);
    get(`${GET_ALL_INCUBATORS}/${id}`).then((response: any) => {
    //   console.log(response.data.meetingId);
      if (!response) return;
    //   console.log(response.meetingId);
      if (typeof response.data.meetingId != "undefined") {
        setIncubatorDetails(response.data.meetingId);
      }
      setFilteredIncubatorDetails([]);
      setModalDate(date);
      setModalTime(time);
      setMeetingID(id);
    });
  };

  // const removeIncubator = (id: any) => {
  //     post(`${REMOVE_INCUBATOR}/${meetingID}/removeIncubator`,{"incubator_application_id":id}).then((response: any) => {
  //         if (!response || !response.data) return;
  //         if(response.data.result=="success"){
  //             get(`${GET_ALL_INCUBATORS}?meeting_id=${meetingID}`).then((response: any) => {
  //                 if (!response || !response.data) return;
  //                 setIncubatorDetails(response.data.result.data)

  //             });
  //         }
  //     });
  // };
  // item.incubator_application_no, item.user_id,item.incubator_application_id,item.application_index
  // id: any, userId: any, application_id:any,application_index:any
  const handleRowChange = (
    id: any,
    userId: any,
    incubator_application_no: any,
    application_index: any
  ) => {
    // console.log(id);
    // console.log(userId);
    // console.log(incubator_application_no);
    // console.log(id, userId, application_id,application_index)
    // return;
    history.replace({
      pathname: "/merged_Incubator_Details_list",
      // application_id: id, isFundDetails: flag, userId: userId, id :application_id,activeTab:'1'
      state: {
        id: id,
        userId: userId,
        application_id: incubator_application_no,
        application_index: application_index,
      },
    });
  };

  const evaluate = (id: any, count: any, date: any, time: any) => {
    history.replace({
      pathname: "/view_selection_meeting",
      state: {
        meeting_id: id,
        incubator_count: count,
        time_string: time,
        date_string: date,
      },
    });
  };
  const handleDateChange = (date: Date, field: string) => {};

  const searchList = (e: any) => {
    setNoDataFilter(e.target.value);
    const filteredData = filterByValue(upcomingMeetingData, e.target.value);
    const filteredPastData = filterByValue(pastMeetingData, e.target.value);
    setFilteredUpcomingMeetingData(filteredData);
    setFilteredPastMeetingData(filteredPastData);
  };

  const searchIncubator = (e: any) => {
    setNoDataFilterIncubator(e.target.value);
    const filteredIncubators = filterByValue(incubatorDetails, e.target.value);
    // console.log(filteredIncubatorDetails);
    setFilteredIncubatorDetails(filteredIncubators);
  };

  return (
    <>
      <div className="container container-min pt-5">
        <div className="row mb-4">
          <div className="col-md-6">
            <p className="dashboard pt-2 pl-3">EAC Review</p>
          </div>
        </div>

        <div className="row incubator-heading-review">
          <div className="col-md-6 text-left">
            <ul className="nav nav-tabs chart-nav" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#tabs-1"
                  role="tab"
                >
                  Upcoming Meetings
                </a>
              </li>
              <div className="vertical-dash"></div>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="tab"
                  href="#tabs-2"
                  role="tab"
                >
                  Past Meetings
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-6 text-right search-left">
            <div className="d-flex searchDiv">
              {" "}
              <img src={search} className="search" alt="search" />
              <input
                className="searchBar"
                type="text"
                placeholder="Search.."
                name="search"
                onKeyUp={searchList}
              />
              {/* <img src={CalendarIcon} className="calendar-icon review-calendar-icon" alt="Calendar icon to select date" /> */}
            </div>
          </div>
        </div>
        <div className="tab-content mb-4">
          <div className="tab-pane active" id="tabs-1">
            <div className="table-scroll">
              <table className="reviewTable">
                <thead>
                  <tr>
                    <th className="text-left table-heading custom-td-left table-heading ">
                      Meeting Type
                    </th>
                    <th className="text-left table-heading">Date & Time</th>
                    <th className="text-left table-heading">
                      No.of Incubators
                    </th>
                    {/* <th className="table-heading"></th> */}
                    {upcomingMeetingData.length == 0 ||
                    (filteredUpcomingMeetingData.length == 0 &&
                      noDataFilter != "") ? (
                      <tr>
                        <td className="text-center" colSpan={5}>
                          No data
                        </td>
                      </tr>
                    ) : filteredUpcomingMeetingData.length == 0 ? (
                      upcomingMeetingData.map((item: any) => (
                        <>
                          {item.meeting_type == 1 && (
                            <th className="table-heading"></th>
                          )}
                        </>
                      ))
                    ) : (
                      filteredUpcomingMeetingData.map((item: any) => (
                        <>
                          {item.meeting_type == 1 && (
                            <th className="table-heading"></th>
                          )}
                        </>
                      ))
                    )}
                  </tr>
                </thead>
                <tbody>
                  {upcomingMeetingData.length == 0 ||
                  (filteredUpcomingMeetingData.length == 0 &&
                    noDataFilter != "") ? (
                    <tr>
                      <td className="text-center" colSpan={5}>
                        No data
                      </td>
                    </tr>
                  ) : filteredUpcomingMeetingData.length == 0 ? (
                    upcomingMeetingData.map((item: any) => (
                      <tr>
                        <td className="text-left custom-td-left">
                          {item.meeting_type == 1
                            ? "Incubator Selection"
                            : "Performance Evaluation"}
                        </td>
                        <td className="text-left custom-td">
                          <div>{FormatDate(item.meeting_date)}</div>
                          <span className="tableDataApplication">
                            {formatTime(item.meeting_from_time)} -{" "}
                            {formatTime(item.meeting_to_time)}
                          </span>
                        </td>
                        <td className="text-left">
                          {item.incubator_count}{" "}
                          <span
                            className="view-all"
                            onClick={() =>
                              getIncubatorDetails(
                                item.id,
                                item.meeting_date,
                                formatTime(item.meeting_from_time) +
                                  "-" +
                                  formatTime(item.meeting_to_time)
                              )
                            }
                            data-toggle="modal"
                            data-target="#viewIncubator"
                          >
                            View
                          </span>
                        </td>
                        {/* <td></td> */}
                        {item.meeting_type == 1 && (
                          <td className="text-right">
                            {item.meeting_type == 1 ? (
                              <button
                                onClick={() =>
                                  evaluate(
                                    item.id,
                                    item.incubator_count,
                                    item.meeting_date,
                                    item.meeting_from_time +
                                      "-" +
                                      item.meeting_to_time
                                  )
                                }
                                className="btn btn-sisfs btn-transparent-primary"
                              >
                                Evaluate
                              </button>
                            ) : null}
                          </td>
                        )}
                      </tr>
                    ))
                  ) : (
                    filteredUpcomingMeetingData.map((item: any) => (
                      <tr>
                        <td className="text-left custom-td-left">
                          {item.meeting_type == 1
                            ? "Incubator Selection"
                            : "Performance Evaluation"}
                        </td>
                        <td className="text-left custom-td">
                          <div>{FormatDate(item.meeting_date)}</div>
                          <span className="tableDataApplication">
                            {formatTime(item.meeting_from_time)} -{" "}
                            {formatTime(item.meeting_to_time)}
                          </span>
                        </td>
                        <td className="text-left">
                          {item.incubator_count}{" "}
                          <span
                            className="view-all"
                            onClick={() =>
                              getIncubatorDetails(
                                item.id,
                                item.meeting_date,
                                formatTime(item.meeting_from_time) +
                                  "-" +
                                  formatTime(item.meeting_to_time)
                              )
                            }
                            data-toggle="modal"
                            data-target="#viewIncubator"
                          >
                            View
                          </span>
                        </td>
                        {/* <td></td> */}
                        {item.meeting_type == 1 && (
                          <td className="text-right">
                            {item.meeting_type == 1 ? (
                              <button
                                onClick={() =>
                                  evaluate(
                                    item.id,
                                    item.incubator_count,
                                    item.meeting_date,
                                    item.meeting_from_time +
                                      "-" +
                                      item.meeting_to_time
                                  )
                                }
                                className="btn btn-sisfs btn-transparent-primary"
                              >
                                Evaluate
                              </button>
                            ) : null}
                          </td>
                        )}
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="tab-pane" id="tabs-2">
            <div className="table-scroll mb-5">
              <table className="reviewTable">
                <thead>
                  <tr>
                    <th className="text-left table-heading custom-td-left table-heading">
                      Meeting Type
                    </th>
                    <th className="text-left table-heading">Date & Time</th>
                    <th className="text-left table-heading">
                      No.of Incubators Evaluated
                    </th>
                    <th className="text-left table-heading">
                      No.of Incubators Selected
                    </th>
                    <th className="text-left table-heading">
                      Funds Approved(&#8377;)
                    </th>
                    <th className="text-left table-heading">Meeting Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {pastMeetingData.length == 0 ||
                  (filteredPastMeetingData.length == 0 &&
                    noDataFilter != "") ? (
                    <tr>
                      <td className="text-center" colSpan={5}>
                        No data
                      </td>
                    </tr>
                  ) : filteredPastMeetingData.length == 0 ? (
                    pastMeetingData.map((item: any) => (
                      <tr>
                        <td className="text-left custom-td-left">
                          {item.meeting_type == 1
                            ? "Incubator Selection"
                            : "Performance Evaluation"}
                        </td>
                        <td className="text-left custom-td">
                          <div>{FormatDate(item.meeting_date)}</div>
                          <span className="tableDataApplication">
                            {formatTime(item.meeting_from_time)} -{" "}
                            {formatTime(item.meeting_to_time)}
                          </span>
                        </td>
                        <td className="text-left">
                          {item.incubator_count}{" "}
                          <span
                            className="view-all"
                            onClick={() =>
                              getIncubatorDetails(
                                item.id,
                                item.meeting_date,
                                formatTime(item.meeting_from_time) +
                                  "-" +
                                  formatTime(item.meeting_to_time)
                              )
                            }
                            data-toggle="modal"
                            data-target="#viewIncubator"
                          >
                            View
                          </span>
                        </td>
                        <td className="text-center">{item.selected_count}</td>
                        <td className="text-right custom-td-right">
                          {item.funds_approved}
                        </td>
                        <td className="text-left">
                          {item.file_path != "" ? (
                            <img
                              src={download}
                              alt="download"
                              onClick={() =>
                                downloadDocument(
                                  item.file_path,
                                  item.meeting_type == 1
                                    ? "Incubator-Selection-Meeting" +
                                        item.meeting_date
                                    : "Performance-Evaluation-Meeting-" +
                                        item.meeting_date
                                )
                              }
                            />
                          ) : (
                            "N/A"
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    filteredPastMeetingData.map((item: any) => (
                      <tr>
                        <td className="text-left custom-td-left">
                          {item.meeting_type == 1
                            ? "Incubator Selection"
                            : "Performance Evaluation"}
                        </td>
                        <td className="text-left custom-td">
                          <div>{FormatDate(item.meeting_date)}</div>
                          <span className="tableDataApplication">
                            {formatTime(item.meeting_from_time)} -{" "}
                            {formatTime(item.meeting_to_time)}
                          </span>
                        </td>
                        <td className="text-left">
                          {item.incubator_count}{" "}
                          <span
                            className="view-all"
                            onClick={() =>
                              getIncubatorDetails(
                                item.id,
                                item.meeting_date,
                                formatTime(item.meeting_from_time) +
                                  "-" +
                                  formatTime(item.meeting_to_time)
                              )
                            }
                            data-toggle="modal"
                            data-target="#viewIncubator"
                          >
                            View
                          </span>
                        </td>
                        <td className="text-center">{item.selected_count}</td>
                        <td className="text-right custom-td-right">
                          {item.funds_approved}
                        </td>
                        <td className="text-left">
                          {item.file_path != "" ? (
                            <img
                              src={download}
                              alt="download"
                              onClick={() =>
                                downloadDocument(
                                  item.file_path,
                                  item.meeting_type == 1
                                    ? "Incubator-Selection-Meeting" +
                                        item.meeting_date
                                    : "Performance-Evaluation-Meeting-" +
                                        item.meeting_date
                                )
                              }
                            />
                          ) : (
                            "N/A"
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* Modal */}
        <div
          className="modal fade"
          id="viewIncubator"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header modal-header-bottom mt-3 mb-3">
                <h5 className="modal-title pl-2" id="exampleModalLongTitle">
                  Incubators <span>(Selection Meeting)</span>
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6 text-left">
                      <span className="modal-text">Date & Time</span>
                      <br />
                      <span className="modal-text">
                        {FormatDate(modalDate)} | {modalTime}
                      </span>
                    </div>

                    <div className="col-md-6 text-right">
                      <div className="d-flex searchDiv">
                        {" "}
                        <img src={search} className="search" alt="search" />
                        <input
                          className="searchBar"
                          type="text"
                          placeholder="Search.."
                          name="search"
                          onKeyUp={searchIncubator}
                        />
                      </div>
                    </div>

                    <div className="table-scroll mb-5">
                      <table id="example">
                        <thead>
                          <tr>
                            <th className="custom-td-left">Incubator</th>
                            <th className="text-center custom-td-left">
                              Application Date
                            </th>
                            {/* <th className="text-center">No. Of Statrtup Supported</th> */}
                            {/* <th></th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {incubatorDetails.length == 0 ||
                          (filteredIncubatorDetails.length == 0 &&
                            noDataFilterIncubator != "") ? (
                            <tr>
                              <td className="text-center" colSpan={5}>
                                No data
                              </td>
                            </tr>
                          ) : filteredIncubatorDetails.length == 0 ? (
                            incubatorDetails.map((item: any) => (
                              <tr>
                                <td
                                  className="text-left custom-td-left"
                                  onClick={() =>
                                    handleRowChange(
                                      item.incubator_application_id,
                                      item.user_id,
                                      item.incubator_application_no,
                                      item.application_index
                                    )
                                  }
                                  data-dismiss="modal"
                                >
                                  <u className="link-orange-text">
                                    {item.incubator_application_name}
                                  </u>{" "}
                                  <br />
                                  {item.source_indicator == 0 && (
                                    <span className="selectedflagEAC">
                                      First Application
                                    </span>
                                  )}
                                  {item.source_indicator == 1 && (
                                    <span className="selectedflagEAC">
                                      Second Application
                                    </span>
                                  )}
                                  {item.status_id == 7 && <span className="selectedflagEAC_BGGreen">Selected</span>}
                                </td>
                                <td className="text-center custom-td-left">
                                  {item.first_submitted_date}
                                </td>
                                {/* <td className="text-center">32</td> */}

                                {/* <td className="text-left"></td> */}
                              </tr>
                            ))
                          ) : (
                            filteredIncubatorDetails.map((item: any) => (
                              <tr>
                                <td
                                  className="text-left custom-td-left"
                                  onClick={() =>
                                    handleRowChange(
                                      item.incubator_application_id,
                                      item.user_id,
                                      item.incubator_application_no,
                                      item.application_index
                                    )
                                  }
                                  data-dismiss="modal"
                                >
                                  <u className="link-orange-text">
                                    {item.incubator_application_name}
                                  </u>{" "}
                                  <br />
                                  {item.source_indicator == 0 && (
                                    <span className="selectedflagEAC">
                                      First Application
                                    </span>
                                  )}
                                  {item.source_indicator == 1 && (
                                    <span className="selectedflagEAC">
                                      Second Application
                                    </span>
                                  )}
                                  {item.status_id == 7 && <span className="selectedflagEAC_BGGreen">Selected</span>}
                                </td>
                                <td className="text-center custom-td-left">
                                  {item.first_submitted_date}
                                </td>
                                {/* <td className="text-center">32</td> */}

                                {/* <td className="text-left"></td> */}
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row pt-3 mb-3">
                  <div className="col-md-12 centerText">
                    <button
                      className="tertiary  btn-yellow-primary"
                      data-dismiss="modal"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* schedule a meeting tab */}
        <div
          className="modal fade"
          id="scheduleMeeting"
          role="dialog"
          aria-labelledby="exampleModalLabel1"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-slideout modal-sm"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body pl-5 pr-5 pt-5">
                <div className="popup-head mb-5 ml-3">
                  <div className="main-heading">Schedule a Meeting</div>
                  <div className="count-text">2 Incubators Selected</div>
                </div>

                <label className="col-sm-4 col-form-head">Meeting Type</label>
                <div className="col-md-12 mb-4">
                  <select
                    className="form-control"
                    aria-label="Select Entity Type"
                  >
                    <option value="1">Incubator Selection</option>
                    <option value="2">Performance Review Meeting</option>
                  </select>
                </div>
                <label className="col-sm-4 col-form-head">Date & Time</label>
                <div className="col-md-6 mb-4">
                  <Calendar
                    id="registrationDate"
                    name="registrationDate"
                    className="form-control"
                    format="dd/MM/yyyy"
                    selectedDate={new Date()}
                    onDateChange={(date: Date, field: string) =>
                      handleDateChange(date, field)
                    }
                  />
                </div>
                <div className="row pl-3 mb-5">
                  <div className="col-md-6">
                    <label className="col-form-head">From Time</label>
                    <input
                      type="time"
                      name="fromTime"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="col-form-head">To Time</label>
                    <input type="time" name="toTime" className="form-control" />
                  </div>
                </div>
                <div className="row pl-5">
                  <button
                    className="btn btn-sisfs btn-transparent-primary"
                    data-dismiss="modal"
                  >
                    CANCEL
                  </button>
                  <button className="tertiary  btn-yellow-primary ml-3">
                    SCHEDULE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ViewReviewComponent;
