import React, { Component, createRef, useEffect, useState } from 'react';
import homeseedfundneed from '../../images/seedfund-need.svg';
import whatsstartupindia from '../../images/whatsstartupindia.svg';
import seedfund from '../../images/seedfund.svg';
import objectives from '../../images/objectives.svg';
import Toparrow from '../../images/Toparrow.svg';
import Plus from '../../images/Plus.svg'
import Minus from '../../images/Minus.svg'
import { Link } from 'react-scroll'
import logo from './logo.svg';
import '../../styles/component.css';
import Startup from './startup'
import Incubator from './incubator'

const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
};

function Faq(props: any) {
    const [activeIndex, setActiveIndex] = useState("startup");
    const [activeTab, setActiveTab] = useState("startup");

    useEffect(() => {
        window.scrollTo(0, 0);

        if ((typeof props.location.state !== 'undefined') && (props.location.state != null)) {
            if (props.location.state.selected_tab == "incubator") {

                setActiveTab("incubator");
                setActiveIndex("incubator");
            }
        }

    }, []);

    const changeActive = (tabs: any) => {
        setActiveIndex(tabs)
    }
    return (
        <main role="main">
            <div className="about-container" >
                <div className="container-fluid about-heading faq-container">
                    <div className="text-center main-heading faq-heading">Frequently Asked Questions</div>
                    <div className="offset-4 col-sm-8 objectives-nav faq-container">
                        <ul className="nav nav-tabs pl-5 ml-5">
                            <li className={activeIndex == "startup" ? "active" : ""} onClick={() => changeActive('startup')}>
                                <a href="#startup" data-toggle="tab">Startups</a>
                            </li>
                            <li className={activeIndex == "incubator" ? "active" : ""} onClick={() => changeActive('incubator')}><a href="#incubator" data-toggle="tab">Incubators</a>
                            </li>
                        </ul>

                    </div>
                </div>

                <div className="container">
                    <div className="tab-content">
                        <div className={activeTab == "startup" ? "tab-pane active" : "tab-pane"} id="startup">
                            <Startup />
                        </div>
                        <div className={activeTab == "incubator" ? "tab-pane active" : "tab-pane"} id="incubator">
                            <Incubator />
                        </div>
                    </div>
                </div>
                <div className="top-arrow">
                    <div id="myBtn" onClick={scrollTop}>
                        <img src={Toparrow} className="img-fluid" alt="SISFS Logo" /> TOP
                    </div>
                </div>

            </div>
        </main>
    );
}

export default Faq;
