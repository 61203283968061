import { useEffect, useState } from "react";
import { STORE_STARTUP_APPLICATION, VIEW_STARTUP_APPLICATION } from "../../../../constants/api";
import { get } from "../../../../effects/api/api.common";
import { FileUploadTypeModel } from "../../../../models/FileUploadModel";
import { TeamDetails } from "../../../../models/incubator/IncubatorTeamModel";
import { FundingDetailsModel } from "../../../../models/startup/FundingDetailsModel";
import { initAuthorizedRepresentative, initEntityDetail, initFundingDetails, initIncubatorPreference, initStartupDetail, initStartupTeam, initUploadDocuments, StartupModel } from "../../../../models/startup/StartupModel";
import { createFile, downloadDocument } from "../../../../utils/CommonMethods";


interface IStartupApplicationDetailsProps {
    applicationDetails: any
}

interface IStartupApplicationDetailsState {

}

function StartupApplicationDetailsComponent(props: IStartupApplicationDetailsProps) {
    const {
        applicationDetails
    } = props;
    const [contactDetails, setContactDetails] = useState({
        name: '',
        mobile: '',
        Designation: '',
        address: '',
        email: ''
    });


    useEffect(() => {

        // if (typeof props.location.state != "undefined") {
        //     setStartup(startup);
        //     viewActiveApplication(props.location.state.application_id);
        // }
        if (Object.keys(applicationDetails).length !== 0) {
            viewActiveApplication(applicationDetails);
        }

    }, [applicationDetails]);

    //view active application
    const viewActiveApplication = (response: any) => {

        setContactDetails({
            name: response.data.data.Application_Details.AuthorizedRepresentative.representative_firstname !== null ? response.data.data.Application_Details.AuthorizedRepresentative.representative_firstname + ' ' + response.data.data.Application_Details.AuthorizedRepresentative.representative_lastname : "",
            mobile: response.data.data.Application_Details.AuthorizedRepresentative.representative_mobile_no !== null ? response.data.data.Application_Details.AuthorizedRepresentative.representative_mobile_no : "",
            Designation: response.data.data.Application_Details.AuthorizedRepresentative.representative_designation !== null ? response.data.data.Application_Details.AuthorizedRepresentative.representative_designation : "",
            address: response.data.data.Application_Details.EntityDetail.startup_address !== null ? response.data.data.Application_Details.EntityDetail.startup_address : "",
            email: response.data.data.Application_Details.AuthorizedRepresentative.representative_email !== null ? response.data.data.Application_Details.AuthorizedRepresentative.representative_email : ""
        })
    };

    return (
        <>

            <div className="accordion accordion-details-wrap" id="accordion-application-details">
                <div className="card accordion-card">
                    <div className="card-body accordion-card-body">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="startup-info-group">
                                    <div className="startup-info-group-label">
                                        Name of Representative
                                        </div>
                                    <div className="startup-info-group-value">
                                        {contactDetails.name}
                                    </div>
                                </div>

                                <div className="startup-info-group">
                                    <div className="startup-info-group-label">
                                        Designation
                                        </div>
                                    <div className="startup-info-group-value">
                                        {contactDetails.Designation}
                                    </div>
                                </div>


                            </div>

                            <div className="col-lg-6">
                                <div className="startup-info-group">
                                    <div className="startup-info-group-label">
                                        Mobile No.
                                            </div>
                                    <div className="startup-info-group-value">
                                        {contactDetails.mobile}
                                    </div>
                                </div>

                                <div className="startup-info-group">
                                    <div className="startup-info-group-label">
                                        Email ID
                                            </div>
                                    <div className="startup-info-group-value">
                                        {contactDetails.email}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="startup-info-group">
                                    <div className="startup-info-group-label">
                                        Address
                                            </div>
                                    <div className="startup-info-group-value">
                                        {contactDetails.address}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default StartupApplicationDetailsComponent;