import React, { useEffect, useState } from "react";
import {
  BASE_URL,
  GET_ALL_STARTUP_TRANCHES,
  STARTUP_GET_INCUBATORS,
} from "../../../constants/api";
import { VIEW_STARTUP_APPLICATION } from "../../../constants/dist/api";
import { get, post } from "../../../effects/api/api.common";
import { StartupTrancheDetail } from "../../../models/funddetails/StartupTrancheDetails";
import {
  TrancheData,
  TrancheDetail,
} from "../../../models/funddetails/TrancheDetails";
import { changeNumberFormat } from "../../../utils/CommonMethods";
import TrancheInfoComponent from "../common/TrancheInfoComponent";
import TrancheWiseDetails from "../common/TrancheWiseDetails";

var UserCommet: string = "";

interface ITrancheDataProps {
  trancheDataModel: Array<StartupTrancheDetail>;
  trancheDataDESCModel: Array<StartupTrancheDetail>;
  trancheDetailsModel: Array<StartupTrancheDetail>;
  // tracheFundDetailsModel: Array<StartupTrancheDetail>;
  tracheFundDetailsModel: any;
  statusId2: any;
  evDate2: any;
  isPrefer2: any;
  sel: any;
}

function TrancheDetailsComponent(props: ITrancheDataProps) {
  const {
    trancheDataModel,
    trancheDataDESCModel,
    trancheDetailsModel,
    tracheFundDetailsModel,
    statusId2,
    evDate2,
    isPrefer2,
    sel,
  } = props;
  const [total_grand_approved, setGrantApproved] = useState(0);
  const [total_approved, setTotalApproved] = useState<any>("");
  const [startUp, setStartUp] = useState<any>({});
  if (trancheDetailsModel != undefined) {
    var passId = trancheDetailsModel[0].startup_application_id;
  }
  const [totalDisbursedAmount, setTotalDisbursedAmount] = useState<any>();
  var sum = 0;
  useEffect(() => {
    calculateApprovedAmount();
    {
      for (let i = 0; i < trancheDataModel?.length; i++) {
        if (
          trancheDataModel[i].startup_tranches_statuses_label === "Disbursed"
        ) {
          sum = sum + trancheDataModel[i].fund_released;
          setTotalDisbursedAmount(sum);
        }
      }
    }
  });
  const handleTrancheDataChange = (updatedTrancheData: any) => {
    // setEmployeeData(updatedTrancheData);
  };
  const calculateApprovedAmount = () => {
    if (trancheDataModel.length != 0) {
      if (trancheDataModel.length > 0) {
        let grant_approved = 0;
        for (let i = 0; i < trancheDataModel.length; i++) {
          grant_approved =
            grant_approved +
            (trancheDataModel[i].fund_released == null
              ? 0
              : Number(trancheDataModel[i].fund_released));
        }
        setGrantApproved(Number(grant_approved.toFixed(2)));
      }
    }
    // total_applroved_amount

    {
      trancheDataModel.length != 0 &&
        trancheDataModel.map((item: any, index: any) => {
          if (index == 0) {
            setTotalApproved(item);
          }
        });
    }
  };

  // const fetchData = async (props: any) => {
  //   const { data: { data } } = await get(VIEW_STARTUP_APPLICATION + '/' + passId);
  //   setStartUp(data)
  // };

  // useEffect(() => {
  //   fetchData(props);
  // }, []);

  if (startUp.Application_Details !== undefined) {
    if (startUp.Application_Details.FundingDetails.instrument_comment === "") {
      UserCommet =
        startUp.Application_Details.FundingDetails.instrument_applying_for;
    }
  }

  const quickEditCall = () => {
    var payload = {};
    payload = {
      instrument_comment:
        startUp.Application_Details.FundingDetails.instrument_comment,
      instrument_approved:
        startUp.Application_Details.FundingDetails.instrument_applying_for,
    };

    post(`${BASE_URL}startup/${passId}/quickEdit`, payload).then(
      (response: any) => {
        if (!response || !response.data) return;
      }
    );
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md m-auto">
              <div style={{ textAlign: "center" }}>
                <span className="fund-amount-cur">
                  &#8377;
                  {/* {console.log(total_approved,"totaal")} */}
                  <label className="fund-amount-label-value">
                    {" "}
                    {changeNumberFormat(
                      Number(total_approved.total_applroved_amount),
                      2,
                      1
                    )}{" "}
                  </label>
                </span>
                <br />
                <span className="fund-amount-label">Total Funds Approved</span>
              </div>
            </div>
            <div className="vl vl-mr"></div>
            <div className="col-md m-auto">
              <div style={{ textAlign: "center" }}>
                <span className="fund-amount-cur">
                  <label className="fund-amount-label-value">
                    {trancheDataModel.length != 0 &&
                    trancheDataModel[0].approved_amount == 0
                      ? 0
                      : trancheDataModel.length}
                  </label>
                </span>
                <br />

                <span className="fund-amount-label">Total No. of Tranches</span>
              </div>
            </div>
            <div className="vl vl-mr"></div>
            <div className="col-md m-auto">
              <div style={{ textAlign: "center" }}>
                <span className="fund-amount-cur">
                  &#8377;
                  <label className="fund-amount-label-value">
                    {trancheDataModel != undefined && totalDisbursedAmount > 0
                      ? changeNumberFormat(Number(totalDisbursedAmount), 2, 1)
                      : "0"}{" "}
                  </label>
                </span>
                <br />
                <span className="fund-amount-label">Total Funds Disbursed</span>
              </div>
            </div>
            <div className="vl vl-mr"></div>
            <div className="col-md m-auto">
              <div style={{ textAlign: "center" }}>
                {tracheFundDetailsModel.length != 0 &&
                tracheFundDetailsModel[0].instrument_approved != "" ? (
                  <label className="fund-amount-label-value">
                    {tracheFundDetailsModel[0].instrument_approved}
                  </label>
                ) : (
                  <label className="fund-amount-label-value">
                    <span>-</span>
                  </label>
                )}
                <br />
                <span className="fund-amount-label">Instrument Type</span>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      </div>
      <br />
      {/* <hr /> */}
      <br />
      {/* <div className="d-flex">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <div>
            <div className='d-flex align-items-center justify-content-around mb-5'>
              <div>
                <label htmlFor="browser" className="form-label">Choose the Option:</label>
                <div>
                  <input className="form-control" type="text" defaultValue={UserCommet} />
                </div>
              </div>
              <div className="vl vl-mr"></div>
              <div>
                <h6>Comment :-</h6>
                 <textarea placeholder={startUp?.Application_Details?.FundingDetails?.instrument_comment === "Selected." ? "Selected" : ''} />
              </div>
            </div>

            <div className=" d-flex justify-content-center">
              <button className="tertiary btn-yellow-primary"
                onClick={() => {
                  quickEditCall()
                }}>
                Submit
              </button>
            </div>
          </div>
        </div> 
      </div> */}
      <div className="row">
        <div className="col-md-12">
          <br />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {trancheDataModel.length != 0 &&
          trancheDataModel[0].approved_amount == 0 ? (
            <>
              <br />
              <br />
              <br />
            </>
          ) : (
            <TrancheWiseDetails
              trancheDetailsModel={trancheDataModel}
              total_grant_approved={0}
              instrumentStatus={false}
              statusId2={statusId2}
              evDate2={evDate2}
              isPrefer2={isPrefer2}
              sel1={sel}
              applicationStatusId={null}
              onTrancheDataChange={handleTrancheDataChange}
              applicationIDDD={null}
              disbursed_to_startups={null}
              disbursed_to_inc={null}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default TrancheDetailsComponent;
