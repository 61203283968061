import React, { useEffect, useState } from "react";
import {
  EVALUATION_FILE,
  FILE_REMOVE,
  FILE_UPLOAD,
  GET_ALL_DISTRICTS,
  GET_ALL_INDUSTRIES,
  GET_ALL_INDUSTRY_SECTORS,
  GET_ALL_STATES,
  GET_ASSISTED_INCUBATOR,
  GET_LEGAL_ENTITY,
  NODE_URL,
  STORE_INCUBATOR_APPLICATION,
} from "../../../constants/api";
import { get, post } from "../../../effects/api/api.common";
import { GeneralDetailModel } from "../../../models/incubator/GeneralDetailModel";
import { SaveRequestModel } from "../../../models/incubator/SaveRequestModel";
import MultiSelect from "../../common/controls/MultiSelect";
import { DropdownModel } from "../../../models/DropdownModel";
import FileUploader from "@amodv/react-file-uploader";
import "@amodv/react-file-uploader/dist/index.css";
import { requestType, StepperEnum, UploadTypeEnum } from "../../../utils/Enums";
import Calendar from "../../common/controls/Calendar";
import IncrementDecrement from "../../common/controls/IncrementDecrement";
import { FileUploadModel } from "../../../models/FileUploadModel";
import { useAuthStateValue } from "../../../providers/AuthStateProvider";
import { FileDataModel } from "../../../models/common/FileDataModel";
import {
  validateEmail,
  validateMobile,
  validatePAN,
  validateRequiredText,
  validateSocialURL,
  validateURL,
} from "../../../effects/utils/validations";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import { downloadDocument } from "../../../utils/CommonMethods";
import { useHistory } from "react-router-dom";
import error from '../../../images/error.svg';
import ConfirmationDialog from '../../common/controls/ConfirmationDialog';
import { getSessionStorageValue } from "../../../effects/utils/session-storage";
import pdf from "../../../images/PDF.svg";
import xls from "../../../images/xls.svg";
import doc from "../../../images/doc.svg";
import xlsx from "../../../images/xls.svg";
import Allround from "../../../images/Allround.svg";
interface IGeneralDetailsProps {
  stepper: any;
  generalDetailModel: GeneralDetailModel;
  onStepSave: (
    stepperIndex: number,
    generalModel: GeneralDetailModel,
    request: number
  ) => void;
  applicationId: number;
  errors: Array<string>;
  disableForm: any
  functions:any
}
interface IIKeyValueIndexer {
  [key: string]: boolean;
}

/** Render Incubator general details tab */
function GeneralDetailsComponent(props: IGeneralDetailsProps) {
  const { stepper, generalDetailModel, onStepSave, applicationId, disableForm,functions } = props;
  // @ts-ignore: Unreachable code error
  const [{ user }, dispatch] = useAuthStateValue();

  const [showIncubatorFailureModal, setShowIncubatorFailureModal] =
    useState(false);

  const [showIncubator_limited_Upload_Model, setshowIncubator_limited_Upload_Model] = useState(false);

  const dismissModal = () => {
    setShowIncubatorFailureModal(false);
  };

  const [generalDetail, setGeneralDetail] = useState<GeneralDetailModel>(
    new GeneralDetailModel(
      "",
      [],
      [],
      null,
      null,
      "",
      "",
      "",
      "",
      "",
      0,
      new Date(),
      "",
      new Date(),
      25,
      5,
      "",
      [],
      "",
      [],
      [],
      [],
      "",
      "",
      "",
      "",
      "",
      "",
      false,
      false,
      false,
      false,
      "",
      [],
      [],
      [],
      []
    )
  );

  const [countryStates, setCountryStates] = useState([]);
  const [stateDistricts, setStateDistricts] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [legalEntities, setLegalEntities] = useState([]);
  const [govAssistedIncubators, setGovAssistedIncubators] = useState([
    { centralStateIncubatorId: 0, attribute_value: "", isChecked: false },
  ]);
  const [industrySectors, setIndustrySectors] = useState<Array<DropdownModel>>(
    []
  );
  const [fileUpload, setFileUpload] = useState<FileUploadModel>(
    new FileUploadModel(0, 0, new File([], ""))
  );
  const [openUploadBlock, setOpenUploadBlock] = useState<IIKeyValueIndexer>();
  const [isDisabled, setIsDisabled] = useState(true);
  const [loader, setLoader] = useState(false);
  const [
    openTextOnCheckbox,
    setOpenTextOnCheckbox,
  ] = useState<IIKeyValueIndexer>();
  const [legalEntityId, setLegalEntityId] = useState("");
  const [incorporationCert, setIncorporationCert] = useState("");
  const [no_startups_incubation, set_no_startups_incubation] = useState(1);
  const [pdfOnly, setPdfOnly] = useState<IIKeyValueIndexer>();
  const [operalizationDate, setOperalizationDate] = useState<number>(2);
  const [authorisationLetterPath, setAuthorisationLetterPath] = useState('');
  const [failDialogData, setFailDialogData] = useState({
    header: '',
    text: '',
  });


  const [showIncubatorFailureModal1, setShowIncubatorFailureModal1] = useState(false);
  useEffect(() => {
    getAllIndustries();
    // getAllStates();
    // getExcelFilePath();
  }, []);

  useEffect(() => {
    setGeneralDetail(generalDetailModel);
    // setGeneralDetail(
    //   prevState => ({
    //     ...prevState,
    //     annual_report: generalDetailModel.annual_report
    //   })
    // )
    if (generalDetailModel.industryIds.length > 0) {
      getAllIndustrySectors(generalDetailModel.industryIds.join(","));
    }
  }, [generalDetailModel.annual_report, generalDetailModel.proof_assistance, generalDetailModel.incorporation_certificate]);

  useEffect(() => {
    setGeneralDetail(generalDetailModel);
    if (generalDetailModel.stateId != "") {
      getAllDistrictsByStateId(generalDetailModel.stateId);
    }
  }, [generalDetailModel.stateId]);

  useEffect(() => {
    setGeneralDetail(generalDetailModel);
    getGovAssistedIncubators();
    setOperalizationDate(
      generalDetailModel.central_incubator_far_id?.length &&
        generalDetailModel.central_incubator_far_id.indexOf(326) > -1
        ? 3
        : 2
    );
  }, [generalDetailModel.central_incubator_far_id]);

  useEffect(() => {
    getLegalEntities();
    setLegalEntityId(
      generalDetailModel.typeLegalEntityId
        ? generalDetailModel.typeLegalEntityId.toString()
        : ""
    );
  }, [generalDetailModel.typeLegalEntityId]);

  useEffect(() => {
    setGeneralDetail(generalDetailModel);
  }, [generalDetailModel]);

  // useEffect(() => {
  //   // setGeneralDetail(JSON.parse(JSON.stringify(generalDetail)));
  //   setGeneralDetail(generalDetailModel);
  // }, [
  //   generalDetail.facebook_enable,
  //   generalDetail.twitter_enable,
  //   generalDetail.youtube_enable,
  //   generalDetail.linkedin_enable,
  // ]);

  // get all the industries on component load
  const getAllIndustries = () => {
    get(GET_ALL_INDUSTRIES).then((response: any) => {
      if (!response || !response.data) return;

      const industries = response.data.data.map(
        (item: any): DropdownModel => {
          return {
            text: item.attribute_value,
            id: item.id.toString(),
            translation_text: "",
          };
        }
      );
      setIndustries(industries);
      getAllStates();
    });
  };

  // fetch all the sectors based on industries
  const getAllIndustrySectors = (industryId: any) => {
    if (!industryId) {
      setIndustrySectors([]);
      return;
    }
    get(`${GET_ALL_INDUSTRY_SECTORS}/${industryId}`).then((response: any) => {
      if (!response || !response?.data?.data) {
        setIndustrySectors([]);
        return;
      }

      const industrySectors: Array<DropdownModel> = response.data.data.map(
        (item: any): DropdownModel => {
          return {
            text: item?.attribute_value,
            id: item?.id.toString(),
            translation_text: "",
          };
        }
      );
      setIndustrySectors(industrySectors);
    });
  };

  // get all the states on component load
  const getAllStates = () => {
    get(GET_ALL_STATES).then((response: any) => {
      if (!response || !response.data) return;

      const states = response.data.data.map((item: any) => ({
        label: item.name,
        value: item.id,
      }));
      setCountryStates(states);
      getExcelFilePath();
    });
  };

  // fetch all the districts by state id
  const getAllDistrictsByStateId = (stateId: string) => {
    if (!stateId) {
      setGeneralDetail((prevState: GeneralDetailModel) => {
        return {
          ...prevState,
          cityId: "",
        };
      });
      setStateDistricts([]);
      return;
    }

    get(`${GET_ALL_DISTRICTS}/${stateId}`).then((response: any) => {
      if (!response || !response?.data?.data) {
        setStateDistricts([]);
        return;
      }

      const districts = response?.data?.data.map((item: any) => ({
        label: item?.name,
        value: item?.id,
      }));
      setStateDistricts(districts);
    });
  };

  // get all the Legal entities
  const getLegalEntities = () => {
    get(GET_LEGAL_ENTITY).then((response: any) => {
      if (!response || !response.data) return;

      const entities = response.data.data.map((item: any) => ({
        typeLegalEntityId: item.id,
        attribute_value: item.attribute_value,
      }));
      setLegalEntities(entities);
    });
  };

  //get all the gov. assisted incubators
  const getGovAssistedIncubators = () => {
    get(GET_ASSISTED_INCUBATOR).then((response: any) => {
      if (!response || !response.data) return;

      const assisted_incubators = response.data.data.map((item: any) => ({
        centralStateIncubatorId: item.id,
        attribute_value: item.attribute_value,
        isChecked: generalDetailModel.central_incubator_far_id.includes(
          item.id
        ),
      }));

      const incubators = assisted_incubators.filter(
        (inc: any) => inc.isChecked && inc.centralStateIncubatorId
      );
      setGovAssistedIncubators(assisted_incubators);

      let genDetails = JSON.parse(JSON.stringify(generalDetail));
      genDetails.central_incubator_far_id =
        incubators.length > 0
          ? incubators.map((inc: any) => inc.centralStateIncubatorId)
          : [];

      if (genDetails.central_incubator_far_id.indexOf(326) > -1) {
        setIsDisabled(true);
        genDetails.proof_assistance = [];
      } else {
        setIsDisabled(false);
      }

      // onStepSave(
      //   StepperEnum.GeneralDetail,
      //   genDetails,
      //   requestType.propertyChange
      // );
    });
  };

  // triggers whenever multi-select values are changed
  const onMultiSelectChange = (selectedIds: Array<string>, name: string) => {
    setGeneralDetail((prevState: GeneralDetailModel) => {
      return {
        ...prevState,
        [name]: selectedIds,
      };
    });

    // cascaded multi-select for sectors based on industry select
    if (name === "industryIds") {
      getAllIndustrySectors(selectedIds.join(","));
    }
    onStepSave(
      StepperEnum.GeneralDetail,
      generalDetail,
      requestType.propertyChange
    );
  };

  // trrigers on date change
  const handleDateChange = (date: Date, field: string) => {
    setGeneralDetail((prevState: GeneralDetailModel) => {
      return {
        ...prevState,
        [field]: moment(date).format(
          "YYYY/MM/DD"
        ),
      };
    });

    let genDetail = JSON.parse(JSON.stringify(generalDetail));
    genDetail[field] = moment(date).format(
      "YYYY/MM/DD"
    );
    if (field === "registrationDate") {
      genDetail.operationalization_date = "";
      onStepSave(
        StepperEnum.GeneralDetail,
        genDetail,
        requestType.propertyChange
      );
    } else {
      onStepSave(
        StepperEnum.GeneralDetail,
        genDetail,
        requestType.propertyChange
      );
    }
  };

  // trrigers increment decrement change
  const handleIncrementDecrementChange = (name: string, value: number) => {
    setGeneralDetail((prevState: GeneralDetailModel) => {
      return {
        ...prevState,
        [name]: value ? value : "",
      };
    });
  };

  const handleOnClick = (evt: any) => {
    const name = evt.target.name;
    let link: string = "";
    if (name === "facebook_enable") link = "facebook_link";
    else if (name === "linkedin_enable") link = "linkedin_link";
    else if (name === "twitter_enable") link = "twitter_link";
    else link = "youtube_link";

    let genDetails = JSON.parse(JSON.stringify(generalDetail));

    genDetails[name] = evt.target.checked;
    genDetails[link] = evt.target.checked ? genDetails[link] : "";

    setGeneralDetail(genDetails);
    onStepSave(
      StepperEnum.GeneralDetail,
      genDetails,
      requestType.propertyChange
    );
  };
  const [popUpName, setPopUpName] = useState('');
  const setMeetingModal = (fieldName: string) => {
    setPopUpName(fieldName);
  };
  // triggers whenever user makes any changes in the form for select and input type
  const handleChange = (
    evt: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const name = evt.target.name;
    const value = evt.target.value;

    let genDetails = JSON.parse(JSON.stringify(generalDetail));

    if (evt.target.name == "registeredName" || evt.target.name == "representative_name" || evt.target.name == "representative_designation") {
      genDetails[name] = value.replace(/[^a-zA-Z0-9-.&() ]/ig, '');
      setGeneralDetail(genDetails);
    } else if (evt.target.name == "incubator_name") {
      genDetails[name] = value.replace(/[^a-zA-Z0-9-_ |]/ig, '');
      setGeneralDetail(genDetails);
    } else if (evt.target.name == "representative_email") {
      genDetails[name] = value.replace(/[^a-zA-Z@.]/ig, '');
      setGeneralDetail(genDetails);
    } else if (evt.target.name == "corporateId") {
      genDetails[name] = value.replace(/[^a-zA-Z0-9-/]/ig, '');
      setGeneralDetail(genDetails);
    } else if (evt.target.name == "pan_card_number") {
      genDetails[name] = value.replace(/[^A-Z0-9]/ig, '');
      setGeneralDetail(genDetails);
    } else if (evt.target.name === "representative_mobile_no") {
      if (!isNaN(parseInt(value))) {
        genDetails[name] = value;
      } else {
        genDetails[name] = "";
      }
      setGeneralDetail(genDetails);
    } else {
      genDetails[name] = evt.target.value;
      setGeneralDetail(genDetails);

      // cascaded dropdown for district on state change
      if (name === "stateId") {
        getAllDistrictsByStateId(value);
      }

      if (
        evt.target.type === "radio" &&
        name != "fund_disburse_using_3rd_party"
      ) {
        genDetails.typeLegalEntityId = parseInt(value);
        setLegalEntityId(value);
      }
    }
    onStepSave(
      StepperEnum.GeneralDetail,
      genDetails,
      requestType.propertyChange
    );
  };

  const handleBlur = () => {
    onStepSave(
      StepperEnum.GeneralDetail,
      generalDetail,
      requestType.propertyChange
    );
  };

  /** upload the files */
  const onFileUpload = (files: Array<File>, type: string) => {
    let indexes: Array<string> = [];
    let updatedGeneralDetail = Object.assign({}, generalDetail);

    for (let file of files) {
      // if already upladed then skip
      const fileData = generalDetail as any;

      // const fileExists = fileData[type].findIndex(
      //   (uf: FileUploadModel) => uf.file.name === file.name
      // );
      // if (fileExists > -1) continue;
      let updatedFileData: Array<FileUploadModel> = [];

      if (file.type === "application/pdf") {
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user)
        const formData = new FormData();
        // Update the formData object
        formData.append("file", file, file.name);
        formData.append("application_id", applicationId.toString());
        formData.append("file_type", type);
        formData.append("logged_user_id", userInfo.id)
        setLoader(true);
        post(FILE_UPLOAD, formData).then((response: any) => {
          if (!response.data) return;
          setLoader(false);
          if (response.data.result.message == "File already exists.") {
            // setFailDialogData({
            //     "header":"Oops",
            //     "text":response.data.result.message     
            //   })
            //   setShowIncubatorFailureModal1(true);  
            return;

          } else {
            switch (type) {
              case UploadTypeEnum.AnnualReport:
                // generalDetail.annual_report = [
                //   ...generalDetail.annual_report,
                //   new FileUploadModel(response.data.result.file_id, 0, file),
                //   // new FileUploadModel(0, 0, file),
                // ];
                // setGeneralDetail(JSON.parse(JSON.stringify(generalDetail)));
                updatedFileData = [
                  ...updatedGeneralDetail.annual_report.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                  new FileUploadModel(response.data.data.file_id, 0, file)
                ];
                updatedGeneralDetail.annual_report = updatedFileData
                setGeneralDetail(prevState => {
                  const updatedFileOther = {
                    ...prevState,
                    annual_report: [
                      ...prevState.annual_report.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                      new FileUploadModel(response.data.data.file_id, 0, file)
                    ]
                  }
                  return updatedFileOther;
                });
                onStepSave(
                  StepperEnum.GeneralDetail,
                  updatedGeneralDetail,
                  requestType.propertyChange
                );
                break;

              case UploadTypeEnum.ProofAssistance:
                updatedFileData = [
                  ...updatedGeneralDetail.proof_assistance.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                  new FileUploadModel(response.data.data.file_id, 0, file)
                ];
                updatedGeneralDetail.proof_assistance = updatedFileData
                // generalDetail.proof_assistance = [
                //   ...generalDetail.proof_assistance,
                //   new FileUploadModel(response.data.data.file_id, 0, file),
                //   // new FileUploadModel(0, 0, file),
                // ];
                // setGeneralDetail(JSON.parse(JSON.stringify(generalDetail)));
                setGeneralDetail(prevState => {
                  const updatedFileOther = {
                    ...prevState,
                    proof_assistance: [
                      ...prevState.proof_assistance.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                      new FileUploadModel(response.data.data.file_id, 0, file)
                    ]
                  }
                  return updatedFileOther;
                });
                onStepSave(
                  StepperEnum.GeneralDetail,
                  updatedGeneralDetail,
                  requestType.propertyChange
                );
                break;

              case UploadTypeEnum.IncorporationCertificate:
                updatedFileData = [
                  ...updatedGeneralDetail.incorporation_certificate.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                  new FileUploadModel(response.data.data.file_id, 0, file)
                ];
                updatedGeneralDetail.incorporation_certificate = updatedFileData
                // generalDetail.incorporation_certificate = [
                //   ...generalDetail.incorporation_certificate,
                //   new FileUploadModel(response.data.data.file_id, 0, file),
                //   // new FileUploadModel(0, 0, file),
                // ];
                // setGeneralDetail(JSON.parse(JSON.stringify(generalDetail)));
                setGeneralDetail(prevState => {
                  const updatedFileOther = {
                    ...prevState,
                    incorporation_certificate: [
                      ...prevState.incorporation_certificate.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                      new FileUploadModel(response.data.data.file_id, 0, file)
                    ]
                  }
                  return updatedFileOther;
                });
                onStepSave(
                  StepperEnum.GeneralDetail,
                  updatedGeneralDetail,
                  requestType.propertyChange
                );
                break;
              case UploadTypeEnum.PanCardUpload:
                updatedFileData = [
                  ...updatedGeneralDetail.pan_card_upload.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                  new FileUploadModel(response.data.data.file_id, 0, file)
                ];
                updatedGeneralDetail.pan_card_upload = updatedFileData
                setGeneralDetail(prevState => {
                  const updatedFileOther = {
                    ...prevState,
                    pan_card_upload: [
                      ...prevState.pan_card_upload.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                      new FileUploadModel(response.data.data.file_id, 0, file)
                    ]
                  }
                  return updatedFileOther;
                });
                onStepSave(
                  StepperEnum.GeneralDetail,
                  updatedGeneralDetail,
                  requestType.propertyChange
                );
                break;

              case UploadTypeEnum.AuthorisationLetterUpload:
                updatedFileData = [
                  ...updatedGeneralDetail.authorisation_letter_upload.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                  new FileUploadModel(response.data.data.file_id, 0, file)
                ];
                updatedGeneralDetail.authorisation_letter_upload = updatedFileData
                setGeneralDetail(prevState => {
                  const updatedFileOther = {
                    ...prevState,
                    authorisation_letter_upload: [
                      ...prevState.authorisation_letter_upload.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                      new FileUploadModel(response.data.data.file_id, 0, file)
                    ]
                  }
                  return updatedFileOther;
                });
                onStepSave(
                  StepperEnum.GeneralDetail,
                  updatedGeneralDetail,
                  requestType.propertyChange
                );
                break;

              case UploadTypeEnum.AdhaarCardUpload:
                updatedFileData = [
                  ...updatedGeneralDetail.adhaar_card_upload.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                  new FileUploadModel(response.data.data.file_id, 0, file)
                ];
                updatedGeneralDetail.adhaar_card_upload = updatedFileData
                setGeneralDetail(prevState => {
                  const updatedFileOther = {
                    ...prevState,
                    adhaar_card_upload: [
                      ...prevState.adhaar_card_upload.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                      new FileUploadModel(response.data.data.file_id, 0, file)
                    ]
                  }
                  return updatedFileOther;
                });
                onStepSave(
                  StepperEnum.GeneralDetail,
                  updatedGeneralDetail,
                  requestType.propertyChange
                );
                break;

              case UploadTypeEnum.StartupIncubationUpload:
                updatedFileData = [
                  ...updatedGeneralDetail.startup_incubation_upload.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                  new FileUploadModel(response.data.data.file_id, 0, file)
                ];
                updatedGeneralDetail.startup_incubation_upload = updatedFileData
                setGeneralDetail(prevState => {
                  const updatedFileOther = {
                    ...prevState,
                    startup_incubation_upload: [
                      ...prevState.startup_incubation_upload.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                      new FileUploadModel(response.data.data.file_id, 0, file)
                    ]
                  }
                  return updatedFileOther;
                });
                onStepSave(
                  StepperEnum.GeneralDetail,
                  updatedGeneralDetail,
                  requestType.propertyChange
                );
                break;

              // default:

              //   break;
            }
            onStepSave(
              StepperEnum.GeneralDetail,
              updatedGeneralDetail,
              requestType.propertyChange
            );


            // setGeneralDetail(generalDetail);
            setPdfOnly({ ...pdfOnly, [type]: false });
          }
        });
      } else {
        indexes.push(file.name);
        setPdfOnly({ ...pdfOnly, [type]: true });
      }
    }

    if (indexes.length) {
      indexes.map((ind) => {
        const position: number = files.findIndex((file) => file.name === ind);
        files.splice(position, 1);
      });
    }
  };

  const on_Limited_FileUploads = (files: Array<File>, type: string) => {
    let indexes: Array<string> = [];
    let updatedGeneralDetail = Object.assign({}, generalDetail);

    if (files.length > 10) {
      setshowIncubator_limited_Upload_Model(true);
      return;
    } else {
          const user: any = getSessionStorageValue("user");
          const userInfo = JSON.parse(user)
          const formData = new FormData();
          formData.append("application_id", applicationId.toString());
          formData.append("file_type", type);
          formData.append("logged_user_id", userInfo.id)
      for (let i=0; i<=files.length; i++) {
        // if already upladed then skip
        const fileData = generalDetail as any;

        // const fileExists = fileData[type].findIndex(
        //   (uf: FileUploadModel) => uf.file.name === file.name
        // );
        // if (fileExists > -1) continue;
        let updatedFileData: Array<FileUploadModel> = [];

        if (files[i].type === "application/pdf") {
          // Update the formData object
          formData.append("file", files[i], files[i].name);
        }
        if(i == files.length-1){
          setLoader(true);
          post(FILE_UPLOAD, formData).then((response: any) => {
            if (!response.data) return;
            setLoader(false);
            functions(applicationId);
          });
        }
      }
    }


    if (indexes.length) {
      indexes.map((ind) => {
        const position: number = files.findIndex((file) => file.name === ind);
        files.splice(position, 1);
      });
    }
  };

  const onFileUploadAuditedReport = (files: Array<File>, type: string) => {
    let indexes: Array<string> = [];
    let updatedGeneralDetail = Object.assign({}, generalDetail);


    if (files.length > 10) {
      setShowIncubatorFailureModal(true);
      return;
    } else if (files.length <= 10) {
      for (let file of files) {
        // if already upladed then skip
        const fileData = generalDetail as any;

        // const fileExists = fileData[type].findIndex(
        //   (uf: FileUploadModel) => uf.file.name === file.name
        // );
        // if (fileExists > -1) continue;
        let updatedFileData: Array<FileUploadModel> = [];

        if (file.type === "application/pdf") {
          const user: any = getSessionStorageValue("user");
          const userInfo = JSON.parse(user)
          const formData = new FormData();
          // Update the formData object
          formData.append("file", file);
          formData.append("application_id", applicationId.toString());
          formData.append("file_type", type);
          formData.append("logged_user_id", userInfo.id)
          setLoader(true);
          post(FILE_UPLOAD, formData).then((response: any) => {
            if (!response.data) return;
            setLoader(false);
            // console.log(response.data.data,"sri@@")
            if (response.data.result.message == "File already exists.") {
              // setFailDialogData({
              //     "header":"Oops",
              //     "text":response.data.result.message     
              //   })
              //   setShowIncubatorFailureModal1(true);  
              return;

            } else {

              switch (type) {
                case UploadTypeEnum.AnnualReport:
                  // generalDetail.annual_report = [
                  //   ...generalDetail.annual_report,
                  //   new FileUploadModel(response.data.result.file_id, 0, file),
                  //   // new FileUploadModel(0, 0, file),
                  // ];
                  // setGeneralDetail(JSON.parse(JSON.stringify(generalDetail)));
                  updatedFileData = [
                    ...updatedGeneralDetail.annual_report.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                    new FileUploadModel(response.data.data.file_id, 0, file)
                  ];
                  updatedGeneralDetail.annual_report = updatedFileData
                  setGeneralDetail(prevState => {
                    const updatedFileOther = {
                      ...prevState,
                      annual_report: [
                        ...prevState.annual_report.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                        new FileUploadModel(response.data.data.file_id, 0, file)
                      ]
                    }
                    return updatedFileOther;
                  });
                  onStepSave(
                    StepperEnum.GeneralDetail,
                    updatedGeneralDetail,
                    requestType.propertyChange
                  );
                  break;

                case UploadTypeEnum.ProofAssistance:
                  updatedFileData = [
                    ...updatedGeneralDetail.proof_assistance.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                    new FileUploadModel(response.data.data.file_id, 0, file)
                  ];
                  updatedGeneralDetail.proof_assistance = updatedFileData
                  // generalDetail.proof_assistance = [
                  //   ...generalDetail.proof_assistance,
                  //   new FileUploadModel(response.data.result.file_id, 0, file),
                  //   // new FileUploadModel(0, 0, file),
                  // ];
                  // setGeneralDetail(JSON.parse(JSON.stringify(generalDetail)));
                  setGeneralDetail(prevState => {
                    const updatedFileOther = {
                      ...prevState,
                      proof_assistance: [
                        ...prevState.proof_assistance.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                        new FileUploadModel(response.data.data.file_id, 0, file)
                      ]
                    }
                    return updatedFileOther;
                  });
                  onStepSave(
                    StepperEnum.GeneralDetail,
                    updatedGeneralDetail,
                    requestType.propertyChange
                  );
                  break;

                case UploadTypeEnum.IncorporationCertificate:
                  updatedFileData = [
                    ...updatedGeneralDetail.incorporation_certificate.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                    new FileUploadModel(response.data.data.file_id, 0, file)
                  ];
                  updatedGeneralDetail.incorporation_certificate = updatedFileData
                  // generalDetail.incorporation_certificate = [
                  //   ...generalDetail.incorporation_certificate,
                  //   new FileUploadModel(response.data.result.file_id, 0, file),
                  //   // new FileUploadModel(0, 0, file),
                  // ];
                  // setGeneralDetail(JSON.parse(JSON.stringify(generalDetail)));
                  setGeneralDetail(prevState => {
                    const updatedFileOther = {
                      ...prevState,
                      incorporation_certificate: [
                        ...prevState.incorporation_certificate.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                        new FileUploadModel(response.data.data.file_id, 0, file)
                      ]
                    }
                    return updatedFileOther;
                  });
                  onStepSave(
                    StepperEnum.GeneralDetail,
                    updatedGeneralDetail,
                    requestType.propertyChange
                  );
                  break;

                case UploadTypeEnum.PanCardUpload:
                  updatedFileData = [
                    ...updatedGeneralDetail.pan_card_upload.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                    new FileUploadModel(response.data.data.file_id, 0, file)
                  ];
                  updatedGeneralDetail.pan_card_upload = updatedFileData
                  setGeneralDetail(prevState => {
                    const updatedFileOther = {
                      ...prevState,
                      pan_card_upload: [
                        ...prevState.pan_card_upload.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                        new FileUploadModel(response.data.data.file_id, 0, file)
                      ]
                    }
                    return updatedFileOther;
                  });
                  onStepSave(
                    StepperEnum.GeneralDetail,
                    updatedGeneralDetail,
                    requestType.propertyChange
                  );
                  break;

                case UploadTypeEnum.AuthorisationLetterUpload:
                  updatedFileData = [
                    ...updatedGeneralDetail.authorisation_letter_upload.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                    new FileUploadModel(response.data.data.file_id, 0, file)
                  ];
                  updatedGeneralDetail.authorisation_letter_upload = updatedFileData
                  setGeneralDetail(prevState => {
                    const updatedFileOther = {
                      ...prevState,
                      authorisation_letter_upload: [
                        ...prevState.authorisation_letter_upload.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                        new FileUploadModel(response.data.data.file_id, 0, file)
                      ]
                    }
                    return updatedFileOther;
                  });
                  onStepSave(
                    StepperEnum.GeneralDetail,
                    updatedGeneralDetail,
                    requestType.propertyChange
                  );
                  break;

                case UploadTypeEnum.AdhaarCardUpload:
                  updatedFileData = [
                    ...updatedGeneralDetail.adhaar_card_upload.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                    new FileUploadModel(response.data.data.file_id, 0, file)
                  ];
                  updatedGeneralDetail.adhaar_card_upload = updatedFileData
                  setGeneralDetail(prevState => {
                    const updatedFileOther = {
                      ...prevState,
                      adhaar_card_upload: [
                        ...prevState.adhaar_card_upload.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                        new FileUploadModel(response.data.data.file_id, 0, file)
                      ]
                    }
                    return updatedFileOther;
                  });
                  onStepSave(
                    StepperEnum.GeneralDetail,
                    updatedGeneralDetail,
                    requestType.propertyChange
                  );
                  break;

                case UploadTypeEnum.StartupIncubationUpload:
                  updatedFileData = [
                    ...updatedGeneralDetail.startup_incubation_upload.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                    new FileUploadModel(response.data.data.file_id, 0, file)
                  ];
                  updatedGeneralDetail.startup_incubation_upload = updatedFileData
                  setGeneralDetail(prevState => {
                    const updatedFileOther = {
                      ...prevState,
                      startup_incubation_upload: [
                        ...prevState.startup_incubation_upload.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                        new FileUploadModel(response.data.data.file_id, 0, file)
                      ]
                    }
                    return updatedFileOther;
                  });
                  onStepSave(
                    StepperEnum.GeneralDetail,
                    updatedGeneralDetail,
                    requestType.propertyChange
                  );
                  break;

                // default:

                //   break;
              }
              onStepSave(
                StepperEnum.GeneralDetail,
                updatedGeneralDetail,
                requestType.propertyChange
              );


              // setGeneralDetail(generalDetail);
              setPdfOnly({ ...pdfOnly, [type]: false });
            }
          });
        } else {
          indexes.push(file.name);
          setPdfOnly({ ...pdfOnly, [type]: true });
        }
      }
    }


    if (indexes.length) {
      indexes.map((ind) => {
        const position: number = files.findIndex((file) => file.name === ind);
        files.splice(position, 1);
      });
    }
  };

  const getExcelFilePath = () => {
    get(EVALUATION_FILE).then((response: any) => {
      if (!response.data) return;
      setAuthorisationLetterPath(response.data.authorisationLetterPath);
    });
  }

  // Triggers when delete file is clicked
  const onFileDelete = (file: File, type: string) => {
    const {
      annual_report,
      proof_assistance,
      incorporation_certificate,
      pan_card_upload,
      authorisation_letter_upload,
      adhaar_card_upload,
      startup_incubation_upload
    } = generalDetail;
    let remainingFiles: Array<FileUploadModel> = [];
    let fileToBeDeleted: any;
    // console.log(file,annual_report,"file@@@")
    switch (type) {
      case UploadTypeEnum.AnnualReport:
        fileToBeDeleted = annual_report.find(
          (uploadedFile: FileUploadModel) => getFileDetails(uploadedFile.file, 'name') === file.name
        );

        if (!fileToBeDeleted) return;
        remainingFiles = annual_report.filter(
          (uf: FileUploadModel) => uf.file !== fileToBeDeleted.file
        );

        generalDetail.annual_report = remainingFiles;
        onStepSave(
          StepperEnum.GeneralDetail,
          generalDetail,
          requestType.propertyChange
        );
        break;

      case UploadTypeEnum.ProofAssistance:
        fileToBeDeleted = proof_assistance.find(
          (uploadedFile: FileUploadModel) => getFileDetails(uploadedFile.file, 'name') === file.name
        );

        if (!fileToBeDeleted) return;
        remainingFiles = proof_assistance.filter(
          (uf: FileUploadModel) => uf.file !== fileToBeDeleted.file
        );
        generalDetail.proof_assistance = remainingFiles;
        onStepSave(
          StepperEnum.GeneralDetail,
          generalDetail,
          requestType.propertyChange
        );
        break;

      case UploadTypeEnum.IncorporationCertificate:
        fileToBeDeleted = incorporation_certificate.find(
          (uploadedFile: FileUploadModel) => getFileDetails(uploadedFile.file, 'name') === file.name
        );

        if (!fileToBeDeleted) return;
        remainingFiles = incorporation_certificate.filter(
          (uf: FileUploadModel) => uf.file_id !== fileToBeDeleted.file_id
        );
        generalDetail.incorporation_certificate = remainingFiles;
        onStepSave(
          StepperEnum.GeneralDetail,
          generalDetail,
          requestType.propertyChange
        );
        break;

      case UploadTypeEnum.PanCardUpload:
        fileToBeDeleted = pan_card_upload.find(
          (uploadedFile: FileUploadModel) => getFileDetails(uploadedFile.file, 'name') === file.name
        );

        if (!fileToBeDeleted) return;
        remainingFiles = pan_card_upload.filter(
          (uf: FileUploadModel) => uf.file_id !== fileToBeDeleted.file_id
        );
        generalDetail.pan_card_upload = remainingFiles;
        onStepSave(
          StepperEnum.GeneralDetail,
          generalDetail,
          requestType.propertyChange
        );
        break;

      case UploadTypeEnum.AuthorisationLetterUpload:
        fileToBeDeleted = authorisation_letter_upload.find(
          (uploadedFile: FileUploadModel) => getFileDetails(uploadedFile.file, 'name') === file.name
        );

        if (!fileToBeDeleted) return;
        remainingFiles = authorisation_letter_upload.filter(
          (uf: FileUploadModel) => uf.file_id !== fileToBeDeleted.file_id
        );
        generalDetail.authorisation_letter_upload = remainingFiles;
        onStepSave(
          StepperEnum.GeneralDetail,
          generalDetail,
          requestType.propertyChange
        );
        break;

      case UploadTypeEnum.AdhaarCardUpload:
        fileToBeDeleted = adhaar_card_upload.find(
          (uploadedFile: FileUploadModel) => getFileDetails(uploadedFile.file, 'name') === file.name
        );

        if (!fileToBeDeleted) return;
        remainingFiles = adhaar_card_upload.filter(
          (uf: FileUploadModel) => uf.file_id !== fileToBeDeleted.file_id
        );
        generalDetail.adhaar_card_upload = remainingFiles;
        onStepSave(
          StepperEnum.GeneralDetail,
          generalDetail,
          requestType.propertyChange
        );
        break;

      case UploadTypeEnum.StartupIncubationUpload:
        fileToBeDeleted = startup_incubation_upload.find(
          (uploadedFile: FileUploadModel) => getFileDetails(uploadedFile.file, 'name') === file.name
        );

        if (!fileToBeDeleted) return;
        remainingFiles = startup_incubation_upload.filter(
          (uf: FileUploadModel) => uf.file_id !== fileToBeDeleted.file_id
        );
        generalDetail.startup_incubation_upload = remainingFiles;
        onStepSave(
          StepperEnum.GeneralDetail,
          generalDetail,
          requestType.propertyChange
        );
        break;
    }

    // delete the file
    setLoader(true);
    // {console.log(fileToBeDeleted,applicationId,"applicationId")}
    post(FILE_REMOVE, {
      application_id: applicationId, // TODO: dynamic value
      file_id: fileToBeDeleted.file_id,
    }).then((response: any) => {
      if (response.data) {
        setLoader(false);
        setGeneralDetail(generalDetail);
      }
    });
    setGeneralDetail(JSON.parse(JSON.stringify(generalDetail)));

    onStepSave(
      StepperEnum.GeneralDetail,
      generalDetail,
      requestType.propertyChange
    );
  };

  const getFileDetails = (file: any, key: string) => {
    let file_name = '';
    file_name =
      file != undefined && file != null && file.length > 1
        ? file.substr(file.lastIndexOf('/') + 1, file.length)
        : '';

    const file_type = file != undefined && file != null && file.length > 1
      ? file.substr(
        file.lastIndexOf('.') + 1,
        file.length
      ) : '';

    if (key == 'name') return file_name;
    if (key == 'file') return file != undefined ? file.toString() : '';
    if (key == 'type') return file != undefined ? file_type.toString() : '';
  };
  // handle all the check-box event
  const handleOnCheck = (e: any) => {
    const value = parseInt(e.target.value);
    const isChecked = e.target.checked;

    // let d = new Date();
    // let year = d.getFullYear();
    // let month = d.getMonth();
    // let day = d.getDate();   

    if (e.target.name === "central_incubator_far_id") {
      const controlIndex = govAssistedIncubators.findIndex(
        (gov: any) => gov.centralStateIncubatorId === value
      );

      if (controlIndex > -1) {
        govAssistedIncubators[controlIndex].isChecked = isChecked;
      }

      // checkbox option 3 set disable to true for file upload and deselect all other checkboxes.
      if (value === 326 && isChecked == true) {
        // generalDetail.registrationDate = new Date(year - 3, month, day);
        generalDetail.registrationDate = new Date();
        generalDetail.operationalization_date = new Date();
        setOperalizationDate(3);
        setIsDisabled(true);
        generalDetail.proof_assistance = [];
        generalDetail.nature_assistance_incubator = "";
        govAssistedIncubators
          .filter((gov: any) => gov.centralStateIncubatorId !== value)
          .map((g: any) => (g.isChecked = false));
      }

      if (value === 326 && isChecked == false) {
        generalDetail.registrationDate = new Date();
        generalDetail.operationalization_date = new Date();
      }

      if ((value === 324 || value === 325) && isChecked == false) {
        generalDetail.registrationDate = new Date();
        generalDetail.operationalization_date = new Date();
      }

      // if first two options of checkbox are clicked then uncheck the option 3
      if ((value === 324 || value === 325) && isChecked == true) {
        generalDetail.registrationDate = new Date();
        generalDetail.operationalization_date = new Date();
        setOperalizationDate(2);
        setIsDisabled(false);
        govAssistedIncubators
          .filter((gov: any) => gov.centralStateIncubatorId == 326)
          .map((g: any) => (g.isChecked = false));
      }

      // get selected checkbox ids.
      const selectedGovAssitIncubatorIds = govAssistedIncubators
        .filter((gov: any) => gov.isChecked)
        .map((g: any) => g.centralStateIncubatorId);

      // assign it to object property
      generalDetail.central_incubator_far_id = selectedGovAssitIncubatorIds as Array<number>;

      setGovAssistedIncubators(
        JSON.parse(JSON.stringify(govAssistedIncubators))
      );
      onStepSave(
        StepperEnum.GeneralDetail,
        generalDetail,
        requestType.propertyChange
      );
    }
  };
  const history = useHistory();

  const applicationPage = () => {
    history.push({
      pathname: "/incubator_status",
    });
  }
  // triggers when user clicks on the save and next button
  const onSave = () => {
    // before passing data to the parent component do validate the form
    // if(!isValid()) return;
    generalDetail.incubator_name = generalDetail.incubator_name
      ? generalDetail.incubator_name
      : user?.name;
    onStepSave(StepperEnum.GeneralDetail, generalDetail, requestType.save);

    stepper && stepper.next();
    window.scrollTo(0, 250);
  };

  const add_years = (dt: Date, n: number) => {
    //console.log("Number", n, dt);
    return new Date(dt.setFullYear(dt.getFullYear() - n));
  };

  const validateSocialMediaURL = (url: string, website: string) => {
    if (
      (url?.length !== 0 &&
        validateSocialURL(url) !== "This is mandatory field" &&
        validateSocialURL(url) !== "Please enter valid URL") ||
      !validateSocialURL(url)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div
      id="incubator-step-1"
      role="tabpanel"
      className="bs-stepper-pane content"
      aria-labelledby="stepper1trigger1"
    >
      {loader &&
        <div className="spinner-border custom-spinner" role="status">
          <span className="sr-only">Loading...</span>
        </div>}
      {/* {//console.log("VALIDATION", props.errors, generalDetail)} */}
      {/* {console.log("GENERAL DETAIL MODEL", generalDetail)} */}
      {/* {console.log("GENERAL DETAIL ERROR", props.errors)} */}
      <fieldset disabled={loader}>
        <div className="form-wrapper stepper-form stepper-form-1">
          <div className="form-content">
            <fieldset disabled={disableForm}>
              <div className="form-group required row">
                <label className="col-sm-4 col-form-label">Registered Name</label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    name="registeredName"
                    className={`${props.errors.length &&
                      validateRequiredText(generalDetail.registeredName, 255)
                      ? `form-control error`
                      : "form-control"
                      }`}
                    placeholder="Enter Name"
                    value={generalDetail.registeredName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required={true}
                    maxLength={255}
                  />
                  <div
                    className={`${props.errors.length &&
                      validateRequiredText(generalDetail.registeredName, 255)
                      ? `error`
                      : "invalid-feedback "
                      }`}
                  >
                    {validateRequiredText(generalDetail.registeredName, 255)}
                  </div>
                </div>
              </div>
              <div className="form-group required row">
                <label className="col-sm-4 col-form-label">Incubator Name</label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    name="incubator_name"
                    className="form-control"
                    placeholder={
                      user?.name
                        ? user?.name
                        : "Enter Name"
                    }
                    // value={user?.name}
                    value={
                      generalDetail.incubator_name
                    }
                    onChange={handleChange}
                  // disabled={true}
                  />
                </div>
              </div>

              <div className="form-group required row">
                <label className="col-sm-4 col-form-label">Industry</label>
                <div className="col-sm-8">
                  <MultiSelect
                    id="industries"
                    className="multi-select"
                    options={industries}
                    defaultText="Select Industry"
                    onClose={(selectedIds: string[]) =>
                      onMultiSelectChange(selectedIds, "industryIds")
                    }
                    selectedValues={
                      generalDetail.industryIds ? generalDetail.industryIds : []
                    }
                    hasError
                    errorText=""
                    searchable
                  />
                  <div
                    className={`${props.errors.length && !generalDetail.industryIds.length
                      ? `col-12 pl-0 error`
                      : "invalid-feedback "
                      }`}
                  >
                    This is mandatory field
                  </div>
                </div>
              </div>

              <div className="form-group required row">
                <label className="col-sm-4 col-form-label">Sector</label>
                <div className="col-sm-8">
                  <MultiSelect
                    id="industrySectors"
                    className="multi-select"
                    options={industrySectors}
                    defaultText="Select Sector"
                    onClose={(selectedIds: string[]) =>
                      onMultiSelectChange(selectedIds, "sectorIds")
                    }
                    selectedValues={
                      generalDetail.sectorIds ? generalDetail.sectorIds : []
                    }
                    hasError
                    errorText=""
                    searchable
                  />
                  <div
                    className={`${props.errors.length && !generalDetail.sectorIds.length
                      ? `col-12  pl-0 error`
                      : "invalid-feedback "
                      }`}
                  >
                    This is mandatory field
                  </div>
                </div>
              </div>

              <hr />

              <div className="form-group required row">
                <label className="col-sm-4 col-form-label">
                  Address of Incubator
                </label>
                <div className="col-sm-8">
                  <div className="form-row">
                    <div className="col select-custom">
                      <select
                        className={`${props.errors.indexOf("stateId") > -1
                          ? `form-control error`
                          : "form-control"
                          }`}
                        aria-label="Select State"
                        id="State"
                        name="stateId"
                        value={generalDetail?.stateId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Select State</option>
                        {/* {console.log(countryStates,"countryStates")} */}
                        {countryStates.map(({ label, value }) => (
                          <option key={label} value={value}>
                            {label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col select-custom">
                      <select
                        className={`${props.errors.indexOf("cityId") > -1
                          ? `form-control error`
                          : "form-control"
                          }`}
                        aria-label="Select City"
                        id="City"
                        name="cityId"
                        value={generalDetail?.cityId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Select City</option>
                        {stateDistricts.map(({ label, value }) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {/* New Addition */}
                  <div className="form-row mb-0">
                    <div className="col">
                      <textarea
                        className={`${props.errors.length &&
                          validateRequiredText(generalDetail.address, 1000)
                          ? `form-control error`
                          : "form-control"
                          }`}
                        rows={3}
                        placeholder="Full Address (max. 1000 characters)"
                        name="address"
                        value={generalDetail.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={true}
                        maxLength={1000}
                      ></textarea>
                    </div>
                  </div>
                  <div
                    className={`${props.errors.length &&
                      validateRequiredText(generalDetail.address, 1000)
                      ? `error`
                      : "invalid-feedback "
                      }`}
                  >
                    {validateRequiredText(generalDetail.address, 1000)}
                  </div>
                </div>
              </div>

              <hr />

              {/* New Addition */}
              <div className="form-group required row">
                <label className="col-sm-4 col-form-label">Website</label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    name="website_url"
                    className={`${props.errors.length && validateURL(generalDetail.website_url)
                      ? `form-control error`
                      : "form-control"
                      }`}
                    placeholder="Enter the URL"
                    value={generalDetail.website_url}
                    onChange={handleChange}
                  />
                  <div
                    className={`${props.errors.length && validateURL(generalDetail.website_url)
                      ? `col-12 pl-0 error`
                      : "invalid-feedback "
                      }`}
                  >
                    {validateURL(generalDetail.website_url)}
                  </div>
                </div>
              </div>

              <hr />
              <div className="form-group row">
                <label className="col-sm-4 col-form-label">
                  Social Media
                  <small className="pt-1">(Optional)</small>
                </label>
                <div className="col-sm-8">
                  <div className="form-row">
                    <div className="col">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          id="customcheckbox1"
                          name="facebook_enable"
                          className="custom-control-input"
                          onClick={(event: any) => {
                            handleOnClick(event);
                          }}
                          checked={generalDetail.facebook_enable}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customcheckbox1"
                        >
                          Facebook
                        </label>
                      </div>
                    </div>
                    <div className="col">
                      {generalDetail.facebook_enable && (
                        <input
                          type="text"
                          id="facebook_link"
                          name="facebook_link"
                          className={
                            props.errors.length &&
                              openTextOnCheckbox &&
                              openTextOnCheckbox["Facebook"] &&
                              !validateSocialMediaURL(
                                generalDetail["facebook_link"],
                                "Facebook"
                              )
                              ? `form-control error`
                              : "form-control"
                          }
                          placeholder="Enter the link"
                          value={generalDetail["facebook_link"]}
                          onChange={handleChange}
                        />
                      )}
                      <div
                        className={`${props.errors.length &&
                          props.errors.indexOf("facebook_link") > -1 &&
                          openTextOnCheckbox &&
                          openTextOnCheckbox["Facebook"] &&
                          validateSocialURL(generalDetail["facebook_link"])
                          ? `col-12 pl-0 error`
                          : "invalid-feedback "
                          }`}
                      >
                        {validateSocialURL(generalDetail["facebook_link"])}
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          id="customcheckbox2"
                          name="linkedin_enable"
                          className="custom-control-input"
                          onClick={(event: any) => {
                            handleOnClick(event);
                          }}
                          checked={generalDetail.linkedin_enable}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customcheckbox2"
                        >
                          Linkedin
                        </label>
                      </div>
                    </div>
                    <div className="col">
                      {generalDetail.linkedin_enable && (
                        <input
                          type="text"
                          id="linkedin_link"
                          name="linkedin_link"
                          className={
                            props.errors.length &&
                              openTextOnCheckbox &&
                              openTextOnCheckbox["Linkedin"] &&
                              !validateSocialMediaURL(
                                generalDetail["linkedin_link"],
                                "Linkedin"
                              )
                              ? `form-control error`
                              : "form-control"
                          }
                          placeholder="Enter the link"
                          value={generalDetail["linkedin_link"]}
                          onChange={handleChange}
                        />
                      )}
                      <div
                        className={`${props.errors.length &&
                          openTextOnCheckbox &&
                          openTextOnCheckbox["Linkedin"] &&
                          validateSocialURL(generalDetail["linkedin_link"])
                          ? `col-12 pl-0 error`
                          : "invalid-feedback "
                          }`}
                      >
                        {validateSocialURL(generalDetail["linkedin_link"])}
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          id="customcheckbox3"
                          name="twitter_enable"
                          className="custom-control-input"
                          onClick={(event: any) => {
                            handleOnClick(event);
                          }}
                          checked={generalDetail.twitter_enable}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customcheckbox3"
                        >
                          Twitter
                        </label>
                      </div>
                    </div>
                    <div className="col">
                      {generalDetail.twitter_enable && (
                        <input
                          type="text"
                          id="twitter_link"
                          name="twitter_link"
                          className={
                            props.errors.length &&
                              openTextOnCheckbox &&
                              openTextOnCheckbox["Twitter"] &&
                              !validateSocialMediaURL(
                                generalDetail["twitter_link"],
                                "Twitter"
                              )
                              ? `form-control error`
                              : "form-control"
                          }
                          placeholder="Enter the link"
                          value={generalDetail["twitter_link"]}
                          onChange={handleChange}
                        />
                      )}
                      <div
                        className={`${props.errors.length &&
                          openTextOnCheckbox &&
                          openTextOnCheckbox["Twitter"] &&
                          validateSocialURL(generalDetail["twitter_link"])
                          ? `col-12 pl-0 error`
                          : "invalid-feedback "
                          }`}
                      >
                        {validateSocialURL(generalDetail["twitter_link"])}
                      </div>
                    </div>
                  </div>

                  <div className="form-row mb-0">
                    <div className="col">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          id="customcheckbox4"
                          name="youtube_enable"
                          className="custom-control-input"
                          onClick={(event: any) => {
                            handleOnClick(event);
                          }}
                          checked={generalDetail.youtube_enable}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customcheckbox4"
                        >
                          Youtube
                        </label>
                      </div>
                    </div>
                    <div className="col">
                      {generalDetail.youtube_enable && (
                        <input
                          type="text"
                          id="youtube_link"
                          name="youtube_link"
                          className={
                            props.errors.length &&
                              openTextOnCheckbox &&
                              openTextOnCheckbox["Youtube"] &&
                              !validateSocialMediaURL(
                                generalDetail["youtube_link"],
                                "Youtube"
                              )
                              ? `form-control error`
                              : "form-control"
                          }
                          placeholder="Enter the link"
                          value={generalDetail["youtube_link"]}
                          onChange={handleChange}
                        />
                      )}
                      <div
                        className={`${props.errors.length &&
                          openTextOnCheckbox &&
                          openTextOnCheckbox["Youtube"] &&
                          validateSocialURL(generalDetail["youtube_link"])
                          ? `col-12 pl-0 error`
                          : "invalid-feedback "
                          }`}
                      >
                        {validateSocialURL(generalDetail["youtube_link"])}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* New Addition */}

              <hr />

              {/* New Addition */}
              <div className="form-group required row">
                <label className="col-sm-4 col-form-label">
                  Name of Representative
                </label>
                <div className="col-sm-8">
                  <div className="form-row">
                    <div className="col">
                      <input
                        type="text"
                        name="representative_name"
                        className={`${props.errors.length &&
                          validateRequiredText(
                            generalDetail.representative_name,
                            255
                          )
                          ? `form-control error`
                          : "form-control"
                          }`}
                        placeholder="Representative Name"
                        value={generalDetail.representative_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={true}
                        maxLength={255}
                      />
                    </div>
                    <div
                      className={`${props.errors.length &&
                        validateRequiredText(generalDetail.representative_name, 255)
                        ? `col-12 col-12 pl-0 error`
                        : "invalid-feedback "
                        }`}
                    >
                      {validateRequiredText(generalDetail.representative_name, 255)}
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-6">
                      <input
                        type="text"
                        name="representative_designation"
                        className={`${props.errors.length &&
                          validateRequiredText(
                            generalDetail.representative_designation,
                            50
                          )
                          ? `form-control error`
                          : "form-control"
                          }`}
                        placeholder="Designation"
                        value={generalDetail.representative_designation}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={true}
                        maxLength={50}
                      />
                    </div>
                    <div
                      className={`${props.errors.length &&
                        validateRequiredText(
                          generalDetail.representative_designation,
                          255
                        )
                        ? `col-12 col-12 pl-0 error`
                        : "invalid-feedback "
                        }`}
                    >
                      {validateRequiredText(
                        generalDetail.representative_designation,
                        255
                      )}
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-6">
                      <div className="input-group mb-2 mr-sm-2 mb-sm-0">
                        <span className="input-group-addon">+91</span>
                        <input
                          type="text"
                          name="representative_mobile_no"
                          className={`${props.errors.length &&
                            validateMobile(generalDetail.representative_mobile_no)
                            ? `form-control error`
                            : "form-control"
                            }`}
                          placeholder="Ten digit mobile number"
                          value={generalDetail.representative_mobile_no}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required={true}
                        />
                      </div>
                    </div>
                    <div
                      className={`${props.errors.length &&
                        validateMobile(generalDetail.representative_mobile_no)
                        ? `col-12 pl-0 error`
                        : "invalid-feedback "
                        }`}
                    >
                      {validateMobile(generalDetail.representative_mobile_no)}
                    </div>
                  </div>

                  <div className="form-row mb-0">
                    <div className="col">
                      <input
                        type="text"
                        name="representative_email"
                        className={`${props.errors.length &&
                          validateEmail(generalDetail.representative_email)
                          ? `form-control error`
                          : "form-control"
                          }`}
                        placeholder="Enter Email ID"
                        value={generalDetail.representative_email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={true}
                      />
                    </div>
                  </div>
                  <div
                    className={`${props.errors.length &&
                      validateEmail(generalDetail.representative_email)
                      ? `error`
                      : "invalid-feedback "
                      }`}
                  >
                    {validateEmail(generalDetail.representative_email)}
                  </div>
                </div>
              </div>

              <hr />

              <div className="form-group required row">
                <div className="col-form-label col-sm-4">Type of Legal Entity</div>

                <div className="col-sm-6">
                  {legalEntities.map(({ typeLegalEntityId, attribute_value }) => (
                    <div
                      className="custom-control custom-radio"
                      key={typeLegalEntityId}
                    >
                      <input
                        type="radio"
                        className="custom-control-input"
                        id={typeLegalEntityId}
                        value={typeLegalEntityId}
                        checked={typeLegalEntityId == legalEntityId}
                        name="typeLegalEntityId"
                        onChange={handleChange}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={typeLegalEntityId}
                      >
                        {attribute_value}
                      </label>
                    </div>
                  ))}
                  <div
                    className={`${props.errors.indexOf("typeLegalEntityId") > -1
                      ? `col-12 pl-0 error`
                      : "invalid-feedback "
                      }`}
                  >
                    This is mandatory field
                  </div>
                </div>
              </div>

              <hr />

              <div className="form-group required row">
                <div className="col-form-label col-sm-4">
                  Has a Central/State Government assisted your incubator so far?
                </div>
                <div className="col-sm-6">
                  {govAssistedIncubators.map(
                    (
                      { centralStateIncubatorId, attribute_value, isChecked },
                      index
                    ) => (
                      <div
                        className="custom-control custom-checkbox"
                        key={centralStateIncubatorId}
                      >

                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id={`centralStateIncubatorId_${centralStateIncubatorId.toString()}`}
                          value={centralStateIncubatorId.toString()}
                          // checked={generalDetailModel.central_incubator_far_id.indexOf(centralStateIncubatorId.toString()) > -1}
                          checked={isChecked}
                          name="central_incubator_far_id"
                          onChange={(e) => {
                            handleOnCheck(e);
                            if (
                              centralStateIncubatorId === 324 ||
                              centralStateIncubatorId === 325
                            ) {
                              set_no_startups_incubation(5);
                            } else if (centralStateIncubatorId === 326) {
                              set_no_startups_incubation(10);
                            }
                          }}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={`centralStateIncubatorId_${centralStateIncubatorId}`}
                        >
                          {attribute_value}
                        </label>
                      </div>
                    )
                  )}
                  <div
                    className={`${props.errors.indexOf("central_incubator_far_id") > -1
                      ? `error`
                      : "invalid-feedback "
                      }`}
                  >
                    This is mandatory field
                  </div>
                </div>
              </div>

              {/* New Addition */}
              <div className="form-group required row">
                <label
                  className={
                    isDisabled
                      ? "col-sm-4 col-form-label disabled"
                      : "col-sm-4 col-form-label"
                  }
                >
                  Nature of Assistance provided to incubator
                </label>
                <div className="col-sm-8">
                  <div>
                    <textarea
                      className="form-control"
                      rows={3}
                      placeholder="Describe in max. 1000 characters"
                      name="nature_assistance_incubator"
                      value={generalDetail.nature_assistance_incubator}
                      onChange={handleChange}
                      disabled={isDisabled}
                      required={true}
                      maxLength={1000}
                    ></textarea>
                  </div>
                  <div
                    className={`${props.errors.length &&
                      props.errors.indexOf("nature_assistance_incubator") > -1
                      ? `col-12 pl-0 error`
                      : "invalid-feedback "
                      }`}
                  >
                    This is mandatory field
                  </div>
                </div>
              </div>
            </fieldset>

            <div className="form-group required row">
              <label
                className={
                  isDisabled
                    ? "col-sm-4 col-form-label disabled"
                    : "col-sm-4 col-form-label"
                }
              >
                Proof of assistance from Central/State Government
              </label>
              <div className="col-sm-8">
                <div className="">
                  {/* <input type="file" className="form-control-file btn btn-sisfs btn-transparent-primary" id="exampleFormControlFile1" /> */}
                  <fieldset disabled={disableForm}>
                    <button
                      className="btn btn-sisfs btn-transparent-primary"
                      onClick={() => setOpenUploadBlock({ ProofAssistance: true })}
                      disabled={isDisabled}
                    >
                      Upload
                    </button>
                    {((openUploadBlock &&
                      openUploadBlock["ProofAssistance"] &&
                      generalDetail.proof_assistance.length === 0) ||
                      generalDetail.proof_assistance.length > 0) && (
                        <>
                          <span
                            className="close"
                            onClick={() => setOpenUploadBlock({ ProofAssistance: false })}
                          >
                            <i className="fa fa-close"></i>
                          </span>
                          <FileUploader
                            // key={UploadTypeEnum.ProofAssistance}
                            key={Math.random().toString()}
                            id={UploadTypeEnum.ProofAssistance}
                            information="Drag & Drop the file or Browse"
                            fileType="application/pdf"
                            buttonLabel="Browse"
                            multiple={true}
                            enablePreview
                            IconComponent={() => (
                              <span className="fa fa-cloud-upload"></span>
                            )}
                            removeIconClass=""
                            existingFiles={generalDetail.proof_assistance.map(
                              (uploadedFile: FileUploadModel) =>
                              ({
                                name: getFileDetails(uploadedFile.file, 'name') || uploadedFile.file.name,
                                url: uploadedFile.file
                              })
                              // uploadedFile.file
                            )}
                            onUploadFinish={(files: Array<File>) => {
                              on_Limited_FileUploads(files, UploadTypeEnum.ProofAssistance);
                            }}
                            onFileDelete={(deletedFile: File) =>
                              onFileDelete(
                                deletedFile,
                                UploadTypeEnum.ProofAssistance
                              )
                            }
                          />
                          {/* <strong>file Uploader for proof</strong> */}
                        </>
                      )}
                  </fieldset>
                  {/* && generalDetail.proof_assistance.some((item) => item.file_name) */}
                  {(generalDetail.proof_assistance.length > 0 && generalDetail.proof_assistance[0].file_type_id != 0) ? (
                    <a
                      className="incubator-details-link"
                      data-toggle="modal"
                      data-target="#showMoreDocument"
                      onClick={() =>
                        setMeetingModal('Proof of Assistance')
                      }>
                      View uploaded Documents
                    </a>
                  ) : (
                    ''
                  )}
                  {/* <div>
                      {generalDetail.proof_assistance.map((uploadedFile) => (
                          <div key={`${uploadedFile.file}`} >
                              <span className="pdfText">{getFileDetails(uploadedFile.file, 'name')} - </span> 
                              <a href={`${uploadedFile.file}`} download target="blank" className="orange-link-text">
                                  View 
                              </a>
                          </div>
                      ))}
                  </div> */}
                  <small>Supported file format - PDF only</small>
                  <div
                    className={`${props.errors.length &&
                      !generalDetail.proof_assistance.length
                      && !isDisabled
                      ? `col-12 pl-0 error`
                      : "invalid-feedback "
                      }`}
                  >
                    This is mandatory field
                  </div>

                  <div
                    className={`${pdfOnly?.proof_assistance
                      ? `col-12 pl-0 error`
                      : "invalid-feedback "
                      }`}
                  >
                    Only PDF files are allowed
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <fieldset disabled={disableForm}>
              <div className="form-group required row">
                <label className="col-sm-4 col-form-label">
                  Incorporation/Registration Date
                </label>

                <div className="col-sm-4">
                  <ReactDatePicker
                    id="registrationDate"
                    name="registrationDate"
                    className={`${props.errors.indexOf("registrationDate") > -1
                      ? `form-control error`
                      : "form-control"
                      }`}
                    dateFormat="yyyy-MM-dd"
                    // value={
                    //   (new Date().getTime() -
                    //     new Date(generalDetail.registrationDate).getTime()) /
                    //     (1000 * 3600 * 24) >
                    //     Math.abs(operalizationDate * 365)
                    //     ? moment(generalDetail.registrationDate).format(
                    //       "YYYY-MM-DD"
                    //     )
                    //     : ""
                    // }
                    value={
                      moment(generalDetail.registrationDate).format(
                        "YYYY-MM-DD"
                      ) ? moment(generalDetail.registrationDate).format(
                        "YYYY-MM-DD"
                      ) : ""
                    }
                    onChange={(date: Date) => {
                      handleDateChange(date, "registrationDate");
                    }}
                    showMonthDropdown
                    showYearDropdown
                  // maxDate={add_years(
                  //   new Date(),
                  //   operalizationDate
                  // )}
                  // maxDate={add_years(
                  //   generalDetail.registrationDate
                  //     ? new Date(generalDetail.registrationDate)
                  //     : new Date(),
                  //   operalizationDate
                  // )}
                  />
                  <div
                    className={`${props.errors.indexOf("registrationDate") > -1
                      ? `col-12 pl-0 error`
                      : "invalid-feedback "
                      }`}
                  >
                    Please enter valid date
                  </div>
                </div>
              </div>

              <div className="form-group required row">
                <label className="col-sm-4 col-form-label">
                  Corporate Identification Number(CIN) / Registration No.
                </label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    name="corporateId"
                    className={`${props.errors.length &&
                      validateRequiredText(generalDetail.corporateId, 255)
                      ? `form-control error`
                      : "form-control"
                      }`}
                    placeholder="Enter the number"
                    value={generalDetail.corporateId}
                    onChange={handleChange}
                  />
                  <div
                    className={`${props.errors.length &&
                      validateRequiredText(generalDetail.corporateId, 255)
                      ? `col-12 pl-0 error`
                      : "invalid-feedback "
                      }`}
                  >
                    {validateRequiredText(generalDetail.corporateId, 255)}
                  </div>
                </div>
              </div>
            </fieldset>
            {/* New Addition Static Added as of now */}
            <div className="form-group required row">
              <label className="col-sm-4 col-form-label">
                Incorporation Certificate
              </label>
              <div className="col-sm-8">
                <div className="">
                  {/* <input type="file" className="form-control-file btn btn-sisfs btn-transparent-primary" id="exampleFormControlFile1" /> */}
                  <fieldset disabled={disableForm}>
                    <button
                      className="btn btn-sisfs btn-transparent-primary"
                      onClick={() =>
                        setOpenUploadBlock({ IncorporationCertificate: true })
                      }
                    >
                      Upload
                    </button>
                    {((openUploadBlock &&
                      openUploadBlock["IncorporationCertificate"] &&
                      generalDetail.incorporation_certificate.length === 0) ||
                      generalDetail.incorporation_certificate.length > 0) && (
                        <>
                          <span
                            className="close"
                            onClick={() =>
                              setOpenUploadBlock({ IncorporationCertificate: false })
                            }
                          >
                            <i className="fa fa-close"></i>
                          </span>
                          <FileUploader
                            // key={UploadTypeEnum.IncorporationCertificate}
                            key={Math.random().toString()}
                            id={UploadTypeEnum.IncorporationCertificate}
                            information="Drag & Drop the file or Browse"
                            fileType="application/pdf"
                            buttonLabel="Browse"
                            multiple={false}
                            enablePreview
                            IconComponent={() => (
                              <span className="fa fa-cloud-upload"></span>
                            )}
                            removeIconClass=""
                            existingFiles={generalDetail.incorporation_certificate.map(
                              (uploadedFile: FileUploadModel) =>
                              ({
                                name: getFileDetails(uploadedFile.file, 'name') || uploadedFile.file.name,
                                url: uploadedFile.file
                              })
                              // uploadedFile.file
                            )}
                            onUploadFinish={(files: Array<File>) => {
                              onFileUpload(
                                files,
                                UploadTypeEnum.IncorporationCertificate
                              );
                            }}
                            onFileDelete={(deletedFile: File) =>
                              onFileDelete(
                                deletedFile,
                                UploadTypeEnum.IncorporationCertificate
                              )
                            }
                          />
                        </>
                      )}
                  </fieldset>
                  {/* && generalDetail.incorporation_certificate.some((item) => item.file_name) */}
                  {(generalDetail.incorporation_certificate.length > 0 && generalDetail.incorporation_certificate[0].file_type_id != 0) ? (
                    <a
                      className="incubator-details-link"
                      data-toggle="modal"
                      data-target="#showMoreDocument"
                      onClick={() =>
                        setMeetingModal('Incorporation Certificate')
                      }>
                      View uploaded Documents
                    </a>
                  ) : (
                    ''
                  )}
                  {/* <div>
                      {generalDetail.incorporation_certificate.map((uploadedFile) => (
                          <div key={`${uploadedFile.file}`} >
                              <span className="pdfText">{getFileDetails(uploadedFile.file, 'name')} - </span> 
                              <a href={`${uploadedFile.file}`} download target="blank" className="orange-link-text">
                                  View 
                              </a>
                          </div>
                      ))}
                  </div> */}
                  <small>Supported file format - PDF only</small>
                  <div
                    className={`${props.errors.length &&
                      !generalDetail.incorporation_certificate.length
                      ? `col-12 pl-0 error`
                      : "invalid-feedback "
                      }`}
                  >
                    This is mandatory field
                  </div>
                  <div
                    className={`${pdfOnly?.incorporation_certificate
                      ? `col-12 pl-0 error`
                      : "invalid-feedback "
                      }`}
                  >
                    Only PDF files are allowed
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group required row">
              <label className="col-sm-4 col-form-label">
                PAN Card of legal entity
              </label>
              <div className="col-sm-8">

                <fieldset disabled={disableForm}>
                  <div className="form-row no-gutters">
                    <div className="col-6">
                      <input
                        type="text"
                        name="pan_card_number"
                        className={`${props.errors.length &&
                          validatePAN(generalDetail.pan_card_number)
                          ? `form-control error`
                          : "form-control"
                          }`}
                        placeholder="Enter PAN Number"
                        value={generalDetail.pan_card_number}
                        onChange={handleChange}
                        required={true}
                        maxLength={255}
                      />
                      <div
                        className={`${props.errors.length &&
                          validatePAN(generalDetail.pan_card_number)
                          ? `col-12 pl-0 error`
                          : "invalid-feedback"
                          }`}
                      >
                        {validatePAN(generalDetail.pan_card_number)}
                      </div>
                    </div>
                  </div>
                </fieldset>

                <div className="form-row">
                  <div className="col-12">
                    <div className="">
                      <fieldset disabled={disableForm}>
                        <button
                          className="btn btn-sisfs btn-transparent-primary"
                          onClick={() =>
                            setOpenUploadBlock({ PanCardUpload: true })
                          }
                        >
                          Upload
                        </button>
                        {((openUploadBlock &&
                          openUploadBlock["PanCardUpload"] &&
                          generalDetail.pan_card_upload.length === 0) ||
                          generalDetail.pan_card_upload.length > 0) && (
                            <>
                              <span
                                className="close"
                                onClick={() =>
                                  setOpenUploadBlock({ PanCardUpload: false })
                                }
                              >
                                <i className="fa fa-close"></i>
                              </span>
                              <FileUploader
                                // key={UploadTypeEnum.IncorporationCertificate}
                                key={Math.random().toString()}
                                id={UploadTypeEnum.PanCardUpload}
                                information="Drag & Drop the file or Browse"
                                fileType="application/pdf"
                                buttonLabel="Browse"
                                multiple={false}
                                enablePreview
                                IconComponent={() => (
                                  <span className="fa fa-cloud-upload"></span>
                                )}
                                removeIconClass=""
                                existingFiles={generalDetail.pan_card_upload.map(
                                  (uploadedFile: FileUploadModel) =>
                                  ({
                                    name: getFileDetails(uploadedFile.file, 'name') || uploadedFile.file.name,
                                    url: uploadedFile.file
                                  })
                                  // uploadedFile.file
                                )}
                                onUploadFinish={(files: Array<File>) => {
                                  onFileUpload(
                                    files,
                                    UploadTypeEnum.PanCardUpload
                                  );
                                }}
                                onFileDelete={(deletedFile: File) =>
                                  onFileDelete(
                                    deletedFile,
                                    UploadTypeEnum.PanCardUpload
                                  )
                                }
                              />
                            </>
                          )}
                      </fieldset>
                      {/* && generalDetail.pan_card_upload.some((item) => item.file_name) */}
                      {(generalDetail.pan_card_upload.length > 0 && generalDetail.pan_card_upload[0].file_type_id != 0) ? (
                        <a
                          className="incubator-details-link"
                          data-toggle="modal"
                          data-target="#showMoreDocument"
                          onClick={() =>
                            setMeetingModal('Pan Card')
                          }>
                          View uploaded Documents
                        </a>
                      ) : (
                        ''
                      )}
                      {/* <div>
                        {generalDetail.pan_card_upload.map((uploadedFile) => (
                            <div key={`${uploadedFile.file}`} >
                                <span className="pdfText">{getFileDetails(uploadedFile.file, 'name')} - </span> 
                                <a href={`${uploadedFile.file}`} download target="blank" className="orange-link-text">
                                    View 
                                </a>
                            </div>
                        ))}
                    </div> */}
                      <small>Supported file format - PDF only</small>
                      <small>Please upload a copy of PAN Card of the legal entity</small>

                      <div
                        className={`${props.errors.length &&
                          !generalDetail.pan_card_upload.length
                          ? `col-12 pl-0 error`
                          : "invalid-feedback "
                          }`}
                      >
                        This is mandatory field
                      </div>
                      <div
                        className={`${pdfOnly?.pan_card_upload
                          ? `col-12 pl-0 error`
                          : "invalid-feedback "
                          }`}
                      >
                        Only PDF files are allowed
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <hr />

            <div className="form-group required row">
              <label className="col-sm-4 col-form-label">
                Board Resolution / Authorisation letter / PoA
              </label>
              <div className="col-sm-8">
                <div className="d-inline-flex upload-pitch">
                  <span className="link-orange-text link-underline"
                  // onClick={() => downloadDocument(authorisationLetterPath, 'Authorisation_Letter')}
                  >
                    {/* <u>Sample Download</u> */}
                    <a style={{ color: '#e26640' }}
                      href={`${NODE_URL}` + authorisationLetterPath}>
                      <u>Sample Download</u>
                    </a>
                  </span>
                </div>

                <div className="">
                  <fieldset disabled={disableForm}>
                    <button
                      className="btn btn-sisfs btn-transparent-primary"
                      onClick={() =>
                        setOpenUploadBlock({ AuthorisationLetterUpload: true })
                      }
                    >
                      Upload
                    </button>
                    {((openUploadBlock &&
                      openUploadBlock["AuthorisationLetterUpload"] &&
                      generalDetail.authorisation_letter_upload.length === 0) ||
                      generalDetail.authorisation_letter_upload.length > 0) && (
                        <>
                          <span
                            className="close"
                            onClick={() =>
                              setOpenUploadBlock({ AuthorisationLetterUpload: false })
                            }
                          >
                            <i className="fa fa-close"></i>
                          </span>
                          <FileUploader
                            // key={UploadTypeEnum.IncorporationCertificate}
                            key={Math.random().toString()}
                            id={UploadTypeEnum.AuthorisationLetterUpload}
                            information="Drag & Drop the file or Browse"
                            fileType="application/pdf"
                            buttonLabel="Browse"
                            multiple={false}
                            enablePreview
                            IconComponent={() => (
                              <span className="fa fa-cloud-upload"></span>
                            )}
                            removeIconClass=""
                            existingFiles={generalDetail.authorisation_letter_upload.map(
                              (uploadedFile: FileUploadModel) =>
                              ({
                                name: getFileDetails(uploadedFile.file, 'name') || uploadedFile.file.name,
                                url: uploadedFile.file
                              })
                              // uploadedFile.file
                            )}
                            onUploadFinish={(files: Array<File>) => {
                              onFileUpload(
                                files,
                                UploadTypeEnum.AuthorisationLetterUpload
                              );
                            }}
                            onFileDelete={(deletedFile: File) =>
                              onFileDelete(
                                deletedFile,
                                UploadTypeEnum.AuthorisationLetterUpload
                              )
                            }
                          />
                        </>
                      )}
                  </fieldset>
                  {/* && generalDetail.authorisation_letter_upload.some((item) => item.file_name) */}
                  {(generalDetail.authorisation_letter_upload.length > 0 && generalDetail.authorisation_letter_upload[0].file_type_id != 0) ? (
                    <a
                      className="incubator-details-link"
                      data-toggle="modal"
                      data-target="#showMoreDocument"
                      onClick={() =>
                        setMeetingModal('Authorisation Letter')
                      }>
                      View uploaded Documents
                    </a>
                  ) : (
                    ''
                  )}
                  {/* <div>
                      {generalDetail.authorisation_letter_upload.map((uploadedFile) => (
                          <div key={`${uploadedFile.file}`} >
                              <span className="pdfText">{getFileDetails(uploadedFile.file, 'name')} - </span> 
                              <a href={`${uploadedFile.file}`} download target="blank" className="orange-link-text">
                                  View 
                              </a>
                          </div>
                      ))}
                    </div> */}
                  <small>Supported file format - PDF only</small>
                  <small>
                    For the person filling this form and signing other self-declaration
                    documents from authorised signatory of the applicant for filling and
                    representing the organisation for this application
                  </small>
                  <div
                    className={`${props.errors.length &&
                      !generalDetail.authorisation_letter_upload.length
                      ? `col-12 pl-0 error`
                      : "invalid-feedback "
                      }`}
                  >
                    This is mandatory field
                  </div>
                  <div
                    className={`${pdfOnly?.authorisation_letter_upload
                      ? `col-12 pl-0 error`
                      : "invalid-feedback "
                      }`}
                  >
                    Only PDF files are allowed
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group required row">
              <label className="col-sm-4 col-form-label">
                Aadhar Card of authorised signatory
              </label>
              <div className="col-sm-8">
                <div className="">
                  <fieldset disabled={disableForm}>
                    <button
                      className="btn btn-sisfs btn-transparent-primary"
                      onClick={() =>
                        setOpenUploadBlock({ AdhaarCardUpload: true })
                      }
                    >
                      Upload
                    </button>
                    {((openUploadBlock &&
                      openUploadBlock["AdhaarCardUpload"] &&
                      generalDetail.adhaar_card_upload.length === 0) ||
                      generalDetail.adhaar_card_upload.length > 0) && (
                        <>
                          <span
                            className="close"
                            onClick={() =>
                              setOpenUploadBlock({ AdhaarCardUpload: false })
                            }
                          >
                            <i className="fa fa-close"></i>
                          </span>
                          <FileUploader
                            // key={UploadTypeEnum.IncorporationCertificate}
                            key={Math.random().toString()}
                            id={UploadTypeEnum.AdhaarCardUpload}
                            information="Drag & Drop the file or Browse"
                            fileType="application/pdf"
                            buttonLabel="Browse"
                            multiple={false}
                            enablePreview
                            IconComponent={() => (
                              <span className="fa fa-cloud-upload"></span>
                            )}
                            removeIconClass=""
                            existingFiles={generalDetail.adhaar_card_upload.map(
                              (uploadedFile: FileUploadModel) =>
                              ({
                                name: getFileDetails(uploadedFile.file, 'name') || uploadedFile.file.name,
                                url: uploadedFile.file
                              })
                              // uploadedFile.file
                            )}
                            onUploadFinish={(files: Array<File>) => {
                              onFileUpload(
                                files,
                                UploadTypeEnum.AdhaarCardUpload
                              );
                            }}
                            onFileDelete={(deletedFile: File) =>
                              onFileDelete(
                                deletedFile,
                                UploadTypeEnum.AdhaarCardUpload
                              )
                            }
                          />
                        </>
                      )}
                  </fieldset>
                  {/* && generalDetail.adhaar_card_upload.some((item) => item.file_name) */}
                  {(generalDetail.adhaar_card_upload.length > 0 && generalDetail.adhaar_card_upload[0].file_type_id != 0) ? (
                    <a
                      className="incubator-details-link"
                      data-toggle="modal"
                      data-target="#showMoreDocument"
                      onClick={() =>
                        setMeetingModal('Aadhaar Card')
                      }>
                      View uploaded Documents
                    </a>
                  ) : (
                    ''
                  )}
                  {/* <div>
                      {generalDetail.adhaar_card_upload.map((uploadedFile) => (
                          <div key={`${uploadedFile.file}`} >
                              <span className="pdfText">{getFileDetails(uploadedFile.file, 'name')} - </span> 
                              <a href={`${uploadedFile.file}`} download target="blank" className="orange-link-text">
                                  View 
                              </a>
                          </div>
                      ))}
                    </div> */}
                  <small>Supported file format - PDF only</small>
                  <div
                    className={`${props.errors.length &&
                      !generalDetail.adhaar_card_upload.length
                      ? `col-12 pl-0 error`
                      : "invalid-feedback "
                      }`}
                  >
                    This is mandatory field
                  </div>
                  <div
                    className={`${pdfOnly?.adhaar_card_upload
                      ? `col-12 pl-0 error`
                      : "invalid-feedback "
                      }`}
                  >
                    Only PDF files are allowed
                  </div>
                </div>
              </div>
            </div>


            {/* New Addition */}
            <fieldset disabled={disableForm}>
              <div className="form-group required row">
                <label className="col-sm-4 col-form-label">
                  Date of commencement of Business/Operations
                </label>
                <div className="col-sm-4">
                  <ReactDatePicker
                    id="operationalization_date"
                    name="operationalization_date"
                    className={`${props.errors.indexOf("operationalization_date") > -1
                      ? `form-control error`
                      : "form-control"
                      }`}
                    dateFormat="yyyy-MM-dd"
                    value={
                      (new Date().getTime() -
                        new Date(generalDetail.operationalization_date).getTime()) /
                        (1000 * 3600 * 24) >
                        Math.abs(operalizationDate * 365)
                        ? moment(generalDetail.operationalization_date).format(
                          "YYYY-MM-DD"
                        )
                        : ""
                    }
                    onChange={(date: Date) =>
                      handleDateChange(date, "operationalization_date")
                    }
                    maxDate={add_years(
                      new Date(),
                      operalizationDate
                    )}
                    showMonthDropdown
                    showYearDropdown
                  // maxDate={add_years(
                  //   generalDetail.operationalization_date
                  //     ? new Date(generalDetail.operationalization_date)
                  //     : new Date(),
                  //   operalizationDate
                  // )}
                  />

                  <div
                    className={`${props.errors.indexOf("operationalization_date") > -1
                      ? `col-12 pl-0 error`
                      : "invalid-feedback "
                      }`}
                  >
                    Please enter valid date
                  </div>
                </div>
                <div className="offset-4 col-sm-8">
                  <small>
                    Needs to be 2 years if assisted by Central/State Government and
                    3 years if not assisted by Central/State Government
                  </small>
                </div>
              </div>
            </fieldset>

            <hr />

            {/* New Addition */}
            <div className="form-group required row">
              <label className="col-sm-4 col-form-label">
                Audited Annual Report for last 2 years
              </label>
              <div className="col-sm-8">
                <div className="">
                  {/* <input type="file" className="form-control-file btn btn-sisfs btn-transparent-primary" id="exampleFormControlFile1" /> */}
                  <fieldset disabled={disableForm}>
                    <button
                      className="btn btn-sisfs btn-transparent-primary"
                      onClick={() => setOpenUploadBlock({ AuditReport: true })}
                    >
                      Upload
                    </button>

                    {((openUploadBlock &&
                      openUploadBlock["AuditReport"] &&
                      generalDetail.annual_report.length === 0) ||
                      generalDetail.annual_report.length > 0) && (
                        <>
                          <span
                            className="close"
                            onClick={() => setOpenUploadBlock({ AuditReport: false })}
                          >
                            <i className="fa fa-close"></i>
                          </span>
                          <FileUploader
                            // key={UploadTypeEnum.AnnualReport}
                            key={Math.random().toString()}
                            id={UploadTypeEnum.AnnualReport}
                            information="Drag & Drop the file or Browse"
                            fileType="application/pdf"
                            buttonLabel="Browse"
                            multiple
                            enablePreview
                            IconComponent={() => (
                              <span className="fa fa-cloud-upload"></span>
                            )}
                            removeIconClass=""
                            existingFiles={generalDetail.annual_report.map(
                              (uploadedFile: FileUploadModel) =>
                              ({
                                name: getFileDetails(uploadedFile.file, 'name') || uploadedFile.file.name,
                                url: uploadedFile.file
                              })
                              // uploadedFile.file
                            )}
                            onUploadFinish={(files: Array<File>) => {
                              on_Limited_FileUploads(files, UploadTypeEnum.AnnualReport);
                            }}
                            onFileDelete={(deletedFile: File) =>
                              onFileDelete(deletedFile, UploadTypeEnum.AnnualReport)
                            }
                          />
                        </>
                      )}
                  </fieldset>
                  {/* && generalDetail.annual_report.some((item) => item.file_name) */}
                  {(generalDetail.annual_report.length > 0 && generalDetail.annual_report[0].file_type_id != 0) ? (
                    <a
                      className="incubator-details-link"
                      data-toggle="modal"
                      data-target="#showMoreDocument"
                      onClick={() =>
                        setMeetingModal('Annual Report')
                      }>
                      View uploaded Documents
                    </a>
                  ) : (
                    ''
                  )}
                  {/* <div>
                      {generalDetail.annual_report.map((uploadedFile) => (
                          <div key={`${uploadedFile.file}`} >
                              <span className="pdfText">{getFileDetails(uploadedFile.file, 'name')} - </span> 
                              <a href={`${uploadedFile.file}`} download target="blank" className="orange-link-text">
                                  View 
                              </a>
                          </div>
                      ))}
                    </div> */}
                  {/* <small>
                  Applicable for incubators not assisted by Central/State
                  Government
                </small> */}
                  <small>
                    Copy of audited financial statements of two previous years with seal of the auditor (Unaudited financial statement of previous financial year can be attached if audited report is not available at time of application. The audited copy of such a financial statement can be submitted when available)
                  </small>
                  <div
                    className={`${props.errors.length && !generalDetail.annual_report.length
                      ? `col-12 pl-0 error`
                      : "invalid-feedback "
                      }`}
                  >
                    This is mandatory field
                  </div>
                  <div
                    className={`${pdfOnly?.annual_report
                      ? `col-12 pl-0 error`
                      : "invalid-feedback "
                      }`}
                  >
                    Only PDF files are allowed
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <fieldset disabled={disableForm}>
              {/* New Addition */}
              <div className="form-group required row">
                <label className="col-sm-4 col-form-label">Seating Capacity</label>
                <div className="col-sm-8">
                  <div className="form-row mb-0">
                    <div className="col">
                      <span className="col-label">
                        Number of individuals that can be seated at once
                      </span>

                      <IncrementDecrement
                        name="seating_capacity_individulas"
                        minValue={25}
                        value={
                          generalDetail.seating_capacity_individulas
                            ? generalDetail.seating_capacity_individulas
                            : 25
                        }
                        onChange={(name: string, value: number) =>
                          handleIncrementDecrementChange(
                            "seating_capacity_individulas",
                            value
                          )
                        }
                      />

                      <small>
                        Incubator must have facilities to seat at least 25
                        individuals from startups
                      </small>
                    </div>
                  </div>
                </div>
              </div>

              <hr />
              {/* New Addition */}
              <div className="form-group required row">
                <label className="col-sm-4 col-form-label">
                  No. of startups undergoing incubation physically at the incubator
                  on the date of application
                  {/* Agreement between incubator and startup */}
                </label>
                <div className="col-sm-8">
                  <div className="form-row">
                    <div className="col">
                      <IncrementDecrement
                        name="no_startups_incubation"
                        minValue={no_startups_incubation}
                        value={
                          generalDetail.no_startups_incubation >
                            no_startups_incubation
                            ? generalDetail.no_startups_incubation
                            : no_startups_incubation
                        }
                        onChange={(name: string, value: number) =>
                          handleIncrementDecrementChange(
                            "no_startups_incubation",
                            value
                          )
                        }
                      />

                      <small>
                        If the incubator is assisted by Central/State Government(s) then the incubator should have at least 5 different startups undergoing incubation physically at the time of application. In case the incubator has not been assisted by Central or State Government(s), then it must have at least 10 different startups undergoing incubation at the time of application.
                      </small>

                      <small>
                        Please upload the self declaration form signed by authorised signatory.
                        The sample format of document is available in the "Other Details" section.
                      </small>
                      {/* <small>
                      A self declaration form signed by authorised signatory is required to
                      attest. The sample format of document is
                      available in the "Other Details" section.
                    </small> */}
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
            <div className="form-group required row">
              <label className="col-sm-4 col-form-label">
                Agreement between incubator and startup
              </label>
              <div className="col-sm-8">
                <div className="form-row mb-0">
                  <div className="col">

                    <div className="">
                      <fieldset disabled={disableForm}>
                        <button
                          className="btn btn-sisfs btn-transparent-primary"
                          onClick={() =>
                            setOpenUploadBlock({ StartupIncubationUpload: true })
                          }
                        >
                          Upload
                        </button>
                        {((openUploadBlock &&
                          openUploadBlock["StartupIncubationUpload"] &&
                          generalDetail.startup_incubation_upload.length === 0) ||
                          generalDetail.startup_incubation_upload.length > 0) && (
                            <>
                              <span
                                className="close"
                                onClick={() =>
                                  setOpenUploadBlock({ StartupIncubationUpload: false })
                                }
                              >
                                <i className="fa fa-close"></i>
                              </span>
                              <FileUploader
                                // key={UploadTypeEnum.IncorporationCertificate}
                                key={Math.random().toString()}
                                id={UploadTypeEnum.StartupIncubationUpload}
                                information="Drag & Drop the file or Browse"
                                fileType="application/pdf"
                                buttonLabel="Browse"
                                multiple={false}
                                enablePreview
                                IconComponent={() => (
                                  <span className="fa fa-cloud-upload"></span>
                                )}
                                removeIconClass=""
                                existingFiles={generalDetail.startup_incubation_upload.map(
                                  (uploadedFile: FileUploadModel) =>
                                  ({
                                    name: getFileDetails(uploadedFile.file, 'name') || uploadedFile.file.name,
                                    url: uploadedFile.file
                                  })
                                  // uploadedFile.file
                                )}
                                onUploadFinish={(files: Array<File>) => {
                                  onFileUpload(
                                    files,
                                    UploadTypeEnum.StartupIncubationUpload
                                  );
                                }}
                                onFileDelete={(deletedFile: File) =>
                                  onFileDelete(
                                    deletedFile,
                                    UploadTypeEnum.StartupIncubationUpload
                                  )
                                }
                              />
                            </>
                          )}
                      </fieldset>
                      {(generalDetail.startup_incubation_upload.length > 0 && generalDetail.startup_incubation_upload[0].file_type_id != 0) ? (
                        <a
                          className="incubator-details-link"
                          data-toggle="modal"
                          data-target="#showMoreDocument"
                          onClick={() =>
                            setMeetingModal('Incubation Upload')
                          }>
                          View uploaded Documents
                        </a>
                      ) : (
                        ''
                      )}
                      {/* <div>
                      {generalDetail.startup_incubation_upload.map((uploadedFile) => (
                          <div key={`${uploadedFile.file}`} >
                              <span className="pdfText">{getFileDetails(uploadedFile.file, 'name')} - </span> 
                              <a href={`${uploadedFile.file}`} download target="blank" className="orange-link-text">
                                  View 
                              </a>
                          </div>
                        ))}
                      </div> */}
                      <small>Supported file format - PDF only</small>
                      {/* <small>Copy of relevant document (such as agreement signed with the
                      incubated startup; dates in agreement should clearly indicate that
                      startup is currently physically incubated with the incubator.
                      In case the agreement does not have requisite dates, self-declaration
                         from authorized signatory of incubator should be additionally shared)</small> */}
                      <small>
                        Please upload copy of agreement signed with incubated startups;
                        dates in agreement should clearly indicate that startup is currently
                        physically incubated with the incubator. In case the agreement does not
                        have requisite dates, self-declaration from authorized signatory of
                        incubator should be additionally shared. (Incase incubator is assisted
                        by Central/State government 5 startup agreements needs to be uploaded,
                        else agreement of 10 startups currently phsycially incubated should
                        be uploaded)
                      </small>
                      <div
                        className={`${props.errors.length &&
                          !generalDetail.startup_incubation_upload.length
                          ? `col-12 pl-0 error`
                          : "invalid-feedback "
                          }`}
                      >
                        This is mandatory field
                      </div>
                      <div
                        className={`${pdfOnly?.startup_incubation_upload
                          ? `col-12 pl-0 error`
                          : "invalid-feedback "
                          }`}
                      >
                        Only PDF files are allowed
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <hr />

            <fieldset disabled={disableForm}>
              <div className="form-group required row">
                <label className="col-sm-4 col-form-label">
                  Are you disbursing seed fund to your incubatees using funding from
                  any third-party private entity?
                </label>
                <div className="col-sm-8">
                  {/* <div className="btn-group btn-group-toggle" data-toggle="buttons">
                <label
                  className={
                    generalDetail.fund_disburse_using_3rd_party === 1
                      ? "btn active"
                      : "btn"
                  }
                >
                  <input
                    type="radio"
                    name="fund_disburse_using_3rd_party"
                    id="fund_disburse_using_3rd_party1"
                    onChange={handleChange}
                    checked={generalDetail.fund_disburse_using_3rd_party === 1}
                  />
                  Yes
                </label>
                <label
                  className={
                    generalDetail.fund_disburse_using_3rd_party === 0
                      ? "btn active"
                      : "btn"
                  }
                >
                  <input
                    type="radio"
                    name="fund_disburse_using_3rd_party"
                    id="fund_disburse_using_3rd_party2"
                    onChange={handleChange}
                    checked={generalDetail.fund_disburse_using_3rd_party === 0}
                  />
                  No
                </label>
              </div> */}

                  <div className="btn-group btn-group-toggle">
                    <label
                      className={
                        generalDetail.fund_disburse_using_3rd_party === "1"
                          ? "btn active"
                          : "btn"
                      }
                    >
                      <input
                        type="radio"
                        name="fund_disburse_using_3rd_party"
                        id="fund_disburse_using_3rd_party1"
                        value="1"
                        onChange={handleChange}
                        checked={
                          generalDetail.fund_disburse_using_3rd_party === "1"
                        }
                      />
                      Yes
                    </label>
                    <label
                      className={
                        generalDetail.fund_disburse_using_3rd_party === "0"
                          ? "btn active"
                          : "btn"
                      }
                    >
                      <input
                        type="radio"
                        name="fund_disburse_using_3rd_party"
                        id="fund_disburse_using_3rd_party2"
                        value="0"
                        onChange={handleChange}
                        checked={
                          generalDetail.fund_disburse_using_3rd_party === "0"
                        }
                      />
                      No
                    </label>
                  </div>
                  {generalDetail.fund_disburse_using_3rd_party === "1" && (
                    <small>
                      Only Incubators not disbursing seed fund to incubatees using
                      funding from any third-party private entity are eligible
                    </small>
                  )}
                  <small>
                    A self declaration form signed by authorised signatory is to be attached<br /> (last question)
                  </small>
                </div>
              </div>
            </fieldset>


          </div>

          <fieldset disabled={disableForm}>
            <div className="stepper-footer-section">
              <div>
                <div
                  className="btn-toolbar justify-content-between"
                  role="toolbar"
                  aria-label="Toolbar with button groups"
                >
                  <button className="btn btn-sisfs btn-transparent-primary" onClick={() => applicationPage()}>
                    Cancel
                  </button>
                  <span className="stepper-page-count">Step 1 / 5</span>
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={onSave}
                    >
                      Save And Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </fieldset >

      {showIncubator_limited_Upload_Model && (
        <div
          className="modal align-middle modal-sisfs d-block"
          id="FormSubmissionError"
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img
                  src={error}
                  className="search mb-2"
                  alt="search"

                />
                <h4 className="message-orange" style={{ fontWeight: "bold", fontFamily: "Roboto Slab" }}>
                  Oops!!!
                </h4>
                <h4 className="message-orange" style={{ fontWeight: "bold", fontFamily: "Roboto Slab" }}>
                  Please Try Again !!!<br /><br />
                  Only 10 files can be uploaded once
                </h4>
                <div className="modal-action">
                  <button
                    className="btn btn-sisfs btn-yellow-primary"
                    onClick={() => setshowIncubator_limited_Upload_Model(false)}
                  >
                    OK !
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showIncubatorFailureModal1 && (
        <ConfirmationDialog
          headerText={failDialogData.header}
          bodyText={failDialogData.text}
          // bodyNote={failDialogData.note}        
          onDialogClose={() => setShowIncubatorFailureModal1(false)}
          iconClass="showImage"
        />
      )}
      {showIncubatorFailureModal && (
        <div
          className="modal align-middle modal-sisfs d-block"
          id="FormSubmissionError"
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img
                  src={error}
                  className="search mb-2"
                  alt="search"

                />
                <h4 className="message-orange" style={{ fontWeight: "bold", fontFamily: "Roboto Slab" }}>
                  Oops!!!
                </h4>
                <h4 className="message-orange" style={{ fontWeight: "bold", fontFamily: "Roboto Slab" }}>
                  Please Try Again !!!<br /><br />
                  Only 10 files can be uploaded once
                </h4>
                <div className="modal-action">
                  <button
                    className="btn btn-sisfs btn-yellow-primary"
                    onClick={dismissModal}
                  >
                    OK !
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Show More  */}
      <div
        className="modal fade"
        id="showMoreDocument"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none ml-18px">
              <h5 className="modal-title " id="exampleModalLongTitle">
                {popUpName} <span className="eac-name-pop-up"></span>
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {/* <div className=""> */}
              {/* 1St Application Documents */}

              {/* General Details documents */}
              {popUpName == "Proof of Assistance" ? (
                generalDetail.proof_assistance !=
                  undefined ? (
                  generalDetail.proof_assistance.map(
                    (file: any, index: any) => (
                      <div className="row">
                        <div className="col-md-2">
                          {getFileDetails(file.file, "type") == "pdf" ? (
                            <img src={pdf} alt="SISFS Logo" />
                          ) : getFileDetails(file.file, "type") == "doc" ? (
                            <img src={doc} alt="SISFS Logo" />
                          ) : getFileDetails(file.file, "type") == "xls" ? (
                            <img src={xls} alt="SISFS Logo" />
                          ) : (
                            <img src={Allround} alt="SISFS Logo" />
                          )}
                        </div>
                        <div className="col-md-9 left-align">
                          <span className="document-label-value-wrap">
                            {getFileDetails(file.file, "name")}
                          </span>
                          <br />
                          <a
                            // href={file.file}
                            href={file.file}
                            target="_blank"
                            //download="annual_report"
                            className="incubator-details-link"
                          >
                            View
                          </a>
                          {/* /
                            <a
                              href="#"
                              //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <span className="incubator-label-value">-</span>
                )
              ) : (
                ""
              )}
              {popUpName == "Incorporation Certificate" ? (
                generalDetail.incorporation_certificate !=
                  undefined ? (
                  generalDetail.incorporation_certificate.map(
                    (file: any, index: any) => (
                      <div className="row">
                        <div className="col-md-2">
                          {getFileDetails(file.file, "type") == "pdf" ? (
                            <img src={pdf} alt="SISFS Logo" />
                          ) : getFileDetails(file.file, "type") == "doc" ? (
                            <img src={doc} alt="SISFS Logo" />
                          ) : getFileDetails(file.file, "type") == "xls" ? (
                            <img src={xls} alt="SISFS Logo" />
                          ) : (
                            <img src={Allround} alt="SISFS Logo" />
                          )}
                        </div>
                        <div className="col-md-9 left-align">
                          <span className="document-label-value-wrap">
                            {getFileDetails(file.file, "name")}
                          </span>
                          <br />
                          <a
                            // href={file.file}
                            href={file.file}
                            target="_blank"
                            //download="annual_report"
                            className="incubator-details-link"
                          >
                            View
                          </a>
                          {/* /
                            <a
                              href="#"
                              //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <span className="incubator-label-value">-</span>
                )
              ) : (
                ""
              )}
              {popUpName == "Pan Card" ? (
                generalDetail.pan_card_upload !=
                  undefined ? (
                  generalDetail.pan_card_upload.map(
                    (file: any, index: any) => (
                      <div className="row">
                        <div className="col-md-2">
                          {getFileDetails(file.file, "type") == "pdf" ? (
                            <img src={pdf} alt="SISFS Logo" />
                          ) : getFileDetails(file.file, "type") == "doc" ? (
                            <img src={doc} alt="SISFS Logo" />
                          ) : getFileDetails(file.file, "type") == "xls" ? (
                            <img src={xls} alt="SISFS Logo" />
                          ) : (
                            <img src={Allround} alt="SISFS Logo" />
                          )}
                        </div>
                        <div className="col-md-9 left-align">
                          <span className="document-label-value-wrap">
                            {getFileDetails(file.file, "name")}
                          </span>
                          <br />
                          <a
                            // href={file.file}
                            href={file.file}
                            target="_blank"
                            //download="annual_report"
                            className="incubator-details-link"
                          >
                            View
                          </a>
                          {/* /
                            <a
                              href="#"
                              //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <span className="incubator-label-value">-</span>
                )
              ) : (
                ""
              )}
              {popUpName == "Authorisation Letter" ? (
                generalDetail.authorisation_letter_upload !=
                  undefined ? (
                  generalDetail.authorisation_letter_upload.map(
                    (file: any, index: any) => (
                      <div className="row">
                        <div className="col-md-2">
                          {getFileDetails(file.file, "type") == "pdf" ? (
                            <img src={pdf} alt="SISFS Logo" />
                          ) : getFileDetails(file.file, "type") == "doc" ? (
                            <img src={doc} alt="SISFS Logo" />
                          ) : getFileDetails(file.file, "type") == "xls" ? (
                            <img src={xls} alt="SISFS Logo" />
                          ) : (
                            <img src={Allround} alt="SISFS Logo" />
                          )}
                        </div>
                        <div className="col-md-9 left-align">
                          <span className="document-label-value-wrap">
                            {getFileDetails(file.file, "name")}
                          </span>
                          <br />
                          <a
                            // href={file.file}
                            href={file.file}
                            target="_blank"
                            //download="annual_report"
                            className="incubator-details-link"
                          >
                            View
                          </a>
                          {/* /
                            <a
                              href="#"
                              //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <span className="incubator-label-value">-</span>
                )
              ) : (
                ""
              )}
              {popUpName == "Aadhaar Card" ? (
                generalDetail.adhaar_card_upload !=
                  undefined ? (
                  generalDetail.adhaar_card_upload.map(
                    (file: any, index: any) => (
                      <div className="row">
                        <div className="col-md-2">
                          {getFileDetails(file.file, "type") == "pdf" ? (
                            <img src={pdf} alt="SISFS Logo" />
                          ) : getFileDetails(file.file, "type") == "doc" ? (
                            <img src={doc} alt="SISFS Logo" />
                          ) : getFileDetails(file.file, "type") == "xls" ? (
                            <img src={xls} alt="SISFS Logo" />
                          ) : (
                            <img src={Allround} alt="SISFS Logo" />
                          )}
                        </div>
                        <div className="col-md-9 left-align">
                          <span className="document-label-value-wrap">
                            {getFileDetails(file.file, "name")}
                          </span>
                          <br />
                          <a
                            // href={file.file}
                            href={file.file}
                            target="_blank"
                            //download="annual_report"
                            className="incubator-details-link"
                          >
                            View
                          </a>
                          {/* /
                            <a
                              href="#"
                              //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <span className="incubator-label-value">-</span>
                )
              ) : (
                ""
              )}
              {popUpName == "Annual Report" ? (
                generalDetail.annual_report !=
                  undefined ? (
                  generalDetail.annual_report.map(
                    (file: any, index: any) => (
                      <div className="row">
                        <div className="col-md-2">
                          {getFileDetails(file.file, "type") == "pdf" ? (
                            <img src={pdf} alt="SISFS Logo" />
                          ) : getFileDetails(file.file, "type") == "doc" ? (
                            <img src={doc} alt="SISFS Logo" />
                          ) : getFileDetails(file.file, "type") == "xls" ? (
                            <img src={xls} alt="SISFS Logo" />
                          ) : (
                            <img src={Allround} alt="SISFS Logo" />
                          )}
                        </div>
                        <div className="col-md-9 left-align">
                          <span className="document-label-value-wrap">
                            {getFileDetails(file.file, "name")}
                          </span>
                          <br />
                          <a
                            // href={file.file}
                            href={file.file}
                            target="_blank"
                            //download="annual_report"
                            className="incubator-details-link"
                          >
                            View
                          </a>
                          {/* /
                            <a
                              href="#"
                              //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <span className="incubator-label-value">-</span>
                )
              ) : (
                ""
              )}
              {popUpName == "Incubation Upload" ? (
                generalDetail.startup_incubation_upload !=
                  undefined ? (
                  generalDetail.startup_incubation_upload.map(
                    (file: any, index: any) => (
                      <div className="row">
                        <div className="col-md-2">
                          {getFileDetails(file.file, "type") == "pdf" ? (
                            <img src={pdf} alt="SISFS Logo" />
                          ) : getFileDetails(file.file, "type") == "doc" ? (
                            <img src={doc} alt="SISFS Logo" />
                          ) : getFileDetails(file.file, "type") == "xls" ? (
                            <img src={xls} alt="SISFS Logo" />
                          ) : (
                            <img src={Allround} alt="SISFS Logo" />
                          )}
                        </div>
                        <div className="col-md-9 left-align">
                          <span className="document-label-value-wrap">
                            {getFileDetails(file.file, "name")}
                          </span>
                          <br />
                          <a
                            // href={file.file}
                            href={file.file}
                            target="_blank"
                            //download="annual_report"
                            className="incubator-details-link"
                          >
                            View
                          </a>
                          {/* /
                            <a
                              href="#"
                              //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <span className="incubator-label-value">-</span>
                )
              ) : (
                ""
              )}

              <div className="row">
                <div className="col-md-12 centerText">
                  <div className="btn-group mt-1-5 ">
                    <button
                      className="tertiary  btn-yellow-primary"
                      data-dismiss="modal"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default GeneralDetailsComponent;
