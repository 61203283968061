import {
  validateEmail,
  validateMobile,
  validateMobileNumber,
  validateNumber,
  validatePAN,
  validateSocialURL,
  validateStartupSocialURL,
  validateURL,
} from "../effects/utils/validations";
import { ErrorModel } from "../models/common/ErrorModel";
import { TeamDetails } from "../models/incubator/IncubatorTeamModel";
import { FundExpenseDetails } from "../models/startup/FundingDetailsModel";
import { useErrorStateValue } from "../providers/ErrorProvider";
import { Action } from "../reducers/reducer.action";

export interface IRequireField {
  [key: string]: string[];
}

/** Helps to validate the form
 * state: state of the form
 * requiredFields: An array of required fields which needs to be validated
 * nullFields: An array because of it's null property
 */
export class ValidationHelper {
  constructor(
    public state: any,
    public requiredFields: IRequireField,
    public nullFields: Array<string>
  ) {
    this.state = state;
    this.requiredFields = requiredFields;
    this.nullFields = nullFields;
  }

  /** Triggers the validation based on required field and state,passed. */
  public triggerValidation = () => {
    let errors: any = {};

    Object.keys(this.requiredFields).map((key: string) =>
      this.requiredFields[key].map((field: string) => {
        const value = this.state[key][field];
        const errorModel = new ErrorModel(field, "", value);

        let message: string = "";
        try {
          if (!this.nullFields.includes(field) && errorModel.isMandatory()) {
            message = `This is mandatory field`;
          } else {
            message = "";
          }
        } catch (error) {
          console.warn(`[${key}][${field}] - ${error}`);
        }

        // advance error handling eg. depended validations and assign message
        //   message = this.generalDetailValidations(field, key, message);

        //   message = this.incubatorTeamValidation(field, key, message);

        //   message = this.fundUtilizationValidation(field, key, message);

        //   message = this.incubatorOtherDetailsValidation(field, key, message);

        message = this.entityDetailsValidations(field, key, message);

        message = this.startupDetailsValidations(field, key, message);

        message = this.authorizedRepresentativeValidations(field, key, message);

        message = this.startupTeamValidation(field, key, message);

        message = this.startupFundingExpenseValidation(field, key, message);

        message=this.uploadDocumentValidation(field,key,message);

        // message = this.incubatorPreferenceValidation(field, key, message);

        errors = {
          ...errors,
          [key]: {
            ...errors[key],
            [field]: message,
          },
        };
        //console.log("Helper Errors", errors);
      })
    );
    return errors;
  };

  private entityDetailsValidations(
    field: string,
    key: string,
    message: string
  ) {

    // validate PAN Number
    if (field === "pan_number") {
      if(this.state[key]["pan_number"].length>0){
      message = validatePAN(this.state[key][field]);
      }
      else{
        message=""
      }
    }
    return message;
  };



  private startupDetailsValidations(
    field: string,
    key: string,
    message: string
  ) {
    if ((field === "competitors_startup_enable" && this.state[key]["competitors_startup_enable"] == false) &&
      (this.state[key]["competitors_msme_enable"] == false) &&
      (this.state[key]["competitors_corporate_enable"] == false) &&
      (this.state[key]["competitors_other_enable"] == false)) {
      message = "This is mandatory field";
    }
    if ((field === "competitors_startup_desc" && this.state[key]["competitors_startup_desc"] === "" && this.state[key]["competitors_startup_enable"] == true) ||
      (field === "competitors_msme_desc" && this.state[key]["competitors_msme_desc"] === "" && this.state[key]["competitors_msme_enable"] == true) ||
      (field === "competitors_corporate_desc" && this.state[key]["competitors_corporate_desc"] === "" && this.state[key]["competitors_corporate_enable"] == true) ||
      (field === "competitors_other_desc" && this.state[key]["competitors_other_desc"] === "" && this.state[key]["competitors_other_enable"] == true)) {
      message = "This is mandatory field";
    }
    else if ((field === "competitors_startup_desc" && this.state[key]["competitors_startup_desc"] === "" && this.state[key]["competitors_startup_enable"] == false) ||
      (field === "competitors_msme_desc" && this.state[key]["competitors_msme_desc"] === "" && this.state[key]["competitors_msme_enable"] == false) ||
      (field === "competitors_corporate_desc" && this.state[key]["competitors_corporate_desc"] === "" && this.state[key]["competitors_corporate_enable"] == false) ||
      (field === "competitors_other_desc" && this.state[key]["competitors_other_desc"] === "" && this.state[key]["competitors_other_enable"] == false)) {
      message = ""
    }
    // social media validations
    if (field === "website_url") {
      if(this.state[key]["website_url"].length>0){
      message = validateURL(this.state[key]["website_url"]);
      }
      else{
        message=""
      }
    }

    if (field === "facebook_link") {
      if(this.state[key]["facebook_link"].length>0){
      message = validateStartupSocialURL(this.state[key]["facebook_link"]);
      }
      else{
        message=""
      }
    }
    if (field === "linkedin_link") {
      if(this.state[key]["linkedin_link"].length>0){
      message = validateStartupSocialURL(this.state[key]["linkedin_link"]);
      }
      else{
        message=""
      }
    }
    if (field === "twitter_link") {
      if(this.state[key]["twitter_link"].length>0){
      message = validateStartupSocialURL(this.state[key]["twitter_link"]);
      }
      else{
        message=""
      }
    }
    if (field === "youtube_link") {
      if(this.state[key]["youtube_link"].length>0){
      message = validateStartupSocialURL(this.state[key]["youtube_link"]);
      }
      else{
        message=""
      }
    }
    if (field === "other_link") {
      if(this.state[key]["other_link"].length>0){
      message = validateStartupSocialURL(this.state[key]["other_link"]);
      }
      else{
        message=""
      }
    }

    
    // message = this.facebookValidation(field, key, message);
    // message = this.twitterValidation(field, key, message);
    // message = this.linkedInValidation(field, key, message);
    // message = this.youtubeValidation(field, key, message);
    return message
  }

  private authorizedRepresentativeValidations(
    field: string,
    key: string,
    message: string
  ) {

    // designation
    if (field === "representative_mobile_no") {
      message = validateMobileNumber(this.state[key][field]);
    }
    if (field === "representative_email") {
      message = validateEmail(this.state[key][field]);
    }

    return message;
  }

  // validate all startup team form
  private startupTeamValidation = (
    field: string,
    key: string,
    message: string
  ) => {
    if (field === "team_data") {
      const teamDetails = this.state[key]["team_data"] as Array<TeamDetails>;
      const isEmpty = teamDetails.some(
        (teamDetail: TeamDetails) => teamDetail.team_name.length === 0
      );
      if (isEmpty) {
        message = "This is mandatory field";
      }
    }
    if (field === "ceo_linkedin_link") {
      if(this.state[key]["ceo_linkedin_link"].length>0){
      message = validateStartupSocialURL(this.state[key]["ceo_linkedin_link"]);
      }
      else{
        message=""
      }
    }

    return message;
  };

  // validate all funding expenses data
  private startupFundingExpenseValidation = (
    field: string,
    key: string,
    message: string
  ) => {
    //console.log("Expense Detail Field", field);
    if (field === "fund_expense_data") {
      const fundingDetails = this.state[key]["fund_expense_data"] as Array<FundExpenseDetails>;
      const isEmpty = fundingDetails.some(
        (fundExpensesDetail: FundExpenseDetails) =>
          (fundExpensesDetail.expense_amount === 0) ||
          (fundExpensesDetail.expense_bucket.length === 0)
      );
      //console.log("Expense Detail", isEmpty, fundingDetails);
      if (isEmpty) {
        message = "This is mandatory field";
      }
      // if (fundingDetails.some((fundExpensesDetail: FundExpenseDetails) => fundExpensesDetail.expense_amount)) {
      //   message = validateNumber(this.state[key][field], 50000000);
      // }
    }

    return message;
  };

  // Incubator Preference Validation
  private incubatorPreferenceValidation = (
    field: string,
    key: string,
    message: string
  ) => {
    if (field === "incubator_preference_1") {
      if (this.state[key]["incubator_preference_1"] !== null || this.state[key]["incubator_preference_1"] !== "")
        message = "";
      else {
        message = "This is mandatory field";
      }
    }

    return message;
  };


  
  private uploadDocumentValidation(
    field: string,
    key: string,
    message: string
  ) {

    if (field === "video_url_for_product") {
      if(this.state[key]["video_url_for_product"].length>0){
      message = validateStartupSocialURL(this.state[key]["video_url_for_product"]);
      }
      else{
        message=""
      }
    }
    return message;
  };

  // validate facebook url
  // private facebookValidation(field: string, key: string, message: string) {
  //   if (
  //     field === "facebook_enable" &&
  //     this.state[key]["facebook_enable"] === true &&
  //     this.state[key]["facebook_link"].length === 0
  //   ) {
  //     message = "Facebook url is required";
  //   } else if (
  //     field === "facebook_enable" &&
  //     this.state[key]["facebook_enable"] === true &&
  //     this.state[key]["facebook_link"].length > 0
  //   ) {
  //     if (validateSocialURL(this.state[key]["facebook_link"]).length > 0) {
  //       message = validateSocialURL(this.state[key]["facebook_link"]);
  //     }
  //   }
  //   return message;
  // }

  // validate all general detail step
  // private generalDetailValidations(
  //   field: string,
  //   key: string,
  //   message: string
  // ) {
  //   if (
  //     field === "sector_ids" &&
  //     this.state[key]["industry_ids"].length > 0 &&
  //     this.state[key]["sector_ids"].length === 0
  //   ) {
  //     message = "This is mandatory field";
  //   }

  //   // social media validations
  //   message = this.facebookValidation(field, key, message);
  //   message = this.twitterValidation(field, key, message);
  //   message = this.linkedInValidation(field, key, message);
  //   message = this.youtubeValidation(field, key, message);

  //   // designation
  //   if (field === "representative_mobile_no") {
  //     message = validateMobile(this.state[key][field]);
  //   }
  //   if (field === "representative_email") {
  //     message = validateEmail(this.state[key][field]);
  //   }

  //   // legal entities
  //   if (field === "typeLegalEntityId" && this.state[key][field] === 0) {
  //     message = "This is mandatory field.";
  //   }

  //   // nature_assistance_incubator
  //   if (field === "nature_assistance_incubator") {
  //     if (this.state[key]["central_incubator_far_ids"].includes(326)) {
  //       message = "";
  //     } else if (
  //       !this.state[key]["central_incubator_far_ids"].includes(326) &&
  //       this.state[key]["nature_assistance_incubator"].length === 0
  //     ) {
  //       message = "This is mandatory field";
  //     }
  //   }

  //   if (field === "proof_assistances") {
  //     if (this.state[key]["central_incubator_far_ids"].includes(326)) {
  //       message = "";
  //     } else if (
  //       !this.state[key]["central_incubator_far_ids"].includes(326) &&
  //       this.state[key]["proof_assistances"].length === 0
  //     ) {
  //       message = "This is mandatory field";
  //     }
  //   }

  //   // registration date
  //   if (
  //     field === "registrationDate" &&
  //     this.state[key]["registrationDate"] === null
  //   ) {
  //     message = "This is mandatory field.";
  //   }

  //   //operationalization_date
  //   if (
  //     field === "operationalization_date" &&
  //     this.state[key]["operationalization_date"] === null
  //   ) {
  //     message = "This is mandatory field.";
  //   }
  //   return message;
  // }

  // validate all incubator team form
  //     private incubatorTeamValidation = (
  //       field: string,
  //       key: string,
  //       message: string
  //     ) => {
  //       if (field === "team_data") {
  //         const teamDetails = this.state[key]["team_data"] as Array<TeamDetails>;
  //         const isEmpty = teamDetails.some(
  //           (teamDetail: TeamDetails) => teamDetail.team_name.length === 0
  //         );
  //         if (isEmpty) {
  //           message = "This is mandatory field";
  //         }
  //       }

  //       return message;
  //     };

  //     // validate all fund utilization form
  //     private fundUtilizationValidation = (
  //       field: string,
  //       key: string,
  //       message: string
  //     ) => {
  //       if (field === "quantum_fund_required") {
  //         message = validateNumber(this.state[key][field], 50000000);
  //       }

  //       return message;
  //     };

  //     // validate incubator other details form
  //     private incubatorOtherDetailsValidation = (
  //       field: string,
  //       key: string,
  //       message: string
  //     ) => {
  //       if (field === "relavent_document_excel") {
  //         if (
  //           this.state[key]["upload_documents_composition_incubator"] === true &&
  //           this.state[key]["upload_documents_incubation_support"] === true &&
  //           this.state[key]["upload_documents_mentorship_provided"] === true &&
  //           this.state[key][field].length === 0
  //         ) {
  //           message = "This is mandatory field";
  //         } else {
  //           message = "";
  //         }
  //       }

  //       return message;
  //     };

  //     // validate facebook url
  //     private facebookValidation(field: string, key: string, message: string) {
  //       if (
  //         field === "facebook_enable" &&
  //         this.state[key]["facebook_enable"] === true &&
  //         this.state[key]["facebook_link"].length === 0
  //       ) {
  //         message = "Facebook url is required";
  //       } else if (
  //         field === "facebook_enable" &&
  //         this.state[key]["facebook_enable"] === true &&
  //         this.state[key]["facebook_link"].length > 0
  //       ) {
  //         if (validateSocialURL(this.state[key]["facebook_link"]).length > 0) {
  //           message = validateSocialURL(this.state[key]["facebook_link"]);
  //         }
  //       }
  //       return message;
  //     }

  //     // validate twitter url
  //     private twitterValidation(field: string, key: string, message: string) {
  //       if (
  //         field === "twitter_enable" &&
  //         this.state[key]["twitter_enable"] === true &&
  //         this.state[key]["twitter_link"].length === 0
  //       ) {
  //         message = "Twitter url is required";
  //       } else if (
  //         field === "twitter_enable" &&
  //         this.state[key]["twitter_enable"] === true &&
  //         this.state[key]["twitter_link"].length > 0
  //       ) {
  //         if (validateSocialURL(this.state[key]["twitter_link"]).length > 0) {
  //           message = validateSocialURL(this.state[key]["twitter_link"]);
  //         }
  //       }
  //       return message;
  //     }

  //     // validate linkedin url
  //     private linkedInValidation(field: string, key: string, message: string) {
  //       if (
  //         field === "linkedin_enable" &&
  //         this.state[key]["linkedin_enable"] === true &&
  //         this.state[key]["linkedin_link"].length === 0
  //       ) {
  //         message = "Twitter url is required";
  //       } else if (
  //         field === "linkedin_enable" &&
  //         this.state[key]["linkedin_enable"] === true &&
  //         this.state[key]["linkedin_link"].length > 0
  //       ) {
  //         if (validateSocialURL(this.state[key]["twitter_link"]).length > 0) {
  //           message = validateSocialURL(this.state[key]["linkedin_link"]);
  //         }
  //       }
  //       return message;
  //     }

  //     // validate youtube url
  //     private youtubeValidation(field: string, key: string, message: string) {
  //       if (
  //         field === "youtube_enable" &&
  //         this.state[key]["youtube_enable"] === true &&
  //         this.state[key]["youtube_link"].length === 0
  //       ) {
  //         message = "Twitter url is required";
  //       } else if (
  //         field === "youtube_enable" &&
  //         this.state[key]["youtube_enable"] === true &&
  //         this.state[key]["youtube_link"].length > 0
  //       ) {
  //         if (validateSocialURL(this.state[key]["twitter_link"]).length > 0) {
  //           message = validateSocialURL(this.state[key]["youtube_link"]);
  //         }
  //       }
  //       return message;
  //     }
}
function validvalidateSocialURLateURL(arg0: any): string {
  throw new Error("Function not implemented.");
}

