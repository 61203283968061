import React, { useEffect, useState } from "react";
import {
  GET_Re_APPLICATION_LIST,
  GET_APPLICATION_VIEW,
  REPLACE_FILE,
  DELETE_APPLICATION,
  DELETE_Re_APPLICATION,
  EVALUATION_FILE,
  CANCELLATION_REQUEST,
  CANCELLATION_Re_REQUEST,
  UPLOAD_DISBURSEMENT_PROOF,
  FILE_UPLOAD,
  GET_APPLICATION_LIST_NODE
} from "../../../../constants/api";
import { get, post } from "../../../../effects/api/api.common";
import ContactSectionComponent from "./ContactSectionComponent";
import GetApplicationInfoComponent from "./GetApplicationInfoComponenet";
import { Link, NavLink, useHistory } from "react-router-dom";
import { FormatDate } from "../../../../utils/DateFormat";
import { changeNumberFormat, downloadDocument, showEllipsisText } from "../../../../utils/CommonMethods";
import FileUploader from "@amodv/react-file-uploader";
import ConfirmationDialog from "../../../common/controls/ConfirmationDialog";
import IncubatorHeaderComponent from "../../../../components/incubator/common/IncubatorHeaderComponent";
import { getSessionStorageValue } from "../../../../effects/utils/session-storage";
import ApplicationStatusComponent from "./ApplicationStatusComponent";


// import '../applynow.css';

// interface IGeneralDetailsProps {
//   ApplicationListData:any;
// }

interface IIKeyValueIndexer {
  [key: string]: boolean;
}

/** Render Application Status for Incubator screen */
function ApplicationStatusApply(props:any) {
  const history = useHistory();
  const {ApplicationListData1} = props
  const [applicationListData, setApplicationListData] = useState([]);
  const [applicationID, setApplicationID] = useState('');
  const [uploadFile, setUploadFile] = useState<Array<File>>([]);
  const [showIncubatorSuccessModal, setShowIncubatorSuccessModal] = useState(false);
  const [showIncubatorFailureModal, setShowIncubatorFailureModal] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [other_file, setOtherFile] = useState<Array<File>>([]);
  const [failDialogData, setFailDialogData] = useState({
    "header": '',
    "text": ''
  });
  const [dialogData, setDialogData] = useState({
    "header": '',
    "text": ''
  });
  const [cancellationData, setCancellationData] = useState({
    reason: '',
    amount_refunded: '',
    interest_funds: ''
  });
  const [applicationData, setApplicationData] = useState([]);
  const [applicationDataCount2, setApplicationDataCount] = useState(0);
  const [showEditFailureModal, setShowEditFailureModal] = useState(false);
  const [showEditSuccessModal, setShowEditSuccessModal] = useState(false);
  // const [openUploadBlock, setOpenUploadBlock] = useState(false);
  const [openUploadBlock, setOpenUploadBlock] = useState<IIKeyValueIndexer>();
  const [PPTPath, setPPTPath] = useState('');
  const [showViewMore, setShowViewMore] = useState('');
  const [IncubatorModal, setIncubatorModal] = useState(false);
 

  useEffect(() => {
    // if (((typeof props.location.state !== 'undefined') && props.location.state != null)) {
    //   if (props.location.state.perform_reload == "true") {
    //     if (!localStorage.justOnce) {
    //       localStorage.setItem("justOnce", "true");
    //       window.location.reload();
    //     }
    //   }
    // }
    // else {
    //   localStorage.removeItem("justOnce");
    // }
    // getApplicationList();
    // getPPTPath();
    // getActiveApplication();
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   setApplicationListData(ApplicationListData1)
  // })

  const getActiveApplication = (id: any) => {
    history.push({
      pathname: "/re-apply",
      state: { application_id: id, disabled: true },
    });
  };
  const dismissModal = () => {
    setShowEditFailureModal(false);
    setShowEditSuccessModal(false);
    setShowIncubatorFailureModal(false);
    setShowIncubatorSuccessModal(false);
    window.location.reload();

  }

  // const getApplicationList = () => {
  //   const user: any = getSessionStorageValue("user");
  //   const userInfo = JSON.parse(user)
  //   const userId = userInfo.id
  //   const UserRole = userInfo.roles
  //   const requestPayload = {
  //     "logged_user_id":userId,
  //     "user_role":UserRole
  //   }
  //   post(GET_APPLICATION_LIST_NODE, requestPayload).then((response: any) => {
  //     if (!response || !response.data) return;
  //     if (response.data.length != 0) {
  //       setApplicationDataCount(response.data.data.length);
  //       const applicationList = response.data.data.map((item: any) => ({
  //         application_id: item.application_id,
  //         incubator_application_no: item.incubator_application_no,
  //         registered_name: item.registered_name,
  //         updated_at: item.last_updated,
  //         cancellation_requested: item.cancellation_requested,
  //         cancellation_status: item.cancellation_status,
  //         submitted_at: item.first_submitted_date != null ? item.first_submitted_date : item.submitted_at,
  //         label: item.status,
  //         dpiit_comments: item.dpiit_comments,
  //         Upload_pitchdeck: item.Upload_pitchdeck,
  //         file_location: item.file_location,
  //         file_id: item.file_id,
  //         comments_data: item.comments_data,
  //         comment_updated_at: item.comment_updated_at,
  //         date_of_evaluation: item.date_of_evaluation,
  //         status_created_at: item.status_created_at,
  //         total_approved_amt: item.total_approved_amt,
  //         no_of_tranches: item.no_of_tranches == null ? 0 : item.no_of_tranches
  //       }));
  //       setApplicationListData(applicationList);
  //       history.push({
  //         pathname:'/incubator_status',
  //         state:{props1:applicationList}
  //       })
  //       {console.log(applicationList[0].total_approved_amt)}

  //     } else {
  //     }
  //   });
  // };

  const continueEditing = (id: any,item:any) => {
    // console.log(id);
    setIncubatorModal(true);
    if(item.label == "Marked Incomplete"){
      history.push({
        pathname: "/re-apply",
        state: { application_id: id, continue_editing: 1 },
      });
      return;
    }
    return;
    history.push({
      pathname: "/re-apply",
      state: { application_id: id, continue_editing: 1 },
    });
  };

  const deleteApplication = (id: any) => {
    // console.log(id,"idd")
    post(DELETE_Re_APPLICATION, { "application_id": id }).then((response: any) => {
      if (!response || !response.data) return;
      if (response.data.result == "success") {
        // getApplicationList()
        // ApplicationListData1
        setShowEditSuccessModal(true);
      }
      else {
        setShowEditFailureModal(true);
      }

    })
  };


  const onChange = (e: any, applicationID: any, fileID: any) => {
    const fileData = e.target.files[0];
    // console.log("filejsdf", fileID)
    if (fileID == "null") {
      const user: any = getSessionStorageValue("user");
      const userInfo = JSON.parse(user)
      const formData = new FormData();
      // Update the formData object
      formData.append("file", fileData);
      formData.append("application_id", applicationID);
      formData.append("file_type", 'relavent_document_pitchdeck');
      formData.append("logged_user_id",userInfo.id)
      post(FILE_UPLOAD, formData).then((response: any) => {
        if (!response || !response.data) return;
        if (response.data.result == "success") {
          setDialogData({
            "header": "Thank You!",
            "text": response.data.message
          })
          setShowIncubatorSuccessModal(true)
        }
        else {
          setFailDialogData({
            "header": "Oops !!",
            "text": response.data.message
          })
          setShowIncubatorFailureModal(true);
        }
      })
    }
    else {
      const user: any = getSessionStorageValue("user");
      const userInfo = JSON.parse(user)
      let formData1 = new FormData();
      formData1.append('id', applicationID);
      formData1.append('file_id', fileID);
      formData1.append('file', fileData);
      formData1.append("logged_user_id",userInfo.id)
      post(REPLACE_FILE, formData1).then((response: any) => {
        if (!response || !response.data) return;
        if (response.data.result == "success") {
          setDialogData({
            "header": "Thank You!",
            "text": response.data.message
          })
          setShowIncubatorSuccessModal(true)
        }
        else {
          setFailDialogData({
            "header": "Oops !!",
            "text": response.data.message
          })
          setShowIncubatorFailureModal(true);
        }
      })
    }
  }



  const onChangedDisbursementProof = (e: any, applicationID: any) => {

    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)

    const fileData = e.target.files[0];
    let formData = new FormData();
    formData.append('application_id', applicationID);
    formData.append('file', fileData);
    formData.append('user_Role', userInfo.roles);
    formData.append('logged_user_id', userInfo.id);
    post(UPLOAD_DISBURSEMENT_PROOF, formData).then((response: any) => {
      if (!response || !response.data) return;
      if (response.data.result == "success") {
        setDialogData({
          "header": "Thank You!",
          "text": response.data.message
        })
        setShowIncubatorSuccessModal(true)
      }
      else {
        setFailDialogData({
          "header": "Oops !!",
          "text": response.data.message
        })
        setShowIncubatorFailureModal(true);
      }
    })
  }

  const getPPTPath = () => {
    get(EVALUATION_FILE).then((response: any) => {
      if (!response.data) return;
      setPPTPath(response.data.eacDeckPath);
    });
  }

  const onFileUploadComplete = (files: Array<File>, type: string) => {
    // console.log("testing", files)
    let indexes: Array<string> = [];
    let updatedUploadDocuments = Object.assign({}, cancellationData);
    for (const file of files) {


      switch (type) {
        case "file":
          setUploadFile([...uploadFile, file]);
          break;
        case "other_file":
          setOtherFile([...other_file, file])
          break;

      }

    }

    if (indexes.length) {
      indexes.map((ind) => {
        const position: number = files.findIndex((file) => file.name === ind);
        files.splice(position, 1);
      });
    }
  };

  const onFileDelete = (type: string) => {
    switch (type) {
      case "file":
        setUploadFile([]);
        break;
      case "other_file":
        setOtherFile([])
        break;


    };
  };

  const openCancellationPopup = (id: any) => {
    setApplicationID(id)
    setUploadFile([]);
    setOtherFile([]);
    setCancellationData({
      reason: '',
      amount_refunded: '',
      interest_funds: ''
    })
  }

  const submitCancellationRequest = () => {
   if(cancellationData.reason.length == 0){
    setFailDialogData({
      "header": "Oops !!",
      "text": "The reason field is required."
    })
    setShowIncubatorFailureModal(true);
   }else if(cancellationData.amount_refunded.length == 0){
    setFailDialogData({
      "header": "Oops !!",
      "text": "The amount refunded field is required."
    })
    setShowIncubatorFailureModal(true);
   }else if(cancellationData.interest_funds.length == 0){
    setFailDialogData({
      "header": "Oops !!",
      "text": "The interest funds field is required."
    })
    setShowIncubatorFailureModal(true);
   }else if(uploadFile.length == 0){
      setFailDialogData({
        "header": "Oops !!",
        "text": "The file field is required."
      })
      setShowIncubatorFailureModal(true);
    }else{
      // setShowIncubatorFailureModal(true);

    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)    
    const userId = userInfo.id

    const formData = new FormData();
    formData.append("application_id", applicationID);
    formData.append("logged_user_id", userId);

    formData.append("reason", cancellationData.reason);
    formData.append("amount_refunded", cancellationData.amount_refunded);
    formData.append("interest_funds", cancellationData.interest_funds);
    uploadFile.length != 0 && formData.append("file_path", uploadFile[0]);
    other_file.length != 0 && formData.append("file_path_other", other_file[0]);
    setDisabled(true);
    post(CANCELLATION_Re_REQUEST, formData).then((response: any) => {
      if (!response || !response.data) return;
      setDisabled(false);
      if (response.data.result == "success") {
        setDialogData({
          "header": "Thank You!",
          "text": response.data.message
        })
        setShowIncubatorSuccessModal(true)
      }
      else {
        setFailDialogData({
          "header": "Oops !!",
          "text": response.data.message
        })
        setShowIncubatorFailureModal(true);
      }

    })
  }
  }


  // const HandleChange = () => {
  //   // console.log("gvgghh")
  //   history.push({
  //     pathname: "/re-apply",
  //     state: { id: "", disabled: true },
  //   });
  // }


 

  return (
    <>
      {/* <IncubatorHeaderComponent /> */}

      <div className="app-inside app-apply-incubator">
        <div className="status-list-wrapper ">
          <div className="">
            <div className="app-inside-content">
              <div className="d-flex">
                {/* <p className="dashboard application-count">Re Applications ({applicationDataCount})</p> */}
                <div className="ml-auto">

                  {/* <button
                    className="btn btn-sisfs btn-transparent-primary mx-4"
                    data-toggle="collapse" onClick={() => HandleChange()}
                  > Re Apply Now 
                  </button> */}


                  {/* <button
                    className="btn btn-sisfs btn-transparent-primary mx-4"
                    data-toggle="collapse" disabled
                  >
                    Re Apply Now
                  </button> */}

                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-xs-12 col-sm-12">
                  {ApplicationListData1.map((item: any, index: any) => (
                    <>
                      <div className="status-list" key={index}>
                        <div className="row mb-3">
                        <div className="col-2">
                            <h4>Second Application <small>({item.label == "Draft" ? "In Progress" : "#" + (item.incubator_application_no === null || '' ? item.application_id : item.incubator_application_no)})</small></h4>
                            {item.cancellation_requested == "Yes" && <><small>{item.label}</small></>}
                          </div>

                          <div className="col-3 nopadding">
                            {item.cancellation_requested == "No" ? (
                              (item.label == "Draft" && <span className="status-badge blue-badge">
                              <b>{item.label}</b>
                            </span>) ||
                              (item.label == "Marked Incomplete" && <span className="status-badge yellow-badge">
                                <b>Incomplete</b>
                              </span>) ||
                              (item.label == "Submitted" && <span className="status-badge blue-badge">
                                <b>Submitted</b>
                              </span>) ||
                              (item.label == "On Hold" && <span className="status-badge red-badge">
                                <b>{item.label}</b>
                              </span>) ||
                              (item.label == "Closed" && <span className="status-badge grey-badge">
                                <b>{item.label}</b>
                              </span>) ||
                              (item.label == "Cancelled" && <span className="status-badge grey-badge">
                              <b>{item.label}</b>
                            </span>) ||
                              (item.label == "Pending Review" && <span className="status-badge yellow-badge">
                                <b>{item.label}</b>
                              </span>) ||
                              (item.label == "Rejected" && <span className="status-badge red-badge">
                                <b>{item.label}</b>
                              </span>) ||
                              (item.label == "Selected" && <span className="status-badge blue-badge">
                                <b>{item.label}</b>
                              </span>)
                              ) :
                              (
                                (item.label == "Selected" ?
                                  <span className="status-badge blue-badge small-font-badge">
                                    {item.cancellation_status}
                                  </span> :
                                  <span className="status-badge grey-badge">
                                    {/* {(item.label == "1")?"Draft":((item.label == "2")?"Submitted":(item.label == "4")?"Incomplete":"")} */}
                                    <b>{item.label}</b>
                                  </span>
                                )
                              )
                            }

                          </div>
                          <div className="col-2 nopadding"> {(item.label == "Selected" && item.cancellation_requested == "No") && <label className="link-orange-text link-underline nopadding" data-toggle="modal" onClick={() => openCancellationPopup(item.application_id)} data-target="#cancellationPopup1">Request Cancellation</label>}</div>
                          <div className="col-5 text-right nopadding pr-2">
                            <div className="btn-group header-action-section">
                              
                              {(item.label == "Rejected" || item.label == "On Hold" || item.label == "Selected" || item.label == "Pending Review") && (item.dpiit_comments != null) &&
                                <button
                                  className="btn btn-sisfs btn-transparent-primary"
                                  data-toggle="collapse" data-target={`#status_comment_${item.incubator_application_no}`}
                                >
                                  View Details
                                </button>}
                              {(item.label == "Marked Incomplete" || item.label == "Draft") ?
                                <>
                                {/* && (item.comments_data.length != 0) */}
                                  {(item.label == "Marked Incomplete")  && (item.comments_data.length != 0) &&
                                    <button
                                      className="btn btn-sisfs btn-transparent-primary"
                                      data-toggle="collapse" data-target={`#status_comment_${item.incubator_application_no}`}
                                    >
                                      View Details
                                    </button>}
                                  {item.label == "Draft" ? 
                                  <button
                                    className="btn btn-sisfs btn-transparent-primary" onClick={() =>
                                      deleteApplication(item.incubator_application_no)
                                    }
                                  >
                                    Delete
                                  </button> : null}
                                  <button
                                    className="btn btn-sisfs btn-yellow-primary"
                                    onClick={() =>
                                      continueEditing(item.incubator_application_no,item)
                                    }
                                  >
                                    Continue Editing 
                                  </button>

                                </>
                                : 
                                <button
                                  className="btn btn-sisfs btn-transparent-primary"
                                  onClick={() =>
                                    getActiveApplication(item.incubator_application_no)
                                  }
                                >
                                  View Application
                                </button>
                                }
                            </div>
                          </div>

                        </div>


                        <div className="row">
                          <div className="col-2">
                            {item.label == "Draft" ? <>
                              <span className="d-block mb-2">Last Updated</span>
                              <span>
                                <b>{FormatDate(item.updated_at)}</b>
                              </span>
                            </> : <>
                              <span className="d-block mb-2">Submitted</span>
                              <span>
                                <b>{FormatDate(item.submitted_at)}</b>
                                {/* <b>{FormatDate('2023-01-06 13:06:01')}</b> */}
                              </span>
                            </>}
                          </div>
                          {(item.label != "Rejected" && item.label != "Draft") ?
                            <>

                              {item.date_of_evaluation != null ?
                                <div className="col-2">

                                  <>
                                    <span className="d-block mb-2">EAC Application Evaluation</span>
                                    <span>
                                      <b>{FormatDate(item.date_of_evaluation)}</b>
                                    </span>
                                  </>
                                </div>
                                : null}
                              <div className="col-3">
                                {item.cancellation_requested == "No" ? <>
                                  <span className="d-block mb-2">Application Completeness Checked On</span>
                                  <span>
                                    <b>{item.status_created_at != null ? FormatDate(item.status_created_at) : "In Progress"}</b>
                                  </span>
                                </> : <>
                                  <span className="d-block mb-2">Funds Approved</span>
                                  <span>
                                    <b>{changeNumberFormat(Number(item.total_approved_amt), 1, 0) + ' (' + item.no_of_tranches + ' Tranches)'}</b>
                                  </span>
                                </>}
                              </div>
                            </> : null}
                          <div className={item.date_of_evaluation == null ? "col-7 text-right pr-3" : "col-5"}>

                          </div>
                        </div>

                      </div>
                      <div className="collapse status-collapse" id={`status_comment_${item.incubator_application_no}`}>
                        <div className="row">
                          <div className="col-5">
                            <label className="comment-heading">DPIIT Comments/Feedback</label><br />
                            {item.comment_updated_at != null && <label className="comment-date">{FormatDate(item.comment_updated_at)}</label>}
                          </div>
                        </div>
                        <div className="row">

                          <table className="reviewTable contactUsTable statusTable" >
                            <thead>
                              <tr>
                                <th className="table-heading">Application Status</th>
                                <th className="table-heading">Date</th>
                                <th className="table-heading">Comments</th>
                                
                              </tr>
                            </thead>
                            <tbody>
                              {item.comments_data.length == 0 ? <tr><td className="text-center" colSpan={3}>No data</td></tr> :
                                item.comments_data.map((item: any, index: any) => (
                                  <tr key={index}>
                                    <td className="text-left custom-td">{item.application_status}</td>
                                    <td className="text-left custom-td">{FormatDate(item.created_at)}</td>
                                    {item.dpiit_status_comment != null ? <td className="text-left custom-td">{showViewMore != "show_" + index && <><div>{showEllipsisText(item.dpiit_status_comment, 50)}</div>{item.dpiit_status_comment.length >= 50 && <span className="link-orange-text d-block" onClick={() => setShowViewMore('show_' + index)}>View More</span>}</>}
                                      {showViewMore == "show_" + index && <><div>{item.dpiit_status_comment}</div><span className="link-orange-text d-block" onClick={() => setShowViewMore('')}>View Less</span></>}</td> : <td className="text-left custom-td">-</td>}
                                  </tr>
                                ))
                              }
                            </tbody>
                            </table>
                        </div>


                      </div>
                    </>

                  ))}


                </div>

              </div>
            </div>
          </div>

      {/* cancellation popup */}
          <div className="modal fade" id="cancellationPopup1" role="dialog" aria-labelledby="exampleModalLabel1" aria-hidden="true">
            <div className="modal-dialog modal-dialog-slideout modal-md" role="document">
              <div className="modal-content">
                <div className="modal-header modal-header-bottom pr-5">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body pl-5 pr-5 pt-4">
                  <div className="popup-head mb-4 ml-3">
                    <div className="main-heading">Request Cancellation</div>

                  </div>

                  <label className="col-sm-12 col-form-head">
                    Reason for Cancellation
                  </label>
                  <div className="col-md-12 mb-4">

                    <textarea
                      className="form-control"
                      rows={3}
                      placeholder="Describe in Max. 1000 characters"
                      name="reason"
                      value={cancellationData.reason}
                      required={true}
                      maxLength={1000}
                      onChange={(e: any) => {
                        setCancellationData(prevState => ({
                          ...prevState,
                          reason: e.target.value
                        }))
                      }}
                    ></textarea>

                  </div>
                  <div className="col-md-12 mb-4">
                    <label className="col-form-head">
                      Amount to be Refunded
                    </label>
                    <input type="number" value={cancellationData.amount_refunded} placeholder="Enter Amount (&#8377;)" name="amount_refunded" onChange={(e: any) => {
                      setCancellationData(prevState => ({
                        ...prevState,
                        amount_refunded: e.target.value.toString().split(".").map((el: any, i: any) => i ? el.split("").slice(0, 2).join("") : el).join(".")
                      }))
                    }} className="form-control input-height" />
                  </div>

                  <div className="col-md-12 mb-5">
                    <label className="col-form-head">
                      Interest Accumulated on Funds
                    </label>
                    <input type="number" value={cancellationData.interest_funds} placeholder="Enter Amount (&#8377;)" name="interest_funds" onChange={(e: any) => {
                      setCancellationData(prevState => ({
                        ...prevState,
                        interest_funds: e.target.value.toString().split(".").map((el: any, i: any) => i ? el.split("").slice(0, 2).join("") : el).join(".")
                      }))
                    }} className="form-control input-height" />
                  </div>


                  <div className="col-md-12  mb-5">
                    <label className="col-form-head">
                      Audited Report of Bank Statement
                    </label>
                    <div className="">
                      <button
                        className="btn btn-sisfs btn-transparent-primary"
                        onClick={() => setOpenUploadBlock({ BankStatementUpload: true })}
                        disabled={false}
                      >
                        Upload
                      </button>
                      {(openUploadBlock && openUploadBlock["BankStatementUpload"]) && (
                        <>
                          <span
                            className="close"
                            onClick={() => setOpenUploadBlock({ BankStatementUpload: false })}
                          >
                            <i className="fa fa-close"></i>
                          </span>
                          <FileUploader
                            key={"bank_statement_upload"}
                            id={"bank_statement_upload"}
                            information="Drag & Drop the file or Browse"
                            fileType="application/pdf"
                            buttonLabel="Browse"
                            multiple={false}
                            enablePreview
                            IconComponent={() => (
                              <span className="fa fa-cloud-upload"></span>
                            )}
                            removeIconClass=""
                            existingFiles={uploadFile}
                            onUploadFinish={(files: Array<File>) => {
                              onFileUploadComplete(files, "file");
                            }}
                            onFileDelete={(deletedFile: File) => {
                              onFileDelete("file")
                            }}
                          />
                          <small>Supported file format - PDF only</small>

                        </>
                      )}

                    </div>
                  </div>

                  <div className="col-md-12  mb-4">
                    <label className="col-form-head">
                      Supporting Documents (Optional)
                    </label>
                    <div className="">
                      <button
                        className="btn btn-sisfs btn-transparent-primary"
                        onClick={() => setOpenUploadBlock({ SupportingDocumentsUpload: true })}
                        disabled={false}
                      >
                        Upload
                      </button>
                      {(openUploadBlock && openUploadBlock["SupportingDocumentsUpload"]) && (
                        <>
                          <span
                            className="close"
                            onClick={() => setOpenUploadBlock({ SupportingDocumentsUpload: false })}
                          >
                            <i className="fa fa-close"></i>
                          </span>
                          <FileUploader
                            key={"supporting_documents_upload"}
                            id={"supporting_documents_upload"}
                            information="Drag & Drop the file or Browse"
                            fileType="application/pdf"
                            buttonLabel="Browse"
                            multiple={false}
                            enablePreview
                            IconComponent={() => (
                              <span className="fa fa-cloud-upload"></span>
                            )}
                            removeIconClass=""
                            existingFiles={other_file}
                            onUploadFinish={(files: Array<File>) => {
                              onFileUploadComplete(files, "other_file");
                            }}
                            onFileDelete={(deletedFile: File) => {
                              onFileDelete("other_file")
                            }}
                          />
                          <small>Supported file format - PDF only</small>

                        </>
                      )}

                    </div>
                  </div>


                </div>
                <div className="modal-footer">
                  <div className="row modal-row">

                    <div className="col-md-6 text-center">
                      <button className="tertiary  btn-transparent-primary full-width" data-dismiss="modal">
                        Cancel
                      </button>
                    </div>
                    <div className="col-md-6 text-left">
                      <button className={isDisabled ? "btn btn-sisfs btn-yellow-primary full-width custom-disabled" : "btn btn-sisfs btn-yellow-primary full-width"} disabled={isDisabled} onClick={submitCancellationRequest}>
                        Save
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

           {showEditSuccessModal && (
            <>
              <div className="modal-backdrop fade show"></div>

              <div
                className="modal align-middle modal-sisfs d-block"
                id="FormSubmissionSuccess"
              >
                <div className="modal-dialog modal-dialog-centered modal-md">
                  <div className="modal-content">
                    <div className="modal-body text-center">
                      <i className="fa fa-check-circle-o"></i>
                      <h3 className="modal-message">Thank You !</h3>
                      <div className="modal-text">
                        Application has been deleted Successfully !!!
                      </div>
                      <div className="modal-action">
                        <button className="btn btn-sisfs btn-yellow-primary" onClick={dismissModal}>
                          Ok !!
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
    
          {showEditFailureModal && (
            <>
              <div className="modal-backdrop fade show"></div>
              <div
                className="modal align-middle modal-sisfs d-block"
                id="FormSubmissionError"
              >
                <div className="modal-dialog modal-dialog-centered modal-md">
                  <div className="modal-content">
                    <div className="modal-body text-center">
                      <h3 className="modal-message message-orange">
                        Oops! Something is Wrong
                      </h3><br />
                      <h3 className="modal-message message-orange">
                        Please Try Again!!!
                      </h3>
                      <div className="modal-action">
                        <button className="btn btn-sisfs btn-yellow-primary" onClick={dismissModal}>
                          OK!
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {showIncubatorSuccessModal && (
            <ConfirmationDialog
              headerText={dialogData.header}
              bodyText={dialogData.text}
              onDialogClose={dismissModal}
            />
          )}
          {showIncubatorFailureModal && (

            <ConfirmationDialog
              headerText={failDialogData.header}
              bodyText={failDialogData.text}
              onDialogClose={dismissModal}
              iconClass="test"
            />
          )}
        </div>
        {/* <GetApplicationInfoComponent /> */}

        {/* <ContactSectionComponent /> */}
        {/* applicationListData */}
        {/* <ApplicationStatusComponent props1={applicationData}/> */}
        {IncubatorModal && (
          <ConfirmationDialog
            headerText={"Oops !!"}
            bodyText={
              <div
                dangerouslySetInnerHTML={{
                  __html: `
            Dear Incubator, 
            <br/><br/>  
            Kindly note, we are currently not accepting any incubator applications for Startup India Seed Fund Scheme. Experts Advisory Committee has approved almost the entire corpus of funds under the scheme and there are enough applicants for the remaining corpus. Incubator applications may be admitted in the future, depending on the availability of funds. In case you are willing to apply for the scheme, kindly write to <a href="mailto:sisfs@investindia.org.in">sisfs@investindia.org.in</a> and <a href="mailto:seedfund@investindia.org.in">seedfund@investindia.org.in</a> and we will reach out to you in case of any slots available. 
            <br/><br/> 
            Regards, 
            <br/> 
            Seed Fund Secretariat.
          `,
                }}
              />
            }
            onDialogClose={() => setIncubatorModal(false)}
            iconClass="test"
          />
        )}
      </div>
    </>
  );
}

export default ApplicationStatusApply;


