import React, { Component, createRef, useState } from "react";
import homeseedfundneed from "../../images/seedfund-need.svg";
import whatsstartupindia from "../../images/whatsstartupindia.svg";
import seedfund from "../../images/seedfund.svg";
import objectives from "../../images/objectives.svg";
import Toparrow from "../../images/Toparrow.svg";
import Plus from "../../images/Plus.svg";
import Minus from "../../images/Minus.svg";
import { Link } from "react-scroll";
import logo from "./logo.svg";
import "../../styles/component.css";

function Incubator() {
  const [activeIndex, setActiveIndex] = useState("");
  const changeActive = (id: string) => {
    setActiveIndex(id);
  };
  const changeActiveBack = () => {
    setActiveIndex("");
  };
  return (
    <div className="">
      <div className="row about-content sticky-content" id="incubator">
        <div className="col-sm-12 col-md-4 sticky-dropdown-section">
          <div className="d-block d-sm-block d-md-none">
            <div className="sticky-dropdown">
              <div className="dropdown">
                <button
                  className="btn btn-large btn-sisfs btn-faq btn-transparent-primary dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                >
                  The Scheme and Eligibility Criteria
                  <span className="caret"></span>
                </button>
                <ul className="about-list dropdown-menu">
                  <li className="active">
                    <Link
                      activeClass="active"
                      to="what-startup-5"
                      offset={-125}
                      spy={true}
                      smooth={true}
                    >
                      The Scheme and Eligibility Criteria
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeClass="active"
                      to="what-startup-6"
                      offset={-125}
                      spy={true}
                      smooth={true}
                    >
                      About Application Process
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeClass="active"
                      to="what-startup-7"
                      offset={-125}
                      spy={true}
                      smooth={true}
                    >
                      About Evaluation Process
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeClass="active"
                      to="what-startup-8"
                      offset={-125}
                      spy={true}
                      smooth={true}
                    >
                      Post Evaluation Process
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeClass="active"
                      to="what-startup-9"
                      offset={-125}
                      spy={true}
                      smooth={true}
                    >
                      Disbursement of the Seed Fund
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <ul className="about-list d-none d-sm-none d-md-block">
            <li className="active">
              <Link
                activeClass="active"
                to="what-startup-5"
                offset={-130}
                spy={true}
                smooth={true}
              >
                About the Scheme and Eligibility Criteria
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="what-startup-6"
                offset={-130}
                spy={true}
                smooth={true}
              >
                About Application Process
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="what-startup-7"
                offset={-130}
                spy={true}
                smooth={true}
              >
                About Evaluation Process
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="what-startup-8"
                offset={-130}
                spy={true}
                smooth={true}
              >
                Post Evaluation Process
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="what-startup-9"
                offset={-130}
                spy={true}
                smooth={true}
              >
                Disbursement of the Seed Fund
              </Link>
            </li>
          </ul>
        </div>
        <div className="right-cont col-xs-12 col-sm-12 col-md-8">
          <div id="what-startup-5">
            <div className="main-heading right-heading">
              About the Scheme and Eligibility Criteria
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-40"
                  className={
                    activeIndex == "40"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  What is Startup India Seed Fund Scheme?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "40" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-40"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-40"
                      className="hide-icon pull-right"
                      id="show-40"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-40"
                      onClick={() => changeActive("40")}
                      data-toggle="collapse"
                      data-target="#question-40"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-40"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-40">
                Startup India Seed Fund Scheme (SISFS) provides financial
                assistance to startups for proof of concept, prototype
                development, product trials, market-entry, and
                commercialization. Eligible startups can apply for the scheme on
                the Startup India portal. The Seed Fund will be disbursed to
                selected startups through eligible incubators across India.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-41"
                  className={
                    activeIndex == "41"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  What is the eligibility criteria for incubators to apply for
                  SISFS?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "41" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-41"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-41"
                      className="hide-icon pull-right"
                      id="show-41"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-41"
                      onClick={() => changeActive("41")}
                      data-toggle="collapse"
                      data-target="#question-41"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-41"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-41">
                Any Indian incubator operational for at least two years (for
                Government-supported incubators) and for three years (for
                incubators non supported by Government) on the date of
                application can apply to the scheme. For detailed eligibility
                criteria, please visit{" "}
                <a
                  href="https://seedfund.startupindia.gov.in/about"
                  target="_blank"
                >
                  <u className="link-orange-text">
                    https://seedfund.startupindia.gov.in/about
                  </u>
                </a>
                {/* <a href=" www.abc.com/incubators" target="_blank"><u className="link-orange-text"> www.abc.com/incubators</u></a>*/}{" "}
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-42"
                  className={
                    activeIndex == "42"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  What is the quantum of funds offered by the scheme to a
                  selected incubator?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "42" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-42"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-42"
                      className="hide-icon pull-right"
                      id="show-42"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-42"
                      onClick={() => changeActive("42")}
                      data-toggle="collapse"
                      data-target="#question-42"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-42"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-42">
                A Grant of up to Rs. 5 (five) crore would be provided to a
                selected incubator in milestone-based three (or) more
                installments. The exact quantum of grant and instalments for
                each incubator will be decided by the Experts Advisory Committee
                (EAC) based on its evaluation.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-43"
                  className={
                    activeIndex == "43"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  Is there a Management Fee provisioned in the scheme for
                  selected incubators?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "43" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-43"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-43"
                      className="hide-icon pull-right"
                      id="show-43"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-43"
                      onClick={() => changeActive("43")}
                      data-toggle="collapse"
                      data-target="#question-43"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-43"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-43">
                Yes, a component of Management Fee @ 5% of Seed Fund grant to
                the incubator will be provisioned (i.e. if an incubator is
                granted Rs. 1 crore of Seed Fund, then by including management
                fee @ 5%, the total assistance would be Rs. 1.050 crore). The
                Management Fee will be utilized for administrative expenditure,
                selection and due diligence of startups, and monitoring of
                progress of beneficiary startups. The Management Fee provisioned
                for incubators shall not be used by the incubator for facility
                creation or any other administrative expenses. As Installments
                of the Grant are released to incubators, proportionate
                Management fee shall also be released with each installment.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-44"
                  className={
                    activeIndex == "44"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  What can the incubator use this Grant of up to Rs 5 Cr. for?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "44" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-44"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-44"
                      className="hide-icon pull-right"
                      id="show-44"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-44"
                      onClick={() => changeActive("44")}
                      data-toggle="collapse"
                      data-target="#question-44"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-44"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-44">
                Incubators shall use the grant for disbursal of seed fund to
                eligible startups as follows:<br></br>
                <div className="ordered-list wrapper faq-ordered-list">
                  <ol>
                    {" "}
                    <li>
                      Up to Rs. 20 Lakhs as grant for validation of Proof of
                      Concept, or prototype development, or product trials. The
                      grant shall be disbursed in milestone-based installments.
                      These milestones can be related to development of
                      prototype, product testing, building a product ready for
                      market launch, etc.
                    </li>
                    <li>
                      Up to Rs. 50 Lakhs of investment for market entry,
                      commercialization, or scaling up through convertible
                      debentures or debt or debt-linked instruments
                    </li>
                    <li>
                      Not more than 20% of the total grant to an incubator shall
                      be given as grants to startups by incubator
                    </li>
                    <li>
                      A startup applicant can avail seed support in the form of
                      grant and debt/convertible debentures each once as per the
                      guidelines of the scheme
                    </li>
                  </ol>
                </div>
                Incubators shall not use the grant for facility creation or any
                other expenses. A component of Management Fee @ 5% of Seed Fund
                grant to the incubator will be provisioned (i.e. if an incubator
                is granted Rs. 1 crore of Seed Fund, then by including
                management fee @ 5%, the total assistance would be Rs. 1.050
                crore). The Management Fee will be utilized for administrative
                expenditure, selection and due diligence of startups, and
                monitoring of progress of beneficiary startups. The Management
                Fee provisioned for incubators shall not be used by the
                incubator for facility creation or any other administrative
                expenses.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-45"
                  className={
                    activeIndex == "45"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  How much time does the incubator have to disburse the entire
                  sanctioned fund to startups?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "45" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-45"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-45"
                      className="hide-icon pull-right"
                      id="show-45"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-45"
                      onClick={() => changeActive("45")}
                      data-toggle="collapse"
                      data-target="#question-45"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-45"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-45">
                The grant should be utilized fully by the incubator within a
                period of three years from the date of receipt of the first
                installment of funds. If the Incubator has not utilized at least
                50% of the total commitment within the first 2 years, then the
                Incubator will not be eligible for any further drawdowns. It
                will return all unutilized funds along with interest.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-46"
                  className={
                    activeIndex == "46"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  What are the guidelines of the scheme?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "46" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-46"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-46"
                      className="hide-icon pull-right"
                      id="show-46"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-46"
                      onClick={() => changeActive("46")}
                      data-toggle="collapse"
                      data-target="#question-46"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-46"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-46">
                The terms and conditions of seed fund under the scheme can be
                found in scheme guidelines document available at{" "}
                <a
                  href="https://www.startupindia.gov.in/content/dam/invest-india/Templates/public/Guidelines%20for%20Startup%20India%20Seed%20Fund%20Scheme.pdf"
                  target="_blank"
                >
                  <u className="link-orange-text">
                    https://www.startupindia.gov.in/content/dam/invest-india/Templates/public/Guidelines%20for%20Startup%20India%20Seed%20Fund%20Scheme.pdf
                  </u>
                </a>
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-47"
                  className={
                    activeIndex == "47"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  What are my responsibilities under this scheme?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "47" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-47"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-47"
                      className="hide-icon pull-right"
                      id="show-47"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-47"
                      onClick={() => changeActive("47")}
                      data-toggle="collapse"
                      data-target="#question-47"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-47"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-47">
                The incubators shall be responsible for providing physical
                infrastructure to the selected startups for regular functioning,
                support for testing and validating ideas, mentoring for
                prototype or product development or commercialization, and
                developing capacities in finance, human resources, legal
                compliances, and other functions. They are also expected to
                provide networking with investors and opportunities for
                showcasing in various national and international events. If the
                selected startup does not want to utilize the physical
                infrastructure of the incubator, the incubator shall offer all
                other resources and services to the startup.
              </div>
            </div>

            <div className="question-tab">
              <div className="row">
                <div
                  id="q-48"
                  className={
                    activeIndex == "48"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  What is the ISMC?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "48" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-48"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-48"
                      className="hide-icon pull-right"
                      id="show-48"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-48"
                      onClick={() => changeActive("48")}
                      data-toggle="collapse"
                      data-target="#question-48"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-48"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-48">
                Each of the incubators applying for the Startup India Seed Fund
                Scheme will constitute a committee called the Incubator Seed
                Management Committee (ISMC), consisting of experts who can
                evaluate and select startups for seed support. The composition
                of ISMC would be as follows:<br></br>
                <div className="ordered-list wrapper faq-ordered-list">
                  <ol>
                    {" "}
                    <li>Nominee of Incubator (Chairman)</li>
                    <li>
                      Representative from State Government’s Startup Nodal Team
                    </li>
                    <li>
                      Representative of a Venture Capital Fund or Angel Network
                    </li>
                    <li>A domain expert from Industry</li>
                    <li>A domain expert from academia</li>
                    <li>Two successful Entrepreneurs</li>
                    <li>Any other relevant Stakeholder</li>
                  </ol>
                </div>
                The final composition and members of ISMC of each incubator
                shall be approved by EAC and will be a critical parameter in
                selection of incubators
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-49"
                  className={
                    activeIndex == "49"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  I am not able to find relevant members for ISMC. Can Startup
                  India or EAC help me find members?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "49" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-49"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-49"
                      className="hide-icon pull-right"
                      id="show-49"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-49"
                      onClick={() => changeActive("49")}
                      data-toggle="collapse"
                      data-target="#question-49"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-49"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-49">
                Startup India or EAC shall not be directly involved in
                constituting the members of ISMC at each incubator. It is the
                responsibility of the selected incubator to constitute the ISMC.
              </div>
            </div>
            {/* <div className='question-tab'><div className="row"><div id="q-50" className={activeIndex=="50"?"para-text question-heading-orange col-sm-11":"para-text question-heading col-sm-11"}>As an incubator, I have never disbursed funds to startups before. How can I build expertise in this?</div><div className="col-sm-1">{activeIndex=="50"?<div  onClick={changeActiveBack} data-toggle="collapse" data-target="#question-50" role="button" aria-expanded="false" aria-controls="question-50" className="hide-icon pull-right" id="show-50"><img src={Minus} className="img-fluid" alt="SISFS Logo" /></div>:<div id="hide-50"  onClick={() => changeActive("50")} data-toggle="collapse" data-target="#question-50" role="button" aria-expanded="false" aria-controls="question-50" className="expand-icon pull-right"><img src={Plus} className="img-fluid" alt="SISFS Logo" /></div>}</div></div><div className="collapse para-text help-content" id="question-50">DPIIT will organize a training session for each selected incubator to apprise them about the reporting mechanisms, EAC reviews, and best practices on managing the fund.
                        As mentioned in the Guidelines, the financing of beneficiaries should be done with efficiency and care. Selected incubators would be responsible for proper management and disbursement of the Seed Fund. Selected incubator shall maintain a transparent process of selection, monitoring, and disbursement mechanism for the fund. Seed Fund would be disbursed to selected startups after due diligence by the incubator.
</div></div> */}
          </div>
          <div id="what-startup-6" className="right-cols">
            <div className="main-heading right-heading">
              About Application Process
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-51"
                  className={
                    activeIndex == "51"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  How can I apply to SISFS?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "51" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-51"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-51"
                      className="hide-icon pull-right"
                      id="show-51"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-51"
                      onClick={() => changeActive("51")}
                      data-toggle="collapse"
                      data-target="#question-51"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-51"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-51">
                An online call for applications is hosted on an ongoing basis on
                the Startup India portal at{" "}
                <a href="https://www.startupindia.gov.in/" target="_blank">
                  <u className="link-orange-text">
                    https://www.startupindia.gov.in
                  </u>
                </a>
              </div>
            </div>
            {/* <div className='question-tab'><div className="row"><div id="q-52" className={activeIndex=="52"?"para-text question-heading-orange col-sm-11":"para-text question-heading col-sm-11"}>What is the documentation required for the SISFS application?</div><div className="col-sm-1">{activeIndex=="52"?<div  onClick={changeActiveBack} data-toggle="collapse" data-target="#question-52" role="button" aria-expanded="false" aria-controls="question-52" className="hide-icon pull-right" id="show-52"><img src={Minus} className="img-fluid" alt="SISFS Logo" /></div>:<div id="hide-52"  onClick={() => changeActive("52")} data-toggle="collapse" data-target="#question-52" role="button" aria-expanded="false" aria-controls="question-52" className="expand-icon pull-right"><img src={Plus} className="img-fluid" alt="SISFS Logo" /></div>}</div></div><div className="collapse para-text help-content" id="question-52">The application form requires startups to give information about their business idea, how technology is being used in the business, monetary support received previously from the government, etc. Apart from these form fields, below is the list of documents you will need to furnish as part of your application: proofread after application form finalized<br></br><div className="ordered-list wrapper faq-ordered-list">
                            <ol>                         <li>Pitch deck – A presentation with information such as vision and value proposition, the problem being solved, target market and size of the opportunity, the solution, revenue and business model, product-market fit, Team, Financials, Roadmap, and utilization of fund with a timeline</li>
                                <li>Registration Certificate</li>
                                <li>Audited Annual Report (If required)</li>
                                <li>List of ISMC Members</li>
                                <li>Any other required document</li></ol></div></div></div> */}
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-53"
                  className={
                    activeIndex == "53"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  Is the SISFS application process completely online?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "53" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-53"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-53"
                      className="hide-icon pull-right"
                      id="show-53"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-53"
                      onClick={() => changeActive("53")}
                      data-toggle="collapse"
                      data-target="#question-53"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-2"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-53">
                The application submission is completely online, and no physical
                submission of documents is required.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-86"
                  className={
                    activeIndex == "86"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  Is there a fee for applying to this scheme?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "86" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-86"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-86"
                      className="hide-icon pull-right"
                      id="show-86"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-86"
                      onClick={() => changeActive("86")}
                      data-toggle="collapse"
                      data-target="#question-86"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-86"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-86">
                There are no application fees for the scheme. Even after the
                selection of a startup by an incubator for assistance under this
                scheme, the startup shall not be charged any fees. The incubator
                or any of its staff members shall not charge any fee in cash or
                in kind from applicants or beneficiaries under the scheme for
                any process of selection, disbursement, incubation, or
                monitoring.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-87"
                  className={
                    activeIndex == "87"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  Do I need to have a pool of startups ready to fund when I
                  apply for the scheme?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "87" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-87"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-87"
                      className="hide-icon pull-right"
                      id="show-87"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-87"
                      onClick={() => changeActive("87")}
                      data-toggle="collapse"
                      data-target="#question-87"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-87"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-87">
                No. A central Call for Applications for startups will be hosted
                by DPIIT on Startup India portal. Applicants can apply for seed
                fund to any three incubators selected as disbursing partners for
                this scheme in order of their preference. All applications
                received will be shared online with respective incubators for
                further evaluation.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-93"
                  className={
                    activeIndex == "93"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  Is the information provided by me in the application form kept
                  Confidential?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "93" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-93"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-93"
                      className="hide-icon pull-right"
                      id="show-93"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-93"
                      onClick={() => changeActive("93")}
                      data-toggle="collapse"
                      data-target="#question-93"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-93"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-93">
                We maintain confidentiality of all the proposals we receive
                under the scheme. Your application is only shared with the EAC
                for the purpose of evaluation and monitoring.{" "}
              </div>
            </div>
            {/* <div className='question-tab'><div className="row"><div id="q-94" className={activeIndex=="94"?"para-text question-heading-orange col-sm-11":"para-text question-heading col-sm-11"}>Can I apply to the Startup India Seed Fund Scheme again after receiving a rejection?</div><div className="col-sm-1">{activeIndex=="94"?<div  onClick={changeActiveBack} data-toggle="collapse" data-target="#question-94" role="button" aria-expanded="false" aria-controls="question-94" className="hide-icon pull-right" id="show-94"><img src={Minus} className="img-fluid" alt="SISFS Logo" /></div>:<div id="hide-94"  onClick={() => changeActive("94")} data-toggle="collapse" data-target="#question-94" role="button" aria-expanded="false" aria-controls="question-94" className="expand-icon pull-right"><img src={Plus} className="img-fluid" alt="SISFS Logo" /></div>}</div></div><div className="collapse para-text help-content" id="question-94">Yes, you can apply to the SISFS again after a rejection. However, there is a mandatory buffer time of minimum 3 months before reapplication.</div></div> */}
            {/* <div className='question-tab'><div className="row"><div id="q-94" className={activeIndex=="94"?"para-text question-heading-orange col-sm-11":"para-text question-heading col-sm-11"}>Can I apply to the Startup India Seed Fund Scheme again after receiving a rejection?</div><div className="col-sm-1">{activeIndex=="94"?<div  onClick={changeActiveBack} data-toggle="collapse" data-target="#question-94" role="button" aria-expanded="false" aria-controls="question-94" className="hide-icon pull-right" id="show-94"><img src={Minus} className="img-fluid" alt="SISFS Logo" /></div>:<div id="hide-94"  onClick={() => changeActive("94")} data-toggle="collapse" data-target="#question-94" role="button" aria-expanded="false" aria-controls="question-94" className="expand-icon pull-right"><img src={Plus} className="img-fluid" alt="SISFS Logo" /></div>}</div></div><div className="collapse para-text help-content" id="question-94">Yes, you can apply to the SISFS again after a rejection. However, there is a mandatory buffer time of minimum 3 months before reapplication.</div></div> */}
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-89"
                  className={
                    activeIndex == "89"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  What are the terms and conditions of seed fund under the
                  scheme?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "89" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-89"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-89"
                      className="hide-icon pull-right"
                      id="show-89"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-89"
                      onClick={() => changeActive("89")}
                      data-toggle="collapse"
                      data-target="#question-89"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-89"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-89">
                The scheme guidelines are available at Link{" "}
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-90"
                  className={
                    activeIndex == "90"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  Is the information provided by me in the application form kept
                  confidential?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "90" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-90"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-90"
                      className="hide-icon pull-right"
                      id="show-90"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-90"
                      onClick={() => changeActive("90")}
                      data-toggle="collapse"
                      data-target="#question-90"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-90"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-90">
                We maintain confidentiality of all the proposals we receive
                under the scheme. Your application is only shared with the
                incubators you apply to for the purpose of evaluation, and with
                the EAC for the purpose of monitoring.{" "}
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-91"
                  className={
                    activeIndex == "91"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  Do I need to be physically present at an incubator’s’ premises
                  to avail seed funds under this scheme?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "54" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-54"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-54"
                      className="hide-icon pull-right"
                      id="show-54"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-54"
                      onClick={() => changeActive("54")}
                      data-toggle="collapse"
                      data-target="#question-54"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-54"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-91">
                To apply for the scheme, it is not mandatory to be physically
                present at the incubator’s location. It is completely a
                voluntary choice. For startups being monitored virtually by the
                incubator, it is required for both parties to touch base every
                30 days. This is to ensure that an incubator will be able to
                keep a check on the progress of the startup and a startup will
                be able to take guidance for their business from the incubator.
              </div>
            </div>

            <div className="question-tab">
              <div className="row">
                <div
                  id="q-94"
                  className={
                    activeIndex == "94"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  Can I apply to the Startup India Seed Fund Scheme again after
                  receiving a rejection?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "94" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-94"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-94"
                      className="hide-icon pull-right"
                      id="show-94"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-94"
                      onClick={() => changeActive("94")}
                      data-toggle="collapse"
                      data-target="#question-94"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-94"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-94">
                Yes, you can apply to the SISFS again after 3 months of
                receiving a rejection. This buffer time is given to ensure that
                you have worked on the feedback received from incubators and are
                ready to be considered again.
              </div>
            </div>
          </div>
          <div id="what-startup-7" className="right-cols">
            <div className="main-heading right-heading">
              About Evaluation Process
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-55"
                  className={
                    activeIndex == "55"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  Who is going to review my application?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "55" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-55"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-55"
                      className="hide-icon pull-right"
                      id="show-55"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-55"
                      onClick={() => changeActive("55")}
                      data-toggle="collapse"
                      data-target="#question-55"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-55"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-55">
                An Experts Advisory Committee (EAC) has been constituted by
                DPIIT will evaluate and select incubators for allotment of Seed
                Funds, monitor progress, and take all necessary measures for
                efficient utilization of funds towards the fulfilment of
                objectives of the Startup India Seed Fund Scheme. The Experts
                Advisory Committee (EAC) members can be seen here
                {/* <div className="ordered-list wrapper faq-ordered-list">
                                <ol><li>Chairman, an individual of eminence</li>
                                    <li>Financial Advisor, DPIIT, or his representative</li>
                                    <li>Additional Secretary/ Joint Secretary/ Director/ Deputy Secretary, DPIIT (Convener)</li>
                                    <li>A representative of the Department of Biotechnology (DBT)</li>
                                    <li>A representative of the Department of Science & Technology (DST)</li>
                                    <li>A representative of the Ministry of Electronics and Information Technology (MeiTY)</li>
                                    <li>A representative of the Indian Council of Agricultural Research (ICAR)</li>
                                    <li>A representative of NITI Aayog</li>
                                    <li>At least 3 members nominated by Secretary, DPIIT from amongst members of the startup ecosystem, investors, experts in the domain of R&D, technology development and commercialization, entrepreneurship, and other relevant domains</li></ol></div> */}
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-56"
                  className={
                    activeIndex == "56"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  What are the evaluation criteria for the SISFS?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "56" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-56"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-56"
                      className="hide-icon pull-right"
                      id="show-56"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-56"
                      onClick={() => changeActive("56")}
                      data-toggle="collapse"
                      data-target="#question-56"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-56"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-56">
                <div className="ordered-list wrapper faq-ordered-list">
                  Incubators shall be selected based on the following
                  parameters:
                  <ol>
                    <li>Fulfillment of eligibility criteria</li>
                    <li>Quality of the team of Incubator</li>
                    <li>Available infrastructure, testing labs etc.</li>
                    <li>Composition of ISMC</li>
                    <li>
                      Incubation support provided by incubator in last three
                      years:<br></br>- No. of startups incubated<br></br>- No.
                      of startups graduated, i.e. progressed from one stage of
                      business development cycle to the next<br></br>- No. of
                      startups that raised follow on investments<br></br>- No.
                      of startups that crossed a revenue of Rs 1 Cr in last 1
                      year<br></br>- 2-year survival rate of startups from the
                      date of joining incubator
                    </li>
                    <li>
                      Funding support extended to incubatees in last three
                      years:<br></br>- Investment agreements signed between
                      incubator and startups<br></br>- No. of startups invested
                      in<br></br>- Total corpus allocated to incubatees<br></br>
                      - Total investments raised by incubatees from external
                      sources
                    </li>
                    <li>
                      Mentoring provided to incubatees in last three years:
                      <br></br>- No. of mentors hired<br></br>- Average
                      mentoring hours allocated per startup per month<br></br>-
                      No. of IP (patents, copyrights, designs, and trademarks)
                      registered by incubatees
                    </li>
                    <li>
                      Other support extended to incubatees in last three years:
                      <br></br>- Industry/Corporate connects<br></br>- Events
                      held for stakeholder engagements<br></br>- Participation
                      in other events
                    </li>
                    <li>
                      Number of startups that the incubator intends to support
                    </li>
                    <li>
                      Quantum of funds applied for, along with fund deployment
                      plan with timelines
                    </li>
                    <li>
                      Any other relevant parameters that decided by the EAC
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-57"
                  className={
                    activeIndex == "57"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  What is the process of evaluation?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "57" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-57"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-57"
                      className="hide-icon pull-right"
                      id="show-57"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-57"
                      onClick={() => changeActive("57")}
                      data-toggle="collapse"
                      data-target="#question-57"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-57"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-57">
                The Call for Applications for incubators will be open online
                throughout the year. Experts Advisory Committee (EAC) will
                convene at least quarterly to:
                <div className="ordered-list wrapper">
                  <ol>
                    <li>
                      Evaluate the applications received during the period
                    </li>
                    <li>Select incubators for funds under the Scheme</li>
                    <li>
                      Decide the total amount of fund and number of installments
                      in which it is to be allocated to each incubator
                    </li>
                    <li>
                      Specify milestones to be achieved by each incubator for
                      release of installments
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-58"
                  className={
                    activeIndex == "58"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  How much time will it take for my application to get
                  processed?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "58" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-58"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-58"
                      className="hide-icon pull-right"
                      id="show-58"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-58"
                      onClick={() => changeActive("58")}
                      data-toggle="collapse"
                      data-target="#question-58"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-58"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-58">
                The Call for Applications for incubators will be open online
                throughout the year. Experts Advisory Committee (EAC) will
                convene at least quarterly to evaluate the applications received
                during the period, select incubators for funds under the Scheme
                and, decide the total amount of fund and number of installments
                in which it is to be allocated to each incubator.{" "}
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-59"
                  className={
                    activeIndex == "59"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  How can I track the status of my application?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "59" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-59"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-59"
                      className="hide-icon pull-right"
                      id="show-59"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-59"
                      onClick={() => changeActive("59")}
                      data-toggle="collapse"
                      data-target="#question-59"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-59"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-59">
                Once the incubator has submitted its seed fund scheme
                application, they will get access to their respective dashboard.
                The dashboard can be accessed using their login credential to
                check the real-time application status
              </div>
            </div>
          </div>
          <div id="what-startup-8" className="right-cols">
            <div className="main-heading right-heading">
              Post-Evaluation Process
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-60"
                  className={
                    activeIndex == "60"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  I have been selected for seed fund, but the terms and
                  conditions being set by the EAC are not agreeable to me. What
                  should I do?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "60" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-60"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-60"
                      className="hide-icon pull-right"
                      id="show-60"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-60"
                      onClick={() => changeActive("60")}
                      data-toggle="collapse"
                      data-target="#question-60"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-60"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-60">
                Features of the scheme, as mentioned in Guidelines on{" "}
                <a
                  href="https://dipp.gov.in/sites/default/files/Guidelines-FundScheme-Startup-29January2021.pdf"
                  target="_blank"
                >
                  <u className="link-orange-text">
                    https://dipp.gov.in/sites/default/files/Guidelines-FundScheme-Startup-29January2021.pdf
                  </u>
                </a>
                , are non-negotiable. Any other terms, such as quantum of fund,
                quantum of each tranche, or milestones, are to be negotiated
                between the EAC and the incubator only. If a selected incubator
                chooses to not participate in the scheme for any reason, they
                can communicate the same to DPIIT and the EAC.
              </div>
            </div>

            <div className="question-tab">
              <div className="row">
                <div
                  id="q-103"
                  className={
                    activeIndex == "103"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  What is the process of fund disbursement to be followed by
                  incubators after getting selected by the EAC?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "103" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-103"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-103"
                      className="hide-icon pull-right"
                      id="show-103"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-103"
                      onClick={() => changeActive("103")}
                      data-toggle="collapse"
                      data-target="#question-103"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-103"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div
                className="collapse para-text help-content"
                id="question-103"
              >
                After approval by EAC, the following actionables must be
                completed before release of first instalment:-
                <br />
                <b>
                  Before issuance of Sanction Order to Incubator from DPIIT
                  (through email):-
                </b>
                <div className="ordered-list wrapper faq-ordered-list">
                  <ol>
                    <li>
                      To open a Trust and Retention Account in any Nationalized
                      Bank by the Incubator and share the ECS mandate form
                      signed by the bank in prescribed format
                    </li>
                    <li>
                      To share details on Public Financial Management System
                      (PFMS)
                      <br />
                      <b>a.</b> For Incubators already registered on PFMS: To
                      share Unique PFMS Agency Code
                      <br />
                      <b>b.</b> For Incubators not registered on PFMS: To share
                      following information -<br />
                      &nbsp;&nbsp;i. Type of registration:
                      <br />
                      &nbsp;&nbsp;ii. PAN Number:
                      <br />
                      &nbsp;&nbsp;iii. Agency Name:
                      <br />
                      &nbsp;&nbsp;iv. Act or Registration No:
                      <br />
                      &nbsp;&nbsp;v. Registering Authority:
                      <br />
                      &nbsp;&nbsp;vi. Registering Authority Name:
                      <br />
                      &nbsp;&nbsp;vii. State Of Registration:
                      <br />
                      &nbsp;&nbsp;viii. TIN Number:
                      <br />
                      &nbsp;&nbsp;ix. TAN Number:
                      <br />
                      &nbsp;&nbsp;x. Father/Husband Name:
                      <br />
                      &nbsp;&nbsp;xi. Birth Date: <br />
                      &nbsp;&nbsp;xii. GST Number: <br />
                      &nbsp;&nbsp;xiii. Full Address: <br />
                      &nbsp;&nbsp;xiv. Contact Person: <br />
                      &nbsp;&nbsp;xv. Designation:
                      <br />
                      &nbsp;&nbsp;xvi. Phone Number:
                      <br />
                      &nbsp;&nbsp;xvii. STD Code:
                      <br />
                      &nbsp;&nbsp;xviii. Phone Number: <br />
                      &nbsp;&nbsp;xix. Mobile Number: <br />
                      &nbsp;&nbsp;xx. Email: <br />
                      &nbsp;&nbsp;xxi. Agency Name In Bank:
                      <br />
                      &nbsp;&nbsp;xxii. Bank Name:
                      <br />
                      &nbsp;&nbsp;xxiii. IFSC Code: <br />
                      &nbsp;&nbsp;xxiv. Account Number:
                    </li>
                    <li>
                      To share the ID of registration on Darpan Portal of NITI
                      Aayog (www.ngodarpan.gov.in).
                    </li>
                  </ol>
                </div>
                <b>
                  After Issuance of Sanction Order to ensure release of funds
                </b>
                <br />
                Below mentioned documents need to be shared through email at
                sis-dipp@gov.in and physical copy through post at the following
                address: (Startup India Section, Room No.: 435A, DPIIT, Ministry
                of Commerce & Industry, Udyog Bhawan, New Delhi – 110011)
                <div className="ordered-list wrapper faq-ordered-list">
                  <ol>
                    <li>
                      To furnish TRA Agreement signed by the concerned bank and
                      Incubator
                    </li>
                    <li>
                      To send pre-receipt in prescribed format affixing revenue
                      stamp and signing across
                    </li>
                    <li>
                      To send an undertaking that it will comply with all the
                      terms and conditions stipulated in the sanction order,
                      including that the incubator will not avail any funds from
                      any other Ministry/Department of Government of India or
                      State Government for this purpose under SISFS.
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            {/* <div className='question-tab'><div className="row"><div id="q-61" className={activeIndex=="61"?"para-text question-heading-orange col-sm-11":"para-text question-heading col-sm-11"}>I have been selected for seed fund. Will I need to sign a legal agreement with DPIIT?</div><div className="col-sm-1">{activeIndex=="61"?<div  onClick={changeActiveBack} data-toggle="collapse" data-target="#question-61" role="button" aria-expanded="false" aria-controls="question-61" className="hide-icon pull-right" id="show-61"><img src={Minus} className="img-fluid" alt="SISFS Logo" /></div>:<div id="hide-61"  onClick={() => changeActive("61")} data-toggle="collapse" data-target="#question-61" role="button" aria-expanded="false" aria-controls="question-61" className="expand-icon pull-right"><img src={Plus} className="img-fluid" alt="SISFS Logo" /></div>}</div></div><div className="collapse para-text help-content" id="question-61">Yes, it is mandatory to sign a legal agreement with DPIIT to avail the seed fund you have been granted.</div></div> */}
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-62"
                  className={
                    activeIndex == "62"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  How much time will it take for the seed fund to be released to
                  a selected incubator?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "62" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-62"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-62"
                      className="hide-icon pull-right"
                      id="show-62"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-62"
                      onClick={() => changeActive("62")}
                      data-toggle="collapse"
                      data-target="#question-62"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-62"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-62">
                Experts Advisory Committee (EAC) will convene at least quarterly
                to evaluate incubator applications and select incubators for
                funds under the Scheme. The exact quantum of grant and
                instalments for each incubator will be decided by the Experts
                Advisory Committee (EAC) based on its evaluation. DPIIT will
                release the first tranche of funds to the incubator as per the
                directions of EAC. When the cash-in-hand of the incubator goes
                below 10% of the total commitment by EAC, the Incubator may
                request for the next installment, which shall be released to
                incubator within 30 days of submission of proof of achievement
                of milestones. The incubator shall submit the interim progress
                update and utilisation certificate to initiate the release of
                subsequent installment of grant.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-63"
                  className={
                    activeIndex == "63"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  How many tranches with the sanctioned seed fund be released in
                  to the selected incubators?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "63" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-63"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-63"
                      className="hide-icon pull-right"
                      id="show-63"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-63"
                      onClick={() => changeActive("63")}
                      data-toggle="collapse"
                      data-target="#question-63"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-63"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-63">
                A Grant of up to Rs. 5 (five) crore would be provided to a
                selected incubator in milestone-based three (or) more
                installments. The exact quantum of grant and instalments for
                each incubator will be decided by the Experts Advisory Committee
                (EAC) based on its evaluation. The quantum of first installment
                may be up to 40% of total approved commitment.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-64"
                  className={
                    activeIndex == "64"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  Where can selected incubators receive funds?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "64" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-64"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-64"
                      className="hide-icon pull-right"
                      id="show-64"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-64"
                      onClick={() => changeActive("64")}
                      data-toggle="collapse"
                      data-target="#question-64"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-64"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-64">
                Each selected incubator shall maintain an exclusive,
                project-specific Trust and Retention Account (TRA) with any
                nationalized bank.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-65"
                  className={
                    activeIndex == "65"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  How often do I have to report back my progress after receiving
                  seed fund?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "65" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-65"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-65"
                      className="hide-icon pull-right"
                      id="show-65"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-65"
                      onClick={() => changeActive("65")}
                      data-toggle="collapse"
                      data-target="#question-65"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-65"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-65">
                <div className="ordered-list wrapper faq-ordered-list">
                  <ol>
                    <li>
                      Each Incubator shall provide information on progress of
                      selected startups (see Para 10 of guidelines) real-time to
                      Startup India through their online dashboards and shall
                      present the same to EAC on a quarterly basis
                    </li>
                    <li>
                      Incubator shall also report return on investment for each
                      Startup.
                    </li>
                    <li>
                      Each incubator shall report the funds sanctioned,
                      received, and disbursed to each startup for each financial
                      year
                    </li>
                    <li>
                      Incubators would also submit detailed report on status of
                      utilization of funds and audited expenditure for each
                      financial year
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-66"
                  className={
                    activeIndex == "66"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  I want to quit the scheme. What are the provisions?{" "}
                </div>
                <div className="col-sm-1">
                  {activeIndex == "66" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-66"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-66"
                      className="hide-icon pull-right"
                      id="show-66"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-66"
                      onClick={() => changeActive("66")}
                      data-toggle="collapse"
                      data-target="#question-66"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-66"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-66">
                An incubator can quit the scheme. The procedure for the same
                depends on the stage of the incubator in the application
                process.
                <div className="ordered-list wrapper faq-ordered-list">
                  <ol>
                    {/* <ol><li>Each Incubator shall provide information on progress of selected startups (see Para 10 of guidelines) real-time to Startup India through their online dashboards and shall present the same to EAC on a quarterly basis</li> */}
                    <li>
                      If the incubator has been selected and the first tranche
                      is approved, they will be required to write a short letter
                      mentioning the reason to pull out of the process. Post the
                      acknowledgment of the letter, they will find a cancel
                      application button on the application tracker tab of their
                      dashboard which will allow them to cancel their
                      application
                    </li>
                    <li>
                      If the incubator has already received the first tranche,
                      it will be required to write a short letter mentioning the
                      reason to pull out of the process. Post acknowledgment of
                      the letter, they will be obliged to return the fund along
                      with the interest accumulated within 15 days. Post the
                      acknowledgment of refund, they will find a cancel
                      application button on the application tracker tab of their
                      dashboard
                    </li>
                    {/* <li>Incubators would also submit detailed report on status of utilization of funds and audited expenditure for each financial year</li> */}
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div id="what-startup-9" className="right-cols">
            <div className="main-heading right-heading">
              Disbursement of the Seed Fund
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-67"
                  className={
                    activeIndex == "67"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  What is the ISMC?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "67" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-67"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-67"
                      className="hide-icon pull-right"
                      id="show-67"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-67"
                      onClick={() => changeActive("67")}
                      data-toggle="collapse"
                      data-target="#question-67"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-67"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-67">
                Each of the incubators applying for the Startup India Seed Fund
                Scheme will constitute a committee called the Incubator Seed
                Management Committee (ISMC), consisting of experts who can
                evaluate and select startups for seed support. The composition
                of ISMC would be as follows:
                <div className="ordered-list wrapper faq-ordered-list">
                  <ol>
                    <li>Nominee of Incubator (Chairman)</li>
                    <li>
                      Representative from State Government’s Startup Nodal Team
                    </li>
                    <li>
                      Representative of a Venture Capital Fund or Angel Network
                    </li>
                    <li>A domain expert from Industry</li>
                    <li>A domain expert from academia</li>
                    <li>Two successful Entrepreneurs</li>
                    <li>Any other relevant Stakeholder</li>
                  </ol>
                </div>
                The final composition and members of ISMC of each incubator
                shall be approved by EAC and will be a critical parameter in
                selection of incubators
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-68"
                  className={
                    activeIndex == "68"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  What is the process for selecting startups for seed fund?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "68" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-68"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-68"
                      className="hide-icon pull-right"
                      id="show-68"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-68"
                      onClick={() => changeActive("68")}
                      data-toggle="collapse"
                      data-target="#question-68"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-68"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-68">
                <div className="ordered-list wrapper faq-ordered-list">
                  <ol>
                    <li>
                      An online call for applications shall be hosted for
                      startups on an ongoing basis on the Startup India portal
                    </li>
                    <li>
                      Applicants can apply for seed fund to any three incubators
                      selected as disbursing partners for this scheme in order
                      of their preference
                    </li>
                    <li>
                      All applications received will be shared online with
                      respective incubators for further evaluation
                    </li>
                    <li>
                      The incubators shall shortlist applicants as per
                      eligibility criteria
                    </li>
                    <li>
                      Incubator may shortlist applicants based on their
                      evaluation for a presentation before ISMC
                    </li>
                    <li>
                      ISMC shall evaluate applicants based on their submissions
                      and presentations and select startups for Seed Fund within
                      45 days of receipt of application
                    </li>
                    <li>
                      All incubators shall provide information about progress of
                      evaluation of startups real-time to Startup India portal
                    </li>
                    <li>
                      Selected startups shall receive seed funding under the
                      respective incubator that selects them as beneficiaries as
                      per their preference shared during application (for
                      example, if incubators at Preference 1 and Preference 2
                      both select a startup, the funding shall be given by
                      Preference 1 incubator. If Preference 1 incubator rejects
                      and Preference 2 incubator selects, the funding shall be
                      given by incubator at Preference 2, and so on.){" "}
                    </li>
                    <li>
                      Incubator shall conduct due diligence of the startups and
                      sign legal agreement with them
                    </li>
                    <li>
                      For grants, the first installment to any selected startup
                      shall be released not more than 60 days from receipt of
                      application from the startup.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-69"
                  className={
                    activeIndex == "69"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  What are the parameters for evaluation of startup applicants?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "69" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-69"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-69"
                      className="hide-icon pull-right"
                      id="show-69"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-69"
                      onClick={() => changeActive("69")}
                      data-toggle="collapse"
                      data-target="#question-69"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-69"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-69">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Criteria</th>
                        <th>Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Is there a need for this Idea?</td>
                        <td>
                          Market size, what market gap is it filling, does it
                          solve a real-world problem?
                        </td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Feasibility</td>
                        <td>
                          Feasibility and reasonability of the technical claims,
                          methodology used/ to be used for PoC and validation,
                          roadmap for product development
                        </td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Potential Impact</td>
                        <td>
                          Customer demographic & the technology’s effect on
                          these, national importance (if any)
                        </td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>Novelty</td>
                        <td>USP of the technology, associated IP </td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>Team</td>
                        <td>
                          Strength of the team, Technical and business expertise{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <td>Fund Utilization Plan</td>
                        <td>Roadmap of money utilization </td>
                      </tr>
                      <tr>
                        <td>7</td>
                        <td>Additional Parameters</td>
                        <td>
                          Any additional parameters considered appropriate by
                          incubator{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>8</td>
                        <td>Presentation</td>
                        <td>Overall assessment</td>
                      </tr>
                      <tr>
                        <td colSpan={3}>
                          Weightages for each of the above criteria may be
                          assigned by incubator.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-70"
                  className={
                    activeIndex == "70"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  Do incubators need the approval of EAC for selecting or
                  funding a startup?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "70" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-70"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-70"
                      className="hide-icon pull-right"
                      id="show-70"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-70"
                      onClick={() => changeActive("70")}
                      data-toggle="collapse"
                      data-target="#question-70"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-70"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-70">
                No. The ISMC of each incubator will be responsible for
                evaluating and selecting startups for seed support under the
                scheme.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-71"
                  className={
                    activeIndex == "71"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  How much seed funding can a startup receive under the scheme?{" "}
                </div>
                <div className="col-sm-1">
                  {activeIndex == "71" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-71"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-71"
                      className="hide-icon pull-right"
                      id="show-71"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-71"
                      onClick={() => changeActive("71")}
                      data-toggle="collapse"
                      data-target="#question-71"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-71"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-71">
                Seed Fund to an eligible startup by the incubator shall be
                disbursed as follows:
                <div className="ordered-list wrapper faq-ordered-list">
                  <ol>
                    <li>
                      Up to Rs. 20 Lakhs as grant for validation of Proof of
                      Concept, or prototype development, or product trials. The
                      grant shall be disbursed in milestone-based installments.
                      These milestones can be related to development of
                      prototype, product testing, building a product ready for
                      market launch, etc.
                    </li>
                    <li>
                      Up to Rs. 50 Lakhs of investment for market entry,
                      commercialization, or scaling up through convertible
                      debentures or debt or debt-linked instruments
                    </li>
                    <li>
                      Not more than 20% of the total grant to an incubator shall
                      be given as grants to startups by incubator
                    </li>
                    <li>
                      A startup applicant can avail seed support in the form of
                      grant and debt/convertible debentures each once as per the
                      guidelines of the scheme
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-72"
                  className={
                    activeIndex == "72"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  What are the terms and conditions of seed funding through
                  convertible debentures or debt or debt-linked instruments?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "72" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-72"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-72"
                      className="hide-icon pull-right"
                      id="show-72"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-72"
                      onClick={() => changeActive("72")}
                      data-toggle="collapse"
                      data-target="#question-72"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-72"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-72">
                For startups being supported through convertible debentures, or
                debt, or debt-linked instruments, funds shall be provided at a
                rate of interest of not more than prevailing repo rate. The
                tenure should be fixed at the time of sanctioning the loan by
                the incubator, which shall be not more than 60 months (5 years).
                A moratorium of up to 12 months may be provided for the
                startups. Because of the early stage of the startups, this shall
                be unsecured and no guarantee from promoter or third-party will
                be required.{" "}
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-73"
                  className={
                    activeIndex == "73"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  If the startup requires legal help for finalizing their seed
                  fund engagement with an incubator, who will bear the cost for
                  these services?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "73" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-73"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-73"
                      className="hide-icon pull-right"
                      id="show-73"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-73"
                      onClick={() => changeActive("73")}
                      data-toggle="collapse"
                      data-target="#question-73"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-73"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-73">
                The startup shall bear the cost of any consultant hired to guide
                them finalizing their seed fund engagement with an incubator.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-74"
                  className={
                    activeIndex == "74"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  Who will be bearing the cost for due diligence of the
                  startups?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "74" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-74"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-74"
                      className="hide-icon pull-right"
                      id="show-74"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-74"
                      onClick={() => changeActive("74")}
                      data-toggle="collapse"
                      data-target="#question-74"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-74"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-74">
                The Management Fee provisioned to each selected incubator will
                be utilized for administrative expenditure, selection and due
                diligence of startups, and monitoring of progress of beneficiary
                startups.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-75"
                  className={
                    activeIndex == "75"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  Do incubators need to sign an Agreement with selected
                  startups?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "75" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-75"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-75"
                      className="hide-icon pull-right"
                      id="show-75"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-75"
                      onClick={() => changeActive("75")}
                      data-toggle="collapse"
                      data-target="#question-75"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-75"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-75">
                The incubator shall execute a legal agreement with the selected
                startups before the release of the first installment. The
                incubators shall ensure that the necessary terms and conditions,
                including milestones, related to the Seed Fund are clearly
                detailed in the agreement.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-76"
                  className={
                    activeIndex == "76"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  Will incubators be provided a sample Agreement to sign with
                  each selected startup?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "76" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-76"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-76"
                      className="hide-icon pull-right"
                      id="show-76"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-76"
                      onClick={() => changeActive("76")}
                      data-toggle="collapse"
                      data-target="#question-76"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-76"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-76">
                Yes. The incubator can make suitable changes to the sample
                agreement on the basis of the terms and conditions finalized
                with each selected startup. However, no mandatory clause of the
                Guidelines may be changed in this Agreement.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-77"
                  className={
                    activeIndex == "77"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  Can incubators charge any fee from the selected startups?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "77" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-77"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-77"
                      className="hide-icon pull-right"
                      id="show-77"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-77"
                      onClick={() => changeActive("77")}
                      data-toggle="collapse"
                      data-target="#question-77"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-77"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-77">
                A startup selected by an incubator for assistance under this
                scheme shall not be charged any fees. The incubator or any of
                its staff members shall not charge any fee in cash or in kind
                from applicants or beneficiaries under the scheme for any
                process of selection, disbursement, incubation, or monitoring.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-78"
                  className={
                    activeIndex == "78"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  How often do incubators have to interact with selected
                  startups?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "78" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-78"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-78"
                      className="hide-icon pull-right"
                      id="show-78"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-78"
                      onClick={() => changeActive("78")}
                      data-toggle="collapse"
                      data-target="#question-78"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-78"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-78">
                Each startup will be required to touch base with the incubator
                team and share updates with them at least once in 15 days via
                videoconferences or physical meetings. These updates should be
                shared on the scheme dashboard at least on a monthly basis.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-79"
                  className={
                    activeIndex == "79"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  What parameters does an incubator need to track for selected
                  startups?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "79" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-79"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-79"
                      className="hide-icon pull-right"
                      id="show-79"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-79"
                      onClick={() => changeActive("79")}
                      data-toggle="collapse"
                      data-target="#question-79"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-79"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-79">
                The following shall be tracked and recorded by incubators for
                all beneficiary startups:
                <div className="ordered-list wrapper faq-ordered-list">
                  <ol>
                    <li>Progress of proof of concept</li>
                    <li>Progress of prototype development</li>
                    <li>Progress of product development</li>
                    <li>Progress of field trials</li>
                    <li>Progress of market launch</li>
                    <li>Quantum of loan, angel or VC funding raised</li>
                    <li>Jobs created by startup</li>
                    <li>Turnover of startup</li>
                    <li>Any other appropriate parameter</li>
                  </ol>
                </div>
                Selected startups shall furnish details on above parameters to
                incubator in all progress reports. The incubator shall provide
                the above information real-time to Startup India through their
                online dashboards and shall present the same to EAC on a
                quarterly basis. Incubator shall also report return on
                investment for each Startup.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-80"
                  className={
                    activeIndex == "80"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  When can incubators ask DPIIT for the next installment of
                  funds?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "80" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-80"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-80"
                      className="hide-icon pull-right"
                      id="show-80"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-80"
                      onClick={() => changeActive("80")}
                      data-toggle="collapse"
                      data-target="#question-80"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-80"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-80">
                When the cash-in-hand of the incubator goes below 10% of the
                total commitment by EAC, the Incubator may request for the next
                installment, which shall be released to incubator within 30 days
                of submission of proof of achievement of milestones.
              </div>
            </div>
            {/* <div className='question-tab'><div className="row"><div id="q-81" className={activeIndex=="81"?"para-text question-heading-orange col-sm-11":"para-text question-heading col-sm-11"}>What documentation do incubators need to submit to receive the next installment of funds?</div><div className="col-sm-1">{activeIndex=="81"?<div  onClick={changeActiveBack} data-toggle="collapse" data-target="#question-81" role="button" aria-expanded="false" aria-controls="question-81" className="hide-icon pull-right" id="show-81"><img src={Minus} className="img-fluid" alt="SISFS Logo" /></div>:<div id="hide-81"  onClick={() => changeActive("81")} data-toggle="collapse" data-target="#question-81" role="button" aria-expanded="false" aria-controls="question-81" className="expand-icon pull-right"><img src={Plus} className="img-fluid" alt="SISFS Logo" /></div>}</div></div><div className="collapse para-text help-content" id="question-81">
                        Installments shall be released to incubators upon submission of proofs of achievement of milestones as decided by EAC.
                        </div></div> */}
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-82"
                  className={
                    activeIndex == "82"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  We have received a tranche of funds but have not been able to
                  select many startups. What happens to the unutilized funds?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "82" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-82"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-82"
                      className="hide-icon pull-right"
                      id="show-82"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-82"
                      onClick={() => changeActive("82")}
                      data-toggle="collapse"
                      data-target="#question-82"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-82"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-82">
                Rate of interest (as defined under GFR) on unutilized funds
                available with incubator shall be taken into account and
                adjusted at the time of next release. If the Incubator has not
                utilized at least 50% of the total commitment within the first 2
                years, then the Incubator will not be eligible for any further
                drawdowns. It will return all unutilized funds along with
                interest. In case of poor performance of any selected incubator,
                EAC may decide to discontinue seed fund support to the incubator
                and take further action as may be required.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-83"
                  className={
                    activeIndex == "83"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  How does an incubator utilize net returns from seed funding
                  into startups?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "83" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-83"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-83"
                      className="hide-icon pull-right"
                      id="show-83"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-83"
                      onClick={() => changeActive("83")}
                      data-toggle="collapse"
                      data-target="#question-83"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-83"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-83">
                Any net return received from beneficiary startup can be used for
                further funding in startups as per guidelines of this scheme
                (net returns shall include principal, interest, and profits). In
                case of no further funding of startups using this money for
                three years, this shall be returned to DPIIT.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-84"
                  className={
                    activeIndex == "84"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  What happens if a startup fails?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "84" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-84"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-84"
                      className="hide-icon pull-right"
                      id="show-84"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-84"
                      onClick={() => changeActive("84")}
                      data-toggle="collapse"
                      data-target="#question-84"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-84"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-84">
                It is understood that every startup cannot be successful.
              </div>
            </div>
            <div className="question-tab">
              <div className="row">
                <div
                  id="q-85"
                  className={
                    activeIndex == "85"
                      ? "para-text question-heading-orange col-sm-11"
                      : "para-text question-heading col-sm-11"
                  }
                >
                  Can an incubator reapply to the scheme if they successfully
                  disburse funds sanctioned to them?
                </div>
                <div className="col-sm-1">
                  {activeIndex == "85" ? (
                    <div
                      onClick={changeActiveBack}
                      data-toggle="collapse"
                      data-target="#question-85"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-85"
                      className="hide-icon pull-right"
                      id="show-85"
                    >
                      <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  ) : (
                    <div
                      id="hide-85"
                      onClick={() => changeActive("85")}
                      data-toggle="collapse"
                      data-target="#question-85"
                      role="button"
                      aria-expanded="false"
                      aria-controls="question-85"
                      className="expand-icon pull-right"
                    >
                      <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                    </div>
                  )}
                </div>
              </div>
              <div className="collapse para-text help-content" id="question-85">
                An incubator can reapply for funds under the Scheme when it has
                disbursed or committed its entire previously released grant.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Incubator;
