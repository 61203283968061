import { useEffect } from "react";
import { Question } from "../../../models/eac/QuestionModel";
import { IReviewStepProps } from "./ReviewStepWrapperComponent";

/** Renders question weight component */
function WeightageQuestionComponent(props: IReviewStepProps) {
  return (
    <div className="review-overview-wrapper">
      <div className="review-question">
        <h5>{props.data?.desc}</h5>
        <span>
          Weightage of Criteria: <b>{props.data?.weightage}%</b>
        </span>
      </div>
      <div className="review-score">
        <span>Weighted Score</span>
        <span className="score-value">
          {isNaN(
            props.data?.question
              .map((quest: Question) =>
                isNaN(parseFloat(quest.weighted_score))
                  ? 0
                  : quest.weighted_score
              )
              .reduce((a: any, b: any) => parseFloat(a) + parseFloat(b), 0)
          )
            ? "0.0"
            : props.data?.question
                .map((quest: Question) =>
                  isNaN(parseFloat(quest.weighted_score))
                    ? 0
                    : quest.weighted_score
                )
                .reduce((a: any, b: any) => parseFloat(a) + parseFloat(b), 0)
                ?.toFixed(2)}
        </span>
      </div>
    </div>
  );
}

export default WeightageQuestionComponent;
