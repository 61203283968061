import React from "react";

interface IHeaderProps {
}

function StepperHeaderReviewComponent(props: IHeaderProps) {
    return (
        <div className="bs-stepper-header" role="tablist">
            <div className="step" data-target="#review-step-1">
                <button
                    type="button"
                    className="step-trigger"
                    role="tab"
                    id="stepper1trigger1"
                    aria-controls="review-step1"
                >
                    <span className="bs-stepper-circle"></span>
                </button>
                <div className="bs-stepper-line"></div>
                <span className="bs-stepper-label">Incubation Presentation</span>
                {/* <span className="bs-stepper-label">Incubation Support</span> */}
            </div>

            <div className="step" data-target="#review-step-2">
                <button
                    type="button"
                    className="step-trigger"
                    role="tab"
                    id="stepper1trigger2"
                    aria-controls="review-step2"
                >
                    <span className="bs-stepper-circle"></span>
                </button>
                <div className="bs-stepper-line"></div>
                <span className="bs-stepper-label">Incubation Support</span>
                {/* <span className="bs-stepper-label">Funding Support</span> */}
            </div>

            <div className="step" data-target="#review-step-3">
                <button
                    type="button"
                    className="step-trigger"
                    role="tab"
                    id="stepper1trigger3"
                    aria-controls="review-step3"
                >
                    <span className="bs-stepper-circle"></span>
                </button>
                <div className="bs-stepper-line"></div>
                <span className="bs-stepper-label">Funding Support</span>
                {/* <span className="bs-stepper-label">Composition of ISMC</span> */}
            </div>

            <div className="step" data-target="#review-step-4">
                <button
                    type="button"
                    className="step-trigger"
                    role="tab"
                    id="stepper1trigger4"
                    aria-controls="review-step4"
                >
                    <span className="bs-stepper-circle"></span>
                </button>
                <div className="bs-stepper-line"></div>
                <span className="bs-stepper-label">Composition of ISMC</span>
                {/* <span className="bs-stepper-label">Incubator's Team</span> */}
            </div>

            <div className="step" data-target="#review-step-5">
                <button
                    type="button"
                    className="step-trigger"
                    role="tab"
                    id="stepper1trigger5"
                    aria-controls="review-step5"
                >
                    <span className="bs-stepper-circle"></span>
                </button>
                <div className="bs-stepper-line"></div>
                <span className="bs-stepper-label">Incubator's Team</span>
                {/* <span className="bs-stepper-label">Infrastructure</span> */}
            </div>

            <div className="step" data-target="#review-step-6">
                <button
                    type="button"
                    className="step-trigger"
                    role="tab"
                    id="stepper1trigger6"
                    aria-controls="review-step6"
                >
                    <span className="bs-stepper-circle"></span>
                </button>
                <div className="bs-stepper-line"></div>
                <span className="bs-stepper-label">Infrastructure</span>
                {/* <span className="bs-stepper-label">Fund Deployment Plan</span> */}
            </div>

            <div className="step" data-target="#review-step-7">
                <button
                    type="button"
                    className="step-trigger"
                    role="tab"
                    id="stepper1trigger7"
                    aria-controls="review-step7"
                >
                    <span className="bs-stepper-circle"></span>
                </button>
                <div className="bs-stepper-line"></div>
                <span className="bs-stepper-label">Fund Deployment Plan</span>
                {/* <span className="bs-stepper-label">Other Support to Startups</span> */}
            </div>

            <div className="step" data-target="#review-step-8">
                <button
                    type="button"
                    className="step-trigger"
                    role="tab"
                    id="stepper1trigger8"
                    aria-controls="review-step8"
                >
                    <span className="bs-stepper-circle"></span>
                </button>
                <div className="bs-stepper-line"></div>
                <span className="bs-stepper-label">Other Support to Startups</span>
                {/* <span className="bs-stepper-label">Mentoring</span> */}
            </div>

            <div className="step" data-target="#review-step-9">
                <button
                    type="button"
                    className="step-trigger"
                    role="tab"
                    id="stepper1trigger9"
                    aria-controls="review-step9"
                >
                    <span className="bs-stepper-circle"></span>
                </button>
                <span className="bs-stepper-label">Mentoring</span>
            </div>
        </div>
    );
}

export default StepperHeaderReviewComponent;
