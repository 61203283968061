import { useEffect, useState } from "react";
import {
    GET_APPLICATION_LIST,
    GET_APPLICATION_VIEW,
    REPLACE_FILE,
    DELETE_APPLICATION,
    EVALUATION_FILE,
    STARTUP_ALL_APPLICATIONS,
    GET_GRIEVANCE_LIST,
    CONTACT_LIST,
    GRIEVANCE_DETAILS,
    ADD_COMMENT,
    EDIT_STATUS,
    File_node_url
} from "../../../constants/api";
import { get, post } from "../../../effects/api/api.common";
// import ContactSectionComponent from "./ContactSectionComponent";
// import GetApplicationInfoComponent from "./GetApplicationInfoComponenet";
import { useHistory, Link } from "react-router-dom";
import search from '../../../images/search.svg';
import { showEllipsisText } from "../../../utils/CommonMethods";
import { FormatDate } from "../../../utils/DateFormat";
import { getSessionStorageValue } from "../../../effects/utils/session-storage";
import moment from "moment";
// import { FormatDate } from "../../../../utils/DateFormat";
import { downloadDocument } from "../../../utils/CommonMethods";
/** Render Application Status for Incubator screen */
function GrievanceDetailComponent(props: any) {
    const history = useHistory();
    const [grievanceID, setGrievanceID] = useState("");
    const [closedIssueFlag, setClossedIssueFlag] = useState(true);
    const [loggedInRole, setLoggedInRole] = useState("");
    const [loader, setLoader] = useState(false);
    const [showEditStatus, setShowEditStatus] = useState(false);
    const [changedGrievanceStatus, setChangedGrievanceStatus] = useState('');
    const [grievanceDetails, setGrievanceDetails] = useState({
        id: '',
        entity_type: "",
        name_of_entity: "",
        name_of_user: "",
        email_id: "",
        contact_number: "",
        grievance_type: "",
        grievance_against: "",
        grievance_description: "",
        user_id: "",
        user_name: "",
        uer_role: "",
        file: "",
        created_date: "",
        status: "",
        created_by: "",
        state_name: "",
        city_name: ""
    });
    const [commentsList, setCommentsList] = useState([
        {
            "id": '',
            "grievance_data_id": '',
            "comments": "",
            "created_at": "",
            "created_by": '',
            "user_name": "",
            "user_role": ""
        }]);
    //     {
    //         "id": 8,
    //         "grievance_data_id": 6,
    //         "comments": "testing test",
    //         "created_at": "2021-05-13T16:49:00.000000Z",
    //         "created_by": 22,
    //         "user_name": "Second Incubator",
    //         "user_role": "dpiit"
    //     },
    //     {
    //         "id": 9,
    //         "grievance_data_id": 6,
    //         "comments": "hello",
    //         "created_at": "2021-05-13T16:49:00.000000Z",
    //         "created_by": 22,
    //         "user_name": "Second Incubator",
    //         "user_role": "dpiit"
    //     }, {
    //         "id": 10,
    //         "grievance_data_id": 6,
    //         "comments": "Hi",
    //         "created_at": "2021-05-13T16:49:00.000000Z",
    //         "created_by": 22,
    //         "user_name": "Second Incubator",
    //         "user_role": "dpiit"
    //     }
    // ]);
    const [commentString, setCommentString] = useState('');
    const [closeIssues, setCloseIssues] = useState([]);
    const [showbutton, setShowButton] = useState(true);
    const [routeIncubator, setRouteIncubator] = useState(true)



    useEffect(() => {
        window.scrollTo(0, 0);
        if ((typeof props.location.state !== 'undefined') && (props.location.state != null)) {
            if (props.location.state.grievance_id != 0 && props.location.state.logged_in_role) {
                getGrievanceDetails(props.location.state.grievance_id);
                setGrievanceID(props.location.state.grievance_id)
                setLoggedInRole(props.location.state.logged_in_role)
                setClossedIssueFlag(props.location.state.isClosed)
            }
        }
    }, []);


    const getGrievanceDetails = (id: any) => {
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user);
        var payload = {
            "id": id,
            "roles":userInfo.roles
        }
        post(GRIEVANCE_DETAILS, payload).then((response: any) => {
            if (!response || !response.data) return;
            console.log(response.data,"data@@@@")
            setGrievanceDetails(response.data.data)
            setCommentsList(response.data.comment)
            if(response.data.data.status=='closed'){
                setClossedIssueFlag(true);
            }
            else if(response.data.data.status!='closed'){
                setClossedIssueFlag(false);
            }

        });
    };

    const addComment = () => {
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user);
        
        const payload = {
            grievance_id: grievanceID,
            comments: commentString,
            "roles":userInfo.roles,
            "logged_user_id":userInfo.id
        }
        if(commentString == ''){
            return;
        }
        setLoader(true);
        post(ADD_COMMENT, payload).then((response: any) => {
            if (!response || !response.data) return;
            setLoader(false);
            if (response.data.result == "success") {
                getGrievanceDetails(grievanceID);
                setCommentString("");
            }

        });
    };
    const getFileDetails = (file: any, key: string) => {
        let file_name = '';
        let file_ext = '';
        file_name =
          file != undefined && file.length >1
            ? file.substr(file.lastIndexOf('/') + 1, file.length)
            : '';
            file_ext =  file != undefined && file.length >1 ?( file.substr(
              file.lastIndexOf('.') + 1,
              file.length )
            ):"";
            if (key == 'ext') return file_ext;
        if (key == 'name') return file_name;
        if (key == 'file') return file.toString();
      };

    const changeStatus = () => {
        if (changedGrievanceStatus == '') {
            setShowEditStatus(false);
            return;
        }
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user);
        const payload = {
            id: grievanceID,
            status: changedGrievanceStatus,
            "roles":userInfo.roles
        }
        post(EDIT_STATUS, payload).then((response: any) => {
            if (!response || !response.data) return;
            if (response.data.result == "success") {
                getGrievanceDetails(grievanceID);
                setShowEditStatus(false);
            }
        });
        
    };

    return (
        
        <>
        {loader &&
        <div className="spinner-border custom-spinner" role="status">
          <span className="sr-only">Loading...</span>
        </div>}
            <fieldset disabled={loader}>
            <div className="app-inside grievance-chat-wrapper">
                <div className="container">
                    <div className="breadcrumb-wrapper">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">{loggedInRole=="dpiit"&&<Link to='dpiit_grievances'>Grievance</Link>}{loggedInRole=="incubator"&&<Link to='incubator_grievances'>Grievance</Link>}{loggedInRole=="startup"&&<Link to='startup_grievances'>Grievance</Link>}{loggedInRole=="secretariat"&&<Link to='secretariat_grievances'>Grievance</Link>}</li>
                                <li className="breadcrumb-item active" aria-current="page">{grievanceDetails.user_name}</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="content-section">
                        <div className="row">
                            <div className="col-4 left-section">
                                <div className="grey-box">
                                    <div className="box-heading">
                                        <h4 className="box-main-heading">{grievanceDetails.user_name}</h4>
                                        <span className="box-main-subheading">{grievanceDetails.city_name + ', ' + grievanceDetails.state_name}</span>
                                    </div>
                                    <div className="box-content">
                                        <div className="content-list">
                                            <label className="label-heading d-block">Entity Type</label>
                                            <span className="content-value">{grievanceDetails.entity_type}</span>
                                        </div>
                                        <div className="content-list">
                                            <label className="label-heading d-block">Name</label>
                                            <span className="content-value">{grievanceDetails.name_of_entity}</span>
                                        </div>
                                        <div className="content-list">
                                            <label className="label-heading d-block">Email</label>
                                            <span className="content-value d-block">{grievanceDetails.email_id}</span>
                                            <a href={'mailto:'+grievanceDetails.email_id} className="content-link">Email</a>
                                        </div>
                                        <div className="content-list">
                                            <label className="label-heading d-block">Mobile No.</label>
                                            <span className="content-value">{grievanceDetails.contact_number}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="grey-box">
                                    <div className="box-content">
                                        <div className="content-list">
                                            <label className="label-heading d-block">Grievance Type</label>
                                            <span className="content-value">{grievanceDetails.grievance_type}</span>
                                        </div>
                                        <div className="content-list">
                                            <label className="label-heading d-block">Date</label>
                                            <span className="content-value">{FormatDate(new Date(grievanceDetails.created_date))}</span>
                                        </div>
                                        <div className="content-list">
                                            <label className="label-heading d-block">Grievance Status</label>
                                            <div className="row no-gutters">
                                                <div className="col-6">
                                                    <span 
                                                    className={grievanceDetails.status == 'open' ? "status-badge yellow-badge" :
                                                    grievanceDetails.status == 'Closed'? "status-badge green-badge" : 
                                                    "status-badge red-badge"} 
                                                    >
                                                        <b>{grievanceDetails.status}</b>
                                                    </span>
                                                </div>
                                                {loggedInRole=="dpiit"&& false&&<div className="col-6 text-left pl-2">
                                                    <span className="content-link send-link" onClick={() => setShowEditStatus(true)}>Edit</span>
                                                </div>}
                                            </div>

                                        </div>
                                        <div className="content-list">
                                            <label className="label-heading d-block">Uploaded Document</label>
                                            
                                                
                                                {
                                                    grievanceDetails.file != '' ?
                                                    <div className="row mt-3">
                                                    <div className="col-md-3">
                                                    <img src="/static/media/PDF.54e0cc8f.svg" alt="SISFS Logo" />
                                                    </div>
                                                        <div className="col-md-9">
                                                        {/* <span className="document-label">{grievanceDetails.file!=null?grievanceDetails.file.substr(grievanceDetails.file.lastIndexOf("/") + 1, grievanceDetails.file.length):'-'}</span> */}
                                                        <span className="document-label-value-wrap">
                                                        {  getFileDetails(
                                                            grievanceDetails.file,
                                                            'name'
                                                                        ) }
                                                            </span>
                                                        <br />
                                                        {/* {grievanceDetails.file!=null&&<a className="incubator-details-link" onClick={() => downloadDocument(grievanceDetails.file, grievanceDetails.file.substr(grievanceDetails.file.lastIndexOf("/") + 1, grievanceDetails.file.length))}>Download</a>} */}
                                                        <a
                                                        href={`${File_node_url}`+'grievance_files/' + grievanceDetails.file}
                                                        // href={`${File_node_url}` + grievanceDetails.file.substring(1)}
                                                            // href={grievanceDetails.file}
                                                            // href={`http://35.154.181.26:8585/fileStorage/grievance_files/GramaVolunter_PRRD.pdf`}
                                                            target="_blank"
                                                            //download="pan_card"
                                                            className="incubator-details-link"
                                                            >
                                                            View
                                                        </a>
                                                    </div>
                                                    </div> : <>
                                                            <br /><br />
                                                    </>
                                                }
                                                
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-8 right-section">
                                <div className="grievance-chat-wrapper">
                                    <div className="chat-heading">
                                        <h4 className="grievance-head">Grievance Message</h4>
                                        <span className="grievance-content">{grievanceDetails.grievance_description==null?"No Comments":grievanceDetails.grievance_description}</span>
                                    </div>
                                    <div className="chat-content">
                                        <div id="chat-content" >
                                            {loggedInRole == "dpiit" && commentsList.map((item: any, index:any) => (
                                                item.user_role != "dpiit" ? 
                                                // <div className="chat-list media-chat" key={index}>
                                                //     <div className="media-head d-block mb-2">
                                                //         <div className="row">
                                                //             <div className="col-6">

                                                //             </div>
                                                //             <div className="col-6">
                                                //                 {/* {moment(item.created_at).format("D MMM YYYY, h:mm a")} */}
                                                //                 <span className="meta">{FormatDate(new Date(moment(item.created_at).format('YYYY-MM-DD'))) + ', '}<time >{moment(item.created_at).format('hh:mm A')}</time></span>
                                                //             </div>
                                                //         </div>
                                                //     </div>
                                                //     <div className="media-body">
                                                //         <div className="row">
                                                //             <div className="col-12">
                                                //                 <p>{item.comments}</p>
                                                //             </div>
                                                //         </div>
                                                //     </div>
                                                // </div>
                                                <div className="chat-list media-chat-reverse" key={index}>
                                                <div className="media-head mb-2">
                                                    <div className="row">
                                                    <div className="col-6 text-left">
                                                        <span>
                                                        <b>{item.user_name}</b>
                                                        </span>
                                                    </div>
                                                    <div className="col-6 text-right">
                                                        <span className="meta">
                                                        {FormatDate(
                                                            new Date(
                                                            moment(item.created_at).format(
                                                                "YYYY-MM-DD"
                                                            )
                                                            )
                                                        ) + ", "}
                                                        <time>
                                                            {moment(item.created_at).format(
                                                            "hh:mm A"
                                                            )}
                                                        </time>
                                                        </span>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div className="media-body">
                                                    <p>{item.comments}</p>
                                                </div>
                                                </div>
                                                 : <div className="chat-list media-chat-reverse" key={index}>
                                                    <div className="media-head mb-2">
                                                        <div className="row">
                                                            <div className="col-6 text-left">
                                                                <span><b>{item.user_name}</b></span>
                                                            </div>
                                                            <div className="col-6 text-right">
                                                                <span className="meta">{FormatDate(new Date(moment(item.created_at).format('YYYY-MM-DD'))) + ', '}<time >{moment(item.created_at).format('hh:mm A')}</time></span>
                                                                {/* {moment(item.created_at).format("D MMM YYYY, h:mm a")} */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="media-body">
                                                        <p>{item.comments}</p>

                                                    </div>
                                                </div>

                                            ))}
                                            {loggedInRole == "secretariat" &&
                        commentsList.map((item: any, index:any) =>
                          item.user_role != "secretariat" ? (
                            // <div className="chat-list media-chat" key={index}>
                            //   <div className="media-head d-block mb-2">
                            //     <div className="row">
                            //       <div className="col-6"></div>
                            //       <div className="col-6">
                            //         <span className="meta">
                            //           {FormatDate(
                            //             new Date(
                            //               moment(item.created_at).format(
                            //                 "YYYY-MM-DD"
                            //               )
                            //             )
                            //           ) + ", "}
                            //           <time>
                            //             {moment(item.created_at).format(
                            //               "hh:mm A"
                            //             )}
                            //           </time>
                            //         </span>
                            //       </div>
                            //     </div>
                            //   </div>
                            //   <div className="media-body">
                            //     <div className="row">
                            //       <div className="col-12">
                            //         <p>{item.comments}</p>
                            //       </div>
                            //     </div>
                            //   </div>
                            // </div>

                            <div className="chat-list media-chat-reverse" key={index}>
                                <div className="media-head mb-2">
                                    <div className="row">
                                    <div className="col-6 text-left">
                                        <span>
                                        <b>{item.user_name}</b>
                                        </span>
                                    </div>
                                    <div className="col-6 text-right">
                                        <span className="meta">
                                        {FormatDate(
                                            new Date(
                                            moment(item.created_at).format(
                                                "YYYY-MM-DD"
                                            )
                                            )
                                        ) + ", "}
                                        <time>
                                            {moment(item.created_at).format(
                                            "hh:mm A"
                                            )}
                                        </time>
                                        </span>
                                    </div>
                                    </div>
                                </div>
                            <div className="media-body">
                                <p>{item.comments}</p>
                            </div>
                            </div>
                          ) : (
                            <div className="chat-list media-chat-reverse" key={index}>
                              <div className="media-head mb-2">
                                <div className="row">
                                  <div className="col-6 text-left">
                                    <span>
                                      <b>{item.user_name}</b>
                                    </span>
                                  </div>
                                  <div className="col-6 text-right">
                                    <span className="meta">
                                      {FormatDate(
                                        new Date(
                                          moment(item.created_at).format(
                                            "YYYY-MM-DD"
                                          )
                                        )
                                      ) + ", "}
                                      <time>
                                        {moment(item.created_at).format(
                                          "hh:mm A"
                                        )}
                                      </time>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="media-body">
                                <p>{item.comments}</p>
                              </div>
                            </div>
                          )
                        )}

                                            {loggedInRole == "incubator" && commentsList.map((item: any, index:any) => (
                                                item.user_role == "incubator" ? <div className="chat-list media-chat" key={index}>
                                                    <div className="media-head d-block mb-2">
                                                        <div className="row">
                                                            <div className="col-6">

                                                            </div>
                                                            <div className="col-6">
                                                                <span className="meta">{FormatDate(new Date(moment(item.created_at).format('YYYY-MM-DD'))) + ', '}<time >{moment(item.created_at).format('hh:mm A')}</time></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="media-body">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <p>{item.comments}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> : <div className="chat-list media-chat-reverse" key={index}>
                                                    <div className="media-head mb-2">
                                                        <div className="row">
                                                            <div className="col-6 text-left">
                                                                <span><b>{item.user_name}</b></span>
                                                            </div>
                                                            <div className="col-6 text-right">
                                                                <span className="meta">{FormatDate(new Date(moment(item.created_at).format('YYYY-MM-DD'))) + ', '}<time >{moment(item.created_at).format('hh:mm A')}</time></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="media-body">
                                                        <p>{item.comments}</p>

                                                    </div>
                                                </div>

                                            ))}

                                            {loggedInRole == "startup" && commentsList.map((item: any, index:any) => (
                                                item.user_role == "startup" ? 
                                                // <div className="chat-list media-chat" key={index}>
                                                //     <div className="media-head d-block mb-2">
                                                //         <div className="row">
                                                //             <div className="col-6">

                                                //             </div>
                                                //             <div className="col-6">
                                                //                 <span className="meta">{FormatDate(new Date(moment(item.created_at).format('YYYY-MM-DD'))) + ', '}<time >{moment(item.created_at).format('hh:mm A')}</time></span>
                                                //             </div>
                                                //         </div>
                                                //     </div>
                                                //     <div className="media-body">
                                                //         <div className="row">
                                                //             <div className="col-12">
                                                //                 <p>{item.comments}</p>
                                                //             </div>
                                                //         </div>
                                                //     </div>
                                                // </div> 
                                                <div className="chat-list media-chat-reverse" key={index}>
                                                    <div className="media-head mb-2">
                                                        <div className="row">
                                                        <div className="col-6 text-left">
                                                            <span>
                                                            <b>{item.user_name}</b>
                                                            </span>
                                                        </div>
                                                        <div className="col-6 text-right">
                                                        {/* moment(formatTime(item.created_at)).format('hh:mm A') */}
                                                        <span className="meta">{FormatDate(new Date(moment(item.created_at).format('YYYY-MM-DD'))) + ', '}<time >{moment(item.created_at).format('hh:mm A')}</time></span>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div className="media-body">
                                                        <p>{item.comments}</p>
                                                    </div>
                                                    </div>
                                                : 
                                                <div className="chat-list media-chat-reverse" key={index}>
                                                    <div className="media-head mb-2">
                                                        <div className="row">
                                                            <div className="col-6 text-left">
                                                                <span><b>{item.user_name}</b></span>
                                                            </div>
                                                            <div className="col-6 text-right">
                                                                <span className="meta">{FormatDate(new Date(moment(item.created_at).format('YYYY-MM-DD'))) + ', '}<time >{moment(item.created_at).format('hh:mm A')}</time></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="media-body">
                                                        <p>{item.comments}</p>

                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                    </div>
                                    <div className="chat-action">
                                       {!closedIssueFlag&&<div className="row no-gutters">
                                            <div className="col-11">
                                                <textarea
                                                    value={commentString}
                                                    onChange={(e: any) => setCommentString(e.target.value)}
                                                    className="form-control"
                                                    placeholder="Enter"
                                                />
                                            </div>
                                            <div className="col-1 text-left pl-3 pt-2">
                                                <span className="content-link send-link" onClick={addComment}>Send</span>
                                            </div>
                                        </div>}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </fieldset>
            {showEditStatus && (
                <>
                    <div className="modal-backdrop fade show"></div>
                    <div
                        className="modal align-middle modal-sisfs d-block"
                        id="editGrievance"
                    >
                        <div className="modal-dialog modal-dialog-centered modal-md">

                            <div className="modal-content">
                                <div className="modal-header modal-header-bottom">
                                    <h4 className="pt-2 pl-4">Edit Grievance Status</h4>
                                    <button
                                        type="button"
                                        className="close pr-0"
                                        onClick={() => setShowEditStatus(false)}
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body text-center">


                                    <div className="select-custom">
                                        <select
                                            className="form-control input-height"
                                            value={changedGrievanceStatus}
                                            onChange={(e: any) => setChangedGrievanceStatus(e.target.value)}
                                        >
                                            <option value='pending'>
                                                Pending
                                    </option>
                                            <option value='open'>
                                                Open
                                    </option>
                                            <option value='Closed'>
                                                Closed
                                    </option>
                                        </select>
                                    </div>
                                    {/* <div className="modal-action mt-2">
                                        <button className="btn btn-sisfs btn-transparent-primary" onClick={() => setShowEditStatus(false)}>
                                            Cancel
                               </button>
                                        <button className="btn btn-sisfs btn-yellow-primary ml-2" onClick={() => changeStatus()}>
                                            Save
                               </button>
                                    </div> */}
                                </div>
                                <div className="modal-footer">
                                    <div className="row modal-row">
                                        <div className="col-md-12 text-right">
                                            <button className="btn btn-sisfs btn-transparent-primary" onClick={() => setShowEditStatus(false)}>
                                                Cancel
                               </button>
                                            <button className="btn btn-sisfs btn-yellow-primary ml-2" onClick={() => changeStatus()}>
                                                Save
                               </button>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default GrievanceDetailComponent;
function GRIEVANCE_COMMENT(GRIEVANCE_COMMENT: any, payload: { grievance_id: string; comments: string; }) {
    throw new Error("Function not implemented.");
}

