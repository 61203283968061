export enum StepperEnum {
  GeneralDetail = 0,
  IncubatorTeam = 1,
  IncubatorDetail = 2,
  // Authorized = 2,
  FundUtilization = 3,
  OtherDetail = 4,
}

/** Fiile Upload types */
export enum UploadTypeEnum {
  ProofAssistance = 'proof_assistance',
  AnnualReport = 'annual_report',
  RelaventDocumentExcel = 'relavent_document_excel',
  ReleventDocumentPitchDeck = 'relavent_document_pitchdeck',
  RelaventDocumentOther = 'relavent_document_other',
  IncorporationCertificate = 'incorporation_certificate',
  TeamMemberCV = 'team_members_cv',
  FacilitiesPictures = 'facilities_pictures',
  QuarterlyReport = 'quarterly_reports',
  SupportingDocuments = 'supporting_documents',
  ProofDisbursement = 'proof_of_disbursement',
  PAN = 'pan_card',
  CancelledCheque = 'cancelled_cheque',
  traAgreement= 'tra_agreement_file',
  sanctionOrder = 'sanction_order',
  Darpan = 'darpan',
  PanCardUpload = 'pan_card_upload',
  AuthorisationLetterUpload = 'authorisation_letter_upload',
  AdhaarCardUpload = 'adhaar_card_upload',
  StartupIncubationUpload = 'startup_incubation_upload',
  SelfDeclarationUpload = 'self_declaration_upload',
}

/** Fiile Upload types */
export enum UploadTypeEnum1 {
  // ListSignificant = 'List_significant_facilities_upload',
  // ListSignificantSlice='List_significant_facilities_upload_slice',
  // employeesupload = 'No_full_time_employees_upload',
  // excelupload = "Re_Apply_relavant_excel",
  pitchDocumentUpload = "Re_apply_pitchdeck_upload",
  performanceUpload = "Performance_Upload",
  otherDocumentUpload = "Other_document_upload",

}
export enum UploadTypeEnum3 {

  CurrentIncubator = 'Current_Incubator_Team_Upload'


}
export enum UploadTypeEnum2 {
  UtilizationCertificate = 'Utilization_Certificate_Upload',
  AuditedStatement = 'Audited_statement_expenditure_Upload',
  // DeclarationViolation = 'Declaration_violation_SISFS_Upload',
}

export enum UploadTypeEnum4 {
  LogoImage = 'Logo_image',
  CoverImage = 'Cover_image',
  GalleryPictures = 'Gallery_pictures'
}

export enum SubsequentEnum {
  Utilization = 'Utilization_upload',
  AuditedUpload = 'Audited_upload',
  InterestDetails = 'InterestDetails_upload',
  IsmcUpload = 'Ismc_upload',
  ProofOfAchievement = 'ProofOfAchieve_upload',
  ConvertibleDebenture = 'Convertible_debenture',
  GrantDisbursed = 'GrantDisbursed_upload',
  Declaration = 'Declaration_upload',
  LegalAgreement = 'LegalAgreement_upload',
  ProofOfPFMS = 'ProofOfPFMS_upload',
  PreviousSanction = 'PreviousSanction_upload',
  TRAagrement = 'TRAagrement_upload'
}

export enum requestType {
  propertyChange = 0,
  save = 1,
  submit = 2
}

export enum StepperStartupEnum {
  EntityDetail = 0,
  StartupDetail = 1,
  AuthorizedRepresentative = 2,
  StartupTeam = 3,
  FundingDetails = 4,
  IncubatorPreference = 5,
  UploadDocuments = 6
}

/** File Upload types for Startups */
export enum StartupUploadTypeEnum {
  UploadPitchDeck = 'upload_pitch_deck',
  UploadVideoForProduct = 'upload_video_for_product',
  UploadRelaventDocumentOther = 'upload_relavent_document_other',
  UploadLegalAgreement = 'authorisation_letter_upload',
  PromotersAadhar = "promoters_aadhar_cards"
}
export enum ProgressOverviewe {
  SupportinDoc = 'supporting_doc'
}
export enum REUploadTypeEnum {
  ProofAssistance = 'proof_assistance',
  AnnualReport = 'annual_report',
  RelaventDocumentExcel = 'relavent_document_excel',
  ReleventDocumentPitchDeck = 'relavent_document_pitchdeck',
  RelaventDocumentOther = 'relavent_document_other',
  IncorporationCertificate = 'incorporation_certificate',
  TeamMemberCV = 'team_members_cv',
  FacilitiesPictures = 'facilities_pictures',
  QuarterlyReport = 'quarterly_reports',
  SupportingDocuments = 'supporting_documents',
  ProofDisbursement = 'proof_of_disbursement',
  PAN = 'pan_card',
  CancelledCheque = 'cancelled_cheque',
  PanCardUpload = 'pan_card_upload',
  AuthorisationLetterUpload = 'authorisation_letter_upload',
  AdhaarCardUpload = 'adhaar_card_upload',
  StartupIncubationUpload = 'startup_incubation_upload',
  SelfDeclarationUpload = 'self_declaration_upload',
}