import FileUploader from '@amodv/react-file-uploader';
import React, { useEffect, useState } from 'react';
import { CANCEL_DATE_UPDATE, CANCEL_DATE_UPDATE_INCUBATOR, CANCEL_STATUS_UPDATE, CANCEL_STATUS_UPDATE_StARTUP, UPDATE_FILE_CANCELLATION, UPDATE_FILE_CANCELLATION_INCUBATOR } from '../../../constants/api';
import { post } from '../../../effects/api/api.common';
import { changeNumberFormat, downloadDocument } from '../../../utils/CommonMethods';
import { FormatDate } from '../../../utils/DateFormat';
import Calendar from '../../common/controls/Calendar';
import ConfirmationDialog from '../../common/controls/ConfirmationDialog';
import '../incubator/incubator.css';
import { ApplicationGeneralDetails } from './ApplicationGeneralDetails';
import { getSessionStorageValue } from '../../../effects/utils/session-storage';

interface IContactProps {
    data: Array<any>;
    role: string;
    isEdit:boolean
}
interface IIKeyValueIndexer {
    [key: string]: boolean;
}


function CancellationReapplyContainer(props: IContactProps) {
    const { data, role,isEdit } = props;
    const [isDisabled, setDisabled] = useState(false);
    const [quickEditCancellation, setquickEditCancellation] = useState({
        id: data[0].id,
        comment: data[0].dpiitComment,
        status_id: "2"
    }
    );
    const user:any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    const [dateUpdate, setDateUpdate] = useState({
        id: data[0].id,
        date: data[0].fund_received_date,
    }
    );
    const [showIncubatorSuccessModal, setShowIncubatorSuccessModal] = useState(false);
    const [showIncubatorFailureModal, setShowIncubatorFailureModal] = useState(false);
    const [uploadFile, setUploadFile] = useState<Array<File>>([]);
    const [openUploadBlock, setOpenUploadBlock] = useState<IIKeyValueIndexer>();
    const [failDialogData, setFailDialogData] = useState({
        "header": '',
        "text": ''
    });
    const [dialogData, setDialogData] = useState({
        "header": '',
        "text": ''
    });

    useEffect(() => {
        if(role=="incubator"){
            setquickEditCancellation(prevState => ({
                ...prevState,
                comment: data[0].incubatorComment
            }))
        }
      }, []);
    //console.log(generalDetailsModel, 'IContactProps');
    // on change time;
    const updateCancellationStatus = () => {
        setDisabled(true);

        let payload = Object.assign({"user_id":userInfo.id,"roles":userInfo.roles}, quickEditCancellation)

        if (role == "dpiit") {
            post(CANCEL_STATUS_UPDATE, payload).then((response: any) => {
                if (!response || !response.data) return;
                setDisabled(false)
                if (response.data.result == "success") {
                    setDialogData({
                        "header": "Thank You!",
                        "text": response.data.message
                    })
                    setShowIncubatorSuccessModal(true)
                }
                else {
                    setFailDialogData({
                        "header": "Oops !!",
                        "text": response.data.message
                    })
                    setShowIncubatorFailureModal(true);
                }
            })
        }
        else if (role == "incubator") {
            post(CANCEL_STATUS_UPDATE_StARTUP, quickEditCancellation).then((response: any) => {
                if (!response || !response.data) return;
                setDisabled(false)
                if (response.data.result == "success") {
                    setDialogData({
                        "header": "Thank You!",
                        "text": response.data.message
                    })
                    setShowIncubatorSuccessModal(true)
                }
                else {
                    setFailDialogData({
                        "header": "Oops !!",
                        "text": response.data.message
                    })
                    setShowIncubatorFailureModal(true);
                }
            })
        }
    };

    const updateDateCall = () => {
        setDisabled(true);
        if (role == "dpiit") {

            let Payload = Object.assign({"roles":userInfo.roles,"user_id":userInfo.id},dateUpdate)

            post(CANCEL_DATE_UPDATE, Payload).then((response: any) => {
                if (!response || !response.data) return;
                setDisabled(false)
                if (response.data.result == "success") {
                    setDialogData({
                        "header": "Thank You!",
                        "text": response.data.message
                    })
                    setShowIncubatorSuccessModal(true)
                }
                else {
                    setFailDialogData({
                        "header": "Oops !!",
                        "text": response.data.message
                    })
                    setShowIncubatorFailureModal(true);
                }
            })
        }
        else if (role == "incubator") {
            let Payload = Object.assign({"user_role":userInfo.roles,"logged_user_id":userInfo.id},dateUpdate)
            post(CANCEL_DATE_UPDATE_INCUBATOR, Payload).then((response: any) => {
                if (!response || !response.data) return;
                setDisabled(false)
                if (response.data.result == "success") {
                    setDialogData({
                        "header": "Thank You!",
                        "text": response.data.message
                    })
                    setShowIncubatorSuccessModal(true)
                }
                else {
                    setFailDialogData({
                        "header": "Oops !!",
                        "text": response.data.message
                    })
                    setShowIncubatorFailureModal(true);
                }
            })
        }
    };

    const onFileUploadComplete = (files: Array<File>, type: string) => {
        // console.log("testing", files)
        let indexes: Array<string> = [];
        for (const file of files) {



            setUploadFile([...uploadFile, file]);


        }

        if (indexes.length) {
            indexes.map((ind) => {
                const position: number = files.findIndex((file) => file.name === ind);
                files.splice(position, 1);
            });
        }
    };

    const onFileDelete = (type: string) => {
        setUploadFile([]);
    };

    // function to format date
    function formatDate(date: Date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const uploadFileCall = () => {
        const formData = new FormData();
        formData.append("id", data[0].id);
        formData.append('logged_user_id', userInfo.id);
        formData.append('user_role', userInfo.roles);
        uploadFile.length != 0 && formData.append("file", uploadFile[0]);
        if (role == "dpiit") {
            post(UPDATE_FILE_CANCELLATION, formData).then((response: any) => {
                if (!response || !response.data) return;
                setDisabled(false)
                if (response.data.result == "success") {
                    setDialogData({
                        "header": "Thank You!",
                        "text": response.data.message
                    })
                    setShowIncubatorSuccessModal(true)
                }
                else {
                    setFailDialogData({
                        "header": "Oops !!",
                        "text": response.data.message
                    })
                    setShowIncubatorFailureModal(true);
                }
            })
        }
        else if (role == "incubator") {
            post(UPDATE_FILE_CANCELLATION_INCUBATOR, formData).then((response: any) => {
                if (!response || !response.data) return;
                setDisabled(false)
                if (response.data.result == "success") {
                    setDialogData({
                        "header": "Thank You!",
                        "text": response.data.message
                    })
                    setShowIncubatorSuccessModal(true)
                }
                else {
                    setFailDialogData({
                        "header": "Oops !!",
                        "text": response.data.message
                    })
                    setShowIncubatorFailureModal(true);
                }
            })
        }
    };

    const onFileUpdate = (e: any) => {
        const fileData = e.target.files[0];
        let formData = new FormData();
        formData.append('file', fileData);
        formData.append("id", data[0].id);
        formData.append('logged_user_id', userInfo.id);
        formData.append('user_role', userInfo.roles);
        if (role == "dpiit") {
            post(UPDATE_FILE_CANCELLATION, formData).then((response: any) => {
                if (!response || !response.data) return;
                setDisabled(false)
                if (response.data.result == "success") {
                    setDialogData({
                        "header": "Thank You!",
                        "text": response.data.message
                    })
                    setShowIncubatorSuccessModal(true)
                }
                else {
                    setFailDialogData({
                        "header": "Oops !!",
                        "text": response.data.message
                    })
                    setShowIncubatorFailureModal(true);
                }
            })
        }
        else if (role == "incubator") {
            post(UPDATE_FILE_CANCELLATION_INCUBATOR, formData).then((response: any) => {
                if (!response || !response.data) return;
                setDisabled(false)
                if (response.data.result == "success") {
                    setDialogData({
                        "header": "Thank You!",
                        "text": response.data.message
                    })
                    setShowIncubatorSuccessModal(true)
                }
                else {
                    setFailDialogData({
                        "header": "Oops !!",
                        "text": response.data.message
                    })
                    setShowIncubatorFailureModal(true);
                }
            })
        }
    }

    return (
        <>
            <div className="accordion accordion-details-wrap text-left" id="accordion-application-details">
                <div className="card accordion-card">
                    <div className="card-body accordion-card-body">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="startup-info-group">
                                    <div className="startup-info-group-label">
                                        Date Requested
                                        </div>
                                    <div className="startup-info-group-value">
                                        {FormatDate(new Date(data[0].request_date))}
                                    </div>
                                </div>

                                <div className="startup-info-group">
                                    <div className="startup-info-group-label">
                                        Status of Cancellation {isEdit&&<a
                                            className="incubator-details-link ml-2"
                                            data-toggle="modal"
                                            data-target="#quickEditCancel"
                                        >
                                            Update
              </a>}
                                    </div>
                                    <div className="startup-info-group-value">
                                        {data[0].status_id == 1 && "Cancellation Requested"}
                                        {data[0].status_id == 2 && "Cancellation Request Approved"}
                                        {data[0].status_id == 3 && "Cancellation Request Rejected"}

                                    </div>
                                </div>


                            </div>

                            <div className="col-lg-6">
                                <div className="startup-info-group">
                                    <div className="startup-info-group-label">
                                        Amount to be Refunded
                                            </div>
                                    <div className="startup-info-group-value">
                                        &#8377; {changeNumberFormat(Number(data[0].amount_refunded), 1, 0)}
                                    </div>
                                </div>

                                <div className="startup-info-group">
                                    <div className="startup-info-group-label">
                                        Interest Accumulated on Funds
                                            </div>
                                    <div className="startup-info-group-value">
                                        &#8377; {changeNumberFormat(Number(data[0].interest_funds), 1, 0)}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="startup-info-group">
                                    <div className="startup-info-group-label">
                                        Reason
                                            </div>
                                    {data[0].reason}
                                </div>
                                <div className="startup-info-group">
                                    <div className="startup-info-group-label">
                                        Audited Report For Bank Statement
                                            </div>
                                    <div className="startup-info-group-value">
                                        {data[0].file_path != null ?
                                            <div className="document-info document-pdf-icon">
                                                <div className="document-info-name">{data[0].file_path.substr(data[0].file_path.lastIndexOf("/") + 1, data[0].file_path.length)}</div>
                                                <a className="tab-pane-link tab-pane-link-view link-orange-text" href={data[0].file_path} target='_blank'>View  </a>
                                                {/* <a className="tab-pane-link tab-pane-link-view link-orange-text" onClick={() => downloadDocument(data[0].file_path, data[0].file_path.substr(data[0].file_path.lastIndexOf("/") + 1, data[0].file_path.length))}>Download</a> */}
                                            </div>
                                            : " - "
                                        }
                                    </div>
                                </div>
                                <div className="startup-info-group">
                                    <div className="startup-info-group-label">
                                        Supporting Documents
                                            </div>
                                    {data[0].file_path_other !== null ? <div className="startup-info-group-value">
                                        <div className="document-info document-pdf-icon">
                                            <div className="document-info-name">{data[0].file_path_other.substr(data[0].file_path_other.lastIndexOf("/") + 1, data[0].file_path_other.length)}</div>
                                            <a className="tab-pane-link tab-pane-link-view link-orange-text" href={data[0].file_path_other} target='_blank'>View  </a>
                                            {/* <a className="tab-pane-link tab-pane-link-view link-orange-text" onClick={() => downloadDocument(data[0].file_path_other, data[0].file_path_other.substr(data[0].file_path.lastIndexOf("/") + 1, data[0].file_path_other.length))}>Download</a> */}
                                        </div>
                                    </div> : "-"}
                                </div>



                            </div>
                            <div className="col-lg-6">
                                <div className="startup-info-group">
                                    <div className="startup-info-group-label">
                                        Date of Funds Received
                                            </div>
                                    <div className="startup-info-group-value">
                                        {data[0].fund_received_date != null ? FormatDate(new Date(data[0].fund_received_date)) : "-"}{isEdit&&<a
                                            className="incubator-details-link ml-2"
                                            data-toggle="modal"
                                            data-target="#dateUpdate"
                                        >
                                            Update
              </a>}
                                    </div>
                                </div>
                                <div className="startup-info-group">
                                    <div className="startup-info-group-label">
                                       {role=="dpiit"?"Proof of Funds received by DPIIT":"Proof of Funds received by Incubator"}
                                            </div>
                                    <div className="startup-info-group-value">
                                        {(data[0].proof_fund_path == null && isEdit) ? <div className="">
                                            {/* <input type="file" className="form-control-file btn btn-sisfs btn-transparent-primary" id="exampleFormControlFile1" /> */}
                                            <button
                                                className="btn btn-sisfs btn-transparent-primary"
                                                onClick={() => setOpenUploadBlock({ BankStatementUpload: true })}
                                                disabled={false}
                                            >
                                                Upload
                      </button>
                                            {(openUploadBlock && openUploadBlock["BankStatementUpload"]) && (
                                                <>
                                                    <span
                                                        className="close"
                                                        onClick={() => setOpenUploadBlock({ BankStatementUpload: false })}
                                                    >
                                                        <i className="fa fa-close"></i>
                                                    </span>
                                                    <FileUploader
                                                        key={"bank_statement_upload"}
                                                        id={"bank_statement_upload"}
                                                        information="Drag & Drop the file or Browse"
                                                        fileType="application/pdf"
                                                        buttonLabel="Browse"
                                                        multiple={false}
                                                        enablePreview
                                                        IconComponent={() => (
                                                            <span className="fa fa-cloud-upload"></span>
                                                        )}
                                                        removeIconClass=""
                                                        existingFiles={uploadFile}
                                                        onUploadFinish={(files: Array<File>) => {
                                                            onFileUploadComplete(files, "file");
                                                        }}
                                                        onFileDelete={(deletedFile: File) => {
                                                            onFileDelete("file")
                                                        }}
                                                    />
                                                    <small>Supported file format - PDF only</small>
                                                    <button
                                                        className="btn btn-sisfs btn-transparent-primary"
                                                        onClick={uploadFileCall}
                                                    >
                                                        Upload File
                      </button>

                                                </>
                                            )}

                                        </div> : <div className="startup-info-group-value">
                                            <div className="document-info document-pdf-icon">
                                                <div className="document-info-name">{data[0].proof_fund_path != null && data[0].proof_fund_path.substr(data[0].proof_fund_path.lastIndexOf("/") + 1, data[0].proof_fund_path.length)}</div>
                                                <a className="tab-pane-link tab-pane-link-view link-orange-text" href={data[0].proof_fund_path} target='_blank'>View </a>
                                                {isEdit&& <label htmlFor={"inputUpload_" + 1} className="tab-pane-link tab-pane-link-view link-orange-text"><input id={"inputUpload_" + 1} className="d-none" type="file" onChange={(e) =>
                                                    onFileUpdate(e)
                                                } />| Upload</label>}
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                                <div className="startup-info-group">
                                    <div className="startup-info-group-label">
                                        {role=="dpiit"?"Proof of Disbursement of Incubator":"Proof of Disbursement of Startup"}
                                            </div>


                                    <div className="startup-info-group-value">
                                        {data[0].proof_disbursement_path != null ? <div className="document-info document-pdf-icon">
                                            <div className="document-info-name">{data[0].proof_disbursement_path.substr(data[0].proof_disbursement_path.lastIndexOf("/") + 1, data[0].proof_disbursement_path.length)}</div>
                                            <a className="tab-pane-link tab-pane-link-view link-orange-text" href={data[0].proof_disbursement_path} target='_blank'>View </a>
                                            {/* <a className="tab-pane-link tab-pane-link-view link-orange-text" onClick={() => downloadDocument(data[0].proof_disbursement_path, data[0].proof_disbursement_path.substr(data[0].proof_disbursement_path.lastIndexOf("/") + 1, data[0].proof_disbursement_path.length))}>Download</a> */}
                                        </div> : "-"}
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="tab-pane-label-group cancellation-comment-tab">
                            <div className="tab-pane-label-feedback">
                                Comments/Feedback
                                                             </div>
                            {role == "dpiit" ? <div className="tab-pane-value-text mb-2">
                                {data[0].dpiitComment != "" ? data[0].dpiitComment : "-"}
                            </div> : <div className="tab-pane-value-text mb-2">
                                {data[0].incubatorComment != "" ? data[0].incubatorComment : "-"}
                            </div>}
                            {/* <a className="tab-pane-link">Add Comment</a> */}
                        </div>

                    </div>
                </div>
            </div>

            <div
                className="modal align-middle modal-sisfs fade"
                id="dateUpdate"
                role="dialog"
                aria-labelledby="exampleModalLabel1"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-md">

                    <div className="modal-content">
                        <div className="modal-header modal-header-bottom">
                            <h4 className="pt-2 pl-4">Edit Date of Fund Received</h4>
                            <button
                                type="button"
                                className="close pr-0"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body text-center">



                            <Calendar
                                id="registrationDate"
                                name="registrationDate"
                                className="form-control input-height"
                                format="dd/MM/yyyy"
                                selectedDate={dateUpdate.date != null ? new Date(dateUpdate.date) : new Date()}
                                onDateChange={(date: Date, field: string) => {
                                    setDateUpdate(prevState => ({
                                        ...prevState,
                                        date: formatDate(date)
                                    }))
                                }}
                                minDate={new Date()}
                            />

                            {/* <div className="modal-action mt-2">
                                        <button className="btn btn-sisfs btn-transparent-primary" onClick={() => setShowEditStatus(false)}>
                                            Cancel
                               </button>
                                        <button className="btn btn-sisfs btn-yellow-primary ml-2" onClick={() => changeStatus()}>
                                            Save
                               </button>
                                    </div> */}
                        </div>
                        <div className="modal-footer">
                            <div className="row modal-row">
                                <div className="col-md-12 text-right">
                                    <button className="btn btn-sisfs btn-transparent-primary" data-dismiss="modal" >
                                        Cancel
                               </button>
                                    <button className="btn btn-sisfs btn-yellow-primary ml-2" onClick={updateDateCall} >
                                        Save
                               </button>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* quick edit data for cancellation */}
            <div
                className="modal fade"
                id="quickEditCancel"
                role="dialog"
                aria-labelledby="exampleModalLabel1"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-slideout modal-md"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header modal-header-border-none modal-header-bottom pr-5">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"

                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body text-left pl-5 pr-5 pt-5">
                            <div className="popup-head mb-5 ml-2">
                                <div className="main-heading">
                                    {/* {quickEditPopupData.incubator_name} */}
                                </div>
                                <div className="count-text">

                                </div>
                            </div>

                            <label className="col-sm-8 col-form-head">
                                Cancellation Status
              </label>
                            <div className="row pl-3">
                                <div className="col-md-11 mb-5">
                                    <div className="select-custom">
                                        <select
                                            className="form-control input-height"
                                            aria-label="Select State"
                                            id="application_status"
                                            name="application_status"
                                            value={quickEditCancellation.status_id}
                                            onChange={(e: any) => setquickEditCancellation(prevState => ({
                                                ...prevState,
                                                status_id: e.target.value
                                            }))}
                                        >

                                            {/* <option value="1">Requested</option> */}
                                            <option value="2">Approved</option>
                                            <option value="3">Rejected</option>

                                        </select>
                                    </div>
                                </div>

                            </div>

                            <label className="col-sm-8 col-form-head">Comments, if any</label>
                            <div className="col-md-12 mb-4">
                                <textarea
                                    value={quickEditCancellation.comment}
                                    className="form-control textarea-height"
                                    name="application_comments"
                                    onChange={(e: any) => setquickEditCancellation(prevState => ({
                                        ...prevState,
                                        comment: e.target.value
                                    }))}
                                />
                            </div>
                            <div className="row pl-3">
                                <div className="col-md-6">
                                    <button
                                        className="btn btn-sisfs btn-transparent-primary full-width"
                                        data-dismiss="modal"
                                    >
                                        CANCEL
                  </button>
                                </div>
                                <div className="col-md-6">
                                    <button
                                        onClick={updateCancellationStatus}
                                        className={isDisabled ? "tertiary  btn-yellow-primary full-width custom-disabled" : "tertiary  btn-yellow-primary full-width"} disabled={isDisabled}
                                    >
                                        UPDATE
                  </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showIncubatorSuccessModal && (
                <ConfirmationDialog
                    headerText={dialogData.header}
                    bodyText={dialogData.text}
                    onDialogClose={() => (setShowIncubatorSuccessModal(false), window.location.reload())}
                />
            )}
            {showIncubatorFailureModal && (
                <ConfirmationDialog
                    headerText={failDialogData.header}
                    bodyText={failDialogData.text}
                    onDialogClose={() => setShowIncubatorFailureModal(false)}
                    iconClass="test"
                />
            )}
        </>
    );
}
export default CancellationReapplyContainer;
