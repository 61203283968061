import * as React from "react";
import "../../../styles/component.css";
import {
  GET_MEETING_DETAILS,
  PENDING_INCUBATOR_COUNT,
  MEETING_FILE_REMOVE,
  MEETING_FILE_UPLOAD,
  INCUBATOR_SEARCH,
  EAC_REVIEW_UPDATE,
  UPDATE_MEETING,
  GET_MEETINGS_BY_ID,
  SCHEDULE_MEETING_COUNT,
  GET_ALL_INCUBATORS,
  GET_Re_ALL_INCUBATORS,
  REMOVE_INCUBATOR,
  GET_DPIIT_DASHBOARD_CHART_COUNT,
  ADD_INCUBATOR,
  EAC_VIEW_SEARCH,
  REMOVE_INCUBATOR_NODE,
  File_node_url,
  REMOVE_MEETING
} from "../../../constants/api";
import { get, post } from "../../../effects/api/api.common";
import { useEffect, useRef, useState } from "react";
import Calendar from "../../common/controls/Calendar";
import download from "../../../images/download.svg";
import Remove from '../../../images/ReturnsIcons/trash-img.svg'
// images/ReturnsIcons/trash-bin.svg';
import search from "../../../images/search.svg";
import { compareTime, FormatDate } from "../../../utils/DateFormat";
import { formatTime } from "../../../utils/DateFormat";
import { useHistory } from "react-router-dom";
import edit from "../../../images/edit.svg";
import ConfirmationDialog from "../../common/controls/ConfirmationDialog";
import FileUploader from "@amodv/react-file-uploader";
import {
  changeNumberFormat,
  createFile,
  filterByValue,
  showEllipsisText,
} from "../../../utils/CommonMethods";
import { downloadDocument } from "../../../utils/CommonMethods";
import error from "../../../images/error.svg";
import moment from "moment";
import { getSessionStorageValue } from "../../../effects/utils/session-storage";
import ReactPaginate from 'react-paginate';
import '../../../styles/dashboard.css';
declare var $: any;

function EacReviewDpiit() {
  const history = useHistory();
  const [incubatorPending, setIncubatorPending] = useState(Number);
  const [isDisabled, setDisabled] = useState(false);
  const [upcomingMeetingData, setupcomingMeetingData] = useState([]);
  const [filteredUpcomingMeetingData, setFilteredUpcomingMeetingData] =
    useState([]);
  const [pastMeetingData, setPastMeetingData] = useState([]);
  const [getTotalEvaluated,setTotalEvaluated] = useState();
  const [getTotalSelected,setTotalSelected] = useState();
  const [getTotalFunds,setToatalFunds] = useState();
  const [loader, setLoader] = useState(false);
  const [filteredPastMeetingData, setFilteredPastMeetingData] = useState([]);
  const [incubatorDetails, setIncubatorDetails] = useState([]);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [modalDate, setModalDate] = useState();
  const [meetingPath, setMeetingPath] = useState("");
  const [openUploadBlock, setOpenUploadBlock] = useState(false);
  const [noDataFilter, setNoDataFilter] = useState("");
  const [meetingIdFilter, setMeetingIdFilter] = useState("");
  const [modalTime, setModalTime] = useState();
  const [meetingID, setMeetingID] = useState();
  const [userIdremove, setUserIdRemove] = useState();
  const [meetId, setMeetId] = useState<any>("");
  const [incAppId, setIncAppId] = useState();
  const [sourceId, setSourceId] = useState<any>([]);
  const [filterText, setFilterText] = useState("");
  const [showViewMore, setShowViewMore] = useState("");
  const [isEditable, setIsEditable] = useState(true);
  const [showIncubatorSuccessModal, setShowIncubatorSuccessModal] =
    useState(false);
  const [showMeetingSuccessModal, setShowMeetingSuccessModal] = useState(false);
  const [showIncubatorFailureModal, setShowIncubatorFailureModal] =
    useState(false);
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const [uploadedFile, setUploadedFile] = useState([]);

  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [showAddConfirmationBox, setShowAddConfirmationBox] = useState(false);
  const [failDialogData, setFailDialogData] = useState({
    header: "",
    text: "",
  });
  const [dialogData, setDialogData] = useState({
    header: "",
    text: "",
  });
  const [confirmationDialogData, setConfirmationDialogData] = useState({
    header: "",
    text: "",
  });

  const [scheduleDate, setScheduleDate] = useState("");
  const [selectedDateControl, setSelectedDateControl] = useState(new Date());
  const [loggedInRole, setLoggedInRole] = useState("");
  const [meetingFilter, setMeetingFilter] = useState("");
  const [meetingTab, setMeetingTab] = useState("upcoming");
  const [AddPayLoad, setAddPayLoad] = useState("");
  const [timeValidation, setTimeValidation] = useState(false);
  const [timeValidationCurrentTime, setTimeValidationCurrentTime] =
    useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [meetingLink, setMeetingLink] = useState("");
  const [meetingComment, setMeetingComment] = useState("");
  const [linkValidation, setLinkValidation] = useState(false);
  const [noDataStartupFilter, setNoDataStartupFilter] = useState("");
  const [incuabtorApplicationID, setIncubatorApplicationID] = useState("");
  const [meetingNumber,setMeetingNumber]=useState()
  const [count, setCount] = useState({
    Tomorrow: "",
    This_week: "",
    Next_week: "",
    Next_month: "",
    Next_2_months: "",
  });

  const [editCountMeeting, setEditCountMeeting] = useState({
    meeting_id: "",
    incubator_evaluated: "",
    incubator_selected: "",
    funds_approved: "",
    meeting_date: "",
    meeting_from_time: "",
    meeting_to_time: "",
    meeting_type: "",
    meeting_number:""
  });

  const [editMeeting, setEditMeeting] = useState({
    meeting_type: "",
    meeting_date: "",
    meeting_from_time: "",
    meeting_to_time: "",
    meeting_id: "",
    created_at: "",
    comment: "",
    link: "",
  });

  const [meetingType, setMeetingType] = useState("1");

  useEffect(() => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    setLoggedInRole(userInfo.roles);
    getMeetingDetails();

    // getMeetingCount();
    // getIncubatorCount();
  }, []);
  // get states in dropdown
  const getMeetingDetails = () => {
    setLoader(true)
    get(GET_MEETING_DETAILS).then((response: any) => {
      setLoader(false)
      if (!response || !response.data) return;
      // console.log(response.data.past,"data#####")
      setupcomingMeetingData(response.data.upcoming);
      setFilteredUpcomingMeetingData([]);
      
      let totalEvaluated = response.data.past.reduce((acc:any,cur:any)=> (acc + (cur.incubator_count ||0)),0);
      setTotalEvaluated(totalEvaluated);
      let totalSelected = response.data.past.reduce((acc:any,cur:any)=> (acc + (cur.selected_count ||0)),0);
      setTotalSelected(totalSelected);
      let totalFundsApproved = response.data.past.reduce((acc:any,cur:any)=> (acc + (cur.funds_approved ||0)),0);
      setToatalFunds(totalFundsApproved);

      setPastMeetingData(response.data.past);
      setFilteredPastMeetingData([]);
      getMeetingCount();
    });
  };
  const getIncubatorDetails = (ind: any, id: any, date: any, time: any) => {
    // console.log(id);
    setMeetingIdFilter(id);
    setIncubatorDetails([]);
    setFilteredData([]);

    setFilterText("")

    // if(ind==0){
    setLoader(true)
    get(`${GET_ALL_INCUBATORS}/${id}`).then((response: any) => {
      setLoader(false)
      if (!response || !response.data) return;
      //  console.log(response.data,"itemDetails");
      // console.log(response.data.meetingId)
      response.data.meetingId.forEach((element: any) => {
        setUserIdRemove(element.user_id);
        setMeetId(element.meeting_id);
      });
      //  console.log("@!@@@@@@@@!!!!!!", response.data.meetingId);
      if (typeof response.data.meetingId != "undefined") {
        //  console.log(response.data, "meetingv");
        setIncubatorDetails(response.data.meetingId);
      }
      setModalDate(date);
      setModalTime(time);
      setMeetingID(id);
    });
    // }
    // if(ind==1){
    //   console.log(`${GET_Re_ALL_INCUBATORS}/${id}`)
    //   get(`${GET_Re_ALL_INCUBATORS}/${id}`).then((response: any) => {
    //     if (!response || !response.data) return;
    //     console.log(response.data.meetingId);
    //     if (typeof response.data != "undefined") {
    //       console.log(response.data,"meetingv")
    //       setIncubatorDetails(response.data.meetingId);
    //       console.log(incubatorDetails)
    //     }
    //     setModalDate(date);
    //     setModalTime(time);
    //     setMeetingID(id);
    //   });
    // }
  };

  const applyIncubatorFilter = () => {
    const payload = {
      // meeting_id: meetingIdFilter,
      // search: filterText,
      search_name: filterText,
    };
    if (filterText == "") {
      return;
    }
    else {
      // console.log(filterText)
      post(EAC_VIEW_SEARCH, payload).then((response: any) => {
        if (!response || !response.data) return;
        //  console.log(response.data.Data);
        setFilteredData(response.data.Data);
        if (response.data.Data.length == 0) {
          setNoDataStartupFilter("test");
        }
      });
    }

  };

  const handleResetClick = () => {
    // setFilterText("")
    setLoader(false);
    $('#viewIncubator').modal('hide');

    // window.location.reload()
    // console.log(filterText)
  };
  const getMeetingCount = () => {
    get(SCHEDULE_MEETING_COUNT).then((response: any) => {

      if (!response || !response.data) return;
      setCount(response.data.Data);
      getIncubatorCount();
    });
  };

  const removeMeeting = (id: any) => {
    const payload = {
      meeting_id: id
    };
    console.log(payload);
    setLoader(true)
    post(REMOVE_MEETING, payload).then((response: any) => {
      setLoader(false)
      if (!response || !response.data) return;
      window.location.reload();
    });
  };
  const removeIncubator = (ind: any, id: any) => {
    // if(ind==0){
    // /${meetingID}/removeIncubator
    // post(`${REMOVE_INCUBATOR}`, {
    //   incubator_application_id: id,
    // }).then((response: any) => {
    //   console.log(response,"@@@@")
    //   if (!response || !response.data) return;
    // if (response.data.result == "success") {
    //   get(`${GET_ALL_INCUBATORS}?meeting_id=${meetingID}`).then(
    //     (response: any) => {
    //       console.log(response,"@@@@")
    //       if (!response || !response.data) return;
    //       setIncubatorDetails(response.data.result.data);
    //       getMeetingDetails();
    //     }
    //   );
    // }
    // });
    // }else{

    // }

    const payload = {
      user_id: id,
      meeting_id: ind,
    };
    console.log(payload);
    // return;
    setLoader(true);
    post(REMOVE_INCUBATOR_NODE, payload).then((response: any) => {
      setLoader(false);
      if (!response || !response.data) return;
      // console.log(response.data.data);
      // setFilteredData(response.data.data);
      // if (response.data.data.length == 0) {
      //   setNoDataStartupFilter("test");
      // }
      // window.location.reload();

      // getIncubatorDetails();
      window.location.reload();
    });

    //  console.log(meetingID)
    get(`${GET_ALL_INCUBATORS}/${meetingID}`).then((response: any) => {
      if (!response || !response.data) return;
      // console.log(response.data.meetingId);

      //  console.log("@!@@@@@@@@!!!!!!", response.data.meetingId);
      if (typeof response.data.meetingId != "undefined") {
        // console.log(response.data, "meetingv");
        setIncubatorDetails(response.data.meetingId);
      }
      // setModalDate(date);
      // setModalTime(time);
      setMeetingID(id);
    });


  };

  const addIncubator = (id: any, ind: any) => {
    // console.log(id);
    // console.log(filteredData);
    setIncubatorApplicationID(id);
    // /${meetingIdFilter}/addIncubator
    // post(`${REMOVE_INCUBATOR}`, {
    //   // meeting_id:meetingIdFilter,
    //   incubator_application_id: id,
    //   // incubator_user_id:userIdremove,
    //   warning_ignore: 0,
    // })
    // filteredData
    let getselectedData: any = filteredData.find(
      (person: any) => person.incubator_application_id === id
    );

    // console.log(getselectedData);

    const payload = {
      meeting_id: meetId,
      incubator_user_id: getselectedData.user_id,
      incubator_application_id: getselectedData.incubator_application_id,
      source_indicator: getselectedData.source_indicator,
    };
    // return;
    post(ADD_INCUBATOR, payload).then((response: any) => {
      if (!response || !response.data) return;
      if (response.data.result == "success") {
        setDialogData({
          header: "Thank You!",
          text: "Incubator Added Successfully!!!",
        });
        setShowIncubatorSuccessModal(true);
        setFilteredData([]);

        get(`${GET_ALL_INCUBATORS}/${id}`).then((response: any) => {
          if (!response || !response.data) return;
          //  console.log(response.data.meetingId);
          // console.log(response.data.meetingId)

          //  console.log("@!@@@@@@@@!!!!!!", response.data.meetingId);
          if (typeof response.data.meetingId != "undefined") {
            //   console.log(response.data, "meetingv");
            setIncubatorDetails(response.data.meetingId);
          }
          // setModalDate(date);
          // setModalTime(time);
          // setMeetingID(id);
        });
        // if(ind==0){
        //   get(`${GET_ALL_INCUBATORS}/${meetingIdFilter}`).then(
        //     (response: any) => {
        //       if (!response || !response.data) return;
        //       setIncubatorDetails(response.data.result.data);
        //       getMeetingDetails();
        //     }

        //   )
        // }else{
        //   get(`${GET_Re_ALL_INCUBATORS}/${meetingIdFilter}`).then(
        //     (response: any) => {
        //       if (!response || !response.data) return;
        //       setIncubatorDetails(response.data.meetingId);
        //       getMeetingDetails();
        //     }

        //   )
        // }
      } else if (response.data.result == "Warning") {
        setConfirmationDialogData({
          header: "Meeting Already Exist",
          text: response.data.message,
        });
        setShowAddConfirmationBox(true);
      } else {
        setFailDialogData({
          header: "Oops ! Something went wrong,",
          text: response.data.message,
        });
        setShowIncubatorFailureModal(true);
      }
    });
  };

  const finalAddIncubator = () => {
    post(`${REMOVE_INCUBATOR}/${meetingIdFilter}/addIncubator`, {
      incubator_application_id: incuabtorApplicationID,
      warning_ignore: 1,
    }).then((response: any) => {
      if (!response || !response.data) return;
      setShowAddConfirmationBox(false);
      if (response.result == "success") {
        setDialogData({
          header: "Thank You!",
          text: "Incubator Added Successfully!!!",
        });
        setShowIncubatorSuccessModal(true);

        setFilteredData([]);
        get(`${GET_ALL_INCUBATORS}/${meetingIdFilter}`).then(
          (response: any) => {
            if (!response || !response.data) return;
            setIncubatorDetails(response.data.result.data);
            getMeetingDetails();
          }
        );
      } else {
        setFailDialogData({
          header: "Oops ! Something went wrong,",
          text: response.data.message,
        });
        setShowIncubatorFailureModal(true);
      }
    });
  };

  // function to format date
  function formatDate(date: Date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  const handleDateChange = (date: Date, field: string) => {
    setScheduleDate(formatDate(date));
    setSelectedDateControl(date);
  };

  const handleEditDateChange = (date: Date, field: string) => {
    setEditMeeting((prevState) => ({
      ...prevState,
      meeting_date: formatDate(new Date(date)),
    }));
  };
  const handleChange = (e:any) =>{
    const value = e.target.value.replace(/[^0-9.]/gi, '')
    setMeetingNumber(value)
    setEditCountMeeting((prevState) => ({
      ...prevState,
      meeting_number: value,
    }));
  }

  // secretariat_view_application
  const handleRowChange = (
    id: any,
    ind: any,
    application_index: any,
    userId: any,
    appId: any

  ) => {
    //console.log(id, ind, application_index, userId, "337");
    if (loggedInRole == "dpiit") {
      history.replace({
        pathname: "/dpiit_view_incubator",
        state: { incubator_application_id: id },
      });
    } else if (loggedInRole == "secretariat" && ind == 0) {
      // console.log("ind==0");
      //  return
      history.replace({
        pathname: "/merged_Incubator_Details_list",
        state: {
          application_id: id,
          application_index: application_index,
          userId: userId,
          id: appId
        },
      });
    } else if (loggedInRole == "secretariat" && ind == 1) {
      // console.log("ind==1");
      history.replace({
        pathname: "/merged_Incubator_Details_list",
        state: {
          application_id: id,
          application_index: application_index,
          userId: userId,
          id: appId
        },
      });
    }
  };
  const getIncubatorCount = () => {
    get(PENDING_INCUBATOR_COUNT).then((response: any) => {
      // console.log(response,"asdsaasd");
      if (!response || !response.data) return;

      setIncubatorPending(parseInt(response.data.data.count));
    });
  };

  const handleOnChange = (e: any /*index:number*/) => {
    const value = e.target.value;
    setMeetingType(value);
    if (value == "2") {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const onEditTimeChange = (e: any) => {
    var value = e.target.value;
    var name = e.target.name;
    if (name == "fromTime") {
      setEditMeeting((prevState) => ({
        ...prevState,
        meeting_from_time: value + ":00",
      }));
      if (
        compareTime(
          editMeeting.meeting_from_time,
          editMeeting.meeting_to_time
        ) && editMeeting.meeting_to_time != ""
      ) {
        setTimeValidation(false);
      }
    }
    if (name == "toTime") {
      setEditMeeting((prevState) => ({
        ...prevState,
        meeting_to_time: value + ":00",
      }));
      if (
        compareTime(
          editMeeting.meeting_from_time,
          editMeeting.meeting_to_time
        ) && editMeeting.meeting_to_time != ""
      ) {
        setTimeValidation(false);
      }
    }
    if (name == "MeetingLink") {
      setEditMeeting((prevState) => ({
        ...prevState,
        link: value,
      }));
    }
    if (name == "meeting_Comment") {
      setEditMeeting((prevState) => ({
        ...prevState,
        comment: value,
      }));
    }
    if (name == "incubatorSelected") {
      setEditCountMeeting((prevState) => ({
        ...prevState,
        incubator_selected: value,
      }));
    }
    if (name == "incubatorEvaluated") {
      setEditCountMeeting((prevState) => ({
        ...prevState,
        incubator_evaluated: value,
      }));
    }
    if (name == "fundsApproved") {
      setEditCountMeeting((prevState) => ({
        ...prevState,
        funds_approved: value,
      }));
    }
  };
  const handleRadioChange = (e: any /*index:number*/) => {
    var value = e.target.value;
    setMeetingFilter(value);
  };

  const filterValue = (e: any /*index:number*/) => {
    var value = e.target.value;
    if (e.target.value == "" || e.target.value == null) {
      setFilteredData([]);
      setNoDataStartupFilter("");
    }
    setFilterText(value);


  };

  // on change time;
  const onTimeChange = (e: any /*index:number*/) => {
    var value = e.target.value;
    var name = e.target.name;
    if (name == "fromTime") {
      setFromTime(value);
    } else if (name == "toTime") {
      setToTime(value);
    }
  };

  const dismissModal = () => {
    setShowIncubatorSuccessModal(false);
    setShowIncubatorFailureModal(false);
    window.location.reload();
  };

  const dismissMeetingModal = () => {
    setShowMeetingSuccessModal(false);
    window.location.reload();
  };

  // const quickMeetingSubmit = () => {
  //   var time = new Date();
  //   var curTime = moment(time, "HH:mm").format("HH:mm");
  //   if (!compareTime(fromTime, toTime)) {
  //     setTimeValidation(true);
  //     setTimeValidationCurrentTime(false);
  //     return false;
  //   }
  //   const payload = {
  //     meeting_date: scheduleDate != "" ? scheduleDate : formatDate(new Date()),
  //     meeting_from_time: fromTime,
  //     meeting_to_time: toTime,
  //     meeting_type: meetingType,
  //     link: meetingLink,
  //     comment: meetingComment,
  //     meeting_filter: meetingFilter,
  //     warning_ignore: 0,
  //   };
  //   if (meetingLink == "") {
  //     setLinkValidation(true);
  //     return false;
  //   }
  //   if (
  //     formatDate(new Date()) == payload.meeting_date &&
  //     compareTime(fromTime, curTime)
  //   ) {
  //     setTimeValidationCurrentTime(true);
  //     setTimeValidation(false);
  //     return false;
  //   }
  //   setTimeValidation(false);
  //   setTimeValidationCurrentTime(false);
  //   setLinkValidation(false);
  //   setDisabled(true);
  //   post(QUICK_MEETINGS, payload).then((response: any) => {
  //     if (!response || !response.data) return;
  //     if (response.data.result == "success") {
  //       setDialogData({
  //         header: "Thank You!",
  //         text: "Your meeting has been scheduled !!!",
  //       });
  //       setShowMeetingSuccessModal(true);
  //       setDisabled(false);
  //     } else if (response.data.result == "warning") {
  //       setConfirmationDialogData({
  //         header: "Meeting Already Exist",
  //         text: response.data.message,
  //       });
  //       setShowConfirmationBox(true);
  //       setDisabled(false);
  //     } else {
  //       setFailDialogData({
  //         header: "Oops ! Something went wrong,",
  //         text: "Please Try Again !!",
  //       });
  //       setShowIncubatorFailureModal(true);
  //       setDisabled(false);
  //     }
  //   });
  // };

  // const finalSchedule = () => {
  //   const payload = {
  //     meeting_date: scheduleDate != "" ? scheduleDate : formatDate(new Date()),
  //     meeting_from_time: fromTime,
  //     meeting_to_time: toTime,
  //     meeting_type: meetingType,
  //     link: meetingLink,
  //     meeting_filter: meetingFilter,
  //     warning_ignore: 1,
  //   };
  //   setTimeValidation(false);
  //   post(QUICK_MEETINGS, payload).then((response: any) => {
  //     if (!response || !response.data) return;
  //     setShowConfirmationBox(false);
  //     if (response.data.result == "success") {
  //       setDialogData({
  //         header: "Thank You!",
  //         text: "Your meeting has been scheduled !!!",
  //       });
  //       setShowIncubatorSuccessModal(true);
  //     } else {
  //       setFailDialogData({
  //         header: "Oops !!",
  //         text: response.data.message,
  //       });
  //       setShowIncubatorFailureModal(true);
  //     }
  //   });
  // };

  const getMeetingByID = (id: any) => {
    // console.log(id);
    // return;
    setOpenUploadBlock(false);
    setUploadedFile([]);
    setMeetingIdFilter(id.id);

    setEditMeeting({
      meeting_type: id.meeting_type,
      meeting_date: id.meeting_date,
      meeting_from_time: id.meeting_from_time,
      meeting_to_time: id.meeting_to_time,
      meeting_id: id.id,
      created_at: id.created_at,
      comment: id.comment,
      link: id.link,
    });

    if (id.file_path != "") {
      setMeetingPath(id.file_path);
    } else {
      setMeetingPath("");
    }

    setEditCountMeeting({
      meeting_id: id.id,
      incubator_evaluated: id.incubator_count,
      incubator_selected: id.selected_count,
      funds_approved: id.funds_approved,
      meeting_date: id.meeting_date,
      meeting_from_time: id.meeting_from_time,
      meeting_to_time: id.meeting_to_time,
      meeting_type: id.meeting_type,
      meeting_number: id.meeting_number
    });

    // post(GET_MEETINGS_BY_ID, { meeting_id: id }).then((response: any) => {
    //   if (!response || !response.data) return;
    //  // console.log(response.data);
    //   setEditMeeting({
    //     meeting_type: response.data.data.meeting_type,
    //     meeting_date: response.data.data.meeting_date,
    //     meeting_from_time: response.data.data.meeting_from_time,
    //     meeting_to_time: response.data.data.meeting_to_time,
    //     meeting_id: response.data.data.meeting_id,
    //     created_at: response.data.data.created_at,
    //     comment: response.data.data.comment,
    //     link: response.data.data.link,
    //   });

    //   if (response.data.data.file_path != "") {
    //     setMeetingPath(response.data.data.file_path);
    //   } else {
    //     setMeetingPath("");
    //   }
    //   setEditCountMeeting({
    //     meeting_id: response.data.data.meeting_id,
    //     incubator_evaluated: response.data.data.incubator_evaluated,
    //     incubator_selected: response.data.data.incubator_selected,
    //     funds_approved: response.data.data.funds_approved,
    //     meeting_date: response.data.data.meeting_date,
    //     meeting_from_time: response.data.data.meeting_from_time,
    //     meeting_to_time: response.data.data.meeting_to_time,
    //     meeting_type: response.data.data.meeting_type,
    //   });
    // });
  };
  const meetingReschedule = () => {
    //  console.log("rescud")
    var time = new Date();
    var curTime = moment(time, "HH:mm").format("HH:mm");
    if (
      !compareTime(editMeeting.meeting_from_time, editMeeting.meeting_to_time)
    ) {
      setTimeValidation(true);
      setTimeValidationCurrentTime(false);
      return false;
    }
    if (
      formatDate(new Date()) == editMeeting.meeting_date && compareTime(editMeeting.meeting_from_time, curTime)
    ) {
      setTimeValidationCurrentTime(true);
      setTimeValidation(false);
      return false;
    }
    if (editMeeting.link == "") {
      // setLinkValidation(true);
      return false;
    }
    if (editMeeting.comment == "") {
      return false;
    }
    setDisabled(true);
    setTimeValidationCurrentTime(false);
    setLinkValidation(false);
    setTimeValidation(false);
    post(UPDATE_MEETING, editMeeting).then((response: any) => {
      if (!response || !response.data) return;
      // console.log(response.data);
      if (response.data.result == "success") {
        setDialogData({
          header: "Thank You!",
          text: "Meeting Details Updated Successfully !!!",
        });
        setShowMeetingSuccessModal(true);
        getMeetingDetails();
        setIsEditable(true);
        setDisabled(false);
      } else {
        setFailDialogData({
          header: "Oops ! Something went wrong,",
          text: "Please Try Again !!",
        });
        setShowIncubatorFailureModal(true);
        setDisabled(false);
      }
    });
  };

  const saveMeetingInfo = () => {
    const payload = {
      meeting_number: editCountMeeting.meeting_number,
      meeting_id: editCountMeeting.meeting_id,
      incubator_evaluated: editCountMeeting.incubator_evaluated,
      incubator_selected: editCountMeeting.incubator_selected,
      funds_approved: editCountMeeting.funds_approved,
    };
    post(EAC_REVIEW_UPDATE, payload).then((response: any) => {
      if (!response || !response.data) return;
      console.log(response.data,"$$$$$$$$$$");
      if (response.data.result == "success") {
        setDialogData({
          header: "Thank You!",
          text: "Meeting Details Updated Successfully !!!",
        });
        setShowIncubatorSuccessModal(true);
        getMeetingDetails();
        setIsEditable(true);
      } else {
        setFailDialogData({
          header: "Oops !",         
          text: response.data.message
          // "Please Try Again !!",
        });
        setShowIncubatorFailureModal(true);
      }
    });
  };

  const onFileUpload = (files: Array<File>) => {
    let indexes: Array<string> = [];
    setUploadedFile([]);
    for (let file of files) {
      if (file.type === "application/pdf") {
        const formData = new FormData();
        // Update the formData object
        formData.append("file", file, file.name);
        formData.append("meeting_id", meetingIdFilter);
        post(MEETING_FILE_UPLOAD, formData).then((response: any) => {
          if (!response.data) return;
          if (response.data.result.status == true) {
            setDialogData({
              header: "Thank You!",
              text: "Document Uploaded Successfully !!!",
            });
            // setShowIncubatorSuccessModal(true);
            getMeetingDetails();
          } else {
            setFailDialogData({
              header: "Oops ! Something went wrong,",
              text: "Please Try Again !!",
            });
            setShowIncubatorFailureModal(true);
          }
        });
      } else {
        indexes.push(file.name);
      }
    }
  };
  const onFileDelete = () => {
    post(MEETING_FILE_REMOVE, { meeting_id: meetingIdFilter }).then(
      (response: any) => {
        if (!response.data) return;
        if (response.data.result.status == true) {
          setDialogData({
            header: "Thank You!",
            text: "Document Deleted Successfully !!!",
          });
          setShowIncubatorSuccessModal(true);
          getMeetingDetails();
          setMeetingPath("");
        } else {
          setFailDialogData({
            header: "Oops ! Something went wrong,",
            text: "Please Try Again !!",
          });
          setShowIncubatorFailureModal(true);
        }
      }
    );
  };

  const searchList = (e: any) => {
    setNoDataFilter(e.target.value);
    const filteredData = filterByValue(upcomingMeetingData, e.target.value);
    const filteredPastData = filterByValue(pastMeetingData, e.target.value);
    // console.log(filteredData);
    setFilteredUpcomingMeetingData(filteredData);
    setFilteredPastMeetingData(filteredPastData);

    /////////////////upcoming/////////////
    setCurrentPage(0);
    setCurrentPage1(0);
    handlePageChange({ selected: 0 })

    //////////////past////////////////////////
    setSelectedCurrentPage(0);
    setFilteredCurrentPage(0);
    selectedhandlePageChange({ selected: 0 })
    // console.log(filteredData)
  };

  const [currentPage, setCurrentPage] = useState(0);
  const [currentPage1, setCurrentPage1] = useState(0);
  const [pageRange, setPageRange] = useState(5);
  const [selectedPage, setSelectedPage] = useState(0);
  const [selectedPage1, setSelectedPage1] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [itemsPerPage1, setItemsPerPage1] = useState(25);
  // const itemsPerPage = 10;
  const handlePageChange = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
    setCurrentPage1(selectedItem.selected);
  };
  const handleItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newItemsPerPage = parseInt(event.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setItemsPerPage1(newItemsPerPage);
    setCurrentPage(0); // Reset current page to first page when items per page changes
    setCurrentPage1(0); // Reset current page to first page when items per page changes
    handlePageChange({ selected: 0 })
  };
  const offset = currentPage * itemsPerPage;
  const offset1 = currentPage1 * itemsPerPage1;
  const currentPageData = upcomingMeetingData.slice(offset, offset + itemsPerPage);
  // const currentPageData1 = filteredUpcomingMeetingData.slice(offset1, offset1 + itemsPerPage1);
  var currentPageData1 = noDataFilter
    ? filteredUpcomingMeetingData.slice(offset1, offset1 + itemsPerPage1)
    : upcomingMeetingData.slice(offset1, offset1 + itemsPerPage1);
  const totalPages = Math.ceil((upcomingMeetingData.length / itemsPerPage));
  // const totalPages1 = Math.ceil( (filteredUpcomingMeetingData.length / itemsPerPage1));
  const totalPages1 = noDataFilter
    ? Math.ceil(filteredUpcomingMeetingData.length / itemsPerPage1)
    : Math.ceil(upcomingMeetingData.length / itemsPerPage1);




  const [selectedCurrentPage, setSelectedCurrentPage] = useState(0);
  const [filteredCurrentPage, setFilteredCurrentPage] = useState(0);
  const [selectedItemsPerPage, setSelectedItemsPerPage] = useState(25);
  const [filteredItemsPerPage, setFilteredItemsPerPage] = useState(25);
  // const itemsPerPage = 10;
  const selectedhandlePageChange = (selectedItem: { selected: number }) => {
    setSelectedCurrentPage(selectedItem.selected);
    setFilteredCurrentPage(selectedItem.selected);
  };
  const selectedhandleItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newItemsPerPage = parseInt(event.target.value, 10);
    setSelectedItemsPerPage(newItemsPerPage);
    setFilteredItemsPerPage(newItemsPerPage);
    setSelectedCurrentPage(0); // Reset current page to first page when items per page changes
    setFilteredCurrentPage(0); // Reset current page to first page when items per page changes
    selectedhandlePageChange({ selected: 0 })
  };
  const selectedoffset = selectedCurrentPage * selectedItemsPerPage;
  const filteredoffset = filteredCurrentPage * filteredItemsPerPage;
  const selectedcurrentPageData = pastMeetingData.slice(selectedoffset, selectedoffset + selectedItemsPerPage);
  // const filteredcurrentPageData = filteredPastMeetingData.slice(filteredoffset, filteredoffset + filteredItemsPerPage);
  var filteredcurrentPageData = noDataFilter
    ? filteredPastMeetingData.slice(filteredoffset, filteredoffset + filteredItemsPerPage)
    : pastMeetingData.slice(filteredoffset, filteredoffset + filteredItemsPerPage);
  const selectedTotalPages = Math.ceil((pastMeetingData.length / selectedItemsPerPage));
  // const filteredTotalPages = Math.ceil( (filteredPastMeetingData.length / filteredItemsPerPage));
  const filteredTotalPages = noDataFilter
    ? Math.ceil(filteredPastMeetingData.length / filteredItemsPerPage)
    : Math.ceil(pastMeetingData.length / filteredItemsPerPage);

  return (
    <>
      {loader &&
        <div className="spinner-border custom-spinner" role="status">
          <span className="sr-only">Loading...</span>
        </div>}
      <fieldset disabled={loader}>
        <div className="container-fluid container-min pt-5">
          <div className="row mb-4 px-3">
            <div className="col-md-6">
              <p className="dashboard pt-2 pl-3">EAC Review</p>
            </div>
          </div>

          <div className="row incubator-heading-review">
            <div className="col-md-6 text-left">
              <ul className="nav nav-tabs chart-nav" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#tabs-1"
                    role="tab"
                    onClick={() => setMeetingTab("upcoming")}
                  >
                    Upcoming Meetings
                  </a>
                </li>
                <div className="vertical-dash"></div>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#tabs-2"
                    role="tab"
                    onClick={() => setMeetingTab("past")}
                  >
                    Past Meetings
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-6 search-left-meeting">
              <div className="row" style={{ float: 'right' }}>
                <div className="d-flex searchDiv col-12">
                  {" "}
                  <img src={search} className="search" alt="search" />
                  <input
                    className="searchBar"
                    type="text"
                    placeholder="Search.."
                    name="search"
                    onKeyUp={searchList}
                  />
                </div>
                {/* <div className="col-6 schedule-button-pd text-right pr-3">
                <button
                  className="btn btn-sisfs  btn-yellow-primary"
                  data-toggle="modal"
                  data-target="#scheduleMeeting"
                >
                  SCHEDULE A MEETING
                </button>
              </div> */}
              </div>
            </div>
          </div>
          <div className="tab-content mb-4">
            <div className="tab-pane active" id="tabs-1">
              <div className="table-scroll">
                <table className="reviewTable">
                  <thead>
                    <tr>
                    <th className="text-left table-heading custom-td-left table-heading ">
                        Meeting Number
                      </th>
                      <th className="text-left table-heading custom-td-left table-heading ">
                        Meeting Type
                      </th>
                      <th className="text-left table-heading">Date & Time</th>
                      <th className="text-left table-heading">
                        No.of Incubators
                      </th>
                      <th className="table-heading">Meeting Link</th>
                      <th className="table-heading"></th>
                      <th className="table-heading"></th>
                    </tr>
                  </thead>
                  {/* / {console.log(upcomingMeetingData,"mohan")} */}
                  <tbody>
                    {upcomingMeetingData.length == 0 ||
                      (filteredUpcomingMeetingData.length == 0 &&
                        noDataFilter != "") ? (
                      <tr>
                        <td className="text-center" colSpan={5}>
                          No data
                        </td>
                      </tr>
                    ) : filteredUpcomingMeetingData.length == 0 ? (
                      currentPageData.map((item: any, index) => (

                        <tr>
                          <td className="text-left custom-td-left">
                            {(!item.meeting_number || item.meeting_number == 0) ? '-' : item.meeting_number}
                          </td>
                          <td className="text-left custom-td-left">
                            {item.meeting_type == 1
                              ? "Incubator Selection"
                              : "Performance Evaluation"}
                          </td>
                          <td className="text-left custom-td">
                            <div>{FormatDate(item.meeting_date)}</div>
                            <span className="tableDataApplication">
                              {formatTime(item.meeting_from_time)} -{" "}
                              {formatTime(item.meeting_to_time)}
                            </span>
                          </td>
                          <td className="text-left pl-2">
                            {item.incubator_count}{" "}
                            <span
                              className="view-all"
                              onClick={() =>
                                getIncubatorDetails(
                                  item.source_indicator,
                                  item.id,
                                  item.meeting_date,
                                  formatTime(item.meeting_from_time) +
                                  "-" +
                                  formatTime(item.meeting_to_time)
                                )
                              }
                              data-toggle="modal"
                              data-target="#viewIncubator"
                            >
                              View<span className="view-all back-slash">Add</span>
                            </span>
                            {/* <span className="view-all back-slash">Add</span> */}
                          </td>
                          <td className="pl-2">
                            {item.link != null ? (
                              <>
                                {showViewMore != "show_" + index && (
                                  <>
                                    <a
                                      href={item.link}
                                      target="_blank"
                                      className="link-orange-text"
                                    >
                                      {showEllipsisText(item.link, 15)}
                                    </a>
                                    {item.link.length >= 15 && (
                                      <span
                                        className="link-orange-text d-block"
                                        onClick={() =>
                                          setShowViewMore("show_" + index)
                                        }
                                      >
                                        View More
                                      </span>
                                    )}
                                  </>
                                )}
                                {showViewMore == "show_" + index && (
                                  <>
                                    <a
                                      href={item.link}
                                      target="_blank"
                                      className="link-orange-text"
                                    >
                                      {item.link}
                                    </a>
                                    <span
                                      className="link-orange-text d-block"
                                      onClick={() => setShowViewMore("")}
                                    >
                                      View Less
                                    </span>
                                  </>
                                )}
                              </>
                            ) : (
                              "-"
                            )}
                          </td>
                          {console.log(item)}
                          <td className="text-left">
                            <span
                              className="orange-link"
                              onClick={() =>
                                removeMeeting(
                                  item.id
                                )
                              }
                            >

                              <img src={Remove} alt="SISFS Logo" style={{ cursor: 'pointer', width: '16px', marginBottom: '4px' }} />

                            </span>
                          </td>
                          <td className="text-right">
                            <button
                              className="btn btn-sisfs btn-transparent-primary"
                              onClick={() => getMeetingByID(item)}
                              data-toggle="modal"
                              data-target="#editMeeting"
                            >
                              Reschedule
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      currentPageData1.map((item: any, index) => (
                        <tr>
                          <td className="text-left custom-td-left">
                            {(!item.meeting_number || item.meeting_number == 0) ? '-' : item.meeting_number}
                          </td>
                          <td className="text-left custom-td-left">
                            {item.meeting_type == 1
                              ? "Incubator Selection"
                              : "Performance Evaluation"}
                          </td>
                          <td className="text-left custom-td">
                            <div>{FormatDate(item.meeting_date)}</div>
                            <span className="tableDataApplication">
                              {formatTime(item.meeting_from_time)} -{" "}
                              {formatTime(item.meeting_to_time)}
                            </span>
                          </td>
                          <td className="text-left pl-2">
                            {item.incubator_count}{" "}
                            <span
                              className="view-all"
                              onClick={() =>
                                getIncubatorDetails(
                                  item.source_indicator,
                                  item.id,
                                  item.meeting_date,
                                  formatTime(item.meeting_from_time) +
                                  "-" +
                                  formatTime(item.meeting_to_time)
                                )
                              }
                              data-toggle="modal"
                              data-target="#viewIncubator"
                            >
                              View
                            </span>
                            <span className="view-all back-slash">Add</span>
                          </td>
                          <td className="pl-2">
                            {item.link != null ? (
                              <>
                                {showViewMore != "show_" + index && (
                                  <>
                                    <a
                                      href={item.link}
                                      target="_blank"
                                      className="link-orange-text"
                                    >
                                      {showEllipsisText(item.link, 15)}
                                    </a>
                                    {item.link.length >= 15 && (
                                      <span
                                        className="link-orange-text d-block"
                                        onClick={() =>
                                          setShowViewMore("show_" + index)
                                        }
                                      >
                                        View More
                                      </span>
                                    )}
                                  </>
                                )}
                                {showViewMore == "show_" + index && (
                                  <>
                                    <a
                                      href={item.link}
                                      target="_blank"
                                      className="link-orange-text"
                                    >
                                      {item.link}
                                    </a>
                                    <span
                                      className="link-orange-text d-block"
                                      onClick={() => setShowViewMore("")}
                                    >
                                      View Less
                                    </span>
                                  </>
                                )}
                              </>
                            ) : (
                              "-"
                            )}
                          </td>
                          {console.log(item)}
                          <td className="text-left">
                            <span
                              className="orange-link"
                              onClick={() =>
                                removeMeeting(
                                  item.id
                                )
                              }
                            >
                              Remove Meeting
                            </span>
                          </td>
                          <td className="text-right">
                            <button
                              className="btn btn-sisfs btn-transparent-primary"
                              onClick={() => getMeetingByID(item)}
                              data-toggle="modal"
                              data-target="#editMeeting"
                            >
                              Reschedule
                            </button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
              {upcomingMeetingData.length == 0 ? <></>
                :
                filteredUpcomingMeetingData.length == 0 ?
                  <div style={{ float: 'right', display: 'flex', marginRight: '20px', alignItems: 'center', justifyContent: 'center' }}>

                    <span>
                      <label htmlFor="itemsPerPageSelect" className="itemsPerPage">Items per Page:</label>
                      <select
                        className="itemsPerPageSelect"
                        id="itemsPerPageSelect"
                        value={itemsPerPage}
                        onChange={handleItemsPerPageChange}
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                      </select>
                    </span>&nbsp;&nbsp;&nbsp;

                    <span className="pagination" style={{ fontSize: '18px', marginTop: '10px' }}>
                      <ReactPaginate
                        key={itemsPerPage}
                        pageCount={totalPages}
                        pageRangeDisplayed={0}
                        marginPagesDisplayed={0}
                        previousLabel="Prev"
                        nextLabel="Next"
                        // breakLabel="..."
                        onPageChange={handlePageChange}
                        containerClassName="pagination"
                        activeClassName="active"
                        disableInitialCallback={true}
                        forcePage={currentPage}
                      />
                    </span>&nbsp;&nbsp;&nbsp;

                    <span className="pageNum">
                      Page {(currentPage) + 1} of {totalPages}
                    </span>
                    {/* &nbsp;&nbsp;&nbsp;<select value={currentPage} onChange={handleSelectedPageChange} style={{border:'0 0 1px solid black 0'}} >
              {renderPageNumbers()}
            </select>&nbsp;&nbsp;&nbsp; */}
                  </div>
                  :
                  <>
                    <div style={{ float: 'right', display: 'flex', marginRight: '20px', alignItems: 'center', justifyContent: 'center' }}>

                      <span>
                        <label htmlFor="itemsPerPageSelect" className="itemsPerPage">Items per Page:</label>
                        <select
                          className="itemsPerPageSelect"
                          id="itemsPerPageSelect"
                          value={itemsPerPage1}
                          onChange={handleItemsPerPageChange}
                        >
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="75">75</option>
                          <option value="100">100</option>
                        </select>
                      </span>&nbsp;&nbsp;&nbsp;

                      <span className="pagination" style={{ fontSize: '18px', marginTop: '10px' }}>
                        <ReactPaginate
                          pageCount={Math.ceil((filteredUpcomingMeetingData.length / itemsPerPage))}
                          pageRangeDisplayed={0}
                          marginPagesDisplayed={0}
                          previousLabel="Prev"
                          nextLabel="Next"
                          // breakLabel="..."
                          onPageChange={handlePageChange}
                          containerClassName="pagination"
                          activeClassName="active"
                          disableInitialCallback={true}
                          forcePage={currentPage1}
                        />
                      </span>&nbsp;&nbsp;&nbsp;

                      <span className="pageNum">
                        Page {currentPage1 + 1} of {totalPages1}
                      </span>
                      {/* &nbsp;&nbsp;&nbsp;<select value={currentPage} onChange={handleSelectedPageChange} style={{border:'0 0 1px solid black 0'}} >
                          {renderPageNumbers()}
                          </select>&nbsp;&nbsp;&nbsp; */}
                    </div>
                  </>
              }
            </div>
            <div className="tab-pane" id="tabs-2">
            <div className="row cardDiv">
              <div className="col-md"></div>
            <div className="col-md">
              <div className="rectangle-2">
                <span className="incubator-numeric" style={{fontSize:'28px', fontWeight:'600'}}>
                  {getTotalEvaluated}
                </span>
                <br />
                <span className="incubator-applicatio " style={{fontSize:'13px'}}>
                Total No.of <br /> Incubators Evaluated
                </span>
              </div>
            </div>
            <div className="col-md">
              <div className="rectangle-2">
                <span className="incubator-numeric"  style={{fontSize:'28px', fontWeight:'600'}}>
                {getTotalFunds && changeNumberFormat(Number(getTotalFunds), 2, 1)}
                  
                </span>
                <br />
                <span className="incubator-applicatio " style={{fontSize:'13px'}}>
                 Total Funds <br />
                  Approved
                </span>
              </div>
            </div>
            <div className="col-md">
              <div className="rectangle-2">
                <span className="incubator-numeric"  style={{fontSize:'28px', fontWeight:'600'}}>
                {getTotalSelected}
                </span>
                <br />
                <span className="incubator-applicatio " style={{fontSize:'13px'}}>
                  Total No. of <br />
                  Incubators Selected
                </span>
              </div>
            </div>
            <div className="col-md"></div>
          </div>
              <div className="table-scroll mb-5">
                <table className="reviewTable">
                  <thead>
                    <tr>
                    <th className="text-center table-heading custom-td-left">
                        Meeting Number
                      </th>
                      <th className="text-center table-heading custom-td-left">
                        Meeting Type
                      </th>
                      <th className="text-center table-heading">Date & Time</th>
                      <th className="text-center table-heading">
                        No.of Incubators Evaluated
                      </th>
                      <th className="text-center table-heading">
                        No.of Incubators Selected
                      </th>
                      <th className="text-center table-heading">
                        Funds Approved(&#8377;)
                      </th>
                      <th className="text-center table-heading">Meeting Notes</th>
                      {/* <th className="text-center table-heading"></th> */}
                      <th className="text-center table-heading">Edit/Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pastMeetingData.length == 0 ||
                      (filteredPastMeetingData.length == 0 &&
                        noDataFilter != "") ? (
                      <tr>
                        <td className="text-center" colSpan={8}>
                          No data
                        </td>
                      </tr>
                    ) : filteredPastMeetingData.length == 0 ? (
                      selectedcurrentPageData.map((item: any) => (
                        <tr>
                          <td className="text-center custom-td-left">
                            {(!item.meeting_number || item.meeting_number == 0) ? '-' : item.meeting_number}
                          </td>
                          <td className="text-center custom-td-left">
                            {item.meeting_type == 1
                              ? "Incubator Selection"
                              : "Performance Evaluation"}
                          </td>
                          <td className="text-center custom-td">
                            <div>{FormatDate(item.meeting_date)}</div>
                            <span className="tableDataApplication">
                              {formatTime(item.meeting_from_time)} -{" "}
                              {formatTime(item.meeting_to_time)}
                            </span>
                          </td>
                          <td className="text-center">
                            {item.incubator_count}{" "}
                            <span
                              className="view-all"
                              onClick={() =>
                                getIncubatorDetails(
                                  item.source_indicator,
                                  item.id,
                                  item.meeting_date,
                                  formatTime(item.meeting_from_time) +
                                  "-" +
                                  formatTime(item.meeting_to_time)
                                )
                              }
                              data-toggle="modal"
                              data-target="#viewIncubator"
                            >
                              View
                            </span>
                          </td>
                          <td className="text-center">
                            {item.selected_count}
                          </td>
                          <td className="text-center custom-td-right">
                            {changeNumberFormat(Number(item.funds_approved), 2, 1) }
                          </td>
                          <td className="text-center">

                            {item.file_location != "" ? (
                              <a style={{ color: '#e26640' }}
                                target='_blank'
                                href={`${File_node_url}` + item.file_location}>
                                <u>
                                  <img
                                    src={download}
                                    alt="download"

                                  />
                                </u>
                              </a>
                              // <img
                              //   src={download}
                              //   alt="download"
                              //   onClick={() =>
                              //     downloadDocument(
                              //       item.file_location,
                              //       item.meeting_type == 1
                              //         ? "Incubator-Selection-Meeting" +
                              //             item.meeting_date
                              //         : "Performance-Evaluation-Meeting-" +
                              //             item.meeting_date
                              //     )
                              //   }
                              // />
                            ) : (
                              "N/A"
                            )}
                          </td>
                          {/* <td className="text-left">
                          
                        </td> */}
                          <td
                            className="text-center"
                          // data-toggle="modal"
                          // onClick={() => getMeetingByID(item.id)}
                          // data-target="#editPastMeeting"
                          >
                            {/* <img src={edit} className="search" alt="search" /> */}
                            <img data-toggle="modal"
                              // onClick={() => getMeetingByID(item.id)}
                              onClick={() => getMeetingByID(item)}
                              data-target="#editPastMeeting" src={edit} className="search" alt="search" />|
                            <span
                              className="orange-link"
                              onClick={() =>
                                removeMeeting(
                                  item.id
                                )
                              }
                            >
                              <img src={Remove} alt="SISFS Logo" style={{ cursor: 'pointer', width: '16px', marginBottom: '4px' }} />

                            </span>

                          </td>
                        </tr>
                      ))

                      // <span
                      //         className="view-all"
                      //         onClick={() =>
                      //           getIncubatorDetails(
                      //             item.source_indicator,
                      //             item.id,
                      //             item.meeting_date,
                      //             formatTime(item.meeting_from_time) +
                      //               "-" +
                      //               formatTime(item.meeting_to_time)
                      //           )
                      //         }
                      //         data-toggle="modal"
                      //         data-target="#viewIncubator"
                      //       >
                      //         View
                      //       </span>
                    ) : (
                      filteredcurrentPageData.map((item: any) => (
                        <tr>
                          <td className="text-center custom-td-left">
                            {(!item.meeting_number || item.meeting_number == 0) ? '-' : item.meeting_number}
                          </td>
                          <td className="text-center custom-td-left">
                            {item.meeting_type == 1
                              ? "Incubator Selection"
                              : "Performance Evaluation"}
                          </td>
                          <td className="text-center custom-td">
                            <div>{FormatDate(item.meeting_date)}</div>
                            <span className="tableDataApplication">
                              {formatTime(item.meeting_from_time)} -{" "}
                              {formatTime(item.meeting_to_time)}
                            </span>
                          </td>
                          <td className="text-center">
                            {item.incubator_count}{" "}
                            <span
                              className="view-all"
                              onClick={() =>
                                getIncubatorDetails(
                                  item.source_indicator,
                                  item.id,
                                  item.meeting_date,
                                  formatTime(item.meeting_from_time) +
                                  "-" +
                                  formatTime(item.meeting_to_time)
                                )
                              }
                              data-toggle="modal"
                              data-target="#viewIncubator"
                            >
                              View
                            </span>
                          </td>
                          <td className="text-center">
                            {item.selected_count}
                          </td>
                          <td className="text-center custom-td-right">
                          {changeNumberFormat(Number(item.funds_approved), 2, 1) }
                          </td>
                          <td className="text-center">
                            {item.file_path != "" ? (
                              <img
                                src={download}
                                alt="download"
                                onClick={() =>
                                  downloadDocument(
                                    item.file_path,
                                    item.meeting_type == 1
                                      ? "Incubator-Selection-Meeting" +
                                      item.meeting_date
                                      : "Performance-Evaluation-Meeting-" +
                                      item.meeting_date
                                  )
                                }
                              />
                            ) : (
                              "N/A"
                            )}
                          </td>
                          <td className="text-center"
                            data-toggle="modal"
                            onClick={() => getMeetingByID(item)}
                            data-target="#editPastMeeting"
                          >
                            <img src={edit} className="search" alt="search" />|
                            <span
                              className="orange-link"
                              onClick={() =>
                                removeMeeting(
                                  item.id
                                )
                              }
                            >
                              <img src={Remove} alt="SISFS Logo" style={{ cursor: 'pointer', width: '16px', marginBottom: '4px' }} />

                            </span>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
              {pastMeetingData.length == 0 ? <></>
                :
                filteredPastMeetingData.length == 0 ?
                  <div style={{ float: 'right', display: 'flex', marginRight: '20px', alignItems: 'center', justifyContent: 'center' }}>

                    <span>
                      <label htmlFor="itemsPerPageSelect" className="itemsPerPage">Items per Page:</label>
                      <select
                        className="itemsPerPageSelect"
                        id="itemsPerPageSelect"
                        value={selectedItemsPerPage}
                        onChange={selectedhandleItemsPerPageChange}
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                      </select>
                    </span>&nbsp;&nbsp;&nbsp;

                    <span className="pagination" style={{ fontSize: '18px', marginTop: '10px' }}>
                      <ReactPaginate
                        key={selectedItemsPerPage}
                        pageCount={selectedTotalPages}
                        pageRangeDisplayed={0}
                        marginPagesDisplayed={0}
                        previousLabel="Prev"
                        nextLabel="Next"
                        // breakLabel="..."
                        onPageChange={selectedhandlePageChange}
                        containerClassName="pagination"
                        activeClassName="active"
                        disableInitialCallback={true}
                        forcePage={selectedCurrentPage}
                      />
                    </span>&nbsp;&nbsp;&nbsp;

                    <span className="pageNum">
                      Page {(selectedCurrentPage) + 1} of {selectedTotalPages}
                    </span>
                    {/* &nbsp;&nbsp;&nbsp;<select value={currentPage} onChange={handleSelectedPageChange} style={{border:'0 0 1px solid black 0'}} >
                {renderPageNumbers()}
              </select>&nbsp;&nbsp;&nbsp; */}
                  </div>
                  : <>
                    <div style={{ float: 'right', display: 'flex', marginRight: '20px', alignItems: 'center', justifyContent: 'center' }}>

                      <span>
                        <label htmlFor="itemsPerPageSelect" className="itemsPerPage">Items per Page:</label>
                        <select
                          className="itemsPerPageSelect"
                          id="itemsPerPageSelect"
                          value={filteredItemsPerPage}
                          onChange={selectedhandleItemsPerPageChange}
                        >
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="75">75</option>
                          <option value="100">100</option>
                        </select>
                      </span>&nbsp;&nbsp;&nbsp;

                      <span className="pagination" style={{ fontSize: '18px', marginTop: '10px' }}>
                        <ReactPaginate
                          key={filteredItemsPerPage}
                          pageCount={filteredTotalPages}
                          pageRangeDisplayed={0}
                          marginPagesDisplayed={0}
                          previousLabel="Prev"
                          nextLabel="Next"
                          // breakLabel="..."
                          onPageChange={selectedhandlePageChange}
                          containerClassName="pagination"
                          activeClassName="active"
                          disableInitialCallback={true}
                          forcePage={filteredCurrentPage}
                        />
                      </span>&nbsp;&nbsp;&nbsp;

                      <span className="pageNum">
                        Page {filteredCurrentPage + 1} of {filteredTotalPages}
                      </span>
                      {/* &nbsp;&nbsp;&nbsp;<select value={currentPage} onChange={handleSelectedPageChange} style={{border:'0 0 1px solid black 0'}} >
                              {renderPageNumbers()}
                              </select>&nbsp;&nbsp;&nbsp; */}
                    </div>
                  </>
              }
              <br />
            </div>
          </div>
          {/* Modal */}
          <div
            className="modal fade"
            id="viewIncubator"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            {loader &&
              <div className="spinner-border custom-spinner" role="status">
                <span className="sr-only">Loading...</span>
              </div>}

            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header modal-header-bottom mt-3 mb-3">
                  <h5 className="modal-title pl-2" id="exampleModalLongTitle">
                    Incubators
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={handleResetClick}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-5 text-left">
                        <span className="modal-text">Date & Time</span>
                        <br />
                        <span className="modal-text">
                          {FormatDate(modalDate)} | {modalTime}
                        </span>
                      </div>

                      {/* {meetingTab == "upcoming" && ( */}
                      <div className="col-md-7 text-right">
                        <div className="row">
                          <div className="d-flex searchDiv col-7">
                            {" "}
                            <img src={search} className="search" alt="search" />
                            <input
                              className="searchBar"
                              type="text"
                              placeholder="Search.."
                              name="search"
                              onChange={filterValue}
                            />
                          </div>
                          <div className="col-1  schedule-button-pd">
                            <button
                              className="tertiary  btn-yellow-primary"
                              onClick={applyIncubatorFilter}
                            >
                              Search
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* )} */}

                      <div className="table-scroll mb-5">
                        <table id="example">
                          <thead>
                            <tr>
                              <th className="custom-td-left table-heading">
                                Incubator
                              </th>
                              <th className="text-center table-heading">
                                Application Date
                              </th>
                              {/* <th className="text-center table-heading">No. Of Statrtup Supported</th> */}
                              {/* {meetingTab == "upcoming" && ( */}
                              <th className="table-heading" style={{ width: '80px' }}></th>
                              {/* )} */}
                            </tr>
                          </thead>
                          <tbody>
                            {/* {console.log(incubatorDetails, "incubator")}
                          {console.log(filteredData, "filteredData")}
                          {console.log(noDataStartupFilter, "noDataStartupFilter")} */}
                            {
                              // incubatorDetails.length == 0 ||
                              (filteredData.length == 0 &&
                                noDataStartupFilter != "") ? (
                                <tr>
                                  <td className="text-center" colSpan={4}>
                                    No data
                                  </td>
                                </tr>
                              ) : filteredData.length != 0 ? (
                                filteredData.map((item: any) => (
                                  <tr>
                                    {/* {console.log(item, "1240")} */}
                                    <td
                                      className="text-left custom-td-left"
                                      onClick={() =>
                                        handleRowChange(
                                          item.incubator_application_no,
                                          item.source_indicator,
                                          item.application_index,
                                          item.user_id,
                                          item.incubator_application_id
                                        )
                                      }
                                      data-dismiss="modal"
                                    >
                                      <u className="link-orange-text">
                                        {item.registered_name}
                                      </u>
                                    </td>
                                    <td className="text-left custom-td-left">
                                      {item.first_submitted_date != null
                                        ? item.first_submitted_date
                                        : item.incubator_application_submitted_date}
                                    </td>
                                    {/* <td className="text-center">-</td> */}

                                    <td className="text-left">
                                      <span
                                        className="orange-link"
                                        onClick={() =>
                                          addIncubator(
                                            item.incubator_application_id,
                                            item.source_indicator
                                          )
                                        }
                                      >
                                        Add
                                      </span>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                incubatorDetails.map((item: any) => (
                                  <tr>
                                    {/* {console.log(item, "1308")} */}
                                    <td
                                      className="text-left custom-td-left"
                                      onClick={() =>
                                        handleRowChange(
                                          item.incubator_application_no,
                                          item.source_indicator,
                                          item.application_index,
                                          item.user_id,
                                          item.incubator_application_id
                                        )
                                      }
                                      data-dismiss="modal"
                                    >
                                      <u className="link-orange-text">
                                        {item.incubator_application_name}
                                      </u>{" "}
                                      <br />
                                      {item.source_indicator == 0 && (
                                        <span className="selectedflagEAC">
                                          First Application
                                        </span>
                                      )}
                                      {item.source_indicator == 1 && (
                                        <span className="selectedflagEAC">
                                          Second Application
                                        </span>
                                      )}
                                      {item.status_id == 7 && <span className="selectedflagEAC_BGGreen">Selected</span>}
                                    </td>
                                    <td className="text-center custom-td-left">
                                      {item.first_submitted_date != null
                                        ? item.first_submitted_date
                                        : item.incubator_application_submitted_date}
                                    </td>
                                    {/* <td className="text-center">-</td> */}

                                    {/* {meetingTab == "upcoming" && ( */}
                                    <td className="text-left" style={{ width: '80px' }}>
                                      <span
                                        className="orange-link"
                                        onClick={() =>
                                          removeIncubator(
                                            item.meeting_id,
                                            item.user_id
                                          )
                                        }
                                      >
                                        <img src={Remove} alt="SISFS Logo" style={{ cursor: 'pointer', width: '16px', marginBottom: '4px' }} />
                                      </span>
                                    </td>
                                    {/* )} */}
                                  </tr>
                                ))
                              )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row pt-3 mb-3">
                    <div className="col-md-12 centerText">
                      <button
                        className="tertiary  btn-yellow-primary"
                        data-dismiss="modal"
                        onClick={handleResetClick}
                      >
                        OK
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          {/* Schedule meeting modal  */}
          {/* <div
            className="modal fade"
            id="scheduleMeeting"
            role="dialog"
            aria-labelledby="exampleModalLabel1"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-slideout modal-md"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header modal-header-bottom pr-5">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => (
                      setTimeValidation(false),
                      setTimeValidationCurrentTime(false)
                    )}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body pl-5 pr-5 pt-5">
                  <div className="popup-head mb-5 ml-3">
                    <div className="main-heading">Schedule a Meeting</div>
                    {!isVisible ? (
                      <div className="count-text">
                        {incubatorPending} Incubators Selected
                      </div>
                    ) : null}
                  </div>

                  <label className="col-sm-4 col-form-head">Meeting Type</label>
                  <div className="col-md-12 mb-4">
                    <div className="select-custom">
                      <select
                        className="form-control input-height"
                        aria-label="Select Entity Type"
                        onChange={handleOnChange}
                      >
                        <option value="1">Incubator Selection</option>
                        <option value="2">Performance Review Meeting</option>
                      </select>
                    </div>
                  </div>
                  {isVisible ? (
                    <div className="radio-popup">
                      <label className="col-sm-12 col-form-head mb-2">
                        Incubator Due for Review
                      </label>
                      <div className="pl-3">
                        <div>
                          <input
                            type="radio"
                            id="Tomorrow"
                            name="filter"
                            value="Tomorrow"
                            onChange={handleRadioChange}
                            disabled={count.Tomorrow == "0" ? true : false}
                          />
                          <label className="radio-label">
                            Tomorrow({count.Tomorrow})
                          </label>
                        </div>
                        <div>
                          <input
                            type="radio"
                            name="filter"
                            value="This_week"
                            onChange={handleRadioChange}
                            disabled={count.This_week == "0" ? true : false}
                          />
                          <label className="radio-label">
                            This Week({count.This_week})
                          </label>
                        </div>
                        <div>
                          <input
                            type="radio"
                            name="filter"
                            value="Next_week"
                            onChange={handleRadioChange}
                            disabled={count.Next_week == "0" ? true : false}
                          />
                          <label className="radio-label">
                            Next Week({count.Next_week})
                          </label>
                        </div>
                        <div>
                          <input
                            type="radio"
                            name="filter"
                            value="Next_month"
                            onChange={handleRadioChange}
                            disabled={count.Next_month == "0" ? true : false}
                          />
                          <label className="radio-label">
                            Next Month({count.Next_month})
                          </label>
                        </div>
                        <div>
                          <input
                            type="radio"
                            name="filter"
                            value="Next_2_months"
                            onChange={handleRadioChange}
                            disabled={count.Next_2_months == "0" ? true : false}
                          />
                          <label className="radio-label">
                            Next 2 Months({count.Next_2_months})
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <label className="col-sm-4 col-form-head">Date</label>
                  <div className="col-md-6 mb-4">
                    <Calendar
                      id="registrationDate"
                      name="registrationDate"
                      className="form-control input-height"
                      format="dd/MM/yyyy"
                      selectedDate={
                        selectedDateControl ? selectedDateControl : new Date()
                      }
                      onDateChange={(date: Date, field: string) =>
                        handleDateChange(date, field)
                      }
                      minDate={new Date()}
                    />
                  </div>
                  <div className="row pl-3">
                    <div className="col-md-6">
                      <label className="col-form-head">From Time</label>
                      <input
                        type="time"
                        value={fromTime}
                        name="fromTime"
                        className="form-control input-height"
                        onChange={onTimeChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="col-form-head">To Time</label>
                      <input
                        type="time"
                        value={toTime}
                        name="toTime"
                        className="form-control input-height"
                        onChange={onTimeChange}
                      />
                    </div>
                  </div>
                  <div className="row text-left pl-4 mb-5">
                    {timeValidation == true ? (
                      <label className="error-message">
                        From time greater than To time
                      </label>
                    ) : null}
                    {timeValidationCurrentTime == true ? (
                      <label className="error-message">
                        Meeting can't be scheduled for past time
                      </label>
                    ) : null}
                  </div>
                  <label className="col-sm-4 col-form-head">Meeting Link</label>
                  <div className="col-md-12 mb-4">
                    <input
                      type="text"
                      id="meeting_link"
                      name="meeting_link"
                      className="form-control input-height"
                      onChange={(e: any) => setMeetingLink(e.target.value)}
                    />
                    <div className="row text-left pl-3">
                      {linkValidation == true ? (
                        <label className="error-message">
                          Meeting Link is required
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <label
                    style={{
                      color: "#333333",
                      fontFamily: "Open Sans",
                      fontSize: "15px",
                      fontWeight: "600",
                      letterSpacing: "0.08px",
                      marginLeft: "14px",
                      marginTop: "1px",
                    }}
                  >
                    Meeting Comment
                  </label>
                  <div className="col-md-12 mb-4">
                    <textarea
                      style={{ height: "100px" }}
                      id="meeting_link"
                      name="meeting_link"
                      className="form-control"
                      onChange={(e: any) => setMeetingComment(e.target.value)}
                    />
                  </div>
                  <div className="row pl-3">
                    <div className="col-md-6">
                      <button
                        className="btn btn-sisfs btn-transparent-primary full-width"
                        data-dismiss="modal"
                        onClick={() => (
                          setTimeValidation(false),
                          setTimeValidationCurrentTime(false),
                          setLinkValidation(false)
                        )}
                      >
                        CANCEL
                      </button>
                    </div>
                    <div className="col-md-6">
                      <button
                        className={
                          isDisabled
                            ? "tertiary  btn-yellow-primary full-width custom-disabled"
                            : "tertiary  btn-yellow-primary full-width"
                        }
                        disabled={isDisabled}
                        onClick={quickMeetingSubmit}
                      >
                        SCHEDULE  
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* Edit meeting modal  */}
          <div
            className="modal fade"
            id="editMeeting"
            role="dialog"
            aria-labelledby="exampleModalLabel1"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-slideout modal-md"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header modal-header-bottom pr-5">
                  <button
                    type="button"
                    className="close"
                    onClick={() => (
                      setIsEditable(true),
                      setTimeValidation(false),
                      setTimeValidationCurrentTime(false)
                    )}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body pl-5 pr-5 pt-5">
                  <div className="popup-head mb-5 ml-3">
                    <div className="main-heading">Reschedule Meeting</div>
                  </div>
                  <label className="col-sm-4 col-form-head">Meeting Type</label>
                  <div className="col-md-12 mb-4">
                    <input
                      type="text"
                      disabled
                      className="form-control input-height"
                      aria-label="Select Entity Type"
                      value={
                        editMeeting.meeting_type == "1"
                          ? "Incubator Selection"
                          : "Performance Review Meeting"
                      }
                    />
                  </div>

                  <fieldset disabled={isEditable}>
                    <div className="col-md-6 mb-4">
                      <label className="col-form-head">Date</label>
                      <Calendar
                        id="registrationDate"
                        name="registrationDate"
                        className="form-control input-height"
                        format="dd/MM/yyyy"
                        selectedDate={
                          editMeeting.meeting_date
                            ? new Date(editMeeting.meeting_date)
                            : new Date()
                        }
                        onDateChange={(date: Date, field: string) =>
                          handleEditDateChange(date, field)
                        }
                      // minDate={new Date()}
                      />
                    </div>
                    <div className="row pl-3">
                      {" "}
                      <div className="col-md-6">
                        {" "}
                        <label className="col-form-head">From Time</label>{" "}
                        <input
                          type="time"
                          value={editMeeting.meeting_from_time}
                          name="fromTime"
                          className="form-control input-height"
                          onChange={onEditTimeChange}
                        />{" "}
                      </div>{" "}
                      <div className="col-md-6">
                        {" "}
                        <label className="col-form-head">To Time</label>{" "}
                        <input
                          type="time"
                          value={editMeeting.meeting_to_time}
                          name="toTime"
                          className="form-control input-height"
                          onChange={onEditTimeChange}
                        />{" "}
                      </div>{" "}
                    </div>{" "}
                    <div className="row text-left pl-4 mb-4">
                      {" "}
                      {timeValidation == true ? (
                        <label className="error-message">
                          {" "}
                          From time greater than To time
                        </label>
                      ) : null}
                      {timeValidationCurrentTime == true ? (
                        <label className="error-message">
                          {" "}
                          Meeting can't be scheduled for past time
                        </label>
                      ) : null}
                    </div>{" "}
                    <label className="col-sm-4 col-form-head">
                      Meeting Link
                    </label>{" "}
                    <div className="col-md-12">
                      {" "}
                      <input
                        type="text"
                        name="MeetingLink"
                        className="form-control input-height"
                        aria-label="Select Entity Type"
                        value={editMeeting.link}
                        onChange={onEditTimeChange}
                      />{" "}
                    </div>{" "}
                    <div className="row text-left pl-4 mb-4">
                      {" "}
                      {editMeeting.link == "" ? (
                        <label className="error-message">
                          {" "}
                          Meeting Link is required
                        </label>
                      ) : null}
                    </div>{" "}
                    <label
                      style={{
                        color: "#333333",
                        fontFamily: "Open Sans",
                        fontSize: "15px",
                        fontWeight: "600",
                        letterSpacing: "0.08px",
                        marginLeft: "14px",
                        marginTop: "1px",
                      }}
                    >
                      {" "}
                      Meeting Comment
                    </label>{" "}
                    <div className="col-md-12">
                      {" "}
                      <textarea
                        style={{ height: "100px" }}
                        id="meeting_Comment"
                        name="meeting_Comment"
                        className="form-control"
                        value={editMeeting.comment}
                        onChange={onEditTimeChange}
                      />{" "}
                    </div>{" "}
                    <div className="row text-left pl-4 mb-4">
                      {" "}
                      {editMeeting.comment == "" ? (
                        <label className="error-message">
                          {" "}
                          Comment is Required
                        </label>
                      ) : null}
                    </div>
                  </fieldset>

                  <div className="row pl-3">
                    <div className="col-md-6">
                      <button
                        className="btn btn-sisfs btn-transparent-primary full-width"
                        onClick={() => setIsEditable(false)}
                      >
                        EDIT
                      </button>
                    </div>
                    <div className="col-md-6">
                      <button
                        className={
                          isDisabled
                            ? "tertiary  btn-yellow-primary full-width custom-disabled"
                            : "tertiary  btn-yellow-primary full-width"
                        }
                        disabled={isDisabled}
                        onClick={meetingReschedule}
                      >
                        RESCHEDULE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* quick meeting popop */}
          <div
            className="modal fade"
            id="editPastMeeting"
            role="dialog"
            aria-labelledby="exampleModalLabel1"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-slideout modal-md"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header modal-header-bottom pr-5">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => setIsEditable(true)}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                {/* {console.log(editCountMeeting, '***************')} */}
                <div className="modal-body pl-5 pr-5 pt-5">
                  <div className="popup-head mb-5 ml-3">
                    <div className="main-heading">
                      {editCountMeeting.meeting_type == "1"
                        ? "Incubator Selection"
                        : "Performance Review Meeting"}
                    </div>
                  </div>
                  <div className="row pl-3 mb-5">
                    <div className="col-6">
                    <label className="col-form-head">Date</label>
                    <fieldset disabled={true}>
                    <div className="">
                      <Calendar
                        id="registrationDate"
                        name="registrationDate"
                        className="form-control input-height"
                        format="dd/MM/yyyy"
                        selectedDate={
                          editCountMeeting.meeting_date
                            ? (moment(editCountMeeting.meeting_date).format("DD-MM-YYYY"))
                            : new Date()
                        }
                        onDateChange={(date: Date, field: string) =>
                          handleEditDateChange(date, field)
                        }
                      />
                    </div>
                  </fieldset>
                    </div>
                    <div className="col-6">
                    <label className="col-form-head">Meeting Number</label>
                    <input
                        disabled={isEditable}
                        type="text"
                        name="meetingNo"
                        maxLength={3}
                        value={editCountMeeting.meeting_number}
                        onChange={(e)=>{handleChange(e)}}
                        className="form-control input-height"
                      />
                    </div>
                  </div>
                  {/* <label className="col-sm-4 col-form-head">Date</label>
                  <fieldset disabled={true}>
                    <div className="col-md-6 mb-4">
                      <Calendar
                        id="registrationDate"
                        name="registrationDate"
                        className="form-control input-height"
                        format="dd/MM/yyyy"
                        selectedDate={
                          editCountMeeting.meeting_date
                            ? (moment(editCountMeeting.meeting_date).format("DD-MM-YYYY"))
                            : new Date()
                        }
                        onDateChange={(date: Date, field: string) =>
                          handleEditDateChange(date, field)
                        }
                      />
                    </div>
                  </fieldset> */}
                  <div className="row pl-3 mb-5">
                    <div className="col-md-6">
                      <label className="col-form-head">From Time</label>
                      <input
                        type="time"
                        disabled
                        value={editCountMeeting.meeting_from_time}
                        name="fromTime"
                        className="form-control input-height"
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="col-form-head">To Time</label>
                      <input
                        type="time"
                        disabled
                        name="toTime"
                        value={editCountMeeting.meeting_to_time}
                        className="form-control input-height"
                      />
                    </div>
                  </div>
                  <fieldset disabled={isEditable}>
                    <div className="col-md-12 mb-5">
                      <label className="col-form-head">
                        No. of Incubators Evaluated
                      </label>
                      <input
                        type="number"
                        disabled
                        value={editCountMeeting.incubator_evaluated}
                        name="incubatorEvaluated"
                        className="form-control input-height"
                        onChange={onEditTimeChange}
                      />
                    </div>
                    {editCountMeeting.meeting_type == "1" ? (
                      <div className="col-md-12 mb-5">
                        <label className="col-form-head">
                          No. of Incubators Selected
                        </label>
                        <input
                          type="number"
                          disabled
                          value={editCountMeeting.incubator_selected}
                          name="incubatorSelected"
                          className="form-control input-height"
                          onChange={onEditTimeChange}
                        />
                      </div>
                    ) : null}

                    <div className="col-md-12 mb-4">
                      <label className="col-form-head">Funds Approved</label>
                      <input
                        type="number"
                        disabled
                        value={editCountMeeting.funds_approved}
                        name="fundsApproved"
                        className="form-control input-height"
                        onChange={onEditTimeChange}
                      />
                    </div>

                    <div className="col-md-12">
                      <div className="">
                        {/* <input type="file" className="form-control-file btn btn-sisfs btn-transparent-primary" id="exampleFormControlFile1" /> */}
                        <button
                          className="btn btn-sisfs btn-transparent-primary"
                          onClick={() => setOpenUploadBlock(true)}
                          disabled={false}
                        >
                          Upload Meeting Notes
                        </button>
                        {(openUploadBlock || uploadedFile.length != 0) && (
                          <>
                            <span
                              className="close"
                              onClick={() => setOpenUploadBlock(false)}
                            >
                              <i className="fa fa-close"></i>
                            </span>
                            <FileUploader
                              key={"meeting_file" + meetingIdFilter}
                              id={"meeting_file" + meetingIdFilter}
                              information="Drag & Drop the file or Browse"
                              fileType="application/pdf"
                              buttonLabel="Browse"
                              multiple={false}
                              enablePreview
                              IconComponent={() => (
                                <span className="fa fa-cloud-upload"></span>
                              )}
                              removeIconClass=""
                              existingFiles={uploadedFile}
                              onUploadFinish={(files: Array<File>) => {
                                onFileUpload(files);
                              }}
                              onFileDelete={(deletedFile: File) => onFileDelete()}
                            />
                            <small>Supported file format - PDF only</small>
                          </>
                        )}
                      </div>
                    </div>
                  </fieldset>
                  {/* {meetingPath != "" ? (
                  <>
                    <label className="col-form-head mt-5 pl-4">
                      Uploaded Document
                    </label>
                    <br />
                    <div className="doc-div  text-center">
                      <span className="view-all">
                        <a
                          href={File_node_url + meetingPath}
                          className="view-all"
                          target="_blank"
                        >
                          View
                        </a>
                      </span>
                      &nbsp;&nbsp;
                      <span className="view-all" onClick={onFileDelete}>
                        Remove
                      </span>
                      <br />
                      <iframe scrolling="no" src={meetingPath} />
                    </div>
                  </>
                ) : null} */}
                </div>
                <div className="modal-footer">
                  <div className="row modal-row">
                    <div className="col-md-6 text-center">
                      <button
                        className="btn btn-sisfs btn-yellow-primary full-width"
                        onClick={() => setIsEditable(false)}
                      >
                        EDIT
                      </button>
                    </div>
                    <div className="col-md-6 text-left">
                      <button
                        className="tertiary  btn-transparent-primary full-width"
                        onClick={saveMeetingInfo}
                        data-dismiss="modal"
                      >
                        SAVE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {showIncubatorSuccessModal && (
            // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
            // <>
            //     <div className="modal-backdrop fade show"></div>

            //     <div
            //         className="modal align-middle modal-sisfs d-block"
            //         id="FormSubmissionSuccess"
            //     >
            //         <div className="modal-dialog modal-dialog-centered modal-md">
            //             <div className="modal-content">
            //                 <div className="modal-body text-center">
            //                     <i className="fa fa-check-circle-o"></i>
            //                     <h3 className="modal-message">Thank You!</h3>
            //                     <div className="modal-text">
            //                         Your Meeting Has been Scheduled
            //             </div>
            //                     <div className="modal-action">
            //                         <button className="btn btn-sisfs btn-yellow-primary" onClick={dismissModal}>
            //                             Ok!!
            //             </button>
            //                     </div>
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            // </>
            <ConfirmationDialog
              headerText={dialogData.header}
              bodyText={dialogData.text}
              onDialogClose={dismissModal}
            />
          )}
          {showMeetingSuccessModal && (
            // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
            // <>
            //     <div className="modal-backdrop fade show"></div>

            //     <div
            //         className="modal align-middle modal-sisfs d-block"
            //         id="FormSubmissionSuccess"
            //     >
            //         <div className="modal-dialog modal-dialog-centered modal-md">
            //             <div className="modal-content">
            //                 <div className="modal-body text-center">
            //                     <i className="fa fa-check-circle-o"></i>
            //                     <h3 className="modal-message">Thank You!</h3>
            //                     <div className="modal-text">
            //                         Your Meeting Has been Scheduled
            //             </div>
            //                     <div className="modal-action">
            //                         <button className="btn btn-sisfs btn-yellow-primary" onClick={dismissModal}>
            //                             Ok!!
            //             </button>
            //                     </div>
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            // </>
            <ConfirmationDialog
              headerText={dialogData.header}
              bodyText={dialogData.text}
              onDialogClose={dismissMeetingModal}
            />
          )}
          {showIncubatorFailureModal && (
            // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
            // <>
            //     <div className="modal-backdrop fade show"></div>
            //     <div
            //         className="modal align-middle modal-sisfs d-block"
            //         id="FormSubmissionError"
            //     >
            //         <div className="modal-dialog modal-dialog-centered modal-md">
            //             <div className="modal-content">
            //                 <div className="modal-body text-center">
            //                     <img
            //                         src={error}
            //                         className="search mb-2"
            //                         alt="search"

            //                     />
            //                     <h3 className="modal-message">
            //                         {failDialogData.header}
            //                     </h3>
            //                     <h3 className="modal-text">
            //                         {failDialogData.text}
            //                     </h3>
            //                     <div className="modal-action">
            //                         <button className="btn btn-sisfs btn-yellow-primary" onClick={() => setShowIncubatorFailureModal(false)}>
            //                             OK!
            //           </button>
            //                     </div>
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            // </>
            <ConfirmationDialog
              headerText={failDialogData.header}
              bodyText={failDialogData.text}
              onDialogClose={() => setShowIncubatorFailureModal(false)}
              iconClass="test"
            />
          )}
          {/* {showConfirmationBox && (
            <>
              <div className="modal-backdrop fade show"></div>
              <div
                className="modal align-middle modal-sisfs d-block"
                id="FormSubmissionError"
              >
                <div className="modal-dialog modal-dialog-centered modal-md">
                  <div className="modal-content">
                    <div className="modal-body text-center">
                      
                      <h3 className="modal-message mt-2">
                        {confirmationDialogData.header}
                      </h3>
                      <h3 className="modal-text">
                        {confirmationDialogData.text}
                      </h3>
                      <div className="modal-action mt-2">
                        <button
                          className="btn btn-sisfs btn-transparent-primary"
                          onClick={() => setShowConfirmationBox(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn btn-sisfs btn-yellow-primary ml-2"
                          onClick={finalSchedule}
                        >
                          Schedule Anyway
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )} */}
          {showAddConfirmationBox && (
            <>
              <div className="modal-backdrop fade show"></div>
              <div
                className="modal align-middle modal-sisfs d-block"
                id="FormSubmissionError"
              >
                <div className="modal-dialog modal-dialog-centered modal-md">
                  <div className="modal-content">
                    <div className="modal-body text-center">
                      {/* <img
               src={error}
               className="search mb-2"
               alt="search"

             /> */}
                      <h3 className="modal-message mt-2">
                        {confirmationDialogData.header}
                      </h3>
                      <h3 className="modal-text">
                        {confirmationDialogData.text}
                      </h3>
                      <div className="modal-action mt-5">
                        <button
                          className="btn btn-sisfs btn-transparent-primary"
                          onClick={() => setShowAddConfirmationBox(false)}
                        >
                          OK
                        </button>
                        {/* <button
                        className="btn btn-sisfs btn-yellow-primary ml-2"
                        onClick={finalAddIncubator}
                      >
                        Schedule Anyway
                      </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </fieldset>
    </>
  );
}
export default EacReviewDpiit;
