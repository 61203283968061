import { FileDownloadModel } from "../../components/dpiit/incubator/ApplicationGeneralDetails";
import { FileUploadModel, TrancheFileUploadModel } from "../FileUploadModel";

export class TrancheData {
  constructor(public trancheDetail: Array<TrancheDetail>) {
    this.trancheDetail = trancheDetail;
  }
}

export class TrancheDetail {
  constructor(
    public application_id: number,
    public tranch_id: number,
    public tranche_number: string = "",
    public approved_amount: number,
    public funds_requested: any,
    public cash_in_hand: string,
    public tranche_requested_date: string,
    public documents: Documents,
    public tranches_status_description: string,
    public interest_accumulated_on_funds: number,
    public fund_released: number,
    public date_of_tranche_release: string,
    public tranches_status_label: string,
    public comments: string,
    public date_of_release: string,
    public approved_amount_date: string,
    public tranche_status_comment: string,
    public isNextDisbursed: boolean,
    public isPrevDisbursed: boolean,
    public proofDisbursementFlag: boolean,
    public sanction_order: string,
    public sanction_order_location: string,
    public total_approved_amt: any,
    public total_management_amount: any,
    public application_index: any,
    public no_of_tranches: any,
    public Reduced_management_fee: any,
    public Actual_management_fee: any,
    public incubator_application_no: any
  ) {
    this.application_id = application_id;
    this.tranch_id = tranch_id;
    this.tranche_number = tranche_number;
    this.approved_amount = approved_amount;
    this.cash_in_hand = cash_in_hand;
    this.funds_requested = funds_requested;
    this.tranche_requested_date = tranche_requested_date;
    this.documents = documents;
    this.tranches_status_description = tranches_status_description;
    this.interest_accumulated_on_funds = interest_accumulated_on_funds;
    this.fund_released = fund_released;
    this.date_of_tranche_release = date_of_tranche_release;
    this.tranches_status_label = tranches_status_label;
    this.comments = comments;
    this.date_of_release = date_of_release;
    this.approved_amount_date = approved_amount_date;
    this.tranche_status_comment = tranche_status_comment;
    this.isNextDisbursed = isNextDisbursed;
    this.isPrevDisbursed = isPrevDisbursed;
    this.proofDisbursementFlag = proofDisbursementFlag;
    this.sanction_order = sanction_order;
    this.sanction_order_location = sanction_order_location;
    this.total_approved_amt = total_approved_amt;
    this.total_management_amount = total_management_amount;
    this.application_index = application_index;
    this.no_of_tranches = no_of_tranches;
    this.Reduced_management_fee = Reduced_management_fee;
    this.Actual_management_fee = Actual_management_fee;
    this.incubator_application_no = incubator_application_no;
  }
}

export class Documents {
  constructor(
    public quarterly_reports: Array<FileUploadModelTranche>,
    public supporting_documents: Array<FileUploadModelTranche>,
    public proof_of_disbursement: Array<FileUploadModelTranche>
  ) {
    this.quarterly_reports = quarterly_reports;
    this.supporting_documents = supporting_documents;
    this.proof_of_disbursement = proof_of_disbursement;
  }
}

export class FileUploadModelTranche {
  constructor(
    public file_id: number,
    public file_type_id: number,
    public file: File,
    public ext: string,
    public fileName: string
  ) {
    this.file_id = file_id;
    this.file_type_id = file_type_id;
    this.file = file;
    this.ext = ext;
    this.fileName = fileName;
  }
}

export class TrancheRequest {
  constructor(
    public application_id: number,
    public tranche_id: number,
    public tranche_number: string = "",
    public amount_required: number,
    public cash_in_hand: string,
    public quarterly_report: Array<TrancheFileUploadModel>,
    public supporting_documents: Array<TrancheFileUploadModel>,
    public incubator_application_no: any
  ) {
    this.application_id = application_id;
    this.tranche_id = tranche_id;
    this.tranche_number = tranche_number;
    this.amount_required = amount_required;
    this.cash_in_hand = cash_in_hand;
    this.quarterly_report = quarterly_report;
    this.supporting_documents = supporting_documents;
    this.incubator_application_no = incubator_application_no;
  }
}

export class AddTranche {
  constructor(
    public tranche_id: number,
    public tranche_number: string,
    public application_id: number,
    public amount_approved: number
  ) {
    this.tranche_id = tranche_id;
    this.tranche_number = tranche_number;
    this.application_id = application_id;
    this.amount_approved = amount_approved;
  }
}

export class UpdateTranche {
  constructor(
    public tranche_id: number,
    public tranche_status_id: number,
    public application_id: number,
    public approved_amount: number,
    public fund_released: number,
    public date_of_release: string,
    public tranch_comment: string,
    public funds_requested: number
  ) {
    this.tranche_id = tranche_id;
    this.tranche_status_id = tranche_status_id;
    this.application_id = application_id;
    this.approved_amount = approved_amount;
    this.fund_released = fund_released;
    this.date_of_release = date_of_release;
    this.tranch_comment = tranch_comment;
    this.funds_requested = funds_requested;
  }
}
