import React, {Fragment, useEffect, useState} from "react"
import {get, post} from "../../../effects/api/api.common";
import {
    PROGRESS_DATA_BY_PROGRESS_ID,
    PROGRESS_OVERVIEW_EDIT_FORM,
    PROGRESS_PERCENTAGE,
    PROGRESS_STAGES,
    PROGRESS_OVERVIEW_FILE_UPLOAD
} from "../../../constants/api";
import FileUploader from '@amodv/react-file-uploader';
import ConfirmationDialog from "../../common/controls/ConfirmationDialog";
import pdf from "../../../images/PDF.svg";
import { getSessionStorageValue } from "../../../effects/utils/session-storage";

interface ProgessStartupProps {
    progressPercentages:any;
    progressIds:any;
    progressStagess:any;
}
const ProgressEditInfoModal = (props: ProgessStartupProps) => {
    const {progressPercentages,progressIds,progressStagess} = props;
    const [progressId, setProgressId] = useState('')
    const [progressPercentage, setProgressPercentage] = useState([])
    const [progressStages, setProgressStages] = useState([])
    const [validationFlag, setValidationFlag] = useState({
        stage_id: false,
        progress_in_percentage: false,
        progress_in_text: false,
        external_fund_raised_till_date: false,
        external_fund_raised_latest_quarter: false,
        turnover_till_date: false,
        turnover_latest_quarter: false,
        gst_filled_till_date: false,
        gst_filled_latest_quarter: false,
        no_products_on_gem_portal_till_date: false,
        no_products_on_gem_portal_latest_quarter: false,
        no_ip_regstr_req_till_date: false,
        no_ip_regstr_req_latest_quarter: false,
        other_comment: false,
        turnover: false
    })
    const [progressFormData, setProgressFormData] = useState({
        stage_id: undefined,
        progress_in_percentage: undefined,
        progress_in_text: undefined,
        external_fund_raised_till_date: "",
        external_fund_raised_latest_quarter: "",
        turnover_till_date: "",
        turnover_latest_quarter: "",
        gst_filled_till_date: "",
        gst_filled_latest_quarter: "",
        no_products_on_gem_portal_till_date: undefined,
        no_products_on_gem_portal_latest_quarter: undefined,
        no_ip_regstr_req_till_date: undefined,
        no_ip_regstr_req_latest_quarter: undefined,
        other_comment: undefined,
        
        turnover: undefined
    })
    const [startupApplicationID,setStartupApplicationID]=useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')

    const [jobsTillDate, setJobsTillDate] = useState(0)
    const [jobsLatestDate, setJobslatestDate] = useState(0)

    const[productsTillDate,setProductsTillDate]=useState(0)
    const[productsLatestDate,setProductsLatestDate]=useState(0)
    const[ipregistationTillDate,setIPregistationTillDate]=useState(0)
    const[ipregistationLatestDate,setIPregistationLatestDate]=useState(0)
    const [supportDoc,setSupportDoc] = useState<any>([])
    const [uploadedFile, setUploadedFile] = useState<Array<File>>([]);
    const [approveBy,setApproveBy] = useState<any>('')
    const [approveDatae,setApproveDate] = useState<any>('')
    const [createdAt,setCreatedAt] = useState<any>('')
    const [updatedAt,setupdatedAt] = useState<any>('')

    useEffect(()=>{
        getProgressDataByProgressId()
    },[])
    useEffect(() => {
        
        setProgressStages(props.progressStagess)
        // setProgressPercentage(props.progressPercentage)
        // console.log(progressPercentage,"22222","progreeesss")
        // getProgressPercentage()
        // getProgressStages()
    }, [props.progressPercentages,props.progressStagess,props.progressIds])
    const handleJobsTillDate = (incOrDec: number) => {
        if(jobsTillDate===0) return
        const localJobs = jobsTillDate + incOrDec
        setJobsTillDate(localJobs)
    }

    const handleJobslatestQuarter = (incOrDec: number) => {
        if(jobsTillDate===0) return
        const localLatestJobs = jobsLatestDate + incOrDec
        setJobslatestDate(localLatestJobs)
    }


    
    const myNumberWithTwoDecimalPlace=(myNumber: string)=>{
        return  parseFloat(myNumber).toFixed(2);
     }
    

    const handleChange = (
        evt: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const name = evt.target.name;
        const value = evt.target.value.replace(/[^0-9]/ig, '').toString().split(".").map((el,i)=>i?el.split("").slice(0,2).join(""):el).join(".");
        //console.log('event target', name, value)

        setProgressFormData((values) => ({
            ...values,
            [name]: value,
        }));
        //console.log(setProgressFormData);
    }

    const handleChangeTextField = (
        evt: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const name = evt.target.name;
        const value = evt.target.value;
        //console.log('event target', name, value)

        setProgressFormData((values) => ({
            ...values,
            [name]: value,
        }));

    }

    const handelValidate = (
        evt: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>
    ) => {
        // var reg=d\+\.\d\d$
        const name = evt.target.name;
        const t = evt.target.value;
        if(t!=="")
        {

        
        const value = t.toString().split(".")[1]?.length || 0;
        //console.log(validationFlag.external_fund_raised_till_date)
        if(value>2)
        {
            setValidationFlag((values) => ({
                ...values,
                [name]: true,
            }));
        }
        else{
            setValidationFlag((values) => ({
                ...values,
                [name]: false,
            }));
        }
    }
}
     

    const [fileToSend,setFileToSend] = useState<any[]>([])
    const getProgressDataByProgressId = () => {
        try {
            setLoading(true)
            post(PROGRESS_DATA_BY_PROGRESS_ID, {id: progressId}).then((response: any) => {
                // console.log(response.data.message[0],"respppppppppp")
                if (!response || !response.data) return;
                if (response?.data?.result) {
                    setProgressFormData(response.data.message[0])
                    setJobsTillDate(response.data.message[0].people_employed_till_date)
                    setJobslatestDate(response.data.message[0].people_employed_latest_quarter)
                    setProductsTillDate(response.data.message[0].no_products_on_gem_portal_till_date)
                    setProductsLatestDate(response.data.message[0].no_products_on_gem_portal_latest_quarter)
                    setIPregistationTillDate(response.data.message[0].no_ip_regstr_req_till_date)
                    setIPregistationLatestDate(response.data.message[0].no_ip_regstr_req_latest_quarter)
                    setSupportDoc(response.data.message[1].supporting_documents)
                    // const apiFiles = response.data.message[1].supporting_documents.map((res:any)=>{
                    //     // console.log(res,"respppppppppp")
                    //     return res;
                    // })
                    // setFileToSend(apiFiles)
                    //console.log({progressFormData})
                    // setJobs(response.data.data.jobs_created)
                    setStartupApplicationID(response.data.message[0].startup_application_id)
                    //console.log('data found for this progress id', response.data)
                    setApproveBy(response.data.message[0].approve_by);
                    setApproveDate(response.data.message[0].approve_date);
                    setCreatedAt(response.data.message[0].created_at);
                    setupdatedAt(response.data.message[0].updated_at)
                }
                // console.log(fileToSend,"respppppppppp")
                // getProgressPercentage()
                setProgressPercentage(props.progressPercentages)
            })
        } catch (e) {
            //console.log(e)
        } finally {
            setLoading(false)
        }
    }
    const [editFiles,setEditFiles] = useState<any>('')
    const onFileEditUpload=(files: File[])=>{
        
        //console.log(files)
        let indexes: Array<string> = [];
        // console.log(files,"respppppppppp")
        setUploadedFile(files)
        // setUploadedFile([]);
        setEditFiles(files)
        for (let file of files) {
        //   if (file.type === "application/pdf") {
        //     const formData = new FormData();
        //     // Update the formData object
        //     formData.append("file", file, file.name);
        //     // formData.append('file_type', type);
        //     formData.append("startup_progress_overview_id", progressId);
        //     formData.append("startup_application_id", startupApplicationID);

        //     post(PROGRESS_OVERVIEW_FILE_UPLOAD, formData).then((response: any) => {
        //       //console.log(response.data)
        //       if (!response.data) return;
        //       if (response.data.result == 'success') {
        //         //console.log("response ")
        //         //console.log(response.data.data.file)
        //         setSuccessMessage('Account Document Uploaded Successfully..!')
        //         // setUploadedFile([response.data.data.file])
        //         // setShowSuccessModal(true);
        //       } else {
        //         setErrorMessage('oops! something went wrong')
        //         // setShowFailureAccountModal(true);
        //         // setShowIncubatorFailureModal(true);
        //       }
        //     });
        //   } else {
        //     indexes.push(file.name);
        //   }
        }
        //console.log(uploadedFile)
    }
    const [fileWithLocation,setFileWithLocation] = useState<any>('')
    const handleProgressFormSubmit = () => {
        //console.log('progress Form Data', progressFormData)
        setErrorMessage('')
        setSuccessMessage('')
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user)
        const formData = new FormData();
        for (let file of editFiles) {
            formData.append("file", file?file:"")
        }
        if(startupApplicationID != undefined){
            formData.append("people_employed_till_date",jobsTillDate.toString());
        formData.append("people_employed_latest_quarter",jobsLatestDate.toString());
        formData.append("no_ip_regstr_req_latest_quarter",ipregistationLatestDate.toString());
        formData.append("no_ip_regstr_req_till_date",ipregistationTillDate.toString());
        formData.append("no_products_on_gem_portal_latest_quarter",ipregistationLatestDate.toString());
        formData.append("no_products_on_gem_portal_till_date",ipregistationTillDate.toString());
        formData.append("id",progressId);
        formData.append("other_comment",JSON.stringify(progressFormData.other_comment))
        formData.append("progress_in_text",JSON.stringify(progressFormData.progress_in_text))
        formData.append("stage_id",JSON.stringify(progressFormData.stage_id))
        formData.append("progress_in_percentage",JSON.stringify(progressFormData.progress_in_percentage))
        formData.append("external_fund_raised_till_date",progressFormData.external_fund_raised_till_date)
        formData.append("external_fund_raised_latest_quarter",progressFormData.external_fund_raised_latest_quarter)
        formData.append("turnover_till_date",progressFormData.turnover_till_date)
        formData.append("turnover_latest_quarter",progressFormData.turnover_latest_quarter)
        formData.append("gst_filled_till_date",progressFormData.gst_filled_till_date)
        formData.append("gst_filled_latest_quarter",progressFormData.gst_filled_latest_quarter)
        formData.append("approve_by",approveBy);
        formData.append("approve_date",approveDatae);
        formData.append("created_at",createdAt);
        formData.append("updated_at",updatedAt);
        formData.append("startup_application_id",startupApplicationID);
        formData.append("startup_user_id",userInfo.id);
        

        // stage_id
        // progress_in_percentage
        // progress_in_text
        // external_fund_raised_till_date
        // external_fund_raised_latest_quarter
        // turnover_till_date
        // turnover_latest_quarter
        // gst_filled_till_date
        // gst_filled_latest_quarter
        // other_comment
        // const payload = {
        //     id: progressId,
        //     ...progressFormData,
        //     ...formData,
        //     people_employed_till_date: jobsTillDate.toString(),
        //     people_employed_latest_quarter:jobsLatestDate.toString(),
        //     no_ip_regstr_req_latest_quarter: ipregistationLatestDate.toString(),
        //     no_ip_regstr_req_till_date: ipregistationTillDate.toString(),
        //     no_products_on_gem_portal_latest_quarter: ipregistationLatestDate.toString(),
        //     no_products_on_gem_portal_till_date: ipregistationTillDate.toString(),

        // }
        
        if (validationFlag.external_fund_raised_till_date==true || validationFlag.external_fund_raised_latest_quarter==true ||validationFlag.turnover_till_date|| validationFlag.turnover_latest_quarter || validationFlag.gst_filled_till_date || validationFlag.gst_filled_latest_quarter ) {
            setErrorMessage('field should have upto two decimal point')
            return
        }
    //console.log({formData})
        //@ts-ignore
        // if (!formData.stage_id) {
        //     setErrorMessage('Please select Stages')
        //     return
        // }
        // //@ts-ignore
        // if (!formData.progress_in_percentage) {
        //     setErrorMessage('Please select progress percentage')
        //     return
        // }
        // //@ts-ignore
        // if (!formData.progress_in_text) {
        //     setErrorMessage('Please add some text in progress text')
        //     return
        // }

        // //@ts-ignore
        // if (!formData.fund_raised) {
        //     setErrorMessage('Please enter fund raised')
        //     return
        // }
        // //@ts-ignore
        // if (!formData.jobs_created) {
        //     setErrorMessage('Please enter Jobs created')
        //     return
        // }
        // //@ts-ignore
        // if (!formData.turnover) {
        //     setErrorMessage('Please enter turnover')
        //     return
        // }

        try {
            setLoading(true)
            post(PROGRESS_OVERVIEW_EDIT_FORM, formData).then((response: any) => {
                if (!response || !response.data) return;
                if (response?.data?.result) {
                    //console.log('stages', response.data)
                    setSuccessMessage('Data Updated successfully')
                } else {
                    setErrorMessage('Data Shared Failed')
                }
            })
        } catch (e:any) {
            setErrorMessage(e.message)
        } finally {
            setLoading(false)
        }
        }
    }
    

    useEffect(() => {
        setProgressId(props.progressIds)
    }, [props.progressIds,progressId])

    

    // const getProgressPercentage = () => {
    //     get(PROGRESS_PERCENTAGE).then((response: any) => {
    //         if (!response || !response.data) return;
    //         if (response?.data?.result === 'success') {
    //             //console.log('percentage', response.data)
    //             setProgressPercentage(response.data.data)
    //         } else {
    //             setProgressPercentage([])
    //         }

    //     })
    // }

    // const getProgressStages = () => {
    //     get(PROGRESS_STAGES).then((response: any) => {
    //         if (!response || !response.data) return;
    //         if (response?.data?.result === 'success') {
    //             //console.log('stages', response.data)
    //             setProgressStages(response.data.data)
    //         } else {
    //             setProgressStages([])
    //         }
    //         getProgressStages()
    //     })
    // }

    return (
        <Fragment>
            <div
                className="modal fade"
                id="progressEditInfoModal"
                role="dialog"
                aria-labelledby="progressEditInfoModal"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-slideout modal-md"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header modal-header-bottom pr-5">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body pl-4 pr-4">
                            <div className="popup-head mb-5 ml-3">
                                <div className="main-heading">Share Progress Edit Info</div>
                            </div>

                            <div className="col-md-12 mb-4">
                                <label className="col-form-head">
                                    Choose a stage
                                </label>

                                <div className="select-custom">
                                    <select
                                        onChange={handleChange}
                                        className="form-control input-height"
                                        aria-label="Select Stage"
                                        id="edit_stage_id"
                                        name="stage_id"
                                        value={progressFormData?.stage_id}
                                    >
                                        <option selected>Please select stage</option>
                                        {progressStages.length > 0 && progressStages.map((progress: any, index: number) => {
                                            return (
                                                <option value={progress.id} key={index}>{progress.name}</option>
                                            )
                                        })}


                                    </select>
                                </div>
                            </div>

                            <div className="col-md-12 mb-4">
                                <label className="col-form-head">
                                    Progress of: Proof of concept
                                </label>

                                <div className="select-custom">
                                    <select
                                        onChange={handleChange}
                                        className="form-control input-height"
                                        aria-label="Select progress_in_percentage"
                                        id="progress_in_percentage"
                                        name="progress_in_percentage"
                                        value={progressFormData?.progress_in_percentage}
                                    >
                                        <option selected>Please select Progress percentage</option>
                                        {progressPercentage.length > 0 && progressPercentage.map((progress: any, index: number) => {
                                            return (
                                                <option value={progress.id} key={index}>{progress.name}</option>
                                            )
                                        })}

                                    </select>
                                </div>

                                <textarea
                                    onChange={handleChangeTextField}
                                    rows={5}
                                    placeholder="Progress text..."
                                    name="progress_in_text"
                                    className="form-control mt-3"
                                    value={progressFormData?.progress_in_text}
                                />


                            </div>


                            <div className="col-md-12 mb-4">

                                <label className="col-form-head">
                                    Supporting Documents
                                </label>
                                <br/>

                                <button className="btn btn-sisfs btn-transparent-primary">
                                    Upload
                                </button>
                                {/* <input type="file" className="btn btn-sisfs btn-transparent-primary"/> */}
                                <FileUploader
                                    key={"test1"}
                                    id={"test1"}
                                    // id={UploadTypeEnum.QuarterlyReport}
                                    information="Drag & Drop the file or Browse"
                                    fileType="application/pdf"
                                    buttonLabel="Browse"
                                    multiple={false}
                                    enablePreview
                                    IconComponent={() => (
                                        <span className="fa fa-cloud-upload"></span>
                                    )}
                                    
                                    removeIconClass=""  existingFiles={uploadedFile} 
                                    onUploadFinish={function (files: File[]): void {
                                    
                                              onFileEditUpload(files)
                                        
                                        
                                    } } 
                                    onFileDelete={function (file: File): void {
                                        throw new Error("Function not implemented.");
                                    } }                            // existingFiles={trancheRequest.quarterly_report.map(
                            //   (uploadedFile: TrancheFileUploadModel) => uploadedFile.file
                            // )}
                            // onUploadFinish={(files: Array<File>) => {
                            //   onFileUploadTranche(files, UploadTypeEnum.QuarterlyReport);
                            // }}
                            // onFileDelete={(deletedFile: File) =>
                            //   onFileDeleteTranche(
                            //     deletedFile,
                            //     UploadTypeEnum.QuarterlyReport
                            //   )
                            // }
                          />
                                    
                            
                            {/* <div className="row">
                            {fileToSend.map((item:any)=>
                                    <>
                                        {   item.file_url != null && (
                                    <div className="col-4">
                                    <div className="startup-info-group">
                                        
                                        {item.file_url != null ? (
                                            <div className="document-info document-pdf-icon">
                                                <div className="document-info-name">
                                                    {" "}
                                                    <img src={pdf} alt="SISFS Logo" />
                                                    &nbsp;<br />
                                                    {item.file_url.substr(
                                                        item.file_url.lastIndexOf(
                                                            "/"
                                                        ) + 1,
                                                        item.file_url.length
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            " - "
                                        )}
                                    </div>
                                </div>
                                )}
                                    </>
                                ) 
                                
                            }
                            </div> */}
                            

                            </div>

                            <div className="col-md-12 mb-4">
                                <label className="col-form-head">
                                Cumulative Quantum of External Funding Raised by Startup (post-SISFS selection only) – till date
 (&#8377;)
                                </label>
                                <br/>
                                <input
                                    min={0}
                                    onChange={handleChange}
                                    // type="number"
                                    placeholder="Enter Amount(&#8377;)"
                                    name="external_fund_raised_till_date"
                                    onBlur={handelValidate}
                                    className="form-control input-height mt-3"
                                    value={progressFormData?.external_fund_raised_till_date ?parseFloat(progressFormData?.external_fund_raised_till_date):progressFormData?.external_fund_raised_till_date}
                                />
                                 {validationFlag.external_fund_raised_till_date == true ? <label className="error-message">Please enter upto two decimal point</label> : null}
                            </div>
                            <div className="col-md-12 mb-4">
                                <label className="col-form-head">
                                Cumulative Quantum of External Funding Raised by Startup (post-SSFS selection only) – in latest quarter
                                </label>
                                <br/>
                                <input
                                    min={0}
                                    onChange={handleChange}
                                    // type="number"
                                    placeholder="Enter Amount(&#8377;)"
                                    onBlur={handelValidate}
                                    name="external_fund_raised_latest_quarter"
                                    className="form-control input-height mt-3"
                                    value={progressFormData?.external_fund_raised_latest_quarter ?parseFloat(progressFormData?.external_fund_raised_latest_quarter):progressFormData?.external_fund_raised_latest_quarter}
                                />
                               {validationFlag.external_fund_raised_latest_quarter == true ? <label className="error-message">Please enter upto two decimal point</label> : null}
                            </div>

                            <div className="col-md-12 mb-4">
                                <label className="col-form-head">
                                Cumulative No. of People Employed by the Startup – till date
                                </label>
                                <div className="col-md-12 justify-content-center heightJobsBtn p-0">
                                    {/* <button className="btn btn-sisfs btn-transparent-primary mr-3 mt-3"
                                            onClick={() => handleJobsTillDate(-1)}>
                                        -
                                    </button> */}

                                    <input
                                        min={0}
                                        // type="number"
                                        name="people_employed_till_date"
                                        value={jobsTillDate?jobsTillDate:""}
                                        placeholder="Enter Value"
                                        onChange={(e: any) => setJobsTillDate(parseInt(e.target.value))}
                                        className="form-control input-height mt-3"
                                    />

                                    {/* <button className="btn btn-sisfs btn-transparent-primary ml-3 mt-3"
                                            onClick={() => handleJobsTillDate(1)}>
                                        +
                                    </button> */}
                                </div>
                            </div>
                            <div className="col-md-12 mb-4">
                                <label className="col-form-head">
                                Cumulative No. of People Employed by the Startup – in latest quarter­­
                                </label>
                                <div className="col-md-12 justify-content-center heightJobsBtn p-0">
                                    {/* <button className="btn btn-sisfs btn-transparent-primary mr-3 mt-3"
                                            onClick={() => handleJobslatestQuarter(-1)}>
                                        -
                                    </button> */}

                                    <input
                                        min={0}
                                        // type="number"
                                        name="people_employed_latest_quarter"
                                        value={jobsLatestDate?jobsLatestDate:""}
                                        placeholder="Enter Value"
                                        onChange={(e: any) => setJobslatestDate(parseInt(e.target.value))}
                                        className="form-control input-height mt-3"
                                    />

                                    {/* <button className="btn btn-sisfs btn-transparent-primary ml-3 mt-3"
                                            onClick={() => handleJobslatestQuarter(1)}>
                                        +
                                    </button> */}
                                </div>
                            </div>

                            <div className="col-md-12 mb-4">
                                <label className="col-form-head">
                                Cumulative Turnover of Startup – till date (&#8377;)
                                </label>
                                <br/>
                                <input
                                    min={0}
                                    onChange={handleChange}
                                    // type="number"
                                    onBlur={handelValidate}
                                    placeholder="Enter Amount(&#8377;)"
                                    name="turnover_till_date"
                                    className="form-control input-height mt-3"
                                    value={progressFormData?.turnover_till_date ? parseFloat(progressFormData?.turnover_till_date):progressFormData?.turnover_till_date}
                                />
                                {validationFlag.turnover_till_date == true ? <label className="error-message">Please enter upto two decimal point</label> : null}
                            </div>
                            <div className="col-md-12 mb-4">
                                <label className="col-form-head">
                                Cumulative Turnover of Startup – in latest quarter (&#8377;)
                                </label>
                                <br/>
                                <input
                                    min={0}
                                    onChange={handleChange}
                                    // type="number"
                                    onBlur={handelValidate}
                                    placeholder="Enter Amount(&#8377;)"
                                    name="turnover_latest_quarter"
                                    className="form-control input-height mt-3"
                                    value={progressFormData?.turnover_latest_quarter?parseFloat(progressFormData?.turnover_latest_quarter):progressFormData?.turnover_latest_quarter}
                                />
                                {validationFlag.turnover_latest_quarter == true ? <label className="error-message">Please enter upto two decimal point</label> : null}
                            </div>
                            <div className="col-md-12 mb-4">
                                <label className="col-form-head">
                                Cumulative GST Filed by Startup – till date (&#8377;)
                                </label>
                                <br/>
                                <input
                                    min={0}
                                    onChange={handleChange}
                                    // type="number"
                                    onBlur={handelValidate}
                                    placeholder="Enter Amount(&#8377;)"
                                    name="gst_filled_till_date"
                                    className="form-control input-height mt-3"
                                    value={progressFormData?.gst_filled_till_date ? parseFloat(progressFormData?.gst_filled_till_date):progressFormData?.gst_filled_till_date}
                                />
                                {validationFlag.gst_filled_till_date == true ? <label className="error-message">Please enter upto two decimal point</label> : null}
                            </div>
                            <div className="col-md-12 mb-4">
                                <label className="col-form-head">
                                Cumulative GST Filed by Startup – in latest quarter (&#8377;)
                                </label>
                                <br/>
                                <input
                                    min={0}
                                    onChange={handleChange}
                                    // type="number"
                                    placeholder="Enter Amount(&#8377;)"
                                    onBlur={handelValidate}
                                    name="gst_filled_latest_quarter"
                                    className="form-control input-height mt-3"
                                    value={progressFormData?.gst_filled_latest_quarter  ? parseFloat(progressFormData?.gst_filled_latest_quarter):progressFormData?.gst_filled_latest_quarter}
                                />
                                {validationFlag.gst_filled_latest_quarter == true ? <label className="error-message">Please enter upto two decimal point</label> : null}
                            </div>
                            <div className="col-md-12 mb-4">
                                <label className="col-form-head">
                                Cumulative No. of Products and Services by Startup Live on GeM Portal – till date 
                                </label>
                                <br/>
                                <input
                                        min={0}
                                        // type="number"
                                        name="no_products_on_gem_portal_till_date"
                                        value={productsTillDate?productsTillDate:0}
                                        placeholder="Enter Value"
                                        onChange={(e: any) => setProductsTillDate(parseInt(e.target.value))}
                                        className="form-control input-height mt-3"
                                    />

                            </div>
                            <div className="col-md-12 mb-4">
                                <label className="col-form-head">
                                Cumulative No. of Products and Services by Startup Live on GeM Portal – in latest quarter 
                                </label>
                                <br/>
                                <input
                                        min={0}
                                        // type="number"
                                        name="no_products_on_gem_portal_latest_quarter"
                                        value={productsLatestDate?productsLatestDate:0}
                                        placeholder="Enter Value"
                                        onChange={(e: any) => setProductsLatestDate(parseInt(e.target.value))}
                                        className="form-control input-height mt-3"
                                    />
                            </div>
                            <div className="col-md-12 mb-4">
                                <label className="col-form-head">
                                Cumulative No. of IP Registration Requests Filed by the Startup 
                                </label>
                                <br/>
                                <input
                                        min={0}
                                        // type="number"
                                        name="no_ip_regstr_req_till_date"
                                        value={ipregistationTillDate?ipregistationTillDate:0}
                                        placeholder="Enter Value"
                                        onChange={(e: any) => setIPregistationTillDate(parseInt(e.target.value))}
                                        className="form-control input-height mt-3"
                                    />

                            </div>
                            <div className="col-md-12 mb-4">
                                <label className="col-form-head">
                                Cumulative No. of IP Registration Requests Filed by the Startup – in latest quarter
                                </label>
                                <br/>
                                <input
                                        min={0}
                                        // type="number"
                                        name="no_ip_regstr_req_latest_quarter"
                                        value={ipregistationLatestDate?ipregistationLatestDate:0}
                                        placeholder="Enter Value"
                                        onChange={(e: any) => setIPregistationLatestDate(parseInt(e.target.value))}
                                        className="form-control input-height mt-3"
                                    />


                            </div>
                            <div className="col-md-12 mb-5">
                                <label className="col-form-head">
                                    Others if any
                                </label>
                                <br/>
                                <textarea
                                    onChange={handleChangeTextField}
                                    rows={5}
                                    placeholder="Other if any"
                                    name="other_comment"
                                    className="form-control mt-3"
                                    value={progressFormData?.other_comment}
                                />
                            </div>



                            <div className="row pl-3">
                                <div className="col-md-6">
                                    <button
                                        className="btn btn-sisfs btn-transparent-primary full-width"
                                        data-dismiss="modal"
                                    >
                                        CANCEL
                                    </button>
                                </div>
                                <div className="col-md-6">
                                    <button
                                        disabled={loading}
                                        className="tertiary  btn-yellow-primary full-width"
                                        onClick={() => !loading ? handleProgressFormSubmit() : null}
                                    >
                                        SHARE
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {errorMessage &&
            <ConfirmationDialog
                headerText={"Failed"}
                bodyText={errorMessage}
                onDialogClose={()=>window.location.reload()}
                iconClass="test"
            />
            }

            {successMessage &&
            <ConfirmationDialog
                headerText="Success"
                bodyText={successMessage}
                onDialogClose={()=>window.location.reload()}
            />
            }

        </Fragment>
    )
}

export default ProgressEditInfoModal
function convertToBinary(file: any) {
    throw new Error("Function not implemented.");
}

