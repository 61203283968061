import React, { useEffect, useRef, useState } from 'react';
// import { Link } from 'react-router-dom';
import { scroller } from 'react-scroll';
import Toparrow from '../../images/Toparrow.svg';
import { Link } from 'react-scroll'

const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
};

function GlossaryContainer(props: any) {

    const IAIRef = useRef(null)
    const ISMCRef = useRef(null)
    const GrantRef = useRef(null)
    const ConvertibleAgreementRef = useRef(null)
    const DebtInstrumentRef = useRef(null)
    const ManagementFeesRef = useRef(null)

    useEffect(() => {
        window.scrollTo(0, 0)
        // if (typeof props.location.state !== 'undefined') {
        //     if (props.location.state.selected_tab == "IAI") {
        //         // scrollToRef(startupRef);
        //         scroller.scrollTo("IAIScroll", {
        //             duration: 800,
        //             delay: 0,
        //             offset: -130,
        //             smooth: "easeInOutQuart",
        //         });
        //     }
        //     else if (props.location.state.selected_tab == "ISMC") {
        //         // scrollToRef(startupRef);
        //         scroller.scrollTo("ISMCScroll", {
        //             duration: 800,
        //             delay: 0,
        //             offset: -130,
        //             smooth: "easeInOutQuart",
        //         });
        //     }
        //     else if (props.location.state.selected_tab == "Grant") {
        //         // scrollToRef(expertRef);
        //         scroller.scrollTo("GrantScroll", {
        //             duration: 800,
        //             delay: 0,
        //             offset: -130,
        //             smooth: "easeInOutQuart",
        //         });
        //     }
        //     else if (props.location.state.selected_tab == "ConvertibleAgreement") {
        //         // scrollToRef(objectiveRef);

        //         scroller.scrollTo("ConvertibleAgreementScroll", {
        //             duration: 800,
        //             delay: 0,
        //             offset: -130,
        //             smooth: "easeInOutQuart",
        //         });

        //     }
        //     else if (props.location.state.selected_tab == "DebtInstrument") {
        //         // scrollToRef(sfsRef);
        //         scroller.scrollTo("DebtInstrumentScroll", {
        //             duration: 800,
        //             delay: 0,
        //             offset: -130,
        //             smooth: "easeInOutQuart",
        //         });
        //     }
        //     else if (props.location.state.selected_tab == "ManagementFees") {
        //         // scrollToRef(whatStartupRef);
        //         scroller.scrollTo("ManagementFeesScroll", {
        //             duration: 800,
        //             delay: 0,
        //             offset: -130,
        //             smooth: "easeInOutQuart",
        //         });
        //     }

        // }

    }, []);


    return (
        <div className="app-inside app-contact">

            {/* Inside banner section */}
            <div className="container-fluid about-heading faq-container">
                <div className="text-center main-heading faq-heading">
                    Glossary
                </div>
            </div>

            <div className="container inside-container">

                <div className="app-inside-content app-inside-full app-inside-glossary">


                    <div className="row about-content sticky-content">
                        <div className="col-sm-12 col-md-4 sticky-dropdown-section">

                            <div className="d-block d-sm-block d-md-none">
                                <div className="sticky-dropdown">
                                    <div className="dropdown">
                                        <button className="btn btn-large btn-sisfs btn-transparent-primary dropdown-toggle"
                                            type="button" data-toggle="dropdown">
                                            Incubator and Incubatee
                                        <span className="caret"></span>
                                        </button>
                                        <ul className="about-list dropdown-menu">
                                        <li><Link activeClass="active" to="what-startup" offset={-125} spy={true} smooth={true}>Incubator and Incubatee</Link></li>
                                <li><Link activeClass="active" to="what-startup-2" offset={-125} spy={true} smooth={true}>ISMC </Link></li>
                                <li><Link activeClass="active" to="what-startup-3" offset={-125} spy={true} smooth={true}>Grant</Link></li>
                                <li><Link activeClass="active" to="what-startup-4" offset={-125} spy={true} smooth={true}>Convertible debenture agreement?</Link></li>
                                <li ><Link activeClass="active" to="what-startup-5" offset={-125} spy={true} smooth={true}>Debt Instrument</Link></li>
                                <li ><Link activeClass="active" to="what-startup-6" offset={-125} spy={true} smooth={true}>Management Fees for Incubators</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <ul className="about-list glossary-page d-none d-sm-none d-md-block">
                                {/* <ul className="about-list glossary-page"> */}
                                <li><Link activeClass="active" to="what-startup" offset={-130} spy={true} smooth={true}>Incubator and Incubatee</Link></li>
                                <li><Link activeClass="active" to="what-startup-2" offset={-130} spy={true} smooth={true}>Incubator Seed Management Committee </Link></li>
                                <li><Link activeClass="active" to="what-startup-3" offset={-130} spy={true} smooth={true}>Grant</Link></li>
                                <li><Link activeClass="active" to="what-startup-4" offset={-130} spy={true} smooth={true}>What is a convertible debenture agreement?</Link></li>
                                <li ><Link activeClass="active" to="what-startup-5" offset={-130} spy={true} smooth={true}>Debt Instrument</Link></li>
                                <li ><Link activeClass="active" to="what-startup-6" offset={-130} spy={true} smooth={true}>Management Fees for Incubators</Link></li>
                            </ul>
                        </div>
                        <div className="right-cont col-sm-12 col-md-8">

                            <div id="what-startup" ref={IAIRef} className="right-cols IAIScroll">
                                <div className="main-heading right-heading">Incubator and Incubatee</div>

                                <p className="para-text">
                                    For the purpose of this scheme, an Incubator is an entity responsible for selecting startups under the scheme, disbursing seed fund to them, and monitoring their progress. Incubator shall also be providing physical infrastructure to the selected startups for regular functioning, support for testing and validating ideas, mentoring for prototype or product development or commercialization, and developing capacities in finance, human resources, legal compliances, and other functions. They are also expected to provide networking with investors and opportunities for showcasing in various national and international events. If the selected startup does not want to utilize the physical infrastructure of the incubator, the incubator shall offer all other resources and services to the startup.
                                </p>

                                <p className="para-text">An Incubatee is a startup selected under this scheme, who will avail services of the Incubator.</p>
                            </div>

                            <div id="what-startup-2" ref={ISMCRef} className="right-cols ISMCScroll">
                                <div className="main-heading right-heading">Incubator Seed Management Committee (ISMC)</div>

                                <p className="para-text">Each incubator applying for Startup India Seed Fund Scheme will have to constitute a committee called the Incubator Seed Management Committee (ISMC). The committee will be responsible for evaluating and selecting startups for seed support.
An Incubatee is a startup selected under this scheme, who will avail services of the Incubator.</p>

                                <p className="para-text">The ISMC constitutes of the following members:</p>
                                <div className="ordered-list wrapper about-ordered-list">
                                    <ol>
                                        <li>Nominee of Incubator (Chairman)</li>
                                        <li>A representative from the State Government’s Startup Nodal Team</li>
                                        <li>A representative of a Venture Capital Fund or Angel Network</li>
                                        <li>A domain expert from Industry</li>
                                        <li>A domain expert from academia</li>
                                        <li>Two successful Entrepreneurs</li>
                                        <li>Any other relevant Stakeholder</li>
                                    </ol>
                                </div>
                            </div>

                            <div id="what-startup-3" ref={GrantRef} className="right-cols GrantScroll">
                                <div className="main-heading right-heading">Grant</div>

                                <p className="para-text">For the purpose of this scheme, Grant is an instrument of seed fund provided to selected startups for validation of Proof of Concept, or prototype development, or product trials. The startup receiving a grant is not expected to return it.</p>
                                <p className="para-text">The funds given to incubators for providing seed support to startups shall also be in the form of a grant.</p>

                            </div>

                            <div id="what-startup-4" ref={DebtInstrumentRef} className="right-cols ConvertibleAgreementScroll">
                                <div className="main-heading right-heading">What is a convertible debenture agreement?</div>

                                <p className="para-text">As per section 2(30) of the Companies Act, 2013, “debenture" includes debenture stock, bonds or any other instrument of a company evidencing a debt, whether constituting a charge on the assets of the company or not;</p>

                                <p className="para-text">Provided that -</p>

                                <div className="ordered-list wrapper about-ordered-list">
                                    <ol>
                                        <li>The instruments referred to in Chapter III-D of the Reserve Bank of India Act, 1934;</li>
                                        <li>Such other instrument, as may be prescribed by the Central Government in consultation with the Reserve Bank of India, issued by a company, shall not be treated as debenture</li>
                                    </ol>
                                </div>

                                <p className="para-text">'Convertible Debenture' refers to debentures that can be converted into Equity Shares of a Company (startup in this case) within fixed period of time subject to conditions specified in the Companies Act, 2013.</p>
                                <p className="para-text">For the purpose of this scheme, selected startups can receive seed fund through this instrument for market entry, commercialization, or scaling up of their business.</p>
                            </div>

                            <div id="what-startup-5" ref={ConvertibleAgreementRef} className="right-cols DebtInstrumentScroll">
                                <div className="main-heading right-heading">Debt Instrument</div>

                                <p className="para-text">Debt is a financing instrument in which funds shall be provided at a rate of interest of not more than prevailing repo rate. The tenure should be fixed at the time of sanctioning the loan by the incubator, which shall be not more than 60 months (5 years). A moratorium of up to 12 months may be provided for the startups. Because of the early stage of the startups, this shall be unsecured and no guarantee from promoter or third-party will be required.</p>

                            </div>

                            <div id="what-startup-6" ref={ManagementFeesRef} className="right-cols ManagementFeesScroll">
                                <div className="main-heading right-heading">Management Fees for Incubators</div>

                                <p className="para-text">A component of Management Fee @ 5% of Seed Fund grant to the incubator will be provisioned (i.e. if an incubator is granted &#8377;1 crore of Seed Fund, then by including management fee @ 5%, the total assistance would be &#8377;1.050 crore)</p>

                                <p className="para-text">The Management Fee provisioned for incubators shall not be used by the incubator for facility creation or any other administrative expenses. The Management Fee will be utilized for administrative expenditure, selection and due diligence of startups, and monitoring of progress of beneficiary startups
</p>
                            </div>

                        </div>
                    </div>

                    {/* <h1 className="section-heading text-center">Coming Soon!!</h1> */}
                </div>
            </div>

            <div className="top-arrow">
                <div id="myBtn" onClick={scrollTop}>
                    <img src={Toparrow} className="img-fluid" alt="SISFS Logo" /> TOP
            </div>
            </div>

        </div>
    )
}

export default GlossaryContainer;