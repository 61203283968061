import React, {Fragment, WheelEventHandler, useEffect, useState} from "react"
import {get, post} from "../../../effects/api/api.common";
import {PROGRESS_OVERVIEW_FORM, PROGRESS_PERCENTAGE, PROGRESS_STAGES,PROGRESS_OVERVIEW_FILE_UPLOAD} from "../../../constants/api";
import ConfirmationDialog from "../../common/controls/ConfirmationDialog";
import FileUploader from '@amodv/react-file-uploader';
import { FileUploadModel } from "../../../models/FileUploadModel";
import { getSessionStorageValue } from "../../../effects/utils/session-storage";
import { ProgressOverviewe } from "../../../utils/Enums";


const ProgressUpdateModal = (props: any) => {

    const [applicationId, setApplicationId] = useState('')
    const [progressPercentage, setProgressPercentage] = useState([])
    const [progressStages, setProgressStages] = useState([])
    const [progressFormData, setProgressFormData] = useState({
        external_fund_raised_till_date:"",
        external_fund_raised_latest_quarter:"",
        turnover_till_date:"",
        turnover_latest_quarter:"",
        gst_filled_till_date:"",
        gst_filled_latest_quarter:"",
        progress_in_text: undefined,
        other_comment:undefined,
        no_products_on_gem_portal_till_date: "",
        no_products_on_gem_portal_latest_quarter: "",
        no_ip_regstr_req_till_date: "",
        no_ip_regstr_req_latest_quarter: "",
        stage_id:"",
        progress_in_percentage:""

    })
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const[dataSuccess,setDataSuccess]=useState(false)

    const [jobsTillDate, setJobsTillDate] = useState(0)
    const [jobsLatestDate, setJobslatestDate] = useState(0)
    const[productsTillDate,setProductsTillDate]=useState(0)
    const[productsLatestDate,setProductsLatestDate]=useState(0)
    const[ipregistationTillDate,setIPregistationTillDate]=useState(0)
    const[ipregistationLatestDate,setIPregistationLatestDate]=useState(0)

    const [uploadedFile, setUploadedFile] = useState<Array<File>>([]);

    const [validationFlag, setValidationFlag] = useState({
        
        external_fund_raised_till_date: false,
        external_fund_raised_latest_quarter: false,
        turnover_till_date: false,
        turnover_latest_quarter: false,
        gst_filled_till_date: false,
        gst_filled_latest_quarter: false,
    })


    
    useEffect(() => {
        setApplicationId(props.applicationId)
        //console.log(props.applicationId)
    }, [props.applicationId])
    useEffect(()=>{
        setProgressPercentage(props.progressPercentage)
        setProgressStages(props.progressStagess)
    },[props.progressPercentage,props.progressStagess,props.prevDate])

    const handleJobsTillDate = (incOrDec: number) => {
        if(jobsTillDate===0) return
        const localJobs = jobsTillDate + incOrDec
        setJobsTillDate(localJobs)
    }

    const handleJobslatestQuarter = (incOrDec: number) => {
        if(jobsTillDate===0) return
        const localLatestJobs = jobsLatestDate + incOrDec
        setJobslatestDate(localLatestJobs)
    }
    // handleJobslatestQuarter
    // const handleValidateDecimalPoint = (
    //     evt: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>
    // ) => {
    //     const name = evt.target.name;
    //     const value = evt.target.value;
    //     //console.log('event target', name, value)

    //     setProgressFormData((values) => ({
    //         ...values,
    //         [name]: value,
    //     }));

    // }
    
    
    // Number.prototype.countDecimals = function () {
    //     if(Math.floor(this.valueOf()) === this.valueOf()) return 0;
    //     return this.toString().split(".")[1].length || 0; 
    // }
    const handelValidate = (
        evt: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>
    ) => {
        // var reg=d\+\.\d\d$
        
        const name = evt.target.name;
        const t = evt.target.value;
        if(t!=="")
        {

        
        const value = t.toString().split(".")[1]?.length || 0;
        //console.log(validationFlag.external_fund_raised_till_date)
        if(value>2)
        {
            setValidationFlag((values) => ({
                ...values,
                [name]: true,
            }));
        }
        else{
            setValidationFlag((values) => ({
                ...values,
                [name]: false,
            }));
        }
    }
        
}   


    const handleChange = (
        evt: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>
    ) => {
        
        const name = evt.target.name;        
        const value = evt.target.value.replace(/[^0-9]/ig, '').toString().split(".").map((el,i)=>i?el.split("").slice(0,2).join(""):el).join(".");
                
        setProgressFormData((values) => ({
            ...values,
            [name]:  value,
        }));
    }
    const handleChange1 = (
        evt: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>
    ) => {
        
        const name = evt.target.name;        
        const value = evt.target.value.toString().split(".").map((el,i)=>i?el.split("").slice(0,2).join(""):el).join(".");
                
        setProgressFormData((values) => ({
            ...values,
            [name]:  value,
        }));
    }
    const handleChangeTextField = (
        evt: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const name = evt.target.name;
        const value = evt.target.value;
        //console.log('event target', name, value)

        setProgressFormData((values) => ({
            ...values,
            [name]: value,
        }));

    }
    const [fileToSend,setFileToSend] = useState<any>('')
    const onFileUploadTrack=(files: File[])=>{

        let indexes: Array<string> = [];
        setUploadedFile(files)
        setFileToSend(files)
        // setUploadedFile([]);
        for (let file of files) {
            
        //   if (file.type === "application/pdf") {
        //     const user: any = getSessionStorageValue("user");
        //     const userInfo = JSON.parse(user)
        //     const formData = new FormData();
        //     // Update the formData object
        //     formData.append("file", file, file.name);
        //     // formData.append('file_type', type);
        //     formData.append("startup_progress_overview_id","0");
        //     formData.append("startup_application_id",props.applicationId);
        //     formData.append("logged_user_id",userInfo.id)
        //     post(PROGRESS_OVERVIEW_FILE_UPLOAD, formData).then((response: any) => {
             
        //       if (!response.data) return;
        //       if (response.data.result == 'success') {
        //         //console.log("response ")
        //         // setMessage("Account Document Uploaded Successfully..!");
        //         // setSuccessMessage('Account Document Uploaded Successfully..!')
        //         setUploadedFile(response.data.file)
        //         // setShowSuccessModal(true);
        //         // setShowIncubatorSuccessModal(true);
        //         // getStartupMeetingDetails();
        //       } else {
        //         setErrorMessage('oops! something went wrong')
        //         // setShowFailureAccountModal(true);
        //         // setShowIncubatorFailureModal(true);
        //       }
        //     });
        //   } else {
        //     indexes.push(file.name);
        //   }
        }
    }
    const handleProgressFormSubmit = () => {

        setErrorMessage('')
        setSuccessMessage('')
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user)

        
        //@ts-ignore
        const otherComment = progressFormData?.other_comment ? progressFormData?.other_comment : ""
        
        // const formData = {
        //     ...progressFormData,
        //     startup_application_id: applicationId.toString(),
        //     people_employed_till_date: jobsTillDate.toString(),
        //     people_employed_latest_quarter:jobsLatestDate.toString(),
        //     no_ip_regstr_req_latest_quarter: ipregistationLatestDate.toString(),
        //     no_ip_regstr_req_till_date: ipregistationTillDate.toString(),
        //     no_products_on_gem_portal_latest_quarter: ipregistationLatestDate.toString(),
        //     no_products_on_gem_portal_till_date: ipregistationTillDate.toString(),            
        //     prev_date: props.prevDate,
        //     other_comment: otherComment,
        //     "logged_user_id":userInfo.id,
            
        // }

        const formData = new FormData();
        for (let file of fileToSend) {

            formData.append("file", file)
        }
          if (progressFormData.external_fund_raised_till_date !== undefined) {
            formData.append("external_fund_raised_till_date", progressFormData.external_fund_raised_till_date);
          }
          if (progressFormData.external_fund_raised_latest_quarter !== undefined) {
            formData.append("external_fund_raised_latest_quarter", progressFormData.external_fund_raised_latest_quarter);
          }
          if (progressFormData.turnover_till_date !== undefined) {
            formData.append("turnover_till_date", progressFormData.turnover_till_date);
          }
          if (progressFormData.turnover_latest_quarter !== undefined) {
            formData.append("turnover_latest_quarter", progressFormData.turnover_latest_quarter);
          }
          if (progressFormData.gst_filled_till_date !== undefined) {
            formData.append("gst_filled_till_date", progressFormData.gst_filled_till_date);
          }
          if (progressFormData.gst_filled_latest_quarter !== undefined) {
            formData.append("gst_filled_latest_quarter", progressFormData.gst_filled_latest_quarter);
          }
          if (progressFormData.progress_in_text !== undefined) {
            formData.append("progress_in_text", JSON.stringify(progressFormData.progress_in_text));
          }
          
        formData.append("startup_application_id",applicationId.toString());
        formData.append("people_employed_till_date",jobsTillDate.toString());
        formData.append("people_employed_latest_quarter",jobsLatestDate.toString());
        formData.append("no_ip_regstr_req_latest_quarter",ipregistationLatestDate.toString());
        formData.append("no_ip_regstr_req_till_date",ipregistationTillDate.toString());
        formData.append("no_products_on_gem_portal_latest_quarter",ipregistationLatestDate.toString());
        formData.append("no_products_on_gem_portal_till_date",ipregistationTillDate.toString());
        formData.append("prev_date",props.prevDate);
        formData.append("other_comment",JSON.stringify(otherComment));
        formData.append("logged_user_id",userInfo.id);
        formData.append("stage_id",progressFormData.stage_id);
        formData.append("progress_in_percentage",progressFormData.progress_in_percentage);
        
        // external_fund_raised_till_date
        // external_fund_raised_latest_quarter
        // turnover_till_date
        // turnover_latest_quarter
        // gst_filled_till_date
        // gst_filled_latest_quarter
        // progress_in_text
        // other_comment
        // no_products_on_gem_portal_till_date
        // no_products_on_gem_portal_latest_quarter
        // no_ip_regstr_req_till_date
        // no_ip_regstr_req_latest_quarter

        if (validationFlag.external_fund_raised_till_date==true || validationFlag.external_fund_raised_latest_quarter==true ||validationFlag.turnover_till_date|| validationFlag.turnover_latest_quarter || validationFlag.gst_filled_till_date || validationFlag.gst_filled_latest_quarter ) {
            setErrorMessage('field should have upto two decimal point')
            return
        }
        // //@ts-ignore
        // if (!formData.stage_id) {
        //     setErrorMessage('Please select Stages')
        //     return
        // }
        // //@ts-ignore
        // if (!formData.progress_in_percentage) {
        //     setErrorMessage('Please select progress percentage')
        //     return
        // }
        // //@ts-ignore
        // if (!formData.progress_in_text) {
        //     setErrorMessage('Please add some text in progress text')
        //     return
        // }

        // //@ts-ignore
        // if (!formData.external_fund_raised_till_date) {
        //     setErrorMessage('Please enter fund raised')
        //     return
        // }
        // //@ts-ignore
        // if (!formData.people_employed_till_date) {
        //     setErrorMessage('Please enter Jobs created')
        //     return
        // }
        // //@ts-ignore
        // if (!formData.turnover_till_date) {
        //     setErrorMessage('Please enter turnover')
        //     return
        // }


        try {
            setLoading(true)
            post(PROGRESS_OVERVIEW_FORM, formData).then((response: any) => {
                // console.log(response.data.data,"reponsee")
                if (!response || !response.data) return;
                if (response?.data?.result) {
                    // console.log('stages', response?.data?.result,"reponsee")
                    setSuccessMessage('Data Shared successfully')
                    setDataSuccess(true)
                } else {
                    setErrorMessage('Data share failed')
                }
            })
        } catch (e:any) {
            //console.log('err', e?.response?.data)
            setErrorMessage(e?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }


    // const validate = (e: { value: any; } | undefined) =>{
    //     //console.log(e)
    //     // var t = e.target.value;
    //     // e.value = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
    // }


    // const getProgressPercentage = () => {
    //     get(PROGRESS_PERCENTAGE).then((response: any) => {
    //         if (!response || !response.data) return;
    //         if (response?.data?.result === 'success') {
    //             //console.log('percentage', response.data)
    //             setProgressPercentage(response.data.data)
    //         } else {
    //             setProgressPercentage([])
    //         }
    //         getProgressStages()
    //     })
    // }
     

    const getProgressStages = () => {
        get(PROGRESS_STAGES).then((response: any) => {
            if (!response || !response.data) return;
            if (response?.data?.result === 'success') {
                //console.log('stages', response.data)
                setProgressStages(response.data.data)
            } else {
                setProgressStages([])
            }
        })
    }

    return (
        <Fragment>
            <div
                className="modal fade"
                id="progressUpdateModal"
                role="dialog"
                aria-labelledby="exampleModalLabel1"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-slideout modal-md"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header modal-header-bottom pr-5">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body pl-4 pr-4">
                            <div className="popup-head mb-5 ml-3">
                                <div className="main-heading">Share Progress </div>
                            </div>

                            <div className="col-md-12 mb-4">
                                <label className="col-form-head">
                                    Choose a stage
                                </label>
                                <div className="select-custom">
                                    <select
                                        onChange={handleChange}
                                        className="form-control input-height"
                                        aria-label="Select Stage"
                                        id="stage_id"
                                        name="stage_id"
                                    >
                                        <option selected>Please select stage</option>
                                        
                                        {progressStages.length > 0 && progressStages.map((progress: any, index: number) => {
                                            return (
                                                <option value={progress.id} key={index}>
                                                    {progress.name}
                                                </option>
                                            )
                                        })}


                                    </select>
                                </div>
                                
                            </div>

                            <div className="col-md-12 mb-4">
                                <label className="col-form-head">
                                    Progress of: Proof of concept
                                </label>

                                <div className="select-custom">
                                    <select
                                        onChange={handleChange}
                                        className="form-control input-height"
                                        aria-label="Select progress_in_percentage"
                                        id="progress_in_percentage"
                                        name="progress_in_percentage"
                                    >
                                        <option selected>Please select Progress percentage</option>
                                        {progressPercentage.length > 0 && progressPercentage.map((progress: any, index: number) => {
                                            return (
                                                <option value={progress.id} key={index}>{progress.name}</option>
                                            )
                                        })}

                                    </select>
                                </div>

                                <textarea
                                    onChange={handleChange1}
                                    rows={5}
                                    placeholder="Progress text..."
                                    id="progress_in_text"
                                    name="progress_in_text"
                                    className="form-control mt-3"
                                    value={progressFormData?.progress_in_text}
                                />

                            </div>


                            <div className="col-md-12 mb-4">

                                <label className="col-form-head">
                                    Supporting Documents
                                </label>
                              
                                <br/>
                                <button className="btn btn-sisfs btn-transparent-primary">
                                     Upload
                                </button>
                                <FileUploader
                                    key={"test2"}
                                    // id={"test2"}
                                    id={ProgressOverviewe.SupportinDoc}
                                    information="Drag & Drop the file or Browse"
                                    fileType="application/pdf"
                                    buttonLabel="Browse"
                                    multiple={false}
                                    enablePreview
                                    IconComponent={() => (
                                        <span className="fa fa-cloud-upload"></span>
                                    )}
                                    
                                    removeIconClass=""  
                                    existingFiles={
                                         uploadedFile
                                    } 
                                    onUploadFinish={function (files: File[]): void {
                                        
                                        onFileUploadTrack(files)
                                        
                                        
                                    } } 
                                    onFileDelete={function (file: File): void {
                                        throw new Error("Function not implemented.");
                                    } } />

                                 {/* <input  type=".pdf" className="btn btn-sisfs btn-transparent-primary">
                                    Upload
                                 </input> */}
                                
                            </div>

                            <div className="col-md-12 mb-4">
                                <label className="col-form-head">
                                Cumulative Quantum of External Funding Raised by Startup – till date (&#8377;)
                                </label>
                                <br/>
                                <input
                                    min={0}
                                    onChange={handleChange}
                                    type="text"
                                    value={progressFormData.external_fund_raised_till_date}
                                    pattern="[0-9]*"
                                    onBlur={handelValidate}
                                    placeholder="Enter Amount(&#8377;)"
                                    name="external_fund_raised_till_date"
                                    className="form-control input-height mt-3"
                                />
                               {validationFlag.external_fund_raised_till_date == true ? <label className="error-message">Please enter upto two decimal point</label> : null}
                            </div>
                            <div className="col-md-12 mb-4">
                                <label className="col-form-head">
                                Cumulative Quantum of External Funding Raised by Startup – in latest quarter (&#8377;)
                                </label>
                                <br/>
                                <input
                                    min={0}
                                    onChange={handleChange}
                                    onBlur={handelValidate}
                                    type="text"
                                    value={progressFormData.external_fund_raised_latest_quarter}
                                    pattern="[0-9]*"
                                    placeholder="Enter Amount(&#8377;)"
                                    name="external_fund_raised_latest_quarter"
                                    className="form-control input-height mt-3"
                                />
                               {validationFlag.external_fund_raised_latest_quarter == true ? <label className="error-message">Please enter upto two decimal point</label> : null}
                            </div>

                            <div className="col-md-12 mb-4">
                                <label className="col-form-head">
                                Cumulative No. of People Employed by the Startup – till date
                                </label>
                                <div className="col-md-12 justify-content-center heightJobsBtn p-0">
                                    {/* <button className="btn btn-sisfs btn-transparent-primary mr-3 mt-3"
                                            onClick={() => handleJobsTillDate(-1)}>
                                        -
                                    </button> */}

                                    <input
                                        min={0}
                                        // type="number"
                                        name="people_employed_till_date"
                                        value={jobsTillDate?jobsTillDate:""}
                                        placeholder="Enter Value"
                                        onChange={(e: any) => setJobsTillDate(parseInt(e.target.value))}
                                        className="form-control input-height mt-3"
                                    />

                                    {/* <button className="btn btn-sisfs btn-transparent-primary ml-3 mt-3"
                                            onClick={() => handleJobsTillDate(1)}>
                                        +
                                    </button> */}
                                </div>
                            </div>
                            <div className="col-md-12 mb-4">
                                <label className="col-form-head">
                                Cumulative No. of People Employed by the Startup – in latest quarter­­
                                </label>
                                <div className="col-md-12 justify-content-center heightJobsBtn p-0">
                                    {/* <button className="btn btn-sisfs btn-transparent-primary mr-3 mt-3"
                                            onClick={() => handleJobslatestQuarter(-1)}>
                                        -
                                    </button> */}

                                    <input
                                        min={0}
                                        // type="number"
                                        name="people_employed_latest_quarter"
                                        value={jobsLatestDate?jobsLatestDate:""}
                                        placeholder="Enter Value"
                                        onChange={(e: any) => setJobslatestDate(parseInt(e.target.value))}
                                        className="form-control input-height mt-3"
                                    />

                                    {/* <button className="btn btn-sisfs btn-transparent-primary ml-3 mt-3"
                                            onClick={() => handleJobslatestQuarter(1)}>
                                        +
                                    </button> */}
                                </div>
                            </div>

                            <div className="col-md-12 mb-4">
                                <label className="col-form-head">
                                Cumulative Turnover of Startup – till date (&#8377;)
                                </label>
                                <br/>
                                <input
                                    min={0}
                                    onChange={handleChange}
                                    onBlur={handelValidate}
                                    type="text"
                                    value={progressFormData.turnover_till_date}
                                    pattern="[0-9]*"
                                    placeholder="Enter Amount(&#8377;)"
                                    name="turnover_till_date"
                                    className="form-control input-height mt-3"
                                />
                                 {validationFlag.turnover_till_date == true ? <label className="error-message">Please enter upto two decimal point</label> : null}
                            </div>
                            <div className="col-md-12 mb-4">
                                <label className="col-form-head">
                                Cumulative Turnover of Startup – in latest quarter (&#8377;)
                                </label>
                                <br/>
                                <input
                                    min={0}
                                    onChange={handleChange}
                                    onBlur={handelValidate}
                                    type="text"
                                    value={progressFormData.turnover_latest_quarter}
                                    pattern="[0-9]*"
                                    placeholder="Enter Amount(&#8377;)"
                                    name="turnover_latest_quarter"
                                    className="form-control input-height mt-3"
                                />
                                {validationFlag.turnover_latest_quarter == true ? <label className="error-message">Please enter upto two decimal point</label> : null}
                            </div>
                            <div className="col-md-12 mb-4">
                                <label className="col-form-head">
                                Cumulative GST Filed by Startup – till date (&#8377;)
                                </label>
                                <br/>
                                <input
                                    min={0}
                                    onChange={handleChange}
                                    type="text"
                                    value={progressFormData.gst_filled_till_date}
                                    pattern="[0-9]*"
                                    onBlur={handelValidate}
                                    placeholder="Enter Amount(&#8377;)"
                                    name="gst_filled_till_date"
                                    className="form-control input-height mt-3"
                                />
                                 {validationFlag.gst_filled_till_date == true ? <label className="error-message">Please enter upto two decimal point</label> : null}
                            </div>
                            <div className="col-md-12 mb-4">
                                <label className="col-form-head">
                                Cumulative GST Filed by Startup – in latest quarter (&#8377;)
                                </label>
                                <br/>
                                <input
                                    min={0}
                                    onChange={handleChange}
                                    type="text"
                                    value={progressFormData.gst_filled_latest_quarter}
                                    pattern="[0-9]*"
                                    onBlur={handelValidate}
                                    placeholder="Enter Amount(&#8377;)"
                                    name="gst_filled_latest_quarter"
                                    className="form-control input-height mt-3"
                                />
                                {validationFlag.gst_filled_latest_quarter == true ? <label className="error-message">Please enter upto two decimal point</label> : null}

                            </div>
                            <div className="col-md-12 mb-4">
                                <label className="col-form-head">
                                Cumulative No. of Products and Services by Startup Live on GeM Portal – till date 
                                </label>
                                <br/>
                                 <input
                                        min={0}
                                        type="text"
                                        pattern="[0-9]*"
                                        name="no_products_on_gem_portal_till_date"
                                        value={productsTillDate?productsTillDate:""}
                                        placeholder="Enter Value"
                                        onChange={(e: any) => setProductsTillDate(parseInt(e.target.value))}
                                        className="form-control input-height mt-3"
                                    />

                            </div>
                            <div className="col-md-12 mb-4">
                                <label className="col-form-head">
                                Cumulative No. of Products and Services by Startup Live on GeM Portal – in latest quarter 
                                </label>
                                <br/>
                                  <input
                                        min={0}
                                        type="text"
                                        pattern="[0-9]*"
                                        name="no_products_on_gem_portal_latest_quarter"
                                        value={productsLatestDate?productsLatestDate:""}
                                        placeholder="Enter Value"
                                        onChange={(e: any) => setProductsLatestDate(parseInt(e.target.value))}
                                        className="form-control input-height mt-3"
                                    />

                            </div>
                            <div className="col-md-12 mb-4">
                                <label className="col-form-head">
                                Cumulative No. of IP Registration Requests Filed by the Startup  
                                </label>
                                <br/>
                                <input
                                        min={0}
                                        type="text"
                                        pattern="[0-9]*"
                                        name="no_ip_regstr_req_till_date"
                                        value={ipregistationTillDate?ipregistationTillDate:""}
                                        placeholder="Enter Value"
                                        onChange={(e: any) => setIPregistationTillDate(parseInt(e.target.value))}
                                        className="form-control input-height mt-3"
                                    />
                            </div>
                            <div className="col-md-12 mb-4">
                                <label className="col-form-head">
                                Cumulative No. of IP Registration Requests Filed by the Startup – in latest quarter 
                                </label>
                                <br/>
                                <input
                                        min={0}
                                        type="text"
                                        pattern="[0-9]*"
                                        name="no_ip_regstr_req_latest_quarter"
                                        value={ipregistationLatestDate?ipregistationLatestDate:""}
                                        placeholder="Enter Value"
                                        onChange={(e: any) => setIPregistationLatestDate(parseInt(e.target.value))}
                                        className="form-control input-height mt-3"
                                    />

                            </div>
                            <div className="col-md-12 mb-5">
                                <label className="col-form-head">
                                    Others if any
                                </label>
                                <br/>
                                <textarea
                                    onChange={handleChangeTextField}
                                    rows={5}
                                    placeholder="Other if any"
                                    name="other_comment"
                                    className="form-control mt-3"
                                    value={progressFormData?.other_comment}
                                />
                            </div>


                            <div className="row pl-3">
                                <div className="col-md-6">
                                    <button
                                        className="btn btn-sisfs btn-transparent-primary full-width"
                                        data-dismiss="modal"
                                    >
                                        CANCEL
                                    </button>
                                </div>
                                <div className="col-md-6">
                                    <button
                                        disabled={loading}
                                        className="tertiary  btn-yellow-primary full-width"
                                        onClick={() => !loading ? handleProgressFormSubmit() : null}
                                    >
                                        SHARE
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {errorMessage &&
            <ConfirmationDialog
                headerText={"Failed"}
                bodyText={errorMessage}
                onDialogClose={() => setErrorMessage('')}
                iconClass="test"
            />
            }

            {successMessage &&
            <ConfirmationDialog
                headerText="Success"
                bodyText={successMessage}
                onDialogClose={() => dataSuccess? window.location.reload():setSuccessMessage('')}
            />
            }

        </Fragment>
    )
}

export default ProgressUpdateModal
