import { AccountDetailsData, SubsequentDetails } from '../../../models/funddetails/AccountDetailsData';
import pdf from '../../../images/PDF.svg';
import xls from '../../../images/xls.svg';
import doc from '../../../images/doc.svg';
import xlsx from '../../../images/xls.svg';
import { useEffect, useState } from 'react';
import Allround from '../../../images/Allround.svg';
import { AccountFileUploadModel } from '../../../models/FileUploadModel';
import { NODE_URL } from '../../../constants/api';

interface IAccountDetailsProp {
  accountDetailsModel: AccountDetailsData;
  subsequentDetails: SubsequentDetails;
  trancheStatusLabel: any
}

function AccountDetailsComponent(props: IAccountDetailsProp) {
  const { accountDetailsModel, subsequentDetails, trancheStatusLabel } = props;
  // console.log("********&&&&&&&",accountDetailsModel.darpan)

  useEffect(() => {

    window.scrollTo(0, 0);
    // SetAccountDetails(accountDetailsModel);
  }, []);

  const getFileDetails = (file: any, key: string) => {
    let file_name = '';
    let file_ext = '';
    file_name =
      file != undefined && file.length > 1
        ? file.substr(file.lastIndexOf('/') + 1, file.length)
        : '';
    file_ext = file != undefined && file.length > 1 ? (file.substr(
      file.lastIndexOf('.') + 1,
      file.length)
    ) : "";
    if (key == 'ext') return file_ext;
    if (key == 'name') return file_name;
    if (key == 'file') return file
    // .toString();
  };

  const downloadDocument = (file: any, file_name: any) => {
    fetch(file).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = file_name;
        a.click();
      });
      //window.location.href = response.url;
    });
  };

  const accountTypeString = (id: any) => {
    if (id == 1) {
      return "Saving"
    }
    else if (id == 2) {
      return "Current"
    }
    else if (id == 3) {
      return "Salary"
    }
    else if (id == 4) {
      return "Fixed Deposit"
    }
    else if (id == 5) {
      return "Recurring Deposit"
    }
    else {
      return "-"
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <span className="incubator-label-value">Account Holder</span>
        </div>
      </div>
      <div className="row mt-1-5">
        <div className="col-md-4">

          <span className="incubator-label">Account Name </span>
          <br />
          <span className="incubator-label-value">
            {accountDetailsModel.bank_account_holder_name == '' ||
              accountDetailsModel.bank_account_holder_name == null
              ? '-'
              : accountDetailsModel.bank_account_holder_name}
          </span>
        </div>
        <div className="col-md-4">
          <span className="incubator-label">PFMS</span>
          <br />
          {
            subsequentDetails?.pfms_file_path_file_name == null ?
            Object.keys(accountDetailsModel.pan_card).length>0 ? <div className="row mt-2">
                <div className="col-md-3">
                  {getFileDetails(
                    accountDetailsModel.pan_card.file,
                    'ext'
                  ) == 'pdf' ? (
                    <img src={pdf} alt="SISFS Logo" />
                  ) : getFileDetails(
                    accountDetailsModel.pan_card.file,
                    'ext'
                  ) == 'doc' ? (
                    <img src={doc} alt="SISFS Logo" />
                  ) : getFileDetails(
                    accountDetailsModel.pan_card.file,
                    'ext'
                  ) == 'xls' ? (
                    <img src={xls} alt="SISFS Logo" />
                  ) : (
                    <img src={Allround} alt="SISFS Logo" />
                  )}
                </div>
                <div className="col-md-9" style={{ wordBreak: 'break-all' }}>
                  <span className="document-label-value">
                    {getFileDetails(accountDetailsModel.pan_card.file, 'name')}
                  </span>
                  <br />
                  <a
                    href={getFileDetails(accountDetailsModel.pan_card.file, "file")}
                    target="_blank"
                    className="incubator-details-link"
                  >
                    View
                  </a>
                </div>
              </div>
              : (trancheStatusLabel == "Disbursement in Process" || trancheStatusLabel == "Disbursed") && subsequentDetails?.pfms_file_path_file_name != "" ?
                <div className="row mt-2">
                  {
                    subsequentDetails?.pfms_file_path == "No FIle provided by user" ?
                      <div className="col-12">
                        <span className='document-label-value'>
                          {subsequentDetails?.pfms_file_path_file_name}
                        </span>
                      </div>
                      :
                      <>
                        <div className="col-md-3">
                          {getFileDetails(
                            subsequentDetails?.pfms_file_path,
                            'ext'
                          ) == 'pdf' ? (
                            <img src={pdf} alt="SISFS Logo" />
                          ) : getFileDetails(
                            subsequentDetails.pfms_file_path,
                            'ext'
                          ) == 'doc' ? (
                            <img src={doc} alt="SISFS Logo" />
                          ) : getFileDetails(
                            subsequentDetails.pfms_file_path,
                            'ext'
                          ) == 'xls' ? (
                            <img src={xls} alt="SISFS Logo" />
                          ) : (
                            <img src={Allround} alt="SISFS Logo" />
                          )}
                        </div>
                        <div className="col-md-9" style={{ wordBreak: 'break-all' }}>
                          <span className="document-label-value">
                            {getFileDetails(
                              subsequentDetails.pfms_file_path,
                              'name'
                            )}
                          </span>
                          <br />
                          <a
                            href={`${NODE_URL + subsequentDetails.pfms_file_path}`}
                            target="_blank"
                            className="incubator-details-link"
                          >
                            View
                          </a>
                        </div>
                      </>
                  }
                </div>
                : "-" :
                (trancheStatusLabel == "Disbursement in Process" || trancheStatusLabel == "Disbursed") && <div className="row mt-2">
                {
                  subsequentDetails?.pfms_file_path == "No FIle provided by user" ?
                    <div className="col-12">
                      <span className='document-label-value'>
                        {subsequentDetails?.pfms_file_path_file_name}
                      </span>
                    </div>
                    :
                    <>
                      <div className="col-md-3">
                        {getFileDetails(
                          subsequentDetails?.pfms_file_path,
                          'ext'
                        ) == 'pdf' ? (
                          <img src={pdf} alt="SISFS Logo" />
                        ) : getFileDetails(
                          subsequentDetails.pfms_file_path,
                          'ext'
                        ) == 'doc' ? (
                          <img src={doc} alt="SISFS Logo" />
                        ) : getFileDetails(
                          subsequentDetails.pfms_file_path,
                          'ext'
                        ) == 'xls' ? (
                          <img src={xls} alt="SISFS Logo" />
                        ) : (
                          <img src={Allround} alt="SISFS Logo" />
                        )}
                      </div>
                      <div className="col-md-9" style={{ wordBreak: 'break-all' }}>
                        <span className="document-label-value">
                          {getFileDetails(
                            subsequentDetails.pfms_file_path,
                            'name'
                          )}
                        </span>
                        <br />
                        <a
                          href={`${NODE_URL + subsequentDetails.pfms_file_path}`}
                          target="_blank"
                          className="incubator-details-link"
                        >
                          View
                        </a>
                      </div>
                    </>
                }
              </div>}

        </div>
        <div className="col-md-4">
          <span className="incubator-label">DARPAN ID</span>
          <br />
          {
            subsequentDetails?.proof_Of_Darpan_file_path_file_name == null ?
            Object.keys(accountDetailsModel.darpan).length > 0 ?  <div className="row mt-2">
                <div className="col-md-3">
                  {getFileDetails(
                    accountDetailsModel.darpan.file,
                    'ext'
                  ) == 'pdf' ? (
                    <img src={pdf} alt="SISFS Logo" />
                  ) : getFileDetails(
                    accountDetailsModel.darpan.file,
                    'ext'
                  ) == 'doc' ? (
                    <img src={doc} alt="SISFS Logo" />
                  ) : getFileDetails(
                    accountDetailsModel.darpan.file,
                    'ext'
                  ) == 'xls' ? (
                    <img src={xls} alt="SISFS Logo" />
                  ) : (
                    <img src={Allround} alt="SISFS Logo" />
                  )}
                </div>
                <div className="col-md-9" style={{ wordBreak: 'break-all' }}>
                  <span className="document-label-value">
                    {getFileDetails(
                      accountDetailsModel.darpan.file,
                      'name'
                    )}
                  </span>
                  <br />
                  <a
                    href={getFileDetails(accountDetailsModel.darpan.file, "file")}
                    target="_blank"
                    className="incubator-details-link"
                  >
                    View
                  </a>
                </div>
              </div>
              : (trancheStatusLabel == "Disbursement in Process" || trancheStatusLabel == "Disbursed") &&
              
              subsequentDetails?.proof_Of_Darpan_file_path_file_name != "" ?
                <div className="row mt-2">
                  <div className="col-md-3">
                    <span className='document-label-value'>

                      {subsequentDetails?.proof_Of_Darpan_file_path_file_name}
                    </span>
                  </div>
                </div> : "-" :
                (trancheStatusLabel == "Disbursement in Process" || trancheStatusLabel == "Disbursed") &&
                 <div className="row mt-2">
                  <div className="col-md-3">
                    <span className='document-label-value'>

                      {subsequentDetails?.proof_Of_Darpan_file_path_file_name}
                    </span>
                  </div>
                </div>}

        </div>

        <div className="col-md-4 mt-3">
          <span className="incubator-label">ECS Mandate Form</span>
          <br />
          {
            subsequentDetails?.ECS_Mandate_file_path_file_name == null ?
            Object.keys(accountDetailsModel.cancelled_cheque).length>0?
             <div className="row mt-2">
                <div className="col-md-3">
                  {getFileDetails(
                    accountDetailsModel.cancelled_cheque.file,
                    'ext'
                  ) == 'pdf' ? (
                    <img src={pdf} alt="SISFS Logo" />
                  ) : getFileDetails(
                    accountDetailsModel.cancelled_cheque.file,
                    'ext'
                  ) == 'doc' ? (
                    <img src={doc} alt="SISFS Logo" />
                  ) : getFileDetails(
                    accountDetailsModel.cancelled_cheque.file,
                    'ext'
                  ) == 'xls' ? (
                    <img src={xls} alt="SISFS Logo" />
                  ) : (
                    <img src={Allround} alt="SISFS Logo" />
                  )}
                </div>
                <div className="col-md-9" style={{ wordBreak: 'break-all' }}>
                  <span className="document-label-value">
                    {getFileDetails(
                      accountDetailsModel.cancelled_cheque.file,
                      'name'
                    )}
                  </span>
                  <br />
                  <a
                    href={getFileDetails(accountDetailsModel.cancelled_cheque.file, "file")}
                    target="_blank"
                    className="incubator-details-link"
                  >
                    View
                  </a>
                </div>
              </div>
              : (trancheStatusLabel == "Disbursement in Process" || trancheStatusLabel == "Disbursed") && subsequentDetails&&subsequentDetails?.ECS_Mandate_file_path_file_name != "" ?
                <div className="row mt-2">
                  <div className="col-md-3">
                    {getFileDetails(
                      subsequentDetails.ECS_Mandate_file_path,
                      'ext'
                    ) == 'pdf' ? (
                      <img src={pdf} alt="SISFS Logo" />
                    ) : getFileDetails(
                      subsequentDetails?.ECS_Mandate_file_path,
                      'ext'
                    ) == 'doc' ? (
                      <img src={doc} alt="SISFS Logo" />
                    ) : getFileDetails(
                      subsequentDetails.ECS_Mandate_file_path,
                      'ext'
                    ) == 'xls' ? (
                      <img src={xls} alt="SISFS Logo" />
                    ) : (
                      <img src={Allround} alt="SISFS Logo" />
                    )}
                  </div>
                  <div className="col-md-9" style={{ wordBreak: 'break-all' }}>
                    <span className="document-label-value">
                      {getFileDetails(
                        subsequentDetails.ECS_Mandate_file_path,
                        'name'
                      )}
                    </span>
                    <br />
                    <a
                      href={`${NODE_URL + subsequentDetails.ECS_Mandate_file_path}`}
                      target="_blank"
                      className="incubator-details-link"
                    >
                      View
                    </a>
                  </div>
                </div>
                : "-" : (trancheStatusLabel == "Disbursement in Process" || trancheStatusLabel == "Disbursed") &&<div className="row mt-2">
                <div className="col-md-3">
                  {getFileDetails(
                    subsequentDetails.ECS_Mandate_file_path,
                    'ext'
                  ) == 'pdf' ? (
                    <img src={pdf} alt="SISFS Logo" />
                  ) : getFileDetails(
                    subsequentDetails?.ECS_Mandate_file_path,
                    'ext'
                  ) == 'doc' ? (
                    <img src={doc} alt="SISFS Logo" />
                  ) : getFileDetails(
                    subsequentDetails.ECS_Mandate_file_path,
                    'ext'
                  ) == 'xls' ? (
                    <img src={xls} alt="SISFS Logo" />
                  ) : (
                    <img src={Allround} alt="SISFS Logo" />
                  )}
                </div>
                <div className="col-md-9" style={{ wordBreak: 'break-all' }}>
                  <span className="document-label-value">
                    {getFileDetails(
                      subsequentDetails.ECS_Mandate_file_path,
                      'name'
                    )}
                  </span>
                  <br />
                  <a
                    href={`${NODE_URL + subsequentDetails.ECS_Mandate_file_path}`}
                    target="_blank"
                    className="incubator-details-link"
                  >
                    View
                  </a>
                </div>
              </div>}

        </div>
        <div className="col-md-4 mt-3">
          <span className="incubator-label">TRA Agreement</span>
          <br />
          {
            accountDetailsModel.tra_agreement_name == null ? "-"
              : (trancheStatusLabel == "Disbursement in Process" || trancheStatusLabel == "Disbursed") && accountDetailsModel.tra_agreement_name != "" ?
                <div className="row mt-2">
                  <div className="col-md-3">
                    {getFileDetails(
                      accountDetailsModel.tra_agreement_location,
                      'ext'
                    ) == 'pdf' ? (
                      <img src={pdf} alt="SISFS Logo" />
                    ) : getFileDetails(
                      accountDetailsModel.tra_agreement_location,
                      'ext'
                    ) == 'doc' ? (
                      <img src={doc} alt="SISFS Logo" />
                    ) : getFileDetails(
                      accountDetailsModel.tra_agreement_location,
                      'ext'
                    ) == 'xls' ? (
                      <img src={xls} alt="SISFS Logo" />
                    ) : (
                      <img src={Allround} alt="SISFS Logo" />
                    )}
                  </div>
                  <div className="col-md-9" style={{ wordBreak: 'break-all' }}>
                    <span className="document-label-value">
                      {getFileDetails(
                        accountDetailsModel.tra_agreement_location,
                        'name'
                      )}
                    </span>
                    <br />
                    <a
                      href={`${NODE_URL + '/filestorage/' + accountDetailsModel.tra_agreement_location}`}
                      target="_blank"
                      className="incubator-details-link"
                    >
                      View
                    </a>
                  </div>
                </div>
                : "-"}

        </div>
        <div className="col-md-4 mt-3">
          <span className="incubator-label">Sanction Order</span>
          <br />
          {
            accountDetailsModel.sanction_name == null ? "-"
              : (trancheStatusLabel == "Disbursement in Process" || trancheStatusLabel == "Disbursed") && accountDetailsModel.sanction_name != "" ?
                <div className="row mt-2">
                  <div className="col-md-3">
                    {getFileDetails(
                      accountDetailsModel.sanction_location,
                      'ext'
                    ) == 'pdf' ? (
                      <img src={pdf} alt="SISFS Logo" />
                    ) : getFileDetails(
                      accountDetailsModel.sanction_location,
                      'ext'
                    ) == 'doc' ? (
                      <img src={doc} alt="SISFS Logo" />
                    ) : getFileDetails(
                      accountDetailsModel.sanction_location,
                      'ext'
                    ) == 'xls' ? (
                      <img src={xls} alt="SISFS Logo" />
                    ) : (
                      <img src={Allround} alt="SISFS Logo" />
                    )}
                  </div>
                  <div className="col-md-9" style={{ wordBreak: 'break-all' }}>
                    <span className="document-label-value">
                      {getFileDetails(
                        accountDetailsModel.sanction_location,
                        'name'
                      )}
                    </span>
                    <br />
                    <a
                      href={`${NODE_URL + '/filestorage/' + accountDetailsModel.sanction_location}`}
                      target="_blank"
                      className="incubator-details-link"
                    >
                      View
                    </a>
                  </div>
                </div>
                : "-"}

        </div>
      </div>

      <div className="application-incubator-hr">

        <hr />
      </div>

      <div className="row">
        <div className="col-md-12">
          <span className="incubator-label-value">Account Details</span>
        </div>
      </div>

      <div className="row mt-1-5">
        <div className="col-md-4">
          <span className="incubator-label">Bank Name</span>
          <br />
          <span className="incubator-label-value">
            {accountDetailsModel.bank_name == '' ||
              accountDetailsModel.bank_name == null
              ? '-'
              : accountDetailsModel.bank_name}
          </span>
        </div>
        <div className="col-md-4">
          <span className="incubator-label">Branch Name/Branch Code</span>
          <br />
          <span className="incubator-label-value">
            {accountDetailsModel.branch_name == '' ||
              accountDetailsModel.branch_name == null
              ? '-'
              : accountDetailsModel.branch_name}
          </span>
        </div>
        <div className="col-md-4">
          <span className="incubator-label">IFSC Code</span>
          <br />
          <span className="incubator-label-value">
            {accountDetailsModel.bank_ifsc_code == '' ||
              accountDetailsModel.bank_ifsc_code == null
              ? '-'
              : accountDetailsModel.bank_ifsc_code}
          </span>
        </div>


      </div>

      <div className="row mt-1-5">
        <div className="col-md-4">
          <span className="incubator-label">Account Type</span>
          <br />
          <span className="incubator-label-value">
            {accountTypeString(accountDetailsModel.account_type)
            }
          </span>
        </div><div className="col-md-4">
          <span className="incubator-label">Account Number</span>
          <br />
          <span className="incubator-label-value">
            {accountDetailsModel.account_number == '0' ||
              accountDetailsModel.account_number == null
              ? '-'
              : accountDetailsModel.account_number}
          </span>
        </div>
        <div className="col-md-4">
          <span className="incubator-label">MICR Code</span>
          <br />
          <span className="incubator-label-value">
            {accountDetailsModel.bank_micr_code == '' ||
              accountDetailsModel.bank_micr_code == null
              ? '-'
              : accountDetailsModel.bank_micr_code}
          </span>
        </div>

      </div>

      <div className="row mt-1-5">
        <div className="col-md-4">
          <span className="incubator-label">Bank Address</span>
          <br />
          <span className="incubator-label-value">
            {accountDetailsModel.branch_address == '' ||
              accountDetailsModel.branch_address == null
              ? '-'
              : accountDetailsModel.branch_address}
          </span>
        </div>
      </div>
      <div className="mt-1-5"></div>
    </div>
  );
}

export default AccountDetailsComponent;
