import React, { useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import FooterComponent from "../components/common/footer/FooterComponent";
import ContactContainer from "../components/contact/ContactContainer";
import GrievanceDetailComponent from "../components/dpiit/grievances/GrievanceDetailComponent";
import GrievanceListComponent from "../components/dpiit/grievances/GrievanceListComponent";
import ApplicationApplyContainer from "../components/incubator/application/myapplications/ApplicationApplyContainer";
import StartupContainer from "../components/startups/application/StartupContainer";
import StartupStatusComponent from "../components/startups/applicationstatus/StartupStatusComponent";
import StartupHeaderComponent from "../components/startups/common/StartupHeaderComponent";
import StartupFundDetailsContainer from "../components/startups/funddetails/StartupFundDetailsContainer";
import StartupMeetingContainer from "../components/startups/meetings/StartupMeetingContainer";
import { getSessionStorageValue } from "../effects/utils/session-storage";
import { ErrorModel } from "../models/common/ErrorModel";
import { StateErrorProvider } from "../providers/ErrorProvider";
import errorReducer, { IErrorInitialState } from "../reducers/error.reducer";
import RequireAuth from "../Routelocks/RouterHoooks";
import NoticeBoard from "../components/notice_board/NoticeBoard";

const StartupRouterComponent = () => {
    const initialErrorState = (): IErrorInitialState => {
        return {
            errors: new ErrorModel("", "", ""),
        };
    };
    const history = useHistory();
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    useEffect(() => {
        if (user == null || userInfo.roles != "startup") {
            history.push('/home');
        }
    }, []);
    return (
        <>
         <Switch>
         {/* <StartupHeaderComponent /> */}
         <Route path="/startupApplications/:token" component={StartupStatusComponent} />
         </Switch>

            <RequireAuth>
                {/* App Header */}
                <StartupHeaderComponent />

                <Switch>
                    <Route path="/startup_meeting" component={StartupMeetingContainer} />

                    <Route path="/startup_grievances" component={GrievanceListComponent} />

                    <Route exact path="/contact_startup" component={ContactContainer} />

                    <Route path="/grievance_detail_startup" component={GrievanceDetailComponent} />

                    <Route path="/startup_fund_details" component={StartupFundDetailsContainer} />

                    <Route path="/startup_apply" component={ApplicationApplyContainer} />

                    <Route path="/startup_status" component={StartupStatusComponent} />

                    <Route path="/startup_notice_board" component={NoticeBoard} />

                    <StateErrorProvider
                        initialState={initialErrorState()}
                        reducer={errorReducer}
                    >
                        <Route path="/startup" component={StartupContainer} />
                    </StateErrorProvider>

                </Switch>

                {/* App Footer */}
                <FooterComponent />
            </RequireAuth>
        </>
    )
}

export default StartupRouterComponent;