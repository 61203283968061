import '../../incubator/incubator.css';
import search from '../../../../images/search.svg';
import {useEffect, useState} from 'react';
import {filterByValue, showEllipsisText} from '../../../../utils/CommonMethods';
import {FormatDate} from '../../../../utils/DateFormat';
import Plus from '../../../../images/Plus.svg';
import Minus from '../../../../images/Minus.svg';

interface ICommentsProps {
  CommentsData: any;
  role: string;
}

function MergedCommentsDetailsComponent(props: ICommentsProps) {
  const {CommentsData, role} = props;

  const [commentDataInfo, setCommentDataInfo] = useState<Array<any>>([]);
  const [filteredCommentData, setFilteredCommentData] = useState<any>([]);
  const [filteredCommentData1, setFilteredCommentData1] = useState<any>([]);

  const [noDataFilter, setNoDataFilter] = useState('');
  const [noDataFilter1, setNoDataFilter1] = useState('');

  const [showViewMore, setShowViewMore] = useState('');
  const [loader, setLoader] = useState(true);

  const [activeIndexApp, setActiveIndexApp] = useState('');
  const changeActiveApp = (id: string) => {
    //setLoader(true);
    setActiveIndexApp(id);
    setTimeout(() => {
      setLoader(false);
    }, 4000);
  };

  const changeActiveBackApp = () => {
    setActiveIndexApp('');
  };

  useEffect(() => {
    setCommentDataInfo(CommentsData);
  }, []);
  const searchList = (e: any) => {
    setNoDataFilter(e.target.value);
    const filteredCommentInfo = filterByValue(CommentsData.Second_Application_Comments, e.target.value);
    setFilteredCommentData(filteredCommentInfo);
  };
  const searchList1 = (e: any) => {
    setNoDataFilter1(e.target.value);
    const filteredCommentInfo1 = filterByValue(CommentsData.First_Application_Comments, e.target.value);

    setFilteredCommentData1(filteredCommentInfo1);
  };
  return (
    <>
      {!CommentsData.Second_Application_Comments&& !CommentsData.First_Application_Comments?"":((CommentsData.Second_Application_Comments?.length || CommentsData.First_Application_Comments.length)?"":(<h6 style={{textAlign:'center'}}>No Data</h6>))}
      {(!CommentsData.Second_Application_Comments)?null      
      :
      (CommentsData.Second_Application_Comments?.length ?
      <div
      className="accordion accordion-details-wrap text-left"
      id="accordion-application-details">
      <div className="row">    
        <div
          className="col-md-12 left-align"
          style={{backgroundColor: '#f8f7f7', padding: '5px',marginLeft:'10px'}}>
          <span className="eac-heading-title">2<sup>nd</sup> Application Comments</span>
          <span className="">
          {activeIndexApp == '1' ? (
            <div
              onClick={changeActiveBackApp}
              data-toggle="collapse"
              data-target="#2comments-expand"
              role="button"
              aria-expanded="false"
              aria-controls="2comments-expand"
              className="hide-icon pull-right hide-icon-details"
              id="show-1">
              <img src={Minus} className="img-fluid" alt="SISFS Logo" />
            </div>
          ) : (
            <div
              onClick={() => changeActiveApp('1')}
              id="hide-1"
              data-toggle="collapse"
              data-target="#2comments-expand"
              role="button"
              aria-expanded="false"
              aria-controls="2comments-expand"
              className="expand-icon pull-right expand-icon-details">
              <img src={Plus} className="img-fluid" alt="SISFS Logo" />
            </div>
          )}
        </span>
        </div>
        
      </div>

      
      {activeIndexApp == '1' ? (
        <div className="collapse " id="2comments-expand">
          
          <div className="card accordion-card">
        <div className="card-body">
          <div className="row incubator-heading-review">
            <div className="col-md-6 text-left">
              <p className="dashboard pt-2 pl-3">Comment History</p>
            </div>
            <div className="col-md-6 text-right">
              <div className="d-flex searchDiv">
                {' '}
                <img src={search} className="search" alt="search" />
                <input
                  className="searchBar"
                  type="text"
                  placeholder="Search.."
                  name="search"
                  onKeyUp={searchList}
                />
                {/* <img src={CalendarIcon} className="calendar-icon review-calendar-icon" alt="Calendar icon to select date" /> */}
              </div>
            </div>
          </div>
          {role == 'dpiit' && (
              <div className="table-scroll">
                <table className="reviewTable contactUsTable">
                  <thead>
                    <tr>
                      <th className="table-heading">Application No.</th>
                      <th className="table-heading">Commentator</th>
                      <th className="table-heading">Date</th>
                      <th className="table-heading">Comments</th>
                    </tr>
                  </thead>
                  <tbody>
                   

                    {CommentsData.Second_Application_Comments == undefined ||
                    (filteredCommentData == 0 &&
                      noDataFilter != '') ? (
                      <tr>
                        <td className="text-center" colSpan={3}>
                          No data
                        </td>
                      </tr>
                    ) : filteredCommentData== 0 ? (
                      CommentsData.Second_Application_Comments.map(
                        (item: any, index: any) => (
                          <tr key={index}>
                            <td className="text-left custom-td">
                              {item.incubator_application_no}
                            </td>
                            <td
                              className={
                                item.roles == 'dpiit'
                                  ? 'text-left custom-td uppercase'
                                  : 'text-left custom-td capitize'
                              }>
                              {item.roles}
                            </td>
                            <td className="text-left custom-td">
                              {FormatDate(item.created_at)}
                            </td>
                            {item.dpiit_status_comment != null ? (
                              <td className="text-left custom-td">
                                {showViewMore != 'show_' + index && (
                                  <>
                                    <div>
                                      {showEllipsisText(
                                        item.dpiit_status_comment,
                                        50,
                                      )}
                                    </div>
                                    {item.dpiit_status_comment.length >= 50 && (
                                      <span
                                        className="link-orange-text d-block"
                                        onClick={() =>
                                          setShowViewMore('show_' + index)
                                        }>
                                        View More
                                      </span>
                                    )}
                                  </>
                                )}
                                {showViewMore == 'show_' + index && (
                                  <>
                                    <div>{item.dpiit_status_comment}</div>
                                    <span
                                      className="link-orange-text d-block"
                                      onClick={() => setShowViewMore('')}>
                                      View Less
                                    </span>
                                  </>
                                )}
                              </td>
                            ) : (
                              <td className="text-left custom-td">-</td>
                            )}
                          </tr>
                        ),
                      )
                    ) : (
                      filteredCommentData.map(
                        (item: any, index: any) => (
                          <tr key={index}>
                            <td className="text-left custom-td">
                              {item.incubator_application_no}
                            </td>
                            <td
                              className={
                                item.roles == 'dpiit'
                                  ? 'text-left custom-td uppercase'
                                  : 'text-left custom-td capitize'
                              }>
                              {item.roles}
                            </td>
                            <td className="text-left custom-td">
                              {FormatDate(item.created_at)}
                            </td>
                            {item.dpiit_status_comment != null ? (
                              <td className="text-left custom-td">
                                {showViewMore != 'show_' + index && (
                                  <>
                                    <div>
                                      {showEllipsisText(
                                        item.dpiit_status_comment,
                                        50,
                                      )}
                                    </div>
                                    {item.dpiit_status_comment.length >= 50 && (
                                      <span
                                        className="link-orange-text d-block"
                                        onClick={() =>
                                          setShowViewMore('show_' + index)
                                        }>
                                        View More
                                      </span>
                                    )}
                                  </>
                                )}
                                {showViewMore == 'show_' + index && (
                                  <>
                                    <div>{item.dpiit_status_comment}</div>
                                    <span
                                      className="link-orange-text d-block"
                                      onClick={() => setShowViewMore('')}>
                                      View Less
                                    </span>
                                  </>
                                )}
                              </td>
                            ) : (
                              <td className="text-left custom-td">-</td>
                            )}
                          </tr>
                        ),
                      )
                    )}
                  </tbody>
                </table>
              </div>
            )}
            {role == 'incubator' && (
              <div className="table-scroll">
                <table className="reviewTable contactUsTable">
                  <thead>
                    <tr>
                      <th className="table-heading">Application No.</th>

                      <th className="table-heading">Status</th>
                      <th className="table-heading">Date</th>
                      <th className="table-heading">Comments</th>
                    </tr>
                  </thead>
                  <tbody>
                    {CommentsData.Second_Application_Comments.length == 0 ||
                    (filteredCommentData.Second_Application_Comments.length ==
                      0 &&
                      noDataFilter1 != '') ? (
                      <tr>
                        <td className="text-center" colSpan={3}>
                          No data
                        </td>
                      </tr>
                    ) : filteredCommentData.Second_Application_Comments.length ==
                      0 ? (
                      CommentsData.Second_Application_Comments.map(
                        (item: any, index: any) => (
                          <tr key={index}>
                            <td className="text-left custom-td">
                              {item.incubator_application_no}
                            </td>

                            <td
                              className={
                                item.roles == 'dpiit'
                                  ? 'text-left custom-td uppercase'
                                  : 'text-left custom-td capitize'
                              }>
                              {item.label}
                            </td>
                            <td className="text-left custom-td">
                              {FormatDate(item.created_at)}
                            </td>
                            {item.incubator_status_comment != null ? (
                              <td className="text-left custom-td">
                                {showViewMore != 'show_' + index && (
                                  <>
                                    <div>
                                      {showEllipsisText(
                                        item.incubator_status_comment,
                                        50,
                                      )}
                                    </div>
                                    {item.incubator_status_comment.length >=
                                      50 && (
                                      <span
                                        className="link-orange-text d-block"
                                        onClick={() =>
                                          setShowViewMore('show_' + index)
                                        }>
                                        View More
                                      </span>
                                    )}
                                  </>
                                )}
                                {showViewMore == 'show_' + index && (
                                  <>
                                    <div>{item.incubator_status_comment}</div>
                                    <span
                                      className="link-orange-text d-block"
                                      onClick={() => setShowViewMore('')}>
                                      View Less
                                    </span>
                                  </>
                                )}
                              </td>
                            ) : (
                              <td className="text-left custom-td">-</td>
                            )}
                          </tr>
                        ),
                      )
                    ) : (
                      filteredCommentData.Second_Application_Comments.map(
                        (item: any, index: any) => (
                          <tr key={index}>
                            <td className="text-left custom-td">
                              {item.incubator_application_no}
                            </td>

                            <td
                              className={
                                item.roles == 'dpiit'
                                  ? 'text-left custom-td uppercase'
                                  : 'text-left custom-td capitize'
                              }>
                              {item.label}
                            </td>
                            <td className="text-left custom-td">
                              {FormatDate(item.created_at)}
                            </td>
                            {item.incubator_status_comment != null ? (
                              <td className="text-left custom-td">
                                {showViewMore != 'show_' + index && (
                                  <>
                                    <div>
                                      {showEllipsisText(
                                        item.incubator_status_comment,
                                        50,
                                      )}
                                    </div>
                                    {item.incubator_status_comment.length >=
                                      50 && (
                                      <span
                                        className="link-orange-text d-block"
                                        onClick={() =>
                                          setShowViewMore('show_' + index)
                                        }>
                                        View More
                                      </span>
                                    )}
                                  </>
                                )}
                                {showViewMore == 'show_' + index && (
                                  <>
                                    <div>{item.incubator_status_comment}</div>
                                    <span
                                      className="link-orange-text d-block"
                                      onClick={() => setShowViewMore('')}>
                                      View Less
                                    </span>
                                  </>
                                )}
                              </td>
                            ) : (
                              <td className="text-left custom-td">-</td>
                            )}
                          </tr>
                        ),
                      )
                    )}
                  </tbody>
                </table>
              </div>
            )}
        </div>
      </div>


        </div>
      ) : (
        ''
      )}
      {/* <div className="incubator-hr">
        <hr />
      </div> */}
      
    </div>:"")
    
    }

      <br />

      {(!CommentsData.First_Application_Comments)?
      null
      :
      (CommentsData.First_Application_Comments.length ?
      <div
        className="accordion accordion-details-wrap text-left"
        id="accordion-application-details">
        <div className="row">
          <div
            className="col-md-12 left-align"
            style={{backgroundColor: '#f8f7f7', padding: '5px',marginLeft:'10px'}}>
            <span className="eac-heading-title">1<sup>st</sup> Application Comments</span>
            <span className="">
          {activeIndexApp == '2' ? (
            <div
              onClick={changeActiveBackApp}
              data-toggle="collapse"
              data-target="#1comments-expand"
              role="button"
              aria-expanded="false"
              aria-controls="1comments-expand"
              className="hide-icon pull-right hide-icon-details"
              id="show-1">
              <img src={Minus} className="img-fluid" alt="SISFS Logo" />
            </div>
          ) : (
            <div
              onClick={() => changeActiveApp('2')}
              id="hide-1"
              data-toggle="collapse"
              data-target="#1comments-expand"
              role="button"
              aria-expanded="false"
              aria-controls="1comments-expand"
              className="expand-icon pull-right expand-icon-details">
              <img src={Plus} className="img-fluid" alt="SISFS Logo" />
            </div>
          )}
        </span>
          </div>
        </div>

        {activeIndexApp == '2' ? (
        <div className="collapse " id="1comments-expand">
          
        <div className="card accordion-card">
          <div className="card-body">
            <div className="row incubator-heading-review">
              <div className="col-md-6 text-left">
                <p className="dashboard pt-2 pl-3">Comment History</p>
              </div>
              <div className="col-md-6 text-right">
                <div className="d-flex searchDiv">
                  {' '}
                  <img src={search} className="search" alt="search" />
                  <input
                    className="searchBar"
                    type="text"
                    placeholder="Search.."
                    name="search"
                    onKeyUp={searchList1}
                  />
                  {/* <img src={CalendarIcon} className="calendar-icon review-calendar-icon" alt="Calendar icon to select date" /> */}
                </div>
              </div>
            </div>
            {role == 'dpiit' && (
              <div className="table-scroll">
                <table className="reviewTable contactUsTable">
                  <thead>
                    <tr>
                      <th className="table-heading">Application No.</th>
                      <th className="table-heading">Commentator</th>
                      <th className="table-heading">Date</th>
                      <th className="table-heading">Comments</th>
                    </tr>
                  </thead>
                  <tbody>
                   

                    {CommentsData.First_Application_Comments == undefined ||
                    (filteredCommentData1 == 0 &&
                      noDataFilter1 != '') ? (
                      <tr>
                        <td className="text-center" colSpan={3}>
                          No data
                        </td>
                      </tr>
                    ) : filteredCommentData1== 0 ? (
                      CommentsData.First_Application_Comments.map(
                        (item: any, index: any) => (
                          <tr key={index}>
                            <td className="text-left custom-td">
                              {item.incubator_application_no}
                            </td>
                            <td
                              className={
                                item.roles == 'dpiit'
                                  ? 'text-left custom-td uppercase'
                                  : 'text-left custom-td capitize'
                              }>
                              {item.roles}
                            </td>
                            <td className="text-left custom-td">
                              {FormatDate(item.created_at)}
                            </td>
                            {item.dpiit_status_comment != null ? (
                              <td className="text-left custom-td">
                                {showViewMore != 'show_' + index && (
                                  <>
                                    <div>
                                      {showEllipsisText(
                                        item.dpiit_status_comment,
                                        50,
                                      )}
                                    </div>
                                    {item.dpiit_status_comment.length >= 50 && (
                                      <span
                                        className="link-orange-text d-block"
                                        onClick={() =>
                                          setShowViewMore('show_' + index)
                                        }>
                                        View More
                                      </span>
                                    )}
                                  </>
                                )}
                                {showViewMore == 'show_' + index && (
                                  <>
                                    <div>{item.dpiit_status_comment}</div>
                                    <span
                                      className="link-orange-text d-block"
                                      onClick={() => setShowViewMore('')}>
                                      View Less
                                    </span>
                                  </>
                                )}
                              </td>
                            ) : (
                              <td className="text-left custom-td">-</td>
                            )}
                          </tr>
                        ),
                      )
                    ) : (
                      filteredCommentData1.map(
                        (item: any, index: any) => (
                          <tr key={index}>
                            <td className="text-left custom-td">
                              {item.incubator_application_no}
                            </td>
                            <td
                              className={
                                item.roles == 'dpiit'
                                  ? 'text-left custom-td uppercase'
                                  : 'text-left custom-td capitize'
                              }>
                              {item.roles}
                            </td>
                            <td className="text-left custom-td">
                              {FormatDate(item.created_at)}
                            </td>
                            {item.dpiit_status_comment != null ? (
                              <td className="text-left custom-td">
                                {showViewMore != 'show_' + index && (
                                  <>
                                    <div>
                                      {showEllipsisText(
                                        item.dpiit_status_comment,
                                        50,
                                      )}
                                    </div>
                                    {item.dpiit_status_comment.length >= 50 && (
                                      <span
                                        className="link-orange-text d-block"
                                        onClick={() =>
                                          setShowViewMore('show_' + index)
                                        }>
                                        View More
                                      </span>
                                    )}
                                  </>
                                )}
                                {showViewMore == 'show_' + index && (
                                  <>
                                    <div>{item.dpiit_status_comment}</div>
                                    <span
                                      className="link-orange-text d-block"
                                      onClick={() => setShowViewMore('')}>
                                      View Less
                                    </span>
                                  </>
                                )}
                              </td>
                            ) : (
                              <td className="text-left custom-td">-</td>
                            )}
                          </tr>
                        ),
                      )
                    )}
                  </tbody>
                </table>
              </div>
            )}
            {role == 'incubator' && (
              <div className="table-scroll">
                <table className="reviewTable contactUsTable">
                  <thead>
                    <tr>
                      <th className="table-heading">Application No.</th>

                      <th className="table-heading">Status</th>
                      <th className="table-heading">Date</th>
                      <th className="table-heading">Comments</th>
                    </tr>
                  </thead>
                  <tbody>
                    {CommentsData.First_Application_Comments.length == 0 ||
                    (filteredCommentData1.First_Application_Comments.length ==
                      0 &&
                      noDataFilter1 != '') ? (
                      <tr>
                        <td className="text-center" colSpan={3}>
                          No data
                        </td>
                      </tr>
                    ) : filteredCommentData1.First_Application_Comments.length ==
                      0 ? (
                      CommentsData.First_Application_Comments.map(
                        (item: any, index: any) => (
                          <tr key={index}>
                            <td className="text-left custom-td">
                              {item.incubator_application_no}
                            </td>

                            <td
                              className={
                                item.roles == 'dpiit'
                                  ? 'text-left custom-td uppercase'
                                  : 'text-left custom-td capitize'
                              }>
                              {item.label}
                            </td>
                            <td className="text-left custom-td">
                              {FormatDate(item.created_at)}
                            </td>
                            {item.incubator_status_comment != null ? (
                              <td className="text-left custom-td">
                                {showViewMore != 'show_' + index && (
                                  <>
                                    <div>
                                      {showEllipsisText(
                                        item.incubator_status_comment,
                                        50,
                                      )}
                                    </div>
                                    {item.incubator_status_comment.length >=
                                      50 && (
                                      <span
                                        className="link-orange-text d-block"
                                        onClick={() =>
                                          setShowViewMore('show_' + index)
                                        }>
                                        View More
                                      </span>
                                    )}
                                  </>
                                )}
                                {showViewMore == 'show_' + index && (
                                  <>
                                    <div>{item.incubator_status_comment}</div>
                                    <span
                                      className="link-orange-text d-block"
                                      onClick={() => setShowViewMore('')}>
                                      View Less
                                    </span>
                                  </>
                                )}
                              </td>
                            ) : (
                              <td className="text-left custom-td">-</td>
                            )}
                          </tr>
                        ),
                      )
                    ) : (
                      filteredCommentData1.First_Application_Comments.map(
                        (item: any, index: any) => (
                          <tr key={index}>
                            <td className="text-left custom-td">
                              {item.incubator_application_no}
                            </td>

                            <td
                              className={
                                item.roles == 'dpiit'
                                  ? 'text-left custom-td uppercase'
                                  : 'text-left custom-td capitize'
                              }>
                              {item.label}
                            </td>
                            <td className="text-left custom-td">
                              {FormatDate(item.created_at)}
                            </td>
                            {item.incubator_status_comment != null ? (
                              <td className="text-left custom-td">
                                {showViewMore != 'show_' + index && (
                                  <>
                                    <div>
                                      {showEllipsisText(
                                        item.incubator_status_comment,
                                        50,
                                      )}
                                    </div>
                                    {item.incubator_status_comment.length >=
                                      50 && (
                                      <span
                                        className="link-orange-text d-block"
                                        onClick={() =>
                                          setShowViewMore('show_' + index)
                                        }>
                                        View More
                                      </span>
                                    )}
                                  </>
                                )}
                                {showViewMore == 'show_' + index && (
                                  <>
                                    <div>{item.incubator_status_comment}</div>
                                    <span
                                      className="link-orange-text d-block"
                                      onClick={() => setShowViewMore('')}>
                                      View Less
                                    </span>
                                  </>
                                )}
                              </td>
                            ) : (
                              <td className="text-left custom-td">-</td>
                            )}
                          </tr>
                        ),
                      )
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>


        </div>
      ) : (
        ''
      )}

        {/* <div className="incubator-hr">
          <hr />
        </div> */}
        
      </div>:"")
      }
    
    </>
  );
}
export default MergedCommentsDetailsComponent;
