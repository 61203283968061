import { FileDownloadModel } from '../../components/dpiit/incubator/ApplicationGeneralDetails';
import { FileUploadModel, TrancheFileUploadModel } from '../FileUploadModel';

export class TrancheData {
  constructor(public trancheDetail: Array<StartupTrancheDetail>) {
    this.trancheDetail = trancheDetail;
  }
}

export class StartupTrancheDetail {
  constructor(
    public startup_application_id: number,
    public startup_tranche_id: number,
    public tranche_number: string = '',
    public approved_amount: number,
    public funds_requested: number,
    public cash_in_hand: string,
    public tranche_requested_date: string,
    public documents: Documents,
    public startup_tranches_statuses_description: string,
    public interest_accumulated_on_funds: number,
    public fund_released: number,
    public date_of_tranche_release: string,
    public startup_tranches_statuses_label: string,
    public comments: string,
    public date_of_release: string,
    public approved_amount_date: string,
    public tranche_status_comment: string,
    public isNextDisbursed:boolean,
    public isPrevDisbursed:boolean,
    public proofDisbursementFlag: boolean, 
    public total_applroved_amount : any,
    public tranche_status_id:any,
    public updated_at:any
  ) {
    this.startup_application_id = startup_application_id;
    this.startup_tranche_id = startup_tranche_id;
    this.tranche_number = tranche_number;
    this.approved_amount = approved_amount;
    this.cash_in_hand = cash_in_hand;
    this.funds_requested = funds_requested;
    this.tranche_requested_date = tranche_requested_date;
    this.documents = documents;
    this.startup_tranches_statuses_description = startup_tranches_statuses_description;
    this.interest_accumulated_on_funds = interest_accumulated_on_funds;
    this.fund_released = fund_released;
    this.date_of_tranche_release = date_of_tranche_release;
    this.startup_tranches_statuses_label = startup_tranches_statuses_label;
    this.comments = comments;
    this.date_of_release = date_of_release;
    this.approved_amount_date = approved_amount_date;
    this.tranche_status_comment = tranche_status_comment;
    this.isNextDisbursed=isNextDisbursed;
    this.isPrevDisbursed=isPrevDisbursed;
    this.proofDisbursementFlag=proofDisbursementFlag;
    this.total_applroved_amount=total_applroved_amount; 
    this.tranche_status_id=tranche_status_id;
    this.updated_at = updated_at;
  }
}

export class Documents {
  constructor(
    public quarterly_reports: Array<FileUploadModelTranche>,
    public supporting_documents: Array<FileUploadModelTranche>,
    public proof_of_disbursement: Array<FileUploadModelTranche>
  ) {
    this.quarterly_reports = quarterly_reports;
    this.supporting_documents = supporting_documents;
    this.proof_of_disbursement = proof_of_disbursement;
  }
}

export class FileUploadModelTranche {
  constructor(
    public file_id: number,
    public file_type_id: number,
    public file: File,
    public ext: string,
    public fileName:string
    
  ) {
    this.file_id = file_id;
    this.file_type_id = file_type_id;
    this.file = file;
    this.ext = ext;
    this.fileName=fileName;
   
  }
}

export class TrancheRequest {
  constructor(
    public startup_application_id: number,
    public startup_tranche_id: number,
    public tranche_number: string = '',
    public amount_required: number,
    public cash_in_hand: string,
    public quarterly_report: Array<TrancheFileUploadModel>,
    public supporting_documents: Array<TrancheFileUploadModel>
  ) {
    this.startup_application_id = startup_application_id;
    this.startup_tranche_id = startup_tranche_id;
    this.tranche_number = tranche_number;
    this.amount_required = amount_required;
    this.cash_in_hand = cash_in_hand;
    this.quarterly_report = quarterly_report;
    this.supporting_documents = supporting_documents;
  }
}

export class AddTranche {
  constructor(
    public tranche_id: number,
    public tranche_number: string,
    public application_id: number,
    public amount_approved: number
  ) {
    this.tranche_id = tranche_id;
    this.tranche_number = tranche_number;
    this.application_id = application_id;
    this.amount_approved = amount_approved;
  }
}

export class UpdateTranche {
  constructor(
    public startup_tranche_id: number,
    public tranche_status_id: number,
    public startup_application_id: number,
    public approved_amount: number,
    public fund_released: number,
    public date_of_release: string,
    public tranch_comment: string,
    public funds_requested: number
  ) {
    this.startup_tranche_id = startup_tranche_id;
    this.tranche_status_id = tranche_status_id;
    this.startup_application_id = startup_application_id;
    this.approved_amount = approved_amount;
    this.fund_released = fund_released;
    this.date_of_release = date_of_release;
    this.tranch_comment = tranch_comment;
    this.funds_requested = funds_requested;
  }
}
