import React, { useEffect, useState } from "react";
import Stepper from "bs-stepper";
import FileUploader from "@amodv/react-file-uploader";
import { IncubatorOthersModel } from "../../../models/incubator/IncubatorOthersModel";
import { requestType, StepperEnum, UploadTypeEnum } from "../../../utils/Enums";
import { FileUploadModel } from "../../../models/FileUploadModel";
import { get, post } from "../../../effects/api/api.common";
import { FILE_REMOVE, FILE_UPLOAD, EVALUATION_FILE, NODE_URL } from "../../../constants/api";
import { Link, useHistory } from "react-router-dom";
import { downloadDocument } from "../../../utils/CommonMethods";
import ConfirmationDialog from "../../common/controls/ConfirmationDialog";
import error from '../../../images/error.svg';
import { getSessionStorageValue } from "../../../effects/utils/session-storage";
import pdf from "../../../images/PDF.svg";
import xls from "../../../images/xls.svg";
import doc from "../../../images/doc.svg";
import xlsx from "../../../images/xls.svg";
import Allround from "../../../images/Allround.svg";
interface IIncubatorOtherDetails {
  stepper: any;
  incubatorOthersDetailsModel: IncubatorOthersModel;
  onStepSave: (
    stepperIndex: number,
    incubatorOthersDetailsModel: IncubatorOthersModel,
    request: number
  ) => void;
  applicationId: number;
  errors: Array<string>;
  disabled: boolean;
  disableForm: any;
  functions:any;
}

interface IIKeyValueIndexer {
  [key: string]: boolean;
}

function OtherDetailsComponent(props: IIncubatorOtherDetails) {
  const {
    stepper,
    incubatorOthersDetailsModel,
    onStepSave,
    applicationId,
    disabled,
    disableForm,
    functions
  } = props;

  const [otherDetail, setOtherDetail] = useState<IncubatorOthersModel>(
    new IncubatorOthersModel("", [], [], [], [], false, false, false, false, [])
  );

  const [openUploadBlock, setOpenUploadBlock] = useState<IIKeyValueIndexer>();
  const [agreeTermsBlock, setAgreeTermsBlock] = useState(false);
  const [excelSheetPath, setExcelSheetPath] = useState('');
  const [pdfOnly, setPdfOnly] = useState<IIKeyValueIndexer>();
  const [PPTPath, setPPTPath] = useState('');
  const [selfDeclarationPath, setSelfDeclarationPath] = useState('');
  const [loader, setLoader] = useState(false);
  const [exceedFileSize, setExceedFileSize] = useState(false)
  const [failDialogData, setFailDialogData] = useState({
    header: "",
    text: "",
  });
  const [showIncubatorFailureModal, setShowIncubatorFailureModal] =
    useState(false);

  const dismissModal = () => {
    setShowIncubatorFailureModal(false);
    setExceedFileSize(false);
  };

  useEffect(() => {
    setOtherDetail(otherDetail);
    //console.log("otherDetailCall", otherDetail)
  }, [
    otherDetail.upload_documents_composition_incubator,
    otherDetail.upload_documents_incubation_support,
    otherDetail.upload_documents_mentorship_provided,
    otherDetail.upload_documents_events_organized,
    // incubatorOthersDetailsModel.relavent_document_excel,
    // incubatorOthersDetailsModel.relavent_document_other,
    // incubatorOthersDetailsModel.relavent_document_pitchdeck,
  ]);

  useEffect(() => {
    //console.log("updatedother", incubatorOthersDetailsModel)
    setOtherDetail(incubatorOthersDetailsModel);
    getExcelFilePath();
  }, [incubatorOthersDetailsModel.relavent_document_excel,
  incubatorOthersDetailsModel.relavent_document_other,
  incubatorOthersDetailsModel.relavent_document_pitchdeck]);

  const getExcelFilePath = () => {
    get(EVALUATION_FILE).then((response: any) => {
      if (!response.data) return;
      setExcelSheetPath(response.data.datasheetPath);
      setPPTPath(response.data.eacDeckPath);
      setSelfDeclarationPath(response.data.selfDeclarationPath);
    });
  }
  const [popUpName, setPopUpName] = useState("");
  const setMeetingModal = (fieldName: string) => {
    setPopUpName(fieldName);
  };
  /** upload the files */
  const onFileUploadOther = (files: Array<File>, type: string) => {
    //console.log("testinggggggggggggggggggggggggggggggggggggggggggggggggg", files,)
    let indexes: Array<string> = [];
    let updatedOtherDetail = Object.assign({}, otherDetail);
    for (const file of files) {
      // if already upladed then skip
      const fileData = otherDetail as any;
      let updatedFileData: Array<FileUploadModel> = [];
      // const fileExists = fileData[type].findIndex(
      //   (uf: FileUploadModel) => uf.file.name === file.name
      // );

      // if (fileExists > -1) continue;
      const user: any = getSessionStorageValue("user");
      const userInfo = JSON.parse(user)
      const formData = new FormData();
      // Update the formData object
      formData.append("file", file, file.name);
      formData.append("application_id", applicationId.toString());
      formData.append("file_type", type);
      formData.append("logged_user_id", userInfo.id)
      // let updatedOtherDetail = JSON.parse(JSON.stringify(otherDetail));


      //console.log("updatedotherdetail2", updatedOtherDetail)
      setLoader(true);

      post(FILE_UPLOAD, formData).then((response: any) => {
        setLoader(false);
        if (!response.data) return;
        // let updatedOtherDetail = JSON.parse(JSON.stringify(otherDetail))
        if (response.data.result.message == "File already exists.") {
          // setFailDialogData({
          //     "header":"Oops",
          //     "text":response.data.result.message     
          //   })
          //   setShowIncubatorFailureModal1(true);  
          return;

        } else {
          switch (type) {
            case UploadTypeEnum.RelaventDocumentExcel:
              updatedFileData = [
                ...updatedOtherDetail.relavent_document_excel.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                new FileUploadModel(response.data.data.file_id, 0, file)
              ];
              updatedOtherDetail.relavent_document_excel = updatedFileData
              // updatedOtherDetail.relavent_document_excel = updatedFileData;
              // setOtherDetail(updatedOtherDetail);
              setOtherDetail(prevState => {
                const updatedFileOther = {
                  ...prevState,
                  relavent_document_excel: [
                    ...prevState.relavent_document_excel.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                    new FileUploadModel(response.data.data.file_id, 0, file)
                  ]
                }
                return updatedFileOther;
              });
              onStepSave(
                StepperEnum.OtherDetail,
                updatedOtherDetail,
                requestType.propertyChange
              );

              break;

            case UploadTypeEnum.ReleventDocumentPitchDeck:
              updatedFileData = [
                ...updatedOtherDetail.relavent_document_pitchdeck.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                new FileUploadModel(response.data.data.file_id, 0, file)
              ];

              updatedOtherDetail.relavent_document_pitchdeck = updatedFileData;
              // setOtherDetail(updatedOtherDetail);
              setOtherDetail(prevState => {
                const updatedFileOther = {
                  ...prevState,
                  relavent_document_pitchdeck: [
                    ...prevState.relavent_document_pitchdeck.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                    new FileUploadModel(response.data.data.file_id, 0, file)
                  ]
                }
                return updatedFileOther;
              });
              onStepSave(
                StepperEnum.OtherDetail,
                updatedOtherDetail,
                requestType.propertyChange
              );
              break;

            case UploadTypeEnum.RelaventDocumentOther:
              updatedFileData = [
                ...updatedOtherDetail.relavent_document_other.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                new FileUploadModel(response.data.data.file_id, 0, file)
              ];
              updatedOtherDetail.relavent_document_other = updatedFileData;
              // setOtherDetail(updatedOtherDetail);
              setOtherDetail(prevState => {
                const updatedFileOther = {
                  ...prevState,
                  relavent_document_other: [
                    ...prevState.relavent_document_other.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                    new FileUploadModel(response.data.data.file_id, 0, file)
                  ]
                }
                return updatedFileOther;
              });
              onStepSave(
                StepperEnum.OtherDetail,
                updatedOtherDetail,
                requestType.propertyChange
              );
              break;

            case UploadTypeEnum.TeamMemberCV:
              // updatedFileData = [
              //   ...updatedOtherDetail.team_members_cv.map((uf:any):FileUploadModel=>{return new FileUploadModel(uf.file_id,uf.file_type_id,uf.file)}),
              //   new FileUploadModel(response.data.data.file_id, 0, file as File)
              //   // new FileUploadModel(0, 0, file),
              // ];
              updatedFileData = [
                ...updatedOtherDetail.team_members_cv.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                new FileUploadModel(response.data.data.file_id, 0, file)
                // new FileUploadModel(0, 0, file),
              ];
              updatedOtherDetail.team_members_cv = updatedFileData;
              //console.log("testingnow", updatedFileData)
              // updatedOtherDetail.team_members_cv = updatedFileData;
              // setOtherDetail(updatedOtherDetail);
              setOtherDetail(prevState => {
                const updatedFileOther = {
                  ...prevState,
                  team_members_cv: [
                    ...prevState.team_members_cv.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                    new FileUploadModel(response.data.data.file_id, 0, file)
                  ]
                }
                return updatedFileOther;
              });
              // setOtherDetail((prevState:any) => {
              //   return {...prevState, updatedOtherDetail}
              //   });
              onStepSave(
                StepperEnum.OtherDetail,
                updatedOtherDetail,
                requestType.propertyChange
              );
              break;

            case UploadTypeEnum.SelfDeclarationUpload:
              updatedFileData = [
                ...updatedOtherDetail.self_declaration_upload.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                new FileUploadModel(response.data.data.file_id, 0, file)
              ];

              updatedOtherDetail.self_declaration_upload = updatedFileData;
              setOtherDetail(prevState => {
                const updatedFileOther = {
                  ...prevState,
                  self_declaration_upload: [
                    ...prevState.self_declaration_upload.map((uf: any): FileUploadModel => { return new FileUploadModel(uf.file_id, uf.file_type_id, uf.file) }),
                    new FileUploadModel(response.data.data.file_id, 0, file)
                  ]
                }
                return updatedFileOther;
              });

              onStepSave(
                StepperEnum.OtherDetail,
                updatedOtherDetail,
                requestType.propertyChange
              );
              break;

            default:
              onStepSave(
                StepperEnum.OtherDetail,
                updatedOtherDetail,
                requestType.propertyChange
              );
              break;
          }

          // onStepSave(
          //   StepperEnum.OtherDetail,
          //   updatedOtherDetail,
          //   requestType.propertyChange
          // );


          // de-allocate references.
          formData.delete("file");
          formData.delete("application_id");
          formData.delete("file_type");

          setPdfOnly({ ...pdfOnly, [type]: false });
          setLoader(false);
        }
      });
    }



    if (indexes.length) {
      indexes.map((ind) => {
        const position: number = files.findIndex((file) => file.name === ind);
        files.splice(position, 1);
      });
    }
  };

  const onFileUploadOtherSelfDeclaration = (files: Array<File>, type: string) => {
    let indexes: Array<string> = [];
    let updatedOtherDetail = Object.assign({}, otherDetail);
    if (files.length > 10) {
      setShowIncubatorFailureModal(true);
      return;
    } else if (files.length <= 10) {
      const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user)
        const formData = new FormData();
      formData.append("application_id", applicationId.toString());
      formData.append("file_type", type);
      formData.append("logged_user_id", userInfo.id)
      for (let i=0; i<= files.length; i++) {
        // if already upladed then skip
        const fileData = otherDetail as any;
        let updatedFileData: Array<FileUploadModel> = [];
        // const fileExists = fileData[type].findIndex(
        //   (uf: FileUploadModel) => uf.file.name === file.name
        // );

        // if (fileExists > -1) continue;
        
        // Update the formData object
        formData.append("file", files[i], files[i].name);
        console.log(files[i].name);
        if(i == files.length-1){
          setLoader(true);
          post(FILE_UPLOAD, formData).then((response: any) => {;
            if (!response.data) return;
            setLoader(false)
            functions(applicationId);
          });
        }
        
      }
    }


  };

  const getFileDetails = (file: any, key: string) => {
    let file_name = '';
    file_name =
      file != undefined && file != null && file.length > 1
        ? file.substr(file.lastIndexOf('/') + 1, file.length)
        : '';

    const file_type = file != undefined && file != null && file.length > 1
      ? file.substr(
        file.lastIndexOf('.') + 1,
        file.length
      ) : '';

    if (key == 'name') return file_name;
    if (key == 'file') return file != undefined ? file.toString() : '';
    if (key == 'type') return file != undefined ? file_type.toString() : '';
  };

  // Triggers when delete file is clicked
  const onFileDelete = (file: File, type: string) => {
    const {
      relavent_document_excel,
      relavent_document_pitchdeck,
      relavent_document_other,
      team_members_cv,
      self_declaration_upload,
    } = otherDetail;
    let remainingFiles: Array<FileUploadModel> = [];
    let fileToBeDeleted: any;

    switch (type) {
      case UploadTypeEnum.RelaventDocumentExcel:
        fileToBeDeleted = relavent_document_excel.find(
          (uploadedFile: FileUploadModel) => getFileDetails(uploadedFile.file, 'name') === file.name
        );

        if (!fileToBeDeleted) return;
        remainingFiles = relavent_document_excel.filter(
          (uf: FileUploadModel) => uf.file !== fileToBeDeleted.file
        );

        otherDetail.relavent_document_excel = remainingFiles;
        onStepSave(
          StepperEnum.OtherDetail,
          otherDetail,
          requestType.propertyChange
        );
        break;

      case UploadTypeEnum.ReleventDocumentPitchDeck:
        fileToBeDeleted = relavent_document_pitchdeck.find(
          (uploadedFile: FileUploadModel) => getFileDetails(uploadedFile.file, 'name') === file.name
        );

        if (!fileToBeDeleted) return;
        remainingFiles = relavent_document_pitchdeck.filter(
          (uf: FileUploadModel) => uf.file_id !== fileToBeDeleted.file_id
        );
        otherDetail.relavent_document_pitchdeck = remainingFiles;
        onStepSave(
          StepperEnum.OtherDetail,
          otherDetail,
          requestType.propertyChange
        );
        break;

      case UploadTypeEnum.RelaventDocumentOther:
        fileToBeDeleted = relavent_document_other.find(
          (uploadedFile: FileUploadModel) => getFileDetails(uploadedFile.file, 'name') === file.name
        );

        if (!fileToBeDeleted) return;
        remainingFiles = relavent_document_other.filter(
          (uf: FileUploadModel) => uf.file_id !== fileToBeDeleted.file_id
        );
        otherDetail.relavent_document_other = remainingFiles;
        onStepSave(
          StepperEnum.OtherDetail,
          otherDetail,
          requestType.propertyChange
        );
        break;

      case UploadTypeEnum.TeamMemberCV:
        fileToBeDeleted = team_members_cv.find(
          (uploadedFile: FileUploadModel) => getFileDetails(uploadedFile.file, 'name') === file.name
        );

        if (!fileToBeDeleted) return;
        remainingFiles = team_members_cv.filter(
          (uf: FileUploadModel) => uf.file !== fileToBeDeleted.file
        );
        otherDetail.team_members_cv = remainingFiles;
        onStepSave(
          StepperEnum.OtherDetail,
          otherDetail,
          requestType.propertyChange
        );
        break;

      case UploadTypeEnum.SelfDeclarationUpload:
        fileToBeDeleted = self_declaration_upload.find(
          (uploadedFile: FileUploadModel) => getFileDetails(uploadedFile.file, 'name') === file.name
        );

        if (!fileToBeDeleted) return;
        remainingFiles = self_declaration_upload.filter(
          (uf: FileUploadModel) => uf.file !== fileToBeDeleted.file
        );
        otherDetail.self_declaration_upload = remainingFiles;
        onStepSave(
          StepperEnum.OtherDetail,
          otherDetail,
          requestType.propertyChange
        );
        break;
    }
    // delete the file
    setLoader(true);
    post(FILE_REMOVE, {
      application_id: applicationId,
      file_id: fileToBeDeleted.file_id,
    }).then((response: any) => {
      if (response.data) {
        setLoader(false)
        setOtherDetail(otherDetail);
      }
    });
    setOtherDetail(JSON.parse(JSON.stringify(otherDetail)));
  };

  // handle all the check-box event
  const handleOnCheck = (e: any) => {
    const isChecked = e.target.checked;
    setAgreeTermsBlock(isChecked);
  };
  const history = useHistory();

  const applicationPage = () => {
    history.push({
      pathname: "/incubator_status",
    });
  }
  // triggers when user clicks on the save and next button
  const onSave = () => {
    onStepSave(StepperEnum.OtherDetail, otherDetail, requestType.save);
    window.scrollTo(0, 250);
  };

  // triggers when user clicks on the submit button
  const onSubmit = (action?: string) => {
    // before passing data to the parent component do validate the form
    otherDetail.action = action === "submit" ? action : "";
    onStepSave(StepperEnum.OtherDetail, otherDetail, requestType.submit);
  };

  // handle checkboxes of upload XL
  const handleCheckbox = (e: any) => {
    const name = e.target.name;
    const checked = e.target.checked;

    let updatedOtherDetail = JSON.parse(JSON.stringify(otherDetail));
    updatedOtherDetail[name] = checked;

    setOtherDetail(updatedOtherDetail);

    onStepSave(
      StepperEnum.OtherDetail,
      updatedOtherDetail,
      requestType.propertyChange
    );
  };

  return (
    <div
      id="incubator-step-5"
      role="tabpanel"
      className="bs-stepper-pane content"
      aria-labelledby="stepper1trigger5"
    >
      {loader &&
        <div className="spinner-border custom-spinner" role="status">
          <span className="sr-only">Loading...</span>
        </div>}
      {/* {console.log("VALIDATION", props.errors)} */}
      {/* Incubator Stepper Form 5 */}
      <fieldset disabled={loader}>
        <div className="form-wrapper stepper-form stepper-form-5">
          <div className="form-content">
            <div className="form-group required row">
              <label className="col-sm-4 col-form-label">
                Upload Relevant Documents
              </label>
              <div className="col-sm-8">
                <div className="form-row mb-1">
                  <div className="col">
                    <button className="btn btn-sisfs btn-transparent-primary"
                    //  onClick={() => downloadDocument(excelSheetPath, 'Incubator_Application_Datasheet')}
                    >
                      {/* <Link className="download-excel-link" to="../../../files/Datasheet.xlsx" target="_blank" download> */}
                      {/* Download EXCEL */}
                      <a style={{ textDecoration: 'none', color: '#25429c', fontSize: '14px' }}
                        href={`${NODE_URL}` + excelSheetPath}>
                        Download EXCEL
                      </a>
                      {/* </Link> */}
                    </button>


                    {/* <Link to={require("../../../files/IncubatorApplicationForm.docx")}>
                    Download XL
                  </Link> */}
                    <ul>
                      <li>Please download Excel sheet to share details</li>
                      <li>
                        Please fill Excel sheet without changing its format.
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="form-row mb-1">
                  <div className="col">
                    <span className="col-label">
                      Please make sure to have below information filled in the
                      excel before uploading the file
                    </span>

                    {/* New Addition */}
                    <fieldset disabled={disableForm}>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="upload_documents_incubation_support"
                          name="upload_documents_incubation_support"
                          onChange={handleCheckbox}
                          checked={otherDetail.upload_documents_incubation_support}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="upload_documents_incubation_support"
                        >
                          Details on incubation support provided in last three years
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="upload_documents_mentorship_provided"
                          name="upload_documents_mentorship_provided"
                          onChange={handleCheckbox}
                          checked={otherDetail.upload_documents_mentorship_provided}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="upload_documents_mentorship_provided"
                        >
                          Mentorship provided to incubatees in last three years
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          name="upload_documents_composition_incubator"
                          id="upload_documents_composition_incubator"
                          onChange={handleCheckbox}
                          checked={
                            otherDetail.upload_documents_composition_incubator
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="upload_documents_composition_incubator"
                        >
                          Composition of Incubator Seed Management Committee (ISMC)
                        </label>
                      </div>

                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          name="upload_documents_events_organized"
                          id="upload_documents_events_organized"
                          onChange={handleCheckbox}
                          checked={
                            otherDetail.upload_documents_events_organized
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="upload_documents_events_organized"
                        >
                          Details on events organised in last three years
                        </label>
                      </div>

                    </fieldset>
                  </div>
                </div>
                {/* New Addition */}

                <div className="form-row">
                  <div className="col">
                    <span className="col-label">
                      Upload the filled Excel Sheet
                    </span>
                    <div className="">
                      <fieldset disabled={disableForm}>
                        <button
                          className="btn btn-sisfs btn-transparent-primary"
                          onClick={() => {
                            setOpenUploadBlock({ UploadExcel: true });
                          }}
                          disabled={
                            !(
                              otherDetail.upload_documents_composition_incubator ===
                              true &&
                              otherDetail.upload_documents_incubation_support ===
                              true &&
                              otherDetail.upload_documents_mentorship_provided ===
                              true &&
                              otherDetail.upload_documents_events_organized ===
                              true
                            )
                          }
                        >
                          Upload XL
                        </button>
                        {((openUploadBlock &&
                          openUploadBlock["UploadExcel"] &&
                          otherDetail.relavent_document_excel.length === 0) ||
                          otherDetail.relavent_document_excel.length > 0) && (
                            <>
                              <span
                                className="close"
                                onClick={() =>
                                  setOpenUploadBlock({ UploadExcel: false })
                                }
                              >
                                <i className="fa fa-close"></i>
                              </span>

                              <FileUploader
                                // key={UploadTypeEnum.RelaventDocumentExcel}
                                key={Math.random().toString()}
                                id={UploadTypeEnum.RelaventDocumentExcel}
                                information="Drag & Drop the file or Browse"
                                fileType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                buttonLabel="Browse"
                                multiple={false}
                                enablePreview
                                IconComponent={() => (
                                  <span className="fa fa-cloud-upload"></span>
                                )}
                                removeIconClass=""
                                existingFiles={otherDetail.relavent_document_excel.map(
                                  (uploadedFile: FileUploadModel) =>
                                  ({
                                    name: getFileDetails(uploadedFile.file, 'name') || uploadedFile.file.name,
                                    url: uploadedFile.file
                                  })
                                  // uploadedFile.file
                                )}
                                onUploadFinish={(files: Array<File>) => {
                                  onFileUploadOther(
                                    files,
                                    UploadTypeEnum.RelaventDocumentExcel
                                  );
                                }}
                                onFileDelete={(deletedFile: File) =>
                                  onFileDelete(
                                    deletedFile,
                                    UploadTypeEnum.RelaventDocumentExcel
                                  )
                                }
                              />
                            </>
                          )}
                      </fieldset>
                      {/* && otherDetail.relavent_document_excel.some((item) => item.file_name) */}
                      {(otherDetail.relavent_document_excel.length >
                        0 && otherDetail.relavent_document_excel[0].file_type_id != 0) ? (
                        <a
                          className="incubator-details-link"
                          data-toggle="modal"
                          data-target="#showMoreOtherDocuments"
                          onClick={() => setMeetingModal("Document Excel")}
                        >
                          View uploaded Documents
                        </a>
                      ) : (
                        ""
                      )}
                      {/* <div>
                      {otherDetail.relavent_document_excel.map((uploadedFile) => (
                          <div key={`${uploadedFile.file}`} >
                              <span className="pdfText">{getFileDetails(uploadedFile.file, 'name')} - </span> 
                              <a href={`${uploadedFile.file}`} download target="blank" className="orange-link-text">
                                  View 
                              </a>
                          </div>
                        ))}
                      </div>    */}
                      <div
                        className={`${props.errors.length &&
                          !otherDetail.relavent_document_excel.length
                          ? `col-12 pl-0 error`
                          : "invalid-feedback "
                          }`}
                      >
                        This is mandatory field
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {//console.log("Existing Files", otherDetail.team_members_cv)} */}
            {/* New Addition for Upload */}
            <div className="form-group required row">
              <label className="col-sm-4 col-form-label">
                Upload CV of each Team Member
              </label>
              <div className="col-sm-8">
                <div className="form-row">
                  <div className="col">
                    <div className="d-inline-flex upload-pitch">
                      <span className="col-label">
                        Only CVs of Full time employees Working for Incubation
                        Center
                      </span>
                    </div>

                    <div className="">
                      <fieldset disabled={disableForm}>
                        <button
                          className="btn btn-sisfs btn-transparent-primary"
                          onClick={() => setOpenUploadBlock({ TeamMemberCV: true })}
                        >
                          Upload
                        </button>
                        {((openUploadBlock &&
                          openUploadBlock["TeamMemberCV"] &&
                          otherDetail.team_members_cv.length === 0) ||
                          otherDetail.team_members_cv.length > 0) && (
                            <>
                              <span
                                className="close"
                                onClick={() =>
                                  setOpenUploadBlock({ TeamMemberCV: false })
                                }
                              >
                                <i className="fa fa-close"></i>
                              </span>
                              {console.log(otherDetail.team_members_cv)}
                              <FileUploader
                                // key={UploadTypeEnum.TeamMemberCV}
                                key={Math.random().toString()}
                                id={UploadTypeEnum.TeamMemberCV}
                                information="Drag & Drop the file or Browse"
                                fileType="application/pdf"
                                buttonLabel="Browse"
                                multiple={true}
                                enablePreview
                                IconComponent={() => (
                                  <span className="fa fa-cloud-upload">
                                  </span>
                                )}
                                removeIconClass=""
                                // .filter((item) => (item.file.size) <= 15 * 1024 * 1024)
                                existingFiles={otherDetail.team_members_cv.map(
                                  (uploadedFile: FileUploadModel) =>
                                  ({
                                    name: getFileDetails(uploadedFile.file, 'name') || uploadedFile.file.name,
                                    url: uploadedFile.file
                                  })
                                )}
                                onUploadFinish={(files: Array<File>) => {
                                  for (const file of files) {
                                    if (file.size <= 15 * 1024 * 1024) {
                                      onFileUploadOtherSelfDeclaration(
                                        files,
                                        UploadTypeEnum.TeamMemberCV
                                      )
                                    } else {
                                      setExceedFileSize(true);
                                      return;
                                    }
                                  }

                                }}
                                onFileDelete={(deletedFile: File) =>
                                  onFileDelete(
                                    deletedFile,
                                    UploadTypeEnum.TeamMemberCV
                                  )
                                }
                              />
                            </>
                          )}
                      </fieldset>
                      {/* && otherDetail.team_members_cv.some((item) => item.file_name) */}
                      {(otherDetail.team_members_cv.length >
                        0 && otherDetail.team_members_cv[0].file_type_id != 0) ? (
                        <a
                          className="incubator-details-link"
                          data-toggle="modal"
                          data-target="#showMoreOtherDocuments"
                          onClick={() => setMeetingModal("TeamMember CV")}
                        >
                          View uploaded Documents
                        </a>
                      ) : (
                        ""
                      )}
                      {/* <div>
                        {otherDetail.team_members_cv.map((uploadedFile) => (
                            <div key={`${uploadedFile.file}`} >
                                <span className="pdfText">{getFileDetails(uploadedFile.file, 'name')} - </span> 
                                <a href={`${uploadedFile.file}`} download target="blank" className="orange-link-text">
                                    View 
                                </a>
                            </div>
                          ))}
                      </div>  */}
                      <small>
                        Supported file format - PDF only | Max. size: 15 MB
                      </small>
                      <div
                        className={`${props.errors.length &&
                          !otherDetail.team_members_cv.length
                          ? `col-12 pl-0 error`
                          : "invalid-feedback "
                          }`}
                      >
                        This is mandatory field
                      </div>
                      <div
                        className={`${pdfOnly?.team_members_cv
                          ? `col-12 pl-0 error`
                          : "invalid-feedback "
                          }`}
                      >
                        Only PDF files are allowed
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* New Addition for Upload */}

            <div className="form-group required row">
              <label className="col-sm-4 col-form-label">Upload Pitch deck</label>
              <div className="col-sm-8">
                <div className="form-row">
                  <div className="col">
                    <div className="d-inline-flex upload-pitch">
                      {/* <span className="col-label">Upload Pitch deck</span> */}
                      <span className="link-orange-text link-underline"
                      // onClick={() => downloadDocument(PPTPath, 'eac_deck')}
                      >
                        <a style={{ color: '#e26640' }}
                          href={`${NODE_URL}` + PPTPath}>
                          <u>Download Sample Pitch Deck</u>
                        </a>
                        {/* <u>Download Sample Pitch Deck</u> */}
                      </span>
                    </div>

                    <div className="">
                      <fieldset disabled={disableForm}>
                        <button
                          className="btn btn-sisfs btn-transparent-primary"
                          onClick={() =>
                            setOpenUploadBlock({ UploadPitchDeck: true })
                          }
                        >
                          Upload
                        </button>

                        {((openUploadBlock &&
                          openUploadBlock["UploadPitchDeck"] &&
                          otherDetail.relavent_document_pitchdeck.length === 0) ||
                          otherDetail.relavent_document_pitchdeck.length > 0) && (
                            <>
                              <span
                                className="close"
                                onClick={() =>
                                  setOpenUploadBlock({ UploadPitchDeck: false })
                                }
                              >
                                <i className="fa fa-close"></i>
                              </span>
                              <FileUploader
                                // key={UploadTypeEnum.ReleventDocumentPitchDeck}
                                key={Math.random().toString()}
                                id={UploadTypeEnum.ReleventDocumentPitchDeck}
                                information="Drag & Drop the file or Browse"
                                fileType="application/pdf"
                                buttonLabel="Browse"
                                multiple={false}
                                enablePreview
                                IconComponent={() => (
                                  <span className="fa fa-cloud-upload"></span>
                                )}
                                removeIconClass=""
                                existingFiles={otherDetail.relavent_document_pitchdeck.filter((item) => (item.file.size) <= 15 * 1024 * 1024).map(
                                  (uploadedFile: FileUploadModel) =>
                                  ({
                                    name: getFileDetails(uploadedFile.file, 'name') || uploadedFile.file.name,
                                    url: uploadedFile.file
                                  })
                                )}
                                onUploadFinish={(files: Array<File>) => {
                                  for (const file of files) {
                                    if (file.size <= 15 * 1024 * 1024) {
                                      onFileUploadOther(
                                        files,
                                        UploadTypeEnum.ReleventDocumentPitchDeck
                                      )
                                    } else {
                                      setExceedFileSize(true);
                                      return;
                                    }
                                  }

                                }}
                                onFileDelete={(deletedFile: File) =>
                                  onFileDelete(
                                    deletedFile,
                                    UploadTypeEnum.ReleventDocumentPitchDeck
                                  )
                                }
                              />
                            </>
                          )}
                      </fieldset>
                      {/* && otherDetail.relavent_document_pitchdeck.some((item) => item.file_name) */}
                      {(otherDetail.relavent_document_pitchdeck.length >
                        0 && otherDetail.relavent_document_pitchdeck[0].file_type_id != 0) ? (
                        <a
                          className="incubator-details-link"
                          data-toggle="modal"
                          data-target="#showMoreOtherDocuments"
                          onClick={() => setMeetingModal("Doc PitchDeck")}
                        >
                          View uploaded Documents
                        </a>
                      ) : (
                        ""
                      )}
                      {/* <div>
                      {otherDetail.relavent_document_pitchdeck.map((uploadedFile) => (
                            <div key={`${uploadedFile.file}`} >
                                <span className="pdfText">{getFileDetails(uploadedFile.file, 'name')} - </span> 
                                <a href={`${uploadedFile.file}`} download target="blank" className="orange-link-text">
                                    View 
                                </a>
                            </div>
                          ))}
                      </div>  */}
                      <small>
                        Supported file format - PDF only | Max. size: 15 MB
                      </small>
                      <small>
                        Please use the template in the Sample Pitch Deck and upload in PDF format
                      </small>
                      <div
                        className={`${props.errors.length &&
                          !otherDetail.relavent_document_pitchdeck.length
                          ? `col-12 pl-0 error`
                          : "invalid-feedback "
                          }`}
                      >
                        This is mandatory field
                      </div>
                      {/* <div
                        className={`${props.errors.length &&
                          !otherDetail.relavent_document_pitchdeck.length
                          ? `col-12 pl-0 error`
                          : "invalid-feedback "
                          }`}
                      >
                        This is mandatory field
                    </div>
                      <div
                        className={`${pdfOnly?.relavent_document_pitchdeck
                          ? `col-12 pl-0 error`
                          : "invalid-feedback "
                          }`}
                      >
                        Only PDF files are allowed
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group required row">
              <label className="col-sm-4 col-form-label">
                Upload Self Declaration
              </label>
              <div className="col-sm-8">
                <div className="d-inline-flex upload-pitch">
                  <span className="link-orange-text link-underline"
                  // onClick={() =>downloadDocument(selfDeclarationPath, 'Self_Declaration')}
                  >
                    <a style={{ color: '#e26640' }}
                      href={`${NODE_URL}` + selfDeclarationPath}>
                      <u>Download Sample Self Declaration</u>
                    </a>
                    {/* <u>Download Sample Self Declaration</u> */}
                  </span>
                </div>


                <div className="form-row">
                  <div className="col">
                    <div className="">
                      <fieldset disabled={disableForm}>
                        <button
                          className="btn btn-sisfs btn-transparent-primary"
                          onClick={() =>
                            setOpenUploadBlock({ SelfDeclarationUpload: true })
                          }
                        >
                          Upload
                        </button>

                        {((openUploadBlock &&
                          openUploadBlock["SelfDeclarationUpload"] &&
                          otherDetail.self_declaration_upload.length === 0) ||
                          otherDetail.self_declaration_upload.length > 0) && (
                            <>
                              <span
                                className="close"
                                onClick={() =>
                                  setOpenUploadBlock({ SelfDeclarationUpload: false })
                                }
                              >
                                <i className="fa fa-close"></i>
                              </span>
                              <FileUploader
                                // key={UploadTypeEnum.RelaventDocumentOther}
                                key={Math.random().toString()}
                                id={UploadTypeEnum.SelfDeclarationUpload}
                                information="Drag & Drop the file or Browse"
                                fileType="application/pdf"
                                buttonLabel="Browse"
                                multiple={true}
                                enablePreview
                                IconComponent={() => (
                                  <span className="fa fa-cloud-upload"></span>
                                )}
                                removeIconClass=""
                                // .filter((item) => (item.file.size) <= 15 * 1024 * 1024)
                                existingFiles={otherDetail.self_declaration_upload.map(
                                  (uploadedFile: FileUploadModel) =>
                                  ({
                                    name: getFileDetails(uploadedFile.file, 'name') || uploadedFile.file.name,
                                    url: uploadedFile.file
                                  })
                                  // uploadedFile.file
                                )}
                                onUploadFinish={(files: Array<File>) => {
                                  for (const file of files) {
                                    if (file.size <= 15 * 1024 * 1024) {
                                      onFileUploadOtherSelfDeclaration(
                                        files,
                                        UploadTypeEnum.SelfDeclarationUpload
                                      )
                                    } else {
                                      setExceedFileSize(true);
                                      return;
                                    }
                                  }

                                }}
                                onFileDelete={(deletedFile: File) =>
                                  onFileDelete(
                                    deletedFile,
                                    UploadTypeEnum.SelfDeclarationUpload
                                  )
                                }
                              />
                            </>
                          )}
                      </fieldset>
                      {/* && otherDetail.self_declaration_upload.some((item) => item.file_name) */}
                      {(otherDetail.self_declaration_upload.length >
                        0 && otherDetail.self_declaration_upload[0].file_type_id != 0) ? (
                        <a
                          className="incubator-details-link"
                          data-toggle="modal"
                          data-target="#showMoreOtherDocuments"
                          onClick={() => setMeetingModal("Self Declaration")}
                        >
                          View uploaded Documents
                        </a>
                      ) : (
                        ""
                      )}
                      {/* <div>
                      {otherDetail.self_declaration_upload.map((uploadedFile) => (
                            <div key={`${uploadedFile.file}`} >
                                <span className="pdfText">{getFileDetails(uploadedFile.file, 'name')} - </span> 
                                <a href={`${uploadedFile.file}`} download target="blank" className="orange-link-text">
                                    View 
                                </a>
                            </div>
                          ))}
                      </div>  */}
                      <small>Supported file format - PDF only | Max. size: 15 MB</small>
                      <small>Upload self-declaration from authorized signatory of incubator</small>
                      <div
                        className={`${props.errors.length &&
                          !otherDetail.self_declaration_upload.length
                          ? `col-12 pl-0 error`
                          : "invalid-feedback "
                          }`}
                      >
                        This is mandatory field
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-4 col-form-label">
                Upload any other relevant document
                <small className="pt-1">( Optional )</small>
              </label>
              <div className="col-sm-8">
                <div className="form-row">
                  <div className="col">
                    {/* <span className="col-label">
                    {' '}
                    Upload any other relevant document
                  </span> */}
                    {/* <button className="btn btn-sisfs btn-transparent-primary">Upload</button> */}
                    <div className="">
                      <fieldset disabled={disableForm}>
                        <button
                          className="btn btn-sisfs btn-transparent-primary"
                          onClick={() =>
                            setOpenUploadBlock({ RelevantUpload: true })
                          }
                        >
                          Upload
                        </button>

                        {((openUploadBlock &&
                          openUploadBlock["RelevantUpload"] &&
                          otherDetail.relavent_document_other.length === 0) ||
                          otherDetail.relavent_document_other.length > 0) && (
                            <>
                              <span
                                className="close"
                                onClick={() =>
                                  setOpenUploadBlock({ RelevantUpload: false })
                                }
                              >
                                <i className="fa fa-close"></i>
                              </span>
                              <FileUploader
                                // key={UploadTypeEnum.RelaventDocumentOther}
                                key={Math.random().toString()}
                                id={UploadTypeEnum.RelaventDocumentOther}
                                information="Drag & Drop the file or Browse"
                                fileType="application/pdf,application/vnd.ms-excel, 
                                        application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, 
                                        application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, 
                                        application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                buttonLabel="Browse"
                                multiple={true}
                                enablePreview
                                IconComponent={() => (
                                  <span className="fa fa-cloud-upload"></span>
                                )}
                                removeIconClass=""
                                existingFiles={otherDetail.relavent_document_other.map(
                                  (uploadedFile: FileUploadModel) =>
                                  ({
                                    name: getFileDetails(uploadedFile.file, 'name') || uploadedFile.file.name,
                                    url: uploadedFile.file
                                  })
                                  // uploadedFile.file
                                )}
                                onUploadFinish={(files: Array<File>) => {
                                  onFileUploadOtherSelfDeclaration(
                                    files,
                                    UploadTypeEnum.RelaventDocumentOther
                                  );
                                }}
                                onFileDelete={(deletedFile: File) =>
                                  onFileDelete(
                                    deletedFile,
                                    UploadTypeEnum.RelaventDocumentOther
                                  )
                                }
                              />
                            </>
                          )}
                      </fieldset>
                      {/* && otherDetail.relavent_document_other.some((item) => item.file_name) */}
                      {(otherDetail.relavent_document_other.length >
                        0 && otherDetail.relavent_document_other[0].file_type_id != 0) ? (
                        <a
                          className="incubator-details-link"
                          data-toggle="modal"
                          data-target="#showMoreOtherDocuments"
                          onClick={() => setMeetingModal("Relavant Doc Other")}
                        >
                          View uploaded Documents
                        </a>
                      ) : (
                        ""
                      )}
                      {/* <div>
                      {otherDetail.relavent_document_other.map((uploadedFile) => (
                            <div key={`${uploadedFile.file}`} >
                                <span className="pdfText">{getFileDetails(uploadedFile.file, 'name')} - </span> 
                                <a href={`${uploadedFile.file}`} download target="blank" className="orange-link-text">
                                    View 
                                </a>
                            </div>
                          ))}
                      </div>  */}
                      <small>Maxi. upload upto 10 documents</small>
                      {/* <div
                        className={`${props.errors.length &&
                          !otherDetail.relavent_document_other.length
                          ? `col-12 pl-0 error`
                          : "invalid-feedback "
                          }`}
                      >
                        This is mandatory field
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <fieldset disabled={disableForm}>
              <div className="form-group row">
                <div className="col-sm-12">
                  <div className="agree-confirm-box">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="agreeincubatorapplication"
                        name="agreeincubatorapplication"
                        checked={disabled ? true : agreeTermsBlock}
                        onChange={handleOnCheck}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="agreeincubatorapplication"
                      >
                        We are in compliance with the provisions of the various
                        Acts, Rules, Regulations, Guidelines, Standards applicable
                        to the entity from time to time. All information provided by
                        us in the application is true, correct and complete and no
                        information material to the subject matter of this form has
                        been suppressed or concealed. If there is any change in this
                        information or compliance status, I will duly inform Startup
                        India within 15 days on{" "}
                        <a href="mailto:dipp-startups@nic.in">
                          <u className="link-orange-text">dipp-startups@nic.in</u>
                        </a>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <fieldset disabled={disableForm}>
            <div className="stepper-footer-section">
              <div>
                <div
                  className="btn-toolbar justify-content-between"
                  role="toolbar"
                  aria-label="Toolbar with button groups"
                >
                  <button className="btn btn-sisfs btn-transparent-primary" onClick={() => applicationPage()}>
                    Cancel
                  </button>
                  <span className="stepper-page-count">Step 5 / 5</span>

                  <div className="btn-group">
                    <button
                      className="btn btn-sisfs btn-transparent-primary"
                      onClick={() => stepper && stepper.previous()}
                    >
                      Previous
                    </button>
                    <button
                      className="btn btn-sisfs btn-transparent-primary"
                      onClick={onSave}
                    >
                      Save
                    </button>
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={() => onSubmit("submit")}
                      disabled={!agreeTermsBlock}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </fieldset>
      {/* Incubator Stepper Form 6 */}

      {showIncubatorFailureModal && (
        <div
          className="modal align-middle modal-sisfs d-block"
          id="FormSubmissionError"
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img
                  src={error}
                  className="search mb-2"
                  alt="search"

                />
                <h4 className="message-orange" style={{ fontWeight: "bold", fontFamily: "Roboto Slab" }}>
                  Oops!!!
                </h4>
                <h4 className="message-orange" style={{ fontWeight: "bold", fontFamily: "Roboto Slab" }}>
                  Please Try Again !!!<br /><br />
                  Only 10 files can be uploaded once
                </h4>
                <div className="modal-action">
                  <button
                    className="btn btn-sisfs btn-yellow-primary"
                    onClick={dismissModal}
                  >
                    OK !
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {exceedFileSize && (
        <div
          className="modal align-middle modal-sisfs d-block"
          id="FormSubmissionError"
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img
                  src={error}
                  className="search mb-2"
                  alt="search"
                  style={{ width: '2rem' }}
                />
                <h4 className="message-orange" style={{ fontFamily: "Roboto Slab" }}>
                  Oops!!!
                </h4>
                <h5 className="message-orange" style={{ fontFamily: "Roboto Slab" }}>
                  File size should not exceed 15Mb<br /><br />
                  Please upload again.
                </h5>
                <div className="modal-action">
                  <button
                    className="btn btn-sisfs btn-yellow-primary"
                    onClick={dismissModal}
                  >
                    OK !
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Show More  */}
      <div
        className="modal fade"
        id="showMoreOtherDocuments"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none ml-18px">
              <h5 className="modal-title " id="exampleModalLongTitle">
                {popUpName} <span className="eac-name-pop-up"></span>
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {/* <div className=""> */}
              {/* 1St Application Documents */}

              {popUpName == "Document Excel" ? (
                otherDetail.relavent_document_excel !=
                  undefined ? (
                  otherDetail.relavent_document_excel.map(
                    (file: any, index: any) => (
                      <div className="row">
                        <div className="col-md-2">
                          {getFileDetails(file.file, "type") == "pdf" ? (
                            <img src={pdf} alt="SISFS Logo" />
                          ) : getFileDetails(file.file, "type") == "doc" ? (
                            <img src={doc} alt="SISFS Logo" />
                          ) : getFileDetails(file.file, "type") == "xls" ? (
                            <img src={xls} alt="SISFS Logo" />
                          ) : (
                            <img src={Allround} alt="SISFS Logo" />
                          )}
                        </div>
                        <div className="col-md-9 left-align">
                          <span className="document-label-value-wrap">
                            {getFileDetails(file.file, "name")}
                          </span>
                          <br />
                          <a
                            // href={file.file}
                            href={file.file}
                            target="_blank"
                            //download="annual_report"
                            className="incubator-details-link"
                          >
                            View
                          </a>
                          {/* /
                            <a
                              href="#"
                              //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <span className="incubator-label-value">-</span>
                )
              ) : (
                ""
              )}
              {popUpName == "TeamMember CV" ? (
                otherDetail.team_members_cv !=
                  undefined ? (
                  otherDetail.team_members_cv.map(
                    (file: any, index: any) => (
                      <div className="row">
                        <div className="col-md-2">
                          {getFileDetails(file.file, "type") == "pdf" ? (
                            <img src={pdf} alt="SISFS Logo" />
                          ) : getFileDetails(file.file, "type") == "doc" ? (
                            <img src={doc} alt="SISFS Logo" />
                          ) : getFileDetails(file.file, "type") == "xls" ? (
                            <img src={xls} alt="SISFS Logo" />
                          ) : (
                            <img src={Allround} alt="SISFS Logo" />
                          )}
                        </div>
                        <div className="col-md-9 left-align">
                          <span className="document-label-value-wrap">
                            {getFileDetails(file.file, "name")}
                          </span>
                          <br />
                          <a
                            // href={file.file}
                            href={file.file}
                            target="_blank"
                            //download="annual_report"
                            className="incubator-details-link"
                          >
                            View
                          </a>
                          {/* /
                            <a
                              href="#"
                              //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <span className="incubator-label-value">-</span>
                )
              ) : (
                ""
              )}
              {popUpName == "Doc PitchDeck" ? (
                otherDetail.relavent_document_pitchdeck !=
                  undefined ? (
                  otherDetail.relavent_document_pitchdeck.map(
                    (file: any, index: any) => (
                      <div className="row">
                        <div className="col-md-2">
                          {getFileDetails(file.file, "type") == "pdf" ? (
                            <img src={pdf} alt="SISFS Logo" />
                          ) : getFileDetails(file.file, "type") == "doc" ? (
                            <img src={doc} alt="SISFS Logo" />
                          ) : getFileDetails(file.file, "type") == "xls" ? (
                            <img src={xls} alt="SISFS Logo" />
                          ) : (
                            <img src={Allround} alt="SISFS Logo" />
                          )}
                        </div>
                        <div className="col-md-9 left-align">
                          <span className="document-label-value-wrap">
                            {getFileDetails(file.file, "name")}
                          </span>
                          <br />
                          <a
                            // href={file.file}
                            href={file.file}
                            target="_blank"
                            //download="annual_report"
                            className="incubator-details-link"
                          >
                            View
                          </a>
                          {/* /
                            <a
                              href="#"
                              //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <span className="incubator-label-value">-</span>
                )
              ) : (
                ""
              )}
              {popUpName == "Self Declaration" ? (
                otherDetail.self_declaration_upload !=
                  undefined ? (
                  otherDetail.self_declaration_upload.map(
                    (file: any, index: any) => (
                      <div className="row">
                        <div className="col-md-2">
                          {getFileDetails(file.file, "type") == "pdf" ? (
                            <img src={pdf} alt="SISFS Logo" />
                          ) : getFileDetails(file.file, "type") == "doc" ? (
                            <img src={doc} alt="SISFS Logo" />
                          ) : getFileDetails(file.file, "type") == "xls" ? (
                            <img src={xls} alt="SISFS Logo" />
                          ) : (
                            <img src={Allround} alt="SISFS Logo" />
                          )}
                        </div>
                        <div className="col-md-9 left-align">
                          <span className="document-label-value-wrap">
                            {getFileDetails(file.file, "name")}
                          </span>
                          <br />
                          <a
                            // href={file.file}
                            href={file.file}
                            target="_blank"
                            //download="annual_report"
                            className="incubator-details-link"
                          >
                            View
                          </a>
                          {/* /
                            <a
                              href="#"
                              //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <span className="incubator-label-value">-</span>
                )
              ) : (
                ""
              )}
              {popUpName == "Relavant Doc Other" ? (
                otherDetail.relavent_document_other !=
                  undefined ? (
                  otherDetail.relavent_document_other.map(
                    (file: any, index: any) => (
                      <div className="row">
                        <div className="col-md-2">
                          {getFileDetails(file.file, "type") == "pdf" ? (
                            <img src={pdf} alt="SISFS Logo" />
                          ) : getFileDetails(file.file, "type") == "doc" ? (
                            <img src={doc} alt="SISFS Logo" />
                          ) : getFileDetails(file.file, "type") == "xls" ? (
                            <img src={xls} alt="SISFS Logo" />
                          ) : (
                            <img src={Allround} alt="SISFS Logo" />
                          )}
                        </div>
                        <div className="col-md-9 left-align">
                          <span className="document-label-value-wrap">
                            {getFileDetails(file.file, "name")}
                          </span>
                          <br />
                          <a
                            // href={file.file}
                            href={file.file}
                            target="_blank"
                            //download="annual_report"
                            className="incubator-details-link"
                          >
                            View
                          </a>
                          {/* /
                            <a
                              href="#"
                              //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <span className="incubator-label-value">-</span>
                )
              ) : (
                ""
              )}

              <div className="row">
                <div className="col-md-12 centerText">
                  <div className="btn-group mt-1-5 ">
                    <button
                      className="tertiary  btn-yellow-primary"
                      data-dismiss="modal"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default OtherDetailsComponent;
