/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useState,useEffect } from 'react'
import { StartupTrancheDetail } from '../../../models/funddetails/StartupTrancheDetails';
import { VIEW_STARTUP_APPLICATION, BASE_URL } from "../../../constants/api";
import { Modal } from 'react-bootstrap';
import InstrumentComponent from './InstrumentComponent';
import { get, post } from '../../../effects/api/api.common';
import moment from "moment";

export var passId: number;
interface ITrancheProps {
  trancheDetailsModel: Array<StartupTrancheDetail>;
  applicationDetails:any;
  statusId1:any;
    evDate1:any;
    isPrefer1:any;
    sel:any;
    applicationStatusId:any;
    userRoles:any
}

function TrancheInfoComponent(props: ITrancheProps) {
  const { trancheDetailsModel,applicationDetails,statusId1,evDate1,isPrefer1,sel,applicationStatusId,userRoles } = props;
  const [startUp, setStartUp] = useState<any>({});
  const[instrumentApproved,setInstrumentApproved]=useState<any>(applicationDetails?.data?.data?.Application_Details?.FundingDetails?.instrument_approved)
  const[comment,setComment]=useState<any>(applicationDetails?.data?.data?.Application_Details?.FundingDetails?.instrument_comment)
  //  const [isstatus,setIsStatus]=useState("")
  //  const [date,setIsDate]=useState("")
  //  const [pref,setPref]=useState("")
  const [loggedRole,setLoggedRole] = useState<any>('')
  var currentdatenew = moment().format("YYYY-MM-DD")
  // console.log(trancheDetailsModel);

  // if (trancheDetailsModel !== undefined) {
  //   passId = trancheDetailsModel[0].startup_application_id;
  // }
  
  passId = applicationDetails?.data?.data?.Application_Details?.application_id

 const handelChange=(e: string)=>{
    setInstrumentApproved(e)
    //  console.log(e)
  }
  const handelComment=(e:string)=>{
    setComment(e)
  }
  const handelShowReport=()=>{
    setShowReport(false)
  }

  useEffect(() => {
      // console.log(applicationStatusId,"46")
    setInstrumentApproved(applicationDetails?.data?.data?.Application_Details?.FundingDetails?.instrument_approved)
    setComment(applicationDetails?.data?.data?.Application_Details?.FundingDetails?.instrument_comment)
    // console.log(props,"latha")
    setLoggedRole(userRoles)
  }, [applicationDetails]);

  const [showReport, setShowReport] = useState(false);


  return (
    <div>
      <div>
      <div className='d-flex align-items-center justify-content-around mb-3 mt-4'>
        <div>
          <label htmlFor="browser" className="form-label">Select instrument type to be approved:</label>
          <div style={{width:"300px"}} className='mt-3 mb-2'>
            {/*  */}
              <div>
                {/* {console.log("instrumentApproved",instrumentApproved)} */}
              <div className={instrumentApproved==0 || instrumentApproved==null ?"select-custom":"select-custom dropdown-disabled"}>
                  <select className="form-control" disabled  name="entity_type" value={instrumentApproved} aria-label="Select Entity Type">
                      <option value="">Select</option>
                      {/* selected */}
                      <option value="funding_grant">Grant</option>
                      <option value="funding_debt">Debt</option>
                      <option value="funding_debenture">Debenture</option>
                      <option value="funding_debt_linked">Other Debt-Linked Instruments</option>
                  </select>
              </div>    
          </div>
          </div>
          {instrumentApproved==0||instrumentApproved===null ?<span>Please select the instrument approved, else system won't allow you to move further</span>:null}
        </div>
        {/* instrument_comment */}
        <div className="vl vl-mr"></div>
        <div>
          <h6>Comment :-</h6>
          {startUp?.Application_Details?.FundingDetails.instrument_comment}
          {/* <textarea ></textarea> */}
          <textarea 
          disabled={true}
          style={{height:"100px",width:'200px'}}
          value={startUp?.Application_Details?.FundingDetails.instrument_comment ? startUp?.Application_Details?.FundingDetails.instrument_comment:comment}
          className="form-control" 
          />
        </div>
        {
        (
          (evDate1 < currentdatenew)&&((isPrefer1 == '7'&& statusId1 == '7' && sel == '1')||(statusId1 == '2' ||statusId1 == '3' ||statusId1 == '4'||statusId1 == '5'))?

        (    
          (applicationStatusId == "Cancelled" || loggedRole == "secretariat") ? <> </> :
        <a className="incubator-details-link" style={{marginLeft:'5px'}}>Edit</a>):((applicationStatusId == "Cancelled"|| loggedRole == "secretariat") ? <> </> : <a className="incubator-details-link" style={{marginLeft:'5px'}} onClick={() => setShowReport(true)}>Edit</a>))}

        {/* {instrumentApproved==0||instrumentApproved===null ?<a className="incubator-details-link" style={{marginLeft:'5px'}} onClick={() => setShowReport(true)}>Edit</a>:null} */}
        {/* <a className="incubator-details-link" style={{marginLeft:'5px'}} onClick={() => setShowReport(true)}>Edit</a> */}
      </div>
      </div>
      
      <Modal
        show={showReport}
        onHide={() => setShowReport(false)}
        dialogClassName="modal-90w modal-xl modal-dialog-scrollable table-bordered table-hover"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Instrument
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InstrumentComponent 
             id={passId} 
             setInstrumentApproved={setInstrumentApproved} 
             instrumentApproved={instrumentApproved}
             comment={comment}
             setComment={setComment}
             handelComment={handelComment}
             handelChange={handelChange}
             handelShowReport={handelShowReport}
             
             />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default TrancheInfoComponent;
