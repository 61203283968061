import { useContext, useReducer, createContext } from "react";
import { IErrorInitialState } from "../reducers/error.reducer";

// Prepares the dataLayer
export const StateContext = createContext(null as unknown);

export interface IErrorProvider {
  reducer: any;
  initialState: IErrorInitialState;
  children: any;
}

// Wrap our app and provide the Data layer
export const StateErrorProvider = (props: IErrorProvider) => {
  const { reducer, initialState, children } = props;

  return (
    <StateContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </StateContext.Provider>
  );
};

// Pull information from the data layer
export const useErrorStateValue = () => useContext(StateContext);
