import moment from "moment";

/** convert src to blob */
export const createFile = (src: string) =>
  new Promise((resolve, reject) => {
    fetch(src).then((e) => {
      resolve(e.blob());
      // return e.blob();
    });
  });


// A promise object which reads the file and converts it to the byte[]
export const convertToByteArray = (file: File) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);

    // make sure the file is in READY state, so that we can process the image file
    fileReader.onload = function (e) {
      const buffer = new Uint8Array(fileReader.result as ArrayBuffer);
      let byteArray: Array<any> = [];
      for (var i = 0; i < buffer.length; i++) {
        byteArray.push(buffer[i]);
      }
      resolve(byteArray);
    };
  });


export const downloadDocument = (file: any, file_name: any) => {
  fetch(file).then((response) => {
    response.blob().then((blob) => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = file_name;
      a.click();
    });
  });
};

export const filterByValue = (array: any, value: any) => {
  return array.filter((data: any) => JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1);
}
export const filterByValue1 = (array: any, value: any) => {
  
  return array.filter((data: any) => JSON.stringify(data,["city","state","incubator_name","sectors"]).toLowerCase().indexOf(value.toLowerCase()) !== -1);
  
}

export const changeNumberFormat = (number: any, decimals: any, recursiveCall: any) => {
  var val = Math.abs(number)
  if (val >= 10000000) {
    var value = (val / 10000000).toString().split(".").map((el,i)=>i?el.split("").slice(0,2).join(""):el).join(".") + ' Cr';
    return value;
  } else if (val >= 100000) {
    var value = (val / 100000).toString().split(".").map((el,i)=>i?el.split("").slice(0,2).join(""):el).join(".") + ' L';
    return value;
  }else if (val >= 100000) {
    var value = (val / 100000).toString().split(".").map((el,i)=>i?el.split("").slice(0,2).join(""):el).join(".") + ' K';
    return value;
  }else if (val >= 1000) {
    var value = (val / 1000).toString().split(".").map((el,i)=>i?el.split("").slice(0,2).join(""):el).join(".") + ' K';
    return value;
  }else{
    return val;
  }
  // const decimalPoints = decimals || 2;
  // const noOfLakhs = number / 100000;
  // let displayStr;
  // let isPlural;

  // // Rounds off digits to decimalPoints decimal places
  // function roundOf(integer: any) {
  //   return +integer.toLocaleString(undefined, {
  //     minimumFractionDigits: decimalPoints,
  //     maximumFractionDigits: decimalPoints,
  //   });
  // }

  // if (noOfLakhs >= 0.01 && noOfLakhs <= 0.99) {
  //   const lakhs = roundOf(noOfLakhs*100);
  //   displayStr = `${lakhs} K`;
  // }
  // else if (noOfLakhs >= 1 && noOfLakhs <= 99) {
  //   const lakhs = roundOf(noOfLakhs);
  //   isPlural = lakhs > 1 && !recursiveCall;
  //   displayStr = `${lakhs} L${isPlural ? 's' : ''}`;
  // } else if (noOfLakhs >= 100) {
  //   const crores = roundOf(noOfLakhs / 100);
  //   const crorePrefix: any = crores >= 100000 ? changeNumberFormat(crores, decimals, true) : crores;
  //   isPlural = crores > 1 && !recursiveCall;
  //   displayStr = `${crorePrefix} Cr${isPlural ? 's' : ''}`;
  // } else {
  //   displayStr = roundOf(number.toFixed(1));
  // }

  // return displayStr;
}

export const changeNumberFormat1 = (number: any, decimals: any, recursiveCall: any, name: string) => {
  var val = Math.abs(number)
  if ((name == 'grantAmount' && val == 20000000) || (name == 'availAmount' && val == 100000000)) {
    var value = (val / 10000000).toString().split(".").map((el,i)=>i?el.split("").slice(0,1).join(""):el).join(".") + ' Crore';
    return value;
  } 
  else if (val >= 10000000) {
    var value = (val / 10000000).toString().split(".").map((el,i)=>i?el.split("").slice(0,1).join(""):el).join(".") + ' Crore or more';
    return value;
  } else if (val >= 100000) {
    var value = (val / 100000).toString().split(".").map((el,i)=>i?el.split("").slice(0,1).join(""):el).join(".") + ' Lakhs or more';
    return value;
  }else if (val >= 100000) {
    var value = (val / 100000).toString().split(".").map((el,i)=>i?el.split("").slice(0,1).join(""):el).join(".") + ' K or more';
    return value;
  }else if (val >= 1000) {
    var value = (val / 1000).toString().split(".").map((el,i)=>i?el.split("").slice(0,1).join(""):el).join(".") + ' K or more';
    return value;
  }else{
    return val;
  }
}

export const passwordValidate = (val: string) => {
  //get posted resource name value
  //should be in the word\word\word format
  var pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,25}$/;

  //If the inputString is NOT a match
  if (!pattern.test(val)) {
    return false
  }
  else {
    return true
  }
}

export const showEllipsisText = (input: string , characterlength:number) => {
  if (input.length > characterlength) {
    return input.substring(0, characterlength) + '...';
  }
  return input;
};

export const validateEmail=(emailField: string)=> {
  var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

  if (reg.test(emailField) == false) {
      return false;
  }

  return true;

}

export const timeSince=(date:any)=> {
var curDate:any=new Date()
  var seconds = Math.floor((curDate - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return "Today";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return "Today";
  }
  return "Today";
}

export const compareDate=(dateToCheck:Date)=>{
  let year  = new Date().getFullYear();
  let month = new Date().getMonth();
  let day   = new Date().getDate();
  let twoYearPrevDate  = new Date(year-2, month, day);
    
  var d1 = new Date(moment(twoYearPrevDate).format(
    "YYYY-MM-DD"
));
  var d2 = new Date(moment(new Date()).format(
    "YYYY-MM-DD"
));
    
  if (dateToCheck >= d1 && dateToCheck <= d2) {
      return true
  } else {
     return false
  }
}