import React, { Component, createRef, useEffect, useState } from "react";
import DashboardHeaderComponent from "../common/DashboardHeaderComponent";
import DashboardFooterComponent from "../../common/footer/DashboardFooterComponent";
import EACReview from "../incubator/EACReviewComponent";

import filter from "../../../images/filter.svg";
import edit from "../../../images/edit.svg";
import search from "../../../images/search.svg";
import error from "../../../images/error.svg";
import ReApplied from "./ReAppliedComponent"
import {
  GET_ALL_LEGAL_ENTITY,
  GET_ALL_SECTOR,
  GET_ALL_STATES,
  GET_INCUBATOR_LISTING_COUNT,
  SCHEDULE_MEETING,
  GET_APPLICATION_STATUS,
  GET_QUICK_EDIT,
  QUICK_EDIT,
  DOWNLOAD_EXCEL,
} from "../../../constants/api";
import { get, post } from "../../../effects/api/api.common";
import "../../../styles/dashboard.css";
import { Modal, Button } from "react-bootstrap";
import MultiSelectDropdown from "../../common/controls/MultiSelectDropdown";
import { DropdownModel } from "../../../models/DropdownModel";
import Calendar from "../../common/controls/Calendar";
//import { strict } from 'node:assert';
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { compareTime, FormatDate } from "../../../utils/DateFormat";
import { formatTime } from "../../../utils/DateFormat";
import MultiSelect from "../../common/controls/MultiSelect";
import { QuickEditPopupData } from "../incubator/ApplicationGeneralDetails";
import ConfirmationDialog from "../../common/controls/ConfirmationDialog";
import IncubatorReportComponent from "../../incubator/dashboard/IncubatorReportComponent";
import {
  changeNumberFormat,
  downloadDocument,
  filterByValue,
} from "../../../utils/CommonMethods";
import moment from "moment";
import { getSessionStorageValue } from "../../../effects/utils/session-storage";

function IncubatorListingContainer() {
  const [showIncubatorFailureModal, setShowIncubatorFailureModal] =
    useState(false);
  const [timeValidation, setTimeValidation] = useState(false);
  const [timeValidationCurrentTime, setTimeValidationCurrentTime] =
    useState(false);
  const [linkValidation, setLinkValidation] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [checkBoxHide, setCheckBoxHide] = useState(true);
  const [showEditFailureModal, setShowEditFailureModal] = useState(false);
  const [showEditSuccessModal, setShowEditSuccessModal] = useState(false);
  const [applicationStatus, setApplicationStatus] = useState([]);
  const [selectFlag, setSelectFlag] = useState(true);
  const [noDataFilter, setNoDataFilter] = useState("");
  const [incubatorListingCounts, setIncubatorListingCounts] = useState({
    incomplete: "0",
    onHold: "0",
    pendingReview: "0",
    rejected: "0",
    selected: "0",
    submitted: "0",
    reviewCount: "0",
    trancheRequest: "0",
    cancel: "0",
    closed: "0",
    cancelled:"0"
  });
  const [incubatorList, setincubatorList] = useState([
    {
      application_id: "",
      //  incubator_application_id:"",
      application_date: "",
      government_assisted: "",
      state_name: "",
      incubator_name: "",
      total_approved_amt: "",
      total_disbursed_amt: "",
      meeting_date: "",
      meeting_time: "",
      cancellation_status: "",
      meeting_id: "",
      meeting_status: "",
      status_id: "",
      count_startups_supported: "",
      amount_disbursed: "",
      incubator_application_no: "",
    },
  ]);
  const [incompleteApplications, setIncompleteApplications] = useState([]);
  const [pendingDataAll, setpendingDataAll] = useState([]);
  const [filteredPendingDataAll, setFilteredPendingDataAll] = useState([]);
  const [pendingDataReview, setpendingDataReview] = useState([]);
  const [filteredPendingDataReview, setFilteredPendingDataReview] = useState(
    []
  );
  const [pendingData, setpendingData] = useState([]);
  const [filteredPendingData, setFilteredPendingData] = useState([]);
  const [filteredIncubatorList, setFilteredIncubatorList] = useState([
    {
      application_id: "",
      application_date: "",
      government_assisted: "",
      state_name: "",
      incubator_name: "",
      total_approved_amt: "",
      total_disbursed_amt: "",
      meeting_date: "",
      meeting_time: "",
      cancellation_status: "",
      meeting_id: "",
      meeting_status: "",
      status_id: "",
      count_startups_supported: "",
      amount_disbursed: "",
      incubator_application_no: "",
    },
  ]);
  const [incubatorListCountHead, setincubatorListCountHead] = useState("");
  const [incubatorListId, setIncubatorListId] = useState({
    incomplete_id: "",
    onHold_id: "",
    pendingReview_id: "",
    rejected_id: "",
    selected_id: "",
    submitted_id: "",
    trancheRequest_id: "",
    closed_id: "",
  });
  const history = useHistory();
  const [showIncubatorSuccessModal, setShowIncubatorSuccessModal] =
    useState(false);
  const [countryStates, setCountryStates] = useState([]);
  const [sectorDetails, setSectorDetails] = useState([]);
  const [entityDetails, setEntityDetails] = useState([]);
  const [isDisabled, setDisabled] = useState(false);
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [oneStartupCheckbox, setOneStartup] = useState(false);
  const [columnHideFlag, setColumnHideFlag] = useState(true);
  const [tableHeading, setTableHeading] = useState("All Applications");
  const [noStartupCheckbox, setNoStartup] = useState(false);
  const [selectedStateValue, setStateValue] = useState<Array<string>>([]);
  const [selectedSectorValue, setSectorValue] = useState<Array<string>>([]);
  const [selectedEntityValue, setEntityValue] = useState<Array<string>>([]);
  const [checkedArray, setCheckedArray] = useState<Array<string>>([]);
  const [fromTime, setFromTime] = useState(String);
  const [toTime, setToTime] = useState(String);
  const [incubatorMeetingType, setincubatorMeetingType] = useState("1");
  const [scheduleDate, setScheduleDate] = useState(String);
  const [selectedDateControl, setSelectedDateControl] = useState(new Date());
  const [activeIndex, setActiveIndex] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [showFilterDot, setShowFilterDot] = useState(false);
  const [showEditTranche, setShowEditTranche] = useState(false);
  const [mouseOverFlag, setMouseOverFlag] = useState("");
  const [meetingLink, setMeetingLink] = useState("");
  const [meetingComment, setMeetingComment] = useState("");
  const [showReport, setShowReport] = useState(false);
  const [quickEditPopupData, setquickEditPopupData] = useState({
    application_comments: "",
    application_id: "",
    application_status: "",
    corporate_id_no: "",
    incubator_name: "",
    no_of_tranches: "",
    date_of_evaluation: "",
    total_approved_amt: "",
    incubator_application_no: "",
  });
  const [dialogData, setDialogData] = useState({
    header: "",
    text: "",
  });
  const [failDialogData, setFailDialogData] = useState({
    header: "",
    text: "",
  });
  const [confirmationDialogData, setConfirmationDialogData] = useState({
    header: "",
    text: "",
  });
  const [loggedInRole, setLoggedInRole] = useState("");
  useEffect(() => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    setLoggedInRole(userInfo.roles);
    window.scrollTo(0, 0);
    // getAllStates();
    getIncubatorCount();
    // getAllSectors();
    // getAllLegalEntity();
  }, []);
  // get initial count of incubators
  const getIncubatorCount = () => {
    setCheckBoxHide(true);
    setButtonDisable(true);
    setColumnHideFlag(true);
    post(GET_INCUBATOR_LISTING_COUNT, "").then((response: any) => {
      if (!response || !response.data) return;
      const counts = {
        incomplete: "0",
        onHold: "0",
        pendingReview: "0",
        rejected: "0",
        selected: "0",
        submitted: "0",
        reviewCount: response.data.reviewCount
          ? response.data.reviewCount
          : "0",
        trancheRequest: response.data.tranceRequestedCount,
        cancel: response.data.cancellationRequestedCount,
        closed: "0",
        cancelled: "0",

      };
      const status_id = {
        incomplete_id: "",
        onHold_id: "",
        pendingReview_id: "",
        rejected_id: "",
        selected_id: "",
        submitted_id: "",
        trancheRequest_id: "",
        closed_id: "",
      };
      for (var i = 0; i < response.data.mainCount.length; i++) {
        if (response.data.mainCount[i].name == "incomplete") {
          counts.incomplete = response.data.mainCount[i].appln_count;
          status_id.incomplete_id = response.data.mainCount[i].id;
        } else if (response.data.mainCount[i].name == "pending_review") {
          counts.pendingReview = response.data.mainCount[i].appln_count;
          status_id.pendingReview_id = response.data.mainCount[i].id;
        } else if (response.data.mainCount[i].name == "submitted") {
          counts.submitted = response.data.mainCount[i].appln_count;
          status_id.submitted_id = response.data.mainCount[i].id;
        } else if (response.data.mainCount[i].name == "on_hold") {
          counts.onHold = response.data.mainCount[i].appln_count;
          status_id.onHold_id = response.data.mainCount[i].id;
        } else if (response.data.mainCount[i].name == "rejected") {
          counts.rejected = response.data.mainCount[i].appln_count;
          status_id.rejected_id = response.data.mainCount[i].id;
        } else if (response.data.mainCount[i].name == "selected") {
          counts.selected = response.data.mainCount[i].appln_count;
          status_id.selected_id = response.data.mainCount[i].id;
        } else if (response.data.mainCount[i].name == "closed") {
          counts.closed = response.data.mainCount[i].appln_count;
          status_id.closed_id = response.data.mainCount[i].id;
        }else if (response.data.mainCount[i].name == "cancelled") {
          counts.cancelled = response.data.mainCount[i].appln_count + response.data.cancellationApprovedCount;
          
        }
      }

      setIncubatorListingCounts(counts);
      setIncubatorListId(status_id);
      setincubatorListCountHead(response.data.applications.length);
      if (response.data.applications.length != 0) {
        const incubatorListData = response.data.applications.map(
          (item: any) => ({
            application_id: item.application_id,
            incubator_application_no: item.incubator_application_no,
            application_date:
              item.first_submitted_date != null
                ? FormatDate(item.first_submitted_date)
                : FormatDate(item.application_date),
            government_assisted: item.government_assisted,
            state_name: item.state_name,
            incubator_name: item.registered_name,
            total_approved_amt: item.total_approved_amt,
            total_disbursed_amt: item.total_disbursed_amt,
            meeting_date:
              item.meetingData.meeting_date != ""
                ? item.meetingData.meeting_date
                : "",
            meeting_time:
              item.meetingData.meeting_from_time != ""
                ? item.meetingData.meeting_from_time
                : "",
            meeting_id:
              item.meetingData.meeting_id != ""
                ? item.meetingData.meeting_id
                : "",
            meeting_status: item.meetingData.status,
            cancellation_status: item.cancellation_status
              ? item.cancellation_status
              : "",
            status_id: item.status_id,
            count_startups_supported:
              item.count_startups_supported != null
                ? item.count_startups_supported
                : "0",
            amount_disbursed:
              item.amount_disbursed != null ? item.amount_disbursed : "0",
          })
        );
        setFilteredIncubatorList([
          {
            application_id: "",
            application_date: "",
            government_assisted: "",
            state_name: "",
            incubator_name: "",
            total_approved_amt: "",
            total_disbursed_amt: "",
            meeting_date: "",
            meeting_time: "",
            cancellation_status: "",
            meeting_id: "",
            meeting_status: "",
            status_id: "",
            count_startups_supported: "",
            amount_disbursed: "",
            incubator_application_no: "",
          },
        ]);
        setincubatorList(incubatorListData);
      } else {
        setincubatorList([]);
      }
      getAllStates();
    });
  };

  // get states in dropdown
  const getAllStates = () => {
    get(GET_ALL_STATES).then((response: any) => {
      if (!response || !response.data) return;
      const states = response.data.data.map((item: any): DropdownModel => {
        return {
          text: item.name,
          id: item.id.toString(),
          translation_text: "",
        };
      });
      setCountryStates(states);
      getAllSectors();
    });
  };
  //get application status
  const getApplicationStatus = () => {
    get(GET_APPLICATION_STATUS).then((response: any) => {
      if (!response || !response.data) return;
      // console.log(response.data);
      const status = response.data.data.map((item: any) => ({
        label: item.label,
        value: item.id,
      }));
      setApplicationStatus(status);
    });
  };

  // get all sectors in dropdown
  const getAllSectors = () => {
    // const param = sectorDetails.length == 0 ? '0' : sectorDetails
    get(`${GET_ALL_SECTOR}/0`).then((response: any) => {
      if (!response || !response.data) return;
      const sectors = response.data.data.map((item: any): DropdownModel => {
        return {
          text: item.attribute_value,
          id: item.id.toString(),
          translation_text: "",
        };
      });
      setSectorDetails(sectors);
      getAllLegalEntity();
    });
  };

  // get entity values in dropdown
  const getAllLegalEntity = () => {
    get(GET_ALL_LEGAL_ENTITY).then((response: any) => {
      if (!response || !response.data) return;
      const entity = response.data.data.map((item: any): DropdownModel => {
        return {
          text: item.attribute_value,
          id: item.id.toString(),
          translation_text: "",
        };
      });
      setEntityDetails(entity);
    });
  };

  // apply filter button click function
  const applyFilter = () => {
    if (
      selectedEntityValue.length != 0 ||
      selectedStateValue.length != 0 ||
      selectedSectorValue.length != 0 ||
      oneStartupCheckbox == true ||
      noStartupCheckbox == true
    ) {
      setShowFilterDot(true);
    } else {
      setShowFilterDot(false);
    }

    setTableHeading("All Applications");
    setActiveIndex("");
    var payload = {};
    payload = {
      leagel_entity_id:
        selectedEntityValue.length != 0 ? selectedEntityValue : null,
      state_id: selectedStateValue.length != 0 ? selectedStateValue : null,
      sector_id: selectedSectorValue.length != 0 ? selectedSectorValue : null,
      startup_funded_incubators: oneStartupCheckbox ? "yes" : "",
      no_startup_funded_incubators: noStartupCheckbox ? "yes" : "",
    };
    post(GET_INCUBATOR_LISTING_COUNT, payload).then((response: any) => {
      if (!response || !response.data) return;
      const counts = {
        incomplete: "0",
        onHold: "0",
        pendingReview: "0",
        rejected: "0",
        selected: "0",
        submitted: "0",
        reviewCount: response.data.reviewCount
          ? response.data.reviewCount
          : "0",
        trancheRequest: response.data.tranceRequestedCount,
        cancel: response.data.cancellationRequestedCount,
        closed: "0",
        cancelled: '0',
        
      };
      const status_id = {
        incomplete_id: "",
        onHold_id: "",
        pendingReview_id: "",
        rejected_id: "",
        selected_id: "",
        submitted_id: "",
        trancheRequest_id: "",
        closed_id: "",
      };
      for (var i = 0; i < response.data.mainCount.length; i++) {
        if (response.data.mainCount[i].name == "incomplete") {
          counts.incomplete = response.data.mainCount[i].appln_count;
          status_id.incomplete_id = response.data.mainCount[i].id;
        } else if (response.data.mainCount[i].name == "pending_review") {
          counts.pendingReview = response.data.mainCount[i].appln_count;
          status_id.pendingReview_id = response.data.mainCount[i].id;
        } else if (response.data.mainCount[i].name == "submitted") {
          counts.submitted = response.data.mainCount[i].appln_count;
          status_id.submitted_id = response.data.mainCount[i].id;
        } else if (response.data.mainCount[i].name == "on_hold") {
          counts.onHold = response.data.mainCount[i].appln_count;
          status_id.onHold_id = response.data.mainCount[i].id;
        } else if (response.data.mainCount[i].name == "rejected") {
          counts.rejected = response.data.mainCount[i].appln_count;
          status_id.rejected_id = response.data.mainCount[i].id;
        } else if (response.data.mainCount[i].name == "selected") {
          counts.selected = response.data.mainCount[i].appln_count;
          status_id.selected_id = response.data.mainCount[i].id;
        } else if (response.data.mainCount[i].name == "closed") {
          counts.closed = response.data.mainCount[i].appln_count;
          status_id.closed_id = response.data.mainCount[i].id;
        }else if (response.data.mainCount[i].name == "cancelled") {
          counts.cancelled = response.data.mainCount[i].appln_count + response.data.cancellationApprovedCount;
          // status_id.closed_id = response.data.mainCount[i].id;
        }
      }
      setIncubatorListingCounts(counts);
      setIncubatorListId(status_id);
      setincubatorListCountHead(response.data.applications.length);
      if (response.data.applications.length != 0) {
        const incubatorListData = response.data.applications.map(
          (item: any) => ({
            application_id: item.application_id,
            incubator_application_no: item.incubator_application_no,
            application_date:
              item.first_submitted_date != null
                ? FormatDate(item.first_submitted_date)
                : FormatDate(item.application_date),
            government_assisted: item.government_assisted,
            state_name: item.state_name,
            incubator_name: item.registered_name,
            total_approved_amt: item.total_approved_amt,
            total_disbursed_amt: item.total_disbursed_amt,
            meeting_date:
              item.meetingData.meeting_date != ""
                ? item.meetingData.meeting_date
                : "",
            meeting_time:
              item.meetingData.meeting_from_time != ""
                ? item.meetingData.meeting_from_time
                : "",
            meeting_id:
              item.meetingData.meeting_id != ""
                ? item.meetingData.meeting_id
                : "",
            cancellation_status: item.cancellation_status
              ? item.cancellation_status
              : "",
            meeting_status: item.meetingData.status,
            status_id: item.status_id,
            count_startups_supported:
              item.count_startups_supported != null
                ? item.count_startups_supported
                : "0",
            amount_disbursed:
              item.amount_disbursed != null ? item.amount_disbursed : "0",
          })
        );
        setFilteredIncubatorList([
          {
            application_id: "",
            application_date: "",
            government_assisted: "",
            state_name: "",
            incubator_name: "",
            total_approved_amt: "",
            total_disbursed_amt: "",
            meeting_date: "",
            cancellation_status: "",
            meeting_time: "",
            meeting_id: "",
            meeting_status: "",
            status_id: "",
            count_startups_supported: "",
            amount_disbursed: "",
            incubator_application_no: "",
          },
        ]);
        setincubatorList(incubatorListData);
      } else {
        setincubatorList([]);
      }
    });
  };

  const dismissModal = () => {
    setShowIncubatorSuccessModal(false);
    setShowIncubatorFailureModal(false);
    // setShowEditFailureModal(false);
    // setShowEditSuccessModal(false);
    window.location.reload();
  };

  // handeling multiselect checkbox in dropdown value
  const onMultiSelectChange = (selectedIds: Array<string>, name: string) => {
    if (name == "state") {
      setStateValue(selectedIds);
    } else if (name == "sector") {
      setSectorValue(selectedIds);
    } else if (name == "entity") {
      setEntityValue(selectedIds);
    }
  };
  
  // reset button function
  const resetFilter = () => {
    setTableHeading("All Applications");
    setActiveIndex("");
    setStateValue([]);
    setSectorValue([]);
    setEntityValue([]);
    setOneStartup(false);
    setShowFilterDot(false);
    setNoStartup(false);
    getIncubatorCount();
  };

  // get data on click status card
  const getDataByStatus = (selected_id: any) => {
    if (selected_id == "2") {
      setIsChecked(false);
      setButtonDisable(true);
      setCheckBoxHide(true);
      setTableHeading("Applications Submitted");
      setincubatorListCountHead(incubatorListingCounts.submitted);
    } else if (selected_id == "3") {
      setincubatorMeetingType("1");
      setButtonDisable(false);
      setCheckBoxHide(false);
      setCheckedArray([]);
      setTableHeading("Applications to be Reviewed");
      setincubatorListCountHead(incubatorListingCounts.pendingReview);
    } else if (selected_id == "4") {
      setIsChecked(false);
      setButtonDisable(true);
      setCheckBoxHide(true);
      setTableHeading("Applications Incomplete");
      setincubatorListCountHead(incubatorListingCounts.incomplete);
    } else if (selected_id == "5") {
      setIsChecked(false);
      setButtonDisable(true);
      setCheckBoxHide(true);
      setTableHeading("Applications On Hold");
      setincubatorListCountHead(incubatorListingCounts.onHold);
    } else if (selected_id == "6") {
      setIsChecked(false);
      setButtonDisable(true);
      setCheckBoxHide(true);
      setTableHeading("Applications Rejected");
      setincubatorListCountHead(incubatorListingCounts.rejected);
    } else if (selected_id == "7") {
      setincubatorMeetingType("2");
      setButtonDisable(false);
      setCheckBoxHide(false);
      setCheckedArray([]);
      setTableHeading("Applications Selected");
      setincubatorListCountHead(incubatorListingCounts.selected);
    } else if (selected_id == "8") {
      setIsChecked(false);
      setButtonDisable(true);
      setCheckBoxHide(true);
      setTableHeading("Applications Closed");
      setincubatorListCountHead(incubatorListingCounts.closed);
    } else if (selected_id == "tranche") {
      setIsChecked(false);
      setButtonDisable(true);
      setCheckBoxHide(true);
      setTableHeading("Tranche Requested");
      setincubatorListCountHead(incubatorListingCounts.trancheRequest);
    } else if (selected_id == "cancel") {
      setIsChecked(false);
      setButtonDisable(true);
      setCheckBoxHide(true);
      setTableHeading("Cancellation Requested");
      setincubatorListCountHead(incubatorListingCounts.cancel);
    } else if (selected_id == "cancelled") {
      setIsChecked(false);
      setButtonDisable(true);
      setCheckBoxHide(true);
      setTableHeading("Cancelled Requested");
      setincubatorListCountHead(incubatorListingCounts.cancelled);
    }
    setActiveIndex(selected_id);
    setincubatorList([]);
    var payload = {};
    if (selected_id == "tranche") {
      payload = {
        trance_requested: "Yes",
        leagel_entity_id:
          selectedEntityValue.length != 0 ? selectedEntityValue : null,
        state_id: selectedStateValue.length != 0 ? selectedStateValue : null,
        sector_id: selectedSectorValue.length != 0 ? selectedSectorValue : null,
        startup_funded_incubators: oneStartupCheckbox ? "yes" : "",
        no_startup_funded_incubators: noStartupCheckbox ? "yes" : "",
      };
    } else if (selected_id == "cancel") {
      payload = {
        cancellation_requested: "Yes",
        leagel_entity_id:
          selectedEntityValue.length != 0 ? selectedEntityValue : null,
        state_id: selectedStateValue.length != 0 ? selectedStateValue : null,
        sector_id: selectedSectorValue.length != 0 ? selectedSectorValue : null,
        startup_funded_incubators: oneStartupCheckbox ? "yes" : "",
        no_startup_funded_incubators: noStartupCheckbox ? "yes" : "",
      }}else if (selected_id == "cancelled") {
        payload = {
          cancellation_approved: "Yes",
          leagel_entity_id:
            selectedEntityValue.length != 0 ? selectedEntityValue : null,
          state_id: selectedStateValue.length != 0 ? selectedStateValue : null,
          sector_id: selectedSectorValue.length != 0 ? selectedSectorValue : null,
          startup_funded_incubators: oneStartupCheckbox ? "yes" : "",
          no_startup_funded_incubators: noStartupCheckbox ? "yes" : "",
        }
    } else {
      payload = {
        status_id: selected_id,
        leagel_entity_id:
          selectedEntityValue.length != 0 ? selectedEntityValue : null,
        state_id: selectedStateValue.length != 0 ? selectedStateValue : null,
        sector_id: selectedSectorValue.length != 0 ? selectedSectorValue : null,
        startup_funded_incubators: oneStartupCheckbox ? "yes" : "",
        no_startup_funded_incubators: noStartupCheckbox ? "yes" : "",
      };
    }
    post(GET_INCUBATOR_LISTING_COUNT, payload).then((response: any) => {
      if (!response || !response.data) return;

      if (response.data.applications.length != 0) {
        if (selected_id == "4") {
          setIncompleteApplications(response.data.applications_incomplete);
          setpendingDataAll(response.data.applications_incomplete.all);
          setpendingDataReview(
            response.data.applications_incomplete.need_clarification
          );
          setpendingData(response.data.applications_incomplete.incomplete);
          const incubatorListData = response.data.applications.map(
            (item: any) => ({
              application_id: item.application_id,
              incubator_application_no: item.incubator_application_no,
              application_date:
                item.first_submitted_date != null
                  ? FormatDate(item.first_submitted_date)
                  : FormatDate(item.application_date),
              government_assisted: item.government_assisted,
              state_name: item.state_name,
              incubator_name: item.registered_name,
              total_approved_amt: item.total_approved_amt,
              total_disbursed_amt: item.total_disbursed_amt,
              meeting_date:
                item.meetingData.meeting_date != ""
                  ? item.meetingData.meeting_date
                  : "",
              meeting_time:
                item.meetingData.meeting_from_time != ""
                  ? item.meetingData.meeting_from_time
                  : "",
              meeting_id:
                item.meetingData.meeting_id != ""
                  ? item.meetingData.meeting_id
                  : "",
              cancellation_status: item.cancellation_status
                ? item.cancellation_status
                : "",
              meeting_status: item.meetingData.status,
              status_id: item.status_id,
            })
          );
          setFilteredIncubatorList([
            {
              application_id: "",
              application_date: "",
              government_assisted: "",
              state_name: "",
              incubator_name: "",
              total_approved_amt: "",
              total_disbursed_amt: "",
              meeting_date: "",
              meeting_time: "",
              cancellation_status: "",
              meeting_id: "",
              meeting_status: "",
              status_id: "",
              count_startups_supported: "",
              amount_disbursed: "",
              incubator_application_no: "",
            },
          ]);
          setincubatorList(incubatorListData);
        } else {
          setIncompleteApplications([]);
          const incubatorListData = response.data.applications.map(
            (item: any) => ({
              application_id: item.application_id,
              incubator_application_no: item.incubator_application_no,
              application_date:
                item.first_submitted_date != null
                  ? FormatDate(item.first_submitted_date)
                  : FormatDate(item.application_date),
              government_assisted: item.government_assisted,
              state_name: item.state_name,
              incubator_name: item.registered_name,
              total_approved_amt: item.total_approved_amt,
              total_disbursed_amt: item.total_disbursed_amt,
              meeting_date:
                item.meetingData.meeting_date != ""
                  ? item.meetingData.meeting_date
                  : "",
              meeting_time:
                item.meetingData.meeting_from_time != ""
                  ? item.meetingData.meeting_from_time
                  : "",
              meeting_id:
                item.meetingData.meeting_id != ""
                  ? item.meetingData.meeting_id
                  : "",
              cancellation_status: item.cancellation_status
                ? item.cancellation_status
                : "",
              meeting_status: item.meetingData.status,
              status_id: item.status_id,
              count_startups_supported:
                item.count_startups_supported != null
                  ? item.count_startups_supported
                  : "0",
              amount_disbursed:
                item.amount_disbursed != null ? item.amount_disbursed : "0",
            })
          );
          setFilteredIncubatorList([
            {
              application_id: "",
              application_date: "",
              government_assisted: "",
              state_name: "",
              incubator_name: "",
              total_approved_amt: "",
              total_disbursed_amt: "",
              meeting_date: "",
              meeting_time: "",
              cancellation_status: "",
              meeting_id: "",
              meeting_status: "",
              status_id: "",
              count_startups_supported: "",
              amount_disbursed: "",
              incubator_application_no: "",
            },
          ]);
          setincubatorList(incubatorListData);
        }
      } else {
        setincubatorList([]);
      }
    });
    if (selected_id == "7" || selected_id == "8" || selected_id == "tranche") {
      setColumnHideFlag(true);
    } else {
      setColumnHideFlag(false);
    }
  };

  // checkbox checked event
  const handleOnCheck = (e: any /*index:number*/) => {
    const item = e.target.name;
    const isChecked = e.target.checked;
    if (isChecked) {
      if (item == "one_startup") {
        setOneStartup(true);
      } else if (item == "any_startup") {
        setNoStartup(true);
      }
    } else {
      if (item == "one_startup") {
        setOneStartup(false);
      } else if (item == "any_startup") {
        setNoStartup(false);
      }
    }
  };

  // setCheckedArrayCount(checkedArray.length);
  const handleListChange = (e: any /*index:number*/) => {
    var value = e.target.value;
    var isChecked = e.target.checked;

    if (isChecked) {
      setButtonDisable(false);
      if (checkedArray.includes(value) == false) {
        setCheckedArray((oldArray) => [...oldArray, value]);
      }
    } else if (isChecked == false) {
      if (checkedArray.includes(value)) {
        setCheckedArray(checkedArray.filter((item) => item !== value));
      }
    }
  };
  // allcheckbox check
  const handleAllCheck = (e: any /*index:number*/) => {
    var name = e.target.name;
    var isChecked = e.target.checked;
    if (isChecked) {
      setCheckedArray([]);
      incubatorList.map(({ application_id }) =>
        setCheckedArray((oldArray) => [...oldArray, application_id])
      );
      setIsChecked(true);
    } else if (isChecked == false) {
      setIsChecked(false);
      setCheckedArray([]);
    }
  };

  const quickEditCall = () => {
    // console.log(quickEditPopupData);
    setSelectFlag(true);
    var payload = {};
    if (quickEditPopupData.application_status == "7") {
      payload = {
        application_comments: quickEditPopupData.application_comments,
        application_id: quickEditPopupData.application_id,
        incubator_application_no: quickEditPopupData.incubator_application_no,
        application_status: quickEditPopupData.application_status,
        no_of_tranches: quickEditPopupData.no_of_tranches,
        total_approved_amt: quickEditPopupData.total_approved_amt,
        date_of_evaluation: quickEditPopupData.date_of_evaluation,
      };
    } else if (quickEditPopupData.application_status != "7") {
      payload = {
        application_comments: quickEditPopupData.application_comments,
        application_id: quickEditPopupData.application_id,
        incubator_application_no: quickEditPopupData.incubator_application_no,
        application_status: quickEditPopupData.application_status,
        date_of_evaluation: quickEditPopupData.date_of_evaluation,
      };
    }
    setDisabled(true);
    post(QUICK_EDIT, payload).then((response: any) => {
      // console.log(response,"mohaannnnn")
      if (!response || !response.data) return;
      // console.log(response.data);

      getIncubatorCount();
      setTableHeading("All Applications");
      setActiveIndex("");
      if (response.data.result == "success") {
        setDialogData({
          header: "Thank You!",
          text: "Application has been Updated Successfully !!!",
        });
        setShowIncubatorSuccessModal(true);
        setDisabled(false);
      } else {
        setFailDialogData({
          header: "Oops,Something went wrong",
          text: "Please Try Again !!",
        });
        setShowIncubatorFailureModal(true);
        setDisabled(false);
      }
      // console.log(response.data);
    });
    setquickEditPopupData({
      application_comments: "",
      application_id: "",
      application_status: "",
      corporate_id_no: "",
      incubator_name: "",
      no_of_tranches: "",
      date_of_evaluation: "",
      total_approved_amt: "",
      incubator_application_no: "",
    });
  };
  // function to format date
  function formatDate(date: Date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  // trrigers on date change
  const handleDateChange = (date: Date, field: string) => {
    setScheduleDate(formatDate(date));
    setSelectedDateControl(date);
  };

  const handlePopupDateChange = (date: Date, field: string) => {
    setquickEditPopupData((prevState) => ({
      ...prevState,
      date_of_evaluation: formatDate(date),
    }));
  };

  // on change time;
  const onTimeChange = (e: any /*index:number*/) => {
    var value = e.target.value;
    var name = e.target.name;
    if (name == "fromTime") {
      setFromTime(value);
    } else if (name == "toTime") {
      setToTime(value);
    }
  };

  // popup change
  const handlePopupChange = (e: any /*index:number*/) => {
    var value = e.target.value;
    var name = e.target.name;
    if (name == "status_id") {
      setquickEditPopupData((prevState) => ({
        ...prevState,
        application_status: value,
      }));
      setquickEditPopupData((prevState) => ({
        ...prevState,
        application_comments: "",
      }));
      if (value == "7") {
        setSelectFlag(false);
      } else if (value != "7") {
        setSelectFlag(true);
      }
    } else if (name == "popup_comments") {
      setquickEditPopupData((prevState) => ({
        ...prevState,
        application_comments: value,
      }));
    } else if (name == "no_of_tranches") {
      setquickEditPopupData((prevState) => ({
        ...prevState,
        no_of_tranches: value,
      }));
    } else if (name == "total_approved_amt") {
      setquickEditPopupData((prevState) => ({
        ...prevState,
        total_approved_amt: value.toString().split(".").map((el:any,i:any)=>i?el.split("").slice(0,2).join(""):el).join("."),
      }));
    }
  };


  // on incubator dropdown change
  const handleOnChange = (e: any /*index:number*/) => {
    var value = e.target.value;
    setincubatorMeetingType(value);
  };

  const scheduleMeetingFunction = () => {
    var time = new Date();
    var curTime = moment(time, "HH:mm").format("HH:mm");
    if (!compareTime(fromTime, toTime)) {
      setTimeValidation(true);
      setTimeValidationCurrentTime(false);
      return false;
    } else if (compareTime(fromTime, toTime)) {
      setTimeValidation(false);
      setTimeValidationCurrentTime(false);
    }

    const data = {
      meeting_date: scheduleDate != "" ? scheduleDate : formatDate(new Date()),
      meeting_from_time: fromTime != "" ? fromTime : "",
      meeting_to_time: toTime != "" ? toTime : "",
      meeting_type: incubatorMeetingType != "" ? incubatorMeetingType : "",
      incubator_application_ids: checkedArray.length != 0 ? checkedArray : [],
      link: meetingLink,
      comment: meetingComment,
      warning_ignore: 0,
    };
    if (meetingLink == "") {
      setLinkValidation(true);
      return false;
    }
    if (
      formatDate(new Date()) == data.meeting_date &&
      compareTime(fromTime, curTime)
    ) {
      setTimeValidationCurrentTime(true);
      setTimeValidation(false);
      return false;
    }
    if (checkedArray.length == 0) {
      setFailDialogData({
        header: "",
        text: "Please select atleast one Incubator!!",
      });
      setShowIncubatorFailureModal(true);
      return;
    }
    setDisabled(true);
    setTimeValidation(false);
    setLinkValidation(false);
    setTimeValidationCurrentTime(false);
    post(SCHEDULE_MEETING, data).then((response: any) => {
      if (!response || !response.data) return;
      if (response.data.result == "success") {
        setDialogData({
          header: "Thank You!",
          text: "Your meeting has been scheduled !!!",
        });
        setShowIncubatorSuccessModal(true);

        setDisabled(false);
      } else if (response.data.result == "warning") {
        setConfirmationDialogData({
          header: "Meeting Already Exist",
          text: response.data.message,
        });
        setShowConfirmationBox(true);
        setDisabled(false);
      } else if (response.data.result == "error") {
        setFailDialogData({
          header: "Oops !!",
          text: response.data.message,
        });
        setShowIncubatorFailureModal(true);
        setDisabled(false);
      } else {
        setFailDialogData({
          header: "Oops !!",
          text: "Internal Server Error",
        });
        setShowIncubatorFailureModal(true);
        setDisabled(false);
      }
    });
  };
  const finalSchedule = () => {
    const data = {
      meeting_date: scheduleDate != "" ? scheduleDate : formatDate(new Date()),
      meeting_from_time: fromTime != "" ? fromTime : "",
      meeting_to_time: toTime != "" ? toTime : "",
      meeting_type: incubatorMeetingType != "" ? incubatorMeetingType : "",
      incubator_application_ids: checkedArray.length != 0 ? checkedArray : [],
      link: meetingLink,
      warning_ignore: 1,
    };
    setDisabled(true);
    post(SCHEDULE_MEETING, data).then((response: any) => {
      if (!response || !response.data) return;
      setShowConfirmationBox(false);
      if (response.data.result == "success") {
        setDialogData({
          header: "Thank You!",
          text: "Your meeting has been scheduled !!!",
        });

        setShowIncubatorSuccessModal(true);
        setDisabled(false);
      } else {
        setFailDialogData({
          header: "Oops !!",
          text: response.data.message,
        });
        setShowIncubatorFailureModal(true);
        setDisabled(false);
      }
    });
  };

  const mouseOverEvent = (id: any) => {
    const index = incubatorList.findIndex((x) => x.application_id == id);
    setMouseOverFlag(incubatorList[index].application_id);
  };
  const handleRowChange = (id: any) => {
    let flag = false;
    if (activeIndex == "tranche") {
      flag = true;
    } else {
      flag = false;
    }
    if (loggedInRole == "dpiit") {
      history.replace({
        pathname: "/dpiit_view_incubator",
        state: { application_id: id, isFundDetails: flag },
      });
    } else if (loggedInRole == "secretariat") {
      history.replace({
        pathname: "/secretariat_view_incubator",
        state: { application_id: id, isFundDetails: flag },
      });
    }
  };
  const quickEditData = (
    name: string,
    date: string,
    status_id: string,
    id: string
  ) => {
    setquickEditPopupData({
      application_comments: "",
      application_id: "",
      application_status: "",
      corporate_id_no: "",
      incubator_name: "",
      no_of_tranches: "",
      date_of_evaluation: "",
      total_approved_amt: "",
      incubator_application_no: "",
    });
    getApplicationStatus();
    post(GET_QUICK_EDIT, { application_id: id }).then((response: any) => {
      if (!response || !response.data) return;
      setquickEditPopupData({
        application_comments: response.data.data.application_comments,
        application_id: response.data.data.application_id,
        incubator_application_no: response.data.data.incubator_application_no,
        application_status: response.data.data.application_status,
        corporate_id_no: response.data.data.corporate_id_no,
        incubator_name: response.data.data.registered_name,
        no_of_tranches:
          response.data.data.no_of_tranches != null
            ? response.data.data.no_of_tranches
            : "0",
        date_of_evaluation:
          response.data.data.date_of_evaluation != null
            ? response.data.data.date_of_evaluation
            : "",
        total_approved_amt:
          response.data.data.total_approved_amt != null
            ? response.data.data.total_approved_amt
            : "0",
      });
      if (response.data.data.application_status == "7") {
        setSelectFlag(false);
      } else if (response.data.data.application_status != "7") {
        setSelectFlag(true);
      }
      if (response.data.data.requested_tranchData.length != 0) {
        setShowEditTranche(true);
      } else if (response.data.data.requested_tranchData.length == 0) {
        setShowEditTranche(false);
      }
    });
  };

  const searchList = (e: any) => {
    setNoDataFilter(e.target.value);
    const filteredData = filterByValue(incubatorList, e.target.value);
    const filteredPendingDatainfo = filterByValue(pendingData, e.target.value);
    const filteredPendingDataAllinfo = filterByValue(
      pendingDataAll,
      e.target.value
    );
    const filteredPendingDataReviewinfo = filterByValue(
      pendingDataReview,
      e.target.value
    );
    setincubatorListCountHead(filteredData.length.toString());
    setFilteredIncubatorList(filteredData);
    setFilteredPendingData(filteredPendingDatainfo);
    setFilteredPendingDataAll(filteredPendingDataAllinfo);
    setFilteredPendingDataReview(filteredPendingDataReviewinfo);
    // console.log(filteredData)
  };

  const downloadExcel = () => {
    var payload = {};
    if (activeIndex == "tranche") {
      // console.log("Into IF");
      payload = {
        applications_type: "incubator",
        trance_requested: "Yes",
        leagel_entity_id:
          selectedEntityValue.length != 0 ? selectedEntityValue : null,
        state_id: selectedStateValue.length != 0 ? selectedStateValue : null,
        sector_id: selectedSectorValue.length != 0 ? selectedSectorValue : null,
        startup_funded_incubators: oneStartupCheckbox ? "yes" : "",
        no_startup_funded_incubators: noStartupCheckbox ? "yes" : "",
      };
    } else if (activeIndex == "cancel") {
      // console.log("Into Else IF");
      payload = {
        applications_type: "incubator",
        cancellation_requested: "Yes",
        leagel_entity_id:
          selectedEntityValue.length != 0 ? selectedEntityValue : null,
        state_id: selectedStateValue.length != 0 ? selectedStateValue : null,
        sector_id: selectedSectorValue.length != 0 ? selectedSectorValue : null,
        startup_funded_incubators: oneStartupCheckbox ? "yes" : "",
        no_startup_funded_incubators: noStartupCheckbox ? "yes" : "",
      };
    } else {
      // console.log("Into Else");
      payload = {
        applications_type: "incubator",
        status_id: activeIndex,
        leagel_entity_id:
          selectedEntityValue.length != 0 ? selectedEntityValue : null,
        state_id: selectedStateValue.length != 0 ? selectedStateValue : null,
        sector_id: selectedSectorValue.length != 0 ? selectedSectorValue : null,
        startup_funded_incubators: oneStartupCheckbox ? "yes" : "",
        no_startup_funded_incubators: noStartupCheckbox ? "yes" : "",
      };
      // console.log(payload);
    }
    post(DOWNLOAD_EXCEL, payload).then((response: any) => {
      // console.log(payload);
      // console.log(response);
      if (!response || !response.data) return;
      if (response.data.result == "success") {
        downloadDocument(response.data.data, "incubator_list");
      }
    });
  };

const tab = ((e:any,val:any)=>{
// console.log(e,val)

})
  return (
    <div>
      <div className="container container-min pt-5 startup-listing-wrapper">
        <div className="row mb-3">
          <div className="col-md-6">
            <div className="details-tabs">
              <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#tabs-1"
                    role="tab"
                    onClick={ (e:any) => tab(e,"in")}
                  >
                    <p className=" pt-2 mb-2">Incubator</p>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#tabs-3"
                    role="tab"
                    onClick={ (e:any) => tab(e,"re")}
                  >
                    <p className=" pt-2 pl-3">Re-Applied Incubator</p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="col-md-5 text-right ">
            <div className="d-flex searchDiv">
              {" "}
              <img src={search} className="search" alt="search" />
              <input
                className="searchBar"
                type="text"
                placeholder=" Search.."
                name="search"
                onKeyUp={searchList}
              />
            </div>
          </div>
          <div className="col-md-1 filter-icon">
            <img
              src={filter}
              className="img-fluid filter"
              alt="SISFS Logo"
              data-toggle="modal"
              data-target="#filterModal"
            />
            {showFilterDot == true ? <mark></mark> : null}
          </div> */}
        </div>

        <div className="tab-content">
          <div className="tab-pane active" id="tabs-1">
          <div className="row mb-3">
          <div className="col-md-6">
            {/* <div className="details-tabs">
              <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#tabs-1"
                    role="tab"
                  >
                    <p className=" pt-2 mb-2">Incubator</p>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#tabs-3"
                    role="tab"
                  >
                    <p className=" pt-2 pl-3">Re-Applied Incubator</p>
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
          <div className="col-md-5 text-right ">
            <div className="d-flex searchDiv">
              {" "}
              <img src={search} className="search" alt="search" />
              <input
                className="searchBar"
                type="text"
                placeholder=" Search.."
                name="search"
                onKeyUp={searchList}
              />
            </div>
          </div>
          <div className="col-md-1 filter-icon">
            <img
              src={filter}
              className="img-fluid filter"
              alt="SISFS Logo"
              data-toggle="modal"
              data-target="#filterModal"
            />
            {showFilterDot == true ? <mark></mark> : null}
          </div>
        </div>
            <div className="d-flex flex-row cardDiv">
              <div
                className="incubator-card"
                onClick={() =>
                  getDataByStatus(`${incubatorListId.submitted_id}`)
                }
              >
                <div
                  className={
                    activeIndex == incubatorListId.submitted_id
                      ? "rectangle-card-selected"
                      : "rectangle-card"
                  }
                >
                  <span
                    className={
                      activeIndex == incubatorListId.submitted_id
                        ? "smallIncubatorNumeric"
                        : "incubator-numeric-black"
                    }
                  >
                    {incubatorListingCounts.submitted}
                  </span>
                  <br />
                  <span
                    className={
                      activeIndex == incubatorListId.submitted_id
                        ? "incubator-text-selected-small"
                        : "card-text-incubator"
                    }
                  >
                    Applications Submitted
                  </span>
                </div>
              </div>
              <div
                className="incubator-card"
                onClick={() =>
                  getDataByStatus(`${incubatorListId.pendingReview_id}`)
                }
              >
                <div
                  className={
                    activeIndex == incubatorListId.pendingReview_id
                      ? "rectangle-card-selected"
                      : "rectangle-card"
                  }
                >
                  <span
                    className={
                      activeIndex == incubatorListId.pendingReview_id
                        ? "smallIncubatorNumeric reviewed-count"
                        : "incubator-numeric-black reviewed-count"
                    }
                  >
                    {incubatorListingCounts.reviewCount}
                  </span>
                  <br />
                  <span
                    className={
                      activeIndex == incubatorListId.pendingReview_id
                        ? "incubator-text-selected-small reviewed-text"
                        : "card-text-incubator reviewed-text"
                    }
                  >
                    Applications To be Reviewed by DPIIT
                  </span>
                </div>
              </div>
              <div
                className="incubator-card"
                onClick={() =>
                  getDataByStatus(`${incubatorListId.incomplete_id}`)
                }
              >
                <div
                  className={
                    activeIndex == incubatorListId.incomplete_id
                      ? "rectangle-card-selected"
                      : "rectangle-card"
                  }
                >
                  <span
                    className={
                      activeIndex == incubatorListId.incomplete_id
                        ? "smallIncubatorNumeric"
                        : "incubator-numeric-black"
                    }
                  >
                    {incubatorListingCounts.incomplete}
                  </span>
                  <br />
                  <span
                    className={
                      activeIndex == incubatorListId.incomplete_id
                        ? "incubator-text-selected-small"
                        : "card-text-incubator"
                    }
                  >
                    Applications Incomplete
                  </span>
                </div>
              </div>
              <div
                className="incubator-card"
                onClick={() => getDataByStatus(`${incubatorListId.onHold_id}`)}
              >
                <div
                  className={
                    activeIndex == incubatorListId.onHold_id
                      ? "rectangle-card-selected"
                      : "rectangle-card"
                  }
                >
                  <span
                    className={
                      activeIndex == incubatorListId.onHold_id
                        ? "smallIncubatorNumeric"
                        : "incubator-numeric-black"
                    }
                  >
                    {incubatorListingCounts.onHold}
                  </span>
                  <br />
                  <span
                    className={
                      activeIndex == incubatorListId.onHold_id
                        ? "incubator-text-selected-small"
                        : "card-text-incubator"
                    }
                  >
                    Applications <br />
                    on Hold
                  </span>
                </div>
              </div>
              <div
                className="incubator-card"
                onClick={() =>
                  getDataByStatus(`${incubatorListId.selected_id}`)
                }
              >
                <div
                  className={
                    activeIndex == incubatorListId.selected_id
                      ? "rectangle-card-selected"
                      : "rectangle-card"
                  }
                >
                  <span
                    className={
                      activeIndex == incubatorListId.selected_id
                        ? "smallIncubatorNumeric"
                        : "incubator-numeric-black"
                    }
                  >
                    {incubatorListingCounts.selected}
                  </span>
                  <br />
                  <span
                    className={
                      activeIndex == incubatorListId.selected_id
                        ? "incubator-text-selected-small"
                        : "card-text-incubator"
                    }
                  >
                    Applications Selected
                  </span>
                </div>
              </div>
              <div
                className="incubator-card"
                onClick={() =>
                  getDataByStatus(`${incubatorListId.rejected_id}`)
                }
              >
                <div
                  className={
                    activeIndex == incubatorListId.rejected_id
                      ? "rectangle-card-selected"
                      : "rectangle-card"
                  }
                >
                  <span
                    className={
                      activeIndex == incubatorListId.rejected_id
                        ? "smallIncubatorNumeric"
                        : "incubator-numeric-black"
                    }
                  >
                    {incubatorListingCounts.rejected}
                  </span>
                  <br />
                  <span
                    className={
                      activeIndex == incubatorListId.rejected_id
                        ? "incubator-text-selected-small"
                        : "card-text-incubator"
                    }
                  >
                    Applications Rejected
                  </span>
                </div>
              </div>
              <div
                className="incubator-card"
                onClick={() => getDataByStatus("tranche")}
              >
                <div
                  className={
                    activeIndex == "tranche"
                      ? "rectangle-card-selected"
                      : "rectangle-card"
                  }
                >
                  <span
                    className={
                      activeIndex == "tranche"
                        ? "smallIncubatorNumeric"
                        : "incubator-numeric-black"
                    }
                  >
                    {incubatorListingCounts.trancheRequest}
                  </span>
                  <br />
                  <span
                    className={
                      activeIndex == "tranche"
                        ? "incubator-text-selected-small"
                        : "card-text-incubator"
                    }
                  >
                    Tranche Requested
                  </span>
                </div>
              </div>
              <div
                className="incubator-card"
                onClick={() => getDataByStatus(`${incubatorListId.closed_id}`)}
              >
                <div
                  className={
                    activeIndex == incubatorListId.closed_id
                      ? "rectangle-card-selected"
                      : "rectangle-card"
                  }
                >
                  <span
                    className={
                      activeIndex == incubatorListId.closed_id
                        ? "smallIncubatorNumeric"
                        : "incubator-numeric-black"
                    }
                  >
                    {incubatorListingCounts.closed}
                  </span>
                  <br />
                  <span
                    className={
                      activeIndex == incubatorListId.closed_id
                        ? "incubator-text-selected-small"
                        : "card-text-incubator"
                    }
                  >
                    Applications Closed
                  </span>
                </div>
              </div>
              <div
                className="incubator-card"
                onClick={() => getDataByStatus("cancel")}
              >
                <div
                  className={
                    activeIndex == "cancel"
                      ? "rectangle-card-selected"
                      : "rectangle-card"
                  }
                >
                  <span
                    className={
                      activeIndex == "cancel"
                        ? "smallIncubatorNumeric"
                        : "incubator-numeric-black"
                    }
                  >
                    {incubatorListingCounts.cancel}
                  </span>
                  <br />
                  <span
                    className={
                      activeIndex == "cancel"
                        ? "incubator-text-selected-small"
                        : "card-text-incubator"
                    }
                  >
                    Cancellation Request
                  </span>
                </div>
              </div>
              <div
                className="incubator-card"
                onClick={() => getDataByStatus("cancelled")}
              >
                <div
                  className={
                    activeIndex == "cancelled"
                      ? "rectangle-card-selected"
                      : "rectangle-card"
                  }
                >
                  <span
                    className={
                      activeIndex == "cancelled"
                        ? "smallIncubatorNumeric"
                        : "incubator-numeric-black"
                    }
                  >
                    {incubatorListingCounts.cancelled}
                  </span>
                  <br />
                  <span
                    className={
                      activeIndex == "cancelled"
                        ? "incubator-text-selected-small"
                        : "card-text-incubator"
                    }
                  >
                  Applications Cancelled
                  </span>
                </div>
              </div>
            </div>
            <div className="row incubator-heading-div">
              <div className="col-md-3 pt-2">
                {incompleteApplications.length == 0 ? (
                  <>
                    {" "}
                    <span className="table-head">{tableHeading}</span>
                    <br />
                    <p className="count-text-incubator">
                      ({incubatorListCountHead} Results)
                    </p>
                  </>
                ) : (
                  <ul className="nav nav-tabs chart-nav" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-toggle="tab"
                        href="#tabs-1"
                        role="tab"
                      >
                        All
                      </a>
                    </li>
                    <div className="vertical-dash"></div>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#tabs-2"
                        role="tab"
                      >
                        Clarification Sought
                      </a>
                    </li>
                    <div className="vertical-dash"></div>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#tabs-3"
                        role="tab"
                      >
                        Incomplete
                      </a>
                    </li>
                  </ul>
                )}
              </div>
              <div className="col-md-12 text-center mb-3">
                <button
                  className="tertiary btn-yellow-primary  mr-3"
                  onClick={() => {
                    setShowReport(true);
                  }}
                >
                  Download Report
                </button>
                <button
                  className={
                    buttonDisable == true
                      ? "tertiary  btn-yellow-primary btn-yellow-disabled"
                      : "tertiary  btn-yellow-primary"
                  }
                  disabled={buttonDisable}
                  data-toggle="modal"
                  data-target="#scheduleMeeting"
                >
                  SCHEDULE A MEETING
                </button>
                <button
                  className="tertiary btn-yellow-primary ml-3 mt-sm-2"
                  onClick={() => downloadExcel()}
                >
                  DOWNLOAD EXCEL
                </button>
              </div>
            </div>
            {incompleteApplications.length == 0 ? (
              <div className="table-scroll mb-5">
                <table className="reviewTable" id="customTable">
                  <thead>
                    <tr>
                      {checkBoxHide == false ? (
                        <th className="text-center table-heading">
                          {/* <input className="form-check-input" type="checkbox" onChange={handleAllCheck} /> */}

                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="customcheckbox1"
                              name="customcheckbox"
                              className="custom-control-input"
                              onChange={handleAllCheck}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customcheckbox1"
                            ></label>
                          </div>
                        </th>
                      ) : null}
                      <th className="text-left table-heading">
                        Incubator Name
                      </th>
                      {columnHideFlag == true ? (
                        <th className="text-left table-heading">
                          Amount Approved (&#8377;)
                        </th>
                      ) : null}
                      {columnHideFlag == true ? (
                        <th className="text-left table-heading">
                          Amount Disbursed (&#8377;)
                        </th>
                      ) : null}
                      {columnHideFlag == true ? (
                        <th className="text-left table-heading">
                          No. of Startups Supported{" "}
                        </th>
                      ) : null}
                      {columnHideFlag == true ? (
                        <th className="text-left table-heading custom-td">
                          Amount Disbursed to Startups (&#8377;)
                        </th>
                      ) : null}
                      {columnHideFlag != true ? (
                        <th className="text-left table-heading">State</th>
                      ) : null}
                      {columnHideFlag != true ? (
                        <th className="text-left table-heading">
                          Assisted by Central/State Govt.
                        </th>
                      ) : null}
                      {columnHideFlag != true ? (
                        <th className="text-left table-heading">
                          Date of Application
                        </th>
                      ) : null}
                      {activeIndex == "cancel" && (
                        <th className="text-left table-heading">Status</th>
                      )}
 {activeIndex == "cancelled" && (
                        <th className="text-left table-heading">Status</th>
                      )}
                      <th className="text-center custom-td table-heading">
                        Meeting Details
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {incubatorList.length == 0 ||
                    filteredIncubatorList.length == 0 ? (
                      <tr>
                        <td className="text-center table-heading" colSpan={7}>
                          No data
                        </td>
                      </tr>
                    ) : filteredIncubatorList.length == 0 ||
                      filteredIncubatorList[0].application_id == "" ? (
                      incubatorList.map(
                        ({
                          application_id,
                          application_date,
                          government_assisted,
                          state_name,
                          incubator_name,
                          total_approved_amt,
                          total_disbursed_amt,
                          meeting_date,
                          meeting_time,
                          cancellation_status,
                          meeting_status,
                          status_id,
                          count_startups_supported,
                          amount_disbursed,
                          incubator_application_no,
                        }) => (
                          <tr>
                            {checkBoxHide == false ? (
                              <td>
                                {/* <input
                          type="checkbox"
                          disabled={isChecked}
                          onChange={handleListChange}
                          value={application_id}
                        /> */}
                                {/* <input className="form-check-input" type="checkbox"  value={application_id}
                            id={application_id}
                            name={application_id}    disabled={isChecked}
                            onChange={handleListChange}/> */}
                                <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    value={application_id}
                                    id={incubator_name + "_" + application_id}
                                    name={incubator_name + "_" + application_id}
                                    className="custom-control-input"
                                    disabled={isChecked}
                                    onChange={handleListChange}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={
                                      incubator_name + "_" + application_id
                                    }
                                  ></label>
                                </div>
                              </td>
                            ) : null}
                            <td
                              className="text-left custom-td"
                              onClick={() => handleRowChange(application_id)}
                            >
                              <span className="tableDataHead">
                                {incubator_name}
                              </span>
                              <br />
                              <span className="tableDataApplication">
                                No:{" "}
                                {incubator_application_no == null || ""
                                  ? application_id
                                  : incubator_application_no}
                                {/* No:  {incubator_application_no  === '' || null ? application_id  : incubator_application_no} */}
                              </span>
                            </td>
                            {columnHideFlag == true ? (
                              <td
                                onClick={() => handleRowChange(application_id)}
                                className="text-center custom-td-small customcenter"
                              >
                                {total_approved_amt
                                  ? changeNumberFormat(
                                      Number(total_approved_amt),
                                      1,
                                      0
                                    )
                                  : 0}
                              </td>
                            ) : null}
                            {columnHideFlag == true ? (
                              <td
                                onClick={() => handleRowChange(application_id)}
                                className="text-center custom-td-small custom-td-right"
                              >
                                {total_disbursed_amt
                                  ? changeNumberFormat(
                                      Number(total_disbursed_amt),
                                      1,
                                      0
                                    )
                                  : 0}
                              </td>
                            ) : null}
                            {columnHideFlag == true ? (
                              <td
                                onClick={() => handleRowChange(application_id)}
                                className="text-center custom-td-small"
                              >
                                {count_startups_supported
                                  ? count_startups_supported
                                  : 0}
                              </td>
                            ) : null}
                            {columnHideFlag == true ? (
                              <td
                                onClick={() => handleRowChange(application_id)}
                                className="text-center custom-td-small custom-td-right"
                              >
                                {amount_disbursed
                                  ? changeNumberFormat(
                                      Number(amount_disbursed),
                                      1,
                                      0
                                    )
                                  : 0}
                              </td>
                            ) : null}

                            {columnHideFlag != true ? (
                              <td
                                onClick={() => handleRowChange(application_id)}
                                className="text-left custom-td"
                              >
                                {state_name}
                              </td>
                            ) : null}
                            {columnHideFlag != true ? (
                              <td
                                onClick={() => handleRowChange(application_id)}
                                className="text-left custom-td-left"
                              >
                                {government_assisted}
                              </td>
                            ) : null}
                            {columnHideFlag != true ? (
                              <td
                                onClick={() => handleRowChange(application_id)}
                                className="text-left custom-td custom-td-width"
                              >
                                {application_date}
                              </td>
                            ) : null}
                            {activeIndex == "cancel" && (
                              <td className="text-left custom-td">
                                {cancellation_status}
                              </td>
                            )}
                            {activeIndex == "cancelled" && (
                              <td className="text-left custom-td">
                                {cancellation_status}
                              </td>
                            )}
                            <td
                              onMouseOver={() => mouseOverEvent(application_id)}
                              onMouseOut={() => setMouseOverFlag("")}
                              onClick={() =>
                                quickEditData(
                                  incubator_name,
                                  application_date,
                                  status_id,
                                  application_id
                                )
                              }
                              data-toggle="modal"
                              data-target="#quickEdit"
                              className="text-center edit-column"
                            >
                              {mouseOverFlag == application_id ? (
                                <img
                                  src={edit}
                                  className="search"
                                  alt="search"
                                />
                              ) : (meeting_date == "" && meeting_time == "") ||
                                meeting_status == "NA" ? (
                                // <>
                                //   <div className="badge-red">
                                //     Today:{meeting_time}
                                //   </div>
                                //   <span className="tableDataApplication">
                                //     {meeting_date}
                                //   </span>
                                // </>
                                <div className="badge-grey">
                                  {meeting_status}
                                </div>
                              ) : meeting_status == "Today" ? (
                                <>
                                  <div className="badge-red">
                                    Today:{" "}
                                    {meeting_time != null
                                      ? formatTime(meeting_time)
                                      : ""}
                                  </div>
                                  <span className="tableDataApplication">
                                    {FormatDate(meeting_date)}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <div className="badge-grey">
                                    {meeting_status}
                                  </div>
                                  <span className="tableDataApplication">
                                    {FormatDate(meeting_date)}
                                  </span>
                                </>
                              )}
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      filteredIncubatorList.map(
                        ({
                          application_id,
                          application_date,
                          government_assisted,
                          state_name,
                          incubator_name,
                          total_approved_amt,
                          total_disbursed_amt,
                          meeting_date,
                          meeting_time,
                          meeting_status,
                          status_id,
                          count_startups_supported,
                          amount_disbursed,
                          incubator_application_no,
                        }) => (
                          <tr>
                            {checkBoxHide == false ? (
                              <td>
                                {/* <input
                          type="checkbox"
                          disabled={isChecked}
                          onChange={handleListChange}
                          value={application_id}
                        /> */}
                                {/* <input className="form-check-input" type="checkbox"  value={application_id}
                            id={application_id}
                            name={application_id}    disabled={isChecked}
                            onChange={handleListChange}/> */}
                                <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    value={application_id}
                                    id={incubator_name + "_" + application_id}
                                    name={incubator_name + "_" + application_id}
                                    className="custom-control-input"
                                    disabled={isChecked}
                                    onChange={handleListChange}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={
                                      incubator_name + "_" + application_id
                                    }
                                  ></label>
                                </div>
                              </td>
                            ) : null}
                            <td
                              className="text-left custom-td"
                              onClick={() => handleRowChange(application_id)}
                            >
                              <span className="tableDataHead">
                                {incubator_name}
                              </span>
                              <br />
                              <span className="tableDataApplication">
                                No:{" "}
                                {incubator_application_no == null || ""
                                  ? application_id
                                  : incubator_application_no}
                              </span>
                            </td>
                            {columnHideFlag == true ? (
                              <td
                                onClick={() => handleRowChange(application_id)}
                                className="text-right custom-td-small custom-td-right"
                              >
                                {total_approved_amt
                                  ? changeNumberFormat(
                                      Number(total_approved_amt),
                                      1,
                                      0
                                    )
                                  : 0}
                              </td>
                            ) : null}
                            {columnHideFlag == true ? (
                              <td
                                onClick={() => handleRowChange(application_id)}
                                className="text-right custom-td-small custom-td-right"
                              >
                                {total_disbursed_amt
                                  ? changeNumberFormat(
                                      Number(total_disbursed_amt),
                                      1,
                                      0
                                    )
                                  : 0}
                              </td>
                            ) : null}
                            {columnHideFlag == true ? (
                              <td
                                onClick={() => handleRowChange(application_id)}
                                className="text-center custom-td-small"
                              >
                                {count_startups_supported
                                  ? count_startups_supported
                                  : 0}
                              </td>
                            ) : null}
                            {columnHideFlag == true ? (
                              <td
                                onClick={() => handleRowChange(application_id)}
                                className="text-center custom-td-small custom-td-right"
                              >
                                {amount_disbursed
                                  ? changeNumberFormat(
                                      Number(amount_disbursed),
                                      1,
                                      0
                                    )
                                  : 0}
                              </td>
                            ) : null}

                            {columnHideFlag != true ? (
                              <td
                                onClick={() => handleRowChange(application_id)}
                                className="text-left custom-td"
                              >
                                {state_name}
                              </td>
                            ) : null}
                            {columnHideFlag != true ? (
                              <td
                                onClick={() => handleRowChange(application_id)}
                                className="text-left custom-td-left"
                              >
                                {government_assisted}
                              </td>
                            ) : null}
                            {columnHideFlag != true ? (
                              <td
                                onClick={() => handleRowChange(application_id)}
                                className="text-left custom-td custom-td-width"
                              >
                                {application_date}
                              </td>
                            ) : null}

                            <td
                              onMouseOver={() => mouseOverEvent(application_id)}
                              onMouseOut={() => setMouseOverFlag("")}
                              onClick={() =>
                                quickEditData(
                                  incubator_name,
                                  application_date,
                                  status_id,
                                  application_id
                                )
                              }
                              data-toggle="modal"
                              data-target="#quickEdit"
                              className="text-center custom-td edit-column"
                            >
                              {mouseOverFlag == application_id ? (
                                <img
                                  src={edit}
                                  className="search"
                                  alt="search"
                                />
                              ) : meeting_date == "" && meeting_time == "" ? (
                                // <>
                                //   <div className="badge-red">
                                //     Today:{meeting_time}
                                //   </div>
                                //   <span className="tableDataApplication">
                                //     {meeting_date}
                                //   </span>
                                // </>
                                <div className="badge-grey">
                                  {meeting_status}
                                </div>
                              ) : meeting_status == "Today" ? (
                                <>
                                  <div className="badge-red">
                                    Today:{" "}
                                    {meeting_time != null
                                      ? formatTime(meeting_time)
                                      : ""}
                                  </div>
                                  <span className="tableDataApplication">
                                    {FormatDate(meeting_date)}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <div className="badge-grey">
                                    {meeting_status}
                                  </div>
                                  <span className="tableDataApplication">
                                    {FormatDate(meeting_date)}
                                  </span>
                                </>
                              )}
                            </td>
                          </tr>
                        )
                      )
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="tab-content">
                <div className="tab-pane active" id="tabs-1">
                  <div className="table-scroll">
                    <table className="reviewTable grievanceTable">
                      <thead>
                        <tr>
                          <th className="text-left table-heading custom-td-left table-heading ">
                            Incubator Name
                          </th>
                          <th className="text-left table-heading">State</th>
                          <th className="text-left table-heading">
                            Assisted by Central/State Govt.
                          </th>
                          <th className="text-left table-heading">
                            Date of Application
                          </th>
                          <th className="text-left table-heading">
                            Meeting Details
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {pendingDataAll.length == 0 ||
                        (filteredPendingDataAll.length == 0 &&
                          noDataFilter != "") ? (
                          <tr>
                            <td className="text-center" colSpan={5}>
                              No data
                            </td>
                          </tr>
                        ) : filteredPendingDataAll.length == 0 ? (
                          pendingDataAll.map((item: any) => (
                            <tr>
                              <td
                                className="text-left custom-td"
                                onClick={() =>
                                  handleRowChange(item.application_id)
                                }
                              >
                                <span className="tableDataHead">
                                  {item.incubator_name}
                                </span>
                                <br />
                                <span className="tableDataApplication">
                                  No:{" "}
                                  {item.incubator_application_no == null || ""
                                    ? item.application_id
                                    : item.incubator_application_no}
                                </span>
                              </td>
                              <td
                                onClick={() =>
                                  handleRowChange(item.application_id)
                                }
                                className="text-left custom-td"
                              >
                                {item.state_name}
                              </td>
                              <td
                                onClick={() =>
                                  handleRowChange(item.application_id)
                                }
                                className="text-left custom-td-left"
                              >
                                {item.government_assisted}
                              </td>
                              <td
                                onClick={() =>
                                  handleRowChange(item.application_id)
                                }
                                className="text-left custom-td custom-td-width"
                              >
                                {FormatDate(item.application_date)}
                              </td>

                              <td
                                data-toggle="modal"
                                data-target="#quickEdit"
                                className="text-center custom-td"
                                onClick={() =>
                                  quickEditData(
                                    item.incubator_name,
                                    item.application_date,
                                    item.status_id,
                                    item.application_id
                                  )
                                }
                                onMouseOver={() =>
                                  mouseOverEvent(item.application_id)
                                }
                                onMouseOut={() => setMouseOverFlag("")}
                              >
                                {mouseOverFlag == item.application_id ? (
                                  <img
                                    src={edit}
                                    className="search"
                                    alt="search"
                                    data-toggle="modal"
                                    data-target="#quickEdit"
                                  />
                                ) : (item.meetingData.meeting_date == "" &&
                                    typeof item.meetingData.meeting_time ==
                                      "undefined") ||
                                  item.meetingData.status == "NA" ? (
                                  // <>
                                  //   <div className="badge-red">
                                  //     Today:{meeting_time}
                                  //   </div>
                                  //   <span className="tableDataApplication">
                                  //     {meeting_date}
                                  //   </span>
                                  // </>
                                  <div className="badge-grey">
                                    {item.meetingData.status}
                                  </div>
                                ) : item.meetingData.status == "Today" ? (
                                  <>
                                    <div className="badge-red">
                                      Today:{" "}
                                      {item.meetingData.meeting_time != null
                                        ? formatTime(
                                            item.meetingData.meeting_time
                                          )
                                        : ""}
                                    </div>
                                    <span className="tableDataApplication">
                                      {FormatDate(
                                        item.meetingData.meeting_date
                                      )}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <div className="badge-grey">
                                      {item.meetingData.status}
                                    </div>
                                    <span className="tableDataApplication">
                                      {FormatDate(
                                        item.meetingData.meeting_date
                                      )}
                                    </span>
                                  </>
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          filteredPendingDataAll.map((item: any) => (
                            <tr>
                              <td
                                className="text-left custom-td"
                                onClick={() =>
                                  handleRowChange(item.application_id)
                                }
                              >
                                <span className="tableDataHead">
                                  {item.incubator_name}
                                </span>
                                <br />
                                <span className="tableDataApplication">
                                  No:{" "}
                                  {item.incubator_application_no == null || ""
                                    ? item.application_id
                                    : item.incubator_application_no}
                                </span>
                              </td>
                              <td
                                onClick={() =>
                                  handleRowChange(item.application_id)
                                }
                                className="text-left custom-td"
                              >
                                {item.state_name}
                              </td>
                              <td
                                onClick={() =>
                                  handleRowChange(item.application_id)
                                }
                                className="text-left custom-td-left"
                              >
                                {item.government_assisted}
                              </td>
                              <td
                                onClick={() =>
                                  handleRowChange(item.application_id)
                                }
                                className="text-left custom-td custom-td-width"
                              >
                                {FormatDate(item.application_date)}
                              </td>
                              <td
                                data-toggle="modal"
                                data-target="#quickEdit"
                                className="text-center custom-td"
                                onClick={() =>
                                  quickEditData(
                                    item.incubator_name,
                                    item.application_date,
                                    item.status_id,
                                    item.application_id
                                  )
                                }
                                onMouseOver={() =>
                                  mouseOverEvent(item.application_id)
                                }
                                onMouseOut={() => setMouseOverFlag("")}
                              >
                                {mouseOverFlag == item.application_id ? (
                                  <img
                                    src={edit}
                                    className="search"
                                    alt="search"
                                    data-toggle="modal"
                                    data-target="#quickEdit"
                                  />
                                ) : (item.meetingData.meeting_date == "" &&
                                    typeof item.meetingData.meeting_time ==
                                      "undefined") ||
                                  item.meetingData.status == "NA" ? (
                                  // <>
                                  //   <div className="badge-red">
                                  //     Today:{meeting_time}
                                  //   </div>
                                  //   <span className="tableDataApplication">
                                  //     {meeting_date}
                                  //   </span>
                                  // </>
                                  <div className="badge-grey">
                                    {item.meetingData.status}
                                  </div>
                                ) : item.meetingData.status == "Today" ? (
                                  <>
                                    <div className="badge-red">
                                      Today:{" "}
                                      {item.meetingData.meeting_time != null
                                        ? formatTime(
                                            item.meetingData.meeting_time
                                          )
                                        : ""}
                                    </div>
                                    <span className="tableDataApplication">
                                      {FormatDate(
                                        item.meetingData.meeting_date
                                      )}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <div className="badge-grey">
                                      {item.meetingData.status}
                                    </div>
                                    <span className="tableDataApplication">
                                      {FormatDate(
                                        item.meetingData.meeting_date
                                      )}
                                    </span>
                                  </>
                                )}
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="tab-pane" id="tabs-2">
                  <div className="table-scroll">
                    <table className="reviewTable grievanceTable">
                      <thead>
                        <tr>
                          <th className="text-left table-heading custom-td-left table-heading ">
                            Incubator Name
                          </th>
                          <th className="text-left table-heading">State</th>
                          <th className="text-left table-heading">
                            Assisted by Central/State Govt.
                          </th>
                          <th className="text-left table-heading">
                            Date of Application
                          </th>
                          <th className="text-left table-heading">
                            Meeting Details
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {pendingDataReview.length == 0 ||
                        (filteredPendingDataReview.length == 0 &&
                          noDataFilter != "") ? (
                          <tr>
                            <td className="text-center" colSpan={5}>
                              No data
                            </td>
                          </tr>
                        ) : filteredPendingDataReview.length == 0 ? (
                          pendingDataReview.map((item: any) => (
                            <tr>
                              <td
                                className="text-left custom-td"
                                onClick={() =>
                                  handleRowChange(item.application_id)
                                }
                              >
                                <span className="tableDataHead">
                                  {item.incubator_name}
                                </span>
                                <br />
                                <span className="tableDataApplication">
                                  No:{" "}
                                  {item.incubator_application_no == null || ""
                                    ? item.application_id
                                    : item.incubator_application_no}
                                </span>
                              </td>
                              <td
                                onClick={() =>
                                  handleRowChange(item.application_id)
                                }
                                className="text-left custom-td"
                              >
                                {item.state_name}
                              </td>
                              <td
                                onClick={() =>
                                  handleRowChange(item.application_id)
                                }
                                className="text-left custom-td-left"
                              >
                                {item.government_assisted}
                              </td>
                              <td
                                onClick={() =>
                                  handleRowChange(item.application_id)
                                }
                                className="text-left custom-td custom-td-width"
                              >
                                {FormatDate(item.application_date)}
                              </td>
                              <td
                                data-toggle="modal"
                                data-target="#quickEdit"
                                className="text-center custom-td"
                                onClick={() =>
                                  quickEditData(
                                    item.incubator_name,
                                    item.application_date,
                                    item.status_id,
                                    item.application_id
                                  )
                                }
                                onMouseOver={() =>
                                  mouseOverEvent(item.application_id)
                                }
                                onMouseOut={() => setMouseOverFlag("")}
                              >
                                {mouseOverFlag == item.application_id ? (
                                  <img
                                    src={edit}
                                    className="search"
                                    alt="search"
                                    data-toggle="modal"
                                    data-target="#quickEdit"
                                  />
                                ) : (item.meetingData.meeting_date == "" &&
                                    typeof item.meetingData.meeting_time ==
                                      "undefined") ||
                                  item.meetingData.status == "NA" ? (
                                  // <>
                                  //   <div className="badge-red">
                                  //     Today:{meeting_time}
                                  //   </div>
                                  //   <span className="tableDataApplication">
                                  //     {meeting_date}
                                  //   </span>
                                  // </>
                                  <div className="badge-grey">
                                    {item.meetingData.status}
                                  </div>
                                ) : item.meetingData.status == "Today" ? (
                                  <>
                                    <div className="badge-red">
                                      Today:{" "}
                                      {item.meetingData.meeting_time != null
                                        ? formatTime(
                                            item.meetingData.meeting_time
                                          )
                                        : ""}
                                    </div>
                                    <span className="tableDataApplication">
                                      {FormatDate(
                                        item.meetingData.meeting_date
                                      )}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <div className="badge-grey">
                                      {item.meetingData.status}
                                    </div>
                                    <span className="tableDataApplication">
                                      {FormatDate(
                                        item.meetingData.meeting_date
                                      )}
                                    </span>
                                  </>
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          filteredPendingDataReview.map((item: any) => (
                            <tr>
                              <td
                                className="text-left custom-td"
                                onClick={() =>
                                  handleRowChange(item.application_id)
                                }
                              >
                                <span className="tableDataHead">
                                  {item.incubator_name}
                                </span>
                                <br />
                                <span className="tableDataApplication">
                                  No:{" "}
                                  {item.incubator_application_no == null || ""
                                    ? item.application_id
                                    : item.incubator_application_no}
                                </span>
                              </td>
                              <td
                                onClick={() =>
                                  handleRowChange(item.application_id)
                                }
                                className="text-left custom-td"
                              >
                                {item.state_name}
                              </td>
                              <td
                                onClick={() =>
                                  handleRowChange(item.application_id)
                                }
                                className="text-left custom-td-left"
                              >
                                {item.government_assisted}
                              </td>
                              <td
                                onClick={() =>
                                  handleRowChange(item.application_id)
                                }
                                className="text-left custom-td custom-td-width"
                              >
                                {FormatDate(item.application_date)}
                              </td>
                              <td
                                data-toggle="modal"
                                data-target="#quickEdit"
                                className="text-center custom-td"
                                onClick={() =>
                                  quickEditData(
                                    item.incubator_name,
                                    item.application_date,
                                    item.status_id,
                                    item.application_id
                                  )
                                }
                                onMouseOver={() =>
                                  mouseOverEvent(item.application_id)
                                }
                                onMouseOut={() => setMouseOverFlag("")}
                              >
                                {mouseOverFlag == item.application_id ? (
                                  <img
                                    src={edit}
                                    className="search"
                                    alt="search"
                                    data-toggle="modal"
                                    data-target="#quickEdit"
                                  />
                                ) : (item.meetingData.meeting_date == "" &&
                                    typeof item.meetingData.meeting_time ==
                                      "undefined") ||
                                  item.meetingData.status == "NA" ? (
                                  // <>
                                  //   <div className="badge-red">
                                  //     Today:{meeting_time}
                                  //   </div>
                                  //   <span className="tableDataApplication">
                                  //     {meeting_date}
                                  //   </span>
                                  // </>
                                  <div className="badge-grey">
                                    {item.meetingData.status}
                                  </div>
                                ) : item.meetingData.status == "Today" ? (
                                  <>
                                    <div className="badge-red">
                                      Today:{" "}
                                      {item.meetingData.meeting_time != null
                                        ? formatTime(
                                            item.meetingData.meeting_time
                                          )
                                        : ""}
                                    </div>
                                    <span className="tableDataApplication">
                                      {FormatDate(
                                        item.meetingData.meeting_date
                                      )}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <div className="badge-grey">
                                      {item.meetingData.status}
                                    </div>
                                    <span className="tableDataApplication">
                                      {FormatDate(
                                        item.meetingData.meeting_date
                                      )}
                                    </span>
                                  </>
                                )}
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="tab-pane" id="tabs-3">
                  <div className="table-scroll">
                    <table className="reviewTable grievanceTable">
                      <thead>
                        <tr>
                          <th className="text-left table-heading custom-td-left table-heading ">
                            Incubator Name
                          </th>
                          <th className="text-left table-heading">State</th>
                          <th className="text-left table-heading">
                            Assisted by Central/State Govt.
                          </th>
                          <th className="text-left table-heading">
                            Date of Application
                          </th>
                          <th className="text-left table-heading">
                            Meeting Details
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {pendingData.length == 0 ||
                        (filteredPendingData.length == 0 &&
                          noDataFilter != "") ? (
                          <tr>
                            <td className="text-center" colSpan={5}>
                              No data
                            </td>
                          </tr>
                        ) : filteredPendingData.length == 0 ? (
                          pendingData.map((item: any) => (
                            <tr>
                              <td
                                className="text-left custom-td"
                                onClick={() =>
                                  handleRowChange(item.application_id)
                                }
                              >
                                <span className="tableDataHead">
                                  {item.incubator_name}
                                </span>
                                <br />
                                <span className="tableDataApplication">
                                  No:{" "}
                                  {item.incubator_application_no == null || ""
                                    ? item.application_id
                                    : item.incubator_application_no}
                                </span>
                              </td>
                              <td
                                onClick={() =>
                                  handleRowChange(item.application_id)
                                }
                                className="text-left custom-td"
                              >
                                {item.state_name}
                              </td>
                              <td
                                onClick={() =>
                                  handleRowChange(item.application_id)
                                }
                                className="text-left custom-td-left"
                              >
                                {item.government_assisted}
                              </td>
                              <td
                                onClick={() =>
                                  handleRowChange(item.application_id)
                                }
                                className="text-left custom-td custom-td-width"
                              >
                                {FormatDate(item.application_date)}
                              </td>
                              <td
                                data-toggle="modal"
                                data-target="#quickEdit"
                                className="text-center custom-td"
                                onClick={() =>
                                  quickEditData(
                                    item.incubator_name,
                                    item.application_date,
                                    item.status_id,
                                    item.application_id
                                  )
                                }
                                onMouseOver={() =>
                                  mouseOverEvent(item.application_id)
                                }
                                onMouseOut={() => setMouseOverFlag("")}
                              >
                                {mouseOverFlag == item.application_id ? (
                                  <img
                                    src={edit}
                                    className="search"
                                    alt="search"
                                    data-toggle="modal"
                                    data-target="#quickEdit"
                                  />
                                ) : (item.meetingData.meeting_date == "" &&
                                    typeof item.meetingData.meeting_time ==
                                      "undefined") ||
                                  item.meetingData.status == "NA" ? (
                                  // <>
                                  //   <div className="badge-red">
                                  //     Today:{meeting_time}
                                  //   </div>
                                  //   <span className="tableDataApplication">
                                  //     {meeting_date}
                                  //   </span>
                                  // </>
                                  <div className="badge-grey">
                                    {item.meetingData.status}
                                  </div>
                                ) : item.meetingData.status == "Today" ? (
                                  <>
                                    <div className="badge-red">
                                      Today:{" "}
                                      {item.meetingData.meeting_time != null
                                        ? formatTime(
                                            item.meetingData.meeting_time
                                          )
                                        : ""}
                                    </div>
                                    <span className="tableDataApplication">
                                      {FormatDate(
                                        item.meetingData.meeting_date
                                      )}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <div className="badge-grey">
                                      {item.meetingData.status}
                                    </div>
                                    <span className="tableDataApplication">
                                      {FormatDate(
                                        item.meetingData.meeting_date
                                      )}
                                    </span>
                                  </>
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          filteredPendingData.map((item: any) => (
                            <tr>
                              <td
                                className="text-left custom-td"
                                onClick={() =>
                                  handleRowChange(item.application_id)
                                }
                              >
                                <span className="tableDataHead">
                                  {item.incubator_name}
                                </span>
                                <br />
                                <span className="tableDataApplication">
                                  No:{" "}
                                  {item.incubator_application_no == null || ""
                                    ? item.application_id
                                    : item.incubator_application_no}
                                </span>
                              </td>
                              <td
                                onClick={() =>
                                  handleRowChange(item.application_id)
                                }
                                className="text-left custom-td"
                              >
                                {item.state_name}
                              </td>
                              <td
                                onClick={() =>
                                  handleRowChange(item.application_id)
                                }
                                className="text-left custom-td-left"
                              >
                                {item.government_assisted}
                              </td>
                              <td
                                onClick={() =>
                                  handleRowChange(item.application_id)
                                }
                                className="text-left custom-td custom-td-width"
                              >
                                {FormatDate(item.application_date)}
                              </td>
                              <td
                                data-toggle="modal"
                                data-target="#quickEdit"
                                className="text-center custom-td"
                                onClick={() =>
                                  quickEditData(
                                    item.incubator_name,
                                    item.application_date,
                                    item.status_id,
                                    item.application_id
                                  )
                                }
                                onMouseOver={() =>
                                  mouseOverEvent(item.application_id)
                                }
                                onMouseOut={() => setMouseOverFlag("")}
                              >
                                {mouseOverFlag == item.application_id ? (
                                  <img
                                    src={edit}
                                    className="search"
                                    alt="search"
                                    data-toggle="modal"
                                    data-target="#quickEdit"
                                  />
                                ) : (item.meetingData.meeting_date == "" &&
                                    typeof item.meetingData.meeting_time ==
                                      "undefined") ||
                                  item.meetingData.status == "NA" ? (
                                  // <>
                                  //   <div className="badge-red">
                                  //     Today:{meeting_time}
                                  //   </div>
                                  //   <span className="tableDataApplication">
                                  //     {meeting_date}
                                  //   </span>
                                  // </>
                                  <div className="badge-grey">
                                    {item.meetingData.status}
                                  </div>
                                ) : item.meetingData.status == "Today" ? (
                                  <>
                                    <div className="badge-red">
                                      Today:{" "}
                                      {item.meetingData.meeting_time != null
                                        ? formatTime(
                                            item.meetingData.meeting_time
                                          )
                                        : ""}
                                    </div>
                                    <span className="tableDataApplication">
                                      {FormatDate(
                                        item.meetingData.meeting_date
                                      )}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <div className="badge-grey">
                                      {item.meetingData.status}
                                    </div>
                                    <span className="tableDataApplication">
                                      {FormatDate(
                                        item.meetingData.meeting_date
                                      )}
                                    </span>
                                  </>
                                )}
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Re-Applied Incubators Tab */}
          <div className="tab-pane " id="tabs-3">
           <ReApplied/>
          </div>
        </div>
      </div>



      {/* modal for filter */}
      <div
        className="modal fade"
        id="filterModal"
        role="dialog"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-slideout modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-bottom">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pl-5 pr-5 pt-1">
              <div className="popup-head mb-5 ml-3">
                <div className="main-heading">Filters</div>
              </div>

              <label className="col-sm-4 col-form-head">Location</label>
              <div className="col-md-12 mb-4">
                <MultiSelect
                  id="countryStates"
                  options={countryStates}
                  defaultText="Select State"
                  onClose={(selectedIds: string[]) =>
                    onMultiSelectChange(selectedIds, "state")
                  }
                  selectedValues={selectedStateValue ? selectedStateValue : []}
                  hasError
                  errorText=""
                  searchable
                />
              </div>
              <label className="col-sm-4 col-form-head">Sector</label>
              <div className="col-md-12 mb-4">
                <MultiSelect
                  id="countryStates"
                  options={sectorDetails}
                  defaultText="Select Industry"
                  onClose={(selectedIds: string[]) =>
                    onMultiSelectChange(selectedIds, "sector")
                  }
                  selectedValues={
                    selectedSectorValue ? selectedSectorValue : []
                  }
                  hasError
                  errorText=""
                  searchable
                />
              </div>
              <label className="col-sm-4 col-form-head">Legal Entity</label>
              <div className="col-md-12 mb-4">
                <MultiSelect
                  id="countryStates"
                  options={entityDetails}
                  defaultText="Select Industry"
                  onClose={(selectedIds: string[]) =>
                    onMultiSelectChange(selectedIds, "entity")
                  }
                  selectedValues={
                    selectedEntityValue ? selectedEntityValue : []
                  }
                  hasError
                  errorText=""
                  searchable
                />
              </div>
              {/* <label className="col-sm-4 col-form-head">Due Review</label>
              <div className="col-md-12 mb-4 ">
                <div  className="select-custom">
                <select disabled={true}
                  className="form-control input-height"
                  aria-label="Select Entity Type"
                >
                  <option selected>Incubator Selection</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
                </div>
              </div> */}
              <fieldset disabled={true}>
                {/* <label className="col-sm-4 col-form-head">Tranche</label>
              <div className="col-md-12 mb-4">
              <div  className="select-custom">
                <select disabled={true}
                  className="form-control input-height"
                  aria-label="Select Entity Type"
                >
                  <option selected>Incubator Selection</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
                </div>
              </div> */}
                <div className="row ml-3">
                  <div className="pt-1 mr-2">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        id="one_startup"
                        className="custom-control-input"
                        onChange={handleOnCheck}
                        checked={oneStartupCheckbox}
                        name="one_startup"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="one_startup"
                      ></label>
                    </div>
                    {/* <input
                    type="checkbox"
                    onChange={handleOnCheck}
                    checked={oneStartupCheckbox}
                    name="one_startup"
                  /> */}
                  </div>
                  <div className="checkbox-text">
                    Incubators that have funded atleast 1 startup
                  </div>
                </div>
                <div className="row ml-3 mb-4">
                  <div className="pt-1 mr-2">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        id="any_startup"
                        className="custom-control-input"
                        onChange={handleOnCheck}
                        checked={noStartupCheckbox}
                        name="any_startup"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="any_startup"
                      ></label>
                    </div>
                    {/* <input
                    type="checkbox"
                    onChange={handleOnCheck}
                    checked={noStartupCheckbox}
                    name="any_startup"
                  /> */}
                  </div>
                  <div className="checkbox-text">
                    Incubators that have not funded any startups
                  </div>
                </div>
              </fieldset>
            </div>
            <div className="modal-footer">
              <div className="row modal-row">
                <div className="col-md-6 text-center">
                  <button
                    className="btn btn-sisfs btn-transparent-primary"
                    data-dismiss="modal"
                    onClick={resetFilter}
                  >
                    RESET ALL
                  </button>
                </div>
                <div className="col-md-6 text-left">
                  <button
                    className="tertiary  btn-yellow-primary"
                    data-dismiss="modal"
                    onClick={applyFilter}
                  >
                    APPLY FILTER
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* schedule a meeting tab */}
      <div
        className="modal fade"
        id="scheduleMeeting"
        role="dialog"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-slideout modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-bottom pr-5">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => (
                  setTimeValidation(false), setTimeValidationCurrentTime(false)
                )}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pl-5 pr-5 pt-2">
              <div className="popup-head mb-5 ml-3">
                <div className="main-heading">Schedule a Meeting</div>
                <div className="count-text">
                  {checkedArray.length} Incubators Selected
                </div>
              </div>

              <label className="col-sm-4 col-form-head">Meeting Type</label>
              <div className="col-md-12 mb-4">
                <div className="select-custom">
                  <select
                    className="form-control input-height"
                    aria-label="Select Entity Type"
                    onChange={handleOnChange}
                  >
                    {tableHeading == "Applications Selected" ? (
                      <option value="2">Performance Review Meeting</option>
                    ) : null}
                    {tableHeading == "Applications to be Reviewed" ? (
                      <option value="1">Incubator Selection</option>
                    ) : null}
                  </select>
                </div>
              </div>
              <label className="col-sm-4 col-form-head">Date & Time</label>
              <div className="col-md-6 mb-4">
                <Calendar
                  id="registrationDate"
                  name="registrationDate"
                  className="form-control input-height"
                  format="dd/MM/yyyy"
                  selectedDate={
                    selectedDateControl ? selectedDateControl : new Date()
                  }
                  onDateChange={(date: Date, field: string) =>
                    handleDateChange(date, field)
                  }
                  minDate={new Date()}
                />
              </div>
              <div className="row pl-3">
                <div className="col-md-6">
                  <label className="col-form-head">From Time</label>
                  <input
                    type="time"
                    value={fromTime}
                    name="fromTime"
                    className="form-control input-height"
                    onChange={onTimeChange}
                  />
                </div>
                <div className="col-md-6">
                  <label className="col-form-head">To Time</label>
                  <input
                    type="time"
                    value={toTime}
                    name="toTime"
                    className="form-control input-height"
                    onChange={onTimeChange}
                  />
                </div>
              </div>
              <div className="row text-left pl-4 mb-5">
                {timeValidation == true ? (
                  <label className="error-message">
                    From time greater than To time
                  </label>
                ) : null}
                {timeValidationCurrentTime == true ? (
                  <label className="error-message">
                    Meeting can't be scheduled for past time
                  </label>
                ) : null}
              </div>
              <label className="col-sm-4 col-form-head">Meeting Link</label>
              <div className="col-md-12 mb-4">
                <input
                  type="text"
                  id="meeting_link"
                  name="meeting_link"
                  className="form-control input-height"
                  onChange={(e: any) => setMeetingLink(e.target.value)}
                />
                <div className="row text-left pl-3">
                  {linkValidation == true ? (
                    <label className="error-message">
                      Meeting Link is required
                    </label>
                  ) : null}
                </div>
              </div>
              <label
                style={{
                  color: "#333333",
                  fontFamily: "Open Sans",
                  fontSize: "15px",
                  fontWeight: "600",
                  letterSpacing: "0.08px",
                  marginLeft: "12px",
                  marginTop: "1px",
                  paddingRight: "15px",
                }}
              >
                Meeting Comment
              </label>
              <div className="col-md-12 mb-4">
                <textarea
                  style={{ height: "100px" }}
                  id="meeting_link"
                  name="meeting_link"
                  className="form-control"
                  onChange={(e: any) => setMeetingComment(e.target.value)}
                />
                <div className="row text-left pl-3">
                  {linkValidation == true ? (
                    <label className="error-message">
                      Meeting Link is required
                    </label>
                  ) : null}
                </div>
              </div>
              <div className="row pl-3">
                <div className="col-md-6">
                  <button
                    className="btn btn-sisfs btn-transparent-primary full-width"
                    data-dismiss="modal"
                    onClick={() => (
                      setTimeValidation(false),
                      setTimeValidationCurrentTime(false),
                      setLinkValidation(false)
                    )}
                  >
                    CANCEL
                  </button>
                </div>
                <div className="col-md-6">
                  <button
                    className={
                      isDisabled
                        ? "tertiary  btn-yellow-primary full-width custom-disabled"
                        : "tertiary  btn-yellow-primary full-width"
                    }
                    disabled={isDisabled}
                    onClick={scheduleMeetingFunction}
                  >
                    SCHEDULE
                  </button>
                </div>
              </div>
              {/* {showIncubatorSuccessModal && (
                // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
                <>
                  <div className="modal-backdrop fade show"></div>

                  <div
                    className="modal align-middle modal-sisfs d-block"
                    id="FormSubmissionSuccess"
                  >
                    <div className="modal-dialog modal-dialog-centered modal-md">
                      <div className="modal-content">
                        <div className="modal-body text-center">
                          <i className="fa fa-check-circle-o"></i>
                          <h3 className="modal-message">Thank You!</h3>
                          <div className="modal-text">
                            Your Meeting Has been Scheduled
                                </div>
                          <div className="modal-action">
                            <button className="btn btn-sisfs btn-yellow-primary" onClick={dismissModal}>
                              Ok!!
                                </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )} */}
              {/* {showIncubatorFailureModal && (
                // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
                <>
                  <div className="modal-backdrop fade show"></div>
                  <div
                    className="modal align-middle modal-sisfs d-block"
                    id="FormSubmissionError"
                  >
                    <div className="modal-dialog modal-dialog-centered modal-md">
                      <div className="modal-content">
                        <div className="modal-body text-center">
                          {/* <i className="fa fa-times-circle-o"></i> 
                          <h3 className="modal-message message-orange">
                            Oops! Something is Wrong
                              </h3><br />
                          <h3 className="modal-message message-orange">
                            Please Try Again!!!
                              </h3>
                          <div className="modal-action">
                            <button className="btn btn-sisfs btn-yellow-primary" onClick={dismissModal}>
                              OK!
                              </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )} */}
              {/* <div className="row pl-5">
                <button
                  className="btn btn-sisfs btn-transparent-primary"
                  data-dismiss="modal"
                >
                  CANCEL
                </button>
                <button
                  className="tertiary  btn-yellow-primary ml-3"
                  onClick={scheduleMeetingFunction}
                >
                  SCHEDULE
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* quick edit popup */}
      <div
        className="modal fade"
        id="quickEdit"
        role="dialog"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
       >
        <div
          className="modal-dialog modal-dialog-slideout modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-bottom pr-5">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setSelectFlag(true)}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pl-5 pr-5 pt-5">
              <div className="popup-head mb-5 ml-2">
                <div className="main-heading">
                  {quickEditPopupData.incubator_name}
                </div>
                <div className="count-text">
                  No:{" "}
                  {quickEditPopupData.incubator_application_no == null || ""
                    ? quickEditPopupData.application_id
                    : quickEditPopupData.incubator_application_no}
                </div>
              </div>

              <label className="col-sm-8 col-form-head">
                Total Grant Approved ({quickEditPopupData.no_of_tranches}{" "}
                Tranches)
              </label>
              {showEditTranche ? (
                <span
                  className="col-sm-4 text-left view-all"
                  data-dismiss="modal"
                  onClick={() =>
                    handleRowChange(quickEditPopupData.application_id)
                  }
                >
                  Edit Tranche
                </span>
              ) : null}
              <div className="row pl-3">
                <div className="col-md-11 mb-4">
                  <input
                    type="number"
                    id="total_approved_amt"
                    name="total_approved_amt"
                    className="form-control input-height"
                    value={quickEditPopupData.total_approved_amt}
                    onChange={handlePopupChange}
                    disabled={selectFlag}
                  />
                </div>
                {/* <div className="col-md-4 mb-4">
                  <span className="view-all">View</span>
                </div> */}
              </div>

              <label className="col-sm-8 col-form-head">No. of Tranches</label>
              <div className="row pl-3">
                <div className="col-md-11 mb-4">
                  <input
                    type="number"
                    aria-label="Select State"
                    id="no_of_tranches"
                    name="no_of_tranches"
                    className="form-control input-height"
                    value={quickEditPopupData.no_of_tranches}
                    onChange={handlePopupChange}
                    disabled={selectFlag}
                  />
                </div>
                {/* <div className="col-md-4 mb-4">
                  <span className="view-all" >Edit</span>
                </div> */}
              </div>

              <label className="col-sm-8 col-form-head">
                Application Status
              </label>
              <div className="row pl-3">
                <div className="col-md-11 mb-4">
                  <div className="select-custom">
                    <select
                      className="form-control input-height"
                      aria-label="Select State"
                      id="status_id"
                      name="status_id"
                      value={quickEditPopupData.application_status}
                      onChange={handlePopupChange}
                    >
                      {applicationStatus.map(({ label, value }) =>
                        label !== "Draft" ? (
                          <option key={label} value={value}>
                            {label}
                          </option>
                        ) : null
                      )}
                    </select>
                  </div>
                </div>
                {/* <div className="col-md-4 mb-4">
                  <span className="view-all" onClick={() => setSelectFlag(false)}>Edit</span>
                </div> */}
              </div>
              <label className="col-sm-8 col-form-head">
                Date Of Evaluation
              </label>
              <div className="row pl-3">
                <div className="col-md-11 mb-4">
                  <fieldset disabled={selectFlag}>
                    <Calendar
                      id="registrationDate"
                      name="registrationDate"
                      className="form-control input-height"
                      format="dd/MM/yyyy"
                      selectedDate={quickEditPopupData.date_of_evaluation}
                      onDateChange={(date: Date, field: string) =>
                        handlePopupDateChange(date, field)
                      }
                      minDate={new Date()}
                    />
                  </fieldset>
                </div>
                {/* <div className="col-md-3 text-left mb-4">
                  <span className="view-all">View Details</span>
                </div> */}
              </div>
              <label className="col-sm-8 col-form-head">Comments, if any</label>
              <div className="row pl-3">
                <div className="col-md-11 mb-4">
                  <textarea
                    value={quickEditPopupData.application_comments}
                    className="form-control textarea-height"
                    name="popup_comments"
                    onChange={handlePopupChange}
                  />
                </div>
              </div>
              <div className="row pl-3">
                <div className="col-md-6">
                  <button
                    className="btn btn-sisfs btn-transparent-primary full-width"
                    data-dismiss="modal"
                  >
                    CANCEL
                  </button>
                </div>
                <div className="col-md-6">
                  <button
                    className={
                      isDisabled
                        ? "tertiary  btn-yellow-primary full-width custom-disabled"
                        : "tertiary  btn-yellow-primary full-width"
                    }
                    disabled={isDisabled}
                    onClick={quickEditCall}
                  >
                    UPDATE
                  </button>
                </div>
              </div>

              {/* <div className="row pl-5">
                <button
                  className="btn btn-sisfs btn-transparent-primary"
                  data-dismiss="modal"
                >
                  CANCEL
                </button>
                <button
                  className="tertiary  btn-yellow-primary ml-3"
                  onClick={scheduleMeetingFunction}
                >
                  SCHEDULE
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {showIncubatorSuccessModal && (
        // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
        // <>
        //   <div className="modal-backdrop fade show"></div>

        //   <div
        //     className="modal align-middle modal-sisfs d-block"
        //     id="FormSubmissionSuccess"
        //   >
        //     <div className="modal-dialog modal-dialog-centered modal-md">
        //       <div className="modal-content">
        //         <div className="modal-body text-center">
        //           <i className="fa fa-check-circle-o"></i>
        //           <h3 className="modal-message">Thank You!</h3>
        //           <div className="modal-text">
        //             Your Meeting Has been Scheduled
        //                         </div>
        //           <div className="modal-action">
        //             <button className="btn btn-sisfs btn-yellow-primary" onClick={dismissModal}>
        //               Ok!!
        //                         </button>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // </>
        <ConfirmationDialog
          headerText={dialogData.header}
          bodyText={dialogData.text}
          onDialogClose={dismissModal}
        />
      )}
      {showIncubatorFailureModal && (
        // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
        // <>
        //   <div className="modal-backdrop fade show"></div>
        //   <div
        //     className="modal align-middle modal-sisfs d-block"
        //     id="FormSubmissionError"
        //   >
        //     <div className="modal-dialog modal-dialog-centered modal-md">
        //       <div className="modal-content">
        //         <div className="modal-body text-center">
        //         <img
        //       src={error}
        //       className="search mb-2"
        //       alt="search"

        //     />
        //           <h3 className="modal-message">
        //             {failDialogData.header}
        //                       </h3>
        //           <h3 className="modal-text">
        //           {failDialogData.text}
        //                       </h3>
        //           <div className="modal-action">
        //             <button className="btn btn-sisfs btn-yellow-primary" onClick={() => setShowIncubatorFailureModal(false)}>
        //               OK!
        //                       </button>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // </>
        <ConfirmationDialog
          headerText={failDialogData.header}
          bodyText={failDialogData.text}
          onDialogClose={() => setShowIncubatorFailureModal(false)}
          iconClass="test"
        />
      )}
      {showConfirmationBox && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionError"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  {/* <img
               src={error}
               className="search mb-2"
               alt="search"

             /> */}
                  <h3 className="modal-message mt-2">
                    {confirmationDialogData.header}
                  </h3>
                  <h3 className="modal-text">{confirmationDialogData.text}</h3>
                  <div className="modal-action mt-2">
                    <button
                      className="btn btn-sisfs btn-transparent-primary"
                      onClick={() => setShowConfirmationBox(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className={
                        isDisabled
                          ? "btn btn-sisfs btn-yellow-primary ml-2 custom-disabled"
                          : "btn btn-sisfs btn-yellow-primary ml-2"
                      }
                      disabled={isDisabled}
                      onClick={finalSchedule}
                    >
                      Schedule Anyway
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* edit success Modal */}
      {showEditSuccessModal && (
        // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
        <>
          <div className="modal-backdrop fade show"></div>

          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionSuccess"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <i className="fa fa-check-circle-o"></i>
                  <h3 className="modal-message">Thank You!</h3>
                  <div className="modal-text">
                    Application has been Updated Successfully
                  </div>
                  <div className="modal-action">
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={dismissModal}
                    >
                      Ok!!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* show report */}
      <Modal
        show={showReport}
        onHide={() => setShowReport(false)}
        dialogClassName="modal-90w modal-xl modal-dialog-scrollable table-bordered table-hover"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            Download Incubator Excel Report
          </Modal.Title>
          <button
            type="button"
            onClick={() => setShowReport(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <IncubatorReportComponent />
        </Modal.Body>
      </Modal>

      {/* edit fail modal */}
      {showEditFailureModal && (
        // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionError"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  {/* <i className="fa fa-times-circle-o"></i> */}
                  <h3 className="modal-message message-orange">
                    Oops! Something is Wrong
                  </h3>
                  <br />
                  <h3 className="modal-message message-orange">
                    Please Try Again!!!
                  </h3>
                  <div className="modal-action">
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={dismissModal}
                    >
                      OK!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default IncubatorListingContainer;
