import { FileUploadModel } from "../FileUploadModel";

export class IncubatorOthersModel {
  constructor(
    public action: string,
    public relavent_document_excel: Array<FileUploadModel>,
    public relavent_document_pitchdeck: Array<FileUploadModel>,
    public relavent_document_other: Array<FileUploadModel>,
    public team_members_cv: Array<FileUploadModel>,
    public upload_documents_incubation_support: boolean,
    public upload_documents_mentorship_provided: boolean,
    public upload_documents_composition_incubator: boolean,
    public upload_documents_events_organized: boolean,
    public self_declaration_upload: Array<FileUploadModel>,
  ) {
    this.action = action;
    this.relavent_document_excel = relavent_document_excel;
    this.relavent_document_pitchdeck = relavent_document_pitchdeck;
    this.relavent_document_other = relavent_document_other;
    this.team_members_cv = team_members_cv;
    this.upload_documents_incubation_support = upload_documents_incubation_support;
    this.upload_documents_mentorship_provided = upload_documents_mentorship_provided;
    this.upload_documents_composition_incubator = upload_documents_composition_incubator;
    this.upload_documents_events_organized = upload_documents_events_organized;
    this.self_declaration_upload = self_declaration_upload;
  }
}
