import React, { Fragment, useEffect, useState } from "react";
import filter from "../../../src/images/filter.svg";
import "../../../styles/component.css";
import { Link, useHistory } from "react-router-dom";
import { get, post } from "../../../effects/api/api.common";
import { Node_GET_PORTFOLIO, NODE_URL, GET_PORTFOLIO_LIST, GET_ALL_STATES, GET_ALL_SECTOR, CONTACT_STATE, CONTACT_CITY, GET_PORTFOLIO_LIST_DESC, CHECK_PORTFOLIO_ELIGIBILTY, INC_NAMES_DROPDOWN, POST_PORTFOLIODATA, GET_PORTFOLIO_DETAILS, REMOVE_IMAGE } from "../../../constants/api";
import { createFile, filterByValue, filterByValue1 } from "../../../utils/CommonMethods";
import search from "../../../../src/images/search.svg"
import MultiSelect from "../../common/controls/MultiSelect";
import { DropdownModel } from "../../../models/DropdownModel";
import FileUploader from "@amodv/react-file-uploader";
import { PortfolioDetailsModel } from "../../../models/incubator/PortfolioDetailsModel";
import { UploadTypeEnum4 } from "../../../utils/Enums";
import { FileUploadModel } from "../../../models/FileUploadModel";
import { getSessionStorageValue } from "../../../effects/utils/session-storage";
import portolioDetail from "../../portfolio/dist/portolioDetail";
import { Console } from "console";
import Incubator from "../../faq/incubator";
import {
    FileUploadModelTranche,
} from '../../../models/funddetails/TrancheDetails';
import PreviewPortfolio from "../../dpiit/PreviewPortfolio";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
interface IIKeyValueIndexer {
    [key: string]: boolean;
}
const MyPortfolio = () => {
    const [data, setData] = useState<any[]>([]);
    const [dataPortfolio, setDataPortfolio] = useState<any>({})
    const [portfolioList, setPortfolioList] = useState([]);
    const [openUploadBlock, setOpenUploadBlock] = useState<IIKeyValueIndexer>();
    const [portfolioDetails, setPortfolioDetails] = useState<PortfolioDetailsModel>(
        new PortfolioDetailsModel(
            "",
            "",
            "",
            "",
            [],
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            [],
            [],
            [],
            "",
            "",
            "",
            false,
            false,
            false,
            false,
            ""
        ))
    const [uploadLogo, setUploadLogo] = useState<Array<File>>([]);
    const [uploadcover, setUploadCover] = useState<Array<File>>([]);
    const [uploadGallery, setUploadGallery] = useState<Array<File>>([]);
    const [des, setDes] = useState("");
    const [countryStates, setCountryStates] = useState([]);
    const [sectorDetails, setSectorDetails] = useState([]);
    const [selectedStateValue, setStateValue] = useState<Array<any>>([]);
    const [selectedSectorValue, setSectorValue] = useState<Array<any>>([]);
    const [stateDistricts, setStateDistricts] = useState([]);
    const [showOthers, setShowOthers] = useState(false);
    const [showRejectedText, setShowRejectedText] = useState(false);
    const [showMandatory, setShowMandatory] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [shoSubmitModal, setShowsubmitModal] = useState(false);
    const [loader, setLoader] = useState(false);
    const [allowCreation, setAllowCreation] = useState(false);
    const [maxUploads, setMaxUploads] = useState(false);
    const [incubatorId, setIncubatorId] = useState('')
    const [portfolioData, setportfolioData] = useState({
        incubator_name: '',
        site_url: '',
        logo: '',
        gallery: '',
        cover_img: '',
        location_state: '',
        location_city: '',
        sectors: '',
        message: '',
        key_features: '',
        incubator_email: ''
    });

    const [validationFlag, setValidationFlag] = useState({
        incubator_name: false,
        site_url: false,
        logo: false,
        gallery: false,
        cover_img: false,
        location_state: false,
        location_city: false,
        sectors: false,
        message: false,
        key_features: false,
        incubator_email: false

    });
    const [getIncubatorName, setIncubatorName] = useState()
    const [getRegName, setRegName] = useState()
    const [getURL, setURL] = useState()
    const [getDropdownData, setDropdownData] = useState<any>({
        incubator_name: '',
        registered_name: '',
        website_url: ''
    })
    const fileTypes = ["JPG", "PNG", "JPEG"];
    useEffect(() => {
        setPortfolioDetails(portfolioDetails);
    }, [portfolioDetails, portfolioDetails.gallery_pictures]);
    useEffect(() => {
    }, [selectedStateValue, selectedSectorValue, allowCreation, maxUploads])
    useEffect(() => { }, [showMandatory, showRejectedText])
    useEffect(() => {
        // getPortfolio();
        getAllStates();
        getAllSectors();
        getIncubatorNames();
        checkEligibilty();
        getDetailsOfPortfolio();

    }, []);
    const [showMore, setShowMore] = useState(false)
    const getPortfolio = () => {
        // console.log("Hellooooo")
        get(GET_PORTFOLIO_LIST).then((response: any) => {
            if (!response || !response.data) return;
            if (response?.data?.result === "success") {
                const APIData = response.data.data
                const sectors: any = []
                APIData.forEach((element: any) => {
                    const Sectorsarray = element.sectors.split(',');
                    sectors.push({ "Id": element.Id, "sectors": Sectorsarray, "state": element.state_id, "city": element.city, "image": element.image, "incubator_name": element.incubator_name, "description": element.description })
                });
                // console.log(sectors)
                setData(sectors);
            } else {
                setData([]);
            }
        });
    };
    const onMultiSelectChange = (selectedIds: Array<string>, name: string) => {
        if (name == "state") {
            setStateValue(selectedIds);
            getAllDistrictsByStateId(selectedIds);
            // setportfolioData((values) => ({
            //     ...values,
            //     location_state: value,
            // }));
        } else if (name == "sector") {

            setSectorValue(selectedIds);
            if (selectedSectorValue.length > 0) {
                setValidationFlag((values) => ({
                    ...values,
                    sectors: false,
                }));
            }
        }
    };

    const getDetailsOfPortfolio = () => {
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user)
        setLoader(true);
        post(GET_PORTFOLIO_DETAILS, { "logged_user_id": userInfo.id }).then((response: any) => {
            setLoader(false);
            const keyFeaturesList = response.data.data[0].key_features.split("','");
            const imagePathString = response.data.data[0].image_data.slice(0);
            let image_data: any = [];
            if (imagePathString.length > 0) {
                image_data = imagePathString.split(",");
            }
            // const image_data1 = 
            // const image_data = image_data1[image_data1.length - 1].replace(',', '');
            setIncubatorId(response.data.data[0].incubator_user_id)
            setPortfolioDetails({
                incubator_name: response.data.data[0].incubator_name,
                site_url: response.data.data[0].site_url,
                location_state: response.data.data[0].state_id,
                location_city: response.data.data[0].city,
                sectors: JSON.parse(response.data.data[0].sectors),
                message: response.data.data[0].description,
                key_features: keyFeaturesList[0] != undefined ? keyFeaturesList[0].replace(/'/g, '') : "",
                key_features1: keyFeaturesList[1] != undefined ? keyFeaturesList[1].replace(/'/g, '') : "",
                key_features2: keyFeaturesList[2] != undefined ? keyFeaturesList[2].replace(/'/g, '') : "",
                key_features3: keyFeaturesList[3] != undefined ? keyFeaturesList[3].replace(/'/g, '') : "",
                key_features4: keyFeaturesList[4] != undefined ? keyFeaturesList[4].replace(/'/g, '') : "",
                incubator_email: response.data.data[0].email_id,
                logo_image: response.data.data[0].logo,
                cover_image: response.data.data[0].image.replace(/\s/g, ""),
                gallery_pictures: image_data,
                remaining_pictures: response.data.data[0].image_data,
                remaining_logo: response.data.data[0].logo,
                remaining_banner: response.data.data[0].image,
                flag: false,
                flag1: false,
                flag2: false,
                flag3: false,
                Id: response.data.data[0].Id
            });

            if (
                response.data.data[0].status_ind == '2' &&
                (response.data.data[0].approve_in == '0' || response.data.data[0].approve_in == '1')
            ) {
                setAllowCreation(true)
            }
            else if (
                response.data.data[0].status_ind == '2' && response.data.data[0].approve_in == '2'
            ) {
                setAllowCreation(false)
                setShowRejectedText(true)
                setShowMandatory(true)
            }
            else if (
                response.data.data[0].status_ind == '2' && (response.data.data[0].approve_in == '1' || response.data.data[0].approve_in == '0')

            ) {
                setShowMandatory(false)
            }
            else if (
                response.data.data[0].status_ind == '2' &&
                (response.data.data[0].approve_in == '0')
            ) {
                setShowRejectedText(false)
            }
            else {
                setAllowCreation(false)
            }
            setSectorValue(JSON.parse(response.data.data[0].sectors))
            getAllDistrictsByStateId(response.data.data[0].state_id)
            const sectors1: any = []
            const Sectorsarray = response.data.data[0].sectorsnames != null ? response.data.data[0].sectorsnames.split(',') : "";
            const imagePathString1 = response.data.data[0].image_data.replace(/,$/, '');
            const ImagesArray = imagePathString1.split(',');
            const KeyFeatresArray = response.data.data[0].key_features
            const resultArray = KeyFeatresArray.split(/,\s*'|'\s*,/)
                .filter((item: any) => item !== "'" && item !== "''")
                .map((item: any) => item.replace(/'/g, ''));
            //  console.log(resultArray);
            sectors1.push({ "Id": response.data.data[0].Id, "sectors": Sectorsarray, "state": response.data.data[0].state, "city": response.data.data[0].cityname, "image": response.data.data[0].image, "incubator_name": response.data.data[0].incubator_name, "description": response.data.data[0].description, "image_data": ImagesArray, "key_features": resultArray, "logo": response.data.data[0].logo, "site_url": response.data.data[0].site_url })

            // console.log(sectors1)
            setDataPortfolio(sectors1[0])

        })
    }
    const getIncubatorNames = () => {
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user)
        post(INC_NAMES_DROPDOWN, { "logged_user_id": userInfo.id }).then((res: any) => {
            setDropdownData({
                incubator_name: res.data.data.incubator_name,
                registered_name: res.data.data.registered_name,
                website_url: res.data.data.website_url
            })
        })

    }

    const checkEligibilty = () => {
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user)
        post(CHECK_PORTFOLIO_ELIGIBILTY, { "logged_user_id": userInfo.id }).then((response: any) => {
            if (response.data.allow == "true") {
                setAllowCreation(false);
            } else {
                setAllowCreation(true);
            }
        });
    };

    const getAllStates = () => {
        get(CONTACT_STATE).then((response: any) => {
            // if (!response || !response.data) return;    
            // const states = response.data.data.map((item: any): DropdownModel => {
            //     return {
            //         text: item.name,
            //         id: item.id.toString(),
            //         translation_text: "",
            //     };
            // });

            if (!response || !response.data) return;

            const states = response.data.data.map((item: any) => ({
                label: item.name,
                value: item.id,
            }));
            setCountryStates(states);
        });
    };
    const getAllDistrictsByStateId = (stateId: any) => {
        get(`${CONTACT_CITY}/${stateId}`).then((response: any) => {
            if (!response || !response?.data?.data) {
                setStateDistricts([]);
                return;
            }

            const districts = response?.data?.data.map((item: any) => ({
                label: item?.name,
                value: item?.id,
            }));
            setStateDistricts(districts);
        });
    };
    // Logo Code Starts
    const [file, setFile] = useState(null);
    const [logoFileName, setlogoFileName] = useState("");
    const logoFilehandleChange = (file: any) => {
        // console.log(file)
        setlogoFileName(file.name)
        setFile(file);
    };
    // Cover Image Code Starts
    const [filess, setFiless] = useState(null);
    const [coverFileName, setcoverFileName] = useState("");
    const coverFilehandleChange = (filess: any) => {
        // console.log(filess)
        setcoverFileName(filess.name)
        setFiless(filess);
    };
    // Gallery Code Starts
    const [gallery, setgallery] = useState([]);
    const [galleryName, setgalleryName] = useState([]);
    const getFileDetails = (file: any, key: string) => {
        let file_name = '';
        file_name =
            file != undefined && file != null && file.length > 1
                ? file.substr(file.lastIndexOf('/') + 1, file.length)
                : '';

        const file_type = file != undefined && file != null && file.length > 1
            ? file.substr(
                file.lastIndexOf('.') + 1,
                file.length
            ) : '';

        if (key == 'name') return file_name;
        if (key == 'file') return file != undefined ? file.toString() : '';
        if (key == 'type') return file != undefined ? file_type.toString() : '';
    };
    const onFileUpload = (files: Array<File>, type: string) => {
        // console.log(files, "imageesss")
        // console.log("FILES!!!!!!!!!", files.length)
        // console.log("UPLOADED FILES!!!!!!!!!", portfolioDetails.gallery_pictures.length)

        // console.log(files.length + portfolioDetails.gallery_pictures.length)

        if ((files.length + portfolioDetails.gallery_pictures.length) > 10) {
            setMaxUploads(true);
            setUploadGallery([]);
            return;
        }

        // if (files.length > 10 || portfolioDetails.gallery_pictures.length + files.length > 10) {
        //     // console.log(files.length, "iffffffffffffff", "imageesss")
        //     // console.log(maxUploads, "iffffffffffffff", "imageesss")
        //     // console.log(uploadGallery, "iffffffffffffff", "imageesss")
        //     setMaxUploads(true)
        //     return;
        // }
        // else if (portfolioDetails.gallery_pictures.length + files.length > 10) {
        //     console.log(portfolioDetails.gallery_pictures.length + files.length, "elseiffffffff", "imageesss")
        //     setMaxUploads(true)
        //     return;
        // }
        else {
            // console.log("elseeeeeee", "imageesss")
            setMaxUploads(false)
            let indexes: Array<string> = [];
            for (const file of files) {
                // console.log(file)
                // console.log(files,"imageesss")
                switch (type) {
                    case "logo_image":
                        setUploadLogo([files[files.length - 1]]);
                        // console.log(uploadLogo)
                        break;
                    case "cover_image":
                        setUploadCover([files[files.length - 1]])
                        break;
                    case "gallery_image":
                        setUploadGallery(prevGallery => {
                            const uniqueFiles = files.filter(newFile =>
                                !prevGallery.some(existingFile => existingFile.name === newFile.name)
                            );
                            return [...prevGallery, ...uniqueFiles];
                        });
                        break;
                    default:
                        break;
                }

            }
            if (indexes.length) {
                indexes.map((ind) => {
                    const position: number = files.findIndex((file) => file.name === ind);
                    files.splice(position, 1);
                });
            }
        }

    };
    const onFileDelete = (type: string, deletedFile: File) => {
        switch (type) {
            case "logo_image":
                // setUploadLogo([]);
                setUploadLogo(prevGallery =>
                    prevGallery.filter(file => file.name !== deletedFile.name)
                );
                break;
            case "cover_image":
                // setUploadCover([])
                setUploadCover(prevGallery =>
                    prevGallery.filter(file => file.name !== deletedFile.name)
                );
                break;
            case "gallery_image":
                // setUploadGallery([])

                setUploadGallery(prevGallery =>
                    prevGallery.filter(file => file.name !== deletedFile.name)
                );
                break;
        };
    };
    const GalleryFilehandleChange = (gallery: any) => {
        setgallery(files => files.concat(...gallery));
    };

    // get all sectors in dropdown
    const getAllSectors = () => {
        // const param = sectorDetails.length == 0 ? '0' : sectorDetails
        get(`${GET_ALL_SECTOR}/0`).then((response: any) => {
            if (!response || !response.data) return;
            const sectors = response.data.data.map((item: any): DropdownModel => {
                return {
                    text: item.attribute_value,
                    id: item.id.toString(),
                    translation_text: "",
                };
            });
            setSectorDetails(sectors);
        });
    };
    const [show, setShow] = useState(false)
    const searchList = (e: any) => {

        const filteredData = filterByValue1(data, e.target.value);

        setShow(true)
        setPortfolioList(filteredData);
    }
    const [backspacePressed, setBackspacePressed] = useState(false);
    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Backspace') {
            setBackspacePressed(true);
        } else {
            setBackspacePressed(false);
        }
    };
    const resetAll = () => {
        // return
        setPortfolioDetails({
            incubator_name: '',
            site_url: '',
            location_state: '',
            location_city: '',
            sectors: '',
            message: '',
            key_features: '',
            key_features1: '',
            key_features2: '',
            key_features3: '',
            key_features4: '',
            incubator_email: '',
            logo_image: [],
            cover_image: [],
            gallery_pictures: [],
            remaining_pictures: "",
            remaining_logo: '',
            remaining_banner: '',
            flag: false,
            flag1: false,
            flag2: false,
            flag3: false,
            Id: '',
        });
        setSectorValue([]);
        setUploadLogo([]);
        setUploadCover([]);
        setUploadGallery([]);
        setMaxUploads(false)
        setValidationFlag({
            incubator_name: false,
            site_url: false,
            logo: false,
            gallery: false,
            cover_img: false,
            location_state: false,
            location_city: false,
            sectors: false,
            message: false,
            key_features: false,
            incubator_email: false

        })
        // portfolioDetails.gallery_pictures.map((item: any) => {
        //     removeImage(item);
        // })
        // if (portfolioDetails.cover_image.length > 0) {
        //     removeImage(portfolioDetails.cover_image);
        // }
        // if (portfolioDetails.logo_image.length > 0) {
        //     removeImage(portfolioDetails.logo_image);
        // }
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user)
        const formData = new FormData();
        formData.append("logged_user_id", userInfo.id);
        formData.append("incubator_name", "");
        formData.append("site_url", "");
        formData.append("state_id", "0");
        formData.append("city_id", "0");
        formData.append("sectors_id", "");
        formData.append("description", "");
        formData.append("key_features", "");
        formData.append("email_id", "");
        formData.append("logo", "");
        formData.append("image", "");
        formData.append("reamaining_Images", "");
        formData.append("reamaining_logo", "");
        formData.append("reamaining_banner", "");
        formData.append("image_data", "");
        formData.append("status_ind", "1")
        // return
        setLoader(true);
        post(POST_PORTFOLIODATA, formData).then((response: any) => {
            // console.log(response);
            if (response.data.result == 'success') {
                setLoader(false);
                getDetailsOfPortfolio();
            }
        });
    };
    const saveForm = () => {
        // return
        // const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        // const CheckEmail = emailRegex.test(portfolioDetails.incubator_email)
        // // 
        // if (portfolioDetails.incubator_email == "" || CheckEmail == false) {
        //     setValidationFlag((values) => ({
        //         ...values,
        //         incubator_email: true,
        //     }));
        //     return;
        // }
        // else {
        //     setValidationFlag((values) => ({
        //         ...values,
        //         incubator_email: false,
        //     }));
        // }
        // return
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user)
        const formData = new FormData();
        formData.append("logged_user_id", userInfo.id);
        formData.append("incubator_name", portfolioDetails.flag === false ? getDropdownData.incubator_name : portfolioDetails.incubator_name);
        formData.append("site_url", portfolioDetails.flag1 === false ? getDropdownData.website_url : portfolioDetails.site_url);

        // console.log(portfolioDetails)

        formData.append("state_id", (portfolioDetails.location_state != 0 && portfolioDetails.location_state != null) ? portfolioDetails.location_state : portfolioDetails.flag2 === false ? '0' : portfolioDetails.location_state);


        formData.append("city_id", (portfolioDetails.location_city != 0 && portfolioDetails.location_city != null) ? portfolioDetails.location_city : portfolioDetails.flag3 === false ? '0' : portfolioDetails.location_city);

        formData.append("sectors_id", selectedSectorValue == null ? "" : JSON.stringify(selectedSectorValue));
        formData.append("description", portfolioDetails.message);
        let keysToCombine: any;
        let keysToCombine1: any = [];
        const data = {
            'key': portfolioDetails.key_features,
            'key1': portfolioDetails.key_features1,
            'key2': portfolioDetails.key_features2,
            'key3': portfolioDetails.key_features3,
            'key4': portfolioDetails.key_features4,
        }

        for (let [key, value] of Object.entries(data)) {
            if (value !== '') {
                keysToCombine1.push(value)
            }
        }
        // console.log(keysToCombine1)
        // keysToCombine = [portfolioDetails.key_features, portfolioDetails.key_features1, portfolioDetails.key_features2,
        // portfolioDetails.key_features3, portfolioDetails.key_features4]
        const keyFeatures = keysToCombine1.map((feature: any) => `'${feature}'`).join(',');
        // console.log(keyFeatures.replace(/(,'')+$/g, '').replace(/(,'')+,/g, ','))
        //   return
        formData.append("key_features", keyFeatures);
        formData.append("email_id", portfolioDetails.incubator_email);
        formData.append("logo", uploadLogo[0] != undefined ? uploadLogo[0] : "");
        formData.append("image", uploadcover[0] != undefined ? uploadcover[0] : "");
        formData.append("reamaining_Images", portfolioDetails.remaining_pictures);
        formData.append("reamaining_logo", portfolioDetails.remaining_logo);
        formData.append("reamaining_banner", portfolioDetails.remaining_banner);
        uploadGallery.forEach((file, index) => {
            formData.append("image_data", file);
        });
        formData.append("status_ind", "1")
        // return
        setLoader(true);
        post(POST_PORTFOLIODATA, formData).then((response: any) => {
            // console.log(response);
            if (response.data.result == 'success') {
                setLoader(false);
                getDetailsOfPortfolio();
                setUploadGallery([])
                setUploadCover([])
                setUploadLogo([])
                setShowSuccessModal(true);
            }
        });
        // console.log(formData);
    };

    const submitForm = () => {
        // console.log(portfolioDetails)
        if (portfolioDetails.flag === true) {
            if (portfolioDetails.incubator_name == "") {
                setValidationFlag((values) => ({
                    ...values,
                    incubator_name: true,
                }));
                return;
            } else {
                setValidationFlag((values) => ({
                    ...values,
                    incubator_name: false,
                }));
            }
        }
        // console.log(portfolioDetails.location_state != 0 ? 'mohan' : 'lanke', "stateuuuu")
        // return;
        if (portfolioDetails.location_state == "" || portfolioDetails.location_state == 0) {
            setValidationFlag((values) => ({
                ...values,
                location_state: true,
            }));
            return;
        }
        else {
            setValidationFlag((values) => ({
                ...values,
                location_state: false,
            }));
        }
        if (portfolioDetails.location_city == "" || portfolioDetails.location_city == 0) {
            setValidationFlag((values) => ({
                ...values,
                location_city: true,
            }));
            return;
        }
        else {
            setValidationFlag((values) => ({
                ...values,
                location_city: false,
            }));
        }
        if (selectedSectorValue.length == 0) {
            setValidationFlag((values) => ({
                ...values,
                sectors: true,
            }));
            return;
        }
        else {
            setValidationFlag((values) => ({
                ...values,
                sectors: false,
            }));
        }
        if (portfolioDetails.flag === true) {
            if (portfolioDetails.site_url == "") {
                setValidationFlag((values) => ({
                    ...values,
                    site_url: true,
                }));
                return;
            }
            else {
                setValidationFlag((values) => ({
                    ...values,
                    site_url: false,
                }));
            }
        }
        if (portfolioDetails.message == "") {
            setValidationFlag((values) => ({
                ...values,
                message: true,
            }));
            return;
        }
        else {
            setValidationFlag((values) => ({
                ...values,
                message: false,
            }));
        }
        if (portfolioDetails.remaining_logo.length == 0 && uploadLogo.length == 0) {
            setValidationFlag((values) => ({
                ...values,
                logo: true,
            }));
            return;
        }
        else {
            setValidationFlag((values) => ({
                ...values,
                logo: false,
            }));
        }
        if (portfolioDetails.remaining_banner.length == 0 && uploadcover.length == 0) {
            setValidationFlag((values) => ({
                ...values,
                cover_img: true,
            }));
            return;
        }
        else {
            setValidationFlag((values) => ({
                ...values,
                cover_img: false,
            }));
        }
        if (portfolioDetails.remaining_pictures.length == 0 && uploadGallery.length == 0) {
            setValidationFlag((values) => ({
                ...values,
                gallery: true,
            }));
            return;
        }
        else {
            setValidationFlag((values) => ({
                ...values,
                gallery: false,
            }));
        }
        if (portfolioDetails.key_features == "" &&
            portfolioDetails.key_features1 == "" &&
            portfolioDetails.key_features2 == "" &&
            portfolioDetails.key_features3 == "" &&
            portfolioDetails.key_features4 == "") {
            setValidationFlag((values) => ({
                ...values,
                key_features: true,
            }));
            return;
        }
        else {
            setValidationFlag((values) => ({
                ...values,
                key_features: false,
            }));
        }
        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        const CheckEmail = emailRegex.test(portfolioDetails.incubator_email)
        // 
        if (portfolioDetails.incubator_email == "" || CheckEmail == false) {
            setValidationFlag((values) => ({
                ...values,
                incubator_email: true,
            }));
            return;
        }
        else {
            setValidationFlag((values) => ({
                ...values,
                incubator_email: false,
            }));
        }
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user)
        const formData = new FormData();
        formData.append("logged_user_id", userInfo.id);
        formData.append("incubator_name", portfolioDetails.flag === false ? getDropdownData.incubator_name : portfolioDetails.incubator_name);
        formData.append("site_url", portfolioDetails.flag1 === false ? getDropdownData.website_url : portfolioDetails.site_url);
        formData.append("state_id", portfolioDetails.location_state != 0 ? portfolioDetails.location_state : portfolioDetails.flag2 === false ? '0' : portfolioDetails.location_state);
        formData.append("city_id", portfolioDetails.location_city != 0 ? portfolioDetails.location_city : portfolioDetails.flag3 === false ? '0' : portfolioDetails.location_city);
        formData.append("sectors_id", selectedSectorValue == null ? "" : JSON.stringify(selectedSectorValue));
        formData.append("description", portfolioDetails.message);
        let keysToCombine: any;
        let keysToCombine1: any = [];
        const data = {
            'key': portfolioDetails.key_features,
            'key1': portfolioDetails.key_features1,
            'key2': portfolioDetails.key_features2,
            'key3': portfolioDetails.key_features3,
            'key4': portfolioDetails.key_features4,
        }

        for (let [key, value] of Object.entries(data)) {
            if (value !== '') {
                keysToCombine1.push(value)
            }
        }
        // console.log(keysToCombine1)
        // keysToCombine = [portfolioDetails.key_features, portfolioDetails.key_features1, portfolioDetails.key_features2,
        // portfolioDetails.key_features3, portfolioDetails.key_features4]
        const keyFeatures = keysToCombine1.map((feature: any) => `'${feature}'`).join(',');
        // console.log(keyFeatures.replace(/(,'')+$/g, '').replace(/(,'')+,/g, ','))
        //   return
        formData.append("key_features", keyFeatures);
        formData.append("email_id", portfolioDetails.incubator_email);
        formData.append("logo", uploadLogo[0] != undefined ? uploadLogo[0] : "");
        formData.append("image", uploadcover[0] != undefined ? uploadcover[0] : "");
        formData.append("reamaining_Images", portfolioDetails.remaining_pictures);
        formData.append("reamaining_logo", portfolioDetails.remaining_logo);
        formData.append("reamaining_banner", portfolioDetails.remaining_banner);
        uploadGallery.forEach((file, index) => {
            formData.append("image_data", file);
        });
        formData.append("status_ind", "2")
        // return
        setLoader(true);
        post(POST_PORTFOLIODATA, formData).then((response: any) => {
            // console.log(response);
            if (response.data.result == 'success') {
                setLoader(false);
                getDetailsOfPortfolio();
                setUploadGallery([]);
                setUploadCover([]);
                setUploadLogo([]);
                setShowsubmitModal(true);
            }
        });
        // console.log(formData);
    }
    const history = useHistory();
    const previewPage = () => {
        // console.log(incubatorId)
        history.push('/preview_portfolio_page');
        localStorage.setItem("incubatorId", incubatorId)
    }
    const closeModal = () => {
        setShowSuccessModal(false);
        setShowsubmitModal(false);
        window.location.reload();
    }
    const removeImage = (filePath: any) => {
        var payload = {
            "file_path": filePath,
            "portfolio_id": portfolioDetails.Id
        }
        post(REMOVE_IMAGE, payload).then((response: any) => {
            if (response.data.result == 'success') {

                // console.log(response.data)
            }
        });
        console.log(filePath, "filepathhhh")
        console.log(portfolioDetails.remaining_pictures, "filepathhhh")
        let filteredPaths = portfolioDetails.remaining_pictures.split(",").filter((item) => item != filePath)
        console.log(filteredPaths, "filepathhhh")
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user)
        const formData = new FormData();
        formData.append("logged_user_id", userInfo.id);
        formData.append("incubator_name", portfolioDetails.flag === false ? getDropdownData.incubator_name : portfolioDetails.incubator_name);
        formData.append("site_url", portfolioDetails.flag1 === false ? getDropdownData.website_url : portfolioDetails.site_url);
        formData.append("state_id", portfolioDetails.location_state != 0 ? portfolioDetails.location_state : portfolioDetails.flag2 === false ? '0' : portfolioDetails.location_state);
        formData.append("city_id", portfolioDetails.location_city != 0 ? portfolioDetails.location_city : portfolioDetails.flag3 === false ? '0' : portfolioDetails.location_city);
        formData.append("sectors_id", selectedSectorValue == null ? "" : JSON.stringify(selectedSectorValue));
        formData.append("description", portfolioDetails.message);
        let keysToCombine: any;
        let keysToCombine1: any = [];
        const data = {
            'key': portfolioDetails.key_features,
            'key1': portfolioDetails.key_features1,
            'key2': portfolioDetails.key_features2,
            'key3': portfolioDetails.key_features3,
            'key4': portfolioDetails.key_features4,
        }

        for (let [key, value] of Object.entries(data)) {
            if (value !== '') {
                keysToCombine1.push(value)
            }
        }
        // console.log(keysToCombine1)
        // keysToCombine = [portfolioDetails.key_features, portfolioDetails.key_features1, portfolioDetails.key_features2,
        // portfolioDetails.key_features3, portfolioDetails.key_features4]
        const keyFeatures = keysToCombine1.map((feature: any) => `'${feature}'`).join(',');
        // console.log(keyFeatures.replace(/(,'')+$/g, '').replace(/(,'')+,/g, ','))
        //   return
        formData.append("key_features", keyFeatures);
        formData.append("email_id", portfolioDetails.incubator_email);
        formData.append("logo", uploadLogo[0] != undefined ? uploadLogo[0] : "");
        formData.append("image", uploadcover[0] != undefined ? uploadcover[0] : "");
        formData.append("reamaining_Images", filteredPaths.toString());
        formData.append("reamaining_logo", portfolioDetails.remaining_logo);
        formData.append("reamaining_banner", portfolioDetails.remaining_banner);
        uploadGallery.forEach((file, index) => {
            formData.append("image_data", file);
        });
        formData.append("status_ind", "1")
        // return
        // setTimeout(() => {
        post(POST_PORTFOLIODATA, formData).then((response: any) => {
            // console.log(response);
            if (response.data.result == 'success') {

                setUploadGallery([])
                setUploadCover([])
                setUploadLogo([])
            }
        });
        // }, 1500)
        setLoader(true);
        setTimeout(() => {
            getDetailsOfPortfolio();
            setLoader(false)
        }, 1000);

    }

    const handleChange = (
        evt: React.ChangeEvent<
            HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
        >
    ) => {
        const name = evt.target.name;
        const value = evt.target.value;
        // console.log(name);
        // console.log(portfolioDetails);
        // getAllDistrictsByStateId(value);   
        if (name == "stateId") {
            getAllDistrictsByStateId(value);
            // console.log(value);
            setPortfolioDetails((values) => ({
                ...values,
                location_state: value,
                flag2: true
            }));
        }
        else if (name == "incubator_name") {
            setPortfolioDetails((values) => ({
                ...values,
                incubator_name: value,
                flag: true
            }));
            // console.log(portfolioDetails, "portfomm")
        }
        else if (name == "incubator_email") {
            setPortfolioDetails((values) => ({
                ...values,
                incubator_email: value
            }));
            const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
            const CheckEmail = emailRegex.test(portfolioDetails.incubator_email)

            // if (portfolioDetails.incubator_email == "" || CheckEmail == false) {
            //     setValidationFlag((values) => ({
            //         ...values,
            //         incubator_email: true,
            //     }));
            //     return;
            // }
            // else {
            //     setValidationFlag((values) => ({
            //         ...values,
            //         incubator_email: false,
            //     }));
            // }
        }
        else if (name == "cover_img") {
            setPortfolioDetails((values) => ({
                ...values,
                cover_img: value
            }));


        }
        else if (name == "site_url") {
            setPortfolioDetails((values) => ({
                ...values,
                site_url: value,
                flag1: true
            }));

        }
        else if (name == 'logo') {
            setPortfolioDetails((values) => ({
                ...values,
                logo: value,
            }));

        }
        else if (name == 'gallery') {
            setPortfolioDetails((values) => ({
                ...values,
                gallery: value,
            }));
        }
        else if (name == "cityId") {
            setPortfolioDetails((values) => ({
                ...values,
                location_city: value,
                flag3: true
            }));
        }
        else if (name == 'sectors') {
            setPortfolioDetails((values) => ({
                ...values,
                sectors: values,
            }));
        }
        else if (name == "key_features") {

            setPortfolioDetails(values => ({
                ...values,
                key_features: value
            }));
        }
        else if (name == "key_features1") {

            setPortfolioDetails(values => ({
                ...values,
                key_features1: value
            }));
        }
        else if (name == "key_features2") {

            setPortfolioDetails(values => ({
                ...values,
                key_features2: value
            }));
        }
        else if (name == "key_features3") {

            setPortfolioDetails(values => ({
                ...values,
                key_features3: value
            }));
        }
        else if (name == "key_features4") {

            setPortfolioDetails(values => ({
                ...values,
                key_features4: value
            }));
        }
        else if (name == 'message') {
            const words = value.split(/\s+/);
            if (words.length <= 150) {
                setPortfolioDetails((values) => ({
                    ...values,
                    message: value,
                }));
            }
        }
    }
    const handleValidate = (evt: React.ChangeEvent<
        HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
    ) => {
        const name = evt.target.name;
        const value = evt.target.value;

        // if (name == "stateId") {
        //     if (portfolioDetails.location_state == "") {
        //         setValidationFlag((values) => ({
        //             ...values,
        //             location_state: true,
        //         }));
        //     }
        //     else {
        //         setValidationFlag((values) => ({
        //             ...values,
        //             location_state: false,
        //         }));
        //     }
        // }
        // else if (name == "incubator_name") {
        //     if (portfolioDetails.incubator_name == "") {
        //         setValidationFlag((values) => ({
        //             ...values,
        //             incubator_name: true,
        //         }));
        //     }
        //     else {
        //         setValidationFlag((values) => ({
        //             ...values,
        //             incubator_name: false,
        //         }));
        //     }

        // }
        // else if (name == "incubator_email") {
        //     if (portfolioDetails.incubator_email == "") {
        //         setValidationFlag((values) => ({
        //             ...values,
        //             incubator_email: true,
        //         }));
        //     }
        //     else {
        //         setValidationFlag((values) => ({
        //             ...values,
        //             incubator_email: false,
        //         }));
        //     }

        // }
        // else if (name == "site_url") {
        //     if (portfolioDetails.site_url == "") {
        //         setValidationFlag((values) => ({
        //             ...values,
        //             site_url: true,
        //         }));
        //     }
        //     else {
        //         setValidationFlag((values) => ({
        //             ...values,
        //             site_url: false,
        //         }));
        //     }
        // }
        // else if (name == 'cover_img') {
        //     if (portfolioDetails.logo_image == "") {
        //         setValidationFlag((values) => ({
        //             ...values,
        //             logo: true,
        //         }));
        //     }
        //     else {
        //         setValidationFlag((values) => ({
        //             ...values,
        //             logo: false,
        //         }));
        //     }
        // }
        // else if (name == 'cover_img') {
        //     if (portfolioDetails.cover_image == "") {
        //         setValidationFlag((values) => ({
        //             ...values,
        //             cover_img: true,
        //         }));
        //     }
        //     else {
        //         setValidationFlag((values) => ({
        //             ...values,
        //             cover_img: false,
        //         }));
        //     }
        // }
        // else if (name == 'gallery') {
        //     if (portfolioDetails.gallery_pictures == "") {
        //         setValidationFlag((values) => ({
        //             ...values,
        //             gallery: true,
        //         }));
        //     }
        //     else {
        //         setValidationFlag((values) => ({
        //             ...values,
        //             gallery: false,
        //         }));
        //     }
        // }
        // else if (name == "cityId") {
        //     if (portfolioDetails.location_city == "") {
        //         setValidationFlag((values) => ({
        //             ...values,
        //             location_city: true,
        //         }));
        //     }
        //     else {
        //         setValidationFlag((values) => ({
        //             ...values,
        //             location_city: false,
        //         }));
        //     }
        // }
        // else if (name == 'sectors') {
        //     if (portfolioDetails.sectors == "") {
        //         setValidationFlag((values) => ({
        //             ...values,
        //             sectors: true,
        //         }));
        //     }
        //     else {
        //         setValidationFlag((values) => ({
        //             ...values,
        //             sectors: false,
        //         }));
        //     }
        // }
        // else if (name == 'message') {
        //     if (portfolioDetails.message == "") {
        //         setValidationFlag((values) => ({
        //             ...values,
        //             message: true,
        //         }));
        //     }
        //     else {
        //         setValidationFlag((values) => ({
        //             ...values,
        //             message: false,
        //         }));
        //     }
        // }
        // else if (name == 'key_features' || name == 'key_features1' || name == 'key_features2' || name == 'key_features3' || name == 'key_features4') {
        //     if (portfolioDetails.key_features == "" && portfolioDetails.key_features1 == "" &&
        //         portfolioDetails.key_features2 == "" && portfolioDetails.key_features3 == "" && portfolioDetails.key_features4 == ""
        //     ) {
        //         setValidationFlag((values) => ({
        //             ...values,
        //             key_features: true,
        //         }));
        //     }
        //     else {
        //         setValidationFlag((values) => ({
        //             ...values,
        //             key_features: false,
        //         }));
        //     }
        // }

    }
    return (
        <Fragment>
            <main role="main">
                {loader &&
                    <div className="spinner-border custom-spinner" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>}
                <fieldset disabled={loader}>
                    <div className="about-container portfolio-container">
                        <div className="container-fluid about-heading">
                            <div className="text-center main-heading">My Portfolio</div>
                        </div>
                    </div>

                    <div className="container inside-container">

                        <div className="app-inside-content app-inside-full">

                            <div className="row justify-content-center">
                                <div className="col-sm-10">

                                    <fieldset>
                                        {showMandatory == true ? <div className="text-center mb-1 mt-3">

                                            <span className="text-center text-danger" style={{ fontSize: '16px' }}><span><sup>*</sup></span> All fields are mandatory</span>
                                        </div> : ''}
                                        <div className="text-center">
                                            {showRejectedText == true ?
                                                <>
                                                    <span className="text-danger text-center" style={{ fontWeight: 'bolder' }}>
                                                        Your Portfolio Application has been Rejected. Try Submitting again.</span>
                                                </> : <> </>

                                            }
                                        </div>
                                        <br />
                                        <div className="form-wrapper contact-form">

                                            {/* <div className="form-group row">
                                            <label className="col-sm-12 col-md-4 col-form-label">
                                                Incubator Name
                                            </label>
                                            <div className="col-sm-12 col-md-8">
                                                <input type="text" onChange={handleChange} onBlur={handleValidate} name="incubator_name" value={portfolioData.incubator_name} className="form-control" placeholder="Enter" />
                                                {validationFlag.incubator_name == true ? <label className="error-message">Please Enter Incubator Name</label> : null}
                                            </div>
                                        </div> */}
                                            <fieldset disabled={allowCreation}>
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-md-4 col-form-label">
                                                        Incubator Name <span style={{ color: 'red' }}><sup>*</sup></span>
                                                    </label>
                                                    <div className="col-sm-12 col-md-8">
                                                        <div className="select-custom">
                                                            <select className="form-control" onBlur={handleValidate} onChange={handleChange} value={portfolioDetails.incubator_name} name="incubator_name" aria-label="Select Industry">
                                                                {/* <option value="" >Select</option> */}
                                                                {/* selected */}
                                                                <option value={getDropdownData.incubator_name}>{getDropdownData.incubator_name}</option>
                                                                <option value={getDropdownData.registered_name}>{getDropdownData.registered_name}</option>
                                                            </select>
                                                        </div>
                                                        {validationFlag.incubator_name == true ? <label className="error-message">Please Enter Incubator Name</label> : null}
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-md-4 col-form-label">
                                                        Location <span style={{ color: 'red' }}><sup>*</sup></span>
                                                    </label>
                                                    <div className="col-sm-12 col-md-8">
                                                        <div className="form-row">
                                                            <div className="col">
                                                                <div className="select-custom">
                                                                    <select
                                                                        className="form-control"
                                                                        aria-label="Select State"
                                                                        id="State"
                                                                        name="stateId"
                                                                        value={portfolioDetails.location_state}
                                                                        onChange={handleChange}
                                                                        onBlur={handleValidate}
                                                                    >
                                                                        <option value="">Select State</option>
                                                                        {countryStates.map(({ label, value }) => (
                                                                            <option key={label} value={value}>
                                                                                {label}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                {validationFlag.location_state == true ? <label className="error-message">Please select State</label> : null}
                                                            </div>

                                                            <div className="col">
                                                                <div className="select-custom">
                                                                    {/* {console.log("@@@", portfolioDetails.location_city)} */}
                                                                    <select
                                                                        className="form-control"
                                                                        aria-label="Select City"
                                                                        id="City"
                                                                        name="cityId"
                                                                        onChange={handleChange}
                                                                        onBlur={handleValidate}
                                                                        value={portfolioDetails.location_city}

                                                                    >
                                                                        <option value="">Select City</option>
                                                                        {stateDistricts.map(({ label, value }) => (
                                                                            <option key={value} value={value}>
                                                                                {label}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                {validationFlag.location_city == true ? <label className="error-message">Please select city</label> : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-md-4 col-form-label">
                                                        Sectors <span style={{ color: 'red' }}><sup>*</sup></span>
                                                    </label>
                                                    <div className="col-sm-12 col-md-8">
                                                        {/* <div className="select-custom"> */}
                                                        <MultiSelect
                                                            id="countryStates"
                                                            options={sectorDetails}
                                                            defaultText="Select Sectors"
                                                            onClose={(selectedIds: string[]) =>
                                                                onMultiSelectChange(selectedIds, "sector")
                                                            }
                                                            selectedValues={
                                                                selectedSectorValue ? selectedSectorValue : []
                                                            }
                                                            hasError
                                                            errorText=""
                                                            searchable
                                                        />
                                                        {/* <select className="form-control" onBlur={handleValidate} onChange={handleChange} value={portfolioData.sectors} name="sectors" aria-label="Select Industry">
                                                            <option value="" >Select</option>
                                                            
                                                            <option value="Login Issue">Login Issue</option>
                                                            <option value="Other Technical Issue">Other Technical Issue</option>
                                                            <option value="Eligibility Criteria">Eligibility Criteria</option>
                                                            <option value="Information about Application Process">Information about Application Process</option>
                                                            <option value="Information about Selection Process">Information about Selection Process</option>
                                                            <option value="Information about Seed Fund Offered">Information about Seed Fund Offered</option>
                                                            <option value="others">Others</option>
                                                        </select> */}
                                                        {/* </div> */}
                                                        {validationFlag.sectors == true ? <label className="error-message">Please select Sectors</label> : null}
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-md-4 col-form-label">
                                                        Website URL <span style={{ color: 'red' }}><sup>*</sup></span>
                                                    </label>
                                                    <div className="col-sm-12 col-md-8">
                                                        <input type="text" onChange={handleChange} onBlur={handleValidate} name="site_url" value={portfolioDetails.site_url == "" ? getDropdownData.website_url : portfolioDetails.site_url} className="form-control" placeholder="Enter" />
                                                        {validationFlag.site_url == true ? <label className="error-message">Please Enter Website Url</label> : null}
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-md-4 col-form-label">
                                                        Description <span style={{ color: 'red' }}><sup>*</sup></span>
                                                    </label>
                                                    <div className="col-sm-12 col-md-8">
                                                        <textarea className="form-control" data-announce="true" value={portfolioDetails.message} onBlur={handleValidate} onChange={handleChange} name="message" rows={3} placeholder="Enter"></textarea>
                                                        {validationFlag.message == true ? <label className="error-message">Please Enter Description</label> : null}
                                                        <small>Maximum words : 150 </small>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-md-4 col-form-label">
                                                        Logo <span style={{ color: 'red' }}><sup>*</sup></span>
                                                        <small style={{ color: 'gray' }}><i>(Size : 175px * 75px)</i></small>
                                                    </label>
                                                    <div className="col-sm-12 col-md-8">
                                                        <button
                                                            className="btn btn-sisfs btn-transparent-primary"
                                                            onClick={() => setOpenUploadBlock({ LogoImage: true })}
                                                        >
                                                            Upload
                                                        </button>&nbsp;&nbsp;
                                                        <span className="fileToolTip">
                                                            {

                                                                <button
                                                                    className="button tool-tip"
                                                                    style={{
                                                                        borderRadius: '10px',
                                                                        backgroundColor: '#000000',
                                                                        color: '#ffffff',
                                                                        border: 'none',
                                                                        width: '18px',
                                                                        height: '18px',
                                                                        fontSize: '12px',
                                                                        marginLeft: '5px'
                                                                    }}
                                                                >
                                                                    !
                                                                </button>
                                                            }
                                                        </span>
                                                        {((openUploadBlock &&
                                                            openUploadBlock["LogoImage"] == true &&
                                                            portfolioDetails.logo_image.length === 0) ||
                                                            portfolioDetails.logo_image.length > 0) && (
                                                                <>
                                                                    <span
                                                                        className="close"
                                                                        onClick={() => setOpenUploadBlock({ LogoImage: false })}
                                                                    >
                                                                        <i className="fa fa-close"></i>
                                                                    </span>
                                                                    <FileUploader
                                                                        // key={UploadTypeEnum.FacilitiesPictures}
                                                                        key={Math.random().toString()}
                                                                        id={UploadTypeEnum4.LogoImage}
                                                                        information="Drag & Drop the file or Browse"
                                                                        fileType="image/jpeg, image/png, image/jpg"
                                                                        buttonLabel="Browse"
                                                                        multiple={false}
                                                                        enablePreview
                                                                        IconComponent={() => (
                                                                            <span className="fa fa-cloud-upload"></span>
                                                                        )}
                                                                        removeIconClass=""
                                                                        existingFiles={uploadLogo}
                                                                        onUploadFinish={(files: Array<File>) => {
                                                                            onFileUpload(files, "logo_image");
                                                                        }}
                                                                        onFileDelete={(deletedFile: File) =>
                                                                            onFileDelete("logo_image", deletedFile)
                                                                        }
                                                                    />
                                                                </>
                                                            )}
                                                        {/* <FileUploader handleChange={logoFilehandleChange} name="file" types={fileTypes} /> */}
                                                        {
                                                            portfolioDetails.logo_image.length > 0 &&
                                                            <>
                                                                <span style={{ fontWeight: 'bolder' }}>Uploaded Image:</span>&nbsp;&nbsp;
                                                                {getFileDetails(portfolioDetails.logo_image, 'name')}&nbsp;&nbsp;
                                                                <span >
                                                                    <a
                                                                        href={`${NODE_URL}${portfolioDetails.logo_image}`}
                                                                        target="_blank" className="message-orange"
                                                                    >view
                                                                    </a>
                                                                </span>
                                                            </>
                                                        }
                                                        {uploadLogo.length == 0 && validationFlag.logo == true ? <label className="error-message">Please Upload Logo</label> : null}
                                                        {/* <p>{file ? `File name: ${logoFileName}` : "No files uploaded yet"}</p> */}
                                                    </div>

                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-md-4 col-form-label">
                                                        Cover Image <span style={{ color: 'red' }}><sup>*</sup></span>
                                                        <small style={{ color: 'gray' }}><i>(Size : 880px * 360px)</i></small>
                                                    </label>
                                                    <div className="col-sm-12 col-md-8">
                                                        <button
                                                            className="btn btn-sisfs btn-transparent-primary"
                                                            onClick={() => setOpenUploadBlock({ CoverImage: true })}
                                                        >
                                                            Upload
                                                        </button>&nbsp;&nbsp;
                                                        <span className="fileToolTip">
                                                            {

                                                                <button
                                                                    className="button tool-tip"
                                                                    style={{
                                                                        borderRadius: '10px',
                                                                        backgroundColor: '#000000',
                                                                        color: '#ffffff',
                                                                        border: 'none',
                                                                        width: '18px',
                                                                        height: '18px',
                                                                        fontSize: '12px',
                                                                        marginLeft: '5px'
                                                                    }}
                                                                >
                                                                    !
                                                                </button>
                                                            }
                                                        </span>

                                                        {((openUploadBlock &&
                                                            openUploadBlock["CoverImage"] &&
                                                            portfolioDetails.cover_image.length === 0) ||
                                                            portfolioDetails.cover_image.length > 0) && (
                                                                <>
                                                                    <span
                                                                        className="close"
                                                                        onClick={() => setOpenUploadBlock({ CoverImage: false })}
                                                                    >
                                                                        <i className="fa fa-close"></i>
                                                                    </span>
                                                                    <FileUploader
                                                                        // key={UploadTypeEnum.FacilitiesPictures}
                                                                        key={Math.random().toString()}
                                                                        id={UploadTypeEnum4.CoverImage}
                                                                        information="Drag & Drop the file or Browse"
                                                                        fileType="image/jpeg, image/png, image/jpg"
                                                                        buttonLabel="Browse"
                                                                        multiple={false}
                                                                        enablePreview
                                                                        IconComponent={() => (
                                                                            <span className="fa fa-cloud-upload"></span>
                                                                        )}
                                                                        removeIconClass=""
                                                                        existingFiles={uploadcover}
                                                                        onUploadFinish={(files: Array<File>) => {
                                                                            onFileUpload(files, "cover_image");
                                                                        }}
                                                                        onFileDelete={(deletedFile: File) =>
                                                                            onFileDelete("cover_image", deletedFile)
                                                                        }
                                                                    />
                                                                </>
                                                            )}

                                                        {/* {
                                                            portfolioDetails.cover_image.length > 0 &&
                                                            <>
                                                                <span style={{ fontWeight: 'bolder' }}>Uploaded Image:</span>&nbsp;&nbsp;
                                                                {getFileDetails(portfolioDetails.logo_image, 'name')}&nbsp;&nbsp;
                                                                <span >
                                                                    <a
                                                                        href={`${NODE_URL}${portfolioDetails.logo_image}`}
                                                                        target="_blank" className="message-orange"
                                                                    >view
                                                                    </a>
                                                                </span>
                                                            </>
                                                        } */}
                                                        {portfolioDetails.cover_image.length > 0 &&
                                                            <>
                                                                <span style={{ fontWeight: 'bolder' }}>Uploaded Image:</span>&nbsp;&nbsp;
                                                                {getFileDetails(portfolioDetails.cover_image, 'name')}&nbsp;&nbsp;
                                                                <span >
                                                                    <a
                                                                        href={`${NODE_URL}${portfolioDetails.cover_image}`}
                                                                        target="_blank"
                                                                        className="message-orange"
                                                                    >view
                                                                    </a>
                                                                </span>
                                                            </>
                                                        }
                                                        {/* <FileUploader handleChange={coverFilehandleChange} name="filess" types={fileTypes} /> */}
                                                        {uploadcover.length == 0 && validationFlag.cover_img == true ? <label className="error-message">Please Upload Cover Image</label> : null}
                                                        {/* <p>{filess ? `File name: ${coverFileName}` : "No files uploaded yet"}</p> */}
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-md-4 col-form-label">
                                                        Gallery <span style={{ color: 'red' }}><sup>*</sup></span>
                                                        <small style={{ color: 'gray' }}><i>(Size : 720px * 295px)</i></small>
                                                    </label>
                                                    <div className="col-sm-12 col-md-8">
                                                        <button
                                                            className="btn btn-sisfs btn-transparent-primary"
                                                            onClick={() => setOpenUploadBlock({ GalleryPictures: true })}
                                                        >
                                                            Upload
                                                        </button>&nbsp;&nbsp;
                                                        <span className="fileToolTip">
                                                            {
                                                                <button
                                                                    className="button tool-tip"
                                                                    style={{
                                                                        borderRadius: '10px',
                                                                        backgroundColor: '#000000',
                                                                        color: '#ffffff',
                                                                        border: 'none',
                                                                        width: '18px',
                                                                        height: '18px',
                                                                        fontSize: '12px',
                                                                        marginLeft: '5px'
                                                                    }}
                                                                >
                                                                    !
                                                                </button>
                                                            }
                                                        </span>
                                                        {((openUploadBlock &&
                                                            openUploadBlock["GalleryPictures"] &&
                                                            portfolioDetails.gallery_pictures.length === 0) ||
                                                            portfolioDetails.gallery_pictures.length > 0) && (
                                                                <>
                                                                    <span
                                                                        className="close"
                                                                        onClick={() => setOpenUploadBlock({ GalleryPictures: false })}
                                                                    >
                                                                        <i className="fa fa-close"></i>
                                                                    </span>
                                                                    <FileUploader
                                                                        // key={UploadTypeEnum.FacilitiesPictures}
                                                                        key={Math.random().toString()}
                                                                        id={UploadTypeEnum4.GalleryPictures}
                                                                        information="Drag & Drop the file or Browse"
                                                                        fileType="image/jpeg, image/png, image/jpg"
                                                                        buttonLabel="Browse"
                                                                        multiple={true}
                                                                        enablePreview
                                                                        IconComponent={() => (
                                                                            <span className="fa fa-cloud-upload"></span>
                                                                        )}
                                                                        removeIconClass=""
                                                                        existingFiles={uploadGallery}
                                                                        onUploadFinish={(files: Array<File>) => {
                                                                            onFileUpload(files, "gallery_image");
                                                                        }}
                                                                        onFileDelete={(deletedFile: File) =>
                                                                            onFileDelete("gallery_image", deletedFile)
                                                                        }
                                                                    />
                                                                </>
                                                            )}
                                                        {/* <FileUploader multiple={true} handleChange={GalleryFilehandleChange} name="gallery" types={fileTypes} /> */}
                                                        {/* {console.log(portfolioDetails.gallery_pictures, "%%%%%%%%")} */}
                                                        {

                                                            portfolioDetails.gallery_pictures.length > 0 &&
                                                            portfolioDetails.gallery_pictures.filter((item: any) => item.length > 15)
                                                                .map((item: any) =>
                                                                    <>
                                                                        <span style={{ fontWeight: 'bolder' }}>Uploaded Image:</span>&nbsp;&nbsp;
                                                                        {getFileDetails(item, 'name')}&nbsp;&nbsp;
                                                                        <span >
                                                                            <a
                                                                                href={`${NODE_URL}${item}`}
                                                                                target="_blank"
                                                                                className="message-orange"
                                                                            > view
                                                                            </a>
                                                                        </span>
                                                                        <i className="fa fa-times-circle" onClick={() => removeImage(item)} style={{ color: 'red', marginLeft: '15px', cursor: 'pointer' }}></i>
                                                                        <br />
                                                                    </>
                                                                )
                                                        }

                                                        {/* {console.log("CHECK LENGTH", uploadGallery.length, "", portfolioDetails.gallery_pictures.length)} */}



                                                        {uploadGallery.length == 0 && validationFlag.gallery == true ? <label className="error-message">Please Upload Gallery</label> : null}
                                                        {/* {(uploadGallery.length + portfolioDetails.gallery_pictures.length > 10) ? <label className="error-message">Maximum of 10 Images should be allowed</label> : null} */}

                                                        {maxUploads == true ? <label className="error-message">Maximum of 10 Images should be allowed</label> : null}
                                                        {/* <p>{gallery ? `File name: ${gallery.name}` : "No files uploaded yet"}</p> */}
                                                        {/* <div>
                                                        {Array.isArray(gallery) && gallery.map((item, index) => (
                                                            <p key={index}>{item}</p>
                                                        ))}
                                                    </div> */}
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-md-4 col-form-label">
                                                        Key Features <span style={{ color: 'red' }}><sup>*</sup></span>
                                                        <small style={{ color: 'gray' }}><i>(Add points describing the activities, interests, facilities etc. of the incubator.)</i></small>
                                                    </label>
                                                    <div className="col-sm-12 col-md-8">
                                                        {<textarea className="form-control mb-2"
                                                            onKeyDown={handleKeyDown} value={portfolioDetails.key_features}
                                                            onBlur={handleValidate} onChange={handleChange} name="key_features"
                                                            rows={3} placeholder="Enter">
                                                        </textarea>}
                                                        {<textarea className="form-control mb-2"
                                                            onKeyDown={handleKeyDown} value={portfolioDetails.key_features1}
                                                            onBlur={handleValidate} onChange={handleChange} name="key_features1"
                                                            rows={3} placeholder="Enter">
                                                        </textarea>}
                                                        {<textarea className="form-control mb-2"
                                                            onKeyDown={handleKeyDown} value={portfolioDetails.key_features2}
                                                            onBlur={handleValidate} onChange={handleChange} name="key_features2"
                                                            rows={3} placeholder="Enter">
                                                        </textarea>}
                                                        {<textarea className="form-control mb-2"
                                                            onKeyDown={handleKeyDown} value={portfolioDetails.key_features3}
                                                            onBlur={handleValidate} onChange={handleChange} name="key_features3"
                                                            rows={3} placeholder="Enter">
                                                        </textarea>}
                                                        {<textarea className="form-control mb-2"
                                                            onKeyDown={handleKeyDown} value={portfolioDetails.key_features4}
                                                            onBlur={handleValidate} onChange={handleChange} name="key_features4"
                                                            rows={3} placeholder="Enter">
                                                        </textarea>}

                                                        {validationFlag.key_features == true ? <label className="error-message">Please Enter Atleast One Key Feature</label> : null}
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-md-4 col-form-label">
                                                        Incubator Email <span style={{ color: 'red' }}><sup>*</sup></span>  &nbsp;&nbsp;
                                                        {/* <span className="emailToolTip">
                                                        {

                                                            <button
                                                                className="button tool-tip"
                                                                style={{
                                                                    borderRadius: '10px',
                                                                    backgroundColor: '#000000',
                                                                    color: '#ffffff',
                                                                    border: 'none',
                                                                    width: '18px',
                                                                    height: '18px',
                                                                    fontSize: '12px',
                                                                    marginLeft: '5px'
                                                                }}
                                                            >
                                                                !
                                                            </button>
                                                        }
                                                    </span> */}
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip
                                                                    id="example-tooltip"
                                                                    style={{ fontSize: "14px" }}
                                                                >
                                                                    Kindly note that this email ID will be published on your portfolio page on SISFS website and will be publicly visible to all visitors.
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <button
                                                                type="button"
                                                                style={{
                                                                    borderRadius: "16px",
                                                                    backgroundColor: "#000000",
                                                                    color: "#ffffff",
                                                                    border: "none",
                                                                    width: "18px",
                                                                    height: "18px",
                                                                }}
                                                            >
                                                                !
                                                            </button>
                                                        </OverlayTrigger>
                                                    </label>
                                                    <div className="col-sm-12 col-md-8">
                                                        <input type="text" onChange={handleChange} onBlur={handleValidate} name="incubator_email" value={portfolioDetails.incubator_email} className="form-control" placeholder="Incubator Email" />
                                                        {validationFlag.incubator_email == true ? <label className="error-message">Please Enter Valid Email</label> : null}
                                                    </div>
                                                </div>
                                            </fieldset>
                                            <div style={{ textAlign: 'center' }}>
                                                <small style={{ color: 'gray', textAlign: 'center' }}><i>Kindly save the form as Draft before submitting a request or previewing portfolio page</i></small>
                                            </div>
                                            <div className="form-group row action-section">
                                                {/* <label className="col-sm-12 col-md-4 col-form-label">
                                            </label> */}

                                                <div className="col-sm-12 col-md-3 text-left">
                                                    <div className="btn-group">
                                                        <button disabled={allowCreation} className="btn btn-sisfs btn-yellow-primary" onClick={resetAll} >Reset</button>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-3 text-center">
                                                    <div className="btn-group">
                                                        <button disabled={allowCreation} className="btn btn-sisfs btn-yellow-primary" onClick={saveForm}>Save Draft</button>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-3 text-center">
                                                    <div className="btn-group">
                                                        <button className="btn btn-sisfs btn-yellow-primary" onClick={() => previewPage()}>Preview Page</button>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-3 text-right">
                                                    <div className="btn-group">
                                                        <button disabled={allowCreation} className="btn btn-sisfs btn-yellow-primary" onClick={submitForm} >Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>




                {/* Draft modal popup */}
                {showSuccessModal && (
                    <>
                        <div className="modal-backdrop fade show"></div>
                        <div
                            className="modal align-middle modal-sisfs d-block"
                            id="draftFormPopup"
                        >
                            <div className="modal-dialog modal-dialog-centered modal-md">
                                <div className="modal-content">
                                    <br />
                                    <div style={{ textAlign: 'center' }}>
                                        <i className="fa fa-check-circle-o" style={{ fontSize: '42px', color: 'green' }}></i>
                                        {/* <span aria-hidden="true" style={{ cursor: 'pointer', fontSize: '30px', marginRight: '15px', marginTop: '15px' }}>×</span> */}
                                        {/* <i className="fa fa-close" data-dismiss="modal" style={{ cursor: 'pointer', fontSize: '30px', marginRight: '15px', marginTop: '15px', color: '#D3D3D3' }}></i> */}
                                    </div>
                                    <div className="modal-body text-center pt-0">
                                        <h4 className="modal-message mt-3" style={{ color: '#2b3f89', }}>
                                            {/* message-orange */}
                                            Portfolio saved as Draft
                                        </h4>
                                        <div className="modal-action">
                                            <button className="btn btn-sisfs btn-yellow-primary" data-dismiss="modal" onClick={closeModal}>
                                                OK!
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {/* submit modal popup */}
                {shoSubmitModal && (
                    <>
                        <div className="modal-backdrop fade show"></div>
                        <div
                            className="modal align-middle modal-sisfs d-block"
                            id="draftFormPopup"
                        >
                            <div className="modal-dialog modal-dialog-centered modal-md">
                                <div className="modal-content">
                                    <br />
                                    <div style={{ textAlign: 'center' }}>
                                        <i className="fa fa-check-circle-o" style={{ fontSize: '42px', color: 'green' }}></i>
                                        {/* <span aria-hidden="true" style={{ cursor: 'pointer', fontSize: '30px', marginRight: '15px', marginTop: '15px' }}>×</span> */}
                                        {/* <i className="fa fa-close" data-dismiss="modal" style={{ cursor: 'pointer', fontSize: '30px', marginRight: '15px', marginTop: '15px', color: '#D3D3D3' }}></i> */}
                                    </div>
                                    <div className="modal-body text-center pt-0">
                                        <h4 className="modal-message mt-3" style={{ color: '#2b3f89', }}>
                                            {/* message-orange */}
                                            Portfolio submitted successfully
                                        </h4>
                                        <div className="modal-action">
                                            <button className="btn btn-sisfs btn-yellow-primary" data-dismiss="modal" onClick={closeModal}>
                                                OK!
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {/* preview modal */}
                <div
                    className="modal fade"
                    id="previewPageModal"
                    role="dialog"
                    aria-labelledby="exampleModalLabel1"
                    aria-hidden="true"
                >
                    <div
                        className="modal-dialog modal-dialog-centered modal-xl"
                        role="document"
                    >
                        <div className="modal-content">
                            <div className="modal-header modal-header-bottom pr-5">
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body pl-3 pr-5 pt-4">
                                <div className="popup-head mb-5 ml-3">
                                    <p style={{ fontWeight: 'bolder', textAlign: 'center', fontSize: '22px' }}>Preview of Portfolio</p>

                                </div>

                                {Object.keys(dataPortfolio).length > 0 &&
                                    <div className="mt-5 mb-5 portfolio-detail-container">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="portFolioGrid">

                                                        <div className="portImageBlock">
                                                            {/* {console.log({NODE_URL})} */}
                                                            <img
                                                                src={`${NODE_URL}${portfolioDetails.cover_image}`}
                                                                alt={portfolioDetails.incubator_name}
                                                                className="portImage"
                                                            />
                                                        </div>
                                                        <div className="portContentBlock">
                                                            <p className="portCity">
                                                                {dataPortfolio.city},{" " + dataPortfolio.state}
                                                            </p>
                                                            <h1 className="portHeading twoLine">
                                                                {portfolioDetails.incubator_name}
                                                            </h1>
                                                            <p className="portDescription twoLine">
                                                                {portfolioDetails?.message}
                                                            </p>

                                                            {dataPortfolio.sectors.length > 0 && (
                                                                <p className="portTag">
                                                                    <span>{dataPortfolio.sectors[0]}</span>
                                                                    {dataPortfolio.sectors.length > 1 && (
                                                                        <span>
                                                                            {" "}
                                                                            +{dataPortfolio.sectors.length - 1} More{" "}
                                                                        </span>
                                                                    )}
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col">
                                                    <div className="d-flex justify-content-between mb-4">
                                                        <div className="logo">
                                                            <img src={`${NODE_URL}${dataPortfolio.logo}`} alt={dataPortfolio.incubator_name} className="portLogo" />
                                                        </div>
                                                        <div className="website">
                                                            <button className="btn btn-sisfs btn-transparent-primary mt-4" onClick={() => window.open(dataPortfolio.site_url)}>
                                                                Visit Website
                                                            </button>
                                                        </div>
                                                    </div>


                                                    <h1 className="main-heading portDetailHeading" style={{ fontSize: '14px', lineHeight: '1.2' }}>{dataPortfolio.incubator_name}</h1>

                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <p className="portCity mr-1 pt-1">{dataPortfolio.city},{' ' + dataPortfolio.state}|</p>
                                                            {!showMore &&
                                                                <p className="portTagDetail ml-4" style={{ display: 'contents', fontSize: '13px' }}>
                                                                    {dataPortfolio.sectors.length > 0 && dataPortfolio?.sectors.map((sector: string, index: number) => {
                                                                        if (index < 4) {
                                                                            return (
                                                                                <Fragment key={index}>
                                                                                    <span className="mr-1">{sector}</span>
                                                                                </Fragment>
                                                                            )
                                                                        }
                                                                    })}

                                                                </p>}
                                                            {showMore &&
                                                                <p className="portTagDetail ml-4" style={{ display: 'contents', fontSize: '13px' }}>
                                                                    {dataPortfolio.sectors.length > 0 && dataPortfolio?.sectors.map((sector: string, index: number) => {
                                                                        return (
                                                                            <Fragment key={index}>
                                                                                <span className="mr-1">{sector}</span>
                                                                            </Fragment>
                                                                        )
                                                                    })}

                                                                </p>}
                                                            {!showMore && (dataPortfolio.sectors.length > 4) && <span className="link-orange-text d-block pt-1 pl-3" style={{ fontSize: '13px' }} onClick={() => setShowMore(true)}>View More</span>}
                                                        </div>

                                                        <div className="mainImage">
                                                            <img src={`${NODE_URL}${dataPortfolio.image}`} alt={dataPortfolio.incubator_name} className="portImageMain" />
                                                        </div>
                                                    </div>


                                                    {/* <div className="portImageBlock">
                            <img src="" className="portImage" />
                        </div> */}

                                                    <div className="main-heading right-heading mt-1" style={{ fontSize: '16px' }}>About</div>
                                                    <p className="para-text mb-2" style={{ fontSize: '14px' }}>
                                                        {dataPortfolio.description}
                                                    </p>
                                                </div>
                                                <div className="col-4">

                                                    <div className="main-heading right-heading" style={{ fontSize: '16px' }}>Key Features</div>

                                                    <div className="key">
                                                        <div className="ordered-list wrapper about-ordered-list">
                                                            <ol>
                                                                {dataPortfolio.key_features.length > 0 && dataPortfolio.key_features.map((feature: string, index: number) => {
                                                                    return (
                                                                        <Fragment key={index}>
                                                                            <li className="mb-1">
                                                                                <span style={{ fontSize: '14px', lineHeight: '25px' }}>{feature}</span>
                                                                            </li>
                                                                        </Fragment>
                                                                    )
                                                                })}
                                                            </ol>
                                                        </div>

                                                    </div>

                                                    <div className="main-heading text-left mt-3">Photo Gallery</div>
                                                    <div className="galleryCarousel">

                                                        <div id="carouselExampleIndicators" className="carousel" data-ride="carousel">
                                                            <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                <span className="sr-only">Previous</span>
                                                            </a>
                                                            <ol className="carousel-indicators">
                                                                {dataPortfolio.image_data.map((image: string, index: number) => {
                                                                    if (index == 0) {
                                                                        return (
                                                                            <li data-target="#carouselExampleIndicators" data-slide-to={index} className="active" key={index}></li>
                                                                        )
                                                                    }
                                                                    else {
                                                                        return (
                                                                            <li data-target="#carouselExampleIndicators" data-slide-to={index} key={index}></li>
                                                                        )
                                                                    }
                                                                })}
                                                                {/* <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                                                                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                                                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li> */}
                                                            </ol>
                                                            <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                                <span className="sr-only">Next</span>
                                                            </a>

                                                            <div className="carousel-inner carousel-overflow">
                                                                {dataPortfolio.image_data.map((image: string, index: number) => {
                                                                    if (index == 0) {
                                                                        return (
                                                                            <div className="carousel-item active" key={index}>
                                                                                <img className="d-block w-100 corousel-image" src={`${NODE_URL}${image}`} alt="First slide" />
                                                                            </div>
                                                                        )
                                                                    }
                                                                    else {
                                                                        return (
                                                                            <div className="carousel-item" key={index}>
                                                                                <img className="d-block w-100 corousel-image" src={`${NODE_URL}${image}`} alt="First slide" />
                                                                            </div>
                                                                        )
                                                                    }

                                                                })}
                                                                {/* <div className="carousel-item active">
                                                                    <img className="d-block w-100" src={dataPortfolio.image} alt="First slide" />
                                                                </div>
                                                                <div className="carousel-item">
                                                                    <img className="d-block w-100" src={dataPortfolio.image} alt="Second slide" />
                                                                </div>
                                                                <div className="carousel-item">
                                                                    <img className="d-block w-100" src={dataPortfolio.image} alt="Third slide" />
                                                                </div> */}



                                                            </div>



                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row mt-2">
                                                <div className="col-3 text-center" style={{ fontWeight: 'bolder' }}>Tile View</div>
                                                <div className="col text-center" style={{ fontWeight: 'bolder' }}>Inside Preview Part-1</div>
                                                <div className="col-4 text-center" style={{ fontWeight: 'bolder' }}>Inside Preview Part-2</div>
                                            </div>



                                            {/* <div className="text-center">
                            <button className="btn btn-sisfs btn-transparent-primary mt-4" onClick={() => window.open(data.site_url)}>
                                Visit Website
                            </button>
                        </div> */}

                                        </div>
                                    </div>
                                }


                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </Fragment>
    );
};
export default MyPortfolio;
