import React, { useEffect, useState } from "react";
import Stepper from "bs-stepper";
import "bs-stepper/dist/css/bs-stepper.min.css";
import StepperHeaderStartupComponent from "./StepperHeaderStartupComponent";
import EntityDetailsComponent from "./EntityDetailsComponent";
import StartupDetailsComponent from "./StartupDetailsComponent";
import AuthorizedRepresentativeComponent from "./AuthorizedRepresentativeComponent";
import StartupTeamComponent from "./StartupTeamComponent";
import IncubatorPreferenceComponent from "./IncubatorPreferenceComponent";
import StartupFundingDetailsComponent from "./StartupFundingDetailsComponent";
import UploadDocumentsComponent from "./UploadDocumentsComponent";
import {
  initAuthorizedRepresentative,
  initEntityDetail,
  initFundingDetails,
  initIncubatorPreference,
  initStartupDetail,
  initStartupTeam,
  initUploadDocuments,
  StartupModel,
} from "../../../models/startup/StartupModel";
import {
  requestType,
  StepperEnum,
  StepperStartupEnum,
} from "../../../utils/Enums";
import { EntityDetailModel } from "../../../models/startup/EntityDetailModel";
import { StartupDetailModel } from "../../../models/startup/StartupDetailModel";
import {
  PromoterDetails,
  StartupTeamModel,
  TeamDetails,
} from "../../../models/startup/StartupTeamModel";
import { AuthorizedRepresentativeModel } from "../../../models/startup/AuthorizedRepresentativeStartupModel";
import {
  FundExpenseDetails,
  FundingDetailsModel,
  PriorDetails,
} from "../../../models/startup/FundingDetailsModel";
import { IncubatorPreferenceModel } from "../../../models/startup/IncubatorPreferenceModel";
import { UploadDocumentsModel } from "../../../models/startup/UploadDocumentsModel";
import {
  IRequireField,
  ValidationHelper,
} from "../../../utils/ValidationHelper";
import { Action } from "../../../reducers/reducer.action";
import {
  StateErrorProvider,
  useErrorStateValue,
} from "../../../providers/ErrorProvider";
import { get, post } from "../../../effects/api/api.common";
import {
  File_node_url,
  STARTUP_GET_DETAILS,
  STARTUP_GET_INCUBATORS,
  STORE_STARTUP_APPLICATION,
  STORE_STARTUP_APPLICATION_NODE,
  STORE_STARTUP_APPLICATION_SAVE,
} from "../../../constants/api";
import ConfirmationDialog from "../../common/controls/ConfirmationDialog";
import { compareDate, createFile } from "../../../utils/CommonMethods";
import {
  FileUploadModel,
  FileUploadTypeModel,
} from "../../../models/FileUploadModel";
import { Link } from "react-router-dom";
import moment from "moment";
import { getSessionStorageValue } from "../../../effects/utils/session-storage";

interface IStartupProps {
  tabIndex: number;
  location: any;
}

interface IStartupState {
  showIncubatorSuccessModal: boolean;
  showIncubatorErrorModal: boolean;
  applicationId: string;
}
let isValidForm: boolean = true;
/** Render start-up application screen */
function StartupContainer(props: IStartupProps) {
  const [stepperRef, setStepperRef] = useState<Stepper>();

  // @ts-ignore
  const [{ }, dispatch] = useErrorStateValue();

  const [stepper, setStepper] = useState<Stepper>();
  const [startup, setStartup] = useState<StartupModel>(
    new StartupModel(
      "",
      new EntityDetailModel(
        "",
        "",
        "",
        [],
        "",
        false,
        [],
        "",
        "",
        "",
        "",
        "",
        ""
      ),
      new StartupDetailModel(
        "",
        "",
        "",
        "",
        "",
        1,
        "",
        "",
        // [],
        "",
        "",
        "",
        "",
        false,
        false,
        false,
        false,
        "",
        "",
        "",
        "",
        "",
        "",
        false,
        false,
        false,
        false,
        false
      ),
      new AuthorizedRepresentativeModel("", "", "", "", "", []),
      new StartupTeamModel(
        "",
        "",
        "",
        "",
        1,
        [new TeamDetails(0, "", 0)],
        [new PromoterDetails(0, "", "")],
        []
      ),
      new FundingDetailsModel(
        "",
        "",
        0,
        [
          {
            expense_bucket: "",
            expense_amount: null,
            show_other: false,
            expense_bucket_other: "",
            deployment_start_date: new Date(),
            deployment_end_date: new Date(),
          },
        ],
        "1",
        [new PriorDetails(0, formatDate(new Date()), null, "", "", "")]
      ),
      new IncubatorPreferenceModel("", "", ""),
      new UploadDocumentsModel("", [], "", [], [])
      // initStartupDetail,
      // initAuthorizedRepresentative,
      // initStartupTeam,
      // initFundingDetails,
      // initIncubatorPreference,
      // initUploadDocuments
    )
  );
  const [showStartupSuccessModal, setShowStartupSuccessModal] = useState(false);
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [showStartupErrorModal, setShowStartupErrorModal] = useState(false);
  const [showFailureMessage, setShowFailureMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [startupStatusId, setStartupStatusId] = useState(1);
  const [apiCalled, setApiCalled] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isDisabledTab, setIsDisabledTab] = useState(false);
  const [incubatorPreferenceListMain, setIncubatorPreferenceListMain] =
    useState([]);
  const [stopSubmitStartupDetails, setStopSubmitStartupDetails] =
    useState(false);
  const [stopSubmitQuantumFunds, setStopSubmitSubmitQuantumFunds] =
    useState(false);
    const [stopSubmitQuantum_Funds, setStopSubmitSubmitQuantum_Funds] =
    useState(false);
  const [stopSubmitDebt, setStopSubmitDebt] = useState(false);
  const [stopSubmitFundingDetails, setStopSubmitFundingDetails] =
    useState(false);
  const [stopSubmitEntityDetails, setStopSubmitEntityDetails] = useState(false);
  const [stopSubmitIncorporationDate, setStopSubmitIncorporationDate] =
    useState(false);
  const [eligibilityCriteria, setEligibilityCriteria] = useState([
    "funding_grant",
    "funding_debt",
    "funding_debenture",
  ]);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    // console.log(props.location.state,"state@@@@")
    if (typeof props.location.state != "undefined") {
      if (props.location.state.new_apply == 1) {
        // console.log(props.location.state.eligibilityCriteriaArray);
        setEligibilityCriteria(props.location.state.eligibilityCriteriaArray);
        getStartupDetails();
      } else if (
        props.location.state.application_id != 0 &&
        props.location.state.disabled == true
      ) {
        startup.application_id = props.location.state.application_id;
        setStartup(startup);
        setDisabled(true);
        viewActiveApplication(props.location.state.application_id);
        getAllIncubators(props.location.state.application_id);
      } else if (
        props.location.state.application_id != 0 &&
        props.location.state.continue_editing == 1
      ) {
        startup.application_id = props.location.state.application_id;
        setStartup(startup);
        viewActiveApplication(props.location.state.application_id);
        getAllIncubators(props.location.state.application_id);
      }
      // else {
      //     startup.application_id = props.location.state.application_id;
      //     setStartup(startup);
      //     viewActiveApplication(props.location.state.application_id);
      //     getAllIncubators(props.location.state.application_id);
      // }
    }
    setStepper(
      new Stepper(document.querySelector("#startupstepper") as Element, {
        linear: false,
        animation: true,
      })
    );
    window.scrollTo(0, 0);
    // console.log(startup.application_id,"startup$$$$")
    // viewActiveApplication(application_id);
  }, []);

  // trigers when clicked on save button
  // const onSave = () => {
  //     stepper && stepper.next();
  // };

  //view active application
  const getStartupDetails = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    post(STARTUP_GET_DETAILS, { "logged_user_id": userInfo.id }).then(async (response: any) => {
      if (!response || !response.data) return;
      if (response.data.data != "") {
        bindEntityDetailFromStartup(response.data.data, startup);
        setStartup((prevState: StartupModel) => {
          const updatedState = { ...prevState, startup };
          return updatedState;
        });
        setApiCalled(true);
      }
    });
  };

  //view active application
  const viewActiveApplication = (application_id: any) => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)

    var payload = {
      "user_id": userInfo.id
    }
    post(`${STORE_STARTUP_APPLICATION_NODE}/${application_id}`, payload).then(
      async (response: any) => {
        if (!response || !response.data) return;

        if (response.data.data) {
          setEligibilityCriteria(
            response.data.eligibilityData.eligibilityCriteria
          );


          // 1st Step
          bindEntityDetail(response, startup);

          // 2nd Step
          bindStartupDetail(response, startup);

          // 3rd Step
          bindAuthorizedRepresentative(response, startup);

          // 4th Step
          bindStartupTeam(response, startup);

          // 5th Step
          bindFundingDetails(response, startup);

          // 6th Step
          bindIncubatorPreference(response, startup);

          // 7th Step
          bindUploadDocuments(response, startup);


          setStartupStatusId(response.data.data.Other.startup_status_id);
          if (response.data.data.Other.startup_status_id != 1) {
            setIsDisabledTab(true);
          }
          // console.log(response.data.eligibilityData.eligibility == true ? 1 : 0,"297")
          setStartup((prevState: StartupModel) => {
            const updatedState = { ...prevState, startup };
            return updatedState;
          });
          // console.log(startup, 'YYYYYYYYYYYYYYYYYYY')
          setApiCalled(true);
        }
      }
    );
  };
  // Bind EntityDetail Step 1 response from startup api
  const bindEntityDetailFromStartup = (
    response: any,
    startup: StartupModel
  ) => {
    // console.log(response.recognition_number,"entityyy")
    startup.EntityDetail.recognition_number =
      response.recognition_number !== null
        ? response.recognition_number
        : "";
    startup.EntityDetail.name_of_entity =
      response.name_of_entity !== null
        ? response.name_of_entity
        : "";
    startup.EntityDetail.nature_of_entity =
      response.nature_of_entity !== null
        ? response.nature_of_entity
        : "";
    startup.EntityDetail.industry_ids = response.industry_ids;
    startup.EntityDetail.industry_id_other =
      response.industry_id_other !== null
        ? response.industry_id_other
        : "";
    startup.EntityDetail.industry_id_other_show =
      response.industry_id_other_show;
    startup.EntityDetail.sector_ids = response.sector_ids;
    startup.EntityDetail.company_information_number =
      response.company_information_number !== null
        ? response.company_information_number
        : "";
    startup.EntityDetail.registration_date = response
      .registration_date
      ? moment(response.registration_date).format("YYYY-MM-DD")
      : "";
    startup.EntityDetail.pan_number =
      response.pan_number !== null
        ? response.pan_number
        : "";
    startup.EntityDetail.state_id = response.state_id;
    startup.EntityDetail.city_id = response.city_id;
    startup.EntityDetail.startup_address =
      response.startup_address !== null
        ? response.startup_address
        : "";
  };

  // Bind EntityDetail Step 1 response
  const bindEntityDetail = (response: any, startup: StartupModel) => {
    startup.EntityDetail.recognition_number =
      response.data.data.EntityDetail.recognition_number !== null
        ? response.data.data.EntityDetail.recognition_number
        : "";
    startup.EntityDetail.name_of_entity =
      response.data.data.EntityDetail.name_of_entity !== null
        ? response.data.data.EntityDetail.name_of_entity
        : "";
    startup.EntityDetail.nature_of_entity =
      response.data.data.EntityDetail.nature_of_entity !== null
        ? response.data.data.EntityDetail.nature_of_entity
        : "";
    startup.EntityDetail.industry_ids =
      response.data.data.EntityDetail.industry_ids;
    startup.EntityDetail.industry_id_other =
      response.data.data.EntityDetail.industry_id_other !== null
        ? response.data.data.EntityDetail.industry_id_other
        : "";
    startup.EntityDetail.industry_id_other_show =
      response.data.data.EntityDetail.industry_id_other_show;
    startup.EntityDetail.sector_ids =
      response.data.data.EntityDetail.sector_ids;
    startup.EntityDetail.company_information_number =
      response.data.data.EntityDetail.company_information_number !== null
        ? response.data.data.EntityDetail.company_information_number
        : "";
    startup.EntityDetail.registration_date = response.data.data.EntityDetail
      .registration_date
      ? moment(response.data.data.EntityDetail.registration_date).format("YYYY-MM-DD")
      : "";
    startup.EntityDetail.pan_number =
      response.data.data.EntityDetail.pan_number !== null
        ? response.data.data.EntityDetail.pan_number
        : "";
    startup.EntityDetail.state_id = response.data.data.EntityDetail.state_id;
    startup.EntityDetail.city_id = response.data.data.EntityDetail.city_id;
    startup.EntityDetail.startup_address =
      response.data.data.EntityDetail.startup_address !== null
        ? response.data.data.EntityDetail.startup_address
        : "";
    // setStartup(startup);
  };

  // Bind StartupDetail Step 2 response
  const bindStartupDetail = (response: any, startup: StartupModel) => {
    startup.StartupDetail.is_technology_startup =
      response.data.data.StartupDetail.is_technology_startup !== null
        ? response.data.data.StartupDetail.is_technology_startup
        : "";
    startup.StartupDetail.solving_problem_for =
      response.data.data.StartupDetail.solving_problem_for !== null
        ? response.data.data.StartupDetail.solving_problem_for
        : "";
    startup.StartupDetail.value_proposition_for_problem =
      response.data.data.StartupDetail.value_proposition_for_problem !== null
        ? response.data.data.StartupDetail.value_proposition_for_problem
        : "";
    startup.StartupDetail.unique_selling_point =
      response.data.data.StartupDetail.unique_selling_point !== null
        ? response.data.data.StartupDetail.unique_selling_point
        : "";
    startup.StartupDetail.target_customer_segment =
      response.data.data.StartupDetail.target_customer_segment !== null
        ? response.data.data.StartupDetail.target_customer_segment
        : "";
    startup.StartupDetail.market_size_opportunity =
      response.data.data.StartupDetail.market_size_opportunity !== null
        ? response.data.data.StartupDetail.market_size_opportunity
        : "";
    startup.StartupDetail.aim_to_scale_up =
      response.data.data.StartupDetail.aim_to_scale_up !== null
        ? response.data.data.StartupDetail.aim_to_scale_up
        : "";
    startup.StartupDetail.revenue_model =
      response.data.data.StartupDetail.revenue_model !== null
        ? response.data.data.StartupDetail.revenue_model
        : "";
    // startup.StartupDetail.key_competitors =
    //     response.data.data.StartupDetail.key_competitors;
    startup.StartupDetail.competitors_startup_desc =
      response.data.data.StartupDetail.competitors_startup_desc !== null
        ? response.data.data.StartupDetail.competitors_startup_desc
        : "";
    startup.StartupDetail.competitors_msme_desc =
      response.data.data.StartupDetail.competitors_msme_desc !== null
        ? response.data.data.StartupDetail.competitors_msme_desc
        : "";
    startup.StartupDetail.competitors_corporate_desc =
      response.data.data.StartupDetail.competitors_corporate_desc !== null
        ? response.data.data.StartupDetail.competitors_corporate_desc
        : "";
    startup.StartupDetail.competitors_other_desc =
      response.data.data.StartupDetail.competitors_other_desc !== null
        ? response.data.data.StartupDetail.competitors_other_desc
        : "";
    startup.StartupDetail.competitors_startup_enable =
      response.data.data.StartupDetail.competitors_startup_enable;
    startup.StartupDetail.competitors_msme_enable =
      response.data.data.StartupDetail.competitors_msme_enable;
    startup.StartupDetail.competitors_corporate_enable =
      response.data.data.StartupDetail.competitors_corporate_enable;
    startup.StartupDetail.competitors_other_enable =
      response.data.data.StartupDetail.competitors_other_enable;
    startup.StartupDetail.website_url =
      response.data.data.StartupDetail.website_url !== null
        ? response.data.data.StartupDetail.website_url
        : "";
    startup.StartupDetail.facebook_link =
      response.data.data.StartupDetail.facebook_link !== null
        ? response.data.data.StartupDetail.facebook_link
        : "";
    startup.StartupDetail.facebook_enable =
      response.data.data.StartupDetail.facebook_enable;
    startup.StartupDetail.twitter_link =
      response.data.data.StartupDetail.twitter_link !== null
        ? response.data.data.StartupDetail.twitter_link
        : "";
    startup.StartupDetail.twitter_enable =
      response.data.data.StartupDetail.twitter_enable;
    startup.StartupDetail.linkedin_link =
      response.data.data.StartupDetail.linkedin_link !== null
        ? response.data.data.StartupDetail.linkedin_link
        : "";
    startup.StartupDetail.linkedin_enable =
      response.data.data.StartupDetail.linkedin_enable;
    startup.StartupDetail.youtube_link =
      response.data.data.StartupDetail.youtube_link !== null
        ? response.data.data.StartupDetail.youtube_link
        : "";
    startup.StartupDetail.youtube_enable =
      response.data.data.StartupDetail.youtube_enable;
    startup.StartupDetail.other_link =
      response.data.data.StartupDetail.other_link !== null
        ? response.data.data.StartupDetail.other_link
        : "";
    startup.StartupDetail.other_enable =
      response.data.data.StartupDetail.other_enable;
  };

  // Bind StartupDetail Step 3 response
  const bindAuthorizedRepresentative = (
    response: any,
    startup: StartupModel
  ) => {
    startup.AuthorizedRepresentative.representative_firstname =
      response.data.data.AuthorizedRepresentative.representative_firstname !==
        null
        ? response.data.data.AuthorizedRepresentative.representative_firstname
        : "";
    startup.AuthorizedRepresentative.representative_lastname =
      response.data.data.AuthorizedRepresentative.representative_lastname !==
        null
        ? response.data.data.AuthorizedRepresentative.representative_lastname
        : "";
    startup.AuthorizedRepresentative.representative_designation =
      response.data.data.AuthorizedRepresentative.representative_designation !==
        null
        ? response.data.data.AuthorizedRepresentative.representative_designation
        : "";
    startup.AuthorizedRepresentative.representative_mobile_no =
      response.data.data.AuthorizedRepresentative.representative_mobile_no !==
        null
        ? response.data.data.AuthorizedRepresentative.representative_mobile_no
        : "";
    startup.AuthorizedRepresentative.representative_email =
      response.data.data.AuthorizedRepresentative.representative_email !== null
        ? response.data.data.AuthorizedRepresentative.representative_email
        : "";

    startup.AuthorizedRepresentative.authorisation_letter_upload =
      response.data.data.AuthorizedRepresentative.authorisation_letter_upload !== undefined
        ? response.data.data.AuthorizedRepresentative.authorisation_letter_upload
        : "";

    const file_data = response.data.data.AuthorizedRepresentative;
    if (file_data) {
      // startup.AuthorizedRepresentative.authorisation_letter_upload = [];
      setStartup((prevState: StartupModel) => {
        const updatedState = { ...prevState, startup };
        return updatedState;
      });
      // Object.keys(file_data).length &&
      //   Object.keys(file_data).map((key: string) => {
      //     switch (key) {
      //       case "authorisation_letter_upload":
      //         // if(file_data[key]?.length){
      //         //   tempIncubator.GeneralDetail.annual_report = file_data[key]
      //         // }
      //         file_data[key].map((data: any) => {

      //           createFile(data.file).then((blob: any) => {
      //             blob.lastModifiedDate = new Date();
      //             const src = data.file as string;
      //             blob.name = src.substr(src.lastIndexOf("/") + 1, src.length);
      //             const file: FileUploadTypeModel = new FileUploadTypeModel(
      //               data.file_id,
      //               data.file_type,
      //               blob as File
      //             );
      //             startup.AuthorizedRepresentative.authorisation_letter_upload = [
      //               ...startup.AuthorizedRepresentative.authorisation_letter_upload,
      //               file,
      //             ];
      //             setStartup((prevState: StartupModel) => {
      //               const updatedState = { ...prevState, startup };
      //               return updatedState;
      //             });
      //           });
      //         });

      //         break;
      //     }
      //   });
    }
  };

  // Bind StartupDetail Step 4 response
  const bindStartupTeam = (response: any, startup: StartupModel) => {
    // console.log(response.data.data.StartupTeam.promoter_data, '4444444444444444444444')
    startup.StartupTeam.ceo_firstname =
      response.data.data.StartupTeam.ceo_firstname !== null
        ? response.data.data.StartupTeam.ceo_firstname
        : "";
    startup.StartupTeam.ceo_lastname =
      response.data.data.StartupTeam.ceo_lastname !== null
        ? response.data.data.StartupTeam.ceo_lastname
        : "";
    startup.StartupTeam.ceo_description =
      response.data.data.StartupTeam.ceo_description !== null
        ? response.data.data.StartupTeam.ceo_description
        : "";
    startup.StartupTeam.ceo_linkedin_link =
      response.data.data.StartupTeam.ceo_linkedin_link !== null
        ? response.data.data.StartupTeam.ceo_linkedin_link
        : "";
    startup.StartupTeam.fulltime_employees_no =
      response.data.data.StartupTeam.fulltime_employees_no;
    startup.StartupTeam.team_data = response.data.data.StartupTeam.team_data
      .length
      ? response.data.data.StartupTeam.team_data
      : [new TeamDetails(0, "", 0)];
    // response.data.data.StartupTeam.team_data;
    startup.StartupTeam.promoter_data =
      response.data.data.StartupTeam.promoter_data != undefined
        ? response.data.data.StartupTeam.promoter_data
        : [new PromoterDetails(0, "", "")];

    startup.StartupTeam.promoters_aadhar_cards =
      response.data.data.StartupTeam.promoters_aadhar_cards != undefined
        ? response.data.data.StartupTeam.promoters_aadhar_cards
        : "";

    const file_data_team = response.data.data.StartupTeam;

    if (file_data_team) {

      // startup.StartupTeam.promoters_aadhar_cards = [];
      setStartup((prevState: StartupModel) => {
        const updatedState = { ...prevState, startup };
        return updatedState;
      });
      // Object.keys(file_data_team).length &&
      //   Object.keys(file_data_team).map((key: string) => {
      //     switch (key) {
      //       case "promoters_aadhar_cards":
      //         // if(file_data_team[key]?.length){
      //         //   tempIncubator.GeneralDetail.annual_report = file_data_team[key]
      //         // }
      //         file_data_team[key].map((data: any) => {
      //           createFile(data.file).then((blob: any) => {
      //             blob.lastModifiedDate = new Date();
      //             const src = data.file as string;
      //             blob.name = src.substr(src.lastIndexOf("/") + 1, src.length);
      //             const file: FileUploadTypeModel = new FileUploadTypeModel(
      //               data.file_id,
      //               data.file_type,
      //               blob as File
      //             );
      //             startup.StartupTeam.promoters_aadhar_cards = [
      //               ...startup.StartupTeam.promoters_aadhar_cards,
      //               file,
      //             ];
      //             setStartup((prevState: StartupModel) => {
      //               const updatedState = { ...prevState, startup };
      //               return updatedState;
      //             });
      //           });
      //         });
      //         break;
      //     }
      //   });
    }
  };

  function formatDate(date: Date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  // Bind StartupDetail Step 5 response
  const bindFundingDetails = (response: any, startup: StartupModel) => {
    // console.log(response.data.data.FundingDetails.fund_expense_data,"mohaaan")
    startup.FundingDetails.received_monetary_support_under_scheme =
      response.data.data.FundingDetails
        .received_monetary_support_under_scheme !== null
        ? response.data.data.FundingDetails
          .received_monetary_support_under_scheme
        : "";
    startup.FundingDetails.instrument_applying_for =
      response.data.data.FundingDetails.instrument_applying_for !== null
        ? response.data.data.FundingDetails.instrument_applying_for
        : "";
    startup.FundingDetails.quantum_funds_required =
      response.data.data.FundingDetails.quantum_funds_required;
    startup.FundingDetails.fund_expense_data =
      response.data.data.FundingDetails.fund_expense_data[0] != undefined
        ? response.data.data.FundingDetails.fund_expense_data[0]
        : [
          {
            expense_bucket: "",
            expense_amount: null,
            show_other: false,
            expense_bucket_other: "",
            deployment_start_date: new Date(),
            deployment_end_date: new Date(),
          },
        ];
    //  response.data.data.FundingDetails.fund_expense_data;
    startup.FundingDetails.prior_funds_applicable =
      response.data.data.FundingDetails.prior_funds_applicable;
    startup.FundingDetails.prior_fund_data =
      response.data.data.FundingDetails.prior_fund_data.length != 0
        ? response.data.data.FundingDetails.prior_fund_data
        : [new PriorDetails(0, formatDate(new Date()), null, "", "", "")];
  };

  // Bind StartupDetail Step 6 response
  const bindIncubatorPreference = (response: any, startup: StartupModel) => {
    // console.log(response.data.data, 'Adhi')
    startup.IncubatorPreference.incubator_preference_1 =
      response.data.data.IncubatorPreference.incubator_preference_1 == null
        ? ""
        : response.data.data.IncubatorPreference.incubator_preference_1;
    startup.IncubatorPreference.incubator_preference_2 =
      response.data.data.IncubatorPreference.incubator_preference_2 == null
        ? ""
        : response.data.data.IncubatorPreference.incubator_preference_2;
    startup.IncubatorPreference.incubator_preference_3 =
      response.data.data.IncubatorPreference.incubator_preference_3 == null
        ? ""
        : response.data.data.IncubatorPreference.incubator_preference_3;
  };

  // Bind StartupDetail Step 7 response
  const bindUploadDocuments = (response: any, startup: StartupModel) => {
    // console.log(response,"3333333333333333333")
    startup.UploadDocuments.upload_pitch_deck =
      response.data.data.UploadDocuments.upload_pitch_deck;

    startup.UploadDocuments.video_url_for_product =
      response.data.data.UploadDocuments.video_url_for_product !== null
        ? response.data.data.UploadDocuments.video_url_for_product
        : "";
    startup.UploadDocuments.upload_video_for_product =
      response.data.data.UploadDocuments.upload_video_for_product;

    startup.UploadDocuments.upload_relavent_document_other =
      response.data.data.UploadDocuments.upload_relavent_document_other;

    const file_data = response.data.data.UploadDocuments;
    if (file_data) {
      // startup.UploadDocuments.upload_pitch_deck = [];
      // startup.UploadDocuments.upload_video_for_product = [];
      // startup.UploadDocuments.upload_relavent_document_other = [];

      setStartup((prevState: StartupModel) => {
        const updatedState = { ...prevState, startup };
        return updatedState;
      });

      // console.log(startup);
      // Object.keys(file_data).length &&
      //   Object.keys(file_data).map((key: string) => {
      //     switch (key) {
      //       case "upload_pitch_deck":
      //         // if(file_data[key]?.length){
      //         //   tempIncubator.GeneralDetail.annual_report = file_data[key]
      //         // }
      //         file_data[key].map((data: any) => {
      //           createFile(data.file).then((blob: any) => {
      //             blob.lastModifiedDate = new Date();
      //             const src = data.file as string;
      //             blob.name = src.substr(src.lastIndexOf("/") + 1, src.length);
      //             const file: FileUploadTypeModel = new FileUploadTypeModel(
      //               data.file_id,
      //               data.file_type,
      //               blob as File
      //             );
      //             startup.UploadDocuments.upload_pitch_deck = [
      //               ...startup.UploadDocuments.upload_pitch_deck,
      //               file,
      //             ];
      //             setStartup((prevState: StartupModel) => {
      //               const updatedState = { ...prevState, startup };
      //               return updatedState;
      //             });
      //           });
      //         });

      //         break;
      //       case "upload_video_for_product":
      //         // if(file_data[key]?.length){
      //         //   tempIncubator.GeneralDetail.annual_report = file_data[key]
      //         // }
      //         file_data[key].map((data: any) => {
      //           createFile(data.file).then((blob: any) => {
      //             blob.lastModifiedDate = new Date();
      //             const src = data.file as string;
      //             blob.name = src.substr(src.lastIndexOf("/") + 1, src.length);
      //             // const file: FileUploadModel = new FileUploadModel(
      //             //     data.file_id,
      //             //     data.file_type_id,
      //             //     blob as File
      //             // );
      //             const file: FileUploadTypeModel = new FileUploadTypeModel(
      //               data.file_id,
      //               data.file_type,
      //               blob as File
      //             );
      //             startup.UploadDocuments.upload_video_for_product = [
      //               ...startup.UploadDocuments.upload_video_for_product,
      //               file,
      //             ];
      //             setStartup((prevState: StartupModel) => {
      //               const updatedState = { ...prevState, startup };
      //               return updatedState;
      //             });
      //           });
      //         });

      //         break;
      //       case "upload_relavent_document_other":
      //         // if(file_data[key]?.length){
      //         //   tempIncubator.GeneralDetail.annual_report = file_data[key]
      //         // }
      //         file_data[key].map((data: any) => {
      //           createFile(data.file).then((blob: any) => {
      //             blob.lastModifiedDate = new Date();
      //             const src = data.file as string;
      //             blob.name = src.substr(src.lastIndexOf("/") + 1, src.length);
      //             // const file: FileUploadModel = new FileUploadModel(
      //             //     data.file_id,
      //             //     data.file_type_id,
      //             //     blob as File
      //             // );
      //             const file: FileUploadTypeModel = new FileUploadTypeModel(
      //               data.file_id,
      //               data.file_type,
      //               blob as File
      //             );
      //             startup.UploadDocuments.upload_relavent_document_other = [
      //               ...startup.UploadDocuments.upload_relavent_document_other,
      //               file,
      //             ];
      //             setStartup((prevState: StartupModel) => {
      //               const updatedState = { ...prevState, startup };
      //               return updatedState;
      //             });
      //           });
      //         });

      //         break;
      //     }
      //   });
    }
  };
  const updateApplicationID = (application_id: any) => {
    if (application_id != 0) {
      startup.application_id = application_id;
      setStartup(startup);
    }
  };

  const onChildUpdate = (object: any, stepIndex: number) => {
    let updatedStartup: any = startup;
    switch (stepIndex) {
      case StepperStartupEnum.EntityDetail:
        updatedStartup = { ...updatedStartup, ["EntityDetail"]: object };
        setStartup(updatedStartup);
        break;

      case StepperStartupEnum.StartupDetail:
        updatedStartup = { ...updatedStartup, ["StartupDetail"]: object };
        setStartup(updatedStartup);
        break;

      case StepperStartupEnum.AuthorizedRepresentative:
        updatedStartup = {
          ...updatedStartup,
          ["AuthorizedRepresentative"]: object,
        };
        setStartup(updatedStartup);
        break;

      case StepperStartupEnum.StartupTeam:
        updatedStartup = { ...updatedStartup, ["StartupTeam"]: object };
        setStartup(updatedStartup);
        break;

      case StepperStartupEnum.FundingDetails:
        updatedStartup = { ...updatedStartup, ["FundingDetails"]: object };
        setStartup(updatedStartup);
        break;

      case StepperStartupEnum.IncubatorPreference:
        updatedStartup = { ...updatedStartup, ["IncubatorPreference"]: object };
        setStartup(updatedStartup);
        break;

      case StepperStartupEnum.UploadDocuments:
        updatedStartup = { ...updatedStartup, ["UploadDocuments"]: object };
        setStartup(updatedStartup);
        break;
    }

    delete updatedStartup["updatedStartup"];

    // delete updatedIncubator["updatedIncubator"];

    // console.log(updatedIncubator, "onChildUpdate");
    !isValidForm && triggerValidation(updatedStartup);
  };

  const getAllIncubators = (id: string) => {
    // console.log("Application", startup.);
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    var payload = {
      logged_user_id: userInfo.id
    }
    post(`${STARTUP_GET_INCUBATORS}/${id}/incubators`, payload).then((response: any) => {

      if (!response || !response?.data) {
        return;
      }

      // console.log("response", response);
      if (response?.data?.result?.status != false) {
        const preferenceList = response?.data?.result?.data?.map(
          (item: any) => ({
            id: item?.user_id,
            registered_name: item?.registered_name,
            incubator_name: item?.incubator_name,
            incubator_preference_name: item?.incubator_preference_name,
          })
        );
        // const preferenceList: any = [
        //     // {
        //     //     id: 1,
        //     //     registered_name: "Test1",
        //     //     incubator_name: "incubatorname1"
        //     // },
        //     // {
        //     //     id: 2,
        //     //     registered_name: "Test2",
        //     //     incubator_name: "incubatorname2"
        //     // },
        //     // {
        //     //     id: 3,
        //     //     registered_name: "Test3",
        //     //     incubator_name: "incubatorname3"
        //     // },
        //     // {
        //     //     id: 4,
        //     //     registered_name: "Test4",
        //     //     incubator_name: "incubatorname4"
        //     // }
        // ]
        setIncubatorPreferenceListMain(preferenceList);
      }
    });
  };

  // triggers when user clicks on 'save' button of any step with no form error
  const onSave = (object: any, stepIndex: number, request: number) => {
    let data = { ...startup };
    switch (stepIndex) {
      case StepperStartupEnum.EntityDetail:
        data.EntityDetail = object as EntityDetailModel;
        break;

      case StepperStartupEnum.StartupDetail:
        data.StartupDetail = object as StartupDetailModel;
        break;

      case StepperStartupEnum.AuthorizedRepresentative:
        data.AuthorizedRepresentative =
          object as AuthorizedRepresentativeModel;
        break;

      case StepperStartupEnum.StartupTeam:
        data.StartupTeam = object as StartupTeamModel;
        break;

      case StepperStartupEnum.FundingDetails:
        data.FundingDetails = object as FundingDetailsModel;
        break;

      case StepperStartupEnum.IncubatorPreference:
        data.IncubatorPreference = object as IncubatorPreferenceModel;
        break;

      case StepperStartupEnum.UploadDocuments:
        data.UploadDocuments = object as UploadDocumentsModel;
        break;
    }

    // console.log("data", data);

    // stop submit if in step 2 startup details is technology startup is selected NO
    if (
      request === requestType.submit &&
      data.StartupDetail.is_technology_startup === "No"
    ) {
      setStopSubmitStartupDetails(true);
      data.UploadDocuments.action = "";
      return;
    }
    setStopSubmitStartupDetails(false);

    // stop submit if in step 5 funding details received monetary support under scheme is selected YES
    if (
      request === requestType.submit &&
      data.FundingDetails.received_monetary_support_under_scheme ===
      "1"
    ) {
      setStopSubmitFundingDetails(true);
      data.UploadDocuments.action = "";
      return;
    }
    setStopSubmitFundingDetails(false);

    // stop submit if registration number is not found
    if (
      request === requestType.submit &&
      data.EntityDetail.recognition_number === ""
    ) {
      setStopSubmitEntityDetails(true);
      data.UploadDocuments.action = "";
      return;
    }
    setStopSubmitEntityDetails(false);

    // stop submit on first submit of form, trigger validation on incorporation date
    if (
      request === requestType.submit &&
      startupStatusId === 1 &&
      !compareDate(new Date(startup.EntityDetail.registration_date)) &&
      (startup.EntityDetail.registration_date != "" || startup.EntityDetail.registration_date != null)
    ) {
      setStopSubmitIncorporationDate(true);
      data.UploadDocuments.action = "";
      return;
    }
    setStopSubmitIncorporationDate(false);

    // stop submit on quantum of funds less than 10
    if (
      request === requestType.submit &&
      startup.FundingDetails.quantum_funds_required < 10
    ) {
      setStopSubmitSubmitQuantum_Funds(true);
      data.UploadDocuments.action = "";
      return;
    }
    setStopSubmitSubmitQuantum_Funds(false);
    // stop submit on quantum of funds more than 20 lakhs
    if (
      request === requestType.submit &&
      startup.FundingDetails.instrument_applying_for == "funding_grant" &&
      startup.FundingDetails.quantum_funds_required > 2000000
    ) {
      setStopSubmitSubmitQuantumFunds(true);
      data.UploadDocuments.action = "";
      return;
    }
    setStopSubmitSubmitQuantumFunds(false);

    // stop submit on quantum of funds more than 50 lakhs if debt
    if (
      request === requestType.submit &&
      startup.FundingDetails.instrument_applying_for == "funding_debt" &&
      startup.FundingDetails.quantum_funds_required > 5000000
    ) {
      setStopSubmitDebt(true);
      data.UploadDocuments.action = "";
      return;
    }
    setStopSubmitDebt(false);

    // stop submit on quantum of funds more than 50 lakhs if debt
    if (
      request === requestType.submit &&
      startup.FundingDetails.instrument_applying_for == "funding_debenture" &&
      startup.FundingDetails.quantum_funds_required > 5000000
    ) {
      setStopSubmitDebt(true);
      data.UploadDocuments.action = "";
      return;
    }
    setStopSubmitDebt(false);

    if (data.UploadDocuments.action === "submit") {
      if (triggerValidation(data)) {
        // console.log(
        //   "triggerValidation(data)",
        //   triggerValidation(data)
        // );
        data.UploadDocuments.action = "";
        isValidForm = false;
        // alert("error found")
        return;
      } else {
        // alert("submitted")
        // setShowConfirmationBox(true);
        // return;
      }
    }

    setLoader(true);
    // setDisabled(true);

    // convert all the models into api request model
    // if(data.UploadDocuments.action === "submit"){
    //     setShowConfirmationBox(true)
    // }
    // else{
    if (props.location.state.closed_id == 8) {
      setLoader(false);

      return
    } else {
      const user: any = getSessionStorageValue("user");
      const userInfo = JSON.parse(user)
      var payload = {
        "logged_user_id": userInfo.id
      }
      post(STORE_STARTUP_APPLICATION_SAVE, { data, "logged_user_id": userInfo.id }).then((response: any) => {
        if (!response || !response.data) {
          return;
        }

        setLoader(false);
        // setDisabled(false);
        // console.log("API response", response);

        if (response.data.result === false) {
          setErrorMessage(response.data.message);
          data.UploadDocuments.action = "";
          // setShowFailureMessage(true);
          return;
        }

        if (response.data.application_id != 0) {
          startup.application_id = response.data.application_id;
          setStartup(startup);
        }

        if (response.data.data.FundingDetails.instrument_applying_for != "") {
          if (response.data.application_id != "") {
            getAllIncubators(response.data.application_id);
          }
        }

        if (response.data.result === "success") {
          // show success message
          if (
            data.UploadDocuments.action &&
            data.UploadDocuments.action == "submit"
          ) {
            setShowStartupSuccessModal(true);
          } else {
            // show error message
            if (
              data.UploadDocuments.action &&
              data.UploadDocuments.action == ""
            ) {
              setShowStartupErrorModal(true);
            }
          }
        }
      });
    }

    stepper && stepper.next();
  };

  // const finalSubmit = () => {
  //     setLoader(true);
  //     setDisabled(true);
  //     post(STORE_STARTUP_APPLICATION, startup).then(
  //         (response: any) => {
  //             if (!response || !response.data) {
  //                 return;
  //             }

  //             setLoader(false);
  //             setDisabled(false);
  //             console.log("API response", response);

  //             if (response.data.application_id != 0) {
  //                 startup.application_id = response.data.application_id;
  //                 setStartup(startup);
  //             }

  //             if (response.data.data.FundingDetails.instrument_applying_for != "") {
  //                 if (response.data.application_id != "") {
  //                     getAllIncubators(response.data.application_id);
  //                 }
  //             }

  //             if (response.data.result === "success") {
  //                 // show success message
  //                 if (startup.UploadDocuments.action &&
  //                     startup.UploadDocuments.action == "submit"
  //                 ) {
  //                     setShowStartupSuccessModal(true);
  //                 }
  //                 else {
  //                     // show error message
  //                     if (startup.UploadDocuments.action &&
  //                         startup.UploadDocuments.action == "") {
  //                         setShowStartupErrorModal(true);
  //                     }
  //                 }
  //             }

  //         }
  //     );
  // }

  // triggers the validation
  const triggerValidation = (startup: StartupModel) => {
    const requiredFields: IRequireField = {
      EntityDetail: ["registration_date", "pan_number"],
      StartupDetail: [
        "is_technology_startup",
        "solving_problem_for",
        "value_proposition_for_problem",
        "unique_selling_point",
        "target_customer_segment",
        "market_size_opportunity",
        "aim_to_scale_up",
        "revenue_model",
        "website_url",
        "competitors_startup_enable",
        // "key_competitors",
        "competitors_startup_desc",
        "competitors_msme_desc",
        "competitors_corporate_desc",
        "competitors_other_desc",
        "facebook_link",
        "linkedin_link",
        "twitter_link",
        "youtube_link",
        "other_link",
      ],
      AuthorizedRepresentative: [
        "representative_firstname",
        "representative_lastname",
        "representative_designation",
        "representative_mobile_no",
        "representative_email",
        "authorisation_letter_upload",
      ],
      StartupTeam: [
        "ceo_firstname",
        "ceo_lastname",
        "ceo_description",
        "ceo_linkedin_link",
        "fulltime_employees_no",
        "team_data",
        "promoters_aadhar_cards"
      ],
      FundingDetails: [
        "received_monetary_support_under_scheme",
        "instrument_applying_for",
        "quantum_funds_required",
        "fund_expense_data",
        // "expense_bucket",
        // "expense_amount"
      ],
      IncubatorPreference: ["incubator_preference_1"],
      UploadDocuments: ["upload_pitch_deck", "video_url_for_product"],
    };
    if (incubatorPreferenceListMain.length > 1) {
      requiredFields.IncubatorPreference.push("incubator_preference_2");
    }
    if (incubatorPreferenceListMain.length > 2) {
      requiredFields.IncubatorPreference.push("incubator_preference_3");
    }
    const nullFields = ["registration_date"];

    const validationHelper = new ValidationHelper(
      startup,
      requiredFields,
      nullFields
    );
    const errors = validationHelper.triggerValidation();
    dispatch({
      type: Action.SET_ERROR,
      payload: errors,
    });

    // return Object.keys(errors).length > 0;
    const isValid = Object.keys(errors).map((key: string) =>
      Object.keys(errors[key]).some((key1) => errors[key][key1].length > 0)
    );
    // console.log(isValid);
    return isValid.some((valid: boolean) => valid === true);
  };

  // trigers when clicked on previous button
  const onPreviousClick = () => {
    stepper && stepper.previous();
  };

  return (
    <>
      <div className="app-inside app-incubator">
        <div className="container-fluid about-heading app-banner-inside">
          <div className="text-center main-heading">Startup Application</div>

          <div className="text-center sub-heading">
            All form fields are <b>mandatory</b>, unless mentioned as{" "}
            <b>‘optional’</b>
            <br />
            Application form to be filled in English language
          </div>
        </div>

        <div className="container stepper-container">
          <div className="app-inside-content">
            <div className="row justify-content-center">
              <div className="col-xs-12 col-sm-12">
                <div id="startupstepper" className="bs-stepper">
                  {/* Incubator For Stepper Steps Header */}
                  <StepperHeaderStartupComponent
                    errors={null}
                    onClick={(currentStep: number) => { }}
                  />

                  {/* Incubator For Stepper Steps Content */}
                  <div className="bs-stepper-content">
                    {showStartupSuccessModal && (
                      // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
                      <>
                        <div className="modal-backdrop fade show"></div>

                        <div
                          className="modal align-middle modal-sisfs d-block"
                          id="FormSubmissionSuccess"
                        >
                          <div className="modal-dialog modal-dialog-centered modal-md">
                            <div className="modal-content">
                              <div className="modal-body text-center">
                                <i className="fa fa-check-circle-o"></i>
                                <h3 className="modal-message">Thank You!</h3>
                                <div className="modal-text">
                                  Your application was successfully submitted.
                                  We’ll be in touch with you, soon!
                                </div>
                                <div className="modal-action">
                                  <button className="btn btn-sisfs btn-yellow-primary">
                                    <Link
                                      to={{
                                        pathname: "startup_status",
                                        state: { perform_reload: "true" },
                                      }}
                                    >
                                      Continue
                                    </Link>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {/* {showConfirmationBox && (
                                            <>
                                                <div className="modal-backdrop fade show"></div>
                                                <div
                                                    className="modal align-middle modal-sisfs d-block"
                                                    id="FormSubmissionError"
                                                >
                                                    <div className="modal-dialog modal-dialog-centered modal-md">
                                                        <div className="modal-content">
                                                            <div className="modal-body text-center">
                                                              
                                                                <h3 className="modal-message mt-2">
                                                                </h3>
                                                                <h3 className="modal-text">
                                                                    Please review your application before submission. Once submitted your application cannot be modified.
                                                                </h3>
                                                                <div className="modal-action mt-2">
                                                                    <button className="btn btn-sisfs btn-transparent-primary" onClick={() => setShowConfirmationBox(false)}>
                                                                        Cancel
                                                                    </button>
                                                                    <button className="btn btn-sisfs btn-yellow-primary ml-2" onClick={() => finalSubmit()}>
                                                                        Yes
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )} */}


                    {showStartupErrorModal && (
                      // <FormSubmissionError data-toggle="modal" data-target="#FormSubmissionError"/>
                      <>
                        <div className="modal-backdrop fade show"></div>
                        <div
                          className="modal align-middle modal-sisfs d-block"
                          id="FormSubmissionError"
                        >
                          <div className="modal-dialog modal-dialog-centered modal-md">
                            <div className="modal-content">
                              <div className="modal-body text-center">
                                <i className="fa fa-times-circle-o"></i>
                                <h3 className="modal-message">
                                  Oops! Something is Wrong
                                </h3>
                                <div className="modal-text">
                                  <ul>
                                    <li>
                                      {" "}
                                      You have either missed out on some
                                      mandatory fields{" "}
                                    </li>
                                    <li>Entered the data wrongly</li>
                                  </ul>
                                </div>
                                <div className="modal-action">
                                  <button className="btn btn-sisfs btn-yellow-primary">
                                    Ok Got It!
                                    {/* <Link to="/incubator">Ok Got It!</Link> */}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {loader && (
                      <div
                        className="spinner-border custom-spinner"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                    <fieldset>
                      <div
                        id="startup-step-1"
                        role="tabpanel"
                        className="bs-stepper-pane content"
                        aria-labelledby="startup-step1"
                      >
                        {/* {console.log(startup,"aplicationsID")} */}
                        <div className="form-wrapper stepper-form stepper-form-1">
                          <AuthorizedRepresentativeComponent
                            stepper={stepper}
                            applicationID={startup.application_id}
                            authorizedRepresentativeModel={
                              startup.AuthorizedRepresentative
                            }
                            tabIndex={1}
                            onChildUpdate={(
                              AuthorizedRepresentative: AuthorizedRepresentativeModel
                            ) =>
                              onChildUpdate(
                                AuthorizedRepresentative,
                                StepperStartupEnum.AuthorizedRepresentative
                              )
                            }
                            onUpdateApplicationID={(application_id: any) =>
                              updateApplicationID(application_id)
                            }
                            onStepSave={onSave}
                            onPreviousClick={onPreviousClick}
                            errors={[]}
                            disabledForm={disabled}
                          />
                        </div>
                      </div>

                      <div
                        id="startup-step-2"
                        role="tabpanel"
                        className="bs-stepper-pane content"
                        aria-labelledby="startup-step2"
                      >
                        <div className="form-wrapper stepper-form stepper-form-2">
                          <EntityDetailsComponent
                            stepper={stepper}
                            entityDetailModel={startup.EntityDetail}
                            tabIndex={2}
                            onChildUpdate={(EntityDetail: EntityDetailModel) =>
                              onChildUpdate(
                                EntityDetail,
                                StepperStartupEnum.EntityDetail
                              )
                            }
                            onStepSave={onSave}
                            onPreviousClick={onPreviousClick}
                            errors={[]}
                            apiCalled={apiCalled}
                            disabledForm={disabled}
                          />
                        </div>
                      </div>

                      <div
                        id="startup-step-3"
                        role="tabpanel"
                        className="bs-stepper-pane content"
                        aria-labelledby="startup-step3"
                      >
                        <div className="form-wrapper stepper-form stepper-form-3">
                          <StartupDetailsComponent
                            stepper={stepper}
                            startupDetailModel={startup.StartupDetail}
                            tabIndex={3}
                            onChildUpdate={(
                              StartupDetail: StartupDetailModel
                            ) =>
                              onChildUpdate(
                                StartupDetail,
                                StepperStartupEnum.StartupDetail
                              )
                            }
                            onStepSave={onSave}
                            onPreviousClick={onPreviousClick}
                            errors={[]}
                            disabledForm={disabled}
                          />
                        </div>
                      </div>

                      <div
                        id="startup-step-4"
                        role="tabpanel"
                        className="bs-stepper-pane content"
                        aria-labelledby="startup-step4"
                      >
                        <div className="form-wrapper stepper-form stepper-form-4">
                          <StartupTeamComponent
                            stepper={stepper}
                            applicationID={startup.application_id}
                            startupTeamModel={startup.StartupTeam}
                            tabIndex={4}
                            onChildUpdate={(StartupTeam: StartupTeamModel) =>
                              onChildUpdate(
                                StartupTeam,
                                StepperStartupEnum.StartupTeam
                              )
                            }
                            onStepSave={onSave}
                            onPreviousClick={onPreviousClick}
                            errors={[]}
                            apiCalled={apiCalled}
                            disabledForm={disabled}
                          />
                        </div>
                      </div>

                      <div
                        id="startup-step-5"
                        role="tabpanel"
                        className="bs-stepper-pane content"
                        aria-labelledby="startup-step5"
                      >
                        <div className="form-wrapper stepper-form stepper-form-5">
                          <StartupFundingDetailsComponent
                            stepper={stepper}
                            fundingDetailsModel={startup.FundingDetails}
                            tabIndex={5}
                            onChildUpdate={(
                              FundingDetails: FundingDetailsModel
                            ) =>
                              onChildUpdate(
                                FundingDetails,
                                StepperStartupEnum.FundingDetails
                              )
                            }
                            onStepSave={onSave}
                            onPreviousClick={onPreviousClick}
                            errors={[]}
                            eligibilityCriteriaEnable={eligibilityCriteria}
                            apiCalled={apiCalled}
                            isDisabled={isDisabledTab}
                            disabledForm={disabled}
                          // isDisabled={false}
                          />
                        </div>
                      </div>
                      {/* {console.log(startup.IncubatorPreference,"1538888")} */}
                      <div
                        id="startup-step-6"
                        role="tabpanel"
                        className="bs-stepper-pane content"
                        aria-labelledby="startup-step6"
                      >
                        <div className="form-wrapper stepper-form stepper-form-6">
                          <IncubatorPreferenceComponent
                            stepper={stepper}
                            incubatorPreferenceModel={
                              startup.IncubatorPreference
                            }
                            tabIndex={6}
                            onChildUpdate={(
                              IncubatorPreference: IncubatorPreferenceModel
                            ) =>
                              onChildUpdate(
                                IncubatorPreference,
                                StepperStartupEnum.IncubatorPreference
                              )
                            }
                            onStepSave={onSave}
                            onPreviousClick={onPreviousClick}
                            errors={[]}
                            applicationId={startup.application_id}
                            apiCalled={apiCalled}
                            allowInstrument={
                              startup.FundingDetails.instrument_applying_for
                            }
                            incubatorList={incubatorPreferenceListMain}
                            isDisabled={isDisabledTab}
                          //  isDisabled={false}
                          />
                        </div>
                      </div>

                      <div
                        id="startup-step-7"
                        role="tabpanel"
                        className="bs-stepper-pane content"
                        aria-labelledby="startup-step7"
                      >
                        {/* {console.log(startup.application_id,"application")} */}
                        <div className="form-wrapper stepper-form stepper-form-7">
                          <UploadDocumentsComponent
                            stepper={stepper}
                            uploadDocumentsModel={startup.UploadDocuments}
                            tabIndex={7}
                            onChildUpdate={(
                              UploadDocuments: UploadDocumentsModel
                            ) =>
                              onChildUpdate(
                                UploadDocuments,
                                StepperStartupEnum.UploadDocuments
                              )
                            }
                            onStepSave={onSave}
                            onPreviousClick={onPreviousClick}
                            errors={[]}
                            applicationId={startup.application_id}
                            apiCalled={apiCalled}
                            disabledForm={disabled}
                          />
                        </div>
                      </div>
                    </fieldset>

                    {/* {showStartupErrorModal && (
                      // <FormSubmissionError data-toggle="modal" data-target="#FormSubmissionError"/>
                      <>
                        <div className="modal-backdrop fade show"></div>
                        <div
                          className="modal align-middle modal-sisfs d-block"
                          id="FormSubmissionError"
                        >
                          <div className="modal-dialog modal-dialog-centered modal-md">
                            <div className="modal-content">
                              <div className="modal-body text-center">
                                <i className="fa fa-times-circle-o"></i>
                                <h3 className="modal-message">
                                  Oops! Something is Wrong
                                </h3>
                                <div className="modal-text">
                                  <ul>
                                    <li>
                                      {" "}
                                      You have either missed out on some
                                      mandatory fields{" "}
                                    </li>
                                    <li>Entered the data wrongly</li>
                                  </ul>
                                </div>
                                <div className="modal-action">
                                  <button className="btn btn-sisfs btn-yellow-primary">
                                    Ok Got It!
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )} */}

                    {/* {loader && (
                      <div
                        className="spinner-border custom-spinner"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    )} */}
                    {/* <fieldset disabled={loader}>
                      <div
                        id="startup-step-1"
                        role="tabpanel"
                        className="bs-stepper-pane content"
                        aria-labelledby="startup-step1"
                      >
                        <div className="form-wrapper stepper-form stepper-form-1">
                          <AuthorizedRepresentativeComponent
                            stepper={stepper}
                            applicationID={startup.application_id}
                            authorizedRepresentativeModel={
                              startup.AuthorizedRepresentative
                            }
                            tabIndex={1}
                            onChildUpdate={(
                              AuthorizedRepresentative: AuthorizedRepresentativeModel
                            ) =>
                              onChildUpdate(
                                AuthorizedRepresentative,
                                StepperStartupEnum.AuthorizedRepresentative
                              )
                            }
                            onUpdateApplicationID={(application_id: any) =>
                              updateApplicationID(application_id)
                            }
                            onStepSave={onSave}
                            onPreviousClick={onPreviousClick}
                            errors={[]}
                          />
                        </div>
                      </div>

                      <div
                        id="startup-step-2"
                        role="tabpanel"
                        className="bs-stepper-pane content"
                        aria-labelledby="startup-step2"
                      >
                        <div className="form-wrapper stepper-form stepper-form-2">
                          <EntityDetailsComponent
                            stepper={stepper}
                            entityDetailModel={startup.EntityDetail}
                            tabIndex={2}
                            onChildUpdate={(EntityDetail: EntityDetailModel) =>
                              onChildUpdate(
                                EntityDetail,
                                StepperStartupEnum.EntityDetail
                              )
                            }
                            onStepSave={onSave}
                            onPreviousClick={onPreviousClick}
                            errors={[]}
                            apiCalled={apiCalled}
                          />
                        </div>
                      </div>

                      <div
                        id="startup-step-3"
                        role="tabpanel"
                        className="bs-stepper-pane content"
                        aria-labelledby="startup-step3"
                      >
                        <div className="form-wrapper stepper-form stepper-form-3">
                          <StartupDetailsComponent
                            stepper={stepper}
                            startupDetailModel={startup.StartupDetail}
                            tabIndex={3}
                            onChildUpdate={(
                              StartupDetail: StartupDetailModel
                            ) =>
                              onChildUpdate(
                                StartupDetail,
                                StepperStartupEnum.StartupDetail
                              )
                            }
                            onStepSave={onSave}
                            onPreviousClick={onPreviousClick}
                            errors={[]}
                          />
                        </div>
                      </div>

                      <div
                        id="startup-step-4"
                        role="tabpanel"
                        className="bs-stepper-pane content"
                        aria-labelledby="startup-step4"
                      >
                        <div className="form-wrapper stepper-form stepper-form-4">
                          <StartupTeamComponent
                            stepper={stepper}
                            applicationID={startup.application_id}
                            startupTeamModel={startup.StartupTeam}
                            tabIndex={4}
                            onChildUpdate={(StartupTeam: StartupTeamModel) =>
                              onChildUpdate(
                                StartupTeam,
                                StepperStartupEnum.StartupTeam
                              )
                            }
                            onStepSave={onSave}
                            onPreviousClick={onPreviousClick}
                            errors={[]}
                            apiCalled={apiCalled}
                          />
                        </div>
                      </div>

                      <div
                        id="startup-step-5"
                        role="tabpanel"
                        className="bs-stepper-pane content"
                        aria-labelledby="startup-step5"
                      >
                        <div className="form-wrapper stepper-form stepper-form-5">
                          <StartupFundingDetailsComponent
                            stepper={stepper}
                            fundingDetailsModel={startup.FundingDetails}
                            tabIndex={5}
                            onChildUpdate={(
                              FundingDetails: FundingDetailsModel
                            ) =>
                              onChildUpdate(
                                FundingDetails,
                                StepperStartupEnum.FundingDetails
                              )
                            }
                            onStepSave={onSave}
                            onPreviousClick={onPreviousClick}
                            errors={[]}
                            eligibilityCriteriaEnable={eligibilityCriteria}
                            apiCalled={apiCalled}
                            isDisabled={isDisabledTab}
                            // isDisabled={false}
                          />
                        </div>
                      </div>

                      <div
                        id="startup-step-6"
                        role="tabpanel"
                        className="bs-stepper-pane content"
                        aria-labelledby="startup-step6"
                      >
                        <div className="form-wrapper stepper-form stepper-form-6">
                          <IncubatorPreferenceComponent
                            stepper={stepper}
                            incubatorPreferenceModel={
                              startup.IncubatorPreference
                            }
                            tabIndex={6}
                            onChildUpdate={(
                              IncubatorPreference: IncubatorPreferenceModel
                            ) =>
                              onChildUpdate(
                                IncubatorPreference,
                                StepperStartupEnum.IncubatorPreference
                              )
                            }
                            onStepSave={onSave}
                            onPreviousClick={onPreviousClick}
                            errors={[]}
                            applicationId={startup.application_id}
                            apiCalled={apiCalled}
                            allowInstrument={
                              startup.FundingDetails.instrument_applying_for
                            }
                            incubatorList={incubatorPreferenceListMain}
                            isDisabled={isDisabledTab}
                            // isDisabled={false}
                          />
                        </div>
                      </div>

                      <div
                        id="startup-step-7"
                        role="tabpanel"
                        className="bs-stepper-pane content"
                        aria-labelledby="startup-step7"
                      >
                        <div className="form-wrapper stepper-form stepper-form-7">
                          <UploadDocumentsComponent
                            stepper={stepper}
                            uploadDocumentsModel={startup.UploadDocuments}
                            tabIndex={7}
                            onChildUpdate={(
                              UploadDocuments: UploadDocumentsModel
                            ) =>
                              onChildUpdate(
                                UploadDocuments,
                                StepperStartupEnum.UploadDocuments
                              )
                            }
                            onStepSave={onSave}
                            onPreviousClick={onPreviousClick}
                            errors={[]}
                            applicationId={startup.application_id}
                            apiCalled={apiCalled}
                          />
                        </div>
                      </div>
                    </fieldset> */}

                    {stopSubmitStartupDetails && (
                      <ConfirmationDialog
                        headerText=""
                        bodyText="Startups should be using technology in it’s core product or
                                                service or business model or distribution model or methodology
                                                to solve the problem being targetted"
                        onDialogClose={() => setStopSubmitStartupDetails(false)}
                        iconClass="fa fa-times-circle-o"
                      />
                    )}

                    {stopSubmitQuantumFunds && (
                      <ConfirmationDialog
                        headerText=""
                        bodyText="Quantum of funds required is Incorrect. Kindly fill the correct quantum of funds required."
                        onDialogClose={() =>
                          setStopSubmitSubmitQuantumFunds(false)
                        }
                        iconClass="fa fa-times-circle-o"
                      />
                    )}
                    {stopSubmitQuantum_Funds && (
                      <ConfirmationDialog
                        headerText=""
                        bodyText="Quantum of Funds required should not be less than 10."
                        onDialogClose={() =>
                          setStopSubmitSubmitQuantum_Funds(false)
                        }
                        iconClass="fa fa-times-circle-o"
                      />
                    )}

                    {stopSubmitDebt && (
                      <ConfirmationDialog
                        headerText=""
                        bodyText="Quantum of Funds required should not be more than 50 Lakhs if applying for Debt or Debenture"
                        onDialogClose={() => setStopSubmitDebt(false)}
                        iconClass="fa fa-times-circle-o"
                      />
                    )}

                    {stopSubmitFundingDetails && (
                      <ConfirmationDialog
                        headerText=""
                        bodyText="Startup should not have received more than Rs 10 lakh of monetary support under any other Central or State Government scheme. This does not include prize money from competitions and grand challenges, subsidized working space, founder monthly allowance, access to labs, or access to prototyping facility"
                        onDialogClose={() => setStopSubmitFundingDetails(false)}
                        iconClass="fa fa-times-circle-o"
                      />
                    )}
                    {stopSubmitIncorporationDate && (
                      <ConfirmationDialog
                        headerText=""
                        bodyText="Date of application should not be more than 2 years from incorporation Or Date of application is missing from Entity Details"
                        onDialogClose={() =>
                          setStopSubmitIncorporationDate(false)
                        }
                        iconClass="fa fa-times-circle-o"
                      />
                    )}
                    {stopSubmitEntityDetails && (
                      <ConfirmationDialog
                        headerText=""
                        bodyText="DPIIT Recognition Number is mandatory."
                        onDialogClose={() => setStopSubmitEntityDetails(false)}
                        iconClass="fa fa-times-circle-o"
                      />
                    )}
                    {showFailureMessage && (
                      <ConfirmationDialog
                        headerText={""}
                        bodyText={errorMessage}
                        onDialogClose={() => setShowFailureMessage(false)}
                        iconClass="test"
                      />
                    )}
                  </div>

                  {/* <div className="stepper-footer-section">
                                        <div
                                            className="btn-toolbar justify-content-between"
                                            role="toolbar"
                                            aria-label="Toolbar with button groups"
                                        >
                                            <button className="btn btn-sisfs btn-transparent-primary">
                                                Cancel
                                            </button>
                                            <span className="stepper-page-count">Step {props.tabIndex} / 7 </span>
                                            <div className="btn-group">
                                                {props.tabIndex != 1 && (
                                                    <button
                                                        className="btn btn-sisfs btn-transparent-primary"
                                                        onClick={() => onPreviousClick()}
                                                    >
                                                        Previous
                                                    </button>
                                                )}
                                                <button
                                                    type="button"
                                                    className="btn btn-sisfs btn-yellow-primary"
                                                    onClick={() => onSave()}
                                                >
                                                    {props.tabIndex == 7 ? "Save" : "Save And Next"}
                                                </button>
                                                {props.tabIndex == 7 && (
                                                    <button
                                                        className="btn btn-sisfs btn-yellow-primary"
                                                    // onClick={() => props.onSubmit}                                                   
                                                    >
                                                        Review & Submit
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StartupContainer;
