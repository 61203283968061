import '../../incubator/incubator.css';
import Plus from '../../../images/Plus.svg';
import Minus from '../../../images/Minus.svg';
import React, { useEffect, useState, useRef } from 'react';
// import pdf from '../../../images/PDF.svg';
import pdf from '../../../../images/PDF.svg'
import xls from '../../../../images/xls.svg';
import doc from '../../../../images/doc.svg';
import xlsx from '../../../../images/xls.svg';
import TrancheStepperComponent from '../TrancheStepperComponent';
import Stepper from 'bs-stepper';
import 'bs-stepper/dist/css/bs-stepper.min.css';
import {
  AddTranche,
  Documents,
  TrancheDetail,
} from '../../../../models/funddetails/TrancheDetails';
import {
  ADD_MORE_TRANCHE,
  ADD_TRANCHE_WITH_AMOUNT,
  ADD_TRANCHE_WITH_Re_AMOUNT,
  BASE_URL,
  GET_ALL_TRANCHES,
  GET_BANK_DETAILS,
  GET_BANK_DETAILS_REAPPLY_NODE,
  GET_INCUBATOR_DETAILS,
  GET_TRANCHE_REQUEST,
  NODE_URL,
  QUICK_EDIT,
  QUICK_Re_EDIT,
  File_base_url,
  File_node_url,
  TRANCHES_EDIT,
  DELETE_REAPPLY_TRANCHE,
  DELETE_TRANCHE
} from '../../../../constants/api';
import { get, post } from '../../../../effects/api/api.common';
import TrancheInfoComponent from '../../../incubator/common/TrancheInfoComponent';
import TrancheWiseDetails from '../../../incubator/common/TrancheWiseDetails';
import { UploadTypeEnum } from '../../../../utils/Enums';
import { changeNumberFormat, createFile } from '../../../../utils/CommonMethods';
import { FileDownloadModel } from '../ApplicationGeneralDetails';
import AccountDetailsComponent from '../../../incubator/dashboard/AccountDetailsComponent';
import { AccountDetailsData } from '../../../../models/funddetails/AccountDetailsData';
import { getSessionStorageValue } from '../../../../effects/utils/session-storage';
import ReapplyTrancheDetailsComponent from '../../../incubator/dashboard/ReapplyTrancheDetailsComponent';
import ReapplyAccountDetailsComponent from '../../../incubator/dashboard/ReapplyAccountDetailsComponent';
import MergedTrancheDetailsComponent from './MergedTrancheDetailsComponent';
import MergedTrancheInfoComponent from './MergedTrancheInfoComponent';
import MergedTrancheWiseDetails from './MergedTrancheWiseDetails';
import MergedFirstTrancheWiseDetails from './MergedFirstTrancheWiseDetails';
import MergedFirstTrancheInfoComponent from './MergedFirstTrancheInfoComponent';
import ConfirmationDialogNote from '../../../common/controls/ConfirmationDialogNote';
import Allround from '../../../../images/Allround.svg';
declare var $:any;
interface IFundDetailsProps {
  application_id: number;
  total_grant_approved: any;
  trancheData: any;
  trancheDataFirst: any;
  trancheDataASC: any;
  accountDetailsData: AccountDetailsData;
  application_status: string;
  total_grant_disbursed: any;
  totalManagementAmount: any;
  leftSideData: any;
  available_balance: any;
  approved_return: any;
  amountDisbursedToInc: any;
  amountDisbursedToStartup: any;
  // FundsData:any

}

function MergedFundDetailsComponent(props: any) {
  const {
    application_id,
    total_grant_approved,
    trancheData,
    trancheDataFirst,
    trancheDataASC,
    accountDetailsData1,
    subsequentDetails,
    accountDetailsData2,
    application_status,
    total_grant_disbursed,
    Total_Disbursed_to_Startups,
    totalManagementAmount,
    total_no_tranches,
    leftSideData,
    available_balance,
    approved_return,
    amountDisbursedToInc,
    amountDisbursedToStartup
  } = props;

  //  //console.log("***********",accountDetailsData1);

  const [isTrancheStepper, setStepper] = useState<boolean>(true);
  const [isEditing, setIsEditing] = useState(false);

  const [activeIndex, setActiveIndex] = useState('');
  const [ReapplyactiveIndex, setReapplyActiveIndex] = useState('3');
  const [ReapplytrancheData, setReapplyTrancheData] = useState<Array<TrancheDetail>>([
    new TrancheDetail(
      0,
      0,
      '',
      0,
      0,
      '',
      '',
      new Documents([], [], []),
      '',
      0,
      0,
      '',
      '',
      '',
      '',
      '',
      '', false, false, false,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      ''
    ),
  ])
  const changeActive = (id: string) => {
    setActiveIndex(id);
  };
  const changeActiveBack = () => {
    setActiveIndex('');
  };
  const [loggedInRole, setLoggedInRole] = useState('');
  const [ReaapplyInclistData, SetReaapplyInclistData] = useState('');
  const ReapplychangeActive = (id: string) => {
    setReapplyActiveIndex(id);
  };
  const [ReapplyAccountDataLength, setReapplyAccountDataLength] = useState("")
  const [ReapplyAccountData, setReapplyAccountData] = useState({
    bank_account_holder_name: "",
    bank_name: "",
    branch_name: "",
    account_type: "",
    account_number: "",
    bank_ifsc_code: "",
    bank_micr_code: "",
    branch_address: "",
    pan_card_location: "",
    cancelled_cheque_location: "",
    darpan_location: "",

  });
  const [total_grand_approved_amount, setGrantApproved] = useState("");
  const [disbursed_to_Startups, setDisbursed_to_Startups] = useState("");


  const [total_Management_approved_amount, setManagementAmount] = useState("");
  const [tranchesno, setTranchesNo] = useState("");

  const user: any = getSessionStorageValue("user");
  const userInfo = JSON.parse(user)


  const [addMoreTrancheData1, setAddMoreTrancheData1] = useState<AddTranche>(
    new AddTranche(0, '', 0, 0)
  );
  const [addMoreTrancheData2, setAddMoreTrancheData2] = useState<AddTranche>(
    new AddTranche(0, '', 0, 0)
  );
  const [isAddTrancheRowVisible, setIsAddTrancheRowVisible] = useState(true);
  const [isAddTrancheRowVisible1, setIsAddTrancheRowVisible1] = useState(true);
  const [showEditSuccessModal, setShowSuccessModal] = useState(false);
  const [reasonPopup, setReasonPopup] = useState(false);
  const [reasonPopup2, setReasonPopup2] = useState(false);
  const [trancheDeleteModal, settrancheDeleteModal] = useState(false);
  const [showEditFailureModal, setShowFailureModal] = useState(false);
  const [checked, setChecked] = useState(false);
  // const [count, setCount] = useState(0);
  const [employeeData, setEmployeeData] = useState<any>([])
  const [employeeData1, setEmployeeData1] = useState<any>([])
  const [showIncubatorFailureModal, setShowIncubatorFailureModal] = useState(false);
  // const [sumOfSecond,setsumOfSecond]=useState(0)
  // const [sumOfFirst,setsumOfFirst]=useState(0)

  const [failDialogData, setFailDialogData] = useState({
    "header": '',
    "text": '',
    "note": ''
  });
  const dismissModal = () => {
    setShowSuccessModal(false);
    setShowFailureModal(false);
    // history.push('/merged_Incubator_Details_list')

    window.location.reload()
  };
  const dismissModal1 = () => {
    // setShowSuccessModal(false);
    setShowFailureModal(false);
    // history.push('/merged_Incubator_Details_list')

    // window.location.reload()
  };

  const [final2, setFinal2] = useState<any>([])
  const [final1, setFinal1] = useState<any>([])

  const [trancheStatusLabel, settrancheStatusLabel] = useState(false)
  const [trancheStatusLabel1, settrancheStatusLabel1] = useState(false)

  const [totalDisbursedAmount1, setTotalDisbursedAmount1] = useState<any>()
  var sum1 = 0;
  const [totalDisbursedAmount2, setTotalDisbursedAmount2] = useState<any>()
  var sum2 = 0;
  const [totalDisbursedMngmnt1, setTotalDisbursedMngmnt1] = useState<any>()
  var sum3 = 0;
  const [totalDisbursedMngmnt2, setTotalDisbursedMngmnt2] = useState<any>()
  var sum4 = 0;
  useEffect(() => {

    {
      trancheData.map((item: any, index: any) => {
        if (index == 0) {
          item.map((newItem: any, index: any) => {
            setFinal2(newItem)
          })
        }
      })
    }
    /////////////////////////////////////////////////

    {
      for (let i = 0; i < trancheDataFirst[0]?.length; i++) {
        if (trancheDataFirst[0][i].tranches_status_label === 'Disbursed') {
          sum1 = sum1 + trancheDataFirst[0][i].fund_released;
          sum3 = sum3 + trancheDataFirst[0][i].Reduced_management_fee;
          setTotalDisbursedMngmnt1(sum3);
          setTotalDisbursedAmount1(sum1);
        }
      }
    }

    {
      for (let i = 0; i < trancheData[0]?.length; i++) {
        if (trancheData[0][i].tranches_status_label === 'Disbursed') {
          sum2 = sum2 + trancheData[0][i].fund_released;
          sum4 = sum4 + trancheDataFirst[0][i].Reduced_management_fee;
          setTotalDisbursedMngmnt2(sum4);
          setTotalDisbursedAmount2(sum2);
        }
      }
    }
    //////////////////////////////////////////////////////
    {
      trancheDataFirst.map((item: any, index: any) => {
        if (index == 0) {
          item.map((newItem: any, index: any) => {
            setFinal1(newItem)
          })
        }
      })
    }

    //console.log(accountDetailsData2,accountDetailsData1,"accountDetailsData12333")
    // ReapplygetAccountDetails()
    setEmployeeData(trancheData[0])
    setEmployeeData1(trancheDataFirst[0])
    //console.log(employeeData,"emp")
    // let sumSecond = employeeData.reduce(function(prev :any, current:any) {
    //   return prev + +current.approved_amount
    // }, 0); 
    // setsumOfSecond(sumSecond)
    // let sumFirst = employeeData1.reduce(function(prev :any, current:any) {
    //   return prev + +current.approved_amount
    // }, 0); 
    // setsumOfFirst(sumFirst)
  }, [total_grand_approved_amount, trancheData, trancheDataFirst, disbursed_to_Startups])
  useEffect(() => {

    setStepper(true);
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    setLoggedInRole(userInfo.roles);
    // getAccountDetails();
    //console.log(total_grant_approved,"total_grant_approvedtotal_grant_approved")
    setGrantApproved(total_grant_approved == null ? "0" : total_grant_approved);
    setDisbursed_to_Startups(Total_Disbursed_to_Startups == null ? "0" : Total_Disbursed_to_Startups);

    //console.log(totalManagementAmount,"totalManagementAmounttotalManagementAmount")
    setManagementAmount((totalManagementAmount == null) ? "0" : totalManagementAmount)
    setTranchesNo(total_no_tranches == null ? "0" : total_no_tranches)
    //console.log(tranchesno,"application_idapplication_id")

  }, [total_grant_approved, Total_Disbursed_to_Startups, total_no_tranches, accountDetailsData1, accountDetailsData2, subsequentDetails]);
  const addMoreTranche2 = () => {
    let addTranche = JSON.parse(JSON.stringify(addMoreTrancheData2));
    //console.log(final2.application_id,"106")
    addTranche['application_id'] = ((final2.application_id == "" || final2.application_id == 0 || final2.application_id == undefined) ? application_id : final2.application_id);
    //console.log(addTranche)
    setAddMoreTrancheData2(addTranche);
    setIsAddTrancheRowVisible(true);

    let sum = employeeData.reduce(function (prev: any, current: any) {
      return prev + +current.approved_amount
    }, 0);

    if (typeof addMoreTrancheData2.amount_approved === "string") {
      addMoreTrancheData2.amount_approved = Number(addMoreTrancheData2.amount_approved)
    }

    if (addMoreTrancheData2.amount_approved == 0) {
      setShowFailureModal(true);
    } else if ((sum + addMoreTrancheData2.amount_approved) > final2.total_grant_amount + final2.total_management_amount) {
      // +final2.total_management_amount
      setFailDialogData({
        "header": "Oops",
        "text": "Can’t create new tranche, approved amount is equal to total grant approved + Total Management Fee",
        "note": "Note : If you still want to create a new tranche please check Total funds Approved & Total Funds Disbursed (If Total Funds Disbursed is lesser than Total funds Approved + Total Management Fee in existing tranches please decrease Total Funds Approved)"
      })
      setShowIncubatorFailureModal(true);
    } else {
      const user: any = getSessionStorageValue("user");
      const userInfo = JSON.parse(user)
      const userId = userInfo.id
      let addMoreTrancheDetails = {
        application_id: ((final2.application_id == "" || final2.application_id == 0 || final2.application_id == undefined) ? application_id : final2.application_id),
        approved_amount: addMoreTrancheData2.amount_approved,
        logged_user_id: userId
      };
      //console.log(final2.id,"final2final2")
      //  return
      post(`${ADD_TRANCHE_WITH_Re_AMOUNT}`, addMoreTrancheDetails).then(
        (response: any) => {
          if (!response || !response.data) return;
          if (response.data.result == 'success') {
            setShowSuccessModal(true);
            //  window.location.reload()

          }
          else {
            // setShowFailureModal(true);
          }
          // //console.log(addMoreTrancheData, 'setaddMoreTrancheData');
          // getAllTranches();
        }
      );
    }
  };
  //2nd Edit button clicked
  const addMoreTranche2Edit = (tranch_id: any, item: any, i: any) => {
    //  //console.log(trancheData[0])
    if (item.tranches_status_label == 'Disbursed') {
      setFailDialogData({
        "header": "Oops",
        "text": "Tranche Status should not be in Disbursed",
        "note": ""
      })
      setShowIncubatorFailureModal(true);
    } else {
      trancheData[0].forEach((element: any, index: any) => {
        if (element.tranch_id == tranch_id) {
          //console.log("into if") 
          element.editchecked = "No"
        }
        else {
          element.editchecked = "Yes"
        }

      });
    }
    // setEmployeeData([...trancheData[0],trancheData[0]])
    setEmployeeData([...trancheData[0], trancheData[0]])
    const newArray = employeeData.splice(0, employeeData.length)
    setEmployeeData(newArray)
    //console.log(employeeData,"emp")

  }
  //2nd Handle change
  const handleChangeEdit2 = (e: any,
    tranch_id: any
  ) => {
    const { name, value } = e.target
    var editData: any = []
    editData = employeeData.map((item: any) =>
      item.tranch_id === tranch_id && name ? { ...item, [name]: value.replace(/[^0-9]/ig, '') } : item
    )
    setEmployeeData(editData)

  };

  const addMoreTranche2Save = (item: any, i: any, fund: any) => {
    setChecked(false)
    let totalfunds = final2.total_grant_amount + final2.total_management_amount
    // let sum = employeeData.reduce(function(prev :any, current:any) {
    //   return prev + +current.fund_released
    // }, 0);   
    let sumApprove = employeeData.reduce(function (prev: any, current: any) {
      return prev + +current.approved_amount
    }, 0);
    approved.current = parseInt(item.approved_amount)
    funds.current = parseInt(item.fund_released)
    //console.log(sumApprove,"^^^^^^")

    if (item.tranches_status_label !== 'Disbursement in Process') {
      setFailDialogData({
        "header": "Oops",
        "text": "Tranche Status should be in Disbursement in Process",
        "note": ""
      })
      setShowIncubatorFailureModal(true);
    }
    // else 
    if (approved.current == 0) {
      setShowFailureModal(true);
    } else if (sumApprove > totalfunds) {
      setFailDialogData({
        "header": "Oops",
        "text": "Entered Amount should be less than or equal to Total Grant Approved + Total Management Fee",
        "note": ""

      })
      setShowIncubatorFailureModal(true);
      return;
    } else if (approved.current < funds.current) {
      setFailDialogData({
        "header": "Oops",
        "text": "Amount Disbursed should be less than or equal to Approved Amount",
        "note": ""

      })
      setShowIncubatorFailureModal(true);
      return;
      // } 
      // else if(sum > totalfunds){
      // setFailDialogData({
      //   "header":"Oops",
      //   "text":"Entered Amount should be lesser than Total Grant Approved & Total Management Fees"
      // })
      // setShowIncubatorFailureModal(true);   
      // return; 
    } else {

      const payload = {
        "application_id": item.application_id,
        "tranche_id": item.tranch_id,
        "amount_approved": item.approved_amount,
        "amount_released": item.fund_released,
        "application_index": item.application_index
      }
      //console.log(payload,"addMoreTrancheData2")

      post(TRANCHES_EDIT, payload).then(
        (response: any) => {
          if (!response || !response.data) return;
          //console.log(response.data,"resulttttt")
          if (response.data.result == 'success') {
            setShowSuccessModal(true);


          }
          else {
            // setShowFailureModal(true);
          }

        }
      );
    }
  }
  //model 
  // const closeModel = ()=> {
  // setShowIncubatorFailureModal(false)
  // window.location.reload()
  // }
  //1st Edit button clicked
  const addMoreTranche1Edit = (tranch_id: any, item: any, i: any) => {

    if (item.tranches_status_label == 'Disbursed') {
      setFailDialogData({
        "header": "Oops",
        "text": "Tranche Status should not be in Disbursed",
        "note": ""
      })
      setShowIncubatorFailureModal(true);
    } else {
      //  //console.log(trancheData[0])
      trancheDataFirst[0].forEach((element: any, index: any) => {
        if (element.tranch_id == tranch_id) {
          //console.log("into if") 
          element.editchecked = "No"
        }
        else {
          element.editchecked = "Yes"
        }
      });
    }

    setEmployeeData1([...trancheDataFirst[0], trancheDataFirst[0]])
    // const newArray =  employeeData.pop()
    // setEmployeeData(employeeData)
    //console.log(employeeData1,"emp1")
    const newArray = employeeData1.splice(0, employeeData1.length)
    setEmployeeData1(newArray)
  }
  //1st Handle change
  const handleChangeEdit1 = (e: any,
    tranch_id: any
  ) => {
    const { name, value } = e.target
    var editData1: any = []
    editData1 = employeeData1.map((item: any) =>
      item.tranch_id === tranch_id && name ? { ...item, [name]: value.replace(/[^0-9]/ig, '') } : item
    )

    setEmployeeData1(editData1)
    //  //console.log(name,evt,i,"poppp")
    // trancheData[0]({ ...trancheData, [name]: evt });


  };
  //1st sva 
  const addMoreTranche1Save = (item: any, i: any) => {
    setChecked(false)
    let totalfunds1 = final1.total_grant_amount + final1.total_management_amount

    // let sum1 = employeeData1.reduce(function(prev :any, current:any) {
    //   return prev + +current.fund_released
    // }, 0);
    let sumApprove1 = employeeData1.reduce(function (prev: any, current: any) {
      return prev + +current.approved_amount
    }, 0);
    //console.log(sumApprove1,"sumApprove1")
    approved1.current = parseInt(item.approved_amount)
    funds1.current = parseInt(item.fund_released)

    // else
    if (approved1.current == 0) {
      setShowFailureModal(true);
    } else if (sumApprove1 > totalfunds1) {
      setFailDialogData({
        "header": "Oops",
        "text": "Entered Amount should be less than or equal to Total Grant Approved + Total Management Fee",
        "note": ""

      })
      setShowIncubatorFailureModal(true);
    } else if (approved1.current < funds1.current) {
      setFailDialogData({
        "header": "Oops",
        "text": "Amount Disbursed should be less than or equal to Approved Amount",
        "note": ""

      })
      setShowIncubatorFailureModal(true);
      // } 
      // else if(sum1 > totalfunds1){
      //   setFailDialogData({
      //     "header":"Oops",
      //     "text":"Entered Amount should be lesser than Total Grant Approved & Total Management Fees"
      //   })
      //   setShowIncubatorFailureModal(true); 
    } else {
      const payload = {
        "application_id": item.application_id,
        "tranche_id": item.tranch_id,
        "amount_approved": item.approved_amount,
        "amount_released": item.fund_released,
        "application_index": item.application_index
      }

      post(TRANCHES_EDIT, payload).then(
        (response: any) => {
          if (!response || !response.data) return;
          //console.log(response.data,"resulttttt")
          if (response.data.result == 'success') {
            setShowSuccessModal(true);
          }
          else {
            // setShowFailureModal(true);
          }

        }
      );
    }
  }
  const addMoreTranche1 = () => {
    //console.log(application_id,"application_id")
    let addTranche = JSON.parse(JSON.stringify(addMoreTrancheData1));
    addTranche['application_id'] = ((final1.application_id == "" || final1.application_id == 0 || final1.application_id == undefined) ? application_id : final1.application_id);
    setAddMoreTrancheData1(addTranche);
    setIsAddTrancheRowVisible1(true);

    // let sum = employeeData1.reduce(function(prev :any, current:any) {
    //   return prev + +current.fund_released
    // }, 0); 
    let sum1 = employeeData1.reduce(function (prev: any, current: any) {
      //console.log(current,"current@@@")
      return prev + +current.approved_amount
    }, 0);
    if (typeof addMoreTrancheData1.amount_approved === "string") {
      addMoreTrancheData1.amount_approved = Number(addMoreTrancheData1.amount_approved)
    }
    if (addMoreTrancheData1.amount_approved == 0) {
      setShowFailureModal(true);
    } else if ((sum1 + addMoreTrancheData1.amount_approved) > (final1.total_grant_amount + final1.total_management_amount)) {
      // +final1.total_management_amount
      setFailDialogData({
        "header": "Oops",
        "text": "Can’t create new tranche, approved amount is equal to total grant approved + Total Management Fee",
        "note": "Note : If you still want to create a new tranche please check Total Funds Approved & Total Funds Disbursed (If Total Funds Disbursed is lesser than Total Funds Approved + Total Management Fee in existing tranches please decrease Total Funds Approved)"
      })
      setShowIncubatorFailureModal(true);
    } else {
      let addMoreTrancheDetails = {
        application_id: ((final1.application_id == "" || final1.application_id == 0 || final1.application_id == undefined) ? application_id : final1.application_id),
        approved_amount: addMoreTrancheData1.amount_approved,
        logged_user_id: userInfo.id
      };

      post(`${ADD_TRANCHE_WITH_AMOUNT}`, addMoreTrancheDetails).then(
        (response: any) => {
          if (!response || !response.data) return;
          //console.log(response.data.result,"resulttttt")
          if (response.data.result == 'success') {
            setIsAddTrancheRowVisible1(true);

            setShowSuccessModal(true);

          } else {
            // setShowFailureModal(true);
          }
          //getAllTranches();
        }
      );
    }
  };
  const cancel = () => {
    window.location.reload()

  };

  const [tranchIndex, setTrancheIndex] = useState('');
  const [trancheeId, setTrancheeId] = useState('');
  const [tranchApplication, setTranchApplication] = useState('')

  const deleteRowModal = (
    tranch_id: any,
    application_id: any,
    i: any
  ) => {
    setTrancheeId(tranch_id);
    setTranchApplication(application_id)
    setTrancheIndex(i)
  }
  const deleteRowModal1 = (
    tranch_id: any,
    application_id: any,
    i: any
  ) => {
    setTrancheeId(tranch_id);
    setTranchApplication(application_id)
    setTrancheIndex(i)
  }

  const deleteRow = (trancheeId: any, tranchApplication: any) => {

    var payload = {
      "tranch_id": trancheeId,
      "application_id": tranchApplication
    }
    post(DELETE_REAPPLY_TRANCHE, payload).then((res: any) => {
      if (!res || !res.data) return;
      // window.location.reload()
    })

  }

  const deleteTranch = (trancheeId: any, tranchApplication: any) => {

    var payload = {
      "tranch_id": trancheeId,
      "application_id": tranchApplication
    }
    post(DELETE_TRANCHE, payload).then((res: any) => {
      if (!res || !res.data) return;
      if (res.data.result == 'success') {
        settrancheDeleteModal(true);
        // window.location.reload()
      }

    })

  }

  const handleChange2 = (
    evt: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const name = evt.target.name;
    const value = evt.target.value;

    let trancheDetails = JSON.parse(JSON.stringify(trancheData));

    if (name == "amount_approved") {

      const numStr = String(evt.target.value);
      var count = 0;
      if (numStr.includes('.')) {
        count = numStr.split('.')[1].length;
      };
      if (count > 2) {
        trancheDetails[name] = Number(evt.target.value.replace(/[^0-9]/ig, '')).toFixed(2);
      }
      else { trancheDetails[name] = evt.target.value.replace(/[^0-9]/ig, ''); }

    }
    else {
      trancheDetails[name] = evt.target.value.replace(/[^0-9]/ig, '');
    }
    if (name == "amount_approved") {
      trancheDetails[name] = evt.target.value.replace(/[^0-9]/ig, '');
    }
    setAddMoreTrancheData2(trancheDetails);
  };
  const handleChange1 = (
    evt: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const name = evt.target.name;
    const value = evt.target.value;

    let trancheDetails = JSON.parse(JSON.stringify(trancheDataFirst));
    if (name == "amount_approved") {

      const numStr = String(evt.target.value);
      var count = 0;
      if (numStr.includes('.')) {
        count = numStr.split('.')[1].length;
      };
      if (count > 2) {
        trancheDetails[name] = Number(evt.target.value.replace(/[^0-9]/ig, '')).toFixed(2);
      }
      else { trancheDetails[name] = evt.target.value.replace(/[^0-9]/ig, ''); }

    }
    else {
      trancheDetails[name] = evt.target.value.replace(/[^0-9]/ig, '');
    }
    if (name == "amount_approved") {
      trancheDetails[name] = evt.target.value.replace(/[^0-9]/ig, '')
    }
    setAddMoreTrancheData1(trancheDetails);
  };

  const handleChangeTotalGrantApproved2 = (evt: React.ChangeEvent<
    HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
  >) => {
    const name = evt.target.name;
    const value = evt.target.value;

    let quickEdit = JSON.parse(JSON.stringify(quickEditPopupData));

    const numStr = String(evt.target.value);
    var count = 0;
    if (numStr.includes('.')) {
      count = numStr.split('.')[1].length;
    };
    if (count > 2) {
      quickEdit[name] = Number(evt.target.value.replace(/[^0-9]/gi, '')).toFixed(2);
    }
    else { quickEdit[name] = evt.target.value.replace(/[^0-9]/gi, ''); }

    //quickEdit["total_approved_amt"] = value;
    setQuickEditPopupData(quickEdit);
  }

  const handleChangeTotalGrantApproved1 = (evt: React.ChangeEvent<
    HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
  >) => {
    const name = evt.target.name;
    const value = evt.target.value;

    let quickEdit = JSON.parse(JSON.stringify(quickEditPopupData));

    const numStr = String(evt.target.value);
    var count = 0;
    if (numStr.includes('.')) {
      count = numStr.split('.')[1].length;
    };
    if (count > 2) {
      quickEdit[name] = Number(evt.target.value.replace(/[^0-9]/gi, '')).toFixed(2);
    }
    else {
      quickEdit[name] = evt.target.value.replace(/[^0-9]/gi, '');
    }

    //quickEdit["total_approved_amt"] = value;
    setQuickEditPopupData(quickEdit);
  }
  const formatDate = (date: string) => {
    var month = new Array();
    month[0] = 'Jan';
    month[1] = 'Feb';
    month[2] = 'Mar';
    month[3] = 'April';
    month[4] = 'May';
    month[5] = 'Jun';
    month[6] = 'Jul';
    month[7] = 'Aug';
    month[8] = 'Sep';
    month[9] = 'Oct';
    month[10] = 'Nov';
    month[11] = 'Dec';

    var d = new Date(date),
      monthn = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (monthn.length < 2) monthn = '0' + monthn;
    if (day.length < 2) day = '0' + day;

    return [day, month[d.getMonth()], year].join(' ');
  };
  // const [total,set_total]=useState(0);
  const approved = useRef(0)
  const funds = useRef(0)
  const approved1 = useRef(0)
  const funds1 = useRef(0)
  const [alertPopUp,setAlertPopUp] = useState(false);
  const [grantPopup,setGrantPopup] = useState(0);
  const approveGrantAmount2 =() =>{
    setGrantPopup(2)
    $("#editTotalGrantApproved2").modal('hide');
    setAlertPopUp(true);
  }
  const approveGrantAmount1 =() =>{
    setGrantPopup(1)
    $("#editTotalGrantApproved1").modal('hide');
    setAlertPopUp(true);
  }
  const [quickEditPopupData, setQuickEditPopupData] = useState({ application_id: "", total_approved_amt: "", application_status: "", total_disbursed_amt: "", Amount_Disbursed_to_Startups: "" });
  const updateTotalGrantApproved2 = () => {
    // console.log(quickEditPopupData.Amount_Disbursed_to_Startups,"startupDis")
    if (quickEditPopupData.Amount_Disbursed_to_Startups > quickEditPopupData.total_approved_amt) {
      setFailDialogData({
        "header": "Oops",
        "text": "Entered amount is lesser than total Funds Approved to Startups",
        "note": ""
      })

      setShowIncubatorFailureModal(true);
    } else if (quickEditPopupData.total_disbursed_amt > quickEditPopupData.total_approved_amt) {
      setFailDialogData({
        "header": "Oops",
        "text": "Entered amount is lesser than total Funds Disbursed to Incubator",
        "note": ""
      })

      setShowIncubatorFailureModal(true);

    } else {
      var payload = {
        application_id: ((final2.application_id == "" || final2.application_id == 0 || final2.application_id == undefined) ? application_id : final2.application_id),
        total_approved_amt: quickEditPopupData.total_approved_amt,
        application_status: "7"
      }

      post(QUICK_Re_EDIT, payload).then((response: any) => {
        if (!response || !response.data) return;
        //console.log(response.data)


        if (response.data.result == "success") {
          //console.log(response.data)
          window.location.reload()
          setGrantApproved(quickEditPopupData.total_approved_amt);
          setManagementAmount(response.data.data.total_management_amount.toString())
          // setManagementAmount(amountMangement)


        }
        else {

        }
        //console.log(response.data)
      });
    }
  }
  
  const updateTotalGrantApproved1 = () => {

    if (quickEditPopupData.Amount_Disbursed_to_Startups > quickEditPopupData.total_approved_amt) {
      setFailDialogData({
        "header": "Oops",
        "text": "Entered amount is lesser than total Funds Approved to Startups",
        "note": ""
      })

      setShowIncubatorFailureModal(true);
    } else if (quickEditPopupData.total_disbursed_amt > quickEditPopupData.total_approved_amt) {
      setFailDialogData({
        "header": "Oops",
        "text": "Entered amount is lesser than total Funds Disbursed to Incubator",
        "note": ""
      })

      setShowIncubatorFailureModal(true);
    } else {
      var payload = {
        application_id: ((final1.application_id == "" || final1.application_id == 0 || final1.application_id == undefined) ? application_id : final1.application_id),
        total_approved_amt: quickEditPopupData.total_approved_amt,
        application_status: "7"
      }

      post(QUICK_EDIT, payload).then((response: any) => {
        if (!response || !response.data) return;
        //console.log(response.data)


        if (response.data.result == "success") {
          //console.log(response.data)
          window.location.reload()
          setGrantApproved(quickEditPopupData.total_approved_amt);
          setManagementAmount(response.data.data.total_management_amount.toString())
          // setManagementAmount(amountMangement)



        }
        else {

        }
        //console.log(response.data)
      });
    }
  }
  const setQuickEdit = (value: string, value1: string, value2: string) => {
    let quickEdit = JSON.parse(JSON.stringify(quickEditPopupData));
    // quickEdit["total_approved_amt"] = Number(value).toFixed();
    quickEdit["total_approved_amt"] = Number(value);
    quickEdit["total_disbursed_amt"] = Number(value1);
    quickEdit["Amount_Disbursed_to_Startups"] = Number(value2);


    setQuickEditPopupData(quickEdit);

    // console.log(value2,quickEditPopupData,"quiiiii")

  }



  const getFileDetails = (file: any, key: string) => {
    let file_name = '';
    let file_ext = '';
    file_name =
      file != undefined && file.length > 1
        ? file.substr(file.lastIndexOf('/') + 1, file.length)
        : '';
    file_ext = file != undefined && file.length > 1
      ? file.substr(
        file.lastIndexOf('.') + 1,
        file.length
      ) : "";
    if (key == 'ext') return file_ext;
    if (key == 'name') return file_name;
    if (key == 'file') return file.toString();
  };

  const downloadDocument = (file: any, file_name: any) => {
    //console.log(file,"download file")
    let file_name1 =
      file_name != undefined && file_name.length > 1
        ? file_name.substr(file_name.lastIndexOf('/') + 1, file_name.length)
        : '';
    //console.log(`${File_node_url}`+file,"file")

    fetch(`${File_node_url}` + file).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = file_name1;
        a.click();
      });
      //window.location.href = response.url;
    });
  };
  const downloadDocument1 = (file: any, file_name: any) => {
    //console.log(file,"download file")
    let file_name1 =
      file_name != undefined && file_name.length > 1
        ? file_name.substr(file_name.lastIndexOf('/') + 1, file_name.length)
        : '';

    fetch(file).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = file_name1;
        a.click();
      });
      //window.location.href = response.url;
    });
  };
  const ReapplygetAccountDetails = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    const userId = userInfo.id
    post(`${GET_BANK_DETAILS_REAPPLY_NODE}`, { "logged_user_id": leftSideData.user_id }).then((response: any) => {
      if (!response || !response.data) return;
      //console.log(response.data,"ReapplyAccountData");
      //console.log(Object.values(response.data))
      setReapplyAccountDataLength(response.data.data);
      if (response.data.data.length > 0) {
        setReapplyAccountData(response.data.data[0]);
        //console.log(ReapplyAccountData,"ReapplyAccountData");
      }

    });

  };
  return (
    <div className="container-fluid p-0">
      <div className="row">
        {/* { //console.log(trancheData.length,"trancheData")}
      { //console.log(trancheDataFirst.length,"trancheDataFirst")} */}
        <div className="row col-md-12 pt-2 pb-2 m-3" style={{ backgroundColor: "#f8f7f7" }}>

          <p className="dashboard pt-2 pl-2">Total Fund Details</p>

        </div>
        <br></br>
        <div className="col-md-2 left-align text-center" style={{ borderRight: '1px solid #E0E0E0', paddingRight: '5px', paddingLeft: '5px' }}>
          <span className="fund-amount-cur">
            &#8377;
            <label className="fund-amount-label-value">
              {' '}
              {total_grand_approved_amount != null && total_grand_approved_amount != "0"
                ? changeNumberFormat(Number(total_grand_approved_amount), 2, 1)
                : 0}
            </label>
            {/* Cr */}
          </span>
          <br />
          <span className="fund-amount-label-new">Total Grant Approved</span>
          <br />
          {/* { console.log(application_status,loggedInRole,"application_status")} */}
          {/* {(application_status == "selected" && loggedInRole != "eac")
            ?
            <a className="incubator-details-link"
              data-target="#editTotalGrantApproved"
              data-toggle="modal"
              onClick={() => setQuickEdit(total_grand_approved_amount.toString())}
            >Edit</a>

            :
            ""} */}

        </div>
        {/* <div className="vl vl-mr"></div> */}
        <div className="col-md-2 left-align text-center" style={{ borderRight: '1px solid #E0E0E0', paddingRight: '5px', paddingLeft: '5px' }}>
          <span className="fund-amount-cur">
            &#8377;
            <label className="fund-amount-label-value">
              {/* {console.log(total_Management_approved_amount,"336")} */}
              {' '}
              {total_Management_approved_amount !== null && total_Management_approved_amount !== "0"
                ? changeNumberFormat(Number(total_Management_approved_amount), 2, 1)
                : 0}
            </label>
            {/* Cr */}
          </span>
          <br />
          <span className="fund-amount-label-new">Total Management <br />Fees</span>
          <br />
        </div>
        {/* <div className="vl vl-mr"></div> */}
        <div className="col-md-2 left-align text-center" style={{ borderRight: '1px solid #E0E0E0', paddingRight: '5px', paddingLeft: '5px' }}>
          <span className="fund-amount-cur">
            <label className="fund-amount-label-value">

              {tranchesno}
            </label>
          </span>
          <br />

          <span className="fund-amount-label-new">Total No. of Tranches</span>
          <br />
          {/* {loggedInRole != "eac" && <a
            className="incubator-details-link"
            data-target="#addMoreTranchePopUp"
            data-toggle="modal"
          >
            Add More Tranche
          </a>} */}
        </div>

        {/* <div className="vl vl-mr"></div> */}
        <div className="col-md-2 left-align text-center" style={{ borderRight: '1px solid #E0E0E0', paddingRight: '5px', paddingLeft: '5px' }}>
          <span className="fund-amount-cur">
            &#8377;
            <label className="fund-amount-label-value">
              {' '}
              {/* final1.total_disbursed_amt != null && trancheDataFirst[0][0].tranches_status_label === "Disbursed"  */}
              {/* {total_grant_disbursed != null
              
              ? changeNumberFormat(Number(total_grant_disbursed.toString()), 2, 1) 
              
              : 0}{' '} */}
              {/* { 
              total_grant_disbursed != null ? 
              <>
              {
                final1.total_disbursed_amt != null && trancheStatusLabel1== true && 
                final2.total_disbursed_amt != null && trancheStatusLabel== true ?
                <>
                {changeNumberFormat(Number((final1.total_disbursed_amt + final2.total_disbursed_amt).toString()), 2, 1)}
                </>:
                <>
                {
                  final1.total_disbursed_amt != null && trancheStatusLabel1== true ?
                  changeNumberFormat(Number((final1.total_disbursed_amt).toString()), 2, 1):
                  <>
                  {
                    final2.total_disbursed_amt != null && trancheStatusLabel== true ?
                    changeNumberFormat(Number((final2.total_disbursed_amt).toString()), 2, 1):0
                  }
                  </>
                }
                </>
                
              }
              </>:<></>
              
                
              } */}

              {
                (totalDisbursedAmount1 > 0 && totalDisbursedAmount2 > 0) ? changeNumberFormat(Number(totalDisbursedAmount1 + totalDisbursedAmount2), 2, 1) :
                  totalDisbursedAmount2 > 0 ? changeNumberFormat(Number(totalDisbursedAmount2), 2, 1) :
                    totalDisbursedAmount1 > 0 ? changeNumberFormat(Number(totalDisbursedAmount1), 2, 1) : '0'
                // changeNumberFormat(
                //   Number((ReapplytrancheData && total_grand_approvedtwo) + (trancheData && total_grand_approvedone)),
                //   2,
                //   1
                // )
              }
            </label>

          </span>
          <br />
          <span className="fund-amount-label-new">
            Total Funds Disbursed to Incubator

          </span>
          <br />
          {/* <a
            className="incubator-details-link"
            data-target="#viewBankDetails"
            data-toggle="modal"
          >
            View Bank Details
          </a> */}
        </div>
        <div className="col-md-2 left-align text-center" style={{ borderRight: '1px solid #E0E0E0', paddingRight: '5px', paddingLeft: '5px' }}>
          <span className="fund-amount-cur">
            &#8377;
            <label className="fund-amount-label-value">
              {' '}
              {approved_return != null && approved_return != "0"
                ? changeNumberFormat(Number(approved_return), 2, 1)
                : 0}
            </label>
            {/* Cr */}
          </span>
          <br />
          <span className="fund-amount-label-new">Total Approved Returns</span>
        </div>
        {/* <div className="vl vl-mr"></div> */}
        <div className="col-md-2 left-align text-center" style={{ paddingRight: '5px', paddingLeft: '5px' }}>
          <span className="fund-amount-cur">
            &#8377;
            <label className="fund-amount-label-value">
              {/* {console.log(available_balance,"336")} */}
              {' '}
              {available_balance !== null && available_balance !== "0"
                ? changeNumberFormat(Number(available_balance), 2, 1)
                : 0}
            </label>
            {/* Cr */}
          </span>
          <br />
          <span className="fund-amount-label-new">Available Balance of the Incubator</span>
          <br />
        </div>
      </div>
      <br></br>
      {/* 2nd Tranche details ************************************/}

      {leftSideData.application_index == 'R1' && leftSideData.status_id == '7' ?
        <div className="row">
          <div className="row col-md-12 pt-2 pb-2 m-3" style={{ backgroundColor: "#f8f7f7" }}>

            <p className="dashboard pt-2 pl-2">2<sup>nd</sup> Application Fund Details</p>

          </div>
          <br></br>

          <div className="col-md left-align text-center" style={{ borderRight: '1px solid #E0E0E0', paddingRight: '5px', paddingLeft: '5px' }}>
            <span className="fund-amount-cur">
              &#8377;
              {/* { console.log(final2.total_grant_amount,"222222222222222222")} */}
              <label className="fund-amount-label-value">
                {' '}
                {final2.total_grant_amount != null && final2.total_grant_amount != "0"
                  ? changeNumberFormat(Number(final2.total_grant_amount), 2, 1)
                  : 0}
              </label>
              {/* Cr */}
            </span>
            <br />
            <span className="fund-amount-label">Total Grant <br /> Approved</span>
            <br />
            {(final2.total_grant_amount>0 ? "" : application_status == "Selected" && loggedInRole != "eac")
              ?
              <a className="incubator-details-link"
                data-target="#editTotalGrantApproved2"
                data-toggle="modal"
                onClick={() => setQuickEdit(final2.total_grant_amount.toString(), final2.total_disbursed_amt.toString(), Total_Disbursed_to_Startups)}
              >Edit</a>

              :
              ""}

          </div>
          {/* <div className="vl vl-mr"></div> */}
          <div className="col-md left-align text-center" style={{ borderRight: '1px solid #E0E0E0', paddingRight: '5px', paddingLeft: '5px' }}>
            <span className="fund-amount-cur">
              &#8377;
              <label className="fund-amount-label-value">
                {/* { //console.log(total_Management_approved_amount,"336")} */}
                {' '}

                {final2.total_management_amount != null && final2.total_management_amount != 0
                  ? changeNumberFormat(Number(final2.total_management_amount), 2, 1)
                  : 0}
              </label>
              {/* Cr */}
            </span>
            <br />
            <span className="fund-amount-label">Total <br /> Management Fee<br />Approved</span>
            <br />
          </div>
          <div className="col-md left-align text-center" style={{ borderRight: '1px solid #E0E0E0', paddingRight: '5px', paddingLeft: '5px' }}>
            <span className="fund-amount-cur">
              &#8377;
              <label className="fund-amount-label-value">
                {/* { //console.log(total_Management_approved_amount,"336")} */}
                {' '}
                {/* {
                final2.Reduced_management_fee > 0 ? 
                changeNumberFormat(Number((final2.total_management_amount) -(final2.Reduced_management_fee)),2,1) 
                : changeNumberFormat(Number(final2.total_management_amount),2,1)
                } */}
                {totalDisbursedMngmnt2 > 0 ? changeNumberFormat(Number(totalDisbursedMngmnt2), 2, 1) : '0'}

              </label>
              {/* Cr */}
            </span>
            <br />
            <span className="fund-amount-label">Total <br />Management Fee<br />Disbursed</span>
            <br />
          </div>

          {/* <div className="col-md left-align text-center" style={{ borderRight: '1px solid #E0E0E0', paddingRight: '5px', paddingLeft: '5px' }}> */}
          {/* <span className="fund-amount-cur"> */}
          {/* &#8377; */}
          {/* <label className="fund-amount-label-value"> */}
          {/* {' '} */}

          {/* {final2.Reduced_management_fee != null && final2.Reduced_management_fee != 0
                  ? changeNumberFormat(Number(final2.Reduced_management_fee), 2, 1)
                  : 0} */}
          {/* </label> */}
          {/* Cr */}
          {/* </span> */}
          {/* <br /> */}
          {/* <span className="fund-amount-label">Reduced <br />Management <br />Fee</span> */}
          {/* <br /> */}
          {/* {
              final2.Reduced_management_fee >0 ?              
              <a className="incubator-details-link" onClick={()=>setReasonPopup2(true)}>View</a>:''
            } */}
          {/* </div> */}

          {/* <div className="vl vl-mr"></div> */}
          <div className="col-md left-align text-center" style={{ borderRight: '1px solid #E0E0E0', paddingRight: '5px', paddingLeft: '5px' }}>
            <span className="fund-amount-cur">
              <label className="fund-amount-label-value">
                {final2.total_no_of_tranches == null ? 0 : final2.total_no_of_tranches}
              </label>
            </span>
            <br />

            <span className="fund-amount-label">Total No. of <br /> Tranches</span>
            <br />
            {/* {loggedInRole != "eac" && <a
              className="incubator-details-link"
              data-target="#addMoreTranchePopUp"
              data-toggle="modal"
            >
              Add More <br /> Tranche
            </a>} */}
          </div>

          {/* <div className="vl vl-mr"></div> */}

          {/* <div className="vl vl-mr"></div> */}
          <div className="col-md left-align text-center" style={{ paddingRight: '5px', paddingLeft: '5px' }}>
            <span className="fund-amount-cur">
              &#8377;
              <label className="fund-amount-label-value">
                {' '}
                {/* {console.log(trancheStatusLabel,"final222")}
                {final2.total_disbursed_amt != null && trancheStatusLabel== true ? changeNumberFormat(Number(final2.total_disbursed_amt.toString()), 2, 1) : 0}{' '} */}
                {totalDisbursedAmount2 > 0 ? changeNumberFormat(Number(totalDisbursedAmount2), 2, 1) : '0'}
              </label>

            </span>
            <br />
            <span className="fund-amount-label">
              Total Funds <br /> Disbursed to <br /> Incubator
            </span>
            <br />
            <a
              className="incubator-details-link"
              data-target="#viewBankDetails2"
              data-toggle="modal"
            >
              View Bank Details
            </a>
          </div>

          {/* //------------------------------------------------ */}

        </div> : null
      }

      {leftSideData.application_index == 'R1' && leftSideData.status_id == '7' ?

        (final2.length !== 0 ? ((final2.tranch_id !== null) ?
          <span>
            {/* <div className="row col-md-12">
              <div className="col-md-12">
                <MergedTrancheInfoComponent trancheDetailsModel={trancheData} />
              </div>
            </div> */}
            <div className="row">
              <div className="col-md-12 left-align">
                <span className="eac-heading-title"> No. of Tranches</span>
              </div>
            </div>
            <div className='row'>

            </div>
            <div className="application-incubator-hr">
              <hr />
            </div>

            <div className="row">

              <div className="col-md-12">
                <MergedTrancheWiseDetails
                  trancheDetailsModel={trancheData}
                  total_managment={final2.total_management_amount}
                  total_grant_approved={final2.total_grant_amount}
                  amount_disbursed_toInc={props.amountDisbursedToInc}
                  amount_disbursed_toStartup={props.amountDisbursedToStartup}
                  incubator_user_id={leftSideData.user_id}

                />
                {/* sumOfSecond={sumOfSecond} */}
              </div>
            </div>
          </span>
          : null) : null)

        : null}

      {/* </div> */}




      {/* 1st Tranche details ************************************/}
      {/* {console.log(final1,"lengthhhhhhhh")} */}
      {/* {trancheDataFirst.length &&  */}
      <div className='row'>
        <div className="row col-md-12 pt-2 pb-2 m-3" style={{ backgroundColor: "#f8f7f7" }}>
          <p className="dashboard pt-2 pl-2">1<sup>st</sup> Application Fund Details</p>
        </div>
        <br></br>
        <div className="col-md left-align text-center" style={{ borderRight: '1px solid #E0E0E0', paddingRight: '5px', paddingLeft: '5px' }}>
          <span className="fund-amount-cur">
            &#8377;
            {/* {console.log(final1.total_grant_amount,"222222222222222222")} */}
            <label className="fund-amount-label-value">
              {' '}
              {final1.total_grant_amount != null && final1.total_grant_amount != "0"
                ? changeNumberFormat(Number(final1.total_grant_amount), 2, 1)
                : 0}
            </label>
            {/* Cr */}
          </span>
          <br />
          <span className="fund-amount-label">Total Grant <br /> Approved</span>
          <br />
          {final1.total_grant_amount>0 ? "" :(application_status == "Selected" && loggedInRole != "eac")
            ?
            <a className="incubator-details-link"
              data-target="#editTotalGrantApproved1"
              data-toggle="modal"
              // .toString()
              onClick={() => setQuickEdit(final1.total_grant_amount, final1.total_disbursed_amt, Total_Disbursed_to_Startups)}
            >Edit</a>

            :
            ""}
        </div>
        {/* <div className="vl vl-mr"></div> */}
        <div className="col-md left-align text-center" style={{ borderRight: '1px solid #E0E0E0', paddingRight: '5px', paddingLeft: '5px' }}>
          <span className="fund-amount-cur">
            &#8377;
            <label className="fund-amount-label-value">
              {/* { //console.log(total_Management_approved_amount,"336")} */}
              {' '}
              {(final1.total_management_amount != null && final1.total_management_amount != "0")
                ? changeNumberFormat(Number(final1.total_management_amount), 2, 1)
                : 0}
            </label>
            {/* Cr */}
          </span>
          <br />
          <span className="fund-amount-label">Total <br /> Management <br />Approved</span>
          <br />
        </div>
        <div className="col-md left-align text-center" style={{ borderRight: '1px solid #E0E0E0', paddingRight: '5px', paddingLeft: '5px' }}>
          <span className="fund-amount-cur">
            &#8377;
            <label className="fund-amount-label-value">
              {console.log(final1, "336")}
              {' '}
              {/* {
                final1.Reduced_management_fee > 0 ? 
                changeNumberFormat(Number((final1.total_management_amount) -(final1.Reduced_management_fee)),2,1) 
                : changeNumberFormat(Number(final1.total_management_amount),2,1)
                } */}
              {totalDisbursedMngmnt1 > 0 ? changeNumberFormat(Number(totalDisbursedMngmnt1), 2, 1) : '0'}
            </label>
            {/* Cr */}
          </span>
          <br />
          <span className="fund-amount-label">Total <br />Management Fee<br />Disbursed</span>
          <br />
        </div>
        {/* <div className="col-md left-align text-center" style={{ borderRight: '1px solid #E0E0E0', paddingRight: '5px', paddingLeft: '5px' }}>
          <span className="fund-amount-cur">
            &#8377;
            <label className="fund-amount-label-value">
              {' '}

              {final1.Reduced_management_fee != null && final1.Reduced_management_fee != 0
                ? changeNumberFormat(Number(final1.Reduced_management_fee), 2, 1)
                : 0}
            </label>
          </span>
          <br />
          <span className="fund-amount-label">Reduced <br /> Management <br />Fee</span>
          <br />
          {
            final1.Reduced_management_fee > 0 ? 
            <a className="incubator-details-link" onClick={()=>setReasonPopup(true)}>View</a> : ''
          }          
        </div> */}
        {/* <div className="vl vl-mr"></div> */}
        <div className="col-md left-align text-center" style={{ borderRight: '1px solid #E0E0E0', paddingRight: '5px', paddingLeft: '5px' }}>
          <span className="fund-amount-cur">
            <label className="fund-amount-label-value">
              {final1.total_no_of_tranches == null ? 0 : final1.total_no_of_tranches}
            </label>
          </span>
          <br />

          <span className="fund-amount-label">Total No. of <br /> Tranches</span>
          <br />
          {/* {loggedInRole != "eac" && <a
            className="incubator-details-link"
            data-target="#addMoreTranchePopUp1"
            data-toggle="modal"
          >
            Add More <br /> Tranche
          </a>} */}
        </div>

        {/* <div className="vl vl-mr"></div> */}

        {/* <div className="vl vl-mr"></div> */}
        <div className="col-md left-align text-center" style={{ paddingRight: '5px', paddingLeft: '5px' }}>
          <span className="fund-amount-cur">
            &#8377;
            <label className="fund-amount-label-value">
              {' '}
              {/* {final1.total_disbursed_amt != null && trancheStatusLabel1== true ? changeNumberFormat(Number(final1.total_disbursed_amt.toString()), 2, 1) : 0}{' '} */}
              {totalDisbursedAmount1 > 0 ? changeNumberFormat(Number(totalDisbursedAmount1), 2, 1) : '0'}
            </label>
          </span>
          <br />
          <span className="fund-amount-label">
            Total Funds <br /> Disbursed to <br /> Incubator
          </span>
          <br />
          <a
            className="incubator-details-link"
            data-target="#viewBankDetails1"
            data-toggle="modal"
          >
            View Bank Details
          </a>
        </div>

        {/* //------------------------------------------------ */}

      </div>
      {/* {console.log(final1.tranch_id,"tranche_id")} */}
      {final1.length !== 0 ?
        ((final1.tranch_id !== null) ?
          <span>
            <br />

            {/* <div className='row'> */}
            {/* <div className="row">
              <div className="col-md-12">
                <MergedFirstTrancheInfoComponent trancheDetailsModel={trancheDataFirst}  />
              </div>
            </div> */}
            <div className="row">
              <div className="col-md-12 left-align">
                <span className="eac-heading-title">No. of Tranches</span>
              </div>
            </div>
            <div className="application-incubator-hr">
              <hr />
            </div>
            {/* <div className="application-incubator-hr">
        <hr />
      </div> */}
            <div className="row">
              {/* {console.log(trancheDataFirst,"trancheDataFirsttrancheDataFirst")} */}
              <div className="col-md-12">
                <MergedFirstTrancheWiseDetails
                  trancheDetailsModel={trancheDataFirst}
                  total_managment={final1.total_management_amount}
                  total_grant_approved={final1.total_grant_amount}
                  amount_disbursed_toInc={props.amountDisbursedToInc}
                  amount_disbursed_toStartup={props.amountDisbursedToStartup}
                  incubator_user_id={leftSideData.user_id}
                  sec_left_box={leftSideData}
                />
                {/* sumOfFirst={sumOfFirst} */}
              </div>
            </div>
            {/* </div> */}
          </span> : null) : null}



      {/* 2nd //Add more tranche */}

      <div
        className="modal fade"
        id="addMoreTranchePopUp"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none ml-18px">
              <h5 className="modal-title pl-2" id="exampleModalLongTitle">
                Add More Tranche<span></span>
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" onClick={cancel}>&times;</span>
              </button>
            </div>
            <div className="modal-body mt-15-px">
              <div className="container">
                <div className="row">
                  <div className="col-md-3 left-align">
                    <span className="fund-amount-cur">
                      &#8377;
                      <span className="fund-amount-label-value">
                        {' '}
                        {/* {total_grand_approved} */}
                        {final2.total_grant_amount != null
                          ? changeNumberFormat(Number(final2.total_grant_amount), 2, 1)
                          : 0}{' '}
                      </span>

                    </span>
                    <br />
                    <span className="fund-amount-label">
                      Total Grant Approved
                    </span>
                  </div>
                  <div className="col-md-3 left-align">
                    <span className="fund-amount-cur">
                      &#8377;
                      <label className="fund-amount-label-value" style={{ height: 0 }}>
                        {/* {console.log(final2.total_management_amount,"336")} */}
                        {' '}
                        {final2.total_management_amount !== null && final2.total_management_amount !== "0"
                          ? changeNumberFormat(Number(final2.total_management_amount), 2, 1)
                          : 0}
                      </label>
                      {/* Cr */}
                    </span>
                    <br />
                    <span className="fund-amount-label">Total Management <br />Fees</span>
                    <br />
                  </div>
                  <div className="col-md-3 left-align">
                    <span className="fund-amount-cur">
                      <span className="fund-amount-label-value">
                        {/* { //console.log(trancheData,"tranchData")} */}
                        {/* {trancheData.length}{' '} */}
                        {final2.total_no_of_tranches == null ? 0 : final2.total_no_of_tranches}
                      </span>
                    </span>
                    <br />
                    <span className="fund-amount-label">
                      Total No. of Tranches
                    </span>
                  </div>

                  <div className="col-md-4 right">
                    <br />
                    {/* <a
                      className="incubator-details-link"
                      onClick={() => setIsAddTrancheRowVisible(true)}
                    >
                      +Add More Tranches{' '}
                    </a> */}
                  </div>
                </div>

                <div className="row">
                  <div className="table-scroll ">
                    <table id="example">
                      <thead>
                        <tr>
                          <th className="text-left custom-td-left table-heading">
                            Tranche Request
                          </th>
                          <th className="text-left custom-td-left table-heading">
                            Funds Approved
                          </th>
                          <th className="text-left table-heading">
                            Funds Disbursed
                          </th>
                          <th className="text-left table-heading"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {isAddTrancheRowVisible == true ? (
                          <tr>
                            <td className="text-left ">
                              <span className="fund-amount-label">
                                {' '}
                                Tranche {final2.total_no_of_tranches + 1}
                              </span>
                            </td>
                            <td className="text-left custom-td-left">
                              <input
                                type="input"
                                placeholder="Enter"
                                name="amount_approved"
                                className="form-control input-height"
                                onChange={handleChange2}
                                value={addMoreTrancheData2.amount_approved}
                              />
                            </td>
                            <td className="text-left custom-td-left"></td>
                            <td>
                              <a
                                className="incubator-details-link"
                                // href="#"
                                onClick={() => addMoreTranche2()}
                              >
                                Save
                              </a>
                              |
                              <a
                                className="incubator-details-link"
                                href=""
                                onClick={() => cancel()}
                                data-dismiss="modal"
                              >
                                Cancel
                              </a>
                            </td>
                            {/* <td>
                              <a
                                className="incubator-details-link"
                                // href="#"
                                onClick={() => addMoreTranche2Edit()}
                              >
                                Edit
                              </a>
                              |
                              <a
                                className="incubator-details-link"
                                href=""
                                onClick={() => cancel()}
                                data-dismiss="modal"
                              >
                                Cancel
                              </a>
                            </td> */}
                          </tr>
                        ) : (
                          ''
                        )}
                        {/* {console.log(employeeData,"trancheDatalengthhhhhh")} */}
                        {employeeData != undefined && employeeData.length > 0 && final2.tranch_id !== null
                          ? employeeData.map((item: any, i: any) => (

                            // (typeof item == Object ?
                            // typeof item == 'object' ?
                            <tr key={i}>
                              <td className="text-left ">
                                <span className="incubator-label-value">
                                  {item.tranche_number}
                                  {/* {console.log(item,"typeeeee")} */}
                                </span>
                                <br />
                                <span className="incubator-label">
                                  {' '}
                                  {item.tranche_requested_date != null ? formatDate(item.tranche_requested_date) : "-"}
                                </span>
                              </td>
                              <td className="text-left ">
                                <span className="incubator-label-value">
                                  {' '}

                                  <input
                                    type="input"
                                    placeholder="Enter"
                                    name="approved_amount"
                                    className="form-control input-height"
                                    onChange={(e) => handleChangeEdit2(e, item.tranch_id)}
                                    // value={item.approved_amount}
                                    value={item.editchecked == "Yes" ? changeNumberFormat(Number(item.approved_amount), 2, 1) : item.approved_amount}
                                    disabled={item.editchecked == "Yes"}


                                  />
                                  {/* {changeNumberFormat(Number(item.approved_amount), 2, 1)} */}
                                </span>
                                <br />
                                {item.approved_amount != null ? (
                                  <span className="incubator-label">
                                    {' '}
                                    {item.approved_amount_date != null ? formatDate(item.approved_amount_date) : "-"}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </td>
                              <td className="text-left ">
                                <span className="incubator-label-value">
                                  {' '}
                                  <input
                                    type="input"
                                    placeholder="Enter"
                                    name="fund_released"
                                    className="form-control input-height"
                                    onChange={(e) => handleChangeEdit2(e, item.tranch_id)}
                                    // value={changeNumberFormat(Number(item.fund_released), 2, 1)}
                                    // value={item.fund_released}
                                    value={item.editchecked == "Yes" ? changeNumberFormat(Number(item.fund_released), 2, 1) : item.fund_released}
                                    disabled={item.editchecked == "Yes"}

                                  />
                                  {/* {changeNumberFormat(Number(item.fund_released), 2, 1)} */}
                                </span>
                                <br />
                                {item.fund_released != null ? (
                                  <span className="incubator-label">
                                    {' '}
                                    {item.date_of_release != null ? formatDate(item.date_of_release) : "-"}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </td>
                              <td className="text-left custom-td-left">
                                {/* <td> */}
                                {/* {console.log("Checking Item",item)} */}
                                {item.editchecked == "Yes" ? <a
                                  className="incubator-details-link"
                                  // href="#"
                                  onClick={() => addMoreTranche2Edit(item.tranch_id, item, i)}
                                >
                                  Edit
                                </a> : null}
                                {/* {console.log(item.editchecked,"editttttttttt")} */}
                                {item.editchecked == "No" ? <a
                                  className="incubator-details-link"
                                  // href="#"
                                  onClick={() => addMoreTranche2Save(item, i, item.fund_released)}
                                >
                                  Save
                                </a> : null}
                                |
                                {
                                  item.tranches_status_label == 'Disbursed' ? <>
                                    <a
                                      className="incubator-details-link"
                                      onClick={() => cancel()}
                                      data-dismiss="modal"
                                    >
                                      Cancel
                                    </a>
                                  </> :

                                    item.editchecked == "Yes" ? <a
                                      className="incubator-details-link"
                                      onClick={() => deleteRowModal(item.tranch_id, item.application_id, i)}
                                      data-target="#deleteReapplyTranch"
                                      data-toggle="modal"
                                    // data-dismiss="modal"
                                    >
                                      Delete
                                    </a> :
                                      item.editchecked == "No" ? <a
                                        className="incubator-details-link"
                                        onClick={() => cancel()}
                                        data-dismiss="modal"
                                      >
                                        Cancel
                                      </a> : null}

                                {/* <a
                                className="incubator-details-link"
                                href=""
                                onClick={() => cancel()}
                                data-dismiss="modal"
                              >
                                Cancel
                              </a> */}
                                {/* </td> */}
                                {/* <a
                                      className="incubator-details-link"
                                      href=""
                                      onClick={() =>
                                        handleRowChange(item.application_id)
                                      }
                                      data-dismiss="modal"
                                    >
                                      View
                                    </a> */}
                              </td>
                            </tr>
                            // :null
                            // :null
                            // )
                          ))
                          : ''}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row pt-3 mb-3">
                <div className="col-md-12 centerText">
                  <button
                    className="tertiary  btn-yellow-primary"
                    // data-dismiss="modal" 
                    onClick={cancel}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 2nd //View Bank Details */}

      <div
        className="modal fade"
        id="viewBankDetails2"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none ml-18px">
              <h5 className="modal-title pl-2" id="exampleModalLongTitle">
                View Bank Details<span></span>
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body text-left">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <span className="incubator-label-value">Account Holder</span>
                  </div>
                </div>

                <div className="row mt-1-5">
                  <div className="col-md-6 left">
                    <span className="incubator-label">Account Name </span>
                  </div>
                  <div className="col-md-6 left">
                    <span className="incubator-label">PFMS</span>
                  </div>
                </div>
                {
                  subsequentDetails != undefined ? subsequentDetails?.pfms_file_path_file_name == null ?
                    "-"
                    :
                    subsequentDetails?.pfms_file_path == "No FIle provided by user" ?
                      <div className="col-12">
                        <span className='document-label-value'>
                          {subsequentDetails?.pfms_file_path_file_name}
                        </span>
                      </div>
                      :
                      <>
                        <div className="col-md-3">
                          {getFileDetails(
                            subsequentDetails?.pfms_file_path,
                            'ext'
                          ) == 'pdf' ? (
                            <img src={pdf} alt="SISFS Logo" />
                          ) : getFileDetails(
                            subsequentDetails.pfms_file_path,
                            'ext'
                          ) == 'doc' ? (
                            <img src={doc} alt="SISFS Logo" />
                          ) : getFileDetails(
                            subsequentDetails.pfms_file_path,
                            'ext'
                          ) == 'xls' ? (
                            <img src={xls} alt="SISFS Logo" />
                          ) : (
                            <img src={Allround} alt="SISFS Logo" />
                          )}
                        </div>
                        <div className="col-md-9" style={{ wordBreak: 'break-all' }}>
                          <span className="document-label-value">
                            {getFileDetails(
                              subsequentDetails.pfms_file_path,
                              'name'
                            )}
                          </span>
                          <br />
                          <a
                            href={`${NODE_URL + subsequentDetails.pfms_file_path}`}
                            target="_blank"
                            className="incubator-details-link"
                          >
                            View
                          </a>
                        </div>
                      </>
                    :
                    <div className="row">
                      <div className="col-md-3">
                        {getFileDetails(
                          accountDetailsData2.length == 0 ? accountDetailsData1.pan_card.file : accountDetailsData2.pan_card_location,
                          'ext'
                        ) == 'pdf' ? (
                          <img src={pdf} alt="SISFS Logo" />
                        ) : getFileDetails(
                          accountDetailsData2.length == 0 ? accountDetailsData1.pan_card.file : accountDetailsData2.pan_card_location,
                          'ext'
                        ) == 'doc' ? (
                          <img src={doc} alt="SISFS Logo" />
                        ) : getFileDetails(
                          accountDetailsData2.length == 0 ? accountDetailsData1.pan_card.file : accountDetailsData2.pan_card_location,
                          'ext'
                        ) == 'xls' ? (
                          <img src={xls} alt="SISFS Logo" />
                        ) : (
                          <img src={Allround} alt="SISFS Logo" />
                        )}
                      </div>
                      <div className="col-md-9" style={{ wordBreak: 'break-all' }}>
                        <span className="document-label-value">
                          {getFileDetails(
                            accountDetailsData2.length == 0 ? accountDetailsData1.pan_card.file : accountDetailsData2.pan_card_location,
                            'name'
                          )}
                        </span>
                        <br />
                        <a
                          href={getFileDetails((accountDetailsData2.length == 0 ? accountDetailsData1.pan_card.file : `${NODE_URL}` + accountDetailsData2.pan_card_location.substr(1)), "file")}
                          target="_blank"
                          // href={`${NODE_URL}` + ReapplyaccountDetailsModel.pan_card_location.substr(1)}
                          //download="pan_card"
                          className="incubator-details-link"
                        >
                          View
                        </a>
                      </div>
                    </div>
                }
                {/* <div className="row ">
                  <div className="col-md-6 left">
                    <span className="incubator-label-value">
                      {accountDetailsData2.bank_account_holder_name == '' ||
                        accountDetailsData2.bank_account_holder_name == null
                        ? '-'
                        : accountDetailsData2.bank_account_holder_name}
                    </span>
                  </div>
                  <div className="col-md-6 left">
                    {accountDetailsData2.pan_card_location != undefined && accountDetailsData2.pan_card_location != null ?

                      <div className="row">
                        <div className="col-md-3">
                          {getFileDetails(
                            accountDetailsData2.pan_card_location,
                            'ext'
                          ) == 'pdf' ? (
                            <img src={pdf} alt="SISFS Logo" />
                          ) : getFileDetails(
                            accountDetailsData2.pan_card_location,
                            'ext'
                          ) == 'doc' ? (
                            <img src={doc} alt="SISFS Logo" />
                          ) : getFileDetails(
                            accountDetailsData2.pan_card_location,
                            'ext'
                          ) == 'xls' ? (
                            <img src={xls} alt="SISFS Logo" />
                          )
                            : (
                              <img src={Allround} alt="SISFS Logo" />
                            )
                          }
                        </div>
                        <div className="col-md-9" style={{ wordBreak: 'break-all' }}>
                          <span className="document-label-value">
                            {getFileDetails(
                              accountDetailsData2.pan_card_location,
                              'name'
                            )}
                          </span>
                          <br />
                          <br />
                          <a
                            href={(accountDetailsData2.application_index == 'F1' ? (`${File_node_url}` + accountDetailsData2.pan_card_location) : (`${NODE_URL}` + '/' + accountDetailsData2.pan_card_location))}
                            target="_blank"
                            //download="pan_card_location"
                            className="incubator-details-link"
                          >
                            View
                          </a>
                        </div>
                      </div>
                      : ""}
                  </div>
                </div> */}

                <div className="row mt-1-5">
                  <div className="col-md-6 left">
                    <span className="incubator-label">
                      ECS Mandate Form
                    </span>
                    <br />
                    {
                      subsequentDetails != undefined ? subsequentDetails?.ECS_Mandate_file_path_file_name == null ?
                        "-"
                        :
                        subsequentDetails?.ECS_Mandate_file_path_file_name != "" ?
                          <div className="row mt-2">
                            <div className="col-md-3">
                              {getFileDetails(
                                subsequentDetails.ECS_Mandate_file_path,
                                'ext'
                              ) == 'pdf' ? (
                                <img src={pdf} alt="SISFS Logo" />
                              ) : getFileDetails(
                                subsequentDetails?.ECS_Mandate_file_path,
                                'ext'
                              ) == 'doc' ? (
                                <img src={doc} alt="SISFS Logo" />
                              ) : getFileDetails(
                                subsequentDetails.ECS_Mandate_file_path,
                                'ext'
                              ) == 'xls' ? (
                                <img src={xls} alt="SISFS Logo" />
                              ) : (
                                <img src={Allround} alt="SISFS Logo" />
                              )}
                            </div>
                            <div className="col-md-9" style={{ wordBreak: 'break-all' }}>
                              <span className="document-label-value">
                                {getFileDetails(
                                  subsequentDetails.ECS_Mandate_file_path,
                                  'name'
                                )}
                              </span>
                              <br />
                              <a
                                href={`${NODE_URL + subsequentDetails.ECS_Mandate_file_path}`}
                                target="_blank"
                                className="incubator-details-link"
                              >
                                View
                              </a>
                            </div>
                          </div> : "-"
                        :
                        <div className="row mt-2">
                          <div className="col-md-3">
                            {getFileDetails(
                              //  ReapplyaccountDetailsModel.cancelled_cheque_location,
                              accountDetailsData2.length == 0 ? accountDetailsData1.cancelled_cheque.file : accountDetailsData2.cancelled_cheque_location,
                              'ext'
                            ) == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(
                              accountDetailsData2.length == 0 ? accountDetailsData1.cancelled_cheque.file : accountDetailsData2.cancelled_cheque_location,
                              'ext'
                            ) == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(
                              accountDetailsData2.length == 0 ? accountDetailsData1.cancelled_cheque.file : accountDetailsData2.cancelled_cheque_location,
                              'ext'
                            ) == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-9" style={{ wordBreak: 'break-all' }}>
                            <span className="document-label-value">
                              {getFileDetails(
                                accountDetailsData2.length == 0 ? accountDetailsData1.cancelled_cheque_location : accountDetailsData2.cancelled_cheque_location,
                                'name'
                              )}
                            </span>
                            <br />
                            <a
                              href={getFileDetails((accountDetailsData2.length == 0 ? accountDetailsData1.cancelled_cheque.file : `${NODE_URL}` + accountDetailsData2.cancelled_cheque_location.substr(1)), "file")}
                              target="_blank"
                              //download="proof_of_disbursement"
                              className="incubator-details-link"
                            >
                              View
                            </a>
                          </div>
                        </div>
                    }
                    {/* {accountDetailsData2.cancelled_cheque_location != undefined && accountDetailsData2.cancelled_cheque_location != null ?
                      <div className="row">
                        <div className="col-md-3">
                          {getFileDetails(
                            accountDetailsData2.cancelled_cheque_location,
                            'ext'
                          ) == 'pdf' ? (
                            <img src={pdf} alt="SISFS Logo" />
                          ) : getFileDetails(
                            accountDetailsData2.cancelled_cheque_location,
                            'ext'
                          ) == 'doc' ? (
                            <img src={doc} alt="SISFS Logo" />
                          ) : getFileDetails(
                            accountDetailsData2.cancelled_cheque_location,
                            'ext'
                          ) == 'xls' ? (
                            <img src={xls} alt="SISFS Logo" />
                          ) : (
                            <img src={Allround} alt="SISFS Logo" />
                          )}
                        </div>
                        <div className="col-md-9" style={{ wordBreak: 'break-all' }}>
                          <span className="document-label-value">
                            {getFileDetails(
                              accountDetailsData2.cancelled_cheque_location,
                              'name'
                            )}
                          </span>
                          <br />
                          <br />
                          <a
                            href={(accountDetailsData2.application_index == 'F1' ? (`${File_node_url}` + accountDetailsData2.cancelled_cheque_location) : (`${NODE_URL}` + '/' + accountDetailsData2.cancelled_cheque_location))}
                            target="_blank"
                            //download="proof_of_disbursement"
                            className="incubator-details-link"
                          >
                            View
                          </a>
                        </div>
                      </div>
                      : ""} */}
                  </div>
                  <div className="col-md-4">
                    <span className="incubator-label">
                      DARPAN ID
                    </span>
                    <br />
                    {
                      subsequentDetails != undefined ? subsequentDetails?.proof_Of_Darpan_file_path_file_name == null ?
                        "-"
                        :
                        <div className="row mt-2">
                          <div className="col-md-3">
                            <span className='document-label-value'>
                              {subsequentDetails?.proof_Of_Darpan_file_path_file_name}
                            </span>
                          </div>
                        </div>
                        :
                        accountDetailsData2.darpan_location == "null" ? "-" :
                          <div className="row">
                            <div className="col-md-3">
                              {getFileDetails(
                                accountDetailsData2.length == 0 ? accountDetailsData1.darpan.file : accountDetailsData2.darpan_location,
                                'ext'
                              ) == 'pdf' ? (
                                <img src={pdf} alt="SISFS Logo" />
                              ) : getFileDetails(
                                accountDetailsData2.length == 0 ? accountDetailsData1.darpan.file : accountDetailsData2.darpan_location,
                                'ext'
                              ) == 'doc' ? (
                                <img src={doc} alt="SISFS Logo" />
                              ) : getFileDetails(
                                accountDetailsData2.length == 0 ? accountDetailsData1.darpan.file : accountDetailsData2.darpan_location,
                                'ext'
                              ) == 'xls' ? (
                                <img src={xls} alt="SISFS Logo" />
                              ) : (
                                <img src={Allround} alt="SISFS Logo" />
                              )}
                            </div>
                            <div className="col-md-9" style={{ wordBreak: 'break-all' }}>
                              <span className="document-label-value">
                                {getFileDetails(
                                  accountDetailsData2.length == 0 ? accountDetailsData1.darpan_location : accountDetailsData2.darpan_location,
                                  'name'
                                )}
                              </span>
                              <br />
                              <br />
                              <a
                                href={getFileDetails((accountDetailsData2.length == 0 ? accountDetailsData1.darpan.file : `${NODE_URL}` + accountDetailsData2.darpan_location.substr(1)), "file")}
                                target="_blank"
                                //download="pan_card"
                                className="incubator-details-link"
                              >
                                View
                              </a>
                            </div>
                          </div>
                    }
                    {/* {accountDetailsData2.darpan_location != undefined && accountDetailsData2.darpan_location != null ?
                      <div className="row">
                        <div className="col-md-3">
                          {getFileDetails(
                            accountDetailsData2.darpan_location,
                            'ext'
                          ) == 'pdf' ? (
                            <img src={pdf} alt="SISFS Logo" />
                          ) : getFileDetails(
                            accountDetailsData2.darpan_location,
                            'ext'
                          ) == 'doc' ? (
                            <img src={doc} alt="SISFS Logo" />
                          ) : getFileDetails(
                            accountDetailsData2.darpan_location,
                            'ext'
                          ) == 'xls' ? (
                            <img src={xls} alt="SISFS Logo" />
                          ) : (
                            <img src={Allround} alt="SISFS Logo" />
                          )}
                        </div>
                        <div className="col-md-9" style={{ wordBreak: 'break-all' }}>
                          <span className="document-label-value">
                            {getFileDetails(
                              accountDetailsData2.darpan_location,
                              'name'
                            )}
                          </span>
                          <br />
                          <br />
                          <a
                            href={(accountDetailsData2.application_index == 'F1' ? (`${File_node_url}` + accountDetailsData2.darpan_location) : (`${NODE_URL}` + '/' + accountDetailsData2.darpan_location))}
                            target="_blank"
                            //download="proof_of_disbursement"
                            className="incubator-details-link"
                          >
                            View
                          </a>
                        </div>
                      </div>
                      : ""} */}
                  </div>
                </div>
                <div className="row mt-1-5">
                  <div className="col-md-6 left">
                    <span className="incubator-label">
                      TRA Agreement
                    </span>
                    <br />
                    {
                      accountDetailsData2.traAgreement_Location == undefined ?
                        accountDetailsData1.tra_agreement_name != "" ?
                          <div className="row mt-2">
                            <div className="col-md-3">
                              {getFileDetails(
                                accountDetailsData1.tra_agreement_location,
                                'ext'
                              ) == 'pdf' ? (
                                <img src={pdf} alt="SISFS Logo" />
                              ) : getFileDetails(
                                accountDetailsData1.tra_agreement_location,
                                'ext'
                              ) == 'doc' ? (
                                <img src={doc} alt="SISFS Logo" />
                              ) : getFileDetails(
                                accountDetailsData1.tra_agreement_location,
                                'ext'
                              ) == 'xls' ? (
                                <img src={xls} alt="SISFS Logo" />
                              ) : (
                                <img src={Allround} alt="SISFS Logo" />
                              )}
                            </div>
                            <div className="col-md-9" style={{ wordBreak: 'break-all' }}>
                              <span className="document-label-value">
                                {getFileDetails(
                                  accountDetailsData1.tra_agreement_location,
                                  'name'
                                )}
                              </span>
                              <br />
                              <a
                                href={`${NODE_URL + '/filestorage/' + accountDetailsData1.tra_agreement_location}`}
                                target="_blank"
                                className="incubator-details-link"
                              >
                                View
                              </a>
                            </div>
                          </div> : "-" :
                        <div className="row mt-2">
                          <div className="col-md-3">
                            {getFileDetails(
                              accountDetailsData2.traAgreement_Location,
                              'ext'
                            ) == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(
                              accountDetailsData2.traAgreement_Location,
                              'ext'
                            ) == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(
                              accountDetailsData2.traAgreement_Location,
                              'ext'
                            ) == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-9" style={{ wordBreak: 'break-all' }}>
                            <span className="document-label-value">
                              {getFileDetails(
                                accountDetailsData2.traAgreement_Location,
                                'name'
                              )}
                            </span>
                            <br />
                            <a
                              href={`${NODE_URL + '/filestorage/' + accountDetailsData2.traAgreement_Location}`}
                              target="_blank"
                              className="incubator-details-link"
                            >
                              View
                            </a>
                          </div>
                        </div>

                    }
                  </div>
                  <div className="col-md-4">
                    <span className="incubator-label">
                      Sanction Order
                    </span>
                    <br />
                    {
                      accountDetailsData2.traAgreement_Location == undefined ?
                        accountDetailsData1.sanction_name != "" ?
                          <div className="row mt-2">
                            <div className="col-md-3">
                              {getFileDetails(
                                accountDetailsData1.sanction_location,
                                'ext'
                              ) == 'pdf' ? (
                                <img src={pdf} alt="SISFS Logo" />
                              ) : getFileDetails(
                                accountDetailsData1.sanction_location,
                                'ext'
                              ) == 'doc' ? (
                                <img src={doc} alt="SISFS Logo" />
                              ) : getFileDetails(
                                accountDetailsData1.sanction_location,
                                'ext'
                              ) == 'xls' ? (
                                <img src={xls} alt="SISFS Logo" />
                              ) : (
                                <img src={Allround} alt="SISFS Logo" />
                              )}
                            </div>
                            <div className="col-md-9" style={{ wordBreak: 'break-all' }}>
                              <span className="document-label-value">
                                {getFileDetails(
                                  accountDetailsData1.sanction_location,
                                  'name'
                                )}
                              </span>
                              <br />
                              <a
                                href={`${NODE_URL + '/filestorage/' + accountDetailsData1.sanction_location}`}
                                target="_blank"
                                className="incubator-details-link"
                              >
                                View
                              </a>
                            </div>
                          </div> : "-" :
                        <div className="row mt-2">
                          <div className="col-md-3">
                            {getFileDetails(
                              accountDetailsData2.sanctionOrder_Location,
                              'ext'
                            ) == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(
                              accountDetailsData2.sanctionOrder_Location,
                              'ext'
                            ) == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(
                              accountDetailsData2.sanctionOrder_Location,
                              'ext'
                            ) == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-9" style={{ wordBreak: 'break-all' }}>
                            <span className="document-label-value">
                              {getFileDetails(
                                accountDetailsData2.sanctionOrder_Location,
                                'name'
                              )}
                            </span>
                            <br />
                            <a
                              href={`${NODE_URL + '/filestorage/' + accountDetailsData2.sanctionOrder_Location}`}
                              target="_blank"
                              className="incubator-details-link"
                            >
                              View
                            </a>
                          </div>
                        </div>
                    }
                  </div>
                </div>
                <div className="application-incubator-hr">

                  <hr />
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <span className="incubator-label-value">Account Details</span>
                  </div>
                </div>

                <div className="row mt-1-5">
                  <div className="col-md-6 left">
                    <span className="incubator-label">Bank Name</span>
                  </div>
                  <div className="col-md-4">
                    <span className="incubator-label">Account Number</span>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-6 left">
                    <span className="incubator-label-value">
                      {accountDetailsData2.bank_name == '' ||
                        accountDetailsData2.bank_name == null
                        ? '-'
                        : accountDetailsData2.bank_name}
                    </span>
                  </div>
                  <div className="col-md-6">
                    <span className="incubator-label-value">
                      {accountDetailsData2.account_number == '0' ||
                        accountDetailsData2.account_number == null
                        ? '-'
                        : accountDetailsData2.account_number}
                    </span>
                  </div>
                </div>

                <div className="row mt-1-5">
                  <div className="col-md-6 left">
                    <span className="incubator-label">Account Type</span>
                  </div>
                  <div className="col-md-6 left">
                    <span className="incubator-label">
                      Branch Name/Branch Code
                    </span>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-6 left">
                    <span className="incubator-label-value">
                      {accountDetailsData2.account_type == 0 ||
                        accountDetailsData2.account_type == null
                        ? '-'
                        : (accountDetailsData2.account_type == 1 && "Saving" ||
                          accountDetailsData2.account_type == 2 && "Current" ||
                          accountDetailsData2.account_type == 3 && "Salary" ||
                          accountDetailsData2.account_type == 4 && "Fixed Deposit" ||
                          accountDetailsData2.account_type == 5 && "Recurring Deposit"
                        )}
                    </span>
                  </div>
                  <div className="col-md-6 left">
                    <span className="incubator-label-value">
                      {accountDetailsData2.branch_name == '' ||
                        accountDetailsData2.branch_name == null
                        ? '-'
                        : accountDetailsData2.branch_name}
                    </span>
                  </div>
                </div>

                <div className="row mt-1-5">
                  <div className="col-md-6 left">
                    <span className="incubator-label">IFSC Code</span>
                  </div>
                  <div className="col-md-6 left">
                    <span className="incubator-label">MICR Code</span>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-6 left">
                    <span className="incubator-label-value">
                      {accountDetailsData2.bank_ifsc_code == '' ||
                        accountDetailsData2.bank_ifsc_code == null
                        ? '-'
                        : accountDetailsData2.bank_ifsc_code}
                    </span>
                  </div>
                  <div className="col-md-6 left">
                    <span className="incubator-label-value">
                      {accountDetailsData2.bank_micr_code == '' ||
                        accountDetailsData2.bank_micr_code == null
                        ? '-'
                        : accountDetailsData2.bank_micr_code}
                    </span>
                  </div>
                </div>

                <div className="row mt-1-5">
                  <div className="col-md-6 left">
                    <span className="incubator-label">Bank Address</span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 left">
                    <span className="incubator-label-value">
                      {accountDetailsData2.branch_address == '' ||
                        accountDetailsData2.branch_address == null
                        ? '-'
                        : accountDetailsData2.branch_address}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row pt-3 mb-3">
                <div className="col-md-12 centerText">
                  <button
                    className="tertiary  btn-yellow-primary"
                    data-dismiss="modal"
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* {delete reapply tranch modal} */}
      <div
        className="modal fade"
        id="deleteReapplyTranch"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none">

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h5 className="modal-title text-center"
                id="exampleModalLongTitle"
                style={{ color: '#E26640' }}
              >
                Are you sure you want to delete Tranche
              </h5><br />
              <div className="row">
                <div className="col-md-12 text-center">
                  <div className="btn-group mt-1-5 ">



                    <button
                      className="tertiary btn-transparent-primary"
                      data-dismiss="modal"
                      style={{ width: '107.17px' }}
                    >
                      Cancel
                    </button>


                    <button
                      type="button"
                      className="btn btn-sisfs btn-yellow-primary ml-2"
                      // data-dismiss="modal"
                      onClick={() => deleteRow(trancheeId, tranchApplication)}
                    >
                      Delete{' '}
                    </button>


                  </div>
                </div>
              </div>

              <div className="row pt-3 mb-3">
                <div className="col-md-12 centerText"></div>
              </div>
            </div>
          </div>
        </div>
      </div>




      {/* 2nd Total Grant Approved Edit pop up */}
      <div
        className="modal fade"
        id="editTotalGrantApproved2"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none">
              <h5 className="modal-title " id="exampleModalLongTitle">
                Update Total Grant Approved <span className="eac-name-pop-up"></span>
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12 text-left">
                  <span className="col-form-head">Total Grant Approved</span>
                </div>
              </div>
              <div className="row mt-1-5">
                <div className="col-md-12 text-left">
                  <input
                    type="text"
                    className="form-control"
                    name="total_approved_amt"
                    value={quickEditPopupData.total_approved_amt}
                    placeholder="Enter total grant approved"
                    onChange={handleChangeTotalGrantApproved2}
                  ></input>

                </div>
              </div>

              <div className="row">
                <div className="col-md-12 text-right">
                  <div className="btn-group mt-1-5 ">
                    <button
                      className="tertiary   btn-transparent-primary"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>

                    <button
                      type="button"
                      className="btn btn-sisfs btn-yellow-primary ml-2"
                      onClick={() => approveGrantAmount2()}
                      data-dismiss="modal"
                    >
                      Update{' '}
                    </button>
                  </div>
                </div>
              </div>

              <div className="row pt-3 mb-3">
                <div className="col-md-12 centerText"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 1st Total Grant Approved Edit pop up */}
      <div
        className="modal fade"
        id="editTotalGrantApproved1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none">
              <h5 className="modal-title " id="exampleModalLongTitle">
                Update Total Grant Approved <span className="eac-name-pop-up"></span>
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12 text-left">
                  <span className="col-form-head">Total Grant Approved</span>
                </div>
              </div>
              <div className="row mt-1-5">
                <div className="col-md-12 text-left">
                  <input
                    type="text"
                    className="form-control"
                    name="total_approved_amt"
                    value={quickEditPopupData.total_approved_amt}
                    placeholder="Enter total grant approved"
                    onChange={handleChangeTotalGrantApproved1}
                  ></input>

                </div>
              </div>

              <div className="row">
                <div className="col-md-12 text-right">
                  <div className="btn-group mt-1-5 ">
                    <button
                      className="tertiary   btn-transparent-primary"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>

                    <button
                      type="button"
                      className="btn btn-sisfs btn-yellow-primary ml-2"
                      onClick={() => approveGrantAmount1()}
                      // data-dismiss="modal"
                    >
                      Update{' '}
                    </button>
                  </div>
                </div>
              </div>

              <div className="row pt-3 mb-3">
                <div className="col-md-12 centerText"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 1st add more tranche model */}
      <div
        className="modal fade"
        id="addMoreTranchePopUp1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none ml-18px">
              <h5 className="modal-title pl-2" id="exampleModalLongTitle">
                Add More Tranche<span></span>
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" onClick={cancel}>&times;</span>
              </button>
            </div>
            <div className="modal-body mt-15-px">
              <div className="container">
                <div className="row">
                  <div className="col-md-3 left-align">
                    <span className="fund-amount-cur">
                      &#8377;
                      <span className="fund-amount-label-value">
                        {' '}
                        {/* {total_grand_approved} */}
                        {final1.total_grant_amount != null
                          ? changeNumberFormat(Number(final1.total_grant_amount), 2, 1)
                          : 0}{' '}
                      </span>

                    </span>
                    <br />
                    <span className="fund-amount-label">
                      Total Grant Approved
                    </span>
                  </div>
                  <div className="col-md-3 left-align">
                    <span className="fund-amount-cur">
                      &#8377;
                      <label className="fund-amount-label-value" style={{ height: 0 }}>
                        {/* {console.log(final1.total_management_amount,"336")} */}
                        {' '}
                        {final1.total_management_amount !== null && final1.total_management_amount !== "0"
                          ? changeNumberFormat(Number(final1.total_management_amount), 2, 1)
                          : 0}
                      </label>
                      {/* Cr */}
                    </span>
                    <br />
                    <span className="fund-amount-label">Total Management <br />Fees</span>
                    <br />
                  </div>
                  <div className="col-md-3 left-align">
                    <span className="fund-amount-cur">
                      <span className="fund-amount-label-value">
                        {/* { //console.log(trancheData,"tranchData")} */}
                        {/* {trancheData.length}{' '} */}
                        {final1.total_no_of_tranches == null ? 0 : final1.total_no_of_tranches}
                      </span>
                    </span>
                    <br />
                    <span className="fund-amount-label">
                      Total No. of Tranches
                    </span>
                  </div>

                  <div className="col-md-4 right">
                    <br />
                    {/* <a
                      className="incubator-details-link"
                      onClick={() => setIsAddTrancheRowVisible(true)}
                    >
                      +Add More Tranches{' '}
                    </a> */}
                  </div>
                </div>

                <div className="row">
                  <div className="table-scroll ">
                    <table id="example">
                      <thead>
                        <tr>
                          <th className="text-left custom-td-left table-heading">
                            Tranche Request
                          </th>
                          <th className="text-left custom-td-left table-heading">
                            Funds Approved
                          </th>
                          <th className="text-left table-heading">
                            Funds Disbursed
                          </th>
                          <th className="text-left table-heading"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {isAddTrancheRowVisible1 == true ? (
                          <tr>
                            <td className="text-left ">
                              <span className="fund-amount-label">
                                {' '}
                                Tranche {final1.total_no_of_tranches + 1}
                              </span>
                            </td>
                            <td className="text-left custom-td-left">
                              <input
                                type="input"
                                placeholder="Enter"
                                name="amount_approved"
                                className="form-control input-height"
                                onChange={handleChange1}
                                value={addMoreTrancheData1.amount_approved}
                              />
                            </td>
                            <td className="text-left custom-td-left"></td>
                            <td>
                              <a
                                className="incubator-details-link"
                                // href="#"
                                onClick={() => addMoreTranche1()}
                              >
                                Save
                              </a>
                              |
                              <a
                                className="incubator-details-link"
                                href=""
                                onClick={() => cancel()}
                                data-dismiss="modal"
                              >
                                Cancel
                              </a>
                            </td>
                          </tr>
                        ) : (
                          ''
                        )}
                        {/* {console.log(employeeData1,"releeeeeeeeeeeeee")} */}
                        {employeeData1 != undefined && employeeData1.length > 0 && final1.tranch_id !== null
                          ?
                          employeeData1.map((item: any, i: any) => (
                            <tr key={i}>
                              <td className="text-left ">
                                <span className="incubator-label-value">
                                  {item.tranche_number}
                                </span>
                                <br />
                                <span className="incubator-label">
                                  {' '}
                                  {item.tranche_requested_date != null ? formatDate(item.tranche_requested_date) : "-"}
                                </span>
                              </td>
                              <td className="text-left ">
                                <span className="incubator-label-value">
                                  {' '}

                                  <input
                                    type="input"
                                    placeholder="Enter"
                                    name="approved_amount"
                                    className="form-control input-height"
                                    onChange={(e) => handleChangeEdit1(e, item.tranch_id)}
                                    // value={changeNumberFormat(Number(item.approved_amount), 2, 1)}
                                    value={item.editchecked == "Yes" ? changeNumberFormat(Number(item.approved_amount), 2, 1) : item.approved_amount}
                                    disabled={item.editchecked == "Yes"}


                                  />
                                  {/* {changeNumberFormat(Number(item.approved_amount), 2, 1)} */}
                                </span>
                                <br />
                                {item.approved_amount != null ? (
                                  <span className="incubator-label">
                                    {' '}
                                    {item.approved_amount_date != null ? formatDate(item.approved_amount_date) : "-"}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </td>
                              <td className="text-left ">
                                <span className="incubator-label-value">
                                  {' '}
                                  <input
                                    type="input"
                                    placeholder="Enter"
                                    name="fund_released"
                                    className="form-control input-height"
                                    onChange={(e) => handleChangeEdit1(e, item.tranch_id)}
                                    // value={changeNumberFormat(Number(item.fund_released), 2, 1)}
                                    // value={item.fund_released}
                                    value={item.editchecked == "Yes" ? changeNumberFormat(Number(item.fund_released), 2, 1) : item.fund_released}
                                    // disabled={item.editchecked == "Yes"}
                                    disabled

                                  />
                                  {/* {changeNumberFormat(Number(item.fund_released), 2, 1)} */}
                                </span>
                                <br />
                                {item.fund_released != null ? (
                                  <span className="incubator-label">
                                    {' '}
                                    {item.date_of_release != null ? formatDate(item.date_of_release) : "-"}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </td>
                              <td className="text-left custom-td-left">
                                {/* <td> */}
                                {item.editchecked == "Yes" ? <a
                                  className="incubator-details-link"
                                  // href="#"
                                  onClick={() => addMoreTranche1Edit(item.tranch_id, item, i)}
                                >
                                  Edit
                                </a> : null}
                                {/* {console.log(item.editchecked,"editttttttttt")} */}
                                {item.editchecked == "No" ? <a
                                  className="incubator-details-link"
                                  // href="#"
                                  onClick={() => addMoreTranche1Save(item, i)}
                                >
                                  Save
                                </a> : null}
                                |

                                {
                                  item.tranches_status_label == 'Disbursed' ? <>
                                    <a
                                      className="incubator-details-link"
                                      href=""
                                      onClick={() => cancel()}
                                      data-dismiss="modal"
                                    >
                                      Cancel
                                    </a>
                                  </> :
                                    item.editchecked == "Yes" ? <a
                                      className="incubator-details-link"
                                      onClick={() => deleteRowModal1(item.tranch_id, item.application_id, i)}
                                      data-target="#deleteTranch"
                                      data-toggle="modal"
                                    // data-dismiss="modal"
                                    >
                                      Delete
                                      <br />
                                    </a> :
                                      item.editchecked == "No" ? <a
                                        className="incubator-details-link"
                                        href=""
                                        onClick={() => cancel()}
                                        data-dismiss="modal"
                                      >
                                        Cancel
                                      </a> : null}

                                {/* <a
                                  className="incubator-details-link"
                                  href=""
                                  onClick={() => cancel()}
                                  data-dismiss="modal"
                                >
                                  Cancel
                                </a> */}
                                {/* </td> */}
                                {/* <a
                                        className="incubator-details-link"
                                        href=""
                                        onClick={() =>
                                          handleRowChange(item.application_id)
                                        }
                                        data-dismiss="modal"
                                      >
                                        View
                                      </a> */}
                              </td>
                            </tr>
                          ))
                          : ''}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row pt-3 mb-3">
                <div className="col-md-12 centerText">
                  <button
                    className="tertiary  btn-yellow-primary"
                    // data-dismiss="modal"
                    onClick={cancel}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      {/* {delete tranch modal} */}
      <div
        className="modal fade"
        id="deleteTranch"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none">

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h5 className="modal-title text-center"
                id="exampleModalLongTitle"
                style={{ color: '#E26640' }}
              >
                Are you sure you want to delete Tranche
              </h5><br />
              <div className="row">
                <div className="col-md-12 text-center">
                  <div className="btn-group mt-1-5 ">



                    <button
                      className="tertiary btn-transparent-primary"
                      data-dismiss="modal"
                      style={{ width: '107.17px' }}
                    >
                      Cancel
                    </button>


                    <button
                      type="button"
                      className="btn btn-sisfs btn-yellow-primary ml-2"
                      // data-dismiss="modal"
                      onClick={() => deleteTranch(trancheeId, tranchApplication)}
                    >
                      Delete{' '}
                    </button>


                  </div>
                </div>
              </div>

              <div className="row pt-3 mb-3">
                <div className="col-md-12 centerText"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 1st bank details model */}
      <div
        className="modal fade"
        id="viewBankDetails1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none ml-18px">
              <h5 className="modal-title pl-2" id="exampleModalLongTitle">
                View Bank Details<span></span>
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body text-left">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <span className="incubator-label-value">Account Holder</span>
                  </div>
                </div>

                <div className="row mt-1-5">
                  <div className="col-md-6 left">
                    <span className="incubator-label">Account Name </span>
                  </div>
                  <div className="col-md-6 left">
                    <span className="incubator-label">PFMS</span>
                  </div>
                </div>
                {/* {console.log(accountDetailsData1.length,"accountDetailsData1")}  */}

                <div className="row ">
                  <div className="col-md-6 left">
                    <span className="incubator-label-value">
                      {accountDetailsData1.bank_account_holder_name == '' ||
                        accountDetailsData1.bank_account_holder_name == null
                        ? '-'
                        : accountDetailsData1.bank_account_holder_name}
                    </span>
                  </div>
                  <div className="col-md-6 left">
                    {
                      subsequentDetails?.pfms_file_path_file_name == null && accountDetailsData1.pan_card ?
                        Object.keys(accountDetailsData1.pan_card).length > 0 ? 
                        <div className="row mt-2">
                          <div className="col-md-3">
                            {getFileDetails(
                              accountDetailsData1.pan_card.file,
                              'ext'
                            ) == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(
                              accountDetailsData1.pan_card.file,
                              'ext'
                            ) == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(
                              accountDetailsData1.pan_card.file,
                              'ext'
                            ) == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-9" style={{ wordBreak: 'break-all' }}>
                            <span className="document-label-value">
                              {getFileDetails(accountDetailsData1.pan_card.file, 'name')}
                            </span>
                            <br />
                            <a
                              href={getFileDetails(accountDetailsData1.pan_card.file, "file")}
                              target="_blank"
                              className="incubator-details-link"
                            >
                              View
                            </a>
                          </div>
                        </div>
                          : subsequentDetails?.pfms_file_path_file_name != "" ?
                            <div className="row mt-2">
                              {
                                subsequentDetails?.pfms_file_path == "No FIle provided by user" ?
                                  <div className="col-12">
                                    <span className='document-label-value'>
                                      {subsequentDetails?.pfms_file_path_file_name}
                                    </span>
                                  </div>
                                  :
                                  <>
                                    <div className="col-md-3">
                                      {getFileDetails(
                                        subsequentDetails?.pfms_file_path,
                                        'ext'
                                      ) == 'pdf' ? (
                                        <img src={pdf} alt="SISFS Logo" />
                                      ) : getFileDetails(
                                        subsequentDetails.pfms_file_path,
                                        'ext'
                                      ) == 'doc' ? (
                                        <img src={doc} alt="SISFS Logo" />
                                      ) : getFileDetails(
                                        subsequentDetails.pfms_file_path,
                                        'ext'
                                      ) == 'xls' ? (
                                        <img src={xls} alt="SISFS Logo" />
                                      ) : (
                                        <img src={Allround} alt="SISFS Logo" />
                                      )}
                                    </div>
                                    <div className="col-md-9" style={{ wordBreak: 'break-all' }}>
                                      <span className="document-label-value">
                                        {getFileDetails(
                                          subsequentDetails.pfms_file_path,
                                          'name'
                                        )}
                                      </span>
                                      <br />
                                      <a
                                        href={`${NODE_URL + subsequentDetails.pfms_file_path}`}
                                        target="_blank"
                                        className="incubator-details-link"
                                      >
                                        View
                                      </a>
                                    </div>
                                  </>
                              }
                            </div>
                            : "-" :
                        <div className="row mt-2">
                          {
                            subsequentDetails?.pfms_file_path == "No FIle provided by user" ?
                              <div className="col-12">
                                <span className='document-label-value'>
                                  {subsequentDetails?.pfms_file_path_file_name}
                                </span>
                              </div>
                              :
                              <>
                                <div className="col-md-3">
                                  {getFileDetails(
                                    subsequentDetails?.pfms_file_path,
                                    'ext'
                                  ) == 'pdf' ? (
                                    <img src={pdf} alt="SISFS Logo" />
                                  ) : getFileDetails(
                                    subsequentDetails.pfms_file_path,
                                    'ext'
                                  ) == 'doc' ? (
                                    <img src={doc} alt="SISFS Logo" />
                                  ) : getFileDetails(
                                    subsequentDetails.pfms_file_path,
                                    'ext'
                                  ) == 'xls' ? (
                                    <img src={xls} alt="SISFS Logo" />
                                  ) : (
                                    <img src={Allround} alt="SISFS Logo" />
                                  )}
                                </div>
                                <div className="col-md-9" style={{ wordBreak: 'break-all' }}>
                                  <span className="document-label-value">
                                    {getFileDetails(
                                      subsequentDetails.pfms_file_path,
                                      'name'
                                    )}
                                  </span>
                                  <br />
                                  <a
                                    href={`${NODE_URL + subsequentDetails.pfms_file_path}`}
                                    target="_blank"
                                    className="incubator-details-link"
                                  >
                                    View
                                  </a>
                                </div>
                              </>
                          }
                        </div>}

                  </div>
                </div>

                <div className="row mt-1-5">
                  <div className="col-md-6 left">
                    <span className="incubator-label">
                      ECS Mandate Form
                    </span>
                    <br />
                    {
                      subsequentDetails?.ECS_Mandate_file_path_file_name == null && accountDetailsData1.pan_card ?
                        Object.keys(accountDetailsData1.cancelled_cheque).length > 0 ?
                          <div className="row mt-2">
                            <div className="col-md-3">
                              {getFileDetails(
                                accountDetailsData1.cancelled_cheque.file,
                                'ext'
                              ) == 'pdf' ? (
                                <img src={pdf} alt="SISFS Logo" />
                              ) : getFileDetails(
                                accountDetailsData1.cancelled_cheque.file,
                                'ext'
                              ) == 'doc' ? (
                                <img src={doc} alt="SISFS Logo" />
                              ) : getFileDetails(
                                accountDetailsData1.cancelled_cheque.file,
                                'ext'
                              ) == 'xls' ? (
                                <img src={xls} alt="SISFS Logo" />
                              ) : (
                                <img src={Allround} alt="SISFS Logo" />
                              )}
                            </div>
                            <div className="col-md-9" style={{ wordBreak: 'break-all' }}>
                              <span className="document-label-value">
                                {getFileDetails(
                                  accountDetailsData1.cancelled_cheque.file,
                                  'name'
                                )}
                              </span>
                              <br />
                              <a
                                href={getFileDetails(accountDetailsData1.cancelled_cheque.file, "file")}
                                target="_blank"
                                className="incubator-details-link"
                              >
                                View
                              </a>
                            </div>
                          </div>
                          : subsequentDetails && subsequentDetails?.ECS_Mandate_file_path_file_name != "" ?
                            <div className="row mt-2">
                              <div className="col-md-3">
                                {getFileDetails(
                                  subsequentDetails.ECS_Mandate_file_path,
                                  'ext'
                                ) == 'pdf' ? (
                                  <img src={pdf} alt="SISFS Logo" />
                                ) : getFileDetails(
                                  subsequentDetails?.ECS_Mandate_file_path,
                                  'ext'
                                ) == 'doc' ? (
                                  <img src={doc} alt="SISFS Logo" />
                                ) : getFileDetails(
                                  subsequentDetails.ECS_Mandate_file_path,
                                  'ext'
                                ) == 'xls' ? (
                                  <img src={xls} alt="SISFS Logo" />
                                ) : (
                                  <img src={Allround} alt="SISFS Logo" />
                                )}
                              </div>
                              <div className="col-md-9" style={{ wordBreak: 'break-all' }}>
                                <span className="document-label-value">
                                  {getFileDetails(
                                    subsequentDetails.ECS_Mandate_file_path,
                                    'name'
                                  )}
                                </span>
                                <br />
                                <a
                                  href={`${NODE_URL + subsequentDetails.ECS_Mandate_file_path}`}
                                  target="_blank"
                                  className="incubator-details-link"
                                >
                                  View
                                </a>
                              </div>
                            </div>
                            : "-" : <div className="row mt-2">
                          <div className="col-md-3">
                            {getFileDetails(
                              subsequentDetails.ECS_Mandate_file_path,
                              'ext'
                            ) == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(
                              subsequentDetails?.ECS_Mandate_file_path,
                              'ext'
                            ) == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(
                              subsequentDetails.ECS_Mandate_file_path,
                              'ext'
                            ) == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-9" style={{ wordBreak: 'break-all' }}>
                            <span className="document-label-value">
                              {getFileDetails(
                                subsequentDetails.ECS_Mandate_file_path,
                                'name'
                              )}
                            </span>
                            <br />
                            <a
                              href={`${NODE_URL + subsequentDetails.ECS_Mandate_file_path}`}
                              target="_blank"
                              className="incubator-details-link"
                            >
                              View
                            </a>
                          </div>
                        </div>}
                    {/* {accountDetailsData1.cancelled_cheque_location != undefined && accountDetailsData1.cancelled_cheque_location != null ?
                      <div className="row">
                        <div className="col-md-3">
                          {getFileDetails(
                            accountDetailsData1.cancelled_cheque_location,
                            'ext'
                          ) == 'pdf' ? (
                            <img src={pdf} alt="SISFS Logo" />
                          ) : getFileDetails(
                            accountDetailsData1.cancelled_cheque_location,
                            'ext'
                          ) == 'doc' ? (
                            <img src={doc} alt="SISFS Logo" />
                          ) : getFileDetails(
                            accountDetailsData1.cancelled_cheque_location,
                            'ext'
                          ) == 'xls' ? (
                            <img src={xls} alt="SISFS Logo" />
                          ) : (
                            <img src={Allround} alt="SISFS Logo" />
                          )}
                        </div>
                        <div className="col-md-9" style={{ wordBreak: 'break-all' }}>
                          <span className="document-label-value">
                            {getFileDetails(
                              accountDetailsData1.cancelled_cheque_location,
                              'name'
                            )}
                          </span>
                          <br />
                          <br />
                          <a
                            href={(accountDetailsData1.application_index == 'F1' ? (`${File_node_url}` + accountDetailsData1.cancelled_cheque_location) : (`${NODE_URL}` + '/' + accountDetailsData1.cancelled_cheque_location))}
                            target="_blank"
                            //download="proof_of_disbursement"
                            className="incubator-details-link"
                          >
                            View
                          </a>
                        </div>
                      </div>
                      : ""} */}
                  </div>
                  <div className="col-md-4">
                    <span className="incubator-label">
                      DARPAN ID
                    </span>
                    <br />
                    {
                      subsequentDetails?.proof_Of_Darpan_file_path_file_name == null && accountDetailsData1.pan_card ?
                        Object.keys(accountDetailsData1.darpan).length > 0 ? <div className="row mt-2">
                          <div className="col-md-3">
                            {getFileDetails(
                              accountDetailsData1.darpan.file,
                              'ext'
                            ) == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(
                              accountDetailsData1.darpan.file,
                              'ext'
                            ) == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(
                              accountDetailsData1.darpan.file,
                              'ext'
                            ) == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-9" style={{ wordBreak: 'break-all' }}>
                            <span className="document-label-value">
                              {getFileDetails(
                                accountDetailsData1.darpan.file,
                                'name'
                              )}
                            </span>
                            <br />
                            <a
                              href={getFileDetails(accountDetailsData1.darpan.file, "file")}
                              target="_blank"
                              className="incubator-details-link"
                            >
                              View
                            </a>
                          </div>
                        </div>
                          :
                          subsequentDetails?.proof_Of_Darpan_file_path_file_name != "" ?
                            <div className="row mt-2">
                              <div className="col-md-3">
                                <span className='document-label-value'>

                                  {subsequentDetails?.proof_Of_Darpan_file_path_file_name}
                                </span>
                              </div>
                            </div> : "-" :

                        <div className="row mt-2">
                          <div className="col-md-3">
                            <span className='document-label-value'>

                              {subsequentDetails?.proof_Of_Darpan_file_path_file_name}
                            </span>
                          </div>
                        </div>}
                    {/* {accountDetailsData1.darpan_location != undefined && accountDetailsData1.darpan_location != null ?
                      <div className="row">
                        <div className="col-md-3">
                          {getFileDetails(
                            accountDetailsData1.darpan_location,
                            'ext'
                          ) == 'pdf' ? (
                            <img src={pdf} alt="SISFS Logo" />
                          ) : getFileDetails(
                            accountDetailsData1.darpan_location,
                            'ext'
                          ) == 'doc' ? (
                            <img src={doc} alt="SISFS Logo" />
                          ) : getFileDetails(
                            accountDetailsData1.darpan_location,
                            'ext'
                          ) == 'xls' ? (
                            <img src={xls} alt="SISFS Logo" />
                          ) : (
                            <img src={Allround} alt="SISFS Logo" />
                          )}
                        </div>
                        <div className="col-md-9" style={{ wordBreak: 'break-all' }}>
                          <span className="document-label-value">
                            {getFileDetails(
                              accountDetailsData1.darpan_location,
                              'name'
                            )}
                          </span>
                          <br />
                          <br />
                          <a
                            href={(accountDetailsData1.application_index == 'F1' ? (`${File_node_url}` + accountDetailsData1.darpan_location) : (`${NODE_URL}` + '/' + accountDetailsData1.darpan_location))}
                            target="_blank"
                            //download="proof_of_disbursement"
                            className="incubator-details-link"
                          >
                            View
                          </a>
                        </div>
                      </div>
                      : ""} */}
                  </div>
                </div>
                <div className="row mt-1-5">
                  <div className="col-md-6 left">
                    <span className="incubator-label">
                      TRA Agreement
                    </span>
                    <br />
                    {
                      accountDetailsData1.tra_agreement_name == null ? "-"
                        : accountDetailsData1.tra_agreement_name != "" ?
                          <div className="row mt-2">
                            <div className="col-md-3">
                              {getFileDetails(
                                accountDetailsData1.tra_agreement_location,
                                'ext'
                              ) == 'pdf' ? (
                                <img src={pdf} alt="SISFS Logo" />
                              ) : getFileDetails(
                                accountDetailsData1.tra_agreement_location,
                                'ext'
                              ) == 'doc' ? (
                                <img src={doc} alt="SISFS Logo" />
                              ) : getFileDetails(
                                accountDetailsData1.tra_agreement_location,
                                'ext'
                              ) == 'xls' ? (
                                <img src={xls} alt="SISFS Logo" />
                              ) : (
                                <img src={Allround} alt="SISFS Logo" />
                              )}
                            </div>
                            <div className="col-md-9" style={{ wordBreak: 'break-all' }}>
                              <span className="document-label-value">
                                {getFileDetails(
                                  accountDetailsData1.tra_agreement_location,
                                  'name'
                                )}
                              </span>
                              <br />
                              <a
                                href={`${NODE_URL + '/filestorage/' + accountDetailsData1.tra_agreement_location}`}
                                target="_blank"
                                className="incubator-details-link"
                              >
                                View
                              </a>
                            </div>
                          </div>
                          : "-"}
                  </div>
                  <div className="col-md-4">
                    <span className="incubator-label">
                      Sanction Order
                    </span>
                    <br />
                    {
                      accountDetailsData1.sanction_name == null ? "-"
                        : accountDetailsData1.sanction_name != "" ?
                          <div className="row mt-2">
                            <div className="col-md-3">
                              {getFileDetails(
                                accountDetailsData1.sanction_location,
                                'ext'
                              ) == 'pdf' ? (
                                <img src={pdf} alt="SISFS Logo" />
                              ) : getFileDetails(
                                accountDetailsData1.sanction_location,
                                'ext'
                              ) == 'doc' ? (
                                <img src={doc} alt="SISFS Logo" />
                              ) : getFileDetails(
                                accountDetailsData1.sanction_location,
                                'ext'
                              ) == 'xls' ? (
                                <img src={xls} alt="SISFS Logo" />
                              ) : (
                                <img src={Allround} alt="SISFS Logo" />
                              )}
                            </div>
                            <div className="col-md-9" style={{ wordBreak: 'break-all' }}>
                              <span className="document-label-value">
                                {getFileDetails(
                                  accountDetailsData1.sanction_location,
                                  'name'
                                )}
                              </span>
                              <br />
                              <a
                                href={`${NODE_URL + '/filestorage/' + accountDetailsData1.sanction_location}`}
                                target="_blank"
                                className="incubator-details-link"
                              >
                                View
                              </a>
                            </div>
                          </div>
                          : "-"}
                  </div>
                </div>
                <div className="application-incubator-hr">

                  <hr />
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <span className="incubator-label-value">Account Details</span>
                  </div>
                </div>

                <div className="row mt-1-5">
                  <div className="col-md-6 left">
                    <span className="incubator-label">Bank Name</span>
                  </div>
                  <div className="col-md-4">
                    <span className="incubator-label">Account Number</span>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-6 left">
                    <span className="incubator-label-value">
                      {accountDetailsData1.bank_name == '' ||
                        accountDetailsData1.bank_name == null
                        ? '-'
                        : accountDetailsData1.bank_name}
                    </span>
                  </div>
                  <div className="col-md-6">
                    <span className="incubator-label-value">
                      {accountDetailsData1.account_number == '0' ||
                        accountDetailsData1.account_number == null
                        ? '-'
                        : accountDetailsData1.account_number}
                    </span>
                  </div>
                </div>

                <div className="row mt-1-5">
                  <div className="col-md-6 left">
                    <span className="incubator-label">Account Type</span>
                  </div>
                  <div className="col-md-6 left">
                    <span className="incubator-label">
                      Branch Name/Branch Code
                    </span>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-6 left">
                    <span className="incubator-label-value">
                      {accountDetailsData1.account_type == 0 ||
                        accountDetailsData1.account_type == null
                        ? '-'
                        : accountDetailsData1.account_type}
                    </span>
                  </div>
                  <div className="col-md-6 left">
                    <span className="incubator-label-value">
                      {accountDetailsData1.branch_name == '' ||
                        accountDetailsData1.branch_name == null
                        ? '-'
                        : accountDetailsData1.branch_name}
                    </span>
                  </div>
                </div>

                <div className="row mt-1-5">
                  <div className="col-md-6 left">
                    <span className="incubator-label">IFSC Code</span>
                  </div>
                  <div className="col-md-6 left">
                    <span className="incubator-label">MICR Code</span>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-6 left">
                    <span className="incubator-label-value">
                      {accountDetailsData1.bank_ifsc_code == '' ||
                        accountDetailsData1.bank_ifsc_code == null
                        ? '-'
                        : accountDetailsData1.bank_ifsc_code}
                    </span>
                  </div>
                  <div className="col-md-6 left">
                    <span className="incubator-label-value">
                      {accountDetailsData1.bank_micr_code == '' ||
                        accountDetailsData1.bank_micr_code == null
                        ? '-'
                        : accountDetailsData1.bank_micr_code}
                    </span>
                  </div>
                </div>

                <div className="row mt-1-5">
                  <div className="col-md-6 left">
                    <span className="incubator-label">Bank Address</span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 left">
                    <span className="incubator-label-value">
                      {accountDetailsData1.branch_address == '' ||
                        accountDetailsData1.branch_address == null
                        ? '-'
                        : accountDetailsData1.branch_address}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row pt-3 mb-3">
                <div className="col-md-12 centerText">
                  <button
                    className="tertiary  btn-yellow-primary"
                    data-dismiss="modal"
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {reasonPopup2 && (
        <>
          <div className="modal-backdrop fade show"></div>

          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionSuccess"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <div className="row">
                    <div className="col-4 text-left">Reason:</div>
                    <div className="col-8" >
                      <textarea readOnly name="" id="" style={{ width: '100%', padding: '5px 20px', height: '100px' }}>
                        {final2.Reduced_management_fee_comment}
                      </textarea>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-4 text-left">Uploaded File:</div>
                    <div className="col-8 d-flex">
                      <div>
                        <img src={pdf} alt="" />
                      </div>
                      <div>
                        <a className="incubator-details-link" href={NODE_URL + final2.Reduced_management_fee_reason_file_path}>{final2.Reduced_management_fee_reason_file_name}</a>
                      </div>
                    </div>
                  </div>
                  <div className="modal-action">
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={() => setReasonPopup2(false)}
                    >
                      Ok !!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {
                alertPopUp && (
                    <div
                        className="modal align-middle modal-sisfs d-block"
                        id="FormSubmissionError"
                    >
                        <div className="modal-dialog modal-dialog-centered modal-md">
                            <div className="modal-content">
                                <div className="modal-body text-center">

                                    <h3 className="modal-message mt-2">
                                        Are you sure you want to approve total grant amount.
                                    </h3>
                                    <h6 className=" message-orange">
                                        Note: you are no longer able to edit again, Once approved.
                                    </h6>
                                    <div className="modal-action mt-2">
                                        <button
                                            className="btn btn-sisfs btn-transparent-primary"
                                            onClick={() =>  setAlertPopUp(false)}>
                                            NO
                                        </button>
                                        <button
                                            className="btn btn-sisfs btn-yellow-primary ml-2"
                                            onClick={()=> grantPopup==1? updateTotalGrantApproved1() : updateTotalGrantApproved2()}
                                        >
                                            YES
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
      {reasonPopup && (
        <>
          <div className="modal-backdrop fade show"></div>

          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionSuccess"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <div className="row">
                    <div className="col-4 text-left">Reason:</div>
                    <div className="col-8" >
                      <textarea readOnly name="" id="" style={{ width: '100%', padding: '5px 20px', height: '100px' }}>
                        {final1.Reduced_management_fee_comment}
                      </textarea>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-4 text-left">Uploaded File:</div>
                    <div className="col-8 d-flex">
                      <div>
                        <img src={pdf} alt="" />
                      </div>
                      <div>
                        <a className="incubator-details-link" href={NODE_URL + final1.Reduced_management_fee_reason_file_path}>{final1.Reduced_management_fee_reason_file_name}</a>
                      </div>
                    </div>
                  </div>
                  <div className="modal-action">
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={() => setReasonPopup(false)}
                    >
                      Ok !!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* sccess and failure models  */}
      {showEditSuccessModal && (
        <>
          <div className="modal-backdrop fade show"></div>

          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionSuccess"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <i className="fa fa-check-circle-o"></i>
                  <h3 className="modal-message">Thank You !</h3>
                  <div className="modal-text">
                    Tranche Details Updated Successfully !!
                  </div>
                  <div className="modal-action">
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={dismissModal}
                    >
                      Ok !!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* sccess and failure models  */}
      {trancheDeleteModal && (
        <>
          <div className="modal-backdrop fade show"></div>

          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionSuccess"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <i className="fa fa-check-circle-o"></i>
                  <h3 className="modal-message">Thank You !</h3>
                  <div className="modal-text">
                    Tranche Delete Successfully !!
                  </div>
                  <div className="modal-action">
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={dismissModal}
                    >
                      Ok !!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {showEditFailureModal && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionError"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  {/* <i className="fa fa-times-circle-o"></i> */}
                  <h3 className="modal-message message-orange">
                    Oops! The Amount Should be Greater than 0
                  </h3>
                  <br />
                  <h3 className="modal-message message-orange">
                    Please Try Again !!!
                  </h3>
                  <div className="modal-action">
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={dismissModal1}
                    >
                      OK !
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showIncubatorFailureModal && (
        <ConfirmationDialogNote
          headerText={failDialogData.header}
          bodyText={failDialogData.text}
          bodyNote={failDialogData.note}
          onDialogClose={() => setShowIncubatorFailureModal(false)}
          iconClass="showImage"
        />
      )}

    </div>
  );
}
export default MergedFundDetailsComponent;
