

export class EntityDetailModel {
    constructor(
        public recognition_number: string,
        public name_of_entity: string,
        public nature_of_entity: string,    
        public industry_ids: Array<string>,
        public industry_id_other: string,
        public industry_id_other_show:boolean,
        public sector_ids: Array<string>,
        public company_information_number: string,       
        public registration_date: string,
        public pan_number: string,
        public state_id: string,
        public city_id: string,
        public startup_address: string,      
    ) {
        this.recognition_number = recognition_number;
        this.name_of_entity = name_of_entity;
        this.nature_of_entity = nature_of_entity;
        this.industry_ids = industry_ids;
        this.industry_id_other = industry_id_other;
        this.industry_id_other_show=industry_id_other_show;
        this.sector_ids = sector_ids;
        this.company_information_number = company_information_number;
        this.registration_date = registration_date;
        this.pan_number = pan_number;
        this.state_id = state_id;
        this.city_id = city_id;
        this.startup_address = startup_address;
    }
}