import React, { Component, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import '../incubator/incubator.css';
import EACReview from '../incubator/EACReviewComponent';
import Contact from '../incubator/ContactComponent';
import Document from '../incubator/DocumentComponent';
import ApplicationDetails from '../incubator/ApplicationDetailsComponent';
import FundDetailsComponent from './IncubatorFundDetailsComponent';
import {
  GET_ALL_TRANCHES,
  GET_APPLICATION_STATUS,
  GET_BANK_DETAILS,
  GET_INCUBATOR_DETAILS,
  GET_QUICK_EDIT,
  INCUBATOR_CANCEL_DETAIL,
  QUICK_EDIT,
  SCHEDULE_MEETING,
  RE_INCUBATOR_CANCEL_DETAIL
} from '../../../constants/api';
import { get, post } from '../../../effects/api/api.common';
import { IncubatorDetails } from './IncubatorDetails';
import { IncubatorDetailsModel } from '../../../models/incubator/IncubatorDetailsModel';
//import { AnyNsRecord } from 'node:dns';
import { IIncubatorModel } from '../../incubator/application/IncubatorModel';
import { FundDetailsModel } from '../../../models/incubator/FundDetailsModel';
import { IncubatorTeamModel } from '../../../models/incubator/IncubatorTeamModel';
import { GeneralDetailModel } from '../../../models/incubator/GeneralDetailModel';
import { IncubatorOthersModel } from '../../../models/incubator/IncubatorOthersModel';
import {
  ApplicationGeneralDetails,
  ApplicationStatus,
  FileDownloadModel,
  OthersModel,
  QuickEditPopupData,
} from './ApplicationGeneralDetails';
import { UploadTypeEnum } from '../../../utils/Enums';
import { AccountFileUploadModel, FileUploadModel, TrancheFileUploadModel } from '../../../models/FileUploadModel';
import { changeNumberFormat, createFile } from '../../../utils/CommonMethods';
import Calendar from '../../common/controls/Calendar';
import UserInfoModel from '../../../models/UserInfoModel';
import { getSessionStorageValue } from '../../../effects/utils/session-storage';
import IncubatorLeftPanelComponent from './IncubatorLeftPanelComponent';
import { AccountDetailsData } from '../../../models/funddetails/AccountDetailsData';
import { FileUploadModelTranche, TrancheDetail } from '../../../models/funddetails/TrancheDetails';
import CancellationContainer from './CancellationContainer';
import CommentContainer from './CommentContainer';

interface IIncubatorDetailsProps {
  application_id: number;
}

interface IIncubatorDetailsState {
  //incubator: IIncubatorModel;
  incubatorDetailsModel: IncubatorDetails;
  applicationGeneral: ApplicationGeneralDetails;
  otherDetailsModel: OthersModel;
  isSelectedOrClosed: boolean;
  applicationId: number;
  checkedArray: Array<string>;
  fromTime: string;
  toTime: string;  
  meetingLink:string,
  incubatorMeetingType: string;
  scheduleDate: String;
  selectFlag: boolean;
  showEditFailureModalPopUp: boolean;
  showEditSuccessModalPopUp: boolean;
  applicationStatus: Array<ApplicationStatus>;
  application_status: number;
  application_comments: string;
  successMsg: string;
  user: boolean;  
  loggedInRole:string;
  history: any;
  tranches: Array<TrancheDetail>;
  tranchesAsc: Array<TrancheDetail>;
  bankDetails: AccountDetailsData;
  total_grant_disbursed: number;
  isFundDetailsTabActive: boolean;
  cancellationData: Array<any>;
  commentData:Array<any>;
  count_startups_supported:string;
  amount_disbursed:string;
  // total_management_amount:string;
  user_id:string;
  total_management_amount:number;
  meetingComment:string
  
}

class ViewIncubatorDetails extends Component<
  IIncubatorDetailsProps,
  IIncubatorDetailsState
> {
  state: IIncubatorDetailsState = {
    applicationGeneral: new ApplicationGeneralDetails(
      0,
      0,
      '',
      0,
      0,
      '',
      '',
      '',
      0,
      '',
      '',
      '',
      0,
      '',
      0,
      0,
      0,
      [],
      '',
      '',
      '',
      '',
      0,
      '',
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      '',
      0,
      '',
      0,
      '',
      0,
      '',
      0,
      '',
      0,
      '',
      0,
      '',
      0,
      '',
      0,
      '',
      0,
      0,
      0,
      0,
      0,
      '',
      0,
      0,
      0,
      0,
      0,
      false,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      [],
      [],
      [],
      new OthersModel([], [], [], [], [], [], [], [], [], [], [], [], []),
      '',
      '',
      '',
      '',
      '',
      '',
      0,
      0,
      0,
      '',
      '',
      '',
      '',
      
    ),
    incubatorDetailsModel: new IncubatorDetails(
      0,
      0,
      0,
      '',
      0,
      0,
      '',
      '',
      '',
      0,
      '',
      '',
      '',
      0,
      '',
      0,
      0,
      0,
      [],
      '',
      '',
      '',
      '',
      0,
      '',
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      '',
      '',
      0,
      0,
      0,
      0,
      0,   
      0,
      0,  
      false,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      [],
      0,
      [],
      [],
      [],
    ),
    otherDetailsModel: new OthersModel([], [], [], [], [], [], [], [], [], [], [], [], []),
    isSelectedOrClosed: true,
    applicationId: 0,
    checkedArray: [],
    fromTime: '',
    toTime: '',
    meetingLink:'',
    incubatorMeetingType: '1',
    scheduleDate: '',
    selectFlag: true,

    showEditFailureModalPopUp: false,
    showEditSuccessModalPopUp: false,
    applicationStatus: [],
    application_status: 0,
    application_comments: '',
    successMsg: '',
    user: true,    
loggedInRole:'',
    history: '',
    tranches: [],
    tranchesAsc: [],
    bankDetails: new AccountDetailsData(
      0,
      0,
      '',
      '',
      '',
      0,
      '',
      '',
      '',
      '',
      '',
      ''
      , "", "", "", "", new AccountFileUploadModel(new File([], "")), new AccountFileUploadModel(new File([], ""))
      ,"",new AccountFileUploadModel(new File([], "")),"",
      new AccountFileUploadModel(new File([], "")),new AccountFileUploadModel(new File([], "")),"","","",""),
    total_grant_disbursed: 0,
    isFundDetailsTabActive: false,
    cancellationData: [],
    commentData: [],
    count_startups_supported:'',
    amount_disbursed:'',
    user_id:'',
    total_management_amount:0,
    meetingComment:''
  };
  Status_ID: any;
  buttonDisable:boolean=true;

  constructor(props: any) {
    super(props);
    console.log(props.location.state);
    if (typeof props.location.state !== 'undefined') {
      this.setDetails(
        props.location.state.application_id,
        props.location.state.isFundDetails != undefined ? props.location.state.isFundDetails : false
      );
      this.state.checkedArray.push(this.state.applicationId.toString());
      
      if( props.location.state.application_index == "F1"){
        this.getActiveApplication(props.location.state.application_id);
        this.getCancellationData(props.location.state.application_id)
      }
      if( props.location.state.application_index == "R1"){
        this.getActiveApplication(props.location.state.application_id);
        this.getReCancellationData(props.location.state.application_id)
      }

    }


    
    const user = getSessionStorageValue('user');
    this.setState({
      loggedInRole: 'test',
    });
    if (user) {
      const userInfo = JSON.parse(user) as UserInfoModel;
      ////console.log(userInfo.roles,'nfo.roles');
     this.state.loggedInRole=userInfo.roles
      if (userInfo.roles == 'eac') {
        this.state.user = false;
        this.setState({
          user: false,
        });
      }
    }
    //console.log(this.state);
  }
  private getReCancellationData = (application_id: number) => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    post(RE_INCUBATOR_CANCEL_DETAIL, { "application_id": application_id ,"logged_user_id": userInfo.id, "userRole" : userInfo.roles}).then(
      (response: any) => {
        //  //console.log("CHECKING RESPONSE",response)
        // if (!response || !response.data || response.data == '') return;
        this.setState({
          cancellationData: response.data.data
        })
        // this.setState({
        //   cancellationData: response.data.data[0],
        // });
        //  //console.log("1789",this.state.cancellationData);
      }
    );
  };
  private setDetails = (appId: number, isFund: boolean) => {
    this.state.isFundDetailsTabActive = isFund;
    this.state.applicationId = appId;
  };

  reload = () => {
    //RELOAD COMPONENT
    this.componentDidMount();
  };
  
  componentDidMount() { 
   
  }

  // on change time;
  private onTimeChange = (e: any /*index:number*/) => {
    var value = e.target.value;
    var name = e.target.name;
    if (name == 'fromTime') {
      this.setState({
        fromTime: value,
      });
    } else if (name == 'toTime') {
      this.setState({
        toTime: value,
      });
    }
  };

  // on incubator dropdown change
  private handleOnChange = (e: any /*index:number*/) => {
    var value = e.target.value;
    this.setState({
      incubatorMeetingType: value,
    });
  };

  // function to format date
  private formatDate(date: Date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  private formatDateWithMonth(date: Date) {
    var month = new Array();
    month[0] = 'Jan';
    month[1] = 'Feb';
    month[2] = 'Mar';
    month[3] = 'April';
    month[4] = 'May';
    month[5] = 'Jun';
    month[6] = 'Jul';
    month[7] = 'Aug';
    month[8] = 'Sep';
    month[9] = 'Oct';
    month[10] = 'Nov';
    month[11] = 'Dec';

    var d = new Date(date),
      monthn = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (monthn.length < 2) monthn = '0' + monthn;
    if (day.length < 2) day = '0' + day;

    return [day, month[d.getMonth()], year].join(' ');
  }

  // trrigers on date change
  private handleDateChange = (date: Date, field: string) => {
    this.setState({
      scheduleDate: this.formatDate(date),
    });
  };

  private scheduleMeetingFunction = () => {
    const data = {
      meeting_date:
        this.state.scheduleDate != ''
          ? this.state.scheduleDate
          : this.formatDate(new Date()),
      meeting_from_time: this.state.fromTime != '' ? this.state.fromTime : '',
      meeting_to_time: this.state.toTime != '' ? this.state.toTime : '',
      link:this.state.meetingLink,
      comment:this.state.meetingComment,
      meeting_type:
        this.state.incubatorMeetingType != ''
          ? this.state.incubatorMeetingType
          : '',
      incubator_application_ids: [
        this.state.applicationId == 0
          ? this.state.incubatorDetailsModel.id
          : this.state.applicationId,
      ],
    };
    post(SCHEDULE_MEETING, data).then((response: any) => {
      if (!response || !response.data) return;
      if (response.data.result == 'success') {
        this.setState({
          showEditSuccessModalPopUp: true,
          successMsg: 'Your Meeting Has been Scheduled',
        });
        this.getActiveApplication(this.state.incubatorDetailsModel.id);
      } else {
        this.setState({
          showEditFailureModalPopUp: true,
        });
      }
    });
  };

  private dismissModalPopUp = () => {
    this.setState({
      showEditSuccessModalPopUp: false,
    });
    this.setState({
      showEditFailureModalPopUp: false,
    });
  };

  render() {
    return (
      <div className="container">
        <div className="">
          <div className="row">
            <div className="margin-16"></div>
          </div>
          <div className="row ">
            <div className="col-md-12">
              {/* <span className="incubators-title"> Incubators</span>&nbsp; */}
              {this.state.loggedInRole=='dpiit'&&<a
                className="incubators-title"
                href="/dpiit_incubator_list"
                aria-current="page"
              >
                Incubators {' '}
              </a>}
              {this.state.loggedInRole=='eac'&&<a
                className="incubators-title"
                href="/view_incubator_eac"
                aria-current="page"
              >
                Incubators {' '}
              </a>}
              {this.state.loggedInRole=='secretariat'&&<a
                className="incubators-title"
                href="/secretariat_incubator_list"
                aria-current="page"
              >
                Incubators {' '}
              </a>}

              <span className="incubator-name-value">
                /{' '}
                {this.state.applicationGeneral.incubator_name == ''
                  ? ''
                  : this.state.applicationGeneral.incubator_name}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 incubator-rectangle">
              <div>
                <IncubatorLeftPanelComponent
                  generalDetailsModel={this.state.incubatorDetailsModel}
                />
              </div>
              <div className="margin-3"></div>

              {this.state.isSelectedOrClosed == true ? (
                <div className="incubator-rectangle-2 margin-top-1 padding-top-12">
                  <div className="container">
                    <div className="group-11">
                      <div className="row  mt-05">
                        <div className="col-md-12">
                          <span className="incubator-label">
                            Total Grant Approved
                            
                          </span>
                          <br />
                          {/* {console.log(this.state.incubatorDetailsModel.total_approved_amt,"totalapprov")} */}

                          {this.state.incubatorDetailsModel
                            .total_approved_amt == null ||
                            this.state.incubatorDetailsModel.total_approved_amt ==
                            0 ? (
                            <span className="incubator-label-value">-</span>
                          ) : (
                            <span className="incubator-label-value">
                              &#8377;{' '}
                              {changeNumberFormat(
                                Number(
                                  this.state.incubatorDetailsModel
                                    .total_approved_amt
                                ),
                                2,
                                1
                              )}
                            </span>
                          )}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          {this.state.incubatorDetailsModel
                            .total_approved_amt == null ||
                            this.state.incubatorDetailsModel.total_approved_amt ==
                            0 ? (
                            ''
                          ) : (
                            <a
                              className="incubator-details-link"
                              data-toggle="modal"
                              data-target="#TrancheDetailsModal"
                            >
                              Tranche Details
                            </a>
                          )}
                        </div>

                        <div
                          className="modal fade"
                          id="TrancheDetailsModal"
                          role="dialog"
                          aria-labelledby="exampleModalCenterTitle"
                          aria-hidden="true"
                        >
                          <div
                            className="modal-dialog modal-dialog-centered modal-lg"
                            role="document"
                          >
                            <div className="modal-content">
                              <div className="modal-header modal-header-border-none ml-18px">
                                <h5
                                  className="modal-title pl-2"
                                  id="exampleModalLongTitle"
                                >
                                  Tranche Details<span></span>
                                </h5>
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div className="modal-body mt-15-px">
                                <div className="container">
                                  <div className="row">
                                    <div className="col-md-3 left-align">
                                      <span className="fund-amount-cur">
                                        &#8377;
                                        <span className="fund-amount-label-value">
                                          {' '}
                                          {/* {total_grand_approved} */}
                                          {this.state.incubatorDetailsModel
                                            .total_approved_amt != null
                                            ? changeNumberFormat(
                                              Number(
                                                this.state
                                                  .incubatorDetailsModel
                                                  .total_approved_amt
                                              ),
                                              2,
                                              1
                                            )
                                            : 0}{' '}
                                        </span>
                                      </span>
                                      <br />
                                      <span className="fund-amount-label">
                                        Total Grant Approved
                                    
                                      </span>
                                    </div>

                                    <div className="col-md-3 left-align">
                                      <span className="fund-amount-cur">
                                        <span className="fund-amount-label-value">
                                          {this.state.tranches.length}{' '}
                                        </span>
                                      </span>
                                      <br />
                                      <span className="fund-amount-label">
                                        Total No. of Tranches
                                      </span>
                                    </div>

                                    <div className="col-md-4 right">
                                      <br />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="table-scroll ">
                                      <table id="example">
                                        <thead>
                                          <tr>
                                            <th className="text-left custom-td-left table-heading">
                                              Tranche Request
                                            </th>
                                            <th className="text-left custom-td-left table-heading">
                                              Amount Approved
                                            </th>
                                            <th className="text-left table-heading">
                                              Amount Disbursed
                                            </th>
                                            <th className="text-left table-heading"></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.tranches != undefined &&
                                            this.state.tranches.length > 0
                                            ? this.state.tranches.map(
                                              (item: any) => (
                                                <tr>
                                                  <td className="text-left ">
                                                    <span className="incubator-label-value">
                                                      {item.tranche_number}
                                                    </span>
                                                    <br />
                                                    <span className="incubator-label">
                                                      {' '}
                                                      {item.tranche_requested_date !=
                                                        null
                                                        ? this.formatDateWithMonth(
                                                          item.tranche_requested_date
                                                        )
                                                        : '-'}
                                                    </span>
                                                  </td>
                                                  <td className="text-left ">
                                                    <span className="incubator-label-value">
                                                      {' '}
                                                      {item.approved_amount}
                                                    </span>
                                                    <br />
                                                    {item.approved_amount !=
                                                      null ? (
                                                      <span className="incubator-label">
                                                        {' '}
                                                        {item.approved_amount_date !=
                                                          null
                                                          ? this.formatDateWithMonth(
                                                            item.approved_amount_date
                                                          )
                                                          : '-'}
                                                      </span>
                                                    ) : (
                                                      ''
                                                    )}
                                                  </td>
                                                  <td className="text-left ">
                                                    <span className="incubator-label-value">
                                                      {' '}
                                                      {item.fund_released}
                                                    </span>
                                                    <br />
                                                    {item.fund_released !=
                                                      null ? (
                                                      <span className="incubator-label">
                                                        {' '}
                                                        {item.date_of_release !=
                                                          null
                                                          ? this.formatDateWithMonth(
                                                            item.date_of_release
                                                          )
                                                          : '-'}
                                                      </span>
                                                    ) : (
                                                      ''
                                                    )}
                                                  </td>
                                                  <td className="text-left custom-td-left"></td>
                                                </tr>
                                              )
                                            )
                                            : ''}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <div className="row ">
                                  <div className="col-md-12 centerText">
                                    <button
                                      className="tertiary  btn-yellow-primary"
                                      data-dismiss="modal"
                                    >
                                      OK
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-05">
                        <div className="col-md-12">
                          <span className="incubator-label">
                            Total Funds Disbursed to Incubator
                            
                          </span>
                          <br />
                          {this.state.total_grant_disbursed == null ||
                            this.state.total_grant_disbursed == 0 ? (
                            <span className="incubator-label-value">-</span>
                          ) : (
                            <span className="incubator-label-value">
                              &#8377;
                              {changeNumberFormat(Number(this.state.total_grant_disbursed), 2, 1)}
                            </span>
                          )}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          {/* {this.state.total_grant_disbursed == null ||
                          this.state.total_grant_disbursed == 0 ? (
                            ''
                          ) : (
                            <a className="incubator-details-link">
                              Add proof|Add More
                            </a>
                          )} */}
                        </div>
                      </div>
                      {/* <--------------------------------------------------------------> */}
                      <div className="row mt-05">
                        <div className="col-md-12">
                          <span className="incubator-label">
                            Total Management Fees
                          </span>
                          <br />
                          {this.state.total_management_amount == null ||
                            this.state.total_management_amount == 0 ? (
                            <span className="incubator-label-value">-</span>
                          ) : (
                            <span className="incubator-label-value">
                              &#8377;
                              {changeNumberFormat(Number(this.state.total_management_amount), 2, 1)}
                            </span>
                          )}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          {/* {this.state.total_grant_disbursed == null ||
                          this.state.total_grant_disbursed == 0 ? (
                            ''
                          ) : (
                            <a className="incubator-details-link">
                              Add proof|Add More
                            </a>
                          )} */}
                        </div>
                      </div>
                      {/* <-----------------------------------------------------------> */}
                      <div className="row mt-05">
                        <div className="col-md-12">
                          <span className="incubator-label">
                            No. of Startups Supported
                          </span>
                          <br />
                          <span className="incubator-label-value">{this.state.count_startups_supported}</span>
                        </div>
                      </div>
                      <div className="row mt-05">
                        <div className="col-md-12">
                          <span className="incubator-label">
                            Funds Disbursed to Startups
                          </span>
                          <br />
                          <span className="incubator-label-value">{changeNumberFormat(Number(this.state.amount_disbursed), 2, 1)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
            <div className="col-md-8 text-right">
            <div className="btn-group mt-1-5 mr-2">
           
                  {this.state.user_id!='' && (this.state.incubatorDetailsModel.application_status=="Selected" || this.state.incubatorDetailsModel.application_status=="Closed") && this.state.loggedInRole=="secretariat" &&
                   <Link className="btn btn-sisfs btn-yellow-primary" to={{ pathname: "/secretariat_startup_list_details", state: { user_id: this.state.user_id,showPreference:false } }}>
                                        Associated Startups</Link>
                  }
                  {this.state.user_id!='' && (this.state.incubatorDetailsModel.application_status=="Selected" || this.state.incubatorDetailsModel.application_status=="Closed") && this.state.loggedInRole=="dpiit" &&
                   <Link className="btn btn-sisfs btn-yellow-primary" to={{ pathname: "/dpiit_startup_list_details", state: { user_id: this.state.user_id,showPreference:false } }}>
                                        Associated Startups</Link>
                  }
                  {this.state.user_id!='' && (this.state.incubatorDetailsModel.application_status=="Selected" || this.state.incubatorDetailsModel.application_status=="Closed") && this.state.loggedInRole=="eac" &&
                   <Link className="btn btn-sisfs btn-yellow-primary" to={{ pathname: "/eac_startup_list_details", state: { user_id: this.state.user_id,showPreference:false } }}>
                                        Associated Startups</Link>
                  }
                  {/* 
                   <button className="btn btn-sisfs btn-transparent-primary">
                 Cancel
               </button> */}
                </div>
              {this.state.user == true ? (
                <div className="btn-group mt-1-5 ">
                  <button
                    type="button"
                    className={
                      this.buttonDisable == true
                        ? "tertiary  btn-yellow-primary btn-yellow-disabled"
                        : "tertiary  btn-yellow-primary"
                    }
                    data-toggle="modal"
                    data-target="#scheduleMeeting"
                    disabled={this.buttonDisable}
                  >
                    Schedule Meeting
                  </button>
                  {/* 
                   <button className="btn btn-sisfs btn-transparent-primary">
                 Cancel
               </button> */}
                </div>
              ) : (
                ''
              )}

              <div className="margin-20"></div>
              {this.state.isSelectedOrClosed == true ? (
                <div className="details-tabs">
                  <ul className="nav nav-tabs" role="tablist">
                    {(this.Status_ID ==  6 || this.Status_ID ==  7)  &&<li className="nav-item">
                      <a
                        className={
                          this.state.isFundDetailsTabActive == false
                            ? 'nav-link active'
                            : 'nav-link '
                        }
                        data-toggle="tab"
                        href="#tabs-1"
                        role="tab"
                      >
                        EAC Review
                      </a>
                    </li>}

                    <li className="nav-item">
                      <a
                        className={
                          this.state.isFundDetailsTabActive == true
                            ? 'nav-link active'
                            : 'nav-link '
                        }
                        data-toggle="tab"
                        href="#tabs-2"
                        role="tab"
                      >
                        Fund Details
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#tabs-3"
                        role="tab"
                      >
                        Application<br />
                        Details
                      </a>
                    </li>
                    {this.state.cancellationData.length != 0 && <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#tabs-6"
                        role="tab"
                      >
                        Cancellation
                    </a>
                    </li>}
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#tabs-4"
                        role="tab"
                      >
                        Document
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#tabs-5"
                        role="tab"
                      >
                        Contact
                      </a>
                    </li>
                    {this.state.commentData.length!=0&&<li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#tabs-7"
                        role="tab"
                      >
                        Comments
                      </a>
                    </li>}
                  </ul>
                </div>
              ) : (
                <div className="details-tabs">
                  <ul className="nav nav-tabs" role="tablist">
                  {(this.Status_ID ==  6 || this.Status_ID ==  7)  && <li className="nav-item">
                      <a
                        className={
                          this.state.isFundDetailsTabActive == false
                            ? 'nav-link active'
                            : 'nav-link '
                        }
                        data-toggle="tab"
                        href="#tabs-1"
                        role="tab"
                      >
                        EAC Review
                      </a>
                    </li>}
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#tabs-3"
                        role="tab"
                      >
                        Application <br />
                      Details
                    </a>
                    </li>
                    {this.state.cancellationData.length != 0 && <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#tabs-6"
                        role="tab"
                      >
                        Cancellation
                    </a>
                    </li>}
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#tabs-4"
                        role="tab"
                      >
                        Document
                    </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#tabs-5"
                        role="tab"
                      >
                        Contact
                    </a>
                    </li>
                    {this.state.commentData.length!=0&&<li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#tabs-7"
                        role="tab"
                      >
                        Comments
                      </a>
                    </li>}
                  </ul>
                </div>
              )}
              <div className="incubator-hr margin-top-minus-17">
                <hr />
              </div>

              {this.state.isSelectedOrClosed == true ? (
                <div className="tab-content">
                  {(this.Status_ID ==  6 || this.Status_ID ==  7)  && <div
                    className={
                      this.state.isFundDetailsTabActive == false
                        ? 'tab-pane active'
                        : 'tab-pane'
                    }
                    id="tabs-1"
                  >
                    <EACReview
                      generalDetailModel={this.state.incubatorDetailsModel}
                    />
                  </div>}
                  <div
                    className={
                      this.state.isFundDetailsTabActive == true
                        ? 'tab-pane active'
                        : 'tab-pane'
                    }
                    id="tabs-2"
                  >
                    {/* {console.log("Tranche", this.state.tranches)} */}
                    <FundDetailsComponent
                      application_id={this.state.incubatorDetailsModel.id}
                      total_grant_approved={
                        this.state.incubatorDetailsModel.total_approved_amt
                      }
                      trancheData={this.state.tranches}
                      trancheDataASC={this.state.tranchesAsc}
                      accountDetailsData={this.state.bankDetails}
                      application_status={
                        this.state.incubatorDetailsModel.application_status
                      }
                      totalManagementAmount={this.state.total_management_amount}
                      total_grant_disbursed={this.state.total_grant_disbursed}
                    />
                  </div>
                  <div className="tab-pane" id="tabs-3">
                    <ApplicationDetails
                      applicationGeneralDetailModel={
                        this.state.applicationGeneral
                      }
                    // otherDetailsModel={this.state.otherDetailsModel}

                    />
                  </div>
                  <div className="tab-pane" id="tabs-4">
                    <Document otherDetailModel={this.state.otherDetailsModel} />
                  </div>
                  <div className="tab-pane" id="tabs-5">
                    <Contact
                      generalDetailsModel={this.state.applicationGeneral}
                    />
                  </div>
                  <div className="tab-pane" id="tabs-7">
                    <CommentContainer
                    commentData={this.state.commentData}
                    role={'dpiit'}
                    />
                  </div>
                  {this.state.cancellationData.length != 0 && <div className="tab-pane" id="tabs-6">
                    <CancellationContainer
                      data={this.state.cancellationData}
                      role={"dpiit"}
                      isEdit={true}
                    />
                  </div>}
                </div>
              ) : (
                <div className="tab-content">
                 {(this.Status_ID ==  6 || this.Status_ID ==  7) && <div
                    className={
                      this.state.isFundDetailsTabActive == false
                        ? 'tab-pane active'
                        : 'tab-pane'
                    }
                    id="tabs-1"
                  >
                    <EACReview
                      generalDetailModel={this.state.incubatorDetailsModel}
                    />
                  </div>}
                  <div className="tab-pane active" id="tabs-3">
                    <ApplicationDetails
                      applicationGeneralDetailModel={
                        this.state.applicationGeneral
                      }
                    // otherDetailsModel={this.state.otherDetailsModel}
                    />
                  </div>
                  <div className="tab-pane" id="tabs-4">
                    <Document otherDetailModel={this.state.otherDetailsModel} />
                  </div>
                  <div className="tab-pane" id="tabs-5">
                    <Contact
                      generalDetailsModel={this.state.applicationGeneral}
                    />
                  </div>
                  <div className="tab-pane" id="tabs-7">
                    <CommentContainer
                    commentData={this.state.commentData}
                    role={'dpiit'}
                    />
                  </div>
                  {this.state.cancellationData.length != 0 && <div className="tab-pane" id="tabs-6">
                    <CancellationContainer
                      data={this.state.cancellationData}
                      role={"dpiit"}
                      isEdit={true}
                    />
                  </div>}
                </div>
              )}
            </div>
          </div>

          {/* schedule a meeting tab */}
          <div
            className="modal fade"
            id="scheduleMeeting"
            role="dialog"
            aria-labelledby="exampleModalLabel1"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-slideout modal-md"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header modal-header-border-none modal-header-bottom pr-5">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body pl-5 pr-5 pt-5">
                  <div className="popup-head mb-5 ml-3">
                    <div className="main-heading">Schedule a Meeting</div>
                    <div className="count-text">
                      {this.state.checkedArray.length} Incubators Selected
                    </div>
                  </div>

                  <label className="col-sm-4 col-form-head">Meeting Type</label>
                  <div className="col-md-12 mb-4">
                    <div className="select-custom">
                      <select
                        className="form-control input-height"
                        aria-label="Select Entity Type"
                        onChange={this.handleOnChange}
                      >
                        <option value="1">Incubator Selection</option>
                        <option value="2">Performance Review Meeting</option>
                      </select>
                    </div>
                  </div>
                  <label className="col-sm-4 col-form-head">Date & Time</label>
                  <div className="col-md-6 mb-4">
                    <Calendar
                      id="registrationDate"
                      name="registrationDate"
                      className="form-control input-height"
                      format="dd/MM/yyyy"
                      selectedDate={new Date()}
                      onDateChange={(date: Date, field: string) =>
                        this.handleDateChange(date, field)
                      }
                    />
                  </div>
                  <div className="row pl-3 mb-5">
                    <div className="col-md-6">
                      <label className="col-form-head">From Time</label>
                      <input
                        type="time"
                        value={this.state.fromTime}
                        name="fromTime"
                        className="form-control input-height"
                        onChange={this.onTimeChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="col-form-head">To Time</label>
                      <input
                        type="time"
                        value={this.state.toTime}
                        name="toTime"
                        className="form-control input-height"
                        onChange={this.onTimeChange}
                      />
                    </div>
                  </div>
                  <label className="col-sm-4 col-form-head">
                  Meeting Link
                  </label>
                  <div className="col-md-12 mb-4">
                    <input
                    type="textarea"
                    id="meeting_link"
                    name="meeting_link"
                    className="form-control input-height"  
                    onChange={(e:any) => this.setState({meetingLink:e.target.value})}                  
                   />
                    <label style={{color:"#333333",fontFamily:"Open Sans",fontSize:"15px",fontWeight:"600",letterSpacing:"0.08px",marginTop:"3px"}}>
                     Meeting Comment
                    </label>
                    <textarea
                     style={{height:"100px"}}
                    id="meeting_comment"
                    name="meeting_comment"
                    className="form-control"  
                    onChange={(e:any) => this.setState({meetingComment:e.target.value})}                  
                   />
                    
    
              </div>
                  {/* <div className="row pl-5">
                    <button
                      className="btn btn-sisfs btn-transparent-primary"
                      data-dismiss="modal"
                    >
                      CANCEL
                    </button>
                    <button
                      className="tertiary  btn-yellow-primary ml-3"
                      onClick={this.scheduleMeetingFunction}
                    >
                      SCHEDULE
                    </button>
                  </div> */}

                  <div className="row pl-3">
                    <div className="col-md-6">
                      <button
                        className="btn btn-sisfs btn-transparent-primary full-width"
                        data-dismiss="modal"
                      >
                        CANCEL
                      </button>
                    </div>
                    <div className="col-md-6">
                      <button
                        className="tertiary  btn-yellow-primary full-width"
                        onClick={this.scheduleMeetingFunction}
                        data-dismiss="modal"
                      >
                        SCHEDULE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <br />
            <br />
          </div>
        </div>

        {this.state.showEditSuccessModalPopUp && (
          // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
          <>
            <div className="modal-backdrop fade show"></div>

            <div
              className="modal align-middle modal-sisfs d-block"
              id="FormSubmissionSuccess"
            >
              <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                  <div className="modal-body text-center">
                    <i className="fa fa-check-circle-o"></i>
                    <h3 className="modal-message">Thank You !</h3>
                    <div className="modal-text">
                      Your Meeting Has been Scheduled !!
                    </div>
                    <div className="modal-action">
                      <button
                        className="btn btn-sisfs btn-yellow-primary"
                        onClick={this.dismissModalPopUp}
                      >
                        Ok !!
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {this.state.showEditFailureModalPopUp && (
          // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
          <>
            <div className="modal-backdrop fade show"></div>
            <div
              className="modal align-middle modal-sisfs d-block"
              id="FormSubmissionError"
            >
              <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                  <div className="modal-body text-center">
                    {/* <i className="fa fa-times-circle-o"></i> */}
                    <h3 className="modal-message message-orange">
                      Oops! Something is Wrong
                    </h3>
                    <br />
                    <h3 className="modal-message message-orange">
                      Please Try Again !!!
                    </h3>
                    <div className="modal-action">
                      <button
                        className="btn btn-sisfs btn-yellow-primary"
                        onClick={this.dismissModalPopUp}
                      >
                        OK !
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
  total_management_amount(total_management_amount: any): any {
    throw new Error('Method not implemented.');
  }

  private getAllTranches = (application_id: number) => {
    if (application_id != 0) {
      let tranche_parameters = {
        application_id: application_id,
        order_by: 'DESC',
      };

      post(`${GET_ALL_TRANCHES}`, tranche_parameters).then((response: any) => {
        if (!response || !response.data) return;

        var trancheDetailsData: Array<TrancheDetail> = response.data.data;

        for (var i = 0; i < trancheDetailsData.length; i++) {
          var tranche_details = trancheDetailsData[i];
          const file_data = tranche_details.documents;

          if (file_data) {
            Object.keys(file_data).length &&
              Object.keys(file_data).map((key: string, index) => {
                switch (key) {
                  case UploadTypeEnum.ProofDisbursement:
                    file_data[key].map((data: any) => {
                      createFile(data.file).then((blob: any) => {
                        blob.lastModifiedDate = new Date();
                        const src = data.file as string;
                        blob.name = src.substr(src.lastIndexOf('/') + 1, src.length);

                        const index = file_data.proof_of_disbursement.findIndex((el) => el.file_id === data.file_id);
                        file_data.proof_of_disbursement[index] = {
                          file_id: data.file_id,
                          file_type_id: data.file_type_id,
                          file: blob as File,
                          ext: data.ext,
                          fileName: blob.name
                        };
                      });
                    });
                    break;
                  case UploadTypeEnum.QuarterlyReport:
                    file_data[key].map((data: any) => {
                      createFile(data.file).then((blob: any) => {
                        blob.lastModifiedDate = new Date();
                        const src = data.file as string;
                        blob.name = src.substr(src.lastIndexOf('/') + 1, src.length);

                        const index = file_data.quarterly_reports.findIndex((el) => el.file_id === data.file_id);
                        file_data.quarterly_reports[index] = {
                          file_id: data.file_id,
                          file_type_id: data.file_type_id,
                          file: blob as File,
                          ext: data.ext,
                          fileName: blob.name
                        };
                      });
                    });
                    break;
                  case UploadTypeEnum.SupportingDocuments:
                    file_data[key].map((data: any) => {
                      createFile(data.file).then((blob: any) => {
                        blob.lastModifiedDate = new Date();
                        const src = data.file as string;
                        blob.name = src.substr(src.lastIndexOf('/') + 1, src.length);

                        const index = file_data.supporting_documents.findIndex((el) => el.file_id === data.file_id);
                        file_data.supporting_documents[index] = {
                          file_id: data.file_id,
                          file_type_id: data.file_type_id,
                          file: blob as File,
                          ext: data.ext,
                          fileName: blob.name
                        };
                      });
                    });
                    break;
                }


              });
          }

          tranche_details.documents = file_data;
          trancheDetailsData[i] = tranche_details;
        }

        this.setState({
          tranches: trancheDetailsData,
        });

        //total disbursed
        let grant_disbursed = 0;
        for (let i = 0; i < response.data.data.length; i++) {
          grant_disbursed =
            grant_disbursed +
            (response.data.data[i].fund_released == null
              ? 0
              : Number(response.data.data[i].fund_released));
        }
        this.setState({
          total_grant_disbursed: grant_disbursed,
        });
      });

      //
      let trancheAsc_parameters = {
        application_id: application_id,
        order_by: 'ASC',
      };

      post(`${GET_ALL_TRANCHES}`, trancheAsc_parameters).then(
        (response: any) => {
          if (!response || !response.data) return;
          this.setState({
            tranchesAsc: response.data.data,
          });
        }
      );
    }
  };

  private getAccountDetails = () => {
    get(`${GET_BANK_DETAILS}`).then((response: any) => {
      if (!response || !response.data) return;
      if (
        response.data != undefined &&
        response.data.bankDetails != undefined
      ) {
        //this.state.bankDetails (response.data.bankDetails;
        this.setState({
          bankDetails: response.data.bankDetails,
        });

      }


    });
  };

  //get cancellation
  private getCancellationData = (application_id: number) => {
    post(INCUBATOR_CANCEL_DETAIL, { "application_id": application_id }).then(
      // get(`${GET_INCUBATOR_DETAILS}/8/details`).then(
      (response: any) => {
        if (!response || !response.data || response.data == '') return;
        this.setState({
          cancellationData: response.data.data,
        });

      }
    );
    // this.getAccountDetails();
  };


  //get active application
  private getActiveApplication = (application_id: number) => {
    get(`${GET_INCUBATOR_DETAILS}/${application_id}/details`).then(
      async (response: any) => {
        //console.log(response.data.data);
        this.Status_ID =response.data.data.application_details.status_id

        if(this.Status_ID === 3 || this.Status_ID === 7){
          this.buttonDisable = false;
         }
        //console.log(this.Status_ID);
        if (!response || !response.data || response.data == '') return;
        this.setState({
          commentData: response.data.data.comments_data,
          count_startups_supported:response.data.data.count_startups_supported!=null?response.data.data.count_startups_supported:'0',
          amount_disbursed:response.data.data.amount_disbursed!=null?response.data.data.amount_disbursed:'0',
          user_id: response.data.data.application_details.user_id
        });
        //console.log("******************",response.data)
        if (response.data.data != undefined && response.data.data != '') {
          
          response.data.data.application_details.submitted_at = this.formatDateWithMonth(
            response.data.data.application_details.submitted_at
          );

          if (
            response.data.data.bankDetails != undefined
          ) {
            this.setState({
              bankDetails: response.data.data.bankDetails,
            });
          }
          this.setState({
            total_management_amount:response.data.data.application_details.total_management_amount
          })
          
          var incubatorDetailsDataModel = response.data.data.application_details;

          incubatorDetailsDataModel.evaluationData =
            response.data.data.evaluationData;
          incubatorDetailsDataModel.meeting_data =
            response.data.data.meeting_data;
          incubatorDetailsDataModel.otherApplications =
            response.data.data.otherApplications;
          incubatorDetailsDataModel.applicationComments =
            response.data.data.applicationComments;
          incubatorDetailsDataModel.evaluation_avg_score =
            response.data.data.evaluation_avg_score;

          this.setState({
            incubatorDetailsModel: incubatorDetailsDataModel
          });

          this.setState({
            isSelectedOrClosed:
              response.data.data.application_details.application_status ==
                'Selected' ||
                response.data.data.application_details.application_status ==
                'Close'
                ? true
                : false,
          });
          let { otherDetailsModel } = this.state;

          if (response.data.data.uploaded_documents) {
            // await this.bindFilesNew(response, otherDetailsModel);
            this.setState({
              otherDetailsModel: response.data.data.uploaded_documents,
            });

          }

          //bind files 

          var application_data = response.data.data.application_details;
          application_data.uploaded_documents = response.data.data.uploaded_documents;

          this.setState({
            applicationGeneral: application_data,
          });
          // this.setState({
          //   commentData:response.data.data.comments_data
          // });

        }
       
      }
    );
    this.getAllTranches(application_id);
    //this.getAccountDetails();
  };

  // bind files for all tabs
  private bindFilesNew = async (response: any, otherDetailsModel: OthersModel) => {
    let file_data = response.data.data.uploaded_documents;
    if (file_data) {
      Object.keys(file_data).map((key: string) => {
        switch (key) {
          case UploadTypeEnum.AnnualReport:
            file_data[key].map(async (data: any) => {
              createFile(data.file).then((blob: any) => {
                blob.lastModifiedDate = new Date();
                const src = data.file as string;
                blob.name = src.substr(src.lastIndexOf('/') + 1, src.length);
                const file_type = src.substr(
                  src.lastIndexOf('.') + 1,
                  src.length
                );
                const file: FileDownloadModel = new FileDownloadModel(
                  data.file_id,
                  data.file_type_id,
                  data.file,
                  blob.name,
                  file_type
                );

                this.state.otherDetailsModel.annual_report = [
                  ...otherDetailsModel.annual_report,
                  file,
                ];
              });
            });
            this.setState({ otherDetailsModel });
            break;

          case UploadTypeEnum.ProofAssistance:
            file_data[key].map(async (data: any) => {
              createFile(data.file).then((blob: any) => {
                blob.lastModifiedDate = new Date();
                const src = data.file as string;
                blob.name = src.substr(src.lastIndexOf('/') + 1, src.length);
                const file_type = src.substr(
                  src.lastIndexOf('.') + 1,
                  src.length
                );
                const file: any = new FileDownloadModel(
                  data.file_id,
                  data.file_type_id,
                  data.file,
                  blob.name,
                  file_type
                );

                this.state.otherDetailsModel.proof_assistance = [
                  ...otherDetailsModel.proof_assistance,
                  file,
                ];
              });
            });
            this.setState({ otherDetailsModel });
            break;

          case UploadTypeEnum.RelaventDocumentExcel:
            file_data[key].map(async (data: any) => {
              createFile(data.file).then((blob: any) => {
                blob.lastModifiedDate = new Date();
                const src = data.file as string;
                blob.name = src.substr(src.lastIndexOf('/') + 1, src.length);
                const file_type = src.substr(
                  src.lastIndexOf('.') + 1,
                  src.length
                );
                const file: any = new FileDownloadModel(
                  data.file_id,
                  data.file_type_id,
                  data.file,
                  blob.name,
                  file_type
                );

                this.state.otherDetailsModel.relavent_document_excel = [
                  ...otherDetailsModel.relavent_document_excel,
                  file,
                ];
              });
            });
            this.setState({ otherDetailsModel });
            break;

          case UploadTypeEnum.ReleventDocumentPitchDeck:
            file_data[key].map(async (data: any) => {
              createFile(data.file).then((blob: any) => {
                blob.lastModifiedDate = new Date();
                const src = data.file as string;
                blob.name = src.substr(src.lastIndexOf('/') + 1, src.length);
                const file_type = src.substr(
                  src.lastIndexOf('.') + 1,
                  src.length
                );
                const file: any = new FileDownloadModel(
                  data.file_id,
                  data.file_type_id,
                  data.file,
                  blob.name,
                  file_type
                );

                this.state.otherDetailsModel.relavent_document_pitchdeck = [
                  ...otherDetailsModel.relavent_document_pitchdeck,
                  file,
                ];
              });
            });
            this.setState({ otherDetailsModel });
            break;

          case UploadTypeEnum.RelaventDocumentOther:
            file_data[key].map(async (data: any) => {
              createFile(data.file).then((blob: any) => {
                blob.lastModifiedDate = new Date();
                const src = data.file as string;
                blob.name = src.substr(src.lastIndexOf('/') + 1, src.length);
                const file_type = src.substr(
                  src.lastIndexOf('.') + 1,
                  src.length
                );
                const file: any = new FileDownloadModel(
                  data.file_id,
                  data.file_type_id,
                  data.file,
                  blob.name,
                  file_type
                );

                this.state.otherDetailsModel.relavent_document_other = [
                  ...otherDetailsModel.relavent_document_other,
                  file,
                ];
              });
            });
            this.setState({ otherDetailsModel });
            break;

          case UploadTypeEnum.IncorporationCertificate:
            file_data[key].map(async (data: any) => {
              createFile(data.file).then((blob: any) => {
                blob.lastModifiedDate = new Date();
                const src = data.file as string;
                blob.name = src.substr(src.lastIndexOf('/') + 1, src.length);
                const file_type = src.substr(
                  src.lastIndexOf('.') + 1,
                  src.length
                );
                const file: any = new FileDownloadModel(
                  data.file_id,
                  data.file_type_id,
                  data.file,
                  blob.name,
                  file_type
                );

                this.state.otherDetailsModel.incorporation_certificate = [
                  ...otherDetailsModel.incorporation_certificate,
                  file,
                ];
              });
            });
            this.setState({ otherDetailsModel });
            break;

          case UploadTypeEnum.TeamMemberCV:
            file_data[key].map(async (data: any) => {
              createFile(data.file).then((blob: any) => {
                blob.lastModifiedDate = new Date();
                const src = data.file as string;
                blob.name = src.substr(src.lastIndexOf('/') + 1, src.length);
                const file_type = src.substr(
                  src.lastIndexOf('.') + 1,
                  src.length
                );
                const file: any = new FileDownloadModel(
                  data.file_id,
                  data.file_type_id,
                  data.file,
                  blob.name,
                  file_type
                );

                this.state.otherDetailsModel.team_members_cv = [
                  ...otherDetailsModel.team_members_cv,
                  file,
                ];
              });
            });
            this.setState({ otherDetailsModel });
            break;

          case UploadTypeEnum.FacilitiesPictures:
            file_data[key].map(async (data: any) => {
              createFile(data.file).then((blob: any) => {
                blob.lastModifiedDate = new Date();
                const src = data.file as string;
                blob.name = src.substr(src.lastIndexOf('/') + 1, src.length);
                const file_type = src.substr(
                  src.lastIndexOf('.') + 1,
                  src.length
                );
                const file: any = new FileDownloadModel(
                  data.file_id,
                  data.file_type_id,
                  data.file,
                  blob.name,
                  file_type
                );

                this.state.otherDetailsModel.facilities_pictures = [
                  ...otherDetailsModel.facilities_pictures,
                  file,
                ];
              });
            });
            this.setState({ otherDetailsModel });
            break;
        }
      });
    }
  };


}

export default ViewIncubatorDetails;
