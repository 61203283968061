import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';

import {
    GET_APPLICATION_LIST,
    GET_APPLICATION_VIEW,
    REPLACE_FILE,
    DELETE_APPLICATION,
    EVALUATION_FILE,
    STARTUP_ALL_APPLICATIONS,
    STORE_STARTUP_APPLICATION,
    CANCELLATION_REQUEST_STARTUP,
    UPLOAD_DISBURSEMENT_PROOF_STARTUP,
    NODE_URL,
    BASE_URL,
    STORE_STARTUP_APPLICATION_NODE,
    SEND_TOKEN_TO,
} from "../../../constants/api";
import pdf from "../../../images/PDF.svg";
import { get, post } from "../../../effects/api/api.common";
// import ContactSectionComponent from "./ContactSectionComponent";
// import GetApplicationInfoComponent from "./GetApplicationInfoComponenet";
import { useHistory } from "react-router-dom";
import { FormatDate } from "../../../utils/DateFormat";
import GetApplicationInfoComponent from "../../incubator/application/myapplications/GetApplicationInfoComponenet";
import ContactSectionComponent from "../../incubator/application/myapplications/ContactSectionComponent";
import FileUploader from "@amodv/react-file-uploader";
import ConfirmationDialog from "../../common/controls/ConfirmationDialog";
import {
    changeNumberFormat,
    downloadDocument,
    showEllipsisText,
} from "../../../utils/CommonMethods";
import { getSessionStorageValue, setSessionStorageValue } from "../../../effects/utils/session-storage";
// import { FormatDate } from "../../../../utils/DateFormat";
// import { downloadDocument } from "../../../../utils/CommonMethods";
/** Render Application Status for Incubator screen */
import '../../../styles/dashboard.css'
import { cpuUsage } from "process";
import UserInfoModel from "../../../models/UserInfoModel";
import AuthContext, { AuthContextType } from "../../../Contexts/AuthContext";
interface IIKeyValueIndexer {
    [key: string]: boolean;
}
function StartupStatusComponent(props: any) {
    const history = useHistory();
    const [activeIndex, setActiveIndex] = useState("submitted");
    const [dip, setDip] = useState<any>('')
    const [certmodal, setCertmodal] = useState(false);
    const [certmodal1, setCertmodal1] = useState(false);
    const [loader, setLoader] = useState(false);
    const { setAuth } = React.useContext(AuthContext) as AuthContextType;
    const [activeTab, setActiveTab] = useState("submitted");
    const [applicationID, setApplicationID] = useState("");
    const [application_comments, setApplicationComments] = useState<any>([])
    const [legalAggrements, setLegalAggrements] = useState<any>([])
    const [applicationListData, setApplicationListData] = useState<any>([]);
    //     application_id: 1,
    //     registered_name: 'test',
    //     updated_at: 'test',
    //     submitted_at: 'test',
    //     label: 'Draft',
    //     dpiit_comments: 'test',
    //     Upload_pitchdeck: 'test',
    //     file_location: 'test',
    //     file_id: 'test',
    //     comment_updated_at: 'test',
    //     date_of_evaluation: 'test',
    //     status_created_at: 'test'
    // },
    // {
    //     application_id: 2,
    //     registered_name: 'test',
    //     updated_at: 'test',
    //     submitted_at: 'test',
    //     label: 'Marked Incomplete',
    //     dpiit_comments: 'test',
    //     Upload_pitchdeck: 'test',
    //     file_location: 'test',
    //     file_id: 'test',
    //     comment_updated_at: 'test',
    //     date_of_evaluation: 'test',
    //     status_created_at: 'test'
    // }]);
    const [applicationListDataSelected, setApplicationListDataSelected] =
        useState([]);
    //     application_id: 1,
    //     registered_name: 'test',
    //     updated_at: 'test',
    //     submitted_at: 'test',
    //     label: 'Selected',
    //     dpiit_comments: 'test',
    //     Upload_pitchdeck: 'test',
    //     file_location: 'test',
    //     file_id: 'test',
    //     comment_updated_at: 'test',
    //     date_of_evaluation: 'test',
    //     status_created_at: 'test'
    // }]);
    const [applicationListDataClosed, setApplicationListDataClosed] = useState<any>([]);
    const [applicationListDataRejected, setApplicationListDataRejected] =
        useState<any>([]);
    //     application_id: 1,
    //     registered_name: 'test',
    //     updated_at: 'test',
    //     submitted_at: 'test',
    //     label: 'Draft',
    //     dpiit_comments: 'test',
    //     Upload_pitchdeck: 'test',
    //     file_location: 'test',
    //     file_id: 'test',
    //     comment_updated_at: 'test',
    //     date_of_evaluation: 'test',
    //     status_created_at: 'test'
    // },
    // {
    //     application_id: 2,
    //     registered_name: 'test',
    //     updated_at: 'test',
    //     submitted_at: 'test',
    //     label: 'Draft',
    //     dpiit_comments: 'test',
    //     Upload_pitchdeck: 'test',
    //     file_location: 'test',
    //     file_id: 'test',
    //     comment_updated_at: 'test',
    //     date_of_evaluation: 'test',
    //     status_created_at: 'test'
    // }]);
    const [applicationData, setApplicationData] = useState([]);
    const [applicationDataCount, setApplicationDataCount] = useState(0);
    const [showEditFailureModal, setShowEditFailureModal] = useState(false);
    const [showEditSuccessModal, setShowEditSuccessModal] = useState(false);
    const [startupTopId, setStartupTopId] = useState("");
    const [applicationStatusLable, setApplicationStatusLable] = useState("");
    const [cancellationData, setCancellationData] = useState({
        reason: "",
        amount_refunded: "",
        interest_funds: "",
    });
    const [failDialogData, setFailDialogData] = useState({
        header: "",
        text: "",
    });
    const [dialogData, setDialogData] = useState({
        header: "",
        text: "",
    });
    const [incubatorPrefereneceList, setIncubatorPreferenceList] = useState([]);
    //     {
    //         startup_application_id: 1,
    //         last_updated: "2021-05-10 00:00:00",
    //         application_status_lable: "Submitted",
    //         startup_name: "Kunal Kurhade",
    //         prefrence_incubator: "Vinesh Gohil",
    //         response_label: "Submitted",
    //         incubator_response_status: 4,
    //         file_id: null,
    //         file_location: null,
    //         response_date: "2021-05-12",
    //         incubator_comment: "Test 4 comment",
    //         startup_status_id: 2,
    //         submitted_date: "2021-05-12",
    //         Upload_pitchdeck: 'No'
    //     },
    //     {
    //         startup_application_id: 3,
    //         last_updated: "2021-05-10 00:00:00",
    //         application_status_lable: "Submitted",
    //         startup_name: "Kunal Kurhade",
    //         prefrence_incubator: "Vinesh Gohil",
    //         response_label: "Pending Review",
    //         incubator_response_status: 4,
    //         file_id: null,
    //         file_location: null,
    //         response_date: "2021-05-12",
    //         incubator_comment: "Test 4 comment",
    //         startup_status_id: 2,
    //         submitted_date: "2021-05-12",
    //         Upload_pitchdeck: 'No'
    //     },
    //     {
    //         startup_application_id: 4,
    //         last_updated: "2021-05-10 00:00:00",
    //         application_status_lable: "Submitted",
    //         startup_name: "Kunal Kurhade",
    //         prefrence_incubator: "Vinesh Gohil",
    //         response_label: "Incomplete",
    //         incubator_response_status: 4,
    //         file_id: null,
    //         file_location: null,
    //         response_date: "2021-05-12",
    //         incubator_comment: "Test 4 comment",
    //         startup_status_id: 2,
    //         submitted_date: "2021-05-12",
    //         Upload_pitchdeck: 'No'
    //     }
    // ]);
    const [PPTPath, setPPTPath] = useState("");
    const [uploadFile, setUploadFile] = useState<Array<File>>([]);
    const [other_file, setOtherFile] = useState<Array<File>>([]);
    const [isDisabled, setDisabled] = useState(false);
    const [showIncubatorSuccessModal, setShowIncubatorSuccessModal] =
        useState(false);
    const [showIncubatorFailureModal, setShowIncubatorFailureModal] =
        useState(false);
    const [openUploadBlock, setOpenUploadBlock] = useState<IIKeyValueIndexer>();
    const [showViewMore, setShowViewMore] = useState("");
    const location = useLocation();
    const [invalidPopup, setInvalidpopup] = useState(false);

    useEffect(() => {
        const currentPath = location.pathname;
        const startIndex = currentPath.indexOf("startupApplications/") + "startupApplications/".length;
        const token = currentPath.slice(startIndex);
        // console.log("HEELLOOOO @@@@")
        // console.log("Token:", token);
        if (token) {
            var payload = {
                "access_token": token
            }

            post(SEND_TOKEN_TO, payload).then((response) => {
                // console.log(response.data.message)
                if (response.data.message === "Invalid Token") {
                    history.push('')
                    alert("Invalid Token")
                    // setFailDialogData({
                    //     header: "Oops !!",
                    //     text: response.data.message,
                    // });
                    // setInvalidpopup(true);
                    return;
                }
                // localStorage.removeItem("dipp_num");
                // localStorage.removeItem("user");
                const access_token: string = response.data.access_token;
                const user = response.data.user;
                localStorage.removeItem("incubatorName")
                if (access_token) {
                    const userInfo: UserInfoModel = new UserInfoModel(
                        user?.name,
                        user?.email,
                        user?.id,
                        user?.roles,
                        access_token
                    );
                    // console.log(userInfo);
                    localStorage.setItem("dipp_num", response.data.user.dipp_number)
                    setSessionStorageValue("user", JSON.stringify(userInfo));
                    setAuth(response.data);
                    // console.log(userInfo)
                    if (userInfo.roles === 'startup') {
                        const user: any = getSessionStorageValue("user");
                        const userInfo = JSON.parse(user)
                        // console.log(userInfo)
                        //  console.log(userInfo,"oppp")
                        var payload = {
                            "logged_user_id": userInfo.id,
                            "get_list": "",
                            "user_role": userInfo.roles
                        }
                        // console.log(payload)
                        post(STARTUP_ALL_APPLICATIONS, payload).then((response: any) => {
                            // console.log("**************************", response)
                            if (!response || !response.data) return;

                            if (response.data.data.length == 0) {
                                // console.log("INTO IF @@@@@@@@@@@@@@@@@@@@")
                                history.push('/startup_status')
                            }

                            if (response.data.data.InProgress.length == 0 && response.data.data.Selected.length == 0 && response.data.data.Rejected.length == 0) {
                                // console.log("INTO FINMAL IF ############################")
                                const user: any = getSessionStorageValue("user");
                                const userInfo = JSON.parse(user)

                                var payload = {
                                    "logged_user_id": userInfo.id,
                                    "get_list": "incubator_prefrences",
                                    "user_role": userInfo.roles
                                }
                                // setLoader(true);
                                post(STARTUP_ALL_APPLICATIONS, payload).then((res: any) => {
                                    // console.log("*********&&&&&&&&*****************", res)
                                    if (!res || !res.data) return;
                                    if (res.data.data.length != 0) {
                                        history.push('/startup_status')
                                    }
                                    else {
                                        setSessionStorageValue("hide", "hide_flag")
                                        history.push('/startup_apply')
                                    }
                                })

                            }
                            else {
                                // console.log("ELSEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE")
                                history.replace('/startup_status')
                            }

                        });

                    }
                }

            })
        }
        setTimeout(() => {
            getApplicationList();
        }, 700);
        setTimeout(() => {
            getIncubatorPreferenceList();
        }, 1000);
        getPPTPath();

        // getActiveApplication();
        window.scrollTo(0, 0);
        const DipNum = localStorage.getItem("dipp_num")
        setDip(DipNum)
    }, []);

    const changeActive = (tabs: any) => {
        setActiveIndex(tabs);
    };

    const getActiveApplication = (id: any, closeId: any) => {
        history.push({
            pathname: "/startup",
            state: { application_id: id, disabled: true, closed_id: closeId },
        });
    };

    // const getActiveApplication = (id: any) => {
    //     history.push({
    //         pathname: "/startup",
    //         state: { application_id: id, continue_editing: 1 },
    //     });
    // };
    const dismissModal = () => {
        setShowEditSuccessModal(false);
        setShowEditFailureModal(false);

        window.location.reload();
    };
    const dismissInvalidModal = () => {
        setInvalidpopup(false);
        history.push('/home')
        window.location.reload();
    }
    const getApplicationList = () => {
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user)

        var payload = {
            "logged_user_id": userInfo.id,
            "get_list": "",
            "user_role": userInfo.roles
        }
        setLoader(true);
        post(STARTUP_ALL_APPLICATIONS, payload).then((response: any) => {
            setLoader(false);
            // const selected = response.data.data.filter((res:any)=>{
            //     return res.application_status_lable === "Selected"
            // })
            // setApplicationComments(response.data.application_comments);
            // setLegalAggrements(response.data.legal_agreement)
            // const draftData = response.data.data.filter((item:any)=>{
            //     return item.application_status_lable === "Draft" || "Submitted"
            // })
            // setApplicationListData(draftData)

            // const closedData = response.data.data.filter((item:any)=>{
            //     return item.application_status_lable === "Cancelled"
            // })
            // setApplicationListDataClosed(closedData)

            // const selectedData = response.data.data.filter((item:any)=>{
            //     return item.application_status_lable === "Selected"
            // })
            // setApplicationListDataSelected(selectedData)

            // const rejectedData = response.data.data.filter((item:any)=>{
            //     return item.application_status_lable === "Rejected"
            // })
            // setApplicationListDataRejected(rejectedData)

            if (!response || !response.data) return;
            // if (response.data.data.Selected.length != 0) {
            //     changeActive("selected");
            //     setActiveTab("selected");
            // }
            // if (
            //     response.data.data.Selected.length == 0 &&
            //     response.data.data.Rejected.length != 0
            // ) {
            //     changeActive("rejected");
            //     setActiveTab("rejected");
            // }
            // if (response.data.data.Selected.length == 0 && response.data.data.Closed.length != 0) {
            //     changeActive("closed");
            //     setActiveTab("closed");
            // }
            setApplicationListData(response.data.data?.InProgress);
            setApplicationListDataSelected(response.data.data?.Selected);
            setApplicationListDataClosed(response.data.data?.Closed);
            setApplicationListDataRejected(response.data.data?.Rejected);
        });
    };

    const onFileUploadComplete = (files: Array<File>, type: string) => {
        //console.log("testing", files);
        let indexes: Array<string> = [];
        let updatedUploadDocuments = Object.assign({}, cancellationData);
        for (const file of files) {
            switch (type) {
                case "file":
                    setUploadFile([...uploadFile, file]);
                    break;
                case "other_file":
                    setOtherFile([...other_file, file]);
                    break;
            }
        }

        if (indexes.length) {
            indexes.map((ind) => {
                const position: number = files.findIndex((file) => file.name === ind);
                files.splice(position, 1);
            });
        }
    };

    const onFileDelete = (type: string) => {
        switch (type) {
            case "file":
                setUploadFile([]);
                break;
            case "other_file":
                setOtherFile([]);
                break;
        }
    };

    const getIncubatorPreferenceList = () => {
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user)

        var payload = {
            "logged_user_id": userInfo.id,
            "get_list": "incubator_prefrences",
            "user_role": userInfo.roles
        }
        post(STARTUP_ALL_APPLICATIONS, payload).then(
            (response: any) => {
                if (!response || !response.data) return;
                setIncubatorPreferenceList(response.data.data);
                setStartupTopId(
                    response.data.data[0]
                        ? response.data.data[0].startup_application_id
                        : ""
                );
                if (response.data.data.length > 0) {
                    for (var i = 0; i < response.data.data.length; i++) {
                        if (response.data.data[i].response_label == "Marked Incomplete") {
                            setApplicationStatusLable("Marked Incomplete");
                        }
                    }
                }
            }
        );
    };

    const openCancellationPopup = (id: any) => {
        setApplicationID(id);
        setUploadFile([]);
        setOtherFile([]);
        setCancellationData({
            reason: "",
            amount_refunded: "",
            interest_funds: "",
        });
    };

    const app: any = [
        {
            application_id: 1,
            registered_name: "test",
            updated_at: "test",
            submitted_at: "test",
            label: "test",
            dpiit_comments: "test",
            Upload_pitchdeck: "test",
            file_location: "test",
            file_id: "test",
            comment_updated_at: "test",
            date_of_evaluation: "test",
            status_created_at: "test",
        },
    ];

    // setApplicationListData(app);
    const continueEditing = (id: any) => {
        //console.log(dip)
        if (dip == 'null' || dip == "" || dip == undefined) {
            setCertmodal(true)

        } else {
            history.push({
                pathname: "/startup",
                state: { application_id: id, continue_editing: 1 },
            });
        }
    }
    const [deleteId, setDeleteId] = useState<any>('');
    const [showDeleteModal, setDeleteModal] = useState(false);
    const deleteApplicationModal = (id: any) => {
        setDeleteId(id);
        setDeleteModal(true);
    }
    const deleteApplication = (id: any) => {

        post(`${STORE_STARTUP_APPLICATION_NODE}/${id}/delete`, "").then((response: any) => {
            if (!response || !response.data) return;
            if (response.data.result == "success") {
                // getApplicationList()
                setDeleteModal(false)
                setShowEditSuccessModal(true);

            } else {
                setShowEditFailureModal(true);
            }
        });
    };
    const dismissdeleteModal = () => {
        setShowEditSuccessModal(false);
    }

    // const downloadDocument = (file: any, fileName: any) => {
    //   fetch(file).then((response) => {
    //     response.blob().then((blob) => {
    //       let url = window.URL.createObjectURL(blob);
    //       let a = document.createElement('a');
    //       a.href = url;
    //       a.download = "PitchDeck_" + fileName;
    //       a.click();
    //     });
    //   });
    // };

    const onChange = (e: any, applicationID: any, fileID: any) => {
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user)
        const fileData = e.target.files[0];
        let formData = new FormData();
        formData.append("application_id", applicationID);
        formData.append("file_id", fileID);
        formData.append("file", fileData);
        formData.append("logged_user_id", userInfo.id)
        post(REPLACE_FILE, formData).then((response: any) => {
            if (!response || !response.data) return;
        });
    };

    const getPPTPath = () => {
        get(EVALUATION_FILE).then((response: any) => {
            if (!response.data) return;
            setPPTPath(response.data.startupPitchDeckPath);
        });
    };

    const compareDateString = (date1: any, date2: any) => {
        if (date1 < date2) {
            return true;
        } else {
            return false;
        }
    };

    const submitCancellationRequest = () => {
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user)


        const formData = new FormData();
        formData.append("startup_application_id", applicationID);
        formData.append("reason", cancellationData.reason ? cancellationData.reason : 'null');
        formData.append("logged_user_id", userInfo.id);
        formData.append("userRole", userInfo.roles);
        formData.append("userName", userInfo.name);
        formData.append("amount_refunded", cancellationData.amount_refunded ? cancellationData.amount_refunded : 'null');
        formData.append("interest_funds", cancellationData.interest_funds ? cancellationData.interest_funds : 'null');
        uploadFile.length != 0 && formData.append("file_path", uploadFile[0]);
        other_file.length != 0 && formData.append("file_path_other", other_file[0]);
        // const payload = {
        //   application_id: applicationID,
        //   reason: cancellationData.reason,
        //   amount_refund: cancellationData.amount_refunded,
        //   interest_funds: cancellationData.interest_funds,
        //   file: typeof uploadFile[0] !="undefined"?uploadFile[0]:null,
        //   other_file:typeof other_file[0] !="undefined"?other_file[0]:null
        // }
        setDisabled(true);
        //console.log(formData);
        post(CANCELLATION_REQUEST_STARTUP, formData).then((response: any) => {
            if (!response || !response.data) return;
            setDisabled(false);
            if (response.data.result == "success") {
                setDialogData({
                    header: "Thank You!",
                    text: response.data.message,
                });
                setShowIncubatorSuccessModal(true);
            } else {
                setFailDialogData({
                    header: "Oops !!",
                    text: response.data.message,
                });
                setShowIncubatorFailureModal(true);
            }
        });
    };
    const onChangedDisbursementProof = (e: any, applicationID: any) => {
        const fileData = e.target.files[0];
        let formData = new FormData();
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user)
        formData.append("application_id", applicationID);
        formData.append("file", fileData);
        formData.append("logged_user_id", userInfo.id);
        formData.append("user_Role", userInfo.roles)

        post(UPLOAD_DISBURSEMENT_PROOF_STARTUP, formData).then((response: any) => {
            if (!response || !response.data) return;
            if (response.data.result == "success") {
                setDialogData({
                    header: "Thank You!",
                    text: response.data.message,
                });
                setShowIncubatorSuccessModal(true);
            } else {
                setFailDialogData({
                    header: "Oops !!",
                    text: response.data.message,
                });
                setShowIncubatorFailureModal(true);
            }
        });
    };
    const chekDp = () => {
        if (dip == 'null' || dip == "" || dip == undefined) {
            setCertmodal(true)
        } else {
            history.push('/startup_apply')
        }
    }
    return (
        <>
            {loader &&
                <div className="spinner-border custom-spinner" role="status">
                    <span className="sr-only">Loading...</span>
                </div>}
            <div className="app-inside app-apply-incubator">
                <div className="status-list-wrapper mt-4">
                    <div className="container">
                        <div className="app-inside-content">
                            <p className="dashboard application-count">My Applications</p>

                            <div className="objectives-nav">
                                <ul className="nav nav-tabs">
                                    <li
                                        className={activeIndex == "submitted" ? "active" : ""}
                                        onClick={() => changeActive("submitted")}
                                    >
                                        <a href="#tab-submitted" data-toggle="tab">
                                            In Progress
                                            <sup>
                                                {activeIndex == "submitted" ? <></> :
                                                    applicationListData?.length > 0 && <span style={{ fontSize: '16px', color: '#ff6b6b' }} >&#9632;</span>}
                                            </sup>
                                        </a>
                                    </li>
                                    <li
                                        className={activeIndex == "selected" ? "active" : ""}
                                        onClick={() => changeActive("selected")}
                                    >

                                        <a href="#tab-selected" data-toggle="tab">
                                            Selected
                                            <sup>
                                                {activeIndex == "selected" ? <></> :
                                                    applicationListDataSelected?.length > 0 && <span style={{ fontSize: '16px', color: '#ff6b6b' }} >&#9632;</span>}
                                            </sup>
                                        </a>
                                    </li>{" "}
                                    <li
                                        className={activeIndex == "closed" ? "active" : ""}
                                        onClick={() => changeActive("closed")}
                                    >
                                        <a href="#tab-closed" data-toggle="tab">
                                            Closed / Cancelled
                                            <sup>
                                                {activeIndex == "closed" ? <></> :
                                                    applicationListDataClosed?.length > 0 && <span style={{ fontSize: '16px', color: '#ff6b6b' }} >&#9632;</span>}
                                            </sup>
                                        </a>
                                    </li>{" "}
                                    <li
                                        className={activeIndex == "rejected" ? "active" : ""}
                                        onClick={() => changeActive("rejected")}
                                    >
                                        <a href="#tab-rejected" data-toggle="tab">
                                            Rejected
                                            <sup>
                                                {activeIndex == "rejected" ? <></> :
                                                    applicationListDataRejected?.length > 0 && <span style={{ fontSize: '16px', color: '#ff6b6b' }} >&#9632;</span>}
                                            </sup>
                                        </a>
                                    </li>
                                    <li style={{ float: "right" }}>
                                        <button className="btn btn-sisfs btn-yellow-primary" onClick={chekDp} >
                                            Apply Now
                                        </button>
                                    </li>
                                </ul>

                                {/* {
                            user && user.roles === "startup" &&
                            <div className="pt-2 pb-2 dropdown-item">
                                <Link className="btn btn-sisfs btn-yellow-primary" to="/startup_apply" onClick={chekDp}>Apply Now</Link>
                            </div>
                        } */}
                                {/* <ul className="nav nav-tabs">
                                            <li className="active">
                                                <a href="#tab-startup" data-toggle="tab">Startups</a>
                                            </li>
                                            <li><a href="#tab-incubator" data-toggle="tab">Incubators</a>
                                            </li>
                                        </ul> */}
                            </div>
                            <div className="tab-content">
                                {/* submitted tab */}
                                <div
                                    className={
                                        activeTab == "submitted" ? "tab-pane active" : "tab-pane"
                                    }
                                    id="tab-submitted"
                                >
                                    <div className="d-flex row">
                                        <div className="col-6">
                                            {startupTopId != "" && (
                                                <p className="dashboard application-count">
                                                    Application Number - {startupTopId}
                                                </p>
                                            )}
                                        </div>
                                        <div className="col-6 text-right">
                                            {applicationStatusLable == "Marked Incomplete" ? (
                                                <button
                                                    className="btn btn-sisfs btn-yellow-primary"
                                                    onClick={() => continueEditing(startupTopId)}
                                                >
                                                    Continue Editing
                                                </button>
                                            ) : incubatorPrefereneceList.length > 0 ? (
                                                <button
                                                    className="btn btn-sisfs btn-transparent-primary"
                                                    onClick={() => getActiveApplication(startupTopId, "")}
                                                >
                                                    View Application
                                                </button>
                                            ) : null}
                                            {/* {incubatorPrefereneceList.length>0&& applicationStatusLable != ""&&   <button
                                                    className="btn btn-sisfs btn-transparent-primary"
                                                    onClick={() =>
                                                        getActiveApplication(startupTopId)
                                                    }
                                                >
                                                    View Application
                                                </button>} */}
                                        </div>
                                    </div>

                                    <div className="row justify-content-center">
                                        <div className="col-xs-12 col-sm-12">
                                            {startupTopId != "" &&
                                                <div className="status-list mt-0 py-2" style={{ backgroundColor: '#e9ecef' }}>
                                                    <p className="mb-0" style={{ fontSize: '18px', fontWeight: 'bolder', fontFamily: 'Roboto Slab' }}>Incubator Preferences</p>
                                                </div>
                                            }

                                            {incubatorPrefereneceList.length > 0 &&
                                                incubatorPrefereneceList.map(
                                                    (item: any, index: number) =>
                                                        item.item?.response_label != "Cancelled" && (
                                                            <span key={index}>
                                                                <div className="status-list mt-0">
                                                                    <div className="row mb-3 mt-3">
                                                                        <h4 className="col-6">
                                                                            {/* Application <small>({applicationStatusLable == "Draft" ? "In Progress" : "#" + item.application_id})</small> */}
                                                                            <span style={{ fontSize: '18px', fontWeight: 'bolder', fontFamily: 'Roboto Slab' }}>{index + 1}.</span>
                                                                            {item.incubator_preference_name != null
                                                                                ? item.incubator_preference_name
                                                                                : item.prefrence_incubator}
                                                                        </h4>
                                                                        <div className="col-3">
                                                                            {item.response_label == "Draft" && (
                                                                                <span className="status-badge blue-badge">
                                                                                    <b>{item.response_label}</b>
                                                                                </span>
                                                                            )}
                                                                            {item.response_label ==
                                                                                "Marked Incomplete" && (
                                                                                    <span className="status-badge yellow-badge">
                                                                                        <b>{item.response_label}</b>
                                                                                    </span>
                                                                                )}
                                                                            {item.response_label == "Submitted" && (
                                                                                <span className="status-badge blue-badge">
                                                                                    <b>{item.response_label}</b>
                                                                                </span>
                                                                            )}
                                                                            {item.response_label == "On Hold" && (
                                                                                <span className="status-badge red-badge">
                                                                                    <b>{item.response_label}</b>
                                                                                </span>
                                                                            )}
                                                                            {item.response_label == "Close" && (
                                                                                <span className="status-badge grey-badge">
                                                                                    <b>{item.response_label}</b>
                                                                                </span>
                                                                            )} 
                                                                            {item.response_label ==
                                                                                "Pending Review" && (
                                                                                    <span className="status-badge yellow-badge">
                                                                                        <b>{item.response_label}</b>
                                                                                    </span>
                                                                                )}
                                                                            {item.response_label == "Rejected" && (
                                                                                <span className="status-badge red-badge">
                                                                                    <b>{item.response_label}</b>
                                                                                </span>
                                                                            )}
                                                                            {item.response_label == "Selected" && (
                                                                                <span className="status-badge blue-badge">
                                                                                    <b>{item.response_label}</b>
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                        <div className="col-3 text-right">
                                                                            <div className="btn-group header-action-section">
                                                                                {item.application_comments.length !=
                                                                                    0 && <>

                                                                                        <button
                                                                                            className="btn btn-sisfs btn-transparent-primary"
                                                                                            data-toggle="collapse"
                                                                                            data-target={`#status_comment_${index}`}
                                                                                        >
                                                                                            View Details
                                                                                        </button>

                                                                                    </>

                                                                                }

                                                                                {<>
                                                                                    {/* <button
                                                                                            className="btn btn-sisfs btn-transparent-primary"
                                                                                            data-toggle="collapse" data-target={`#status_comment_${index}`}
                                                                                        >
                                                                                            View Details
                                                                                        </button> */}
                                                                                </>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        {item.submitted_date != null && (
                                                                            <div className="col-2">
                                                                                <span className="d-block mb-2">
                                                                                    Submitted
                                                                                </span>
                                                                                <span>
                                                                                    <b>
                                                                                        {item.first_submitted_date != null
                                                                                            ? FormatDate(
                                                                                                new Date(
                                                                                                    item.first_submitted_date
                                                                                                )
                                                                                            )
                                                                                            : FormatDate(
                                                                                                new Date(item.submitted_date)
                                                                                            )}
                                                                                    </b>
                                                                                </span>
                                                                            </div>
                                                                        )}

                                                                        {item.response_label != "Draft" ? (
                                                                            <>
                                                                                <div className="col-4">
                                                                                    <span className="d-block mb-2">
                                                                                        Application Completeness Checked On
                                                                                    </span>
                                                                                    <span>
                                                                                        <b>
                                                                                            {item.response_date != null
                                                                                                ? FormatDate(
                                                                                                    new Date(item.response_date)
                                                                                                )
                                                                                                : "In Progress"}
                                                                                        </b>
                                                                                    </span>
                                                                                </div>{" "}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <div className="col-4">
                                                                                    <span className="d-block mb-2">
                                                                                        Last Updated
                                                                                    </span>
                                                                                    <span>
                                                                                        <b>
                                                                                            {item.last_updated != null
                                                                                                ? FormatDate(
                                                                                                    new Date(item.last_updated)
                                                                                                )
                                                                                                : "-"}
                                                                                        </b>
                                                                                    </span>
                                                                                </div>
                                                                            </>
                                                                        )}

                                                                        {/* <div className="ml-auto mr-4">
                                                                <label className="link-orange-text link-underline"  >Download EAC Deck</label>
                                                            </div> */}
                                                                    </div>
                                                                </div>
                                                                {item.application_comments.length != 0 && (
                                                                    <div
                                                                        className="collapse status-collapse"
                                                                        id={`status_comment_${index}`}
                                                                    >
                                                                        <label className="comment-heading">
                                                                            Incubator Comments/Feedback
                                                                        </label>
                                                                        {/* {item.response_date != null && <label className="comment-date">{FormatDate(new Date(item.response_date))}</label>} */}

                                                                        <div className="row pl-1">
                                                                            <table className="reviewTable contactUsTable statusTable">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th className="table-heading">
                                                                                            Application Status
                                                                                        </th>
                                                                                        <th className="table-heading">
                                                                                            Date
                                                                                        </th>
                                                                                        <th className="table-heading">
                                                                                            Comments
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {item.application_comments.length ==
                                                                                        0 ? (
                                                                                        <tr>
                                                                                            <td
                                                                                                className="text-center"
                                                                                                colSpan={3}
                                                                                            >
                                                                                                No data
                                                                                            </td>
                                                                                        </tr>
                                                                                    ) : (
                                                                                        item.application_comments.map(
                                                                                            (item: any) => (
                                                                                                <tr key={index}>
                                                                                                    <td className="text-left custom-td">
                                                                                                        {item.label}
                                                                                                    </td>
                                                                                                    <td className="text-left custom-td">
                                                                                                        {FormatDate(
                                                                                                            item.created_at
                                                                                                        )}
                                                                                                    </td>
                                                                                                    {item.incubator_status_comment !=
                                                                                                        null ? (
                                                                                                        <td className="text-left custom-td">
                                                                                                            {showViewMore !=
                                                                                                                "show_" + index && (
                                                                                                                    <>
                                                                                                                        <div>
                                                                                                                            {showEllipsisText(
                                                                                                                                item.incubator_status_comment,
                                                                                                                                50
                                                                                                                            )}
                                                                                                                        </div>
                                                                                                                        {item
                                                                                                                            .incubator_status_comment
                                                                                                                            .length >= 50 && (
                                                                                                                                <span
                                                                                                                                    className="link-orange-text d-block"
                                                                                                                                    onClick={() =>
                                                                                                                                        setShowViewMore(
                                                                                                                                            "show_" +
                                                                                                                                            index
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                >
                                                                                                                                    View More
                                                                                                                                </span>
                                                                                                                            )}
                                                                                                                    </>
                                                                                                                )}
                                                                                                            {showViewMore ==
                                                                                                                "show_" + index && (
                                                                                                                    <>
                                                                                                                        <div>
                                                                                                                            {
                                                                                                                                item.incubator_status_comment
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                        <span
                                                                                                                            className="link-orange-text d-block"
                                                                                                                            onClick={() =>
                                                                                                                                setShowViewMore(
                                                                                                                                    ""
                                                                                                                                )
                                                                                                                            }
                                                                                                                        >
                                                                                                                            View Less
                                                                                                                        </span>
                                                                                                                    </>
                                                                                                                )}
                                                                                                        </td>
                                                                                                    ) : (
                                                                                                        <td className="text-left custom-td">
                                                                                                            -
                                                                                                        </td>
                                                                                                    )}
                                                                                                </tr>
                                                                                            )
                                                                                        )
                                                                                    )}
                                                                                </tbody>
                                                                            </table>
                                                                            {/* <div className="col-7">
                                                                    <div>
                                                                        <label className="comment-heading">Incubator Comments/Feedback</label><br />
                                                                        {item.response_date != null && <label className="comment-date">{FormatDate(new Date(item.response_date))}</label>}
                                                                    </div>
                                                                    <div className="comment-content">{item.incubator_comment}</div>

                                                                </div> */}
                                                                            {/* end */}
                                                                            {/* <div className="col-5 text-right pr-3">
                                                                {item.Upload_pitchdeck == "Yes" ? <><label className="comment-heading">EAC Performance Evaluation Deck</label>
                                                                    <label className="link-orange-text">Download Deck Format</label> | <label htmlFor={"inputUpload_" + item.application_id} className="link-orange-text"><input id={"inputUpload_" + item.application_id} className="d-none" type="file" onChange={(e) =>
                                                                        onChange(e, `${item.application_id}`, `${item.file_id}`)
                                                                    } />Upload Deck</label></> : null}
                                                            </div> */}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                <div className="seperator"></div>
                                                            </span>
                                                        )
                                                )}
                                        </div>
                                    </div>

                                    {/* <hr /> */}
                                    <div className="row justify-content-center">
                                        <div className="col-xs-12 col-sm-12">
                                            {applicationListData != undefined &&
                                                applicationListData.map(
                                                    (item: any, index: any) =>
                                                        item.startup_application_id !== startupTopId && (
                                                            <span key={index}>
                                                                <div className="status-list">
                                                                    <div className="row mb-3">
                                                                        <h4 className="col-3">
                                                                            Application{" "}
                                                                            <small>
                                                                                (
                                                                                {item.application_status_lable == "Draft"
                                                                                    ? "In Progress"
                                                                                    : "#" + item.startup_application_id}
                                                                                )
                                                                            </small>
                                                                        </h4>
                                                                        <div className="col-3">
                                                                            {item.application_status_lable ==
                                                                                "Draft" && (
                                                                                    <span className="status-badge blue-badge">
                                                                                        <b>{item.application_status_lable}</b>
                                                                                    </span>
                                                                                )}
                                                                            {item.application_status_lable ==
                                                                                "Marked Incomplete" && (
                                                                                    <span className="status-badge yellow-badge">
                                                                                        <b>{item.application_status_lable}</b>
                                                                                    </span>
                                                                                )}
                                                                            {item.application_status_lable ==
                                                                                "Incomplete" && (
                                                                                    <span className="status-badge yellow-badge">
                                                                                        <b>Incomplete</b>
                                                                                    </span>
                                                                                )}
                                                                            {item.application_status_lable ==
                                                                                "Submitted" && (
                                                                                    <span className="status-badge blue-badge">
                                                                                        <b>{item.application_status_lable}</b>
                                                                                    </span>
                                                                                )}
                                                                            {item.application_status_lable ==
                                                                                "On Hold" && (
                                                                                    <span className="status-badge red-badge">
                                                                                        <b>{item.application_status_lable}</b>
                                                                                    </span>
                                                                                )}
                                                                            {item.application_status_lable ==
                                                                                "Close" && (
                                                                                    <span className="status-badge grey-badge">
                                                                                        <b>{item.application_status_lable}</b>
                                                                                    </span>
                                                                                )}
                                                                             
                                                                            {item.application_status_lable ==
                                                                                "Pending Review" && (
                                                                                    <span className="status-badge yellow-badge">
                                                                                        <b>{item.application_status_lable}</b>
                                                                                    </span>
                                                                                )}
                                                                            {item.application_status_lable ==
                                                                                "Rejected" && (
                                                                                    <span className="status-badge red-badge">
                                                                                        <b>{item.application_status_lable}</b>
                                                                                    </span>
                                                                                )}
                                                                            {item.application_status_lable ==
                                                                                "Selected" && (
                                                                                    <span className="status-badge blue-badge">
                                                                                        <b>{item.application_status_lable}</b>
                                                                                    </span>
                                                                                )}
                                                                        </div>
                                                                        <div className="col-6 text-right">
                                                                            <div className="btn-group header-action-section">
                                                                                {item.application_status_lable ==
                                                                                    "Marked Incomplete" ||
                                                                                    item.application_status_lable ==
                                                                                    "Draft" ? (
                                                                                    <>
                                                                                        {item.application_status_lable ==
                                                                                            "Marked Incomplete" &&
                                                                                            item.application_comments.length !=
                                                                                            0 && (
                                                                                                <button
                                                                                                    className="btn btn-sisfs btn-transparent-primary"
                                                                                                    data-toggle="collapse"
                                                                                                    data-target={`#status_comment_application_${item.startup_application_id}`}
                                                                                                >
                                                                                                    View Details
                                                                                                </button>
                                                                                            )}
                                                                                        {item.application_status_lable ==
                                                                                            "Draft" ? (
                                                                                            <button
                                                                                                className="btn btn-sisfs btn-transparent-primary"
                                                                                                data-toggle="modal"
                                                                                                data-target="#deleteModal"
                                                                                                onClick={() =>
                                                                                                    deleteApplicationModal(
                                                                                                        item.startup_application_id
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                Delete
                                                                                            </button>
                                                                                        ) : null}
                                                                                        <button
                                                                                            className="btn btn-sisfs btn-yellow-primary"
                                                                                            onClick={() =>
                                                                                                continueEditing(
                                                                                                    item.startup_application_id
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            Continue Editing
                                                                                        </button>
                                                                                    </>
                                                                                ) : (
                                                                                    <button
                                                                                        className="btn btn-sisfs btn-transparent-primary"
                                                                                        onClick={() =>
                                                                                            getActiveApplication(
                                                                                                item.startup_application_id,
                                                                                                item.startup_status_id
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        View Application
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        {item.submitted_date != null && (
                                                                            <div className="col-2">
                                                                                <span className="d-block mb-2">
                                                                                    Submitted
                                                                                </span>
                                                                                <span>
                                                                                    <b>
                                                                                        {item.first_submitted_date != null
                                                                                            ? FormatDate(
                                                                                                new Date(
                                                                                                    item.first_submitted_date
                                                                                                )
                                                                                            )
                                                                                            : FormatDate(
                                                                                                new Date(item.submitted_date)
                                                                                            )}
                                                                                    </b>
                                                                                </span>
                                                                            </div>
                                                                        )}

                                                                        {item.application_status_lable ==
                                                                            "Pending Review" ? (
                                                                            <>
                                                                                <div className="col-4">
                                                                                    <span className="d-block mb-2">
                                                                                        Application Completeness Checked On
                                                                                    </span>
                                                                                    <span>
                                                                                        <b>
                                                                                            {FormatDate(
                                                                                                new Date(item.response_date)
                                                                                            )}
                                                                                        </b>
                                                                                    </span>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <div className="col-4">
                                                                                    <span className="d-block mb-2">
                                                                                        Last Updated
                                                                                    </span>
                                                                                    <span>
                                                                                        <b>
                                                                                            {item.last_updated != null
                                                                                                ? FormatDate(
                                                                                                    new Date(item.last_updated)
                                                                                                )
                                                                                                : "-"}
                                                                                        </b>
                                                                                    </span>
                                                                                </div>
                                                                            </>
                                                                        )}

                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="collapse status-collapse"
                                                                    id={`status_comment_application_${item.startup_application_id}`}
                                                                >
                                                                    <label className="comment-heading">
                                                                        Incubator Comments/Feedback
                                                                    </label>
                                                                    <div className="row pl-1">
                                                                        <table className="reviewTable contactUsTable statusTable">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th className="table-heading">
                                                                                        Application Status
                                                                                    </th>
                                                                                    <th className="table-heading">Date</th>
                                                                                    <th className="table-heading">
                                                                                        Comments
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {item.application_comments.length == 0 ? (
                                                                                    <tr>
                                                                                        <td
                                                                                            className="text-center"
                                                                                            colSpan={3}
                                                                                        >
                                                                                            No data
                                                                                        </td>
                                                                                    </tr>
                                                                                ) : (
                                                                                    item.application_comments.map(
                                                                                        (item: any) => (
                                                                                            <tr key={item.id}>
                                                                                                <td className="text-left custom-td">
                                                                                                    {item.label}
                                                                                                </td>
                                                                                                <td className="text-left custom-td">
                                                                                                    {FormatDate(item.created_at)}
                                                                                                </td>
                                                                                                {item.incubator_status_comment !=
                                                                                                    null ? (
                                                                                                    <td className="text-left custom-td">
                                                                                                        {showViewMore !=
                                                                                                            "show_" + index && (
                                                                                                                <>
                                                                                                                    <div>
                                                                                                                        {showEllipsisText(
                                                                                                                            item.incubator_status_comment,
                                                                                                                            50
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                    {item
                                                                                                                        .incubator_status_comment
                                                                                                                        .length >= 50 && (
                                                                                                                            <span
                                                                                                                                className="link-orange-text d-block"
                                                                                                                                onClick={() =>
                                                                                                                                    setShowViewMore(
                                                                                                                                        "show_" + index
                                                                                                                                    )
                                                                                                                                }
                                                                                                                            >
                                                                                                                                View More
                                                                                                                            </span>
                                                                                                                        )}
                                                                                                                </>
                                                                                                            )}
                                                                                                        {showViewMore ==
                                                                                                            "show_" + index && (
                                                                                                                <>
                                                                                                                    <div>
                                                                                                                        {
                                                                                                                            item.incubator_status_comment
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                    <span
                                                                                                                        className="link-orange-text d-block"
                                                                                                                        onClick={() =>
                                                                                                                            setShowViewMore("")
                                                                                                                        }
                                                                                                                    >
                                                                                                                        View Less
                                                                                                                    </span>
                                                                                                                </>
                                                                                                            )}
                                                                                                    </td>
                                                                                                ) : (
                                                                                                    <td className="text-left custom-td">
                                                                                                        -
                                                                                                    </td>
                                                                                                )}
                                                                                            </tr>
                                                                                        )
                                                                                    )
                                                                                )}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </span>
                                                        )
                                                )}
                                        </div>
                                    </div>

                                </div>
                                {/* selected tab */}

                                <div
                                    className={
                                        activeTab == "selected" ? "tab-pane active" : "tab-pane"
                                    }
                                    id="tab-selected"
                                >
                                    <div className="row justify-content-center">
                                        <div className="col-xs-12 col-sm-12">
                                            {applicationListDataSelected != undefined &&
                                                applicationListDataSelected.map((item: any, index: any) => (
                                                    <span key={index}>

                                                        <div className="status-list">
                                                            <div className="row mb-3">
                                                                <h4 className="col-3">
                                                                    Application{" "}
                                                                    <small>
                                                                        (
                                                                        {item.label == "Draft"
                                                                            ? "In Progress"
                                                                            : "#" + item.startup_application_id}
                                                                        )
                                                                    </small>
                                                                    {item.cancellation_requested == "Yes" && (
                                                                        <>
                                                                            <br />
                                                                            <small>
                                                                                {item.application_status_lable}
                                                                            </small>
                                                                        </>
                                                                    )}
                                                                </h4>
                                                                <div className="col-3 text-left">
                                                                    {item.status_of_engagement_label != null ? (
                                                                        <span className="status-badge blue-badge">
                                                                            <b>
                                                                                {item.cancellation_requested == "No"
                                                                                    ? item.status_of_engagement_label
                                                                                    : item.cancellation_status}
                                                                            </b>
                                                                        </span>
                                                                    ) : (
                                                                        <span className="status-badge blue-badge">
                                                                            <b>
                                                                                {item.cancellation_requested == "No"
                                                                                    ? item.application_status_lable
                                                                                    : item.cancellation_status}
                                                                            </b>
                                                                        </span>
                                                                    )}
                                                                </div>
                                                                <div className="col-3 text-right">
                                                                    {item.application_comments.length != 0 && (
                                                                        <button
                                                                            className="btn btn-sisfs btn-transparent-primary"
                                                                            data-toggle="collapse"
                                                                            data-target={`#selected_comment_${item.startup_application_id}`}
                                                                        >
                                                                            View Details
                                                                        </button>
                                                                    )}
                                                                </div>

                                                                <div className="col-3 text-right">
                                                                    <div className="btn-group header-action-section">
                                                                        <button
                                                                            className="btn btn-sisfs btn-transparent-primary"
                                                                            onClick={() =>
                                                                                getActiveApplication(
                                                                                    item.startup_application_id,
                                                                                    item.startup_status_id
                                                                                )
                                                                            }
                                                                        >
                                                                            View Application
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-3">
                                                                    <span className="d-block mb-2">
                                                                        Supporting Incubator
                                                                    </span>
                                                                    <span>
                                                                        <b>
                                                                            {item.incubator_preference_name != null
                                                                                ? item.incubator_preference_name
                                                                                : item.incubator_name}
                                                                        </b>
                                                                    </span>
                                                                </div>

                                                                {item.completness_checked_date != null && (
                                                                    <div className="col-2">
                                                                        <>
                                                                            <span className="d-block mb-2">
                                                                                Application Completeness Checked On
                                                                            </span>
                                                                            <span>
                                                                                <b>
                                                                                    {FormatDate(
                                                                                        new Date(
                                                                                            item.completness_checked_date
                                                                                        )
                                                                                    )}
                                                                                </b>
                                                                            </span>
                                                                        </>
                                                                    </div>
                                                                )}

                                                                {item.total_applroved_amount != null && (
                                                                    <div className="col-3">
                                                                        <>
                                                                            <span className="d-block mb-2">
                                                                                Funds Approved
                                                                            </span>
                                                                            <span>
                                                                                <b>
                                                                                    &#8377;{" "}
                                                                                    {(item.total_applroved_amount != null
                                                                                        ? changeNumberFormat(
                                                                                            Number(
                                                                                                item.total_applroved_amount
                                                                                            ),
                                                                                            1,
                                                                                            0
                                                                                        )
                                                                                        : 0) +
                                                                                        " (" +
                                                                                        (item.no_of_tranches != null
                                                                                            ? item.no_of_tranches
                                                                                            : 0) +
                                                                                        " Tranches)"}
                                                                                </b>
                                                                            </span>
                                                                        </>
                                                                    </div>
                                                                )}
                                                                {(item.legalAggrement != null || undefined) &&
                                                                    item.legalAggrement.map((item: any) =>
                                                                        <span key={item.id}>
                                                                            {item.legal_agreement != null && (
                                                                                <div className="col-3">
                                                                                    <div className="startup-info-group">
                                                                                        <div className="startup-info-group-label mb-2">
                                                                                            Legal Agreement
                                                                                        </div>
                                                                                        {item.legal_agreement != null ? (
                                                                                            <div className="document-info document-pdf-icon">
                                                                                                <div className="document-info-name">
                                                                                                    {" "}
                                                                                                    <img src={pdf} alt="SISFS Logo" />
                                                                                                    &nbsp;
                                                                                                    {item.legal_agreement.substr(
                                                                                                        item.legal_agreement.lastIndexOf(
                                                                                                            "/"
                                                                                                        ) + 1,
                                                                                                        item.legal_agreement.length
                                                                                                    )}
                                                                                                </div>
                                                                                                <a
                                                                                                    className="tab-pane-link tab-pane-link-view link-orange-text"
                                                                                                    href={`http://35.154.181.26:8000` + item.legal_agreement}
                                                                                                    target="_blank"
                                                                                                >
                                                                                                    View {" "}
                                                                                                </a>
                                                                                                {/* <a
                                                                                    className="tab-pane-link tab-pane-link-view link-orange-text"
                                                                                    onClick={() =>
                                                                                        downloadDocument(
                                                                                            item.legal_agreement,
                                                                                            item.legal_agreement.substr(
                                                                                                item.legal_agreement.lastIndexOf(
                                                                                                    "/"
                                                                                                ) + 1,
                                                                                                item.legal_agreement.length
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    Download
                                                                                </a> */}
                                                                                            </div>
                                                                                        ) : (
                                                                                            " - "
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </span>
                                                                    )

                                                                }
                                                                <div className="col-3">
                                                                    {console.log("item", item)}
                                                                    {
                                                                        (item.cancellation_request_eligibility === 1 &&
                                                                            item.cancellation_requested === "No") ||
                                                                            (item.cancellation_request_eligibility === 1 &&
                                                                                item.cancellation_status === "Cancellation Rejected")
                                                                            ? (
                                                                                <label
                                                                                    className="link-orange-text link-underline"
                                                                                    data-toggle="modal"
                                                                                    onClick={() =>
                                                                                        openCancellationPopup(item.startup_application_id)
                                                                                    }
                                                                                    data-target="#cancellationPopup"
                                                                                >
                                                                                    Request Cancellation
                                                                                </label>
                                                                            ) : null
                                                                    }







                                                                    {item.cancellation_requested == "Yes" &&
                                                                        item.cancellation_status ==
                                                                        "Cancellation Approved" && (
                                                                            <label
                                                                                htmlFor={
                                                                                    "inputDisbursementUpload_" +
                                                                                    item.startup_application_id
                                                                                }
                                                                                className="link-orange-text link-underline mr-4 mt-2"
                                                                            >
                                                                                <input
                                                                                    id={
                                                                                        "inputDisbursementUpload_" +
                                                                                        item.startup_application_id
                                                                                    }
                                                                                    className="d-none"
                                                                                    type="file"
                                                                                    onChange={(e) =>
                                                                                        onChangedDisbursementProof(
                                                                                            e,
                                                                                            `${item.startup_application_id}`
                                                                                        )
                                                                                    }
                                                                                />
                                                                                Upload Disbursement Proof
                                                                            </label>
                                                                        )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="collapse status-collapse"
                                                            id={`selected_comment_${item.startup_application_id}`}
                                                        >
                                                            <label className="comment-heading">
                                                                Incubator Comments/Feedback
                                                            </label>
                                                            <div className="row pl-1">
                                                                {/* <div className="col-7">
                                                                <div>
                                                                    <label className="comment-heading">Incubator Comments/Feedback</label><br />
                                                                    {item.comment_date != null && <label className="comment-date">{FormatDate(item.comment_date)}</label>}
                                                                </div>
                                                                <div className="comment-content">{item.incubator_status_comment}</div>

                                                            </div> */}
                                                                <table className="reviewTable contactUsTable statusTable">
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="table-heading">
                                                                                Application Status
                                                                            </th>
                                                                            <th className="table-heading">Date</th>
                                                                            <th className="table-heading">Comments</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {item.application_comments.length == 0 ? (
                                                                            <tr>
                                                                                <td className="text-center" colSpan={3}>
                                                                                    No data
                                                                                </td>
                                                                            </tr>
                                                                        ) : (
                                                                            item.application_comments.map(
                                                                                (item: any) => (
                                                                                    <tr key={item.id}>
                                                                                        <td className="text-left custom-td">
                                                                                            {item.label}
                                                                                        </td>
                                                                                        <td className="text-left custom-td">
                                                                                            {FormatDate(item.created_at)}
                                                                                        </td>
                                                                                        {item.incubator_status_comment !=
                                                                                            null ? (
                                                                                            <td className="text-left custom-td">
                                                                                                {showViewMore !=
                                                                                                    "show_" + index && (
                                                                                                        <>
                                                                                                            <div>
                                                                                                                {showEllipsisText(
                                                                                                                    item.incubator_status_comment,
                                                                                                                    50
                                                                                                                )}
                                                                                                            </div>
                                                                                                            {item.incubator_status_comment
                                                                                                                .length >= 50 && (
                                                                                                                    <span
                                                                                                                        className="link-orange-text d-block"
                                                                                                                        onClick={() =>
                                                                                                                            setShowViewMore(
                                                                                                                                "show_" + index
                                                                                                                            )
                                                                                                                        }
                                                                                                                    >
                                                                                                                        View More
                                                                                                                    </span>
                                                                                                                )}
                                                                                                        </>
                                                                                                    )}
                                                                                                {showViewMore ==
                                                                                                    "show_" + index && (
                                                                                                        <>
                                                                                                            <div>
                                                                                                                {
                                                                                                                    item.incubator_status_comment
                                                                                                                }
                                                                                                            </div>
                                                                                                            <span
                                                                                                                className="link-orange-text d-block"
                                                                                                                onClick={() =>
                                                                                                                    setShowViewMore("")
                                                                                                                }
                                                                                                            >
                                                                                                                View Less
                                                                                                            </span>
                                                                                                        </>
                                                                                                    )}
                                                                                            </td>
                                                                                        ) : (
                                                                                            <td className="text-left custom-td">
                                                                                                -
                                                                                            </td>
                                                                                        )}
                                                                                    </tr>
                                                                                )
                                                                            )
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </span>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                                {/* closed tab */}
                                <div
                                    className={
                                        activeTab == "closed" ? "tab-pane active" : "tab-pane"
                                    }
                                    id="tab-closed"
                                >
                                    <div className="row justify-content-center">
                                        <div className="col-xs-12 col-sm-12">
                                            {applicationListDataClosed != undefined &&
                                                applicationListDataClosed.map((item: any, index: any) => (
                                                    <span key={index}>
                                                        <div className="status-list">
                                                            <div className="row mb-3">
                                                                <h4 className="col-3">
                                                                    Application{" "}
                                                                    <small>
                                                                        (
                                                                        {item.label == "Draft"
                                                                            ? "In Progress"
                                                                            : "#" + item.startup_application_id}
                                                                        )
                                                                    </small>
                                                                </h4>
                                                                <div className="col-2">
                                                                   {item.application_status_lable ==
                                                                                "Cancelled" && (
                                                                                    <span className="status-badge grey-badge">
                                                                                        <b>{item.application_status_lable}</b>
                                                                                    </span>
                                                                                )}
                                                                 </div>
                                                                <div className="col-4 text-left">
                                                                    {item.status_of_engagement_label != null && (
                                                                        <span className="status-badge blue-badge">
                                                                            <b>{item.status_of_engagement_label}</b>
                                                                        </span>
                                                                    )}
                                                                </div>
                                                                
                                                                <div className="col-3 text-right">
                                                                    <div className="btn-group header-action-section">
                                                                        <button
                                                                            className="btn btn-sisfs btn-transparent-primary"
                                                                            onClick={() =>
                                                                                getActiveApplication(
                                                                                    item.startup_application_id,
                                                                                    item.startup_status_id
                                                                                )
                                                                            }
                                                                        >
                                                                            View Application
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-3">
                                                                    <span className="d-block mb-2">
                                                                        Supporting Incubator
                                                                    </span>
                                                                    <span>
                                                                        <b>
                                                                            {item.incubator_preference_name != null
                                                                                ? item.incubator_preference_name
                                                                                : item.incubator_name}
                                                                        </b>
                                                                    </span>
                                                                </div>

                                                                <div className="col-2">
                                                                    <>
                                                                        <span className="d-block mb-2">
                                                                            Last Updated
                                                                        </span>
                                                                        <span>
                                                                            <b>
                                                                                {FormatDate(new Date(item.last_updated))}
                                                                            </b>
                                                                        </span>
                                                                    </>
                                                                </div>

                                                                {item.total_applroved_amount != null && (
                                                                    <div className="col-3">
                                                                        <>
                                                                            <span className="d-block mb-2">
                                                                                Funds Approved
                                                                            </span>
                                                                            <span>
                                                                                <b>
                                                                                    &#8377;{" "}
                                                                                    {(item.total_applroved_amount != null
                                                                                        ? changeNumberFormat(
                                                                                            Number(
                                                                                                item.total_applroved_amount
                                                                                            ),
                                                                                            1,
                                                                                            0
                                                                                        )
                                                                                        : 0) +
                                                                                        " (" +
                                                                                        (item.no_of_tranches != null
                                                                                            ? item.no_of_tranches
                                                                                            : 0) +
                                                                                        " Tranches)"}
                                                                                </b>
                                                                            </span>
                                                                        </>
                                                                    </div>
                                                                )}
                                                                <div className="col"></div>
                                                                <div className="col-3 text-center">
                                                                    {item.application_comments.length != 0 && (
                                                                        <button
                                                                            className="btn btn-sisfs btn-transparent-primary"
                                                                            data-toggle="collapse"
                                                                            data-target={`#selected_comment_${item.startup_application_id}`}
                                                                        >
                                                                            View Details
                                                                        </button>
                                                                    )}
                                                                </div>
                                                                {/* <div className="ml-auto mr-4">
                                                           <label className="link-orange-text link-underline"  >Request Cancellation</label>
                                                       </div> */}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="collapse status-collapse"
                                                            id={`status_comment_${item.startup_application_id}`}
                                                        >
                                                            <div className="row">
                                                                <div className="col-7">
                                                                    <div>
                                                                        <label className="comment-heading">
                                                                            DPIIT Comments/Feedback
                                                                        </label>
                                                                        <br />
                                                                        <label className="comment-date">date</label>
                                                                    </div>
                                                                    <div className="comment-content">
                                                                        {item.incubator_status_comment}
                                                                    </div>
                                                                </div>
                                                                <div className="col-5 text-right pr-3">
                                                                    {item.Upload_pitchdeck == "Yes" ? (
                                                                        <>
                                                                            <label className="comment-heading">
                                                                                EAC Performance Evaluation Deck
                                                                            </label>
                                                                            <label className="link-orange-text">
                                                                                Download Deck Format
                                                                            </label>{" "}
                                                                            |{" "}
                                                                            <label
                                                                                htmlFor={
                                                                                    "inputUpload_" +
                                                                                    item.startup_application_id
                                                                                }
                                                                                className="link-orange-text"
                                                                            >
                                                                                <input
                                                                                    id={
                                                                                        "inputUpload_" +
                                                                                        item.startup_application_id
                                                                                    }
                                                                                    className="d-none"
                                                                                    type="file"
                                                                                    onChange={(e) =>
                                                                                        onChange(
                                                                                            e,
                                                                                            `${item.startup_application_id}`,
                                                                                            `${item.file_id}`
                                                                                        )
                                                                                    }
                                                                                />
                                                                                Upload Deck
                                                                            </label>
                                                                        </>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="collapse status-collapse"
                                                            id={`selected_comment_${item.startup_application_id}`}
                                                        >
                                                            <label className="comment-heading">
                                                                Incubator Comments/Feedback
                                                            </label>
                                                            <div className="row pl-1">
                                                                {/* <div className="col-7">
                                                                <div>
                                                                    <label className="comment-heading">Incubator Comments/Feedback</label><br />
                                                                    {item.comment_date != null && <label className="comment-date">{FormatDate(item.comment_date)}</label>}
                                                                </div>
                                                                <div className="comment-content">{item.incubator_status_comment}</div>

                                                            </div> */}
                                                                <table className="reviewTable contactUsTable statusTable">
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="table-heading">
                                                                                Application Status
                                                                            </th>
                                                                            <th className="table-heading">Date</th>
                                                                            <th className="table-heading">Comments</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {item.application_comments.length == 0 ? (
                                                                            <tr>
                                                                                <td className="text-center" colSpan={3}>
                                                                                    No data
                                                                                </td>
                                                                            </tr>
                                                                        ) : (
                                                                            item.application_comments.map(
                                                                                (item: any) => (
                                                                                    <tr key={item.id}>
                                                                                        <td className="text-left custom-td">
                                                                                            {item.label}
                                                                                        </td>
                                                                                        <td className="text-left custom-td">
                                                                                            {FormatDate(item.created_at)}
                                                                                        </td>
                                                                                        {item.incubator_status_comment !=
                                                                                            null ? (
                                                                                            <td className="text-left custom-td">
                                                                                                {showViewMore !=
                                                                                                    "show_" + index && (
                                                                                                        <>
                                                                                                            <div>
                                                                                                                {showEllipsisText(
                                                                                                                    item.incubator_status_comment,
                                                                                                                    50
                                                                                                                )}
                                                                                                            </div>
                                                                                                            {item.incubator_status_comment
                                                                                                                .length >= 50 && (
                                                                                                                    <span
                                                                                                                        className="link-orange-text d-block"
                                                                                                                        onClick={() =>
                                                                                                                            setShowViewMore(
                                                                                                                                "show_" + index
                                                                                                                            )
                                                                                                                        }
                                                                                                                    >
                                                                                                                        View More
                                                                                                                    </span>
                                                                                                                )}
                                                                                                        </>
                                                                                                    )}
                                                                                                {showViewMore ==
                                                                                                    "show_" + index && (
                                                                                                        <>
                                                                                                            <div>
                                                                                                                {
                                                                                                                    item.incubator_status_comment
                                                                                                                }
                                                                                                            </div>
                                                                                                            <span
                                                                                                                className="link-orange-text d-block"
                                                                                                                onClick={() =>
                                                                                                                    setShowViewMore("")
                                                                                                                }
                                                                                                            >
                                                                                                                View Less
                                                                                                            </span>
                                                                                                        </>
                                                                                                    )}
                                                                                            </td>
                                                                                        ) : (
                                                                                            <td className="text-left custom-td">
                                                                                                -
                                                                                            </td>
                                                                                        )}
                                                                                    </tr>
                                                                                )
                                                                            )
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </span>
                                                ))}
                                        </div>
                                    </div>
                                </div>

                                {/* rejected tab */}
                                <div
                                    className={
                                        activeTab == "rejected" ? "tab-pane active" : "tab-pane"
                                    }
                                    id="tab-rejected"
                                >
                                    <div className="row justify-content-center">
                                        <div className="col-xs-12 col-sm-12">
                                            {applicationListDataRejected != undefined &&
                                                applicationListDataRejected.map((item: any, index: any) => (
                                                    <span key={index}>
                                                        <div className="status-list">
                                                            <div className="row mb-3">
                                                                <h4 className="col-3">
                                                                    Application{" "}
                                                                    <small>
                                                                        (
                                                                        {item.label == "Draft"
                                                                            ? "In Progress"
                                                                            : "#" + item.startup_application_id}
                                                                        )
                                                                    </small>
                                                                </h4>
                                                                <div className="col-3 text-left">
                                                                    {item.application_status_lable != null && (
                                                                        <span className="status-badge blue-badge">
                                                                            <b>{item.application_status_lable}</b>
                                                                        </span>
                                                                    )}
                                                                </div>
                                                                <div className="col-3 text-right">
                                                                    <div className="btn-group header-action-section">
                                                                        {item.application_comments.length != 0 && (
                                                                            <button
                                                                                className="btn btn-sisfs btn-transparent-primary"
                                                                                data-toggle="collapse"
                                                                                data-target={`#status_comment_rejected_${item.startup_application_id}`}
                                                                            >
                                                                                View Details
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="col-3 text-right">
                                                                    <button
                                                                        className="btn btn-sisfs btn-transparent-primary"
                                                                        onClick={() =>
                                                                            getActiveApplication(
                                                                                item.startup_application_id,
                                                                                item.startup_status_id
                                                                            )
                                                                        }
                                                                    >
                                                                        View Application
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-3">
                                                                    <span className="d-block mb-2">
                                                                        Submitted Date
                                                                    </span>
                                                                    <span>
                                                                        <b>
                                                                            {item.submitted_date != null
                                                                                ? FormatDate(
                                                                                    new Date(item.submitted_date)
                                                                                )
                                                                                : "-"}
                                                                        </b>
                                                                    </span>
                                                                </div>

                                                                <div className="col-3">
                                                                    <>
                                                                        <span className="d-block mb-2">
                                                                            Rejected Date
                                                                        </span>
                                                                        <span>
                                                                            <b>
                                                                                {item.rejected_date != null
                                                                                    ? FormatDate(
                                                                                        new Date(item.rejected_date)
                                                                                    )
                                                                                    : "-"}
                                                                            </b>
                                                                        </span>
                                                                    </>
                                                                </div>

                                                                <div className="col-3">
                                                                    {item.rejected_date != null && item.allow_date != null ? <>
                                                                        <span className="d-block mb-2">
                                                                            You are allowed to submit the new application from
                                                                        </span>
                                                                        <span>
                                                                            <b>
                                                                                {compareDateString(
                                                                                    item.rejected_date,
                                                                                    item.allow_date
                                                                                )
                                                                                    ? FormatDate(
                                                                                        new Date(item.allow_date))
                                                                                    : FormatDate(
                                                                                        new Date(item.rejected_date))}
                                                                            </b>
                                                                        </span>
                                                                    </> : ''}
                                                                </div>

                                                                {/* <div className="ml-auto mr-4">
                                                           <label className="link-orange-text link-underline"  >Request Cancellation</label>
                                                       </div> */}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="collapse status-collapse"
                                                            id={`status_comment_rejected_${item.startup_application_id}`}
                                                        >
                                                            <label className="comment-heading">
                                                                Incubator Comments/Feedback
                                                            </label>
                                                            <div className="row pl-1">
                                                                {/* <div className="col-7">
                                                                <div>
                                                                    <label className="comment-heading">Incubator Comments/Feedback</label><br />
                                                                    {item.comment_date != null && <label className="comment-date">{FormatDate(item.comment_date)}</label>}
                                                                </div>
                                                                <div className="comment-content">{item.incubator_status_comment}</div>

                                                            </div> */}
                                                                <table className="reviewTable contactUsTable statusTable">
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="table-heading">
                                                                                Application Status
                                                                            </th>
                                                                            <th className="table-heading">Date</th>
                                                                            <th className="table-heading">Comments</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {item.application_comments.length == 0 ? (
                                                                            <tr>
                                                                                <td className="text-center" colSpan={3}>
                                                                                    No data
                                                                                </td>
                                                                            </tr>
                                                                        ) : (
                                                                            item.application_comments.map(
                                                                                (item: any) => (
                                                                                    <tr>
                                                                                        <td className="text-left custom-td">
                                                                                            {item.label}
                                                                                        </td>
                                                                                        <td className="text-left custom-td">
                                                                                            {FormatDate(item.created_at)}
                                                                                        </td>
                                                                                        {item.incubator_status_comment !=
                                                                                            null ? (
                                                                                            <td className="text-left custom-td">
                                                                                                {showViewMore !=
                                                                                                    "show_" + index && (
                                                                                                        <>
                                                                                                            <div>
                                                                                                                {showEllipsisText(
                                                                                                                    item.incubator_status_comment,
                                                                                                                    50
                                                                                                                )}
                                                                                                            </div>
                                                                                                            {item.incubator_status_comment
                                                                                                                .length >= 50 && (
                                                                                                                    <span
                                                                                                                        className="link-orange-text d-block"
                                                                                                                        onClick={() =>
                                                                                                                            setShowViewMore(
                                                                                                                                "show_" + index
                                                                                                                            )
                                                                                                                        }
                                                                                                                    >
                                                                                                                        View More
                                                                                                                    </span>
                                                                                                                )}
                                                                                                        </>
                                                                                                    )}
                                                                                                {showViewMore ==
                                                                                                    "show_" + index && (
                                                                                                        <>
                                                                                                            <div>
                                                                                                                {
                                                                                                                    item.incubator_status_comment
                                                                                                                }
                                                                                                            </div>
                                                                                                            <span
                                                                                                                className="link-orange-text d-block"
                                                                                                                onClick={() =>
                                                                                                                    setShowViewMore("")
                                                                                                                }
                                                                                                            >
                                                                                                                View Less
                                                                                                            </span>
                                                                                                        </>
                                                                                                    )}
                                                                                            </td>
                                                                                        ) : (
                                                                                            <td className="text-left custom-td">
                                                                                                -
                                                                                            </td>
                                                                                        )}
                                                                                    </tr>
                                                                                )
                                                                            )
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </span>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {showDeleteModal && (
                        // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
                        <>
                            <div className="modal-backdrop fade show"></div>
                            <div
                                className="modal align-middle modal-sisfs d-block"
                                id="FormSubmissionError"
                            >
                                <div className="modal-dialog modal-dialog-centered modal-md">
                                    <div className="modal-content">
                                        <div className="modal-body text-center">
                                            {/* <button
                                            type="button"
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => (
                                            setDeleteModal(false)
                                            )}>
                                            <span aria-hidden="true">×</span>
                                        </button>
                                        <br />
                                            <br /><br /> */}
                                            <h3 className="modal-message message-orange">
                                                Are you sure you want to delete the application
                                            </h3>
                                            <br />
                                            <div className="modal-action">
                                                <div className="row pl-3">
                                                    <div className="col-md-6">
                                                        <button
                                                            className="btn btn-sisfs btn-transparent-primary full-width"
                                                            onClick={() => (setDeleteModal(false))}
                                                        >
                                                            CANCEL
                                                        </button>
                                                    </div>
                                                    {/* {console.log("Data Check", data)} */}
                                                    <div className="col-md-6">

                                                        <button
                                                            onClick={() =>
                                                                deleteApplication(
                                                                    deleteId
                                                                )
                                                            }
                                                            className="tertiary  btn-yellow-primary full-width"
                                                        >
                                                            DELETE
                                                        </button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {showEditSuccessModal && (
                        // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
                        <>
                            <div className="modal-backdrop fade show"></div>

                            <div
                                className="modal align-middle modal-sisfs d-block"
                                id="FormSubmissionSuccess"
                            >
                                <div className="modal-dialog modal-dialog-centered modal-md">
                                    <div className="modal-content">
                                        <div className="modal-body text-center">
                                            <i className="fa fa-check-circle-o"></i>
                                            <h3 className="modal-message">Thank You !</h3>
                                            <div className="modal-text">
                                                Application has been deleted Successfully !!!
                                            </div>
                                            <div className="modal-action">
                                                <button
                                                    className="btn btn-sisfs btn-yellow-primary"
                                                    onClick={() => dismissModal()}
                                                >
                                                    Ok !!
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {/* edit fail modal */}
                    {showEditFailureModal && (
                        // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
                        <>
                            <div className="modal-backdrop fade show"></div>
                            <div
                                className="modal align-middle modal-sisfs d-block"
                                id="FormSubmissionError"
                            >
                                <div className="modal-dialog modal-dialog-centered modal-md">
                                    <div className="modal-content">
                                        <div className="modal-body text-center">
                                            {/* <i className="fa fa-times-circle-o"></i> */}
                                            <h3 className="modal-message message-orange">
                                                Oops! Something is Wrong
                                            </h3>
                                            <br />
                                            <h3 className="modal-message message-orange">
                                                Please Try Again!!!
                                            </h3>
                                            <div className="modal-action">
                                                <button
                                                    className="btn btn-sisfs btn-yellow-primary"
                                                    onClick={() => dismissModal()}
                                                >
                                                    OK!
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    {/* cancellation popup */}
                    <div
                        className="modal fade"
                        id="cancellationPopup"
                        role="dialog"
                        aria-labelledby="exampleModalLabel1"
                        aria-hidden="true"
                    >
                        <div
                            className="modal-dialog modal-dialog-slideout modal-md"
                            role="document"
                        >
                            <div className="modal-content">
                                <div className="modal-header modal-header-bottom pr-5">
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body pl-5 pr-5 pt-4">
                                    <div className="popup-head mb-4 ml-3">
                                        <div className="main-heading">Request Cancellation</div>
                                    </div>
                                    <br></br><br></br><br></br><br></br>
                                    <label className="col-sm-12 col-form-head">
                                        Reason for Cancellation Request
                                    </label> <br></br>
                                    <div className="col-md-12 mb-4">
                                        <textarea
                                            className="form-control"
                                            rows={3}
                                            placeholder="Describe in Max. 1000 characters"
                                            name="reason"
                                            value={cancellationData.reason}
                                            required={true}
                                            maxLength={1000}
                                            onChange={(e: any) => {
                                                setCancellationData((prevState) => ({
                                                    ...prevState,
                                                    reason: e.target.value,
                                                }));
                                            }}
                                        ></textarea>
                                    </div>
                                    {/* <div className="col-md-12 mb-4">
                                        <label className="col-form-head">
                                            Amount to be Refunded
                                        </label>
                                        <input
                                            type="number"
                                            value={cancellationData.amount_refunded}
                                            placeholder="Enter Amount (&#8377;)"
                                            name="amount_refunded"
                                            onChange={(e: any) => {
                                                setCancellationData((prevState) => ({
                                                    ...prevState,
                                                    amount_refunded: e.target.value,
                                                }));
                                            }}
                                            className="form-control input-height"
                                        />
                                    </div> */}

                                    {/* <div className="col-md-12 mb-5">
                                        <label className="col-form-head">
                                            Interest Accumulated on Funds
                                        </label>
                                        <input
                                            type="number"
                                            value={cancellationData.interest_funds}
                                            placeholder="Enter Amount (&#8377;)"
                                            name="interest_funds"
                                            onChange={(e: any) => {
                                                setCancellationData((prevState) => ({
                                                    ...prevState,
                                                    interest_funds: e.target.value,
                                                }));
                                            }}
                                            className="form-control input-height"
                                        />
                                    </div> */}

                                    {/* <div className="col-md-12  mb-5">
                                        <label className="col-form-head">
                                            Audited Report of Bank Statement
                                        </label>
                                        <div className="">
                                            <button
                                                className="btn btn-sisfs btn-transparent-primary"
                                                onClick={() =>
                                                    setOpenUploadBlock({ BankStatementUpload: true })
                                                }
                                                disabled={false}
                                            >
                                                Upload
                                            </button>
                                            {openUploadBlock &&
                                                openUploadBlock["BankStatementUpload"] && (
                                                    <>
                                                        <span
                                                            className="close"
                                                            onClick={() =>
                                                                setOpenUploadBlock({
                                                                    BankStatementUpload: false,
                                                                })
                                                            }
                                                        >
                                                            <i className="fa fa-close"></i>
                                                        </span>
                                                        <FileUploader
                                                            key={"bank_statement_upload"}
                                                            id={"bank_statement_upload"}
                                                            information="Drag & Drop the file or Browse"
                                                            fileType="application/pdf"
                                                            buttonLabel="Browse"
                                                            multiple={false}
                                                            enablePreview
                                                            IconComponent={() => (
                                                                <span className="fa fa-cloud-upload"></span>
                                                            )}
                                                            removeIconClass=""
                                                            existingFiles={uploadFile}
                                                            onUploadFinish={(files: Array<File>) => {
                                                                onFileUploadComplete(files, "file");
                                                            }}
                                                            onFileDelete={(deletedFile: File) => {
                                                                onFileDelete("file");
                                                            }}
                                                        />
                                                        <small>Supported file format - PDF only</small>
                                                    </>
                                                )}
                                        </div>
                                    </div> */}

                                    {/* <div className="col-md-12  mb-4">
                                        <label className="col-form-head">
                                            Supporting Documents (Optional)
                                        </label>
                                        <div className="">
                                            <button
                                                className="btn btn-sisfs btn-transparent-primary"
                                                onClick={() =>
                                                    setOpenUploadBlock({
                                                        SupportingDocumentsUpload: true,
                                                    })
                                                }
                                                disabled={false}
                                            >
                                                Upload
                                            </button>
                                            {openUploadBlock &&
                                                openUploadBlock["SupportingDocumentsUpload"] && (
                                                    <>
                                                        <span
                                                            className="close"
                                                            onClick={() =>
                                                                setOpenUploadBlock({
                                                                    SupportingDocumentsUpload: false,
                                                                })
                                                            }
                                                        >
                                                            <i className="fa fa-close"></i>
                                                        </span>
                                                        <FileUploader
                                                            key={"supporting_documents_upload"}
                                                            id={"supporting_documents_upload"}
                                                            information="Drag & Drop the file or Browse"
                                                            fileType="application/pdf"
                                                            buttonLabel="Browse"
                                                            multiple={false}
                                                            enablePreview
                                                            IconComponent={() => (
                                                                <span className="fa fa-cloud-upload"></span>
                                                            )}
                                                            removeIconClass=""
                                                            existingFiles={other_file}
                                                            onUploadFinish={(files: Array<File>) => {
                                                                onFileUploadComplete(files, "other_file");
                                                            }}
                                                            onFileDelete={(deletedFile: File) => {
                                                                onFileDelete("other_file");
                                                            }}
                                                        />
                                                        <small>Supported file format - PDF only</small>
                                                    </>
                                                )}
                                        </div>
                                    </div> */}
                                </div>
                                <div className="modal-footer">
                                    <div className="row modal-row">
                                        <div className="col-md-6 text-center">
                                            <button
                                                className="tertiary  btn-transparent-primary full-width"
                                                data-dismiss="modal"
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                        <div className="col-md-6 text-left">
                                            <button
                                                className={
                                                    isDisabled
                                                        ? "btn btn-sisfs btn-yellow-primary full-width custom-disabled"
                                                        : "btn btn-sisfs btn-yellow-primary full-width"
                                                }
                                                disabled={isDisabled}
                                                onClick={submitCancellationRequest}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {showIncubatorSuccessModal && (
                        <ConfirmationDialog
                            headerText={dialogData.header}
                            bodyText={dialogData.text}
                            onDialogClose={dismissModal}
                        />
                    )}
                    {showIncubatorFailureModal && (
                        <ConfirmationDialog
                            headerText={failDialogData.header}
                            bodyText={failDialogData.text}
                            onDialogClose={dismissModal}
                            iconClass="test"
                        />
                    )}
                    {invalidPopup && (
                        <ConfirmationDialog
                            headerText={failDialogData.header}
                            bodyText={failDialogData.text}
                            onDialogClose={dismissInvalidModal}
                            iconClass="test"
                        />
                    )}
                </div>
                {certmodal &&
                    <ConfirmationDialog
                        headerText={"Oops !!"}
                        bodyText={
                            <React.Fragment>
                                Account detail provided is not recognized. If your startup is recognized, please use the user account having active startup recognition on{" "}
                                <a href='https://startupindia.gov.in/' target="_blank">startupindia.gov.in</a>{" "}
                                or use your CIN/LLPIN/ Registration number as username for login. In case you still face an issue, please raise a ticket in the contact page of the website.
                            </React.Fragment>}
                        onDialogClose={() => setCertmodal(false)}
                        iconClass="test"
                    />

                }
                <GetApplicationInfoComponent />

                <ContactSectionComponent />

            </div>
        </>
    );
}

export default StartupStatusComponent;
