import { useEffect, useState } from "react";
import {
  STORE_STARTUP_APPLICATION,
  VIEW_STARTUP_APPLICATION,
} from "../../../../constants/api";
import { get } from "../../../../effects/api/api.common";
import { FileUploadTypeModel } from "../../../../models/FileUploadModel";
import { TeamDetails } from "../../../../models/incubator/IncubatorTeamModel";
import { FundingDetailsModel } from "../../../../models/startup/FundingDetailsModel";
import {
  initAuthorizedRepresentative,
  initEntityDetail,
  initFundingDetails,
  initIncubatorPreference,
  initStartupDetail,
  initStartupTeam,
  initUploadDocuments,
  StartupModel,
} from "../../../../models/startup/StartupModel";
import { createFile, downloadDocument, showEllipsisText } from "../../../../utils/CommonMethods";
import { FormatDate } from "../../../../utils/DateFormat";
interface IPreferencProp {
  preferenceData: Array<any>;
}
function StartupApplicationDetailsComponent(props: any) {
  const { preferenceData } = props;
  
  const [showViewMore, setShowViewMore] = useState("");

  useEffect(() => {
    // console.log(preferenceData);
  }, [preferenceData]);

  return (
    <>
      <div
        className="accordion accordion-details-wrap"
        id="accordion-application-details"
      >
        <div className="card accordion-card">
          <div className="card-body accordion-card-body">
            <div className="status-list-wrapper mt-4">
              <div className="row justify-content-center">
                <div className="col-xs-12 col-sm-12">
                  {preferenceData && preferenceData.length > 0 &&
                    preferenceData && preferenceData.map((item: any, index: number) => (
                      <>
                      <div className="status-list">
                        <div className="row mb-3">
                          <h4 className="col-3">
                            {/* {console.log("test sarang",item)} */}
                            {item.incubator_preference_name == null
                              ?item.registered_name
                              :item.incubator_preference_name}
                          </h4>
                          <div className="col-4">
                            {item.tag_status == "Draft" && (
                              <span className="status-badge blue-badge">
                                <b>{item.tag_status}</b>
                              </span>
                            )}
                            {item.tag_status ==
                              "Marked Incomplete" && (
                              <span className="status-badge yellow-badge">
                                <b>{item.tag_status}</b>
                              </span>
                            )}
                            {item.tag_status == "Incomplete" && (
                              <span className="status-badge yellow-badge">
                                <b>Incomplete</b>
                              </span>
                            )}
                            {item.tag_status == "Submitted" && (
                              <span className="status-badge blue-badge">
                                <b>{item.tag_status}</b>
                              </span>
                            )}
                            {item.tag_status == "On Hold" && (
                              <span className="status-badge red-badge">
                                <b>{item.tag_status}</b>
                              </span>
                            )}
                            {(item.tag_status == "Closed") && (
                              <span className="status-badge grey-badge">
                                <b>{item.tag_status}</b>
                              </span>
                            )}
                            {(item.tag_status == "Cancelled") && (
                              <span className="status-badge grey-badge">
                                <b>{item.tag_status}</b>
                              </span>
                            )}
                            {item.tag_status ==
                              "Pending Review" && (
                              <span className="status-badge yellow-badge">
                                <b>{item.tag_status}</b>
                              </span>
                            )}
                            {item.tag_status == "Rejected" && (
                              <span className="status-badge red-badge">
                                <b>{item.tag_status}</b>
                              </span>
                            )}
                            {item.tag_status == "Selected" && (
                              <span className="status-badge blue-badge">
                                <b>{item.tag_status}</b>
                              </span>
                            )}
                            {item.tag_status == "System Rejected" && (
                              <span className="status-badge red-badge">
                                <b>{item.tag_status}</b>
                              </span>
                            )}
                          </div>
                          <div className="col-5 text-right">
                            <div className="btn-group header-action-section">
                              <button
                                className="btn btn-sisfs btn-transparent-primary"
                                data-toggle="collapse"
                                data-target={`#status_comment_${index}`}
                              >
                                View Comments
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-4">
                            <span className="d-block mb-2">Submitted</span>
                            <span>
                              <b>
                                {item.first_submitted_date != null
                                  ? FormatDate(
                                      new Date(item.first_submitted_date)
                                    )
                                  : "-"}
                              </b>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                      className="collapse status-collapse"
                      id={`status_comment_${index}`}
                  >
                      <label className="comment-heading">
                          Incubator Comments/Feedback
                      </label>
                      <div className="row pl-1">
                          <table className="reviewTable contactUsTable statusTable">
                              <thead>
                                  <tr>
                                      <th className="table-heading">
                                          Application Status
                                      </th>
                                      <th className="table-heading">Date</th>
                                      <th className="table-heading">
                                          Comments
                                      </th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {item.commentsData && item.commentsData.length == 0 ? (
                                      <tr key={index}>
                                          <td
                                              className="text-center"
                                              colSpan={3}
                                          >
                                              No data
                                          </td>
                                      </tr>
                                  ) : (
                                    item.commentsData && item.commentsData.map(
                                          (item: any, index: any) => (
                                              <tr key={index}>
                                                  <td className="text-left custom-td">
                                                      {item.label}
                                                  </td>
                                                  <td className="text-left custom-td">
                                                      {FormatDate(item.created_at)}
                                                  </td>
                                                  {item.incubator_status_comment !=
                                                      null ? (
                                                      <td className="text-left custom-td">
                                                          {showViewMore !=
                                                              "show_" + index && (
                                                                  <>
                                                                      <div>
                                                                          {showEllipsisText(
                                                                              item.incubator_status_comment,
                                                                             20
                                                                          )}
                                                                      </div>
                                                                      {item.incubator_status_comment && item.incubator_status_comment
                                                                          .length >=20 && (
                                                                              <span
                                                                                  className="link-orange-text d-block"
                                                                                  onClick={() =>
                                                                                      setShowViewMore(
                                                                                          "show_" + index
                                                                                      )
                                                                                  }
                                                                              >
                                                                                  View More
                                                                              </span>
                                                                          )}
                                                                  </>
                                                              )}
                                                          {showViewMore ==
                                                              "show_" + index && (
                                                                  <>
                                                                      <div>
                                                                          {
                                                                              item.incubator_status_comment
                                                                          }
                                                                      </div>
                                                                      <span
                                                                          className="link-orange-text d-block"
                                                                          onClick={() =>
                                                                              setShowViewMore("")
                                                                          }
                                                                      >
                                                                          View Less
                                                                      </span>
                                                                  </>
                                                              )}
                                                      </td>
                                                  ) : (
                                                      <td className="text-left custom-td">
                                                          -
                                                      </td>
                                                  )}
                                              </tr>
                                          )
                                      )
                                  )}
                              </tbody>
                          </table>
                          {/* <div className="col-7">
                      <div>
                          <label className="comment-heading">DPIIT Comments/Feedback</label><br />
                          <label className="comment-date">{FormatDate(new Date(item.comment_date))}</label>
                      </div>
                      <div className="comment-content">{item.incubator_status_comment}</div>

                  </div> */}
                          {/* <div className="col-5 text-right pr-3">
                      {item.Upload_pitchdeck == "Yes" ? <><label className="comment-heading">EAC Performance Evaluation Deck</label>
                          <label className="link-orange-text">Download Deck Format</label> | <label htmlFor={"inputUpload_" + item.application_id} className="link-orange-text"><input id={"inputUpload_" + item.application_id} className="d-none" type="file" onChange={(e) =>
                              onChange(e, `${item.application_id}`, `${item.file_id}`)
                          } />Upload Deck</label></> : null}
                  </div> */}
                      </div>
                  </div>
                  </>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StartupApplicationDetailsComponent;
