import cardstartups from "../../../images/startups.svg";
import cardincubator from "../../../images/incubator.svg";
import UserInfoModel from "../../../models/UserInfoModel";
import { getSessionStorageValue } from "../../../effects/utils/session-storage";
import { useHistory } from "react-router-dom";
import { get, post } from "../../../effects/api/api.common";
import { CREATE_EMPTY_APPLICATION, GET_APPLICATION_LIST, STARTUP_ALL_APPLICATIONS } from "../../../constants/api";
import { useEffect, useState } from "react";

function ApplyNowModalComponent() {
  const history = useHistory();
  const [isDisabledIncubator, setIsDisabledIncubator] = useState(true);
  const [isDisabledStartup, setIsDisabledStartup] = useState(true);
  useEffect(() => {
    if (getSessionStorageValue("user")) {
      const user: any = getSessionStorageValue("user");
      const userInfo = JSON.parse(user)
      // console.log(userInfo.roles=='startup'? 'mohan':'lanke',"mohaaan")
      if (userInfo.roles == 'incubator') {
        setIsDisabledIncubator(false);
      }
      else if (userInfo.roles == 'startup') {
        setIsDisabledIncubator(true);
        setIsDisabledStartup(false);
      }
      else {
        setIsDisabledIncubator(true);
        setIsDisabledStartup(true);
      }
    }
    else {
      setIsDisabledIncubator(false);
      setIsDisabledStartup(false)
    }
  }, [getSessionStorageValue("user")]);

  const checkLogin = () => {
    if (getSessionStorageValue("user")) {
      const user: any = getSessionStorageValue("user");
      const userInfo = JSON.parse(user)
      var payload = {
        "logged_user_id": userInfo.id,
        "roles": userInfo.roles
      }
      post(GET_APPLICATION_LIST, payload).then((response: any) => {
        if (!response || !response.data) return;
        if (response.data.length == 0) {
          history.push('/incubator_apply')
        }
        else {
          history.push('/incubator_status')
        }

      });
    }
    else {
      return
    }
  };
  const checkLoginStartup = () => {
    if (getSessionStorageValue("user")) {
      const user: any = getSessionStorageValue("user");
      const userInfo = JSON.parse(user)

      var payload = {
        "logged_user_id": userInfo.id,
        "user_role": userInfo.roles
      }
      post(STARTUP_ALL_APPLICATIONS, payload).then((response: any) => {
        if (!response || !response.data) return;

        if (response.data.data.InProgress.length == 0 && response.data.data.Selected.length == 0) {
          history.push('/startup_apply')
        }
        else {
          history.push('/startup_status')
        }
      });
    }
    else {
      return
    }
  };
  return (
    <>
      <div
        className="modal fade"
        id="ApplyNowModal"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close btn"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body pl-4 pr-4">
              <div className="row">
                <div className="col text-center">
                  <div className="card-apply">
                    <div className="">
                      <img
                        className="img-fluid"
                        src={cardincubator}
                        alt="Card Startup"
                      />
                    </div>
                    <div className="card-apply-heading">For Incubators</div>
                    <div className="card-apply-content">
                      <p>
                        Want to join Seed Fund Scheme as an Incubator? Simply
                        select the apply now button.
                      </p>
                    </div>
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      data-dismiss="modal"
                      onClick={checkLogin}
                      disabled={isDisabledIncubator}
                      data-toggle="modal"
                      data-target="#LoginCollapse"
                    >
                      Apply Now
                    </button>
                  </div>
                </div>

                <div className="col text-center">
                  <div className="card-apply">
                    <div className="">
                      <img
                        className="img-fluid"
                        src={cardstartups}
                        alt="Card Startup"
                      />
                    </div>
                    <div className="card-apply-heading">For Startups</div>
                    <div className="card-apply-content">
                      <p>
                        To join our Seed Fund Scheme, simply select the apply
                        now button and tell us more about your idea.
                      </p>
                    </div>
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      data-dismiss="modal"
                      onClick={checkLoginStartup}
                      disabled={isDisabledStartup}
                      // disabled={true}
                      data-toggle="modal"
                      data-target="#LoginCollapse"
                    >
                      { }
                      Apply Now
                    </button>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-6"></div>
                <div className="col-6 call-applicaton text-center mt-4">
                  {/* <h6>Call for the applications will open soon!</h6> */}
                  {/* <h6>The Startup application will open on
                    <b className="d-inline-flex text-center">1st July 2021</b>
                  </h6> */}

                  <p>
                    For more details, please get in touch with us at
                    <a href="mailto:dipp-startups@nic.in" className="d-block">
                      <u className="link-orange-text"> dipp-startups@nic.in</u>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ApplyNowModalComponent;
