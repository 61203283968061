export class GeneralIncubatorModel {
    constructor(
        // public No_individuals:any,
        // public No_startups_undergoing:any,

        // public List_significant_facilities:any,

        // public List_significant_facilities_upload:any,
        // public List_significant_facilities_upload_slice :any,
        // public List_significant_facilities_upload_get:any,

        // public No_full_time_employees_upload:any,
        // public No_full_time_employees_upload_get:any,
        // public No_full_time_employees_upload_slice:any,

        // public Utilization_Certificate_Upload_get:any = "",
        // public Utilization_Certificate_Upload_slice:any = "",
        
        public No_startups_incubated:any,
        public No_startups_graduated:any,
        public No_startups_raised:any,
        public No_startups_crossed:any,
        public survival_rate_startups:any,
        public Investment_agreements_signed:any,
        public No_startups_invested:any,
        public allocated_incubatees:any,
        public external_sources:any,
        public No_mentors_actively:any,
        public allocated_startup_month:any,
        public No_patents_registered:any,
        public No_patents_copyrights:any,
        public No_trademarks_registered:any,
        public Corporate_connects_facilitated:any,
        public Events_stakeholder_engagements:any,
        public Participation_Event:any,
        public Name_Background_current:any,
        // public No_full_time_employees:any,
        public Fund_Utilization_Details:any,

        // public mteam_name :any,

        // public checkbox_composition_incubator:any,
        // public checkbox_incubation_support:any,
        // public checkbox_mentorship_provided:any,
        // public checkbox_events_organized:any,
        // public Re_Apply_relavant_excel: any,

        public Re_apply_pitchdeck_upload:any,
        public Re_apply_pitchdeck_upload_get:any,
        public Re_apply_pitchdeck_upload_slice:any,

        // public relavent_document_pitchdeck:any,

        public Other_document_upload : any,
        public Other_document_upload_get:any,
        public Other_document_upload_slice:any,

        public Performance_Upload : any,
        public Performance_Upload_get:any,
        public Performance_Upload_slice :any,

        public team_data: Array<TeamDetails>,
        public fulltime_employees_re_apply :any,

        // public sum_disbured :any
    ) {
        //  this.No_individuals= No_individuals;
        //  this.No_startups_undergoing= No_startups_undergoing;

        //  this.List_significant_facilities= List_significant_facilities;

        //  this.List_significant_facilities_upload= List_significant_facilities_upload;
        //  this.List_significant_facilities_upload_slice = List_significant_facilities_upload_slice;
        //  this.List_significant_facilities_upload_get =List_significant_facilities_upload_get;

        // this.No_full_time_employees_upload = No_full_time_employees_upload;
        //  this.No_full_time_employees_upload_get = No_full_time_employees_upload_get;
        //  this.No_full_time_employees_upload_slice =No_full_time_employees_upload_slice;

        //  this. Utilization_Certificate_Upload_get = Utilization_Certificate_Upload_get;
        //  this. Utilization_Certificate_Upload_slice = Utilization_Certificate_Upload_slice;

         this.No_startups_incubated= No_startups_incubated;
         this.No_startups_graduated= No_startups_graduated;
         this.No_startups_raised= No_startups_raised;
         this.No_startups_crossed= No_startups_crossed;
         this.survival_rate_startups= survival_rate_startups;
         this.Investment_agreements_signed= Investment_agreements_signed;
         this.No_startups_invested= No_startups_invested;
         this.allocated_incubatees= allocated_incubatees;
         this.external_sources= external_sources;
         this.No_mentors_actively= No_mentors_actively;
         this.allocated_startup_month= allocated_startup_month;
         this.No_patents_registered= No_patents_registered;
         this.No_patents_copyrights= No_patents_copyrights;
         this.No_trademarks_registered= No_trademarks_registered;
         this.Corporate_connects_facilitated= Corporate_connects_facilitated;
         this.Events_stakeholder_engagements= Events_stakeholder_engagements;
         this.Participation_Event= Participation_Event;
         this.Name_Background_current= Name_Background_current;
        //  this.No_full_time_employees= No_full_time_employees;
         this.Fund_Utilization_Details= Fund_Utilization_Details;

        // this.mteam_name = mteam_name;
        //  this.checkbox_composition_incubator = checkbox_composition_incubator;
        // this.checkbox_incubation_support = checkbox_incubation_support;
        // this.checkbox_mentorship_provided = checkbox_mentorship_provided;
        // this.checkbox_events_organized = checkbox_events_organized;
        // this.Re_Apply_relavant_excel = Re_Apply_relavant_excel;

        this.Re_apply_pitchdeck_upload = Re_apply_pitchdeck_upload;
        this.Re_apply_pitchdeck_upload_get = Re_apply_pitchdeck_upload_get;
        this.Re_apply_pitchdeck_upload_slice = Re_apply_pitchdeck_upload_slice;

        // this.relavent_document_pitchdeck = relavent_document_pitchdeck;

        this.Other_document_upload = Other_document_upload;
        this.Other_document_upload_get = Other_document_upload_get;
        this.Other_document_upload_slice = Other_document_upload_slice;

        this.Performance_Upload = Performance_Upload;
        this.Performance_Upload_get = Performance_Upload_get;
        this.Performance_Upload_slice = Performance_Upload_slice;

        this.fulltime_employees_re_apply = fulltime_employees_re_apply;

        this.team_data = team_data;
        // this.sum_disbured =sum_disbured
    }
  }


  export class TeamDetails {
    constructor(
      public team_id: number,
      public team_name: string,
      public no_employee: number
    ) {
      this.team_id = team_id;
      this.team_name = team_name;
      this.no_employee = no_employee;
    }
  }