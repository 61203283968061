
/* eslint-disable */

import { MeetingDetails } from "./EACReviewDetails";

export class IncubatorDetails {
  constructor(
    public incubator_application_no: number= 0,
    public id: number = 0,
    public user_id: any = 0,
    public registered_name: string = '',
    public address_state_id: number = 0,
    public address_city_id: number = 0,
    public address: string = '',
    public representative_name: string = '',
    public representative_designation: string = '',
    public representative_mobile_no: number = 0,
    public representative_email: string = '',
    public type_legal_entity_id: string = '',
    public registration_date: string = '',
    public corporate_id_no: number = 0,
    public operationalization_date: string = '',
    public seating_capacity_individulas: number = 0,
    public no_startups_incubation: number = 0,
    public fund_disburse_using_3rd_party: number = 0,
    public central_incubator_far_id: Array<string> = [],
    public nature_assistance_incubator: string = '',
    public ceo_firstname: string = '',
    public ceo_lastname: string = '',
    public ceo_description: string = '',
    public fulltime_employees_no: number = 0,
    public list_facilities: string = '',
    public startups_no_incubated: number = 0,
    public startups_no_graduated: number = 0,
    public startups_no_investments: number = 0,
    public startups_no_revenue_year: number = 0,
    public startups_survival_rate: number = 0,
    public investments_between_incubator_startups: number = 0,
    public startups_invested: number = 0,
    public startups_invested_applicable: number = 0,
    public startups_invested_total_corpus: number = 0,
    public startups_invested_total_corpus_applicable: number = 0,
    public total_investments_raised_incubatees: number = 0,
    public total_investments_raised_incubatees_applicable: number = 0,
    public mentors_no_engaged: number = 0,
    public mentors_no_engaged_applicable: number = 0,
    public mentors_average_hours: number = 0,
    public mentors_average_hours_applicable: number = 0,
    public mentors_no_of_ip: number = 0,
    public mentors_no_of_ip_applicable: number = 0,
    public corporate_facilitated: number = 0,
    public corporate_facilitated_applicable: number = 0,
    public event_engagements: number = 0,
    public event_engagements_applicable: number = 0,
    public participation_other_events: number = 0,
    public participation_other_events_applicable: number = 0,
    public quantum_fund_required: number = 0,
    public no_fund_benifitiaries_1yr: number = 0,
    public no_fund_benifitiaries_2yr: number = 0,
    public no_fund_benifitiaries_3yr: number = 0,
    public status_id: number = 0,
    public submitted_at: string = '',
    public first_submitted_date: string = '',
    public no_of_tranches: number = 0,
    public date_of_evaluation: number = 0,
    public total_approved_amt: number = 0,
    public total_management_amount: number = 0,
    public total_grant_disbursed: number = 0,
    public total_disbursed_amt: number = 0,
    public dpiit_comments: number = 0,
    public is_active: boolean = false,
    public created_by: string = '',
    public updated_by: string = '',
    public created_at: string = '',
    public updated_at: string = '',
    public application_status: string = '',
    public state: string = '',
    public city: string = '',
    public legal_entity: string = '',
    public central_incubator_far: string = '',
    public evaluationData: Array<EvaluationData>,
    public evaluation_avg_score: number = 0,
    public meeting_data : Array<MeetingData>,
    public otherApplications:Array<OtherApplications>,
    public applicationComments:Array<MeetingDetails>,
    public fund_disbursed:any = '',
   

  ) {
    this.id = id;
    this.user_id = user_id;
    this.registered_name = registered_name;
    this.address_state_id = address_state_id;
    this.address_city_id = address_city_id;
    this.address = address;
    this.representative_name = representative_name;
    this.representative_designation = representative_designation;
    this.representative_mobile_no = representative_mobile_no;
    this.representative_email = representative_email;
    this.type_legal_entity_id = type_legal_entity_id;
    this.registration_date = registration_date;
    this.corporate_id_no = corporate_id_no;
    this.operationalization_date = operationalization_date;
    this.seating_capacity_individulas = seating_capacity_individulas;
    this.no_startups_incubation = no_startups_incubation;
    this.fund_disburse_using_3rd_party = fund_disburse_using_3rd_party;
    this.central_incubator_far_id = central_incubator_far_id;
    this.nature_assistance_incubator = nature_assistance_incubator;
    this.ceo_firstname = ceo_firstname;
    this.ceo_lastname = ceo_lastname;
    this.ceo_description = ceo_description;
    this.fulltime_employees_no = fulltime_employees_no;
    this.list_facilities = list_facilities;
    this.startups_no_incubated = startups_no_incubated;
    this.startups_no_graduated = startups_no_graduated;
    this.startups_no_investments = startups_no_investments;
    this.startups_no_revenue_year = startups_no_revenue_year;
    this.startups_survival_rate = startups_survival_rate;
    this.investments_between_incubator_startups = investments_between_incubator_startups;
    this.startups_invested = startups_invested;
    this.startups_invested_applicable = startups_invested_applicable;
    this.startups_invested_total_corpus = startups_invested_total_corpus;
    this.startups_invested_total_corpus_applicable = startups_invested_total_corpus_applicable;
    this.total_investments_raised_incubatees = total_investments_raised_incubatees;
    this.total_investments_raised_incubatees_applicable = total_investments_raised_incubatees_applicable;
    this.mentors_no_engaged = mentors_no_engaged;
    this.mentors_no_engaged_applicable = mentors_no_engaged_applicable;
    this.mentors_average_hours = mentors_average_hours;
    this.mentors_average_hours_applicable = mentors_average_hours_applicable;
    this.mentors_no_of_ip = mentors_no_of_ip;
    this.mentors_no_of_ip_applicable = mentors_no_of_ip_applicable;
    this.corporate_facilitated = corporate_facilitated;
    this.corporate_facilitated_applicable = corporate_facilitated_applicable;
    this.event_engagements = event_engagements;
    this.event_engagements_applicable = event_engagements_applicable;
    this.participation_other_events = participation_other_events;
    this.participation_other_events_applicable = participation_other_events_applicable;
    this.quantum_fund_required = quantum_fund_required;
    this.no_fund_benifitiaries_1yr = no_fund_benifitiaries_1yr;
    this.no_fund_benifitiaries_2yr = no_fund_benifitiaries_2yr;
    this.no_fund_benifitiaries_3yr = no_fund_benifitiaries_3yr;
    this.status_id = status_id;
    this.submitted_at = submitted_at;
    this.first_submitted_date = first_submitted_date;
    this.no_of_tranches = no_of_tranches;
    this.date_of_evaluation = date_of_evaluation;
    this.total_approved_amt = total_approved_amt;
    this.total_disbursed_amt = total_disbursed_amt;
    this.dpiit_comments = dpiit_comments;
    this.is_active = is_active;
    this.created_by = created_by;
    this.updated_by = updated_by;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.application_status = application_status;
    this.state = state;
    this.city = city;
    this.legal_entity = legal_entity;
    this.central_incubator_far = central_incubator_far;
    this.evaluationData = evaluationData;
    this.evaluation_avg_score = evaluation_avg_score;
    this.meeting_data = meeting_data;
    this.otherApplications=otherApplications;
    this.applicationComments=applicationComments;
    this.fund_disbursed = fund_disbursed;
    // this.incubator_application_no=incubator_application_no;
  }
}
export class IncubatorDetails1 {
  constructor(
    // public incubator_application_no: number= 0,
    // public id: number = 0,
    // public user_id: any = 0,
    // public registered_name: string = '',
    // public address_state_id: number = 0,
    // public address_city_id: number = 0,
    // public address: string = '',
    // public representative_name: string = '',
    // public representative_designation: string = '',
    // public representative_mobile_no: number = 0,
    // public representative_email: string = '',
    // public type_legal_entity_id: string = '',
    // public registration_date: string = '',
    // public corporate_id_no: number = 0,
    // public operationalization_date: string = '',
    // public seating_capacity_individulas: number = 0,
    // public no_startups_incubation: number = 0,
    // public fund_disburse_using_3rd_party: number = 0,
    // public central_incubator_far_id: Array<string> = [],
    // public nature_assistance_incubator: string = '',
    // public ceo_firstname: string = '',
    // public ceo_lastname: string = '',
    // public ceo_description: string = '',
    // public fulltime_employees_no: number = 0,
    // public list_facilities: string = '',
    // public startups_no_incubated: number = 0,
    // public startups_no_graduated: number = 0,
    // public startups_no_investments: number = 0,
    // public startups_no_revenue_year: number = 0,
    // public startups_survival_rate: number = 0,
    // public investments_between_incubator_startups: number = 0,
    // public startups_invested: number = 0,
    // public startups_invested_applicable: number = 0,
    // public startups_invested_total_corpus: number = 0,
    // public startups_invested_total_corpus_applicable: number = 0,
    // public total_investments_raised_incubatees: number = 0,
    // public total_investments_raised_incubatees_applicable: number = 0,
    // public mentors_no_engaged: number = 0,
    // public mentors_no_engaged_applicable: number = 0,
    // public mentors_average_hours: number = 0,
    // public mentors_average_hours_applicable: number = 0,
    // public mentors_no_of_ip: number = 0,
    // public mentors_no_of_ip_applicable: number = 0,
    // public corporate_facilitated: number = 0,
    // public corporate_facilitated_applicable: number = 0,
    // public event_engagements: number = 0,
    // public event_engagements_applicable: number = 0,
    // public participation_other_events: number = 0,
    // public participation_other_events_applicable: number = 0,
    // public quantum_fund_required: number = 0,
    // public no_fund_benifitiaries_1yr: number = 0,
    // public no_fund_benifitiaries_2yr: number = 0,
    // public no_fund_benifitiaries_3yr: number = 0,
    // public status_id: number = 0,
    // public submitted_at: string = '',
    // public first_submitted_date: string = '',
    // public no_of_tranches: number = 0,
    // public date_of_evaluation: number = 0,
    // public total_approved_amt: number = 0,
    // public total_management_amount: number = 0,
    // public total_grant_disbursed: number = 0,
    // public total_disbursed_amt: number = 0,
    // public dpiit_comments: number = 0,
    // public is_active: boolean = false,
    // public created_by: string = '',
    // public updated_by: string = '',
    // public created_at: string = '',
    // public updated_at: string = '',
    // public application_status: string = '',
    // public state: string = '',
    // public city: string = '',
    // public legal_entity: string = '',
    // public central_incubator_far: string = '',
    // public evaluationData: Array<EvaluationData>,
    // public evaluation_avg_score: number = 0,
    // public meeting_data : Array<MeetingData>,
    // public otherApplications:Array<OtherApplications>,
    // public applicationComments:Array<MeetingDetails>,
    // public fund_disbursed:any = '',
   public firstApplication:any,
   public secondApplication:any,
   public totalAmount:any,
   public total_approved_amt:any,
   public total_management_amount:any,
   public no_of_tranches:any,
   public total_disbursed_amt:any,
   public Amount_Disbursed_to_Startups:any,
   public Available_Balance_Incubator:any,
   public Approved_return:any,
  ) {
    // this.id = id;
    // this.user_id = user_id;
    // this.registered_name = registered_name;
    // this.address_state_id = address_state_id;
    // this.address_city_id = address_city_id;
    // this.address = address;
    // this.representative_name = representative_name;
    // this.representative_designation = representative_designation;
    // this.representative_mobile_no = representative_mobile_no;
    // this.representative_email = representative_email;
    // this.type_legal_entity_id = type_legal_entity_id;
    // this.registration_date = registration_date;
    // this.corporate_id_no = corporate_id_no;
    // this.operationalization_date = operationalization_date;
    // this.seating_capacity_individulas = seating_capacity_individulas;
    // this.no_startups_incubation = no_startups_incubation;
    // this.fund_disburse_using_3rd_party = fund_disburse_using_3rd_party;
    // this.central_incubator_far_id = central_incubator_far_id;
    // this.nature_assistance_incubator = nature_assistance_incubator;
    // this.ceo_firstname = ceo_firstname;
    // this.ceo_lastname = ceo_lastname;
    // this.ceo_description = ceo_description;
    // this.fulltime_employees_no = fulltime_employees_no;
    // this.list_facilities = list_facilities;
    // this.startups_no_incubated = startups_no_incubated;
    // this.startups_no_graduated = startups_no_graduated;
    // this.startups_no_investments = startups_no_investments;
    // this.startups_no_revenue_year = startups_no_revenue_year;
    // this.startups_survival_rate = startups_survival_rate;
    // this.investments_between_incubator_startups = investments_between_incubator_startups;
    // this.startups_invested = startups_invested;
    // this.startups_invested_applicable = startups_invested_applicable;
    // this.startups_invested_total_corpus = startups_invested_total_corpus;
    // this.startups_invested_total_corpus_applicable = startups_invested_total_corpus_applicable;
    // this.total_investments_raised_incubatees = total_investments_raised_incubatees;
    // this.total_investments_raised_incubatees_applicable = total_investments_raised_incubatees_applicable;
    // this.mentors_no_engaged = mentors_no_engaged;
    // this.mentors_no_engaged_applicable = mentors_no_engaged_applicable;
    // this.mentors_average_hours = mentors_average_hours;
    // this.mentors_average_hours_applicable = mentors_average_hours_applicable;
    // this.mentors_no_of_ip = mentors_no_of_ip;
    // this.mentors_no_of_ip_applicable = mentors_no_of_ip_applicable;
    // this.corporate_facilitated = corporate_facilitated;
    // this.corporate_facilitated_applicable = corporate_facilitated_applicable;
    // this.event_engagements = event_engagements;
    // this.event_engagements_applicable = event_engagements_applicable;
    // this.participation_other_events = participation_other_events;
    // this.participation_other_events_applicable = participation_other_events_applicable;
    // this.quantum_fund_required = quantum_fund_required;
    // this.no_fund_benifitiaries_1yr = no_fund_benifitiaries_1yr;
    // this.no_fund_benifitiaries_2yr = no_fund_benifitiaries_2yr;
    // this.no_fund_benifitiaries_3yr = no_fund_benifitiaries_3yr;
    // this.status_id = status_id;
    // this.submitted_at = submitted_at;
    // this.first_submitted_date = first_submitted_date;
    // this.no_of_tranches = no_of_tranches;
    // this.date_of_evaluation = date_of_evaluation;
    // this.total_approved_amt = total_approved_amt;
    // this.total_disbursed_amt = total_disbursed_amt;
    // this.dpiit_comments = dpiit_comments;
    // this.is_active = is_active;
    // this.created_by = created_by;
    // this.updated_by = updated_by;
    // this.created_at = created_at;
    // this.updated_at = updated_at;
    // this.application_status = application_status;
    // this.state = state;
    // this.city = city;
    // this.legal_entity = legal_entity;
    // this.central_incubator_far = central_incubator_far;
    // this.evaluationData = evaluationData;
    // this.evaluation_avg_score = evaluation_avg_score;
    // this.meeting_data = meeting_data;
    // this.otherApplications=otherApplications;
    // this.applicationComments=applicationComments;
    // this.fund_disbursed = fund_disbursed;
    // this.incubator_application_no=incubator_application_no;
    this.firstApplication = firstApplication,
    this.secondApplication = secondApplication,
    this.totalAmount = totalAmount
    this.total_approved_amt=total_approved_amt,
    this.total_management_amount=total_management_amount,
    this.no_of_tranches = no_of_tranches,
    this.total_disbursed_amt =total_disbursed_amt
    this.Amount_Disbursed_to_Startups = Amount_Disbursed_to_Startups
    this.Available_Balance_Incubator = Available_Balance_Incubator
    this.Approved_return = Approved_return

  }
}
export class EvaluationData {
  constructor(
    public eac_score: number,
    public name: string,
    public eac_id: number,
    public incubator_id: number,
    public name_initials:string
  ) {
    this.eac_score = eac_score;
    this.name = name;
    this.eac_id = eac_id;
    this.incubator_id = incubator_id;
    this.name_initials=name_initials;
  }
}


export class MeetingData {
  constructor(
    public meeting_id: number,
    public meeting_type: number,
    public meeting_date: string,
    public meeting_from_time: string,
    public meeting_to_time:string,
    public comment:string
  ) {
    this.meeting_id = meeting_id;
    this.meeting_type = meeting_type;
    this.meeting_date = meeting_date;
    this.meeting_from_time = meeting_from_time;
    this.meeting_to_time=meeting_to_time;
    this.comment=comment;
  }
}

export class OtherApplications {
  constructor(
    public application_id: number,
    public status_id: number,
    public registered_name: string,
    public application_status: string,

  ) {
    this.application_id = application_id;
    this.status_id = status_id;
    this.registered_name = registered_name;
    this.application_status = application_status;
    
  }
}

