import { FileUploadModel } from "../FileUploadModel";

export class IncubatorDetailsModel {
    constructor(
        public list_facilities: string = "",
        public startups_no_incubated: number,
        public startups_no_graduated: number,
        public startups_no_investments: number,
        public startups_no_revenue_year: number,
        public startups_survival_rate: number,
        public investments_between_incubator_startups: number,
        public startups_invested: number,
        public startups_invested_applicable: boolean,
        public startups_invested_total_corpus: number,
        public startups_invested_total_corpus_applicable: boolean,
        public total_investments_raised_incubatees: number,
        public total_investments_raised_incubatees_applicable: boolean,
        public mentors_no_engaged: number,
        public mentors_no_engaged_applicable: boolean,
        public mentors_average_hours: number,
        public mentors_average_hours_applicable: boolean,
        public mentors_no_of_ip: number,
        public mentors_no_of_ip_applicable: boolean,
        public corporate_facilitated: number,
        public corporate_facilitated_applicable: boolean,
        public event_engagements: number,
        public event_engagements_applicable: boolean,
        public participation_other_events: number,
        public participation_other_events_applicable: boolean,
        public no_of_patents: number,
        public no_of_patents_applicable: boolean,
        public no_of_copyrights: number,
        public no_of_copyrights_applicable: boolean,
        public no_of_trademarks: number,
        public no_of_trademarks_applicable: boolean,
        public facilities_pictures: Array<FileUploadModel>,
    ) {
        this.list_facilities = list_facilities;
        this.startups_no_incubated = startups_no_incubated;
        this.startups_no_graduated = startups_no_graduated;
        this.startups_no_investments = startups_no_investments;
        this.startups_no_revenue_year = startups_no_revenue_year;
        this.startups_survival_rate = startups_survival_rate;
        this.investments_between_incubator_startups = investments_between_incubator_startups;
        this.startups_invested = startups_invested;
        this.startups_invested_applicable = startups_invested_applicable;
        this.startups_invested_total_corpus = startups_invested_total_corpus;
        this.startups_invested_total_corpus_applicable = startups_invested_total_corpus_applicable;
        this.total_investments_raised_incubatees = total_investments_raised_incubatees;
        this.total_investments_raised_incubatees_applicable = total_investments_raised_incubatees_applicable;
        this.mentors_no_engaged = mentors_no_engaged;
        this.mentors_no_engaged_applicable = mentors_no_engaged_applicable;
        this.mentors_average_hours = mentors_average_hours;
        this.mentors_average_hours_applicable = mentors_average_hours_applicable;
        this.mentors_no_of_ip = mentors_no_of_ip;
        this.mentors_no_of_ip_applicable = mentors_no_of_ip_applicable;
        this.corporate_facilitated = corporate_facilitated;
        this.corporate_facilitated_applicable = corporate_facilitated_applicable;
        this.event_engagements = event_engagements;
        this.event_engagements_applicable = event_engagements_applicable;
        this.participation_other_events = participation_other_events;
        this.participation_other_events_applicable = participation_other_events_applicable;
        this.no_of_patents = no_of_patents;
        this.no_of_patents_applicable = no_of_patents_applicable;
        this.no_of_copyrights = no_of_copyrights;
        this.no_of_copyrights_applicable = no_of_copyrights_applicable;
        this.no_of_trademarks = no_of_trademarks;
        this.no_of_trademarks_applicable = no_of_trademarks_applicable;
        this.facilities_pictures = facilities_pictures;
    }
}