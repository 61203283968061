import FileUploader from '@amodv/react-file-uploader';
import React, { useEffect, useState } from 'react';
import { CANCEL_DATE_UPDATE, CANCEL_DATE_UPDATE_INCUBATOR, CANCEL_STATUS_UPDATE, CANCEL_STATUS_UPDATE_StARTUP, NODE_URL, UPDATE_FILE_CANCELLATION, UPDATE_FILE_CANCELLATION_INCUBATOR } from '../../../constants/api';
import { post } from '../../../effects/api/api.common';
import { changeNumberFormat, downloadDocument } from '../../../utils/CommonMethods';
import { FormatDate } from '../../../utils/DateFormat';
import Calendar from '../../common/controls/Calendar';
import ConfirmationDialog from '../../common/controls/ConfirmationDialog';
import '../incubator/incubator.css';
import { ApplicationGeneralDetails } from './ApplicationGeneralDetails';
import { getSessionStorageValue } from '../../../effects/utils/session-storage';
import { userInfo } from 'os';
declare var $: any;
interface IContactProps {
    data: Array<any>;
    role: string;
    isEdit: boolean
}
interface IIKeyValueIndexer {
    [key: string]: boolean;
}


function CancellationContainer(props: IContactProps) {

    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)

    const { data, role, isEdit } = props;
    const [isDisabled, setDisabled] = useState(false);
    const [quickEditCancellation, setquickEditCancellation] = useState({
        id: "",
        comment: "",
        status_id: "",
        "user_id": userInfo.id,
        "roles": userInfo.roles,
        "startup_application_id" : ""
    }
    );

  
    const [showIncubatorSuccessModal, setShowIncubatorSuccessModal] = useState(false);
    const [showIncubatorFailureModal, setShowIncubatorFailureModal] = useState(false);
    // const [quickEditCancel, setquickEditCancelModal] = useState(false);

    const [uploadFile, setUploadFile] = useState<Array<File>>([]);
    const [openUploadBlock, setOpenUploadBlock] = useState<IIKeyValueIndexer>();
    const [failDialogData, setFailDialogData] = useState({
        "header": '',
        "text": ''
    });
    const [dialogData, setDialogData] = useState({
        "header": '',
        "text": ''
    });

    // useEffect(() => {
    //     if (role == "incubator") {
    //         setquickEditCancellation(prevState => ({
    //             ...prevState,
    //             comment: data[0].incubatorComment
    //         }))
    //     }
    // }, []);
    //console.log(generalDetailsModel, 'IContactProps');
    // on change time;
    const updateCancellationStatus = () => {

        let object = Object.assign({ "user_id": userInfo.id, "roles": userInfo.roles }, quickEditCancellation)

        setDisabled(true);
        if (role == "dpiit") {
            post(CANCEL_STATUS_UPDATE, object).then((response: any) => {
                if (!response || !response.data) return;
                setDisabled(false)
                if (response.data.result == "success") {
                    setDialogData({
                        "header": "Thank You!",
                        "text": response.data.message
                    })
                    $('#confirmationPopup').modal('hide');
                    setShowIncubatorSuccessModal(true)
                }
                else {
                    setFailDialogData({
                        "header": "Oops !!",
                        "text": response.data.message
                    })
                    setShowIncubatorFailureModal(true);
                }
            })
        }
        else if (role == "incubator") {
            const user: any = getSessionStorageValue("user");
            const userInfo = JSON.parse(user)

            var payload = {
                "id": quickEditCancellation.id,
                "status_id": quickEditCancellation.status_id,
                "comment": quickEditCancellation.comment,
                "roles": userInfo.roles,
                "user_id": userInfo.id,
                "startup_application_id": quickEditCancellation.startup_application_id
            }
            post(CANCEL_STATUS_UPDATE_StARTUP, payload).then((response: any) => {
                if (!response || !response.data) return;
                setDisabled(false)
                if (response.data.result == "success") {
                    setDialogData({
                        "header": "Thank You!",
                        "text": response.data.message
                    })
                    $('#confirmationPopup').modal('hide');
                    setShowIncubatorSuccessModal(true)
                }
                else {
                    setFailDialogData({
                        "header": "Oops !!",
                        "text": response.data.message
                    })
                    setShowIncubatorFailureModal(true);
                }
            })
        }
    };

    const updateDateCall = () => {
        setDisabled(true);

        let Payload = Object.assign({ "roles": userInfo.roles, "user_id": userInfo.id }, dateUpdate)

        if (role == "dpiit") {
            post(CANCEL_DATE_UPDATE, Payload).then((response: any) => {
                if (!response || !response.data) return;
                setDisabled(false)
                if (response.data.result == "success") {
                    setDialogData({
                        "header": "Thank You!",
                        "text": response.data.message
                    })
                    setShowIncubatorSuccessModal(true)
                }
                else {
                    setFailDialogData({
                        "header": "Oops !!",
                        "text": response.data.message
                    })
                    setShowIncubatorFailureModal(true);
                }
            })
        }
        else if (role == "incubator") {

            let Payload = Object.assign({ "user_role": userInfo.roles, "logged_user_id": userInfo.id }, dateUpdate)

            post(CANCEL_DATE_UPDATE_INCUBATOR, Payload).then((response: any) => {
                if (!response || !response.data) return;
                setDisabled(false)
                if (response.data.result == "success") {
                    setDialogData({
                        "header": "Thank You!",
                        "text": response.data.message
                    })
                    setShowIncubatorSuccessModal(true)
                }
                else {
                    setFailDialogData({
                        "header": "Oops !!",
                        "text": response.data.message
                    })
                    setShowIncubatorFailureModal(true);
                }
            })
        }
    };

    const onFileUploadComplete = (files: Array<File>, type: string) => {
        // console.log("testing", files)
        let indexes: Array<string> = [];
        for (const file of files) {



            setUploadFile([...uploadFile, file]);


        }

        if (indexes.length) {
            indexes.map((ind) => {
                const position: number = files.findIndex((file) => file.name === ind);
                files.splice(position, 1);
            });
        }
    };

    const onFileDelete = (type: string) => {
        setUploadFile([]);
    };

    // function to format date
    function formatDate(date: Date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const uploadFileCall = () => {
        const formData = new FormData();
        formData.append("id", data[0].id);
        formData.append('logged_user_id', userInfo.id);
        formData.append('user_role', userInfo.roles);
        uploadFile.length != 0 && formData.append("file", uploadFile[0]);
        if (role == "dpiit") {
            post(UPDATE_FILE_CANCELLATION, formData).then((response: any) => {
                if (!response || !response.data) return;
                setDisabled(false)
                if (response.data.result == "success") {
                    setDialogData({
                        "header": "Thank You!",
                        "text": response.data.message
                    })
                    setShowIncubatorSuccessModal(true)
                }
                else {
                    setFailDialogData({
                        "header": "Oops !!",
                        "text": response.data.message
                    })
                    setShowIncubatorFailureModal(true);
                }
            })
        }
        else if (role == "incubator") {
            post(UPDATE_FILE_CANCELLATION_INCUBATOR, formData).then((response: any) => {
                if (!response || !response.data) return;
                setDisabled(false)
                if (response.data.result == "success") {
                    setDialogData({
                        "header": "Thank You!",
                        "text": response.data.message
                    })
                    setShowIncubatorSuccessModal(true)
                }
                else {
                    setFailDialogData({
                        "header": "Oops !!",
                        "text": response.data.message
                    })
                    setShowIncubatorFailureModal(true);
                }
            })
        }
    };

    const onFileUpdate = (e: any) => {
        const fileData = e.target.files[0];
        let formData = new FormData();
        formData.append('file', fileData);
        formData.append("id", data[0].id);
        formData.append('logged_user_id', userInfo.id);
        formData.append('user_role', userInfo.roles);
        if (role == "dpiit") {
            post(UPDATE_FILE_CANCELLATION, formData).then((response: any) => {
                if (!response || !response.data) return;
                setDisabled(false)
                if (response.data.result == "success") {
                    setDialogData({
                        "header": "Thank You!",
                        "text": response.data.message
                    })
                    setShowIncubatorSuccessModal(true)
                }
                else {
                    setFailDialogData({
                        "header": "Oops !!",
                        "text": response.data.message
                    })
                    setShowIncubatorFailureModal(true);
                }
            })
        }
        else if (role == "incubator") {
            post(UPDATE_FILE_CANCELLATION_INCUBATOR, formData).then((response: any) => {
                if (!response || !response.data) return;
                setDisabled(false)
                if (response.data.result == "success") {
                    setDialogData({
                        "header": "Thank You!",
                        "text": response.data.message
                    })
                    setShowIncubatorSuccessModal(true)
                }
                else {
                    setFailDialogData({
                        "header": "Oops !!",
                        "text": response.data.message
                    })
                    setShowIncubatorFailureModal(true);
                }
            })
        }
    }


    const quickeditcancelpopup  = (item: any) => {
    console.log("quickeditcancelpopup",item.item)
    $('#quickEditCancel').modal('show');
    setquickEditCancellation(item.item)
    }
    interface ItemData {
        id: number;
        fund_received_date: string; // Assuming fund_received_date is of type string
        // Add other properties if needed
    }
    const [fetchitemData, setfetchitemData] = useState<ItemData | null>(null); // Provide the type for fetchitemData
    const [dateUpdate, setDateUpdate] = useState({
        id: null as number | null,
        date: null as string | null,
    });
    const dateUpdatepopup  = (item: any) => {
        console.log("dateUpdatepopup",item.item)
        setfetchitemData(item.item);
        $('#dateUpdate').modal('show');
        }
        useEffect(() => {
            if (fetchitemData) {
                setDateUpdate({
                    id: fetchitemData.id,
                    date: fetchitemData.fund_received_date,
                });
                console.log("fetchitemData", fetchitemData);
            }
        }, [fetchitemData]);

    return (
        <>
            {data != undefined && data.length > 0
                ? data.map((item: any) => (
                    <div className="accordion accordion-details-wrap text-left" id="accordion-application-details">
                        <div className="card accordion-card">
                            <div className="card-body accordion-card-body">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="startup-info-group">
                                            <div className="startup-info-group-label">
                                                Cancellation Requested Date
                                            </div>
                                            <div className="startup-info-group-value">
                                                {FormatDate(new Date(item.request_date))}
                                            </div>
                                        </div>

                                        {/* <div className="startup-info-group">
                                            <div className="startup-info-group-label">
                                                Status of Cancellation {isEdit && <a
                                                    className="incubator-details-link ml-2"
                                                    onClick={() => quickeditcancelpopup({item})}
                                                    data-toggle="modal" 
                                                >
                                                    {item.status_id == 2 || item.status_id == 3 ? <></> : <>Update</>}

                                                </a>}
                                            </div>
                                            <div className="startup-info-group-value">
                                                {item.status_id == 1 && "Requested"}
                                                {item.status_id == 2 && "Approved"}
                                                {item.status_id == 3 && "Rejected"}

                                            </div>
                                        </div> */}
                                        <div className="startup-info-group">
                                            <div className="startup-info-group-label">
                                                Reason for Requesting Cancellation
                                            </div>
                                            <div className="startup-info-group-value">
                                            {(item.reason !== "" && item.reason !== "null" && item.reason !== null) ? item.reason : "-"}
                                            </div>
                                            {/* {item.reason} */}
                                        </div>
                                        <div className="startup-info-group">
                                            <div className="startup-info-group-label">
                                            Comments / Feedback by an Incubator
                                            </div>
                                            {role == "dpiit" ? <div className="startup-info-group-value">
                                        {item.dpiitComment != "" ? item.dpiitComment : "-"}
                                    </div> : <div className="startup-info-group-value">
                                        {item.incubatorComment != "" ? item.incubatorComment : "-"}
                                    </div>}
                                        </div> 
                                    </div>

                                    <div className="col-lg-6">
                                        {/* <div className="startup-info-group">
                                            <div className="startup-info-group-label">
                                                Amount to be Refunded
                                            </div>
                                            <div className="startup-info-group-value">
                                                &#8377; {changeNumberFormat(Number(item.amount_refunded), 1, 0)}
                                            </div>
                                        </div> */}
                                        <div className="startup-info-group">
                                            <div className="startup-info-group-label">
                                                Status of Cancellation {isEdit && <a
                                                    className="incubator-details-link ml-2"
                                                    onClick={() => quickeditcancelpopup({item})}
                                                    data-toggle="modal" 
                                                >
                                                    <div className="startup-info-group-value">
                                                    {item.status_id == 2 || item.status_id == 3 ? <></> : <>Update</>}
                                                    </div>
                                                </a>}
                                            </div>
                                            <div className="startup-info-group-value"> 
                                                {item.status_id == 1 && "Cancellation Requested"}
                                                {item.status_id == 2 && "Cancellation Request Approved"}
                                                {item.status_id == 3 && "Cancellation Request Rejected"}
                                            </div>
                                        </div>
                                        <div className="startup-info-group">
                                            <div className="startup-info-group-label">
                                            Cancellation Approval / Rejection Date
                                            </div>
                                            <div className="startup-info-group-value">
                                            {item.Approve_reject_date !== "-" ? FormatDate(new Date(item.Approve_reject_date)): "-"}
                                            </div>
                                        </div>
                                        <div className="startup-info-group">
                                            <div className="startup-info-group-label">
                                            Cancellation Approval / Rejection File by the Secretariat
                                            </div>
                                            <div className="startup-info-group-value">
                                            {item.cancellation_status_file_path !== null ? <div className="startup-info-group-value">
                                                <div className="document-info document-pdf-icon">
                                                    <div className="document-info-name">{item.cancellation_status_file_path.substr(item.cancellation_status_file_path.lastIndexOf("/") + 1, item.cancellation_status_file_path.length)}</div>
                                                    <a className="tab-pane-link tab-pane-link-view link-orange-text" href={`${NODE_URL}` + item.cancellation_status_file_path} target='_blank'>View  </a>
                                                </div>
                                            </div> : "-"}
                                            </div>
                                        </div>
                                        {/* <div className="startup-info-group">
                                            <div className="startup-info-group-label">
                                                Interest Accumulated on Funds
                                            </div>
                                            <div className="startup-info-group-value">
                                                &#8377; {changeNumberFormat(Number(item.interest_funds), 1, 0)}
                                            </div>
                                        </div> */}
                                    </div>
                                    {/* <div className="col-lg-6"> 
                                        <div className="startup-info-group">
                                            <div className="startup-info-group-label">
                                                Audited Report For Bank Statement
                                            </div>
                                            <div className="startup-info-group-value">
                                                {item.file_path != null ?
                                                    <div className="document-info document-pdf-icon">
                                                        <div className="document-info-name">{item.file_path.substr(item.file_path.lastIndexOf("/") + 1, item.file_path.length)}</div>
                                                        <a className="tab-pane-link tab-pane-link-view link-orange-text" href={item.file_path} target='_blank'>View   </a>
                                                    </div>
                                                    : " - "
                                                }
                                            </div>
                                        </div>
                                        <div className="startup-info-group">
                                            <div className="startup-info-group-label">
                                                Supporting Documents
                                            </div>
                                            {item.file_path_other !== null ? <div className="startup-info-group-value">
                                                <div className="document-info document-pdf-icon">
                                                    <div className="document-info-name">{item.file_path_other.substr(item.file_path_other.lastIndexOf("/") + 1, item.file_path_other.length)}</div>
                                                    <a className="tab-pane-link tab-pane-link-view link-orange-text" href={item.file_path_other} target='_blank'>View  </a>
                                                </div>
                                            </div> : "-"}
                                        </div>



                                    </div> */}
                                    {/* <div className="col-lg-6">
                                        <div className="startup-info-group">
                                            <div className="startup-info-group-label">
                                                Date of Funds Received
                                            </div>
                                            <div className="startup-info-group-value">
                                                {item.fund_received_date != null ? FormatDate(new Date(item.fund_received_date)) : "-"}{isEdit && <a
                                                    className="incubator-details-link ml-2"
                                                    onClick={() => dateUpdatepopup({item})}
                                                    data-toggle="modal"
                                                >
                                                    Update
                                                </a>}
                                            </div>
                                        </div>
                                        <div className="startup-info-group">
                                            <div className="startup-info-group-label">
                                                {role == "dpiit" ? "Proof of Funds received by DPIIT" : "Proof of Funds received by Incubator"}
                                            </div>
                                            <div className="startup-info-group-value">
                                                {(item.proof_fund_path == null && isEdit) ? <div className="">
                                                    <button
                                                        className="btn btn-sisfs btn-transparent-primary"
                                                        onClick={() => setOpenUploadBlock({ BankStatementUpload: true })}
                                                        disabled={false}
                                                    >
                                                        Upload
                                                    </button>
                                                    {(openUploadBlock && openUploadBlock["BankStatementUpload"]) && (
                                                        <>
                                                            <span
                                                                className="close"
                                                                onClick={() => setOpenUploadBlock({ BankStatementUpload: false })}
                                                            >
                                                                <i className="fa fa-close"></i>
                                                            </span>
                                                            <FileUploader
                                                                key={"bank_statement_upload"}
                                                                id={"bank_statement_upload"}
                                                                information="Drag & Drop the file or Browse"
                                                                fileType="application/pdf"
                                                                buttonLabel="Browse"
                                                                multiple={false}
                                                                enablePreview
                                                                IconComponent={() => (
                                                                    <span className="fa fa-cloud-upload"></span>
                                                                )}
                                                                removeIconClass=""
                                                                existingFiles={uploadFile}
                                                                onUploadFinish={(files: Array<File>) => {
                                                                    onFileUploadComplete(files, "file");
                                                                }}
                                                                onFileDelete={(deletedFile: File) => {
                                                                    onFileDelete("file")
                                                                }}
                                                            />
                                                            <small>Supported file format - PDF only</small>
                                                            <button
                                                                className="btn btn-sisfs btn-transparent-primary"
                                                                onClick={uploadFileCall}
                                                            >
                                                                Upload File
                                                            </button>

                                                        </>
                                                    )}

                                                </div> : <div className="startup-info-group-value">
                                                    <div className="document-info document-pdf-icon">
                                                        <div className="document-info-name">{item.proof_fund_path != null && item.proof_fund_path.substr(item.proof_fund_path.lastIndexOf("/") + 1, item.proof_fund_path.length)}</div>
                                                        <a className="tab-pane-link tab-pane-link-view link-orange-text" href={item.proof_fund_path} target='_blank'>View </a>
                                                        {isEdit && <label htmlFor={"inputUpload_" + 1} className="tab-pane-link tab-pane-link-view link-orange-text"><input id={"inputUpload_" + 1} className="d-none" type="file" onChange={(e) =>
                                                            onFileUpdate(e)
                                                        } />| Upload</label>}
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>
                                        <div className="startup-info-group">
                                            <div className="startup-info-group-label">
                                                {role == "dpiit" ? "Proof of Disbursement of Incubator" : "Proof of Disbursement of Startup"}
                                            </div>
                                            <div className="startup-info-group-value">
                                                {item.proof_disbursement_path != null ? <div className="document-info document-pdf-icon">
                                                    <div className="document-info-name">{item.proof_disbursement_path.substr(item.proof_disbursement_path.lastIndexOf("/") + 1, item.proof_disbursement_path.length)}</div>
                                                    <a className="tab-pane-link tab-pane-link-view link-orange-text" href={item.proof_disbursement_path} target='_blank'>View  </a>
                                                </div> : "-"}
                                            </div>

                                        </div>

                                    </div> */}
                                </div>
                              

                            </div>
                        </div>
                    </div>
                ))
                : ''}
            <div
                className="modal align-middle modal-sisfs fade"
                id="dateUpdate"
                role="dialog"
                aria-labelledby="exampleModalLabel1"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-md">

                    <div className="modal-content">
                        <div className="modal-header modal-header-bottom">
                            <h4 className="pt-2 pl-4">Edit Date of Fund Received</h4>
                            <button
                                type="button"
                                className="close pr-0"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body text-center">



                            <Calendar
                                id="registrationDate"
                                name="registrationDate"
                                className="form-control input-height"
                                format="dd/MM/yyyy"
                                selectedDate={dateUpdate.date != null ? new Date(dateUpdate.date) : new Date()}
                                onDateChange={(date: Date, field: string) => {
                                    setDateUpdate(prevState => ({
                                        ...prevState,
                                        date: formatDate(date)
                                    }))
                                }}
                                minDate={new Date()}
                            />

                            {/* <div className="modal-action mt-2">
                                        <button className="btn btn-sisfs btn-transparent-primary" onClick={() => setShowEditStatus(false)}>
                                            Cancel
                               </button>
                                        <button className="btn btn-sisfs btn-yellow-primary ml-2" onClick={() => changeStatus()}>
                                            Save
                               </button>
                                    </div> */}
                        </div>
                        <div className="modal-footer">
                            <div className="row modal-row">
                                <div className="col-md-12 text-right">
                                    <button className="btn btn-sisfs btn-transparent-primary" data-dismiss="modal" >
                                        Cancel
                                    </button>
                                    <button className="btn btn-sisfs btn-yellow-primary ml-2" onClick={updateDateCall} >
                                        Save
                                    </button>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* quick edit data for cancellation */}
            <div
                className="modal fade"
                id="quickEditCancel"
                role="dialog"
                aria-labelledby="exampleModalLabel1"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-slideout modal-md"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header modal-header-border-none modal-header-bottom pr-5">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"

                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body text-left pl-5 pr-5 pt-5">
                            <div className="popup-head mb-5 ml-2">
                                <div className="main-heading">
                                    {/* {quickEditPopupData.incubator_name} */}
                                </div>
                                <div className="count-text">

                                </div>
                            </div>

                            <label className="col-sm-8 col-form-head">
                                Cancellation Status
                            </label>
                            <div className="row pl-3">
                                <div className="col-md-11 mb-5">
                                    <div className="select-custom">
                                        <select
                                            className="form-control input-height"
                                            aria-label="Select State"
                                            id="application_status"
                                            name="application_status"
                                            value={quickEditCancellation.status_id}
                                            onChange={(e: any) => 
                                                // console.log(e.target.value)
                                                setquickEditCancellation(prevState => ({
                                                ...prevState,
                                                status_id: e.target.value
                                            }))
                                        }
                                        >

                                            <option >Please Select</option>
                                            <option value="2">Approve Cancellation Request </option>
                                            <option value="3">Reject Cancellation Request </option>

                                        </select>
                                    </div>
                                </div>

                            </div>

                            <label className="col-sm-8 col-form-head">Comments, if any</label>
                            <div className="col-md-12 mb-4">
                                <textarea
                                    value={quickEditCancellation.comment}
                                    className="form-control textarea-height"
                                    name="application_comments"
                                    onChange={(e: any) => setquickEditCancellation(prevState => ({
                                        ...prevState,
                                        comment: e.target.value
                                    }))}
                                />
                            </div>
                            <div className="row pl-3">
                                <div className="col-md-6">
                                    <button
                                        className="btn btn-sisfs btn-transparent-primary full-width"
                                        data-dismiss="modal"
                                    >
                                        CANCEL
                                    </button>
                                </div>
                                <div className="col-md-6">
                                    <button
                                        // onClick={updateCancellationStatus}
                                        data-toggle="modal" data-target="#confirmationPopup"
                                        className={isDisabled ? "tertiary  btn-yellow-primary full-width custom-disabled" : "tertiary  btn-yellow-primary full-width"} disabled={isDisabled}
                                    >
                                        UPDATE
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                <div className="modal fade" id="confirmationPopup" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal align-middle modal-sisfs d-block" id="FormSubmissionError">
                                <div className="modal-dialog modal-dialog-centered modal-md">
                                    <div className="modal-content">
                                        <div style={{ textAlign: 'right', }}>
                                            <span aria-hidden="true" data-dismiss="modal" style={{ cursor: 'pointer', fontSize: '45px', marginRight: '15px', marginTop: '15px', color: '#D3D3D3' }}>×</span>
                                            {/* <i className="fa fa-close" data-dismiss="modal" style={{ cursor: 'pointer', fontSize: '30px', marginRight: '15px', marginTop: '15px', color: '#D3D3D3' }}></i> */}
                                        </div>
                                        <div className="modal-body text-center pt-0">
                                            <h4 className="modal-message btn" style={{ color: '#2b3f88', }}>
                                                {/* message-orange */}
                                                Once you change the status, you are no longer available to change again</h4>
                                            <div className="modal-action" style={{ marginBottom: '0px' }}>
                                                <button className="btn btn-sisfs " style={{ marginLeft: '15px', color: '#2b3f88', border: "1px solid #2b3f88" }} data-dismiss="modal"  >
                                                    NO
                                                </button>
                                                <button className="btn btn-sisfs btn-yellow-primary" style={{ marginLeft: '15px' }} onClick={updateCancellationStatus} >
                                                    YES
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {showIncubatorSuccessModal && (
                <ConfirmationDialog
                    headerText={dialogData.header}
                    bodyText={dialogData.text}
                    onDialogClose={() => (setShowIncubatorSuccessModal(false), window.location.reload())}
                />
            )}
            {showIncubatorFailureModal && (
                <ConfirmationDialog
                    headerText={failDialogData.header}
                    bodyText={failDialogData.text}
                    onDialogClose={() => setShowIncubatorFailureModal(false)}
                    iconClass="test"
                />
            )}
        </>
    );
}
export default CancellationContainer;
