import { LoginModel } from "../models/LoginModel";
import UserInfoModel from "../models/UserInfoModel";
import { Action, IAction } from "./reducer.action"

export interface IUserIniitalState {
    user: UserInfoModel,
}

export const userInitialState: IUserIniitalState = {
    user: {
        email: '',
        name: '',
        accessToken: '',
        id:'',
        roles: '',
    }
}

const authReducer = (state: IUserIniitalState, action: IAction) => {
    switch (action.type) {
       case Action.SET_USER: 
            return { ...state, user: action.payload };
        
       default: return state;
    }
}

export default authReducer;