import React, { useEffect, useState } from "react";
import { GeneralDetailModel } from "../../../models/incubator/GeneralDetailModel";
import FileUploader from "@amodv/react-file-uploader";
import "@amodv/react-file-uploader/dist/index.css";
import {
  requestType,
  StepperEnum,
  UploadTypeEnum1,
} from "../../../utils/Enums";
import { useAuthStateValue } from "../../../providers/AuthStateProvider";
import {
  GeneralIncubatorModel,
  TeamDetails,
} from "../../../models/incubator/GeneralIncubatorModel";
import { FileUploadModel } from "../../../models/FileUploadModel";
import {
  EVALUATION_FILE,
  File_node_url,
  GET_SUM_DISBURSED_AMOUNT,
  NODE_URL,
  secgreybox,
} from "../../../constants/api";
import { useHistory } from "react-router-dom";
import {
  validateRequiredText,
  validateRequiredText1,
} from "../../../effects/utils/validations";
import { downloadDocument } from "../../../utils/CommonMethods";
import { GET_RE_APPLICATION_VIEW } from "../../../constants/api";
import IncrementDecrement from "../../common/controls/IncrementDecrement";
import DeleteRow from "../../../images/delete-row.svg";
import { getSessionStorageValue } from "../../../effects/utils/session-storage";
import { get, post } from "../../../effects/api/api.common";
interface IGeneralDetailsProps {
  stepper: any;
  generalDetailModel: GeneralIncubatorModel;
  onStepSave: (
    stepperIndex: number,
    generalModel: GeneralIncubatorModel,
    request: number
  ) => void;
  applicationId: any;
  errors: Array<string>;
  teamError: Array<Number>;
}
interface IIKeyValueIndexer {
  [key: string]: boolean;
}
/** Render Incubator general details tab */
function CurrentApplicationForm(props: IGeneralDetailsProps) {
  const { stepper, generalDetailModel, onStepSave, applicationId } = props;
  // @ts-ignore: Unreachable code error
  const [{ user }, dispatch] = useAuthStateValue();
  const [openUploadBlock, setOpenUploadBlock] = useState<IIKeyValueIndexer>();
  const [openUploadBlock1, setOpenUploadBlock1] = useState<IIKeyValueIndexer>();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isRequire, setIsRequire] = useState(false);
  const [pdfOnly, setPdfOnly] = useState<IIKeyValueIndexer>();
  const [fileUpload, setFileUpload] = useState<FileUploadModel>(
    new FileUploadModel(0, 0, new File([], ""))
  );
  const [agreeTermsBlock, setAgreeTermsBlock] = useState(false);
  const [onlypdf, setonlypdf] = useState<any>("");
  const [onlypdf1, setonlypdf1] = useState<any>("");
  const [onlypdf2, setonlypdf2] = useState<any>("");
  const [PPTPath, setPPTPath] = useState("");
  const [excelSheetPath, setExcelSheetPath] = useState("");
  const [fullempval, setfullempval] = useState<any>("");
  const [sumOfEmpVal, setSumOfEmpVal] = useState(false);
  const [selfDeclarationPath, setSelfDeclarationPath] = useState("");
  const [loader, setLoader] = useState(false);
  const [pdfdemo, setpdfdemo] = useState<any>("");
  const [generalDetail, setGeneralDetail] = useState<GeneralIncubatorModel>(
    new GeneralIncubatorModel(
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      [],
      ""
    )
  );

  const [teams, setTeams] = useState<Array<TeamDetails>>([
    new TeamDetails(0, "", 0),
  ]);

  useEffect(() => {
    generalDetail.team_data = teams;
    onStepSave(
      StepperEnum.GeneralDetail,
      generalDetail,
      requestType.propertyChange
    );
  }, []);
  useEffect(() => {
    setGeneralDetail(generalDetailModel);
    if (generalDetailModel.team_data?.length > 0) {
      setTeams(generalDetailModel.team_data);
    }
  }, [generalDetailModel.team_data]);
  const history = useHistory();
  useEffect(() => {
    setGeneralDetail(generalDetailModel);
    bindDetails();
  }, [generalDetailModel]);
  const bindDetails = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    const userId = userInfo.id;
    setLoader(true);
    post(GET_SUM_DISBURSED_AMOUNT, { logged_user_id: userId }).then(
      (response: any) => {
        setLoader(false);
        if (!response || !response.data) return;
        if (response.data.length != 0) {
          generalDetail["Fund_Utilization_Details"] =
            response.data.Data.sum_disbured;
        }
      }
    );
  };
  useEffect(() => {
    setGeneralDetail(generalDetailModel);
    getExcelFilePath();
  }, [
    generalDetailModel.Re_apply_pitchdeck_upload,
    generalDetailModel.Other_document_upload,
    generalDetailModel.Performance_Upload,
  ]);
  const getExcelFilePath = () => {
    setLoader(true);
    get(EVALUATION_FILE).then((response: any) => {
      setLoader(false);
      if (!response.data) return;
      setExcelSheetPath(response.data.datasheetPath);
      setPPTPath(response.data.eacDeckPathReapp);
      setSelfDeclarationPath(response.data.selfDeclarationPath);
    });
  };
  const ListComponent = (props: any) => (
    <div
      style={{
        overflowY: "hidden",
        border: "dashed 2px #ccc",
        borderRadius: 5,
        width: "auto !important",
      }}
    >
      <div>
        {props.content}
        {props.links.map((title: any, url: any, code: any) => (
          <LinkComponent
            link={constructURLQuery(url, code, title)}
            title={title}
          />
        ))}
      </div>
    </div>
  );
  const ListComponent2 = (props: any) => (
    <div
      style={{
        overflowY: "hidden",
        border: "dashed 2px #ccc",
        borderRadius: 5,
        width: "auto !important",
      }}
    >
      <div>
        {props.content}
        {props.links.map((title: any, url: any, code: any) => (
          <LinkComponent2
            link={constructURLQuery(url, code, title)}
            title={title}
          />
        ))}
      </div>
    </div>
  );
  const ListComponent3 = (props: any) => (
    <div
      style={{
        overflowY: "hidden",
        border: "dashed 2px #ccc",
        borderRadius: 5,
        width: "auto !important",
      }}
    >
      <div>
        {props.content}
        {props.links.map((title: any, url: any, code: any) => (
          <LinkComponent3
            link={constructURLQuery(url, code, title)}
            title={title}
          />
        ))}
      </div>
    </div>
  );
  const removeItem = (title: any, type: any) => {
    // ptchdeck file start
    const newItems2 = [
      ...generalDetail.Re_apply_pitchdeck_upload_get.split(",").map(
        (currentName2: any, index: any, arr: any) => {
          return `${currentName2.slice(1)} `;
        }
      ),
    ];
    // ////console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@",newItems2);
    const index2 = newItems2.indexOf(title);
    // ////console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@",index2);
    newItems2.splice(index2, 1).join("\n");
    generalDetail["Re_apply_pitchdeck_upload_get"] = newItems2.toString();
    generalDetail["Re_apply_pitchdeck_upload_slice"] = newItems2.toString();
    // pitch deck file end
    onStepSave(StepperEnum.GeneralDetail, generalDetail, requestType.save);
  };
  const removeItem2 = (title: any, type: any) => {
    // performance file start
    const newItems3 = [
      ...generalDetail.Performance_Upload_get.split(",").map(
        (currentName3: any, index: any, arr: any) => {
          return `${currentName3.slice(1)} `;
        }
      ),
    ];
    // ////console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",newItems3);
    const index3 = newItems3.indexOf(title);
    // ////console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",index3);
    newItems3.splice(index3, 1).join("\n");
    generalDetail["Performance_Upload_get"] = newItems3.toString();
    generalDetail["Performance_Upload_slice"] = newItems3.toString();
    //performance file end
    onStepSave(StepperEnum.GeneralDetail, generalDetail, requestType.save);
  };
  const removeItem3 = (title: any, type: any) => {
    //other file start
    const newItems4 = [
      ...generalDetail.Other_document_upload_get.split(",").map(
        (currentName4: any, index: any, arr: any) => {
          return `${currentName4.slice(1)} `;
        }
      ),
    ];
    // ////console.log("###########################",newItems4);
    const index4 = newItems4.indexOf(title);
    // ////console.log("###########################",index4);
    newItems4.splice(index4, 1).join("\n");
    generalDetail["Other_document_upload_get"] = newItems4.toString();
    generalDetail["Other_document_upload_slice"] = newItems4.toString();
    //other file end
    onStepSave(StepperEnum.GeneralDetail, generalDetail, requestType.save);
  };
  function constructURLQuery(url: any, code: any, title: any) {
    return `${NODE_URL}${title}`;
  }
  const LinkComponent = (props: any) => (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          padding: 15,
        }}
      >
        <img
          style={{ width: 40, height: 30 }}
          src="https://www.macworld.com/wp-content/uploads/2023/01/pdf-icon.jpg?quality=50&strip=all"
        ></img>
        <a
          style={{ color: "#000", textAlign: "center", fontSize: 10 }}
          href={props.link}
          title={props.title}
          target="_blank"
        >
          {props.title.slice(31)}
        </a>
        <span
          className="close"
          // onClick={() => { props.onDelete(props.index) }}
          onClick={() => removeItem(props.title, props.type)}
        >
          <i className="fa fa-close"></i>
        </span>
      </div>
      <div
        style={{
          borderBottom: "1px solid #ccc",
          height: 1,
          width: "auto !important",
        }}
      ></div>
    </div>
  );
  const LinkComponent2 = (props: any) => (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          padding: 15,
        }}
      >
        <img
          style={{ width: 40, height: 30 }}
          src="https://www.macworld.com/wp-content/uploads/2023/01/pdf-icon.jpg?quality=50&strip=all"
        ></img>
        <a
          style={{ color: "#000", textAlign: "center", fontSize: 10 }}
          href={props.link}
          title={props.title}
          target="_blank"
        >
          {props.title.slice(31)}
        </a>
        <span
          className="close"
          // onClick={() => { props.onDelete(props.index) }}
          onClick={() => removeItem2(props.title, props.type)}
        >
          <i className="fa fa-close"></i>
        </span>
      </div>
      <div
        style={{
          borderBottom: "1px solid #ccc",
          height: 1,
          width: "auto !important",
        }}
      ></div>
    </div>
  );
  const LinkComponent3 = (props: any) => (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          padding: 15,
        }}
      >
        <img
          style={{ width: 40, height: 30 }}
          src="https://www.macworld.com/wp-content/uploads/2023/01/pdf-icon.jpg?quality=50&strip=all"
        ></img>
        <a
          style={{ color: "#000", textAlign: "center", fontSize: 10 }}
          href={props.link}
          title={props.title}
          target="_blank"
        >
          {props.title.slice(31)}
        </a>
        <span
          className="close"
          // onClick={() => { props.onDelete(props.index) }}
          onClick={() => removeItem3(props.title, props.type)}
        >
          <i className="fa fa-close"></i>
        </span>
      </div>
      <div
        style={{
          borderBottom: "1px solid #ccc",
          height: 1,
          width: "auto !important",
        }}
      ></div>
    </div>
  );
  const onFileUpload = (files: Array<File>, type: any) => {
    if (type == "Re_apply_pitchdeck_upload") {
      generalDetail["Re_apply_pitchdeck_upload"] = files;
      //////console.log(generalDetail, "inc")
      if (Array.isArray(generalDetail.Re_apply_pitchdeck_upload)) {
        //////console.log("tru@@@@@@@@@@")
        setonlypdf(generalDetail.Re_apply_pitchdeck_upload);
      } else {
        //////console.log("falsemahi@@@@@")
      }
    } else if (type == "Performance_Upload") {
      generalDetail["Performance_Upload"] = files;
      //////console.log(generalDetail, "inc")
      if (Array.isArray(generalDetail.Performance_Upload)) {
        //////console.log("trumahesh@@@@@@@@@@")
        setonlypdf1(generalDetail.Performance_Upload);
      } else {
        //////console.log("false@@@@@")
      }
    }
    if (type == "Other_document_upload") {
      generalDetail["Other_document_upload"] = files;
      //////console.log(generalDetail, "inc")
      if (Array.isArray(generalDetail.Other_document_upload)) {
        //////console.log("tru@@@@@@@@@@")
        setonlypdf2(generalDetail.Other_document_upload);
      } else {
        //////console.log("falsemahi@@@@@")
      }
    }
  };
  const onFileDelete = (file: File, type: string) => {
    const {
      Re_apply_pitchdeck_upload,
      Performance_Upload,
      Other_document_upload,
    } = generalDetail;
    let remainingFiles: Array<FileUploadModel> = [];
    let fileToBeDeleted: any;
    switch (type) {
      case UploadTypeEnum1.pitchDocumentUpload:
        fileToBeDeleted = Re_apply_pitchdeck_upload.find(
          (uploadedFile: FileUploadModel) => uploadedFile.file === file
        );
        if (!fileToBeDeleted) return;
        remainingFiles = Re_apply_pitchdeck_upload.filter(
          (uf: FileUploadModel) => uf.file_id !== fileToBeDeleted.file_id
        );
        generalDetail.Re_apply_pitchdeck_upload = remainingFiles;
        onStepSave(
          StepperEnum.GeneralDetail,
          generalDetail,
          requestType.propertyChange
        );
        break;

      case UploadTypeEnum1.performanceUpload:
        fileToBeDeleted = Performance_Upload.find(
          (uploadedFile: FileUploadModel) => uploadedFile.file === file
        );
        if (!fileToBeDeleted) return;
        remainingFiles = Performance_Upload.filter(
          (uf: FileUploadModel) => uf.file_id !== fileToBeDeleted.file_id
        );
        generalDetail.Performance_Upload = remainingFiles;
        onStepSave(
          StepperEnum.GeneralDetail,
          generalDetail,
          requestType.propertyChange
        );
        break;
      case UploadTypeEnum1.otherDocumentUpload:
        fileToBeDeleted = Other_document_upload.find(
          (uploadedFile: FileUploadModel) => uploadedFile.file === file
        );
        if (!fileToBeDeleted) return;
        remainingFiles = Other_document_upload.filter(
          (uf: FileUploadModel) => uf.file_id !== fileToBeDeleted.file_id
        );
        generalDetail.Other_document_upload = remainingFiles;
        onStepSave(
          StepperEnum.GeneralDetail,
          generalDetail,
          requestType.propertyChange
        );
        break;
    }
  };
  const onSave = (e: any) => {
    e.preventDefault();
    generalDetail.team_data = teams;
    generalDetail.team_data.map((data: any) => {
      var passdata = data.team_name;
    });
    onStepSave(
      StepperEnum.GeneralDetail,
      generalDetail,
      requestType.propertyChange
    );
    onStepSave(StepperEnum.GeneralDetail, generalDetail, requestType.save);
    stepper && stepper.next();
    window.scrollTo(0, 250);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    // const teamDetail = new TeamDetails(0, e.target.value, 1);
    teams[index].team_name = e.target.value;
    const updatedTeams = JSON.stringify(teams);
    setTeams(JSON.parse(updatedTeams));
    generalDetail.team_data = teams;
    // onStepSave(
    //     StepperEnum.GeneralDetail,
    //     generalDetail,
    //     requestType.propertyChange
    // );
  };
  const [total, setTotal] = useState("");
  var check: boolean = false;
  const [show, setShow] = useState(0);
  // handle input change for team rows- no of employee
  const initialValue = generalDetail.fulltime_employees_re_apply;

  const [maxValue, setMaxValue] = useState(initialValue);
  const [totalInputValue, setTotalInputValue] = useState<number>(0);
  useEffect(() => {
    //console.log(totalInputValue, "inputval"); // don't remove the //console
    if (totalInputValue == generalDetail.fulltime_employees_re_apply) {
      setSumOfEmpVal(true);
      // setMaxValue(0)
    } else {
      setSumOfEmpVal(false);
    }
    //console.log(sumOfEmpVal, "inputval");
  }, [totalInputValue]);
  const handleIncrementDecrementChangeInput = (
    name: string,
    value: number,
    index: number
  ) => {
    setfullempval(value);
    const teamDetail = teams[index];
    if (typeof teamDetail.no_employee === "string") {
      value = Number(teamDetail.no_employee);
    }

    const sum = teams.reduce((acc, team, i) => {
      if (i !== index) {
        return acc + team.no_employee;
      }
      return acc;
    }, 0);
    const maxValue = generalDetail.fulltime_employees_re_apply - sum;
    setMaxValue(maxValue);
    teamDetail.no_employee = value;
    // ////console.log(teamDetail.no_employee,"hhhh")
    teams[index] = teamDetail;
    const updatedTeams = JSON.stringify(teams);
    setTeams(JSON.parse(updatedTeams));
    // Calculate total sum
    let add = 0;
    teams.forEach((team) => {
      add += team.no_employee;
    });
    setTotalInputValue(add);
  };
  // on increament decrement control change (not for teams[])
  const handleIncrementDecrementChange = (name: string, value: number) => {
    if (!isNaN(value)) {
      let incubator = JSON.parse(JSON.stringify(generalDetail));
      generalDetail.fulltime_employees_re_apply = value;
      setGeneralDetail((prevState: GeneralIncubatorModel) => {
        setMaxValue(value);
        return {
          ...prevState,
          [name]: value ? value : "",
        };
      });
      // onStepSave(
      //     StepperEnum.GeneralDetail,
      //     generalDetail,
      //     requestType.propertyChange
      // );
    }
  };
  // add and removes the teams
  const addRemoveTeam = (rowIndex?: number) => {
    var fullvalue = generalDetail.fulltime_employees_re_apply;
    var teamdata = generalDetail.team_data;
    const totals: any = [];
    let sum = 0;
    let key = "no_employee";
    ////console.log(generalDetail.team_data,"generalDetail.team_data")
    let empdata = generalDetail.team_data.map((obj: any) => {
      ////console.log(obj,"obj")
      if (obj.hasOwnProperty(key)) {
        let val = (sum += parseInt(obj[key]));
        ////console.log(val,"valu")
        setShow(val);
        if (rowIndex || rowIndex === 0) {
          const teamRows = teams.filter(
            (_, index: number) => index !== rowIndex
          );
          setTeams(teamRows);
          generalDetail.team_data = teamRows;
          if (val != fullvalue) {
            setSumOfEmpVal(false);
          }
        } else if (fullempval == fullvalue || val == fullvalue) {
          const teamRows = teams.filter(
            (_, index: number) => index !== rowIndex
          );
          setTeams(teamRows);
          generalDetail.team_data = teamRows;
        } else {
          const team = new TeamDetails(teams.length, "", 0);
          ////console.log(team,"new")
          setTeams([...teams, team]);
          generalDetail.team_data = [...teams, team];
          ////console.log(fullempval,fullvalue,"team")
        }
      }
    });
    const total = teams.reduce((sum, team) => sum + team.no_employee, 0);
    onStepSave(
      StepperEnum.GeneralDetail,
      generalDetail,
      requestType.propertyChange
    );
  };
  useEffect(() => {
    setGeneralDetail(generalDetailModel);
    let data = ["/data", "/my name is mahesh", "/this is pdf"];
  }, [generalDetailModel]);
  const initialValues = { username: "", email: "", password: "" };
  const [isSubmit, setIsSubmit] = useState(false);
  const preventMinus = (e: any) => {};
  const handleBlur = () => {};
  const handleChange = (
    evt: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const name = evt.target.name;
    let value = evt.target.value
      .replace(/[^0-9]/gi, "")
      .toString()
      .split(".")
      .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
      .join(".");
    let genDetails = JSON.parse(JSON.stringify(generalDetail));
    genDetails[name] = value;
    setGeneralDetail(genDetails);
    setGeneralDetail({ ...generalDetail, [name]: value });
    //////console.log(genDetails, "pp")
  };
  const handleChange2 = (
    evt: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const name = evt.target.name;
    let value = evt.target.value
      .replace(/[^0-9.]/gi, "")
      .toString()
      .split(".")
      .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
      .join(".");
    let decimalCount = value.split(".").length - 1;
    if (decimalCount > 1) {
      value = value.split(".").slice(0, 2).join(".");
    }
    let genDetails = JSON.parse(JSON.stringify(generalDetail));

    genDetails[name] = value;
    setGeneralDetail(genDetails);
    setGeneralDetail({ ...generalDetail, [name]: value });
  };
  const handleChange1 = (
    evt: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const name = evt.target.name;
    const value = evt.target.value;
    // .toString().split(".").map((el, i) => i ? el.split("").slice(0, 2).join("") : el).join(".");

    let genDetails = JSON.parse(JSON.stringify(generalDetail));

    genDetails[name] = value;
    setGeneralDetail(genDetails);
    setGeneralDetail({ ...generalDetail, [name]: value });

    //////console.log(genDetails, "pp")
  };
  // handle checkboxes of upload XL
  const handleCheckbox = (e: any) => {
    const name = e.target.name;
    const checked = e.target.checked;
    // ////console.log(checked, "checkedd")
    let updatedOtherDetail = JSON.parse(JSON.stringify(generalDetail));
    updatedOtherDetail[name] = checked;

    // if(checked == 'true'){
    // updatedOtherDetail[name] = 1;

    // }else{
    //     updatedOtherDetail[name] = 0;

    // }

    setGeneralDetail(updatedOtherDetail);
    // ////console.log(generalDetail, "gener")
    onStepSave(
      StepperEnum.GeneralDetail,
      updatedOtherDetail,
      requestType.propertyChange
    );
  };
  const applicationPage = () => {
    history.push({
      pathname: "/incubator_status",
    });
  };
  const userdetails: any = getSessionStorageValue("user");
  const userInfo = JSON.parse(userdetails);
  const [disbursedamountdata, setdisbursedamountdata] = useState<any>("");
  const [disbursedamount, setdisbursedamount] = useState("");
  useEffect(() => {
    if (userInfo.id) {
      getLeftSideBoxDetails(userInfo.id);
    }
  }, [userInfo.id]); // Add userInfo.id as a dependency

  const getLeftSideBoxDetails = async (user_id: number) => {
    console.log(user_id);
    let Paylod = { user_id: user_id };

    try {
      const response = await post(secgreybox, Paylod);
      if (response && response.data && response.data.Data) {
        console.log(response.data.Data);
        setdisbursedamountdata(response.data.Data);

        // You can use .find instead of .forEach for more efficient data retrieval
        const amount = response.data.Data.find(
          (element: any) =>
            element.funds_disbursed_startup != null &&
            element.funds_disbursed_startup !== undefined
        );
        if (amount) {
          setdisbursedamount(amount.funds_disbursed_startup);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div
      id="incubator-step-1"
      role="tabpanel"
      className="bs-stepper-pane content"
      aria-labelledby="stepper1trigger1"
    >
      {loader && (
        <div className="spinner-border custom-spinner" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}
      {/* {//////console.log("VALIDATION", props.errors, generalDetail)} */}
      {/* {//////console.log("GENERAL DETAIL MODEL", generalDetail)} */}
      {/* {//////console.log("GENERAL DETAIL ERROR", props.errors)} */}
      <fieldset disabled={loader}>
        <div className="form-wrapper stepper-form stepper-form-1">
          <div className="form-content">
            <div className="text-center">
              <b>
                Following are the general progress details of the incubator for
                facilities developed, incubation support provided, startups
                supported and the funds managed/utilized in the last three
                years.
              </b>
            </div>
            <hr />
            <p
              className=""
              style={{
                fontSize: "17px",
                fontWeight: "600",
                color: "#2b3f88",
                padding: "20px 20px",
                backgroundColor: "#f8f7f7",
              }}
            >
              Details of incubation, funding, mentorship, other support provided
              to startups in the last three years as on date
            </p>
            <h6
              className="pb-3"
              style={{
                fontWeight: "600",
                padding: "20px 20px",
                backgroundColor: "#f8f7f7",
              }}
            >
              Incubation support provided by the incubator in last three years
              as on date
            </h6>
            <div className="row">
              <div className="col-md-6">
                <div style={{ marginLeft: "20px" }}>
                  <div className="form-group required row">
                    <label className="col-sm-10 col-form-label">
                      No. of startups incubated and their details
                    </label>
                    <div className="col-sm-10">
                      <input
                        min="0"
                        onKeyPress={preventMinus}
                        name="No_startups_incubated"
                        value={
                          generalDetail.No_startups_incubated == "0"
                            ? 0
                            : generalDetail.No_startups_incubated
                        }
                        className={`${
                          props.errors.length &&
                          validateRequiredText(
                            generalDetail.No_startups_incubated,
                            255
                          )
                            ? `form-control error`
                            : "form-control"
                        }`}
                        onBlur={handleBlur}
                        required={true}
                        maxLength={255}
                        onChange={handleChange}
                      />
                      <div
                        className={`${
                          props.errors.length &&
                          validateRequiredText(
                            generalDetail.No_startups_incubated,
                            255
                          )
                            ? `error`
                            : "invalid-feedback "
                        }`}
                      >
                        {validateRequiredText(
                          generalDetail.No_startups_incubated,
                          255
                        )}
                      </div>
                      <small>
                        {" "}
                        <i>as on date (in the last 3 years as on date)</i>{" "}
                      </small>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div style={{ marginLeft: "30px" }}>
                  <div className="form-group required row">
                    <label className="col-sm-10 col-form-label">
                      No. of startups graduated{" "}
                    </label>
                    <div className="col-sm-10">
                      <br />
                      <input
                        min="0"
                        onKeyPress={preventMinus}
                        name="No_startups_graduated"
                        value={
                          generalDetail.No_startups_graduated == "0"
                            ? 0
                            : generalDetail.No_startups_graduated
                        }
                        className={`${
                          props.errors.length &&
                          validateRequiredText(
                            generalDetail.No_startups_graduated,
                            255
                          )
                            ? `form-control error`
                            : "form-control"
                        }`}
                        onBlur={handleBlur}
                        required={true}
                        maxLength={255}
                        onChange={handleChange}
                      />
                      <div
                        className={`${
                          props.errors.length &&
                          validateRequiredText(
                            generalDetail.No_startups_graduated,
                            255
                          )
                            ? `error`
                            : "invalid-feedback "
                        }`}
                      >
                        {validateRequiredText(
                          generalDetail.No_startups_graduated,
                          255
                        )}
                      </div>
                      <small>
                        {" "}
                        <i>as on date (in the last 3 years as on date)</i>{" "}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div style={{ marginLeft: "20px" }}>
                  <div className="form-group required row">
                    <label className="col-sm-10 col-form-label">
                      No. of startups that raised follow on investments
                    </label>
                    <div className="col-sm-10">
                      <input
                        min="0"
                        onKeyPress={preventMinus}
                        name="No_startups_raised"
                        value={
                          generalDetail.No_startups_raised == "0"
                            ? 0
                            : generalDetail.No_startups_raised
                        }
                        className={`${
                          props.errors.length &&
                          validateRequiredText(
                            generalDetail.No_startups_raised,
                            255
                          )
                            ? `form-control error`
                            : "form-control"
                        }`}
                        onBlur={handleBlur}
                        required={true}
                        maxLength={255}
                        onChange={handleChange}
                      />
                      <div
                        className={`${
                          props.errors.length &&
                          validateRequiredText(
                            generalDetail.No_startups_raised,
                            255
                          )
                            ? `error`
                            : "invalid-feedback "
                        }`}
                      >
                        {validateRequiredText(
                          generalDetail.No_startups_raised,
                          255
                        )}
                      </div>
                      <small>
                        {" "}
                        <i>as on date (in the last 3 years as on date)</i>{" "}
                      </small>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div style={{ marginLeft: "30px" }}>
                  <div className="form-group required row">
                    <label className="col-sm-10 col-form-label">
                      No. of startups that crossed a revenue of Rs 1 Cr in last
                      1 year
                    </label>
                    <div className="col-sm-10">
                      <input
                        min="0"
                        onKeyPress={preventMinus}
                        name="No_startups_crossed"
                        value={
                          generalDetail.No_startups_crossed == "0"
                            ? 0
                            : generalDetail.No_startups_crossed
                        }
                        className={`${
                          props.errors.length &&
                          validateRequiredText(
                            generalDetail.No_startups_crossed,
                            255
                          )
                            ? `form-control error`
                            : "form-control"
                        }`}
                        onBlur={handleBlur}
                        required={true}
                        maxLength={255}
                        onChange={handleChange}
                      />
                      <div
                        className={`${
                          props.errors.length &&
                          validateRequiredText(
                            generalDetail.No_startups_crossed,
                            255
                          )
                            ? `error`
                            : "invalid-feedback "
                        }`}
                      >
                        {validateRequiredText(
                          generalDetail.No_startups_crossed,
                          255
                        )}
                      </div>
                      <small>
                        <i>as on date date of application</i>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div style={{ marginLeft: "20px" }}>
                  <div className="form-group required row">
                    <label className="col-sm-10 col-form-label">
                      2-year survival rate of startups from the date of joining
                      incubator
                    </label>
                    <div className="col-sm-10">
                      <div className="form-row">
                        <div className="col">
                          <div className="form-inline">
                            <input
                              min="0"
                              onKeyPress={preventMinus}
                              name="survival_rate_startups"
                              value={
                                generalDetail.survival_rate_startups == "0"
                                  ? 0
                                  : generalDetail.survival_rate_startups
                              }
                              className={`${
                                props.errors.length &&
                                validateRequiredText(
                                  generalDetail.survival_rate_startups,
                                  255
                                )
                                  ? `form-control error`
                                  : "form-control"
                              }`}
                              onBlur={handleBlur}
                              required={true}
                              maxLength={255}
                              onChange={handleChange2}
                            />
                            <div className="ml-2">
                              <span className="form-control-plaintext">
                                (%)
                              </span>
                            </div>
                          </div>
                          <div
                            className={`${
                              props.errors.length &&
                              validateRequiredText(
                                generalDetail.survival_rate_startups,
                                255
                              )
                                ? `error`
                                : "invalid-feedback "
                            }`}
                          >
                            {validateRequiredText(
                              generalDetail.survival_rate_startups,
                              255
                            )}
                          </div>
                          <small>
                            {" "}
                            <i>
                              as on date (in the last 3 years as on date)
                            </i>{" "}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div style={{ marginLeft: "30px" }}></div>
              </div>
            </div>
            <h6
              className="pb-3"
              style={{
                fontWeight: "600",
                padding: "20px 20px",
                backgroundColor: "#f8f7f7",
              }}
            >
              Funding support extended to incubatees in last three years as on
              date
            </h6>
            <div className="row">
              <div className="col-md-6">
                <div style={{ marginLeft: "20px" }}>
                  <div className="form-group required row">
                    <label className="col-sm-10 col-form-label">
                      Investment agreements signed between incubator and
                      startups
                    </label>
                    <div className="col-sm-10">
                      <input
                        min="0"
                        onKeyPress={preventMinus}
                        name="Investment_agreements_signed"
                        value={
                          generalDetail.Investment_agreements_signed == "0"
                            ? 0
                            : generalDetail.Investment_agreements_signed
                        }
                        className={`${
                          props.errors.length &&
                          validateRequiredText(
                            generalDetail.Investment_agreements_signed,
                            255
                          )
                            ? `form-control error`
                            : "form-control"
                        }`}
                        onBlur={handleBlur}
                        required={true}
                        maxLength={255}
                        // placeholder="Investment agreements signed"
                        onChange={handleChange}
                      />
                      <div
                        className={`${
                          props.errors.length &&
                          validateRequiredText(
                            generalDetail.Investment_agreements_signed,
                            255
                          )
                            ? `error`
                            : "invalid-feedback "
                        }`}
                      >
                        {validateRequiredText(
                          generalDetail.Investment_agreements_signed,
                          255
                        )}
                      </div>
                      {/* <small style={{color:'#ff0000'}}><i>{formErrors.Investment_agreements_signed}</i></small> */}
                      <small>
                        {" "}
                        <i>as on date (in the last 3 years as on date)</i>{" "}
                      </small>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div style={{ marginLeft: "30px" }}>
                  <div className="form-group required row">
                    <label className="col-sm-10 col-form-label">
                      No. of startups invested in
                    </label>
                    <div className="col-sm-10">
                      <br />
                      <input
                        min="0"
                        onKeyPress={preventMinus}
                        name="No_startups_invested"
                        value={
                          generalDetail.No_startups_invested == "0"
                            ? 0
                            : generalDetail.No_startups_invested
                        }
                        className={`${
                          props.errors.length &&
                          validateRequiredText(
                            generalDetail.No_startups_invested,
                            255
                          )
                            ? `form-control error`
                            : "form-control"
                        }`}
                        onBlur={handleBlur}
                        required={true}
                        maxLength={255}
                        // placeholder="No. of startups invested"
                        onChange={handleChange}
                      />
                      <div
                        className={`${
                          props.errors.length &&
                          validateRequiredText(
                            generalDetail.No_startups_invested,
                            255
                          )
                            ? `error`
                            : "invalid-feedback "
                        }`}
                      >
                        {validateRequiredText(
                          generalDetail.No_startups_invested,
                          255
                        )}
                      </div>
                      {/* <small style={{color:'#ff0000'}}><i>{formErrors.No_startups_invested}</i></small> */}
                      <small>
                        {" "}
                        <i>as on date (in the last 3 years as on date)</i>{" "}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div style={{ marginLeft: "20px" }}>
                  <div className="form-group required row">
                    <label className="col-sm-10 col-form-label">
                      Total Corpus allocated to incubatees by the incubator
                    </label>
                    <div className="col-sm-10">
                      <div className="form-inline">
                        <input
                          min="0"
                          onKeyPress={preventMinus}
                          name="allocated_incubatees"
                          value={
                            generalDetail.allocated_incubatees == "0"
                              ? 0
                              : generalDetail.allocated_incubatees
                          }
                          className={`${
                            props.errors.length &&
                            validateRequiredText(
                              generalDetail.allocated_incubatees,
                              255
                            )
                              ? `form-control error`
                              : "form-control"
                          }`}
                          onBlur={handleBlur}
                          required={true}
                          maxLength={255}
                          // placeholder="Rupees in Lakhs (&#8377;)"
                          onChange={handleChange}
                        />
                        <div className="ml-2">
                          <span className="form-control-plaintext">
                            &#8377;
                          </span>
                        </div>
                      </div>
                      <div
                        className={`${
                          props.errors.length &&
                          validateRequiredText(
                            generalDetail.allocated_incubatees,
                            255
                          )
                            ? `error`
                            : "invalid-feedback "
                        }`}
                      >
                        {validateRequiredText(
                          generalDetail.allocated_incubatees,
                          255
                        )}
                      </div>
                      {/* <small style={{color:'#ff0000'}}><i>{formErrors.allocated_incubatees}</i></small> */}
                      <small>
                        {" "}
                        <i>as on date (in the last 3 years as on date)</i>{" "}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div style={{ marginLeft: "30px" }}>
                  <div className="form-group required row">
                    <label className="col-sm-10 col-form-label">
                      Total Corpus allocated to incubatees from external sources
                    </label>
                    <div className="col-sm-10">
                      <div className="form-inline">
                        <input
                          min="0"
                          onKeyPress={preventMinus}
                          name="external_sources"
                          value={
                            generalDetail.external_sources == "0"
                              ? 0
                              : generalDetail.external_sources
                          }
                          className={`${
                            props.errors.length &&
                            validateRequiredText(
                              generalDetail.external_sources,
                              255
                            )
                              ? `form-control error`
                              : "form-control"
                          }`}
                          onBlur={handleBlur}
                          required={true}
                          maxLength={255}
                          // placeholder="Rupees in Lakhs (&#8377;)"
                          onChange={handleChange}
                        />
                        <div className="ml-2">
                          <span className="form-control-plaintext">
                            &#8377;
                          </span>
                        </div>
                      </div>
                      <div
                        className={`${
                          props.errors.length &&
                          validateRequiredText(
                            generalDetail.external_sources,
                            255
                          )
                            ? `error`
                            : "invalid-feedback "
                        }`}
                      >
                        {validateRequiredText(
                          generalDetail.external_sources,
                          255
                        )}
                      </div>
                      {/* <small style={{color:'#ff0000'}}><i>{formErrors.external_sources}</i></small> */}
                      <small>
                        {" "}
                        <i>as on date (in the last 3 years as on date)</i>{" "}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h6
              className="pb-3"
              style={{
                fontWeight: "600",
                padding: "20px 20px",
                backgroundColor: "#f8f7f7",
              }}
            >
              Mentoring provided to incubatees in last three years as on date
            </h6>
            <div className="row">
              <div className="col-md-6">
                <div style={{ marginLeft: "20px" }}>
                  <div className="form-group required row">
                    <label className="col-sm-10 col-form-label">
                      No. of mentors actively engaged with incubator
                    </label>
                    <div className="col-sm-10">
                      <input
                        min="0"
                        onKeyPress={preventMinus}
                        name="No_mentors_actively"
                        value={
                          generalDetail.No_mentors_actively == "0"
                            ? 0
                            : generalDetail.No_mentors_actively
                        }
                        className={`${
                          props.errors.length &&
                          validateRequiredText(
                            generalDetail.No_mentors_actively,
                            255
                          )
                            ? `form-control error`
                            : "form-control"
                        }`}
                        onBlur={handleBlur}
                        required={true}
                        maxLength={255}
                        // placeholder="No. of mentors actively"
                        onChange={handleChange}
                      />
                      <div
                        className={`${
                          props.errors.length &&
                          validateRequiredText(
                            generalDetail.No_mentors_actively,
                            255
                          )
                            ? `error`
                            : "invalid-feedback "
                        }`}
                      >
                        {validateRequiredText(
                          generalDetail.No_mentors_actively,
                          255
                        )}
                      </div>
                      {/* <small style={{color:'#ff0000'}}><i>{formErrors.No_mentors_actively}</i></small> */}
                      <small>
                        {" "}
                        <i>as on date (in the last 3 years as on date)</i>{" "}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div style={{ marginLeft: "30px" }}>
                  <div className="form-group required row">
                    <label className="col-sm-10 col-form-label">
                      Avg mentoring hours allocated per startup per month
                    </label>
                    <div className="col-sm-10">
                      <input
                        min="0"
                        onKeyPress={preventMinus}
                        name="allocated_startup_month"
                        value={
                          generalDetail.allocated_startup_month == "0"
                            ? 0
                            : generalDetail.allocated_startup_month
                        }
                        className={`${
                          props.errors.length &&
                          validateRequiredText(
                            generalDetail.allocated_startup_month,
                            255
                          )
                            ? `form-control error`
                            : "form-control"
                        }`}
                        onBlur={handleBlur}
                        required={true}
                        maxLength={255}
                        // placeholder="allocated per startup per month"
                        onChange={handleChange}
                      />
                      <div
                        className={`${
                          props.errors.length &&
                          validateRequiredText(
                            generalDetail.allocated_startup_month,
                            255
                          )
                            ? `error`
                            : "invalid-feedback "
                        }`}
                      >
                        {validateRequiredText(
                          generalDetail.allocated_startup_month,
                          255
                        )}
                      </div>
                      {/* <small style={{color:'#ff0000'}}><i>{formErrors.allocated_startup_month}</i></small> */}
                      <small>
                        {" "}
                        <i>as on date (in the last 3 years as on date)</i>{" "}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div style={{ marginLeft: "20px" }}>
                  <div className="form-group required row">
                    <label className="col-sm-10 col-form-label">
                      No. of patents registered by incubatees
                    </label>
                    <div className="col-sm-10">
                      <input
                        min="0"
                        onKeyPress={preventMinus}
                        name="No_patents_registered"
                        value={
                          generalDetail.No_patents_registered == "0"
                            ? 0
                            : generalDetail.No_patents_registered
                        }
                        className={`${
                          props.errors.length &&
                          validateRequiredText(
                            generalDetail.No_patents_registered,
                            255
                          )
                            ? `form-control error`
                            : "form-control"
                        }`}
                        onBlur={handleBlur}
                        required={true}
                        maxLength={255}
                        // placeholder="No. of patents registered"
                        onChange={handleChange}
                      />
                      <div
                        className={`${
                          props.errors.length &&
                          validateRequiredText(
                            generalDetail.No_patents_registered,
                            255
                          )
                            ? `error`
                            : "invalid-feedback "
                        }`}
                      >
                        {validateRequiredText(
                          generalDetail.No_patents_registered,
                          255
                        )}
                      </div>
                      {/* <small style={{color:'#ff0000'}}><i>{formErrors.No_patents_registered}</i></small> */}
                      <small>
                        <i>as on date (in the last 3 years as on date)</i>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div style={{ marginLeft: "30px" }}>
                  <div className="form-group required row">
                    <label className="col-sm-10 col-form-label">
                      No. of copyrights registered by incubatees
                    </label>
                    <div className="col-sm-10">
                      <input
                        min="0"
                        onKeyPress={preventMinus}
                        name="No_patents_copyrights"
                        value={
                          generalDetail.No_patents_copyrights == "0"
                            ? 0
                            : generalDetail.No_patents_copyrights
                        }
                        className={`${
                          props.errors.length &&
                          validateRequiredText(
                            generalDetail.No_patents_copyrights,
                            255
                          )
                            ? `form-control error`
                            : "form-control"
                        }`}
                        onBlur={handleBlur}
                        required={true}
                        maxLength={255}
                        // placeholder="No. of copyrights registered"
                        onChange={handleChange}
                      />
                      <div
                        className={`${
                          props.errors.length &&
                          validateRequiredText(
                            generalDetail.No_patents_copyrights,
                            255
                          )
                            ? `error`
                            : "invalid-feedback "
                        }`}
                      >
                        {validateRequiredText(
                          generalDetail.No_patents_copyrights,
                          255
                        )}
                      </div>
                      {/* <small style={{color:'#ff0000'}}><i>{formErrors.No_patents_copyrights}</i></small> */}
                      <small>
                        <i>as on date (in the last 3 years as on date)</i>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div style={{ marginLeft: "20px" }}>
                  <div className="form-group required row">
                    <label className="col-sm-10 col-form-label">
                      No. of trademarks registered by incubatees
                    </label>
                    <div className="col-sm-10">
                      <input
                        min="0"
                        onKeyPress={preventMinus}
                        name="No_trademarks_registered"
                        value={
                          generalDetail.No_trademarks_registered == "0"
                            ? 0
                            : generalDetail.No_trademarks_registered
                        }
                        className={`${
                          props.errors.length &&
                          validateRequiredText(
                            generalDetail.No_trademarks_registered,
                            255
                          )
                            ? `form-control error`
                            : "form-control"
                        }`}
                        onBlur={handleBlur}
                        required={true}
                        maxLength={255}
                        // placeholder="No. of trademarks registered"
                        onChange={handleChange}
                      />
                      <div
                        className={`${
                          props.errors.length &&
                          validateRequiredText(
                            generalDetail.No_trademarks_registered,
                            255
                          )
                            ? `error`
                            : "invalid-feedback "
                        }`}
                      >
                        {validateRequiredText(
                          generalDetail.No_trademarks_registered,
                          255
                        )}
                      </div>
                      {/* <small style={{color:'#ff0000'}}><i>{formErrors.No_trademarks_registered}</i></small> */}
                      <small>
                        <i>as on date (in the last 3 years as on date)</i>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div style={{ marginLeft: "30px" }}></div>
              </div>
            </div>
            <h6
              className="pb-3"
              style={{
                fontWeight: "600",
                padding: "20px 20px",
                backgroundColor: "#f8f7f7",
              }}
            >
              Other support extended to incubatees in last three years as on
              date
            </h6>

            <div className="row">
              <div className="col-md-6">
                <div style={{ marginLeft: "20px" }}>
                  <div className="form-group required row">
                    <label className="col-sm-10 col-form-label">
                      Industry/Corporate connects facilitated
                    </label>
                    <div className="col-sm-10">
                      <input
                        min="0"
                        onKeyPress={preventMinus}
                        name="Corporate_connects_facilitated"
                        value={
                          generalDetail.Corporate_connects_facilitated == "0"
                            ? 0
                            : generalDetail.Corporate_connects_facilitated
                        }
                        className={`${
                          props.errors.length &&
                          validateRequiredText(
                            generalDetail.Corporate_connects_facilitated,
                            255
                          )
                            ? `form-control error`
                            : "form-control"
                        }`}
                        onBlur={handleBlur}
                        required={true}
                        maxLength={255}
                        // placeholder="Industry/Corporate connects facilitated"
                        onChange={handleChange}
                      />
                      <div
                        className={`${
                          props.errors.length &&
                          validateRequiredText(
                            generalDetail.Corporate_connects_facilitated,
                            255
                          )
                            ? `error`
                            : "invalid-feedback "
                        }`}
                      >
                        {validateRequiredText(
                          generalDetail.Corporate_connects_facilitated,
                          255
                        )}
                      </div>
                      {/* <small style={{color:'#ff0000'}}><i>{formErrors.Corporate_connects_facilitated}</i></small> */}
                      <small>
                        <i>as on date (in the last 3 years as on date)</i>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div style={{ marginLeft: "30px" }}>
                  <div className="form-group required row">
                    <label className="col-sm-10 col-form-label">
                      Events held for stakeholder engagements
                    </label>
                    <div className="col-sm-10">
                      <input
                        min="0"
                        onKeyPress={preventMinus}
                        name="Events_stakeholder_engagements"
                        value={
                          generalDetail.Events_stakeholder_engagements == "0"
                            ? 0
                            : generalDetail.Events_stakeholder_engagements
                        }
                        className={`${
                          props.errors.length &&
                          validateRequiredText(
                            generalDetail.Events_stakeholder_engagements,
                            255
                          )
                            ? `form-control error`
                            : "form-control"
                        }`}
                        onBlur={handleBlur}
                        required={true}
                        maxLength={255}
                        // placeholder="Events held for stakeholder engagements"
                        onChange={handleChange}
                      />
                      <div
                        className={`${
                          props.errors.length &&
                          validateRequiredText(
                            generalDetail.Events_stakeholder_engagements,
                            255
                          )
                            ? `error`
                            : "invalid-feedback "
                        }`}
                      >
                        {validateRequiredText(
                          generalDetail.Events_stakeholder_engagements,
                          255
                        )}
                      </div>
                      {/* <small style={{color:'#ff0000'}}><i>{formErrors.Events_stakeholder_engagements}</i></small> */}
                      <small>
                        <i>as on date (in the last 3 years as on date)</i>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div style={{ marginLeft: "20px" }}>
                  <div className="form-group required row">
                    <label className="col-sm-10 col-form-label">
                      Participation in other events
                    </label>
                    <div className="col-sm-10">
                      <input
                        min="0"
                        onKeyPress={preventMinus}
                        name="Participation_Event"
                        value={
                          generalDetail.Participation_Event == "0"
                            ? 0
                            : generalDetail.Participation_Event
                        }
                        className={`${
                          props.errors.length &&
                          validateRequiredText(
                            generalDetail.Participation_Event,
                            255
                          )
                            ? `form-control error`
                            : "form-control"
                        }`}
                        onBlur={handleBlur}
                        required={true}
                        maxLength={255}
                        // placeholder="Participation in other events"
                        onChange={handleChange}
                      />
                      <div
                        className={`${
                          props.errors.length &&
                          validateRequiredText(
                            generalDetail.Participation_Event,
                            255
                          )
                            ? `error`
                            : "invalid-feedback "
                        }`}
                      >
                        {validateRequiredText(
                          generalDetail.Participation_Event,
                          255
                        )}
                      </div>
                      {/* <small style={{color:'#ff0000'}}><i>{formErrors.Participation_Event}</i></small> */}
                      <small>
                        <i>as on date (in the last 3 years as on date)</i>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div style={{ marginLeft: "30px" }}></div>
              </div>
            </div>
            <hr />
            <h5
              className="mb-2"
              style={{
                fontSize: "17px",
                fontWeight: "600",
                padding: "20px 20px",
                backgroundColor: "#f8f7f7",
              }}
            >
              Details of the incubator team as on date
            </h5>
            <div className="form-group required row">
              <label className="col-sm-4 col-form-label">
                Name & Background of the current CEO{" "}
              </label>
              <div className="col-sm-8">
                <textarea
                  name="Name_Background_current"
                  value={
                    generalDetail.Name_Background_current == "0"
                      ? 0
                      : generalDetail.Name_Background_current
                  }
                  className={`${
                    props.errors.length &&
                    validateRequiredText1(
                      generalDetail.Name_Background_current,
                      20000
                    )
                      ? `form-control error`
                      : "form-control"
                  }`}
                  // onBlur={handleBlur}
                  required={true}
                  maxLength={20000}
                  // placeholder="Name & Background of the current CEO"
                  onChange={handleChange1}
                ></textarea>
                <div
                  className={`${
                    props.errors.length &&
                    validateRequiredText1(
                      generalDetail.Name_Background_current,
                      20000
                    )
                      ? `error`
                      : "invalid-feedback "
                  }`}
                >
                  {validateRequiredText1(
                    generalDetail.Name_Background_current,
                    20000
                  )}
                </div>
                <small>
                  <i>as on date date of application</i>
                </small>
              </div>
            </div>
            <div className="form-group required row">
              <label className="col-sm-4 col-form-label">
                No. of full-time employees
              </label>
              <div className="col-sm-8">
                <IncrementDecrement
                  name="fulltime_employees_no"
                  value={
                    generalDetail.fulltime_employees_re_apply > 0
                      ? generalDetail.fulltime_employees_re_apply
                      : 0
                  }
                  onChange={(name: string, value: number) =>
                    handleIncrementDecrementChange(
                      "fulltime_employees_re_apply",
                      value
                    )
                  }
                />
                <div
                  className={`${
                    props.errors.length &&
                    props.errors.indexOf("fulltime_employees_re_apply") > -1 &&
                    generalDetail.fulltime_employees_re_apply < 1
                      ? `col-12 pl-0 error`
                      : "invalid-feedback "
                  }`}
                >
                  This is mandatory field
                </div>
              </div>
            </div>

            <div className="form-group required row">
              <label className="col-sm-4 col-form-label">
                List of all teams along with the number of full-time employees
                in each team
              </label>
              <div className="col-sm-8">
                <div className="form-row">
                  <div className="col">
                    <span className="col-label">Team Name</span>
                  </div>
                  <div className="col">
                    <span className="col-label">
                      No. of full time employees
                    </span>
                  </div>
                </div>
                {teams.map((team: TeamDetails, index: number) => (
                  <div key={`team_name_${index}`} className="form-row mb-2">
                    <div className="col-5">
                      <input type="hidden" value={team.team_id}></input>
                      <input
                        type="text"
                        name={`team_name_${index}`}
                        className={`${
                          props.errors.length &&
                          validateRequiredText(team.team_name, 255)
                            ? `form-control error`
                            : "form-control"
                        }`}
                        placeholder="Enter"
                        // value={generalDetail.mteam_name}
                        value={team.team_name}
                        onChange={(e) => handleInputChange(e, index)}
                        disabled={
                          generalDetail.fulltime_employees_re_apply == 0 ||
                          generalDetail.fulltime_employees_re_apply == null
                        }
                        // onChange={handleChange1}
                        required={true}
                        maxLength={255}
                      />
                      <div
                        className={`${
                          props.errors.length &&
                          validateRequiredText(team.team_name, 255)
                            ? `error`
                            : "invalid-feedback "
                        }`}
                      >
                        {validateRequiredText(team.team_name, 255)}
                      </div>
                    </div>

                    <div className="col-5 text-center">
                      <IncrementDecrement
                        name={`no_employee${index}`}
                        value={team.no_employee > 0 ? team.no_employee : 0}
                        onChange={(name: string, value: number) =>
                          handleIncrementDecrementChangeInput(
                            name,
                            value,
                            index
                          )
                        }
                        disabled={
                          generalDetail.fulltime_employees_re_apply == 0 ||
                          generalDetail.fulltime_employees_re_apply == null ||
                          (team.team_name != undefined &&
                            team.team_name.length == 0)
                        }
                        minValue={0}
                        maxValue={maxValue}
                      />
                    </div>
                    <div className="col-2">
                      <div className="number">
                        {teams.length > 1 && (
                          <img
                            src={DeleteRow}
                            className="img-fluid margin-top-15"
                            alt="Delete Logo"
                            onClick={() => addRemoveTeam(index)}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                {sumOfEmpVal == true ? (
                  <> </>
                ) : (
                  <>
                    <div className="form-row">
                      <div className="col">
                        <span
                          className="link-orange-text"
                          onClick={() => addRemoveTeam()}
                        >
                          <u>
                            <i className="fa fa-plus"></i> Add Team
                          </u>
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <hr />

            <h5
              className=""
              style={{
                fontSize: "17px",
                fontWeight: "600",
                color: "#2b3f88",
                padding: "20px 20px",
                backgroundColor: "#f8f7f7",
              }}
            >
              Details of funds managed and utilized by the incubator under SISFS
              as on date
            </h5>

            <h6
              className="pb-3"
              style={{
                fontWeight: "600",
                padding: "20px 20px",
                backgroundColor: "#f8f7f7",
              }}
            >
              Fund Utilization Details in SISFS as on date
            </h6>

            <div className="form-group  row">
              <label className="col-sm-4 col-form-label col_inside_lable">
                Quantum of fund disbursed
              </label>
              <div className="col-sm-8">
                <input
                  min="0"
                  name="Fund_Utilization_Details"
                  readOnly
                  value={disbursedamount == null ? 0 : disbursedamount}
                  // value={generalDetail.sum_disbured}
                  className="form-control"
                  // onBlur={handleBlur}
                  // required={true}
                  maxLength={255}
                  // placeholder="Fund Utilization Details"
                  // onChange={handleChange}
                />
                <small>
                  <i>This is automatically fetched</i>
                </small>
                {/* <small style={{color:'#ff0000'}}><i>{formErrors.Fund_Utilization_Details}</i></small> */}
                {/* <small><i>as on date, please enter 0 if not applicable</i></small> */}
              </div>
            </div>
            <div className="form-group  row">
              <label className="col-sm-4 col-form-label">
                Upload Pitch deck<span style={{ color: "#d00c0c" }}>*</span>
              </label>
              <div className="col-sm-8">
                <div className="form-row">
                  <div className="col">
                    <div className="d-inline-flex upload-pitch">
                      <span className="link-orange-text link-underline">
                        <a
                          href={
                            `${File_node_url}` +
                            "samplefiles/Incubator_Reapplication_Sample_Pitch_Deck.pptx"
                          }
                          className="link-orange-text link-underline"
                        >
                          <u>Download Sample Pitch Deck</u>
                        </a>
                      </span>
                    </div>
                    <div className="">
                      <button
                        className="btn btn-sisfs btn-transparent-primary"
                        onClick={() =>
                          setOpenUploadBlock({ pitchDocumentUpload: true })
                        }
                      >
                        Upload
                      </button>
                      {((openUploadBlock &&
                        openUploadBlock["pitchDocumentUpload"] &&
                        generalDetail.Re_apply_pitchdeck_upload.length === 0) ||
                        generalDetail.Re_apply_pitchdeck_upload.length > 0) && (
                        <>
                          <span
                            className="close"
                            onClick={() =>
                              setOpenUploadBlock({ pitchDocumentUpload: false })
                            }
                          >
                            <i className="fa fa-close"></i>
                          </span>
                          <FileUploader
                            key={Math.random().toString()}
                            id={UploadTypeEnum1.pitchDocumentUpload}
                            information="Drag & Drop the file or Browse"
                            fileType="application/pdf"
                            buttonLabel="Browse"
                            multiple={true}
                            enablePreview
                            IconComponent={() => (
                              <span className="fa fa-cloud-upload"></span>
                            )}
                            removeIconClass=""
                            existingFiles={onlypdf}
                            onUploadFinish={(files: Array<File>) => {
                              onFileUpload(
                                files,
                                UploadTypeEnum1.pitchDocumentUpload
                              );
                            }}
                            onFileDelete={(deletedFile: File) =>
                              onFileDelete(
                                deletedFile,
                                UploadTypeEnum1.pitchDocumentUpload
                              )
                            }
                          />
                        </>
                      )}
                      {typeof generalDetail.Re_apply_pitchdeck_upload_get ==
                        "string" &&
                      generalDetail.Re_apply_pitchdeck_upload_get.length > 0 ? (
                        <>
                          <div
                            style={{
                              minHeight: "fit-content",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ width: "40vh", maxWidth: "400px" }}>
                              <ListComponent
                                links={generalDetail.Re_apply_pitchdeck_upload_get.split(
                                  ","
                                ).map(
                                  (currentName: any, index: any, arr: any) => {
                                    return `${currentName.slice(1)} `;
                                  }
                                )}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      <small>
                        <i>
                          Please use the template in the Sample Pitch Deck and
                          upload in PDF format
                        </i>
                      </small>
                      <div
                        className={`${
                          props.errors.length &&
                          !generalDetail.Re_apply_pitchdeck_upload.length &&
                          !isDisabled
                            ? `col-12 pl-0 error`
                            : "invalid-feedback "
                        }`}
                      >
                        This is mandatory field
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group  row">
              <label className="col-sm-4 col-form-label">
                Please upload a performance audit report by an independent third
                party<span style={{ color: "#d00c0c" }}>*</span>
              </label>
              <div className="col-sm-8 m-auto">
                <div className="d-inline-flex upload-pitch">
                  <span className="link-orange-text link-underline">
                    <a
                      href={
                        `${File_node_url}` +
                        "samplefiles/Format_for_Incubator_Performance_Audit_Report.pdf"
                      }
                      className="link-orange-text link-underline"
                      target="_blank"
                    >
                      <u>Download Sample Incubator Performance Audit Report</u>
                    </a>
                  </span>
                </div>
                <div className="">
                  <button
                    className="btn btn-sisfs btn-transparent-primary"
                    onClick={() =>
                      setOpenUploadBlock({ performanceUpload: true })
                    }
                  >
                    Upload
                  </button>
                  {((openUploadBlock &&
                    openUploadBlock["performanceUpload"] &&
                    generalDetail.Performance_Upload.length === 0) ||
                    generalDetail.Performance_Upload.length > 0) && (
                    <>
                      <span
                        className="close"
                        onClick={() =>
                          setOpenUploadBlock({ performanceUpload: false })
                        }
                      >
                        <i className="fa fa-close"></i>
                      </span>
                      <FileUploader
                        key={Math.random().toString()}
                        id={UploadTypeEnum1.performanceUpload}
                        information="Drag & Drop the file or Browse"
                        fileType="application/pdf"
                        buttonLabel="Browse"
                        multiple={true}
                        enablePreview
                        IconComponent={() => (
                          <span className="fa fa-cloud-upload"></span>
                        )}
                        removeIconClass=""
                        existingFiles={onlypdf1}
                        onUploadFinish={(files: Array<File>) => {
                          onFileUpload(
                            files,
                            UploadTypeEnum1.performanceUpload
                          );
                        }}
                        onFileDelete={(deletedFile: File) =>
                          onFileDelete(
                            deletedFile,
                            UploadTypeEnum1.performanceUpload
                          )
                        }
                      />
                    </>
                  )}
                  {typeof generalDetail.Performance_Upload_get == "string" &&
                  generalDetail.Performance_Upload_get.length > 0 ? (
                    <>
                      <div
                        style={{
                          minHeight: "fit-content",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ width: "40vh", maxWidth: "400px" }}>
                          <ListComponent2
                            links={generalDetail.Performance_Upload_get.split(
                              ","
                            ).map((currentName: any, index: any, arr: any) => {
                              return `${currentName.slice(1)} `;
                            })}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <small>
                    <i>
                      The report shall detail the progress made by the incubator
                      with respect to the utilization of the previously approved
                      grant as per the relevant performance metrics under the
                      scheme - PDF only
                    </i>
                  </small>

                  <div
                    className={`${
                      props.errors.length &&
                      !generalDetail.Performance_Upload.length &&
                      !isDisabled
                        ? `col-12 pl-0 error`
                        : "invalid-feedback "
                    }`}
                  >
                    This is mandatory field
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group  row">
              <label className="col-sm-4 col-form-label">
                Other Documents<span style={{ color: "#d00c0c" }}>*</span>
              </label>
              <div className="col-sm-8 m-auto">
                <div className="">
                  <button
                    className="btn btn-sisfs btn-transparent-primary"
                    onClick={() =>
                      setOpenUploadBlock({ otherDocumentUpload: true })
                    }
                  >
                    Upload
                  </button>
                  {((openUploadBlock &&
                    openUploadBlock["otherDocumentUpload"] &&
                    generalDetail.Other_document_upload.length === 0) ||
                    generalDetail.Other_document_upload.length > 0) && (
                    <>
                      <span
                        className="close"
                        onClick={() =>
                          setOpenUploadBlock({ otherDocumentUpload: false })
                        }
                      >
                        <i className="fa fa-close"></i>
                      </span>
                      <FileUploader
                        key={Math.random().toString()}
                        id={UploadTypeEnum1.otherDocumentUpload}
                        information="Drag & Drop the file or Browse"
                        fileType="application/pdf"
                        buttonLabel="Browse"
                        multiple={true}
                        enablePreview
                        IconComponent={() => (
                          <span className="fa fa-cloud-upload"></span>
                        )}
                        removeIconClass=""
                        existingFiles={onlypdf2}
                        onUploadFinish={(files: Array<File>) => {
                          onFileUpload(
                            files,
                            UploadTypeEnum1.otherDocumentUpload
                          );
                        }}
                        onFileDelete={(deletedFile: File) =>
                          onFileDelete(
                            deletedFile,
                            UploadTypeEnum1.otherDocumentUpload
                          )
                        }
                      />
                    </>
                  )}
                  {typeof generalDetail.Other_document_upload_get == "string" &&
                  generalDetail.Other_document_upload_get.length > 0 ? (
                    <>
                      <div
                        style={{
                          minHeight: "fit-content",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ width: "40vh", maxWidth: "400px" }}>
                          <ListComponent3
                            links={generalDetail.Other_document_upload_get.split(
                              ","
                            ).map((currentName: any, index: any, arr: any) => {
                              return `${currentName.slice(1)} `;
                            })}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <small>
                    <i>Maxi. upload upto 10 documents</i>
                  </small>
                  <div
                    className={`${
                      props.errors.length &&
                      !generalDetail.Other_document_upload.length &&
                      !isDisabled
                        ? `col-12 pl-0 error`
                        : "invalid-feedback "
                    }`}
                  >
                    This is mandatory field
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="stepper-footer-section">
            <div>
              <div
                className="btn-toolbar justify-content-between"
                role="toolbar"
                aria-label="Toolbar with button groups"
              >
                <button
                  className="btn btn-sisfs btn-transparent-primary"
                  onClick={() => applicationPage()}
                >
                  Cancel
                </button>
                <span className="stepper-page-count pt-2">Step 1 / 2</span>
                <div className="btn-group">
                  <button
                    type="button"
                    className="btn btn-sisfs btn-yellow-primary"
                    onClick={onSave}
                  >
                    Save And Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
}
export default CurrentApplicationForm;
