import React, { useEffect, useState } from 'react';
import './App.css';
import { Redirect, BrowserRouter as Router, useHistory } from "react-router-dom";
import RouterComponent from './routes/RouterComponent';
import { getSessionStorageValue } from './effects/utils/session-storage';
import { useAuthStateValue } from './providers/AuthStateProvider';
import { Action } from './reducers/reducer.action';
import UserInfoModel from './models/UserInfoModel';
import 'font-awesome/css/font-awesome.min.css';
import * as Crypto from 'crypto-js';
import { AuthProvider } from './Contexts/AuthContext';
import ProfileContainer from './components/common/header/ProfileContainer';
export const DATA_SECRET = 'sisfs123dash@-_!app';
function App() {
  //@ts-ignore
  const [{ }, dispatch] = useAuthStateValue();
  const [loader,setLoader] = useState(false);
  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
  ];
  let timer: any;
  useEffect(() => {
    setLoader(true);
    setTimeout(() => {
      const userString = getSessionStorageValue('user');
      if (userString) {

        dispatch({
          type: Action.SET_USER,
          payload: JSON.parse(userString)
        })
      }
      setLoader(false);
    }, 3000)

    sessionValue();
  //   window.onbeforeunload = function() {
  //     localStorage.clear();
  //  }
  }, [])
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      window.removeEventListener("visibilitychange", resetTimer)
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      const user: any = getSessionStorageValue('user');
      if (user) {
        localStorage.setItem("inactivity", "true")
      }
    }, 60 * 60 * 1000);
  };

  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };
  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
      // window.addEventListener("visibilitychange", () => { handleVisibilityChange() })
    });
  }, []);
  const handleVisibilityChange = () => {
    if (document.visibilityState == "hidden") {
      resetTimer();
      handleLogoutTimer();
    }
  }
  const sessionValue = () => {
    const lsValue = localStorage.getItem("user");

    if (lsValue) {
      const decryptedValue = Crypto.AES.decrypt(lsValue, DATA_SECRET);

      if (decryptedValue) {
        // const vv = decryptedValue.toString(Crypto.enc.Utf8)

        // if (vv) {
        //   let key = JSON.parse(vv)
        //   const tokenId = JSON.parse(Buffer.from(key.accessToken.split('.')[1], 'base64').toString())
        //   let timestamp = tokenId.exp;
        //   let getTime = new Date(timestamp * 1000);

        //   return vv;
        // }
        // else {
        //   return null;
        // }

      }
      else {
        return null;
      }
    } else {
      return null;
    }
  }

  return (
    <div className="App">

      {/* Overlay added when login screen is opened */}
      <div id="overlay myComponentContainer">&nbsp;</div>
      <AuthProvider>
        <Router>
          {loader &&
        <div className="spinner-border custom-spinner" role="status">
          <span className="sr-only">Loading...</span>
        </div>}
        <fieldset disabled={loader}>

          <RouterComponent />
        </fieldset>

        </Router>
      </AuthProvider>

    </div>
  );
}

export default App;
