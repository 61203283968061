import React, { useEffect, useState } from 'react';
import { FUNDDETAILS_GET_API_FILEUPLOAD, SEC_RETURNS_EXCEL_DOWNLOAD, SEC_RETURNS_FORM_DATA, SEC_RETURNS_FORM_Delete, SEC_RETURNS_FORM_Edit, SEC_RETURNS_GET_FORM_DATA } from '../../../../constants/api';
import { get, post } from '../../../../effects/api/api.common';
import { getSessionStorageValue } from '../../../../effects/utils/session-storage';
import moment from 'moment';
import Remove from '../../../../images/ReturnsIcons/trash-bin.svg';
import SuccessCheck from '../../../../images/ReturnsIcons/check (2).svg';
import Edit from '../../../../images/ReturnsIcons/edit.svg';
import { changeNumberFormat } from '../../../../utils/CommonMethods';
import ConfirmationDialog from '../../../common/controls/ConfirmationDialog';
import Allround from '../../../../images/Allround.svg'
import View from "../../../../images/ReturnsIcons/eye.svg"
import IconDownload from "../../../../images/ReturnsIcons/downloads.svg"
declare var $: any;
function MergedRetrnsDetailsComponent(props: any) {
    // console.log(props);

    useEffect(() => {
        formGetAPI();
    }, []);

    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    const [errors, setErrors] = useState<any>({});
    const [errors2, setErrors2] = useState<any>({});
    const [disabled_part2, setdisabled_part2] = useState(null);
    const [returns_reported_Data, setreturns_reported_Data] = useState();
    const [PrincipleCount, setPrincipleCount] = useState();
    const [InterestCount, setInterestCount] = useState();
    const [EquityCount, setEquityCount] = useState();
    const [SecondaryCount, setSecondaryCount] = useState();
    const [TotalApprovedAmount, setTotalApprovedAmount] = useState<any>();
    const [OtherCount, setOtherCount] = useState();
    const [TraUploadfilesData, setTraUploadfilesData] = useState<any>("");
    const [showIncubatorSuccessModal, setShowIncubatorSuccessModal] = useState(false);
    const [dialogData, setDialogData] = useState({
        header: '',
        text: '',
    });
    const [showIncubatorFailureModal, setShowIncubatorFailureModal] = useState(false);
    const [failDialogData, setFailDialogData] = useState({
        header: '',
        text: '',
    });
    const [returnsFormSubmit, setreturnsFormSubmit] = useState<any>({
        approve_date: '',
        approved_amount: '',
    })
    const [loader, setLoader] = useState(false);
    const [returnsForm, setreturnsForm] = useState<any>({
        approve_date: '',
        approved_amount: '',
        updated_at: '',
    })

    const handleChange = (event: any) => {
        const { name, value, type } = event.target;
        const newValue = value.replace(/[^0-9]/ig, '');

        setreturnsFormSubmit((prevFormData: any) => ({
            ...prevFormData,
            [name]: newValue,
        }));
    };

    const handleChange22 = (e: any, Id: any) => {
        const { name, value, type } = e.target;
        // console.log(e.target);
        const editValue = value.replace(/[^0-9]/ig, '');

        var editData: any = []
        editData = returnsForm.map((item: any) =>
            item.id === Id && name ? { ...item, [name]: editValue } : item
        )
        setreturnsForm(editData);
        console.log(editData)

    }

    const formGetAPI = () => {
        setLoader(true);
        get(`${SEC_RETURNS_GET_FORM_DATA}/${props.Incubatorid.location.state.userId}`).then((res: any) => {
            // console.log(res.data.all_reported_returns);
            setLoader(false);
            const sum = res.data.all_reported_returns.reduce((total: any, obj: any) => total + obj.count, 0);
            setTotalApprovedAmount(sum);

            res.data.all_reported_returns.map((ele: any) => {
                if (ele.type_of_return == "principai") {
                    setPrincipleCount(ele.count)
                } else if (ele.type_of_return == "intrest") {
                    setInterestCount(ele.count)
                } else if (ele.type_of_return == "secondary_sale_of_instrument") {
                    setSecondaryCount(ele.count)
                } else if (ele.type_of_return == "other") {
                    setOtherCount(ele.count)
                } else if (ele.type_of_return == 'sale_of_equity') {
                    setEquityCount(ele.count)
                }
            })
            setreturns_reported_Data(res.data.approved_returns)
            setreturnsForm(res.data.data);
            setdisabled_part2(null);
            console.log(res.data.data)

        })
    }

    const validateForm = () => {
        const newErrors: any = {};

        if (!returnsFormSubmit.approved_amount) {
            newErrors.approved_amount = 'Amount is required';
        } if (!returnsForm.approved_amount) {
            newErrors.approved_amount = 'Amount is required';
        }

        setErrors(newErrors);
    };

    const ApprovalClick = (e: any) => {
        e.preventDefault();
        // if (!returnsFormSubmit.approved_amount) {
        //     validateForm();
        //     return;
        // } else {
        let payload = {
            "approved_amount": returnsFormSubmit.approved_amount,
            "incubator_id": props.Incubatorid.location.state.userId,
            "logged_user_id": userInfo.id
        }
        setLoader(true);
        post(SEC_RETURNS_FORM_DATA, payload).then((response: any) => {
            setLoader(false);
            // console.log(response.data);
            if (response.data.result == "success") {
                setDialogData({
                    header: 'Thank You!',
                    text: response.data.message,
                });
                setShowIncubatorSuccessModal(true)
            } else {
                setFailDialogData({
                    header: '',
                    text: response.data.message,
                });
                setShowIncubatorFailureModal(true);
            }
        });
        // }
    };

    const validateForm2 = () => {
        const newErrors2: any = {};

        if (!returnsForm.approved_amount) {
            newErrors2.approved_amount = 'Amount is required';
        }

        setErrors2(newErrors2);
    };

    const SaveReturnsTab = (e: any, item: any, i: any) => {
        e.preventDefault();
        // console.log(returnsForm[0].approved_amount);
        // console.log(item.approved_amount)
        // return;
        let payload = {
            "return_id": item.id,
            "logged_user_id": userInfo.id,
            "incubator_id": props.Incubatorid.location.state.userId,
            "approved_amount": item.approved_amount,
        }
        setLoader(true);
        post(SEC_RETURNS_FORM_Edit, payload).then((response: any) => {
            setLoader(false);
            // console.log(response);
            if (response.data.result == "success") {
                if (response.data.result == "success") {
                    setDialogData({
                        header: 'Thank You!',
                        text: response.data.message,
                    });
                    setShowIncubatorSuccessModal(true)
                } else {
                    setFailDialogData({
                        header: '',
                        text: response.data.message,
                    });
                    setShowIncubatorFailureModal(true);
                }
            }
        });
    }


    const DeleteClick = (item: any, i: any) => {
        let payload = {
            "return_id": item.id
        }
        // console.log(payload);
        setLoader(true);
        post(SEC_RETURNS_FORM_Delete, payload).then((response: any) => {
            setLoader(false);
            // console.log(response);
            if (response.data.result == "success") {
                if (response.data.result == "success") {
                    setDialogData({
                        header: 'Thank You!',
                        text: response.data.message,
                    });
                    setShowIncubatorSuccessModal(true)
                } else {
                    setFailDialogData({
                        header: '',
                        text: response.data.message,
                    });
                    setShowIncubatorFailureModal(true);
                }
            }
        });
    }

    const dismissModal = () => {
        window.location.reload();
    };

    const DownloadExcel = () => {
        setLoader(true);
        post(SEC_RETURNS_EXCEL_DOWNLOAD, { "incubatorId": props.Incubatorid.location.state.userId }).then((res: any) => {
            setLoader(false);
            // console.log(res.data.data);
            window.open(res.data.data);
        })
    }

    const DownloadDifferFiles = () => {
        setLoader(true);
        get(`${FUNDDETAILS_GET_API_FILEUPLOAD}/${props.Incubatorid.location.state.userId}`).then((res: any) => {
            setLoader(false);
            console.log(res.data, "returnss")
            setTraUploadfilesData(res.data.data);
            $('#Upload_TRA_Popup').modal('show');
        })
    }
    const handleFileDowload = (file: any) => {
        // console.log(file);
        // window.open(file.file_path);
        // const link = document.createElement('a');
        // link.href = file.file_path;
        // link.download = file.file;
        // link.target = '_blank';
        // link.click();
        fetch(file.file_path).then(response => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = file.file;
                a.click();
            });
            window.location.href = response.url;
        });
    }
    return (
        <>
        {loader && (
          <div className="spinner-border custom-spinner" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}
           <fieldset disabled={loader}>
           <div>
                <div className="row">
                    <div className="col-6 text-center" style={{ wordWrap: 'break-word' }}>
                        <span className="fund-amount-cur">
                            &#8377;
                            <label className="fund-amount-label-value">
                                {' '}
                                {returns_reported_Data != null && returns_reported_Data != "0"
                                    ? changeNumberFormat(Number(returns_reported_Data), 2, 1)
                                    :
                                    0
                                }
                            </label>
                        </span>
                        <br />
                        <span className="fund-amount-label-new" style={{ fontSize: '14px' }}>Total Returns Approved(by the Secretariat/EAC/DPIIT)</span>
                    </div>
                    <div className="col-6" style={{ textAlignLast: 'end', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <button className="btn btn-sisfs btn-yellow-primary mr-3" data-target="#ApproveReturn" data-toggle="modal">Approve Returns</button>
                    </div>

                </div>
                <br />
                <hr />

                <div className='container'>
                    <div className='row'>
                        <div className='col-1'></div>
                        <div className='col-4' style={{ marginTop: "auto", marginBottom: "auto", textAlign: "center", wordWrap: 'break-word' }}>
                            <span className="fund-amount-cur">
                                &#8377;
                                <label className="fund-amount-label-value" >
                                    {' '}
                                    {TotalApprovedAmount != null && TotalApprovedAmount != "0"
                                        ? changeNumberFormat(Number(TotalApprovedAmount), 2, 1)
                                        :
                                        0
                                    }
                                </label>
                            </span>
                            <br />
                            <span className="fund-amount-label-new" style={{ fontSize: '14px' }}>Amount of Total Returns Reported By Incubator</span>
                        </div>
                        <div className='col-1'></div>
                        <div className="vl-mr" style={{ height: "220px", borderLeft: '1px solid #E0E0E0' }}></div>
                        {/* <div className='col-1'></div> */}
                        <div className='col-5'>
                            <div className='row'>
                                <div className='col-12 mt-3 d-flex justify-content-between'>
                                    <h6><b>Principle</b></h6>
                                    <b style={{ color: "rgb(112 100 201)" }}>
                                        &#8377;{PrincipleCount == undefined
                                            ? 0
                                            : changeNumberFormat(Number(PrincipleCount), 2, 1)}
                                    </b>
                                </div>
                                <div className='col-12 mt-3 d-flex justify-content-between'>
                                    <h6><b>Interest</b></h6>
                                    <b style={{ color: "rgb(112 100 201)" }}>
                                        &#8377; {InterestCount == undefined
                                            ? 0
                                            : changeNumberFormat(Number(InterestCount), 2, 1)}
                                    </b>
                                </div>
                                <div className='col-12 mt-3 d-flex justify-content-between'>
                                    <h6><b>Equity</b></h6>
                                    <b style={{ color: "rgb(112 100 201)" }}>
                                        &#8377; {EquityCount == undefined
                                            ? 0
                                            : changeNumberFormat(Number(EquityCount), 2, 1)}
                                    </b>
                                </div>
                                <div className='col-12 mt-3 d-flex justify-content-between'>
                                    <h6><b>Secondary Sale</b></h6>
                                    <b style={{ color: "rgb(112 100 201)" }}>
                                        &#8377; {SecondaryCount == undefined
                                            ? 0
                                            : changeNumberFormat(Number(SecondaryCount), 2, 1)}
                                    </b>
                                </div>
                                <div className='col-12 mt-3 d-flex justify-content-between'>
                                    <h6><b>Other</b></h6>
                                    <b style={{ color: "rgb(112 100 201)" }}>
                                        &#8377; {OtherCount == undefined
                                            ? 0
                                            : changeNumberFormat(Number(OtherCount), 2, 1)}
                                    </b>
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-1'></div> */}
                    </div>
                    <br />
                    <div className='row text-center'>
                        <div className='col-md'>
                            <button className='btn btn_Download_returns btn-yellow-primary' onClick={DownloadExcel}>Download Return Reports of All Startup</button>
                        </div>
                        <div className='col-md'>
                            <button className='btn btn_Download_returns btn-yellow-primary' onClick={DownloadDifferFiles}>Download TRA Statement</button>
                        </div>
                    </div>
                    <hr />
                </div>


                {/* Approved Returns Popup */}
                <div
                    className="modal fade"
                    id="ApproveReturn"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                    data-backdrop="static"
                    data-keyboard="false"
                >
                    <div
                        className="modal-dialog modal-dialog-centered modal-lg"
                        role="document"
                    >
                        <div className="modal-content">
                            <div className="modal-header modal-header-border-none ml-18px">
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true" onClick={() => window.location.reload()}>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="container">
                                    <div>
                                        <div className='d-flex flex-row justify-content-start'>
                                            <h6 className="modal-title pl-2" id="exampleModalLongTitle">
                                                Approve A New Return<span></span>
                                            </h6>
                                        </div>
                                        <form className="mb-3" onSubmit={ApprovalClick}>
                                            <div className="row">
                                                <div className="col-md-4 mt-3">
                                                    <label className="form-label required" style={{ float: 'left' }}>
                                                        <b>Date</b>
                                                    </label>
                                                    <br />
                                                    <input
                                                        type="date"
                                                        name="approve_date"
                                                        className="form-control"
                                                        // value={returnsFormSubmit.date_of_transaction}
                                                        value={new Date().toISOString().slice(0, 10)}
                                                        onChange={handleChange}
                                                        placeholder="select Date"
                                                        disabled={true}
                                                    />
                                                </div>

                                                <div className="col-md-4 mt-3">
                                                    <label className="form-label required" style={{ float: 'left' }}>
                                                        <b>Amount</b>
                                                    </label>
                                                    <br />
                                                    <input
                                                        min="0"
                                                        name="approved_amount"
                                                        value={returnsFormSubmit.approved_amount}
                                                        className="form-control"
                                                        onChange={handleChange}
                                                        required={true}
                                                        maxLength={255}
                                                        placeholder="Enter Amount"
                                                    />
                                                    {/* {errors.approved_amount && <small style={{ color: 'red', float: "left" }}>{errors.approved_amount}</small>} */}
                                                </div>
                                                <div className="col-md-4" style={{ marginTop: '40px' }}>
                                                    <button className="btn btn-sisfs btn-yellow-primary" type='submit'>Approval</button>
                                                </div>
                                            </div>
                                        </form>
                                        <hr />

                                        <div className='d-flex flex-row justify-content-start'>
                                            <h6 className="modal-title pl-2" id="exampleModalLongTitle">
                                                Historical Approved Returns<span></span>
                                            </h6>
                                        </div>
                                        {returnsForm != undefined && returnsForm.length > 0
                                            ? returnsForm.map((item: any, i: any) => (
                                                <div>
                                                    <form className="mb-3" onSubmit={(e) => SaveReturnsTab(e, item, i)}>
                                                        <div className="row">
                                                            <div className="col-md-3 mt-3">
                                                                <label className="form-label required" style={{ float: 'left' }}>
                                                                    <b>Date of Approval</b>
                                                                </label>
                                                                <br />
                                                                <input
                                                                    type="date"
                                                                    name="approve_date"
                                                                    className="form-control"
                                                                    value={item.approve_date.slice(0, 10)}
                                                                    onChange={(e) => handleChange22(e, item.id)}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                            <div className="col-md-3 mt-3">
                                                                <label className="form-label required" style={{ float: 'left' }}>
                                                                    <b>Amount</b>
                                                                </label>
                                                                <br />
                                                                <input
                                                                    min="0"
                                                                    name="approved_amount"
                                                                    value={item.approved_amount}
                                                                    className="form-control"
                                                                    onChange={(e) => handleChange22(e, item.id)}
                                                                    required
                                                                    maxLength={255}
                                                                    disabled={i != disabled_part2 && true}
                                                                    placeholder='Enter Amount'
                                                                />
                                                                {/* {errors2.approved_amount && <small style={{ color: 'red', float:"left"}}>{errors2.approved_amount}</small>} */}
                                                            </div>
                                                            <div className="col-md-3 mt-3">
                                                                <label className="form-label required" style={{ float: 'left' }}>
                                                                    <b>Last Edited Date</b>
                                                                </label>
                                                                <br />
                                                                <input
                                                                    type="date"
                                                                    name="updated_at"
                                                                    className="form-control"
                                                                    value={item.updated_at.slice(0, 10)}
                                                                    onChange={(e) => handleChange22(e, item.id)}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                            <div className="col-md-3" style={{ marginTop: '55px' }}>
                                                                {i == disabled_part2 && true && <button type='submit' style={{ border: "none", background: "none" }}><img src={SuccessCheck} alt="SISFS Logo" style={{ marginRight: "15px", cursor: 'pointer' }} /></button>}
                                                                {i != disabled_part2 && true && <img src={Edit} alt="SISFS Logo" style={{ marginRight: "15px", cursor: 'pointer' }} onClick={() => { setdisabled_part2(i) }} />}
                                                                <img src={Remove} alt="SISFS Logo" style={{ cursor: 'pointer' }} onClick={() => DeleteClick(item, i)} />
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            ))
                                            : ''}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Uplod Files View Popup */}
                <div
                    className="modal fade"
                    id="Upload_TRA_Popup"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                    data-backdrop="static"
                    data-keyboard="false"
                >
                    <div
                        className="modal-dialog modal-dialog-centered modal-lg"
                        role="document"
                    >
                        <div className="modal-content">
                            <div className="modal-header modal-header-border-none ml-18px">
                                <h4 style={{ marginTop: "10px" }}>Uploaded Files</h4>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true" data-dismiss="modal" >&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="container">
                                    <div>
                                        <div className='row text-center'>
                                            {/* {console.log(TraUploadfilesData)} */}
                                            {TraUploadfilesData && TraUploadfilesData.length > 0 ? TraUploadfilesData.map((file: any, index: any) => (<div className='col-md-3 mt-5'>
                                                {/* {console.log(file)} */}
                                                <img src={Allround} alt="SISFS Logo" />
                                                <br />
                                                {file.file}
                                                <div>
                                                    <a href={file.file_path} target="_blank" rel="noopener noreferrer">
                                                        <img src={View} alt="SISFS Logo" style={{ marginRight: "15px", cursor: 'pointer', height: '17px' }} />
                                                    </a>
                                                    <img src={IconDownload} alt="SISFS Logo" style={{ cursor: 'pointer', height: '25px' }} onClick={() => handleFileDowload(file)} />
                                                </div>
                                            </div>)) : <div style={{ color: "red", fontSize: '35px', marginLeft: "auto", marginRight: "auto" }}> No Files</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showIncubatorSuccessModal && (
                    <ConfirmationDialog
                        headerText={dialogData.header}
                        bodyText={dialogData.text}
                        onDialogClose={dismissModal}
                    />
                )}
                {showIncubatorFailureModal && (
                    <ConfirmationDialog
                        headerText={failDialogData.header}
                        bodyText={failDialogData.text}
                        onDialogClose={() => setShowIncubatorFailureModal(false)}
                        iconClass="test"
                    />
                )}

            </div>
           </fieldset>
        </>
    )
}
export default MergedRetrnsDetailsComponent;