import React from 'react';
import homeseedfundneed from '../../images/seedfund-need.svg';
import objectives from '../../images/objectives.svg';
import { Link } from 'react-router-dom';

function HomeInitiativeComponent() {
    return (
        <section className="section-container section-bg-color">

            <div className="container">
                <div className="section-initiative">
                    <div className="section-heading">
                        <h2>An initiative to spur entrepreneurship across India</h2>
                    </div>

                    <div className="row justify-content-center pt-3 pb-3">
                        <div className="col-xs-12 col-lg-6">
                            <h3 className="sub-heading">
                                The need for Startup India Seed Fund Scheme
                            </h3>
                            <div className="section-content">
                                <p>Easy availability of capital is essential for entrepreneurs at the early stages of growth of an enterprise. </p>
                                <p>Funding from angel investors and venture capital firms becomes available to startups only after the proof of concept has been provided. Similarly, banks provide loans only to asset-backed applicants.</p>
                                <p>It is essential to provide seed funding to startups with an innovative idea to conduct proof of concept trials.</p>
                            </div>
                        </div>
                        <div className="col-xs-12 col-lg-6">
                            <div className="section-image">
                                <div className="item-center height-150">
                                    <img className="img-fluid" src={homeseedfundneed} alt="Seed Fund Need" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center pt-3 pb-3">
                        <div className="col-xs-12 col-lg-6">
                            <div className="d-none d-sm-none d-md-none d-lg-block">

                                <div className="section-image">
                                    <div className="item-center height-150">
                                        <img className="img-fluid" src={objectives} alt="Objectives of the scheme" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-lg-6">
                            <h3 className="sub-heading">
                                Objective of the scheme
                            </h3>
                            <div className="section-content">
                                <p>Startup India Seed Fund Scheme (SISFS) aims to provide financial assistance to startups for proof of concept, prototype development, product trials, market entry and commercialization.</p>
                                <p>This would enable these startups to graduate to a level where they will be able to raise investments from angel investors or venture capitalists or seek loans from commercial banks or financial institutions.</p>
                            </div>
                        </div>

                        <div className="d-block d-sm-block d-md-block d-lg-none">
                            <div className="col-xs-12 col-lg-6">
                                <div className="section-image">
                                    <div className="item-center height-150">
                                        <img className="img-fluid" src={objectives} alt="Objectives of the scheme" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-action pt-3 pb-3">
                    <Link className="btn btn-large btn-sisfs btn-transparent-primary" to="/about">
                        Know more about the scheme
                </Link>
                </div>

            </div>

        </section>
    )
}

export default HomeInitiativeComponent;