import React from "react";

function FileUploadInfo(props: any) {
  return (
    <>
      <br />
      {props.fileName == "Utilization" && (
        <>
          <div className="row" style={{ textAlign: "center" }}>
            <div className="col-12 text-center">
              <a
                href="https://drive.google.com/drive/folders/1C4I7hqzMnLDm925T-bGl7v2K25-fy-Mk"
                target="_blank"
              >
                https://drive.google.com/drive/folders/1C4I7hqzMnLDm925T-bGl7v2K25-fy-Mk
              </a>
            </div>
          </div>
          <div className="row pl-5 pr-5 pointsList">
            <ul className="point-list">
              <li className="point">Must be on a CA Letterhead</li>
              <li className="point">Point no. 2 should be "Non-Recurring”.</li>
              <li className="point">
                Grant received during the year = Grant + Management Fees
              </li>
              <li className="point">
                The closing balance must match the balance as per the bank
                statement.
              </li>
              <li className="point">
                In case of a request for the third tranche, the opening balance
                should match the closing balance of the UC submitted for the
                second tranche
              </li>
              <li className="point">
                Shall be Signed and Stamped by the CA and Incubator
              </li>
              <li className="point">
                Mention interest earned from both startups and bank
              </li>
              <li className="point">Blank Spaces to be filled – NIL</li>
              <li className="point">
                Add a table with startups name, DIPP number, Amount approved,
                Instrument and tranches approved, tranche wise fund disbursement
                and date of disbursement as an annexure to UC
                <div className="pointlistTable" style={{ overflowX: "auto" }}>
                  <table className="infoTable" style={{ fontSize: "12px" }}>
                    <thead className="table-bordered table-head">
                      <tr>
                        <th className="table-head">Name of Startup</th>
                        <th className="table-head">DIPP Number</th>
                        <th className="table-head">Amount Approved</th>
                        <th className="table-head">Instrument Approved</th>
                        <th className="table-head">Number of Tranches</th>
                        <th className="table-head">Fund Disbursed</th>
                        <th className="table-head">Tranche 1</th>
                        <th className="table-head">Date of Disbursement</th>
                        <th className="table-head">Tranche 2</th>
                        <th className="table-head">Date of Disbursement</th>
                      </tr>
                    </thead>
                    <tbody className="table-data">
                      <tr>
                        <td className="table-data"></td>
                        <td className="table-data"></td>
                        <td className="table-data"></td>
                        <td className="table-data"></td>
                        <td className="table-data"></td>
                        <td className="table-data"></td>
                        <td className="table-data"></td>
                        <td className="table-data"></td>
                        <td className="table-data"></td>
                        <td className="table-data"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </li>

              {/* <li>
                                In case of realised returns from startups, add details in column 4 – Returns earned on
                                utilized funds
                            </li> */}
              <li>
                In case of more than one document provide a merged copy of all
                documents.
              </li>
            </ul>
          </div>
          <div className="row" style={{ textAlign: "center" }}>
            <div className="col-12 text-center">
              <a
                href="https://drive.google.com/drive/folders/1hSZNbGTRfO1EqX0gT5zAQtOCyk4MEi47"
                target="_blank"
              >
                https://drive.google.com/drive/folders/1hSZNbGTRfO1EqX0gT5zAQtOCyk4MEi47
              </a>
            </div>
          </div>
          <div className="row pl-5 pr-5 mt-2">
            <ul className="point-list">
              <li>
                Please refer to the sample formats (Please note that sample docs
                shall be used for reference purposes only and changes may be
                needed depending on case-to-case)
              </li>
              <li>
                Create a table of broad head-wise utilisation of management fee
                (Refer to Statement of Expenditure for Startup India Seed Fund
                Scheme – Management Fee)
              </li>
              <li>
                The total amount of seed fund utilization and management fee
                utilization in the SoE must match with the closing balance in
                the UC and bank statement.
              </li>
              <li>
                The Audited statement of SISFS Management Fees and Seed Fund
                needs to be signed and stamped by the CA and incubator on CA
                letterhead.
              </li>
            </ul>
          </div>
        </>
      )}
      {props.fileName == "Audited" && (
        <>
          <div className="row pl-5 pr-5">
            <ul className="point-list">
              {/* <li className="point">Please refer to the sample formats (Please note that sample docs shall be used for reference purposes only and changes may be needed depending on case-to-case)</li>
                            <li className="point">Create a table of broad head-wise utilisation of management fee (Refer to Statement of Expenditure for Startup India Seed Fund Scheme – Management Fee)</li> */}
              <li className="point">
                Bank Statement should carry the stamp of the Bank. The bank
                statement should be from the date of opening the TRA account
                till date of UC
              </li>
              {/* <li className="point">The total amount of seed fund utilization and management fee utilization in the SoE must match with the closing balance in the UC and bank statement</li>
                            <li className="point">The Audited statement of SISFS Management Fees and Seed Fund needs to be signed and stamped by the CA and incubator on CA letterhead</li> */}
              <li className="point">
                In case of credit transactions, prepare a declaration for all
                credit entries on incubator letterhead along with supporting
                vouchers
              </li>
            </ul>
          </div>
        </>
      )}
      {props.fileName == "Interest" && (
        <>
          <div className="row pl-5 pr-5">
            <ul className="point-list">
              <li className="point">
                {" "}
                Create a declaration stating the interest received on the bank
                deposits duly signed and stamped by Incubator and on letter head
                of the Incubator
              </li>
              <li className="point">
                {" "}
                Add a table in case of multiple entries and provide date and
                transaction-wise breakup
              </li>
              <li className="point">
                In case of no interest earned, kindly mention the same in the
                declaration, stamped and signed by Incubator
              </li>
            </ul>
          </div>
        </>
      )}
      {props.fileName == "Ismc" && (
        <>
          <div className="row pl-5 pr-5">
            <ul className="point-list">
              <li className="point">On Incubator Letterhead</li>
              <li className="point">
                Add the following details:
                <ol>
                  <li>Attendee's (ISMC Member’s) name</li>
                  <li>Date of the Meeting</li>
                  <li>
                    List of startups that presented (Name, DPIIT No.,
                    Application Date)
                  </li>
                  <li>Result for each startup (selection/rejection)</li>
                </ol>
              </li>
              <li className="point">
                For selected startups, please add the following details:
                <ol>
                  <li>Reason for selection / ISMC Remarks/ Evaluation</li>
                  <li>Amount approved to each startup</li>
                  <li>Instrument Type</li>
                  <li>Rate of interest wherever applicable</li>
                  <li>Tenure</li>
                  <li>Number of tranches</li>
                  <li>Amount per tranche and any other relevant information</li>
                </ol>
              </li>
              <li>
                {" "}
                For rejected startup, please mention the reason for rejection/
                ISMC Remarks/ Evaluation
              </li>
              <li>
                The minutes must be either e-signed or physically signed. Email
                approvals are not accepted
              </li>
            </ul>
          </div>
        </>
      )}
      {props.fileName == "Achievement" && (
        <>
          <div className="row" style={{ textAlign: "center" }}>
            <div className="col-12 text-center">
              <a
                href="https://drive.google.com/drive/folders/1to65YjOp130yeBrb5iPRBwh8xU_Iqv7F"
                target="_blank"
              >
                https://drive.google.com/drive/folders/1to65YjOp130yeBrb5iPRBwh8xU_Iqv7F
              </a>
            </div>
          </div>
          <div className="row pl-5 pr-5">
            <ul className="point-list">
              <li className="point">
                This is the milestone achievement report of the incubator
              </li>
              <li className="point">
                A comparison of the annual targets mentioned in the Pitch Deck
                (At the time of application to the scheme) and the actual
                figures needs to be attached
              </li>
              <li className="point">
                The details must be quantitative in nature
              </li>
              <li className="point">On Incubator Letterhead</li>
              <li className="point">Signed and stamped by the Incubator</li>
            </ul>
          </div>
          {/* <div className="row pl-5 pr-5">
                        <ul className="point-list">
                            <li className="point">On Incubator Letterhead – ISMC Meeting wise</li>
                            <li className="point">Include separate columns for Startup Name, DPIIT Number, Application Date, Selection
                                Date, Startup’s Bank Account Details, Funds Approved, Instrument Type, Number of
                                Tranches, Amount Disbursed Per Tranche, Date of Tranche Disbursal, Total Funds
                                Disbursed
                            </li>
                            <li className="point">Signed and stamped by Incubator</li>
                        </ul>


                    </div> */}
        </>
      )}
      {props.fileName == "Debenture" && (
        <>
          <div className="row pl-5 pr-5">
            <ul className="point-list">
              <li className="point">On Incubator Letterhead </li>
              <li className="point">Date, Sign and stamp of the Incubator</li>
              <li className="point">
                {" "}
                Include the following details of startups funded using debt or
                debt-linked instruments like CCDs or OCDs-
                <ol>
                  <li>
                    Startup Name, DPIIT Number, Application Date, Selection
                    Date, Funds Approved, Funds Disbursed, Instrument Type,
                    Number of Tranches, Amount Disbursed Per Tranche, Date of
                    Tranche Disbursal, Return on Investment (if any), Total
                  </li>
                </ol>
              </li>
              <li className="point">
                Add a declaration stating the interest / return realised from
                each startup. In case of no realised return, declaration stating
                the same must be provided
              </li>
              <li className="point">
                It may indicatively read as: “This is to certify that ABC
                Incubator has not earned any interest on the debt or debt-linked
                instruments under the Startup India Seed Fund Scheme from DPIIT,
                Ministry of Commerce and Industry, Government of India.”
              </li>
            </ul>
          </div>
        </>
      )}
      {props.fileName == "Disbursed" && (
        <>
          <div className="row pl-5 pr-5">
            <ul className="point-list">
              <li className="point">On Incubator Letterhead</li>
              <li className="point"> Date, sign and stamp of the Incubator</li>
              <li className="point">
                {" "}
                Include the following details of startups funded using grant
                instruments –
                <ol>
                  <li>
                    Startup Name, DPIIT Number, Application Date, Selection
                    Date, Funds Approved, Funds Disbursed, Instrument Type,
                    Number of Tranches, Amount Disbursed Per Tranche, Date of
                    Tranche Disbursal, Net Total
                  </li>
                </ol>
              </li>
              <li>Add a declaration stating NO return earned on the grants</li>
            </ul>
          </div>
        </>
      )}
      {props.fileName == "Declaration" && (
        <>
          <div className="row pl-5 pr-5">
            <ul className="point-list">
              <li className="point">On Incubator Letterhead</li>
              <li className="point"> Date, sign and stamp of the Incubator</li>
              <li className="point">
                It may indicatively read as: “To whomsoever it may concern; This
                is to declare that the following selected Startups under the
                Startup India Seed Fund Scheme by ABC Incubator have not been
                charged any fee.”
              </li>
              <li>Add list of startups as well</li>
            </ul>
          </div>
        </>
      )}
      {props.fileName == "LegalAgreement" && (
        <>
          <div className="row pl-5 pr-5">
            <ul className="point-list">
              <li className="point">On stamp paper</li>
              <li className="point"> Full name of Incubator and Startup</li>
              <li className="point">
                Signed and stamped by both Incubator and Startup
              </li>
              <li className="point">
                Agreement should not be in violation to the scheme guidelines
              </li>
            </ul>
          </div>
        </>
      )}
      {props.fileName == "Pfms" && (
        <>
          <div className="row" style={{ textAlign: "center" }}></div>
          <div className="row pl-5 pr-5">
            <ul className="point-list">
              <li className="point">
                Update fund disbursal details on PFMS and share a screenshot of
                the closing balance on PFMS Account matching with the closing
                balance as per the Utilization Certificate
              </li>
              <li className="point">
                Should be updated for all the startups funded till date
              </li>
              <li className="point">
                Please share Unspent balance report as follows:
                <ol>
                  <li>
                    {" "}
                    In your Approver Account, Go to Reports -- EAT_ -- EAT 02
                    Scheme wise EAT Aggregation{" "}
                  </li>
                  <li>Choose GOI Scheme</li>
                  <li>Controller: Industry</li>
                  <li>
                    Grant: Department for Promotion of Industry and Internal
                    Trade
                  </li>
                  <li>Scheme Type: Central Sector Scheme</li>
                  <li>Scheme Name: SISFS</li>
                  <li>
                    View Report - A scheme level report will pop up. In the
                    column ‘Agency Count’ click on ‘1’ written in S No. 2
                  </li>
                  <li>
                    Agency Statement on Fund Flow and Unspent Balances will be
                    generated.
                  </li>
                  <li>Save the file as pdf by clicking on save icon.</li>
                </ol>
              </li>
              <li>
                In case of realised returns from startups, add details in column
                4 – Returns earned on utilized funds
              </li>
            </ul>
          </div>
        </>
      )}
      {props.fileName == "Sanction" && (
        <>
          <div className="row pl-5 pr-5">
            <ul className="point-list">
              <li>
                In case of more than one document provide a merged copy of all
                documents.
              </li>
            </ul>
          </div>
        </>
      )}
      {props.fileName == "Tra" && <></>}

      {props.fileName == "ListStartups" && (
        <>
          <div className="row pl-5 pr-5">
            <ul className="point-list">
              <li className="point">
                On Incubator Letterhead – ISMC Meeting wise
              </li>
              <li className="point">
                Include separate columns for Startup Name, DPIIT Number,
                Application Date, Selection Date, Startup’s Bank Account
                Details, Funds Approved, Instrument Type, Number of Tranches,
                Amount Disbursed Per Tranche, Date of Tranche Disbursal, Total
                Funds Disbursed
              </li>

              <li>Signed and stamped by Incubator</li>
            </ul>
          </div>
        </>
      )}
      {props.fileName == "CA" && (
        <>
          <div className="row pl-5 pr-5">
            <ul className="point-list">
              <li className="point">
                Please note that as per Para 3.1(5) of the scheme guidelines,
                startup should not have received more than Rs 10 lakh of
                monetary support under any other Central or State Government
                scheme. This does not include prize money from competitions and
                grand challenges, subsidized working space, founder monthly
                allowance, access to labs, or access to prototyping facility.
              </li>
              <li className="point">
                Any one of the following two formats of CA certificate are
                acceptable:
                <ol>
                  <li>
                    A single CA certificate with all the names of startups
                    declaring that none have received more than Rs. 10 Lakh in
                    grant from the government. This must be the CA of the
                    Incubator OR
                  </li>
                  <li> Separate CA certificates of each startup</li>
                </ol>
              </li>
              <li>
                It must also be noted that as per Para 5.12 of the scheme
                guidelines, a startup selected by an incubator for assistance
                under this scheme shall not be charged any fees
              </li>
            </ul>
          </div>
        </>
      )}
      {props.fileName == "Interim" && (
        <>
          <div className="row pl-5 pr-5">
            <ul className="point-list">
              <li className="point">
                Both documents need to be on the Letterhead of the Startup
              </li>
              <li className="point">
                Must be signed and stamped by both the Startup and the Incubator
              </li>
              <li>
                When requesting for a tranche, the Progress Reports and UCs
                should not be older than one month and must be current
              </li>
            </ul>
          </div>
        </>
      )}
    </>
  );
}

export default FileUploadInfo;
