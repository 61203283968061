import React, { Fragment, useEffect, useState } from "react";
import "../../styles/component.css";
import { Link } from "react-router-dom";
import { get } from "../../effects/api/api.common";
import {
  GET_STARTUP_PORTFOLIO_LIST,
  GET_ALL_SECTOR,
  GET_ALL_CITIES,
  CONTACT_STATE,
  GET_ALL_INDUSTRIES,
} from "../../constants/api";
import { filterByValue1 } from "../../utils/CommonMethods";
import search from "../../../src/images/search.svg";
import MultiSelect from "../common/controls/MultiSelect";
import { DropdownModel } from "../../models/DropdownModel";
import seedfundlogo from "../../images/avatar.jpg";
import starrtupImage from "../../images/starrtupImage.svg";
import Arrow from "../../images/arrow.png";
import * as XLSX from "xlsx";
interface Startup {
  name_of_entity: string;
  Industry: string;
  Sector: string;
  state_name: string;
  city_name: string;
  logo?: string;
  id: number;
  startup_user_id: number;
  incubator_name: string;
  Industry_Id: number;
  Sector_id: number;
  state_id: number;
  city_id: number;
  recognition_number: string;
  about_startup: string;
  stage: string;
  nature_of_entity: string;
  website_url: string;
  facebook_link: string;
  twitter_link: string;
  youtube_link: string;
  linkedin_link: string;
  other_link: string;
  proofofconcept: string;
  prototypedevelopement: string;
  productdevelopement: string;
  fieldtrials: string;
  marketlaunch: string;
  Funding_Status: string;
  noofemployee: string;
  noofipregistration: string;
  angel_funding: string;
  loans: string;
  vc_funding: string;
  others: string;
}
interface TransformedStartup {
  "DIPP Number": string;
  "Name of Startup": string;
  "About Startup": string;
  "Entity Type": string;
  "Stage of Startup": string;
  Industry: string;
  Sector: string;
  State: string;
  City: string;
  "Funds Raised Externally": string;
  "No of People Employed": string;
  "IP Registrations Request filed": string;
  "Website Url": string;
  "Facebook Url": string;
  "Twitter Url": string;
  "Youtube Url": string;
  "LinkedIn Url": string;
  "Other Url": string;
  "Proof Of Concept": string;
  "Prototype Developement": string;
  "Product Developement": string;
  "Field Trials": string;
  "Market Launch": string;
}
interface ProgressDropdownModel {
  id: string;
  text: string;
  translation_text: string;
}

const StartupPortfolio = () => {
  const [data, setData] = useState<Startup[]>([]);
  const [sectorDetails, setSectorDetails] = useState<DropdownModel[]>([]);
  const [IndustryDetails, setIndustryDetails] = useState<DropdownModel[]>([]);
  const [countryStates, setCountryStates] = useState<DropdownModel[]>([]);
  const [stateDistricts, setStateDistricts] = useState<DropdownModel[]>([]);
  const [selectedStateValue, setStateValue] = useState<string[]>([]);
  const [selectedSectorValue, setSelectedSectorValue] = useState<string[]>([]);
  const [selectedIndustryValue, setIndustryValue] = useState<string[]>([]);
  const [selectedCityValue, setCityValue] = useState<string[]>([]);
  const [loader, setLoader] = useState(false);
  const [inputValue, setInputValue] = useState<string>(""); // Ensure it's initialized to an empty string
  const [showNoData, setShowNoData] = useState(false);
  const [filteredData, setFilteredData] = useState<Startup[]>([]);
  const [visibleItems, setVisibleItems] = useState<number>(25); // Number of items initially visible
  const [selectedProgressValue, setProgressValue] = useState<string[]>([]);
  useEffect(() => {
    fetchData();
  }, []);

  const filterByValue = (data: Startup[], value: string): Startup[] => {
    return data.filter((item) => {
      const nameOfEntity = item.name_of_entity?.toLowerCase() || "";
      const industry = item.Industry?.toLowerCase() || "";
      const sector = item.Sector?.toLowerCase() || "";
      const stateName = item.state_name?.toLowerCase() || "";
      const cityName = item.city_name?.toLowerCase() || "";

      return (
        nameOfEntity.includes(value) ||
        industry.includes(value) ||
        sector.includes(value) ||
        stateName.includes(value) ||
        cityName.includes(value)
      );
    });
  };

  useEffect(() => {
    if (inputValue.length >= 2) {
      // setLoader(true);
      const filtered = filterByValue(data, inputValue);
      setTimeout(() => {
        setFilteredData(filtered);
        setShowNoData(filtered.length === 0);
        // setLoader(false);
      }, 500); // Simulate async loading time
    } else {
      setFilteredData(data);
      setShowNoData(false);
    }
  }, [inputValue, data]);

  const fetchData = () => {
    setLoader(true);
    get(GET_STARTUP_PORTFOLIO_LIST).then((response: any) => {
      setLoader(false);
      if (!response || !response.data) return;
      if (response?.data?.result === "success") {
        setShowNoData(false);
        const APIData: Startup[] = response.data.data;
        setData(APIData);
        setFilteredData(APIData);
        getAllStates();
        getAllCities();
        getAllSectors();
        getAllIndustries();
      } else {
        setData([]);
        setFilteredData([]);
      }
    });
  };
  const applyFilters = () => {
    let filtered = [...data];
    console.log("Before filters:", filtered); // Debug statement

    if (selectedStateValue.length > 0) {
      filtered = filtered.filter(
        (item) =>
          item.state_id && selectedStateValue.includes(item.state_id.toString())
      );
      console.log("After state filter:", filtered); // Debug statement
    }

    if (selectedIndustryValue.length > 0) {
      filtered = filtered.filter(
        (item) =>
          item.Industry_Id &&
          selectedIndustryValue.includes(item.Industry_Id.toString())
      );
      console.log("After industry filter:", filtered); // Debug statement
    }

    if (selectedSectorValue.length > 0) {
      filtered = filtered.filter(
        (item) =>
          item.Sector_id &&
          selectedSectorValue.includes(item.Sector_id.toString())
      );
      console.log("After sector filter:", filtered); // Debug statement
    }

    if (selectedCityValue.length > 0) {
      filtered = filtered.filter(
        (item) =>
          item.city_id && selectedCityValue.includes(item.city_id.toString())
      );
      console.log("After city filter:", filtered); // Debug statement
    }
    if (selectedProgressValue.length > 0) {
      console.log("######################", filtered);
      filtered = filtered.filter((item) => {
        // Check if the item meets any of the selected progress stages
        const meetsAnyProgressStage = selectedProgressValue.some(
          (progressId) => {
            switch (progressId) {
              case "1":
                return item.proofofconcept === "Completed";
              case "2":
                return item.prototypedevelopement === "Completed";
              case "3":
                return item.productdevelopement === "Completed";
              case "4":
                return item.fieldtrials === "Completed";
              case "5":
                return item.marketlaunch === "Completed";
              default:
                return false;
            }
          }
        );

        return meetsAnyProgressStage;
      });
      console.log("After Progress filter:", filtered); // Debug statement
    }

    setFilteredData(filtered);
    setShowNoData(filtered.length === 0);
    console.log("Filtered Data:", filtered); // Debug statement
  };

  const getAllStates = () => {
    get(CONTACT_STATE).then((response: any) => {
      if (!response || !response.data) return;
      const states = response.data.data.map(
        (item: any): DropdownModel => ({
          text: item.name,
          id: item.id.toString(),
          translation_text: "",
        })
      );
      setCountryStates(states);
    });
  };

  const getAllCities = () => {
    get(`${GET_ALL_CITIES}`).then((response: any) => {
      if (!response || !response.data) return;
      const cities = response.data.data.map(
        (item: any): DropdownModel => ({
          text: item.city_name,
          id: item.city_id.toString(),
          translation_text: "",
        })
      );
      setStateDistricts(cities);
    });
  };

  const getAllSectors = () => {
    get(`${GET_ALL_SECTOR}/0`).then((response: any) => {
      if (!response || !response.data) return;
      const sectors = response.data.data.map(
        (item: any): DropdownModel => ({
          text: item.attribute_value,
          id: item.id.toString(),
          translation_text: "",
        })
      );
      setSectorDetails(sectors);
    });
  };

  const getAllIndustries = () => {
    get(`${GET_ALL_INDUSTRIES}`).then((response: any) => {
      if (!response || !response.data) return;
      const industries = response.data.data.map(
        (item: any): DropdownModel => ({
          text: item.attribute_value,
          id: item.id.toString(),
          translation_text: "",
        })
      );
      setIndustryDetails(industries);
    });
  };

  const onMultiSelectChange = (selectedIds: string[], name: string) => {
    switch (name) {
      case "state":
        setStateValue(selectedIds);
        break;
      case "industries":
        setIndustryValue(selectedIds);
        break;
      case "sector":
        setSelectedSectorValue(selectedIds);
        break;
      case "city":
        setCityValue(selectedIds);
        break;
      case "progress":
        setProgressValue(selectedIds);
        break;
      default:
        break;
    }
    console.log({ selectedIds, name }); // Debug statement
    applyFilters();
  };
  useEffect(() => {
    applyFilters();
  }, [
    selectedStateValue,
    selectedIndustryValue,
    selectedSectorValue,
    selectedCityValue,
    selectedProgressValue,
  ]);

  const searchList = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value.toLowerCase();
    console.log(searchValue);
    setInputValue(searchValue);

    if (searchValue !== "") {
      localStorage.setItem("searchTerm", searchValue);
    } else {
      localStorage.removeItem("searchTerm");
    }
  };

  const handleViewMore = () => {
    setVisibleItems((prev) => prev + 25); // Increase visible items by 25
  };
  const transformData = (data: Startup[]): TransformedStartup[] => {
    return data.map((item) => {
      console.log(item);
      const transformedItem = {
        "DIPP Number": item.recognition_number || "-",
        "Name of Startup": item.name_of_entity || "-",
        "About Startup": item.about_startup || "-",
        "Entity Type": item.nature_of_entity || "-",
        "Stage of Startup": item.stage || "-",
        Industry: item.Industry || "-",
        Sector: item.Sector || "-",
        City: item.city_name || "-",
        State: item.state_name || "-",
        "Website Url": item.website_url || "-",
        "Facebook Url": item.facebook_link || "-",
        "Twitter Url": item.twitter_link || "-",
        "Youtube Url": item.youtube_link || "-",
        "LinkedIn Url": item.linkedin_link || "-",
        "Other Url": item.other_link || "-",
        "Proof Of Concept": item.proofofconcept || "-",
        "Prototype Developement": item.prototypedevelopement || "-",
        "Product Developement": item.productdevelopement || "-",
        "Field Trials": item.fieldtrials || "-",
        "Market Launch": item.marketlaunch || "-",
        "Funds Raised Externally": item.Funding_Status || "-",
        "No of People Employed": item.noofemployee || "-",
        "IP Registrations Request filed": item.noofipregistration || "-",
      };
      return transformedItem;
    });
  };
  const downloadExcel = (data: Startup[]) => {
    const transformedData = transformData(data);
    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Startups");
    XLSX.writeFile(workbook, "Startups.xlsx");
  };
  const Progressoptions: ProgressDropdownModel[] = [
    {
      id: "1",
      text: "Proof of Concept",
      translation_text: "Proof of Concept",
    },
    {
      id: "2",
      text: "Prototype development",
      translation_text: "Prototype development",
    },
    {
      id: "3",
      text: "Product Development",
      translation_text: "Product Development",
    },
    {
      id: "4",
      text: "Field Trials",
      translation_text: "Field Trials",
    },
    {
      id: "5",
      text: "Market Launch",
      translation_text: "Market Launch",
    },
  ];

  return (
    <Fragment>
      <main role="main" style={{ fontFamily: "Poppins" }}>
        {loader && (
          <div className="spinner-border custom-spinner" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}
        <fieldset disabled={loader}>
          <div className="row col-md-12" style={{ backgroundColor: "#f4f6ff" }}>
            <div className="col-md-1"></div>
            <div className="col-md-10">
              <div
                className="about-container portfolio-container"
                style={{ marginTop: "100px" }}
              >
                <div
                  className="container-fluid about-heading"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="main-heading"
                    style={{
                      fontSize: "40px",
                      lineHeight: "1.3",
                      color: "#506EB9",
                      fontWeight: "900",
                      fontFamily: "Poppins",
                    }}
                  >
                    Portfolio of startups selected under the <br /> Startup
                    India Seed Fund Scheme
                  </div>
                  <div className="main-heading">
                    <img
                      src={starrtupImage}
                      className="search"
                      alt="search"
                      style={{ maxWidth: "80%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>

          <div className="row">
            <div className="container-fluid">
              <div className="col-md-12">
                <div className="mt-5 mb-5">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row col-md-12 pt-3">
                        <div className="col-md-1"></div>
                        <div className="col-md-6">
                          <h1
                            className="portMainHeading"
                            style={{
                              color: "#506EB9",
                              fontFamily: "Poppins",
                              fontSize: "35px",
                            }}
                          >
                            Filters
                          </h1>
                        </div>
                        <div className="col-md-4 d-flex justify-content-end align-items-center">
                          {/* <button
                            className="btn"
                            style={{
                              backgroundColor: "#F75C02",
                              borderColor: "#F75C02",
                              color: "white",
                              fontSize: "small",
                            }}
                            onClick={() => downloadExcel(filteredData)}
                          >
                            Download Excel
                          </button> */}
                        </div>
                        <div className="col-md-1"></div>
                      </div>{" "}
                      <br></br>
                      {/* <div className="row col-md-12">
                        <div className="col-md-1"></div>
                        <div
                          className="col-md-2"
                          style={{ marginRight: "25px" }}
                        >
                          <MultiSelect
                            id="industries"
                            options={IndustryDetails}
                            defaultText="Industry"
                            onClose={(selectedIds: string[]) =>
                              onMultiSelectChange(selectedIds, "industries")
                            }
                            selectedValues={selectedIndustryValue}
                            hasError
                            errorText=""
                            searchable
                          />
                        </div>
                        <div
                          className="col-md-2 "
                          style={{ marginRight: "25px" }}
                        >
                          <MultiSelect
                            id="sectors"
                            options={sectorDetails}
                            defaultText="Sector"
                            onClose={(selectedIds: string[]) =>
                              onMultiSelectChange(selectedIds, "sector")
                            }
                            selectedValues={selectedSectorValue}
                            hasError={true}
                            errorText=""
                            searchable={true}
                          />
                        </div>

                        <div
                          className="col-md-2"
                          style={{ marginRight: "25px" }}
                        >
                          <MultiSelect
                            id="states"
                            options={countryStates}
                            defaultText="State"
                            onClose={(selectedIds: string[]) =>
                              onMultiSelectChange(selectedIds, "state")
                            }
                            selectedValues={selectedStateValue}
                            hasError
                            errorText=""
                            searchable
                          />
                        </div>
                        <div
                          className="col-md-2"
                          style={{ marginRight: "25px" }}
                        >
                          <MultiSelect
                            id="cities"
                            options={stateDistricts}
                            defaultText="City"
                            onClose={(selectedIds: string[]) =>
                              onMultiSelectChange(selectedIds, "city")
                            }
                            selectedValues={selectedCityValue}
                            hasError
                            errorText=""
                            searchable
                          />
                        </div>
                        <div
                          className="col-md-2"
                          style={{ marginRight: "25px" }}
                        >
                          <MultiSelect
                            id="progress"
                            options={Progressoptions}
                            defaultText="Stage completed"
                            onClose={(selectedIds: string[]) =>
                              onMultiSelectChange(selectedIds, "progress")
                            }
                            selectedValues={selectedProgressValue}
                            hasError
                            errorText=""
                            searchable
                          />
                        </div>
                        <div className="col-md-1"></div>
                      </div> */}
                      <div className="row justify-content-center">
                        <div className="col-md-2">
                          <MultiSelect
                            id="industries"
                            options={IndustryDetails}
                            defaultText="Industry"
                            onClose={(selectedIds: string[]) =>
                              onMultiSelectChange(selectedIds, "industries")
                            }
                            selectedValues={selectedIndustryValue}
                            hasError
                            errorText=""
                            searchable
                          />
                        </div>
                        <div className="col-md-2">
                          <MultiSelect
                            id="sectors"
                            options={sectorDetails}
                            defaultText="Sector"
                            onClose={(selectedIds: string[]) =>
                              onMultiSelectChange(selectedIds, "sector")
                            }
                            selectedValues={selectedSectorValue}
                            hasError
                            errorText=""
                            searchable
                          />
                        </div>
                        <div className="col-md-2">
                          <MultiSelect
                            id="states"
                            options={countryStates}
                            defaultText="State"
                            onClose={(selectedIds: string[]) =>
                              onMultiSelectChange(selectedIds, "state")
                            }
                            selectedValues={selectedStateValue}
                            hasError
                            errorText=""
                            searchable
                          />
                        </div>
                        <div className="col-md-2">
                          <MultiSelect
                            id="cities"
                            options={stateDistricts}
                            defaultText="City"
                            onClose={(selectedIds: string[]) =>
                              onMultiSelectChange(selectedIds, "city")
                            }
                            selectedValues={selectedCityValue}
                            hasError
                            errorText=""
                            searchable
                          />
                        </div>
                        <div className="col-md-2">
                          <MultiSelect
                            id="progress"
                            options={Progressoptions}
                            defaultText="Stage completed"
                            onClose={(selectedIds: string[]) =>
                              onMultiSelectChange(selectedIds, "progress")
                            }
                            selectedValues={selectedProgressValue}
                            hasError
                            errorText=""
                            searchable
                          />
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ backgroundColor: "#f9faff" }}>
                <br></br>
                <br></br>
                <div
                  className="row col-md-12 pt-10"
                  style={{ backgroundColor: "#F9FAFF" }}
                >
                  <div className="col-md-1"></div>
                  <div className="col-md-6">
                    <h1
                      className="portMainHeading"
                      style={{
                        color: "#506EB9",
                        fontFamily: "Poppins",
                        fontSize: "35px",
                      }}
                    >
                      Selected Startups({filteredData.length})
                    </h1>
                  </div>
                  <div className="col-md-4 d-flex justify-content-end align-items-center">
                    <div
                      className="d-flex searchDiv align-items-center"
                      style={{ position: "relative", width: "75%" }}
                    >
                      <input
                        className="searchBar"
                        style={{
                          backgroundColor: "#ffffff",
                          border: "2px solid #0A0A0A",
                          paddingRight: "30px",
                          width: "100%",
                          borderRadius: "6px",
                        }}
                        type="text"
                        value={inputValue || ""}
                        onChange={searchList}
                        placeholder=" Search.."
                        name="search"
                      />
                      <img
                        src={search}
                        className="search"
                        alt="search"
                        style={{
                          position: "absolute",
                          right: "45px",
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-1"></div>
                </div>
                <br></br>
                <div className="row col-md-12">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    {showNoData ? (
                      <div
                        className="col-md-12 text-center"
                        style={{
                          color: "red",
                          fontSize: "20px",
                          marginTop: "20px",
                        }}
                      >
                        NO DATA FOUND
                      </div>
                    ) : (
                      <div className="row">
                        {filteredData
                          .slice(0, visibleItems)
                          .map((item, index) => (
                            <div key={index} className="col-md-4 mb-4">
                              <div
                                className="portFolioGrid"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  background:
                                    "#FFFFFF 0% 0% no-repeat padding-box",
                                  boxShadow: "0px 4px 25px #61606070",
                                  mixBlendMode: "normal",
                                  border: "1px solid #000000",
                                  opacity: 1,
                                  borderRadius: "20px",
                                  padding: "10px",
                                  width: "100%",
                                  height: "150px", // Set a fixed height for the cards
                                }}
                              >
                                <div
                                  className="portImageBlock"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginRight: "10px",
                                  }}
                                >
                                  <img
                                    src={item.logo ? item.logo : seedfundlogo}
                                    alt={item.name_of_entity}
                                    className="portImage"
                                    style={{
                                      width: "60px",
                                      height: "60px",
                                      borderRadius: "50%",
                                      objectFit: "scale-down",
                                      marginBottom: "100%",
                                    }}
                                  />
                                </div>
                                <div
                                  className="portContentBlock"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    flexGrow: 1,
                                    textAlign: "left",
                                    overflow: "hidden",
                                  }}
                                >
                                  <h1
                                    className="portHeading"
                                    style={{
                                      color: "#000000",
                                      fontSize: "16px",
                                      whiteSpace: "normal",
                                      lineHeight: "20px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {item.name_of_entity}
                                  </h1>
                                  <p
                                    style={{
                                      fontSize: "small",
                                      margin: 0,
                                      whiteSpace: "normal",
                                      // marginTop: "15px",
                                      fontWeight: "600",
                                      color: "black",
                                    }}
                                  >
                                    {item.city_name} | {item.state_name} |{" "}
                                    {item.Industry}
                                  </p>
                                </div>
                                <Link
                                  to={{
                                    pathname: "/startup_portfolio_detail",
                                    state: {
                                      Startup_application_id: item.id,
                                      Startup_user_id: item.startup_user_id,
                                      proofofconcept: item.proofofconcept,
                                      prototypedevelopement:
                                        item.prototypedevelopement,
                                      productdevelopement:
                                        item.productdevelopement,
                                      fieldtrials: item.fieldtrials,
                                      marketlaunch: item.marketlaunch,
                                      noofemployee: item.noofemployee,
                                      noofipregistration:
                                        item.noofipregistration,
                                      Funding_Status: item.Funding_Status,
                                      angel_funding: item.angel_funding,
                                      loans: item.loans,
                                      vc_funding: item.vc_funding,
                                      others: item.others,
                                    },
                                  }}
                                  className="portLink"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    textDecoration: "none",
                                    color: "inherit",
                                  }}
                                >
                                  <div
                                    className="portArrowBlock"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <img
                                      src={Arrow}
                                      alt={item.incubator_name}
                                      className="portArrow"
                                      style={{
                                        width: "40px",
                                        height: "40px",
                                        marginBottom: "135%",
                                      }}
                                    />
                                  </div>
                                </Link>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                  <div className="col-md-1"></div>
                </div>
                {visibleItems < data.length && (
                  <div className="col-md-12 d-flex justify-content-center mt-4">
                    <a
                      onClick={handleViewMore}
                      style={{
                        color: "#506eb9",
                        fontSize: "18px",
                        cursor: "pointer",
                      }}
                    >
                      <u>View More</u>
                      <span
                        className="icon arrow"
                        style={{ marginLeft: "5px" }}
                      ></span>
                      <span
                        className="icon arrow"
                        style={{
                          marginLeft: "-8px",
                          marginBottom: "5px",
                          color: "#506EB9",
                          display: "inline-block",
                        }}
                      ></span>
                    </a>
                  </div>
                )}
                <br></br>
              </div>
            </div>
          </div>
        </fieldset>
      </main>
    </Fragment>
  );
};

export default StartupPortfolio;
