import { EntityDetailModel } from "./EntityDetailModel";
import { FundExpenseDetails, FundingDetailsModel, PriorDetails } from "./FundingDetailsModel";
import { IncubatorPreferenceModel } from "./IncubatorPreferenceModel";
import { StartupDetailModel } from "./StartupDetailModel";
import { PromoterDetails, StartupTeamModel, TeamDetails } from "./StartupTeamModel";
import { UploadDocumentsModel } from "./UploadDocumentsModel";
import { AuthorizedRepresentativeModel } from "../startup/AuthorizedRepresentativeStartupModel";

export class StartupModel {
    constructor(
        public application_id: string,
        public EntityDetail: EntityDetailModel,
        public StartupDetail: StartupDetailModel,
        public AuthorizedRepresentative: AuthorizedRepresentativeModel,
        public StartupTeam: StartupTeamModel,
        public FundingDetails: FundingDetailsModel,
        public IncubatorPreference: IncubatorPreferenceModel,
        public UploadDocuments: UploadDocumentsModel
    ) {
        application_id = application_id;
        this.EntityDetail = EntityDetail;
        this.StartupDetail = StartupDetail;
        this.AuthorizedRepresentative = AuthorizedRepresentative;
        this.StartupTeam = StartupTeam;
        this.FundingDetails = FundingDetails;
        this.IncubatorPreference = IncubatorPreference;
        this.UploadDocuments = UploadDocuments;
    }
}
export const initEntityDetail: EntityDetailModel = new EntityDetailModel(
    "",
    "",
    "",
    [],
    "",
    false,
    [],
    "",
    "",
    "",
    "",
    "",
    ""
);

export const initStartupDetail: StartupDetailModel = new StartupDetailModel(
    "",
    "",
    "",
    "",
    "",
    1,
    "",
    "",
    // [],
    "",
    "",
    "",
    "",
    false,
    false,
    false,
    false,
    "",
    "",
    "",
    "",
    "",
    "",
    false,
    false,
    false,
    false,
    false
);

export const initAuthorizedRepresentative: AuthorizedRepresentativeModel = new AuthorizedRepresentativeModel(
    "",
    "",
    "",
    "",
    "",
    []
);

export const initStartupTeam: StartupTeamModel = new StartupTeamModel(
    "", "", "", "", 1, [new TeamDetails(0, "", 0)], [new PromoterDetails(0, "", "")], []
);

export const initFundingDetails: FundingDetailsModel = new FundingDetailsModel(
    "", "", 1, [{ expense_bucket: "", expense_amount: "", show_other: false, expense_bucket_other: '', deployment_start_date: new Date(), deployment_end_date: new Date() }], "1", [new PriorDetails(0, formatDate(new Date()), null, "", "", "")]
    // [],
    // 1,
    // new Date(),
    // new Date()
);

export const initIncubatorPreference: IncubatorPreferenceModel = new IncubatorPreferenceModel(
    "",
    "",
    ""
);

export const initUploadDocuments: UploadDocumentsModel = new UploadDocumentsModel(
    "", [], "", [], []
);

function formatDate(date: Date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}