import React, { useEffect, useRef, useState } from "react";
import filter from "../../../images/filter.svg";
import edit from "../../../images/edit.svg";
import search from "../../../images/search.svg";
import {
  changeNumberFormat,
  downloadDocument,
  filterByValue,
} from "../../../utils/CommonMethods";
import { compareTime, FormatDate } from "../../../utils/DateFormat";
import { formatTime } from "../../../utils/DateFormat";
import MultiSelect from "../../common/controls/MultiSelect";
import Calendar from "../../common/controls/Calendar";
import ConfirmationDialog from "../../common/controls/ConfirmationDialog";
import { get, post } from "../../../effects/api/api.common";
import {
  DOWNLOAD_EXCEL,
  GET_ALL_SECTOR,
  GET_ALL_STATES,
  GET_STARTUP_APPLICATION_STATUS,
  GET_STARTUP_ENGAGEMENT_STATUS,
  GET_STARTUP_LISTING,
  GET_STARTUP_LISTING_FILTER,
  STARTUP_SCHEDULE_MEETING,
  STORE_STARTUP_APPLICATION,
  File_node_url,
  STORE_STARTUP_APPLICATION_NODE,
  QUATERLY_SUCCESS,
  File_base_url
} from "../../../constants/api";
import { useHistory } from "react-router-dom";
import { DropdownModel } from "../../../models/DropdownModel";
import { isTemplateExpression } from "typescript";
import moment from "moment";
import { getSessionStorageValue } from "../../../effects/utils/session-storage";
import { userInfo } from "os";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import * as XLSX from 'xlsx';
import { exportToExcel } from "../../../constants/ExcelServices";
interface IStartupListProps { }

interface IStartupListState { }
declare var $: any;
function StartupListContainer(props: any) {
  var currentdatenew = moment().format("YYYY-MM-DD")
  const history = useHistory();
  const [quater1, setQuater] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const isRejected = useRef(0);
  const [SubmitBTN, setSubmitBTN] = useState(false);
  const [meetingLink, setMeetingLink] = useState("");
  const [showEditTranche, setShowEditTranche] = useState(false);
  const [showIncubatorFailureModal, setShowIncubatorFailureModal] =
    useState(false);
  const [timeValidation, setTimeValidation] = useState(false);
  const [linkValidation, setLinkValidation] = useState(false);
  const [showPreference, setShowPreference] = useState(true);
  const [timeValidationCurrentTime, setTimeValidationCurrentTime] =
    useState(false);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [checkBoxHide, setCheckBoxHide] = useState(true);
  const [showEditFailureModal, setShowEditFailureModal] = useState(false);
  const [showEditSuccessModal, setShowEditSuccessModal] = useState(false);
  const [applicationStatus, setApplicationStatus] = useState([]);
  const [engagementStatus, setEngagementStatus] = useState([]);
  const [selectFlag, setSelectFlag] = useState(true);
  const [showIncubatorSuccessModal, setShowIncubatorSuccessModal] =
    useState(false);
  const [countryStates, setCountryStates] = useState([]);
  const [sectorDetails, setSectorDetails] = useState([]);
  const [isDisabled, setDisabled] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [oneStartupCheckbox, setOneStartup] = useState(false);
  const [columnHideFlag, setColumnHideFlag] = useState(true);
  const [tableHeading, setTableHeading] = useState("Applications Submitted");
  const [noStartupCheckbox, setNoStartup] = useState(false);
  const [selectedStateValue, setStateValue] = useState<Array<string>>([]);
  const [selectedSectorValue, setSectorValue] = useState<Array<string>>([]);
  const [fundingType, setFundingType] = useState("");
  const [filterDate, setFilterDate] = useState("");
  // const [selectedEntityValue, setEntityValue] = useState<Array<string>>([]);
  const [checkedArray, setCheckedArray] = useState<Array<string>>([]);
  const [fromTime, setFromTime] = useState(String);
  const [toTime, setToTime] = useState(String);
  const [incubatorMeetingType, setincubatorMeetingType] = useState("1");
  const [scheduleDate, setScheduleDate] = useState(String);
  const [selectedDateControl, setSelectedDateControl] = useState(new Date());
  const [activeIndex, setActiveIndex] = useState<any>(["2"]);
  const [mouseOverFlag, setMouseOverFlag] = useState("");
  const [Excelpaylod, setExcelpaylod] = useState('');
  const user: any = getSessionStorageValue("user");
  const userInfo = JSON.parse(user);
  const openApplication: any = [];
  const allotedYouApplication: any = [];
  const allotedToAnother: any = [];
  const rejectedByYou: any = [];
  const systemRejected: any = [];
  const earlyClosureData: any = [];
  const fullClosedData: any = [];
  const cancelledData: any = [];
  const reamainingData: any = [];
  const lastDayApplication: any = [];
  var newArray: any = [];
  const [quickEditPopupData, setquickEditPopupData] = useState({
    application_comments: "",
    application_id: "",
    application_status: "",
    engagement_status: "",
    corporate_id_no: "",
    incubator_name: "",
    no_of_tranches: "",
    date_of_evaluation: "",
    total_approved_amount: "",
    total_disbursed_amount: ""
  });
  const [incubatorListingCounts, setIncubatorListingCounts] = useState({
    incomplete: "0",
    onHold: "0",
    pendingReview: "0",
    rejected: "0",
    selected: "0",
    submitted: "0",
    reviewCount: "0",
    trancheRequest: "0",
    closed: "0",
    cancel: '0',
    cancelled: "0",
    system_rejected: "0"
  });
  const [incubatorList, setincubatorList] = useState<Array<any>>([]);
  const [showFilterDot, setShowFilterDot] = useState(false);
  const [noDataFilter, setNoDataFilter] = useState('');
  const [filteredIncubatorList, setFilteredIncubatorList] = useState([
    {
      id: "",
      application_date: "",
      government_assisted: "",
      state_name: "",
      incubator_name: "",
      total_approved_amount: "",
      total_disbursed_amt: "",
      meeting_date: "",
      meeting_time: "",
      meeting_id: "",
      meeting_status: "",
      status_id: "",
      startup_status_id: "",
    },
  ]);
  const [incubatorListCountHead, setincubatorListCountHead] = useState("");
  const [incubatorListId, setIncubatorListId] = useState({
    incomplete_id: "",
    onHold_id: "",
    pendingReview_id: "",
    rejected_id: "",
    selected_id: "",
    submitted_id: "",
    trancheRequest_id: "",
    closed_id: "",
    cancelled_id: "",
    system_rejected_id : ""
  });

  const [dialogData, setDialogData] = useState({
    header: "",
    text: "",
  });
  const [failDialogData, setFailDialogData] = useState({
    header: "",
    text: "",
  });
  const [confirmationDialogData, setConfirmationDialogData] = useState({
    header: "",
    text: "",
  });
  const [loggedInRole, setLoggedInRole] = useState("");
  const [incubatorUserId, setIncubatorUserId] = useState("");
  const [Cancellation_Requested_Count, setCancellation_Requested_Count] = useState<any>("");
  const [Cancellation_Approved_Count, setCancellation_Approved_Count] = useState<any>("");
  const [system_rejected_Count, setsystem_rejected_Count] = useState<any>("");
  const [Tranche_Requested_Count, setTranche_Requested_Count] = useState<any>("");
  const [currentdate, setCurrentdate] = useState("")
  const ExcelDataId: any = [];
  const [QRIntiatedDetails, setQRIntiatedDetails] = useState<any>("");
  const [TotalApplicationAPI, setTotalApplicationAPI] = useState<any>("");
  const [Orange, setOrange] = useState();
  const [Green, setGreen] = useState();
  const [Eligbility,setEligibility] = useState();

  const [totalQR, settotalQR] = useState();
  const [CompletedQR, setCompletedQR] = useState(); 

  // const [quaterlyreviewcompleted,setquaterlyreviewcompleted]= useState();
  useEffect(() => {

    if (getFileDetails(window.location.href, "name") != "view_startup_details") {
      localStorage.setItem("value", '4')
    }
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    setLoggedInRole(userInfo.roles);
    //console.log(typeof props.location.state !== "undefined","data$$$$")
    //console.log(props.location.state !=null,"data$$$$")
    //console.log( typeof props.location.state !== "undefined" && props.location.state != null,"data$$$$")


    if (
      typeof props.location.state !== "undefined" &&
      props.location.state != null
    ) {
      window.scrollTo(0, 0);
      getAllStates();
      getRestrictedIncubatorCount(props.location.state.user_id);
      setIncubatorUserId(props.location.state.user_id);
      if (typeof props.location.state.showPreference != "undefined") {
        setShowPreference(props.location.state.showPreference);
      }
      getAllSectors();
    } else {
      //console.log("getttt")
      window.scrollTo(0, 0);
      // getAllStates();
      getIncubatorCount();
      // getAllSectors();
    }
    // getDataByStatus("2")

    // getAllLegalEntity();
    // function convert(d:any) {
    //   var date = new Date(),
    //     mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    //     day = ("0" + date.getDate()).slice(-2);
    //   return [date.getFullYear(), mnth, day].join("-");
    // }
    // const d = new Date();
    // const frmtD = convert(d)
    // //console.log(d)
    // setCurrentdate(frmtD)
  }, []);
  const getFileDetails = (file: any, key: string) => {
    let file_name = "";
    file_name =
      file != undefined && file != null && file.length > 1
        ? file.substr(file.lastIndexOf("/") + 1, file.length)
        : "";

    const file_type =
      file != undefined && file != null && file.length > 1
        ? file.substr(file.lastIndexOf(".") + 1, file.length)
        : "";

    if (key == "name") return file_name;
    if (key == "file") return file != undefined ? file.toString() : "";
    if (key == "type") return file != undefined ? file_type.toString() : "";
  };
  const getRestrictedIncubatorCount = (id: any) => {
    // console.log(id,"222222222222","selectedIdddddddddd")

    //console.log(id,"idddd")
    setCheckBoxHide(true);
    setButtonDisable(true);
    setLoader(true)
    post(GET_STARTUP_LISTING, {
      list_from: "incubator",
      incubator_id: id,
      status_id: [2],
      intial_call: 'yes',
      logged_user_id: userInfo.id,
      userRole: userInfo.roles
    }).then((response: any) => {
      setLoader(false)
      if (!response || !response.data) return;
      const counts = {
        incomplete: "0",
        onHold: "0",
        pendingReview: "0",
        rejected: "0",
        selected: "0",
        submitted: "0",
        reviewCount: response.data.reviewCount
          ? response.data.reviewCount
          : "0",
        trancheRequest: response.data.tranceRequestedCount,
        closed: "0",
        cancel: response.data.cancellationRequestedCount,
        cancelled: response.data.cancellationApprovedCount,
        system_rejected : response.data.system_rejected
      };
      const status_id = {
        incomplete_id: "",
        onHold_id: "",
        pendingReview_id: "",
        rejected_id: "",
        selected_id: "",
        submitted_id: "",
        trancheRequest_id: "",
        closed_id: "",
        cancelled_id: "",
        system_rejected_id : ""
      };
      for (var i = 0; i < response.data.mainCount.length; i++) {
        if (response.data.mainCount[i].name == "incomplete") {
          counts.incomplete = response.data.mainCount[i].appln_count;
          status_id.incomplete_id = response.data.mainCount[i].id;
        } else if (response.data.mainCount[i].name == "pending_review") {
          counts.pendingReview = response.data.mainCount[i].appln_count;
          status_id.pendingReview_id = response.data.mainCount[i].id;
        } else if (response.data.mainCount[i].name == "submitted") {
          counts.submitted = response.data.mainCount[i].appln_count;
          status_id.submitted_id = response.data.mainCount[i].id;
        } else if (response.data.mainCount[i].name == "on_hold") {
          counts.onHold = response.data.mainCount[i].appln_count;
          status_id.onHold_id = response.data.mainCount[i].id;
        } else if (response.data.mainCount[i].name == "rejected") {
          counts.rejected = response.data.mainCount[i].appln_count;
          status_id.rejected_id = response.data.mainCount[i].id;
        } else if (response.data.mainCount[i].name == "selected") {
          counts.selected = response.data.mainCount[i].appln_count;
          status_id.selected_id = response.data.mainCount[i].id;
        } else if (response.data.mainCount[i].name == "closed") {
          counts.closed = response.data.mainCount[i].appln_count;
          status_id.closed_id = response.data.mainCount[i].id;
        } else if (response.data.mainCount[i].name == "cancelled") {
          // counts.cancelled = response.data.mainCount[i].appln_count;
          // status_id.closed_id = response.data.mainCount[i].id;
        }
      }
      //console.log(response.data.cancellationRequestedCount,"data$$$$$")
      //console.log(response.data.tranceRequestedCount,"data$$$$$")
      setCancellation_Requested_Count(response.data.cancellationRequestedCount);
      setCancellation_Approved_Count(response.data.cancellationApprovedCount);
      setsystem_rejected_Count(response.data.system_rejected);

      //console.log(response.data.tranceRequestedCount,"tranceRequestedCount")
      setTranche_Requested_Count(response.data.tranceRequestedCount);
      setIncubatorListingCounts(counts);
      // console.log(counts,"hello")
      setIncubatorListId(status_id);
      setincubatorListCountHead(response.data.applications.length);
      if (response.data.applications.length != 0) {
        //console.log(response.data.applications,"sri@@")
        setincubatorList(response.data.applications);
        // console.log(incubatorList,"list1")
        response.data.applications.forEach((item: any, index: any) => {
          ExcelDataId.push(
            item.id
          );
        });
        setExcelpaylod(ExcelDataId);

        setFilteredIncubatorList([]);

        setColumnHideFlag(false);
        // setincubatorList(incubatorListData);
      } else {
        setincubatorList([]);
      }
    });
  };

  // get initial count of incubators
  const getIncubatorCount = () => {
    // console.log("selected_id","333333333","selectedIdddddddddd")

    //console.log("hello")
    setCheckBoxHide(true);
    setButtonDisable(true);
    setLoader(true)
    post(GET_STARTUP_LISTING, { status_id: [2], intial_call: 'yes', logged_user_id: userInfo.id, userRole: userInfo.roles }).then((response: any) => {
      setLoader(false)
      setCancellation_Requested_Count(response.data.cancellationRequestedCount);
      setCancellation_Approved_Count(response.data.cancellationApprovedCount);
      setsystem_rejected_Count(response.data.system_rejected);
      setTotalApplicationAPI(response.data);
      setQRIntiatedDetails(response.data.QRIntiatedDetails);
      setOrange(response.data.quaterly_review_started);
      setGreen(response.data.all_startups_QR_completed);


      settotalQR(response.data.total_QR_applications);
      setCompletedQR(response.data.completed_QR_applications);


      // console.log("response.data",response.data)
      // setEligibility(response.data.eligbility);

      //console.log(response.data.tranceRequestedCount,"tranceRequestedCount")
      setTranche_Requested_Count(response.data.tranceRequestedCount);
      if (!response || !response.data) return;
      const counts = {
        incomplete: "0",
        onHold: "0",
        pendingReview: "0",
        rejected: "0",
        selected: "0",
        submitted: "0",
        reviewCount: response.data.reviewCount
          ? response.data.reviewCount
          : "0",
        trancheRequest: response.data.tranceRequestedCount,
        closed: "0",
        cancel: response.data.cancellationRequestedCount,
        cancelled: response.data.cancellationApprovedCount,
        system_rejected :response.data.system_rejected
      };
      const status_id = {
        incomplete_id: "",
        onHold_id: "",
        pendingReview_id: "",
        rejected_id: "",
        selected_id: "",
        submitted_id: "",
        trancheRequest_id: "",
        closed_id: "",
        cancelled_id: "",
        system_rejected_id : ""
      };
      for (var i = 0; i < response.data.mainCount.length; i++) {
        if (response.data.mainCount[i].name == "incomplete") {
          counts.incomplete = response.data.mainCount[i].appln_count;
          status_id.incomplete_id = response.data.mainCount[i].id;
        } else if (response.data.mainCount[i].name == "pending_review") {
          counts.pendingReview = response.data.mainCount[i].appln_count;
          status_id.pendingReview_id = response.data.mainCount[i].id;
        } else if (response.data.mainCount[i].name == "submitted") {
          counts.submitted = response.data.mainCount[i].appln_count;
          status_id.submitted_id = response.data.mainCount[i].id;
        } else if (response.data.mainCount[i].name == "on_hold") {
          counts.onHold = response.data.mainCount[i].appln_count;
          status_id.onHold_id = response.data.mainCount[i].id;
        } else if (response.data.mainCount[i].name == "rejected") {
          counts.rejected = response.data.mainCount[i].appln_count;
          status_id.rejected_id = response.data.mainCount[i].id;
        } else if (response.data.mainCount[i].name == "selected") {
          counts.selected = response.data.mainCount[i].appln_count;
          status_id.selected_id = response.data.mainCount[i].id;
        } else if (response.data.mainCount[i].name == "closed") {
          counts.closed = response.data.mainCount[i].appln_count;
          status_id.closed_id = response.data.mainCount[i].id;
        }
      }

      setIncubatorListingCounts(counts);
      setIncubatorListId(status_id);
      setincubatorListCountHead(response.data.applications.length);
      if (response.data.applications.length != 0) {
        for (let i = 0; i < response.data.applications.length; i++) {
          var d = new Date();
          var last1Day = d.setDate(d.getDate() - 1); // subtract one day
          var formatLast1Day = moment(last1Day).format("YYYY-MM-DD")
          var LastDay = moment(new Date()).format("YYYY-MM-DD")
          // console.log(response.data.applications[i].evaluation_to_be_completed_by , LastDay , "yesterday");
          setEligibility(response.data.applications[i].eligbility);
          // setquaterlyreviewcompleted(response.data.applications[i].quaterly_review_completed);

          if (
            (response.data.applications[i].evaluation_to_be_completed_by < currentdatenew) &&
            (response.data.applications[i].incubator_preferences_data == 2
              || response.data.applications[i].incubator_preferences_data == 3
              || response.data.applications[i].incubator_preferences_data == 4
              || response.data.applications[i].incubator_preferences_data == 5)
          ) {
            let data1 = {
              ...response.data.applications[i],
              newIndex: 1
            }
            systemRejected.push(data1)
          }
          else if (response.data.applications[i].evaluation_to_be_completed_by == LastDay) {
            let data3 = {
              ...response.data.applications[i],
              newIndex: 9
            }
            lastDayApplication.push(data3)
          }
          else if (response.data.applications[i].evaluation_to_be_completed_by > formatLast1Day) {
            let data2 = {
              ...response.data.applications[i],
              newIndex: 2
            }
            openApplication.push(data2)

          }
          else {
            reamainingData.push(response.data.applications[i])
          }
        }
        setincubatorList([...lastDayApplication, ...openApplication, ...systemRejected, ...reamainingData]);
        // setincubatorList(response.data.applications);
        // console.log(incubatorList,"list2")

        response.data.applications.forEach((item: any, index: any) => {

          ExcelDataId.push(
            item.id
          );

        });
        setExcelpaylod(ExcelDataId);

        setFilteredIncubatorList([]);

        setColumnHideFlag(false);
        // setincubatorList(incubatorListData);
      } else {
        setincubatorList([]);
      }

      if (response.data.QRIntiatedDetails && response.data.QRIntiatedDetails.length != 0) {
        if (response.data.QRIntiatedDetails[0].quarter == '1') {
          setQuater('Jan - Mar');
        } else if (response.data.QRIntiatedDetails[0].quarter == '2') {
          setQuater('Apr - Jun');
        } else if (response.data.QRIntiatedDetails[0].quarter == '3') {
          setQuater('Jul - Sep');
        } else if (response.data.QRIntiatedDetails[0].quarter == '4') {
          setQuater('Oct - Dec');
        } else {
          setQuater('');
        }
      }
      getAllStates();
    });
  };
  const SubmitQRClick = () => {

    // {Orange == 1 && item.eligbility != 0 && item.quaterly_review_completed == 0 && item.already_selected_flag != 1 && ((item.incubator_preferences_data == 7 && item.startup_status_id == 7) || (item.incubator_preferences_data == 8 && item.startup_status_id == 8)) && (item.evaluation_to_be_completed_by < currentdatenew) && (
    //   <span className={Orange == 1 && item.quaterly_review_completed == 0 ? "orange_link" : ""}>
    //     QR Pending
    //   </span>
    // )}



    console.log("Orange", Orange)
    console.log("Green", Green)
    console.log("totalQR", totalQR)
    console.log("CompletedQR", CompletedQR)  

    if ((((Orange == 1 && Green == 0 ) && (totalQR != CompletedQR)) || ((Orange == 1 && Green == 1 ) && (totalQR != CompletedQR)))) {
      $("#QRPending").modal('show');
    } else if ((Orange == 1 && Green == 1) &&  (totalQR == CompletedQR) || (totalQR == CompletedQR)) {
      $("#QRSuccess").modal('show');
    }

   

    // if ((Orange == 1 && Green == 0 && Eligbility == 1) || (Orange == 1 && Green == 1 && Eligbility == 1 && quaterlyreviewcompleted == 0)) {
    //   $("#QRPending").modal('show');
    // } 
    //  if ((Orange == 1 && Green == 1 && Eligbility == 1) || (Orange == 1 && Green == 0 && Eligbility == 0 && quaterlyreviewcompleted == 0)|| (Orange == 1 && Green == 1 && Eligbility == 0 && quaterlyreviewcompleted == 0)) {
    //   $("#QRSuccess").modal('show');
    // }
  }
  // get states in dropdown
  const getAllStates = () => {
    get(GET_ALL_STATES).then((response: any) => {
      if (!response || !response.data) return;
      const states = response.data.data.map((item: any): DropdownModel => {
        return {
          text: item.name,
          id: item.id.toString(),
          translation_text: "",
        };
      });
      setCountryStates(states);
      getAllSectors();
    });
  };

  const searchList = (e: any) => {
    setNoDataFilter(e.target.value)
    const filteredData = filterByValue(incubatorList, e.target.value);
    setincubatorListCountHead(filteredData.length.toString());
    setFilteredIncubatorList(filteredData);
  };

  // get all sectors in dropdown
  const getAllSectors = () => {
    // const param = sectorDetails.length == 0 ? '0' : sectorDetails
    get(`${GET_ALL_SECTOR}/0`).then((response: any) => {
      if (!response || !response.data) return;
      const sectors = response.data.data.map((item: any): DropdownModel => {
        return {
          text: item.attribute_value,
          id: item.id.toString(),
          translation_text: "",
        };
      });
      setSectorDetails(sectors);
    });
  };

  const getDataByStatus = (selected_id: any) => {
    // console.log(selected_id,"111111111111","selectedIdddddddddd")
    if (loggedInRole == "secretariat" || loggedInRole == "dpiit") {

      if (selected_id == "2") {
        isRejected.current = 1;
        setIsChecked(false);
        setButtonDisable(true);
        setCheckBoxHide(true);
        setTableHeading("Applications Submitted");
        setincubatorListCountHead(incubatorListingCounts.submitted);
        setSubmitBTN(false);
      } else if (selected_id == "3") {
        isRejected.current = 1;
        if (parseInt(incubatorListingCounts.pendingReview) == 0) {
          setButtonDisable(true);
        } else {
          setButtonDisable(false);
        }
        setincubatorMeetingType("1");
        setCheckBoxHide(false);
        setCheckedArray([]);
        setTableHeading("Applications to be Reviewed");
        setincubatorListCountHead(incubatorListingCounts.pendingReview);
        setSubmitBTN(false);
      } else if (selected_id == "4") {
        isRejected.current = 1;
        setIsChecked(false);
        setButtonDisable(true);
        setCheckBoxHide(true);
        setTableHeading("Applications Incomplete");
        setincubatorListCountHead(incubatorListingCounts.incomplete);
        setSubmitBTN(false);
      } else if (selected_id == "5") {
        isRejected.current = 1;
        setIsChecked(false);
        setButtonDisable(true);
        setCheckBoxHide(true);
        setTableHeading("Applications On Hold");
        setincubatorListCountHead(incubatorListingCounts.onHold);
        setSubmitBTN(false);
      } else if (selected_id == "6") {
        isRejected.current = 1;
        setIsChecked(false);
        setButtonDisable(true);
        setCheckBoxHide(true);
        setTableHeading("Applications Rejected");
        setincubatorListCountHead(incubatorListingCounts.rejected);
        setSubmitBTN(false);
      } else if (selected_id == "7") {
        isRejected.current = 1;
        setincubatorMeetingType("2");
        if (parseInt(incubatorListingCounts.selected) == 0) {
          setButtonDisable(true);
        } else {
          setButtonDisable(false);
        }
        setCheckBoxHide(false);
        setCheckedArray([]);
        setTableHeading("Applications Selected");
        setincubatorListCountHead(incubatorListingCounts.selected);
        setSubmitBTN(true);
      } else if (selected_id == "8") {
        isRejected.current = 1;
        setIsChecked(false);
        setButtonDisable(true);
        setCheckBoxHide(true);
        setTableHeading("Applications Closed");
        setincubatorListCountHead(incubatorListingCounts.closed);
        setSubmitBTN(true);
      } else if (selected_id == "tranche") {
        isRejected.current = 1;
        setIsChecked(false);
        setButtonDisable(true);
        setCheckBoxHide(true);
        setTableHeading("Tranche Requested");
        setincubatorListCountHead(incubatorListingCounts.trancheRequest);
        setSubmitBTN(false);
      } else if (selected_id == "cancelled") {
        isRejected.current = 1;
        setIsChecked(false);
        setButtonDisable(true);
        setCheckBoxHide(true);
        setTableHeading("Applications Cancelled");
        setincubatorListCountHead(incubatorListingCounts.cancelled);
        setSubmitBTN(false);
      }
      else if (selected_id == "cancel") {
        isRejected.current = 1;
        setIsChecked(false);
        setButtonDisable(true);
        setCheckBoxHide(true);
        setTableHeading("Cancellation Requested");
        setincubatorListCountHead(incubatorListingCounts.cancel);
        setSubmitBTN(false);
      }else if (selected_id == "system_rejected") {
        isRejected.current = 0
        setIsChecked(false);
        setButtonDisable(true);
        setCheckBoxHide(true);
        setTableHeading("System Rejected");
        setincubatorListCountHead(incubatorListingCounts.system_rejected);
        setSubmitBTN(false);
      }
      setActiveIndex(selected_id);
      setincubatorList([]);
      var payload = {};
      if (selected_id == "tranche") {
        payload = {
          logged_user_id: userInfo.id,
          userRole: userInfo.roles,
          tranche_requested: "Yes",
          list_from: "incubator",
          incubator_id: incubatorUserId,
          // leagel_entity_id:
          //     selectedEntityValue.length != 0 ? selectedEntityValue : null,
          state_id: selectedStateValue.length != 0 ? selectedStateValue : null,
          sector_id:
            selectedSectorValue.length != 0 ? selectedSectorValue : null,
          submitted_date: filterDate,
          instrument_applying_for: fundingType,
          // startup_funded_incubators: oneStartupCheckbox ? 'yes' : '',
          // no_startup_funded_incubators: noStartupCheckbox ? 'yes' : '',
        };
      } else if (selected_id == "cancel") {
        payload = {
          logged_user_id: userInfo.id,
          userRole: userInfo.roles,
          cancellation_requested: "Yes",
          list_from: "incubator",
          incubator_id: incubatorUserId,
          // leagel_entity_id:
          //     selectedEntityValue.length != 0 ? selectedEntityValue : null,
          state_id: selectedStateValue.length != 0 ? selectedStateValue : null,
          sector_id:
            selectedSectorValue.length != 0 ? selectedSectorValue : null,
          submitted_date: filterDate,
          instrument_applying_for: fundingType,
          // startup_funded_incubators: oneStartupCheckbox ? 'yes' : '',
          // no_startup_funded_incubators: noStartupCheckbox ? 'yes' : '',
        };
      } else if (selected_id == "cancelled") {
        payload = {
          logged_user_id: userInfo.id,
          userRole: userInfo.roles,
          cancellation_approved: "yes",
          list_from: "incubator",
          incubator_id: incubatorUserId,

          state_id: selectedStateValue.length != 0 ? selectedStateValue : null,
          sector_id:
            selectedSectorValue.length != 0 ? selectedSectorValue : null,
          submitted_date: filterDate,
          instrument_applying_for: fundingType,

        };
      } else if (selected_id == "system_rejected") {
        payload = {
          logged_user_id: userInfo.id,
          userRole: userInfo.roles,
          system_rejected: "yes",
          list_from: "incubator",
          incubator_id: incubatorUserId,
          state_id: selectedStateValue.length != 0 ? selectedStateValue : null,
          sector_id:
            selectedSectorValue.length != 0 ? selectedSectorValue : null,
          submitted_date: filterDate,
          instrument_applying_for: fundingType,

        };
      }else {
        payload = {
          logged_user_id: userInfo.id,
          userRole: userInfo.roles,
          status_id: selected_id,
          list_from: "incubator",
          incubator_id: incubatorUserId,
          // leagel_entity_id:
          //     selectedEntityValue.length != 0 ? selectedEntityValue : null,
          state_id: selectedStateValue.length != 0 ? selectedStateValue : null,
          sector_id:
            selectedSectorValue.length != 0 ? selectedSectorValue : null,
          submitted_date: filterDate,
          instrument_applying_for: fundingType,
          // startup_funded_incubators: oneStartupCheckbox ? 'yes' : '',
          // no_startup_funded_incubators: noStartupCheckbox ? 'yes' : '',
        };
      }
      setLoader(true)
      post(GET_STARTUP_LISTING, payload).then((response: any) => {
        setLoader(false)
        if (!response || !response.data) return;
        if (response.data.applications.length != 0) {

          for (let i = 0; i < response.data.applications.length; i++) {
            var d = new Date();
            var last1Day = d.setDate(d.getDate() - 1); // subtract one day
            var formatLast1Day = moment(last1Day).format("YYYY-MM-DD")
            var LastDay = moment(new Date()).format("YYYY-MM-DD")
            if (
              (response.data.applications[i].evaluation_to_be_completed_by < currentdatenew) &&
              (response.data.applications[i].incubator_preferences_data == 6)
            ) {
              let data6 = {
                ...response.data.applications[i],
                newIndex: isRejected.current == 0  ? 1 : 6
              }
                rejectedByYou.push(data6)
             
            }
            else if (
              (response.data.applications[i].evaluation_to_be_completed_by < currentdatenew) &&
              (response.data.applications[i].incubator_preferences_data == 2
                || response.data.applications[i].incubator_preferences_data == 3
                || response.data.applications[i].incubator_preferences_data == 4
                || response.data.applications[i].incubator_preferences_data == 5)
            ) {
              let data1 = {
                ...response.data.applications[i],
                newIndex: 1
              }
              systemRejected.push(data1)
            }
            else if (response.data.applications[i].evaluation_to_be_completed_by == LastDay) {
              let data3 = {
                ...response.data.applications[i],
                newIndex: isRejected.current == 0  ? 1 : 9
              }
              lastDayApplication.push(data3)
            }
            else if (response.data.applications[i].evaluation_to_be_completed_by > formatLast1Day) {
              let data2 = {
                ...response.data.applications[i],
                newIndex: isRejected.current == 0  ? 1 : 2
              }
              openApplication.push(data2)
            }
            else if (
              response.data.applications[i].already_selected_flag != 1 &&
              response.data.applications[i].incubator_preferences_data == 7 &&
              response.data.applications[i].startup_status_id == 7 &&
              (response.data.applications[i].evaluation_to_be_completed_by < currentdatenew)
            ) {
              let data3 = {
                ...response.data.applications[i],
                newIndex: isRejected.current == 0  ? 1 : 3
              }
              allotedYouApplication.push(data3)
            }

            else if (
              response.data.applications[i].already_selected_flag == 1 &&
              response.data.applications[i].incubator_preferences_data == 7 &&
              (response.data.applications[i].startup_status_id == 7 || response.data.applications[i].startup_status_id != 7 )&&
              (response.data.applications[i].evaluation_to_be_completed_by < currentdatenew)
            ) {
              let data4 = {
                ...response.data.applications[i],
                newIndex: isRejected.current == 0  ? 1 : 4
              }
              allotedToAnother.push(data4)

            }

            else if (
              response.data.applications[i].early_closure == "yes"
            ) {
              let data5 = {
                ...response.data.applications[i],
                newIndex: isRejected.current == 0  ? 1 : 5
              }
              earlyClosureData.push(data5)
            }

            else if (
              (response.data.applications[i].total_applroved_amount == response.data.applications[i].total_disbursed_amount) &&
              (response.data.applications[i].incubator_preferences_data == 8)
            ) {
              let data7 = {
                ...response.data.applications[i],
                newIndex: isRejected.current == 0  ? 1 : 7
              }
              fullClosedData.push(data7);
            }

            else if (
              response.data.applications[i].incubator_preferences_data == 9
            ) {
              let data8 = {
                ...response.data.applications[i],
                newIndex: isRejected.current == 0  ? 1 : 8
              }
              cancelledData.push(data8)
            }
            else {
              reamainingData.push(response.data.applications[i])
            }
          }
          setincubatorList([...lastDayApplication, ...openApplication, ...allotedYouApplication, ...allotedToAnother, ...earlyClosureData,
          ...fullClosedData, ...rejectedByYou, ...cancelledData, ...systemRejected, ...reamainingData]);
          // setincubatorList(response.data.applications);
          // console.log(incubatorList,"list3")

          response.data.applications.forEach((item: any, index: any) => {

            ExcelDataId.push(
              item.id
            );

          });
          setExcelpaylod(ExcelDataId);

          setFilteredIncubatorList([]);
          if (
            selected_id == "7" ||
            selected_id == "8" ||
            selected_id == "tranche"
          ) {
            setColumnHideFlag(true);
          } else {
            setColumnHideFlag(false);
          }
        } else {
          setincubatorList([]);
        }
      });
    } else {
      //console.log(selected_id,"incubator sri@@")

      if (selected_id == "2") {
        isRejected.current = 1;
        setIsChecked(false);
        setButtonDisable(true);
        setCheckBoxHide(true);
        setTableHeading("Applications Submitted");
        setincubatorListCountHead(incubatorListingCounts.submitted);
        setSubmitBTN(false);
      } else if (selected_id == "3") {
        isRejected.current = 1;
        setincubatorMeetingType("1");
        if (parseInt(incubatorListingCounts.pendingReview) == 0) {
          setButtonDisable(true);
        } else {
          setButtonDisable(false);
        }
        setCheckBoxHide(false);
        setCheckedArray([]);
        setTableHeading("Applications to be Reviewed");
        setincubatorListCountHead(incubatorListingCounts.pendingReview);
        setSubmitBTN(false);
      } else if (selected_id == "4") {
        isRejected.current = 1;
        setIsChecked(false);
        setButtonDisable(true);
        setCheckBoxHide(true);
        setTableHeading("Applications Incomplete");
        setincubatorListCountHead(incubatorListingCounts.incomplete);
        setSubmitBTN(false);
      } else if (selected_id == "5") {
        isRejected.current = 1;
        setIsChecked(false);
        setButtonDisable(true);
        setCheckBoxHide(true);
        setTableHeading("Applications On Hold");
        setincubatorListCountHead(incubatorListingCounts.onHold);
        setSubmitBTN(false);
      } else if (selected_id == "6") {
        isRejected.current = 1;
        setIsChecked(false);
        setButtonDisable(true);
        setCheckBoxHide(true);
        setTableHeading("Applications Rejected");
        setincubatorListCountHead(incubatorListingCounts.rejected);
        setSubmitBTN(false);
      } else if (selected_id == "7") {
        isRejected.current = 1;
        setincubatorMeetingType("2");
        if (parseInt(incubatorListingCounts.selected) == 0) {
          setButtonDisable(true);
        } else {
          setButtonDisable(false);
        }

        setCheckBoxHide(false);
        setCheckedArray([]);
        setTableHeading("Applications Selected");
        setincubatorListCountHead(incubatorListingCounts.selected);
        setSubmitBTN(true);
      } else if (selected_id == "8") {
        isRejected.current = 1;
        setIsChecked(false);
        setButtonDisable(true);
        setCheckBoxHide(true);
        setTableHeading("Applications Closed");
        setincubatorListCountHead(incubatorListingCounts.closed);
        setSubmitBTN(true);
      } else if (selected_id == "tranche") {
        isRejected.current = 1;
        setIsChecked(false);
        setButtonDisable(true);
        setCheckBoxHide(true);
        setTableHeading("Tranche Requested");
        setincubatorListCountHead(incubatorListingCounts.trancheRequest);
        setSubmitBTN(false);
      } else if (selected_id == "cancelled") {
        isRejected.current = 1;
        setIsChecked(false);
        setButtonDisable(true);
        setCheckBoxHide(true);
        setTableHeading("Applications Cancelled");
        setincubatorListCountHead(incubatorListingCounts.cancelled);
        setSubmitBTN(false);
      } else if (selected_id == "cancel") {
        isRejected.current = 1;
        setIsChecked(false);
        setButtonDisable(true);
        setCheckBoxHide(true);
        setTableHeading("Cancellation Requested");
        setincubatorListCountHead(incubatorListingCounts.cancel);
        setSubmitBTN(false);
      }else if (selected_id == "system_rejected") {
        isRejected.current = 0;
        setIsChecked(false);
        setButtonDisable(true);
        setCheckBoxHide(true);
        setTableHeading("System Rejected");
        setincubatorListCountHead(incubatorListingCounts.system_rejected);
        setSubmitBTN(false);
      }
      setActiveIndex(selected_id);
      setincubatorList([]);
      var payload = {};
      if (selected_id == "tranche") {
        payload = {
          logged_user_id: userInfo.id,
          userRole: userInfo.roles,
          tranche_requested: "Yes",
          // leagel_entity_id:
          //     selectedEntityValue.length != 0 ? selectedEntityValue : null,
          state_id: selectedStateValue.length != 0 ? selectedStateValue : null,
          sector_id:
            selectedSectorValue.length != 0 ? selectedSectorValue : null,
          submitted_date: filterDate,
          instrument_applying_for: fundingType,
          // startup_funded_incubators: oneStartupCheckbox ? 'yes' : '',
          // no_startup_funded_incubators: noStartupCheckbox ? 'yes' : '',
        };
      } else if (selected_id == "cancelled") {
        payload = {
          logged_user_id: userInfo.id,
          userRole: userInfo.roles,
          cancellation_approved: "yes",
          state_id: selectedStateValue.length != 0 ? selectedStateValue : null,
          sector_id: selectedSectorValue.length != 0 ? selectedSectorValue : null,
          submitted_date: filterDate,
          instrument_applying_for: fundingType,
        };
      } else if (selected_id == "cancel") {
        payload = {
          logged_user_id: userInfo.id,
          userRole: userInfo.roles,
          cancellation_requested: "Yes",
          // leagel_entity_id:
          //     selectedEntityValue.length != 0 ? selectedEntityValue : null,
          state_id: selectedStateValue.length != 0 ? selectedStateValue : null,
          sector_id:
            selectedSectorValue.length != 0 ? selectedSectorValue : null,
          submitted_date: filterDate,
          instrument_applying_for: fundingType,
          // startup_funded_incubators: oneStartupCheckbox ? 'yes' : '',
          // no_startup_funded_incubators: noStartupCheckbox ? 'yes' : '',
        };
      } else if (selected_id == "system_rejected") {
        payload = {
          logged_user_id: userInfo.id,
          userRole: userInfo.roles,
          system_rejected: "yes",
          state_id: selectedStateValue.length != 0 ? selectedStateValue : null,
          sector_id: selectedSectorValue.length != 0 ? selectedSectorValue : null,
          submitted_date: filterDate,
          instrument_applying_for: fundingType,
        };
      }else {
        payload = {
          logged_user_id: userInfo.id,
          userRole: userInfo.roles,
          status_id: selected_id,
          // leagel_entity_id:
          //     selectedEntityValue.length != 0 ? selectedEntityValue : null,
          state_id: selectedStateValue.length != 0 ? selectedStateValue : null,
          sector_id:
            selectedSectorValue.length != 0 ? selectedSectorValue : null,
          submitted_date: filterDate,
          instrument_applying_for: fundingType,
          // startup_funded_incubators: oneStartupCheckbox ? 'yes' : '',
          // no_startup_funded_incubators: noStartupCheckbox ? 'yes' : '',
        };
      }
      setLoader(true)
      post(GET_STARTUP_LISTING, payload).then((response: any) => {
        setLoader(false)
        if (!response || !response.data) return;
        if (response.data.applications.length != 0) {
          //console.log(response.data.applications,"@@")
          // //console.log(response.data.applications[0].recognition_number,"$$")

          // //console.log(response.data.applications[0].evaluation_to_be_completed_by < currentdate,response.data.applications[0].startup_status_id, response.data.applications[0].startup_status_id)

          // //console.log(response.data.applications[0].evaluation_to_be_completed_by < currentdate,response.data.applications[0].startup_status_id !== 6 , response.data.applications[0].startup_status_id !== 7)

          for (let i = 0; i < response.data.applications.length; i++) {
            var d = new Date();
            var last1Day = d.setDate(d.getDate() - 1); // subtract one day
            var formatLast1Day = moment(last1Day).format("YYYY-MM-DD")
            var LastDay = moment(new Date()).format("YYYY-MM-DD")
            if (
              (response.data.applications[i].evaluation_to_be_completed_by < currentdatenew) &&
              (response.data.applications[i].incubator_preferences_data == 6)
            ) {
              let data6 = {
                ...response.data.applications[i],
                newIndex: isRejected.current == 0  ? 1 : 6
              }
              rejectedByYou.push(data6)
            }
            else if (
              (response.data.applications[i].evaluation_to_be_completed_by < currentdatenew) &&
              (response.data.applications[i].incubator_preferences_data == 2
                || response.data.applications[i].incubator_preferences_data == 3
                || response.data.applications[i].incubator_preferences_data == 4
                || response.data.applications[i].incubator_preferences_data == 5)
            ) {
              let data1 = {
                ...response.data.applications[i],
                newIndex: 1
              }
              systemRejected.push(data1)
            }
            else if (response.data.applications[i].evaluation_to_be_completed_by == LastDay) {
              let data3 = {
                ...response.data.applications[i],
                newIndex: isRejected.current == 0  ? 1 : 9
              }
              lastDayApplication.push(data3)
            }
            else if (response.data.applications[i].evaluation_to_be_completed_by > formatLast1Day) {
              let data2 = {
                ...response.data.applications[i],
                newIndex: isRejected.current == 0  ? 1 : 2
              }
              openApplication.push(data2)
            }
            else if (
              response.data.applications[i].already_selected_flag != 1 &&
              response.data.applications[i].incubator_preferences_data == 7 &&
              response.data.applications[i].startup_status_id == 7 &&
              (response.data.applications[i].evaluation_to_be_completed_by < currentdatenew)
            ) {
              let data3 = {
                ...response.data.applications[i],
                newIndex: isRejected.current == 0  ? 1 : 3
              }
              allotedYouApplication.push(data3)
            }

            else if (
              response.data.applications[i].already_selected_flag == 1 &&
              response.data.applications[i].incubator_preferences_data == 7 &&
              (response.data.applications[i].startup_status_id == 7 || response.data.applications[i].startup_status_id != 7) &&
              (response.data.applications[i].evaluation_to_be_completed_by < currentdatenew)
            ) {
              let data4 = {
                ...response.data.applications[i],
                newIndex: isRejected.current == 0  ? 1 : 4
              }
              allotedToAnother.push(data4)

            }

            else if (
              response.data.applications[i].early_closure == "yes"
            ) {
              let data5 = {
                ...response.data.applications[i],
                newIndex: isRejected.current == 0  ? 1 : 5
              }
              earlyClosureData.push(data5)
            }

            else if (
              (response.data.applications[i].total_applroved_amount == response.data.applications[i].total_disbursed_amount) &&
              (response.data.applications[i].incubator_preferences_data == 8)
            ) {
              let data7 = {
                ...response.data.applications[i],
                newIndex: isRejected.current == 0  ? 1 : 7
              }
              fullClosedData.push(data7);
            }

            else if (
              response.data.applications[i].incubator_preferences_data == 9
            ) {
              let data8 = {
                ...response.data.applications[i],
                newIndex: isRejected.current == 0  ? 1 : 8
              }
              cancelledData.push(data8)
            }
            else {
              reamainingData.push(response.data.applications[i])
            }
          }
          setincubatorList([...lastDayApplication, ...openApplication, ...allotedYouApplication, ...allotedToAnother, ...earlyClosureData,
          ...fullClosedData, ...rejectedByYou, ...cancelledData, ...systemRejected, ...reamainingData]);

          // newArray = [...openApplication,...allotedYouApplication,...allotedToAnother,earlyClosureData,
          //             ...fullClosedData,...rejectedByYou,...cancelledData,...systemRejected]
          // console.log(newArray,"newArrayyyyy")

          // setincubatorList(response.data.applications);
          // console.log(incubatorList,"list4")
          setTotalApplicationAPI(response.data);
          setQRIntiatedDetails(response.data.QRIntiatedDetails);
          setOrange(response.data.quaterly_review_started);
          setGreen(response.data.all_startups_QR_completed);
          // console.log("response.data",response.data)
          // setEligibility(response.data.eligbility);

          response.data.applications.forEach((item: any, index: any) => {

            ExcelDataId.push(
              item.id
            );

          });
          setExcelpaylod(ExcelDataId);
          setFilteredIncubatorList([]);
          if (
            selected_id == "7" ||
            selected_id == "8" ||
            selected_id == "tranche"
          ) {
            setColumnHideFlag(true);
          } else {
            setColumnHideFlag(false);
          }
        } else {
          setincubatorList([]);
        }

        if (response.data.QRIntiatedDetails && response.data.QRIntiatedDetails.length != 0) {
          if (response.data.QRIntiatedDetails[0].quarter == '1') {
            setQuater('Jan - Mar');
          } else if (response.data.QRIntiatedDetails[0].quarter == '2') {
            setQuater('Apr - Jun');
          } else if (response.data.QRIntiatedDetails[0].quarter == '3') {
            setQuater('Jul - Sep');
          } else if (response.data.QRIntiatedDetails[0].quarter == '4') {
            setQuater('Oct - Dec');
          } else {
            setQuater('');
          }
        }

      });
    }
  };
  const mouseOverEvent = (id: any) => {
    const index = incubatorList.findIndex((x: any) => x.id == id);
    setMouseOverFlag(incubatorList[index].id);
  };

  const handleRowChange = (id: any, isSelected: any, isBlocked: any, ev: any, status_id: any, incubator_preferences_data: any, incubator_id: any) => {
    if (loggedInRole == "incubator") {
      //Previous condition was isSelected == 0 and isBlocked == 0
      if (isSelected == 0 || isSelected == 1) {
        //console.log(id,isSelected,isBlocked,"click")

        if (isBlocked == 0 || isBlocked == 1) {
          //console.log(id,isSelected,isBlocked,"click1")

          let flag = false;
          if (activeIndex == "tranche") {
            flag = true;
          } else {
            flag = false;
          }
          history.replace({
            pathname: "/view_startup_details",
            state: {
              application_id: id,
              logged_in_role: "incubator",
              isEditable: ((ev < currentdatenew) && ((incubator_preferences_data === 2 || incubator_preferences_data === 3 || incubator_preferences_data === 4 || incubator_preferences_data === 5 || incubator_preferences_data === 6) || (isSelected == 1 && incubator_preferences_data == 7 && status_id == 7))) ? false : true,
              showPreference: showPreference,
              isClick: incubator_preferences_data,
              isPref: incubator_preferences_data,
              evaluationDate: ev,
              from: "",

              select_flag: isSelected,

            },
          });
        } else {
          let flag = false;
          if (activeIndex == "tranche") {
            flag = true;
          } else {
            flag = false;
          }
          history.replace({
            pathname: "/view_startup_details",
            state: {
              application_id: id,
              logged_in_role: "incubator",
              isEditable: false,
              showPreference: showPreference,
              isClick: incubator_preferences_data
            },
          });
        }
      }
    } else if (loggedInRole == "secretariat") {
      history.replace({
        pathname: "/secretariat_startup_details",
        state: {
          application_id: id,
          logged_in_role: "secretariat",
          showPreference: showPreference,
          from: props.location.state.from,
          incubator_id: incubator_id

        },
      });
    } else if (loggedInRole == "dpiit") {
      history.replace({
        pathname: "/secretariat_startup_details",
        state: {
          application_id: id,
          logged_in_role: "dpiit",
          showPreference: showPreference,
          from: props.location.state.from,
        },
      });
    } else if (loggedInRole == "eac") {
      history.replace({
        pathname: "/eac_startup_details",
        state: {
          application_id: id,
          logged_in_role: "eac",
          showPreference: showPreference,
          from: props.location.state.from,
        },
      });
    }
  };

  // const mouseOverEvent = (id: any) => {
  //     const index = incubatorList.findIndex(x => x.application_id == id);
  //     setMouseOverFlag(incubatorList[index].application_id)

  // }

  // handeling multiselect checkbox in dropdown value
  const onMultiSelectChange = (selectedIds: Array<string>, name: string) => {
    if (name == "state") {
      setStateValue(selectedIds);
    } else if (name == "sector") {
      setSectorValue(selectedIds);
    }
  };

  const resetFilter = () => {
    setTableHeading("All Applications");
    setActiveIndex("2");
    getDataByStatus("2")
    setStateValue([]);
    setSectorValue([]);
    setOneStartup(false);
    setFilterDate("");
    setFundingType("");
    setShowFilterDot(false);
    setNoStartup(false);
    if (loggedInRole == "dpiit" || loggedInRole == "secretariat") {
      getRestrictedIncubatorCount(props.location.state.user_id);
    } else {
      getIncubatorCount();
    }
  };
  // const [startupStatus,setStartupStatus] = useState<Number>(0);

  var startupStatus: any;
  //get application status
  const getEngagementStatus = () => {
    get(GET_STARTUP_ENGAGEMENT_STATUS).then((response: any) => {
      if (!response || !response.data) return;
      const status = response.data.data.map((item: any) => ({
        label: item.label,
        value: item.id,
      }));
      setEngagementStatus(status);
    });
  };


  const [evalDate, setEvalDate] = useState<any>('')

  const quickEditData = (id: string, item: any) => {
    // console.log(item.startup_status_id,"mohaaaaan")
    startupStatus = item.startup_status_id;
    // console.log(startupStatus,"mohaaaaan")
    setEvalDate(item.evaluation_to_be_completed_by)
    // setquickEditPopupData({
    //   application_comments: "",
    //   application_id: "",
    //   application_status: "",
    //   engagement_status: "",
    //   corporate_id_no: "",
    //   incubator_name: "",
    //   no_of_tranches: "",
    //   date_of_evaluation: "",
    //   total_approved_amount: "",
    // });
    getApplicationStatus();
    getEngagementStatus();
    var payload = {
      "logged_user_id": userInfo.id
    }
    post(`${STORE_STARTUP_APPLICATION_NODE}/${id}/quickEdit`, payload).then(
      (response: any) => {
        if (!response || !response.data) return;
        setquickEditPopupData({
          application_comments: response.data.data.comment,
          application_id: response.data.data.id,
          application_status: response.data.data.startup_status_id,
          engagement_status:
            response.data.data.status_of_engagement != null
              ? response.data.data.status_of_engagement
              : "",
          corporate_id_no: response.data.data.recognition_number,
          incubator_name: response.data.data.name_of_entity,
          no_of_tranches:
            response.data.data.no_of_tranches != null
              ? response.data.data.no_of_tranches
              : "0",
          date_of_evaluation:
            response.data.data.date_of_evaluation != null
              ? moment(response.data.data.date_of_evaluation).format("YYYY-MM-DD")
              : currentdatenew,
          total_approved_amount:
            response.data.data.total_approved_amount != null
              ? response.data.data.total_approved_amount
              : "0",
          total_disbursed_amount:
            response.data.data.amountDisbursed != null
              ? response.data.data.amountDisbursed
              : "0"

        });
        if (response.data.data.startup_status_id == "7") {
          setSelectFlag(false);
        } else if (response.data.data.startup_status_id != "7") {
          setSelectFlag(true);
        }
        //   if (response.data.data.requested_tranchData.length != 0) {
        //     setShowEditTranche(true);
        //   }
        //   else if (response.data.data.requested_tranchData.length == 0) {
        //     setShowEditTranche(false);
        //   }
      }
    );
  };
  const [getStatus, setGetStatus] = useState([])
  //get application status
  const getApplicationStatus = () => {
    get(GET_STARTUP_APPLICATION_STATUS).then((response: any) => {
      if (!response || !response.data) return;
      const status = response.data.data.map((item: any) => ({
        label: item.label,
        value: item.id,
      }));
      const indexOfObject = status.filter((item: any) => item.value == 8 || item.value == 7);
      const indexOfObject2 = status.filter((item: any) => item.value == 7);
      const indexOfObject1 = status.filter((item: any) => item.value != 8 && item.value != 9);

      if ((startupStatus == 8 && (evalDate < currentdatenew))) {
        setApplicationStatus(indexOfObject);
        return;
      }
      if (evalDate < currentdatenew) {

        setGetStatus(indexOfObject2)
      }
      if ((startupStatus == 7 && (evalDate < currentdatenew)) || startupStatus == 8 && (evalDate < currentdatenew)) {
        // || startupStatus == 8 && (evalDate< currentdatenew)
        setApplicationStatus(indexOfObject);

      } else {
        // console.log(indexOfObject1,"indexx")
        setApplicationStatus(indexOfObject1);
      }
      // setApplicationStatus(status);
    });
  };
  const [showQuickEditModal, setShowQuickEditModal] = useState(false)
  const [showValidation, setShowValidation] = useState(false);
  const quickEditCallMOdal = () => {
    if (quickEditPopupData.application_comments == '') {
      setShowValidation(true)
    } else {
      setShowValidation(false)
      setShowQuickEditModal(true)
    }
  }
  const quickEditCall = () => {
    if (quickEditPopupData.application_comments == '' || quickEditPopupData.application_comments == null) {
      setShowValidation(true)
      return;
    } else {
      setShowValidation(false)
    }
    setSelectFlag(true);
    if (quickEditPopupData.application_id != 'undefined') {
      var payload = {};
      payload = {
        comment: quickEditPopupData.application_comments,
        date_of_evaluation: quickEditPopupData.date_of_evaluation,
        startup_status_id: quickEditPopupData.application_status,
        no_of_tranches: quickEditPopupData.no_of_tranches,
        total_approved_amount: quickEditPopupData.total_approved_amount,
        status_of_engagement: quickEditPopupData.engagement_status,
        "logged_user_id": userInfo.id
      };

      setDisabled(true);
      post(
        `${STORE_STARTUP_APPLICATION_NODE}/${quickEditPopupData.application_id}/quickEdit_Post`,
        payload
      ).then((response: any) => {
        //console.log(response);
        if (!response || !response.data) return;
        // console.log(response.data);

        getIncubatorCount();
        setTableHeading("All Applications");
        setActiveIndex("2");

        // setActiveIndex("");
        if (response.data.result == "success") {
          setDialogData({
            header: "Thank You!",
            text: "Application has been Updated Successfully !!!",
          });
          setShowIncubatorSuccessModal(true);
          setDisabled(false);
        } else {
          setFailDialogData({
            header: "Oops,Something went wrong",
            text: response.data.message,
          });
          setShowIncubatorFailureModal(true);
          setDisabled(false);
        }
        //console.log(response.data);
      });
      setquickEditPopupData({
        application_comments: "",
        application_id: "",
        application_status: "",
        engagement_status: "",
        corporate_id_no: "",
        incubator_name: "",
        no_of_tranches: "",
        date_of_evaluation: "",
        total_approved_amount: "",
        total_disbursed_amount: ""
      });
    }
  };

  // apply filter button click function
  const applyFilter = () => {
    if (loggedInRole == "dpiit" || loggedInRole == "secretariat") {
      if (
        selectedStateValue.length != 0 ||
        selectedSectorValue.length != 0 ||
        filterDate != "" ||
        fundingType != ""
      ) {
        setShowFilterDot(true);
      } else {
        setShowFilterDot(false);
      }

      setTableHeading("All Applications");
      setActiveIndex("2");
      getDataByStatus("2");
      var payload = {};
      payload = {
        logged_user_id: userInfo.id,
        userRole: userInfo.roles,
        list_from: "incubator",
        incubator_id: incubatorUserId,
        // leagel_entity_id:
        //     selectedEntityValue.length != 0 ? selectedEntityValue : null,
        state_id: selectedStateValue.length != 0 ? selectedStateValue : null,
        sector_id: selectedSectorValue.length != 0 ? selectedSectorValue : null,
        submitted_date: filterDate,
        instrument_applying_for: fundingType,
        intial_call: 'yes',
        status_id: [2],
        // startup_funded_incubators: oneStartupCheckbox ? 'yes' : '',
        // no_startup_funded_incubators: noStartupCheckbox ? 'yes' : '',
      };
      setLoader(true)
      post(GET_STARTUP_LISTING, payload).then((response: any) => {
        setLoader(false)
        if (!response || !response.data) return;
        const counts = {
          incomplete: "0",
          onHold: "0",
          pendingReview: "0",
          rejected: "0",
          selected: "0",
          submitted: "0",
          reviewCount: response.data.reviewCount
            ? response.data.reviewCount
            : "0",
          trancheRequest: response.data.tranceRequestedCount,
          closed: "0",
          cancel: response.data.cancellationRequestedCount,
          cancelled: response.data.cancellationApprovedCount,
          system_rejected: response.data.system_rejected,
        };
        const status_id = {
          incomplete_id: "",
          onHold_id: "",
          pendingReview_id: "",
          rejected_id: "",
          selected_id: "",
          submitted_id: "",
          trancheRequest_id: "",
          closed_id: "",
          cancelled_id: "",
        system_rejected_id : ""
        };
        for (var i = 0; i < response.data.mainCount.length; i++) {
          if (response.data.mainCount[i].name == "incomplete") {
            counts.incomplete = response.data.mainCount[i].appln_count;
            status_id.incomplete_id = response.data.mainCount[i].id;
          } else if (response.data.mainCount[i].name == "pending_review") {
            counts.pendingReview = response.data.mainCount[i].appln_count;
            status_id.pendingReview_id = response.data.mainCount[i].id;
          } else if (response.data.mainCount[i].name == "submitted") {
            counts.submitted = response.data.mainCount[i].appln_count;
            status_id.submitted_id = response.data.mainCount[i].id;
          } else if (response.data.mainCount[i].name == "on_hold") {
            counts.onHold = response.data.mainCount[i].appln_count;
            status_id.onHold_id = response.data.mainCount[i].id;
          } else if (response.data.mainCount[i].name == "rejected") {
            counts.rejected = response.data.mainCount[i].appln_count;
            status_id.rejected_id = response.data.mainCount[i].id;
          } else if (response.data.mainCount[i].name == "selected") {
            counts.selected = response.data.mainCount[i].appln_count;
            status_id.selected_id = response.data.mainCount[i].id;
          } else if (response.data.mainCount[i].name == "closed") {
            counts.closed = response.data.mainCount[i].appln_count;
            status_id.closed_id = response.data.mainCount[i].id;
          }
          // else if (response.data.mainCount[i].name == 'cancelled') {
          //     counts.cancelled = response.data.mainCount[i].appln_count;
          //     status_id.cancelled_id = response.data.mainCount[i].id;
          // }
        }

        setIncubatorListingCounts(counts);
        setIncubatorListId(status_id);
        setincubatorListCountHead(response.data.applications.length);
        // console.log(incubatorListCountHead);
        if (response.data.applications.length != 0) {
          for (let i = 0; i < response.data.applications.length; i++) {
            var d = new Date();
            var last1Day = d.setDate(d.getDate() - 1); // subtract one day
            var formatLast1Day = moment(last1Day).format("YYYY-MM-DD")
            var LastDay = moment(new Date()).format("YYYY-MM-DD")
            if (
              (response.data.applications[i].evaluation_to_be_completed_by < currentdatenew) &&
              (response.data.applications[i].incubator_preferences_data == 6)
            ) {
              let data6 = {
                ...response.data.applications[i],
                newIndex: 6
              }
              rejectedByYou.push(data6)
            }
            else if (
              (response.data.applications[i].evaluation_to_be_completed_by < currentdatenew) &&
              (response.data.applications[i].incubator_preferences_data == 2
                || response.data.applications[i].incubator_preferences_data == 3
                || response.data.applications[i].incubator_preferences_data == 4
                || response.data.applications[i].incubator_preferences_data == 5)
            ) {
              let data1 = {
                ...response.data.applications[i],
                newIndex: 1
              }
              systemRejected.push(data1)
            }
            else if (response.data.applications[i].evaluation_to_be_completed_by == LastDay) {
              let data3 = {
                ...response.data.applications[i],
                newIndex: 9
              }
              lastDayApplication.push(data3)
            }
            else if (response.data.applications[i].evaluation_to_be_completed_by > formatLast1Day) {
              let data2 = {
                ...response.data.applications[i],
                newIndex: 2
              }
              openApplication.push(data2)
            }
            else if (
              response.data.applications[i].already_selected_flag != 1 &&
              response.data.applications[i].incubator_preferences_data == 7 &&
              response.data.applications[i].startup_status_id == 7 &&
              (response.data.applications[i].evaluation_to_be_completed_by < currentdatenew)
            ) {
              let data3 = {
                ...response.data.applications[i],
                newIndex: 3
              }
              allotedYouApplication.push(data3)
            }

            else if (
              response.data.applications[i].already_selected_flag == 1 &&
              response.data.applications[i].incubator_preferences_data == 7 &&
              response.data.applications[i].startup_status_id == 7 &&
              (response.data.applications[i].evaluation_to_be_completed_by < currentdatenew)
            ) {
              let data4 = {
                ...response.data.applications[i],
                newIndex: 4
              }
              allotedToAnother.push(data4)

            }

            else if (
              response.data.applications[i].early_closure == "yes"
            ) {
              let data5 = {
                ...response.data.applications[i],
                newIndex: 5
              }
              earlyClosureData.push(data5)
            }

            else if (
              (response.data.applications[i].total_applroved_amount == response.data.applications[i].total_disbursed_amount) &&
              (response.data.applications[i].incubator_preferences_data == 8)
            ) {
              let data7 = {
                ...response.data.applications[i],
                newIndex: 7
              }
              fullClosedData.push(data7);
            }

            else if (
              response.data.applications[i].incubator_preferences_data == 9
            ) {
              let data8 = {
                ...response.data.applications[i],
                newIndex: 8
              }
              cancelledData.push(data8)
            }
            else {
              reamainingData.push(response.data.applications[i])
            }
          }
          setincubatorList([...lastDayApplication, ...openApplication, ...allotedYouApplication, ...allotedToAnother, ...earlyClosureData,
          ...fullClosedData, ...rejectedByYou, ...cancelledData, ...systemRejected, ...reamainingData]);
          // setincubatorList(response.data.applications);
          // console.log(incubatorList, "list6")

          response.data.applications.forEach((item: any, index: any) => {

            ExcelDataId.push(
              item.id
            );

          });
          setExcelpaylod(ExcelDataId);

          setFilteredIncubatorList([]);
          getDataByStatus("2");
        } else {
          setincubatorList([]);
        }
        setincubatorListCountHead(response.data.applications.length);
      });
    } else {
      if (
        selectedStateValue.length != 0 ||
        selectedSectorValue.length != 0 ||
        filterDate != "" ||
        fundingType != ""
      ) {
        setShowFilterDot(true);
      } else {
        setShowFilterDot(false);
      }

      setTableHeading("All Applications");
      setActiveIndex("2");
      getDataByStatus("2");
      var payload = {};
      payload = {
        logged_user_id: userInfo.id,
        userRole: userInfo.roles,
        // leagel_entity_id:
        //     selectedEntityValue.length != 0 ? selectedEntityValue : null,
        state_id: selectedStateValue.length != 0 ? selectedStateValue : null,
        sector_id: selectedSectorValue.length != 0 ? selectedSectorValue : null,
        submitted_date: filterDate,
        instrument_applying_for: fundingType,
        intial_call: 'yes',
        status_id: [2],
        // startup_funded_incubators: oneStartupCheckbox ? 'yes' : '',
        // no_startup_funded_incubators: noStartupCheckbox ? 'yes' : '',
      };
      setLoader(true)
      post(GET_STARTUP_LISTING, payload).then((response: any) => {

        setLoader(false)
        // console.log(response.data.applications,"apply")

        if (!response || !response.data) return;
        const counts = {
          incomplete: "0",
          onHold: "0",
          pendingReview: "0",
          rejected: "0",
          selected: "0",
          submitted: "0",
          reviewCount: response.data.reviewCount
            ? response.data.reviewCount
            : "0",
          trancheRequest: response.data.tranceRequestedCount,
          closed: "0",
          cancel: response.data.cancellationRequestedCount,
          cancelled: response.data.cancellationApprovedCount,
          system_rejected : response.data.system_rejected
        };
        const status_id = {
          incomplete_id: "",
          onHold_id: "",
          pendingReview_id: "",
          rejected_id: "",
          selected_id: "",
          submitted_id: "",
          trancheRequest_id: "",
          closed_id: "",
          cancelled_id: "", 
        system_rejected_id : ""
        };
        for (var i = 0; i < response.data.mainCount.length; i++) {
          if (response.data.mainCount[i].name == "incomplete") {
            counts.incomplete = response.data.mainCount[i].appln_count;
            status_id.incomplete_id = response.data.mainCount[i].id;
          } else if (response.data.mainCount[i].name == "pending_review") {
            counts.pendingReview = response.data.mainCount[i].appln_count;
            status_id.pendingReview_id = response.data.mainCount[i].id;
          } else if (response.data.mainCount[i].name == "submitted") {
            counts.submitted = response.data.mainCount[i].appln_count;
            status_id.submitted_id = response.data.mainCount[i].id;
          } else if (response.data.mainCount[i].name == "on_hold") {
            counts.onHold = response.data.mainCount[i].appln_count;
            status_id.onHold_id = response.data.mainCount[i].id;
          } else if (response.data.mainCount[i].name == "rejected") {
            counts.rejected = response.data.mainCount[i].appln_count;
            status_id.rejected_id = response.data.mainCount[i].id;
          } else if (response.data.mainCount[i].name == "selected") {
            counts.selected = response.data.mainCount[i].appln_count;
            status_id.selected_id = response.data.mainCount[i].id;
          } else if (response.data.mainCount[i].name == "closed") {
            counts.closed = response.data.mainCount[i].appln_count;
            status_id.closed_id = response.data.mainCount[i].id;
          }
          // else if (response.data.mainCount[i].name == 'cancelled') {
          //     counts.cancelled = response.data.mainCount[i].appln_count;
          //     status_id.cancelled_id = response.data.mainCount[i].id;
          // }
        }
        setIncubatorListingCounts(counts);
        setIncubatorListId(status_id);
        setincubatorListCountHead(response.data.applications.length);
        if (response.data.applications.length != 0) {
          for (let i = 0; i < response.data.applications.length; i++) {
            var d = new Date();
            var last1Day = d.setDate(d.getDate() - 1); // subtract one day
            var formatLast1Day = moment(last1Day).format("YYYY-MM-DD")
            var LastDay = moment(new Date()).format("YYYY-MM-DD")
            if (
              (response.data.applications[i].evaluation_to_be_completed_by < currentdatenew) &&
              (response.data.applications[i].incubator_preferences_data == 6)
            ) {
              let data6 = {
                ...response.data.applications[i],
                newIndex: 6
              }
              rejectedByYou.push(data6)
            }
            else if (
              (response.data.applications[i].evaluation_to_be_completed_by < currentdatenew) &&
              (response.data.applications[i].incubator_preferences_data == 2
                || response.data.applications[i].incubator_preferences_data == 3
                || response.data.applications[i].incubator_preferences_data == 4
                || response.data.applications[i].incubator_preferences_data == 5)
            ) {
              let data1 = {
                ...response.data.applications[i],
                newIndex: 1
              }
              systemRejected.push(data1)
            }
            else if (response.data.applications[i].evaluation_to_be_completed_by == LastDay) {
              let data3 = {
                ...response.data.applications[i],
                newIndex: 9
              }
              lastDayApplication.push(data3)
            }
            else if (response.data.applications[i].evaluation_to_be_completed_by > formatLast1Day) {
              let data2 = {
                ...response.data.applications[i],
                newIndex: 2
              }
              openApplication.push(data2)
            }
            else if (
              response.data.applications[i].already_selected_flag != 1 &&
              response.data.applications[i].incubator_preferences_data == 7 &&
              response.data.applications[i].startup_status_id == 7 &&
              (response.data.applications[i].evaluation_to_be_completed_by < currentdatenew)
            ) {
              let data3 = {
                ...response.data.applications[i],
                newIndex: 3
              }
              allotedYouApplication.push(data3)
            }

            else if (
              response.data.applications[i].already_selected_flag == 1 &&
              response.data.applications[i].incubator_preferences_data == 7 &&
              response.data.applications[i].startup_status_id == 7 &&
              (response.data.applications[i].evaluation_to_be_completed_by < currentdatenew)
            ) {
              let data4 = {
                ...response.data.applications[i],
                newIndex: 4
              }
              allotedToAnother.push(data4)

            }

            else if (
              response.data.applications[i].early_closure == "yes"
            ) {
              let data5 = {
                ...response.data.applications[i],
                newIndex: 5
              }
              earlyClosureData.push(data5)
            }

            else if (
              (response.data.applications[i].total_applroved_amount == response.data.applications[i].total_disbursed_amount) &&
              (response.data.applications[i].incubator_preferences_data == 8)
            ) {
              let data7 = {
                ...response.data.applications[i],
                newIndex: 7
              }
              fullClosedData.push(data7);
            }

            else if (
              response.data.applications[i].incubator_preferences_data == 9
            ) {
              let data8 = {
                ...response.data.applications[i],
                newIndex: 8
              }
              cancelledData.push(data8)
            }
            else {
              reamainingData.push(response.data.applications[i])
            }
          }
          setincubatorList([...lastDayApplication, ...openApplication, ...allotedYouApplication, ...allotedToAnother, ...earlyClosureData,
          ...fullClosedData, ...rejectedByYou, ...cancelledData, ...systemRejected, ...reamainingData]);
          // setincubatorList(response.data.applications);
          // console.log(incubatorList, "list7")

          response.data.applications.forEach((item: any, index: any) => {

            ExcelDataId.push(
              item.id
            );

          });
          setExcelpaylod(ExcelDataId);

          setFilteredIncubatorList([]);
          getDataByStatus("2")
        } else {
          setincubatorList([]);
        }
        setincubatorListCountHead(response.data.applications.length);
      });

    }

  };
  // allcheckbox check
  const handleAllCheck = (e: any /*index:number*/) => {
    var name = e.target.name;
    // console.log(e.target.name, "nameeeee")
    var isChecked = e.target.checked;
    if (isChecked) {
      setCheckedArray([]);
      // console.log(incubatorList,"incubatorList")
      incubatorList.map((item: any) => {

        if (item.evaluation_to_be_completed_by > currentdatenew) {
          setCheckedArray((oldArray) => [...oldArray, item.id]);
        } else if ((item.evaluation_to_be_completed_by < currentdatenew) && (item.already_selected_flag == 0)) {

          if (item.incubator_preferences_data !== 2 && item.incubator_preferences_data !== 3 && item.incubator_preferences_data !== 4 && item.incubator_preferences_data !== 5) {
            setCheckedArray((oldArray) => [...oldArray, item.id]);
          }
        }


      });
      setIsChecked(true);

    } else if (isChecked == false) {
      setIsChecked(false);
      setCheckedArray([]);
    }
  };
  const handleListChange = (e: any) => {
    var value: any = Number(e.target.value);
    var isChecked = e.target.checked;

    if (isChecked) {
      setButtonDisable(false);
      if (!checkedArray.includes(value)) {
        setCheckedArray((oldArray: any) => [...oldArray, value]);
      }
    } else {
      if (checkedArray.includes(value)) {
        setCheckedArray(checkedArray.filter((item: any) => item !== value));
      }
    }
  };






  // const handleAllCheck = (e: any /*index:number*/) => {
  //   var name = e.target.name;
  //   // console.log(e.target.name, "nameeeee")
  //   var isChecked = e.target.checked;
  //   if (isChecked) {
  //     setCheckedArray([]);
  //     incubatorList.map(({ incubator_application_no }) =>
  //       setCheckedArray((oldArray) => [...oldArray, incubator_application_no])
  //     );
  //     setIsChecked(true);
  //   } else if (isChecked == false) {
  //     setIsChecked(false);
  //     setCheckedArray([]);
  //   }
  // };
  // setCheckedArrayCount(checkedArray.length);

  // const handleListChange = (e: any /*index:number*/) => {
  //   var value = e.target.value;
  //   var isChecked = e.target.checked;

  //   if (isChecked) {
  //     setButtonDisable(false);
  //     if (checkedArray.includes(value) == false) {
  //       setCheckedArray((oldArray) => [...oldArray, value]);
  //     }
  //   } else if (isChecked == false) {
  //     if (checkedArray.includes(value)) {
  //       setCheckedArray(checkedArray.filter((item) => item !== value));
  //     }
  //   }
  // };

  // on incubator dropdown change
  const handleOnChange = (e: any /*index:number*/) => {
    var value = e.target.value;
    setincubatorMeetingType(value);
  };

  // trrigers on date change
  const handleDateChange = (date: Date, field: string) => {
    setScheduleDate(formatDate(date));
    setSelectedDateControl(date);
    if (field == "submissionDate") {
      setFilterDate(formatDate(date));
    }
  };

  // function to format date
  function formatDate(date: Date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  // on change time;
  const onTimeChange = (e: any /*index:number*/) => {
    var value = e.target.value;
    var name = e.target.name;
    if (name == "fromTime") {
      setFromTime(value);
    } else if (name == "toTime") {
      setToTime(value);
    }
  };

  const scheduleMeetingFunction = () => {
    var time = new Date();
    var curTime = moment(time, "HH:mm").format("HH:mm");
    if (!compareTime(fromTime, toTime)) {
      setTimeValidation(true);
      setTimeValidationCurrentTime(false);
      return false;
    } else if (compareTime(fromTime, toTime)) {
      setTimeValidation(false);
      setTimeValidationCurrentTime(false);
    }
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    const data = {

      meeting_date: scheduleDate != "" ? scheduleDate : formatDate(new Date()),
      meeting_from_time: fromTime != "" ? fromTime : "",
      meeting_to_time: toTime != "" ? toTime : "",
      meeting_type: incubatorMeetingType != "" ? incubatorMeetingType : "",
      link: meetingLink,
      startup_application_ids: checkedArray.length != 0 ? checkedArray : [],
      warning_ignore: 0,
      "startup_incubator_id": userInfo.id
    };
    if (meetingLink == "") {
      setLinkValidation(true);
      return false;
    }
    if (
      formatDate(new Date()) == data.meeting_date &&
      compareTime(fromTime, curTime)
    ) {
      setTimeValidationCurrentTime(true);
      setLinkValidation(false);
      setTimeValidation(false);
      return false;
    }
    if (checkedArray.length == 0) {
      setFailDialogData({
        header: "",
        text: "Please select atleast one Startup!!",
      });
      setShowIncubatorFailureModal(true);
      return;
    }
    setDisabled(true);
    setTimeValidation(false);
    setTimeValidationCurrentTime(false);
    setLoader(true);
    post(STARTUP_SCHEDULE_MEETING, data).then((response: any) => {
      setLoader(false);
      if (!response || !response.data) return;
      if (response.data.result == "success") {
        setDialogData({
          header: "Thank You!",
          text: "Your meeting has been scheduled !!!",
        });
        setShowIncubatorSuccessModal(true);

        setDisabled(false);
      } else if (response.data.result == "warning") {
        setConfirmationDialogData({
          header: "Meeting Already Exist",
          text: response.data.message,
        });
        setShowConfirmationBox(true);
        setDisabled(false);
      } else if (response.data.result == "error") {
        setFailDialogData({
          header: "Oops !!",
          text: response.data.message,
        });
        setShowIncubatorFailureModal(true);
        setDisabled(false);
      } else {
        setFailDialogData({
          header: "Oops !!",
          text: "Internal Server Error",
        });
        setShowIncubatorFailureModal(true);
        setDisabled(false);
      }
    });
  };

  const finalSchedule = () => {
    const data = {
      meeting_date: scheduleDate != "" ? scheduleDate : formatDate(new Date()),
      meeting_from_time: fromTime != "" ? fromTime : "",
      meeting_to_time: toTime != "" ? toTime : "",
      meeting_type: incubatorMeetingType != "" ? incubatorMeetingType : "",
      startup_application_ids: checkedArray.length != 0 ? checkedArray : [],
      link: meetingLink,
      warning_ignore: 1,
      startup_incubator_id: userInfo.id
    };
    setDisabled(true);
    post(STARTUP_SCHEDULE_MEETING, data).then((response: any) => {
      if (!response || !response.data) return;
      setShowConfirmationBox(false);
      if (response.data.result == "success") {
        setDialogData({
          header: "Thank You!",
          text: "Your meeting has been scheduled !!!",
        });

        setShowIncubatorSuccessModal(true);
        setDisabled(false);
      } else {
        setFailDialogData({
          header: "Oops !!",
          text: response.data.message,
        });
        setShowIncubatorFailureModal(true);
        setDisabled(false);
      }
    });
  };

  const handlePopupDateChange = (date: Date, field: string) => {
    setquickEditPopupData((prevState) => ({
      ...prevState,
      date_of_evaluation: formatDate(date),
    }));
  };

  // popup change
  const handlePopupChange = (e: any /*index:number*/) => {
    var value = e.target.value
    var name = e.target.name;
    if (name == "status_id") {
      setquickEditPopupData((prevState) => ({
        ...prevState,
        application_status: value,
      }));
      setquickEditPopupData((prevState) => ({
        ...prevState,
        application_comments: "",
      }));
      if (value == "7") {
        setSelectFlag(false);
      } else if (value != "7") {
        setSelectFlag(true);
      }
    } else if (name == "popup_comments") {
      if (value != '') {
        setShowValidation(false)
      }
      setquickEditPopupData((prevState) => ({
        ...prevState,
        application_comments: value,
      }));
    } else if (name == "engagement_status") {
      setquickEditPopupData((prevState) => ({
        ...prevState,
        engagement_status: value,
      }));
    } else if (name == "no_of_tranches") {
      setquickEditPopupData((prevState) => ({
        ...prevState,
        no_of_tranches: value,
      }));
    } else if (name == "total_approved_amount") {
      setquickEditPopupData((prevState) => ({
        ...prevState,
        total_approved_amount: value.toString().split(".").map((el: any, i: any) => i ? el.split("").slice(0, 2).join("") : el).join("."),
      }));
    }
  };

  const dismissModal = () => {
    $('#quickEdit').modal('hide');
    setShowIncubatorSuccessModal(false);
    setShowIncubatorFailureModal(false);
    getIncubatorCount();
    // getAllStates();
    // getAllSectors();

    $('#scheduleMeeting').modal('hide');
    // setShowEditFailureModal(false);
    // setShowEditSuccessModal(false);
    // window.location.reload();
  };

  const downloadExcel = () => {
    // console.log("hellloo")
    var payload = {};
    if (loggedInRole == "secretariat" || loggedInRole == "dpiit") {
      // console.log(ExcelDataId,"SECID")
      if (activeIndex == "tranche") {
        payload = {
          // applications_type: "startup",
          // tranche_requested: "Yes",
          // list_from: "incubator",
          // incubator_id: incubatorUserId,
          // state_id: selectedStateValue.length != 0 ? selectedStateValue : null,
          // sector_id:
          //   selectedSectorValue.length != 0 ? selectedSectorValue : null,
          // submitted_date: filterDate,
          // instrument_applying_for: fundingType,
          "startup_ids": Excelpaylod
        };
      } else if (activeIndex == "cancel") {
        payload = {
          // applications_type: "startup",
          // cancellation_requested: "Yes",
          // list_from: "incubator",
          // incubator_id: incubatorUserId,
          // state_id: selectedStateValue.length != 0 ? selectedStateValue : null,
          // sector_id:
          //   selectedSectorValue.length != 0 ? selectedSectorValue : null,
          // submitted_date: filterDate,
          // instrument_applying_for: fundingType,
        };
      } else {
        payload = {
          // applications_type: "startup",
          // status_id: [...activeIndex],
          // list_from: "incubator",
          // incubator_id: incubatorUserId,
          // state_id: selectedStateValue.length != 0 ? selectedStateValue : null,
          // sector_id:
          //   selectedSectorValue.length != 0 ? selectedSectorValue : null,
          // submitted_date: filterDate,
          // instrument_applying_for: fundingType,
          "startup_ids": Excelpaylod
        };
      }
      // //console.log(payload,"payload1")
      setLoader(true);
      post(DOWNLOAD_EXCEL, payload).then((response: any) => {
        setLoader(false);
        if (!response || !response.data) return;
        if (response.data.result == "success") {
          var ExcelName = "Startup_Applications.xlsx"
          exportToExcel(response.data.data, ExcelName);
          // window.open(`${File_node_url}` + response.data.data);
          // downloadDocument(`${File_node_url}` + response.data.data, "startup_list");
        } else if (response.data.result == "fail") {
          setFailDialogData({
            header: "Oops !!",
            text: response.data.message,
          });
          setShowIncubatorFailureModal(true);
        }

      });
    } else {
      if (activeIndex == "tranche") {
        payload = {
          "startup_ids": Excelpaylod
          // applications_type: "startup",
          // tranche_requested: "Yes",
          // state_id: selectedStateValue.length != 0 ? selectedStateValue : null,
          // sector_id:
          //   selectedSectorValue.length != 0 ? selectedSectorValue : null,
          // submitted_date: filterDate,
          // instrument_applying_for: fundingType,
        };
      } else if (activeIndex == "cancel") {
        payload = {
          "startup_ids": Excelpaylod

          // applications_type: "startup",
          // cancellation_requested: "Yes",
          // state_id: selectedStateValue.length != 0 ? selectedStateValue : null,
          // sector_id:
          //   selectedSectorValue.length != 0 ? selectedSectorValue : null,
          // submitted_date: filterDate,
          // instrument_applying_for: fundingType,
        };
      } else {
        payload = {
          "startup_ids": Excelpaylod

          // applications_type: "startup",
          // status_id: activeIndex,
          // state_id: selectedStateValue.length != 0 ? selectedStateValue : null,
          // sector_id:
          //   selectedSectorValue.length != 0 ? selectedSectorValue : null,
          // submitted_date: filterDate,
          // instrument_applying_for: fundingType,
        };
      }
      setLoader(true);
      post(DOWNLOAD_EXCEL, payload).then((response: any) => {
        setLoader(false);
        if (!response || !response.data) return;
        if (response.data.result == "success") {
          var SheetName = "Startup_Applications.xlsx"
          exportToExcel(response.data.data, SheetName);
          // window.open(`${File_node_url}` + response.data.data);
          // downloadDocument(`${File_node_url}` + response.data.data, "startup_list");
        } else if (response.data.result == "fail") {
          setFailDialogData({
            header: "Oops !!",
            text: response.data.message,
          });
          setShowIncubatorFailureModal(true);
        }
      });
    }
  };


  // const exportToExcel = (jsonData: any) => {
  //   const worksheet = XLSX.utils.json_to_sheet(jsonData, { header: [], skipHeader: true });
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  //   const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  //   const excelBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
  //   const fileName = 'Startup_Applications.xlsx';
  //   const excelUrl = URL.createObjectURL(excelBlob);
  //   const a = document.createElement('a');
  //   a.href = excelUrl;
  //   a.download = fileName;
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  //   URL.revokeObjectURL(excelUrl);
  // };




  // const SubmitQRClick = () => {
  //   if (Orange == 1 && Green == 0) {
  //     $("#QRPending").modal('show');
  //   } else if (Orange == 1 && Green == 1) {
  //     $("#QRSuccess").modal('show');
  //   }
  // }



 
  // const SubmitQRClick = () => {
  //   console.log("Heloo Amitha")
  //   console.log("Orange",Orange)
  //   console.log("Green",Green)
  //   console.log("Eligbility",Eligbility)
  //   if (
  //     (Orange == 1 && Green == 0)||
  //     (Orange == 1 && Green == 1 && Eligbility == 0)
  //     ) {
  //     console.log("Into If")
  //     $("#QRPending").modal('show');
  //   }  
  //   else if ( 
  //     (Orange == 1 && Green == 1 && Eligbility == 1)
  //     ) {
  //     console.log("Into Else If")
  //     $("#QRSuccess").modal('show');
  //   } 

  //   // else if (Orange == 1 && Green == 1) {
  //   //   console.log("Into Else if")
  //   //   $("#QRSuccess").modal('show');
  //   // } 
  //   // else if ((Orange == 1 && Green == 1) || (Orange == 1 && Green == 0 && Eligbility == 1) || (Orange == 0 && Green == 1 && Eligbility == 1) ) {
  //   //   console.log("Into Else If 2")
  //   //   $("#QRSuccess").modal('show');
  //   // }
  // }

  const [returnsFormSubmit, setreturnsFormSubmit] = useState<any>({
    success_story: "",
    intrest_earneed: "",
    additional_information: "",
  })

  const handleChange = (event: any) => {
    const { name, value, type } = event.target;
    const newValue = name == "intrest_earneed" ? value.replace(/[^0-9]/ig, '') : value

    setreturnsFormSubmit((prevFormData: any) => ({
      ...prevFormData,
      [name]: newValue,
    }));

  };


  const Submit_Quaterly_Review = (e: any) => {
    e.preventDefault();
    $('#LastSubmitForm').modal('show')
  }

  const ConformationClick = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    let payload = {
      "incubator_id": userInfo.id,
      "QR_ID": QRIntiatedDetails[0].id,
      "success_story": returnsFormSubmit.success_story,
      "additional_information": returnsFormSubmit.additional_information,
      "intrest_earneed": returnsFormSubmit.intrest_earneed,
      "logged_user_id": userInfo.id
    }
    post(QUATERLY_SUCCESS, payload).then((res: any) => {
      if (res.data.result == "success") {
        window.location.reload();
      }
    })

  }
  return (
    <>
      {loader &&
        <div className="spinner-border custom-spinner" role="status">
          <span className="sr-only">Loading...</span>
        </div>}
      <fieldset disabled={loader}>
        <div className="app-inside app-startup-listing">
          <div className="startup-listing-wrapper mt-4">
            <div className="container-fluid container-min pl-5 pr-5 pt-2">
              <div className="app-inside-content">
                <div className="row mb-3">
                  <div className="col-md-6">
                    <p className="dashboard pt-2" style={{ textDecoration: 'underline', fontSize: '30px' }}>Startups</p>
                  </div>
                  {/* <div className="col-md-5 text-right ">
                  <div className="d-flex searchDiv" style={{float:'right'}}>
                    {" "}
                    <img src={search} className="search" alt="search" />
                    <input
                      className="searchBar"
                      type="text"
                      placeholder=" Search.."
                      name="search"
                      onKeyUp={searchList}
                    />
                  </div>
                </div>
                <div className="col-md-1 filter-icon">
                  <img
                    src={filter}
                    className="img-fluid filter"
                    alt="SISFS Logo"
                    data-toggle="modal"
                    data-target="#filterModal"
                    style={{float:'right'}}
                    />
                  {showFilterDot == true ? <mark></mark> : null}
                </div> */}
                </div>
                {QRIntiatedDetails && QRIntiatedDetails.length > 0 &&
                  <div className="mt-3 mb-3">
                    <b><p style={{ backgroundColor: '#f8f7f7', padding: '9px', width: 'max-content', marginLeft: 'auto', marginRight: 'auto' }}>Quarterly Review of Startup Progress Initiated by department for the months of {quater1} {QRIntiatedDetails && QRIntiatedDetails[0].year}, (here is a {" "}
                      <a
                        href={`${File_node_url}Seed Fund - Incubator QR Guide.pdf`}
                        target="_blank">
                        quick guide
                      </a>
                      )</p></b>
                  </div>}

                <div className="d-flex flex-row justify-content-center cardDiv">
                  <div
                    className="incubator-card"
                    onClick={() =>
                      getDataByStatus(`${incubatorListId.submitted_id}`)
                    }
                  >
                    <div
                      className={
                        activeIndex == incubatorListId.submitted_id
                          ? "rectangle-card-selected"
                          : "rectangle-card"
                      }
                    >
                      <span
                        className={
                          activeIndex == incubatorListId.submitted_id
                            ? "smallIncubatorNumeric"
                            : "incubator-numeric-black"
                        }
                      >
                        {incubatorListingCounts.submitted}
                      </span>
                      <br />
                      <span
                        className={
                          activeIndex == incubatorListId.submitted_id
                            ? "incubator-text-selected-small"
                            : "card-text-incubator"
                        }
                      >
                        Applications Submitted
                      </span>
                    </div>
                  </div>
                  <div
                    className="incubator-card"
                    onClick={() =>
                      getDataByStatus(`${incubatorListId.pendingReview_id}`)
                    }
                  >
                    <div
                      className={
                        activeIndex == incubatorListId.pendingReview_id
                          ? "rectangle-card-selected"
                          : "rectangle-card"
                      }
                    >
                      <span
                        className={
                          activeIndex == incubatorListId.pendingReview_id
                            ? "smallIncubatorNumeric"
                            : "incubator-numeric-black"
                        }
                      >
                        {incubatorListingCounts.pendingReview}
                      </span>
                      <br />
                      <span
                        className={
                          activeIndex == incubatorListId.pendingReview_id
                            ? "incubator-text-selected-small"
                            : "card-text-incubator"
                        }
                      >
                        Applications to
                        <br />
                        &nbsp;be Reviewed
                      </span>
                    </div>
                  </div>
                  <div
                    className="incubator-card"
                    onClick={() =>
                      getDataByStatus(`${incubatorListId.incomplete_id}`)
                    }
                  >
                    <div
                      className={
                        activeIndex == incubatorListId.incomplete_id
                          ? "rectangle-card-selected"
                          : "rectangle-card"
                      }
                    >
                      <span
                        className={
                          activeIndex == incubatorListId.incomplete_id
                            ? "smallIncubatorNumeric"
                            : "incubator-numeric-black"
                        }
                      >
                        {incubatorListingCounts.incomplete}
                      </span>
                      <br />
                      <span
                        className={
                          activeIndex == incubatorListId.incomplete_id
                            ? "incubator-text-selected-small"
                            : "card-text-incubator"
                        }
                      >
                        Applications Incomplete
                      </span>
                    </div>
                  </div>
                  <div
                    className="incubator-card"
                    onClick={() =>
                      getDataByStatus(`${incubatorListId.onHold_id}`)
                    }
                  >
                    <div
                      className={
                        activeIndex == incubatorListId.onHold_id
                          ? "rectangle-card-selected"
                          : "rectangle-card"
                      }
                    >
                      <span
                        className={
                          activeIndex == incubatorListId.onHold_id
                            ? "smallIncubatorNumeric"
                            : "incubator-numeric-black"
                        }
                      >
                        {incubatorListingCounts.onHold}
                      </span>
                      <br />
                      <span
                        className={
                          activeIndex == incubatorListId.onHold_id
                            ? "incubator-text-selected-small"
                            : "card-text-incubator"
                        }
                      >
                        Applications <br />
                        on Hold
                      </span>
                    </div>
                  </div>
                  <div
                    className="incubator-card"
                    onClick={() =>
                      getDataByStatus(`${incubatorListId.selected_id}`)
                    }
                  >
                    <div
                      className={
                        (activeIndex == incubatorListId.selected_id) && (Orange == 1 && Green == 0) ? "rectangle-card-selected-orange" :
                          (activeIndex == incubatorListId.selected_id) && (Orange == 1 && Green == 1) ? "rectangle-card-selected-green" :
                            (Orange == 1 && Green == 0) ? "rectangle-card-selected-orange" :
                              (Orange == 1 && Green == 1) ? "rectangle-card-selected-green" :
                                (activeIndex == incubatorListId.selected_id) ? "rectangle-card-selected" :
                                  "rectangle-card"
                      }
                    >
                      <span
                        className={
                          activeIndex == incubatorListId.selected_id
                            ? "smallIncubatorNumeric"
                            : "incubator-numeric-black"
                        }
                      >
                        {incubatorListingCounts.selected}
                      </span>
                      <br />
                      <span
                        className={
                          activeIndex == incubatorListId.selected_id
                            ? "incubator-text-selected-small"
                            : "card-text-incubator"
                        }
                      >
                        Applications Selected
                      </span>
                    </div>
                  </div>
                  <div
                    className="incubator-card"
                    onClick={() =>
                      getDataByStatus(`${incubatorListId.rejected_id}`)
                    }
                  >
                    <div
                      className={
                        activeIndex == incubatorListId.rejected_id
                          ? "rectangle-card-selected"
                          : "rectangle-card"
                      }
                    >
                      <span
                        className={
                          activeIndex == incubatorListId.rejected_id
                            ? "smallIncubatorNumeric"
                            : "incubator-numeric-black"
                        }
                      >
                        {incubatorListingCounts.rejected}
                      </span>
                      <br />
                      <span
                        className={
                          activeIndex == incubatorListId.rejected_id
                            ? "incubator-text-selected-small"
                            : "card-text-incubator"
                        }
                      >
                        Applications Rejected
                      </span>
                    </div>
                  </div>
                  <div
                    className="incubator-card"
                    onClick={() =>
                      getDataByStatus(`${incubatorListId.closed_id}`)
                    }
                  >
                    <div
                      className={
                        (activeIndex == incubatorListId.closed_id) && (Orange == 1 && Green == 0) ? "rectangle-card-selected-orange" :
                          (activeIndex == incubatorListId.closed_id) && (Orange == 1 && Green == 1) ? "rectangle-card-selected-green" :
                            (Orange == 1 && Green == 0) ? "rectangle-card-selected-orange" :
                              (Orange == 1 && Green == 1) ? "rectangle-card-selected-green" :
                                (activeIndex == incubatorListId.closed_id) ? "rectangle-card-selected" :
                                  "rectangle-card"
                      }
                    >
                      <span
                        className={
                          activeIndex == incubatorListId.closed_id
                            ? "smallIncubatorNumeric"
                            : "incubator-numeric-black"
                        }
                      >
                        {incubatorListingCounts.closed}
                      </span>
                      <br />
                      <span
                        className={
                          activeIndex == incubatorListId.closed_id
                            ? "incubator-text-selected-small"
                            : "card-text-incubator"
                        }
                      >
                        Applications Closed
                      </span>
                    </div>
                  </div>
                  <div
                    className="incubator-card"
                    onClick={() => getDataByStatus("cancelled")}
                  >
                    <div
                      className={
                        activeIndex == "cancelled"
                          ? "rectangle-card-selected"
                          : "rectangle-card"
                      }
                    >
                      <span
                        className={
                          activeIndex == "cancelled"
                            ? "smallIncubatorNumeric"
                            : "incubator-numeric-black"
                        }
                      >
                        {incubatorListingCounts.cancelled}
                        {/* {(Cancellation_Approved_Count == 0 || Cancellation_Approved_Count == "")?0:Cancellation_Approved_Count} */}
                      </span>
                      <br />
                      <span
                        className={
                          activeIndex == "cancelled"
                            ? "incubator-text-selected-small"
                            : "card-text-incubator"
                        }
                      >
                        Applications Cancelled
                      </span>
                    </div>
                  </div>
                  <div
                    className="incubator-card"
                    onClick={() => getDataByStatus("system_rejected")}
                  >
                    <div
                      className={
                        activeIndex == "system_rejected"
                          ? "rectangle-card-selected"
                          : "rectangle-card"
                      }
                      // className={
                      //   (activeIndex == incubatorListId.system_rejected_id) && (Orange == 1 && Green == 0) ? "rectangle-card-selected-orange" :
                      //     (activeIndex == incubatorListId.system_rejected_id) && (Orange == 1 && Green == 1) ? "rectangle-card-selected-green" :
                      //       (Orange == 1 && Green == 0) ? "rectangle-card-selected-orange" :
                      //         (Orange == 1 && Green == 1) ? "rectangle-card-selected-green" :
                      //           (activeIndex == incubatorListId.system_rejected_id) ? "rectangle-card-selected" :
                      //             "rectangle-card"
                      // }
                    >
                      <span
                        className=
                        {
                          activeIndex == "system_rejected"
                            ? "smallIncubatorNumeric"
                            : "incubator-numeric-black"
                        }
                      >
                        {incubatorListingCounts.system_rejected} 
                      </span>
                      <br />
                      <span
                        className={
                          activeIndex == "system_rejected"
                            ? "incubator-text-selected-small"
                            : "card-text-incubator"
                        }
                      >
                        System Rejected
                      </span>
                    </div>
                  </div>
                  <span style={{ borderLeft: "2px solid rgb(108 117 125 / 12%)", marginLeft: '10px', marginRight: '10px' }}></span>

                  {/* <div
                  className="incubator-card"
                  onClick={() => getDataByStatus("tranche")}
                >
                  <div
                    className={
                      activeIndex == "tranche"
                        ? "rectangle-card-selected"
                        : "rectangle-card"
                    }
                  >
                    <span
                      className={
                        activeIndex == "tranche"
                          ? "smallIncubatorNumeric"
                          : "incubator-numeric-black"
                      }
                    >
                      {incubatorListingCounts.trancheRequest}
                    </span>
                    <br />
                    <span
                      className={
                        activeIndex == "tranche"
                          ? "incubator-text-selected-small"
                          : "card-text-incubator"
                      }
                    >
                      Tranche Requested
                    </span>
                  </div>
                </div> */}

                  <div
                    className="incubator-card"
                    onClick={() => getDataByStatus("cancel")}
                  >
                    <div
                      className={
                        activeIndex == "cancel"
                          ? "rectangle-card-selected"
                          : "rectangle-card"
                      }
                    >
                      <span
                        className={
                          activeIndex == "cancel"
                            ? "smallIncubatorNumeric"
                            : "incubator-numeric-black"
                        }
                      >
                        {incubatorListingCounts.cancel}
                        {/* {(Cancellation_Requested_Count == 0 || Cancellation_Requested_Count == "")?0:Cancellation_Requested_Count} */}

                      </span>
                      <br />
                      <span
                        className={
                          activeIndex == "cancel"
                            ? "incubator-text-selected-small"
                            : "card-text-incubator"
                        }
                      >
                        Cancellation Requested
                      </span>
                    </div>
                  </div>


                </div>
                <div className="row incubator-heading-div">
                  <div className="col-md-3 pt-2">
                    <span className="table-head">{tableHeading}</span>
                    {
                      (tableHeading == 'Applications Incomplete' || tableHeading == 'Applications On Hold' || tableHeading == 'Applications to be Reviewed') &&

                      <button
                        className="button tool-tip"
                        style={{
                          borderRadius: '10px',
                          backgroundColor: '#000000',
                          color: '#ffffff',
                          border: 'none',
                          width: '18px',
                          height: '18px',
                          fontSize: '12px',
                          marginLeft: '5px'
                        }}
                      >
                        !
                      </button>
                    }
                    <br />
                    <p className="count-text-incubator">
                      {/* {console.log(incubatorListCountHead,"apply")} */}
                      ({incubatorListCountHead} Results)
                    </p>
                  </div>
                  <div className="col-md-5 text-right">

                    {SubmitBTN == true && (QRIntiatedDetails && QRIntiatedDetails.length > 0) && TotalApplicationAPI.quaterly_review_submitted == 0 ? <button
                      className={TotalApplicationAPI.completed_QR_applications == TotalApplicationAPI.total_QR_applications ? "tertiary btn_green ml-3 mr-3" : "tertiary btn_Orange ml-3 mr-3"}
                      onClick={() => SubmitQRClick()}
                    >
                      Submit Quarterly Review ({TotalApplicationAPI.completed_QR_applications}/{TotalApplicationAPI.total_QR_applications})
                    </button>
                      : SubmitBTN == true && (QRIntiatedDetails && QRIntiatedDetails.length > 0) && TotalApplicationAPI.quaterly_review_submitted == 1 ? <button
                        disabled
                        className="tertiary btn_green_opacity ml-3 mr-3"
                      >
                        Quarterly Review Submited
                      </button> : null}

                    {loggedInRole == "incubator" && (
                      <button
                        className={
                          buttonDisable == true
                            ? "tertiary  btn-yellow-primary btn-yellow-disabled"
                            : "tertiary  btn-yellow-primary"
                        }
                        disabled={buttonDisable}
                        data-toggle="modal"
                        data-target="#scheduleMeeting"
                      >
                        SCHEDULE A MEETING
                      </button>
                    )}
                    {/* <button
                    className="tertiary  btn-yellow-primary ml-3 mr-3"
                    onClick={() => downloadExcel()}
                  >
                    DOWNLOAD EXCEL
                  </button> */}
                    {parseInt(incubatorListCountHead) == 0 ?
                      <>
                        <button
                          className="tertiary  btn-yellow-primary ml-3 mr-3 btn-yellow-disabled"
                        // onClick={() => downloadExcel()}
                        >
                          DOWNLOAD EXCEL
                        </button>
                      </> :
                      <>
                        <button
                          className="tertiary  btn-yellow-primary ml-3 mr-3"
                          onClick={() => downloadExcel()}
                        >
                          DOWNLOAD EXCEL
                        </button>
                      </>
                    }

                    {/* <button className="btn btn-sisfs btn-transparent-primary">
              UPLOAD XL
            </button> */}
                  </div>

                  <div className="col-md-3 text-right ">
                    <div className="d-flex searchDiv" style={{ float: 'right' }}>
                      {" "}
                      <img src={search} className="search" alt="search" />
                      <input
                        className="searchBar"
                        style={{ width: 'auto' }}
                        type="text"
                        placeholder=" Search.."
                        name="search"
                        onKeyUp={searchList}
                      />
                    </div>
                  </div>
                  <div className="col-md-1 filter-icon">
                    <img
                      src={filter}
                      className="img-fluid filter"
                      alt="SISFS Logo"
                      data-toggle="modal"
                      data-target="#filterModal"
                      style={{ float: 'left' }}
                    />
                    {showFilterDot == true ? <mark></mark> : null}
                  </div>
                </div>
                <div className="table-scroll mb-5">

                  <fieldset>
                    <table className="reviewTable" id="customTable">
                      <thead>
                        <tr>
                          {checkBoxHide == false
                            ? loggedInRole == "incubator" && (
                              <th className="table-heading" style={{ paddingLeft: "27px" }}>
                                {/* <input className="form-check-input" type="checkbox" onChange={handleAllCheck} /> */}

                                <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    id="customcheckbox1"
                                    name="customcheckbox"
                                    className="custom-control-input"
                                    onChange={handleAllCheck}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customcheckbox1"
                                  ></label>
                                </div>
                              </th>
                            )
                            : null}
                          <th className="text-left table-heading">
                            DIPP Number
                          </th>
                          <th className="text-left table-heading" style={{ width: '370px' }}>Startup Name</th>

                          {/* {columnHideFlag == true ? (
                                                <th className="text-left table-heading custom-td">
                                                    Amount Disbursed to Startups (&#8377;)
                                                </th>
                                            ) : null} */}
                          {columnHideFlag != true ? (
                            <th className="table-heading">Industry</th>
                          ) : null}
                          {columnHideFlag != true ? (
                            <th className="table-heading">Sector</th>
                          ) : null}
                          {columnHideFlag != true ? (
                            <th className="text-left table-heading">
                              Evaluation To be Completed By
                            </th>
                          ) : null}
                          {activeIndex == "cancel" && (
                            <th className="text-left table-heading">Status</th>
                          )}
                          {activeIndex == "cancelled" && (
                            <th className="text-left table-heading">Status</th>
                          )}
                          <th className="text-left table-heading">
                            Quantum of Funds Required (&#8377;)
                          </th>

                          {columnHideFlag == true ? (
                            <th className="text-left table-heading">
                              Funds Approved (&#8377;)
                            </th>
                          ) : null}
                          {columnHideFlag == true ? (
                            <th className="text-left table-heading">
                              Funds Disbursed{" "}
                            </th>
                          ) : null}
                          {columnHideFlag == true
                            ? loggedInRole == "incubator" && (
                              <th className="text-center custom-td table-heading">
                                Meeting Details
                              </th>
                            )
                            : null}
                        </tr>
                      </thead>
                      <tbody>
                        {incubatorList.length == 0 || (filteredIncubatorList.length == 0 && noDataFilter != '') ? (
                          <tr>
                            <td className="text-center table-heading" colSpan={7}>
                              No data
                            </td>
                          </tr>
                        ) : filteredIncubatorList.length == 0 ? (
                          incubatorList.map((item: any, index) => (
                            <tr key={index}
                            // className={ 
                            //   item.already_selected_flag == 1 ||
                            //   (item.incubator_preferences_data==6 && item.startup_status_id>2)  || item.startup_status_id==6
                            //     ? "custom-disabled"
                            //     : ""
                            // }

                            // className={ 

                            // ((item.evaluation_to_be_completed_by < currentdatenew)&&(item.startup_status_id ===2 || item.startup_status_id ===3 || item.startup_status_id ===5)) ? "custom-disabled": ""
                            // }

                            >
                              {checkBoxHide == false
                                ? loggedInRole == "incubator" && (
                                  <td>
                                    {/* <input
                          type="checkbox"
                          disabled={isChecked}
                          onChange={handleListChange}
                          value={application_id}
                        /> */}
                                    {/* <input className="form-check-input" type="checkbox"  value={application_id}
                            id={application_id}
                            name={application_id}    disabled={isChecked}
                            onChange={handleListChange}/> */}
                                    <div className="custom-control custom-checkbox">
                                      {/* <input
                                      type="checkbox"
                                      value={item.id}
                                      id={item.name_of_entity + "_" + item.id}
                                      name={item.name_of_entity + "_" + item.id}
                                      className="custom-control-input"
                                      disabled={isChecked}
                                      onChange={handleListChange}
                                    /> */}
                                      {/* {console.log(checkedArray,item.id,"checkedArray")} */}
                                      <input
                                        type="checkbox"
                                        value={item.id}
                                        id={item.name_of_entity + "_" + item.id}
                                        name={item.name_of_entity + "_" + item.id}
                                        className="custom-control-input"
                                        disabled={(item.evaluation_to_be_completed_by < currentdatenew) && (item.incubator_preferences_data === 2 || item.incubator_preferences_data === 3 || item.incubator_preferences_data === 4 || item.incubator_preferences_data === 5) || (item.already_selected_flag === 1 && item.incubator_preferences_data === 7 && item.startup_status_id === 7)}
                                        checked={checkedArray.includes(item.id)}
                                        onChange={handleListChange}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={
                                          item.name_of_entity + "_" + item.id
                                        }
                                      ></label>
                                    </div>
                                  </td>
                                )
                                : null}
                              {/* {columnHideFlag !== true ? ( */}
                              <td
                                onClick={() =>
                                  handleRowChange(
                                    item.id,
                                    item.already_selected_flag,
                                    item.is_blocked,
                                    item.evaluation_to_be_completed_by,
                                    item.startup_status_id,
                                    item.incubator_preferences_data,
                                    item.incubator_id
                                  )
                                }
                                className="text-left custom-td-small custom-tdd-small customcenter"
                              >
                                {Orange == 1 && item.eligbility != 0 && item.quaterly_review_completed == 0 && item.already_selected_flag != 1 && ((item.incubator_preferences_data == 7 && item.startup_status_id == 7) || (item.incubator_preferences_data == 8 && item.startup_status_id == 8)) && (item.evaluation_to_be_completed_by < currentdatenew) && (
                                  <span className={Orange == 1 && item.quaterly_review_completed == 0 ? "orange_link" : ""}>
                                    QR Pending
                                  </span>
                                )}

                                {Orange == 1 && item.eligbility != 0 && item.quaterly_review_completed == 1 && item.already_selected_flag != 1 && ((item.incubator_preferences_data == 7 && item.startup_status_id == 7) || (item.incubator_preferences_data == 8 && item.startup_status_id == 8)) && (item.evaluation_to_be_completed_by < currentdatenew) && (
                                  <span className={Orange == 1 && item.quaterly_review_completed == 1 ? "green_link" : ""}>
                                    QR Completed
                                  </span>
                                )}

                                <br />
                                <span className="tableDataHead">
                                  {item.recognition_number}
                                </span>
                                {/* {console.log(item)}
                            {console.log(currentdatenew)} */}
                                {
                                  // (item.evaluation_to_be_completed_by < currentdatenew) && (item.incubator_preferences_data == 6) 
                                  (item.newIndex == 6)
                                  && (
                                    <span className="By_rejected_Flag">
                                      Rejected by you {/* {item.evaluation_to_be_completed_by}{' '}{item.startup_status_id} */}
                                    </span>
                                  )}

                                {(item.newIndex == 1)
                                  // (item.evaluation_to_be_completed_by < currentdatenew) && (item.incubator_preferences_data == 2 || item.incubator_preferences_data == 3 || item.incubator_preferences_data == 4 || item.incubator_preferences_data == 5) 
                                  && (
                                    <span className="rejected-flag">
                                      System rejected{/* {item.evaluation_to_be_completed_by}{' '}{item.startup_status_id} */}
                                    </span>
                                  )}
                                {
                                  // (item.evaluation_to_be_completed_by > currentdatenew) 
                                  (item.newIndex == 2) && (
                                    <span className="open-flag">
                                      Open Application{/* {item.evaluation_to_be_completed_by}{' '}{item.startup_status_id} */}
                                    </span>
                                  )}
                                {
                                  (item.newIndex == 9) && (
                                    <span className="orange-flag">
                                      &nbsp;&nbsp; Final Day &nbsp;&nbsp;
                                    </span>
                                  )}
                                {/* {(item.evaluation_to_be_completed_by < currentdatenew)&&(item.startup_status_id === 6) && (
                              <span className="rejected-flag">
                                Rejected
                              </span>
                            )} */}
                                {
                                  // item.already_selected_flag == 1 && item.incubator_preferences_data == 7 && item.startup_status_id == 7 && (item.evaluation_to_be_completed_by < currentdatenew) 
                                  (item.newIndex == 4) && (
                                    <span className="selected-flag">
                                      Allotted to another incubator
                                      {/* {item.already_selected_flag}{item.incubator_preferences_data}{item.startup_status_id} */}
                                    </span>
                                  )}

                                {
                                  // item.already_selected_flag != 1 && item.incubator_preferences_data == 7 && item.startup_status_id == 7 && (item.evaluation_to_be_completed_by < currentdatenew) 
                                  (item.newIndex == 3 && (item.selected_incubator_id == item.incubator_id)) && (
                                    <span className="Approved_flag">
                                      Allotted to you
                                      {/* {item.already_selected_flag}{item.incubator_preferences_data}{item.startup_status_id} */}
                                    </span>
                                  )}
                                {
                                  (item.early_closure == "yes" && (item.selected_incubator_id == item.incubator_id)) &&
                                  <div style={{ display: 'flex' }}>
                                    <span className="Approved_flag">
                                      Allotted to you
                                    </span>&nbsp;
                                    <span className="selected-flag">
                                      Early Closure
                                    </span>
                                  </div>

                                }
                                {
                                  // item.total_applroved_amount == item.total_disbursed_amount && item.incubator_preferences_data == 8 
                                  (item.newIndex == 7) &&
                                  <div style={{ display: 'flex' }}>
                                    <span className="Approved_flag">
                                      Allotted to you
                                    </span>&nbsp;
                                    <span className="fullClosed_flag">
                                      Full closed
                                    </span>
                                  </div>
                                }
                                {item.incubator_preferences_data == 9 &&
                                  <div style={{ display: 'flex' }}>
                                    <span className="Approved_flag">
                                      Allotted to you
                                    </span>&nbsp;
                                    <span className="cancelleed_flag">
                                      Cancelled
                                    </span>
                                  </div>
                                }
                              </td>


                              <td
                                className={Orange == 1 && item.eligbility != 0 && item.quaterly_review_completed == 0 && item.already_selected_flag != 1 && ((item.incubator_preferences_data == 7 && item.startup_status_id == 7) || (item.incubator_preferences_data == 8 && item.startup_status_id == 8)) && (item.evaluation_to_be_completed_by < currentdatenew) ? "orange_link text-left custom-td" :
                                  Orange == 1 && item.eligbility != 0 && item.quaterly_review_completed == 1 && item.already_selected_flag != 1 && ((item.incubator_preferences_data == 7 && item.startup_status_id == 7) || (item.incubator_preferences_data == 8 && item.startup_status_id == 8)) && (item.evaluation_to_be_completed_by < currentdatenew) ? "green_link text-left custom-td" :
                                    "text-left custom-td"}
                                onClick={() =>
                                  handleRowChange(
                                    item.id,
                                    item.already_selected_flag,
                                    item.is_blocked,
                                    item.evaluation_to_be_completed_by,
                                    item.startup_status_id,
                                    item.incubator_preferences_data,
                                    item.incubator_id
                                  )
                                }
                              >
                                <span className="tableDataHead">
                                  {item.name_of_entity}
                                </span>
                                <br />
                                <span className="tableDataApplication">
                                  {"No: " + item.id}
                                </span>

                                {/* {item.evaluation_to_be_completed_by} {currentdatenew} */}
                                {/* {(item.evaluation_to_be_completed_by < currentdatenew)&&(item.startup_status_id ===2 || item.startup_status_id ===3 ||item.startup_status_id ===4|| item.startup_status_id ===5) && (
                              <span className="rejected-flag">
                                System Rejected
                                {item.evaluation_to_be_completed_by}{' '}{item.startup_status_id}
                              </span>
                            )} */}
                                {/* {(item.evaluation_to_be_completed_by < currentdate)&&(item.startup_status_id !== 6 && item.startup_status_id !== 7) ? (
                              <span className="rejected-flag">
                                System Rejected 
                                
                              </span>
                            ):''} */}
                                {/* {item.evaluation_to_be_completed_by}{'sr'}{item.startup_status_id} */}

                              </td>
                              {columnHideFlag != true ? (
                                <td
                                  onClick={() =>
                                    handleRowChange(
                                      item.id,
                                      item.already_selected_flag,
                                      item.is_blocked,
                                      item.evaluation_to_be_completed_by,
                                      item.startup_status_id,
                                      item.incubator_preferences_data,
                                      item.incubator_id
                                    )
                                  }
                                  className="custom-td-small customcenter"
                                >
                                  {JSON.parse(item.industries).map(
                                    (industry: any) => industry.industry_name + " "
                                  )}
                                </td>
                              ) : null}
                              {columnHideFlag != true ? (
                                <td
                                  onClick={() =>
                                    handleRowChange(
                                      item.id,
                                      item.already_selected_flag,
                                      item.is_blocked,
                                      item.evaluation_to_be_completed_by,
                                      item.startup_status_id,
                                      item.incubator_preferences_data,
                                      item.incubator_id
                                    )
                                  }
                                  className="custom-td-small customcenter"
                                >
                                  {JSON.parse(item.sectors).map(
                                    (sector: any) => sector.sector_name
                                  )}
                                </td>
                              ) : null}
                              {columnHideFlag != true ? (
                                <td
                                  onClick={() =>
                                    handleRowChange(
                                      item.id,
                                      item.already_selected_flag,
                                      item.is_blocked,
                                      item.evaluation_to_be_completed_by,
                                      item.startup_status_id,
                                      item.incubator_preferences_data,
                                      item.incubator_id
                                    )
                                  }
                                  className="custom-td-small"
                                >
                                  {/* <span className={(item.evaluation_to_be_completed_by_alert <= currentdatenew && item.evaluation_to_be_completed_by >= currentdatenew) ? "text-center Before_FiveDays" : ""}>{item.evaluation_to_be_completed_by}</span> */}
                                  {(item.evaluation_to_be_completed_by_alert <= currentdatenew && item.evaluation_to_be_completed_by >= currentdatenew)
                                    ? <OverlayTrigger
                                      placement="top"
                                      overlay={<Tooltip id="example-tooltip" style={{ fontSize: '20px' }}>less than 5 days left for evaluation</Tooltip>}
                                    >
                                      <span className={(item.evaluation_to_be_completed_by_alert <= currentdatenew && item.evaluation_to_be_completed_by >= currentdatenew) ? "text-center Before_FiveDays" : ""}>{item.evaluation_to_be_completed_by}</span>
                                    </OverlayTrigger> : <span>{item.evaluation_to_be_completed_by}</span>}
                                </td>
                              ) : null}
                              {/* {console.log(item,"item@@@@@")} */}
                              {activeIndex == "cancel" && (
                                <td
                                  onClick={() =>
                                    handleRowChange(
                                      item.id,
                                      item.already_selected_flag,
                                      item.is_blocked,
                                      item.evaluation_to_be_completed_by,
                                      item.startup_status_id,
                                      item.incubator_preferences_data,
                                      item.incubator_id
                                    )
                                  }
                                  className="custom-td-small custom-td-right"
                                >

                                  {item.cancellation_status}
                                </td>
                              )}
                              {activeIndex == "cancelled" && (
                                <td
                                  onClick={() =>
                                    handleRowChange(
                                      item.id,
                                      item.already_selected_flag,
                                      item.is_blocked,
                                      item.evaluation_to_be_completed_by,
                                      item.startup_status_id,
                                      item.incubator_preferences_data,
                                      item.incubator_id
                                    )
                                  }
                                  className="custom-td-small custom-td-right"
                                >

                                  {item.cancellation_status}
                                </td>
                              )}
                              {/* <td></td> */}
                              <td
                                onClick={() =>
                                  handleRowChange(
                                    item.id,
                                    item.already_selected_flag,
                                    item.is_blocked,
                                    item.evaluation_to_be_completed_by,
                                    item.startup_status_id,
                                    item.incubator_preferences_data,
                                    item.incubator_id
                                  )
                                }
                                className="custom-td-small custom-td-right"
                              >
                                {item.quantum_funds_required
                                  ? changeNumberFormat(
                                    Number(item.quantum_funds_required),
                                    1,
                                    0
                                  )
                                  : 0}
                              </td>
                              {columnHideFlag == true ? (
                                <td
                                  onClick={() =>
                                    handleRowChange(
                                      item.id,
                                      item.already_selected_flag,
                                      item.is_blocked,
                                      item.evaluation_to_be_completed_by,
                                      item.startup_status_id,
                                      item.incubator_preferences_data,
                                      item.incubator_id
                                    )
                                  }
                                  className="text-left custom-td"
                                >
                                  {item.already_selected_flag == 1 && item.incubator_preferences_data == 7 && item.startup_status_id == 7 && (item.evaluation_to_be_completed_by < currentdatenew) ? "-"
                                    : item.total_applroved_amount
                                      ? changeNumberFormat(
                                        Number(item.total_applroved_amount),
                                        1,
                                        0
                                      )
                                      : 0}
                                </td>
                              ) : null}
                              {columnHideFlag == true ? (
                                <td
                                  onClick={() =>
                                    handleRowChange(
                                      item.id,
                                      item.already_selected_flag,
                                      item.is_blocked,
                                      item.evaluation_to_be_completed_by,
                                      item.startup_status_id,
                                      item.incubator_preferences_data,
                                      item.incubator_id
                                    )
                                  }
                                  className="text-left custom-td"
                                >
                                  {item.already_selected_flag == 1 && item.incubator_preferences_data == 7 && item.startup_status_id == 7 && (item.evaluation_to_be_completed_by < currentdatenew) ? "-"
                                    : item.total_disbursed_amount
                                      ? changeNumberFormat(
                                        Number(item.total_disbursed_amount),
                                        1,
                                        0
                                      )
                                      : 0}
                                </td>
                              ) : null}
                              {columnHideFlag == true
                                ? loggedInRole == "incubator" &&
                                (item.already_selected_flag == 0 ? (
                                  <td
                                    data-toggle="modal"
                                    data-target="#quickEdit"
                                    className="text-center custom-td"
                                    onClick={() => quickEditData(item.id, item)}
                                    onMouseOver={() => mouseOverEvent(item.id)}
                                    onMouseOut={() => setMouseOverFlag("")}
                                  >
                                    {/* (item.evaluation_to_be_completed_by<currentdate)&&(item.startup_status_id == '2'||item.startup_status_id == '3'||item.startup_status_id == '4'||item.startup_status_id == '5') */}
                                    {mouseOverFlag == item.id ? (
                                      <img
                                        src={edit}
                                        className="search"
                                        alt="search"
                                        data-toggle="modal"
                                        data-target="#quickEdit"
                                      />
                                    ) : (item.meetingData.meeting_date == "" &&
                                      typeof item.meetingData.meeting_time ==
                                      "undefined") ||
                                      item.meetingData.status == "NA" ? (
                                      // <>
                                      //   <div className="badge-red">
                                      //     Today:{meeting_time}
                                      //   </div>
                                      //   <span className="tableDataApplication">
                                      //     {meeting_date}
                                      //   </span>
                                      // </>
                                      <div className="badge-grey">
                                        {item.meetingData.status}
                                      </div>
                                    ) : item.meetingData.status == "Today" ? (
                                      <>
                                        <div className="badge-red">
                                          Today:{" "}
                                          {item.meetingData.meeting_time != null
                                            ? formatTime(
                                              item.meetingData.meeting_time
                                            )
                                            : ""}
                                        </div>
                                        <span className="tableDataApplication">
                                          {FormatDate(
                                            item.meetingData.meeting_date
                                          )}
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <div className="badge-grey">
                                          {item.meetingData.status}
                                        </div>
                                        <span className="tableDataApplication">
                                          {FormatDate(
                                            item.meetingData.meeting_date
                                          )}
                                        </span>
                                      </>
                                    )}
                                  </td>
                                ) : (
                                  <td className="text-center custom-td">
                                    <img
                                      src={edit}
                                      className="search"
                                      alt="search"
                                    />
                                  </td>
                                ))
                                : null}

                              {/* <td
                                                                onMouseOver={() => mouseOverEvent(application_id)}
                                                                onMouseOut={() => setMouseOverFlag("")}
                                                                onClick={() => quickEditData(incubator_name, application_date, status_id, application_id)} data-toggle="modal" data-target="#quickEdit"
                                                                className="text-center edit-column"
                                                            >
                                                                {mouseOverFlag == application_id ? <img
                                                                    src={edit}
                                                                    className="search"
                                                                    alt="search"
                                                                /> :
                                                                    (meeting_date == "" && meeting_time == "") || meeting_status == "NA" ?
                                                                        // <>
                                                                        //   <div className="badge-red">
                                                                        //     Today:{meeting_time}
                                                                        //   </div>
                                                                        //   <span className="tableDataApplication">
                                                                        //     {meeting_date}
                                                                        //   </span>
                                                                        // </>
                                                                        <div className="badge-grey">{meeting_status}</div>
                                                                        :
                                                                        meeting_status == "Today" ?
                                                                            <>
                                                                                <div className="badge-red">
                                                                                    Today: {meeting_time != null ? formatTime(meeting_time) : ""}
                                                                                </div>
                                                                                <span className="tableDataApplication">
                                                                                    {FormatDate(meeting_date)}
                                                                                </span>
                                                                            </>
                                                                            : <>
                                                                                <div className="badge-grey">{meeting_status}</div>
                                                                                <span className="tableDataApplication">
                                                                                    {FormatDate(meeting_date)}
                                                                                </span>
                                                                            </>
                                                                }
                                                            </td> */}
                            </tr>
                          ))
                        ) : (
                          filteredIncubatorList.map((item: any, index) => (

                            <tr key={index}

                            // className={
                            // ((item.evaluation_to_be_completed_by < currentdatenew)&&(item.startup_status_id ===2 || item.startup_status_id ===3 || item.startup_status_id ===5)) ? "custom-disabled": ""
                            // item.already_selected_flag == 1 ||
                            // (item.incubator_preferences_data == 6 &&
                            //   item.startup_status_id > 2) ||
                            // item.startup_status_id == 6
                            //   ? 'custom-disabled'
                            //   : ''
                            // }
                            // className={
                            //   item.already_selected_flag == 0 ||
                            //   item.startup_status_id != 6
                            //     ? "custom-disabled"
                            // : ""
                            // }
                            // className={
                            //   ((item.evaluation_to_be_completed_by < currentdate)&&(item.startup_status_id !== 6 && item.startup_status_id !== 7))
                            //     ? "custom-disabled"
                            //     : ""
                            // }

                            // className={
                            //   // item.already_selected_flag == 1  && (item.incubator_preferences_data==7 && item.startup_status_id==7)

                            //   item.already_selected_flag == 1 ||
                            //   (item.incubator_preferences_data == 6 &&
                            //     item.startup_status_id > 2) ||
                            //   item.startup_status_id == 6
                            //     ? 'custom-disabled'
                            //     : ''
                            //   }
                            >
                              {checkBoxHide == false
                                ? loggedInRole == "incubator" && (
                                  <td>
                                    {/* <input
                          type="checkbox"
                          disabled={isChecked}
                          onChange={handleListChange}
                          value={application_id}
                        /> */}
                                    {/* <input className="form-check-input" type="checkbox"  value={application_id}
                            id={application_id}
                            name={application_id}    disabled={isChecked}
                            onChange={handleListChange}/> */}
                                    <div className="custom-control custom-checkbox">
                                      {/* <input
                                      type="checkbox"
                                      value={item.id}
                                      id={item.name_of_entity + "_" + item.id}
                                      name={item.name_of_entity + "_" + item.id}
                                      className="custom-control-input"
                                      disabled={isChecked}
                                      // onChange={handleListChange}
                                    /> */}
                                      {/* {console.log(item.evaluation_to_be_completed_by < currentdatenew,"current")}
{console.log(item.already_selected_flag === 1 ,"current")}
{console.log(item.already_selected_flag === 1 ,"current")} */}


                                      <input
                                        type="checkbox"
                                        value={item.id}
                                        id={item.name_of_entity + "_" + item.id}
                                        name={item.name_of_entity + "_" + item.id}
                                        className="custom-control-input"
                                        disabled={(item.evaluation_to_be_completed_by < currentdatenew) && (item.incubator_preferences_data === 2 || item.incubator_preferences_data === 3 || item.incubator_preferences_data === 4 || item.incubator_preferences_data === 5) || (item.already_selected_flag === 1 && item.incubator_preferences_data === 7 && item.startup_status_id === 7)}


                                        checked={checkedArray.includes(item.id)}
                                        onChange={handleListChange}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={
                                          item.name_of_entity + "_" + item.id
                                        }
                                      ></label>
                                    </div>
                                  </td>
                                )
                                : null}
                              {/* {columnHideFlag != true ? ( */}
                              <td
                                onClick={() =>
                                  handleRowChange(
                                    item.id,
                                    item.already_selected_flag,
                                    item.is_blocked,
                                    item.evaluation_to_be_completed_by,
                                    item.startup_status_id,
                                    item.incubator_preferences_data,
                                    item.incubator_id
                                  )
                                }
                                className="text-left custom-td-small custom-tdd-small customcenter"
                              >
                                {Orange == 1 && item.eligbility != 0 && item.quaterly_review_completed == 0 && item.already_selected_flag != 1 && ((item.incubator_preferences_data == 7 && item.startup_status_id == 7) || (item.incubator_preferences_data == 8 && item.startup_status_id == 8)) && (item.evaluation_to_be_completed_by < currentdatenew) && (
                                  <span className={Orange == 1 && item.quaterly_review_completed == 0 ? "orange_link" : ""}>
                                    QR Pending
                                  </span>
                                )}

                                {Orange == 1 && item.eligbility != 0 && item.quaterly_review_completed == 1 && item.already_selected_flag != 1 && ((item.incubator_preferences_data == 7 && item.startup_status_id == 7) || (item.incubator_preferences_data == 8 && item.startup_status_id == 8)) && (item.evaluation_to_be_completed_by < currentdatenew) && (
                                  <span className={Orange == 1 && item.quaterly_review_completed == 1 ? "green_link" : ""}>
                                    QR Completed
                                  </span>
                                )}
                                <br />
                                <span className="tableDataHead">
                                  {item.recognition_number}
                                </span>

                                {
                                  // (item.evaluation_to_be_completed_by < currentdatenew) && (item.incubator_preferences_data == 6) 
                                  (item.newIndex == 6)
                                  && (
                                    <span className="By_rejected_Flag">
                                      Rejected by you {/* {item.evaluation_to_be_completed_by}{' '}{item.startup_status_id} */}
                                    </span>
                                  )}

                                {(item.newIndex == 1)
                                  // (item.evaluation_to_be_completed_by < currentdatenew) && (item.incubator_preferences_data == 2 || item.incubator_preferences_data == 3 || item.incubator_preferences_data == 4 || item.incubator_preferences_data == 5) 
                                  && (
                                    <span className="rejected-flag">
                                      System rejected{/* {item.evaluation_to_be_completed_by}{' '}{item.startup_status_id} */}
                                    </span>
                                  )}
                                {
                                  // (item.evaluation_to_be_completed_by > currentdatenew) 
                                  (item.newIndex == 2) && (
                                    <span className="open-flag">
                                      Open Application{/* {item.evaluation_to_be_completed_by}{' '}{item.startup_status_id} */}
                                    </span>
                                  )}
                                {
                                  (item.newIndex == 9) && (
                                    <span className="orange-flag">
                                      &nbsp;&nbsp; Final Day &nbsp;&nbsp;
                                    </span>
                                  )}
                                {/* {(item.evaluation_to_be_completed_by < currentdatenew)&&(item.startup_status_id === 6) && (
                              <span className="rejected-flag">
                                Rejected
                              </span>
                            )} */}
                                {
                                  // item.already_selected_flag == 1 && item.incubator_preferences_data == 7 && item.startup_status_id == 7 && (item.evaluation_to_be_completed_by < currentdatenew) 
                                  (item.newIndex == 4) && (
                                    <span className="selected-flag">
                                      Allotted to another incubator
                                      {/* {item.already_selected_flag}{item.incubator_preferences_data}{item.startup_status_id} */}
                                    </span>
                                  )}

                                {
                                  // item.already_selected_flag != 1 && item.incubator_preferences_data == 7 && item.startup_status_id == 7 && (item.evaluation_to_be_completed_by < currentdatenew) 
                                  (item.newIndex == 3 && (item.selected_incubator_id == item.incubator_id)) && (
                                    <span className="Approved_flag">
                                      Allotted to you
                                      {/* {item.already_selected_flag}{item.incubator_preferences_data}{item.startup_status_id} */}
                                    </span>
                                  )}
                                {
                                  (item.early_closure == "yes" && (item.selected_incubator_id == item.incubator_id)) &&
                                  <div style={{ display: 'flex' }}>
                                    <span className="Approved_flag">
                                      Allotted to you
                                    </span>&nbsp;
                                    <span className="selected-flag">
                                      Early Closure
                                    </span>
                                  </div>

                                }
                                {
                                  // item.total_applroved_amount == item.total_disbursed_amount && item.incubator_preferences_data == 8 
                                  (item.newIndex == 7) &&
                                  <div style={{ display: 'flex' }}>
                                    <span className="Approved_flag">
                                      Allotted to you
                                    </span>&nbsp;
                                    <span className="fullClosed_flag">
                                      Full closed
                                    </span>
                                  </div>
                                }
                                {item.incubator_preferences_data == 9 &&
                                  <div style={{ display: 'flex' }}>
                                    <span className="Approved_flag">
                                      Allotted to you
                                    </span>&nbsp;
                                    <span className="cancelleed_flag">
                                      Cancelled
                                    </span>
                                  </div>
                                }
                              </td>
                              {/* ) : null} */}
                              <td
                                className={Orange == 1 && item.eligbility != 0 && item.quaterly_review_completed == 0 && item.already_selected_flag != 1 && ((item.incubator_preferences_data == 7 && item.startup_status_id == 7) || (item.incubator_preferences_data == 8 && item.startup_status_id == 8)) && (item.evaluation_to_be_completed_by < currentdatenew) ? "orange_link text-left custom-td" :
                                  Orange == 1 && item.eligbility != 0 && item.quaterly_review_completed == 1 && item.already_selected_flag != 1 && ((item.incubator_preferences_data == 7 && item.startup_status_id == 7) || (item.incubator_preferences_data == 8 && item.startup_status_id == 8)) && (item.evaluation_to_be_completed_by < currentdatenew) ? "green_link text-left custom-td" :
                                    "text-left custom-td"}
                                onClick={() =>
                                  handleRowChange(
                                    item.id,
                                    item.already_selected_flag,
                                    item.is_blocked,
                                    item.evaluation_to_be_completed_by,
                                    item.startup_status_id,
                                    item.incubator_preferences_data,
                                    item.incubator_id
                                  )
                                }
                              >

                                <span className="tableDataHead">
                                  {item.name_of_entity}
                                </span>

                                <br />
                                <span className="tableDataApplication">
                                  {"No: " + item.id}
                                </span>

                                {/* {(item.evaluation_to_be_completed_by < currentdatenew)&&(item.startup_status_id === 2 || item.startup_status_id === 3 || item.startup_status_id === 4 || item.startup_status_id === 5) && (
                              <span className="rejected-flag">
                                System Rejected
                                {item.evaluation_to_be_completed_by}{' '}{item.startup_status_id}
                              </span>
                            )} */}
                                {/* {item.startup_status_id>2 && item.incubator_preferences_data<6 && (
                              <span className="rejected-flag">
                                System Rejected
                              </span>
                            )} */}

                                {/* {item.startup_status_id>2 && item.incubator_preferences_data<6 && (
                              <span className="rejected-flag">
                                System Rejected
                              </span> */}

                                {/* {(item.evaluation_to_be_completed_by < currentdate)&&(item.startup_status_id !== 6 && item.startup_status_id !== 7) ? (
                              <span className="rejected-flag">
                                System Rejected 
                                
                              </span> */}
                                {/* ):''} */}
                              </td>
                              {columnHideFlag != true ? (
                                <td
                                  onClick={() =>
                                    handleRowChange(
                                      item.id,
                                      item.already_selected_flag,
                                      item.is_blocked,
                                      item.evaluation_to_be_completed_by,
                                      item.startup_status_id,
                                      item.incubator_preferences_data,
                                      item.incubator_id
                                    )
                                  }
                                  className="custom-td-small customcenter"
                                >
                                  {JSON.parse(item.industries).map(
                                    (industry: any) => industry.industry_name
                                  )}
                                </td>
                              ) : null}
                              {columnHideFlag != true ? (
                                <td
                                  onClick={() =>
                                    handleRowChange(
                                      item.id,
                                      item.already_selected_flag,
                                      item.is_blocked,
                                      item.evaluation_to_be_completed_by,
                                      item.startup_status_id,
                                      item.incubator_preferences_data,
                                      item.incubator_id
                                    )
                                  }
                                  className="custom-td-small customcenter"
                                >
                                  {JSON.parse(item.sectors).map(
                                    (industry: any) => industry.sector_name
                                  )}
                                </td>
                              ) : null}
                              {columnHideFlag != true ? (
                                <td
                                  onClick={() =>
                                    handleRowChange(
                                      item.id,
                                      item.already_selected_flag,
                                      item.is_blocked,
                                      item.evaluation_to_be_completed_by,
                                      item.startup_status_id,
                                      item.incubator_preferences_data,
                                      item.incubator_id
                                    )
                                  }
                                  className="custom-td-small"
                                >
                                  {(item.evaluation_to_be_completed_by_alert <= currentdatenew && item.evaluation_to_be_completed_by >= currentdatenew)
                                    ? <OverlayTrigger
                                      placement="top"
                                      overlay={<Tooltip id="example-tooltip" style={{ fontSize: '20px' }}>less than 5 days left for evaluation</Tooltip>}
                                    >
                                      <span className={(item.evaluation_to_be_completed_by_alert <= currentdatenew && item.evaluation_to_be_completed_by >= currentdatenew) ? "text-center Before_FiveDays" : ""}>{item.evaluation_to_be_completed_by}</span>
                                    </OverlayTrigger> : <span>{item.evaluation_to_be_completed_by}</span>}
                                </td>
                              ) : null}
                              {activeIndex == "cancel" && (
                                <td
                                  onClick={() =>
                                    handleRowChange(
                                      item.id,
                                      item.already_selected_flag,
                                      item.is_blocked,
                                      item.evaluation_to_be_completed_by,
                                      item.startup_status_id,
                                      item.incubator_preferences_data,
                                      item.incubator_id
                                    )
                                  }
                                  className="custom-td-small custom-td-right"
                                >
                                  {item.cancellation_status}
                                </td>
                              )}
                              {activeIndex == "cancelled" && (
                                <td
                                  onClick={() =>
                                    handleRowChange(
                                      item.id,
                                      item.already_selected_flag,
                                      item.is_blocked,
                                      item.evaluation_to_be_completed_by,
                                      item.startup_status_id,
                                      item.incubator_preferences_data,
                                      item.incubator_id
                                    )
                                  }
                                  className="custom-td-small custom-td-right"
                                >
                                  {item.cancellation_status}
                                </td>
                              )}
                              <td
                                onClick={() =>
                                  handleRowChange(
                                    item.id,
                                    item.already_selected_flag,
                                    item.is_blocked,
                                    item.evaluation_to_be_completed_by,
                                    item.startup_status_id,
                                    item.incubator_preferences_data,
                                    item.incubator_id
                                  )
                                }
                                className="custom-td-small custom-td-right"
                              >
                                {item.quantum_funds_required
                                  ? changeNumberFormat(
                                    Number(item.quantum_funds_required),
                                    1,
                                    0
                                  )
                                  : 0}
                              </td>

                              {columnHideFlag == true ? (
                                <td
                                  onClick={() =>
                                    handleRowChange(
                                      item.id,
                                      item.already_selected_flag,
                                      item.is_blocked,
                                      item.evaluation_to_be_completed_by,
                                      item.startup_status_id,
                                      item.incubator_preferences_data,
                                      item.incubator_id
                                    )
                                  }
                                  className="text-left custom-td"
                                >
                                  {item.already_selected_flag == 1 && item.incubator_preferences_data == 7 && item.startup_status_id == 7 && (item.evaluation_to_be_completed_by < currentdatenew) ? "-"
                                    : item.total_applroved_amount
                                      ? changeNumberFormat(
                                        Number(item.total_applroved_amount),
                                        1,
                                        0
                                      )
                                      : 0}
                                </td>
                              ) : null}
                              {columnHideFlag == true ? (
                                <td
                                  onClick={() =>
                                    handleRowChange(
                                      item.id,
                                      item.already_selected_flag,
                                      item.is_blocked,
                                      item.evaluation_to_be_completed_by,
                                      item.startup_status_id,
                                      item.incubator_preferences_data,
                                      item.incubator_id
                                    )
                                  }
                                  className="text-left custom-td"
                                >
                                  {item.already_selected_flag == 1 && item.incubator_preferences_data == 7 && item.startup_status_id == 7 && (item.evaluation_to_be_completed_by < currentdatenew) ? "-"
                                    : item.total_disbursed_amount
                                      ? changeNumberFormat(
                                        Number(item.total_disbursed_amount),
                                        1,
                                        0
                                      )
                                      : 0}
                                </td>
                              ) : null}
                              {columnHideFlag == true
                                ? loggedInRole == "incubator" &&
                                (item.already_selected_flag == 0 ? (
                                  <td
                                    data-toggle="modal"
                                    data-target="#quickEdit"
                                    className="text-center custom-td"
                                    onClick={() => quickEditData(item.id, item)}
                                    onMouseOver={() => mouseOverEvent(item.id)}
                                    onMouseOut={() => setMouseOverFlag("")}
                                  >
                                    {mouseOverFlag == item.id ? (
                                      <img
                                        src={edit}
                                        className="search"
                                        alt="search"
                                        data-toggle="modal"
                                        data-target="#quickEdit"
                                      />
                                    ) : (item.meetingData.meeting_date == "" &&
                                      typeof item.meetingData.meeting_time ==
                                      "undefined") ||
                                      item.meetingData.status == "NA" ? (
                                      // <>
                                      //   <div className="badge-red">
                                      //     Today:{meeting_time}
                                      //   </div>
                                      //   <span className="tableDataApplication">
                                      //     {meeting_date}
                                      //   </span>
                                      // </>
                                      <div className="badge-grey">
                                        {item.meetingData.status}
                                      </div>
                                    ) : item.meetingData.status == "Today" ? (
                                      <>
                                        <div className="badge-red">
                                          Today:{" "}
                                          {item.meetingData.meeting_time != null
                                            ? formatTime(
                                              item.meetingData.meeting_time
                                            )
                                            : ""}
                                        </div>
                                        <span className="tableDataApplication">
                                          {FormatDate(
                                            item.meetingData.meeting_date
                                          )}
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <div className="badge-grey">
                                          {item.meetingData.status}
                                        </div>
                                        <span className="tableDataApplication">
                                          {FormatDate(
                                            item.meetingData.meeting_date
                                          )}
                                        </span>
                                      </>
                                    )}
                                  </td>
                                ) : (
                                  <td className="text-center custom-td">
                                    <img
                                      src={edit}
                                      className="search"
                                      alt="search"
                                    />
                                  </td>
                                ))
                                : null}

                              {/* <td
                                                                onMouseOver={() => mouseOverEvent(application_id)}
                                                                onMouseOut={() => setMouseOverFlag("")}
                                                                onClick={() => quickEditData(incubator_name, application_date, status_id, application_id)} data-toggle="modal" data-target="#quickEdit"
                                                                className="text-center edit-column"
                                                            >
                                                                {mouseOverFlag == application_id ? <img
                                                                    src={edit}
                                                                    className="search"
                                                                    alt="search"
                                                                /> :
                                                                    (meeting_date == "" && meeting_time == "") || meeting_status == "NA" ?
                                                                        // <>
                                                                        //   <div className="badge-red">
                                                                        //     Today:{meeting_time}
                                                                        //   </div>
                                                                        //   <span className="tableDataApplication">
                                                                        //     {meeting_date}
                                                                        //   </span>
                                                                        // </>
                                                                        <div className="badge-grey">{meeting_status}</div>
                                                                        :
                                                                        meeting_status == "Today" ?
                                                                            <>
                                                                                <div className="badge-red">
                                                                                    Today: {meeting_time != null ? formatTime(meeting_time) : ""}
                                                                                </div>
                                                                                <span className="tableDataApplication">
                                                                                    {FormatDate(meeting_date)}
                                                                                </span>
                                                                            </>
                                                                            : <>
                                                                                <div className="badge-grey">{meeting_status}</div>
                                                                                <span className="tableDataApplication">
                                                                                    {FormatDate(meeting_date)}
                                                                                </span>
                                                                            </>
                                                                }
                                                            </td> */}
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </fieldset>
                </div>
                {/* quick edit popup */}
                <div
                  className="modal fade"
                  id="quickEdit"
                  role="dialog"
                  aria-labelledby="exampleModalLabel1"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-slideout modal-md"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header modal-header-bottom pr-5">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          // onClick={() => setSelectFlag(true)}
                          onClick={() => {
                            setSelectFlag(true);
                            setShowValidation(false)
                          }}
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body pl-5 pr-5 pt-5">
                        <div className="popup-head mb-5 ml-2">
                          <div className="main-heading">
                            {quickEditPopupData.incubator_name}
                          </div>
                          <div className="count-text">
                            {"No: " + quickEditPopupData.application_id}
                          </div>
                        </div>

                        <label className="col-sm-8 col-form-head">
                          Total Grant Approved (
                          {quickEditPopupData.no_of_tranches} Tranches)
                        </label>
                        {showEditTranche ? (
                          <span
                            className="col-sm-4 text-left view-all"
                            data-dismiss="modal"
                            onClick={() =>
                              handleRowChange(
                                quickEditPopupData.application_id,
                                0,
                                0,
                                0,
                                0,
                                "",
                                0

                              )
                            }
                          >
                            Edit Tranche
                          </span>
                        ) : null}
                        <div className="row pl-3">
                          <div className="col-md-11 mb-4">
                            <input
                              type="number"
                              readOnly
                              id="total_approved_amount"
                              name="total_approved_amount"
                              className="form-control input-height"
                              value={quickEditPopupData.total_approved_amount}
                              onChange={handlePopupChange}
                              disabled={selectFlag}
                            />
                          </div>
                          {/* <div className="col-md-4 mb-4">
                  <span className="view-all">View</span>
                </div> */}
                        </div>

                        {/* <label className="col-sm-8 col-form-head">No. of Tranches</label>
                                            <div className="row pl-3">
                                                <div className="col-md-11 mb-4">
                                                    <input
                                                        type="number"
                                                        aria-label="Select State"
                                                        id="no_of_tranches"
                                                        name="no_of_tranches"
                                                        className="form-control input-height"
                                                        value={quickEditPopupData.no_of_tranches}
                                                        onChange={handlePopupChange}
                                                        disabled={selectFlag}
                                                    />
                                                </div>
                                               
                                            </div> */}

                        <label className="col-sm-8 col-form-head">
                          Application Status
                        </label>
                        <div className="row pl-3">
                          <div className="col-md-11 mb-4">
                            <div className="select-custom">
                              <select
                                className="form-control input-height"
                                aria-label="Select State"
                                id="status_id"
                                name="status_id"
                                value={quickEditPopupData.application_status}
                                onChange={handlePopupChange}
                              >
                                {
                                  quickEditPopupData.total_disbursed_amount == "0" ?
                                    <>
                                      {getStatus.map(({ label, value }) =>
                                        label !== "Draft" ? (
                                          <option key={label} value={value}>
                                            {label}
                                          </option>
                                        ) : null
                                      )}

                                    </> :
                                    <>
                                      {applicationStatus.map(({ label, value }) =>
                                        label !== "Draft" ? (
                                          <option key={label} value={value}>
                                            {label}
                                          </option>
                                        ) : null
                                      )}
                                    </>
                                }
                              </select>
                            </div>
                          </div>
                          {/* <div className="col-md-4 mb-4">
                  <span className="view-all" onClick={() => setSelectFlag(false)}>Edit</span>
                </div> */}
                        </div>
                        <label className="col-sm-8 col-form-head">
                          Status of Engagement
                        </label>
                        <div className="row pl-3">
                          <div className="col-md-11 mb-4">
                            <div className="select-custom">
                              <select
                                className="form-control input-height"
                                aria-label="Select State"
                                id="status_id"
                                name="engagement_status"
                                value={quickEditPopupData.engagement_status}
                                onChange={handlePopupChange}
                              // disabled={selectFlag}
                              >
                                <option key="" value="">
                                  Select
                                </option>
                                {engagementStatus.map(({ label, value }) => (
                                  <option key={label} value={value}>
                                    {label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          {/* <div className="col-md-4 mb-4">
                  <span className="view-all" onClick={() => setSelectFlag(false)}>Edit</span>
                </div> */}
                        </div>
                        <div className="row pl-3 mb-4">
                          <div className="col-md-6">
                            <label className="col-form-head">
                              Date Of Evaluation
                            </label>
                            <fieldset disabled={selectFlag}>
                              <Calendar
                                id="registrationDate"
                                name="registrationDate"
                                className="form-control input-height"
                                format="dd/MM/yyyy"
                                selectedDate={
                                  quickEditPopupData.date_of_evaluation
                                }
                                onDateChange={(date: Date, field: string) =>
                                  handlePopupDateChange(date, field)
                                }
                                minDate={new Date()}
                              />
                            </fieldset>
                          </div>
                          <div className="col-md-5">
                            <label className="col-form-head">
                              No. of Tranches
                            </label>
                            <input
                              type="number"
                              readOnly
                              aria-label="Select State"
                              id="no_of_tranches"
                              name="no_of_tranches"
                              className="form-control input-height"
                              value={quickEditPopupData.no_of_tranches}
                              onChange={handlePopupChange}
                              disabled={selectFlag}
                            />
                          </div>
                        </div>
                        {/* <label className="col-sm-8 col-form-head">Date Of Evaluation</label>
                                            <div className="row pl-3">

                                                <div className="col-md-11 mb-4">
                                                    <fieldset disabled={selectFlag}>
                                                        <Calendar
                                                            id="registrationDate"
                                                            name="registrationDate"
                                                            className="form-control input-height"
                                                            format="dd/MM/yyyy"
                                                            selectedDate={quickEditPopupData.date_of_evaluation}
                                                            onDateChange={(date: Date, field: string) =>
                                                                handlePopupDateChange(date, field)
                                                            }
                                                            minDate={new Date()}
                                                        />
                                                    </fieldset>
                                                </div>
                                            </div> */}
                        <label className="col-sm-8 col-form-head">
                          Comments, if any
                        </label>
                        <div className="row pl-3">
                          <div className="col-md-11 mb-4">
                            <textarea
                              value={quickEditPopupData.application_comments}
                              className="form-control textarea-height"
                              name="popup_comments"
                              onChange={handlePopupChange}
                            />
                            {showValidation == true && (
                              <span className="text-danger">
                                Comment is mandatory
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="row pl-3">
                          <div className="col-md-6">
                            <button
                              className="btn btn-sisfs btn-transparent-primary full-width"
                              data-dismiss="modal"
                              onClick={() => setShowValidation(false)}
                            >
                              CANCEL
                            </button>
                          </div>
                          <div className="col-md-6">
                            {/* <button
                            className={
                              isDisabled
                                ? "tertiary  btn-yellow-primary full-width custom-disabled"
                                : "tertiary  btn-yellow-primary full-width"
                            }
                            disabled={isDisabled}
                            onClick={quickEditCall}
                          >
                            UPDATE
                          </button> */}

                            {
                              quickEditPopupData.application_status == '8' ?
                                <>
                                  <button
                                    className={
                                      isDisabled
                                        ? 'tertiary  btn-yellow-primary full-width custom-disabled'
                                        : 'tertiary  btn-yellow-primary full-width'
                                    }
                                    disabled={isDisabled}
                                    onClick={quickEditCallMOdal}
                                  // data-dismiss="modal"
                                  >
                                    UPDATE
                                  </button>
                                </>
                                :
                                <>
                                  <button
                                    className={
                                      isDisabled
                                        ? 'tertiary  btn-yellow-primary full-width custom-disabled'
                                        : 'tertiary  btn-yellow-primary full-width'
                                    }
                                    disabled={isDisabled}
                                    onClick={quickEditCall}
                                  // data-dismiss="modal"
                                  >
                                    UPDATE
                                  </button>
                                </>

                            }
                          </div>
                        </div>

                        {/* <div className="row pl-5">
                <button
                  className="btn btn-sisfs btn-transparent-primary"
                  data-dismiss="modal"
                >
                  CANCEL
                </button>
                <button
                  className="tertiary  btn-yellow-primary ml-3"
                  onClick={scheduleMeetingFunction}
                >
                  SCHEDULE
                </button>
              </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* modal for filter */}
                <div
                  className="modal fade"
                  id="filterModal"
                  role="dialog"
                  aria-labelledby="exampleModalLabel1"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-slideout modal-md"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header modal-header-bottom">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body pl-5 pr-5 pt-1">
                        <div className="popup-head mb-5 ml-3">
                          <div className="main-heading">Filters</div>
                        </div>

                        <label className="col-sm-4 col-form-head">Location</label>
                        <div className="col-md-12 mb-4">
                          <MultiSelect
                            id="countryStates"
                            options={countryStates}
                            defaultText="Select State"
                            onClose={(selectedIds: string[]) =>
                              onMultiSelectChange(selectedIds, "state")
                            }
                            selectedValues={
                              selectedStateValue ? selectedStateValue : []
                            }
                            hasError
                            errorText=""
                            searchable
                          />
                        </div>
                        <label className="col-sm-4 col-form-head">Sector</label>
                        <div className="col-md-12 mb-4">
                          <MultiSelect
                            id="countryStates"
                            options={sectorDetails}
                            defaultText="Select Industry"
                            onClose={(selectedIds: string[]) =>
                              onMultiSelectChange(selectedIds, "sector")
                            }
                            selectedValues={
                              selectedSectorValue ? selectedSectorValue : []
                            }
                            hasError
                            errorText=""
                            searchable
                          />
                        </div>
                        {/* <label className="col-sm-4 col-form-head">Legal Entity</label>
                                            <div className="col-md-12 mb-4">
                                                <MultiSelect
                                                    id="countryStates"
                                                    options={entityDetails}
                                                    defaultText="Select Industry"
                                                    onClose={(selectedIds: string[]) =>
                                                        onMultiSelectChange(selectedIds, 'entity')
                                                    }
                                                    selectedValues={
                                                        selectedEntityValue ? selectedEntityValue : []
                                                    }
                                                    hasError
                                                    errorText=""
                                                    searchable
                                                />
                                            </div> */}
                        <label className="col-sm-12 col-form-head">
                          Types of Funds Required
                        </label>
                        <div className="col-md-12 mb-4">
                          <div className="select-custom">
                            <select
                              className="form-control input-height"
                              aria-label="Select Fund Type"
                              value={fundingType}
                              onChange={(e: any) =>
                                setFundingType(e.target.value)
                              }
                            >
                              <option >Select</option>
                              {/* selected */}
                              <option value="funding_grant">Grant</option>
                              <option value="funding_debenture">Debenture</option>
                              <option value="funding_debt">Debt</option>
                            </select>
                          </div>
                        </div>
                        <label className="col-sm-12 col-form-head">
                          Date Of Submission
                        </label>
                        <div className="col-md-12 mb-4">
                          <Calendar
                            id="submissionDate"
                            name="submissionDate"
                            className="form-control input-height"
                            format="dd/MM/yyyy"
                            selectedDate={filterDate}
                            onDateChange={(date: Date, field: string) =>
                              handleDateChange(date, "submissionDate")
                            }
                          />
                        </div>
                      </div>
                      <div className="modal-footer">
                        <div className="row modal-row">
                          <div className="col-md-6 text-center">
                            <button
                              className="btn btn-sisfs btn-transparent-primary"
                              data-dismiss="modal"
                              onClick={resetFilter}
                            >
                              RESET ALL
                            </button>
                          </div>
                          <div className="col-md-6 text-left">
                            <button
                              className="tertiary  btn-yellow-primary"
                              data-dismiss="modal"
                              onClick={applyFilter}
                            >
                              APPLY FILTER
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* schedule a meeting tab */}
                <div
                  className="modal fade"
                  id="scheduleMeeting"
                  role="dialog"
                  aria-labelledby="exampleModalLabel1"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-slideout modal-md"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header modal-header-bottom pr-5">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={() => (
                            setTimeValidation(false),
                            setTimeValidationCurrentTime(false),
                            setLinkValidation(false)
                          )}
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body pl-5 pr-5 pt-2">
                        <div className="popup-head mb-5 ml-3">
                          <div className="main-heading">Schedule a Meeting</div>
                          <div className="count-text">
                            {checkedArray.length} Startups Selected
                          </div>
                        </div>

                        <label className="col-sm-4 col-form-head">
                          Meeting Type
                        </label>
                        <div className="col-md-12 mb-4">
                          <div className="select-custom">
                            <select
                              className="form-control input-height"
                              aria-label="Select Entity Type"
                              onChange={handleOnChange}
                            >
                              {tableHeading == "Applications Selected" ? (
                                <option value="2">
                                  Performance Review Meeting
                                </option>
                              ) : null}
                              {tableHeading == "Applications to be Reviewed" ? (
                                <option value="1">Startup Selection</option>
                              ) : null}
                            </select>
                          </div>
                        </div>
                        <label className="col-sm-4 col-form-head">
                          Date & Time
                        </label>
                        <div className="col-md-6 mb-4">
                          <Calendar
                            id="registrationDate"
                            name="registrationDate"
                            className="form-control input-height"
                            format="dd/MM/yyyy"
                            selectedDate={
                              selectedDateControl
                                ? selectedDateControl
                                : new Date()
                            }
                            onDateChange={(date: Date, field: string) =>
                              handleDateChange(date, field)
                            }
                          // minDate={new Date()}
                          />
                        </div>
                        <div className="row pl-3">
                          <div className="col-md-6">
                            <label className="col-form-head">From Time</label>
                            <input
                              type="time"
                              value={fromTime}
                              name="fromTime"
                              className="form-control input-height"
                              onChange={onTimeChange}
                            />
                          </div>
                          <div className="col-md-6">
                            <label className="col-form-head">To Time</label>
                            <input
                              type="time"
                              value={toTime}
                              name="toTime"
                              className="form-control input-height"
                              onChange={onTimeChange}
                            />
                          </div>
                        </div>
                        <div className="row text-left pl-4 mb-5">
                          {timeValidation == true ? (
                            <label className="error-message">
                              From time greater than To time
                            </label>
                          ) : null}
                          {timeValidationCurrentTime == true ? (
                            <label className="error-message">
                              Meeting can't be scheduled for past time
                            </label>
                          ) : null}
                        </div>
                        <label className="col-sm-4 col-form-head">
                          Meeting Link
                        </label>
                        <div className="col-md-12 mb-4">
                          <input
                            type="text"
                            id="meeting_link"
                            name="meeting_link"
                            className="form-control input-height"
                            onChange={(e: any) => setMeetingLink(e.target.value)}
                          />
                          <div className="row text-left pl-3">
                            {linkValidation == true ? (
                              <label className="error-message">
                                Meeting Link is required
                              </label>
                            ) : null}
                          </div>
                        </div>

                        <div className="row pl-3">
                          <div className="col-md-6">
                            <button
                              className="btn btn-sisfs btn-transparent-primary full-width"
                              data-dismiss="modal"
                              onClick={() => (
                                setTimeValidation(false),
                                setTimeValidationCurrentTime(false),
                                setLinkValidation(false)
                              )}
                            >
                              CANCEL
                            </button>
                          </div>
                          <div className="col-md-6">
                            <button
                              className={
                                isDisabled
                                  ? "tertiary  btn-yellow-primary full-width custom-disabled"
                                  : "tertiary  btn-yellow-primary full-width"
                              }
                              disabled={isDisabled}
                              onClick={scheduleMeetingFunction}
                            >
                              SCHEDULE
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* quick edit popup */}
                <div
                  className="modal fade"
                  id="quickEdit"
                  role="dialog"
                  aria-labelledby="exampleModalLabel1"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-slideout modal-md"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header modal-header-bottom pr-5">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={() => setSelectFlag(true)}
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body pl-5 pr-5 pt-5">
                        <div className="popup-head mb-5 ml-2">
                          <div className="main-heading">
                            {quickEditPopupData.incubator_name}
                          </div>
                          <div className="count-text">
                            {"No: " + quickEditPopupData.application_id}
                          </div>
                        </div>

                        <label className="col-sm-8 col-form-head">
                          Total Grant Approved (
                          {quickEditPopupData.no_of_tranches} Tranches)
                        </label>
                        {showEditTranche ? (
                          <span
                            className="col-sm-4 text-left view-all"
                            data-dismiss="modal"
                            onClick={() =>
                              handleRowChange(
                                quickEditPopupData.application_id,
                                0,
                                0,
                                0,
                                0,
                                "",
                                0
                              )
                            }
                          >
                            Edit Tranche
                          </span>
                        ) : null}
                        <div className="row pl-3">
                          <div className="col-md-11 mb-4">
                            <input
                              type="number"
                              id="total_approved_amount"
                              name="total_approved_amount"
                              className="form-control input-height"
                              value={quickEditPopupData.total_approved_amount}
                              onChange={handlePopupChange}
                              disabled={selectFlag}
                            />
                          </div>
                          {/* <div className="col-md-4 mb-4">
                  <span className="view-all">View</span>
                </div> */}
                        </div>

                        <label className="col-sm-8 col-form-head">
                          No. of Tranches
                        </label>
                        <div className="row pl-3">
                          <div className="col-md-11 mb-4">
                            <input
                              type="number"
                              aria-label="Select State"
                              id="no_of_tranches"
                              name="no_of_tranches"
                              className="form-control input-height"
                              value={quickEditPopupData.no_of_tranches}
                              onChange={handlePopupChange}
                              disabled={selectFlag}
                            />
                          </div>
                          {/* <div className="col-md-4 mb-4">
                  <span className="view-all" >Edit</span>
                </div> */}
                        </div>

                        <label className="col-sm-8 col-form-head">
                          Application Status
                        </label>
                        <div className="row pl-3">
                          <div className="col-md-11 mb-4">
                            <div className="select-custom">
                              <select
                                className="form-control input-height"
                                aria-label="Select State"
                                id="status_id"
                                name="status_id"
                                value={quickEditPopupData.application_status}
                                onChange={handlePopupChange}
                              >
                                {applicationStatus.map(({ label, value }) =>
                                  label !== "Draft" ? (
                                    <option key={label} value={value}>
                                      {label}
                                    </option>
                                  ) : null
                                )}
                              </select>
                            </div>
                          </div>
                          {/* <div className="col-md-4 mb-4">
                  <span className="view-all" onClick={() => setSelectFlag(false)}>Edit</span>
                </div> */}
                        </div>
                        <label className="col-sm-8 col-form-head">
                          Date Of Evaluation
                        </label>
                        <div className="row pl-3">
                          <div className="col-md-11 mb-4">
                            <fieldset disabled={selectFlag}>
                              <Calendar
                                id="registrationDate"
                                name="registrationDate"
                                className="form-control input-height"
                                format="dd/MM/yyyy"
                                selectedDate={
                                  quickEditPopupData.date_of_evaluation
                                }
                                onDateChange={(date: Date, field: string) =>
                                  handlePopupDateChange(date, field)
                                }
                                minDate={new Date()}
                              />
                            </fieldset>
                          </div>
                          {/* <div className="col-md-3 text-left mb-4">
                  <span className="view-all">View Details</span>
                </div> */}
                        </div>
                        <label className="col-sm-8 col-form-head">
                          Comments, if any
                        </label>
                        <div className="row pl-3">
                          <div className="col-md-11 mb-4">
                            <textarea
                              value={quickEditPopupData.application_comments}
                              className="form-control textarea-height"
                              name="popup_comments"
                              onChange={handlePopupChange}
                            />
                            {showValidation == true && (
                              <span className="text-danger">
                                Comment is mandatory
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="row pl-3">
                          <div className="col-md-6">
                            <button
                              className="btn btn-sisfs btn-transparent-primary full-width"
                              data-dismiss="modal"
                            >
                              CANCEL
                            </button>
                          </div>
                          <div className="col-md-6">
                            <button
                              className={
                                isDisabled
                                  ? "tertiary  btn-yellow-primary full-width custom-disabled"
                                  : "tertiary  btn-yellow-primary full-width"
                              }
                              disabled={isDisabled}
                              onClick={quickEditCall}
                            >
                              UPDATE
                            </button>
                          </div>
                        </div>

                        {/* <div className="row pl-5">
                <button
                  className="btn btn-sisfs btn-transparent-primary"
                  data-dismiss="modal"
                >
                  CANCEL
                </button>
                <button
                  className="tertiary  btn-yellow-primary ml-3"
                  onClick={scheduleMeetingFunction}
                >
                  SCHEDULE
                </button>
              </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                {showQuickEditModal && (
                  <>
                    <div className="modal-backdrop fade show"></div>
                    <div
                      className="modal align-middle modal-sisfs d-block"
                      id="FormSubmissionError">
                      <div className="modal-dialog modal-dialog-centered modal-md">
                        <div className="modal-content">
                          <div className="modal-body text-center">
                            {/* <img
               src={error}
               className="search mb-2"
               alt="search"

             /> */}
                            <h3 className="modal-message mt-2">
                              Are you sure you wish to close the application?
                            </h3>
                            {/* <h3 className="modal-text">{confirmationDialogData.text}</h3> */}
                            <h6 className=" message-orange">
                              Note: Please make sure that all funds disbursed
                              to the Startup are mentioned in the respective Tranches. In case you wish to update please press No.
                            </h6>
                            <br />
                            <div className="modal-action mt-2">
                              <button
                                className="btn btn-sisfs btn-transparent-primary"
                                onClick={() => setShowQuickEditModal(false)}>
                                NO
                              </button>
                              <button
                                className="btn btn-sisfs btn-yellow-primary ml-2"
                                onClick={quickEditCall}
                              >
                                YES
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {showIncubatorSuccessModal && (
                  // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
                  // <>
                  //   <div className="modal-backdrop fade show"></div>

                  //   <div
                  //     className="modal align-middle modal-sisfs d-block"
                  //     id="FormSubmissionSuccess"
                  //   >
                  //     <div className="modal-dialog modal-dialog-centered modal-md">
                  //       <div className="modal-content">
                  //         <div className="modal-body text-center">
                  //           <i className="fa fa-check-circle-o"></i>
                  //           <h3 className="modal-message">Thank You!</h3>
                  //           <div className="modal-text">
                  //             Your Meeting Has been Scheduled
                  //                         </div>
                  //           <div className="modal-action">
                  //             <button className="btn btn-sisfs btn-yellow-primary" onClick={dismissModal}>
                  //               Ok!!
                  //                         </button>
                  //           </div>
                  //         </div>
                  //       </div>
                  //     </div>
                  //   </div>
                  // </>
                  <ConfirmationDialog
                    headerText={dialogData.header}
                    bodyText={dialogData.text}
                    onDialogClose={dismissModal}
                  />
                )}
                {showIncubatorFailureModal && (
                  // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
                  // <>
                  //   <div className="modal-backdrop fade show"></div>
                  //   <div
                  //     className="modal align-middle modal-sisfs d-block"
                  //     id="FormSubmissionError"
                  //   >
                  //     <div className="modal-dialog modal-dialog-centered modal-md">
                  //       <div className="modal-content">
                  //         <div className="modal-body text-center">
                  //         <img
                  //       src={error}
                  //       className="search mb-2"
                  //       alt="search"

                  //     />
                  //           <h3 className="modal-message">
                  //             {failDialogData.header}
                  //                       </h3>
                  //           <h3 className="modal-text">
                  //           {failDialogData.text}
                  //                       </h3>
                  //           <div className="modal-action">
                  //             <button className="btn btn-sisfs btn-yellow-primary" onClick={() => setShowIncubatorFailureModal(false)}>
                  //               OK!
                  //                       </button>
                  //           </div>
                  //         </div>
                  //       </div>
                  //     </div>
                  //   </div>
                  // </>
                  <ConfirmationDialog
                    headerText={failDialogData.header}
                    bodyText={failDialogData.text}
                    onDialogClose={() => setShowIncubatorFailureModal(false)}
                    iconClass="test"
                  />
                )}
                {showConfirmationBox && (
                  <>
                    <div className="modal-backdrop fade show"></div>
                    <div
                      className="modal align-middle modal-sisfs d-block"
                      id="FormSubmissionError"
                    >
                      <div className="modal-dialog modal-dialog-centered modal-md">
                        <div className="modal-content">
                          <div className="modal-body text-center">
                            {/* <img
               src={error}
               className="search mb-2"
               alt="search"

             /> */}
                            <h3 className="modal-message mt-2">
                              {confirmationDialogData.header}
                            </h3>
                            <h3 className="modal-text">
                              {confirmationDialogData.text}
                            </h3>
                            <div className="modal-action mt-2">
                              <button
                                className="btn btn-sisfs btn-transparent-primary"
                                onClick={() => setShowConfirmationBox(false)}
                              >
                                Cancel
                              </button>
                              <button
                                className={
                                  isDisabled
                                    ? "btn btn-sisfs btn-yellow-primary ml-2 custom-disabled"
                                    : "btn btn-sisfs btn-yellow-primary ml-2"
                                }
                                disabled={isDisabled}
                                onClick={finalSchedule}
                              >
                                Schedule Anyway
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {/* edit success Modal */}
                {showEditSuccessModal && (
                  // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
                  <>
                    <div className="modal-backdrop fade show"></div>

                    <div
                      className="modal align-middle modal-sisfs d-block"
                      id="FormSubmissionSuccess"
                    >
                      <div className="modal-dialog modal-dialog-centered modal-md">
                        <div className="modal-content">
                          <div className="modal-body text-center">
                            <i className="fa fa-check-circle-o"></i>
                            <h3 className="modal-message">Thank You!</h3>
                            <div className="modal-text">
                              Application has been Updated Successfully
                            </div>
                            <div className="modal-action">
                              <button
                                className="btn btn-sisfs btn-yellow-primary"
                                onClick={dismissModal}
                              >
                                Ok!!
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {/* edit fail modal */}
                {showEditFailureModal && (
                  // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
                  <>
                    <div className="modal-backdrop fade show"></div>
                    <div
                      className="modal align-middle modal-sisfs d-block"
                      id="FormSubmissionError"
                    >
                      <div className="modal-dialog modal-dialog-centered modal-md">
                        <div className="modal-content">
                          <div className="modal-body text-center">
                            {/* <i className="fa fa-times-circle-o"></i> */}
                            <h3 className="modal-message message-orange">
                              Oops! Something is Wrong
                            </h3>
                            <br />
                            <h3 className="modal-message message-orange">
                              Please Try Again!!!
                            </h3>
                            <div className="modal-action">
                              <button
                                className="btn btn-sisfs btn-yellow-primary"
                                onClick={dismissModal}
                              >
                                OK!
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

      </fieldset>
      {/* Conformation Poppup */}
      <div className="modal fade" id="QRSuccess" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal align-middle modal-sisfs d-block" id="FormSubmissionError">
              <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                  <div style={{ textAlign: 'right', }}>
                    <i className="fa fa-close" data-dismiss="modal" style={{ cursor: 'pointer', fontSize: '30px', marginRight: '15px', marginTop: '15px', color: '#D3D3D3' }}></i>
                  </div>
                  <div className="modal-body pt-0">
                    <form onSubmit={Submit_Quaterly_Review}>
                      <div className='row'>
                        <div className='col-12 mt-3'>
                          <label className="form-label required d-flex justify-content-between">
                            <b>Sucess Story of Incubator</b>
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip id="example-tooltip" style={{ fontSize: '15px' }}>For success stories of incubators, please mention links to articles, media coverage, or any other related material, as links (if any) within the textbox (For Example: Google Drive links etc.).</Tooltip>}
                            >
                              <button
                                type="button"
                                style={{
                                  borderRadius: '20px',
                                  backgroundColor: '#000000',
                                  color: '#ffffff',
                                  border: 'none',
                                  width: '25px',
                                  height: '25px',
                                }}
                              >
                                !
                              </button>
                            </OverlayTrigger>
                          </label>
                          <textarea className="form-control" name="success_story" placeholder="Sucess Story" value={returnsFormSubmit.success_story} onChange={handleChange}></textarea>
                        </div>
                        <div className='col-12 mt-3'>
                          <label className="form-label required">
                            <b>Intereset Earned On Unutilized funds (INR)</b>
                          </label>
                          <input className="form-control" name="intrest_earneed" value={returnsFormSubmit.intrest_earneed} placeholder="Intereset Earned" required onChange={handleChange} />

                        </div>
                        <div className='col-12 mt-3'>
                          <label className="form-label required">
                            <b>Any Additional Information</b>
                          </label>
                          <textarea className="form-control" name="additional_information" value={returnsFormSubmit.additional_information} placeholder="Additional Information" onChange={handleChange}></textarea>
                        </div>
                        <div className='col-12 mt-3'>
                          <input type="checkbox" required />
                          <p>We have checked and confirm the information of all startups incubated by us. They are true to best of our knowledge and are as per scheme Guidelines</p>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-2"></div>
                        <div className="col-10 d-flex flex-row justify-content-end">
                          <button className="btn btn-sisfs btn-yellow-primary" type='submit'>
                            Submit Quarterly Review
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* QR Pending Poppup */}
      <div className="modal fade" id="QRPending" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal align-middle modal-sisfs d-block" id="FormSubmissionError">
              <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                  <div style={{ textAlign: 'right', }}>
                    <i className="fa fa-close" data-dismiss="modal" style={{ cursor: 'pointer', fontSize: '30px', marginRight: '15px', marginTop: '15px', color: '#D3D3D3' }}></i>
                  </div>
                  <div className="modal-body text-center pt-0">
                    <h4 className="modal-message" style={{ color: '#2b3f88', }}>
                      Quarterly review has not been completed for all startups. Please complete Quaterly review of all startups before submitting the Review by Clicking on the Startup
                    </h4>
                    <div className="modal-action">
                      <button className="btn btn-sisfs btn-yellow-primary" data-dismiss="modal">
                        OK!
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Conformation Poppup */}
      <div className="modal fade" id="LastSubmitForm" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal align-middle modal-sisfs d-block" id="FormSubmissionError">
              <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                  <div style={{ textAlign: 'right', }}>
                    <i className="fa fa-close" data-dismiss="modal" style={{ cursor: 'pointer', fontSize: '30px', marginRight: '15px', marginTop: '15px', color: '#D3D3D3' }}></i>
                  </div>
                  <div className="modal-body text-center pt-0">
                    <h4 className="modal-message" style={{ color: '#2b3f88', }}>
                      All the information provided by me regarding all the startups associated with me are correct and i take full responsibility of it.
                    </h4>
                    <div className="modal-action">
                      <button className="btn btn-sisfs btn-yellow-primary" onClick={ConformationClick}>
                        Yes
                      </button>
                      <button className="btn btn-sisfs btn-yellow-primary ml-3" data-dismiss="modal">
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
}

export default StartupListContainer;
