import React, { useState, useEffect } from "react"
import { get, post } from "../../../../effects/api/api.common";
import { File_node_url, INCUBATOE_RETURNS_FORM, INCUBATOE_RETURNS_FORM_Delete, INCUBATOE_RETURNS_FORM_Edit, INCUBATOE_RETURNS_GET_FORM_DATA, QUATERLY_REVIEW_RETURNS } from "../../../../constants/api";
import { getSessionStorageValue } from "../../../../effects/utils/session-storage";
import moment from "moment";
import { changeNumberFormat } from "../../../../utils/CommonMethods";
import ConfirmationDialog from "../../../common/controls/ConfirmationDialog";
import Allround from '../../../../images/Allround.svg';
import SuccessCheck from '../../../../images/ReturnsIcons/check (2).svg'
import pdf from '../../../../images/PDF.svg';
declare var $: any;

interface IIKeyValueIndexer {
    [key: string]: boolean;
}

const ReturnsFields = (props: any) => {
    // console.log(props);
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    // const [errors, setErrors] = useState<any>({});

    // const [uploadFile_Id, setuploadFile_Id] = useState()
    const [disabled_part2, setdisabled_part2] = useState(null);
    const [Report_Returns_Form_Hide, setReport_Returns_Form_Hide] = useState(false);
    const [returns_reported_Data, setreturns_reported_Data] = useState()
    const [EditReturnFormFile, setEditReturnFormFile] = useState<any>("")
    const [QuaterlyBTN, setQuaterlyBTN] = useState(false);
    const [showIncubatorSuccessModal, setShowIncubatorSuccessModal] = useState(false);
    const [dialogData, setDialogData] = useState({
        header: '',
        text: '',
    });
    const [showIncubatorFailureModal, setShowIncubatorFailureModal] = useState(false);
    const [failDialogData, setFailDialogData] = useState({
        header: '',
        text: '',
    });

    const [returnsFormSubmit, setreturnsFormSubmit] = useState<any>({
        date_of_transaction: '',
        reference_id: '',
        type_of_return: '',
        other_return_type: '',
        fund_returned: '',
        remarks: '',
        file: "",
    })

    const [returnsForm, setreturnsForm] = useState<any>({
        date_of_transaction: '',
        reference_id: '',
        type_of_return: '',
        other_return_type: '',
        fund_returned: '',
        remarks: '',
        file: "",
    })

    const handleChange22 = (e: any, Id: any) => {
        const { name, value, type } = e.target;
        const editValue = type === 'file' ? e.target.files[0] : name === 'fund_returned' ? value.replace(/[^0-9.]|(?<=\..*)\./g, '') : value;

        var editData: any = []
        editData = returnsForm.map((item: any) =>
            item.id === Id && name ? { ...item, [name]: editValue } : item
        )

        // console.log(editData);
        setreturnsForm(editData);

    }

    const handleChange = (event: any) => {
        const { name, value, type } = event.target;
        // const newValue = type === 'file' ? event.target.files[0] : name === 'fund_returned' ? value.replace(/[^0-9]/ig, '') : value;

        if (type === 'file') {
            const file = event.target.files[0];
            setreturnsFormSubmit((prevFormData: any) => ({
                ...prevFormData,
                [name]: file,
            }));
        } else if (name === 'fund_returned') {
            const newValue = value.replace(/[^0-9.]|(?<=\..*)\./g, '');
            setreturnsFormSubmit((prevFormData: any) => ({
                ...prevFormData,
                [name]: newValue,
            }));
        } else {
            setreturnsFormSubmit((prevFormData: any) => ({
                ...prevFormData,
                [name]: value,
            }));
        }
        // setreturnsFormSubmit((prevFormData: any) => ({
        //     ...prevFormData,
        //     [name]: newValue,
        // }));
    };

    const handleChange33 = (event: any) => {
        const { name, value, type } = event.target;
        // const newValue = type === 'file' ? event.target.files[0] : name === 'fund_returned' ? value.replace(/[^0-9]/ig, '') : value;
        setEditReturnFormFile(event.target.files[0]);
    };

    useEffect(() => {
        formGetAPI();
        // console.log(props)
        if (props.qrDetails && props.qrDetails.length > 0) {
            setQuaterlyBTN(true);
        }
    }, []);


    const formGetAPI = () => {
        get(`${INCUBATOE_RETURNS_GET_FORM_DATA}/${props.application_id}`).then((res: any) => {
            // console.log(res);
            setreturns_reported_Data(res.data.returns_reported)
            setreturnsForm(res.data.data);
            setdisabled_part2(null);

        })
    }

    const SaveReturnsTab = (e: any, item: any, i: any) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("date_of_transaction", moment(item.date_of_transaction).format("YYYY-MM-DD"))
        formData.append("reference_id", item.reference_id)
        formData.append("type_of_return", item.type_of_return)
        formData.append("remarks", item.remarks)
        formData.append("file", EditReturnFormFile)
        formData.append("logged_user_id", userInfo.id)
        formData.append("fund_returned", item.fund_returned)
        formData.append("other_return_type", item.other_return_type)
        formData.append("startup_application_id", props.application_id)
        formData.append("return_id", item.id)

        post(INCUBATOE_RETURNS_FORM_Edit, formData).then((response: any) => {
            // console.log(response);
            if (response.data.result == "success") {
                setDialogData({
                    header: 'Thank You!',
                    text: response.data.messgae,
                });
                setShowIncubatorSuccessModal(true)
            } else {
                setFailDialogData({
                    header: '',
                    text: response.data.messgae,
                });
                setShowIncubatorFailureModal(true);
            }
        });
    }


    const DeleteClick = (item: any, i: any) => {
        let payload = {
            "return_id": item.id,
            "startup_application_id": props.application_id,
            "logged_user_id": userInfo.id
        }
        // console.log(payload);
        post(INCUBATOE_RETURNS_FORM_Delete, payload).then((response: any) => {
            // console.log(response);
            if (response.data.result == "success") {
                setDialogData({
                    header: 'Thank You!',
                    text: response.data.messgae,
                });
                setShowIncubatorSuccessModal(true)
            } else {
                setFailDialogData({
                    header: '',
                    text: response.data.messgae,
                });
                setShowIncubatorFailureModal(true);
            }

        });
    }

    // const validateForm = () => {
    //     const newErrors:any = {};

    //     if (!returnsFormSubmit.date_of_transaction) {
    //       newErrors.date_of_transaction = 'Date of Transaction is required';
    //     }
    //     if (!returnsFormSubmit.reference_id) {
    //       newErrors.reference_id = 'Reference ID is required';
    //     }
    //     if (!returnsFormSubmit.type_of_return) {
    //       newErrors.type_of_return = 'Type of Return is required';
    //     } else if (returnsFormSubmit.type_of_return === '5' && !returnsFormSubmit.other_return_type) {
    //       newErrors.other_return_type = 'Others is required for Type of Return';
    //     }
    //     if(!returnsFormSubmit.fund_returned) {
    //       newErrors.fund_returned = 'Amount Returned is required';
    //     }
    //     if (!returnsFormSubmit.remarks) {
    //         newErrors.remarks = 'Remarks is required';
    //       }

    //     setErrors(newErrors);
    //   };

    const SubmitReturnsTab = (e: any) => {
        e.preventDefault();
        // {console.log(errors)}
        // if(!returnsFormSubmit.date_of_transaction) {
        //     validateForm();
        //     return;
        // }if(!returnsFormSubmit.reference_id) {
        //     validateForm();
        //     return;
        // }if(!returnsFormSubmit.type_of_return) {
        //     validateForm();
        //     return;
        // }else if (returnsFormSubmit.type_of_return === '5' && !returnsFormSubmit.other_return_type) {
        //     validateForm(); 
        // }if(!returnsFormSubmit.remarks) {
        //     validateForm();
        //     return;
        // }else{
        // console.log(returnsFormSubmit);
        // return;
        const formData = new FormData();
        formData.append("date_of_transaction", returnsFormSubmit.date_of_transaction)
        formData.append("reference_id", returnsFormSubmit.reference_id)
        formData.append("type_of_return", returnsFormSubmit.type_of_return)
        formData.append("remarks", returnsFormSubmit.remarks)
        formData.append("file", returnsFormSubmit.file)
        formData.append("logged_user_id", userInfo.id)
        formData.append("fund_returned", returnsFormSubmit.fund_returned)
        formData.append("other_return_type", returnsFormSubmit.other_return_type)
        formData.append("startup_application_id", props.application_id)
        post(INCUBATOE_RETURNS_FORM, formData).then((response: any) => {
            if (response.data.result == "success") {
                setDialogData({
                    header: 'Thank You!',
                    text: response.data.messgae,
                });
                setShowIncubatorSuccessModal(true)
            } else {
                setFailDialogData({
                    header: '',
                    text: response.data.messgae,
                });
                setShowIncubatorFailureModal(true);
            }
        });
        // }

    };

    const dismissModal = () => {
        window.location.reload();
    };



    const ConformationClick = () => {
        let payload = {
            "status": 1,

            "user_id": userInfo.id,

            "startup_application_id": props.application_id,

            "QRID": props.quaterlyReports_returns.length != 0 ? props.quaterlyReports_returns[0].id : '',

            "quaterly_review_id": props.qrDetails[0].id
        }
        post(QUATERLY_REVIEW_RETURNS, payload).then((res: any) => {
            // console.log(res.data);
            if (res.data.result == "success") {
                window.location.reload();
            }
        })
    }

    return (
        <div>
            
            <fieldset disabled= {userInfo.roles == 'secretariat'}>
                <div className="row">
                    <div className="col-6 text-center">
                        <span className="fund-amount-cur">
                            &#8377;
                            <label className="fund-amount-label-value">
                                {' '}
                                {
                                    returns_reported_Data != null && returns_reported_Data != "0"
                                        ? changeNumberFormat(Number(returns_reported_Data), 2, 1)
                                        :
                                        0
                                }
                            </label>
                            {/* Cr */}
                        </span>
                        <br />
                        <span className="fund-amount-label">Total Returns from the Startup</span>
                    </div>
                    <div className="vl vl-mr"></div>
                    <div className="col-5 mt-4" style={{ textAlignLast: 'end' }}>
                        <button className="btn btn-sisfs btn-yellow-primary mr-3" onClick={() => {
                            setReport_Returns_Form_Hide(true)
                        }}
                        >Report Returns</button>
                    </div>

                </div>
            </fieldset>
            <br />
            <hr />

            {Report_Returns_Form_Hide && <div>
                {/* {console.log(errors)} */}
                <fieldset disabled= {userInfo.roles == 'secretariat'}>
                <form className="mb-3 p-2" onSubmit={SubmitReturnsTab}>
                    <div className="row">
                        <div className="col-md-6 mt-3">
                            <label className="form-label required">
                                <b>Date of Transaction</b>
                            </label>
                            <br />
                            <input
                                type="date"
                                name="date_of_transaction"
                                className="form-control"
                                // value={returnsFormSubmit.date_of_transaction}
                                onChange={handleChange}
                                required
                                title="Please Select the Date"
                                placeholder="Select Date"
                            />
                            {/* {errors.date_of_transaction && <small style={{ color: 'red' }}>{errors.date_of_transaction}</small>} */}
                        </div>

                        <div className="col-md-6 mt-3">
                            <label className="form-label required">
                                <b>Reference ID</b>
                            </label>
                            <br />
                            <input
                                min="0"
                                name="reference_id"
                                value={returnsFormSubmit.reference_id}
                                className="form-control"
                                onChange={handleChange}
                                required={true}
                                maxLength={255}
                                placeholder="Reference ID"
                            />
                            {/* {errors.date_of_transaction && <small style={{ color: 'red' }}>{errors.reference_id}</small>} */}
                        </div>
                        <div className="col-md-6 mt-3">
                            <label className="form-label required">
                                <b>Type of Return</b>
                            </label>
                            <br />
                            {/* value={returnsFormSubmit.type_of_return} */}
                            <select className="form-control" value={returnsFormSubmit.type_of_return} name="type_of_return" aria-label="Select Entity Type" onChange={handleChange} required>
                                <option value="">Select Type</option>
                                <option value="1">Principal</option>
                                <option value="2">Interest</option>
                                <option value="3">Sale of Equity  </option>
                                <option value="4">Secondary sale of Instrument </option>
                                <option value="5">Other </option>
                            </select>
                            {/* {errors.date_of_transaction && <small style={{ color: 'red' }}>{errors.reference_id}</small>} */}
                        </div>
                        {returnsFormSubmit.type_of_return == 5 && <div className="col-md-6 mt-3">
                            <label className="form-label required">
                                <b>Others</b>
                            </label>
                            <br />
                            <input
                                min="0"
                                name="other_return_type"
                                value={returnsFormSubmit.other_return_type}
                                className="form-control"
                                onChange={handleChange}
                                required={true}
                                maxLength={255}
                                placeholder="Enter Others"
                            />
                            {/* {errors.date_of_transaction && <small style={{ color: 'red' }}>{errors.other_return_type}</small>} */}
                        </div>}
                        <div className="col-md-6 mt-3">
                            <label className="form-label required">
                                <b>Amount Returned</b>
                            </label>
                            <br />
                            <input
                                min="0"
                                name="fund_returned"
                                value={returnsFormSubmit.fund_returned}
                                className="form-control"
                                onChange={handleChange}
                                required={true}
                                maxLength={255}
                                placeholder="Enter Returns"
                            />
                            {/* {errors.date_of_transaction && <small style={{ color: 'red' }}>{errors.fund_returned}</small>} */}
                        </div>
                        <div className="col-md-6 mt-3">
                            <label className="form-label required">
                                <b>Remarks</b>
                            </label>
                            <br />
                            <textarea
                                name="remarks"
                                value={returnsFormSubmit.remarks}
                                className="form-control"
                                onChange={handleChange}
                                placeholder="Enter Remarks"
                                required
                            />
                            {/* {errors.date_of_transaction && <small style={{ color: 'red' }}>{errors.remarks}</small>} */}
                        </div>
                        <div className="col-md-6" style={{ marginTop: "20px" }}>
                        <label className="form-label required">
                                <b>Bank statement to verify returns</b>
                            </label>
                            <br />
                            <input
                                type="file"
                                name="file"
                                required={true}
                                onChange={handleChange}
                                accept=".pdf"
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-6 mt-3">
                            {/* <b><span onClick={() => {setdisabled_part2(i)}} style={{ color: '#f1970afa', cursor: 'pointer' }}>Edit</span>  /  <span style={{ color: 'red', cursor: 'pointer' }}>Delete</span></b> */}
                            <button className="btn btn-sisfs btn-yellow-primary" onClick={() => setReport_Returns_Form_Hide(false)}>Cancel</button>
                        </div>
                        <div className="col-md-6 mt-3">
                            <button type="submit" className="btn btn-sisfs btn-yellow-primary" style={{ float: 'right' }} >Submit</button>
                        </div>
                    </div>
                </form>
                </fieldset>
                <hr />
            </div>}


            <div style={{ maxHeight: '850px', overflowY: 'auto', overflowX: 'hidden' }}>
                {returnsForm != undefined && returnsForm.length > 0
                    ? returnsForm.map((item: any, i: any) => (
                        <fieldset disabled={userInfo.roles=='secretariat'}>
                            <div>
                            <form className="mb-3 p-2" onSubmit={(e) => SaveReturnsTab(e, item, i)}>
                                {/* <fieldset disabled={i != disabled_part2 && true}> */}
                                    {/* {console.log(item)} */}
                                    <div className="row">
                                        <div className="col-md-6 mt-3">
                                            <label className="form-label required">
                                                <b>Date of Transaction</b>
                                            </label>
                                            <br />
                                            <input
                                                type="date"
                                                name="date_of_transaction"
                                                className="form-control"
                                                value={item.date_of_transaction.slice(0, 10)}
                                                onChange={(e) => handleChange22(e, item.id)}
                                                required
                                                disabled={i != disabled_part2 && true}
                                            />
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <label className="form-label required">
                                                <b>Reference ID</b>
                                            </label>
                                            <br />
                                            <input
                                                min="0"
                                                name="reference_id"
                                                value={item.reference_id}
                                                className="form-control"
                                                onChange={(e) => handleChange22(e, item.id)}
                                                required={true}
                                                maxLength={255}
                                                disabled={i != disabled_part2 && true}
                                            />
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <label className="form-label required">
                                                <b>Type of Return</b>
                                            </label>
                                            <br />
                                            <select className="form-control" value={item.type_of_return} name="type_of_return" aria-label="Select Entity Type" onChange={(e) => handleChange22(e, item.id)} required disabled={i != disabled_part2 && true}>
                                                <option value="">Select</option>
                                                <option value="1">Principal</option>
                                                <option value="2">Interest</option>
                                                <option value="3">Sale of Equity  </option>
                                                <option value="4">Secondary sale of Instrument </option>
                                                <option value="5">Other </option>
                                            </select>
                                        </div>
                                        {item.type_of_return == 5 && <div className="col-md-6 mt-3">
                                            <label className="form-label required">
                                                <b>Others</b>
                                            </label>
                                            <br />
                                            <input
                                                min="0"
                                                name="other_return_type"
                                                value={item.other_return_type}
                                                className="form-control"
                                                onChange={(e) => handleChange22(e, item.id)}
                                                required={true}
                                                maxLength={255}
                                                disabled={i != disabled_part2 && true}
                                            />
                                        </div>}
                                        <div className="col-md-6 mt-3">
                                            <label className="form-label required">
                                                <b>Amount Returned</b>
                                            </label>
                                            <br />
                                            <input
                                                min="0"
                                                name="fund_returned"
                                                value={item.fund_returned}
                                                className="form-control"
                                                onChange={(e) => handleChange22(e, item.id)}
                                                required={true}
                                                maxLength={255}
                                                disabled={i != disabled_part2 && true}
                                            />
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <label className="form-label required">
                                                <b>Remarks</b>
                                            </label>
                                            <br />
                                            <textarea
                                                name="remarks"
                                                value={item.remarks}
                                                className="form-control"
                                                onChange={(e) => handleChange22(e, item.id)}
                                                disabled={i != disabled_part2 && true}
                                            />
                                        </div>
                                        {/* {console.log(item.file.split('/')[3])} */}
                                        {/* {console.log(EditselectedFile)} */}
                                        <div className="col-md-6" style={{ marginTop: "20px"}}>
                                            <div className="row" style={{display:'block'}}>
                                                <div className="col-md">
                                                <label className="form-label required">
                                                    <b>Bank statement to verify returns</b>
                                                </label>
                                                </div>
                                                {item.file && <div className="col-md d-flex">
                                                    <img src={pdf} alt="SISFS Logo" />
                                                    <br/>
                                                    <div style={{display:'grid', marginLeft:'15px'}}>
                                                    <a
                                                        href={`${File_node_url}` + item.file}
                                                        target="_blank"
                                                        // download={file.file}
                                                        className="incubator-details-link"
                                                        >
                                                        View
                                                    </a>
                                                    <span>{(item.file.split('/')[3])}</span>
                                                    </div>
                                                </div>}
                                                <div className="col-md mt-3">
                                                    <input
                                                    type="file"
                                                    name="file"
                                                    // onChange={(e) => handleChange22(e, item.id)}
                                                    onChange={handleChange33}
                                                    accept=".pdf"
                                                    disabled={i != disabled_part2 && true}
                                                />
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                {/* </fieldset> */}
                                <div className="row mt-3">
                                    <div className="col-md-6 mt-3">
                                        <b><span onClick={() => { setdisabled_part2(i) }} style={{ color: '#f1970afa', cursor: 'pointer' }}>Edit</span>  /  <span style={{ color: 'red', cursor: 'pointer' }} onClick={() => DeleteClick(item, i)}>Delete</span></b>
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <button type="submit" className="btn btn-sisfs btn-yellow-primary" disabled={i != disabled_part2 && true} style={{ float: 'right' }} >Save</button>
                                    </div>
                                </div>
                            </form>
                            <hr />
                        </div>
                        </fieldset>
                    ))
                    : ''}
            </div>


            {
                userInfo.roles == 'secretariat' ? <></>:
                <>
                    {QuaterlyBTN == true && <div className='row mb-3'>
                    <div className="col-6"></div>
                        <div className="col-6 d-flex justify-content-end">
                        {/* disabled={props.quaterlyReports_returns.length == 0 || (props.quaterlyReports_returns.length && (props.quaterlyReports_returns[0].returns_review == "1" || props.quaterlyReports_returns[0].progress_overview_review == "0"))} */}
                        {(props.quaterlyReports_returns.length && props.quaterlyReports_returns[0].returns_review == "0") || props.quaterlyReports_returns.length == 0 ? <button className="btn btn-sisfs btn-yellow-primary" type="submit" style={{ float: 'right' }} data-target="#YesOrNoTwo" data-toggle="modal">
                            <div className="d-flex flex-row justify-content-between">
                                <span>Confirm Quarterly Review</span>
                            </div>
                        </button> : <button className="btn btn-sisfs btn-yellow-primary" type="submit" style={{ float: 'right' }} disabled={(props.quaterlyReports_returns.length && (props.quaterlyReports_returns[0].returns_review == "1"))}>
                            <div className="d-flex flex-row justify-content-between">
                                <span>Quarterly Review Confirmed</span>
                                {props.quaterlyReports_returns.length && props.quaterlyReports_returns[0].returns_review == "1" && true ? (<img src={SuccessCheck} alt="SISFS Logo" style={{ marginRight: "15px", cursor: 'pointer' }} />) : ""}
                            </div>
                        </button>}
                        </div>
                    </div>}
                </>
            }
            

            {/* Conformation Poppup */}
            <div className="modal fade" id="YesOrNoTwo" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal align-middle modal-sisfs d-block" id="FormSubmissionError">
                            <div className="modal-dialog modal-dialog-centered modal-md">
                                <div className="modal-content">
                                    <div style={{ textAlign: 'right', }}>
                                        <i className="fa fa-close" data-dismiss="modal" style={{ cursor: 'pointer', fontSize: '30px', marginRight: '15px', marginTop: '15px', color: '#D3D3D3' }}></i>
                                    </div>
                                    <div className="modal-body text-center pt-0">
                                        <h4 className="modal-message" style={{ color: '#2b3f88', }}>
                                            Are You Sure You Want To Continue
                                        </h4>
                                        <div className="modal-action">
                                            <button className="btn btn-sisfs btn-yellow-primary" onClick={() => ConformationClick()}>
                                                Yes
                                            </button>
                                            <button className="btn btn-sisfs btn-yellow-primary ml-3" data-dismiss="modal">
                                                No
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showIncubatorSuccessModal && (
                <ConfirmationDialog
                    headerText={dialogData.header}
                    bodyText={dialogData.text}
                    onDialogClose={dismissModal}
                />
            )}
            {showIncubatorFailureModal && (
                <ConfirmationDialog
                    headerText={failDialogData.header}
                    bodyText={failDialogData.text}
                    onDialogClose={() => setShowIncubatorFailureModal(false)}
                    iconClass="test"
                />
            )}

        </div>
    )
}

export default ReturnsFields
