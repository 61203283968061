import WeightageQuestionComponent from "./WeightageQuestionComponent";
import ScoreRangeComponent from "./ScoreRangeComponent";
import WeightageDetailsComponent from "./WeightageDetailsComponent";
import {
  IQuestionModel,
  Question,
  QuestionModel,
} from "../../../models/eac/QuestionModel";
import { useEffect, useState } from "react";

export interface IReviewStepProps {
  data: any;
  tabIndex: number;
  onChildUpdate: (question: IQuestionModel) => void;
  onSave: (question: IQuestionModel) => void;
  onPreviousClick?: any;
  onSubmit?: (questionModel: IQuestionModel) => void;
}

function ReviewStepWrapperComponent(props: IReviewStepProps) {
  const [questionModel, setQuestionModel] = useState<any>(null);

  const onUpdateChild = (question: Question, index: number) => {
    let updatedQuestions = props.data;
    updatedQuestions.question[index] = question;

    setQuestionModel(JSON.parse(JSON.stringify(updatedQuestions)));

    setTimeout(() => props.onChildUpdate(updatedQuestions), 0);
  };

  return (
    <>
      <div className="form-content">
        {/* {console.log(props.data, "data")} */}
        {props.data && props.data.question && (
          <WeightageQuestionComponent {...props} />
        )}

        {props.data &&
          props.data.question &&
          props.data.question.map((question: Question, index: number) => (
            <>
              <div
                key={question?.esb_id}
                className="row score-range-tab no-gutters"
              >
                {/* <ScoreRangeComponent {...question} /> */}
                <WeightageDetailsComponent
                  question={question}
                  index={index}
                  onUpdate={(question: Question) =>
                    onUpdateChild(question, index)
                  }
                />
              </div>
            </>
          ))}
      </div>

      <div className="stepper-footer-section">
        <div
          className="btn-toolbar justify-content-between"
          role="toolbar"
          aria-label="Toolbar with button groups"
        >
          <button className="btn btn-sisfs btn-transparent-primary">
            Cancel
          </button>
          <span className="stepper-page-count">Step {props.tabIndex} / 9 </span>
          <div className="btn-group">
            {props.tabIndex != 1 && (
              <button
                className="btn btn-sisfs btn-transparent-primary"
                onClick={() => props.onPreviousClick()}
              >
                Previous
              </button>
            )}
            <button
              type="button"
              className="btn btn-sisfs btn-yellow-primary"
              onClick={() => props.onSave(questionModel)}
            >
              {props.tabIndex == 9 ? "Save" : "Save And Next"}
            </button>
            {props.tabIndex == 9 && (
              <button
                className="btn btn-sisfs btn-yellow-primary"
                onClick={() => props.onSubmit && props.onSubmit(questionModel)}
                data-toggle="modal"
                data-dismiss="modal"
                data-target="#ReviewScoreModal"
              >
                Review & Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ReviewStepWrapperComponent;
