import '../../incubator/incubator.css';
import Plus from '../../../../images/Plus.svg';
import Minus from '../../../../images/Minus.svg';
import {useEffect, useState} from 'react';
import pdf from '../../../../images/PDF.svg';
import xls from '../../../../images/xls.svg';
import doc from '../../../../images/doc.svg';
import xlsx from '../../../../images/xls.svg';
import Allround from '../../../../images/Allround.svg';
import {File_base_url, File_node_url, NODE_URL} from '../../../../constants/api';

interface IDocumentProps {
  DocumentsData: any;
}

function MergedDocumentsDetailsComponent(props: IDocumentProps) {
  const {DocumentsData} = props;

  useEffect(() => {
    window.scroll(0, 0);
    // //console.log(DocumentsData);
  }, []);

  const downloadDocument = (file: any, file_name: any) => {
    fetch(`${File_node_url}` + file).then(response => {
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = file_name;
        a.click();
      });
      //window.location.href = response.url;
    });
  };

  const downloadDocumentNode = (file: any, file_name: any) => {
    //console.log(file);
    //console.log(file_name);
    //console.log(file.substr(1));
    let finalfile_path = file.substr(1);
    let fetchpath = `${File_node_url}` + finalfile_path;
    //console.log(fetchpath);
    fetch(fetchpath, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      // body:data
    }).then((response: any) => {
      //console.log('Response check', response);
      response.blob().then((blob: any) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'SamplePDF.pdf';
        alink.click();
      });
    });

    return;
    fetch(file).then(response => {
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = file_name;
        a.click();
      });
    });
  };

  const getFileDetails = (file: any, key: string) => {
    let file_name = '';
    file_name =
      file != undefined && file != null && file.length > 1
        ? file.substr(file.lastIndexOf('/') + 1, file.length)
        : '';

    const file_type =
      file != undefined && file != null && file.length > 1
        ? file.substr(file.lastIndexOf('.') + 1, file.length)
        : '';

    if (key == 'name') return file_name;
    if (key == 'file') return file != undefined ? file.toString() : '';
    if (key == 'type') return file != undefined ? file_type.toString() : '';
  };

  const [popUpName, setPopUpName] = useState('');
  const setMeetingModal = (fieldName: string) => {
    setPopUpName(fieldName);
  };

  const [activeIndexDoc, setActiveIndexDoc] = useState('');
  const changeActiveDoc = (id: string) => {
    setActiveIndexDoc(id);
  };
  const changeActiveBackDoc = () => {
    setActiveIndexDoc('');
  };

  return (
    <div className="container">
      {!DocumentsData.Second_Application_Documents ? null : DocumentsData
          .Second_Application_Documents.Audited_statement_expenditure_Upload
          .length == ' ' &&
        DocumentsData.Second_Application_Documents.Current_Incubator_Team_Upload
          .length == ' ' &&
        DocumentsData.Second_Application_Documents
          .List_significant_facilities_upload.length == ' ' &&
        DocumentsData.Second_Application_Documents.No_full_time_employees_upload
          .length == ' ' &&
        DocumentsData.Second_Application_Documents.Other_doc_upload_fpath
          .length == ' ' &&
        DocumentsData.Second_Application_Documents.Performance_Upload_fpath
          .length == ' ' &&
        DocumentsData.Second_Application_Documents
          .Utilization_Certificate_Upload.length == ' ' &&
        DocumentsData.Second_Application_Documents.pitchdeck_upload_fpath
          .length == ' ' ? (
        <></>
      ) : (
        <>
          <div className="row">
            <div
              className="col-md-12 left-align"
              style={{backgroundColor: '#f8f7f7', padding: '5px'}}>
              <span className="eac-heading-title">
                2<sup>nd</sup> Application Documents
              </span>
            </div>
          </div>

          <div className="incubator-hr">
            <hr />
          </div>

          <div className="row">
            <div
              id="q-2"
              className={
                activeIndexDoc == '6'
                  ? 'tranche-heading col-sm-11 left-align'
                  : 'reports-heading col-sm-11 left-align'
              }>
              General Incubator Progress
            </div>
            <div className="col-sm-1">
              {activeIndexDoc == '6' ? (
                <div
                  onClick={changeActiveBackDoc}
                  data-toggle="collapse"
                  data-target="#pick-deck-expand"
                  role="button"
                  aria-expanded="false"
                  aria-controls="pick-deck-expand"
                  className="hide-icon pull-right hide-icon-details"
                  id="show-2">
                  <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                </div>
              ) : (
                <div
                  id="hide-2"
                  onClick={() => changeActiveDoc('6')}
                  data-toggle="collapse"
                  data-target="#pick-deck-expand"
                  role="button"
                  aria-expanded="false"
                  aria-controls="pick-deck-expand"
                  className="expand-icon pull-right expand-icon-details">
                  <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                </div>
              )}
            </div>
          </div>
          {activeIndexDoc == '6' ? (
            <div className="collapse " id="pick-deck-expand">
              <div className="row">
                <div className="col-md-8 left-align">
                  <h6>
                    <b>Upload Pitch deck</b>
                  </h6>
                  {DocumentsData.Second_Application_Documents
                    .pitchdeck_upload_fpath.length != 0 ? (
                    ''
                  ) : (
                    <label className="incubator-label-value">-</label>
                  )}

                  <br />

                  { DocumentsData.Second_Application_Documents.pitchdeck_upload_fpath[0] != null &&
                  DocumentsData.Second_Application_Documents.pitchdeck_upload_fpath[0].split(",").map(
                    (file: any, index: any) =>
                      index < 2 ? (
                        <div className="row">
                          {file !== null?
                          <div className="col-md-3">
                            {getFileDetails(file, 'type') == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(file, 'type') == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(file, 'type') == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>:null}
                          <div className="col-md-9">
                            <span className="document-label-value-wrap">
                              {getFileDetails(file, 'name')}
                            </span>
                            <br />
                            {file !== null ?
                            <a
                              href={`${NODE_URL}` + file.substr(1)}
                              target="_blank"
                              //download="PitchDeck"
                              className="incubator-details-link">
                              View
                            </a>:"-"}
                            {/* /
                      <a
                        //  href={`${NODE_URL}` + file.substr(1)}
                        //  download 
                        //  target='_blank'
                        //download={file.file}
                        className="incubator-details-link"
                        onClick={() => downloadDocumentNode(file, file)}
                      >
                        Download
                      </a> */}
                          </div>
                        </div>
                      ) : (
                        ''
                      ),
                  )}
                  { DocumentsData.Second_Application_Documents.pitchdeck_upload_fpath[0] != null &&
                  DocumentsData.Second_Application_Documents
                    .pitchdeck_upload_fpath[0].split(",").length > 2 ? (
                    <a
                      className="incubator-details-link"
                      data-toggle="modal"
                      data-target="#showMoreDocument"
                      onClick={() => setMeetingModal('pitchdeck_upload_fpath')}>
                      More...
                    </a>
                  ) : (
                    ''
                  )}
                  <br />
                  <br />
                  <h6>
                    <b>
                      Please upload a performance audit report by an independent
                      third party
                    </b>
                  </h6>
                  { DocumentsData.Second_Application_Documents.Performance_Upload_fpath[0] != null &&
                  DocumentsData.Second_Application_Documents.Performance_Upload_fpath[0].split(",").map(
                    (file: any, index: any) =>
                      index < 2 ? (
                        <div className="row">
                          {file !== null?
                          <div className="col-md-3">
                            {getFileDetails(file, 'type') == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(file, 'type') == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(file, 'type') == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>:null}
                          <div className="col-md-9">
                            <span className="document-label-value-wrap">
                              {getFileDetails(file, 'name')}
                            </span>
                            <br />
                            {file !== null ?
                            <a
                              href={`${NODE_URL}` + file.substr(1)}
                              target="_blank"
                              //download="PitchDeck"
                              className="incubator-details-link">
                              View
                            </a>:"-"}
                            {/* /
                      <a
                        href="#"
                        //download={file.file}
                        className="incubator-details-link"
                        onClick={() => downloadDocumentNode(file, file)}
                      >
                        Download
                      </a> */}
                          </div>
                        </div>
                      ) : (
                        ''
                      ),
                  )}
                  { DocumentsData.Second_Application_Documents.Performance_Upload_fpath[0] != null &&
                  DocumentsData.Second_Application_Documents
                    .Performance_Upload_fpath[0].split(",").length > 2 ? (
                    <a
                      className="incubator-details-link"
                      data-toggle="modal"
                      data-target="#showMoreDocument"
                      onClick={() =>
                        setMeetingModal('Performance_Upload_fpath')
                      }>
                      More...
                    </a>
                  ) : (
                    ''
                  )}

                  <br />
                  <br />
                  <h6>
                    <b>
                      Please upload a performance audit report by an independent
                      third party
                    </b>
                  </h6>
                  { DocumentsData.Second_Application_Documents.Other_doc_upload_fpath[0] != null &&
                  DocumentsData.Second_Application_Documents.Other_doc_upload_fpath[0].split(",").map(
                    (file: any, index: any) =>
                      index < 2 ? (
                        <div className="row">
                          {file !== null?
                          <div className="col-md-3">
                            {getFileDetails(file, 'type') == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(file, 'type') == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(file, 'type') == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>:null}
                          <div className="col-md-9">
                            <span className="document-label-value-wrap">
                              {getFileDetails(file, 'name')}
                            </span>
                            <br />
                            
                            {file !== null ?
                            <a
                              href={`${NODE_URL}` + file.substr(1)}
                              target="_blank"
                              //download="PitchDeck"
                              className="incubator-details-link">
                              View
                            </a>:"-"}
                            {/* /
                      <a
                        href="#"
                        //download={file.file}
                        className="incubator-details-link"
                        onClick={() => downloadDocumentNode(file, file)}
                      >
                        Download
                      </a> */}
                          </div>
                        </div>
                      ) : (
                        ''
                      ),
                  )}
                  { DocumentsData.Second_Application_Documents.Other_doc_upload_fpath[0] != null &&
                  DocumentsData.Second_Application_Documents
                    .Other_doc_upload_fpath[0].split(",").length > 2 ? (
                    <a
                      className="incubator-details-link"
                      data-toggle="modal"
                      data-target="#showMoreDocument"
                      onClick={() => setMeetingModal('Other_doc_upload_fpath')}>
                      More...
                    </a>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          ) : (
            ''
          )}

          <div className="incubator-hr">
            <hr />
          </div>

          <div className="row">
            <div
              id="q-4"
              className={
                activeIndexDoc == '7'
                  ? 'tranche-heading col-sm-11 left-align'
                  : 'reports-heading col-sm-11 left-align'
              }>
              Incubator Scheme Progress - Part 1
            </div>
            <div className="col-sm-1">
              {activeIndexDoc == '7' ? (
                <div
                  onClick={changeActiveBackDoc}
                  data-toggle="collapse"
                  data-target="#annual-report-expand"
                  role="button"
                  aria-expanded="false"
                  aria-controls="annual-report-expand"
                  className="hide-icon pull-right hide-icon-details"
                  id="show-4">
                  <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                </div>
              ) : (
                <div
                  onClick={() => changeActiveDoc('7')}
                  id="hide-4"
                  data-toggle="collapse"
                  data-target="#annual-report-expand"
                  role="button"
                  aria-expanded="false"
                  aria-controls="annual-report-expand"
                  className="expand-icon pull-right expand-icon-details">
                  <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                </div>
              )}
            </div>
          </div>
          {activeIndexDoc == '7' ? (
            <div className="collapse" id="annual-report-expand">
              <div className="row">
                <div className="col-md-8 left-align">
                  <h6>
                    <b>Utilization Certificate (UC) of previous installments</b>
                  </h6>
                  {DocumentsData.Second_Application_Documents
                    .Utilization_Certificate_Upload.length != 0 ? (
                    ''
                  ) : (
                    <label className="document-label-value-wrap">-</label>
                  )}
                  <br />
                  {DocumentsData.Second_Application_Documents.Utilization_Certificate_Upload[0] != null &&
                  DocumentsData.Second_Application_Documents.Utilization_Certificate_Upload[0].split(",").map(
                    (file: any, index: any) =>
                      index < 2 ? (
                        <div className="row">
                          {file !== null ?
                          <div className="col-md-3">
                            {console.log(file,"filefile")}
                            {getFileDetails(file, 'type') == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(file, 'type') == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(file, 'type') == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>:null}
                          <div className="col-md-9">
                            <span className="document-label-value-wrap">
                              {getFileDetails(file, 'name')}
                            </span>
                            <br />
                            {file !== null ?
                            <a
                              href={`${NODE_URL}` + file.substr(1)}
                              target="_blank"
                              className="incubator-details-link">
                              View
                            </a>:"-"}
                            {/* /
                      <a
                        href="#"
                        className="incubator-details-link"
                        onClick={() => downloadDocumentNode(file, file)}
                      >
                        Download
                      </a> */}
                          </div>
                        </div>
                      ) : (
                        ''
                      ),
                  )}

                  {DocumentsData.Second_Application_Documents.Utilization_Certificate_Upload[0] != null &&
                  DocumentsData.Second_Application_Documents
                    .Utilization_Certificate_Upload[0].split(",").length > 2 ? (
                    <a
                      className="incubator-details-link"
                      data-toggle="modal"
                      data-target="#showMoreDocument"
                      onClick={() =>
                        setMeetingModal('Utilization_Certificate_Upload')
                      }>
                      More...
                    </a>
                  ) : (
                    ''
                  )}

                  <br />
                  <br />
                  <h6>
                    <b>Audited statement of expenditure</b>
                  </h6>
                  {DocumentsData.Second_Application_Documents.Utilization_Certificate_Upload[0] != null &&
                  DocumentsData.Second_Application_Documents.Audited_statement_expenditure_Upload[0].split(",").map(
                    (file: any, index: any) =>
                      index < 2 ? (
                        <div className="row">
                          {file !== null ?
                          <div className="col-md-3">
                            {getFileDetails(file, 'type') == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(file, 'type') == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(file, 'type') == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>:null}
                          <div className="col-md-9">
                            <span className="document-label-value-wrap">
                              {getFileDetails(file, 'name')}
                            </span>
                            <br />
                            {file !== null?
                            <a
                              href={`${NODE_URL}` + file.substr(1)}
                              target="_blank"
                              className="incubator-details-link">
                              View
                            </a>:"-"}
                            {/* /
                      <a
                        href="#"
                        className="incubator-details-link"
                        onClick={() => downloadDocumentNode(file, file)}
                      >
                        Download
                      </a> */}
                          </div>
                        </div>
                      ) : (
                        ''
                      ),
                  )}

                  <br />

                  {DocumentsData.Second_Application_Documents.Utilization_Certificate_Upload[0] != null &&
                  DocumentsData.Second_Application_Documents
                    .Audited_statement_expenditure_Upload[0].split(",").length > 2 ? (
                    <a
                      className="incubator-details-link"
                      data-toggle="modal"
                      data-target="#showMoreDocument"
                      onClick={() =>
                        setMeetingModal('Audited_statement_expenditure_Upload')
                      }>
                      More...
                    </a>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          ) : (
            ''
          )}

          <hr />
        </>
      )}
      {!DocumentsData.First_Application_Documents ? null : DocumentsData.First_Application_Documents ? (
        <>
          {/* 1st Application Documents */}
          <div className="row">
            <div
              className="col-md-12 left-align"
              style={{backgroundColor: '#f8f7f7', padding: '5px'}}>
              <span className="eac-heading-title">
                1<sup>st</sup> Application Documents
              </span>
            </div>
          </div>

          <div className="incubator-hr">
            <hr />
          </div>

          <div className="row">
            {/* {console.log(DocumentsData.First_Application_Documents)} */}
            <div
              id="q-1"
              className={
                activeIndexDoc == '1'
                  ? 'tranche-heading col-sm-11 left-align'
                  : 'reports-heading col-sm-11 left-align'
              }>
              General Details
            </div>
            <div className="col-sm-1">
              {activeIndexDoc == '1' ? (
                <div
                  onClick={changeActiveBackDoc}
                  data-toggle="collapse"
                  data-target="#qtrly-expand"
                  role="button"
                  aria-expanded="false"
                  aria-controls="qtrly-expand"
                  className="hide-icon pull-right hide-icon-details"
                  id="show-1">
                  <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                </div>
              ) : (
                <div
                  id="hide-1"
                  onClick={() => changeActiveDoc('1')}
                  data-toggle="collapse"
                  data-target="#qtrly-expand"
                  role="button"
                  aria-expanded="false"
                  aria-controls="qtrly-expand"
                  className="expand-icon pull-right expand-icon-details">
                  <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                </div>
              )}
            </div>
          </div>
          {activeIndexDoc == '1' ? (
            <div className="collapse " id="qtrly-expand">
              <div className="row">
                <div className="col-md-8 left-align">
                  <h6>
                    <b>Proof of assistance from Central/State Government</b>
                  </h6>

                  {DocumentsData.First_Application_Documents.proof_assistance
                    .length != 0 ? (
                    ''
                  ) : (
                    <label className="incubator-label-value">-</label>
                  )}

                  <br />
                  {DocumentsData.First_Application_Documents.proof_assistance.map(
                    (file: any, index: any) =>
                      index < 2 ? (
                        <div className="row">
                          <div className="col-md-3">
                            {getFileDetails(file.file, 'type') == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-9">
                            <span className="document-label-value-wrap">
                              {getFileDetails(file.file, 'name')}
                            </span>
                            <br />
                            <a
                              // href={file.file}
                              href={`${File_node_url}` + file.file}
                              target="_blank"
                              //download="PitchDeck"
                              className="incubator-details-link">
                              View
                            </a>
                            {/* /
        <a
          href="#"
          //download={file.file}
          className="incubator-details-link"
          onClick={() => downloadDocument(file.file, file.file)}
        >
          Download
        </a> */}
                          </div>
                        </div>
                      ) : (
                        ''
                      ),
                  )}
                  {DocumentsData.First_Application_Documents.proof_assistance
                    .length > 2 ? (
                    <a
                      className="incubator-details-link"
                      data-toggle="modal"
                      data-target="#showMoreDocument"
                      onClick={() => setMeetingModal('Proof of assistance from Central/State Government')}>
                      More...
                    </a>
                  ) : (
                    ''
                  )}
                  <br />
                  <br />
                </div>
  {/* </br> */}
                <div className="col-md-8 left-align">
                  <h6>
                    <b>Incorporation Certificate</b>
                  </h6>

                  {DocumentsData.First_Application_Documents
                    .incorporation_certificate.length != 0 ? (
                    ''
                  ) : (
                    <label className="incubator-label-value">-</label>
                  )}

                  <br />
                  {DocumentsData.First_Application_Documents.incorporation_certificate.map(
                    (file: any, index: any) =>
                      index < 2 ? (
                        <div className="row">
                          <div className="col-md-3">
                            {getFileDetails(file.file, 'type') == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-9">
                            <span className="document-label-value-wrap">
                              {getFileDetails(file.file, 'name')}
                            </span>
                            <br />
                            <a
                              // href={file.file}
                              href={`${File_node_url}` + file.file}
                              target="_blank"
                              //download="PitchDeck"
                              className="incubator-details-link">
                              View
                            </a>
                            {/* /
        <a
          href="#"
          //download={file.file}
          className="incubator-details-link"
          onClick={() => downloadDocument(file.file, file.file)}
        >
          Download
        </a> */}
                          </div>
                        </div>
                      ) : (
                        ''
                      ),
                  )}
                  {DocumentsData.First_Application_Documents
                    .incorporation_certificate.length > 2 ? (
                    <a
                      className="incubator-details-link"
                      data-toggle="modal"
                      data-target="#showMoreDocument"
                      onClick={() => setMeetingModal('Incorporation Certificate')}>
                      More...
                    </a>
                  ) : (
                    ''
                  )}
                  <br />
                  <br />
                </div>

                <div className="col-md-8 left-align">
                  <h6>
                    <b>PAN Card of legal entity</b>
                  </h6>

                  {DocumentsData.First_Application_Documents.pan_card_upload
                    .length != 0 ? (
                    ''
                  ) : (
                    <label className="incubator-label-value">-</label>
                  )}

                  <br />
                  {DocumentsData.First_Application_Documents.pan_card_upload.map(
                    (file: any, index: any) =>
                      index < 2 ? (
                        <div className="row">
                          <div className="col-md-3">
                            {getFileDetails(file.file, 'type') == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-9">
                            <span className="document-label-value-wrap">
                              {getFileDetails(file.file, 'name')}
                            </span>
                            <br />
                            <a
                              // href={file.file}
                              href={`${File_node_url}` + file.file}
                              target="_blank"
                              //download="PitchDeck"
                              className="incubator-details-link">
                              View
                            </a>
                            {/* /
        <a
          href="#"
          //download={file.file}
          className="incubator-details-link"
          onClick={() => downloadDocument(file.file, file.file)}
        >
          Download
        </a> */}
                          </div>
                        </div>
                      ) : (
                        ''
                      ),
                  )}
                  {DocumentsData.First_Application_Documents.pan_card_upload
                    .length > 2 ? (
                    <a
                      className="incubator-details-link"
                      data-toggle="modal"
                      data-target="#showMoreDocument"
                      onClick={() => setMeetingModal('PAN Card of legal entity')}>
                      More...
                    </a>
                  ) : (
                    ''
                  )}
                  <br />
                  <br />
                </div>

                <div className="col-md-8 left-align">
                  <h6>
                    <b>Board Resolution/ Authorisation letter/ PoA</b>
                  </h6>

                  {DocumentsData.First_Application_Documents
                    .authorisation_letter_upload.length != 0 ? (
                    ''
                  ) : (
                    <label className="incubator-label-value">-</label>
                  )}

                  <br />
                  {DocumentsData.First_Application_Documents.authorisation_letter_upload.map(
                    (file: any, index: any) =>
                      index < 2 ? (
                        <div className="row">
                          <div className="col-md-3">
                            {getFileDetails(file.file, 'type') == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-9">
                            <span className="document-label-value-wrap">
                              {getFileDetails(file.file, 'name')}
                            </span>
                            <br />
                            <a
                              // href={file.file}
                              href={`${File_node_url}` + file.file}
                              target="_blank"
                              //download="PitchDeck"
                              className="incubator-details-link">
                              View
                            </a>
                            {/* /
        <a
          href="#"
          //download={file.file}
          className="incubator-details-link"
          onClick={() => downloadDocument(file.file, file.file)}
        >
          Download
        </a> */}
                          </div>
                        </div>
                      ) : (
                        ''
                      ),
                  )}
                  {DocumentsData.First_Application_Documents
                    .authorisation_letter_upload.length > 2 ? (
                    <a
                      className="incubator-details-link"
                      data-toggle="modal"
                      data-target="#showMoreDocument"
                      onClick={() => setMeetingModal('Board Resolution/ Authorisation letter/ PoA')}>
                      More...
                    </a>
                  ) : (
                    ''
                  )}
                  <br />
                  <br />
                </div>

                <div className="col-md-8 left-align">
                  <h6>
                    <b>Aadhaar Card of authorised signatory</b>
                  </h6>

                  {DocumentsData.First_Application_Documents.adhaar_card_upload
                    .length != 0 ? (
                    ''
                  ) : (
                    <label className="incubator-label-value">-</label>
                  )}

                  <br />
                  {DocumentsData.First_Application_Documents.adhaar_card_upload.map(
                    (file: any, index: any) =>
                      index < 2 ? (
                        <div className="row">
                          <div className="col-md-3">
                            {getFileDetails(file.file, 'type') == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-9">
                            <span className="document-label-value-wrap">
                              {getFileDetails(file.file, 'name')}
                            </span>
                            <br />
                            <a
                              // href={file.file}
                              href={`${File_node_url}` + file.file}
                              target="_blank"
                              //download="PitchDeck"
                              className="incubator-details-link">
                              View
                            </a>
                            {/* /
        <a
          href="#"
          //download={file.file}
          className="incubator-details-link"
          onClick={() => downloadDocument(file.file, file.file)}
        >
          Download
        </a> */}
                          </div>
                        </div>
                      ) : (
                        ''
                      ),
                  )}
                  {DocumentsData.First_Application_Documents.adhaar_card_upload
                    .length > 2 ? (
                    <a
                      className="incubator-details-link"
                      data-toggle="modal"
                      data-target="#showMoreDocument"
                      onClick={() => setMeetingModal('Aadhaar Card of authorised signatory')}>
                      More...
                    </a>
                  ) : (
                    ''
                  )}
                  <br />
                  <br />
                </div>

                <div className="col-md-8 left-align">
                  <h6>
                    <b>Audited Annual Report for last 2 years</b>
                  </h6>

                  {DocumentsData.First_Application_Documents.annual_report
                    .length != 0 ? (
                    ''
                  ) : (
                    <label className="incubator-label-value">-</label>
                  )}

                  <br />
                  {DocumentsData.First_Application_Documents.annual_report.map(
                    (file: any, index: any) =>
                      index < 2 ? (
                        <div className="row">
                          <div className="col-md-3">
                            {getFileDetails(file.file, 'type') == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-9">
                            <span className="document-label-value-wrap">
                              {getFileDetails(file.file, 'name')}
                            </span>
                            <br />
                            <a
                              // href={file.file}
                              href={`${File_node_url}` + file.file}
                              target="_blank"
                              //download="PitchDeck"
                              className="incubator-details-link">
                              View
                            </a>
                            {/* /
        <a
          href="#"
          //download={file.file}
          className="incubator-details-link"
          onClick={() => downloadDocument(file.file, file.file)}
        >
          Download
        </a> */}
                          </div>
                        </div>
                      ) : (
                        ''
                      ),
                  )}
                  {DocumentsData.First_Application_Documents.annual_report
                    .length > 2 ? (
                    <a
                      className="incubator-details-link"
                      data-toggle="modal"
                      data-target="#showMoreDocument"
                      onClick={() => setMeetingModal('Audited Annual Report for last 2 years')}>
                      More...
                    </a>
                  ) : (
                    ''
                  )}
                  <br />
                  <br />
                </div>

                <div className="col-md-8 left-align">
                  <h6>
                    <b>Agreement between incubator and startup</b>
                  </h6>

                  {DocumentsData.First_Application_Documents.startup_incubation_upload
                    .length != 0 ? (
                    ''
                  ) : (
                    <label className="incubator-label-value">-</label>
                  )}

                  <br />
                  {DocumentsData.First_Application_Documents.startup_incubation_upload.map(
                    (file: any, index: any) =>
                      index < 2 ? (
                        <div className="row">
                          <div className="col-md-3">
                            {getFileDetails(file.file, 'type') == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-9">
                            <span className="document-label-value-wrap">
                              {getFileDetails(file.file, 'name')}
                            </span>
                            <br />
                            <a
                              // href={file.file}
                              href={`${File_node_url}` + file.file}
                              target="_blank"
                              //download="PitchDeck"
                              className="incubator-details-link">
                              View
                            </a>
                            {/* /
        <a
          href="#"
          //download={file.file}
          className="incubator-details-link"
          onClick={() => downloadDocument(file.file, file.file)}
        >
          Download
        </a> */}
                          </div>
                        </div>
                      ) : (
                        ''
                      ),
                  )}
                  {DocumentsData.First_Application_Documents.startup_incubation_upload
                    .length > 2 ? (
                    <a
                      className="incubator-details-link"
                      data-toggle="modal"
                      data-target="#showMoreDocument"
                      onClick={() => setMeetingModal('Agreement between incubator and startup')}>
                      More...
                    </a>
                  ) : (
                    ''
                  )}
                  
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          <div className="incubator-hr">
            <hr />
          </div>

          <div className="row">
            <div
              id="q-2"
              className={
                activeIndexDoc == '2'
                  ? 'tranche-heading col-sm-11 left-align'
                  : 'reports-heading col-sm-11 left-align'
              }>
              Incubator Details
            </div>
            <div className="col-sm-1">
              {activeIndexDoc == '2' ? (
                <div
                  onClick={changeActiveBackDoc}
                  data-toggle="collapse"
                  data-target="#pick-deck-expand"
                  role="button"
                  aria-expanded="false"
                  aria-controls="pick-deck-expand"
                  className="hide-icon pull-right hide-icon-details"
                  id="show-2">
                  <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                </div>
              ) : (
                <div
                  id="hide-2"
                  onClick={() => changeActiveDoc('2')}
                  data-toggle="collapse"
                  data-target="#pick-deck-expand"
                  role="button"
                  aria-expanded="false"
                  aria-controls="pick-deck-expand"
                  className="expand-icon pull-right expand-icon-details">
                  <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                </div>
              )}
            </div>
          </div>
          {activeIndexDoc == '2' ? (
            <div className="collapse " id="pick-deck-expand">
              <div className="row">
                <div className="col-md-8 left-align">
                  <h6>
                    <b>Facilities Pictures</b>
                  </h6>

                  {DocumentsData.First_Application_Documents.facilities_pictures
                    .length != 0 ? (
                    ''
                  ) : (
                    <label className="incubator-label-value">-</label>
                  )}

                  <br />
                  {DocumentsData.First_Application_Documents.facilities_pictures.map(
                    (file: any, index: any) =>
                      index < 2 ? (
                        <div className="row">
                          <div className="col-md-3">
                            {getFileDetails(file.file, 'type') == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-9">
                            <span className="document-label-value-wrap">
                              {getFileDetails(file.file, 'name')}
                            </span>
                            <br />
                            <a
                              // href={file.file}
                              href={`${File_node_url}` + file.file}
                              target="_blank"
                              //download="PitchDeck"
                              className="incubator-details-link">
                              View
                            </a>
                            {/* /
                      <a
                        href="#"
                        //download={file.file}
                        className="incubator-details-link"
                        onClick={() => downloadDocument(file.file, file.file)}
                      >
                        Download
                      </a> */}
                          </div>
                        </div>
                      ) : (
                        ''
                      ),
                  )}
                  {DocumentsData.First_Application_Documents.facilities_pictures
                    .length > 2 ? (
                    <a
                      className="incubator-details-link"
                      data-toggle="modal"
                      data-target="#showMoreDocument"
                      onClick={() => setMeetingModal('Facilities Pictures')}>
                      More...
                    </a>
                  ) : (
                    ''
                  )}
                 
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          <div className="incubator-hr">
            <hr />
          </div>

          <div className="row">
            <div
              id="q-3"
              className={
                activeIndexDoc == '3'
                  ? 'tranche-heading col-sm-11 left-align'
                  : 'reports-heading col-sm-11 left-align'
              }>
              Other
            </div>
            <div className="col-sm-1">
              {activeIndexDoc == '3' ? (
                <div
                  onClick={changeActiveBackDoc}
                  data-toggle="collapse"
                  data-target="#startup-details-expand"
                  role="button"
                  aria-expanded="false"
                  aria-controls="startup-details-expand"
                  className="hide-icon pull-right hide-icon-details"
                  id="show-3">
                  <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                </div>
              ) : (
                <div
                  onClick={() => changeActiveDoc('3')}
                  id="hide-3"
                  data-toggle="collapse"
                  data-target="#startup-details-expand"
                  role="button"
                  aria-expanded="false"
                  aria-controls="pstartup-details-expand"
                  className="expand-icon pull-right expand-icon-details">
                  <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                </div>
              )}
            </div>
          </div>
          {activeIndexDoc == '3' ? (
            <div className="collapse " id="startup-details-expand">
               <div className="row">
                <div className="col-md-8 left-align">
                  <h6>
                  <b>Relevant Document (Excel)</b>
                  </h6>

                  {DocumentsData.First_Application_Documents.relavent_document_excel
                    .length != 0 ? (
                    ''
                  ) : (
                    <label className="incubator-label-value">-</label>
                  )}

                  <br />
                  {DocumentsData.First_Application_Documents.relavent_document_excel.map(
                    (file: any, index: any) =>
                      index < 2 ? (
                        <div className="row">
                          <div className="col-md-3">
                            {getFileDetails(file.file, 'type') == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-9">
                            <span className="document-label-value-wrap">
                              {getFileDetails(file.file, 'name')}
                            </span>
                            <br />
                            <a
                              // href={file.file}
                              href={`${File_node_url}` + file.file}
                              target="_blank"
                              //download="PitchDeck"
                              className="incubator-details-link">
                              View
                            </a>
                            {/* /
                      <a
                        href="#"
                        //download={file.file}
                        className="incubator-details-link"
                        onClick={() => downloadDocument(file.file, file.file)}
                      >
                        Download
                      </a> */}
                          </div>
                        </div>
                      ) : (
                        ''
                      ),
                  )}
                  {DocumentsData.First_Application_Documents.relavent_document_excel
                    .length > 2 ? (
                    <a
                      className="incubator-details-link"
                      data-toggle="modal"
                      data-target="#showMoreDocument"
                      onClick={() => setMeetingModal('Relevant Document Excel')}>
                      More...
                    </a>
                  ) : (
                    ''
                  )}
                  <br />
                  <br />
                </div>
               
                <div className="col-md-8 left-align">
                  <h6>
                    <b>Team Member CV</b>
                  </h6>

                  {DocumentsData.First_Application_Documents.team_members_cv
                    .length != 0 ? (
                    ''
                  ) : (
                    <label className="incubator-label-value">-</label>
                  )}

                  <br />
                  {DocumentsData.First_Application_Documents.team_members_cv.map(
                    (file: any, index: any) =>
                      index < 2 ? (
                        <div className="row">
                          <div className="col-md-3">
                            {getFileDetails(file.file, 'type') == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-9">
                            <span className="document-label-value-wrap">
                              {getFileDetails(file.file, 'name')}
                            </span>
                            <br />
                            <a
                              // href={file.file}
                              href={`${File_node_url}` + file.file}
                              target="_blank"
                              //download="PitchDeck"
                              className="incubator-details-link">
                              View
                            </a>
                            {/* /
                      <a
                        href="#"
                        //download={file.file}
                        className="incubator-details-link"
                        onClick={() => downloadDocument(file.file, file.file)}
                      >
                        Download
                      </a> */}
                          </div>
                        </div>
                      ) : (
                        ''
                      ),
                  )}
                  {DocumentsData.First_Application_Documents.team_members_cv
                    .length > 2 ? (
                    <a
                      className="incubator-details-link"
                      data-toggle="modal"
                      data-target="#showMoreDocument"
                      onClick={() => setMeetingModal('Team Member CV')}>
                      More...
                    </a>
                  ) : (
                    ''
                  )}
                  <br />
                  <br />
                </div>
             
              
                <div className="col-md-8 left-align">
                  <h6>
                    <b>Pitch Deck</b>
                  </h6>

                  {DocumentsData.First_Application_Documents.relavent_document_pitchdeck
                    .length != 0 ? (
                    ''
                  ) : (
                    <label className="incubator-label-value">-</label>
                  )}

                  <br />
                  {DocumentsData.First_Application_Documents.relavent_document_pitchdeck.map(
                    (file: any, index: any) =>
                      index < 2 ? (
                        <div className="row">
                          <div className="col-md-3">
                            {getFileDetails(file.file, 'type') == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-9">
                            <span className="document-label-value-wrap">
                              {getFileDetails(file.file, 'name')}
                            </span>
                            <br />
                            <a
                              // href={file.file}
                              href={`${File_node_url}` + file.file}
                              target="_blank"
                              //download="PitchDeck"
                              className="incubator-details-link">
                              View
                            </a>
                            {/* /
                      <a
                        href="#"
                        //download={file.file}
                        className="incubator-details-link"
                        onClick={() => downloadDocument(file.file, file.file)}
                      >
                        Download
                      </a> */}
                          </div>
                        </div>
                      ) : (
                        ''
                      ),
                  )}
                  {DocumentsData.First_Application_Documents.relavent_document_pitchdeck
                    .length > 2 ? (
                    <a
                      className="incubator-details-link"
                      data-toggle="modal"
                      data-target="#showMoreDocument"
                      onClick={() => setMeetingModal('Pitch Deck')}>
                      More...
                    </a>
                  ) : (
                    ''
                  )}
                  <br />
                  <br />
                </div>
              
             
                <div className="col-md-8 left-align">
                  <h6>
                    <b>Self Declaration</b>
                  </h6>

                  {DocumentsData.First_Application_Documents.self_declaration_upload
                    .length != 0 ? (
                    ''
                  ) : (
                    <label className="incubator-label-value">-</label>
                  )}

                  <br />
                  {DocumentsData.First_Application_Documents.self_declaration_upload.map(
                    (file: any, index: any) =>
                      index < 2 ? (
                        <div className="row">
                          <div className="col-md-3">
                            {getFileDetails(file.file, 'type') == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-9">
                            <span className="document-label-value-wrap">
                              {getFileDetails(file.file, 'name')}
                            </span>
                            <br />
                            <a
                              // href={file.file}
                              href={`${File_node_url}` + file.file}
                              target="_blank"
                              //download="PitchDeck"
                              className="incubator-details-link">
                              View
                            </a>
                            {/* /
                      <a
                        href="#"
                        //download={file.file}
                        className="incubator-details-link"
                        onClick={() => downloadDocument(file.file, file.file)}
                      >
                        Download
                      </a> */}
                          </div>
                        </div>
                      ) : (
                        ''
                      ),
                  )}
                  {DocumentsData.First_Application_Documents.self_declaration_upload
                    .length > 2 ? (
                    <a
                      className="incubator-details-link"
                      data-toggle="modal"
                      data-target="#showMoreDocument"
                      onClick={() => setMeetingModal('Self Declaration')}>
                      More...
                    </a>
                  ) : (
                    ''
                  )}
                  <br />
                  <br />
                </div>
             
              
                <div className="col-md-8 left-align">
                  <h6>
                    <b>Relevant Document (Other)</b>
                  </h6>

                  {DocumentsData.First_Application_Documents.relavent_document_other
                    .length != 0 ? (
                    ''
                  ) : (
                    <label className="incubator-label-value">-</label>
                  )}

                  <br />
                  {DocumentsData.First_Application_Documents.relavent_document_other.map(
                    (file: any, index: any) =>
                      index < 2 ? (
                        <div className="row">
                          <div className="col-md-3">
                            {getFileDetails(file.file, 'type') == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-9">
                            <span className="document-label-value-wrap">
                              {getFileDetails(file.file, 'name')}
                            </span>
                            <br />
                            <a
                              // href={file.file}
                              href={`${File_node_url}` + file.file}
                              target="_blank"
                              //download="PitchDeck"
                              className="incubator-details-link">
                              View
                            </a>
                            {/* /
                      <a
                        href="#"
                        //download={file.file}
                        className="incubator-details-link"
                        onClick={() => downloadDocument(file.file, file.file)}
                      >
                        Download
                      </a> */}
                          </div>
                        </div>
                      ) : (
                        ''
                      ),
                  )}
                  {DocumentsData.First_Application_Documents.relavent_document_other
                    .length > 2 ? (
                    <a
                      className="incubator-details-link"
                      data-toggle="modal"
                      data-target="#showMoreDocument"
                      onClick={() => setMeetingModal('Relavent Document Other')}>
                      More...
                    </a>
                  ) : (
                    ''
                  )}
                  <br />
                  <br />
                </div>
             
              
              
                <div className="col-md-8 left-align">
                  <h6>
                    <b>Quarterly Reports</b>
                  </h6>

                  {/* {DocumentsData.First_Application_Documents.self_declaration_upload
                    .length != 0 ? (
                    ''
                  ) : ( */}
                    <label className="incubator-label-value">-</label>
                  {/* )} */}

                  <br />
                  
                  {/* {DocumentsData.First_Application_Documents.self_declaration_upload.map(
                    (file: any, index: any) =>
                      index < 2 ? (
                        <div className="row">
                          <div className="col-md-3">
                            {getFileDetails(file.file, 'type') == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-9">
                            <span className="document-label-value-wrap">
                              {getFileDetails(file.file, 'name')}
                            </span>
                            <br />
                            <a
                              // href={file.file}
                              href={`${File_node_url}` + file.file}
                              target="_blank"
                              //download="PitchDeck"
                              className="incubator-details-link">
                              View
                            </a>
                            
                          </div>
                        </div>
                      ) : (
                        ''
                      ),
                  )}
                  {DocumentsData.First_Application_Documents.self_declaration_upload
                    .length > 2 ? (
                    <a
                      className="incubator-details-link"
                      data-toggle="modal"
                      data-target="#showMoreDocument"
                      onClick={() => setMeetingModal('Pitch Deck')}>
                      More...
                    </a>
                  ) : (
                    ''
                  )} */}
                  <br/>
                </div>
             

      
              
                <div className="col-md-8 left-align">
                  <h6>
                    <b>Startup Details for the Last 3 Years</b>
                  </h6>

                  {/* {DocumentsData.First_Application_Documents.self_declaration_upload
                    .length != 0 ? (
                    ''
                  ) : ( */}
                    <label className="incubator-label-value">-</label>
                  {/* )} */}

                  <br />
                  {/* {DocumentsData.First_Application_Documents.self_declaration_upload.map(
                    (file: any, index: any) =>
                      index < 2 ? (
                        <div className="row">
                          <div className="col-md-3">
                            {getFileDetails(file.file, 'type') == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-9">
                            <span className="document-label-value-wrap">
                              {getFileDetails(file.file, 'name')}
                            </span>
                            <br />
                            <a
                              // href={file.file}
                              href={`${File_node_url}` + file.file}
                              target="_blank"
                              //download="PitchDeck"
                              className="incubator-details-link">
                              View
                            </a>
                            
                          </div>
                        </div>
                      ) : (
                        ''
                      ),
                  )}
                  {DocumentsData.First_Application_Documents.self_declaration_upload
                    .length > 2 ? (
                    <a
                      className="incubator-details-link"
                      data-toggle="modal"
                      data-target="#showMoreDocument"
                      onClick={() => setMeetingModal('Pitch Deck')}>
                      More...
                    </a>
                  ) : (
                    ''
                  )} */}
                </div>
              </div>
             
            </div>
          ) : (
            ''
          )}

          <div className="incubator-hr">
            <hr />
          </div>
{/* 
          <div className="row">
            <div
              id="q-4"
              className={
                activeIndexDoc == '4'
                  ? 'tranche-heading col-sm-11 left-align'
                  : 'reports-heading col-sm-11 left-align'
              }>
              Audited Annual Report for the Last 2 Years
            </div>
            <div className="col-sm-1">
              {activeIndexDoc == '4' ? (
                <div
                  onClick={changeActiveBackDoc}
                  data-toggle="collapse"
                  data-target="#annual-report-expand"
                  role="button"
                  aria-expanded="false"
                  aria-controls="annual-report-expand"
                  className="hide-icon pull-right hide-icon-details"
                  id="show-4">
                  <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                </div>
              ) : (
                <div
                  onClick={() => changeActiveDoc('4')}
                  id="hide-4"
                  data-toggle="collapse"
                  data-target="#annual-report-expand"
                  role="button"
                  aria-expanded="false"
                  aria-controls="annual-report-expand"
                  className="expand-icon pull-right expand-icon-details">
                  <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                </div>
              )}
            </div>
          </div>
          {activeIndexDoc == '4' ? (
            <div className="collapse" id="annual-report-expand">
              <div className="row">
                <div className="col-md-8 left-align">
                  {DocumentsData.First_Application_Documents.annual_report
                    .length != 0 ? (
                    ''
                  ) : (
                    <label className="document-label-value-wrap"> -</label>
                  )}
                  <br />
                  {DocumentsData.First_Application_Documents.annual_report.map(
                    (file: any, index: any) =>
                      index < 2 ? (
                        <div className="row">
                          <div className="col-md-3">
                            {getFileDetails(file.file, 'type') == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-9">
                            <span className="document-label-value-wrap">
                              {getFileDetails(file.file, 'name')}
                            </span>
                            <br />
                            <a
                              href={`${File_node_url}` + file.file}
                              target="_blank"
                              className="incubator-details-link">
                              View
                            </a>
                          </div>
                        </div>
                      ) : (
                        ''
                      ),
                  )}

                  {DocumentsData.First_Application_Documents.annual_report
                    .length > 2 ? (
                    <a
                      className="incubator-details-link"
                      data-toggle="modal"
                      data-target="#showMoreDocument"
                      onClick={() => setMeetingModal('Annual Report')}>
                      More...
                    </a>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          ) : (
            ''
          )}

          <div className="incubator-hr">
            <hr />
          </div>

          <div className="row">
            <div
              id="q-5"
              className={
                activeIndexDoc == '5'
                  ? 'tranche-heading col-sm-11 left-align'
                  : 'reports-heading col-sm-11 left-align'
              }>
              Relevant
            </div>
            <div className="col-sm-1">
              {activeIndexDoc == '5' ? (
                <div
                  onClick={changeActiveBackDoc}
                  data-toggle="collapse"
                  data-target="#relevant-expand"
                  role="button"
                  aria-expanded="false"
                  aria-controls="relevant-expand"
                  className="hide-icon pull-right hide-icon-details"
                  id="show-5">
                  <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                </div>
              ) : (
                <div
                  onClick={() => changeActiveDoc('5')}
                  id="hide-5"
                  data-toggle="collapse"
                  data-target="#relevant-expand"
                  role="button"
                  aria-expanded="false"
                  aria-controls="relevant-expand"
                  className="expand-icon pull-right expand-icon-details">
                  <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                </div>
              )}
            </div>
          </div>
          {activeIndexDoc == '5' ? (
            <div className="collapse" id="relevant-expand">
              <div className="row ">
                {DocumentsData.First_Application_Documents
                  .relavent_document_excel.length != 0 ? (
                  ''
                ) : (
                  <div className="col-md-3 left-align">
                    <label className="incubator-label-value"> -</label>
                  </div>
                )}

                <br />
                {DocumentsData.First_Application_Documents.relavent_document_excel.map(
                  (file: any, index: any) =>
                    index < 2 ? (
                      <div className="col-md-8 left-align">
                        <div className="row">
                          <div className="col-md-3">
                            {getFileDetails(file.file, 'type') == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-9">
                            <span className="document-label-value-wrap">
                              {getFileDetails(file.file, 'name')}
                            </span>
                            <br />
                            <a
                              // href={file.file}
                              href={`${File_node_url}` + file.file}
                              target="_blank"
                              //download="RelaventDocumentExcel"
                              className="incubator-details-link">
                              View
                            </a>
                           
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    ),
                )}

                {DocumentsData.First_Application_Documents
                  .relavent_document_excel.length > 2 ? (
                  <a
                    className="incubator-details-link"
                    data-toggle="modal"
                    data-target="#showMoreDocument"
                    onClick={() => setMeetingModal('Relevant Document Excel')}>
                    More...
                  </a>
                ) : (
                  ''
                )}

                <br />
                {DocumentsData.First_Application_Documents.relavent_document_other.map(
                  (file: any, index: any) =>
                    index < 2 ? (
                      <div className="col-md-8 left-align">
                        <div className="row">
                          <div className="col-md-3">
                            {getFileDetails(file.file, 'type') == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-9">
                            <span className="document-label-value-wrap">
                              {getFileDetails(file.file, 'name')}
                            </span>
                            <br />
                            <a
                              // href={file.file}
                              href={`${File_node_url}` + file.file}
                              target="_blank"
                              //download="RelaventDocumentOther"
                              className="incubator-details-link">
                              View
                            </a>
                            
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    ),
                )}
                {DocumentsData.First_Application_Documents
                  .relavent_document_other.length > 2 ? (
                  <a
                    className="incubator-details-link"
                    data-toggle="modal"
                    data-target="#showMoreDocument"
                    onClick={() => setMeetingModal('Relavent Document Other')}>
                    More...
                  </a>
                ) : (
                  ''
                )}

                {DocumentsData.First_Application_Documents.team_members_cv.map(
                  (file: any, index: any) =>
                    index < 2 ? (
                      <div className="col-md-8 left-align">
                        <div className="row">
                          <div className="col-md-3">
                            {getFileDetails(file.file, 'type') == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(file.file, 'type') == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-9">
                            <span className="document-label-value-wrap">
                              {getFileDetails(file.file, 'name')}{' '}
                            </span>
                            <br />
                            <a
                              // href={file.file}
                              href={`${File_node_url}` + file.file}
                              target="_blank"
                              //download="team_members_cv"
                              className="incubator-details-link">
                              View
                            </a>
                            
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    ),
                )}

                {DocumentsData.First_Application_Documents.team_members_cv
                  .length > 2 ? (
                  <a
                    className="incubator-details-link"
                    data-toggle="modal"
                    data-target="#showMoreDocument"
                    onClick={() => setMeetingModal('Team Member CV')}>
                    More...
                  </a>
                ) : (
                  ''
                )}
              </div>
            </div>
          ) : (
            ''
          )}

          <div className="incubator-hr">
            <hr />
          </div> */}

          {/* Show More  */}
          <div
            className="modal fade"
            id="showMoreDocument"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true">
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document">
              <div className="modal-content">
                <div className="modal-header modal-header-border-none ml-18px">
                  <h5 className="modal-title " id="exampleModalLongTitle">
                    {popUpName} <span className="eac-name-pop-up"></span>
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  {/* <div className=""> */}
                    {/* 1St Application Documents */}

                    {/* General Details documents */}

                    {popUpName == 'Proof of assistance from Central/State Government' ? (
                      DocumentsData.First_Application_Documents.proof_assistance !=
                      undefined ? (
                        DocumentsData.First_Application_Documents.proof_assistance.map(
                          (file: any, index: any) => (
                            <div className="row">
                                <div className="col-md-2">
                                {getFileDetails(file.file, 'type') == 'pdf' ? (
                                  <img src={pdf} alt="SISFS Logo" />
                                ) : getFileDetails(file.file, 'type') ==
                                  'doc' ? (
                                  <img src={doc} alt="SISFS Logo" />
                                ) : getFileDetails(file.file, 'type') ==
                                  'xls' ? (
                                  <img src={xls} alt="SISFS Logo" />
                                ) : (
                                  <img src={Allround} alt="SISFS Logo" />
                                )}
                              </div>
                              <div className="col-md-9 left-align">
                                <span className="document-label-value-wrap">
                                  {getFileDetails(file.file, 'name')}
                                </span>
                                <br />
                                <a
                                  // href={file.file}
                                  href={`${File_node_url}` + file.file}
                                  target="_blank"
                                  //download="annual_report"
                                  className="incubator-details-link">
                                  View
                                </a>
                                {/* /
                            <a
                              href="#"
                              //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                              </div>
                            </div>
                          ),
                        )
                      ) : (
                        <span className="incubator-label-value">-</span>
                      )
                    ) : (
                      ''
                    )}

{popUpName == 'Incorporation Certificate' ? (
                      DocumentsData.First_Application_Documents.incorporation_certificate !=
                      undefined ? (
                        DocumentsData.First_Application_Documents.incorporation_certificate.map(
                          (file: any, index: any) => (
                            <div className="row">
                                <div className="col-md-2">
                                {getFileDetails(file.file, 'type') == 'pdf' ? (
                                  <img src={pdf} alt="SISFS Logo" />
                                ) : getFileDetails(file.file, 'type') ==
                                  'doc' ? (
                                  <img src={doc} alt="SISFS Logo" />
                                ) : getFileDetails(file.file, 'type') ==
                                  'xls' ? (
                                  <img src={xls} alt="SISFS Logo" />
                                ) : (
                                  <img src={Allround} alt="SISFS Logo" />
                                )}
                              </div>
                              <div className="col-md-9 left-align">
                                <span className="document-label-value-wrap">
                                  {getFileDetails(file.file, 'name')}
                                </span>
                                <br />
                                <a
                                  // href={file.file}
                                  href={`${File_node_url}` + file.file}
                                  target="_blank"
                                  //download="annual_report"
                                  className="incubator-details-link">
                                  View
                                </a>
                                {/* /
                            <a
                              href="#"
                              //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                              </div>
                            </div>
                          ),
                        )
                      ) : (
                        <span className="incubator-label-value">-</span>
                      )
                    ) : (
                      ''
                    )}
                    {popUpName == 'PAN Card of legal entity' ? (
                      DocumentsData.First_Application_Documents.pan_card_upload !=
                      undefined ? (
                        DocumentsData.First_Application_Documents.pan_card_upload.map(
                          (file: any, index: any) => (
                            <div className="row">
                                <div className="col-md-2">
                                {getFileDetails(file.file, 'type') == 'pdf' ? (
                                  <img src={pdf} alt="SISFS Logo" />
                                ) : getFileDetails(file.file, 'type') ==
                                  'doc' ? (
                                  <img src={doc} alt="SISFS Logo" />
                                ) : getFileDetails(file.file, 'type') ==
                                  'xls' ? (
                                  <img src={xls} alt="SISFS Logo" />
                                ) : (
                                  <img src={Allround} alt="SISFS Logo" />
                                )}
                              </div>
                              <div className="col-md-9 left-align">
                                <span className="document-label-value-wrap">
                                  {getFileDetails(file.file, 'name')}
                                </span>
                                <br />
                                <a
                                  // href={file.file}
                                  href={`${File_node_url}` + file.file}
                                  target="_blank"
                                  //download="annual_report"
                                  className="incubator-details-link">
                                  View
                                </a>
                                {/* /
                            <a
                              href="#"
                              //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                              </div>
                            </div>
                          ),
                        )
                      ) : (
                        <span className="incubator-label-value">-</span>
                      )
                    ) : (
                      ''
                    )}
                    {popUpName == 'Board Resolution/ Authorisation letter/ PoA' ? (
                      DocumentsData.First_Application_Documents.authorisation_letter_upload !=
                      undefined ? (
                        DocumentsData.First_Application_Documents.authorisation_letter_upload.map(
                          (file: any, index: any) => (
                            <div className="row">
                            <div className="col-md-2">
                                {getFileDetails(file.file, 'type') == 'pdf' ? (
                                  <img src={pdf} alt="SISFS Logo" />
                                ) : getFileDetails(file.file, 'type') ==
                                  'doc' ? (
                                  <img src={doc} alt="SISFS Logo" />
                                ) : getFileDetails(file.file, 'type') ==
                                  'xls' ? (
                                  <img src={xls} alt="SISFS Logo" />
                                ) : (
                                  <img src={Allround} alt="SISFS Logo" />
                                )}
                              </div>
                              <div className="col-md-9 left-align">
                                <span className="document-label-value-wrap">
                                  {getFileDetails(file.file, 'name')}
                                </span>
                                <br />
                                <a
                                  // href={file.file}
                                  href={`${File_node_url}` + file.file}
                                  target="_blank"
                                  //download="annual_report"
                                  className="incubator-details-link">
                                  View
                                </a>
                                {/* /
                            <a
                              href="#"
                              //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                              </div>
                            </div>
                          ),
                        )
                      ) : (
                        <span className="incubator-label-value">-</span>
                      )
                    ) : (
                      ''
                    )}
                    {popUpName == 'Aadhaar Card of authorised signatory' ? (
                      DocumentsData.First_Application_Documents.adhaar_card_upload !=
                      undefined ? (
                        DocumentsData.First_Application_Documents.adhaar_card_upload.map(
                          (file: any, index: any) => (
                            <div className="row">
                                <div className="col-md-2">
                                {getFileDetails(file.file, 'type') == 'pdf' ? (
                                  <img src={pdf} alt="SISFS Logo" />
                                ) : getFileDetails(file.file, 'type') ==
                                  'doc' ? (
                                  <img src={doc} alt="SISFS Logo" />
                                ) : getFileDetails(file.file, 'type') ==
                                  'xls' ? (
                                  <img src={xls} alt="SISFS Logo" />
                                ) : (
                                  <img src={Allround} alt="SISFS Logo" />
                                )}
                              </div>
                              <div className="col-md-9 left-align">
                                <span className="document-label-value-wrap">
                                  {getFileDetails(file.file, 'name')}
                                </span>
                                <br />
                                <a
                                  // href={file.file}
                                  href={`${File_node_url}` + file.file}
                                  target="_blank"
                                  //download="annual_report"
                                  className="incubator-details-link">
                                  View
                                </a>
                                {/* /
                            <a
                              href="#"
                              //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                              </div>
                            </div>
                          ),
                        )
                      ) : (
                        <span className="incubator-label-value">-</span>
                      )
                    ) : (
                      ''
                    )}
                    {popUpName == 'Audited Annual Report for last 2 years' ? (
                      DocumentsData.First_Application_Documents.annual_report !=
                      undefined ? (
                        DocumentsData.First_Application_Documents.annual_report.map(
                          (file: any, index: any) => (
                            <div className="row">
                                <div className="col-md-2">
                                {getFileDetails(file.file, 'type') == 'pdf' ? (
                                  <img src={pdf} alt="SISFS Logo" />
                                ) : getFileDetails(file.file, 'type') ==
                                  'doc' ? (
                                  <img src={doc} alt="SISFS Logo" />
                                ) : getFileDetails(file.file, 'type') ==
                                  'xls' ? (
                                  <img src={xls} alt="SISFS Logo" />
                                ) : (
                                  <img src={Allround} alt="SISFS Logo" />
                                )}
                              </div>
                              <div className="col-md-9 left-align">
                                <span className="document-label-value-wrap">
                                  {getFileDetails(file.file, 'name')}
                                </span>
                                <br />
                                <a
                                  // href={file.file}
                                  href={`${File_node_url}` + file.file}
                                  target="_blank"
                                  //download="annual_report"
                                  className="incubator-details-link">
                                  View
                                </a>
                                {/* /
                            <a
                              href="#"
                              //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                              </div>
                            </div>
                          ),
                        )
                      ) : (
                        <span className="incubator-label-value">-</span>
                      )
                    ) : (
                      ''
                    )}
                    {popUpName == 'Agreement between incubator and startup' ? (
                      DocumentsData.First_Application_Documents.startup_incubation_upload !=
                      undefined ? (
                        DocumentsData.First_Application_Documents.startup_incubation_upload.map(
                          (file: any, index: any) => (
                            <div className="row">
                                <div className="col-md-2">
                                {getFileDetails(file.file, 'type') == 'pdf' ? (
                                  <img src={pdf} alt="SISFS Logo" />
                                ) : getFileDetails(file.file, 'type') ==
                                  'doc' ? (
                                  <img src={doc} alt="SISFS Logo" />
                                ) : getFileDetails(file.file, 'type') ==
                                  'xls' ? (
                                  <img src={xls} alt="SISFS Logo" />
                                ) : (
                                  <img src={Allround} alt="SISFS Logo" />
                                )}
                              </div>
                              <div className="col-md-9 left-align">
                                <span className="document-label-value-wrap">
                                  {getFileDetails(file.file, 'name')}
                                </span>
                                <br />
                                <a
                                  // href={file.file}
                                  href={`${File_node_url}` + file.file}
                                  target="_blank"
                                  //download="annual_report"
                                  className="incubator-details-link">
                                  View
                                </a>
                                {/* /
                            <a
                              href="#"
                              //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                              </div>
                            </div>
                          ),
                        )
                      ) : (
                        <span className="incubator-label-value">-</span>
                      )
                    ) : (
                      ''
                    )}
 {/* Incubator Details Documents popup */}
 {popUpName == 'Facilities Pictures'
                      ? DocumentsData.First_Application_Documents
                          .facilities_pictures != undefined
                        ? DocumentsData.First_Application_Documents.facilities_pictures.map(
                            (file: any, index: any) => (
                              <div className="row">
                                <div className="col-md-2">
                                  {getFileDetails(file.file, 'type') ==
                                  'pdf' ? (
                                    <img src={pdf} alt="SISFS Logo" />
                                  ) : getFileDetails(file.file, 'type') ==
                                    'doc' ? (
                                    <img src={doc} alt="SISFS Logo" />
                                  ) : getFileDetails(file.file, 'type') ==
                                    'xls' ? (
                                    <img src={xls} alt="SISFS Logo" />
                                  ) : (
                                    <img src={Allround} alt="SISFS Logo" />
                                  )}
                                </div>
                                <div className="col-md-10 left-align">
                                  <span className="document-label-value-wrap">
                                    {getFileDetails(file.file, 'name')}
                                  </span>
                                  <br />
                                  <a
                                    // href={file.file}
                                    href={`${File_node_url}` + file.file}
                                    target="_blank"
                                    //download="RelaventExcel"
                                    className="incubator-details-link">
                                    View
                                  </a>
                                  {/* /
                            <a
                              href="#"
                              //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                                </div>
                              </div>
                            ),
                          )
                        : ''
                      : ''}

{/* Other Documents pop ups */}
                    {popUpName == 'Relevant Document Excel'
                      ? DocumentsData.First_Application_Documents
                          .relavent_document_excel != undefined
                        ? DocumentsData.First_Application_Documents.relavent_document_excel.map(
                            (file: any, index: any) => (
                              <div className="row">
                                <div className="col-md-2">
                                  {getFileDetails(file.file, 'type') ==
                                  'pdf' ? (
                                    <img src={pdf} alt="SISFS Logo" />
                                  ) : getFileDetails(file.file, 'type') ==
                                    'doc' ? (
                                    <img src={doc} alt="SISFS Logo" />
                                  ) : getFileDetails(file.file, 'type') ==
                                    'xls' ? (
                                    <img src={xls} alt="SISFS Logo" />
                                  ) : (
                                    <img src={Allround} alt="SISFS Logo" />
                                  )}
                                </div>
                                <div className="col-md-10 left-align">
                                  <span className="document-label-value-wrap">
                                    {getFileDetails(file.file, 'name')}
                                  </span>
                                  <br />
                                  <a
                                    // href={file.file}
                                    href={`${File_node_url}` + file.file}
                                    target="_blank"
                                    //download="RelaventExcel"
                                    className="incubator-details-link">
                                    View
                                  </a>
                                  {/* /
                            <a
                              href="#"
                              //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                                </div>
                              </div>
                            ),
                          )
                        : ''
                      : ''}
                  {/* </div> */}
                  {popUpName == 'Team Member CV'
                    ? DocumentsData.First_Application_Documents
                        .team_members_cv != undefined
                      ? DocumentsData.First_Application_Documents.team_members_cv.map(
                          (file: any) => (
                            <div className="row">
                              <div className="col-md-2">
                                {getFileDetails(file.file, 'type') == 'pdf' ? (
                                  <img src={pdf} alt="SISFS Logo" />
                                ) : getFileDetails(file.file, 'type') ==
                                  'doc' ? (
                                  <img src={doc} alt="SISFS Logo" />
                                ) : getFileDetails(file.file, 'type') ==
                                  'xls' ? (
                                  <img src={xls} alt="SISFS Logo" />
                                ) : (
                                  <img src={Allround} alt="SISFS Logo" />
                                )}
                              </div>
                              <div className="col-md-10 left-align">
                                <span className="document-label-value-wrap">
                                  {getFileDetails(file.file, 'name')}
                                </span>
                                <br />
                                <a
                                  // href={file.file}
                                  href={`${File_node_url}` + file.file}
                                  target="_blank"
                                  //download="TeamMember"
                                  className="incubator-details-link">
                                  View
                                </a>
                                {/* /
                        <a
                          href="#"
                          //download={file.file}
                          className="incubator-details-link"
                          onClick={() =>
                            downloadDocument(file.file, file.file)
                          }
                        >
                          Download
                        </a> */}
                              </div>
                            </div>
                          ),
                        )
                      : ''
                    : ''}
                  {popUpName == 'Pitch Deck'
                    ? DocumentsData.First_Application_Documents
                        .relavent_document_pitchdeck != undefined
                      ? DocumentsData.First_Application_Documents.relavent_document_pitchdeck.map(
                          (file: any) => (
                            <div className="row">
                              <div className="col-md-2">
                                {getFileDetails(file.file, 'type') == 'pdf' ? (
                                  <img src={pdf} alt="SISFS Logo" />
                                ) : getFileDetails(file.file, 'type') ==
                                  'doc' ? (
                                  <img src={doc} alt="SISFS Logo" />
                                ) : getFileDetails(file.file, 'type') ==
                                  'xls' ? (
                                  <img src={xls} alt="SISFS Logo" />
                                ) : (
                                  <img src={Allround} alt="SISFS Logo" />
                                )}
                              </div>
                              <div className="col-md-10 left-align">
                                <span className="document-label-value-wrap">
                                  {getFileDetails(file.file, 'name')}
                                </span>
                                <br />
                                <a
                                  // href={file.file}
                                  href={`${File_node_url}` + file.file}
                                  target="_blank"
                                  //download="PitchDeck"
                                  className="incubator-details-link">
                                  View
                                </a>
                                {/* /
                        <a
                          href="#"
                          //download={file.file}
                          className="incubator-details-link"
                          onClick={() =>
                            downloadDocument(file.file, file.file)
                          }
                        >
                          Download
                        </a> */}
                              </div>
                            </div>
                          ),
                        )
                      : ''
                    : ''}

                  {popUpName == 'Relavent Document Other'
                    ? DocumentsData.First_Application_Documents
                        .relavent_document_other != undefined
                      ? DocumentsData.First_Application_Documents.relavent_document_other.map(
                          (file: any) => (
                            <div className="row">
                              <div className="col-md-2">
                                {getFileDetails(file.file, 'type') == 'pdf' ? (
                                  <img src={pdf} alt="SISFS Logo" />
                                ) : getFileDetails(file.file, 'type') ==
                                  'doc' ? (
                                  <img src={doc} alt="SISFS Logo" />
                                ) : getFileDetails(file.file, 'type') ==
                                  'xls' ? (
                                  <img src={xls} alt="SISFS Logo" />
                                ) : (
                                  <img src={Allround} alt="SISFS Logo" />
                                )}
                              </div>
                              <div className="col-md-10 left-align">
                                <span className="document-label-value-wrap">
                                  {getFileDetails(file.file, 'name')}
                                </span>
                                <br />
                                <a
                                  // href={file.file}
                                  href={`${File_node_url}` + file.file}
                                  target="_blank"
                                  //download="Relavent"
                                  className="incubator-details-link">
                                  View
                                </a>
                                {/* /{' '}
                        <a
                          href="#"
                          //download={file.file}
                          className="incubator-details-link"
                          onClick={() =>
                            downloadDocument(file.file, file.file)
                          }
                        >
                          Download
                        </a> */}
                              </div>
                            </div>
                          ),
                        )
                      : ''
                    : ''}
                    {popUpName == 'Self Declaration'
                    ? DocumentsData.First_Application_Documents
                        .self_declaration_upload != undefined
                      ? DocumentsData.First_Application_Documents.self_declaration_upload.map(
                          (file: any) => (
                            <div className="row">
                              <div className="col-md-2">
                                {getFileDetails(file.file, 'type') == 'pdf' ? (
                                  <img src={pdf} alt="SISFS Logo" />
                                ) : getFileDetails(file.file, 'type') ==
                                  'doc' ? (
                                  <img src={doc} alt="SISFS Logo" />
                                ) : getFileDetails(file.file, 'type') ==
                                  'xls' ? (
                                  <img src={xls} alt="SISFS Logo" />
                                ) : (
                                  <img src={Allround} alt="SISFS Logo" />
                                )}
                              </div>
                              <div className="col-md-10 left-align">
                                <span className="document-label-value-wrap">
                                  {getFileDetails(file.file, 'name')}
                                </span>
                                <br />
                                {file !== null ?
                                <a
                                  // href={file.file}
                                  href={`${File_node_url}` + file.file}
                                  target="_blank"
                                  //download="Relavent"
                                  className="incubator-details-link">
                                  View
                                </a>:null}
                                {/* /{' '}
                        <a
                          href="#"
                          //download={file.file}
                          className="incubator-details-link"
                          onClick={() =>
                            downloadDocument(file.file, file.file)
                          }
                        >
                          Download
                        </a> */}
                              </div>
                            </div>
                          ),
                        )
                      : ''
                    : ''}

                  {/* 2nd Application Documents */}
                  {popUpName == 'pitchdeck_upload_fpath'
                    ? DocumentsData.Second_Application_Documents
                        .pitchdeck_upload_fpath != undefined
                      ? DocumentsData.Second_Application_Documents.pitchdeck_upload_fpath.map(
                          (file: any) => (
                            <div className="row">
                              <div className="col-md-2">
                                {getFileDetails(file, 'type') == 'pdf' ? (
                                  <img src={pdf} alt="SISFS Logo" />
                                ) : getFileDetails(file, 'type') == 'doc' ? (
                                  <img src={doc} alt="SISFS Logo" />
                                ) : getFileDetails(file, 'type') == 'xls' ? (
                                  <img src={xls} alt="SISFS Logo" />
                                ) : (
                                  <img src={Allround} alt="SISFS Logo" />
                                )}
                              </div>
                              <div className="col-md-10 left-align">
                                <span className="document-label-value-wrap">
                                  {getFileDetails(file, 'name')}
                                </span>
                                <br />
                                {file !== null ?
                                <a
                                  // href={file}
                                  href={`${File_node_url}` + file.file}
                                  target="_blank"
                                  //download="Relavent"
                                  className="incubator-details-link">
                                  View
                                </a>:null}
                                {/* /{' '}
                        <a
                          href="#"
                          //download={file.file}
                          className="incubator-details-link"
                          onClick={() =>
                            downloadDocument(file.file, file.file)
                          }
                        >
                          Download
                        </a> */}
                              </div>
                            </div>
                          ),
                        )
                      : ''
                    : ''}

                  {popUpName == 'Performance_Upload_fpath'
                    ? DocumentsData.Second_Application_Documents
                        .Performance_Upload_fpath != undefined
                      ? DocumentsData.Second_Application_Documents.Performance_Upload_fpath.map(
                          (file: any) => (
                            <div className="row">
                              <div className="col-md-2">
                                {getFileDetails(file, 'type') == 'pdf' ? (
                                  <img src={pdf} alt="SISFS Logo" />
                                ) : getFileDetails(file, 'type') == 'doc' ? (
                                  <img src={doc} alt="SISFS Logo" />
                                ) : getFileDetails(file, 'type') == 'xls' ? (
                                  <img src={xls} alt="SISFS Logo" />
                                ) : (
                                  <img src={Allround} alt="SISFS Logo" />
                                )}
                              </div>
                              <div className="col-md-10 left-align">
                                <span className="document-label-value-wrap">
                                  {getFileDetails(file, 'name')}
                                </span>
                                <br />
                                {file !== null ?
                                <a
                                  // href={file}
                                  href={`${File_node_url}` + file.file}
                                  target="_blank"
                                  //download="Relavent"
                                  className="incubator-details-link">
                                  View
                                </a>:null}
                                {/* /{' '}
                        <a
                          href="#"
                          //download={file.file}
                          className="incubator-details-link"
                          onClick={() =>
                            downloadDocument(file.file, file.file)
                          }
                        >
                          Download
                        </a> */}
                              </div>
                            </div>
                          ),
                        )
                      : ''
                    : ''}

                  {popUpName == 'Other_doc_upload_fpath'
                    ? DocumentsData.Second_Application_Documents
                        .Other_doc_upload_fpath != undefined
                      ? DocumentsData.Second_Application_Documents.Other_doc_upload_fpath.map(
                          (file: any) => (
                            <div className="row">
                              <div className="col-md-2">
                                {getFileDetails(file, 'type') == 'pdf' ? (
                                  <img src={pdf} alt="SISFS Logo" />
                                ) : getFileDetails(file, 'type') == 'doc' ? (
                                  <img src={doc} alt="SISFS Logo" />
                                ) : getFileDetails(file, 'type') == 'xls' ? (
                                  <img src={xls} alt="SISFS Logo" />
                                ) : (
                                  <img src={Allround} alt="SISFS Logo" />
                                )}
                              </div>
                              <div className="col-md-10 left-align">
                                <span className="document-label-value-wrap">
                                  {getFileDetails(file, 'name')}
                                </span>
                                <br />
                                {file !== null ?
                                <a
                                  // href={file}
                                  href={`${File_node_url}` + file.file}
                                  target="_blank"
                                  //download="Relavent"
                                  className="incubator-details-link">
                                  View
                                </a>:null}
                                {/* /{' '}
                        <a
                          href="#"
                          //download={file.file}
                          className="incubator-details-link"
                          onClick={() =>
                            downloadDocument(file.file, file.file)
                          }
                        >
                          Download
                        </a> */}
                              </div>
                            </div>
                          ),
                        )
                      : ''
                    : ''}

                  {popUpName == 'Utilization_Certificate_Upload'
                    ? DocumentsData.Second_Application_Documents
                        .Utilization_Certificate_Upload != undefined
                      ? DocumentsData.Second_Application_Documents.Utilization_Certificate_Upload.map(
                          (file: any) => (
                            <div className="row">
                              <div className="col-md-2">
                                {getFileDetails(file, 'type') == 'pdf' ? (
                                  <img src={pdf} alt="SISFS Logo" />
                                ) : getFileDetails(file, 'type') == 'doc' ? (
                                  <img src={doc} alt="SISFS Logo" />
                                ) : getFileDetails(file, 'type') == 'xls' ? (
                                  <img src={xls} alt="SISFS Logo" />
                                ) : (
                                  <img src={Allround} alt="SISFS Logo" />
                                )}
                              </div>
                              <div className="col-md-10 left-align">
                                <span className="document-label-value-wrap">
                                  {getFileDetails(file, 'name')}
                                </span>
                                <br />
                                {file !== null ?
                                <a
                                  // href={file}
                                  href={`${File_node_url}` + file.file}
                                  target="_blank"
                                  //download="Relavent"
                                  className="incubator-details-link">
                                  View
                                </a>:null}
                                {/* /{' '}
                        <a
                          href="#"
                          //download={file.file}
                          className="incubator-details-link"
                          onClick={() =>
                            downloadDocument(file.file, file.file)
                          }
                        >
                          Download
                        </a> */}
                              </div>
                            </div>
                          ),
                        )
                      : ''
                    : ''}

                  {popUpName == 'Audited_statement_expenditure_Upload'
                    ? DocumentsData.Second_Application_Documents
                        .Audited_statement_expenditure_Upload != undefined
                      ? DocumentsData.Second_Application_Documents.Audited_statement_expenditure_Upload.map(
                          (file: any) => (
                            <div className="row">
                              <div className="col-md-2">
                                {getFileDetails(file, 'type') == 'pdf' ? (
                                  <img src={pdf} alt="SISFS Logo" />
                                ) : getFileDetails(file, 'type') == 'doc' ? (
                                  <img src={doc} alt="SISFS Logo" />
                                ) : getFileDetails(file, 'type') == 'xls' ? (
                                  <img src={xls} alt="SISFS Logo" />
                                ) : (
                                  <img src={Allround} alt="SISFS Logo" />
                                )}
                              </div>
                              <div className="col-md-10 left-align">
                                <span className="document-label-value-wrap">
                                  {getFileDetails(file, 'name')}
                                </span>
                                <br />
                                {file !== null ?
                                <a
                                  // href={file}
                                  href={`${File_node_url}` + file.file}
                                  target="_blank"
                                  //download="Relavent"
                                  className="incubator-details-link">
                                  View
                                </a>:null}
                                {/* /{' '}
                        <a
                          href="#"
                          //download={file.file}
                          className="incubator-details-link"
                          onClick={() =>
                            downloadDocument(file.file, file.file)
                          }
                        >
                          Download
                        </a> */}
                              </div>
                            </div>
                          ),
                        )
                      : ''
                    : ''}

                  <div className="row">
                    <div className="col-md-12 centerText">
                      <div className="btn-group mt-1-5 ">
                        <button
                          className="tertiary  btn-yellow-primary"
                          data-dismiss="modal">
                          OK
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  );
}
export default MergedDocumentsDetailsComponent;
