import TrancheDetails from "./TrancheDetailsComponent";
import AccountDetails from "./AccountDetailsComponent";
import React, { useEffect, useState } from "react";
import { UploadTypeEnum } from "../../../utils/Enums";
import FileUploader from "@amodv/react-file-uploader";
import {
  Documents,
  FileUploadModelTranche,
  TrancheData,
  TrancheDetail,
  TrancheRequest,
} from "../../../models/funddetails/TrancheDetails";
import {
  AccountFileUploadModel,
  FileUploadModel,
  TrancheFileUploadModel,
} from "../../../models/FileUploadModel";
import {
  ACCOUNT_DOCUMENT_REMOVE,
  ACCOUNT_DOCUMENT_UPLOAD,
  GET_ACTIVE_TRANCHE_REQUEST,
  GET_ALL_TRANCHES,
  GET_APPLICATION_LIST,
  GET_BANK_DETAILS,
  GET_TRANCHE_REQUEST,
  STORE_BANK_DETAILS,
  STORE_TRANCHE_REQUEST,
  TRANCHE_REMOVE_DOCUMENT,
  TRANCHE_UPLOAD_DOCUMENT,
  STARTUP_DOCUMENT_UPLOAD,
  GET_APPLICATION_LIST_NODE,
  GET_ALL_TRANCHES_NODE,
  STORE_TRANCHE_REQUEST_NODE,
  GET_ACTIVE_TRANCHE_REQUEST_NODE,
  STORE_BANK_DETAILS_Reapply_Node,
  GET_BANK_DETAILS_REAPPLY_NODE,
  File_node_url,
  FUNDDETAILS_GET_API_FILEUPLOAD,
  FUNDDETAILS_FILEUPLOAD,
} from "../../../constants/api";
import { get, post } from "../../../effects/api/api.common";
import { changeNumberFormat, createFile } from "../../../utils/CommonMethods";
import {
  ApplicationStatus,
  FileDownloadModel,
} from "../../dpiit/incubator/ApplicationGeneralDetails";
import {
  AccountDetailsData,
  SubsequentDetails,
} from "../../../models/funddetails/AccountDetailsData";
import { FundDetailsModel } from "../../../models/incubator/FundDetailsModel";
import { useHistory, useParams } from "react-router-dom";
import { updateSetAccessor } from "typescript";
import { validateRequiredText } from "../../../effects/utils/validations";
import { getSessionStorageValue } from "../../../effects/utils/session-storage";
import ReapplyTrancheDetailsComponent from "./ReapplyTrancheDetailsComponent";
import ReapplyAccountDetailsComponent from "./ReapplyAccountDetailsComponent";
import ConfirmationDialog from "../../common/controls/ConfirmationDialog";
import View from "../../../images/ReturnsIcons/eye.svg";
import Allround from "../../../images/Allround.svg";
import flashIcon from "../../../images/flashIcon.png";
interface IIKeyValueIndexer {
  [key: string]: boolean;
}

function FundDetailsContainer() {
  const [openUploadBlock, setOpenUploadBlock] = useState<IIKeyValueIndexer>();

  const [trancheRequest, setTrancheRequest] = useState<TrancheRequest>(
    new TrancheRequest(0, 0, "", 0, "0", [], [], "")
  );

  const [ReapplyTrancheRequest, setReapplyTrancheRequest] =
    useState<TrancheRequest>(new TrancheRequest(0, 0, "", 0, "0", [], [], ""));
  const [total_grand_approvedone, setGrantApprovedone] = useState(0);
  const [total_grand_approvedtwo, setGrantApprovedtwo] = useState(0);
  const [checkFundRelease, setCheckFundRelese] = useState(false);
  // const [ReapplyAccountData, setReapplyAccountData] = useState<any>({
  //   bank_account_holder_name: "",
  //   bank_name: "",
  //   branch_name: "",
  //   account_type: "",
  //   account_number: "",
  //   bank_ifsc_code: "",
  //   bank_micr_code: "",
  //   branch_address: "",
  //   pan_card_location: "",
  //   cancelled_cheque_location: "",
  //   darpan_location: "",
  // });

  const [ReapplyAccountData, setReapplyAccountData] =
    useState<AccountDetailsData>(
      new AccountDetailsData(
        0,
        0,
        "",
        "",
        "",
        0,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        new AccountFileUploadModel(new File([], "")),
        new AccountFileUploadModel(new File([], "")),
        "",
        new AccountFileUploadModel(new File([], "")),
        "",
        new AccountFileUploadModel(new File([], "")),
        new AccountFileUploadModel(new File([], "")),
        "",
        "",
        "",
        ""
      )
    );

  const [accountDetailsData, setAccountDetails] = useState<AccountDetailsData>(
    new AccountDetailsData(
      0,
      0,
      "",
      "",
      "",
      0,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      new AccountFileUploadModel(new File([], "")),
      new AccountFileUploadModel(new File([], "")),
      "",
      new AccountFileUploadModel(new File([], "")),
      "",
      new AccountFileUploadModel(new File([], "")),
      new AccountFileUploadModel(new File([], "")),
      "",
      "",
      "",
      ""
    )
  );
  const [subsequentDetails, setSubsequentDtls] = useState<SubsequentDetails>(
    new SubsequentDetails(0, 0, "", "", "", "", "", "", "")
  );
  const [uploadedPan, setUploadedPan] = useState<Array<FileUploadModelTranche>>(
    []
  );
  const [uploadDarpan, setUploadedDarpan] = useState<
    Array<FileUploadModelTranche>
  >([]);
  const [uploadSanction, setUploadedSanction] = useState<
    Array<FileUploadModelTranche>
  >([]);
  const [uploadtra, setUploadedtra] = useState<Array<FileUploadModelTranche>>(
    []
  );

  const [uploadFile, setUploadFile] = useState<Array<File>>([]);
  const [other_file, setOtherFile] = useState<Array<File>>([]);
  const [pan_file, setPanFile] = useState<Array<File>>([]);
  const [sanction_file, setSanctionFile] = useState<Array<File>>([]);
  const [tra_file, setTraFile] = useState<Array<File>>([]);
  // new FileUploadModelTranche(
  //   0,
  //   0,
  //   new File([], ""),
  //   ''
  // )
  const [uploadedCheque, setUploadedCheque] = useState<
    Array<FileUploadModelTranche>
  >([]);
  const [tranche_message, setMessage] = useState("");
  const [uploadedFile, setUploadedFile] = useState([]);
  const [ReaapplyInclistData, SetReaapplyInclistData] = useState("");

  const [isDisabledAccountDetails, setIsDisabledAccountDetails] = useState({
    bank_account_holder_name: false,
    bank_name: false,
    account_number: false,
    account_type: false,
    bank_ifsc_code: false,
    bank_micr_code: false,
    branch_name: false,
    branch_address: false,
    pan_card: false,
    cancelled_cheque: false,
    darpan: false,
  });

  const [activeIndex, setActiveIndex] = useState("1");
  const changeActive = (id: string) => {
    setActiveIndex(id);
  };

  const [ReapplyactiveIndex, setReapplyActiveIndex] = useState("3");
  const ReapplychangeActive = (id: string) => {
    setReapplyActiveIndex(id);
  };

  // const changeActiveBack = () => {
  //   setActiveIndex('1');
  // };

  // const changeActiveBack1 = () => {
  //   setActiveIndex1('11');
  // };

  const [errMessage, setErrMessage] = useState("");
  const [ReapplyErrMessage, setReapplyErrMessage] = useState("");

  const user: any = getSessionStorageValue("user");
  const userInfo = JSON.parse(user);
  const [isEnable, setIsEnable] = useState(false);
  const [ReapplyIsEnable, setReapplyIsEnable] = useState(false);

  const [activeTranches, setActiveTranches] = useState<
    Array<ApplicationStatus>
  >([]);

  const [ReapplyActiveTranches, setReapplyActiveTranches] = useState<
    Array<ApplicationStatus>
  >([]);

  const [Approved_Amount, setApproved_Amount] = useState();
  const [Availble_Amount, setAvailble_Amount] = useState<any>();
  const [pdfOnly, setPdfOnly] = useState<IIKeyValueIndexer>();
  const [application_id, setApplicationId] = useState(0);
  const [ReApply_application_id, setReapplyApplicationId] = useState(0);
  const [showIncubatorSuccessModal, setShowIncubatorSuccessModal] =
    useState(false);
  const [dialogData, setDialogData] = useState({
    header: "",
    text: "",
  });
  const [showIncubatorFailureModal1, setShowIncubatorFailureModal1] =
    useState(false);
  const [failDialogData, setFailDialogData] = useState({
    header: "",
    text: "",
  });
  const params: any = useParams();
  // triggers whenever user makes any changes in the form for select and input type
  const handleChange = (
    evt: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const name = evt.target.name;
    const value = evt.target.value;

    let trancheDetails = JSON.parse(JSON.stringify(trancheRequest));

    if (name == "amount_required") {
      const numStr = String(evt.target.value);
      var count = 0;
      if (numStr.includes(".")) {
        count = numStr.split(".")[1].length;
      }
      if (count > 2) {
        trancheDetails[name] = Number(evt.target.value).toFixed(2);
      } else {
        trancheDetails[name] = evt.target.value.replace(/[^0-9.]/gi, "");
      }
    } else {
      trancheDetails[name] = evt.target.value;
    }
    if (name == "amount_required" && Number(value) > 0) {
      setIsEnable(true);
    }
    if (
      name == "cash_in_hand" &&
      Number(getTranche1_amnt || getTranche2_amnt || getTranche3_amnt) > 0
    ) {
      setIsEnable(true);
    }

    setTrancheRequest(trancheDetails);
  };

  const handleChangeReapply = (
    evt: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const name = evt.target.name;
    const value = evt.target.value;

    let trancheDetails = JSON.parse(JSON.stringify(ReapplyTrancheRequest));

    if (name == "amount_required") {
      const numStr = String(evt.target.value);
      var count = 0;
      if (numStr.includes(".")) {
        count = numStr.split(".")[1].length;
      }
      if (count > 2) {
        trancheDetails[name] = Number(evt.target.value).toFixed(2);
      } else {
        trancheDetails[name] = evt.target.value.replace(/[^0-9.]/gi, "");
      }
    } else {
      trancheDetails[name] = evt.target.value;
    }
    if (name == "amount_required" && Number(value) > 0) {
      setReapplyIsEnable(true);
    }
    if (
      name == "cash_in_hand" &&
      Number(getReTranche1_amnt || getReTranche2_amnt || getReTranche3_amnt) > 0
    ) {
      setReapplyIsEnable(true);
    }

    setReapplyTrancheRequest(trancheDetails);
  };

  const handleChangeAccountDetails = (
    evt: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const name = evt.target.name;
    const value = evt.target.value;

    let accountDetails = JSON.parse(JSON.stringify(accountDetailsData));

    if (name == "bank_account_holder_name" || name == "bank_name") {
      accountDetails[name] = value.replace(/[^a-zA-Z ]/gi, "");
      setAccountDetails(accountDetails);
    } else if (
      name == "branch_name" ||
      name == "bank_ifsc_code" ||
      name == "bank_micr_code"
    ) {
      accountDetails[name] = value.replace(/[^a-zA-Z0-9 ]/gi, "");
      setAccountDetails(accountDetails);
    } else if (name == "account_number") {
      accountDetails[name] = value.replace(/[^0-9]/gi, "");
      setAccountDetails(accountDetails);
    } else {
      accountDetails[name] = value;
      setAccountDetails(accountDetails);
    }
  };

  const ReapplyhandleChangeAccountDetails = (
    evt: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const Reapplyname = evt.target.name;
    const Reapplyvalue = evt.target.value;

    let ReapplyACData = JSON.parse(JSON.stringify(ReapplyAccountData));
    ReapplyACData[Reapplyname] = Reapplyvalue;
    setReapplyAccountData(ReapplyACData);

    // setReapplyAccountData(() => ({
    //   ...ReapplyAccountData,
    //   [Reapplyname]: Reapplyvalue
    // }))

    // console.log(ReapplyAccountData);
  };
  const [getTranche1_amnt, setTranche1Amnt] = useState<any>();
  const [getTranche2_amnt, setTranche2Amnt] = useState<any>();
  const [getTranche3_amnt, setTranche3Amnt] = useState<any>();
  const [getReTranche1_amnt, setReTranche1Amnt] = useState<any>();
  const [getReTranche2_amnt, setReTranche2Amnt] = useState<any>();
  const [getReTranche3_amnt, setReTranche3Amnt] = useState<any>();
  const [totalDisbursedAmount1, setTotalDisbursedAmount1] = useState<any>();
  const [totalDisbursedAmount2, setTotalDisbursedAmount2] = useState<any>();
  const [loader, setLoader] = useState(false);
  const [showRequest, setShowRequest] = useState(true);
  useEffect(() => {
    console.log(trancheData, "trancheData666");

    // console.log(totalDisbursedAmount1,"trancheData")
    // console.log(totalDisbursedAmount2,"trancheData")
    window.scrollTo(0, 0);
    checkSelectedApplication();
    // ReApplyCheckSelectedApplication();
    getAccountDetails();
    // ReapplygetAccountDetails();
    Get_upload_File();
  }, []);
  const [trancheData, setTrancheData] = useState<Array<TrancheDetail>>([
    new TrancheDetail(
      0,
      0,
      "",
      0,
      0,
      "",
      "",
      new Documents([], [], []),
      "",
      0,
      0,
      "",
      "",
      "",
      "",
      "",
      "",
      false,
      false,
      false,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    ),
  ]);

  const [ReapplytrancheData, setReapplyTrancheData] = useState<
    Array<TrancheDetail>
  >([
    new TrancheDetail(
      0,
      0,
      "",
      0,
      0,
      "",
      "",
      new Documents([], [], []),
      "",
      0,
      0,
      "",
      "",
      "",
      "",
      "",
      "",
      false,
      false,
      false,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    ),
  ]);
  const [Reapplytrancheslist, setReapplytrancheslist] = useState<any>([
    new TrancheDetail(
      0,
      0,
      "",
      0,
      0,
      "",
      "",
      new Documents([], [], []),
      "",
      0,
      0,
      "",
      "",
      "",
      "",
      "",
      "",
      false,
      false,
      false,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    ),
  ]);
  const [firsttrancheslist, setfirsttrancheslist] = useState<any>();
  const [trancheDataDESC, setTrancheDataDESC] = useState<Array<TrancheDetail>>([
    new TrancheDetail(
      0,
      0,
      "",
      0,
      0,
      "",
      "",
      new Documents([], [], []),
      "",
      0,
      0,
      "",
      "",
      "",
      "",
      "",
      "",
      false,
      false,
      false,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    ),
  ]);

  const [ReapplytrancheDataDESC, setReapplyTrancheDataDESC] = useState<
    Array<TrancheDetail>
  >([
    new TrancheDetail(
      0,
      0,
      "",
      0,
      0,
      "",
      "",
      new Documents([], [], []),
      "",
      0,
      0,
      "",
      "",
      "",
      "",
      "",
      "",
      false,
      false,
      false,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    ),
  ]);

  const checkSelectedApplication = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    var payload = {
      logged_user_id: userInfo.id,
      roles: userInfo.roles,
    };
    setLoader(true);
    post(GET_APPLICATION_LIST, payload).then((response: any) => {
      setLoader(false);
      if (!response || !response.data) return;
      // console.log(response.data.data[0], "data!!!!")

      if (response.data.data.length != 0) {
        // setTrancheData(response.data.data);
        const trancheDataArray: any[] = [];

        response.data.data.forEach((element: any) => {
          // console.log(element)
          if (element.status != "Rejected") {
            trancheDataArray.push(element);
          }
        });
        setTrancheData(trancheDataArray);
        console.log(trancheDataArray[0]);
        setApproved_Amount(trancheDataArray[0].returns_approved);
        setAvailble_Amount(
          parseInt(response.data.data[0].totalRemainingAmount)
        );
        let totalApprovedAmount = trancheDataArray[0].total_approved_amt;
        let totalMangementAmount = trancheDataArray[0].total_management_amount;

        let forTranche1 = (
          (totalApprovedAmount + totalMangementAmount) *
          0.4
        ).toFixed(2);
        setTranche1Amnt(forTranche1);
        let forTranche2 = (
          (totalApprovedAmount + totalMangementAmount) *
          0.3
        ).toFixed(2);
        setTranche2Amnt(forTranche2);
        let forTranche3 = (
          (totalApprovedAmount + totalMangementAmount) *
          0.3
        ).toFixed(2);
        setTranche3Amnt(forTranche3);

        const nonDisbursedStatus = response.data.data.some(
          (item: any) => item.tranches_status_label != "Disbursed"
        );
        setShowRequest(!nonDisbursedStatus);
        for (let i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].status === "Selected") {
            // console.log(response.data.data[i].application_id, "idssss")
            setApplicationId(response.data.data[i].application_id);
            getAllTranches(response.data.data[i].application_id);
            getActiveTrancheRequest(response.data.data[i].application_id);
            break;
          }
        }
      }
      ReApplyCheckSelectedApplication();
    });
  };

  const ReApplyCheckSelectedApplication = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    const userId = userInfo.id;
    const UserRole = userInfo.roles;
    const requestPayload = {
      logged_user_id: userId,
      user_role: UserRole,
    };
    setLoader(true);
    post(GET_APPLICATION_LIST_NODE, requestPayload).then((response: any) => {
      setLoader(false);
      if (!response || !response.data.data) return;
      SetReaapplyInclistData(response.data.data);
      setReapplyTrancheData(response.data.data);
      let totalApprovedAmount = response.data?.data[0]?.total_approved_amt;
      let totalMangementAmount =
        response.data?.data[0]?.total_management_amount;

      let forTranche1 = (
        (totalApprovedAmount + totalMangementAmount) *
        0.4
      ).toFixed(2);
      setReTranche1Amnt(forTranche1);
      let forTranche2 = (
        (totalApprovedAmount + totalMangementAmount) *
        0.3
      ).toFixed(2);
      setReTranche2Amnt(forTranche2);
      let forTranche3 = (
        (totalApprovedAmount + totalMangementAmount) *
        0.3
      ).toFixed(2);
      setReTranche3Amnt(forTranche3);
      if (response.data.data.length != 0) {
        for (let i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].status === "Selected") {
            setReapplyApplicationId(response.data.data[i].application_id);
            getReapplyAllTranches(response.data.data[i].application_id);
            getReapplyActiveTrancheRequest(
              response.data.data[i].application_id
            );
            break;
          }
        }
      }
      getAccountDetails();
    });
  };

  const getAllTranches = (application_Id: number) => {
    if (application_Id != 0) {
      let tranche_parameters = {
        application_id: application_Id,
        order_by: "DESC",
        application_index: "F1",
      };
      setLoader(true);
      post(`${GET_ALL_TRANCHES}`, tranche_parameters).then((response: any) => {
        setLoader(false);
        if (!response || !response.data?.data) return;
        var sum1 = 0;
        {
          for (let i = 0; i < response.data.data?.length; i++) {
            if (response.data.data[i].tranches_status_label === "Disbursed") {
              sum1 = sum1 + response.data.data[i].fund_released;
              setTotalDisbursedAmount1(sum1);
            }
          }
        }

        var trancheDetailsData: Array<TrancheDetail> = response.data.data;

        for (var i = 0; i < trancheDetailsData.length; i++) {
          var tranche_details = trancheDetailsData[i];
          const file_data = tranche_details.documents;
          if (trancheDetailsData[i].fund_released == null) {
            setCheckFundRelese(true);
          }
          if (file_data) {
            Object.keys(file_data).length &&
              Object.keys(file_data).map((key: string, index) => {
                switch (key) {
                  case UploadTypeEnum.ProofDisbursement:
                    file_data[key].map((data: any) => {
                      createFile(data.file).then((blob: any) => {
                        blob.lastModifiedDate = new Date();
                        const src = data.file as string;
                        blob.name = src.substr(
                          src.lastIndexOf("/") + 1,
                          src.length
                        );

                        const index = file_data.proof_of_disbursement.findIndex(
                          (el) => el.file_id === data.file_id
                        );
                        file_data.proof_of_disbursement[index] = {
                          file_id: data.file_id,
                          file_type_id: data.file_type_id,
                          file: blob as File,
                          ext: data.ext,
                          fileName: data.file,
                        };
                      });
                    });

                    break;
                }
              });
          }

          tranche_details.documents = file_data;
          trancheDetailsData[i] = tranche_details;
        }

        setfirsttrancheslist(trancheDetailsData);
        calculateApprovedAmountFirst(trancheDetailsData);
      });
    }

    if (application_Id != 0) {
      let tranche_parameters = {
        application_id: application_Id,
        order_by: "ASC",
        application_index: "F1",
      };

      post(`${GET_ALL_TRANCHES}`, tranche_parameters).then((response: any) => {
        if (!response || !response.data) return;
        setTrancheDataDESC(response.data.data);
      });
    }
  };

  const getReapplyAllTranches = (application_Id: number) => {
    if (application_Id != 0) {
      let tranche_parameters = {
        application_id: application_Id,
        order_by: "DESC",
        application_index: "R1",
      };

      post(`${GET_ALL_TRANCHES}`, tranche_parameters).then((response: any) => {
        // console.log("response","totalamount");
        if (!response || !response.data.data) return;
        var sum2 = 0;
        {
          for (let i = 0; i < response.data.data?.length; i++) {
            if (response.data.data[i].tranches_status_label === "Disbursed") {
              sum2 = sum2 + response.data.data[i].fund_released;
              setTotalDisbursedAmount2(sum2);
            }
          }
        }
        var ReapplytrancheDetailsData: Array<TrancheDetail> =
          response.data.data;

        for (var i = 0; i < ReapplytrancheDetailsData.length; i++) {
          var tranche_details = ReapplytrancheDetailsData[i];
          const file_data = tranche_details.documents;

          if (file_data) {
            Object.keys(file_data).length &&
              Object.keys(file_data).map((key: string, index) => {
                switch (key) {
                  case UploadTypeEnum.ProofDisbursement:
                    file_data[key].map((data: any) => {
                      createFile(data.file).then((blob: any) => {
                        blob.lastModifiedDate = new Date();
                        const src = data.file as string;
                        blob.name = src.substr(
                          src.lastIndexOf("/") + 1,
                          src.length
                        );

                        const index = file_data.proof_of_disbursement.findIndex(
                          (el) => el.file_id === data.file_id
                        );
                        file_data.proof_of_disbursement[index] = {
                          file_id: data.file_id,
                          file_type_id: data.file_type_id,
                          file: blob as File,
                          ext: data.ext,
                          fileName: data.file,
                        };
                      });
                    });

                    break;
                }
              });
          }

          tranche_details.documents = file_data;
          ReapplytrancheDetailsData[i] = tranche_details;
        }

        setReapplytrancheslist(ReapplytrancheDetailsData);
        calculateApprovedAmountSecond(ReapplytrancheDetailsData);
      });
    }

    // if (application_Id != 0) {
    //   let tranche_parameters = {
    //     application_id: application_Id,
    //     order_by: 'ASC',
    //   };

    //   post(`${GET_ALL_TRANCHES_NODE}`, tranche_parameters).then((response: any) => {
    //     if (!response || !response.data.data) return;
    //     setReapplyTrancheDataDESC(response.data.data);
    //     console.log(response.data.data);
    //   });
    // }
  };

  const getActiveTrancheRequest = (application_Id: number) => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    // let tranche_request = {
    //   application_id: application_Id,
    // };
    var payload = {
      application_id: application_Id,
      logged_user_id: userInfo.id,
    };

    post(GET_ACTIVE_TRANCHE_REQUEST, payload).then(async (response: any) => {
      if (!response || !response.data) return;
      // console.log(response.data.,"data@@@@@@@@@@@")

      if (response.data.result === "success") {
        const tranches = [
          {
            label: response.data.data[0].tranch_id,
            value: response.data.data[0].tranche_number,
          },
        ];

        if (response.data.data[0].tranches_status_label == "Requested") {
          setErrMessage(
            "Selected Tranche is already in requested, Please disbursed the same to request the next tranche. "
          );
        } else {
          setErrMessage("");
        }

        let trancheDetails = JSON.parse(JSON.stringify(trancheRequest));
        console.log("trancheDetails", trancheDetails);
        trancheDetails["application_id"] = response.data.data[0].application_id;
        trancheDetails["tranche_id"] = response.data.data[0].tranch_id;
        trancheDetails["tranche_number"] = response.data.data[0].tranche_number;
        trancheDetails["incubator_application_no"] =
          response.data.data[0].incubator_application_no;
        trancheDetails["amount_required"] = Number(
          response.data.data[0].funds_requested
        ).toFixed();
        trancheDetails["cash_in_hand"] = response.data.data[0].cash_in_hand;

        if (
          response.data.data[0].funds_requested != null &&
          Number(response.data.data[0].funds_requested) > 0 &&
          response.data.data[0].cash_in_hand == 1
        ) {
          setIsEnable(true);
        }
        setActiveTranches(tranches);
        setTrancheRequest(trancheDetails);
        bindFiles(response, trancheDetails);
      }
    });
  };

  const getReapplyActiveTrancheRequest = (application_Id: number) => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    const userId = userInfo.id;
    const UserRole = userInfo.roles;
    const requestPayload = {
      logged_user_id: userId,
      application_id: application_Id,
      // "user_role":UserRole
    };
    // let tranche_request = {
    //   application_id: application_Id,
    // };

    post(`${GET_ACTIVE_TRANCHE_REQUEST_NODE}`, requestPayload).then(
      async (response: any) => {
        if (!response || !response.data) return;

        if (response.data.result === "success") {
          const tranches = [
            {
              label: response.data.data[0].tranch_id,
              value: response.data.data[0].tranche_number,
            },
          ];

          if (response.data.data[0].tranches_status_label == "Requested") {
            setReapplyErrMessage(
              "Selected Tranche is already in requested, Please disbursed the same to request the next tranche. "
            );
          } else {
            setReapplyErrMessage("");
          }

          let trancheDetails = JSON.parse(
            JSON.stringify(ReapplyTrancheRequest)
          );
          trancheDetails["application_id"] =
            response.data.data[0].application_id;
          trancheDetails["tranche_id"] = response.data.data[0].tranch_id;
          trancheDetails["tranche_number"] =
            response.data.data[0].tranche_number;
          trancheDetails["amount_required"] = Number(
            response.data.data[0].funds_requested
          ).toFixed();
          trancheDetails["cash_in_hand"] = response.data.data[0].cash_in_hand;
          trancheDetails["incubator_application_no"] =
            response.data.data[0].incubator_application_no;
          if (
            Number(response.data.data[0].funds_requested) > 0 &&
            response.data.data[0].cash_in_hand == 1
          ) {
            setReapplyIsEnable(true);
          }
          setReapplyActiveTranches(tranches);
          setReapplyTrancheRequest(trancheDetails);
          ReapplybindFiles(response, trancheDetails);
        }
      }
    );
  };

  const bindFiles = (response: any, trancheReq: TrancheRequest) => {
    const file_data = response.data.data.documents;
    // incubator = JSON.parse(JSON.stringify(incubator));
    if (file_data) {
      Object.keys(file_data).length &&
        Object.keys(file_data).map((key: string) => {
          switch (key) {
            case UploadTypeEnum.QuarterlyReport:
              file_data[key].map((data: any) => {
                createFile(data.file).then((blob: any) => {
                  blob.lastModifiedDate = new Date();
                  const src = data.file as string;
                  blob.name = src.substr(src.lastIndexOf("/") + 1, src.length);

                  const file: TrancheFileUploadModel =
                    new TrancheFileUploadModel(
                      data.file_id,
                      data.file_type_id,
                      blob as File,
                      data.ext
                    );

                  const index = trancheReq.quarterly_report.findIndex(
                    (el: any) => el.file_id === data.file_id
                  );

                  if (index == -1) {
                    trancheReq.quarterly_report = [
                      ...trancheReq.quarterly_report,
                      file,
                    ];

                    setTrancheRequest((prevState) => {
                      const updatedFileOther = {
                        ...prevState,
                        quarterly_report: [
                          ...trancheReq.quarterly_report,
                          //file,
                        ],
                      };
                      return updatedFileOther;
                    });
                  }
                });
              });

              break;

            case UploadTypeEnum.SupportingDocuments:
              file_data[key].map((data: any) => {
                createFile(data.file).then((blob: any) => {
                  blob.lastModifiedDate = new Date();
                  const src = data.file as string;
                  blob.name = src.substr(src.lastIndexOf("/") + 1, src.length);

                  const file: TrancheFileUploadModel =
                    new TrancheFileUploadModel(
                      data.file_id,
                      data.file_type_id,
                      blob as File,
                      data.ext
                    );

                  const index = trancheReq.supporting_documents.findIndex(
                    (el: any) => el.file_id === data.file_id
                  );

                  if (index == -1) {
                    trancheReq.supporting_documents = [
                      ...trancheReq.supporting_documents,
                      file,
                    ];

                    setTrancheRequest((prevState) => {
                      const updatedFileOther = {
                        ...prevState,
                        supporting_documents: [
                          ...trancheReq.supporting_documents,
                          // file,
                        ],
                      };
                      return updatedFileOther;
                    });
                  }
                });
              });

              break;
          }
        });
    }
  };

  const ReapplybindFiles = (response: any, trancheReq: TrancheRequest) => {
    const file_data = response.data.data.documents;
    // incubator = JSON.parse(JSON.stringify(incubator));
    if (file_data) {
      Object.keys(file_data).length &&
        Object.keys(file_data).map((key: string) => {
          switch (key) {
            case UploadTypeEnum.QuarterlyReport:
              file_data[key].map((data: any) => {
                createFile(data.file).then((blob: any) => {
                  blob.lastModifiedDate = new Date();
                  const src = data.file as string;
                  blob.name = src.substr(src.lastIndexOf("/") + 1, src.length);

                  const file: TrancheFileUploadModel =
                    new TrancheFileUploadModel(
                      data.file_id,
                      data.file_type_id,
                      blob as File,
                      data.ext
                    );

                  const index = trancheReq.quarterly_report.findIndex(
                    (el: any) => el.file_id === data.file_id
                  );

                  if (index == -1) {
                    trancheReq.quarterly_report = [
                      ...trancheReq.quarterly_report,
                      file,
                    ];

                    setReapplyTrancheRequest((prevState) => {
                      const updatedFileOther = {
                        ...prevState,
                        quarterly_report: [
                          ...trancheReq.quarterly_report,
                          //file,
                        ],
                      };
                      return updatedFileOther;
                    });
                  }
                });
              });

              break;

            case UploadTypeEnum.SupportingDocuments:
              file_data[key].map((data: any) => {
                createFile(data.file).then((blob: any) => {
                  blob.lastModifiedDate = new Date();
                  const src = data.file as string;
                  blob.name = src.substr(src.lastIndexOf("/") + 1, src.length);

                  const file: TrancheFileUploadModel =
                    new TrancheFileUploadModel(
                      data.file_id,
                      data.file_type_id,
                      blob as File,
                      data.ext
                    );

                  const index = trancheReq.supporting_documents.findIndex(
                    (el: any) => el.file_id === data.file_id
                  );

                  if (index == -1) {
                    trancheReq.supporting_documents = [
                      ...trancheReq.supporting_documents,
                      file,
                    ];

                    setReapplyTrancheRequest((prevState) => {
                      const updatedFileOther = {
                        ...prevState,
                        supporting_documents: [
                          ...trancheReq.supporting_documents,
                          // file,
                        ],
                      };
                      return updatedFileOther;
                    });
                  }
                });
              });

              break;
          }
        });
    }
  };
  // console.log("response.data", "resDataaa")
  const getAccountDetails = () => {
    post(`${GET_BANK_DETAILS}`, { logged_user_id: userInfo.id }).then(
      (response: any) => {
        if (!response || !response.data) return;

        setSubsequentDtls(response.data.Subsequentfiles[0]);

        if (
          response.data != undefined &&
          response.data.bankDetails != undefined &&
          response.data.bankDetails.length != 0
        ) {
          setAccountDetails(response.data.bankDetails[0]);

          setUploadedSanction([response.data.bankDetails[0].sanction]);
          setUploadedtra([response.data.bankDetails[0].tra]);

          if (response.data.bankDetails.pan_card != undefined) {
            createFile(response.data.bankDetails.pan_card.file).then(
              (blob: any) => {
                blob.lastModifiedDate = new Date();
                const src = response.data.bankDetails.pan_card.file as string;
                blob.name = src.substr(src.lastIndexOf("/") + 1, src.length);
                var file_data: FileUploadModelTranche =
                  new FileUploadModelTranche(
                    response.data.bankDetails.pan_card.file_id,
                    response.data.bankDetails.pan_card.file_type_id,
                    blob as File,
                    response.data.bankDetails.pan_card.file.ext,
                    ""
                  );

                var uploadedPanCard: Array<FileUploadModelTranche> = [
                  file_data,
                ];
                setUploadedPan(uploadedPanCard);
              }
            );
          }
          if (
            response.data.bankDetails.darpan !== undefined
            // && response.data.bankDetails.darpan.length != 0
          ) {
            createFile(response.data.bankDetails.darpan.file).then(
              (blob: any) => {
                blob.lastModifiedDate = new Date();
                const src = response.data.bankDetails.darpan.file as string;
                blob.name = src.substr(src.lastIndexOf("/") + 1, src.length);
                var file_data: FileUploadModelTranche =
                  new FileUploadModelTranche(
                    response.data.bankDetails.darpan.file_id,
                    response.data.bankDetails.darpan.file_type_id,
                    blob as File,
                    response.data.bankDetails.darpan.file.ext,
                    ""
                  );

                var uploadDarpanString: Array<FileUploadModelTranche> = [
                  file_data,
                ];
                setUploadedDarpan(uploadDarpanString);
              }
            );
          }

          // else{setUploadedPan([]);}

          if (
            response.data.bankDetails.cancelled_cheque != undefined &&
            response.data.bankDetails.cancelled_cheque.file != undefined
          ) {
            createFile(response.data.bankDetails.cancelled_cheque.file).then(
              (blob: any) => {
                blob.lastModifiedDate = new Date();
                const src = response.data.bankDetails.cancelled_cheque
                  .file as string;
                blob.name = src.substr(src.lastIndexOf("/") + 1, src.length);
                var file_data_ch: FileUploadModelTranche =
                  new FileUploadModelTranche(
                    response.data.bankDetails.cancelled_cheque.file_id,
                    response.data.bankDetails.cancelled_cheque.file_type_id,
                    blob as File,
                    response.data.bankDetails.cancelled_cheque.file.ext,
                    ""
                  );

                var uploadedCh: Array<FileUploadModelTranche> = [file_data_ch];
                setUploadedCheque(uploadedCh);
              }
            );
          }
          // else{setUploadedCheque([]);}
        }

        let is_disable_account_details = JSON.parse(
          JSON.stringify(isDisabledAccountDetails)
        );

        //Once account details are entered cn not edit the same
        if (
          response?.data.bankDetails.bank_account_holder_name != undefined &&
          response?.data.bankDetails.bank_account_holder_name != "" &&
          response?.data.bankDetails.bank_account_holder_name != null
        ) {
          is_disable_account_details["bank_account_holder_name"] = true;
        } else {
          is_disable_account_details["bank_account_holder_name"] = false;
        }

        if (
          response.data.bankDetails.bank_name != "" &&
          response.data.bankDetails.bank_name != undefined &&
          response.data.bankDetails.bank_name != null
        ) {
          is_disable_account_details["bank_name"] = true;
        }

        if (
          response.data.bankDetails.account_number != "" &&
          response.data.bankDetails.account_number != undefined &&
          response.data.bankDetails.account_number != null
        ) {
          is_disable_account_details["account_number"] = true;
        }

        if (
          response.data.bankDetails.account_type != "" &&
          response.data.bankDetails.account_type != undefined &&
          response.data.bankDetails.account_type != null
        ) {
          is_disable_account_details["account_type"] = true;
        }

        if (
          response.data.bankDetails.bank_ifsc_code != "" &&
          response.data.bankDetails.bank_ifsc_code != undefined &&
          response.data.bankDetails.bank_ifsc_code != null
        ) {
          is_disable_account_details["bank_ifsc_code"] = true;
        }

        if (
          response.data.bankDetails.bank_micr_code != "" &&
          response.data.bankDetails.bank_micr_code != undefined &&
          response.data.bankDetails.bank_micr_code != null
        ) {
          is_disable_account_details["bank_micr_code"] = true;
        }
        if (
          response.data.bankDetails.branch_name != "" &&
          response.data.bankDetails.branch_name != undefined &&
          response.data.bankDetails.branch_name != null
        ) {
          is_disable_account_details["branch_name"] = true;
        }
        if (
          response.data.bankDetails.branch_address != "" &&
          response.data.bankDetails.branch_address != undefined &&
          response.data.bankDetails.branch_address != null
        ) {
          is_disable_account_details["branch_address"] = true;
        }

        if (
          response.data.bankDetails.pan_card != "" &&
          response.data.bankDetails.pan_card != undefined &&
          response.data.bankDetails.pan_card != null
        ) {
          is_disable_account_details["pan_card"] = true;
        }

        if (
          response.data.bankDetails.cancelled_cheque != "" &&
          response.data.bankDetails.cancelled_cheque != undefined &&
          response.data.bankDetails.cancelled_cheque != null
        ) {
          is_disable_account_details["cancelled_cheque"] = true;
        }
        if (
          response.data.bankDetails.darpan != "" &&
          response.data.bankDetails.darpan != undefined &&
          response.data.bankDetails.darpan != null
        ) {
          is_disable_account_details["darpan"] = true;
        }
        setIsDisabledAccountDetails(is_disable_account_details);

        ReapplygetAccountDetails();
      }
    );
  };

  const ReapplygetAccountDetails = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    const userId = userInfo.id;
    post(`${GET_BANK_DETAILS_REAPPLY_NODE}`, { logged_user_id: userId }).then(
      (response: any) => {
        if (!response || !response.data) return;
        console.log(response.data, "dataaReapply");
        setReapplyAccountDataLength(response.data.data);
        if (response.data.data.length > 0) {
          setReapplyAccountData(response.data.data[0]);
          // console.log(ReapplyAccountData);
        }

        // if (
        //   response.data != undefined &&
        //   response.data.data != undefined
        // ) {
        //   setAccountDetails(response.data.data[0]);
        //   console.log(accountDetailsData);

        //   if (response.data.bankDetails.pan_card != undefined) {
        //     createFile(response.data.bankDetails.pan_card.file).then((blob: any) => {
        //       blob.lastModifiedDate = new Date();
        //       const src = response.data.bankDetails.pan_card.file as string;
        //       blob.name = src.substr(src.lastIndexOf('/') + 1, src.length);
        //       var file_data: FileUploadModelTranche = new FileUploadModelTranche(
        //         response.data.bankDetails.pan_card.file_id,
        //         response.data.bankDetails.pan_card.file_type_id,
        //         blob as File,
        //         response.data.bankDetails.pan_card.file.ext, ""
        //       );

        //       var uploadedPanCard: Array<FileUploadModelTranche> = ([file_data]);
        //       setUploadedPan(uploadedPanCard);
        //     });
        //   }
        //   if (response.data.bankDetails.darpan !== undefined && response.data.bankDetails.darpan.length != 0) {
        //     createFile(response.data.bankDetails.darpan.file).then((blob: any) => {
        //       blob.lastModifiedDate = new Date();
        //       const src = response.data.bankDetails.darpan.file as string;
        //       blob.name = src.substr(src.lastIndexOf('/') + 1, src.length);
        //       var file_data: FileUploadModelTranche = new FileUploadModelTranche(
        //         response.data.bankDetails.darpan.file_id,
        //         response.data.bankDetails.darpan.file_type_id,
        //         blob as File,
        //         response.data.bankDetails.darpan.file.ext, ""
        //       );

        //       var uploadDarpanString: Array<FileUploadModelTranche> = ([file_data]);
        //       setUploadedDarpan(uploadDarpanString);
        //     });
        //   }
        //   // else{setUploadedPan([]);}

        //   if (response.data.bankDetails.cancelled_cheque != undefined && response.data.bankDetails.cancelled_cheque.file != undefined) {
        //     createFile(response.data.bankDetails.cancelled_cheque.file).then((blob: any) => {
        //       blob.lastModifiedDate = new Date();
        //       const src = response.data.bankDetails.cancelled_cheque.file as string;
        //       blob.name = src.substr(src.lastIndexOf('/') + 1, src.length);
        //       var file_data_ch: FileUploadModelTranche = new FileUploadModelTranche(
        //         response.data.bankDetails.cancelled_cheque.file_id,
        //         response.data.bankDetails.cancelled_cheque.file_type_id,
        //         blob as File,
        //         response.data.bankDetails.cancelled_cheque.file.ext, ""
        //       );

        //       var uploadedCh: Array<FileUploadModelTranche> = ([file_data_ch]);
        //       setUploadedCheque(uploadedCh);
        //     });

        //   }
        //   // else{setUploadedCheque([]);}

        // }

        // let is_disable_account_details = JSON.parse(
        //   JSON.stringify(isDisabledAccountDetails)
        // );

        //Once account details are entered cn not edit the same

        // if (
        //   (response.data.bankDetails.bank_account_holder_name != '' &&
        //     response.data.bankDetails.bank_account_holder_name != undefined) &&
        //   response.data.bankDetails.bank_account_holder_name != null
        // ) {
        //   is_disable_account_details['bank_account_holder_name'] = true;
        // }
        // else { is_disable_account_details['bank_account_holder_name'] = false; }

        // if (
        //   response.data.bankDetails.bank_name != '' &&
        //   response.data.bankDetails.bank_name != undefined &&
        //   response.data.bankDetails.bank_name != null
        // ) {
        //   is_disable_account_details['bank_name'] = true;
        // }

        // if (
        //   response.data.bankDetails.account_number != '' &&
        //   response.data.bankDetails.account_number != undefined &&
        //   response.data.bankDetails.account_number != null
        // ) {
        //   is_disable_account_details['account_number'] = true;
        // }

        // if (
        //   response.data.bankDetails.account_type != '' &&
        //   response.data.bankDetails.account_type != undefined &&
        //   response.data.bankDetails.account_type != null
        // ) {
        //   is_disable_account_details['account_type'] = true;
        // }

        // if (
        //   response.data.bankDetails.bank_ifsc_code != '' &&
        //   response.data.bankDetails.bank_ifsc_code != undefined &&
        //   response.data.bankDetails.bank_ifsc_code != null
        // ) {
        //   is_disable_account_details['bank_ifsc_code'] = true;
        // }

        // if (
        //   response.data.bankDetails.bank_micr_code != '' &&
        //   response.data.bankDetails.bank_micr_code != undefined &&
        //   response.data.bankDetails.bank_micr_code != null
        // ) {
        //   is_disable_account_details['bank_micr_code'] = true;
        // }
        // if (
        //   response.data.bankDetails.branch_name != '' &&
        //   response.data.bankDetails.branch_name != undefined &&
        //   response.data.bankDetails.branch_name != null
        // ) {
        //   is_disable_account_details['branch_name'] = true;
        // }
        // if (
        //   response.data.bankDetails.branch_address != '' &&
        //   response.data.bankDetails.branch_address != undefined &&
        //   response.data.bankDetails.branch_address != null
        // ) {
        //   is_disable_account_details['branch_address'] = true;
        // }

        // if (
        //   response.data.bankDetails.pan_card != '' &&
        //   response.data.bankDetails.pan_card != undefined &&
        //   response.data.bankDetails.pan_card != null
        // ) {
        //   is_disable_account_details['pan_card'] = true;
        // }

        // if (
        //   response.data.bankDetails.cancelled_cheque != '' &&
        //   response.data.bankDetails.cancelled_cheque != undefined &&
        //   response.data.bankDetails.cancelled_cheque != null
        // ) {
        //   is_disable_account_details['cancelled_cheque'] = true;
        // }

        // setIsDisabledAccountDetails(is_disable_account_details);
      }
    );
  };

  const storeAccountDetails = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);

    // alert("hy 2")
    // console.log(accountDetailsData.pan_card," accountDetailsData.pan_card")
    if (
      accountDetailsData.bank_account_holder_name == "" ||
      accountDetailsData.bank_account_holder_name == null
    ) {
      setFailDialogData({
        header: "",
        text: "Please Enter Bank Account Holder Name",
      });
      setShowIncubatorFailureModal1(true);
    }
    // }else if (typeof accountDetailsData.pan_card.file === 'object' && Object.keys(accountDetailsData.pan_card.file).length === 0){
    //   setFailDialogData({
    //     header: '',
    //     text: "Please Upload Proof of PFMS Registration",
    //   });
    //   setShowIncubatorFailureModal1(true);
    // }else if (typeof accountDetailsData.cancelled_cheque.file === 'object' && Object.keys(accountDetailsData.cancelled_cheque.file).length === 0){
    //   setFailDialogData({
    //     header: '',
    //     text: "Please Upload Proof of TRA Account & ECS Mandate Form",
    //   });
    //   setShowIncubatorFailureModal1(true);
    // }else if (typeof accountDetailsData.darpan.file === 'object' && Object.keys(accountDetailsData.darpan.file).length === 0){
    //   setFailDialogData({
    //     header: '',
    //     text: "Please Upload Proof of DARPAN Registration",
    //   });
    //   setShowIncubatorFailureModal1(true);
    // }
    else if (
      accountDetailsData.bank_name == "" ||
      accountDetailsData.bank_name == null
    ) {
      setFailDialogData({
        header: "",
        text: "Please Enter Bank Name",
      });
      setShowIncubatorFailureModal1(true);
    } else if (
      accountDetailsData.branch_name == "" ||
      accountDetailsData.branch_name == null
    ) {
      setFailDialogData({
        header: "",
        text: "Please Enter Branch/Branch Code",
      });
      setShowIncubatorFailureModal1(true);
    } else if (
      accountDetailsData.account_type == "" ||
      accountDetailsData.account_type == null
    ) {
      setFailDialogData({
        header: "",
        text: "Please Select Account Type",
      });
      setShowIncubatorFailureModal1(true);
    } else if (
      accountDetailsData.account_number == "" ||
      accountDetailsData.account_number == null
    ) {
      setFailDialogData({
        header: "",
        text: "Please Enter Account Number",
      });
      setShowIncubatorFailureModal1(true);
    } else if (
      accountDetailsData.bank_ifsc_code == "" ||
      accountDetailsData.bank_ifsc_code == null
    ) {
      setFailDialogData({
        header: "",
        text: "Please Enter IFSC Code",
      });
      setShowIncubatorFailureModal1(true);
    } else if (
      accountDetailsData.bank_micr_code == "" ||
      accountDetailsData.bank_micr_code == null
    ) {
      setFailDialogData({
        header: "",
        text: "Please Enter MICR Code",
      });
      setShowIncubatorFailureModal1(true);
    } else if (
      accountDetailsData.branch_address == "" ||
      accountDetailsData.branch_address == null
    ) {
      setFailDialogData({
        header: "",
        text: "Please Enter Branch Address",
      });
      setShowIncubatorFailureModal1(true);
    } else {
      var bank_details = {
        bank_account_holder_name: accountDetailsData.bank_account_holder_name,
        bank_name: accountDetailsData.bank_name,
        account_number: accountDetailsData.account_number,
        account_type: accountDetailsData.account_type,
        bank_ifsc_code: accountDetailsData.bank_ifsc_code,
        bank_micr_code: accountDetailsData.bank_micr_code,
        branch_name: accountDetailsData.branch_name,
        branch_address: accountDetailsData.branch_address,
        incubator_id: userInfo.id,
      };
      setLoader(true);
      post(`${STORE_BANK_DETAILS}`, bank_details).then((response: any) => {
        setLoader(false);
        if (!response || !response.data) return;

        if (response.data == undefined) {
          setShowFailureModal(true);
        }
        if (response.data.result == "success") {
          setMessage("Account Details Submitted Successfully !!");
          setShowSuccessModal(true);
        }
        if (response.data.result == "failure") {
          setShowFailureValidationModal(true);
        }

        getAccountDetails();
      });
    }
  };

  const ReapplystoreAccountDetails = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    const userId = userInfo.id;

    // return;
    const formData = new FormData();
    formData.append("logged_user_id", userId);
    formData.append(
      "bank_account_holder_name",
      ReapplyAccountDataLength.length == 0
        ? accountDetailsData.bank_account_holder_name
        : ReapplyAccountData.bank_account_holder_name
    );
    formData.append(
      "bank_name",
      ReapplyAccountDataLength.length == 0
        ? accountDetailsData.bank_name
        : ReapplyAccountData.bank_name
    );
    formData.append(
      "account_number",
      ReapplyAccountDataLength.length == 0
        ? accountDetailsData.account_number
        : ReapplyAccountData.account_number
    );
    formData.append(
      "account_type",
      ReapplyAccountDataLength.length == 0
        ? accountDetailsData.account_type
        : ReapplyAccountData.account_type
    );
    formData.append(
      "bank_ifsc_code",
      ReapplyAccountDataLength.length == 0
        ? accountDetailsData.bank_ifsc_code
        : ReapplyAccountData.bank_ifsc_code
    );
    formData.append(
      "bank_micr_code",
      ReapplyAccountDataLength.length == 0
        ? accountDetailsData.bank_micr_code
        : ReapplyAccountData.bank_micr_code
    );
    formData.append(
      "branch_name",
      ReapplyAccountDataLength.length == 0
        ? accountDetailsData.branch_name
        : ReapplyAccountData.branch_name
    );
    formData.append(
      "branch_address",
      ReapplyAccountDataLength.length == 0
        ? accountDetailsData.branch_address
        : ReapplyAccountData.branch_address
    );
    // console.log("***************************************1189**********************",ReapplyAccountData)
    if (uploadFile.length == 0) {
      formData.append(
        "pan_card_location_get",
        accountDetailsData.pan_card_location
      );
    } else {
      formData.append("pan_card_location_get", "");
      // uploadFile.length != 0 && formData.append("pan_card_location", uploadFile[0]);
    }
    if (other_file.length == 0) {
      formData.append(
        "cancelled_cheque_location_get",
        accountDetailsData.cancelled_cheque_location
      );
    } else {
      formData.append("cancelled_cheque_location_get", "");
      // other_file.length != 0 && formData.append("cancelled_cheque_location", other_file[0]);
    }
    if (pan_file.length == 0) {
      formData.append(
        "darpan_location_get",
        accountDetailsData.darpan_location
      );
    } else {
      formData.append("darpan_location_get", "");
      // pan_file.length != 0 && formData.append("darpan_location", pan_file[0]);
    }
    if (sanction_file.length == 0) {
      formData.append(
        "sanction_file_get",
        accountDetailsData.sanction_location
      );
    } else {
      formData.append("sanctionOrder_Location", sanction_file[0]);
    }
    if (tra_file.length == 0) {
      formData.append(
        "tra_file_get",
        accountDetailsData.tra_agreement_location
      );
    } else {
      formData.append("traAgreement_Location", tra_file[0]);
    }
    // formData.append("pan_card_location", ReapplyAccountData.pan_card_location);
    // formData.append("cancelled_cheque_location", ReapplyAccountData.cancelled_cheque_location);
    // formData.append("darpan_location", ReapplyAccountData.darpan_location);
    // console.log(formData)
    // return;
    setLoader(true);
    post(`${STORE_BANK_DETAILS_Reapply_Node}`, formData).then(
      (response: any) => {
        setLoader(false);
        if (!response || !response.data) return;

        if (response.data == undefined) {
          setShowFailureModal(true);
        }
        if (response.data.result == "success") {
          setMessage("Account Details Submitted Successfully !!");
          setShowSuccessModal(true);
        }
        if (response.data.result == "failure") {
          setShowFailureValidationModal(true);
        }

        ReapplygetAccountDetails();
      }
    );
  };

  const history = useHistory();

  const onRequestTranche = () => {
    console.log("trancheRequest", trancheRequest);
    const funds_requested_amount =
      trancheRequest.tranche_number == "Tranche 1"
        ? getTranche1_amnt
        : trancheRequest.tranche_number == "Tranche 2"
        ? getTranche2_amnt
        : trancheRequest.tranche_number == "Tranche 3"
        ? getTranche3_amnt
        : "0";
    trancheRequest.tranche_number == "Tranche 1"
      ? history.push({
          pathname: `/first_subsequent_tranche/${application_id}/${trancheRequest.tranche_id}/${funds_requested_amount}/${trancheRequest.cash_in_hand}/${trancheRequest.tranche_number}/F1/${trancheRequest.incubator_application_no}`,
        })
      : history.push({
          pathname: `/subsequent_tranche/${application_id}/${trancheRequest.tranche_id}/${funds_requested_amount}/${trancheRequest.cash_in_hand}/${trancheRequest.tranche_number}/F1/${trancheRequest.incubator_application_no}`,
        });
  };
  const onReapplyRequestTranche = () => {
    const funds_requested_amount =
      ReapplyTrancheRequest.tranche_number == "Tranche 1"
        ? getReTranche1_amnt
        : ReapplyTrancheRequest.tranche_number == "Tranche 2"
        ? getReTranche2_amnt
        : ReapplyTrancheRequest.tranche_number == "Tranche 3"
        ? getReTranche3_amnt
        : "0";

    ReapplyTrancheRequest.tranche_number == "Tranche 1"
      ? history.push({
          pathname: `/first_subsequent_tranche/${ReapplyTrancheRequest.application_id}/${ReapplyTrancheRequest.tranche_id}/${funds_requested_amount}/${ReapplyTrancheRequest.cash_in_hand}/${ReapplyTrancheRequest.tranche_number}/R1/${ReapplyTrancheRequest.incubator_application_no}`,
        })
      : history.push({
          pathname: `/subsequent_tranche/${ReapplyTrancheRequest.application_id}/${ReapplyTrancheRequest.tranche_id}/${funds_requested_amount}/${ReapplyTrancheRequest.cash_in_hand}/${ReapplyTrancheRequest.tranche_number}/R1/${ReapplyTrancheRequest.incubator_application_no}`,
        });
  };
  const storeTrancheRequest = () => {
    // if (trancheRequest.amount_required > 0) {
    const data_input = {
      application_id: application_id,
      tranche_id: trancheRequest.tranche_id,
      funds_requested:
        // trancheRequest.amount_required
        trancheRequest.tranche_number == "Tranche 1"
          ? getTranche1_amnt
          : trancheRequest.tranche_number == "Tranche 2"
          ? getTranche2_amnt
          : trancheRequest.tranche_number == "Tranche 3"
          ? getTranche3_amnt
          : "0",
      cash_in_hand: trancheRequest.cash_in_hand,
    };
    setLoader(true);
    post(STORE_TRANCHE_REQUEST, data_input).then((response: any) => {
      setLoader(false);
      if (!response || !response.data || response.data == "") return;
      if (response.data != undefined && response.data != "") {
        if (response.data.result == "success") {
          setMessage("Tranche Request Submitted Successfully !!");
          setShowSuccessModal(true);

          history.push({
            pathname: "/incubator_view_funddetails",
          });
          window.location.reload();
          // setRenderState(!renderState)
        } else {
          setShowFailureModal(true);
        }
      }
    });

    getActiveTrancheRequest(application_id);
    getAllTranches(application_id);
    // }
  };

  const ReapplystoreTrancheRequest = () => {
    // if (ReapplyTrancheRequest.amount_required > 0) {
    const data_input = {
      application_id: ReApply_application_id,
      tranche_id: ReapplyTrancheRequest.tranche_id,

      funds_requested:
        // ReapplyTrancheRequest.amount_required
        ReapplyTrancheRequest.tranche_number == "Tranche 1"
          ? getReTranche1_amnt
          : ReapplyTrancheRequest.tranche_number == "Tranche 2"
          ? getReTranche2_amnt
          : ReapplyTrancheRequest.tranche_number == "Tranche 3"
          ? getReTranche3_amnt
          : "0",

      cash_in_hand: ReapplyTrancheRequest.cash_in_hand,
    };
    // return;
    setLoader(true);
    post(STORE_TRANCHE_REQUEST_NODE, data_input).then((response: any) => {
      setLoader(false);
      if (!response || !response.data || response.data == "") return;
      if (response.data != undefined && response.data != "") {
        if (response.data.result == "success") {
          setMessage("Tranche Request Submitted Successfully !!");
          setShowSuccessModal(true);

          history.push({
            pathname: "/incubator_view_funddetails",
          });
          window.location.reload();
        } else {
          setShowFailureModal(true);
        }
      }
    });

    getReapplyActiveTrancheRequest(ReApply_application_id);
    getReapplyAllTranches(ReApply_application_id);
    // }
  };

  /** upload the files */
  const onFileUploadTranche = (files: Array<File>, type: string) => {
    for (const file of files) {
      var fileData = [];
      var index = 0;
      if (type == "quarterly_reports") {
        fileData = trancheRequest.quarterly_report;
        index = trancheRequest.quarterly_report.findIndex(
          (el: any) => el.file.name === file.name
        );
      }
      if (type == "supporting_documents") {
        fileData = trancheRequest.supporting_documents;
        index = trancheRequest.supporting_documents.findIndex(
          (el: any) => el.file.name === file.name
        );
      }

      if (index == -1) {
        if (file.type === "application/pdf") {
          // console.log(trancheRequest,"toString!!!")

          // console.log(trancheRequest.tranche_id.toString(),"toString!!!")
          const formData = new FormData();
          // Update the formData object
          formData.append("file", file, file.name);
          formData.append("tranche_id", trancheRequest.tranche_id.toString());
          formData.append("file_type", type);
          formData.append("user_Role", userInfo.roles);
          formData.append("logged_user_id", userInfo.id);
          post(TRANCHE_UPLOAD_DOCUMENT, formData).then((response: any) => {
            if (!response.data) return;

            setApplicationId(trancheRequest.application_id);
            // console.log(response.data.data.status == "true","dat&&&&&")
            if (response.data.data.status == "true") {
              setMessage("Tranche Document Uploaded Successfully..!");
              // setShowSuccessModal(true);
              //bind files
              createFile(
                `${File_node_url}` + response.data.data.file.substr(1)
              ).then((blob: any) => {
                blob.lastModifiedDate = new Date();
                const src = response.data.data.file as string;
                blob.name = src.substr(src.lastIndexOf("/") + 1, src.length);
                const ext = src.substr(src.lastIndexOf(".") + 1, src.length);
                const file: TrancheFileUploadModel = new TrancheFileUploadModel(
                  response.data.data.file_id,
                  0,
                  blob as File,
                  ext
                );

                if (type == "quarterly_reports") {
                  const index = trancheRequest.quarterly_report.findIndex(
                    (el: any) => el.file_id === response.data.data.file_id
                  );

                  if (index == -1) {
                    trancheRequest.quarterly_report = [
                      ...trancheRequest.quarterly_report,
                      file,
                    ];

                    setTrancheRequest((prevState) => {
                      const updatedFileOther = {
                        ...prevState,
                        quarterly_report: [
                          ...trancheRequest.quarterly_report,
                          // file,
                        ],
                      };
                      return updatedFileOther;
                    });
                  }
                }

                if (type == "supporting_documents") {
                  const index = trancheRequest.supporting_documents.findIndex(
                    (el: any) => el.file_id === response.data.data.file_id
                  );

                  if (index == -1) {
                    trancheRequest.supporting_documents = [
                      ...trancheRequest.supporting_documents,
                      file,
                    ];

                    setTrancheRequest((prevState) => {
                      const updatedFileOther = {
                        ...prevState,
                        supporting_documents: [
                          ...trancheRequest.supporting_documents,
                          // file,
                        ],
                      };
                      return updatedFileOther;
                    });
                  }
                }
              });

              //getActiveTrancheRequest(trancheRequest.application_id);
            } else {
              // setShowFailureModal(true);
              // console.log(response.data, "data!!!!!!")
              setFailDialogData({
                header: "Oops",
                text: response.data.message,
              });
              setShowIncubatorFailureModal1(true);
              return;
            }
          });
        }
      }
    }
  };

  const onReapplyFileUploadTranche = (files: Array<File>, type: string) => {
    for (const file of files) {
      var fileData = [];
      var index = 0;
      if (type == "quarterly_reports") {
        fileData = ReapplyTrancheRequest.quarterly_report;
        index = ReapplyTrancheRequest.quarterly_report.findIndex(
          (el: any) => el.file.name === file.name
        );
      }
      if (type == "supporting_documents") {
        fileData = ReapplyTrancheRequest.supporting_documents;
        index = ReapplyTrancheRequest.supporting_documents.findIndex(
          (el: any) => el.file.name === file.name
        );
      }

      if (index == -1) {
        if (file.type === "application/pdf") {
          const formData = new FormData();
          // Update the formData object
          formData.append("file", file, file.name);
          formData.append(
            "tranche_id",
            ReapplyTrancheRequest.tranche_id.toString()
          );
          formData.append("file_type", type);
          post(TRANCHE_UPLOAD_DOCUMENT, formData).then((response: any) => {
            if (!response.data) return;

            setReapplyApplicationId(ReapplyTrancheRequest.application_id);

            if (response.data.result.status == true) {
              setMessage("Tranche Document Uploaded Successfully..!");
              // setShowSuccessModal(true);
              //bind files
              createFile(response.data.result.file).then((blob: any) => {
                blob.lastModifiedDate = new Date();
                const src = response.data.result.file as string;
                blob.name = src.substr(src.lastIndexOf("/") + 1, src.length);
                const ext = src.substr(src.lastIndexOf(".") + 1, src.length);
                const file: TrancheFileUploadModel = new TrancheFileUploadModel(
                  response.data.result.file_id,
                  0,
                  blob as File,
                  ext
                );

                if (type == "quarterly_reports") {
                  const index =
                    ReapplyTrancheRequest.quarterly_report.findIndex(
                      (el: any) => el.file_id === response.data.result.file_id
                    );

                  if (index == -1) {
                    ReapplyTrancheRequest.quarterly_report = [
                      ...ReapplyTrancheRequest.quarterly_report,
                      file,
                    ];

                    setReapplyTrancheRequest((prevState) => {
                      const updatedFileOther = {
                        ...prevState,
                        quarterly_report: [
                          ...ReapplyTrancheRequest.quarterly_report,
                          // file,
                        ],
                      };
                      return updatedFileOther;
                    });
                  }
                }

                if (type == "supporting_documents") {
                  const index =
                    ReapplyTrancheRequest.supporting_documents.findIndex(
                      (el: any) => el.file_id === response.data.result.file_id
                    );

                  if (index == -1) {
                    ReapplyTrancheRequest.supporting_documents = [
                      ...ReapplyTrancheRequest.supporting_documents,
                      file,
                    ];

                    setReapplyTrancheRequest((prevState) => {
                      const updatedFileOther = {
                        ...prevState,
                        supporting_documents: [
                          ...ReapplyTrancheRequest.supporting_documents,
                          // file,
                        ],
                      };
                      return updatedFileOther;
                    });
                  }
                }
              });

              //getActiveTrancheRequest(ReapplyTrancheRequest.application_id);
            } else {
              setShowFailureModal(true);
            }
          });
        }
      }
    }
  };
  const getFileDetails = (file: any, key: string) => {
    let file_name = "";
    file_name =
      file != undefined && file != null && file.length > 1
        ? file.substr(file.lastIndexOf("/") + 1, file.length)
        : "";

    const file_type =
      file != undefined && file != null && file.length > 1
        ? file.substr(file.lastIndexOf(".") + 1, file.length)
        : "";

    if (key == "name") return file_name;
    if (key == "file") return file != undefined ? file.toString() : "";
    if (key == "type") return file != undefined ? file_type.toString() : "";
  };
  /** upload the files */
  const onFileUploadAccount = (files: Array<File>, type: string) => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);

    debugger;
    let indexes: Array<string> = [];

    for (let file of files) {
      const fileData = accountDetailsData as any;

      const formData = new FormData();
      formData.append("incubator_id", userInfo.id);
      formData.append("file", file, file.name);
      formData.append("file_type", type);
      post(ACCOUNT_DOCUMENT_UPLOAD, formData).then((response: any) => {
        if (!response.data) return;
        if (response.data.result.status == true) {
          setMessage("Account Document Uploaded Successfully..!");
          setShowSuccessModal(true);

          //bind files
          createFile(response.data.result.file).then((blob: any) => {
            blob.lastModifiedDate = new Date();
            const src = response.data.result.file as string;
            blob.name = src.substr(src.lastIndexOf("/") + 1, src.length);
            const ext = src.substr(src.lastIndexOf(".") + 1, src.length);
            const file: AccountFileUploadModel = new AccountFileUploadModel(
              blob as File
            );

            if (type == "tra_agreement_file") {
              const index =
                accountDetailsData.tra_agreement_name == blob.name ? 0 : -1;

              if (index == -1) {
                accountDetailsData.tra = file;
              }

              setAccountDetails((prevState) => {
                const updatedFileOther = {
                  ...prevState,
                  tra: accountDetailsData.tra,
                  tra_agreement_name: blob.name,
                };
                return updatedFileOther;
              });
            }
            if (type == "sanction_order") {
              const index = accountDetailsData.sanction == blob.name ? 0 : -1;

              if (index == -1) {
                accountDetailsData.sanction = file;
              }

              setAccountDetails((prevState) => {
                const updatedFileOther = {
                  ...prevState,
                  sanction_order: accountDetailsData.sanction,
                  sanction_order_name: blob.name,
                };
                return updatedFileOther;
              });
            }
            if (type == "pan_card") {
              const index =
                accountDetailsData.pan_card_name == blob.name ? 0 : -1;

              if (index == -1) {
                accountDetailsData.pan_card = file;
              }

              setAccountDetails((prevState) => {
                const updatedFileOther = {
                  ...prevState,
                  pan_card: accountDetailsData.pan_card,
                  pan_card_name: blob.name,
                };
                return updatedFileOther;
              });
            }

            if (type == "cancelled_cheque") {
              const index =
                accountDetailsData.cancelled_cheque_name == blob.name ? 0 : -1;

              if (index == -1) {
                accountDetailsData.cancelled_cheque = file;

                setAccountDetails((prevState) => {
                  const updatedFileOther = {
                    ...prevState,
                    ccancelled_cheque: accountDetailsData.cancelled_cheque,
                    cancelled_cheque_name: blob.name,
                  };
                  return updatedFileOther;
                });
              }
            }

            if (type == "darpan") {
              const index =
                accountDetailsData.darpan_name == blob.name ? 0 : -1;

              if (index == -1) {
                accountDetailsData.darpan = file;

                setAccountDetails((prevState) => {
                  const updatedFileOther = {
                    ...prevState,
                    darpan: accountDetailsData.darpan,
                    darpan_name: blob.name,
                  };
                  return updatedFileOther;
                });
              }
            }
          });
        }
      });
    }

    if (indexes.length) {
      indexes.map((ind) => {
        const position: number = files.findIndex((file) => file.name === ind);
        files.splice(position, 1);
      });
    }
  };

  const onReapplyFileUploadAccount = (files: Array<File>, type: string) => {
    // debugger;
    let indexes: Array<string> = [];

    for (const file of files) {
      switch (type) {
        case "file":
          setUploadFile([...uploadFile, file]);
          break;
        case "other_file":
          setOtherFile([...other_file, file]);
          break;
        case "pan_file":
          setPanFile([...pan_file, file]);
          break;
        case "sanction_file":
          setSanctionFile([...sanction_file, file]);
          break;
        case "tra_file":
          setTraFile([...tra_file, file]);
          break;
      }
    }

    if (indexes.length) {
      indexes.map((ind) => {
        const position: number = files.findIndex((file) => file.name === ind);
        files.splice(position, 1);
      });
    }
  };

  const [ReapplyAccountDataLength, setReapplyAccountDataLength] = useState("");
  const [showEditSuccessModal, setShowSuccessModal] = useState(false);
  const [showEditSuccessModal1, setShowSuccessModal1] = useState(false);
  const [showEditFailureModal, setShowFailureModal] = useState(false);
  const [renderState, setRenderState] = useState(true);
  // const [failDialogData, setFailDialogData] = useState({
  //   header: '',
  //   text: '',
  // });
  const [showEditFailureValidationModal, setShowFailureValidationModal] =
    useState(false);
  const [showEditFailureModalAccount, setShowFailureAccountModal] =
    useState(false);
  const dismissModal = () => {
    setShowSuccessModal(false);
    setShowFailureModal(false);
    setShowFailureAccountModal(false);
  };

  const dismissModal_refresh = () => {
    window.location.reload();
  };

  const onFileUpload = (files: Array<File>, type: string) => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);

    let indexes: Array<string> = [];
    debugger;
    setUploadedFile([]);
    for (let file of files) {
      if (file.type === "application/pdf") {
        const formData = new FormData();
        // Update the formData object
        formData.append("incubator_id", userInfo.id);
        formData.append("file", file, file.name);
        formData.append("file_type", type);
        // formData.append("meeting_id", meetingIdFilter);
        post(ACCOUNT_DOCUMENT_UPLOAD, formData).then((response: any) => {
          if (!response.data) return;
          if (response.data.result == "success") {
            setMessage("Account Document Uploaded Successfully..!");
            // setShowSuccessModal(true);
            // setShowIncubatorSuccessModal(true);
            // getStartupMeetingDetails();
          } else {
            setShowFailureAccountModal(true);
            // setShowIncubatorFailureModal(true);
          }
        });
      } else {
        indexes.push(file.name);
      }
    }
  };

  const onFileDelete = () => {
    // post(STARTUP_DOCUMENT_REMOVE, { meeting_id: meetingIdFilter }).then(
    //   (response: any) => {
    //     if (!response.data) return;
    //     if (response.data.result.status == true) {
    //       setDialogData({
    //         header: "Thank You!",
    //         text: "Document Deleted Successfully !!!",
    //       });
    //       setShowIncubatorSuccessModal(true);
    //       getStartupMeetingDetails();
    //       setMeetingPath("");
    //     } else {
    //       setFailDialogData({
    //         header: "Oops ! Something went wrong,",
    //         text: "Please Try Again !!",
    //       });
    //       setShowIncubatorFailureModal(true);
    //     }
    //   }
    // );
  };

  // Triggers when delete file is clicked
  const onFileDeleteAccount = (file: File, type: string) => {
    // console.log("file", file, type)
    setShowFailureAccountModal(true);
  };

  const onReapplyFileDeleteAccount = (type: string) => {
    switch (type) {
      case "file":
        setUploadFile([]);
        break;
      case "other_file":
        setOtherFile([]);
        break;
      case "pan_file":
        setPanFile([]);
        break;
      case "sanction_file":
        setSanctionFile([]);
        break;
      case "tra_file":
        setTraFile([]);
        break;
    }
  };

  const onFileDeleteTranche = (file: File, type: string) => {
    const { quarterly_report, supporting_documents } = trancheRequest;
    let remainingFiles: Array<TrancheFileUploadModel> = [];
    let fileToBeDeleted: any;

    switch (type) {
      case UploadTypeEnum.QuarterlyReport:
        fileToBeDeleted = quarterly_report.find(
          (uploadedFile: TrancheFileUploadModel) => uploadedFile.file === file
        );

        if (!fileToBeDeleted) return;
        remainingFiles = quarterly_report.filter(
          (uf: TrancheFileUploadModel) => uf.file !== file
        );

        break;

      case UploadTypeEnum.SupportingDocuments:
        fileToBeDeleted = supporting_documents.find(
          (uploadedFile: TrancheFileUploadModel) => uploadedFile.file === file
        );

        if (!fileToBeDeleted) return;
        remainingFiles = supporting_documents.filter(
          (uf: TrancheFileUploadModel) => uf.file !== file
        );

        break;
    }
    // delete the file
    post(TRANCHE_REMOVE_DOCUMENT, {
      application_id: trancheRequest.application_id,
      file_id: fileToBeDeleted.file_id,
      tranche_id: trancheRequest.tranche_id,
    }).then((response: any) => {
      if (response.data.result.status == true) {
        setMessage("Tranche Document Removed Successfully..!");
        setShowSuccessModal(true);
        if (type == UploadTypeEnum.QuarterlyReport) {
          trancheRequest.quarterly_report = remainingFiles;
        }

        if (type == UploadTypeEnum.SupportingDocuments) {
          trancheRequest.supporting_documents = remainingFiles;
        }
        setTrancheRequest(trancheRequest);
      } else {
        setShowFailureModal(true);
      }

      //getActiveTrancheRequest(trancheRequest.application_id);
    });
  };

  const onReapplyFileDeleteTranche = (file: File, type: string) => {
    const { quarterly_report, supporting_documents } = ReapplyTrancheRequest;
    let remainingFiles: Array<TrancheFileUploadModel> = [];
    let fileToBeDeleted: any;

    switch (type) {
      case UploadTypeEnum.QuarterlyReport:
        fileToBeDeleted = quarterly_report.find(
          (uploadedFile: TrancheFileUploadModel) => uploadedFile.file === file
        );

        if (!fileToBeDeleted) return;
        remainingFiles = quarterly_report.filter(
          (uf: TrancheFileUploadModel) => uf.file !== file
        );

        break;

      case UploadTypeEnum.SupportingDocuments:
        fileToBeDeleted = supporting_documents.find(
          (uploadedFile: TrancheFileUploadModel) => uploadedFile.file === file
        );

        if (!fileToBeDeleted) return;
        remainingFiles = supporting_documents.filter(
          (uf: TrancheFileUploadModel) => uf.file !== file
        );

        break;
    }
    // delete the file
    post(TRANCHE_REMOVE_DOCUMENT, {
      application_id: ReapplyTrancheRequest.application_id,
      file_id: fileToBeDeleted.file_id,
      tranche_id: ReapplyTrancheRequest.tranche_id,
    }).then((response: any) => {
      if (response.data.result.status == true) {
        setMessage("Tranche Document Removed Successfully..!");
        setShowSuccessModal(true);
        if (type == UploadTypeEnum.QuarterlyReport) {
          ReapplyTrancheRequest.quarterly_report = remainingFiles;
        }

        if (type == UploadTypeEnum.SupportingDocuments) {
          ReapplyTrancheRequest.supporting_documents = remainingFiles;
        }
        setReapplyTrancheRequest(ReapplyTrancheRequest);
      } else {
        setShowFailureModal(true);
      }

      //getActiveTrancheRequest(ReapplyTrancheRequest.application_id);
    });
  };
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [SelecteduplaodFilesLength, setSelecteduplaodFilesLength] =
    useState<any>();
  var fileArr: any;
  const handleFileUpload = (event: any) => {
    const { name, value, type } = event.target;
    // console.log(event)
    const files = event.target.files;

    Object.values(files).map((item: any) => {
      selectedFiles.push(item);
    });

    const allowedFileTypes = [
      "application/pdf",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    // const filteredFiles = files && files.filter((file:any) =>
    //     allowedFileTypes.includes(file.type)
    //   );

    // const fileObjects = filteredFiles.map((file: File) => ({
    //   name: file.name,
    //   file
    // }));

    // setSelectedFiles((prevFormData: any) => ({
    //       ...prevFormData,
    //       [name]: files,
    //   }));

    // setSelectedFiles(fileArr);
    upload_File();
  };
  const upload_File = () => {
    // console.log(selectedFiles);
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);

    let formDataArray = [];
    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("file", selectedFiles[i]);
      formDataArray.push(formData);
    }
    formData.append("incubtorId", userInfo.id);
    post(FUNDDETAILS_FILEUPLOAD, formData).then((response: any) => {
      // console.log(response.data);
      if (response.data.result == "success") {
        // console.log("ADHI");
        setDialogData({
          header: "Thank You!",
          text: response.data.message,
        });
        setShowIncubatorSuccessModal(true);
      } else {
        setFailDialogData({
          header: "",
          text: response.data.message,
        });
        setShowIncubatorFailureModal1(true);
      }
    });
  };
  const Get_upload_File = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);

    get(`${FUNDDETAILS_GET_API_FILEUPLOAD}/${userInfo.id}`).then((res: any) => {
      setSelecteduplaodFilesLength(res.data.data);
      // const fileObjects = res.data.data.map((file: File) => ({
      //       name: file.name,
      //       file
      //     }));
      //     setSelecteduplaodFiles((prevSelectedFiles) => [...prevSelectedFiles, ...fileObjects]);

      // res.data.data.map((ele:any) => {
      //   setSelecteduplaodFiles(ele);
      // })
    });
  };
  const calculateApprovedAmountSecond = (ReapplytrancheDetailsData: any) => {
    if (ReapplytrancheDetailsData.length > 0) {
      let grant_approved = 0;
      for (let i = 0; i < ReapplytrancheDetailsData.length; i++) {
        grant_approved =
          grant_approved +
          (ReapplytrancheDetailsData[i].fund_released == null
            ? 0
            : Number(ReapplytrancheDetailsData[i].fund_released));
      }

      setGrantApprovedtwo(Number(grant_approved.toFixed(2)));
    }
  };

  const calculateApprovedAmountFirst = (trancheDetailsData: any) => {
    if (trancheDetailsData.length > 0) {
      let grant_approved2 = 0;
      for (let i = 0; i < trancheDetailsData.length; i++) {
        grant_approved2 =
          grant_approved2 +
          (trancheDetailsData[i].fund_released == null
            ? 0
            : Number(trancheDetailsData[i].fund_released));
      }
      setGrantApprovedone(Number(grant_approved2.toFixed(2)));
    }
  };

  return (
    <>
      {loader && (
        <div className="spinner-border custom-spinner" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}
      <fieldset disabled={loader}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-1"></div>
            <div
              className="col-md-10 pt-2 pb-2 m-3"
              style={{ backgroundColor: "#f8f7f7" }}
            >
              <p className="dashboard pt-2 pl-2">Total Fund Details</p>
            </div>
            <div className="col-md-1"></div>
          </div>
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-5"></div>
            <div className="col-md-5 d-flex justify-content-end">
              <div>
                <input
                  type="file"
                  name="file"
                  id="upload"
                  multiple
                  hidden
                  accept=".pdf, .xls, .xlsx"
                  onChange={handleFileUpload}
                />
                <label className="Multiple_Upload_Files" htmlFor="upload">
                  Upload TRA Statement
                </label>
                <br />
                {SelecteduplaodFilesLength &&
                  SelecteduplaodFilesLength.length > 0 && (
                    <b
                      style={{
                        color: "red",
                        marginLeft: "60px",
                        cursor: "pointer",
                      }}
                      data-target="#Upload_Files_Popup"
                      data-toggle="modal"
                    >
                      Uploaded Files
                    </b>
                  )}
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
          <div className="row mt-5">
            <div className="col-md"></div>

            <div className="col-md left-align" style={{ maxWidth: "20%" }}>
              <span className="fund-amount-cur">
                &#8377;
                <label className="fund-amount-label-value">
                  {" "}
                  {changeNumberFormat(
                    Number(
                      (ReapplytrancheData.length != 0
                        ? ReapplytrancheData[0]?.total_approved_amt
                        : 0) +
                        (trancheData.length != 0
                          ? trancheData[0]?.total_approved_amt
                          : 0)
                    ),
                    2,
                    1
                  )}
                </label>
              </span>
              <br />
              <span className="funds-Label-heading">Total Grant Approved</span>
            </div>

            <div className="vl vl-mr"></div>

            <div className="col-md left-align" style={{ maxWidth: "20%" }}>
              <span className="fund-amount-cur">
                &#8377;
                <label className="fund-amount-label-value">
                  {" "}
                  {changeNumberFormat(
                    Number(
                      (ReapplytrancheData.length != 0
                        ? ReapplytrancheData[0]?.total_management_amount
                        : 0) +
                        (trancheData.length != 0
                          ? trancheData[0]?.total_management_amount
                          : 0)
                    ),
                    2,
                    1
                  )}{" "}
                </label>
              </span>
              <br />
              <span className="funds-Label-heading">Total Management Fees</span>
            </div>

            <div className="vl vl-mr"></div>
            <div className="col-md left-align" style={{ maxWidth: "20%" }}>
              <span className="fund-amount-cur">
                <label className="fund-amount-label-value">
                  {/* {((ReapplytrancheData.length != 0 && ReapplytrancheData[0].application_id != 0)? ReapplytrancheData[0].no_of_tranches :0 + trancheData[0].no_of_tranches)} */}
                  {(ReapplytrancheData.length != 0
                    ? ReapplytrancheData[0].no_of_tranches
                    : 0) +
                    (trancheData.length != 0
                      ? trancheData[0].no_of_tranches
                      : 0)}
                </label>
              </span>
              <br />
              <span className="funds-Label-heading">Total No. of Tranches</span>
            </div>

            <div className="vl vl-mr"></div>

            <div className="col-md left-align" style={{ maxWidth: "20%" }}>
              <span className="fund-amount-cur">
                &#8377;
                <label className="fund-amount-label-value">
                  {
                    totalDisbursedAmount1 > 0 && totalDisbursedAmount2 > 0
                      ? changeNumberFormat(
                          Number(totalDisbursedAmount1 + totalDisbursedAmount2),
                          2,
                          1
                        )
                      : totalDisbursedAmount2 > 0
                      ? changeNumberFormat(Number(totalDisbursedAmount2), 2, 1)
                      : totalDisbursedAmount1 > 0
                      ? changeNumberFormat(Number(totalDisbursedAmount1), 2, 1)
                      : "0"
                    // changeNumberFormat(
                    //   Number((ReapplytrancheData && total_grand_approvedtwo) + (trancheData && total_grand_approvedone)),
                    //   2,
                    //   1
                    // )
                  }{" "}
                </label>
              </span>
              <br />
              <span className="funds-Label-heading">
                Total Funds Disbursed to Incubator
              </span>
            </div>

            <div className="vl vl-mr"></div>

            <div className="col-md left-align" style={{ maxWidth: "20%" }}>
              <span className="fund-amount-cur">
                &#8377;
                <label className="fund-amount-label-value">
                  {changeNumberFormat(Number(Approved_Amount), 2, 1)}
                </label>
              </span>
              <br />
              <span className="funds-Label-heading">
                Total Approved Returns
              </span>
            </div>

            <div className="vl vl-mr"></div>

            <div className="col-md" style={{ maxWidth: "20%" }}>
              <span className="fund-amount-cur">
                &#8377;
                <label className="fund-amount-label-value">
                  {Availble_Amount! > 0
                    ? ReapplytrancheData[0]?.total_approved_amt > 0 ||
                      trancheData[0]?.total_approved_amt > 0
                      ? changeNumberFormat(
                          Number(Availble_Amount + Approved_Amount),
                          2,
                          1
                        )
                      : 0
                    : 0}
                </label>
              </span>
              <br />
              <span className="funds-Label-heading">
                Available Balance of the Incubator
              </span>
            </div>

            <div className="col-md"></div>
          </div>
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md mx-10">
              {/* Reapply Request Tranche */}

              {ReaapplyInclistData.length > 0 ? (
                <div>
                  <div
                    className="row pt-2 pb-2 m-3"
                    style={{ backgroundColor: "#f8f7f7" }}
                  >
                    <div className="col-md-12">
                      <p className="dashboard pt-2 pl-2">
                        2<sup>nd</sup> Application Fund Details
                      </p>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-md-9">
                      <ul className="nav nav-tabs chart-nav" role="tablist">
                        <li className="nav-item">
                          <a
                            className={
                              ReapplyactiveIndex == "3"
                                ? "nav-link active nav-incubator mt-7"
                                : "nav-link nav-incubator mt-7"
                            }
                            data-toggle="tab"
                            href="#tabs-3"
                            role="tab"
                            onClick={() => ReapplychangeActive("3")}
                          >
                            Fund Tranche Details
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={
                              ReapplyactiveIndex == "4"
                                ? "nav-link active nav-incubator mt-7"
                                : "nav-link nav-incubator mt-7"
                            }
                            data-toggle="tab"
                            href="#tabs-4"
                            role="tab"
                            onClick={() => ReapplychangeActive("4")}
                          >
                            My Account Details
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="application-incubator-hr mt--15">
                    <hr />
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-9"></div>
                        <div className="col-md-3">
                          <div className="btn-group mb-12">
                            {/* && showRequest */}
                            {/* {console.log(checkFundRelease,"****************************")} */}
                            {checkFundRelease ? (
                              ""
                            ) : ReapplytrancheData[0].total_approved_amt > 0 &&
                              ReapplyactiveIndex == "3" &&
                              ReapplytrancheData.length > 0 &&
                              ReapplytrancheData[0].no_of_tranches != null ? (
                              <button
                                type="button"
                                className="btn btn-sisfs btn-yellow-primary white-space-nowrap"
                                data-toggle="modal"
                                data-target="#ReapplyrequestTranche"
                              >
                                Request Tranche
                              </button>
                            ) : ReapplyactiveIndex == "4" ? (
                              <button
                                type="button"
                                className="btn btn-sisfs btn-yellow-primary white-space-nowrap"
                                data-toggle="modal"
                                data-target="#ReapplyEditAccountDetails"
                              >
                                Edit Account Details
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="tab-content">
                        {ReapplyactiveIndex == "3" ? (
                          <div
                            className={
                              ReapplyactiveIndex == "3"
                                ? "tab-pane active"
                                : "tab-pane"
                            }
                            id="tabs-3"
                          >
                            <ReapplyTrancheDetailsComponent
                              ReapplyTrancheRequest={ReapplyTrancheRequest}
                              trancheDataModel={ReapplytrancheData}
                              trancheDisbursedData={totalDisbursedAmount2}
                              trancheDataDESCModel={ReapplytrancheData}
                              trancheslist={Reapplytrancheslist}
                            />
                          </div>
                        ) : (
                          <div
                            className={
                              ReapplyactiveIndex == "4"
                                ? "tab-pane active"
                                : "tab-pane"
                            }
                            id="tabs-4"
                          >
                            <ReapplyAccountDetailsComponent
                              accountDetailsModel={accountDetailsData}
                              subsequentDetails={subsequentDetails}
                              ReapplyaccountDetailsModel={ReapplyAccountData}
                              ReapplyAccountDetailsLength={
                                ReapplyAccountDataLength
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <br />
              <br />

              {/* Request Tranche */}
              <div
                className="row pt-2 pb-2 m-3"
                style={{ backgroundColor: "#f8f7f7" }}
              >
                <div className="col-md-12">
                  <p className="dashboard pt-2 pl-2">
                    1<sup>st</sup> Application Fund Details
                  </p>
                </div>
              </div>

              <div className="row ">
                <div className="col-md-9">
                  <ul className="nav nav-tabs chart-nav" role="tablist">
                    <li className="nav-item">
                      <a
                        className={
                          activeIndex == "1"
                            ? "nav-link active nav-incubator mt-7"
                            : "nav-link nav-incubator mt-7"
                        }
                        data-toggle="tab-1"
                        href="#tabs-1"
                        role="tabone"
                        onClick={() => changeActive("1")}
                      >
                        Fund Tranche Details
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          activeIndex == "2"
                            ? "nav-link active nav-incubator mt-7"
                            : "nav-link nav-incubator mt-7"
                        }
                        data-toggle="tab-2"
                        href="#tabs-2"
                        role="tabtwo"
                        onClick={() => changeActive("2")}
                      >
                        My Account Details
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="application-incubator-hr mt--15">
                <hr />
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-9"></div>
                    <div className="col-md-3">
                      <div className="btn-group mb-12">
                        {trancheData[0].total_approved_amt > 0 &&
                        activeIndex == "1" &&
                        activeTranches.length > 0 ? (
                          <button
                            type="button"
                            className="btn btn-sisfs btn-yellow-primary white-space-nowrap"
                            data-toggle="modal"
                            data-target="#requestTranche"
                          >
                            Request Tranche
                          </button>
                        ) : activeIndex == "2" ? (
                          <button
                            type="button"
                            className="btn btn-sisfs btn-yellow-primary white-space-nowrap"
                            data-toggle="modal"
                            data-target="#editAccountDetails"
                          >
                            Edit Account Details
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="tab-content">
                    {activeIndex == "1" ? (
                      <div
                        className={
                          activeIndex == "1" ? "tab-pane active" : "tab-pane"
                        }
                        id="tabs-1"
                      >
                        <TrancheDetails
                          trancheRequest={trancheRequest}
                          trancheDataModel={trancheData}
                          trancheDataDESCModel={trancheDataDESC}
                          firsttranchesssslist={firsttrancheslist}
                        />
                      </div>
                    ) : (
                      <div
                        className={
                          activeIndex == "2" ? "tab-pane active" : "tab-pane"
                        }
                        id="tabs-2"
                      >
                        <AccountDetails
                          accountDetailsModel={accountDetailsData}
                          trancheStatusLabel={
                            trancheDataDESC[0].tranches_status_label
                          }
                          subsequentDetails={subsequentDetails}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* Uplod Files View Popup */}
              <div
                className="modal fade"
                id="Upload_Files_Popup"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
                data-backdrop="static"
                data-keyboard="false"
              >
                <div
                  className="modal-dialog modal-dialog-centered modal-lg"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header modal-header-border-none ml-18px">
                      <h4 style={{ marginTop: "10px" }}>Uploaded Files</h4>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true" data-dismiss="modal">
                          &times;
                        </span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="container">
                        {SelecteduplaodFilesLength &&
                          SelecteduplaodFilesLength.length > 0 && (
                            <div>
                              <div className="row text-center">
                                {SelecteduplaodFilesLength.map(
                                  (file: any, index: any) => (
                                    <div className="col-md-3 mt-5">
                                      {/* {console.log(file)} */}
                                      <img
                                        key={index}
                                        src={Allround}
                                        alt="SISFS Logo"
                                      />
                                      <br />
                                      {file.file}
                                      <div>
                                        {/* {console.log("!!!!!!!!!!",file)} */}
                                        <a
                                          href={file.file_path}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <img
                                            src={View}
                                            alt="SISFS Logo"
                                            style={{
                                              marginRight: "15px",
                                              cursor: "pointer",
                                              height: "17px",
                                            }}
                                          />
                                        </a>
                                        {/* <img src={Remove} alt="SISFS Logo" style={{ cursor: 'pointer' }} onClick={() => handleFileDelete(file.name)} /> */}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Request tranche model */}

              <div
                className="modal fade"
                id="requestTranche"
                role="dialog"
                aria-labelledby="exampleModalLabel1"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-slideout modal-md"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header modal-header-bottom pr-5">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body pl-5 pr-5 pt-2">
                      <div className="popup-head mb-2 ml-3">
                        <div className="main-heading">
                          {/* Request for the Subsequent Tranche */}
                          Tranche Request
                        </div>
                      </div>

                      <div className={errMessage != "" ? "div-disable" : ""}>
                        <label className="col-md-6 col-form-head">
                          Tranche Number
                        </label>
                        <div className="col-md-12 mb-4">
                          <div className="select-custom">
                            <select
                              className="form-control"
                              aria-label="Select Tranche"
                              id="tranche_id"
                              name="tranche_id"
                              value={trancheRequest.tranche_id}
                              onChange={handleChange}
                              disabled
                            >
                              <option value="">Select Tranche</option>
                              {activeTranches.map(({ label, value }) => (
                                <option key={value} value={label}>
                                  {value}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <label className="col-md-12 col-form-head">
                          Amount Required
                        </label>
                        <div className="col-md-12 mb-4">
                          <input
                            type="input"
                            placeholder="Enter Amount(&#8377;)"
                            name="amount_required"
                            className="form-control input-height"
                            value={
                              trancheRequest.tranche_number == "Tranche 1"
                                ? getTranche1_amnt
                                : trancheRequest.tranche_number == "Tranche 2"
                                ? getTranche2_amnt
                                : trancheRequest.tranche_number == "Tranche 3"
                                ? getTranche3_amnt
                                : ""
                            }
                            readOnly
                            onChange={handleChange}
                          />
                        </div>
                        {trancheRequest.tranche_number != "Tranche 1" && (
                          <div className="row pl-3 mb-2">
                            <div className="col-md-12">
                              <label className="col-form-head">
                                Is your Cash-in -Hand less than 10% of the total
                                commitment by EAC(including interest earned on
                                unutilized funds)?
                              </label>
                              <div className="toggle-btn-group">
                                <div className="btn-group btn-group-toggle ">
                                  <label
                                    className={
                                      trancheRequest.cash_in_hand === "1"
                                        ? "btn active"
                                        : "btn"
                                    }
                                  >
                                    <input
                                      type="radio"
                                      name="cash_in_hand"
                                      id="cash_in_hand_1"
                                      value="1"
                                      onChange={handleChange}
                                      checked={
                                        trancheRequest.cash_in_hand === "1"
                                      }
                                    />
                                    Yes
                                  </label>
                                  <label
                                    className={
                                      trancheRequest.cash_in_hand === "0"
                                        ? "btn active"
                                        : "btn"
                                    }
                                  >
                                    <input
                                      type="radio"
                                      name="cash_in_hand"
                                      id="cash_in_hand_2"
                                      value="0"
                                      onChange={handleChange}
                                      checked={
                                        trancheRequest.cash_in_hand === "0"
                                      }
                                    />
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="row pl-3">
                          <div className="col-md-12">
                            {errMessage != "" ? (
                              <span className="error-message">
                                &#42; {errMessage}
                              </span>
                            ) : (
                              ""
                            )}
                            {/* <br /> <br></br> */}
                            {/* {trancheRequest.amount_required <= 0 ? (
                              <span className="error-message">
                                &#42; Amount Required should be greater than zero.
                              </span>
                            ) : (
                              ''
                            )} */}
                            {/* <br></br> */}
                            <br />
                            {trancheRequest.tranche_number != "Tranche 1" &&
                            trancheRequest.cash_in_hand == "0" ? (
                              <span className="error-message">
                                &#42; You are not allowed to request tranche if
                                your cash in hand is more than 10% of the total
                                commitment by EAC.
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        {trancheRequest.tranche_number != "Tranche 1" && (
                          <div
                            style={{
                              backgroundColor: "rgb(248, 216, 116)",
                              borderRadius: "10px",
                              padding: "10px",
                              fontWeight: "500",
                            }}
                          >
                            <img
                              style={{ width: "70px" }}
                              src={flashIcon}
                              alt=""
                            />{" "}
                            <br />
                            <small style={{ fontWeight: "600" }}>
                              Before proceeding, please ensure that you have
                              updated the funding status for all your
                              beneficiary startups such as the total funds and
                              instrument approved, total funds disbursed,
                              details of tranches, and dates of fund disbursal.
                              This will streamline the subsequent tranche form
                              to gather only relevant information for further
                              processing of your application.
                            </small>
                          </div>
                        )}
                        <div className="application-incubator-hr">
                          <hr />
                        </div>

                        <div className="row pl-3">
                          <div className="col-md-6">
                            <button
                              className="btn btn-sisfs btn-transparent-primary full-width"
                              data-dismiss="modal"
                            >
                              CANCEL
                            </button>
                          </div>
                          <div className="col-md-6">
                            {trancheRequest.tranche_number == "Tranche 1" &&
                            trancheRequest.cash_in_hand === "0" ? (
                              <button
                                className="tertiary  btn-yellow-primary full-width"
                                data-dismiss="modal"
                                onClick={onRequestTranche}
                              >
                                Continue
                              </button>
                            ) : (trancheRequest.tranche_number != "Tranche 1" &&
                                trancheRequest.cash_in_hand === "0") ||
                              isEnable == false ? (
                              <button
                                disabled
                                className="tertiary  btn-yellow-primary full-width"
                                //
                                onClick={onRequestTranche}
                                // onClick={() => storeTrancheRequest()}
                              >
                                Continue
                                {/* Request */}
                              </button>
                            ) : (
                              <button
                                className="tertiary  btn-yellow-primary full-width"
                                data-dismiss="modal"
                                onClick={onRequestTranche}
                                // onClick={() => storeTrancheRequest()}
                              >
                                Continue
                                {/* Request */}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Re Applied Request tranche model */}

              <div
                className="modal fade"
                id="ReapplyrequestTranche"
                role="dialog"
                aria-labelledby="exampleModalLabel1"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-slideout modal-md"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header modal-header-bottom pr-5">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body pl-5 pr-5 pt-2">
                      <div className="popup-head mb-2 ml-3">
                        <div className="main-heading">
                          {/* Request for the Subsequent Tranche */}
                          Tranche Request
                        </div>
                      </div>

                      <div
                        className={ReapplyErrMessage != "" ? "div-disable" : ""}
                      >
                        <label className="col-md-6 col-form-head">
                          Tranche Number
                        </label>
                        <div className="col-md-12 mb-4">
                          <div className="select-custom">
                            <select
                              className="form-control"
                              aria-label="Select Tranche"
                              id="tranche_id"
                              name="tranche_id"
                              value={ReapplyTrancheRequest.tranche_id}
                              onChange={handleChangeReapply}
                              disabled
                            >
                              <option value="">Select Tranche</option>
                              {ReapplyActiveTranches.map(({ label, value }) => (
                                <option key={value} value={label}>
                                  {value}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <label className="col-md-12 col-form-head">
                          Amount Required
                        </label>
                        <div className="col-md-12 mb-4">
                          <input
                            type="input"
                            placeholder="Enter Amount(&#8377;)"
                            name="amount_required"
                            className="form-control input-height"
                            value={
                              ReapplyTrancheRequest.tranche_number ==
                              "Tranche 1"
                                ? getReTranche1_amnt
                                : ReapplyTrancheRequest.tranche_number ==
                                  "Tranche 2"
                                ? getReTranche2_amnt
                                : ReapplyTrancheRequest.tranche_number ==
                                  "Tranche 3"
                                ? getReTranche3_amnt
                                : ""
                            }
                            readOnly
                            onChange={handleChangeReapply}
                          />
                        </div>
                        {ReapplyTrancheRequest.tranche_number !=
                          "Tranche 1" && (
                          <div className="row pl-3 mb-2">
                            <div className="col-md-12">
                              <label className="col-form-head">
                                Is your Cash-in -Hand less than 10% of the total
                                commitment by EAC(including interest earned on
                                unutilized funds)?
                              </label>
                              <div className="toggle-btn-group">
                                <div className="btn-group btn-group-toggle ">
                                  <label
                                    className={
                                      ReapplyTrancheRequest.cash_in_hand === "1"
                                        ? "btn active"
                                        : "btn"
                                    }
                                  >
                                    <input
                                      type="radio"
                                      name="cash_in_hand"
                                      id="cash_in_hand_1"
                                      value="1"
                                      onChange={handleChangeReapply}
                                      checked={
                                        ReapplyTrancheRequest.cash_in_hand ===
                                        "1"
                                      }
                                    />
                                    Yes
                                  </label>
                                  <label
                                    className={
                                      ReapplyTrancheRequest.cash_in_hand === "0"
                                        ? "btn active"
                                        : "btn"
                                    }
                                  >
                                    <input
                                      type="radio"
                                      name="cash_in_hand"
                                      id="cash_in_hand_2"
                                      value="0"
                                      onChange={handleChangeReapply}
                                      checked={
                                        ReapplyTrancheRequest.cash_in_hand ===
                                        "0"
                                      }
                                    />
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="row pl-3">
                          <div className="col-md-12">
                            {ReapplyErrMessage != "" ? (
                              <span className="error-message">
                                &#42; {ReapplyErrMessage}
                              </span>
                            ) : (
                              ""
                            )}
                            {/* <br /> <br></br>
                            {ReapplyTrancheRequest.amount_required <= 0 ? (
                              <span className="error-message">
                                &#42; Amount Required should be greater than zero.
                              </span>
                            ) : (
                              ''
                            )}
                            <br></br>
                            <br /> */}
                            {/* ReapplyTrancheRequest.tranche_number != "Tranche 1" && */}
                            {ReapplyTrancheRequest.tranche_number !=
                              "Tranche 1" &&
                            ReapplyTrancheRequest.cash_in_hand == "0" ? (
                              <span className="error-message">
                                &#42; You are not allowed to request tranche if
                                your cash in hand is more than 10% of the total
                                commitment by EAC.
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        {ReapplyTrancheRequest.tranche_number !=
                          "Tranche 1" && (
                          <div
                            style={{
                              backgroundColor: "rgb(248, 216, 116)",
                              borderRadius: "10px",
                              padding: "10px",
                            }}
                          >
                            <img
                              style={{ width: "70px" }}
                              src={flashIcon}
                              alt=""
                            />{" "}
                            <br />
                            <small style={{ fontWeight: "600" }}>
                              Before proceeding, please ensure that you have
                              updated the funding status for all your
                              beneficiary startups such as the total funds and
                              instrument approved, total funds disbursed,
                              details of tranches, and dates of fund disbursal.
                              This will streamline the subsequent tranche form
                              to gather only relevant information for further
                              processing of your application.
                            </small>
                          </div>
                        )}
                        <div className="application-incubator-hr">
                          <hr />
                        </div>

                        <div className="row pl-3">
                          <div className="col-md-6">
                            <button
                              className="btn btn-sisfs btn-transparent-primary full-width"
                              data-dismiss="modal"
                            >
                              CANCEL
                            </button>
                          </div>
                          <div className="col-md-6">
                            {ReapplyTrancheRequest.tranche_number ==
                              "Tranche 1" &&
                            ReapplyTrancheRequest.cash_in_hand == "0" ? (
                              <button
                                className="tertiary  btn-yellow-primary full-width"
                                data-dismiss="modal"
                                onClick={onReapplyRequestTranche}
                              >
                                Continue
                              </button>
                            ) : (ReapplyTrancheRequest.tranche_number !=
                                "Tranche 1" &&
                                ReapplyTrancheRequest.cash_in_hand === "0") ||
                              ReapplyIsEnable == false ? (
                              <button
                                disabled
                                className="tertiary  btn-yellow-primary full-width"
                                onClick={onReapplyRequestTranche}
                              >
                                Continue
                              </button>
                            ) : (
                              <button
                                className="tertiary  btn-yellow-primary full-width"
                                data-dismiss="modal"
                                onClick={onReapplyRequestTranche}
                                // onClick={() => ReapplystoreTrancheRequest()}
                              >
                                {/* Request */}
                                Continue
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Edit Account Details */}
              <div
                className="modal fade"
                id="editAccountDetails"
                role="dialog"
                aria-labelledby="exampleModalLabel1"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-slideout modal-md"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header modal-header-bottom pr-5">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body pl-5 pr-5 pt-5">
                      <div className="popup-head mb-5 ml-3">
                        <div className="main-heading">
                          Edit Account Details{" "}
                        </div>
                      </div>
                      <label className="col-md-8 col-form-head">
                        Bank Account Holder Name
                      </label>
                      <div className="col-md-12 mb-4">
                        <input
                          type="input"
                          placeholder="Bank Account Holder Name"
                          name="bank_account_holder_name"
                          className="form-control input-height"
                          value={accountDetailsData.bank_account_holder_name}
                          onChange={handleChangeAccountDetails}
                        />
                      </div>
                      {/* <div className="col-md-12 mb-4">
                        <label className="col-md-12 col-form-head">
                          Proof of PFMS Registration
                        </label>
                        <br />
                        <button
                          className="btn btn-sisfs btn-transparent-primary"
                          onClick={() =>
                            setOpenUploadBlock({ PAN: true })
                          }
                        >
                          Upload
                        </button>
                        {((openUploadBlock
                          || uploadedPan.length != 0
                        ))
                          && (
                            <>
                              <span
                                className="close"
                                onClick={() =>
                                  setOpenUploadBlock({ PAN: false })
                                }
                              >
                                <i className="fa fa-close"></i>
                              </span>

                              <FileUploader
                                key={Math.random().toString()}
                                id={UploadTypeEnum.PAN}
                                information="Drag & Drop the file or Browse"
                                fileType="image/png, image/jpg, image/jpeg, application/pdf"
                                buttonLabel="Browse"
                                multiple={false}
                                enablePreview
                                IconComponent={() => (
                                  <span className="fa fa-cloud-upload"></span>
                                )}
                                removeIconClass=""

                                existingFiles={uploadedPan.map(
                                  (uploadedFile: FileUploadModelTranche) =>
                                    (uploadedFile.file)
                                )}

                                onUploadFinish={(files: Array<File>) => {
                                  onFileUploadAccount(files, UploadTypeEnum.PAN);
                                }}
                                onFileDelete={(deletedFile: File) =>
                                  onFileDeleteAccount(
                                    deletedFile,
                                    UploadTypeEnum.PAN
                                  )
                                }
                              />
                            </>
                          )}
                      </div>
                      <div className="col-md-12 mb-4">
                        <label className="col-md-12 col-form-head">
                          Proof of TRA Account & ECS Mandate Form
                        </label>
                        <br />
                        <button
                          className="btn btn-sisfs btn-transparent-primary"
                          onClick={() =>
                            setOpenUploadBlock({ CancelledCheque: true })
                          }
                        >
                          Upload
                        </button>

                        {((openUploadBlock || uploadedCheque.length != 0
                        ))
                          && (
                            <>
                              <span
                                className="close"
                                onClick={() =>
                                  setOpenUploadBlock({ CancelledCheque: false })
                                }
                              >
                                <i className="fa fa-close"></i>
                              </span>
                              <FileUploader
                                key={Math.random().toString()}
                                id={UploadTypeEnum.CancelledCheque}
                                information="Drag & Drop the file or Browse"
                                fileType="image/png, image/jpg, image/jpeg, application/pdf"
                                buttonLabel="Browse"
                                multiple={false}
                                enablePreview
                                IconComponent={() => (
                                  <span className="fa fa-cloud-upload"></span>
                                )}
                                removeIconClass=""
                                existingFiles={uploadedCheque.map(
                                  (uploadedFile: FileUploadModelTranche) =>
                                    (uploadedFile.file)
                                )}
                                onUploadFinish={(files: Array<File>) => {
                                  onFileUploadAccount(files, UploadTypeEnum.CancelledCheque);
                                }}
                                onFileDelete={(deletedFile: File) =>
                                  onFileDeleteAccount(
                                    deletedFile,
                                    UploadTypeEnum.CancelledCheque
                                  )
                                }
                              />
                            </>
                          )}
                      </div>
                      <div className="col-md-12 mb-4">
                        <label className="col-md-12 col-form-head">
                          Proof of DARPAN Registration
                        </label>
                        <br />
                        <button
                          className="btn btn-sisfs btn-transparent-primary"
                          onClick={() =>
                            setOpenUploadBlock({ uploadDarpan: true })
                          }
                        >
                          Upload
                        </button>
                        {((openUploadBlock
                          || uploadDarpan.length != 0
                        ))
                          && (
                            <>
                              <span
                                className="close"
                                onClick={() =>
                                  setOpenUploadBlock({ uploadDarpan: false })
                                }
                              >
                                <i className="fa fa-close"></i>
                              </span>
                              <FileUploader
                                key={Math.random().toString()}
                                id={UploadTypeEnum.Darpan}
                                information="Drag & Drop the file or Browse"
                                fileType="application/pdf"
                                buttonLabel="Browse"
                                multiple={false}
                                enablePreview
                                IconComponent={() => (
                                  <span className="fa fa-cloud-upload"></span>
                                )}
                                removeIconClass=""
                                existingFiles={uploadDarpan.map(
                                  (uploadedFile: FileUploadModelTranche) =>
                                    (uploadedFile.file)
                                )}
                                onUploadFinish={(files: Array<File>) => {
                                  onFileUploadAccount(files, UploadTypeEnum.Darpan);
                                }}
                                onFileDelete={(deletedFile: File) =>
                                  onFileDeleteAccount(
                                    deletedFile,
                                    UploadTypeEnum.Darpan
                                  )
                                }
                              />
                            </>
                          )}
                      </div> */}
                      <div className="col-md-12 mb-4">
                        <label className="col-md-12 col-form-head pl-0">
                          Sanction Order
                        </label>
                        <br />
                        <button
                          className="btn btn-sisfs btn-transparent-primary"
                          onClick={() =>
                            setOpenUploadBlock({ sanctionFile: true })
                          }
                        >
                          Upload
                        </button>
                        {openUploadBlock && openUploadBlock["sanctionFile"] && (
                          <>
                            <span
                              className="close"
                              onClick={() =>
                                setOpenUploadBlock({ sanctionFile: false })
                              }
                            >
                              <i className="fa fa-close"></i>
                            </span>
                            <FileUploader
                              key={Math.random().toString()}
                              id={UploadTypeEnum.sanctionOrder}
                              information="Drag & Drop the file or Browse"
                              fileType="application/pdf"
                              buttonLabel="Browse"
                              multiple={false}
                              enablePreview
                              IconComponent={() => (
                                <span className="fa fa-cloud-upload"></span>
                              )}
                              removeIconClass=""
                              existingFiles={uploadSanction.map(
                                (uploadedFile: FileUploadModelTranche) => ({
                                  name:
                                    (
                                      <a
                                        href={`${uploadedFile.file}`}
                                        target="_blank"
                                      >
                                        {getFileDetails(
                                          uploadedFile.file,
                                          "name"
                                        )}
                                      </a>
                                    ) || uploadedFile.file.name,
                                  url: uploadedFile.file,
                                })
                              )}
                              onUploadFinish={(files: Array<File>) => {
                                onFileUploadAccount(
                                  files,
                                  UploadTypeEnum.sanctionOrder
                                );
                              }}
                              onFileDelete={(deletedFile: File) =>
                                onFileDeleteAccount(
                                  deletedFile,
                                  UploadTypeEnum.sanctionOrder
                                )
                              }
                            />
                          </>
                        )}
                      </div>
                      <div className="col-md-12 mb-4">
                        <label className="col-md-12 col-form-head pl-0">
                          TRA Agreement
                        </label>
                        <br />
                        <button
                          className="btn btn-sisfs btn-transparent-primary"
                          onClick={() => setOpenUploadBlock({ traFile: true })}
                        >
                          Upload
                        </button>
                        {openUploadBlock && openUploadBlock["traFile"] && (
                          <>
                            <span
                              className="close"
                              onClick={() =>
                                setOpenUploadBlock({ traFile: false })
                              }
                            >
                              <i className="fa fa-close"></i>
                            </span>
                            <FileUploader
                              key={Math.random().toString()}
                              id={UploadTypeEnum.traAgreement}
                              information="Drag & Drop the file or Browse"
                              fileType="application/pdf"
                              buttonLabel="Browse"
                              multiple={false}
                              enablePreview
                              IconComponent={() => (
                                <span className="fa fa-cloud-upload"></span>
                              )}
                              removeIconClass=""
                              existingFiles={uploadtra.map(
                                (uploadedFile: FileUploadModelTranche) => ({
                                  name:
                                    (
                                      <a
                                        href={`${uploadedFile.file}`}
                                        target="_blank"
                                      >
                                        {getFileDetails(
                                          uploadedFile.file,
                                          "name"
                                        )}
                                      </a>
                                    ) || uploadedFile.file.name,
                                  url: uploadedFile.file,
                                })
                              )}
                              onUploadFinish={(files: Array<File>) => {
                                onFileUploadAccount(
                                  files,
                                  UploadTypeEnum.traAgreement
                                );
                              }}
                              onFileDelete={(deletedFile: File) =>
                                onFileDeleteAccount(
                                  deletedFile,
                                  UploadTypeEnum.traAgreement
                                )
                              }
                            />
                          </>
                        )}
                      </div>
                      <label className="col-md-6 col-form-head">
                        Bank Name
                      </label>
                      <div className="col-md-12 mb-4">
                        <input
                          type="input"
                          placeholder="Bank Name"
                          name="bank_name"
                          className="form-control input-height"
                          value={accountDetailsData.bank_name}
                          onChange={handleChangeAccountDetails}
                        />
                        {/* {isDisabledAccountDetails.bank_name == true ? (
                  <input
                    disabled
                    type="input"
                    placeholder="Bank Name"
                    name="bank_name"
                    className="form-control input-height"
                    value={accountDetailsData.bank_name}
                    onChange={handleChangeAccountDetails}
                  />
                ) : (
                  <input
                    type="input"
                    placeholder="Bank Name"
                    name="bank_name"
                    className="form-control input-height"
                    value={accountDetailsData.bank_name}
                    onChange={handleChangeAccountDetails}
                  />
                )} */}
                      </div>
                      <label className="col-md-12 col-form-head">
                        Branch/Branch Code
                      </label>
                      <div className="col-md-12 mb-4">
                        <input
                          type="input"
                          placeholder="Branch"
                          name="branch_name"
                          className="form-control input-height"
                          value={accountDetailsData.branch_name}
                          onChange={handleChangeAccountDetails}
                        />
                        {/* {isDisabledAccountDetails.branch_name == true ? (
                  <input
                    disabled
                    type="input"
                    placeholder="Branch"
                    name="branch_name"
                    className="form-control input-height"
                    value={accountDetailsData.branch_name}
                    onChange={handleChangeAccountDetails}
                  />
                ) : (
                  <input
                    type="input"
                    placeholder="Branch"
                    name="branch_name"
                    className="form-control input-height"
                    value={accountDetailsData.branch_name}
                    onChange={handleChangeAccountDetails}
                  />
                )} */}
                      </div>
                      <div className="row pl-3 mb-4">
                        <div className="col-md-12">
                          <label className="col-form-head">Account Type</label>
                          <select
                            className="form-control"
                            onChange={handleChangeAccountDetails}
                            name="account_type"
                            value={accountDetailsData.account_type}
                            aria-label="Select Account Type"
                          >
                            <option value="">
                              {/* selected */}
                              Select
                            </option>
                            <option value="1">Saving</option>
                            <option value="2">Current</option>
                            <option value="3">Salary</option>
                            <option value="4">Fixed Deposit</option>
                            <option value="5">Recurring Deposit</option>
                          </select>
                          {/* {isDisabledAccountDetails.account_type == true ? (
                    <select
                      disabled
                      className="form-control"
                      onChange={handleChangeAccountDetails}
                      name="account_type"
                      value={accountDetailsData.account_type}
                      aria-label="Select Account Type"
                    >
                      <option value="" selected>
                        Select
                      </option>
                      <option value="1">Saving</option>
                      <option value="2">Current</option>
                      <option value="3">Salary</option>
                      <option value="4">Fixed Deposit</option>
                      <option value="5">Recurring Deposit</option>
                    </select>
                  ) : (
                    <select
                      className="form-control"
                      onChange={handleChangeAccountDetails}
                      name="account_type"
                      value={accountDetailsData.account_type}
                      aria-label="Select Account Type"
                    >
                      <option value="" selected>
                        Select
                      </option>
                      <option value="1">Saving</option>
                      <option value="2">Current</option>
                      <option value="3">Salary</option>
                      <option value="4">Fixed Deposit</option>
                      <option value="5">Recurring Deposit</option>
                    </select>
                  )} */}
                          {/* <input
                  type="input"
                  placeholder="Account Type "
                  name="account_type"
                  className="form-control input-height"
                  value={accountDetailsData.account_type}
                  onChange={handleChangeAccountDetails}
                /> */}
                        </div>
                      </div>
                      <div className="row pl-3 mb-4">
                        <div className="col-md-12">
                          <label className="col-form-head">
                            Account Number
                          </label>
                          <input
                            type="input"
                            placeholder="Account Number "
                            name="account_number"
                            className="form-control input-height"
                            value={accountDetailsData.account_number}
                            onChange={handleChangeAccountDetails}
                          />
                          {/* {isDisabledAccountDetails.account_number == true ? (
                    <input
                      disabled
                      type="input"
                      placeholder="Account Number "
                      name="account_number"
                      className="form-control input-height"
                      value={accountDetailsData.account_number}
                      onChange={handleChangeAccountDetails}
                    />
                  ) : (
                    <input
                      type="input"
                      placeholder="Account Number "
                      name="account_number"
                      className="form-control input-height"
                      value={accountDetailsData.account_number}
                      onChange={handleChangeAccountDetails}
                    />
                  )} */}
                        </div>
                      </div>

                      <div className="row pl-3 mb-4">
                        <div className="col-md-12">
                          <label className="col-form-head">IFSC Code</label>
                          <br />
                          <input
                            type="input"
                            placeholder="IFSC Code"
                            name="bank_ifsc_code"
                            className="form-control input-height"
                            value={accountDetailsData.bank_ifsc_code}
                            onChange={handleChangeAccountDetails}
                          />
                          {/* {isDisabledAccountDetails.bank_ifsc_code == true ? (
                    <input
                      disabled
                      type="input"
                      placeholder="IFSC Code"
                      name="bank_ifsc_code"
                      className="form-control input-height"
                      value={accountDetailsData.bank_ifsc_code}
                      onChange={handleChangeAccountDetails}
                    />
                  ) : (
                    <input
                      type="input"
                      placeholder="IFSC Code"
                      name="bank_ifsc_code"
                      className="form-control input-height"
                      value={accountDetailsData.bank_ifsc_code}
                      onChange={handleChangeAccountDetails}
                    />
                  )} */}
                        </div>
                      </div>

                      <div className="row pl-3 mb-4">
                        <div className="col-md-12">
                          <label className="col-form-head">MICR Code</label>
                          <br />
                          <input
                            type="input"
                            placeholder="MICR Code"
                            name="bank_micr_code"
                            className="form-control input-height"
                            value={accountDetailsData.bank_micr_code}
                            onChange={handleChangeAccountDetails}
                          />
                          {/* {isDisabledAccountDetails.bank_micr_code == true ? (
                    <input
                      disabled
                      type="input"
                      placeholder="MICR Code"
                      name="bank_micr_code"
                      className="form-control input-height"
                      value={accountDetailsData.bank_micr_code}
                      onChange={handleChangeAccountDetails}
                    />
                  ) : (
                    <input
                      type="input"
                      placeholder="MICR Code"
                      name="bank_micr_code"
                      className="form-control input-height"
                      value={accountDetailsData.bank_micr_code}
                      onChange={handleChangeAccountDetails}
                    />
                  )} */}
                        </div>
                      </div>
                      <div className="row pl-3 mb-4">
                        <div className="col-md-12">
                          <label className="col-form-head">
                            Branch Address
                          </label>
                          <br />
                          <input
                            type="input"
                            placeholder="Branch Address"
                            name="branch_address"
                            className="form-control input-height"
                            value={accountDetailsData.branch_address}
                            onChange={handleChangeAccountDetails}
                          />
                          {/* {isDisabledAccountDetails.branch_address == true ? (
                    <input
                      disabled
                      type="input"
                      placeholder="Branch Address"
                      name="branch_address"
                      className="form-control input-height"
                      value={accountDetailsData.branch_address}
                      onChange={handleChangeAccountDetails}
                    />
                  ) : (
                    <input
                      type="input"
                      placeholder="Branch Address"
                      name="branch_address"
                      className="form-control input-height"
                      value={accountDetailsData.branch_address}
                      onChange={handleChangeAccountDetails}
                    />
                  )} */}
                        </div>
                      </div>

                      <div className="application-incubator-hr">
                        <hr />
                      </div>

                      <div className="row pl-3">
                        <div className="col-md-6">
                          <button
                            className="btn btn-sisfs btn-transparent-primary full-width"
                            data-dismiss="modal"
                          >
                            CANCEL
                          </button>
                        </div>
                        <div className="col-md-6">
                          <button
                            className="tertiary  btn-yellow-primary full-width"
                            onClick={() => storeAccountDetails()}
                          >
                            Save Account
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Reapply Edit Account Details */}
              <div
                className="modal fade"
                id="ReapplyEditAccountDetails"
                role="dialog"
                aria-labelledby="exampleModalLabel1"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-slideout modal-md"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header modal-header-bottom pr-5">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body pl-5 pr-5 pt-5">
                      <div className="popup-head mb-5 ml-3">
                        <div className="main-heading">
                          Edit Account Details{" "}
                        </div>
                      </div>
                      <label className="col-md-8 col-form-head">
                        Bank Account Holder Name
                      </label>
                      <div className="col-md-12 mb-4">
                        <input
                          type="input"
                          placeholder="Bank Account Holder Name"
                          name="bank_account_holder_name"
                          className="form-control input-height"
                          value={
                            ReapplyAccountDataLength.length == 0
                              ? accountDetailsData.bank_account_holder_name
                              : ReapplyAccountData.bank_account_holder_name
                          }
                          onChange={
                            ReapplyAccountDataLength.length == 0
                              ? handleChangeAccountDetails
                              : ReapplyhandleChangeAccountDetails
                          }
                        />
                        {/* {isDisabledAccountDetails.bank_account_holder_name == true ? (
                  <input
                    disabled
                    type="input"
                    placeholder="Bank Account Holder Name"
                    name="bank_account_holder_name"
                    className="form-control input-height"
                    value={accountDetailsData.bank_account_holder_name}
                    onChange={handleChangeAccountDetails}
                  />
                ) : (
                  <input
                    type="input"
                    placeholder="Bank Account Holder Name"
                    name="bank_account_holder_name"
                    className="form-control input-height"
                    value={accountDetailsData.bank_account_holder_name}
                    onChange={handleChangeAccountDetails}
                  />
                )} */}
                      </div>
                      {/* <div className="col-md-12 mb-4">
                        <label className="col-md-12 col-form-head">
                          Proof of PFMS Registration
                        </label>
                        <br />
                        <button
                          className="btn btn-sisfs btn-transparent-primary"
                          onClick={() => setOpenUploadBlock({ pfmsupload: true })}
                          disabled={false}
                        >
                          Upload
                        </button>
                        {(openUploadBlock && openUploadBlock["pfmsupload"])
                          && (
                            <>
                              <span
                                className="close"
                                onClick={() =>
                                  setOpenUploadBlock({ pfmsupload: false })
                                }
                              >
                                <i className="fa fa-close"></i>
                              </span>

                              <FileUploader
                                key={"pan_card_location"}
                                id={"pan_card_location"}
                                information="Drag & Drop the file or Browse"
                                fileType="image/png, image/jpg, image/jpeg, application/pdf"
                                buttonLabel="Browse"
                                multiple={false}
                                enablePreview
                                IconComponent={() => (
                                  <span className="fa fa-cloud-upload"></span>
                                )}
                                removeIconClass=""

                                existingFiles={uploadFile}

                                onUploadFinish={(files: Array<File>) => {
                                  onReapplyFileUploadAccount(files, "file");
                                }}
                                onFileDelete={(deletedFile: File) =>
                                  onReapplyFileDeleteAccount("file")
                                }
                              />
                            </>
                          )}
                      </div>
                      <div className="col-md-12 mb-4">
                        <label className="col-md-12 col-form-head">
                          Proof of TRA Account & ECS Mandate Form
                        </label>
                        <br />
                        <button
                          className="btn btn-sisfs btn-transparent-primary"
                          onClick={() =>
                            setOpenUploadBlock({ CancelledCheque: true })
                          }
                          disabled={false}
                        >
                          Upload
                        </button>
                        {(openUploadBlock && openUploadBlock["CancelledCheque"])
                          && (
                            <>
                              <span
                                className="close"
                                onClick={() =>
                                  setOpenUploadBlock({ CancelledCheque: false })
                                }
                              >
                                <i className="fa fa-close"></i>
                              </span>
                              <FileUploader
                                key={"cancelled_cheque_location"}
                                id={"cancelled_cheque_location"}
                                information="Drag & Drop the file or Browse"
                                fileType="image/png, image/jpg, image/jpeg, application/pdf"
                                buttonLabel="Browse"
                                multiple={false}
                                enablePreview
                                IconComponent={() => (
                                  <span className="fa fa-cloud-upload"></span>
                                )}
                                removeIconClass=""
                                existingFiles={other_file}
                                onUploadFinish={(files: Array<File>) => {
                                  onReapplyFileUploadAccount(files, 'other_file');
                                }}
                                onFileDelete={(deletedFile: File) =>
                                  onReapplyFileDeleteAccount('other_file')
                                }
                              />
                            </>
                          )}
                      </div>
                      <div className="col-md-12 mb-4">
                        <label className="col-md-12 col-form-head">
                          Proof of DARPAN Registration
                        </label>
                        <br />
                        <button
                          className="btn btn-sisfs btn-transparent-primary"
                          onClick={() =>
                            setOpenUploadBlock({ uploadDarpan: true })
                          }
                          disabled={false}
                        >
                          Upload
                        </button>
                        {((openUploadBlock
                          && openUploadBlock["uploadDarpan"]
                        ))
                          && (
                            <>
                              <span
                                className="close"
                                onClick={() =>
                                  setOpenUploadBlock({ uploadDarpan: false })
                                }
                              >
                                <i className="fa fa-close"></i>
                              </span>
                              <FileUploader
                                key={"darpan_location"}
                                id={"darpan_location"}
                                information="Drag & Drop the file or Browse"
                                fileType="application/pdf"
                                buttonLabel="Browse"
                                multiple={false}
                                enablePreview
                                IconComponent={() => (
                                  <span className="fa fa-cloud-upload"></span>
                                )}
                                removeIconClass=""
                                existingFiles={pan_file}
                                onUploadFinish={(files: Array<File>) => {
                                  onReapplyFileUploadAccount(files, "pan_file");
                                }}
                                onFileDelete={(deletedFile: File) => onReapplyFileDeleteAccount("pan_file")}
                              />
                            </>
                          )}
                      </div> */}
                      <div className="col-md-12 mb-4">
                        <label className="col-md-12 col-form-head">
                          Sanction Order
                        </label>
                        <br />
                        <button
                          className="btn btn-sisfs btn-transparent-primary"
                          onClick={() =>
                            setOpenUploadBlock({ sanctionOrder: true })
                          }
                          disabled={false}
                        >
                          Upload
                        </button>
                        {openUploadBlock &&
                          openUploadBlock["sanctionOrder"] && (
                            <>
                              <span
                                className="close"
                                onClick={() =>
                                  setOpenUploadBlock({ sanctionOrder: false })
                                }
                              >
                                <i className="fa fa-close"></i>
                              </span>
                              <FileUploader
                                key={"sanction_location"}
                                id={"sanction_location"}
                                information="Drag & Drop the file or Browse"
                                fileType="image/png, image/jpg, image/jpeg, application/pdf"
                                buttonLabel="Browse"
                                multiple={false}
                                enablePreview
                                IconComponent={() => (
                                  <span className="fa fa-cloud-upload"></span>
                                )}
                                removeIconClass=""
                                existingFiles={sanction_file}
                                onUploadFinish={(files: Array<File>) => {
                                  onReapplyFileUploadAccount(
                                    files,
                                    "sanction_file"
                                  );
                                }}
                                onFileDelete={(deletedFile: File) =>
                                  onReapplyFileDeleteAccount("sanction_file")
                                }
                              />
                            </>
                          )}
                      </div>
                      <div className="col-md-12 mb-4">
                        <label className="col-md-12 col-form-head">
                          TRA Agreement
                        </label>
                        <br />
                        <button
                          className="btn btn-sisfs btn-transparent-primary"
                          onClick={() => setOpenUploadBlock({ traFile: true })}
                          disabled={false}
                        >
                          Upload
                        </button>

                        {openUploadBlock && openUploadBlock["traFile"] && (
                          <>
                            <span
                              className="close"
                              onClick={() =>
                                setOpenUploadBlock({ traFile: false })
                              }
                            >
                              <i className="fa fa-close"></i>
                            </span>
                            <FileUploader
                              key={"tra_agreement_location"}
                              id={"tra_agreement_location"}
                              information="Drag & Drop the file or Browse"
                              fileType="image/png, image/jpg, image/jpeg, application/pdf"
                              buttonLabel="Browse"
                              multiple={false}
                              enablePreview
                              IconComponent={() => (
                                <span className="fa fa-cloud-upload"></span>
                              )}
                              removeIconClass=""
                              existingFiles={tra_file}
                              onUploadFinish={(files: Array<File>) => {
                                onReapplyFileUploadAccount(files, "tra_file");
                              }}
                              onFileDelete={(deletedFile: File) =>
                                onReapplyFileDeleteAccount("tra_file")
                              }
                            />
                          </>
                        )}
                      </div>
                      <label className="col-md-6 col-form-head">
                        Bank Name
                      </label>
                      <div className="col-md-12 mb-4">
                        <input
                          type="input"
                          placeholder="Bank Name"
                          name="bank_name"
                          className="form-control input-height"
                          // value={accountDetailsData.bank_name}
                          value={
                            ReapplyAccountDataLength.length == 0
                              ? accountDetailsData.bank_name
                              : ReapplyAccountData.bank_name
                          }
                          onChange={
                            ReapplyAccountDataLength.length == 0
                              ? handleChangeAccountDetails
                              : ReapplyhandleChangeAccountDetails
                          }
                        />
                        {/* {isDisabledAccountDetails.bank_name == true ? (
                  <input
                    disabled
                    type="input"
                    placeholder="Bank Name"
                    name="bank_name"
                    className="form-control input-height"
                    value={accountDetailsData.bank_name}
                    onChange={handleChangeAccountDetails}
                  />
                ) : (
                  <input
                    type="input"
                    placeholder="Bank Name"
                    name="bank_name"
                    className="form-control input-height"
                    value={accountDetailsData.bank_name}
                    onChange={handleChangeAccountDetails}
                  />
                )} */}
                      </div>
                      <label className="col-md-12 col-form-head">
                        Branch/Branch Code
                      </label>
                      <div className="col-md-12 mb-4">
                        <input
                          type="input"
                          placeholder="Branch"
                          name="branch_name"
                          className="form-control input-height"
                          // value={accountDetailsData.branch_name}
                          value={
                            ReapplyAccountDataLength.length == 0
                              ? accountDetailsData.branch_name
                              : ReapplyAccountData.branch_name
                          }
                          onChange={
                            ReapplyAccountDataLength.length == 0
                              ? handleChangeAccountDetails
                              : ReapplyhandleChangeAccountDetails
                          }
                        />
                        {/* {isDisabledAccountDetails.branch_name == true ? (
                  <input
                    disabled
                    type="input"
                    placeholder="Branch"
                    name="branch_name"
                    className="form-control input-height"
                    value={accountDetailsData.branch_name}
                    onChange={handleChangeAccountDetails}
                  />
                ) : (
                  <input
                    type="input"
                    placeholder="Branch"
                    name="branch_name"
                    className="form-control input-height"
                    value={accountDetailsData.branch_name}
                    onChange={handleChangeAccountDetails}
                  />
                )} */}
                      </div>
                      <div className="row pl-3 mb-4">
                        <div className="col-md-12">
                          <label className="col-form-head">Account Type</label>
                          <select
                            className="form-control"
                            onChange={
                              ReapplyAccountDataLength.length == 0
                                ? handleChangeAccountDetails
                                : ReapplyhandleChangeAccountDetails
                            }
                            name="account_type"
                            // value={accountDetailsData.account_type}
                            value={
                              ReapplyAccountDataLength.length == 0
                                ? accountDetailsData.account_type
                                : ReapplyAccountData.account_type
                            }
                            aria-label="Select Account Type"
                          >
                            <option value="">
                              {/* selected */}
                              Select
                            </option>
                            <option value="1">Saving</option>
                            <option value="2">Current</option>
                            <option value="3">Salary</option>
                            <option value="4">Fixed Deposit</option>
                            <option value="5">Recurring Deposit</option>
                          </select>
                          {/* {isDisabledAccountDetails.account_type == true ? (
                    <select
                      disabled
                      className="form-control"
                      onChange={handleChangeAccountDetails}
                      name="account_type"
                      value={accountDetailsData.account_type}
                      aria-label="Select Account Type"
                    >
                      <option value="" selected>
                        Select
                      </option>
                      <option value="1">Saving</option>
                      <option value="2">Current</option>
                      <option value="3">Salary</option>
                      <option value="4">Fixed Deposit</option>
                      <option value="5">Recurring Deposit</option>
                    </select>
                  ) : (
                    <select
                      className="form-control"
                      onChange={handleChangeAccountDetails}
                      name="account_type"
                      value={accountDetailsData.account_type}
                      aria-label="Select Account Type"
                    >
                      <option value="" selected>
                        Select
                      </option>
                      <option value="1">Saving</option>
                      <option value="2">Current</option>
                      <option value="3">Salary</option>
                      <option value="4">Fixed Deposit</option>
                      <option value="5">Recurring Deposit</option>
                    </select>
                  )} */}
                          {/* <input
                  type="input"
                  placeholder="Account Type "
                  name="account_type"
                  className="form-control input-height"
                  value={accountDetailsData.account_type}
                  onChange={handleChangeAccountDetails}
                /> */}
                        </div>
                      </div>
                      <div className="row pl-3 mb-4">
                        <div className="col-md-12">
                          <label className="col-form-head">
                            Account Number
                          </label>
                          <input
                            type="number"
                            placeholder="Account Number"
                            name="account_number"
                            className="form-control input-height"
                            // value={accountDetailsData.account_number}
                            value={
                              ReapplyAccountDataLength.length == 0
                                ? accountDetailsData.account_number
                                : ReapplyAccountData.account_number
                            }
                            onChange={
                              ReapplyAccountDataLength.length == 0
                                ? handleChangeAccountDetails
                                : ReapplyhandleChangeAccountDetails
                            }
                          />
                          {/* {isDisabledAccountDetails.account_number == true ? (
                    <input
                      disabled
                      type="input"
                      placeholder="Account Number "
                      name="account_number"
                      className="form-control input-height"
                      value={accountDetailsData.account_number}
                      onChange={handleChangeAccountDetails}
                    />
                  ) : (
                    <input
                      type="input"
                      placeholder="Account Number "
                      name="account_number"
                      className="form-control input-height"
                      value={accountDetailsData.account_number}
                      onChange={handleChangeAccountDetails}
                    />
                  )} */}
                        </div>
                      </div>

                      <div className="row pl-3 mb-4">
                        <div className="col-md-12">
                          <label className="col-form-head">IFSC Code</label>
                          <br />
                          <input
                            type="input"
                            placeholder="IFSC Code"
                            name="bank_ifsc_code"
                            className="form-control input-height"
                            // value={accountDetailsData.bank_ifsc_code}
                            value={
                              ReapplyAccountDataLength.length == 0
                                ? accountDetailsData.bank_ifsc_code
                                : ReapplyAccountData.bank_ifsc_code
                            }
                            onChange={
                              ReapplyAccountDataLength.length == 0
                                ? handleChangeAccountDetails
                                : ReapplyhandleChangeAccountDetails
                            }
                          />
                          {/* {isDisabledAccountDetails.bank_ifsc_code == true ? (
                    <input
                      disabled
                      type="input"
                      placeholder="IFSC Code"
                      name="bank_ifsc_code"
                      className="form-control input-height"
                      value={accountDetailsData.bank_ifsc_code}
                      onChange={handleChangeAccountDetails}
                    />
                  ) : (
                    <input
                      type="input"
                      placeholder="IFSC Code"
                      name="bank_ifsc_code"
                      className="form-control input-height"
                      value={accountDetailsData.bank_ifsc_code}
                      onChange={handleChangeAccountDetails}
                    />
                  )} */}
                        </div>
                      </div>

                      <div className="row pl-3 mb-4">
                        <div className="col-md-12">
                          <label className="col-form-head">MICR Code</label>
                          <br />
                          <input
                            type="input"
                            placeholder="MICR Code"
                            name="bank_micr_code"
                            className="form-control input-height"
                            // value={accountDetailsData.bank_micr_code}
                            value={
                              ReapplyAccountDataLength.length == 0
                                ? accountDetailsData.bank_micr_code
                                : ReapplyAccountData.bank_micr_code
                            }
                            onChange={
                              ReapplyAccountDataLength.length == 0
                                ? handleChangeAccountDetails
                                : ReapplyhandleChangeAccountDetails
                            }
                          />
                          {/* {isDisabledAccountDetails.bank_micr_code == true ? (
                    <input
                      disabled
                      type="input"
                      placeholder="MICR Code"
                      name="bank_micr_code"
                      className="form-control input-height"
                      value={accountDetailsData.bank_micr_code}
                      onChange={handleChangeAccountDetails}
                    />
                  ) : (
                    <input
                      type="input"
                      placeholder="MICR Code"
                      name="bank_micr_code"
                      className="form-control input-height"
                      value={accountDetailsData.bank_micr_code}
                      onChange={handleChangeAccountDetails}
                    />
                  )} */}
                        </div>
                      </div>
                      <div className="row pl-3 mb-4">
                        {/* {console.log(ReapplyAccountData)} */}
                        <div className="col-md-12">
                          <label className="col-form-head">
                            Branch Address
                          </label>
                          <br />
                          <input
                            type="input"
                            placeholder="Branch Address"
                            name="branch_address"
                            className="form-control input-height"
                            // value={accountDetailsData.branch_address}
                            value={
                              ReapplyAccountDataLength.length == 0
                                ? accountDetailsData.branch_address
                                : ReapplyAccountData.branch_address
                            }
                            onChange={
                              ReapplyAccountDataLength.length == 0
                                ? handleChangeAccountDetails
                                : ReapplyhandleChangeAccountDetails
                            }
                          />
                          {/* {isDisabledAccountDetails.branch_address == true ? (
                    <input
                      disabled
                      type="input"
                      placeholder="Branch Address"
                      name="branch_address"
                      className="form-control input-height"
                      value={accountDetailsData.branch_address}
                      onChange={handleChangeAccountDetails}
                    />
                  ) : (
                    <input
                      type="input"
                      placeholder="Branch Address"
                      name="branch_address"
                      className="form-control input-height"
                      value={accountDetailsData.branch_address}
                      onChange={handleChangeAccountDetails}
                    />
                  )} */}
                        </div>
                      </div>

                      <div className="application-incubator-hr">
                        <hr />
                      </div>

                      <div className="row pl-3">
                        <div className="col-md-6">
                          <button
                            className="btn btn-sisfs btn-transparent-primary full-width"
                            data-dismiss="modal"
                          >
                            CANCEL
                          </button>
                        </div>
                        <div className="col-md-6">
                          <button
                            className="tertiary  btn-yellow-primary full-width"
                            onClick={() => ReapplystoreAccountDetails()}
                          >
                            Save Account
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {showEditSuccessModal && (
                <>
                  <div className="modal-backdrop fade show"></div>

                  <div
                    className="modal align-middle modal-sisfs d-block"
                    id="FormSubmissionSuccess"
                  >
                    <div className="modal-dialog modal-dialog-centered modal-md">
                      <div className="modal-content">
                        <div className="modal-body text-center">
                          <i className="fa fa-check-circle-o"></i>
                          <h3 className="modal-message">Thank You !</h3>
                          <div className="modal-text">{tranche_message}</div>
                          <div className="modal-action">
                            <button
                              className="btn btn-sisfs btn-yellow-primary"
                              onClick={() => window.location.reload()}
                            >
                              Ok !!
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {showEditFailureModalAccount && (
                <>
                  <div className="modal-backdrop fade show"></div>
                  <div
                    className="modal align-middle modal-sisfs d-block"
                    id="FormSubmissionError"
                  >
                    <div className="modal-dialog modal-dialog-centered modal-md">
                      <div className="modal-content">
                        <div className="modal-body text-center">
                          {/* <i className="fa fa-times-circle-o"></i> */}
                          <h3 className="modal-message message-orange">
                            Oops! You can not remove account related documents
                            once you upload it..
                          </h3>
                          <br />

                          <div className="modal-action">
                            <button
                              className="btn btn-sisfs btn-yellow-primary"
                              onClick={dismissModal}
                            >
                              OK !
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {showEditFailureModal && (
                <>
                  <div className="modal-backdrop fade show"></div>
                  <div
                    className="modal align-middle modal-sisfs d-block"
                    id="FormSubmissionError"
                  >
                    <div className="modal-dialog modal-dialog-centered modal-md">
                      <div className="modal-content">
                        <div className="modal-body text-center">
                          {/* <i className="fa fa-times-circle-o"></i> */}
                          <h3 className="modal-message message-orange">
                            Oops! Something is Wrong
                          </h3>
                          <br />
                          <h3 className="modal-message message-orange">
                            Please Try Again !!!
                          </h3>
                          <div className="modal-action">
                            <button
                              className="btn btn-sisfs btn-yellow-primary"
                              onClick={dismissModal}
                            >
                              OK !
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {showEditFailureValidationModal && (
                <>
                  <div className="modal-backdrop fade show"></div>
                  <div
                    className="modal align-middle modal-sisfs d-block"
                    id="FormSubmissionError"
                  >
                    <div className="modal-dialog modal-dialog-centered modal-md">
                      <div className="modal-content">
                        <div className="modal-body text-center">
                          {/* <i className="fa fa-times-circle-o"></i> */}
                          {/* <h3 className="modal-message message-orange">
                    Oops! Something is Wrong
                  </h3> */}
                          <br />
                          <h3 className="modal-message message-orange">
                            Please Fill all the fields
                          </h3>
                          <div className="modal-action">
                            <button
                              className="btn btn-sisfs btn-yellow-primary"
                              onClick={() =>
                                setShowFailureValidationModal(false)
                              }
                            >
                              OK !
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {showIncubatorSuccessModal && (
                <ConfirmationDialog
                  headerText={dialogData.header}
                  bodyText={dialogData.text}
                  onDialogClose={dismissModal_refresh}
                />
              )}

              {showIncubatorFailureModal1 && (
                <ConfirmationDialog
                  headerText={failDialogData.header}
                  bodyText={failDialogData.text}
                  // bodyNote={failDialogData.note}
                  onDialogClose={() => setShowIncubatorFailureModal1(false)}
                  iconClass="showImage"
                />
              )}
            </div>

            <div className="col-md-1"></div>
          </div>
        </div>
      </fieldset>
    </>
  );
}

export default FundDetailsContainer;
