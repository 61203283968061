import '../../incubator/incubator.css';
import Plus from '../../../../images/Plus.svg';
import Minus from '../../../../images/Minus.svg';
import { GeneralDetailModel } from '../../../../models/incubator/GeneralDetailModel';
import { FileUploadModel } from '../../../../models/FileUploadModel';
import React, { useEffect, useState } from 'react';
import {
  ApplicationGeneralDetails,
  OthersModel,
} from './../ApplicationGeneralDetails';
import { UploadTypeEnum } from '../../../../utils/Enums';
import FileUploader from '@amodv/react-file-uploader';
import pdf from '../../../../images/PDF.svg';
import xls from '../../../../images/xls.svg';
import doc from '../../../../images/doc.svg';
import xlsx from '../../../../images/xls.svg';
import Allround from '../../../../images/Allround.svg';
import { File_node_url, File_base_url, NODE_URL } from '../../../../constants/api';

interface IGeneralDetailsProps {
  FirstApplication_Data: any;
  DocumentsData:any;
  Inindustrydata_Data:any;
  sectorData_Data:any;
  teamsData_Data:any;
}

function MergedFirstApplicationDetailsComponent(props: IGeneralDetailsProps) {
  const { FirstApplication_Data, DocumentsData, Inindustrydata_Data, sectorData_Data, teamsData_Data} = props;
  // console.log(FirstApplication_Data,"@#");
  //console.log(otherDetailsModel, 'otherDetailsModel');
  const [ applicationGeneralDetail, setGeneralDetail,] = useState<any>('')

  const formatDateWithMonth = (date: Date) => {
    var month = new Array();
    month[0] = 'Jan';
    month[1] = 'Feb';
    month[2] = 'Mar';
    month[3] = 'April';
    month[4] = 'May';
    month[5] = 'Jun';
    month[6] = 'Jul';
    month[7] = 'Aug';
    month[8] = 'Sep';
    month[9] = 'Oct';
    month[10] = 'Nov';
    month[11] = 'Dec';

    var d = new Date(date),
      monthn = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (monthn.length < 2) monthn = '0' + monthn;
    if (day.length < 2) day = '0' + day;

    return [day, month[d.getMonth()], year].join(' ');
  };
  
  const downloadDocument = (file: any, file_name: any) => {
    fetch(`${File_node_url}`+file).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = file_name;
        a.click();
      });
    });
  };
  

  const [activeIndexApp, setActiveIndexApp] = useState('');
  const changeActiveApp = (id: string) => {
    //setLoader(true);
    setActiveIndexApp(id);
    setTimeout(() => {
      setLoader(false);
    }, 4000);
  };
  const changeActiveBackApp = () => {
    setActiveIndexApp('');
  };

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
      setGeneralDetail(FirstApplication_Data[0]);
      // {console.log("File URL", FirstApplication_Data[0])}
      // console.log(DocumentsData);
  }, [FirstApplication_Data[0]]);

  const [popUpName,setPopUpName]=useState("");
  const setMeetingModal=(fieldName:string)=>{
setPopUpName(fieldName);

  }

  const getFileDetails = (file: any, key: string) => {
    let file_name = '';
    file_name =
      file != undefined && file != null && file.length > 1
        ? file.substr(file.lastIndexOf('/') + 1, file.length)
        : '';

        const file_type =  file != undefined && file != null && file.length > 1
        ? file.substr(
          file.lastIndexOf('.') + 1,
                            file.length
                          ):'';

    if (key == 'name') return file_name;
    if (key == 'file') return file != undefined ? file.toString() : '';
    if (key == 'type') return file != undefined ? file_type.toString() : '';
  };


  return (
    <div className="container">
      <div className="row">
      </div>
      <div className="row">
        <div
          id="q-1"
          className={
            activeIndexApp == '1'
              ? 'tranche-heading col-sm-11 left-align'
              : 'reports-heading col-sm-11 left-align'
          }
        >
          General Details
        </div>

        <div className="col-sm-1">
          {activeIndexApp == '1' ? (
            <div
              onClick={changeActiveBackApp}
              data-toggle="collapse"
              data-target="#general-expand"
              role="button"
              aria-expanded="false"
              aria-controls="general-expand"
              className="hide-icon pull-right hide-icon-details"
              id="show-1"
            >
              <img src={Minus} className="img-fluid" alt="SISFS Logo" />
            </div>
          ) : (
            <div
              onClick={() => changeActiveApp('1')}
              id="hide-1"
              data-toggle="collapse"
              data-target="#general-expand"
              role="button"
              aria-expanded="false"
              aria-controls="general-expand"
              className="expand-icon pull-right expand-icon-details"
            >
              <img src={Plus} className="img-fluid" alt="SISFS Logo" />
            </div>
          )}
        </div>
      </div>
      {activeIndexApp == '1' ? (
      <div className="collapse " id="general-expand">
        <div className="row mt-05">
          <div className="col-md-6 left-align">
            <span className="incubator-label">Incubator Registered Name</span>
            <br />
            <span className="incubator-label-value-wrap">
              {applicationGeneralDetail.registered_name == null
                ? '-'
                : applicationGeneralDetail.registered_name}
            </span>
          </div>
          <div className="col-md-6 left-align">
            <span className="incubator-label">Incubator Name/Brand Name</span>
            <br />
            <span className="incubator-label-value-wrap">
              {applicationGeneralDetail.incubator_name == null
                ? '-'
                : applicationGeneralDetail.incubator_name}
            </span>
          </div>
        </div>

        <div className="row mt-05">
          <div className="col-md-6 left-align">
            <span className="incubator-label">Industry</span>
            <br />
              <ul className="ul-ml">
{Inindustrydata_Data ==undefined || Inindustrydata_Data.length == 0 ? (
              <span className="incubator-label-value-wrap">-</span>
            ) : (
              Inindustrydata_Data.map((industry:any,index:any) => (
               index <=4 ? 
                
                <li className="incubator-label-value-wrap ul-b">
                  {industry.industry_name}
                </li>
                : ""
                
                
               
              ))
            )}
            </ul>
            {Inindustrydata_Data?.length > 5 ?
             <a
             className="incubator-details-link"
             data-toggle="modal"
             data-target="#showMore"
             onClick={() => setMeetingModal('Industry')}
           >
             More...
           </a>
           :""}
           
          </div>
          <div className="col-md-6 left-align">
            <span className="incubator-label">Sector</span>
            <br />
              <ul className="ul-ml">
            {sectorData_Data ==undefined || sectorData_Data.length == 0 ? (
              <span className="incubator-label-value-wrap">-</span>
            ) : (
              sectorData_Data.map((sector:any,index:any) => (
                index <=4 ? 
                <li className="incubator-label-value-wrap ul-b">
                  {sector.industry_name}
                </li>
                :""
              ))
            )}
            </ul>
            {sectorData_Data?.length > 5 ?
             <a
             className="incubator-details-link"
             data-toggle="modal"
             data-target="#showMore"
             onClick={() => setMeetingModal('Sector')}
           >
             More...
           </a>
           :""}
          </div>
        </div>
        <div className="eac-incubator-hr">
          <hr />
        </div>

        <div className="row mt-05">
          <div className="col-md-6 left-align">
            <span className="label-lh-28">Incubator Address</span>
            <br />
            <span className="value-lh-20">
              {applicationGeneralDetail.city == null
                ? ''
                : applicationGeneralDetail.city}
              , &nbsp;
              {applicationGeneralDetail.state == null
                ? ''
                : applicationGeneralDetail.state}
              ,&nbsp;
              {applicationGeneralDetail.address == null
                ? ' '
                : applicationGeneralDetail.address}
            </span>
          </div>
          <div className="col-md-6 left-align">
            <span className="incubator-label">Website</span>
            <br />
            <span className="incubator-label-value-wrap">
              {applicationGeneralDetail.website_url == '' ||
              applicationGeneralDetail.website_url == null
                ? '-'
                : applicationGeneralDetail.website_url}
            </span>
          </div>
        </div>

        <div className="row mt-05 ">
          <div className="col-md-6 left-align">
            <span className="label-lh-28">Facebook</span>
            <br />
            <span className="website-link">
              {applicationGeneralDetail.facebook_link == '' ||
              applicationGeneralDetail.facebook_link == null
                ? '-'
                : applicationGeneralDetail.facebook_link}
            </span>
          </div>
          <div className="col-md-6 left-align">
            <span className="label-lh-28">Linkedin</span>
            <br />
            <span className="website-link">
              {applicationGeneralDetail.linkedin_link == '' ||
              applicationGeneralDetail.linkedin_link == null
                ? '-'
                : applicationGeneralDetail.linkedin_link}
            </span>
          </div>
        </div>

        <div className="row mt-05 ">
          <div className="col-md-6 left-align">
            <span className="label-lh-28">Twitter</span>
            <br />
            <span className="website-link">
              {applicationGeneralDetail.twitter_link == '' ||
              applicationGeneralDetail.twitter_link == null
                ? '-'
                : applicationGeneralDetail.twitter_link}
            </span>
          </div>
          <div className="col-md-6 left-align">
            <span className="label-lh-28">Youtube</span>
            <br />
            <span className="website-link">
              {applicationGeneralDetail.youtube_link == '' ||
              applicationGeneralDetail.youtube_link == null
                ? '-'
                : applicationGeneralDetail.youtube_link}
            </span>
          </div>
        </div>

        <div className="eac-incubator-hr">
          <hr />
        </div>

        <div className="row left-align mt-05">
          <div className="col-md-6">
            <span className="incubator-label">Name of the Representative</span>
            <br />
            <span className="incubator-label-value-wrap">
              {applicationGeneralDetail.representative_name == null
                ? '-'
                : applicationGeneralDetail.representative_name}
            </span>
          </div>
          <div className="col-md-6">
            <span className="incubator-label">Designation</span>
            <br />
            <span className="incubator-label-value-wrap">
              {applicationGeneralDetail.representative_designation == '' ||
              applicationGeneralDetail.representative_designation == null
                ? '-'
                : applicationGeneralDetail.representative_designation}
            </span>
          </div>
        </div>

        <div className="row left-align mt-05">
          <div className="col-md-6">
            <span className="incubator-label">Mobile No.</span>
            <br />
            <span className="incubator-label-value">
              {applicationGeneralDetail.representative_mobile_no == null ||
              applicationGeneralDetail.representative_mobile_no == null
                ? '-'
                : applicationGeneralDetail.representative_mobile_no}
            </span>
          </div>
          <div className="col-md-6">
            <span className="incubator-label">Email</span>
            <br />
            {applicationGeneralDetail.representative_email == '' ||
            applicationGeneralDetail.representative_email == null ? (
              ' <span className="incubator-label-value">-</span>'
            ) : (
              <a
                className="incubator-email-value"
                href={`mailto:${applicationGeneralDetail.representative_email}`}
              >
                {applicationGeneralDetail.representative_email}
              </a>
            )}
          </div>
        </div>

        <div className="eac-incubator-hr">
          <hr />
        </div>

        <div className="row mt-05">
          <div className="col-md-6 left-align">
            <span className="label-lh-28">Type of Legal Entity</span>
            <br />
            <span className="value-lh-20">
              {applicationGeneralDetail.legal_entity == null
                ? '-'
                : applicationGeneralDetail.legal_entity}
            </span>
          </div>
          <div className="col-md-6 left-align">
            <span className="incubator-label-wrap">
              Has a Central/State Government assisted your incubator so far?
            </span>
            <br />

            {/* {applicationGeneralDetail.central_incubator_far_id !=undefined && applicationGeneralDetail.central_incubator_far_id.length > 0 ? (
              applicationGeneralDetail.central_incubator_far_id.map(
                (incubator: any) => (
                  <span className="value-lh-20">
                    {incubator}
                  </span>
                )
              )
            ) : (
              <span className="incubator-label-value">-</span>
            )} */}
            {applicationGeneralDetail.central_incubator_far_id ==undefined && applicationGeneralDetail.central_incubator_far_id ==null ?
            <span className="incubator-label-value">-</span> : applicationGeneralDetail.central_incubator_far_id}
          </div>
        </div>

        <div className="row mt-05 ">
          <div className="col-md-6 left-align">
            <span className="incubator-label">
              Nature of Assistance provided to incubator
            </span>
            <br />
            <span className="incubator-label-value-wrap">
              {applicationGeneralDetail.nature_assistance_incubator == null
                ? '-'
                : applicationGeneralDetail.nature_assistance_incubator}
            </span>
          </div>
          <div className="col-md-6 left-align">
            <span className="incubator-label-wrap">
              Proof of assistance from Central/State Government
            </span>
            <br />
            {/* //proof_assistance */}
            {loader ?
              <div className="spinner-border custom-spinner loader-position" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            :
            (DocumentsData != undefined && DocumentsData.First_Application_Documents.proof_assistance !=undefined
              ? DocumentsData.First_Application_Documents.proof_assistance.map(
                  (file:any,index:any) => (
                    index <2 
                    ?
                    <div className="row mt-05">
                      <div className="col-md-3">
                        { getFileDetails(
                              file.file,
                             'type'
                                          )== 'pdf' ? (
                          <img src={pdf} alt="SISFS Logo" />
                        ) :  getFileDetails(
                          file.file,
                         'type'
                                      ) == 'doc' ? (
                          <img src={doc} alt="SISFS Logo" />
                        ) :  getFileDetails(
                          file.file,
                         'type'
                                      )== 'xls' ? (
                          <img src={xls} alt="SISFS Logo" />
                        ) : (
                          <img src={Allround} alt="SISFS Logo" />
                        )}
                      </div>
                      <div className="col-md-9">
                        <span className="document-label-value-wrap">
                          { getFileDetails(
                              file.file,
                             'name'
                                          )}
                        </span>
                        <br />
                        <a
                          // href={file.file}
                          // href={`${NODE_URL}` + file.file.substr(1)}
                          href={`${File_node_url}` + file.file}
                          target="_blank"
                          // download={file.file}
                          className="incubator-details-link"
                        >
                          View
                        </a>
                        {/* /
                        <a
                          href="#"
                          // download={file.file}
                          className="incubator-details-link"
                          onClick={() =>
                            downloadDocument(file.file, file.file)
                          }
                        >
                          Download
                        </a> */}
                      </div>
                    </div>
                    :""
                  )
                )
              : 'NA')
              }

              
            { DocumentsData.First_Application_Documents
              .proof_assistance.length >2 ?
             <a
             className="incubator-details-link"
             data-toggle="modal"
             data-target="#showMore"
             onClick={() => setMeetingModal('Proof Assistance')}
           >
             More...
           </a>
           :""}

            {DocumentsData.First_Application_Documents.proof_assistance
              .length == 0 ? (
              <span className="incubator-label-value"> -</span>
            ) : (
              ''
            )}
          </div>
        </div>

        <div className="eac-incubator-hr">
          <hr />
        </div>

        <div className="row mt-05">
          <div className="col-md-6 left-align">
            <span className="incubator-label">
              Incorporation/ Registration Date
            </span>
            <br />
            <span className="incubator-label-value">
              {applicationGeneralDetail.registration_date == null
                ? '-'
                : formatDateWithMonth(
                    new Date(applicationGeneralDetail.registration_date)
                  )}
            </span>
          </div>
          <div className="col-md-6 left-align">
            <span className="incubator-label">
              Corporate Identification Number(CIN) / Registration No.
            </span>
            <br />
            <span className="incubator-label-value-wrap">
              {applicationGeneralDetail.corporate_id_no == null
                ? '-'
                : applicationGeneralDetail.corporate_id_no}
            </span>
          </div>
        </div>

        <div className="row mt-05">
          <div className="col-md-6 left-align">
            <span className="incubator-label">Incorporation Certificate</span>
            <br></br>

            {/* //incorporation_certificate */}
            {
            loader ?
              <div className="spinner-border custom-spinner loader-position" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            :
            (DocumentsData != undefined ? (
              DocumentsData.First_Application_Documents.incorporation_certificate.map(
                (file:any,index:any) => (
                  index < 2 
                  ? 
                  <div className="row mt-05">
                    <div className="col-md-3">
                      {getFileDetails(file.file,
                                  'type'
                                ) == 'pdf' ? (
                        <img src={pdf} alt="SISFS Logo" />
                      ) : getFileDetails(file.file,
                        'type'
                      ) == 'doc' ? (
                        <img src={doc} alt="SISFS Logo" />
                      ) :getFileDetails(file.file,
                        'type'
                      ) == 'xls' ? (
                        <img src={xls} alt="SISFS Logo" />
                      ) : (
                        <img src={Allround} alt="SISFS Logo" />
                      )}
                    </div>
                    <div className="col-md-9">
                      <span className="document-label-value-wrap">
                      { getFileDetails(file.file,
                                  'name'
                                )}
                      </span>
                      <br />
                      <a
                        // href={file.file}
                        href={`${File_node_url}` + file.file}
                        target="_blank"
                        // download={file.file}
                        className="incubator-details-link"
                      >
                        View
                      </a>
                      {/* /
                      <a
                        href="#"
                        // download={file.file}
                        className="incubator-details-link"
                        onClick={() =>
                          downloadDocument(file.file, file.file)
                        }
                      >
                        Download
                      </a> */}
                    </div>
                  </div>
                  :""
                )
              )
            ) : (
              <span className="incubator-label-value">-</span>
            )
            )
            }
            {/* {DocumentsData.First_Application_Documents
              .incorporation_certificate.length == 0 ? (
              <span className="incubator-label-value">-</span>
            ) : (
              ''
            )} */}

            { DocumentsData.First_Application_Documents
              .incorporation_certificate.length >2 ?
             <a
             className="incubator-details-link"
             data-toggle="modal"
             data-target="#showMore"
             onClick={() => setMeetingModal('Incorporation Certificate')}
           >
             More...
           </a>
           :""}

          </div>
         
        </div>

        <div className="row mt-05">

          <div className="col-md-6 left-align">
              <span className="incubator-label">
                PAN Card Number of legal entity
              </span>
              <br />
              <span className="incubator-label-value-wrap">
                {applicationGeneralDetail.pan_card_number == null
                  ? '-'
                  : applicationGeneralDetail.pan_card_number}
              </span>
            </div>

            <div className="col-md-6 left-align">
                <span className="incubator-label">PAN Card of legal entity</span>
                <br></br>
                {DocumentsData.First_Application_Documents.pan_card_upload.length !=
                0 ? (
                  ''
                ) : (
                  <label className="document-label-value-wrap"> -</label>
                )}
                {
                loader ?
                  <div className="spinner-border custom-spinner loader-position" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                :
                (DocumentsData != undefined ? (
                  DocumentsData.First_Application_Documents.pan_card_upload.map(
                    (file:any,index:any) => (
                      index < 2 
                      ? 
                      <div className="row mt-05">
                        <div className="col-md-3">
                          {getFileDetails(file.file,
                                      'type'
                                    ) == 'pdf' ? (
                            <img src={pdf} alt="SISFS Logo" />
                          ) : getFileDetails(file.file,
                            'type'
                          ) == 'doc' ? (
                            <img src={doc} alt="SISFS Logo" />
                          ) :getFileDetails(file.file,
                            'type'
                          ) == 'xls' ? (
                            <img src={xls} alt="SISFS Logo" />
                          ) : (
                            <img src={Allround} alt="SISFS Logo" />
                          )}
                        </div>
                        <div className="col-md-9">
                          <span className="document-label-value-wrap">
                          { getFileDetails(file.file,
                                      'name'
                                    )}
                          </span>
                          <br />
                          
                          <a
                            // href={(file.file).toString()}
                            href={`${File_node_url}` + (file.file).toString()}
                            target="_blank"
                            // download={file.file}
                            className="incubator-details-link"
                          >
                            View
                          </a>
                          {/* /
                          <a
                            href="#"
                            // download={file.file}
                            className="incubator-details-link"
                            onClick={() =>
                              downloadDocument(file.file, "Pan_Card_Copy")
                            }
                          >
                            Download
                          </a> */}
                        </div>
                      </div>
                      :""
                    )
                  )
                ) : (
                  <span className="incubator-label-value">-</span>
                )
                )
                }        

                {/* { DocumentsData.First_Application_Documents
                              .pan_card_upload.length >2 ?
                            <a
                            className="incubator-details-link"
                            data-toggle="modal"
                            data-target="#showMore"
                            onClick={() => setMeetingModal('Incorporation Certificate')}
                          >
                            More...
                          </a>
                          :""} */}

              </div>

          </div>

        <div className="row mt-05">
          <div className="col-md-6 left-align">
            <span className="incubator-label">Board Resolution/ Authorisation letter/ PoA</span>
            <br></br>
            {DocumentsData.First_Application_Documents.authorisation_letter_upload.length !=
            0 ? (
              ''
            ) : (
              <label className="document-label-value-wrap"> -</label>
            )}
            {
            loader ?
              <div className="spinner-border custom-spinner loader-position" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            :
            (DocumentsData != undefined ? (
              DocumentsData.First_Application_Documents.authorisation_letter_upload.map(
                (file:any,index:any) => (
                  index < 2 
                  ? 
                  <div className="row mt-05">
                    <div className="col-md-3">
                      {getFileDetails(file.file,
                                  'type'
                                ) == 'pdf' ? (
                        <img src={pdf} alt="SISFS Logo" />
                      ) : getFileDetails(file.file,
                        'type'
                      ) == 'doc' ? (
                        <img src={doc} alt="SISFS Logo" />
                      ) :getFileDetails(file.file,
                        'type'
                      ) == 'xls' ? (
                        <img src={xls} alt="SISFS Logo" />
                      ) : (
                        <img src={Allround} alt="SISFS Logo" />
                      )}
                    </div>
                    <div className="col-md-9">
                      <span className="document-label-value-wrap">
                      { getFileDetails(file.file,
                                  'name'
                                )}
                      </span>
                      <br />
                      
                      <a
                        // href={(file.file).toString()}
                        href={`${File_node_url}` + (file.file).toString()}
                        target="_blank"
                        // download={file.file}
                        className="incubator-details-link"
                      >
                        View
                      </a>
                      {/* / */}
                      {/* <a
                        href="#"
                        // download={file.file}
                        className="incubator-details-link"
                        onClick={() =>
                          downloadDocument(file.file, "Authorisation_Letter")
                        }
                      >
                        Download
                      </a> */}
                    </div>
                  </div>
                  :""
                )
              )
            ) : (
              <span className="incubator-label-value">-</span>
            )
            )
            }        

            {/* { DocumentsData.First_Application_Documents
                          .pan_card_upload.length >2 ?
                        <a
                        className="incubator-details-link"
                        data-toggle="modal"
                        data-target="#showMore"
                        onClick={() => setMeetingModal('Incorporation Certificate')}
                      >
                        More...
                      </a>
                      :""} */}

          </div>

         <div className="col-md-6 left-align">
            <span className="incubator-label">Aadhaar Card of authorised signatory</span>
            <br></br>
            {DocumentsData.First_Application_Documents.adhaar_card_upload.length !=
            0 ? (
              ''
            ) : (
              <label className="document-label-value-wrap"> -</label>
            )}
            {
            loader ?
              <div className="spinner-border custom-spinner loader-position" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            :
            (DocumentsData != undefined ? (
              DocumentsData.First_Application_Documents.adhaar_card_upload.map(
                (file:any,index:any) => (
                  index < 2 
                  ? 
                  <div className="row mt-05">
                    <div className="col-md-3">
                      {getFileDetails(file.file,
                                  'type'
                                ) == 'pdf' ? (
                        <img src={pdf} alt="SISFS Logo" />
                      ) : getFileDetails(file.file,
                        'type'
                      ) == 'doc' ? (
                        <img src={doc} alt="SISFS Logo" />
                      ) :getFileDetails(file.file,
                        'type'
                      ) == 'xls' ? (
                        <img src={xls} alt="SISFS Logo" />
                      ) : (
                        <img src={Allround} alt="SISFS Logo" />
                      )}
                    </div>
                    <div className="col-md-9">
                      <span className="document-label-value-wrap">
                      { getFileDetails(file.file,
                                  'name'
                                )}
                      </span>
                      <br />
                      
                      <a
                        // href={(file.file).toString()}
                        href={`${File_node_url}` + (file.file).toString()}
                        target="_blank"
                        // download={file.file}
                        className="incubator-details-link"
                      >
                        View
                      </a>
                      {/* /
                      <a
                        href="#"
                        // download={file.file}
                        className="incubator-details-link"
                        onClick={() =>
                          downloadDocument(file.file, "Adhaar_Card_Copy")
                        }
                      >
                        Download
                      </a> */}
                    </div>
                  </div>
                  :""
                )
              )
            ) : (
              <span className="incubator-label-value">-</span>
            )
            )
            }        

            {/* { DocumentsData.First_Application_Documents
                          .pan_card_upload.length >2 ?
                        <a
                        className="incubator-details-link"
                        data-toggle="modal"
                        data-target="#showMore"
                        onClick={() => setMeetingModal('Incorporation Certificate')}
                      >
                        More...
                      </a>
                      :""} */}

          </div>
        </div>

        <div className="row mt-05">
          <div className="col-md-6 left-align">
            <span className="incubator-label-wrap">
            Date of commencement of Business/Operations
            </span>
            <br />
            <span className="incubator-label-value-wrap">
              {applicationGeneralDetail.operationalization_date == null
                ? '-'
                : formatDateWithMonth(
                    new Date(applicationGeneralDetail.operationalization_date)
                  )}
            </span>
          </div>

          <div className="col-md-6 left-align">
            <span className="incubator-label-wrap">
              Audited Annual Report for last 2 years
            </span>
            <br />
            {DocumentsData.First_Application_Documents.annual_report.length !=
            0 ? (
              ''
            ) : (
              <label className="document-label-value-wrap"> -</label>
            )}
            <br />
            {loader ?
                <div className="spinner-border custom-spinner loader-position" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              :
            (DocumentsData.First_Application_Documents.annual_report.map(
              (file:any,index:any) => (
                index < 2 ?
                <div className="row">
                  <div className="col-md-3">
                    {getFileDetails(file.file,
                                              'type'
                                            )== 'pdf' ? (
                      <img src={pdf} alt="SISFS Logo" />
                    ) : getFileDetails(file.file,
                      'type'
                    ) == 'doc' ? (
                      <img src={doc} alt="SISFS Logo" />
                    ) :getFileDetails(file.file,
                      'type'
                    ) == 'xls' ? (
                      <img src={xls} alt="SISFS Logo" />
                    ) : (
                      <img src={Allround} alt="SISFS Logo" />
                    )}
                  </div>
                  <div className="col-md-9">
                    <span className="document-label-value-wrap">
                      {/* {file.file_name}{' '} */}
                      {getFileDetails(
                                              file.file,
                                              'name'
                                            )}
                    </span>
                    <br />
                    <a
                      // href={getFileDetails(file.file,'file')}
                      href={`${File_node_url}` + (file.file).toString()}
                      target="_blank"
                      // download="AnnualReport"
                      className="incubator-details-link"
                    >
                      View
                    </a>
                    {/* /
                    <a
                      href="#"
                      // download="annual_report"
                      className="incubator-details-link"
                      onClick={() =>
                        downloadDocument(file.file, file.file)
                      }
                    >
                      Download
                    </a> */}
                  </div>
                </div>
                :""
              )
            ))}

            
            {DocumentsData.First_Application_Documents
              .annual_report.length > 2 ?  
             <a
             className="incubator-details-link"
             data-toggle="modal"
             data-target="#showMore"
             onClick={() => setMeetingModal('Annual Report')}
           >
             More...
           </a>
           :""}

          </div>

        </div>


        <div className="row mt-05">
          <div className="col-md-6 left-align">
            {/* { applicationGeneralDetail.central_incubator_far_id !=undefined && applicationGeneralDetail.central_incubator_far_id.length> 0?
            applicationGeneralDetail.central_incubator_far_id.map(
              (sector: string) =>
              {
                if(sector === 'Not assisted by Central/State Government'){
                  return <span className="incubator-label-wrap">
                    Has the incubator been operational for atleast three years
                    on the date of application to the scheme{' '}
                  </span>
                }
                else if (sector === 'Assisted by Central Government' || sector === 'Assisted by State Government'){
                  return <span className="incubator-label-wrap">
                    Has the incubator been operational for atleast two years on
                    the date of application to the scheme
                  </span>
                }
              }
                // sector === 'Not assisted by Central/State Government' ? (
                //   <span className="incubator-label-wrap">
                //     Has the incubator been operational for atleast three years
                //     on the date of application to the scheme{' '}
                //   </span>
                // ) : (
                //   <span className="incubator-label-wrap">
                //     Has the incubator been operational for atleast two years on
                //     the date of application to the scheme
                //   </span>
                // )
            ):""} */}

            {/* {applicationGeneralDetail.central_incubator_far_id==undefined|| applicationGeneralDetail.central_incubator_far_id.length == 0 ? (
              <span className="incubator-label-wrap">
                Has the incubator been operational for atleast two years on the
                date of application to the scheme
              </span>
            ) : (
              ''
            )} */}

            {
            applicationGeneralDetail.central_incubator_far_id.includes(
              'Not assisted by Central/State Government'
            ) &&
                  <span className="incubator-label-wrap">
                     Has the incubator been operational for atleast three years
                     on the date of application to the scheme{' '}
                  </span>
            }
            {
            (applicationGeneralDetail.central_incubator_far_id.includes(
              'Assisted by Central Government'
            ) || 
            applicationGeneralDetail.central_incubator_far_id.includes(
              'Assisted by State Government'
            )) &&
                  <span className="incubator-label-wrap">
                    Has the incubator been operational for atleast two years on
                    the date of application to the scheme{' '}
                  </span>
            }

            <br />
            <span className="incubator-label-value">
              {applicationGeneralDetail.operationalization_date != null &&
              applicationGeneralDetail.operationalization_date != ''
                ? 'Yes'
                : '-'}
            </span>
          </div>
          <div className="col-md-6 left-align">
            <span className="incubator-label-wrap">
              Number of individuals that can be seated at once
            </span>
            <br />
            <span className="incubator-label-value-wrap">
              {applicationGeneralDetail.seating_capacity_individulas == null
                ? '-'
                : applicationGeneralDetail.seating_capacity_individulas}
            </span>
          </div>
        </div>

        {/* <div className="eac-incubator-hr">
          <hr />
        </div> */}

        <div className="eac-incubator-hr">
          <hr />
        </div>

        <div className="row mt-05">
          <div className="col-md-6 left-align">
            <span className="incubator-label-wrap">
              No. of startups undergoing incubation physically at the incubator
              on the date of application
              {/* Agreement between incubator and startup */}
            </span>
            <br />
            <span className="incubator-label-value-wrap">
              {applicationGeneralDetail.no_startups_incubation == null
                ? '-'
                : applicationGeneralDetail.no_startups_incubation}
            </span>
          </div>
          <div className="col-md-6 left-align">
            <span className="incubator-label-wrap">
              Are you disbursing seed fund to your incubatees using funding from
              any third-party private entity?
            </span>
            <br />
            <span className="incubator-label-value-wrap">
              {applicationGeneralDetail.fund_disburse_using_3rd_party == null
                ? '-'
                : applicationGeneralDetail.fund_disburse_using_3rd_party == 0
                ? 'No'
                : applicationGeneralDetail.fund_disburse_using_3rd_party == 1
                ? 'Yes'
                : ''}
            </span>
          </div>
        </div>

        <div className="row mt-05">
          <div className="col-md-12 left-align">
              <div className="row">
                <div className="col-md-6">
                <span className="incubator-label-wrap">
                  Agreement between incubator and startup
                  </span>
                </div>
                <div className="col-md-6"></div>
              </div>
              <div className="row mt-1">
              {DocumentsData.First_Application_Documents.startup_incubation_upload.length !=
              0 ? (
                ''
              ) : (
                <label className="document-label-value-wrap d-block"> -</label>
              )}
              {
              loader ?
                <div className="spinner-border custom-spinner loader-position" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              :
              (DocumentsData != undefined ? (
                DocumentsData.First_Application_Documents.startup_incubation_upload.map(
                  (file:any,index:any) => (
                    <div className="col-md-6 left-align">
                      <div className="row mt-05">
                      <div className="col-md-2">
                        {getFileDetails(file.file,
                                    'type'
                                  ) == 'pdf' ? (
                          <img src={pdf} alt="SISFS Logo" />
                        ) : getFileDetails(file.file,
                          'type'
                        ) == 'doc' ? (
                          <img src={doc} alt="SISFS Logo" />
                        ) :getFileDetails(file.file,
                          'type'
                        ) == 'xls' ? (
                          <img src={xls} alt="SISFS Logo" />
                        ) : (
                          <img src={Allround} alt="SISFS Logo" />
                        )}
                      </div>
                      <div className="col-md-10">
                        <span className="document-label-value-wrap">
                        { getFileDetails(file.file,
                                    'name'
                                  )}
                        </span>
                        <br />
                        
                        <a
                          // href={(file.file).toString()}
                          href={`${File_node_url}` + (file.file).toString()}
                          target="_blank"
                          // download={file.file}
                          className="incubator-details-link"
                        >
                          View
                        </a>
                        {/* /
                        <a
                          href="#"
                          // download={file.file}
                          className="incubator-details-link"
                          onClick={() =>
                            downloadDocument(file.file, "Startup_Incubation")
                          }
                        >
                          Download
                        </a> */}
                      </div>
                    </div>

                    </div>
                    
                  )
                )
              ) : (
                <span className="incubator-label-value">-</span>
              )
              )
              } 
              </div>
            </div>
          </div>
      </div>
      ):""}

      <div className="incubator-hr">
        <hr />
      </div>
      <div className="row">
        <div
          id="q-4"
          className={
            activeIndexApp == '4'
              ? 'tranche-heading col-sm-11 left-align'
              : 'reports-heading col-sm-11 left-align'
          }
        >
          Incubator's Team
        </div>
        <div className="col-sm-1">
          {activeIndexApp == '4' ? (
            <div
              onClick={changeActiveBackApp}
              data-toggle="collapse"
              data-target="#team-expand"
              role="button"
              aria-expanded="false"
              aria-controls="team-expand"
              className="hide-icon pull-right hide-icon-details"
              id="show-4"
            >
              <img src={Minus} className="img-fluid" alt="SISFS Logo" />
            </div>
          ) : (
            <div
              onClick={() => changeActiveApp('4')}
              id="hide-4"
              data-toggle="collapse"
              data-target="#team-expand"
              role="button"
              aria-expanded="false"
              aria-controls="team-expand"
              className="expand-icon pull-right expand-icon-details"
            >
              <img src={Plus} className="img-fluid" alt="SISFS Logo" />
            </div>
          )}
        </div>
      </div>
      {activeIndexApp == '4' ? (
      <div className="collapse " id="team-expand">
        <div className="row mt-05 ">
          <div className="col-md-6 left-align">
            <span className="incubator-label">Name of the CEO</span>
            <br />
            <span className="incubator-label-value-wrap">
              {applicationGeneralDetail.ceo_firstname == null ||
              applicationGeneralDetail.ceo_firstname == ''
                ? '-'
                : applicationGeneralDetail.ceo_firstname}{' '}
              &nbsp;
              {applicationGeneralDetail.ceo_lastname}
            </span>
          </div>
          <div className="col-md-6 left-align">
            <span className="incubator-label">Background of the CEO</span>
            <br />
            <span className="incubator-label-value-wrap">
              {applicationGeneralDetail.ceo_description == null ||
              applicationGeneralDetail.ceo_description == ''
                ? '-'
                : applicationGeneralDetail.ceo_description}
            </span>
          </div>
        </div>

        <div className="row mt-05 ">
          <div className="col-md-6 left-align">
            <span className="incubator-label">No. of full-time employees</span>
            <br />
            <span className="incubator-label-value">
              {applicationGeneralDetail.fulltime_employees_no}
            </span>
          </div>
          <div className="col-md-6 left-align"></div>
        </div>

        <div className="row mt-1-5 ">
          <div className="col-md-12 left-align">
            <span className="incubator-label-heading">
              List of all teams along with the number of full-time employees in
              each team
            </span>
            <br />

            <div className="row mt-05 ">
              <div className="col-md-6">
                <span className="incubator-label">Team Name</span>

                {console.log(teamsData_Data,"fileType")}
              </div>
              <div className="col-md-6">
                <span className="incubator-label">No. of employees</span>
              </div>
            </div>
            {teamsData_Data == undefined|| teamsData_Data.length == 0 ? (
              <div className="row">
                <div className="col-md-6">
                  <span className="incubator-label-value">-</span>
                </div>
                <div className="col-md-6">
                  <span className="incubator-label-value">-</span>
                </div>
              </div>
            ) : (
              teamsData_Data.map((team:any) => (
                <div className="row">
                  <div className="col-md-6">
                    <span className="incubator-label-value-wrap">
                      {team.team_name == '' ? '-' : team.team_name}
                    </span>
                  </div>
                  <div className="col-md-6">
                    <span className="incubator-label-value">
                      {team.no_employees}
                    </span>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      ):""}
      <div className="incubator-hr">
        <hr />
      </div>
      <div className="row">
        <div
          id="q-2"
          className={
            activeIndexApp == '2'
              ? 'tranche-heading col-sm-11 left-align'
              : 'reports-heading col-sm-11 left-align'
          }
        >
          Incubator Support Details
        </div>
        <div className="col-sm-1">
          {activeIndexApp == '2' ? (
            <div
              onClick={changeActiveBackApp}
              data-toggle="collapse"
              data-target="#incubator-expand"
              role="button"
              aria-expanded="false"
              aria-controls="incubator-expand"
              className="hide-icon pull-right hide-icon-details"
              id="show-2"
            >
              <img src={Minus} className="img-fluid" alt="SISFS Logo" />
            </div>
          ) : (
            <div
              onClick={() => changeActiveApp('2')}
              id="hide-2"
              data-toggle="collapse"
              data-target="#incubator-expand"
              role="button"
              aria-expanded="false"
              aria-controls="incubator-expand"
              className="expand-icon pull-right expand-icon-details"
            >
              <img src={Plus} className="img-fluid" alt="SISFS Logo" />
            </div>
          )}
        </div>
      </div>
      {activeIndexApp == '2' ? (
      <div className="collapse " id="incubator-expand">
        <div className="row mt-05">
          <div className="col-md-6 left-align">
            <span className="label-lh-28-wrap">
              List the significant facilities available for startups at the
              incubation center
            </span>
            <br />
            <span className="value-lh-20">
              {applicationGeneralDetail.list_facilities == null
                ? '-'
                : applicationGeneralDetail.list_facilities}
            </span>
          </div>
          <div className="col-md-6 left-align">
            <span className="incubator-label">Facilities Pictures</span>
            <br />
            <div className="row mt-05">
              <div className="col-md-12 left-align">
                {loader ?
                <div className="spinner-border custom-spinner loader-position" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              :
                  (DocumentsData != undefined ? (
                  DocumentsData.First_Application_Documents.facilities_pictures.map(
                    (file:any,index:any) => (
                      index < 2 ?
                      <div className="row ">
                        <div className="col-md-2">
                          {getFileDetails(
                              file.file,
                              'type'
                            ) == 'pdf' ? (
                            <img src={pdf} alt="SISFS Logo" />
                          ) : getFileDetails(
                            file.file,
                            'type'
                          ) == 'doc' ? (
                            <img src={doc} alt="SISFS Logo" />
                          ) : getFileDetails(
                            file.file,
                            'type'
                          ) == 'xls' ? (
                            <img src={xls} alt="SISFS Logo" />
                          ) : (
                            <img src={Allround} alt="SISFS Logo" />
                          )}
                        </div>
                        <div className="col-md-9 left-align">
                          <span className="document-label-value-wrap">
                            {getFileDetails(
                              file.file,
                              'name'
                            )}
                          </span>
                          <br />
                          <a
                            // href={getFileDetails(
                            //   file.file,
                            //   'file'
                            // )}
                            href={`${File_node_url}` + (file.file).toString()}
                            target="_blank"
                            // download={file.file}
                            className="incubator-details-link"
                          >
                            View
                          </a>
                          {/* /
                          <a
                            href="#"
                            // download={file.file}
                            className="incubator-details-link"
                            onClick={() =>
                              downloadDocument(file.file, file.file)
                            }
                          >
                            Download
                          </a> */}
                        </div>
                      </div> : ''
                    )
                  )
                ) : (
                  <span className="incubator-label-value">-</span>
                ))}
                {DocumentsData.First_Application_Documents.facilities_pictures
                  .length == 0 ? (
                  <span className="incubator-label-value">-</span>
                ) : (
                  ''
                )}

            {DocumentsData.First_Application_Documents
              .facilities_pictures.length > 2 ?
             <a
             className="incubator-details-link"
             data-toggle="modal"
             data-target="#showMore"
             onClick={() => setMeetingModal('Facilities Pictures')}
           >
             More...
           </a>
           :""}

              </div>
            </div>
          </div>
        </div>

        <div className="row mt-1-5">
          <div className="col-md-12 left-align">
            <span className="incubator-label-heading">
              Incubation support provided by the incubator in last three years
            </span>
          </div>
        </div>
        <div className="row mt-05">
          <div className="col-md-6 left-align">
            <span className="incubator-label">
              No. of startups incubated and their details
            </span>
            <br />
            <span className="incubator-label-value">
              {applicationGeneralDetail.startups_no_incubated == null
                ? '-'
                : applicationGeneralDetail.startups_no_incubated}
            </span>
          </div>
          <div className="col-md-6 left-align">
            <span className="incubator-label">No. of startups graduated</span>
            <br />
            <span className="incubator-label-value">
              {applicationGeneralDetail.startups_no_graduated == null
                ? '-'
                : applicationGeneralDetail.startups_no_graduated}
            </span>
          </div>
        </div>

        <div className="row mt-05">
          <div className="col-md-6 left-align">
            <span className="incubator-label">
              No. of startups that raised follow on investments
            </span>
            <br />
            <span className="incubator-label-value">
              {applicationGeneralDetail.startups_no_investments == null
                ? '-'
                : applicationGeneralDetail.startups_no_investments}
            </span>
          </div>
          <div className="col-md-6 left-align">
            <span className="incubator-label-wrap">
              No. of startups that crossed a revenue of Rs 1 Cr in last 1 year
            </span>
            <br />
            <span className="incubator-label-value">
              {applicationGeneralDetail.startups_no_revenue_year == null
                ? '-'
                : applicationGeneralDetail.startups_no_revenue_year}
            </span>
          </div>
        </div>

        <div className="row mt-05">
          <div className="col-md-6 left-align">
            <span className="incubator-label-wrap">
              2-year survival rate of startups from the date of joining
              incubator
            </span>
            <br />
            <span className="incubator-label-value">
            {/* &#8377; {' '}  */}

              {applicationGeneralDetail.startups_survival_rate == null
                ? '0'
                : applicationGeneralDetail.startups_survival_rate}
                %
            </span>
          </div>
          <div className="col-md-6 left-align"></div>
        </div>

        <div className="row mt-1-5">
          <div className="col-md-12 left-align">
            <span className="incubator-label-heading ">
              Funding support extended to incubatees in last three years
            </span>
          </div>
        </div>

        <div className="row mt-05">
          <div className="col-md-6 left-align">
            <span className="incubator-label-wrap">
              Investment agreements signed between incubator and startups
            </span>
            <br />
            <span className="incubator-label-value">
              {applicationGeneralDetail.investments_between_incubator_startups ==
              null
                ? '-'
                : applicationGeneralDetail.investments_between_incubator_startups}
            </span>
          </div>
          <div className="col-md-6 left-align">
            <span className="incubator-label">No. of startups invested in</span>
            <br />
            <span className="incubator-label-value">
              {/* {applicationGeneralDetail.startups_invested_applicable == '' */}
              {/* {applicationGeneralDetail.startups_invested_applicable ==
              null ? '-' */}
               {applicationGeneralDetail.startups_invested_applicable == "1"
               ? 'NA'
              : applicationGeneralDetail.startups_invested}
            </span>
          </div>
        </div>

        <div className="row mt-05">
          <div className="col-md-6 left-align">
            <span className="incubator-label">
              Total Corpus allocated to incubatees (&#8377; Lakh)
            </span>
            <br />
            <span className="incubator-label-value">
            &#8377; {' '} 
              {/* {applicationGeneralDetail.startups_invested_total_corpus_applicable ==
              '' || applicationGeneralDetail.startups_invested_total_corpus_applicable ==
              null */}
              {applicationGeneralDetail.startups_invested_total_corpus_applicable == "1"
                ? 'NA'
                : applicationGeneralDetail.startups_invested_total_corpus
                }
            </span>
          </div>
          <div className="col-md-6 left-align">
            <span className="incubator-label-wrap">
              Total investments raised by incubatees from external sources  (&#8377; Lakh)
            </span>
            <br />
            <span className="incubator-label-value">
            &#8377; {' '} 

              {/* {applicationGeneralDetail.total_investments_raised_incubatees_applicable !=
              '' */}
               {/* {applicationGeneralDetail.total_investments_raised_incubatees_applicable != "0"
                ? '0' */}
                  {applicationGeneralDetail.total_investments_raised_incubatees_applicable == "1"
                ? 'NA'
                : applicationGeneralDetail.total_investments_raised_incubatees}
            </span>
          </div>
        </div>

        <div className="row mt-1-5">
          <div className="col-md-12 left-align">
            <span className="incubator-label-heading ">
              Mentoring provided to incubatees in last three years
            </span>
          </div>
        </div>

        <div className="row mt-05 ">
          <div className="col-md-6 left-align">
            <span className="incubator-label">
              No. of mentors actively engaged with incubator
            </span>
            <br />
            <span className="incubator-label-value">
              {/* {applicationGeneralDetail.mentors_no_engaged_applicable != '' */}
              {applicationGeneralDetail.mentors_no_engaged_applicable == "1"
                ? 'NA'
                : applicationGeneralDetail.mentors_no_engaged}
            </span>
          </div>
          <div className="col-md-6 left-align">
            <span className="incubator-label-wrap">
              Average mentoring hours allocated per startup per month
            </span>
            <br />
            <span className="incubator-label-value">
              {/* {applicationGeneralDetail.mentors_average_hours_applicable != '' */}
              {applicationGeneralDetail.mentors_average_hours_applicable == "1"
                ? 'NA'
                : applicationGeneralDetail.mentors_average_hours > 1 ? 
                applicationGeneralDetail.mentors_average_hours+ " hrs" : 
                applicationGeneralDetail.mentors_average_hours+ " hr"}
            </span>
          </div>
        </div>

        <div className="row mt-05 ">
          <div className="col-md-6 left-align">
            <span className="incubator-label">
              No. of patents registered by incubatees
            </span>
            <br />
            <span className="incubator-label-value">
              {/* {applicationGeneralDetail.no_of_patents_applicable != '' */}
              {applicationGeneralDetail.no_of_patents_applicable == "1"
                ? 'NA'
                : applicationGeneralDetail.no_of_patents}
            </span>
          </div>
          <div className="col-md-6 left-align">
            <span className="incubator-label">
              No. of copyrights registered by incubatees
            </span>
            <br />
            <span className="incubator-label-value">
              {/* {applicationGeneralDetail.no_of_copyrights_applicable != '' */}
              {applicationGeneralDetail.no_of_copyrights_applicable == "1"
                ? 'NA'
                : applicationGeneralDetail.no_of_copyrights}
            </span>
          </div>
        </div>

        <div className="row mt-05 ">
          <div className="col-md-6 left-align">
            <span className="incubator-label">
              No. of trademarks registered by incubatees
            </span>
            <br />
            <span className="incubator-label-value">
              {/* {applicationGeneralDetail.no_of_trademarks_applicable != '' */}
              {applicationGeneralDetail.no_of_trademarks_applicable == "1"
                ? 'NA'
                : applicationGeneralDetail.no_of_trademarks}
            </span>
          </div>
          <div className="col-md-6 left-align"></div>
        </div>

        <div className="row mt-1-5">
          <div className="col-md-12 left-align">
            <span className="incubator-label-heading ">
              Other support extended to incubatees in last three years
            </span>
          </div>
        </div>

        <div className="row mt-05 ">
          <div className="col-md-6 left-align">
            <span className="incubator-label">
              Industry/Corporate connects facilitated
            </span>
            <br />
            <span className="incubator-label-value">
              {/* {applicationGeneralDetail.corporate_facilitated_applicable != '' */}
              {applicationGeneralDetail.corporate_facilitated_applicable == "1"
                ? 'NA'
                : applicationGeneralDetail.corporate_facilitated}
            </span>
          </div>
          <div className="col-md-6 left-align">
            <span className="incubator-label">
              Events held for stakeholder engagements
            </span>
            <br />
            <span className="incubator-label-value">
              {/* {applicationGeneralDetail.event_engagements_applicable != '' */}
              {applicationGeneralDetail.event_engagements_applicable == "1"
                ? 'NA'
                : applicationGeneralDetail.event_engagements}
            </span>
          </div>
        </div>

        <div className="row mt-05 ">
          <div className="col-md-6 left-align">
            <span className="incubator-label">
              Participation in other events
            </span>
            <br />
            <span className="incubator-label-value">
              {/* {applicationGeneralDetail.participation_other_events_applicable !=
              '' */}
              {applicationGeneralDetail.participation_other_events_applicable == "1"
                ? 'NA'
                : applicationGeneralDetail.participation_other_events}
            </span>
          </div>
          <div className="col-md-6 left-align"></div>
        </div>
      </div>
      ):""}
      <div className="incubator-hr">
        <hr />
      </div>

      <div className="row">
        <div
          id="q-5"
          className={
            activeIndexApp == '5'
              ? 'tranche-heading col-sm-11 left-align'
              : 'reports-heading col-sm-11 left-align'
          }
        >
          Fund Utilization Details
        </div>
        <div className="col-sm-1">
          {activeIndexApp == '5' ? (
            <div
              onClick={changeActiveBackApp}
              data-toggle="collapse"
              data-target="#fund-expand"
              role="button"
              aria-expanded="false"
              aria-controls="fund-expand"
              className="hide-icon pull-right hide-icon-details"
              id="show-5"
            >
              <img src={Minus} className="img-fluid" alt="SISFS Logo" />
            </div>
          ) : (
            <div
              onClick={() => changeActiveApp('5')}
              id="hide-5"
              data-toggle="collapse"
              data-target="#fund-expand"
              role="button"
              aria-expanded="false"
              aria-controls="fund-expand"
              className="expand-icon pull-right expand-icon-details"
            >
              <img src={Plus} className="img-fluid" alt="SISFS Logo" />
            </div>
          )}
        </div>
      </div>
      {activeIndexApp == '5' ? (
      <div className="collapse " id="fund-expand">
        <div className="row mt-05 ">
          <div className="col-md-6 left-align">
            <span className="incubator-label">Quantum of fund required</span>
            <br />
            {applicationGeneralDetail.quantum_fund_required == null ? (
              <span className="incubator-label-value">-</span>
            ) : (
              <span className="incubator-label-value">
                &#8377; {' '}{applicationGeneralDetail.quantum_fund_required}
              </span>
            )}
          </div>
          <div className="col-md-6 left-align">
            <span className="incubator-label">
              Expected number of fund beneficiaries in year 1
            </span>
            <br />
            <span className="incubator-label-value">
              {
              applicationGeneralDetail.no_fund_benifitiaries_1yr != null
              ?applicationGeneralDetail.no_fund_benifitiaries_1yr
              :0
              }
            </span>
          </div>
        </div>

        <div className="row mt-05 ">
          <div className="col-md-6 left-align">
            <span className="incubator-label">
              Expected number of fund beneficiaries in year 2
            </span>
            <br />
            <span className="incubator-label-value">
              {applicationGeneralDetail.no_fund_benifitiaries_2yr!= null
              ?applicationGeneralDetail.no_fund_benifitiaries_2yr
              :0}
            </span>
          </div>
          <div className="col-md-6 left-align">
            <span className="incubator-label">
              Expected number of fund beneficiaries in year 3
            </span>
            <br />
            <span className="incubator-label-value">
              {applicationGeneralDetail.no_fund_benifitiaries_3yr!= null
              ?applicationGeneralDetail.no_fund_benifitiaries_3yr
              :0}
            </span>
          </div>
        </div>
      </div>
      ):""}
      <div className="incubator-hr">
        <hr />
      </div>

      <div className="row">
        <div
          id="q-6"
          className={
            activeIndexApp == '6'
              ? 'tranche-heading col-sm-11 left-align'
              : 'reports-heading col-sm-11 left-align'
          }
        >
          Other
        </div>
        <div className="col-sm-1">
          {activeIndexApp == '6' ? (
            <div
              onClick={changeActiveBackApp}
              data-toggle="collapse"
              data-target="#other-expand"
              role="button"
              aria-expanded="false"
              aria-controls="other-expand"
              className="hide-icon pull-right hide-icon-details"
              id="show-6"
            >
              <img src={Minus} className="img-fluid" alt="SISFS Logo" />
            </div>
          ) : (
            <div
              onClick={() => changeActiveApp('6')}
              id="hide-6"
              data-toggle="collapse"
              data-target="#other-expand"
              role="button"
              aria-expanded="false"
              aria-controls="other-expand"
              className="expand-icon pull-right expand-icon-details"
            >
              <img src={Plus} className="img-fluid" alt="SISFS Logo" />
            </div>
          )}
        </div>
      </div>

     

      {activeIndexApp == '6' ? (
      <div className="collapse" id="other-expand">
        <div className="row mt-05">
          <div className="col-md-12 left-align">
            <span className="incubator-label-wrap">
              Relevant Document (Excel)
            </span>
            <br />

            <div className="row mt-05">
              
                {loader ?
                <div className="spinner-border custom-spinner loader-position" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              :
               ( DocumentsData.First_Application_Documents != undefined
                  ? DocumentsData.First_Application_Documents.relavent_document_excel.map(
                      (file:any,index:any) => (
                        
                        (index == 0 || index ==1  )?
                        <div className="col-md-6 left-align">
                          <div className="row">
                          <div className="col-md-2">
                            {getFileDetails(
                                file.file,
                               'type'
                                            ) == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) :getFileDetails(
                              file.file,
                             'type'
                                          ) == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(
                              file.file,
                             'type'
                                          ) == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-10">
                            <span className="document-label-value-wrap">
                              {/* {file.file_name} */}
                              {getFileDetails(
                                              file.file,
                                              'name'
                                            )}
                                </span>
                            <br />
                            <a
                              // href={getFileDetails(
                              //   file.file,
                              //                 'file'
                              //               )}
                              href={`${File_node_url}` + (file.file).toString()}
                              target="_blank"
                              // download="RelaventExcel"
                              className="incubator-details-link"
                            >
                              View
                            </a>
                            {/* /
                            <a
                              href="#"
                              // download="relavent_document_excel"
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                          </div>
                        </div>
                        </div>
                        : 
                       ""
                      )
                    )
                  :  <label className="incubator-label-value">-</label>)

                  }

                {/* {DocumentsData.First_Application_Documents
                  .relavent_document_excel.length == 0 ? (
                  <label className="incubator-label-value">-</label>
                ) : (
                  ''
                )} */}
               {""} {DocumentsData.First_Application_Documents
                  .relavent_document_excel.length > 2 ?
                    <a
                    style={{marginLeft:'15px'}}
                    className="incubator-details-link"
                    data-toggle="modal"
                    data-target="#showMore"
                    onClick={() => setMeetingModal('Relevant Document Excel')}
                  >
                    More...
                  </a>
                  :""}
              
            </div>
          </div>
        </div>
        <hr className='seperator' />
        <div className="row mt-05">
          <div className="col-md-12 left-align">
            <span className="incubator-label-wrap">Team Member CV</span>
            <br />
            <div className="row mt-05">
                {loader ?
                <div className="spinner-border custom-spinner loader-position" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              :
                (DocumentsData != undefined
                  ? DocumentsData.First_Application_Documents.team_members_cv.map(
                      (file:any,index:any) => (
                        (index == 0 || index ==1  )?
                        <div className="col-md-6 left-align">
                          <div className="row">
                          <div className="col-md-2">
                            {getFileDetails(
                                file.file,
                               'type'
                                            ) == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(
                              file.file,
                             'type'
                                          )== 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(
                              file.file,
                             'type'
                                          )== 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-10">
                            <span className="document-label-value-wrap">
                              {/* {file.file_name} */}
                              {getFileDetails(
                                              file.file,
                                              'name'
                                            )}

                            </span>
                            <br />
                            <a
                              // href={getFileDetails(
                              //   file.file,
                              //                 'file'
                              //               )}
                              href={`${File_node_url}` + (file.file).toString()}
                              target="_blank"
                              // download="TeamMember"
                              className="incubator-details-link"
                            >
                              View
                            </a>
                            {/* /
                            <a
                              href="#"
                              // download="relavent_document_excel"
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                          </div>
                        </div>
                        </div>
                        
                        :""
                      )
                    )
                  :  <label className="incubator-label-value">-</label>)
                  }
                {/* {DocumentsData.First_Application_Documents.team_members_cv
                  .length == 0 ? (
                  <label className="incubator-label-value">-</label>
                ) : (
                  ''
                )} */}

          {DocumentsData.First_Application_Documents
              .team_members_cv.length >2 ?
             <a
             style={{marginLeft:'15px'}}
             className="incubator-details-link"
             data-toggle="modal"
             data-target="#showMore"
             onClick={() => setMeetingModal('Team Member CV')}
           >
             More...
           </a>
           :""}

            </div>
          </div>
        </div>
        <hr className='seperator' />
        <div className="row mt-05">
          <div className="col-md-12 left-align">
            <span className="incubator-label-wrap">Pitch Deck</span>
            <br />
            <div className="row mt-05">
                {loader ?
                <div className="spinner-border custom-spinner loader-position" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              :
                (DocumentsData != undefined
                  ? DocumentsData.First_Application_Documents.relavent_document_pitchdeck.map(
                      (file:any,index:any) => (
                        (index == 0 || index ==1  )?
                       <div className="col-md-6 left-align">
                         <div className="row">
                          <div className="col-md-2">
                            {getFileDetails(
                                file.file,
                               'type'
                                            ) == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(
                              file.file,
                             'type'
                                          ) == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(
                              file.file,
                             'type'
                                          )== 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-10">
                            <span className="document-label-value-wrap">
                              {/* {file.file_name} */}
                              {getFileDetails(
                                              file.file,
                                              'name'
                                            )}
                            </span>
                            <br />
                            <a
                              // href={getFileDetails(
                              //   file.file,
                              //                 'file'
                              //               )}
                              href={`${File_node_url}` + (file.file).toString()}
                              target="_blank"
                              // download="PitchDeck"
                              className="incubator-details-link"
                            >
                              View
                            </a>
                            {/* /
                            <a
                              href="#"
                              // download="pickdeck"
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                          </div>
                        </div>
                       </div>
                        :""
                      )
                    )
                  : <label className="incubator-label-value">-</label>)
                  }

                {/* {DocumentsData.First_Application_Documents
                  .relavent_document_pitchdeck.length == 0 ? (
                  <label className="incubator-label-value">-</label>
                ) : (
                  ''
                )} */}

          {DocumentsData.First_Application_Documents
              .relavent_document_pitchdeck.length >2 ?
             <a
             style={{marginLeft:'15px'}}
             className="incubator-details-link"
             data-toggle="modal"
             data-target="#showMore"
             onClick={() => setMeetingModal('Pitch Deck')}
           >
             More...
           </a>
           :""}

            </div>
          </div>

          <div className="col-md-4 left-align">
            {/* //relavent_document_excel */}
          </div>
        </div>
        <hr className='seperator' />
        <div className="row mt-05">
          <div className="col-md-12 left-align">
            <span className="incubator-label-wrap">Self Declaration</span>
            <br />
            <div className="row mt-05">
              {DocumentsData.First_Application_Documents.self_declaration_upload.length !=
            0 ? (
              ''
            ) : (
              <label className="document-label-value-wrap"> -</label>
            )}
                {loader ?
                <div className="spinner-border custom-spinner loader-position" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              :
                (DocumentsData != undefined
                  ? DocumentsData.First_Application_Documents.self_declaration_upload.map(
                      (file:any,index:any) => (
                        (index == 0 || index ==1  )?
                        <div className="col-md-6 left-align">
                          <div className="row">
                          <div className="col-md-2">
                            {getFileDetails(
                                file.file,
                               'type'
                                            ) == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(
                              file.file,
                             'type'
                                          )== 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(
                              file.file,
                             'type'
                                          )== 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-10">
                            <span className="document-label-value-wrap">
                              {/* {file.file_name} */}
                              {getFileDetails(
                                              file.file,
                                              'name'
                                            )}

                            </span>
                            <br />
                            <a
                              // href={getFileDetails(
                              //   file.file,
                              //                 'file'
                              //               )}
                              href={`${File_node_url}` + (file.file).toString()}
                              target="_blank"
                              // download="TeamMember"
                              className="incubator-details-link"
                            >
                              View
                            </a>
                            {/* /
                            <a
                              href="#"
                              // download="relavent_document_excel"
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, "Self_Declaration")
                              }
                            >
                              Download
                            </a> */}
                          </div>
                        </div>
                        </div>
                        :""
                      )
                    )
                  :  <label className="incubator-label-value">-</label>)
                  }
                {/* {DocumentsData.First_Application_Documents.team_members_cv
                  .length == 0 ? (
                  <label className="incubator-label-value">-</label>
                ) : (
                  ''
                )} */}

          {DocumentsData.First_Application_Documents
              .self_declaration_upload.length >2 ?
             <a
             style={{marginLeft:'15px'}}
             className="incubator-details-link"
             data-toggle="modal"
             data-target="#showMore"
             onClick={() => setMeetingModal('Self Declaration')}
           >
             More...
           </a>
           :""}
            </div>
          </div>
        </div>
        <hr className='seperator' />
        <div className="row mt-05">
          <div className="col-md-12 left-align">
            <span className="incubator-label-wrap">
              Relevant Document (Other)
            </span>
            <br />
            <div className="row mt-05">
                {loader ?
                <div className="spinner-border custom-spinner loader-position" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              :
                
                (DocumentsData != undefined
                  ? DocumentsData.First_Application_Documents.relavent_document_other.map(
                      (file:any,index:any) => (
                        (index == 0 || index ==1  )?
                        <div className="col-md-6 left-align">
                          <div className="row">
                          <div className="col-md-2">
                            {getFileDetails(
                                file.file,
                               'type'
                                            ) == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(
                              file.file,
                             'type'
                                          )== 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(
                              file.file,
                             'type'
                                          )== 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-10">
                            <span className="document-label-value-wrap">
                              {/* {file.file_name} */}
                              {getFileDetails(
                                              file.file,
                                              'name'
                                            )}

                            </span>
                            <br />
                            <a
                              // href={getFileDetails(
                              //   file.file,
                              //                 'file'
                              //               )}
                              href={`${File_node_url}` + (file.file).toString()}
                              target="_blank"
                              // download="Relavent"
                              className="incubator-details-link"
                            >
                              View
                            </a>
                            {/* /{' '}
                            <a
                              href="#"
                              // download="otherdocument"
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                          </div>
                        </div>
                        </div>
                        :""
                      )
                    )
                  : <label className="incubator-label-value">-</label>)
                  
                  }

                {/* {DocumentsData.First_Application_Documents
                  .relavent_document_other.length == 0 ? (
                  <label className="incubator-label-value">-</label>
                ) : (
                  ''
                )} */}

                
            {DocumentsData.First_Application_Documents
              .relavent_document_other.length >2 ?
             <a
             style={{marginLeft:'15px'}}
             className="incubator-details-link"
             data-toggle="modal"
             data-target="#showMore"
             onClick={() => setMeetingModal('Relavent Document Other')}
           >
             More...
           </a>
           :""}
            </div>
          </div>
        </div>
     
 </div>
      ):""}
      <div className="incubator-hr">
        <hr />
      </div>

      <div></div>

{/* Show More  */}
      <div
          className="modal fade"
          id="showMore"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header modal-header-border-none ml-18px">
                <h5 className="modal-title " id="exampleModalLongTitle">
                 {popUpName} <span className="eac-name-pop-up"></span>
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className={popUpName =="Industry" || popUpName =="Sector" ? "row":""}>
           {popUpName =="Industry" ? 
                      
                      Inindustrydata_Data ==undefined || Inindustrydata_Data.length == 0 ? (
                                    <span className="incubator-label-value-wrap">-</span>
                                  ) : (
                                    Inindustrydata_Data.map((industry:any) => (
                                      <div className="col-md-6"><ul className="ul-ml">
                                      <li className="incubator-label-value-wrap ul-b left-align">
                                        {industry.industry_name}
                                      </li>
                                      </ul>
                                     </div>
                                    ))
                                  )
                                  
           :
           ""}

{popUpName =="Sector" ? 
                      
                      sectorData_Data ==undefined || sectorData_Data.length == 0 ? (
                                    <span className="incubator-label-value-wrap">-</span>
                                  ) : (
                                    sectorData_Data.map((sector:any) => (
                                      <div className="col-md-6"><ul className="ul-ml">
                                      <li className="incubator-label-value-wrap ul-b left-align">
                                        {sector.industry_name}
                                      </li>
                                      </ul>
                                     </div>
                                    ))
                                  )
                                  
           :
           ""}
{popUpName =="Proof Assistance" ? 
                      
                      DocumentsData != undefined ? (
                        DocumentsData.First_Application_Documents.proof_assistance.map(
                          (file:any,index:any) => (
                            
                            <div className="row mt-05">
                              <div className="col-md-3">
                                {getFileDetails(
                                file.file,
                               'type'
                                            ) == 'pdf' ? (
                                  <img src={pdf} alt="SISFS Logo" />
                                ) : getFileDetails(
                                  file.file,
                                 'type'
                                              ) == 'doc' ? (
                                  <img src={doc} alt="SISFS Logo" />
                                ) : getFileDetails(
                                  file.file,
                                 'type'
                                              )== 'xls' ? (
                                  <img src={xls} alt="SISFS Logo" />
                                ) : (
                                  <img src={Allround} alt="SISFS Logo" />
                                )}
                              </div>
                              <div className="col-md-9 left-align">
                                <span className="document-label-value-wrap">
                              {  getFileDetails(
                                  file.file,
                                 'name'
                                              ) }
                                </span>
                                <br />
                                <a
                                  // href={file.file}
                                  href={`${File_node_url}` + (file.file)}
                                  target="_blank"
                                  // download={file.file}
                                  className="incubator-details-link"
                                >
                                  View
                                </a>
                                {/* /
                                <a
                                  href="#"
                                  // download={file.file}
                                  className="incubator-details-link"
                                  onClick={() =>
                                    downloadDocument(file.file, file.file)
                                  }
                                >
                                  Download
                                </a> */}
                              </div>
                            </div>
                            
                          )
                        )
                      ) : (
                        <span className="incubator-label-value">-</span>
                      )
                                  
           :
           ""}

{popUpName =="Incorporation Certificate" ? 
                      
                      DocumentsData != undefined ? (
                        DocumentsData.First_Application_Documents.incorporation_certificate.map(
                          (file:any,index:any) => (
                            
                            <div className="row mt-05">
                              <div className="col-md-3">
                                {getFileDetails(
                                file.file,
                               'type'
                                            ) == 'pdf' ? (
                                  <img src={pdf} alt="SISFS Logo" />
                                ) : getFileDetails(
                                  file.file,
                                 'type'
                                              ) == 'doc' ? (
                                  <img src={doc} alt="SISFS Logo" />
                                ) : getFileDetails(
                                  file.file,
                                 'type'
                                              )== 'xls' ? (
                                  <img src={xls} alt="SISFS Logo" />
                                ) : (
                                  <img src={Allround} alt="SISFS Logo" />
                                )}
                              </div>
                              <div className="col-md-9 left-align">
                                <span className="document-label-value-wrap">
                                  { getFileDetails(
                                  file.file,
                                 'name'
                                              )}
                                </span>
                                <br />
                                <a
                                  // href={file.file}
                                  href={`${File_node_url}` + (file.file)}
                                  target="_blank"
                                  // download={file.file}
                                  className="incubator-details-link"
                                >
                                  View
                                </a>
                                {/* /
                                <a
                                  href="#"
                                  // download={file.file}
                                  className="incubator-details-link"
                                  onClick={() =>
                                    downloadDocument(file.file, file.file)
                                  }
                                >
                                  Download
                                </a> */}
                              </div>
                            </div>
                            
                          )
                        )
                      ) : (
                        <span className="incubator-label-value">-</span>
                      )
                                  
           :
           ""}
{popUpName =="Annual Report" ? 
                      
                      DocumentsData.First_Application_Documents.annual_report != undefined ? (
                        DocumentsData.First_Application_Documents.annual_report.map(
                          (file:any,index:any) => (
                           
                            <div className="row mt-05">
                              <div className="col-md-3">
                                {getFileDetails(file.file,
                      'type'
                    ) == 'pdf' ? (
                                  <img src={pdf} alt="SISFS Logo" />
                                ) : getFileDetails(file.file,
                                  'type'
                                )  == 'doc' ? (
                                  <img src={doc} alt="SISFS Logo" />
                                ) : getFileDetails(file.file,
                                  'type'
                                ) == 'xls' ? (
                                  <img src={xls} alt="SISFS Logo" />
                                ) : (
                                  <img src={Allround} alt="SISFS Logo" />
                                )}
                              </div>
                              <div className="col-md-9 left-align">
                                <span className="document-label-value-wrap">
                               { getFileDetails(file.file,
                                  'name'
                                )}
                                </span>
                                <br />
                                <a
                                  // href={file.file}
                                  href={`${File_node_url}` + (file.file)}
                                  target="_blank"
                                  // download={file.file}
                                  className="incubator-details-link"
                                >
                                  View
                                </a>
                                {/* /
                                <a
                                  href="#"
                                  // download={file.file}
                                  className="incubator-details-link"
                                  onClick={() =>
                                    downloadDocument(file.file, file.file)
                                  }
                                >
                                  Download
                                </a> */}
                              </div>
                            </div>
                           
                          )
                        )
                      ) : (
                        <span className="incubator-label-value">-</span>
                      )
                                  
           :
           ""}

{popUpName =="Facilities Pictures" ? 
DocumentsData != undefined ? (
  DocumentsData.First_Application_Documents.facilities_pictures.map(
                    (file:any,index:any) => (
                      
                      <div className="row ">
                        <div className="col-md-2">
                          {getFileDetails(
                                file.file,
                               'type'
                                            ) == 'pdf' ? (
                            <img src={pdf} alt="SISFS Logo" />
                          ) : getFileDetails(
                            file.file,
                           'type'
                                        )== 'doc' ? (
                            <img src={doc} alt="SISFS Logo" />
                          ) : getFileDetails(
                            file.file,
                           'type'
                                        )== 'xls' ? (
                            <img src={xls} alt="SISFS Logo" />
                          ) : (
                            <img src={Allround} alt="SISFS Logo" />
                          )}
                        </div>
                        <div className="col-md-9 left-align">
                          <span className="document-label-value-wrap">
                      {    getFileDetails(
                                file.file,
                                'name'
                                            )}
                          </span>
                          <br />
                          <a
                            // href={file.file}
                            href={`${File_node_url}` + (file.file)}
                            target="_blank"
                            // download={file.file}
                            className="incubator-details-link"
                          >
                            View
                          </a>
                          {/* /
                          <a
                            href="#"
                            // download={file.file}
                            className="incubator-details-link"
                            onClick={() =>
                              downloadDocument(file.file, file.file)
                            }
                          >
                            Download
                          </a> */}
                        </div>
                      </div>
                     
                    )
                  )
                ) : (
                  <span className="incubator-label-value">-</span>
                ):""}
{popUpName =="Relevant Document Excel" ? 
DocumentsData != undefined
                  ? DocumentsData.First_Application_Documents.relavent_document_excel.map(
                      (file:any,index:any) => (
                        
                        <div className="row">
                          <div className="col-md-2">
                            {getFileDetails(
                                file.file,
                               'type'
                                            )== 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) :getFileDetails(
                              file.file,
                             'type'
                                          ) == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(
                              file.file,
                             'type'
                                          ) == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-10 left-align">
                            <span className="document-label-value-wrap">
                            {getFileDetails(
                                file.file,
                               'name'
                                            )}
                            </span>
                            <br />
                            <a
                              // href={file.file}
                              href={`${File_node_url}` + (file.file)}
                              target="_blank"
                              // download="RelaventExcel"
                              className="incubator-details-link"
                            >
                              View
                            </a>
                            {/* /
                            <a
                              href="#"
                              // download="relavent_document_excel"
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                          </div>
                        </div>
                       
                      )
                    )
                  : ''
                :""}
                </div>
                {popUpName =="Team Member CV" ? DocumentsData != undefined
                  ? DocumentsData.First_Application_Documents.team_members_cv.map(
                      (file:any) => (
                        <div className="row">
                          <div className="col-md-2">
                            {getFileDetails(
                                file.file,
                               'type'
                                            ) == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) :getFileDetails(
                              file.file,
                             'type'
                                          ) == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(
                              file.file,
                             'type'
                                          )== 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-10 left-align">
                            <span className="document-label-value-wrap">
                            {getFileDetails(
                                file.file,
                               'name'
                                            )}
                            </span>
                            <br />
                            <a
                              // href={file.file}
                              href={`${File_node_url}` + (file.file)}
                              target="_blank"
                              // download="TeamMember"
                              className="incubator-details-link"
                            >
                              View
                            </a>
                            {/* /
                            <a
                              href="#"
                              // download="relavent_document_excel"
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                          </div>
                        </div>
                      )
                    )
                  : ''
                :""}
 {popUpName =="Pitch Deck" ?  DocumentsData != undefined
                  ? DocumentsData.First_Application_Documents.relavent_document_pitchdeck.map(
                      (file:any) => (
                        <div className="row">
                          <div className="col-md-2">
                            {getFileDetails(
                                file.file,
                               'type'
                                            ) == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) :getFileDetails(
                              file.file,
                             'type'
                                          ) == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(
                              file.file,
                             'type'
                                          )== 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-10 left-align">
                            <span className="document-label-value-wrap">
                           {getFileDetails(
                                file.file,
                               'name'
                                            )}
                            </span>
                            <br />
                            <a
                              // href={file.file}
                              href={`${File_node_url}` + (file.file)}
                              target="_blank"
                              // download="PitchDeck"
                              className="incubator-details-link"
                            >
                              View
                            </a>
                            {/* /
                            <a
                              href="#"
                              // download="pickdeck"
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                          </div>
                        </div>
                      )
                    )
                  : ''
                :""}


{popUpName =="Relavent Document Other" ? DocumentsData != undefined
                  ? DocumentsData.First_Application_Documents.relavent_document_other.map(
                      (file:any) => (
                        <div className="row">
                          <div className="col-md-2">
                            { getFileDetails(
                              file.file,
                             'type'
                                          )== 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) :  getFileDetails(
                              file.file,
                             'type'
                                          ) == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) :  getFileDetails(
                              file.file,
                             'type'
                                          ) == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-10 left-align">
                            <span className="document-label-value-wrap">
                             { getFileDetails(
                              file.file,
                             'name'
                                          )}
                            </span>
                            <br />
                            <a
                              // href={file.file}
                              href={`${File_node_url}` + (file.file)}
                              target="_blank"
                              // download="Relavent"
                              className="incubator-details-link"
                            >
                              View
                            </a>
                            {/* /{' '}
                            <a
                              href="#"
                              // download="otherdocument"
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                          </div>
                        </div>
                      )
                    )
                  : ''
                :""}

{popUpName =="Self Declaration" ? DocumentsData != undefined
                  ? DocumentsData.First_Application_Documents.self_declaration_upload.map(
                      (file:any) => (
                        <div className="row">
                          <div className="col-md-2">
                            { getFileDetails(
                              file.file,
                             'type'
                                          )== 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) :  getFileDetails(
                              file.file,
                             'type'
                                          ) == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) :  getFileDetails(
                              file.file,
                             'type'
                                          ) == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-10 left-align">
                            <span className="document-label-value-wrap">
                             { getFileDetails(
                              file.file,
                             'name'
                                          )}
                            </span>
                            <br />
                            <a
                              // href={file.file.toString()}
                              href={`${File_node_url}` + (file.file).toString()}
                              target="_blank"
                              // download="Relavent"
                              className="incubator-details-link"
                            >
                              View
                            </a>
                            {/* /{' '}
                            <a
                              href="#"
                              // download="otherdocument"
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, "Self_Declaration")
                              }
                            >
                              Download
                            </a> */}
                          </div>
                        </div>
                      )
                    )
                  : ''
                :""}

                <div className="row">
                  <div className="col-md-12 centerText">
                    <div className="btn-group mt-1-5 ">
                      <button
                        className="tertiary  btn-yellow-primary"
                        data-dismiss="modal"
                      >
                        OK
                      </button>

                   
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

    </div>
  );
}
export default MergedFirstApplicationDetailsComponent;
