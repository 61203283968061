import React, { useEffect } from 'react';

import CarouselComponent from '../common/carousel/CarouselComponent';
import HomeInitiativeComponent from './HomeInitiativeComponent';
import HomeOperationsComponent from './HomeOperationsComponent';
import HomeFeaturesComponent from './HomeFeaturesComponent';
import HomeEligibilityComponent from './HomeEligibilityComponent';
import HomeTestimonialsComponent from './HomeTestimonialsComponent';
import HomeApplyNowComponent from './HomeApplyNowComponent';
import ApplyNowModalComponent from '../common/header/ApplyNowModalComponent';
import flashIcon from '../../images/flashIcon.png'
function HomeContainer() {
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <main role="main">
      <div className="app-home">
      <div style={{backgroundColor:'#f8d874',display:'flex',alignItems:'center',justifyContent:'center'}}>
      <div className="train-container">
      <div className="train-text-wrapper">
        <p className="train-text mb-0 p-1">
       <span  style={{width:'60px',height:'60px'}}><img className='m-auto' src={flashIcon} alt="" style={{width:'inherit',objectFit:'cover'}} /></span>  Applications for Startup India Seed Fund Scheme are open throughout the year and can be filled independently by the startups, free of cost. Startup India does not have or promote any authorised consultants or vendors to help startups apply for the scheme.
          
        </p>
        <p className="train-text mb-0 p-1">
        <span  style={{width:'60px',height:'60px'}}><img className='m-auto' src={flashIcon} alt="" style={{width:'inherit',objectFit:'cover'}} /></span> Applications for Startup India Seed Fund Scheme are open throughout the year and can be filled independently by the startups, free of cost. Startup India does not have or promote any authorised consultants or vendors to help startups apply for the scheme.

        </p>
      </div>
    </div>
      </div>
        
        {/* Carousel for SISFS */}
        <CarouselComponent />
        {/* Carousel for SISFS */}

        {/* section-initiative for SISFS */}
        <HomeInitiativeComponent />
        {/* section-initiative for SISFS */}

        {/* section-operation for how SISFS operate*/}
        <HomeOperationsComponent />
        {/* section-operation for how SISFS operate*/}

        {/* section-features of SISFS*/}
        <HomeFeaturesComponent />
        {/* section-features of SISFS*/}

        {/* section-eligibility for SISFS*/}
        <HomeEligibilityComponent />
        {/* section-eligibility for SISFS*/}

        {/* section-testimonials for SISFS*/}
        <HomeTestimonialsComponent />
        {/* section-testimonials for SISFS*/}

        {/* section-apply now for SISFS*/}
        <HomeApplyNowComponent />
        {/* section-apply now for SISFS*/}
        {/* apply now modal */}
        <ApplyNowModalComponent />

      </div>
    </main>
  );
}

export default HomeContainer;
