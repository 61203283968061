import bannerimage from '../../../images/banner-image.svg';
import playButton from '../../../images/play-button-blue.svg'
import orangArrow from '../../../images/orangearrow.svg'
import { Link } from 'react-scroll'
import { downloadDocument } from '../../../utils/CommonMethods';
import { get } from '../../../effects/api/api.common';
import { useEffect, useState } from 'react';
import { EVALUATION_FILE } from '../../../constants/api';

function CarouselComponent() {
 
  return (

    <div id="app-carousel" className="carousel slide" data-ride="carousel">
      <ol className="carousel-indicators">
        {/* <li data-target="#app-carousel" data-slide-to="0" className="active"></li> */}
        {/* <li data-target="#app-carousel" data-slide-to="1"></li>
      <li data-target="#app-carousel" data-slide-to="2"></li> */}
      </ol>
      <div className="carousel-inner">
        <div className="carousel-item active">

          <div className="container">
            <div className="row justify-content-end">
              <div className="col-xs-12 col-sm-12 col-md-6">
                <div className="carousel-caption text-left">
                  <h1>Startup India</h1>
                  <h1>Seed Fund Scheme</h1>
                  <p>Financial assistance to startups for proof of concept, prototype development, product trials, market entry, and commercialization</p>
                  <p><a className="btn btn-large btn-sisfs btn-yellow-primary" data-toggle="modal" data-target="#ApplyNowModal">Apply Now</a></p>

                  <Link activeClass="active" to="home-testimonial" offset={-125} spy={true} smooth={true}>
                    <div className="call-applicaton play-span text-center d-inline-flex mt-3" >

                      <img src={playButton} className="img-fluid mr-3" alt="SISFS Logo" />
                      <h6 className="pt-2">Startup India Seed Fund Scheme Launch
                  </h6>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="col-xs-12 col-sm-12 col-md-6">
                <img src={bannerimage} className="img-fluid" alt="SISFS Logo" />
              </div>
            </div>

            {/* <div className="carousel-caption text-left">
                <span className="caption-tagline">Startup India</span>
                <h1>Seed Fund Scheme</h1>
                <p>Financial assistance to startups for proof of concept, prototype development, product trials, market entry, and commercialization</p>
                <p><a className="btn btn-sisfs btn-yellow-primary" href="#">Apply Now</a></p>
            </div> */}
          </div>
        </div>
        {/* <div className="carousel-item">
        <svg className="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" role="img" aria-label=" :  " preserveAspectRatio="xMidYMid slice" focusable="false"><title> </title><rect width="100%" height="100%" fill="#777"/><text x="50%" y="50%" fill="#777" dy=".3em"> </text></svg>

        <div className="container">
          <div className="carousel-caption">
            <h1>Another example headline.</h1>
            <p>Some representative placeholder content for the second slide of the carousel.</p>
            <p><a className="btn btn-lg btn-primary" href="#">Learn more</a></p>
          </div>
        </div>
      </div> */}
        {/* <div className="carousel-item">
        <svg className="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" role="img" aria-label=" :  " preserveAspectRatio="xMidYMid slice" focusable="false"><title> </title><rect width="100%" height="100%" fill="#777"/><text x="50%" y="50%" fill="#777" dy=".3em"> </text></svg>

        <div className="container">
          <div className="carousel-caption text-right">
            <h1>One more for good measure.</h1>
            <p>Some representative placeholder content for the third slide of this carousel.</p>
            <p><a className="btn btn-lg btn-primary" href="#">Browse gallery</a></p>
          </div>
        </div>
      </div> */}
      </div>
      {/* <a className="carousel-control-prev" href="#app-carousel" role="button" data-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      <span className="sr-only">Previous</span>
    </a>
    <a className="carousel-control-next" href="#app-carousel" role="button" data-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"></span>
      <span className="sr-only">Next</span>
    </a> */}
    </div>

  )
}

export default CarouselComponent;