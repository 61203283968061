import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getSessionStorageValue } from '../../effects/utils/session-storage';
import ContactUsComponent from './ContactUsComponent';
import GrievancesComponent from './GrievancesComponent';
import { GET_APPLICATION_LIST, STARTUP_ALL_APPLICATIONS } from '../../constants/api';
import { post } from '../../effects/api/api.common';

function ContactContainer(props: any) {
    const [activeIndex, setActiveIndex] = useState("contact");
    const [activeTab, setActiveTab] = useState("contact");
    const [showbutton, setShowButton] = useState(false);
    const [draftStatusLabel, setDraftStatusLabel] = useState<any>("");
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)

    useEffect(() => {
        window.scrollTo(0, 0)
        
        if ((typeof props.location.state !== 'undefined') && (props.location.state != null)) {
            if (props.location.state.selected_tab == "grievance") {
                setActiveTab("grievance");
                setActiveIndex("grievance");
            }
        }
        if (getSessionStorageValue("user")) {
            const user: any = getSessionStorageValue("user");
            const userInfo = JSON.parse(user)
            if (userInfo.roles == 'incubator') {
                setShowButton(true);
            }
            else if (userInfo.roles == 'dpiit') {
                setShowButton(false)
            }
            else if (userInfo.roles == 'secretariat') {
                setShowButton(false)
            }
            else if (userInfo.roles == 'startup') {
                setShowButton(true)
            }
        }
        else {
            setShowButton(false);
        }
    }, []);
    useEffect(()=>{
        getApplicationList();
    },[])
    const changeActive = (tabs: any) => {
        setActiveIndex(tabs)
    }



    const getApplicationList = () => {

        if (userInfo && userInfo.roles == 'incubator') {
            var payload = {
                "logged_user_id": userInfo.id,
                "roles": userInfo.roles
            }
            post(GET_APPLICATION_LIST, payload).then((response: any) => {
                if (response.data.data.length > 0) {
                    setDraftStatusLabel(response.data.data[0].status)
                }

            });
        } else if (userInfo && userInfo.roles == 'startup') {
            var payload2 = {
                "get_list": "",
                "logged_user_id": userInfo.id,
                "user_role": userInfo.roles
            }
            post(STARTUP_ALL_APPLICATIONS, payload2).then((response: any) => {
                if (!response || !response.data) return;
                if (response.data.data.length != 0 && response.data.data.InProgress.length != 0) {
                    setDraftStatusLabel(response.data.data.InProgress[0].application_status_lable);
                }
            });
        }

    };

    return (
        <div className="app-inside app-contact">

            {/* Inside banner section */}
            <div className="container-fluid about-heading faq-container">
                <div className="text-center main-heading faq-heading">
                    {activeIndex == "contact" ? "Contact Us" : "Grievances"}
                </div>

                {/* <!-- Tab Links --> */}
                {/* <div className="text-center">                   
                    <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" data-toggle="tab" href="#tabs-1" role="tab">Contact Us</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="tab" href="#tabs-2" role="tab">Grievance</a>
                        </li>
                    </ul>
                </div> */}
                <div className="text-center objectives-nav faq-container">
                    {/* <ul className="nav nav-tabs">
                            <li className="active">
                                <a  data-toggle="tab" href="#tabs-1">Contact Us</a>
                            </li>
                            <li><a data-toggle="tab" href="#tabs-2">Grievances</a>
                            </li>
                        </ul> */}
                        
                    {/* <ul className="nav nav-tabs pl-5 ml-3">
                        {((userInfo && userInfo.roles != 'incubator') && (userInfo && userInfo.roles != 'startup')) && <li className={activeIndex == "contact" ? "active" : ""} onClick={() => changeActive('contact')}>
                            <a data-toggle="tab" href="#contact">Contact Us</a>
                        </li>}
                        {showbutton && ((userInfo && userInfo.roles != 'incubator') && (userInfo && userInfo.roles != 'startup')) ? <li className={activeIndex == "grievance" ? "active" : ""} onClick={() => changeActive('grievance')}><a data-toggle="tab" href="#grievance">Grievances</a>
                        </li> : null}
                    </ul> */}

                </div>
            </div>

            {/* <!-- Tab panes --> */}
            <div className="tab-content">

                {draftStatusLabel == "Draft" && userInfo && (userInfo.roles == 'incubator' || userInfo.roles == 'startup') ? <div className={activeTab == "contact" ? "tab-pane active" : "tab-pane"} id="contact" role="tabpanel">
                    <ContactUsComponent />
                </div> : <div className={activeTab == "contact" ? "tab-pane active" : "tab-pane"} id="contact" role="tabpanel">
                    <ContactUsComponent />
                </div>}

                {draftStatusLabel != "Draft" && userInfo && (userInfo.roles == 'incubator' || userInfo.roles == 'startup') ? <div className={activeTab == "grievance" ? "tab-pane active" : "tab-pane"} id="grievance" role="tabpanel">
                    <GrievancesComponent />
                </div> : <div className={activeTab == "grievance" ? "tab-pane active" : "tab-pane"} id="grievance" role="tabpanel">
                    <GrievancesComponent />
                </div>}

                <section className="section-container section-bg-yellow">
                    <div className="container">
                        <div className="section-apply">
                            <div className="section-heading">
                                <h2>The Most Frequently Asked Questions About <br />
                                    Startup India Seed Fund Scheme</h2>
                            </div>
                        </div>

                        <div className="section-action">
                            {/* <a className="btn btn-large btn-sisfs btn-blue-primary" href="#">Find Them Here</a> */}
                            <Link className="btn btn-large btn-sisfs btn-blue-primary" to="/faq">Find Them Here</Link>
                        </div>
                    </div>
                </section>

            </div>
        </div>
    )
}

export default ContactContainer;