import React, { useEffect, useState, useContext } from "react";
import { LOGIN, GET_APPLICATION_LIST, ALLOW_INCUBATOR_CREATION, LOGIN_STARTUP, EVALUATION_FILE, STARTUP_ALL_APPLICATIONS, NODE_URL } from "../../../constants/api";
import { get, post } from "../../../effects/api/api.common";
import Lock from '../../../images/lock.svg';
import { DATA_SECRET, getSessionStorageValue, setSessionStorageValue, } from "../../../effects/utils/session-storage";
import { LoginModel } from "../../../models/LoginModel";
import { useAuthStateValue } from "../../../providers/AuthStateProvider";
import { Action } from "../../../reducers/reducer.action";
import { useHistory } from "react-router-dom";
import UserInfoModel from "../../../models/UserInfoModel";
import { validateEmail } from "../../../effects/utils/validations";
import ForgotPasswordComponent from "./ForgotPasswordComponent";
import orangArrow from '../../../images/orangearrow.svg'
import AuthContext, { AuthContextType } from "../../../Contexts/AuthContext";
import errorimg from '../../../images/error.svg';
import jwt from 'jsonwebtoken-promisified';

declare var $: any;
interface IIndexer {
  [key: string]: string;
}
function LoginComponent() {
  const history = useHistory();
  const initModel = new LoginModel("", "");
  const { setAuth } = React.useContext(AuthContext) as AuthContextType;
  const [login, setLogin] = useState<LoginModel>(initModel);
  const [error, setError] = useState<IIndexer>();
  const [activeIndex, setActiveIndex] = useState("login-startup");
  const [loader, setLoader] = useState(false);
  const [loginPopup, setLoginPopup] = useState(false);
  const [loginPopupData, setLoginPopupData] = useState<any>();
  const [startupLoginPopup, setStartupLoginPopup] = useState(false);
  const [startupLoginPopupData, setStartupLoginPopupData] = useState<any>();
  // @ts-ignore: Unreachable code error
  const [{ }, dispatch] = useAuthStateValue();

  const [PPTPath, setPPTPath] = useState('');
  const [PPTPathStartup, setPPTPathStartup] = useState('');
  useEffect(() => {
    let isMounted = true;
    // console.log(isMounted, "In")
    const getPPTPath = () => {
      get(EVALUATION_FILE).then((response: any) => {
        if (isMounted) {
          if (!response.data) return;
          setPPTPath(response.data.seedFundGuidePath);
          setPPTPathStartup(response.data.startupGuidePath)
        }
      });
    }
    getPPTPath();
    return () => {
      isMounted = false;
      // console.log(isMounted, "out@@")
    };
  }, []);
  useEffect(() => {

  }, [loginPopup, startupLoginPopup, loginPopupData, startupLoginPopupData])
  // const getPPTPath = () => {
  //   get(EVALUATION_FILE).then((response: any) => {
  //     if (!response.data) return;
  //     setPPTPath(response.data.seedFundGuidePath);
  //     setPPTPathStartup(response.data.startupGuidePath)
  //   });
  // }

  // triggers whenever username and password is being changed by the user
  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {

    if (evt.target.name == "email") {
      setLogin((prevState: LoginModel) => {
        return {
          ...prevState,
          [evt.target.name]: evt.target.value.replace(/[^a-zA-Z0-9_%@!$*~.-]/ig, ''),
        };
      });
    } else {
      setLogin((prevState: LoginModel) => {
        return {
          ...prevState,
          [evt.target.name]: evt.target.value,
        };
      });
    }

  };

  // triggers when login is performed
  const onLogin = () => {
    // console.log("sri@@")
    // const loginModel = new LoginModel(login.password, login.email);
    setLoader(true);
    let enteredPassword = login.password
    let JWT_SECRET = 'Laravel Password Grant Client';
    let payload =   {enteredPassword} ; 
    var token = jwt.sign(payload, JWT_SECRET);
    console.log('JWT Token:', token);
    const payloaddata = {
      email: login.email,
      password: token
    }; 
    post(LOGIN, payloaddata).then((response: any) => {
      setLoader(false); 
      // return;
      $("#LoginCollapse").modal('hide');
      //   if (response?.response?.data?.status !== 200) {
      //     const errorMessage = response.response
      //       ? response.response?.data?.message
      //       : "";
      //     setError({ loginFailed: errorMessage });
      //     return;
      //   }
      // if (response.data.message) {
      //   alert(response.data.message);
      //   return;
      // }
      error && delete error["loginFailed"];
      setAuth(response.data)
      if (!response || !response.data) return;
      if (response.data.message) {
        
        setLoginPopup(true)
        setLoginPopupData(response.data.message)
        // alert(response.data.message)
      }


      // capture the access_token and store it
      const access_token: string = response.data.data[0].access_token;
      const user = response.data.data[0];
      localStorage.setItem("dipp_num", response.data.data[0].dipp_number)
      // console.log(response.data.user.dipp_number,"num")
      if (access_token) {
        setAuth(user);
        localStorage.removeItem("inactivity")
        const userInfo: UserInfoModel = new UserInfoModel(
          user?.name,
          user?.email,
          user?.id,
          user?.roles,
          access_token
        );

        // encrypt before saving into storage 'userInfo'
        // const securedata = Crypto.AES.encrypt(JSON.stringify(userInfo), DATA_SECRET);

        setSessionStorageValue("user", JSON.stringify(userInfo));

        dispatch({
          type: Action.SET_USER,
          payload: userInfo,
        });

        if (userInfo.roles === 'incubator') {

          var payload2 = {
            "logged_user_id": userInfo.id,
            "roles": userInfo.roles,
            "get_list": "incubator_prefrences"
          }

          post(GET_APPLICATION_LIST, payload2).then((response: any) => {
            // setLoader(false);
            if (!response || !response.data) return;
            if (response.data.registeredName.registered_name !== undefined && response.data.registeredName.registered_name !== null) {
              localStorage.setItem("incubatorName", response.data.registeredName.registered_name)
            }
            if (response.data.length == 0) {

              setSessionStorageValue("hide", "hide_flag")
              history.push('/incubator_apply')

            }
            else {
              history.push('/incubator_status')
            }

          });

        }

        else if (userInfo.roles === 'startup') {

          const user: any = getSessionStorageValue("user");
          const userInfo = JSON.parse(user)

          var payload = {
            "get_list": "",
            "logged_user_id": userInfo.id,
            "user_role": userInfo.roles,
          }
          // setLoader(true);
          post(STARTUP_ALL_APPLICATIONS, payload).then((response: any) => {
            // setLoader(false);
            if (!response || !response.data) return;
            // console.log(response.data.data);
            // console.log(response.data.data.length);

            if (response.data.data.length == 0) {
              history.push('/startup_status')
            }
            if ((response.data.data.length != 0 && response.data.data.InProgress.length == 0) && (response.data.data.length != 0 && response.data.data.Selected.length == 0) && (response.data.data.length != 0 && response.data.data.Rejected.length == 0)) {

              const user: any = getSessionStorageValue("user");
              const userInfo = JSON.parse(user)

              var payload = {
                "logged_user_id": userInfo.id,
                "get_list": "incubator_prefrences",
                "user_role": userInfo.roles
              }
              // setLoader(true);
              post(STARTUP_ALL_APPLICATIONS, payload).then((res: any) => {
                // setLoader(false);
                if (!res || !res.data) return;
                if (res.data.data.length != 0) {
                  history.push('/startup_status')
                }
                else {
                  setSessionStorageValue("hide", "hide_flag")
                  history.push('/startup_apply')
                }
              })

            }
            else {
              history.push('/startup_status')
            }

          });

        }

        // history.replace("/incubator_apply");
        else if (userInfo.roles === 'dpiit')
          history.replace("/merged_list");
        else if (userInfo.roles === 'secretariat')
          history.replace("/merged_list");
        else if (userInfo.roles === 'eac')
          history.replace("/merged_list");
        else if (userInfo.roles === 'startup')
          history.replace("/startup_status");
      }
    });
  };


  const onLoginStartup = () => {
    // console.log("Helloooooo")
    // const loginModel = new LoginModel(login.password, login.email);
    setLoader(true);

    let enteredPassword = login.password
    let JWT_SECRET = 'Laravel Password Grant Client';
    let payload =   {enteredPassword} ; 
    var token = jwt.sign(payload, JWT_SECRET);
    console.log('JWT Token:', token);
    const payloaddata = {
      email: login.email,
      password: token
    }; 


    post(LOGIN_STARTUP, payloaddata).then((response: any) => {
      setLoader(false);
      error && delete error["loginFailed"];
      if (!response || !response.data) return;
      setAuth(response.data)
      if (response.data.message) {
        setStartupLoginPopupData(response.data.message)
        setStartupLoginPopup(true);
        // alert(response.data.message)
      }
      // capture the access_token and store it
      const access_token: string = response.data.access_token;
      const user = response.data.user;
      if (response.data.user.dipp_number != undefined) {
        localStorage.setItem("dipp_num", response.data.user.dipp_number)
      }

      if (access_token) {
        const userInfo: UserInfoModel = new UserInfoModel(
          user?.name,
          user?.email,
          user?.id,
          user?.roles,
          access_token
        );
        // console.log(access_token)
        // console.log(response.data.user)
        // encrypt before saving into storage 'userInfo'
        // const securedata = Crypto.AES.encrypt(JSON.stringify(userInfo), DATA_SECRET);
        //  console.log(userInfo,"userInfo!!!!")
        setSessionStorageValue("user", JSON.stringify(userInfo));

        dispatch({
          type: Action.SET_USER,
          payload: userInfo,
        });

        if (userInfo.roles === 'incubator') {
          var payload1 = {
            "logged_user_id": userInfo.id,
            "roles": userInfo.roles
          }
          // setLoader(true);
          post(GET_APPLICATION_LIST, payload1).then((response: any) => {
            // setLoader(false);
            if (!response || !response.data) return;
            if (response.data.registeredName.registered_name !== undefined && response.data.registeredName.registered_name !== null) {
              localStorage.setItem("incubatorName", response.data.registeredName.registered_name)
            }
            if (response.data.length == 0) {

              setSessionStorageValue("hide", "hide_flag")
              history.push('/incubator_apply')

            }
            else {
              history.push('/incubator_status')
            }

          });

        }

        else if (userInfo.roles === 'startup') {
          const user: any = getSessionStorageValue("user");
          const userInfo = JSON.parse(user)
          // console.log(userInfo)
          //  console.log(userInfo,"oppp")
          var payload = {
            "logged_user_id": userInfo.id,
            "get_list": "",
            "user_role": userInfo.roles
          }
          // setLoader(true);
          post(STARTUP_ALL_APPLICATIONS, payload).then((response: any) => {
            // setLoader(false);
            if (!response || !response.data) return;

            if (response.data.data.length == 0) {
              history.push('/startup_status')
            }

            if (response.data.data.InProgress.length == 0 && response.data.data.Selected.length == 0 && response.data.data.Rejected.length == 0) {

              const user: any = getSessionStorageValue("user");
              const userInfo = JSON.parse(user)

              var payload = {
                "logged_user_id": userInfo.id,
                "get_list": "incubator_prefrences",
                "user_role": userInfo.roles
              }
              // setLoader(true);
              post(STARTUP_ALL_APPLICATIONS, payload).then((res: any) => {
                // setLoader(false);
                if (!res || !res.data) return;
                if (res.data.data.length != 0) {
                  history.push('/startup_status')
                }
                else {
                  setSessionStorageValue("hide", "hide_flag")
                  history.push('/startup_apply')
                }
              })

            }
            else {
              history.push('/startup_status')
            }

          });

        }

        // history.replace("/incubator_apply");
        else if (userInfo.roles === 'dpiit')
          history.replace("/merged_list");
        else if (userInfo.roles === 'secretariat')
          history.replace("/merged_list");
        else if (userInfo.roles === 'eac')
          history.replace("/merged_list");
      }

    });
  };

  const changeActive = (tabs: any) => {
    setActiveIndex(tabs)
  }
  const dismissModal = () => {
    setLoginPopup(false);
    setStartupLoginPopup(false);
  }
  return (
    <>
      {loader &&
        <div className="spinner-border custom-spinner" role="status">
          <span className="sr-only">Loading...</span>
        </div>}
      <fieldset>
        <div className="modal fade" id="LoginCollapse" role="dialog" aria-labelledby="LoginCollapseModal" aria-hidden="true">
          <div className="modal-dialog modal-dialog-slideout" role="document">
            <div className="modal-content">
              <div className="login py-3">
                <div className="login-wrapper">
                  <form className="form-signin" action="" method="post">

                    <button type="button" className="close btn" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>

                    <fieldset className="login-fieldset">
                      {/* <div className="text-center mb-4">
                    <img src={Lock} className="img-fluid text-center" alt="SISFS LOGIN" />
                  </div> */}

                      <fieldset disabled={loader}>
                        <h1 className="login-heading text-center mb-3">Login</h1>
                        <div className="login-nav mb-4 pl-1">
                          <ul className="nav nav-tabs" >
                            <li className={activeIndex == "login-startup" ? "active" : ""} ><a href="#login-startup" onClick={() => changeActive('login-startup')} data-toggle="tab" className="text-decoration-none">Incubator/Startup Credentials</a>
                            </li>
                            <li className={activeIndex == "login" ? "active" : ""} >
                              <a href="#login" data-toggle="tab" onClick={() => changeActive('login')} className="text-decoration-none">DPIIT/EAC <br />Credentials</a>
                            </li>

                          </ul>

                        </div>
                        {/* <ul className="nav nav-tabs">
                            <li className="mr-1">
                                <a href="#login" data-toggle="tab"><h1 className="mb-4 login-heading tab-login">Login</h1></a>
                            </li>
                            <li><a href="#login-startup" data-toggle="tab"><h1 className="mb-4 login-heading tab-login devider-before">Startup India Login</h1></a>
                            </li>
                        </ul> */}
                        {/* <h1 className="mb-4 login-heading">Login</h1> */}
                        <div className="tab-content login-div">
                          <div className="tab-pane" id="login">
                            <div className="form-group row">
                              <div className="col-sm-12">
                                <input
                                  type="text"
                                  id="email"
                                  name="email"
                                  // className={`${validateEmail(login.email) ? `form-control error` : 'form-control'}`}
                                  className="form-control h-25"
                                  placeholder="Username"
                                  value={login.email}
                                  required
                                  onChange={onChange}
                                />
                              </div>
                            </div>
                            <div className="form-group row  mt-4">
                              <div className="col-sm-12">
                                <input
                                  type="password"
                                  id="password"
                                  name="password"
                                  className="form-control  h-25"
                                  placeholder="Password"
                                  value={login.password}
                                  required
                                  onChange={onChange}
                                />
                              </div>
                            </div>
                            {error && error["loginFailed"] && (
                              <p className="text-danger">{error["loginFailed"]}</p>
                            )}
                            <div className="form-group row mt-4 mb-4">
                              <div className="col-sm-12">
                                <button
                                  id="btnLogIn"
                                  className="btn w-100 btn-sisfs btn-yellow-primary"
                                  name="button"
                                  value="login"
                                  type="button"
                                  onClick={onLogin}
                                // data-dismiss="modal"
                                >
                                  Login
                                </button>
                              </div>

                            </div>
                            <div className="form-group row text-center">
                              <div className="col-sm-12">
                                <span className="link-blue-text" data-dismiss="modal" data-toggle="modal" data-target="#forgotPassword">
                                  Forgot Password ?
                                </span>
                              </div>
                            </div>
                            {/* <div className="form-group row startup-account-tab text-center">
                    <div className="col-sm-12">
                      <span>
                        Don’t have Startup India login credentials yet?<br />
                      </span>
                      <a href="https://www.startupindia.gov.in/content/sih/en/registration.html" target="_blank" className="link-orange-text ml-2">
                        Create an Account
                      </a>
                    </div>
                  </div> */}
                          </div>
                          <div className="tab-pane active" id="login-startup">
                            <div className="form-group row">
                              <div className="col-sm-12">
                                <input
                                  type="text"
                                  id="email"
                                  name="email"
                                  // className={`${validateEmail(login.email) ? `form-control error` : 'form-control'}`}
                                  className="form-control h-25"
                                  placeholder="Username"
                                  value={login.email}
                                  required
                                  onChange={onChange}
                                />
                              </div>
                            </div>
                            <div className="form-group row  mt-4">
                              <div className="col-sm-12">
                                <input
                                  type="password"
                                  id="password"
                                  name="password"
                                  className="form-control h-25"
                                  placeholder="Password"
                                  value={login.password}
                                  required
                                  onChange={onChange}
                                />
                              </div>
                            </div>
                            {error && error["loginFailed"] && (
                              <p className="text-danger">{error["loginFailed"]}</p>
                            )}
                            <div className="form-group row mt-4">
                              <div className="col-sm-12">
                                <button
                                  id="btnLogIn1"
                                  className="btn w-100 btn-sisfs btn-yellow-primary"
                                  name="button"
                                  value="login"
                                  type="button"
                                  onClick={onLoginStartup}
                                  data-dismiss="modal"
                                > Login
                                </button>
                              </div>
                            </div>
                            {/* <div className="form-group row text-center">
                        <div className="col-sm-12">
                          <span className="link-blue-text" >
                            Forgot Password ?
                    </span>
                        </div>
                      </div> */}
                            <div className="note text-center">
                              <span className="footnote-text">
                                Please use your Startup India Credentials to Login/Apply
                              </span>
                            </div>
                            <div className="form-group row text-center">
                              <div className="col-sm-12">
                                <span>
                                  Don’t have Startup India login credentials yet?<br />
                                </span>
                                <a href="https://www.startupindia.gov.in/content/sih/en/registration.html" target="_blank" className="link-orange-text ml-2">
                                  Create an Account
                                </a> &nbsp;&nbsp;&nbsp;
                                <a href="https://www.startupindia.gov.in/content/sih/en/home-page.html#" target="_blank">
                                  <span className="link-blue-text">
                                    Forgot Password ?
                                  </span>
                                </a>
                              </div>
                            </div>


                            <hr className="mt-4" />
                            <div className="text-center mt-4">
                              <a className="btn btn-large btn-sisfs btn-transparent-orange-startup ml-3 pl-3 pl-3" href={`${NODE_URL}` + PPTPathStartup} target="_blank" >Startup Application GUIDE BOOK <img src={orangArrow} className="img-fluid ml-2 pb-1" alt="SISFS Logo" /></a>
                            </div>
                            <div className="text-center mt-3">
                              <a className="btn btn-large btn-sisfs btn-transparent-orange ml-3" href={`${NODE_URL}` + PPTPath} target="_blank" >Incubator Application GUIDE BOOK <img src={orangArrow} className="img-fluid ml-2 pb-1" alt="SISFS Logo" /></a>
                            </div>

                          </div>
                        </div>

                      </fieldset>

                      {/* <div className="form-group row text-center">
                    <div className="col-sm-12">
                      <a href="#" className="link-blue-text">
                        Forgot Password ?
                    </a>
                    </div>
                  </div> */}


                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ForgotPasswordComponent />
      </fieldset>

      {loginPopup && (
        // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
        <>
          <div className="modal-backdrop fade show"></div>

          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionSuccess"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <span><img src={errorimg} alt="" style={{ width: '40px' }} /></span>
                  <h3 className="modal-message mt-2" style={{ color: '#e26640' }}>{loginPopupData}</h3>
                  <div className="modal-action">
                    <button className="btn btn-sisfs btn-yellow-primary" onClick={dismissModal}>
                      Ok!!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {startupLoginPopup && (
        // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
        <>
          <div className="modal-backdrop fade show"></div>

          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionSuccess"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <h5 className="modal-message" style={{ color: '#e26640' }}>{startupLoginPopupData}</h5>
                  <div className="modal-action">
                    <button className="btn btn-sisfs btn-yellow-primary mt-3" onClick={dismissModal}>
                      Ok!!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default LoginComponent;
