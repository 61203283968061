import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import downoadFile from '../../../images/ReturnsIcons/downloads.svg';
import circle_exclamation from '../../../images/ReturnsIcons/circle_exclamation.svg';
import circle_check from '../../../images/ReturnsIcons/circle_check.svg';
import {
  QUARTERLY_R_ACTIVATE,
  QUARTERLY_R_DEACTIVATE,
  QUARTERLY_R_BUCKET_COUNT,
  QUARTERLY_R_GET_ACTIVE,
  QUARTERLY_R_TABLE_LIST,
  QUARTERLY_R_DETAILS,
  QUARTERLY_R_CLOSE,
  QUARTERLY_R_REMINDER,
  QR_EXCEL_DOWNLOAD
} from '../../../constants/api';
import { post, get } from '../../../effects/api/api.common';
import ConfirmationDialog from '../../common/controls/ConfirmationDialog';
import ReactDatePicker from 'react-datepicker';
import moment, { duration } from 'moment';
import ProgressBar from './ProgressBar';
import search from '../../../images/search.svg';
import * as XLSX from 'xlsx';
import { filterByValue } from '../../../utils/CommonMethods';
import ReactPaginate from 'react-paginate';
import QrSortingComponent from './QrSortingComponent';
import { exportToExcelMultipleSheets } from "../../../constants/ExcelServices";
import download_disabled from '../../../images/ReturnsIcons/download_disabled.svg';
declare var $: any;

function QrViewComponent(props: any) {
  const history = useHistory();
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [SortData, setSortData] = useState<any>("");
  const [SortDataFilter, setSortDataFilter] = useState<any>(0);
  const [SortDataFilter2, setSortDataFilter2] = useState<any>(0);
  const [sortComponent1, setSortComponent1] = useState<any>(0);
  const [sortComponent11, setSortComponent11] = useState<any>(0);
  const [sortComponent2, setSortComponent2] = useState<any>(0);
  const [sortComponent22, setSortComponent22] = useState<any>(0);
  const [sortArror, setsortArror] = useState(false);

  const [qValue, setQvalue] = useState<any>(0);
  const [year, setYear] = useState<any>(0);
  const [tableData, setTabledata] = useState<any>([]);
  const [tableData1, setTabledata1] = useState<any>([]);
  const [loader, setLoader] = useState(false);
  const [noDataFilter, setNoDataFilter] = useState('');
  const [filteredUserList, setFilteredUserList] = useState<any>([]);
  const [selectedCheckboxIds, setSelectedCheckboxIds] = useState<any>([]);
  const [yqdetails, setYqdetails] = useState<any>([]);
  // const [selectedInd, setSelectedInd] = useState<any>([]);
  // const [activetype,setActivetype]=useState("")
  // const [status,setStatus]=useState("")
  const [total, setTotal] = useState<any>({
    selectedInd: [],
    activetype: '',
    status: '',
  });
  const [detotal, setDetotal] = useState<any>({
    selectedInd: [],
    activetype: '',
    status: '',
  });
  const [remaindertotal, setremaindertotal] = useState<any>({
    selectedInd: [],
    activetype: '',
    status: '',
  });
  const [checkCnt, setCheckcnt] = useState(0);
  const [bucketCounts, setBucketCounts] = useState<any>([]);
  const [subBucketCounts, setSubBucketCounts] = useState<any>([]);
  const [allCounts, setAllCounts] = useState<any>([]);
  const [optiondsb, setOptiondsb] = useState(false);
  const [qrid, setQrid] = useState(0);
  const [showIncubatorFailureModal1, setShowIncubatorFailureModal1] =
    useState(false);
  const [buttonen, setButtonen] = useState(false);
  const [currentQuarter, setCurrentQuarter] = useState<number>(1);
  const [chooseYear, setChooseYear] = useState<any>([])
  var chooseQuarter: any = [];
  const [newQuarter, setNewQuarter] = useState([])
  const [ChangeYearmonth, setChangeYearmonth] = useState({
    year: "",
    quarter: "",
  });
  const [yearV, setYearValue] = useState(0);
  const [quaterValue, setQuaterValue] = useState(0);
  const [quater1, setQuater] = useState('');
  const [reminderDate, setReminder] = useState<any>("")
  const [active, setActive] = useState(false);

  const [failDialogData, setFailDialogData] = useState({
    header: '',
    text: '',
  });
  const [dialogData, setDialogData] = useState({
    header: '',
    text: '',
  });
  const [isDisabled, setDisabled] = useState(false);

  const [checkAll, setCheckAll] = useState(false);
  const [showDateRangeDialog, setShowDateRangeDialog] = useState(false);
  const [dateValid, setDateValid] = useState(false);
  const [showIncubatorSuccessModal, setShowIncubatorSuccessModal] =
    useState(false);
  const [rangePopupData, setRangePopupData] = useState({
    fromDate: null,
    toDate: null,
    minDate: new Date(),
  });
  const [count, setCount] = useState(0);
  const [excelDownloadClicked, setExcelDownloadClicked] = useState(false)
  const allSelected = allCounts.length > 0 ? allCounts[0]?.all_selected : 0;
  const activeIncubators =
    bucketCounts.length > 0
      ? parseInt(bucketCounts[0]?.qr_active_incubators)
      : 0;
  const submittedIncubators =
  subBucketCounts.length > 0
      ? parseInt(subBucketCounts[0]?.qr_submitted_incubators)
      : 0;
  const incubatorsDiff = activeIncubators - submittedIncubators;
  useEffect(() => {
    setExcelDownloadClicked(false)


    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Adding 1 since getMonth() returns a zero-based index
    const currentYear = currentDate.getFullYear();
    let yearss: any = [];
    for (let i = currentYear; i >= 2023; i--) {
      let data = i.toString()
      yearss.push(data);
      setChooseYear(yearss)
    }

    let quarter = 1;
    if (currentMonth >= 1 && currentMonth <= 3) {
      quarter = 1;
    } else if (currentMonth >= 4 && currentMonth <= 6) {
      quarter = 2;
    } else if (currentMonth >= 7 && currentMonth <= 9) {
      quarter = 3;
    } else {
      quarter = 4;
    }

    setCurrentQuarter(quarter);
    getActiveQr();
    bucket_count(0, 0);
    // details(0,0)
    // tableapi(0,0)
  }, []);
  const previousQuarter = currentQuarter === 1 ? 4 : currentQuarter - 1;

  const bucket_count = (selectedYear: number, selectedQValue: number) => {
    if (
      (selectedYear == 0 && selectedQValue == 0) ||
      (selectedYear !== 0 && selectedQValue !== 0)
    ) {
      let data = {
        year: selectedYear == null ? 0 : selectedYear,
        quarter: selectedQValue,
      };
      if (data.year == 0) {
        return;
      } else {
        post(QUARTERLY_R_BUCKET_COUNT, data).then((response: any) => {
          if (!response || !response.data) return;
          setSubBucketCounts(response.data.data)
          setBucketCounts(response.data.data2[2]);
          setAllCounts(response.data.data2[1]);
          setSelectedCheckboxIds([]);
          setCheckAll(false);
        });
      }
    }
  };
  const searchList = (e: any) => {
    setNoDataFilter(e.target.value)
    const filteredData = filterByValue(tableData1, e.target.value);
    setFilteredUserList(filteredData)
    // console.log(filteredData) 
    setCurrentPage(0);
    setCurrentPage1(0);
    handlePageChange({ selected: 0 })
    setSortData("");
  }
  const handleRangeDateChange = (date: any) => {
    const nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() + 1);

    setRangePopupData(prevState => ({
      ...prevState,
      fromDate: date,
      minDate: nextDay,
    }));
  };
  const dismissModal = () => {
    setShowIncubatorSuccessModal(false);
    //   tableapi(year,qValue)

    // window.location.reload();
  };

  const activate_qr = (status: any, singleCheck: any, indv: any) => {
    setExcelDownloadClicked(false)
    const hasStatusOne = selectedCheckboxIds.some(
      (item: any) => item.status == 1,
    );
    // console.log(selectedCheckboxIds, 'sri@@@@@@@');
    if (
      year == 0 ||
      year == null ||
      year == '' ||
      qValue == 0 ||
      qValue == null ||
      qValue == ''
    ) {
      setFailDialogData({
        header: '',
        text: 'Please Select Year and Quarter',
      });
      setShowIncubatorFailureModal1(true);
      // }else if(qValue==0||qValue==null||qValue==""){
      //     setFailDialogData({
      //         header: '',
      //         text: "Please Select Month",
      //       });
      //       setShowIncubatorFailureModal1(true);
    } else if (indv == 'ind' && singleCheck === '') {
      setFailDialogData({
        header: '',
        text: 'Please select incubators for the QR to proceed ahead',
      });
      setShowIncubatorFailureModal1(true);
    } else if (indv !== 'ind' && selectedCheckboxIds.length === 0) {
      setFailDialogData({
        header: '',
        text: 'Please select incubators for the QR to proceed ahead',
      });
      setShowIncubatorFailureModal1(true);
    } else if (hasStatusOne) {
      setFailDialogData({
        header: '',
        text: 'Please select Deactived Incubators to activate QR',
      });
      setShowIncubatorFailureModal1(true);
      return;
    } else {
      // setSelectedInd(singleCheck)
      // setActivetype(indv)
      // setStatus(status)
      setTotal((prevState: any) => ({
        ...prevState,
        selectedInd: singleCheck,
        activetype: indv,
        status: status,
      }));
      const newCount =
        indv === 'ind' ? [singleCheck].length : selectedCheckboxIds.length;
      setCount(newCount);
      // console.log(count,"checkCoutn")
      $('#activeModel').modal('show');
    }
  };
  const yesActive = () => {
    $('#activeModel').modal('hide');
    setExcelDownloadClicked(false);
    let data = {
      status: total.status,
      year: year,
      quarter: qValue,
      incubatorIDs:
        total.activetype == 'ind'
          ? [total.selectedInd]
          : selectedCheckboxIds.map((obj: any) => obj.incubator_id),
    };
    // console.log(data, 'data@@@@@@@');
    // return;
    setLoader(true);
    post(QUARTERLY_R_ACTIVATE, data).then((response: any) => {
      if (!response || !response.data) return;
      // console.log(response.data.result, 'responsee');
      if (response.data.result == 'success') {
        setLoader(false);        
        setDialogData({
          header: 'Thank You!',
          text: response.data.message,
        });
        setShowIncubatorSuccessModal(true);
        setDisabled(false);
        tableapi(year, qValue);
      }
    });
    setSelectedCheckboxIds([]);
    setCheckAll(false);
  };
  const noActive = () => {
    $('#activeModel').modal('hide');
  };
  const de_activate_qr = (status: any, singleCheck: any, indv: any) => {
    setExcelDownloadClicked(false)

    const hasStatusOne = selectedCheckboxIds.some(
      (item: any) => item.status == 0,
    );
    // const hasStatusOne1 = selectedCheckboxIds.some(
    //   (item: any) => item.submitted == 0 && item.status == 1,
    // );

    if (
      year == 0 ||
      year == null ||
      year == '' ||
      qValue == 0 ||
      qValue == null ||
      qValue == ''
    ) {
      setFailDialogData({
        header: '',
        text: 'Please Select Year and Quarter',
      });
      setShowIncubatorFailureModal1(true);
      // }else if(qValue==0||qValue==null||qValue==""){
      //     setFailDialogData({
      //         header: '',
      //         text: "Please Select Month",
      //       });
      //       setShowIncubatorFailureModal1(true);
    } else if (indv == 'ind' && singleCheck === '') {
      setFailDialogData({
        header: '',
        text: 'Please select incubators for the QR to proceed ahead',
      });
      setShowIncubatorFailureModal1(true);
    } else if (indv !== 'ind' && selectedCheckboxIds.length === 0) {
      setFailDialogData({
        header: '',
        text: 'Please select incubators for the QR to proceed ahead',
      });
      setShowIncubatorFailureModal1(true);
    } else if (hasStatusOne) {
      setFailDialogData({
        header: '',
        text: 'Please select activated Incubators to Deactivate QR',
      });
      setShowIncubatorFailureModal1(true);
      return;
      // } else if (hasStatusOne1) {
      //   setFailDialogData({
      //     header: '',
      //     text: 'You have selected incubator(s) for which QR is not yet submitted, please check and try again',
      //   });
      //   setShowIncubatorFailureModal1(true);
      //   return;
    } else {
      $('#deactiveModel').modal('show');

      setDetotal((prevState: any) => ({
        ...prevState,
        selectedInd: singleCheck,
        activetype: indv,
        status: status,
      }));
      const newCount =
        indv === 'ind' ? [singleCheck].length : selectedCheckboxIds.length;
      setCount(newCount);
    }
  };
  const yesDeactive = () => {
    $('#deactiveModel').modal('hide');
    setExcelDownloadClicked(false)
    // console.log(detotal, 'detotalll');
    let data = {
      status: detotal.status,
      year: year,
      quarter: qValue,
      incubatorIDs:
        detotal.activetype == 'ind'
          ? [detotal.selectedInd]
          : selectedCheckboxIds.map((obj: any) => obj.incubator_id),
    };
    // console.log(data, 'latha!!!');
    // return;
    setLoader(true);
    post(QUARTERLY_R_DEACTIVATE, data).then((response: any) => {
      if (!response || !response.data) return;
      // console.log(response, 'responsee');
      if (response.data.result == 'success') {
        setLoader(false);
        setDialogData({
          header: 'Thank You!',
          text: response.data.message,
        });
        setShowIncubatorSuccessModal(true);
        setDisabled(false);
        tableapi(year, qValue);
      }
    });
    setSelectedCheckboxIds([]);
    setCheckAll(false);
  };
  const noDeactive = () => {
    $('#deactiveModel').modal('hide');
  };
  const close_quaterly = (status: any, singleCheck: any, indv: any) => {
    setExcelDownloadClicked(false)

    // const getSubmitted = tableData1.some(
    //   (obj: any) => obj.submitted == 0 && obj.status == 1,
    // );

    // const getStatus = tableData1.some(
    //   (obj: any) => obj.submitted == 1 && obj.status == 1,
    // );


    const getStatus = tableData1.some(
      (obj: any) => obj.status == 1,
    );

    if (
      year == 0 ||
      year == null ||
      year == '' ||
      qValue == 0 ||
      qValue == null ||
      qValue == ''
    ) {
      setFailDialogData({
        header: '',
        text: 'Please Select Year and Quarter',
      });
      setShowIncubatorFailureModal1(true);
    } else if (qValue == 0 || qValue == null || qValue == '') {
      setFailDialogData({
        header: '',
        text: 'Please Select Month',
      });
      setShowIncubatorFailureModal1(true);
      // } else if (getSubmitted) {
      //   setFailDialogData({
      //     header: '',
      //     text: 'QR for all eligible incubators has not been submitted & deactivated, please make sure that all incubators have submitted the QR and the same is deactivated for them',
      //   });
      //   setShowIncubatorFailureModal1(true);
    } else if (getStatus) {
      setFailDialogData({
        header: '',
        text: 'QR for all eligible incubators has not been deactivated, please make sure that QR is deactivated for all incubators',
      });
      setShowIncubatorFailureModal1(true);
    } else {
      $('#sameLogic').modal('show');
      return;
    }
  };

  const yesClose = () => {
    setExcelDownloadClicked(false)

    // console.log(selectedInd,"selectedInd")
    let data = {
      // "status": status,
      // "year": year,
      // "quarter":qValue,
      incubatorIDs: tableData1.map((obj: any) => obj.incubator_id),
      QRID: qrid,
    };
    // console.log(data,"data!!!!!!")
    // indv=="ind"?[singleCheck]:
    // console.log('Yes close');
    // return
    post(QUARTERLY_R_CLOSE, data).then((response: any) => {
      if (!response || !response.data) return;
      // console.log(response, 'responsee');
      if (response.data.result == 'success') {
        $('#sameLogic').modal('hide');

        setDialogData({
          header: 'Thank You!',
          text: response.data.message,
        });
        setShowIncubatorSuccessModal(true);
      }
    });
  };
  const noClose = () => {
    $('#sameLogic').modal('hide');
  };
  const send_remainder = (status: any, singleCheck: any, indv: any) => {
    setExcelDownloadClicked(false)

    // console.log(selectedCheckboxIds,"selectedCheckboxIds")
    // console.log(reminderDate,"reminder")
    const hasStatusOne1 = selectedCheckboxIds.some(
      (item: any) => item.status == 0,
    );
    if (
      year == 0 ||
      year == null ||
      year == '' ||
      qValue == 0 ||
      qValue == null ||
      qValue == ''
    ) {
      setFailDialogData({
        header: '',
        text: 'Please Select Year and Quarter',
      });
      setShowIncubatorFailureModal1(true);
      // }else if(qValue==0||qValue==null||qValue==""){
      //     setFailDialogData({
      //         header: '',
      //         text: "Please Select Month",
      //       });
      //       setShowIncubatorFailureModal1(true);
    } else if (indv == 'ind' && singleCheck === '') {
      setFailDialogData({
        header: '',
        text: 'Please select incubators for the QR to proceed ahead',
      });
      setShowIncubatorFailureModal1(true);
    } else if (indv !== 'ind' && selectedCheckboxIds.length === 0) {
      setFailDialogData({
        header: '',
        text: 'Please select incubators for the QR to proceed ahead',
      });
      setShowIncubatorFailureModal1(true);
    } else if (hasStatusOne1) {
      setFailDialogData({
        header: '',
        text: 'Please select activated Incubators to send reminder for QR',
      });
      setShowIncubatorFailureModal1(true);
    } else if (reminderDate == "" || reminderDate == null) {
      setShowDateRangeDialog(true);
      setremaindertotal((prevState: any) => ({
        ...prevState,
        selectedInd: singleCheck,
        activetype: indv,
        status: status,
      }));

    } else {
      remainder_apply()
      setShowDateRangeDialog(false);

      setremaindertotal((prevState: any) => ({
        ...prevState,
        selectedInd: singleCheck,
        activetype: indv,
        status: status,
      }));
    }
  };
  const remainder_apply = () => {
    setExcelDownloadClicked(false)

    // console.log(reminderDate, 'reminderDatereminderDate');

    if (reminderDate == null && !rangePopupData.fromDate) {
      // console.log("range")
      setDateValid(true);
      //   return;
    } else {
      setShowDateRangeDialog(false);

      // console.log("range")
      setDateValid(false);
      let data = {
        QRID: qrid,
        incubatorIDs: remaindertotal.indv == "ind" ? [remaindertotal.singleCheck] : selectedCheckboxIds.map((obj: any) => obj.incubator_id),
        reminderDate: (reminderDate == null || reminderDate == "") ? moment(rangePopupData.fromDate).format('YYYY-MM-DD') : moment(reminderDate).format('YYYY-MM-DD'),
      };
      // console.log(data, 'latha!!!!!!!');
      //   return;
      post(QUARTERLY_R_REMINDER, data).then((response: any) => {
        if (!response || !response.data) return;
        // console.log(response, 'REMINDER responsee');
        if (response.data.result == 'success') {
          setShowDateRangeDialog(false);

          setDialogData({
            header: 'Thank You!',
            text: response.data.message,
          });
          setShowIncubatorSuccessModal(true);
        } else {
          setShowDateRangeDialog(false);

          setFailDialogData({
            header: '',
            text: response.data.message,
          });
          setShowIncubatorFailureModal1(true);
        }

      });
    }
  };
  const download_qr = (status: any, singleCheck: any, indv: any) => {
    let allList: any = selectedCheckboxIds.filter((obj: any) => obj.activated == 1 && obj.status == 0).map((obj: any) => obj.incubator_id);

    let allList1: any = selectedCheckboxIds.filter((obj: any) => obj.activated == 0 && obj.status == 0).map((obj: any) => obj.incubator_id);
    let allList2: any = selectedCheckboxIds.filter((obj: any) => obj.activated == 1 && obj.status == 1).map((obj: any) => obj.incubator_id);

    if (
      year == 0 ||
      year == null ||
      year == '' ||
      qValue == 0 ||
      qValue == null ||
      qValue == ''
    ) {
      setFailDialogData({
        header: '',
        text: 'Please Select Year and Quarter',
      });
      setShowIncubatorFailureModal1(true);
      // }else if(qValue==0||qValue==null||qValue==""){
      //     setFailDialogData({
      //         header: '',
      //         text: "Please Select Month",
      //       });
      //       setShowIncubatorFailureModal1(true);
    } else if (indv !== 'ind' && yqdetails.length == 0) {
      setFailDialogData({
        header: '',
        text: `Quaterly review doesn't exist for selected year and quarter`,
      });
      setShowIncubatorFailureModal1(true);
    }
    else if (indv == 'ind' && singleCheck === '') {
      setFailDialogData({
        header: '',
        text: 'Please select atleast one Incubator!!',
      });
      setShowIncubatorFailureModal1(true);

    }
    //   else if (indv !== 'ind' && singleCheck === '') {
    //     setFailDialogData({
    //       header: '',
    //       text: 'Please select atleast one Incubator!!',
    //     });
    //     setShowIncubatorFailureModal1(true);

    // } 
    else if (indv !== 'ind' && allList.length == 0) {
      setFailDialogData({
        header: '',
        text: 'Please select atleast one Incubator!!',
      });
      setShowIncubatorFailureModal1(true);
    }
    else if (indv !== 'ind' && allList1.length > 0) {
      setFailDialogData({
        header: '',
        text: 'QR was not activated for these Incubators!!',
      });
      setShowIncubatorFailureModal1(true);
    } else if (indv !== 'ind' && allList2.length > 0) {
      setFailDialogData({
        header: '',
        text: 'Please deactivate QR for these Incubators!!',
      });
      setShowIncubatorFailureModal1(true);
    }
    else {
      setLoader(true)

      setExcelDownloadClicked(true)
      let data = {
        // status: status,
        QRID: yqdetails[0].id,
        year: yqdetails[0].year,
        quarter: yqdetails[0].quarter,
        // incubatorIds: indv == 'ind' ? [singleCheck] : selectedCheckboxIds.filter((obj: any) => (obj.incubator_id )),
        incubatorIds: indv == 'ind' ? [singleCheck] : selectedCheckboxIds.filter((obj: any) => obj.activated == 1 && obj.status == 0).map((obj: any) => obj.incubator_id)
      };
      // return;
      post(QR_EXCEL_DOWNLOAD, data).then((response: any) => {
        if (!response || !response.data) return;
        // console.log(response, 'responsee');
        setLoader(false)

        var IncubatorLevelData = response.data.incubator_level_data
        var StartupLevelData = response.data.startup_level_data
        var Sheet1Name = "Incubator Level Data"
        var Sheet2Name = "Startup Level Data"
        var ExcelName = "Quaterly_Review.xlsx"
        exportToExcelMultipleSheets(IncubatorLevelData, StartupLevelData, Sheet1Name, Sheet2Name, ExcelName);
        setExcelDownloadClicked(false);
        setSelectedCheckboxIds([]);
        setCheckAll(false)
        // window.location.reload();
        // selectedCheckboxIds.length = []
        // selectedCheckboxIds.length = 0
        // console.log(selectedCheckboxIds,"dsf")

      });
    }
  };
  const download_excel = (indv: any, singleCheck: any) => {
    // if (year == 0 || year == null || year == '') {
    //   setFailDialogData({
    //     header: '',
    //     text: 'Please Select Year',
    //   });
    //   setShowIncubatorFailureModal1(true);
    // } else if (qValue == 0 || qValue == null || qValue == '') {
    //   setFailDialogData({
    //     header: '',
    //     text: 'Please Select Month',
    //   });
    //   setShowIncubatorFailureModal1(true);
    if (
      year == 0 ||
      year == null ||
      year == '' ||
      qValue == 0 ||
      qValue == null ||
      qValue == ''
    ) {
      setFailDialogData({
        header: '',
        text: 'Please Select Year and Quarter',
      });
      setShowIncubatorFailureModal1(true);
      // }else if(qValue==0||qValue==null||qValue==""){
      //     setFailDialogData({
      //         header: '',
      //         text: "Please Select Month",
      //       });
      //       setShowIncubatorFailureModal1(true);
    } else if (indv == 'ind' && singleCheck === '') {
      setFailDialogData({
        header: '',
        text: 'Please select atleast one Incubator!!',
      });
      setShowIncubatorFailureModal1(true);
    } else if (indv == 'ind' && yqdetails.length == 0) {
      setFailDialogData({
        header: '',
        text: `Quaterly review doesn't exist for selected year and quarter`,
      });
      setShowIncubatorFailureModal1(true);
    }

    else {
      setLoader(true)

      let data = {
        // status: status,
        QRID: yqdetails[0].id,
        year: yqdetails[0].year,
        quarter: yqdetails[0].quarter,
        incubatorIds: indv == 'ind' ? [singleCheck] : selectedCheckboxIds.filter((obj: any) => obj.activated == 1 && obj.status == 0).map((obj: any) => obj.incubator_id),
      };
      // return;
      post(QR_EXCEL_DOWNLOAD, data).then((response: any) => {
        if (!response || !response.data) return;
        // console.log(response, 'responsee');
        setLoader(false)
        var IncubatorLevelData = response.data.incubator_level_data
        var StartupLevelData = response.data.startup_level_data
        var Sheet1Name = "Incubator Level Data"
        var Sheet2Name = "Startup Level Data"
        var ExcelName = "Quaterly_Review.xlsx"
        exportToExcelMultipleSheets(IncubatorLevelData, StartupLevelData, Sheet1Name, Sheet2Name, ExcelName);
      });
    }
  };
  const getActiveQr = () => {
    get(QUARTERLY_R_GET_ACTIVE).then((response: any) => {
      if (!response || !response.data) return;
      if (response.data.data.length > 0) {
        setYear(response.data.data[0].year);
        setQvalue(response.data.data[0].quarter);
        bucket_count(response.data.data[0].year, response.data.data[0].quarter);
        setOptiondsb(true);
        tableapi(response.data.data[0].year, response.data.data[0].quarter);
        setQrid(response.data.data[0].id);
        // details(response.data.data[0].year,response.data.data[0].quarter)
      } else if (year !== '' && qValue !== '') {
        tableapi(year, qValue);
        // details(0,0)
      } else {
        setOptiondsb(false);
        tableapi(0, 0);
      }
    });
  };
  const tableapi = (selectedYear: number, selectedQValue: number) => {
    setLoader(true)
    if (
      (selectedYear == 0 && selectedQValue == 0) ||
      (selectedYear !== 0 && selectedQValue !== 0)
    ) {
      let data = {
        year: selectedYear == null ? 0 : selectedYear,
        quarter: selectedQValue,
      };
      post(QUARTERLY_R_TABLE_LIST, data).then((response: any) => {
        if (!response || !response.data) return;
        setLoader(false)
        details(selectedYear, selectedQValue, response.data.data);

      });
    }
  };

  const details = (
    selectedYear: number,
    selectedQValue: number,
    tableData: any[],
  ) => {
    let data = {
      year: selectedYear == null ? 0 : selectedYear,
      quarter: selectedQValue,
    };
    // console.log(data, 'data!!!!');
    post(QUARTERLY_R_DETAILS, data).then((response: any) => {
      if (!response || !response.data) return;
      setYqdetails(response.data.data)
      if (response.data.data.length > 0) {
        const updatedTableData = tableData.map((item: any) => {
          return {
            ...item,
            disable123: response.data.data[0]?.is_active == '0' ? true : false,
          };
        });

        setButtonen(response.data.data[0]?.is_active == '0' ? true : false);
        setActive(response.data.data[0]?.is_active == '1' ? true : false);
        setReminder(response.data.data[0]?.reminder_date);

        if (response.data.data[0].quarter == '1') {
          setQuater('Jan - Mar');
        } else if (response.data.data[0].quarter == '2') {
          setQuater('Apr - Jun');
        } else if (response.data.data[0].quarter == '3') {
          setQuater('Jul - Sep');
        } else if (response.data.data[0].quarter == '4') {
          setQuater('Oct - Dec');
        } else {
          setQuater('');
        }
        setYearValue(response.data.data[0].year);
        setQuaterValue(response.data.data[0].quarter);
        setTabledata(updatedTableData);
        setTabledata1(updatedTableData);
        setFilteredUserList(updatedTableData)
      } else {
        const updatedTableData = tableData.map((item: any) => {

          // console.log(item, "responseeeeeee")
          return {
            ...item,
            disable123: false,
          };
        });

        setTabledata(updatedTableData);
        setTabledata1(updatedTableData);
        setFilteredUserList(updatedTableData)
        setButtonen(false);
        setActive(false);
      }
    });
    // setButtonen(response.data.data[0]?.is_active == "0"?true:false)
  };

  const handleCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setCheckAll(checked);
    if (checked) {
      // If "Check All" is checked, select all items
      setSelectedCheckboxIds(tableData1);
    } else {
      // If "Check All" is unchecked, clear the selected items
      setSelectedCheckboxIds([]);
    }
  };

  const handleCheckboxClick = (id: any, item: any) => {
    if (
      selectedCheckboxIds.some(
        (checkboxItem: any) => checkboxItem.incubator_id === id,
      )
    ) {
      // If the item is already present, remove it from the array
      setSelectedCheckboxIds(
        selectedCheckboxIds.filter(
          (checkboxItem: any) => checkboxItem.incubator_id !== id,
        ),
      );
    } else {
      // If the item is not present, add it to the array
      setSelectedCheckboxIds([...selectedCheckboxIds, item]);
    }
  };

  const handleChangeYearMonth = (event: any) => {
    const { name, value, type } = event.target;
    // console.log(event);
    const newValue = value;

    setYear(newValue);

    // if (newValue == 2023 && currentQuarter == 3) {
    //   // console.log("ielse fffff","ifCondition");
    //   for (let i = 3; i >= 2; i--) {
    //     let data = i.toString()
    //     chooseQuarter.push(data)
    //   }
    // }
    // else 
    if (newValue == 2023 && currentQuarter == 4) {
      // console.log("ielse fffff","ifCondition");
      for (let i = 4; i >= 2; i--) {
        let data = i.toString()
        chooseQuarter.push(data)
      }
    }
    else if (newValue == currentYear) {
      // console.log("ifffff","ifCondition");
      for (let i = currentQuarter; i >= 1; i--) {
        let data = i.toString()
        chooseQuarter.push(data)
      }
    }
    else if (newValue != currentYear && newValue == 2023) {
      // console.log("else","ifCondition");
      for (let i = 4; i >= 2; i--) {
        let data = i.toString()
        chooseQuarter.push(data)
      }
    }
    else if (newValue != currentYear) {
      // console.log("else","ifCondition");
      for (let i = 4; i >= 1; i--) {
        let data = i.toString()
        chooseQuarter.push(data)
      }
    }

    setNewQuarter(chooseQuarter)
    if (newValue !== 0 && qValue !== 0) {
      // console.log("year",qValue)
      // Perform API call only when a valid year is selected
      bucket_count(newValue, qValue);
      tableapi(newValue, qValue);
      // details(selectedYear, 0);
      // getActiveQr()
    }

  };
  const selectYear = (e: any) => {
    const selectedYear = parseInt(e);

    setYear(parseInt(e));

    if (selectedYear !== 0 && qValue !== 0) {
      // console.log("year",qValue)
      // Perform API call only when a valid year is selected
      bucket_count(selectedYear, qValue);
      tableapi(selectedYear, qValue);
      // details(selectedYear, 0);
      // getActiveQr()
    }
  };

  const handleChangeYearMonth1 = (event: any) => {
    const { name, value, type } = event.target;
    // console.log(event);
    const newValue = value;
    setQvalue(newValue);

    if (newValue !== 0 && year !== 0) {
      // console.log("qValue")
      bucket_count(year, newValue);
      tableapi(year, newValue);
      //  getActiveQr()
      //  details(year,parseInt(e))
    }
    // console.log(qrDetails.length,"qrDetailssss")


  };

  const quater = (e: any) => {
    const quaterEvent = parseInt(e);
    setQvalue(parseInt(e));
    if (quaterEvent !== 0 && year !== 0) {
      // console.log("qValue")
      bucket_count(year, parseInt(e));
      tableapi(year, parseInt(e));
      //  getActiveQr()
      //  details(year,parseInt(e))
    }
  };

  const handleClickExport = () => {
    const jsonData = [
      { Name: 'John Doe', Age: 30 },
      { Name: 'Jane Smith', Age: 35 },
      { Name: 'Bob Johnson', Age: 40 },
    ];
    exportToExcel(jsonData);
  };


  const exportToExcel = (jsonData: any) => {
    const worksheet = XLSX.utils.json_to_sheet(jsonData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const excelBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const fileName = 'data.xlsx';

    // if (navigator.msSaveBlob) {
    //   // For IE11 and Edge
    //   navigator.msSaveBlob(excelBlob, fileName);
    // } 
    // else {
    // For other browsers
    const excelUrl = URL.createObjectURL(excelBlob);
    const a = document.createElement('a');
    a.href = excelUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(excelUrl);
    // }
  };

  const [currentPage, setCurrentPage] = useState(0);
  const [currentPage1, setCurrentPage1] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [itemsPerPage1, setItemsPerPage1] = useState(25);


  // const itemsPerPage = 10;
  const handlePageChange = (selectedItem: { selected: number }) => {
    // console.log((currentPage) + 1, "pageee")
    setCurrentPage(selectedItem.selected);
    setCurrentPage1(selectedItem.selected);
  };
  const handleItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newItemsPerPage = parseInt(event.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setItemsPerPage1(newItemsPerPage);
    setCurrentPage(0); // Reset current page to first page when items per page changes
    setCurrentPage1(0); // Reset current page to first page when items per page changes
    handlePageChange({ selected: 0 })
  };
  const offset = currentPage * itemsPerPage;
  const offset1 = currentPage1 * itemsPerPage1;
  const currentPageData = tableData1.slice(offset, offset + itemsPerPage)
  // const currentPageData1 = filteredUserList.slice(offset1, offset1 + itemsPerPage1);
  var currentPageData1 = noDataFilter
    ? filteredUserList.slice(offset1, offset1 + itemsPerPage1)
    : tableData1.slice(offset1, offset1 + itemsPerPage1);
  //  setSortData(currentPageData1);
  const totalPages = Math.ceil((tableData1.length / itemsPerPage));
  // const totalPages1 = Math.ceil( (filteredUserList.length / itemsPerPage1));
  const totalPages1 = noDataFilter
    ? Math.ceil(filteredUserList.length / itemsPerPage1)
    : Math.ceil(tableData1.length / itemsPerPage1);


  const handleSort = () => {
    // console.log(sortArror,"sortArror")
    setsortArror(!sortArror);
    if (sortComponent2 !== "1" && sortComponent22 !== "1") {
      var currentPageData2 = tableData1;
      // console.log(currentPageData2);
      setSortData(currentPageData2)

      // red Case
      SortDataFilter == 1 && setSortDataFilter("0");
      SortDataFilter2 == 0 && setSortDataFilter2(1);
      SortDataFilter == 1 && setSortComponent1(1);
      SortDataFilter2 == 0 && setSortComponent11(1);
      SortDataFilter == 1 && setSortComponent2(0);
      SortDataFilter2 == 1 && setSortComponent22(0);

      // Dash Case
      SortDataFilter == 0 && SortDataFilter2 == 1 && setSortDataFilter("0");
      SortDataFilter == 0 && setSortDataFilter2("0");
      SortDataFilter == 0 && SortDataFilter2 == 1 && setSortComponent1("0");
      SortDataFilter == 0 && setSortComponent11("1");
      SortDataFilter == 0 && SortDataFilter2 == 1 && setSortComponent2("1");
      SortDataFilter == 0 && setSortComponent22(1);

      // green Case    
      SortDataFilter === 0 && setSortDataFilter(1);
      SortDataFilter2 === 0 && setSortDataFilter2(1);
      SortDataFilter === 0 && setSortComponent1(0);
      SortDataFilter2 === 0 && setSortComponent11(1);
      SortDataFilter === 0 && setSortComponent2(0);
      SortDataFilter2 === 0 && setSortComponent22(0);
    } else {
      setSortData("");

      setSortDataFilter(0);
      setSortDataFilter2(0);
      setSortComponent1(0);
      setSortComponent11(0);
      setSortComponent2(0);
      setSortComponent22(0);
    }






  };

  return (
    <>
      {loader &&
        <div className="spinner-border custom-spinner" role="status">
          <span className="sr-only">Loading...</span>
        </div>}
      <fieldset disabled={loader}>
        <div className="container-fluid p-5">
          {/* {console.log(currentPageData1)} */}
          {/* <div>
        <button onClick={handleClickExport}>Export to Excel</button>
      </div> */}

          {/* <div className="container"> */}
          <div className="d-flex justify-content-around">
            <div
              // className={
              //     activeIndex == "0"
              //         ? "rectangle-card-selected"
              //         : "rectangle-card"
              // }
              className="quaterly_rectangle_card_new"
            // quaterly_rectangle_card_selected
            >
              <span
                // className={
                //     activeIndex == "0"
                //         ? "smallIncubatorNumeric"
                //         : "incubator-numeric-black"
                // }
                className="incubator-numeric-black_new">
                {/* {incubatorListingCounts.total_id} */}
                {/* 3 */}
                {allSelected}
              </span>
              <br />
              <span
                // className={
                //     activeIndex == "0"
                //         ? "incubator-text-selected-small"
                //         : "card-text-incubator"
                // }
                className="card-text-incubator_new">
                All Selected Incubators
              </span>
            </div>
            <div
              // className={
              //     activeIndex == "0"
              //         ? "rectangle-card-selected"
              //         : "rectangle-card"
              // }
              className="quaterly_rectangle_card_new">
              <span
                // className={
                //     activeIndex == "0"
                //         ? "smallIncubatorNumeric"
                //         : "incubator-numeric-black"
                // }
                className="incubator-numeric-black_new">
                {/* {incubatorListingCounts.total_id} */}
                {activeIncubators}
              </span>
              <br />
              <span
                // className={
                //     activeIndex == "0"
                //         ? "incubator-text-selected-small"
                //         : "card-text-incubator"
                // }
                className="card-text-incubator_new">
                Incubators Eligible for QR
              </span>
            </div>
            <div
              // className={
              //     activeIndex == "0"
              //         ? "rectangle-card-selected"
              //         : "rectangle-card"
              // }
              className="quaterly_rectangle_card_new">
              <span
                // className={
                //     activeIndex == "0"
                //         ? "smallIncubatorNumeric"
                //         : "incubator-numeric-black"
                // }
                className="incubator-numeric-black_new">
                {/* {incubatorListingCounts.total_id} */}
                {submittedIncubators}
              </span>
              <br />
              <span
                // className={
                //     activeIndex == "0"
                //         ? "incubator-text-selected-small"
                //         : "card-text-incubator"
                // }
                className="card-text-incubator_new">
                Incubators which have submitted QR
              </span>
            </div>
            <div
              // className={
              //     activeIndex == "0"
              //         ? "rectangle-card-selected"
              //         : "rectangle-card"
              // }
              className="quaterly_rectangle_card_new">
              <span
                // className={
                //     activeIndex == "0"
                //         ? "smallIncubatorNumeric"
                //         : "incubator-numeric-black"
                // }
                className="incubator-numeric-black_new">
                {/* {incubatorListingCounts.total_id} */}

                {incubatorsDiff}
              </span>
              <br />
              <span
                // className={
                //     activeIndex == "0"
                //         ? "incubator-text-selected-small"
                //         : "card-text-incubator"
                // }
                className="card-text-incubator_new">
                Incubators which have not submitted QR
              </span>
            </div>
          </div>
          {/* </div> */}
          <div className="row mt-3 mb-3">
            {/* <div className="col-md-12 mt-3 mb-3">
            <h6 style={{fontWeight:'bold',textAlign:'center',fontSize:'18px',color:'#2b3f89'}}>
              {active == true
                ? `The Quaterly Review is initiated for ${activeIncubators} incubators for the Quarter ${quater1} ${yearV}`
                : ''}
            </h6>
          </div> */}
            {active == true ? (
              <div className="col-md-12 mt-3 mb-3">
                <h6 style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '18px', color: '#2b3f89' }}>
                  The Quaterly Review is initiated for {activeIncubators} incubators for the Quarter {quater1} {yearV}
                </h6>
              </div>
            ) : null}

            <div className='col-md-12 d-flex'>
              <div className='col-md-2'></div>
              <div className='col-md-1'></div>
              <div className='col-md'></div>
              <div className="col-md-2 mb-4">
                <label className="col-form-head">Choose year</label>

                <div className="select-custom">
                  <select
                    // onChange={(e: any) => setYear(e.target.value)}
                    className="form-control input-height"
                    aria-label="Select Year"
                    // style={{backgroundColor:'#f8d874'}}
                    value={year}
                    onChange={handleChangeYearMonth}
                    // onChange={e => selectYear(e.target.value)}
                    disabled={optiondsb}
                  >
                    <option value="0">Please select Year</option>
                    {/* selected */}
                    {chooseYear.map((ele: any) =>
                      <option value={ele}>{ele}</option>
                    )}
                    {/* <option value={currentYear + 1}>{currentYear + 1}</option>
                    <option value={currentYear}>{currentYear}</option> */}
                    {/* <option value={(currentYear - 1)}>{currentYear - 1}</option>
                                <option value={currentYear - 2}>{currentYear - 2}</option>
                                <option value={currentYear - 3}>{currentYear - 3}</option>
                                <option value={currentYear - 4}>{currentYear - 4}</option> */}
                  </select>
                </div>
              </div>
              <div className='col-md'></div>
              <div className="col-md-2 mb-4">
                <label className="col-form-head">Choose Quarter</label>
                <div className="select-custom">
                  <select
                    //  style={{backgroundColor:'#f8d874'}}
                    // onChange={(e: any) => setMonth(e.target.value)}
                    className="form-control input-height"
                    aria-label="Select Year"
                    value={qValue}
                    // onChange={e => quater(e.target.value)}
                    onChange={handleChangeYearMonth1}
                    disabled={optiondsb || year == '0'}>
                    <option value="0">Please select Quarter</option>
                    {
                      newQuarter.length == 0 ?

                        <option value={qValue}>
                          {qValue == 1 ? 'Jan - Mar' : qValue == 2 ? 'Apr - Jun' : qValue == 3 ? 'Jul - Sep' : 'Oct - Dec'}
                        </option> :
                        newQuarter.length && newQuarter.map((item: any) =>

                          <option value={item}>
                            {item == 1 ? 'Jan - Mar' : item == 2 ? 'Apr - Jun' : item == 3 ? 'Jul - Sep' : 'Oct - Dec'}
                          </option>
                        )
                    }
                    {/* {currentQuarter === 1 && (
                      <>
                        <option value="1">Jan - Mar</option>
                        <option value="4">Oct - Dec</option>
                      </>
                    )}
                    {currentQuarter === 2 && (
                      <>
                        <option value="2">Apr - Jun</option>
                        <option value="1">Jan - Mar</option>
                      </>
                    )}
                    {currentQuarter === 3 && (
                      <>
                        <option value="3">Jul - Sep</option>
                        <option value="2">Apr - Jun</option>
                      </>
                    )}
                    {currentQuarter === 4 && (
                      <>
                        <option value="4">Oct - Dec</option>
                        <option value="3">Jul - Sep</option>
                      </>
                    )} */}
                  </select>
                  {/* <select
                                // onChange={(e: any) => setMonth(e.target.value)}
                                className="form-control input-height"
                                aria-label="Select Year"
                                value={qValue}
                                onChange={(e) => quater(e.target.value)}
                                disabled={optiondsb}

                            >
                                <option value="">Please select Quater</option>
                                <option value="1">Jan - Mar</option>
                                <option value="2">Apr - Jun</option>
                                <option value="3">Jul - Sep</option>
                                <option value="4">Oct - Dec</option>
                            </select> */}
                </div>
              </div>
              <div className='col-md'></div>
              <div className='col-md-1'></div>
              <div className='col-md-2'>
                <div className="d-flex searchDiv align-self-end mt-4 justify-content-end"> <img
                  src={search}
                  className="search"
                  alt="search"
                />
                  <input
                    className="searchBar"
                    type="text"
                    placeholder="Search.."
                    name="search"
                    onKeyUp={searchList}
                  />
                </div>
              </div>
            </div>

          </div>
          <div className="row">
            <div className="col-md"></div>
            <div className="col-md-12">
              <div className="row text-center">
                <div className="col-md mb-2">
                  <button
                    disabled={buttonen}
                    onClick={() => close_quaterly(0, 0, '')}
                    className="btn btn-sisfs-Quaterly btn-yellow-primary"
                  >
                    Close Quaterly Review Exercise
                  </button>
                </div>
                <div className="col-md mb-2">
                  <button
                    disabled={buttonen}
                    className="btn btn-sisfs-Quaterly btn-yellow-primary"
                    onClick={() => activate_qr(1, 0, '')}
                  >
                    Activate Quaterly Review
                  </button>
                </div>
                <div className="col-md mb-2">
                  <button
                    disabled={buttonen}
                    className="btn btn-sisfs-Quaterly btn-yellow-primary"
                    onClick={() => de_activate_qr(0, 0, '')}
                  >
                    Deactivate Quaterly Review
                  </button>
                </div>
                <div className="col-md mb-2">
                  <button
                    disabled={buttonen}
                    className="btn btn-sisfs-Quaterly btn-yellow-primary"
                    onClick={() => send_remainder(0, 0, '')}
                  >
                    Send Reminder for QR
                  </button>
                </div>
                <div className="col-md mb-2">
                  <button
                    disabled={optiondsb}
                    className="btn btn-sisfs-Quaterly btn-yellow-primary"
                    onClick={() => download_qr(0, 0, '')}
                  >
                    Download QR for all Incubators
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md"></div>
          </div>

          <br />


          <div className="row">
            <div className="col-12" style={{ padding: '0' }}>
              <div className="table-scroll">
                <table className="table qrtable">
                  <thead>
                    <tr className="text-center">
                      <th className='table-heading1'>
                        <input
                          type="checkbox"
                          checked={checkAll}
                          onChange={handleCheckAll}
                        />
                      </th>
                      <th className='table-heading1'>Incubator Application ID</th>
                      <th className='table-heading1' style={{ width: '20%' }}>Incubator Name</th>
                      <th className='table-heading1'>No of QR Submitted</th>
                      <th className='table-heading1'>QR Active</th>
                      <th className='table-heading1' style={{ cursor: "pointer" }} onClick={handleSort}>QR Submitted
                        {!sortArror && <span>▲</span>}
                        {sortArror && <span>▼</span>}
                      </th>
                      <th className='table-heading1'>Progress</th>
                      <th className='table-heading1'>Download Excel</th>
                    </tr>
                  </thead>
                  {loader ? (
                    <div className="spinner-border custom-spinner" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) :
                    <tbody>
                      {/* {console.log(SortDataFilter,SortDataFilter2,"111111","datattata")}
                    {console.log(sortComponent1,sortComponent11,"2222","datattata")}
                    {console.log(sortComponent2,sortComponent22,"33333","datattata")} */}
                      {tableData1.length == 0 || (filteredUserList.length == 0 && noDataFilter != '') ? <tr><td className="text-center" colSpan={8}>No data</td></tr> :
                        (filteredUserList.length == 0 && tableData1.length > 0 ?
                          noDataFilter && tableData1.map((item: any, i: any) => {
                            <tr style={{ textAlignLast: 'center' }}>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={selectedCheckboxIds.some(
                                    (checkboxItem: any) =>
                                      checkboxItem.incubator_id === item.incubator_id,
                                  )}
                                  onChange={() =>
                                    handleCheckboxClick(item.incubator_id, item)
                                  }
                                  disabled={excelDownloadClicked && item.submitted == 0}

                                />
                              </td>
                              <td>{item.incubator_application_no}</td>
                              <td>{item.registered_name}</td>
                              <td>
                                {item.no_of_QR_submitted}
                                {/* <input className="form-control" style={{ width: "150px", marginLeft: 'auto', marginRight: 'auto' }} /> */}
                              </td>
                              <td className="text-center custom-td1 slider-td1">
                                {/* || (item.submitted == 0 && item.status == 1) */}
                                <fieldset
                                  disabled={
                                    item.disable123
                                  }>
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={item.status == 0 ? false : true}
                                      onChange={checked => {
                                        // console.log(
                                        //   checked.target.value,
                                        //   checked,
                                        //   'checked',
                                        // );
                                        const newStatus = !item.status; // Convert the checked value to 1 or 0
                                        if (newStatus) {
                                          activate_qr(1, item.incubator_id, 'ind'); // Call the activate_qr function
                                        } else {
                                          de_activate_qr(0, item.incubator_id, 'ind'); // Call the dev_qr function
                                        }
                                      }}
                                    />
                                    <span
                                      className="slider1 round1"
                                      style={{
                                        color: '#fff',
                                        fontSize: '9px', // Adjust the font size as needed
                                        fontWeight: 100,
                                      }}>
                                      {' '}
                                      <span
                                        style={{
                                          marginRight:
                                            item.status == 0 ? '-11px' : '12px',
                                        }}>
                                        {item.status === 0 ? 'Off' : 'On'}
                                      </span>
                                    </span>
                                  </label>
                                  {/* <BootstrapSwitchButton
                                              checked={item.status == 0 ?false:true}
                                              onlabel=''
                                              offlabel=''
                                              onstyle="success"
                                              offstyle="danger"
                                              onChange={(checked) => {
                                                  const newStatus = checked ? 1 : 0; // Convert the checked value to 1 or 0
                                                  if (newStatus === 1) {
                                                      activate_qr(newStatus,item.incubator_id,"ind"); // Call the activate_qr function
                                                  } else {
                                                      
                                                          de_activate_qr(newStatus,item.incubator_id,"ind"); // Call the dev_qr function
                                                                                                        
                                                  }
                                              }}
                                         
                                          /> */}
                                </fieldset>
                              </td>
                              <td>
                                {/* <fieldset disabled={item.disable123}>
                                          <BootstrapSwitchButton                                            
                                              checked={item.status == 2?true:false}
                                              onlabel='Yes'
                                              offlabel='No'
                                              onstyle="success"
                                              offstyle="danger"
                                          // onChange={(checked: boolean) => {
                                          //     this.setState({ isUserAdmin: checked })
                                          // }}
                                          />
                                          </fieldset> */}
                                {/* {item.status == 2?"Yes":"No"} */}

                                {item.submitted == 1 ? (
                                  <img src={circle_check} style={{ width: ' 20px' }} />
                                ) : item.submitted == 0 && item.status == 1 ? (
                                  <img
                                    src={circle_exclamation}
                                    style={{ width: ' 20px' }}
                                  />
                                ) : (
                                  '-'
                                )}
                              </td>
                              <td>
                                {/* <ProgressBar numerator={parseInt(item.progress.split('/')[0])} denominator={parseInt(item.progress.split('/')[1])} /> */}

                                {/* && item.total_QR_applications !=0 */}
                                {yearV && quaterValue ? <ProgressBar numerator={item.completed_QR_applications} denominator={item.total_QR_applications} /> : "-"}
                                {/* <input
                              className="form-control"
                              style={{
                                width: '150px',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                              }}
                            /> */}
                              </td>
                              <td>
                                {item.activated == 1 && item.status == 0 ? (
                                  <img
                                    onClick={() =>
                                      download_excel('ind', item.incubator_id)
                                    }
                                    src={downoadFile}
                                    style={{ width: ' 21px' }}
                                  />) : (
                                  <img
                                    // onClick={() =>
                                    //   download_excel('ind',item.incubator_id)
                                    // }
                                    src={download_disabled}
                                    style={{ width: ' 21px' }}
                                  />

                                )}

                              </td>
                            </tr>
                          }) :
                          !SortData ? filteredUserList.map((item: any, i: any) => (
                            // QrSortingComponent({item:any, selectedCheckboxIds:any, handleCheckboxClick:any, activate_qr:any, de_activate_qr:any, yearV:any, quaterValue:any})
                            <QrSortingComponent
                              item={item}
                              selectedCheckboxIds={selectedCheckboxIds}
                              handleCheckboxClick={handleCheckboxClick}
                              activate_qr={activate_qr}
                              de_activate_qr={de_activate_qr}
                              yearV={yearV}
                              quaterValue={quaterValue}
                              download_excel={download_excel}
                              excelDownloadClicked={excelDownloadClicked}
                            />
                          )) :
                            <> {!noDataFilter && SortData && SortData.map((item: any, i: any) => (
                              item.submitted == SortDataFilter && item.status == SortDataFilter2 &&
                              <QrSortingComponent
                                item={item}
                                selectedCheckboxIds={selectedCheckboxIds}
                                handleCheckboxClick={handleCheckboxClick}
                                activate_qr={activate_qr}
                                de_activate_qr={de_activate_qr}
                                yearV={yearV}
                                quaterValue={quaterValue}
                                download_excel={download_excel}
                                excelDownloadClicked={excelDownloadClicked}

                              />
                            ))}
                              {!noDataFilter && SortData && SortData.map((item: any, i: any) => (
                                item.submitted == sortComponent1 && item.status == sortComponent11 &&
                                <QrSortingComponent
                                  item={item}
                                  selectedCheckboxIds={selectedCheckboxIds}
                                  handleCheckboxClick={handleCheckboxClick}
                                  activate_qr={activate_qr}
                                  de_activate_qr={de_activate_qr}
                                  yearV={yearV}
                                  quaterValue={quaterValue}
                                  download_excel={download_excel}
                                  excelDownloadClicked={excelDownloadClicked}
                                />
                              ))}
                              {!noDataFilter && SortData && SortData.map((item: any, i: any) => (
                                item.submitted == sortComponent2 && item.status == sortComponent22 &&
                                <QrSortingComponent
                                  item={item}
                                  selectedCheckboxIds={selectedCheckboxIds}
                                  handleCheckboxClick={handleCheckboxClick}
                                  activate_qr={activate_qr}
                                  de_activate_qr={de_activate_qr}
                                  yearV={yearV}
                                  quaterValue={quaterValue}
                                  download_excel={download_excel}
                                  excelDownloadClicked={excelDownloadClicked}
                                />
                              ))}
                            </>
                        )
                      }
                      {/* <tr style={{ textAlignLast: 'center' }}>
                                    <td>
                                        <input type="checkbox" />
                                    </td>
                                    <td>SIF21004</td>
                                    <td>Adi Palanki</td>
                                    <td>
                                        <input className="form-control" style={{ width: "150px", marginLeft: 'auto', marginRight: 'auto' }} />
                                    </td>
                                    <td>
                                        <BootstrapSwitchButton
                                            checked={true}
                                            onlabel=''
                                            offlabel=''
                                            onstyle="success"
                                            offstyle="danger"
                                        // onChange={(checked: boolean) => {
                                        //     this.setState({ isUserAdmin: checked })
                                        // }}
                                        />
                                    </td>
                                    <td>
                                        <BootstrapSwitchButton
                                            checked={false}
                                            onlabel=''
                                            offlabel=''
                                            onstyle="success"
                                            offstyle="danger"
                                        // onChange={(checked: boolean) => {
                                        //     this.setState({ isUserAdmin: checked })
                                        // }}
                                        />
                                    </td>
                                    <td>
                                        <input className="form-control" style={{ width: "150px", marginLeft: 'auto', marginRight: 'auto' }} />
                                    </td>
                                    <td>
                                        <img src={downoadFile} style={{ width: ' 35px' }} />
                                    </td>
                                </tr>
                                <tr style={{ textAlignLast: 'center' }}>
                                    <td>
                                        <input type="checkbox" />
                                    </td>
                                    <td>SIF21004</td>
                                    <td>Adi Palanki</td>
                                    <td>
                                        <input className="form-control" style={{ width: "150px", marginLeft: 'auto', marginRight: 'auto' }} />
                                    </td>
                                    <td>
                                        <BootstrapSwitchButton
                                            checked={true}
                                            onlabel=''
                                            offlabel=''
                                            onstyle="success"
                                            offstyle="danger"
                                        // onChange={(checked: boolean) => {
                                        //     this.setState({ isUserAdmin: checked })
                                        // }}
                                        />
                                    </td>
                                    <td>
                                        <BootstrapSwitchButton
                                            checked={false}
                                            onlabel=''
                                            offlabel=''
                                            onstyle="success"
                                            offstyle="danger"
                                        // onChange={(checked: boolean) => {
                                        //     this.setState({ isUserAdmin: checked })
                                        // }}
                                        />
                                    </td>
                                    <td>
                                        <input className="form-control" style={{ width: "150px", marginLeft: 'auto', marginRight: 'auto' }} />
                                    </td>
                                    <td>
                                        <img src={downoadFile} style={{ width: ' 35px' }} />
                                    </td>
                                </tr> */}
                    </tbody>}
                </table>
              </div>
              <br />
              {/* {tableData1.length == 0 ? <></>
              :

              filteredUserList.length == 0 ?
                <div style={{ float: 'right', display: 'flex', marginRight: '20px', alignItems: 'center', justifyContent: 'center' }}>

                  <span>
                    <label htmlFor="itemsPerPageSelect" className="itemsPerPage">Items per Page:</label>
                    <select
                      className="itemsPerPageSelect"
                      id="itemsPerPageSelect"
                      value={itemsPerPage}
                      onChange={handleItemsPerPageChange}
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="75">75</option>
                      <option value={tableData1.length}>All</option>
                    </select>
                  </span>&nbsp;&nbsp;&nbsp;

                  <span className="pagination" style={{ fontSize: '18px', marginTop: '10px' }}>
                    <ReactPaginate
                      key={itemsPerPage}
                      pageCount={totalPages}
                      pageRangeDisplayed={0}
                      marginPagesDisplayed={0}
                      previousLabel="Prev"
                      nextLabel="Next"
                      // breakLabel="..."
                      onPageChange={handlePageChange}
                      containerClassName="pagination"
                      activeClassName="active"
                      disableInitialCallback={true}
                      forcePage={currentPage}
                    />

                  </span>&nbsp;&nbsp;&nbsp;

                  <span className="pageNum">
                    Page {(currentPage) + 1} of {totalPages}
                  </span>
                </div>
                : <>
                  <div style={{ float: 'right', display: 'flex', marginRight: '20px', alignItems: 'center', justifyContent: 'center' }}>

                    <span>
                      <label htmlFor="itemsPerPageSelect" className="itemsPerPage">Items per Page:</label>
                      <select
                        className="itemsPerPageSelect"
                        id="itemsPerPageSelect"
                        value={itemsPerPage1}
                        onChange={handleItemsPerPageChange}
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value={filteredUserList.length}>All</option>
                      </select>
                    </span>&nbsp;&nbsp;&nbsp;

                    <span className="pagination" style={{ fontSize: '18px', marginTop: '10px' }}>
                      <ReactPaginate
                        key={itemsPerPage1}
                        pageCount={totalPages1}
                        pageRangeDisplayed={0}
                        marginPagesDisplayed={0}
                        previousLabel="Prev"
                        nextLabel="Next"
                        // breakLabel="..."
                        onPageChange={handlePageChange}
                        containerClassName="pagination"
                        activeClassName="active"
                        disableInitialCallback={true}
                        forcePage={currentPage1}
                      />
                    </span>&nbsp;&nbsp;&nbsp;

                    <span className="pageNum">
                      Page {currentPage1 + 1} of {totalPages1}
                    </span>
                  </div>
                </>
            } */}
            </div>
          </div>
          {showIncubatorFailureModal1 && (
            <ConfirmationDialog
              headerText={failDialogData.header}
              bodyText={failDialogData.text}
              // bodyNote={failDialogData.note}
              onDialogClose={() => setShowIncubatorFailureModal1(false)}
              iconClass="showImage"
            />
          )}
          {showIncubatorSuccessModal && (
            <ConfirmationDialog
              headerText={dialogData.header}
              bodyText={dialogData.text}
              onDialogClose={dismissModal}
            />
          )}
          {/* send Remainder model */}
          {showDateRangeDialog && (
            <>
              <div className="modal-backdrop fade show"></div>
              <div
                className="modal align-middle modal-sisfs d-block"
                id="FormSubmissionError">
                <div className="modal-dialog modal-dialog-centered modal-md">
                  <div className="modal-content">
                    <div className="modal-body text-center">
                      <h3 className="modal-message mt-2">Select Date</h3>
                      <br />
                      <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                          {/* <h5>From Date</h5> */}
                          <ReactDatePicker
                            id="registrationDate"
                            name="registrationDate"
                            className="form-control"
                            dateFormat="yyyy-MM-dd"
                            selected={
                              rangePopupData.fromDate
                                ? moment(rangePopupData.fromDate).toDate()
                                : null
                            }
                            onChange={date => handleRangeDateChange(date)}
                            showMonthDropdown
                            showYearDropdown
                            minDate={rangePopupData.minDate}
                          />
                        </div>
                        <div className="col-md-2"></div>
                      </div>
                      {dateValid == true && !rangePopupData.fromDate && (
                        <p style={{ color: 'red' }}>Please select a date</p>
                      )}
                      <br />

                      <div className="modal-action mt-2">
                        <button
                          className="btn btn-sisfs btn-transparent-primary"
                          onClick={() => setShowDateRangeDialog(false)}>
                          Cancel
                        </button>
                        <button
                          className="btn btn-sisfs btn-yellow-primary ml-2"
                          onClick={() => remainder_apply()}>
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* Activate Model */}
          <div
            className="modal fade"
            id="activeModel"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div
                  className="modal align-middle modal-sisfs d-block"
                  id="FormSubmissionError">
                  <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                      <div style={{ textAlign: 'right' }}>
                        <i
                          className="fa fa-close"
                          data-dismiss="modal"
                          style={{
                            cursor: 'pointer',
                            fontSize: '30px',
                            marginRight: '15px',
                            marginTop: '15px',
                            color: '#D3D3D3',
                          }}></i>
                      </div>
                      <div className="modal-body text-center pt-0">
                        <h4
                          className="modal-message btn"
                          style={{ color: '#2b3f88' }}>
                          {/* {console.log(quater1,"quater1")} */}
                          {(qValue == '1' || qValue == 'Jan - Mar') && (
                            <p>
                              You are about to start the Quarterly Review for{' '}
                              {count} incubators for the quarter <br />Jan - Mar {' '}
                              {year}, do you wish to proceed?
                            </p>
                          )}

                          {(qValue == '2' || qValue == 'Apr - Jun') && (
                            <p>
                              You are about to start the Quarterly Review for{' '}
                              {count} incubators for the quarter <br />Apr - Jun {' '}
                              {year}, do you wish to proceed?
                            </p>
                          )}

                          {(qValue == '3' || qValue == 'Jul - Sep') && (
                            <p>
                              You are about to start the Quarterly Review for{' '}
                              {count} incubators for the quarter <br />Jul - Sep {' '}
                              {year}, do you wish to proceed?
                            </p>
                          )}

                          {(qValue == '4' || qValue == 'Oct - Dec') && (
                            <p>
                              You are about to start the Quarterly Review for{' '}
                              {count} incubators for the quarter <br />Oct - Dec {' '}
                              {year}, do you wish to proceed?
                            </p>
                          )}
                        </h4>
                        <div
                          className="modal-action"
                          style={{ marginBottom: '0px' }}>
                          <button
                            className="btn btn-sisfs btn-yellow-primary"
                            style={{ marginLeft: '15px' }}
                            onClick={yesActive}>
                            Yes
                          </button>
                          <button
                            className="btn btn-sisfs "
                            style={{
                              marginLeft: '15px',
                              color: '#2b3f88',
                              border: '1px solid #2b3f88',
                            }}
                            onClick={noActive}>
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Deactivate Model */}
          <div
            className="modal fade"
            id="deactiveModel"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div
                  className="modal align-middle modal-sisfs d-block"
                  id="FormSubmissionError">
                  <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                      <div style={{ textAlign: 'right' }}>
                        <i
                          className="fa fa-close"
                          data-dismiss="modal"
                          style={{
                            cursor: 'pointer',
                            fontSize: '30px',
                            marginRight: '15px',
                            marginTop: '15px',
                            color: '#D3D3D3',
                          }}></i>
                      </div>
                      <div className="modal-body text-center pt-0">
                        <h4
                          className="modal-message btn"
                          style={{ color: '#2b3f88' }}>
                          {/* {console.log(count,"quater1")} */}
                          {(qValue == '1' || qValue == 'Jan - Mar') && (
                            <p>
                              You are about to end the Quarterly Review for{' '}
                              {count} incubators for the quarter <br />Jan - Mar {' '}
                              {year}, do you wish to proceed?
                            </p>
                          )}

                          {(qValue == '2' || qValue == 'Apr - Jun') && (
                            <p>
                              You are about to end the Quarterly Review for{' '}
                              {count} incubators for the quarter <br />Apr - Jun {' '}
                              {year}, do you wish to proceed?
                            </p>
                          )}

                          {(qValue == '3' || qValue == 'Jul - Sep') && (
                            <p>
                              You are about to end the Quarterly Review for{' '}
                              {count} incubators for the quarter <br />Jul - Sep {' '}
                              {year}, do you wish to proceed?
                            </p>
                          )}

                          {(qValue == '4' || qValue == 'Oct - Dec') && (
                            <p>
                              You are about to end the Quarterly Review for{' '}
                              {count} incubators for the quarter <br />Oct - Dec {' '}
                              {year}, do you wish to proceed?
                            </p>
                          )}
                        </h4>
                        <div
                          className="modal-action"
                          style={{ marginBottom: '0px' }}>
                          <button
                            className="btn btn-sisfs btn-yellow-primary"
                            style={{ marginLeft: '15px' }}
                            onClick={yesDeactive}>
                            Yes
                          </button>
                          <button
                            className="btn btn-sisfs "
                            style={{
                              marginLeft: '15px',
                              color: '#2b3f88',
                              border: '1px solid #2b3f88',
                            }}
                            onClick={noDeactive}>
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Close QR Model */}
          <div
            className="modal fade"
            id="sameLogic"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div
                  className="modal align-middle modal-sisfs d-block"
                  id="FormSubmissionError">
                  <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                      <div style={{ textAlign: 'right' }}>
                        <i
                          className="fa fa-close"
                          data-dismiss="modal"
                          style={{
                            cursor: 'pointer',
                            fontSize: '30px',
                            marginRight: '15px',
                            marginTop: '15px',
                            color: '#D3D3D3',
                          }}></i>
                      </div>
                      <div className="modal-body text-center pt-0">
                        <h4
                          className="modal-message btn"
                          style={{ color: '#2b3f88' }}>
                          This action will close the QR exercise and take the
                          snapshot of the data. This step is not reversible. Are
                          you sure you wish to continue?
                        </h4>
                        <div
                          className="modal-action"
                          style={{ marginBottom: '0px' }}>
                          <button
                            className="btn btn-sisfs btn-yellow-primary"
                            style={{ marginLeft: '15px' }}
                            onClick={yesClose}>
                            Yes
                          </button>
                          <button
                            className="btn btn-sisfs "
                            style={{
                              marginLeft: '15px',
                              color: '#2b3f88',
                              border: '1px solid #2b3f88',
                            }}
                            onClick={noClose}>
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </>
  );
}

export default QrViewComponent;