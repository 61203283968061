import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  GET_APPLCAITON_COMMENT,
  GET_APPLICATION_COMMENT_STARTUP,
  GET_EAC_QUESTION_DETAILS,
  SAVE_APPLCAITON_COMMENT,
  SAVE_MEETING_COMMENT_STARTUP,
  SCHEDULE_MEETING_COUNT,
} from '../../../../constants/api';
import { post } from '../../../../effects/api/api.common';
import { getSessionStorageValue } from '../../../../effects/utils/session-storage';
import UserInfoModel from '../../../../models/UserInfoModel';
import { changeNumberFormat } from '../../../../utils/CommonMethods';
import moment from "moment";

// import '../../../incubator/incubator.css';
import {
  EACReviewData,
  EACReviewDetails,
  MeetingDetails,
} from './MeetingModel';
import { userInfo } from 'os';
// import { IncubatorDetails } from './IncubatorDetails';



function MeetingTab(props: any) {
  const { meetingData, startup_application_id, applicationComments, statusId, evDate, isPrefer, flag } = props;

  const [meetingAllData, setMeetingAllData] = useState([]);


  const [eac_id, setEacIdValue] = useState(0);
  const [comments, setComments] = useState('');
  const [incubator_id, setIncubatorIdValue] = useState(0);
  const [application_comments, setApplicationComment] = useState<Array<any>>([]);
  const [eacReviewDetails, setEACReviewDetails] = useState<
    Array<EACReviewDetails>
  >([new EACReviewDetails(0, '', '', 0, [])]);
  // const [isEditable, setIsEditable] = useState(true);

  const [eacReviewData, setEACReviewData] = useState<EACReviewData>(
    new EACReviewData(0, '', 0, 0, '', [])
  );

  const [meetingType, setMeetingType] = useState('');
  const [meetingId, setMeetingId] = useState('');
  const [isClickid, setIsClickid] = useState('');
  const [isPref1, setIsPref1] = useState('');
  const [isevid, setEvid] = useState('');
  const [alr, setAlr] = useState('')
  // const [isPref1,setisPref1]=useState("")


  var currentdatenew = moment().format("YYYY-MM-DD")

  useEffect(() => {
    //console.log(props,"**")
    // setMeetingAllData(meetingData,evaluation_to_be_completed_by,startup_status_id);
    setMeetingAllData(meetingData);
    setIsClickid(statusId)
    // setIsPref1(isPref)
    setEvid(evDate)
    // getMeetingData(meetingType, meetingId);
    setIsPref1(isPrefer)
    setAlr(flag)
    // setIsEditable(props.location.state.isEditable);
    setApplicationComment(applicationComments);
    getUserRoleAccess();
    window.scroll(0, 0);
  }, [meetingData, applicationComments, statusId, evDate]);

  const [is_eac_user, setIsEAC] = useState(false);

  const getUserRoleAccess = () => {
    const user = getSessionStorageValue('user');
    if (user) {
      const userInfo = JSON.parse(user) as UserInfoModel;
      if (userInfo.roles == 'eac') {
        setIsEAC(true);
      }
    }
  };

  const getMeetingData = (meeting_type: any, startup_meeting_id: any) => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    const data_input = {
      startup_application_id: startup_application_id,
      meeting_type: meeting_type,
      startup_meeting_id: startup_meeting_id
    };
    post(GET_APPLICATION_COMMENT_STARTUP, data_input).then((response: any) => {
      // console.log(response,"responseee")
      if (!response || !response.data || response.data == '') return;
      if (response.data != undefined && response.data != '') {
        if (response.data.data.comment != undefined) {
          setComments(response.data.data.comment);

          let updatedList = application_comments.map((item: any) => {
            if (item.startup_meeting_id == Number(meetingId)) {
              return { ...item, comment: response.data.data.comment }; //gets everything that was already in item, and updates "comments"
            }
            return item; // else return unmodified item
          });

          setApplicationComment(updatedList);
        } else {
          setComments('');
        }
      }
    });
  };

  const setMeetingModal = (
    meetingType: any,
    meetingId: number,
    comment: string
  ) => {
    setComments(comment);
    setMeetingId(meetingId.toString());
    setMeetingType(meetingType);
    getMeetingData(meetingType, meetingId);
  };
  const setModalData = (eac_id: number, incubator_id: number) => {
    // setEacIdValue(eac_id);
    // setIncubatorIdValue(incubator_id);
    getQuestionsDetails(eac_id, incubator_id);
  };

  const checkMeetingDate = (meeting_date: string) => {
    //console.log(meeting_date,"ll")
    if (meeting_date != '') {
      var meet_date = new Date(meeting_date);
      var date1 = new Date();
      if (meet_date <= date1) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const getQuestionsDetails = (eac_id: number, incubator_id: number) => {
    const data_input = {
      eac_id: eac_id,
      incubator_id: incubator_id,
    };
    post(GET_EAC_QUESTION_DETAILS, data_input).then((response: any) => {
      if (!response || !response.data || response.data == '') return;

      if (response.data != undefined && response.data != '') {
        setEACReviewDetails(response.data.data);
        setEACReviewData(response.data);
        //console.log(eacReviewData, 'EACReviewData');
      }
    });
  };
  const history = useHistory();

  const addUpdateComment = (comment: any) => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    const new_Comment = {
      startup_application_id: startup_application_id,
      meeting_type: meetingType,
      comment: comment,
      startup_meeting_id: meetingId,
      "logged_user_id": userInfo.id
    };
    post(SAVE_MEETING_COMMENT_STARTUP, new_Comment).then((response: any) => {
      if (!response || !response.data || response.data == '') return;

      if (response.data != undefined && response.data != '') {
        // history.push({
        //   pathname: '/dpiit_view_incubator',
        //   state: { application_id: startup_application_id, isFundDetails: false },
        // });
        window.location.reload();
      }
    });
    setComments('');
  };
  const formatDateWithMonth = (date: Date) => {
    var month = new Array();
    month[0] = 'Jan';
    month[1] = 'Feb';
    month[2] = 'Mar';
    month[3] = 'April';
    month[4] = 'May';
    month[5] = 'Jun';
    month[6] = 'Jul';
    month[7] = 'Aug';
    month[8] = 'Sep';
    month[9] = 'Oct';
    month[10] = 'Nov';
    month[11] = 'Dec';

    var d = new Date(date),
      monthn = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (monthn.length < 2) monthn = '0' + monthn;
    if (day.length < 2) day = '0' + day;

    return [day, month[d.getMonth()], year].join(' ');
  };

  // on incubator dropdown change
  const handleOnChange = (e: any /*index:number*/) => {
    var value = e.target.value;
    setComments(value);
  };

  // private handlePopupChange = (e: any /*index:number*/) => {
  //   var value = e.target.value;
  //   var name = e.target.name;
  //   if (name == 'status_id') {
  //     this.setState({
  //       application_status: value,
  //     });
  //   } else if (name == 'popup_comments') {
  //     //this.state.quickEditPopupData.application_comments= value;

  //     this.setState({
  //       application_comments: value,
  //     });
  //   }
  // };

  return (
    <>
      {meetingAllData.length != 0 ? <div className="">
        <div className="row">
          <div className="col-md-12 left-align">
            <span className="eac-heading-title">Meetings</span>
          </div>
        </div>
        <div className="eac-incubator-hr">
          <hr />
        </div>
        {/* //incubator performance evaluation */}
        <div className="row">
          <div className="col-md-7 left-align">
            <span className="eac-review-title">

              Startup Performance Evaluation
            </span>
            <br />
            {/* {console.log(meetingAllData, "All")} */}
            {meetingAllData == undefined ||
              meetingAllData.length == 0 ? (
              <span className="incubator-label">-</span>
            ) : (
              meetingAllData.map((meeting: any, index) =>
                meeting.meeting_type == 2 ? (
                  <div key={index}>
                    <div className="incubator-label">
                      {formatDateWithMonth(new Date(meeting.meeting_date))} |{' '}
                      {meeting.meeting_from_time} {'-'} {meeting.meeting_to_time}
                    </div>
                    <div className="row  mt-15-px">
                      <div className="col-md-10 left-align">
                        {checkMeetingDate(meeting.meeting_date) == true ? (
                          <div>
                            <span className="eac-review-subtitle">
                              {/* ISMC  */}
                              Comments/Feedback
                            </span>
                            <br />
                          </div>
                        ) : (
                          ''
                        )}
                        {/* <div>
                              <span className="eac-p"> {  meeting.comment}</span>
                              <br/>
                            </div> */}
                        {application_comments != undefined &&
                          application_comments.length > 0
                          ? application_comments
                            .filter(function (item) {
                              return item.meeting_type == 2; //&& item.startup_meeting_id ==meeting.startup_meeting_id
                            })
                            .map((item: any, index) =>
                              item.startup_meeting_id === meeting.startup_meeting_id ? (
                                <div key={index}>
                                  <span className="eac-p"> {item.comment}</span>
                                  <br />
                                </div>
                              ) : (
                                ''
                              )
                            )
                          : <>


                          </>}

                        {(checkMeetingDate(meeting.meeting_date) == true) &&
                          // ((isevid < currentdatenew)&&((isPref1 == '7'&& isClickid == '7' && alr == '1')||(isClickid == '2' ||isClickid == '3' ||isClickid == '4'||isClickid == '5')))) ? 
                          (((isevid < currentdatenew) && ((isPref1 == '7' && isClickid == '7' && flag == '1') || (isClickid == '2' || isClickid == '3' || isClickid == '4' || isClickid == '5')))) ?

                          (<div>
                            <a
                              className="incubator-details-link"
                            >
                              Add Comment
                              {/* {isClickid}{isPref} */}
                            </a>
                            <br />
                            <br />
                          </div>)
                          :

                          (
                            <div>
                              <a
                                className="incubator-details-link"
                                data-toggle="modal"
                                data-target="#addEditComment"
                                onClick={() =>
                                  setMeetingModal(
                                    '2',
                                    meeting.startup_meeting_id,
                                    meeting.comment
                                  )
                                }
                              >
                                Add Comment
                              </a>
                              <br />
                              <br />
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                ) : (
                  '-'
                )
              )
            )}

            {/* <span className="incubator-label">
            15 Jan 2021 | 10:30am - 11:30am
          </span> */}
          </div>
          <div className="col-md-5">
            {/* <label className="incubator-label">Funds Approved</label>
          <label className="eac-review-title">&#8377;2.5 L</label> */}
          </div>
        </div>

        <div className="eac-incubator-hr mt-3">
          <hr />
        </div>

        {/* //incubator selection */}
        <div className="row">
          <div className="col-md-7 left-align">
            <span className="eac-review-title">Startup Selection</span>
            <br />
            {meetingAllData == undefined ||
              meetingAllData.length == 0 ? (
              <span className="incubator-label">-</span>
            ) : (
              meetingAllData.map((meeting: any, index) =>
                meeting.meeting_type == 1 ? (
                  <div key={index}>
                    <div className="incubator-label">
                      {formatDateWithMonth(new Date(meeting.meeting_date))} |{' '}
                      {meeting.meeting_from_time} {'-'} {meeting.meeting_to_time}
                    </div>
                    <div className="row  mt-15-px">
                      <div className="col-md-10 left-align">
                        {checkMeetingDate(meeting.meeting_date) == true ? (
                          <div>
                            <span className="eac-review-subtitle">
                              {/* EAC */}
                              Comments/Feedback
                            </span>
                            <br />
                          </div>
                        ) : (
                          ''
                        )}
                        {application_comments != undefined &&
                          application_comments.length > 0
                          ? application_comments
                            // .filter(
                            //   function (item) {
                            //     return  item.meeting_type == 1   //&& item.startup_meeting_id ==meeting.startup_meeting_id
                            // })
                            .map((item: any, index) =>
                              item.startup_meeting_id === meeting.startup_meeting_id ? (
                                <div key={index}>
                                  <span className="eac-p"> {item.comment}</span>
                                  <br />
                                </div>
                              ) : (
                                ''
                              )
                            )
                          : ''}
                        {/* {checkMeetingDate(meeting.meeting_date) == true ? ( */}
                        {/* {console.log(flag,"gg")} */}

                        {(checkMeetingDate(meeting.meeting_date) == true) &&
                          // ((isevid < currentdatenew)&&(isClickid == '2' ||isClickid == '3' ||isClickid == '4'||isClickid == '5')) ? 
                          (((isevid < currentdatenew) && ((isPref1 == '7' && isClickid == '7' && flag == '1') || (isClickid == '2' || isClickid == '3' || isClickid == '4' || isClickid == '5')))) ?
                          (<div>
                            <a
                              className="incubator-details-link"
                            >
                              Add Comment
                            </a>
                            <br />
                            <br />
                          </div>)
                          :

                          (<div>
                            <a
                              className="incubator-details-link"
                              data-toggle="modal"
                              data-target="#addEditComment"
                              onClick={() =>
                                setMeetingModal(
                                  '1',
                                  meeting.startup_meeting_id,
                                  meeting.comment
                                )
                              }
                            >
                              Add Comment
                            </a>
                            <br />
                            <br />
                          </div>
                          )

                        }
                      </div>
                    </div>
                  </div>
                ) : (
                  '-'
                )
              )
            )}

            {/* <span className="incubator-label">
            3 Oct 2020 | 02:30pm - 04:30pm
          </span> */}






          </div>

          {/* fund details comment */}
          {/* <div className="col-md-5">
          <span className="incubator-label">Funds Requested</span>
          <br />
          {generalDetail.quantum_fund_required == null ? (
            <span className="incubator-label-value">-</span>
          ) : (
            <span className="incubator-label-value">
              &#8377;
              {generalDetail.quantum_fund_required != null
                ? changeNumberFormat(
                    Number(generalDetail.quantum_fund_required),
                    1,
                    0
                  )
                : 0}
            </span>
          )}

          <br />
          <span className="incubator-label">Funds Approved</span>
          <br />
          {generalDetail.total_approved_amt == null ? (
            <span className="incubator-label-value">-</span>
          ) : (
            <span className="incubator-label-value">
              &#8377;
              {generalDetail.total_approved_amt != null
                ? changeNumberFormat(
                    Number(generalDetail.total_approved_amt),
                    1,
                    0
                  )
                : 0}
            </span>
          )}
        </div> */}
        </div>
        <br />

        {/* Evaluation Score */}
        {/* <div className="row mt-05">
        <div className="col-md-2 left-align">
          <span className="eac-heading-title">Evaluation Score</span>
        </div>
        <div className="col-md-10 left-align">
          <span className="label-lh-30">
            &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;( On a scale 0-5 )
          </span>
        </div>
      </div> */}

        {/* <div className="row margin-5">
        <div className="col-md-5 left-align">
          <div className="fund-amount-label-value">
            {generalDetail.evaluation_avg_score == null ||
            generalDetail.evaluation_avg_score.toString() == ''
              ? '-'
              : generalDetail.evaluation_avg_score.toFixed(2)}{' '}
          </div>
        </div>
      </div> */}

        <div className="row">
          {/* {generalDetail.evaluationData != undefined &&
        generalDetail.evaluationData.length > 0
          ? generalDetail.evaluationData.map((eac: any) => (
              <div className="col-md-3">
                <div className="eac-rectangle">
                  <div className="row ">
                    <div className="col-md-6 left-align margin-left-10">
                      <a className="incubator-link-number">
                        {eac.eac_score.toFixed(2)}
                      </a>
                      <br />
                      <span className="eac-member ">EAC Member</span>
                      <br />{' '}
                      <span className="eac-member-name ">
                        {eac.name_initials}
                      </span>
                    </div>
                    <div className="col-md-3 left-align">
                      <a
                        className="incubator-details-link"
                        onClick={() =>
                          setModalData(eac.eac_id, eac.incubator_id)
                        }
                        data-toggle="modal"
                        data-target="#viewEacDetails"
                      >
                        Details
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))
          : ''} */}

          <div
            className="modal fade"
            id="viewEacDetails"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header modal-header-border-none  ml-18px">
                  <h5 className="modal-title pl-2" id="exampleModalLongTitle">
                    Evaluation by EAC{' '}
                    <span className="eac-name-pop-up">
                      (
                      {eacReviewData.eac_name == '' ? '' : eacReviewData.eac_name}
                      )
                    </span>
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body mt-3-px">
                  <div className="row">
                    <div className="col-md-6 left-align">
                      <span className="fund-amount-label-value ml-21-px">
                        {eacReviewData.eac_score}{' '}
                      </span>
                      <br />
                      <span className="eac-heading-title ml-21-px">
                        Evaluation Score
                      </span>
                      &nbsp;&nbsp;
                      <span className="incubator-label ">( Out of 5 )</span>
                    </div>
                    <div className="col-md-6">
                      {/* <input
                        className="searchBar"
                        type="text"
                        placeholder="View Criteria "
                        name="search"
                      /> */}
                    </div>
                  </div>

                  <div className="table-scroll mb-5">
                    <table id="example">
                      <thead>
                        <tr>
                          <th className="custom-td-left left-align table-heading">
                            Evaluation Criteria
                          </th>
                          <th className="text-left table-heading">
                            Sub-Evaluation Criteria
                          </th>
                          <th className="text-left table-heading">Score</th>
                          <th className="left-align table-heading">Comments</th>
                        </tr>
                      </thead>
                      <tbody>
                        {eacReviewData.data.map((eac) =>
                          eac.question.map((ques, index2) => (
                            <tr key={index2}>
                              <td className="text-left custom-td-left">
                                {index2 == 0 ? eac.label : ''}
                              </td>
                              <td className="text-left">{ques.esb_label}</td>
                              <td className="text-left">{ques.score}</td>

                              <td className="text-left">{ques.comments}</td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div className="row pt-3 mb-3">
                    <div className="col-md-12 centerText">
                      <button
                        className="tertiary  btn-yellow-primary"
                        data-dismiss="modal"
                      >
                        OK
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="addEditComment"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-md"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header modal-header-border-none">
                  <h5 className="modal-title " id="exampleModalLongTitle">
                    {/* {comments == ""  && comments.length==0 ? 'Add' : 'Edit'}  */}
                    Add Meeting Comment <span className="eac-name-pop-up"></span>
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12 left">
                      <textarea
                        className="form-control"
                        name="comment"
                        value={comments == null ? '' : comments}
                        rows={3}
                        placeholder="Enter Comment"
                        onChange={handleOnChange}
                      ></textarea>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 right">
                      <div className="btn-group mt-1-5 ">
                        <button
                          className="tertiary   btn-transparent-primary"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>

                        <button
                          type="button"
                          className="btn btn-sisfs btn-yellow-primary ml-2"
                          onClick={() => addUpdateComment(comments)}
                          data-dismiss="modal"
                        >
                          {/* {comments == ""  && comments.length==0  ? 'Add' : 'Edit'} */}
                          Add Comment{' '}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="row pt-3 mb-3">
                    <div className="col-md-12 centerText"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        :
        <div style={{ color: 'red', textAlign: "center", fontSize: '25px' }}>
          <span>No Data</span>
        </div>}
    </>
  );
}
export default MeetingTab;
