import { FileUploadTypeModel } from "../FileUploadModel";

export class StartupTeamModel {
    constructor(
      public ceo_firstname: string,
      public ceo_lastname: string,
      public ceo_description: string,
      public ceo_linkedin_link: string,
      public fulltime_employees_no: number,
      public team_data: Array<TeamDetails>,
      public promoter_data: Array<PromoterDetails>,
      public promoters_aadhar_cards: Array<FileUploadTypeModel>,
    ) {
      this.ceo_firstname = ceo_firstname;
      this.ceo_lastname = ceo_lastname;
      this.ceo_description = ceo_description;
      this.ceo_linkedin_link = ceo_linkedin_link;
      this.fulltime_employees_no = fulltime_employees_no;
      this.team_data = team_data;
      this.promoter_data = promoter_data;
      this.promoters_aadhar_cards=promoters_aadhar_cards;
    }
  }
  
  export class TeamDetails {
    constructor(
      public team_id: number,
      public team_name: string,
      public no_employee: number
    ) {
      this.team_id = team_id;
      this.team_name = team_name;
      this.no_employee = no_employee;
    }
  }

  export class PromoterDetails {
    constructor(
      public promoter_id: number,
      public name: string,
      public aadhar_number: string
    ) {
      this.promoter_id = promoter_id;
      this.name = name;
      this.aadhar_number = aadhar_number;
    }
  }
  