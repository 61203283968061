import { LoginModel } from "../models/LoginModel";

export interface IAction {
    type: string,
    payload: any
}

export class Action {
    static SET_USER: string = 'SET_USER';
    static SET_ERROR: string = "SET_ERROR";
}