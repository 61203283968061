import React, { Component, createRef, useEffect, useState } from "react";
import filter from "../../../images/filter.svg";
import edit from "../../../images/edit.svg";
import search from "../../../images/search.svg";
import { getSessionStorageValue } from "../../../../effects/utils/session-storage";
import { SPOC_DETAILS } from "../../../../constants/api";
import { post } from "../../../../effects/api/api.common";
import Plus from '../../../../images/Plus.svg';
import Minus from '../../../../images/Minus.svg';

function MergedContactDetailsComponent(props: any) {
  const { contact_Data } = props
  const [loggedInRole, setLoggedInRole] = useState("");
  const [spocDetails, setSpocDetails] = useState({
    representative_name: "",
    representative_designation: "",
    representative_mobile_no: "",
    representative_email: "",
    representative_name_1: "",
    representative_designation_1: "",
    representative_mobile_no_1: "",
    representative_email_1: "",
    other_email: ""
  });
  const [isPopoverVisible, setPopoverVisible] = useState('');
  useEffect(() => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    setLoggedInRole(userInfo.roles);
    window.scrollTo(0, 0);
    getSpocDetails();
  }, []);
  const getSpocDetails = () => {

    const payload = {
      "incubator_id": contact_Data[0].user_id
    }
    post(SPOC_DETAILS, payload).then((response: any) => {
      if (!response || !response.data.data) return;
      setSpocDetails(response.data.data[0]);
    })
  }
  const [activeIndexApp, setActiveIndexApp] = useState('');
  const changeActiveApp = (id: string) => {
    setActiveIndexApp(id);
  };

  const changeActiveBackApp = () => {
    setActiveIndexApp('');
  };

  const handleCopyClick = (textToCopy: any,id:any) => {
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);

    setPopoverVisible(id);
    setTimeout(() => {
      setPopoverVisible('');
    }, 2000);
  };

  return (
    <div>
      {/* <div className="row left-align mt-05">
        <div className="col-md-4">
          <span className="incubator-label">Name of the Representative</span>
          <br />
          {console.log(spocDetails, "HHHHH")}
          <span className="incubator-label-value">
            {contact_Data[0].representative_name == '' ||
              contact_Data[0].representative_name == null
              ? '-'
              : contact_Data[0].representative_name}
          </span>
        </div>
        <div className="col-md-4">
          <span className="incubator-label">Designation</span>
          <br />
          <span className="incubator-label-value">
            {contact_Data[0].representative_designation == '' ||
              contact_Data[0].representative_designation == null
              ? '-'
              : contact_Data[0].representative_designation}
          </span>
        </div>
      </div>

      <div className="row left-align mt-05">
        <div className="col-md-4">
          <span className="incubator-label">Mobile No.</span>
          <br />
          <span className="incubator-label-value">
            {contact_Data[0].representative_mobile_no == null ||
              contact_Data[0].representative_mobile_no == null
              ? '-'
              : contact_Data[0].representative_mobile_no}
          </span>
        </div>
        <div className="col-md-4">
          <span className="incubator-label">Email</span>
          <br />

          {contact_Data[0].representative_email == '' ||
            contact_Data[0].representative_email == null ? (
            '-'
          ) : (
            <a
              className="incubator-email-value"
              href={`mailto:${contact_Data[0].representative_email}`}
            >
              {contact_Data[0].representative_email}
            </a>
          )}
        </div>
      </div>

      <div className="row left-align mt-05">
        <div className="col-md-4">
          <span className="label-lh-28">Incubator Address</span>
          <br />
          <span className="value-lh-20">
            {contact_Data[0].address == '' ||
              contact_Data[0].address == null
              ? '-'
              : contact_Data[0].address}
          </span>
        </div>
        <div className="col-md-4"></div>
      </div>

      <div className="row left-align">
        <div className="col-md-4"></div>
        <div className="col-md-4"></div>
      </div> */}

      <div
        className="accordion accordion-details-wrap text-left mt-3"
        id="accordion-application-details">
        <div className="row">
          <div
            className="col-md-12 left-align"
            style={{ backgroundColor: '#f8f7f7', padding: '10px', marginLeft: '10px', borderRadius: '5px' }}>
            <span className="eac-heading-title" style={{fontSize:'15px'}}>SPOC 1</span>
            <span className="">
              {activeIndexApp == '2' ? (
                <div
                  onClick={changeActiveBackApp}
                  data-toggle="collapse"
                  data-target="#1comments-expand"
                  role="button"
                  aria-expanded="false"
                  aria-controls="1comments-expand"
                  className="hide-icon pull-right hide-icon-details"
                  id="show-1">
                  <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                </div>
              ) : (
                <div
                  onClick={() => changeActiveApp('2')}
                  id="hide-1"
                  data-toggle="collapse"
                  data-target="#1comments-expand"
                  role="button"
                  aria-expanded="false"
                  aria-controls="1comments-expand"
                  className="expand-icon pull-right expand-icon-details">
                  <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                </div>
              )}
            </span>
          </div>
        </div>

        {activeIndexApp == '2' ? (
          <div className="collapse " id="1comments-expand">

            <div className="card accordion-card">
              <div className="card-body">
                <div className="row left-align mt-05">
                  <div className="col-md-6">
                    <span className="incubator-label">Name</span>
                    <br />
                    {console.log(spocDetails, "HHHHH")}
                    <span className="incubator-label-value">
                      {contact_Data[0].representative_name == '' ||
                        contact_Data[0].representative_name == null
                        ? '-'
                        : contact_Data[0].representative_name}
                    </span>
                  </div>
                  <div className="col-md-6">
                    <span className="incubator-label">Designation</span>
                    <br />
                    <span className="incubator-label-value">
                      {contact_Data[0].representative_designation == '' ||
                        contact_Data[0].representative_designation == null
                        ? '-'
                        : contact_Data[0].representative_designation}
                    </span>
                  </div>
                </div>

                <div className="row left-align mt-05">
                  <div className="col-md-6">
                    <span className="incubator-label">Mobile No.</span>
                    <br />
                    <span className="incubator-label-value">
                      {contact_Data[0].representative_mobile_no == null ||
                        contact_Data[0].representative_mobile_no == null
                        ? '-'
                        : 
                        (
                          <>
                          {contact_Data[0].representative_mobile_no}
                          &nbsp;<i className="fa fa-copy" style={{cursor:'pointer',color:'#25429c'}} onClick={() => handleCopyClick(contact_Data[0].representative_mobile_no,'1')}></i>
                        {isPopoverVisible=='1' && (
                        <div
                          style={{
                            position: "absolute",
                            // top: "50px",
                            left: "50%",
                            width:'200px',
                            transform: "translateX(-50%)",
                            background: "#fff",
                            padding: "10px",
                            fontSize: '14px',
                            zIndex:'999',
                            boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                            borderRadius: "5px",
                            
                          }}
                        >
                          Copied to clipboard!
                        </div>
                      )}
                          </>
                        )
                        }
                    </span>
                  </div>
                  <div className="col-md-6">
                    <span className="incubator-label">Email</span>
                    <br />

                    {contact_Data[0].representative_email == '' ||
                      contact_Data[0].representative_email == null ? (
                      '-'
                    ) : (
                      <>
                      <a
                        className="incubator-email-value"
                        href={`mailto:${contact_Data[0].representative_email}`}
                      >
                        {contact_Data[0].representative_email}
                        
                      </a>
                      &nbsp;<i className="fa fa-copy" style={{cursor:'pointer',color:'#25429c'}} onClick={() => handleCopyClick(contact_Data[0].representative_email,'2')}></i>
                        {isPopoverVisible=='2' && (
                        <div
                          style={{
                            position: "absolute",
                            // top: "50px",
                            left: "50%",
                            width:'200px',
                            transform: "translateX(-50%)",
                            background: "#fff",
                            padding: "10px",
                            fontSize: '14px',
                            boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                            borderRadius: "5px",
                            zIndex:'999',
                            
                          }}
                        >
                          Copied to clipboard!
                        </div>
                      )}
                      </>
                    )}
                  </div>
                </div>

                <div className="row left-align mt-05">
                  <div className="col-md-10">
                    <span className="label-lh-28">Incubator Address</span>
                    <br />
                    <span className="value-lh-20">
                      {contact_Data[0].address == '' ||
                        contact_Data[0].address == null
                        ? '-'
                        : contact_Data[0].address}
                    </span>
                  </div>
                  <div className="col-md"></div>
                </div>
              </div>
            </div>


          </div>
        ) : (
          ''
        )}

      </div>

      <div
        className="accordion accordion-details-wrap text-left mt-3"
        id="accordion-application-details">
        <div className="row">
          <div
            className="col-md-12 left-align"
            style={{ backgroundColor: '#f8f7f7', padding: '10px', marginLeft: '10px', borderRadius: '5px' }}>
            <span className="eac-heading-title" style={{fontSize:'15px'}}>SPOC 2</span>
            <span className="">
              {activeIndexApp == '1' ? (
                <div
                  onClick={changeActiveBackApp}
                  data-toggle="collapse"
                  data-target="#2comments-expand"
                  role="button"
                  aria-expanded="false"
                  aria-controls="2comments-expand"
                  className="hide-icon pull-right hide-icon-details"
                  id="show-1">
                  <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                </div>
              ) : (
                <div
                  onClick={() => changeActiveApp('1')}
                  id="hide-1"
                  data-toggle="collapse"
                  data-target="#2comments-expand"
                  role="button"
                  aria-expanded="false"
                  aria-controls="2comments-expand"
                  className="expand-icon pull-right expand-icon-details">
                  <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                </div>
              )}
            </span>
          </div>

        </div>


        {activeIndexApp == '1' ? (
          <div className="collapse " id="2comments-expand">

            <div className="card accordion-card">
              <div className="card-body">
              <div className="row left-align mt-05">
                  <div className="col-md-4">
                    <span className="incubator-label">Name</span>
                    <br />
                    <span className="incubator-label-value">
                      {spocDetails.representative_name_1 == '' ||
                        spocDetails.representative_name_1 == null
                        ? '-'
                        : spocDetails.representative_name_1}
                    </span>
                  </div>
                  <div className="col-md-4">
                    <span className="incubator-label">Designation</span>
                    <br />
                    <span className="incubator-label-value">
                      {spocDetails.representative_designation_1 == '' ||
                        spocDetails.representative_designation_1 == null
                        ? '-'
                        : spocDetails.representative_designation_1}
                    </span>
                  </div>
                </div>

                <div className="row left-align mt-05">
                  <div className="col-md-4">
                    <span className="incubator-label">Mobile No.</span>
                    <br />
                    <span className="incubator-label-value">
                      {spocDetails.representative_mobile_no_1 == "" ||
                        spocDetails.representative_mobile_no_1 == null
                        ? '-'
                        : 
                        <>
                        {spocDetails.representative_mobile_no_1}
                        &nbsp;<i className="fa fa-copy" style={{cursor:'pointer',color:'#25429c'}} onClick={() => handleCopyClick(spocDetails.representative_mobile_no_1,'3')}></i>
                        {isPopoverVisible=='3' && (
                        <div
                          style={{
                            position: "absolute",
                            // top: "50px",
                            left: "50%",
                            width:'200px',
                            transform: "translateX(-50%)",
                            background: "#fff",
                            padding: "10px",
                            fontSize: '14px',
                            boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                            borderRadius: "5px",
                            zIndex:'999',
                            
                          }}
                        >
                          Copied to clipboard!
                        </div>
                      )}
                        </>

                      }
                    </span>
                  </div>
                  <div className="col-md-4">
                    <span className="incubator-label">Email</span>
                    <br />

                    {spocDetails.representative_email_1 == '' ||
                      spocDetails.representative_email_1 == null ? (
                      '-'
                    ) : (
                      <>
                      <a
                        className="incubator-email-value"
                        href={`mailto:${spocDetails.representative_email_1}`}
                      >
                        {spocDetails.representative_email_1}
                      </a>
                      &nbsp;<i className="fa fa-copy" style={{cursor:'pointer',color:'#25429c'}} onClick={() => handleCopyClick(spocDetails.representative_email_1,'4')}></i>
                        {isPopoverVisible=='4' && (
                        <div
                          style={{
                            position: "absolute",
                            // top: "50px",
                            left: "50%",
                            width:'200px',
                            transform: "translateX(-50%)",
                            background: "#fff",
                            padding: "10px",
                            fontSize: '14px',
                            boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                            borderRadius: "5px",
                            zIndex:'999',
                            
                          }}
                        >
                          Copied to clipboard!
                        </div>
                      )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>


          </div>
        ) : (
          ''
        )}

      </div>

      <div
        className="accordion accordion-details-wrap text-left mt-3"
        id="accordion-application-details">
        <div className="row">
          <div
            className="col-md-12 left-align"
            style={{ backgroundColor: '#f8f7f7', padding: '10px', marginLeft: '10px', borderRadius: '5px' }}>
            <span className="eac-heading-title" style={{fontSize:'15px'}}>ANOTHER EMAIL</span>
            <span className="">
              {activeIndexApp == '3' ? (
                <div
                  onClick={changeActiveBackApp}
                  data-toggle="collapse"
                  data-target="#3comments-expand"
                  role="button"
                  aria-expanded="false"
                  aria-controls="3comments-expand"
                  className="hide-icon pull-right hide-icon-details"
                  id="show-1">
                  <img src={Minus} className="img-fluid" alt="SISFS Logo" />
                </div>
              ) : (
                <div
                  onClick={() => changeActiveApp('3')}
                  id="hide-1"
                  data-toggle="collapse"
                  data-target="#3comments-expand"
                  role="button"
                  aria-expanded="false"
                  aria-controls="3comments-expand"
                  className="expand-icon pull-right expand-icon-details">
                  <img src={Plus} className="img-fluid" alt="SISFS Logo" />
                </div>
              )}
            </span>
          </div>

        </div>


        {activeIndexApp == '3' ? (
          <div className="collapse " id="3comments-expand">

            <div className="card accordion-card">
              <div className="card-body">
              <div className="col-md-4 p-0">
                    <span className="incubator-label">Email</span>
                    <br />

                    {spocDetails.other_email == '' ||
                      spocDetails.other_email == null ? (
                      '-'
                    ) : (
                      <>
                      <a
                        className="incubator-email-value"
                        href={`mailto:${spocDetails.other_email}`}
                      >
                        {spocDetails.other_email}
                      </a>
                      &nbsp;<i className="fa fa-copy" style={{cursor:'pointer',color:'#25429c'}} onClick={() => handleCopyClick(spocDetails.other_email,'5')}></i>
                        {isPopoverVisible=='5' && (
                        <div
                          style={{
                            position: "absolute",
                            // top: "50px",
                            left: "50%",
                            width:'200px',
                            transform: "translateX(-50%)",
                            background: "#fff",
                            padding: "10px",
                            fontSize: '14px',
                            boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                            borderRadius: "5px",
                            zIndex:'999',
                            
                          }}
                        >
                          Copied to clipboard!
                        </div>
                      )}
                      </>
                    )}
                  </div>
              </div>
            </div>


          </div>
        ) : (
          ''
        )}

      </div>
    </div>
  )
}
export default MergedContactDetailsComponent;