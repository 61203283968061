import * as React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import DpiitDashboardContainer from "../components/dpiit/dashboard/DpiitContainer";
import EacDashboardContainer from "../components/eac/dashboard/EacContainer";
import IncubatorDashboardContainer from "../components/incubator/dashboard/IncubatorContainer";
import IncubatorListingContainer from "../components/dpiit/dashboard/IncubatorListingContainer";
import DashboardFooterComponent from "../components/common/footer/DashboardFooterComponent";
import IncubatorHeaderComponent from "../components/incubator/common/IncubatorHeaderComponent";
import ApplicationApplyContainer from "../components/incubator/application/myapplications/ApplicationApplyContainer";
import ApplicationStatusComponent from "../components/incubator/application/myapplications/ApplicationStatusComponent";
import IncubatorContainerMain from "../components/incubator/application/IncubatorContainerMain";
import HomeContainer from "../components/home/HomeContainer";
import IncubatorContainer from "../components/incubator/dashboard/IncubatorContainer";
import IncubatorMeetingComponent from "../components/incubator/meetings/IncubatorMeetingComponent";
import { getSessionStorageValue } from "../effects/utils/session-storage";
import { useEffect } from "react";
import FundDetailsContainer from "../components/incubator/dashboard/FundDetailsContainer";
import GrievanceListComponent from "../components/dpiit/grievances/GrievanceListComponent";
import ContactContainer from "../components/contact/ContactContainer";
import GrievanceDetailComponent from "../components/dpiit/grievances/GrievanceDetailComponent";
import StartupListContainer from "../components/incubator/startuplisting/StartupListContainer";
import ViewStartupDetailsComponent from "../components/incubator/startuplisting/startupdetails/ViewStartupDetailsComponent";
import ReApplyIncComponent from "../components/incubator/application/ReApplyIncComponent";
import ApplicationStatusApply from "../components/incubator/application/myapplications/ApplicationStatusApply";
import FundRequiremntDtls from "../components/incubator/application/FundRequiremntDtls";
import MyPortfolio from "../components/incubator/common/MyPortfolio";
import PreviewPortfolio from "../components/dpiit/PreviewPortfolio";
import RequireAuth from "../Routelocks/RouterHoooks";
import SubsequentTranche from "../components/incubator/dashboard/SubsequentTranche";
import SubsequentTrancheTwo from "../components/incubator/dashboard/SubsequentTrancheTwo";
import FirstTrancheSubsequent from "../components/incubator/dashboard/FirstTrancheSubsequent";
import NoticeBoard from "../components/notice_board/NoticeBoard";
import errorReducer, { IErrorInitialState } from "../reducers/error.reducer";
import { ErrorModel } from "../models/common/ErrorModel";
import { StateErrorProvider } from "../providers/ErrorProvider";
const IncubatorRouterComponent = () => {
  const initialErrorState = (): IErrorInitialState => {
    return {
      errors: new ErrorModel("", "", ""),
    };
  };
  const history = useHistory();
  const user: any = getSessionStorageValue("user");
  const userInfo = JSON.parse(user);
  useEffect(() => {
    //console.log(user,userInfo.roles)
    if (user == null || userInfo.roles != "incubator") {
      history.push("/error");
    }
  }, []);
  return (
    <>
      <Switch>
        <Route
          path="/incubatorApplications/:token"
          component={ApplicationStatusComponent}
        />
      </Switch>
      <RequireAuth>
        {/* Dashboard Header Component */}
        <IncubatorHeaderComponent />
        <Switch>
          <Route path="/fund_details_re_apply" component={FundRequiremntDtls} />

          <Route path="/re-apply" component={ReApplyIncComponent} />
          <Route
            path="/incubator_re_apply"
            component={ApplicationStatusApply}
          />

          <Route
            path="/incubator_dashboard"
            component={IncubatorDashboardContainer}
          />
          <Route exact path="/incubator_notice_board" component={NoticeBoard} />
          {/* <Route path="/incubator" component={IncubatorContainer} /> */}
          <Route path="/incubator" component={IncubatorContainerMain} />

          <Route
            path="/incubator_apply"
            component={ApplicationApplyContainer}
          />

          <Route
            path="/incubator_status"
            component={ApplicationStatusComponent}
          />
          <Route
            exact
            path="/incubator_re_apply"
            component={ApplicationStatusApply}
          />

          <Route
            path="/incubator_grievances"
            component={GrievanceListComponent}
          />

          <Route path="/contact_incubator" component={ContactContainer} />
          <Route path="/incubator_portfolio" component={MyPortfolio} />
          <Route
            path="/grievance_detail_incubator"
            component={GrievanceDetailComponent}
          />

          <Route
            path="/incubator_meeting"
            component={IncubatorMeetingComponent}
          />

          <Route
            path="/incubator_view_funddetails"
            component={FundDetailsContainer}
          />
          <Route
            path="/subsequent_tranche/:application_id/:tranche_id/:fund_request/:cash_in_hand/:tranche_number/:app_index/:incubator_application_no"
            component={SubsequentTranche}
          />
          <Route
            path="/subsequent_tranche_two/:application_id/:tranche_id/:fund_request/:cash_in_hand/:tranche_number/:app_index/:incubator_application_no"
            component={SubsequentTrancheTwo}
          />
          <Route
            path="/first_subsequent_tranche/:application_id/:tranche_id/:fund_request/:cash_in_hand/:tranche_number/:app_index/:incubator_application_no"
            component={FirstTrancheSubsequent}
          />
          <Route
            path="/view_startup_details"
            component={ViewStartupDetailsComponent}
          />

          <Route
            path="/incubator_startup_list"
            component={StartupListContainer}
          />

          <Route path="/preview_portfolio_page" component={PreviewPortfolio} />

          <StateErrorProvider
            initialState={initialErrorState()}
            reducer={errorReducer}
          >
            <Route path="/incubator" component={IncubatorContainerMain} />
          </StateErrorProvider>
        </Switch>
        {/* Dashboard Footer Component */}
        <DashboardFooterComponent />
      </RequireAuth>
    </>
  );
};

export default IncubatorRouterComponent;
