import ProgressBar from './ProgressBar';
import circle_exclamation from '../../../images/ReturnsIcons/circle_exclamation.svg';
import circle_check from '../../../images/ReturnsIcons/circle_check.svg';
import downoadFile from '../../../images/ReturnsIcons/downloads.svg';
import download_disabled from '../../../images/ReturnsIcons/download_disabled.svg'
interface IIncubatorProps {
    item: any;
    selectedCheckboxIds: any;
    handleCheckboxClick: any;
    activate_qr: any;
    de_activate_qr: any;
    yearV: any;
    quaterValue: any;
    download_excel:any;
    excelDownloadClicked:any;
  }

function QrSortingComponent(props: IIncubatorProps) {
    const {item, selectedCheckboxIds, handleCheckboxClick, activate_qr, de_activate_qr, yearV, quaterValue, download_excel,excelDownloadClicked} = props

    return(
        <tr style={{ textAlignLast: 'center' }}>
            {/* {console.log(item)} */}
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedCheckboxIds.some(
                                  (checkboxItem: any) =>
                                    checkboxItem.incubator_id === item.incubator_id,
                                )}
                                onChange={() =>
                                  handleCheckboxClick(item.incubator_id, item)
                                }
                                disabled={excelDownloadClicked && item.submitted == 0}

                              />
                            </td>
                            <td>{item.incubator_application_no}</td>
                            <td>{item.registered_name}</td>
                            <td>
                              {item.no_of_QR_submitted}
                              {/* <input className="form-control" style={{ width: "150px", marginLeft: 'auto', marginRight: 'auto' }} /> */}
                            </td>
                            {/* {console.log(item.status,"button")} */}
                            <td className="text-center custom-td1 slider-td1">
                              {/* || (item.submitted == 0 && item.status == 1) */}
                              <fieldset
                                disabled={
                                  item.disable123
                                }>
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={item.status == 0 ? false : true}
                                    onChange={checked => {
                                      // console.log(
                                      //   checked.target.value,
                                      //   checked,
                                      //   'checked',
                                      // );
                                      const newStatus = !item.status; // Convert the checked value to 1 or 0
                                      if (newStatus) {
                                        activate_qr(1, item.incubator_id, 'ind'); // Call the activate_qr function
                                      } else {
                                        de_activate_qr(0, item.incubator_id, 'ind'); // Call the dev_qr function
                                      }
                                    }}
                                  />
                                  <span
                                    className="slider1 round1"
                                    style={{
                                      color: '#fff',
                                      fontSize: '9px', // Adjust the font size as needed
                                      fontWeight: 100,
                                    }}>
                                    {' '}
                                    <span
                                      style={{
                                        marginRight:
                                          item.status == 0 ? '-11px' : '12px',
                                      }}>
                                      {item.status === 0 ? 'Off' : 'On'}
                                    </span>
                                  </span>
                                </label>
                                {/* <BootstrapSwitchButton
                                              checked={item.status == 0 ?false:true}
                                              onlabel=''
                                              offlabel=''
                                              onstyle="success"
                                              offstyle="danger"
                                              onChange={(checked) => {
                                                  const newStatus = checked ? 1 : 0; // Convert the checked value to 1 or 0
                                                  if (newStatus === 1) {
                                                      activate_qr(newStatus,item.incubator_id,"ind"); // Call the activate_qr function
                                                  } else {
                                                      
                                                          de_activate_qr(newStatus,item.incubator_id,"ind"); // Call the dev_qr function
                                                                                                        
                                                  }
                                              }}
                                         
                                          /> */}
                              </fieldset>
                            </td>
                            <td>
                              {/* <fieldset disabled={item.disable123}>
                                          <BootstrapSwitchButton                                            
                                              checked={item.status == 2?true:false}
                                              onlabel='Yes'
                                              offlabel='No'
                                              onstyle="success"
                                              offstyle="danger"
                                          // onChange={(checked: boolean) => {
                                          //     this.setState({ isUserAdmin: checked })
                                          // }}
                                          />
                                          </fieldset> */}
                              {/* {item.status == 2?"Yes":"No"} */}

                              {item.submitted == 1 ? (
                                <img src={circle_check} style={{ width: ' 20px' }} />
                              ) : item.submitted == 0 && item.status == 1 ? (
                                <img
                                  src={circle_exclamation}
                                  style={{ width: ' 20px' }}
                                />
                              ) : (
                                '-'
                              )}
                            </td>
                            <td>
                              {/* <ProgressBar numerator={parseInt(item.progress.split('/')[0])} denominator={parseInt(item.progress.split('/')[1])} /> */}
                              {/* {console.log(yearV, '999999999999999999')}
                              {console.log(quaterValue, '3333333333333')} */}
                              {yearV && quaterValue && item.completed_QR_applications!=undefined && item.total_QR_applications!=undefined ? <ProgressBar numerator={item.completed_QR_applications} denominator={item.total_QR_applications} /> : "-"}
                              {/* <input
                              className="form-control"
                              style={{
                                width: '150px',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                              }}
                            /> */}
                            </td>
                            <td>
                            {item.activated == 1 && item.status == 0 ? (
                              <img
                                onClick={() =>
                                  download_excel('ind',item.incubator_id)
                                }
                                src={downoadFile}
                                style={{ width: ' 21px' }}
                              />):(
                                <img
                                // onClick={() =>
                                //   download_excel('ind',item.incubator_id)
                                // }
                                src={download_disabled}
                                style={{ width: ' 21px' }}
                              />
                              )}
                            </td>
                          </tr>
    )
}

export default QrSortingComponent;