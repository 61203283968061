export class FundReapplyDtlsModel {
    constructor(
        public action : any,
        public quantum_fund_required_re_apply: any,
        public no_fund_benifitiaries_1yr_re_apply: any,
        public no_fund_benifitiaries_2yr_re_apply: any,
        public no_fund_benifitiaries_3yr_re_apply: any,
    ){
        this.action = action
        this.quantum_fund_required_re_apply = quantum_fund_required_re_apply;
        this.no_fund_benifitiaries_1yr_re_apply = no_fund_benifitiaries_1yr_re_apply;
        this.no_fund_benifitiaries_2yr_re_apply = no_fund_benifitiaries_2yr_re_apply;
        this.no_fund_benifitiaries_3yr_re_apply = no_fund_benifitiaries_3yr_re_apply; 
       
    }
}

