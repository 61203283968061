import React from 'react';
import features from '../../images/features-home.svg';

function HomeFeaturesComponent(){
    return(
        <section className="section-container section-bg">
            <div className="container">
                <div className="section-features"> 

                    <div className="section-heading">
                        <h2>Features of Startup India Seed Fund Scheme</h2>
                    </div>
                    <div className="row justify-content-center">
                        {/* <div className="col-xs-12 col-sm-6 section-content text-center">
                            <p>We help cover the first Valley of Death for Startups
                                and help them to become Investible.</p>
                        </div> */}
                    </div>

                    <div className="row justify-content-center pt-3">
                        <div className="col-xs-12 col-lg-6">
                            <div className="section-image">
                                <div className="item-center height-150">
                                    <img className="img-fluid mb-0" src={features} alt="Features of SISFS" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-lg-6">
                            <div className="features-block">
                                
                                <div className="section-content">                                        
                                    <div className="ordered-list">
                                        <ol>
                                            <li>Year-round ‘Call for Applications’ for Incubators and Startups</li>
                                            <li>Sector-agnostic</li>
                                            <li>No mandatory physical incubation</li>                          
                                            <li>PAN-India startup programme</li>
                                            <li>Startups can apply to 3 incubators simultaneously</li>
                                        </ol>   
                                    </div>                                    
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomeFeaturesComponent;