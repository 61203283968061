import React, { useState, useEffect } from "react";
import Stepper from "bs-stepper";
import "bs-stepper/dist/css/bs-stepper.min.css";
import StepperHeaderComponent from "./StepperHeaderComponent";
import GeneralDetailsComponent from "./GeneralDetailsComponent";
import SupportDetailsComponent from "./SupportDetailsComponent";
import TeamDetailsComponent from "./TeamDetailsComponent";
import FundDetailsComponent from "./FundDetailsComponent";
import OtherDetailsComponent from "./OtherDetailsComponent";
import FormSubmissionSuccess from "./FormSubmissionSuccess";
import { IIncubatorModel } from "./IncubatorModel";
import { GeneralDetailModel } from "../../../models/incubator/GeneralDetailModel";
import { IncubatorDetailsModel } from "../../../models/incubator/IncubatorDetailsModel";
import { requestType, StepperEnum, UploadTypeEnum } from "../../../utils/Enums";
import { SaveRequestModel } from "../../../models/incubator/SaveRequestModel";
import { post } from "../../../effects/api/api.common";
import {
  CREATE_EMPTY_APPLICATION,
  FILE_UPLOAD,
  GET_ACTIVE_APPLICATION,
  GET_APPLICATION_VIEW,
  STORE_INCUBATOR_APPLICATION,
} from "../../../constants/api";
import { FundDetailsModel } from "../../../models/incubator/FundDetailsModel";
import {
  IncubatorTeamModel,
  TeamDetails,
} from "../../../models/incubator/IncubatorTeamModel";
import { IncubatorOthersModel } from "../../../models/incubator/IncubatorOthersModel";
import FormSubmissionError from "./FormSubmissionError";
import {
  FileUploadModel,
  FileUploadTypeModel,
} from "../../../models/FileUploadModel";
import { createFile } from "../../../utils/CommonMethods";
import { Link } from "react-router-dom";
//import { isArray } from "node:util";
import ConfirmationDialog from "../../common/controls/ConfirmationDialog";
import { IQuestionModel } from "../../../models/eac/QuestionModel";
import { useHistory } from "react-router-dom";
import { ErrorModel } from "../../../models/common/ErrorModel";
import { userInfo } from "os";
import { getSessionStorageValue } from "../../../effects/utils/session-storage";
import moment from "moment";

interface IIncubatorProps {
  application_Id: number;
  location: any;
}

interface IIncubatorState {
  stepperRef: any;
  name: string;
  applicationId: number;
  incubator: IIncubatorModel;
  showIncubatorSuccessModal: boolean;
  showIncubatorErrorModal: boolean;
  // not in use but helps to call re-render method to bind other tabs
  currentStep: number;
  viewApplicationId: number;
  disabled: boolean;
}

let stepper: any = null;
let isSubmitted: boolean = false;
let formError: any;

const IncubatorContainerMain = (props: IIncubatorProps) => {
  const [name, setName] = useState("React");
  const history = useHistory();
  const [stepperRef, setStepperRef] = useState(null);
  const [applicationId, setApplicationId] = useState(0);
  const [loader, setLoader] = useState(false);
  const [incubator, setIncubator] = useState({
    GeneralDetail: new GeneralDetailModel(
      "",
      [],
      [],
      null,
      null,
      "",
      "",
      "",
      "",
      "",
      0,
      new Date(),
      "",
      new Date(),
      25,
      5,
      "",
      [],
      "",
      [],
      [],
      [],
      "",
      "",
      "",
      "",
      "",
      "",
      false,
      false,
      false,
      false,
      "",
      [],
      [],
      [],
      []
    ),
    IncubatorTeam: new IncubatorTeamModel("", "", "", 0, []),
    IncubatorDetails: new IncubatorDetailsModel(
      "",
      1,
      0,
      0,
      0,
      0,
      0,
      1,
      true,
      1,
      true,
      1,
      true,
      1,
      true,
      1,
      true,
      1,
      true,
      1,
      true,
      1,
      true,
      1,
      true,
      1,
      true,
      1,
      true,
      1,
      true,
      []
    ),
    FundDetails: new FundDetailsModel("", 1, 0, 0),
    OtherDetails: new IncubatorOthersModel(
      "",
      [],
      [],
      [],
      [],
      false,
      false,
      false,
      false,
      []
    ),
  });
  const [showIncubatorSuccessModal, setShowIncubatorSuccessModal] =
    useState(false);
  const [showIncubatorErrorModal, setShowIncubatorErrorModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(StepperEnum.GeneralDetail);

  const [disabled, setDisabled] = useState(false);
  const [validator, setValidator] = useState({
    GeneralDetail: [],
    IncubatorTeam: [],
    IncubatorDetails: [],
    FundDetails: [],
    OtherDetails: [],
  });
  const [teamError, setTeamError] = useState<Array<number>>([]);
  const [stopSubmit, setStopSubmit] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);

  //
  useEffect(() => {
    if (typeof props.location.state != "undefined") {
      // if (props.location.state.application_id == 0) {
      //   setApplicationId(0);
      // } else
      if (
        props.location.state.application_id != 0 &&
        props.location.state.disabled == true
      ) {
        setDisabled(true);
        setApplicationId(props.location.state.application_id);
        viewActiveApplication(props.location.state.application_id);
      } else if (
        props.location.state.application_id != 0 &&
        props.location.state.continue_editing == 1
      ) {
        setApplicationId(props.location.state.application_id);
        viewActiveApplication(props.location.state.application_id);
        setValidator({
          GeneralDetail: [],
          IncubatorTeam: [],
          IncubatorDetails: [],
          FundDetails: [],
          OtherDetails: [],
        });
        setTeamError([]);

        // if (!localStorage.loadIncubator) {
        //   localStorage.setItem("loadIncubator", "true");
        //   window.location.reload();
        // }
      } else {
        setApplicationId(props.location.state.application_id);
      }
    } else {
      history.push("/incubator_status");
      // if (!localStorage.setApplication) {

      //   post(CREATE_EMPTY_APPLICATION, null).then((response) => {
      //     if (!response.data) return;
      //     localStorage.setItem("setApplication", response.data.application_id);
      //     setApplicationId(response.data.application_id);
      //   });

      // }else if(localStorage.setApplication){
      //   setApplicationId(localStorage.setApplication);
      // }
    }

    stepper = new Stepper(
      document.querySelector("#incubatorstepper") as Element,
      {
        linear: false,
        animation: true,
      }
    );
    // fetch application records for each tabs and convert it into respective models
    // getActiveApplication();
    setStepperRef(stepper);
    window.scrollTo(0, 0);
  }, []);

  // Set disabled flag when we can view application when it's submitted
  const setDisabledFlag = (flag: boolean) => {
    setDisabled(true);
  };

  const handleSubmittedApplication = () => {
    window.location.reload();
  };

  //view active application
  const viewActiveApplication = (application_id: any) => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    post(GET_APPLICATION_VIEW, {
      application_id: application_id,
      user_id: userInfo.id,
    }).then(async (response: any) => {
      console.log(response, "ressss");
      if (!response || !response.data) return;

      if (response.data.data.applicationData) {
        // 1st Step
        bindGeneralDetail(response, incubator);

        // 2nd Step
        bindIncubatorTeam(response, incubator);

        // 3rd Step
        bindIncubatorDetail(response, incubator);

        // 4th Step
        bindFundDetail(response, incubator);

        await bindFiles(response, incubator);

        bindOtherDetail(response, incubator);
        console.log(response);
        setIncubator((prevState: IIncubatorModel) => {
          const updatedState = { ...prevState, incubator };
          return updatedState;
        });
      }
    });
  };

  // bind files for all tabs
  const bindFiles = async (response: any, incubator: IIncubatorModel) => {
    // console.log(response,"ressppp")
    const { file_data } = response.data.data?.applicationData;
    // incubator = JSON.parse(JSON.stringify(incubator));
    // if (file_data) {

    //   Object.keys(file_data).length &&
    //     Object.keys(file_data).map((key: string) => {
    //       switch (key) {

    //         case UploadTypeEnum.AnnualReport:
    //           // if(file_data[key]?.length){
    //           //   tempIncubator.GeneralDetail.annual_report = file_data[key]
    //           // }
    //           file_data[key].map((data: any) => {
    //             createFile(data.file).then((blob: any) => {
    //               blob.lastModifiedDate = new Date();
    //               const src = data.file as string;
    //               blob.name = src.substr(src.lastIndexOf("/") + 1, src.length);
    //               const file: FileUploadModel = new FileUploadModel(
    //                 data.file_id,
    //                 data.file_type_id,
    //                 blob as File
    //               );
    //               incubator.GeneralDetail.annual_report = [
    //                 ...incubator.GeneralDetail.annual_report,
    //                 file,
    //               ];
    //               setIncubator((prevState: IIncubatorModel) => {
    //                 const updatedState = { ...prevState, incubator };
    //                 return updatedState;
    //               });
    //             });
    //           });

    //           break;

    //         case UploadTypeEnum.ProofAssistance:
    //           file_data[key].map((data: any) => {
    //             createFile(data.file).then((blob: any) => {
    //               blob.lastModifiedDate = new Date();
    //               const src = data.file as string;
    //               blob.name = src.substr(src.lastIndexOf("/") + 1, src.length);
    //               const file: FileUploadModel = new FileUploadModel(
    //                 data.file_id,
    //                 data.file_type_id,
    //                 blob as File
    //               );
    //               incubator.GeneralDetail.proof_assistance = [
    //                 ...incubator.GeneralDetail.proof_assistance,
    //                 file,
    //               ];
    //               //setIncubator(tempIncubator);
    //               setIncubator((prevState: IIncubatorModel) => {
    //                 const updatedState = { ...prevState, incubator };
    //                 return updatedState;
    //               });
    //             });

    //           });
    //           // setIncubator(tempIncubator);
    //           break;

    //         case UploadTypeEnum.RelaventDocumentExcel:
    //           file_data[key].map((data: any) => {
    //             createFile(data.file).then((blob: any) => {
    //               blob.lastModifiedDate = new Date();
    //               const src = data.file as string;
    //               blob.name = src.substr(src.lastIndexOf("/") + 1, src.length);
    //               const file: FileUploadModel = new FileUploadModel(
    //                 data.file_id,
    //                 data.file_type_id,
    //                 blob as File
    //               );

    //               incubator.OtherDetails.relavent_document_excel = [
    //                 ...incubator.OtherDetails.relavent_document_excel,
    //                 file,
    //               ];
    //               setIncubator((prevState: IIncubatorModel) => {
    //                 const updatedState = { ...prevState, incubator };
    //                 return updatedState;
    //               });
    //               //setIncubator(tempIncubator);
    //             });
    //           });
    //           // setIncubator(tempIncubator);
    //           break;

    //         case UploadTypeEnum.ReleventDocumentPitchDeck:
    //           file_data[key].map((data: any) => {
    //             createFile(data.file).then((blob: any) => {
    //               blob.lastModifiedDate = new Date();
    //               const src = data.file as string;
    //               blob.name = src.substr(src.lastIndexOf("/") + 1, src.length);
    //               const file: FileUploadModel = new FileUploadModel(
    //                 data.file_id,
    //                 data.file_type_id,
    //                 blob as File
    //               );

    //               incubator.OtherDetails.relavent_document_pitchdeck = [
    //                 ...incubator.OtherDetails.relavent_document_pitchdeck,
    //                 file,
    //               ];
    //               setIncubator((prevState: IIncubatorModel) => {
    //                 const updatedState = { ...prevState, incubator };
    //                 return updatedState;
    //               });
    //               //setIncubator(tempIncubator);
    //             });
    //           });
    //           // setIncubator(tempIncubator);
    //           break;

    //         case UploadTypeEnum.RelaventDocumentOther:
    //           file_data[key].map((data: any) => {
    //             createFile(data.file).then((blob: any) => {
    //               blob.lastModifiedDate = new Date();
    //               const src = data.file as string;
    //               blob.name = src.substr(src.lastIndexOf("/") + 1, src.length);
    //               const file: FileUploadModel = new FileUploadModel(
    //                 data.file_id,
    //                 data.file_type_id,
    //                 blob as File
    //               );

    //               incubator.OtherDetails.relavent_document_other = [
    //                 ...incubator.OtherDetails.relavent_document_other,
    //                 file,
    //               ];
    //               setIncubator((prevState: IIncubatorModel) => {
    //                 const updatedState = { ...prevState, incubator };
    //                 return updatedState;
    //               });
    //               //setIncubator(tempIncubator);
    //             });
    //           });
    //           // setIncubator(tempIncubator);
    //           break;

    //         case UploadTypeEnum.IncorporationCertificate:
    //           file_data[key].map((data: any) => {
    //             createFile(data.file).then((blob: any) => {
    //               blob.lastModifiedDate = new Date();
    //               const src = data.file as string;
    //               blob.name = src.substr(src.lastIndexOf("/") + 1, src.length);
    //               const file: FileUploadModel = new FileUploadModel(
    //                 data.file_id,
    //                 data.file_type_id,
    //                 blob as File
    //               );

    //               incubator.GeneralDetail.incorporation_certificate = [
    //                 ...incubator.GeneralDetail.incorporation_certificate,
    //                 file,
    //               ];
    //               console.log(incubator.GeneralDetail.incorporation_certificate,"certificatefilee")
    //               setIncubator((prevState: IIncubatorModel) => {
    //                 const updatedState = { ...prevState, incubator };
    //                 return updatedState;
    //               });
    //               //setIncubator(tempIncubator);
    //             });
    //           });
    //           // setIncubator(tempIncubator);
    //           break;

    //         case UploadTypeEnum.TeamMemberCV:
    //           file_data[key].map((data: any) => {
    //             createFile(data.file).then((blob: any) => {
    //               blob.lastModifiedDate = new Date();
    //               const src = data.file as string;
    //               blob.name = src.substr(src.lastIndexOf("/") + 1, src.length);
    //               const file: FileUploadModel = new FileUploadModel(
    //                 data.file_id,
    //                 data.file_type_id,
    //                 blob as File
    //               );

    //               incubator.OtherDetails.team_members_cv = [
    //                 ...incubator.OtherDetails.team_members_cv,
    //                 file,
    //               ];
    //               setIncubator((prevState: IIncubatorModel) => {
    //                 const updatedState = { ...prevState, incubator };
    //                 return updatedState;
    //               });
    //               //setIncubator(tempIncubator);
    //             });
    //           });
    //           // setIncubator(tempIncubator);
    //           break;

    //         case UploadTypeEnum.FacilitiesPictures:
    //           file_data[key].map((data: any) => {
    //             createFile(data.file).then((blob: any) => {
    //               blob.lastModifiedDate = new Date();
    //               const src = data.file as string;
    //               blob.name = src.substr(src.lastIndexOf("/") + 1, src.length);
    //               const file: FileUploadModel = new FileUploadModel(
    //                 data.file_id,
    //                 data.file_type_id,
    //                 blob as File
    //               );
    //               incubator.IncubatorDetails.facilities_pictures = [
    //                 ...incubator.IncubatorDetails.facilities_pictures,
    //                 file,
    //               ];
    //               setIncubator((prevState: IIncubatorModel) => {
    //                 const updatedState = { ...prevState, incubator };
    //                 return updatedState;
    //               });
    //               // setIncubator(tempIncubator);
    //             });
    //           });
    //           // setIncubator(tempIncubator);
    //           break;

    //         case UploadTypeEnum.PanCardUpload:
    //           file_data[key].map((data: any) => {
    //             createFile(data.file).then((blob: any) => {
    //               blob.lastModifiedDate = new Date();
    //               const src = data.file as string;
    //               blob.name = src.substr(src.lastIndexOf("/") + 1, src.length);
    //               const file: FileUploadModel = new FileUploadModel(
    //                 data.file_id,
    //                 data.file_type_id,
    //                 blob as File
    //               );

    //               incubator.GeneralDetail.pan_card_upload = [
    //                 ...incubator.GeneralDetail.pan_card_upload,
    //                 file,
    //               ];
    //               setIncubator((prevState: IIncubatorModel) => {
    //                 const updatedState = { ...prevState, incubator };
    //                 return updatedState;
    //               });

    //             });
    //           });
    //           break;

    //         case UploadTypeEnum.AuthorisationLetterUpload:
    //           file_data[key].map((data: any) => {
    //             createFile(data.file).then((blob: any) => {
    //               blob.lastModifiedDate = new Date();
    //               const src = data.file as string;
    //               blob.name = src.substr(src.lastIndexOf("/") + 1, src.length);
    //               const file: FileUploadModel = new FileUploadModel(
    //                 data.file_id,
    //                 data.file_type_id,
    //                 blob as File
    //               );

    //               incubator.GeneralDetail.authorisation_letter_upload = [
    //                 ...incubator.GeneralDetail.authorisation_letter_upload,
    //                 file,
    //               ];
    //               setIncubator((prevState: IIncubatorModel) => {
    //                 const updatedState = { ...prevState, incubator };
    //                 return updatedState;
    //               });

    //             });
    //           });
    //           break;

    //         case UploadTypeEnum.AdhaarCardUpload:
    //           file_data[key].map((data: any) => {
    //             createFile(data.file).then((blob: any) => {
    //               blob.lastModifiedDate = new Date();
    //               const src = data.file as string;
    //               blob.name = src.substr(src.lastIndexOf("/") + 1, src.length);
    //               const file: FileUploadModel = new FileUploadModel(
    //                 data.file_id,
    //                 data.file_type_id,
    //                 blob as File
    //               );

    //               incubator.GeneralDetail.adhaar_card_upload = [
    //                 ...incubator.GeneralDetail.adhaar_card_upload,
    //                 file,
    //               ];
    //               setIncubator((prevState: IIncubatorModel) => {
    //                 const updatedState = { ...prevState, incubator };
    //                 return updatedState;
    //               });

    //             });
    //           });
    //           break;

    //         case UploadTypeEnum.StartupIncubationUpload:
    //           file_data[key].map((data: any) => {
    //             createFile(data.file).then((blob: any) => {
    //               blob.lastModifiedDate = new Date();
    //               const src = data.file as string;
    //               blob.name = src.substr(src.lastIndexOf("/") + 1, src.length);
    //               const file: FileUploadModel = new FileUploadModel(
    //                 data.file_id,
    //                 data.file_type_id,
    //                 blob as File
    //               );

    //               incubator.GeneralDetail.startup_incubation_upload = [
    //                 ...incubator.GeneralDetail.startup_incubation_upload,
    //                 file,
    //               ];
    //               setIncubator((prevState: IIncubatorModel) => {
    //                 const updatedState = { ...prevState, incubator };
    //                 return updatedState;
    //               });

    //             });
    //           });
    //           break;

    //         case UploadTypeEnum.SelfDeclarationUpload:
    //           file_data[key].map((data: any) => {
    //             createFile(data.file).then((blob: any) => {
    //               blob.lastModifiedDate = new Date();
    //               const src = data.file as string;
    //               blob.name = src.substr(src.lastIndexOf("/") + 1, src.length);
    //               const file: FileUploadModel = new FileUploadModel(
    //                 data.file_id,
    //                 data.file_type_id,
    //                 blob as File
    //               );

    //               incubator.OtherDetails.self_declaration_upload = [
    //                 ...incubator.OtherDetails.self_declaration_upload,
    //                 file,
    //               ];
    //               setIncubator((prevState: IIncubatorModel) => {
    //                 const updatedState = { ...prevState, incubator };
    //                 return updatedState;
    //               });

    //             });
    //           });
    //           break;

    //       }
    //     });
    // }
  };

  const onSave = (stepperIndex: number, object: any, request: number) => {
    //console.log('OBJ', object)
    switch (stepperIndex) {
      case StepperEnum.GeneralDetail:
        incubator.GeneralDetail = object;
        setIncubator(incubator);
        break;

      case StepperEnum.IncubatorTeam:
        incubator.IncubatorTeam = object;
        break;

      case StepperEnum.IncubatorDetail:
        incubator.IncubatorDetails = object;
        break;

      case StepperEnum.FundUtilization:
        incubator.FundDetails = object;
        break;

      case StepperEnum.OtherDetail:
        incubator.OtherDetails = object;
        break;
    }

    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);

    // convert all the models into api request model
    const saveRequestModel = new SaveRequestModel(
      // 1st step
      applicationId, // not hard coded
      incubator.GeneralDetail.registeredName,
      incubator.GeneralDetail.industryIds,
      incubator.GeneralDetail.sectorIds,
      incubator.GeneralDetail.stateId,
      incubator.GeneralDetail.cityId,
      incubator.GeneralDetail.address,
      incubator.GeneralDetail.representative_name,
      incubator.GeneralDetail.representative_designation,
      incubator.GeneralDetail.representative_mobile_no,
      incubator.GeneralDetail.representative_email,
      incubator.GeneralDetail.typeLegalEntityId,
      incubator.GeneralDetail.registrationDate,
      incubator.GeneralDetail.corporateId,
      incubator.GeneralDetail.operationalization_date,
      incubator.GeneralDetail.seating_capacity_individulas,
      incubator.GeneralDetail.no_startups_incubation,
      incubator.GeneralDetail.fund_disburse_using_3rd_party,
      incubator.GeneralDetail.central_incubator_far_id,
      incubator.GeneralDetail.nature_assistance_incubator,
      incubator.GeneralDetail.facebook_link,
      incubator.GeneralDetail.twitter_link,
      incubator.GeneralDetail.linkedin_link,
      incubator.GeneralDetail.youtube_link,
      incubator.GeneralDetail.website_url,
      incubator.GeneralDetail.incubator_name,
      incubator.GeneralDetail.facebook_enable,
      incubator.GeneralDetail.linkedin_enable,
      incubator.GeneralDetail.twitter_enable,
      incubator.GeneralDetail.youtube_enable,
      incubator.GeneralDetail.pan_card_number,

      //2nd step
      incubator.IncubatorTeam.ceo_firstname,
      incubator.IncubatorTeam.ceo_lastname,
      incubator.IncubatorTeam.ceo_description,
      incubator.IncubatorTeam.fulltime_employees_no,
      incubator.IncubatorTeam.team_data,

      //3rd step
      incubator.IncubatorDetails.list_facilities,
      incubator.IncubatorDetails.startups_no_incubated,
      incubator.IncubatorDetails.startups_no_graduated,
      incubator.IncubatorDetails.startups_no_investments,
      incubator.IncubatorDetails.startups_no_revenue_year,
      incubator.IncubatorDetails.startups_survival_rate,
      incubator.IncubatorDetails.investments_between_incubator_startups,
      incubator.IncubatorDetails.startups_invested,
      incubator.IncubatorDetails.startups_invested_applicable,
      incubator.IncubatorDetails.startups_invested_total_corpus,
      incubator.IncubatorDetails.startups_invested_total_corpus_applicable,
      incubator.IncubatorDetails.total_investments_raised_incubatees,
      incubator.IncubatorDetails.total_investments_raised_incubatees_applicable,
      incubator.IncubatorDetails.mentors_no_engaged,
      incubator.IncubatorDetails.mentors_no_engaged_applicable,
      incubator.IncubatorDetails.mentors_average_hours,
      incubator.IncubatorDetails.mentors_average_hours_applicable,
      incubator.IncubatorDetails.mentors_no_of_ip,
      incubator.IncubatorDetails.mentors_no_of_ip_applicable,
      incubator.IncubatorDetails.corporate_facilitated,
      incubator.IncubatorDetails.corporate_facilitated_applicable,
      incubator.IncubatorDetails.event_engagements,
      incubator.IncubatorDetails.event_engagements_applicable,
      incubator.IncubatorDetails.participation_other_events,
      incubator.IncubatorDetails.participation_other_events_applicable,
      incubator.IncubatorDetails.no_of_patents,
      incubator.IncubatorDetails.no_of_patents_applicable,
      incubator.IncubatorDetails.no_of_copyrights,
      incubator.IncubatorDetails.no_of_copyrights_applicable,
      incubator.IncubatorDetails.no_of_trademarks,
      incubator.IncubatorDetails.no_of_trademarks_applicable,

      // 4th step
      incubator.FundDetails.quantum_fund_required,
      incubator.FundDetails.no_fund_benifitiaries_1yr,
      incubator.FundDetails.no_fund_benifitiaries_2yr,
      incubator.FundDetails.no_fund_benifitiaries_3yr,

      //5th step
      incubator.OtherDetails.action,
      incubator.OtherDetails.upload_documents_incubation_support,
      incubator.OtherDetails.upload_documents_mentorship_provided,
      incubator.OtherDetails.upload_documents_composition_incubator,
      incubator.OtherDetails.upload_documents_events_organized
    );
    // console.log({'logged_user_id':userInfo.id, "data":{saveRequestModel}},"requestModel");
    let Payload = {
      logged_user_id: userInfo.id,
      saveRequestModel,
    };
    // console.log(Payload);
    // return;
    let check: boolean = false;
    if (
      request === requestType.submit ||
      (request === requestType.propertyChange && isSubmitted)
    ) {
      isSubmitted = true;
      formError = validateIncubator(incubator);
      check = request === requestType.submit && formError.error;
    }

    if (request !== requestType.submit) {
      saveRequestModel.action = "";
    }

    const hasFormError =
      formError &&
      (formError.errors?.FundDetails.length ||
        formError.errors?.GeneralDetail.length ||
        formError.errors?.IncubatorDetails.length ||
        formError.errors?.IncubatorTeam.length ||
        formError.errors?.OtherDetails.length);

    if (formError && check && hasFormError > 0) {
      setValidator(formError.errors);
      return;
    } else if (
      isSubmitted &&
      request === requestType.propertyChange &&
      hasFormError > 0
    ) {
      setValidator(formError.errors);
    } else {
      check = false;
    }

    if (
      (request === requestType.submit && !check) ||
      request === requestType.save
    ) {
      if (
        request === requestType.submit &&
        saveRequestModel.fund_disburse_using_3rd_party === "1"
      ) {
        setStopSubmit(true);
        return;
      }
      for (let i = 0; i < saveRequestModel.team_data.length; i++) {
        if (
          saveRequestModel.team_data[i].team_name == "" ||
          saveRequestModel.team_data[i].team_name == null
        ) {
          return;
        }
      }
      setStopSubmit(false);
      setLoader(true);
      setDisabled(true);
      // return;
      post(STORE_INCUBATOR_APPLICATION, Payload).then((response: any) => {
        if (!response || !response.data) {
          return;
        }
        setLoader(false);
        setDisabled(false);

        formError = null;
        isSubmitted = false;

        if (response.data.application_id != 0) {
          setApplicationId(response.data.application_id);
        }

        if (response.status === 200) {
          // show success message
          if (saveRequestModel.action && saveRequestModel.action == "submit") {
            setShowIncubatorSuccessModal(true);
          }
        } else {
          // show error message
          if (saveRequestModel.action && saveRequestModel.action == "") {
            setShowIncubatorErrorModal(true);
          }
        }
      });
    }
  };

  // validate Incubator
  const validateIncubator = (data: any) => {
    setValidator({
      GeneralDetail: [],
      IncubatorTeam: [],
      IncubatorDetails: [],
      FundDetails: [],
      OtherDetails: [],
    });

    let errors: any = {
      GeneralDetail: [],
      IncubatorTeam: [],
      IncubatorDetails: [],
      FundDetails: [],
      OtherDetails: [],
    };
    const parentKeys = Object.keys(data);
    let nonMandatoryFields = [
      "website_url",
      "facebook_enable",
      "linkedin_enable",
      "twitter_enable",
      "youtube_enable",
      "facebook_link",
      "twitter_link",
      "linkedin_link",
      "youtube_link",
      "no_of_patents",
      "no_of_patents_applicable",
      "no_of_copyrights",
      "no_of_copyrights_applicable",
      "no_of_trademarks",
      "no_of_trademarks_applicable",
      "relavent_document_other",
      "fund_disburse_using_3rd_party",
      "action",
      "mentors_average_hours",
      "incubator_name",
      "startups_invested_total_corpus_applicable",
      "total_investments_raised_incubatees_applicable",
      "mentors_average_hours_applicable",
      "startups_invested_applicable",
      "mentors_no_engaged_applicable",
      "corporate_facilitated_applicable",
      "event_engagements_applicable",
      "participation_other_events_applicable",
      "fulltime_employees_no",
      "upload_documents_incubation_support",
      "upload_documents_mentorship_provided",
      "upload_documents_composition_incubator",
      "upload_documents_events_organized",
      // "relavent_document_pitchdeck",
      "startups_invested",
      "mentors_no_engaged",
      "mentors_no_of_ip",
      "no_fund_benifitiaries_1yr",
      "no_fund_benifitiaries_2yr",
      "no_fund_benifitiaries_3yr",
      "startups_no_incubated",
      "startups_no_graduated",
      "startups_no_investments",
      "startups_no_revenue_year",
      "startups_survival_rate",
      "investments_between_incubator_startups",
      "corporate_facilitated",
      "event_engagements",
      "participation_other_events",
      "startups_invested_total_corpus",
      "total_investments_raised_incubatees",
    ];
    let error = false;

    parentKeys.map((key, ind) => {
      Object.keys(data[key]).map((item) => {
        if (item === "central_incubator_far_id" && data[key][item].length) {
          if (
            data[key][item].indexOf(326) > -1 ||
            data[key][item].indexOf("326") > -1
          ) {
            nonMandatoryFields.push("proof_assistance");
            nonMandatoryFields.push("nature_assistance_incubator");
          } else if (
            data[key][item].indexOf(324) > -1 ||
            data[key][item].indexOf("324") > -1 ||
            data[key][item].indexOf(325) > -1 ||
            data[key][item].indexOf("325") > -1
          ) {
            if (nonMandatoryFields.indexOf("proof_assistance") > -1) {
              nonMandatoryFields.splice(
                nonMandatoryFields.indexOf("proof_assistance"),
                1
              );
            }
            if (
              nonMandatoryFields.indexOf("nature_assistance_incubator") > -1
            ) {
              nonMandatoryFields.splice(
                nonMandatoryFields.indexOf("nature_assistance_incubator"),
                1
              );
            }
          } else {
            nonMandatoryFields.push("proof_assistance");
            nonMandatoryFields.push("nature_assistance_incubator");
          }
        }

        if (nonMandatoryFields.indexOf(item) < 0) {
          if (item === "team_data") {
            let uniqueArray: any = [];
            data[key][item].length &&
              data[key][item].map((team: TeamDetails, ind: number) => {
                if (!team.team_name.length || team.no_employee < 0) {
                  uniqueArray.push(ind);
                  // error = false;
                } else {
                  uniqueArray.splice(uniqueArray.indexOf(ind), 1);
                  // error = true;
                }
              });
            let tempArray: any = [];
            uniqueArray.length &&
              uniqueArray.map(function (item: number, pos: number) {
                if (tempArray.indexOf(item) < 0) {
                  tempArray.push(item);
                }
              });
            setTeamError(uniqueArray);
          }
          // if (item === "quantum_fund_required") {
          //   if (data[key][item] < 1 || data[key][item] > 50000000) {
          //     nonMandatoryFields.push("quantum_fund_required");
          //     errors[key].push(item);
          //   }
          // }
          if (item === "quantum_fund_required") {
            if (data[key][item] != "" && data[key][item] <= 50000000) {
              nonMandatoryFields.push("quantum_fund_required");
              // error = false;
            } else {
              errors[key].push(item);
              // error = true;
            }
          }
          if (Array.isArray(data[key][item]) && !data[key][item].length) {
            errors[key].indexOf(item) < 0 && errors[key].push(item);
            error = true;
          } else if (!data[key][item] || !data[key][item].toString().length) {
            errors[key].indexOf(item) < 0 && errors[key].push(item);
            error = true;
          }
        }
      });
    });

    setValidator(errors);
    const errorObject = { error, errors: error ? errors : null };
    return errorObject;
  };

  // Bind GeneralDetail Step 1 response
  const bindGeneralDetail = (response: any, incubator: IIncubatorModel) => {
    incubator.GeneralDetail.registeredName =
      response.data.data.applicationData.registered_name;
    incubator.GeneralDetail.industryIds =
      response.data.data.applicationData.industry_ids;
    incubator.GeneralDetail.sectorIds =
      response.data.data.applicationData.sector_ids;
    incubator.GeneralDetail.stateId =
      response.data.data.applicationData.address_state_id;
    incubator.GeneralDetail.cityId =
      response.data.data.applicationData.address_city_id;
    incubator.GeneralDetail.address =
      response.data.data.applicationData.address;
    incubator.GeneralDetail.representative_name =
      response.data.data.applicationData.representative_name;
    incubator.GeneralDetail.representative_designation =
      response.data.data.applicationData.representative_designation;
    incubator.GeneralDetail.representative_mobile_no =
      response.data.data.applicationData.representative_mobile_no;
    incubator.GeneralDetail.representative_email =
      response.data.data.applicationData.representative_email;
    incubator.GeneralDetail.typeLegalEntityId =
      response.data.data.applicationData.type_legal_entity_id;
    incubator.GeneralDetail.registrationDate = response.data.data
      .applicationData.registration_date
      ? moment(
          new Date(response.data.data.applicationData.registration_date)
        ).format("YYYY-MM-DD")
      : moment(new Date()).format("YYYY-MM-DD");
    incubator.GeneralDetail.corporateId =
      response.data.data.applicationData.corporate_id_no;
    incubator.GeneralDetail.operationalization_date = response.data.data
      .applicationData.operationalization_date?.length
      ? moment(
          new Date(response.data.data.applicationData.operationalization_date)
        ).format("YYYY-MM-DD")
      : moment(new Date()).format("YYYY-MM-DD");

    incubator.GeneralDetail.seating_capacity_individulas = response.data.data
      .applicationData.seating_capacity_individulas
      ? response.data.data.applicationData.seating_capacity_individulas
      : 25;
    incubator.GeneralDetail.no_startups_incubation = response.data.data
      .applicationData.no_startups_incubation
      ? response.data.data.applicationData.no_startups_incubation
      : 5;
    incubator.GeneralDetail.fund_disburse_using_3rd_party =
      response.data.data.applicationData.fund_disburse_using_3rd_party;
    incubator.GeneralDetail.central_incubator_far_id = response.data.data
      .applicationData.central_incubator_far_id
      ? response.data.data.applicationData.central_incubator_far_id.length
        ? response.data.data.applicationData.central_incubator_far_id.map(
            (item: string) => parseInt(item)
          )
        : [""]
      : [""];
    incubator.GeneralDetail.nature_assistance_incubator =
      response.data.data.applicationData.nature_assistance_incubator;
    incubator.GeneralDetail.facebook_link =
      response.data.data.applicationData.facebook_link;
    incubator.GeneralDetail.twitter_link =
      response.data.data.applicationData.twitter_link;
    incubator.GeneralDetail.linkedin_link =
      response.data.data.applicationData.linkedin_link;
    incubator.GeneralDetail.youtube_link =
      response.data.data.applicationData.youtube_link;
    incubator.GeneralDetail.website_url =
      response.data.data.applicationData.website_url;
    incubator.GeneralDetail.incubator_name =
      response.data.data.applicationData.incubator_name;

    incubator.GeneralDetail.facebook_enable =
      response.data.data.applicationData.facebook_enable;

    incubator.GeneralDetail.twitter_enable =
      response.data.data.applicationData.twitter_enable;
    incubator.GeneralDetail.youtube_enable =
      response.data.data.applicationData.youtube_enable;
    incubator.GeneralDetail.linkedin_enable =
      response.data.data.applicationData.linkedin_enable;
    incubator.GeneralDetail.pan_card_number =
      response.data.data.applicationData.pan_card_number;

    incubator.GeneralDetail.proof_assistance =
      response.data.data.applicationData.file_data.proof_assistance;

    incubator.GeneralDetail.incorporation_certificate =
      response.data.data.applicationData.file_data.incorporation_certificate;

    incubator.GeneralDetail.pan_card_upload =
      response.data.data.applicationData.file_data.pan_card_upload;

    incubator.GeneralDetail.authorisation_letter_upload =
      response.data.data.applicationData.file_data.authorisation_letter_upload;

    incubator.GeneralDetail.adhaar_card_upload =
      response.data.data.applicationData.file_data.adhaar_card_upload;

    incubator.GeneralDetail.annual_report =
      response.data.data.applicationData.file_data.annual_report;

    incubator.GeneralDetail.startup_incubation_upload =
      response.data.data.applicationData.file_data.startup_incubation_upload;
  };

  // Bind IncubatorTeam Step 2 response
  const bindIncubatorTeam = (response: any, incubator: IIncubatorModel) => {
    incubator.IncubatorTeam.ceo_firstname =
      response.data.data.applicationData.ceo_firstname;
    incubator.IncubatorTeam.ceo_lastname =
      response.data.data.applicationData.ceo_lastname;
    incubator.IncubatorTeam.ceo_description =
      response.data.data.applicationData.ceo_description;
    incubator.IncubatorTeam.fulltime_employees_no =
      response.data.data.applicationData.fulltime_employees_no;
    incubator.IncubatorTeam.team_data = response.data.data.applicationData
      .team_data.length
      ? response.data.data.applicationData.team_data
      : [new TeamDetails(0, "", 0)];
  };

  // Bind IncubatorDetail Step 3 response
  const bindIncubatorDetail = (response: any, incubator: IIncubatorModel) => {
    incubator.IncubatorDetails.list_facilities =
      response.data.data.applicationData.list_facilities;
    incubator.IncubatorDetails.startups_no_incubated =
      response.data.data.applicationData.startups_no_incubated;
    incubator.IncubatorDetails.startups_no_graduated =
      response.data.data.applicationData.startups_no_graduated;
    incubator.IncubatorDetails.startups_no_investments =
      response.data.data.applicationData.startups_no_investments;
    incubator.IncubatorDetails.startups_no_revenue_year =
      response.data.data.applicationData.startups_no_revenue_year;
    incubator.IncubatorDetails.startups_survival_rate =
      response.data.data.applicationData.startups_survival_rate != ""
        ? response.data.data.applicationData.startups_survival_rate
        : 0;
    incubator.IncubatorDetails.investments_between_incubator_startups =
      response.data.data.applicationData.investments_between_incubator_startups;
    incubator.IncubatorDetails.startups_invested =
      response.data.data.applicationData.startups_invested;
    incubator.IncubatorDetails.startups_invested_applicable =
      response.data.data.applicationData.startups_invested_applicable == "0"
        ? false
        : true;
    incubator.IncubatorDetails.startups_invested_total_corpus =
      response.data.data.applicationData.startups_invested_total_corpus;
    incubator.IncubatorDetails.startups_invested_total_corpus_applicable =
      response.data.data.applicationData
        .startups_invested_total_corpus_applicable == "0"
        ? false
        : true;
    incubator.IncubatorDetails.total_investments_raised_incubatees =
      response.data.data.applicationData.total_investments_raised_incubatees;
    incubator.IncubatorDetails.total_investments_raised_incubatees_applicable =
      response.data.data.applicationData
        .total_investments_raised_incubatees_applicable == "0"
        ? false
        : true;
    incubator.IncubatorDetails.mentors_no_engaged =
      response.data.data.applicationData.mentors_no_engaged;
    incubator.IncubatorDetails.mentors_no_engaged_applicable =
      response.data.data.applicationData.mentors_no_engaged_applicable == "0"
        ? false
        : true;
    incubator.IncubatorDetails.mentors_average_hours =
      response.data.data.applicationData.mentors_average_hours;
    incubator.IncubatorDetails.mentors_average_hours_applicable =
      response.data.data.applicationData.mentors_average_hours_applicable == "0"
        ? false
        : true;
    // incubator.IncubatorDetails.mentors_no_of_ip =
    //   response.data.data.applicationData.mentors_no_of_ip;
    // incubator.IncubatorDetails.mentors_no_of_ip_applicable =
    //   response.data.data.applicationData.mentors_no_of_ip_applicable == "0" ?
    //   false : true;
    incubator.IncubatorDetails.corporate_facilitated =
      response.data.data.applicationData.corporate_facilitated;
    incubator.IncubatorDetails.corporate_facilitated_applicable =
      response.data.data.applicationData.corporate_facilitated_applicable == "0"
        ? false
        : true;
    incubator.IncubatorDetails.event_engagements =
      response.data.data.applicationData.event_engagements;
    incubator.IncubatorDetails.event_engagements_applicable =
      response.data.data.applicationData.event_engagements_applicable == "0"
        ? false
        : true;
    incubator.IncubatorDetails.participation_other_events =
      response.data.data.applicationData.participation_other_events;
    incubator.IncubatorDetails.participation_other_events_applicable =
      response.data.data.applicationData
        .participation_other_events_applicable == "0"
        ? false
        : true;
    incubator.IncubatorDetails.no_of_patents =
      response.data.data.applicationData.no_of_patents;
    incubator.IncubatorDetails.no_of_patents_applicable =
      response.data.data.applicationData.no_of_patents_applicable == "0"
        ? false
        : true;
    incubator.IncubatorDetails.no_of_copyrights =
      response.data.data.applicationData.no_of_copyrights;
    incubator.IncubatorDetails.no_of_copyrights_applicable =
      response.data.data.applicationData.no_of_copyrights_applicable == "0"
        ? false
        : true;
    incubator.IncubatorDetails.no_of_trademarks =
      response.data.data.applicationData.no_of_trademarks;
    incubator.IncubatorDetails.no_of_trademarks_applicable =
      response.data.data.applicationData.no_of_trademarks_applicable == "0"
        ? false
        : true;
    incubator.IncubatorDetails.facilities_pictures =
      response.data.data.applicationData.file_data.facilities_pictures;
  };

  // Bind FundDetail Step 4 response
  const bindFundDetail = (response: any, incubator: IIncubatorModel) => {
    incubator.FundDetails.quantum_fund_required =
      response.data.data.applicationData.quantum_fund_required;
    incubator.FundDetails.no_fund_benifitiaries_1yr =
      response.data.data.applicationData.no_fund_benifitiaries_1yr;
    incubator.FundDetails.no_fund_benifitiaries_2yr =
      response.data.data.applicationData.no_fund_benifitiaries_2yr;
    incubator.FundDetails.no_fund_benifitiaries_3yr =
      response.data.data.applicationData.no_fund_benifitiaries_3yr;
  };

  // bind other details
  const bindOtherDetail = (response: any, incubator: IIncubatorModel) => {
    incubator.OtherDetails.upload_documents_composition_incubator =
      response.data.data.applicationData.upload_documents_composition_incubator;

    incubator.OtherDetails.upload_documents_incubation_support =
      response.data.data.applicationData.upload_documents_incubation_support;

    incubator.OtherDetails.upload_documents_mentorship_provided =
      response.data.data.applicationData.upload_documents_mentorship_provided;

    incubator.OtherDetails.upload_documents_events_organized =
      response.data.data.applicationData.upload_documents_events_organized;

    incubator.OtherDetails.relavent_document_excel =
      response.data.data.applicationData.file_data.relavent_document_excel;

    incubator.OtherDetails.team_members_cv =
      response.data.data.applicationData.file_data.team_members_cv;
    incubator.OtherDetails.relavent_document_pitchdeck =
      response.data.data.applicationData.file_data.relavent_document_pitchdeck;
    incubator.OtherDetails.self_declaration_upload =
      response.data.data.applicationData.file_data.self_declaration_upload;
    incubator.OtherDetails.relavent_document_other =
      response.data.data.applicationData.file_data.relavent_document_other;
  };

  return (
    <div className="app-inside app-incubator">
      <div className="container-fluid about-heading app-banner-inside">
        <div className="text-center main-heading">Incubator Application</div>
        <div className="text-center sub-heading">
          All form fields are <b>mandatory</b>, unless mentioned as{" "}
          <b>‘optional’</b>
          <br />
          Application form to be filled in English language
        </div>
      </div>
      <div className="container stepper-container">
        <div className="app-inside-content">
          <div className="row justify-content-center">
            <div className="col-xs-12 col-sm-12">
              <div id="incubatorstepper" className="bs-stepper">
                {/* Incubator For Stepper Steps Header */}
                <StepperHeaderComponent
                  teamError={teamError}
                  errors={validator}
                  onClick={(currentStep: number) => {
                    setCurrentStep(currentStep);
                  }}
                />

                {/* Incubator For Stepper Steps Content */}
                <div className="bs-stepper-content">
                  {/* Check whether to make a common reusable component for Dialog */}
                  {showIncubatorSuccessModal && (
                    // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
                    <>
                      <div className="modal-backdrop fade show"></div>

                      <div
                        className="modal align-middle modal-sisfs d-block"
                        id="FormSubmissionSuccess"
                      >
                        <div className="modal-dialog modal-dialog-centered modal-md">
                          <div className="modal-content">
                            <div className="modal-body text-center">
                              <i className="fa fa-check-circle-o"></i>
                              <h3 className="modal-message">Thank You!</h3>
                              <div className="modal-text">
                                Your application was successfully submitted.
                                We’ll be in touch with you, soon!
                              </div>
                              <div className="modal-action">
                                <button className="btn btn-sisfs btn-yellow-primary">
                                  <Link
                                    to={{
                                      pathname: "/incubator_status",
                                      state: { perform_reload: "true" },
                                    }}
                                  >
                                    Continue
                                  </Link>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {showIncubatorErrorModal && (
                    // <FormSubmissionError data-toggle="modal" data-target="#FormSubmissionError"/>
                    <>
                      <div className="modal-backdrop fade show"></div>
                      <div
                        className="modal align-middle modal-sisfs d-block"
                        id="FormSubmissionError"
                      >
                        <div className="modal-dialog modal-dialog-centered modal-md">
                          <div className="modal-content">
                            <div className="modal-body text-center">
                              <i className="fa fa-times-circle-o"></i>
                              <h3 className="modal-message">
                                Oops! Something is Wrong
                              </h3>
                              <div className="modal-text">
                                <ul>
                                  <li>
                                    {" "}
                                    You have either missed out on some mandatory
                                    fields{" "}
                                  </li>
                                  <li>Entered the data wrongly</li>
                                </ul>
                              </div>
                              <div className="modal-action">
                                <button className="btn btn-sisfs btn-yellow-primary">
                                  <Link to="/incubator">Ok Got It!</Link>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {loader && (
                    <div
                      className="spinner-border custom-spinner"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                  <fieldset>
                    <GeneralDetailsComponent
                      stepper={stepperRef}
                      onStepSave={onSave}
                      generalDetailModel={incubator.GeneralDetail}
                      applicationId={applicationId}
                      errors={validator.GeneralDetail}
                      disableForm={disabled}
                      functions={viewActiveApplication}
                    />
                    {/* {console.log(incubator,"incDetailsss")} */}
                    <SupportDetailsComponent
                      stepper={stepperRef}
                      onStepSave={onSave}
                      incubatorDetailsModel={incubator.IncubatorDetails}
                      applicationId={applicationId}
                      errors={validator.IncubatorDetails}
                      disableForm={disabled}
                      functions={viewActiveApplication}
                    />

                    <TeamDetailsComponent
                      stepper={stepperRef}
                      onStepSave={onSave}
                      incubatorTeamDetailsModel={incubator.IncubatorTeam}
                      errors={validator.IncubatorTeam}
                      teamError={teamError}
                      disableForm={disabled}
                    />

                    <FundDetailsComponent
                      stepper={stepperRef}
                      onStepSave={onSave}
                      fundDetailsModel={incubator.FundDetails}
                      errors={validator.FundDetails}
                      disableForm={disabled}
                    />
                    {/* {
                      console.log('RENDER',
                        incubator.OtherDetails)
                    } */}
                    <OtherDetailsComponent
                      stepper={stepperRef}
                      onStepSave={onSave}
                      incubatorOthersDetailsModel={incubator.OtherDetails}
                      applicationId={applicationId}
                      errors={validator.OtherDetails}
                      disabled={disabled}
                      disableForm={disabled}
                      functions={viewActiveApplication}
                    />
                  </fieldset>

                  {stopSubmit && (
                    <ConfirmationDialog
                      headerText=""
                      bodyText="Only Incubators not disbursing seed fund to incubatees using
                    funding from any third-party private entity are eligible"
                      onDialogClose={() => setStopSubmit(false)}
                      iconClass="fa fa-times-circle-o"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncubatorContainerMain;
