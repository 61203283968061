import React from 'react';
import cardstartups from '../../images/startups.svg';
import cardincubator from '../../images/incubator.svg';
import { Link } from 'react-router-dom';

function HomeEligibilityComponent() {
    return (
        <section className="section-container section-bg-multiple">
            <div className="container">
                <div className="section-eligibility">
                    <div className="section-heading pb-4">
                        <h2>Eligibility Criteria</h2>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-xs-12 col-lg-5">
                            <div className="card mb-4 box-shadow">
                                <div className="card-header">
                                    <img className="card-img-top img-fluid" src={cardstartups} alt="Card Startup" />
                                    <div className="card-heading sec">
                                        For Startups
                                    </div>
                                </div>

                                <div className="card-body ordered-list">
                                    <ol>
                                        <li>A startup, recognized by DPIIT, incorporated not more than 2 years ago at the time of application.</li>
                                        <li>The startup must have a business idea to develop a product or a service with a market fit, viable commercialization, and scope of scaling.</li>
                                        <li>The startup must have a business idea</li>
                                    </ol>

                                    <div className="text-center mt-3 mb-3">
                                        <Link className="btn btn-sisfs btn-transparent-primary" to={{ pathname: "/about", state: { selected_tab: "startup" }}}>
                                          
                                        More Details </Link>

                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="col-xs-12 col-lg-5">
                            <div className="card mb-4 box-shadow">
                                <div className="card-header">
                                    <img className="card-img-top img-fluid" src={cardincubator} alt="Card incubators" />
                                    <div className="card-heading">
                                        For Incubators
                                    </div>
                                </div>

                                <div className="card-body ordered-list">
                                    <ol>
                                        <li>Incubator should be operational for at least two years on the date of application to the scheme.</li>
                                        <li>Incubator must have facilities to seat at least 25 individuals.</li>
                                        <li>Incubator must have at least 5 startups undergoing incubation physically on the date of application</li>
                                    </ol>
                                    
                                    <div className="text-center mt-3 mb-3">
                                    <Link className="btn btn-sisfs btn-transparent-primary" to={{ pathname: "/about", state: { selected_tab: "incubator" }}}>
                                            More Details
                </Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </section>
    )
}

export default HomeEligibilityComponent;