import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { REGISTER_USER } from '../../../constants/api';
import { post } from '../../../effects/api/api.common';
import error from '../../../images/error.svg';
import { passwordValidate, validateEmail } from '../../../utils/CommonMethods';
import { getSessionStorageValue } from '../../../effects/utils/session-storage';
import jwt from 'jsonwebtoken-promisified';
const user: any = getSessionStorageValue("user");
const userInfo = JSON.parse(user)
// console.log(userInfo)

function AddUserComponent() {
    const [showIncubatorFailureModal, setShowIncubatorFailureModal] = useState(false);
    const [showIncubatorSuccessModal, setShowIncubatorSuccessModal] = useState(false);
    const [passwordMatch, setPasswordMatch] = useState(false);
    const [passwordValidateFlag, setPasswordValidate] = useState(false);
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    // console.log(userInfo)
    const [modalMessage, setModalMessage] = useState('')
    const [validationFlag, setValidationFlag] = useState({
        name: false,
        designation: false,
        email: false,
        password: false,
        password_confirmation: false,
        roles: false

    });
    useEffect(() => {
        window.scrollTo(0, 0)

    }, []);
    const [userData, setUserData] = useState({
        name: '',
        designation: '',
        email: '',
        password: '',
        password_confirmation: '',
        roles: '',
        logged_user_id: userInfo.id,
        logged_user_role: userInfo.roles
    });
    const handleChange = (
        evt: React.ChangeEvent<
            HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
        >
    ) => {
        const name = evt.target.name;
        const value = evt.target.value;
        setUserData((values) => ({
            ...values,
            [name]: value
        }));

    }
    const addUser = () => {
        // console.log(userData);
        // return;
        if (userData.name != '' && userData.email != '' && userData.designation != '' && userData.password != '' && userData.password_confirmation != '' && userData.roles != '' && validationFlag.email == false && passwordValidateFlag == false && passwordMatch == false) {
            let enteredPassword = userData.password
            let confirmpassword = userData.password_confirmation
            let JWT_SECRET = 'Laravel Password Grant Client';
            let payload =   {enteredPassword, confirmpassword} ; 
            var token = jwt.sign(payload.enteredPassword, JWT_SECRET);
            var confirmtoken = jwt.sign(payload.confirmpassword, JWT_SECRET);
            console.log('JWT Token:', token);
            const payloaddata = {
                ...userData,
                password: token,
                password_confirmation: confirmtoken,
              };

            
            post(REGISTER_USER, payloaddata).then((response: any) => {
                if (!response || !response.data) return;
                // console.log(response.data.result)
                if (response.data.result == "success") {
                    setModalMessage(response.data.message)
                    setShowIncubatorSuccessModal(true);
                    setUserData({
                        name: '',
                        designation: '',
                        email: '',
                        password: '',
                        password_confirmation: '',
                        roles: '',
                        logged_user_id: '',
                        logged_user_role: ''
                    })
                    setValidationFlag({
                        name: false,
                        designation: false,
                        email: false,
                        password: false,
                        password_confirmation: false,
                        roles: false

                    })
                }
                else {
                    setModalMessage(response.data.message)
                    setShowIncubatorFailureModal(true)
                }
            })
        } else {
            if (userData.name == "") {
                setValidationFlag((values) => ({
                    ...values,
                    name: true,
                }));
            }
            if (userData.designation == "") {
                setValidationFlag((values) => ({
                    ...values,
                    designation: true,
                }));
            }
            if (userData.password == "") {
                setValidationFlag((values) => ({
                    ...values,
                    password: true,
                }));
            }
            if (!passwordValidate(userData.password)) {
                setPasswordValidate(true)
            }
            if (userData.password_confirmation == "") {
                setValidationFlag((values) => ({
                    ...values,
                    password_confirmation: true,
                }));
            }
            if (userData.password != userData.password_confirmation) {
                setPasswordMatch(true);
            }
            if (userData.email == "" || validateEmail(userData.email) == false) {
                setValidationFlag((values) => ({
                    ...values,
                    email: true,
                }));
            }
            if (userData.roles == "") {
                setValidationFlag((values) => ({
                    ...values,
                    roles: true,
                }));
            }
        }
    }

    const dismissModal = () => {
        window.location.reload();
        setShowIncubatorSuccessModal(false);
        setShowIncubatorFailureModal(false);

    }

    const handleValidate = (evt: React.ChangeEvent<
        HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
    ) => {
        const name = evt.target.name;
        const value = evt.target.value;
        if (name == "name") {
            if (userData.name == "") {
                setValidationFlag((values) => ({
                    ...values,
                    name: true,
                }));
            }
            else {
                setValidationFlag((values) => ({
                    ...values,
                    name: false,
                }));
            }
        }
        else if (name == "designation") {
            if (userData.designation == "") {
                setValidationFlag((values) => ({
                    ...values,
                    designation: true,
                }));
            }
            else {
                setValidationFlag((values) => ({
                    ...values,
                    designation: false,
                }));
            }
        }
        else if (name == "password") {
            if (userData.password == "") {
                setValidationFlag((values) => ({
                    ...values,
                    password: true,
                }));
            }
            else {
                setValidationFlag((values) => ({
                    ...values,
                    password: false,
                }));
            }
            if (passwordValidate(value)) {
                setPasswordValidate(false)
            }
            if (!passwordValidate(value)) {
                setPasswordValidate(true)
            }
            if (userData.password_confirmation == value) {
                setPasswordMatch(false);
            }
            if (userData.password_confirmation != value) {
                setPasswordMatch(true);
            }

        }
        else if (name == "password_confirmation") {
            if (userData.password_confirmation == "") {
                setValidationFlag((values) => ({
                    ...values,
                    password_confirmation: true,
                }));
            }
            else {
                setValidationFlag((values) => ({
                    ...values,
                    password_confirmation: false,
                }));
            }
            if (userData.password == value) {
                setPasswordMatch(false);
            }
            if (userData.password != value) {
                setPasswordMatch(true);
            }
        }
        else if (name == 'email') {
            if (userData.email == "" || validateEmail(value) == false) {
                setValidationFlag((values) => ({
                    ...values,
                    email: true,
                }));
            }
            else {
                setValidationFlag((values) => ({
                    ...values,
                    email: false,
                }));
            }
        }
        else if (name == "roles") {
            if (userData.roles == "") {
                setValidationFlag((values) => ({
                    ...values,
                    roles: true,
                }));
            }
            else {
                setValidationFlag((values) => ({
                    ...values,
                    roles: false,
                }));
            }
        }


    }
    return (
        <div className="app-inside app-contact">

            {/* Inside banner section */}
            <div className="container-fluid about-heading faq-container">
                <div className="text-center main-heading faq-heading">
                    Add User
                </div>


            </div>

            {/* <!-- Tab panes --> */}
            <div className="tab-content">

                <>
                    <div className="container inside-container">

                        <div className="app-inside-content app-inside-full">

                            <div className="row justify-content-center">
                                <div className="col-sm-10">

                                    <div className="form-wrapper contact-form">



                                        <div className="form-group row">
                                            <label className="col-sm-12 col-md-4 col-form-label">
                                                Name
                                            </label>
                                            <div className="col-sm-12 col-md-8">
                                                <input type="text" name="name" value={userData.name} onChange={handleChange} onBlur={handleValidate} className="form-control" placeholder="Enter" />
                                                {validationFlag.name == true ? <label className="error-message">Please enter name</label> : null}
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-md-4 col-form-label">
                                                Designation
                                            </label>
                                            <div className="col-sm-12 col-md-8">
                                                <input type="text" name="designation" value={userData.designation} onChange={handleChange} onBlur={handleValidate} className="form-control" placeholder="Enter" />
                                                {validationFlag.designation == true ? <label className="error-message">Please enter designation</label> : null}
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-md-4 col-form-label">
                                                Email ID
                                            </label>
                                            <div className="col-sm-12 col-md-8">
                                                <input type="text" name="email" value={userData.email} onChange={handleChange} onBlur={handleValidate} className="form-control" placeholder="Enter" />
                                                {validationFlag.email == true ? <label className="error-message">Please enter valid Email ID</label> : null}
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-md-4 col-form-label">
                                                Password
                                            </label>
                                            <div className="col-sm-12 col-md-8">
                                                <input type="password" name="password" value={userData.password} onChange={handleChange} onBlur={handleValidate} className="form-control" placeholder="Enter" />
                                                {validationFlag.password == true ? <label className="error-message">Please enter password</label> : null}
                                                {passwordValidateFlag == true ? <label className="error-message">The password should contain an uppercase Letter (A-Z), a lowercase letter and one number. The length should be between 8-25</label> : null}
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-md-4 col-form-label">
                                                Confirm Password
                                            </label>
                                            <div className="col-sm-12 col-md-8">
                                                <input type="password" name="password_confirmation" onChange={handleChange} onBlur={handleValidate} value={userData.password_confirmation} className="form-control" placeholder="Enter" />
                                                {validationFlag.password_confirmation == true ? <label className="error-message">Please enter confirm password</label> : null}
                                                {passwordMatch == true ? <label className="error-message">Password does not match!!</label> : null}

                                            </div>
                                        </div>



                                        <div className="form-group row">
                                            <label className="col-sm-12 col-md-4 col-form-label">
                                                Role
                                            </label>
                                            <div className="col-sm-12 col-md-8">
                                                <div className="select-custom">
                                                    <select className="form-control" value={userData.roles} onChange={handleChange} onBlur={handleValidate} name="roles" aria-label="Select Role">
                                                        <option value="" selected>Select Role</option>
                                                        <option value="eac">EAC</option>
                                                        <option value="dpiit">DPIIT</option>
                                                        <option value="incubator">Incubator</option>
                                                        <option value="startup">Startup</option>
                                                        <option value="secretariat">Secretariat</option>

                                                    </select>
                                                </div>
                                                {validationFlag.roles == true ? <label className="error-message">Please select role</label> : null}
                                            </div>


                                        </div>

                                        <div className="form-group row action-section">
                                            <label className="col-sm-12 col-md-4 col-form-label">

                                            </label>
                                            <div className="col-sm-12 col-md-8">
                                                <div className="btn-group">
                                                    <button className="btn btn-sisfs btn-yellow-primary" onClick={addUser}>Add User</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    {showIncubatorSuccessModal && (
                        // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
                        <>
                            <div className="modal-backdrop fade show"></div>

                            <div
                                className="modal align-middle modal-sisfs d-block"
                                id="FormSubmissionSuccess"
                            >
                                <div className="modal-dialog modal-dialog-centered modal-md">
                                    <div className="modal-content">
                                        <div className="modal-body text-center">
                                            <i className="fa fa-check-circle-o"></i>
                                            <h3 className="modal-message">Thank You!</h3>
                                            <div className="modal-text">
                                                {modalMessage}
                                            </div>
                                            <div className="modal-action">
                                                <button className="btn btn-sisfs btn-yellow-primary" onClick={dismissModal}>
                                                    Ok!!
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {showIncubatorFailureModal && (
                        // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
                        <>
                            <div className="modal-backdrop fade show"></div>
                            <div
                                className="modal align-middle modal-sisfs d-block"
                                id="FormSubmissionError"
                            >
                                <div className="modal-dialog modal-dialog-centered modal-md">
                                    <div className="modal-content">
                                        <div className="modal-body text-center">
                                            <img
                                                src={error}
                                                className="search mb-2"
                                                alt="search"

                                            />
                                            <h3 className="modal-message">

                                            </h3><br />
                                            <h3 className="modal-text">
                                                {modalMessage}
                                            </h3>
                                            <div className="modal-action">
                                                <button className="btn btn-sisfs btn-yellow-primary" onClick={dismissModal}>
                                                    OK!
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}


                </>



            </div>
        </div>
    )
}

export default AddUserComponent;