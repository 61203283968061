/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { VIEW_STARTUP_APPLICATION, BASE_URL, NODE_URL } from "../../../constants/api";
import { get, post } from '../../../effects/api/api.common';
import { getSessionStorageValue } from '../../../effects/utils/session-storage';

var UserCommet: string = '';

export default function InstrumentComponent(props: any) {
  const [startUp, setStartUp] = useState<any>({});
  const[instrumentApprovedTemp,setInstrumentApprovedTemp]=useState<any>()
  const[instrumentApproved,setInstrumentApproved]=useState<any>()
  const [flag,setFlag]=useState(false)
  const[commentTemp,setCommentTemp]=useState<any>()
  const[comment,setComment]=useState<any>()
  const[cancel,setCancel]=useState(false)
  const [showEditSuccessModal, setShowSuccessModal] = useState(false);
  const user: any = getSessionStorageValue("user");
  const userInfo = JSON.parse(user)
  const [instmntDtls,setInstrmntDtls] = useState<any>({
    instrumentType:'',
    comment:''
  })

  useEffect(() => {
    setInstrumentApprovedTemp(props.instrumentApproved)
    setCommentTemp(props.comment)
  }, []);

  if (startUp.Application_Details !== undefined) {
    if (startUp.Application_Details.FundingDetails.instrument_comment === '') {
      UserCommet = startUp.Application_Details.FundingDetails.instrument_applying_for;
    }
  }

  const handelClose=()=>{
    setShowSuccessModal(false)
    
  }
  

  const changeInstrumentApproved=(e:any)=>
  {
    setFlag(false)
    setInstrumentApprovedTemp(e)
  }
  const handelCallApi=()=>{
    // setShowSuccessModal(false)
    setCancel(true)
    callAPI()
    props.handelShowReport(false)
  }
  const quickEditCall = () => {
   
    if(instrumentApprovedTemp==undefined||instrumentApprovedTemp=="NA")
    {
      setFlag(true)
      return;
    }
    setFlag(false)
    // setShowSuccessModal(true)
    handelCallApi()
    //console.log(cancel,"can")
    if(cancel){
      if(props.id != 'undefined'){
        const payload = {
          instrument_approved:instrumentApprovedTemp,
          instrument_comment:commentTemp,
          logged_user_id:userInfo.id
        
         };
    // console.log("handle api")
  
         //console.log(`${BASE_URL}startup/${props.id}/quickEdit`,"url")
         post(`${NODE_URL}/api/startup/${props.id}/quickEdit_Post`,
          payload
        ).then((response: any) => {
          if (!response || !response.data) return;
          //console.log('this is data1', response.data);
        });
        props.handelChange(instrumentApprovedTemp)
        props.handelComment(commentTemp)
       }
      }
  };

  function callAPI()
  {
  // console.log("call API")
  if(props.id != undefined){
    const payload = {
      instrument_approved:instrumentApprovedTemp,
        instrument_comment:commentTemp,
        logged_user_id:userInfo.id
    
    };
    post(`${NODE_URL}/api/startup/${props.id}/quickEdit_Post`,
      payload
    ).then((response: any) => {
      if (!response || !response.data) return;
      //console.log('this is data', response.data);
      if(response.data.result=='success'){
        window.location.reload();
      }else{
        //console.log(response.data.result,"fail")
      }
    });
    
    props.handelChange(instrumentApprovedTemp)
      props.handelComment(commentTemp)
  }
    
  }

  return (
    <div>
      <div className='d-flex align-items-center justify-content-around mb-5'>
        <div>
          <label htmlFor="browser" className="form-label">Select instrument type to be approved:</label>
          <div>
            {startUp?.Application_Details?.FundingDetails?.instrument_comment === "Selected." ?
              <input className="form-control" type="text" defaultValue={UserCommet} disabled /> :
              <div className="col-sm-12 col-md-8">
              <div className="select-custom" >
                  <select className="form-control" 
                  onChange={(e)=>changeInstrumentApproved(e.target.value)} 
                  value={instrumentApprovedTemp} 
                  name="entity_type"  
                  aria-label="Select Entity Type">
                      <option value="NA">Select</option>
                      {/* selected */}
                      <option value="funding_grant">Grant</option>
                      <option value="funding_debt">Debt</option>
                      <option value="funding_debenture">Convertible Debenture </option>
                      <option value="funding_debt_linked">Other Debt-Linked Instruments</option>
                  </select>
              </div>
              {flag== true ? <label className="error-message">Please select Instrument Type</label> : null}
          </div>
              
            }
              

          </div>
        </div>
        <div className="vl vl-mr"></div>
        <div>
          <h6>Comment :-</h6>
          {/* <textarea ></textarea> */}
          <textarea 
          style={{height:"100px"}}
          value={commentTemp}
          name="comment"
          className="form-control" 
          placeholder={startUp?.Application_Details?.FundingDetails?.instrument_comment === "Selected." ? "Selected" : ''}
          onChange={(e)=>setCommentTemp(e.target.value)}
          />
        </div>
      </div>

      <div className=" d-flex justify-content-center">
        <button className="tertiary btn-yellow-primary"
          onClick={() => {
            
            quickEditCall()
          }}>
          Submit
        </button>
      </div>
      {showEditSuccessModal && (
        <>
          <div className="modal-backdrop fade show"></div>

          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionSuccess"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <i className="fa fa-check-circle-o"></i>
                  <div className="modal-text">
                   You can approve instrument type only once,not allowed to modify further.<br/>
                   Do you really want to approve !!
                  </div>
                  <div className="modal-action">
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={handelClose}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-sisfs btn-blue-primary"
                      onClick={handelCallApi}
                    >
                      Ok 
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
    
  )
}
