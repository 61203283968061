import React, { useEffect, useState } from "react";
import { FundExpenseDetails, FundingDetailsModel, PriorDetails } from "../../../models/startup/FundingDetailsModel";
import { initFundingDetails } from "../../../models/startup/StartupModel";
import { requestType, StepperStartupEnum } from "../../../utils/Enums";
import Calendar from "../../common/controls/Calendar";
import { useErrorStateValue } from "../../../providers/ErrorProvider";
import ValidationMessage from "../../common/controls/ValidationMessage";
import DeleteRow from "../../../images/delete-row.svg";
import { get } from "../../../effects/api/api.common";
import { EXPENSE_BUCKET_OPTION } from "../../../constants/api";
import { useHistory } from "react-router-dom";
import { updateLocale } from "moment";

interface IStartupFundingDetailsProps {
    stepper: any;
    fundingDetailsModel: FundingDetailsModel;
    tabIndex: number;
    onStepSave: (fundingDetails: FundingDetailsModel, stepIndex: number, request: number) => void;
    onChildUpdate: (fundingDetails: FundingDetailsModel) => void;
    onPreviousClick: () => void;
    errors: Array<string>;
    eligibilityCriteriaEnable: Array<string>;
    apiCalled: Boolean;
    isDisabled: Boolean;
    disabledForm: any
}

interface IStartupFundingDetailsState { }

/** Render start-up application screen */
function StartupFundingDetailsComponent(props: IStartupFundingDetailsProps) {

    const { stepper, onChildUpdate, fundingDetailsModel, eligibilityCriteriaEnable, apiCalled, isDisabled, disabledForm } = props;

    // @ts-ignore
    const [{ errors }, dispatchError] = useErrorStateValue();
    const [fieldsetDisabled, setFieldsetDisabled] = useState<any>(false);

    const [fundingDetails, setFundingDetails] = useState<FundingDetailsModel>(
        initFundingDetails
    );

    const [fundExpenses, setFundExpenses] = useState<Array<any>>([]);

    // const [fundExpensesModal, setFundExpensesModal] = useState<FundExpenseDetails>(
    //     new FundExpenseDetails(0, "", 1, new Date(), new Date()),
    // );

    const [selectedDateControl, setSelectedDateControl] = useState(new Date);
    const [currentFundingApplied, setCurrentFundingApplied] = useState("");
    const [expenseBucketList, setExpenseBucketList] = useState([]);
    useEffect(() => {
        getExpenseBucketOptions()
    }, [])
    useEffect(() => {
        setCurrentFundingApplied(fundingDetailsModel.instrument_applying_for);
        setFundingDetails(fundingDetailsModel);
        setFundExpenses(fundingDetailsModel.fund_expense_data)

        setFieldsetDisabled(isDisabled)

        // const fundExpenseDetails = Object.assign({}, fundingDetails);

        // fundingDetails.fund_expense_data = fundExpenses;
        // let updatedFundingDetails: FundingDetailsModel = initFundingDetails;

        // updatedFundingDetails = {
        //     ...fundExpenseDetails,
        //     fund_expense_data: fundExpenses,
        // };

        // setFundExpenses(fundExpenses);


    }, [ /*fundingDetails.instrument_applying_for,*/ fundingDetailsModel.fund_expense_data, fundingDetailsModel.prior_fund_data, isDisabled]);

    // triggers whenever user makes any changes in the form for select and input type
    const handleChange = (
        evt: React.ChangeEvent<
            HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
        >
    ) => {
        const name = evt.target.name;
        const value = evt.target.value.toString().split(".").map((el, i) => i ? el.split("").slice(0, 2).join("") : el).join(".");;

        let updatedFundingDetails: FundingDetailsModel = initFundingDetails;
        if (evt.target.name == 'quantum_funds_required') {
            updatedFundingDetails = {
                ...fundingDetails,
                [name]: value.replace(/[^0-9]/ig, ''),
            };
            fundingDetails.quantum_funds_required = +value
        } else {
            updatedFundingDetails = {
                ...fundingDetails,
                [name]: value,
            };
        }

        setLocalStateAndUpdateParent(updatedFundingDetails);
    };


    const history = useHistory();

    const applicationPage = () => {
        history.push({
            pathname: "/startup_status",
        });
    }

    // triggers whenever user makes any changes in the form for select and input type
    const handleInputChangeFund = (event: React.ChangeEvent<
        HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >, nameVal: any, index: any) => {
        // triggers when team is updated

        const value = event.currentTarget.value;
        // let updatedStartupTeam: StartupTeamModel = initStartupTeam;
        if (nameVal == "fund_date") {
            fundingDetails.prior_fund_data[index].fund_date = value;
        }
        else if (nameVal == "amount") {
            fundingDetails.prior_fund_data[index].amount = value.replace(/[^0-9]/ig, '')
            // .toString().split(".").map((el,i)=>i?el.split("").slice(0,2).join(""):el).join(".");
        }
        else if (nameVal == "financial_instrument") {
            fundingDetails.prior_fund_data[index].financial_instrument = value;
        }
        else if (nameVal == "funding_agency_name") {
            fundingDetails.prior_fund_data[index].funding_agency_name = value.replace(/[^a-zA-Z ]/ig, '');
        }
        else if (nameVal == "funding_agency_type") {
            fundingDetails.prior_fund_data[index].funding_agency_type = value;
        }
        setLocalStateAndUpdateParent(fundingDetails);


        // let updatedFundingDetails: FundingDetailsModel = initFundingDetails;

        // updatedFundingDetails = {
        //     ...fundingDetails,
        //     [name]: value,
        // };

        // setLocalStateAndUpdateParent(updatedFundingDetails);
    };

    const handleRadioChange = (
        evt: React.ChangeEvent<
            HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
        >
    ) => {
        const value = evt.target.value;
        setCurrentFundingApplied(value)
        let updatedFundingDetails: FundingDetailsModel = initFundingDetails;

        updatedFundingDetails = {
            ...fundingDetails,
            instrument_applying_for: value,
        };
        setLocalStateAndUpdateParent(updatedFundingDetails);
    };

    function formatDate(date: Date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    // handle input change for team rows - team name
    const handleInputChange = (
        e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>,
        index: number
    ) => {
        // const fundExpenseDetails = {expense_bucket: "", expense_amount: "", deployment_start_date: new Date(),deployment_end_date: new Date()}

        if (e.target.name == "expense_bucket") {
            // const updated = {
            //     ...fundExpenseDetails,
            //     fund_expense_id: index,
            //     expense_bucket: e.target.value
            // };

            if (e.target.value == "8") {
                fundExpenses[index].show_other = true;
                fundExpenses[index].expense_bucket = e.target.value;
            }
            else {
                fundExpenses[index].expense_bucket = e.target.value;
                fundExpenses[index].show_other = false;
                fundExpenses[index].expense_bucket_other = '';
            }
        }
        else if (e.target.name == "expense_bucket_other") {
            fundExpenses[index].expense_bucket_other = e.target.value.replace(/[^a-zA-Z ]/ig, '');
        }
        else {
            fundExpenses[index].expense_amount = e.target.value == "" ? null : e.target.value.replace(/[^0-9.]/ig, '').toString().split(".").map((el, i) => i ? el.split("").slice(0, 2).join("") : el).join(".");
        }

        setFundExpenses(fundExpenses);

        fundingDetails.fund_expense_data = fundExpenses;
        // let updatedFundingDetails: FundingDetailsModel = initFundingDetails;

        const updatedFundingDetails = {
            ...fundingDetails,
            fund_expense_data: fundExpenses,
        };
        setLocalStateAndUpdateParent(updatedFundingDetails);

    };

    // trrigers on date change
    const handleDateChange = (date: Date, field: string, index: number) => {

        // const fundExpenseDetails = {expense_bucket: "", expense_amount: "", deployment_start_date: new Date(),deployment_end_date: new Date()}

        // const updated = {
        //     ...fundExpenseDetails,
        //     [field]: date,
        // };
        // setFundExpensesModal(updated)
        fundExpenses[index][field] = formatDate(date);
        // const updatedTeams = JSON.stringify(fundExpenses);
        setFundExpenses(fundExpenses);
        fundingDetails.fund_expense_data = fundExpenses;
        // let updatedFundingDetails: FundingDetailsModel = initFundingDetails;

        const updatedFundExpenseDetails = {
            ...fundingDetails,
            fund_expense_data: fundExpenses,
        };

        setLocalStateAndUpdateParent(updatedFundExpenseDetails);
    };
    // trrigers on date change
    const handleDateChangeFund = (date: Date, field: string, index: number) => {

        // const fundExpenseDetails = {expense_bucket: "", expense_amount: "", deployment_start_date: new Date(),deployment_end_date: new Date()}

        // const updated = {
        //     ...fundExpenseDetails,
        //     [field]: date,
        // };
        // setFundExpensesModal(updated)
        if (field == "fund_date") {
            fundingDetails.prior_fund_data[index].fund_date = formatDate(date);
        }

        setLocalStateAndUpdateParent(fundingDetails);
    };

    // handle checkboxes of upload XL
    const handleCheckbox = (e: any) => {
        const name = e.target.name;
        const checked = e.target.checked;

        if (checked) {
            fundingDetails.prior_funds_applicable = "1";
            fundingDetails.prior_fund_data = [new PriorDetails(0, formatDate(new Date()), null, "", "", "")]
        }
        else {
            fundingDetails.prior_funds_applicable = "0"
        }
        setLocalStateAndUpdateParent(fundingDetails);
    };

    // add and removes the teams
    const addRemoveFundExpenses = (rowIndex?: number) => {
        if (rowIndex || rowIndex === 0) {
            const teamRows = fundExpenses.filter((_, index: number) => index !== rowIndex);
            setFundExpenses(teamRows);
            fundingDetails.fund_expense_data = teamRows;
        } else {
            const team = { expense_bucket: "", expense_amount: "", expense_bucket_other: "", deployment_start_date: new Date(), deployment_end_date: new Date() };
            setFundExpenses([...fundExpenses, team]);
            fundingDetails.fund_expense_data = [...fundExpenses, team];
        }
    };

    // set local state and update parent component
    const setLocalStateAndUpdateParent = (
        updatedFundingDetails: FundingDetailsModel
    ) => {
        setFundingDetails(updatedFundingDetails);

        setTimeout(() => {
            console.log(updatedFundingDetails)
            onChildUpdate(updatedFundingDetails);
        }, 100);

    };


    const getExpenseBucketOptions = () => {
        get(EXPENSE_BUCKET_OPTION).then((response: any) => {
            if (!response || !response?.data) {
                return;
            }

            const expenseBucketListUpdated = response?.data?.data?.map((item: any) => ({
                id: item?.id,
                name: item?.name
            }));
            setExpenseBucketList(expenseBucketListUpdated);
        });
    };

    const addRemoveFund = (rowIndex?: number) => {
        // if (rowIndex || rowIndex === 0) {
        //     const teamRows = teams.filter((_, index: number) => index !== rowIndex);
        //     setTeams(teamRows);
        //     startupTeam.team_data = teamRows;
        // } else {
        //     const team = new TeamDetails(teams.length, "", 1);
        //     setTeams([...teams, team]);
        //     startupTeam.team_data = [...teams, team];
        // }

        // let updatedStartupTeam: StartupTeamModel = initStartupTeam;
        if (rowIndex || rowIndex === 0) {
            const teamRows = fundingDetails.prior_fund_data.filter(
                (_, index: number) => index !== rowIndex
            );
            fundingDetails.prior_fund_data = teamRows;
        } else {
            const team = new PriorDetails(fundingDetails.prior_fund_data.length, formatDate(new Date()), null, "", "", "");
            fundingDetails.prior_fund_data = [
                ...fundingDetails.prior_fund_data,
                team,
            ];
        }
        setLocalStateAndUpdateParent(fundingDetails);
    };
    return (
        <>
            <fieldset disabled={disabledForm}>
                <div className="form-content">
                    <div className="form-group required row">
                        <label className="col-sm-4 col-form-label">
                            Have you cumulatively received more than ₹10 lakhs of monetary
                            support under any Central or State government scheme?
                        </label>
                        <div className="col-sm-8">
                            <div className="btn-group btn-group-toggle">
                                <label
                                    className={
                                        fundingDetails.received_monetary_support_under_scheme === "1"
                                            ? "btn active"
                                            : "btn"
                                    }
                                >
                                    <input
                                        type="radio"
                                        name="received_monetary_support_under_scheme"
                                        id="received_monetary_support_under_scheme1"
                                        value="1"
                                        onChange={handleChange}
                                        checked={
                                            fundingDetails.received_monetary_support_under_scheme === "1"
                                        }
                                    />
                                    Yes
                                </label>
                                <label
                                    className={
                                        fundingDetails.received_monetary_support_under_scheme === "0"
                                            ? "btn active"
                                            : "btn"
                                    }
                                >
                                    <input
                                        type="radio"
                                        name="received_monetary_support_under_scheme"
                                        id="received_monetary_support_under_scheme2"
                                        value="0"
                                        onChange={handleChange}
                                        checked={
                                            fundingDetails.received_monetary_support_under_scheme === "0"
                                        }
                                    />
                                    No
                                </label>
                            </div>
                            {
                                <small className={fundingDetails.received_monetary_support_under_scheme === "1" ? "red-text" : ""}>
                                    Startup should not have received more than Rs 10 lakh of monetary support under any other Central or State Government scheme. This does not include prize money from competitions and grand challenges, subsidized working space, founder monthly allowance, access to labs, or access to prototyping facility
                                </small>
                            }
                            {errors &&
                                errors.FundingDetails &&
                                errors.FundingDetails["received_monetary_support_under_scheme"].length > 0 && (
                                    <ValidationMessage
                                        className="error"
                                        message={errors.FundingDetails["received_monetary_support_under_scheme"]}
                                    />
                                )}
                        </div>
                    </div>

                    <fieldset disabled={fieldsetDisabled}>
                        <div className="form-group required row">
                            <div className="col-sm-4 ">
                                <label className="col-form-label">
                                    Current Funding Requirement
                                </label>
                            </div>

                            <div className="col-sm-8">
                                <div className="form-row">
                                    <div className="col-12">
                                        <label className="col-label">Instrument applying for</label>
                                        {/* <div className="select-custom">
                                    <select
                                        className="form-control"
                                        aria-label="Select"
                                        id="key_competitors"
                                        name="key_competitors"
                                        // value={startupDetails?.key_competitors}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select</option>

                                    </select>
                                </div> */}

                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input
                                                type="radio"
                                                className="custom-control-input"
                                                id="funding_grant"
                                                value="funding_grant"
                                                checked={!!(currentFundingApplied === "funding_grant")}
                                                // checked
                                                onChange={handleRadioChange}
                                            // disabled={!eligibilityCriteriaEnable.includes("funding_grant")}
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="funding_grant"
                                            >
                                                Grant
                                            </label>
                                        </div>

                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input
                                                type="radio"
                                                className="custom-control-input"
                                                id="funding_debenture"
                                                value="funding_debenture"
                                                checked={!!(currentFundingApplied === "funding_debenture")}
                                                onChange={handleRadioChange}
                                            // disabled={!eligibilityCriteriaEnable.includes("funding_debenture")}
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="funding_debenture"
                                            >
                                                Convertible Debenture
                                            </label>
                                        </div>

                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input
                                                type="radio"
                                                className="custom-control-input"
                                                id="funding_debt"
                                                value="funding_debt"
                                                checked={!!(currentFundingApplied === "funding_debt")}
                                                onChange={handleRadioChange}
                                            // disabled={!eligibilityCriteriaEnable.includes("funding_debt")}
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="funding_debt"
                                            >
                                                Debt
                                            </label>
                                        </div>

                                    </div>
                                    {errors &&
                                        errors.FundingDetails &&
                                        errors.FundingDetails["instrument_applying_for"].length > 0 && (
                                            <ValidationMessage
                                                className="error"
                                                message={errors.FundingDetails["instrument_applying_for"]}
                                            />
                                        )}

                                    <div className="col-12">
                                        <small>
                                            <b>Grant:</b>  It is for Proof of Concept or prototype development or product trials.
                                        </small>

                                        <small>
                                            <b>Convertible Debentures/Debt/Debt-linked Instruments:</b>  They are for market entry, commercialization or scaling up
                                        </small>
                                    </div>
                                </div>

                                <div className="form-row">

                                    <div className="col-12">
                                        <label className="col-label">Quantum of Funds Required</label>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                {/* {console.log("Instrument Type ",currentFundingApplied)} */}
                                                <input
                                                    type="text"
                                                    name="quantum_funds_required"
                                                    className={`${errors["FundingDetails"] &&
                                                        errors["FundingDetails"]["quantum_funds_required"]
                                                        ? `form-control error`
                                                        : "form-control"
                                                        }`}
                                                    placeholder="Enter"
                                                    value={fundingDetails.quantum_funds_required}
                                                    onChange={handleChange}
                                                    required={true}
                                                    min={0}

                                                />
                                            {
                                                errors &&
                                                errors.FundingDetails &&
                                                errors.FundingDetails["quantum_funds_required"].length > 0 && (
                                                    <ValidationMessage
                                                        className="error"
                                                        message={errors.FundingDetails["quantum_funds_required"]}
                                                    />
                                                )
                                            }


                                            </div>
                                            {currentFundingApplied == "funding_grant" && fundingDetails.quantum_funds_required > 2000000 &&
                                                <span style={{ color: 'red', marginTop: '2%' }}>Funds Cannot be greater than 2000000</span>
                                            }
                                            {currentFundingApplied == "funding_debenture" && fundingDetails.quantum_funds_required > 5000000 &&
                                                <span style={{ color: 'red', marginTop: '2%' }}>Funds Cannot be greater than 5000000</span>
                                            }
                                            {currentFundingApplied == "funding_debt" && fundingDetails.quantum_funds_required > 5000000 &&
                                                <span style={{ color: 'red', marginTop: '2%' }}>Funds Cannot be greater than 5000000</span>
                                            }
                                            <div className="col-sm-4 pl-1">
                                                <small>Enter in (&#8377;)</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </fieldset>

                    {/* only wireframe start */}
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label">
                            Prior Funding Details
                        </label>
                        {/* {console.log("test", fundingDetails.prior_fund_data)} */}
                        <div className="col-sm-8">
                            <div className="custom-control custom-checkbox">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="prior_funds_applicable"
                                    name="prior_funds_applicable"
                                    onChange={handleCheckbox}
                                    checked={fundingDetails.prior_funds_applicable == "1" ? true : false}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor="prior_funds_applicable"
                                >
                                    NA
                                </label>
                            </div>
                            <small className="mb-2">
                                Please give details of all the funding details received by the startup across different rounds of funding.
                            </small>
                            <fieldset disabled={fundingDetails.prior_funds_applicable == "1" ? true : false}>
                                {fundingDetails.prior_fund_data.map((prior: PriorDetails, index: number) => (
                                    <>
                                        <div key={`prior_data_${index}`} className="form-row">
                                            <div className="col">
                                                <span className="col-label">Date</span>
                                            </div>
                                            <div className="col text-left">
                                                <span className="col-label">Amount (in &#8377;)</span>
                                            </div>
                                            <div className="col text-left">
                                                <span className="col-label">Financial Instrument</span>
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="col">

                                                <Calendar
                                                    id="fund_date"
                                                    name="fund_date"
                                                    className="form-control input-height"
                                                    format="dd/MM/yyyy"
                                                    selectedDate={prior.fund_date != "" ? new Date(prior.fund_date) : new Date()}
                                                    onDateChange={(date: Date, field: string) =>
                                                        handleDateChangeFund(date, "fund_date", index)
                                                    }
                                                    maxDate={new Date()}
                                                />

                                            </div>
                                            <div className="col text-center">
                                                <input
                                                    type="text"
                                                    name={`prior_data_${index}`}
                                                    className="form-control"
                                                    value={prior.amount}
                                                    placeholder="Enter Amount"
                                                    onChange={(event: React.ChangeEvent<
                                                        | HTMLSelectElement
                                                        | HTMLInputElement
                                                        | HTMLTextAreaElement
                                                    >) => handleInputChangeFund(event, "amount", index)}

                                                />
                                            </div>
                                            <div className="col text-center">
                                                <select
                                                    className="form-control"
                                                    aria-label="Select"
                                                    id="expense_bucket"
                                                    name="expense_bucket"
                                                    value={prior.financial_instrument}
                                                    onChange={(event: React.ChangeEvent<
                                                        | HTMLSelectElement
                                                        | HTMLInputElement
                                                        | HTMLTextAreaElement
                                                    >) => handleInputChangeFund(event, "financial_instrument", index)}
                                                >
                                                    <option value="" hidden >Select</option>
                                                    <option value="Grant">Grant</option>
                                                    <option value="Debt">Debt</option>
                                                    <option value="Equity">Equity</option>
                                                </select>
                                            </div>

                                        </div>

                                        <div className="form-row">
                                            <div className="col">
                                                <span className="col-label">Name of Funding Agency</span>
                                            </div>
                                            <div className="col text-left">
                                                <span className="col-label">Funding Agency Type</span>
                                            </div>
                                            <div className="col text-left invisible">
                                                <span className="col-label">Funding Agency Type</span>
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="col">

                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Name"
                                                    value={prior.funding_agency_name}
                                                    onChange={(event: React.ChangeEvent<
                                                        | HTMLSelectElement
                                                        | HTMLInputElement
                                                        | HTMLTextAreaElement
                                                    >) => handleInputChangeFund(event, "funding_agency_name", index)}
                                                />

                                            </div>
                                            <div className="col text-center">
                                                <select
                                                    className="form-control"
                                                    aria-label="Select"
                                                    id="funding_agency_type"
                                                    name="funding_agency_type"
                                                    value={prior.funding_agency_type}
                                                    onChange={(event: React.ChangeEvent<
                                                        | HTMLSelectElement
                                                        | HTMLInputElement
                                                        | HTMLTextAreaElement
                                                    >) => handleInputChangeFund(event, "funding_agency_type", index)}
                                                >
                                                    <option value="" hidden >Select</option>
                                                    <option value="Government">Government</option>
                                                    <option value="Corporate">Corporate</option>
                                                    <option value="VC/Angel">VC/Angel</option>
                                                </select>
                                            </div>
                                            {/* <div className="col text-center invisible">
                                        <select
                                            className="form-control"
                                            aria-label="Select"
                                            id="expense_bucket"
                                            name="expense_bucket"

                                        ><option>Government</option></select>
                                    </div> */}
                                            <div className="col">
                                                <div className="number">
                                                    {fundingDetails.prior_fund_data.length > 1 && (
                                                        <img
                                                            src={DeleteRow}
                                                            className="img-fluid margin-top-15"
                                                            alt="Delete Logo"
                                                            onClick={() => addRemoveFund(index)}
                                                        />
                                                    )}
                                                </div>
                                            </div>



                                        </div>


                                    </>
                                ))}
                                <div className="form-row">
                                    <div className="col">
                                        <span
                                            className="link-orange-text"
                                            onClick={() => addRemoveFund()}
                                        >
                                            <u>
                                                <i className="fa fa-plus"></i> Add More
                                            </u>
                                        </span>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    {/* only wireframe end */}


                    <div className="form-group required row">
                        <div className="col-sm-4 ">
                            <label className="col-form-label">
                                Funds Deployment Plan with Broad Expense Categories
                            </label>
                        </div>

                        <div className="col-sm-8">

                            {/* {fundExpenses.map((fundExpense: FundExpenseDetails, index: number) => ( */}
                            {fundExpenses.map((fundExpense: FundExpenseDetails, index: number) => (
                                <div key={`fund_detail_${index}`}>
                                    <div className="form-row">
                                        <div className="col-6">
                                            <label className="col-label">Expense Bucket</label>
                                            <div className="select-custom">
                                                <select
                                                    className={`${errors["FundingDetails"] &&
                                                        errors["FundingDetails"]["fund_expense_data"] &&
                                                        fundExpense.expense_bucket.length === 0
                                                        ? `form-control error`
                                                        : "form-control"
                                                        }`}
                                                    aria-label="Select"
                                                    id="expense_bucket"
                                                    name="expense_bucket"
                                                    value={fundExpense.expense_bucket}
                                                    onChange={(e) => handleInputChange(e, index)}
                                                    required={true}
                                                >
                                                    <option value="">Select</option>
                                                    {expenseBucketList.map(({ id, name }) => (
                                                        <option key={id} value={id}>
                                                            {name}
                                                        </option>
                                                    ))}

                                                </select>
                                            </div>
                                            {/* {errors &&
                                            errors.FundingDetails &&
                                            errors.FundingDetails["fund_expense_data"].length > 0 &&
                                            fundExpense.expense_bucket.length === 0 && (
                                                <ValidationMessage
                                                    className="error"
                                                    message={errors.FundingDetails["fund_expense_data"]}
                                                />
                                            )} */}

                                            {errors["FundingDetails"] &&
                                                errors["FundingDetails"][`fund_expense_data`] &&
                                                fundExpense.expense_bucket.length === 0 && (
                                                    <ValidationMessage
                                                        className="col-12 pl-0 error"
                                                        message={errors["FundingDetails"]["fund_expense_data"]}
                                                    />
                                                )}
                                        </div>
                                        <div className="col-4">
                                            {fundExpenses[index].show_other && <><label className="col-label">Expense Bucket Other</label>
                                                <input
                                                    type="text"
                                                    name="expense_bucket_other"
                                                    // className={`${errors["FundingDetails"] &&
                                                    //     errors["FundingDetails"][`fund_expense_data`] &&
                                                    //     fundExpense.expense_amount === 0
                                                    //     ? `form-control error`
                                                    //     : "form-control"
                                                    //     }`}
                                                    className="form-control"
                                                    placeholder="Enter"
                                                    value={fundExpense.expense_bucket_other}
                                                    onChange={(e) => handleInputChange(e, index)}
                                                />


                                                {errors["FundingDetails"] &&
                                                    errors["FundingDetails"][`fund_expense_data`] &&
                                                    fundExpense.expense_bucket.length === 0 && (
                                                        <ValidationMessage
                                                            className="col-12 pl-0 error"
                                                            message={errors["FundingDetails"]["fund_expense_data"]}
                                                        />
                                                    )}</>}
                                        </div>

                                        <div className="col-2 text-right">
                                            <div className="number">
                                                {fundExpenses.length > 1 && (
                                                    <img
                                                        src={DeleteRow}
                                                        className="img-fluid margin-top-15"
                                                        alt="Delete Logo"
                                                        onClick={() => addRemoveFundExpenses(index)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="col-4">
                                            <label className="col-label">Amount</label>
                                            <input
                                                type="text"
                                                name="expense_amount"
                                                // className={`${errors["FundingDetails"] &&
                                                //     errors["FundingDetails"]["expense_amount"]
                                                //     ? `form-control error`
                                                //     : "form-control"
                                                //     }`}
                                                className={`${errors["FundingDetails"] &&
                                                    errors["FundingDetails"][`fund_expense_data`] &&
                                                    fundExpense.expense_amount === 0
                                                    ? `form-control error`
                                                    : "form-control"
                                                    }`}
                                                placeholder="Enter"
                                                value={fundExpense.expense_amount}
                                                onChange={(e) => handleInputChange(e, index)}
                                                required={true}
                                                maxLength={255}
                                                min={0}
                                            />
                                            {/* {errors &&
                                            errors.FundingDetails &&
                                            errors.FundingDetails["expense_amount"].length > 0 && (
                                                <ValidationMessage
                                                    className="error"
                                                    message={errors.FundingDetails["expense_amount"]}
                                                />
                                            )} */}
                                            {errors["FundingDetails"] &&
                                                errors["FundingDetails"][`fund_expense_data`] &&
                                                fundExpense.expense_amount === 0 && (
                                                    <ValidationMessage
                                                        className="col-12 pl-0 error"
                                                        message={errors["FundingDetails"]["fund_expense_data"]}
                                                    />
                                                )}
                                        </div>

                                        <div className="col-4">
                                            <label className="col-label">Deployment Start Date</label>
                                            <Calendar
                                                id="deployment_start_date"
                                                name="deployment_start_date"
                                                className="form-control input-height"
                                                format="dd/MM/yyyy"
                                                selectedDate={new Date(fundExpense.deployment_start_date)}
                                                onDateChange={(date: Date, field: string) =>
                                                    handleDateChange(date, "deployment_start_date", index)
                                                }
                                            // maxDate={new Date(fundExpense.deployment_end_date)}
                                            />
                                        </div>

                                        <div className="col-4">
                                            <label className="col-label">Deployment End Date</label>
                                            <Calendar
                                                id="deployment_end_date"
                                                name="deployment_end_date"
                                                className="form-control input-height"
                                                format="dd/MM/yyyy"
                                                selectedDate={new Date(fundExpense.deployment_end_date)}
                                                onDateChange={(date: Date, field: string) =>
                                                    handleDateChange(date, "deployment_end_date", index)
                                                }
                                                minDate={new Date(fundExpense.deployment_start_date)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}

                            <div className="form-row">
                                <div className="col">
                                    <span
                                        className="link-orange-text"
                                        onClick={() => addRemoveFundExpenses()}
                                    >
                                        <u>
                                            <i className="fa fa-plus"></i> Add More
                                        </u>
                                    </span>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                <div className="stepper-footer-section">
                    <div
                        className="btn-toolbar justify-content-between"
                        role="toolbar"
                        aria-label="Toolbar with button groups"
                    >
                        <button className="btn btn-sisfs btn-transparent-primary" onClick={() => applicationPage()}>
                            Cancel
                        </button>
                        <span className="stepper-page-count">Step {props.tabIndex} / 7 </span>
                        <div className="btn-group">
                            {props.tabIndex != 1 && (
                                <button
                                    className="btn btn-sisfs btn-transparent-primary"
                                    onClick={props.onPreviousClick}
                                >
                                    Previous
                                </button>
                            )}
                            <button
                                type="button"
                                className="btn btn-sisfs btn-yellow-primary"
                                onClick={() =>
                                    props.onStepSave(fundingDetails, StepperStartupEnum.FundingDetails, requestType.save)
                                }
                            >
                                {props.tabIndex == 7 ? "Save" : "Save And Next"}
                            </button>
                            {props.tabIndex == 7 && (
                                <button
                                    className="btn btn-sisfs btn-yellow-primary"
                                // onClick={() => props.onSubmit}                                                   
                                >
                                    Review & Submit
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </fieldset>
        </>
    )
}

export default StartupFundingDetailsComponent;