import { useEffect, useState } from "react";
import {
    GET_APPLICATION_LIST,
    GET_APPLICATION_VIEW,
    REPLACE_FILE,
    DELETE_APPLICATION,
    EVALUATION_FILE,
    STARTUP_ALL_APPLICATIONS,
    GET_GRIEVANCE_LIST,
    USER_LIST,
    USER_ACTIVE_INACTIVE
} from "../../../constants/api";
import { get, post } from "../../../effects/api/api.common";
// import ContactSectionComponent from "./ContactSectionComponent";
// import GetApplicationInfoComponent from "./GetApplicationInfoComponenet";
import { useHistory, NavLink } from "react-router-dom";
import search from '../../../images/search.svg';
import { getSessionStorageValue } from "../../../effects/utils/session-storage";
import { filterByValue } from "../../../utils/CommonMethods";
import ReactPaginate from 'react-paginate';
import '../../../styles/dashboard.css';
// import { userInfo } from "os";
// import { FormatDate } from "../../../../utils/DateFormat";
// import { downloadDocument } from "../../../../utils/CommonMethods";
/** Render Application Status for Incubator screen */
function ViewUserComponent() {
    const history = useHistory();
    const [activeIndex, setActiveIndex] = useState("grievances");
    const [activeTab, setActiveTab] = useState("grievances");
    const [noDataFilter, setNoDataFilter] = useState('');
    const [showToEac, setShowToEac] = useState(false);
    const [userList, setUserList] = useState([]);
    const [filteredUserList, setFilteredUserList] = useState<any>([]);
    const [loggedInRole, setLoggedInRole] = useState('');
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);

        if (getSessionStorageValue("user")) {
            const user: any = getSessionStorageValue("user");
            const userInfo = JSON.parse(user)
            setLoggedInRole(userInfo.roles);
            if (userInfo.roles == 'dpiit') {
                setShowToEac(false);
            }
            else if (userInfo.roles == 'secretariat') {
                setShowToEac(false);
            }
            else if (userInfo.roles == 'eac') {
                setShowToEac(true)
            }
        }
        else {
            setShowToEac(false);
        }
        getUserList();
    }, []);
    const changeActive = (tabs: any) => {
        setActiveIndex(tabs)
    }

    const getUserList = () => {
        setLoader(true);
        post(USER_LIST, { "roles": userInfo.roles }).then((response: any) => {
            setLoader(false);
            if (!response || !response.data) return;
            setUserList(response.data.Data)
        });
    };

    const searchList = (e: any) => {
        setNoDataFilter(e.target.value)
        const filteredData = filterByValue(userList, e.target.value);
        setFilteredUserList(filteredData)
        // console.log(filteredData)    
        setCurrentPage(0);
        setCurrentPage1(0);
        handlePageChange({ selected: 0 })
    }
    const handleAllCheck = (e: any /*index:number*/, id: any) => {
        var name = e.target.name;
        var isChecked = e.target.checked;
        if (isChecked) {
            const payload = {
                "action": "active",
                "user_id": id
            }
            post(USER_ACTIVE_INACTIVE, payload).then((response: any) => {
                if (!response || !response.data) return;
                if (response.data.result == "success") {
                    getUserList()
                    if (filteredUserList.length != 0) {
                        let index = filteredUserList.findIndex((p: any) => p.id == id);
                        filteredUserList[index].status = 1
                        setFilteredUserList(filteredUserList)
                    }
                }
            });
        } else if (isChecked == false) {
            const payload = {
                "action": "inactive",
                "user_id": id
            }
            post(USER_ACTIVE_INACTIVE, payload).then((response: any) => {
                if (!response || !response.data) return;
                if (response.data.result == "success") {
                    getUserList()
                    if (filteredUserList.length != 0) {
                        let index = filteredUserList.findIndex((p: any) => p.id == id);
                        filteredUserList[index].status = 0
                        setFilteredUserList(filteredUserList)

                    }
                }
            });
        }
    };



    const [currentPage, setCurrentPage] = useState(0);
    const [currentPage1, setCurrentPage1] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [itemsPerPage1, setItemsPerPage1] = useState(25);


    const handlePageChange = (selectedItem: { selected: number }) => {
        console.log((currentPage) + 1, "pageee")
        setCurrentPage(selectedItem.selected);
        setCurrentPage1(selectedItem.selected);
    };
    const handleItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newItemsPerPage = parseInt(event.target.value, 10);
        setItemsPerPage(newItemsPerPage);
        setItemsPerPage1(newItemsPerPage);
        setCurrentPage(0); // Reset current page to first page when items per page changes
        setCurrentPage1(0); // Reset current page to first page when items per page changes
        handlePageChange({ selected: 0 })
    };
    const offset = currentPage * itemsPerPage;
    const offset1 = currentPage1 * itemsPerPage1;
    const currentPageData = userList.slice(offset, offset + itemsPerPage);
    //   const currentPageData1 = filteredUserList.slice(offset1, offset1 + itemsPerPage1);
    var currentPageData1 = noDataFilter
        ? filteredUserList.slice(offset1, offset1 + itemsPerPage1)
        : userList.slice(offset1, offset1 + itemsPerPage1);
    const totalPages = Math.ceil((userList.length / itemsPerPage));
    //   const totalPages1 = Math.ceil( (filteredUserList.length / itemsPerPage1));
    const totalPages1 = noDataFilter
        ? Math.ceil(filteredUserList.length / itemsPerPage1)
        : Math.ceil(userList.length / itemsPerPage1);


    return (
        <>
            {loader && (
                <div className="spinner-border custom-spinner" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            )}
            <fieldset disabled={loader}>
                <div className="app-inside app-apply-incubator">
                    <div className="status-list-wrapper mt-4">
                        <div className="container">
                            <div className="row py-2">
                                <div className="col-md-4 text-left m-auto">
                                    <p className="dashboard mb-0">Users</p>
                                </div>
                                <div className="col-md-4 text-center m-auto">
                                    {showToEac == false ? loggedInRole == 'dpiit' ? <NavLink exact={true} className="btn-sisfs btn-transparent-primary_grievance mb-4" activeClassName='active' to='/user_add'>Add User</NavLink> : <NavLink exact={true} className="btn-sisfs btn-transparent-primary_grievance mb-4" activeClassName='active' to='/user_add_secretariat'>Add User</NavLink> : <NavLink exact={true} className="btn-sisfs btn-transparent-primary_grievance mb-4" activeClassName='active' to='/user_add_eac'>Add User</NavLink>}
                                </div>
                                <div className="col-md-4 m-auto">
                                    <div className=" text-right">
                                        <div className="d-flex searchDiv align-self-end justify-content-end"> <img
                                            src={search}
                                            className="search"
                                            alt="search"

                                        />
                                            <input
                                                className="searchBar"
                                                type="text"
                                                placeholder="Search.."
                                                name="search"
                                                onKeyUp={searchList}
                                            /></div>

                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="col-4 text-left">

                                </div>
                                <div className=" text-right">

                                </div>
                            </div>
                            <div className=" text-right">



                            </div>

                            <div className="app-inside-content">


                                <div className="tab-pane active" id="tabs-1">
                                    {/* <div className="row incubator-heading-review mt-3">
                                        <div className="col-md-6 text-left">
                                            
                                        </div>
                                        
                                    </div>    */}

                                    <div className="table-scroll mb-4">
                                        <table className="reviewTable userListTable" >
                                            <thead>
                                                <tr>
                                                    <th className="text-left table-heading custom-td-left table-heading ">Name</th>
                                                    <th className="text-left table-heading">Designation</th>
                                                    <th className="text-left table-heading">Email</th>
                                                    <th className="text-left table-heading">Role</th>
                                                    <th className="text-left table-heading">Activate / Deactivate</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {userList.length == 0 || (filteredUserList.length == 0 && noDataFilter != '') ? <tr><td className="text-center" colSpan={5}>No data</td></tr> :
                                                    (filteredUserList.length == 0 ?
                                                        currentPageData.map((item: any) => (
                                                            <tr key={item.id}>
                                                                <td className="text-left custom-td">{item.name}</td>
                                                                <td className="text-left custom-td">{item.designation}</td>
                                                                <td className="text-left custom-td">{item.email}</td>
                                                                <td className="text-left custom-td">{item.roles}</td>
                                                                <td className="text-center custom-td slider-td"><label className="switch">
                                                                    <input type="checkbox" checked={item.status == 1 ? true : false} onChange={(e: any) => handleAllCheck(e, item.id)} />
                                                                    <span className="slider round"></span>
                                                                </label></td>

                                                            </tr>
                                                        )) :
                                                        currentPageData1.map((item: any) => (
                                                            <tr key={item.id}>
                                                                <td className="text-left custom-td">{item.name}</td>
                                                                <td className="text-left custom-td">{item.designation}</td>
                                                                <td className="text-left custom-td">{item.email}</td>
                                                                <td className="text-left custom-td">{item.roles}</td>
                                                                <td className="text-center custom-td slider-td"><label className="switch">
                                                                    <input type="checkbox" checked={item.status == 1 ? true : false} onChange={(e: any) => handleAllCheck(e, item.id)} />
                                                                    <span className="slider round"></span>
                                                                </label></td>
                                                            </tr>
                                                        ))
                                                    )}
                                                {/* {userList.length == 0 ?
                                            <tr><td className="text-center" colSpan={5}>No data</td></tr> :
                                            userList.map((item: any) => (
                                                <tr>
                                                    <td className="text-left custom-td">{item.name}</td>
                                                    <td className="text-left custom-td">{item.designation}</td>
                                                    <td className="text-left custom-td">{item.email}</td>
                                                    <td className="text-left custom-td">{item.roles}</td>

                                                </tr>
                                            ))} */}
                                            </tbody>

                                        </table>


                                    </div>
                                    {userList.length == 0 ? <></>
                                        :

                                        filteredUserList.length == 0 ?
                                            <div style={{ float: 'right', display: 'flex', marginRight: '20px', alignItems: 'center', justifyContent: 'center' }}>

                                                <span>
                                                    <label htmlFor="itemsPerPageSelect" className="itemsPerPage">Items per Page:</label>
                                                    <select
                                                        className="itemsPerPageSelect"
                                                        id="itemsPerPageSelect"
                                                        value={itemsPerPage}
                                                        onChange={handleItemsPerPageChange}
                                                    >
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                        <option value="75">75</option>
                                                        <option value="100">100</option>
                                                    </select>
                                                </span>&nbsp;&nbsp;&nbsp;

                                                <span className="pagination" style={{ fontSize: '18px', marginTop: '10px' }}>
                                                    <ReactPaginate
                                                        key={itemsPerPage}
                                                        pageCount={totalPages}
                                                        pageRangeDisplayed={0}
                                                        marginPagesDisplayed={0}
                                                        previousLabel="Prev"
                                                        nextLabel="Next"
                                                        // breakLabel="..."
                                                        onPageChange={handlePageChange}
                                                        containerClassName="pagination"
                                                        activeClassName="active"
                                                        disableInitialCallback={true}
                                                        forcePage={currentPage}
                                                    />

                                                </span>&nbsp;&nbsp;&nbsp;

                                                <span className="pageNum">
                                                    Page {(currentPage) + 1} of {totalPages}
                                                </span>
                                                {/* &nbsp;&nbsp;&nbsp;<select value={currentPage} onChange={handleSelectedPageChange} style={{border:'0 0 1px solid black 0'}} >
                                    {renderPageNumbers()}
                                    </select>&nbsp;&nbsp;&nbsp; */}
                                            </div>
                                            : <>
                                                <div style={{ float: 'right', display: 'flex', marginRight: '20px', alignItems: 'center', justifyContent: 'center' }}>

                                                    <span>
                                                        <label htmlFor="itemsPerPageSelect" className="itemsPerPage">Items per Page:</label>
                                                        <select
                                                            className="itemsPerPageSelect"
                                                            id="itemsPerPageSelect"
                                                            value={itemsPerPage1}
                                                            onChange={handleItemsPerPageChange}
                                                        >
                                                            <option value="25">25</option>
                                                            <option value="50">50</option>
                                                            <option value="75">75</option>
                                                            <option value="100">100</option>
                                                        </select>
                                                    </span>&nbsp;&nbsp;&nbsp;

                                                    <span className="pagination" style={{ fontSize: '18px', marginTop: '10px' }}>
                                                        <ReactPaginate
                                                            key={itemsPerPage1}
                                                            pageCount={totalPages1}
                                                            pageRangeDisplayed={0}
                                                            marginPagesDisplayed={0}
                                                            previousLabel="Prev"
                                                            nextLabel="Next"
                                                            // breakLabel="..."
                                                            onPageChange={handlePageChange}
                                                            containerClassName="pagination"
                                                            activeClassName="active"
                                                            disableInitialCallback={true}
                                                            forcePage={currentPage1}
                                                        />
                                                    </span>&nbsp;&nbsp;&nbsp;

                                                    <span className="pageNum">
                                                        Page {currentPage1 + 1} of {totalPages1}
                                                    </span>
                                                    {/* &nbsp;&nbsp;&nbsp;<select value={currentPage} onChange={handleSelectedPageChange} style={{border:'0 0 1px solid black 0'}} >
                                        {renderPageNumbers()}
                                        </select>&nbsp;&nbsp;&nbsp; */}
                                                </div>
                                            </>
                                    }
                                </div>

                            </div>

                        </div>
                    </div>
                    <br /><br /><br />
                </div>
            </fieldset>

        </>
    );
}

export default ViewUserComponent;
