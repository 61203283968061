import '../incubator/incubator.css';
import { ApplicationGeneralDetails } from './ApplicationGeneralDetails';
import search from '../../../images/search.svg';
import { useEffect, useState } from 'react';
import { filterByValue, showEllipsisText } from '../../../utils/CommonMethods';
import { FormatDate } from '../../../utils/DateFormat';
interface IContactProps {
    commentData: Array<any>;
    role: string;
}

function CommentReapplyContainer(props: any) {
    const { commentData, role } = props;
    //console.log(generalDetailsModel, 'IContactProps');
    const [commentDataInfo, setCommentDataInfo] = useState<Array<any>>([]);
    const [filteredCommentData, setFilteredCommentData] = useState<Array<any>>([]);
    const [noDataFilter, setNoDataFilter] = useState('');
    const [showViewMore, setShowViewMore] = useState('');
    useEffect(() => {
        setCommentDataInfo(commentData)
    }, []);
    const searchList = (e: any) => {
        setNoDataFilter(e.target.value)
        const filteredCommentInfo = filterByValue(commentData, e.target.value);

        setFilteredCommentData(filteredCommentInfo)
    }
    return (
        <>
            {/* {console.log('commentDtat', commentData)} */}
            <div className="accordion accordion-details-wrap text-left" id="accordion-application-details">
                <div className="card accordion-card">
                    <div className="card-body">

                        <div className="row incubator-heading-review">
                            <div className="col-md-6 text-left">
                                <p className="dashboard pt-2 pl-3">Comment History</p>
                            </div>
                            <div className="col-md-6 text-right">
                                <div className="d-flex searchDiv"> <img
                                    src={search}
                                    className="search"
                                    alt="search"

                                />
                                    <input
                                        className="searchBar"
                                        type="text"
                                        placeholder="Search.."
                                        name="search"
                                        onKeyUp={searchList}
                                    />
                                    {/* <img src={CalendarIcon} className="calendar-icon review-calendar-icon" alt="Calendar icon to select date" /> */}
                                </div>

                            </div>
                        </div>
                        {role == "dpiit" && <div className="table-scroll">
                            <table className="reviewTable contactUsTable" >
                                <thead>
                                    <tr>
                                        <th className="table-heading">Commentator</th>
                                        <th className="table-heading">Date</th>
                                        <th className="table-heading">Comments</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {commentData.length == 0 || (filteredCommentData.length == 0 && noDataFilter != '') ? <tr><td className="text-center" colSpan={3}>No data</td></tr> :
                                        (filteredCommentData.length == 0 ?
                                            commentData.map((item: any, index: any) => (
                                                <tr key={index}>
                                                    <td className={item.roles == 'dpiit' ? "text-left custom-td uppercase" : "text-left custom-td capitize"}>{item.roles}</td>
                                                    <td className="text-left custom-td">{FormatDate(item.created_at)}</td>
                                                    {item.dpiit_status_comment != null ? <td className="text-left custom-td">{showViewMore != "show_" + index && <><div>{showEllipsisText(item.dpiit_status_comment, 50)}</div>{item.dpiit_status_comment.length >= 50 && <span className="link-orange-text d-block" onClick={() => setShowViewMore('show_' + index)}>View More</span>}</>}
                                                        {showViewMore == "show_" + index && <><div>{item.dpiit_status_comment}</div><span className="link-orange-text d-block" onClick={() => setShowViewMore('')}>View Less</span></>}</td> : <td className="text-left custom-td">-</td>}
                                                </tr>
                                            )) : filteredCommentData.map((item: any, index: any) => (
                                                <tr key={index}>
                                                    <td className={item.roles == 'dpiit' ? "text-left custom-td uppercase" : "text-left custom-td capitize"}>{item.roles}</td>
                                                    <td className="text-left custom-td">{FormatDate(item.created_at)}</td>
                                                    {item.dpiit_status_comment != null ? <td className="text-left custom-td">{showViewMore != "show_" + index && <><div>{showEllipsisText(item.dpiit_status_comment, 50)}</div>{item.dpiit_status_comment.length >= 50 && <span className="link-orange-text d-block" onClick={() => setShowViewMore('show_' + index)}>View More</span>}</>}
                                                        {showViewMore == "show_" + index && <><div>{item.dpiit_status_comment}</div><span className="link-orange-text d-block" onClick={() => setShowViewMore('')}>View Less</span></>}</td> : <td className="text-left custom-td">-</td>}
                                                </tr>
                                            ))


                                        )}
                                </tbody>

                            </table>
                        </div>}
                        {role == "incubator" && <div className="table-scroll">
                            <table className="reviewTable contactUsTable" >
                                <thead>
                                    <tr>
                                        <th className="table-heading">Status</th>
                                        <th className="table-heading">Date</th>
                                        <th className="table-heading">Comments</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {commentData.length == 0 || (filteredCommentData.length == 0 && noDataFilter != '') ? <tr><td className="text-center" colSpan={3}>No data</td></tr> :
                                        (filteredCommentData.length == 0 ?
                                            commentData.map((item: any, index: any) => (
                                                <tr key={index}>
                                                    <td className={item.roles == 'dpiit' ? "text-left custom-td uppercase" : "text-left custom-td capitize"}>{item.label}</td>
                                                    <td className="text-left custom-td">{FormatDate(item.created_at)}</td>
                                                    {item.incubator_status_comment != null ? <td className="text-left custom-td">{showViewMore != "show_" + index && <><div>{showEllipsisText(item.incubator_status_comment, 50)}</div>{item.incubator_status_comment.length >= 50 && <span className="link-orange-text d-block" onClick={() => setShowViewMore('show_' + index)}>View More</span>}</>}
                                                        {showViewMore == "show_" + index && <><div>{item.incubator_status_comment}</div><span className="link-orange-text d-block" onClick={() => setShowViewMore('')}>View Less</span></>}</td> : <td className="text-left custom-td">-</td>}
                                                </tr>
                                            )) : filteredCommentData.map((item: any, index: any) => (
                                                <tr key={index}>
                                                    <td className={item.roles == 'dpiit' ? "text-left custom-td uppercase" : "text-left custom-td capitize"}>{item.label}</td>
                                                    <td className="text-left custom-td">{FormatDate(item.created_at)}</td>
                                                    {item.incubator_status_comment != null ? <td className="text-left custom-td">{showViewMore != "show_" + index && <><div>{showEllipsisText(item.incubator_status_comment, 50)}</div>{item.incubator_status_comment.length >= 50 && <span className="link-orange-text d-block" onClick={() => setShowViewMore('show_' + index)}>View More</span>}</>}
                                                        {showViewMore == "show_" + index && <><div>{item.incubator_status_comment}</div><span className="link-orange-text d-block" onClick={() => setShowViewMore('')}>View Less</span></>}</td> : <td className="text-left custom-td">-</td>}
                                                </tr>
                                            ))


                                        )}
                                </tbody>

                            </table>
                        </div>}

                    </div>
                </div>
            </div>
        </>
    );
}
export default CommentReapplyContainer;
