import React from "react";
interface IValidateMessageProps {
  className: string;
  message: string;
}
function ValidationMessage(props: IValidateMessageProps) {
  return <div className={props.className}>{props.message}</div>;
}

export default ValidationMessage;
