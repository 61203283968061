import React from 'react';
import modiji from '../../images/modiji.png';
import videoBanner from '../../images/videoBnr.png'
import launch_video from '../../files/Launch.mp4';


function HomeTestimonialsComponent() {
    return (
        <section className="section-container section-bg" id="home-testimonial">
            <div className="container">
                <div className="section-testimonials testimonials">
                    <div className="section-heading pb-2">
                        <h2>Startup India Seed Fund Scheme Launch</h2>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xs-12 col-sm-12 col-md-8 section-content text-center mb-2">
                            <p>The Startup India Seed Fund Scheme was launched by<br /> Honourable Shri Piyush Goyal on 19th April 2021</p>
                        </div>
                    </div>
                    {/* <div className="row justify-content-center pt-3 pb-2">
                        <div className="col-xs-12 col-lg-6">
                        <figure className="testimonial">
                            <blockquote>The country is launching Startup India Seed Fund worth INR 1000 crore, this will help to start and grow new startups.
                                <div className="arrow"></div>
                            </blockquote>

                            <img src={modi} alt="Narendra Modi PM Of India" />
                            <div className="user">
                                <h5>Shri Narendra Modi</h5>
                                <p className="identify">Honorable Prime Minister of India</p>                              
                            </div>
                        </figure>
                        </div>
                    </div>                 */}

                    {/* <video autoplay="" muted="" loop="" id="headerVideo" class="banner-video">
                        <source src="https://df8k7gbekvfuy.cloudfront.net/home_video/AgniHomePageVideo.mp4" type="video/mp4">
                            <track label="English" kind="subtitles" srclang="en" src="" default="">
                                Your browser does not support HTML5 video.
                    </video> */}
                    {/* <video src={launch_video} autoPlay={true} width="500" height="500" type="video/mp4" /> */}
                    {/* <video>
      <source src={launch_video} type="video/mp4"/>
 </video> */}
                    <video controls width="100%" poster={videoBanner}>
                        <source src={launch_video} type="video/mp4" />Your browser does not support the video tag. I suggest you upgrade your browser.
</video>

                </div>
            </div>
        </section>
    )
}

export default HomeTestimonialsComponent;