import React, { useEffect, useLayoutEffect, useState } from 'react';
import { isTemplateExpression } from 'typescript';
import {
  Documents,
  FileUploadModelTranche,
  TrancheDetail,
  TrancheRequest,
  UpdateTranche,
} from '../../../../models/funddetails/TrancheDetails';
import Plus from '../../../../images/Plus.svg';
import Minus from '../../../../images/Minus.svg';
import pdf from '../../../../images/PDF.svg';
import xls from '../../../../images/xls.svg';
import doc from '../../../../images/doc.svg';
import xlsx from '../../../../images/xls.svg';
import { UploadTypeEnum } from '../../../../utils/Enums';
import Allround from '../../../../images/Allround.svg';
import { File_base_url, File_node_url, NODE_URL, TRANCHE_STATUS_LOGS } from '../../../../constants/api';
import {
  ApplicationStatus,
  FileDownloadModel,
} from '../../../dpiit/incubator/ApplicationGeneralDetails';
import { changeNumberFormat, createFile } from '../../../../utils/CommonMethods';
import { get, post } from '../../../../effects/api/api.common';
import {
  DPIIT_TRANCHE,
  GET_ALL_TRANCHES,
  GET_APPLICATION_STATUS,
  GET_TRANCHE_REQUEST,
  GET_TRANCHE_STATUS,
  STORE_TRANCHE_REQUEST,
  TRANCHE_REMOVE_DOCUMENT,
  TRANCHE_UPLOAD_DOCUMENT,
  UPDATE_TRANCHE,
} from '../../../../constants/api';
import {
  FileUploadModel,
  TrancheFileUploadModel,
} from '../../../../models/FileUploadModel';
import FileUploader from '@amodv/react-file-uploader';
import { getSessionStorageValue } from '../../../../effects/utils/session-storage';
import UserInfoModel from '../../../../models/UserInfoModel';
import Calendar from '../../../common/controls/Calendar';
import { Link, useHistory } from 'react-router-dom';
import ConfirmationDialog from '../../../common/controls/ConfirmationDialog';
import moment from 'moment';
declare var $: any
interface ITrancheProps {
  trancheDetailsModel: Array<TrancheDetail>;
  total_grant_approved: number;
  total_managment: number;
  amount_disbursed_toInc: any;
  amount_disbursed_toStartup: any;
  incubator_user_id: any;
  sec_left_box: any
}
interface IIKeyValueIndexer {
  [key: string]: boolean;
}

function MergedFirstTrancheWiseDetails(props: ITrancheProps) {
  const { trancheDetailsModel, total_grant_approved, total_managment, amount_disbursed_toInc, amount_disbursed_toStartup, incubator_user_id, sec_left_box } = props;
  const [activeIndex1, setActiveIndex1] = useState(0);
  const changeActive1 = (id: number) => {
    setActiveIndex1(id);
  };
  const changeActiveBack1 = () => {
    setActiveIndex1(0);
  };
  const [openUploadBlock, setOpenUploadBlock] = useState<IIKeyValueIndexer>();
  const [reasonPopup2, setReasonPopup2] = useState(false);
  const [reducedData, setreducedData] = useState<any>();
  const [isOpenUploadBlock, setIsOpenUploadBlock] = useState<
    Array<ApplicationStatus>
  >([]);
  const [showValidationFailureModal, setshowValidationFailureModal] = useState(false);
  const [showValidationFailureModal1, setshowValidationFailureModal1] = useState(false);
  const [showfundvalidation, setshowfundvalidation] = useState(false);
  const [showIncubatorFailureModal, setShowIncubatorFailureModal] = useState(false);
  const formatDate = (date: string) => {
    var month = new Array();
    month[0] = 'Jan';
    month[1] = 'Feb';
    month[2] = 'Mar';
    month[3] = 'April';
    month[4] = 'May';
    month[5] = 'Jun';
    month[6] = 'Jul';
    month[7] = 'Aug';
    month[8] = 'Sep';
    month[9] = 'Oct';
    month[10] = 'Nov';
    month[11] = 'Dec';

    var d = new Date(date),
      monthn = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (monthn.length < 2) monthn = '0' + monthn;
    if (day.length < 2) day = '0' + day;

    return [day, month[d.getMonth()], year].join(' ');
  };

  const reducedpopup = (item: any) => {
    setreducedData(item)
    setReasonPopup2(true)
  }

  const [trancheData, setTrancheData] = useState<Array<TrancheDetail>>([
    new TrancheDetail(
      0,
      0,
      '',
      0,
      0,
      '',
      '',
      new Documents([], [], []),
      '',
      0,
      0,
      '',
      '',
      '',
      '',
      '',
      '',
      false, false, false,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      
      
    ),
  ]);
  const [trancheRequest, setTrancheRequest] = useState<TrancheRequest>(
    new TrancheRequest(0, 0, '', 0, '', [], [],"")
  );

  const handleChangeTrancheRequest = (
    evt: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const name = evt.target.name;
    const value = evt.target.value;

    let trancheDetails = JSON.parse(JSON.stringify(trancheRequest));
    trancheDetails[name] = evt.target.value;
    setTrancheRequest(trancheDetails);
  };
  const [updateTranche, setUpdateTranche] = useState<UpdateTranche>(
    new UpdateTranche(0, 0, 0, 0, 0, '', '', 0)
  );
  const [applicationIndex, setApplicationIndex] = useState<any>('')
  const [amount_disbursed_inc, setDisbursedToInc] = useState<any>('')
  const [amount_disbursed_startup, setDisbursedTostartup] = useState<any>('');
  const [getTranche1_amnt, setTranche1Amnt] = useState<any>()
  const [getTranche2_amnt, setTranche2Amnt] = useState<any>()
  const [getTranche3_amnt, setTranche3Amnt] = useState<any>()

  useEffect(() => {
    setDisbursedToInc(props.amount_disbursed_toInc);
    setDisbursedTostartup(props.amount_disbursed_toStartup);
    window.scrollTo(0, 0);
    {
      trancheDetailsModel.map((item: any, index: any) => {
        if (index == 0) {
          setTrancheData(item);
        }
      })
    }
    // setTrancheData(trancheDetailsModel);
    // getTrancheRequest(application_id);
    getUserRoleAccess();
    setApprovedAmountLimit();
    // setManualTrancheDetails();

    if (trancheDetailsModel.length == 0) {
      setTimeout(() => {
        setManualTrancheDetails();
      }, 1200);
      return;
    }

  }, [trancheDetailsModel]);
  useEffect(() => { }, [updateTranche])
  useEffect(() => {
    getTrancheStatus();
    let forTranche1 = ((total_managment) * (0.40)).toFixed(2)
    setTranche1Amnt(forTranche1);
    let forTranche2 = ((total_managment) * (0.30)).toFixed(2)
    setTranche2Amnt(forTranche2);
    let forTranche3 = ((total_managment) * (0.30)).toFixed(2)
    setTranche3Amnt(forTranche3);


  }, [])
  const [applicationStatus, setApplicationStatus] = useState<
    Array<ApplicationStatus>
  >([]);

  const [is_dpiit_user, setIsDpiit] = useState(false);
  const [is_incubator_user, setIsIncubator] = useState(false);
  const [getAllStatusLogs, setAllStatusLogs] = useState([])
  const [tranche1_approved_limit, setApprovedLimit] = useState(0);
  const [showLimitMessage, setLimitMessage] = useState(false);
  const [loggedInRoleName, setLoggedInRoleName] = useState('');
  const [loggedUserId, setLoggedUserId] = useState('')
  const user: any = getSessionStorageValue("user");
  const userInfo = JSON.parse(user)
  const [failDialogData, setFailDialogData] = useState({
    header: '',
    text: '',
  });
  const [disbursement_file, setDisbursementFile] = useState<any>(null)
  const [reasonChk, setReasonChk] = useState(false);
  const [getStatusInputs, setStatusInputs] = useState({
    mngmnt_Fee: '',
    intrst_Accm: '',
    fund_Approved: '',
    fund_Disbursed: '',
    mngmntDisbursed: '',
    reason: '',
    date_disbursed: ''
  })
  useLayoutEffect(() => {

  }, [getStatusInputs])
  const setApprovedAmountLimit = () => {
    if (total_grant_approved > 0) {
      let amount_per = 40 / 100;
      let amount_limit = amount_per * total_grant_approved;
      setApprovedLimit(amount_limit);
    }
  };

  const getUserRoleAccess = () => {
    const user = getSessionStorageValue('user');
    if (user) {
      const userInfo = JSON.parse(user) as UserInfoModel;
      setLoggedInRoleName(userInfo.roles)
      setLoggedUserId(userInfo.id)
      if (userInfo.roles == 'dpiit') {
        setIsDpiit(true);
      }
      if (userInfo.roles == 'secretariat') {
        setIsDpiit(true);
      }
      if (userInfo.roles == 'incubator') {
        setIsIncubator(true);
      }
    }
  };

  const getStatusLogs = (trancheID: any) => {

    get(`${TRANCHE_STATUS_LOGS + '/' + trancheID}/F1`).then((response: any) => {
      setAllStatusLogs(response.data.data)
      // console.log(response);
    })
  }
  const setManualTrancheDetails = () => {
    var trancheDetailsData: Array<TrancheDetail> = trancheDetailsModel;

    //isNextDisbursed
    for (var i = trancheDetailsData.length - 1; i >= 0; i--) {
      var tranche_details = trancheDetailsData[i];

      tranche_details.proofDisbursementFlag = false;

      if (i == trancheDetailsData.length - 1) {
        tranche_details.isNextDisbursed = false;
      }
      if (
        i - 1 >= 0 &&
        (trancheDetailsData[i - 1].tranches_status_label.trimEnd() ==
          'Disbursed' || trancheDetailsData[i - 1].tranches_status_label.trimEnd() == "Disbursement in Process") &&
        (trancheDetailsData[i].tranches_status_label.trimEnd() == 'Disbursed' || trancheDetailsData[i + 1].tranches_status_label.trimEnd() == "Disbursement in Process")
      ) {
        tranche_details.isNextDisbursed = true;
      } else {
        tranche_details.isNextDisbursed = false;
      }

      trancheDetailsData[i] = tranche_details;
    }

    //isPrevDisbursed
    for (var i = (trancheDetailsData.length - 1); i >= 0; i--) {
      var tranche_details = trancheDetailsData[i];

      if (
        (i + 1) <= (trancheDetailsData.length - 1) &&
        (trancheDetailsData[i + 1].tranches_status_label.trimEnd() ==
          'Disbursed' || trancheDetailsData[i + 1].tranches_status_label.trimEnd() == "Disbursement in Process")
        && (trancheDetailsData[i].tranches_status_label.trimEnd() != "Not Requested")
      ) {
        tranche_details.isPrevDisbursed = true;
      } else {
        tranche_details.isPrevDisbursed = false;
      }
      if (i == (trancheDetailsData.length - 1) &&
        (trancheDetailsData[i].tranches_status_label.trimEnd() ==
          'Disbursed' || trancheDetailsData[i].tranches_status_label.trimEnd() ==
          'Requested' || trancheDetailsData[i].tranches_status_label.trimEnd() == "Disbursement in Process"
          || trancheDetailsData[i].tranches_status_label.trimEnd() == "Incomplete"
          || trancheDetailsData[i].tranches_status_label.trimEnd() == "Verification of Documents in Process"
        )) {
        tranche_details.isPrevDisbursed = true;
      }

      trancheDetailsData[i] = tranche_details;
    }

    setTrancheData(trancheDetailsData);
  };
  // trrigers on date change
  const handleDateChange = (date: Date, field: string) => {
    let trancheDetails = JSON.parse(JSON.stringify(updateTranche));
    trancheDetails[field] = date;
    setUpdateTranche(trancheDetails);
  };

  //get application status
  const getTrancheStatus = () => {
    get(GET_TRANCHE_STATUS).then((response: any) => {
      if (!response || !response.data) return;
      if (response.data.data != undefined) {
        // let getSTatus = response.data.data.filter((item:any)=> item.value== 4)
        // console.log(getSTatus)
        const status = response.data.data.map((item: any) => ({
          label: item.label,
          value: item.id,
        }));
        setApplicationStatus(status);
      }
    });
  };

  const downloadDocument = (file: any, file_name: any) => {
    fetch(file).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = file_name;
        a.click();
      });
    });
  };

  const getDisburseentDate = (requested_date: string, key: string) => {
    if (key == 'date' && requested_date != '') {
      let req_date = new Date(requested_date);
      req_date.setDate(new Date(requested_date).getDate() + 30);

      return formatDate(new Date(req_date).toLocaleDateString());
    }

    if (key == 'day') {
      let day = 30;
      if (requested_date != '') {
        let req_date = new Date(requested_date);
        req_date.setDate(new Date(requested_date).getDate() + 30);

        let disbursementDue = formatDate(
          new Date(req_date).toLocaleDateString()
        );

        var date1 = new Date();
        var date2 = req_date;

        var Difference_In_Time = date2.getTime() - date1.getTime();

        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        day = Difference_In_Days;
      }
      return Number(day).toFixed(0);
    }
  };
  const getFileDetails = (file: any, key: string) => {
    let file_name = '';
    file_name =
      file != undefined && file != null && file.length > 1
        ? file.substr(file.lastIndexOf('/') + 1, file.length)
        : '';
    if (key == 'name') return file_name;
    if (key == 'file') return file != undefined ? file.toString() : '';
  };

  const [loader, setLoader] = useState(false);

  const setDocumentFlag = (index: number, flag: boolean) => {
    setLoader(true);
    // trancheData.forEach(element => {
    //   element.documents.proof_of_disbursement = []
    // });

    var trancheDetailsData = trancheData;
    for (var i = 0; i < trancheDetailsData.length; i++) {
      var tranche_details = trancheDetailsData[i];
      if (i == index) {
        trancheDetailsData[i].proofDisbursementFlag = flag;
      }

      trancheDetailsData[i] = tranche_details;
    }
    setTrancheData(trancheDetailsData);
    setTimeout(() => {
      setLoader(false);
    }, 4000);
  };

  const getDocumentFlag = (index: number, flag: boolean) => {
    var key = 'ProofDisbursement' + index;
    return key;
  };
  const [editName, setEditName] = useState('');
  const [nameProp, setNameProp] = useState('');
  const [prevComment, setPrevComment] = useState('');
  const [prevStatus, setPrevStatus] = useState('');
  const [dateRelease, setDateRelease] = useState<any>('')
  const [fundDisbursed, setFundDisbursed] = useState<any>('')
  const [fundApproved, setFundApproved] = useState<any>('')
  const [fundsRequested, setFundsRequested] = useState<any>();
  const [getStatus_label, setStatus_label] = useState<any>();
  const [interestAcc, setInterestAcc] = useState<any>();
  const [manamentFee, setManagementFee] = useState<any>();
  const [getTrancheNumber, setTrancheNumber] = useState<any>();
  const [dis_file_index, setIndex] = useState<any>();
  const [app_details, setAppDetails] = useState<any>();
  const [Disbursed_Amount, setDisbursedAmount] = useState<any>();
  const [Disbursed_mngm, setDisbursedFee] = useState<any>();
  const setModalDetails = (
    field_name: string,
    tranche_id: number,
    application_id: number,
    value: string,
    status?: string,
    comment?: string,
    dateOfReleased?: any,
    fundDisbursed?: any,
    applicationIndex?: any,
    approvedAmount?: any,
    amountRequested?: any,
    trancheNumber?: any,
    allTranchDtls?: any,
    index?: any
  ) => {
    // console.log(allTranchDtls)
    setStatus_label(value);
    setIndex(index);
    setAppDetails(allTranchDtls);
    if (field_name == 'approved_amount') {

      setEditName('Fund Approved');
      setNameProp('approved_amount');

      if (status == 'Tranche 1') {
        if (tranche1_approved_limit == 0) {
          setApprovedAmountLimit();
        }
        setLimitMessage(true);
      } else {
        setLimitMessage(false);
      }
    }
    if (field_name == 'fund_released') {
      setEditName('Fund Disbursed');
      setNameProp('fund_released');
    }
    if (field_name == 'tranch_comment') {
      setEditName('Tranche Comments');
      setNameProp('tranch_comment');
    }
    if (field_name == 'tranche_requested_date') {
      setEditName('Tranche Requested Date');
      setNameProp('tranche_requested_date');
    }
    if (field_name == 'funds_requested') {
      setEditName('Fund Requested');
      setNameProp('funds_requested');
    }
    if (field_name == 'date_of_release') {
      setEditName('Date of Fund Disbursed');
      setNameProp('date_of_release');
    }

    if (field_name == 'cash_in_hand') {
      setEditName('Cash in hand');
      setNameProp('cash_in_hand');
    }

    if (field_name == 'tranches_status_label') {
      setEditName('Tranche Status');
      setNameProp('tranches_status_label');
    }

    setTrancheId(tranche_id);
    setApplicationId(application_id);
    setDateRelease(dateOfReleased);
    setFundDisbursed(fundDisbursed);
    setApplicationIndex(applicationIndex);
    setFundApproved(approvedAmount);
    setFundsRequested(amountRequested);
    setTrancheNumber(trancheNumber);
    let getManagementFee = trancheNumber == 'Tranche 1' ? getTranche1_amnt : getTranche2_amnt;
    setDisbursedFee(getManagementFee)
    let finalFundApproved = approvedAmount - Number(trancheNumber == 'Tranche 1' ? getTranche1_amnt : getTranche2_amnt) - allTranchDtls.interest_accumulated_on_funds;
    setDisbursedAmount(finalFundApproved)
    setStatusInputs({
      mngmnt_Fee: getManagementFee.toString(),
      intrst_Accm: allTranchDtls.interest_accumulated_on_funds,
      fund_Approved: approvedAmount ? approvedAmount : amountRequested.toString(),
      fund_Disbursed: finalFundApproved.toString(),
      mngmntDisbursed: getManagementFee.toString(),
      reason: getStatusInputs.reason,
      date_disbursed: ''
    })
    let trancheDetails = JSON.parse(JSON.stringify(updateTranche));
    if (field_name == "funds_requested" || field_name == "fund_released" || field_name == "approved_amount") {
      trancheDetails[field_name] = Number(value).toFixed();
    }
    else {
      trancheDetails[field_name] = value;
    }
    if (field_name == "tranch_comment") { comment = value; }

    if (field_name == 'date_of_release') {
      trancheDetails['date_of_release'] = new Date()
    }

    trancheDetails['tranche_id'] = tranche_id;
    trancheDetails['application_id'] = application_id;
    //console.log(status, "statussss")
    if (status != null) {
      trancheDetails['tranche_status_id'] = applicationStatus.find(
        (option) => option.label === status
      )?.value;
      trancheDetails['tranch_comment'] = comment;
      setPrevStatus(trancheDetails['tranche_status_id']);
      setPrevComment(comment != undefined ? comment : '');
    }

    setUpdateTranche(trancheDetails);

    let trancheRequestDetails = JSON.parse(JSON.stringify(trancheRequest));
    trancheRequestDetails[field_name] = value;
    trancheRequestDetails['tranche_id'] = tranche_id;
    trancheRequestDetails['application_id'] = application_id;
    setTrancheRequest(trancheRequestDetails);
  };

  const [tranche_id, setTrancheId] = useState(0);
  const [application_id, setApplicationId] = useState(0);
  const [dateEmptyPopup, setdateEmptyPopup] = useState(false)
  const [disbursedAmountPopup, setDisbursedAmountPopup] = useState(false)
  const [checkStatus, setCheckStatus] = useState<any>('0');
  const [exceedFileSize, setExceedFileSize] = useState<any>();
  const [PoD_disbursement_file, setPodDisbursementFile] = useState<any>(null)
  const handleUploadFile = (evt: any) => {
    if (evt.target.files[0].size > 5 * 1024 * 1024) {
      setExceedFileSize(true)
      return;
    } else {
      setExceedFileSize(false);
    }
    const file = evt.target.files[0]
    setDisbursementFile(file)

  }
  const [errormsg, setErrorMsg] = useState(false)
  function maxValueField(name: any, maxValue: any, value: any) {
    const prescribedValue = maxValue;
    if(value > prescribedValue) return;
    if (value < prescribedValue) {
      setReasonChk(true)
    } else {
      setReasonChk(false)
    }
  }
  const handleFileChange = (event: any, name: any, item: any, index: number,) => {
    let file = event.target.files[0]
    // console.log(file)
    setPodDisbursementFile(file)
    onFileUpload(event.target.files, name, item, index)
  };
  const handleStatusChange = (
    evt: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {

    const { name, value } = evt.target;
    // console.log(value)

    if (name == "fund_Disbursed") {
      if (Number(value) > (Number(getStatusInputs.fund_Approved) - Number(getStatusInputs.intrst_Accm))) {
        return;
      } else {
        setStatusInputs({ ...getStatusInputs, [name]: value.replace(/[^0-9.]/gi, '') })
      }
    }
    else if (name == 'mngmntDisbursed') {
      maxValueField(name, Number(getStatusInputs.mngmnt_Fee), Number(value))

      if (Number(getStatusInputs.mngmnt_Fee) < Number(value)) {
        return;
      } else {

        setStatusInputs({ ...getStatusInputs, [name]: value.replace(/[^0-9.]/gi, '') })
      }
    } else if (name == "reason") {
      setStatusInputs({ ...getStatusInputs, [name]: value })
    } else if (name == "date_disbursed") {
      setStatusInputs({ ...getStatusInputs, [name]: value })
    } else {
      setStatusInputs({ ...getStatusInputs, [name]: value.replace(/[^0-9.]/gi, '') })
    }

  }
  const updateTrancheDetails = (updateProp: string, trancheStatusID: any) => {

    if (checkStatus == '1') {
      setDisbursedAmountPopup(true)
      return;
    }
    if (updateProp == 'cash_in_hand') {
      const data_input = {
        application_id: application_id,
        tranche_id: trancheRequest.tranche_id,
        funds_requested: trancheRequest.amount_required,
        cash_in_hand: trancheRequest.cash_in_hand,
      };
      post(STORE_TRANCHE_REQUEST, data_input).then((response: any) => {
        if (!response || !response.data || response.data == '') return;
        if (response.data != undefined && response.data != '') {
          if (response.data.result == 'success') {
            //getTrancheRequest(application_id);
            setShowSuccessModal(true);
            getTrancheRequest(application_id);
          } else {
            setShowFailureModal(true);
          }
        }
      });
    } else {
      let trancheDetails = JSON.parse(JSON.stringify(updateTranche));
      trancheDetails['tranche_id'] = tranche_id;
      trancheDetails['application_id'] = application_id;
      trancheDetails['tranch_comment'] = '';
      setUpdateTranche(trancheDetails);

      // let update_tranche = {
      //   "tranche_id": tranche_id,
      //   "application_id": application_id,
      //   "logged_user_id": userInfo.id,
      //   "logged_user_role": userInfo.roles,
      //   "funds_requested": updateTranche.funds_requested == 0 ? 0 : updateTranche.funds_requested,
      //   "approved_amount": updateTranche.approved_amount == 0 ? 0 : updateTranche.approved_amount,
      //   "tranche_status_id": updateTranche.tranche_status_id == undefined ? 0 : updateTranche.tranche_status_id,
      //   "tranch_comment": updateTranche.tranch_comment == '' || undefined ? 0 : updateTranche.tranch_comment,
      //   "fund_released": updateTranche.fund_released == 0 ? 0 : updateTranche.fund_released,
      //   "date_of_release": updateTranche.date_of_release == '' ? 0 : updateTranche.date_of_release
      // };
      if (trancheStatusID == 3) {
        if ((getTrancheNumber != "Tranche 1" && getStatusInputs.intrst_Accm == '') || getStatusInputs.fund_Approved == '' || (getTrancheNumber != "Tranche 1" && getStatusInputs.intrst_Accm == undefined) || getStatusInputs.fund_Approved == undefined) {
          setErrorMsg(true);
          return;
        } else {
          setErrorMsg(false);
        }
      }
      if (trancheStatusID == 4) {
        if (
          getStatusInputs.fund_Disbursed == '' || getStatusInputs.mngmntDisbursed == '' || getStatusInputs.date_disbursed == '' ||
          getStatusInputs.fund_Disbursed == undefined || getStatusInputs.mngmntDisbursed == undefined || getStatusInputs.date_disbursed == undefined
        ) {
          setErrorMsg(true);
          return;
        } else if (PoD_disbursement_file == null || PoD_disbursement_file == undefined) {
          setErrorMsg(true);
          return;
        } else if (reasonChk && (getStatusInputs.reason == '' || getStatusInputs.reason == undefined)) {
          setErrorMsg(true);
          return;
        } else {
          setErrorMsg(false);
        }
      }



      let update_tranche = {
        "tranche_id": tranche_id,
        "application_id": application_id,
        "logged_user_id": userInfo.id,
        "logged_user_role": userInfo.roles,
        "approved_amount": getStatusInputs.fund_Approved,
        "tranche_status_id": trancheStatusID,
        "interest_accumulated_on_funds": getStatusInputs.intrst_Accm ? getStatusInputs.intrst_Accm : 0,
        "Actual_management_fee": getStatusInputs.mngmnt_Fee,
        "trancheNum": getTrancheNumber,
        "application_ind": "F1"
      }

      let formData = new FormData();
      formData.append("tranche_id", tranche_id.toString());
      formData.append("application_id", application_id.toString());
      formData.append("logged_user_id", userInfo.id);
      formData.append("logged_user_role", userInfo.roles);
      formData.append("tranche_status_id", trancheStatusID);
      formData.append("fund_released", getStatusInputs.fund_Disbursed);
      formData.append("disbursed_date", getStatusInputs.date_disbursed);
      formData.append("trancheNum", getTrancheNumber);
      formData.append("application_ind", "F1")
      if (reasonChk) {
        formData.append("Reduced_management_fee", getStatusInputs.mngmntDisbursed)
      }
      if (getStatusInputs.reason != '') {
        formData.append("Reduced_management_fee_comment", getStatusInputs.reason);
      }
      if (disbursement_file != null) {
        formData.append("file", disbursement_file)
      }
      const payloads = trancheStatusID == 3 ? update_tranche : formData
      post(`${UPDATE_TRANCHE}`, payloads).then((response: any) => {
        if (!response) return;
        if (response.data.result == 500) {
          setFailDialogData({
            "header": "Oops",
            "text": response.data.message,
          })
          $("#editTrancheStatus").modal('hide');
          setshowStatusFailureModal(true);
          return;
        }
        // console.log(response)
        setInterestAcc(response?.data?.result[2][0].interest_accumulated_on_funds);

        if (response.data.result.length > 0) {
          $("#editTrancheStatus1").modal('hide');
          setShowSuccessModal(true);

        } else {
          setShowFailureModal(true);
        }
        getTrancheRequest(application_id);
        // history.push({
        //   pathname: '/dpiit_view_incubator',
        //   state: { application_id: application_id ,isFundDetails:true},
        // });
        // window.location.reload();
      });
    }
  };

  const updateTrancheCommentByStatus = () => {

    let trancheDetails = JSON.parse(JSON.stringify(updateTranche));
    //console.log(trancheDetails, "tranche_status_idddd")
    let addCommentsByStatus = {
      application_id: application_id,
      tranch_comment: trancheDetails.tranch_comment,
      tranche_id: trancheDetails.tranche_id,
      tranche_status_id: trancheDetails.tranche_status_id,
      logged_user_id: userInfo.id,
      application_ind: 'F1'
    };
    trancheDetails['tranche_id'] = tranche_id;
    trancheDetails['application_id'] = application_id;
    //trancheDetails['tranch_comment'] = application_id;
    setUpdateTranche(trancheDetails);

    post(DPIIT_TRANCHE, addCommentsByStatus).then(
      (response: any) => {
        if (!response || !response.data) return;
        if (response.data.result == 'success') {
          setShowSuccessModal(true);
        } else {
          setShowFailureModal(true);
        }
        getTrancheRequest(application_id);
      }
    );
  };

  const getTrancheRequest = (app_id: number) => {
    let tranche_parameters = {
      application_id: app_id,
      order_by: 'DESC',
      application_index: applicationIndex
    };

    post(`${GET_ALL_TRANCHES}`, tranche_parameters).then((response: any) => {
      if (!response || !response.data) return;
      var trancheDetailsData: Array<TrancheDetail> = response.data.data;
      // console.log(response)
      setTrancheData(trancheDetailsData);
      for (var i = 0; i < trancheDetailsData.length; i++) {
        //setTrancheDetails(trancheDetailsData[i]);
        var tranche_details = trancheDetailsData[i];

        const file_data = tranche_details.documents;

        if (file_data) {
          Object.keys(file_data).length &&
            Object.keys(file_data).map((key: string, index) => {
              switch (key) {
                case UploadTypeEnum.ProofDisbursement:
                  file_data[key].map((data: any) => {
                    createFile(data.file).then((blob: any) => {
                      blob.lastModifiedDate = new Date();
                      const src = data.file as string;
                      blob.name = src.substr(
                        src.lastIndexOf('/') + 1,
                        src.length
                      );

                      const index = file_data.proof_of_disbursement.findIndex(
                        (el) => el.file_id === data.file_id
                      );
                      file_data.proof_of_disbursement[index] = {
                        file_id: data.file_id,
                        file_type_id: data.file_type_id,
                        file: blob as File,
                        ext: data.ext,
                        fileName: blob.name,
                      };
                    });
                  });

                  break;

                case UploadTypeEnum.QuarterlyReport:
                  file_data[key].map((data: any) => {
                    createFile(data.file).then((blob: any) => {
                      blob.lastModifiedDate = new Date();
                      const src = data.file as string;
                      blob.name = src.substr(
                        src.lastIndexOf('/') + 1,
                        src.length
                      );

                      const index = file_data.quarterly_reports.findIndex(
                        (el) => el.file_id === data.file_id
                      );
                      file_data.quarterly_reports[index] = {
                        file_id: data.file_id,
                        file_type_id: data.file_type_id,
                        file: blob as File,
                        ext: data.ext,
                        fileName: data.file,
                      };
                    });
                  });

                  break;

                case UploadTypeEnum.SupportingDocuments:
                  file_data[key].map((data: any) => {
                    createFile(data.file).then((blob: any) => {
                      blob.lastModifiedDate = new Date();
                      const src = data.file as string;
                      blob.name = src.substr(
                        src.lastIndexOf('/') + 1,
                        src.length
                      );

                      const index = file_data.supporting_documents.findIndex(
                        (el) => el.file_id === data.file_id
                      );
                      file_data.supporting_documents[index] = {
                        file_id: data.file_id,
                        file_type_id: data.file_type_id,
                        file: blob as File,
                        ext: data.ext,
                        fileName: data.file,
                      };
                    });
                  });

                  break;
              }
            });
        }
        tranche_details.documents = file_data;
        trancheDetailsData[i] = tranche_details;
      }

      for (var i = trancheDetailsData.length - 1; i >= 0; i--) {
        var tranche_details = trancheDetailsData[i];

        tranche_details.proofDisbursementFlag = false;
        //isNextDisbursed
        if (i == trancheDetailsData.length - 1) {
          tranche_details.isNextDisbursed = false;
        }
        if (
          i - 1 >= 0 &&
          (trancheDetailsData[i - 1].tranches_status_label.trimEnd() ==
            'Disbursed' || trancheDetailsData[i - 1].tranches_status_label.trimEnd() == "Disbursement in Process") &&
          (trancheDetailsData[i].tranches_status_label.trimEnd() == 'Disbursed' || trancheDetailsData[i + 1].tranches_status_label.trimEnd() == "Disbursement in Process")
        ) {
          tranche_details.isNextDisbursed = true;
        } else {
          tranche_details.isNextDisbursed = false;
        }
        trancheDetailsData[i] = tranche_details;
      }

      //isPrevDisbursed

      for (var i = (trancheDetailsData.length - 1); i >= 0; i--) {
        var tranche_details = trancheDetailsData[i];

        if (
          (i + 1) <= (trancheDetailsData.length - 1) &&
          (trancheDetailsData[i + 1].tranches_status_label.trimEnd() ==
            'Disbursed' || trancheDetailsData[i + 1].tranches_status_label.trimEnd() == "Disbursement in Process")
          && (trancheDetailsData[i].tranches_status_label.trimEnd() != "Not Requested")
        ) {
          tranche_details.isPrevDisbursed = true;
        } else {
          tranche_details.isPrevDisbursed = false;
        }
        if (i == (trancheDetailsData.length - 1) &&
          (trancheDetailsData[i].tranches_status_label.trimEnd() ==
            'Disbursed' || trancheDetailsData[i].tranches_status_label.trimEnd() ==
            'Requested' || trancheDetailsData[i].tranches_status_label.trimEnd() == "Disbursement in Process"
            || trancheDetailsData[i].tranches_status_label.trimEnd() == "Incomplete"
            || trancheDetailsData[i].tranches_status_label.trimEnd() == "Verification of Documents in Process"
          )) {
          tranche_details.isPrevDisbursed = true;
        }

        trancheDetailsData[i] = tranche_details;
      }


      // setManualTrancheDetails();
    });
  };

  const handleChange = (
    evt: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const name = evt.target.name;
    const value = evt.target.value;

    if (name == 'tranche_status_id') {
      if (prevStatus == '4' &&
        ((parseInt(amount_disbursed_inc) - parseInt(fundDisbursed)) < parseInt(amount_disbursed_startup))
      ) {
        setCheckStatus('1')
      } else {
        setCheckStatus('0')
      }
      let trancheDetails = JSON.parse(JSON.stringify(updateTranche));
      if (prevStatus == evt.target.value) {
        trancheDetails['tranch_comment'] = prevComment;
      } else {
        trancheDetails['tranch_comment'] = '';
      }
      trancheDetails[name] = evt.target.value;
      setUpdateTranche(trancheDetails);
    } else {
      let trancheDetails = JSON.parse(JSON.stringify(updateTranche));
      if (name == 'fund_released' || name == 'approved_amount') {
        const numStr = String(evt.target.value);
        var count = 0;
        if (numStr.includes('.')) {
          count = numStr.split('.')[1].length;
        }
        if (count > 2) {
          trancheDetails[name] = Number(evt.target.value).toFixed(2);
        } else {
          trancheDetails[name] = evt.target.value;
        }
      } else {
        trancheDetails[name] = evt.target.value;
      }

      setUpdateTranche(trancheDetails);
    }
  };
  const history = useHistory();
  /** upload the files */
  const onFileUpload = (
    files: Array<File>,
    type: string,
    item: TrancheDetail,
    index: number
  ) => {
    // console.log(files, type, item, index,"index!!!")
    let indexes: Array<string> = [];

    for (const file of files) {
      let updatedOtherDetail = Object.assign(
        {},
        item.documents.proof_of_disbursement
      );
      const fileData = item.documents.proof_of_disbursement;

      const index = fileData.findIndex((el: any) => el.file === file);

      if (index == -1) {
        if (file.type === 'application/pdf') {
          const formData = new FormData();
          // Update the formData object
          formData.append('file', file, file.name);
          formData.append('tranche_id', item.tranch_id.toString());

          formData.append('file_type', type);
          formData.append('user_Role', userInfo.roles);

          formData.append('logged_user_id', userInfo.id);

          post(TRANCHE_UPLOAD_DOCUMENT, formData).then((response: any) => {
            if (!response.data) return;

            setApplicationId(item.application_id);
            //  console.log(response.data.data.status,"status!!!!")
            // if (response.data.data.status == "true") {
            //   // console.log("fileee")
            //   setShowSuccessModal(true);
            // } else {
            //   setShowFailureModal(true);
            // }
            // getTrancheRequest(item.application_id);
            // console.log(application_id,"77","appIddd")
          });
        }
      }
    }
  };

  const [showEditSuccessModal, setShowSuccessModal] = useState(false);
  const [showStatusFailureModal, setshowStatusFailureModal] = useState(false);
  const [showEditFailureModal, setShowFailureModal] = useState(false);
  const dismissModal = () => {
    setShowSuccessModal(false);
    setShowFailureModal(false);
    setdateEmptyPopup(false);
    setDisbursedAmountPopup(false);
    window.location.reload();
  };

  // Triggers when delete file is clicked
  const onFileDelete = (file: File, type: string, item: TrancheDetail) => {
    const { proof_of_disbursement, quarterly_reports, supporting_documents } = item.documents;
    let remainingFiles: Array<FileUploadModelTranche> = [];
    let fileToBeDeleted: any;

    switch (type) {
      case UploadTypeEnum.ProofDisbursement:
        fileToBeDeleted = proof_of_disbursement.find(
          (uploadedFile: FileUploadModelTranche) => uploadedFile.file === file
        );

        if (!fileToBeDeleted) return;
        remainingFiles = proof_of_disbursement.filter(
          (uf: FileUploadModelTranche) => uf.file !== file
        );

        break;
      case UploadTypeEnum.QuarterlyReport:
        fileToBeDeleted = quarterly_reports.find(
          (uploadedFile: FileUploadModelTranche) => uploadedFile.file === file
        );

        if (!fileToBeDeleted) return;
        remainingFiles = quarterly_reports.filter(
          (uf: FileUploadModelTranche) => uf.file !== file
        );

        break;

      case UploadTypeEnum.SupportingDocuments:
        fileToBeDeleted = supporting_documents.find(
          (uploadedFile: FileUploadModelTranche) => uploadedFile.file === file
        );

        if (!fileToBeDeleted) return;
        remainingFiles = proof_of_disbursement.filter(
          (uf: FileUploadModelTranche) => uf.file !== file
        );

        break;

    }
    // delete the file
    post(TRANCHE_REMOVE_DOCUMENT, {
      application_id: item.application_id,
      file_id: fileToBeDeleted.file_id,
      tranche_id: item.tranch_id,
    }).then((response: any) => {
      if (response.data.result.status == true) {
        setShowSuccessModal(true);
      } else {
        setShowFailureModal(true);
      }
      getTrancheRequest(item.application_id);
    });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          {trancheData != undefined && trancheData.length > 0
            ? trancheData.map((item, index) => (
              <div>
                <div className="row">
                  <div
                    id={`q-${index + 1}`}
                    className={
                      activeIndex1 == index + 1
                        ? 'tranche-heading col-sm-10 left-align'
                        : 'reports-heading col-sm-10 left-align'
                    }
                  >
                    <span
                      className={
                        activeIndex1 == index + 1
                          ? 'tranche-heading '
                          : 'reports-heading '
                      }
                    >
                      {item.tranche_number}
                    </span>
                    &nbsp;&nbsp; &nbsp;&nbsp;
                    {item.tranche_requested_date != null &&
                      is_incubator_user == true ? (
                      <span
                        className={
                          activeIndex1 == index + 1
                            ? 'tranche-subheading '
                            : 'reports-subheading '
                        }
                      >
                        {item.tranches_status_label === 'Disbursed'
                          ? 'Disbursed'
                          : 'Disbursement due within'}{' '}
                        {(item.tranches_status_label == 'Verification of Documents in Process' || item.tranches_status_label == 'Incomplete' || item.tranches_status_label == 'Disbursement in Process' || item.tranches_status_label == 'Requested')
                          ? getDisburseentDate(
                            item.tranche_requested_date,
                            'day by '
                          )
                          : ''}
                        {(item.tranches_status_label == 'Verification of Documents in Process' || item.tranches_status_label == 'Incomplete' || item.tranches_status_label == 'Disbursement in Process' || item.tranches_status_label == 'Requested')
                          ? ' days by '
                          : ''}
                        {(item.tranches_status_label == 'Verification of Documents in Process' || item.tranches_status_label == 'Incomplete' || item.tranches_status_label == 'Disbursement in Process' || item.tranches_status_label == 'Requested')
                          ? getDisburseentDate(
                            item.tranche_requested_date,
                            'date'
                          )
                          : ''}
                      </span>
                    ) : (
                      ''
                    )}
                    {is_incubator_user === false ? (
                      <span
                        className={
                          activeIndex1 == index + 1
                            ? 'tranche-subheading '
                            : 'reports-subheading '
                        }
                      >

                        {item.tranches_status_label == "Incomplete" ?
                          <>Marked Incomplete by Secretariat
                            <span className='Info-circle-incomplete ml-2'
                            // data-target="#fileInfo"
                            // data-toggle="modal"
                            // onClick={() => setModalPopup("Sanction")} 
                            >
                              ℹ️
                            </span>

                          </>
                          : item.tranches_status_label == "Verification of Documents Completed" ?
                            <>
                              Verification of Documents Completed
                              <span className='Info-circle-complete ml-2'
                              // data-target="#fileInfo"
                              // data-toggle="modal"
                              // onClick={() => setModalPopup("Sanction")} 
                              >
                                ℹ️
                              </span>
                            </>
                            : item.tranches_status_label}
                      </span>
                    ) : (
                      ''
                    )}


                  </div>
                  <div className="col-sm-1">
                    {/* {is_incubator_user == true &&
                      item.tranches_status_label == 'Incomplete' ? (
                      <a
                        className="incubator-details-link "
                        data-target="#requestTranche"
                        data-toggle="modal"
                      >
                        Edit Tranche
                      </a>
                    ) : (
                      ''
                    )} */}
                  </div>
                  <div className="col-sm-1">


                    {activeIndex1 == index + 1 ? (
                      <div
                        onClick={changeActiveBack1}
                        data-toggle="collapse"
                        data-target={`#tranche-expand1${index + 1}`}
                        role="button"
                        aria-expanded="false"
                        aria-controls={`tranche-expand1${index + 1}`}
                        className="hide-icon pull-right hide-icon-details"
                        id={`show-${index + 1}`}
                      >
                        <img
                          src={Minus}
                          className="img-fluid"
                          alt="SISFS Logo"
                        />
                      </div>
                    ) : (
                      <div
                        onClick={() => changeActive1(index + 1)}
                        id={`hide-${index + 1}`}
                        data-toggle="collapse"
                        data-target={`#tranche-expand1${index + 1}`}
                        role="button"
                        aria-expanded="false"
                        aria-controls={`tranche-expand1${index + 1}`}
                        className="expand-icon pull-right expand-icon-details"
                      >
                        <img
                          src={Plus}
                          className="img-fluid"
                          alt="SISFS Logo"
                        />
                      </div>
                    )}
                  </div>
                </div>
                {activeIndex1 == index + 1 ? (
                  <div className="collapse"//{activeIndex1 == index + 1 ? "collapse show" :"collapse"}
                    id={`tranche-expand1${index + 1}`}>
                    <div className="">
                      <div className="row mt-05">
                        <div className="col-md-6 left-align">
                          <span className="incubator-label">
                            Date of Requested
                          </span>
                          <br />
                          <span className="incubator-label-value">
                            {item.tranche_requested_date != null
                              ? formatDate(item.tranche_requested_date)
                              : '-'}
                          </span>

                          {/* {is_incubator_user == true ?
                          <a
                          className="incubator-details-link "
                          onClick={() =>
                            setModalDetails(
                              'tranche_requested_date',
                              item.tranch_id,
                              item.application_id,
                              item.tranche_requested_date.toString()
                            )
                          }
                          data-target="#editTranche"
                          data-toggle="modal"
                        >
                          Update
                        </a>
                          :""} */}
                        </div>
                        <div className="col-md-6 left-align">
                          <span className="incubator-label">Status
                            <span className='Info-circle2 ml-2'
                              data-target="#fileInfo2"
                              data-toggle="modal"
                              onClick={() => getStatusLogs(item.tranch_id)}
                            >
                              ℹ️
                            </span></span>
                          <br />
                          {item.tranches_status_label != null ? (
                            <span className="incubator-label-value-wrap">
                              {' '}
                              {item.tranches_status_label == "Incomplete" ? "Marked Incomplete by secretariat" : item.tranches_status_label}&nbsp;&nbsp;
                            </span>
                          ) : (
                            <span className="incubator-label-value-wrap">
                              {' '}
                              {'-'}{' '}
                            </span>
                          )}
                          {is_dpiit_user == true &&
                            // (item.tranches_status_label != "Disbursed")
                            (item.tranches_status_label == "Verification of Documents Completed" || item.tranches_status_label == "Disbursement in Process")
                            // && item.tranches_status_label != 'Not Requested'
                            // && item.isNextDisbursed == false &&
                            // item.isPrevDisbursed == true 
                            ? (
                              <a
                                className="incubator-details-link "
                                onClick={() =>
                                  setModalDetails(
                                    'tranches_status_label',
                                    item.tranch_id,
                                    item.application_id,
                                    item.tranches_status_label == null
                                      ? ''
                                      : item.tranches_status_label.toString(),
                                    item.tranches_status_label,
                                    item.tranche_status_comment,
                                    item.date_of_release,
                                    item.fund_released,
                                    item.application_index,
                                    item.approved_amount,
                                    item.funds_requested,
                                    item.tranche_number,
                                    item,
                                    index
                                  )
                                }

                                data-target="#editTrancheStatus1"
                                data-toggle="modal"
                              >

                                <b>Update</b>
                              </a>
                            ) : (
                              ''
                            )}
                        </div>
                      </div>
                      
                      <div className="row mt-05">
                        <div className="col-md-6 left-align">
                          <span className="incubator-label">
                            Fund Requested
                          </span>
                          <br />
                          {item.funds_requested != null ? (
                            <span className="incubator-label-value">
                              &#8377;{' '}
                              {changeNumberFormat(
                                Number(item.funds_requested),
                                2,
                                1
                              )}
                            </span>
                          ) : (
                            <span className="incubator-label-value">
                              {'-'}
                            </span>
                          )}
                          {/* {is_incubator_user == true &&
                            item.tranches_status_label == 'Incomplete' ? (
                              <a
                                className="incubator-details-link "
                                // onClick={() =>
                                //   setModalDetails(
                                //     'funds_requested',
                                //     item.tranch_id,
                                //     item.application_id,
                                //     item.funds_requested.toString()
                                //   )
                                // }
                                data-target="#requestTranche"
                                data-toggle="modal"
                              >
                                Edit
                              </a>
                            ) : (
                              ''
                            )} */}
                        </div>
                        <div className="col-md-6 left-align">
                          <span className="incubator-label">
                            Fund Approved
                          </span>
                          <br />
                          {item.approved_amount != null ? (
                            <span className="incubator-label-value">
                              &#8377;{' '}
                              {changeNumberFormat(
                                Number(item.approved_amount),
                                2,
                                1
                              )}
                            </span>
                          ) : (
                            <span className="incubator-label-value">
                              {'-'}
                            </span>
                          )}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          {is_dpiit_user == true && false
                            &&
                            (item.tranches_status_label == 'Disbursement in Process')
                            ? (
                              <a
                                className="incubator-details-link "
                                onClick={() =>
                                  setModalDetails(
                                    'approved_amount',
                                    item.tranch_id,
                                    item.application_id,
                                    item.approved_amount == null
                                      ? ''
                                      : item.approved_amount.toString(),
                                    item.tranche_number.trimEnd()
                                  )
                                }
                                data-target="#editTranche1"
                                data-toggle="modal"
                              >
                                Edit
                              </a>
                            ) : (
                              ''
                            )}
                          <br />
                          {/* {Number(item.fund_released) > Number(item.approved_amount) ? (
                            <span className="error-message">
                              Fund release amount is greater than approved
                              amount, Please check again..
                            </span>
                          ) : (
                            ''
                          )} */}
                        </div>

                      </div>
                      <div className="row mt-05">
                        <div className="col-md-6 left-align">
                          <span className="incubator-label">
                            Fund Disbursed
                          </span>
                          <br />
                          {item.fund_released != null ? (
                            <span className="incubator-label-value">
                              &#8377;{' '}
                              {changeNumberFormat(
                                Number(item.fund_released) + Number(item.Actual_management_fee != null ? item.Actual_management_fee : 0),
                                2,
                                1
                              )}
                            </span>
                          ) : (
                            <span className="incubator-label-value">
                              {'-'}
                            </span>
                          )}
                          {/* <span className="incubator-label-value">NA</span> */}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          {is_dpiit_user == true && false
                            &&
                            (item.tranches_status_label == 'Disbursement in Process')
                            ? (
                              <a
                                className="incubator-details-link "
                                onClick={() =>
                                  setModalDetails(
                                    'fund_released',
                                    item.tranch_id,
                                    item.application_id,
                                    item.fund_released == null
                                      ? ''
                                      : item.fund_released.toString()
                                  )
                                }
                                data-target="#editTranche1"
                                data-toggle="modal"
                              >
                                Edit
                              </a>
                            ) : (
                              ''
                            )}
                        </div>
                        <div className="col-md-6 left-align">
                          <span className="incubator-label">
                            Date of Fund Disbursed
                          </span>
                          <br />
                          {item.date_of_release != null ? (
                            <span className="incubator-label-value">
                              {formatDate(item.date_of_release)}
                            </span>
                          ) : (
                            <span className="incubator-label-value">
                              {'-'}
                            </span>
                          )}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          {is_dpiit_user == true && false
                            &&
                            (item.tranches_status_label == 'Disbursement in Process')
                            ? (
                              <a
                                className="incubator-details-link "
                                onClick={() =>
                                  setModalDetails(
                                    'date_of_release',
                                    item.tranch_id,
                                    item.application_id,
                                    item.date_of_release
                                  )
                                }
                                data-target="#editTranche1"
                                data-toggle="modal"
                              >
                                Edit
                              </a>
                            ) : (
                              ''
                            )}
                        </div>
                      </div>

                      <div className="row mt-05">
                        <div className="col-md-6 left-align">
                          <span className="incubator-label">
                            Proof of Disbursement
                          </span>
                          <br />
                          <div className="row">
                            {/* {console.log(item.documents.proof_of_disbursement)} */}
                            
                            <div className="col-md-8 left-align">
                              {/* is_dpiit_user == true && */}
                              {/* &&
                            item.isNextDisbursed == false */}
                              {/* {console.log(item.tranches_status_label)} */}
                              
                              {item.documents.proof_of_disbursement.map(
                                (file: any, index: any) =>
                                  index < 2 ? (
                                    <div className="row">
                                      <div className="col-md-3">
                                        {/* {console.log("file", file)} */}
                                        {getFileDetails(file, 'type') == 'pdf' ? (
                                          <img src={pdf} alt="SISFS Logo" />
                                        ) : getFileDetails(file, 'type') == 'doc' ? (
                                          <img src={doc} alt="SISFS Logo" />
                                        ) : getFileDetails(file, 'type') == 'xls' ? (
                                          <img src={xls} alt="SISFS Logo" />
                                        ) : (
                                          <img src={Allround} alt="SISFS Logo" />
                                        )}
                                      </div>
                                      <div className="col-md-9">
                                        <span className="document-label-value-wrap">
                                          {getFileDetails(file, 'name')}
                                        </span>
                                        <br />
                                        <a
                                          // href={file.file}
                                          href={file}
                                          target="_blank"
                                          //download="PitchDeck"
                                          className="incubator-details-link">
                                          <b>View</b>
                                        </a>
                                      </div>
                                    </div>
                                  ) : (
                                    '-'
                                  ),
                              )}

                            </div>
                          </div>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          {false && loader ?
                            <div className="spinner-border custom-spinner loader-position" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                            :

                            item &&
                            item.proofDisbursementFlag == true && (
                              // item.documents.proof_of_disbursement.length ===
                              //   0) ||
                              //   (item.documents.proof_of_disbursement.length >
                              //   0) && (
                              <>



                                <span style={{ marginTop: "-5%" }}
                                  className="close"
                                  onClick={
                                    () => setDocumentFlag(index, false)
                                  }
                                >
                                  <i className="fa fa-close"></i>
                                </span>

                                <FileUploader
                                  key={Math.random().toString()}
                                  id={`ProofDisbursement${index}`}
                                  information="Drag & Drop the file or Browse"
                                  fileType="application/pdf"
                                  buttonLabel="Browse"
                                  multiple={false}
                                  enablePreview
                                  IconComponent={() => (
                                    <span className="fa fa-cloud-upload"></span>
                                  )}
                                  removeIconClass=""
                                  existingFiles={[]}
                                  onUploadFinish={(files: Array<File>) => {
                                    onFileUpload(
                                      files,
                                      UploadTypeEnum.ProofDisbursement,
                                      item,
                                      index
                                    );
                                  }}
                                  onFileDelete={(deletedFile: File) =>
                                    onFileDelete(
                                      deletedFile,
                                      UploadTypeEnum.ProofDisbursement,
                                      item
                                    )
                                  }
                                />
                              </>
                            )}
                        </div>
                        <div className="col-md-6 left-align">
                          <span className="incubator-label">
                            Supporting Documents
                          </span>
                          <br />
                          {/* {console.log(item.tranches_status_label)} */}
                          {console.log(item)}
                          {

                            (new Date(item.tranche_requested_date) > new Date("2024-02-11T00:00:00.000Z")) &&
                            <>
                              { 
                              // item.tranches_status_label == "Incomplete" ? "Click here to change/ View all documents":
                                item.tranche_number == "Tranche 1" && item.tranches_status_label !== "Not Requested" ?

                                  <Link to={{
                                    pathname: `/secretariat/first_subsequent_tranche/${incubator_user_id}/${item.tranche_number}/F1/${item.application_id}/${item.tranch_id}/${item.tranches_status_label}/${item.incubator_application_no}`,
                                    state: {
                                      application_id: item.application_id,
                                      tranche_id: item.tranch_id,
                                      funds_requested: item.funds_requested,
                                      cash_in_hand: item.cash_in_hand,
                                      incubator_application_no:item.incubator_application_no
                                    }
                                  }} >Click here to change/ View all documents </Link> :

                                  item.tranches_status_label !== "Not Requested" &&
                                  <Link to={{
                                    pathname: `/secretariat/subsequent_tranche/${incubator_user_id}/${item.tranche_number}/F1/${item.application_id}/${item.tranch_id}/${item.tranches_status_label}/${item.incubator_application_no}`,
                                    state: {
                                      application_id: item.application_id,
                                      tranche_id: item.tranch_id,
                                      funds_requested: item.funds_requested,
                                      cash_in_hand: item.cash_in_hand,
                                      incubator_application_no:item.incubator_application_no
                                    }
                                  }} >Click here to change/ View all documents</Link>
                              }
                            </>
                          }

                          {/* <div className="row">
                            <div className="col-md-8 left-align">
                              {item.documents.supporting_documents.length == 0 &&
                                <label className="document-label-value">
                                  {' '}
                                  -
                                </label>
                              }
                            </div>

                            <div className="col-md-8 left-align">
                              {item.documents.supporting_documents.map(
                                (file: any, index: any) =>
                                  index < 2 ? (
                                    <div className="row">
                                      <div className="col-md-3">
                                        {getFileDetails(file, 'type') == 'pdf' ? (
                                          <img src={pdf} alt="SISFS Logo" />
                                        ) : getFileDetails(file, 'type') == 'doc' ? (
                                          <img src={doc} alt="SISFS Logo" />
                                        ) : getFileDetails(file, 'type') == 'xls' ? (
                                          <img src={xls} alt="SISFS Logo" />
                                        ) : (
                                          <img src={Allround} alt="SISFS Logo" />
                                        )}
                                      </div>
                                      <div className="col-md-9">
                                        <span className="document-label-value-wrap">
                                          {getFileDetails(file, 'name')}
                                        </span>
                                        <br />
                                        <a
                                          // href={file.file}
                                          href={file}
                                          target="_blank"
                                          //download="PitchDeck"
                                          className="incubator-details-link">
                                          View
                                        </a>
                                      </div>
                                    </div>
                                  ) : (
                                    ''
                                  ),
                              )}

                              {(item.tranches_status_label == 'Disbursed' || item.tranches_status_label == 'Disbursement in Process') &&
                                // {((openUploadBlock &&
                                //   openUploadBlock['SupportingDocuments'] &&
                                //   trancheRequest.supporting_documents.length ===
                                //   0) ||
                                //   trancheRequest.supporting_documents.length >
                                //   0) && (
                                <>
                                  <span style={{ marginTop: "-7%" }}
                                    className="close"
                                    onClick={() =>
                                      setOpenUploadBlock({
                                        SupportingDocuments: false,
                                      })
                                    }
                                  >
                                    <i className="fa fa-close"></i>
                                  </span>
                                  <FileUploader
                                    key={UploadTypeEnum.SupportingDocuments}
                                    id={`supporting_documents_${index + 1}`}
                                    information="Drag & Drop the file or Browse"
                                    fileType="application/pdf"
                                    buttonLabel="Browse"
                                    multiple={false}
                                    enablePreview
                                    IconComponent={() => (
                                      <span className="fa fa-cloud-upload"></span>
                                    )}
                                    removeIconClass=""
                                    existingFiles={trancheRequest.supporting_documents.map(
                                      (uploadedFile: FileUploadModel) =>
                                        uploadedFile.file
                                    )}
                                    onUploadFinish={(files: Array<File>) => {
                                      onFileUpload(
                                        files,
                                        UploadTypeEnum.SupportingDocuments,
                                        item,
                                        index
                                      );
                                    }}
                                    onFileDelete={(deletedFile: File) =>
                                      onFileDelete(
                                        deletedFile,
                                        UploadTypeEnum.SupportingDocuments,
                                        item
                                      )
                                    }
                                  />
                                </>
                                // )}
                              }
                            </div>
                          </div> */}
                        </div>
                        
                        {/* <div className="col-md-6 left-align">
                          <span className="eac-review-subtitle">
                            Quarterly Report
                          </span>
                          <br />
                          <div className="row">
                            <div className="col-md-8 left-align">
                              {item.documents.quarterly_reports.length == 0 &&
                                <label className="document-label-value">
                                  {' '}
                                  -
                                </label>
                              }
                            </div>
                            <div className="col-md-8 left-align">
                              {item.documents.quarterly_reports.map(
                                (file: any, index: any) =>
                                  index < 2 ? (
                                    <div className="row">
                                      <div className="col-md-3">
                                        {getFileDetails(file, 'type') == 'pdf' ? (
                                          <img src={pdf} alt="SISFS Logo" />
                                        ) : getFileDetails(file, 'type') == 'doc' ? (
                                          <img src={doc} alt="SISFS Logo" />
                                        ) : getFileDetails(file, 'type') == 'xls' ? (
                                          <img src={xls} alt="SISFS Logo" />
                                        ) : (
                                          <img src={Allround} alt="SISFS Logo" />
                                        )}
                                      </div>
                                      <div className="col-md-9">
                                        <span className="document-label-value-wrap">
                                          {getFileDetails(file, 'name')}
                                        </span>
                                        <br />
                                        <a
                                          // href={file.file}
                                          href={file}
                                          target="_blank"
                                          //download="PitchDeck"
                                          className="incubator-details-link">
                                          View
                                        </a>
                                      </div>
                                    </div>
                                  ) : (
                                    ''
                                  ),
                              )}
                              {(item.tranches_status_label == 'Disbursed' || item.tranches_status_label == 'Disbursement in Process') &&
                                <>
                                  <span style={{ marginTop: "-7%" }}
                                    className="close"
                                    onClick={() =>
                                      setOpenUploadBlock({
                                        QuarterlyReport: false,
                                      })
                                    }
                                  >
                                    <i className="fa fa-close"></i>
                                  </span>
                                  <FileUploader
                                    key={UploadTypeEnum.QuarterlyReport}
                                    id={`quarterly_report_${index + 1}`}
                                    information="Drag & Drop the file or Browse"
                                    fileType="application/pdf"
                                    buttonLabel="Browse"
                                    multiple={false}
                                    enablePreview
                                    IconComponent={() => (
                                      <span className="fa fa-cloud-upload"></span>
                                    )}
                                    removeIconClass=""

                                    existingFiles={trancheRequest.quarterly_report.map(
                                      (uploadedFile: FileUploadModel) =>
                                        uploadedFile.file
                                    )}
                                    onUploadFinish={(files: Array<File>) => {
                                      onFileUpload(
                                        files,
                                        UploadTypeEnum.QuarterlyReport,
                                        item,
                                        index
                                      );
                                    }}
                                    onFileDelete={(deletedFile: File) =>
                                      onFileDelete(
                                        deletedFile,
                                        UploadTypeEnum.QuarterlyReport,
                                        item
                                      )
                                    }
                                  />
                                </>
                              }
                            </div>
                          </div>
                        </div> */}
                      </div>
                      {item.tranche_number != "Tranche 1" &&
                        <div className="row mt-05">
                          <div className="col-md-6 left-align">
                            <span className="incubator-label">
                              Interest Accumulated on Funds
                            </span>
                            <br />
                            {item.interest_accumulated_on_funds != null &&
                              item.interest_accumulated_on_funds.toString() !=
                              '' ? (
                              <span className="incubator-label-value">
                                &#8377;{' '}
                                {changeNumberFormat(
                                  Number(item.interest_accumulated_on_funds),
                                  2,
                                  1
                                )}
                              </span>
                            ) : (
                              <span className="incubator-label-value">
                                {'-'}
                              </span>
                            )}
                          </div>


                          <div className="col-md-6 left-align">
                            <span className="fund-label">
                              Is cash-in-hand of the incubator below 10% of the
                              total commitment by EAC?
                            </span>
                            <br />
                            <span className="incubator-label-value">
                              {/* No */}
                              {item.cash_in_hand == '1'
                                ? 'Yes'
                                : item.tranches_status_label == "Not Requested" && item.cash_in_hand == '0'
                                  ? '-'
                                  : 'No'}
                            </span>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {/* {is_incubator_user == true &&
                            item.tranches_status_label == 'Incomplete' ? (
                              <a
                                className="incubator-details-link "
                                // onClick={() =>
                                //   setModalDetails(
                                //     'cash_in_hand',
                                //     item.tranch_id,
                                //     item.application_id,
                                //     item.cash_in_hand == null
                                //       ? ''
                                //       : item.cash_in_hand.toString()
                                //   )
                                //}
                                data-target="#requestTranche"
                                data-toggle="modal"
                              >
                                Edit
                              </a>
                            ) : (
                              ''
                            )} */}
                          </div>
                        </div>}
                        <div className="row mt-05">
                        <div className="col-md-6 left-align">
                          <span className="incubator-label">
                            Disbursed management fee
                          </span>
                          <br />
                          {/* {console.log(item.Reduced_management_fee)} */}
                          {item.Reduced_management_fee != null ? (
                            <span className="incubator-label-value">
                              &#8377;{' '}
                              {item.Reduced_management_fee} &nbsp;&nbsp;&nbsp;
                              {
                                item.Reduced_management_fee > 0 ?
                                  <a className="incubator-details-link" onClick={() => reducedpopup(item)}>
                                     <b>View</b>
                                     </a> : ''
                              }
                            </span>
                          ) : (
                            <span className="incubator-label-value">
                              {'-'}
                            </span>
                          )}
                        </div>
                        </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div className="application-incubator-hr">
                  <hr />
                </div>
              </div>
            ))
            : ''}
        </div>
      </div>
      {/* Tranche Status Change Pop up */}
      <div
        className="modal fade"
        id="fileInfo2"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none pb-0">
              <div className='text-center'>
                <h5 className="modal-title " id="exampleModalLongTitle">
                  <span>
                    Tranche Status Logs
                  </span>
                </h5>
              </div>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ overflowY: 'scroll' }}>
              {/* <div className="row mb-3">
                                <div className="col-12 text-center">
                                    <h5 className="modal-title " id="exampleModalLongTitle">

                                        <span>SISFS Subsequent Tranche SOP – Do's and Don’ts
                                        </span>
                                    </h5>
                                </div>
                            </div> */}

              {
                getAllStatusLogs && getAllStatusLogs.length > 0 ?
                  <div className="pl-3">
                    <table>
                      <thead>
                        <tr>
                        <th className='text-center'>Status Changed By</th>
                          <th className='text-center'>Status</th>
                          <th className='text-center'>Updated On</th>
                        </tr>
                      </thead>
                      {getAllStatusLogs && getAllStatusLogs.map((item: any) => {
                        return <>
                          <tbody>
                            <tr>
                              <td className='text-center'>{item.created_role}</td>
                              <td className='text-center'>{item.label}</td>
                              <td className='text-center'>{moment(item.updated_at).format("DD-MM-YYYY")}</td>
                            </tr>
                          </tbody>
                        </>
                      })}

                    </table>
                  </div>
                  :
                  <span className='text-center'>
                    <h4>Status is not updated yet!</h4>
                  </span>
              }

              <div className="row pt-3 mb-3">
                <div className="col-md-12 centerText"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Add Comment Pop Up */}
      <div
        className="modal fade"
        id="addEditCommentTranche"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none">
              <h5 className="modal-title " id="exampleModalLongTitle">
                Add Tranche Comment <span className="eac-name-pop-up"></span>
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12 left">
                  <textarea
                    className="form-control"
                    name="comment"
                    value={updateTranche.tranch_comment}
                    rows={3}
                    placeholder="Enter Comment"
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 text-right">
                  <div className="btn-group mt-1-5 ">
                    <button
                      className="tertiary   btn-transparent-primary"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>

                    <button
                      type="button"
                      className="btn btn-sisfs btn-yellow-primary ml-2"
                      onClick={() => updateTrancheCommentByStatus()}
                      data-dismiss="modal"
                    >
                      Add Comment{' '}
                    </button>
                  </div>
                </div>
              </div>

              <div className="row pt-3 mb-3">
                <div className="col-md-12 centerText"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Amount Pop Up*/}
      <div
        className="modal fade"
        id="editTranche1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none">
              <h5 className="modal-title " id="exampleModalLongTitle">
                {editName} <span className="eac-name-pop-up"></span>
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {nameProp == 'date_of_release' ||
                nameProp == 'tranche_requested_date' ? (
                <div>
                  <div className="row">
                    <div className="col-md-12 text-left">
                      <span className="col-form-head">Select Date</span>
                    </div>
                  </div>
                  <div className="row mt-1-5">
                    <div className="col-md-12 text-left">
                      <Calendar
                        id="date_of_release"
                        name={
                          nameProp == 'date_of_release' ? 'date_of_release' : ''
                        }
                        className="form-control input-height"
                        format="dd/MM/yyyy"
                        selectedDate={
                          updateTranche.date_of_release != null
                            ? updateTranche.date_of_release
                            : new Date()
                        }
                        onDateChange={(date: Date, field: string) =>
                          handleDateChange(date, field)
                        }
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-md-12 center">
                    {nameProp == 'funds_requested' ? (
                      <input
                        type="number"
                        className="form-control"
                        name={nameProp}
                        value={updateTranche.funds_requested}
                        placeholder={editName}
                        onChange={handleChange}
                      ></input>
                    ) : (
                      ''
                    )}

                    {nameProp == 'approved_amount' ||
                      nameProp == 'fund_released' ? (
                      <input
                        type="number"
                        className="form-control"
                        name={nameProp}
                        value={
                          nameProp == 'approved_amount'
                            ? updateTranche.approved_amount
                            : nameProp == 'fund_released'
                              ? updateTranche.fund_released
                              : updateTranche.tranch_comment
                        }
                        placeholder={editName}
                        onChange={handleChange}
                      ></input>
                    ) : nameProp == 'tranch_comment' ? (
                      <textarea
                        className="form-control"
                        name={nameProp}
                        value={updateTranche.tranch_comment}
                        rows={4}
                        placeholder={"Type Comment"}
                        onChange={handleChange}
                      ></textarea>
                    ) : (
                      ''
                    )}
                    {nameProp == 'cash_in_hand' ? (
                      <div>
                        <label className="col-form-head">
                          Is your Cash-in -Hand less than 10% of the total
                          commitment by EAC(including the interest accumulated
                          and net returns)?
                        </label>
                        <div className="toggle-btn-group">
                          <div className="btn-group btn-group-toggle ">
                            <label
                              className={
                                trancheRequest.cash_in_hand === '1'
                                  ? 'btn active'
                                  : 'btn'
                              }
                            >
                              <input
                                type="radio"
                                name="cash_in_hand"
                                id="cash_in_hand_1"
                                value="1"
                                onChange={handleChangeTrancheRequest}
                                checked={trancheRequest.cash_in_hand === '1'}
                              />
                              Yes
                            </label>
                            <label
                              className={
                                trancheRequest.cash_in_hand === '0'
                                  ? 'btn active'
                                  : 'btn'
                              }
                            >
                              <input
                                type="radio"
                                name="cash_in_hand"
                                id="cash_in_hand_2"
                                value="0"
                                onChange={handleChangeTrancheRequest}
                                checked={trancheRequest.cash_in_hand === '0'}
                              />
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              )}
              {showLimitMessage == true &&
                updateTranche.approved_amount > tranche1_approved_limit && (loggedInRoleName == "incubator" || loggedInRoleName == "startup") ? (
                <div className="row">
                  <div className="col-md-12 text-left">
                    <span className="error-message">
                      You cannot approve more than 40% of total grant approved
                      amount(that is more than {tranche1_approved_limit}).
                    </span>
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className="row">
                <div className="col-md-12 text-right">
                  <div className="btn-group mt-1-5 ">
                    <button
                      className="tertiary   btn-transparent-primary"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>

                    {showLimitMessage == true &&
                      updateTranche.approved_amount > tranche1_approved_limit && (loggedInRoleName == "incubator" || loggedInRoleName == "startup") ? (
                      <button
                        type="button"
                        disabled
                        className="btn btn-sisfs btn-yellow-primary ml-2"
                        onClick={() =>
                          nameProp == 'tranch_comment'
                            ? updateTrancheCommentByStatus()
                            : updateTrancheDetails(nameProp, "")
                        }
                        data-dismiss="modal"
                      >
                        Update{' '}
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-sisfs btn-yellow-primary ml-2"
                        onClick={() =>
                          nameProp == 'tranch_comment'
                            ? updateTrancheCommentByStatus()
                            : updateTrancheDetails(nameProp, "")
                        }
                        data-dismiss="modal"
                      >
                        Update{' '}
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className="row pt-3 mb-3">
                <div className="col-md-12 centerText"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Status Pop Up*/}
      <div
        className="modal fade"
        id="editTrancheStatus1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none">
              <h5 className="modal-title " id="exampleModalLongTitle">
                Update Tranches Status <span className="eac-name-pop-up"></span>
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12 text-left">
                  <span className="col-form-head">Tranche Status</span>
                </div>
              </div>
              {/* {console.log(getTrancheNumber, "updateTranche")} */}
              <div className="row mt-1-5">
                <div className="col-md-12 text-left">
                  <div className="select-custom">
                    <select
                      className="form-control"
                      aria-label="Select Status"
                      id="tranche_status_id"
                      name="tranche_status_id"
                      value={updateTranche.tranche_status_id}
                      style={{fontWeight:'600'}}
                      onChange={handleChange}
                    >
                      <option value="">--Please Select status--</option>
                      {/* .filter((item:any)=> item.value == 3 || item.value ==4) */}
                      {applicationStatus.filter((item: any) => (getStatus_label == "Verification of Documents Completed" ? (item.value == 3) : (item.value == 3 || item.value == 4))).map((item) =>
                        item.label != 'Requested' ? (
                          <option key={item.label} value={item.value} style={{fontWeight:'600'}}>
                            {item.label}
                          </option>
                        ) : (
                          ''
                        )
                      )}
                    </select>
                  </div>
                </div>
              </div>
              {
                updateTranche.tranche_status_id == 3 &&
                <>
                  <div className="row justify-content-center mt-3">
                    <div className="col-sm-12">
                      <div className="form-wrapper contact-form">
                        <div className="form-group row">
                          <label className="col-sm-12 col-md-4 col-form-label text-left">
                            Fund Approved:
                          </label>
                          <div className="col-sm-12 col-md-7">
                            <input
                              type="text"
                              name='fund_Approved'
                              disabled
                              value={getStatusInputs.fund_Approved}
                              max={getStatusInputs.fund_Approved}
                              className='form-control'
                              placeholder='Enter Amount'
                              onChange={handleStatusChange}
                            />
                            {(getStatusInputs.fund_Approved == '' || getStatusInputs.fund_Approved == undefined) && errormsg && < span className='text-danger' > Please Enter Value</span>}
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-sm-12 col-md-4 col-form-label text-left">
                            Interest Accumulated:
                          </label>
                          <div className="col-sm-12 col-md-7">
                            <input
                              type="text"
                              name='intrst_Accm'
                              disabled={getTrancheNumber == "Tranche 1"}
                              value={getStatusInputs.intrst_Accm}
                              className='form-control'
                              placeholder='Enter Amount'
                              onChange={handleStatusChange}
                            />
                            {(getStatusInputs.intrst_Accm == '' || getStatusInputs.intrst_Accm == undefined) && errormsg && < span className='text-danger' > Please Enter Value</span>}
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-sm-12 col-md-4 col-form-label text-left">
                            Management Fee Approved:
                          </label>
                          <div className="col-sm-12 col-md-7">
                            <input
                              type="text"
                              name='mngmnt_Fee'
                              onChange={handleStatusChange}
                              value={getStatusInputs.mngmnt_Fee}
                              className='form-control'
                              placeholder='Enter Management Fee'
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }

              {
                updateTranche.tranche_status_id == 4 &&
                <>
                  <div className="row justify-content-center mt-3">
                    <div className="col-sm-12">
                      <div className="form-wrapper contact-form">
                        <div className="form-group row">
                          <label className="col-sm-12 col-md-4 col-form-label text-left">
                            Fund Disbursed:
                          </label>
                          <div className="col-sm-12 col-md-7">
                            <input
                              type="text"
                              name='fund_Disbursed'
                              value={getStatusInputs.fund_Disbursed}
                              onChange={handleStatusChange}
                              className='form-control'
                              placeholder='Enter Amount'
                            />
                            {(getStatusInputs.fund_Disbursed == '' || getStatusInputs.fund_Disbursed == undefined) && errormsg && < span className='text-danger' > Please Enter Value</span>}
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-sm-12 col-md-4 col-form-label text-left">
                            Management Fee Disbursed:
                          </label>
                          <div className="col-sm-12 col-md-7">
                            <input
                              type="text"
                              name='mngmntDisbursed'
                              onChange={handleStatusChange}
                              value={getStatusInputs.mngmntDisbursed}
                              className='form-control'
                              placeholder='Enter Amount'
                            />
                            <span style={{ color: 'grey', fontSize: '12px' }}><i>Can only decrease the amount, if need.</i></span> <br />
                            {(getStatusInputs.mngmntDisbursed == '' || getStatusInputs.mngmntDisbursed == undefined) && errormsg && < span className='text-danger' > Please Enter Value</span>}
                          </div>
                        </div>

                        {reasonChk &&

                          <>
                            <div className="form-group row">
                              <label className="col-sm-12 col-md-4 col-form-label text-left">
                                Reason:
                              </label>
                              <div className="col-sm-12 col-md-7">
                                <input
                                  type="text"
                                  name='reason'
                                  onChange={handleStatusChange}
                                  className='form-control'
                                  placeholder='Reason For Changing Amount'
                                />
                                {(getStatusInputs.reason == '' || getStatusInputs.reason == undefined) && errormsg && < span className='text-danger' > Please Enter Reason</span>}
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-sm-12 col-md-4 col-form-label text-left">
                                Attach a File:
                              </label>
                              <div className="col-sm-12 col-md-7">
                                <input
                                  type="file"
                                  id="file"
                                  accept='.pdf'
                                  name='file_Upload'
                                  className='form-control'
                                  onChange={handleUploadFile}
                                />
                                <span style={{ color: 'grey', fontSize: '12px' }}><i>File format - PDF Only</i></span> <br />
                                {exceedFileSize ? < span className='text-danger'>File size should be less than or equal to 5MB.</span>
                                  : (disbursement_file == null || disbursement_file == undefined) && errormsg && < span className='text-danger' >Please Upload File</span>}
                              </div>
                            </div>
                          </>
                        }

                        <div className="form-group row">
                          <label className="col-sm-12 col-md-4 col-form-label text-left">
                            Date of Funds Disbursed:
                          </label>
                          <div className="col-sm-12 col-md-7">
                            <input
                              type="date"
                              name='date_disbursed'
                              className='form-control'
                              onChange={handleStatusChange}
                              placeholder='Select Date'
                            />
                            {(getStatusInputs.date_disbursed == '' || getStatusInputs.date_disbursed == undefined) && errormsg && < span className='text-danger' > Please Enter Date</span>}
                          </div>
                        </div>

                        <div className="form-group row">
                          <label className="col-sm-12 col-md-4 col-form-label text-left">
                            Proof of Disbursement:
                          </label>
                          <div className="col-sm-12 col-md-7">
                            <input
                              type="file"
                              id="file"
                              accept='.pdf'
                              className='form-control'
                              name='PoD_file_Upload'
                              onChange={(event) => handleFileChange(event, UploadTypeEnum.ProofDisbursement, app_details, dis_file_index,)}
                            />    
                            <span style={{ color: 'grey', fontSize: '12px' }}><i>File format - PDF Only</i></span> <br />
                            {(PoD_disbursement_file == null || PoD_disbursement_file == undefined) && errormsg && < span className='text-danger' >Please Upload File</span>}                        
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
              {/* <div className="row  mt-1-5">
                <div className="col-md-12 text-left">
                  <span className="col-form-head">Tranche Comment</span>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <textarea
                    className="form-control"
                    name="tranch_comment"
                    value={updateTranche.tranch_comment}
                    rows={4}
                    placeholder="Type Comment"
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div> */}
              <div className="row">
                <div className="col-md-12 text-right">
                  <div className="btn-group mt-1-5 ">
                    <button
                      className="tertiary   btn-transparent-primary"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-sisfs btn-yellow-primary ml-2"
                      disabled={updateTranche.tranche_status_id == 7 || (typeof updateTranche.tranche_status_id == 'number' ? updateTranche.tranche_status_id == null : updateTranche.tranche_status_id == '')}
                      onClick={() =>
                        // updateTranche.tranche_status_id == 4 &&
                        // (dateRelease == null || fundDisbursed == null || fundApproved == null) ? setdateEmptyPopup(true)
                        // : 
                        updateTrancheDetails('tranche_status_id', updateTranche.tranche_status_id)}
                    // data-dismiss="modal"
                    >
                      Update{' '}
                    </button>
                  </div>
                </div>
              </div>

              <div className="row pt-3 mb-3">
                <div className="col-md-12 centerText"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        dateEmptyPopup && (
          <>
            <div className="modal-backdrop fade show"></div>
            <div
              className="modal align-middle modal-sisfs d-block"
              id="FormSubmissionError"
            >
              <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                  <div className="modal-body text-center">
                    {/* <i className="fa fa-times-circle-o"></i> */}
                    <h3 className="modal-message message-orange">
                      Oops!
                    </h3>
                    <br />
                    <h4 className="modal-message message-orange" >
                      Please provide Approved amount, Disbursed amount and Date of Disbursal
                    </h4>
                    <div className="modal-action">
                      <button
                        className="btn btn-sisfs btn-yellow-primary"
                        onClick={dismissModal}
                      >
                        OK !
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }
      {
        disbursedAmountPopup && (
          <>
            <div className="modal-backdrop fade show"></div>
            <div
              className="modal align-middle modal-sisfs d-block"
              id="FormSubmissionError"
            >
              <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                  <div className="modal-body text-center">
                    {/* <i className="fa fa-times-circle-o"></i> */}
                    <h3 className="modal-message">
                      Oops!
                    </h3>
                    <br />
                    <h6 className="message-orange" style={{ lineHeight: '1.5' }}>
                      You can't change the status of the tranche as total amount disbursed
                      to the incubator cannot be less than the total amount disbursed to the startups.
                    </h6>
                    <div className="modal-action">
                      <button
                        className="btn btn-sisfs btn-yellow-primary"
                        onClick={dismissModal}
                      >
                        OK !
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }
      {
        showEditSuccessModal && (
          <>
            <div className="modal-backdrop fade show"></div>

            <div
              className="modal align-middle modal-sisfs d-block"
              id="FormSubmissionSuccess"
            >
              <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                  <div className="modal-body text-center">
                    <i className="fa fa-check-circle-o"></i>
                    <h3 className="modal-message">Thank You !</h3>
                    <div className="modal-text">
                      Tranche Details Updated Successfully !!
                    </div>
                    <div className="modal-action">
                      <button
                        className="btn btn-sisfs btn-yellow-primary"
                        onClick={dismissModal}
                      >
                        Ok !!
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }
      {
        showEditFailureModal && (
          <>
            <div className="modal-backdrop fade show"></div>
            <div
              className="modal align-middle modal-sisfs d-block"
              id="FormSubmissionError"
            >
              <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                  <div className="modal-body text-center">
                    {/* <i className="fa fa-times-circle-o"></i> */}
                    <h3 className="modal-message message-orange">
                      Oops! The Amount Should be Greater than 0
                    </h3>
                    <br />
                    <h3 className="modal-message message-orange">
                      Please Try Again !!!
                    </h3>
                    <div className="modal-action">
                      <button
                        className="btn btn-sisfs btn-yellow-primary"
                        onClick={dismissModal}
                      >
                        OK !
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }
      {/* {showValidationFailureModal && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionError"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center"> 
                  <h4 className="modal-message message-orange">Entered Amount should be less than or equal to Approved Amount</h4>
                  <div className="modal-action">
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={dismissModal}
                    >
                      OK !
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )} */}
      {/* {showValidationFailureModal1 && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionError"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center"> 
                  <h4 className="modal-message message-orange">Entered Amount is less than Fund Released Amount</h4>
                  <div className="modal-action">
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={dismissModal}
                    >
                      OK !
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )} */}
      {/* {showfundvalidation && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionError"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center"> 
                  <h4 className="modal-message message-orange">Entered Amount should be lesser than Total Grant Approved & Total Management Fees</h4>
                  <div className="modal-action">
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={dismissModal}
                    >
                      OK !
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )} */}
      {showStatusFailureModal && (
        <ConfirmationDialog
          headerText={failDialogData.header}
          bodyText={failDialogData.text}
          // bodyNote={failDialogData.note}        
          onDialogClose={() => setshowStatusFailureModal(false)}
          iconClass="showImage"
        />
      )}
      {
        showIncubatorFailureModal && (
          <ConfirmationDialog
            headerText={failDialogData.header}
            bodyText={failDialogData.text}
            // bodyNote={failDialogData.note}        
            onDialogClose={() => setShowIncubatorFailureModal(false)}
            iconClass="showImage"
          />
        )
      }
      {reasonPopup2 && (
        <>
          <div className="modal-backdrop fade show"></div>

          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionSuccess"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <div className="row text-center">
                    <h4>Reduced Management Fee Reason</h4>
                  </div>
                  <div className="row mt-3">
                    <div className="col-4 text-left">Reason:</div>
                    <div className="col-8" >
                      <textarea readOnly name="" id="" style={{ width: '100%', padding: '5px 20px', height: '100px' }}>
                        {reducedData.Reduced_management_fee_comment}
                      </textarea>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-4 text-left">Uploaded File:</div>
                    <div className="col-8 d-flex">
                      <div>
                        <img src={pdf} alt="" /> &nbsp;&nbsp;&nbsp;
                      </div>
                      <div>
                        <a target="_blank" className="incubator-details-link" href={NODE_URL + reducedData.Reduced_management_fee_reason_file_path}>{reducedData.Reduced_management_fee_reason_file_name}</a>
                      </div>
                    </div>
                  </div>
                  <div className="modal-action">
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={() => setReasonPopup2(false)}
                    >
                      Ok !!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div >
  );
}

export default MergedFirstTrancheWiseDetails;
