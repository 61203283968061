import React from 'react';

function HomeApplyNowComponent(){
    return(
        <section className="section-container section-bg-yellow section-home">
                <div className="container">
                    <div className="section-apply">  
                        <div className="section-heading">
                            <h2 className="mb-0">Scale up your startup with <br/>
                            Startup India Seed Fund Scheme</h2>
                        </div>
                        <div className="section-content mb-4">
                            <p>Your journey of seed fund starts here.<br/>
                            Complete the application to initiate the journey</p>
                        </div>
                    </div>

                    <div className="section-action">
                        <a className="btn btn-large btn-sisfs btn-blue-primary" data-toggle="modal" data-target="#ApplyNowModal">Apply Now</a>
                    </div>
                </div>
        </section>  
    )
}

export default HomeApplyNowComponent;