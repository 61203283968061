//  *********HeaderComponent*****************
// SelectHTMLAttributes
import React, { useState, useEffect } from "react";
import SeedFundSchemeLogo from "../../../images/SeedFundSchemeLogo.svg";
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import LoginComponent from "../login/LoginComponent";
import ProfileContainer from "./ProfileContainer";
import { getSessionStorageValue } from "../../../effects/utils/session-storage";
import { useAuthStateValue } from "../../../providers/AuthStateProvider";
import ApplyNowModalComponent from "./ApplyNowModalComponent";

import web_icon from "../../../utils/web-icon.svg";
// import GuidelinesFundSchemeStartup from '../../../pdffiles/Guidelines-FundScheme-Startup-29January2021.pdf';

import { ChangeLanguage } from "../../../utils/p9_language";
import { Dropdown } from "react-bootstrap";
import Cookies from "js-cookie";
import { File_node_url } from "../../../constants/api";

// interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
//     p9ignore?: string ;
//   }
//   const MySelect: React.FC<Props> = ({ p9ignore:any, ...rest }) => {
//     return (
//         <div id="p9divmenu">
//       <select {...rest} onChange={(e) => ChangeLanguage(e.target.value)} id="P9LngDdl" >

//        <option value="telugu">Telugu</option>
//     <option value="english">English</option>
//     <option value="assamese">Assamese</option>
//     <option value="bengali">Bengali</option>
//     <option value="gujarati">Gujarati</option>
//     <option value="hindi">Hindi</option>
//     <option value="kannada">Kannada</option>
//     <option value="malayalam">Malayalam</option>
//     <option value="marathi">Marathi</option>
//     <option value="oriya">Oriya</option>
//     <option value="punjabi">Punjabi</option>
//     <option value="tamil">Tamil</option>
//       </select>

//     </div>)
//   };

function HeaderComponent() {
  // @ts-ignore: Unreachable code error
  const [{ user }, dispatch] = useAuthStateValue();
  const [showLanguageList, setShowLanguageList] = useState(false);

  useEffect(() => {
    if (user.accessToken) {
      const overlayElement = document.getElementById("overlay");
      if (!overlayElement) return;

      overlayElement.style.display = "none";
    }
  }, [user]);

  const renderLanguageList = () => {
    const languages = [
      { name: "English", value: "english" },
      { name: "हिन्दी", value: "hindi" },
      { name: "অসমীয়া", value: "assamese" },
      { name: "বাংলা", value: "bengali" },
      { name: "ગુજરાતી", value: "gujarati" },
      { name: "ಕನ್ನಡ", value: "kannada" },
      { name: "മലയാളം", value: "malayalam" },
      { name: "मराठी", value: "marathi" },
      { name: "ଓଡ଼ିଆ", value: "oriya" },
      { name: "ਪੰਜਾਬੀ", value: "punjabi" },
      { name: "தமிழ்", value: "tamil" },
      { name: "తెలుగు", value: "telugu" },
    ];
    const activeLanguage = Cookies.get("lang");
    return languages.map((language) => {
      // console.log(language.value,"lannn")
      let isActive = false;

      if (activeLanguage === undefined && language.value === "english") {
        isActive = true;
      } else {
        isActive = language.value === activeLanguage;
      }
      const style = isActive
        ? { color: "#e26640", lineHeight: "2px" }
        : { color: "black", lineHeight: "2px" };
      return (
        <a
          style={style}
          id="P9LngDdl"
          data-p9ignore="true"
          key={language.value}
          href="#"
          onClick={() => ChangeLanguage(language.value)}
        >
          {language.name}
        </a>
        // </p>
      );
    });
  };

  // function removeCookie(name:any, domainName:any) {
  //     if (domainName == '') {
  //         document.cookie = name + '="";expires=Thu, 2 Aug 2001 20:47:11 UTC;path=/';
  //     } else {
  //         document.cookie = name + '="";expires=Thu, 2 Aug 2001 20:47:11 UTC; path=/;domain=' + domainName;
  //     }
  // }
  // function getMoxCookie(key:any) {
  //     // document.cookie = "lang=en-US";

  //     console.log(key,"key@@")
  //     var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
  //     console.log(keyValue,"keyValue")
  //     return keyValue ? keyValue[2] : null;
  // }

  // function setCookie(key:any, value:any) {
  //     console.log(key,value,"cookiee")
  //     var cookieExist = getMoxCookie(key)
  //     console.log(getMoxCookie(key),cookieExist,"cookiEXIST")
  //         if (cookieExist != '') {
  //             //remove all existing cookie with 'MOXLanguage' and then set new cookie
  //             removeCookie("lang", "http://localhost:3000/");
  //         }
  //         var expires = new Date();
  //     expires.setTime(expires.getTime() + 86400000);
  //     document.cookie = key + '=' + value + '; path=/;domain=http://localhost:3000/;expires=' + expires.toUTCString();
  // }
  // function  ChangeLanguage(dropMenu:any) {
  //     console.log(dropMenu,"dropMenu")
  //     // var dropMenu = dropMenu.options[dropMenu.selectedIndex].value;
  //     if (dropMenu == "english"){
  //     console.log("hello")
  //         removeCookie("lang", "http://localhost:3000/");
  //     }
  //     else{
  //         setCookie('lang', dropMenu);
  //     // window.location.reload();
  //     }
  // }

  return (
    <header className="app-header" style={{ minHeight: "100px" }}>
      <nav className="navbar navbar-expand-lg navbar-dark fixed-top">
        <div className="container-fluid">
          <Link className="navbar-brand mr-md-auto" to="/">
            <span aria-label="SISFS Logo" />
          </Link>

          {/* @* Hamburger menu code for responsive smaller screens *@ */}
          <div
            className="navbar-toggler ml-auto"
            style={{ cursor: "pointer" }}
            id="ChangeToggle"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            {/* type="button" */}
            <div id="navbar-hamburger">
              <span className="navbar-toggler-icon">
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </span>
            </div>
            <div id="navbar-close" className="hidden">
              {/* <span className="fa fa-close"></span> */}
              <button type="button" className="close" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
          </div>

          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav m-auto">
              <li className="nav-item active">
                {/* <Link role="menuitem" className="nav-link" to="/">Home</Link> */}
                <NavLink
                  exact={true}
                  className="nav-link"
                  activeClassName="active"
                  to="/home"
                >
                  Home
                </NavLink>
              </li>
              <li role="none" className="nav-item dropdown">
                {/* <Link role="menuitem" className="nav-link" to="/about">About</Link> */}
                {/* <a role="menuitem" className="nav-link">About</a> */}
                <NavLink
                  exact={true}
                  className="nav-link"
                  activeClassName="active"
                  to="/about"
                >
                  About
                </NavLink>

                <ul className="dropdown-menu">
                  <li className="dropdown-item">
                    {/* <Link role="menuitem" to="/about">About Startup India Seed Fund Scheme</Link> */}
                    <NavLink exact={true} activeClassName="active" to="/about">
                      About Startup India Seed Fund Scheme
                    </NavLink>
                  </li>
                  <li className="dropdown-item">
                    <a
                      href="https://www.startupindia.gov.in/content/dam/invest-india/Templates/seedfund-gazette.pdf"
                      target="_blank"
                    >
                      Gazette Notification{" "}
                      <span className="arrow-link">PDF</span>
                    </a>
                  </li>
                  <li className="dropdown-item">
                    <a
                      href="https://www.startupindia.gov.in/content/dam/invest-india/Templates/public/Guidelines%20for%20Startup%20India%20Seed%20Fund%20Scheme.pdf"
                      target="_blank"
                    >
                      Guidelines for Startup India Seed Fund Scheme (English){" "}
                      <span className="arrow-link">PDF</span>
                    </a>
                  </li>
                  <li className="dropdown-item">
                    <a
                      href={
                        `${File_node_url}` +
                        "samplefiles/Guidelines_for_Startup_India_Seed_Fund_Scheme_Hindi.pdf"
                      }
                      target="_blank"
                    >
                      Guidelines for Startup India Seed Fund Scheme (Hindi){" "}
                      <span className="arrow-link">PDF</span>
                    </a>
                  </li>
                </ul>
              </li>

              {/* <li role="none" className="nav-item">
                <NavLink
                  exact={true}
                  className="nav-link"
                  activeClassName="active"
                  to="/portfolio"
                >
                  Portfolio
                </NavLink>
              </li> */}
              <li role="none" className="nav-item dropdown">
                <NavLink
                  exact={true}
                  className="nav-link"
                  activeClassName="active"
                  to=""
                >
                  Portfolios
                </NavLink>
                <ul className="dropdown-menu">
                  <li className="dropdown-item">
                    <NavLink
                      exact={true}
                      activeClassName="active"
                      to="/portfolio"
                    >
                      Incubator Portfolios
                    </NavLink>
                  </li>
                  <li className="dropdown-item">
                    <NavLink
                      exact={true}
                      activeClassName="active"
                      to="/startup_portfolio"
                    >
                      Startup Portfolios
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li role="none" className="nav-item">
                {/* <Link role="menuitem" className="nav-link" to="/faq">F.A.Q.</Link> */}
                <NavLink
                  exact={true}
                  className="nav-link"
                  activeClassName="active"
                  to="/faq"
                >
                  F.A.Q.
                </NavLink>
              </li>
              <li role="none" className="nav-item">
                {/* <Link role="menuitem" className="nav-link" to="/contact">Contact</Link> */}
                <NavLink
                  exact={true}
                  className="nav-link"
                  activeClassName="active"
                  to="/contact"
                >
                  Contact Us
                </NavLink>
              </li>
              {/* onChange={(e) => ChangeLanguage(e.target.value)} id="P9LngDdl" data-p9ignore="true" */}
              {/* <li className="language-sm" style={{cursor: 'pointer'}}>
                  <label class="cancel-btn listSmallScreen">
                 <img src="../../assets/web-icon.svg" alt=""> <span style={{font-family: 'ApexMedium'}}>
</span>
 </label>
 </li> */}
              {/* <div id="p9divmenu"> */}
              {/* <li className="language-sm" style={{cursor:"pointer"}}>

<label className="cancel-btn listSmallScreen">

<img src={web_icon} alt="" /> <span style={{fontFamily: "ApexMedium;"}}>

<select
                  onChange={e => ChangeLanguage(e.target.value)}
                  id="P9LngDdl"
                  data-p9ignore="true"
                  style={{
                    outline: 'none',
                    borderRadius: '8px',
                    fontSize: '14px',
                    padding: '3px',
                  }}>
                  <option value="english"> English</option>
                  <option value="assamese">অসমীয়া</option>
                  <option value="bengali">বাংলা</option>
                  <option value="gujarati">ગુજરાતી</option>
                  <option value="hindi">हिन्दी</option>
                  <option value="kannada">ಕನ್ನಡ</option>
                  <option value="malayalam">മലയാളം</option>
                  <option value="marathi">मराठी</option>
                  <option value="oriya">ଓଡ଼ିଆ</option>
                  <option value="punjabi">ਪੰਜਾਬੀ</option>
                  <option value="tamil">தமிழ்</option>
                  <option value="telugu">తెలుగు</option>
                </select>
</span>

                        </label>

                    </li> */}
              {/* <li
                role="none"
                className="nav-item"
                style={{marginTop: '5px'}}
                id="p9divmenu">
                

                <select
                  onChange={e => ChangeLanguage(e.target.value)}
                  id="P9LngDdl"
                  data-p9ignore="true"
                  style={{
                    outline: 'none',
                    borderRadius: '8px',
                    fontSize: '14px',
                    padding: '3px',
                  }}>
                  <option value="english"> English</option>
                  <option value="assamese">অসমীয়া</option>
                  <option value="bengali">বাংলা</option>
                  <option value="gujarati">ગુજરાતી</option>
                  <option value="hindi">हिन्दी</option>
                  <option value="kannada">ಕನ್ನಡ</option>
                  <option value="malayalam">മലയാളം</option>
                  <option value="marathi">मराठी</option>
                  <option value="oriya">ଓଡ଼ିଆ</option>
                  <option value="punjabi">ਪੰਜਾਬੀ</option>
                  <option value="tamil">தமிழ்</option>
                  <option value="telugu">తెలుగు</option>
                </select>
              </li> */}

              <li role="none" className="nav-item dropdown">
                {/* <Link role="menuitem" className="nav-link" to="/about">About</Link> */}
                {/* <a role="menuitem" className="nav-link">About</a> */}
                <NavLink
                  // exact={true}
                  className="nav-link"
                  activeClassName="active"
                  to=""
                >
                  <img src={web_icon} style={{ marginTop: "-1px" }} />
                </NavLink>

                <ul className="dropdown-menu menu11">
                  <li className="dropdown-item drop">{renderLanguageList()}</li>
                </ul>
              </li>
            </ul>

            {/* <img src={web_icon}  onClick={handleLanguageIconClick} /> */}
            {/* {showLanguageList && (
      <ul className='arrow-up'>
        
     <div className="dropdown123"  data-toggle="tooltip" data-placement="bottom">
  {renderLanguageList()}
</div>



      </ul>
    )} */}

            <div
              className="btn-group header-action-section"
              hidden={user?.accessToken ? true : false}
            >
              <button
                className="btn btn-sisfs btn-transparent-primary"
                data-toggle="modal"
                data-target="#LoginCollapse"
              >
                Login
              </button>
              {/* <Link className="btn btn-sisfs btn-transparent-primary" data-toggle="offcanvas" to={ !user.accessToken ? '/login' : '/'} /*onToggle={toggleOffcanvas} /*to="/login">Login</Link> */}

              <a
                className="btn btn-sisfs btn-yellow-primary"
                data-toggle="modal"
                data-target="#ApplyNowModal"
              >
                Apply Now
              </a>
            </div>
          </div>

          {/* <!-- Profile Section --> */}
          {user.accessToken && <ProfileContainer user={user} />}
        </div>
      </nav>

      {/* Offcanvas for Login screen */}
      {!user.accessToken && <LoginComponent />}
      {/* Offcanvas for Login screen */}

      <ApplyNowModalComponent />
    </header>
  );
}

export default HeaderComponent;
