import { useEffect, useState } from "react";
import { IncubatorPreferenceModel } from "../../../models/startup/IncubatorPreferenceModel";
import { initEntityDetail, initIncubatorPreference } from "../../../models/startup/StartupModel";
import { requestType, StepperStartupEnum } from "../../../utils/Enums";
import { useErrorStateValue } from "../../../providers/ErrorProvider";
import { get } from "../../../effects/api/api.common";
import { STARTUP_GET_INCUBATORS } from "../../../constants/api";
import ValidationMessage from "../../common/controls/ValidationMessage";
import { useHistory } from "react-router-dom";
import flashIcon from '../../../images/flashIcon.png';
interface IIncubatorPreferenceProps {
    stepper: any;
    incubatorPreferenceModel: IncubatorPreferenceModel;
    tabIndex: number;
    onStepSave: (incubatorPreference: IncubatorPreferenceModel, stepIndex: number, request: number) => void;
    onChildUpdate: (incubatorPreference: IncubatorPreferenceModel) => void;
    onPreviousClick: () => void;
    errors: Array<string>;
    applicationId: string;
    apiCalled: Boolean;
    incubatorList: any;
    allowInstrument: string;
    isDisabled: Boolean;
}

interface IIncubatorPreferenceState { }

/** Render start-up application screen */
function IncubatorPreferenceComponent(props: IIncubatorPreferenceProps) {

    const { stepper, onChildUpdate, incubatorPreferenceModel, applicationId, apiCalled, incubatorList, allowInstrument, isDisabled } = props;

    // @ts-ignore
    const [{ errors }, dispatchError] = useErrorStateValue();

    const [incubatorPreference, setIncubatorPreference] = useState<IncubatorPreferenceModel>(
        initIncubatorPreference
    );

    const [incubatorPreferenceList, setIncubatorPreferenceList] = useState([]);
    const [fieldsetDisabled, setFieldsetDisabled] = useState<any>(false);


    useEffect(() => {
        console.log(incubatorPreferenceModel, props, "propssssssssssss")
        setIncubatorPreference(incubatorPreferenceModel);
        setFieldsetDisabled(isDisabled)
        let sortIncubatorList = incubatorList.sort((a: any, b: any) => a.registered_name.localeCompare(b.registered_name));
        if (applicationId !== "")
            // getAllIncubators(applicationId);
            setIncubatorPreferenceList(sortIncubatorList)
    }, [applicationId, apiCalled, incubatorList]);

    // // fetch all the Incubator Preference
    // const getAllIncubators = (id: string) => {

    //     console.log("Application", applicationId);
    //     get(`${STARTUP_GET_INCUBATORS}/${applicationId}/incubators`).then((response: any) => {
    //         if (!response || !response?.data) {
    //             return;
    //         }

    //         console.log("response", response);
    //         if(response?.data?.result?.status!=false){
    //         const preferenceList = response?.data?.result?.data?.map((item: any) => ({
    //             id: item?.user_id,
    //             // registered_name: item?.registered_name,
    //             incubator_name: item?.incubator_name
    //         }));
    //         // const preferenceList: any = [
    //         //     // {
    //         //     //     id: 1,
    //         //     //     registered_name: "Test1",
    //         //     //     incubator_name: "incubatorname1"
    //         //     // },
    //         //     // {
    //         //     //     id: 2,
    //         //     //     registered_name: "Test2",
    //         //     //     incubator_name: "incubatorname2"
    //         //     // },
    //         //     // {
    //         //     //     id: 3,
    //         //     //     registered_name: "Test3",
    //         //     //     incubator_name: "incubatorname3"
    //         //     // },
    //         //     // {
    //         //     //     id: 4,
    //         //     //     registered_name: "Test4",
    //         //     //     incubator_name: "incubatorname4"
    //         //     // }
    //         // ]
    //         setIncubatorPreferenceList(preferenceList);
    //     }
    //     });
    // };



    var removeByAttr = function (arr: any, attr: any, value: any) {
        var i = arr.length;
        while (i--) {
            if (arr[i]
                && arr[i].hasOwnProperty(attr)
                && (arguments.length > 2 && arr[i][attr] === value)) {

                arr.splice(i, 1);

            }
        }
        return arr;
    }

    const history = useHistory();

    const applicationPage = () => {
        history.push({
            pathname: "/startup_status",
        });
    }

    // triggers whenever user makes any changes in the form for select and input type
    const handleChange = (
        evt: React.ChangeEvent<
            HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
        >
    ) => {
        const name = evt.target.name;
        const value = evt.target.value;

        // setIncubatorPreference((prevState: IncubatorPreferenceModel) => {
        //     return {
        //         ...prevState,
        //         [name]: value ? value : "",
        //     };
        // });
        let updatedIncubatorPreference: IncubatorPreferenceModel = initIncubatorPreference;

        updatedIncubatorPreference = {
            ...incubatorPreference,
            [name]: value,
        };

        setLocalStateAndUpdateParent(updatedIncubatorPreference);
    };

    // set local state and update parent component
    const setLocalStateAndUpdateParent = (
        updatedIncubatorPreference: IncubatorPreferenceModel
    ) => {
        setIncubatorPreference(updatedIncubatorPreference);

        setTimeout(() => {
            onChildUpdate(updatedIncubatorPreference);
        }, 0);
    };

    return (
        <>
            <fieldset disabled={fieldsetDisabled}>
                <div className="form-content">
                    <div className="form-group row p-4" style={{backgroundColor:'rgb(248, 216, 116)',borderRadius:'10px'}}>
                    <img style={{width:'70px'}} src={flashIcon} alt="" /> <br />
                            <span style={{fontWeight:'600',fontSize:'13px',textAlign:'justify'}}>
                               <p className="mb-2">Please note that once your application is submitted, you cannot modify your incubator preferences, or the amount and instrument of funding requested. If your application is selected by more than one preferred incubator,  it will be allocated to the highest preferred incubator that you selected and cannot be shifted to another incubator.</p>
                               <p>
                                Before submitting the application, we encourage startups to carefully review our portfolio page at &nbsp;
                                <a href="https://seedfund.startupindia.gov.in/portfolio"><u className="link-orange-text">https://seedfund.startupindia.gov.in/portfolio</u></a>

                                . This will help applicant startups to make an informed decision about the incubator preferences based on relevant parameters such as sector alignment, available funding balance, city/state, or any other relevant parameters. You may also contact the incubators directly using the contact details provided in the portfolio selection.</p>


                            </span>
                        

                    </div>
                    <div className="form-group required row">
                        <div className="col-sm-4 ">
                            <label className="col-form-label">
                                Incubator Preference # 1
                            </label>

                        </div>
                        {console.log(incubatorPreference, "incuuubator")}
                        <div className="col-sm-8">

                            <div className="select-custom">
                                <select
                                    aria-label="Select Preference 1"
                                    id="incubator_preference_1"
                                    name="incubator_preference_1"
                                    className={
                                        errors &&
                                            errors.IncubatorPreference &&
                                            errors.IncubatorPreference["incubator_preference_1"].length > 0
                                            ? "form-control error"
                                            : "form-control"
                                    }
                                    value={incubatorPreference.incubator_preference_1}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Preference</option>
                                    {incubatorPreferenceList.map(({ id, registered_name, incubator_preference_name }) => (
                                        <option key={id} value={id}>
                                            {(incubator_preference_name != null && incubator_preference_name != 'NA') ? incubator_preference_name : registered_name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {errors &&
                                errors.IncubatorPreference &&
                                errors.IncubatorPreference["incubator_preference_1"].length > 0 && (
                                    <ValidationMessage
                                        className="error"
                                        message={errors.IncubatorPreference["incubator_preference_1"]}
                                    />
                                )}

                            {
                                incubatorPreferenceList.length === 0 && allowInstrument != "" &&
                                <small >
                                    <span className="red-text text-justify">Technical Error: The incubator list is not available, please follow the below steps:</span>
                                    <ul className="red-text text-justify">
                                        <li>Make sure that in the application form, all the required fields are filled, and please click on ‘Save & Next’ to move to the next page of the application.</li>
                                        <li>In case you have left some details blank in previous sections, please go back to the application form to fill in the required details and click on ‘Save & Next’ for all pages.</li>
                                        <li>Please not while selecting the funding instrument as ‘Grant’, the amount requested should be less than or equal to Rs. 20 Lakhs, and while selecting the funding instrument as ‘Debt/ Debenture’, the amount requested should be less than or equal to Rs. 50 Lakhs.</li>
                                    </ul>
                                    {/* All the incubators have exhausted corpus to be given as grant.
                                    Only startups in commercialization or scale up stage can apply
                                    to a debt or convertible debenture fund */}
                                </small>
                            }
                            {
                                allowInstrument == "" &&
                                <small className="red-text">
                                    Please select Funding Instrument applying for under current funding details and click on "Save and Next" button to view the incubator preference list.
                                </small>
                            }
                        </div>
                    </div>


                    <div className="form-group required row">
                        <div className="col-sm-4 ">
                            <label className="col-form-label">
                                Incubator Preference # 2
                            </label>
                        </div>

                        <div className="col-sm-8">
                            <div className="select-custom">
                                <select
                                    className={
                                        errors &&
                                            errors.IncubatorPreference && typeof errors.IncubatorPreference["incubator_preference_2"] != "undefined" &&
                                            errors.IncubatorPreference["incubator_preference_2"].length > 0
                                            ? "form-control error"
                                            : "form-control"
                                    }
                                    aria-label="Select Preference 2"
                                    id="incubator_preference_2"
                                    name="incubator_preference_2"
                                    value={incubatorPreference.incubator_preference_2}
                                    onChange={handleChange}
                                    disabled={!(incubatorPreferenceList.length > 1)}
                                >
                                    <option value="">Select Preference</option>
                                    {incubatorPreferenceList.filter((item: any) => item.id !== Number(incubatorPreference.incubator_preference_1)).map(({ id, registered_name, incubator_preference_name }) => (

                                        <option key={id} value={id}>
                                            {(incubator_preference_name != null && incubator_preference_name != 'NA') ? incubator_preference_name : registered_name}
                                        </option>
                                    ))}

                                </select>
                            </div>
                            {errors &&
                                errors.IncubatorPreference && typeof errors.IncubatorPreference["incubator_preference_2"] != "undefined" &&
                                errors.IncubatorPreference["incubator_preference_2"].length > 0 && (
                                    <ValidationMessage
                                        className="error"
                                        message={errors.IncubatorPreference["incubator_preference_2"]}
                                    />
                                )}
                        </div>
                    </div>



                    <div className="form-group required row">
                        <div className="col-sm-4">
                            <label className="col-form-label">
                                Incubator Preference # 3
                            </label>
                        </div>

                        <div className="col-sm-8">
                            <div className="select-custom">
                                <select
                                    className={
                                        errors &&
                                            errors.IncubatorPreference && typeof errors.IncubatorPreference["incubator_preference_3"] != "undefined" &&
                                            errors.IncubatorPreference["incubator_preference_3"].length > 0
                                            ? "form-control error"
                                            : "form-control"
                                    }
                                    aria-label="Select Preference 3"
                                    id="incubator_preference_3"
                                    name="incubator_preference_3"
                                    disabled={!(incubatorPreferenceList.length > 2)}
                                    value={incubatorPreference.incubator_preference_3}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Preference</option>
                                    {incubatorPreferenceList.filter((item: any) => item.id !== Number(incubatorPreference.incubator_preference_2) && item.id !== Number(incubatorPreference.incubator_preference_1)).map(({ id, registered_name, incubator_preference_name }) => (

                                        <option key={id} value={id}>
                                            {(incubator_preference_name != null && incubator_preference_name != 'NA') ? incubator_preference_name : registered_name}
                                        </option>
                                    ))}

                                </select>
                            </div>
                            {errors &&
                                errors.IncubatorPreference && typeof errors.IncubatorPreference["incubator_preference_3"] != "undefined" &&
                                errors.IncubatorPreference["incubator_preference_3"].length > 0 && (
                                    <ValidationMessage
                                        className="error"
                                        message={errors.IncubatorPreference["incubator_preference_3"]}
                                    />
                                )}
                        </div>
                    </div>


                </div>
                <div className="stepper-footer-section">
                    <div
                        className="btn-toolbar justify-content-between"
                        role="toolbar"
                        aria-label="Toolbar with button groups"
                    >
                        <button className="btn btn-sisfs btn-transparent-primary" onClick={() => applicationPage()}>
                            Cancel
                        </button>
                        <span className="stepper-page-count">Step {props.tabIndex} / 7 </span>
                        <div className="btn-group">
                            {props.tabIndex != 1 && (
                                <button
                                    className="btn btn-sisfs btn-transparent-primary"
                                    onClick={props.onPreviousClick}
                                >
                                    Previous
                                </button>
                            )}
                            <button
                                type="button"
                                className="btn btn-sisfs btn-yellow-primary"
                                onClick={() =>
                                    props.onStepSave(incubatorPreference, StepperStartupEnum.IncubatorPreference, requestType.save)
                                }
                            >
                                {props.tabIndex == 7 ? "Save" : "Save And Next"}
                            </button>
                            {props.tabIndex == 7 && (
                                <button
                                    className="btn btn-sisfs btn-yellow-primary"
                                // onClick={() => props.onSubmit}                                                   
                                >
                                    Review & Submit
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </fieldset>
        </>
    )
}

export default IncubatorPreferenceComponent;