import React, { Fragment, useEffect, useState } from "react";
import filter from "../../../src/images/filter.svg";
import "../../styles/component.css";
import { Link } from "react-router-dom";
import { get, post } from "../../effects/api/api.common";
import {
  Node_GET_PORTFOLIO,
  NODE_URL,
  GET_PORTFOLIO_LIST,
  GET_ALL_STATES,
  GET_ALL_SECTOR,
  GET_CITIES,
  CONTACT_STATE,
  PORTFOLIO_FILTER,
} from "../../constants/api";
import {
  changeNumberFormat,
  changeNumberFormat1,
  filterByValue,
  filterByValue1,
  showEllipsisText,
} from "../../utils/CommonMethods";
import search from "../../../src/images/search.svg";
import MultiSelect from "../common/controls/MultiSelect";
import { DropdownModel } from "../../models/DropdownModel";
import { CONTACT_CITY } from "../../constants/dist/api";
import sort from "../../images/Sort.svg";
import { getSessionStorageValue } from "../../effects/utils/session-storage";
import { userInfo } from "os";
const Portfolio = () => {
  const [data, setData] = useState<any[]>([]);
  const [portfolioList, setPortfolioList] = useState([]);
  const [stateDistricts, setStateDistricts] = useState([]);
  const [countryStates, setCountryStates] = useState([]);
  const [sectorDetails, setSectorDetails] = useState([]);
  const [selectedStateValue, setStateValue] = useState<Array<any>>([]);
  const [showFilterDot, setShowFilterDot] = useState(false);
  const [showSortDot, setShowSortDot] = useState(false);
  const [selectedSectorValue, setSectorValue] = useState<Array<any>>([]);
  const [selectedCityValue, setCityValue] = useState<Array<any>>([]);
  const [availValue, setavailValue] = useState(0);
  const [grantValue, setgrantValue] = useState(0);
  const [debtValue, setdebtValue] = useState(0);
  const [schemeValue, setSchemeValue] = useState(0);
  const [schemeCheck, setSchemeCheck] = useState(false);
  const [schemeCheck1, setSchemeCheck1] = useState(false);
  const [loader, setLoader] = useState(false);
  const [sortOrder, setSortOrder] = useState<any | null>(() => {
    const sortedValue = localStorage.getItem("sortedValue");
    if (sortedValue !== null) {
      setShowSortDot(true);
      return Number(sortedValue);
    }
    setShowSortDot(false);
    return null;
  });
  const [userInfo, setUserInfo] = useState({
    id: "",
    roles: "",
  });
  var showPayload: any;
  const [filterBtn, setFilterBtn] = useState(0);
  const [dataForSort, setDataForSort] = useState<any>();
  const [newDataForSort, setNewDataForSort] = useState<any>();
  const [showNoData, setShowNoData] = useState(false);
  const initialValueFromLocalStorage = localStorage.getItem("INCsearchTerm");
  const [inputValue, setInputValue] = useState<any | null>(
    initialValueFromLocalStorage
  );
  const getPortfolio = () => {
    // console.log("Hellooooo")
    // return
    setLoader(true);
    get(GET_PORTFOLIO_LIST).then((response: any) => {
      setLoader(false);
      // console.log("Hellooooo1")
      // console.log(response);
      if (!response || !response.data) return;
      if (response?.data?.result === "success") {
        setShowNoData(false);
        const APIData = response.data.data;
        const sectors: any = [];

        APIData.forEach((element: any) => {
          const Sectorsarray = element.sectors.split(",");
          sectors.push({
            Id: element.Id,
            sectors: Sectorsarray,
            state: element.state,
            city: element.city,
            image: element.image,
            incubator_name: element.incubator_name,
            description: element.description,
            incubatorID: element.incubator_user_id,
            evaluationRate: element.Evaluation,
          });
        });
        setData(sectors);
      } else {
        setData([]);
      }
    });
  };
  const onMultiSelectChange = (selectedIds: Array<string>, name: string) => {
    // console.log(selectedIds);
    if (name == "state") {
      setStateValue(selectedIds);
      // console.log(selectedStateValue, "idddd");

      // getAllDistrictsByStateId(selectedIds);
    } else if (name == "sector") {
      setSectorValue(selectedIds);
    } else if (name == "city") {
      setCityValue(selectedIds);
    }
  };
  const onMultiSelectChange1 = (selectedIds: Array<string>, name: string) => {
    // console.log(selectedIds.length)
    const Ids = selectedIds;
    let cities = Ids.join(",");
    var payload = {
      stateId: cities.length == 0 ? 0 : cities,
    };
    // if (payload.stateId == "") {
    //   return;
    // } else {
    post(GET_CITIES, payload).then((res: any) => {
      if (!res.data || !res.data.data) {
        setStateDistricts([]);
        return;
      } else {
        const districts = res.data?.data.map((item: any): DropdownModel => {
          return {
            text: item.city_name,
            id: item.city_id.toString(),
            translation_text: "",
          };
        });
        setStateDistricts(districts);
      }
    });
    // }

    if (name == "state") {
      setStateValue(selectedIds);
      // getAllDistrictsByStateId(selectedIds);
    }
  };
  useEffect(() => {}, [selectedStateValue, selectedSectorValue, showNoData]);
  useEffect(() => {
    let payloadData = localStorage.getItem("payload");
    // console.log(payloadData,"payloaddddddddd")
    // console.log(filterBtn,"payloaddddddddd")

    if (payloadData !== null) {
      const parsedData = JSON.parse(payloadData);
      setavailValue(parsedData.total_balance);
      setgrantValue(parsedData.grant_balance);
      setSectorValue(parsedData.sectors);
      setStateValue(parsedData.states);
      setCityValue(parsedData.cities);
      onMultiSelectChange1(parsedData.states, "state");
    }
    if (payloadData == null || payloadData == undefined) {
      //  getPortfolio();
      var payload = {
        total_balance: availValue,
        grant_balance: grantValue,
        sectors: selectedSectorValue,
        states: selectedStateValue,
        cities: selectedCityValue,
      };
      setLoader(true);
      post(PORTFOLIO_FILTER, payload).then((response: any) => {
        localStorage.setItem("payload", JSON.stringify(payload));
        setLoader(false);
        if (!response || !response.data) return;
        if (response?.data?.result === "success") {
          var filterDataa: any;
          const searchTerm = localStorage.getItem("INCsearchTerm");
          if (searchTerm) {
            filterDataa = filterByValue1(response.data.data, searchTerm);
            setShow(false);
          }
          const APIData = filterDataa || response.data.data;
          var APIData1: any;
          if (sortOrder !== null) {
            if (sortOrder == 1) {
              APIData1 = APIData.sort(
                (a: any, b: any) =>
                  a.totalRemainingAmount - b.totalRemainingAmount
              );
            } else if (sortOrder == 2) {
              APIData1 = APIData.sort(
                (a: any, b: any) =>
                  b.totalRemainingAmount - a.totalRemainingAmount
              );
            } else if (sortOrder == 3) {
              APIData1 = APIData.sort(
                (a: any, b: any) =>
                  a.totalGrantremainingAmount - b.totalGrantremainingAmount
              );
            } else if (sortOrder == 4) {
              APIData1 = APIData.sort(
                (a: any, b: any) =>
                  b.totalGrantremainingAmount - a.totalGrantremainingAmount
              );
            } else if (sortOrder == 5) {
              APIData1 = APIData.sort(
                (a: any, b: any) => a.Evaluation - b.Evaluation
              );
            } else if (sortOrder == 6) {
              APIData1 = APIData.sort(
                (a: any, b: any) => b.Evaluation - a.Evaluation
              );
            }
            if (APIData1.length == 0) {
              setShowNoData(true);
            } else {
              setShowNoData(false);
            }
            const sectors: any = [];
            APIData1.forEach((element: any) => {
              const Sectorsarray = element.sectors.split(",");
              sectors.push({
                Id: element.Id,
                sectors: Sectorsarray,
                state: element.state,
                city: element.city,
                image: element.image,
                incubator_name: element.incubator_name,
                description: element.description,
                incubatorID: element.incubator_user_id,
                evaluationRate: element.Evaluation,
              });
            });
            setData(sectors);
            // console.log("11111111111111111111111", "dattaaarr")
          } else {
            if (APIData.length == 0) {
              setShowNoData(true);
            } else {
              setShowNoData(false);
            }
            const sectors: any = [];
            APIData.forEach((element: any) => {
              const Sectorsarray = element.sectors.split(",");
              sectors.push({
                Id: element.Id,
                sectors: Sectorsarray,
                state: element.state,
                city: element.city,
                image: element.image,
                incubator_name: element.incubator_name,
                description: element.description,
                incubatorID: element.incubator_user_id,
                evaluationRate: element.Evaluation,
              });
            });
            setData(sectors);
            // console.log("222222222222", "dattaaarr")
          }
        } else {
          setData([]);
        }
      });
    } else {
      // setLoader(true);
      showPayload = payloadData;
      if (
        showPayload.total_balance == 0 &&
        showPayload.grant_balance == 0 &&
        showPayload.sectors.length == 0 &&
        showPayload.states.length == 0 &&
        showPayload.cities.length == 0
      ) {
        setShowFilterDot(false);
      } else {
        setShowFilterDot(true);
      }
      applyFilter(showPayload);
    }

    getAllStates();
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    setUserInfo(userInfo);
    // getAllDistrictsByStateId("6");
  }, []);

  const getAllStates = () => {
    get(CONTACT_STATE).then((response: any) => {
      if (!response || !response.data) return;
      const states = response.data.data.map((item: any): DropdownModel => {
        return {
          text: item.name,
          id: item.id.toString(),
          translation_text: "",
        };
      });
      setCountryStates(states);
      getAllSectors();
    });
  };
  const getAllDistrictsByStateId = (stateId: any) => {
    get(`${CONTACT_CITY}/${stateId}`).then((response: any) => {
      if (!response || !response?.data?.data) {
        setStateDistricts([]);
        return;
      }
    });
  };
  // get all sectors in dropdown
  const getAllSectors = () => {
    // const param = sectorDetails.length == 0 ? '0' : sectorDetails
    get(`${GET_ALL_SECTOR}/0`).then((response: any) => {
      if (!response || !response.data) return;
      const sectors = response.data.data.map((item: any): DropdownModel => {
        return {
          text: item.attribute_value,
          id: item.id.toString(),
          translation_text: "",
        };
      });
      setSectorDetails(sectors);
    });
  };

  const handleSort = (value: any) => {
    setSortOrder(value);
  };
  const applyFilter = (object: any) => {
    // console.log(object)

    setFilterBtn(1);
    var payload: any;
    if (object != null || object != undefined) {
      // console.log("ifffff")
      const newData = JSON.parse(object);
      payload = {
        total_balance: newData?.total_balance
          ? Number(newData.total_balance)
          : Number(availValue),
        grant_balance: newData?.grant_balance
          ? Number(newData.grant_balance)
          : Number(grantValue),
        sectors: newData?.sectors ? newData.sectors : selectedSectorValue,
        states: newData?.states ? newData.states : selectedStateValue,
        cities: newData?.cities ? newData.cities : selectedCityValue,
      };
    } else {
      payload = {
        total_balance: availValue,
        grant_balance: grantValue,
        sectors: selectedSectorValue,
        states: selectedStateValue,
        cities: selectedCityValue,
      };
    }
    if (
      payload.total_balance == 0 &&
      payload.grant_balance == 0 &&
      payload.sectors.length == 0 &&
      payload.states.length == 0 &&
      payload.cities.length == 0
    ) {
      setShowFilterDot(false);
    } else {
      setShowFilterDot(true);
    }
    setLoader(true);
    post(PORTFOLIO_FILTER, payload).then((response: any) => {
      localStorage.setItem("payload", JSON.stringify(payload));
      setLoader(false);
      if (!response || !response.data) return;
      if (response?.data?.result === "success") {
        var APIData: any;
        var filterDataa: any;
        const searchTerm = localStorage.getItem("INCsearchTerm");
        if (searchTerm) {
          filterDataa = filterByValue1(response.data.data, searchTerm);
          setShow(false);
        }
        if (payload.total_balance == 0 && payload.grant_balance == 0) {
          APIData = filterDataa || response.data.data;
          setDataForSort(APIData);
          if (APIData.length == 0) {
            setShowNoData(true);
          } else {
            setShowNoData(false);
          }
        } else if (payload.total_balance > 0 && payload.grant_balance == 0) {
          setShowFilterDot(true);
          APIData = (filterDataa || response.data.data).filter(
            (item: any) => item.totalRemainingAmount >= payload.total_balance
          );
          // }
          setDataForSort(APIData);
          if (APIData.length == 0) {
            setShowNoData(true);
          } else {
            setShowNoData(false);
          }
          // APIData = data.sort((a:any, b:any) => b.totalRemainingAmount - a.totalRemainingAmount);
        } else if (payload.total_balance == 0 && payload.grant_balance > 0) {
          setShowFilterDot(true);
          APIData = (filterDataa || response.data.data).filter(
            (item: any) =>
              item.totalGrantremainingAmount >= payload.grant_balance
          );
          setDataForSort(APIData);
          if (APIData.length == 0) {
            setShowNoData(true);
          } else {
            setShowNoData(false);
          }
          // APIData = data.sort((a:any, b:any) => b.totalGrantremainingAmount - a.totalGrantremainingAmount);
        } else if (payload.total_balance > 0 && payload.grant_balance > 0) {
          setShowFilterDot(true);
          APIData = (filterDataa || response.data.data).filter(
            (item: any) =>
              item.totalRemainingAmount >= payload.total_balance &&
              item.totalGrantremainingAmount >= payload.grant_balance
          );
          setDataForSort(APIData);
          if (APIData.length == 0) {
            setShowNoData(true);
          } else {
            setShowNoData(false);
          }
          // APIData = data.sort((a:any, b:any) =>(b.totalRemainingAmount - a.totalRemainingAmount) && (b.totalGrantremainingAmount - a.totalGrantremainingAmount));
        } else {
          APIData = filterDataa || response.data.data;

          setDataForSort(APIData);
          if (APIData.length == 0) {
            setShowNoData(true);
          } else {
            setShowNoData(false);
          }
          setShowFilterDot(false);
        }
        setNewDataForSort(APIData);
        let sortedData = localStorage.getItem("sortedValue");
        var APIData1: any;
        if (sortOrder !== null) {
          if (sortOrder == 1) {
            APIData1 = APIData.sort(
              (a: any, b: any) =>
                a.totalRemainingAmount - b.totalRemainingAmount
            );
          } else if (sortOrder == 2) {
            APIData1 = APIData.sort(
              (a: any, b: any) =>
                b.totalRemainingAmount - a.totalRemainingAmount
            );
          } else if (sortOrder == 3) {
            APIData1 = APIData.sort(
              (a: any, b: any) =>
                a.totalGrantremainingAmount - b.totalGrantremainingAmount
            );
          } else if (sortOrder == 4) {
            APIData1 = APIData.sort(
              (a: any, b: any) =>
                b.totalGrantremainingAmount - a.totalGrantremainingAmount
            );
          } else if (sortOrder == 5) {
            APIData1 = APIData.sort(
              (a: any, b: any) => a.Evaluation - b.Evaluation
            );
          } else if (sortOrder == 6) {
            APIData1 = APIData.sort(
              (a: any, b: any) => b.Evaluation - a.Evaluation
            );
          }
          const sectors: any = [];

          APIData1.forEach((element: any) => {
            const Sectorsarray = element.sectors.split(",");
            sectors.push({
              Id: element.Id,
              sectors: Sectorsarray,
              state: element.state,
              city: element.city,
              image: element.image,
              incubator_name: element.incubator_name,
              description: element.description,
              incubatorID: element.incubator_user_id,
              evaluationRate: element.Evaluation,
            });
          });
          setData(sectors);
          // console.log("333333333333333333333", "dattaaarr")
        } else {
          if (APIData.length == 0) {
            setShowNoData(true);
          } else {
            setShowNoData(false);
          }
          const sectors: any = [];
          APIData.forEach((element: any) => {
            const Sectorsarray = element.sectors.split(",");
            sectors.push({
              Id: element.Id,
              sectors: Sectorsarray,
              state: element.state,
              city: element.city,
              image: element.image,
              incubator_name: element.incubator_name,
              description: element.description,
              incubatorID: element.incubator_user_id,
              evaluationRate: element.Evaluation,
            });
          });
          // console.log(sectors, "dattaaarr")
          setData(sectors);
          // console.log("4444444444444444444", "dattaaarr")
        }
      } else {
        setData([]);
      }
    });
  };
  const applySort = () => {
    if (sortOrder !== null) {
      localStorage.setItem("sortedValue", sortOrder);
    }
    if (filterBtn == 1) {
      var APIData: any;
      if (sortOrder !== null) {
        setShowSortDot(true);
      }
      if (sortOrder == 1) {
        APIData = dataForSort.sort(
          (a: any, b: any) => a.totalRemainingAmount - b.totalRemainingAmount
        );
      } else if (sortOrder == 2) {
        APIData = dataForSort.sort(
          (a: any, b: any) => b.totalRemainingAmount - a.totalRemainingAmount
        );
      } else if (sortOrder == 3) {
        APIData = dataForSort.sort(
          (a: any, b: any) =>
            a.totalGrantremainingAmount - b.totalGrantremainingAmount
        );
      } else if (sortOrder == 4) {
        APIData = dataForSort.sort(
          (a: any, b: any) =>
            b.totalGrantremainingAmount - a.totalGrantremainingAmount
        );
      } else if (sortOrder == 5) {
        APIData = dataForSort.sort(
          (a: any, b: any) => a.Evaluation - b.Evaluation
        );
      } else if (sortOrder == 6) {
        APIData = dataForSort.sort(
          (a: any, b: any) => b.Evaluation - a.Evaluation
        );
      }

      const sectors: any = [];
      APIData.forEach((element: any) => {
        const Sectorsarray = element.sectors.split(",");
        sectors.push({
          Id: element.Id,
          sectors: Sectorsarray,
          state: element.state,
          city: element.city,
          image: element.image,
          incubator_name: element.incubator_name,
          description: element.description,
          incubatorID: element.incubator_user_id,
          evaluationRate: element.Evaluation,
        });
      });
      setData(sectors);
      // console.log("55555555555555555", "dattaaarr")
    } else {
      var payload = {
        total_balance: 0,
        grant_balance: 0,
        sectors: [],
        states: [],
        cities: [],
      };
      setLoader(true);
      post(PORTFOLIO_FILTER, payload).then((response: any) => {
        setLoader(false);
        if (!response || !response.data) return;
        if (response?.data?.result === "success") {
          var APIData: any;
          var filterDataa: any;
          const searchTerm = localStorage.getItem("INCsearchTerm");
          if (searchTerm) {
            filterDataa = filterByValue1(response.data.data, searchTerm);
            setShow(false);
          }
          if (sortOrder !== null) {
            setShowSortDot(true);
          }
          if (sortOrder == 1) {
            APIData = (filterDataa || response.data.data).sort(
              (a: any, b: any) =>
                a.totalRemainingAmount - b.totalRemainingAmount
            );
          } else if (sortOrder == 2) {
            APIData = (filterDataa || response.data.data).sort(
              (a: any, b: any) =>
                b.totalRemainingAmount - a.totalRemainingAmount
            );
          } else if (sortOrder == 3) {
            APIData = (filterDataa || response.data.data).sort(
              (a: any, b: any) =>
                a.totalGrantremainingAmount - b.totalGrantremainingAmount
            );
          } else if (sortOrder == 4) {
            APIData = (filterDataa || response.data.data).sort(
              (a: any, b: any) =>
                b.totalGrantremainingAmount - a.totalGrantremainingAmount
            );
          } else if (sortOrder == 5) {
            APIData = (filterDataa || response.data.data).sort(
              (a: any, b: any) => a.Evaluation - b.Evaluation
            );
          } else if (sortOrder == 6) {
            APIData = (filterDataa || response.data.data).sort(
              (a: any, b: any) => b.Evaluation - a.Evaluation
            );
          }
          // console.log(APIData)
          const sectors: any = [];
          APIData.forEach((element: any) => {
            const Sectorsarray = element.sectors.split(",");
            sectors.push({
              Id: element.Id,
              sectors: Sectorsarray,
              state: element.state,
              city: element.city,
              image: element.image,
              incubator_name: element.incubator_name,
              description: element.description,
              incubatorID: element.incubator_user_id,
              evaluationRate: element.Evaluation,
            });
          });
          setData(sectors);
          // console.log("6666666666666666", "dattaaarr")
        } else {
          setData([]);
        }
      });
    }
  };
  const resetSort = () => {
    setSortOrder(null);
    applyFilter(showPayload);
    localStorage.removeItem("sortedValue");
    setShowSortDot(false);
  };
  const resetFilter = () => {
    setavailValue(0);
    setgrantValue(0);
    setSectorValue([]);
    setStateValue([]);
    setCityValue([]);
    setFilterBtn(0);

    setShowFilterDot(false);
    localStorage.removeItem("payload");
    var payload = {
      total_balance: 0,
      grant_balance: 0,
      sectors: [],
      states: [],
      cities: [],
    };
    setLoader(true);
    post(PORTFOLIO_FILTER, payload).then((response: any) => {
      localStorage.setItem("payload", JSON.stringify(payload));
      setLoader(false);
      if (!response || !response.data) return;
      if (response?.data?.result === "success") {
        var filterDataa: any;
        const searchTerm = localStorage.getItem("INCsearchTerm");
        if (searchTerm) {
          filterDataa = filterByValue1(response.data.data, searchTerm);
          setShow(false);
        }
        const APIData = filterDataa || response.data.data;
        var APIData1: any;
        if (sortOrder !== null) {
          if (sortOrder == 1) {
            APIData1 = APIData.sort(
              (a: any, b: any) =>
                a.totalRemainingAmount - b.totalRemainingAmount
            );
          } else if (sortOrder == 2) {
            APIData1 = APIData.sort(
              (a: any, b: any) =>
                b.totalRemainingAmount - a.totalRemainingAmount
            );
          } else if (sortOrder == 3) {
            APIData1 = APIData.sort(
              (a: any, b: any) =>
                a.totalGrantremainingAmount - b.totalGrantremainingAmount
            );
          } else if (sortOrder == 4) {
            APIData1 = APIData.sort(
              (a: any, b: any) =>
                b.totalGrantremainingAmount - a.totalGrantremainingAmount
            );
          } else if (sortOrder == 5) {
            APIData1 = APIData.sort(
              (a: any, b: any) => a.Evaluation - b.Evaluation
            );
          } else if (sortOrder == 6) {
            APIData1 = APIData.sort(
              (a: any, b: any) => b.Evaluation - a.Evaluation
            );
          }
          if (APIData1.length == 0) {
            setShowNoData(true);
          } else {
            setShowNoData(false);
          }
          const sectors: any = [];
          APIData1.forEach((element: any) => {
            const Sectorsarray = element.sectors.split(",");
            sectors.push({
              Id: element.Id,
              sectors: Sectorsarray,
              state: element.state,
              city: element.city,
              image: element.image,
              incubator_name: element.incubator_name,
              description: element.description,
              incubatorID: element.incubator_user_id,
              evaluationRate: element.Evaluation,
            });
          });

          setData(sectors);
          // console.log("7777777777777777777", "dattaaarr")
        } else {
          if (APIData.length == 0) {
            setShowNoData(true);
          } else {
            setShowNoData(false);
            setShow(false);
          }
          const sectors: any = [];
          APIData.forEach((element: any) => {
            const Sectorsarray = element.sectors.split(",");
            sectors.push({
              Id: element.Id,
              sectors: Sectorsarray,
              state: element.state,
              city: element.city,
              image: element.image,
              incubator_name: element.incubator_name,
              description: element.description,
              incubatorID: element.incubator_user_id,
              evaluationRate: element.Evaluation,
            });
          });
          setData(sectors);
          // console.log("888888888888888888888", "dattaaarr")
        }
      } else {
        setData([]);
      }
    });
  };
  useEffect(() => {
    console.log("dataa");
  }, [data]);
  useEffect(() => {}, [filterBtn]);
  const [show, setShow] = useState(false);
  const searchList = (e: any) => {
    const filteredData = filterByValue1(data, e.target.value);
    const searchValue = e.target.value;
    setInputValue(e.target.value);
    setShow(true);
    setPortfolioList(filteredData);
    if (searchValue !== "") {
      localStorage.setItem("INCsearchTerm", searchValue);
    } else if (searchValue == "") {
      localStorage.removeItem("INCsearchTerm");
      setShow(false);
      applyFilter(showPayload);
    } else {
      /////
    }
  };

  const handleChange = (e: any) => {
    if (e.target.name == "old_new") {
      setSchemeCheck(true);
      setSchemeCheck1(false);
      setSchemeValue(1);
    } else if (e.target.name == "new_old") {
      setSchemeCheck(false);
      setSchemeCheck1(true);
      setSchemeValue(2);
    }
    setavailValue(e.target.value);
  };
  const handleChange1 = (e: any) => {
    setgrantValue(e.target.value);
  };
  const handleChange2 = (e: any) => {
    setdebtValue(e.target.value);
  };

  return (
    <Fragment>
      <main role="main">
        {loader && (
          <div className="spinner-border custom-spinner" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}
        <fieldset disabled={loader}>
          <div className="about-container portfolio-container">
            <div className="container-fluid about-heading">
              <div
                className="text-center main-heading"
                style={{ fontSize: "24px", lineHeight: "1.3" }}
              >
                Portfolio of Incubators approved under the Startup India Seed
                Fund Scheme <br />
                <small style={{ color: "gray", fontSize: "14px" }}>
                  <i>
                    The information displayed on the incubator’s portfolio page
                    may not be latest, it is recommended to reach out to the
                    concerned incubation center for any clarifications.
                  </i>
                </small>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="container">
              <div className="col-md-12">
                <div className="mt-5 mb-5">
                  <div className="row">
                    <div className="col-md-12">
                      <ul>
                        <li>
                          Certain incubators listed on this page may not be
                          available in the drop-down menus of the startup
                          application form in case the said incubators are
                          unable to receive more applications. Please reach out
                          to{" "}
                          <a href="mailto:sisfs@investindia.org.in">
                            <u className="link-orange-text">
                              sisfs@investindia.org.in
                            </u>
                          </a>{" "}
                          for any queries related to the same.
                        </li>
                      </ul>
                      <div className="row">
                        <div className="col-md-7">
                          <h1
                            className="portMainHeading"
                            style={{ fontSize: "24px" }}
                          >
                            Selected Incubators
                          </h1>
                        </div>
                        <div className="col-md-3 pull-right">
                          <div className="portFoundText pull-right">
                            <div className="d-flex searchDiv">
                              {" "}
                              <img
                                src={search}
                                className="search"
                                alt="search"
                              />
                              <input
                                className="searchBar"
                                type="text"
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                placeholder=" Search.."
                                name="search"
                                onKeyUp={searchList}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-1 filter-icon1">
                          <div style={{ marginTop: "10px" }}>
                            <img
                              src={filter}
                              className="img-fluid filter"
                              alt="SISFS Logo"
                              data-toggle="modal"
                              data-target="#filterModal"
                              style={{ float: "right" }}
                            />
                            {showFilterDot == true ? <mark></mark> : null}
                          </div>
                        </div>
                        <div
                          className="col-md-1 filter-icon2"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <div
                            style={{
                              marginTop: "8px",
                              cursor: "pointer",
                              backgroundColor: "skyblue",
                              borderRadius: "50%",
                            }}
                            data-toggle="modal"
                            data-target="#sortModal"
                          >
                            <img
                              src={sort}
                              className="img-fluid filter"
                              alt="SISFS Logo"
                              style={{ padding: "9px 7px", width: "36px" }}
                            />
                            {showSortDot == true ? <mark></mark> : null}
                          </div>
                        </div>
                      </div>

                      {show && (
                        <div className="row">
                          {
                            // data.length == 0 ? (
                            //     <div>
                            //         No Data
                            //     </div>
                            // ) :

                            !portfolioList.length || showNoData ? (
                              <div className="col-md text-center mt-5">
                                <h4>No Results Found</h4>
                              </div>
                            ) : (
                              portfolioList.map((key: any, index) => {
                                return (
                                  <Fragment key={index}>
                                    <div className="col-md-4">
                                      <div className="portFolioGrid">
                                        <Link
                                          to={{
                                            pathname: "/portfolio_detail",
                                            state: {
                                              portfolioId: key.Id,
                                              incubatorID: key.incubatorID,
                                              evalutionRate: key.evaluationRate,
                                              check: "frominc",
                                            },
                                          }}
                                          className="portLink"
                                        >
                                          <div className="portImageBlock">
                                            <img
                                              src={`${NODE_URL}${key.image}`}
                                              alt={key.incubator_name}
                                              className="portImage"
                                            />
                                          </div>
                                          <div className="portContentBlock">
                                            <p className="portCity">
                                              {key.city},{" " + key.state}
                                            </p>
                                            <h1 className="portHeading twoLine">
                                              {key?.incubator_name}
                                            </h1>
                                            <p className="portDescription twoLine">
                                              {key?.description}
                                            </p>

                                            {key?.sectors.length > 0 && (
                                              <p className="portTag">
                                                <span>
                                                  {showEllipsisText(
                                                    key?.sectors[0],
                                                    15
                                                  )}
                                                </span>
                                                {key?.sectors.length > 1 && (
                                                  <span>
                                                    {" "}
                                                    +{key.sectors.length -
                                                      1}{" "}
                                                    More{" "}
                                                  </span>
                                                )}
                                              </p>
                                            )}
                                          </div>
                                        </Link>
                                      </div>
                                    </div>
                                  </Fragment>
                                );
                              })
                            )
                          }
                        </div>
                      )}

                      {!show && (
                        <div className="row">
                          {showNoData && (
                            <div className="col-md text-center mt-5">
                              <h4>No Results Found</h4>
                            </div>
                          )}
                          {data &&
                            data.length > 0 &&
                            data.map((key, index) => {
                              return (
                                <Fragment key={index}>
                                  <div className="col-md-4">
                                    <div className="portFolioGrid">
                                      <Link
                                        to={{
                                          pathname: "/portfolio_detail",
                                          state: {
                                            portfolioId: key.Id,
                                            incubatorID: key.incubatorID,
                                            evalutionRate: key.evaluationRate,
                                            check: "frominc",
                                          },
                                        }}
                                        className="portLink"
                                      >
                                        <div className="portImageBlock">
                                          <img
                                            src={`${NODE_URL}${key.image}`}
                                            alt={key.incubator_name}
                                            className="portImage"
                                          />
                                        </div>
                                        <div className="portContentBlock">
                                          <p className="portCity">
                                            {key.city},{" " + key.state}
                                          </p>
                                          <h1 className="portHeading twoLine">
                                            {key?.incubator_name}
                                          </h1>
                                          <p className="portDescription twoLine">
                                            {key?.description}
                                          </p>

                                          {key?.sectors.length > 0 && (
                                            <p className="portTag">
                                              <span>
                                                {showEllipsisText(
                                                  key?.sectors[0],
                                                  15
                                                )}
                                              </span>
                                              {key?.sectors.length > 1 && (
                                                <span>
                                                  {" "}
                                                  +{key.sectors.length -
                                                    1} More{" "}
                                                </span>
                                              )}
                                            </p>
                                          )}
                                        </div>
                                      </Link>
                                    </div>
                                  </div>
                                </Fragment>
                              );
                            })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md"></div> */}
          </div>
        </fieldset>
      </main>

      {/* modal for filter */}
      <div
        className="modal fade"
        id="filterModal"
        role="dialog"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-slideout modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-bottom">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pl-5 pr-5 pt-1">
              <div className="popup-head mb-5 ml-3">
                <div className="main-heading">Filters</div>
              </div>
              <label className="col-form-head">
                Total Available Balance with the Incubator
              </label>
              <div className="range-container">
                <input
                  name="availAmount"
                  type="range"
                  min="0"
                  max="100000000"
                  value={availValue}
                  onChange={handleChange}
                  className="range-slider"
                />
                <div className="range-tooltip">
                  {changeNumberFormat1(Number(availValue), 2, 1, "availAmount")}
                </div>
              </div>
              <label className="col-form-head">
                Available Grant Balance with the Incubator
              </label>
              <div className="range-container">
                <input
                  name="grantAmount"
                  type="range"
                  min="0"
                  max="20000000"
                  value={grantValue}
                  onChange={handleChange1}
                  className="range-slider"
                />
                <div className="range-tooltip">
                  {changeNumberFormat1(Number(grantValue), 2, 1, "grantAmount")}
                </div>
              </div>
              <label className="col-sm-4 col-form-head">Sector</label>
              <div className="col-md-12 mb-4">
                <MultiSelect
                  id="countryStates"
                  options={sectorDetails}
                  defaultText="Select Sectors"
                  onClose={(selectedIds: string[]) =>
                    onMultiSelectChange(selectedIds, "sector")
                  }
                  selectedValues={
                    selectedSectorValue ? selectedSectorValue : []
                  }
                  hasError
                  errorText=""
                  searchable
                />
              </div>
              <label className="col-sm-4 col-form-head">State</label>
              <div className="col-md-12 mb-4">
                <MultiSelect
                  id="countryStates"
                  options={countryStates}
                  defaultText="Select State"
                  onClose={(selectedIds: string[]) =>
                    onMultiSelectChange1(selectedIds, "state")
                  }
                  selectedValues={selectedStateValue ? selectedStateValue : []}
                  hasError
                  errorText=""
                  searchable
                />
              </div>
              <label className="col-sm-4 col-form-head">City</label>
              <div className="col-md-12 mb-4">
                <MultiSelect
                  id="countryStates"
                  options={stateDistricts}
                  defaultText="Select City"
                  onClose={(selectedIds: string[]) =>
                    onMultiSelectChange(selectedIds, "city")
                  }
                  selectedValues={selectedCityValue ? selectedCityValue : []}
                  hasError
                  errorText=""
                  searchable
                />
              </div>
            </div>
            <div className="modal-footer">
              <div className="row modal-row">
                <div className="col-md-6 text-center">
                  <button
                    className="btn btn-sisfs btn-transparent-primary"
                    // data-dismiss="modal"
                    onClick={resetFilter}
                  >
                    RESET ALL
                  </button>
                </div>
                <div className="col-md-6 text-left">
                  <button
                    className="tertiary  btn-yellow-primary"
                    data-dismiss="modal"
                    onClick={() => {
                      applyFilter(showPayload);
                    }}
                  >
                    APPLY FILTER
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* modal for sort */}
      <div
        className="modal fade"
        id="sortModal"
        role="dialog"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-slideout modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-bottom">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pl-5 pr-5 pt-1">
              <div className="popup-head mb-5 ml-3">
                <div className="main-heading">Sort</div>
              </div>

              <div className="form-check mt-2">
                <input
                  className="form-check-input"
                  type="radio"
                  onChange={() => handleSort(1)}
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  checked={sortOrder === 1}
                />
                <label className="form-check-label">
                  Total Available Balance with the Incubator (Low-High)
                </label>
              </div>
              <div className="form-check mt-2">
                <input
                  className="form-check-input"
                  type="radio"
                  onChange={() => handleSort(2)}
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  checked={sortOrder === 2}
                />
                <label className="form-check-label">
                  Total Available Balance with the Incubator (High-Low)
                </label>
              </div>
              <div className="form-check mt-2">
                <input
                  className="form-check-input"
                  type="radio"
                  onChange={() => handleSort(3)}
                  name="flexRadioDefault"
                  id="flexRadioDefault3"
                  checked={sortOrder === 3}
                />
                <label className="form-check-label">
                  Available Grant Balance with the Incubator (Low-High)
                </label>
              </div>
              <div className="form-check mt-2">
                <input
                  className="form-check-input"
                  type="radio"
                  onChange={() => handleSort(4)}
                  name="flexRadioDefault"
                  id="flexRadioDefault4"
                  checked={sortOrder === 4}
                />
                <label className="form-check-label">
                  Available Grant Balance with the Incubator (High-Low)
                </label>
              </div>
              <div className="form-check mt-2">
                <input
                  className="form-check-input"
                  type="radio"
                  onChange={() => handleSort(5)}
                  name="flexRadioDefault"
                  id="flexRadioDefault5"
                  checked={sortOrder === 5}
                />
                <label className="form-check-label">
                  Evaluation Rate (%) (Low-High)
                </label>
              </div>
              <div className="form-check mt-2">
                <input
                  className="form-check-input"
                  type="radio"
                  onChange={() => handleSort(6)}
                  name="flexRadioDefault"
                  id="flexRadioDefault6"
                  checked={sortOrder === 6}
                />
                <label className="form-check-label">
                  Evaluation Rate (%) (High-Low)
                </label>
              </div>
            </div>
            <div className="modal-footer">
              <div className="row modal-row">
                <div className="col-md-6 text-center">
                  <button
                    className="btn btn-sisfs btn-transparent-primary"
                    // data-dismiss="modal"
                    onClick={resetSort}
                  >
                    Reset
                  </button>
                </div>
                <div className="col-md-6 text-left">
                  <button
                    className="tertiary  btn-yellow-primary"
                    data-dismiss="modal"
                    onClick={applySort}
                  >
                    APPLY
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Portfolio;
