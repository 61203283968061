import { useEffect, useState } from "react";
import fileupload from '../../../../images/fileupload.svg'
import error from '../../../../images/error.svg'
import { getSessionStorageValue } from "../../../../effects/utils/session-storage";
import { post } from "../../../../effects/api/api.common";
import { CANCELLATION_REQUEST_STARTUP } from "../../../../constants/api";


function SecCancellationRequest(props: any) {
    // callback
    const { application_id } = props
    const [activeTab1, setActiveTab1] = useState(localStorage.getItem("Tabvalue") || "1");
    const [mailTrailUpload, setMailTrailUpload] = useState<any>()
    const [MOMUpload, setMOMUpload] = useState<any>();
    const [selectedReason, setSelectedReason] = useState('');
    const [comments, setcomments] = useState('');
    const [exceedFileSize, setExceedFileSize] = useState(false);
    const [checkError, setCheckError] = useState(false);
    const [showTabs, setShowTabs] = useState("1");
    const [showTabs1, setShowTabs1] = useState(false);
    const [tranche_message, setMessage] = useState("");
    const [showEditSuccessModal, setShowSuccessModal] = useState(false);
    const [showEditFailureModal, setShowFailureModal] = useState(false);
    const tabActive = (val: any) => {
        console.log(val)
        localStorage.setItem("Tabvalue", val);
    };
    useEffect(() => {
        console.log(application_id)
    }, [activeTab1])

    const handleContinue = (event: any) => {
        event.preventDefault();
        tabActive("2")
    }
    const closeModal = () =>{
        // callback();
        setShowSuccessModal(false)
        setShowFailureModal(false);
        window.location.reload();
    }
    const handleChange = (evt: any) => {
        const name = evt.target.name;
        const value = evt.target.value;

        if (name == "selectReason") {
            setSelectedReason(value)
        }
        if (name == "mailUpload") {
            const file = evt.target.files[0];
            if (file.size > 3 * 1024 * 1024) {
                setExceedFileSize(true)
                return;
            } else {

                setMailTrailUpload(file)
            }
        }
        if (name == "MOMUpload") {
            const file = evt.target.files[0];
            if (file.size > 3 * 1024 * 1024) {
                setExceedFileSize(true)
                return;
            } else {

                setMOMUpload(file)
            }
        }
        if (name == "comments") {
            setcomments(value)
        }
    }

    const submit = () => {
        console.log(selectedReason)
        if (selectedReason == "" || comments == "" || mailTrailUpload == undefined || MOMUpload == undefined) {
            setCheckError(true)
            return;
        }
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user)
        const formData = new FormData();

        formData.append("startup_application_id", application_id);
        formData.append("logged_user_id", userInfo.id);
        formData.append("userRole", userInfo.roles);
        formData.append("userName", userInfo.name);
        formData.append("reason", selectedReason);
        mailTrailUpload.length != 0 && formData.append("file_path", mailTrailUpload);
        MOMUpload.length != 0 && formData.append("file_path_other", MOMUpload);
        formData.append("incubator_comments",comments)

        post(CANCELLATION_REQUEST_STARTUP, formData).then((response: any) => {
            console.log(response)
            if (!response || !response.data) return;
            if(response.data.result == "success"){
                // callback();
            }
            if (response.data.result == 'success') {
                setMessage("Cancellation Request raised Successfully !!");
                setShowSuccessModal(true);

            } else {
                setShowFailureModal(true);
            }
        })
    }

    const dismissModal = () => {
        setExceedFileSize(false);
    }
    const clickTabs = (val: any) => {
        if (val == 1) {
            setShowTabs("1")
        } else {
            setShowTabs("2")
        }
    }
    return (
        <>

            <div>
                {showTabs == "1" &&
                    <>
                    
                       <div className="row">
                            <div className="col-md-12 text-center">
                                <h5>
                                   <b className="list_tags">Process approved by the Experts Advisory Committee</b> 
                                </h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col"></div>
                            <div className="col-md-11 pl-0" style={{lineHeight:'1.7rem', fontSize:'16px',textAlign:'justify'}}>
                                <ol>
                                    <li className="mt-4">
                                        <b className="list_tags">Intimation to the startup:</b> The incubator is required to issue a written electronic 
                                        notice to the concerned startup regarding cancellation. The startup should be given a minimum of 30 days to respond.
                                    </li>
                                    <li className="mt-4">
                                        <b className="list_tags">ISMC to decide cancellation:</b> The Incubator Seed Management Committee(ISMC) is empowered to resolve any cases of conflicts
                                        between startup & incubator. The ISMC must give a fair chance to both parties to share and present thier inputs. In case
                                        the startup fails to give a satisfactory response within 30 days of electronic notice, the ISMC may decide that startup's
                                        application should be cancelled.
                                    </li>
                                    <li className="mt-4">
                                        <b className="list_tags">Seed Fund Secretariat to initiate cancellation:</b> The incubator may request Seed Fund Secretariat to initiate cancellation of
                                        startup application on the portal by sharing the minutes of the relavant ISMC meeting and all communication between the incubator and startup.
                                    </li>
                                </ol>
                            </div>
                            <div className="col"></div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md"></div>
                            <div className="col-md-11 text-right">
                                <button className="btn btn-sisfs btn-yellow-primary" onClick={()=>clickTabs(2)}>Continue</button>
                            </div>
                            <div className="col-md"></div>
                        </div>
                    </>
                }
                {
                    showTabs == "2" &&
                    <>
                    
                        <div className="form-wrapper contact-form">
                            <div className="form-group row mb-0 mt-2">
                            <div className="col"></div>
                                <label className="col-sm-12 col-md-5 col-form-label text-left list_tags" style={{fontSize:'14px'}}>
                                    Reason for cancellation startup <span className='required'>*</span>
                                </label>
                                <div className="col-sm-12 col-md-6">
                                    <select className="form-control" name="selectReason" id="selectReason" onChange={(e) => handleChange(e)}>
                                        <option value="">--Please Select--</option>
                                        <option value="Disagreement between startup and incubator regarding legal agreement">Disagreement between startup and incubator regarding legal agreement</option>
                                        <option value="Startup not responding">Startup not responding</option>
                                        <option value="Startup failed in due to diligence check">Startup failed in due diligence check</option>
                                        <option value="Others(Elaborate in comment section)">Others(Elaborate in comment section)</option>
                                    </select>
                                </div>
                                <div className="col"></div>
                            </div>
                            <div className="form-group row mb-0 mt-2">
                            <div className="col"></div>
                                <label className="col-sm-12 col-md-5 col-form-label text-left">
                                    
                                </label>
                                <div className="col-sm-12 col-md-6">
                                {
                                        checkError && selectedReason == "" &&
                                        <span style={{ color: 'red', fontSize: '12px' }}><i>Please select an option</i></span>
                                    }
                                </div>
                                <div className="col"></div>
                            </div>
                            
                            <div className="form-group row mb-0 mt-2">
                            <div className="col"></div>
                                <label className="col-sm-12 col-md-5 col-form-label text-left list_tags" style={{fontSize:'14px'}}>
                                    Attach mail trail between incubator and startup <span className='required'>*</span>
                                </label>
                                <div className="col-sm-12 col-md-6 d-flex">
                                    <input type="file" id="mailUpload" hidden accept=".pdf" className="form-control" name="mailUpload" onChange={(e) => handleChange(e)} />
                                    <label htmlFor="mailUpload" style={{ marginBottom: '0px' }}><img src={fileupload} className="form-control" alt="mailUpload" style={{ cursor: 'pointer', height: '100%' }} /></label>
                                    <span className="form-control">{mailTrailUpload != undefined && 
                                    mailTrailUpload.name.length > 15 ? mailTrailUpload.name.slice(0,15) + "...pdf" :mailTrailUpload != undefined && mailTrailUpload.name
                                
                                }</span>

                                </div>
                                <div className="col"></div>
                            </div>
                            <div className="form-group row mb-0 mt-0">
                            <div className="col"></div>
                                <label className="col-sm-12 col-md-5 text-left">
                                <span style={{ color: 'grey', fontSize: '12px' }}><i>File format - PDF Only, Max.size - 3MB</i></span>
                                </label>
                                <div className="col-sm-12 col-md-6">
                                 
                                {
                                    checkError && mailTrailUpload == undefined &&
                                    <span style={{ color: 'red', fontSize: '12px' }}><i>Please uplaod file.</i></span>
                                }
                                </div>
                                <div className="col"></div>
                            </div>
                            <div className="form-group row mb-0 mt-2">
                            <div className="col"></div>
                                <label className="col-sm-12 col-md-5 col-form-label text-left list_tags" style={{fontSize:'14px'}}>
                                    Attach minutes of the ISMC meeting where startup cancellation was approved <span className='required'>*</span>
                                </label>
                                <div className="col-sm-12 col-md-6 d-flex">
                                    <input type="file" id="MOMUpload" hidden accept=".pdf" className="form-control" name="MOMUpload" onChange={(e) => handleChange(e)} />
                                    <label htmlFor="MOMUpload" style={{ marginBottom: '0px' }}><img src={fileupload} className="form-control" alt="MOMUpload" style={{ cursor: 'pointer', height: '100%' }} /></label>
                                    <span className="form-control">{MOMUpload != undefined && MOMUpload.name.length >15 ?
                                    MOMUpload.name.slice(0,15) + "...pdf" :MOMUpload != undefined && MOMUpload.name}</span>
                                </div>
                                <div className="col"></div>
                            </div>
                            <div className="form-group row mb-0 mt-0">
                            <div className="col"></div>
                                <label className="col-sm-12 col-md-5 text-left">
                                <span style={{ color: 'grey', fontSize: '12px' }}><i>File format - PDF Only, Max.size - 3MB</i></span>
                                </label>
                                <div className="col-sm-12 col-md-6">
                                {
                                        checkError && MOMUpload == undefined &&
                                        <span style={{ color: 'red', fontSize: '12px' }}><i>Please uplaod file.</i></span>
                                    }
                                </div>
                                <div className="col"></div>
                            </div>
                            <div className="form-group row mb-0 mt-2">
                            <div className="col"></div>
                                <label className="col-sm-12 col-md-5 col-form-label text-left list_tags" style={{fontSize:'14px'}}>
                                    Additional comments <span className='required'>*</span>
                                </label>
                                <div className="col-sm-12 col-md-6">
                                    <textarea
                                        name="comments"
                                        id="comments"
                                        className="form-control"
                                        onChange={(e) => handleChange(e)}
                                    >
                                    </textarea>
                                </div>
                                <div className="col"></div>
                            </div>
                            <div className="form-group row mb-0 mt-2">
                            <div className="col"></div>
                                <label className="col-sm-12 col-md-5 text-left">
                                </label>
                                <div className="col-sm-12 col-md-6">
                                {
                                        checkError && comments =="" &&
                                        <span style={{ color: 'red', fontSize: '12px' }}><i>Please enter comment.</i></span>
                                    }
                                </div>
                                <div className="col"></div>
                            </div>
                        </div>
                        <br />
                        <div className="form-group row mb-0 mt-2">
                            <div className="col"></div>
                                <label className="col-sm-12 col-md-5 text-left">
                                </label>
                                <div className="col-sm-12 col-md-6 text-right">
                                <button className="btn btn-sisfs btn-yellow-primary" onClick={()=>clickTabs(1)}>Previous</button>
                                <button className="btn btn-sisfs btn-yellow-primary ml-2" onClick={submit}>Submit Request</button>
                                </div>
                                <div className="col"></div>
                            </div>
                        <div className="row">
                            <div className="col-md-11 text-right">
                                
                            </div>
                        </div>
                    </>
                }

            </div>
            
            {
                showEditSuccessModal && (
                    <>
                        <div className="modal-backdrop fade show"></div>

                        <div
                            className="modal align-middle modal-sisfs d-block"
                            id="FormSubmissionSuccess"
                        >
                            <div className="modal-dialog modal-dialog-centered modal-md">
                                <div className="modal-content">
                                    <div className="modal-body text-center">
                                        <i className="fa fa-check-circle-o"></i>
                                        <h3 className="modal-message">Thank You !</h3>
                                        <div className="modal-text">
                                            {tranche_message}
                                        </div>
                                        <div className="modal-action">
                                            <button
                                                className="btn btn-sisfs btn-yellow-primary"
                                                onClick={closeModal}
                                            >
                                                Ok !!
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
{
                showEditFailureModal && (
                    <>
                        <div className="modal-backdrop fade show"></div>
                        <div
                            className="modal align-middle modal-sisfs d-block"
                            id="FormSubmissionError"
                        >
                            <div className="modal-dialog modal-dialog-centered modal-md">
                                <div className="modal-content">
                                    <div className="modal-body text-center">
                                        {/* <i className="fa fa-times-circle-o"></i> */}
                                        <h3 className="modal-message message-orange">
                                        Cancelation request already raised.
                                        </h3>
                                        <br />
                                        <div className="modal-action">
                                            <button
                                                className="btn btn-sisfs btn-yellow-primary"
                                                onClick={closeModal}
                                            >
                                                OK !
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
            {
                exceedFileSize && (
                    <div
                        className="modal align-middle modal-sisfs d-block"
                        id="FormSubmissionError"
                    >
                        <div className="modal-dialog modal-dialog-centered modal-md">
                            <div className="modal-content">
                                <div className="modal-body text-center">
                                    <img
                                        src={error}
                                        className="search mb-2"
                                        alt="search"
                                        style={{ width: '2rem' }}
                                    />
                                    <h4 className="message-orange" style={{ fontFamily: "Roboto Slab" }}>
                                        Oops!!!
                                    </h4>
                                    <h5 className="message-orange" style={{ fontFamily: "Roboto Slab" }}>
                                        File size should not exceed 3Mb<br /><br />
                                        Please upload again.
                                    </h5>
                                    <div className="modal-action">
                                        <button
                                            className="btn btn-sisfs btn-yellow-primary"
                                            onClick={dismissModal}
                                        >
                                            OK !
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default SecCancellationRequest;