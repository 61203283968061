import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Plus from "../../../../images/Plus.svg";
import Minus from "../../../../images/Minus.svg";
import pdf from "../../../../images/PDF.svg";
import xls from "../../../../images/xls.svg";
import doc from "../../../../images/doc.svg";
import Allround from "../../../../images/Allround.svg";
import {
  FETCH_ST_CANCEL_DETAILS,
  File_base_url,
  File_node_url,
  NODE_URL,
  STORE_STARTUP_APPLICATION_NODE,
} from "../../../../constants/api";
import {
  EVALUATION_FILE,
  GET_ALL_STARTUP_TRANCHES,
  GET_STARTUP_APPLICATION_STATUS,
  GET_STARTUP_ENGAGEMENT_STATUS,
  PENDING_STARTUP_COUNT,
  QUICK_STARTUP_MEETING,
  SCHEDULE_MEETING_COUNT_STARTUP,
  STARTUP_CANCELLATION_DETAIL,
  STARTUP_SCHEDULE_MEETING,
  STORE_STARTUP_APPLICATION,
  UPLOAD_FILE,
  UPLOAD_FILE1,
  VIEW_STARTUP_APPLICATION,
  EVAL_REMOVE,
} from "../../../../constants/api";
import { get, post } from "../../../../effects/api/api.common";
import { AccountFileUploadModel } from "../../../../models/FileUploadModel";
import { StartupAccountDetailsData } from "../../../../models/funddetails/StartupAccountDetailsData";
import { StartupTrancheDetail } from "../../../../models/funddetails/StartupTrancheDetails";
import { TrancheDetail } from "../../../../models/funddetails/TrancheDetails";
import {
  changeNumberFormat,
  createFile,
  downloadDocument,
} from "../../../../utils/CommonMethods";
import { compareTime, FormatDate } from "../../../../utils/DateFormat";
import { UploadTypeEnum } from "../../../../utils/Enums";
import Calendar from "../../../common/controls/Calendar";
import ConfirmationDialog from "../../../common/controls/ConfirmationDialog";
import CancellationContainer from "../../../dpiit/incubator/CancellationContainer";
import MeetingTab from "./MeetingTab";
import StartupApplicationDetailsComponent from "./StartupApplicationDetailsComponent";
import StartupContactTab from "./StartupContactTab";
import StartupFundDetailsComponent from "./StartupFundDetailsComponent";
import ProgressOverviewFields from "../../../startups/progressoverview/ProgressOverviewFields";
import CommentContainer from "../../../dpiit/incubator/CommentContainer";
import PreferenceContainer from "./PreferenceContainer";
import StartupFundDetailsContainer from "../../../startups/funddetails/StartupFundDetailsContainer";
// import { isEmptyBindingElement, isTemplateExpression } from "typescript";
import { getSessionStorageValue } from "../../../../effects/utils/session-storage";
import ReturnsFields from "./StartupReturnsDetailsComponent";
import SecCancellationRequest from "./SecCancellationRequest";
declare var $: any;

interface IViewStartupDetailsProps { }

interface IViewStartupDetailsState { }

/** Render Startup Details Component for Incubator screen */
function ViewStartupDetailsComponent(props: any) {
  const [applicationID, setApplicationID] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [scheduleDate, setScheduleDate] = useState("");
  const [selectedDateControl, setSelectedDateControl] = useState(new Date());
  const [timeValidation, setTimeValidation] = useState(false);
  const [linkValidation, setLinkValidation] = useState(false);
  const [timeValidationCurrentTime, setTimeValidationCurrentTime] =
    useState(false);
  const [applicationDetails, setApplicationDetails] = useState<any>({});
  const [engagementStatus, setEngagementStatus] = useState([]);
  const [applicationStatus, setApplicationStatus] = useState([]);
  const [loggedInRole, setLoggedInRole] = useState("");
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const [incubatorPending, setIncubatorPending] = useState(0);
  const [meetingType, setMeetingType] = useState("1");
  const [meetingFilter, setMeetingFilter] = useState("");
  const [loader, setLoader] = useState(false);
  const [incubatorPreferanceData, setIncubatorPreferanceData] = useState({
    no_of_tranches: "",
    startup_application_id: "",
    status_of_engagement: "",
    total_approved_amount: "",
    remaining_amounts: "",
    total_disbursed_amount: ""
  });
  const user: any = getSessionStorageValue("user");
  const userInfo = JSON.parse(user);
  // console.log(userInfo);
  const [showIncubatorFailureModal, setShowIncubatorFailureModal] =
    useState(false);
  const [grantCheck, setGrantCheck] = useState<any>("");
  const [userDetails, setUserDetails] = useState({
    created_at: "",
    email: "",
    id: "",
    name: "",
    roles: "",
    status: "",
    engagement_status: "",
    updated_at: "",
    selected_incubator_id: "",
    quaterly_lengthConditions: "",
    quaterly_Condition: "",
    quaterly_Tranche: "",
    quaterly_returns: "",
    quaterly_progress: "",
    FortyFiveDays: "",
  });
  const [quickEditPopupData, setquickEditPopupData] = useState({
    application_comments: "",
    application_comments1: "",
    application_id: "",
    application_status: "",
    engagement_status: "",
    incubator_name: "",
  });
  const [count, setCount] = useState({
    Tomorrow: "",
    This_week: "",
    Next_week: "",
    Next_month: "",
    Next_2_months: "",
  });
  const [dialogData, setDialogData] = useState({
    header: "",
    text: "",
  });
  const [failDialogData, setFailDialogData] = useState({
    header: "",
    text: "",
  });
  const [isDisabled, setDisabled] = useState(false);
  const [showIncubatorSuccessModal, setShowIncubatorSuccessModal] =
    useState(false);
  const [grantCheckModal, setGrantCheckModal] = useState(false);
  const [tranches, setTranches] = useState<Array<StartupTrancheDetail>>();
  const [tranchesAsc, setTranchesAsc] = useState<Array<StartupTrancheDetail>>();
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [cancellationData, setCancellationData] = useState([]);
  const [commentData, setCommentData] = useState([]);
  const [preferenceData, setPreferenceData] = useState([]);
  const [otherApplications, setOtherApplications] = useState([]);
  const [meetingLink, setMeetingLink] = useState("");
  const [documentPath, setDocumentPath] = useState({
    authorisation_letter_upload: [],
    promoters_aadhar_cards: [],
    upload_pitch_deck: [],
    upload_relavent_document_other: [],
    upload_video_for_product: [],
    video_url_for_product: [],
    evaluation_sheet: [],
    legal_agreement: [],
  });
  const [event, setEvent] = useState("");
  const [status, setStatus] = useState("");
  const [QRIntiatedDetails, setQRIntiatedDetails] = useState();
  const [activeIndexDoc, setActiveIndexDoc] = useState("");
  const [disbursedToStartups, setDisbursedToStartups] = useState<any>("");
  const [disbursedToIncs, setDisbursedToInc] = useState<any>("");
  const changeActiveDoc = (id: string) => {
    setActiveIndexDoc(id);
  };
  const changeActiveBackDoc = () => {
    setActiveIndexDoc("");
  };
  const [activeTab, setActiveTab] = useState("");
  const [activeTab1, setActiveTab1] = useState("");
  var currentdatenew = moment().format("YYYY-MM-DD");

  // const [bankDetails, setBankDetails] = useState<any>();

  const [bankDetails, setBankDetails] = useState<StartupAccountDetailsData>(
    new StartupAccountDetailsData(
      0,
      0,
      "",
      "",
      "",
      0,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      new AccountFileUploadModel(new File([], "")),
      new AccountFileUploadModel(new File([], ""))
    )
  );
  const [totalGrantDisbursed, setTotalGrantDisbursed] = useState<number>(0);
  const [evaluationSheetPath, setEvaluationSheetPath] = useState("");
  const [isEditable, setIsEditable] = useState(true);
  const [isClick, setisClick] = useState("");
  const [iseval, setEval] = useState([]);
  const [isPref, setisPref] = useState("");
  const [flags, setFlags] = useState("");
  const [meetingAllData, setMeetingAllData] = useState([]);
  const [applicationComments, setApplictaionComments] = useState([]);
  const [showPreference, setShowPreference] = useState(true);
  const [legalAgreementPath, setLegalAgreementPath] = useState("");
  const [confirmationDialogData, setConfirmationDialogData] = useState({
    header: "",
    text: "",
  });
  const [incubatorId, setIncubatorId] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (typeof props.location.state != "undefined") {
      setApplicationID(props.location.state.application_id);
      userDetails.id = props.location.state.application_id;
      getAllTranches(props.location.state.application_id);
      setUserDetails(userDetails);
      viewActiveApplication(props.location.state.application_id);
      getCancellationData(props.location.state.application_id);
      // viewActiveApplication("1")
      setIncubatorId(props.location.state.incubator_id);
      // console.log(props.location.state, 'pppppppppppppppppp');
      setLoggedInRole(props.location.state.logged_in_role);
      setIsEditable(props.location.state.isEditable);
      setisClick(props.location.state.isClick);
      setisPref(props.location.state.isPref);
      setEval(props.location.state.evaluationDate);
      setEvent(props.location.state.evaluationDate);
      setFlags(props.location.state.select_flag);
      // console.log(props.location.state, '@#$');

      // setStatus(props.location.state.startup_status_id)
      if (typeof props.location.state.showPreference != "undefined") {
        setShowPreference(props.location.state.showPreference);
      }
    }
    // getFormCancellationData();
    getCancellationDetails();

  }, []);
  useEffect(() => {
    // FetchCAncellationData(incubatorId)
  }, [])
  const [cancellationRequested, setCancellationRequest] = useState(false);
  const [cancellationList, setCancellationList] = useState([{
    status_id: ''
  }]);
  // const getFormCancellationData = () =>{
  //   $("#CancellationRequest").modal('hide');
  //   setCancellationRequest(true);
  // }
  const getCancellationDetails = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    post(FETCH_ST_CANCEL_DETAILS, { incubator_id: userInfo.id }).then((response: any) => {
      // console.log(response,"&&&&&&&&&&&&&")
      // console.log(props.location.state.application_id,"&&&&&&&&&&&&&")
      let filterCancellationData = response.data.data.filter((item: any) => item.startup_application_id == props.location.state.application_id);
      let reversedArr = filterCancellationData.reverse();
      setCancellationList(reversedArr)
    })
  }
  //  const FetchCAncellationData = (id: any) => {
  //   post(FETCH_ST_CANCEL_DETAILS, { incubator_id: id }).then((response: any) => {
  //     setCancellationList(response.data.data)
  //     console.log(response.data.data)
  //   })
  // }
  const tabActive = (val: any) => {
    // setActiveTab(val)
    localStorage.setItem("value", val);
  };
  useEffect(() => {
    setActiveTab1(localStorage.getItem("value") || "4");
  }, [activeTab]);

  const getFileDetails = (file: any, key: string) => {
    let file_name = "";
    file_name =
      file != undefined && file != null && file.length > 1
        ? file.substr(file.lastIndexOf("/") + 1, file.length)
        : "";

    const file_type =
      file != undefined && file != null && file.length > 1
        ? file.substr(file.lastIndexOf(".") + 1, file.length)
        : "";

    if (key == "name") return file_name;
    if (key == "file") return file != undefined ? file.toString() : "";
    if (key == "type") return file != undefined ? file_type.toString() : "";
  };

  const [popUpName, setPopUpName] = useState("");
  const setMeetingModal = (fieldName: string) => {
    setPopUpName(fieldName);
  };
  const getMeetingCount = () => {
    get(SCHEDULE_MEETING_COUNT_STARTUP).then((response: any) => {
      // console.log(response,"resss")
      if (!response || !response.data) return;
      setCount(response.data.Data);
      getStartupCount();
    });
  };

  const getStartupCount = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);

    post(PENDING_STARTUP_COUNT, { logged_user_id: userInfo.id }).then(
      (response: any) => {
        if (!response || !response.data) return;

        setIncubatorPending(parseInt(response.data.data.count));
      }
    );
  };

  const getCancellationData = (application_id: number) => {
    post(STARTUP_CANCELLATION_DETAIL, {
      startup_application_id: application_id,
      logged_user_id: userInfo.id,
      user_role: userInfo.roles,
    }).then(
      // get(`${GET_INCUBATOR_DETAILS}/8/details`).then(
      (response: any) => {
        if (!response || !response.data || response.data == "") return;
        setCancellationData(response.data.data);
        // console.log(response.data.data[0].id, "cancell")
      }
    );
    // this.getAccountDetails();
  };

  const getSampleFilePath = () => {
    get(EVALUATION_FILE).then((response: any) => {
      if (!response.data) return;
      setEvaluationSheetPath(response.data.evaluationSheetPath);
      setLegalAgreementPath(response.data.legalAgreementPath);
      getEngagementStatus();
    });
  };

  //on radio button change

  const handleRadioChange = (e: any /*index:number*/) => {
    var value = e.target.value;
    setMeetingFilter(value);
  };
  // function to format date
  function formatDate(date: Date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  // trrigers on date change
  const handleDateChange = (date: Date, field: string) => {
    setScheduleDate(formatDate(date));
    setSelectedDateControl(date);
  };

  //  show hide radio buttons on select dropdown
  const handleOnChange = (e: any /*index:number*/) => {
    const value = e.target.value;
    setMeetingType(value);
    if (value == "2") {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // submit meeting
  const quickMeetingSubmit = () => {
    var time = new Date();
    var curTime = moment(time, "HH:mm").format("HH:mm");
    if (!compareTime(fromTime, toTime)) {
      setTimeValidation(true);
      setTimeValidationCurrentTime(false);
      return false;
    }
    const payload = {
      meeting_date: scheduleDate != "" ? scheduleDate : formatDate(new Date()),
      meeting_from_time: fromTime,
      meeting_to_time: toTime,
      meeting_type: meetingType,
      meeting_filter: meetingFilter,
      warning_ignore: 0,
      startup_incubator_id: userInfo.id,
    };
    if (
      formatDate(new Date()) == payload.meeting_date &&
      compareTime(fromTime, curTime)
    ) {
      setTimeValidationCurrentTime(true);
      setTimeValidation(false);
      return false;
    }
    setTimeValidation(false);
    setTimeValidationCurrentTime(false);
    setDisabled(true);
    post(QUICK_STARTUP_MEETING, payload).then((response: any) => {
      if (!response || !response.data) return;
      if (response.data.result == "success") {
        setDialogData({
          header: "Thank You!",
          text: "Your meeting has been scheduled !!!",
        });
        setShowIncubatorSuccessModal(true);
        setDisabled(false);
      } else if (response.data.result == "warning") {
        setConfirmationDialogData({
          header: "Meeting Already Exist",
          text: response.data.message,
        });
        setShowConfirmationBox(true);
        setDisabled(false);
      } else {
        setFailDialogData({
          header: "Oops !!",
          text: response.data.message,
        });
        setShowIncubatorFailureModal(true);
        setDisabled(false);
      }
    });
  };

  // on change time;
  const onTimeChange = (e: any /*index:number*/) => {
    var value = e.target.value;
    var name = e.target.name;
    if (name == "fromTime") {
      setFromTime(value);
      if (compareTime(fromTime, toTime) && toTime != "") {
        setTimeValidation(false);
      }
    } else if (name == "toTime") {
      setToTime(value);
      if (compareTime(fromTime, toTime) && fromTime != "") {
        setTimeValidation(false);
      }
    }
  };
  const [evalCompletedDate, setEvalCompletedDate] = useState<any>("");
  const viewActiveApplication = (id: any) => {
    post(`${VIEW_STARTUP_APPLICATION}/${id}`, {
      incubator_id: props.location.state.incubator_id,
      list_from: userInfo.roles,
      logged_user_id: userInfo.id,
      userRole: userInfo.roles,
      from: props.location.state.from,
    }).then(async (response: any) => {
      setApplictaionComments(response.data.data.meetings_data);
      setEvalCompletedDate(response.data.data.evaluation_to_be_completed_by);
      setQRIntiatedDetails(response.data.data.QRIntiatedDetails);
      setDisbursedToStartups(response.data.data.AmountDisbursedToAllStartups);
      setDisbursedToInc(response.data.data.AmountDisbursedToIncubtor);
      if (!response || !response.data) return;
      if (response.data.data) {
        // console.log(response.data.data, 'ls');
        setApplicationDetails(response);
        if (typeof response.data.data.preferenceData != "undefined") {
        //   let data = response.data.data.preferenceData.map((response:any) => {
        //     let foundNonNullTagStatus = false;            
        //     response.commentsData.forEach((item:any) => {
        //         if(item.tag_status != null && !foundNonNullTagStatus){
        //             response.response_label = item.tag_status;
        //             foundNonNullTagStatus = true; 
        //         }
        //     });            
        //     return response;
        // });        
        //   console.log(data)
          setPreferenceData(response.data.data.preferenceData);
        }

        if (userInfo.roles === "secretariat" &&
          response.data.data?.otherApplications != undefined &&
          response.data.data?.otherApplications.length > 0) {
          setOtherApplications(response.data.data.otherApplications)
        }
        // console.log('UserData', userInfo);
        if (userInfo.roles === "incubator") {
          setGrantCheck(
            response.data.data.Application_Details.FundingDetails
              .instrument_approved
          );
          setUserDetails((prevState) => ({
            ...prevState,
            created_at:
              response.data.data.Application_Details.Other
                .first_submitted_date != null
                ? response.data.data.Application_Details.Other
                  .first_submitted_date
                : response.data.data.Application_Details.Other.submitted_date,
            email: response.data.data.startup_user.email,
            name: response.data.data.Application_Details.EntityDetail
              .name_of_entity,
            roles: response.data.data.startup_user.roles,
            status: response.data.data.startup_incubator_preferences_data.label,
            engagement_status: response.data.data.engagement_status_data.label,
            updated_at: response.data.data.startup_user.updated_at,
            selected_incubator_id:
              response.data.data.Application_Details.Other
                .selected_incubator_id,
            quaterly_lengthConditions: response.data.data.QRdetails,
            quaterly_Condition: response.data.data.quaterly_review_started,
            quaterly_Tranche:
              response.data.data.QRdetails.length &&
              response.data.data.QRdetails[0].fund_details_review,
            quaterly_returns:
              response.data.data.QRdetails.length &&
              response.data.data.QRdetails[0].returns_review,
            quaterly_progress:
              response.data.data.QRdetails.length &&
              response.data.data.QRdetails[0].progress_overview_review,
            FortyFiveDays:
              response.data.data &&
              response.data.data.evaluation_to_be_completed_by,
          }));
        }
        if (userInfo.roles === "secretariat") {
          setUserDetails((prevState) => ({
            ...prevState,
            created_at:
              response.data.data.Application_Details.Other
                .first_submitted_date != null
                ? response.data.data.Application_Details.Other
                  .first_submitted_date
                : response.data.data.Application_Details.Other.submitted_date,
            email: response.data.data.startup_user.email,
            name: response.data.data.Application_Details.EntityDetail
              .name_of_entity,
            roles: response.data.data.startup_user.roles,
            status: response.data.data.startup_status_data.label,
            engagement_status: response.data.data.engagement_status_data.label,
            updated_at: response.data.data.startup_user.updated_at,
            selected_incubator_id:
              response.data.data.Application_Details.Other
                .selected_incubator_id,
            quaterly_lengthConditions: response.data.data.QRdetails,
            quaterly_Condition: response.data.data.quaterly_review_started,
            quaterly_Tranche:
              response.data.data.QRdetails.length &&
              response.data.data.QRdetails[0].fund_details_review,
            quaterly_returns:
              response.data.data.QRdetails.length &&
              response.data.data.QRdetails[0].returns_review,
            quaterly_progress:
              response.data.data.QRdetails.length &&
              response.data.data.QRdetails[0].progress_overview_review,
            FortyFiveDays:
              response.data.data &&
              response.data.data.evaluation_to_be_completed_by,
          }));
        }
        if (userInfo.roles === "dpiit") {
          setUserDetails((prevState) => ({
            ...prevState,
            created_at:
              response.data.data.Application_Details.Other
                .first_submitted_date != null
                ? response.data.data.Application_Details.Other
                  .first_submitted_date
                : response.data.data.Application_Details.Other.submitted_date,
            email: response.data.data.startup_user.email,
            name: response.data.data.Application_Details.EntityDetail
              .name_of_entity,
            roles: response.data.data.startup_user.roles,
            status: response.data.data.startup_status_data.label,
            engagement_status: response.data.data.engagement_status_data.label,
            updated_at: response.data.data.startup_user.updated_at,
            selected_incubator_id:
              response.data.data.Application_Details.Other
                .selected_incubator_id,
            quaterly_lengthConditions: response.data.data.QRdetails,
            quaterly_Condition: response.data.data.quaterly_review_started,
            quaterly_Tranche:
              response.data.data.QRdetails.length &&
              response.data.data.QRdetails[0].fund_details_review,
            quaterly_returns:
              response.data.data.QRdetails.length &&
              response.data.data.QRdetails[0].returns_review,
            quaterly_progress:
              response.data.data.QRdetails.length &&
              response.data.data.QRdetails[0].progress_overview_review,
            FortyFiveDays:
              response.data.data &&
              response.data.data.evaluation_to_be_completed_by,
          }));
        }
        setIncubatorPreferanceData({
          no_of_tranches:
            response.data.data.startup_incubator_preferences_data
              .no_of_tranches,
          startup_application_id:
            response.data.data.startup_incubator_preferences_data
              .startup_application_id,
          status_of_engagement:
            response.data.data.startup_incubator_preferences_data
              .status_of_engagement,
          total_approved_amount:
            response.data.data.Application_Details.Other.total_applroved_amount,
          remaining_amounts: response.data.data.remainingAmount,
          total_disbursed_amount: response.data.data.Application_Details.Other.total_disbursed_amount
        });
        // console.log(response.data.data.remainingAmount,"count!!!!!!")
        setBankDetails(response.data.data.startup_bankDetails);
        // if (
        //   response.data.data.Application_Details.UploadDocuments
        //     .authorisation_letter_upload.length != 0
        // ) {
        //   setDocumentPath(prevState => ({
        //     ...prevState,
        //     authorisation_letter_upload:
        //       response.data.data.Application_Details.UploadDocuments.authorisation_letter_upload.map(
        //         (ele: any) => ele,
        //       ),
        //   }));
        // }
        if (
          response.data.data.Application_Details.UploadDocuments
            .evaluation_sheet.length != 0
        ) {
          setDocumentPath((prevState) => ({
            ...prevState,
            evaluation_sheet:
              response.data.data.Application_Details.UploadDocuments.evaluation_sheet.map(
                (ele: any) => ele
              ),
          }));
        }

        if (
          response.data.data.Application_Details.UploadDocuments
            .authorisation_letter_upload.length != 0
        ) {
          setDocumentPath((prevState) => ({
            ...prevState,
            authorisation_letter_upload:
              response.data.data.Application_Details.UploadDocuments.authorisation_letter_upload.map(
                (ele: any) => ele
              ),
          }));
        }
        if (
          response.data.data.Application_Details.UploadDocuments
            .promoters_aadhar_cards.length != 0
        ) {
          setDocumentPath((prevState) => ({
            ...prevState,
            promoters_aadhar_cards:
              response.data.data.Application_Details.UploadDocuments.promoters_aadhar_cards.map(
                (ele: any) => ele
              ),
          }));
        }
        if (
          response.data.data.Application_Details.UploadDocuments
            .upload_pitch_deck.length != 0
        ) {
          setDocumentPath((prevState) => ({
            ...prevState,
            upload_pitch_deck:
              response.data.data.Application_Details.UploadDocuments.upload_pitch_deck.map(
                (ele: any) => ele
              ),
          }));
        }
        if (
          response.data.data.Application_Details.UploadDocuments
            .upload_relavent_document_other.length != 0
        ) {
          setDocumentPath((prevState) => ({
            ...prevState,
            upload_relavent_document_other:
              response.data.data.Application_Details.UploadDocuments.upload_relavent_document_other.map(
                (ele: any) => ele
              ),
          }));
        }
        if (
          response.data.data.Application_Details.UploadDocuments
            .upload_video_for_product.length != 0
        ) {
          setDocumentPath((prevState) => ({
            ...prevState,
            upload_video_for_product:
              response.data.data.Application_Details.UploadDocuments.upload_video_for_product.map(
                (ele: any) => ele
              ),
          }));
        }
        // console.log(response.data.data.Application_Details.UploadDocuments)
        if (
          response.data.data.Application_Details.UploadDocuments
            .video_url_for_product
        ) {
          setDocumentPath((prevState) => ({
            ...prevState,
            video_url_for_product:
              response.data.data.Application_Details.UploadDocuments
                .video_url_for_product,
          }));
        }
        // if (
        //   response.data.data.Application_Details.UploadDocuments.legal_agreement
        //     .length != 0
        // ) {
        //   setDocumentPath(prevState => ({
        //     ...prevState,
        //     legal_agreement:
        //       response.data.data.Application_Details.UploadDocuments
        //         .legal_agreement[
        //         response.data.data.Application_Details.UploadDocuments
        //           .legal_agreement.length - 1
        //       ].file,
        //   }));
        // }
        if (
          response.data.data.Application_Details.UploadDocuments.legal_agreement
            .length != 0
        ) {
          setDocumentPath((prevState) => ({
            ...prevState,
            legal_agreement:
              response.data.data.Application_Details.UploadDocuments.legal_agreement.map(
                (ele: any) => ele
              ),
          }));
        }
        setMeetingAllData(response.data.data.meetings_data);
        // console.log("@@@@@@@@@@@@@@@",response.data.data)
        if (response.data.data.comments_data) {
          setCommentData(response.data.data.comments_data);
        } else {
          setCommentData(response.data.data.applicationComments);
        }
      }
      getApplicationStatus();
    });

    getAllTranches(id);
  };

  //get application status
  const getApplicationStatus = () => {
    get(GET_STARTUP_APPLICATION_STATUS).then((response: any) => {
      if (!response || !response.data) return;
      const status = response.data.data.map((item: any) => ({
        label: item.label,
        value: item.id,
      }));
      const indexOfObject = status.filter(
        (item: any) => item.value == 8 || item.value == 7
      );
      const indexOfObject2 = status.filter((item: any) => item.value == 7);
      const indexOfObject1 = status.filter(
        (item: any) => item.value != 8 && item.value != 9
      );
      // console.log(userDetails.status === 'Selected' &&(evalCompletedDate < currentdatenew)&&totalGrantDisbursed == 0 ,"totalGrantDisbursed")
      if (
        userDetails.status === "Selected" &&
        evalCompletedDate < currentdatenew &&
        totalGrantDisbursed == 0
      ) {
        // console.log("if","totalGrantDisbursed")
        setApplicationStatus(indexOfObject2);
      }
      // console.log(userDetails.created_at,"592")
      else if (
        (userDetails.status === "Selected" &&
          evalCompletedDate < currentdatenew) ||
        (userDetails.status === "Closed" && evalCompletedDate < currentdatenew)
      ) {
        setApplicationStatus(indexOfObject);
      } else {
        setApplicationStatus(indexOfObject1);
      }
      // setApplicationStatus(status);
      getSampleFilePath();
    });
  };

  //get application status
  const getEngagementStatus = () => {
    get(GET_STARTUP_ENGAGEMENT_STATUS).then((response: any) => {
      if (!response || !response.data) return;
      const status = response.data.data.map((item: any) => ({
        label: item.label,
        value: item.id,
      }));

      setEngagementStatus(status);
      getMeetingCount();
    });
  };

  const quickEditData = () => {
    // console.log('quickEditData clicked');
    setquickEditPopupData({
      application_comments: "",
      application_comments1: "",

      application_id: "",
      application_status: "",
      engagement_status: "",
      incubator_name: "",
    });
    getApplicationStatus();
    // getEngagementStatus();
    // console.log(applicationID, '33');
    var payload = {
      logged_user_id: userInfo.id,
    };
    post(
      `${STORE_STARTUP_APPLICATION_NODE}/${applicationID}/quickEdit`,
      payload
    ).then((response: any) => {
      if (!response || !response.data) return;
      // console.log(response.data.data.id, '99');
      setquickEditPopupData({
        application_comments: response.data.data.comment,
        application_comments1: response.data.data.comment_engagement,
        application_id: response.data.data.id,
        application_status: response.data.data.startup_status_id,
        engagement_status:
          response.data.data.status_of_engagement != null
            ? response.data.data.status_of_engagement
            : "",
        incubator_name: response.data.data.name_of_entity,
      });
    });
  };
  const [showQuickEditModal, setShowQuickEditModal] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const quickEditCallMOdal = () => {
    if (
      quickEditPopupData.application_comments == "" ||
      quickEditPopupData.application_comments == null
    ) {
      setShowValidation(true);
      return;
    } else {
      setShowValidation(false);
    }
    setShowQuickEditModal(true);
  };
  const quickEditCall = () => {
    if (
      quickEditPopupData.application_comments == "" ||
      quickEditPopupData.application_comments == null
    ) {
      setShowValidation(true);
      return;
    } else {
      setShowValidation(false);
    }
    if (quickEditPopupData.application_id != 'undefined') {
      var payload = {};
      payload = {
        comment: quickEditPopupData.application_comments,
        startup_status_id: quickEditPopupData.application_status,
        logged_user_id: userInfo.id,
        // status_of_engagement: quickEditPopupData.engagement_status
      };
      setShowQuickEditModal(false)
      setDisabled(true);
      post(
        `${STORE_STARTUP_APPLICATION_NODE}/${quickEditPopupData.application_id}/quickEdit_Post`,
        payload
      ).then((response: any) => {
        if (!response || !response.data) return;
        if (response.data.result == "success") {
          setDialogData({
            header: "Thank You!",
            text: "Application has been Updated Successfully !!!",
          });
          setShowQuickEditModal(false);
          viewActiveApplication(applicationID);
          setShowIncubatorSuccessModal(true);
          setDisabled(false);
        } else {
          setFailDialogData({
            header: "Oops,Something went wrong",
            text: "Please Try Again !!",
          });
          setShowIncubatorFailureModal(true);
          setDisabled(false);
        }
      });
      // setquickEditPopupData({
      //   application_comments: '',
      //   application_comments1: '',
      //   application_id: '',
      //   application_status: '',
      //   engagement_status: '',
      //   incubator_name: '',
      // });
    }

  };

  const quickEditCallEngagement = () => {
    if (quickEditPopupData.application_id != 'undefined') {
      var payload = {};
      payload = {
        comment_engagement: quickEditPopupData.application_comments1,
        status_of_engagement: quickEditPopupData.engagement_status,
        // status_of_engagement: quickEditPopupData.engagement_status
        comment: "",
        date_of_evaluation: "",
        startup_status_id: "",
        no_of_tranches: "",
        total_approved_amount: "",
        logged_user_id: userInfo.id,
      };
      setDisabled(true);
      post(
        `${STORE_STARTUP_APPLICATION_NODE}/${quickEditPopupData.application_id}/quickEdit_Post`,
        payload
      ).then((response: any) => {
        if (!response || !response.data) return;

        if (response.data.result == "success") {
          setDialogData({
            header: "Thank You!",
            text: "Application has been Updated Successfully !!!",
          });
          setShowIncubatorSuccessModal(true);
          setDisabled(false);
        } else {
          setFailDialogData({
            header: "Oops,Something went wrong",
            text: response.data.message,
          });
          setShowIncubatorFailureModal(true);
          setDisabled(false);
        }
      });
      setquickEditPopupData({
        application_comments: "",
        application_comments1: "",
        application_id: "",
        application_status: "",
        engagement_status: "",
        incubator_name: "",
      });
    }
  };

  const dismissModal = () => {
    setShowIncubatorSuccessModal(false);
    setShowIncubatorFailureModal(false);
    $("#quickEdit").modal("hide");
    // setShowEditFailureModal(false);
    // setShowEditSuccessModal(false);
    window.location.reload();
  };
  const grantModal = () => {
    // console.log("mohannnnnn","lankeeeeeee")
    setGrantCheckModal(false);
    $("#quickEdit").modal("hide");
  };
  // popup change
  const handlePopupChange = (e: any /*index:number*/) => {
    var value = e.target.value;
    var name = e.target.name;
    if (name == "application_comments") {
      if (value != "" || value != null) {
        // console.log(quickEditPopupData.application_comments,"cmntss")
        setShowValidation(false);
        // return;
      } else {
        setShowValidation(true);
      }
    }
    if (userDetails.status == "Selected" && value == 8 && grantCheck == "0") {
      setGrantCheckModal(true);
    }
    if (name == "application_status") {
      setquickEditPopupData((prevState) => ({
        ...prevState,
        application_comments: "",
        application_comments1: "",
      }));
    }
    setquickEditPopupData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getAllTranches = (startup_application_id: number) => {
    if (startup_application_id != 0) {
      let tranche_parameters = {
        startup_application_id: startup_application_id,
        order_by: "DESC",
      };

      // var trancheDetails = {
      //     application_id: "1",
      //     tranch_id: 1,
      //     tranche_number: '123',
      //     // approved_amount: ,
      //     // funds_requested: ,
      //     // cash_in_hand: ,
      //     // tranche_requested_date: ,
      //     // documents: Documents,
      //     // tranches_status_description: ,
      //     // interest_accumulated_on_funds: ,
      //     // fund_released: number,
      //     // date_of_tranche_release: ,
      //     // tranches_status_label: ,
      //     // comments: ,
      //     // date_of_release: ,
      //     // approved_amount_date: ,
      //     // tranche_status_comment: ,
      //     // isNextDisbursed: boolean,
      //     // isPrevDisbursed: boolean,
      //     // proofDisbursementFlag: boolean
      //   }

      // setState(trancheDetails);

      post(`${GET_ALL_STARTUP_TRANCHES}`, tranche_parameters).then(
        (response: any) => {
          if (!response || !response.data) return;
          setTranchesAsc(response.data.data);
          var trancheDetailsData: Array<StartupTrancheDetail> =
            response.data.data;

          // var startupTrancheDetailsData: Array<StartupTrancheDetail> = response.data.data;

          for (var i = 0; i < trancheDetailsData.length; i++) {
            var tranche_details = trancheDetailsData[i];

            // var startup_tranche_details = startupTrancheDetailsData[i];
            // tranche_details.tranches_status_label = startup_tranche_details.startup_tranches_statuses_label;
            // tranche_details.application_id = startup_tranche_details.startup_application_id;
            // tranche_details.tranch_id = startup_tranche_details.startup_tranche_id;
            // tranche_details.tranches_status_description = startup_tranche_details.startup_tranches_statuses_description;
            // tranche_details.tranche_number = startup_tranche_details.tranche_number;
            // tranche_details.tranche_requested_date = startup_tranche_details.tranche_requested_date;

            const file_data = tranche_details.documents;

            if (file_data) {
              Object.keys(file_data).length &&
                Object.keys(file_data).map((key: string, index) => {
                  switch (key) {
                    case UploadTypeEnum.ProofDisbursement:
                      file_data[key].map((data: any) => {
                        createFile(data.file).then((blob: any) => {
                          blob.lastModifiedDate = new Date();
                          const src = data.file as string;
                          blob.name = src.substr(
                            src.lastIndexOf("/") + 1,
                            src.length
                          );

                          const index =
                            file_data.proof_of_disbursement.findIndex(
                              (el) => el.file_id === data.file_id
                            );
                          file_data.proof_of_disbursement[index] = {
                            file_id: data.file_id,
                            file_type_id: data.file_type_id,
                            file: blob as File,
                            ext: data.ext,
                            fileName: data.fileName,
                          };
                        });
                      });
                      break;
                    case UploadTypeEnum.QuarterlyReport:
                      file_data[key].map((data: any) => {
                        createFile(data.file).then((blob: any) => {
                          blob.lastModifiedDate = new Date();
                          const src = data.file as string;
                          blob.name = src.substr(
                            src.lastIndexOf("/") + 1,
                            src.length
                          );

                          const index = file_data.quarterly_reports.findIndex(
                            (el) => el.file_id === data.file_id
                          );
                          file_data.quarterly_reports[index] = {
                            file_id: data.file_id,
                            file_type_id: data.file_type_id,
                            file: blob as File,
                            ext: data.ext,
                            fileName: data.fileName,
                          };
                        });
                      });
                      break;
                    case UploadTypeEnum.SupportingDocuments:
                      file_data[key].map((data: any) => {
                        createFile(data.file).then((blob: any) => {
                          blob.lastModifiedDate = new Date();
                          const src = data.file as string;
                          blob.name = src.substr(
                            src.lastIndexOf("/") + 1,
                            src.length
                          );

                          const index =
                            file_data.supporting_documents.findIndex(
                              (el) => el.file_id === data.file_id
                            );
                          file_data.supporting_documents[index] = {
                            file_id: data.file_id,
                            file_type_id: data.file_type_id,
                            file: blob as File,
                            ext: data.ext,
                            fileName: data.fileName,
                          };
                        });
                      });
                      break;
                  }
                });
            }

            tranche_details.documents = file_data;
            trancheDetailsData[i] = tranche_details;
          }

          setTranches(trancheDetailsData);
          // console.log(tranches, "tranches!!!!!!!!!!!!!!!!")
          // this.setState({
          //   tranches: trancheDetailsData,
          // });

          //total disbursed
          let grant_disbursed = 0;
          for (let i = 0; i < response.data.data.length; i++) {
            // console.log((response.data.data[i].startup_tranches_statuses_label == "Disbursed") , response.data.data[i].fund_released == null,"Mohaaan")
            grant_disbursed =
              grant_disbursed +
              (response.data.data[i].startup_tranches_statuses_label ==
                "Disbursed" &&
                (response.data.data[i].fund_released != null)
                // || response.data.data[i].date_of_release != null
                ? Number(response.data.data[i].fund_released)
                : 0);
            // grant_disbursed =
            //   grant_disbursed +
            //   ((response.data.data[i].startup_tranches_statuses_label == "Disbursed")?response.data.data[i].fund_released == null
            //     ? 0
            //     : Number(response.data.data[i].fund_released):0);
          }
          // this.setState({
          //   total_grant_disbursed: grant_disbursed,
          // });
          // console.log(grant_disbursed,"data@@@@@@")
          setTotalGrantDisbursed(grant_disbursed);
        }
      );

      //
      // let trancheAsc_parameters = {
      //   startup_application_id: startup_application_id,
      //   order_by: 'ASC',
      // };

      // post(`${GET_ALL_STARTUP_TRANCHES}`, trancheAsc_parameters).then(
      //   (response: any) => {

      //     if (!response || !response.data) return;
      //     //   this.setState({
      //     //     tranchesAsc: response.data.data,
      //     //   });
      //     setTranchesAsc(response.data.data);
      //   },
      // );
    }
  };

  // const finalSchedule = () => {
  //     const payload = {
  //       "meeting_date": (scheduleDate != "") ? scheduleDate : formatDate(new Date()),
  //       "meeting_from_time": fromTime,
  //       "meeting_to_time": toTime,
  //       "meeting_type": meetingType,
  //       "meeting_filter": meetingFilter,
  //       "warning_ignore": 1
  //     }
  //     setTimeValidation(false);
  //     post(QUICK_STARTUP_MEETING, payload).then((response: any) => {
  //       if (!response || !response.data) return;
  //       setShowConfirmationBox(false);
  //       if (response.data.result == "success") {
  //         setDialogData({
  //           "header": "Thank You!",
  //           "text": "Your meeting has been scheduled !!!"
  //         })
  //         setShowIncubatorSuccessModal(true)
  //       }
  //       else {
  //         setFailDialogData({
  //           "header": "Oops !!",
  //           "text": response.data.message
  //         })
  //         setShowIncubatorFailureModal(true);
  //       }
  //     })
  //   }

  const scheduleMeetingFunction = () => {
    var time = new Date();
    var curTime = moment(time, "HH:mm").format("HH:mm");
    if (!compareTime(fromTime, toTime)) {
      setTimeValidation(true);
      setTimeValidationCurrentTime(false);
      return false;
    } else if (compareTime(fromTime, toTime)) {
      setTimeValidation(false);
      setTimeValidationCurrentTime(false);
    }
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    const data = {
      meeting_date: scheduleDate != "" ? scheduleDate : formatDate(new Date()),
      meeting_from_time: fromTime != "" ? fromTime : "",
      meeting_to_time: toTime != "" ? toTime : "",
      meeting_type: userDetails.status == "Selected" ? "2" : "1",
      startup_application_ids: [userDetails.id],
      link: meetingLink,
      warning_ignore: 0,
      startup_incubator_id: userInfo.id,
    };
    if (meetingLink == "") {
      setLinkValidation(true);
      return false;
    }
    if (
      formatDate(new Date()) == data.meeting_date &&
      compareTime(fromTime, curTime)
    ) {
      setTimeValidationCurrentTime(true);
      setTimeValidation(false);
      setLinkValidation(false);
      return false;
    }
    // if (checkedArray.length == 0) {
    //     setFailDialogData({
    //         "header": "",
    //         "text": "Please select atleast one Startup!!"
    //     })
    //     setShowIncubatorFailureModal(true);
    //     return;
    // }
    setDisabled(true);
    setTimeValidation(false);
    setTimeValidationCurrentTime(false);
    post(STARTUP_SCHEDULE_MEETING, data).then((response: any) => {
      // console.log(response.data, "respooooo")
      if (!response || !response.data) return;
      if (response.data.result == "success") {
        setDialogData({
          header: "Thank You!",
          text: "Your meeting has been scheduled !!!",
        });
        setShowIncubatorSuccessModal(true);

        setDisabled(false);
      } else if (response.data.result == "warning") {
        setConfirmationDialogData({
          header: "Meeting Already Exist",
          text: response.data.message,
        });
        setShowConfirmationBox(true);
        setDisabled(false);
      } else if (response.data.result == "error") {
        setFailDialogData({
          header: "Oops !!",
          text: response.data.message,
        });
        setShowIncubatorFailureModal(true);
        setDisabled(false);
      } else {
        setFailDialogData({
          header: "Oops !!",
          text: response.data.message,
        });
        setShowIncubatorFailureModal(true);
        setDisabled(false);
      }
    });
  };
  const finalSchedule = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    const data = {
      meeting_date: scheduleDate != "" ? scheduleDate : formatDate(new Date()),
      meeting_from_time: fromTime != "" ? fromTime : "",
      meeting_to_time: toTime != "" ? toTime : "",
      meeting_type: userDetails.status == "Selected" ? "2" : "1",
      startup_application_ids: [userDetails.id],
      link: meetingLink,
      warning_ignore: 1,
      startup_incubator_id: userInfo.id,
    };
    setDisabled(true);
    post(STARTUP_SCHEDULE_MEETING, data).then((response: any) => {
      if (!response || !response.data) return;
      setShowConfirmationBox(false);
      if (response.data.result == "success") {
        setDialogData({
          header: "Thank You!",
          text: "Your meeting has been scheduled !!!",
        });

        setShowIncubatorSuccessModal(true);
        setDisabled(false);
      } else {
        setFailDialogData({
          header: "Oops !!",
          text: response.data.message,
        });
        setShowIncubatorFailureModal(true);
        setDisabled(false);
      }
    });
  };

  // const onFileUpload = (files: Array<File>) => {
  //   let indexes: Array<string> = [];
  //   setUploadedFile([]);
  //   for (let file of files) {
  //     if (file.type === "application/pdf") {
  //       const formData = new FormData();
  //       // Update the formData object
  //       formData.append("file", file, file.name);
  // const [ar, setar] =useState<Array<any>>([]);
  const uploadSheet = (e: any, applicationID: any, type: string) => {
    // setDocumentPath((prevState) => ({
    //   ...prevState,
    //   evaluation_sheet:[]}))
    // console.log(documentPath,"900")

    var fileData: any = [""];
    var fileData = e.target.files;
    if (fileData.length <= 10) {
      let formData = new FormData();
      formData.append("logged_user_id", userInfo.id);
      formData.append("user_role", userInfo.roles);
      var ar: any = [];
      var newArr: any = [];
      for (const File in fileData) {
        ar.push(fileData[File]);
        var newArr = ar.slice(0, -2);
      }
      // console.log(newArr, "newArrr")
      formData.append("startup_application_id", applicationID);
      formData.append("file_type", type);
      newArr.forEach((element: any, index: any) => {
        element.applicationID1 = applicationID;
        element.startup_application_id1 = "startup_application_id";
        // + '[' + index + ']'
        element.file_type1 = "file_type";
        // + '[' + index + ']'
        element.file1 = "file";
        // + '[' + index + ']'
        // formData.append(
        //   element.startup_application_id1,
        //   element.applicationID1,
        // );
        // formData.append(element.file_type1, type);
        formData.append(element.file1, element);
      });
      // return
      setLoader(true);
      post(UPLOAD_FILE1, formData).then((response: any) => {
        setLoader(false);
        if (!response || !response.data) return;
        if (response.data.result == "error") {
          setFailDialogData({
            header: "Oops !!",
            text: response.data.message,
          });
          setShowIncubatorFailureModal(true);
        }
        if (response.data.result == "fail") {
          setFailDialogData({
            header: "Oops !!",
            text: response.data.message,
          });
          setShowIncubatorFailureModal(true);
        }
        // let finalObj: any = {};
        // for (let i = 0; i < response.data.length; i++) {
        //   Object.assign(finalObj, response.data[i]);
        // }
        if (response.data.result == "success") {
          viewActiveApplication(applicationID);
          setDialogData({
            header: "Thank You!",
            text: response.data.message,
          });
          setShowIncubatorSuccessModal(true);
        }
      });
    } else {
      setFailDialogData({
        header: "Oops !!",
        text: "Only 10 files can be uploaded once",
      });
      setShowIncubatorFailureModal(true);
    }
  };
  const uploadSheet1 = (e: any, applicationID: any, type: string) => {
    var fileData: any = [""];
    var fileData = e.target.files;
    let formData = new FormData();
    // console.log(userDetails)
    formData.append("logged_user_id", userDetails.selected_incubator_id);
    formData.append("user_role", userInfo.roles);
    var ar: any = [];
    var newArr: any = [];
    for (const File in fileData) {
      ar.push(fileData[File]);
      var newArr = ar.slice(0, -2);
    }
    newArr.forEach((element: any, index: any) => {
      if (element.type == "application/pdf") {
        element.applicationID1 = applicationID;
        element.startup_application_id1 = "startup_application_id";
        element.file_type1 = "file_type";
        element.file1 = "file";
        formData.append(
          element.startup_application_id1,
          element.applicationID1
        );
        formData.append(element.file_type1, type);
        formData.append(element.file1, element);
        setLoader(true);
        post(UPLOAD_FILE1, formData).then((response: any) => {
          setLoader(false);
          if (!response || !response.data) return;
          if (response.data.result == "error") {
            setFailDialogData({
              header: "Oops !!",
              text: response.data.message,
            });
            setShowIncubatorFailureModal(true);
          }
          if (response.data.result == "fail") {
            setFailDialogData({
              header: "Oops !!",
              text: response.data.message,
            });
            setShowIncubatorFailureModal(true);
          }
          // let finalObj: any = {};
          // for (let i = 0; i < response.data.length; i++) {
          //   Object.assign(finalObj, response.data[i]);
          // }
          if (response.data.result == "success") {
            viewActiveApplication(applicationID);
            setDialogData({
              header: "Thank You!",
              text: response.data.message,
            });
            setShowIncubatorSuccessModal(true);
          }
        });
      } else {
        setFailDialogData({
          header: "Oops !!",
          text: "Please select Only Pdf File",
        });
        setShowIncubatorFailureModal(true);
      }
    });
    // return
  };
  const closeIcon = (item: any) => {
    // console.log(item, 'event');
    post(EVAL_REMOVE, {
      application_id: applicationID,
      file_id: item.file_id,
    }).then(
      // get(`${GET_INCUBATOR_DETAILS}/8/details`).then(
      (response: any) => {
        if (!response || !response.data || response.data == "") return;
        // console.log(response.data.result);
        setShowConfirmationBox(false);
        if (response.data.result == "success") {
          setDialogData({
            header: "Thank You!",
            text: response.data.message,
          });

          setShowIncubatorSuccessModal(true);
          viewActiveApplication(applicationID);
          setDisabled(false);
        } else {
          setFailDialogData({
            header: "Oops !!",
            text: response.data.message,
          });
          setShowIncubatorFailureModal(true);
          setDisabled(false);
        }
      }
    );
  };
  const history = useHistory();
  const handleRowChange = (id: any) => {
    history.push({
      pathname: "/secretariat_startup_details",
      state: {
        application_id: id,
        logged_in_role: "secretariat",
        showPreference: showPreference,
        from: props.location.state.from,

      }
    })
    window.location.reload()
  }
  const goBack = () => {
    // event.preventDefault();
    // history.goBack();
    const states: any = localStorage.getItem("state");
    // console.log(states, "states!!!")
    history.push({
      pathname: "/secretariat_startup_list_details",
      state: JSON.parse(states),
    });
  };
  return (
    <>
      <div className="app-inside grievance-chat-wrapper">
        <fieldset disabled={loader}>
          <div className="container-fluid">
            <div className="row breadcrumb-wrapper">
              <div className="col-1"></div>
              <div className="col">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      {loggedInRole == "dpiit" && (
                        <a onClick={goBack} style={{ cursor: "pointer" }}>
                          Associated Startups
                        </a>
                        // <Link to="secretariat_startup_list">Associated Startups</Link>
                      )}
                      {loggedInRole == "incubator" && (
                        <Link
                          to="incubator_startup_list"
                          style={{ cursor: "pointer" }}
                        >
                          Startups
                        </Link>
                      )}
                      {loggedInRole == "secretariat" &&
                        showPreference == false && (
                          <a onClick={goBack} style={{ cursor: "pointer" }}>
                            Associated Startups
                          </a>
                          // <Link to="incubator_startup_list">Associated Startups</Link>
                        )}
                      {loggedInRole == "secretariat" &&
                        showPreference == true && (
                          // <a onClick={goBack} style={{ cursor: 'pointer' }}>Associated Startups</a>
                          <Link to="secretariat_startup_list"> Startups</Link>
                        )}
                      {loggedInRole == "eac" && <>Associated Startups</>}
                    </li>
                    {/* {console.log(userDetails,"userDetaislllll")} */}
                    <li className="breadcrumb-item active" aria-current="page">
                      {userDetails.name}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div className="content-section">
              <div className="row">
                <div className="col-1"></div>
                <div className="col-3 left-section">
                  <div className="grey-box">
                    <div className="box-heading">
                      <h4 className="box-main-heading">
                        {userDetails.name != "" ? userDetails.name : "-"}
                      </h4>
                      {/* <span className="box-main-subheading">Mumbai, Maharashtra</span> */}
                    </div>
                    <div className="box-content">
                      <div className="content-list">
                        <label className="label-heading d-block">
                          Application Number
                        </label>
                        <span className="content-value">{userDetails.id}</span>&nbsp;&nbsp;
                        {loggedInRole == "secretariat" && otherApplications.length != 0 &&
                          <a
                            className="incubator-details-link"
                            data-toggle="modal"
                            data-target="#otherApplications"
                          >
                            + {otherApplications.length} Application{otherApplications.length > 1 && <>s</>}
                          </a>}
                      </div>
                      <div className="content-list">
                        <label className="label-heading d-block">Status</label>
                        <span className="content-value">
                          {userDetails.status != "" ? userDetails.status : "-"}
                        </span>
                        {userDetails.created_at < currentdatenew}{" "}
                        {/* (userDetails.status === 'Cancelled' ||(userDetails.created_at < currentdatenew && userDetails.status === 'Rejected')) */}
                        {loggedInRole != "dpiit" &&
                          isEditable &&
                          (userDetails.status === "Cancelled" ||
                            (userDetails.FortyFiveDays < currentdatenew &&
                              userDetails.status != "Selected" && userDetails.status != "Closed") ? (
                            <> </>
                          ) : (
                            <a
                              className="content-link ml-3"
                              onClick={() => quickEditData()}
                              data-toggle="modal"
                              data-target="#quickEdit"
                            >
                              Edit
                            </a>
                          ))}
                      </div>
                      <div className="content-list">
                        <label className="label-heading d-block">
                          Application Date
                        </label>
                        <span className="content-value">
                          {userDetails.created_at != ""
                            ? FormatDate(userDetails.created_at)
                            : "-"}
                        </span>
                      </div>
                      <div className="content-list">
                        <label className="label-heading d-block">
                          Date of Evaluation
                        </label>
                        <span className="content-value">
                          {userDetails.created_at != ""
                            ? FormatDate(userDetails.FortyFiveDays)
                            : "-"}
                        </span>
                      </div>
                      <div className="content-list">
                        <label className="label-heading d-block">
                          Startup Email
                        </label>
                        <span className="content-value d-block">
                          {userDetails.email != "" ? userDetails.email : "-"}
                        </span>
                        {/* <span className="content-link">Email</span> */}
                        {loggedInRole != "dpiit" && isEditable && (
                          <a
                            href={"mailto:" + userDetails.email}
                            className="content-link"
                          >
                            Email
                          </a>
                        )}
                      </div>
                      {/* <div className="content-list">
                      <label className="label-heading d-block">
                        Status of Engagement
                      </label>
                      <span className="content-value">
                        {typeof userDetails.engagement_status != 'undefined'
                          ? userDetails.engagement_status
                          : '-'}
                      </span>
                      {typeof userDetails.engagement_status != 'undefined' &&
                        loggedInRole != 'dpiit' &&
                        isEditable && (
                          <a
                            className="content-link ml-3"
                            data-toggle="modal"
                            data-target="#quickEditEngagement"
                            onClick={() => quickEditData()}>
                            Edit
                          </a>
                        )}
                    </div> */}
                    </div>
                  </div>

                  {loader && (
                    <div
                      className="spinner-border custom-spinner"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}

                  <div className="grey-box">
                    <div className="box-content">
                      {(userDetails.status == "Selected" ||
                        userDetails.status == "Closed") && (
                          <div className="content-list">
                            <label className="label-heading d-block">
                              Amount Approved
                            </label>
                            <span className="content-value">
                              {changeNumberFormat(
                                Number(
                                  incubatorPreferanceData.total_approved_amount
                                ),
                                2,
                                1
                              )}
                            </span>
                          </div>
                        )}
                      {(userDetails.status == "Selected" ||
                        userDetails.status == "Closed") && (
                          <div className="content-list">
                            <label className="label-heading d-block">
                              Amount Disbursed
                            </label>
                            <span className="content-value">
                              {/* &#8377; */}
                              <label className="fund-amount-label-value">
                                {changeNumberFormat(
                                  Number(
                                    incubatorPreferanceData.total_disbursed_amount
                                  ),
                                  2,
                                  1
                                )}
                              </label>
                            </span>
                          </div>
                        )}

                      {/* <div className="content-list">
                      <label className="label-heading d-block">
                        Evaluation Sheet
                        <span
                          className="link-orange-text link-underline ml-2"
                          onClick={() =>
                            downloadDocument(
                              evaluationSheetPath,
                              'Evaluation_Sheet',
                            )
                          }>
                          <u>Download Sample</u>
                        </span>
                      </label>
                      {isEditable ? (
                        <label
                          htmlFor={'inputDisbursementUpload_sheet'}
                          className="tertiary btn-yellow-primary c-pointer">
                          <input
                            id="inputDisbursementUpload_sheet"
                            className="d-none"
                            type="file"
                            multiple={true}
                            onChange={e =>
                              uploadSheet(e, userDetails.id, 'evaluation_sheet')
                            }
                          />
                          UPLOAD SHEET
                        </label>
                      ) : (
                        <label
                          htmlFor={'inputDisbursementUpload_sheet'}
                          className="tertiary btn-yellow-primary c-pointer">
                          UPLOAD SHEET
                        </label>
                      )}
                    </div> */}

                      {loggedInRole == "secretariat" ? (
                        <></>
                      ) : loggedInRole == "incubator" &&
                        event < currentdatenew &&
                        (isClick == "2" ||
                          isClick == "3" ||
                          isClick == "4" ||
                          isClick == "5" ||
                          isClick == "6" ||
                          (flags == "1" && isPref == "7" && isClick == "7")) ? (
                        <div className="content-list">
                          <label className="label-heading d-block">
                            Evaluation Sheet
                            <span
                              className="link-orange-text ml-2"
                            // onClick={() =>
                            //   downloadDocument(
                            //     evaluationSheetPath,
                            //     'Evaluation_Sheet',
                            //   )
                            // }
                            >
                              <a
                                style={{ color: "#e26640" }}
                                target="_blank"
                                href={`${NODE_URL}` + evaluationSheetPath}
                              >
                                <u>Download Sample</u>
                              </a>
                              {/* <u>Download Sample</u> */}
                            </span>
                          </label>
                          {isEditable ? (
                            <label
                              htmlFor={"inputDisbursementUpload_sheet"}
                              className="tertiary btn-yellow-primary btn-yellow-disabled"
                            >
                              <input
                                id="inputDisbursementUpload_sheet"
                                className="d-none"
                                type="file"
                                multiple={true}
                              />
                              UPLOAD SHEET
                            </label>
                          ) : (
                            <label
                              htmlFor={"inputDisbursementUpload_sheet"}
                              className="tertiary btn-yellow-primary btn-yellow-disabled"
                            >
                              UPLOAD SHEET
                            </label>
                          )}
                        </div>
                      ) : userDetails.status == "Cancelled" ||
                        userDetails.status == "Closed" ? (
                        <div className="content-list">
                          <label className="label-heading d-block">
                            Evaluation Sheet
                            <span
                              className="link-orange-text ml-2"
                            // onClick={() =>
                            //   downloadDocument(
                            //     evaluationSheetPath,
                            //     'Evaluation_Sheet',
                            //   )
                            // }
                            >
                              <a
                                style={{ color: "#e26640" }}
                                target="_blank"
                                href={`${NODE_URL}` + evaluationSheetPath}
                              >
                                <u>Download Sample</u>
                              </a>
                              {/* <u>Download Sample</u> */}
                            </span>
                          </label>
                          {isEditable ? (
                            <label
                              htmlFor={"inputDisbursementUpload_sheet"}
                              className="tertiary btn-yellow-primary btn-yellow-disabled"
                            >
                              <input
                                id="inputDisbursementUpload_sheet"
                                className="d-none"
                                type="file"
                                multiple={true}
                              />
                              UPLOAD SHEET
                            </label>
                          ) : (
                            <label
                              htmlFor={"inputDisbursementUpload_sheet"}
                              className="tertiary btn-yellow-primary btn-yellow-disabled"
                            >
                              UPLOAD SHEET
                            </label>
                          )}
                        </div>
                      ) : userDetails.status == "Submitted" ||
                        userDetails.status == "On Hold" ||
                        userDetails.status == "Marked Incomplete" ||
                        userDetails.status == "Rejected" ? (
                        <div className="content-list">
                          <label className="label-heading d-block">
                            Evaluation Sheet
                            <span
                              className="link-orange-text ml-2"
                            // onClick={() =>
                            //   downloadDocument(
                            //     evaluationSheetPath,
                            //     'Evaluation_Sheet',
                            //   )
                            // }
                            >
                              <a
                                style={{ color: "#e26640" }}
                                target="_blank"
                                href={`${NODE_URL}` + evaluationSheetPath}
                              >
                                <u>Download Sample</u>
                              </a>
                              {/* <u>Download Sample</u> */}
                            </span>
                          </label>
                          {isEditable ? (
                            <label
                              htmlFor={"inputDisbursementUpload_sheet"}
                              className="tertiary btn-yellow-primary btn-yellow-disabled"
                            >
                              <input
                                id="inputDisbursementUpload_sheet"
                                className="d-none"
                                type="file"
                                multiple={true}
                              />
                              UPLOAD SHEET
                            </label>
                          ) : (
                            <label
                              htmlFor={"inputDisbursementUpload_sheet"}
                              className="tertiary btn-yellow-primary btn-yellow-disabled"
                            >
                              UPLOAD SHEET
                            </label>
                          )}
                        </div>
                      ) : (
                        <div className="content-list">
                          <label className="label-heading d-block">
                            Evaluation Sheet
                            <span
                              className="link-orange-text link-underline ml-2"
                            // onClick={() =>
                            //   downloadDocument(
                            //     evaluationSheetPath,
                            //     'Evaluation_Sheet',
                            //   )
                            // }
                            >
                              <a
                                style={{ color: "#e26640" }}
                                target="_blank"
                                href={`${NODE_URL}` + evaluationSheetPath}
                              >
                                <u>Download Sample</u>
                              </a>
                              {/* <u>Download Sample</u> */}
                            </span>
                          </label>
                          {isEditable ? (
                            <label
                              htmlFor={"inputDisbursementUpload_sheet"}
                              className={
                                "tertiary btn-yellow-primary c-pointer"
                              }
                            >
                              <input
                                id="inputDisbursementUpload_sheet"
                                className="d-none"
                                type="file"
                                multiple={true}
                                onChange={(e) =>
                                  uploadSheet(
                                    e,
                                    userDetails.id,
                                    "evaluation_sheet"
                                  )
                                }
                              />
                              UPLOAD SHEET
                            </label>
                          ) : (
                            <label
                              htmlFor={"inputDisbursementUpload_sheet"}
                              className="tertiary btn-yellow-primary c-pointer"
                            >
                              UPLOAD SHEET
                            </label>
                          )}
                        </div>
                      )}

                      {loggedInRole == "secretariat" ? (
                        <></>
                      ) : (
                        (userDetails.status == "Selected" ||
                          userDetails.status == "Closed") && (
                          <div className="content-list">
                            <label className="label-heading d-block">
                              Legal Agreement Signed by Incubator
                              <span
                                className="link-orange-text link-underline ml-2"
                              // onClick={() =>
                              //   downloadDocument(
                              //     legalAgreementPath,
                              //     'Legal_Agreement',
                              //   )
                              // }
                              >
                                {/* <a
                                style={{ color: "#e26640" }}
                                target="_blank"
                                href={`${NODE_URL}` + legalAgreementPath}
                              >
                                <u>Download Sample</u>
                              </a> */}
                                {/* <u>Download Sample</u> */}
                              </span>
                            </label>
                            {isEditable ? (
                              <label
                                htmlFor={
                                  "inputDisbursementUpload_legal_agreement"
                                }
                                className="tertiary btn-yellow-primary c-pointer"
                              >
                                <input
                                  id="inputDisbursementUpload_legal_agreement"
                                  className="d-none"
                                  type="file"
                                  onChange={(e) =>
                                    uploadSheet1(
                                      e,
                                      userDetails.id,
                                      "legal_agreement"
                                    )
                                  }
                                />
                                Upload Agreement
                              </label>
                            ) : (
                              <label
                                htmlFor={
                                  "inputDisbursementUpload_legal_agreement"
                                }
                                className="tertiary btn-yellow-primary c-pointer"
                              >
                                Upload Agreement
                              </label>
                            )}
                          </div>
                        )
                      )}
                      {
                        (cancellationData.length != 0 && Object.keys(cancellationList).length == 0) ? '' :
                          (flags != '1' && isPref == '7' &&
                            moment(iseval).format("YYYY-MM-DD") < currentdatenew &&
                            userDetails.status == "Selected") ?
                            loggedInRole == 'incubator' &&
                            userDetails.selected_incubator_id == userInfo.id &&
                            (
                              <div className="content-list">
                                <label className="label-heading d-block">
                                  Request Cancellation from the Secretariat
                                </label>
                                {
                                  cancellationList.length > 0 && cancellationList[0].status_id == '3' ?
                                    <>
                                      <p className="cancellation_label">Cancellation Request Rejected</p>
                                      <a
                                        style={{ fontSize: '12px', textDecoration: 'none' }}
                                        className="tertiary btn-yellow-primary c-pointer mt-2"
                                        data-toggle="modal"
                                        data-target="#CancellationRequest"
                                      >
                                        Request For Cancellation
                                      </a>
                                    </>
                                    :
                                    cancellationList.length > 0 && cancellationList[0].status_id == '2' ?
                                      <p className="cancellation_label">Cancellation Request Approved</p> :
                                      cancellationList.length > 0 && cancellationList[0].status_id == '1' ?
                                        <p className="cancellation_label">Cancellation Requested </p> :
                                        <a
                                          style={{ fontSize: '12px', textDecoration: 'none' }}
                                          className="tertiary btn-yellow-primary c-pointer"
                                          data-toggle="modal"
                                          data-target="#CancellationRequest"
                                        >
                                          Request For Cancellation
                                        </a>
                                }

                              </div>)
                            : ""
                      }
                    </div>
                  </div>
                </div>
                <div className="col-7 right-section">
                  <div className="startup-details-wrapper">
                    <div className="mb-4 clearfix">
                      {/* {console.log(isClick, isPref, flags, 'sleeee')} */}
                      {loggedInRole == "incubator" &&
                        // ((event < currentdatenew)&&(!isEditable))
                        event < currentdatenew &&
                        (isClick == "2" ||
                          isClick == "3" ||
                          isClick == "4" ||
                          isClick == "5" ||
                          isClick == "6" ||
                          (flags == "1" && isPref == "7" && isClick == "7")) ? (
                        <button className="tertiary pull-right btn-yellow-primary btn-yellow-disabled">
                          SCHEDULE A MEETING
                        </button>
                      ) : loggedInRole == "secretariat" ? (
                        <></>
                      ) : userDetails.status == "Cancelled" ||
                        userDetails.status == "Closed" ? (
                        <>
                          <button className="tertiary pull-right btn-yellow-primary btn-yellow-disabled">
                            SCHEDULE A MEETING
                          </button>
                        </>
                      ) : userDetails.status == "Submitted" ||
                        userDetails.status == "On Hold" ||
                        userDetails.status == "Marked Incomplete" ||
                        userDetails.status == "Rejected" ? (
                        <>
                          <button className="tertiary pull-right btn-yellow-primary btn-yellow-disabled">
                            SCHEDULE A MEETING
                          </button>
                        </>
                      ) : (
                        <button
                          className="tertiary pull-right btn-yellow-primary"
                          data-toggle="modal"
                          data-target="#scheduleMeeting"
                        >
                          SCHEDULE A MEETING
                        </button>
                      )}
                    </div>
                    {/* {console.log(userDetails.status,"status")} */}
                    {/* DETAILS TABS START */}
                    <div className="details-tabs">
                      <ul
                        className="nav nav-tabs d-flex justify-content-around"
                        id="myTab"
                        role="tablist"
                      >
                        {/* {console.log(userDetails.selected_incubator_id, userInfo.id , "############@@@@@@@@")}
                      {console.log(userDetails.status ,applicationDetails, "@@@@@@@@@@@@@@@@")}  */}
                        {(userDetails.selected_incubator_id == userInfo.id ||
                          userInfo.roles == "secretariat") &&
                          (userDetails.status == "Selected" ||
                            userDetails.status == "Closed") && (
                            <li className="nav-item">
                              <a
                                className={
                                  userDetails.quaterly_Condition == "1" &&
                                    (userDetails.quaterly_lengthConditions
                                      .length == 0 ||
                                      userDetails.quaterly_progress == "0")
                                    ? "orange_link_Details"
                                    : userDetails.quaterly_Condition == "1" &&
                                      (userDetails.quaterly_lengthConditions
                                        .length == 0 ||
                                        userDetails.quaterly_progress == "1")
                                      ? "green_link_Details"
                                      : activeTab1 == "1"
                                        ? "nav-link active"
                                        : "nav-link"
                                }
                                onClick={() => {
                                  tabActive("1");
                                }}
                                data-toggle="tab"
                                href="#details_tab_3"
                                role="tab"
                                aria-selected="false"
                                style={{
                                  textDecoration: "none",
                                  color: "#000000",
                                }}
                              >
                                Progress Overview
                                {/* <br />
                              Overview */}
                              </a>
                            </li>
                          )}
                        {(userDetails.selected_incubator_id == userInfo.id ||
                          userInfo.roles == "secretariat") &&
                          (userDetails.status == "Selected" ||
                            userDetails.status == "Closed" ||
                            userDetails.status == "Cancelled") &&
                          applicationDetails.data.data.Application_Details.Other
                            .selected_incubator_id != null && (
                            <li className="nav-item">
                              <a
                                className={
                                  userDetails.quaterly_Condition == "1" &&
                                    (userDetails.quaterly_lengthConditions
                                      .length == 0 ||
                                      userDetails.quaterly_Tranche == "0") &&
                                    userDetails.status != "Cancelled"
                                    ? "orange_link_Details"
                                    : userDetails.quaterly_Condition == "1" &&
                                      (userDetails.quaterly_lengthConditions
                                        .length == 0 ||
                                        userDetails.quaterly_Tranche == "1") &&
                                      userDetails.status != "Cancelled"
                                      ? "green_link_Details"
                                      : activeTab1 == "2"
                                        ? "nav-link active"
                                        : "nav-link"
                                }
                                onClick={() => {
                                  tabActive("2");
                                }}
                                data-toggle="tab"
                                href="#details_tab_2"
                                role="tab"
                                aria-selected="false"
                                style={{
                                  textDecoration: "none",
                                  color: "#000000",
                                }}
                              >
                                Tranche Details
                                {/* <br />
                              Details */}
                              </a>
                            </li>
                          )}

                        {/* {userInfo.roles=="secretariat" &&
                        (userDetails.status == 'Selected' ||
                          userDetails.status == 'Closed' || userDetails.status == 'Cancelled') &&
                        applicationDetails.data.data.Application_Details.Other
                          .selected_incubator_id != null && (
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#details_tab_2"
                              role="tab"
                              aria-selected="false">
                              Tranche
                              <br />
                              Details
                            </a>
                          </li>
                        )} */}
                        {(userDetails.selected_incubator_id == userInfo.id ||
                          userInfo.roles == "secretariat") &&
                          (userDetails.status == "Selected" ||
                            userDetails.status == "Closed") &&
                          applicationDetails.data.data.Application_Details
                            .FundingDetails.instrument_approved !=
                          "funding_grant" && (
                            <li className="nav-item">
                              <a
                                className={
                                  userDetails.quaterly_Condition == "1" &&
                                    (userDetails.quaterly_lengthConditions
                                      .length == 0 ||
                                      userDetails.quaterly_returns == "0")
                                    ? "orange_link_Details"
                                    : userDetails.quaterly_Condition == "1" &&
                                      (userDetails.quaterly_lengthConditions
                                        .length == 0 ||
                                        userDetails.quaterly_returns == "1")
                                      ? "green_link_Details"
                                      : activeTab1 == "3"
                                        ? "nav-link active"
                                        : "nav-link"
                                }
                                onClick={() => {
                                  tabActive("3");
                                }}
                                data-toggle="tab"
                                href="#details_tab_Returns"
                                role="tab"
                                aria-selected="false"
                                style={{
                                  textDecoration: "none",
                                  color: "#000000",
                                }}
                              >
                                Returns
                              </a>
                            </li>
                          )}

                        {/* {userDetails.selected_incubator_id != userInfo.id &&
                        (userDetails.status == 'Draft' || userDetails.status == 'Submitted' || userDetails.status == 'Pending Review' || 
                        userDetails.status == 'Marked Incomplete' || userDetails.status == 'Rejected' || userDetails.status == 'On Hold') &&
                        ( */}
                        <li className="nav-item">
                          <a
                            onClick={() => {
                              tabActive("4");
                            }}
                            className={
                              activeTab1 == "4" ? "nav-link active" : "nav-link"
                            }
                            data-toggle="tab"
                            href="#details_tab_5"
                            role="tab"
                            aria-selected="false"
                          >
                            Application Details
                            {/* <br /> */}
                            {/* Details */}
                          </a>
                        </li>
                        {/* )} */}
                        {/* meetingAllData.length != 0 && */}
                        {
                          <li className="nav-item">
                            <a
                              onClick={() => {
                                tabActive("5");
                              }}
                              className={
                                activeTab1 == "5"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              data-toggle="tab"
                              href="#details_tab_1"
                              role="tab"
                              aria-selected="true"
                            >
                              Meetings
                            </a>
                          </li>
                        }
                        {cancellationData.length != 0 && Object.keys(cancellationList).length < 1 && (
                          <li className="nav-item">
                            <a
                              onClick={() => {
                                tabActive("6");
                              }}
                              className={
                                activeTab1 == "6"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              data-toggle="tab"
                              href="#details_tab_4"
                              role="tab"
                              aria-selected="false"
                            >
                              Cancellation
                              <br />
                              Request
                            </a>
                          </li>
                        )}

                        <li className="nav-item">
                          <a
                            onClick={() => {
                              tabActive("7");
                            }}
                            className={
                              activeTab1 == "7" ? "nav-link active" : "nav-link"
                            }
                            data-toggle="tab"
                            href="#details_tab_6"
                            role="tab"
                            aria-selected="false"
                          >
                            Documents
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            onClick={() => {
                              tabActive("8");
                            }}
                            className={
                              activeTab1 == "8" ? "nav-link active" : "nav-link"
                            }
                            data-toggle="tab"
                            href="#details_tab_7"
                            role="tab"
                            aria-selected="false"
                          >
                            Contact
                          </a>
                        </li>
                        {
                          <li className="nav-item">
                            <a
                              onClick={() => {
                                tabActive("9");
                              }}
                              className={
                                activeTab1 == "9"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              data-toggle="tab"
                              href="#details_tab_8"
                              role="tab"
                              aria-selected="false"
                            >
                              Comments
                            </a>
                          </li>
                        }
                        {/* {console.log(preferenceData)}
                      {console.log(preferenceData.length)}
                      {console.log(showPreference)} */}
                        {/* && showPreference */}
                        {preferenceData.length != 0 && (
                          <li className="nav-item">
                            <a
                              onClick={() => {
                                tabActive("10");
                              }}
                              className={
                                activeTab1 == "10"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              data-toggle="tab"
                              href="#details_tab_9"
                              role="tab"
                              aria-selected="false"
                            >
                              Incubator Preferences
                              {/* <br />
                            Preferences */}
                            </a>
                          </li>
                        )}
                      </ul>
                      <div className="tab-content details-tabs-content">
                        {/* {console.log(applicationComments,"applicationComments")} */}
                        <div
                          className={
                            activeTab1 == "5"
                              ? "tab-pane fade show active"
                              : "tab-pane fade show"
                          }
                          id="details_tab_1"
                          role="tabpanel"
                        >
                          <MeetingTab
                            meetingData={meetingAllData}
                            startup_application_id={userDetails.id}
                            applicationComments={applicationComments}
                            statusId={isClick}
                            evDate={iseval}
                            isPrefer={isPref}
                            flag={flags}
                          />

                          {/* <div className="tab-pane-header">
                                                    Meetings
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="tab-pane-label-group">
                                                            <div className="tab-pane-label">
                                                                Performance Evaluation
                                                             </div>
                                                            <div className="tab-pane-value-year-time">
                                                                15 Jan 2021  |  10:30am - 11:30am
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane-label-group">
                                                            <div className="tab-pane-label-feedback">
                                                                Comments/Feedback
                                                             </div>
                                                            <div className="tab-pane-value-text mb-2">
                                                                Sed consequat vestibulum sem, sed maximus tellus molestie eu. Pellentesque a fermentum lacus. Quisque eros est, cursus non varius id, vulputate in mauris.
                                                            </div>
                                                            <a className="tab-pane-link">Add Comment</a>
                                                        </div>
                                                        <hr />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-8">
                                                        <div className="tab-pane-label-group">
                                                            <div className="tab-pane-label">
                                                                Startup Selection
                                                             </div>
                                                            <div className="tab-pane-value-year-time">
                                                                3 Oct 2020  |  02:30pm - 04:30pm <a className="tab-pane-link ml-2">Edit</a>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane-label-group">
                                                            <div className="tab-pane-label-feedback">
                                                                Comments/Feedback
                                                             </div>
                                                            <div className="tab-pane-value-text mb-2">
                                                                Sed consequat vestibulum sem, sed maximus tellus molestie eu. Pellentesque a fermentum lacus. Quisque eros est, cursus non varius id, vulputate in mauris.
                                                            </div>
                                                            <a className="tab-pane-link">Add Comment</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="tab-pane-label-group">
                                                            <div className="tab-pane-label-small">
                                                                Funds Requested
                                                             </div>
                                                            <div className="tab-pane-value-highlight">
                                                                ₹1 Cr
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane-label-group">
                                                            <div className="tab-pane-label-small">
                                                                Funds Approved
                                                             </div>
                                                            <div className="tab-pane-value-highlight">
                                                                ₹1 Cr
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                        </div>
                        {/* className="tab-pane fade active show" */}
                        <div
                          // className="tab-pane fade"
                          className={
                            activeTab1 == "2"
                              ? "tab-pane fade show active"
                              : "tab-pane show fade"
                          }
                          id="details_tab_2"
                          role="tabpanel"
                        >
                          <StartupFundDetailsComponent
                            application_id={Number(applicationID)}
                            total_grant_approved={Number(
                              incubatorPreferanceData.total_approved_amount
                            )}
                            trancheData={tranches}
                            trancheDataASC={tranchesAsc}
                            accountDetailsData={bankDetails}
                            application_status={userDetails.status}
                            total_grant_disbursed={totalGrantDisbursed}
                            remainingAmount={Number(
                              incubatorPreferanceData.remaining_amounts
                            )}
                            trancheNumber={Number(
                              incubatorPreferanceData.no_of_tranches
                            )}
                            loggedInRole={loggedInRole}
                            applicationDetails={applicationDetails}
                            statusId={isClick}
                            evDate={iseval}
                            isPrefer={isPref}
                            flag={flags}
                            quaterlyReports={
                              userDetails.quaterly_lengthConditions
                            }
                            qrDetails={QRIntiatedDetails}
                            onChangeData={(data: any) => {
                              if (data == '') {
                                return
                              } else {

                                getAllTranches(data);
                                viewActiveApplication(data);
                              }
                            }}
                            disbursedToStartup={disbursedToStartups}
                            disbursedToInc={disbursedToIncs}
                          />
                          {/* <StartupFundDetailsContainer
                          
                          statusId={isClick}
                          evDate={iseval}
                          isPrefer={isPref}
                        /> */}

                          {/* <div className="clearfix mb-4">
                                                    <div className="tab-pane-total tab-pane-total-spacing pull-left">
                                                        <div className="tab-pane-total-count">₹<span>12.6</span> Cr</div>
                                                        <div className="tab-pane-total-text">Total Grant Approved</div>
                                                        <a className="tab-pane-link">Edit</a>
                                                    </div>
                                                    <div className="tab-pane-total pull-left">
                                                        <div className="tab-pane-total-count"><span>4</span></div>
                                                        <div className="tab-pane-total-text">Total No. of Tranches</div>
                                                        <a className="tab-pane-link">Add More Tranche</a>
                                                        <a
                                                            className="incubator-details-link"
                                                            data-target="#addMoreTranchePopUp"
                                                            data-toggle="modal"
                                                        >
                                                            Add More Tranche
                                                        </a>
                                                    </div>
                                                </div> */}

                          {/* <div className="tab-pane-header">
                                                    No. of Tranches
                                                </div> */}
                          {/*  TRANCHE DETAILS ACCORDION START*/}
                          {/* <div className="accordion accordion-details-wrap" id="accordion-tranche-details">
                                                    <div className="card accordion-card">
                                                        <div className="card-header accordion-card-header" data-toggle="collapse" data-target="#tranche_collapse_1" aria-expanded="true">
                                                            Tranche 4 <span>Disbursement due within 24 days ( 28 Mar 2021 )</span>
                                                        </div>
                                                        <div id="tranche_collapse_1" className="collapse show" data-parent="#accordion-tranche-details">
                                                            <div className="card-body accordion-card-body">
                                                                <div className="row">
                                                                    <div className="col-lg-6">
                                                                        <div className="startup-info-group">
                                                                            <div className="startup-info-group-label">
                                                                                Date Requested
                                                                            </div>
                                                                            <div className="startup-info-group-value">
                                                                                28 Feb 2020
                                                                            </div>
                                                                        </div>
                                                                        <div className="startup-info-group">
                                                                            <div className="startup-info-group-label">
                                                                                Fund Requested
                                                                            </div>
                                                                            <div className="startup-info-group-value">
                                                                                ₹40 L
                                                                            </div>
                                                                        </div>
                                                                        <div className="startup-info-group">
                                                                            <div className="startup-info-group-label">
                                                                                Tranche Amount Approved
                                                                            </div>
                                                                            <div className="startup-info-group-value">
                                                                                ₹30 L <a className="tab-pane-link ml-2">Edit</a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="startup-info-group">
                                                                            <div className="startup-info-group-label">
                                                                                Funds Released in Tranche
                                                                            </div>
                                                                            <div className="startup-info-group-value">
                                                                                NA <a className="tab-pane-link ml-2">Edit</a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="startup-info-group">
                                                                            <div className="startup-info-group-label">
                                                                                Date of Tranche Release
                                                                            </div>
                                                                            <div className="startup-info-group-value">
                                                                                NA <a className="tab-pane-link ml-2">Edit</a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="startup-info-group">
                                                                            <div className="startup-info-group-label-highlight">
                                                                                Supporting Documents
                                                                            </div>
                                                                            <div className="document-info document-pdf-icon">
                                                                                <div className="document-info-name">Application-No.422copy2final.pdf</div>
                                                                                <a className="tab-pane-link">Download</a>
                                                                            </div>
                                                                            <div className="document-info document-pdf-icon">
                                                                                <div className="document-info-name">Application-No.422copy2final.pdf</div>
                                                                                <a className="tab-pane-link">Download</a>
                                                                            </div>
                                                                            <div className="document-info document-pdf-icon">
                                                                                <div className="document-info-name">Application-No.422copy2final.pdf</div>
                                                                                <a className="tab-pane-link">Download</a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="startup-info-group">
                                                                            <div className="startup-info-group-label">
                                                                                Status
                                                                            </div>
                                                                            <div className="startup-info-group-value">
                                                                                Verification of Documents in Process <a className="tab-pane-link">Update</a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="startup-info-group">
                                                                            <div className="startup-info-group-label">
                                                                                Interest Accumulated on Funds
                                                                            </div>
                                                                            <div className="startup-info-group-value">
                                                                                NA
                                                                            </div>
                                                                        </div>
                                                                        <div className="startup-info-group">
                                                                            <div className="startup-info-group-label">
                                                                                Proof of Disbursement
                                                                            </div>
                                                                            <div className="startup-info-group-value">
                                                                                NA <a className="tab-pane-link ml-2">Upload Proof</a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="startup-info-group">
                                                                            <div className="startup-info-group-label-big">
                                                                                Is cash-in-hand of the incubator below 10% of the total commitment by EAC?
                                                                            </div>
                                                                            <div className="startup-info-group-value">
                                                                                Yes
                                                                            </div>
                                                                        </div>
                                                                        <div className="startup-info-group">
                                                                            <div className="startup-info-group-label-highlight">
                                                                                Comments/Feedback
                                                                            </div>
                                                                            <div className="startup-info-group-value-text">
                                                                                Sed consequat vestibulum sem, sed maximus tellus molestie eu. Pellentesque a fermentum lacus. Quisque eros est, cursus non varius id, vulputate in mauris.
                                                                            </div>
                                                                            <a className="tab-pane-link">Add Comment</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card accordion-card">
                                                        <div className="card-header accordion-card-header collapsed" data-toggle="collapse" data-target="#tranche_collapse_2" aria-expanded="false">
                                                            Tranche 3
                                                        </div>
                                                        <div id="tranche_collapse_2" className="collapse" data-parent="#accordion-tranche-details">
                                                            <div className="card-body accordion-card-body">
                                                                Some placeholder content for the second accordion panel. This panel is hidden by default.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card accordion-card">
                                                        <div className="card-header accordion-card-header collapsed" data-toggle="collapse" data-target="#tranche_collapse_3" aria-expanded="false">
                                                            Tranche 2
                                                        </div>
                                                        <div id="tranche_collapse_3" className="collapse" data-parent="#accordion-tranche-details">
                                                            <div className="card-body accordion-card-body">
                                                                And lastly, the placeholder content for the third and final accordion panel. This panel is hidden by default.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card accordion-card">
                                                        <div className="card-header accordion-card-header collapsed" data-toggle="collapse" data-target="#tranche_collapse_4" aria-expanded="false">
                                                            Tranche 1
                                                        </div>
                                                        <div id="tranche_collapse_4" className="collapse" data-parent="#accordion-tranche-details">
                                                            <div className="card-body accordion-card-body">
                                                                And lastly, the placeholder content for the third and final accordion panel. This panel is hidden by default.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                          {/*TRANCHE DETAILS ACCORDION END */}
                        </div>
                        {/* {console.log(userDetails)} */}
                        {(userDetails.selected_incubator_id == userInfo.id ||
                          userInfo.roles == "secretariat") &&
                          (userDetails.status == "Selected" ||
                            userDetails.status == "Closed") &&
                          applicationDetails.data.data.Application_Details
                            .FundingDetails.instrument_approved !=
                          "funding_grant" && (
                            <div
                              // className="tab-pane fade"
                              className={
                                activeTab1 == "3"
                                  ? "tab-pane fade active show"
                                  : "tab-pane show fade"
                              }
                              id="details_tab_Returns"
                              role="tabpanel"
                            >
                              <ReturnsFields
                                application_id={applicationID}
                                quaterlyReports_returns={
                                  userDetails.quaterly_lengthConditions
                                }
                                qrDetails={QRIntiatedDetails}
                                loggedInRole={loggedInRole}
                              />
                            </div>
                          )}

                        {(userDetails.status === "Selected" ||
                          userDetails.status === "Closed") && (
                            <div
                              // className="tab-pane fade"
                              className={
                                activeTab1 == "1"
                                  ? "tab-pane fade show active"
                                  : "tab-pane show fade"
                              }
                              id="details_tab_3"
                              role="tabpanel"
                            >
                              <ProgressOverviewFields
                                UserStatus={userDetails}
                                applicationId={applicationID}
                                statusId={isClick}
                                evDate={iseval}
                                isPrefer={isPref}
                                flag={flags}
                                quaterlyReports_returns={
                                  userDetails.quaterly_lengthConditions
                                }
                                qrDetails={QRIntiatedDetails}
                                loggedInRole={loggedInRole}
                              />
                            </div>
                          )}
                        <div
                          // className="tab-pane fade"
                          className={
                            activeTab1 == "6"
                              ? "tab-pane fade show active"
                              : "tab-pane show fade"
                          }
                          id="details_tab_4"
                          role="tabpanel"
                        >
                          {/* <div className="accordion-details-wrap">
                                                    <div className="card accordion-card">
                                                        <div className="card-body accordion-card-body">
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <div className="startup-info-group">
                                                                        <div className="startup-info-group-label">
                                                                            Date Requested
                                                                        </div>
                                                                        <div className="startup-info-group-value">
                                                                            28 Feb 2020
                                                                         </div>
                                                                    </div>
                                                                    <div className="startup-info-group">
                                                                        <div className="startup-info-group-label">
                                                                            Amount to be Refunded
                                                                        </div>
                                                                        <div className="startup-info-group-value">
                                                                            ₹40 L
                                                                        </div>
                                                                    </div>
                                                                    <div className="startup-info-group">
                                                                        <div className="startup-info-group-label">
                                                                            Proof for Cancellation
                                                                        </div>
                                                                        <div className="document-info document-pdf-icon">
                                                                            <div className="document-info-name">Application-No.422copy2final.pdf</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="startup-info-group">
                                                                        <div className="startup-info-group-label">
                                                                            Date of Funds Received
                                                                        </div>
                                                                        <div className="startup-info-group-value">
                                                                            28 Feb 2020 <a className="tab-pane-link ml-2">Edit</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="startup-info-group">
                                                                        <div className="startup-info-group-label">
                                                                            Status of Cancellation
                                                                        </div>
                                                                        <div className="startup-info-group-value">
                                                                            Approved <a className="tab-pane-link ml-2">Update</a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="startup-info-group">
                                                                        <div className="startup-info-group-label">
                                                                            Interest Accumulated on Funds
                                                                        </div>
                                                                        <div className="startup-info-group-value">
                                                                            ₹2000
                                                                        </div>
                                                                    </div>
                                                                    <div className="startup-info-group">
                                                                        <div className="startup-info-group-label">
                                                                            Proof of Disbursement by Incubator
                                                                        </div>
                                                                        <div className="document-info document-pdf-icon">
                                                                            <div className="document-info-name">Application-No.422copy2final.pdf</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="startup-info-group">
                                                                        <div className="startup-info-group-label">
                                                                            Proof of Funds Received by DPPIT
                                                                        </div>
                                                                        <div className="document-info document-pdf-icon">
                                                                            <div className="document-info-name">Application-No.422copy2final.pdf</div>
                                                                            <a className="tab-pane-link tab-pane-link-view">View | Upload</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className="row mt-4">
                                                                <div className="col-lg-6">
                                                                    <div className="startup-info-group">
                                                                        <div className="startup-info-group-label-highlight">Reason For Cancellation</div>
                                                                        <div className="startup-info-group-value-text">Sed consequat vestibulum sem, sed maximus tellus molestie eu. Pellentesque a fermentum lacus. Quisque eros est, cursus non varius id, vulputate in mauris.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="startup-info-group">
                                                                        <div className="startup-info-group-label-highlight">Comment by DPIIT</div>
                                                                        <div className="startup-info-group-value-text">Sed consequat vestibulum sem, sed maximus tellus molestie eu. Pellentesque a fermentum lacus. Quisque eros est, cursus non varius id, vulputate in mauris.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion accordion-details-wrap" id="accordion-cancellation-details">
                                                    <div className="card accordion-card">
                                                        <div className="card-header accordion-card-header" data-toggle="collapse" data-target="#cancellation_collapse_1" aria-expanded="true">
                                                            Startup Details
                                                        </div>
                                                        <div id="cancellation_collapse_1" className="collapse show" data-parent="#accordion-cancellation-details">
                                                            <div className="card-body accordion-card-body">
                                                                Some placeholder content for the second accordion panel. This panel is hidden by default.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card accordion-card">
                                                        <div className="card-header accordion-card-header collapsed" data-toggle="collapse" data-target="#cancellation_collapse_2" aria-expanded="false">
                                                            Authorized Representative
                                                        </div>
                                                        <div id="cancellation_collapse_2" className="collapse" data-parent="#accordion-cancellation-details">
                                                            <div className="card-body accordion-card-body">
                                                                Some placeholder content for the second accordion panel. This panel is hidden by default.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card accordion-card">
                                                        <div className="card-header accordion-card-header collapsed" data-toggle="collapse" data-target="#cancellation_collapse_3" aria-expanded="false">
                                                            Startup Team
                                                        </div>
                                                        <div id="cancellation_collapse_3" className="collapse" data-parent="#accordion-cancellation-details">
                                                            <div className="card-body accordion-card-body">
                                                                And lastly, the placeholder content for the third and final accordion panel. This panel is hidden by default.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card accordion-card">
                                                        <div className="card-header accordion-card-header collapsed" data-toggle="collapse" data-target="#cancellation_collapse_4" aria-expanded="false">
                                                            Funding Details
                                                        </div>
                                                        <div id="cancellation_collapse_4" className="collapse" data-parent="#accordion-cancellation-details">
                                                            <div className="card-body accordion-card-body">
                                                                And lastly, the placeholder content for the third and final accordion panel. This panel is hidden by default.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                          {cancellationData.length != 0 && (
                            <CancellationContainer
                              data={cancellationData}
                              role={"incubator"}
                              isEdit={
                                loggedInRole == "dpiit" || !isEditable
                                  ? false
                                  : true
                              }
                            />
                          )}
                          {/*CANCELLATION REQUEST ACCORDION END */}
                        </div>

                        {/* {userDetails.selected_incubator_id != userInfo.id &&
                        (userDetails.status == 'Draft' || userDetails.status == 'Submitted' || userDetails.status == 'Pending Review' || 
                        userDetails.status == 'Marked Incomplete' || userDetails.status == 'Rejected' || userDetails.status == 'On Hold') &&
                        ( */}
                        <div
                          // className="tab-pane fade active show"
                          className={
                            activeTab1 == "4"
                              ? "tab-pane fade show active"
                              : "tab-pane show fade"
                          }
                          id="details_tab_5"
                          role="tabpanel"
                        >
                          {/* <div className="text-right mb-2">
                                                    <button className="btn btn-sisfs btn-transparent-primary">Download Pdf</button>
                                                </div> */}

                          <StartupApplicationDetailsComponent
                            applicationDetails={applicationDetails}
                            documentPath={documentPath.legal_agreement}
                          />
                        </div>
                        {/* )} */}

                        <div
                          // className="tab-pane fade"
                          className={
                            activeTab1 == "7"
                              ? "tab-pane fade show active"
                              : "tab-pane show fade"
                          }
                          id="details_tab_6"
                          role="tabpanel"
                        >
                          {/* <div className="incubator-hr">
            <hr />
          </div> */}
                          <div className="row">
                            <div
                              id="q-1"
                              className={
                                activeIndexDoc == "1"
                                  ? "tranche-heading col-sm-11 left-align"
                                  : "reports-heading col-sm-11 left-align"
                              }
                            >
                              Authorized Representative
                            </div>
                            <div className="col-sm-1">
                              {activeIndexDoc == "1" ? (
                                <div
                                  onClick={changeActiveBackDoc}
                                  data-toggle="collapse"
                                  data-target="#qtrly-expand"
                                  role="button"
                                  aria-expanded="false"
                                  aria-controls="qtrly-expand"
                                  className="hide-icon pull-right hide-icon-details"
                                  id="show-1"
                                >
                                  <img
                                    src={Minus}
                                    className="img-fluid"
                                    alt="SISFS Logo"
                                  />
                                </div>
                              ) : (
                                <div
                                  id="hide-1"
                                  onClick={() => changeActiveDoc("1")}
                                  data-toggle="collapse"
                                  data-target="#qtrly-expand"
                                  role="button"
                                  aria-expanded="false"
                                  aria-controls="qtrly-expand"
                                  className="expand-icon pull-right expand-icon-details"
                                >
                                  <img
                                    src={Plus}
                                    className="img-fluid"
                                    alt="SISFS Logo"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          {activeIndexDoc == "1" ? (
                            <div className="collapse " id="qtrly-expand">
                              <div className="row">
                                <div className="col-md-8 left-align">
                                  <p style={{ fontSize: "14px" }}>
                                    <b>
                                      Board Resolution / Authorisation letter /
                                      PoA
                                    </b>
                                  </p>
                                  {/* {console.log(
                                  documentPath.authorisation_letter_upload,
                                  'leegal',
                                )} */}
                                  {documentPath.authorisation_letter_upload
                                    .length != 0 ? (
                                    ""
                                  ) : (
                                    <label className="incubator-label-value">
                                      -
                                    </label>
                                  )}

                                  <br />
                                  {documentPath.authorisation_letter_upload.map(
                                    (file: any, index: any) =>
                                      index < 2 ? (
                                        <div className="row">
                                          <div className="col-md-3">
                                            {getFileDetails(
                                              file.file,
                                              "type"
                                            ) == "pdf" ? (
                                              <img src={pdf} alt="SISFS Logo" />
                                            ) : getFileDetails(
                                              file.file,
                                              "type"
                                            ) == "doc" ? (
                                              <img src={doc} alt="SISFS Logo" />
                                            ) : getFileDetails(
                                              file.file,
                                              "type"
                                            ) == "xls" ? (
                                              <img src={xls} alt="SISFS Logo" />
                                            ) : (
                                              <img
                                                src={Allround}
                                                alt="SISFS Logo"
                                              />
                                            )}
                                          </div>
                                          <div className="col-md-9">
                                            <span className="document-label-value-wrap">
                                              {getFileDetails(
                                                file.file,
                                                "name"
                                              )}
                                            </span>
                                            <br />
                                            {/* {console.log(file, "fileee")} */}
                                            <a
                                              // href={file.file}
                                              href={file.file}
                                              target="_blank"
                                              //download="PitchDeck"
                                              className="incubator-details-link"
                                            >
                                              View
                                            </a>
                                            {/* /
        <a
          href="#"
          //download={file.file}
          className="incubator-details-link"
          onClick={() => downloadDocument(file.file, file.file)}
        >
          Download
        </a> */}
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )
                                  )}
                                  {documentPath.authorisation_letter_upload
                                    .length > 2 ? (
                                    <a
                                      className="incubator-details-link"
                                      data-toggle="modal"
                                      data-target="#showMoreDocument"
                                      onClick={() =>
                                        setMeetingModal(
                                          "Board Resolution / Authorisation letter / PoA"
                                        )
                                      }
                                    >
                                      More...
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                  <br />
                                  <br />
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="incubator-hr">
                            <hr />
                          </div>

                          <div className="row">
                            <div
                              id="q-2"
                              className={
                                activeIndexDoc == "2"
                                  ? "tranche-heading col-sm-11 left-align"
                                  : "reports-heading col-sm-11 left-align"
                              }
                            >
                              Startup Team
                            </div>
                            <div className="col-sm-1">
                              {activeIndexDoc == "2" ? (
                                <div
                                  onClick={changeActiveBackDoc}
                                  data-toggle="collapse"
                                  data-target="#pick-deck-expand"
                                  role="button"
                                  aria-expanded="false"
                                  aria-controls="pick-deck-expand"
                                  className="hide-icon pull-right hide-icon-details"
                                  id="show-2"
                                >
                                  <img
                                    src={Minus}
                                    className="img-fluid"
                                    alt="SISFS Logo"
                                  />
                                </div>
                              ) : (
                                <div
                                  id="hide-2"
                                  onClick={() => changeActiveDoc("2")}
                                  data-toggle="collapse"
                                  data-target="#pick-deck-expand"
                                  role="button"
                                  aria-expanded="false"
                                  aria-controls="pick-deck-expand"
                                  className="expand-icon pull-right expand-icon-details"
                                >
                                  <img
                                    src={Plus}
                                    className="img-fluid"
                                    alt="SISFS Logo"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          {activeIndexDoc == "2" ? (
                            <div className="collapse " id="pick-deck-expand">
                              <div className="row">
                                <div className="col-md-8 left-align">
                                  <p style={{ fontSize: "14px" }}>
                                    <b>Promoters Aadhaar Cards</b>
                                  </p>

                                  {documentPath.promoters_aadhar_cards.length !=
                                    0 ? (
                                    ""
                                  ) : (
                                    <label className="incubator-label-value">
                                      -
                                    </label>
                                  )}

                                  <br />
                                  {documentPath.promoters_aadhar_cards.map(
                                    (file: any, index: any) =>
                                      index < 2 ? (
                                        <div className="row">
                                          <div className="col-md-3">
                                            {getFileDetails(
                                              file.file,
                                              "type"
                                            ) == "pdf" ? (
                                              <img src={pdf} alt="SISFS Logo" />
                                            ) : getFileDetails(
                                              file.file,
                                              "type"
                                            ) == "doc" ? (
                                              <img src={doc} alt="SISFS Logo" />
                                            ) : getFileDetails(
                                              file.file,
                                              "type"
                                            ) == "xls" ? (
                                              <img src={xls} alt="SISFS Logo" />
                                            ) : (
                                              <img
                                                src={Allround}
                                                alt="SISFS Logo"
                                              />
                                            )}
                                          </div>
                                          <div className="col-md-9">
                                            <span className="document-label-value-wrap">
                                              {getFileDetails(
                                                file.file,
                                                "name"
                                              )}
                                            </span>
                                            <br />
                                            <a
                                              href={file.file}
                                              // href={`${File_node_url}` + file.file}
                                              target="_blank"
                                              //download="PitchDeck"
                                              className="incubator-details-link"
                                            >
                                              View
                                            </a>
                                            {/* /
                      <a
                        href="#"
                        //download={file.file}
                        className="incubator-details-link"
                        onClick={() => downloadDocument(file.file, file.file)}
                      >
                        Download
                      </a> */}
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )
                                  )}
                                  {documentPath.promoters_aadhar_cards.length >
                                    2 ? (
                                    <a
                                      className="incubator-details-link"
                                      data-toggle="modal"
                                      data-target="#showMoreDocument"
                                      onClick={() =>
                                        setMeetingModal(
                                          "Promoters Aadhaar Cards"
                                        )
                                      }
                                    >
                                      More...
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="incubator-hr">
                            <hr />
                          </div>
                          <div className="row">
                            <div
                              id="q-3"
                              className={
                                activeIndexDoc == "3"
                                  ? "tranche-heading col-sm-11 left-align"
                                  : "reports-heading col-sm-11 left-align"
                              }
                            >
                              Upload Documents
                            </div>
                            <div className="col-sm-1">
                              {activeIndexDoc == "3" ? (
                                <div
                                  onClick={changeActiveBackDoc}
                                  data-toggle="collapse"
                                  data-target="#startup-details-expand"
                                  role="button"
                                  aria-expanded="false"
                                  aria-controls="startup-details-expand"
                                  className="hide-icon pull-right hide-icon-details"
                                  id="show-3"
                                >
                                  <img
                                    src={Minus}
                                    className="img-fluid"
                                    alt="SISFS Logo"
                                  />
                                </div>
                              ) : (
                                <div
                                  onClick={() => changeActiveDoc("3")}
                                  id="hide-3"
                                  data-toggle="collapse"
                                  data-target="#startup-details-expand"
                                  role="button"
                                  aria-expanded="false"
                                  aria-controls="pstartup-details-expand"
                                  className="expand-icon pull-right expand-icon-details"
                                >
                                  <img
                                    src={Plus}
                                    className="img-fluid"
                                    alt="SISFS Logo"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          {activeIndexDoc == "3" ? (
                            <div
                              className="collapse "
                              id="startup-details-expand"
                            >
                              <div className="row">
                                <div className="col-md-8 left-align">
                                  <p style={{ fontSize: "14px" }}>
                                    <b>Uploaded Pitch deck of Business Idea</b>
                                  </p>

                                  {documentPath.upload_pitch_deck.length !=
                                    0 ? (
                                    ""
                                  ) : (
                                    <label className="incubator-label-value">
                                      -
                                    </label>
                                  )}

                                  <br />
                                  {documentPath.upload_pitch_deck.map(
                                    (file: any, index: any) =>
                                      index < 2 ? (
                                        <div className="row">
                                          <div className="col-md-3">
                                            {getFileDetails(
                                              file.file,
                                              "type"
                                            ) == "pdf" ? (
                                              <img src={pdf} alt="SISFS Logo" />
                                            ) : getFileDetails(
                                              file.file,
                                              "type"
                                            ) == "doc" ? (
                                              <img src={doc} alt="SISFS Logo" />
                                            ) : getFileDetails(
                                              file.file,
                                              "type"
                                            ) == "xls" ? (
                                              <img src={xls} alt="SISFS Logo" />
                                            ) : (
                                              <img
                                                src={Allround}
                                                alt="SISFS Logo"
                                              />
                                            )}
                                          </div>
                                          <div className="col-md-9">
                                            <span className="document-label-value-wrap">
                                              {getFileDetails(
                                                file.file,
                                                "name"
                                              )}
                                            </span>
                                            <br />
                                            <a
                                              // href={file.file}
                                              href={file.file}
                                              target="_blank"
                                              //download="PitchDeck"
                                              className="incubator-details-link"
                                            >
                                              View
                                            </a>
                                            {/* /
                      <a
                        href="#"
                        //download={file.file}
                        className="incubator-details-link"
                        onClick={() => downloadDocument(file.file, file.file)}
                      >
                        Download
                      </a> */}
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )
                                  )}
                                  {documentPath.upload_pitch_deck.length > 2 ? (
                                    <a
                                      className="incubator-details-link"
                                      data-toggle="modal"
                                      data-target="#showMoreDocument"
                                      onClick={() =>
                                        setMeetingModal(
                                          "Uploaded Pitch deck of Business Idea"
                                        )
                                      }
                                    >
                                      More...
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                  <br />
                                  <br />
                                </div>

                                <div className="col-md-8 left-align">
                                  <p style={{ fontSize: "14px" }}>
                                    <b>Uploaded any other relevant document</b>
                                  </p>

                                  {documentPath.upload_relavent_document_other
                                    .length != 0 ? (
                                    ""
                                  ) : (
                                    <label className="incubator-label-value">
                                      -
                                    </label>
                                  )}

                                  <br />
                                  {documentPath.upload_relavent_document_other.map(
                                    (file: any, index: any) =>
                                      index < 2 ? (
                                        <div className="row">
                                          <div className="col-md-3">
                                            {getFileDetails(
                                              file.file,
                                              "type"
                                            ) == "pdf" ? (
                                              <img src={pdf} alt="SISFS Logo" />
                                            ) : getFileDetails(
                                              file.file,
                                              "type"
                                            ) == "doc" ? (
                                              <img src={doc} alt="SISFS Logo" />
                                            ) : getFileDetails(
                                              file.file,
                                              "type"
                                            ) == "xls" ? (
                                              <img src={xls} alt="SISFS Logo" />
                                            ) : (
                                              <img
                                                src={Allround}
                                                alt="SISFS Logo"
                                              />
                                            )}
                                          </div>
                                          <div className="col-md-9">
                                            <span className="document-label-value-wrap">
                                              {getFileDetails(
                                                file.file,
                                                "name"
                                              )}
                                            </span>
                                            <br />
                                            <a
                                              // href={file.file}
                                              href={file.file}
                                              target="_blank"
                                              //download="PitchDeck"
                                              className="incubator-details-link"
                                            >
                                              View
                                            </a>
                                            {/* /
                      <a
                        href="#"
                        //download={file.file}
                        className="incubator-details-link"
                        onClick={() => downloadDocument(file.file, file.file)}
                      >
                        Download
                      </a> */}
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )
                                  )}
                                  {documentPath.upload_relavent_document_other
                                    .length > 2 ? (
                                    <a
                                      className="incubator-details-link"
                                      data-toggle="modal"
                                      data-target="#showMoreDocument"
                                      onClick={() =>
                                        setMeetingModal(
                                          "Uploaded any other relevant document"
                                        )
                                      }
                                    >
                                      More...
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                  <br />
                                  <br />
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="incubator-hr">
                            <hr />
                          </div>

                          <div className="row">
                            <div
                              id="q-4"
                              className={
                                activeIndexDoc == "4"
                                  ? "tranche-heading col-sm-11 left-align"
                                  : "reports-heading col-sm-11 left-align"
                              }
                            >
                              Documents Uploaded By Incubator
                            </div>
                            <div className="col-sm-1">
                              {activeIndexDoc == "4" ? (
                                <div
                                  onClick={changeActiveBackDoc}
                                  data-toggle="collapse"
                                  data-target="#startup-details-expand"
                                  role="button"
                                  aria-expanded="false"
                                  aria-controls="startup-details-expand"
                                  className="hide-icon pull-right hide-icon-details"
                                  id="show-4"
                                >
                                  <img
                                    src={Minus}
                                    className="img-fluid"
                                    alt="SISFS Logo"
                                  />
                                </div>
                              ) : (
                                <div
                                  onClick={() => changeActiveDoc("4")}
                                  id="hide-4"
                                  data-toggle="collapse"
                                  data-target="#startup-details-expand"
                                  role="button"
                                  aria-expanded="false"
                                  aria-controls="pstartup-details-expand"
                                  className="expand-icon pull-right expand-icon-details"
                                >
                                  <img
                                    src={Plus}
                                    className="img-fluid"
                                    alt="SISFS Logo"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          {activeIndexDoc == "4" ? (
                            <div
                              className="collapse "
                              id="startup-details-expand"
                            >
                              <div className="row">
                                <div className="col-md-8 left-align">
                                  <p style={{ fontSize: "14px" }}>
                                    <b> Uploaded Evaluation Sheet</b>
                                  </p>

                                  {documentPath.evaluation_sheet.length != 0 ? (
                                    ""
                                  ) : (
                                    <label className="incubator-label-value">
                                      -
                                    </label>
                                  )}

                                  <br />
                                  {documentPath.evaluation_sheet.map(
                                    (file: any, index: any) =>
                                      index < 2 ? (
                                        <div className="row">
                                          <div className="col-md-3">
                                            {getFileDetails(
                                              file.file,
                                              "type"
                                            ) == "pdf" ? (
                                              <img src={pdf} alt="SISFS Logo" />
                                            ) : getFileDetails(
                                              file.file,
                                              "type"
                                            ) == "doc" ? (
                                              <img src={doc} alt="SISFS Logo" />
                                            ) : getFileDetails(
                                              file.file,
                                              "type"
                                            ) == "xls" ? (
                                              <img src={xls} alt="SISFS Logo" />
                                            ) : (
                                              <img
                                                src={Allround}
                                                alt="SISFS Logo"
                                              />
                                            )}
                                          </div>
                                          <div className="col-md-9">
                                            <span className="document-label-value-wrap">
                                              {getFileDetails(
                                                file.file,
                                                "name"
                                              )}
                                            </span>
                                            {isEditable && (
                                              <i
                                                className="fa fa-close"
                                                onClick={() => closeIcon(file)}
                                                style={{
                                                  position: "absolute",
                                                  left: "230px",
                                                  cursor: "pointer",
                                                  top: "8px",
                                                }}
                                              ></i>
                                            )}
                                            <br />

                                            <a
                                              // href={file.file}
                                              href={
                                                `${File_node_url}` + file.file
                                              }
                                              target="_blank"
                                              //download="PitchDeck"
                                              className="incubator-details-link"
                                            >
                                              View
                                            </a>
                                            {/* /
                      <a
                        href="#"
                        //download={file.file}
                        className="incubator-details-link"
                        onClick={() => downloadDocument(file.file, file.file)}
                      >
                        Download
                      </a> */}
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )
                                  )}

                                  {documentPath.evaluation_sheet.length > 2 ? (
                                    <a
                                      className="incubator-details-link"
                                      data-toggle="modal"
                                      data-target="#showMoreDocument"
                                      onClick={() =>
                                        setMeetingModal(
                                          "Uploaded Evaluation Sheet"
                                        )
                                      }
                                    >
                                      More...
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                  <br />
                                  <br />
                                </div>

                                <div className="col-md-8 left-align">
                                  <p style={{ fontSize: "14px" }}>
                                    <b>
                                      Uploaded Legal Agreement Signed by
                                      Incubator
                                    </b>
                                  </p>

                                  {documentPath.legal_agreement.length != 0 ? (
                                    ""
                                  ) : (
                                    <label className="incubator-label-value">
                                      -
                                    </label>
                                  )}

                                  <br />

                                  {documentPath.legal_agreement.map(
                                    (file: any, index: any) =>
                                      index < 2 ? (
                                        <div className="row">
                                          <div className="col-md-3">
                                            {getFileDetails(
                                              file.file,
                                              "type"
                                            ) == "pdf" ? (
                                              <img src={pdf} alt="SISFS Logo" />
                                            ) : getFileDetails(
                                              file.file,
                                              "type"
                                            ) == "doc" ? (
                                              <img src={doc} alt="SISFS Logo" />
                                            ) : getFileDetails(
                                              file.file,
                                              "type"
                                            ) == "xls" ? (
                                              <img src={xls} alt="SISFS Logo" />
                                            ) : (
                                              <img
                                                src={Allround}
                                                alt="SISFS Logo"
                                              />
                                            )}
                                          </div>
                                          <div className="col-md-9">
                                            <span className="document-label-value-wrap">
                                              {getFileDetails(
                                                file.file,
                                                "name"
                                              )}
                                            </span>
                                            {isEditable && (
                                              <i
                                                className="fa fa-close"
                                                onClick={() => closeIcon(file)}
                                                style={{
                                                  position: "absolute",
                                                  left: "230px",
                                                  cursor: "pointer",
                                                  top: "8px",
                                                }}
                                              ></i>
                                            )}
                                            <br />

                                            <a
                                              // href={file.file}
                                              href={
                                                `${File_node_url}` + file.file
                                              }
                                              target="_blank"
                                              //download="PitchDeck"
                                              className="incubator-details-link"
                                            >
                                              View
                                            </a>
                                            {/* /
                      <a
                        href="#"
                        //download={file.file}
                        className="incubator-details-link"
                        onClick={() => downloadDocument(file.file, file.file)}
                      >
                        Download
                      </a> */}
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )
                                  )}

                                  {documentPath.legal_agreement.length > 2 ? (
                                    <a
                                      className="incubator-details-link"
                                      data-toggle="modal"
                                      data-target="#showMoreDocument"
                                      onClick={() =>
                                        setMeetingModal("Legal Agrement")
                                      }
                                    >
                                      More...
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                  <br />
                                  <br />
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="incubator-hr">
                            <hr />
                          </div>
                        </div>

                        <div
                          // className="tab-pane fade"
                          className={
                            activeTab1 == "8"
                              ? "tab-pane show fade active"
                              : "tab-pane show fade"
                          }
                          id="details_tab_7"
                          role="tabpanel"
                        >
                          <StartupContactTab
                            applicationDetails={applicationDetails}
                          />
                        </div>
                        <div
                          // className="tab-pane fade"
                          className={
                            activeTab1 == "9"
                              ? "tab-pane show fade active"
                              : "tab-pane show fade"
                          }
                          id="details_tab_8"
                          role="tabpanel"
                        >
                          <CommentContainer
                            commentData={commentData}
                            role={"incubator"}
                          />
                        </div>
                        {/* && showPreference */}
                        {preferenceData.length != 0 && (
                          <div
                            // className="tab-pane fade"
                            className={
                              activeTab1 == "10"
                                ? "tab-pane fade show active"
                                : "tab-pane show fade"
                            }
                            id="details_tab_9"
                            role="tabpanel"
                          >
                            <PreferenceContainer
                              preferenceData={preferenceData}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {/*  DETAILS TABS END */}
                  </div>
                </div>
                <div className="col-1"></div>
                {/* Show More  */}
                <div
                  className="modal fade"
                  id="showMoreDocument"
                  role="dialog"
                  aria-labelledby="exampleModalCenterTitle"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered modal-lg"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header modal-header-border-none ml-18px">
                        <h5 className="modal-title " id="exampleModalLongTitle">
                          {popUpName} <span className="eac-name-pop-up"></span>
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        {/* <div className=""> */}
                        {/* 1St Application Documents */}

                        {/* General Details documents */}

                        {popUpName ==
                          "Board Resolution / Authorisation letter / PoA" ? (
                          documentPath.authorisation_letter_upload !=
                            undefined ? (
                            documentPath.authorisation_letter_upload.map(
                              (file: any, index: any) => (
                                <div className="row">
                                  <div className="col-md-2">
                                    {getFileDetails(file.file, "type") ==
                                      "pdf" ? (
                                      <img src={pdf} alt="SISFS Logo" />
                                    ) : getFileDetails(file.file, "type") ==
                                      "doc" ? (
                                      <img src={doc} alt="SISFS Logo" />
                                    ) : getFileDetails(file.file, "type") ==
                                      "xls" ? (
                                      <img src={xls} alt="SISFS Logo" />
                                    ) : (
                                      <img src={Allround} alt="SISFS Logo" />
                                    )}
                                  </div>
                                  <div className="col-md-9 left-align">
                                    <span className="document-label-value-wrap">
                                      {getFileDetails(file.file, "name")}
                                    </span>
                                    <br />
                                    <a
                                      // href={file.file}
                                      href={`${File_node_url}` + file.file}
                                      target="_blank"
                                      //download="annual_report"
                                      className="incubator-details-link"
                                    >
                                      View
                                    </a>
                                    {/* /
                            <a
                              href="#"
                              //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                                  </div>
                                </div>
                              )
                            )
                          ) : (
                            <span className="incubator-label-value">-</span>
                          )
                        ) : (
                          ""
                        )}

                        {popUpName == "Promoters Aadhaar Cards" ? (
                          documentPath.promoters_aadhar_cards != undefined ? (
                            documentPath.promoters_aadhar_cards.map(
                              (file: any, index: any) => (
                                <div className="row">
                                  <div className="col-md-2">
                                    {getFileDetails(file.file, "type") ==
                                      "pdf" ? (
                                      <img src={pdf} alt="SISFS Logo" />
                                    ) : getFileDetails(file.file, "type") ==
                                      "doc" ? (
                                      <img src={doc} alt="SISFS Logo" />
                                    ) : getFileDetails(file.file, "type") ==
                                      "xls" ? (
                                      <img src={xls} alt="SISFS Logo" />
                                    ) : (
                                      <img src={Allround} alt="SISFS Logo" />
                                    )}
                                  </div>
                                  <div className="col-md-9 left-align">
                                    <span className="document-label-value-wrap">
                                      {getFileDetails(file.file, "name")}
                                    </span>
                                    <br />
                                    <a
                                      // href={file.file}
                                      href={`${File_node_url}` + file.file}
                                      target="_blank"
                                      //download="annual_report"
                                      className="incubator-details-link"
                                    >
                                      View
                                    </a>
                                    {/* /
                            <a
                              href="#"
                              //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                                  </div>
                                </div>
                              )
                            )
                          ) : (
                            <span className="incubator-label-value">-</span>
                          )
                        ) : (
                          ""
                        )}
                        {popUpName == "Uploaded Pitch deck of Business Idea" ? (
                          documentPath.upload_pitch_deck != undefined ? (
                            documentPath.upload_pitch_deck.map(
                              (file: any, index: any) => (
                                <div className="row">
                                  <div className="col-md-2">
                                    {getFileDetails(file.file, "type") ==
                                      "pdf" ? (
                                      <img src={pdf} alt="SISFS Logo" />
                                    ) : getFileDetails(file.file, "type") ==
                                      "doc" ? (
                                      <img src={doc} alt="SISFS Logo" />
                                    ) : getFileDetails(file.file, "type") ==
                                      "xls" ? (
                                      <img src={xls} alt="SISFS Logo" />
                                    ) : (
                                      <img src={Allround} alt="SISFS Logo" />
                                    )}
                                  </div>
                                  <div className="col-md-9 left-align">
                                    <span className="document-label-value-wrap">
                                      {getFileDetails(file.file, "name")}
                                    </span>
                                    <br />
                                    <a
                                      // href={file.file}
                                      href={`${File_node_url}` + file.file}
                                      target="_blank"
                                      //download="annual_report"
                                      className="incubator-details-link"
                                    >
                                      View
                                    </a>
                                    {/* /
                            <a
                              href="#"
                              //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                                  </div>
                                </div>
                              )
                            )
                          ) : (
                            <span className="incubator-label-value">-</span>
                          )
                        ) : (
                          ""
                        )}
                        {popUpName == "Uploaded any other relevant document" ? (
                          documentPath.upload_relavent_document_other !=
                            undefined ? (
                            documentPath.upload_relavent_document_other.map(
                              (file: any, index: any) => (
                                <div className="row">
                                  <div className="col-md-2">
                                    {getFileDetails(file.file, "type") ==
                                      "pdf" ? (
                                      <img src={pdf} alt="SISFS Logo" />
                                    ) : getFileDetails(file.file, "type") ==
                                      "doc" ? (
                                      <img src={doc} alt="SISFS Logo" />
                                    ) : getFileDetails(file.file, "type") ==
                                      "xls" ? (
                                      <img src={xls} alt="SISFS Logo" />
                                    ) : (
                                      <img src={Allround} alt="SISFS Logo" />
                                    )}
                                  </div>
                                  <div className="col-md-9 left-align">
                                    <span className="document-label-value-wrap">
                                      {getFileDetails(file.file, "name")}
                                    </span>
                                    <br />
                                    <a
                                      // href={file.file}
                                      href={`${File_node_url}` + file.file}
                                      target="_blank"
                                      //download="annual_report"
                                      className="incubator-details-link"
                                    >
                                      View
                                    </a>
                                    {/* /
                            <a
                              href="#"
                              //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                                  </div>
                                </div>
                              )
                            )
                          ) : (
                            <span className="incubator-label-value">-</span>
                          )
                        ) : (
                          ""
                        )}
                        {popUpName == "Uploaded Evaluation Sheet" ? (
                          documentPath.evaluation_sheet != undefined ? (
                            documentPath.evaluation_sheet.map(
                              (file: any, index: any) => (
                                <div className="row">
                                  <div className="col-md-2">
                                    {getFileDetails(file.file, "type") ==
                                      "pdf" ? (
                                      <img src={pdf} alt="SISFS Logo" />
                                    ) : getFileDetails(file.file, "type") ==
                                      "doc" ? (
                                      <img src={doc} alt="SISFS Logo" />
                                    ) : getFileDetails(file.file, "type") ==
                                      "xls" ? (
                                      <img src={xls} alt="SISFS Logo" />
                                    ) : (
                                      <img src={Allround} alt="SISFS Logo" />
                                    )}
                                  </div>
                                  <div className="col-md-9 left-align">
                                    <span className="document-label-value-wrap">
                                      {getFileDetails(file.file, "name")}
                                    </span>
                                    {isEditable && (
                                      <i
                                        className="fa fa-close"
                                        onClick={() => closeIcon(file)}
                                        style={{
                                          position: "absolute",
                                          left: "270px",
                                          cursor: "pointer",
                                          top: "8px",
                                        }}
                                      ></i>
                                    )}

                                    <br />
                                    <a
                                      // href={file.file}
                                      href={`${File_node_url}` + file.file}
                                      target="_blank"
                                      //download="annual_report"
                                      className="incubator-details-link"
                                    >
                                      View
                                    </a>
                                    {/* /
                            <a
                              href="#"
                              //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                                  </div>
                                </div>
                              )
                            )
                          ) : (
                            <span className="incubator-label-value">-</span>
                          )
                        ) : (
                          ""
                        )}
                        {popUpName == "Legal Agrement" ? (
                          documentPath.legal_agreement != undefined ? (
                            documentPath.legal_agreement.map(
                              (file: any, index: any) => (
                                <div className="row">
                                  <div className="col-md-2">
                                    {getFileDetails(file.file, "type") ==
                                      "pdf" ? (
                                      <img src={pdf} alt="SISFS Logo" />
                                    ) : getFileDetails(file.file, "type") ==
                                      "doc" ? (
                                      <img src={doc} alt="SISFS Logo" />
                                    ) : getFileDetails(file.file, "type") ==
                                      "xls" ? (
                                      <img src={xls} alt="SISFS Logo" />
                                    ) : (
                                      <img src={Allround} alt="SISFS Logo" />
                                    )}
                                  </div>
                                  <div className="col-md-9 left-align">
                                    <span className="document-label-value-wrap">
                                      {getFileDetails(file.file, "name")}
                                    </span>
                                    {isEditable && (
                                      <i
                                        className="fa fa-close"
                                        onClick={() => closeIcon(file)}
                                        style={{
                                          position: "absolute",
                                          left: "270px",
                                          cursor: "pointer",
                                          top: "8px",
                                        }}
                                      ></i>
                                    )}

                                    <br />
                                    <a
                                      // href={file.file}
                                      href={`${File_node_url}` + file.file}
                                      target="_blank"
                                      //download="annual_report"
                                      className="incubator-details-link"
                                    >
                                      View
                                    </a>
                                    {/* /
                            <a
                              href="#"
                              //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                                  </div>
                                </div>
                              )
                            )
                          ) : (
                            <span className="incubator-label-value">-</span>
                          )
                        ) : (
                          ""
                        )}

                        <div className="row">
                          <div className="col-md-12 centerText">
                            <div className="btn-group mt-1-5 ">
                              <button
                                className="tertiary  btn-yellow-primary"
                                data-dismiss="modal"
                              >
                                OK
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Edit Status Modal */}
                <div
                  className="modal fade"
                  id="quickEdit"
                  role="dialog"
                  aria-labelledby="exampleModalLabel1"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-slideout modal-md"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header modal-header-border-none modal-header-bottom pr-5">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        // onClick={() => setSelectFlag(true)}
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body pl-5 pr-5 pt-5">
                        <div className="popup-head mb-5 ml-2">
                          <div className="main-heading">
                            {quickEditPopupData.incubator_name}
                          </div>
                          <div className="count-text">
                            {quickEditPopupData.incubator_name == null
                              ? "No: " + quickEditPopupData.application_id
                              : "No: " +
                              quickEditPopupData.incubator_name +
                              "_" +
                              quickEditPopupData.application_id}
                          </div>
                        </div>

                        <label className="col-sm-8 col-form-head">
                          Application Status
                        </label>
                        <div className="row pl-3">
                          <div className="col-md-11 mb-4">
                            <div className="select-custom">
                              <select
                                className="form-control input-height"
                                aria-label="Select State"
                                id="application_status"
                                name="application_status"
                                value={quickEditPopupData.application_status}
                                onChange={handlePopupChange}
                                placeholder="Select Status"
                              >
                                {/* <option value="">Select Status</option> */}

                                {applicationStatus.map((item: any) =>
                                  item.label !== "Draft" ? (
                                    <option key={item.label} value={item.value}>
                                      {item.label}
                                    </option>
                                  ) : null
                                )}
                              </select>
                            </div>
                          </div>
                        </div>

                        <label className="col-sm-8 col-form-head">
                          Comments, if any
                        </label>
                        <div className="col-md-12 mb-4">
                          <textarea
                            // value={quickEditPopupData.application_comments}
                            // value={quickEditPopupData.application_comments}
                            value={
                              quickEditPopupData.application_comments == null
                                ? ""
                                : quickEditPopupData.application_comments
                            }
                            className="form-control textarea-height"
                            name="application_comments"
                            onChange={handlePopupChange}
                          />
                          {showValidation == true && (
                            <span className="text-danger">
                              Comment is mandatory
                            </span>
                          )}
                        </div>

                        <div className="row pl-3">
                          <div className="col-md-6">
                            <button
                              className="btn btn-sisfs btn-transparent-primary full-width"
                              // onClick={() => setSelectFlag(true)}
                              data-dismiss="modal"
                            >
                              CANCEL
                            </button>
                          </div>
                          <div className="col-md-6">
                            {/* <button
                            className={
                              isDisabled
                                ? 'tertiary  btn-yellow-primary full-width custom-disabled'
                                : 'tertiary  btn-yellow-primary full-width'
                            }
                            disabled={isDisabled}
                            onClick={quickEditCall}
                            data-dismiss="modal">
                            UPDATE
                          </button> */}
                            {quickEditPopupData.application_status == "8" ? (
                              <>
                                <button
                                  className={
                                    isDisabled
                                      ? "tertiary  btn-yellow-primary full-width custom-disabled"
                                      : "tertiary  btn-yellow-primary full-width"
                                  }
                                  disabled={isDisabled}
                                  onClick={quickEditCallMOdal}
                                // data-dismiss="modal"
                                >
                                  UPDATE
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  className={
                                    isDisabled
                                      ? "tertiary  btn-yellow-primary full-width custom-disabled"
                                      : "tertiary  btn-yellow-primary full-width"
                                  }
                                  disabled={isDisabled}
                                  onClick={quickEditCall}
                                // data-dismiss="modal"
                                >
                                  UPDATE
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Edit engagement Status Modal */}
                <div
                  className="modal fade"
                  id="quickEditEngagement"
                  role="dialog"
                  aria-labelledby="exampleModalLabel1"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-slideout modal-md"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header modal-header-border-none modal-header-bottom pr-5">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        // onClick={() => setSelectFlag(true)}
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body pl-5 pr-5 pt-5">
                        <div className="popup-head mb-5 ml-2">
                          <div className="main-heading">
                            {quickEditPopupData.incubator_name}
                          </div>
                          <div className="count-text">
                            {quickEditPopupData.incubator_name == null
                              ? "No: " + quickEditPopupData.application_id
                              : "No: " +
                              quickEditPopupData.incubator_name +
                              "_" +
                              quickEditPopupData.application_id}
                          </div>
                        </div>

                        <label className="col-sm-8 col-form-head">
                          Application Status
                        </label>
                        <div className="row pl-3">
                          <div className="col-md-11 mb-4">
                            <div className="select-custom">
                              <select
                                className="form-control input-height"
                                aria-label="Select State"
                                id="engagement_status"
                                name="engagement_status"
                                value={quickEditPopupData.engagement_status}
                                onChange={handlePopupChange}
                              >
                                <option key="" value="">
                                  Select
                                </option>
                                {engagementStatus.map(({ label, value }) => (
                                  <option key={label} value={value}>
                                    {label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <label className="col-sm-8 col-form-head">
                          Comments, if any
                        </label>
                        <div className="col-md-12 mb-4">
                          <textarea
                            // value={quickEditPopupData.application_comments}
                            value={
                              quickEditPopupData.application_comments1 == null
                                ? ""
                                : quickEditPopupData.application_comments1
                            }
                            className="form-control textarea-height"
                            name="application_comments1"
                            onChange={handlePopupChange}
                          />
                        </div>
                        <div className="row pl-3">
                          <div className="col-md-6">
                            <button
                              className="btn btn-sisfs btn-transparent-primary full-width"
                              // onClick={() => setSelectFlag(true)}
                              data-dismiss="modal"
                            >
                              CANCEL
                            </button>
                          </div>
                          <div className="col-md-6">
                            <button
                              className={
                                isDisabled
                                  ? "tertiary  btn-yellow-primary full-width custom-disabled"
                                  : "tertiary  btn-yellow-primary full-width"
                              }
                              disabled={isDisabled}
                              onClick={quickEditCallEngagement}
                              data-dismiss="modal"
                            >
                              UPDATE
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </div>

      {showQuickEditModal && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionError"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  {/* <img
               src={error}
               className="search mb-2"
               alt="search"

             /> */}
                  <h3 className="modal-message mt-2">
                    Are you sure you wish to close the application?
                  </h3>
                  {/* <h3 className="modal-text">{confirmationDialogData.text}</h3> */}
                  <h6 className=" message-orange">
                    Note: Please make sure that all funds disbursed to the
                    Startup are mentioned in the respective Tranches. In case
                    you wish to update please press No.
                  </h6>
                  <br />
                  <div className="modal-action mt-2">
                    <button
                      className="btn btn-sisfs btn-transparent-primary"
                      onClick={() => setShowQuickEditModal(false)}
                    >
                      NO
                    </button>
                    <button
                      className="btn btn-sisfs btn-yellow-primary ml-2"
                      onClick={quickEditCall}
                    >
                      YES
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div
        className="modal fade"
        id="CancellationRequest"
        role="dialog"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content pt-0">
            <div className="modal-header modal-header-bottom pr-3 pt-3 cancel-main-heading">
              <div></div>
              <div>
                <h3 className="Cancellation_heading">
                  Request cancellation from the Secretariat
                </h3>
              </div>
              <div>
                <button
                  type="button"
                  className="p-3 modal_close_btn"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => localStorage.removeItem("Tabvalue")}
                >
                  <span aria-hidden="true" className="close_Btn">×</span>
                </button>
              </div>
            </div>
            <div className="modal-body modal-body-bgclr">
              <SecCancellationRequest application_id={applicationID} />
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="scheduleMeeting"
        role="dialog"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-slideout modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-bottom pr-5">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => (
                  setTimeValidation(false),
                  setTimeValidationCurrentTime(false),
                  setLinkValidation(false)
                )}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pl-5 pr-5 pt-2">
              <div className="popup-head mb-5 ml-3">
                <div className="main-heading">Schedule a Meeting</div>
              </div>

              <label className="col-sm-4 col-form-head">Meeting Type</label>
              <div className="col-md-12 mb-4">
                <div className="select-custom">
                  <select
                    className="form-control input-height"
                    aria-label="Select Entity Type"
                    onChange={handleOnChange}
                  >
                    {userDetails.status == "Selected" ? (
                      <option value="2">Performance Review Meeting</option>
                    ) : null}
                    {userDetails.status != "Selected" ? (
                      <option value="1">Startup Selection</option>
                    ) : null}
                  </select>
                </div>
              </div>
              <label className="col-sm-4 col-form-head">Date & Time</label>
              <div className="col-md-6 mb-4">
                <Calendar
                  id="registrationDate"
                  name="registrationDate"
                  className="form-control input-height"
                  format="dd/MM/yyyy"
                  selectedDate={
                    selectedDateControl ? selectedDateControl : new Date()
                  }
                  onDateChange={(date: Date, field: string) =>
                    handleDateChange(date, field)
                  }
                  minDate={new Date()}
                />
              </div>
              <div className="row pl-3">
                <div className="col-md-6">
                  <label className="col-form-head">From Time</label>
                  <input
                    type="time"
                    value={fromTime}
                    name="fromTime"
                    className="form-control input-height"
                    onChange={onTimeChange}
                  />
                </div>
                <div className="col-md-6">
                  <label className="col-form-head">To Time</label>
                  <input
                    type="time"
                    value={toTime}
                    name="toTime"
                    className="form-control input-height"
                    onChange={onTimeChange}
                  />
                </div>
              </div>
              <div className="row text-left pl-4 mb-5">
                {timeValidation == true ? (
                  <label className="error-message">
                    From time greater than To time
                  </label>
                ) : null}
                {timeValidationCurrentTime == true ? (
                  <label className="error-message">
                    Meeting can't be scheduled for past time
                  </label>
                ) : null}
              </div>
              <label className="col-sm-4 col-form-head">Meeting Link</label>
              <div className="col-md-12 mb-4">
                <input
                  type="text"
                  id="meeting_link"
                  name="meeting_link"
                  className="form-control input-height"
                  onChange={(e: any) => setMeetingLink(e.target.value)}
                />
                <div className="row text-left pl-3">
                  {linkValidation == true ? (
                    <label className="error-message">
                      Meeting Link is required
                    </label>
                  ) : null}
                </div>
              </div>
              <div className="row pl-3">
                <div className="col-md-6">
                  <button
                    className="btn btn-sisfs btn-transparent-primary full-width"
                    data-dismiss="modal"
                    onClick={() => (
                      setTimeValidation(false),
                      setTimeValidationCurrentTime(false),
                      setLinkValidation(false)
                    )}
                  >
                    CANCEL
                  </button>
                </div>
                <div className="col-md-6">
                  {/* {console.log(event, currentdatenew, isClick, '099')} */}
                  {/* {((event < currentdatenew)&&(isClick == '2' ||isClick == '3' ||isClick == '4' ||isClick == '5'))? (
                  <button
                  className={
                    isDisabled
                      ? "tertiary  btn-yellow-primary full-width custom-disabled"
                      : "tertiary  btn-yellow-primary full-width"
                  }
                  disabled={isDisabled}
                  
                >
                  SCHEDULE
                </button>
                ):( */}
                  <button
                    className={
                      isDisabled
                        ? "tertiary  btn-yellow-primary full-width custom-disabled"
                        : "tertiary  btn-yellow-primary full-width"
                    }
                    disabled={isDisabled}
                    onClick={scheduleMeetingFunction}
                  >
                    SCHEDULE
                  </button>
                  {/* )} */}
                </div>
              </div>

              {/* {showIncubatorSuccessModal && (
                // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
                <>
                  <div className="modal-backdrop fade show"></div>

                  <div
                    className="modal align-middle modal-sisfs d-block"
                    id="FormSubmissionSuccess"
                  >
                    <div className="modal-dialog modal-dialog-centered modal-md">
                      <div className="modal-content">
                        <div className="modal-body text-center">
                          <i className="fa fa-check-circle-o"></i>
                          <h3 className="modal-message">Thank You!</h3>
                          <div className="modal-text">
                            Your Meeting Has been Scheduled
                                </div>
                          <div className="modal-action">
                            <button className="btn btn-sisfs btn-yellow-primary" onClick={dismissModal}>
                              Ok!!
                                </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )} */}
              {/* {showIncubatorFailureModal && (
                // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
                <>
                  <div className="modal-backdrop fade show"></div>
                  <div
                    className="modal align-middle modal-sisfs d-block"
                    id="FormSubmissionError"
                  >
                    <div className="modal-dialog modal-dialog-centered modal-md">
                      <div className="modal-content">
                        <div className="modal-body text-center">
                          {/* <i className="fa fa-times-circle-o"></i>
                          <h3 className="modal-message message-orange">
                            Oops! Something is Wrong
                              </h3><br />
                          <h3 className="modal-message message-orange">
                            Please Try Again!!!
                              </h3>
                          <div className="modal-action">
                            <button className="btn btn-sisfs btn-yellow-primary" onClick={dismissModal}>
                              OK!
                              </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )} */}
              {/* <div className="row pl-5">
                <button
                  className="btn btn-sisfs btn-transparent-primary"
                  data-dismiss="modal"
                >
                  CANCEL
                </button>
                <button
                  className="tertiary  btn-yellow-primary ml-3"
                  onClick={scheduleMeetingFunction}
                >
                  SCHEDULE
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {showIncubatorSuccessModal && (
        // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
        // <>
        //   <div className="modal-backdrop fade show"></div>

        //   <div
        //     className="modal align-middle modal-sisfs d-block"
        //     id="FormSubmissionSuccess"
        //   >
        //     <div className="modal-dialog modal-dialog-centered modal-md">
        //       <div className="modal-content">
        //         <div className="modal-body text-center">
        //           <i className="fa fa-check-circle-o"></i>
        //           <h3 className="modal-message">Thank You!</h3>
        //           <div className="modal-text">
        //             Your Meeting Has been Scheduled
        //                         </div>
        //           <div className="modal-action">
        //             <button className="btn btn-sisfs btn-yellow-primary" onClick={dismissModal}>
        //               Ok!!
        //                         </button>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // </>
        <ConfirmationDialog
          headerText={dialogData.header}
          bodyText={dialogData.text}
          onDialogClose={dismissModal}
        />
      )}
      {showIncubatorFailureModal && (
        // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
        // <>
        //   <div className="modal-backdrop fade show"></div>
        //   <div
        //     className="modal align-middle modal-sisfs d-block"
        //     id="FormSubmissionError"
        //   >
        //     <div className="modal-dialog modal-dialog-centered modal-md">
        //       <div className="modal-content">
        //         <div className="modal-body text-center">
        //         <img
        //       src={error}
        //       className="search mb-2"
        //       alt="search"

        //     />
        //           <h3 className="modal-message">
        //             {failDialogData.header}
        //                       </h3>
        //           <h3 className="modal-text">
        //           {failDialogData.text}
        //                       </h3>
        //           <div className="modal-action">
        //             <button className="btn btn-sisfs btn-yellow-primary" onClick={() => setShowIncubatorFailureModal(false)}>
        //               OK!
        //                       </button>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // </>
        <ConfirmationDialog
          headerText={failDialogData.header}
          bodyText={failDialogData.text}
          // onDialogClose={() => setShowIncubatorFailureModal(false)}
          iconClass="test"
          onDialogClose={dismissModal}
        />
      )}

      {/* right slide modal  */}
      {/* <div className="modal fade" id="scheduleMeeting" role="dialog" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-slideout modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header modal-header-bottom pr-5">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => (setTimeValidation(false), setTimeValidationCurrentTime(false))}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body pl-5 pr-5 pt-5">
                            <div className="popup-head mb-5 ml-3">
                                <div className="main-heading">Schedule a Meeting</div>
                                {!isVisible ? <div className="count-text">{incubatorPending} Startups Selected</div> : null}
                            </div>

                            <label className="col-sm-4 col-form-head">
                                Meeting Type
                        </label>
                            <div className="col-md-12 mb-4">
                                <div className="select-custom">
                                    <select className="form-control input-height" aria-label="Select Entity Type" onChange={handleOnChange}>
                                        <option value="1">Startup Selection</option>
                                        <option value="2">Performance Review Meeting</option>
                                    </select>
                                </div>
                            </div>
                            {isVisible ?
                                <div className="radio-popup">
                                    <label className="col-sm-12 col-form-head mb-2">
                                        Incubator Due for Review
                        </label>
                                    <div className="pl-3">
                                        <div>
                                            <input
                                                type="radio"
                                                id="Tomorrow"
                                                name="filter"
                                                value="Tomorrow"
                                                onChange={handleRadioChange}
                                                disabled={count.Tomorrow == "0" ? true : false}
                                            />
                                            <label className="radio-label">
                                                Tomorrow({count.Tomorrow})
                  </label>
                                        </div>
                                        <div>
                                            <input
                                                type="radio"
                                                name="filter"
                                                value="This_week"
                                                onChange={handleRadioChange}
                                                disabled={count.This_week == "0" ? true : false}
                                            />
                                            <label className="radio-label">
                                                This Week({count.This_week})
                  </label>
                                        </div>
                                        <div>
                                            <input
                                                type="radio"
                                                name="filter"
                                                value="Next_week"
                                                onChange={handleRadioChange}
                                                disabled={count.Next_week == "0" ? true : false}
                                            />
                                            <label className="radio-label">
                                                Next Week({count.Next_week})
                  </label>
                                        </div>
                                        <div>
                                            <input
                                                type="radio"
                                                name="filter"
                                                value="Next_month"
                                                onChange={handleRadioChange}
                                                disabled={count.Next_month == "0" ? true : false}
                                            />
                                            <label className="radio-label">
                                                Next Month({count.Next_month})
                  </label>
                                        </div>
                                        <div>
                                            <input
                                                type="radio"
                                                name="filter"
                                                value="Next_2_months"
                                                onChange={handleRadioChange}
                                                disabled={count.Next_2_months == "0" ? true : false}
                                            />
                                            <label className="radio-label">
                                                Next 2 Months({count.Next_2_months})
                  </label>
                                        </div>

                                    </div>
                                </div> : null}
                            <label className="col-sm-4 col-form-head">
                                Date
                        </label>
                            <div className="col-md-6 mb-4">
                                <Calendar
                                    id="registrationDate"
                                    name="registrationDate"
                                    className="form-control input-height"
                                    format="dd/MM/yyyy"
                                    selectedDate={selectedDateControl ? selectedDateControl : new Date()}
                                    onDateChange={(date: Date, field: string) =>
                                        handleDateChange(date, field)
                                    }
                                    minDate={new Date()}
                                />
                            </div>
                            <div className="row pl-3">
                                <div className="col-md-6">
                                    <label className="col-form-head">
                                        From Time
                        </label>
                                    <input type="time" value={fromTime} name="fromTime" className="form-control input-height" onChange={onTimeChange} />
                                </div>
                                <div className="col-md-6">
                                    <label className="col-form-head">
                                        To Time
                        </label>
                                    <input type="time" value={toTime} name="toTime" className="form-control input-height" onChange={onTimeChange} />
                                </div>
                            </div>
                            <div className="row text-left pl-4 mb-5">
                                {timeValidation == true ? <label className="error-message">From time greater than To time</label> : null}
                                {timeValidationCurrentTime == true ? <label className="error-message">Meeting can't be scheduled for past time</label> : null}
                            </div>

                            <div className="row pl-3">
                                <div className="col-md-6">
                                    <button className="btn btn-sisfs btn-transparent-primary full-width" data-dismiss="modal" onClick={() => (setTimeValidation(false), setTimeValidationCurrentTime(false))}>
                                        CANCEL
              </button>
                                </div>
                                <div className="col-md-6">
                                    <button className={isDisabled ? "tertiary  btn-yellow-primary full-width custom-disabled" : "tertiary  btn-yellow-primary full-width"} disabled={isDisabled} onClick={quickMeetingSubmit}>
                                        SCHEDULE
                </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div> */}
      {grantCheckModal && (
        <>
          <div className="modal-backdrop fade show"></div>

          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionSuccess"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  {/* <i className="fa fa-check-circle-o"></i> */}
                  <h3 className="modal-message message-orange">Oops!!</h3>
                  <div className="modal-text">
                    Please choose Instrument type for the Startup before closing
                    the Application
                  </div>
                  <div className="modal-action">
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={grantModal}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showConfirmationBox && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionError"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  {/* <img
               src={error}
               className="search mb-2"
               alt="search"

             /> */}
                  <h3 className="modal-message mt-2">
                    {confirmationDialogData.header}
                  </h3>
                  <h3 className="modal-text">{confirmationDialogData.text}</h3>
                  <div className="modal-action mt-2">
                    <button
                      className="btn btn-sisfs btn-transparent-primary"
                      onClick={() => setShowConfirmationBox(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-sisfs btn-yellow-primary ml-2"
                      onClick={finalSchedule}
                    >
                      Schedule Anyway
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* Remaining Applications Model */}
      <div
        className="modal fade"
        id="otherApplications"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none ml-18px">
              <h5 className="modal-title pl-2" id="exampleModalLongTitle">
                Other Applications <span></span>
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container">
                <div className="row">
                  <div className="mt-3-px">
                    <table id="example" className="mt-3-px">
                      <thead>
                        <tr>
                          <th className="text-left custom-td-left table-heading">
                            Application Number
                          </th>
                          <th className="text-left custom-td-left table-heading">
                            Registered Name
                          </th>
                          <th className="text-left table-heading">Status</th>
                          <th className="text-left table-heading"></th>
                        </tr>
                      </thead>
                      <tbody>

                        {
                          otherApplications.length > 0
                            ? otherApplications.map(
                              (item: any) => (
                                <tr>
                                  <td className="text-left custom-td-left">
                                    {item.id}
                                  </td>
                                  <td className="text-left custom-td-left">
                                    {item.name_of_entity}
                                  </td>
                                  <td className="text-left custom-td-left">
                                    {item.startup_status_id}
                                    &nbsp;&nbsp; &nbsp;&nbsp;
                                  </td>
                                  <td className="text-left custom-td-left">
                                    <a
                                      className="incubator-details-link"
                                      href=""
                                      onClick={() =>
                                        handleRowChange(item.id)
                                      }
                                      data-dismiss="modal"
                                    >
                                      View
                                    </a>
                                  </td>
                                </tr>
                              )
                            )
                            : ''}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row pt-3 mb-3">
                <div className="col-md-12 centerText">
                  <button
                    className="tertiary  btn-yellow-primary"
                    data-dismiss="modal"
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewStartupDetailsComponent;
