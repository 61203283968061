import React from "react";
import { StepperEnum } from "../../../utils/Enums";

interface IHeaderProps {
  onClick: (index: number) => void;
  errors?: any
  teamError?: Array<Number>;
}

function StepperHeaderComponent(props: IHeaderProps) {
  return (
    <div className="bs-stepper-header" role="tablist">
      <div className="step" data-target="#incubator-step-1">
        <button
          type="button"
          className="step-trigger"
          role="tab"
          id="stepper1trigger1"
          aria-controls="incubator-step-1"
          onClick={() => props.onClick(StepperEnum.GeneralDetail)}
        >
          <span className={props.errors.GeneralDetail.length ? "bs-stepper-circle-error" : "bs-stepper-circle"}></span>
          
          <span className="bs-stepper-label">General Details</span>
          <div className="bs-stepper-line"></div>
        </button>
        
        
        {
          props.errors.GeneralDetail.length ?
            <span className="badge-red"><i className="fa fa-exclamation-triangle"></i> {props.errors.GeneralDetail.length ? `${props.errors.GeneralDetail.length} invalid fields` : ''}</span>
            : <span />
        }
      </div>

      <div className="step" data-target="#incubator-step-2">
        <button
          type="button"
          className="step-trigger"
          role="tab"
          id="stepper1trigger2"
          aria-controls="incubator-step-2"
          onClick={() => props.onClick(StepperEnum.IncubatorTeam)}
        >
          <span className={props.errors.IncubatorTeam.length || props.teamError?.length ? "bs-stepper-circle-error" : "bs-stepper-circle"}></span>
          
          <span className="bs-stepper-label">Incubator’s Team</span>
          <div className="bs-stepper-line"></div>
        </button>
        {/* {console.log(props.errors.IncubatorTeam,"Team")} */}
        {/* {console.log(props.teamError,"Team")} */}
        {
          props.errors.IncubatorTeam.length || props.teamError?.length ?
            <span className="badge-red"><i className="fa fa-exclamation-triangle"></i> {props.errors.IncubatorTeam.length || props.teamError?.length ? `${props.errors.IncubatorTeam.length + props.teamError?.length} invalid fields` : ''}</span>
            // <span className="badge-red"><i className="fa fa-exclamation-triangle"></i> {props.errors.IncubatorTeam.length ? `${props.errors.IncubatorTeam.length} invalid fields` : ''}</span>
            : <span />
        }
      </div>

      <div className="step" data-target="#incubator-step-3">
        <button
          type="button"
          className="step-trigger"
          role="tab"
          id="stepper1trigger3"
          aria-controls="incubator-step-3"
          onClick={() => props.onClick(StepperEnum.IncubatorDetail)}
        >
          <span className={props.errors.IncubatorDetails.length ? "bs-stepper-circle-error" : "bs-stepper-circle"}></span>
          
          <span className="bs-stepper-label">Incubator Support Details</span>
          <div className="bs-stepper-line"></div>
        </button>
        
        
        {
          props.errors.IncubatorDetails.length ?
            <span className="badge-red"><i className="fa fa-exclamation-triangle"></i> {props.errors.IncubatorDetails.length ? `${props.errors.IncubatorDetails.length} invalid fields` : ''}</span>
            : <span />
        }
      </div>

      <div className="step" data-target="#incubator-step-4">
        <button
          type="button"
          className="step-trigger"
          role="tab"
          id="stepper1trigger4"
          aria-controls="incubator-step-4"
          onClick={() => props.onClick(StepperEnum.FundUtilization)}
        >
          <span className={props.errors.FundDetails.length ? "bs-stepper-circle-error" : "bs-stepper-circle"}></span>
         
          <span className="bs-stepper-label">Fund Requirements Details</span>
          <div className="bs-stepper-line"></div>
        </button>
       
        
        {
          props.errors.FundDetails.length ?
            <span className="badge-red"><i className="fa fa-exclamation-triangle"></i> {props.errors.FundDetails.length ? `${props.errors.FundDetails.length} invalid fields` : ''}</span>
            : <span />
        }
      </div>

      <div className="step" data-target="#incubator-step-5">
        <button
          type="button"
          className="step-trigger"
          role="tab"
          id="stepper1trigger5"
          aria-controls="incubator-step-5"
          onClick={() => props.onClick(StepperEnum.OtherDetail)}
        >
          <span className={props.errors.OtherDetails.length ? "bs-stepper-circle-error" : "bs-stepper-circle"}></span>
          <span className="bs-stepper-label">Other Details</span>
        </button>
       
        {
          props.errors.OtherDetails.length ?
            <span className="badge-red"><i className="fa fa-exclamation-triangle"></i> {props.errors.OtherDetails.length ? `${props.errors.OtherDetails.length} invalid fields` : ''}</span>
            : <span />
        }
      </div>
    </div >
  );
}

export default StepperHeaderComponent;
