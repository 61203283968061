export class FundDetailsModel {
    constructor(
        public quantum_fund_required: string,
        public no_fund_benifitiaries_1yr: number,
        public no_fund_benifitiaries_2yr: number,
        public no_fund_benifitiaries_3yr: number,
    ){
        this.quantum_fund_required = quantum_fund_required;
        this.no_fund_benifitiaries_1yr = no_fund_benifitiaries_1yr;
        this.no_fund_benifitiaries_2yr = no_fund_benifitiaries_2yr;
        this.no_fund_benifitiaries_3yr = no_fund_benifitiaries_3yr;   
    }
}