import React, { useState, useEffect } from 'react';
import SeedFundSchemeLogo from '../../../images/SeedFundSchemeLogo.svg';
import { BrowserRouter as Router, Route, Link, NavLink } from 'react-router-dom';
import LoginComponent from '../../common/login/LoginComponent';
import ProfileContainer from '../../common/header/ProfileContainer';
import { getSessionStorageValue } from '../../../effects/utils/session-storage';
import { useAuthStateValue } from '../../../providers/AuthStateProvider';
import NotificationLogo from '../../../images/notifactions.svg';
import '../../../styles/dashboard.css';
import ApplyNowModalComponent from '../../common/header/ApplyNowModalComponent';
import { COUNT_SUBIMTTED, GET_STARTUP_MEETINGS, NOTIFICATION_LIST, NOTIFICATION_LIST_STATUS_CHANGE, NOTIFICATION_LIST_STATUS_CHANGE_ALL, STARTUP_ALL_APPLICATIONS } from '../../../constants/api';
import { get, post } from '../../../effects/api/api.common';
import { timeSince } from '../../../utils/CommonMethods';

function StartupHeaderComponent() {
  // @ts-ignore: Unreachable code error
  const [{ user }, dispatch] = useAuthStateValue();
  const [showDashboardFlag, setShowDashboardFlag] = useState(false);
  const [hideDashboard, sethideDashboard] = useState(false)
  const [notificationList, setNotificationList] = useState([])
  const [loader, setLoader] = useState(false);
  const [isFundDetailsVisible, setIsFundDetailsVisible] = useState(true);
  const [isMeetingVisible, setIsMeetingVisible] = useState(true);
  const [ContactUsVisable, setContactUsVisable] = useState(false);

  useEffect(() => {
    // showDashboardTab()   

  }, [user]);
  useEffect(() => {

    setTimeout(() => {
      showNotifications();
    }, 500);
    setTimeout(() => {

      getMeetingDetails();
      showFundDetailsMenu();
    }, 3000)
  }, [])
  const showDashboardTab = () => {
    post(COUNT_SUBIMTTED, '').then((response: any) => {
      if (!response || !response.data) return;
      if (response.data.count != 0) {
        // setShowDashboardFlag(true);
      }
    })
  }

  const showNotifications = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    // console.log(userInfo)
    var payload = {
      "logged_user_id": userInfo.id,
    }
    post(NOTIFICATION_LIST, payload).then((response: any) => {
      if (!response || !response.data) return;
      setNotificationList(response.data.data)
    })
  }

  const statusChange = (id: any) => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    post(NOTIFICATION_LIST_STATUS_CHANGE, { "id": id, "logged_user_id": userInfo.id }).then((response: any) => {
      if (!response || !response.data) return;
      if (response.data.result == "success") {
        showNotifications()
      }
    })
  }
  const statusChangeAll = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)

    var payload = {
      "logged_user_id": userInfo.id
    }
    post(NOTIFICATION_LIST_STATUS_CHANGE_ALL, payload).then((response: any) => {
      if (!response || !response.data) return;
      if (response.data.result == "success") {
        showNotifications()
      }
    })
  }

  const showFundDetailsMenu = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)

    var payload = {
      "get_list": "",
      "logged_user_id": userInfo.id,
      "user_role": userInfo.roles
    }
    setLoader(true)
    post(STARTUP_ALL_APPLICATIONS, payload).then((response: any) => {
      setLoader(false)
      if (!response || !response.data) return;
      if (response.data.data.length != 0 && (response.data.data.Selected.length != 0 || response.data.data.Closed.length != 0 || response.data.data.Cancelled.length != 0)) {
        setIsFundDetailsVisible(true);
      }
      else {
        setIsFundDetailsVisible(false)
      }
      if (response.data.data.length != 0) {
        if (response.data.data.InProgress.length != 0 && response.data.data.InProgress[0].application_status_lable == "Draft") {
          setContactUsVisable(true);
        } else {
          setContactUsVisable(false);
        }
      }

    });
  };
  const getMeetingDetails = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    var payload = {
      "userRole": userInfo.roles,
      "logged_user_id": userInfo.id
    }
    post(GET_STARTUP_MEETINGS, payload).then((response: any) => {
      if (!response || !response.data) return;
      if (response.data.data.upcoming.length == 0 && response.data.data.past.length == 0) {
        setIsMeetingVisible(false)
      }
      // setFilteredUpcomingMeetingData([]);
      // setPastMeetingData(response.data.data.past);
      // setFilteredPastMeetingData([])
    });
  };

  return (
    <>
      {/* {console.log(user, "userrrrr")} */}
      {loader &&
        <div className="spinner-border custom-spinner" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      }
      <fieldset disabled={loader}>
        <header className="app-header header-sec">
          <nav className="navbar navbar-expand-lg navbar-dark fixed-top">
            <div className="container-fluid">
              <Link className="navbar-brand-dashboard mr-md-auto" to="/">
                <span aria-label="SISFS Logo" />
              </Link>

              {/* @* Hamburger menu code for responsive smaller screens *@ */}
              <button
                className="navbar-toggler"
                id="ChangeToggle"
                type="button"
                data-toggle="collapse"
                data-target="#navbarCollapse"
                aria-controls="navbarCollapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <div id="navbar-hamburger">
                  <span className="navbar-toggler-icon">
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </span>
                </div>
                <div id="navbar-close" className="hidden">
                  <span className="fa fa-close"></span>
                </div>
              </button>

              <div className="collapse navbar-collapse" id="navbarCollapse">
                <ul className="navbar-nav m-auto">
                  {/* {hideDashboard?  */}
                  {/* {showDashboardFlag != false && <li className="nav-item">
                <NavLink exact={true} className="nav-link" activeClassName='active' to='/incubator_dashboard'>Dashboard</NavLink>
              </li>} */}
              

                  <li role="none" className="nav-item dropdown">
                    <NavLink exact={true} className="nav-link" activeClassName='active' to='/startup_notice_board'>Notifications</NavLink>
                  </li>
                  <li role="none" className="nav-item dropdown">
                    <NavLink exact={true} className="nav-link" activeClassName='active' to='/startup_status'>My Applications</NavLink>
                  </li>
                  {isMeetingVisible === true && <li role="none" className="nav-item">
                    <NavLink exact={true} className="nav-link" activeClassName='active' to='/startup_meeting'>Meetings</NavLink>
                  </li>}

                  {!ContactUsVisable && <li role="none" className="nav-item">
                    <NavLink exact={true} className="nav-link" activeClassName='active' to='/startup_grievances'>Grievances</NavLink>
                  </li>}

                  {ContactUsVisable && <li role="none" className="nav-item">
                    <NavLink exact={true} className="nav-link" activeClassName='active' to='/contact_startup'>Contact Us</NavLink>
                  </li>}

                  {isFundDetailsVisible === true ? (
                    <li role="none" className="nav-item">
                      <NavLink exact={true} className="nav-link" activeClassName='active' to='/startup_fund_details'>Fund Details</NavLink>
                    </li>
                  ) : (
                    ''
                  )}
                </ul>

                {/* <div className="media align-items-center pl-4">
              <span className="avatar avatar-sm rounded-circle">
                <img
                  src={NotificationLogo}
                  className="img-fluid"
                  alt="Notification Logo"
                />
              </span>
            </div> */}

                <div className="dropdown">
                  <div className="media align-items-center pl-4" data-toggle="dropdown">
                    <span className="avatar avatar-sm rounded-circle notification-bell" >
                      <img
                        src={NotificationLogo}
                        className="img-fluid"
                        alt="Notification Logo"
                      />
                    </span>
                    {notificationList.some((o: any) => o.read_status === 0) && <mark></mark>}
                  </div>

                  <ul className="dropdown-menu dropdown-menu-right notifications" role="menu" aria-labelledby="dLabel">

                    <div className="notification-heading">
                      <h4 className="menu-title">Notifications</h4>
                      {notificationList.length != 0 && <span className="link-orange-text  pull-right" onClick={statusChangeAll}><u>Mark all as read</u></span>}
                    </div>
                    <li className="divider"></li>
                    <div className="notifications-wrapper">
                      {notificationList.length == 0 && <a className="content">

                        <div className="notification-item">
                          <h4 className="item-title">No Notifications</h4>
                        </div>

                      </a>}
                      {notificationList.map((item: any, index: any) => (
                        <a key={index} className={item.read_status == 0 ? "content unread" : "content"} onClick={() => statusChange(item.id)}>

                          <div className="notification-item" >
                            <h4 className="item-title">{item.message}</h4>
                            <p className="item-info">{timeSince(new Date(item.created_at))}</p>
                          </div>

                        </a>
                      ))}
                      {/* <a className="content unread" href="#">
                    <div className="notification-item">
                      <h4 className="item-title">Incubator  Gurdeep Shuddhashil Satrujit submitted application number 23455566  1 May 2021</h4>
                      <p className="item-info">1 day ago</p>
                    </div>
                  </a>
                  <a className="content unread" href="#">
                    <div className="notification-item">
                      <h4 className="item-title">Incubator  Gurdeep Shuddhashil Satrujit submitted application number 23455566  1 May 2021</h4>
                      <p className="item-info">1 day ago</p>
                    </div>
                  </a>
                  <a className="content" href="#">
                    <div className="notification-item">
                      <h4 className="item-title">Incubator  Gurdeep Shuddhashil Satrujit submitted application number 23455566  1 May 2021</h4>
                      <p className="item-info">1 day ago</p>
                    </div>

                  </a>
                  <a className="content" href="#">
                    <div className="notification-item">
                      <h4 className="item-title">Incubator  Gurdeep Shuddhashil Satrujit submitted application number 23455566  1 May 2021</h4>
                      <p className="item-info">1 day ago</p>
                    </div>
                  </a>
                  <a className="content" href="#">
                    <div className="notification-item">
                      <h4 className="item-title">Incubator  Gurdeep Shuddhashil Satrujit submitted application number 23455566  1 May 2021</h4>
                      <p className="item-info">1 day ago</p>
                    </div>
                  </a> */}

                    </div>

                  </ul>

                </div>

                <div
                  className="btn-group header-action-section"
                  hidden={user?.accessToken ? true : false}
                >
                  <a
                    className="btn btn-sisfs btn-transparent-primary"
                    data-toggle="modal" data-target="#LoginCollapse"
                  >
                    Login
                  </a>
                </div>
              </div>

              {/* <!-- Profile Section --> */}
              {user.accessToken && <ProfileContainer user={user} />}
            </div>
          </nav>

          {/* Offcanvas for Login screen */}
          {!user.accessToken && <LoginComponent />}
          {/* Offcanvas for Login screen */}
          <div className="hr">
            <hr />
          </div>
          <ApplyNowModalComponent />
        </header>
      </fieldset>
    </>
  );
}

export default StartupHeaderComponent;
