export const FormatDate = (date: any) =>{
var month = new Array();
month[0] = 'Jan';
month[1] = 'Feb';
month[2] = 'Mar';
month[3] = 'April';
month[4] = 'May';
month[5] = 'Jun';
month[6] = 'Jul';
month[7] = 'Aug';
month[8] = 'Sep';
month[9] = 'Oct';
month[10] = 'Nov';
month[11] = 'Dec';

var d = new Date(date),
  monthn = '' + (d.getMonth() + 1),
  day = '' + d.getDate(),
  year = d.getFullYear();

if (monthn.length < 2) monthn = '0' + monthn;
if (day.length < 2) day = '0' + day;

return [day, month[d.getMonth()], year].join(' ');
  };


  function tConvert (time:any) {
    // Check correct time format and split into components
    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
  
    if (time.length > 1) { // If time format correct
      time = time.slice (1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join (''); // return adjusted time or original string
  }

  export const formatTime = (time: string) => {
    const slicedTime=time.slice(0,-3)
    return tConvert(slicedTime)
};


export const compareTime = (time1: string,time2:string) => {
  if (time2 > time1)
    return true;
else
    return false;
};