import React, { Fragment, useEffect, useState } from "react";
import { getSessionStorageValue } from "../../effects/utils/session-storage";
import { GET_PORTFOLIO_DETAILS, NODE_URL } from "../../constants/api";
import { post } from "../../effects/api/api.common";
import { PortfolioDetailsModel } from "../../models/incubator/PortfolioDetailsModel";
import { useHistory } from "react-router-dom";

function PreviewPortfolio() {
  const [showMore, setShowMore] = useState(false);
  const [dataPortfolio, setDataPortfolio] = useState<any>({});
  const [isPopoverVisible, setPopoverVisible] = useState(false);
  const [portfolioDetails, setPortfolioDetails] =
    useState<PortfolioDetailsModel>(
      new PortfolioDetailsModel(
        "",
        "",
        "",
        "",
        [],
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        [],
        [],
        [],
        "",
        "",
        "",
        false,
        false,
        false,
        false,
        ""
      )
    );
  const history = useHistory();
  const [loggedInRole, setLoggedInRole] = useState("");
  useEffect(() => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    setLoggedInRole(userInfo.roles);
    let IncubatorId = localStorage.getItem("incubatorId");
    getDetailsOfPortfolio(IncubatorId);
  }, []);

  const getDetailsOfPortfolio = (incubatorId: any) => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    post(GET_PORTFOLIO_DETAILS, { logged_user_id: incubatorId }).then(
      (response: any) => {
        const keyFeaturesList = response.data.data[0].key_features.split(",");
        setPortfolioDetails({
          incubator_name: response.data.data[0].incubator_name,
          site_url: response.data.data[0].site_url,
          location_state: response.data.data[0].state,
          location_city: response.data.data[0].city,
          sectors: JSON.parse(response.data.data[0].sectors),
          message: response.data.data[0].description,
          key_features:
            keyFeaturesList[0] != undefined
              ? keyFeaturesList[0].replace(/'/g, "")
              : "",
          key_features1:
            keyFeaturesList[1] != undefined
              ? keyFeaturesList[1].replace(/'/g, "")
              : "",
          key_features2:
            keyFeaturesList[2] != undefined
              ? keyFeaturesList[2].replace(/'/g, "")
              : "",
          key_features3:
            keyFeaturesList[3] != undefined
              ? keyFeaturesList[3].replace(/'/g, "")
              : "",
          key_features4:
            keyFeaturesList[4] != undefined
              ? keyFeaturesList[4].replace(/'/g, "")
              : "",
          incubator_email: response.data.data[0].email_id,
          logo_image: response.data.data[0].logo,
          cover_image: response.data.data[0].image,
          gallery_pictures: [],
          remaining_pictures: "",
          remaining_logo: response.data.data[0].logo,
          remaining_banner: response.data.data[0].image,
          flag: false,
          flag1: false,
          flag2: false,
          flag3: false,
          Id: response.data.data[0].Id,
        });

        // setSectorValue(JSON.parse(response.data.data[0].sectors))
        // getAllDistrictsByStateId(response.data.data[0].state)
        const sectors1: any = [];
        const Sectorsarray = response.data.data[0].sectorsnames.split(",");
        const imagePathString1 = response.data.data[0].image_data.replace(
          /,$/,
          ""
        );
        // console.log(imagePathString1,"imagessss")
        const ImagesArray1 = imagePathString1.split(",");
        const ImagesArray = ImagesArray1.filter(
          (item: any) => item.length > 15
        );
        // console.log(ImagesArray,"111111","imagessss")
        // const ImagesArray = response.data.data[0].image_data.split(',');
        const KeyFeatresArray = response.data.data[0].key_features;
        const resultArray = KeyFeatresArray.split(/,\s*'|'\s*,/)
          .filter((item: any) => item !== "'" && item !== "''")
          .map((item: any) => item.replace(/'/g, ""));
        //  console.log(resultArray);
        sectors1.push({
          Id: response.data.data[0].Id,
          sectors: Sectorsarray,
          state: response.data.data[0].state,
          city: response.data.data[0].cityname,
          image: response.data.data[0].image,
          incubator_name: response.data.data[0].incubator_name,
          description: response.data.data[0].description,
          image_data: ImagesArray,
          key_features: resultArray,
          logo: response.data.data[0].logo,
          site_url: response.data.data[0].site_url,
          email: response.data.data[0].email_id,
        });
        // console.log(sectors1)
        setDataPortfolio(sectors1[0]);
      }
    );
  };

  const goToPreferenceTab = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    if (loggedInRole == "secretariat") {
      history.push("/incubator_prefrence_sec");
    } else if (loggedInRole == "incubator") {
      history.push("/incubator_portfolio");
    }
  };
  const ccEmailAddress = dataPortfolio.email || "";
  var mailtoLink = `mailto:?to=${encodeURIComponent(ccEmailAddress)}`;
  const handleCopyClick = (textToCopy: any) => {
    // Create a textarea element
    const textArea = document.createElement("textarea");

    // Set its value to the text you want to copy
    textArea.value = textToCopy;

    // Append the textarea element to the document
    document.body.appendChild(textArea);

    // Select the text inside the textarea
    textArea.select();

    // Execute the copy command
    document.execCommand("copy");

    // Remove the textarea element
    document.body.removeChild(textArea);

    setPopoverVisible(true);
    setTimeout(() => {
      setPopoverVisible(false);
    }, 2000);
  };
  return (
    <>
      {/* <div className="row text-left mt-5">
                <div className="col-12 ">
                    <button className="btn btn-sisfs btn-yellow-primary"  onClick={goToPreferenceTab}>
                        Back
                    </button>
                </div>
            </div> */}
      <Fragment>
        {Object.keys(dataPortfolio).length > 0 && (
          <div className="container mt-5 mb-5 portfolio-detail-container">
            <div className="col-md-12">
              <div className="d-flex justify-content-between mb-4">
                <div className="logo">
                  <img
                    src={`${NODE_URL}${dataPortfolio.logo}`}
                    alt={dataPortfolio.incubator_name}
                    className="portLogo"
                  />
                </div>
                <div className="d-flex justify-content-between">
                  {dataPortfolio.email != null ? (
                    <>
                      <div className="website mr-3">
                        <button className="btn btn-sisfs btn-transparent-primary mt-4">
                          <a
                            href={mailtoLink}
                            style={{
                              color: "#e26640 ",
                              textTransform: "lowercase",
                            }}
                          >
                            {dataPortfolio.email}
                          </a>{" "}
                          &nbsp;
                          <i
                            className="fa fa-copy"
                            onClick={() => handleCopyClick(dataPortfolio.email)}
                          ></i>
                        </button>
                        {isPopoverVisible && (
                          <div
                            style={{
                              position: "absolute",
                              top: "60px",
                              left: "80%",
                              transform: "translateX(-50%)",
                              background: "#fff",
                              padding: "10px",
                              fontSize: "14px",
                              boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                              borderRadius: "5px",
                            }}
                          >
                            Copied to clipboard!
                          </div>
                        )}
                        <br />
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  <div className="website">
                    <button
                      className="btn btn-sisfs btn-transparent-primary mt-4"
                      onClick={() => window.open(dataPortfolio.site_url)}
                    >
                      Visit Website
                    </button>
                    <br />
                  </div>
                </div>
              </div>

              <h1 className="main-heading portDetailHeading">
                {dataPortfolio.incubator_name}
              </h1>

              <div className="col-md-12">
                <div className="row">
                  <p className="portCity mr-1 pt-1">
                    {dataPortfolio.city},{" " + dataPortfolio.state}|
                  </p>
                  {!showMore && (
                    <p
                      className="portTagDetail ml-4"
                      style={{ display: "contents" }}
                    >
                      {dataPortfolio.sectors.length > 0 &&
                        dataPortfolio?.sectors.map(
                          (sector: string, index: number) => {
                            if (index < 4) {
                              return (
                                <Fragment key={index}>
                                  <span className="mr-1">{sector}</span>
                                </Fragment>
                              );
                            }
                          }
                        )}
                    </p>
                  )}
                  {showMore && (
                    <p
                      className="portTagDetail ml-4"
                      style={{ display: "contents" }}
                    >
                      {dataPortfolio.sectors.length > 0 &&
                        dataPortfolio?.sectors.map(
                          (sector: string, index: number) => {
                            return (
                              <Fragment key={index}>
                                <span className="mr-1">{sector}</span>
                              </Fragment>
                            );
                          }
                        )}
                    </p>
                  )}
                  {!showMore && dataPortfolio.sectors.length > 4 && (
                    <span
                      className="link-orange-text d-block pt-1 pl-3"
                      onClick={() => setShowMore(true)}
                    >
                      View More
                    </span>
                  )}
                </div>

                <div className="mainImage">
                  <img
                    src={`${NODE_URL}${dataPortfolio.image}`}
                    alt={dataPortfolio.incubator_name}
                    className="portImageMain"
                  />
                </div>
              </div>

              {/* <div className="portImageBlock">
                <img src="" className="portImage" />
            </div> */}

              <div className="main-heading right-heading mt-4">About</div>
              <p className="para-text mb-2">{dataPortfolio.description}</p>

              <div className="main-heading right-heading mt-5">
                Key Features
              </div>

              <div className="key">
                <div className="ordered-list wrapper about-ordered-list">
                  <ol>
                    {dataPortfolio.key_features.length > 0 &&
                      dataPortfolio.key_features.map(
                        (feature: string, index: number) => {
                          return (
                            <Fragment key={index}>
                              <li>{feature}</li>
                            </Fragment>
                          );
                        }
                      )}
                  </ol>
                </div>
              </div>

              <div className="main-heading text-center mt-5 mb-4">
                Photo Gallery
              </div>
              <div>
                <div
                  id="carouselExampleIndicators"
                  className="carousel"
                  data-ride="carousel"
                >
                  <a
                    className="carousel-control-prev"
                    href="#carouselExampleIndicators"
                    role="button"
                    data-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Previous</span>
                  </a>
                  <ol className="carousel-indicators">
                    {dataPortfolio.image_data.map(
                      (image: string, index: number) => {
                        if (index == 0) {
                          return (
                            <li
                              data-target="#carouselExampleIndicators"
                              data-slide-to={index}
                              className="active"
                              key={index}
                            ></li>
                          );
                        } else {
                          return (
                            <li
                              data-target="#carouselExampleIndicators"
                              data-slide-to={index}
                              key={index}
                            ></li>
                          );
                        }
                      }
                    )}
                    {/* <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li> */}
                  </ol>
                  <a
                    className="carousel-control-next"
                    href="#carouselExampleIndicators"
                    role="button"
                    data-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Next</span>
                  </a>

                  <div className="carousel-inner carousel-overflow">
                    {dataPortfolio.image_data.map(
                      (image: string, index: number) => {
                        if (index == 0) {
                          return (
                            <div className="carousel-item active" key={index}>
                              <img
                                className="d-block w-100 corousel-image"
                                src={`${NODE_URL}${image}`}
                                alt="First slide"
                              />
                            </div>
                          );
                        } else {
                          return (
                            <div className="carousel-item" key={index}>
                              <img
                                className="d-block w-100 corousel-image"
                                src={`${NODE_URL}${image}`}
                                alt="First slide"
                              />
                            </div>
                          );
                        }
                      }
                    )}
                    {/* <div className="carousel-item active">
                            <img className="d-block w-100" src={data.image} alt="First slide" />
                        </div>
                        <div className="carousel-item">
                            <img className="d-block w-100" src={data.image} alt="Second slide" />
                        </div>
                        <div className="carousel-item">
                            <img className="d-block w-100" src={data.image} alt="Third slide" />
                        </div> */}
                  </div>
                </div>
              </div>
              {/* <div className="text-center">
                <button className="btn btn-sisfs btn-transparent-primary mt-4" onClick={() => window.open(data.site_url)}>
                    Visit Website
                </button>
            </div> */}
            </div>
          </div>
        )}
      </Fragment>

      <div className="row text-center">
        <div className="col-12 ">
          {loggedInRole == "secretariat" ? (
            <button
              className="btn btn-sisfs btn-yellow-primary"
              onClick={goToPreferenceTab}
            >
              Back to Portfolio Requests
            </button>
          ) : loggedInRole == "incubator" ? (
            <button
              className="btn btn-sisfs btn-yellow-primary"
              onClick={goToPreferenceTab}
            >
              Back to My Portfolio
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

export default PreviewPortfolio;
