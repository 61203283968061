import React, { useState, useEffect } from 'react';
import SeedFundSchemeLogo from '../../../images/SeedFundSchemeLogo.svg';
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
  useHistory,
} from 'react-router-dom';
import LoginComponent from '../../common/login/LoginComponent';
import ProfileContainer from '../../common/header/ProfileContainer';
import { getSessionStorageValue } from '../../../effects/utils/session-storage';
import { useAuthStateValue } from '../../../providers/AuthStateProvider';
import NotificationLogo from '../../../images/notifactions.svg';
import '../../../styles/dashboard.css';
import ApplyNowModalComponent from '../../common/header/ApplyNowModalComponent';
import { COUNT_SUBIMTTED, GET_APPLICATION_LIST, GET_APPLICATION_LIST_NODE, NOTIFICATION_LIST, NOTIFICATION_LIST_STATUS_CHANGE, NOTIFICATION_LIST_STATUS_CHANGE_ALL, SHOW_DASHBOARD } from '../../../constants/api';
import { get, post } from '../../../effects/api/api.common';
import { timeSince } from '../../../utils/CommonMethods';

function IncubatorHeaderComponent() {
  // @ts-ignore: Unreachable code error
  const [{ user }, dispatch] = useAuthStateValue();
  const [showDashboardFlag, setShowDashboardFlag] = useState(false);
  const [hideDashboard, sethideDashboard] = useState(false);
  const [notificationList, setNotificationList] = useState([])
  const [visibleMeeting, setVisibleMeeting] = useState(true)
  const [visiblePortfolio, setVisiblePortfolio] = useState(false);
  const [Orange, setOrange] = useState();
  const [Green, setGreen] = useState();
  const [OrangeGreenComplete, setOrangeGreenComplete] = useState();
  const [QRIntiatedDetails, setQRIntiatedDetails] = useState();
  useEffect(() => {

    if (user.accessToken) {
      const overlayElement = document.getElementById('overlay');
      if (!overlayElement) return;

      overlayElement.style.display = 'none';
    }
  }, [user]);
  useEffect(() => {
    showNotifications();
    showDashboardTab();
    checkSelectedApplication();
  }, [])
  const getUrl = (url: any) => {
    if (url != "view_startup_details") {
      localStorage.setItem("value", '4')
    }
  }
  const getFileDetails = (file: any, key: string) => {
    let file_name = "";
    file_name =
      file != undefined && file != null && file.length > 1
        ? file.substr(file.lastIndexOf("/") + 1, file.length)
        : "";

    const file_type =
      file != undefined && file != null && file.length > 1
        ? file.substr(file.lastIndexOf(".") + 1, file.length)
        : "";

    if (key == "name") return file_name;
    if (key == "file") return file != undefined ? file.toString() : "";
    if (key == "type") return file != undefined ? file_type.toString() : "";
  };
  const showDashboardTab = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    var payload = {
      "user_id": userInfo.id
    }
    post(SHOW_DASHBOARD, payload).then((response: any) => {
      if (!response || !response.data) return;
      if (response.data.data.data == true) {
        setShowDashboardFlag(true);
      }
      else {
        setShowDashboardFlag(false)
      }
      // console.log(response)
    });
  };
  const [isFundDetailsVisible, setIsFundDetailsVisible] = useState(false);

  const checkSelectedApplication = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    var payload = {
      "logged_user_id": userInfo.id,
      "roles": userInfo.roles
    }
    post(GET_APPLICATION_LIST, payload).then((response: any) => {
      if (!response || !response.data) return;
      if (response.data.message == "Not A Valid User") {
        setVisibleMeeting(false);
      }
      // console.log(response.data)
      if (response.data.data != null) {
        // const applicationList =
        response.data.data.map((item: any) => {
          if (item.status === 'Selected') {
            setIsFundDetailsVisible(true);
            setVisiblePortfolio(true);
          }
        });
        response.data.data.map((item: any) => {
          // console.log(item)
          if (item.status === 'Draft') {
            setVisibleMeeting(false);
          }
        });
        setOrange(response.data.data && response.data.data[0].quaterly_review_started);
        setGreen(response.data.data && response.data.data[0].all_startups_QR_completed);
        setOrangeGreenComplete(response.data.data && response.data.data[0].quaterly_review_submitted)
        // setQRIntiatedDetails(response.data.data && response.data.data[0].QRIntiatedDetails[0].id);
      }
      else {
        setVisibleMeeting(false);
      }
    });

    post(GET_APPLICATION_LIST_NODE, { "logged_user_id": userInfo.id, "user_role": userInfo.roles }).then((response) => {
      console.log(response);
      if (response.data.data != null) {
        // const applicationList =
        response.data.data.map((item: any) => {
          if (item.status === 'Selected') {
            setVisiblePortfolio(true);
          } else {
            setVisiblePortfolio(false);
          }
        });
      }
    });
  };

  const showNotifications = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    // console.log(userInfo)
    var payload = {
      "logged_user_id": userInfo.id,
    }
    post(NOTIFICATION_LIST, payload).then((response: any) => {
      if (!response || !response.data) return;
      setNotificationList(response.data.data)
    })
  }

  const statusChange = (id: any) => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    post(NOTIFICATION_LIST_STATUS_CHANGE, { "id": id, "logged_user_id": userInfo.id }).then((response: any) => {
      if (!response || !response.data) return;
      if (response.data.result == "success") {
        showNotifications()
      }
    })
  }
  const statusChangeAll = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)

    var payload = {
      "logged_user_id": userInfo.id
    }
    post(NOTIFICATION_LIST_STATUS_CHANGE_ALL, payload).then((response: any) => {
      if (!response || !response.data) return;
      if (response.data.result == "success") {
        showNotifications()
      }
    })
  }
  // @ts-ignore
  // @ts-ignore
  return (
    <header className="app-header header-sec">
      <nav className="navbar navbar-expand-lg navbar-dark fixed-top">
        <div className="container-fluid">
          <Link className="navbar-brand-dashboard mr-md-auto" to="/">
            <span aria-label="SISFS Logo" />
          </Link>

          {/* @* Hamburger menu code for responsive smaller screens *@ */}
          <button
            className="navbar-toggler"
            id="ChangeToggle"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <div id="navbar-hamburger">
              <span className="navbar-toggler-icon">
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </span>
            </div>
            <div id="navbar-close" className="hidden">
              <span className="fa fa-close"></span>
            </div>
          </button>

          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav m-auto">
              {/* {showDashboardFlag && (
                <li className="nav-item">
                  <NavLink
                    exact={true}
                    className="nav-link"
                    activeClassName="active"
                    to="/incubator_dashboard"
                    style={{ padding: '0px', marginTop: '10px' }}
                    onClick={() => getUrl('incubator_dashboard')}
                  >
                    <p>Dashboard</p>
                  </NavLink>
                </li>
              )} */}
              {
                <li className="nav-item">
                <NavLink
                  exact={true}
                  className="nav-link"
                  activeClassName="active"
                  to="/incubator_notice_board"
                  style={{ padding: '0px', marginTop: '10px' }}
                  onClick={() => getUrl('incubator_notice_board')}
                >
                  <p>Notifications</p>
                </NavLink>
              </li>
              }
              {/* // :null}  */}

              <li role="none" className="nav-item dropdown">
                {/* <Link role="menuitem" className="nav-link" to="/incubator_status">
                  My Applications
                </Link> */}
                <NavLink
                  exact={true}
                  className="nav-link"
                  activeClassName="active"
                  to="/incubator_status"
                  style={{ padding: '0px', marginTop: '10px' }}
                  onClick={() => getUrl('incubator_status')}
                >
                  {/* My Applications  */}
                  <p>My Applications</p>
                </NavLink>
              </li>

              {visibleMeeting &&
                <li role="none" className="nav-item">
                  <NavLink
                    exact={true}
                    className="nav-link"
                    activeClassName="active"
                    to="/incubator_meeting"
                    style={{ padding: '0px', marginTop: '10px' }}
                    onClick={() => getUrl('incubator_meeting')}
                  >
                    {/* Meetings */}
                    <p>Meetings</p>
                  </NavLink>
                </li>
              }

              {/* <li role="none" className="nav-item">

               <NavLink
                 exact={true}
                 className="nav-link"
                 activeClassName="active"
                 to={{
                  pathname: "/contact_incubator",
                  state: { selected_tab: "grievance" }

              }}
               >
                 Grievances
               </NavLink>
             </li> */}
              {isFundDetailsVisible === true ? (
                <li role="none" className="nav-item">
                  <NavLink
                    exact={true}
                    className="nav-link"
                    activeClassName="active"
                    to="/incubator_view_funddetails"
                    style={{ padding: '0px', marginTop: '10px' }}
                    onClick={() => getUrl('incubator_view_funddetails')}
                  >
                    {/* Fund Details */}
                    <p>Fund Details</p>
                  </NavLink>
                </li>
              ) : (
                ''
              )}
              {visibleMeeting &&
                <li role="none" className="nav-item text-center">

                  <NavLink
                    exact={true}
                    className="nav-link"
                    activeClassName="active"
                    to="/incubator_startup_list"
                    style={{ padding: '0px', marginTop: '10px' }}
                    onClick={() => getUrl('incubator_startup_list')}
                  >
                    {/* Startups */}
                    <p className={(Orange == 1 && ((Green == 0 && OrangeGreenComplete == 0) || (Green == 1 && OrangeGreenComplete == 0))) ? "orange_link_header" : (Orange == 1 && Green == 1 && OrangeGreenComplete == 1) ? "green_link_header" : (Orange == 0 && Green == 0 && OrangeGreenComplete == 0) ? "" : ''} style={{ color: "#333333" }}>Startups</p>
                    {(Orange == 1 && ((Green == 0 && OrangeGreenComplete == 0) || (Green == 1 && OrangeGreenComplete == 0))) && <p style={{ color: "orange" }}>Quarterly Review ONGOING</p>}
                    {/* {(Orange == 1 && Green == 1 && OrangeGreenComplete == 0) && <p style={{color:"#68e568"}}>Quarterly Review SUCCESS</p>} */}
                    {(Orange == 1 && Green == 1 && OrangeGreenComplete == 1) && <p style={{ color: "#68e568" }}>Quarterly Review COMPLETED</p>}
                  </NavLink>
                </li>
              }

              {visibleMeeting && <li role="none" className="nav-item">

                <NavLink
                  exact={true}
                  className="nav-link"
                  activeClassName="active"
                  to="/incubator_grievances"
                  style={{ padding: '0px', marginTop: '10px' }}
                  onClick={() => getUrl('incubator_grievances')}
                >
                  {/* Grievances */}
                  <p>Grievances</p>
                </NavLink>
              </li>}

              {!visibleMeeting && <li role="none" className="nav-item">

                <NavLink
                  exact={true}
                  className="nav-link"
                  activeClassName="active"
                  to="/contact_incubator"
                  style={{ padding: '0px', marginTop: '10px' }}
                  onClick={() => getUrl('contact_incubator')}
                >
                  {/* Contact Us */}
                  <p>Contact Us</p>
                </NavLink>
              </li>}
              {
                visiblePortfolio &&
                <li role="none" className="nav-item dropdown">
                  <NavLink
                    exact={true}
                    className="nav-link"
                    activeClassName="active"
                    to="/incubator_portfolio"
                    style={{ padding: '0px', marginTop: '10px' }}
                  >
                    <p>My Portfolio</p>
                  </NavLink>
                </li>
              }
            </ul>

            {/* Notification Code */}
            <div className="dropdown">
              <div className="media align-items-center pl-4" data-toggle="dropdown">
                <span className="avatar avatar-sm rounded-circle notification-bell" >
                  <img
                    src={NotificationLogo}
                    className="img-fluid"
                    alt="Notification Logo"
                  />
                </span>
                {notificationList && notificationList.some((o: any) => o.read_status === 0) && <mark></mark>}
              </div>

              <ul className="dropdown-menu dropdown-menu-right notifications" role="menu" aria-labelledby="dLabel">

                <div className="notification-heading">
                  <h4 className="menu-title">Notifications</h4>
                  {notificationList.length != 0 && <span className="link-orange-text  pull-right" onClick={statusChangeAll}><u>Mark all as read</u></span>}
                </div>
                <li className="divider"></li>
                <div className="notifications-wrapper">
                  {notificationList.length == 0 && <a className="content">

                    <div className="notification-item">
                      <h4 className="item-title">No Notifications</h4>
                    </div>

                  </a>}
                  {notificationList && notificationList.map((item: any, index) => (
                    <a key={index} className={item.read_status == 0 ? "content unread" : "content"} onClick={() => statusChange(item.id)}>

                      <div className="notification-item">
                        <h4 className="item-title">{item.message}</h4>
                        <p className="item-info">{timeSince(new Date(item.created_at))}</p>
                      </div>

                    </a>
                  ))}

                </div>

              </ul>
            </div>

            {/* <div className="media align-items-center pl-4">
              <span className="avatar avatar-sm rounded-circle">
                <img
                  src={NotificationLogo}
                  className="img-fluid"
                  alt="Notification Logo"
                />
              </span>
            </div> */}
            <div
              className="btn-group header-action-section"
              hidden={user?.accessToken ? true : false}
            >
              <a
                className="btn btn-sisfs btn-transparent-primary"
                data-toggle="modal"
                data-target="#LoginCollapse"
              // data-toggle="offcanvas"
              // to={
              //   !user.accessToken ? '/login' : '/'
              // } /*onToggle={toggleOffcanvas} /*to="/login"*/
              >
                Login
              </a>
            </div>
          </div>

          {/* <!-- Profile Section --> */}
          {user.accessToken && <ProfileContainer user={user} />}
        </div>
      </nav>

      {/* Offcanvas for Login screen */}
      {!user.accessToken && <LoginComponent />}
      {/* Offcanvas for Login screen */}
      <div className="hr">
        <hr />
      </div>
      <ApplyNowModalComponent />
    </header>
  );
}

export default IncubatorHeaderComponent;
