import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import DpiitDashboardContainer from "../components/dpiit/dashboard/DpiitContainer";
import IncubatorListingContainer from "../components/dpiit/dashboard/IncubatorListingContainer";
import DashboardFooterComponent from "../components/common/footer/DashboardFooterComponent";
import EacReviewDpiit from "../components/dpiit/dashboard/EacReviewDpiit";
import ViewIncubatorDetails from "../components/dpiit/incubator/ViewIncubatorDetails";
import { getSessionStorageValue } from "../effects/utils/session-storage";
import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import ContactContainer from "../components/contact/ContactContainer";
import GrievanceListComponent from "../components/dpiit/grievances/GrievanceListComponent";
import AddUserComponent from "../components/dpiit/dashboard/AddUserComponent";
import ViewUserComponent from "../components/dpiit/dashboard/ViewUsersComponent";
import GrievanceDetailComponent from "../components/dpiit/grievances/GrievanceDetailComponent";
import DpiitStartupListingComponent from "../components/dpiit/startuplisting/DpiitStartupListingComponent";
import ViewStartupDetailsComponent from "../components/incubator/startuplisting/startupdetails/ViewStartupDetailsComponent";
import SecretariatHeaderComponent from "../components/dpiit/common/SecretariatHeaderComponent";
import StartupListContainer from "../components/incubator/startuplisting/StartupListContainer";
import IncubatorPreferenceComponent from "../components/dpiit/IncubatorPreferenceComponent";
import ViewApplicationDetails from "../components/dpiit/incubator/ViewApplicationDetails";
import IncAppliedReappliedContainer from "../components/dpiit/dashboard/IncAppliedReappliedContainer";
import MergedIncubatorDetailsComponent from "../components/dpiit/incubator/MergedIncubators/MergedIncubatorDetailsComponent";
import QrViewComponent from "../components/dpiit/qrview/QrViewComponent";
import IncubatorPortfolio from "../components/dpiit/incubator-portfolio/IncubatorPortfolio";
import PreviewPortfolio from "../components/dpiit/PreviewPortfolio";
import RequireAuth from "../Routelocks/RouterHoooks";
import SubsequentTranche from "../components/incubator/dashboard/SubsequentTranche";
import SubsequentTrancheTwo from "../components/incubator/dashboard/SubsequentTrancheTwo";
import FirstTrancheSubsequent from "../components/incubator/dashboard/FirstTrancheSubsequent";
import NoticeBoard from "../components/notice_board/NoticeBoard";
import SecretariatReportsComponent from "../components/dpiit/SecretariatReportsComponent";
import MonthlyReportComponent from "../components/dpiit/monthlyreport";
const SecretariatRouterComponent = () => {
  const history = useHistory();
  const user: any = getSessionStorageValue("user");
  const userInfo = JSON.parse(user);
  useEffect(() => {
    // console.log("Secreta page load")
    // console.log("secretariant")
    //  console.log(userInfo.roles,"roles#####")
    // if (user == null || (userInfo.roles != "secretariat" && userInfo.roles != "dpiit")) {
    //   history.push('/error');
    // }
  }, []);
  return (
    <>
      <RequireAuth>
        <SecretariatHeaderComponent />
        <Switch>
          <Route
            path="/dashboard_secretariat"
            component={DpiitDashboardContainer}
          />
          <Route path="/eac_review_secretariat" component={EacReviewDpiit} />
          <Route
            path="/grievance_detail_secretariat"
            component={GrievanceDetailComponent}
          />
          <Route
            path="/secretariat_incubator_list"
            component={IncubatorListingContainer}
          />
          <Route
            path="/secretariat_startup_list"
            component={DpiitStartupListingComponent}
          />
          <Route
            path="/secretariat_grievances"
            component={GrievanceListComponent}
          />
          <Route path="/user_add_secretariat" component={AddUserComponent} />
          <Route path="/user_view_secretariat" component={ViewUserComponent} />
          <Route
            path="/secretariat_view_incubator"
            component={ViewIncubatorDetails}
          />
          <Route
            path="/secretariat_view_application"
            component={ViewApplicationDetails}
          />
          <Route
            path="/secretariat_startup_details"
            component={ViewStartupDetailsComponent}
          />
          <Route
            path="/secretariat_startup_list_details"
            component={StartupListContainer}
          />
          <Route path="/contact_us_secretariat" component={ContactContainer} />
          <Route
            path="/incubator_prefrence_sec"
            component={IncubatorPreferenceComponent}
          />
          <Route
            path="/secretariatreports"
            component={SecretariatReportsComponent}
          />
          <Route path="/monthlyreport" component={MonthlyReportComponent} />
          <Route path="/merged_list" component={IncAppliedReappliedContainer} />
          <Route
            path="/merged_Incubator_Details_list"
            component={MergedIncubatorDetailsComponent}
          />
          <Route path="/qr_view" component={QrViewComponent} />
          <Route path="/sec_notice_board" component={NoticeBoard} />
          <Route path="/incubators_portfolio" component={IncubatorPortfolio} />
          <Route path="/preview_portfolio" component={PreviewPortfolio} />
          <Route
            exact
            path="/secretariat/first_subsequent_tranche/:id/:tranche_number/:app_index/:application_id/:trancheID/:tranche_status/:incubator_application_no"
            component={FirstTrancheSubsequent}
          />
          <Route
            exact
            path="/secretariat/subsequent_tranche/:id/:tranche_number/:app_index/:application_id/:trancheID/:tranche_status/:incubator_application_no"
            component={SubsequentTranche}
          />
          <Route
            exact
            path="/secretariat/subsequent_tranche_two/:id/:tranche_number/:app_index/:application_id/:trancheID/:tranche_status/:incubator_application_no"
            component={SubsequentTrancheTwo}
          />
        </Switch>
        {/* Dashboard Footer Component */}
        <DashboardFooterComponent />
      </RequireAuth>
    </>
  );
};

export default SecretariatRouterComponent;
