import React, { useEffect, useState } from "react";
import Stepper from "bs-stepper";
import IncrementDecrement from "../../common/controls/IncrementDecrement";
import { IncubatorDetailsModel } from "../../../models/incubator/IncubatorDetailsModel";
import { SaveRequestModel } from "../../../models/incubator/SaveRequestModel";
import { post } from "../../../effects/api/api.common";
import { FILE_REMOVE, FILE_UPLOAD, STORE_INCUBATOR_APPLICATION } from "../../../constants/api";
import { requestType, StepperEnum, UploadTypeEnum } from "../../../utils/Enums";
import FileUploader from "@amodv/react-file-uploader";
import { FileUploadModel } from "../../../models/FileUploadModel";
import { validateNumber, validateRequiredText } from "../../../effects/utils/validations";
import { useHistory } from "react-router-dom";
import { getSessionStorageValue } from "../../../effects/utils/session-storage";
import pdf from "../../../images/PDF.svg";
import xls from "../../../images/xls.svg";
import doc from "../../../images/doc.svg";
import xlsx from "../../../images/xls.svg";
import Allround from "../../../images/Allround.svg";
interface IIncubatorDetails {
  // stepper: any;
  stepper: any;
  incubatorDetailsModel: IncubatorDetailsModel;
  onStepSave: (
    stepperIndex: number,
    incubatorDetailsModel: IncubatorDetailsModel,
    request: number
  ) => void;
  errors: Array<string>;
  applicationId: number;
  disableForm: any;
  functions:any
}

interface IIKeyValueIndexer {
  [key: string]: boolean;
}

function SupportDetailsComponent(props: IIncubatorDetails) {
  const { stepper, incubatorDetailsModel, onStepSave, applicationId, disableForm,functions } = props;
  const [openUploadBlock, setOpenUploadBlock] = useState<IIKeyValueIndexer>();

  const [
    incubatorDetails,
    setIncubatorDetails,
  ] = useState<IncubatorDetailsModel>(
    new IncubatorDetailsModel(
      "",
      1,
      0,
      0,
      0,
      1,
      0,
      0,
      true,
      1,
      true,
      1,
      true,
      1,
      true,
      1,
      true,
      1,
      true,
      1,
      true,
      1,
      true,
      1,
      true,
      1,
      true,
      1,
      true,
      1,
      true,
      []
    )
  );
  const [loader, setLoader] = useState(false);

  const [showIncubatorFailureModal, setShowIncubatorFailureModal] = useState(false);

  const dismissModal = () => {
    setShowIncubatorFailureModal(false);
  };

  useEffect(() => {
    setIncubatorDetails(incubatorDetailsModel);
  }, [incubatorDetailsModel, incubatorDetailsModel.facilities_pictures]);
  const [popUpName, setPopUpName] = useState("");
  const setMeetingModal = (fieldName: string) => {
    setPopUpName(fieldName);
  };
  /** upload the files */
  const onFileUpload = (files: Array<File>, type: string) => {
    let indexes: Array<string> = [];

    let updatedSupportDetail = Object.assign({}, incubatorDetails);

    if (files.length > 10) {
      setShowIncubatorFailureModal(true);
      return;
    } else if (files.length <= 10) {
      const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user)
        const formData = new FormData();
      formData.append("application_id", applicationId.toString());
        formData.append("file_type", type);
        formData.append("logged_user_id", userInfo.id)
      for (let i=0; i<= files.length; i++) {
        // if already upladed then skip
        const fileData = incubatorDetails as any;

        let updatedFileData: Array<FileUploadModel> = [];
        // const fileExists = fileData[type].findIndex(
        //   (uf: FileUploadModel) => uf.file.name === file.name
        // );

        // if (fileExists > -1) continue;
        
        // Update the formData object
        formData.append("file", files[i], files[i].name);
        if(i == files.length-1){
        setLoader(true);
        post(FILE_UPLOAD, formData).then((response: any) => {
          if (!response.data) return;
          setLoader(false);
          functions(applicationId);
        });
      }
        // setIncubatorDetails(JSON.parse(JSON.stringify(incubatorDetails)));
      }
    }


    if (indexes.length) {
      indexes.map((ind) => {
        const position: number = files.findIndex((file) => file.name === ind);
        files.splice(position, 1);
      });
    }
  };

  // Triggers when delete file is clicked
  const onFileDelete = (file: File, type: string) => {
    const {
      facilities_pictures,
    } = incubatorDetails;
    let remainingFiles: Array<FileUploadModel> = [];
    let fileToBeDeleted: any;

    switch (type) {
      case UploadTypeEnum.FacilitiesPictures:
        fileToBeDeleted = facilities_pictures.find(
          (uploadedFile: FileUploadModel) => getFileDetails(uploadedFile.file, 'name') === file.name
        );

        if (!fileToBeDeleted) return;
        remainingFiles = facilities_pictures.filter(
          (uf: FileUploadModel) => uf.file !== fileToBeDeleted.file
        );

        incubatorDetails.facilities_pictures = remainingFiles;
        onStepSave(
          StepperEnum.IncubatorDetail,
          incubatorDetails,
          requestType.propertyChange
        );
        break;
    }
    setLoader(true);
    // delete the file
    post(FILE_REMOVE, {
      application_id: applicationId,
      file_id: fileToBeDeleted.file_id,
    }).then((response: any) => {
      if (response.data) {
        setLoader(false);
        setIncubatorDetails(incubatorDetails);
      }
    });
    setIncubatorDetails(JSON.parse(JSON.stringify(incubatorDetails)));
  };
  const getFileDetails = (file: any, key: string) => {
    let file_name = '';
    file_name =
      file != undefined && file != null && file.length > 1
        ? file.substr(file.lastIndexOf('/') + 1, file.length)
        : '';

    const file_type = file != undefined && file != null && file.length > 1
      ? file.substr(
        file.lastIndexOf('.') + 1,
        file.length
      ) : '';

    if (key == 'name') return file_name;
    if (key == 'file') return file != undefined ? file.toString() : '';
    if (key == 'type') return file != undefined ? file_type.toString() : '';
  };

  // triggers whenever user makes any changes in the form for select and input type
  const handleChange = (
    evt: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const name = evt.target.name;
    const value = evt.target.value
    // .toString().split(".").map((el, i) => i ? el.split("").slice(0, 2).join("") : el).join(".");;

    if (name === 'list_facilities') {
      let supportDetails = JSON.parse(JSON.stringify(incubatorDetails))
      // console.log('supportDetails', supportDetails[name])
      supportDetails[name] = value.length != 0 ? value : "";
      setIncubatorDetails(supportDetails);
      onStepSave(StepperEnum.IncubatorDetail, supportDetails, requestType.propertyChange);
    }
    else {

      // if (value!="" && !isNaN(parseInt(value))) {
      //   let supportDetails = JSON.parse(JSON.stringify(incubatorDetails));
      //   supportDetails[name] = parseInt(value);
      //   setIncubatorDetails(supportDetails);
      //   onStepSave(
      //     StepperEnum.IncubatorTeam,
      //     supportDetails,
      //     requestType.propertyChange
      //   );
      // }
      // if (value=="") {
      //   let supportDetails = JSON.parse(JSON.stringify(incubatorDetails));
      //   supportDetails[name] ="";
      //   setIncubatorDetails(supportDetails);
      //   onStepSave(
      //     StepperEnum.IncubatorTeam,
      //     supportDetails,
      //     requestType.propertyChange
      //   );
      // }
      // else if(isNaN(parseInt(value))){
      //   return
      // }

      // isNaN(parseInt(value)
      if (value) {
        let supportDetails = JSON.parse(JSON.stringify(incubatorDetails));
        // supportDetails[name] = parseInt(value);
        supportDetails[name] = value;
        setIncubatorDetails(supportDetails);
        onStepSave(
          StepperEnum.IncubatorDetail,
          supportDetails,
          requestType.propertyChange
        );
      }
      if (value == '') {
        let supportDetails = JSON.parse(JSON.stringify(incubatorDetails));
        supportDetails[name] = '';
        setIncubatorDetails(supportDetails);
        onStepSave(
          StepperEnum.IncubatorDetail,
          supportDetails,
          requestType.propertyChange
        );
      }
    }
  };

  const handleIncrementDecrementChange = (name: string, value: number) => {
    setIncubatorDetails((prevState: IncubatorDetailsModel) => {
      return {
        ...prevState,
        // [name]: value ? value : "",
        [name]: (value <= 0) ? 0 : value,
      };
    });
  };

  // handle all the check-box event
  const handleOnCheck = (e: any) => {
    // const name = e.target.name;
    // let link: string = "";

    // if (name === "startups_invested_total_corpus_applicable") link = "startups_invested_total_corpus";

    setIncubatorDetails((prevState: IncubatorDetailsModel) => {
      return {
        ...prevState,
        [e.target.name]: e.target.checked,
      };
    });

    // if (!e.target.checked) {
    //   // if(e.target.name === 'startups_invested_total_corpus'){
    //   setIncubatorDetails((prevState: IncubatorDetailsModel) => {
    //     return {
    //       ...prevState,
    //       ['startups_invested_total_corpus']: '',
    //     };
    //   });
    //   // }

    // }
  };
  const history = useHistory();

  const applicationPage = () => {
    history.push({
      pathname: "/incubator_status",
    });
  }
  // triggers when user clicks on the save and next button
  const onSave = () => {
    onStepSave(StepperEnum.IncubatorDetail, incubatorDetails, requestType.save);

    stepper && stepper.next();
    window.scrollTo(0, 250);
  };

  return (
    <div
      id="incubator-step-3"
      role="tabpanel"
      className="bs-stepper-pane content"
      aria-labelledby="stepper1trigger3"
    >
      {loader &&
        <div className="spinner-border custom-spinner" role="status">
          <span className="sr-only">Loading...</span>
        </div>}
      {/* {console.log('VALIDATION', props.errors)} */}
      <fieldset disabled={loader}>
        {/* Incubator Stepper Form 3 */}
        <div className="form-wrapper stepper-form stepper-form-3">
          <div className="form-content">
            {/* New Addition */}
            <fieldset disabled={disableForm}>
              <div className="form-group required row">
                <label className="col-sm-4">
                  <div className="col-form-label">
                    List the significant facilities available for startups at the
                    incubation center
                  </div>
                  <small>
                    e.g: labs, testing facilities, makerspace equipment, etc.
                  </small>
                </label>
                <div className="col-sm-8">
                  <textarea
                    className={`${props.errors.length &&
                      validateRequiredText(incubatorDetails.list_facilities, 20000)
                      ? `form-control error`
                      : "form-control"
                      }`}
                    rows={4}
                    placeholder="List of Facilities"
                    name="list_facilities"
                    value={incubatorDetails.list_facilities}
                    onChange={handleChange}
                    maxLength={20000}
                  ></textarea>
                  <small>Max. 20,000 characters</small>
                  <div
                    className={`${props.errors.length &&
                      validateRequiredText(incubatorDetails.list_facilities, 20000)
                      ? `col-12 pl-0 error`
                      : "invalid-feedback "
                      }`}
                  >
                    {validateRequiredText(incubatorDetails.list_facilities, 20000)}
                  </div>
                  {/* <div
                  className={`${props.errors.length && !incubatorDetails.list_facilities?.length
                    ? `pl-0 col-12 error`
                    : "invalid-feedback "
                    }`}
                >
                  This is mandatory field
                </div> */}
                </div>

              </div>

            </fieldset>
            <hr />
            {/* New Addition */}

            <div className="form-group required row">
              <label className="col-sm-4 col-form-label">
                Pictures of facilities
              </label>
              <div className="col-sm-8">
                <div className="">
                  {/* <input type="file" className="form-control-file btn btn-sisfs btn-transparent-primary" id="exampleFormControlFile1" /> */}
                  <fieldset disabled={disableForm}>
                    <button
                      className="btn btn-sisfs btn-transparent-primary"
                      onClick={() => setOpenUploadBlock({ FacilitiesPictures: true })}
                    >
                      Upload
                    </button>

                    {((openUploadBlock &&
                      openUploadBlock["FacilitiesPictures"] &&
                      incubatorDetails.facilities_pictures.length === 0) ||
                      incubatorDetails.facilities_pictures.length > 0) && (
                        <>
                          <span
                            className="close"
                            onClick={() => setOpenUploadBlock({ FacilitiesPictures: false })}
                          >
                            <i className="fa fa-close"></i>
                          </span>
                          <FileUploader
                            // key={UploadTypeEnum.FacilitiesPictures}
                            key={Math.random().toString()}
                            id={UploadTypeEnum.FacilitiesPictures}
                            information="Drag & Drop the file or Browse"
                            fileType="image/*"
                            buttonLabel="Browse"
                            multiple={true}
                            enablePreview
                            IconComponent={() => (
                              <span className="fa fa-cloud-upload"></span>
                            )}
                            removeIconClass=""
                            existingFiles={incubatorDetails.facilities_pictures.map(
                              (uploadedFile: FileUploadModel) =>
                              ({
                                name: getFileDetails(uploadedFile.file, 'name') || uploadedFile.file.name,
                                url: uploadedFile.file
                              })
                              // uploadedFile.file
                            )}
                            onUploadFinish={(files: Array<File>) => {
                              onFileUpload(files, UploadTypeEnum.FacilitiesPictures);
                            }}
                            onFileDelete={(deletedFile: File) =>
                              onFileDelete(deletedFile, UploadTypeEnum.FacilitiesPictures)
                            }
                          />
                        </>
                      )}
                  </fieldset>
                  {/* && incubatorDetails.facilities_pictures.some((item) => item.file_name) */}
                  {(incubatorDetails.facilities_pictures.length >
                    0 && incubatorDetails.facilities_pictures[0].file_type_id != 0) ? (
                    <a
                      className="incubator-details-link"
                      data-toggle="modal"
                      data-target="#showMoreDocuments"
                      onClick={() => setMeetingModal("Facilities Pictures")}
                    >
                      View uploaded Documents
                    </a>
                  ) : (
                    ""
                  )}
                  {/* <div>
                      {incubatorDetails.facilities_pictures.map((uploadedFile) => (
                          <div key={`${uploadedFile.file}`} >
                              <span className="pdfText">{getFileDetails(uploadedFile.file, 'name')} - </span> 
                              <a href={`${uploadedFile.file}`} download target="blank" className="orange-link-text">
                                  View 
                              </a>
                          </div>
                        ))}
                      </div> */}
                  <small>
                    Only images are allowed
                  </small>
                  <small>
                    Please upload pictures of seating facility, available infrastructure,
                    testing labs etc.
                  </small>
                  <div
                    className={`${props.errors.length && !incubatorDetails.facilities_pictures.length
                      ? `col-12 pl-0 error`
                      : "invalid-feedback "
                      }`}
                  >
                    This is mandatory field
                  </div>

                </div>
              </div>
            </div>
            {/* </div> */}
            <hr />

            <fieldset disabled={disableForm}>
              <div className="form-group required row">
                <label className="col-sm-4 col-form-label">
                  Incubation support provided by the incubator in last three years
                </label>
                <div className="col-sm-8">
                  <div className="form-row">
                    <div className="col">
                      <span className="col-label">
                        No. of startups incubated and their details
                      </span>

                      <IncrementDecrement
                        name="startups_no_incubated"
                        value={incubatorDetails.startups_no_incubated}
                        onChange={(name: string, value: number) =>
                          handleIncrementDecrementChange(
                            "startups_no_incubated",
                            value
                          )
                        }
                        minValue={1}
                      />

                      <small>
                        More details to be provided in Excel sheet in <b>step 5</b>
                      </small>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-8">
                      <span className="col-label">No. of startups graduated</span>

                      <IncrementDecrement
                        name="startups_no_graduated"
                        value={incubatorDetails.startups_no_graduated}
                        onChange={(name: string, value: number) =>
                          handleIncrementDecrementChange(
                            "startups_no_graduated",
                            value
                          )
                        }
                        minValue={0}
                      />

                      <small>
                        ( i.e. progressed from one stage of business development
                        cycle to the next )
                      </small>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col">
                      <span className="col-label">
                        No. of startups that raised follow on investments
                      </span>

                      <IncrementDecrement
                        name="startups_no_investments"
                        value={incubatorDetails.startups_no_investments}
                        onChange={(name: string, value: number) =>
                          handleIncrementDecrementChange(
                            "startups_no_investments",
                            value
                          )
                        }
                        minValue={0}
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col">
                      <span className="col-label">
                        No. of startups that crossed a revenue of Rs 1 Cr in last 1
                        year
                      </span>

                      <IncrementDecrement
                        name="startups_no_revenue_year"
                        value={incubatorDetails.startups_no_revenue_year}
                        onChange={(name: string, value: number) =>
                          handleIncrementDecrementChange(
                            "startups_no_revenue_year",
                            value
                          )
                        }
                        minValue={0}
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col">
                      <span className="col-label">
                        2-year survival rate of startups from the date of joining
                        incubator
                      </span>

                      <div className="form-inline">
                        <IncrementDecrement
                          name="startups_survival_rate"
                          value={incubatorDetails.startups_survival_rate}
                          onChange={(name: string, value: number) =>
                            handleIncrementDecrementChange(
                              "startups_survival_rate",
                              value
                            )
                          }
                          minValue={0}
                        />
                        <div className="ml-3">
                          <span className="form-control-plaintext">(%)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr />

              <div className="form-group required row">
                <label className="col-sm-4 col-form-label">
                  Funding support extended to incubatees in last three years
                </label>
                <div className="col-sm-8">
                  <div className="form-row">
                    <div className="col">
                      <span className="col-label">
                        Investment agreements signed between incubator and startups
                      </span>
                      <IncrementDecrement
                        name="investments_between_incubator_startups"
                        value={
                          incubatorDetails.investments_between_incubator_startups
                        }
                        onChange={(name: string, value: number) =>
                          handleIncrementDecrementChange(
                            "investments_between_incubator_startups",
                            value
                          )
                        }
                        minValue={0}
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col">
                      <span className="col-label">No. of startups invested in</span>
                      <IncrementDecrement
                        name="startups_invested"
                        // value={incubatorDetails.startups_invested}
                        onChange={(name: string, value: number) =>
                          handleIncrementDecrementChange(
                            "startups_invested",
                            value
                          )
                        }
                        value={!incubatorDetails.startups_invested_applicable ?
                          incubatorDetails.startups_invested :
                          1
                        }
                        minValue={1}
                        disabled={incubatorDetails.startups_invested_applicable ? true : false}
                      />
                      <div className="custom-control custom-checkbox mt-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="startups_invested_applicable"
                          name="startups_invested_applicable"
                          onChange={handleOnCheck}
                          checked={incubatorDetails.startups_invested_applicable ? true : false}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="startups_invested_applicable"
                        >
                          Not Applicable
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col">
                      <span className="col-label">
                        Total Corpus allocated to incubatees (&#8377; Lakh)
                      </span>
                      <div className="form-inline">
                        <input
                          type="number"
                          className={`${!incubatorDetails.startups_invested_total_corpus_applicable && props.errors.indexOf(`startups_invested_total_corpus`) > -1 ? `form-control error` : 'form-control'}`}
                          name="startups_invested_total_corpus"
                          value={!incubatorDetails.startups_invested_total_corpus_applicable ?
                            incubatorDetails.startups_invested_total_corpus :
                            ''
                          }
                          placeholder="Enter Amount (&#8377;)"
                          onChange={handleChange}
                          disabled={incubatorDetails.startups_invested_total_corpus_applicable ? true : false}
                        />
                        <div className="ml-2">
                          <span className="form-control-plaintext">Lakhs</span>
                        </div>
                        <div className={`${props.errors.length && validateNumber(incubatorDetails.startups_invested_total_corpus, 50000000) && !incubatorDetails.startups_invested_total_corpus_applicable ? `col-12 pl-0 error` : 'invalid-feedback '}`}>
                          {validateNumber(incubatorDetails.startups_invested_total_corpus, 50000000)}</div>
                      </div>

                      <div className="custom-control custom-checkbox mt-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="startups_invested_total_corpus_applicable"
                          name="startups_invested_total_corpus_applicable"
                          onChange={handleOnCheck}
                          checked={incubatorDetails.startups_invested_total_corpus_applicable ? true : false}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="startups_invested_total_corpus_applicable"
                        >
                          Not Applicable
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col">
                      <span className="col-label">
                        Total investments raised by incubatees from external sources
                      </span>

                      <div className="form-inline">
                        <input
                          type="number"
                          className={`${!incubatorDetails.total_investments_raised_incubatees_applicable && props.errors.indexOf(`total_investments_raised_incubatees`) > -1 ? `form-control error` : 'form-control'}`}
                          name="total_investments_raised_incubatees"
                          value={!incubatorDetails.total_investments_raised_incubatees_applicable ?
                            incubatorDetails.total_investments_raised_incubatees :
                            ''
                          }
                          placeholder="Enter Amount (&#8377;)"
                          onChange={handleChange}
                          disabled={incubatorDetails.total_investments_raised_incubatees_applicable ? true : false}
                        />
                        <div className="ml-2">
                          <span className="form-control-plaintext">Lakhs</span>
                        </div>
                        <div className={`${props.errors.length && validateNumber(incubatorDetails.total_investments_raised_incubatees, 50000000) && !incubatorDetails.total_investments_raised_incubatees_applicable ? `col-12 pl-0 error` : 'invalid-feedback '}`}>
                          {validateNumber(incubatorDetails.total_investments_raised_incubatees, 50000000)}</div>
                      </div>

                      <div className="custom-control custom-checkbox mt-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="total_investments_raised_incubatees_applicable"
                          name="total_investments_raised_incubatees_applicable"
                          onChange={handleOnCheck}
                          checked={incubatorDetails.total_investments_raised_incubatees_applicable ? true : false}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="total_investments_raised_incubatees_applicable"
                        >
                          Not Applicable
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr />

              <div className="form-group required row">
                <label className="col-sm-4 col-form-label">
                  Mentoring provided to incubatees in last three years
                </label>
                <div className="col-sm-8">
                  <div className="form-row">
                    <div className="col">
                      <span className="col-label">
                        No. of mentors actively engaged with incubator
                      </span>
                      <IncrementDecrement
                        name="mentors_no_engaged"
                        value={!incubatorDetails.mentors_no_engaged_applicable ?
                          incubatorDetails.mentors_no_engaged :
                          1
                        }
                        onChange={(name: string, value: number) =>
                          handleIncrementDecrementChange(
                            "mentors_no_engaged",
                            value
                          )
                        }
                        disabled={incubatorDetails.mentors_no_engaged_applicable ? true : false}
                        minValue={1}
                      />
                      <div className="custom-control custom-checkbox mt-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="mentors_no_engaged_applicable"
                          name="mentors_no_engaged_applicable"
                          onChange={handleOnCheck}
                          checked={incubatorDetails.mentors_no_engaged_applicable ? true : false}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="mentors_no_engaged_applicable"
                        >
                          Not Applicable
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col">
                      <span className="col-label">
                        Average mentoring hours allocated per startup per month
                      </span>

                      <div className="form-inline">
                        <input
                          type="number"
                          className={`${!incubatorDetails.mentors_average_hours_applicable && props.errors.indexOf(`total_investments_raised_incubatees`) > -1 ? `form-control error` : 'form-control'}`}
                          placeholder="Enter (hrs)"
                          name="mentors_average_hours"
                          value={!incubatorDetails.mentors_average_hours_applicable ?
                            incubatorDetails.mentors_average_hours :
                            1
                          }
                          onChange={handleChange}
                          disabled={incubatorDetails.mentors_average_hours_applicable ? true : false}
                        />
                        <div className="ml-2">
                          <span className="form-control-plaintext">Hours</span>
                        </div>
                        {/* <div className={`${props.errors.length && validateNumber(incubatorDetails.mentors_average_hours, 500) ? `col-12 pl-0 error` : 'invalid-feedback '}`}>
                {validateNumber(incubatorDetails.mentors_average_hours, 500)}</div> */}
                      </div>
                      <small>
                        More details to be provided in Excel sheet in <b>step 5</b>
                      </small>

                      <div className="custom-control custom-checkbox ">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="mentors_average_hours_applicable"
                          name="mentors_average_hours_applicable"
                          onChange={handleOnCheck}
                          checked={incubatorDetails.mentors_average_hours_applicable ? true : false}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="mentors_average_hours_applicable"
                        >
                          Not Applicable
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* New Addition */}
                  <div className="form-row">
                    <div className="col">
                      <span className="col-label">
                        No. of patents registered by incubatees
                        {/* (patents, copyrights, designs, and trademarks) */}
                      </span>
                      <IncrementDecrement
                        name="no_of_patents"
                        value={!incubatorDetails.no_of_patents_applicable ?
                          incubatorDetails.no_of_patents :
                          1
                        }
                        onChange={(name: string, value: number) =>
                          handleIncrementDecrementChange(
                            "no_of_patents",
                            value
                          )
                        }
                        disabled={incubatorDetails.no_of_patents_applicable ? true : false}
                        minValue={1}
                      />
                      <div className="custom-control custom-checkbox mt-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="no_of_patents_applicable"
                          name="no_of_patents_applicable"
                          onChange={handleOnCheck}
                          checked={incubatorDetails.no_of_patents_applicable ? true : false}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="no_of_patents_applicable"
                        >
                          Not Applicable
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col">
                      <span className="col-label">
                        No. of copyrights registered by incubatees
                      </span>
                      <IncrementDecrement
                        name="no_of_copyrights"
                        value={!incubatorDetails.no_of_copyrights_applicable ?
                          incubatorDetails.no_of_copyrights :
                          1
                        }
                        onChange={(name: string, value: number) =>
                          handleIncrementDecrementChange(
                            "no_of_copyrights",
                            value
                          )
                        }
                        disabled={incubatorDetails.no_of_copyrights_applicable ? true : false}
                        minValue={1}
                      />
                      <div className="custom-control custom-checkbox mt-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="no_of_copyrights_applicable"
                          name="no_of_copyrights_applicable"
                          onChange={handleOnCheck}
                          checked={incubatorDetails.no_of_copyrights_applicable ? true : false}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="no_of_copyrights_applicable"
                        >
                          Not Applicable
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col">
                      <span className="col-label">
                        No. of trademarks registered by incubatees
                      </span>
                      <IncrementDecrement
                        name="no_of_trademarks"
                        value={!incubatorDetails.no_of_trademarks_applicable ?
                          incubatorDetails.no_of_trademarks :
                          1
                        }
                        onChange={(name: string, value: number) =>
                          handleIncrementDecrementChange(
                            "no_of_trademarks",
                            value
                          )
                        }
                        disabled={incubatorDetails.no_of_trademarks_applicable ? true : false}
                        minValue={1}
                      />
                      <div className="custom-control custom-checkbox mt-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="no_of_trademarks_applicable"
                          name="no_of_trademarks_applicable"
                          onChange={handleOnCheck}
                          checked={incubatorDetails.no_of_trademarks_applicable ? true : false}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="no_of_trademarks_applicable"
                        >
                          Not Applicable
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* New Additions */}

                </div>
              </div>

              <hr />

              <div className="form-group required row">
                <label className="col-sm-4 col-form-label">
                  Other support extended to incubatees in last three years
                </label>
                <div className="col-sm-8">
                  <div className="form-row">
                    <div className="col">
                      <span className="col-label">
                        Industry/Corporate connects facilitated
                      </span>
                      <IncrementDecrement
                        name="corporate_facilitated"
                        value={!incubatorDetails.corporate_facilitated_applicable ?
                          incubatorDetails.corporate_facilitated :
                          1
                        }
                        onChange={(name: string, value: number) =>
                          handleIncrementDecrementChange(
                            "corporate_facilitated",
                            value
                          )
                        }
                        disabled={incubatorDetails.corporate_facilitated_applicable ? true : false}
                        minValue={1}
                      />
                      <div className="custom-control custom-checkbox mt-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="corporate_facilitated_applicable"
                          name="corporate_facilitated_applicable"
                          onChange={handleOnCheck}
                          checked={incubatorDetails.corporate_facilitated_applicable ? true : false}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="corporate_facilitated_applicable"
                        >
                          Not Applicable
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col">
                      <span className="col-label">
                        Events held for stakeholder engagements
                      </span>
                      <IncrementDecrement
                        name="event_engagements"
                        value={!incubatorDetails.event_engagements_applicable ?
                          incubatorDetails.event_engagements :
                          1
                        }
                        onChange={(name: string, value: number) =>
                          handleIncrementDecrementChange(
                            "event_engagements",
                            value
                          )
                        }
                        disabled={incubatorDetails.event_engagements_applicable ? true : false}
                        minValue={1}
                      />
                      <div className="custom-control custom-checkbox mt-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="event_engagements_applicable"
                          name="event_engagements_applicable"
                          onChange={handleOnCheck}
                          checked={incubatorDetails.event_engagements_applicable ? true : false}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="event_engagements_applicable"
                        >
                          Not Applicable
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col">
                      <span className="col-label">
                        Participation in other events
                      </span>
                      <IncrementDecrement
                        name="participation_other_events"
                        value={!incubatorDetails.participation_other_events_applicable ?
                          incubatorDetails.participation_other_events :
                          1
                        }
                        onChange={(name: string, value: number) =>
                          handleIncrementDecrementChange(
                            "participation_other_events",
                            value
                          )
                        }
                        disabled={incubatorDetails.participation_other_events_applicable ? true : false}
                        minValue={1}
                      />
                      <div className="custom-control custom-checkbox mt-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="participation_other_events_applicable"
                          name="participation_other_events_applicable"
                          onChange={handleOnCheck}
                          checked={incubatorDetails.participation_other_events_applicable ? true : false}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="participation_other_events_applicable"
                        >
                          Not Applicable
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <fieldset disabled={disableForm}>
            <div className="stepper-footer-section">
              <div>
                <div
                  className="btn-toolbar justify-content-between"
                  role="toolbar"
                  aria-label="Toolbar with button groups"
                >
                  <button className="btn btn-sisfs btn-transparent-primary" onClick={() => applicationPage()}>
                    Cancel
                  </button>
                  <span className="stepper-page-count">Step 3 / 5</span>

                  <div className="btn-group">
                    <button
                      className="btn btn-sisfs btn-transparent-primary"
                      onClick={() => stepper && stepper.previous()}
                    >
                      Previous
                    </button>
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={onSave}
                    >
                      Save And Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        {/* Incubator Stepper Form 2 */}
      </fieldset>

      {/* fileUpload Limt Model         */}
      {showIncubatorFailureModal && (
        <div
          className="modal align-middle modal-sisfs d-block"
          id="FormSubmissionError"
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-body text-center">
                {/* <i className="fa fa-times-circle-o"></i> */}
                <h3 className="modal-message message-orange">
                  Oops!!!
                </h3>
                {/* <br /> */}
                <h3 className="modal-message message-orange">
                  Please Try Again !!!<br /><br />
                  Only 10 files can be uploaded once
                </h3>
                <div className="modal-action">
                  <button
                    className="btn btn-sisfs btn-yellow-primary"
                    onClick={dismissModal}
                  >
                    OK !
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Show More  */}
      <div
        className="modal fade"
        id="showMoreDocuments"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none ml-18px">
              <h5 className="modal-title " id="exampleModalLongTitle">
                {popUpName} <span className="eac-name-pop-up"></span>
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {/* <div className=""> */}
              {/* 1St Application Documents */}

              {/* General Details documents */}
              {popUpName == "Facilities Pictures" ? (
                incubatorDetails.facilities_pictures !=
                  undefined ? (
                  incubatorDetails.facilities_pictures.map(
                    (file: any, index: any) => (
                      <div className="row">
                        <div className="col-md-2">
                          {getFileDetails(file.file, "type") == "pdf" ? (
                            <img src={pdf} alt="SISFS Logo" />
                          ) : getFileDetails(file.file, "type") == "doc" ? (
                            <img src={doc} alt="SISFS Logo" />
                          ) : getFileDetails(file.file, "type") == "xls" ? (
                            <img src={xls} alt="SISFS Logo" />
                          ) : (
                            <img src={Allround} alt="SISFS Logo" />
                          )}
                        </div>
                        <div className="col-md-9 left-align">
                          <span className="document-label-value-wrap">
                            {getFileDetails(file.file, "name")}
                          </span>
                          <br />
                          <a
                            // href={file.file}
                            href={file.file}
                            target="_blank"
                            //download="annual_report"
                            className="incubator-details-link"
                          >
                            View
                          </a>
                          {/* /
                            <a
                              href="#"
                              //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <span className="incubator-label-value">-</span>
                )
              ) : (
                ""
              )}

              <div className="row">
                <div className="col-md-12 centerText">
                  <div className="btn-group mt-1-5 ">
                    <button
                      className="tertiary  btn-yellow-primary"
                      data-dismiss="modal"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupportDetailsComponent;
