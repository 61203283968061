import React from 'react';

const ProgressBar = ({ numerator, denominator }) => {
  const progress = (numerator / denominator) * 100; // Calculate the progress percentage

  const progressBarStyle = {
    width: `${progress}%`,
    height: '100%',
    backgroundColor: '#68e568', // Customize the progress bar color
    borderRadius: '20px', // Adjust the border radius as needed
  };

  const textContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',

  };

  const textStyle = {
    color: '#000000', // Customize the text color
    fontWeight: 'bold',
    position: 'absolute',
    fontSize:'10px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  return (
    <div style={{ width: '100%', height: '15px', backgroundColor: '#f0f0f0', position: 'relative',borderRadius: '20px', }}>
      <div style={progressBarStyle}></div>
      <div style={textContainerStyle}>
        <span style={textStyle}>{`${numerator}/${denominator}`}</span>
      </div>
    </div>
  );
};

export default ProgressBar;
