import React, { useEffect, useState } from "react";
import {
  GET_APPLICATION_LIST,
  GET_Re_APPLICATION_LIST,
  GET_APPLICATION_VIEW,
  REPLACE_FILE,
  DELETE_APPLICATION,
  EVALUATION_FILE,
  CANCELLATION_REQUEST,
  UPLOAD_DISBURSEMENT_PROOF,
  FILE_UPLOAD,
  GET_APPLICATION_LIST_NODE,
  LEGAL_RE_ENTITY,
  GET_REAPPLY_APPLICATION,
  SPOC_DETAILS,
  INC_SEND_TOKEN_TO,
} from "../../../../constants/api";
import { useLocation } from "react-router-dom";
import { get, post } from "../../../../effects/api/api.common";
import ContactSectionComponent from "./ContactSectionComponent";
import GetApplicationInfoComponent from "./GetApplicationInfoComponenet";
import { Link, NavLink, useHistory } from "react-router-dom";
import { FormatDate } from "../../../../utils/DateFormat";
import {
  changeNumberFormat,
  downloadDocument,
  showEllipsisText,
} from "../../../../utils/CommonMethods";
import FileUploader from "@amodv/react-file-uploader";
import ConfirmationDialog from "../../../common/controls/ConfirmationDialog";
import {
  getSessionStorageValue,
  setSessionStorageValue,
} from "../../../../effects/utils/session-storage";
// import '../applynow.css';
import ApplicationStatusApply from "./ApplicationStatusApply";
import ContactDetails from "./ContactDetails";
import UserInfoModel from "../../../../models/UserInfoModel";

declare var $: any;
interface IIKeyValueIndexer {
  [key: string]: boolean;
}

/** Render Application Status for Incubator screen */
function ApplicationStatusComponent(props: any, props1: any) {
  const history = useHistory();
  const [applicationListData, setApplicationListData] = useState([]);
  const [TotalAmountApproved, setTotalAmountApproved] = useState("");
  const [ReapplyApplicationListData, setReapplyApplicationListData] = useState(
    []
  );
  const location = useLocation();
  const [applicationDataCount1, setApplicationDataCount1] = useState(0);
  const [applicationID, setApplicationID] = useState("");
  const [uploadFile, setUploadFile] = useState<Array<File>>([]);
  const [showIncubatorSuccessModal, setShowIncubatorSuccessModal] =
    useState(false);
  const [showIncubatorFailureModal, setShowIncubatorFailureModal] =
    useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [other_file, setOtherFile] = useState<Array<File>>([]);
  const [loader, setLoader] = useState(false);
  const [failDialogData, setFailDialogData] = useState({
    header: "",
    text: "",
  });

  const [dialogData, setDialogData] = useState({
    header: "",
    text: "",
  });
  const [cancellationData, setCancellationData] = useState({
    reason: "",
    amount_refunded: "",
    interest_funds: "",
  });
  const [applicationData, setApplicationData] = useState([]);
  const [applicationDataCount, setApplicationDataCount] = useState(0);
  const [ReapplyapplicationDataCount, setReapplyApplicationDataCount] =
    useState(0);
  const [TotalAmountremaining, setTotalAmountremaining] = useState(0);
  const [showEditFailureModal, setShowEditFailureModal] = useState(false);
  const [showEditSuccessModal, setShowEditSuccessModal] = useState(false);
  // const [openUploadBlock, setOpenUploadBlock] = useState(false);
  const [openUploadBlock, setOpenUploadBlock] = useState<IIKeyValueIndexer>();

  const [PPTPath, setPPTPath] = useState("");
  const [showViewMore, setShowViewMore] = useState("");

  const [sumApprovedAmount, setSumApprovedAmount] = useState<any>("");
  const [totalFundsReleased, setTotalFundsReleased] = useState<any>("");
  const [totalRemainingAmnt, setTotalRemainingAmnt] = useState<any>("");
  const [allowReapply, setAllowReapply] = useState(false);
  const [IncubatorModal, setIncubatorModal] = useState(false);
  useEffect(() => {
    // //console.log(props,props1,"props1")
    if (
      typeof props.location.state !== "undefined" &&
      props.location.state != null
    ) {
      if (props.location.state.perform_reload == "true") {
        if (!localStorage.justOnce) {
          localStorage.setItem("justOnce", "true");
          window.location.reload();
        }
      }
    } else {
      localStorage.removeItem("justOnce");
    }
    getReapplyApplication();
    getApplicationList();
    // ReapplygetApplicationList();
    // getPPTPath();
    // getActiveApplication();
    window.scrollTo(0, 0);

    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    const userId = userInfo.id;
    //
    post(GET_Re_APPLICATION_LIST, { logged_user_id: userId }).then(
      (response: any) => {
        // if (!response || !response.data) return;
        setApplicationDataCount1(response.data.data.length);
        // //console.log(applicationDataCount1, "count")
      }
    );
  }, []);

  const getActiveApplication = (id: any) => {
    history.push({
      pathname: "/incubator",
      state: { application_id: id, disabled: true },
    });
  };
  const dismissModal = () => {
    setShowEditFailureModal(false);
    setShowEditSuccessModal(false);
    setShowIncubatorFailureModal(false);
    setShowIncubatorSuccessModal(false);
    window.location.reload();
  };

  const ReapplygetApplicationList = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    const userId = userInfo.id;
    const UserRole = userInfo.roles;
    const requestPayload = {
      logged_user_id: userId,
      user_role: UserRole,
    };
    post(GET_APPLICATION_LIST_NODE, requestPayload).then((response: any) => {
      if (!response || !response.data) return;
      if (response.data.length != 0) {
        setReapplyApplicationDataCount(response.data.data.length);
        const applicationList = response.data.data.map((item: any) => ({
          application_id: item.application_id,
          incubator_application_no: item.incubator_application_no,
          registered_name: item.registered_name,
          updated_at: item.last_updated,
          cancellation_requested: item.cancellation_requested,
          cancellation_status: item.cancellation_status,
          submitted_at:
            item.first_submitted_date != null
              ? item.first_submitted_date
              : item.submitted_at,
          label: item.status,
          dpiit_comments: item.dpiit_comments,
          Upload_pitchdeck: item.Upload_pitchdeck,
          file_location: item.file_location,
          file_id: item.file_id,
          comments_data: item.comments_data,
          comment_updated_at: item.comment_updated_at,
          date_of_evaluation: item.date_of_evaluation,
          status_created_at: item.status_created_at,
          total_approved_amt: item.total_approved_amt,
          no_of_tranches: item.no_of_tranches == null ? 0 : item.no_of_tranches,
        }));
        if (response.data?.data[0]?.status === "Selected") {
          re_setStatusLabel(response.data.data[0].status);
        }
        setReapplyApplicationListData(applicationList);
      } else {
      }
      getPPTPath();
    });
  };
  const getReapplyApplication = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    var payload = {
      logged_user_id: userInfo.id,
    };

    post(GET_REAPPLY_APPLICATION, payload).then((response: any) => {
      if (!response.data || !response.data.data) {
        return;
      }
      setAllowReapply(response.data.data.allow);
    });
  };
  const [draftStatusLabel, setDraftStatusLabel] = useState<any>("");
  const getApplicationList = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    var payload = {
      logged_user_id: userInfo.id,
      roles: userInfo.roles,
    };
    setLoader(true);
    post(GET_APPLICATION_LIST, payload).then((response: any) => {
      setLoader(false);
      if (response.data.data[0].status === "Draft") {
        setDraftStatusLabel(response.data.data[0].status);
        // console.log(draftStatusLabel)
      } else if (response.data.data[0].status === "Selected") {
        setStatusLabel(response.data.data[0].status);
      } else {
        setLabelStatus(response.data.data[0].status);
      }
      if (!response || !response.data) return;
      if (response.data.length != 0) {
        // console.log(response,"totalFunds")
        // console.log(response.data[0].sumOfApprovedAmt,"totalFunds")
        // console.log(response.data[0].sumOfFundReleased,"totalFunds")

        setSumApprovedAmount(response.data.data[0].total_approved_amt);
        setTotalFundsReleased(response.data.data[0].sumOfFundReleased);

        setTotalRemainingAmnt(response.data.data[0].totalRemainingAmount);

        //console.log(sumApprovedAmount)
        //console.log(totalFundsReleased)
        // console.log(response,"applic")
        setApplicationDataCount(response.data.data.length);
        setTotalAmountremaining(response.data.data[0].total_amount_remaining);
        const applicationList = response.data.data.map((item: any) => ({
          application_id: item.application_id,
          incubator_application_no: item.incubator_application_no,
          registered_name: item.registered_name,
          updated_at: item.last_updated,
          cancellation_requested: item.cancellation_requested,
          cancellation_status: item.cancellation_status,
          submitted_at:
            item.first_submitted_date != null
              ? item.first_submitted_date
              : item.submitted_at,
          label: item.status,
          dpiit_comments: item.dpiit_comments,
          Upload_pitchdeck: item.Upload_pitchdeck,
          file_location: item.file_location,
          file_id: item.file_id,
          comments_data: item.comments_data,
          comment_updated_at: item.comment_updated_at,
          date_of_evaluation: item.date_of_evaluation,
          status_created_at: item.status_created_at,
          total_approved_amt: item.total_approved_amt,
          no_of_tranches: item.no_of_tranches == null ? 0 : item.no_of_tranches,
        }));
        setApplicationListData(applicationList);
        applicationListData.map((res: any) => {
          if (res.label == "Draft") {
            setDraftStatusLabel(res.label);
            // console.log(draftStatusLabel)
          }
        });
      } else {
      }
      ReapplygetApplicationList();
    });
  };
  useEffect(() => {
    const currentPath = location.pathname;
    const startIndex =
      currentPath.indexOf("incubatorApplications/") +
      "incubatorApplications/".length;
    const token = currentPath.slice(startIndex);
    if (token) {
      var payload = {
        access_token: token,
      };
      console.log(payload);
      post(INC_SEND_TOKEN_TO, payload).then((response) => {
        console.log(response.data.message);
        if (response.data.message === "Invalid Token") {
          history.push("");
          alert("Invalid Token");
          return;
        }
        const access_token: string = response.data.access_token;
        const user = response.data.user;
        localStorage.removeItem("incubatorName");
        if (access_token) {
          const userInfo: UserInfoModel = new UserInfoModel(
            user?.name,
            user?.email,
            user?.id,
            user?.roles,
            access_token
          );
          setSessionStorageValue("user", JSON.stringify(userInfo));

          if (userInfo.roles === "incubator") {
            var payload2 = {
              logged_user_id: userInfo.id,
              roles: userInfo.roles,
              get_list: "incubator_prefrences",
            };

            post(GET_APPLICATION_LIST, payload2).then((response: any) => {
              // setLoader(false);
              if (!response || !response.data) return;
              if (
                response.data.registeredName.registered_name !== undefined &&
                response.data.registeredName.registered_name !== null
              ) {
                localStorage.setItem(
                  "incubatorName",
                  response.data.registeredName.registered_name
                );
              }
              if (response.data.length == 0) {
                setSessionStorageValue("hide", "hide_flag");
                history.push("/incubator_apply");
                window.location.reload();
              } else {
                history.push("/incubator_status");
                window.location.reload();
              }
            });
          }
        }
      });
    }
  }, []);
  const continueEditing = (id: any,item:any) => {
    setIncubatorModal(true);
    console.log(item)
    if(item.label == "Marked Incomplete"){
      history.push({
        pathname: "/incubator",
        state: { application_id: id, continue_editing: 1 },
      });
      return;
    }
    return;
    history.push({
      pathname: "/incubator",
      state: { application_id: id, continue_editing: 1 },
    });
  };

  const deleteApplication = (id: any) => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);

    post(DELETE_APPLICATION, { application_id: id, userId: userInfo.id }).then(
      (response: any) => {
        if (!response || !response.data) return;
        if (response.data.result == "success") {
          getApplicationList();
          setShowEditSuccessModal(true);
        } else {
          setShowEditFailureModal(true);
        }
      }
    );
  };

  // const downloadDocument = (file: any, fileName: any) => {
  //   fetch(file).then((response) => {
  //     response.blob().then((blob) => {
  //       let url = window.URL.createObjectURL(blob);
  //       let a = document.createElement('a');
  //       a.href = url;
  //       a.download = "PitchDeck_" + fileName;
  //       a.click();
  //     });
  //   });
  // };

  const onChange = (e: any, applicationID: any, fileID: any) => {
    const fileData = e.target.files[0];
    // //console.log("filejsdf", fileID)
    if (fileID == "null") {
      const user: any = getSessionStorageValue("user");
      const userInfo = JSON.parse(user);
      const formData = new FormData();
      // Update the formData object
      formData.append("file", fileData);
      formData.append("application_id", applicationID);
      formData.append("file_type", "relavent_document_pitchdeck");
      formData.append("logged_user_id", userInfo.id);
      post(FILE_UPLOAD, formData).then((response: any) => {
        if (!response || !response.data) return;
        if (response.data.result == "success") {
          setDialogData({
            header: "Thank You!",
            text: response.data.message,
          });
          setShowIncubatorSuccessModal(true);
        } else {
          setFailDialogData({
            header: "Oops !!",
            text: response.data.message,
          });
          setShowIncubatorFailureModal(true);
        }
      });
    } else {
      const user: any = getSessionStorageValue("user");
      const userInfo = JSON.parse(user);
      let formData1 = new FormData();
      formData1.append("application_id", applicationID);
      formData1.append("file_id", fileID);
      formData1.append("file", fileData);
      formData1.append("logged_user_id", userInfo.id);
      post(REPLACE_FILE, formData1).then((response: any) => {
        if (!response || !response.data) return;
        if (response.data.result == "success") {
          setDialogData({
            header: "Thank You!",
            text: response.data.message,
          });
          setShowIncubatorSuccessModal(true);
        } else {
          setFailDialogData({
            header: "Oops !!",
            text: response.data.message,
          });
          setShowIncubatorFailureModal(true);
        }
      });
    }
  };

  const onChangedDisbursementProof = (e: any, applicationID: any) => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);

    const fileData = e.target.files[0];
    let formData = new FormData();
    formData.append("application_id", applicationID);
    formData.append("file", fileData);
    formData.append("user_Role", userInfo.roles);
    formData.append("logged_user_id", userInfo.id);
    post(UPLOAD_DISBURSEMENT_PROOF, formData).then((response: any) => {
      if (!response || !response.data) return;
      if (response.data.result == "success") {
        setDialogData({
          header: "Thank You!",
          text: response.data.message,
        });
        setShowIncubatorSuccessModal(true);
      } else {
        setFailDialogData({
          header: "Oops !!",
          text: response.data.message,
        });
        setShowIncubatorFailureModal(true);
      }
    });
  };

  const getPPTPath = () => {
    get(EVALUATION_FILE).then((response: any) => {
      if (!response.data) return;
      setPPTPath(response.data.eacDeckPath);
    });
  };

  const onFileUploadComplete = (files: Array<File>, type: string) => {
    // //console.log("testing", files)
    let indexes: Array<string> = [];
    let updatedUploadDocuments = Object.assign({}, cancellationData);
    for (const file of files) {
      switch (type) {
        case "file":
          setUploadFile([...uploadFile, file]);
          break;
        case "other_file":
          setOtherFile([...other_file, file]);
          break;
      }
    }

    if (indexes.length) {
      indexes.map((ind) => {
        const position: number = files.findIndex((file) => file.name === ind);
        files.splice(position, 1);
      });
    }
  };

  const onFileDelete = (type: string) => {
    switch (type) {
      case "file":
        setUploadFile([]);
        break;
      case "other_file":
        setOtherFile([]);
        break;
    }
  };

  const openCancellationPopup = (id: any) => {
    setApplicationID(id);
    setUploadFile([]);
    setOtherFile([]);
    setCancellationData({
      reason: "",
      amount_refunded: "",
      interest_funds: "",
    });
  };

  const submitCancellationRequest = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);

    const formData = new FormData();

    formData.append("logged_user_id", userInfo.id);
    formData.append("application_id", applicationID);
    formData.append(
      "reason",
      cancellationData.reason ? cancellationData.reason : "null"
    );
    formData.append(
      "amount_refunded",
      cancellationData.amount_refunded
        ? cancellationData.amount_refunded
        : "null"
    );
    formData.append(
      "interest_funds",
      cancellationData.interest_funds ? cancellationData.interest_funds : "null"
    );
    uploadFile.length != 0 && formData.append("file", uploadFile[0]);
    other_file.length != 0 && formData.append("other_file", other_file[0]);
    // const payload = {
    //   application_id: applicationID,
    //   reason: cancellationData.reason,
    //   amount_refund: cancellationData.amount_refunded,
    //   interest_funds: cancellationData.interest_funds,
    //   file: typeof uploadFile[0] !="undefined"?uploadFile[0]:null,
    //   other_file:typeof other_file[0] !="undefined"?other_file[0]:null
    // }
    setDisabled(true);
    post(CANCELLATION_REQUEST, formData).then((response: any) => {
      if (!response || !response.data) return;
      setDisabled(false);
      if (response.data.result == "success") {
        setDialogData({
          header: "Thank You!",
          text: response.data.message,
        });
        setShowIncubatorSuccessModal(true);
      } else {
        setFailDialogData({
          header: "Oops !!",
          text: response.data.message,
        });
        setShowIncubatorFailureModal(true);
      }
    });
  };
  const [statusLabel, setStatusLabel] = useState<any>("");
  const [re_statusLabel, re_setStatusLabel] = useState<any>("");
  const [draftStatus, setDraftStatus] = useState<any>("");
  const [labelStatus, setLabelStatus] = useState<any>("");

  const HandleChange = () => {
    setIncubatorModal(true);
    return;
    if (ReapplyapplicationDataCount >= 1 && allowReapply == false) {
      $("#SecondApplicationModalCenter").modal("show");
      //You already have applied for the second Application please continue
    } else if (ReapplyapplicationDataCount >= 1 && allowReapply == true) {
      $("#sameLogic").modal("show");
      // yes or no
    } else if (
      statusLabel &&
      sumApprovedAmount > 0 &&
      totalRemainingAmnt > 0 &&
      (sumApprovedAmount == totalRemainingAmnt ||
        sumApprovedAmount > totalRemainingAmnt ||
        sumApprovedAmount < totalRemainingAmnt)
    ) {
      $("#FirstApplicationModalCenter").modal("show");
      // approve now
    } else if (
      statusLabel &&
      sumApprovedAmount > 0 &&
      sumApprovedAmount != null &&
      totalRemainingAmnt <= 0
    ) {
      $("#sameLogic").modal("show");
      // yes or no
    } else if (
      statusLabel &&
      (sumApprovedAmount == 0 ||
        sumApprovedAmount == null ||
        totalRemainingAmnt == 0 ||
        totalRemainingAmnt == null)
    ) {
      $("#FirstApplicationModal").modal("show");
      // you have no funds approved
    } else if (labelStatus) {
      $("#ApplicationModalCenter").modal("show");
      // Continue with existing Application
    }
    // console.log(sumApprovedAmount, totalRemainingAmnt ,"dddddd")
  };

  const yesClick = () => {
    history.push({
      pathname: "re-apply",
      state: {
        application_id: "",
        disabled: true,
        incubator_application_no: "",
      },
    });
    window.location.reload();
  };

  const approveNow = () => {
    history.push({
      pathname: "/incubator_startup_list",
      state: {
        application_id: "",
        disabled: true,
        incubator_application_no: "",
      },
    });
    window.location.reload();
  };
  const noClick = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    const userId = userInfo.id;
    const payload = {
      logged_user_id: userId,
      same_legal_entity: "No",
    };

    post(LEGAL_RE_ENTITY, payload).then((response: any) => {
      if (!response || !response.data) return;
      if (response.data.result == "success") {
        //console.log(response.data.result)
        window.location.reload();

        // $('#sameLogic').modal('hide');
        // $('#sameLogic').appendTo("body")
        //   setDialogData({
        //     "header": "Thank You!",
        //     "text": response.data.message
        //   })
        //   setShowIncubatorSuccessModal(true)

        // }else{
        //   setFailDialogData({
        //     "header": "Oops !!",
        //     "text": response.data.message
        //   })
        //   setShowIncubatorFailureModal(true);
      }
    });
  };
  // const getActiveApplication = (id: any) => {
  //   history.push({
  //     pathname: "/incubator",
  //     state: { application_id: id, disabled: true },
  //   });
  // };
  //
  const onUpdateSpoc = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    const userId = userInfo.id;
    const payload = {
      incubator_id: userId,
    };
    post(SPOC_DETAILS, payload).then((response: any) => {
      console.log(response);
    });
  };
  return (
    <>
      {loader && (
        <div className="spinner-border custom-spinner" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}

      <div className="app-inside app-apply-incubator">
        <div className="status-list-wrapper mt-4">
          <div className="container">
            <div className="app-inside-content">
              <div
                className="d-flex px-2"
                style={{ justifyContent: "space-between" }}
              >
                <div>
                  <p className="dashboard_Application">
                    My Applications (
                    {applicationDataCount + ReapplyapplicationDataCount})
                  </p>
                </div>
                {(statusLabel || re_statusLabel) && (
                  <div>
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      data-toggle="modal"
                      data-target="#contactPersons"
                      onClick={onUpdateSpoc}
                    >
                      Update Spoc Details
                    </button>
                  </div>
                )}
              </div>

              {applicationDataCount == 0 ? (
                <>
                  <div className="d-flex flex-row justify-content-between status-list">
                    <div className="text-center m-auto">
                      <h5>No Applications were Created</h5>
                    </div>
                  </div>
                </>
              ) : draftStatusLabel ? (
                <></>
              ) : (
                <>
                  <div className="d-flex flex-row justify-content-between status-list">
                    <div className="row">
                      <div className="col-md-9">
                        <small
                          style={{
                            marginTop: "10px",
                            fontSize: "17px",
                            fontWeight: "500",
                          }}
                        >
                          An Incubator is eligible to re-apply when the
                          previously approved corpus to the incubator is fully
                          committed/disbursed to its startups
                        </small>
                      </div>
                      <div
                        className="col-md-3"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "end",
                        }}
                      >
                        <button
                          className="btn btn-sisfs btn-yellow-primary"
                          data-toggle="collapse"
                          onClick={() => HandleChange()}
                        >
                          ReApply
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <ApplicationStatusApply
                ApplicationListData1={ReapplyApplicationListData}
              />
              {/* <NavLink  className="btn btn-sisfs btn-transparent-primary mx-1 Link_txt1" onClick={()=>HandleChange()}>Re Apply Now</NavLink> */}
              {/* to="/incubator" */}
              {/* <button
                    className="btn btn-sisfs btn-transparent-primary mx-4"
                    data-toggle="collapse" disabled
                  >
                    Re Apply Now
                  </button> */}

              {/* </div> */}
              {/* </div> */}

              <div className="row justify-content-center">
                <div className="col-xs-12 col-sm-12">
                  {applicationListData.map((item: any, index: any) => (
                    <>
                      {/* {console.log(item,"kjjds")} */}
                      <div className="status-list" key={index}>
                        <div className="row mb-3">
                          <div className="col-2">
                            <h4>
                              Application{" "}
                              <small>
                                (
                                {item.label == "Draft"
                                  ? "In Progress"
                                  : "#" +
                                    (item.incubator_application_no === null ||
                                    ""
                                      ? item.application_id
                                      : item.incubator_application_no)}
                                )
                              </small>
                            </h4>
                            {item.cancellation_requested == "Yes" && (
                              <>
                                <small>{item.label}</small>
                              </>
                            )}
                          </div>
                          <div className="col-3 nopadding">
                            {item.cancellation_requested == "No" ? (
                              (item.label == "Draft" && (
                                <span className="status-badge blue-badge">
                                  <b>{item.label}</b>
                                </span>
                              )) ||
                              (item.label == "Marked Incomplete" && (
                                <span className="status-badge yellow-badge">
                                  <b>Incomplete</b>
                                </span>
                              )) ||
                              (item.label == "Submitted" && (
                                <span className="status-badge blue-badge">
                                  <b>Submitted</b>
                                </span>
                              )) ||
                              (item.label == "On Hold" && (
                                <span className="status-badge red-badge">
                                  <b>{item.label}</b>
                                </span>
                              )) ||
                              (item.label == "Closed" && (
                                <span className="status-badge grey-badge">
                                  <b>{item.label}</b>
                                </span>
                              )) ||
                              (item.label == "Cancelled" && (
                                <span className="status-badge grey-badge">
                                  <b>{item.label}</b>
                                </span>
                              )) ||
                              (item.label == "Pending Review" && (
                                <span className="status-badge yellow-badge">
                                  <b>{item.label}</b>
                                </span>
                              )) ||
                              (item.label == "Rejected" && (
                                <span className="status-badge red-badge">
                                  <b>{item.label}</b>
                                </span>
                              )) ||
                              (item.label == "Selected" && (
                                <span className="status-badge blue-badge">
                                  <b>{item.label}</b>
                                </span>
                              ))
                            ) : item.label == "Selected" ? (
                              <span className="status-badge blue-badge small-font-badge">
                                {item.cancellation_status}
                              </span>
                            ) : (
                              <span className="status-badge grey-badge">
                                <b>{item.label}</b>
                              </span>
                            )}
                          </div>
                          <div className="col-2 nopadding">
                            {item.label == "Selected" &&
                              item.cancellation_requested == "No" && (
                                <label
                                  className="link-orange-text link-underline nopadding"
                                  data-toggle="modal"
                                  onClick={() =>
                                    openCancellationPopup(item.application_id)
                                  }
                                  data-target="#cancellationPopup"
                                >
                                  Request Cancellation
                                </label>
                              )}
                          </div>
                          <div className="col-5 text-right nopadding pr-2">
                            <div className="btn-group header-action-section">
                              {/* {item.label != "Marked Incomplete" ||
                          item.label != "Draft" ? (
                          <button
                            className="btn btn-sisfs btn-transparent-primary"
                            onClick={() =>
                              getActiveApplication(item.application_id)
                            }
                          >
                            View
                          </button>
                          ) : null} */}

                              {/* {(item.label == "Selected" && item.cancellation_requested == "Yes" && item.cancellation_status == "Cancellation Approved") && <label htmlFor={"inputDisbursementUpload_" + item.application_id} className="link-orange-text link-underline mr-4 mt-2"><input id={"inputDisbursementUpload_" + item.application_id} className="d-none" type="file" onChange={(e) =>
                                onChangedDisbursementProof(e, `${item.application_id}`)
                              } />Upload Disbursement Proof</label>} */}
                              {(item.label == "Rejected" ||
                                item.label == "On Hold" ||
                                item.label == "Selected" ||
                                item.label == "Pending Review") &&
                                item.dpiit_comments != null && (
                                  <button
                                    className="btn btn-sisfs btn-transparent-primary"
                                    data-toggle="collapse"
                                    data-target={`#status_comment_${item.application_id}`}
                                  >
                                    View Details
                                  </button>
                                )}
                              {item.label == "Marked Incomplete" ||
                              item.label == "Draft" ? (
                                <>
                                  {item.label == "Marked Incomplete" &&
                                    item.comments_data.length != 0 && (
                                      <button
                                        className="btn btn-sisfs btn-transparent-primary"
                                        data-toggle="collapse"
                                        data-target={`#status_comment_${item.application_id}`}
                                      >
                                        View Details
                                      </button>
                                    )}
                                  {item.label == "Draft" ? (
                                    <button
                                      className="btn btn-sisfs btn-transparent-primary"
                                      onClick={() =>
                                        deleteApplication(item.application_id)
                                      }
                                    >
                                      Delete
                                    </button>
                                  ) : null}
                                  <button
                                    className="btn btn-sisfs btn-yellow-primary"
                                    onClick={() =>
                                      continueEditing(item.application_id,item)
                                    }
                                  >
                                    Continue Editing
                                  </button>
                                </>
                              ) : (
                                <button
                                  className="btn btn-sisfs btn-transparent-primary"
                                  onClick={() =>
                                    getActiveApplication(item.application_id)
                                  }
                                >
                                  View Application
                                </button>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-2">
                            {item.label == "Draft" ? (
                              <>
                                <span className="d-block mb-2">
                                  Last Updated
                                </span>
                                <span>
                                  <b>{FormatDate(item.updated_at)}</b>
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="d-block mb-2">Submitted</span>
                                <span>
                                  <b>{FormatDate(item.submitted_at)}</b>
                                </span>
                              </>
                            )}
                          </div>
                          {item.label != "Rejected" && item.label != "Draft" ? (
                            <>
                              {item.date_of_evaluation != null ? (
                                <div className="col-2">
                                  <>
                                    <span className="d-block mb-2">
                                      EAC Application Evaluation
                                    </span>
                                    <span>
                                      <b>
                                        {FormatDate(item.date_of_evaluation)}
                                      </b>
                                    </span>
                                  </>
                                </div>
                              ) : null}
                              <div className="col-3">
                                {item.cancellation_requested == "No" ? (
                                  <>
                                    <span className="d-block mb-2">
                                      Application Completeness Checked On
                                    </span>
                                    <span>
                                      <b>
                                        {item.status_created_at != null
                                          ? FormatDate(item.status_created_at)
                                          : "In Progress"}
                                      </b>
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <span className="d-block mb-2">
                                      Funds Approved
                                    </span>
                                    <span>
                                      <b>
                                        {changeNumberFormat(
                                          Number(item.total_approved_amt),
                                          1,
                                          0
                                        ) +
                                          " (" +
                                          item.no_of_tranches +
                                          " Tranches)"}
                                      </b>
                                    </span>
                                  </>
                                )}
                              </div>
                            </>
                          ) : null}
                          <div
                            className={
                              item.date_of_evaluation == null
                                ? "col-7 text-right pr-3"
                                : "col-5"
                            }
                          >
                            {/* <label className="link-orange-text link-underline" onClick={() =>
                              downloadDocument(PPTPath, 'eac_deck')
                            } >Download EAC Deck</label> */}

                            {/* upload disbursement start */}
                            {/* {(item.label == "Selected" && item.cancellation_requested == "Yes" && item.cancellation_status == "Cancellation Approved") && <label htmlFor={"inputDisbursementUpload_" + item.application_id} className="link-orange-text link-underline mt-2"><input id={"inputDisbursementUpload_" + item.application_id} className="d-none" type="file" onChange={(e) =>
                              onChangedDisbursementProof(e, `${item.application_id}`)
                            } />Upload Disbursement Proof</label>}
                            {(item.Upload_pitchdeck == "Yes" && item.cancellation_requested == "No") ? <><label className="comment-heading">EAC Performance Evaluation Deck</label><br />
                              <label className="link-orange-text" onClick={() =>
                                downloadDocument(PPTPath, 'eac_deck')
                              } >Download EAC Deck Format</label> | <label htmlFor={"inputUpload_" + item.application_id} className="link-orange-text"><input id={"inputUpload_" + item.application_id} className="d-none" type="file" onChange={(e) =>
                                onChange(e, `${item.application_id}`, `${item.file_id}`)
                              } />Upload Deck</label></> : null} */}
                            {/* upload disbursement end */}
                          </div>
                        </div>
                      </div>
                      <div
                        className="collapse status-collapse"
                        id={`status_comment_${item.application_id}`}
                      >
                        <div className="row">
                          <div className="col-5">
                            <label className="comment-heading">
                              DPIIT Comments/Feedback
                            </label>
                            <br />
                            {item.comment_updated_at != null && (
                              <label className="comment-date">
                                {FormatDate(item.comment_updated_at)}
                              </label>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          {/* <div className="col-7"> */}

                          {/* <div className="comment-content">{item.dpiit_comments}</div> */}

                          {/* </div> */}
                          {/* <div className="col-5 text-right pr-3"> */}
                          {/* {item.Upload_pitchdeck == "Yes" ? <><label className="comment-heading">EAC Performance Evaluation Deck</label>
                              <label className="link-orange-text" onClick={() =>
                                downloadDocument(`${item.file_location}`, `PitchDeck_${item.application_id}`)
                              } >Download Deck Format</label> | <label htmlFor={"inputUpload_" + item.application_id} className="link-orange-text"><input id={"inputUpload_" + item.application_id} className="d-none" type="file" onChange={(e) =>
                                onChange(e, `${item.application_id}`, `${item.file_id}`)
                              } />Upload Deck</label></> : null} */}
                          {/* </div> */}
                          {/* {console.log(item,"iteemmm")} */}
                          <table className="reviewTable contactUsTable statusTable">
                            <thead>
                              <tr>
                                <th className="table-heading">
                                  Application Status
                                </th>
                                <th className="table-heading">Date</th>
                                <th className="table-heading">Comments</th>
                              </tr>
                            </thead>
                            <tbody>
                              {item.comments_data == null ||
                              undefined ||
                              item.comments_data.length == 0 ? (
                                <tr>
                                  <td className="text-center" colSpan={3}>
                                    No data
                                  </td>
                                </tr>
                              ) : (
                                item.comments_data.map(
                                  (item: any, index: any) => (
                                    <tr key={index}>
                                      <td className="text-left custom-td">
                                        {item.application_status}
                                      </td>
                                      <td className="text-left custom-td">
                                        {FormatDate(item.created_at)}
                                      </td>
                                      {item.dpiit_status_comment != null ? (
                                        <td className="text-left custom-td">
                                          {showViewMore != "show_" + index && (
                                            <>
                                              <div>
                                                {showEllipsisText(
                                                  item.dpiit_status_comment,
                                                  50
                                                )}
                                              </div>
                                              {item.dpiit_status_comment
                                                .length >= 50 && (
                                                <span
                                                  className="link-orange-text d-block"
                                                  onClick={() =>
                                                    setShowViewMore(
                                                      "show_" + index
                                                    )
                                                  }
                                                >
                                                  View More
                                                </span>
                                              )}
                                            </>
                                          )}
                                          {showViewMore == "show_" + index && (
                                            <>
                                              <div>
                                                {item.dpiit_status_comment}
                                              </div>
                                              <span
                                                className="link-orange-text d-block"
                                                onClick={() =>
                                                  setShowViewMore("")
                                                }
                                              >
                                                View Less
                                              </span>
                                            </>
                                          )}
                                        </td>
                                      ) : (
                                        <td className="text-left custom-td">
                                          -
                                        </td>
                                      )}
                                    </tr>
                                  )
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  ))}

                  {/* <div className="status-list d-flex">
                                        <div className="status">
                                            <h4 className="d-block">Application <small>(in progress)</small></h4>

                                            <div className="row">
                                                <div className="col">
                                                    <span className="d-block">Last Updated</span>
                                                    <span><b>5 Mar 2021</b></span>
                                                </div>

                                                <div className="col">
                                                    <span className="d-block">Status</span>
                                                    <span><b>Saved As Draft</b></span>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="ml-auto mt-4">

                                            <div className="btn-group header-action-section">
                                                <button className="btn btn-sisfs btn-transparent-primary">Delete</button>
                                                <button className="btn btn-sisfs btn-yellow-primary">Continue Editing</button>
                                            </div>

                                        </div>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>

          {/* cancellation popup */}
          <div
            className="modal fade"
            id="cancellationPopup"
            role="dialog"
            aria-labelledby="exampleModalLabel1"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-slideout modal-md"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header modal-header-bottom pr-5">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body pl-5 pr-5 pt-4">
                  <div className="popup-head mb-4 ml-3">
                    <div className="main-heading">Request Cancellation</div>
                  </div>

                  <label className="col-sm-12 col-form-head">
                    Reason for Cancellation
                  </label>
                  <div className="col-md-12 mb-4">
                    <textarea
                      className="form-control"
                      rows={3}
                      placeholder="Describe in Max. 1000 characters"
                      name="reason"
                      value={cancellationData.reason}
                      required={true}
                      maxLength={1000}
                      onChange={(e: any) => {
                        setCancellationData((prevState) => ({
                          ...prevState,
                          reason: e.target.value,
                        }));
                      }}
                    ></textarea>
                  </div>
                  <div className="col-md-12 mb-4">
                    <label className="col-form-head">
                      Amount to be Refunded
                    </label>
                    <input
                      value={cancellationData.amount_refunded}
                      placeholder="Enter Amount (&#8377;)"
                      name="amount_refunded"
                      onChange={(e: any) => {
                        setCancellationData((prevState) => ({
                          ...prevState,
                          amount_refunded: e.target.value
                            .replace(/[^0-9.]/gi, "")
                            .toString()
                            .split(".")
                            .map((el: any, i: any) =>
                              i ? el.split("").slice(0, 2).join("") : el
                            )
                            .join("."),
                        }));
                      }}
                      className="form-control input-height"
                    />
                  </div>

                  <div className="col-md-12 mb-5">
                    <label className="col-form-head">
                      Interest Accumulated on Funds
                    </label>
                    <input
                      value={cancellationData.interest_funds}
                      placeholder="Enter Amount (&#8377;)"
                      name="interest_funds"
                      onChange={(e: any) => {
                        setCancellationData((prevState) => ({
                          ...prevState,
                          interest_funds: e.target.value
                            .replace(/[^0-9.]/gi, "")
                            .toString()
                            .split(".")
                            .map((el: any, i: any) =>
                              i ? el.split("").slice(0, 2).join("") : el
                            )
                            .join("."),
                        }));
                      }}
                      className="form-control input-height"
                    />
                  </div>

                  <div className="col-md-12  mb-5">
                    <label className="col-form-head">
                      Audited Report of Bank Statement
                    </label>
                    <div className="">
                      {/* <input type="file" className="form-control-file btn btn-sisfs btn-transparent-primary" id="exampleFormControlFile1" /> */}
                      <button
                        className="btn btn-sisfs btn-transparent-primary"
                        onClick={() =>
                          setOpenUploadBlock({ BankStatementUpload: true })
                        }
                        disabled={false}
                      >
                        Upload
                      </button>
                      {openUploadBlock &&
                        openUploadBlock["BankStatementUpload"] && (
                          <>
                            <span
                              className="close"
                              onClick={() =>
                                setOpenUploadBlock({
                                  BankStatementUpload: false,
                                })
                              }
                            >
                              <i className="fa fa-close"></i>
                            </span>
                            <FileUploader
                              key={"bank_statement_upload"}
                              id={"bank_statement_upload"}
                              information="Drag & Drop the file or Browse"
                              fileType="application/pdf"
                              buttonLabel="Browse"
                              multiple={false}
                              enablePreview
                              IconComponent={() => (
                                <span className="fa fa-cloud-upload"></span>
                              )}
                              removeIconClass=""
                              existingFiles={uploadFile}
                              onUploadFinish={(files: Array<File>) => {
                                onFileUploadComplete(files, "file");
                              }}
                              onFileDelete={(deletedFile: File) => {
                                onFileDelete("file");
                              }}
                            />
                            <small>Supported file format - PDF only</small>
                          </>
                        )}
                    </div>
                  </div>

                  <div className="col-md-12  mb-4">
                    <label className="col-form-head">
                      Supporting Documents (Optional)
                    </label>
                    <div className="">
                      {/* <input type="file" className="form-control-file btn btn-sisfs btn-transparent-primary" id="exampleFormControlFile1" /> */}
                      <button
                        className="btn btn-sisfs btn-transparent-primary"
                        onClick={() =>
                          setOpenUploadBlock({
                            SupportingDocumentsUpload: true,
                          })
                        }
                        disabled={false}
                      >
                        Upload
                      </button>
                      {openUploadBlock &&
                        openUploadBlock["SupportingDocumentsUpload"] && (
                          <>
                            <span
                              className="close"
                              onClick={() =>
                                setOpenUploadBlock({
                                  SupportingDocumentsUpload: false,
                                })
                              }
                            >
                              <i className="fa fa-close"></i>
                            </span>
                            <FileUploader
                              key={"supporting_documents_upload"}
                              id={"supporting_documents_upload"}
                              information="Drag & Drop the file or Browse"
                              fileType="application/pdf"
                              buttonLabel="Browse"
                              multiple={false}
                              enablePreview
                              IconComponent={() => (
                                <span className="fa fa-cloud-upload"></span>
                              )}
                              removeIconClass=""
                              existingFiles={other_file}
                              onUploadFinish={(files: Array<File>) => {
                                onFileUploadComplete(files, "other_file");
                              }}
                              onFileDelete={(deletedFile: File) => {
                                onFileDelete("other_file");
                              }}
                            />
                            <small>Supported file format - PDF only</small>
                          </>
                        )}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="row modal-row">
                    <div className="col-md-6 text-center">
                      <button
                        className="tertiary  btn-transparent-primary full-width"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="col-md-6 text-left">
                      <button
                        className={
                          isDisabled
                            ? "btn btn-sisfs btn-yellow-primary full-width custom-disabled"
                            : "btn btn-sisfs btn-yellow-primary full-width"
                        }
                        disabled={isDisabled}
                        onClick={submitCancellationRequest}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="contactPersons"
            role="dialog"
            aria-labelledby="exampleModalLabel1"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-xl"
              role="document"
            >
              {/* <div className="modal-content">
                <div className="modal-header modal-header-bottom pr-3 pb-0 pt-2">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" >×</span>
                  </button>
                </div>
              </div> */}
              <ContactDetails />
            </div>
          </div>
          {showEditSuccessModal && (
            // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
            <>
              <div className="modal-backdrop fade show"></div>

              <div
                className="modal align-middle modal-sisfs d-block"
                id="FormSubmissionSuccess"
              >
                <div className="modal-dialog modal-dialog-centered modal-md">
                  <div className="modal-content">
                    <div className="modal-body text-center">
                      <i className="fa fa-check-circle-o"></i>
                      <h3 className="modal-message">Thank You !</h3>
                      <div className="modal-text">
                        Application has been deleted Successfully !!!
                      </div>
                      <div className="modal-action">
                        <button
                          className="btn btn-sisfs btn-yellow-primary"
                          onClick={dismissModal}
                        >
                          Ok !!
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* edit fail modal */}
          {showEditFailureModal && (
            // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
            <>
              <div className="modal-backdrop fade show"></div>
              <div
                className="modal align-middle modal-sisfs d-block"
                id="FormSubmissionError"
              >
                <div className="modal-dialog modal-dialog-centered modal-md">
                  <div className="modal-content">
                    <div className="modal-body text-center">
                      {/* <i className="fa fa-times-circle-o"></i> */}
                      <h3 className="modal-message message-orange">
                        Oops! Something is Wrong
                      </h3>
                      <br />
                      <h3 className="modal-message message-orange">
                        Please Try Again!!!
                      </h3>
                      <div className="modal-action">
                        <button
                          className="btn btn-sisfs btn-yellow-primary"
                          onClick={dismissModal}
                        >
                          OK!
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {showIncubatorSuccessModal && (
            <ConfirmationDialog
              headerText={dialogData.header}
              bodyText={dialogData.text}
              onDialogClose={dismissModal}
            />
          )}
          {showIncubatorFailureModal && (
            <ConfirmationDialog
              headerText={failDialogData.header}
              bodyText={failDialogData.text}
              onDialogClose={dismissModal}
              iconClass="test"
            />
          )}
        </div>

        <GetApplicationInfoComponent />

        <ContactSectionComponent />
        {/* 2nd Application modal */}
        <div
          className="modal fade"
          id="SecondApplicationModalCenter"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div
                className="modal align-middle modal-sisfs d-block"
                id="FormSubmissionError"
              >
                <div className="modal-dialog modal-dialog-centered modal-md">
                  <div className="modal-content">
                    <div style={{ textAlign: "right" }}>
                      <i
                        className="fa fa-close"
                        data-dismiss="modal"
                        style={{
                          cursor: "pointer",
                          fontSize: "30px",
                          marginRight: "15px",
                          marginTop: "15px",
                          color: "#D3D3D3",
                        }}
                      ></i>
                    </div>
                    <div className="modal-body text-center pt-0">
                      <h4
                        className="modal-message"
                        style={{ color: "#2b3f88" }}
                      >
                        You have already applied for the second Application
                        please continue with the Existing application
                      </h4>
                      <div className="modal-action">
                        <button
                          className="btn btn-sisfs btn-yellow-primary"
                          data-dismiss="modal"
                        >
                          OK!
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 2nd Application modal */}
        <div
          className="modal fade"
          id="ApplicationModalCenter"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div
                className="modal align-middle modal-sisfs d-block"
                id="FormSubmissionError"
              >
                <div className="modal-dialog modal-dialog-centered modal-md">
                  <div className="modal-content">
                    <div style={{ textAlign: "right" }}>
                      <i
                        className="fa fa-close"
                        data-dismiss="modal"
                        style={{
                          cursor: "pointer",
                          fontSize: "30px",
                          marginRight: "15px",
                          marginTop: "15px",
                          color: "#D3D3D3",
                        }}
                      ></i>
                    </div>
                    <div className="modal-body text-center pt-0">
                      <h4
                        className="modal-message"
                        style={{ color: "#2b3f88" }}
                      >
                        You have already applied for the Application please
                        continue with the Existing application
                      </h4>
                      <div className="modal-action">
                        <button
                          className="btn btn-sisfs btn-yellow-primary"
                          data-dismiss="modal"
                        >
                          OK!
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 1st Application Ammount is Zero modal */}
        <div
          className="modal fade"
          id="FirstApplicationModalCenter"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div
                className="modal align-middle modal-sisfs d-block"
                id="FormSubmissionError"
              >
                <div className="modal-dialog modal-dialog-centered modal-md">
                  <div className="modal-content">
                    <div style={{ textAlign: "right" }}>
                      <i
                        className="fa fa-close"
                        data-dismiss="modal"
                        style={{
                          cursor: "pointer",
                          fontSize: "30px",
                          marginRight: "15px",
                          marginTop: "15px",
                          color: "#D3D3D3",
                        }}
                      ></i>
                    </div>
                    <div className="modal-body text-center pt-0">
                      <h4
                        className="modal-message"
                        style={{ color: "#2b3f88" }}
                      >
                        {/* message-orange */}
                        Please approve funds to the allotted startups on the
                        Seed Fund Portal
                      </h4>
                      <div className="modal-action">
                        <button
                          className="btn btn-sisfs btn-yellow-primary"
                          onClick={approveNow}
                        >
                          APPROVE NOW
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="FirstApplicationModal"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div
                className="modal align-middle modal-sisfs d-block"
                id="FormSubmissionError"
              >
                <div className="modal-dialog modal-dialog-centered modal-md">
                  <div className="modal-content">
                    <div style={{ textAlign: "right" }}>
                      <i
                        className="fa fa-close"
                        data-dismiss="modal"
                        style={{
                          cursor: "pointer",
                          fontSize: "30px",
                          marginRight: "15px",
                          marginTop: "15px",
                          color: "#D3D3D3",
                        }}
                      ></i>
                    </div>
                    <div className="modal-body text-center pt-0">
                      <h4
                        className="modal-message"
                        style={{ color: "#2b3f88" }}
                      >
                        {/* message-orange */}
                        You have no Approved Funds
                      </h4>
                      <div className="modal-action">
                        <button
                          className="btn btn-sisfs btn-yellow-primary"
                          data-dismiss="modal"
                        >
                          OK!
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* reapplying to the scheme with the same legal entity */}
        <div
          className="modal fade"
          id="sameLogic"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div
                className="modal align-middle modal-sisfs d-block"
                id="FormSubmissionError"
              >
                <div className="modal-dialog modal-dialog-centered modal-md">
                  <div className="modal-content">
                    <div style={{ textAlign: "right" }}>
                      <i
                        className="fa fa-close"
                        data-dismiss="modal"
                        style={{
                          cursor: "pointer",
                          fontSize: "30px",
                          marginRight: "15px",
                          marginTop: "15px",
                          color: "#D3D3D3",
                        }}
                      ></i>
                    </div>
                    <div className="modal-body text-center pt-0">
                      <h4
                        className="modal-message btn"
                        style={{ color: "#2b3f88" }}
                      >
                        {/* message-orange */}
                        Are you reapplying to the scheme with the same legal
                        entity as your previously approved application?
                      </h4>
                      <div
                        className="modal-action"
                        style={{ marginBottom: "0px" }}
                      >
                        <button
                          className="btn btn-sisfs btn-yellow-primary"
                          style={{ marginLeft: "15px" }}
                          onClick={yesClick}
                        >
                          YES
                        </button>
                        <button
                          className="btn btn-sisfs "
                          style={{
                            marginLeft: "15px",
                            color: "#2b3f88",
                            border: "1px solid #2b3f88",
                          }}
                          onClick={noClick}
                        >
                          NO
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {IncubatorModal && (
          <ConfirmationDialog
            headerText={"Oops !!"}
            bodyText={
              <div
                dangerouslySetInnerHTML={{
                  __html: `
            Dear Incubator, 
            <br/><br/>  
            Kindly note, we are currently not accepting any incubator applications for Startup India Seed Fund Scheme. Experts Advisory Committee has approved almost the entire corpus of funds under the scheme and there are enough applicants for the remaining corpus. Incubator applications may be admitted in the future, depending on the availability of funds. In case you are willing to apply for the scheme, kindly write to <a href="mailto:sisfs@investindia.org.in">sisfs@investindia.org.in</a> and <a href="mailto:seedfund@investindia.org.in">seedfund@investindia.org.in</a> and we will reach out to you in case of any slots available. 
            <br/><br/> 
            Regards, 
            <br/> 
            Seed Fund Secretariat.
          `,
                }}
              />
            }
            onDialogClose={() => setIncubatorModal(false)}
            iconClass="test"
          />
        )}
      </div>
    </>
  );
}

export default ApplicationStatusComponent;
