import React, { Component, createRef, useEffect, useState } from 'react';
import DashboardHeaderComponent from '../common/EacHeaderComponent';
import DashboardFooterComponent from '../../common/footer/DashboardFooterComponent';
import filter from '../../../images/filter.svg';
import { GET_ALL_LEGAL_ENTITY, GET_ALL_SECTOR, GET_ALL_STATES, GET_INCUBATOR_LISTING_COUNT, SCHEDULE_MEETING } from "../../../constants/api";
import { get, post } from '../../../effects/api/api.common';
import '../../../styles/dashboard.css';
import MultiSelectDropdown from "../../common/controls/MultiSelectDropdown"
import { DropdownModel } from '../../../models/DropdownModel';
import Calendar from '../../common/controls/Calendar';
//import { strict } from 'node:assert';
import { Link } from 'react-router-dom';
import search from '../../../images/search.svg';
import { useHistory } from "react-router-dom";
import {FormatDate, formatTime} from "../../../utils/DateFormat"
import MultiSelect from '../../common/controls/MultiSelect';
import { changeNumberFormat, filterByValue } from '../../../utils/CommonMethods';

function IncubatorContainerEac() {
  const [incubatorListingCounts, setIncubatorListingCounts] = useState({
    incomplete: '0',
    onHold: '0',
    pendingReview: '0',
    rejected: '0',
    selected: '0',
    submitted: '0',
    reviewCount: '0',
    trancheRequest: '0',
    closed: '0'
  });
  const [incubatorList, setincubatorList] = useState([]);
  const [filteredIncubatorList, setFilteredIncubatorList] = useState([{
    application_id: "",
    application_date: "",
    government_assisted: "",
    state_name: "",
    incubator_name: "",
    total_approved_amt: "",
    total_disbursed_amt: "",
    meeting_date: "",
    meeting_time: "",
    meeting_id: "",
    meeting_status:"",
    status_id: ""
  }]);
  const [columnHideFlag, setColumnHideFlag] = useState(true);
  const [incubatorListId, setIncubatorListId] = useState({
    incomplete_id: '',
    onHold_id: '',
    pendingReview_id: '',
    rejected_id: '',
    selected_id: '',
    submitted_id: '',
    trancheRequest_id: '',
    closed_id: ''
  });
  const history = useHistory();
  const [countryStates, setCountryStates] = useState([]);
  const [sectorDetails, setSectorDetails] = useState([]);
  const [entityDetails, setEntityDetails] = useState([]);
  const [oneStartupCheckbox, setOneStartup] = useState(false);
  const [noStartupCheckbox, setNoStartup] = useState(false);  
  const [showFilterDot, setShowFilterDot] = useState(false);
  const [incubatorListCountHead, setincubatorListCountHead] = useState("");
  const [selectedStateValue, setStateValue] = useState<Array<string>>([]);
  const [selectedSectorValue, setSectorValue] = useState<Array<string>>([]);
  const [selectedEntityValue, setEntityValue] = useState<Array<string>>([]);
  const [checkedArray, setCheckedArray] = useState<Array<string>>([]);
  const [fromTime, setFromTime] = useState(String);
  const [toTime, setToTime] = useState(String);
  const [incubatorMeetingType, setincubatorMeetingType] = useState("1");
  const [tableHeading, setTableHeading] = useState("All Applications");
  const [activeIndex, setActiveIndex] = useState("");
  const [scheduleDate, setScheduleDate] = useState(String);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    
    // getAllStates();
    getIncubatorCount();
    // getAllSectors();
    // getAllLegalEntity();
  }, []);
  // get initial count of incubators
  const getIncubatorCount = () => {
    post(GET_INCUBATOR_LISTING_COUNT, '').then((response: any) => {
      if (!response || !response.data) return;
      const counts = {
        incomplete: '0',
        onHold: '0',
        pendingReview: '0',
        rejected: '0',
        selected: '0',
        submitted: '0',
        reviewCount: response.data.reviewCount ? response.data.reviewCount : '0',
        trancheRequest:response.data.tranceRequestedCount,
        closed: '0'
      }
      const status_id = {
        incomplete_id: '',
        onHold_id: '',
        pendingReview_id: '',
        rejected_id: '',
        selected_id: '',
        submitted_id: '',
        trancheRequest_id: '',
        closed_id: '',
      }
      for (var i = 0; i < response.data.mainCount.length; i++) {
        if (response.data.mainCount[i].name == "incomplete") {
          counts.incomplete = response.data.mainCount[i].appln_count
          status_id.incomplete_id = response.data.mainCount[i].id
        }
        else if (response.data.mainCount[i].name == "pending_review") {
          counts.pendingReview = response.data.mainCount[i].appln_count
          status_id.pendingReview_id = response.data.mainCount[i].id
        }
        else if (response.data.mainCount[i].name == "submitted") {
          counts.submitted = response.data.mainCount[i].appln_count
          status_id.submitted_id = response.data.mainCount[i].id
        }
        else if (response.data.mainCount[i].name == "on_hold") {
          counts.onHold = response.data.mainCount[i].appln_count
          status_id.onHold_id = response.data.mainCount[i].id
        }
        else if (response.data.mainCount[i].name == "rejected") {
          counts.rejected = response.data.mainCount[i].appln_count
          status_id.rejected_id = response.data.mainCount[i].id
        }
        else if (response.data.mainCount[i].name == "selected") {
          counts.selected = response.data.mainCount[i].appln_count
          status_id.selected_id = response.data.mainCount[i].id
        }
        else if (response.data.mainCount[i].name == "closed") {
          counts.closed = response.data.mainCount[i].appln_count
          status_id.closed_id = response.data.mainCount[i].id
        }
      }
      setIncubatorListingCounts(counts);
      setIncubatorListId(status_id);
      if (response.data.applications.length != 0) {
        setincubatorListCountHead(response.data.applications.length)
        const incubatorListData = response.data.applications.map((item: any) => ({
          application_id: item.application_id,
          application_date: item.first_submitted_date!=null?FormatDate(item.first_submitted_date):FormatDate(item.application_date),
          government_assisted: item.government_assisted,
          state_name: item.state_name,
          incubator_name: item.incubator_name,
          total_approved_amt: item.total_approved_amt,
          total_disbursed_amt: item.total_disbursed_amt,
          meeting_date: item.meetingData.meeting_date!=""?item.meetingData.meeting_date:"",
          meeting_time: item.meetingData.meeting_from_time!=""? item.meetingData.meeting_from_time: "",
          meeting_id: item.meetingData.meeting_id!=""?item.meetingData.meeting_id: "",
          meeting_status:item.meetingData.status,
        }));

        setincubatorList(incubatorListData);
        setFilteredIncubatorList([
          {
            application_id: "",
            application_date: "",
            government_assisted: "",
            state_name: "",
            incubator_name: "",
            total_approved_amt: "",
            total_disbursed_amt: "",
            meeting_date: "",
            meeting_time: "",
            meeting_id: "",
            meeting_status:"",
            status_id: ""
          }
        ])
      }
      else {
        setincubatorList([]);
      }
      getAllStates();
    });
  };

  // get states in dropdown
  const getAllStates = () => {
    get(GET_ALL_STATES).then((response: any) => {
      if (!response || !response.data) return;
      const states = response.data.data.map(
        (item: any): DropdownModel => {
          return {
            text: item.name,
            id: item.id.toString(),
            translation_text: '',
          };
        }
      );
      setCountryStates(states);
      getAllSectors();
    });
  };

  // get all sectors in dropdown
  const getAllSectors = () => {
    // const param = sectorDetails.length == 0 ? '0' : sectorDetails
    get(`${GET_ALL_SECTOR}/0`).then((response: any) => {
      if (!response || !response.data) return;
      const sectors = response.data.data.map(
        (item: any): DropdownModel => {
          return {
            text: item.attribute_value,
            id: item.id.toString(),
            translation_text: '',
          };
        }
      );
      setSectorDetails(sectors);
      getAllLegalEntity();
    });
  };

  // get entity values in dropdown
  const getAllLegalEntity = () => {
    get(GET_ALL_LEGAL_ENTITY).then((response: any) => {
      if (!response || !response.data) return;
      const entity = response.data.data.map(
        (item: any): DropdownModel => {
          return {
            text: item.attribute_value,
            id: item.id.toString(),
            translation_text: '',
          };
        }
      );
      setEntityDetails(entity);
    });
  };

  // apply filter button click function
  const applyFilter = () => {
    if((selectedEntityValue.length!=0)||
    (selectedStateValue.length!=0)||
    (selectedSectorValue.length!=0)||
    (oneStartupCheckbox==true)||
    (noStartupCheckbox==true)){
      setShowFilterDot(true);
    }
    else{
      setShowFilterDot(false)
    }
    setTableHeading("All Applications")
    setActiveIndex("");
    var payload = {};
    payload = {
      leagel_entity_id:
        selectedEntityValue.length != 0 ? selectedEntityValue : null,
      state_id: selectedStateValue.length != 0 ? selectedStateValue : null,
      sector_id: selectedSectorValue.length != 0 ? selectedSectorValue : null,
      startup_funded_incubators: oneStartupCheckbox ? 'yes' : '',
      no_startup_funded_incubators: noStartupCheckbox ? 'yes' : '',
    };
    post(GET_INCUBATOR_LISTING_COUNT, payload).then((response: any) => {
      if (!response || !response.data) return;
      const counts = {
        incomplete: '0',
        onHold: '0',
        pendingReview: '0',
        rejected: '0',
        selected: '0',
        submitted: '0',
        reviewCount: response.data.reviewCount
          ? response.data.reviewCount
          : '0',
        trancheRequest: response.data.tranceRequestedCount,
        closed: '0',
      };
      const status_id = {
        incomplete_id: '',
        onHold_id: '',
        pendingReview_id: '',
        rejected_id: '',
        selected_id: '',
        submitted_id: '',
        trancheRequest_id: '',
        closed_id: '',
      };
      for (var i = 0; i < response.data.mainCount.length; i++) {
        if (response.data.mainCount[i].name == 'incomplete') {
          counts.incomplete = response.data.mainCount[i].appln_count;
          status_id.incomplete_id = response.data.mainCount[i].id;
        } else if (response.data.mainCount[i].name == 'pending_review') {
          counts.pendingReview = response.data.mainCount[i].appln_count;
          status_id.pendingReview_id = response.data.mainCount[i].id;
        } else if (response.data.mainCount[i].name == 'submitted') {
          counts.submitted = response.data.mainCount[i].appln_count;
          status_id.submitted_id = response.data.mainCount[i].id;
        } else if (response.data.mainCount[i].name == 'on_hold') {
          counts.onHold = response.data.mainCount[i].appln_count;
          status_id.onHold_id = response.data.mainCount[i].id;
        } else if (response.data.mainCount[i].name == 'rejected') {
          counts.rejected = response.data.mainCount[i].appln_count;
          status_id.rejected_id = response.data.mainCount[i].id;
        } else if (response.data.mainCount[i].name == 'selected') {
          counts.selected = response.data.mainCount[i].appln_count;
          status_id.selected_id = response.data.mainCount[i].id;
        } else if (response.data.mainCount[i].name == 'closed') {
          counts.closed = response.data.mainCount[i].appln_count;
          status_id.closed_id = response.data.mainCount[i].id;
        }
      }
      setIncubatorListingCounts(counts);
      setIncubatorListId(status_id);
      setincubatorListCountHead(response.data.applications.length);
      if (response.data.applications.length != 0) {
       
        const incubatorListData = response.data.applications.map(
          (item: any) => ({
            application_id: item.application_id,
            application_date: item.first_submitted_date!=null?FormatDate(item.first_submitted_date):FormatDate(item.application_date),
            government_assisted: item.government_assisted,
            state_name: item.state_name,
            incubator_name: item.incubator_name,
            total_approved_amt: item.total_approved_amt,
            total_disbursed_amt: item.total_disbursed_amt,
            meeting_date: item.meetingData.meeting_date!=""?item.meetingData.meeting_date:"",
              meeting_time: item.meetingData.meeting_from_time!=""? item.meetingData.meeting_from_time: "",
              meeting_id: item.meetingData.meeting_id!=""?item.meetingData.meeting_id: "",
              meeting_status:item.meetingData.status,
            status_id: item.status_id
          })
        );
        setincubatorList(incubatorListData);
        setFilteredIncubatorList([
          {
            application_id: "",
            application_date: "",
            government_assisted: "",
            state_name: "",
            incubator_name: "",
            total_approved_amt: "",
            total_disbursed_amt: "",
            meeting_date: "",
            meeting_time: "",
            meeting_id: "",
            meeting_status:"",
            status_id: ""
          }
        ])
      } else {
        setincubatorList([]);
      }
    });
  };

  // handeling multiselect checkbox in dropdown value
  const onMultiSelectChange = (selectedIds: Array<string>, name: string) => {
    if (name == "state") {
      setStateValue(selectedIds);
    }
    else if (name == "sector") {
      setSectorValue(selectedIds);
    }
    else if (name == "entity") {
      setEntityValue(selectedIds);
    }
  };

  // reset button function
  const resetFilter = () => {
    setTableHeading("All Applications")
    setActiveIndex("");
    setStateValue([]);
    setSectorValue([]);
    setEntityValue([]);    
    setShowFilterDot(false);
    setOneStartup(false);
    setNoStartup(false)
    getIncubatorCount();
  };

  // get data on click status card
  const getDataByStatus = (selected_id: any) => {
    if (selected_id == '2') {
      setTableHeading('Applications Submitted');
      setincubatorListCountHead(incubatorListingCounts.submitted)
    } else if (selected_id == '3') {
      setTableHeading('Applications to be Reviewed');
      setincubatorListCountHead(incubatorListingCounts.pendingReview)
    } else if (selected_id == '4') {
      setTableHeading('Applications Incomplete');
      setincubatorListCountHead(incubatorListingCounts.incomplete)
    } else if (selected_id == '5') {
      setTableHeading('Applications On Hold');
      setincubatorListCountHead(incubatorListingCounts.onHold)
    } else if (selected_id == '6') {
      setTableHeading('Applications Rejected');
      setincubatorListCountHead(incubatorListingCounts.rejected)
    } else if (selected_id == '7') {
      setTableHeading('Applications Selected');
      setincubatorListCountHead(incubatorListingCounts.selected)
    } else if (selected_id == '8') {
      setTableHeading('Applications Closed');
      setincubatorListCountHead(incubatorListingCounts.closed)
    }
    else if (selected_id == 'tranche') {
      setTableHeading('Tranche Requested');
      setincubatorListCountHead(incubatorListingCounts.trancheRequest)
    }
    setActiveIndex(selected_id);
    setincubatorList([]);
    var payload = {};
    if(selected_id=='tranche'){
      payload = {
        trance_requested:'Yes',
        leagel_entity_id:
          selectedEntityValue.length != 0 ? selectedEntityValue : null,
        state_id: selectedStateValue.length != 0 ? selectedStateValue : null,
        sector_id: selectedSectorValue.length != 0 ? selectedSectorValue : null,
        startup_funded_incubators: oneStartupCheckbox ? 'yes' : '',
        no_startup_funded_incubators: noStartupCheckbox ? 'yes' : '',
      };
    }
    else{
    payload = {
      status_id: selected_id,
      leagel_entity_id:
        selectedEntityValue.length != 0 ? selectedEntityValue : null,
      state_id: selectedStateValue.length != 0 ? selectedStateValue : null,
      sector_id: selectedSectorValue.length != 0 ? selectedSectorValue : null,
      startup_funded_incubators: oneStartupCheckbox ? 'yes' : '',
      no_startup_funded_incubators: noStartupCheckbox ? 'yes' : '',
    };
  }
    post(GET_INCUBATOR_LISTING_COUNT, payload).then(
      (response: any) => {
        if (!response || !response.data) return;

        if (response.data.applications.length != 0) {
          const incubatorListData = response.data.applications.map(
            (item: any) => ({
              application_id: item.application_id,
              application_date: item.first_submitted_date!=null?FormatDate(item.first_submitted_date):FormatDate(item.application_date),
              government_assisted: item.government_assisted,
              state_name: item.state_name,
              incubator_name: item.incubator_name,
              total_approved_amt: item.total_approved_amt,
              total_disbursed_amt: item.total_disbursed_amt,
              meeting_date: item.meetingData.meeting_date!=""?item.meetingData.meeting_date:"",
              meeting_time: item.meetingData.meeting_from_time!=""? item.meetingData.meeting_from_time: "",
              meeting_id: item.meetingData.meeting_id!=""?item.meetingData.meeting_id: "",
              meeting_status:item.meetingData.status,
              status_id: item.status_id
            })
          );

          setincubatorList(incubatorListData);
          setFilteredIncubatorList([
            {
              application_id: "",
              application_date: "",
              government_assisted: "",
              state_name: "",
              incubator_name: "",
              total_approved_amt: "",
              total_disbursed_amt: "",
              meeting_date: "",
              meeting_time: "",
              meeting_id: "",
              meeting_status:"",
              status_id: ""
            }
          ])
        } else {
          setincubatorList([]);
        }
      }
    );
    if (selected_id == '7' || selected_id == '8') {
      setColumnHideFlag(true);
    } else {
      setColumnHideFlag(false);
    }
  };
  // checkbox checked event
  const handleOnCheck = (e: any /*index:number*/) => {
    const item = e.target.name;
    const isChecked = e.target.checked;
    if (isChecked) {
      if (item == "one_startup") {
        setOneStartup(true)
      }
      else if (item == "any_startup") {
        setNoStartup(true)
      }
    }
    else {
      if (item == "one_startup") {
        setOneStartup(false)
      }
      else if (item == "any_startup") {
        setNoStartup(false)
      }
    }
  }

  // setCheckedArrayCount(checkedArray.length);
  const handleListChange = (e: any /*index:number*/) => {
    var value = e.target.value;
    var isChecked = e.target.checked;
    if (isChecked) {
      if (checkedArray.includes(value) == false) {
        setCheckedArray(oldArray => [...oldArray, value]);
      }
    }
    else if (isChecked == false) {
      if (checkedArray.includes(value)) {
        setCheckedArray(checkedArray.filter(item => item !== value));
      }
    }
  }
  // allcheckbox check
  const handleAllCheck = (e: any /*index:number*/) => {
    var name = e.target.name;
    var isChecked = e.target.checked;
    if (isChecked) {
      setCheckedArray([]);
      incubatorList.map(({ application_id }) => (
        setCheckedArray(oldArray => [...oldArray, application_id])
      ))
      setIsChecked(true)

    }
    else if (isChecked == false) {
      setIsChecked(false);
      setCheckedArray([]);
    }
  }
  // function to format date
  function formatDate(date: Date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  // trrigers on date change
  const handleDateChange = (date: Date, field: string) => {
    setScheduleDate(formatDate(date));
  };


  // on change time;
  const onTimeChange = (e: any /*index:number*/) => {
    var value = e.target.value;
    var name = e.target.name;
    if (name == "fromTime") {
      setFromTime(value);
    }
    else if (name == "toTime") {
      setToTime(value);
    }
  }

  // on incubator dropdown change
  const handleOnChange = (e: any /*index:number*/) => {
    var value = e.target.value;
    setincubatorMeetingType(value);
  }
 

  const handleRowChange = (id: any) => {
      let flag = false;
      if (activeIndex == "tranche") {
        flag = true;
      }
      else {
        flag = false;
      }
      history.replace({
        pathname: '/eac_view_incubator',
        state: { application_id: id, isFundDetails: flag },
      });   
  }
  
  const searchList=(e:any)=>{
    const filteredData=filterByValue(incubatorList, e.target.value);    
    setincubatorListCountHead(filteredData.length.toString())
    setFilteredIncubatorList(filteredData); 
    // console.log(filteredData)    
  }
  
  return (
    <div>
      <div className="container container-min pt-5">
        <div className="row mb-3">
          <div className="col-md-6">
            <p className="dashboard pl-2 pt-2">Incubator</p>
          </div>
          <div className="col-md-5 text-right pr-5">
            <div className="d-flex searchDiv"> <img
              src={search}
              className="search"
              alt="search"

            />
              <input
                className="searchBar"
                id="search"
                type="text"
                placeholder="Search.."
                name="search"                
                onKeyUp={searchList}
              /></div>
          </div>
          <div className="col-md-1 filter-icon">
            <img src={filter} className="img-fluid filter" alt="SISFS Logo" data-toggle="modal" data-target="#filterModal" />
            {showFilterDot==true?<mark></mark>:null}
          </div>
        </div>

        <div className="d-flex">
          {/* <div className="incubator-card" onClick={() => getDataByStatus(`${incubatorListId.submitted_id}`)}>
            <div className={activeIndex == incubatorListId.submitted_id ? "rectangle-card-selected" : "rectangle-card"}>
              <span className={activeIndex == incubatorListId.submitted_id ? "smallIncubatorNumeric" : "incubator-numeric-black"}>{incubatorListingCounts.submitted}</span>
              <br />
              <span className={activeIndex == incubatorListId.submitted_id ? "incubator-text-selected-small" : "card-text-incubator"}>
                Applications Submitted
              </span>
            </div>
          </div>
          <div className="incubator-card" onClick={() => getDataByStatus(`${incubatorListId.selected_id}`)}>
            <div className={activeIndex == incubatorListId.selected_id ? "rectangle-card-selected" : "rectangle-card"}>
              <span className={activeIndex == incubatorListId.selected_id ? "smallIncubatorNumeric" : "incubator-numeric-black"}>{incubatorListingCounts.selected}</span>
              <br />
              <span className={activeIndex == incubatorListId.selected_id ? "incubator-text-selected-small" : "card-text-incubator"}>
                Applications Selected
              </span>
            </div>
          </div>
          <div className="incubator-card" onClick={() => getDataByStatus(`${incubatorListId.pendingReview_id}`)}>
            <div className={activeIndex == incubatorListId.pendingReview_id ? "rectangle-card-selected" : "rectangle-card"}>
              <span className={activeIndex == incubatorListId.pendingReview_id ? "smallIncubatorNumeric" : "incubator-numeric-black"}>{incubatorListingCounts.reviewCount}</span>
              <br />
              <span className={activeIndex == incubatorListId.pendingReview_id ? "incubator-text-selected-small" : "card-text-incubator"}>
                Applications to<br />&nbsp;be Reviewed
              </span>
            </div>
          </div>
          <div className="incubator-card" onClick={() => getDataByStatus(`${incubatorListId.onHold_id}`)}>
            <div className={activeIndex == incubatorListId.onHold_id ? "rectangle-card-selected" : "rectangle-card"}>
              <span className={activeIndex == incubatorListId.onHold_id ? "smallIncubatorNumeric" : "incubator-numeric-black"}>{incubatorListingCounts.onHold}</span>
              <br />
              <span className={activeIndex == incubatorListId.onHold_id ? "incubator-text-selected-small" : "card-text-incubator"}>
                Applications <br />on Hold
              </span>
            </div>
          </div>
          <div className="incubator-card" onClick={() => getDataByStatus(`${incubatorListId.incomplete_id}`)}>
            <div className={activeIndex == incubatorListId.incomplete_id ? "rectangle-card-selected" : "rectangle-card"}>
              <span className={activeIndex == incubatorListId.incomplete_id ? "smallIncubatorNumeric" : "incubator-numeric-black"}>{incubatorListingCounts.incomplete}</span>
              <br />
              <span className={activeIndex == incubatorListId.incomplete_id ? "incubator-text-selected-small" : "card-text-incubator"}>
                Applications Incomplete
              </span>
            </div>
          </div>
          <div className="incubator-card" onClick={() => getDataByStatus(`${incubatorListId.rejected_id}`)}>
            <div className={activeIndex == incubatorListId.rejected_id ? "rectangle-card-selected" : "rectangle-card"}>
              <span className={activeIndex == incubatorListId.rejected_id ? "smallIncubatorNumeric" : "incubator-numeric-black"}>{incubatorListingCounts.rejected}</span>
              <br />
              <span className={activeIndex == incubatorListId.rejected_id ? "incubator-text-selected-small" : "card-text-incubator"}>
                Applications Rejected
              </span>
            </div>
          </div>

          <div className="incubator-card" >
            <div className="rectangle-card">
              <span className="incubator-numeric-black">{incubatorListingCounts.trancheRequest}</span>
              <br />
              <span className="card-text-incubator">
                Tranche Requested
              </span>
            </div>
          </div>
          <div className="incubator-card" onClick={() => getDataByStatus(`${incubatorListId.closed_id}`)}>
            <div className={activeIndex == incubatorListId.closed_id ? "rectangle-card-selected" : "rectangle-card"}>
              <span className={activeIndex == incubatorListId.closed_id ? "smallIncubatorNumeric" : "incubator-numeric-black"}>{incubatorListingCounts.closed}</span>
              <br />
              <span className={activeIndex == incubatorListId.closed_id ? "incubator-text-selected-small" : "card-text-incubator"}>
                Applications Closed
              </span>
            </div>
          </div> */}
          <div className="incubator-card" onClick={() => getDataByStatus(`${incubatorListId.submitted_id}`)}>
            <div className={activeIndex == incubatorListId.submitted_id ? "rectangle-card-selected" : "rectangle-card"}>
              <span className={activeIndex == incubatorListId.submitted_id ? "smallIncubatorNumeric" : "incubator-numeric-black"}>{incubatorListingCounts.submitted}</span>
              <br />
              <span className={activeIndex == incubatorListId.submitted_id ? "incubator-text-selected-small" : "card-text-incubator"}>
                Applications Submitted
              </span>
            </div>
          </div>          
          <div className="incubator-card" onClick={() => getDataByStatus(`${incubatorListId.pendingReview_id}`)}>
            <div className={activeIndex == incubatorListId.pendingReview_id ? "rectangle-card-selected" : "rectangle-card"}>
              <span className={activeIndex == incubatorListId.pendingReview_id ? "smallIncubatorNumeric" : "incubator-numeric-black"}>{incubatorListingCounts.reviewCount}</span>
              <br />
              <span className={activeIndex == incubatorListId.pendingReview_id ? "incubator-text-selected-small" : "card-text-incubator"}>
                Applications to<br />&nbsp;be Reviewed
              </span>
            </div>
          </div>
          <div className="incubator-card" onClick={() => getDataByStatus(`${incubatorListId.incomplete_id}`)}>
            <div className={activeIndex == incubatorListId.incomplete_id ? "rectangle-card-selected" : "rectangle-card"}>
              <span className={activeIndex == incubatorListId.incomplete_id ? "smallIncubatorNumeric" : "incubator-numeric-black"}>{incubatorListingCounts.incomplete}</span>
              <br />
              <span className={activeIndex == incubatorListId.incomplete_id ? "incubator-text-selected-small" : "card-text-incubator"}>
                Applications Incomplete
              </span>
            </div>
          </div>
          <div className="incubator-card" onClick={() => getDataByStatus(`${incubatorListId.onHold_id}`)}>
            <div className={activeIndex == incubatorListId.onHold_id ? "rectangle-card-selected" : "rectangle-card"}>
              <span className={activeIndex == incubatorListId.onHold_id ? "smallIncubatorNumeric" : "incubator-numeric-black"}>{incubatorListingCounts.onHold}</span>
              <br />
              <span className={activeIndex == incubatorListId.onHold_id ? "incubator-text-selected-small" : "card-text-incubator"}>
                Applications <br />on Hold
              </span>
            </div>
          </div>
          <div className="incubator-card" onClick={() => getDataByStatus(`${incubatorListId.selected_id}`)}>
            <div className={activeIndex == incubatorListId.selected_id ? "rectangle-card-selected" : "rectangle-card"}>
              <span className={activeIndex == incubatorListId.selected_id ? "smallIncubatorNumeric" : "incubator-numeric-black"}>{incubatorListingCounts.selected}</span>
              <br />
              <span className={activeIndex == incubatorListId.selected_id ? "incubator-text-selected-small" : "card-text-incubator"}>
                Applications Selected
              </span>
            </div>
          </div>     
           <div className="incubator-card" onClick={() => getDataByStatus(`${incubatorListId.rejected_id}`)}>
            <div className={activeIndex == incubatorListId.rejected_id ? "rectangle-card-selected" : "rectangle-card"}>
              <span className={activeIndex == incubatorListId.rejected_id ? "smallIncubatorNumeric" : "incubator-numeric-black"}>{incubatorListingCounts.rejected}</span>
              <br />
              <span className={activeIndex == incubatorListId.rejected_id ? "incubator-text-selected-small" : "card-text-incubator"}>
                Applications Rejected
              </span>
            </div>
          </div>
          <div className="incubator-card" onClick={() => getDataByStatus('tranche')}>
            <div className={activeIndex == 'tranche' ? "rectangle-card-selected" : "rectangle-card"}>
              <span className={activeIndex == 'tranche' ? "smallIncubatorNumeric" : "incubator-numeric-black"}>
                {incubatorListingCounts.trancheRequest}
              </span>
              <br />
              <span className={activeIndex == 'tranche' ? "incubator-text-selected-small" : "card-text-incubator"}>Tranche Requested</span>
            </div>
          </div>
          <div className="incubator-card" onClick={() => getDataByStatus(`${incubatorListId.closed_id}`)}>
            <div className={activeIndex == incubatorListId.closed_id ? "rectangle-card-selected" : "rectangle-card"}>
              <span className={activeIndex == incubatorListId.closed_id ? "smallIncubatorNumeric" : "incubator-numeric-black"}>{incubatorListingCounts.closed}</span>
              <br />
              <span className={activeIndex == incubatorListId.closed_id ? "incubator-text-selected-small" : "card-text-incubator"}>
                Applications Closed
              </span>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-md-4 pt-2">
            <span className="table-head">{tableHeading}</span>
          </div>
          <div className="col-md-8 text-right">
            {/* <button className="tertiary  btn-yellow-primary" data-toggle="modal" data-target="#scheduleMeeting">
              SCHEDULE A MEETING
                </button> */}
        {/* <button className="tertiary  btn-yellow-primary ml-3 mr-3">
              Download
                </button> 

          </div>
        </div> */}
        <div className="row incubator-heading-div">
          <div className="col-md-4 pt-2">
            <span className="table-head">{tableHeading}</span>
            <br />
            <p className="count-text-incubator">({incubatorListCountHead} Results)</p>

          </div>
          <div className="col-md-8 text-right">
            {/* <button
              className="tertiary  btn-yellow-primary"
              data-toggle="modal"
              data-target="#scheduleMeeting"
            >
              SCHEDULE A MEETING
            </button> */}
            {/* <button className="tertiary  btn-yellow-primary ml-3 mr-3">
              DOWNLOAD XL
            </button>
            <button className="btn btn-sisfs btn-transparent-primary">
              UPLOAD XL
            </button> */}
          </div>
        </div>
        <div className="table-scroll mb-5">
        <table className="reviewTable" id="customTable">
            <thead>
              <tr>
                {/* <th className="text-center table-heading">

                  <div className="custom-control custom-checkbox">
                  <input
                      type="checkbox"
                      id="customcheckbox1"
                      name="customcheckbox"
                      className="custom-control-input"
                      onChange={handleAllCheck}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customcheckbox1"
                    >

                    </label>
                  </div>
                </th> */}
                <th className="text-left table-heading">Incubator Name</th>
                {columnHideFlag == true ? (
                  <th className="text-left table-heading">Amount Approved (&#8377;)</th>
                ) : null}
                {columnHideFlag == true ? (
                  <th className="text-left table-heading">Amount Disbursed (&#8377;)</th>
                ) : null}
                {columnHideFlag == true ? (
                  <th className="text-left table-heading">No. of Startups Supported </th>
                ) : null}
                {columnHideFlag == true ? (
                  <th className="text-left table-heading">
                    Amount Disbursed to Startups (&#8377;)
                  </th>
                ) : null}
              
                {columnHideFlag != true ? (
                  <th className="text-left table-heading">State</th>
                ) : null}
                {columnHideFlag != true ? (
                  <th className="text-left table-heading">
                    Assisted by Central/State Govt.
                  </th>
                ) : null}
                {columnHideFlag != true ? (
                  <th className="text-left table-heading">Date of Application</th>
                ) : null}
                  <th className="text-center table-heading">Meeting Details</th>
              </tr>
            </thead>
            <tbody>
              {incubatorList.length == 0 || filteredIncubatorList.length==0 ? (
                <tr>
                  <td className="text-center table-heading" colSpan={7}>
                    No data
                  </td>
                </tr>
              ) : ( filteredIncubatorList.length==0 || filteredIncubatorList[0].application_id==""?
              (
                incubatorList.map(
                  ({
                    application_id,
                    application_date,
                    government_assisted,
                    state_name,
                    incubator_name,
                    total_approved_amt,
                    total_disbursed_amt,
                    meeting_status,
                    meeting_date,
                    meeting_time,
                    due_review_date,
                  }) => (
                    <tr>
                      {/* <td>
                       
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            value={application_id}
                            id={incubator_name + '_' + application_id}
                            name={incubator_name + '_' + application_id}
                            className="custom-control-input"
                            disabled={isChecked}
                            onChange={handleListChange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={incubator_name + '_' + application_id}
                          >

                          </label>
                        </div>
                      </td> */}
                      <td className="text-left custom-td" onClick={() => handleRowChange(application_id)}>
                        <span className="tableDataHead">{incubator_name}</span>
                        <br />
                        <span className="tableDataApplication">
                        {'No: '+ application_id}
                        </span>
                      </td>
                      {columnHideFlag == true ? (
                        <td
                          onClick={() => handleRowChange(application_id)}
                          className="text-center custom-td-small custom-td-right"
                        >
                          {total_approved_amt ? changeNumberFormat(Number(total_approved_amt),1,0) : 0}
                        </td>
                      ) : null}
                      {columnHideFlag == true ? (
                        <td
                          onClick={() => handleRowChange(application_id)}
                          className="text-center custom-td-small custom-td-right"
                        >
                         
                            {total_disbursed_amt ? changeNumberFormat(Number(total_disbursed_amt), 1, 0) : 0}
                         
                          
                        </td>
                      ) : null}
                      {columnHideFlag == true ? (
                        <td
                          onClick={() => handleRowChange(application_id)}
                          className="text-center custom-td-small"
                        >
                          0
                        </td>
                      ) : null}
                      {columnHideFlag == true ? (
                        <td
                          onClick={() => handleRowChange(application_id)}
                          className="text-center custom-td-small custom-td-right"
                        >
                          0
                        </td>
                      ) : null}
                       
                      {columnHideFlag != true ? (
                        <td
                          onClick={() => handleRowChange(application_id)}
                          className="text-left custom-td"
                        >
                          {state_name}
                        </td>
                      ) : null}
                      {columnHideFlag != true ? (
                        <td
                          onClick={() => handleRowChange(application_id)}
                          className="text-left"
                        >
                          {government_assisted}
                        </td>
                      ) : null}
                      {columnHideFlag != true ? (
                        <td
                          onClick={() => handleRowChange(application_id)}
                          className="text-left custom-td-width"
                        >
                          {application_date}
                        </td>
                      ) : null}
                       <td
                          onClick={() => handleRowChange(application_id)}
                          className="text-center"
                        >
                          {
                         (meeting_date == "" && meeting_time == "")||meeting_status=="NA"  ?
                              // <>
                              //   <div className="badge-red">
                              //     Today:{meeting_time}
                              //   </div>
                              //   <span className="tableDataApplication">
                              //     {meeting_date}
                              //   </span>
                              // </>
                              <div className="badge-grey">{meeting_status}</div>
                              :
                              meeting_status=="Today" ?
                                <>
                                  <div className="badge-red">
                                  Today: {meeting_time!=null?formatTime(meeting_time):""}
                                  </div>
                                  <span className="tableDataApplication">
                                    {FormatDate(meeting_date)}
                                  </span>
                                </>
                                : <>
                                  <div className="badge-grey">{meeting_status}</div>
                                  <span className="tableDataApplication">
                                    {FormatDate(meeting_date)}
                                  </span>
                                </>
                          }
                        </td>
                    </tr>
                  )
                )
              ):
              (
                filteredIncubatorList.map(
                  ({
                    application_id,
                    application_date,
                    government_assisted,
                    state_name,
                    incubator_name,
                    total_approved_amt,
                    total_disbursed_amt,
                    meeting_status,
                    meeting_date,
                    meeting_time
                  }) => (
                    <tr>
                      {/* <td>
                       
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            value={application_id}
                            id={incubator_name + '_' + application_id}
                            name={incubator_name + '_' + application_id}
                            className="custom-control-input"
                            disabled={isChecked}
                            onChange={handleListChange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={incubator_name + '_' + application_id}
                          >

                          </label>
                        </div>
                      </td> */}
                      <td className="text-left custom-td" onClick={() => handleRowChange(application_id)}>
                        <span className="tableDataHead">{incubator_name}</span>
                        <br />
                        <span className="tableDataApplication">
                        {'No: '+ application_id}
                        </span>
                      </td>
                      {columnHideFlag == true ? (
                        <td
                          onClick={() => handleRowChange(application_id)}
                          className="text-right custom-td-small custom-td-right"
                        >
                          {total_approved_amt ? changeNumberFormat(Number(total_approved_amt), 1, 0) : 0}
                        </td>
                      ) : null}
                      {columnHideFlag == true ? (
                        <td
                          onClick={() => handleRowChange(application_id)}
                          className="text-right custom-td-small custom-td-right"
                        >
                         
                            {total_disbursed_amt ? changeNumberFormat(Number(total_disbursed_amt), 1, 0) : 0}
                         
                          
                        </td>
                      ) : null}
                      {columnHideFlag == true ? (
                        <td
                          onClick={() => handleRowChange(application_id)}
                          className="text-center custom-td-small"
                        >
                          0
                        </td>
                      ) : null}
                      {columnHideFlag == true ? (
                        <td
                          onClick={() => handleRowChange(application_id)}
                          className="text-right custom-td-small custom-td-right"
                        >
                          0
                        </td>
                      ) : null}
                       
                      {columnHideFlag != true ? (
                        <td
                          onClick={() => handleRowChange(application_id)}
                          className="text-left custom-td"
                        >
                          {state_name}
                        </td>
                      ) : null}
                      {columnHideFlag != true ? (
                        <td
                          onClick={() => handleRowChange(application_id)}
                          className="text-left"
                        >
                          {government_assisted}
                        </td>
                      ) : null}
                      {columnHideFlag != true ? (
                        <td
                          onClick={() => handleRowChange(application_id)}
                          className="text-left custom-td-width"
                        >
                          {application_date}
                        </td>
                      ) : null}
                       <td
                          onClick={() => handleRowChange(application_id)}
                          className="text-center"
                        >
                          {
                          meeting_date == "" && meeting_time == "" ?
                              // <>
                              //   <div className="badge-red">
                              //     Today:{meeting_time}
                              //   </div>
                              //   <span className="tableDataApplication">
                              //     {meeting_date}
                              //   </span>
                              // </>
                              <div className="badge-grey">{meeting_status}</div>
                              :
                              meeting_status=="Today" ?
                                <>
                                  <div className="badge-red">
                                  Today: {meeting_time!=null?formatTime(meeting_time):""}
                                  </div>
                                  <span className="tableDataApplication">
                                    {FormatDate(meeting_date)}
                                  </span>
                                </>
                                : <>
                                  <div className="badge-grey">{meeting_status}</div>
                                  <span className="tableDataApplication">
                                    {FormatDate(meeting_date)}
                                  </span>
                                </>
                          }
                        </td>
                    </tr>
                  )
                )
              )
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* modal for filter */}
      <div className="modal fade" id="filterModal" role="dialog" aria-labelledby="exampleModalLabel1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-slideout modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header modal-header-bottom">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pl-5 pr-5 pt-1">
              <div className="popup-head mb-5 ml-3">
                <div className="main-heading">Filters</div>
              </div>

              <label className="col-sm-4 col-form-head">
                Location
                        </label>
              <div className="col-md-12 mb-4">
                <MultiSelect
                  id="countryStates"
                  options={countryStates}
                  defaultText="Select State"
                  onClose={(selectedIds: string[]) =>
                    onMultiSelectChange(selectedIds, 'state')
                  }
                  selectedValues={
                    selectedStateValue ? selectedStateValue : []
                  }
                  hasError
                  errorText=""
                  searchable
                />
              </div>
              <label className="col-sm-4 col-form-head">
                Sector
                        </label>
              <div className="col-md-12 mb-4">
                <MultiSelect
                  id="countryStates"
                  options={sectorDetails}
                  defaultText="Select Industry"
                  onClose={(selectedIds: string[]) =>
                    onMultiSelectChange(selectedIds, 'sector')
                  }
                  selectedValues={
                    selectedSectorValue ? selectedSectorValue : []
                  }
                  hasError
                  errorText=""
                  searchable
                />
              </div>
              <label className="col-sm-4 col-form-head">
                Legal Entity
                        </label>
              <div className="col-md-12 mb-4">
                <MultiSelect
                  id="countryStates"
                  options={entityDetails}
                  defaultText="Select Industry"
                  onClose={(selectedIds: string[]) =>
                    onMultiSelectChange(selectedIds, 'entity')
                  }
                  selectedValues={
                    selectedEntityValue ? selectedEntityValue : []
                  }
                  hasError
                  errorText=""
                  searchable
                />
              </div>
              {/* <label className="col-sm-4 col-form-head">
                Due Review
                        </label>
              <div className="col-md-12 mb-4">
              <div  className="select-custom">
                <select className="form-control input-height" disabled={true} aria-label="Select Entity Type">
                  <option selected>Incubator Selection</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
                </div>
              </div> */}
              <fieldset disabled={true}>
              {/* <label className="col-sm-4 col-form-head">Tranche</label>
              <div className="col-md-12 mb-4">
              <div  className="select-custom">
                <select disabled={true}
                  className="form-control input-height"
                  aria-label="Select Entity Type"
                >
                  <option selected>Incubator Selection</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
                </div>
              </div> */}
              <div className="row ml-3">
                <div className="pt-1 mr-2">
                <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id="one_startup"
                      className="custom-control-input"
                      onChange={handleOnCheck}
                      checked={oneStartupCheckbox}
                      name="one_startup"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="one_startup"
                    >

                    </label>
                  </div>
                  {/* <input
                    type="checkbox"
                    onChange={handleOnCheck}
                    checked={oneStartupCheckbox}
                    name="one_startup"
                  /> */}
                </div>
                <div className="checkbox-text">
                  Incubators that have funded atleast 1 startup
                </div>
              </div>
              <div className="row ml-3 mb-4">
                <div className="pt-1 mr-2">
                <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id="any_startup"
                      className="custom-control-input"
                      onChange={handleOnCheck}
                      checked={noStartupCheckbox}
                      name="any_startup"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="any_startup"
                    >

                    </label>
                  </div>
                  {/* <input
                    type="checkbox"
                    onChange={handleOnCheck}
                    checked={noStartupCheckbox}
                    name="any_startup"
                  /> */}
                </div>
                <div className="checkbox-text">
                  Incubators that have not funded any startups
                </div>
              </div>     
              </fieldset>        
            </div>
            <div className="modal-footer">
              <div className="row modal-row">
              <div className="col-md-6 text-center">
                <button
                  className="btn btn-sisfs btn-transparent-primary"
                  data-dismiss="modal"
                  onClick={resetFilter}
                >
                  RESET ALL
                </button>
                </div>
                <div className="col-md-6 text-left">
                <button
                  className="tertiary  btn-yellow-primary"
                  data-dismiss="modal"
                  onClick={applyFilter}
                >
                  APPLY FILTER
                </button>
                </div>
              </div>
              </div>
          </div>
        </div>
      </div>
      {/* schedule a meeting tab */}
      {/* <div className="modal fade" id="scheduleMeeting" role="dialog" aria-labelledby="exampleModalLabel1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-slideout modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pl-5 pr-5 pt-5">
              <div className="popup-head mb-5 ml-3">
                <div className="main-heading">Schedule a Meeting</div>
                <div className="count-text">{checkedArray.length} Incubators Selected</div>
              </div>

              <label className="col-sm-4 col-form-head">
                Meeting Type
                        </label>
              <div className="col-md-12 mb-4">
                <select className="form-control input-height" aria-label="Select Entity Type" onChange={handleOnChange}>
                  <option value="1">Incubator Selection</option>
                  <option value="2">Performance Review Meeting</option>
                </select>
              </div>
              <label className="col-sm-4 col-form-head">
                Date & Time
                        </label>
              <div className="col-md-6 mb-4">
                <Calendar
                  id="registrationDate"
                  name="registrationDate"
                  className="form-control input-height"
                  format="dd/MM/yyyy"
                  selectedDate={new Date()}
                  onDateChange={(date: Date, field: string) =>
                    handleDateChange(date, field)
                  }
                />
              </div>
              <div className="row pl-3 mb-5">
                <div className="col-md-6">
                  <label className="col-form-head">
                    From Time
                        </label>
                  <input type="time" value={fromTime} name="fromTime" className="form-control input-height" onChange={onTimeChange} />
                </div>
                <div className="col-md-6">
                  <label className="col-form-head">
                    To Time
                        </label>
                  <input type="time" value={toTime} name="toTime" className="form-control input-height" onChange={onTimeChange} />
                </div>
              </div>
              <div className="row pl-5">
                <button className="btn btn-sisfs btn-transparent-primary" data-dismiss="modal">
                  CANCEL
              </button>
                <button className="tertiary  btn-yellow-primary ml-3" onClick={scheduleMeetingFunction}>
                  SCHEDULE
                </button>

              </div>

            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default IncubatorContainerEac;
