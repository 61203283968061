import React, { createContext, useEffect, useState } from 'react';
export interface AuthContextType {
    auth: string | null;
    setAuth: React.Dispatch<React.SetStateAction<string | null>>;
    utilizationFile: File | null;
    setUtizationFile: React.Dispatch<React.SetStateAction<File | null>>;
    auditedFile: File | null;
    setAuditedFile: React.Dispatch<React.SetStateAction<File | null>>;
    interestFile: File | null;
    setInterestFile: React.Dispatch<React.SetStateAction<File | null>>;
    ismcFile: File | null;
    setIsmcFile: React.Dispatch<React.SetStateAction<File | null>>;
    achievementFile: File | null;
    setachievementFile: React.Dispatch<React.SetStateAction<File | null>>;
    debentureFile: File | null;
    setdebentureFile: React.Dispatch<React.SetStateAction<File | null>>;
    disbursedFile: File | null;
    setdisbursedFile: React.Dispatch<React.SetStateAction<File | null>>;
    declarationFile: File | null;
    setdeclarationFile: React.Dispatch<React.SetStateAction<File | null>>;
    legalAgreementFile: File | null;
    setlegalAgreementFile: React.Dispatch<React.SetStateAction<File | null>>;
    pfmsFile: File | null;
    setpfmsFile: React.Dispatch<React.SetStateAction<File | null>>;
    sanctionFile: File | null;
    setsanctionFile: React.Dispatch<React.SetStateAction<File | null>>;
    traFIle: File | null;
    settraFIle: React.Dispatch<React.SetStateAction<File | null>>;
    listStartups: File | null, 
    setListStartups: React.Dispatch<React.SetStateAction<File | null>>;
    getFilesData :any,
    setGetFilesData:React.Dispatch<React.SetStateAction< any | null>>;
    getFilesData1 :any,
    setGetFilesData1:React.Dispatch<React.SetStateAction< any | null>>;
    getTranche1Data:any,
    setGetTranche1Data: React.Dispatch<React.SetStateAction< any | null>>;
}

interface AuthProviderProps {
    children: React.ReactNode;
}




export const AuthContext = createContext<AuthContextType>({
    auth: null,
    setAuth: () => { }, // This is a default no-op function.
    utilizationFile: null,
    setUtizationFile: () => { },
    auditedFile: null,
    setAuditedFile: () => { },
    interestFile: null,
    setInterestFile: () => { },
    ismcFile: null,
    setIsmcFile: () => { },
    achievementFile: null,
    setachievementFile: () => { },
    debentureFile: null,
    setdebentureFile: () => { },
    disbursedFile: null,
    setdisbursedFile: () => { },
    declarationFile: null,
    setdeclarationFile: () => { },
    legalAgreementFile: null,
    setlegalAgreementFile: () => { },
    pfmsFile: null,
    setpfmsFile: () => { },
    sanctionFile: null,
    setsanctionFile: () => { },
    traFIle: null,
    settraFIle: () => { },
    listStartups: null, 
    setListStartups : () =>{},
    getFilesData:null,
    setGetFilesData: () =>{},
    getFilesData1:null,
    setGetFilesData1: () =>{},
    getTranche1Data:null,
    setGetTranche1Data: () => {},
});


export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    // localStorage.getItem("user") || false
    let user = localStorage.getItem("user") == null ? false : true;
    useEffect(() => { }, [user]);
    const [auth, setAuth] = useState<any>(user);
    const [utilizationFile, setUtizationFile] = useState<any>();
    const [auditedFile, setAuditedFile] = useState<any>();
    const [interestFile, setInterestFile] = useState<any>();
    const [ismcFile, setIsmcFile] = useState<any>();
    const [achievementFile, setachievementFile] = useState<any>();
    const [debentureFile, setdebentureFile] = useState<any>();
    const [disbursedFile, setdisbursedFile] = useState<any>();
    const [declarationFile, setdeclarationFile] = useState<any>();
    const [legalAgreementFile, setlegalAgreementFile] = useState<any>();
    const [pfmsFile, setpfmsFile] = useState<any>();
    const [sanctionFile, setsanctionFile] = useState<any>();
    const [traFIle, settraFIle] = useState<any>();
    const [listStartups, setListStartups] = useState<any>()
    const [getFilesData, setGetFilesData] = useState<Record<string, any> | null>(null);
    const [getFilesData1, setGetFilesData1] = useState<Record<string, any> | null>(null);
    const [getTranche1Data, setGetTranche1Data] = useState<Record<string, any> | null>(null);

    return (
        <AuthContext.Provider value={
            {
                auth, setAuth, 
                utilizationFile,setUtizationFile,
                auditedFile,setAuditedFile,
                interestFile, setInterestFile,
                ismcFile, setIsmcFile,
                achievementFile, setachievementFile,
                debentureFile, setdebentureFile,
                disbursedFile, setdisbursedFile,
                declarationFile, setdeclarationFile,
                legalAgreementFile, setlegalAgreementFile,
                pfmsFile, setpfmsFile,
                sanctionFile, setsanctionFile,
                traFIle, settraFIle,
                listStartups, setListStartups,
                getFilesData, setGetFilesData,
                getFilesData1, setGetFilesData1,
                getTranche1Data, setGetTranche1Data
            }
        }>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
