// export function validateRequiredText(text: string, max: number, min?: number) {
//     // console.log(text.length,"texttt")


//     if(text && text.length!==0){
//         if (min) {
//             if (text.length <= min || text.length >= max) {
//                 return `Length should be between ${min} to ${max} characters`
//             }
//             else
//                 return ""
//         }
//         else {
//             if (text.length >= max) {
//                 return `Length should be less than ${max} characters`
//             }
//             else
//                 return ""
//         }
//     }
//     return `This is mandatory field`
//     } 

export function validateRequiredText(text: any, max: number, min?: number) {
    // console.log(text, "text", typeof text);

    if (text !== undefined && text !== null) {
        const textValue = String(text); // Convert to string

        if (textValue.length !== 0) {
            if (min) {
                if (textValue.length < min || textValue.length > max) {
                    return `Length should be between ${min} to ${max} characters`;
                } else {
                    return "";
                }
            } else {
                if (textValue.length > max) {
                    return `Length should be less than ${max} characters`;
                } else {
                    return "";
                }
            }
        }
    }
    return `This is a mandatory field`;
}









export function validateRequiredText1(text: string, max: number, min?: number) {
    // console.log(text,text.length,"textt")
    if (text && text.length>0) {
        if (min) {
            if (text.length <= min || text.length >= max) {
                return `Length should be between ${min} to ${max} characters`
            }
            else
                return ""
        }
        else {
            if (text.length >= max) {
                return `Length should be less than ${max} characters`
            }
            else
                return ""
        }
    }
    return `This is mandatory field`
}
export function validateMobile(mobile: string) {
    if (mobile && mobile.length !== 0) {
        if (mobile.match(/^\d{10}$/)) {
            return ""
        } else {
            return `Please enter valid mobile number`
        }
    }
    else
        return `This is mandatory field`
}

export function validateMobileNumber(mobile: string) {
    if (mobile !== null) {
        const mobileNo = mobile.toString();
        if (mobileNo && mobileNo.length !== 0) {
            if (mobileNo.match(/^\d{10}$/)) {
                return ""
            } else {
                return `Please enter valid mobile number`
            }
        }
        else
            return `This is mandatory field`
    }
    else
            return `This is mandatory field`
}

export function validateEmail(email: string) {
    if (email && email.length) {
        if (email.match(/[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm)) {
            return ""
        } else {
            return `Please enter valid email address`
        }
    }
    return `This is mandatory field`
}

export function validateNumber(amount: number, max: number) {
    if (amount > 0) {
        if (amount <= max) {
            return ""
        } else {
            return `Please enter valid amount`
        }
    }
    return `This is mandatory field`
}

export function validateNumber1(amount: number, max: number) {
    if (amount > 0) {
        if (amount <= max) {
            return ""
        } else {
            return `Please enter valid amount`
        }
    }
    return `This is mandatory field`
}

export function validateURL(url: string) {
    if (url && url.length) {
        if (url.match(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi)) {
            return ""
        } else {
            return `Please enter valid URL`
        }
    }
    return `This is mandatory field`
}

export function validateSocialURL(url: string) {
    const regex = new RegExp(/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i)
    // const regex = new RegExp()
    if (url && url.length) {
        if (regex.test(url)) {
            return false
        } else {
            return `Please enter valid URL`
        }
    }
    else {
        return `This is mandatory field`
    }
}

export function validateStartupSocialURL(url: string) {
    const regex = new RegExp(/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i)
    // const regex = new RegExp()
    if (url && url.length) {
        if (regex.test(url)) {
            return ""
        } else {
            return `Please enter valid URL`
        }
    }
    else {
        return `This is mandatory field`
    }
}

export function validatePAN(pan: string) {
    const regex = new RegExp(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/)
    if (pan && pan.length) {
        if (pan.match(regex)) {
            return ""
        } else {
            return `Please enter valid PAN number`
        }
    }
    return `Enter valid PAN`
}