import * as Crypto from 'crypto-js';

export const DATA_SECRET = 'sisfs123dash@-_!app';

/**
 * Function for setting the sessionstorage value/'s(in encrypted format)
 * @param key String
 * @param value Any
 */
export const setSessionStorageValue = (key: string, value: any) => {
    const securedata = Crypto.AES.encrypt(value, DATA_SECRET);
    localStorage.setItem(key, securedata as any);  
} 

/** removes the storage for it's key */
export const removeStorage = (key: string) => {
    localStorage.removeItem(key);
}

/**
 * Function for getting the localstorage value(in decrypted format) for the provided key
 * @param key String
 */
export const getSessionStorageValue = (key: string) => {
    const lsValue = localStorage.getItem(key);
   
    if(lsValue) {
        const decryptedValue = Crypto.AES.decrypt(lsValue, DATA_SECRET);

        if(decryptedValue){
            const vv = decryptedValue.toString(Crypto.enc.Utf8)
           
            if(vv){
                return vv;               
            }
            else{
                return null;
            }
            
        }
        else{
            return null;
        }
    } else {
        return null;
    }
}