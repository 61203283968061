import * as React from "react";
import DashboardHeaderComponent from '../../dpiit/common/DashboardHeaderComponent';
import DashboardFooterComponent from '../../common/footer/DashboardFooterComponent';
import "../../../styles/dashboard.css";
import FullIncubatorDetailModal from "../../common/modal/FullIncubatorDetailModal";
import ScheduleMeeting from "../../../images/Schedule-Meeting.svg";
import DashboardDurationComponent from "../../dashboard/DashboardDurationComponent";
import { Link } from "react-router-dom";
import { Line, HorizontalBar, Bar } from "react-chartjs-2";
import "../../../styles/component.css";
import error from "../../../../images/error.svg";
import {
  GET_DPIIT_DASHBOARD_COUNT,
  PENDING_INCUBATOR_COUNT,
  UPDATE_MEETING,
  GET_DPIIT_DASHBOARD_CHART_COUNT,
  SCHEDULE_MEETING_COUNT,
  GET_MEETING_DETAILS,
  GET_ALL_INCUBATORS,
  GRAPH_NODE,
  GET_DPIIT_DASHBOARD_CHART_COUNT_STARTUP,
  GET_TOP_INCUBATORS,
  GRAPH_BAR,
  SEC_DASHBOARD_COUNT,
  INCUBATOR_APPLICATION_COUNT,
  GET_ALL_INCUBATORS_NODE,
} from "../../../constants/api";
import { get, post } from "../../../effects/api/api.common";
import { useEffect, useState } from "react";
import { formatTime, compareTime } from "../../../utils/DateFormat";
import Calendar from "../../common/controls/Calendar";
import CalendarIcon from "../../../images/calendar.svg";
import schedulemeetingarrow from "../../../images/schedulemeetingarrow.svg";
import ConfirmationDialog from "../../common/controls/ConfirmationDialog";
import moment, { duration } from "moment";
import { getSessionStorageValue } from "../../../effects/utils/session-storage";
import ReactDatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";


const dummy_data = {
  labels: [
    "Submitted",
    "Incomplete",
    "To be Reviewed",
    "On Hold",
    "Rejected",
    "Selected",
  ],
  datasets: [
    {
      backgroundColor: "#647FC2",
      borderWidth: 1,
      hoverBackgroundColor: "rgba(255,99,132,0.4)",
      hoverBorderColor: "rgba(255,99,132,1)",
      data: [1567, 124, 121, 88, 456, 998],
    },
  ],
};
const options_horizontal = {
  scales: {
    xAxes: [
      {
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
          drawBorder: false,
          zeroLineColor: "transparent",
        },
        display: false,
      },
    ],
    yAxes: [
      {
        margin: 30,
        offset: true,
        ticks: {
          fontFamily: "Open Sans",
          fontSize: 13,
        },
        barPercentage: 1.0,
        categoryPercentage: 0.4,
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
          drawBorder: false,
          zeroLineColor: "transparent",
        },
      },
    ],
  },
  legend: {
    display: false,
  },
  maintainAspectRatio: false,
};

const state_Bar = {
  labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
  datasets: [
    {
      label: "Funds Approved",
      type: "bar",
      backgroundColor: "rgba(159,207,239,1)",
      data: ["6100000", "4900000", "3100000", "3800000"],
      stack: 1,
    },
    {
      label: "Grants",
      backgroundColor: "rgba(107,132,198,1)",
      data: ["600000", "610000", "600000", "600000"],
      stack: 2,
    },
    {
      label: "Debenture",
      backgroundColor: "rgba(179,189,235,1)",
      data: ["2400000", "2500000", "2600000", "2700000"],
      stack: 2,
    },
    {
      label: "Debt",
      backgroundColor: "rgba(218,223,245,1)",
      data: ["1000000", "600000", "700000", "200000"],
      stack: 2,
    },
  ],
};
const options_Bar = {
  legend: {
    position: "top",
    align: "end",
    labels: {
      usePointStyle: true,
      boxWidth: 6,
    },
  },
  scales: {
    yAxes: [
      {
        id: "stacked_testY",
        type: "linear",
        position: "left",
        stacked: true,
        display: true,
        barPercentage: 0.7,
        scaleLabel: {
          display: true,
          labelString: "Amount Disbursed",
          fontSize: 14,
          fontFamily: "Roboto Slab",
          fontColor: "#333333",
        },
      },
    ],
    xAxes: [
      {
        position: "bottom",
        stacked: true,
        display: true,
        barPercentage: 0.7,
        scaleLabel: {
          display: true,
          labelString: "Weeks",
          fontSize: 14,
          fontFamily: "Roboto Slab",
          fontColor: "#333333",
        },
      },
    ],
  },
};
// const state_Line = {
//   labels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20"],
//   datasets: [
//     {
//       label: 'Funds Disbursed', // Name the series
//       data: [800000, 900000, 1100000, 1300000, 2000000, 1500000, 900000, 600000, 400000, 1500000, 2400000, 1600000, 900000, 1700000, 2500000, 1200000, 1000000, 200000, 400000, 600000], // Specify the data values array
//       fill: true,
//       borderColor: '#647FC2', // Add custom color border (Line)
//       backgroundColor: 'rgba(228,215,240,0.6)', // Add custom color background (Points and Fill)
//       borderWidth: 1 // Specify bar border width
//     },
//     {
//       label: 'Funds Approved', // Name the series
//       data: [1500000, 1600000, 1900000, 2400000, 3100000, 2700000, 2500000, 1300000, 900000, 3000000, 3800000, 2600000, 2000000, 2500000, 3000000, 2200000, 1500000, 900000, 600000, 500000], // Specify the data values array
//       fill: true,
//       borderColor: '#2196f3', // Add custom color border (Line)
//       backgroundColor: 'rgba(83,168,226,0.1)', // Add custom color background (Points and Fill)
//       borderWidth: 1 // Specify bar border width
//     },
//   ]
// };
const options_Line = {
  tooltips: {
    mode: "index",
    intersect: false,
  },
  hover: {
    mode: "index",
    intersect: false,
  },
  elements: {
    point: {
      radius: 0,
    },
  },
  legend: {
    position: "top",
    align: "end",
    labels: {
      usePointStyle: true,
      boxWidth: 6,
    },
  },
  scales: {
    yAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Amount Disbursed",
          fontSize: 14,
          fontFamily: "Roboto Slab",
          fontColor: "#333333",
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          display: false, //this will remove only the label
        },
        scaleLabel: {
          display: true,
          labelString: "Date",
          fontSize: 14,
          fontFamily: "Roboto Slab",
          fontColor: "#333333",
        },
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        },
      },
    ],
  },
  responsive: true, // Instruct chart js to respond nicely.
  maintainAspectRatio: true, // Add to prevent default behaviour of full-width/height
};

const options_Line_Startup = {
  tooltips: {
    mode: "index",
    intersect: false,
  },
  hover: {
    mode: "index",
    intersect: false,
  },
  elements: {
    point: {
      radius: 0,
    },
  },
  legend: {
    position: "top",
    align: "end",
    labels: {
      usePointStyle: true,
      boxWidth: 6,
    },
  },
  scales: {
    yAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Amount Disbursed",
          fontSize: 14,
          fontFamily: "Roboto Slab",
          fontColor: "#333333",
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          display: false, //this will remove only the label
        },
        scaleLabel: {
          display: true,
          labelString: "Date",
          fontSize: 14,
          fontFamily: "Roboto Slab",
          fontColor: "#333333",
        },
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        },
      },
    ],
  },
  responsive: true, // Instruct chart js to respond nicely.
  maintainAspectRatio: true, // Add to prevent default behaviour of full-width/height
};
function EacDashboardContainer() {
  const [isDisabled, setDisabled] = useState(false);
  const [showDateRangeDialog, setShowDateRangeDialog] = useState(false);
  const [rangeCardName, setRangeCardName] = useState("");
  const [loader, setLoader] = useState(false);
  const [cardDateRange, setCardDateRange] = useState({
    fromDate: new Date(),
    toDate: new Date(),
  });
  const [chartDateRange, setChartDateRange] = useState({
    fromDate: new Date(),
    toDate: new Date(),
  });
  const [incubatorDateRange, setIncubatorDateRange] = useState({
    fromDate: new Date(),
    toDate: new Date(),
  });
  const [startupDateRange, setStartupDateRange] = useState({
    fromDate: new Date(),
    toDate: new Date(),
  });
  const [topDateRange, setTopDateRange] = useState({
    fromDate: new Date(),
    toDate: new Date(),
  });
  const [rangePopupData, setRangePopupData] = useState({
    fromDate: new Date(),
    toDate: new Date(),
    minDate: new Date(),
  });
  const history = useHistory();

  const [isEditable, setIsEditable] = useState(true);
  const [upcomingMeetingData, setupcomingMeetingData] = useState({
    meeting_date: "",
    meeting_id: "",
    meeting_from_time: "",
    meeting_to_time: "",
    meeting_type: "",
    created_at: "",
  });
  const [showIncubatorSuccessModal, setShowIncubatorSuccessModal] =
    useState(false);
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [showIncubatorFailureModal, setShowIncubatorFailureModal] =
    useState(false);
  const [activeMainSelect, setActiveMainSelect] = useState("till_date");
  const [activeChartSelect, setActiveChartSelect] = useState("till_date");
  const [activeChartSelectStartup, setActiveChartSelectStartup] =
    useState("till_date");
  const [activeChart, setActiveChart] = useState("tillDate");
  const [activeTopIncubator, setActiveTopIncubator] = useState("till_date");
  const [stateHorizontal, setStateHorizontal] = useState({
    labels: [
      "Submitted",
      "Incomplete",
      "To be Reviewed",
      "On Hold",
      "Rejected",
      "Selected",
      "Closed",
      "Cancelled",
    ],
    datasets: [
      {
        backgroundColor: "#647FC2",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255,99,132,0.4)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: [0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
  });
  const [stateBar, setStateBar] = useState({
    labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
    datasets: [
      {
        label: "Funds Approved",
        type: "bar",
        backgroundColor: "rgba(159,207,239,1)",
        data: ["6100000", "4900000", "3100000", "3800000"],
        stack: 1,
      },
      {
        label: "Grants",
        backgroundColor: "rgba(107,132,198,1)",
        data: ["600000", "610000", "600000", "600000"],
        stack: 2,
      },
      {
        label: "Debenture",
        backgroundColor: "rgba(179,189,235,1)",
        data: ["2400000", "2500000", "2600000", "2700000"],
        stack: 2,
      },
      {
        label: "Debt",
        backgroundColor: "rgba(218,223,245,1)",
        data: ["1000000", "600000", "700000", "200000"],
        stack: 2,
      },
    ],
  });
  const [stateHorizontalStartup, setStateHorizontalStartup] = useState({
    labels: ["Submitted", "Rejected", "Selected", "Closed", "Cancelled"],
    datasets: [
      {
        backgroundColor: "#647FC2",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255,99,132,0.4)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: [0, 0, 0, 0, 0],
      },
    ],
  });
  const [stateLine, setStateLine] = useState({
    // labels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20"],
    labels: [],
    datasets: [
      {
        label: "Funds Disbursed", // Name the series
        // data: [800000, 900000, 1100000, 1300000, 2000000, 1500000, 900000, 600000, 400000, 1500000, 2400000, 1600000, 900000, 1700000, 2500000, 1200000, 1000000, 200000, 400000, 600000], // Specify the data values array
        data: [],
        fill: true,
        borderColor: "#647FC2", // Add custom color border (Line)
        backgroundColor: "rgba(228,215,240,0.6)", // Add custom color background (Points and Fill)
        borderWidth: 1, // Specify bar border width
        steppedLine: true,
      },
      {
        label: "Funds Approved", // Name the series
        // data: [1500000, 1600000, 1900000, 2400000, 3100000, 2700000, 2500000, 1300000, 900000, 3000000, 3800000, 2600000, 2000000, 2500000, 3000000, 2200000, 1500000, 900000, 600000, 500000], // Specify the data values array
        data: [],
        fill: true,
        borderColor: "#2196f3", // Add custom color border (Line)
        backgroundColor: "rgba(83,168,226,0.1)", // Add custom color background (Points and Fill)
        borderWidth: 1, // Specify bar border width
        steppedLine: true,
      },
    ],
  });

  const [stateLineStartup, setStateLineStartup] = useState({
    // labels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20"],
    labels: [],
    datasets: [
      {
        label: "Funds Approved", // Name the series
        // data: [1500000, 1600000, 1900000, 2400000, 3100000, 2700000, 2500000, 1300000, 900000, 3000000, 3800000, 2600000, 2000000, 2500000, 3000000, 2200000, 1500000, 900000, 600000, 500000], // Specify the data values array
        data: [],
        fill: true,
        borderColor: "#2196f3", // Add custom color border (Line)
        backgroundColor: "rgba(83,168,226,0.1)", // Add custom color background (Points and Fill)
        borderWidth: 1, // Specify bar border width
        steppedLine: true,
      },
      {
        label: "Grants", // Name the series
        // data: [1500000, 1600000, 1900000, 2400000, 3100000, 2700000, 2500000, 1300000, 900000, 3000000, 3800000, 2600000, 2000000, 2500000, 3000000, 2200000, 1500000, 900000, 600000, 500000], // Specify the data values array
        data: [],
        fill: true,
        borderColor: "#2196f3", // Add custom color border (Line)
        backgroundColor: "rgba(107,132,198,1)", // Add custom color background (Points and Fill)
        borderWidth: 1, // Specify bar border width
        steppedLine: true,
      },
      {
        label: "Debenture", // Name the series
        // data: [800000, 900000, 1100000, 1300000, 2000000, 1500000, 900000, 600000, 400000, 1500000, 2400000, 1600000, 900000, 1700000, 2500000, 1200000, 1000000, 200000, 400000, 600000], // Specify the data values array
        data: [],
        fill: true,
        borderColor: "#647FC2", // Add custom color border (Line)
        backgroundColor: "rgba(179,189,235,1)", // Add custom color background (Points and Fill)
        borderWidth: 1, // Specify bar border width
        steppedLine: true,
      },
      {
        label: "Debt", // Name the series
        // data: [800000, 900000, 1100000, 1300000, 2000000, 1500000, 900000, 600000, 400000, 1500000, 2400000, 1600000, 900000, 1700000, 2500000, 1200000, 1000000, 200000, 400000, 600000], // Specify the data values array
        data: [],
        fill: true,
        borderColor: "#647FC2", // Add custom color border (Line)
        backgroundColor: "rgba(218,223,245,1)", // Add custom color background (Points and Fill)
        borderWidth: 1, // Specify bar border width
        steppedLine: true,
      },
    ],
  });
  const [dpiitDashboardCount, setDpiitDashboardCount] = useState<any>({
    submittedIncubationForms: "0",
    approvedIncubationForms: "0",
    cancelledIncubationForms: "0",
    submittedStartupForms: "0",
    approvedStartupForms: "0",
  });
  const [incubatorDetails, setIncubatorDetails] = useState([]);
  const [topIncubators, setTopIncubators] = useState([]);
  const [fromTime, setFromTime] = useState("");
  const [meetingType, setMeetingType] = useState("1");
  const [toTime, setToTime] = useState("");
  const [scheduleDate, setScheduleDate] = useState("");
  const [meetingFilter, setMeetingFilter] = useState("");
  const [meetingLink, setMeetingLink] = useState("");
  const [timeValidation, setTimeValidation] = useState(false);
  const [linkValidation, setLinkValidation] = useState(false);
  const [timeValidationCurrentTime, setTimeValidationCurrentTime] =
    useState(false);
  const [selectedDateControl, setSelectedDateControl] = useState(new Date());
  const [count, setCount] = useState({
    Tomorrow: "",
    This_week: "",
    Next_week: "",
    Next_month: "",
    Next_2_months: "",
  });
  const [dialogData, setDialogData] = useState({
    header: "",
    text: "",
  });
  const [failDialogData, setFailDialogData] = useState({
    header: "",
    text: "",
  });
  const [confirmationDialogData, setConfirmationDialogData] = useState({
    header: "",
    text: "",
  });
  const [incubatorPending, setIncubatorPending] = useState(Number);
  const [isVisible, setIsVisible] = useState(false);
  const [loggedInRole, setLoggedInRole] = useState("");
  const [bucketCout, setBucketCount] = useState<any>('')
  const user: any = getSessionStorageValue("user");
  const userInfo = JSON.parse(user);
  useEffect(() => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    setLoggedInRole(userInfo.roles);
    window.scrollTo(0, 0);
    getDashboardCount("till_date");
    // getDashboardChartCount("till_date");
    // getChartCount("tillDate");
    // getDashboardChartCountStartup("till_date");
    // getMeetingCount();
    // getMeetingDetails();
    // getIncubatorCount();
    // getTopIncubators("till_date");
    // getSecDashboardCount("till_date");
  }, []);


  const getDashboardCount = (duration: string) => {
    const payload = {
      duration: duration,
      "roles": userInfo.roles,
      "logged_user_id": userInfo.id
    }
    post(GET_DPIIT_DASHBOARD_COUNT, payload).then(
      (response: any) => {
        if (!response || !response.data) return;
        // console.log(response.data.data.cancellationApprovedIncubatorForms)
        const dataCount = {
          submittedIncubationForms: response.data.Data.submittedIncubationForms,
          approvedIncubationForms: response.data.Data.approvedIncubationForms,
          cancelledIncubationForms: response.data.Data.cancellationApprovedIncubatorForms,
          submittedStartupForms: response.data.Data.submittedStartupForms,
          approvedStartupForms: response.data.Data.approvedStartupForms,

        };
        setDpiitDashboardCount(dataCount);
      }
    );
    getSecDashboardCount("till_date");
  };

  const getSecDashboardCount = (duration: string) => {

    console.log(duration);

    const payload = {
      duration: duration
    }

    post(SEC_DASHBOARD_COUNT, payload).then((res: any) => {
      // console.log(res.data.Data)
      setBucketCount(res.data.Data)
    })
    getMeetingDetails();
  }

  const getMeetingDetails = () => {
    get(GET_MEETING_DETAILS).then((response: any) => {
      if (!response || !response.data) return;
      // console.log(response.data,"meetingDataaa7777");
      setupcomingMeetingData({
        meeting_date:
          response.data.upcoming.length != 0
            ? response.data.upcoming[0].meeting_date
            : "",
        meeting_id:
          response.data.upcoming.length != 0
            ? response.data.upcoming[0].id
            : "",
        meeting_from_time:
          response.data.upcoming.length != 0
            ? response.data.upcoming[0].meeting_from_time
            : "",
        meeting_to_time:
          response.data.upcoming.length != 0
            ? response.data.upcoming[0].meeting_to_time
            : "",
        meeting_type:
          response.data.upcoming.length != 0
            ? response.data.upcoming[0].meeting_type
            : "",
        created_at:
          response.data.upcoming.length != 0
            ? response.data.upcoming[0].created_at
            : "",
      });
    });
    getTopIncubators("till_date");
  };
  const getTopIncubators = (duration: string) => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    var payload = {
      "roles": userInfo.roles,
      "user_id": userInfo.id
    }
    post(`${GET_TOP_INCUBATORS}?duration=${duration}`, payload).then((response: any) => {
      if (!response || !response.data) return;
      setTopIncubators(response.data.data);
    });
    getIncubatorCount();
  };

  const getIncubatorCount = () => {
    get(PENDING_INCUBATOR_COUNT).then((response: any) => {
      if (!response || !response.data) return;

      setIncubatorPending(parseInt(response.data.data.count));
    });
    getMeetingCount();
  };

  const getMeetingCount = () => {
    get(SCHEDULE_MEETING_COUNT).then((response: any) => {
      if (!response || !response.data) return;
      // console.log(response.data,"data!!!!");
      setCount(response.data.Data);
    });
    getDashboardChartCount("till_date");
  };

  const getDashboardChartCount = (duration: string) => {
    // get(`${GET_DPIIT_DASHBOARD_CHART_COUNT}?duration=${duration}`).then(
    //   (response: any) => {
    //     console.log(response)
    //     if (!response || !response.data) return;
    //     setStateHorizontal({
    //       labels: [
    //         "Submitted",
    //         "Incomplete",
    //         "To be Reviewed",
    //         "On Hold",
    //         "Rejected",
    //         "Approved",
    //         "Closed",
    //         "Cancelled",
    //       ],
    //       datasets: [
    //         {
    //           backgroundColor: "#647FC2",
    //           borderWidth: 1,
    //           hoverBackgroundColor: "rgba(255,99,132,0.4)",
    //           hoverBorderColor: "rgba(255,99,132,1)",
    //           data: [
    //             parseInt(response.data.data.data.submitted),
    //             parseInt(response.data.data.data.incomplete),
    //             parseInt(response.data.data.data.pending_review),
    //             parseInt(response.data.data.data.on_hold),
    //             parseInt(response.data.data.data.rejected),
    //             parseInt(response.data.data.data.selected),
    //             parseInt(response.data.data.data.closed),
    //             parseInt(response.data.data.data.cancelled),
    //           ],
    //         },
    //       ],
    //     });
    //   }
    // );
    const payload = {
      duration: duration
    }
    post(INCUBATOR_APPLICATION_COUNT, payload).then((res: any) => {
      // console.log(res.data.Data)
      var sum = 0;
      var sum1 = 0;
      var sum2 = 0;
      var sum3 = 0;
      var sum4 = 0;
      var sum5 = 0;
      var sum6 = 0;
      var sum7 = 0;
      for (var i = 0; i < res.data.Data.length; i++) {

        if (res.data.Data[i].id === 5) {
          sum += parseInt(res.data.Data[i].appln_count)
          // console.log(typeof(sum))
        }
        else if (res.data.Data[i].id === 7) {
          sum1 += parseInt(res.data.Data[i].appln_count)
          // console.log(sum1)
        }
        else if (res.data.Data[i].id === 4) {
          sum2 += parseInt(res.data.Data[i].appln_count)
          // console.log(sum2)
        }
        else if (res.data.Data[i].id === 3) {
          sum3 += parseInt(res.data.Data[i].appln_count)
          // console.log(sum3)
        }
        else if (res.data.Data[i].id === 6) {
          sum4 += parseInt(res.data.Data[i].appln_count)
          // console.log(sum4)
        }
        else if (res.data.Data[i].id === 8) {
          sum5 += parseInt(res.data.Data[i].appln_count)
          // console.log(sum5)
        }
        else if (res.data.Data[i].id === 9) {
          sum6 += parseInt(res.data.Data[i].appln_count)
          // console.log(sum6)
        }
        else if (res.data.Data[i].id === 2) {
          sum7 += parseInt(res.data.Data[i].appln_count)
          // console.log(sum7)
        }
      }


      setStateHorizontal({
        labels: [
          "Submitted",
          "Incomplete",
          "To be Reviewed",
          "On Hold",
          "Rejected",
          "Selected",
          "Closed",
          "Cancelled",
        ],
        datasets: [
          {
            backgroundColor: "#647FC2",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(255,99,132,0.4)",
            hoverBorderColor: "rgba(255,99,132,1)",
            data: [

              sum7,        //submitted
              sum2,     //incomplete
              sum3,     //reviewed
              sum,      //onHold
              sum4,     //rejected
              sum1,     //approved
              sum5,      //closed
              sum6,      //cancelled
              // parseInt(res.data.Data.incomplete),
              // parseInt(res.data.Data.pending_review),
              // parseInt(res.data.Data.on_hold),
              // parseInt(res.data.Data.rejected),
              // parseInt(res.data.Data.selected),
              // parseInt(res.data.Data.closed),
              // parseInt(res.data.Data.cancelled),
            ],
          },
        ],
      });
    })
    getDashboardChartCountStartup("till_date");
  };
  const getDashboardChartCountStartup = (duration: string) => {
    const payload = {
      "user_id": userInfo.id,
      "roles": userInfo.roles
    }
    post(`${GET_DPIIT_DASHBOARD_CHART_COUNT_STARTUP}?duration=${duration}`, payload).then(
      (response: any) => {
        if (!response || !response.data) return;
        setStateHorizontalStartup({
          labels: ["Submitted", "Rejected", "Selected", "Closed", "Cancelled"],
          datasets: [
            {
              backgroundColor: "#647FC2",
              borderWidth: 1,
              hoverBackgroundColor: "rgba(255,99,132,0.4)",
              hoverBorderColor: "rgba(255,99,132,1)",
              data: [
                parseInt(response.data.data.data.submitted),
                parseInt(response.data.data.data.rejected),
                parseInt(response.data.data.data.selected),
                parseInt(response.data.data.data.closed),
                parseInt(response.data.data.data.cancelled),
              ],
            },
          ],
        });
      }
    );
    getChartCount("tillDate");
  };

  const getChartCount = (duration: string) => {
    post(`${GRAPH_NODE}?duration=${duration}`, "").then((response: any) => {
      if (!response || !response.data) return;
      // console.log(response.data.data);
      setStateLine({
        labels: response.data.data.labels,
        datasets: [
          {
            label: "Funds Disbursed", // Name the series
            data: response.data.data.funds_disbursed, // Specify the data values array
            fill: true,
            borderColor: "#647FC2", // Add custom color border (Line)
            backgroundColor: "rgba(228,215,240,0.6)", // Add custom color background (Points and Fill)
            borderWidth: 1, // Specify bar border width
            steppedLine: true,
          },
          {
            label: "Funds Approved", // Name the series
            data: response.data.data.funds_approved, // Specify the data values array
            fill: true,
            borderColor: "#2196f3", // Add custom color border (Line)
            backgroundColor: "rgba(83,168,226,0.1)", // Add custom color background (Points and Fill)
            borderWidth: 1, // Specify bar border width
            steppedLine: true,
          },
        ],
      });
    });
    setLoader(true);
    get(`${GRAPH_BAR}?duration=${duration}`).then((response: any) => {
      setLoader(false);
      if (!response || !response.data) return;
      setStateLineStartup({
        labels: response.data.data.labels,
        // labels: [],
        datasets: [
          {
            label: "Funds Approved", // Name the series
            data: response.data.data.funds_approved, // Specify the data values array
            // data: [],
            fill: true,
            borderColor: "#2196f3", // Add custom color border (Line)
            backgroundColor: "rgba(83,168,226,0.1)", // Add custom color background (Points and Fill)
            borderWidth: 1, // Specify bar border width
            steppedLine: true,
          },
          {
            label: "Grants", // Name the series
            data: response.data.data.funds_disbursed_grant, // Specify the data values array
            // data: [],
            fill: true,
            borderColor: "#2196f3", // Add custom color border (Line)
            backgroundColor: "rgba(107,132,198,1)", // Add custom color background (Points and Fill)
            borderWidth: 1, // Specify bar border width
            steppedLine: true,
          },
          {
            label: "Debenture", // Name the series
            data: response.data.data.funds_disbursed_debenture, // Specify the data values array
            // data: [],
            fill: true,
            borderColor: "#647FC2", // Add custom color border (Line)
            backgroundColor: "rgba(179,189,235,1)", // Add custom color background (Points and Fill)
            borderWidth: 1, // Specify bar border width
            steppedLine: true,
          },
          {
            label: "Debt", // Name the series
            data: response.data.data.funds_disbursed_debt, // Specify the data values array
            // data: [],
            fill: true,
            borderColor: "#647FC2", // Add custom color border (Line)
            backgroundColor: "rgba(218,223,245,1)", // Add custom color background (Points and Fill)
            borderWidth: 1, // Specify bar border width
            steppedLine: true,
          },
        ],
      });
      // setStateBar({
      //   labels: response.data.data.labels,
      //   datasets: [
      //     {
      //       label: "Funds Approved",
      //       type: "bar",
      //       backgroundColor: "rgba(159,207,239,1)",
      //       data: response.data.data.funds_approved,
      //       stack: 1
      //     }, {
      //       label: "Grants",
      //       backgroundColor: "rgba(107,132,198,1)",
      //       data: response.data.data.funds_disbursed_grant,
      //       stack: 2,
      //     }, {
      //       label: "Debenture",
      //       backgroundColor: "rgba(179,189,235,1)",
      //       data: response.data.data.funds_disbursed_debenture,
      //       stack: 2
      //     }, {
      //       label: "Debt",
      //       backgroundColor: "rgba(218,223,245,1)",
      //       data: response.data.data.funds_disbursed_debt,
      //       stack: 2
      //     }]
      // });
    });
  };

  // function to format date
  function formatDate(date: Date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  // trrigers on date change
  const handleDateChange = (date: Date, field: string) => {
    setScheduleDate(formatDate(date));
    setSelectedDateControl(date);
  };
  const handleRangeDateChange = (date: Date, field: string) => {
    if (rangeCardName == "card") {
      if (field == "fromDate") {
        setCardDateRange((prevState) => ({
          ...prevState,
          fromDate: date,
        }));
        setRangePopupData((prevState) => ({
          ...prevState,
          fromDate: date,
          minDate: date,
        }));
      } else {
        setCardDateRange((prevState) => ({
          ...prevState,
          toDate: date,
        }));
        setRangePopupData((prevState) => ({
          ...prevState,
          toDate: date,
        }));
      }
    }
    if (rangeCardName == "chart") {
      if (field == "fromDate") {
        setChartDateRange((prevState) => ({
          ...prevState,
          fromDate: date,
        }));
        setRangePopupData((prevState) => ({
          ...prevState,
          fromDate: date,
          minDate: date,
        }));
      } else {
        setChartDateRange((prevState) => ({
          ...prevState,
          toDate: date,
        }));
        setRangePopupData((prevState) => ({
          ...prevState,
          toDate: date,
        }));
      }
    }
    if (rangeCardName == "incubator") {
      if (field == "fromDate") {
        setIncubatorDateRange((prevState) => ({
          ...prevState,
          fromDate: date,
        }));
        setRangePopupData((prevState) => ({
          ...prevState,
          fromDate: date,
          minDate: date,
        }));
      } else {
        setIncubatorDateRange((prevState) => ({
          ...prevState,
          toDate: date,
        }));
        setRangePopupData((prevState) => ({
          ...prevState,
          toDate: date,
        }));
      }
    }
    if (rangeCardName == "startup") {
      if (field == "fromDate") {
        setStartupDateRange((prevState) => ({
          ...prevState,
          fromDate: date,
        }));
        setRangePopupData((prevState) => ({
          ...prevState,
          fromDate: date,
          minDate: date,
        }));
      } else {
        setStartupDateRange((prevState) => ({
          ...prevState,
          toDate: date,
        }));
        setRangePopupData((prevState) => ({
          ...prevState,
          toDate: date,
        }));
      }
    }
    if (rangeCardName == "top") {
      if (field == "fromDate") {
        setTopDateRange((prevState) => ({
          ...prevState,
          fromDate: date,
        }));
        setRangePopupData((prevState) => ({
          ...prevState,
          fromDate: date,
          minDate: date,
        }));
      } else {
        setTopDateRange((prevState) => ({
          ...prevState,
          toDate: date,
        }));
        setRangePopupData((prevState) => ({
          ...prevState,
          toDate: date,
        }));
      }
    }
  };
  const getRangeData = () => {
    if (rangeCardName == "card") {
      const payload = {
        startDate: moment(
          cardDateRange.fromDate
        ).format("YYYY-MM-DD"),
        endDate: moment(cardDateRange.toDate).format(
          "YYYY-MM-DD"
        )
      }
      post(GET_DPIIT_DASHBOARD_COUNT, payload).then((response: any) => {
        if (!response || !response.data) return;
        const dataCount = {
          submittedIncubationForms: response.data.Data.submittedIncubationForms,
          approvedIncubationForms: response.data.Data.approvedIncubationForms,
          cancelledIncubationForms: response.data.Data.cancellationApprovedIncubatorForms,

          submittedStartupForms: response.data.Data.submittedStartupForms,
          approvedStartupForms: response.data.Data.approvedStartupForms,
        };
        setDpiitDashboardCount(dataCount);
        setShowDateRangeDialog(false);
        setActiveMainSelect("");
      });
    }
    if (rangeCardName == "chart") {
      post(
        `${GRAPH_NODE}?startDate=${moment(chartDateRange.fromDate).format(
          "YYYY-MM-DD"
        )}&endDate=${moment(chartDateRange.toDate).format("YYYY-MM-DD")}`, ""
      ).then((response: any) => {
        if (!response || !response.data) return;
        // console.log(response.data.data);
        setStateLine({
          labels: response.data.data.labels,
          datasets: [
            {
              label: "Funds Disbursed", // Name the series
              data: response.data.data.funds_disbursed, // Specify the data values array
              fill: true,
              borderColor: "#647FC2", // Add custom color border (Line)
              backgroundColor: "rgba(228,215,240,0.6)", // Add custom color background (Points and Fill)
              borderWidth: 1, // Specify bar border width
              steppedLine: true,
            },
            {
              label: "Funds Approved", // Name the series
              data: response.data.data.funds_approved, // Specify the data values array
              fill: true,
              borderColor: "#2196f3", // Add custom color border (Line)
              backgroundColor: "rgba(83,168,226,0.1)", // Add custom color background (Points and Fill)
              borderWidth: 1, // Specify bar border width
              steppedLine: true,
            },
          ],
        });
        setShowDateRangeDialog(false);
        setActiveChart("");
      });
      get(
        `${GRAPH_BAR}?startDate=${moment(chartDateRange.fromDate).format(
          "YYYY-MM-DD"
        )}&endDate=${moment(chartDateRange.toDate).format("YYYY-MM-DD")}`
      ).then((response: any) => {
        if (!response || !response.data) return;
        setStateLineStartup({
          labels: response.data.data.labels,
          // labels: [],
          datasets: [
            {
              label: "Funds Approved", // Name the series
              data: response.data.data.funds_approved, // Specify the data values array
              // data: [],
              fill: true,
              borderColor: "#2196f3", // Add custom color border (Line)
              backgroundColor: "rgba(83,168,226,0.1)", // Add custom color background (Points and Fill)
              borderWidth: 1, // Specify bar border width
              steppedLine: true,
            },
            {
              label: "Grants", // Name the series
              data: response.data.data.funds_disbursed_grant, // Specify the data values array
              // data: [],
              fill: true,
              borderColor: "#2196f3", // Add custom color border (Line)
              backgroundColor: "rgba(107,132,198,1)", // Add custom color background (Points and Fill)
              borderWidth: 1, // Specify bar border width
              steppedLine: true,
            },
            {
              label: "Debenture", // Name the series
              data: response.data.data.funds_disbursed_debenture, // Specify the data values array
              // data: [],
              fill: true,
              borderColor: "#647FC2", // Add custom color border (Line)
              backgroundColor: "rgba(179,189,235,1)", // Add custom color background (Points and Fill)
              borderWidth: 1, // Specify bar border width
              steppedLine: true,
            },
            {
              label: "Debt", // Name the series
              data: response.data.data.funds_disbursed_debt, // Specify the data values array
              // data: [],
              fill: true,
              borderColor: "#647FC2", // Add custom color border (Line)
              backgroundColor: "rgba(218,223,245,1)", // Add custom color background (Points and Fill)
              borderWidth: 1, // Specify bar border width
              steppedLine: true,
            },
          ],
        });
      });
    }
    if (rangeCardName == "incubator") {
      const user: any = getSessionStorageValue("user");
      const userInfo = JSON.parse(user)
      var payload1 = {
        "user_id": userInfo.id
      }
      post(
        `${GET_DPIIT_DASHBOARD_CHART_COUNT}?startDate=${moment(
          incubatorDateRange.fromDate
        ).format("YYYY-MM-DD")}&endDate=${moment(
          incubatorDateRange.toDate
        ).format("YYYY-MM-DD")}`, payload1
      ).then((response: any) => {
        if (!response || !response.data) return;
        setStateHorizontal({
          labels: [
            "Submitted",
            "Incomplete",
            "To be Reviewed",
            "On Hold",
            "Rejected",
            "Selected",
            "Closed",
            "Cancelled",
          ],
          datasets: [
            {
              backgroundColor: "#647FC2",
              borderWidth: 1,
              hoverBackgroundColor: "rgba(255,99,132,0.4)",
              hoverBorderColor: "rgba(255,99,132,1)",
              data: [
                parseInt(response.data.data.data.submitted),
                parseInt(response.data.data.data.incomplete),
                parseInt(response.data.data.data.pending_review),
                parseInt(response.data.data.data.on_hold),
                parseInt(response.data.data.data.rejected),
                parseInt(response.data.data.data.selected),
                parseInt(response.data.data.data.closed),
                parseInt(response.data.data.data.cancelled),
              ],
            },
          ],
        });
        setShowDateRangeDialog(false);
        setActiveChartSelect("");
      });
    }
    if (rangeCardName == "startup") {
      const payload = {
        "user_id": userInfo.id,
        "roles": userInfo.roles
      }
      post(
        `${GET_DPIIT_DASHBOARD_CHART_COUNT_STARTUP}?startDate=${moment(
          startupDateRange.fromDate
        ).format("YYYY-MM-DD")}&endDate=${moment(
          startupDateRange.toDate
        ).format("YYYY-MM-DD")}`, payload
      ).then((response: any) => {
        if (!response || !response.data) return;
        setStateHorizontalStartup({
          labels: ["Submitted", "Rejected", "Selected", "Closed", "Cancelled"],
          datasets: [
            {
              backgroundColor: "#647FC2",
              borderWidth: 1,
              hoverBackgroundColor: "rgba(255,99,132,0.4)",
              hoverBorderColor: "rgba(255,99,132,1)",
              data: [
                parseInt(response.data.data.data.submitted),
                parseInt(response.data.data.data.rejected),
                parseInt(response.data.data.data.selected),
                parseInt(response.data.data.data.closed),
                parseInt(response.data.data.data.cancelled),
              ],
            },
          ],
        });
        setShowDateRangeDialog(false);
        setActiveChartSelectStartup("");
      });
    }
    if (rangeCardName == "top") {
      const user: any = getSessionStorageValue("user");
      const userInfo = JSON.parse(user)
      var payload = {
        "roles": userInfo.roles,
        "user_id": userInfo.id
      }
      post(
        `${GET_TOP_INCUBATORS}?startDate=${moment(topDateRange.fromDate).format(
          "YYYY-MM-DD"
        )}&endDate=${moment(topDateRange.toDate).format("YYYY-MM-DD")}`, payload
      ).then((response: any) => {
        if (!response || !response.data) return;
        setTopIncubators(response.data.data);
        setShowDateRangeDialog(false);
        setActiveTopIncubator("");
      });
    }
  };

  //  show hide radio buttons on select dropdown
  const handleOnChange = (e: any /*index:number*/) => {
    const value = e.target.value;
    setMeetingType(value);
    if (value == "2") {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // on change time;
  const onTimeChange = (e: any /*index:number*/) => {
    var value = e.target.value;
    var name = e.target.name;
    if (name == "fromTime") {
      setFromTime(value);
      if (compareTime(fromTime, toTime) && toTime != "") {
        setTimeValidation(false);
      }
    } else if (name == "toTime") {
      setToTime(value);
      if (compareTime(fromTime, toTime) && fromTime != "") {
        setTimeValidation(false);
      }
    }
  };
  //on radio button change

  const handleRadioChange = (e: any /*index:number*/) => {
    var value = e.target.value;
    setMeetingFilter(value);
  };
  // submit meeting
  // const quickMeetingSubmit = () => {
  //   var time = new Date();
  //   var curTime = moment(time, "HH:mm").format("HH:mm");
  //   if (!compareTime(fromTime, toTime)) {
  //     setTimeValidation(true);
  //     setTimeValidationCurrentTime(false);
  //     return false;
  //   }

  //   const payload = {
  //     meeting_date: scheduleDate != "" ? scheduleDate : formatDate(new Date()),
  //     meeting_from_time: fromTime,
  //     meeting_to_time: toTime,
  //     meeting_type: meetingType,
  //     meeting_filter: meetingFilter,
  //     link: meetingLink,
  //     warning_ignore: 0,
  //     logged_user_id:userInfo.id

  //   };
  //   if(meetingLink==""){
  //     setLinkValidation(true);
  //     return false;
  //   }
  //   if (
  //     formatDate(new Date()) == payload.meeting_date &&
  //     compareTime(fromTime, curTime)
  //   ) {
  //     setTimeValidationCurrentTime(true);
  //     setTimeValidation(false);
  //     return false;
  //   }
  //   setTimeValidation(false);
  //   setLinkValidation(false);
  //   setTimeValidationCurrentTime(false);
  //   setDisabled(true);
  //   post(QUICK_MEETINGS, payload).then((response: any) => {
  //     if (!response || !response.data) return;
  //     if (response.data.result == "success") {
  //       setDialogData({
  //         header: "Thank You!",
  //         text: "Your meeting has been scheduled !!!",
  //       });
  //       setShowIncubatorSuccessModal(true);
  //       setDisabled(false);
  //     } else if (response.data.result == "warning") {
  //       setConfirmationDialogData({
  //         header: "Meeting Already Exist",
  //         text: response.data.message,
  //       });
  //       setShowConfirmationBox(true);
  //       setDisabled(false);
  //     } else {
  //       setFailDialogData({
  //         header: "Oops !!",
  //         text: response.data.message,
  //       });
  //       setShowIncubatorFailureModal(true);
  //       setDisabled(false);
  //     }
  //   });
  // };

  // const finalSchedule = () => {
  //   const payload = {
  //     meeting_date: scheduleDate != "" ? scheduleDate : formatDate(new Date()),
  //     meeting_from_time: fromTime,
  //     meeting_to_time: toTime,
  //     meeting_type: meetingType,
  //     meeting_filter: meetingFilter,
  //     link: meetingLink,
  //     warning_ignore: 1,
  //     logged_user_id:userInfo.id
  //   };
  //   setTimeValidation(false);
  //   post(QUICK_MEETINGS, payload).then((response: any) => {
  //     if (!response || !response.data) return;
  //     setShowConfirmationBox(false);
  //     if (response.data.result == "success") {
  //       setDialogData({
  //         header: "Thank You!",
  //         text: "Your meeting has been scheduled !!!",
  //       });
  //       setShowIncubatorSuccessModal(true);
  //     } else {
  //       setFailDialogData({
  //         header: "Oops !!",
  //         text: response.data.message,
  //       });
  //       setShowIncubatorFailureModal(true);
  //     }
  //   });
  // };

  const getDashboardCountActive = (duration: string) => {
    getDashboardCount(duration);
    setActiveMainSelect(duration);
  };
  const dismissModal = () => {
    setShowIncubatorSuccessModal(false);
    setShowIncubatorFailureModal(false);
    window.location.reload();
  };
  const getDashboardChartCountActive = (duration: string) => {
    getDashboardChartCount(duration);
    setActiveChartSelect(duration);
  };
  const getDashboardChartCountActiveStartup = (duration: string) => {
    getDashboardChartCountStartup(duration);
    setActiveChartSelectStartup(duration);
  };

  const getTopIncubatorActive = (duration: string) => {
    getTopIncubators(duration);
    setActiveTopIncubator(duration);
  };

  const changeClassChart = (duration: string) => {
    getChartCount(duration);
    setActiveChart(duration);
  };
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // const getIncubatorList = (meeting_id: any) => {
  //   get(`${GET_ALL_INCUBATORS}?meeting_id=${meeting_id}`).then(
  //     (response: any) => {
  //       if (!response || !response.data) return;
  //       setIncubatorDetails(response.data.result.data);
  //     }
  //   );
  // };

  const getIncubatorList = (meetingData: any) => {
    // console.log(meetingData);
    const payload = {
      "meeting_date": meetingData.meeting_date,
      "from_time": meetingData.meeting_from_time,
      "to_time": meetingData.meeting_to_time
    }
    post(GET_ALL_INCUBATORS_NODE, payload).then(
      (response: any) => {
        if (!response || !response.data) return;
        // console.log(response.data.Data);
        setIncubatorDetails(response.data.Data);
      }
    );
  };

  const handleEditDateChange = (date: Date, field: string) => {
    setupcomingMeetingData((prevState) => ({
      ...prevState,
      meeting_date: formatDate(new Date(date)),
    }));
  };

  const onEditTimeChange = (e: any) => {
    var value = e.target.value;
    var name = e.target.name;

    if (name == "fromTime") {
      setupcomingMeetingData((prevState) => ({
        ...prevState,
        meeting_from_time: value + ":00",
      }));
      if (
        compareTime(
          upcomingMeetingData.meeting_from_time,
          upcomingMeetingData.meeting_to_time
        ) &&
        upcomingMeetingData.meeting_to_time != ""
      ) {
        setTimeValidation(false);
      }
    }
    if (name == "toTime") {
      setupcomingMeetingData((prevState) => ({
        ...prevState,
        meeting_to_time: value + ":00",
      }));
      if (
        compareTime(
          upcomingMeetingData.meeting_from_time,
          upcomingMeetingData.meeting_to_time
        ) &&
        upcomingMeetingData.meeting_from_time != ""
      ) {
        setTimeValidation(false);
      }
    }
  };

  const meetingReschedule = () => {
    var time = new Date();
    var curTime = moment(time, "HH:mm").format("HH:mm");
    if (
      !compareTime(
        upcomingMeetingData.meeting_from_time,
        upcomingMeetingData.meeting_to_time
      )
    ) {
      setTimeValidation(true);
      setTimeValidationCurrentTime(false);
      return false;
    }
    if (
      formatDate(new Date()) == upcomingMeetingData.meeting_date &&
      compareTime(upcomingMeetingData.meeting_from_time, curTime)
    ) {
      setTimeValidationCurrentTime(true);
      setTimeValidation(false);
      return false;
    }

    setDisabled(true);
    setTimeValidationCurrentTime(false);
    setTimeValidation(false);
    post(UPDATE_MEETING, upcomingMeetingData).then((response: any) => {
      if (!response || !response.data) return;
      // console.log(response.data);
      if (response.data.result == "success") {
        setDialogData({
          header: "Thank You!",
          text: "Your meeting has been Rescheduled !!!",
        });
        setShowIncubatorSuccessModal(true);
        getMeetingDetails();
        setIsEditable(true);
      } else {
        setFailDialogData({
          header: "Oops! , Something went wrong",
          text: "Please try again !!!",
        });
        setShowIncubatorFailureModal(true);
      }
    });
  };

  const openRangeDialog = (type: any) => {
    setShowDateRangeDialog(true);
    setRangeCardName(type);
    if (type == "card") {
      setRangePopupData({
        fromDate: cardDateRange.fromDate,
        toDate: cardDateRange.toDate,
        minDate: cardDateRange.fromDate,
      });
    }
    if (type == "chart") {
      setRangePopupData({
        fromDate: chartDateRange.fromDate,
        toDate: chartDateRange.toDate,
        minDate: chartDateRange.fromDate,
      });
    }
    if (type == "incubator") {
      setRangePopupData({
        fromDate: incubatorDateRange.fromDate,
        toDate: incubatorDateRange.toDate,
        minDate: incubatorDateRange.fromDate,
      });
    }
    if (type == "startup") {
      setRangePopupData({
        fromDate: startupDateRange.fromDate,
        toDate: startupDateRange.toDate,
        minDate: startupDateRange.fromDate,
      });
    }
    if (type == "top") {
      setRangePopupData({
        fromDate: topDateRange.fromDate,
        toDate: topDateRange.toDate,
        minDate: topDateRange.fromDate,
      });
    }
  };
  const meetingNavigation = () => {
    history.replace({
      pathname: "/merged_list",
      state: { flags: "schedule" }
    });
  }
  return (
    <>
      {loader &&
        <div className="spinner-border custom-spinner" role="status">
          <span className="sr-only">Loading...</span>
        </div>}
      {/* Dashboard body Component */}
      <div className="container dashboardContainer pt-4">
        <div className="row mb-3 dashboard-heading-secion">
          <div className="col-md-6">
            <p className="dashboard pt-2 pl-2">Dashboard</p>
          </div>
          <div className="col-md-6 text-right">
            <span
              onClick={() => getDashboardCountActive("1day")}
              className={
                activeMainSelect == "1day"
                  ? "chartDuration  active"
                  : "chartDuration"
              }
            >
              1 D
            </span>
            <span
              onClick={() => getDashboardCountActive("1week")}
              className={
                activeMainSelect == "1week"
                  ? "chartDuration  active"
                  : "chartDuration"
              }
            >
              1 W
            </span>
            <span
              onClick={() => getDashboardCountActive("1month")}
              className={
                activeMainSelect == "1month"
                  ? "chartDuration  active"
                  : "chartDuration"
              }
            >
              1 M
            </span>
            <span
              onClick={() => getDashboardCountActive("3months")}
              className={
                activeMainSelect == "3months"
                  ? "chartDuration  active"
                  : "chartDuration"
              }
            >
              3 M
            </span>
            <span
              onClick={() => getDashboardCountActive("6months")}
              className={
                activeMainSelect == "6months"
                  ? "chartDuration  active"
                  : "chartDuration"
              }
            >
              6 M
            </span>
            <span
              onClick={() => getDashboardCountActive("1year")}
              className={
                activeMainSelect == "1year"
                  ? "chartDuration  active"
                  : "chartDuration"
              }
            >
              1 Y
            </span>
            <span
              onClick={() => getDashboardCountActive("till_date")}
              className={
                activeMainSelect == "till_date"
                  ? "chartDuration chartDurationTill  active"
                  : "chartDuration chartDurationTill"
              }
            >
              Till Date
            </span>
            {/* <i className="fa fa-calendar fa-2x ml-2" aria-hidden="true"></i> */}

            <img
              src={CalendarIcon}
              className="calendar-icon"
              onClick={() => openRangeDialog("card")}
              alt="Calendar icon to select date"
            />
          </div>
        </div>

        <div className="row cardDiv">
          <div className="col-md-3">
            <div className="rectangle-1 pt-3">
              <span className="incubator-numeric">
                {/* {dpiitDashboardCount.submittedIncubationForms} */}
                {dpiitDashboardCount.submittedIncubationForms}
              </span>
              <br />
              <span className="incubator-applicatio ">
                Incubator Applications
                <br /> Submitted
              </span>
            </div>
          </div>
          {/* {console.log(dpiitDashboardCount.approvedIncubationForms - dpiitDashboardCount.cancelledIncubationForms,"1515")} */}
          <div className="col-md-3">
            <div className="rectangle-1 pt-3">
              <span className="incubator-numeric">
                {/* {dpiitDashboardCount.approvedIncubationForms - dpiitDashboardCount.cancelledIncubationForms} */}
                {dpiitDashboardCount.approvedIncubationForms}
              </span>
              <br />
              <span className="incubator-applicatio ">
                Incubator Applications <br />
                Selected
              </span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="rectangle-1 pt-3">
              <span className="incubator-numeric">
                {/* {dpiitDashboardCount.submittedStartupForms} */}
                {dpiitDashboardCount.submittedStartupForms}
              </span>
              <br />
              <span className="incubator-applicatio ">
                Startup Applications <br />
                Submitted
              </span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="rectangle-1 pt-3">
              <span className="incubator-numeric">
                {/* {dpiitDashboardCount.approvedStartupForms} */}
                {dpiitDashboardCount.approvedStartupForms}
              </span>
              <br />
              <span className="incubator-applicatio ">
                Startup Applications
                <br /> Selected
              </span>
            </div>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-6">
                <ul className="nav nav-tabs chart-nav" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#tabs-1"
                      role="tab"
                    >
                      Incubators
                    </a>
                  </li>
                  <div className="vertical-dash"></div>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#tabs-2"
                      role="tab"
                    >
                      Startups
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-6 text-right pt-2 pr-4">
                <a
                  className="view-button"
                  data-toggle="modal"
                  data-target="#exampleModalCenter"
                  role="tab"
                >
                  View Full Report
                </a>
              </div>
            </div>
            <div className="text-left mt-2 pl-1">
              <span
                onClick={() => changeClassChart("1month")}
                className={
                  activeChart == "1month"
                    ? "chartDuration  active"
                    : "chartDuration"
                }
              >
                1 M
              </span>
              <span
                onClick={() => changeClassChart("3months")}
                className={
                  activeChart == "3months"
                    ? "chartDuration  active"
                    : "chartDuration"
                }
              >
                3 M
              </span>
              <span
                onClick={() => changeClassChart("6months")}
                className={
                  activeChart == "6months"
                    ? "chartDuration  active"
                    : "chartDuration"
                }
              >
                6 M
              </span>
              <span
                onClick={() => changeClassChart("1year")}
                className={
                  activeChart == "1year"
                    ? "chartDuration  active"
                    : "chartDuration"
                }
              >
                1 Y
              </span>
              <span
                onClick={() => changeClassChart("tillDate")}
                className={
                  activeChart == "tillDate"
                    ? "chartDuration chartDurationTill  active"
                    : "chartDuration chartDurationTill"
                }
              >
                Till Date
              </span>
              {/* <img src={CalendarIcon} className="calendar-icon" alt="Calendar icon to select date" /> */}
              <img
                src={CalendarIcon}
                className="calendar-icon"
                onClick={() => openRangeDialog("chart")}
                alt="Calendar icon to select date"
              />
            </div>
            <div id="dashboard_line_chart" className="tab-content lineChartDiv">
              <div className="tab-pane active" id="tabs-1">
                {/* line */}

                <Line data={stateLine} options={options_Line} />
              </div>
              <div className="tab-pane" id="tabs-2">
                {/* bar chart*/}
                {/* <Bar
                  data={stateBar}
                  options={options_Bar}
                /> */}
                <Line data={stateLineStartup} options={options_Line_Startup} />
              </div>
            </div>
          </div>
          {/* <div className="col-md-2">
            <a
              className="view-full-report"
              data-toggle="tab"
              href="#pop-up"
              role="tab"
            >
              View Full Report
            </a>
          </div> */}
          <div className="col-md-4">
            <div className="scheduleMeeting">
              <div className="row pt-2">
                <div className="col-md-8">
                  <p className="upcoming-meeting mx-4">Upcoming Meeting</p>
                </div>
                <div className="col-md-4 text-right">
                  {loggedInRole == "dpiit" && (
                    <Link className="view-all mr-4" to="/eac_review_dpiit">
                      View All
                    </Link>
                  )}
                  {loggedInRole == "secretariat" && (
                    <Link
                      className="view-all mr-4"
                      to="/eac_review_secretariat"
                    >
                      View All
                    </Link>
                  )}
                  {/* <a
                  className="view-all"
                  data-toggle="tab"
                  href="#pop-up"
                  role="tab"
                >
                  
                </a> */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 centerText">
                  <img
                    src={ScheduleMeeting}
                    className="img-fluid"
                    alt="Schedule Logo"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 centerText">
                  <br />
                  <div
                    className="upcomingMeetingClick"
                    onClick={() =>
                      getIncubatorList(upcomingMeetingData)
                    }
                    data-toggle="modal"
                    data-target="#quickMeetingEdit"
                  >
                    {/* {console.log(upcomingMeetingData,"meetingDataaa")} */}
                    <span className="eacDateNumber">
                      {upcomingMeetingData.meeting_date != ""
                        ? new Date(upcomingMeetingData.meeting_date).getDate()
                        : null}
                    </span>
                    <span className="eacDateMonth">
                      {upcomingMeetingData.meeting_date != ""
                        ? monthNames[
                        new Date(
                          upcomingMeetingData.meeting_date
                        ).getMonth()
                        ]
                        : null}
                    </span>

                    <br />

                    {upcomingMeetingData.meeting_date != "" ? (
                      <>
                        <span className="eacMeetTime">
                          {formatTime(upcomingMeetingData.meeting_from_time)} -{" "}
                          {formatTime(upcomingMeetingData.meeting_to_time)}
                        </span>
                        <img
                          className="blue-arrow"
                          src={schedulemeetingarrow}
                          alt="Schedule Arrow.."
                        />
                      </>
                    ) : null}
                  </div>

                  {upcomingMeetingData.meeting_date != "" ? (
                    <span className="eac-performance-revi">
                      {upcomingMeetingData.meeting_type == "2"
                        ? "EAC Performance Review Meeting"
                        : "Incubator Selection Meeting"}
                      <br />
                      <br />
                    </span>
                  ) : (
                    <span className="eac-performance-revi">
                      No Upcoming Meetings
                      <br />
                      <br />
                    </span>
                  )}
                </div>
              </div>
              <div className="row pt-2">
                <div className="col-md-10 offset-1 centerText">
                  <button
                    className="tertiary  btn-yellow-primary full-width"
                    // data-toggle="modal"
                    // data-target="#scheduleMeeting"
                    onClick={meetingNavigation}
                  >
                    Schedule a Meeting
                  </button>
                </div>
              </div>
              {/* <p className="pending-count-text text-center">{incubatorPending} incubator pending for review</p> */}
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-4">
            <div className="rectangle">
              <div className="row">
                <div className="col-9 link-header">
                  <p className="dahboard-card-heading">
                    Incubator Applications
                  </p>
                </div>
                <div className="col-3 link-header">
                  {loggedInRole == "dpiit" && (
                    <Link className="view-all" to="/dpiit_incubator_list">
                      View All
                    </Link>
                  )}
                  {loggedInRole == "secretariat" && (
                    <Link className="view-all" to="/merged_list">
                      View All
                    </Link>
                  )}
                  {/* <a
                    className="view-all"
                    data-toggle="tab"
                    href="#pop-up"
                    role="tab"
                  >
                    View All
              </a> */}
                </div>
              </div>

              <div className="duration-bottom">
                <span
                  onClick={() => getDashboardChartCountActive("1month")}
                  className={
                    activeChartSelect == "1month"
                      ? "chartDuration  active"
                      : "chartDuration"
                  }
                >
                  1M
                </span>
                <span
                  onClick={() => getDashboardChartCountActive("3months")}
                  className={
                    activeChartSelect == "3months"
                      ? "chartDuration  active"
                      : "chartDuration"
                  }
                >
                  3M
                </span>
                <span
                  onClick={() => getDashboardChartCountActive("6months")}
                  className={
                    activeChartSelect == "6months"
                      ? "chartDuration  active"
                      : "chartDuration"
                  }
                >
                  6M
                </span>
                <span
                  onClick={() => getDashboardChartCountActive("1year")}
                  className={
                    activeChartSelect == "1year"
                      ? "chartDuration  active"
                      : "chartDuration"
                  }
                >
                  1Y
                </span>
                <span
                  onClick={() => getDashboardChartCountActive("till_date")}
                  className={
                    activeChartSelect == "till_date"
                      ? "chartDuration chartDurationTill  active"
                      : "chartDuration chartDurationTill"
                  }
                >
                  Till Date
                </span>
                <img
                  src={CalendarIcon}
                  className="calendar-icon"
                  onClick={() => openRangeDialog("incubator")}
                  alt="Calendar icon to select date"
                />
              </div>
              <div id="hori-bar" className="hori-bar pt-3 pl-2 pr-2 ">
                <HorizontalBar
                  data={stateHorizontal}
                  options={options_horizontal}
                />
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <fieldset disabled={false}>
              <div className="rectangle">
                <div className="row">
                  <div className="col-9 link-header">
                    <p className="dahboard-card-heading">
                      Startup Applications
                    </p>
                  </div>
                  <div className="col-3 link-header">
                    {loggedInRole == "dpiit" && (
                      <Link className="view-all" to="/dpiit_startup_list">
                        View All
                      </Link>
                    )}
                    {loggedInRole == "secretariat" && (
                      <Link className="view-all" to="/secretariat_startup_list">
                        View All
                      </Link>
                    )}
                  </div>
                </div>

                <div className="duration-bottom">
                  <span
                    onClick={() =>
                      getDashboardChartCountActiveStartup("1month")
                    }
                    className={
                      activeChartSelectStartup == "1month"
                        ? "chartDuration  active"
                        : "chartDuration"
                    }
                  >
                    1M
                  </span>
                  <span
                    onClick={() =>
                      getDashboardChartCountActiveStartup("3months")
                    }
                    className={
                      activeChartSelectStartup == "3months"
                        ? "chartDuration  active"
                        : "chartDuration"
                    }
                  >
                    3M
                  </span>
                  <span
                    onClick={() =>
                      getDashboardChartCountActiveStartup("6months")
                    }
                    className={
                      activeChartSelectStartup == "6months"
                        ? "chartDuration  active"
                        : "chartDuration"
                    }
                  >
                    6M
                  </span>
                  <span
                    onClick={() => getDashboardChartCountActiveStartup("1year")}
                    className={
                      activeChartSelectStartup == "1year"
                        ? "chartDuration  active"
                        : "chartDuration"
                    }
                  >
                    1Y
                  </span>
                  <span
                    onClick={() =>
                      getDashboardChartCountActiveStartup("till_date")
                    }
                    className={
                      activeChartSelectStartup == "till_date"
                        ? "chartDuration chartDurationTill  active"
                        : "chartDuration chartDurationTill"
                    }
                  >
                    Till Date
                  </span>
                  <img
                    src={CalendarIcon}
                    className="calendar-icon"
                    onClick={() => openRangeDialog("startup")}
                    alt="Calendar icon to select date"
                  />
                </div>
                <div id="hori-bar" className="hori-bar pt-3 pl-2 pr-2">
                  <HorizontalBar
                    data={stateHorizontalStartup}
                    options={options_horizontal}
                  />
                </div>
              </div>
            </fieldset>
          </div>

          <div className="col-md-4">
            <fieldset disabled={false}>
              <div className="rectangle">
                <div className="row">
                  <div className="col-md-9 link-header">
                    <p className="dahboard-card-heading top-heading-dash">
                      Top Incubators
                    </p>
                  </div>
                </div>
                <div className="duration-bottom">
                  <span
                    onClick={() => getTopIncubatorActive("1month")}
                    className={
                      activeTopIncubator == "1month"
                        ? "chartDuration  active"
                        : "chartDuration"
                    }
                  >
                    1M
                  </span>
                  <span
                    onClick={() => getTopIncubatorActive("3months")}
                    className={
                      activeTopIncubator == "3months"
                        ? "chartDuration  active"
                        : "chartDuration"
                    }
                  >
                    3M
                  </span>
                  <span
                    onClick={() => getTopIncubatorActive("6months")}
                    className={
                      activeTopIncubator == "6months"
                        ? "chartDuration  active"
                        : "chartDuration"
                    }
                  >
                    6M
                  </span>
                  <span
                    onClick={() => getTopIncubatorActive("1year")}
                    className={
                      activeTopIncubator == "1year"
                        ? "chartDuration  active"
                        : "chartDuration"
                    }
                  >
                    1Y
                  </span>
                  <span
                    onClick={() => getTopIncubatorActive("till_date")}
                    className={
                      activeTopIncubator == "till_date"
                        ? "chartDuration chartDurationTill  active"
                        : "chartDuration chartDurationTill"
                    }
                  >
                    Till Date
                  </span>
                  <img
                    src={CalendarIcon}
                    className="calendar-icon"
                    onClick={() => openRangeDialog("top")}
                    alt="Calendar icon to select date"
                  />
                </div>
                <div className="hori-bar pt-4 mx-3">
                  {topIncubators && topIncubators.length != 0 ? (
                    topIncubators.map((item: any) => (
                      <>
                        <div className="row pb-3">
                          <div className="col-8">
                            <span className="burns-industries">
                              {item.incubator_name}
                            </span>
                            <br />
                            <span className="mumbai-maharashtra"></span>
                          </div>
                          <div className="col-4 text-center">
                            <span className="card-count">
                              {item.startup_count}
                            </span>

                            <br />
                            <span className="mumbai-maharashtra">Startups</span>
                          </div>
                        </div>
                        <div className="hr">
                          <hr />
                        </div>
                      </>
                    ))
                  ) : (
                    <div className="row pr-5 pl-3 pb-3">
                      <div className="col-md-10">
                        <span className="burns-industries">
                          No top incubator's
                        </span>
                      </div>
                    </div>
                  )}

                  {/* <div className="row pr-5 pl-3 pb-3">
                    <div className="col-md-10">
                      <span className="burns-industries">Burns Industries</span>
                      <br />
                      <span className="mumbai-maharashtra">
                        Mumbai, Maharashtra
                  </span>
                    </div>
                    <div className="col-md-2">
                      <span className="card-count pl-4">41</span>

                      <br />
                      <span className="mumbai-maharashtra">Startups</span>
                    </div>
                  </div>
                  <div className="hr"><hr /></div>
                  <div className="row  pr-5 pl-3 pb-3">
                    <div className="col-md-10">
                      <span className="burns-industries">Dunder Mifflin, Inc.</span>
                      <br />
                      <span className="mumbai-maharashtra">
                        Chennai, Tamil Nadu
                  </span>
                    </div>
                    <div className="col-md-2">
                      <span className="card-count pl-4">33</span>

                      <br />
                      <span className="mumbai-maharashtra">Startups</span>
                    </div>
                  </div>
                  <div className="hr"><hr /></div>
                  <div className="row pr-5 pl-3 pb-3">
                    <div className="col-md-10">
                      <span className="burns-industries">Stark Industries</span>
                      <br />
                      <span className="mumbai-maharashtra">
                        Mumbai, Maharashtra
                  </span>
                    </div>
                    <div className="col-md-2">
                      <span className="card-count pl-4">29</span>

                      <br />
                      <span className="mumbai-maharashtra">Startups</span>
                    </div>
                  </div>
                  <div className="hr"><hr /></div> */}
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>

      {/* Modal */}
      <FullIncubatorDetailModal role={"dpiit"} />

      {/* Modal */}
      {/* <div className="modal fade" id="exampleModalCenter" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header modal-header-bottom">
              <h5 className="modal-title modal-title pl-3" id="exampleModalLongTitle">Full Report <span>(Incubator)</span></h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-10">
                      <span className="modal-text">Total unique incubators funded till date</span>
                    </div>
                    <div className="col-md-2">
                      <span className="card-count">-</span>
                    </div>
                  </div>
                  <div className="hr"><hr /></div>
                  <div className="row pr-1 pl-3 pb-3">
                    <div className="col-md-10">
                      <span className="modal-text">Total funds committed to incubators</span>
                    </div>
                    <div className="col-md-2">
                      <span className="card-count">-</span>
                    </div>
                  </div>
                  <div className="hr"><hr /></div>
                  <div className="row pr-1 pl-3 pb-3">
                    <div className="col-md-10">
                      <span className="modal-text">Incubators that have funded atleast one startup</span>
                    </div>
                    <div className="col-md-2">
                      <span className="card-count">-</span>
                    </div>
                  </div>
                  <div className="hr"><hr /></div>
                  <div className="row pr-1 pl-3 pb-3">
                    <div className="col-md-10">
                      <span className="modal-text">Incubators that have not funded any startup</span>
                    </div>
                    <div className="col-md-2">
                      <span className="card-count">-</span>
                    </div>
                  </div>

                </div>
                <div className="col-md-6">
                  <div className="row pr-1 pl-3 pb-3">
                    <div className="col-md-10">
                      <span className="modal-text">No. of startups to whom funds have been disbursed</span>
                    </div>
                    <div className="col-md-2">
                      <span className="card-count">-</span>
                    </div>
                  </div>
                  <div className="hr"><hr /></div>
                  <div className="row pr-1 pl-3 pb-3">
                    <div className="col-md-10">
                      <span className="modal-text">Percentage of funds disbursed to Startups</span>
                    </div>
                    <div className="col-md-2">
                      <span className="card-count">-</span>
                    </div>
                  </div>
                  <div className="hr"><hr /></div>
                  <div className="row pr-1 pl-3 pb-3">
                    <div className="col-md-10">
                      <span className="modal-text">Quantum of funds disbursed to Startups</span>
                    </div>
                    <div className="col-md-2">
                      <span className="card-count">-</span>
                    </div>
                  </div>
                  <div className="row pr-1 pl-3 pb-3">
                    <div className="col-md-10">
                      <span className="modal-text">Quantum of funds given to Startups as Funds</span>
                    </div>
                    <div className="col-md-2">
                      <span className="card-count">-</span>
                    </div>
                  </div>
                  <div className="row pr-1 pl-3 pb-3">
                    <div className="col-md-10">
                      <span className="modal-text">Quantum of funds given to Startups as Debts</span>
                    </div>
                    <div className="col-md-2">
                      <span className="card-count">-</span>
                    </div>
                  </div>
                  <div className="row pr-1 pl-3 pb-3">
                    <div className="col-md-10">
                      <span className="modal-text">Quantum of funds given to Startups as Convertible Debentures</span>
                    </div>
                    <div className="col-md-2">
                      <span className="card-count">-</span>
                    </div>
                  </div>
                  <div className="hr"><hr /></div>
                  <div className="row pr-1 pl-3 pb-3">
                    <div className="col-md-10">
                      <span className="modal-text">Average Converteble Debentures/Debt per Startups</span>
                    </div>
                    <div className="col-md-2">
                      <span className="card-count">-</span>
                    </div>
                  </div>

                  <div className="row pr-1 pl-3 pb-3">
                    <div className="col-md-10">
                      <span className="modal-text">Average Grant per Startup</span>
                    </div>
                    <div className="col-md-2">
                      <span className="card-count">-</span>
                    </div>
                  </div>
                  <div className="hr"><hr /></div>
                </div>
              </div>
              <div className="row pt-3 mb-2">
                <div className="col-md-12 centerText">
                  <button className="tertiary  btn-yellow-primary"  data-dismiss="modal" >
                    OK GOT IT!
                </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* quick meeting popop */}
      <div
        className="modal fade"
        id="quickMeetingEdit"
        role="dialog"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-slideout modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-bottom pr-5">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => (
                  setIsEditable(true),
                  setTimeValidation(false),
                  setTimeValidationCurrentTime(false)
                )}
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pl-5 pr-5 pt-5">
              <div className="popup-head mb-5 ml-3">
                <div className="main-heading">
                  {upcomingMeetingData.meeting_type == "2"
                    ? "EAC Performance Review Meeting"
                    : "Incubator Selection Meeting"}
                </div>
              </div>

              <label className="col-sm-4 col-form-head">Date</label>
              <fieldset disabled={isEditable}>
                <div className="col-md-6 mb-4">
                  <Calendar
                    id="registrationDate"
                    name="registrationDate"
                    className="form-control input-height"
                    format="dd/MM/yyyy"
                    selectedDate={
                      upcomingMeetingData.meeting_date
                        ? moment(upcomingMeetingData.meeting_date).format("YYYY-MM-DD")
                        : new Date()
                    }
                    onDateChange={(date: Date, field: string) =>
                      handleEditDateChange(date, field)
                    }
                    minDate={new Date()}
                  />
                </div>
                <div className="row pl-3">
                  <div className="col-md-6">
                    <label className="col-form-head">From Time</label>
                    <input
                      type="time"
                      value={upcomingMeetingData.meeting_from_time}
                      name="fromTime"
                      className="form-control input-height"
                      onChange={onEditTimeChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="col-form-head">To Time</label>
                    <input
                      type="time"
                      value={upcomingMeetingData.meeting_to_time}
                      name="toTime"
                      className="form-control input-height"
                      onChange={onEditTimeChange}
                    />
                  </div>
                </div>
              </fieldset>
              <div className="row text-left pl-4 mb-5">
                {timeValidation == true ? (
                  <label className="error-message">
                    From time greater than To time
                  </label>
                ) : null}
                {timeValidationCurrentTime == true ? (
                  <label className="error-message">
                    Meeting can't be scheduled for past time
                  </label>
                ) : null}
              </div>
              <div className="list-dashboard pl-3">
                <label className="col-form-head">
                  {incubatorDetails.length + " Incubators"}
                </label>
                <div className="list-scroll">
                  <ul>
                    {incubatorDetails.map((item: any) => (
                      <li>
                        <span className="tableDataHead">
                          {item.incubator_application_name}
                        </span>
                        <br />
                        <span className="tableDataApplication">
                          {"No: " + item.incubator_application_id}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="row modal-row">
                <div className="col-md-6 text-center">
                  <button
                    className="btn btn-sisfs btn-transparent-primary full-width"
                    onClick={() => setIsEditable(false)}
                  >
                    EDIT
                  </button>
                </div>
                <div className="col-md-6 text-left">
                  <button
                    className={
                      isDisabled
                        ? "tertiary  btn-yellow-primary full-width custom-disabled"
                        : "tertiary  btn-yellow-primary full-width"
                    }
                    disabled={isDisabled}
                    onClick={meetingReschedule}
                  >
                    RESCHEDULE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* right slide modal  */}
      {/* <div
        className="modal fade"
        id="scheduleMeeting"
        role="dialog"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-slideout modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-bottom pr-5">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => (
                  setTimeValidation(false), setTimeValidationCurrentTime(false)
                )}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pl-5 pr-5 pt-5">
              <div className="popup-head mb-5 ml-3">
                <div className="main-heading">Schedule a Meeting</div>
                {!isVisible ? (
                  <div className="count-text">
                    {incubatorPending} Incubators Selected
                  </div>
                ) : null}
              </div>

              <label className="col-sm-4 col-form-head">Meeting Type</label>
              <div className="col-md-12 mb-4">
                <div className="select-custom">
                  <select
                    className="form-control input-height"
                    aria-label="Select Entity Type"
                    onChange={handleOnChange}
                  >
                    <option value="1">Incubator Selection</option>
                    <option value="2">Performance Review Meeting</option>
                  </select>
                </div>
              </div>
              {isVisible ? (
                <div className="radio-popup">
                  <label className="col-sm-12 col-form-head mb-2">
                    Incubator Due for Review
                  </label>
                  <div className="pl-3">
                    <div>
                      <input
                        type="radio"
                        id="Tomorrow"
                        name="filter"
                        value="Tomorrow"
                        onChange={handleRadioChange}
                        disabled={count.Tomorrow == "0" ? true : false}
                      />
                      <label className="radio-label">
                        Tomorrow({count.Tomorrow})
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        name="filter"
                        value="This_week"
                        onChange={handleRadioChange}
                        disabled={count.This_week == "0" ? true : false}
                      />
                      <label className="radio-label">
                        This Week({count.This_week})
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        name="filter"
                        value="Next_week"
                        onChange={handleRadioChange}
                        disabled={count.Next_week == "0" ? true : false}
                      />
                      <label className="radio-label">
                        Next Week({count.Next_week})
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        name="filter"
                        value="Next_month"
                        onChange={handleRadioChange}
                        disabled={count.Next_month == "0" ? true : false}
                      />
                      <label className="radio-label">
                        Next Month({count.Next_month})
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        name="filter"
                        value="Next_2_months"
                        onChange={handleRadioChange}
                        disabled={count.Next_2_months == "0" ? true : false}
                      />
                      <label className="radio-label">
                        Next 2 Months({count.Next_2_months})
                      </label>
                    </div>
                  </div>
                </div>
              ) : null}
              <label className="col-sm-4 col-form-head">Date</label>
              <div className="col-md-6 mb-4">
                <Calendar
                  id="registrationDate"
                  name="registrationDate"
                  className="form-control input-height"
                  format="dd/MM/yyyy"
                  selectedDate={
                    selectedDateControl ? selectedDateControl : new Date()
                  }
                  onDateChange={(date: Date, field: string) =>
                    handleDateChange(date, field)
                  }
                  minDate={new Date()}
                />
              </div>
              <div className="row pl-3">
                <div className="col-md-6">
                  <label className="col-form-head">From Time</label>
                  <input
                    type="time"
                    value={fromTime}
                    name="fromTime"
                    className="form-control input-height"
                    onChange={onTimeChange}
                  />
                </div>
                <div className="col-md-6">
                  <label className="col-form-head">To Time</label>
                  <input
                    type="time"
                    value={toTime}
                    name="toTime"
                    className="form-control input-height"
                    onChange={onTimeChange}
                  />
                </div>
              </div>
              <div className="row text-left pl-4 mb-5">
                {timeValidation == true ? (
                  <label className="error-message">
                    From time greater than To time
                  </label>
                ) : null}
                {timeValidationCurrentTime == true ? (
                  <label className="error-message">
                    Meeting can't be scheduled for past time
                  </label>
                ) : null}
              </div>
              <label className="col-sm-4 col-form-head">Meeting Link</label>
              <div className="col-md-12 mb-4">
                <input
                  type="text"
                  id="meeting_link"
                  name="meeting_link"
                  className="form-control input-height"
                  onChange={(e: any) => setMeetingLink(e.target.value)}
                />
                <div className="row text-left pl-3">
                {linkValidation == true ? (
                  <label className="error-message">
                    Meeting Link is required
                  </label>
                ) : null}
              </div>
              </div>
              

              <div className="row pl-3">
                <div className="col-md-6">
                  <button
                    className="btn btn-sisfs btn-transparent-primary full-width"
                    data-dismiss="modal"
                    onClick={() => (
                      setTimeValidation(false),
                      setTimeValidationCurrentTime(false),
                      setLinkValidation(false)
                    )}
                  >
                    CANCEL
                  </button>
                </div>
                <div className="col-md-6">
                  <button
                    className={
                      isDisabled
                        ? "tertiary  btn-yellow-primary full-width custom-disabled"
                        : "tertiary  btn-yellow-primary full-width"
                    }
                    disabled={isDisabled}
                    onClick={quickMeetingSubmit}
                  >
                    SCHEDULE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {showIncubatorSuccessModal && (
        // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
        // <>
        //   <div className="modal-backdrop fade show"></div>

        //   <div
        //     className="modal align-middle modal-sisfs d-block"
        //     id="FormSubmissionSuccess"
        //   >
        //     <div className="modal-dialog modal-dialog-centered modal-md">
        //       <div className="modal-content">
        //         <div className="modal-body text-center">
        //           <i className="fa fa-check-circle-o"></i>
        //           <h3 className="modal-message">Thank You!</h3>
        //           <div className="modal-text">
        //             Your Meeting Has been Scheduled
        //                         </div>
        //           <div className="modal-action">
        //             <button className="btn btn-sisfs btn-yellow-primary" onClick={dismissModal}>
        //               Ok!!
        //                         </button>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // </>
        <ConfirmationDialog
          headerText={dialogData.header}
          bodyText={dialogData.text}
          onDialogClose={dismissModal}
        />
      )}
      {showIncubatorFailureModal && (
        // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
        // <>
        //   <div className="modal-backdrop fade show"></div>
        //   <div
        //     className="modal align-middle modal-sisfs d-block"
        //     id="FormSubmissionError"
        //   >
        //     <div className="modal-dialog modal-dialog-centered modal-md">
        //       <div className="modal-content">
        //         <div className="modal-body text-center">
        //         <img
        //       src={error}
        //       className="search mb-2"
        //       alt="search"

        //     />
        //           <h3 className="modal-message">
        //             {failDialogData.header}
        //                       </h3>
        //           <h3 className="modal-text">
        //           {failDialogData.text}
        //                       </h3>
        //           <div className="modal-action">
        //             <button className="btn btn-sisfs btn-yellow-primary" onClick={() => setShowIncubatorFailureModal(false)}>
        //               OK!
        //                       </button>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // </>
        <ConfirmationDialog
          headerText={failDialogData.header}
          bodyText={failDialogData.text}
          onDialogClose={() => setShowIncubatorFailureModal(false)}
          iconClass="showImage"
        />
      )}
      {/* {showConfirmationBox && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionError"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  
                  <h3 className="modal-message mt-2">
                    {confirmationDialogData.header}
                  </h3>
                  <h3 className="modal-text">{confirmationDialogData.text}</h3>
                  <div className="modal-action mt-2">
                    <button
                      className="btn btn-sisfs btn-transparent-primary"
                      onClick={() => setShowConfirmationBox(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-sisfs btn-yellow-primary ml-2"
                      onClick={finalSchedule}
                    >
                      Schedule Anyway
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )} */}
      {showDateRangeDialog && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionError"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  {/* <img
               src={error}
               className="search mb-2"
               alt="search"

             /> */}
                  <h3 className="modal-message mt-2">Select Range</h3>
                  <div className="row">
                    <div className="col-md-6">
                      <h5>From Date</h5>
                      <ReactDatePicker
                        id="registrationDate"
                        name="registrationDate"
                        className="form-control"
                        dateFormat="yyyy-MM-dd"
                        value={moment(rangePopupData.fromDate).format(
                          "YYYY-MM-DD"
                        )}
                        onChange={(date: Date) => {
                          handleRangeDateChange(date, "fromDate");
                        }}
                        showMonthDropdown
                        showYearDropdown
                      />
                    </div>
                    <div className="col-md-6">
                      <h5>To Date</h5>
                      <ReactDatePicker
                        id="registrationDate"
                        name="registrationDate"
                        className="form-control"
                        dateFormat="yyyy-MM-dd"
                        value={moment(rangePopupData.toDate).format(
                          "YYYY-MM-DD"
                        )}
                        onChange={(date: Date) => {
                          handleRangeDateChange(date, "toDate");
                        }}
                        minDate={rangePopupData.minDate}
                        showMonthDropdown
                        showYearDropdown
                      />
                    </div>
                  </div>
                  <div className="modal-action mt-2">
                    <button
                      className="btn btn-sisfs btn-transparent-primary"
                      onClick={() => setShowDateRangeDialog(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-sisfs btn-yellow-primary ml-2"
                      onClick={() => getRangeData()}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* <div className="modal fade" id="exampleModal1" role="dialog" aria-labelledby="exampleModalLabel1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-slideout modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pl-5 pr-5 pt-5">
              <div className="popup-head mb-5 ml-3">
                <div className="main-heading">Schedule a Meeting</div>
                <div className="count-text">32 Incubators Selected</div>
              </div>

              <label className="col-sm-4 col-form-head">
                Meeting Type
                        </label>
              <div className="col-md-12 mb-4">
                <select className="form-control" aria-label="Select Entity Type" onChange={handleOnChange}>
                  <option value="1">Incubator Selection</option>
                  <option value="2">Performance Review Meeting</option>
                </select>
              </div>
              {isVisible ?
                <div className="radio-popup">
                  <label className="col-sm-12 col-form-head mb-2">
                    Incubator Due for Review
                        </label>
                  <div className="pl-3">
                    <div
                      className="custom-control custom-radio"
                    >
                      <input
                        type="radio"
                        className="custom-control-input"
                        id="1"
                        value="1"
                      />
                      <label
                        className="custom-control-label"
                      >
                        Tomorrow
                  </label>
                    </div>
                    <div
                      className="custom-control custom-radio"
                    >
                      <input
                        type="radio"
                        className="custom-control-input"
                        id="1"
                        value="1"
                      />
                      <label
                        className="custom-control-label"
                      >
                        This Week
                  </label>
                    </div>
                    <div
                      className="custom-control custom-radio"
                    >
                      <input
                        type="radio"
                        className="custom-control-input"
                        id="1"
                        value="1"
                      />
                      <label
                        className="custom-control-label"
                      >
                        Next Week
                  </label>
                    </div>
                    <div
                      className="custom-control custom-radio"
                    >
                      <input
                        type="radio"
                        className="custom-control-input"
                        id="1"
                        value="1"
                      />
                      <label
                        className="custom-control-label"
                      >
                        Next Month
                  </label>
                    </div>
                    <div
                      className="custom-control custom-radio"
                    >
                      <input
                        type="radio"
                        className="custom-control-input"
                        id="1"
                        value="1"
                      />
                      <label
                        className="custom-control-label"
                      >
                        Next 2 Months
                  </label>
                    </div>
                    <div
                      className="custom-control custom-radio"
                    >
                      <input
                        type="radio"
                        className="custom-control-input"
                        id="1"
                        value="1"
                      />
                      <label
                        className="custom-control-label"
                      >
                        Next 3 Months
                  </label>
                    </div>
                  </div>
                </div> : null}
              <label className="col-sm-4 col-form-head">
                Date & Time
                        </label>
              <div className="col-md-6 mb-4">
                <Calendar
                  id="registrationDate"
                  name="registrationDate"
                  className="form-control"
                  format="dd/MM/yyyy"
                  selectedDate={new Date()}
                  onDateChange={(date: Date, field: string) =>
                    handleDateChange(date, field)
                  }
                />
              </div>
              <div className="row pl-3 mb-5">
                <div className="col-md-6">
                  <select className="form-control" aria-label="From Time">
                    <option selected>From Time</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
                <div className="col-md-6">
                  <select className="form-control" aria-label="To Time">
                    <option selected>To Time</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div>
              <div className="row pl-5">
                <button className="btn btn-sisfs btn-transparent-primary">
                  CANCEL
              </button>
                <button className="tertiary  btn-yellow-primary ml-3">
                  SCHEDULE
                </button>

              </div>

            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
export default EacDashboardContainer;
