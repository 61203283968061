import React, {Fragment, useEffect, useState} from "react"
import pdf from "../../../images/PDF.svg";
import {PROGRESS_OVERVIEW_DETAIL_BY_APPLICATION_ID, PROGRESS_PERCENTAGE, PROGRESS_STAGES} from "../../../constants/api";
import {get} from "../../../effects/api/api.common";
import ProgressEditInfoModal from "./ProgressEditInFoModal";
import ProgressUpdateModal from "./progressUpdateModal";
import ConfirmationDialog from "../../common/controls/ConfirmationDialog";


const ProgressStartupUpdateEditInfoModal = (props: any) => {

    const [applicationId, setApplicationId] = useState('')
    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState(false)
    const [progressStages, setProgressStages] = useState([])
    const [progressPercentage, setProgressPercentage] = useState([])
    const [editProgressId, setEditProgressId] = useState(0)
    const [prevDate, setPrevDate] = useState('')
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
    const [year, setYear] = useState('')
    const [month, setMonth] = useState('')


    const getProgressByAppId = () => {
        if (applicationId === '') return
        const queryParam = (year || month) ? (year ? '&year=' + year : '') + (month ? '&month=' + month : '&month=0') + '&type=incubator' : '&year=0'+'&month=0'+'&type=all'        
        const apiUrl = `${PROGRESS_OVERVIEW_DETAIL_BY_APPLICATION_ID}${applicationId}${queryParam}`
        // console.log(apiUrl,"responseee")
        setLoading(true)
        get(apiUrl).then((response: any) => {
            // console.log('response app id', response.data.data)
            if (!response || !response.data) return;
            setData(response.data.data)
            //console.log("#########3",data)
            getProgressPercentage()
        }).catch((err) => {
            //console.log('error', err)
            setData([])
        }).finally(() => {
            setLoading(false)
        })
    }

    const getStageNameById = (stageId: number) => {
        
        if (!stageId || !progressStages) return "NA"
        const filter = progressStages.find((key: any) => {
            return key.id === stageId
        })
        // @ts-ignore
        if (filter === undefined || Object.keys(filter).length === 0) return
        // @ts-ignore
        return filter.name ? filter.name : ''
    }
    const getStagePercentNameById = (percentId: number) => {
        
        if (!percentId || !progressPercentage) return "NA"
        const filter = progressPercentage.find((key: any) => {
            return key.id === percentId
        })
        // @ts-ignore
        if (filter === undefined || Object.keys(filter).length === 0) return
        // @ts-ignore
        return filter.name ? filter.name : ''
    }

    const handleSetEditProgressId = (progressId: number) => {
        setEditProgressId(progressId)
    }

    const myNumberWithTwoDecimalPlace=(myNumber: string)=>{
       return  parseFloat(myNumber).toFixed(2);
    }
    

    const handleSetPreviousEditDate = (previousDate: string) => {
        setPrevDate(previousDate)
    }

    useEffect(() => {
        setApplicationId(props.applicationId)
    }, [props.applicationId])



    const dismissModal = () => {
        window.location.reload()
    }
    
    useEffect(() => {
        if(applicationId == '0' || applicationId == ""){
            return;
        }
        getProgressByAppId()
        // getProgressPercentage()
        // getProgressStages()
    }, [applicationId, year, month])

    const getProgressPercentage = () => {
        get(PROGRESS_PERCENTAGE).then((response: any) => {
            if (!response || !response.data) return;
            if (response?.data?.result === 'success') {
                setProgressPercentage(response.data.data)
            } else {
                setProgressPercentage([])
            }
            getProgressStages();
        })
    }

    const getProgressStages = () => {
        get(PROGRESS_STAGES).then((response: any) => {
            if (!response || !response.data) return;
            if (response?.data?.result === 'success') {
                setProgressStages(response.data.data)
            } else {
                setProgressStages([])
            }
        })
    }

    return (
        <Fragment>


            <div className="row">
                <div className="col-md-6 mb-4">
                    <label className="col-form-head">
                        Choose year
                    </label>

                    <div className="select-custom">
                        <select
                            onChange={(e: any) => setYear(e.target.value)}
                            className="form-control input-height"
                            aria-label="Select Year"
                        >
                            <option value="">Please select Year</option>
                            <option value={currentYear}>{currentYear}</option>
                            <option value={currentYear - 1}>{currentYear - 1}</option>
                            <option value={currentYear - 2}>{currentYear - 2}</option>
                            <option value={currentYear - 3}>{currentYear - 3}</option>
                            <option value={currentYear - 4}>{currentYear - 4}</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-6 mb-4">
                    <label className="col-form-head">
                        Choose Month
                    </label>

                    <div className="select-custom">
                        <select
                            onChange={(e: any) => setMonth(e.target.value)}
                            className="form-control input-height"
                            aria-label="Select Year"
                        >
                            <option value="">Please select Month</option>
                            <option value="1">January</option>
                            <option value="2">February</option>
                            <option value="3">March</option>
                            <option value="4">April</option>
                            <option value="5">May</option>
                            <option value="6">June</option>
                            <option value="7">July</option>
                            <option value="8">August</option>
                            <option value="9">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>

                        </select>
                    </div>
                </div>
            </div>

            {!loading && data.length > 0 && data.map((key: any, index: number) => {
                // @ts-ignore
                return (
                    <Fragment key={index}>
                        <div className="status-list-wrapper mb-5">
                            <div className="status-list">
                                <div className="row mb-3">
                                    <h4 className="col-3">Progress Review</h4>
                                    <div className="col-3"><span
                                        className="status-badge blue-badge">
                                        <b>
                                            {key.data.created_at ? "Submitted" : "Not Submitted"}
                                        </b>
                                    </span>
                                    </div>
                                    <div className="col-6 text-right">
                                        <div className="btn-group header-action-section">

                                            {!key.data.created_at &&
                                            <button
                                                onClick={() => handleSetPreviousEditDate(key.date_format)}
                                                type="button"
                                                className="btn btn-sisfs btn-yellow-primary white-space-nowrap"
                                                data-toggle="modal"
                                                data-target="#progressUpdateModal">
                                                Share an update
                                            </button>
                                            }
                                            {key.data.created_at && !key.data.approve_date &&
                                            <button
                                                onClick={() => handleSetEditProgressId(key.data.id)}
                                                type="button"
                                                className="btn btn-sisfs btn-yellow-primary white-space-nowrap"
                                                data-toggle="modal"
                                                data-target="#progressEditInfoModal">
                                                Edit Info
                                            </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-8"><span
                                        className="d-block mb-2">For Month</span><span><b>{key.for_month}</b></span>
                                    </div>
                                    <div className="col-4 text-right">
                                        It is mandatory to update this information on the 1st of every month
                                    </div>

                                    <div className="col-md-12">
                                        <div className="accordion accordion-details-wrap"
                                             id="accordion-progress-details">
                                            <div className="card accordion-card">
                                                <div
                                                    className={`card-header accordion-card-header ${index ? 'collapsed' : ''}`}
                                                    data-toggle="collapse"
                                                    data-target={`#progress_collapse_${index}`}
                                                    aria-expanded={index === 0}>
                                                    {key.for_month}
                                                </div>

                                                <div id={`progress_collapse_${index}`}
                                                     className={`collapse ${index === 0 ? 'show' : ''}`}
                                                     data-parent="#accordion-progress-details">
                                                    <div className="card-body accordion-card-body">
                                                        <div className="row">
                                                            <div className="col-md-6 left-align mt-3">
                                                                <p className="progressOverviewKey">Updated on</p>

                                                                <p className="progressOverviewValue">
                                                                    {key.data.updated_at ? new Date(key.data.updated_at).toLocaleDateString() : ""}
                                                                </p>
                                                            </div>
                                                            <div className="col-md-6 left-align mt-3">
                                                                {/* <p className="progressOverviewKey">Validated by: Burn */}
                                                                    {/* Industries</p> */}

                                                                <p className="progressOverviewValue">{key.data.approve_date ? key.data.approve_date : "Not Validated yet"}</p>
                                                            </div>

                                                            <div className="col-md-6 left-align mt-3">
                                                                <p className="progressOverviewKey">Stage</p>

                                                                <p className="progressOverviewValue"> {getStageNameById(key.data.stage_id)}</p>
                                                            </div>
                                                            <div className="col-md-6 left-align mt-3">
                                                                <p className="progressOverviewKey">Progress</p>

                                                                <p className="progressOverviewValue"> {getStagePercentNameById(key.data.progress_in_percentage)}</p>
                                                            </div>
                                                            <div className="col-md-6 left-align mt-3">
                                                                <p className="progressOverviewKey">Progress
                                                                    Description</p>
                                                                <p className="progressOverviewValue mw80">
                                                                    {key?.data?.progress_in_text}
                                                                </p>
                                                            </div>

                                                            <div className="col-md-6 left-align mt-3">
                                                                <p className="progressOverviewKey">Jobs created by
                                                                    startup - till date</p>

                                                                <p className="progressOverviewValue"> {key?.data?.people_employed_till_date}</p>
                                                            </div>
                                                            <div className="col-md-6 left-align mt-3">
                                                                <p className="progressOverviewKey">Jobs created by
                                                                    startup - Lastest Quarter</p>

                                                                <p className="progressOverviewValue"> {key?.data?.people_employed_latest_quarter}</p>
                                                            </div>
                                                            <div className="col-md-6 left-align mt-3">
                                                                <p className="progressOverviewKey">TurnOver of
                                                                    startup - Till Date</p>

                                                                <p className="progressOverviewValue"> &#8377; {key?.data?.turnover_till_date ? (myNumberWithTwoDecimalPlace(key?.data?.turnover_till_date)):key?.data?.turnover_till_date}</p>
                                                            </div>
                                                            <div className="col-md-6 left-align mt-3">
                                                                <p className="progressOverviewKey">TurnOver of
                                                                    startup - Lastest Quarter</p>

                                                                <p className="progressOverviewValue"> &#8377; {key?.data?.turnover_latest_quarter ?(myNumberWithTwoDecimalPlace(key?.data?.turnover_latest_quarter)):key?.data?.turnover_latest_quarter}</p>
                                                            </div>
                                                            <div className="col-md-6 left-align mt-3">
                                                                <p className="progressOverviewKey">
                                                                Cumulative Quantum of External Funding Raised by Startup (post-SISFS selection only) – till date

                                                                </p>

                                                                <p className="progressOverviewValue"> &#8377; {parseFloat(key?.data?.external_fund_raised_till_date)}</p>
                                                            </div>
                                                            <div className="col-md-6 left-align mt-3">
                                                                <p className="progressOverviewKey">
                                                                Cumulative Quantum of External Funding Raised by Startup (post-SSFS selection only) – in latest quarter
                                                                </p>

                                                                <p className="progressOverviewValue"> &#8377; {parseFloat(key?.data?.external_fund_raised_latest_quarter)}</p>
                                                            </div>
                                                            <div className="col-md-6 left-align mt-3">
                                                                <p className="progressOverviewKey">
                                                                Cumulative GST Filed by Startup – till date 
                                                                </p>

                                                                <p className="progressOverviewValue"> &#8377; {parseFloat(key?.data?.gst_filled_till_date)}</p>
                                                            </div>
                                                            <div className="col-md-6 left-align mt-3">
                                                                <p className="progressOverviewKey">
                                                                Cumulative GST Filed by Startup – Lastest Quarter 
                                                                </p>

                                                                <p className="progressOverviewValue"> &#8377; {parseFloat(key?.data?.gst_filled_latest_quarter)}</p>
                                                            </div>
                                                            <div className="col-md-6 left-align mt-3">
                                                                <p className="progressOverviewKey">
                                                                Cumulative No. of Products and Services by Startup – till date 
                                                                </p>

                                                                <p className="progressOverviewValue">  {key?.data?.no_products_on_gem_portal_till_date}</p>
                                                            </div>
                                                            <div className="col-md-6 left-align mt-3">
                                                                <p className="progressOverviewKey">
                                                                Cumulative No. of Products and Services by Startup – Lastest Quarter
                                                                </p>

                                                                <p className="progressOverviewValue">  {key?.data?.no_products_on_gem_portal_latest_quarter}</p>
                                                            </div>
                                                            <div className="col-md-6 left-align mt-3">
                                                                <p className="progressOverviewKey">
                                                                Cumulative No. of IP Registration Requests Filed by the Startup - Till Date
                                                                </p>

                                                                <p className="progressOverviewValue">  {key?.data?.no_ip_regstr_req_till_date}</p>
                                                            </div>
                                                            <div className="col-md-6 left-align mt-3">
                                                                <p className="progressOverviewKey">
                                                                Cumulative No. of IP Registration Requests Filed by the Startup –  Latest quarter (₹)
                                                                </p>

                                                                <p className="progressOverviewValue">  {key?.data?.no_ip_regstr_req_latest_quarter}</p>
                                                            </div>

                                                            <div className="col-md-6 left-align mt-3">
                                                                <p className="progressOverviewKey">If any other</p>
                                                                <p className="progressOverviewValue mw80">
                                                                    {key?.data?.other_comment}
                                                                </p>
                                                            </div>
                                                                                                          

                                                        </div>
                                                        {/* {console.log(key.data,"file_with_location")} */}
                                                        {key.data.file_with_location &&
                                                            <div className="col-md-6 left-align mt-3">
                                                                <p className="eac-review-subtitle">Supporting
                                                                    Documents</p>

                                                                <div className="row mt-2">
                                                                    <div className="col-md-2">
                                                                        <img src={pdf} alt="SISFS Logo"/>
                                                                    </div>
                                                                    <div className="col-md-10 left-align">
                                                                        <p className="document-attched-label">
                                                                            {/* Progressfieldtrials-No.422copy2final.pdf */}
                                                                            {key.data.file_with_location.substr(
                                                                                key.data.file_with_location.lastIndexOf(
                                                                                    "/"
                                                                                ) + 1,
                                                                                key.data.file_with_location.length
                                                                            )}
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            }
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <hr/>
                                </div>
                            </div>
                        </div>

                    </Fragment>
                )
            })
            }

            {!loading && data.length === 0 &&
            <h3 className="text-center pt-5 pb-5">
                No Data Found
            </h3>
            }

            {loading &&
            <h3 className="text-center pt-5 pb-5">
                Please wait...
            </h3>
            }
            <ProgressEditInfoModal progressIds={editProgressId} progressPercentages ={progressPercentage} progressStagess={progressStages} />
            <ProgressUpdateModal applicationId={applicationId} prevDate={prevDate}  progressPercentage ={progressPercentage} progressStagess={progressStages}/>

        </Fragment>
    )
}

export default ProgressStartupUpdateEditInfoModal
