import TrancheDetails from './TrancheDetailsComponent';
import AccountDetails from './AccountDetailsComponent';
import React, {useEffect, useState} from 'react';
import {Documents, FileUploadModelTranche} from '../../../models/funddetails/TrancheDetails';
import {AccountFileUploadModel, FileUploadModel, TrancheFileUploadModel} from '../../../models/FileUploadModel';
import {ApplicationStatus} from '../../dpiit/incubator/ApplicationGeneralDetails';
import FileUploader from '@amodv/react-file-uploader';
import {UploadTypeEnum} from '../../../utils/Enums';
import {
    GET_ALL_STARTUP_TRANCHES,
    STARTUP_ACCOUNT_DOCUMENT_DELETE,
    STARTUP_ACCOUNT_DOCUMENT_UPLOAD,
    STARTUP_ALL_APPLICATIONS,
    STARTUP_GET_ACTIVE_TRANCHE_REQUEST,
    STARTUP_GET_BANK_DETAILS,
    STARTUP_STORE_BANK_DETAILS,
    STARTUP_STORE_TRANCHE_REQUEST,
    STARTUP_TRANCHE_REMOVE_DOCUMENT,
    STARTUP_TRANCHE_UPLOAD_DOCUMENT
} from '../../../constants/api';
import {get, post} from '../../../effects/api/api.common';
import {createFile} from '../../../utils/CommonMethods';
import {useHistory} from 'react-router-dom';
import {StartupAccountDetailsData} from '../../../models/funddetails/StartupAccountDetailsData';
import {StartupTrancheDetail, TrancheRequest} from '../../../models/funddetails/StartupTrancheDetails';
import ProgressStartupUpdateEditInfoModal from "../progressoverview/progressStartupUpdateEditInfoModal";
import ConfirmationDialog from "../../common/controls/ConfirmationDialog";
import { getSessionStorageValue } from '../../../effects/utils/session-storage';

interface IStartupFundDetailsProps {
   
       
        statusId:any;
        evDate:any;
        isPrefer:any;
        sel1:any;
      
}

interface IStartupFundDetailsState {

}


function StartupFundDetailsContainer(props: IStartupFundDetailsProps) {
    const {
       
        statusId,
        evDate,
        isPrefer,
        sel1
      } = props;
    
    const [uploadedCheque, setUploadedCheque] = useState<Array<FileUploadModelTranche>>([]);

    const [isDisabledAccountDetails, setIsDisabledAccountDetails] = useState({
        bank_account_holder_name: false,
        bank_name: false,
        account_number: false,
        account_type: false,
        bank_ifsc_code: false,
        bank_micr_code: false,
        branch_name: false,
        branch_address: false,
        pan_card: false,
        cancelled_cheque: false
    });

    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')

    const [activeIndex, setActiveIndex] = useState('1');
    const changeActive = (id: string) => {
        setActiveIndex(id);
    };
    const changeActiveBack = () => {
        setActiveIndex('1');
    };

    interface IIKeyValueIndexer {
        [key: string]: boolean;
    }

    const [openUploadBlock, setOpenUploadBlock] = useState<IIKeyValueIndexer>();
    const [trancheRequest, setTrancheRequest] = useState<TrancheRequest>(
        new TrancheRequest(0, 0, '', 0, '0', [], [])
    );
    const [accountDetailsData, setAccountDetails] = useState<StartupAccountDetailsData>(
        new StartupAccountDetailsData(0, 0, '', '', '', 0, '', '', '', '', '', ''
            ,
            "", "", "", "", new AccountFileUploadModel(new File([], "")), new AccountFileUploadModel(new File([], ""))
        )
    );

    const [accountDetailsApi,setAccountDetailsApi] = useState<StartupAccountDetailsData>(
        new StartupAccountDetailsData(0, 0, '', '', '', 0, '', '', '', '', '', ''
            ,
            "", "", "", "", new AccountFileUploadModel(new File([], "")), new AccountFileUploadModel(new File([], ""))
        )
    );

    const [uploadedPan, setUploadedPan] = useState<Array<FileUploadModelTranche>>([]);

    const [errMessage, setErrMessage] = useState('');

    const [isEnable, setIsEnable] = useState(false);

    const [pdfOnly, setPdfOnly] = useState<IIKeyValueIndexer>();
    const [application_id, setApplicationId] = useState(0);

    const [showEditSuccessModal, setShowSuccessModal] = useState(false);
    const [showEditFailureModal, setShowFailureModal] = useState(false);
    const dismissModal = () => {
        setShowSuccessModal(false);
        setShowFailureModal(false);
    };

    //console.log('application id', application_id)

    const [activeTranches, setActiveTranches] = useState<Array<ApplicationStatus>>([]);

    const [trancheData, setTrancheData] = useState<Array<StartupTrancheDetail>>([
        new StartupTrancheDetail(
            0,
            0,
            '',
            0,
            0,
            '',
            '',
            new Documents([], [], []),
            '',
            0,
            0,
            '',
            '',
            '',
            '',
            '',
            '', false, false, false,'','',''
        ),
    ]);
    const [trancheDetailsData, setTrancheDetailsData] = useState<Array<StartupTrancheDetail>>([
        new StartupTrancheDetail(
            0,
            0,
            '',
            0,
            0,
            '',
            '',
            new Documents([], [], []),
            '',
            0,
            0,
            '',
            '',
            '',
            '',
            '',
            '', false, false, false,'','',''
        ),
    ]);
    const [startupFundDetailsData, setstartupFundDetailsData] = useState<Array<StartupTrancheDetail>>([
        new StartupTrancheDetail(
            0,
            0,
            '',
            0,
            0,
            '',
            '',
            new Documents([], [], []),
            '',
            0,
            0,
            '',
            '',
            '',
            '',
            '',
            '', false, false, false,'','',''
        ),
    ]);
    const [trancheDataDESC, setTrancheDataDESC] = useState<Array<StartupTrancheDetail>>([
        new StartupTrancheDetail(
            0,
            0,
            '',
            0,
            0,
            '',
            '',
            new Documents([], [], []),
            '',
            0,
            0,
            '',
            '',
            '',
            '',
            '',
            '', false, false, false,'','',''
        ),
    ]);

    useEffect(() => {
        // console.log(isDisabledAccountDetails,"isDisabledAccountDetails")
        // getAccountDetails();
        checkSelectedApplication();

        window.scrollTo(0, 0);
    }, []);

    // triggers whenever user makes any changes in the form for select and input type
    const handleChange = (
        evt: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const name = evt.target.name;
        const value = evt.target.value;

        let trancheDetails = JSON.parse(JSON.stringify(trancheRequest));

        if (name == 'amount_required') {
            const numStr = String(evt.target.value);
            var count = 0;
            if (numStr.includes('.')) {
                count = numStr.split('.')[1].length;
            }
            if (count > 2) {
                trancheDetails[name] = Number(evt.target.value).toFixed(2);
            } else {
                trancheDetails[name] = evt.target.value;
            }
        } else {
            trancheDetails[name] = evt.target.value;
        }
        if (name == 'amount_required' && Number(value) > 0) {
            setIsEnable(true);
        }

        setTrancheRequest(trancheDetails);
    };


    const handleChangeAccountDetails = (
        evt: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const name = evt.target.name;
        const value = evt.target.value;
        //console.log('event target', name, value)

        if(name == 'bank_account_holder_name' || name == 'bank_name'){
            setAccountDetails((values) => ({
                ...values,
                [name]: value.replace(/[^a-zA-Z0-9 ]/ig, ''),
            }));
        }else if(name == 'branch_name' || name == 'bank_ifsc_code' || name == 'bank_micr_code'){
            setAccountDetails((values) => ({
                ...values,
                [name]: value.replace(/[^a-zA-Z0-9 ]/ig, ''),
            }));
        }else if(name == 'account_number'){
            setAccountDetails((values) => ({
                ...values,
                [name]: value.replace(/[^0-9]/ig, ''),
            }));
        }else{
            setAccountDetails((values) => ({
                ...values,
                [name]: value,
            }));
        }

        

    };


    const checkSelectedApplication = () => {
        const user: any = getSessionStorageValue("user");
              const userInfo = JSON.parse(user)

            //   var payload ={
            //     "logged_user_id":userInfo.id,
            //     // "get_list": "incubator_prefrences"
            //   }
              var payload ={
                "logged_user_id":userInfo.id,
                "get_list":"",
                "user_role":userInfo.roles
              }

              
        // get(STARTUP_ALL_APPLICATIONS).then((response: any) => {
        post(STARTUP_ALL_APPLICATIONS, payload).then((response: any) => {
            // console.log(response.data.data,"aplicatttttt")         
            if (!response || !response.data) return;
            if (response.data.data.length != 0) {
                for(let i = 0; i < response.data.data.Selected.length; i++){
                    if(response.data.data.Selected[i].startup_status_id == 7){
                        // console.log(response.data.data.Selected[i],"mohaaaan")
                        getAllTranches(response.data.data.Selected[i].startup_application_id);
                        setApplicationId(response.data.data.Selected[i].startup_application_id);
                        getActiveTrancheRequest(response.data.data.Selected[i].startup_application_id);
                        break;
                    } 

                }
                // for (let i = 0; i < response.data.data.length; i++) {
                //     if (response.data.data[i].startup_status_id === 7) {
                //         setApplicationId(response.data.data[i].startup_application_id);
                //         getAllTranches(response.data.data[i].startup_application_id);  
                //         getActiveTrancheRequest(response.data.data[i].startup_application_id);
                //         break;
                //     }
                // }
            }
            getAccountDetails();
        });
    };
    
    const getAllTranches = (application_Id: number) => {
        // console.log(application_Id,"tranchDetailssssssss")  
        if (application_Id != 0) {
            let tranche_parameters = {
                startup_application_id: application_Id,
                order_by: 'DESC',
            };
            post(`${GET_ALL_STARTUP_TRANCHES}`, tranche_parameters).then((response: any) => {
                if (!response || !response.data) return;

                setTrancheDataDESC(response.data.data)
                var trancheDetailsData: Array<StartupTrancheDetail> = response.data.data;
                // console.log(trancheDetailsData,"tranchDetailssssssss")

                // var startupFundDetailsData = response.data.funding_data;
                for (var i = 0; i < trancheDetailsData.length; i++) {
                    var tranche_details = trancheDetailsData[i];
                    // console.log(tranche_details,"tranchDetailssssssss")
                    const file_data = tranche_details.documents;

                    if (file_data) {
                        Object.keys(file_data).length &&
                        Object.keys(file_data).map((key: string, index) => {
                            switch (key) {
                                case UploadTypeEnum.ProofDisbursement:
                                    file_data[key].map((data: any) => {
                                        createFile(data.file).then((blob: any) => {
                                            blob.lastModifiedDate = new Date();
                                            const src = data.file as string;
                                            blob.name = src.substr(src.lastIndexOf('/') + 1, src.length);

                                            const index = file_data.proof_of_disbursement.findIndex((el) => el.file_id === data.file_id);
                                            file_data.proof_of_disbursement[index] = {
                                                file_id: data.file_id,
                                                file_type_id: data.file_type_id,
                                                file: blob as File,
                                                ext: data.ext,
                                                fileName: data.file
                                            };

                                        });
                                    });

                                    break;

                                case UploadTypeEnum.QuarterlyReport:
                                    file_data[key].map((data: any) => {
                                        createFile(data.file).then((blob: any) => {
                                            blob.lastModifiedDate = new Date();
                                            const src = data.file as string;
                                            blob.name = src.substr(src.lastIndexOf('/') + 1, src.length);

                                            const index = file_data.quarterly_reports.findIndex((el) => el.file_id === data.file_id);
                                            file_data.quarterly_reports[index] = {
                                                file_id: data.file_id,
                                                file_type_id: data.file_type_id,
                                                file: blob as File,
                                                ext: data.ext,
                                                fileName: data.file
                                            };
                                        });
                                    });
                                    break;

                                case UploadTypeEnum.SupportingDocuments:
                                    file_data[key].map((data: any) => {
                                        createFile(data.file).then((blob: any) => {
                                            blob.lastModifiedDate = new Date();
                                            const src = data.file as string;
                                            blob.name = src.substr(src.lastIndexOf('/') + 1, src.length);

                                            const index = file_data.supporting_documents.findIndex((el) => el.file_id === data.file_id);
                                            file_data.supporting_documents[index] = {
                                                file_id: data.file_id,
                                                file_type_id: data.file_type_id,
                                                file: blob as File,
                                                ext: data.ext,
                                                fileName: data.file
                                            };
                                        });
                                    });
                                    break;
                            }


                        });
                    }

                    tranche_details.documents = file_data;
                    trancheDetailsData[i] = tranche_details;
                }


                setTrancheData(trancheDetailsData);
                setstartupFundDetailsData(response.data.funding_data);

            });
        }


        // if (application_Id != 0) {
        //     let tranche_parameters = {
        //         startup_application_id: application_Id,
        //         order_by: 'ASC',
        //     };

        //     post(`${GET_ALL_STARTUP_TRANCHES}`, tranche_parameters).then((response: any) => {
        //         if (!response || !response.data) return;
        //         setTrancheDataDESC(response.data.data);
        //     });
        // }
    };

    const getActiveTrancheRequest = (application_Id: number) => {
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user)
        let tranche_request = {
            startup_application_id: application_Id,
            "logged_user_id":userInfo.id,
            "user_Role":userInfo.roles
        };

        post(`${STARTUP_GET_ACTIVE_TRANCHE_REQUEST}`, tranche_request).then(
            (response: any) => {
                if (!response || !response.data) return;

                if (response.data.result === 'success') {
                    console.log(response.data.data[0].startup_tranche_id);
                    const tranches = [
                        {
                            label: response.data.data[0].startup_tranche_id,
                            value: response.data.data[0].tranche_number,
                        },
                    ];


                    if (response.data.data[0].startup_tranches_statuses_label == 'Requested') {
                        setErrMessage(
                            'Selected Tranche is already in requested, Please disbursed the same to request the next tranche. '
                        );
                    } else {
                        setErrMessage('');
                    }

                    let trancheDetails = JSON.parse(JSON.stringify(trancheRequest));
                    // trancheDetails["application_id"] = response.data.data.application_id;
                    // trancheDetails["tranche_id"] = response.data.data.tranch_id;

                    trancheDetails["startup_application_id"] = response.data.data[0].startup_application_id;
                    trancheDetails["startup_tranche_id"] = response.data.data[0].startup_tranche_id;
                    trancheDetails["tranche_number"] = response.data.data[0].tranche_number;
                    trancheDetails["amount_required"] = Number(response.data.data[0].funds_requested).toFixed();
                    trancheDetails["cash_in_hand"] = response.data.data[0].cash_in_hand;
                    if (Number(response.data.data[0].funds_requested) > 0 && response.data.data[0].cash_in_hand == 1) {
                        setIsEnable(true);
                    }

                    setTrancheRequest(trancheDetails);
                    bindFiles(response, trancheRequest);
                    setActiveTranches(tranches);
                    //console.log(trancheRequest, "trancheRequest");
                }
            }
        );


        //console.log(activeTranches, 'activeTranches');
    };


    const bindFiles = async (response: any, trancheReq: TrancheRequest) => {
        const file_data = response.data.data[0].documents;
        // incubator = JSON.parse(JSON.stringify(incubator));
        if (file_data) {
            Object.keys(file_data).length &&
            Object.keys(file_data).map((key: string) => {
                switch (key) {
                    case UploadTypeEnum.QuarterlyReport:
                        file_data[key].map((data: any) => {
                            createFile(data.file).then((blob: any) => {
                                blob.lastModifiedDate = new Date();
                                const src = data.file as string;
                                blob.name = src.substr(src.lastIndexOf('/') + 1, src.length);
                                setTrancheRequest((prevState) => {
                                    const updatedFileOther = {
                                        ...prevState,
                                        quarterly_report: [
                                            ...prevState.quarterly_report.map(
                                                (uf: any): TrancheFileUploadModel => {
                                                    return new TrancheFileUploadModel(
                                                        uf.file_id,
                                                        uf.file_type_id,
                                                        uf.file,
                                                        data.file.ext
                                                    );
                                                }
                                            ),
                                            new TrancheFileUploadModel(data.file_id, 0, blob, data.file.ext),
                                        ],
                                    };
                                    return updatedFileOther;
                                });
                            });
                        });

                        break;

                    case UploadTypeEnum.SupportingDocuments:
                        file_data[key].map((data: any) => {
                            createFile(data.file).then((blob: any) => {
                                blob.lastModifiedDate = new Date();
                                const src = data.file as string;
                                blob.name = src.substr(src.lastIndexOf('/') + 1, src.length);

                                setTrancheRequest((prevState) => {
                                    const updatedFileOther = {
                                        ...prevState,
                                        supporting_documents: [
                                            ...prevState.supporting_documents.map(
                                                (uf: any): TrancheFileUploadModel => {
                                                    return new TrancheFileUploadModel(
                                                        uf.file_id,
                                                        uf.file_type_id,
                                                        uf.file,
                                                        data.file.ext
                                                    );
                                                }
                                            ),
                                            new TrancheFileUploadModel(data.file_id, 0, blob, data.file.ext),
                                        ],
                                    };
                                    return updatedFileOther;
                                });
                            });
                        });

                        break;
                }
            });
        }
    };

    const getAccountDetails = () => {
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user)

        var payload ={
        "startup_id":userInfo.id
        }
        
        post(STARTUP_GET_BANK_DETAILS,payload).then((response: any) => {
            // console.log(response.data.bankDetails,"asasasa")
            if (!response || !response.data) return;
            if (
                response.data != undefined &&
                response.data.bankDetails != undefined
            ) {
                // console.log(response.data,"asasasa")
                setAccountDetails(response.data.bankDetails.length>0?response.data.bankDetails[0]:response.data.bankDetails);
                setAccountDetailsApi(response.data.bankDetails.length>0?response.data.bankDetails[0]:response.data.bankDetails)

                if (response.data.bankDetails.pan_card != undefined) {
                    createFile(response.data.bankDetails.pan_card.file).then((blob: any) => {
                        blob.lastModifiedDate = new Date();
                        const src = response.data.bankDetails.pan_card.file as string;
                        if (src != undefined) {
                            blob.name = src && src.substr(src.lastIndexOf('/') + 1, src.length);
                            var file_data: FileUploadModelTranche = new FileUploadModelTranche(
                                response.data.bankDetails.pan_card.file_id,
                                response.data.bankDetails.pan_card.file_type_id,
                                blob as File,
                                response.data.bankDetails.pan_card.file.ext, ""
                            );

                            var uploadedPanCard: Array<FileUploadModelTranche> = ([file_data]);
                            setUploadedPan(uploadedPanCard);
                        }

                    });
                }
                // else{setUploadedPan([]);}

                if (response.data.bankDetails.cancelled_cheque != undefined && response.data.bankDetails.cancelled_cheque.file != undefined) {
                    createFile(response.data.bankDetails.cancelled_cheque.file).then((blob: any) => {
                        blob.lastModifiedDate = new Date();
                        const src = response.data.bankDetails.cancelled_cheque.file as string;
                        blob.name = src.substr(src.lastIndexOf('/') + 1, src.length);
                        var file_data_ch: FileUploadModelTranche = new FileUploadModelTranche(
                            response.data.bankDetails.cancelled_cheque.file_id,
                            response.data.bankDetails.cancelled_cheque.file_type_id,
                            blob as File,
                            response.data.bankDetails.cancelled_cheque.file.ext, ""
                        );

                        var uploadedCh: Array<FileUploadModelTranche> = ([file_data_ch]);
                        setUploadedCheque(uploadedCh);
                    });

                }
                // else{setUploadedCheque([]);}


            }

            let is_disable_account_details = JSON.parse(
                JSON.stringify(isDisabledAccountDetails)
            );

            //Once account details are entered cn not edit the same

            if (
                (response.data.bankDetails.bank_account_holder_name != '' &&
                    response.data.bankDetails.bank_account_holder_name != undefined) &&
                response.data.bankDetails.bank_account_holder_name != null
            ) {
                is_disable_account_details['bank_account_holder_name'] = true;
            }

            if (
                response.data.bankDetails.bank_name != '' &&
                response.data.bankDetails.bank_name != undefined &&
                response.data.bankDetails.bank_name != null
            ) {
                is_disable_account_details['bank_name'] = true;
            }

            if (
                response.data.bankDetails.account_number != '' &&
                response.data.bankDetails.account_number != undefined &&
                response.data.bankDetails.account_number != null
            ) {
                is_disable_account_details['account_number'] = true;
            }

            if (
                response.data.bankDetails.account_type != '' &&
                response.data.bankDetails.account_type != undefined &&
                response.data.bankDetails.account_type != null
            ) {
                is_disable_account_details['account_type'] = true;
            }

            if (
                response.data.bankDetails.bank_ifsc_code != '' &&
                response.data.bankDetails.bank_ifsc_code != undefined &&
                response.data.bankDetails.bank_ifsc_code != null
            ) {
                is_disable_account_details['bank_ifsc_code'] = true;
            }

            if (
                response.data.bankDetails.bank_micr_code != '' &&
                response.data.bankDetails.bank_micr_code != undefined &&
                response.data.bankDetails.bank_micr_code != null
            ) {
                is_disable_account_details['bank_micr_code'] = true;
            }
            if (
                response.data.bankDetails.branch_name != '' &&
                response.data.bankDetails.branch_name != undefined &&
                response.data.bankDetails.branch_name != null
            ) {
                is_disable_account_details['branch_name'] = true;
            }
            if (
                response.data.bankDetails.branch_address != '' &&
                response.data.bankDetails.branch_address != undefined &&
                response.data.bankDetails.branch_address != null
            ) {
                is_disable_account_details['branch_address'] = true;
            }

            if (
                response.data.bankDetails.pan_card != '' &&
                response.data.bankDetails.pan_card != undefined &&
                response.data.bankDetails.pan_card != null
            ) {
                is_disable_account_details['pan_card'] = true;
            }

            if (
                response.data.bankDetails.cancelled_cheque != '' &&
                response.data.bankDetails.cancelled_cheque != undefined &&
                response.data.bankDetails.cancelled_cheque != null
            ) {
                is_disable_account_details['cancelled_cheque'] = true;
            }


            setIsDisabledAccountDetails(is_disable_account_details);
        });
    };


    const storeAccountDetails = () => {

        // console.log(accountDetailsData,"detailsss")
        const user: any = getSessionStorageValue("user");
              const userInfo = JSON.parse(user)

        setErrorMessage('')
        setSuccessMessage('')

        const requestPayload = {
            "id": accountDetailsData.id,
            "startup_id": userInfo.id,
            "bank_account_holder_name": accountDetailsData.bank_account_holder_name,
            "bank_name": accountDetailsData.bank_name,
            "account_number": accountDetailsData.account_number,
            "account_type": accountDetailsData.account_type,
            "bank_ifsc_code": accountDetailsData.bank_ifsc_code,
            "bank_micr_code": accountDetailsData.bank_micr_code,
            "branch_name": accountDetailsData.branch_name,
            "branch_address": accountDetailsData.branch_address,
            "pan_card": AccountFileUploadModel,
            "cancelled_cheque": AccountFileUploadModel
        }

        
        

        if(!requestPayload?.bank_account_holder_name){
            setErrorMessage('Please Provide Bank Account Holder Name')
            return
        }

        if (!requestPayload?.bank_name) {
            setErrorMessage('Please Provide Bank Name')
            return
        }
        if (!requestPayload?.account_number) {
            setErrorMessage('Please Provide Account Number')
            return
        }
        if (!requestPayload?.account_type) {
            setErrorMessage('Please Provide Account type')
            return
        }
        if (!requestPayload?.bank_ifsc_code) {
            setErrorMessage('Please Provide IFSC code')
            return
        }
        if (!requestPayload?.bank_micr_code) {
            setErrorMessage('Please Provide MICR code')
            return
        }
        if (!requestPayload?.branch_name) {
            setErrorMessage('Please Provide Branch Name')
            return
        }
        if (!requestPayload?.branch_address) {
            setErrorMessage('Please Provide Branch Address')
            return
        }


        //@ts-ignore


        // post(`${STORE_BANK_DETAILS}`, accountDetailsData).then((response: any) => {
        post(`${STARTUP_STORE_BANK_DETAILS}`, requestPayload).then((response: any) => {
            
            if (!response || !response.data) return;

            if (response.data.result === 'success') {
                setSuccessMessage('Successully Bank Details Updated')
                getAccountDetails();
            } else {
                setErrorMessage('Failed' + JSON.stringify(response.data.data))
            }

        }).catch((err) => {
            setErrorMessage('Failed' + err?.response?.data?.message)
        });
    };

    const history = useHistory();

    const storeTrancheRequest = () => {
        if (trancheRequest.amount_required > 0) {
            const data_input = {
                startup_application_id: application_id,
                startup_tranche_id: trancheRequest.startup_tranche_id,
                funds_requested: trancheRequest.amount_required,
                cash_in_hand: trancheRequest.cash_in_hand,
            };
            
            post(STARTUP_STORE_TRANCHE_REQUEST, data_input).then((response: any) => {
                 
                if (!response || !response.data || response.data == '') return;
                if (response.data != undefined && response.data != '') {
                    if (response.data.result == 'success') {
                        // setShowSuccessModal(true);

                        history.push({
                            pathname: '/startup_fund_details',

                        });
                        window.location.reload();

                    } else {
                        setShowFailureModal(true);
                    }
                }
            });

            getActiveTrancheRequest(application_id);
            getAllTranches(application_id);
        }
    };

    /** upload the files */
    const onFileUploadTranche = (files: Array<File>, type: string) => {
        for (const file of files) {

            var fileData = [];
            var index = 0;
            if (type == "quarterly_reports") {
                fileData = trancheRequest.quarterly_report;
                index = trancheRequest.quarterly_report.findIndex((el: any) => el.file.name === file.name);
            }
            if (type == "supporting_documents") {
                fileData = trancheRequest.supporting_documents;
                index = trancheRequest.supporting_documents.findIndex((el: any) => el.file.name === file.name);
            }


            // const index = fileData.findIndex((el: any) => el.file.name === file.name);

            if (index == -1) {
                if (file.type === 'application/pdf') {
                    const user: any = getSessionStorageValue("user");
                    const userInfo = JSON.parse(user)
                    const formData = new FormData();
                    // Update the formData object
                    formData.append('file', file, file.name);
                    formData.append('startup_tranche_id', trancheRequest.startup_tranche_id.toString());
                    formData.append('file_type', type);
                    formData.append("logged_user_id",userInfo.id);
                    formData.append("user_Role",userInfo.roles)
                    post(STARTUP_TRANCHE_UPLOAD_DOCUMENT, formData).then((response: any) => {
                        if (!response.data) return;

                        setApplicationId(trancheRequest.startup_application_id);

                        // if (response.data.result == "success") {
                        //     setShowSuccessModal(true);
                        // } else {
                        //     setShowFailureModal(true);
                        // }

                        if (response.data.result == "success") {
                            // setMessage("Tranche Document Uploaded Successfully..!");
                            setShowSuccessModal(true);
                            // //console.log(response.data, "uploaded file");
                            //bind files
                            createFile(response.data.data.file).then((blob: any) => {
                                blob.lastModifiedDate = new Date();
                                const src = response.data.data.file as string;
                                blob.name = src.substr(src.lastIndexOf('/') + 1, src.length);
                                const ext = src.substr(src.lastIndexOf('.') + 1, src.length);
                                const file: TrancheFileUploadModel = new TrancheFileUploadModel(response.data.data.file_id,
                                    0,
                                    blob as File,
                                    ext);

                                if (type == "quarterly_reports") {
                                    const index = trancheRequest.quarterly_report.findIndex((el: any) => el.file_id === response.data.result.file_id);

                                    if (index == -1) {
                                        trancheRequest.quarterly_report = [
                                            ...trancheRequest.quarterly_report,
                                            file,
                                        ];

                                        setTrancheRequest((prevState) => {
                                            const updatedFileOther = {
                                                ...prevState,
                                                quarterly_report: [
                                                    ...trancheRequest.quarterly_report,
                                                    // file,
                                                ],
                                            };
                                            return updatedFileOther;
                                        });
                                    }

                                }

                                if (type == "supporting_documents") {
                                    const index = trancheRequest.supporting_documents.findIndex((el: any) => el.file_id === response.data.result.file_id);

                                    if (index == -1) {
                                        trancheRequest.supporting_documents = [
                                            ...trancheRequest.supporting_documents,
                                            file,
                                        ];

                                        setTrancheRequest((prevState) => {
                                            const updatedFileOther = {
                                                ...prevState,
                                                supporting_documents: [
                                                    ...trancheRequest.supporting_documents,
                                                    // file,
                                                ],
                                            };
                                            return updatedFileOther;
                                        });
                                    }

                                }

                            });


                            //getActiveTrancheRequest(trancheRequest.application_id);
                        } else {
                            setShowFailureModal(true);
                        }

                        // getActiveTrancheRequest(trancheRequest.startup_application_id);
                    });
                }
            }
        }

    };

    /** upload the files */
    const onFileUploadAccount = (files: Array<File>, type: string) => {

        const user: any = getSessionStorageValue("user");
              const userInfo = JSON.parse(user)

        let indexes: Array<string> = [];

        for (let file of files) {
            // if already upladed then skip
            const fileData = accountDetailsData as any;

            const formData = new FormData();
            // Update the formData object
            formData.append('file', file, file.name);
            formData.append('startup_id',userInfo.id)
            //formData.append('tranche_id', trancheRequest.tranche_id.toString());
            formData.append('file_type', type);
            post(STARTUP_ACCOUNT_DOCUMENT_UPLOAD, formData).then((response: any) => {
                if (!response.data) return;


            });

        }

        if (indexes.length) {
            indexes.map((ind) => {
                const position: number = files.findIndex((file) => file.name === ind);
                files.splice(position, 1);
            });
        }
    };


    // Triggers when delete file is clicked
    const onFileDeleteAccount = (files: File, type: string) => {

        const user: any = getSessionStorageValue("user");
              const userInfo = JSON.parse(user)

        var payload ={
            "startup_id" : userInfo.id,
            "file_type" : type
        }
            post(STARTUP_ACCOUNT_DOCUMENT_DELETE, payload).then((response: any) => {
                if (!response.data) return;


            });

        const {quarterly_report, supporting_documents} = trancheRequest;
        let remainingFiles: Array<FileUploadModel> = [];
        let fileToBeDeleted: any;

        // switch (type) {
        //   case UploadTypeEnum.AnnualReport:
        //     fileToBeDeleted = annual_report.find(
        //       (uploadedFile: FileUploadModel) => uploadedFile.file === file
        //     );

        //     if (!fileToBeDeleted) return;
        //     remainingFiles = annual_report.filter(
        //       (uf: FileUploadModel) => uf.file !== file
        //     );

        //     generalDetail.annual_report = remainingFiles;
        //     onStepSave(
        //       StepperEnum.GeneralDetail,
        //       generalDetail,
        //       requestType.propertyChange
        //     );
        //     break;

        //   case UploadTypeEnum.ProofAssistance:
        //     fileToBeDeleted = proof_assistance.find(
        //       (uploadedFile: FileUploadModel) => uploadedFile.file === file
        //     );

        //     if (!fileToBeDeleted) return;
        //     remainingFiles = annual_report.filter(
        //       (uf: FileUploadModel) => uf.file_id !== fileToBeDeleted.file_id
        //     );
        //     generalDetail.proof_assistance = remainingFiles;
        //     onStepSave(
        //       StepperEnum.GeneralDetail,
        //       generalDetail,
        //       requestType.propertyChange
        //     );
        //     break;

        //   case UploadTypeEnum.IncorporationCertificate:
        //     fileToBeDeleted = incorporation_certificate.find(
        //       (uploadedFile: FileUploadModel) => uploadedFile.file === file
        //     );

        //     if (!fileToBeDeleted) return;
        //     remainingFiles = incorporation_certificate.filter(
        //       (uf: FileUploadModel) => uf.file_id !== fileToBeDeleted.file_id
        //     );
        //     generalDetail.incorporation_certificate = remainingFiles;
        //     onStepSave(
        //       StepperEnum.GeneralDetail,
        //       generalDetail,
        //       requestType.propertyChange
        //     );
        //     break;
        // }
        // // delete the file
        // post(FILE_REMOVE, {
        //   application_id: applicationId, // TODO: dynamic value
        //   file_id: fileToBeDeleted.file_id,
        // }).then((response: any) => {
        //   if (response.data) {
        //     setGeneralDetail(generalDetail);
        //   }
        // });
        // setGeneralDetail(JSON.parse(JSON.stringify(generalDetail)));

        // onStepSave(
        //   StepperEnum.GeneralDetail,
        //   generalDetail,
        //   requestType.propertyChange
        // );
    };

    const onFileDeleteTranche = (file: File, type: string) => {
        const {quarterly_report, supporting_documents} = trancheRequest;
        let remainingFiles: Array<TrancheFileUploadModel> = [];
        let fileToBeDeleted: any;

        switch (type) {

            case UploadTypeEnum.QuarterlyReport:
                fileToBeDeleted = quarterly_report.find(
                    (uploadedFile: TrancheFileUploadModel) => uploadedFile.file === file
                );

                if (!fileToBeDeleted) return;
                remainingFiles = quarterly_report.filter(
                    (uf: TrancheFileUploadModel) => uf.file !== file
                );

                break;

            case UploadTypeEnum.SupportingDocuments:
                fileToBeDeleted = supporting_documents.find(
                    (uploadedFile: TrancheFileUploadModel) => uploadedFile.file === file
                );

                if (!fileToBeDeleted) return;
                remainingFiles = supporting_documents.filter(
                    (uf: TrancheFileUploadModel) => uf.file !== file
                );

                break;
        }
        // delete the file
        post(STARTUP_TRANCHE_REMOVE_DOCUMENT, {
            // startup_application_id: trancheRequest.startup_application_id,
            file_id: fileToBeDeleted.file_id,
            startup_tranche_id: trancheRequest.startup_tranche_id,
        }).then((response: any) => {
            if (response.data.result == "success") {
                // setMessage("Tranche Document Removed Successfully..!")
                setShowSuccessModal(true);
                if (type == UploadTypeEnum.QuarterlyReport) {
                    trancheRequest.quarterly_report = remainingFiles;
                }

                if (type == UploadTypeEnum.SupportingDocuments) {
                    trancheRequest.supporting_documents = remainingFiles;
                }
                setTrancheRequest(trancheRequest);

            } else {
                setShowFailureModal(true);
            }

            // if (response.data.result == "success") {
            //     setShowSuccessModal(true);
            // } else {
            //     setShowFailureModal(true);
            // }
            // getActiveTrancheRequest(trancheRequest.startup_application_id);
        });
    };


    return (
        <>
            <div className="container">
                <div className="row pt-4 pb-5">
                    <div className="col-md-12">
                        <div className="clearfix">
                            <div className="dashboard pt-2 pl-2 pull-left">Fund Details</div>
                            {/* <div className="dropdown dropdown-statup-detail pull-left">
                                <div className="dropdown-statup-detail-label" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    #STRP-3467788990 <i className="fa fa-angle-down" aria-hidden="true"></i></div>
                                <div className="dropdown-menu dropdown-statup-detail-menu" aria-labelledby="dropdownMenuLink">
                                    <div className="dropdown-menu-list-header">
                                        Selected Applications
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-lg-6">
                                            <div className="dropdown-item-name">
                                                STRP-3467788990
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="dropdown-item-check-mark text-right"><i className="fa fa-check" aria-hidden="true"></i></div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-lg-6">
                                            <div className="dropdown-item-name">
                                                STRP-3467788990
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <a className="dropdown-item-link" href="#">View Details</a>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-lg-6">
                                            <div className="dropdown-item-name">
                                                STRP-3467788990
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <a className="dropdown-item-link" href="#">View Details</a>
                                        </div>
                                    </div>
                                    <div className="dropdown-menu-list-header mt-4">
                                        Closed Applications
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-lg-6">
                                            <div className="dropdown-item-name">
                                                STRP-3467788990
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <a className="dropdown-item-link" href="#">View Details</a>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-lg-6">
                                            <div className="dropdown-item-name">
                                                STRP-3467788990
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <a className="dropdown-item-link" href="#">View Details</a>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-lg-6">
                                            <div className="dropdown-item-name">
                                                STRP-3467788990
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <a className="dropdown-item-link" href="#">View Details</a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-9">
                        <ul className="nav nav-tabs chart-nav" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active nav-incubator mt-7" onClick={() => changeActive('1')}
                                   data-toggle="tab" href="#tabs-1" role="tab">
                                    Tranche Details
                                </a>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link nav-incubator mt-7" onClick={() => changeActive('2')}
                                   data-toggle="tab" href="#tabs-2" role="tab">
                                    Progress Overview
                                </a>
                            </li> */}
                            <li className="nav-item">
                                <a className="nav-link nav-incubator mt-7" onClick={() => changeActive('3')}
                                   data-toggle="tab" href="#tabs-3" role="tab">
                                    My Account Details
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-3">
                        <div className="btn-group mb-12">
                            {/* {activeIndex == '1' &&
                            <button
                                type="button"
                                className="btn btn-sisfs btn-yellow-primary white-space-nowrap"
                                data-toggle="modal"
                                data-target="#requestTranche"
                            >
                                Request Tranche
                            </button>
                            } */}
                            {activeIndex == '3' &&
                            <button
                                type="button"
                                className="btn btn-sisfs btn-yellow-primary white-space-nowrap"
                                data-toggle="modal"
                                data-target="#editAccountDetails"
                            >
                                Edit Account Details
                            </button>
                            }
                        </div>
                    </div>
                </div>

                <div className="application-incubator-hr mt--15">
                    <hr/>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="tab-content">
                            <div className="tab-pane active" id="tabs-1">
                                <TrancheDetails
                                    trancheDataModel={trancheData}
                                    trancheDataDESCModel={trancheDataDESC}
                                    trancheDetailsModel={trancheDetailsData}
                                    tracheFundDetailsModel={startupFundDetailsData}
                                    statusId2={statusId}
                                    evDate2={evDate}
                                    isPrefer2={isPrefer}
                                    sel={sel1}
                                />
                            </div>

                            <div className="tab-pane" id="tabs-2">
                                <ProgressStartupUpdateEditInfoModal applicationId={application_id}/>
                            </div>

                            <div className="tab-pane" id="tabs-3">
                                <AccountDetails accountDetailsModel={accountDetailsApi}/>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  */}
                {/* Request tranche model */}

                <div
                    className="modal fade"
                    id="requestTranche"
                    role="dialog"
                    aria-labelledby="exampleModalLabel1"
                    aria-hidden="true"
                >
                    <div
                        className="modal-dialog modal-dialog-slideout modal-md"
                        role="document"
                    >
                        <div className="modal-content">
                            <div className="modal-header modal-header-bottom pr-5">
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body pl-5 pr-5 pt-4">
                                <div className="popup-head mb-4 ml-3">
                                    <div className="main-heading">Tranche Request</div>
                                </div>

                                <div className={errMessage != "" ? "div-disable" : ""}>
                                    <label className="col-md-6 col-form-head">Tranche Number</label>
                                    <div className="col-md-12 mb-4">
                                        <div className="select-custom">
                                            <select
                                                className="form-control input-height"
                                                aria-label="Select Tranche"
                                                id="tranche_id"
                                                name="tranche_id"
                                                value={trancheRequest.startup_tranche_id}
                                                onChange={handleChange}
                                                disabled
                                            >
                                                <option value="">Select Tranche</option>
                                                {activeTranches.map(({label, value}) => (
                                                    <option key={value} value={label}>
                                                        {value}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <label className="col-md-12 col-form-head">
                                        Amount Required
                                    </label>
                                    <div className="col-md-12 mb-4">
                                        <input
                                            type="input"
                                            placeholder="Enter Amount(&#8377;)"
                                            name="amount_required"
                                            className="form-control input-height"
                                            value={trancheRequest.amount_required}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    {/* <div className="row pl-3 mb-5">
                                        <div className="col-md-12">
                                            <label className="col-form-head">
                                                Is your Cash-in -Hand less than 10% of the total
                                                commitment by EAC(including the interest accumulated and
                                                net returns)?
                    </label>
                                            <div className="toggle-btn-group">
                                                <div className="btn-group btn-group-toggle ">
                                                    <label
                                                        className={
                                                            trancheRequest.cash_in_hand === '1'
                                                                ? 'btn active'
                                                                : 'btn'
                                                        }
                                                    >
                                                        <input
                                                            type="radio"
                                                            name="cash_in_hand"
                                                            id="cash_in_hand_1"
                                                            value="1"
                                                            onChange={handleChange}
                                                            checked={trancheRequest.cash_in_hand === '1'}
                                                        />
                          Yes
                        </label>
                                                    <label
                                                        className={
                                                            trancheRequest.cash_in_hand === '0'
                                                                ? 'btn active'
                                                                : 'btn'
                                                        }
                                                    >
                                                        <input
                                                            type="radio"
                                                            name="cash_in_hand"
                                                            id="cash_in_hand_2"
                                                            value="0"
                                                            onChange={handleChange}
                                                            checked={trancheRequest.cash_in_hand === '0'}
                                                        />
                          No
                        </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="row pl-3 mb-4">
                                        <div className="col-md-12">
                                            <label className="col-form-head">Quarterly Reports</label>
                                            <br/>
                                            {trancheRequest.cash_in_hand === '0' ? (
                                                <button
                                                    className="btn btn-sisfs btn-transparent-primary"
                                                    disabled
                                                    onClick={() =>
                                                        setOpenUploadBlock({QuarterlyReport: true})
                                                    }
                                                >
                                                    Upload
                                                </button>
                                            ) : (
                                                <button
                                                    className="btn btn-sisfs btn-transparent-primary"
                                                    onClick={() =>
                                                        setOpenUploadBlock({QuarterlyReport: true})
                                                    }
                                                >
                                                    Upload
                                                </button>
                                            )}

                                            {((openUploadBlock &&
                                                openUploadBlock['QuarterlyReport'] &&
                                                trancheRequest.quarterly_report.length === 0) ||
                                                trancheRequest.quarterly_report.length > 0) && (
                                                <>
                                                        <span
                                                            className="close"
                                                            onClick={() =>
                                                                setOpenUploadBlock({QuarterlyReport: false})
                                                            }
                                                        >
                                                            <i className="fa fa-close"></i>
                                                        </span>
                                                    <FileUploader
                                                        key={Math.random().toString()}
                                                        id={UploadTypeEnum.QuarterlyReport}
                                                        information="Drag & Drop the file or Browse"
                                                        fileType="image/*; application/pdf"
                                                        buttonLabel="Browse"
                                                        multiple={false}
                                                        enablePreview
                                                        IconComponent={() => (
                                                            <span className="fa fa-cloud-upload"></span>
                                                        )}
                                                        removeIconClass=""
                                                        existingFiles={trancheRequest.quarterly_report.map(
                                                            (uploadedFile: TrancheFileUploadModel) => uploadedFile.file
                                                        )}
                                                        onUploadFinish={(files: Array<File>) => {
                                                            onFileUploadTranche(files, UploadTypeEnum.QuarterlyReport);
                                                        }}
                                                        onFileDelete={(deletedFile: File) =>
                                                            onFileDeleteTranche(
                                                                deletedFile,
                                                                UploadTypeEnum.QuarterlyReport
                                                            )
                                                        }
                                                    />
                                                </>
                                            )}

                                            <br/>

                                            <label className="incubator-label">Optional</label>
                                        </div>
                                    </div>

                                    <div className="row pl-3 mb-4">
                                        <div className="col-md-12">
                                            <label className="col-form-head">
                                                Supporting Documents
                                            </label>

                                            <br/>
                                            {trancheRequest.cash_in_hand === '0' ? (
                                                <button
                                                    disabled
                                                    className="btn btn-sisfs btn-transparent-primary"
                                                    onClick={() =>
                                                        setOpenUploadBlock({SupportingDocuments: true})
                                                    }
                                                >
                                                    Upload
                                                </button>
                                            ) : (
                                                <button
                                                    className="btn btn-sisfs btn-transparent-primary"
                                                    onClick={() =>
                                                        setOpenUploadBlock({SupportingDocuments: true})
                                                    }
                                                >
                                                    Upload
                                                </button>
                                            )}
                                            {((openUploadBlock &&
                                                openUploadBlock['SupportingDocuments'] &&
                                                trancheRequest.supporting_documents.length === 0) ||
                                                trancheRequest.supporting_documents.length > 0) && (
                                                <>
                                                        <span
                                                            className="close"
                                                            onClick={() =>
                                                                setOpenUploadBlock({SupportingDocuments: false})
                                                            }
                                                        >
                                                            <i className="fa fa-close"></i>
                                                        </span>
                                                    <FileUploader
                                                        key={Math.random().toString()}
                                                        id={UploadTypeEnum.SupportingDocuments}
                                                        information="Drag & Drop the file or Browse"
                                                        fileType="application/pdf"
                                                        buttonLabel="Browse"
                                                        multiple
                                                        enablePreview
                                                        IconComponent={() => (
                                                            <span className="fa fa-cloud-upload"></span>
                                                        )}
                                                        removeIconClass=""
                                                        existingFiles={trancheRequest.supporting_documents.map(
                                                            (uploadedFile: FileUploadModel) => uploadedFile.file
                                                        )}
                                                        onUploadFinish={(files: Array<File>) => {
                                                            onFileUploadTranche(
                                                                files,
                                                                UploadTypeEnum.SupportingDocuments
                                                            );
                                                        }}
                                                        onFileDelete={(deletedFile: File) =>
                                                            onFileDeleteTranche(
                                                                deletedFile,
                                                                UploadTypeEnum.SupportingDocuments
                                                            )
                                                        }
                                                    />
                                                </>
                                            )}

                                            <br/>
                                            <label className="incubator-label">Optional</label>
                                        </div>
                                    </div>
                                    <div className="row pl-3">
                                        <div className="col-md-12">
                                            {errMessage != '' ? (
                                                <span className="error-message">&#42; {errMessage}</span>
                                            ) : (
                                                ''
                                            )}
                                            <br/> <br></br>
                                            {trancheRequest.amount_required <= 0 ? (
                                                <span className="error-message">
                                                    &#42; Amount Required should be greater than zero.
                                                </span>
                                            ) : (
                                                ''
                                            )}
                                            <br></br>
                                            <br/>
                                            {trancheRequest.cash_in_hand == '0' ? (
                                                <span className="error-message">
                                                    &#42; You are not allowed to request tranche if your
                                                    cash in hand is less than 10% of the total commitment by
                                                    EAC.
                                                </span>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>

                                    <div className="application-incubator-hr">
                                        <hr/>
                                    </div>

                                    <div className="row pl-3">
                                        <div className="col-md-6">
                                            <button
                                                className="btn btn-sisfs btn-transparent-primary full-width"
                                                data-dismiss="modal"
                                            >
                                                CANCEL
                                            </button>
                                        </div>
                                        <div className="col-md-6">
                                            {trancheRequest.cash_in_hand === '0' ||
                                            isEnable == false ? (
                                                <button
                                                    disabled
                                                    className="tertiary  btn-yellow-primary full-width"
                                                    //
                                                    onClick={() => storeTrancheRequest()}
                                                >
                                                    Request Tranche
                                                </button>
                                            ) : (
                                                <button
                                                    className="tertiary  btn-yellow-primary full-width"
                                                    data-dismiss="modal"
                                                    onClick={() => storeTrancheRequest()}
                                                >
                                                    Request Tranche
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  */}
                {/*  */}
                {/* Edit Account Details */}
                <div
                    className="modal fade"
                    id="editAccountDetails"
                    role="dialog"
                    aria-labelledby="exampleModalLabel1"
                    aria-hidden="true"
                >
                    <div
                        className="modal-dialog modal-dialog-slideout modal-md"
                        role="document"
                    >
                        <div className="modal-content">
                            <div className="modal-header modal-header-bottom pr-5">
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body pl-5 pr-5 pt-5">
                                <div className="popup-head mb-5 ml-3">
                                    <div className="main-heading">Edit Account Details</div>
                                </div>
                                <label className="col-md-8 col-form-head">
                                    Bank Account Holder Name
                                </label>
                                <div className="col-md-12 mb-4">
                                    {isDisabledAccountDetails.bank_account_holder_name == true ? (
                                        <input
                                            disabled
                                            type="input"
                                            placeholder="Bank Account Holder Name"
                                            name="bank_account_holder_name"
                                            className="form-control input-height"
                                            value={accountDetailsData.bank_account_holder_name}
                                            onChange={handleChangeAccountDetails}
                                        />
                                    ) : (
                                        <input
                                            type="input"
                                            placeholder="Bank Account Holder Name"
                                            name="bank_account_holder_name"
                                            className="form-control input-height"
                                            value={accountDetailsData.bank_account_holder_name}
                                            onChange={handleChangeAccountDetails}
                                        />
                                    )}
                                    {/* {console.log(accountDetailsData.bank_account_holder_name === null ?"mohan":"lanke","accountDetailsDataaaa")} */}
                                </div>
                                {/* <div className="col-md-12 mb-4">
                                    <label className="col-md-12 col-form-head">
                                        Proof of PFMS Registration
                                    </label>
                                    <br/>
                                    {isDisabledAccountDetails.pan_card == true ? (
                                        <button
                                            disabled
                                            className="btn btn-sisfs btn-transparent-primary"
                                            onClick={() =>
                                                setOpenUploadBlock({PAN: true})
                                            }
                                        >
                                            Upload
                                        </button>
                                    ) : <button
                                        className="btn btn-sisfs btn-transparent-primary"
                                        onClick={() =>
                                            setOpenUploadBlock({PAN: true})
                                        }
                                    >
                                        Upload
                                    </button>}

                                    {((openUploadBlock
                                        || uploadedPan.length != 0
                                    ))
                                    && (
                                        <>
                                                <span
                                                    className="close"
                                                    onClick={() =>
                                                        setOpenUploadBlock({PAN: false})
                                                    }
                                                >
                                                    <i className="fa fa-close"></i>
                                                </span>

                                            <FileUploader
                                                key={Math.random().toString()}
                                                id={UploadTypeEnum.PAN}
                                                information="Drag & Drop the file or Browse"
                                                fileType="image/*; application/pdf"
                                                buttonLabel="Browse"
                                                multiple={false}
                                                enablePreview
                                                IconComponent={() => (
                                                    <span className="fa fa-cloud-upload"></span>
                                                )}
                                                removeIconClass=""

                                                existingFiles={uploadedPan.map(
                                                    (uploadedFile: FileUploadModelTranche) =>
                                                        (uploadedFile.file)
                                                )}

                                                onUploadFinish={(files: Array<File>) => {
                                                    onFileUploadAccount(files, UploadTypeEnum.PAN);
                                                }}
                                                onFileDelete={(deletedFile: File) =>
                                                    onFileDeleteAccount(
                                                        deletedFile,
                                                        UploadTypeEnum.PAN
                                                    )
                                                }
                                            />
                                        </>
                                    )}
                                </div> */}
                                <div className="col-md-12 mb-4">
                                    <label className="col-md-12 col-form-head">
                                        {/* Proof of TRA Account & ECS Mandate Form */}
                                        ECS mandate form of startup
                                    </label>
                                    <br/>
                                    {isDisabledAccountDetails.cancelled_cheque == true ? (
                                            <button
                                                disabled
                                                className="btn btn-sisfs btn-transparent-primary"
                                                onClick={() =>
                                                    setOpenUploadBlock({CancelledCheque: true})
                                                }
                                            >
                                                Upload
                                            </button>
                                        ) :
                                        <button
                                            className="btn btn-sisfs btn-transparent-primary"
                                            onClick={() =>
                                                setOpenUploadBlock({CancelledCheque: true})
                                            }
                                        >
                                            Upload
                                        </button>
                                    }

                                    {((openUploadBlock || uploadedCheque.length != 0
                                    ))
                                    && (
                                        <>
                                                <span
                                                    className="close"
                                                    onClick={() =>
                                                        setOpenUploadBlock({CancelledCheque: false})
                                                    }
                                                >
                                                    <i className="fa fa-close"></i>
                                                </span>
                                            <FileUploader
                                                key={Math.random().toString()}
                                                id={UploadTypeEnum.CancelledCheque}
                                                information="Drag & Drop the file or Browse"
                                                fileType="image/*; application/pdf"
                                                buttonLabel="Browse"
                                                multiple={false}
                                                enablePreview
                                                IconComponent={() => (
                                                    <span className="fa fa-cloud-upload"></span>
                                                )}
                                                removeIconClass=""
                                                existingFiles={uploadedCheque.map(
                                                    (uploadedFile: FileUploadModelTranche) =>
                                                        (uploadedFile.file)
                                                )}
                                                onUploadFinish={(files: Array<File>) => {
                                                    onFileUploadAccount(files, UploadTypeEnum.CancelledCheque);
                                                }}
                                                onFileDelete={(deletedFile: File) =>
                                                    onFileDeleteAccount(
                                                        deletedFile,
                                                        UploadTypeEnum.CancelledCheque
                                                    )
                                                }
                                            />
                                        </>
                                    )}
                                </div>

                                <label className="col-md-6 col-form-head">Bank Name</label>
                                <div className="col-md-12 mb-4">
                                    {isDisabledAccountDetails.bank_name == true ? (
                                        <input
                                            disabled
                                            type="input"
                                            placeholder="Bank Name"
                                            name="bank_name"
                                            className="form-control input-height"
                                            value={accountDetailsData.bank_name}
                                            onChange={handleChangeAccountDetails}
                                        />
                                    ) : (
                                        <input
                                            type="input"
                                            placeholder="Bank Name"
                                            name="bank_name"
                                            className="form-control input-height"
                                            value={accountDetailsData.bank_name}
                                            onChange={handleChangeAccountDetails}
                                        />
                                    )}
                                    {/* {console.log(accountDetailsData.bank_name == "" || null || undefined ? 'mohan' :'lanke',"accountttt")} */}
                                </div>
                                <label className="col-md-12 col-form-head">
                                    Branch/Branch Code
                                </label>
                                <div className="col-md-12 mb-4">
                                    {isDisabledAccountDetails.branch_name == true ? (
                                        <input
                                            disabled
                                            type="input"
                                            placeholder="Branch"
                                            name="branch_name"
                                            className="form-control input-height"
                                            value={accountDetailsData.branch_name}
                                            onChange={handleChangeAccountDetails}
                                        />
                                    ) : (
                                        <input
                                            type="input"
                                            placeholder="Branch"
                                            name="branch_name"
                                            className="form-control input-height"
                                            value={accountDetailsData.branch_name}
                                            onChange={handleChangeAccountDetails}
                                        />
                                    )}
                                </div>
                                {/* <div className="row pl-3 mb-4"> */}
                                <div className="col-md-12 mb-4">
                                    <label className="col-form-head">Account Type</label>
                                    {isDisabledAccountDetails.account_type == true ? (
                                        <select
                                            disabled
                                            className="form-control input-height"
                                            onChange={handleChangeAccountDetails}
                                            name="account_type"
                                            value={accountDetailsData.account_type}
                                            aria-label="Select Account Type"
                                        >
                                            <option value="">
                                            {/* selected */}
                                                Select
                                            </option>
                                            <option value="1">Saving</option>
                                            <option value="2">Current</option>
                                            <option value="3">Salary</option>
                                            <option value="4">Fixed Deposit</option>
                                            <option value="5">Recurring Deposit</option>
                                        </select>
                                    ) : (
                                        <select
                                            className="form-control input-height"
                                            onChange={handleChangeAccountDetails}
                                            name="account_type"
                                            value={accountDetailsData.account_type}
                                            aria-label="Select Account Type"
                                        >
                                            <option value="">
                                            {/* selected */}
                                                Select
                                            </option>
                                            <option value="1">Saving</option>
                                            <option value="2">Current</option>
                                            <option value="3">Salary</option>
                                            <option value="4">Fixed Deposit</option>
                                            <option value="5">Recurring Deposit</option>
                                        </select>
                                    )}
                                    {/* <input
                  type="input"
                  placeholder="Account Type "
                  name="account_type"
                  className="form-control input-height"
                  value={accountDetailsData.account_type}
                  onChange={handleChangeAccountDetails}
                /> */}
                                </div>
                                {/* </div> */}
                                {/* <div className="row pl-3 mb-4"> */}
                                <div className="col-md-12 mb-4">
                                    <label className="col-form-head">Account Number</label>
                                    {isDisabledAccountDetails.account_number == true ? (
                                        <input
                                            disabled
                                            type="input"
                                            placeholder="Account Number "
                                            name="account_number"
                                            className="form-control input-height"
                                            value={accountDetailsData.account_number}
                                            onChange={handleChangeAccountDetails}
                                        />
                                    ) : (
                                        <input
                                            type="input"
                                            placeholder="Account Number "
                                            name="account_number"
                                            className="form-control input-height"
                                            value={accountDetailsData.account_number}
                                            onChange={handleChangeAccountDetails}
                                        />
                                    )}
                                </div>
                                {/* </div> */}

                                {/* <div className="row pl-3 mb-4"> */}
                                <div className="col-md-12 mb-4">
                                    <label className="col-form-head">IFSC Code</label>
                                    <br/>
                                    {isDisabledAccountDetails.bank_ifsc_code == true ? (
                                        <input
                                            disabled
                                            type="input"
                                            placeholder="IFSC Code"
                                            name="bank_ifsc_code"
                                            className="form-control input-height"
                                            value={accountDetailsData.bank_ifsc_code}
                                            onChange={handleChangeAccountDetails}
                                        />
                                    ) : (
                                        <input
                                            type="input"
                                            placeholder="IFSC Code"
                                            name="bank_ifsc_code"
                                            className="form-control input-height"
                                            value={accountDetailsData.bank_ifsc_code}
                                            onChange={handleChangeAccountDetails}
                                        />
                                    )}
                                </div>
                                {/* </div> */}

                                {/* <div className="row pl-3 mb-4"> */}
                                <div className="col-md-12 mb-4">
                                    <label className="col-form-head">MICR Code</label>
                                    <br/>
                                    {isDisabledAccountDetails.bank_micr_code == true ? (
                                        <input
                                            disabled
                                            type="input"
                                            placeholder="MICR Code"
                                            name="bank_micr_code"
                                            className="form-control input-height"
                                            value={accountDetailsData.bank_micr_code}
                                            onChange={handleChangeAccountDetails}
                                        />
                                    ) : (
                                        <input
                                            type="input"
                                            placeholder="MICR Code"
                                            name="bank_micr_code"
                                            className="form-control input-height"
                                            value={accountDetailsData.bank_micr_code}
                                            onChange={handleChangeAccountDetails}
                                        />
                                    )}
                                </div>
                                {/* </div> */}
                                {/* <div className="row pl-3 mb-4"> */}
                                <div className="col-md-12 mb-4">
                                    <label className="col-form-head">Branch Address</label>
                                    <br/>
                                    {isDisabledAccountDetails.branch_address == true ? (
                                        <input
                                            disabled
                                            type="input"
                                            placeholder="Branch Address"
                                            name="branch_address"
                                            className="form-control input-height"
                                            value={accountDetailsData.branch_address}
                                            onChange={handleChangeAccountDetails}
                                        />
                                    ) : (
                                        <input
                                            type="input"
                                            placeholder="Branch Address"
                                            name="branch_address"
                                            className="form-control input-height"
                                            value={accountDetailsData.branch_address}
                                            onChange={handleChangeAccountDetails}
                                        />
                                    )}
                                </div>
                                {/* </div> */}

                                <div className="application-incubator-hr">
                                    <hr/>
                                </div>

                                {errorMessage &&
                                <p className="alert alert-danger mt-2 mb-2">
                                    {errorMessage}
                                </p>
                                }

                                <div className="row pl-3">
                                    <div className="col-md-6">
                                        <button
                                            className="btn btn-sisfs btn-transparent-primary full-width"
                                            data-dismiss="modal"
                                        >
                                            CANCEL
                                        </button>
                                    </div>
                                    <div className="col-md-6">
                                        <button
                                            className="tertiary  btn-yellow-primary full-width"
                                            onClick={() => storeAccountDetails()}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/*PROGRESS UPDATE MODAL */}


                {/*  */}

                {/*  */}
                {showEditSuccessModal && (
                    <>
                        <div className="modal-backdrop fade show"></div>

                        <div
                            className="modal align-middle modal-sisfs d-block"
                            id="FormSubmissionSuccess"
                        >
                            <div className="modal-dialog modal-dialog-centered modal-md">
                                <div className="modal-content">
                                    <div className="modal-body text-center">
                                        <i className="fa fa-check-circle-o"></i>
                                        <h3 className="modal-message">Thank You !</h3>
                                        <div className="modal-text">
                                            Tranche Request Submitted Successfully !!
                                        </div>
                                        <div className="modal-action">
                                            <button
                                                className="btn btn-sisfs btn-yellow-primary"
                                                onClick={dismissModal}
                                            >
                                                Ok !!
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {showEditFailureModal && (
                    <>
                        <div className="modal-backdrop fade show"></div>
                        <div
                            className="modal align-middle modal-sisfs d-block"
                            id="FormSubmissionError"
                        >
                            <div className="modal-dialog modal-dialog-centered modal-md">
                                <div className="modal-content">
                                    <div className="modal-body text-center">
                                        {/* <i className="fa fa-times-circle-o"></i> */}
                                        <h3 className="modal-message message-orange">
                                            Oops! Something is Wrong
                                        </h3>
                                        <br/>
                                        <h3 className="modal-message message-orange">
                                            Please Try Again !!!
                                        </h3>
                                        <div className="modal-action">
                                            <button
                                                className="btn btn-sisfs btn-yellow-primary"
                                                onClick={dismissModal}
                                            >
                                                OK !
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {/*  */}
            </div>


            {successMessage &&
            <ConfirmationDialog
                headerText="Success"
                bodyText={successMessage}
                onDialogClose={() => window.location.reload()}
            />
            }


        </>
    );

};

export default StartupFundDetailsContainer;
