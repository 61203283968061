import React, { useEffect, useState } from "react";
import Stepper from "bs-stepper";
import { FundDetailsModel } from "../../../models/incubator/FundDetailsModel";
import { SaveRequestModel } from "../../../models/incubator/SaveRequestModel";
import { post } from "../../../effects/api/api.common";
import { STORE_INCUBATOR_APPLICATION } from "../../../constants/api";
import IncrementDecrement from "../../common/controls/IncrementDecrement";
import { requestType, StepperEnum } from "../../../utils/Enums";
import { validateNumber } from "../../../effects/utils/validations";
import { useHistory } from "react-router-dom";

interface IIncubatorFundDetails {
  stepper: any;
  fundDetailsModel: FundDetailsModel;
  onStepSave: (stepperIndex: number, fundsModel: FundDetailsModel, request: number) => void;
  errors: Array<string>;
  disableForm:any;
}

function FundDetailsComponent(props: IIncubatorFundDetails) {
  const { stepper, fundDetailsModel, onStepSave,disableForm } = props;

  // const fundDetailsModel = new FundDetailsModel("", 1, 1, 1);

  const [fundDetails, setFundDetails] = useState<FundDetailsModel>(
    new FundDetailsModel("", 1, 0, 0)
  );

  useEffect(() => {

    setFundDetails(fundDetailsModel);
  }, []);
  const handleBlur = () => {
    const amount = parseInt(fundDetails.quantum_fund_required);
    if (amount > 50000000) {
      const validationError = validateNumber(amount, 50000000);
      // Handle the validation error, e.g., show a message or update the state
      console.log(validationError);
    }
  };
  // triggers whenever user makes any changes in the form for select and input type
  const handleChange = (
    evt: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const name = evt.target.name;
    const value = evt.target.value.toString().split(".").map((el,i)=>i?el.split("").slice(0,2).join(""):el).join(".");
    let tempFundDetails = JSON.parse(JSON.stringify(fundDetails));
    // !isNaN(parseInt(value))
    if (value) {
      tempFundDetails[name] = value
      setFundDetails(tempFundDetails);
    }
    if (value == '') {
      tempFundDetails[name] = '';
      setFundDetails(tempFundDetails);
    }
    // if (value!="" && !isNaN(parseInt(value))) {
    //   tempFundDetails[name] = parseInt(value)
    //   setFundDetails(tempFundDetails);
    // }
    // if (value=="") {
    //   tempFundDetails[name] = ""
    //   setFundDetails(tempFundDetails);
    // }
    // else if (isNaN(parseInt(value))) {
    //   return false;
    // }
    onStepSave(
      StepperEnum.FundUtilization,
      tempFundDetails,
      requestType.propertyChange
    );
  };

  const handleIncrementDecrementChange = (name: string, value: number) => {
    setFundDetails((prevState: FundDetailsModel) => {
      return {
        ...prevState,
        [name]: (value <= 0) ? 0 : value,
      };
    });
  };

  const history = useHistory();
      
    const applicationPage =() =>{
            history.push({
              pathname: "/incubator_status",
            });
      }
  // triggers when user clicks on the save and next button
  const onSave = () => {
    console.log(fundDetails.quantum_fund_required,"mohan")
    onStepSave(StepperEnum.FundUtilization, fundDetails, requestType.save);

    stepper && stepper.next();
    window.scrollTo(0, 250);
  };

  return (
    <div
      id="incubator-step-4"
      role="tabpanel"
      className="bs-stepper-pane content"
      aria-labelledby="stepper1trigger4"
    >
      {/* {console.log('VALIDATION', props.errors)} */}
      {/* Incubator Stepper Form 4 */}
      <fieldset disabled={disableForm}>
      <div className="form-wrapper stepper-form stepper-form-4">
        <div className="form-content">
          <div className="form-group required row">
            <label className="col-sm-4 col-form-label">
              Quantum of fund required
          </label>
            <div className="col-sm-4">
              <input
                type="number"
                name="quantum_fund_required"
                className={`${props.errors.length && validateNumber(parseInt(fundDetails.quantum_fund_required), 50000000) ? `form-control error` : 'form-control'}`}
                // placeholder="Enter (₹)"
                placeholder="Enter (&#8377;)"
                value={fundDetails.quantum_fund_required}
                onChange={handleChange}
                required={true}
                onBlur={handleBlur}
              />
              {/* Rupee symbol */}
              <small>Max. &#8377; 5,00,00,000</small>
              <div className={`${props.errors.length && validateNumber(parseInt(fundDetails.quantum_fund_required), 50000000) ? `col-12 pl-0 error` : 'invalid-feedback '}`}>
                {validateNumber(parseInt(fundDetails.quantum_fund_required), 50000000)}</div>
            </div>
          </div>

          <div className="form-group required row">
            <label className="col-sm-4 col-form-label">
              Expected number of fund beneficiaries in year 1
          </label>
            <div className="col-sm-4">
              <IncrementDecrement
                name="no_fund_benifitiaries_1yr"
                value={fundDetails.no_fund_benifitiaries_1yr}
                onChange={(name: string, value: number) =>
                  handleIncrementDecrementChange(
                    "no_fund_benifitiaries_1yr",
                    value
                  )
                }
                minValue={1}
              />
            </div>
          </div>

          <div className="form-group required row">
            <label className="col-sm-4 col-form-label">
              Expected number of fund beneficiaries in year 2
          </label>
            <div className="col-sm-4">
              <IncrementDecrement
                name="no_fund_benifitiaries_2yr"
                value={fundDetails.no_fund_benifitiaries_2yr}
                onChange={(name: string, value: number) =>
                  handleIncrementDecrementChange(
                    "no_fund_benifitiaries_2yr",
                    value
                  )
                }
                minValue={0}
              />
            </div>
          </div>

          <div className="form-group required row">
            <label className="col-sm-4 col-form-label">
              Expected number of fund beneficiaries in year 3
          </label>
            <div className="col-sm-4">
              <IncrementDecrement
                name="no_fund_benifitiaries_3yr"
                value={fundDetails.no_fund_benifitiaries_3yr}
                onChange={(name: string, value: number) =>
                  handleIncrementDecrementChange(
                    "no_fund_benifitiaries_3yr",
                    value
                  )
                }
                minValue={0}
              />
            </div>
          </div>
        </div>

        <div className="stepper-footer-section">
          <div>
            <div
              className="btn-toolbar justify-content-between"
              role="toolbar"
              aria-label="Toolbar with button groups"
            >
              <button className="btn btn-sisfs btn-transparent-primary" onClick={()=>applicationPage()}>
                Cancel
              </button>
              <span className="stepper-page-count">Step 4 / 5</span>

              <div className="btn-group">
                <button
                  className="btn btn-sisfs btn-transparent-primary"
                  onClick={() => stepper && stepper.previous()}
                >
                  Previous
                </button>
                <button
                  className="btn btn-sisfs btn-yellow-primary"
                  onClick={onSave}
                >
                  Save And Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      </fieldset>
      {/* Incubator Stepper Form 5 */}
    </div>
  );
}

export default FundDetailsComponent;
