import * as React from "react";
import "../../../styles/component.css";
import {
  GET_MEETING_DETAILS,
  GET_ALL_INCUBATORS,
  REMOVE_INCUBATOR,
} from "../../../constants/api";
import { get, post } from "../../../effects/api/api.common";
import { useEffect, useState } from "react";
import Calendar from "../../common/controls/Calendar";
import { Link, useHistory } from "react-router-dom";
import search from "../../../images/search.svg";
import { FormatDate } from "../../../utils/DateFormat";

function IncubatorSelectionComponent(props: any) {
  const [incubatorData, setIncubatorData] = useState({
    meeting_id: "",
    incubator_count: "",
    time_string: "",
    date_string: "",
  });
  const [incubatorSelectionData, setIncubatorSelectionData] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (typeof props.location.state !== "undefined") {
      setIncubatorData({
        meeting_id: props.location.state.meeting_id,
        incubator_count: props.location.state.incubator_count,
        time_string: props.location.state.time_string,
        date_string: props.location.state.date_string,
      });
      getIncubatorDetails(props.location.state.meeting_id);
    }
  }, []);
  const getIncubatorDetails = (id: any) => {
    get(`${GET_ALL_INCUBATORS}/${id}`).then((response: any) => {
      if (!response || !response.data) return;
      setIncubatorSelectionData(response.data.meetingId);
    });
  };

  return (
    <>
      <div className="container container-min pt-5">
        <div className="row mb-4">
          <div className="col-md-6">
            <p className="dashboard pt-2 pl-3">
              {" "}
              Incubator Selection Meeting ({incubatorData.incubator_count})
            </p>
          </div>
        </div>

        <div className="row incubator-heading-review">
          <div className="col-md-6 text-left">
            <ul className="nav nav-tabs chart-nav" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" role="tab">
                  {FormatDate(incubatorData.date_string)} |{" "}
                  {incubatorData.time_string}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-6 text-right search-left">
            <div className="d-flex searchDiv">
              {" "}
              <img src={search} className="search" alt="search" />
              <input
                className="searchBar"
                type="text"
                placeholder="Search.."
                name="search"
              />
            </div>
          </div>
        </div>
        <div className="tab-content mb-4">
          <div className="tab-pane active">
            <div className="table-scroll mb-5">
              <table id="example">
                <thead>
                  <tr>
                    <th className="text-left custom-td-left">Incubator Name</th>
                    <th className="text-left">Location</th>
                    <th className="text-left">Sector</th>
                    <th className="text-left">Industry</th>
                    <th className="text-left"></th>
                  </tr>
                </thead>
                <tbody>
                  {incubatorSelectionData.length == 0 ? (
                    <tr>
                      <td className="text-center" colSpan={5}>
                        No data
                      </td>
                    </tr>
                  ) : (
                    incubatorSelectionData.map((item: any) => (
                      <tr>
                        <td className="text-left custom-td-left">
                          <div>{item.incubator_application_name}</div>
                          <span className="tableDataApplication">
                            No:-{" "}
                            {item.incubator_application_corporate_id_no == null
                              ? "NA"
                              : item.incubator_application_corporate_id_no}
                          </span>
                        </td>
                        <td className="text-left custom-td-small">
                          <div>{item.state_name}</div>
                          <span className="tableDataApplication">
                            {item.city_name}
                          </span>
                        </td>
                        <td className="text-left custom-td-small">
                        <span className="tableDataApplication">
                            {item.sectors}
                          </span>
                        </td>
                        <td className="text-left custom-td-small">
                        <span className="tableDataApplication">
                            {item.industries}
                          </span>
                        </td>
                        <td className="text-left custom-td-small">
                          <a
                            className="btn btn-sisfs btn-transparent-primary"
                            onClick={() => {
                              history.push({
                                pathname: "/eac_review",
                                state: {
                                  // location state
                                  incubator_application_id: item.user_id,
                                },
                              });
                            }}
                          >
                            Evaluate
                          </a>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default IncubatorSelectionComponent;
