import moment from 'moment';
import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getallDocuments, MERGED_COMMENTS, GET_ALL_TRANCHES, GET_INCUBATOR_DETAILS, INCUBATOR_CANCEL_DETAIL, mergedfirstapplicationdetails, mergedsecndapplicationdetails, SCHEDULE_MEETING, MERGED_SCHEDULE_MEETING, secgreybox, secincapplicationdetails, getallEACComments, MERGED_FUND_DETAILS, MERGED_BANK_DETAILS, RE_INCUBATOR_CANCEL_DETAIL } from '../../../../constants/api';
import { get, post } from '../../../../effects/api/api.common';
import { getSessionStorageValue } from '../../../../effects/utils/session-storage';
import { AccountFileUploadModel } from '../../../../models/FileUploadModel';
import { AccountDetailsData, AccountDetailsData1, AccountDetailsData2, SubsequentDetails } from '../../../../models/funddetails/AccountDetailsData';
import { TrancheDetail } from '../../../../models/funddetails/TrancheDetails';
import UserInfoModel from '../../../../models/UserInfoModel';
import { changeNumberFormat, createFile } from '../../../../utils/CommonMethods';
import { compareTime } from '../../../../utils/DateFormat';
import { UploadTypeEnum } from '../../../../utils/Enums';
import Calendar from '../../../common/controls/Calendar';
import { ApplicationGeneralDetails, ApplicationStatus, OthersModel } from '../ApplicationGeneralDetails';
import { IncubatorDetails, IncubatorDetails1 } from '../IncubatorDetails';
import MergedCancellationDetailsComponent from './MergedCancellationDetailsComponent';
import MergedCommentsDetailsComponent from './MergedCommentsDetailsComponent';
import MergedContactDetailsComponent from './MergedContactDetailsComponent';
import MergedDocumentsDetailsComponent from './MergedDocumentsDetailsComponent';
import MergedEACDetailsComponent from './MergedEACDetailsComponent';
import MergedFirstApplicationDetailsComponent from './MergedFirstApplicationDetailsComponent';
import MergedFundDetailsComponent from './MergedFundDetailsComponent';
import MergedLeftSidebarDetailsComponent from './MergedLeftSidebarDetailsComponent';
import MergedReappliedApplicationDetailsComponent from './MergedReappliedApplicationDetailsComponent';
import MergedRetrnsDetailsComponent from './MergedReturnsDetailsCompomnent';

declare var $: any;
interface IIncubatorDetailsProps {
  application_id: number;
}

interface IIncubatorDetailsState {
  //incubator: IIncubatorModel;
  incubatorDetailsModel: IncubatorDetails1;
  applicationGeneral: ApplicationGeneralDetails;
  otherDetailsModel: OthersModel;
  isSelectedOrClosed: boolean;
  applicationId: number;
  checkedArray: Array<string>;
  fromTime: string;
  toTime: string;
  meetingLink: string,
  incubatorMeetingType: string;
  scheduleDate: String;
  selectFlag: boolean;
  showEditFailureModalPopUp: boolean;
  showEditSuccessModalPopUp: boolean;
  showMeetingFromTime: boolean;

  sch_meetingFromTime: boolean;
  sch_meetingToTime: boolean;

  timeValidation: boolean;
  timeValidationCurrentTime: boolean
  showMeetingToTime: boolean;
  showMeetingLink: boolean;
  showMeetingComment: boolean;
  applicationStatus: Array<ApplicationStatus>;
  application_status: number;
  application_comments: string;
  successMsg: string;
  user: boolean;
  loggedInRole: string;
  history: any;
  tranches: Array<TrancheDetail>;
  tranchesAsc: Array<TrancheDetail>;
  bankDetails1: AccountDetailsData1;
  bankDetails2: AccountDetailsData2;
  subsequentDtls: SubsequentDetails;
  total_grant_disbursed: number;
  isFundDetailsTabActive: boolean;
  cancellationData: Array<any>;
  commentData: Array<any>;
  count_startups_supported: string;
  amount_disbursed: string;
  // total_management_amount:string;
  user_id: string;
  total_management_amount: number;
  meetingComment: string

  Documents_API_Data: any
  Comments_API_Data: any
  FirstApplication_Details: any
  SecondApplication_Details: any
  LeftSideBox_API_Data: any
  LeftSideBox_API_Data1: any
  EAC_API_Data: any
  industrydata_Details: any;
  industrydata_Details2: any;
  sectorData_Details: any;
  sectorData_Details2: any;
  teamsData_Details: any;
  amountDisbursedToInc: any;
  amountDisbursedToStartup: any;

}

class MergedIncubatorDetailsComponent extends Component<
  IIncubatorDetailsProps
// IIncubatorDetailsState
> {
  state: IIncubatorDetailsState = {
    applicationGeneral: new ApplicationGeneralDetails(
      0,
      0,
      '',
      0,
      0,
      '',
      '',
      '',
      0,
      '',
      '',
      '',
      0,
      '',
      0,
      0,
      0,
      [],
      '',
      '',
      '',
      '',
      0,
      '',
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      '',
      0,
      '',
      0,
      '',
      0,
      '',
      0,
      '',
      0,
      '',
      0,
      '',
      0,
      '',
      0,
      '',
      0,
      0,
      0,
      0,
      0,
      '',
      0,
      0,
      0,
      0,
      0,
      false,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      [],
      [],
      [],
      new OthersModel([], [], [], [], [], [], [], [], [], [], [], [], []),
      '',
      '',
      '',
      '',
      '',
      '',
      0,
      0,
      0,
      '',
      '',
      '',
      '',

    ),
    incubatorDetailsModel: new IncubatorDetails1(
      // 0,
      // 0,
      // 0,
      // '',
      // 0,
      // 0,
      // '',
      // '',
      // '',
      // 0,
      // '',
      // '',
      // '',
      // 0,
      // '',
      // 0,
      // 0,
      // 0,
      // [],
      // '',
      // '',
      // '',
      // '',
      // 0,
      // '',
      // 0,
      // 0,
      // 0,
      // 0,
      // 0,
      // 0,
      // 0,
      // 0,
      // 0,
      // 0,
      // 0,
      // 0,
      // 0,
      // 0,
      // 0,
      // 0,
      // 0,
      // 0,
      // 0,
      // 0,
      // 0,
      // 0,
      // 0,
      // 0,
      // 0,
      // 0,
      // 0,
      // 0,
      // 0,
      // '',
      // '',
      // 0,
      // 0,
      // 0,
      // 0,
      // 0,
      // 0,
      // 0,
      // false,
      // '',
      // '',
      // '',
      // '',
      // '',
      // '',
      // '',
      // '',
      // '',
      // [],
      // 0,
      // [],
      // [],
      // [],
      [],
      [],
      [],
      0,
      0,
      0,
      0,
      0,
      0,
      0
    ),
    otherDetailsModel: new OthersModel([], [], [], [], [], [], [], [], [], [], [], [], []),
    isSelectedOrClosed: true,
    applicationId: 0,
    checkedArray: [],
    fromTime: '',
    toTime: '',
    meetingLink: '',
    incubatorMeetingType: '1',
    scheduleDate: '',
    selectFlag: true,

    showEditFailureModalPopUp: false,
    showEditSuccessModalPopUp: false,
    showMeetingFromTime: false,

    sch_meetingFromTime: false,
    sch_meetingToTime: false,

    timeValidation: false,
    timeValidationCurrentTime: false,
    showMeetingToTime: false,
    showMeetingLink: false,
    showMeetingComment: false,
    applicationStatus: [],
    application_status: 0,
    application_comments: '',
    successMsg: '',
    user: true,
    loggedInRole: '',
    history: '',
    tranches: [],
    tranchesAsc: [],
    bankDetails1:
      new AccountDetailsData1(
        0,
        0,
        '',
        '',
        '',
        0,
        '',
        '',
        '',
        '',
        '',
        ''
        , "", "", "", "", "", ""
      ),
    bankDetails2:
      new AccountDetailsData2(
        0,
        0,
        '',
        '',
        '',
        0,
        '',
        '',
        '',
        '',
        '',
        ''
        , "", "", "", "", "", ""
      ),
      subsequentDtls:
      new SubsequentDetails(
        0,0,"","","","","","",""
      ),
    total_grant_disbursed: 0,
    isFundDetailsTabActive: false,
    cancellationData: [],
    commentData: [],
    count_startups_supported: '',
    amount_disbursed: '',
    user_id: '',
    total_management_amount: 0,
    meetingComment: '',

    Documents_API_Data: '',
    Comments_API_Data: '',
    FirstApplication_Details: '',
    SecondApplication_Details: '',
    LeftSideBox_API_Data: '',
    LeftSideBox_API_Data1: '',
    EAC_API_Data: '',
    industrydata_Details: '',
    industrydata_Details2: '',
    sectorData_Details: '',
    sectorData_Details2: '',
    teamsData_Details: '',
    amountDisbursedToInc: '',
    amountDisbursedToStartup: ''

  };
  Status_ID: any;
  loader: boolean = false
  buttonDisable: boolean = true;
  Documents_API_Data: any;
  Comments_API_Data: any;
  ReamaininLeftSideObejects: any = [];
  EAC_API_Data: any;
  getStatus: any;
  getapplicationIndex: any;
  fromtimevalidation: any;
  getStatus1: any;
  cancelled_request: any;
  newArrayTrancheTotal: any;
  newArrayTrancheTotal1: any;
  showMeetingMessage: any;
  activeTab: any;

  constructor(props: any) {
    // console.log(props,"$$$$$$$$$$$$")
    super(props);
    //  console.log(props.location.state.status_id,props.location.state.status_id,"props@");
    //  //console.log(props.location.state);
    if (typeof props.location.state !== 'undefined') {
      //  //console.log(props.location.state.isFundDetails,"sri@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@")
      this.setDetails(
        props.location.state.application_id,
        props.location.state.isFundDetails != undefined ? props.location.state.isFundDetails : false
      );
      // this.state.checkedArray.push(this.state.applicationId);

      // this.getActiveApplication(props.location.state.application_id);
      // this.getCancellationData(props.location.state.id);

      // this.getFirstSecondApplicationDetails();
      // this.getFirstApplicationDetails(props.location.state.application_id, props.location.state.userId);
      // if (props.location.state?.flag == "status") {
      this.getLeftSideBoxDetails(props.location.state.userId, props.location.state.application_id, props.location.state.status_id);
      //   this.getLeftSideBoxDetails1(props.location.state.userId, props.location.state.application_id);
      // } else {
      //   this.getLeftSideBoxDetails1(props.location.state.userId, props.location.state.application_id);
      // }
      // this.getSecondApplicationDetails(props.location.state.userId);
      // console.log(props,"propsuuuuu")
      this.getDocumentaionDetails(props.location.state.userId, props.location.state.id);
      this.getCommentDetails(props.location.state.userId)
      this.getEACComments(props.location.state.userId);
      // this.getFundDetails(props.location.state.userId)
      this.getFundDetails(props.location.state.userId, props.location.state.application_id)
      //   //console.log(props.location.state.activeTab,"Initial")
      //  localStorage.setItem("value",props.location.state.activatab)
      this.activeTab = localStorage.getItem("value")

      //console.log(this.activeTab,this.state.isFundDetailsTabActive,"activation")


    }
    const user = getSessionStorageValue('user');
    this.setState({
      loggedInRole: 'test',
    });
    if (user) {
      const userInfo = JSON.parse(user) as UserInfoModel;
      // //console.log(userInfo.roles,'nfo.roles');
      this.state.loggedInRole = userInfo.roles
      if (userInfo.roles == 'eac') {
        this.state.user = false;
        this.setState({
          user: false,
        });
      }
    }
    //  //console.log(this.state);

  }

  private setDetails = (appId: number, isFund: boolean) => {
    this.state.isFundDetailsTabActive = isFund;
    //console.log(this.state.isFundDetailsTabActive,"sri@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@")
    this.state.applicationId = appId;
  };

  reload = () => {
    //RELOAD COMPONENT
    this.componentDidMount();
  };

  componentDidMount() {

  }

  // on change time;
  private onTimeChange = (e: any /*index:number*/) => {
    var value = e.target.value;
    var name = e.target.name;
    if (name == 'fromTime') {
      this.setState({
        fromTime: value,
      });
    } else if (name == 'toTime') {
      this.setState({
        toTime: value,
      });
    }
  };

  // on incubator dropdown change
  private handleOnChange = (e: any /*index:number*/) => {
    var value = e.target.value;
    this.setState({
      incubatorMeetingType: value,
    });
  };

  // function to format date
  private formatDate(date: Date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  private formatDateWithMonth(date: Date) {
    var month = new Array();
    month[0] = 'Jan';
    month[1] = 'Feb';
    month[2] = 'Mar';
    month[3] = 'April';
    month[4] = 'May';
    month[5] = 'Jun';
    month[6] = 'Jul';
    month[7] = 'Aug';
    month[8] = 'Sep';
    month[9] = 'Oct';
    month[10] = 'Nov';
    month[11] = 'Dec';

    var d = new Date(date),
      monthn = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (monthn.length < 2) monthn = '0' + monthn;
    if (day.length < 2) day = '0' + day;

    return [day, month[d.getMonth()], year].join(' ');
  }

  // trrigers on date change
  private handleDateChange = (date: Date, field: string) => {
    this.setState({
      scheduleDate: this.formatDate(date),
    });
  };

  private scheduleMeetingFunction = () => {

    // console.log(this.state.EAC_API_Data, "resppppp")
    var time = new Date();
    var curTime = moment(time, "HH:mm").format("HH:mm");
    const user: any = getSessionStorageValue('user');
    const userInfo = JSON.parse(user);
    const userId = userInfo.id;
    const data = {
      meeting_date:
        this.state.scheduleDate != ''
          ? this.state.scheduleDate
          : this.formatDate(new Date()),
      meeting_from_time: this.state.fromTime != '' ? this.state.fromTime : '',
      meeting_to_time: this.state.toTime != '' ? this.state.toTime : '',
      link: this.state.meetingLink,
      comment: this.state.meetingComment,
      meeting_type:
        this.state.incubatorMeetingType != ''
          ? this.state.incubatorMeetingType
          : '',
      incubator_application_ids: [
        this.state.applicationId == 0
          ? this.state.applicationId
          : this.state.applicationId,
      ],
      warning_ignore: 0,
      logged_user_id: userId
    };
    // console.log(this.state.EAC_API_Data.First_Application_EAC_Comments, "mohaan")

    const meeting_fromTime = this.state.EAC_API_Data.Second_Application_EAC_Comments.some((fromTime: any) => {

      return (((moment(fromTime.meeting_date).format('YYYY-MM-DD') == (this.state.scheduleDate || moment(time).format('YYYY-MM-DD')))) &&
        (this.state.fromTime >= fromTime.meeting_from_time.substring(0, 5) && this.state.fromTime <= fromTime.meeting_to_time.substring(0, 5)))

    })
    const meeting_toTime = this.state.EAC_API_Data.Second_Application_EAC_Comments.some((fromTime: any) => {

      return (((moment(fromTime.meeting_date).format('YYYY-MM-DD') == (this.state.scheduleDate || moment(time).format('YYYY-MM-DD')))) &&
        (this.state.toTime >= fromTime.meeting_from_time.substring(0, 5) && this.state.toTime <= fromTime.meeting_to_time.substring(0, 5)))

    })

    const meeting_fromTime1 = this.state.EAC_API_Data.First_Application_EAC_Comments.some((fromTime: any) => {
      return (((moment(fromTime.meeting_date).format('YYYY-MM-DD') == (this.state.scheduleDate || moment(time).format('YYYY-MM-DD')))) &&
        (this.state.fromTime >= fromTime.meeting_from_time.substring(0, 5) && this.state.fromTime <= fromTime.meeting_to_time.substring(0, 5)))

    })
    const meeting_toTime1 = this.state.EAC_API_Data.First_Application_EAC_Comments.some((fromTime: any) => {

      return (((moment(fromTime.meeting_date).format('YYYY-MM-DD') == (this.state.scheduleDate || moment(time).format('YYYY-MM-DD')))) &&
        (this.state.toTime >= fromTime.meeting_from_time.substring(0, 5) && this.state.toTime <= fromTime.meeting_to_time.substring(0, 5)))

    })
    if (meeting_fromTime == true || meeting_fromTime1 == true) {
      this.setState({
        sch_meetingFromTime: true,
        showMeetingFromTime: false
      })
      return;
    } else {
      this.setState({
        sch_meetingFromTime: false
      })
    }

    if (meeting_toTime == true || meeting_toTime1 == true) {
      this.setState({
        sch_meetingToTime: true,
        showMeetingToTime: false
      })
      return;
    } else {
      this.setState({
        sch_meetingToTime: false
      })
    }

    if (!data.meeting_from_time) {
      this.setState({
        showMeetingFromTime: true
      });
      return;
    } else {
      this.setState({
        showMeetingFromTime: false
      });
    }
    if (!data.meeting_to_time) {
      this.setState({
        showMeetingToTime: true
      });
      return;
    } else {
      this.setState({
        showMeetingToTime: false
      });
    }

    if (!compareTime(data.meeting_from_time, data.meeting_to_time)) {
      this.setState({
        timeValidation: true,
        // timeValidationCurrentTime:true
      });
      return false;
    } else (compareTime(data.meeting_from_time, data.meeting_to_time)); {
      this.setState({
        timeValidation: false,
        // timeValidationCurrentTime:false
      });
    }

    if (this.formatDate(new Date()) == data.meeting_date &&
      compareTime(data.meeting_from_time, curTime)) {
      this.setState({
        // timeValidation:true,
        timeValidationCurrentTime: true
      });
      return false;
    } else {
      this.setState({
        // timeValidation:true,
        timeValidationCurrentTime: false
      });
    }

    if (!data.link) {
      this.setState({
        showMeetingLink: true
      });
      return;
    } else {
      this.setState({
        showMeetingLink: false
      });
    }
    if (!data.comment) {
      this.setState({
        showMeetingComment: true
      });
      return;
    } else {
      this.setState({
        showMeetingComment: false
      });
    }

    // return;
    this.loader = true
    post(MERGED_SCHEDULE_MEETING, data).then((response: any) => {
      this.loader = false;
      if (!response || !response.data) return;
      if (response.data.result == 'success') {
        this.setState({
          showEditSuccessModalPopUp: true,
          successMsg: 'Your Meeting Has been Scheduled',
        });
        $('#scheduleMeeting1').modal('hide');
        this.getActiveApplication(this.state.applicationId);
      } else {
        this.setState({
          showEditFailureModalPopUp: true,
        });
      }
      // this.loader = false;
    });
  };
  private associated_start = (a: any, b: any, c: any) => {
    const someValue = { "user_id": a, "showPreference": b, "from": c }
    localStorage.setItem("state", JSON.stringify(someValue))
  }
  private dismissModalPopUp = () => {
    this.setState({
      showEditSuccessModalPopUp: false,
    });
    this.setState({
      showEditFailureModalPopUp: false,
    });
    this.setState({
      showMeetingFromTime: false
    });
    this.setState({
      showMeetingToTime: false
    });
    this.setState({
      showMeetingLink: false
    });
    this.setState({
      showMeetingComment: false
    });
    window.location.reload();
  };
  private tabActive = (val: any) => {
    //console.log(val)
    this.activeTab = val
    //console.log(this.activeTab,"activatab") 
    localStorage.setItem("value", this.activeTab)
  }
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md"></div>
          <div className="col-md-8">
            {this.loader && (
              <div className="spinner-border custom-spinner" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            )}
            <fieldset disabled={this.loader}>

              <div className="">
                <div className="row">
                  <div className="margin-16"></div>
                </div>
                <div className="row ">
                  <div className="col-md-12">
                    {/* {console.log(this.state.loggedInRole,"iammamm")} */}
                    {/* <span className="incubators-title"> Incubators</span>&nbsp; */}
                    {this.state.loggedInRole == 'dpiit' && <a
                      className="incubators-title"
                      href="/merged_list"
                      aria-current="page"
                    >
                      Incubators {' '}
                    </a>}
                    {this.state.loggedInRole == 'eac' && <a
                      className="incubators-title"
                      href="/merged_list"
                      aria-current="page"
                    >
                      Incubators {' '}
                    </a>}
                    {this.state.loggedInRole == 'secretariat' && <a
                      className="incubators-title"
                      href="/merged_list"
                      aria-current="page"
                    >
                      Incubators {' '}
                    </a>}
                    <span className="incubator-name-value">
                      /{' '}
                      {this.state.LeftSideBox_API_Data.registered_name == ''
                        ? ''
                        : this.state.LeftSideBox_API_Data.registered_name}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 incubator-rectangle">
                    <div>
                      {/* {console.log(this.ReamaininLeftSideObejects, "objectsss")} */}
                      <MergedLeftSidebarDetailsComponent
                        remainingObjest={this.ReamaininLeftSideObejects}
                        mainObject={this.state.LeftSideBox_API_Data} />
                      {/* {console.log(this.ReamaininLeftSideObejects,"objectsss")} */}
                    </div>
                    <div className="margin-3"></div>

                    {this.state.isSelectedOrClosed == true ? (
                      <div className="incubator-rectangle-2 margin-top-1 padding-top-12">
                        <div className="container">
                          <div className="group-11">
                            <div className="row  mt-05">
                              <div className="col-md-12">
                                <span className="incubator-label">
                                  Total Grant Approved

                                </span>
                                <br />

                                {this.state.LeftSideBox_API_Data.total_approved_amt == null ||
                                  this.state.LeftSideBox_API_Data.total_approved_amt ==
                                  0 ? (
                                  <span className="incubator-label-value">-</span>
                                ) : (
                                  <span className="incubator-label-value">
                                    &#8377;{' '}
                                    {changeNumberFormat(
                                      Number(
                                        this.state.LeftSideBox_API_Data
                                          .total_approved_amt
                                      ),
                                      2,
                                      1
                                    )}
                                  </span>
                                )}
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                {this.state.LeftSideBox_API_Data
                                  .total_approved_amt == null ||
                                  this.state.LeftSideBox_API_Data.total_approved_amt ==
                                  0 ? (
                                  ''
                                )
                                  : (
                                    <a
                                      className="incubator-details-link"
                                      data-toggle="modal"
                                      data-target="#TrancheDetailsModal"
                                    >
                                      Tranche Details
                                    </a>
                                  )
                                }
                              </div>

                              {/* Tranche Details Model Start*/}
                              <div
                                className="modal fade"
                                id="TrancheDetailsModal"
                                role="dialog"
                                aria-labelledby="exampleModalCenterTitle"
                                aria-hidden="true"
                              >
                                <div
                                  className="modal-dialog modal-dialog-centered modal-lg"
                                  role="document"
                                >
                                  <div className="modal-content">
                                    <div className="modal-header modal-header-border-none ml-18px">
                                      <h5
                                        className="modal-title pl-2"
                                        id="exampleModalLongTitle"
                                      >
                                        Tranche Details<span></span>
                                      </h5>
                                      <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <div className="modal-body mt-15-px">
                                      <div className="container">
                                        <div className="row">
                                          <div className="col-md-3 left-align">
                                            <span className="fund-amount-cur">
                                              &#8377;
                                              <span className="fund-amount-label-value">
                                                {' '}

                                                {this.state.LeftSideBox_API_Data
                                                  .total_approved_amt != null
                                                  ? changeNumberFormat(
                                                    Number(
                                                      this.state
                                                        .LeftSideBox_API_Data
                                                        .total_approved_amt
                                                    ),
                                                    2,
                                                    1
                                                  )
                                                  : 0}{' '}
                                              </span>
                                            </span>
                                            <br />
                                            <span className="fund-amount-label">
                                              Total Grant Approved

                                            </span>
                                          </div>
                                          {/* {console.log(this.state.LeftSideBox_API_Data,"data@@@")} */}
                                          <div className="col-md-3 left-align">
                                            <span className="fund-amount-cur">
                                              <span className="fund-amount-label-value">
                                                {this.state.LeftSideBox_API_Data.no_of_tranches == null ? 0 : this.state.LeftSideBox_API_Data.no_of_tranches}{' '}
                                              </span>
                                            </span>
                                            <br />
                                            <span className="fund-amount-label">
                                              Total No. of Tranches
                                            </span>
                                          </div>

                                          <div className="col-md-4 right">
                                            <br />
                                          </div>
                                        </div>

                                        <div className="row">
                                          <div className="table-scroll ">
                                            <table id="example">
                                              <thead>
                                                <tr>
                                                  <th className="text-left custom-td-left table-heading">
                                                    Tranche Request
                                                  </th>
                                                  <th className="text-left custom-td-left table-heading">
                                                    Amount Approved
                                                  </th>
                                                  <th className="text-left table-heading">
                                                    Amount Disbursed
                                                  </th>
                                                  <th className="text-left table-heading"></th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {/* {console.log(this.newArrayTrancheTotal,"newArrry")} */}
                                                {this.state.LeftSideBox_API_Data.application_index === "R1" &&
                                                  (this.state.incubatorDetailsModel.secondApplication[0] &&
                                                    this.state.incubatorDetailsModel.secondApplication[0].length > 0) ? (
                                                  this.state.incubatorDetailsModel.secondApplication[0]
                                                    .filter((item: any) => item.incubator_application_no === this.state.LeftSideBox_API_Data.incubator_application_no)
                                                    .map((item: any) => (
                                                      <tr>
                                                        <td className="text-left ">
                                                          <span className="incubator-label-value">
                                                            {item.tranche_number}
                                                          </span>
                                                          <br />
                                                          <span className="incubator-label">
                                                            {' '}
                                                            {item.tranche_requested_date !=
                                                              null
                                                              ? this.formatDateWithMonth(
                                                                item.tranche_requested_date
                                                              )
                                                              : '-'}
                                                          </span>
                                                        </td>
                                                        <td className="text-left ">
                                                          <span className="incubator-label-value">
                                                            {' '}
                                                            {item.approved_amount}
                                                          </span>
                                                          <br />
                                                          {item.approved_amount !=
                                                            null ? (
                                                            <span className="incubator-label">
                                                              {' '}
                                                              {item.approved_amount_date !=
                                                                null
                                                                ? this.formatDateWithMonth(
                                                                  item.approved_amount_date
                                                                )
                                                                : '-'}
                                                            </span>
                                                          ) : (
                                                            ''
                                                          )}
                                                        </td>
                                                        <td className="text-left ">
                                                          <span className="incubator-label-value">
                                                            {' '}
                                                            {item.fund_released}
                                                          </span>
                                                          <br />
                                                          {item.fund_released !=
                                                            null ? (
                                                            <span className="incubator-label">
                                                              {' '}
                                                              {item.date_of_release !=
                                                                null
                                                                ? this.formatDateWithMonth(
                                                                  item.date_of_release
                                                                )
                                                                : '-'}
                                                            </span>
                                                          ) : (
                                                            ''
                                                          )}
                                                        </td>
                                                        <td className="text-left custom-td-left"></td>
                                                      </tr>
                                                    ))
                                                ) : (null)
                                                }



                                                {this.state.LeftSideBox_API_Data.application_index === "F1" &&
                                                  (this.state.incubatorDetailsModel.firstApplication[0] &&
                                                    this.state.incubatorDetailsModel.firstApplication[0].length > 0) ? (
                                                  this.state.incubatorDetailsModel.firstApplication[0]
                                                    .filter((item: any) => item.incubator_application_no === this.state.LeftSideBox_API_Data.incubator_application_no)
                                                    .map((item: any) => (
                                                      <tr>
                                                        <td className="text-left ">
                                                          <span className="incubator-label-value">
                                                            {item.tranche_number}
                                                          </span>
                                                          <br />
                                                          <span className="incubator-label">
                                                            {' '}
                                                            {item.tranche_requested_date !=
                                                              null
                                                              ? this.formatDateWithMonth(
                                                                item.tranche_requested_date
                                                              )
                                                              : '-'}
                                                          </span>
                                                        </td>
                                                        <td className="text-left ">
                                                          <span className="incubator-label-value">
                                                            {' '}
                                                            {item.approved_amount}
                                                          </span>
                                                          <br />
                                                          {item.approved_amount !=
                                                            null ? (
                                                            <span className="incubator-label">
                                                              {' '}
                                                              {item.approved_amount_date !=
                                                                null
                                                                ? this.formatDateWithMonth(
                                                                  item.approved_amount_date
                                                                )
                                                                : '-'}
                                                            </span>
                                                          ) : (
                                                            ''
                                                          )}
                                                        </td>
                                                        <td className="text-left ">
                                                          <span className="incubator-label-value">
                                                            {' '}
                                                            {item.fund_released}
                                                          </span>
                                                          <br />
                                                          {item.fund_released !=
                                                            null ? (
                                                            <span className="incubator-label">
                                                              {' '}
                                                              {item.date_of_release !=
                                                                null
                                                                ? this.formatDateWithMonth(
                                                                  item.date_of_release
                                                                )
                                                                : '-'}
                                                            </span>
                                                          ) : (
                                                            ''
                                                          )}
                                                        </td>
                                                        <td className="text-left custom-td-left"></td>
                                                      </tr>
                                                    )
                                                    )
                                                ) : (null)
                                                }
                                              </tbody>
                                            </table>

                                            {(this.state.LeftSideBox_API_Data.application_index === "R1" &&
                                              this.state.incubatorDetailsModel.secondApplication.length == 0) || (this.state.LeftSideBox_API_Data.application_index === "F1" &&
                                                this.state.incubatorDetailsModel.firstApplication.length == 0) ?
                                              <div className='text-center'>
                                                <br /><br /><br /><br /><br /><br /><br />
                                                <h4 style={{ color: 'red' }}>You have no access to view as application status is not selected</h4>
                                              </div>
                                              : ''}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row ">
                                        <div className="col-md-12 centerText">
                                          <button
                                            className="tertiary  btn-yellow-primary"
                                            data-dismiss="modal"
                                          >
                                            OK
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>


                              </div>
                              {/* Tranche Details Model End */}

                            </div>
                            <div className="row mt-05">
                              <div className="col-md-12">
                                <span className="incubator-label">
                                  Total Funds Disbursed to Incubator

                                </span>
                                <br />
                                {this.state.LeftSideBox_API_Data.total_disbursed_amt == null ||
                                  this.state.LeftSideBox_API_Data.total_disbursed_amt == 0 ? (
                                  <span className="incubator-label-value">-</span>
                                ) : (
                                  <span className="incubator-label-value">
                                    &#8377;
                                    {changeNumberFormat(Number(this.state.LeftSideBox_API_Data.total_disbursed_amt), 2, 1)}
                                  </span>
                                )}
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                {/* {this.state.LeftSideBox_API_Data.total_disbursed_amt == null ||
                          this.state.LeftSideBox_API_Data.total_disbursed_amt == 0 ? (
                            ''
                          ) : (
                            <a className="incubator-details-link">
                              Add proof|Add More
                            </a>
                          )} */}
                              </div>
                            </div>
                            {/* <--------------------------------------------------------------> */}
                            <div className="row mt-05">
                              <div className="col-md-12">
                                <span className="incubator-label">
                                  Total Management Fees
                                </span>
                                <br />
                                {this.state.LeftSideBox_API_Data.total_management_amount == null ||
                                  this.state.LeftSideBox_API_Data.total_management_amount == 0 ? (
                                  <span className="incubator-label-value">-</span>
                                ) : (
                                  <span className="incubator-label-value">
                                    &#8377;
                                    {changeNumberFormat(Number(this.state.LeftSideBox_API_Data.total_management_amount), 2, 1)}
                                  </span>
                                )}
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                {/* {this.state.total_grant_disbursed == null ||
                          this.state.total_grant_disbursed == 0 ? (
                            ''
                          ) : (
                            <a className="incubator-details-link">
                              Add proof|Add More
                            </a>
                          )} */}
                              </div>
                            </div>
                            {/* <-----------------------------------------------------------> */}
                            <div className="row mt-05">
                              <div className="col-md-12">
                                <span className="incubator-label">
                                  No. of Startups Supported
                                </span>
                                <br />
                                <span className="incubator-label-value">{this.state.LeftSideBox_API_Data1.startup_supported ? this.state.LeftSideBox_API_Data1.startup_supported : 0}</span>
                              </div>
                            </div>
                            <div className="row mt-05">
                              <div className="col-md-12">
                                <span className="incubator-label">
                                  Funds Disbursed to Startups
                                </span>
                                <br />
                                <span className="incubator-label-value">{changeNumberFormat(Number(this.state.LeftSideBox_API_Data1.funds_disbursed_startup ? this.state.LeftSideBox_API_Data1.funds_disbursed_startup : 0), 2, 1)}</span>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  {/* { console.log(this.state.loggedInRole,"roless")} */}
                  <div className="col-md-9 text-right">
                    <div className="btn-group mt-1-5 mr-2">
                      {this.state.LeftSideBox_API_Data.user_id != '' && (this.state.LeftSideBox_API_Data.application_status == "Selected" || this.state.LeftSideBox_API_Data.application_status == "Closed") && this.state.loggedInRole == "secretariat" &&
                        <Link className="btn btn-sisfs btn-yellow-primary" to={{ pathname: "/secretariat_startup_list_details", state: { user_id: this.state.LeftSideBox_API_Data.user_id, showPreference: false, from: "associated_startups" } }}>
                          <button style={{ border: 'none', background: 'transparent', fontSize: '14px', fontWeight: 'bold', color: '#25429c' }} onClick={() => { this.associated_start(this.state.LeftSideBox_API_Data.user_id, false, "associated_startups") }}>Associated Startups</button></Link>
                      }
                      {this.state.LeftSideBox_API_Data.user_id != '' && (this.state.LeftSideBox_API_Data.application_status == "Selected" || this.state.LeftSideBox_API_Data.application_status == "Closed") && this.state.loggedInRole == "dpiit" &&
                        <Link className="btn btn-sisfs btn-yellow-primary" to={{ pathname: "/dpiit_startup_list_details", state: { user_id: this.state.LeftSideBox_API_Data.user_id, showPreference: false, from: "associated_startups" } }}>
                          <button style={{ border: 'none', background: 'transparent', fontSize: '14px', fontWeight: 'bold', color: '#25429c' }} onClick={() => { this.associated_start(this.state.LeftSideBox_API_Data.user_id, false, "associated_startups") }}>Associated Startups</button></Link>
                      }
                      {this.state.LeftSideBox_API_Data.user_id != '' && (this.state.LeftSideBox_API_Data.application_status == "Selected" || this.state.LeftSideBox_API_Data.application_status == "Closed") && this.state.loggedInRole == "eac" &&
                        <Link className="btn btn-sisfs btn-yellow-primary" to={{ pathname: "/eac_startup_list_details", state: { user_id: this.state.LeftSideBox_API_Data.user_id, showPreference: false, from: "associated_startups" } }}>
                          <button style={{ border: 'none', background: 'transparent', fontSize: '14px', fontWeight: 'bold', color: '#25429c' }} onClick={() => { this.associated_start(this.state.LeftSideBox_API_Data.user_id, false, "associated_startups") }}>Associated Startups</button></Link>
                      }

                      {/* {this.state.user_id != '' && (this.state.incubatorDetailsModel.application_status == "Selected" || this.state.incubatorDetailsModel.application_status == "Closed") && this.state.loggedInRole == "secretariat" &&
                  <Link className="btn btn-sisfs btn-yellow-primary" to={{ pathname: "/secretariat_startup_list_details", state: { user_id: this.state.user_id, showPreference: false } }}>
                    Associated Startups</Link>
                }
                {this.state.user_id != '' && (this.state.incubatorDetailsModel.application_status == "Selected" || this.state.incubatorDetailsModel.application_status == "Closed") && this.state.loggedInRole == "dpiit" &&
                  <Link className="btn btn-sisfs btn-yellow-primary" to={{ pathname: "/dpiit_startup_list_details", state: { user_id: this.state.user_id, showPreference: false } }}>
                    Associated Startups</Link>
                }
                {this.state.user_id != '' && (this.state.incubatorDetailsModel.application_status == "Selected" || this.state.incubatorDetailsModel.application_status == "Closed") && this.state.loggedInRole == "eac" &&
                  <Link className="btn btn-sisfs btn-yellow-primary" to={{ pathname: "/eac_startup_list_details", state: { user_id: this.state.user_id, showPreference: false } }}>
                    Associated Startups</Link>
                } */}
                      {/* 
                   <button className="btn btn-sisfs btn-transparent-primary">
                 Cancel
               </button> */}
                    </div>
                    {this.state.user == true ? (
                      <div className="btn-group mt-1-5 ">


                        <button
                          type="button"
                          className={
                            this.buttonDisable == true
                              ? "tertiary  btn-yellow-primary btn-yellow-disabled"
                              : "tertiary  btn-yellow-primary"
                          }
                          data-toggle="modal"
                          data-target="#scheduleMeeting1"
                          disabled={this.buttonDisable}
                        // this.buttonDisable == false
                        >
                          Schedule Meeting
                        </button>
                        {/* 
                   <button className="btn btn-sisfs btn-transparent-primary">
                 Cancel
               </button> */}
                      </div>
                    ) : (
                      ''
                    )}

                    <div className="margin-20"></div>
                    {/* { console.log(this.state.isSelectedOrClosed,"isSelectedOrClosed")} */}
                    {this.state.isSelectedOrClosed == true ? (
                      <div className="details-tabs">
                        <ul className="nav nav-tabs" role="tablist">
                          {/* { console.log(this.state.isFundDetailsTabActive==false,localStorage.getItem("value")=="1","val$$$$")} */}

                          {/* { console.log(this.state.isFundDetailsTabActive == false && localStorage.getItem("value")=="1","val")} */}
                          {/* {(this.getStatus == 6 || this.getStatus == 7) &&  */}
                          {/* { console.log(this.activeTab,"activatab")} */}
                          <li className="nav-item">
                            <a
                              className={
                                (this.activeTab == "1")
                                  ? 'nav-link active'
                                  : 'nav-link '
                              }
                              data-toggle="tab"
                              href="#tabs-1"
                              role="tab"
                              onClick={() => this.tabActive('1')}
                            >
                              EAC Review
                            </a>
                          </li>
                          {/* }  */}
                          {/* {((this.getapplicationIndex == 'R1') || (this.getStatus == '7' && this.getapplicationIndex == 'F1') || (this.cancelled_request = "1")) &&  */}
                          {/* { console.log(this.state.isFundDetailsTabActive,localStorage.getItem("value"),"val$$$$")} */}

                          {/* { console.log(this.state.isFundDetailsTabActive,"sri@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@")} */}

                          <li className="nav-item">
                            <a
                              className={
                                (this.activeTab == "2")
                                  ? 'nav-link active'
                                  : 'nav-link '
                              }
                              data-toggle="tab"
                              href="#tabs-2"
                              role="tab"
                              onClick={() => this.tabActive('2')}

                            >
                              Fund Details
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={
                                (this.activeTab == "10")
                                  ? 'nav-link active'
                                  : 'nav-link '
                              }
                              data-toggle="tab"
                              href="#tabs_Returns"
                              role="tab"
                              onClick={() => this.tabActive('10')}

                            >
                              Returns
                            </a>
                          </li>
                          {/* } */}
                          {/* {console.log(this.getapplicationIndex, "applicatiojIndex")} */}
                          {(this.getapplicationIndex == 'F1') &&
                            <li className="nav-item">
                              <a
                                className={this.activeTab == "3" ? "nav-link active" : "nav-link"
                                }
                                data-toggle="tab"
                                href="#tabs-3"
                                role="tab"
                                onClick={() => this.tabActive("3")}
                              >
                                1<sup>st</sup> Application Details
                              </a>
                            </li>
                          }
                          {(this.getapplicationIndex == 'R1') &&
                            <li className="nav-item">
                              <a
                                className={this.activeTab == "4" ? "nav-link active" : "nav-link"
                                }
                                data-toggle="tab"
                                href="#tabs-8"
                                role="tab"
                                onClick={() => this.tabActive("4")}
                              >
                                2<sup>nd</sup> Application Details
                              </a>
                            </li>}
                          {/* {console.log(this.state.loggedInRole)} */}
                          {(this.state.SecondApplication_Details.length > 0 && this.state.SecondApplication_Details[0].cancellation_requested == "1") && this.state.loggedInRole != 'eac' &&
                            <li className="nav-item">
                              <a
                                className={this.activeTab == "5" ? "nav-link active" : "nav-link"
                                }
                                data-toggle="tab"
                                href="#tabs-6"
                                role="tab"
                                onClick={() => this.tabActive("5")}

                              >
                                Cancellation
                              </a>
                            </li>
                          }

                          {(this.state.FirstApplication_Details.length > 0 && this.state.FirstApplication_Details[0].final_cancellation_requested == "1") &&
                            <li className="nav-item">
                              <a
                                className={this.activeTab == "6" ? "nav-link active" : "nav-link"
                                }
                                data-toggle="tab"
                                href="#tabs-6"
                                role="tab"
                                onClick={() => this.tabActive("6")}
                              >
                                Cancellation
                              </a>
                            </li>
                          }
                          <li className="nav-item">
                            <a
                              className={this.activeTab == "7" ? "nav-link active" : "nav-link"
                              }
                              data-toggle="tab"
                              href="#tabs-4"
                              role="tab"
                              onClick={() => this.tabActive("7")}
                            >
                              Documents
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={this.activeTab == "8" ? "nav-link active" : "nav-link"
                              }
                              data-toggle="tab"
                              href="#tabs-5"
                              role="tab"
                              onClick={() => this.tabActive("8")}
                            >
                              Contact
                            </a>
                          </li>
                          {/* {this.state.commentData.length != 0 &&  */}
                          <li className="nav-item">
                            <a
                              className={this.activeTab == "9" ? "nav-link active" : "nav-link"
                              }
                              data-toggle="tab"
                              href="#tabs-7"
                              role="tab"
                              onClick={() => this.tabActive("9")}
                            >
                              Comments
                            </a>
                          </li>
                          {/* } */}
                        </ul>
                      </div>
                    ) : (
                      <div className="details-tabs">
                        <ul className="nav nav-tabs" role="tablist">
                          {/* {(this.getStatus == 6 || this.getStatus == 7) && */}
                          <li className="nav-item">
                            <a
                              className={
                                (this.activeTab == '1')
                                  ? 'nav-link active'
                                  : 'nav-link '
                              }
                              data-toggle="tab"
                              href="#tabs-1"
                              role="tab"
                              onClick={() => this.tabActive('1')}
                            >
                              EAC Review
                            </a>
                          </li>
                          {/* } */}
                          {(this.getapplicationIndex == 'F1') &&
                            <li className="nav-item">
                              <a
                                className={this.activeTab == "3" ? "nav-link active" : "nav-link"
                                }
                                data-toggle="tab"
                                href="#tabs-3"
                                role="tab"
                                onClick={() => this.tabActive('3')}
                              >
                                1<sup>st</sup> Application <br />
                                Details
                              </a>
                            </li>
                          }
                          {(this.getapplicationIndex == 'R1') &&
                            <li className="nav-item">
                              <a
                                className={this.activeTab == "4" ? "nav-link active" : "nav-link"
                                }
                                data-toggle="tab"
                                href="#tabs-8"
                                role="tab"
                                onClick={() => this.tabActive('4')}
                              >
                                2<sup>nd </sup>Application<br />
                                Details
                              </a>
                            </li>}
                          {(this.state.SecondApplication_Details.length > 0 && this.state.SecondApplication_Details[0].cancellation_requested == "1") && this.state.loggedInRole != 'eac' &&
                            <li className="nav-item">
                              <a
                                className={this.activeTab == "5" ? "nav-link active" : "nav-link"
                                }
                                data-toggle="tab"
                                href="#tabs-6"
                                role="tab"
                                onClick={() => this.tabActive('5')}
                              >
                                Cancellation
                              </a>
                            </li>
                          }
                          {(this.state.FirstApplication_Details.length > 0 && this.state.FirstApplication_Details[0].cancellation_requested == "1") &&
                            <li className="nav-item">
                              <a
                                className={this.activeTab == "6" ? "nav-link active" : "nav-link"
                                }
                                data-toggle="tab"
                                href="#tabs-6"
                                role="tab"
                                onClick={() => this.tabActive('6')}
                              >
                                Cancellation
                              </a>
                            </li>
                          }
                          <li className="nav-item">
                            <a
                              className={this.activeTab == "7" ? "nav-link active" : "nav-link"
                              }
                              data-toggle="tab"
                              href="#tabs-4"
                              role="tab"
                              onClick={() => this.tabActive('7')}
                            >
                              Documents
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={this.activeTab == "8" ? "nav-link active" : "nav-link"
                              }
                              data-toggle="tab"
                              href="#tabs-5"
                              role="tab"
                              onClick={() => this.tabActive('8')}
                            >
                              Contact
                            </a>
                          </li>
                          {/* {this.state.commentData.length != 0 &&  */}
                          <li className="nav-item">
                            <a
                              className={this.activeTab == "8" ? "nav-link active" : "nav-link"
                              }
                              data-toggle="tab"
                              href="#tabs-7"
                              role="tab"
                              onClick={() => this.tabActive('9')}
                            >
                              Comments
                            </a>
                          </li>
                          {/* } */}

                        </ul>
                      </div>
                    )}
                    <div className="incubator-hr margin-top-minus-17">
                      <hr />
                    </div>

                    {this.state.isSelectedOrClosed == true ? (
                      <div className="tab-content">
                        {/* {(this.Status_ID == 6 || this.Status_ID == 7) &&  */}
                        <div className={this.activeTab == "1" ? "tab-pane active" : "tab-pane"} id="tabs-1">
                          {/* <EACReview
                      generalDetailModel={this.state.incubatorDetailsModel}
                    /> */}
                          <MergedEACDetailsComponent EACCommentsData={this.state.EAC_API_Data} />
                        </div>
                        {/* } */}

                        <div
                          className={
                            (this.activeTab == "2")
                              ? 'tab-pane active'
                              : 'tab-pane'
                          }
                          id="tabs-2"
                        >
                          {/* <FundDetailsComponent
                      application_id={this.state.incubatorDetailsModel.id}
                      total_grant_approved={
                        this.state.incubatorDetailsModel.total_approved_amt
                      }
                      trancheData={this.state.tranches}
                      trancheDataASC={this.state.tranchesAsc}
                      accountDetailsData={this.state.bankDetails}
                      application_status={
                        this.state.incubatorDetailsModel.application_status
                      }
                      totalManagementAmount={this.state.total_management_amount}
                      total_grant_disbursed={this.state.total_grant_disbursed}
                    /> */}
                          {/* { console.log(this.state.LeftSideBox_API_Data,"srilatha@@@@@@@")} */}
                          {/* { console.log(this.state.LeftSideBox_API_Data.application_status,"srilatha@@@@@@@")} */}
                          {((this.getapplicationIndex == 'R1' || this.getapplicationIndex == 'F1') && (this.getStatus == '7')) ?
                            <MergedFundDetailsComponent
                              application_id={this.state.LeftSideBox_API_Data.application_id}
                              incubator_application_no={(this.state.incubatorDetailsModel)}

                              trancheData={this.state.incubatorDetailsModel.secondApplication}
                              trancheDataFirst={this.state.incubatorDetailsModel.firstApplication}
                              trancheDataASC={this.state.tranchesAsc}
                              accountDetailsData1={this.state.bankDetails1}
                              accountDetailsData2={this.state.bankDetails2}
                              subsequentDetails={this.state.subsequentDtls}
                              application_status={
                                this.state.LeftSideBox_API_Data.application_status
                              }
                              //              this.total_approved_amt=total_approved_amt,
                              // this.total_management_amount=total_management_amount,
                              // this.no_of_tranches = no_of_tranches,
                              // this.total_disbursed_amt =total_disbursed_amt
                              available_balance={this.state.incubatorDetailsModel.Available_Balance_Incubator}
                              approved_return={this.state.incubatorDetailsModel.Approved_return}
                              totalManagementAmount={this.state.incubatorDetailsModel.total_management_amount}
                              total_grant_disbursed={this.state.incubatorDetailsModel.total_disbursed_amt}
                              total_no_tranches={this.state.incubatorDetailsModel.no_of_tranches}
                              total_grant_approved={
                                this.state.incubatorDetailsModel.total_approved_amt
                              }
                              Total_Disbursed_to_Startups={
                                this.state.incubatorDetailsModel.Amount_Disbursed_to_Startups
                              }
                              leftSideData={this.state.LeftSideBox_API_Data}
                              amountDisbursedToInc={this.state.amountDisbursedToInc}
                              amountDisbursedToStartup={this.state.amountDisbursedToStartup}
                            //   totalManagementAmount2={this.state.incubatorDetailsModel.totalAmount2.total_management_amount}
                            //   total_grant_disbursed2={this.state.incubatorDetailsModel.totalAmount2.total_disbursed_amt}
                            //   total_no_tranches={this.state.incubatorDetailsModel.totalAmount2.no_of_tranches}
                            //   total_grant_approved2={
                            //    this.state.incubatorDetailsModel.totalAmount2.total_approved_amount
                            //  }      
                            /> : <div className='text-center'>
                              <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                              <h4 style={{ color: 'red' }}>You have no access to view as application status is not selected</h4>
                            </div>}
                        </div>
                        <div className={this.activeTab == "10" ? "tab-pane active" : "tab-pane"} id="tabs_Returns">
                          {((this.getapplicationIndex == 'R1' || this.getapplicationIndex == 'F1') && (this.getStatus == '7')) ?
                            <MergedRetrnsDetailsComponent
                              Incubatorid={this.props} /> : <div className='text-center'>
                              <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                              <h4 style={{ color: 'red' }}>You have no access to view as application status is not selected</h4>
                            </div>
                          }
                        </div>
                        {this.state.FirstApplication_Details.length != 0 && <div className={this.activeTab == "3" ? "tab-pane active" : "tab-pane"} id="tabs-3">
                          <MergedFirstApplicationDetailsComponent
                            FirstApplication_Data={this.state.FirstApplication_Details}
                            DocumentsData={this.state.Documents_API_Data}
                            Inindustrydata_Data={this.state.industrydata_Details}
                            sectorData_Data={this.state.sectorData_Details}
                            teamsData_Data={this.state.teamsData_Details}
                          />
                        </div>}

                        {this.state.SecondApplication_Details.length != 0 && <div className={this.activeTab == "4" ? "tab-pane active" : "tab-pane"} id="tabs-8">
                          <MergedReappliedApplicationDetailsComponent
                            SecondApplication_Data={this.state.SecondApplication_Details}
                            sectorData_Data={this.state.sectorData_Details2}
                            Inindustrydata_Data={this.state.industrydata_Details2}
                          />
                        </div>}

                        {/* { console.log(this.state.Documents_API_Data, '###############ADHI')} */}
                        <div className={this.activeTab == "7" ? "tab-pane active" : "tab-pane"} id="tabs-4">
                          {/* <Document otherDetailModel={this.state.otherDetailsModel} /> */}
                          <MergedDocumentsDetailsComponent
                            DocumentsData={this.state.Documents_API_Data} />
                        </div>
                        {/* { console.log(this.state.FirstApplication_Details,"first@@")} */}
                        {/* { console.log(this.state.SecondApplication_Details,"first@@")} */}

                        {this.state.FirstApplication_Details.length != 0 ? <div className={this.activeTab == "8" ? "tab-pane active" : "tab-pane"} id="tabs-5">
                          <MergedContactDetailsComponent
                            contact_Data={this.state.FirstApplication_Details} />
                        </div> : this.state.SecondApplication_Details.length != 0 ? <div className={this.activeTab == "8" ? "tab-pane active" : "tab-pane"} id="tabs-5">
                          <MergedContactDetailsComponent
                            contact_Data={this.state.SecondApplication_Details} />
                        </div> : <div className={this.activeTab == "8" ? "tab-pane active" : "tab-pane"} id="tabs-5"></div>}

                        <div className={this.activeTab == "9" ? "tab-pane active" : "tab-pane"} id="tabs-7">
                          {/* <CommentContainer
                    commentData={this.state.commentData}
                    role={'dpiit'}
                    /> */}
                          {/* <MergedCommentsDetailsComponent /> */}
                          <MergedCommentsDetailsComponent CommentsData={this.state.Comments_API_Data}
                            role={'dpiit'}
                          />

                        </div>
                        {/* {(this.cancelled_request = "1") && (this.getapplicationIndex = "F1") && <div className="tab-pane" id="tabs-6">
                    {this.state.cancellationData.length > 0 && <MergedCancellationDetailsComponent 
                    data={this.state.cancellationData}
                      role={"dpiit"}
                      isEdit={true}
                      />}
                  </div>}
                  {(this.cancelled_request = "1") && (this.getapplicationIndex = "R1") && <div className="tab-pane" id="tabs-6">
                    {this.state.cancellationData1.length > 0 && <MergedCancellationDetailsComponent 
                    data={this.state.cancellationData1}
                      role={"dpiit"}
                      isEdit={true}
                      />}
                  </div>} */}
                        {this.cancelled_request = "1" && <div className={this.activeTab == "6" || this.activeTab == "5" ? "tab-pane active" : "tab-pane"} id="tabs-6">
                          {/* <CancellationContainer
                      data={this.state.cancellationData}
                      role={"dpiit"}
                      isEdit={true}
                    /> */}
                          {/* {console.log(this.state.cancellationData, "cancellation")} */}
                          {this.state.cancellationData != undefined && this.state.cancellationData.length > 0 && <MergedCancellationDetailsComponent
                            data={this.state.cancellationData}
                            role={"dpiit"}
                            isEdit={true}
                          // IDAPPLICATION ={this.applicationID_Status}
                          />}


                        </div>}
                      </div>
                    ) : (
                      <div className="tab-content">
                        {/* {(this.Status_ID == 6 || this.Status_ID == 7) &&  */}
                        <div className={this.activeTab == "1" ? "tab-pane active" : "tab-pane"} id="tabs-1">
                          {/* <EACReview
                      generalDetailModel={this.state.incubatorDetailsModel}
                    /> */}
                          <MergedEACDetailsComponent EACCommentsData={this.state.EAC_API_Data} />
                        </div>
                        {/* } */}

                        {this.state.FirstApplication_Details.length != 0 && <div className={this.activeTab == "3" ? "tab-pane active" : "tab-pane"} id="tabs-3">
                          <MergedFirstApplicationDetailsComponent
                            FirstApplication_Data={this.state.FirstApplication_Details}
                            DocumentsData={this.state.Documents_API_Data}
                            Inindustrydata_Data={this.state.industrydata_Details}
                            sectorData_Data={this.state.sectorData_Details}
                            teamsData_Data={this.state.teamsData_Details}
                          />
                        </div>}


                        {this.state.SecondApplication_Details.length != 0 && <div className={this.activeTab == "4" ? "tab-pane active" : "tab-pane"} id="tabs-8">
                          <MergedReappliedApplicationDetailsComponent
                            SecondApplication_Data={this.state.SecondApplication_Details}
                            sectorData_Data={this.state.sectorData_Details2}
                            Inindustrydata_Data={this.state.industrydata_Details2}
                          />
                        </div>}

                        <div className={this.activeTab == "7" ? "tab-pane active" : "tab-pane"} id="tabs-4">
                          <MergedDocumentsDetailsComponent
                            DocumentsData={this.state.Documents_API_Data} />
                        </div>

                        {/* {this.state.FirstApplication_Details.length !=0 && <div  className={this.activeTab =="9"?"tab-pane active":"tab-pane"}  id="tabs-5">
                    <MergedContactDetailsComponent 
                    contact_Data = {this.state.FirstApplication_Details}/>
                  </div>} */}

                        {this.state.FirstApplication_Details.length != 0 ? <div className={this.activeTab == "8" ? "tab-pane active" : "tab-pane"} id="tabs-5">
                          <MergedContactDetailsComponent
                            contact_Data={this.state.FirstApplication_Details} />
                        </div> : this.state.SecondApplication_Details.length != 0 ? <div className={this.activeTab == "8" ? "tab-pane active" : "tab-pane"} id="tabs-5">
                          <MergedContactDetailsComponent
                            contact_Data={this.state.SecondApplication_Details} />
                        </div> : ''}

                        <div className={this.activeTab == "9" ? "tab-pane active" : "tab-pane"} id="tabs-7">
                          <MergedCommentsDetailsComponent CommentsData={this.state.Comments_API_Data}
                            role={'dpiit'}
                          />
                        </div>
                        {this.cancelled_request = "1" && <div className={(this.activeTab == "5" || this.activeTab == "6") ? "tab-pane active" : "tab-pane"} id="tabs-6">
                          {/* <CancellationContainer
                      data={this.state.cancellationData}
                      role={"dpiit"}
                      isEdit={true}
                    /> */}
                          {this.state.cancellationData != undefined && this.state.cancellationData.length > 0 && <MergedCancellationDetailsComponent
                            data={this.state.cancellationData}
                            role={"dpiit"}
                            isEdit={true}
                          // IDAPPLICATION ={this.applicationID_Status}
                          />}
                        </div>}
                      </div>
                    )}
                  </div>
                </div>

                {/* schedule a meeting tab */}
                <div
                  className="modal fade"
                  id="scheduleMeeting1"
                  role="dialog"
                  aria-labelledby="exampleModalLabel1"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-slideout modal-md"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header modal-header-border-none modal-header-bottom pr-5">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body pl-5 pr-5 pt-5">
                        <div className="popup-head mb-5 ml-3">
                          <div className="main-heading">Schedule a Meeting</div>
                          <div className="count-text">
                            {/* {this.state.checkedArray.length} Incubators Selected */}
                          </div>
                        </div>

                        <label className="col-sm-4 col-form-head">Meeting Type</label>
                        <div className="col-md-12 mb-4">
                          <div className="select-custom">
                            <select
                              className="form-control input-height"
                              aria-label="Select Entity Type"
                              onChange={this.handleOnChange}
                            >
                              <option value="1">Incubator Selection</option>
                              <option value="2">Performance Review Meeting</option>
                            </select>
                          </div>
                        </div>
                        <label className="col-sm-4 col-form-head">Date & Time</label>
                        <div className="col-md-6 mb-4">
                          <Calendar
                            id="registrationDate"
                            name="registrationDate"
                            className="form-control input-height"
                            format="dd/MM/yyyy"
                            selectedDate={new Date()}
                            // minDate={moment().toDate()}
                            onDateChange={(date: Date, field: string) =>
                              this.handleDateChange(date, field)
                            }
                          />
                        </div>
                        <div className="row pl-3 mb-5">
                          <div className="col-md-6">
                            <label className="col-form-head">From Time</label>
                            <input
                              type="time"
                              value={this.state.fromTime}
                              name="fromTime"
                              className="form-control input-height"
                              onChange={this.onTimeChange}
                            />
                            {
                              this.state.sch_meetingFromTime == true &&
                              <span style={{ color: "red" }}>Meeting already scheduled at this Time</span>
                            }
                            {this.state.showMeetingFromTime == true &&
                              <span style={{ color: "red" }}>From Time Is Required</span>
                            }
                          </div>
                          <div className="col-md-6">
                            <label className="col-form-head">To Time</label>
                            <input
                              type="time"
                              value={this.state.toTime}
                              name="toTime"
                              className="form-control input-height"
                              onChange={this.onTimeChange}
                            />
                            {
                              this.state.sch_meetingToTime == true &&
                              <span style={{ color: "red" }}>Meeting already scheduled at this Time</span>
                            }
                            {this.state.showMeetingToTime == true &&
                              <span style={{ color: "red" }}>To Time Is Required</span>
                            }
                          </div>
                          <div className="row text-left pl-4 mb-5">
                            {this.state.timeValidation == true ? (
                              <label className="error-message">
                                Proper Input Time is Required
                              </label>
                            ) : null}
                            {this.state.timeValidationCurrentTime == true ? (
                              <label className="error-message">
                                Meeting can't be scheduled for past time
                              </label>
                            ) : null}
                            {/* {console.log(this.state.timeValidation,this.state.timeValidationCurrentTime,"meetingData")} */}
                          </div>

                        </div>
                        <label className="col-sm-4 col-form-head">
                          Meeting Link
                        </label>
                        <div className="col-md-12 mb-4">
                          <input
                            type="textarea"
                            id="meeting_link"
                            name="meeting_link"
                            className="form-control input-height"
                            onChange={(e: any) => this.setState({ meetingLink: e.target.value })}
                          />
                          {this.state.showMeetingLink == true &&
                            <span style={{ color: "red" }}>Meeting Link Is Required</span>
                          } <br></br>
                          <label style={{ color: "#333333", fontFamily: "Open Sans", fontSize: "15px", fontWeight: "600", letterSpacing: "0.08px", marginTop: "3px" }}>
                            Meeting Comment
                          </label>
                          <textarea
                            style={{ height: "100px" }}
                            id="meeting_comment"
                            name="meeting_comment"
                            className="form-control"
                            onChange={(e: any) => this.setState({ meetingComment: e.target.value })}
                          />
                          {this.state.showMeetingComment == true &&
                            <span style={{ color: "red" }}>Meeting Comment Is Required</span>
                          }

                        </div>
                        {/* <div className="row pl-5">
                    <button
                      className="btn btn-sisfs btn-transparent-primary"
                      data-dismiss="modal"
                    >
                      CANCEL
                    </button>
                    <button
                      className="tertiary  btn-yellow-primary ml-3"
                      onClick={this.scheduleMeetingFunction}
                    >
                      SCHEDULE
                    </button>
                  </div> */}

                        <div className="row pl-3">
                          <div className="col-md-6">
                            <button
                              className="btn btn-sisfs btn-transparent-primary full-width"
                              data-dismiss="modal"
                            >
                              CANCEL
                            </button>
                          </div>
                          <div className="col-md-6">
                            <button
                              className="tertiary  btn-yellow-primary full-width"
                              onClick={this.scheduleMeetingFunction}
                            // data-dismiss="modal"
                            >
                              SCHEDULE
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <br />
                  <br />
                </div>
              </div>
            </fieldset>
            {this.state.showEditSuccessModalPopUp && (
              // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
              <>
                <div className="modal-backdrop fade show"></div>

                <div
                  className="modal align-middle modal-sisfs d-block"
                  id="FormSubmissionSuccess"
                >
                  <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                      <div className="modal-body text-center">
                        <i className="fa fa-check-circle-o"></i>
                        <h3 className="modal-message">Thank You !</h3>
                        <div className="modal-text">
                          Your Meeting Has been Scheduled !!
                        </div>
                        <div className="modal-action">
                          <button
                            className="btn btn-sisfs btn-yellow-primary"
                            onClick={this.dismissModalPopUp}
                          >
                            Ok !!
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {this.state.showEditFailureModalPopUp && (
              // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
              <>
                <div className="modal-backdrop fade show"></div>
                <div
                  className="modal align-middle modal-sisfs d-block"
                  id="FormSubmissionError"
                >
                  <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                      <div className="modal-body text-center">
                        {/* <i className="fa fa-times-circle-o"></i> */}
                        <h3 className="modal-message message-orange">
                          Oops! Something is Wrong
                        </h3>
                        <br />
                        <h3 className="modal-message message-orange">
                          Please Try Again !!!
                        </h3>
                        <div className="modal-action">
                          <button
                            className="btn btn-sisfs btn-yellow-primary"
                            onClick={this.dismissModalPopUp}
                          >
                            OK !
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="col-md"></div>
        </div>
      </div>
    );
  }

  total_management_amount(total_management_amount: any): any {
    throw new Error('Method not implemented.');
  }

  private getAllTranches = (application_id: number) => {
    if (application_id != 0) {
      let tranche_parameters = {
        application_id: application_id,
        order_by: 'DESC',
      };
      // this.loader = true;
      post(`${GET_ALL_TRANCHES}`, tranche_parameters).then((response: any) => {

        if (!response || !response.data) return;

        var trancheDetailsData: Array<TrancheDetail> = response.data.data;

        for (var i = 0; i < trancheDetailsData.length; i++) {
          var tranche_details = trancheDetailsData[i];
          const file_data = tranche_details.documents;

          if (file_data) {
            Object.keys(file_data).length &&
              Object.keys(file_data).map((key: string, index) => {
                switch (key) {
                  case UploadTypeEnum.ProofDisbursement:
                    file_data[key].map((data: any) => {
                      createFile(data.file).then((blob: any) => {
                        blob.lastModifiedDate = new Date();
                        const src = data.file as string;
                        blob.name = src.substr(src.lastIndexOf('/') + 1, src.length);

                        const index = file_data.proof_of_disbursement.findIndex((el) => el.file_id === data.file_id);
                        file_data.proof_of_disbursement[index] = {
                          file_id: data.file_id,
                          file_type_id: data.file_type_id,
                          file: blob as File,
                          ext: data.ext,
                          fileName: blob.name
                        };
                      });
                    });
                    break;
                  case UploadTypeEnum.QuarterlyReport:
                    file_data[key].map((data: any) => {
                      createFile(data.file).then((blob: any) => {
                        blob.lastModifiedDate = new Date();
                        const src = data.file as string;
                        blob.name = src.substr(src.lastIndexOf('/') + 1, src.length);

                        const index = file_data.quarterly_reports.findIndex((el) => el.file_id === data.file_id);
                        file_data.quarterly_reports[index] = {
                          file_id: data.file_id,
                          file_type_id: data.file_type_id,
                          file: blob as File,
                          ext: data.ext,
                          fileName: blob.name
                        };
                      });
                    });
                    break;
                  case UploadTypeEnum.SupportingDocuments:
                    file_data[key].map((data: any) => {
                      createFile(data.file).then((blob: any) => {
                        blob.lastModifiedDate = new Date();
                        const src = data.file as string;
                        blob.name = src.substr(src.lastIndexOf('/') + 1, src.length);

                        const index = file_data.supporting_documents.findIndex((el) => el.file_id === data.file_id);
                        file_data.supporting_documents[index] = {
                          file_id: data.file_id,
                          file_type_id: data.file_type_id,
                          file: blob as File,
                          ext: data.ext,
                          fileName: blob.name
                        };
                      });
                    });
                    break;
                }


              });
          }
          // this.loader = false;
          tranche_details.documents = file_data;
          trancheDetailsData[i] = tranche_details;
        }

        this.setState({
          tranches: trancheDetailsData,
        });

        //total disbursed
        let grant_disbursed = 0;
        for (let i = 0; i < response.data.data.length; i++) {
          grant_disbursed =
            grant_disbursed +
            (response.data.data[i].fund_released == null
              ? 0
              : Number(response.data.data[i].fund_released));
        }
        this.setState({
          total_grant_disbursed: grant_disbursed,
        });
      });

      //
      let trancheAsc_parameters = {
        application_id: application_id,
        order_by: 'ASC',
      };
      // this.loader = true;
      post(`${GET_ALL_TRANCHES}`, trancheAsc_parameters).then(
        (response: any) => {

          if (!response || !response.data) return;
          this.setState({
            tranchesAsc: response.data.data,
          });
          // this.loader = false;
        }
      );
    }
  };


  //get cancellation
  private getCancellationData = (application_id: number) => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    // this.loader = true;
    post(INCUBATOR_CANCEL_DETAIL, { "application_id": application_id, "logged_user_id": userInfo.id, "roles": userInfo.roles }).then(
      (response: any) => {

        // console.log("CHECKING RESPONSE", response.data.data[0])
        // if (!response || !response.data || response.data == '') return;
        this.setState({
          cancellationData: response.data.data
        })
        // this.setState({
        //   cancellationData: response.data.data[0],
        // });
        //  //console.log("1736",this.state.cancellationData);
        // this.loader = false;
      }
    );
  };
  private getReCancellationData = (application_id: number) => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    post(RE_INCUBATOR_CANCEL_DETAIL, { "application_id": application_id, "logged_user_id": userInfo.id, "userRole": userInfo.roles }).then(
      (response: any) => {
        //  console.log("CHECKING RESPONSE",response.data.data)
        // if (!response || !response.data || response.data == '') return;
        this.setState({
          cancellationData: response.data.data
        })
        // this.setState({
        //   cancellationData: response.data.data[0],
        // });
        //  //console.log("1789",this.state.cancellationData);
      }
    );
  };

  // Application And Reapplication Details API

  private getFirstSecondApplicationDetails = () => {
    if (!this.state.LeftSideBox_API_Data.application_id) {
      return;
    }
    let Paylod = {
      "incubator_application_id": this.state.LeftSideBox_API_Data.application_id,
      "application_index": this.state.LeftSideBox_API_Data.application_index
    }
    this.loader = true;
    post(secincapplicationdetails, Paylod).then(async (response: any) => {
      this.loader = false;
      //  console.log(response.data,"IndData");
      //  //console.log("testtttt",response.data.SecondApplication_Details);
      //  //console.log(response.data.First_Application_Details);

      if (response.data.SecondApplication_Details.length > 0) {
        //  //console.log("@@@@@@@ Into IF 1 @@@@@@")
        this.getStatus = response.data.SecondApplication_Details[0].status_id
        this.getapplicationIndex = response.data.SecondApplication_Details[0].application_index
        //   //console.log("Getting Status 1",this.getStatus)
        this.cancelled_request = response.data.SecondApplication_Details[0].cancellation_requested
        //console.log(this.cancelled_request)
      }
      if (response.data.First_Application_Details.length > 0) {
        //  //console.log("@@@@@@@ Into IF 2 @@@@@@")
        this.getStatus = response.data.First_Application_Details[0].status_id
        this.getStatus1 = response.data.First_Application_Details[0].status_id
        this.getapplicationIndex = response.data.First_Application_Details[0].application_index
        this.cancelled_request = response.data.First_Application_Details[0].final_cancellation_requested
        //  //console.log("Getting Status 2",this.getStatus)
        //console.log(this.cancelled_request)
      }
      if ((response.data.First_Application_Details.length) > 0 && (response.data.First_Application_Details[0].application_index == "F1")) {
        this.getCancellationData(response.data.First_Application_Details[0].id)
      }
      if ((response.data.SecondApplication_Details.length) > 0 && (response.data.SecondApplication_Details[0].application_index == "R1")) {
        //  //console.log("into second applications if")
        //  //console.log(response.data.SecondApplication_Details[0])
        // this.getReCancellationData(response.data.SecondApplication_Details[0].id)
        this.getReCancellationData(response.data.SecondApplication_Details[0].id)

      }



      if (!response || !response.data || response.data == '') return;
      this.setState({
        FirstApplication_Details: response.data.First_Application_Details,
        industrydata_Details: response.data.industrydata1,
        industrydata_Details2: response.data.industrydata2,
        sectorData_Details: response.data.sectorData1,
        sectorData_Details2: response.data.sectorData2,
        SecondApplication_Details: response.data.SecondApplication_Details,
        teamsData_Details: response.data.teamsData1,
        amountDisbursedToInc: response.data.AmountDisbursedToInc,
        amountDisbursedToStartup: response.data.AmountDisbursedToStartups
      })

      // this.setState({
      //   SecondApplication_Details : response.data.SecondApplication_Details
      // })

    })
  }


  // First Application Details API
  // private getFirstApplicationDetails = (application_id: number, user_id:number) =>{
  // let Paylod = {
  //   "application_id": application_id,
  //   "user_id": user_id
  // }
  // post(mergedfirstapplicationdetails,Paylod).then(async (response:any) => {
  //    //console.log(response);
  //   if (!response || !response.data || response.data == '') return;
  //   this.setState({
  //     FirstApplication_Details : response.data.First_Application_Details
  //   })
  // })
  // }

  // left side GeayBox
  private getLeftSideBoxDetails = (user_id: number, application_id: number, status_id: any) => {
    // console.log("with status")
    console.log(user_id)
    let Paylod = {
      "user_id": user_id
    }
    // this.loader = true;
    post(secgreybox, Paylod).then(async (response: any) => {

      //  //console.log(response.data.Data);
      //  //console.log(application_id);
      if (!response || !response.data || response.data == '') return;


      //  //console.log(application_id);

      let getapplication: any = response.data.Data.find((person: any) => (person.incubator_application_no === application_id))
      // && person.status_id === status_id

      // console.log(getapplication,"resssssDataaaaaaaaaa");
      // console.log(response.data.Data,"resssssDataaaaaaaaaa");
      response.data.Data.forEach((employee: any, index: any) => {
        //  //console.log(employee.incubator_application_no != getapplication.incubator_application_no);
        if ((employee.incubator_application_no != null && (employee.incubator_application_no != getapplication.incubator_application_no))) {
          //  //console.log(employee);
          this.ReamaininLeftSideObejects.push(
            employee
          );
        }
        // console.log(this.ReamaininLeftSideObejects,"resssssDataaaaaaaaaa");
        //  //console.log(this.ReamaininLeftSideObejects);
      });
      this.setState({
        LeftSideBox_API_Data1: response.data.Data[0]
      })
      this.setState({
        LeftSideBox_API_Data: getapplication
      });
      if (this.state.LeftSideBox_API_Data.status_id == 7 || this.state.LeftSideBox_API_Data.status_id == 3) {
        this.buttonDisable = false;
      }
      // this.loader = false;
      this.getFirstSecondApplicationDetails();
      this.getBankDetails()

    })

  }

  private getLeftSideBoxDetails1 = (user_id: number, application_id: number) => {

    let Paylod = {
      "user_id": user_id
    }
    // this.loader = true;
    // post(secgreybox, Paylod).then(async (response: any) => {

    //   //  //console.log(response.data.Data);
    //   //  //console.log(application_id);
    //   if (!response || !response.data || response.data == '') return;


    //   //  //console.log(application_id);

    //   let getapplication: any = response.data.Data.find((person: any) => person.incubator_application_no === application_id)
    //   //  //console.log(getapplication);
    //   // response.data.Data.forEach((employee: any, index: any) => {
    //   //   //  //console.log(employee.incubator_application_no != getapplication.incubator_application_no);
    //   //   if ((employee.incubator_application_no != getapplication.incubator_application_no)) {
    //   //     //  //console.log(employee);
    //   //     this.ReamaininLeftSideObejects.push(
    //   //       employee
    //   //     );
    //   //   }
    //   //   //  //console.log(this.ReamaininLeftSideObejects);
    //   // });
    //   this.setState({
    //     LeftSideBox_API_Data1: response.data.Data[0]
    //   })
    //   // console.log(this.ReamaininLeftSideObejects, "get")
    //   this.setState({
    //     LeftSideBox_API_Data: getapplication
    //   });
    //   if (this.state.LeftSideBox_API_Data.status_id == 7 || this.state.LeftSideBox_API_Data.status_id == 3) {
    //     this.buttonDisable = false;
    //   }
    //   // this.loader = false;
    //   this.getFirstSecondApplicationDetails();
    //   this.getBankDetails()

    // })

  }

  // Second Application Details API 
  // private getSecondApplicationDetails = (user_id:number) =>{
  //   get(`${mergedsecndapplicationdetails}/${user_id}/merged_secnd_application_details`).then(
  //     async (response: any) => {
  //       if (!response || !response.data || response.data == '') return;
  //      //console.log(response);
  //     this.setState({
  //       SecondApplication_Details : response.data.SecondApplication_Details
  //     })
  //   })
  //   }


  // Documents Details API

  private getDocumentaionDetails = (user_id: number, applicationId: any) => {
    // console.log(user_id,applicationId,"sattttt")
    let Paylod = {
      "user_id": user_id,
      "application_id": applicationId
    }
    // this.loader = true;
    post(getallDocuments, Paylod).then(async (response: any) => {

      //  //console.log(response.data.Second_Application_Documents);
      if (!response || !response.data || response.data == '') return;
      this.setState({
        Documents_API_Data: response.data
      })
      // this.loader = false;
      //  //console.log(this.Documents_API_Data)
      // }
    })
  }
  private getEACComments = (user_id: number) => {
    let Paylod = {
      "user_id": user_id
    }
    // this.loader = true;
    post(getallEACComments, Paylod).then(async (response: any) => {
      // console.log(response,"meetingData")
      //  //console.log("@@@@@@@@@@@@@EAC RESPONSE (1586) @@@@@@@@@@@@@@@",response.data);
      //  //console.log("@@@@@@@@@@@@@EAC FIRST RESPONSE (1587) @@@@@@@@@@@@@@@",response.data.First_Application_EAC_Comments);
      //  //console.log("@@@@@@@@@@@@@EAC SECOND RESPONSE (1588) @@@@@@@@@@@@@@@",response.data.Second_Application_EAC_Comments);

      if (!response || !response.data || response.data == '') return;
      this.setState({
        EAC_API_Data: response.data
      })
      // this.loader = false;
      //  //console.log(this.EAC_API_Data)
      // }
    })
  }
  //Comments API

  private getCommentDetails = (user_id: number) => {
    let Paylod = {
      "user_id": user_id
    }
    // this.loader = true;
    post(MERGED_COMMENTS, Paylod).then(async (response: any) => {

      //  //console.log(response.data.Second_Application_Comments,"sri@@@@");
      if (!response || !response.data || response.data == '') return;
      this.setState({
        Comments_API_Data: response.data
      })
      // this.loader = false;
      //  //console.log(this.Comments_API_Data)
      // }
    })
  }
  private getFundDetails = (user_id: number, application_id: any) => {
    let Paylod = {
      "USER_ID": user_id
    }
    this.loader = true;
    post(MERGED_FUND_DETAILS, Paylod).then(async (response: any) => {
      this.loader = false;
      //  //console.log("AMITHAAAAAAAAAAAAAAAAAAAAAAAA",Object.values(response.data.secondApplication))
      if (!response || !response.data || response.data == '') return;
      var incubatorDetailsDataModel: any = [];
      const firstfilteredData = Object.values(response.data.firstApplication)
      //  //console.log(firstfilteredData,"firstfilteredData")
      const secondfilteredData = Object.values(response.data.secondApplication)
      //  //console.log(secondfilteredData.length,"firstfilteredData")
      incubatorDetailsDataModel.firstApplication = (!firstfilteredData ? "" : firstfilteredData);
      incubatorDetailsDataModel.secondApplication = (!secondfilteredData ? "" : secondfilteredData);
      // incubatorDetailsDataModel.totalAmount1 = (!response.data.first_totalAmount?0:response.data.first_totalAmount);
      // incubatorDetailsDataModel.totalAmount2 = (!response.data.second_totalAmount?0:response.data.second_totalAmount);
      incubatorDetailsDataModel.total_approved_amt = ((response.data.first_totalAmount.total_approved_amt + response.data.second_totalAmount.total_approved_amt))
      incubatorDetailsDataModel.Amount_Disbursed_to_Startups = response.data.first_totalAmount.startup_disbursed
      incubatorDetailsDataModel.total_management_amount = ((response.data.first_totalAmount.total_management_amount + response.data.second_totalAmount.total_management_amount))
      incubatorDetailsDataModel.total_disbursed_amt = ((response.data.first_totalAmount.total_disbursed_amt + response.data.second_totalAmount.total_disbursed_amt))
      // incubatorDetailsDataModel.Amount_Disbursed_to_Startups = ((response.data.first_totalAmount.Amount_Disbursed_to_Startups + response.data.second_totalAmount.Amount_Disbursed_to_Startups))

      incubatorDetailsDataModel.no_of_tranches = ((response.data.first_totalAmount.no_of_tranches + response.data.second_totalAmount.no_of_tranches))
      incubatorDetailsDataModel.Available_Balance_Incubator = ((response.data.first_totalAmount.total_approved_amt + response.data.second_totalAmount.total_approved_amt + response.data.approved_returns) - (response.data.selectedAppsAmount + response.data.closedAppsAmount))
      incubatorDetailsDataModel.Approved_return = ((response.data.approved_returns))
      // incubatorDetailsDataModel.second_totalAmount = (!response.data.second_totalAmount?0:response.data.second_totalAmount);

      // incubatorDetailsDataModel.totalAmount1 = (!response.data.totalAmount?0:response.data.totalAmount1);

      // console.log(incubatorDetailsDataModel, "DataModel")
      this.setState({
        incubatorDetailsModel: incubatorDetailsDataModel
      });
      // let getapplication :any = firstfilteredData.find((person :any) => person.incubator_application_no === application_id)
      //  //console.log(this.state.incubatorDetailsModel.totalAmount1,"getApplicationn")
      //  //console.log(response.data.first_totalAmount.no_of_tranches+response.data.second_totalAmount.no_of_tranches,"getApplicationn")

      // this.newArrayTrancheTotal = this.state.incubatorDetailsModel.firstApplication[0]
      // this.newArrayTrancheTotal1 = this.state.incubatorDetailsModel.secondApplication[0]

      // this.newArrayTrancheTotal.filter((x: any) => x.incubator_application_no === this.state.LeftSideBox_API_Data.incubator_application_no);
      // this.newArrayTrancheTotal1.filter((x: any) => x.incubator_application_no === this.state.LeftSideBox_API_Data.incubator_application_no);





    })
  }






  //get active application
  private getActiveApplication = (application_id: number) => {
    // this.loader = true;
    get(`${GET_INCUBATOR_DETAILS}/${application_id}/details`).then(
      async (response: any) => {
        //  //console.log(response.data.data);
        this.Status_ID = response.data.data.application_details.status_id


        if (this.Status_ID === 3 || this.Status_ID === 7) {
          this.buttonDisable = false;
        }
        //  //console.log(this.Status_ID);
        if (!response || !response.data || response.data == '') return;
        this.setState({
          commentData: response.data.data.comments_data,
          count_startups_supported: response.data.data.count_startups_supported != null ? response.data.data.count_startups_supported : '0',
          amount_disbursed: response.data.data.amount_disbursed != null ? response.data.data.amount_disbursed : '0',
          user_id: response.data.data.application_details.user_id
        });
        //  //console.log("******************", response.data)
        if (response.data.data != undefined && response.data.data != '') {

          response.data.data.application_details.submitted_at = this.formatDateWithMonth(
            response.data.data.application_details.submitted_at
          );

          if (
            response.data.data.bankDetails != undefined
          ) {
            this.setState({
              bankDetails: response.data.data.bankDetails,
            });
          }
          this.setState({
            total_management_amount: response.data.data.application_details.total_management_amount
          })

          var incubatorDetailsDataModel = response.data.data.application_details;

          incubatorDetailsDataModel.evaluationData =
            response.data.data.evaluationData;
          incubatorDetailsDataModel.meeting_data =
            response.data.data.meeting_data;
          incubatorDetailsDataModel.otherApplications =
            response.data.data.otherApplications;
          incubatorDetailsDataModel.applicationComments =
            response.data.data.applicationComments;
          incubatorDetailsDataModel.evaluation_avg_score =
            response.data.data.evaluation_avg_score;

          this.setState({
            incubatorDetailsModel: incubatorDetailsDataModel
          });

          this.setState({
            isSelectedOrClosed:
              response.data.data.application_details.application_status ==
                'Selected' ||
                response.data.data.application_details.application_status ==
                'Close'
                ? true
                : false,
          });
          let { otherDetailsModel } = this.state;

          if (response.data.data.uploaded_documents) {
            // await this.bindFilesNew(response, otherDetailsModel);
            this.setState({
              otherDetailsModel: response.data.data.uploaded_documents,
            });

          }

          //bind files 

          var application_data = response.data.data.application_details;
          application_data.uploaded_documents = response.data.data.uploaded_documents;

          this.setState({
            applicationGeneral: application_data,
          });
          // this.setState({
          //   commentData:response.data.data.comments_data
          // });

        }

      }
    );
    this.getAllTranches(application_id);
    // this.loader = false;
  };
  //Merged bank details
  private getBankDetails = () => {
    console.log(this.state.LeftSideBox_API_Data)
    if(this.state.LeftSideBox_API_Data.user_id == "") return;
    const payload = {
      user_id: this.state.LeftSideBox_API_Data.user_id
      // user_id:"17"

    }
    // this.loader = true;
    post(MERGED_BANK_DETAILS, payload).then(async (response: any) => {

      if (!response || !response.data || response.data == '') return;
        console.log(response,"kkkkk") 
      this.setState({
        subsequentDtls: (Object.keys(response.data.Subsequentfiles).length === 0 ? "" : response.data.Subsequentfiles[0])
      }) 
      this.setState({
        bankDetails1: (Object.keys(response.data.first_application).length === 0 ? "" : response.data.first_application),
      });
      this.setState({
        bankDetails2: ((Object.keys(response.data.second_application).length === 0) ? response.data.first_application : response.data.second_application),
      });
      // this.loader = false;
    })
    //   //console.log(this.state.bankDetails1,"bankDetails")
  }
}

export default MergedIncubatorDetailsComponent;
