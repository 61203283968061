import react, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { getSessionStorageValue, removeStorage, setSessionStorageValue } from '../../../effects/utils/session-storage';
import UserInfoModel from '../../../models/UserInfoModel';
import { useAuthStateValue } from '../../../providers/AuthStateProvider';
import { Action } from '../../../reducers/reducer.action';
import ProfileDefaultLogo from '../../../images/profile-default-big.svg';
import ProfileDefaultSmall from '../../../images/profile-default.svg';
import React from 'react';
import ApplyNowModalComponent from './ApplyNowModalComponent';
import { post } from '../../../effects/api/api.common';
import { ALLOW_INCUBATOR_CREATION } from '../../../constants/api';
import ConfirmationDialog from '../controls/ConfirmationDialog';
// import { BsActivity } from 'react-icons/bs';

interface IProps {
    user: UserInfoModel,
}

function ProfileContainer(props: IProps) {
    const { user } = props;
    const history = useHistory();
    //@ts-ignore 
    const [{ accessToken }, dispatch] = useAuthStateValue();
    const [allowCreation, setAllowCreation] = useState(true);
    const [dip, setDip] = useState<any>('')
    const [certmodal, setCertmodal] = useState(false);
    const [sessionTimeoutModal, setSessionTimeout] = useState(false);
    const [incubatorPopup, setIncubatorPopup] = useState(false);
    const [incubatorPopupData, setIncubatorPopupData] = useState<any>();
    const [activity, setActivity] = useState<boolean | any>(false)
    const initialName = localStorage.getItem("incubatorName")
    const [incubator_name, setIncubator_name] = useState<any | null>(initialName)
    useEffect(() => {
        allowIncubatorCreation();
        const DipNum = localStorage.getItem("dipp_num")
        setDip(DipNum)
        setInterval(() => {
            setActivity(localStorage.getItem("inactivity"));
        }, 1000)
    }, []);
    useEffect(() => {

    }, [incubatorPopup, incubatorPopupData, incubator_name])
    useEffect(() => {
        if (activity != null && activity == "true") {
            localStorage.removeItem("inactivity")
            setSessionTimeout(true)
            // alert("Session Timeout")
            // onLogout();
        }
    }, [activity, sessionTimeoutModal])
    const dismissModal = () => {
        onLogout();

    }
    const dismissincubatorModal = () => {
        setIncubatorPopup(false)
    }
    const onLogout = () => {
        dispatch({
            type: Action.SET_USER,
            payload: new UserInfoModel('', '', '', '', '')
        })
        setActivity(false);
        removeStorage('user',)
        localStorage.removeItem("dipp_num");
        localStorage.removeItem("justOnce");
        localStorage.removeItem("value");
        localStorage.removeItem("payload")
        localStorage.removeItem("sortedValue")
        localStorage.removeItem("inactivity")
        localStorage.removeItem("incubatorName");
        localStorage.removeItem('errorKey')
        history.replace('');
        // alert("Session Timeout")
    }

    // Allow incubator creation based on the flag  
    const allowIncubatorCreation = () => {
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user)
        // console.log(userInfo)
        var payload = {
            "logged_user_id": userInfo.id
        }
        post(ALLOW_INCUBATOR_CREATION, payload).then((response: any) => {
            // console.log(response.data);
            if (!response || !response.data) return;
            setAllowCreation(response.data.data.allow);
            if (response.data.SessionMessage == "Session Invalid Please Relogin") {
                setIncubatorPopupData(response.data.SessionMessage)
                setIncubatorPopup(true)
                // alert(response.data.SessionMessage);
            }
        })
    }
    // const chekDp = () => {
    //     if (dip == 'null' || dip == "" || dip == undefined) {
    //         setCertmodal(true)
    //     } else {
    //         history.push('/startup_apply')
    //     }
    // }
    return (
        <div className="app-profile-section ">

            <ul className="navbar-nav align-items-center d-md-flex">
                <li className="nav-item dropdown">

                    <a className="nav-link pr-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <div className="media align-items-center d-flex">
                            <span className="avatar avatar-sm rounded-circle">
                                <img alt="Image placeholder"
                                    src={ProfileDefaultLogo}
                                    width="32" height="32" />
                            </span>
                            <div className="media-body ml-2 d-none d-lg-block">
                                <span className="user-name mb-0 text-sm">{incubator_name !== undefined && incubator_name !== null ? incubator_name : user?.name}</span>
                                <span className={(user?.roles == "incubator" || user?.roles == "secretariat") ? "user-role-small mb-0 text-sm" : "user-role mb-0 text-sm"}>{user?.roles == "secretariat" ? 'SISFS Secretariat' : user?.roles}</span>
                            </div>
                        </div>
                    </a>

                    <div className="dropdown-menu dropdown-menu-arrow dropdown-menu-right">

                        <div className=" dropdown-header noti-title">
                            <div className="media align-items-center">
                                <span className="avatar avatar-sm rounded-circle">
                                    <img alt="Image placeholder"
                                        src={ProfileDefaultSmall}
                                        width="50" height="50" />
                                </span>
                                <div className="media-body ml-3 /*d-none d-lg-block*/">
                                    <span className="mb-0 text-sm user-name d-flex font-weight-bold">{incubator_name !== undefined && incubator_name !== null ? incubator_name : user?.name}</span>
                                    <span className="user-email">{user?.email}</span>
                                </div>
                            </div>


                        </div>
                        {
                            user && user.roles === "incubator" &&
                            allowCreation === true &&
                            <div className="pt-2 pb-2 dropdown-item">

                                <Link className="btn btn-sisfs btn-yellow-primary" to="/incubator_apply">Apply Now</Link>


                            </div>
                        }

                        {/* {
                            user && user.roles === "startup" &&
                            <div className="pt-2 pb-2 dropdown-item">
                                <Link className="btn btn-sisfs btn-yellow-primary" to="/startup_apply" onClick={chekDp}>Apply Now</Link>
                            </div>
                        } */}
                        <fieldset>
                            <div className="d-flex">

                                {
                                    user && user.roles === "incubator" &&
                                    // <Link role="menuitem" className="dropdown-item" to="/incubator_dashboard">My Dashboard</Link>
                                    <Link role="menuitem" className="dropdown-item link" to="/incubator_status"><span className='myapp'>My Application</span></Link>
                                }
                                {
                                    user && user.roles === "startup" &&
                                    // <Link role="menuitem" className="dropdown-item link" to="/incubator_dashboard">My Dashboard</Link>
                                    <Link role="menuitem" className="dropdown-item link" to="/startup_status">My Application</Link>
                                }
                            {/*   
                            /////////////// commenting dashboard here and implenting home button ////////////////////////////                              
                                {
                                    user && user.roles === "dpiit" &&
                                    <Link role="menuitem" className="dropdown-item link" to="/dashboard_dpiit">My Dashboard</Link>
                                }
                                {
                                    user && user.roles === "secretariat" &&
                                    <Link role="menuitem" className="dropdown-item link" to="/dashboard_secretariat">My Dashboard</Link>
                                }
                                {
                                    user && user.roles === "eac" &&
                                    <Link role="menuitem" className="dropdown-item link" to="/eac_dashboard">My Dashboard</Link>
                                }
                                 */}
                                 {
                                    user && user.roles === "dpiit" &&
                                    <Link role="menuitem" className="dropdown-item link" to="/dpiit_notice_board">Home</Link>
                                }
                                {
                                    user && user.roles === "secretariat" &&
                                    <Link role="menuitem" className="dropdown-item link" to="/sec_notice_board">Home</Link>
                                }
                                {
                                    user && user.roles === "eac" &&
                                    <Link role="menuitem" className="dropdown-item link" to="/eac_notice_board">Home</Link>
                                }
                                <a href="/" className="dropdown-item link text-center" onClick={onLogout}>
                                    <span>Sign Out</span>
                                </a>

                            </div>
                        </fieldset>
                    </div>
                </li>
            </ul>

            {certmodal &&
                <ConfirmationDialog
                    headerText={"Oops !!"}
                    bodyText={
                        <React.Fragment>
                            Account detail provided is not recognized. If your startup is recognized, please use the user account having active startup recognition on{" "}
                            <a href='https://startupindia.gov.in/' target="_blank">startupindia.gov.in</a>{" "}
                            or use your CIN/LLPIN/ Registration number as username for login. In case you still face an issue, please raise a ticket in the contact page of the website.
                        </React.Fragment>}

                    onDialogClose={() => setCertmodal(false)}
                    iconClass="test"

                />

            }
            {sessionTimeoutModal && (
                // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
                <>
                    <div className="modal-backdrop fade show"></div>

                    <div
                        className="modal align-middle modal-sisfs d-block"
                        id="FormSubmissionSuccess"
                    >
                        <div className="modal-dialog modal-dialog-centered modal-md">
                            <div className="modal-content">
                                <div className="modal-body text-center">
                                    <h3 className="modal-message" style={{ color: '#e26640' }}>Your Session has expired!</h3>
                                    <h3 className="modal-message" style={{ color: '#e26640' }}>Please Login again.</h3>
                                    <div className="modal-action">
                                        <button className="btn btn-sisfs btn-yellow-primary" onClick={dismissModal}>
                                            Ok!!
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {incubatorPopup && (
                // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
                <>
                    <div className="modal-backdrop fade show"></div>

                    <div
                        className="modal align-middle modal-sisfs d-block"
                        id="FormSubmissionSuccess"
                    >
                        <div className="modal-dialog modal-dialog-centered modal-md">
                            <div className="modal-content">
                                <div className="modal-body text-center">
                                    <h3 className="modal-message" style={{ color: '#e26640' }}>{incubatorPopupData}</h3>
                                    <div className="modal-action">
                                        <button className="btn btn-sisfs btn-yellow-primary" onClick={dismissincubatorModal}>
                                            Ok!!
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>

    )
};

export default ProfileContainer;