import React, { Fragment, useEffect, useState } from "react";
import { get, post } from "../../effects/api/api.common";
import {
  GET_STARTUP_PORTFOLIO_LIST_DESC,
  PRPGRESS_REVIEW_FORM_GET_API,
} from "../../constants/api";
import moment from "moment";
import seedfundlogo from "../../images/avatar.jpg";
import one from "../../images/1.svg";
import two from "../../images/2.svg";
import three from "../../images/3.svg";
import four from "../../images/4.svg";
import five from "../../images/inc.svg";
import six from "../../images/6.svg";
import { Link } from "react-router-dom";
import CardBg from "../../images/cardbgstartup.png";
import Progress from "../../images/prog.svg";
import Employed from "../../images/emp.svg";
import IP from "../../images/ip.svg";
import Funds from "../../images/funds.png";
import fb from "../../images/fb.svg";
import you from "../../images/you.svg";
import twi from "../../images/twi.svg";
import li from "../../images/li.png";
import web from "../../images/web.svg";
import back from "../../images/back.svg";
const StartupPortfolioDetail = (props: any) => {
  console.log(props);
  const [StartupID, seStartupID] = useState("");
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState<any>({}); 
  const [proofofconcept, setproofofconcept] = useState<any>("");
  const [prototypedevelopement, setprototypedevelopement] = useState<any>("");
  const [productdevelopement, setproductdevelopement] = useState<any>("");
  const [fieldtrials, setfieldtrials] = useState<any>("");
  const [marketlaunch, setmarketlaunch] = useState<any>("");
  const [noofemployee, setnoofemployee] = useState<any>("");
  const [noofipregistration, setnoofipregistration] = useState<any>("");
  const [Funding_Status, setFunding_Status] = useState<any>("");
  const [angel_funding, setangel_funding] = useState<any>("");
  const [loans, setloans] = useState<any>("");
  const [vc_funding, setvc_funding] = useState<any>("");
  const [others, setothers] = useState<any>("");
  useEffect(() => {
    console.log(props.location.state);
    if (
      typeof props.location.state !== "undefined" &&
      props.location.state != null
    ) {
      if (props.location.state.Startup_application_id) {
        seStartupID(props.location.state.Startup_application_id);
        getPortFolioDetail(props.location.state.Startup_application_id);
        setproofofconcept(props.location.state.proofofconcept);
        setprototypedevelopement(props.location.state.prototypedevelopement);
        setproductdevelopement(props.location.state.productdevelopement);
        setfieldtrials(props.location.state.fieldtrials);
        setmarketlaunch(props.location.state.marketlaunch);
        setnoofemployee(props.location.state.noofemployee);
        setnoofipregistration(props.location.state.noofipregistration);
        setFunding_Status(props.location.state.Funding_Status);
        setangel_funding(props.location.state.angel_funding);
        setloans(props.location.state.loans);
        setvc_funding(props.location.state.vc_funding);
        setothers(props.location.state.others);
        // Year_Quater_API();
      }
    }
  }, []);
  const getPortFolioDetail = (Startup_application_id: any) => {
    setLoader(true);
    const url = GET_STARTUP_PORTFOLIO_LIST_DESC + Startup_application_id;
    get(url).then((response: any) => {
      setLoader(false);
      console.log(response.data.data[0]);
      if (!response || !response.data) return;
      if (response?.data?.result === "success") {
        setData(response.data.data[0]);
      } else {
        setData({});
      }
    });
  };
  // Get the current quarter and year
  const currentQuarter: number = Math.ceil(moment().month() / 3) + 1;
  const currentYear: number = moment().year();
  // Calculate previous quarter and year
  let previousQuarter: number;
  let previousYear: number;

  if (currentQuarter === 1) {
    previousQuarter = 4;
    previousYear = currentYear - 1;
  } else {
    previousQuarter = currentQuarter - 1;
    previousYear = currentYear;
  }
  console.log(`Current quarter: Q${currentQuarter} ${currentYear}`);
  console.log(`Previous quarter: Q${previousQuarter} ${previousYear}`);
  // const Year_Quater_API = () => {
  //   let payload = {
  //     year: previousYear,
  //     quarter: previousQuarter,
  //     startup_application_id: props.location.state.Startup_application_id,
  //   };
  //   console.log(payload);
  //   post(PRPGRESS_REVIEW_FORM_GET_API, payload).then((res: any) => {
  //     console.log(res.data.data[0]);
  //     setProgressData(res.data.data[0]);
  //     if (
  //       res.data.data[0].angel_funding != "0" ||
  //       res.data.data[0].loans != "0" ||
  //       res.data.data[0].vc_funding != "0" ||
  //       res.data.data[0].others != "0"
  //     ) {
  //       setFundingStatus("Y");
  //     } else {
  //       setFundingStatus("N");
  //     }
  //   });
  // };
  const shouldRedirect = data && data.incubator_portfolio_id !== 0;

  return (
    <>
      <div
        className="container-fluid"
        style={{ backgroundColor: "#F9FAFF", fontFamily: "Poppins" }}
      >
        <div
          className="row mt-4"
          style={{ float: "left", marginLeft: "150px" }}
        >
          <Link
            to={{
              pathname: "/startup_portfolio",
            }}
          >
            <img
              src={back}
              alt="DIPP"
              className="portImage"
              style={{
                height: "30px",
              }}
            />
          </Link>{" "}
        </div>
        <div
          className="row mt-4"
          style={{ float: "right", marginRight: "50px" }}
        >
          {data.website_url ? (
            <a
              href={data.website_url}
              target="_blank"
              style={{ marginRight: "10px" }}
            >
              <img
                src={web}
                alt="DIPP"
                className="portImage"
                style={{
                  width: "30px",
                  height: "30px",
                }}
              />
            </a>
          ) : (
            <img
              src={web}
              alt="DIPP"
              className="portImage"
              style={{
                width: "30px",
                height: "30px",
                marginRight: "10px",
              }}
            />
          )}

          {data.facebook_link ? (
            <a
              href={data.facebook_link}
              target="_blank"
              style={{ marginRight: "10px" }}
            >
              <img
                src={fb}
                alt="DIPP"
                className="portImage"
                style={{
                  width: "30px",
                  height: "30px",
                }}
              />
            </a>
          ) : (
            <img
              src={fb}
              alt="DIPP"
              className="portImage"
              style={{
                width: "30px",
                height: "30px",
                marginRight: "10px",
              }}
            />
          )}
          {data.twitter_link ? (
            <a
              href={data.twitter_link}
              target="_blank"
              style={{ marginRight: "10px" }}
            >
              <img
                src={twi}
                alt="DIPP"
                className="portImage"
                style={{
                  width: "30px",
                  height: "30px",
                }}
              />
            </a>
          ) : (
            <img
              src={twi}
              alt="DIPP"
              className="portImage"
              style={{
                width: "30px",
                height: "30px",
                marginRight: "10px",
              }}
            />
          )}
          {data.youtube_link ? (
            <a
              href={data.youtube_link}
              target="_blank"
              style={{ marginRight: "10px" }}
            >
              <img
                src={you}
                alt="DIPP"
                className="portImage"
                style={{
                  width: "30px",
                  height: "30px",
                }}
              />
            </a>
          ) : (
            <img
              src={you}
              alt="DIPP"
              className="portImage"
              style={{
                width: "30px",
                height: "30px",
                marginRight: "10px",
              }}
            />
          )}
          {data.linkedin_link ? (
            <a
              href={data.linkedin_link}
              target="_blank"
              style={{ marginRight: "10px" }}
            >
              <img
                src={li}
                alt="DIPP"
                className="portImage"
                style={{
                  width: "30px",
                  height: "30px",
                }}
              />
            </a>
          ) : (
            <img
              src={li}
              alt="DIPP"
              className="portImage"
              style={{
                width: "30px",
                height: "30px",
                marginRight: "10px",
              }}
            />
          )}
        </div>
        <br></br>
        <h3
          className="mt-5"
          style={{
            textAlign: "center",
            fontWeight: "700",
            font: "normal normal 800 35px/53px Poppins",
          }}
        >
          {data &&
          (data.name_of_entity != null || data.name_of_entity != undefined)
            ? String(data.name_of_entity)
            : "-"}
        </h3>
        <div className="row mt-5">
          <div
            className="col-md-4"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "-25px",
            }}
          >
            <div
              style={{
                width: "180px",
                height: "180px",
                borderRadius: "50%",
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Adjust the shadow as needed
              }}
            >
              <img
                src={data.logo ? data.logo : seedfundlogo}
                alt={data.name_of_entity}
                className="portImage"
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>

          <div className="col-md-8">
            <div className="row col-md-12">
              <div className="col-md-6 d-flex align-items-center">
                <img
                  src={one}
                  alt="DIPP"
                  className="portImage"
                  style={{
                    width: "55px",
                    height: "55px",
                    borderRadius: "50%",
                    objectFit: "cover",
                    marginRight: "10px",
                  }}
                />
                <p
                  style={{
                    marginBottom: "5px",
                    fontWeight: "600",
                    font: "normal normal 600 17px / 30px Poppins",
                  }}
                >
                  {data &&
                  (data.recognition_number != null ||
                    data.recognition_number != undefined)
                    ? String(data.recognition_number)
                    : "-"}
                </p>
              </div>

              <div className="col-md-6 d-flex align-items-center">
                <img
                  src={two}
                  alt="DIPP"
                  className="portImage"
                  style={{
                    width: "55px",
                    height: "55px",
                    borderRadius: "50%",
                    objectFit: "cover",
                    marginRight: "10px",
                  }}
                />
                <p
                  style={{
                    marginBottom: "5px",
                    fontWeight: "600",
                    font: "normal normal 600 17px / 30px Poppins",
                  }}
                >
                  {data &&
                  (data.city_name != null || data.city_name != undefined)
                    ? String(data.city_name)
                    : "-"}{" "}
                  (
                  {data &&
                  (data.state_name != null || data.state_name != undefined)
                    ? String(data.state_name)
                    : "-"}
                  )
                </p>
              </div>
            </div>
            <div className="row col-md-12" style={{ marginTop: "10px" }}>
              <div className="col-md-6 d-flex align-items-center">
                <img
                  src={three}
                  alt="DIPP"
                  className="portImage"
                  style={{
                    width: "55px",
                    height: "55px",
                    borderRadius: "50%",
                    objectFit: "cover",
                    marginRight: "10px",
                  }}
                />
                <p
                  style={{
                    marginBottom: "5px",
                    fontWeight: "600",
                    font: "normal normal 600 17px / 30px Poppins",
                  }}
                >
                  {data &&
                  (data.nature_of_entity != null ||
                    data.nature_of_entity != undefined)
                    ? String(data.nature_of_entity)
                    : "-"}
                </p>
              </div>

              <div className="col-md-6 d-flex align-items-center">
                <img
                  src={four}
                  alt="DIPP"
                  className="portImage"
                  style={{
                    width: "55px",
                    height: "55px",
                    borderRadius: "50%",
                    objectFit: "cover",
                    marginRight: "10px",
                  }}
                />
                <p
                  style={{
                    marginBottom: "5px",
                    fontWeight: "600",
                    font: "normal normal 600 17px / 30px Poppins",
                  }}
                >
                  {data && (data.Industry != null || data.Industry != undefined)
                    ? String(data.Industry)
                    : "-"}
                </p>
              </div>
            </div>
            <div className="row col-md-12" style={{ marginTop: "10px" }}>
              <div className="col-md-6 d-flex align-items-center">
                <img
                  src={five}
                  alt="DIPP"
                  className="portImage"
                  style={{
                    width: "55px",
                    height: "55px",
                    borderRadius: "50%",
                    objectFit: "cover",
                    marginRight: "10px",
                  }}
                />
                <p
                  style={{
                    marginBottom: "5px",
                    fontWeight: "600",
                    font: "normal normal 600 17px / 30px Poppins",
                  }}
                >
                  Associated Incubator -{" "}
                  {data && data.registered_name ? (
                    shouldRedirect ? (
                      <Link
                        to={{
                          pathname: "/portfolio_detail",
                          state: {
                            portfolioId: data.incubator_portfolio_id,
                            incubatorID: data.incubator_user_id,
                            evalutionRate: data.Evaluation,
                            check: "fromstartp",
                            Startup_application_id: data.id,
                            Startup_user_id: data.startup_user_id,
                            proofofconcept: proofofconcept,
                            prototypedevelopement: prototypedevelopement,
                            productdevelopement: productdevelopement,
                            fieldtrials: fieldtrials,
                            marketlaunch: marketlaunch,
                            noofemployee: noofemployee,
                            noofipregistration: noofipregistration,
                            Funding_Status: Funding_Status,
                            angel_funding: angel_funding,
                            loans: loans,
                            vc_funding: vc_funding,
                            others: others,
                          },
                        }}
                        style={{
                          color: "#32aed5",
                          textDecoration: "none",
                        }}
                      >
                        {String(data.registered_name)}
                      </Link>
                    ) : (
                      String(data.registered_name)
                    )
                  ) : (
                    "-"
                  )}
                </p>
              </div>

              <div className="col-md-6 d-flex align-items-center">
                <img
                  src={six}
                  alt="DIPP"
                  className="portImage"
                  style={{
                    width: "55px",
                    height: "55px",
                    borderRadius: "50%",
                    objectFit: "cover",
                    marginRight: "10px",
                  }}
                />
                <p
                  style={{
                    marginBottom: "5px",
                    fontWeight: "600",
                    font: "normal normal 600 17px / 30px Poppins",
                  }}
                >
                  {data && (data.Sector != null || data.Sector != undefined)
                    ? String(data.Sector)
                    : "-"}
                </p>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <div className="row col-md-12">
          <div className="col-md-1"></div>
          <div className="col-md-10">
            <h4
              style={{
                fontWeight: "700",
                font: "800 30px / 53px Poppins;",
                marginTop: "25px",
              }}
            >
              What is your value proposition for your startup?
            </h4>{" "}
            <br></br>
            <p
              style={{
                font: "normal normal 500 20px / 30px Poppins",
                color: "black",
                textAlign: "justify",
              }}
            >
              {data &&
              (data.value_proposition_for_problem != null ||
                data.value_proposition_for_problem != undefined)
                ? String(data.value_proposition_for_problem)
                : "-"}
            </p>{" "}
          </div>
          <div className="col-md-1"></div>
        </div>
        <br></br>
        <div className="row col-md-12">
          <div className="col-md-1"></div>
          <div className="col-md-10">
            <h4
              style={{
                fontWeight: "700",
                font: "800 30px / 53px Poppins;",
              }}
            >
              Other Information
            </h4>{" "}
            <br></br>
            <div className="row col-md-12" style={{ marginTop: "25px" }}>
              <div className="col-md-6">
                <div
                  className="card"
                  style={{
                    backgroundImage: `url(${CardBg})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundColor: "#c6e1ff",
                    borderRadius: "15px",
                  }}
                >
                  <div
                    className="card-body"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <h4
                      style={{
                        textAlign: "center",
                        fontWeight: "700",
                        font: "normal normal 600 24px / 60px Poppins",
                      }}
                    >
                      <u>Progress of Startup</u>
                    </h4>
                    <img
                      src={Progress}
                      style={{
                        width: "50px",
                        height: "50px",
                      }}
                    />
                    <p
                      className="mt-3"
                      style={{
                        textAlign: "center",
                        fontWeight: "700",
                        font: "normal normal 600 20px / 20px Poppins",
                        color: "#506EB9",
                      }}
                    >
                      <a
                        data-toggle="modal"
                        data-target="#ViewDetailsModal"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <u>View Details</u>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="card"
                  style={{
                    backgroundImage: `url(${CardBg})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundColor: "#c6e1ff",
                    borderRadius: "15px",
                  }}
                >
                  <div
                    className="card-body"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <h4
                      style={{
                        textAlign: "center",
                        fontWeight: "700",
                        font: "normal normal 600 24px / 60px Poppins",
                      }}
                    >
                      <u>Funds Raised Externally</u>
                    </h4>
                    <img
                      src={Funds}
                      style={{
                        width: "50px",
                        height: "50px",
                      }}
                    />
                    <p
                      className="mt-3"
                      style={{
                        textAlign: "center",
                        fontWeight: "700",
                        font: "800 18px Poppins",
                      }}
                    >
                      {Funding_Status &&
                      (Funding_Status != null || Funding_Status != undefined)
                        ? String(Funding_Status)
                        : "Information Not Shared"}
                    </p>
                  </div>
                </div>
              </div>
            </div>{" "}
            <br></br>
            <div className="row col-md-12">
              <div className="col-md-6">
                <div
                  className="card"
                  style={{
                    backgroundImage: `url(${CardBg})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundColor: "#c6e1ff",
                    borderRadius: "15px",
                  }}
                >
                  <div
                    className="card-body"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <h4
                      style={{
                        textAlign: "center",
                        fontWeight: "700",
                        font: "normal normal 600 24px / 60px Poppins",
                      }}
                    >
                      <u>No. of People Employed</u>
                    </h4>
                    <img
                      src={Employed}
                      style={{
                        width: "50px",
                        height: "50px",
                      }}
                    />
                    <p
                      className="mt-3"
                      style={{
                        textAlign: "center",
                        fontWeight: "700",
                        font: "800 18px Poppins",
                      }}
                    >
                      {noofemployee &&
                      (noofemployee != null || noofemployee != undefined)
                        ? String(noofemployee)
                        : "Information Not Shared"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="card"
                  style={{
                    backgroundImage: `url(${CardBg})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundColor: "#c6e1ff",
                    borderRadius: "15px",
                  }}
                >
                  <div
                    className="card-body"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <h4
                      style={{
                        textAlign: "center",
                        fontWeight: "700",
                        font: "normal normal 600 24px / 60px Poppins",
                      }}
                    >
                      <u>IP Registration Requests Filed</u>
                    </h4>
                    <img
                      src={IP}
                      style={{
                        width: "50px",
                        height: "50px",
                      }}
                    />
                    <p
                      className="mt-3"
                      style={{
                        textAlign: "center",
                        fontWeight: "700",
                        font: "800 18px Poppins",
                      }}
                    >
                      {noofipregistration &&
                      (noofipregistration != null ||
                        noofipregistration != undefined)
                        ? String(noofipregistration)
                        : "Information Not Shared"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-1"></div>
        </div>
        <br></br>
      </div>
      <div
        className="modal fade"
        id="ViewDetailsModal"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div
              className="modal-header d-flex justify-content-center align-items-center position-relative"
              style={{ background: "#F9FAFF", border: "#f9faff" }}
            >
              <div
                style={{
                  color: "#001F40",
                  font: "normal normal 600 25px / 35px Poppins",
                  marginBottom: "15px",
                }}
              >
                Progress Of Startup
              </div>
              <button
                type="button"
                className="close btn position-absolute"
                style={{
                  right: "0px",
                  top: "0px",
                  color: "#fff",
                  fontWeight: "bold",
                  backgroundColor: "#FF0000",
                  borderRadius: "50%",
                  width: "fit-content", // Adjust width to content size
                  height: "45%", // Adjust height to content size
                  padding: "10px", // Control the size of the circle
                  lineHeight: "1", // Ensure cross mark is centered vertically
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  opacity: "inherit",
                }}
                data-dismiss="modal"
                aria-label="Close"
              >
                <span
                  aria-hidden="true"
                  style={{
                    color: "white",
                    fontWeight: "900",
                    fontSize: "24px",
                    display: "block",
                  }}
                >
                  &times;
                </span>
              </button>
            </div>

            <div className="modal-body pl-4 pr-4">
              <div className="row">
                <div className="col text-center">
                  <div className="card-apply">
                    <div className="card-apply-content">
                      <div className="row col-md-12">
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                          <div className="form-group row">
                            <label
                              className="col-md-6 col-form-label"
                              style={{
                                color: "#FF0000",
                                marginBottom: 0,
                                textAlign: "end",
                                fontWeight: "600",
                                fontSize: "14px",
                              }}
                            >
                              Proof of Concept :
                            </label>
                            <div className="col-md-6">
                              <input
                                style={{
                                  textAlign: "center",
                                  background: "white",
                                  color: "#2C4096",
                                  fontSize: "small",
                                }}
                                type="text"
                                className="form-control"
                                value={proofofconcept}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              className="col-md-6 col-form-label"
                              style={{
                                color: "#FF0000",
                                marginBottom: 0,
                                textAlign: "end",
                                fontWeight: "600",
                                fontSize: "14px",
                              }}
                            >
                              Prototype development :
                            </label>
                            <div className="col-md-6">
                              <input
                                style={{
                                  textAlign: "center",
                                  background: "white",
                                  color: "#2C4096",
                                  fontSize: "small",
                                }}
                                type="text"
                                className="form-control"
                                value={prototypedevelopement}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              className="col-md-6 col-form-label"
                              style={{
                                color: "#FF0000",
                                marginBottom: 0,
                                textAlign: "end",
                                fontWeight: "600",
                                fontSize: "14px",
                              }}
                            >
                              Product Development :
                            </label>
                            <div className="col-md-6">
                              <input
                                style={{
                                  textAlign: "center",
                                  background: "white",
                                  color: "#2C4096",
                                  fontSize: "small",
                                }}
                                type="text"
                                className="form-control"
                                value={productdevelopement}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              className="col-md-6 col-form-label"
                              style={{
                                color: "#FF0000",
                                marginBottom: 0,
                                textAlign: "end",
                                fontWeight: "600",
                                fontSize: "14px",
                              }}
                            >
                              Field Trials :
                            </label>
                            <div className="col-md-6">
                              <input
                                style={{
                                  textAlign: "center",
                                  background: "white",
                                  color: "#2C4096",
                                  fontSize: "small",
                                }}
                                type="text"
                                className="form-control"
                                value={fieldtrials}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              className="col-md-6 col-form-label"
                              style={{
                                color: "#FF0000",
                                marginBottom: 0,
                                textAlign: "end",
                                fontWeight: "600",
                                fontSize: "14px",
                              }}
                            >
                              Market Launch :
                            </label>
                            <div className="col-md-6">
                              <input
                                style={{
                                  textAlign: "center",
                                  background: "white",
                                  color: "#2C4096",
                                  fontSize: "small",
                                }}
                                type="text"
                                className="form-control"
                                value={marketlaunch}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-1"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StartupPortfolioDetail;
