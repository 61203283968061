import React, { useEffect, useState } from "react";
import { INCUBATOR_PREFRENCE_GETLOGS } from "../../constants/api";
import { post } from "../../effects/api/api.common";
import Moment from 'react-moment';

export default function IncubatorPrefrenceInformationComponent({ incubatorId, appId, userName }: any) {

  const [incubatorList, setIncubatorList] = useState([]);
 
  let IncubatorId: any = incubatorId;
  let IncubatorApplicationID: any = appId;
  let IncubatorUserName: any = userName;

  const getUserList = () => {
    let payload = {
      "incubator_id": IncubatorId,
      "incubator_application_id": IncubatorApplicationID
    }
    post(INCUBATOR_PREFRENCE_GETLOGS, payload).then((response: any) => {
      if (!response || !response.data) return;
      if (response.data.result === "success") {
        setIncubatorList(response.data.Data);
        // console.log("response.data.data", response.data.data);
      }
    });
  };

  useEffect(() => {
    getUserList()
  }, [])

  return (
    <>
      <div className="app-inside app-apply-incubator">
        <div className="status-list-wrapper mt-4">
          <div className="container">
            <div className="row">
              <div className="col-12 text-left">
                <p className="dashboard_inc">{IncubatorUserName}</p>
              </div>
            </div>
            <div className="app-inside-content">
              <div className="tab-pane active" id="tabs-1">
                <div className="table-scroll mb-4">

                  <table className="reviewTable userListTable" >
                    <thead>
                      <tr>
                        <th className="text-left table-heading custom-td-left table-heading ">Incubator Application ID</th>
                        <th className="text-left table-heading"> Incubator Status </th>
                        <th className="text-left table-heading"> Created At </th>
                        <th className="text-left table-heading" onClick={() => getUserList()}> User</th>
                      </tr>
                    </thead>
                    {incubatorList.length === 0 ? <tr><td className="text-center" colSpan={5}>No data</td></tr> :
                      <tbody>
                        {incubatorList.map((item: any, id: any) => (
                          <tr key={id}>
                            <td>{item.incubator_application_no}</td>
                            <td>{item.active_status == 0 ? <span className="bg-danger text-white rounded-pill py-1 px-2">Inactive</span> : <span className="bg-success text-white rounded-pill py-1 px-2">Active</span>}</td>
                            {/* <td><Moment format="YYYY-MM-DD HH:mm:ss">{item.created_at}</Moment></td> */}
                            <td> {item.created_at} <br/>
                                {item.created_time} 
                            </td>
                            <td>{item.dpiit_name}</td>
                            {/* {console.log(<Moment format="DD/MM/YYYY hh:mm:ss">{item.created_at}</Moment>)} */}
                          </tr>
                        ))}
                      </tbody>
                    }
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}