import React, { useEffect, useState } from "react";
import Stepper from "bs-stepper";
import {
  IncubatorTeamModel,
  TeamDetails,
} from "../../../models/incubator/IncubatorTeamModel";
import { SaveRequestModel } from "../../../models/incubator/SaveRequestModel";
import { STORE_INCUBATOR_APPLICATION } from "../../../constants/api";
import DeleteRow from "../../../images/delete-row.svg";
import IncrementDecrement from "../../common/controls/IncrementDecrement";
import { requestType, StepperEnum } from "../../../utils/Enums";
import { validateRequiredText } from "../../../effects/utils/validations";
//import { parse } from "node:path";

interface IIncubatorIncubatorTeam {
  stepper: any;
  incubatorTeamDetailsModel: IncubatorTeamModel;
  onStepSave: (
    stepperIndex: number,
    incubatorTeam: IncubatorTeamModel,
    request: number
  ) => void;
  errors: Array<string>;
  teamError: Array<Number>;
  disableForm: any
}

/** Renders the Incubator Team component step(form) */
function TeamDetailsComponent(props: IIncubatorIncubatorTeam) {
  const { stepper, incubatorTeamDetailsModel, onStepSave, disableForm } = props;

  const [incubatorTeam, setIncubatorTeam] = useState<IncubatorTeamModel>(
    new IncubatorTeamModel("", "", "", 1, [])
  );

  const [teams, setTeams] = useState<Array<TeamDetails>>([
    new TeamDetails(0, "", 0),
  ]);

  useEffect(() => {
    incubatorTeam.team_data = teams;
    onStepSave(
      StepperEnum.IncubatorTeam,
      incubatorTeam,
      requestType.propertyChange
    );
  }, []);


  useEffect(() => {
    setIncubatorTeam(incubatorTeamDetailsModel);
    if (incubatorTeamDetailsModel.team_data?.length > 0) {
      setTeams(incubatorTeamDetailsModel.team_data);
    }
  }, [incubatorTeamDetailsModel.team_data]);

  // triggers whenever user makes any changes in the form for select and input type
  const handleChange = (
    evt: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const name = evt.target.name;
    const value = evt.target.value;

    setIncubatorTeam((prevState: IncubatorTeamModel) => {
      return {
        ...prevState,
        [name]: value.length ? value : "",
      };
    });
    let incubator = JSON.parse(JSON.stringify(incubatorTeam));

    if (name == "ceo_firstname" || name == "ceo_lastname") {
      incubator[name] = value.length ? value.replace(/[^a-zA-Z-.&() |`~]/ig, '') : "";
      setIncubatorTeam(incubator);
    } else {
      incubator[name] = value.length ? value : "";
      setIncubatorTeam(incubator);
    }
    // onStepSave(
    //   StepperEnum.IncubatorTeam,
    //   incubator,
    //   requestType.propertyChange
    // );
  };

  // triggers when user clicks on the save and next button
  const onSave = () => {
    incubatorTeam.team_data = teams;
    onStepSave(StepperEnum.IncubatorTeam, incubatorTeam, requestType.save);

    stepper && stepper.next();
    window.scrollTo(0, 250);
  };
  const [sumOfEmpVal, setSumOfEmpVal] = useState(false)
  const [fullempval, setfullempval] = useState<any>('')
  const initalValue = incubatorTeam.fulltime_employees_no;
  const [maxValue, setMaxValue] = useState(initalValue);
  const [totalInputValue, setTotalInputValue] = useState<number>(0);
  useEffect(() => {
    console.log(totalInputValue, "inputval"); // don't remove the console
    if (totalInputValue == incubatorTeam.fulltime_employees_no) {
      setSumOfEmpVal(true)
      // setMaxValue(0)
    } else {
      setSumOfEmpVal(false)
    }
    // console.log(sumOfEmpVal,"inputval");
  }, [totalInputValue, initalValue]);
  // handle input change for team rows- no of employee
  const handleIncrementDecrementChangeInput = (
    name: string,
    value: number,
    index: number
  ) => {
    const teamDetail = teams[index];
    if (typeof teamDetail.no_employee === "string") {
      value = Number(teamDetail.no_employee);
    }

    setfullempval(value);
    const sum = teams.reduce((acc, team, i) => {
      if (i !== index) {
        return acc + team.no_employee;
      }
      return acc;
    }, 0);
    // console.log(sum,"inputval")
    const maxValuee = incubatorTeam.fulltime_employees_no - sum;

    setMaxValue(maxValuee);
    teamDetail.no_employee = value;
    teams[index] = teamDetail;
    const updatedTeams = JSON.stringify(teams);
    setTeams(JSON.parse(updatedTeams));

    // Calculate total sum
    let add = 0;
    teams.forEach((team) => {
      add += team.no_employee;
    });
    setTotalInputValue(add);
    // console.log(totalInputValue,"inputval")
  };

  // on increament decrement control change (not for teams[])
  const handleIncrementDecrementChange = (name: string, value: number) => {
    if (!isNaN(value)) {
      let incubator = JSON.parse(JSON.stringify(incubatorTeam));
      // incubator[name] = value ? value : "";
      incubatorTeam.fulltime_employees_no = value;
      setIncubatorTeam((prevState: IncubatorTeamModel) => {
        return {
          ...prevState,
          [name]: value ? value : "",
        };
      });
      onStepSave(
        StepperEnum.IncubatorTeam,
        incubatorTeam,
        requestType.propertyChange
      );
    }
  };

  // handle input change for team rows - team name
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    // const teamDetail = new TeamDetails(0, e.target.value, 1);
    teams[index].team_name = e.target.value.replace(/[^a-zA-Z0-9-.&() |`~]/ig, '');
    const updatedTeams = JSON.stringify(teams);
    setTeams(JSON.parse(updatedTeams));
    incubatorTeam.team_data = teams;
    onStepSave(
      StepperEnum.IncubatorTeam,
      incubatorTeam,
      requestType.propertyChange
    );
  };

  const handleBlur = () => {
    onStepSave(
      StepperEnum.IncubatorTeam,
      incubatorTeam,
      requestType.propertyChange
    );
  };


  const [show, setShow] = useState(0)
  // add and removes the teams
  const addRemoveTeam = (rowIndex?: number) => {

    var fullvalue = incubatorTeam.fulltime_employees_no;
    let sum = 0;
    let key = "no_employee";
    incubatorTeam.team_data.map((obj: any) => {

      if (obj.hasOwnProperty(key)) {

        let val = (sum += parseInt(obj[key]))
        setShow(val)
        // console.log(val,fullempval,fullvalue,"")
        if ((rowIndex || rowIndex === 0)) {
          const teamRows = teams.filter((_, index: number) => index !== rowIndex);
          setTeams(teamRows);
          incubatorTeam.team_data = teamRows;
          if (val != fullvalue) {
            setSumOfEmpVal(false)
          }
        }
        else if ((fullempval == fullvalue) || (val == fullvalue)) {
          const teamRows = teams.filter((_, index: number) => index !== rowIndex);
          setTeams(teamRows);
          incubatorTeam.team_data = teamRows;
        } else if (val < fullvalue) {
          const team = new TeamDetails(teams.length, "", 0);
          setTeams([...teams, team]);
          incubatorTeam.team_data = [...teams, team];
        } else {
          return;
        }
      }

    })
    const total = teams.reduce((sum, team) => sum + team.no_employee, 0);

  };

  return (
    <div
      id="incubator-step-2"
      role="tabpanel"
      className="bs-stepper-pane content"
      aria-labelledby="stepper1trigger2"
    >
      {/* Incubator Stepper Form 2 */}
      <fieldset disabled={disableForm}>
        <div className="form-wrapper stepper-form stepper-form-2">
          <div className="form-content">
            <div className="form-group required row">
              <label className="col-sm-4 col-form-label">
                Name & Background of the CEO
              </label>
              <div className="col-sm-8">
                <div className="form-row">
                  <div className="col">
                    <input
                      type="text"
                      name="ceo_firstname"
                      className={`${props.errors.length &&
                        validateRequiredText(incubatorTeam.ceo_firstname, 255)
                        ? `form-control error`
                        : "form-control"
                        }`}
                      placeholder="First Name"
                      value={incubatorTeam.ceo_firstname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required={true}
                      maxLength={255}
                    />
                    <div
                      className={`${props.errors.length &&
                        validateRequiredText(incubatorTeam.ceo_firstname, 255)
                        ? `col-12 pl-0 error`
                        : "invalid-feedback "
                        }`}
                    >
                      {validateRequiredText(incubatorTeam.ceo_firstname, 255)}
                    </div>
                  </div>

                  <div className="col">
                    <input
                      type="text"
                      name="ceo_lastname"
                      className={`${props.errors.length &&
                        validateRequiredText(incubatorTeam.ceo_lastname, 255)
                        ? `form-control error`
                        : "form-control"
                        }`}
                      placeholder="Last Name"
                      value={incubatorTeam.ceo_lastname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required={true}
                      maxLength={255}
                    />
                    <div
                      className={`${props.errors.length &&
                        validateRequiredText(incubatorTeam.ceo_lastname, 255)
                        ? `col-12 pl-0 error`
                        : "invalid-feedback "
                        }`}
                    >
                      {validateRequiredText(incubatorTeam.ceo_lastname, 255)}
                    </div>
                  </div>
                  <div
                    className={`${props.errors.indexOf("central_incubator_far_id") > -1
                      ? `error`
                      : "invalid-feedback "
                      }`}
                  >
                    This is mandatory field
                  </div>
                </div>

                <div>
                  <textarea
                    className={`${props.errors.length &&
                      validateRequiredText(incubatorTeam.ceo_description, 2000)
                      ? `form-control error`
                      : "form-control"
                      }`}
                    rows={3}
                    placeholder="Describe background"
                    name="ceo_description"
                    value={incubatorTeam.ceo_description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required={true}
                    maxLength={2000}
                  ></textarea>
                  <small>Max. 2000 characters</small>
                  <div
                    className={`${props.errors.length &&
                      validateRequiredText(incubatorTeam.ceo_description, 2000)
                      ? `col-12 pl-0 error`
                      : "invalid-feedback "
                      }`}
                  >
                    {validateRequiredText(incubatorTeam.ceo_description, 2000)}
                  </div>
                </div>

                <small>
                  A self declaration form signed by authorised signatory is required to
                  attest. The sample format of document is
                  available in the "Other Details" section.
                </small>
              </div>
            </div>

            <hr />

            <div className="form-group required row">
              <label className="col-sm-4 col-form-label">
                No. of full-time employees
              </label>
              {console.log(incubatorTeam.fulltime_employees_no)}
              <div className="col-sm-8">
                <IncrementDecrement
                  name="fulltime_employees_no"
                  value={incubatorTeam.fulltime_employees_no > 0 ? incubatorTeam.fulltime_employees_no : 0}
                  onChange={(name: string, value: number) =>
                    handleIncrementDecrementChange(
                      "fulltime_employees_no",
                      value
                    )
                  }
                  minValue={0}
                />

                <div
                  className={`${props.errors.length &&
                    props.errors.indexOf("fulltime_employees_no") > -1 &&
                    incubatorTeam.fulltime_employees_no < 1
                    ? `col-12 pl-0 error`
                    : "invalid-feedback "
                    }`}
                >
                  This is mandatory field
                </div>
              </div>
            </div>

            <div className="form-group required row">
              <label className="col-sm-4 col-form-label">
                List of all teams along with the number of full-time employees in
                each team
              </label>
              <div className="col-sm-8">
                <div className="form-row">
                  <div className="col">
                    <span className="col-label">Team Name</span>
                  </div>
                  <div className="col">
                    <span className="col-label">No. of full time employees</span>
                  </div>
                </div>
                {teams.map((team: TeamDetails, index: number) => (
                  <div key={`team_name_${index}`} className="form-row">
                    <div className="col-5">
                      <input type="hidden" value="team.team_id"></input>
                      <input
                        type="text"
                        name={`team_name_${index}`}
                        className={`${props.teamError.length &&
                          props.teamError.indexOf(index) > -1
                          ? `form-control error`
                          : "form-control"
                          }`}
                        placeholder="Enter"
                        value={team.team_name}
                        onChange={(e) => handleInputChange(e, index)}
                        disabled={incubatorTeam.fulltime_employees_no == 0 || incubatorTeam.fulltime_employees_no == null}
                        // onBlur={handleBlur}
                        required={true}
                        maxLength={255}
                      />
                      <div
                        className={`${props.teamError.length &&
                          props.teamError.indexOf(index) > -1
                          ? `error`
                          : "invalid-feedback"
                          }`}
                      >
                        This field is mandatory
                      </div>
                    </div>
                    <div className="col-5 text-center">
                      <IncrementDecrement
                        name={`no_employee${index}`}
                        value={incubatorTeam.fulltime_employees_no == 0 ||
                          incubatorTeam.fulltime_employees_no < totalInputValue
                          ? 0 : team.no_employee}
                        onChange={(name: string, value: number) =>
                          handleIncrementDecrementChangeInput(name, value, index)
                        }
                        disabled={incubatorTeam.fulltime_employees_no == 0 ||
                          incubatorTeam.fulltime_employees_no == null ||
                          (team.team_name != undefined && team.team_name.length == 0)}
                        minValue={0}
                        maxValue={
                          maxValue
                        }
                      />
                    </div>
                    <div className="col-2">
                      <div className="number">
                        {teams.length > 1 && (
                          <img
                            src={DeleteRow}
                            className="img-fluid margin-top-15"
                            alt="Delete Logo"
                            onClick={() => addRemoveTeam(index)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                {sumOfEmpVal == true ?
                  <>

                  </> :
                  <>
                    <div className="form-row">
                      <div className="col">
                        <span
                          className="link-orange-text"
                          onClick={() => addRemoveTeam()}
                        >
                          <u>
                            <i className="fa fa-plus"></i> Add Team
                          </u>
                        </span>
                      </div>
                    </div>
                  </>
                }



              </div>
            </div>
          </div>

          <div className="stepper-footer-section">
            <div>
              <div
                className="btn-toolbar justify-content-between"
                role="toolbar"
                aria-label="Toolbar with button groups"
              >
                <button className="btn btn-sisfs btn-transparent-primary">
                  Cancel
                </button>
                <span className="stepper-page-count">Step 2 / 5</span>

                <div className="btn-group">
                  <button
                    className="btn btn-sisfs btn-transparent-primary"
                    onClick={() => stepper && stepper.previous()}
                  >
                    Previous
                  </button>
                  <button
                    className="btn btn-sisfs btn-yellow-primary"
                    onClick={onSave}
                  >
                    Save And Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
      {/* Incubator Stepper Form 4 */}
    </div>
  );
}

export default TeamDetailsComponent;
