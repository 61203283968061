import { FileUploadTypeModel } from "../FileUploadModel";

export class AuthorizedRepresentativeModel {
    constructor(
        public representative_firstname: string,
        public representative_lastname: string,
        public representative_designation: string,
        public representative_mobile_no: string,
        public representative_email: string,
        public authorisation_letter_upload: Array<FileUploadTypeModel>,
    ){
        this.representative_firstname = representative_firstname;
        this.representative_lastname = representative_lastname;
        this.representative_designation = representative_designation;
        this.representative_mobile_no = representative_mobile_no;
        this.representative_email = representative_email;     
        this.authorisation_letter_upload = authorisation_letter_upload;
    }
}