export class IncubatorTeamModel {
  constructor(
    public ceo_firstname: string,
    public ceo_lastname: string,
    public ceo_description: string,
    public fulltime_employees_no: number,
    public team_data: Array<TeamDetails>
  ) {
    this.ceo_firstname = ceo_firstname;
    this.ceo_lastname = ceo_lastname;
    this.ceo_description = ceo_description;
    this.fulltime_employees_no = fulltime_employees_no;
    this.team_data = team_data;
  }
}

export class TeamDetails {
  constructor(
    public team_id: number,
    public team_name: string,
    public no_employee: number
  ) {
    this.team_id = team_id;
    this.team_name = team_name;
    this.no_employee = no_employee;
  }
}
