import '../incubator/incubator.css';
import Plus from '../../../images/Plus.svg';
import Minus from '../../../images/Minus.svg';
import { GeneralDetailModel } from '../../../models/incubator/GeneralDetailModel';
import { FileUploadModel } from '../../../models/FileUploadModel';
import React, { useEffect, useState } from 'react';
import { get, post } from '../../../effects/api/api.common';
import {
  ApplicationGeneralDetails,
  OthersModel,
  ReapplyApplicationGeneralDetails,
} from './ApplicationGeneralDetails';
import {
  UploadTypeEnum,
  UploadTypeEnum2,
  requestType,
} from '../../../utils/Enums';
import FileUploader from '@amodv/react-file-uploader';
import pdf from '../../../images/PDF.svg';
import xls from '../../../images/xls.svg';
import doc from '../../../images/doc.svg';
import xlsx from '../../../images/xls.svg';
import Allround from '../../../images/Allround.svg';
import {
  getApplicationDetails2_3_NODE,
  NODE_URL,
  secappdetailsform3_NODE,
  secappdetails_RE_FORM2,
  getApplicationDetails_3_NODE
} from '../../../constants/api';
import ConfirmationDialog from '../../common/controls/ConfirmationDialog';

// interface IGeneralDetailsProps {
//   applicationGeneralDetailModel: ReapplyApplicationGeneralDetails;
// }
interface IIKeyValueIndexer {
  [key: string]: boolean;
}

function ApplicationDetailsReapplyComponent(props: any) {
  const { applicationGeneralDetailModel, onStepSave } = props;

  const formatDateWithMonth = (date: Date) => {
    var month = new Array();
    month[0] = 'Jan';
    month[1] = 'Feb';
    month[2] = 'Mar';
    month[3] = 'April';
    month[4] = 'May';
    month[5] = 'Jun';
    month[6] = 'Jul';
    month[7] = 'Aug';
    month[8] = 'Sep';
    month[9] = 'Oct';
    month[10] = 'Nov';
    month[11] = 'Dec';

    var d = new Date(date),
      monthn = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (monthn.length < 2) monthn = '0' + monthn;
    if (day.length < 2) day = '0' + day;

    return [day, month[d.getMonth()], year].join(' ');
  };

  const [disabled_part1, setdisabled_part1] = useState(false);
  const [disabled_part2, setdisabled_part2] = useState(false);

  const downloadDocument = (file: any) => {
    fetch(file).then(response => {
      response.blob().then(blob => {
        const file_name = file.split('/').pop();
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = file_name;
        a.click();
      });
    });
  };

  const downloadDocumentFirst = (file: any) => {
    fetch(file)
      .then(response => response.blob())
      .then(blob => {
        const blobURL = window.URL.createObjectURL(new Blob([blob]));
        const file_name = file.split('/').pop();
        const a = document.createElement('a');
        a.href = blobURL;
        a.setAttribute('download', file_name);
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  const [activeIndexApp, setActiveIndexApp] = useState('');
  const changeActiveApp = (id: string) => {
    //setLoader(true);
    setActiveIndexApp(id);
    setTimeout(() => {
      setLoader(false);
    }, 4000);
  };

  const changeActiveBackApp = () => {
    setActiveIndexApp('');
  };

  const [loader, setLoader] = useState(true);
  const [openUploadBlock, setOpenUploadBlock] = useState<IIKeyValueIndexer>();
  const [onlypdf, setonlypdf] = useState<any>('');
  const [onlypdf1, setonlypdf1] = useState<any>('');
  const [pdfOnly, setPdfOnly] = useState<IIKeyValueIndexer>();
  const [isDisabled, setIsDisabled] = useState(false);
  const [ucfilesArray, setucfilesArray] = useState<any>('');
  const [AuditedfilesArray, setAuditedfilesArray] = useState<any>('');
  const [dialogData, setDialogData] = useState({
    header: '',
    text: '',
  });
  const [showIncubatorSuccessModal, setShowIncubatorSuccessModal] =
    useState(false);
  const [failDialogData, setFailDialogData] = useState({
    header: '',
    text: '',
  });
  const [confirmationDialogData, setConfirmationDialogData] = useState({
    header: '',
    text: '',
  });
  const [showIncubatorFailureModal, setShowIncubatorFailureModal] =
    useState(false);
  function constructURLQuery(url: any, code: any, title: any) {
    return `${NODE_URL}${title}`;
  }
  const LinkComponent2 = (props: any) => (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          padding: 15,
        }}>
        <img
          style={{ width: 40, height: 30 }}
          src="https://www.macworld.com/wp-content/uploads/2023/01/pdf-icon.jpg?quality=50&strip=all"></img>
        <a
          style={{ color: '#000', textAlign: 'center', fontSize: 10 }}
          href={props.link}
          title={props.title}
          target="_blank">
          {props.title.slice(31)}
        </a>
        <span
          className="close"
          // onClick={() => { props.onDelete(props.index) }}
          onClick={() => removeItem2(props.title, props.typ)}>
          <i className="fa fa-close"></i>
        </span>
      </div>

      <div
        style={{
          borderBottom: '1px solid #ccc',
          height: 1,
          width: 'auto !important',
        }}></div>
    </div>
  );
  const ListComponent2 = (props: any) => (
    <div
      style={{
        overflowY: 'hidden',
        border: 'dashed 2px #ccc',
        borderRadius: 5,
        width: 'auto !important',
      }}>
      <div>
        {props.content}
        {props.links.map((title: any, url: any, code: any) => (
          <LinkComponent2
            link={constructURLQuery(url, code, title)}
            title={title}
          />
        ))}
      </div>
    </div>
  );
  const ListComponent3 = (props: any) => (
    <div
      style={{
        overflowY: 'hidden',
        border: 'dashed 2px #ccc',
        borderRadius: 5,
        width: 'auto !important',
      }}>
      <div>
        {props.content}
        {props.links.map((title: any, url: any, code: any) => (
          <LinkComponent3
            link={constructURLQuery(url, code, title)}
            title={title}
          />
        ))}
      </div>
    </div>
  );
  const LinkComponent3 = (props: any) => (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          padding: 15,
        }}>
        <img
          style={{ width: 40, height: 30 }}
          src="https://www.macworld.com/wp-content/uploads/2023/01/pdf-icon.jpg?quality=50&strip=all"></img>
        <a
          style={{ color: '#000', textAlign: 'center', fontSize: 10 }}
          href={props.link}
          title={props.title}
          target="_blank">
          {props.title.slice(31)}
        </a>
        <span
          className="close"
          // onClick={() => { props.onDelete(props.index) }}
          onClick={() => removeItem3(props.title, props.typ)}>
          <i className="fa fa-close"></i>
        </span>
      </div>
      <div
        style={{
          borderBottom: '1px solid #ccc',
          height: 1,
          width: 'auto !important',
        }}></div>
    </div>
  );
  const removeItem2 = (title: any, type: any) => {
    const newItems2 = [
      ...postapitwo.Utilization_Certificate_Upload_get.split(',').map(
        (currentName2: any, index: any, arr: any) => {
          return `${currentName2.slice(1)} `;
        },
      ),
    ];
    // console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@",newItems2);
    const index2 = newItems2.indexOf(title);
    // console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@",index2);
    newItems2.splice(index2, 1).join('\n');
    postapitwo['Utilization_Certificate_Upload_get'] = newItems2.toString();
    postapitwo['Utilization_Certificate_Upload_slice'] = newItems2.toString();
    // console.log("%%%%%%%%%%%%%%%%%%%%%", newItems2.length)
    if (newItems2.length == 0) {
      // console.log("Into IF")
      setpostapitwo(() => ({
        ...postapitwo,
        ['Utilization_Certificate_Upload_slice']: 0,
      }));
    } else {
      // console.log("Into ELSE")
      setpostapitwo(() => ({
        ...postapitwo,
        ['Utilization_Certificate_Upload_slice']: newItems2.toString(),
      }));
    }

  };
  const removeItem3 = (title: any, type: any) => {
    // console.log('****************!!!!!!!!!!!!!!!!!!!!!!!!!!!');
    const newItems3 = [
      ...postapitwo.Audited_statement_expenditure_Upload_get.split(',').map(
        (currentName3: any, index: any, arr: any) => {
          return `${currentName3.slice(1)} `;
        },
      ),
    ];
    // console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@",newItems3);
    const index3 = newItems3.indexOf(title);
    // console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@",index3);
    newItems3.splice(index3, 1).join('\n');
    postapitwo['Audited_statement_expenditure_Upload_get'] =
      newItems3.toString();
    postapitwo['Audited_statement_expenditure_Upload_slice'] = newItems3.toString();
    if (newItems3.length == 0) {
      // console.log("Into IF")
      setpostapitwo(() => ({
        ...postapitwo,
        ['Audited_statement_expenditure_Upload_slice']: 0,
      }));
    } else {
      setpostapitwo(() => ({
        ...postapitwo,
        ['Audited_statement_expenditure_Upload_slice']: newItems3.toString(),
      }))
    }
    
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    // if(applicationGeneralDetailModel.incubator_application_no ! == ""){
    //   console.log("indd")
    getAPIPartOneAndTwo();
    getAPIPartOneAndThree()
    // }

    // console.log(applicationGeneralDetailModel);
    // console.log(applicationGeneralDetailModel);
    // setGeneralDetail(applicationGeneralDetailModel);
    {
      // console.log('File URL', applicationGeneralDetailModel);
    }
  }, [applicationGeneralDetailModel]);

  const [popUpName, setPopUpName] = useState('');
  const setMeetingModal = (fieldName: string) => {
    setPopUpName(fieldName);
  };

  const getFileDetails = (file: any, key: string) => {
    let file_name = '';
    file_name =
      file != undefined && file != null && file.length > 1
        ? file.substr(file.lastIndexOf('/') + 1, file.length)
        : '';

    const file_type =
      file != undefined && file != null && file.length > 1
        ? file.substr(file.lastIndexOf('.') + 1, file.length)
        : '';

    if (key == 'name') return file_name;
    if (key == 'file') return file != undefined ? file.toString() : '';
    if (key == 'type') return file != undefined ? file_type.toString() : '';
  };

  const handleChange = (
    evt: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >,
  ) => {
    const name = evt.target.name;
    let value = evt.target.value

    let decimalCount = value.split(".").length - 1;
    
    if (decimalCount > 1) {
      value = value.split(".").slice(0, 2).join(".");
    }

      if(name=="Details_Interest_earned" || name=="Debt_Interest" || name=="Debentures_Interest_Earned"){
        setpostapitwo(() => ({
          ...postapitwo,
          [name]: value.replace(/[^0-9.]/gi, '')
          .toString()
          .split('.')
          .map((el, i) => (i ? el.split('').slice(0, 2).join('') : el))
          .join('.'),
        }));
      }else{
        setpostapitwo(() => ({
          ...postapitwo,
          [name]: value.replace(/[^0-9]/gi, ''),
        }));
      }
    

    // console.log(postapitwo);

    // let incubator = JSON.parse(JSON.stringify(postapitwo));

    // incubator[name] = value;

    // setpostapitwo(incubator);
    // setpostapitwo({ ...postapitwo, [name]: value });
    // console.log(incubator);

    // onStepSave(
    //     StepperEnum.postapitwo, postapitwo, requestType.save
    //   );
  };

  const [postapitwo1, setpostapitwo1] = useState<any>({
    No_Startup_Applications_Received: '',
    open_applications: '',
    No_Startup_Applications_Reviewed: '',
    No_Startup_Applications_Selected: '',
    No_Startup_Applications_Allotted: '',
    No_Startup_whom_Agreements: '',
    No_Startup_Applications_Cancelled: '',
    No_Startup_Applications_Closed: '',
    No_industries_represented: '',
    No_States_represented: '',
    Number_startups_PoC_stage: '',
    Number_startups_Prototype: '',
    Number_startups_Product_Development: '',
    Number_startups_Field_Trial: '',
    Number_startups_Market: '',
    Number_startups_Other_Stage: '',
    Cumulative_External_Funding: '',
    No_People_Employed_Startups: '',
    Turnover_Startups_financial: '',
    GST_Filed_Startups: '',
    No_Products_Services_Startups: '',
    No_Registration_Requests_Filed: '',
    Grants: '',
    Convertible_Debentures: '',
    Debt: '',
    disbursed_Debt: '',
    Debt_Linked_Instruments: '',
    disbursed_Debt_Linked_Instruments: '',
    Funds_disbursed_Grants: '',
    disbursed_Convertible_Debentures: '',
    Total_Interest_Debt_Due: '',
    Total_Interest_Debt_Bank: '',
    Principal_Amount_Returned: '',
    Cumulative_Monetary_Return: '',
  })
  const [postapitwo, setpostapitwo] = useState<any>({
    Details_Interest_earned: '',
    Grant_Disbursed: '',
    Debt_Disbursed: '',
    Debt_Interest: '',
    Debt_Other_Earned: '',
    Debentures_Disbursed: '',
    Debentures_Interest_Earned: '',
    Debentures_Other_Earned: '',
    Utilization_Certificate_Upload: '',
    Utilization_Certificate_Upload_get: '',
    Utilization_Certificate_Upload_slice: '',
    Audited_statement_expenditure_Upload: '',
    Audited_statement_expenditure_Upload_get: '',
    Audited_statement_expenditure_Upload_slice: '',
  });

  const handleChangetwo = (
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >,
  ) => {
    const inputName = event.target.name;
    let inputValue = event.target.value;

    let decimalCount = inputValue.split(".").length - 1;
     if (decimalCount > 1) {
       inputValue = inputValue.split(".").slice(0, 2).join(".");
      }

      if( inputName == "Total_Interest_Debt_Due" || inputName == "Total_Interest_Debt_Bank"){
        setpostapitwo1(() => ({
          ...postapitwo1,
          [inputName]: inputValue.replace(/[^0-9.]/gi, '')
          .toString()
          .split('.')
          .map((el: any, i: any) => (i ? el.split('').slice(0, 2).join('') : el))
          .join('.'),
        }));
      }else{
        setpostapitwo1(() => ({
          ...postapitwo1,
          [inputName]: inputValue.replace(/[^0-9]/gi, ''),
        }));
      }
    
  };

  const [average, setAverage] = useState(0);
    
      useEffect(() => {
        const newAverage = ((parseInt(postapitwo1.Total_Interest_Debt_Due)) / (parseInt(postapitwo1.disbursed_Convertible_Debentures) + parseInt(postapitwo1.disbursed_Debt) + parseInt(postapitwo1.disbursed_Debt_Linked_Instruments)))  ;
        setAverage(newAverage);

      }, [postapitwo1.Total_Interest_Debt_Due,postapitwo1.disbursed_Convertible_Debentures,postapitwo1.disbursed_Debt,postapitwo1.disbursed_Debt_Linked_Instruments]);

  const dismissModal = () => {
    setShowIncubatorSuccessModal(false);
    setShowIncubatorFailureModal(false);
    // setShowEditFailureModal(false);
    // setShowEditSuccessModal(false);
    window.location.reload();
  };
  const getAPIPartOneAndTwo = () => {
    // console.log(applicationGeneralDetailModel.incubator_application_no);
    if (applicationGeneralDetailModel.incubator_application_no !== '') {
      const payload = {
        incubator_application_no:
          applicationGeneralDetailModel.incubator_application_no == ''
            ? 0
            : applicationGeneralDetailModel.incubator_application_no,
        // "incubator_application_no": "RESFI10"
      };
      // console.log(payload, 'payload');
      post(getApplicationDetails2_3_NODE, payload).then((response: any) => {
        // console.log(response.data.meetingId[0], 'sri@@@');
        if (!response || !response.data) return;
        if (response.data.meetingId.length > 0) {
          setdisabled_part1(true);
          setdisabled_part2(true);
          // console.log(response.data.meetingId.length,"Meetinglength")
          
          if (
           ( response.data.meetingId[0].Utilization_Certificate_Upload !==
            null) &&        
            response.data.meetingId[0].Audited_statement_expenditure_Upload !==
            null
          ) {
            setpostapitwo(response.data.meetingId[0]);
            setucfilesArray(
              response.data.meetingId[0].Utilization_Certificate_Upload,
            );
            setAuditedfilesArray(
              response.data.meetingId[0].Audited_statement_expenditure_Upload,
            );
          }else{
            // console.log("length null")

            // setpostapitwo(response.data.meetingId[0]);
           
          //  const data = response.data.meetingId[0]
          //  delete response.data.meetingId[0]['Utilization_Certificate_Upload'];
          //  delete response.data.meetingId[0]['Audited_statement_expenditure_Upload'];
          //  console.log(response.data.meetingId[0],"hell")
          //  setpostapitwo(response.data.meetingId[0]);
//            const newData = data.remove("Utilization_Certificate_Upload", response.data.meetingId[0].Utilization_Certificate_Upload);
//  console.log(newData)
// const newData2 = newData.remove("Audited_statement_expenditure_Upload", response.data.meetingId[0].Audited_statement_expenditure_Upload);
// setpostapitwo(newData2);
          //   for(var i = 0; i <  data.length; i++) {
          //     if(data[i].Utilization_Certificate_Upload) {
          //         data.splice(i, 1);
          //         break;
          //     }
          //     if(data[i].Audited_statement_expenditure_Upload) {
          //       data.splice(i, 1);
          //       break;
          //   }
          //    setpostapitwo(data);
          // }

          }
        }
      });
      // console.log(typeof postapitwo);
    }
  };
const getAPIPartOneAndThree = () => {
    // console.log(applicationGeneralDetailModel.incubator_application_no);
    if (applicationGeneralDetailModel.incubator_application_no !== '') {
      const payload = {
        incubator_application_no:
          applicationGeneralDetailModel.incubator_application_no == ''
            ? 0
            : applicationGeneralDetailModel.incubator_application_no,
        // "incubator_application_no": "RESFI10"
      };
      // console.log(payload, 'payload');
      post(getApplicationDetails_3_NODE, payload).then((response: any) => {
        // console.log(response.data.meetingId[0], 'sri@@@');
        if (!response || !response.data) return;
        if (response.data.meetingId.length > 0) {
          // console.log(response.data.meetingId.length,"Meetinglength")
          
          // if (
          //  ( response.data.meetingId[0].Utilization_Certificate_Upload !==
          //   null) &&        
          //   response.data.meetingId[0].Audited_statement_expenditure_Upload !==
          //   null
          // ) {
            setpostapitwo1(response.data.meetingId[0]);
            // setucfilesArray(
            //   response.data.meetingId[0].Utilization_Certificate_Upload,
            // );
            // setAuditedfilesArray(
            //   response.data.meetingId[0].Audited_statement_expenditure_Upload,
            // );
          // }
         
        }
      });
      // console.log(typeof postapitwo);
    }
  };
  // const [formerror, setformerror] = useState({})
  // const validations = () => {
  //   let err={}

  //   if(postapitwo.No_Startup_Applications_Received === ""){
  //     err.No_Startup_Applications_Received == Mandatary Field
  //   }
  //   setformerror({...err})
  //   return false;
  // }

  const onEdit = () =>{
    setdisabled_part2(false); 
    setShowIncubatorSuccessModal(false);
  }

  const SubmitPartTwo = (e: any) => {
    e.preventDefault();
    // console.log(postapitwo);
    // return;
    // let isValid = validations()
    // console.log(isValid);

    const payload = {
      application_number:
        applicationGeneralDetailModel.incubator_application_no,
      // "application_number": "RESFI10",
      No_Startup_Applications_Received:
        postapitwo1.No_Startup_Applications_Received,
      open_applications: postapitwo1.open_applications,
      No_Startup_Applications_Reviewed:
        postapitwo1.No_Startup_Applications_Reviewed,
      No_Startup_Applications_Selected:
        postapitwo1.No_Startup_Applications_Selected,
      No_Startup_Applications_Allotted:
        postapitwo1.No_Startup_Applications_Allotted,
      No_Startup_whom_Agreements: postapitwo1.No_Startup_whom_Agreements,
      No_Startup_Applications_Cancelled:
        postapitwo1.No_Startup_Applications_Cancelled,
      No_Startup_Applications_Closed: postapitwo1.No_Startup_Applications_Closed,
      No_industries_represented: postapitwo1.No_industries_represented,
      No_States_represented: postapitwo1.No_States_represented,
      Number_startups_PoC_stage: postapitwo1.Number_startups_PoC_stage,
      Number_startups_Prototype: postapitwo1.Number_startups_Prototype,
      Number_startups_Product_Development:
        postapitwo1.Number_startups_Product_Development,
      Number_startups_Field_Trial: postapitwo1.Number_startups_Field_Trial,
      Number_startups_Market: postapitwo1.Number_startups_Market,
      Number_startups_Other_Stage: postapitwo1.Number_startups_Other_Stage,
      Cumulative_External_Funding: postapitwo1.Cumulative_External_Funding,
      No_People_Employed_Startups: postapitwo1.No_People_Employed_Startups,
      Turnover_Startups_financial: postapitwo1.Turnover_Startups_financial,
      GST_Filed_Startups: postapitwo1.GST_Filed_Startups,
      No_Products_Services_Startups: postapitwo1.No_Products_Services_Startups,
      No_Registration_Requests_Filed: postapitwo1.No_Registration_Requests_Filed,
      Convertible_Debentures: postapitwo1.Convertible_Debentures,
      Debt_Linked_Instruments: postapitwo1.Debt_Linked_Instruments,
      Total_Interest_Debt_Due: postapitwo1.Total_Interest_Debt_Due,
      Total_Interest_Debt_Bank: postapitwo1.Total_Interest_Debt_Bank,
      Principal_Amount_Returned: postapitwo1.Principal_Amount_Returned,
      Cumulative_Monetary_Return: postapitwo1.Cumulative_Monetary_Return,
      Grants: postapitwo1.Grants,
      Debt: postapitwo1.Debt,
      disbursed_Debt: postapitwo1.disbursed_Debt,
      disbursed_Debt_Linked_Instruments:
        postapitwo1.disbursed_Debt_Linked_Instruments,
      Funds_disbursed_Grants: postapitwo1.Funds_disbursed_Grants,
      disbursed_Convertible_Debentures:
        postapitwo1.disbursed_Convertible_Debentures,
    };
    // console.log(payload);
    // return;
    post(secappdetailsform3_NODE, payload).then((response: any) => {
      if (response.data.result == 'success') {
        setDialogData({
          header: 'Thank You!',
          text: response.data.message,
        });
        setShowIncubatorSuccessModal(true);
        setdisabled_part2(true);
      } else {
        setFailDialogData({
          header: '',
          text: response.data.message,
        });
        setShowIncubatorFailureModal(true);
      }
      // console.log(response, '&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&');
    });
  };

  const onFileUpload = (files: Array<File>, type: any) => {
    // console.log(files, type, 'upF');
    if (type == 'Utilization_Certificate_Upload') {
      postapitwo['Utilization_Certificate_Upload'] = files;

      if (Array.isArray(postapitwo.Utilization_Certificate_Upload)) {
        // console.log('tru@@@@@@@@@@');

        setonlypdf(postapitwo.Utilization_Certificate_Upload);
      } else {
        // console.log('falsemahi@@@@@');
      }
    } else if (type == 'Audited_statement_expenditure_Upload') {
      postapitwo['Audited_statement_expenditure_Upload'] = files;

      if (Array.isArray(postapitwo.Audited_statement_expenditure_Upload)) {
        // console.log('tru@@@@@@@@@@');

        setonlypdf1(postapitwo.Audited_statement_expenditure_Upload);
      } else {
        // console.log('falsemahi@@@@@');
      }
    }
    // else if (type == 'Declaration_violation_SISFS_Upload') {
    //     postapitwo['Declaration_violation_SISFS_Upload'] = files

    // }
    else {
      // console.log('empty');
    }

    // console.log(postapitwo, 'inc');
    let indexes: Array<string> = [];
    let updatedGeneralDetail = Object.assign({}, postapitwo);

    for (let file of files) {
      const fileData = postapitwo as any;

      const fileExists = fileData[type].findIndex(
        (uf: FileUploadModel) => uf.file.name === file.name,
      );

      if (fileExists > -1) continue;

      let updatedFileData: Array<FileUploadModel> = [];

      if (file.type === 'application/pdf') {
        const formData = new FormData();
        formData.append('file', file, file.name);
        // formData.append("application_id", applicationId.toString());
        formData.append('file_type', type);
        // setLoader(true);
        // console.log(formData, 'frm');
      } else {
        indexes.push(file.name);
        setPdfOnly({ ...pdfOnly, [type]: true });
      }
    }

    if (indexes.length) {
      indexes.map(ind => {
        const position: number = files.findIndex(file => file.name === ind);
        files.splice(position, 1);
      });
    }
  };

  const onFileDelete = (file: File, type: string) => {
    // console.log(file, type, 'del');
    const {
      Utilization_Certificate_Upload,
      Audited_statement_expenditure_Upload,
      // Declaration_violation_SISFS_Upload,
    } = postapitwo;
    let remainingFiles: Array<FileUploadModel> = [];
    let fileToBeDeleted: any;

    switch (type) {
      case UploadTypeEnum2.UtilizationCertificate:
        fileToBeDeleted = Utilization_Certificate_Upload.find(
          (uploadedFile: FileUploadModel) => uploadedFile.file === file,
        );

        if (!fileToBeDeleted) return;
        remainingFiles = Utilization_Certificate_Upload.filter(
          (uf: FileUploadModel) => uf.file_id !== fileToBeDeleted.file_id,
        );
        postapitwo.Utilization_Certificate_Upload = remainingFiles;
        // onStepSave(
        //     StepperEnum.postapitwo,
        //     postapitwo,
        //     requestType.propertyChange
        // );
        break;

      case UploadTypeEnum2.AuditedStatement:
        fileToBeDeleted = Audited_statement_expenditure_Upload.find(
          (uploadedFile: FileUploadModel) => uploadedFile.file === file,
        );

        if (!fileToBeDeleted) return;
        remainingFiles = Audited_statement_expenditure_Upload.filter(
          (uf: FileUploadModel) => uf.file_id !== fileToBeDeleted.file_id,
        );
        postapitwo.Audited_statement_expenditure_Upload = remainingFiles;
        // onStepSave(
        //     StepperEnum.postapitwo,
        //     postapitwo,
        //     requestType.propertyChange
        // );
        break;
    }
  };


  const submitOne = (e: any) => {
    e.preventDefault();
    if (applicationGeneralDetailModel.incubator_application_no! == '') {
      return;
    } else if (
      postapitwo.Details_Interest_earned == null ||
      postapitwo.Details_Interest_earned == ''
    ) {
      setFailDialogData({
        header: 'Oops',
        text: 'Please Enter Amount of Interest earned on unutilized funds',
      });
      setShowIncubatorFailureModal(true);
    } else if (
      postapitwo.Grant_Disbursed == null ||
      postapitwo.Grant_Disbursed == ''
    ) {
      setFailDialogData({
        header: 'Oops',
        text: 'Please Enter Grant Disbursed',
      });
      setShowIncubatorFailureModal(true);
    } else if (
      postapitwo.Debt_Disbursed == null ||
      postapitwo.Debt_Disbursed == ''
    ) {
      setFailDialogData({
        header: 'Oops',
        text: 'Please Enter Debt Disbursed',
      });
      setShowIncubatorFailureModal(true);
    } else if (
      postapitwo.Debt_Interest == null ||
      postapitwo.Debt_Interest == ''
    ) {
      setFailDialogData({
        header: 'Oops',
        text: 'Please Enter Interest Earned on Debt',
      });
      setShowIncubatorFailureModal(true);
    } else if (
      postapitwo.Debt_Other_Earned == null ||
      postapitwo.Debt_Other_Earned == ''
    ) {
      setFailDialogData({
        header: 'Oops',
        text: 'Please Enter Other return earned from the instrument (Debt)',
      });
      setShowIncubatorFailureModal(true);
    } else if (
      postapitwo.Debentures_Disbursed == null ||
      postapitwo.Debentures_Disbursed == ''
    ) {
      setFailDialogData({
        header: 'Oops',
        text: 'Please Enter Debentures Disbursed',
      });
      setShowIncubatorFailureModal(true);
    } else if (
      postapitwo.Debentures_Other_Earned == null ||
      postapitwo.Debentures_Other_Earned == ''
    ) {
      setFailDialogData({
        header: 'Oops',
        text: 'Please Enter Interest Earned on Debenture',
      });
      setShowIncubatorFailureModal(true);
    } else if (
      postapitwo.Debentures_Interest_Earned == null ||
      postapitwo.Debentures_Interest_Earned == ''
    ) {
      setFailDialogData({
        header: 'Oops',
        text: 'Please Enter Other return earned from the instrument (Debenture)',
      });
      setShowIncubatorFailureModal(true);
    } else if (postapitwo.Utilization_Certificate_Upload! == '') {
      setFailDialogData({
        header: 'Oops',
        text: 'Please Upload Utilization Certificate (UC)',
      });
      setShowIncubatorFailureModal(true);
    } else if (postapitwo.Utilization_Certificate_Upload == '' || postapitwo.Utilization_Certificate_Upload == 0) {
      // console.log("hello")
      if (postapitwo.Utilization_Certificate_Upload_get !== "" || postapitwo.Utilization_Certificate_Upload_get !== 0) {
        // console.log("emptyy slicess")
        setFailDialogData({
          header: 'Oops',
          text: 'Please Upload Utilization Certificate (UC)',
        });
        setShowIncubatorFailureModal(true);
      } else {
        if (postapitwo.Utilization_Certificate_Upload_slice !== "" || postapitwo.Utilization_Certificate_Upload_slice !== 0) {
          setFailDialogData({
            header: 'Oops',
            text: 'Please Upload Utilization Certificate (UC)',
          });
          setShowIncubatorFailureModal(true);
        }
      }
      //  else {
      //   if (postapitwo.Utilization_Certificate_Upload !== '') {
      //     console.log('posttt');
      //     return;
      //   }
      // }
    } else if (postapitwo.Audited_statement_expenditure_Upload! == '') {
      setFailDialogData({
        header: 'Oops',
        text: 'Please Upload Audited statement of expenditure',
      });
      setShowIncubatorFailureModal(true);
    } else if (postapitwo.Audited_statement_expenditure_Upload == '' || postapitwo.Audited_statement_expenditure_Upload == 0) {
      if (postapitwo.Audited_statement_expenditure_Upload_get !== "" || postapitwo.Audited_statement_expenditure_Upload_get !== 0) {
        setFailDialogData({
          header: 'Oops',
          text: 'Please Upload Audited statement of expenditure',
        });
        setShowIncubatorFailureModal(true);
        // return;
        // } else {
        //   if (postapitwo.Audited_statement_expenditure_Upload !== '') return;
      }
      // console.log(postapitwo.Utilization_Certificate_Upload_slice, postapitwo.Audited_statement_expenditure_Upload_slice, "postAppp")
    } else {
      const formData = new FormData();
      formData.append(
        'application_number',
        applicationGeneralDetailModel.incubator_application_no,
      );
      formData.append(
        'Details_Interest_earned',
        postapitwo.Details_Interest_earned == ''
          ? null
          : postapitwo.Details_Interest_earned,
      );
      formData.append(
        'Grant_Disbursed',
        postapitwo.Grant_Disbursed == '' ? null : postapitwo.Grant_Disbursed,
      );
      formData.append(
        'Debt_Disbursed',
        postapitwo.Debt_Disbursed == '' ? null : postapitwo.Debt_Disbursed,
      );
      formData.append(
        'Debt_Interest',
        postapitwo.Debt_Interest == '' ? null : postapitwo.Debt_Interest,
      );
      formData.append(
        'Debt_Other_Earned',
        postapitwo.Debt_Other_Earned == ''
          ? null
          : postapitwo.Debt_Other_Earned,
      );
      formData.append(
        'Debentures_Disbursed',
        postapitwo.Debentures_Disbursed == ''
          ? null
          : postapitwo.Debentures_Disbursed,
      );
      formData.append(
        'Debentures_Other_Earned',
        postapitwo.Debentures_Other_Earned == ''
          ? null
          : postapitwo.Debentures_Other_Earned,
      );
      formData.append(
        'Debentures_Interest_Earned',
        postapitwo.Debentures_Interest_Earned == ''
          ? null
          : postapitwo.Debentures_Interest_Earned,
      );

      // console.log(typeof postapitwo);
      //Utilization Certificate  File Uplaod Code Starts
      //console.log(ucfilesArray);
      const Utilization_Certificate_Upload_Filetype =
        typeof postapitwo.Utilization_Certificate_Upload;
      const ucfilessplice = postapitwo.Utilization_Certificate_Upload_get;
      // console.log(postapitwo.Utilization_Certificate_Upload, "ucfiles")
      if (
        postapitwo.Utilization_Certificate_Upload.length > 0 &&
        Utilization_Certificate_Upload_Filetype != 'string'
      ) {
        for (
          var i = 0;
          i < postapitwo.Utilization_Certificate_Upload.length;
          i++
        ) {
          formData.append(
            'Utilization_Certificate_Upload',
            postapitwo.Utilization_Certificate_Upload[i],
          );
        }
        if (ucfilesArray.length > 0) {
          formData.append('Utilization_Certificate_Upload_get', ucfilesArray);
          formData.append(
            'Utilization_Certificate_Upload_slice',
            ucfilessplice,
          );
        } else {
          formData.append('Utilization_Certificate_Upload_get', ucfilesArray);
          formData.append(
            'Utilization_Certificate_Upload_slice',
            ucfilessplice,
          );
        }
      } else {
        formData.append('Utilization_Certificate_Upload', '0');
        formData.append('Utilization_Certificate_Upload_get', ucfilesArray);
        formData.append('Utilization_Certificate_Upload_slice', ucfilessplice);
      }
      //Utilization Certificate  File Uplaod Code Ends
      //**************************************************************************************************
      const Audited_statement_expenditure_Upload_Filetype =
        typeof postapitwo.Audited_statement_expenditure_Upload;
      const auditfilessplice =
        postapitwo.Audited_statement_expenditure_Upload_get;

      if (
        postapitwo.Audited_statement_expenditure_Upload.length > 0 &&
        Audited_statement_expenditure_Upload_Filetype != 'string'
      ) {
        for (
          var j = 0;
          j < postapitwo.Audited_statement_expenditure_Upload.length;
          j++
        ) {
          formData.append(
            'Audited_statement_expenditure_Upload',
            postapitwo.Audited_statement_expenditure_Upload[j],
          );
        }
        if (AuditedfilesArray.length > 0) {
          formData.append(
            'Audited_statement_expenditure_Upload_get',
            AuditedfilesArray,
          );
          formData.append(
            'Audited_statement_expenditure_Upload_slice',
            auditfilessplice,
          );
        } else {
          formData.append(
            'Audited_statement_expenditure_Upload_get',
            AuditedfilesArray,
          );
          formData.append(
            'Audited_statement_expenditure_Upload_slice',
            auditfilessplice,
          );
        }
      } else {
        formData.append('Audited_statement_expenditure_Upload', '0');
        formData.append(
          'Audited_statement_expenditure_Upload_get',
          AuditedfilesArray,
        );
        formData.append(
          'Audited_statement_expenditure_Upload_slice',
          auditfilessplice,
        );
      }
      // console.log(auditfilessplice.length)
      // console.log(postapitwo.Utilization_Certificate_Upload, "upload")
      // console.log(postapitwo.Utilization_Certificate_Upload_slice, "slic")

      // console.log(typeof (postapitwo.Utilization_Certificate_Upload), "@@@@@@@@@@", postapitwo.Utilization_Certificate_Upload)
      // console.log(typeof (postapitwo.Utilization_Certificate_Upload_get), "!!!!!!!!!!!", postapitwo.Utilization_Certificate_Upload_get)
      // console.log(typeof (postapitwo.Utilization_Certificate_Upload_slice), "#############", postapitwo.Utilization_Certificate_Upload_slice);

    const utilization_fileType = typeof (postapitwo.Utilization_Certificate_Upload);
    const utilization_splice = postapitwo.Utilization_Certificate_Upload_slice
    // console.log(utilization_fileType);

    if(utilization_fileType == "string" && utilization_splice == '0'){
      setFailDialogData({
        header: 'Oops',
        text: 'Please Upload Utilization Certificate (UC)',
      });
      setShowIncubatorFailureModal(true);
      return;
    }
    const audited_fileType = typeof (postapitwo.Audited_statement_expenditure_Upload);
    const audited_splice = postapitwo.Audited_statement_expenditure_Upload_slice

    // console.log(audited_fileType);
    // console.log(audited_splice);

    if(audited_fileType == "string" && audited_splice == '0'){
      setFailDialogData({
        header: 'Oops',
        text: 'Please Upload Audited statement of expenditure',
      });
      setShowIncubatorFailureModal(true);
      return;
    }


      // const Utilization_Certificate_Upload_Filetype1 = typeof (postapitwo.Utilization_Certificate_Upload);

      // if (Utilization_Certificate_Upload_Filetype1 == "string" && postapitwo.Utilization_Certificate_Upload_slice.length == 0) {
      //   console.log("@@@@@@@@@@@ Coming Into Validation Check Pass @@@@@@@@@@@@@")
      // } else {
      //   console.log("############ Coming Into Validation Check Fail ##############")
      // }

      setdisabled_part1(true);

      // return;
      post(secappdetails_RE_FORM2, formData).then((response: any) => {
        // console.log(response.data, 'LATHA');
        if (response.data.result == 'success') {
          setDialogData({
            header: 'Thank You!',
            text: response.data.message,
          });
          setShowIncubatorSuccessModal(true);
          // setdisabled_part1(false);
        } else {
          setFailDialogData({
            header: 'Oops',
            text: response.data.message,
          });
          setShowIncubatorFailureModal(true);
        }
        if (!response || !response.data) return;
        // console.log(response.data.meetingId.length, 'lengtth');

        if (response.data.meetingId.length > 0) {
          setpostapitwo(response.data.meetingId[0]);
        }
      });
    }

    //**************************************************************************************************
  };

  return (
    <div className="container-fluid">
      <div className="row">
        {/* <div className="col-md-12">
          <div className="btn-group">
            <button className="btn btn-sisfs btn-transparent-primary">
              Download PDF
            </button>
          </div>
        </div> */}
      </div>
      <div className="row">
        <div
          id="q-1"
          className={
            activeIndexApp == '1'
              ? 'tranche-heading col-sm-11 left-align'
              : 'reports-heading col-sm-11 left-align'
          }>
          General Incubator Progress
        </div>

        <div className="col-sm-1">
          {activeIndexApp == '1' ? (
            <div
              onClick={changeActiveBackApp}
              data-toggle="collapse"
              data-target="#general-expand"
              role="button"
              aria-expanded="false"
              aria-controls="general-expand"
              className="hide-icon pull-right hide-icon-details"
              id="show-1">
              <img src={Minus} className="img-fluid" alt="SISFS Logo" />
            </div>
          ) : (
            <div
              onClick={() => changeActiveApp('1')}
              id="hide-1"
              data-toggle="collapse"
              data-target="#general-expand"
              role="button"
              aria-expanded="false"
              aria-controls="general-expand"
              className="expand-icon pull-right expand-icon-details">
              <img src={Plus} className="img-fluid" alt="SISFS Logo" />
            </div>
          )}
        </div>
      </div>
      {activeIndexApp == '1' ? (
        <div className="collapse " id="general-expand">
          <div className="row mt-05">
            <div className="col-md-6 left-align">
              <span className="incubator-label">Incubator Registered Name</span>
              <br />
              <span className="incubator-label-value-wrap">
                {applicationGeneralDetailModel.registered_name == null
                  ? '-'
                  : applicationGeneralDetailModel.registered_name}
              </span>
            </div>
            <div className="col-md-6 left-align">
              <span className="incubator-label">Incubator Name/Brand Name</span>
              <br />
              <span className="incubator-label-value-wrap">
                {applicationGeneralDetailModel.incubator_name == null
                  ? '-'
                  : applicationGeneralDetailModel.incubator_name}
              </span>
            </div>
          </div>

          <div className="row mt-05">
            <div className="col-md-6 left-align">
              <span className="incubator-label">Industry</span>
              <br />
              {/* <ul className="ul-ml">
            {applicationGeneralDetailModel.industryData ==undefined || applicationGeneralDetailModel.industryData.length == 0 ? (
              <span className="incubator-label-value-wrap">-</span>
            ) : (
              applicationGeneralDetailModel.industryData.map((industry:any,index:any) => (
               index <=4 ? 
                
                <li className="incubator-label-value-wrap ul-b">
                  {industry.industry_name}
                </li>
                : ""
                
                
               
              ))
            )}
            </ul>
            {applicationGeneralDetailModel.industryData.length > 5 ?
             <a
             className="incubator-details-link"
             data-toggle="modal"
             data-target="#showMore"
             onClick={() => setMeetingModal('Industry')}
           >
             More...
           </a>
           :""} */}
            </div>
            <div className="col-md-6 left-align">
              <span className="incubator-label">Sector</span>
              <br />
              {/* <ul className="ul-ml">
            {applicationGeneralDetailModel.sectorData ==undefined || applicationGeneralDetailModel.sectorData.length == 0 ? (
              <span className="incubator-label-value-wrap">-</span>
            ) : (
              applicationGeneralDetailModel.sectorData.map((sector:any,index:any) => (
                index <=4 ? 
                <li className="incubator-label-value-wrap ul-b">
                  {sector.industry_name}
                </li>
                :""
              ))
            )}
            </ul>
            {applicationGeneralDetailModel.sectorData.length > 5 ?
             <a
             className="incubator-details-link"
             data-toggle="modal"
             data-target="#showMore"
             onClick={() => setMeetingModal('Sector')}
           >
             More...
           </a>
           :""} */}
            </div>
          </div>

          <div className="eac-incubator-hr">
            <hr />
          </div>

          <div className="row mt-05">
            <div className="col-md-6 left-align">
              <span className="label-lh-28">Incubator Address</span>
              <br />
              <span className="value-lh-20">
                {applicationGeneralDetailModel.city == null
                  ? ''
                  : applicationGeneralDetailModel.city}
                , &nbsp;
                {applicationGeneralDetailModel.state == null
                  ? ''
                  : applicationGeneralDetailModel.state}
                ,&nbsp;
                {applicationGeneralDetailModel.address == null
                  ? ' '
                  : applicationGeneralDetailModel.address}
              </span>
            </div>
            <div className="col-md-6 left-align">
              <span className="incubator-label">Website</span>
              <br />
              <span className="incubator-label-value-wrap">
                {applicationGeneralDetailModel.website_url == '' ||
                  applicationGeneralDetailModel.website_url == null
                  ? '-'
                  : applicationGeneralDetailModel.website_url}
              </span>
            </div>
          </div>

          <div className="row mt-05 ">
            <div className="col-md-6 left-align">
              <span className="label-lh-28">Facebook</span>
              <br />
              <span className="website-link">
                {applicationGeneralDetailModel.facebook_link == '' ||
                  applicationGeneralDetailModel.facebook_link == null
                  ? '-'
                  : applicationGeneralDetailModel.facebook_link}
              </span>
            </div>
            <div className="col-md-6 left-align">
              <span className="label-lh-28">Linkedin</span>
              <br />
              <span className="website-link">
                {applicationGeneralDetailModel.linkedin_link == '' ||
                  applicationGeneralDetailModel.linkedin_link == null
                  ? '-'
                  : applicationGeneralDetailModel.linkedin_link}
              </span>
            </div>
          </div>

          <div className="row mt-05 ">
            <div className="col-md-6 left-align">
              <span className="label-lh-28">Twitter</span>
              <br />
              <span className="website-link">
                {applicationGeneralDetailModel.twitter_link == '' ||
                  applicationGeneralDetailModel.twitter_link == null
                  ? '-'
                  : applicationGeneralDetailModel.twitter_link}
              </span>
            </div>
            <div className="col-md-6 left-align">
              <span className="label-lh-28">Youtube</span>
              <br />
              <span className="website-link">
                {applicationGeneralDetailModel.youtube_link == '' ||
                  applicationGeneralDetailModel.youtube_link == null
                  ? '-'
                  : applicationGeneralDetailModel.youtube_link}
              </span>
            </div>
          </div>

          <hr />

          {/* <div className="row left-align mt-05">
          <div className="col-md-6">
            <span className="incubator-label">No. of individuals that can be seated at once</span>
            <br />
            <span className="incubator-label-value-wrap">
              {applicationGeneralDetailModel.No_individuals == null
                ? '-'
                : applicationGeneralDetailModel.No_individuals}
            </span>
          </div>
          <div className="col-md-6">
            <span className="incubator-label">No. of startups undergoing incubation physically at the incubator</span>
            <br />
            <span className="incubator-label-value-wrap">
              {applicationGeneralDetailModel.No_startups_undergoing == '' ||
              applicationGeneralDetailModel.No_startups_undergoing == null
                ? '-'
                : applicationGeneralDetailModel.No_startups_undergoing}
            </span>
          </div>
        </div> */}

          {/* <div className="row left-align mt-05">
          <div className="col-md-6">
            <span className="incubator-label">List the significant facilities available for startups at the incubation center</span>
            <br />
            <span className="incubator-label-value">
              {applicationGeneralDetailModel.List_significant_facilities == null ||
              applicationGeneralDetailModel.List_significant_facilities == null
                ? '-'
                : applicationGeneralDetailModel.List_significant_facilities}
            </span>
          </div>

        </div> */}

          {/* <div className="row left-align mt-05">
          <div className="col-md-6 left-align">
            <span className="incubator-label-wrap">
            Upload File
            </span>
            <br />
            {loader ?
              <div className="spinner-border custom-spinner loader-position" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            :
            (applicationGeneralDetailModel!= undefined && applicationGeneralDetailModel.uploaded_documents.List_significant_facilities_upload[0] !=undefined
              ? applicationGeneralDetailModel.uploaded_documents.List_significant_facilities_upload[0].split(',').map(
                  (file:any,index:any) => (
                    index < 2 
                    ?
                    <div className="row mt-05">
                      <div className="col-md-3">
                        { getFileDetails(
                              file,
                             'type'
                                          )== 'pdf' ? (
                          <img src={pdf} alt="SISFS Logo" />
                        ) :  getFileDetails(
                          file,
                         'type'
                                      ) == 'doc' ? (
                          <img src={doc} alt="SISFS Logo" />
                        ) :  getFileDetails(
                          file,
                         'type'
                                      )== 'xls' ? (
                          <img src={xls} alt="SISFS Logo" />
                        ) : (
                          <img src={Allround} alt="SISFS Logo" />
                        )}
                      </div>
                      <div className="col-md-9">
                        <span className="document-label-value-wrap">
                          { getFileDetails(
                              file,
                             'name'
                                          )}
                        </span>
                        <br />
                        <a
                          href={`${NODE_URL}`+file.substr(1)}
                          target="_blank"
                          className="incubator-details-link"
                        >
                          View
                        </a>
                        /
                        <a
                          href="#"
                          className="incubator-details-link"
                          onClick={() =>
                            downloadDocumentFirst(file)
                          }
                        >
                          Download
                        </a>
                      </div>
                    </div>
                    :""
                  )
                )
              : (applicationGeneralDetailModel.uploaded_documents.List_significant_facilities_upload.length == 0) ? 'NA' : '')
              }

              
            { applicationGeneralDetailModel.uploaded_documents
              .List_significant_facilities_upload[0].split(',').length > 2 ?
             <a
             className="incubator-details-link"
             data-toggle="modal"
             data-target="#showMore"
             onClick={() => setMeetingModal('List_significant_facilities_upload')}
           >
             More...
           </a>
           :""}

            {applicationGeneralDetailModel.uploaded_documents.List_significant_facilities_upload[0].split(',')
              .length == 0 ? (
              <span className="incubator-label-value"> -</span>
            ) : (
              ''
            )}
          </div>

        </div> */}

          {/* <div className="eac-incubator-hr">
          <hr />
        </div> */}
          <div className="row mt-1-5">
            <div className="col-md-12 left-align">
              <span className="incubator-label-heading">
                Incubation support provided by the incubator in last three years
                from the date of reapplication
              </span>
            </div>
          </div>

          <div className="row mt-05">
            <div className="col-md-6 left-align">
              <span className="label-lh-28">
                No. of startups incubated and their details
              </span>
              <br />
              <span className="value-lh-20">
                {applicationGeneralDetailModel.No_startups_incubated == null
                  ? '-'
                  : applicationGeneralDetailModel.No_startups_incubated}
              </span>
            </div>
            <div className="col-md-6 left-align">
              <span className="incubator-label-wrap">
                No. of startups graduated
              </span>
              <br />
              <span className="value-lh-20">
                {applicationGeneralDetailModel.No_startups_graduated == null
                  ? '-'
                  : applicationGeneralDetailModel.No_startups_graduated}
              </span>
            </div>
          </div>

          <div className="row mt-05 ">
            <div className="col-md-6 left-align">
              <span className="incubator-label">
                No. of startups that raised follow on investments
              </span>
              <br />
              <span className="incubator-label-value-wrap">
                {applicationGeneralDetailModel.No_startups_raised == null
                  ? '-'
                  : applicationGeneralDetailModel.No_startups_raised}
              </span>
            </div>
            <div className="col-md-6 left-align">
              <span className="incubator-label-wrap">
                No. of startups that crossed a revenue of Rs 1 Cr in last 1 year
              </span>
              <br />
              <span className="incubator-label-value-wrap">
                {applicationGeneralDetailModel.No_startups_crossed == null
                  ? '-'
                  : applicationGeneralDetailModel.No_startups_crossed}
              </span>
            </div>
          </div>

          <div className="row mt-05">
            <div className="col-md-6 left-align">
              <span className="incubator-label">
                2-year survival rate of startups from the date of joining
                incubator
              </span>
              <br />
              <span className="incubator-label-value-wrap">
                {applicationGeneralDetailModel.survival_rate_startups == null
                  ? '-'
                  : applicationGeneralDetailModel.survival_rate_startups}
              </span>
            </div>
          </div>

          <hr />

          <div className="row mt-1-5">
            <div className="col-md-12 left-align">
              <span className="incubator-label-heading">
                Funding support extended to incubatees in last three years from
                the date of reapplication
              </span>
            </div>
          </div>

          <div className="row mt-05">
            <div className="col-md-12 left-align">
              <span className="incubator-label">
                Investment agreements signed between incubator and startups
              </span>
              <br />
              <span className="incubator-label-value-wrap">
                {applicationGeneralDetailModel.Investment_agreements_signed ==
                  null
                  ? '-'
                  : applicationGeneralDetailModel.Investment_agreements_signed}
              </span>
            </div>
          </div>

          <div className="row mt-05">
            <div className="col-md-6 left-align">
              <span className="incubator-label">
                No. of startups invested in
              </span>
              <br></br>
              <span className="incubator-label-value-wrap">
                {applicationGeneralDetailModel.No_startups_invested == null
                  ? '-'
                  : applicationGeneralDetailModel.No_startups_invested}
              </span>
            </div>
          </div>

          <div className="row mt-05">
            <div className="col-md-6 left-align">
              <span className="incubator-label">
                Total Corpus allocated to incubatees by the incubator
              </span>
              <br />
              <span className="incubator-label-value-wrap">
                {applicationGeneralDetailModel.allocated_incubatees == null
                  ? '-'
                  : applicationGeneralDetailModel.allocated_incubatees}
              </span>
            </div>

            <div className="col-md-6 left-align">
              <span className="incubator-label">
                Total Corpus allocated to incubatees from external sources
              </span>
              <br></br>
              <span className="incubator-label-value-wrap">
                {applicationGeneralDetailModel.external_sources == null
                  ? '-'
                  : applicationGeneralDetailModel.external_sources}
              </span>
            </div>
          </div>

          <hr />

          <div className="row mt-1-5">
            <div className="col-md-12 left-align">
              <span className="incubator-label-heading">
                Mentoring provided to incubatees in last three years from the
                date of reapplication
              </span>
            </div>
          </div>

          <div className="row mt-05">
            <div className="col-md-6 left-align">
              <span className="incubator-label">
                No. of mentors actively engaged with incubator
              </span>
              <br></br>
              <span className="incubator-label-value-wrap">
                {applicationGeneralDetailModel.No_mentors_actively == null
                  ? '-'
                  : applicationGeneralDetailModel.No_mentors_actively}
              </span>
            </div>

            <div className="col-md-6 left-align">
              <span className="incubator-label">
                Avg mentoring hours allocated per startup per month
              </span>
              <br></br>
              <span className="incubator-label-value-wrap">
                {applicationGeneralDetailModel.allocated_startup_month == null
                  ? '-'
                  : applicationGeneralDetailModel.allocated_startup_month}
              </span>
            </div>
          </div>

          <div className="row mt-05">
            <div className="col-md-6 left-align">
              <span className="incubator-label-wrap">
                No. of patents registered by incubatees
              </span>
              <br />
              <span className="incubator-label-value-wrap">
                {applicationGeneralDetailModel.No_patents_registered == null
                  ? '-'
                  : applicationGeneralDetailModel.No_patents_registered}
              </span>
            </div>

            <div className="col-md-6 left-align">
              <span className="incubator-label-wrap">
                No. of copyrights registered by incubatees
              </span>
              <br />
              <span className="incubator-label-value-wrap">
                {applicationGeneralDetailModel.No_patents_copyrights == null
                  ? '-'
                  : applicationGeneralDetailModel.No_patents_copyrights}
              </span>
            </div>
          </div>

          <div className="row mt-05">
            <div className="col-md-6 left-align">
              <span className="incubator-label-wrap">
                No. of trademarks registered by incubatees
              </span>
              <br />
              <span className="incubator-label-value-wrap">
                {applicationGeneralDetailModel.No_trademarks_registered == null
                  ? '-'
                  : applicationGeneralDetailModel.No_trademarks_registered}
              </span>
            </div>
          </div>

          <hr />

          <div className="row mt-1-5">
            <div className="col-md-12 left-align">
              <span className="incubator-label-heading">
                Other support extended to incubatees in last three years from
                the date of reapplication
              </span>
            </div>
          </div>

          <div className="row mt-05">
            <div className="col-md-6 left-align">
              <span className="incubator-label-wrap">
                Industry/Corporate connects facilitated
              </span>
              <br />
              <span className="incubator-label-value-wrap">
                {applicationGeneralDetailModel.Corporate_connects_facilitated ==
                  null
                  ? '-'
                  : applicationGeneralDetailModel.Corporate_connects_facilitated}
              </span>
            </div>
            <div className="col-md-6 left-align">
              <span className="incubator-label-wrap">
                Events held for stakeholder engagements
              </span>
              <br />
              <span className="incubator-label-value-wrap">
                {applicationGeneralDetailModel.Events_stakeholder_engagements ==
                  null
                  ? '-'
                  : applicationGeneralDetailModel.Events_stakeholder_engagements}
              </span>
            </div>
          </div>

          <div className="row mt-05">
            <div className="col-md-6 left-align">
              <span className="incubator-label-wrap">
                Participation in other events
              </span>
              <br />
              <span className="incubator-label-value-wrap">
                {applicationGeneralDetailModel.Participation_Event == null
                  ? '-'
                  : applicationGeneralDetailModel.Participation_Event}
              </span>
            </div>
          </div>

          <hr />

          <div className="row mt-1-5">
            <div className="col-md-12 left-align">
              <span className="incubator-label-heading">
                Details of the incubator team from the date of reapplication
              </span>
            </div>
          </div>

          <div className="row mt-05">
            <div className="col-md-6 left-align">
              <span className="incubator-label-wrap">
                Name & Background of the current CEO
              </span>
              <br />
              <span className="incubator-label-value-wrap">
                {applicationGeneralDetailModel.Name_Background_current == null
                  ? '-'
                  : applicationGeneralDetailModel.Name_Background_current}
              </span>
            </div>

            <div className="col-md-6 left-align">
              <span className="incubator-label-wrap">
                No. of full-time employees
              </span>
              <br />
              <span className="incubator-label-value-wrap">
                {applicationGeneralDetailModel.fulltime_employees_re_apply ==
                  null
                  ? '-'
                  : applicationGeneralDetailModel.fulltime_employees_re_apply}
              </span>
            </div>
          </div>

          <div className="row mt-05">
            <div className="col-md-12 left-align">
              <span className="incubator-label-heading">
                List of all teams along with the number of full-time employees
                in each team
              </span>
              <br />

              <div className="row mt-05 ">
                <div className="col-md-6">
                  <span className="incubator-label">Team Name</span>
                </div>
                <div className="col-md-6">
                  <span className="incubator-label">No. of employees</span>
                </div>
              </div>
              {applicationGeneralDetailModel.teamsData == undefined ||
                applicationGeneralDetailModel.teamsData.length == 0 ? (
                <div className="row">
                  <div className="col-md-6">
                    <span className="incubator-label-value">-</span>
                  </div>
                  <div className="col-md-6">
                    <span className="incubator-label-value">-</span>
                  </div>
                </div>
              ) : (
                applicationGeneralDetailModel.teamsData.map((team: any) => (
                  <div className="row">
                    <div className="col-md-6">
                      <span className="incubator-label-value-wrap">
                        {team.team_name == '' ? '-' : team.team_name}
                      </span>
                    </div>
                    <div className="col-md-6">
                      <span className="incubator-label-value">
                        {team.no_employee}
                      </span>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>

          <hr />

          <div className="row mt-1-5">
            <div className="col-md-12 left-align">
              <span className="incubator-label-heading">
                Fund Utilization Details in SISFS from the date of reapplication
              </span>
            </div>
          </div>

          <div className="row mt-05">
            <div className="col-md-6 left-align">
              <span className="incubator-label-wrap">
                Quantum of fund disbursed
              </span>
              <br />
              <span className="incubator-label-value-wrap">
                {applicationGeneralDetailModel.Fund_Utilization_Details == null
                  ? '-'
                  : applicationGeneralDetailModel.Fund_Utilization_Details}
              </span>
            </div>

            <div className="col-md-6 left-align"></div>
          </div>

          <div className="row mt-05">
            <div className="col-md-6 left-align">
              <span className="incubator-label-wrap">Upload Pitch deck</span>
              <br />
              {loader ? (
                <div
                  className="spinner-border custom-spinner loader-position"
                  role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : applicationGeneralDetailModel != undefined &&
                applicationGeneralDetailModel.pitchdeck_upload_fpath !=
                undefined ? (
                applicationGeneralDetailModel.pitchdeck_upload_fpath
                  .split(',')
                  .map((file: any, index: any) =>
                    index < 2 ? (
                      <div className="row mt-05">
                        <div className="col-md-3">
                          {getFileDetails(file, 'type') == 'pdf' ? (
                            <img src={pdf} alt="SISFS Logo" />
                          ) : getFileDetails(file, 'type') == 'doc' ? (
                            <img src={doc} alt="SISFS Logo" />
                          ) : getFileDetails(file, 'type') == 'xls' ? (
                            <img src={xls} alt="SISFS Logo" />
                          ) : (
                            <img src={Allround} alt="SISFS Logo" />
                          )}
                        </div>
                        <div className="col-md-9">
                          <span className="document-label-value-wrap">
                            {getFileDetails(file, 'name')}
                          </span>
                          <br />
                          <a
                            href={`${NODE_URL}` + file.substr(1)}
                            target="_blank"
                            className="incubator-details-link">
                            View
                          </a>
                          {/* /
                          <a
                            href="#"
                            className="incubator-details-link"
                            onClick={() => downloadDocument(file)}>
                            Download
                          </a> */}
                        </div>
                      </div>
                    ) : (
                      ''
                    ),
                  )
              ) : applicationGeneralDetailModel.pitchdeck_upload_fpath.length ==
                0 ? (
                'NA'
              ) : (
                ''
              )}

              {applicationGeneralDetailModel.pitchdeck_upload_fpath.split(',')
                .length > 2 ? (
                <a
                  className="incubator-details-link"
                  data-toggle="modal"
                  data-target="#showMore"
                  onClick={() => setMeetingModal('pitchdeck_upload_fpath')}>
                  More...
                </a>
              ) : (
                ''
              )}

              {applicationGeneralDetailModel.pitchdeck_upload_fpath.split(',')
                .length == 0 ? (
                <span className="incubator-label-value"> -</span>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="row mt-05">
            <div className="col-md-6 left-align">
              <span className="incubator-label-wrap">
                Please upload a performance audit report by an independent third
                party
              </span>
              <br />
              {loader ? (
                <div
                  className="spinner-border custom-spinner loader-position"
                  role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : applicationGeneralDetailModel != undefined &&
                applicationGeneralDetailModel.Performance_Upload_fpath !=
                undefined ? (
                applicationGeneralDetailModel.Performance_Upload_fpath.split(
                  ',',
                ).map((file: any, index: any) =>
                  index < 2 ? (
                    <div className="row mt-05">
                      <div className="col-md-3">
                        {getFileDetails(file, 'type') == 'pdf' ? (
                          <img src={pdf} alt="SISFS Logo" />
                        ) : getFileDetails(file, 'type') == 'doc' ? (
                          <img src={doc} alt="SISFS Logo" />
                        ) : getFileDetails(file, 'type') == 'xls' ? (
                          <img src={xls} alt="SISFS Logo" />
                        ) : (
                          <img src={Allround} alt="SISFS Logo" />
                        )}
                      </div>
                      <div className="col-md-9">
                        <span className="document-label-value-wrap">
                          {getFileDetails(file, 'name')}
                        </span>
                        <br />
                        <a
                          href={`${NODE_URL}` + file.substr(1)}
                          target="_blank"
                          className="incubator-details-link">
                          View
                        </a>
                        {/* /
                        <a
                          href="#"
                          className="incubator-details-link"
                          onClick={() => downloadDocument(file)}>
                          Download
                        </a> */}
                      </div>
                    </div>
                  ) : (
                    ''
                  ),
                )
              ) : applicationGeneralDetailModel.Performance_Upload_fpath
                .length == 0 ? (
                'NA'
              ) : (
                ''
              )}

              {applicationGeneralDetailModel.Performance_Upload_fpath.split(',')
                .length > 2 ? (
                <a
                  className="incubator-details-link"
                  data-toggle="modal"
                  data-target="#showMore"
                  onClick={() => setMeetingModal('Performance_Upload_fpath')}>
                  More...
                </a>
              ) : (
                ''
              )}

              {applicationGeneralDetailModel.Performance_Upload_fpath.split(',')
                .length == 0 ? (
                <span className="incubator-label-value"> -</span>
              ) : (
                ''
              )}
            </div>

            <div className="col-md-6 left-align">
              <span className="incubator-label-wrap">Other Documents <br />&nbsp;</span>
              <br />
              {loader ? (
                <div
                  className="spinner-border custom-spinner loader-position"
                  role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : applicationGeneralDetailModel != undefined &&
                applicationGeneralDetailModel.Other_doc_upload_fpath !=
                undefined ? (
                applicationGeneralDetailModel.Other_doc_upload_fpath.split(
                  ',',
                ).map((file: any, index: any) =>
                  index < 2 ? (
                    <div className="row mt-05">
                      <div className="col-md-3">
                        {getFileDetails(file, 'type') == 'pdf' ? (
                          <img src={pdf} alt="SISFS Logo" />
                        ) : getFileDetails(file, 'type') == 'doc' ? (
                          <img src={doc} alt="SISFS Logo" />
                        ) : getFileDetails(file, 'type') == 'xls' ? (
                          <img src={xls} alt="SISFS Logo" />
                        ) : (
                          <img src={Allround} alt="SISFS Logo" />
                        )}
                      </div>
                      <div className="col-md-9">
                        <span className="document-label-value-wrap">
                          {getFileDetails(file, 'name')}
                        </span>
                        <br />
                        <a
                          href={`${NODE_URL}` + file.substr(1)}
                          target="_blank"
                          className="incubator-details-link">
                          View
                        </a>
                        {/* /
                        <a
                          href="#"
                          className="incubator-details-link"
                          onClick={() => downloadDocument(file)}>
                          Download
                        </a> */}
                      </div>
                    </div>
                  ) : (
                    ''
                  ),
                )
              ) : applicationGeneralDetailModel.Other_doc_upload_fpath.length ==
                0 ? (
                'NA'
              ) : (
                ''
              )}

              {applicationGeneralDetailModel.Other_doc_upload_fpath.split(',')
                .length > 2 ? (
                <a
                  className="incubator-details-link"
                  data-toggle="modal"
                  data-target="#showMore"
                  onClick={() => setMeetingModal('Other_doc_upload_fpath')}>
                  More...
                </a>
              ) : (
                ''
              )}

              {applicationGeneralDetailModel.Other_doc_upload_fpath.split(',')
                .length == 0 ? (
                <span className="incubator-label-value"> -</span>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}

      <div className="incubator-hr">
        <hr />
      </div>

      <div className="row">
        <div
          id="q-2"
          className={
            activeIndexApp == '2'
              ? 'tranche-heading col-sm-11 left-align'
              : 'reports-heading col-sm-11 left-align'
          }>
          Incubator Scheme Progress - Part 1
        </div>
        <div className="col-sm-1">
          {activeIndexApp == '2' ? (
            <div
              onClick={changeActiveBackApp}
              data-toggle="collapse"
              data-target="#incubator-expand"
              role="button"
              aria-expanded="false"
              aria-controls="incubator-expand"
              className="hide-icon pull-right hide-icon-details"
              id="show-2">
              <img src={Minus} className="img-fluid" alt="SISFS Logo" />
            </div>
          ) : (
            <div
              onClick={() => changeActiveApp('2')}
              id="hide-2"
              data-toggle="collapse"
              data-target="#incubator-expand"
              role="button"
              aria-expanded="false"
              aria-controls="incubator-expand"
              className="expand-icon pull-right expand-icon-details">
              <img src={Plus} className="img-fluid" alt="SISFS Logo" />
            </div>
          )}
        </div>
      </div>
      {activeIndexApp == '2' ? (
        // <form >
        <div className="collapse " id="incubator-expand">
          <fieldset disabled={disabled_part1}>
          <div className="row mt-1-5">
            <div
              className="col-md-12 left-align"
              style={{
                fontSize: '17px',
                padding: '10px',
                backgroundColor: '#f8f7f7',
              }}>
              <span
                className="incubator-label-heading"
                style={{ color: '#2b3f88' }}>
                Following are the details of the progress made by the incubator
                in SISFS (as indicated in the latest request for the subsequent
                tranche)
              </span>
            </div>
          </div>

          <div className="row mt-1-5">
            <div
              className="col-md-12 left-align"
              style={{
                fontSize: '17px',
                padding: '10px',
                backgroundColor: '#f8f7f7',
              }}>
              <span
                className="incubator-label-heading"
                style={{ color: '#2b3f88' }}>
                Details of utilization of funds from the date of reapplication
              </span>
            </div>
          </div>
          <div className="form-group  row">
            <label className="col-sm-4 col-form-label">
              Utilization Certificate (UC) of previous installments{' '}
              <span style={{ color: 'red' }}>*</span>
            </label>
            <div className="col-sm-8 m-auto">
              <div className="">
                {/* <input type="file" className="form-control-file btn btn-sisfs btn-transparent-primary" id="exampleFormControlFile1" /> */}
                <button
                  className="btn btn-sisfs btn-transparent-primary"
                  onClick={() =>
                    setOpenUploadBlock({ UtilizationCertificate: true })
                  }
                // disabled={isDisabled}
                >
                  Upload
                </button>
                {/* {console.log(postapitwo.Utilization_Certificate_Upload,"uploadss")} */}
                {/* openUploadBlock["UtilizationCertificate"] &&
                                    postapitwo.Utilization_Certificate_Upload == null || postapitwo.Utilization_Certificate_Upload.length === 0) ||
                                    postapitwo.Utilization_Certificate_Upload.length > 0) &&  */}
                {((openUploadBlock &&
                  openUploadBlock["UtilizationCertificate"] &&
                  postapitwo.Utilization_Certificate_Upload == null || postapitwo.Utilization_Certificate_Upload.length == 0 ||
                  postapitwo.Utilization_Certificate_Upload.length > 0)) && (
                    <>
                        <span
                          className="close"
                          onClick={() =>
                            setOpenUploadBlock({UtilizationCertificate: false })
                          }>
                          <i className="fa fa-close"></i>
                        </span>
                      <FileUploader
                        key={Math.random().toString()}
                        id={UploadTypeEnum2.UtilizationCertificate}
                        information="Drag & Drop the file or Browse"
                        fileType="application/pdf"
                        buttonLabel="Browse"
                        multiple={true}
                        enablePreview
                        IconComponent={() => (
                          <span className="fa fa-cloud-upload"></span>
                        )}
                        removeIconClass=""
                        existingFiles={onlypdf}
                        onUploadFinish={(files: Array<File>) => {
                          onFileUpload(
                            files,
                            UploadTypeEnum2.UtilizationCertificate,
                          );
                        }}
                        onFileDelete={(deletedFile: File) =>
                          onFileDelete(
                            deletedFile,
                            UploadTypeEnum2.UtilizationCertificate,
                          )
                        }
                      />
                    </>
                  )}
                {/* {console.log(postapitwo, 'utili_get')} */}
                {typeof postapitwo.Utilization_Certificate_Upload_get ==
                  'string' &&
                  postapitwo.Utilization_Certificate_Upload_get.length > 0 ? (
                  <>
                    <div
                      style={{
                        minHeight: 'fit-content',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <div style={{ width: '40vh', maxWidth: '400px' }}>
                        <ListComponent2
                          links={postapitwo.Utilization_Certificate_Upload_get.split(
                            ',',
                          ).map((currentName: any, index: any, arr: any) => {
                            return `${currentName.slice(1)} `;
                          })}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <small>
                  <i>as submitted in the latest tranche request - PDF only</i>
                </small>

                {/* <div
                                    className={`${props.errors.length &&
                                    (postapitwo.Utilization_Certificate_Upload.length == undefined || postapitwo.Utilization_Certificate_Upload.length == null)
                                    && !isDisabled
                                    ? `col-12 pl-0 error`
                                    : "invalid-feedback "
                                    }`}
                                >
                                    This is mandatory field
                                </div> */}
              </div>
              {/* <small style={{color:'#ff0000'}}><i>{formErrors.Utilization_Certificate_Upload}</i></small> */}
            </div>
          </div>
          {/* <div className="row mt-05">
              <div className="col-md-12 left-align">
                <label className="form-label">
                  <b>Utilization Certificate (UC) of previous installments</b>
                </label>
                <br /> */}
          {/* {loader ?
              <div className="spinner-border custom-spinner loader-position" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            :
            (applicationGeneralDetailModel!= undefined && applicationGeneralDetailModel.uploaded_documents.Utilization_Certificate_Upload[0] !=undefined
              ? applicationGeneralDetailModel.uploaded_documents.Utilization_Certificate_Upload[0].split(',').map(
                  (file:any,index:any) => (
                    index < 2 
                    ?
                    <div className="row mt-05">
                      <div className="col-md-3">
                        { getFileDetails(
                              file,
                             'type'
                                          )== 'pdf' ? (
                          <img src={pdf} alt="SISFS Logo" />
                        ) :  getFileDetails(
                          file,
                         'type'
                                      ) == 'doc' ? (
                          <img src={doc} alt="SISFS Logo" />
                        ) :  getFileDetails(
                          file,
                         'type'
                                      )== 'xls' ? (
                          <img src={xls} alt="SISFS Logo" />
                        ) : (
                          <img src={Allround} alt="SISFS Logo" />
                        )}
                      </div>
                      <div className="col-md-9">
                        <span className="document-label-value-wrap">
                          { getFileDetails(
                              file,
                             'name'
                                          )}
                        </span>
                        <br />
                        <a
                          href={`${NODE_URL}`+file.substr(1)}
                          target="_blank"
                          className="incubator-details-link"
                        >
                          View
                        </a>
                        /
                        <a
                          href="#"
                          className="incubator-details-link"
                          onClick={() =>
                            downloadDocument(file)
                          }
                        >
                          Download
                        </a>
                      </div>
                    </div>
                    :""
                  )
                )
              : (applicationGeneralDetailModel.uploaded_documents.Utilization_Certificate_Upload.length == 0) ? 'NA' : '')
              }

              
            { applicationGeneralDetailModel.uploaded_documents
              .Utilization_Certificate_Upload[0].split(',').length > 2 ?
             <a
             className="incubator-details-link"
             data-toggle="modal"
             data-target="#showMore"
             onClick={() => setMeetingModal('Utilization_Certificate_Upload')}
           >
             More...
           </a>
           :""}

            {applicationGeneralDetailModel.uploaded_documents.Utilization_Certificate_Upload[0].split(',')
              .length == 0 ? (
              <span className="incubator-label-value"> -</span>
            ) : (
              ''
            )} */}
          {/* </div>
            </div> */}

          <div className="row mt-05">
            <div className="col-md-6 left-align">
              <label className="form-label">
                <b>Amount of Interest earned on unutilized funds, if any</b>
              </label>
              <br />
              <input
                min="0"
                name="Details_Interest_earned"
                value={
                  postapitwo.Details_Interest_earned == ''
                    ? null
                    : postapitwo.Details_Interest_earned
                }
                // className={
                //   postapitwo.Details_Interest_earned == ''
                //     ? `form-control error`
                //     : 'form-control'
                // }
                className="form-control"

                onChange={handleChange}
                required={true}
                maxLength={255}
              />
              <small>
                <i>
                  as submitted in the latest tranche request; please enter 0 if
                  not applicable
                </i>
              </small>
            </div>
            <div className="col-md-6 left-align">
              <label className="form-label">
                <b>Grant Disbursed</b>
              </label>
              <br />
              <br />

              <input
                min="0"
                name="Grant_Disbursed"
                value={
                  postapitwo.Grant_Disbursed == ''
                    ? ''
                    : postapitwo.Grant_Disbursed
                }
                // className={
                //   postapitwo.Grant_Disbursed == ''
                //     ? `form-control error`
                //     : 'form-control'
                // }
                className="form-control"

                onChange={handleChange}
                required={true}
                maxLength={255}
              />
              <small>
                <i>
                  as submitted in the latest tranche request; please enter 0 if
                  not applicable
                </i>
              </small>
            </div>
          </div>
          <hr />
          <div className="row mt-1-5">
            <div
              className="col-md-12 left-align"
              style={{
                fontSize: '17px',
                padding: '10px',
                backgroundColor: '#f8f7f7',
              }}>
              <span
                className="incubator-label-heading"
                style={{ color: '#2b3f88' }}>
                Details of funds disbursed and return realized by each
                instrument type from the date of reapplication
              </span>
            </div>
          </div>

          <div className="row mt-05">
            <div className="col-md-6 left-align">
              <label className="form-label">
                <b>Debt Disbursed</b>
              </label>
              <br />
              <input
                min="0"
                name="Debt_Disbursed"
                value={
                  postapitwo.Debt_Disbursed == ''
                    ? ''
                    : postapitwo.Debt_Disbursed
                }
                // className={
                //   postapitwo.Debt_Disbursed == ''
                //     ? `form-control error`
                //     : 'form-control'
                // }
                className="form-control"

                onChange={handleChange}
                required={true}
                maxLength={255}
              />
              <small>
                <i>
                  as submitted in the latest tranche request; please enter 0 if
                  not applicable
                </i>
              </small>
            </div>

            <div className="col-md-6 left-align">
              <label className="form-label">
                <b>Interest Earned on Debt</b>
              </label>
              <br />
              <input
                min="0"
                name="Debt_Interest"
                value={
                  postapitwo.Debt_Interest == '' ? '' : postapitwo.Debt_Interest
                }
                // className={
                //   postapitwo.Debt_Interest == ''
                //     ? `form-control error`
                //     : 'form-control'
                // }
                className="form-control"

                onChange={handleChange}
                required={true}
                maxLength={255}
              />
              <small>
                <i>
                  as submitted in the latest tranche request; please enter 0 if
                  not applicable
                </i>
              </small>
            </div>
          </div>

          <div className="row mt-05">
            <div className="col-md-6 left-align">
              <label className="form-label">
                <b>Other return earned from the instrument (Debt)</b>
              </label>
              <br />
              <input
                min="0"
                name="Debt_Other_Earned"
                value={
                  postapitwo.Debt_Other_Earned == ''
                    ? ''
                    : postapitwo.Debt_Other_Earned
                }
                className="form-control"

                // className={
                //   postapitwo.Debt_Other_Earned == ''
                //     ? `form-control error`
                //     : 'form-control'
                // }
                onChange={handleChange}
                required={true}
                maxLength={255}
              />
              <small>
                <i>
                  as submitted in the latest tranche request; please enter 0 if
                  not applicable
                </i>
              </small>
            </div>

            <div className="col-md-6 left-align">
              <label className="form-label">
                <b>Debentures Disbursed</b>
              </label>
              <br />
              <br />
              <input
                min="0"
                name="Debentures_Disbursed"
                value={
                  postapitwo.Debentures_Disbursed == ''
                    ? ''
                    : postapitwo.Debentures_Disbursed
                }
                // className={
                //   postapitwo.Debentures_Disbursed == ''
                //     ? `form-control error`
                //     : 'form-control'
                // }
                className="form-control"

                onChange={handleChange}
                required={true}
                maxLength={255}
              />
              <small>
                <i>
                  as submitted in the latest tranche request; please enter 0 if
                  not applicable
                </i>
              </small>
              <small>
                <i>(Debentures mean Convertible Instruments)</i>
              </small>
            </div>
          </div>

          <div className="row mt-05">
            <div className="col-md-6 left-align">
              <label className="form-label">
                <b>Interest Earned on Debenture</b>
              </label>
              <br />
              <br />
              <input
                min="0"
                name="Debentures_Interest_Earned"
                value={
                  postapitwo.Debentures_Interest_Earned == ''
                    ? ''
                    : postapitwo.Debentures_Interest_Earned
                }
                // className={
                //   postapitwo.Debentures_Interest_Earned == ''
                //     ? `form-control error`
                //     : 'form-control'
                // }
                className="form-control"

                onChange={handleChange}
                required={true}
                maxLength={255}
              />
              <small>
                <i>
                  as submitted in the latest tranche request; please enter 0 if
                  not applicable
                </i>
              </small>
            </div>

            <div className="col-md-6 left-align">
              <label className="form-label">
                <b>Other return earned from the instrument (Debenture)</b>
              </label>
              <br />
              <input
                min="0"
                name="Debentures_Other_Earned"
                value={
                  postapitwo.Debentures_Other_Earned == ''
                    ? ''
                    : postapitwo.Debentures_Other_Earned
                }
                // className={
                //   postapitwo.Debentures_Other_Earned == ''
                //     ? `form-control error`
                //     : 'form-control'
                // }
                className="form-control"
                onChange={handleChange}
                required={true}
                maxLength={255}
              />
              <small>
                <i>
                  as submitted in the latest tranche request; please enter 0 if
                  not applicable
                </i>
              </small>
            </div>
          </div>

          <hr />
          <div className="row mt-1-5">
            <div
              className="col-md-12 left-align"
              style={{
                fontSize: '17px',
                padding: '10px',
                backgroundColor: '#f8f7f7',
              }}>
              <span
                className="incubator-label-heading"
                style={{ color: '#2b3f88' }}>
                Details of accounting of Seed fund and management fees from the
                date of reapplication
              </span>
            </div>
          </div>

          <div className="row mt-05">
            <div className="col-md-12 left-align">
              <label className="form-label">
                <b>
                  Audited statement of expenditure separately for Management fee
                  and Seed Fund along with bank statement
                </b>
              </label>
              <br />
              <div className="col-sm-8 m-auto">
                <div className="">
                  {/* <input type="file" className="form-control-file btn btn-sisfs btn-transparent-primary" id="exampleFormControlFile1" /> */}
                  <button
                    className="btn btn-sisfs btn-transparent-primary"
                    onClick={() => setOpenUploadBlock({ AuditedStatement: true })}
                  // disabled={isDisabled}
                  >
                    Upload
                  </button>
                  {((openUploadBlock &&
                    openUploadBlock["AuditedStatement"] &&
                    postapitwo.Audited_statement_expenditure_Upload.length === 0 || postapitwo.Audited_statement_expenditure_Upload == null) ||
                    postapitwo.Audited_statement_expenditure_Upload.length > 0) && (
                      <>
                        <span
                          className="close"
                          onClick={() =>
                            setOpenUploadBlock({ AuditedStatement: false })
                          }>
                          <i className="fa fa-close"></i>
                        </span>
                        <FileUploader
                          // key={UploadTypeEnum.ProofAssistance}
                          key={Math.random().toString()}
                          id={UploadTypeEnum2.AuditedStatement}
                          information="Drag & Drop the file or Browse"
                          fileType="application/pdf"
                          buttonLabel="Browse"
                          multiple={true}
                          enablePreview
                          IconComponent={() => (
                            <span className="fa fa-cloud-upload"></span>
                          )}
                          removeIconClass=""
                          existingFiles={
                            onlypdf1
                            // .map(
                            // (uploadedFile: FileUploadModel) =>
                            // uploadedFile.file
                            //   )
                          }
                          onUploadFinish={(files: Array<File>) => {
                            onFileUpload(
                              files,
                              UploadTypeEnum2.AuditedStatement,
                            );
                          }}
                          onFileDelete={(deletedFile: File) =>
                            onFileDelete(
                              deletedFile,
                              UploadTypeEnum2.AuditedStatement,
                            )
                          }
                        />
                        {/* <strong>file Uploader for proof</strong> */}
                      </>
                    )}
                  {typeof postapitwo.Audited_statement_expenditure_Upload_get ==
                    'string' &&
                    postapitwo.Audited_statement_expenditure_Upload_get.length >
                    0 ? (
                    <>
                      <div
                        style={{
                          minHeight: 'fit-content',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        <div style={{ width: '40vh', maxWidth: '400px' }}>
                          <ListComponent3
                            links={postapitwo.Audited_statement_expenditure_Upload_get.split(
                              ',',
                            ).map((currentName: any, index: any, arr: any) => {
                              return `${currentName.slice(1)} `;
                            })}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {/* <div
                                    className={`${props.errors.length &&
                                    !postapitwo.Audited_statement_expenditure_Upload.length
                                    && !isDisabled
                                    ? `col-12 pl-0 error`
                                    : "invalid-feedback "
                                    }`}
                                >
                                    This is mandatory field
                                </div> */}
                </div>
                <small>
                  <i>as submitted in the latest tranche request - PDF only</i>
                </small>

                {/* <small style={{color:'#ff0000'}}><i>{formErrors.Audited_statement_expenditure_Upload}</i></small> */}
              </div>
              {/* {loader ?
              <div className="spinner-border custom-spinner loader-position" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            :
            (applicationGeneralDetailModel!= undefined && applicationGeneralDetailModel.uploaded_documents.Audited_statement_expenditure_Upload[0] !=undefined
              ? applicationGeneralDetailModel.uploaded_documents.Audited_statement_expenditure_Upload[0].split(',').map(
                  (file:any,index:any) => (
                    index < 2 
                    ?
                    <div className="row mt-05">
                      <div className="col-md-3">
                        { getFileDetails(
                              file,
                             'type'
                                          )== 'pdf' ? (
                          <img src={pdf} alt="SISFS Logo" />
                        ) :  getFileDetails(
                          file,
                         'type'
                                      ) == 'doc' ? (
                          <img src={doc} alt="SISFS Logo" />
                        ) :  getFileDetails(
                          file,
                         'type'
                                      )== 'xls' ? (
                          <img src={xls} alt="SISFS Logo" />
                        ) : (
                          <img src={Allround} alt="SISFS Logo" />
                        )}
                      </div>
                      <div className="col-md-9">
                        <span className="document-label-value-wrap">
                          { getFileDetails(
                              file,
                             'name'
                                          )}
                        </span>
                        <br />
                        <a
                          href={`${NODE_URL}`+file.substr(1)}
                          target="_blank"
                          className="incubator-details-link"
                        >
                          View
                        </a>
                        /
                        <a
                          href="#"
                          className="incubator-details-link"
                          onClick={() =>
                            downloadDocument(file)
                          }
                        >
                          Download
                        </a>
                      </div>
                    </div>
                    :""
                  )
                )
              : (applicationGeneralDetailModel.uploaded_documents.Audited_statement_expenditure_Upload.length == 0) ? 'NA' : '')
              }

              
            { applicationGeneralDetailModel.uploaded_documents
              .Audited_statement_expenditure_Upload[0].split(',').length > 2 ?
             <a
             className="incubator-details-link"
             data-toggle="modal"
             data-target="#showMore"
             onClick={() => setMeetingModal('Audited_statement_expenditure_Upload')}
           >
             More...
           </a>
           :""}

            {applicationGeneralDetailModel.uploaded_documents.Audited_statement_expenditure_Upload[0].split(',')
              .length == 0 ? (
              <span className="incubator-label-value"> -</span>
            ) : (
              ''
            )} */}
            </div>
          </div>
          </fieldset>
          <br />
          <button
            className="btn btn-sisfs btn-yellow-primary mr-3"
            onClick={() => {setdisabled_part1(false)}}>
            Edit
          </button>
          <button
            className="btn btn-sisfs btn-yellow-primary"
            onClick={submitOne}>
            Submit
          </button>
        </div>
      ) : (
        ''
      )}
      {/* </form> */}

      <div className="incubator-hr">
        <hr />
      </div>

      <div className="row">
        <div
          id="q-4"
          className={
            activeIndexApp == '4'
              ? 'tranche-heading col-sm-11 left-align'
              : 'reports-heading col-sm-11 left-align'
          }>
          Incubator Scheme Progress - Part 2
        </div>
        <div className="col-sm-1">
          {activeIndexApp == '4' ? (
            <div
              onClick={changeActiveBackApp}
              data-toggle="collapse"
              data-target="#team-expand"
              role="button"
              aria-expanded="false"
              aria-controls="team-expand"
              className="hide-icon pull-right hide-icon-details"
              id="show-4">
              <img src={Minus} className="img-fluid" alt="SISFS Logo" />
            </div>
          ) : (
            <div
              onClick={() => changeActiveApp('4')}
              id="hide-4"
              data-toggle="collapse"
              data-target="#team-expand"
              role="button"
              aria-expanded="false"
              aria-controls="team-expand"
              className="expand-icon pull-right expand-icon-details">
              <img src={Plus} className="img-fluid" alt="SISFS Logo" />
            </div>
          )}
        </div>
      </div>
      {activeIndexApp == '4' ? (
        <form onSubmit={SubmitPartTwo}>
          <div className="collapse " id="team-expand">
          <fieldset disabled={disabled_part2}>
            <div className="row mt-1-5">
              <div
                className="col-md-12 left-align"
                style={{
                  fontSize: '17px',
                  padding: '10px',
                  backgroundColor: '#f8f7f7',
                }}>
                <span
                  className="incubator-label-heading"
                  style={{ color: '#2b3f88' }}>
                  Following are the details of the progress made by the
                  incubator in SISFS (as indicated in the latest Quarterly
                  Review)
                </span>
              </div>
            </div>

            <div className="row mt-1-5">
              <div
                className="col-md-12 left-align"
                style={{
                  fontSize: '17px',
                  padding: '10px',
                  backgroundColor: '#f8f7f7',
                }}>
                <span
                  className="incubator-label-heading"
                  style={{ color: '#2b3f88' }}>
                  Details of status of SISFS startup applications at an
                  incubator level from the date of reapplication
                </span>
              </div>
            </div>

            <div className="row mt-05 ">
              <div className="col-md-6 left-align">
                <label className="form-label required">
                  <b>No. of SISFS Startup Applications received</b>
                </label>
                <br />
                <input
                  min="0"
                  name="No_Startup_Applications_Received"
                  value={postapitwo1.No_Startup_Applications_Received}
                  // className={
                  //   postapitwo1.No_Startup_Applications_Received == ''
                  //     ? `form-control error`
                  //     : 'form-control'
                  // }
                  className="form-control"
                  onChange={handleChangetwo}
                  required={true}
                  maxLength={255}
                />
                <small>
                  <i>
                    This is automatically fetched for your allotted Startups
                  </i>
                </small>
              </div>
              <div className="col-md-6 left-align">
                <label className="form-label">
                  <b>No. of SISFS Startup Applications open</b>
                </label>
                <br />
                <br />
                <input
                  min="0"
                  name="open_applications"
                  // value={postapitwo1.Details_Interest_earned == "0" ? null : postapitwo1.Details_Interest_earned}
                  value={postapitwo1.open_applications}
                  // className={
                  //   postapitwo1.open_applications == ''
                  //     ? `form-control error`
                  //     : 'form-control'
                  // }
                  className="form-control"

                  onChange={handleChangetwo}
                  required={true}
                  maxLength={255}
                />
                <small>
                  <i>
                    This is automatically fetched for your allotted Startups
                  </i>
                </small>
              </div>
            </div>

            <div className="row mt-05 ">
              <div className="col-md-6 left-align">
                <label className="form-label">
                  <b>No. of SISFS Startup Applications not reviewed</b>
                </label>
                <br />
                <input
                  min="0"
                  name="No_Startup_Applications_Reviewed"
                  value={postapitwo1.No_Startup_Applications_Reviewed}
                  // className={
                  //   postapitwo1.No_Startup_Applications_Reviewed == ''
                  //     ? `form-control error`
                  //     : 'form-control'
                  // }
                  className="form-control"

                  onChange={handleChangetwo}
                  required={true}
                  maxLength={255}
                />
                <small>
                  <i>
                    This is automatically fetched for your allotted Startups
                  </i>
                </small>
              </div>
              <div className="col-md-6 left-align">
                <label className="form-label">
                  <b>No. of SISFS Startup Applications selected</b>
                </label>
                <br />
                <input
                  min="0"
                  name="No_Startup_Applications_Selected"
                  value={postapitwo1.No_Startup_Applications_Selected}
                  // className={
                  //   postapitwo1.No_Startup_Applications_Selected == ''
                  //     ? `form-control error`
                  //     : 'form-control'
                  // }
                  className="form-control"

                  onChange={handleChangetwo}
                  required={true}
                  maxLength={255}
                />
                <small>
                  <i>
                    This is automatically fetched for your allotted Startups
                  </i>
                </small>
              </div>
            </div>

            <div className="row mt-05 ">
              <div className="col-md-6 left-align">
                <label className="form-label">
                  <b>No. of SISFS Startup Applications allotted</b>
                </label>
                <br />
                <input
                  min="0"
                  name="No_Startup_Applications_Allotted"
                  value={postapitwo1.No_Startup_Applications_Allotted}
                  // className={
                  //   postapitwo1.No_Startup_Applications_Allotted == ''
                  //     ? `form-control error`
                  //     : 'form-control'
                  // }
                  className="form-control"

                  onChange={handleChangetwo}
                  required={true}
                  maxLength={255}
                />
                <small>
                  <i>
                    This is automatically fetched for your allotted Startups
                  </i>
                </small>
              </div>
              <div className="col-md-6 left-align">
                <label className="form-label">
                  <b>
                    No. of SISFS Startup with whom agreements have been signed
                  </b>
                </label>
                <br />
                <input
                  min="0"
                  name="No_Startup_whom_Agreements"
                  value={postapitwo1.No_Startup_whom_Agreements}
                  // className={
                  //   postapitwo1.No_Startup_whom_Agreements == ''
                  //     ? `form-control error`
                  //     : 'form-control'
                  // }
                  className="form-control"

                  onChange={handleChangetwo}
                  required={true}
                  maxLength={255}
                />
                <small>
                  <i>As Submitted in Last Quarterly Review</i>
                </small>
              </div>
            </div>

            <div className="row mt-05 ">
              <div className="col-md-6 left-align">
                <label className="form-label">
                  <b>No. of Startup Applications cancelled</b>
                </label>
                <br />
                <input
                  min="0"
                  name="No_Startup_Applications_Cancelled"
                  value={postapitwo1.No_Startup_Applications_Cancelled}
                  // className={
                  //   postapitwo1.No_Startup_Applications_Cancelled == ''
                  //     ? `form-control error`
                  //     : 'form-control'
                  // }
                  className="form-control"

                  onChange={handleChangetwo}
                  required={true}
                  maxLength={255}
                />
                <small>
                  <i>
                    This is automatically fetched for your allotted Startups
                  </i>
                </small>
              </div>
              <div className="col-md-6 left-align">
                <label className="form-label">
                  <b>No. of Startup Applications closed</b>
                </label>
                <br />
                <input
                  min="0"
                  name="No_Startup_Applications_Closed"
                  value={postapitwo1.No_Startup_Applications_Closed}
                  // className={
                  //   postapitwo1.No_Startup_Applications_Closed == ''
                  //     ? `form-control error`
                  //     : 'form-control'
                  // }
                  className="form-control"

                  onChange={handleChangetwo}
                  required={true}
                  maxLength={255}
                />
                <small>
                  <i>
                    This is automatically fetched for your allotted Startups
                  </i>
                </small>
              </div>
            </div>
            <hr />
            <div className="row mt-1-5">
              <div
                className="col-md-12 left-align"
                style={{
                  fontSize: '17px',
                  padding: '10px',
                  backgroundColor: '#f8f7f7',
                }}>
                <span
                  className="incubator-label-heading"
                  style={{ color: '#2b3f88' }}>
                  Details of stages of startup applications from the date of
                  reapplication
                </span>
              </div>
            </div>

            <div className="row mt-05 ">
              <div className="col-md-6 left-align">
                <label className="form-label">
                  <b>No. of industries represented</b>
                </label>
                <br />
                <input
                  min="0"
                  name="No_industries_represented"
                  value={postapitwo1.No_industries_represented}
                  // className={
                  //   postapitwo1.No_industries_represented == ''
                  //     ? `form-control error`
                  //     : 'form-control'
                  // }
                  className="form-control"

                  onChange={handleChangetwo}
                  required={true}
                  maxLength={255}
                />
                <small>
                  <i>
                    This is automatically fetched for your allotted Startups
                  </i>
                </small>
              </div>
              <div className="col-md-6 left-align">
                <label className="form-label">
                  <b>No. of States represented</b>
                </label>
                <br />
                <input
                  min="0"
                  name="No_States_represented"
                  value={postapitwo1.No_States_represented}
                  // className={
                  //   postapitwo1.No_States_represented == ''
                  //     ? `form-control error`
                  //     : 'form-control'
                  // }
                  className="form-control"

                  onChange={handleChangetwo}
                  required={true}
                  maxLength={255}
                />
                <small>
                  <i>
                    This is automatically fetched for your allotted Startups
                  </i>
                </small>
              </div>
            </div>

            <div className="row mt-05 ">
              <div className="col-md-6 left-align">
                <label className="form-label">
                  <b>No. of SISFS startups in Proof of Concept stage</b>
                </label>
                <br />
                <input
                  min="0"
                  name="Number_startups_PoC_stage"
                  value={postapitwo1.Number_startups_PoC_stage}
                  // className={
                  //   postapitwo1.Number_startups_PoC_stage == ''
                  //     ? `form-control error`
                  //     : 'form-control'
                  // }
                  className="form-control"

                  onChange={handleChangetwo}
                  required={true}
                  maxLength={255}
                />
                <small>
                  <i>
                    as submitted in last Quarterly Review (for allotted
                    Startups); please enter 0 if not applicable
                  </i>
                </small>
              </div>
              <div className="col-md-6 left-align">
                <label className="form-label">
                  <b>No. of SISFS startups in Prototype Development Stage</b>
                </label>
                <br />
                <input
                  min="0"
                  name="Number_startups_Prototype"
                  value={postapitwo1.Number_startups_Prototype}
                  // className={
                  //   postapitwo1.Number_startups_Prototype == ''
                  //     ? `form-control error`
                  //     : 'form-control'
                  // }
                  className="form-control"

                  onChange={handleChangetwo}
                  required={true}
                  maxLength={255}
                />
                <small>
                  <i>
                    as submitted in last Quarterly Review (for allotted
                    Startups); please enter 0 if not applicable
                  </i>
                </small>
              </div>
            </div>

            <div className="row mt-05 ">
              <div className="col-md-6 left-align">
                <label className="form-label">
                  <b>No. of SISFS startups in Product Development stage</b>
                </label>
                <br />
                <input
                  min="0"
                  name="Number_startups_Product_Development"
                  value={postapitwo1.Number_startups_Product_Development}
                  // className={
                  //   postapitwo1.Number_startups_Product_Development == ''
                  //     ? `form-control error`
                  //     : 'form-control'
                  // }
                  className="form-control"

                  onChange={handleChangetwo}
                  required={true}
                  maxLength={255}
                />
                <small>
                  <i>
                    as submitted in last Quarterly Review (for allotted
                    Startups); please enter 0 if not applicable
                  </i>
                </small>
              </div>
              <div className="col-md-6 left-align">
                <label className="form-label">
                  <b>No. of SISFS startups in Field Trial stage</b>
                </label>
                <br />
                <br />
                <input
                  min="0"
                  name="Number_startups_Field_Trial"
                  value={postapitwo1.Number_startups_Field_Trial}
                  // className={
                  //   postapitwo1.Number_startups_Field_Trial == ''
                  //     ? `form-control error`
                  //     : 'form-control'
                  // }
                  className="form-control"

                  onChange={handleChangetwo}
                  required={true}
                  maxLength={255}
                />
                <small>
                  <i>
                    as submitted in last Quarterly Review (for allotted
                    Startups); please enter 0 if not applicable
                  </i>
                </small>
              </div>
            </div>

            <div className="row mt-05 ">
              <div className="col-md-6 left-align">
                <label className="form-label">
                  <b>No. of SISFS startups in Market Launch stage</b>
                </label>
                <br />
                <input
                  min="0"
                  name="Number_startups_Market"
                  value={postapitwo1.Number_startups_Market}
                  // className={
                  //   postapitwo1.Number_startups_Market == ''
                  //     ? `form-control error`
                  //     : 'form-control'
                  // }
                  className="form-control"

                  onChange={handleChangetwo}
                  required={true}
                  maxLength={255}
                />
                <small>
                  <i>
                    as submitted in last Quarterly Review (for allotted
                    Startups); please enter 0 if not applicable
                  </i>
                </small>
              </div>
              <div className="col-md-6 left-align">
                <label className="form-label">
                  <b>No. of SISFS startups in Other stage</b>
                </label>
                <br />
                <br />
                <input
                  min="0"
                  name="Number_startups_Other_Stage"
                  value={postapitwo1.Number_startups_Other_Stage}
                  // className={
                  //   postapitwo1.Number_startups_Other_Stage == ''
                  //     ? `form-control error`
                  //     : 'form-control'
                  // }
                  className="form-control"

                  onChange={handleChangetwo}
                  required={true}
                  maxLength={255}
                />
                <small>
                  <i>
                    as submitted in last Quarterly Review (for allotted
                    Startups); please enter 0 if not applicable
                  </i>
                </small>
              </div>
            </div>
            <hr />
            <div className="row mt-1-5">
              <div
                className="col-md-12 left-align"
                style={{
                  fontSize: '17px',
                  padding: '10px',
                  backgroundColor: '#f8f7f7',
                }}>
                <span
                  className="incubator-label-heading"
                  style={{ color: '#2b3f88' }}>
                  Details of general progress of SISFS Startups from the date of
                  reapplication
                </span>
              </div>
            </div>

            <div className="row mt-05 ">
              <div className="col-md-6 left-align">
                <label className="form-label">
                  <b>Cumulative External Funding Raised by SISFS Startups</b>
                </label>
                <br />
                <input
                  min="0"
                  name="Cumulative_External_Funding"
                  value={postapitwo1.Cumulative_External_Funding}
                  // className={
                  //   postapitwo1.Cumulative_External_Funding == ''
                  //     ? `form-control error`
                  //     : 'form-control'
                  // }
                  className="form-control"

                  onChange={handleChangetwo}
                  required={true}
                  maxLength={255}
                />
                <small>
                  <i>
                    as submitted in last Quarterly Review (for allotted
                    Startups); please enter 0 if not applicable
                  </i>
                </small>
              </div>
              <div className="col-md-6 left-align">
                <label className="form-label">
                  <b>No. of People Employed by the SISFS Startups</b>
                </label>
                <br />
                <input
                  min="0"
                  name="No_People_Employed_Startups"
                  value={postapitwo1.No_People_Employed_Startups}
                  // className={
                  //   postapitwo1.No_People_Employed_Startups == ''
                  //     ? `form-control error`
                  //     : 'form-control'
                  // }
                  className="form-control"

                  onChange={handleChangetwo}
                  required={true}
                  maxLength={255}
                />
                <small>
                  <i>
                    as submitted in last Quarterly Review (for allotted
                    Startups); please enter 0 if not applicable
                  </i>
                </small>
              </div>
            </div>

            <div className="row mt-05 ">
              <div className="col-md-6 left-align">
                <label className="form-label">
                  <b>Turnover of SISFS Startups in last financial year</b>
                </label>
                <br />
                <input
                  min="0"
                  name="Turnover_Startups_financial"
                  value={postapitwo1.Turnover_Startups_financial}
                  // className={
                  //   postapitwo1.Turnover_Startups_financial == ''
                  //     ? `form-control error`
                  //     : 'form-control'
                  // }
                  className="form-control"

                  onChange={handleChangetwo}
                  required={true}
                  maxLength={255}
                />
                <small>
                  <i>
                    as submitted in last Quarterly Review (for allotted
                    Startups); please enter 0 if not applicable
                  </i>
                </small>
              </div>
              <div className="col-md-6 left-align">
                <label className="form-label">
                  <b>GST Filed by SISFS Startups in last financial year</b>
                </label>
                <br />
                <input
                  min="0"
                  name="GST_Filed_Startups"
                  value={postapitwo1.GST_Filed_Startups}
                  // className={
                  //   postapitwo1.GST_Filed_Startups == ''
                  //     ? `form-control error`
                  //     : 'form-control'
                  // }
                  className="form-control"

                  onChange={handleChangetwo}
                  required={true}
                  maxLength={255}
                />
                <small>
                  <i>
                    as submitted in last Quarterly Review (for allotted
                    Startups); please enter 0 if not applicable
                  </i>
                </small>
              </div>
            </div>

            <div className="row mt-05 ">
              <div className="col-md-6 left-align">
                <label className="form-label">
                  <b>
                    No. of Products and Services by SISFS Startups Live on GeM
                    Portal
                  </b>
                </label>
                <br />
                <br />
                <input
                  min="0"
                  name="No_Products_Services_Startups"
                  value={postapitwo1.No_Products_Services_Startups}
                  // className={
                  //   postapitwo1.No_Products_Services_Startups == ''
                  //     ? `form-control error`
                  //     : 'form-control'
                  // }
                  className="form-control"

                  onChange={handleChangetwo}
                  required={true}
                  maxLength={255}
                />
                <small>
                  <i>
                    as submitted in last Quarterly Review (for allotted
                    Startups); please enter 0 if not applicable
                  </i>
                </small>
              </div>
              <div className="col-md-6 left-align">
                <label className="form-label">
                  <b>
                    No. of IP Registration Requests Filed by the SISFS Startups
                    under the Seed Fund Scheme
                  </b>
                </label>
                <br />
                <input
                  min="0"
                  name="No_Registration_Requests_Filed"
                  value={postapitwo1.No_Registration_Requests_Filed}
                  // className={
                  //   postapitwo1.No_Registration_Requests_Filed == ''
                  //     ? `form-control error`
                  //     : 'form-control'
                  // }
                  className="form-control"

                  onChange={handleChangetwo}
                  required={true}
                  maxLength={255}
                />
                <small>
                  <i>
                    as submitted in last Quarterly Review (for allotted
                    Startups); please enter 0 if not applicable
                  </i>
                </small>
              </div>
            </div>
            <hr />
            <div className="row mt-1-5">
              <div
                className="col-md-12 left-align"
                style={{
                  fontSize: '17px',
                  padding: '10px',
                  backgroundColor: '#f8f7f7',
                }}>
                <span
                  className="incubator-label-heading"
                  style={{ color: '#2b3f88' }}>
                  Details of funds committed, disbursed and return realized by
                  each instrument type from the date of reapplication
                </span>
              </div>
            </div>

            <div className="row mt-1-5">
              <div
                className="col-md-12 left-align"
                style={{
                  fontSize: '17px',
                  padding: '10px',
                  backgroundColor: '#f8f7f7',
                }}>
                <span
                  className="incubator-label-heading"
                  style={{ color: '#2b3f88' }}>
                  Breakdown of funds committed to SISFS Startups by instruments
                  type from the date of reapplication
                </span>
              </div>
            </div>

            <div className="row mt-05 ">
              <div className="col-md-6 left-align">
                <label className="form-label">
                  <b>Funds Committed as Grants</b>
                </label>
                <br />
                <br />
                <input
                  min="0"
                  name="Grants"
                  value={postapitwo1.Grants}
                  // className={
                  //   postapitwo1.Grants == ''
                  //     ? `form-control error`
                  //     : 'form-control'
                  // }
                  className="form-control"
                  onChange={handleChangetwo}
                  required={true}
                  maxLength={255}
                />
                <small>
                  <i>
                    as submitted in last Quarterly Review (for Startups with
                    whom agreements have been signed); please enter 0 if not
                    applicable
                  </i>
                </small>
              </div>
              <div className="col-md-6 left-align">
                <label className="form-label">
                  <b>Funds Committed as Convertible Debentures</b>
                </label>
                <br />
                <input
                  min="0"
                  name="Convertible_Debentures"
                  value={postapitwo1.Convertible_Debentures}
                  // className={
                  //   postapitwo1.Convertible_Debentures == ''
                  //     ? `form-control error`
                  //     : 'form-control'
                  // }
                  className="form-control"
                  onChange={handleChangetwo}
                  required={true}
                  maxLength={255}
                />
                <small>
                  <i>
                    as submitted in last Quarterly Review (for Startups with
                    whom agreements have been signed); please enter 0 if not
                    applicable
                  </i>
                </small>
              </div>
            </div>

            <div className="row mt-05 ">
              <div className="col-md-6 left-align">
                <label className="form-label">
                  <b>Funds Committed as Debt</b>
                </label>
                <br />
                <br />
                <input
                  min="0"
                  name="Debt"
                  value={postapitwo1.Debt}
                  // className={
                  //   postapitwo1.Debt == ''
                  //     ? `form-control error`
                  //     : 'form-control'
                  // }
                  className="form-control"
                  onChange={handleChangetwo}
                  required={true}
                  maxLength={255}
                />
                <small>
                  <i>
                    as submitted in last Quarterly Review (for Startups with
                    whom agreements have been signed); please enter 0 if not
                    applicable
                  </i>
                </small>
              </div>
              <div className="col-md-6 left-align">
                <label className="form-label">
                  <b>Funds Committed as Other Debt-Linked Instruments</b>
                </label>
                <br />
                <input
                  min="0"
                  name="Debt_Linked_Instruments"
                  value={postapitwo1.Debt_Linked_Instruments}
                  // className={
                  //   postapitwo1.Debt_Linked_Instruments == ''
                  //     ? `form-control error`
                  //     : 'form-control'
                  // }
                  className="form-control"
                  onChange={handleChangetwo}
                  required={true}
                  maxLength={255}
                />
                <small>
                  <i>
                    as submitted in last Quarterly Review (for Startups with
                    whom agreements have been signed); please enter 0 if not
                    applicable
                  </i>
                </small>
              </div>
            </div>
            <hr />
            <div className="row mt-1-5">
              <div
                className="col-md-12 left-align"
                style={{
                  fontSize: '17px',
                  padding: '10px',
                  backgroundColor: '#f8f7f7',
                }}>
                <span
                  className="incubator-label-heading"
                  style={{ color: '#2b3f88' }}>
                  Breakdown of funds disbursed to SISFS Startups by instruments
                  type from the date of reapplication
                </span>
              </div>
            </div>

            <div className="row mt-05 ">
              <div className="col-md-6 left-align">
                <label className="form-label">
                  <b>Funds Disbursed as Grants</b>
                </label>
                <br />
                <br />
                <input
                  min="0"
                  name="Funds_disbursed_Grants"
                  value={postapitwo1.Funds_disbursed_Grants}
                  // className={
                  //   postapitwo1.Funds_disbursed_Grants == ''
                  //     ? `form-control error`
                  //     : 'form-control'
                  // }
                  className="form-control"
                  onChange={handleChangetwo}
                  required={true}
                  maxLength={255}
                />
                <small>
                  <i>
                    as submitted in last Quarterly Review (for Startups with
                    whom agreements have been signed); please enter 0 if not
                    applicable
                  </i>
                </small>
              </div>
              <div className="col-md-6 left-align">
                <label className="form-label">
                  <b>Funds Disbursed as Convertible Debentures</b>
                </label>
                <br />
                <input
                  min="0"
                  name="disbursed_Convertible_Debentures"
                  value={postapitwo1.disbursed_Convertible_Debentures}
                  // className={
                  //   postapitwo1.disbursed_Convertible_Debentures == ''
                  //     ? `form-control error`
                  //     : 'form-control'
                  // }
                  className="form-control"
                  onChange={handleChangetwo}
                  required={true}
                  maxLength={255}
                />
                <small>
                  <i>
                    as submitted in last Quarterly Review (for Startups with
                    whom agreements have been signed); please enter 0 if not
                    applicable
                  </i>
                </small>
              </div>
            </div>

            <div className="row mt-05 ">
              <div className="col-md-6 left-align">
                <label className="form-label">
                  <b>Funds Disbursed as Debt</b>
                </label>
                <br />
                <br />
                <input
                  min="0"
                  name="disbursed_Debt"
                  value={postapitwo1.disbursed_Debt}
                  // className={
                  //   postapitwo1.disbursed_Debt == ''
                  //     ? `form-control error`
                  //     : 'form-control'
                  // }
                  className="form-control"
                  onChange={handleChangetwo}
                  required={true}
                  maxLength={255}
                />
                <small>
                  <i>
                    as submitted in last Quarterly Review (for Startups with
                    whom agreements have been signed); please enter 0 if not
                    applicable
                  </i>
                </small>
              </div>
              <div className="col-md-6 left-align">
                <label className="form-label">
                  <b>Funds Disbursed as Other Debt-Linked Instruments</b>
                </label>
                <br />
                <input
                  min="0"
                  name="disbursed_Debt_Linked_Instruments"
                  value={postapitwo1.disbursed_Debt_Linked_Instruments}
                  // className={
                  //   postapitwo1.disbursed_Debt_Linked_Instruments == ''
                  //     ? `form-control error`
                  //     : 'form-control'
                  // }
                  className="form-control"
                  onChange={handleChangetwo}
                  required={true}
                  maxLength={255}
                />
                <small>
                  <i>
                    as submitted in last Quarterly Review (for Startups with
                    whom agreements have been signed); please enter 0 if not
                    applicable
                  </i>
                </small>
              </div>
            </div>
            <hr />
            <div className="row mt-1-5">
              <div
                className="col-md-12 left-align"
                style={{
                  fontSize: '17px',
                  padding: '10px',
                  backgroundColor: '#f8f7f7',
                }}>
                <span
                  className="incubator-label-heading"
                  style={{ color: '#2b3f88' }}>
                  Breakdown of returns realized by instrument type from the date
                  of reapplication
                </span>
              </div>
            </div>

            <div className="row mt-05 ">
              <div className="col-md-6 left-align">
                <label className="form-label">
                  <b>
                    Total Interest on Debt/Debenture/Loan recorded till date
                    (Received + Due)
                  </b>
                </label>
                <br />
                <input
                  min="0"
                  name="Total_Interest_Debt_Due"
                  value={postapitwo1.Total_Interest_Debt_Due}
                  // className={
                  //   postapitwo1.Total_Interest_Debt_Due == ''
                  //     ? `form-control error`
                  //     : 'form-control'
                  // }
                  className="form-control"
                  onChange={handleChangetwo}
                  required={true}
                  maxLength={255}
                />
                <small>
                  <i>
                    as submitted in last Quarterly Review (for Startups with
                    whom agreements have been signed); please enter 0 if not
                    applicable
                  </i>
                </small>
              </div>
              <div className="col-md-6 left-align">
                <label className="form-label">
                  <b>
                    Total Interest on Debt/Debenture/Loan realised till date
                    (Received in Bank)
                  </b>
                </label>
                <br />
                <input
                  min="0"
                  name="Total_Interest_Debt_Bank"
                  value={postapitwo1.Total_Interest_Debt_Bank}
                  // className={
                  //   postapitwo1.Total_Interest_Debt_Bank == ''
                  //     ? `form-control error`
                  //     : 'form-control'
                  // }
                  className="form-control"
                  onChange={handleChangetwo}
                  required={true}
                  maxLength={255}
                />
                <small>
                  <i>
                    as submitted in last Quarterly Review (for Startups with
                    whom agreements have been signed); please enter 0 if not
                    applicable
                  </i>
                </small>
              </div>
            </div>

            <div className="row mt-05 ">
              <div className="col-md-6 left-align">
                <label className="form-label">
                  <b>
                    Debt/Debenture/Loan repaid till date (Principal Amount
                    Returned)
                  </b>
                </label>
                <br />
                <input
                  min="0"
                  name="Principal_Amount_Returned"
                  value={postapitwo1.Principal_Amount_Returned}
                  // className={
                  //   postapitwo1.Principal_Amount_Returned == ''
                  //     ? `form-control error`
                  //     : 'form-control'
                  // }
                  className="form-control"
                  onChange={handleChangetwo}
                  required={true}
                  maxLength={255}
                />
                <small>
                  <i>
                    as submitted in last Quarterly Review (for Startups with
                    whom agreements have been signed); please enter 0 if not
                    applicable
                  </i>
                </small>
              </div>
              <div className="col-md-6 left-align">
                <label className="form-label">
                  <b>Total Return on Investment (Cumulative Monetary Return)</b>
                </label>
                <br />
                <input
                  min="0"
                  name="Cumulative_Monetary_Return"
                  // value={postapitwo1.Cumulative_Monetary_Return}
                  value={average}
                  // className={
                  //   postapitwo1.Cumulative_Monetary_Return == ''
                  //     ? `form-control error`
                  //     : 'form-control'
                  // }
                  className="form-control"
                  onChange={handleChangetwo}
                  required={true}
                  maxLength={255}
                />
              </div>
            </div>
            </fieldset>
            {/* <div className="row mt-05 ">
        <div className="col-md-6 left-align">
              <span className="incubator-label-wrap">
                No. of testimonials and success stories from/of SISFS startups
              </span>
              <br />
              <input 
              min="0"
              name="No_Testimonials_Success"
              className="form-control"
              onChange={handleChange}
              required={true}
              maxLength={255}
              />
          </div>
          <div className="col-md-6 left-align">
          <span className="incubator-label-wrap">
            Self Declaration for violation of the SISFS guidelines
            </span>
            <br /> */}
            {/* {loader ?
              <div className="spinner-border custom-spinner loader-position" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            :
            (applicationGeneralDetailModel!= undefined && applicationGeneralDetailModel.uploaded_documents.Current_Incubator_Team_Upload[0].split(',') !=undefined
              ? applicationGeneralDetailModel.uploaded_documents.Current_Incubator_Team_Upload[0].split(',').map(
                  (file:any,index:any) => (
                    index < 2 
                    ?
                    <div className="row mt-05">
                      <div className="col-md-3">
                        { getFileDetails(
                              file,
                             'type'
                                          )== 'pdf' ? (
                          <img src={pdf} alt="SISFS Logo" />
                        ) :  getFileDetails(
                          file,
                         'type'
                                      ) == 'doc' ? (
                          <img src={doc} alt="SISFS Logo" />
                        ) :  getFileDetails(
                          file,
                         'type'
                                      )== 'xls' ? (
                          <img src={xls} alt="SISFS Logo" />
                        ) : (
                          <img src={Allround} alt="SISFS Logo" />
                        )}
                      </div>
                      <div className="col-md-9">
                        <span className="document-label-value-wrap">
                          { getFileDetails(
                              file,
                             'name'
                                          )}
                        </span>
                        <br />
                        <a
                          href={`${NODE_URL}`+file.substr(1)}
                          target="_blank"
                          className="incubator-details-link"
                        >
                          View
                        </a>
                        /
                        <a
                          href="#"
                          className="incubator-details-link"
                          onClick={() =>
                            downloadDocument(file)
                          }
                        >
                          Download
                        </a>
                      </div>
                    </div>
                    :""
                  )
                )
              : (applicationGeneralDetailModel.uploaded_documents.Current_Incubator_Team_Upload.length == 0) ? 'NA' : '')
              }

              
            { applicationGeneralDetailModel.uploaded_documents
              .Current_Incubator_Team_Upload[0].split(',').length > 2 ?
             <a
             className="incubator-details-link"
             data-toggle="modal"
             data-target="#showMore"
             onClick={() => setMeetingModal('Current_Incubator_Team_Upload')}
           >
             More...
           </a>
           :""}

            {applicationGeneralDetailModel.uploaded_documents.Current_Incubator_Team_Upload[0].split(',')
              .length == 0 ? (
              <span className="incubator-label-value"> -</span>
            ) : (
              ''
            )} */}
            {/* </div>
        </div> */}
            <br />
            <button className="btn btn-sisfs btn-yellow-primary mr-3" onClick={onEdit}>Edit</button>
            <button className="btn btn-sisfs btn-yellow-primary">Submit</button>
          </div>
        </form>
      ) : (
        ''
      )}

      <div className="incubator-hr">
        <hr />
      </div>

      <div className="row">
        <div
          id="q-5"
          className={
            activeIndexApp == '5'
              ? 'tranche-heading col-sm-11 left-align'
              : 'reports-heading col-sm-11 left-align'
          }>
          Fund Requirements Details
        </div>
        <div className="col-sm-1">
          {activeIndexApp == '5' ? (
            <div
              onClick={changeActiveBackApp}
              data-toggle="collapse"
              data-target="#fund-expand"
              role="button"
              aria-expanded="false"
              aria-controls="fund-expand"
              className="hide-icon pull-right hide-icon-details"
              id="show-5">
              <img src={Minus} className="img-fluid" alt="SISFS Logo" />
            </div>
          ) : (
            <div
              onClick={() => changeActiveApp('5')}
              id="hide-5"
              data-toggle="collapse"
              data-target="#fund-expand"
              role="button"
              aria-expanded="false"
              aria-controls="fund-expand"
              className="expand-icon pull-right expand-icon-details">
              <img src={Plus} className="img-fluid" alt="SISFS Logo" />
            </div>
          )}
        </div>
      </div>
      {activeIndexApp == '5' ? (
        <div className="collapse " id="fund-expand">
          <div className="row mt-05 ">
            <div className="col-md-6 left-align">
              <span className="incubator-label">Quantum of fund required</span>
              <br />
              {applicationGeneralDetailModel.quantum_fund_required_re_apply ==
                null ? (
                <span className="incubator-label-value">-</span>
              ) : (
                <span className="incubator-label-value">
                  &#8377;{' '}
                  {applicationGeneralDetailModel.quantum_fund_required_re_apply}
                </span>
              )}
            </div>
            <div className="col-md-6 left-align">
              <span className="incubator-label">
                Expected number of fund beneficiaries in year 1
              </span>
              <br />
              <span className="incubator-label-value">
                {applicationGeneralDetailModel.no_fund_benifitiaries_1yr_re_apply !=
                  null
                  ? applicationGeneralDetailModel.no_fund_benifitiaries_1yr_re_apply
                  : 0}
              </span>
            </div>
          </div>

          <div className="row mt-05 ">
            <div className="col-md-6 left-align">
              <span className="incubator-label">
                Expected number of fund beneficiaries in year 2
              </span>
              <br />
              <span className="incubator-label-value">
                {applicationGeneralDetailModel.no_fund_benifitiaries_2yr_re_apply !=
                  null
                  ? applicationGeneralDetailModel.no_fund_benifitiaries_2yr_re_apply
                  : 0}
              </span>
            </div>
            <div className="col-md-6 left-align">
              <span className="incubator-label">
                Expected number of fund beneficiaries in year 3
              </span>
              <br />
              <span className="incubator-label-value">
                {applicationGeneralDetailModel.no_fund_benifitiaries_3yr_re_apply !=
                  null
                  ? applicationGeneralDetailModel.no_fund_benifitiaries_3yr_re_apply
                  : 0}
              </span>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}

      {/* Show More  */}
      <div
        className="modal fade"
        id="showMore"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document">
          <div className="modal-content">
            <div className="modal-header modal-header-border-none ml-18px">
              <h5 className="modal-title " id="exampleModalLongTitle">
                More Files<span className="eac-name-pop-up"></span>
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="">
                {popUpName == 'pitchdeck_upload_fpath' ? (
                  applicationGeneralDetailModel.pitchdeck_upload_fpath !=
                    undefined ? (
                    applicationGeneralDetailModel.pitchdeck_upload_fpath
                      .split(',')
                      .map((file: any, index: any) => (
                        <div className="row mt-05">
                          <div className="col-md-3">
                            {getFileDetails(file, 'type') == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(file, 'type') == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(file, 'type') == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-9 left-align">
                            <span className="document-label-value-wrap">
                              {getFileDetails(file, 'name')}
                            </span>
                            <br />
                            <a
                              href={`${NODE_URL}` + file.substr(1)}
                              target="_blank"
                              className="incubator-details-link">
                              View
                            </a>
                            {/* /
                            <a
                              href="#"
                              className="incubator-details-link"
                              onClick={() => downloadDocument(file)}>
                              Download
                            </a> */}
                          </div>
                        </div>
                      ))
                  ) : (
                    <span className="incubator-label-value">-</span>
                  )
                ) : (
                  ''
                )}

                {popUpName == 'Performance_Upload_fpath'
                  ? applicationGeneralDetailModel.Performance_Upload_fpath !=
                    undefined
                    ? applicationGeneralDetailModel.Performance_Upload_fpath.split(
                      ',',
                    ).map((file: any, index: any) => (
                      <div className="row">
                        <div className="col-md-2">
                          {getFileDetails(file, 'type') == 'pdf' ? (
                            <img src={pdf} alt="SISFS Logo" />
                          ) : getFileDetails(file, 'type') == 'doc' ? (
                            <img src={doc} alt="SISFS Logo" />
                          ) : getFileDetails(file, 'type') == 'xls' ? (
                            <img src={xls} alt="SISFS Logo" />
                          ) : (
                            <img src={Allround} alt="SISFS Logo" />
                          )}
                        </div>
                        <div className="col-md-10 left-align">
                          <span className="document-label-value-wrap">
                            {getFileDetails(file, 'name')}
                          </span>
                          <br />
                          <a
                            href={`${NODE_URL}` + file.substr(1)}
                            target="_blank"
                            className="incubator-details-link">
                            View
                          </a>
                          {/* /
                          <a
                            href="#"
                            className="incubator-details-link"
                            onClick={() => downloadDocument(file)}>
                            Download
                          </a> */}
                        </div>
                      </div>
                    ))
                    : ''
                  : ''}
              </div>
              {popUpName == 'Other_doc_upload_fpath'
                ? applicationGeneralDetailModel.Other_doc_upload_fpath !=
                  undefined
                  ? applicationGeneralDetailModel.Other_doc_upload_fpath.split(
                    ',',
                  ).map((file: any) => (
                    <div className="row">
                      <div className="col-md-2">
                        {getFileDetails(file, 'type') == 'pdf' ? (
                          <img src={pdf} alt="SISFS Logo" />
                        ) : getFileDetails(file, 'type') == 'doc' ? (
                          <img src={doc} alt="SISFS Logo" />
                        ) : getFileDetails(file, 'type') == 'xls' ? (
                          <img src={xls} alt="SISFS Logo" />
                        ) : (
                          <img src={Allround} alt="SISFS Logo" />
                        )}
                      </div>
                      <div className="col-md-10 left-align">
                        <span className="document-label-value-wrap">
                          {getFileDetails(file, 'name')}
                        </span>
                        <br />
                        <a
                          href={`${NODE_URL}` + file.substr(1)}
                          target="_blank"
                          className="incubator-details-link">
                          View
                        </a>
                        {/* /
                        <a
                          href="#"
                          className="incubator-details-link"
                          onClick={() => downloadDocument(file)}>
                          Download
                        </a> */}
                      </div>
                    </div>
                  ))
                  : ''
                : ''}
              {/* {popUpName =="Audited_statement_expenditure_Upload" ?  applicationGeneralDetailModel.uploaded_documents.Audited_statement_expenditure_Upload[0] != undefined
                  ? applicationGeneralDetailModel.uploaded_documents.Audited_statement_expenditure_Upload[0].split(',').map(
                      (file:any) => (
                        <div className="row">
                          <div className="col-md-2">
                            {getFileDetails(
                              file,
                             'type'
                                          ) == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(
                              file,
                             'type'
                                          )== 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(
                              file,
                             'type'
                                          ) == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-10 left-align">
                            <span className="document-label-value-wrap">
                              {getFileDetails(
                              file,
                             'name'
                                          )}
                            </span>
                            <br />
                            <a
                              href={`${NODE_URL}`+file.substr(1)}
                              target="_blank"
                              className="incubator-details-link"
                            >
                              View
                            </a>
                            /
                            <a
                              href="#"
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file)
                              }
                            >
                              Download
                            </a>
                          </div>
                        </div>
                      )
                    )
                  : ''
                :""} */}

              {/* {popUpName =="Current_Incubator_Team_Upload" ? applicationGeneralDetailModel.uploaded_documents.Current_Incubator_Team_Upload[0] != undefined
                  ? applicationGeneralDetailModel.uploaded_documents.Current_Incubator_Team_Upload[0].split(',').map(
                      (file:any) => (
                        <div className="row">
                          <div className="col-md-2">
                            {getFileDetails(
                              file,
                             'type'
                                          )== 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(
                              file,
                             'type'
                                          )== 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(
                              file,
                             'type'
                                          ) == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-10 left-align">
                            <span className="document-label-value-wrap">
                              {getFileDetails(
                              file,
                             'name'
                                          )}
                            </span>
                            <br />
                            <a
                              href={`${NODE_URL}`+file.substr(1)}
                              target="_blank"
                              className="incubator-details-link"
                            >
                              View
                            </a>
                            /{' '}
                            <a
                              href="#"
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file)
                              }
                            >
                              Download
                            </a>
                          </div>
                        </div>
                      )
                    )
                  : ''
                :""} */}

              <div className="row">
                <div className="col-md-12 centerText">
                  <div className="btn-group mt-1-5 ">
                    <button
                      className="tertiary  btn-yellow-primary"
                      data-dismiss="modal">
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showIncubatorSuccessModal && (
        <ConfirmationDialog
          headerText={dialogData.header}
          bodyText={dialogData.text}
          onDialogClose={dismissModal}
        />
      )}
      {showIncubatorFailureModal && (
        <ConfirmationDialog
          headerText={failDialogData.header}
          bodyText={failDialogData.text}
          onDialogClose={() => setShowIncubatorFailureModal(false)}
          iconClass="test"
        />
      )}
    </div>
  );
}
export default ApplicationDetailsReapplyComponent;
