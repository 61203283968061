import '../incubator/incubator.css';
import Plus from '../../../images/Plus.svg';
import Minus from '../../../images/Minus.svg';
import { OthersModel } from './ApplicationGeneralDetails';
import { useEffect, useState } from 'react';
import pdf from '../../../images/PDF.svg';
import xls from '../../../images/xls.svg';
import doc from '../../../images/doc.svg';
import xlsx from '../../../images/xls.svg';
import Allround from '../../../images/Allround.svg';

interface IDocumentProps {
  otherDetailModel: OthersModel;
}

function DocumentComponent(props: IDocumentProps) {
  const { otherDetailModel } = props;
  //console.log(otherDetailModel, 'otherDetailModel');
  const [otherDetail, setOtherDetail] = useState<OthersModel>(
    new OthersModel([], [], [], [], [], [], [], [], [], [], [], [], [])
  );

  useEffect(() => {
    window.scroll(0, 0);
    setOtherDetail(otherDetailModel);
  }, []);

  const downloadDocument = (file: any, file_name: any) => {
    fetch(file).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = file_name;
        a.click();
      });
      //window.location.href = response.url;
    });
  };

  const getFileDetails = (file: any, key: string) => {
    let file_name = '';
    file_name =
      file != undefined && file != null && file.length > 1
        ? file.substr(file.lastIndexOf('/') + 1, file.length)
        : '';

        const file_type =  file != undefined && file != null && file.length > 1
        ? file.substr(
          file.lastIndexOf('.') + 1,
                            file.length
                          ):'';

    if (key == 'name') return file_name;
    if (key == 'file') return file != undefined ? file.toString() : '';
    if (key == 'type') return file != undefined ? file_type.toString() : '';
  };

  const [popUpName,setPopUpName]=useState("");
  const setMeetingModal=(fieldName:string)=>{
setPopUpName(fieldName);

  }


  const [activeIndexDoc, setActiveIndexDoc] = useState('');
  const changeActiveDoc = (id: string) => {
    setActiveIndexDoc(id);
  };
  const changeActiveBackDoc = () => {
    setActiveIndexDoc('');
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 left-align">
          <span className="eac-heading-title">Reports and Documents</span>
        </div>
      </div>

      <div className="incubator-hr">
        <hr />
      </div>

      <div className="row">
        <div
          id="q-1"
          className={
            activeIndexDoc == '1'
              ? 'tranche-heading col-sm-11 left-align'
              : 'reports-heading col-sm-11 left-align'
          }
        >
          Quarterly Reports
        </div>
        <div className="col-sm-1">
          {activeIndexDoc == '1' ? (
            <div
              onClick={changeActiveBackDoc}
              data-toggle="collapse"
              data-target="#qtrly-expand"
              role="button"
              aria-expanded="false"
              aria-controls="qtrly-expand"
              className="hide-icon pull-right hide-icon-details"
              id="show-1"
            >
              <img src={Minus} className="img-fluid" alt="SISFS Logo" />
            </div>
          ) : (
            <div
              id="hide-1"
              onClick={() => changeActiveDoc('1')}
              data-toggle="collapse"
              data-target="#qtrly-expand"
              role="button"
              aria-expanded="false"
              aria-controls="qtrly-expand"
              className="expand-icon pull-right expand-icon-details"
            >
              <img src={Plus} className="img-fluid" alt="SISFS Logo" />
            </div>
          )}
        </div>
      </div>
      {activeIndexDoc == '1' ? (
      <div className="collapse " id="qtrly-expand">
        <div className="row">
          <div className="col-md-4 left-align">
            {' '}
            <label className="incubator-label-value"> -</label>
          </div>
        </div>
      </div>
      ):""}
      <div className="incubator-hr">
        <hr />
      </div>

      <div className="row">
        <div
          id="q-2"
          className={
            activeIndexDoc == '2'
              ? 'tranche-heading col-sm-11 left-align'
              : 'reports-heading col-sm-11 left-align'
          }
        >
          Incubator Pitch Deck
        </div>
        <div className="col-sm-1">
          {activeIndexDoc == '2' ? (
            <div
              onClick={changeActiveBackDoc}
              data-toggle="collapse"
              data-target="#pick-deck-expand"
              role="button"
              aria-expanded="false"
              aria-controls="pick-deck-expand"
              className="hide-icon pull-right hide-icon-details"
              id="show-2"
            >
              <img src={Minus} className="img-fluid" alt="SISFS Logo" />
            </div>
          ) : (
            <div
              id="hide-2"
              onClick={() => changeActiveDoc('2')}
              data-toggle="collapse"
              data-target="#pick-deck-expand"
              role="button"
              aria-expanded="false"
              aria-controls="pick-deck-expand"
              className="expand-icon pull-right expand-icon-details"
            >
              <img src={Plus} className="img-fluid" alt="SISFS Logo" />
            </div>
          )}
        </div>
      </div>
      {activeIndexDoc == '2' ? (
      <div className="collapse " id="pick-deck-expand">
        <div className="row">
          <div className="col-md-8 left-align">
            {otherDetailModel.relavent_document_pitchdeck.length != 0 ? (
              ''
            ) : (
              <label className="incubator-label-value">-</label>
            )}

            <br />
            {otherDetailModel.relavent_document_pitchdeck.map((file,index) => (
              index<2?
              <div className="row">
                <div className="col-md-3">
                  {getFileDetails(
                              file.file,
                             'type'
                                          )== 'pdf' ? (
                    <img src={pdf} alt="SISFS Logo" />
                  ) : getFileDetails(
                    file.file,
                   'type'
                                )== 'doc' ? (
                    <img src={doc} alt="SISFS Logo" />
                  ) : getFileDetails(
                    file.file,
                   'type'
                                ) == 'xls' ? (
                    <img src={xls} alt="SISFS Logo" />
                  ) : (
                    <img src={Allround} alt="SISFS Logo" />
                  )}
                </div>
                <div className="col-md-9">
                  <span className="document-label-value-wrap">
                  {getFileDetails(
                              file.file,
                             'name'
                                          )}
                  </span>
                  <br />
                  <a
                    href={file.file}
                    target="_blank"
                  //download="PitchDeck"
                    className="incubator-details-link"
                  >
                    View
                  </a>
                  {/* /
                  <a
                    href="#"
                  //download={file.file}
                    className="incubator-details-link"
                    onClick={() => downloadDocument(file.file, file.file)}
                  >
                    Download
                  </a> */}
                </div>
              </div>
              :""
            ))}
            {otherDetailModel.relavent_document_pitchdeck.length >2 ?
             <a
             className="incubator-details-link"
             data-toggle="modal"
             data-target="#showMoreDocument"
             onClick={() => setMeetingModal('Pitch Deck')}
           >
             More...
           </a>
           :""}
          </div>
        </div>
      </div>
      ):""}
      <div className="incubator-hr">
        <hr />
      </div>

      <div className="row">
        <div
          id="q-3"
          className={
            activeIndexDoc == '3'
              ? 'tranche-heading col-sm-11 left-align'
              : 'reports-heading col-sm-11 left-align'
          }
        >
          Startup Details for the Last 3 Years
        </div>
        <div className="col-sm-1">
          {activeIndexDoc == '3' ? (
            <div
              onClick={changeActiveBackDoc}
              data-toggle="collapse"
              data-target="#startup-details-expand"
              role="button"
              aria-expanded="false"
              aria-controls="startup-details-expand"
              className="hide-icon pull-right hide-icon-details"
              id="show-3"
            >
              <img src={Minus} className="img-fluid" alt="SISFS Logo" />
            </div>
          ) : (
            <div
              onClick={() => changeActiveDoc('3')}
              id="hide-3"
              data-toggle="collapse"
              data-target="#startup-details-expand"
              role="button"
              aria-expanded="false"
              aria-controls="pstartup-details-expand"
              className="expand-icon pull-right expand-icon-details"
            >
              <img src={Plus} className="img-fluid" alt="SISFS Logo" />
            </div>
          )}
        </div>
      </div>
      {activeIndexDoc == '3' ? (
      <div className="collapse " id="startup-details-expand">
        <div className="row">
          <div className="col-md-4 left-align">
            <label className="incubator-label-value"> -</label>
          </div>
        </div>
      </div>
      ):""}
      <div className="incubator-hr">
        <hr />
      </div>

      <div className="row">
        <div
          id="q-4"
          className={
            activeIndexDoc == '4'
              ? 'tranche-heading col-sm-11 left-align'
              : 'reports-heading col-sm-11 left-align'
          }
        >
          Audited Annual Report for the Last 2 Years
        </div>
        <div className="col-sm-1">
          {activeIndexDoc == '4' ? (
            <div
              onClick={changeActiveBackDoc}
              data-toggle="collapse"
              data-target="#annual-report-expand"
              role="button"
              aria-expanded="false"
              aria-controls="annual-report-expand"
              className="hide-icon pull-right hide-icon-details"
              id="show-4"
            >
              <img src={Minus} className="img-fluid" alt="SISFS Logo" />
            </div>
          ) : (
            <div
              onClick={() => changeActiveDoc('4')}
              id="hide-4"
              data-toggle="collapse"
              data-target="#annual-report-expand"
              role="button"
              aria-expanded="false"
              aria-controls="annual-report-expand"
              className="expand-icon pull-right expand-icon-details"
            >
              <img src={Plus} className="img-fluid" alt="SISFS Logo" />
            </div>
          )}
        </div>
      </div>
      {activeIndexDoc == '4' ? (
      <div className="collapse" id="annual-report-expand">
        <div className="row">
          <div className="col-md-8 left-align">
            {otherDetailModel.annual_report.length != 0 ? (
              ''
            ) : (
              <label className="document-label-value-wrap"> -</label>
            )}
            <br />
            {otherDetailModel.annual_report.map((file, index) => (
              index <2 ? 
              <div className="row">
                <div className="col-md-3">
                  {getFileDetails(
                              file.file,
                             'type'
                                          )== 'pdf' ? (
                    <img src={pdf} alt="SISFS Logo" />
                  ) : getFileDetails(
                    file.file,
                   'type'
                                ) == 'doc' ? (
                    <img src={doc} alt="SISFS Logo" />
                  ) : getFileDetails(
                    file.file,
                   'type'
                                )== 'xls' ? (
                    <img src={xls} alt="SISFS Logo" />
                  ) : (
                    <img src={Allround} alt="SISFS Logo" />
                  )}
                </div>
                <div className="col-md-9">
                  <span className="document-label-value-wrap">
                  {getFileDetails(
                              file.file,
                             'name'
                                          )}
                  </span>
                  <br />
                  <a
                    href={file.file}
                    target="_blank"
                  //download="AnnualReport"
                    className="incubator-details-link"
                  >
                    View
                  </a>
                  {/* /
                  <a
                    href="#"
                  //download={file.file}
                    className="incubator-details-link"
                    onClick={() => downloadDocument(file.file, file.file)}
                  >
                    Download
                  </a> */}
                </div>
              </div>
              :""
            ))}

{otherDetailModel.annual_report.length >2 ?
             <a
             className="incubator-details-link"
             data-toggle="modal"
             data-target="#showMoreDocument"
             onClick={() => setMeetingModal('Annual Report')}
           >
             More...
           </a>
           :""}

          </div>
        </div>
      </div>
      ):""}
      <div className="incubator-hr">
        <hr />
      </div>

      <div className="row">
        <div
          id="q-5"
          className={
            activeIndexDoc == '5'
              ? 'tranche-heading col-sm-11 left-align'
              : 'reports-heading col-sm-11 left-align'
          }
        >
          Relevant
        </div>
        <div className="col-sm-1">
          {activeIndexDoc == '5' ? (
            <div
              onClick={changeActiveBackDoc}
              data-toggle="collapse"
              data-target="#relevant-expand"
              role="button"
              aria-expanded="false"
              aria-controls="relevant-expand"
              className="hide-icon pull-right hide-icon-details"
              id="show-5"
            >
              <img src={Minus} className="img-fluid" alt="SISFS Logo" />
            </div>
          ) : (
            <div
              onClick={() => changeActiveDoc('5')}
              id="hide-5"
              data-toggle="collapse"
              data-target="#relevant-expand"
              role="button"
              aria-expanded="false"
              aria-controls="relevant-expand"
              className="expand-icon pull-right expand-icon-details"
            >
              <img src={Plus} className="img-fluid" alt="SISFS Logo" />
            </div>
          )}
        </div>
      </div>
      {activeIndexDoc == '5' ? (
      <div className="collapse" id="relevant-expand">
        <div className="row ">
          {otherDetailModel.relavent_document_excel.length != 0 ? (
            ''
          ) : (
            <div className="col-md-3 left-align">
              <label className="incubator-label-value"> -</label>
            </div>
          )}

          <br />
          {otherDetailModel.relavent_document_excel.map((file,index) => (
            index<2?
            <div className="col-md-8 left-align">
              <div className="row">
                <div className="col-md-3">
                  {getFileDetails(
                              file.file,
                             'type'
                                          )== 'pdf' ? (
                    <img src={pdf} alt="SISFS Logo" />
                  ) : getFileDetails(
                    file.file,
                   'type'
                                )== 'doc' ? (
                    <img src={doc} alt="SISFS Logo" />
                  ) : getFileDetails(
                    file.file,
                   'type'
                                ) == 'xls' ? (
                    <img src={xls} alt="SISFS Logo" />
                  ) : (
                    <img src={Allround} alt="SISFS Logo" />
                  )}
                </div>
                <div className="col-md-9">
                  <span className="document-label-value-wrap">
                {getFileDetails(
                            file.file,
                            'name'
                                        )}
                  </span>
                  <br />
                  <a
                    href={file.file}
                    target="_blank"
                  //download="RelaventDocumentExcel"
                    className="incubator-details-link"
                  >
                    View
                  </a>
                  {/* /
                  <a
                    href="#"
                  //download={file.file}
                    className="incubator-details-link"
                    onClick={() => downloadDocument(file.file, file.file)}
                  >
                    Download
                  </a> */}
                </div>
              </div>
            </div>
            :""
          ))}

{otherDetailModel.relavent_document_excel.length >2 ?
             <a
             className="incubator-details-link"
             data-toggle="modal"
             data-target="#showMoreDocument"
             onClick={() => setMeetingModal('Relevant Document Excel')}
           >
             More...
           </a>
           :""}


          <br />
          {otherDetailModel.relavent_document_other.map((file, index) => (
            index<2?
            <div className="col-md-8 left-align">
              <div className="row">
                <div className="col-md-3">
                  {getFileDetails(
                              file.file,
                             'type'
                                          )== 'pdf' ? (
                    <img src={pdf} alt="SISFS Logo" />
                  ) : getFileDetails(
                    file.file,
                   'type'
                                ) == 'doc' ? (
                    <img src={doc} alt="SISFS Logo" />
                  ) :getFileDetails(
                    file.file,
                   'type'
                                ) == 'xls' ? (
                    <img src={xls} alt="SISFS Logo" />
                  ) : (
                    <img src={Allround} alt="SISFS Logo" />
                  )}
                </div>
                <div className="col-md-9">
                  <span className="document-label-value-wrap">
                 { getFileDetails(
                              file.file,
                             'name'
                                          )}
                  </span>
                  <br />
                  <a
                    href={file.file}
                    target="_blank"
                  //download="RelaventDocumentOther"
                    className="incubator-details-link"
                  >
                    View
                  </a>
                  {/* /
                  <a
                    href="#"
                  //download={file.file}
                    className="incubator-details-link"
                    onClick={() => downloadDocument(file.file, file.file)}
                  >
                    Download
                  </a> */}
                </div>
              </div>
            </div>
            :""
          ))}
{otherDetailModel.relavent_document_other.length >2 ?
             <a
             className="incubator-details-link"
             data-toggle="modal"
             data-target="#showMoreDocument"
             onClick={() => setMeetingModal('Relavent Document Other')}
           >
             More...
           </a>
           :""}

          {otherDetailModel.team_members_cv.map((file,index) => (
            index <2? 
            <div className="col-md-8 left-align">
              <div className="row">
                <div className="col-md-3">
                  {getFileDetails(
                              file.file,
                             'type'
                                          ) == 'pdf' ? (
                    <img src={pdf} alt="SISFS Logo" />
                  ) : getFileDetails(
                    file.file,
                   'type'
                                ) == 'doc' ? (
                    <img src={doc} alt="SISFS Logo" />
                  ) : getFileDetails(
                    file.file,
                   'type'
                                ) == 'xls' ? (
                    <img src={xls} alt="SISFS Logo" />
                  ) : (
                    <img src={Allround} alt="SISFS Logo" />
                  )}
                </div>
                <div className="col-md-9">
                  <span className="document-label-value-wrap">
                    {getFileDetails(
                              file.file,
                             'name'
                                          )}{' '}
                  </span>
                  <br />
                  <a
                    href={file.file}
                    target="_blank"
                  //download="team_members_cv"
                    className="incubator-details-link"
                  >
                    View
                  </a>
                  {/* /
                  <a
                    href="#"
                  //download={file.file}
                    className="incubator-details-link"
                    onClick={() => downloadDocument(file.file, file.file)}
                  >
                    Download
                  </a> */}
                </div>
              </div>
            </div>
            :""

          ))}

{otherDetailModel.team_members_cv.length >2 ?
             <a
             className="incubator-details-link"
             data-toggle="modal"
             data-target="#showMoreDocument"
             onClick={() => setMeetingModal('Team Member CV')}
           >
             More...
           </a>
           :""}

        </div>
      </div>
      ):""}

      <div className="incubator-hr">
        <hr />
      </div>
            {/* Show More  */}
      <div
          className="modal fade"
          id="showMoreDocument"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header modal-header-border-none ml-18px">
                <h5 className="modal-title " id="exampleModalLongTitle">
                 {popUpName} <span className="eac-name-pop-up"></span>
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="">
           


{popUpName =="Annual Report" ? 
                      
                      otherDetailModel.annual_report != undefined ? (
                        otherDetailModel.annual_report.map(
                          (file,index) => (
                           
                            <div className="row mt-05">
                              <div className="col-md-3">
                                {getFileDetails(
                              file.file,
                             'type'
                                          )== 'pdf' ? (
                                  <img src={pdf} alt="SISFS Logo" />
                                ) : getFileDetails(
                                  file.file,
                                 'type'
                                              )== 'doc' ? (
                                  <img src={doc} alt="SISFS Logo" />
                                ) : getFileDetails(
                                  file.file,
                                 'type'
                                              ) == 'xls' ? (
                                  <img src={xls} alt="SISFS Logo" />
                                ) : (
                                  <img src={Allround} alt="SISFS Logo" />
                                )}
                              </div>
                              <div className="col-md-9 left-align">
                                <span className="document-label-value-wrap">
                                  {getFileDetails(
                              file.file,
                             'name'
                                          )}
                                </span>
                                <br />
                                <a
                                  href={file.file}
                                  target="_blank"
                                //download="annual_report"
                                  className="incubator-details-link"
                                >
                                  View
                                </a>
                                {/* /
                                <a
                                  href="#"
                                //download={file.file}
                                  className="incubator-details-link"
                                  onClick={() =>
                                    downloadDocument(file.file, file.file)
                                  }
                                >
                                  Download
                                </a> */}
                              </div>
                            </div>
                           
                          )
                        )
                      ) : (
                        <span className="incubator-label-value">-</span>
                      )
                                  
           :
           ""}


{popUpName =="Relevant Document Excel" ? 
otherDetailModel.relavent_document_excel != undefined
                  ? otherDetailModel.relavent_document_excel.map(
                      (file,index) => (
                        
                        <div className="row">
                          <div className="col-md-2">
                            {getFileDetails(
                              file.file,
                             'type'
                                          )== 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(
                              file.file,
                             'type'
                                          ) == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(
                              file.file,
                             'type'
                                          ) == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-10 left-align">
                            <span className="document-label-value-wrap">
                              {getFileDetails(
                              file.file,
                             'name'
                                          )}
                            </span>
                            <br />
                            <a
                              href={file.file}
                              target="_blank"
                            //download="RelaventExcel"
                              className="incubator-details-link"
                            >
                              View
                            </a>
                            {/* /
                            <a
                              href="#"
                            //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                          </div>
                        </div>
                       
                      )
                    )
                  : ''
                :""}
                </div>
                {popUpName =="Team Member CV" ? otherDetailModel.team_members_cv != undefined
                  ? otherDetailModel.team_members_cv.map(
                      (file) => (
                        <div className="row">
                          <div className="col-md-2">
                            {getFileDetails(
                              file.file,
                             'type'
                                          ) == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(
                              file.file,
                             'type'
                                          )== 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(
                              file.file,
                             'type'
                                          ) == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-10 left-align">
                            <span className="document-label-value-wrap">
                              {getFileDetails(
                              file.file,
                             'name'
                                          )}
                            </span>
                            <br />
                            <a
                              href={file.file}
                              target="_blank"
                            //download="TeamMember"
                              className="incubator-details-link"
                            >
                              View
                            </a>
                            {/* /
                            <a
                              href="#"
                            //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                          </div>
                        </div>
                      )
                    )
                  : ''
                :""}
 {popUpName =="Pitch Deck" ?  otherDetailModel.relavent_document_pitchdeck != undefined
                  ? otherDetailModel.relavent_document_pitchdeck.map(
                      (file) => (
                        <div className="row">
                          <div className="col-md-2">
                            {getFileDetails(
                              file.file,
                             'type'
                                          ) == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(
                              file.file,
                             'type'
                                          )== 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(
                              file.file,
                             'type'
                                          ) == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-10 left-align">
                            <span className="document-label-value-wrap">
                              {getFileDetails(
                              file.file,
                             'name'
                                          )}
                            </span>
                            <br />
                            <a
                              href={file.file}
                              target="_blank"
                            //download="PitchDeck"
                              className="incubator-details-link"
                            >
                              View
                            </a>
                            {/* /
                            <a
                              href="#"
                            //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                          </div>
                        </div>
                      )
                    )
                  : ''
                :""}


{popUpName =="Relavent Document Other" ? otherDetailModel.relavent_document_other != undefined
                  ? otherDetailModel.relavent_document_other.map(
                      (file) => (
                        <div className="row">
                          <div className="col-md-2">
                            {getFileDetails(
                              file.file,
                             'type'
                                          )== 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(
                              file.file,
                             'type'
                                          )== 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(
                              file.file,
                             'type'
                                          ) == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-10 left-align">
                            <span className="document-label-value-wrap">
                              {getFileDetails(
                              file.file,
                             'name'
                                          )}
                            </span>
                            <br />
                            <a
                              href={file.file}
                              target="_blank"
                            //download="Relavent"
                              className="incubator-details-link"
                            >
                              View
                            </a>
                            {/* /{' '}
                            <a
                              href="#"
                            //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                          </div>
                        </div>
                      )
                    )
                  : ''
                :""}

                <div className="row">
                  <div className="col-md-12 centerText">
                    <div className="btn-group mt-1-5 ">
                      <button
                        className="tertiary  btn-yellow-primary"
                        data-dismiss="modal"
                      >
                        OK
                      </button>

                   
                    </div>
                  </div>
                </div>

               
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}
export default DocumentComponent;
