import React, { useEffect, useState } from "react";
import { StartupDetailModel } from "../../../models/startup/StartupDetailModel";
import { initStartupDetail } from "../../../models/startup/StartupModel";
import { requestType, StepperStartupEnum } from "../../../utils/Enums";
import { useErrorStateValue } from "../../../providers/ErrorProvider";
import ValidationMessage from "../../common/controls/ValidationMessage";
import { useHistory } from "react-router-dom";

interface IStartupDetailsProps {
  stepper: any;
  startupDetailModel: StartupDetailModel;
  tabIndex: number;
  onStepSave: (
    startupDetails: StartupDetailModel,
    stepIndex: number,
    request: number
  ) => void;
  onChildUpdate: (startupDetails: StartupDetailModel) => void;
  onPreviousClick: () => void;
  errors: Array<string>;
  disabledForm: any;
}

interface IStartupDetailsState {}

/** Render start-up application screen */
function StartupDetailsComponent(props: IStartupDetailsProps) {
  const { stepper, onChildUpdate, startupDetailModel, disabledForm } = props;

  // @ts-ignore
  const [{ errors }, dispatchError] = useErrorStateValue();

  const [startupDetails, setStartupDetails] =
    useState<StartupDetailModel>(initStartupDetail);

  useEffect(() => {
    setStartupDetails(startupDetailModel);
  }, []);

  // triggers whenever user makes any changes in the form for select and input type
  const handleChange = (
    evt: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const name = evt.target.name;
    const value = evt.target.value;

    // setStartupDetails((prevState: StartupDetailModel) => {
    //     return {
    //         ...prevState,
    //         [name]: value ? value : "",
    //     };
    // });
    let updatedStartupDetail: StartupDetailModel = initStartupDetail;

    updatedStartupDetail = {
      ...startupDetails,
      [name]: value,
    };
    setLocalStateAndUpdateParent(updatedStartupDetail);
  };

  const handleChangeAmount = (
    evt: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const name = evt.target.name;
    const value = evt.target.value
      .replace(/[^0-9.]/gi, "")
      .toString()
      .split(".")
      .map((el, i) => (i ? el.split("").slice(0, 2).join("") : el))
      .join(".");

    // setStartupDetails((prevState: StartupDetailModel) => {
    //     return {
    //         ...prevState,
    //         [name]: value ? value : "",
    //     };
    // });
    let updatedStartupDetail: StartupDetailModel = initStartupDetail;

    updatedStartupDetail = {
      ...startupDetails,
      [name]: value,
    };
    setLocalStateAndUpdateParent(updatedStartupDetail);
  };

  const history = useHistory();

  const applicationPage = () => {
    history.push({
      pathname: "/startup_status",
    });
  };

  const handleOnClick = (evt: any) => {
    const name = evt.target.name;
    let link: string = "";
    if (name === "facebook_enable") link = "facebook_link";
    else if (name === "linkedin_enable") link = "linkedin_link";
    else if (name === "twitter_enable") link = "twitter_link";
    else if (name === "youtube_enable") link = "youtube_link";
    else if (name === "other_enable") link = "other_link";
    else if (name === "competitors_startup_enable")
      link = "competitors_startup_desc";
    else if (name === "competitors_msme_enable") link = "competitors_msme_desc";
    else if (name === "competitors_corporate_enable")
      link = "competitors_corporate_desc";
    else if (name === "competitors_other_enable")
      link = "competitors_other_desc";

    // else link = "other_link";

    let startupDetail = JSON.parse(JSON.stringify(startupDetails));

    startupDetail[name] = evt.target.checked;
    startupDetail[link] = evt.target.checked ? startupDetail[link] : "";

    setStartupDetails(startupDetail);
    setLocalStateAndUpdateParent(startupDetail);
  };

  const setLocalStateAndUpdateParent = (
    updatedStartupDetail: StartupDetailModel
  ) => {
    setStartupDetails(updatedStartupDetail);

    setTimeout(() => {
      onChildUpdate(updatedStartupDetail);
    }, 0);
  };

  return (
    <>
      <fieldset disabled={disabledForm}>
        <div className="form-content">
          <div className="form-group required row">
            <label className="col-sm-4 col-form-label">
              Is it a Technology Startup?
            </label>
            <div className="col-sm-8">
              <div className="btn-group btn-group-toggle">
                <label
                  className={
                    startupDetails.is_technology_startup === "Yes"
                      ? "btn active"
                      : "btn"
                  }
                >
                  <input
                    type="radio"
                    name="is_technology_startup"
                    id="is_technology_startup1"
                    value="Yes"
                    onChange={handleChange}
                    checked={startupDetails.is_technology_startup === "Yes"}
                    readOnly
                  />
                  Yes
                </label>
                <label
                  className={
                    startupDetails.is_technology_startup === "No"
                      ? "btn active"
                      : "btn"
                  }
                >
                  <input
                    type="radio"
                    name="is_technology_startup"
                    id="is_technology_startup2"
                    value="No"
                    onChange={handleChange}
                    checked={startupDetails.is_technology_startup === "No"}
                    readOnly
                  />
                  No
                </label>
              </div>
              {
                <small
                  className={
                    startupDetails.is_technology_startup === "No"
                      ? "red-text"
                      : ""
                  }
                >
                  Startups should be using technology in it’s core product or
                  service or business model or distribution model or methodology
                  to solve the problem being targetted
                </small>
              }
              {errors &&
                errors.StartupDetail &&
                errors.StartupDetail["is_technology_startup"].length > 0 && (
                  <ValidationMessage
                    className="error"
                    message={errors.StartupDetail["is_technology_startup"]}
                  />
                )}
            </div>
          </div>

          <div className="form-group required row">
            <label className="col-sm-4 col-form-label">
              What is the problem you are solving?
            </label>
            <div className="col-sm-8">
              <textarea
                className={
                  errors &&
                  errors.StartupDetail &&
                  errors.StartupDetail["solving_problem_for"].length > 0
                    ? "form-control error"
                    : "form-control"
                }
                rows={3}
                placeholder="Describe in Max. 1000 characters"
                name="solving_problem_for"
                value={startupDetails.solving_problem_for}
                onChange={handleChange}
                required={true}
                maxLength={1000}
              ></textarea>
              {errors &&
                errors.StartupDetail &&
                errors.StartupDetail["solving_problem_for"].length > 0 && (
                  <ValidationMessage
                    className="error"
                    message={errors.StartupDetail["solving_problem_for"]}
                  />
                )}
            </div>
          </div>

          <div className="form-group required row">
            <label className="col-sm-4 col-form-label">
              What is your value proposition for this problem?
            </label>
            <div className="col-sm-8">
              <textarea
                className={
                  errors &&
                  errors.StartupDetail &&
                  errors.StartupDetail["value_proposition_for_problem"].length >
                    0
                    ? "form-control error"
                    : "form-control"
                }
                rows={3}
                placeholder="Describe in Max. 1000 characters"
                name="value_proposition_for_problem"
                value={startupDetails.value_proposition_for_problem}
                onChange={handleChange}
                required={true}
                maxLength={1000}
              ></textarea>
              {errors &&
                errors.StartupDetail &&
                errors.StartupDetail["value_proposition_for_problem"].length >
                  0 && (
                  <ValidationMessage
                    className="error"
                    message={
                      errors.StartupDetail["value_proposition_for_problem"]
                    }
                  />
                )}
            </div>
          </div>

          <div className="form-group required row">
            <label className="col-sm-4 col-form-label">
              What is your unique selling point?
            </label>
            <div className="col-sm-8">
              <textarea
                className={
                  errors &&
                  errors.StartupDetail &&
                  errors.StartupDetail["unique_selling_point"].length > 0
                    ? "form-control error"
                    : "form-control"
                }
                rows={3}
                placeholder="Describe in Max. 1000 characters"
                name="unique_selling_point"
                value={startupDetails.unique_selling_point}
                onChange={handleChange}
                required={true}
                maxLength={1000}
              ></textarea>
              {errors &&
                errors.StartupDetail &&
                errors.StartupDetail["unique_selling_point"].length > 0 && (
                  <ValidationMessage
                    className="error"
                    message={errors.StartupDetail["unique_selling_point"]}
                  />
                )}
            </div>
          </div>

          <div className="form-group required row">
            <label className="col-sm-4 col-form-label">
              What is your target customer segment?
            </label>
            <div className="col-sm-8">
              <textarea
                className={
                  errors &&
                  errors.StartupDetail &&
                  errors.StartupDetail["target_customer_segment"].length > 0
                    ? "form-control error"
                    : "form-control"
                }
                rows={3}
                placeholder="Describe in Max. 1000 characters"
                name="target_customer_segment"
                value={startupDetails.target_customer_segment}
                onChange={handleChange}
                required={true}
                maxLength={1000}
              ></textarea>
              {errors &&
                errors.StartupDetail &&
                errors.StartupDetail["target_customer_segment"].length > 0 && (
                  <ValidationMessage
                    className="error"
                    message={errors.StartupDetail["target_customer_segment"]}
                  />
                )}
            </div>
          </div>

          <div className="form-group required row">
            <label className="col-sm-4 col-form-label">
              What is the market size of the opportunity?
            </label>
            <div className="col-sm-4">
              <input
                type="text"
                name="market_size_opportunity"
                className={
                  errors &&
                  errors.StartupDetail &&
                  errors.StartupDetail["market_size_opportunity"].length > 0
                    ? "form-control error"
                    : "form-control"
                }
                placeholder="Enter (&#8377;)"
                value={startupDetails.market_size_opportunity}
                onChange={handleChangeAmount}
                required={true}
              />
              {errors &&
                errors.StartupDetail &&
                errors.StartupDetail["market_size_opportunity"].length > 0 && (
                  <ValidationMessage
                    className="error"
                    message={errors.StartupDetail["market_size_opportunity"]}
                  />
                )}
            </div>
            <div className="col-sm-4 pl-1">
              <small>Enter in (&#8377;)</small>
            </div>
          </div>

          <div className="form-group required row">
            <label className="col-sm-4 col-form-label">
              How do you aim to scale-up?
            </label>
            <div className="col-sm-8">
              <textarea
                className={
                  errors &&
                  errors.StartupDetail &&
                  errors.StartupDetail["aim_to_scale_up"].length > 0
                    ? "form-control error"
                    : "form-control"
                }
                rows={3}
                placeholder="Describe in Max. 1000 characters"
                name="aim_to_scale_up"
                value={startupDetails.aim_to_scale_up}
                onChange={handleChange}
                required={true}
                maxLength={1000}
              ></textarea>
              {errors &&
                errors.StartupDetail &&
                errors.StartupDetail["aim_to_scale_up"].length > 0 && (
                  <ValidationMessage
                    className="error"
                    message={errors.StartupDetail["aim_to_scale_up"]}
                  />
                )}
            </div>
          </div>

          <div className="form-group required row">
            <label className="col-sm-4 col-form-label">
              What will be the revenue <br />
              model?
            </label>
            <div className="col-sm-8">
              <textarea
                className={
                  errors &&
                  errors.StartupDetail &&
                  errors.StartupDetail["revenue_model"].length > 0
                    ? "form-control error"
                    : "form-control"
                }
                rows={3}
                placeholder="Describe in Max. 1000 characters"
                name="revenue_model"
                value={startupDetails.revenue_model}
                onChange={handleChange}
                required={true}
                maxLength={1000}
              ></textarea>
              <small>
                <b>Revenue Model:</b> It is a framework for generating financial
                income and identifies the sources of revenue for a product or
                service.
              </small>
              {errors &&
                errors.StartupDetail &&
                errors.StartupDetail["revenue_model"].length > 0 && (
                  <ValidationMessage
                    className="error"
                    message={errors.StartupDetail["revenue_model"]}
                  />
                )}
            </div>
          </div>

          {/* <div className="form-group required row">
    <div className="col-sm-4 ">
        <label className="col-form-label">
            Who are your key competitors?
        </label>
        <small className="pt-0">e.g: Startups, MSMEs, Corporates etc.</small>
    </div>

    <div className="col-sm-6">
        <div className="select-custom">
            <select
                className={
                    errors &&
                        errors.StartupDetail &&
                        errors.StartupDetail["key_competitors"].length > 0
                        ? "form-control error"
                        : "form-control"
                }
                aria-label="Select Competitors"
                id="key_competitors"
                name="key_competitors"
                value={startupDetails?.key_competitors}
                onChange={handleChange}
            >
                <option value="">Select Competitors</option>

            </select>
        </div>
        {errors &&
            errors.StartupDetail &&
            errors.StartupDetail["key_competitors"].length > 0 && (
                <ValidationMessage
                    className="error"
                    message={errors.StartupDetail["key_competitors"]}
                />
            )}

    </div>
</div> */}

          <div className="form-group required row">
            <div className="col-sm-4">
              <label className="col-form-label">
                Who are your key competitors?
              </label>
              <small className="pt-0">
                e.g: Startups, MSMEs, Corporates etc.
              </small>
            </div>
            <div className="col-sm-8">
              <div className="form-row">
                <div className="col-12">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id="competitors_startup_enable"
                      name="competitors_startup_enable"
                      className="custom-control-input"
                      onClick={(event: any) => {
                        handleOnClick(event);
                      }}
                      checked={startupDetails.competitors_startup_enable}
                      readOnly
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="competitors_startup_enable"
                    >
                      Startups
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  {startupDetails.competitors_startup_enable && (
                    <>
                      <textarea
                        id="competitors_startup_desc"
                        name="competitors_startup_desc"
                        className={
                          errors &&
                          errors.StartupDetail &&
                          errors.StartupDetail["competitors_startup_desc"]
                            .length > 0
                            ? "form-control error"
                            : "form-control"
                        }
                        // className="form-control"
                        placeholder="Describe in Max. 500 characters"
                        value={startupDetails["competitors_startup_desc"]}
                        onChange={handleChange}
                        rows={2}
                        required={true}
                        maxLength={500}
                      />

                      {errors &&
                        errors.StartupDetail &&
                        errors.StartupDetail["competitors_startup_desc"]
                          .length > 0 && (
                          <ValidationMessage
                            className="error"
                            message={
                              errors.StartupDetail["competitors_startup_desc"]
                            }
                          />
                        )}
                    </>
                  )}
                </div>
              </div>

              <div className="form-row">
                <div className="col-12">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id="competitors_msme_enable"
                      name="competitors_msme_enable"
                      className="custom-control-input"
                      onClick={(event: any) => {
                        handleOnClick(event);
                      }}
                      checked={startupDetails.competitors_msme_enable}
                      readOnly
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="competitors_msme_enable"
                    >
                      MSMEs
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  {startupDetails.competitors_msme_enable && (
                    <>
                      <textarea
                        id="competitors_msme_desc"
                        name="competitors_msme_desc"
                        className={
                          errors &&
                          errors.StartupDetail &&
                          errors.StartupDetail["competitors_msme_desc"].length >
                            0
                            ? "form-control error"
                            : "form-control"
                        }
                        placeholder="Describe in Max. 500 characters"
                        value={startupDetails["competitors_msme_desc"]}
                        onChange={handleChange}
                        rows={2}
                        required={true}
                        maxLength={500}
                      />
                      {errors &&
                        errors.StartupDetail &&
                        errors.StartupDetail["competitors_msme_desc"].length >
                          0 && (
                          <ValidationMessage
                            className="error"
                            message={
                              errors.StartupDetail["competitors_msme_desc"]
                            }
                          />
                        )}
                    </>
                  )}
                </div>
              </div>

              <div className="form-row">
                <div className="col-12">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id="competitors_corporate_enable"
                      name="competitors_corporate_enable"
                      className="custom-control-input"
                      onClick={(event: any) => {
                        handleOnClick(event);
                      }}
                      checked={startupDetails.competitors_corporate_enable}
                      readOnly
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="competitors_corporate_enable"
                    >
                      Corporates
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  {startupDetails.competitors_corporate_enable && (
                    <>
                      <textarea
                        id="competitors_corporate_desc"
                        name="competitors_corporate_desc"
                        className={
                          errors &&
                          errors.StartupDetail &&
                          errors.StartupDetail["competitors_corporate_desc"]
                            .length > 0
                            ? "form-control error"
                            : "form-control"
                        }
                        placeholder="Describe in Max. 500 characters"
                        value={startupDetails["competitors_corporate_desc"]}
                        onChange={handleChange}
                        rows={2}
                        required={true}
                        maxLength={500}
                      />
                      {errors &&
                        errors.StartupDetail &&
                        errors.StartupDetail["competitors_corporate_desc"]
                          .length > 0 && (
                          <ValidationMessage
                            className="error"
                            message={
                              errors.StartupDetail["competitors_corporate_desc"]
                            }
                          />
                        )}
                    </>
                  )}
                </div>
              </div>

              <div className="form-row mb-0">
                <div className="col-12">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id="competitors_other_enable"
                      name="competitors_other_enable"
                      className="custom-control-input"
                      onClick={(event: any) => {
                        handleOnClick(event);
                      }}
                      checked={startupDetails.competitors_other_enable}
                      readOnly
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="competitors_other_enable"
                    >
                      Other
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  {startupDetails.competitors_other_enable && (
                    <>
                      <textarea
                        id="competitors_other_desc"
                        name="competitors_other_desc"
                        className={
                          errors &&
                          errors.StartupDetail &&
                          errors.StartupDetail["competitors_other_desc"]
                            .length > 0
                            ? "form-control error"
                            : "form-control"
                        }
                        placeholder="Describe in Max. 500 characters"
                        value={startupDetails["competitors_other_desc"]}
                        onChange={handleChange}
                        rows={2}
                        required={true}
                        maxLength={500}
                      />
                      {errors &&
                        errors.StartupDetail &&
                        errors.StartupDetail["competitors_other_desc"].length >
                          0 && (
                          <ValidationMessage
                            className="error"
                            message={
                              errors.StartupDetail["competitors_other_desc"]
                            }
                          />
                        )}
                    </>
                  )}
                </div>
              </div>

              {errors &&
                errors.StartupDetail &&
                errors.StartupDetail["competitors_startup_enable"].length >
                  0 && (
                  <ValidationMessage
                    className="error"
                    message={errors.StartupDetail["competitors_startup_enable"]}
                  />
                )}
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-4 col-form-label">
              Website URL
              <small className="pt-1">( Optional )</small>
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                name="website_url"
                className={
                  errors &&
                  errors.StartupDetail &&
                  errors.StartupDetail["website_url"].length > 0
                    ? "form-control error"
                    : "form-control"
                }
                placeholder="Enter the URL"
                value={startupDetails.website_url}
                onChange={handleChange}
              />
              {errors &&
                errors.StartupDetail &&
                errors.StartupDetail["website_url"].length > 0 && (
                  <ValidationMessage
                    className="error"
                    message={errors.StartupDetail["website_url"]}
                  />
                )}
            </div>
          </div>

          <hr />

          <div className="form-group row">
            <label className="col-sm-4 col-form-label">
              Social Media
              <small className="pt-1">(Optional)</small>
            </label>
            <div className="col-sm-8">
              <div className="form-row">
                <div className="col-12">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id="customcheckbox1"
                      name="facebook_enable"
                      className="custom-control-input"
                      onClick={(event: any) => {
                        handleOnClick(event);
                      }}
                      checked={startupDetails.facebook_enable}
                      readOnly
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customcheckbox1"
                    >
                      Facebook
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  {startupDetails.facebook_enable && (
                    <input
                      type="text"
                      id="facebook_link"
                      name="facebook_link"
                      className={
                        errors &&
                        errors.StartupDetail &&
                        errors.StartupDetail["facebook_link"].length > 0
                          ? "form-control error"
                          : "form-control"
                      }
                      placeholder="Enter the link"
                      value={startupDetails["facebook_link"]}
                      onChange={handleChange}
                    />
                  )}
                  {errors &&
                    errors.StartupDetail &&
                    errors.StartupDetail["facebook_link"].length > 0 && (
                      <ValidationMessage
                        className="error"
                        message={errors.StartupDetail["facebook_link"]}
                      />
                    )}
                </div>
              </div>

              <div className="form-row">
                <div className="col-12">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id="customcheckbox2"
                      name="linkedin_enable"
                      className="custom-control-input"
                      onClick={(event: any) => {
                        handleOnClick(event);
                      }}
                      checked={startupDetails.linkedin_enable}
                      readOnly
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customcheckbox2"
                    >
                      Linkedin
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  {startupDetails.linkedin_enable && (
                    <input
                      type="text"
                      id="linkedin_link"
                      name="linkedin_link"
                      className={
                        errors &&
                        errors.StartupDetail &&
                        errors.StartupDetail["linkedin_link"].length > 0
                          ? "form-control error"
                          : "form-control"
                      }
                      placeholder="Enter the link"
                      value={startupDetails["linkedin_link"]}
                      onChange={handleChange}
                    />
                  )}
                  {errors &&
                    errors.StartupDetail &&
                    errors.StartupDetail["linkedin_link"].length > 0 && (
                      <ValidationMessage
                        className="error"
                        message={errors.StartupDetail["linkedin_link"]}
                      />
                    )}
                </div>
              </div>

              <div className="form-row">
                <div className="col-12">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id="customcheckbox3"
                      name="twitter_enable"
                      className="custom-control-input"
                      onClick={(event: any) => {
                        handleOnClick(event);
                      }}
                      checked={startupDetails.twitter_enable}
                      readOnly
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customcheckbox3"
                    >
                      Twitter
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  {startupDetails.twitter_enable && (
                    <input
                      type="text"
                      id="twitter_link"
                      name="twitter_link"
                      className={
                        errors &&
                        errors.StartupDetail &&
                        errors.StartupDetail["twitter_link"].length > 0
                          ? "form-control error"
                          : "form-control"
                      }
                      placeholder="Enter the link"
                      value={startupDetails["twitter_link"]}
                      onChange={handleChange}
                    />
                  )}
                  {errors &&
                    errors.StartupDetail &&
                    errors.StartupDetail["twitter_link"].length > 0 && (
                      <ValidationMessage
                        className="error"
                        message={errors.StartupDetail["twitter_link"]}
                      />
                    )}
                </div>
              </div>

              <div className="form-row">
                <div className="col-12">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id="customcheckbox4"
                      name="youtube_enable"
                      className="custom-control-input"
                      onClick={(event: any) => {
                        handleOnClick(event);
                      }}
                      checked={startupDetails.youtube_enable}
                      readOnly
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customcheckbox4"
                    >
                      Youtube
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  {startupDetails.youtube_enable && (
                    <input
                      type="text"
                      id="youtube_link"
                      name="youtube_link"
                      className={
                        errors &&
                        errors.StartupDetail &&
                        errors.StartupDetail["youtube_link"].length > 0
                          ? "form-control error"
                          : "form-control"
                      }
                      placeholder="Enter the link"
                      value={startupDetails["youtube_link"]}
                      onChange={handleChange}
                    />
                  )}
                  {errors &&
                    errors.StartupDetail &&
                    errors.StartupDetail["youtube_link"].length > 0 && (
                      <ValidationMessage
                        className="error"
                        message={errors.StartupDetail["youtube_link"]}
                      />
                    )}
                </div>
              </div>

              <div className="form-row">
                <div className="col-12">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      id="customcheckbox5"
                      name="other_enable"
                      className="custom-control-input"
                      onClick={(event: any) => {
                        handleOnClick(event);
                      }}
                      checked={startupDetails.other_enable}
                      readOnly
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customcheckbox5"
                    >
                      Other
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  {startupDetails.other_enable && (
                    <input
                      type="text"
                      id="other_link"
                      name="other_link"
                      className={
                        errors &&
                        errors.StartupDetail &&
                        errors.StartupDetail["other_link"].length > 0
                          ? "form-control error"
                          : "form-control"
                      }
                      placeholder="Enter the link"
                      value={startupDetails["other_link"]}
                      onChange={handleChange}
                    />
                  )}
                  {errors &&
                    errors.StartupDetail &&
                    errors.StartupDetail["other_link"].length > 0 && (
                      <ValidationMessage
                        className="error"
                        message={errors.StartupDetail["other_link"]}
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="stepper-footer-section">
          <div
            className="btn-toolbar justify-content-between"
            role="toolbar"
            aria-label="Toolbar with button groups"
          >
            <button
              className="btn btn-sisfs btn-transparent-primary"
              onClick={() => applicationPage()}
            >
              Cancel
            </button>
            <span className="stepper-page-count">
              Step {props.tabIndex} / 7{" "}
            </span>
            <div className="btn-group">
              {props.tabIndex != 1 && (
                <button
                  className="btn btn-sisfs btn-transparent-primary"
                  onClick={props.onPreviousClick}
                >
                  Previous
                </button>
              )}
              <button
                type="button"
                className="btn btn-sisfs btn-yellow-primary"
                onClick={() =>
                  props.onStepSave(
                    startupDetails,
                    StepperStartupEnum.StartupDetail,
                    requestType.save
                  )
                }
              >
                {props.tabIndex == 7 ? "Save" : "Save And Next"}
              </button>
              {props.tabIndex == 7 && (
                <button
                  className="btn btn-sisfs btn-yellow-primary"
                  // onClick={() => props.onSubmit}
                >
                  Review & Submit
                </button>
              )}
            </div>
          </div>
        </div>
      </fieldset>
    </>
  );
}

export default StartupDetailsComponent;
