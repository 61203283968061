
import React from "react"
import { BsFillEyeFill } from "react-icons/bs";
import search from "../../../../src/images/search.svg";
import edit from "../../../../src/images/edit.svg";
import remove from "../../../../src/images/ReturnsIcons/trash-img.svg"
function IncubatorPortfolio() {

    const searchList = (e: any) => {

    }
    return (
        <>
            <div className="app-inside app-apply-incubator">
                <div className="status-list-wrapper mt-4">
                    <div className="container">

                        <div className="row">
                            <div className="col-md-6 text-left my-auto mt-3">
                                <p className="dashboard mb-0 ml-1">Incubators Portfolio</p>
                            </div>
                            <div className="col-md-6 text-right mt-3">
                                <div className="text-right">
                                    <div className="d-flex searchDiv align-self-end justify-content-end">
                                        <div className="d-flex searchDiv align-self-end mr-3">
                                            <img src={search} className="search" alt="search" />
                                            <input className="searchBar" type="text" placeholder="Search.." name="search" onKeyUp={searchList} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br />
                        <div className="app-inside-content">
                            <div className="tab-pane active" id="tabs-1">
                                <div className="table-scroll mb-4">
                                    <table className="reviewTable userListTable" >
                                        <thead>
                                            <tr>
                                                <th className="text-center table-heading custom-td-left">Incubator Application ID</th>
                                                <th className="text-center table-heading">Incubator Name <br /> </th>
                                                <th className="text-center table-heading">Published <br /> </th>
                                                <th className="text-center table-heading">Action</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr >
                                                <td className="text-center custom-td">SISFS number1</td>
                                                <td className="text-center custom-td">Incubator's Name1</td>

                                                <td className="text-center custom-td slider-td">
                                                    Yes or No
                                                </td>
                                                <td className="text-center custom-td">{ }
                                                    {/* <BsFillEyeFill /> */}
                                                    <img src={edit} alt="" />
                                                    <img src={remove} alt="" />
                                                </td>
                                            </tr>
                                            <tr >
                                                <td className="text-center custom-td">SISFS number2</td>
                                                <td className="text-center custom-td">Incubator's Name2</td>

                                                <td className="text-center custom-td slider-td">
                                                    Yes or No
                                                </td>
                                                <td className="text-center custom-td">{ }
                                                <img src={edit} alt="" />
                                                    <img src={remove} alt="" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {/* {incubatorList.length == 0 ? <></>
                      :
                      
                          filteredIncubatorList.length == 0 ?
                              <div style={{float:'right',display:'flex',marginRight:'20px',alignItems:'center',justifyContent:'center'}}>
    
                          <span>
                          <label htmlFor="itemsPerPageSelect" className="itemsPerPage">Items per Page:</label>
                          <select
                              className="itemsPerPageSelect"
                              id="itemsPerPageSelect"
                              value={itemsPerPage}
                              onChange={handleItemsPerPageChange}
                          >
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="75">75</option>
                              <option value="100">100</option>
                          </select>
                          </span>&nbsp;&nbsp;&nbsp;
    
                          <span className="pagination" style={{fontSize:'18px',marginTop:'10px'}}>
                              <ReactPaginate
                                  key={itemsPerPage}
                                  pageCount={totalPages}
                                  pageRangeDisplayed={0}
                                  marginPagesDisplayed={0}
                                  previousLabel="Prev"
                                  nextLabel="Next"
                                  // breakLabel="..."
                                  onPageChange={handlePageChange}
                                  containerClassName="pagination"
                                  activeClassName="active"
                                  disableInitialCallback={true}
                                  forcePage={currentPage}
                              />
                          </span>&nbsp;&nbsp;&nbsp;
    
                          <span className="pageNum">
                              Page {(currentPage) + 1} of {totalPages}
                          </span>
                          </div>
                      :<>
                            <div style={{float:'right',display:'flex',marginRight:'20px',alignItems:'center',justifyContent:'center'}}>
    
                              <span>
                              <label htmlFor="itemsPerPageSelect" className="itemsPerPage">Items per Page:</label>
                              <select
                                  className="itemsPerPageSelect"
                                  id="itemsPerPageSelect"
                                  value={itemsPerPage1}
                                  onChange={handleItemsPerPageChange}
                              >
                                  <option value="25">25</option>
                                  <option value="50">50</option>
                                  <option value="75">75</option>
                                  <option value="100">100</option>
                              </select>
                              </span>&nbsp;&nbsp;&nbsp;
    
                              <span className="pagination" style={{fontSize:'18px',marginTop:'10px'}}>
                                  <ReactPaginate
                                      key={itemsPerPage1}
                                      pageCount={totalPages1}
                                      pageRangeDisplayed={0}
                                      marginPagesDisplayed={0}
                                      previousLabel="Prev"
                                      nextLabel="Next"
                                      // breakLabel="..."
                                      onPageChange={handlePageChange}
                                      containerClassName="pagination"
                                      activeClassName="active"
                                      disableInitialCallback={true}
                                      forcePage={currentPage1}
                                  />
                              </span>&nbsp;&nbsp;&nbsp;
                              <span className="pageNum">
                                  Page {currentPage1 + 1} of {totalPages1}
                              </span>
                              </div>
                      </>
                    } */}
                            </div>

                        </div>
                        <br /><br /><br />
                    </div>

                    {/* <Modal
                show={showReport}
                onHide={() => setShowReport(false)}
                dialogClassName="modal-lg modal-dialog-scrollable  table-hover"
                aria-labelledby="example-custom-modal-styling-title"
              >
                <Modal.Header >
                  <Modal.Title id="example-custom-modal-styling-title text-center">
                    Incubator Information
                  </Modal.Title>
                    <button type="button" onClick={() => setShowReport(false)} className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
    
                <Modal.Body>
                  <IncubatorPrefrenceInformationComponent incubatorId={getIncuberId} appId={getAppId} userName={getUserName} />
                </Modal.Body>
    
              </Modal> */}
                </div>
            </div>
        </>
    )
}

export default IncubatorPortfolio;