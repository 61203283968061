import { StepperStartupEnum } from "../../../utils/Enums";
import { useErrorStateValue } from "../../../providers/ErrorProvider";

interface IStartupStepperProps {
    onClick: (index: number) => void;
    errors?: any;
    teamError?: Array<Number>;

}

function StepperHeaderStartupComponent(props: IStartupStepperProps) {
    //@ts-ignore
    const [{ errors }, dispatch] = useErrorStateValue();

    const checkStepError = (field: string): number => {
        if (!errors || !errors[field]) {
            return 0;
        }
        const totalError = Object.keys(errors[field]).filter(
            (key: string) => errors[field][key].length > 0
        ).length;

        return totalError;
    };
    return (
        <>
            <div className="bs-stepper-header" role="tablist">
                <div className="step" data-target="#startup-step-1">
                    <button
                        type="button"
                        className="step-trigger"
                        role="tab"
                        id="stepper1trigger1"
                        aria-controls="startup-step1"
                        
                        onClick={() => props.onClick(StepperStartupEnum.AuthorizedRepresentative)}
                    >
                        <span
                            className={
                                checkStepError("AuthorizedRepresentative") > 0
                                    ? "bs-stepper-circle-error"
                                    : "bs-stepper-circle"
                            }
                        ></span>

                        <span className="bs-stepper-label">Authorized Representative</span>
                        <div className="bs-stepper-line"></div>
                    </button>
                    {checkStepError("AuthorizedRepresentative") > 0 ? (
                        <span className="badge-red">
                            <i className="fa fa-exclamation-triangle"></i>
                            {checkStepError("AuthorizedRepresentative") > 0
                                ? `${checkStepError("AuthorizedRepresentative")} invalid fields`
                                : ""}
                        </span>
                    ) : (
                        <span />
                    )}


                </div>

                <div className="step" data-target="#startup-step-2">
                    <button
                        type="button"
                        className="step-trigger"
                        role="tab"
                        id="stepper1trigger2"
                        aria-controls="startup-step2"
                        onClick={() => props.onClick(StepperStartupEnum.EntityDetail)}
                    >
                        <span
                            className={
                                checkStepError("EntityDetail") > 0
                                    ? "bs-stepper-circle-error"
                                    : "bs-stepper-circle"
                            }
                        ></span>


                        <span className="bs-stepper-label">Entity Details</span>
                        <div className="bs-stepper-line"></div>
                    </button>
                    {checkStepError("EntityDetail") > 0 ? (
                        <span className="badge-red">
                            <i className="fa fa-exclamation-triangle"></i>
                            {checkStepError("EntityDetail") > 0
                                ? `${checkStepError("EntityDetail")} invalid fields`
                                : ""}
                        </span>
                    ) : (
                        <span />
                    )}


                </div>

                <div className="step" data-target="#startup-step-3">
                    <button
                        type="button"
                        className="step-trigger"
                        role="tab"
                        id="stepper1trigger3"
                        aria-controls="startup-step3"
                        onClick={() => props.onClick(StepperStartupEnum.StartupDetail)}
                    >
                        <span
                            className={
                                checkStepError("StartupDetail") > 0
                                    ? "bs-stepper-circle-error"
                                    : "bs-stepper-circle"
                            }
                        ></span>

                        <span className="bs-stepper-label">Startup Details</span>
                        <div className="bs-stepper-line"></div>
                    </button>
                    {checkStepError("StartupDetail") > 0 ? (
                        <span className="badge-red">
                            <i className="fa fa-exclamation-triangle"></i>
                            {checkStepError("StartupDetail") > 0
                                ? `${checkStepError("StartupDetail")} invalid fields`
                                : ""}
                        </span>
                    ) : (
                        <span />
                    )}

                </div>

                <div className="step" data-target="#startup-step-4">
                    <button
                        type="button"
                        className="step-trigger"
                        role="tab"
                        id="stepper1trigger4"
                        aria-controls="startup-step4"
                        onClick={() => props.onClick(StepperStartupEnum.StartupTeam)}
                    >
                        <span
                            className={
                                checkStepError("StartupTeam") > 0
                                    ? "bs-stepper-circle-error"
                                    : "bs-stepper-circle"
                            }
                        ></span>

                        <span className="bs-stepper-label">Startup Team</span>
                        <div className="bs-stepper-line"></div>
                    </button>
                    {checkStepError("StartupTeam") > 0 ? (
                        <span className="badge-red">
                            <i className="fa fa-exclamation-triangle"></i>
                            {checkStepError("StartupTeam") > 0
                                ? `${checkStepError("StartupTeam")} invalid fields`
                                : ""}
                        </span>
                    ) : (
                        <span />
                    )}

                </div>

                <div className="step" data-target="#startup-step-5">
                    <button
                        type="button"
                        className="step-trigger"
                        role="tab"
                        id="stepper1trigger5"
                        aria-controls="startup-step5"
                        onClick={() => props.onClick(StepperStartupEnum.FundingDetails)}
                    >
                        <span
                            className={
                                checkStepError("FundingDetails") > 0
                                    ? "bs-stepper-circle-error"
                                    : "bs-stepper-circle"
                            }
                        ></span>

                        <span className="bs-stepper-label">Funding Details</span>
                        <div className="bs-stepper-line"></div>
                    </button>
                    {checkStepError("FundingDetails") > 0 ? (
                        <span className="badge-red">
                            <i className="fa fa-exclamation-triangle"></i>
                            {checkStepError("FundingDetails") > 0
                                ? `${checkStepError("FundingDetails")} invalid fields`
                                : ""}
                        </span>
                    ) : (
                        <span />
                    )}

                </div>

                <div className="step" data-target="#startup-step-6">
                    <button
                        type="button"
                        className="step-trigger"
                        role="tab"
                        id="stepper1trigger6"
                        aria-controls="startup-step6"
                        onClick={() => props.onClick(StepperStartupEnum.IncubatorPreference)}
                    >
                        <span
                            className={
                                checkStepError("IncubatorPreference") > 0
                                    ? "bs-stepper-circle-error"
                                    : "bs-stepper-circle"
                            }
                        ></span>

                        <span className="bs-stepper-label">Incubator Preference</span>
                        <div className="bs-stepper-line"></div>
                    </button>
                    {checkStepError("IncubatorPreference") > 0 ? (
                        <span className="badge-red">
                            <i className="fa fa-exclamation-triangle"></i>
                            {checkStepError("IncubatorPreference") > 0
                                ? `${checkStepError("IncubatorPreference")} invalid fields`
                                : ""}
                        </span>
                    ) : (
                        <span />
                    )}

                </div>

                <div className="step" data-target="#startup-step-7">
                    <button
                        type="button"
                        className="step-trigger"
                        role="tab"
                        id="stepper1trigger7"
                        aria-controls="startup-step7"
                        onClick={() => props.onClick(StepperStartupEnum.UploadDocuments)}
                    >
                        <span
                            className={
                                checkStepError("UploadDocuments") > 0
                                    ? "bs-stepper-circle-error"
                                    : "bs-stepper-circle"
                            }
                        ></span>
                        <span className="bs-stepper-label">Upload Documents</span>
                    </button>
                    {checkStepError("UploadDocuments") > 0 ? (
                        <span className="badge-red">
                            <i className="fa fa-exclamation-triangle"></i>
                            {checkStepError("UploadDocuments") > 0
                                ? `${checkStepError("UploadDocuments")} invalid fields`
                                : ""}
                        </span>
                    ) : (
                        <span />
                    )}

                </div>

            </div>
        </>
    )
};

export default StepperHeaderStartupComponent;