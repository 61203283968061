import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ContactSectionComponent from "./ContactSectionComponent";
import GetApplicationInfoComponent from "./GetApplicationInfoComponenet";
import { useHistory } from "react-router-dom";
import { get, post } from "../../../../effects/api/api.common";
import {
  CREATE_EMPTY_APPLICATION,
  GET_STARTUP_ELIGIBILITY,
} from "../../../../constants/api";
import UserInfoModel from "../../../../models/UserInfoModel";
import { getSessionStorageValue } from "../../../../effects/utils/session-storage";
import ConfirmationDialog from "../../../common/controls/ConfirmationDialog";
import { userInfo } from "os";

/** Render Apply for Incubator screen */
function ApplicationApplyContainer() {
  const history = useHistory();
  const [startupEligibility, setStartupEligibility] = useState("");
  const [restrictEligibilityModal, setRestrictEligibilityModal] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [restrictIncubatorModal, setRestrictIncubatorModal] = useState(false);
  const [IncubatorModal, setIncubatorModal] = useState(false);
  const [dip, setDip] = useState<any>("");
  const [certmodal, setCertmodal] = useState(false);

  useEffect(() => {
    const DipNum = localStorage.getItem("dipp_num");
    setDip(DipNum);
  });
  const getNewApplication = () => {
    if (startupEligibility === "incubator") {
      const user: any = getSessionStorageValue("user");
      const userInfo = JSON.parse(user);
      var payload = {
        user_id: userInfo.id,
        user_role: userInfo.roles,
      };
      post(CREATE_EMPTY_APPLICATION, payload).then((response) => {
        if (!response.data) return;
        if (response.data.result === "fail") {
          setRestrictIncubatorModal(true);
        }
        if (
          response.data.message === "Not allowed" &&
          response.data.result == "failed"
        ) {
          setIncubatorModal(true);
        } else {
          // history.push({
          //   pathname: "/incubator",
          //   state: { application_id: response.data.application_id },
          // });
        }
      });
    } else if (startupEligibility === "startup") {
      const user: any = getSessionStorageValue("user");
      const userInfo = JSON.parse(user);
      // console.log(startupEligibility,dip,"dippp")
      if (dip == "null" || dip == "" || dip == undefined) {
        setCertmodal(true);
      } else {
        get(`${GET_STARTUP_ELIGIBILITY}/${userInfo.id}`).then(
          (response: any) => {
            if (!response || !response?.data) {
              return;
            }

            if (response?.data?.data?.eligibility === true) {
              history.push({
                pathname: "/startup",
                state: {
                  new_apply: 1,
                  eligibilityCriteriaArray:
                    response?.data?.data?.eligibilityCriteria,
                },
              });
            } else {
              setErrorMessage(response?.data?.data?.message);
              setRestrictEligibilityModal(true);
            }
          }
        );
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (getSessionStorageValue("user")) {
      const user: any = getSessionStorageValue("user");
      const userInfo = JSON.parse(user);

      setStartupEligibility(userInfo.roles);
    }
  }, []);

  return (
    <>
      <div className="app-inside app-apply-incubator">
        <div className="container-fluid about-heading app-banner-inside">
          <span className="text-center caption-tagline mb-2">
            Startup India
          </span>
          <div className="text-center main-heading">Seed Fund Scheme</div>

          <div className="text-center sub-heading">
            <p>
              Financial assistance to startups for proof of concept, prototype
              development, product trials, market entry and commercialization
            </p>
            <p>
              <a
                className="btn btn-large btn-sisfs btn-yellow-primary"
                onClick={() => getNewApplication()}
              >
                Apply Now
              </a>
            </p>
          </div>
        </div>

        <GetApplicationInfoComponent />

        <ContactSectionComponent />

        {restrictEligibilityModal && (
          <ConfirmationDialog
            headerText={"Oops !!"}
            bodyText={errorMessage}
            onDialogClose={() => setRestrictEligibilityModal(false)}
            iconClass="test"
          />
        )}

        {restrictIncubatorModal && (
          <ConfirmationDialog
            headerText={"Oops !!"}
            bodyText={
              "Not allowed to create as already have active application."
            }
            onDialogClose={() => setRestrictIncubatorModal(false)}
            iconClass="test"
          />
        )}
        {certmodal && (
          <ConfirmationDialog
            headerText={"Oops !!"}
            bodyText={
              <React.Fragment>
                Account detail provided is not recognized. If your startup is
                recognized, please use the user account having active startup
                recognition on{" "}
                <a href="https://startupindia.gov.in/" target="_blank">
                  startupindia.gov.in
                </a>{" "}
                or use your CIN/LLPIN/ Registration number as username for
                login. In case you still face an issue, please raise a ticket in
                the contact page of the website.
              </React.Fragment>
            }
            onDialogClose={() => setCertmodal(false)}
            iconClass="test"
          />
        )}
        {IncubatorModal && (
          <ConfirmationDialog
            headerText={"Oops !!"}
            bodyText={
              <div
                dangerouslySetInnerHTML={{
                  __html: `
            Dear Incubator, 
            <br/><br/>  
            Kindly note, we are currently not accepting any incubator applications for Startup India Seed Fund Scheme. Experts Advisory Committee has approved almost the entire corpus of funds under the scheme and there are enough applicants for the remaining corpus. Incubator applications may be admitted in the future, depending on the availability of funds. In case you are willing to apply for the scheme, kindly write to <a href="mailto:sisfs@investindia.org.in">sisfs@investindia.org.in</a> and <a href="mailto:seedfund@investindia.org.in">seedfund@investindia.org.in</a> and we will reach out to you in case of any slots available. 
            <br/><br/> 
            Regards, 
            <br/> 
            Seed Fund Secretariat.
          `,
                }}
              />
            }
            onDialogClose={() => setIncubatorModal(false)}
            iconClass="test"
          />
        )}
      </div>
    </>
  );
}

export default ApplicationApplyContainer;
