import * as React from "react";
import { useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import DashboardFooterComponent from "../components/common/footer/DashboardFooterComponent";
import AddUserComponent from "../components/dpiit/dashboard/AddUserComponent";
import ViewUserComponent from "../components/dpiit/dashboard/ViewUsersComponent";
import ViewIncubatorDetails from "../components/dpiit/incubator/ViewIncubatorDetails";
import DpiitStartupListingComponent from "../components/dpiit/startuplisting/DpiitStartupListingComponent";
import EacHeaderComponent from "../components/eac/common/EacHeaderComponent";
import EacDashboardContainer from "../components/eac/dashboard/EacContainer"
import IncubatorContainerEac from "../components/eac/dashboard/IncubatorContainerEac";
import IncubatorSelectionComponent from "../components/eac/dashboard/IncubatorSelection";
import ReviewContainer from "../components/eac/reviews/ReviewContainer";
import ViewReviewComponent from "../components/eac/reviews/ViewReviewComponent";
import ViewStartupDetailsComponent from "../components/incubator/startuplisting/startupdetails/ViewStartupDetailsComponent";
import StartupListContainer from "../components/incubator/startuplisting/StartupListContainer";
import { getSessionStorageValue } from "../effects/utils/session-storage";
import EacStartupListingComponent from "../components/eac/dashboard/EacStartupListingComponent";
import IncAppliedReappliedContainer from "../components/dpiit/dashboard/IncAppliedReappliedContainer";
import MergedIncubatorDetailsComponent from "../components/dpiit/incubator/MergedIncubators/MergedIncubatorDetailsComponent";
import RequireAuth from "../Routelocks/RouterHoooks";
import NoticeBoard from "../components/notice_board/NoticeBoard";

const EacRouterComponent = () => {
  const history = useHistory();
  const user: any = getSessionStorageValue("user");
  const userInfo = JSON.parse(user)
  useEffect(() => {
    // console.log("EAC")

    // if (user == null || userInfo.roles != "eac") {
    //   history.push('/error');
    // }
  }, []);
  return (
    <>
      <RequireAuth>
        {/* Dashboard Header Component */}
        <EacHeaderComponent />
        <Switch>
          <Route path="/eac_dashboard" component={EacDashboardContainer} />

          <Route path="/eac_review" component={ReviewContainer} />

          <Route path="/view_eac_review" component={ViewReviewComponent} />

          <Route path="/view_incubator_eac" component={IncubatorContainerEac} />

          <Route path="/merged_list" component={IncAppliedReappliedContainer} />
          <Route path="/merged_Incubator_Details_list" component={MergedIncubatorDetailsComponent} />

          
          <Route path="/eac_notice_board" component={NoticeBoard} />
          <Route path="/user_view_eac" component={ViewUserComponent} />

          <Route path="/user_add_eac" component={AddUserComponent} />

          <Route path="/view_selection_meeting" component={IncubatorSelectionComponent} />

          <Route path="/eac_startup_list_details" component={StartupListContainer} />

          <Route path="/eac_startup_details" component={ViewStartupDetailsComponent} />

          <Route path="/eac_view_incubator" component={ViewIncubatorDetails} />

          <Route path="/eac_startup_list" component={EacStartupListingComponent} />
          {/* 
        <Route path="/eac_startup_list" component={DpiitStartupListingComponent} /> */}


        </Switch>


        {/* Dashboard Footer Component */}
        <DashboardFooterComponent />
      </RequireAuth>
    </>
  );
};

export default EacRouterComponent;
