import React, { Fragment, useEffect, useState } from "react";
import pdf from "../../../images/PDF.svg";
import { get, post } from "../../../effects/api/api.common";
import {
  PREVIOUS_QR_DETAILS,
  PROGRESS_OVERVIEW_DETAIL_BY_APPLICATION_ID,
  PROGRESS_OVERVIEW_VALIDATE,
  PROGRESS_PERCENTAGE,
  PROGRESS_STAGES,
  PRPGRESS_REVIEW_DROUPDOWN_API,
  PRPGRESS_REVIEW_FORM_GET_API,
  QUARTERLY_REPORTS_PROGRESS_TAB,
} from "../../../constants/api";
import ConfirmationPopup from "./ConfirmationPopup";
import ConfirmationDialog from "../../common/controls/ConfirmationDialog";
import moment from "moment";
import { getSessionStorageValue } from "../../../effects/utils/session-storage";
import SuccessCheck from "../../../images/ReturnsIcons/check (2).svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

declare var $: any;

const ProgressOverviewFields = (props: any) => {
  const {
    UserStatus,
    statusId,
    evDate,
    isPrefer,
    flag,
    quaterlyReports_returns,
    qrDetails,
    loggedInRole,
  } = props;
  const [isPref1, setIsPref1] = useState("");

  const selected_Droup = [
    "select",
    "Completed",
    "In-Progress",
    "Not Started",
    "Completed Pre-SISFS",
    "Not Applicable",
  ];

  const user: any = getSessionStorageValue("user");
  const userInfo = JSON.parse(user);

  const [showIncubatorSuccessModal, setShowIncubatorSuccessModal] =
    useState(false);
  const [dialogData, setDialogData] = useState({
    header: "",
    text: "",
  });
  const [showIncubatorFailureModal, setShowIncubatorFailureModal] =
    useState(false);
  const [failDialogData, setFailDialogData] = useState({
    header: "",
    text: "",
  });

  const [applicationId, setApplicationId] = useState("");
  const [disabled_part2, setdisabled_part2] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progressStages, setProgressStages] = useState([]);
  const [progressPercentage, setProgressPercentage] = useState([]);
  const [activeIndex, setActiveIndex] = useState({ arrow: true, index: 0 });
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [chooseYear, setChooseYear] = useState<any>([]);
  var chooseQuarter: any = [];
  const [newQuarter, setNewQuarter] = useState([]);
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [validateProgressId, setValidateProgressId] = useState(0);
  const [validateMessage, setValidateMessage] = useState({
    message: "",
    result: "",
  });
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [isClickid, setIsClickid] = useState("");
  const [isevid, setEvid] = useState("");
  var currentdatenew = moment().format("YYYY-MM-DD");
  const [activeTab, setActiveTab] = useState(0);
  const [QuaterlyBTN, setQuaterlyBTN] = useState(false);
  const [quater1, setQuater] = useState("");
  const [currentQuarter, setCurrentQuarter] = useState<number>(1);
  const handleTabClick = (index: any) => {
    setActiveTab(index);
  };
  const [ChangeYearmonth, setChangeYearmonth] = useState({
    year: "",
    quarter: "",
  });
  const [loader, setLoader] = useState(false);
  // //console.log('final props', props.applicationId)

  // const getProgressByAppId = () => {
  //     const queryParam = (year ? '&year=' + year : '') + (month ? '&month=' + month : '')
  //     const apiUrl = `${PROGRESS_OVERVIEW_DETAIL_BY_APPLICATION_ID}${applicationId}&type=incubator${queryParam}`
  //     setLoading(true)
  //     get(apiUrl).then((response: any) => {
  //         if (!response || !response.data) return;
  //         setData(response.data.data)
  //     }).catch((err) => {
  //         //console.log('error', err)
  //         setData([])
  //     }).finally(() => {
  //         setLoading(false)
  //     })
  // }
  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Adding 1 since getMonth() returns a zero-based index
    const currentYear = currentDate.getFullYear();
    let yearss: any = [];
    for (let i = currentYear; i >= 2023; i--) {
      let data = i.toString();
      yearss.push(data);
      setChooseYear(yearss);
    }

    let quarter = 1;
    if (currentMonth >= 1 && currentMonth <= 3) {
      quarter = 1;
    } else if (currentMonth >= 4 && currentMonth <= 6) {
      quarter = 2;
    } else if (currentMonth >= 7 && currentMonth <= 9) {
      quarter = 3;
    } else {
      quarter = 4;
    }

    setCurrentQuarter(quarter);
    if (qrDetails.length > 0) {
      Year_Quater_API(
        qrDetails.length && qrDetails[0].year,
        qrDetails.length && qrDetails[0].quarter
      );
    }
  }, []);

  useEffect(() => {
    // getProgressByAppId()
    // dropdowns_API('market_launch', 4);

    setdisabled_part2(true);
    setChangeYearmonth(qrDetails[0]);

    if (qrDetails.length != 0) {
      if (qrDetails[0].quarter == "1") {
        setQuater("Jan - Mar");
      } else if (qrDetails[0].quarter == "2") {
        setQuater("Apr - Jun");
      } else if (qrDetails[0].quarter == "3") {
        setQuater("Jul - Sep");
      } else if (qrDetails[0].quarter == "4") {
        setQuater("Oct - Dec");
      } else {
        setQuater("");
      }
    }

    //console.log(props,"props")
    //   const { meetingData,startup_application_id,applicationComments,statusId,evDate} = props;
    setIsClickid(statusId);
    setEvid(evDate);
    setIsPref1(isPrefer);
    setreturnsFormSubmit(
      quaterlyReports_returns.length != 0 && quaterlyReports_returns[0]
    );
    // setSecondDroupDownValues(quaterlyReports_returns.length != 0 && quaterlyReports_returns[0].prototype_developement)
    // setThirdDroupDownValues(quaterlyReports_returns.length != 0 && quaterlyReports_returns[0].product_developement)
    // setForthDroupDownValues(quaterlyReports_returns.length != 0 && quaterlyReports_returns[0].field_trials)
    // setFithDroupDownValues(quaterlyReports_returns.length != 0 && quaterlyReports_returns[0].market_launch)
    setreturnsFormLength(quaterlyReports_returns.length);
    // console.log(qrDetails);
    if (qrDetails && qrDetails.length > 0) {
      setQuaterlyBTN(true);
    }
  }, [applicationId, year, month, statusId, evDate, flag]);

  const handleIconClick = (index: number) => {
    const iconState = activeIndex.arrow;
    setActiveIndex({ arrow: !iconState, index: index });
  };

  useEffect(() => {
    setApplicationId(props.applicationId);
    setIsClickid(statusId);
    setEvid(evDate);
  }, [props.applicationId, statusId, evDate]);

  useEffect(() => {
    const getProgressPercentage = () => {
      get(PROGRESS_PERCENTAGE).then((response: any) => {
        if (!response || !response.data) return;
        if (response?.data?.result === "success") {
          setProgressPercentage(response.data.data);
        } else {
          setProgressPercentage([]);
        }
      });
    };

    const getProgressStages = () => {
      get(PROGRESS_STAGES).then((response: any) => {
        if (!response || !response.data) return;
        if (response?.data?.result === "success") {
          setProgressStages(response.data.data);
        } else {
          setProgressStages([]);
        }
      });
    };

    getProgressPercentage();
    getProgressStages();
  }, []);

  const getStageNameById = (stageId: number) => {
    if (!stageId || !progressStages) return "NA";
    const filter = progressStages.find((key: any) => {
      return key.id === stageId;
    });
    // @ts-ignore
    if (filter === undefined || Object.keys(filter).length === 0) return;
    // @ts-ignore
    return filter.name ? filter.name : "";
  };
  const myNumberWithTwoDecimalPlace = (myNumber: string) => {
    return parseFloat(myNumber).toFixed(2);
  };

  const getStagePercentNameById = (percentId: number) => {
    if (!percentId || !progressPercentage) return "NA";
    const filter = progressPercentage.find((key: any) => {
      return key.id === percentId;
    });
    // @ts-ignore
    if (filter === undefined || Object.keys(filter).length === 0) return;
    // @ts-ignore
    return filter.name ? filter.name : "";
  };

  const handleValidateData = (progressId: number) => {
    setValidateMessage({ result: "", message: "" });
    const formData = {
      id: progressId,
    };
    post(PROGRESS_OVERVIEW_VALIDATE, formData).then((response: any) => {
      if (!response || !response.data) return;
      //console.log('validate', response.data)
      setValidateMessage(response.data);
      setShowMessageModal(true);
    });
  };

  const [returnsFormLength, setreturnsFormLength] = useState();
  const [onGoingData, setOngoingQrData] = useState(0);
  const [qrReviewDataId, setQrReviewDataId] = useState(0);

  // const [DroupDownValues, setDroupDownValues] = useState<any>();
  // const [SecondDroupDownValues, setSecondDroupDownValues] = useState<any>("");
  // const [ThirdDroupDownValues, setThirdDroupDownValues] =useState<any>("");
  // const [ForthDroupDownValues, setForthDroupDownValues] =useState<any>("");
  // const [FithDroupDownValues, setFithDroupDownValues] =useState<any>("");

  // const [Payloadsatatedata, setPayloadsatatedata] = useState(0);

  const [returnsFormSubmit, setreturnsFormSubmit] = useState<any>({
    description: "",
    proof_of_concept: "",
    prototype_developement: "",
    agreement_date: "",
    product_developement: "",
    field_trials: "",
    market_launch: "",
    loans: "",
    angel_funding: "",
    vc_funding: "",
    others: "",
    no_of_employee: "",
    turnover_of_startup: "",
    no_of_ip_registration: "",
    gst_filled: "",
    products_on_gem_portal: "",
    agrrement_startup: "",
    engagement_startup: "",
    success_stories: "",
    remarks: "",
    purpose_of_funds_requested: "",
  });
  var qrData: any;
  const [returnsFormSubmit1, setreturnsFormSubmit1] = useState<any>({
    description: "",
    agreement_date: "",
    agrrement_startup: "",
    engagement_startup: "",
    success_stories: "",
    purpose_of_funds_requested: "",
  });
  useEffect(() => {
    console.log(returnsFormSubmit);
  }, [returnsFormSubmit]);
  // const [returnsSecondDroup, setreturnsSecondDroup] = useState<any>({
  //     prototype_developement: "",
  // })
  // const [returnsThirdDroup, setreturnsThirdDroup] = useState<any>({
  //     product_developement: "",
  // })

  // const [returnsForthDroup, setreturnsForthDroup] = useState<any>({
  //     field_trials: "",
  // })

  const handleChange = (event: any) => {
    const { name, value, type } = event.target;
    // console.log(event.target);
    // const newValue = name == 'No_People' || 'IP_Registrations' || "GST_Filed" || "GEM_Portal" ? value.replace(/[^0-9]/ig, '') : value

    if (name == "loans") {
      const newValue = value.replace(/[^0-9.]|(?<=\..*)\./g, "");
      setreturnsFormSubmit((prevFormData: any) => ({
        ...prevFormData,
        [name]: newValue,
      }));
    } else if (name == "angel_funding") {
      const newValue = value.replace(/[^0-9.]|(?<=\..*)\./g, "");
      setreturnsFormSubmit((prevFormData: any) => ({
        ...prevFormData,
        [name]: newValue,
      }));
    } else if (name == "vc_funding") {
      const newValue = value.replace(/[^0-9.]|(?<=\..*)\./g, "");
      setreturnsFormSubmit((prevFormData: any) => ({
        ...prevFormData,
        [name]: newValue,
      }));
    } else if (name == "others") {
      const newValue = value.replace(/[^0-9.]|(?<=\..*)\./g, "");
      setreturnsFormSubmit((prevFormData: any) => ({
        ...prevFormData,
        [name]: newValue,
      }));
    } else if (name == "no_of_employee") {
      const newValue = value.replace(/[^0-9]/g, "");
      setreturnsFormSubmit((prevFormData: any) => ({
        ...prevFormData,
        [name]: newValue,
      }));
    } else if (name == "no_of_ip_registration") {
      const newValue = value.replace(/[^0-9.]|(?<=\..*)\./g, "");
      setreturnsFormSubmit((prevFormData: any) => ({
        ...prevFormData,
        [name]: newValue,
      }));
    } else if (name == "gst_filled") {
      const newValue = value.replace(/[^0-9.]|(?<=\..*)\./g, "");
      setreturnsFormSubmit((prevFormData: any) => ({
        ...prevFormData,
        [name]: newValue,
      }));
    } else if (name == "products_on_gem_portal") {
      const newValue = value.replace(/[^0-9]/g, "");
      setreturnsFormSubmit((prevFormData: any) => ({
        ...prevFormData,
        [name]: newValue,
      }));
    } else if (name == "turnover_of_startup") {
      const newValue = value.replace(/[^0-9.]|(?<=\..*)\./g, "");
      setreturnsFormSubmit((prevFormData: any) => ({
        ...prevFormData,
        [name]: newValue,
      }));
    } else {
      const newValue = value;
      setreturnsFormSubmit((prevFormData: any) => ({
        ...prevFormData,
        [name]: newValue,
      }));
    }

    // dropdowns_API(name, value);
    // FirstDroup_Down();
  };

  // const FirstDroup_Down = () => {
  //     post(PRPGRESS_REVIEW_DROUPDOWN_API, {"previous_values":[returnsFormSubmit.proof_of_concept]}).then((res: any) => {
  //         // console.log(res);
  //         // console.log(res.data);
  //         setDroupDownValues(res.data.data);
  //     })
  // }

  // const secondhandleChange = (event: any) => {
  //     setThirdDroupDownValues("")
  //         setForthDroupDownValues("")
  //         setFithDroupDownValues("")
  //     const { name, value, type } = event.target;
  //     const newValue = value
  //     setreturnsSecondDroup((prevFormData: any) => ({
  //         ...prevFormData,
  //         [name]: newValue,
  //     }));

  //     post(PRPGRESS_REVIEW_DROUPDOWN_API, { "previous_values": [returnsFormSubmit.proof_of_concept, newValue] }).then((res: any) => {
  //         setThirdDroupDownValues(res.data.data);
  //     })
  // }

  // const thirdhandleChange = (event: any) => {
  //     setForthDroupDownValues("")
  //         setFithDroupDownValues("")
  //     const { name, value, type } = event.target;
  //     const newValue = value
  //     setreturnsThirdDroup((prevFormData: any) => ({
  //         ...prevFormData,
  //         [name]: newValue,
  //     }));

  //     post(PRPGRESS_REVIEW_DROUPDOWN_API, { "previous_values": [returnsFormSubmit.proof_of_concept, returnsSecondDroup.prototype_developement, newValue] }).then((res: any) => {
  //         setForthDroupDownValues(res.data.data);
  //     })
  // }

  // const forthhandleChange = (event:any) => {
  //     setFithDroupDownValues("")
  //     const { name, value, type } = event.target;
  //     const newValue = value
  //     setreturnsForthDroup((prevFormData: any) => ({
  //         ...prevFormData,
  //         [name]: newValue,
  //     }));

  //     post(PRPGRESS_REVIEW_DROUPDOWN_API, { "previous_values": [returnsFormSubmit.proof_of_concept, returnsSecondDroup.prototype_developement, returnsThirdDroup.product_developement, newValue] }).then((res: any) => {
  //         setFithDroupDownValues(res.data.data);
  //     })
  // }

  const SubmitForm = (e: any) => {
    e.preventDefault();
    $("#YesOrNoThree").modal("show");
  };

  const [checkQuarter, setCheckquarter] = useState<any>("");
  const [reviewId, setReviewId] = useState<any>("");
  const Year_Quater_API = (year: any, quater: any) => {
    let payload = {
      year: year ? year : ChangeYearmonth && ChangeYearmonth.year,

      quarter: quater ? quater : ChangeYearmonth && ChangeYearmonth.quarter,

      startup_application_id: UserStatus.id,
    };

    if (
      payload.year != "" &&
      (payload.quarter == undefined ||
        payload.quarter == "" ||
        payload.quarter == null)
    ) {
      return;
    }

    setLoading(true);
    post(PRPGRESS_REVIEW_FORM_GET_API, payload).then((res: any) => {
      setLoading(false);
      setreturnsFormLength(res.data.data.length);
      setCheckquarter(res.data.qr_status);
      setReviewId(res.data.qr_id);
      if (res.data.qr_data.length > 0) {
        setOngoingQrData(1);
        const id = res.data.qr_data[0].id;
        setQrReviewDataId(id);
      } else {
        setOngoingQrData(0);
        setQrReviewDataId(0);
      }
      if (
        res.data.data.length == 0 &&
        (res.data.qr_status == "upcoming" || res.data.qr_status == "active")
      ) {
        let payload = {
          year: year ? year : ChangeYearmonth && ChangeYearmonth.year,

          quarter: quater ? quater : ChangeYearmonth && ChangeYearmonth.quarter,

          startup_application_id: UserStatus.id,
        };

        if (
          payload.year != "" &&
          (payload.quarter == undefined ||
            payload.quarter == "" ||
            payload.quarter == null)
        ) {
          return;
        }
        // return
        post(PREVIOUS_QR_DETAILS, payload).then((response: any) => {
          setreturnsFormSubmit({
            description:
              response.data.data.length > 0
                ? response.data.data[0].description
                : "",
            proof_of_concept: "",
            prototype_developement: "",
            agreement_date:
              response.data.data.length > 0
                ? response.data.data[0].agreement_date
                : "",
            product_developement: "",
            field_trials: "",
            market_launch: "",
            loans: "",
            angel_funding: "",
            vc_funding: "",
            others: "",
            no_of_employee: "",
            turnover_of_startup: "",
            no_of_ip_registration: "",
            gst_filled: "",
            products_on_gem_portal: "",
            agrrement_startup:
              response.data.data.length > 0
                ? response.data.data[0].agrrement_startup
                : "",
            engagement_startup:
              response.data.data.length > 0
                ? response.data.data[0].engagement_startup
                : "",
            success_stories:
              response.data.data.length > 0
                ? response.data.data[0].success_stories
                : "",
            remarks: "",
            purpose_of_funds_requested:
              response.data.data.length > 0
                ? response.data.data[0].purpose_of_funds_requested
                : "",
          });
        });
      } else {
        setreturnsFormSubmit(res.data.data[0]);
      }
    });
  };
  const ConformationClick = () => {
    let payload = {
      startup_application_id: UserStatus.id,

      proof_of_concept: returnsFormSubmit.proof_of_concept,

      prototype_developement: returnsFormSubmit.prototype_developement,

      product_development: returnsFormSubmit.product_developement,

      field_trials: returnsFormSubmit.field_trials,

      market_launch: returnsFormSubmit.market_launch,

      loans: returnsFormSubmit.loans,

      angel_funding: returnsFormSubmit.angel_funding,

      vc_funding: returnsFormSubmit.vc_funding,

      others: returnsFormSubmit.others,

      no_of_employee: returnsFormSubmit.no_of_employee,

      turnover_of_startup: returnsFormSubmit.turnover_of_startup,

      no_of_ip_registration: returnsFormSubmit.no_of_ip_registration,

      gst_filled: returnsFormSubmit.gst_filled,

      products_on_gem_portal: returnsFormSubmit.products_on_gem_portal,

      agrrement_startup: returnsFormSubmit.agrrement_startup,

      engagement_startup: returnsFormSubmit.engagement_startup,

      remarks: returnsFormSubmit.remarks,

      success_stories: returnsFormSubmit.success_stories,

      description: returnsFormSubmit.description,

      agreement_date:
        returnsFormSubmit.agreement_date == undefined
          ? null
          : returnsFormSubmit.agreement_date.slice(0, 10),

      purpose_of_funds_requested: returnsFormSubmit.purpose_of_funds_requested,

      logged_user_id: userInfo.id,

      confirmation: 1,
      is_QR_ongoing: onGoingData,

      QRID: returnsFormLength == 0 ? "" : returnsFormSubmit.id,

      quaterly_review_id: qrDetails[0].id || qrReviewDataId,
    };
    post(QUARTERLY_REPORTS_PROGRESS_TAB, payload).then((res: any) => {
      // console.log(res.data.data);
      if (res.data.result == "success") {
        setDialogData({
          header: "Thank You!",
          text: res.data.message,
        });
        setShowIncubatorSuccessModal(true);
        setdisabled_part2(true);
      } else {
        setFailDialogData({
          header: "",
          text: res.data.message,
        });
        setShowIncubatorFailureModal(true);
      }
    });
  };

  const saveClick = () => {
    // console.log(SecondDroupDownValues);
    // console.log(SecondDroupDownValues[0]);
    // console.log(SecondDroupDownValues[0] != undefined ? SecondDroupDownValues[0].value : 0)
    // return;

    let payload = {
      startup_application_id: UserStatus.id,

      proof_of_concept:
        returnsFormSubmit.proof_of_concept == ""
          ? 0
          : returnsFormSubmit.proof_of_concept,

      prototype_developement:
        returnsFormSubmit.prototype_developement == ""
          ? 0
          : returnsFormSubmit.prototype_developement,

      product_development:
        returnsFormSubmit.product_developement == ""
          ? 0
          : returnsFormSubmit.product_developement,

      field_trials:
        returnsFormSubmit.field_trials == ""
          ? 0
          : returnsFormSubmit.field_trials,

      market_launch:
        returnsFormSubmit.market_launch == ""
          ? 0
          : returnsFormSubmit.market_launch,

      loans: returnsFormSubmit.loans,

      angel_funding: returnsFormSubmit.angel_funding,

      vc_funding: returnsFormSubmit.vc_funding,

      others: returnsFormSubmit.others,

      no_of_employee:
        returnsFormSubmit.no_of_employee == ""
          ? 0
          : returnsFormSubmit.no_of_employee,

      turnover_of_startup: returnsFormSubmit.turnover_of_startup,

      no_of_ip_registration:
        returnsFormSubmit.no_of_ip_registration == ""
          ? 0
          : returnsFormSubmit.no_of_ip_registration,

      gst_filled:
        returnsFormSubmit.gst_filled == "" ? 0 : returnsFormSubmit.gst_filled,

      products_on_gem_portal:
        returnsFormSubmit.products_on_gem_portal == ""
          ? 0
          : returnsFormSubmit.products_on_gem_portal,

      agrrement_startup: returnsFormSubmit.agrrement_startup,

      engagement_startup: returnsFormSubmit.engagement_startup,

      remarks: returnsFormSubmit.remarks,

      success_stories: returnsFormSubmit.success_stories,

      description: returnsFormSubmit.description,

      agreement_date:
        returnsFormSubmit.agreement_date == undefined
          ? null
          : returnsFormSubmit.agreement_date.slice(0, 10),

      purpose_of_funds_requested: returnsFormSubmit.purpose_of_funds_requested,

      logged_user_id: userInfo.id,

      confirmation: 0,

      QRID: returnsFormLength == 0 ? "" : returnsFormSubmit.id,

      quaterly_review_id:
        qrDetails.length != 0 ? qrDetails[0]?.id : reviewId ? reviewId : 0,

      year: ChangeYearmonth && ChangeYearmonth.year,
      quarter: ChangeYearmonth && ChangeYearmonth.quarter,
      is_QR_ongoing: onGoingData,
    };

    // console.log(payload);

    // return;
    setLoader(true);
    post(QUARTERLY_REPORTS_PROGRESS_TAB, payload).then((res: any) => {
      // console.log(res.data.data);
      setLoader(false);
      if (res.data.result == "success") {
        setDialogData({
          header: "Thank You!",
          text: res.data.message,
        });
        setShowIncubatorSuccessModal(true);
        setdisabled_part2(true);
      } else {
        setFailDialogData({
          header: "",
          text: res.data.message,
        });
        setShowIncubatorFailureModal(true);
      }
    });
  };
  const handleChangeYearMonth = (event: any) => {
    const { name, value, type } = event.target;
    const newValue = value;

    // if (newValue == 2023 && currentQuarter == 3) {
    //   // console.log("ielse fffff","ifCondition");
    //   for (let i = 3; i >= 2; i--) {
    //     let data = i.toString()
    //     chooseQuarter.push(data)
    //   }
    // }
    // else
    if (newValue == 2023 && currentQuarter == 4) {
      // console.log("ielse fffff","ifCondition");
      for (let i = 4; i >= 2; i--) {
        let data = i.toString();
        chooseQuarter.push(data);
      }
    } else if (newValue == currentYear) {
      // console.log("ifffff","ifCondition");
      for (let i = currentQuarter; i >= 1; i--) {
        let data = i.toString();
        chooseQuarter.push(data);
      }
    } else if (newValue != currentYear && newValue == 2023) {
      // console.log("else","ifCondition");
      for (let i = 4; i >= 2; i--) {
        let data = i.toString();
        chooseQuarter.push(data);
      }
    } else if (newValue != currentYear) {
      // console.log("else","ifCondition");
      for (let i = 4; i >= 1; i--) {
        let data = i.toString();
        chooseQuarter.push(data);
      }
    }

    setNewQuarter(chooseQuarter);
    // console.log(qrDetails.length,"qrDetailssss")
    setChangeYearmonth((prevFormData: any) => ({
      ...prevFormData,
      [name]: newValue,
    }));

    const year = name == "year" && newValue;
    const quater = name == "quarter" && newValue;

    Year_Quater_API(year, quater);
    // previousQRDeatails(year,quater)
  };
  const handleChangeYearMonth1 = (event: any) => {
    const { name, value, type } = event.target;
    // console.log(event);
    const newValue = value;

    // console.log(qrDetails.length,"qrDetailssss")
    setChangeYearmonth((prevFormData: any) => ({
      ...prevFormData,
      [name]: newValue,
    }));

    const year = name == "year" && newValue;
    const quater = name == "quarter" && newValue;
    Year_Quater_API(year, quater);
    // previousQRDeatails(year,quater)
  };

  const dismissModal = () => {
    window.location.reload();
  };

  return (
    <div>
      {loader && (
        <div className="spinner-border custom-spinner" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}
      <fieldset disabled={loader}>
        <div className="tab-content">
          {/* {activeTab === 1 &&  */}
          <fieldset>
            {
              <div>
                <fieldset disabled={qrDetails.length != 0}>
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <label className="col-form-head">Choose year</label>

                      <div className="select-custom">
                        <select
                          name="year"
                          onChange={handleChangeYearMonth}
                          className="form-control input-height"
                          aria-label="Select Year"
                          value={ChangeYearmonth && ChangeYearmonth.year}
                        >
                          <option value="">Please select Year</option>
                          {/* <option value={currentYear + 1}>{currentYear + 1}</option> */}
                          {chooseYear.map((ele: any) => (
                            <option value={ele}>{ele}</option>
                          ))}
                          {/* <option value={currentYear}>{currentYear}</option> */}
                          {/* <option value={currentYear - 2}>{currentYear - 2}</option>
                                        <option value={currentYear - 3}>{currentYear - 3}</option>
                                        <option value={currentYear - 4}>{currentYear - 4}</option> */}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <label className="col-form-head">Choose Quarter</label>
                      <div className="select-custom">
                        <select
                          name="quarter"
                          onChange={handleChangeYearMonth1}
                          className="form-control input-height"
                          aria-label="Select Year"
                          value={ChangeYearmonth && ChangeYearmonth.quarter}
                        >
                          <option value="">Please select Quarter</option>
                          {qrDetails.length > 0 ? (
                            <option
                              value={ChangeYearmonth && ChangeYearmonth.quarter}
                            >
                              {ChangeYearmonth && ChangeYearmonth.quarter == "1"
                                ? "Jan - Mar"
                                : ChangeYearmonth &&
                                  ChangeYearmonth.quarter == "2"
                                ? "Apr - Jun"
                                : ChangeYearmonth &&
                                  ChangeYearmonth.quarter == "3"
                                ? "Jul - Sep"
                                : "Oct - Dec"}
                            </option>
                          ) : (
                            <>
                              {newQuarter.length &&
                                newQuarter.map((item: any) => (
                                  <option value={item}>
                                    {item == 1
                                      ? "Jan - Mar"
                                      : item == 2
                                      ? "Apr - Jun"
                                      : item == 3
                                      ? "Jul - Sep"
                                      : "Oct - Dec"}
                                  </option>
                                ))}
                            </>
                          )}
                          {/* {
                            newQuarter.length && newQuarter.map((item: any) =>
                            
                              <option value={item}>
                                {item == 1 ? 'Jan - Mar' : item == 2 ? 'Apr - Jun' : item == 3 ? 'Jul - Sep' : 'Oct - Dec'}
                                {console.log(item,"itemmm")}
                              </option>
                            )
                          } */}
                          {/* { currentQuarter === 1 && (
                          <>
                            <option value="1">Jan - Mar</option>
                            <option value="4">Oct - Dec</option>
                          </>
                        )}
                        {currentQuarter === 2 && (
                          <>
                            <option value="2">Apr - Jun</option>
                            <option value="1">Jan - Mar</option>
                          </>
                        )}
                        {currentQuarter === 3 && (
                          <>
                            <option value="3">Jul - Sep</option>
                            <option value="2">Apr - Jun</option>
                          </>
                        )}
                        {currentQuarter === 4 && (
                          <>
                            <option value="4">Oct - Dec</option>
                            <option value="3">Jul - Sep</option>
                          </>
                        )} */}
                        </select>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <fieldset disabled={loggedInRole == "secretariat"}>
                  <div>
                    <div>
                      <hr />
                      {/* <div className="d-flex flex-row justify-content-end mb-3">
                                    <button className="btn btn-sisfs btn-yellow-primary mr-3" onClick={() => { setdisabled_part2(false) }}>Edit</button>
                                </div> */}
                      {/* {console.log(returnsFormLength,  'YearAndQuater')}
                                {console.log(qrDetails.length, 'QRDetailsLength')}
                                {console.log(qrDetails.length && qrDetails[0].year, 'QRDetailsYear')}
                                {console.log(qrDetails.length && qrDetails[0].quarter, 'QRDetailsQuater')}
                                {console.log(returnsFormLength && ChangeYearmonth.year, 'changesYear')}
                                {console.log(returnsFormLength && ChangeYearmonth.quarter, 'changesQuater')}
                                {console.log((returnsFormLength != 0 && returnsFormSubmit != undefined && ChangeYearmonth.year && ChangeYearmonth.quarter))}
                                {console.log((returnsFormSubmit != undefined))} */}
                      {/* {console.log(qrDetails[0].year)}
                                {console.log(qrDetails[0].quarter)}
                                {console.log(ChangeYearmonth.year)}
                                {console.log(ChangeYearmonth.quarter)} */}
                      {/* {console.log(quater1)} */}

                      {((returnsFormLength == 0 || returnsFormLength != 0) &&
                        qrDetails.length &&
                        qrDetails[0]?.year ==
                          (ChangeYearmonth && ChangeYearmonth.year) &&
                        qrDetails[0]?.quarter ==
                          (ChangeYearmonth && ChangeYearmonth.quarter)) ||
                      (returnsFormLength != 0 &&
                        qrDetails.length &&
                        (qrDetails[0].year !=
                          (ChangeYearmonth && ChangeYearmonth.year) ||
                          qrDetails[0].quarter != ChangeYearmonth.quarter)) ||
                      (returnsFormLength != 0 &&
                        returnsFormSubmit != undefined &&
                        ChangeYearmonth &&
                        ChangeYearmonth.year != "0" &&
                        ChangeYearmonth &&
                        ChangeYearmonth.quarter != "0") ? (
                        <form className="mb-3" onSubmit={SubmitForm}>
                          {/* || (quaterlyReports_returns.length && quaterlyReports_returns[0].progress_overview_review == "1") */}

                          <fieldset
                            disabled={
                              returnsFormLength &&
                              checkQuarter == "closed" &&
                              returnsFormLength &&
                              qrDetails &&
                              (qrDetails[0]?.year !=
                                (ChangeYearmonth && ChangeYearmonth.year) ||
                                qrDetails[0]?.quarter !=
                                  (ChangeYearmonth && ChangeYearmonth.quarter))
                            }
                          >
                            <h6
                              style={{
                                textAlign: "center",
                                backgroundColor: "#c3dbc445",
                                padding: "5px",
                              }}
                            >
                              <b>
                                Quarterly Review (QR) On-going for{" "}
                                {quater1 ||
                                  (ChangeYearmonth &&
                                    ChangeYearmonth.quarter)}{" "}
                                {(qrDetails.length && qrDetails[0].year) ||
                                  (ChangeYearmonth && ChangeYearmonth.year)}
                              </b>
                            </h6>
                            <div className="row">
                              {/* {console.log(returnsFormSubmit)} */}
                              <div className="col-md-12 mt-3">
                                <label className="form-label required">
                                  <b>Description of the Startup</b>
                                  <span className="text-danger">
                                    <b> *</b>
                                  </span>
                                </label>
                                <textarea
                                  className="form-control"
                                  name="description"
                                  value={
                                    returnsFormSubmit
                                      ? returnsFormSubmit.description
                                      : ""
                                  }
                                  placeholder="Description"
                                  onChange={handleChange}
                                  required
                                ></textarea>
                              </div>
                              <div className="col-md-6 mt-3">
                                <label className="form-label required">
                                  <b>Agreement Signed with the Startup</b>
                                  <span className="text-danger">
                                    <b> *</b>
                                  </span>
                                </label>
                                <select
                                  className="form-control"
                                  name="agrrement_startup"
                                  value={
                                    returnsFormSubmit
                                      ? returnsFormSubmit.agrrement_startup
                                      : ""
                                  }
                                  aria-label="Select Entity Type"
                                  onChange={handleChange}
                                  required
                                >
                                  <option value="">Select</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                              </div>
                              <div className="col-md-6 mt-3">
                                <label className="form-label required">
                                  <b>Medium of Engagement with the Startup</b>
                                  <span className="text-danger">
                                    <b> *</b>
                                  </span>
                                </label>
                                {/* {console.log((returnsFormSubmit && returnsFormSubmit.engagement_startup))} */}
                                <select
                                  className="form-control"
                                  name="engagement_startup"
                                  value={
                                    returnsFormSubmit
                                      ? returnsFormSubmit.engagement_startup
                                      : ""
                                  }
                                  aria-label="Select Entity Type"
                                  onChange={handleChange}
                                  required
                                >
                                  <option value="">Select</option>
                                  <option value="physical">Physical</option>
                                  <option value="virtual">Virtual</option>
                                </select>
                              </div>
                              <div className="col-md-6 mt-3">
                                <label className="form-label required">
                                  <b>Agreement Date</b>
                                </label>
                                <input
                                  type="date"
                                  name="agreement_date"
                                  disabled={
                                    returnsFormSubmit &&
                                    returnsFormSubmit.agrrement_startup == "No"
                                  }
                                  value={
                                    returnsFormSubmit
                                      ? returnsFormSubmit.agreement_date ==
                                          null ||
                                        returnsFormSubmit.agrrement_startup ==
                                          "No"
                                        ? ""
                                        : returnsFormSubmit.agreement_date.slice(
                                            0,
                                            10
                                          )
                                      : ""
                                  }
                                  className="form-control"
                                  onChange={handleChange}
                                  required
                                />
                              </div>
                              <div className="col-md-6 mt-3"></div>
                            </div>
                            <br />
                            <hr />
                            <div className="row">
                              <div className="col-md-6 mt-3">
                                <div className="d-flex flex-row justify-content-between">
                                  <h6 style={{ marginBottom: "10px" }}>
                                    <b>Progress of Startup</b>
                                  </h6>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip
                                        id="example-tooltip"
                                        style={{ fontSize: "20px" }}
                                      >
                                        If all the stages have been marked not
                                        applicable, please provide the progress
                                        / stage of startup in the remarks
                                      </Tooltip>
                                    }
                                  >
                                    <button
                                      type="button"
                                      style={{
                                        borderRadius: "20px",
                                        backgroundColor: "#000000",
                                        color: "#ffffff",
                                        border: "none",
                                        width: "20px",
                                        height: "20px",
                                      }}
                                    >
                                      !
                                    </button>
                                  </OverlayTrigger>
                                  {/* <button type="button" style={{ borderRadius: '20px', backgroundColor: '#000000', color: '#ffffff', border: "none", width: '25px', height: '25px' }} data-bs-toggle="tooltip" data-bs-placement="top" title="As all the stages have been marked not applicable, please provide the progress / stage of startup in the remarks(>> This message is only visible, if all the stages are selected as not applicable">!</button> */}
                                </div>
                                <div className="row">
                                  <div className="col-12 mt-3">
                                    <label className="form-label required">
                                      <b>Proof of Concept</b>
                                      <span className="text-danger">
                                        <b> *</b>
                                      </span>
                                    </label>
                                    <br />
                                    <select
                                      className="form-control"
                                      name="proof_of_concept"
                                      value={
                                        returnsFormSubmit
                                          ? returnsFormSubmit.proof_of_concept
                                          : ""
                                      }
                                      aria-label="Select Entity Type"
                                      onChange={handleChange}
                                      required
                                    >
                                      <option value="">Select</option>
                                      <option value="1">Completed</option>
                                      <option value="2">In-Progress</option>
                                      <option value="3">Not Started</option>
                                      <option value="4">
                                        Completed Pre-SISFS
                                      </option>
                                      <option value="5">Not Applicable</option>
                                    </select>
                                  </div>
                                  <div className="col-12 mt-3">
                                    <label className="form-label required">
                                      <b>Prototype development </b>
                                      <span className="text-danger">
                                        <b> *</b>
                                      </span>
                                    </label>
                                    <br />
                                    <select
                                      className="form-control"
                                      name="prototype_developement"
                                      value={
                                        returnsFormSubmit
                                          ? returnsFormSubmit.prototype_developement
                                          : ""
                                      }
                                      aria-label="Select Entity Type"
                                      onChange={handleChange}
                                      required
                                    >
                                      <option value="">Select</option>
                                      <option value="1">Completed</option>
                                      <option value="2">In-Progress</option>
                                      <option value="3">Not Started</option>
                                      <option value="4">
                                        Completed Pre-SISFS
                                      </option>
                                      <option value="5">Not Applicable</option>
                                    </select>
                                  </div>
                                  <div className="col-12 mt-3">
                                    <label className="form-label required">
                                      <b>Product Development </b>
                                      <span className="text-danger">
                                        <b> *</b>
                                      </span>
                                    </label>
                                    <br />
                                    <select
                                      className="form-control"
                                      name="product_developement"
                                      value={
                                        returnsFormSubmit
                                          ? returnsFormSubmit.product_developement
                                          : ""
                                      }
                                      aria-label="Select Entity Type"
                                      onChange={handleChange}
                                      required
                                    >
                                      <option value="">Select</option>
                                      <option value="1">Completed</option>
                                      <option value="2">In-Progress</option>
                                      <option value="3">Not Started</option>
                                      <option value="4">
                                        Completed Pre-SISFS
                                      </option>
                                      <option value="5">Not Applicable</option>
                                    </select>
                                  </div>
                                  <div className="col-12 mt-3">
                                    <label className="form-label required">
                                      <b>Field Trials </b>
                                      <span className="text-danger">
                                        <b> *</b>
                                      </span>
                                    </label>
                                    <br />
                                    <select
                                      className="form-control"
                                      name="field_trials"
                                      value={
                                        returnsFormSubmit
                                          ? returnsFormSubmit.field_trials
                                          : ""
                                      }
                                      aria-label="Select Entity Type"
                                      onChange={handleChange}
                                      required
                                    >
                                      <option value="">Select</option>
                                      <option value="1">Completed</option>
                                      <option value="2">In-Progress</option>
                                      <option value="3">Not Started</option>
                                      <option value="4">
                                        Completed Pre-SISFS
                                      </option>
                                      <option value="5">Not Applicable</option>
                                    </select>
                                  </div>
                                  <div className="col-12 mt-3">
                                    <label className="form-label required">
                                      <b>Market Launch</b>
                                      <span className="text-danger">
                                        <b> *</b>
                                      </span>
                                    </label>
                                    <br />
                                    <select
                                      className="form-control"
                                      name="market_launch"
                                      value={
                                        returnsFormSubmit
                                          ? returnsFormSubmit.market_launch
                                          : ""
                                      }
                                      aria-label="Select Entity Type"
                                      onChange={handleChange}
                                      required
                                    >
                                      <option value="">Select</option>
                                      <option value="1">Completed</option>
                                      <option value="2">In-Progress</option>
                                      <option value="3">Not Started</option>
                                      <option value="4">
                                        Completed Pre-SISFS
                                      </option>
                                      <option value="5">Not Applicable</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 mt-3">
                                <div className="d-flex flex-row justify-content-between">
                                  <h6>
                                    <b>Quantum of Funds Raised Externally</b>
                                  </h6>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip
                                        id="example-tooltip"
                                        style={{ fontSize: "16px" }}
                                      >
                                        Enter exact amount. For ex :- 4 Lakhs as
                                        400000. No special charecters except
                                        decimal is allowed.
                                      </Tooltip>
                                    }
                                  >
                                    <button
                                      type="button"
                                      style={{
                                        borderRadius: "20px",
                                        backgroundColor: "#000000",
                                        color: "#ffffff",
                                        border: "none",
                                        width: "20px",
                                        height: "20px",
                                      }}
                                    >
                                      !
                                    </button>
                                  </OverlayTrigger>
                                </div>
                                <div className="row">
                                  <div className="col-12 mt-3">
                                    <label className="form-label required">
                                      <b>Loans</b>
                                      <span className="text-danger">
                                        <b> *</b>
                                      </span>
                                    </label>
                                    <br />
                                    <input
                                      className="form-control"
                                      name="loans"
                                      value={
                                        returnsFormSubmit
                                          ? returnsFormSubmit.loans
                                          : ""
                                      }
                                      placeholder="Enter Loans"
                                      onChange={handleChange}
                                      required
                                    />
                                  </div>
                                  <div className="col-12 mt-3">
                                    <label className="form-label required">
                                      <b>Angel Funding</b>
                                      <span className="text-danger">
                                        <b> *</b>
                                      </span>
                                    </label>
                                    <br />
                                    <input
                                      className="form-control"
                                      name="angel_funding"
                                      value={
                                        returnsFormSubmit
                                          ? returnsFormSubmit.angel_funding
                                          : ""
                                      }
                                      placeholder="Angel Funding"
                                      onChange={handleChange}
                                      required
                                    />
                                  </div>
                                  <div className="col-12 mt-3">
                                    <label className="form-label required">
                                      <b>VC Funding</b>
                                      <span className="text-danger">
                                        <b> *</b>
                                      </span>
                                    </label>
                                    <br />
                                    <input
                                      className="form-control"
                                      name="vc_funding"
                                      value={
                                        returnsFormSubmit
                                          ? returnsFormSubmit.vc_funding
                                          : ""
                                      }
                                      placeholder="VC Funding"
                                      onChange={handleChange}
                                      required
                                    />
                                  </div>
                                  <div className="col-12 mt-3">
                                    <label className="form-label required">
                                      <b>Others</b>
                                      <span className="text-danger">
                                        <b> *</b>
                                      </span>
                                    </label>
                                    <br />
                                    <input
                                      className="form-control"
                                      name="others"
                                      value={
                                        returnsFormSubmit
                                          ? returnsFormSubmit.others
                                          : ""
                                      }
                                      placeholder="Others"
                                      onChange={handleChange}
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <br />
                            <hr />
                            <div className="row">
                              <div className="col-md-6 mt-3">
                                <label
                                  className="form-label required"
                                  style={{ marginBottom: "12px" }}
                                >
                                  <b>No of People Employed </b>
                                  <span className="text-danger">
                                    <b> *</b>
                                  </span>
                                </label>
                                <input
                                  className="form-control"
                                  name="no_of_employee"
                                  value={
                                    returnsFormSubmit
                                      ? returnsFormSubmit.no_of_employee
                                      : ""
                                  }
                                  placeholder="No of People Employed"
                                  onChange={handleChange}
                                  required
                                />
                              </div>
                              <div className="col-md-6 mt-3">
                                <label className="form-label required d-flex justify-content-between">
                                  <b>
                                    Turnover of Startup in the Quarter (INR)
                                    <span className="text-danger"> *</span>
                                  </b>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip
                                        id="example-tooltip"
                                        style={{ fontSize: "16px" }}
                                      >
                                        Enter exact amount, No special
                                        characters except decimal is allowed.
                                      </Tooltip>
                                    }
                                  >
                                    <button
                                      type="button"
                                      style={{
                                        borderRadius: "20px",
                                        backgroundColor: "#000000",
                                        color: "#ffffff",
                                        border: "none",
                                        width: "20px",
                                        height: "20px",
                                      }}
                                    >
                                      !
                                    </button>
                                  </OverlayTrigger>
                                  {/* <button type="button" style={{ borderRadius: '20px', backgroundColor: '#000000', color: '#ffffff', border: "none", width: '25px', height: '25px' }} data-bs-toggle="tooltip" data-bs-placement="top" title="For Example:- 7Lakhs is 7,00,000">!</button> */}
                                </label>
                                <input
                                  className="form-control"
                                  value={
                                    returnsFormSubmit
                                      ? returnsFormSubmit.turnover_of_startup
                                      : ""
                                  }
                                  name="turnover_of_startup"
                                  placeholder="Turnover of Startup in the Quarter"
                                  onChange={handleChange}
                                  required
                                />
                              </div>
                              <div className="col-md-6 mt-3">
                                <label
                                  className="form-label required"
                                  style={{ marginBottom: "28px" }}
                                >
                                  <b>
                                    Cumulative number of IP Registrations
                                    Request filed{" "}
                                  </b>
                                  <span className="text-danger">
                                    <b> *</b>
                                  </span>
                                </label>
                                <input
                                  className="form-control"
                                  name="no_of_ip_registration"
                                  value={
                                    returnsFormSubmit
                                      ? returnsFormSubmit.no_of_ip_registration
                                      : ""
                                  }
                                  placeholder="Cumulative number of IP Registrations"
                                  onChange={handleChange}
                                  required
                                />
                              </div>
                              <div className="col-md-6 mt-3">
                                <label
                                  className="form-label required d-flex justify-content-between"
                                  style={{ marginBottom: "25px" }}
                                >
                                  <b>
                                    Total GST billed in the Quarter (including
                                    CGST, SGST, IGST) etc
                                    <span className="text-danger"> *</span>
                                  </b>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip
                                        id="example-tooltip"
                                        style={{ fontSize: "16px" }}
                                      >
                                        Enter exact amount, No special
                                        characters except decimal is allowed.
                                      </Tooltip>
                                    }
                                  >
                                    <button
                                      type="button"
                                      style={{
                                        borderRadius: "20px",
                                        backgroundColor: "#000000",
                                        color: "#ffffff",
                                        border: "none",
                                        width: "20px",
                                        height: "20px",
                                      }}
                                    >
                                      !
                                    </button>
                                  </OverlayTrigger>
                                  {/* <button type="button" style={{ borderRadius: '20px', backgroundColor: '#000000', color: '#ffffff', border: "none", width: '25px', height: '25px' }} data-bs-toggle="tooltip" data-bs-placement="top" title="For Example:- 7Lakhs is 7,00,000">!</button> */}
                                </label>
                                <input
                                  className="form-control"
                                  name="gst_filled"
                                  value={
                                    returnsFormSubmit
                                      ? returnsFormSubmit.gst_filled
                                      : ""
                                  }
                                  placeholder="Total GST billed in the Quarter"
                                  onChange={handleChange}
                                  required
                                />
                              </div>
                              <div className="col-md-6 mt-3">
                                <label className="form-label required">
                                  <b>
                                    Cumulative number of Products and services
                                    live on GEM Portal
                                  </b>
                                  <span className="text-danger">
                                    <b> *</b>
                                  </span>
                                </label>
                                <input
                                  className="form-control"
                                  name="products_on_gem_portal"
                                  value={
                                    returnsFormSubmit
                                      ? returnsFormSubmit.products_on_gem_portal
                                      : ""
                                  }
                                  placeholder="Cumulative number of Products"
                                  onChange={handleChange}
                                  required
                                />
                              </div>
                              <div className="col-md-6 mt-3"></div>
                              <div className="col-md-6 mt-3">
                                <label className="form-label required d-flex justify-content-between">
                                  <b>Success Stories</b>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip
                                        id="example-tooltip"
                                        style={{ fontSize: "20px" }}
                                      >
                                        For success stories of startups, please
                                        mention links to articles, media
                                        coverage, or any other related material,
                                        as links (if any) within the textbox
                                        (For Example: Google Drive links etc.).
                                      </Tooltip>
                                    }
                                  >
                                    <button
                                      type="button"
                                      style={{
                                        borderRadius: "20px",
                                        backgroundColor: "#000000",
                                        color: "#ffffff",
                                        border: "none",
                                        width: "25px",
                                        height: "25px",
                                      }}
                                    >
                                      !
                                    </button>
                                  </OverlayTrigger>
                                </label>
                                <textarea
                                  className="form-control"
                                  name="success_stories"
                                  value={
                                    returnsFormSubmit
                                      ? returnsFormSubmit.success_stories
                                      : ""
                                  }
                                  placeholder="Success Stories"
                                  onChange={handleChange}
                                ></textarea>
                              </div>
                              <div className="col-md-6 mt-3">
                                <label className="form-label required">
                                  <b>Remarks</b>
                                </label>
                                <textarea
                                  className="form-control"
                                  name="remarks"
                                  value={
                                    returnsFormSubmit
                                      ? returnsFormSubmit.remarks
                                      : ""
                                  }
                                  placeholder="Remarks"
                                  onChange={handleChange}
                                ></textarea>
                              </div>
                              <div className="col-6 mt-3">
                                <label className="form-label required">
                                  <b>Purpose of Requested funds</b>
                                  <span className="text-danger">
                                    <b> *</b>
                                  </span>
                                </label>
                                <br />
                                <select
                                  className="form-control"
                                  name="purpose_of_funds_requested"
                                  value={
                                    returnsFormSubmit
                                      ? returnsFormSubmit.purpose_of_funds_requested
                                      : ""
                                  }
                                  aria-label="Select Entity Type"
                                  onChange={handleChange}
                                  required
                                >
                                  <option value="">Select</option>
                                  <option value="1">Proof of concept</option>
                                  <option value="2">
                                    Prototype Development
                                  </option>
                                  <option value="3">Product Trial</option>
                                  <option value="4">Market Entry</option>
                                  <option value="5">Commercialisation</option>
                                  <option value="6">Scaling Up</option>
                                </select>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-6">
                                {loggedInRole == "secretariat" ? (
                                  <></>
                                ) : (
                                  <>
                                    <button
                                      className="btn btn-sisfs btn-yellow-primary"
                                      type="button"
                                      onClick={() => saveClick()}
                                    >
                                      Save
                                    </button>
                                  </>
                                )}
                              </div>
                              <div className="col-6">
                                {/* <button className="btn btn-sisfs btn-yellow-primary" type="submit" style={{ float: 'right' }} >Confirm Quarterly Review</button> */}
                                {loggedInRole == "secretariat" ? (
                                  <></>
                                ) : (
                                  <>
                                    {(quaterlyReports_returns.length &&
                                      quaterlyReports_returns[0]
                                        .progress_overview_review == "0") ||
                                    quaterlyReports_returns.length == 0 ? (
                                      <button
                                        className="btn btn-sisfs btn-yellow-primary"
                                        type="submit"
                                        style={{ float: "right" }}
                                      >
                                        <div className="d-flex flex-row justify-content-between">
                                          <span>Confirm Quarterly Review</span>
                                        </div>
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn-sisfs btn-yellow-primary"
                                        type="submit"
                                        style={{ float: "right" }}
                                        disabled={
                                          quaterlyReports_returns.length &&
                                          quaterlyReports_returns[0]
                                            .progress_overview_review == "1"
                                        }
                                      >
                                        <div className="d-flex flex-row justify-content-between">
                                          <span>
                                            Quarterly Review Confirmed
                                          </span>
                                          {quaterlyReports_returns.length &&
                                          quaterlyReports_returns[0]
                                            .progress_overview_review == "1" &&
                                          true ? (
                                            <img
                                              src={SuccessCheck}
                                              alt="SISFS Logo"
                                              style={{
                                                marginRight: "15px",
                                                cursor: "pointer",
                                              }}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </button>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </fieldset>
                        </form>
                      ) : returnsFormLength == 0 &&
                        qrDetails.length &&
                        (qrDetails[0]?.year != ChangeYearmonth.year ||
                          qrDetails[0]?.quarter != ChangeYearmonth.quarter) ? (
                        <div>
                          <h1
                            style={{
                              color: "red",
                              textAlign: "center",
                              marginTop: "50px",
                            }}
                          >
                            No Data
                          </h1>
                        </div>
                      ) : qrDetails.length == 0 &&
                        ChangeYearmonth?.year &&
                        ChangeYearmonth?.quarter ? (
                        <div>
                          <form className="mb-3" onSubmit={SubmitForm}>
                            {/* || (quaterlyReports_returns.length && quaterlyReports_returns[0].progress_overview_review == "1") */}

                            <fieldset disabled={checkQuarter == "closed"}>
                              <h6
                                style={{
                                  textAlign: "center",
                                  backgroundColor: "#c3dbc445",
                                  padding: "5px",
                                }}
                              >
                                <b>
                                  Quarterly Review (QR) On-going for{" "}
                                  {quater1 || ChangeYearmonth.quarter}{" "}
                                  {(qrDetails.length && qrDetails[0].year) ||
                                    ChangeYearmonth.year}
                                </b>
                              </h6>
                              <div className="row">
                                <div className="col-md-12 mt-3">
                                  <label className="form-label required">
                                    <b>Description of the Startup</b>
                                    <span className="text-danger">
                                      <b> *</b>
                                    </span>
                                  </label>
                                  <textarea
                                    className="form-control"
                                    name="description"
                                    value={
                                      returnsFormSubmit
                                        ? returnsFormSubmit.description
                                        : ""
                                    }
                                    placeholder="Description"
                                    onChange={handleChange}
                                    required
                                  ></textarea>
                                </div>
                                <div className="col-md-6 mt-3">
                                  <label className="form-label required">
                                    <b>Agreement Signed with the Startup</b>
                                    <span className="text-danger">
                                      <b> *</b>
                                    </span>
                                  </label>
                                  <select
                                    className="form-control"
                                    name="agrrement_startup"
                                    value={
                                      returnsFormSubmit
                                        ? returnsFormSubmit.agrrement_startup
                                        : ""
                                    }
                                    aria-label="Select Entity Type"
                                    onChange={handleChange}
                                    required
                                  >
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                </div>
                                <div className="col-md-6 mt-3">
                                  <label className="form-label required">
                                    <b>Medium of Engagement with the Startup</b>
                                    <span className="text-danger">
                                      <b> *</b>
                                    </span>
                                  </label>
                                  {/* {console.log((returnsFormSubmit && returnsFormSubmit.engagement_startup))} */}
                                  <select
                                    className="form-control"
                                    name="engagement_startup"
                                    value={
                                      returnsFormSubmit
                                        ? returnsFormSubmit.engagement_startup
                                        : ""
                                    }
                                    aria-label="Select Entity Type"
                                    onChange={handleChange}
                                    required
                                  >
                                    <option value="">Select</option>
                                    <option value="physical">Physical</option>
                                    <option value="virtual">Virtual</option>
                                  </select>
                                </div>
                                <div className="col-md-6 mt-3">
                                  <label className="form-label required">
                                    <b>Agreement Date</b>
                                  </label>
                                  <input
                                    type="date"
                                    name="agreement_date"
                                    disabled={
                                      returnsFormSubmit &&
                                      returnsFormSubmit.agrrement_startup ==
                                        "No"
                                    }
                                    value={
                                      returnsFormSubmit
                                        ? returnsFormSubmit.agreement_date ==
                                            null ||
                                          returnsFormSubmit.agrrement_startup ==
                                            "No"
                                          ? ""
                                          : returnsFormSubmit.agreement_date.slice(
                                              0,
                                              10
                                            )
                                        : ""
                                    }
                                    className="form-control"
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                                <div className="col-md-6 mt-3"></div>
                              </div>
                              <br />
                              <hr />
                              <div className="row">
                                <div className="col-md-6 mt-3">
                                  <div className="d-flex flex-row justify-content-between">
                                    <h6 style={{ marginBottom: "10px" }}>
                                      <b>Progress of Startup</b>
                                    </h6>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip
                                          id="example-tooltip"
                                          style={{ fontSize: "20px" }}
                                        >
                                          If all the stages have been marked not
                                          applicable, please provide the
                                          progress / stage of startup in the
                                          remarks
                                        </Tooltip>
                                      }
                                    >
                                      <button
                                        type="button"
                                        style={{
                                          borderRadius: "20px",
                                          backgroundColor: "#000000",
                                          color: "#ffffff",
                                          border: "none",
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      >
                                        !
                                      </button>
                                    </OverlayTrigger>
                                    {/* <button type="button" style={{ borderRadius: '20px', backgroundColor: '#000000', color: '#ffffff', border: "none", width: '25px', height: '25px' }} data-bs-toggle="tooltip" data-bs-placement="top" title="As all the stages have been marked not applicable, please provide the progress / stage of startup in the remarks(>> This message is only visible, if all the stages are selected as not applicable">!</button> */}
                                  </div>
                                  <div className="row">
                                    <div className="col-12 mt-3">
                                      <label className="form-label required">
                                        <b>Proof of Concept</b>
                                        <span className="text-danger">
                                          <b> *</b>
                                        </span>
                                      </label>
                                      <br />
                                      <select
                                        className="form-control"
                                        name="proof_of_concept"
                                        value={
                                          returnsFormSubmit
                                            ? returnsFormSubmit.proof_of_concept
                                            : ""
                                        }
                                        aria-label="Select Entity Type"
                                        onChange={handleChange}
                                        required
                                      >
                                        <option value="">Select</option>
                                        <option value="1">Completed</option>
                                        <option value="2">In-Progress</option>
                                        <option value="3">Not Started</option>
                                        <option value="4">
                                          Completed Pre-SISFS
                                        </option>
                                        <option value="5">
                                          Not Applicable
                                        </option>
                                      </select>
                                    </div>
                                    <div className="col-12 mt-3">
                                      <label className="form-label required">
                                        <b>Prototype development </b>
                                        <span className="text-danger">
                                          <b> *</b>
                                        </span>
                                      </label>
                                      <br />
                                      <select
                                        className="form-control"
                                        name="prototype_developement"
                                        value={
                                          returnsFormSubmit
                                            ? returnsFormSubmit.prototype_developement
                                            : ""
                                        }
                                        aria-label="Select Entity Type"
                                        onChange={handleChange}
                                        required
                                      >
                                        <option value="">Select</option>
                                        <option value="1">Completed</option>
                                        <option value="2">In-Progress</option>
                                        <option value="3">Not Started</option>
                                        <option value="4">
                                          Completed Pre-SISFS
                                        </option>
                                        <option value="5">
                                          Not Applicable
                                        </option>
                                      </select>
                                    </div>
                                    <div className="col-12 mt-3">
                                      <label className="form-label required">
                                        <b>Product Development </b>
                                        <span className="text-danger">
                                          <b> *</b>
                                        </span>
                                      </label>
                                      <br />
                                      <select
                                        className="form-control"
                                        name="product_developement"
                                        value={
                                          returnsFormSubmit
                                            ? returnsFormSubmit.product_developement
                                            : ""
                                        }
                                        aria-label="Select Entity Type"
                                        onChange={handleChange}
                                        required
                                      >
                                        <option value="">Select</option>
                                        <option value="1">Completed</option>
                                        <option value="2">In-Progress</option>
                                        <option value="3">Not Started</option>
                                        <option value="4">
                                          Completed Pre-SISFS
                                        </option>
                                        <option value="5">
                                          Not Applicable
                                        </option>
                                      </select>
                                    </div>
                                    <div className="col-12 mt-3">
                                      <label className="form-label required">
                                        <b>Field Trials </b>
                                        <span className="text-danger">
                                          <b> *</b>
                                        </span>
                                      </label>
                                      <br />
                                      <select
                                        className="form-control"
                                        name="field_trials"
                                        value={
                                          returnsFormSubmit
                                            ? returnsFormSubmit.field_trials
                                            : ""
                                        }
                                        aria-label="Select Entity Type"
                                        onChange={handleChange}
                                        required
                                      >
                                        <option value="">Select</option>
                                        <option value="1">Completed</option>
                                        <option value="2">In-Progress</option>
                                        <option value="3">Not Started</option>
                                        <option value="4">
                                          Completed Pre-SISFS
                                        </option>
                                        <option value="5">
                                          Not Applicable
                                        </option>
                                      </select>
                                    </div>
                                    <div className="col-12 mt-3">
                                      <label className="form-label required">
                                        <b>Market Launch</b>
                                        <span className="text-danger">
                                          <b> *</b>
                                        </span>
                                      </label>
                                      <br />
                                      <select
                                        className="form-control"
                                        name="market_launch"
                                        value={
                                          returnsFormSubmit
                                            ? returnsFormSubmit.market_launch
                                            : ""
                                        }
                                        aria-label="Select Entity Type"
                                        onChange={handleChange}
                                        required
                                      >
                                        <option value="">Select</option>
                                        <option value="1">Completed</option>
                                        <option value="2">In-Progress</option>
                                        <option value="3">Not Started</option>
                                        <option value="4">
                                          Completed Pre-SISFS
                                        </option>
                                        <option value="5">
                                          Not Applicable
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 mt-3">
                                  <div className="d-flex flex-row justify-content-between">
                                    <h6>
                                      <b>Quantum of Funds Raised Externally</b>
                                    </h6>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip
                                          id="example-tooltip"
                                          style={{ fontSize: "16px" }}
                                        >
                                          Enter exact amount. For ex :- 4 Lakhs
                                          as 400000. No special charecters
                                          except decimal is allowed.
                                        </Tooltip>
                                      }
                                    >
                                      <button
                                        type="button"
                                        style={{
                                          borderRadius: "20px",
                                          backgroundColor: "#000000",
                                          color: "#ffffff",
                                          border: "none",
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      >
                                        !
                                      </button>
                                    </OverlayTrigger>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 mt-3">
                                      <label className="form-label required">
                                        <b>Loans</b>
                                        <span className="text-danger">
                                          <b> *</b>
                                        </span>
                                      </label>
                                      <br />
                                      <input
                                        className="form-control"
                                        name="loans"
                                        value={
                                          returnsFormSubmit
                                            ? returnsFormSubmit.loans
                                            : ""
                                        }
                                        placeholder="Enter Loans"
                                        onChange={handleChange}
                                        required
                                      />
                                    </div>
                                    <div className="col-12 mt-3">
                                      <label className="form-label required">
                                        <b>Angel Funding</b>
                                        <span className="text-danger">
                                          <b> *</b>
                                        </span>
                                      </label>
                                      <br />
                                      <input
                                        className="form-control"
                                        name="angel_funding"
                                        value={
                                          returnsFormSubmit
                                            ? returnsFormSubmit.angel_funding
                                            : ""
                                        }
                                        placeholder="Angel Funding"
                                        onChange={handleChange}
                                        required
                                      />
                                    </div>
                                    <div className="col-12 mt-3">
                                      <label className="form-label required">
                                        <b>VC Funding</b>
                                        <span className="text-danger">
                                          <b> *</b>
                                        </span>
                                      </label>
                                      <br />
                                      <input
                                        className="form-control"
                                        name="vc_funding"
                                        value={
                                          returnsFormSubmit
                                            ? returnsFormSubmit.vc_funding
                                            : ""
                                        }
                                        placeholder="VC Funding"
                                        onChange={handleChange}
                                        required
                                      />
                                    </div>
                                    <div className="col-12 mt-3">
                                      <label className="form-label required">
                                        <b>Others</b>
                                        <span className="text-danger">
                                          <b> *</b>
                                        </span>
                                      </label>
                                      <br />
                                      <input
                                        className="form-control"
                                        name="others"
                                        value={
                                          returnsFormSubmit
                                            ? returnsFormSubmit.others
                                            : ""
                                        }
                                        placeholder="Others"
                                        onChange={handleChange}
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <br />
                              <hr />
                              <div className="row">
                                <div className="col-md-6 mt-3">
                                  <label
                                    className="form-label required"
                                    style={{ marginBottom: "12px" }}
                                  >
                                    <b>No of People Employed </b>
                                    <span className="text-danger">
                                      <b> *</b>
                                    </span>
                                  </label>
                                  <input
                                    className="form-control"
                                    name="no_of_employee"
                                    value={
                                      returnsFormSubmit
                                        ? returnsFormSubmit.no_of_employee
                                        : ""
                                    }
                                    placeholder="No of People Employed"
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                                <div className="col-md-6 mt-3">
                                  <label className="form-label required d-flex justify-content-between">
                                    <b>
                                      Turnover of Startup in the Quarter (INR)
                                      <span className="text-danger"> *</span>
                                    </b>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip
                                          id="example-tooltip"
                                          style={{ fontSize: "16px" }}
                                        >
                                          Enter exact amount, No special
                                          characters except decimal is allowed.
                                        </Tooltip>
                                      }
                                    >
                                      <button
                                        type="button"
                                        style={{
                                          borderRadius: "20px",
                                          backgroundColor: "#000000",
                                          color: "#ffffff",
                                          border: "none",
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      >
                                        !
                                      </button>
                                    </OverlayTrigger>
                                    {/* <button type="button" style={{ borderRadius: '20px', backgroundColor: '#000000', color: '#ffffff', border: "none", width: '25px', height: '25px' }} data-bs-toggle="tooltip" data-bs-placement="top" title="For Example:- 7Lakhs is 7,00,000">!</button> */}
                                  </label>
                                  <input
                                    className="form-control"
                                    value={
                                      returnsFormSubmit
                                        ? returnsFormSubmit.turnover_of_startup
                                        : ""
                                    }
                                    name="turnover_of_startup"
                                    placeholder="Turnover of Startup in the Quarter"
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                                <div className="col-md-6 mt-3">
                                  <label
                                    className="form-label required"
                                    style={{ marginBottom: "28px" }}
                                  >
                                    <b>
                                      Cumulative number of IP Registrations
                                      Request filed{" "}
                                    </b>
                                    <span className="text-danger">
                                      <b> *</b>
                                    </span>
                                  </label>
                                  <input
                                    className="form-control"
                                    name="no_of_ip_registration"
                                    value={
                                      returnsFormSubmit
                                        ? returnsFormSubmit.no_of_ip_registration
                                        : ""
                                    }
                                    placeholder="Cumulative number of IP Registrations"
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                                <div className="col-md-6 mt-3">
                                  <label
                                    className="form-label required d-flex justify-content-between"
                                    style={{ marginBottom: "25px" }}
                                  >
                                    <b>
                                      Total GST billed in the Quarter (including
                                      CGST, SGST, IGST) etc
                                      <span className="text-danger"> *</span>
                                    </b>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip
                                          id="example-tooltip"
                                          style={{ fontSize: "16px" }}
                                        >
                                          Enter exact amount, No special
                                          characters except decimal is allowed.
                                        </Tooltip>
                                      }
                                    >
                                      <button
                                        type="button"
                                        style={{
                                          borderRadius: "20px",
                                          backgroundColor: "#000000",
                                          color: "#ffffff",
                                          border: "none",
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      >
                                        !
                                      </button>
                                    </OverlayTrigger>
                                    {/* <button type="button" style={{ borderRadius: '20px', backgroundColor: '#000000', color: '#ffffff', border: "none", width: '25px', height: '25px' }} data-bs-toggle="tooltip" data-bs-placement="top" title="For Example:- 7Lakhs is 7,00,000">!</button> */}
                                  </label>
                                  <input
                                    className="form-control"
                                    name="gst_filled"
                                    value={
                                      returnsFormSubmit
                                        ? returnsFormSubmit.gst_filled
                                        : ""
                                    }
                                    placeholder="Total GST billed in the Quarter"
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                                <div className="col-md-6 mt-3">
                                  <label className="form-label required">
                                    <b>
                                      Cumulative number of Products and services
                                      live on GEM Portal
                                    </b>
                                    <span className="text-danger">
                                      <b> *</b>
                                    </span>
                                  </label>
                                  <input
                                    className="form-control"
                                    name="products_on_gem_portal"
                                    value={
                                      returnsFormSubmit
                                        ? returnsFormSubmit.products_on_gem_portal
                                        : ""
                                    }
                                    placeholder="Cumulative number of Products"
                                    onChange={handleChange}
                                    required
                                  />
                                </div>
                                <div className="col-md-6 mt-3"></div>
                                <div className="col-md-6 mt-3">
                                  <label className="form-label required d-flex justify-content-between">
                                    <b>Success Stories</b>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip
                                          id="example-tooltip"
                                          style={{ fontSize: "20px" }}
                                        >
                                          For success stories of startups,
                                          please mention links to articles,
                                          media coverage, or any other related
                                          material, as links (if any) within the
                                          textbox (For Example: Google Drive
                                          links etc.).
                                        </Tooltip>
                                      }
                                    >
                                      <button
                                        type="button"
                                        style={{
                                          borderRadius: "20px",
                                          backgroundColor: "#000000",
                                          color: "#ffffff",
                                          border: "none",
                                          width: "25px",
                                          height: "25px",
                                        }}
                                      >
                                        !
                                      </button>
                                    </OverlayTrigger>
                                  </label>
                                  <textarea
                                    className="form-control"
                                    name="success_stories"
                                    value={
                                      returnsFormSubmit
                                        ? returnsFormSubmit.success_stories
                                        : ""
                                    }
                                    placeholder="Success Stories"
                                    onChange={handleChange}
                                  ></textarea>
                                </div>
                                <div className="col-md-6 mt-3">
                                  <label className="form-label required">
                                    <b>Remarks</b>
                                  </label>
                                  <textarea
                                    className="form-control"
                                    name="remarks"
                                    value={
                                      returnsFormSubmit
                                        ? returnsFormSubmit.remarks
                                        : ""
                                    }
                                    placeholder="Remarks"
                                    onChange={handleChange}
                                  ></textarea>
                                </div>
                                <div className="col-6 mt-3">
                                  <label className="form-label required">
                                    <b>Purpose of Requested funds</b>
                                    <span className="text-danger">
                                      <b> *</b>
                                    </span>
                                  </label>
                                  <br />
                                  <select
                                    className="form-control"
                                    name="purpose_of_funds_requested"
                                    value={
                                      returnsFormSubmit
                                        ? returnsFormSubmit.purpose_of_funds_requested
                                        : ""
                                    }
                                    aria-label="Select Entity Type"
                                    onChange={handleChange}
                                    required
                                  >
                                    <option value="">Select</option>
                                    <option value="1">Proof of concept</option>
                                    <option value="2">
                                      Prototype Development
                                    </option>
                                    <option value="3">Product Trial</option>
                                    <option value="4">Market Entry</option>
                                    <option value="5">Commercialisation</option>
                                    <option value="6">Scaling Up</option>
                                  </select>
                                </div>
                              </div>
                              <div className="row mt-3">
                                <div className="col-6">
                                  {loggedInRole == "secretariat" ? (
                                    <></>
                                  ) : (
                                    <>
                                      <button
                                        className="btn btn-sisfs btn-yellow-primary"
                                        type="button"
                                        onClick={() => saveClick()}
                                      >
                                        Save
                                      </button>
                                    </>
                                  )}
                                </div>
                                <div className="col-6">
                                  {/* <button className="btn btn-sisfs btn-yellow-primary" type="submit" style={{ float: 'right' }} >Confirm Quarterly Review</button> */}
                                  {loggedInRole == "secretariat" ? (
                                    <></>
                                  ) : (
                                    <>
                                      {(quaterlyReports_returns.length &&
                                        quaterlyReports_returns[0]
                                          .progress_overview_review == "0") ||
                                      quaterlyReports_returns.length == 0 ? (
                                        <button
                                          className="btn btn-sisfs btn-yellow-primary"
                                          type="submit"
                                          style={{ float: "right" }}
                                          disabled={qrDetails.length == 0}
                                        >
                                          <div className="d-flex flex-row justify-content-between">
                                            <span>
                                              Confirm Quarterly Review
                                            </span>
                                          </div>
                                        </button>
                                      ) : (
                                        <button
                                          className="btn btn-sisfs btn-yellow-primary"
                                          type="submit"
                                          style={{ float: "right" }}
                                          disabled={
                                            quaterlyReports_returns.length &&
                                            quaterlyReports_returns[0]
                                              .progress_overview_review == "1"
                                          }
                                        >
                                          <div className="d-flex flex-row justify-content-between">
                                            <span>
                                              Quarterly Review Confirmed
                                            </span>
                                            {quaterlyReports_returns.length &&
                                            quaterlyReports_returns[0]
                                              .progress_overview_review ==
                                              "1" &&
                                            true ? (
                                              <img
                                                src={SuccessCheck}
                                                alt="SISFS Logo"
                                                style={{
                                                  marginRight: "15px",
                                                  cursor: "pointer",
                                                }}
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </button>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </fieldset>
                          </form>
                          {/* <h1 style={{ color: 'red', textAlign: "center", marginTop: "50px" }}>No Data</h1> */}
                          {/* {console.log(ChangeYearmonth?.year,ChangeYearmonth?.quarter,"quarterrr")} */}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    {/* {QuaterlyBTN == true && <div className="row">
                                <div className="col-6"></div>
                                <div className="col-6">
                                    <button className="btn btn-sisfs btn-yellow-primary" style={{ float: 'right' }} disabled={returnsFormLength != 0 && returnsFormSubmit.progress_overview_review == "1"} data-target="#QuaterlyReview" data-toggle="modal">CONFIRM QUATERLY REVIEW</button>
                                </div>
                            </div>} */}

                    {/* Conformation Poppup */}
                    <div
                      className="modal fade"
                      id="YesOrNoThree"
                      role="dialog"
                      aria-labelledby="exampleModalCenterTitle"
                      aria-hidden="true"
                    >
                      <div
                        className="modal-dialog modal-dialog-centered"
                        role="document"
                      >
                        <div className="modal-content">
                          <div
                            className="modal align-middle modal-sisfs d-block"
                            id="FormSubmissionError"
                          >
                            <div className="modal-dialog modal-dialog-centered modal-md">
                              <div className="modal-content">
                                <div style={{ textAlign: "right" }}>
                                  <i
                                    className="fa fa-close"
                                    data-dismiss="modal"
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "30px",
                                      marginRight: "15px",
                                      marginTop: "15px",
                                      color: "#D3D3D3",
                                    }}
                                  ></i>
                                </div>
                                <div className="modal-body text-center pt-0">
                                  <h4
                                    className="modal-message"
                                    style={{ color: "#2b3f88" }}
                                  >
                                    Are You Sure You Want To Continue
                                  </h4>
                                  <div className="modal-action">
                                    <button
                                      className="btn btn-sisfs btn-yellow-primary"
                                      onClick={() => ConformationClick()}
                                    >
                                      Yes
                                    </button>
                                    <button
                                      className="btn btn-sisfs btn-yellow-primary ml-3"
                                      data-dismiss="modal"
                                    >
                                      No
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {showIncubatorSuccessModal && (
                      <ConfirmationDialog
                        headerText={dialogData.header}
                        bodyText={dialogData.text}
                        onDialogClose={dismissModal}
                      />
                    )}
                    {showIncubatorFailureModal && (
                      <ConfirmationDialog
                        headerText={failDialogData.header}
                        bodyText={failDialogData.text}
                        onDialogClose={() =>
                          setShowIncubatorFailureModal(false)
                        }
                        iconClass="test"
                      />
                    )}

                    {/* {!loading && returnsFormLength == 0 &&
                                <h3 className="text-center pt-5 pb-5">
                                    No Data Found
                                </h3>
                            } */}

                    {/* {loading &&
                                <h3 className="text-center pt-5 pb-5">
                                    Please wait...
                                </h3>
                            } */}
                  </div>
                </fieldset>
              </div>
            }
          </fieldset>

          {/* } */}

          {/* {activeTab === 0 && 
                <div>
                    <Fragment>
                        <div className="row">
                            <div className="col-md-6 mb-4">
                                <label className="col-form-head">
                                    Choose year
                                </label>

                                <div className="select-custom">
                                    <select
                                        onChange={(e: any) => setYear(e.target.value)}
                                        className="form-control input-height"
                                        aria-label="Select Year"
                                    >
                                        <option value="">Please select Year</option>
                                        <option value={currentYear}>{currentYear}</option>
                                        <option value={currentYear - 1}>{currentYear - 1}</option>
                                        <option value={currentYear - 2}>{currentYear - 2}</option>
                                        <option value={currentYear - 3}>{currentYear - 3}</option>
                                        <option value={currentYear - 4}>{currentYear - 4}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <label className="col-form-head">
                                    Choose Month
                                </label>

                                <div className="select-custom">
                                    <select
                                        onChange={(e: any) => setMonth(e.target.value)}
                                        className="form-control input-height"
                                        aria-label="Select Year"
                                    >
                                        <option value="">Please select Month</option>
                                        <option value="1">January</option>
                                        <option value="2">February</option>
                                        <option value="3">March</option>
                                        <option value="4">April</option>
                                        <option value="5">May</option>
                                        <option value="6">June</option>
                                        <option value="7">July</option>
                                        <option value="8">August</option>
                                        <option value="9">September</option>
                                        <option value="10">October</option>
                                        <option value="11">November</option>
                                        <option value="12">December</option>

                                    </select>
                                </div>
                            </div>
                        </div>


                        {!loading && data.length > 0 && data.map((key: any, index: number) => {
                            return (
                                <Fragment key={index}>

                                    <div className="accordion accordion-details-wrap" id="accordion-progress-details">
                                        <div className="card accordion-card">
                                            <div className={`card-header accordion-card-header ${index ? 'collapsed' : ''}`}
                                                data-toggle="collapse"
                                                data-target={`#progress_collapse_${index}`}
                                                aria-expanded={index === 0}>
                                                {key.for_month}
                                            </div>

                                            <div id={`progress_collapse_${index}`}
                                                className={`collapse ${index === 0 ? 'show' : ''}`}
                                                data-parent="#accordion-progress-details">
                                                <div className="card-body accordion-card-body">
                                                    <div className="row">
                                                        <div className="col-md-6 left-align mt-3">
                                                            <p className="progressOverviewKey">Updated on</p>
                                                            <p className="progressOverviewValue">
                                                                {key.data.updated_at ? new Date(key.data.updated_at).toLocaleDateString() : key.for_month}
                                                            </p>
                                                        </div>
                                                        <div className="col-md-6 left-align mt-3">

                                                            <p className="progressOverviewValue">{key.data.approve_date}</p>
                                                        </div>

                                                        <div className="col-md-6 left-align mt-3">
                                                            <p className="progressOverviewKey">Stage</p>

                                                            <p className="progressOverviewValue"> {getStageNameById(key.data.stage_id)}</p>
                                                        </div>
                                                        <div className="col-md-6 left-align mt-3">
                                                            <p className="progressOverviewKey">Progress</p>

                                                            <p className="progressOverviewValue"> {getStagePercentNameById(key.data.progress_in_percentage)}</p>
                                                        </div>
                                                        <div className="col-md-6 left-align mt-3">
                                                            <p className="progressOverviewKey">Progress Description</p>
                                                            <p className="progressOverviewValue mw80">
                                                                {key?.data?.progress_in_text}
                                                            </p>
                                                        </div>

                                                        <div className="col-md-6 left-align mt-3">
                                                            <p className="progressOverviewKey">Jobs created by
                                                                startup - till date</p>

                                                            <p className="progressOverviewValue"> {key?.data?.people_employed_till_date}</p>
                                                        </div>
                                                        <div className="col-md-6 left-align mt-3">
                                                            <p className="progressOverviewKey">Jobs created by
                                                                startup - Lastest Quarter</p>
                                                            <p className="progressOverviewValue"> {key?.data?.people_employed_latest_quarter}</p>
                                                        </div>
                                                        <div className="col-md-6 left-align mt-3">
                                                            <p className="progressOverviewKey">TurnOver of
                                                                startup - Till Date</p>

                                                            <p className="progressOverviewValue"> &#8377; {key?.data?.turnover_till_date ? (myNumberWithTwoDecimalPlace(key?.data?.turnover_till_date)) : key?.data?.turnover_till_date}</p>
                                                        </div>
                                                        <div className="col-md-6 left-align mt-3">
                                                            <p className="progressOverviewKey">TurnOver of
                                                                startup - Lastest Quarter</p>

                                                            <p className="progressOverviewValue"> &#8377; {key?.data?.turnover_latest_quarter ? (myNumberWithTwoDecimalPlace(key?.data?.turnover_latest_quarter)) : key?.data?.turnover_latest_quarter}</p>
                                                        </div>
                                                        <div className="col-md-6 left-align mt-3">
                                                            <p className="progressOverviewKey">
                                                                Cumulative Quantum of External Funding Raised by Startup (post-SISFS selection only) – till date
                                                            </p>

                                                            <p className="progressOverviewValue"> &#8377; {key?.data?.external_fund_raised_till_date ? (myNumberWithTwoDecimalPlace(key?.data?.external_fund_raised_till_date)) : key?.data?.external_fund_raised_till_date}</p>
                                                        </div>
                                                        <div className="col-md-6 left-align mt-3">
                                                            <p className="progressOverviewKey">
                                                                Cumulative Quantum of External Funding Raised by Startup (post-SSFS selection only) – in latest quarter
                                                            </p>

                                                            <p className="progressOverviewValue"> &#8377; {key?.data?.external_fund_raised_latest_quarter ? (myNumberWithTwoDecimalPlace(key?.data?.external_fund_raised_latest_quarter)) : key?.data?.external_fund_raised_latest_quarter}</p>
                                                        </div>
                                                        <div className="col-md-6 left-align mt-3">
                                                            <p className="progressOverviewKey">
                                                                Cumulative GST Filed by Startup – till date
                                                            </p>

                                                            <p className="progressOverviewValue"> &#8377; {key?.data?.gst_filled_till_date ? (myNumberWithTwoDecimalPlace(key?.data?.gst_filled_till_date)) : key?.data?.gst_filled_till_date}</p>
                                                        </div>
                                                        <div className="col-md-6 left-align mt-3">
                                                            <p className="progressOverviewKey">
                                                                Cumulative GST Filed by Startup – Lastest Quarter
                                                            </p>

                                                            <p className="progressOverviewValue"> &#8377; {key?.data?.gst_filled_latest_quarter ? (myNumberWithTwoDecimalPlace(key?.data?.gst_filled_latest_quarter)) : key?.data?.gst_filled_latest_quarter}</p>
                                                        </div>
                                                        <div className="col-md-6 left-align mt-3">
                                                            <p className="progressOverviewKey">
                                                                Cumulative No. of Products and Services by Startup – till date
                                                            </p>

                                                            <p className="progressOverviewValue">  {key?.data?.no_products_on_gem_portal_till_date}</p>
                                                        </div>
                                                        <div className="col-md-6 left-align mt-3">
                                                            <p className="progressOverviewKey">
                                                                Cumulative No. of Products and Services by Startup – Lastest Quarter
                                                            </p>

                                                            <p className="progressOverviewValue">  {key?.data?.no_products_on_gem_portal_latest_quarter}</p>
                                                        </div>
                                                        <div className="col-md-6 left-align mt-3">
                                                            <p className="progressOverviewKey">
                                                                Cumulative No. of IP Registration Requests Filed by the Startup - Till Date
                                                            </p>

                                                            <p className="progressOverviewValue">  {key?.data?.no_ip_regstr_req_till_date}</p>
                                                        </div>
                                                        <div className="col-md-6 left-align mt-3">
                                                            <p className="progressOverviewKey">
                                                                Cumulative No. of IP Registration Requests Filed by the Startup –  Latest quarter (₹)
                                                            </p>

                                                            <p className="progressOverviewValue">  {key?.data?.no_ip_regstr_req_latest_quarter}</p>
                                                        </div>


                                                        <div className="col-md-6 left-align mt-3">
                                                            <p className="progressOverviewKey">If any other</p>
                                                            <p className="progressOverviewValue mw80">
                                                                {key?.data?.other_comment}
                                                            </p>
                                                        </div>

                                                        {key.file_with_location &&
                                                            <div className="col-md-6 left-align mt-3">
                                                                <p className="eac-review-subtitle">Supporting Documents</p>

                                                                <div className="row mt-2">
                                                                    <div className="col-md-2">
                                                                        <img src={pdf} alt="SISFS Logo" />
                                                                    </div>
                                                                    <div className="col-md-10 left-align">
                                                                        <p className="document-attched-label">Progressfieldtrials-No.422copy2final.pdf</p>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        }


                                                    </div>
                                                </div>
                                                {((!key.data.approve_date) &&
                                                    ((isevid < currentdatenew) && (isClickid == '2' || isClickid == '3' || isClickid == '4' || isClickid == '5' || (isClickid == '7' && isPref1 == '7' && flag == '1')))) ?
                                                    (<button className="tertiary text-center btn-yellow-primary mb-5"
                                                    >
                                                        VALIDATE DATA
                                                    </button>)
                                                    : (<div className="col-md-12 mb-4 clearfix text-center">
                                                        {UserStatus.status === 'Selected' && <button className="tertiary text-center btn-yellow-primary mb-5"
                                                            onClick={() => setValidateProgressId(key.data.id)}>
                                                            VALIDATE DATA
                                                        </button>}
                                                    </div>)}

                                            </div>

                                        </div>
                                    </div>

                                    <div className="application-incubator-hr">
                                    </div>


                                </Fragment>
                            )
                        })
                        }

                        {!loading && data.length === 0 &&
                            <h3 className="text-center pt-5 pb-5">
                                No Data Found
                            </h3>
                        }

                        {loading &&
                            <h3 className="text-center pt-5 pb-5">
                                Please wait...
                            </h3>
                        }


                        {!!validateProgressId && !showMessageModal &&
                            <ConfirmationPopup headerText="Are you sure you want to Validate?"
                                bodyText="Note: User cannot edit data once Validated."
                                message={validateMessage}
                                onDialogClose={() => {
                                    setValidateProgressId(0);
                                }}
                                onSuccess={() => handleValidateData(validateProgressId)} />
                        }


                        {showMessageModal && !validateMessage.result && validateMessage.message &&
                            <ConfirmationDialog
                                headerText={"Failed"}
                                bodyText={validateMessage.message}
                                onDialogClose={() => window.location.reload()}
                                iconClass="test"
                            />
                        }

                        {showMessageModal && validateMessage.result && validateMessage.message &&
                            <ConfirmationDialog
                                headerText="Success"
                                bodyText={validateMessage.message}
                                onDialogClose={() => window.location.reload()}
                            />
                        }


                    </Fragment>
                </div>
                } */}
        </div>
      </fieldset>
    </div>
  );
};

export default ProgressOverviewFields;
