import React, { useEffect, useState } from 'react';
import { TrancheData, TrancheDetail } from '../../../models/funddetails/TrancheDetails';
import { changeNumberFormat } from '../../../utils/CommonMethods';
import TrancheInfoComponent from '../common/TrancheInfoComponent';
import TrancheWiseDetails from '../common/TrancheWiseDetails';

interface ITrancheDataProps {
  trancheDataModel: Array<TrancheDetail>;
  trancheDataDESCModel: Array<TrancheDetail>;
  firsttranchesssslist: Array<TrancheDetail>;
  trancheRequest:any;
}

function TrancheDetailsComponent(props: ITrancheDataProps) {
  const { trancheDataModel, trancheDataDESCModel,firsttranchesssslist,trancheRequest } = props;
  const [total_grand_approved, setGrantApproved] = useState(0);
  const [ApprovedAmount, setApprovedAmount] = useState(0);
  const [ManagementAmount, setManagementAmount] = useState(0);
  const [nooftranches, setnooftranches] = useState(0);
  const [trancheStatusLabel1, settrancheStatusLabel1] = useState(false)
  const [totalDisbursedAmount, setTotalDisbursedAmount] = useState<any>()
  var sum = 0;
  useEffect(() => {
    calculateApprovedAmount();
    if (trancheDataDESCModel) {
      for (let i = 0; i < trancheDataDESCModel.length; i++) {
        if (trancheDataDESCModel[i].tranches_status_label === "Disbursed") {
          // console.log(trancheDataDESCModel[i],"fundReleased")        
          sum = sum + trancheDataDESCModel[i].fund_released;
          // console.log(sum,"sum!!!!!!!!!")
          setTotalDisbursedAmount(sum)
          // console.log(totalDisbursedAmount,"totalDisbursedAmount")
        }
      }
    }
    // console.log(totalDisbursedAmount,"fundReleased")
  },);
  const calculateApprovedAmount = () => {
    if (trancheDataModel.length > 0) {

      // let grant_approved=0;
      // for(let i=0; i < trancheDataModel.length; i++)
      // {
      //   grant_approved = grant_approved + 
      //   (trancheDataModel[i].approved_amount ==null ?0 :  Number(trancheDataModel[i].approved_amount));

      // }
      let grant_approved = 0;
      for (let i = 0; i < trancheDataModel.length; i++) {
        grant_approved = grant_approved +
          (trancheDataModel[i].fund_released == null ? 0 : Number(trancheDataModel[i].fund_released));

      }
      setGrantApproved(Number(grant_approved.toFixed(2)));
      setApprovedAmount(trancheDataModel[0].total_approved_amt);

      setManagementAmount(trancheDataModel[0].total_management_amount);
      setnooftranches(trancheDataModel[0].total_management_amount);
      // setnooftranches(trancheDataModel[0].no_of_tranches)
    }
  }
  return (
    <div>
      <div className="row">
        <div className="col-md-3 left-align" style={{ maxWidth: '20%' }}>
          <span className="fund-amount-cur">

            &#8377;
            <label className="fund-amount-label-value"> {changeNumberFormat(
              Number(ApprovedAmount),
              2,
              1
            )} </label>


          </span>
          <br />
          <span className="funds-Label-heading">Total Grant Approved</span>
        </div>

        <div className="vl vl-mr"></div>
        <div className="col-md-3 left-align" style={{ maxWidth: '20%' }}>
          <span className="fund-amount-cur">

            &#8377;
            <label className="fund-amount-label-value"> {changeNumberFormat(
              Number(ManagementAmount),
              2,
              1
            )} </label>


          </span>
          <br />
          <span className="funds-Label-heading">Total Management Fees</span>
        </div>
        <div className="vl vl-mr"></div>
        <div className="col-md-3 left-align" style={{ maxWidth: '20%' }}>
          <span className="fund-amount-cur">
            <label className="fund-amount-label-value">{(trancheDataModel[0].application_id == 0 || trancheDataModel[0].no_of_tranches==null)? 0 : trancheDataModel[0].no_of_tranches}</label>
          </span>
          <br />
          {/* {console.log(trancheDataModel[0].no_of_tranches)} */}
          <span className="funds-Label-heading">Total No. of Tranches</span>
        </div>

        <div className="vl vl-mr"></div>
        <div className="col-md-3 left-align" style={{ maxWidth: '20%' }}>
          <span className="fund-amount-cur">

            &#8377;
            <label className="fund-amount-label-value">
              {/* {console.log(totalDisbursedAmount, "trancheDataDESCModel")} */}
              {trancheDataDESCModel != undefined && totalDisbursedAmount > 0 ? changeNumberFormat(
                Number(totalDisbursedAmount),
                // Number(trancheDataModel[0].total_approved_amt),
                2,
                1
              ) : '0'}

            </label>
          </span>
          <br />
          <span className="funds-Label-heading">Total Funds Disbursed to Incubator</span>
        </div>
      </div>

      {/* <br />

      <div className='row'>
        <div className="col-md-12">
          <TrancheInfoComponent trancheDetailsModel={trancheDataDESCModel} />
        </div>
      </div> */}

      <br />

      <div className="row">
        <div className="col-md-12">
          <br />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {/* {console.log(trancheRequest)} */}
          {trancheDataModel[0]!=undefined&& trancheDataModel[0].no_of_tranches != null && <TrancheWiseDetails trancheDetailsModel={firsttranchesssslist} total_grant_approved={0}  RequestedTranche={trancheRequest} />}
        </div>
      </div>
    </div>
  );
}

export default TrancheDetailsComponent;
