import { FileUploadModel } from "../FileUploadModel";
import { TeamDetails } from "./IncubatorTeamModel";

export class SaveRequestModel {
  constructor(
    public application_id: number = 0,
    public registered_name: string = "",
    //public incubator_name: string = "",
    public industry_id: Array<string> = [],
    public sector_id: Array<string> = [],
    public address_state_id: any,
    public address_city_id: any,
    public address: String = "",
    public representative_name: string = "",
    public representative_designation: string = "",
    public representative_mobile_no: string = "",
    public representative_email: string = "",
    public type_legal_entity_id: number,
    public registration_date: Date = new Date(),
    public corporate_id_no: string = "",
    public operationalization_date: Date = new Date(),
    public seating_capacity_individulas: number,
    public no_startups_incubation: number,
    public fund_disburse_using_3rd_party: string,
    public central_incubator_far_id: Array<number> = [],
    public nature_assistance_incubator: string = "",
    public facebook_link: string = "",
    public twitter_link: string = "",
    public linkedin_link: string = "",
    public youtube_link: string = "",
    public website_url: string = "",
    public incubator_name: string = "",
    public facebook_enable: boolean = false,
    public twitter_enable: boolean = false,
    public linkedin_enable: boolean = false,
    public youtube_enable: boolean = false,
    public pan_card_number: string = "",

    //2nd step
    public ceo_firstname: string = "",
    public ceo_lastname: string = "",
    public ceo_description: string = "",
    public fulltime_employees_no: number = 0,
    public team_data: Array<TeamDetails> = [],

    // 3rd Step
    public list_facilities: string = "",
    public startups_no_incubated: number,
    public startups_no_graduated: number,
    public startups_no_investments: number,
    public startups_no_revenue_year: number,
    public startups_survival_rate: number,
    public investments_between_incubator_startups: number,
    public startups_invested: number,
    public startups_invested_applicable: boolean,
    public startups_invested_total_corpus: number,
    public startups_invested_total_corpus_applicable: boolean,
    public total_investments_raised_incubatees: number,
    public total_investments_raised_incubatees_applicable: boolean,
    public mentors_no_engaged: number,
    public mentors_no_engaged_applicable: boolean,
    public mentors_average_hours: number,
    public mentors_average_hours_applicable: boolean,
    public mentors_no_of_ip: number,
    public mentors_no_of_ip_applicable: boolean,
    public corporate_facilitated: number,
    public corporate_facilitated_applicable: boolean,
    public event_engagements: number,
    public event_engagements_applicable: boolean,
    public participation_other_events: number,
    public participation_other_events_applicable: boolean,
    public no_of_patents: number,
    public no_of_patents_applicable: boolean,
    public no_of_copyrights: number,
    public no_of_copyrights_applicable: boolean,
    public no_of_trademarks: number,
    public no_of_trademarks_applicable: boolean,

    // 4th Step
    public quantum_fund_required: string = "",
    public no_fund_benifitiaries_1yr: number = 0,
    public no_fund_benifitiaries_2yr: number = 0,
    public no_fund_benifitiaries_3yr: number = 0,

    //others
    public action: string = "",
    public upload_documents_incubation_support: boolean = false,
    public upload_documents_mentorship_provided: boolean = false,
    public upload_documents_composition_incubator: boolean = false,
    public upload_documents_events_organized: boolean = false
  ) {
    this.application_id = application_id;
    this.registered_name = registered_name;
    // this.incubator_name = incubator_name;
    this.industry_id = industry_id;
    this.sector_id = sector_id;
    this.address_state_id = address_state_id;
    this.address_city_id = address_city_id;
    this.address = address;
    this.representative_name = representative_name;
    this.representative_designation = representative_designation;
    this.representative_mobile_no = representative_mobile_no;
    this.representative_email = representative_email;
    this.type_legal_entity_id = type_legal_entity_id;
    this.registration_date = registration_date;
    this.corporate_id_no = corporate_id_no;
    this.operationalization_date = operationalization_date;
    this.seating_capacity_individulas = seating_capacity_individulas;
    this.no_startups_incubation = no_startups_incubation;
    this.fund_disburse_using_3rd_party = fund_disburse_using_3rd_party;
    this.central_incubator_far_id = central_incubator_far_id;
    this.nature_assistance_incubator = nature_assistance_incubator;
    this.facebook_link = facebook_link;
    this.facebook_enable = facebook_enable;
    this.twitter_enable = twitter_enable;
    this.linkedin_enable = linkedin_enable;
    this.youtube_enable = youtube_enable;
    this.twitter_link = twitter_link;
    this.linkedin_link = linkedin_link;
    this.youtube_link = youtube_link;
    this.website_url = website_url;
    this.pan_card_number = pan_card_number;

    //3rd Step
    this.list_facilities = list_facilities;
    this.startups_no_incubated = startups_no_incubated;
    this.startups_no_graduated = startups_no_graduated;
    this.startups_no_investments = startups_no_investments;
    this.startups_no_revenue_year = startups_no_revenue_year;
    this.startups_survival_rate = startups_survival_rate;
    this.investments_between_incubator_startups = investments_between_incubator_startups;
    this.startups_invested = startups_invested;
    this.startups_invested_applicable = startups_invested_applicable;
    this.startups_invested_total_corpus = startups_invested_total_corpus;
    this.startups_invested_total_corpus_applicable = startups_invested_total_corpus_applicable;
    this.total_investments_raised_incubatees = total_investments_raised_incubatees;
    this.total_investments_raised_incubatees_applicable = total_investments_raised_incubatees_applicable;
    this.mentors_no_engaged = mentors_no_engaged;
    this.mentors_no_engaged_applicable = mentors_no_engaged_applicable;
    this.mentors_average_hours = mentors_average_hours;
    this.mentors_average_hours_applicable = mentors_average_hours_applicable;
    this.mentors_no_of_ip = mentors_no_of_ip;
    this.mentors_no_of_ip_applicable = mentors_no_of_ip_applicable;
    this.corporate_facilitated = corporate_facilitated;
    this.corporate_facilitated_applicable = corporate_facilitated_applicable;
    this.event_engagements = event_engagements;
    this.event_engagements_applicable = event_engagements_applicable;
    this.participation_other_events = participation_other_events;
    this.participation_other_events_applicable = participation_other_events_applicable;
    this.no_of_patents = no_of_patents;
    this.no_of_patents_applicable = no_of_patents_applicable;
    this.no_of_copyrights = no_of_copyrights;
    this.no_of_copyrights_applicable = no_of_copyrights_applicable;
    this.no_of_trademarks = no_of_trademarks;
    this.no_of_trademarks_applicable = no_of_trademarks_applicable;

    // 4th Step
    this.quantum_fund_required = quantum_fund_required;
    this.no_fund_benifitiaries_1yr = no_fund_benifitiaries_1yr;
    this.no_fund_benifitiaries_2yr = no_fund_benifitiaries_2yr;
    this.no_fund_benifitiaries_3yr = no_fund_benifitiaries_3yr;

    //5th step
    this.action = action;
    this.upload_documents_incubation_support = upload_documents_incubation_support;
    this.upload_documents_mentorship_provided = upload_documents_mentorship_provided;
    this.upload_documents_composition_incubator = upload_documents_composition_incubator;
    this.upload_documents_events_organized = upload_documents_events_organized;
  }
}
