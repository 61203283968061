import React, { ReactNode, useEffect, useRef } from 'react'

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useClickAwayListener(ref: any, props: IClickAwayListener) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function onClickAway(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
               props.onClickAway()
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", onClickAway);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", onClickAway);
        };
    }, [ref]);
}

interface IClickAwayListener {
    onClickAway: () => void;
    children: ReactNode
}

/** Used when any component needs to perform certain task when clicked away from the control on anywhere on the document */
function ClickAwayListener(props: IClickAwayListener) {
    const wrapperRef = useRef(null);

    useClickAwayListener(wrapperRef, props);

    return (
        <div ref={wrapperRef}>
            {props.children}
        </div>
    );
    
        
}

export default ClickAwayListener
