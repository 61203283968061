import React from "react";
import { StepperEnum } from "../../../utils/Enums";
interface IHeaderProps {
  onClick: (index: number) => void;
  errors?: any
  teamError?: Array<Number>;
}
function StepDetailsComponent(props: IHeaderProps) {
  return (
    <div className="bs-stepper-header" role="tablist" id="step_details" style={{ display: 'block' }}>
      <div className="row">
        <div className="col-md-12" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <div className="step" data-target="#incubator-step-1">
            <button
              type="button"
              className="step-trigger"
              role="tab"
              id="stepper1trigger1"
              aria-controls="incubator-step-1"
              onClick={() => props.onClick(StepperEnum.GeneralDetail)}
            >
              <span className={props.errors.GeneralDetail.length || props.teamError?.length ? "bs-stepper-circle-error" : "bs-stepper-circle"}></span>
              <span className="bs-stepper-label">General Incubator <br /> Progress</span>
              <div className="bs-stepper-line"></div>
            </button>
            {
              props.errors.GeneralDetail.length || props.teamError?.length ?
                <span className="badge-red"><i className="fa fa-exclamation-triangle"></i> {props.errors.GeneralDetail.length || props.teamError?.length ? `${props.errors.GeneralDetail.length} invalid fields` : ''}</span>
                : <span />
            }
          </div>
          <div className="step" data-target="#incubator-step-4">
            <button
              type="button"
              className="step-trigger"
              role="tab"
              id="stepper1trigger4"
              aria-controls="incubator-step-4"
              onClick={() => props.onClick(StepperEnum.FundUtilization)}
            >
              <span className={props.errors.FundDetails.length ? "bs-stepper-circle-error" : "bs-stepper-circle"}></span>
              <span className="bs-stepper-label">Fund Requirements <br /> Details</span>
            </button>
            {
              props.errors.FundDetails.length ?
                <span className="badge-red"><i className="fa fa-exclamation-triangle"></i> {props.errors.FundDetails.length ? `${props.errors.FundDetails.length} invalid fields` : ''}</span>
                : <span />
            }
          </div>
        </div>
      </div>
    </div >
  );
}
export default StepDetailsComponent;
