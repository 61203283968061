export class StartupDetailModel {
    constructor(
        public is_technology_startup: string,
        public solving_problem_for: string,
        public value_proposition_for_problem: string,
        public unique_selling_point: string,
        public target_customer_segment: string,
        public market_size_opportunity: number,
        public aim_to_scale_up: string,
        public revenue_model: string,
        // public key_competitors: Array<string>,
        public competitors_startup_desc: string,
        public competitors_msme_desc: string,
        public competitors_corporate_desc: string,
        public competitors_other_desc: string,
        public competitors_startup_enable: boolean,
        public competitors_msme_enable: boolean,
        public competitors_corporate_enable: boolean,
        public competitors_other_enable: boolean,
        public website_url: string,
        public facebook_link: string,
        public twitter_link: string,
        public linkedin_link: string,
        public youtube_link: string,
        public other_link: string,
        public facebook_enable: boolean,
        public twitter_enable: boolean,
        public linkedin_enable: boolean,
        public youtube_enable: boolean,
        public other_enable: boolean
    ) {
        this.is_technology_startup = is_technology_startup;
        this.solving_problem_for = solving_problem_for;
        this.value_proposition_for_problem = value_proposition_for_problem;
        this.unique_selling_point = unique_selling_point;
        this.target_customer_segment = target_customer_segment;
        this.market_size_opportunity = market_size_opportunity;
        this.aim_to_scale_up = aim_to_scale_up;
        this.revenue_model = revenue_model;
        // this.key_competitors = key_competitors;
        this.website_url = website_url;
        this.competitors_startup_desc = competitors_startup_desc;
        this.competitors_msme_desc = competitors_msme_desc;
        this.competitors_corporate_desc = competitors_corporate_desc;
        this.competitors_other_desc = competitors_other_desc;
        this.competitors_startup_enable = competitors_startup_enable;
        this.competitors_msme_enable = competitors_msme_enable;
        this.competitors_corporate_enable = competitors_corporate_enable;
        this.competitors_other_enable = competitors_other_enable;
        this.website_url = website_url;
        this.facebook_link = facebook_link;
        this.twitter_link = twitter_link;
        this.linkedin_link = linkedin_link;
        this.youtube_link = youtube_link;
        this.other_link = other_link;
        this.facebook_enable = facebook_enable;
        this.linkedin_enable = linkedin_enable;
        this.twitter_enable = twitter_enable;
        this.youtube_enable = youtube_enable;
        this.other_enable = other_enable;
    }
}