import React, { useEffect, useState } from 'react';
import { TrancheData, TrancheDetail } from '../../../models/funddetails/TrancheDetails';
import { changeNumberFormat } from '../../../utils/CommonMethods';
import TrancheInfoComponent from '../common/TrancheInfoComponent';
import TrancheInfoReapplyComponent from '../common/TrancheInfoReapplyComponent';
import TrancheWiseDetails from '../common/TrancheWiseDetails';
import TrancheWiseReapplyDetails from '../common/TrancheWiseReapplyDetails';

interface ITrancheDataProps {
  trancheDataModel: Array<TrancheDetail>;
  trancheDisbursedData: any;
  trancheDataDESCModel: Array<TrancheDetail>;
  trancheslist: Array<TrancheDetail>;
  ReapplyTrancheRequest:any;
}

function ReapplyTrancheDetailsComponent(props: ITrancheDataProps) {
  const { trancheDataModel, trancheDisbursedData, trancheDataDESCModel,trancheslist,ReapplyTrancheRequest } = props;
  const [total_grand_approved, setGrantApproved] = useState(0);
  const [ApprovedAmount, setApprovedAmount] = useState(0);
  const [ManagementAmount, setManagementAmount] = useState(0);
  const [totalDisbursedAmount, setTotalDisbursedAmount] = useState<any>()
  var sum = 0;
  useEffect(() => {
    calculateApprovedAmount();
    for (let i = 0; i < trancheDataModel.length; i++) {
      if (trancheDataModel[i].tranches_status_label === "Disbursed") {
        // console.log(trancheDataModel[i],"fundReleased")        
        sum = sum + trancheDataModel[i].fund_released;
        // console.log(sum,"sum!!!!!!!!!")
        setTotalDisbursedAmount(sum)
        // console.log(totalDisbursedAmount,"totalDisbursedAmount")
      }
    }
  });
  const calculateApprovedAmount = () => {
    if (trancheDataModel.length > 0) {

      // let grant_approved=0;
      // for(let i=0; i < trancheDataModel.length; i++)
      // {
      //   grant_approved = grant_approved + 
      //   (trancheDataModel[i].approved_amount ==null ?0 :  Number(trancheDataModel[i].approved_amount));

      // }

      let grant_approved = 0;
      for (let i = 0; i < trancheDataModel.length; i++) {
        grant_approved = grant_approved +
          (trancheDataModel[i].fund_released == null ? 0 : Number(trancheDataModel[i].fund_released));

      }

      setGrantApproved(Number(grant_approved.toFixed(2)));

      setApprovedAmount(trancheDataModel[0].total_approved_amt);

      setManagementAmount(trancheDataModel[0].total_management_amount);

    }
  }
  return (
    <div>
      <div className="row">
        <div className="col-md-3 left-align" style={{ maxWidth: '20%' }}>
          <span className="fund-amount-cur">

            &#8377;
            <label className="fund-amount-label-value"> {changeNumberFormat(
              Number(ApprovedAmount),
              2,
              1
            )} </label>


          </span>
          <br />
          <span className="funds-Label-heading">Total Grant Approved</span>
        </div>

        <div className="vl vl-mr"></div>
        <div className="col-md-3 left-align" style={{ maxWidth: '20%' }}>
          <span className="fund-amount-cur">

            &#8377;
            <label className="fund-amount-label-value"> {changeNumberFormat(
              Number(ManagementAmount),
              2,
              1
            )} </label>


          </span>
          <br />
          <span className="funds-Label-heading">Total Management Fees</span>
        </div>
        <div className="vl vl-mr"></div>
        <div className="col-md-3 left-align" style={{ maxWidth: '20%' }}>
          <span className="fund-amount-cur">
            <label className="fund-amount-label-value">{(trancheDataModel[0]?.application_id == 0 || trancheDataModel[0].no_of_tranches==null)? 0 : trancheDataModel[0].no_of_tranches}</label>
          </span>
          <br />
          <span className="funds-Label-heading">Total No. of Tranches</span>
        </div>

        <div className="vl vl-mr"></div>
        <div className="col-md-3 left-align" style={{ maxWidth: '20%' }}>
          <span className="fund-amount-cur"> 
            &#8377;
            <label className="fund-amount-label-value">
              {trancheDisbursedData != undefined && trancheDisbursedData > 0 ? changeNumberFormat(
                Number(trancheDisbursedData),
                2,
                1
              ) : '0'} </label>


          </span>
          <br />
          <span className="funds-Label-heading">Total Funds Disbursed to Incubator</span>
          {/* <br />
          <span className="funds-Label-heading">( {trancheDataModel[0].application_id == 0 ? '0':trancheDataModel.length}  Tranches)</span> */}
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <br />
        </div>
      </div>

      {/* <div className='row'>
        <div className="col-md-12">
          <TrancheInfoReapplyComponent trancheDetailsModel={trancheslist} />
        </div>
      </div> */}

      {trancheDataModel[0] != undefined && trancheDataModel[0]?.no_of_tranches != null && <div className="row">
        <div className="col-md-12">
          <TrancheWiseReapplyDetails trancheDetailsModel={trancheslist} total_grant_approved={0} requestedTranche={ReapplyTrancheRequest} />
        </div>
      </div>}
    </div>
  );
}

export default ReapplyTrancheDetailsComponent;
