import { useEffect, useState } from "react";
import {
    GET_APPLICATION_LIST,
    GET_APPLICATION_VIEW,
    REPLACE_FILE,
    DELETE_APPLICATION,
    EVALUATION_FILE,
    STARTUP_ALL_APPLICATIONS,
    GET_GRIEVANCE_LIST,
    CONTACT_LIST
} from "../../../constants/api";
import { get, post } from "../../../effects/api/api.common";
import { Link } from 'react-router-dom';
// import ContactSectionComponent from "./ContactSectionComponent";
// import GetApplicationInfoComponent from "./GetApplicationInfoComponenet";
import { useHistory, NavLink } from "react-router-dom";
import search from '../../../images/search.svg';
import { filterByValue, showEllipsisText } from "../../../utils/CommonMethods";
import { FormatDate } from "../../../utils/DateFormat";
import { getSessionStorageValue } from "../../../effects/utils/session-storage";
import { userInfo } from "os";
import ReactPaginate from 'react-paginate';
import '../../../styles/dashboard.css';
// import { FormatDate } from "../../../../utils/DateFormat";
// import { downloadDocument } from "../../../../utils/CommonMethods";
/** Render Application Status for Incubator screen */
function GrievanceListComponent(props: any) {
    const history = useHistory();
    const [activeIndex, setActiveIndex] = useState("grievances");
    const [showViewMore, setShowViewMore] = useState('');
    const [noDataFilter, setNoDataFilter] = useState('');
    const [loggedInRole, setLoggedInRole] = useState("");
    const [activeTab, setActiveTab] = useState("grievances");
    const [contactDetails, setContactDetails] = useState([]);
    const [allIssues, setAllIssues] = useState([]);
    const [pendingIssues, setPendingIssues] = useState([]);
    const [openIssues, setOpenIssues] = useState([]);
    const [closeIssues, setCloseIssues] = useState([]);
    const [showbutton, setShowButton] = useState(true);
    const [routeIncubator, setRouteIncubator] = useState(true)
    const [filteredOpenList, setFilteredOpenList] = useState([]);
    const [filteredCloseList, setFilteredCloseList] = useState([]);
    const [filteredAllList, setFilteredAllList] = useState([]);
    const [filteredPendingList, setFilteredPendingist] = useState([]);
    const [filteredContactList, setFilteredContactList] = useState([]);
    const [userRole,setUserRole] = useState('')
    const [loader,setLoader] = useState(false)
    useEffect(() => {
        window.scrollTo(0, 0);

        if (getSessionStorageValue("user")) {
            const user: any = getSessionStorageValue("user");
            const userInfo = JSON.parse(user)
            setLoggedInRole(userInfo.roles)
            if (userInfo.roles == 'incubator') {
                setShowButton(true);
                setRouteIncubator(true);
            }
            else if (userInfo.roles == 'dpiit') {
                setShowButton(false)
            }
            else if (userInfo.roles == 'secretariat') {
                setShowButton(false);
            }
            else if (userInfo.roles == 'startup') {
                setShowButton(true)
                setRouteIncubator(false);
            }
        }
        else {
            setShowButton(true);
        }
        getGrievanceDetails();
        // getContactDetails()
    }, []);
    const changeActive = (tabs: any) => {
        setActiveIndex(tabs)
    }

    const getGrievanceDetails = () => {
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user)
        // console.log(userInfo)
        var payload = {
            "logged_user_id":userInfo.id,
            "roles" : userInfo.roles
        }
        setLoader(true)
        post(GET_GRIEVANCE_LIST, payload).then((response: any) => {
            if (!response || !response.data) return;
            setOpenIssues(response.data.open)
            setCloseIssues(response.data.closed)
            setAllIssues(response.data.all)
            setPendingIssues(response.data.pending)
            setLoader(false)
            getContactDetails()
        });
    };

    const getContactDetails = () => {
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user)
        var payload = {
            "roles" : userInfo.roles
        }
               
        post(CONTACT_LIST, payload).then((response: any) => {
            if (!response || !response.data) return;
            setContactDetails(response.data.data)
        });
    };

    const searchList = (e: any) => {
        setNoDataFilter(e.target.value)
        const filteredOpenIssueData = filterByValue(openIssues, e.target.value);
        const filteredCloseIssueData = filterByValue(closeIssues, e.target.value);
        const filteredAllListData=filterByValue(allIssues, e.target.value);
        const filteredPendingListData=filterByValue(pendingIssues, e.target.value);
        
        // console.log(filteredData)
        setFilteredOpenList(filteredOpenIssueData)
        setFilteredCloseList(filteredCloseIssueData)
        setFilteredAllList(filteredAllListData)
        setFilteredPendingist(filteredPendingListData)
        if (!showbutton){
        
        const filteredContactIssueData = filterByValue(contactDetails, e.target.value);
        setFilteredContactList(filteredContactIssueData)
        }

        // console.log(filteredData)    

        //////////*******All*********/////////////////
        setallCurrentPage(0);
        setallCurrentPage1(0);
        allhandlePageChange({selected : 0})

        //////////*******open*********/////////////////
        setopenCurrentPage(0);
        setopenCurrentPage1(0);
        openhandlePageChange({selected:0})

        //////////*******close*********/////////////////
        setpendCurrentPage(0);
        setpendCurrentPage1(0);
        pendhandlePageChange({selected:0})

        //////////*******pend*********/////////////////
        setclosedCurrentPage(0);
        setclosedCurrentPage1(0);
        closedhandlePageChange({selected:0})
    }

    //////////////////////////////    Alll ////////////////////////
    const [allcurrentPage, setallCurrentPage] = useState(0);
    const [allitemsPerPage, setallItemsPerPage] = useState(25); 
    const [allcurrentPage1, setallCurrentPage1] = useState(0);
    const [allitemsPerPage1, setallItemsPerPage1] = useState(25); 

    const allhandlePageChange = (selectedItem: { selected: number }) => {
        setallCurrentPage(selectedItem.selected);
        setallCurrentPage1(selectedItem.selected);
      };
    const handleallItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newallItemsPerPage = parseInt(event.target.value, 10);
    setallItemsPerPage(newallItemsPerPage);
    setallItemsPerPage1(newallItemsPerPage);
    setallCurrentPage(0); // Reset current page to first page when items per page changes
    setallCurrentPage1(0); // Reset current page to first page when items per page changes
    allhandlePageChange({selected : 0})
    };
    const alloffset = allcurrentPage * allitemsPerPage;
    const alloffset1 = allcurrentPage1 * allitemsPerPage1;
    const allcurrentPageData = allIssues.slice(alloffset, alloffset + allitemsPerPage);
    // const allcurrentPageData1 = filteredAllList.slice(alloffset1, alloffset1 + allitemsPerPage1);
    var allcurrentPageData1 = noDataFilter
                              ? filteredAllList.slice(alloffset1, alloffset1 + allitemsPerPage1)
                              : allIssues.slice(alloffset1, alloffset1 + allitemsPerPage1);
    const totalPages = Math.ceil((allIssues.length / allitemsPerPage));
    // const totalPages1 = Math.ceil( (filteredAllList.length / allitemsPerPage1));
    const totalPages1 = noDataFilter 
                            ? Math.ceil(filteredAllList.length / allitemsPerPage1)
                            : Math.ceil(allIssues.length / allitemsPerPage1);

    ///////////////////////////   open   ////////////////////////////////////
    const [opencurrentPage, setopenCurrentPage] = useState(0);
    const [openitemsPerPage, setopenItemsPerPage] = useState(25); 
    const [opencurrentPage1, setopenCurrentPage1] = useState(0);
    const [openitemsPerPage1, setopenItemsPerPage1] = useState(25); 

    const openhandlePageChange = (selectedItem: { selected: number }) => {
        setopenCurrentPage(selectedItem.selected);
        setopenCurrentPage1(selectedItem.selected);
      };
    const handleopenItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newopenItemsPerPage = parseInt(event.target.value, 10);
    setopenItemsPerPage(newopenItemsPerPage);
    setopenItemsPerPage1(newopenItemsPerPage);
    setopenCurrentPage(0); // Reset current page to first page when items per page changes
    setopenCurrentPage1(0); // Reset current page to first page when items per page changes
    openhandlePageChange({selected:0})
    };
    const openoffset = opencurrentPage * openitemsPerPage;
    const openoffset1 = opencurrentPage1 * openitemsPerPage1;
    const opencurrentPageData = openIssues.slice(openoffset, openoffset + openitemsPerPage);
    // const opencurrentPageData1 = filteredOpenList.slice(openoffset1, openoffset1 + openitemsPerPage1);
    var opencurrentPageData1 = noDataFilter
                              ? filteredOpenList.slice(openoffset1, openoffset1 + openitemsPerPage1)
                              : openIssues.slice(openoffset1, openoffset1 + openitemsPerPage1);
    const totalPages2 = Math.ceil((openIssues.length / openitemsPerPage));
    // const totalPages3 = Math.ceil( (filteredOpenList.length / openitemsPerPage1));
    const totalPages3 = noDataFilter 
                            ? Math.ceil(filteredOpenList.length / openitemsPerPage1)
                            : Math.ceil(openIssues.length / openitemsPerPage1);

      ///////////////////////////   pending   ////////////////////////////////////
    const [pendcurrentPage, setpendCurrentPage] = useState(0);
    const [penditemsPerPage, setpendItemsPerPage] = useState(25); 
    const [pendcurrentPage1, setpendCurrentPage1] = useState(0);
    const [penditemsPerPage1, setpendItemsPerPage1] = useState(25); 

    const pendhandlePageChange = (selectedItem: { selected: number }) => {
        setpendCurrentPage(selectedItem.selected);
        setpendCurrentPage1(selectedItem.selected);
      };
      const pendhandlependItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newpendItemsPerPage = parseInt(event.target.value, 10);
        setpendItemsPerPage(newpendItemsPerPage);
        setpendItemsPerPage1(newpendItemsPerPage);
        setpendCurrentPage(0); // Reset current page to first page when items per page changes
        setpendCurrentPage1(0); // Reset current page to first page when items per page changes
        pendhandlePageChange({selected:0})
      };
      const pendoffset = pendcurrentPage * penditemsPerPage;
      const pendoffset1 = pendcurrentPage1 * penditemsPerPage1;
      const pendcurrentPageData = pendingIssues.slice(pendoffset, pendoffset + penditemsPerPage);
    //   const pendcurrentPageData1 = filteredPendingList.slice(pendoffset1, pendoffset1 + penditemsPerPage1);
      var pendcurrentPageData1 = noDataFilter
                              ? filteredPendingList.slice(pendoffset1, pendoffset1 + penditemsPerPage1)
                              : pendingIssues.slice(pendoffset1, pendoffset1 + penditemsPerPage1);
      const totalPages4 = Math.ceil((pendingIssues.length / penditemsPerPage));
    //   const totalPages5 = Math.ceil( (filteredPendingList.length / penditemsPerPage1));
      const totalPages5 = noDataFilter 
                            ? Math.ceil(filteredPendingList.length / penditemsPerPage1)
                            : Math.ceil(pendingIssues.length / penditemsPerPage1);
      ///////////////////////////   closed   ////////////////////////////////////
    const [closedcurrentPage, setclosedCurrentPage] = useState(0);
    const [closedselectedPage, setclosedSelectedPage] = useState(0);
    const [closeditemsPerPage, setclosedItemsPerPage] = useState(25); 
    const [closedcurrentPage1, setclosedCurrentPage1] = useState(0);
    const [closeditemsPerPage1, setclosedItemsPerPage1] = useState(25); 

    const closedhandlePageChange = (selectedItem: { selected: number }) => {
        setclosedCurrentPage(selectedItem.selected);
        setclosedCurrentPage1(selectedItem.selected);
      };
      const handleclosedItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newclosedItemsPerPage = parseInt(event.target.value, 10);
        setclosedItemsPerPage(newclosedItemsPerPage);
        setclosedItemsPerPage1(newclosedItemsPerPage);
        setclosedCurrentPage(0); // Reset current page to first page when items per page changes
        setclosedCurrentPage1(0); // Reset current page to first page when items per page changes
        closedhandlePageChange({selected:0})
      };
      const closedoffset = closedcurrentPage * closeditemsPerPage;
      const closedoffset1 = closedcurrentPage1 * closeditemsPerPage1;
      const closedcurrentPageData = closeIssues.slice(closedoffset, closedoffset + closeditemsPerPage);
    //   const closedcurrentPageData1 = filteredCloseList.slice(closedoffset1, closedoffset1 + closeditemsPerPage1);
      var closedcurrentPageData1 = noDataFilter
                              ? filteredCloseList.slice(closedoffset1, closedoffset1 + closeditemsPerPage1)
                              : closeIssues.slice(closedoffset1, closedoffset1 + closeditemsPerPage1);
      const totalPages6 = Math.ceil((closeIssues.length / closeditemsPerPage));
    //   const totalPages7 = Math.ceil( (filteredCloseList.length / closeditemsPerPage1));
      const totalPages7 = noDataFilter 
                            ? Math.ceil(filteredCloseList.length / closeditemsPerPage1)
                            : Math.ceil(closeIssues.length / closeditemsPerPage1);

    return (
        <>
            {loader &&
                <div className="spinner-border custom-spinner" role="status">
                    <span className="sr-only">Loading...</span>
                </div>}
            <fieldset disabled={loader}>
            <div className="app-inside app-apply-incubator">
                <div className="status-list-wrapper mt-4">
                    <div className="container-fluid pl-5 pr-5 pt-2">
                        {showbutton ? routeIncubator ? <NavLink exact={true} className="btn-sisfs btn-transparent-primary_grievance float-right mb-4" activeClassName='active' to={{
                            pathname: "/contact_incubator",
                            state: { selected_tab: "grievance" }

                        }}>Add Grievance</NavLink> : <NavLink exact={true} className="btn-sisfs btn-transparent-primary_grievance float-right mb-4" activeClassName='active' to={{
                            pathname: "/contact_startup",
                            state: { selected_tab: "grievance" }

                        }}>Add Grievance</NavLink> : loggedInRole=='dpiit' || loggedInRole=='secretariat'?
                        // <NavLink exact={true} className="btn-sisfs btn-transparent-primary float-right mb-4" activeClassName='active' to={{
                        //     pathname: "/contact_us",
                        //     state: { selected_tab: "contact" }

                        // }}>Contact Us</NavLink>
                        <></>
                        :<NavLink exact={true} className="btn-sisfs btn-transparent-primary float-right mb-4" activeClassName='active' to={{
                            pathname: "/contact_us_secretariat",
                            state: { selected_tab: "contact" }

                        }}>Contact Us</NavLink>}
                        <div className="app-inside-content">
                            <div className="objectives-nav">
                                <ul className="nav nav-tabs" >
                                    <li className={activeIndex == "grievances" ? "active" : ""}>
                                        <a href="#tab-grievances" onClick={() => changeActive('grievances')} data-toggle="tab">Grievances</a>
                                    </li>
                                    {/* {!showbutton && <li className={activeIndex == "contact" ? "active" : ""} ><a href="#tab-contact" onClick={() => changeActive('contact')} data-toggle="tab">Contact Us</a>

                                    </li>} */}
                                </ul>

                            </div>
                            <div className="tab-content">
                                {/* submitted tab */}
                                <div className={activeTab == "grievances" ? "tab-pane active" : "tab-pane"} id="tab-grievances">
                                    {/* start */}
                                    <div className="row incubator-heading-review">
                                        <div className="col-md-8 text-left">
                                            <ul className="nav nav-tabs chart-nav" role="tablist">
                                            <li className="nav-item">
                                                    <a
                                                        className="nav-link active"
                                                        data-toggle="tab"
                                                        href="#tabs-3"
                                                        role="tab"
                                                    >
                                                        All {`(${allIssues.length ? allIssues.length : '0' })`}
                </a>
                                                </li>
                                                <div className="vertical-dash"></div>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        data-toggle="tab"
                                                        href="#tabs-1"
                                                        role="tab"
                                                    >
                                                        Open Issues {`(${openIssues.length ? openIssues.length : '0' })`}
                </a>
                                                </li>
                                                <div className="vertical-dash"></div>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        data-toggle="tab"
                                                        href="#tabs-4"
                                                        role="tab"
                                                    >
                                                        Pending Issues {`(${pendingIssues.length ? pendingIssues.length : '0' })`}
                </a>
                                                </li>
                                                <div className="vertical-dash"></div>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        data-toggle="tab"
                                                        href="#tabs-2"
                                                        role="tab"
                                                    >
                                                        Closed Issues {`(${closeIssues.length ? closeIssues.length : '0' })`}
                </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-md-1"></div>
                                        <div className="col-md-3 text-right search-left">
                                            <div className="d-flex searchDiv"> <img
                                                src={search}
                                                className="search"
                                                alt="search"

                                            />
                                                <input
                                                    className="searchBar"
                                                    type="text"
                                                    placeholder="Search.."
                                                    name="search"
                                                    onKeyUp={searchList}
                                                />
                                                {/* <img src={CalendarIcon} className="calendar-icon review-calendar-icon" alt="Calendar icon to select date" /> */}
                                            </div>

                                        </div>
                                    </div>
                                    <div className="tab-content mb-4">
                                        <div className="tab-pane" id="tabs-1">
                                            <div className="table-scroll">
                                                <table className="reviewTable grievanceTable" >
                                                    <thead>
                                                        <tr>
                                                            <th className="text-left table-heading custom-td-left table-heading ">Grievance Type</th>
                                                            {/* <th className="text-left table-heading">Grievance Against</th> */}
                                                            <th className="text-left table-heading">Date</th>
                                                            <th className="text-left table-heading">Entity Name</th>
                                                            <th className="text-left table-heading">Grivance Message</th>
                                                            <th className="text-left table-heading"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {openIssues!=undefined && openIssues.length == 0 || (filteredOpenList.length == 0 && noDataFilter != '') ? <tr><td className="text-center" colSpan={5}>No data</td></tr> :
                                                            (filteredOpenList.length == 0 ?
                                                                openIssues!=undefined && opencurrentPageData.map((item: any,index:any) => (
                                                                    <tr key={index}>
                                                                        <td className="text-left custom-td">{item.grievance_type}</td>
                                                                        {/* <td className="text-left custom-td">{item.grievance_against}</td> */}
                                                                        <td className="text-left custom-td">{item.created_date!=null?FormatDate(item.created_date):'-'}</td>
                                                                        <td className="text-left custom-td">{item.name_of_entity}</td>
                                                                        <td className="text-left custom-td">{showEllipsisText(item.grievance_description, 50)}</td>
                                                                        <td className="text-left custom-td">{loggedInRole == "incubator" && <Link to={{ pathname: "/grievance_detail_incubator", state: { grievance_id: item.id.toString(), logged_in_role: loggedInRole, isClosed: false } }} className="btn btn-sisfs btn-transparent-primary">View Details</Link>}
                                                                            {loggedInRole == "dpiit" && <Link to={{ pathname: "/grievance_detail", state: { grievance_id: item.id.toString(), logged_in_role: loggedInRole, isClosed: false } }} className="btn btn-sisfs btn-transparent-primary">View Details</Link>}
                                                                            {loggedInRole == "startup" && <Link to={{ pathname: "/grievance_detail_startup", state: { grievance_id: item.id.toString(), logged_in_role: loggedInRole, isClosed: false } }} className="btn btn-sisfs btn-transparent-primary">View Details</Link>}
                                                                            {loggedInRole == "secretariat" && <Link to={{ pathname: "/grievance_detail_secretariat", state: { grievance_id: item.id.toString(), logged_in_role: loggedInRole, isClosed: false } }} className="btn btn-sisfs btn-transparent-primary">View Details</Link>}
                                                                        </td>
                                                                    </tr>
                                                                )) :
                                                                opencurrentPageData1.map((item: any,index:any) => (
                                                                    <tr key={index}>
                                                                        <td className="text-left custom-td">{item.grievance_type}</td>
                                                                        {/* <td className="text-left custom-td">{item.grievance_against}</td> */}
                                                                        <td className="text-left custom-td">{item.created_date!=null?FormatDate(item.created_date):'-'}</td>
                                                                        <td className="text-left custom-td">{item.name_of_entity}</td>
                                                                        <td className="text-left custom-td">{showEllipsisText(item.grievance_description, 50)}</td>
                                                                        <td className="text-left custom-td">{loggedInRole == "incubator" && <Link to={{ pathname: "/grievance_detail_incubator", state: { grievance_id: item.id.toString(), logged_in_role: loggedInRole, isClosed: false } }} className="btn btn-sisfs btn-transparent-primary">View Details</Link>}
                                                                            {loggedInRole == "dpiit" && <Link to={{ pathname: "/grievance_detail", state: { grievance_id: item.id.toString(), logged_in_role: loggedInRole, isClosed: false } }} className="btn btn-sisfs btn-transparent-primary">View Details</Link>}
                                                                            {loggedInRole == "startup" && <Link to={{ pathname: "/grievance_detail_startup", state: { grievance_id: item.id.toString(), logged_in_role: loggedInRole, isClosed: false } }} className="btn btn-sisfs btn-transparent-primary">View Details</Link>}
                                                                            {loggedInRole == "secretariat" && <Link to={{ pathname: "/grievance_detail_secretariat", state: { grievance_id: item.id.toString(), logged_in_role: loggedInRole, isClosed: false } }} className="btn btn-sisfs btn-transparent-primary">View Details</Link>}                                                                        
                                                                        </td>
                                                                    </tr>
                                                                ))

                                                            )}
                                                    </tbody>

                                                </table>
                                            </div>
                                            {openIssues.length == 0 ? <></>
                                                :
                                                
                                                    filteredOpenList.length == 0 ?
                                                        <div style={{float:'right',display:'flex',marginRight:'20px',alignItems:'center',justifyContent:'center'}}>

                                                    <span>
                                                    <label htmlFor="itemsPerPageSelect"  className="itemsPerPage">Items per Page:</label>
                                                    <select
                                                        className="itemsPerPageSelect"
                                                        id="itemsPerPageSelect"
                                                        value={openitemsPerPage}
                                                        onChange={handleopenItemsPerPageChange}
                                                    >
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                        <option value="75">75</option>
                                                        <option value="100">100</option>
                                                    </select>
                                                    </span>&nbsp;&nbsp;&nbsp;

                                                    <span className="pagination" style={{fontSize:'18px',marginTop:'10px'}}>
                                                        <ReactPaginate
                                                            key={openitemsPerPage}
                                                            pageCount={(totalPages2)}
                                                            pageRangeDisplayed={0}
                                                            marginPagesDisplayed={0}
                                                            previousLabel="Prev"
                                                            nextLabel="Next"
                                                            // breakLabel="..."
                                                            onPageChange={openhandlePageChange}
                                                            containerClassName="pagination"
                                                            activeClassName="active"
                                                            disableInitialCallback={true}
                                                            forcePage={opencurrentPage}
                                                        />
                                                    </span>&nbsp;&nbsp;&nbsp;

                                                    <span className="pageNum">
                                                        Page {(opencurrentPage) + 1} of {totalPages2}
                                                    </span>
                                                        {/* &nbsp;&nbsp;&nbsp;<select value={currentPage} onChange={handleSelectedPageChange} style={{border:'0 0 1px solid black 0'}} >
                                                        {renderPageNumbers()}
                                                        </select>&nbsp;&nbsp;&nbsp; */}
                                                    </div>
                                                :<>
                                                    <div style={{float:'right',display:'flex',marginRight:'20px',alignItems:'center',justifyContent:'center'}}>

                                                        <span>
                                                        <label htmlFor="itemsPerPageSelect"  className="itemsPerPage">Items per Page:</label>
                                                        <select
                                                            className="itemsPerPageSelect"
                                                            id="itemsPerPageSelect"
                                                            value={openitemsPerPage1}
                                                            onChange={handleallItemsPerPageChange}
                                                        >
                                                            <option value="25">25</option>
                                                            <option value="50">50</option>
                                                            <option value="75">75</option>
                                                            <option value="100">100</option>
                                                        </select>
                                                        </span>&nbsp;&nbsp;&nbsp;

                                                        <span className="pagination" style={{fontSize:'18px',marginTop:'10px'}}>
                                                            <ReactPaginate
                                                                key={openitemsPerPage1}
                                                                pageCount={totalPages3}
                                                                pageRangeDisplayed={0}
                                                                marginPagesDisplayed={0}
                                                                previousLabel="Prev"
                                                                nextLabel="Next"
                                                                // breakLabel="..."
                                                                onPageChange={openhandlePageChange}
                                                                containerClassName="pagination"
                                                                activeClassName="active"
                                                                disableInitialCallback={true}
                                                                forcePage={opencurrentPage1}
                                                            />
                                                        </span>&nbsp;&nbsp;&nbsp;

                                                        <span className="pageNum">
                                                            Page {opencurrentPage1 + 1} of {totalPages3}
                                                        </span>
                                                            {/* &nbsp;&nbsp;&nbsp;<select value={currentPage} onChange={handleSelectedPageChange} style={{border:'0 0 1px solid black 0'}} >
                                                            {renderPageNumbers()}
                                                            </select>&nbsp;&nbsp;&nbsp; */}
                                                        </div>
                                                </>
                                            }
                                            <br /><br />
                                        </div>
                                        <div className="tab-pane" id="tabs-2">
                                            <div className="table-scroll mb-5">
                                                <table className="reviewTable grievanceTable">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-left table-heading custom-td-left table-heading ">Grievance Type</th>
                                                            {/* <th className="text-left table-heading">Grievance Against</th> */}
                                                            <th className="text-left table-heading">Date</th>
                                                            <th className="text-left table-heading">Entity Name</th>
                                                            <th className="text-left table-heading">Grivance Message</th>
                                                            <th className="text-left table-heading"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {closeIssues !=undefined && closeIssues.length == 0 || (filteredCloseList.length == 0 && noDataFilter != '') ? <tr><td className="text-center" colSpan={5}>No data</td></tr> :
                                                            (filteredCloseList.length == 0 ?
                                                                closeIssues!=undefined && closedcurrentPageData.map((item: any,index) => (
                                                                    <tr key={index}>
                                                                        <td className="text-left custom-td">{item.grievance_type}</td>
                                                                        {/* <td className="text-left custom-td">{item.grievance_against}</td> */}
                                                                        <td className="text-left custom-td">{item.created_date!=null?FormatDate(item.created_date):'-'}</td>
                                                                        <td className="text-left custom-td">{item.name_of_entity}</td>
                                                                        <td className="text-left custom-td">{showEllipsisText(item.grievance_description, 50)}</td>
                                                                        <td className="text-left custom-td">{loggedInRole == "incubator" && <Link to={{ pathname: "/grievance_detail_incubator", state: { grievance_id: item.id.toString(), logged_in_role: loggedInRole, isClosed: true } }} className="btn btn-sisfs btn-transparent-primary">View Details</Link>}
                                                                            {loggedInRole == "dpiit" && <Link to={{ pathname: "/grievance_detail", state: { grievance_id: item.id.toString(), logged_in_role: loggedInRole, isClosed: true } }} className="btn btn-sisfs btn-transparent-primary">View Details</Link>}
                                                                            {loggedInRole == "startup" && <Link to={{ pathname: "/grievance_detail_startup", state: { grievance_id: item.id.toString(), logged_in_role: loggedInRole, isClosed: true } }} className="btn btn-sisfs btn-transparent-primary">View Details</Link>}
                                                                            {loggedInRole == "secretariat" && <Link to={{ pathname: "/grievance_detail_secretariat", state: { grievance_id: item.id.toString(), logged_in_role: loggedInRole, isClosed: true } }} className="btn btn-sisfs btn-transparent-primary">View Details</Link>}
                                                                        </td>
                                                                    </tr>
                                                                )) : closedcurrentPageData1.map((item: any,index:any) => (
                                                                    <tr key={index}>
                                                                        <td className="text-left custom-td">{item.grievance_type}</td>
                                                                        {/* <td className="text-left custom-td">{item.grievance_against}</td> */}
                                                                        <td className="text-left custom-td">{item.created_date!=null?FormatDate(item.created_date):'-'}</td>
                                                                        <td className="text-left custom-td">{item.name_of_entity}</td>
                                                                        <td className="text-left custom-td">{showEllipsisText(item.grievance_description, 50)}</td>
                                                                        <td className="text-left custom-td">{loggedInRole == "incubator" && <Link to={{ pathname: "/grievance_detail_incubator", state: { grievance_id: item.id.toString(), logged_in_role: loggedInRole, isClosed: true } }} className="btn btn-sisfs btn-transparent-primary">View Details</Link>}
                                                                            {loggedInRole == "dpiit" && <Link to={{ pathname: "/grievance_detail", state: { grievance_id: item.id.toString(), logged_in_role: loggedInRole, isClosed: true } }} className="btn btn-sisfs btn-transparent-primary">View Details</Link>}
                                                                            {loggedInRole == "startup" && <Link to={{ pathname: "/grievance_detail_startup", state: { grievance_id: item.id.toString(), logged_in_role: loggedInRole, isClosed: true } }} className="btn btn-sisfs btn-transparent-primary">View Details</Link>}
                                                                            {loggedInRole == "secretariat" && <Link to={{ pathname: "/grievance_detail_secretariat", state: { grievance_id: item.id.toString(), logged_in_role: loggedInRole, isClosed: true } }} className="btn btn-sisfs btn-transparent-primary">View Details</Link>}
                                                                        </td>
                                                                    </tr>
                                                                ))


                                                            )}


                                                    </tbody>

                                                </table>
                                            </div>
                                            {closeIssues.length == 0 ? <></>
                                                :
                                                
                                                    filteredAllList.length == 0 ?
                                                        <div style={{float:'right',display:'flex',marginRight:'20px',alignItems:'center',justifyContent:'center'}}>

                                                    <span>
                                                    <label htmlFor="itemsPerPageSelect"  className="itemsPerPage">Items per Page:</label>
                                                    <select
                                                        className="itemsPerPageSelect"
                                                        id="itemsPerPageSelect"
                                                        value={closeditemsPerPage}
                                                        onChange={handleclosedItemsPerPageChange}
                                                    >
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                        <option value="75">75</option>
                                                        <option value="100">100</option>
                                                    </select>
                                                    </span>&nbsp;&nbsp;&nbsp;

                                                    <span className="pagination" style={{fontSize:'18px',marginTop:'10px'}}>
                                                        <ReactPaginate
                                                            key={closeditemsPerPage}
                                                            pageCount={(totalPages6)}
                                                            pageRangeDisplayed={0}
                                                            marginPagesDisplayed={0}
                                                            previousLabel="Prev"
                                                            nextLabel="Next"
                                                            // breakLabel="..."
                                                            onPageChange={closedhandlePageChange}
                                                            containerClassName="pagination"
                                                            activeClassName="active"
                                                            disableInitialCallback={true}
                                                            forcePage={closedcurrentPage}
                                                        />
                                                    </span>&nbsp;&nbsp;&nbsp;

                                                    <span className="pageNum">
                                                        Page {(closedcurrentPage) + 1} of {totalPages6}
                                                    </span>
                                                        {/* &nbsp;&nbsp;&nbsp;<select value={currentPage} onChange={handleSelectedPageChange} style={{border:'0 0 1px solid black 0'}} >
                                                        {renderPageNumbers()}
                                                        </select>&nbsp;&nbsp;&nbsp; */}
                                                    </div>
                                                :<>
                                                    <div style={{float:'right',display:'flex',marginRight:'20px',alignItems:'center',justifyContent:'center'}}>

                                                        <span>
                                                        <label htmlFor="itemsPerPageSelect"  className="itemsPerPage">Items per Page:</label>
                                                        <select
                                                            className="itemsPerPageSelect"
                                                            id="itemsPerPageSelect"
                                                            value={closeditemsPerPage1}
                                                            onChange={handleclosedItemsPerPageChange}
                                                        >
                                                            <option value="25">25</option>
                                                            <option value="50">50</option>
                                                            <option value="75">75</option>
                                                            <option value="100">100</option>
                                                        </select>
                                                        </span>&nbsp;&nbsp;&nbsp;

                                                        <span className="pagination" style={{fontSize:'18px',marginTop:'10px'}}>
                                                            <ReactPaginate
                                                                key={closedcurrentPage1}
                                                                pageCount={totalPages7}
                                                                pageRangeDisplayed={0}
                                                                marginPagesDisplayed={0}
                                                                previousLabel="Prev"
                                                                nextLabel="Next"
                                                                // breakLabel="..."
                                                                onPageChange={closedhandlePageChange}
                                                                containerClassName="pagination"
                                                                activeClassName="active"
                                                                disableInitialCallback={true}
                                                                forcePage={closedcurrentPage1}
                                                            />
                                                        </span>&nbsp;&nbsp;&nbsp;

                                                        <span className="pageNum">
                                                            Page {closedcurrentPage1 + 1} of {totalPages7}
                                                        </span>
                                                            {/* &nbsp;&nbsp;&nbsp;<select value={currentPage} onChange={handleSelectedPageChange} style={{border:'0 0 1px solid black 0'}} >
                                                            {renderPageNumbers()}
                                                            </select>&nbsp;&nbsp;&nbsp; */}
                                                        </div>
                                                </>
                                            }
                                            <br /><br />
                                        </div>

                                        <div className="tab-pane active" id="tabs-3">
                                            <div className="table-scroll mb-5">
                                                <table className="reviewTable grievanceTable">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-left table-heading custom-td-left table-heading ">Grievance Type</th>
                                                            {/* <th className="text-left table-heading">Grievance Against</th> */}
                                                            <th className="text-left table-heading">Date</th>
                                                            <th className="text-left table-heading">Entity Name</th>
                                                            <th className="text-left table-heading">Grivance Message</th>
                                                            <th className="text-left table-heading"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {allIssues!=undefined && allIssues.length == 0 || (filteredAllList.length == 0 && noDataFilter != '') ? <tr><td className="text-center" colSpan={5}>No data</td></tr> :
                                                            (filteredAllList.length == 0 ?
                                                                allIssues!=undefined && allcurrentPageData.map((item: any,index:any) => (
                                                                    <tr key={index}>
                                                                        <td className="text-left custom-td">{item.grievance_type}</td>
                                                                        {/* <td className="text-left custom-td">{item.grievance_against}</td> */}
                                                                        <td className="text-left custom-td">{item.created_date!=null?FormatDate(item.created_date):'-'}</td>
                                                                        <td className="text-left custom-td">{item.name_of_entity}</td>
                                                                        <td className="text-left custom-td">{showEllipsisText(item.grievance_description, 50)}</td>
                                                                        <td className="text-left custom-td">{loggedInRole == "incubator" && <Link to={{ pathname: "/grievance_detail_incubator", state: { grievance_id: item.id.toString(), logged_in_role: loggedInRole, isClosed: false } }} className="btn btn-sisfs btn-transparent-primary">View Details</Link>}
                                                                            {loggedInRole == "dpiit" && <Link to={{ pathname: "/grievance_detail", state: { grievance_id: item.id.toString(), logged_in_role: loggedInRole, isClosed: false } }} className="btn btn-sisfs btn-transparent-primary">View Details</Link>}
                                                                            {loggedInRole == "startup" && <Link to={{ pathname: "/grievance_detail_startup", state: { grievance_id: item.id.toString(), logged_in_role: loggedInRole, isClosed: false } }} className="btn btn-sisfs btn-transparent-primary">View Details</Link>}
                                                                            {loggedInRole == "secretariat" && <Link to={{ pathname: "/grievance_detail_secretariat", state: { grievance_id: item.id.toString(), logged_in_role: loggedInRole, isClosed: true } }} className="btn btn-sisfs btn-transparent-primary">View Details</Link>}
                                                                        </td>
                                                                    </tr>
                                                                )) : allcurrentPageData1.map((item: any,index:any) => (
                                                                    <tr key={index}>
                                                                        <td className="text-left custom-td">{item.grievance_type}</td>
                                                                        {/* <td className="text-left custom-td">{item.grievance_against}</td> */}
                                                                        <td className="text-left custom-td">{item.created_date!=null?FormatDate(item.created_date):'-'}</td>
                                                                        <td className="text-left custom-td">{item.name_of_entity}</td>
                                                                        <td className="text-left custom-td">{showEllipsisText(item.grievance_description, 50)}</td>
                                                                        <td className="text-left custom-td">{loggedInRole == "incubator" && <Link to={{ pathname: "/grievance_detail_incubator", state: { grievance_id: item.id.toString(), logged_in_role: loggedInRole, isClosed: false } }} className="btn btn-sisfs btn-transparent-primary">View Details</Link>}
                                                                            {loggedInRole == "dpiit" && <Link to={{ pathname: "/grievance_detail", state: { grievance_id: item.id.toString(), logged_in_role: loggedInRole, isClosed: false } }} className="btn btn-sisfs btn-transparent-primary">View Details</Link>}
                                                                            {loggedInRole == "startup" && <Link to={{ pathname: "/grievance_detail_startup", state: { grievance_id: item.id.toString(), logged_in_role: loggedInRole, isClosed: false } }} className="btn btn-sisfs btn-transparent-primary">View Details</Link>}
                                                                            {loggedInRole == "secretariat" && <Link to={{ pathname: "/grievance_detail_secretariat", state: { grievance_id: item.id.toString(), logged_in_role: loggedInRole, isClosed: true } }} className="btn btn-sisfs btn-transparent-primary">View Details</Link>}
                                                                        </td>
                                                                    </tr>
                                                                ))


                                                            )}


                                                    </tbody>

                                                </table>
                                            </div>
                                            {allIssues.length == 0 ? <></>
                                                :
                                                
                                                    filteredAllList.length == 0 ?
                                                        <div style={{float:'right',display:'flex',marginRight:'20px',alignItems:'center',justifyContent:'center'}}>

                                                    <span>
                                                    <label htmlFor="itemsPerPageSelect"  className="itemsPerPage">Items per Page:</label>
                                                    <select
                                                        className="itemsPerPageSelect"
                                                        id="itemsPerPageSelect"
                                                        value={allitemsPerPage}
                                                        onChange={handleallItemsPerPageChange}
                                                    >
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                        <option value="75">75</option>
                                                        <option value="100">100</option>
                                                    </select>
                                                    </span>&nbsp;&nbsp;&nbsp;

                                                    <span className="pagination" style={{fontSize:'18px',marginTop:'10px'}}>
                                                        <ReactPaginate
                                                            key={allitemsPerPage}
                                                            pageCount={(totalPages)}
                                                            pageRangeDisplayed={0}
                                                            marginPagesDisplayed={0}
                                                            previousLabel="Prev"
                                                            nextLabel="Next"
                                                            // breakLabel="..."
                                                            onPageChange={allhandlePageChange}
                                                            containerClassName="pagination"
                                                            activeClassName="active"
                                                            disableInitialCallback={true}
                                                            forcePage={allcurrentPage}
                                                        />
                                                    </span>&nbsp;&nbsp;&nbsp;

                                                    <span className="pageNum">
                                                        Page {(allcurrentPage) + 1} of {totalPages}
                                                    </span>
                                                        {/* &nbsp;&nbsp;&nbsp;<select value={currentPage} onChange={handleSelectedPageChange} style={{border:'0 0 1px solid black 0'}} >
                                                        {renderPageNumbers()}
                                                        </select>&nbsp;&nbsp;&nbsp; */}
                                                    </div>
                                                :<>
                                                    <div style={{float:'right',display:'flex',marginRight:'20px',alignItems:'center',justifyContent:'center'}}>

                                                        <span>
                                                        <label htmlFor="itemsPerPageSelect"  className="itemsPerPage">Items per Page:</label>
                                                        <select
                                                            className="itemsPerPageSelect"
                                                            id="itemsPerPageSelect"
                                                            value={allitemsPerPage1}
                                                            onChange={handleallItemsPerPageChange}
                                                        >
                                                            <option value="25">25</option>
                                                            <option value="50">50</option>
                                                            <option value="75">75</option>
                                                            <option value="100">100</option>
                                                        </select>
                                                        </span>&nbsp;&nbsp;&nbsp;

                                                        <span className="pagination" style={{fontSize:'18px',marginTop:'10px'}}>
                                                            <ReactPaginate
                                                                key={allitemsPerPage1}
                                                                pageCount={totalPages1}
                                                                pageRangeDisplayed={0}
                                                                marginPagesDisplayed={0}
                                                                previousLabel="Prev"
                                                                nextLabel="Next"
                                                                // breakLabel="..."
                                                                onPageChange={allhandlePageChange}
                                                                containerClassName="pagination"
                                                                activeClassName="active"
                                                                disableInitialCallback={true}
                                                                forcePage={allcurrentPage1}
                                                            />
                                                        </span>&nbsp;&nbsp;&nbsp;

                                                        <span className="pageNum">
                                                            Page {allcurrentPage1 + 1} of {totalPages1}
                                                        </span>
                                                            {/* &nbsp;&nbsp;&nbsp;<select value={currentPage} onChange={handleSelectedPageChange} style={{border:'0 0 1px solid black 0'}} >
                                                            {renderPageNumbers()}
                                                            </select>&nbsp;&nbsp;&nbsp; */}
                                                        </div>
                                                </>
                                            }
                                            <br /><br />
                                        </div>

                                        <div className="tab-pane" id="tabs-4">
                                            <div className="table-scroll mb-5">
                                                <table className="reviewTable grievanceTable">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-left table-heading custom-td-left table-heading ">Grievance Type</th>
                                                            {/* <th className="text-left table-heading">Grievance Against</th> */}
                                                            <th className="text-left table-heading">Date</th>
                                                            <th className="text-left table-heading">Entity Name</th>
                                                            <th className="text-left table-heading">Grivance Message</th>
                                                            <th className="text-left table-heading"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {pendingIssues!=undefined && pendingIssues.length == 0 || (filteredPendingList.length == 0 && noDataFilter != '') ? <tr><td className="text-center" colSpan={5}>No data</td></tr> :
                                                            (filteredPendingList.length == 0 ?
                                                                pendingIssues!=undefined && pendcurrentPageData.map((item: any,index:any) => (
                                                                    <tr key={index}>
                                                                        <td className="text-left custom-td">{item.grievance_type}</td>
                                                                        {/* <td className="text-left custom-td">{item.grievance_against}</td> */}
                                                                        <td className="text-left custom-td">{item.created_date!=null?FormatDate(item.created_date):'-'}</td>
                                                                        <td className="text-left custom-td">{item.name_of_entity}</td>
                                                                        <td className="text-left custom-td">{showEllipsisText(item.grievance_description, 50)}</td>
                                                                        <td className="text-left custom-td">{loggedInRole == "incubator" && <Link to={{ pathname: "/grievance_detail_incubator", state: { grievance_id: item.id.toString(), logged_in_role: loggedInRole, isClosed: false } }} className="btn btn-sisfs btn-transparent-primary">View Details</Link>}
                                                                            {loggedInRole == "dpiit" && <Link to={{ pathname: "/grievance_detail", state: { grievance_id: item.id.toString(), logged_in_role: loggedInRole, isClosed: false } }} className="btn btn-sisfs btn-transparent-primary">View Details</Link>}
                                                                            {loggedInRole == "startup" && <Link to={{ pathname: "/grievance_detail_startup", state: { grievance_id: item.id.toString(), logged_in_role: loggedInRole, isClosed: false } }} className="btn btn-sisfs btn-transparent-primary">View Details</Link>}
                                                                            {loggedInRole == "secretariat" && <Link to={{ pathname: "/grievance_detail_secretariat", state: { grievance_id: item.id.toString(), logged_in_role: loggedInRole, isClosed: true } }} className="btn btn-sisfs btn-transparent-primary">View Details</Link>}
                                                                        </td>
                                                                    </tr>
                                                                )) : pendcurrentPageData1.map((item: any,index:any) => (
                                                                    <tr key={index}>
                                                                        <td className="text-left custom-td">{item.grievance_type}</td>
                                                                        {/* <td className="text-left custom-td">{item.grievance_against}</td> */}
                                                                        <td className="text-left custom-td">{item.created_date!=null?FormatDate(item.created_date):'-'}</td>
                                                                        <td className="text-left custom-td">{item.name_of_entity}</td>
                                                                        <td className="text-left custom-td">{showEllipsisText(item.grievance_description, 50)}</td>
                                                                        <td className="text-left custom-td">{loggedInRole == "incubator" && <Link to={{ pathname: "/grievance_detail_incubator", state: { grievance_id: item.id.toString(), logged_in_role: loggedInRole, isClosed: false } }} className="btn btn-sisfs btn-transparent-primary">View Details</Link>}
                                                                            {loggedInRole == "dpiit" && <Link to={{ pathname: "/grievance_detail", state: { grievance_id: item.id.toString(), logged_in_role: loggedInRole, isClosed: false } }} className="btn btn-sisfs btn-transparent-primary">View Details</Link>}
                                                                            {loggedInRole == "startup" && <Link to={{ pathname: "/grievance_detail_startup", state: { grievance_id: item.id.toString(), logged_in_role: loggedInRole, isClosed: false } }} className="btn btn-sisfs btn-transparent-primary">View Details</Link>}
                                                                            {loggedInRole == "secretariat" && <Link to={{ pathname: "/grievance_detail_secretariat", state: { grievance_id: item.id.toString(), logged_in_role: loggedInRole, isClosed: true } }} className="btn btn-sisfs btn-transparent-primary">View Details</Link>}
                                                                        </td>
                                                                    </tr>
                                                                ))


                                                            )}


                                                    </tbody>

                                                </table>
                                            </div>
                                            {pendingIssues.length == 0 ? <></>
                                                :
                                                
                                                    filteredAllList.length == 0 ?
                                                        <div style={{float:'right',display:'flex',marginRight:'20px',alignItems:'center',justifyContent:'center'}}>

                                                    <span>
                                                    <label htmlFor="itemsPerPageSelect"  className="itemsPerPage">Items per Page:</label>
                                                    <select
                                                        className="itemsPerPageSelect"
                                                        id="itemsPerPageSelect"
                                                        value={penditemsPerPage}
                                                        onChange={pendhandlependItemsPerPageChange}
                                                    >
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                        <option value="75">75</option>
                                                        <option value="100">100</option>
                                                    </select>
                                                    </span>&nbsp;&nbsp;&nbsp;

                                                    <span className="pagination" style={{fontSize:'18px',marginTop:'10px'}}>
                                                        <ReactPaginate
                                                            key={penditemsPerPage}
                                                            pageCount={(totalPages4)}
                                                            pageRangeDisplayed={0}
                                                            marginPagesDisplayed={0}
                                                            previousLabel="Prev"
                                                            nextLabel="Next"
                                                            // breakLabel="..."
                                                            onPageChange={pendhandlePageChange}
                                                            containerClassName="pagination"
                                                            activeClassName="active"
                                                            disableInitialCallback={true}
                                                            forcePage={pendcurrentPage}
                                                        />
                                                    </span>&nbsp;&nbsp;&nbsp;

                                                    <span className="pageNum">
                                                        Page {(pendcurrentPage) + 1} of {totalPages4}
                                                    </span>
                                                        {/* &nbsp;&nbsp;&nbsp;<select value={currentPage} onChange={handleSelectedPageChange} style={{border:'0 0 1px solid black 0'}} >
                                                        {renderPageNumbers()}
                                                        </select>&nbsp;&nbsp;&nbsp; */}
                                                    </div>
                                                :<>
                                                    <div style={{float:'right',display:'flex',marginRight:'20px',alignItems:'center',justifyContent:'center'}}>

                                                        <span>
                                                        <label htmlFor="itemsPerPageSelect"  className="itemsPerPage">Items per Page:</label>
                                                        <select
                                                            className="itemsPerPageSelect"
                                                            id="itemsPerPageSelect"
                                                            value={penditemsPerPage1}
                                                            onChange={pendhandlependItemsPerPageChange}
                                                        >
                                                            <option value="25">25</option>
                                                            <option value="50">50</option>
                                                            <option value="75">75</option>
                                                            <option value="100">100</option>
                                                        </select>
                                                        </span>&nbsp;&nbsp;&nbsp;

                                                        <span className="pagination" style={{fontSize:'18px',marginTop:'10px'}}>
                                                            <ReactPaginate
                                                                key={penditemsPerPage1}
                                                                pageCount={totalPages5}
                                                                pageRangeDisplayed={0}
                                                                marginPagesDisplayed={0}
                                                                previousLabel="Prev"
                                                                nextLabel="Next"
                                                                // breakLabel="..."
                                                                onPageChange={pendhandlePageChange}
                                                                containerClassName="pagination"
                                                                activeClassName="active"
                                                                disableInitialCallback={true}
                                                                forcePage={pendcurrentPage1}
                                                            />
                                                        </span>&nbsp;&nbsp;&nbsp;

                                                        <span className="pageNum">
                                                            Page {pendcurrentPage1 + 1} of {totalPages5}
                                                        </span>
                                                            {/* &nbsp;&nbsp;&nbsp;<select value={currentPage} onChange={handleSelectedPageChange} style={{border:'0 0 1px solid black 0'}} >
                                                            {renderPageNumbers()}
                                                            </select>&nbsp;&nbsp;&nbsp; */}
                                                        </div>
                                                </>
                                            }
                                            <br /><br />
                                        </div>
                                    </div>
                                    {/* end */}
                                </div>
                                {/* selected tab */}
                                <div className={activeTab == "contact" ? "tab-pane active" : "tab-pane"} id="tab-contact">
                                    <div className="row incubator-heading-review">
                                        <div className="col-md-6 text-left">

                                        </div>
                                        <div className="col-md-6 text-right search-left">
                                            <div className="d-flex searchDiv"> <img
                                                src={search}
                                                className="search"
                                                alt="search"

                                            />
                                                <input
                                                    className="searchBar"
                                                    type="text"
                                                    placeholder="Search.."
                                                    name="search"
                                                    onKeyUp={searchList}
                                                />
                                                {/* <img src={CalendarIcon} className="calendar-icon review-calendar-icon" alt="Calendar icon to select date" /> */}
                                            </div>

                                        </div>
                                    </div>
                                    <div className="table-scroll">
                                        <table className="reviewTable contactUsTable" >
                                            <thead>
                                                <tr>
                                                    <th className="text-left table-heading custom-td-left table-heading ">Entity Type</th>
                                                    <th className="text-left table-heading">Entity Name</th>
                                                    <th className="text-left table-heading">Query Type</th>
                                                    <th className="text-left table-heading">Query</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                             {contactDetails.length == 0 || (filteredContactList.length == 0 && noDataFilter != '') ? <tr><td className="text-center" colSpan={5}>No data</td></tr> :
                                                    (filteredContactList.length == 0 ?
                                                        contactDetails.map((item: any, index: any) => (
                                                            <tr key={index}>
                                                                <td className="text-left custom-td-left">{item.entity_type}</td>
                                                                <td className="text-left custom-td">{item.entity_name}</td>
                                                                <td className="text-left custom-td">{item.query_type}</td>
                                                                <td>{showViewMore != "show_" + index && <><div>{showEllipsisText(item.message, 50)}</div>{item.message.length >= 50 && <span className="link-orange-text d-block" onClick={() => setShowViewMore('show_' + index)}>View More</span>}</>}
                                                                    {showViewMore == "show_" + index && <><div>{item.message}</div><span className="link-orange-text d-block" onClick={() => setShowViewMore('')}>View Less</span></>}</td>
                                                            </tr>
                                                        )) : filteredContactList.map((item: any, index: any) => (
                                                            <tr key={index}>
                                                                <td className="text-left custom-td-left">{item.entity_type}</td>
                                                                <td className="text-left custom-td">{item.entity_name}</td>
                                                                <td className="text-left custom-td">{item.query_type}</td>
                                                                <td>{showViewMore != "show_" + index && <><div>{showEllipsisText(item.message, 50)}</div>{item.message.length >= 50 && <span className="link-orange-text d-block" onClick={() => setShowViewMore('show_' + index)}>View More</span>}</>}
                                                                    {showViewMore == "show_" + index && <><div>{item.message}</div><span className="link-orange-text d-block" onClick={() => setShowViewMore('')}>View Less</span></>}</td>
                                                            </tr>
                                                        ))


                                                    )} 
                                            </tbody>

                                        </table>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
            </div>
            </fieldset>
        </>
    );
}

export default GrievanceListComponent;
