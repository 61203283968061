import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import LayoutRouterComponent from "./LayoutRouterComponent";
import DpiitRouterComponent from "./DpiitRouterComponent";
import EacRouterComponent from "./EacRouterComponent";
import IncubatorRouterComponent from "./IncubatorRouterComponent";
import StartupRouterComponent from "./StartupRouterComponent";
import ErrorContainer from "../components/error/error";
import ErrorGlobal from "../components/error/errorGlobal";
import FundDetailsContainer from "../components/incubator/dashboard/FundDetailsContainer";
import ConfirmPasswordComponent from "../components/common/login/ConfirmPasswordComponent";
import SecretariatRouterComponent from "./SecretariatRouterComponent";
import EacStartupListingComponent from "../components/eac/dashboard/EacStartupListingComponent";
import ReApplyIncComponent from "../components/incubator/application/ReApplyIncComponent";
import ApplicationStatusApply from "../components/incubator/application/myapplications/ApplicationStatusApply";

import ReApplied from "../components/dpiit/dashboard/ReAppliedComponent";
import FundRequiremntDtls from "../components/incubator/application/FundRequiremntDtls";
import { getSessionStorageValue } from "../effects/utils/session-storage";
import { useEffect, useState } from "react";
import NoticeBoard from "../components/notice_board/NoticeBoard";

const RouterComponent = () => {
  // const location = useLocation();
  // let history = useHistory();
  // console.log(location.pathname);
  // if(location.pathname == "/" ||location.pathname == ""){
  //   history.push('/home')
  // }
  const [roles, setRole] = useState("");

  useEffect(() => {
    // console.log("MAIN")
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    setRole(userInfo?.roles);
  });
  return (
    <>
      <Switch>
        {/* <Route exact path="/home" component={LayoutRouterComponent} /> */}

        <Route exact path="/" component={LayoutRouterComponent} />

        <Route exact path="/home" component={LayoutRouterComponent} />

        <Route exact path="/about" component={LayoutRouterComponent} />

        <Route exact path="/portfolio" component={LayoutRouterComponent} />

        <Route
          exact
          path="/portfolio_detail"
          component={LayoutRouterComponent}
        />
        <Route
          exact
          path="/startup_portfolio"
          component={LayoutRouterComponent}
        />

        <Route
          exact
          path="/startup_portfolio_detail"
          component={LayoutRouterComponent}
        />
        <Route exact path="/faq" component={LayoutRouterComponent} />

        <Route exact path="/contact" component={LayoutRouterComponent} />

        <Route exact path="/glossary" component={LayoutRouterComponent} />

        <Route exact path="/error" component={LayoutRouterComponent} />

        <Route
          path="/confirm/:token/:email"
          component={ConfirmPasswordComponent}
        />

        {/* <RequireAuth> */}

        <Route
          exact
          path="/incubator_dashboard"
          component={IncubatorRouterComponent}
        />
        <Route
          exact
          path="/incubator_apply"
          component={IncubatorRouterComponent}
        />
        <Route
          exact
          path="/incubator_notice_board"
          component={IncubatorRouterComponent}
        />

        <Route
          exact
          path="/incubator_status"
          component={IncubatorRouterComponent}
        />
        <Route
          exact
          path="/incubator_portfolio"
          component={IncubatorRouterComponent}
        />
        <Route
          exact
          path="/incubator_re_apply"
          component={IncubatorRouterComponent}
        />

        <Route path="/fund_details_re_apply" component={FundRequiremntDtls} />

        <Route exact path="/incubator" component={IncubatorRouterComponent} />
        <Route exact path="/re-apply" component={ReApplyIncComponent} />

        <Route
          exact
          path="/incubator_meeting"
          component={IncubatorRouterComponent}
        />

        <Route
          exact
          path="/incubator_grievances"
          component={IncubatorRouterComponent}
        />

        <Route
          exact
          path="/incubator_startup_list"
          component={IncubatorRouterComponent}
        />

        <Route
          exact
          path="/contact_incubator"
          component={IncubatorRouterComponent}
        />

        <Route
          path="/grievance_detail_incubator"
          component={IncubatorRouterComponent}
        />

        <Route
          exact
          path="/view_startup_details"
          component={IncubatorRouterComponent}
        />

        <Route
          path="/preview_portfolio_page"
          component={IncubatorRouterComponent}
        />

        <Route exact path="/eac_dashboard" component={EacRouterComponent} />

        <Route exact path="/eac_review" component={EacRouterComponent} />

        <Route exact path="/view_eac_review" component={EacRouterComponent} />
        <Route exact path="/eac_notice_board" component={EacRouterComponent} />
        <Route
          exact
          path="/view_incubator_eac"
          component={EacRouterComponent}
        />

        <Route
          exact
          path="/merged_list"
          component={
            roles == "eac" ? EacRouterComponent : SecretariatRouterComponent
          }
        />

        <Route
          path="/merged_Incubator_Details_list"
          component={
            roles == "eac" ? EacRouterComponent : SecretariatRouterComponent
          }
        />

        <Route exact path="/dashboard_dpiit" component={DpiitRouterComponent} />

        {/* <Route exact path="/dpiit_incubator_list" component={DpiitRouterComponent} /> */}

        {/* <Route exact path="/merged_list" component={DpiitRouterComponent} /> */}

        <Route
          exact
          path="/dpiit_startup_list"
          component={DpiitRouterComponent}
        />
        <Route
          exact
          path="/dpiit_notice_board"
          component={DpiitRouterComponent}
        />
        <Route
          exact
          path="/eac_review_dpiit"
          component={DpiitRouterComponent}
        />

        <Route
          exact
          path="/dpiit_startup_details"
          component={DpiitRouterComponent}
        />

        <Route
          path="/dpiit_startup_list_details"
          component={DpiitRouterComponent}
        />

        <Route
          exact
          path="/dpiit_view_incubator"
          component={DpiitRouterComponent}
        />

        <Route path="/user_add" component={DpiitRouterComponent} />

        <Route path="/user_view" component={DpiitRouterComponent} />

        <Route path="/grievance_detail" component={DpiitRouterComponent} />

        <Route path="/dpiit_grievances" component={DpiitRouterComponent} />

        <Route path="/contact_us" component={DpiitRouterComponent} />
        {/* secretariat routes added */}
        <Route
          path="/dashboard_secretariat"
          component={SecretariatRouterComponent}
        />

        <Route
          path="/eac_review_secretariat"
          component={SecretariatRouterComponent}
        />

        <Route
          path="/secretariat_startup_list"
          component={SecretariatRouterComponent}
        />

        <Route
          path="/grievance_detail_secretariat"
          component={SecretariatRouterComponent}
        />
        <Route
          path="/sec_notice_board"
          component={SecretariatRouterComponent}
        />
        <Route
          path="/secretariat_incubator_list"
          component={SecretariatRouterComponent}
        />

        <Route
          path="/secretariat_startup_list_details"
          component={SecretariatRouterComponent}
        />

        <Route
          path="/secretariat_grievances"
          component={SecretariatRouterComponent}
        />

        <Route
          path="/user_add_secretariat"
          component={SecretariatRouterComponent}
        />

        <Route
          path="/user_view_secretariat"
          component={SecretariatRouterComponent}
        />

        <Route
          path="/secretariat_view_incubator"
          component={SecretariatRouterComponent}
        />
        <Route
          path="/secretariat_view_application"
          component={SecretariatRouterComponent}
        />
        <Route
          path="/secretariat_startup_details"
          component={SecretariatRouterComponent}
        />
        <Route
          exact
          path="/secretariat/subsequent_tranche/:id/:tranche_number/:app_index/:application_id/:trancheID/:tranche_status/:incubator_application_no"
          component={SecretariatRouterComponent}
        />
        <Route
          exact
          path="/secretariat/subsequent_tranche_two/:id/:tranche_number/:app_index/:application_id/:trancheID/:tranche_status/:incubator_application_no"
          component={SecretariatRouterComponent}
        />
        <Route
          exact
          path="/secretariat/first_subsequent_tranche/:id/:tranche_number/:app_index/:application_id/:trancheID/:tranche_status/:incubator_application_no"
          component={SecretariatRouterComponent}
        />

        <Route
          path="/contact_us_secretariat"
          component={SecretariatRouterComponent}
        />
        <Route path="/re_applied" component={ReApplied} />

        {/* secretariat routes end */}

        <Route
          exact
          path="/view_selection_meeting"
          component={EacRouterComponent}
        />

        <Route path="/eac_startup_details" component={EacRouterComponent} />

        <Route
          path="/eac_startup_list_details"
          component={EacRouterComponent}
        />

        <Route
          exact
          path="/eac_view_incubator"
          component={EacRouterComponent}
        />

        {/* <Route path="/user_add_eac" component={EacRouterComponent} />

        <Route path="/user_view_eac" component={EacRouterComponent} /> */}

        <Route exact path="/startup" component={StartupRouterComponent} />

        <Route
          exact
          path="/startup_meeting"
          component={StartupRouterComponent}
        />

        <Route
          exact
          path="/contact_startup"
          component={StartupRouterComponent}
        />

        <Route
          exact
          path="/startup_status"
          component={StartupRouterComponent}
        />
        <Route
          path="/startup_notice_board"
          component={StartupRouterComponent}
        />
        <Route
          exact
          path="/startupApplications/:token"
          component={StartupRouterComponent}
        />

        <Route
          path="/grievance_detail_startup"
          component={StartupRouterComponent}
        />

        <Route path="/startup_grievances" component={StartupRouterComponent} />

        <Route
          exact
          path="/startup_fund_details"
          component={StartupRouterComponent}
        />

        <Route exact path="/startup_apply" component={StartupRouterComponent} />
        <Route
          exact
          path="/incubatorApplications/:token"
          component={IncubatorRouterComponent}
        />
        <Route
          exact
          path="/incubator_view_funddetails"
          component={IncubatorRouterComponent}
        />
        <Route
          exact
          path="/subsequent_tranche/:application_id/:tranche_id/:fund_request/:cash_in_hand/:tranche_number/:app_index/:incubator_application_no"
          component={IncubatorRouterComponent}
        />
        <Route
          exact
          path="/subsequent_tranche_two/:application_id/:tranche_id/:fund_request/:cash_in_hand/:tranche_number/:app_index/:incubator_application_no"
          component={IncubatorRouterComponent}
        />
        <Route
          exact
          path="/first_subsequent_tranche/:application_id/:tranche_id/:fund_request/:cash_in_hand/:tranche_number/:app_index/:incubator_application_no"
          component={IncubatorRouterComponent}
        />

        <Route
          path="/eac_startup_list"
          component={EacStartupListingComponent}
        />

        <Route
          path="/incubator_prefrence_sec"
          component={SecretariatRouterComponent}
        />
        <Route
          path="/secretariatreports"
          component={SecretariatRouterComponent}
        />
        <Route path="/monthlyreport" component={SecretariatRouterComponent} />
        <Route path="/qr_view" component={SecretariatRouterComponent} />
        <Route
          path="/incubators_portfolio"
          component={SecretariatRouterComponent}
        />
        <Route
          path="/preview_portfolio"
          component={SecretariatRouterComponent}
        />

        {/* <Route path="/merged_list" component={roles == 'dpiit'?DpiitRouterComponent:SecretariatRouterComponent} />        
        
        <Route path="/merged_Incubator_Details_list" component={roles == 'dpiit'?DpiitRouterComponent:SecretariatRouterComponent} /> */}

        {/* <Route path="/merged_list" component={SecretariatRouterComponent} />         */}

        {/* <Route path="/dpiit/merged_list" component={DpiitRouterComponent} />        
        
        <Route path="/dpiit/merged_Incubator_Details_list" component={DpiitRouterComponent} /> */}

        <Route
          path="/incubator_prefrence_dpit"
          component={DpiitRouterComponent}
        />

        <Route component={ErrorGlobal} />
        {/* </RequireAuth> */}
      </Switch>
    </>
  );
};

export default RouterComponent;
