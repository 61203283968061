import '../incubator/incubator.css';
import Plus from '../../../images/Plus.svg';
import Minus from '../../../images/Minus.svg';
import React, { useEffect, useState } from 'react';
import pdf from '../../../images/PDF.svg';
import xls from '../../../images/xls.svg';
import doc from '../../../images/doc.svg';
import xlsx from '../../../images/xls.svg';
import Allround from '../../../images/Allround.svg';
import TrancheStepperComponent from './TrancheStepperComponent';
import Stepper from 'bs-stepper';
import 'bs-stepper/dist/css/bs-stepper.min.css';
import {
  AddTranche,
  Documents,
  TrancheDetail,
} from '../../../models/funddetails/TrancheDetails';
import {
  ADD_MORE_TRANCHE,
  ADD_TRANCHE_WITH_AMOUNT,
  GET_ALL_TRANCHES,
  GET_BANK_DETAILS,
  GET_TRANCHE_REQUEST,
  QUICK_EDIT,
} from '../../../constants/api';
import { get, post } from '../../../effects/api/api.common';
import TrancheInfoComponent from '../../incubator/common/TrancheInfoComponent';
import TrancheWiseDetails from '../../incubator/common/TrancheWiseDetails';
import { UploadTypeEnum } from '../../../utils/Enums';
import { changeNumberFormat, createFile } from '../../../utils/CommonMethods';
import { FileDownloadModel } from './ApplicationGeneralDetails';
import AccountDetailsComponent from '../../incubator/dashboard/AccountDetailsComponent';
import { AccountDetailsData } from '../../../models/funddetails/AccountDetailsData';
import { getSessionStorageValue } from '../../../effects/utils/session-storage';

interface IFundDetailsProps {
  application_id: number;
  total_grant_approved: number;
  trancheData: any;
  trancheDataASC: any;
  accountDetailsData: AccountDetailsData;
  application_status:string;
  total_grant_disbursed:number;
  totalManagementAmount:number;
 
}

function IncubatorFundDetailsComponent(props: IFundDetailsProps) {
  const {
    application_id,
    total_grant_approved,
    trancheData,
    trancheDataASC,
    accountDetailsData,
    application_status,
    total_grant_disbursed,
    totalManagementAmount

  } = props;

  // console.log("***********",accountDetailsData);
  const [isTrancheStepper, setStepper] = useState<boolean>(true);

  const [activeIndex, setActiveIndex] = useState('');
  const changeActive = (id: string) => {
    setActiveIndex(id);
  };
  const changeActiveBack = () => {
    setActiveIndex('');
  };
  const [loggedInRole, setLoggedInRole] = useState('');

  const user: any = getSessionStorageValue("user");
  const userInfo = JSON.parse(user) 

  useEffect(() => {
    setStepper(true);
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    setLoggedInRole(userInfo.roles);
    // getAccountDetails();
    // console.log("**************")
    setGrantApproved(total_grant_approved == null ? "0" : total_grant_approved.toString());
    setManagementAmount(totalManagementAmount==null?"0":totalManagementAmount.toString())
    
  }, [total_grant_approved]);
   
  const [total_grand_approved_amount, setGrantApproved] = useState("");
  const [total_Management_approved_amount, setManagementAmount] = useState("");
  useEffect(()=>{
    // console.log("total managment",total_Management_approved_amount)
   
  },[total_grand_approved_amount])

  const [addMoreTrancheData, setAddMoreTrancheData] = useState<AddTranche>(
    new AddTranche(0, '', 0, 0)
  );
  const [isAddTrancheRowVisible, setIsAddTrancheRowVisible] = useState(true);

  const [showEditSuccessModal, setShowSuccessModal] = useState(false);
  const [showEditFailureModal, setShowFailureModal] = useState(false);
  const dismissModal = () => {
    setShowSuccessModal(false);
    setShowFailureModal(false);
  };

  const addMoreTranche = () => {
    let addTranche = JSON.parse(JSON.stringify(addMoreTrancheData));
    addTranche['application_id'] = application_id;
    setAddMoreTrancheData(addTranche);

    setIsAddTrancheRowVisible(false);

    let addMoreTrancheDetails = {
      application_id: application_id,
      approved_amount: addMoreTrancheData.amount_approved,
      logged_user_id:userInfo.id
    };

    post(`${ADD_TRANCHE_WITH_AMOUNT}`, addMoreTrancheDetails).then(
      (response: any) => {
        if (!response || !response.data) return;
        if (response.data.result == 'success') {
          setShowSuccessModal(true);
        } else {
          setShowFailureModal(true);
        }
        //getAllTranches();
      }
    );
  };
  const cancel = () => { };

  const handleChange = (
    evt: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const name = evt.target.name;
    const value = evt.target.value;

    let trancheDetails = JSON.parse(JSON.stringify(trancheData));
    if (name == "amount_approved") {

      const numStr = String(evt.target.value);
      var count = 0;
      if (numStr.includes('.')) {
        count = numStr.split('.')[1].length;
      };
      if (count > 2) {
        trancheDetails[name] = Number(evt.target.value).toFixed(2);
      }
      else { trancheDetails[name] = evt.target.value; }

    }
    else {
      trancheDetails[name] = evt.target.value;
    }

    setAddMoreTrancheData(trancheDetails);
  };


  const handleChangeTotalGrantApproved = (evt: React.ChangeEvent<
    HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
  >) => {
    const name = evt.target.name;
    const value = evt.target.value;

    let quickEdit = JSON.parse(JSON.stringify(quickEditPopupData));

    const numStr = String(evt.target.value);
    var count = 0;
    if (numStr.includes('.')) {
      count = numStr.split('.')[1].length;
    };
    if (count > 2) {
      quickEdit[name] = Number(evt.target.value).toFixed(2);
    }
    else { quickEdit[name] = evt.target.value; }

    //quickEdit["total_approved_amt"] = value;
    setQuickEditPopupData(quickEdit);
  }

  const formatDate = (date: string) => {
    var month = new Array();
    month[0] = 'Jan';
    month[1] = 'Feb';
    month[2] = 'Mar';
    month[3] = 'April';
    month[4] = 'May';
    month[5] = 'Jun';
    month[6] = 'Jul';
    month[7] = 'Aug';
    month[8] = 'Sep';
    month[9] = 'Oct';
    month[10] = 'Nov';
    month[11] = 'Dec';

    var d = new Date(date),
      monthn = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (monthn.length < 2) monthn = '0' + monthn;
    if (day.length < 2) day = '0' + day;

    return [day, month[d.getMonth()], year].join(' ');
  };

  const [quickEditPopupData, setQuickEditPopupData] = useState({ application_id: "", total_approved_amt: "", application_status: "" });
  const updateTotalGrantApproved = () => {
    var payload = {
      application_id: application_id,
      total_approved_amt: quickEditPopupData.total_approved_amt,
      application_status: "7"
    }

    post(QUICK_EDIT, payload).then((response: any) => {
      if (!response || !response.data) return;
      // console.log(response.data)


      if (response.data.result == "success") {
        // console.log(response.data)
        setGrantApproved(quickEditPopupData.total_approved_amt);
        setManagementAmount(response.data.data.total_management_amount.toString())
        // setManagementAmount(amountMangement)

        
      }
      else {

      }
      // console.log(response.data)
    });
  }

  const setQuickEdit = (value: string) => {
    let quickEdit = JSON.parse(JSON.stringify(quickEditPopupData));
    // quickEdit["total_approved_amt"] = Number(value).toFixed();
    quickEdit["total_approved_amt"] = Number(value);
    
    setQuickEditPopupData(quickEdit);

  }



  const getFileDetails = (file: any, key: string) => {
    let file_name = '';
    let file_ext = '';
    file_name =
      file != undefined && file.length > 1
        ? file.substr(file.lastIndexOf('/') + 1, file.length)
        : '';
    file_ext = file != undefined && file.length > 1
      ? file.substr(
        file.lastIndexOf('.') + 1,
        file.length
      ) : "";
    if (key == 'ext') return file_ext;
    if (key == 'name') return file_name;
    if (key == 'file') return file.toString();
  };

  const downloadDocument = (file: any, file_name: any) => {
    fetch(file).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = file_name;
        a.click();
      });
      //window.location.href = response.url;
    });
  };


  return (
    <div className="container">
      <div className="row">
        <div className="col-md-3 left-align" style={{maxWidth:'20%'}}>
          <span className="fund-amount-cur">
            &#8377;
            <label className="fund-amount-label-value">
              {' '}
              {total_grand_approved_amount != null && total_grand_approved_amount != "0"
                ? changeNumberFormat(Number(total_grand_approved_amount), 2, 1)
                : 0}
            </label>
            {/* Cr */}
          </span>
          <br />
          <span className="fund-amount-label">Total Grant Approved</span>
          <br />
          {(application_status == "Selected" && loggedInRole != "eac")
            ?
            <a className="incubator-details-link"
              data-target="#editTotalGrantApproved"
              data-toggle="modal"
              onClick={() => setQuickEdit(total_grand_approved_amount.toString())}
            >Edit</a>

            :
            ""}

        </div>
        <div className="vl vl-mr"></div>
        <div className="col-md-3 left-align" style={{maxWidth:'20%'}}>
          <span className="fund-amount-cur">
            &#8377;
            <label className="fund-amount-label-value">
              {/* {console.log(total_Management_approved_amount)} */}
              {' '}
              {total_Management_approved_amount != null && total_Management_approved_amount !="0"
                ? changeNumberFormat(Number(total_Management_approved_amount), 2, 1)
                : 0}
            </label>
            {/* Cr */}
          </span>
          <br />
          <span className="fund-amount-label">Total Management <br/>Fees</span>
          <br />
        </div>






        <div className="vl vl-mr"></div>
        <div className="col-md-3 left-align" style={{maxWidth:'20%'}}>
          <span className="fund-amount-cur">
            <label className="fund-amount-label-value">
              {trancheData.length}{' '}
            </label>
          </span>
          <br />

          <span className="fund-amount-label">Total No. of Tranches</span>
          <br />
          {loggedInRole != "eac" && <a
            className="incubator-details-link"
            data-target="#addMoreTranchePopUp"
            data-toggle="modal"
          >
            Add More Tranche
          </a>}
        </div>

        <div className="vl vl-mr"></div>
        <div className="col-md-3 " style={{maxWidth:'20%'}}>
          <span className="fund-amount-cur">
            &#8377;
            <label className="fund-amount-label-value">
              {' '}
              {total_grant_disbursed != null ? changeNumberFormat(Number(total_grant_disbursed.toString()), 2, 1) : 0}{' '}
            </label>

          </span>
          <br />
          <span className="fund-amount-label">
            Total Funds Disbursed to Incubator
          </span>
          <br />
          <a
            className="incubator-details-link"
            data-target="#viewBankDetails"
            data-toggle="modal"
          >
            View Bank Details
          </a>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-md-9">
          <TrancheInfoComponent trancheDetailsModel={trancheDataASC} />
        </div>
      </div> */}

      <div className="row ">
        <div className="col-md-12 left-align">
          <span className="eac-heading-title">No. of Tranches</span>
        </div>
      </div>
      <div className="application-incubator-hr">
        <hr />
      </div>

      <div className="row">
        <div className="col-md-12">
          <TrancheWiseDetails trancheDetailsModel={trancheData} total_grant_approved={total_grant_approved} RequestedTranche={""} />
        </div>
      </div>

      {/* Total Grant Approved Edit pop up */}
      <div
        className="modal fade"
        id="editTotalGrantApproved"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none">
              <h5 className="modal-title " id="exampleModalLongTitle">
                Update Total Grant Approved <span className="eac-name-pop-up"></span>
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12 text-left">
                  <span className="col-form-head">Total Grant Approved</span>
                </div>
              </div>
              <div className="row mt-1-5">
                <div className="col-md-12 text-left">
                  <input
                    type="number"
                    className="form-control"
                    name="total_approved_amt"
                    value={quickEditPopupData.total_approved_amt}
                    placeholder="Enter total grant approved"
                    onChange={handleChangeTotalGrantApproved}
                  ></input>

                </div>
              </div>

              <div className="row">
                <div className="col-md-12 text-right">
                  <div className="btn-group mt-1-5 ">
                    <button
                      className="tertiary   btn-transparent-primary"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>

                    <button
                      type="button"
                      className="btn btn-sisfs btn-yellow-primary ml-2"
                      onClick={() => updateTotalGrantApproved()}
                      data-dismiss="modal"
                    >
                      Update{' '}
                    </button>
                  </div>
                </div>
              </div>

              <div className="row pt-3 mb-3">
                <div className="col-md-12 centerText"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* //Add more tranche */}

      <div
        className="modal fade"
        id="addMoreTranchePopUp"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none ml-18px">
              <h5 className="modal-title pl-2" id="exampleModalLongTitle">
                Add More Tranche<span></span>
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body mt-15-px">
              <div className="container">
                <div className="row">
                  <div className="col-md-3 left-align">
                    <span className="fund-amount-cur">
                      &#8377;
                      <span className="fund-amount-label-value">
                        {' '}
                        {/* {total_grand_approved} */}
                        {total_grand_approved_amount != null
                          ? changeNumberFormat(Number(total_grand_approved_amount), 2, 1)
                          : 0}{' '}
                      </span>

                    </span>
                    <br />
                    <span className="fund-amount-label">
                      Total Grant Approved
                    </span>
                  </div>

                  <div className="col-md-3 left-align">
                    <span className="fund-amount-cur">
                      <span className="fund-amount-label-value">
                        {trancheData.length}{' '}
                      </span>
                    </span>
                    <br />
                    <span className="fund-amount-label">
                      Total No. of Tranches
                    </span>
                  </div>

                  <div className="col-md-4 right">
                    <br />
                    {/* <a
                      className="incubator-details-link"
                      onClick={() => setIsAddTrancheRowVisible(true)}
                    >
                      +Add More Tranches{' '}
                    </a> */}
                  </div>
                </div>

                <div className="row">
                  <div className="table-scroll ">
                    <table id="example">
                      <thead>
                        <tr>
                          <th className="text-left custom-td-left table-heading">
                            Tranche Request
                          </th>
                          <th className="text-left custom-td-left table-heading">
                            Amount Approved
                          </th>
                          <th className="text-left table-heading">
                            Amount Disbursed
                          </th>
                          <th className="text-left table-heading"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {isAddTrancheRowVisible == true ? (
                          <tr>
                            <td className="text-left ">
                              <span className="fund-amount-label">
                                {' '}
                                Tranche {trancheData.length + 1}
                              </span>
                            </td>
                            <td className="text-left custom-td-left">
                              <input
                                type="input"
                                placeholder="Enter"
                                name="amount_approved"
                                className="form-control input-height"
                                onChange={handleChange}
                                value={addMoreTrancheData.amount_approved}
                              />
                            </td>
                            <td className="text-left custom-td-left"></td>
                            <td>
                              <a
                                className="incubator-details-link"
                                href="#"
                                onClick={() => addMoreTranche()}
                              >
                                Save
                              </a>
                              |
                              <a
                                className="incubator-details-link"
                                href=""
                                onClick={() => cancel()}
                                data-dismiss="modal"
                              >
                                Cancel
                              </a>
                            </td>
                          </tr>
                        ) : (
                          ''
                        )}

                        {trancheData != undefined && trancheData.length > 0
                          ? trancheData.map((item: any) => (
                            <tr>
                              <td className="text-left ">
                                <span className="incubator-label-value">
                                  {item.tranche_number}
                                </span>
                                <br />
                                <span className="incubator-label">
                                  {' '}
                                  {item.tranche_requested_date != null ? formatDate(item.tranche_requested_date) : "-"}
                                </span>
                              </td>
                              <td className="text-left ">
                                <span className="incubator-label-value">
                                  {' '}
                                  {changeNumberFormat(Number(item.approved_amount), 2, 1)}
                                </span>
                                <br />
                                {item.approved_amount != null ? (
                                  <span className="incubator-label">
                                    {' '}
                                    {item.approved_amount_date != null ? formatDate(item.approved_amount_date) : "-"}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </td>
                              <td className="text-left ">
                                <span className="incubator-label-value">
                                  {' '}
                                  {changeNumberFormat(Number(item.fund_released), 2, 1)}
                                </span>
                                <br />
                                {item.fund_released != null ? (
                                  <span className="incubator-label">
                                    {' '}
                                    {item.date_of_release != null ? formatDate(item.date_of_release) : "-"}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </td>
                              <td className="text-left custom-td-left">
                                {/* <a
                                      className="incubator-details-link"
                                      href=""
                                      onClick={() =>
                                        handleRowChange(item.application_id)
                                      }
                                      data-dismiss="modal"
                                    >
                                      View
                                    </a> */}
                              </td>
                            </tr>
                          ))
                          : ''}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row pt-3 mb-3">
                <div className="col-md-12 centerText">
                  <button
                    className="tertiary  btn-yellow-primary"
                    data-dismiss="modal"
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* //View Bank Details */}

      <div
        className="modal fade"
        id="viewBankDetails"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none ml-18px">
              <h5 className="modal-title pl-2" id="exampleModalLongTitle">
                View Bank Details<span></span>
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body text-left">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <span className="incubator-label-value">Account Holder</span>
                  </div>
                </div>

                <div className="row mt-1-5">
                  <div className="col-md-6 left">
                    <span className="incubator-label">Account Name </span>
                  </div>
                  <div className="col-md-6 left">
                    <span className="incubator-label">Proof of PFMS Registration</span>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-6 left">
                    <span className="incubator-label-value">
                      {accountDetailsData.bank_account_holder_name == '' ||
                        accountDetailsData.bank_account_holder_name == null
                        ? '-'
                        : accountDetailsData.bank_account_holder_name}
                    </span>
                  </div>
                  <div className="col-md-6 left">
                    {accountDetailsData.pan_card != undefined && accountDetailsData.pan_card != null && accountDetailsData.pan_card_name != null ?
                      <div className="row">
                        <div className="col-md-3">
                          {getFileDetails(
                            accountDetailsData.pan_card.file,
                            'ext'
                          ) == 'pdf' ? (
                            <img src={pdf} alt="SISFS Logo" />
                          ) : getFileDetails(
                            accountDetailsData.pan_card.file,
                            'ext'
                          ) == 'doc' ? (
                            <img src={doc} alt="SISFS Logo" />
                          ) : getFileDetails(
                            accountDetailsData.pan_card.file,
                            'ext'
                          ) == 'xls' ? (
                            <img src={xls} alt="SISFS Logo" />
                          ) : (
                            <img src={Allround} alt="SISFS Logo" />
                          )}
                        </div>
                        <div className="col-md-9">
                          <span className="document-label-value">
                            {getFileDetails(
                              accountDetailsData.pan_card.file,
                              'name'
                            )}
                          </span>
                          <br />
                          <br />
                          <a
                            href={getFileDetails(accountDetailsData.pan_card.file, "file")}
                            target="_blank"
                            //download="pan_card"
                            className="incubator-details-link"
                          >
                            View
                          </a>
                          {/* /
                          <a
                            href="#"
                            //download="pan_card"
                            className="incubator-details-link"
                            onClick={() =>
                              downloadDocument(
                                accountDetailsData.pan_card.file,
                                accountDetailsData.pan_card_name
                              )
                            }
                          >
                            Download
                          </a> */}
                        </div>
                      </div>
                      : ""}
                  </div>
                </div>

                <div className="row mt-1-5">
                  <div className="col-md-6 left">
                    <span className="incubator-label">
                      Proof of TRA Account & ECS Mandate Form
                    </span>
                    <br />
                    {accountDetailsData.cancelled_cheque_location != undefined && accountDetailsData.cancelled_cheque_location != null ?
                      <div className="row">
                        <div className="col-md-3">
                          {getFileDetails(
                            accountDetailsData.cancelled_cheque.file,
                            'ext'
                          ) == 'pdf' ? (
                            <img src={pdf} alt="SISFS Logo" />
                          ) : getFileDetails(
                            accountDetailsData.cancelled_cheque.file,
                            'ext'
                          ) == 'doc' ? (
                            <img src={doc} alt="SISFS Logo" />
                          ) : getFileDetails(
                            accountDetailsData.cancelled_cheque.file,
                            'ext'
                          ) == 'xls' ? (
                            <img src={xls} alt="SISFS Logo" />
                          ) : (
                            <img src={Allround} alt="SISFS Logo" />
                          )}
                        </div>
                        <div className="col-md-9">
                          <span className="document-label-value">
                            {getFileDetails(
                              accountDetailsData.cancelled_cheque.file,
                              'name'
                            )}
                          </span>
                          <br />
                          <br />
                          <a
                            href={getFileDetails(accountDetailsData.cancelled_cheque.file, "file")}
                            target="_blank"
                            //download="proof_of_disbursement"
                            className="incubator-details-link"
                          >
                            View
                          </a>
                          {/* /
                          <a
                            href="#"
                            //download="proof_of_disbursement"
                            className="incubator-details-link"
                            onClick={() =>
                              downloadDocument(
                                accountDetailsData.cancelled_cheque.file,
                                accountDetailsData.cancelled_cheque_location
                              )
                            }
                          >
                            Download
                          </a> */}
                        </div>
                      </div>
                      : ""}
                  </div>
                  <div className="col-md-4"></div>
                </div>
                <div className="application-incubator-hr">

                  <hr />
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <span className="incubator-label-value">Account Details</span>
                  </div>
                </div>

                <div className="row mt-1-5">
                  <div className="col-md-6 left">
                    <span className="incubator-label">Bank Name</span>
                  </div>
                  <div className="col-md-4">
                    <span className="incubator-label">Account Number</span>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-6 left">
                    <span className="incubator-label-value">
                      {accountDetailsData.bank_name == '' ||
                        accountDetailsData.bank_name == null
                        ? '-'
                        : accountDetailsData.bank_name}
                    </span>
                  </div>
                  <div className="col-md-6">
                    <span className="incubator-label-value">
                      {accountDetailsData.account_number == '0' ||
                        accountDetailsData.account_number == null
                        ? '-'
                        : accountDetailsData.account_number}
                    </span>
                  </div>
                </div>

                <div className="row mt-1-5">
                  <div className="col-md-6 left">
                    <span className="incubator-label">Account Type</span>
                  </div>
                  <div className="col-md-6 left">
                    <span className="incubator-label">
                      Branch Name/Branch Code
                    </span>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-6 left">
                    <span className="incubator-label-value">
                      {accountDetailsData.account_type == 0 ||
                        accountDetailsData.account_type == null
                        ? '-'
                        : accountDetailsData.account_type}
                    </span>
                  </div>
                  <div className="col-md-6 left">
                    <span className="incubator-label-value">
                      {accountDetailsData.branch_name == '' ||
                        accountDetailsData.branch_name == null
                        ? '-'
                        : accountDetailsData.branch_name}
                    </span>
                  </div>
                </div>

                <div className="row mt-1-5">
                  <div className="col-md-6 left">
                    <span className="incubator-label">IFSC Code</span>
                  </div>
                  <div className="col-md-6 left">
                    <span className="incubator-label">MICR Code</span>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-6 left">
                    <span className="incubator-label-value">
                      {accountDetailsData.bank_ifsc_code == '' ||
                        accountDetailsData.bank_ifsc_code == null
                        ? '-'
                        : accountDetailsData.bank_ifsc_code}
                    </span>
                  </div>
                  <div className="col-md-6 left">
                    <span className="incubator-label-value">
                      {accountDetailsData.bank_micr_code == '' ||
                        accountDetailsData.bank_micr_code == null
                        ? '-'
                        : accountDetailsData.bank_micr_code}
                    </span>
                  </div>
                </div>

                <div className="row mt-1-5">
                  <div className="col-md-6 left">
                    <span className="incubator-label">Bank Address</span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 left">
                    <span className="incubator-label-value">
                      {accountDetailsData.branch_address == '' ||
                        accountDetailsData.branch_address == null
                        ? '-'
                        : accountDetailsData.branch_address}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row pt-3 mb-3">
                <div className="col-md-12 centerText">
                  <button
                    className="tertiary  btn-yellow-primary"
                    data-dismiss="modal"
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showEditSuccessModal && (
        <>
          <div className="modal-backdrop fade show"></div>

          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionSuccess"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <i className="fa fa-check-circle-o"></i>
                  <h3 className="modal-message">Thank You !</h3>
                  <div className="modal-text">
                    Tranche Details Updated Successfully !!
                  </div>
                  <div className="modal-action">
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={dismissModal}
                    >
                      Ok !!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showEditFailureModal && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionError"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  {/* <i className="fa fa-times-circle-o"></i> */}
                  <h3 className="modal-message message-orange">
                    Oops! Something is Wrong
                  </h3>
                  <br />
                  <h3 className="modal-message message-orange">
                    Please Try Again !!!
                  </h3>
                  <div className="modal-action">
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={dismissModal}
                    >
                      OK !
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
export default IncubatorFundDetailsComponent;
