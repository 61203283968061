import '../incubator/incubator.css';
import Plus from '../../../images/Plus.svg';
import Minus from '../../../images/Minus.svg';
import { OthersModel, ReapplyApplicationGeneralDetails, ReapplyOthersModel } from './ApplicationGeneralDetails';
import { useEffect, useState } from 'react';
import pdf from '../../../images/PDF.svg';
import xls from '../../../images/xls.svg';
import doc from '../../../images/doc.svg';
import xlsx from '../../../images/xls.svg';
import Allround from '../../../images/Allround.svg';
import { BASE_URL, getApplicationDetails2_3_NODE, NODE_URL } from "../../../constants/api";
import { post } from '../../../effects/api/api.common';
interface IDocumentProps {
  otherDetailModel: ReapplyApplicationGeneralDetails;
}

function DocumentReapplyComponent(props: IDocumentProps) {
  const { otherDetailModel } = props;
  //console.log(otherDetailModel, 'otherDetailModel');
  // const [otherDetail, setOtherDetail] = useState<ReapplyApplicationGeneralDetails>(
  //   new ReapplyApplicationGeneralDetails("", "", "", "", "","","","","","","")
  // );

  useEffect(() => {
    window.scroll(0, 0);
    // setOtherDetail(otherDetailModel);
    // console.log(otherDetailModel);
    getAPIPartOneAndTwo();
  });

  const [ucfilesArray, setucfilesArray] = useState<any>('');
  const [AuditedfilesArray, setAuditedfilesArray] = useState<any>('');

  const getAPIPartOneAndTwo = () => {
    console.log(otherDetailModel.incubator_application_no);
    if (otherDetailModel.incubator_application_no !== '') {
      const payload = {
        incubator_application_no:
          otherDetailModel.incubator_application_no == ''
            ? 0
            : otherDetailModel.incubator_application_no,
        // "incubator_application_no": "RESFI10"
      };
      // console.log(payload, 'payload');
      post(getApplicationDetails2_3_NODE, payload).then((response: any) => {
        // console.log(response.data.meetingId[0], 'sri@@@');
        if (!response || !response.data) return;
        if (response.data.meetingId.length > 0) {
          // console.log(response.data.meetingId.length,"Meetinglength")
          
          if (
           ( response.data.meetingId[0].Utilization_Certificate_Upload !==
            null) &&        
            response.data.meetingId[0].Audited_statement_expenditure_Upload !==
            null
          ) {
            // setpostapitwo(response.data.meetingId[0]);
            setucfilesArray(
              response.data.meetingId[0].Utilization_Certificate_Upload,
            );
            setAuditedfilesArray(
              response.data.meetingId[0].Audited_statement_expenditure_Upload,
            );
          }else{
            // console.log("length null")
          }
        }
      });
    }
    // console.log(ucfilesArray);
  };

  const downloadDocument = (file: any, file_name: any) => {
    fetch(file).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = file_name;
        a.click();
      });
      //window.location.href = response.url;
    });
  };




  const getFileDetails = (file: any, key: string) => {
    let file_name = '';
    file_name =
      file != undefined && file != null && file.length > 1
        ? file.substr(file.lastIndexOf('/') + 1, file.length)
        : '';

        const file_type =  file != undefined && file != null && file.length > 1
        ? file.substr(
          file.lastIndexOf('.') + 1,
                            file.length
                          ):'';

    if (key == 'name') return file_name;
    if (key == 'file') return file != undefined ? file.toString() : '';
    if (key == 'type') return file != undefined ? file_type.toString() : '';
  };

  const [popUpName,setPopUpName]=useState("");
  const setMeetingModal=(fieldName:string)=>{
  setPopUpName(fieldName);

  }


  const [activeIndexDoc, setActiveIndexDoc] = useState('');
  const changeActiveDoc = (id: string) => {
    setActiveIndexDoc(id);
  };
  const changeActiveBackDoc = () => {
    setActiveIndexDoc('');
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 left-align">
          <span className="eac-heading-title">Reports and Documents</span>
        </div>
      </div>

      <div className="incubator-hr">
        <hr />
      </div>

      <div className="row">
        <div
          id="q-2"
          className={
            activeIndexDoc == '2'
              ? 'tranche-heading col-sm-11 left-align'
              : 'reports-heading col-sm-11 left-align'
          }
        >
          General Incubator Progress
        </div>
        <div className="col-sm-1">
          {activeIndexDoc == '2' ? (
            <div
              onClick={changeActiveBackDoc}
              data-toggle="collapse"
              data-target="#pick-deck-expand"
              role="button"
              aria-expanded="false"
              aria-controls="pick-deck-expand"
              className="hide-icon pull-right hide-icon-details"
              id="show-2"
            >
              <img src={Minus} className="img-fluid" alt="SISFS Logo" />
            </div>
          ) : (
            <div
              id="hide-2"
              onClick={() => changeActiveDoc('2')}
              data-toggle="collapse"
              data-target="#pick-deck-expand"
              role="button"
              aria-expanded="false"
              aria-controls="pick-deck-expand"
              className="expand-icon pull-right expand-icon-details"
            >
              <img src={Plus} className="img-fluid" alt="SISFS Logo" />
            </div>
          )}
        </div>
      </div>
      {activeIndexDoc == '2' ? (
      <div className="collapse " id="pick-deck-expand">
        <div className="row">
          <div className="col-md-8 left-align">
            {otherDetailModel.pitchdeck_upload_fpath.length != 0 ? (
              ''
            ) : (
              <label className="incubator-label-value">-</label>
            )}

            <br />

            {otherDetailModel.pitchdeck_upload_fpath.split(',').map((file:any,index:any) => (
              index<2?
              <div className="row">
                <div className="col-md-3">
                  {getFileDetails(
                              file,
                             'type'
                                          ) == 'pdf' ? (
                    <img src={pdf} alt="SISFS Logo" />
                  ) : getFileDetails(
                    file,
                   'type'
                                )== 'doc' ? (
                    <img src={doc} alt="SISFS Logo" />
                  ) : getFileDetails(
                    file,
                   'type'
                                ) == 'xls' ? (
                    <img src={xls} alt="SISFS Logo" />
                  ) : (
                    <img src={Allround} alt="SISFS Logo" />
                  )}
                </div>
                <div className="col-md-9">
                  <span className="document-label-value-wrap">
                  {getFileDetails(
                              file,
                             'name'
                                          )}
                  </span>
                  <br />
                  <a
                    href={`${NODE_URL}`+file.substr(1)}
                    target="_blank"
                  //download="PitchDeck"
                    className="incubator-details-link"
                  >
                    View
                  </a>
                  {/* /
                  <a
                    href="#"
                  //download={file.file}
                    className="incubator-details-link"
                    onClick={() => downloadDocument(file, file)}
                  >
                    Download
                  </a> */}
                </div>
              </div>
              :""
            ))}
            {otherDetailModel.pitchdeck_upload_fpath.split(',').length >2 ?
             <a
             className="incubator-details-link"
             data-toggle="modal"
             data-target="#showMoreDocument"
             onClick={() => setMeetingModal('pitchdeck_upload_fpath')}
           >
             More...
           </a>
           :""}
<br/>
<br/>
{otherDetailModel.Performance_Upload_fpath.split(',').map((file:any,index:any) => (
              index < 2?
              <div className="row">
                <div className="col-md-3">
                  {getFileDetails(
                              file,
                             'type'
                                          )== 'pdf' ? (
                    <img src={pdf} alt="SISFS Logo" />
                  ) : getFileDetails(
                    file,
                   'type'
                                )== 'doc' ? (
                    <img src={doc} alt="SISFS Logo" />
                  ) : getFileDetails(
                    file,
                   'type'
                                ) == 'xls' ? (
                    <img src={xls} alt="SISFS Logo" />
                  ) : (
                    <img src={Allround} alt="SISFS Logo" />
                  )}
                </div>
                <div className="col-md-9">
                  <span className="document-label-value-wrap">
                  {getFileDetails(
                              file,
                             'name'
                                          )}
                  </span>
                  <br />
                  <a
                    href={`${NODE_URL}`+file.substr(1)}
                    target="_blank"
                  //download="PitchDeck"
                    className="incubator-details-link"
                  >
                    View
                  </a>
                  {/* /
                  <a
                    href="#"
                  //download={file.file}
                    className="incubator-details-link"
                    onClick={() => downloadDocument(file, file)}
                  >
                    Download
                  </a> */}
                </div>
              </div>
              :""
            ))}
            {otherDetailModel.Performance_Upload_fpath.split(',').length >2 ?
             <a
             className="incubator-details-link"
             data-toggle="modal"
             data-target="#showMoreDocument"
             onClick={() => setMeetingModal('Performance_Upload_fpath')}
           >
             More...
           </a>
           :""}

<br/>
<br/>
{otherDetailModel.Other_doc_upload_fpath.split(',').map((file:any,index:any) => (
              index < 2?
              <div className="row">
                <div className="col-md-3">
                  {getFileDetails(
                              file,
                             'type'
                                          )== 'pdf' ? (
                    <img src={pdf} alt="SISFS Logo" />
                  ) : getFileDetails(
                    file,
                   'type'
                                )== 'doc' ? (
                    <img src={doc} alt="SISFS Logo" />
                  ) : getFileDetails(
                    file,
                   'type'
                                ) == 'xls' ? (
                    <img src={xls} alt="SISFS Logo" />
                  ) : (
                    <img src={Allround} alt="SISFS Logo" />
                  )}
                </div>
                <div className="col-md-9">
                  <span className="document-label-value-wrap">
                  {getFileDetails(
                              file,
                             'name'
                                          )}
                  </span>
                  <br />
                  <a
                    href={`${NODE_URL}`+file.substr(1)}
                    target="_blank"
                  //download="PitchDeck"
                    className="incubator-details-link"
                  >
                    View
                  </a>
                  {/* /
                  <a
                    href="#"
                  //download={file.file}
                    className="incubator-details-link"
                    onClick={() => downloadDocument(file, file)}
                  >
                    Download
                  </a> */}
                </div>
              </div>
              :""
            ))}
            {otherDetailModel.Other_doc_upload_fpath.split(',').length >2 ?
             <a
             className="incubator-details-link"
             data-toggle="modal"
             data-target="#showMoreDocument"
             onClick={() => setMeetingModal('Other_doc_upload_fpath')}
           >
             More...
           </a>
           :""}
           
          </div>
        </div>
      </div>
      ):""}

      

      <div className="incubator-hr">
        <hr />
      </div>

      <div className="row">
        <div
          id="q-4"
          className={
            activeIndexDoc == '4'
              ? 'tranche-heading col-sm-11 left-align'
              : 'reports-heading col-sm-11 left-align'
          }
        >
          Incubator Scheme Progress - Part 1
        </div>
        <div className="col-sm-1">
          {activeIndexDoc == '4' ? (
            <div
              onClick={changeActiveBackDoc}
              data-toggle="collapse"
              data-target="#annual-report-expand"
              role="button"
              aria-expanded="false"
              aria-controls="annual-report-expand"
              className="hide-icon pull-right hide-icon-details"
              id="show-4"
            >
              <img src={Minus} className="img-fluid" alt="SISFS Logo" />
            </div>
          ) : (
            <div
              onClick={() => changeActiveDoc('4')}
              id="hide-4"
              data-toggle="collapse"
              data-target="#annual-report-expand"
              role="button"
              aria-expanded="false"
              aria-controls="annual-report-expand"
              className="expand-icon pull-right expand-icon-details"
            >
              <img src={Plus} className="img-fluid" alt="SISFS Logo" />
            </div>
          )}
        </div>
      </div>
      {activeIndexDoc == '4' ? (
      <div className="collapse" id="annual-report-expand">
        <div className="row">
          <div className="col-md-8 left-align">
            {ucfilesArray.split(',').length != 0 ? (
              ''
            ) : (
              <label className="document-label-value-wrap">-</label>
            )}
            <br />
            {ucfilesArray.split(',').map((file:any, index:any) => (
              index <2 ? 
              <div className="row">
                <div className="col-md-3">
                  {getFileDetails(
                              file,
                             'type'
                                          )== 'pdf' ? (
                    <img src={pdf} alt="SISFS Logo" />
                  ) : getFileDetails(
                    file,
                   'type'
                                ) == 'doc' ? (
                    <img src={doc} alt="SISFS Logo" />
                  ) : getFileDetails(
                    file,
                   'type'
                                )== 'xls' ? (
                    <img src={xls} alt="SISFS Logo" />
                  ) : (
                    <img src={Allround} alt="SISFS Logo" />
                  )}
                </div>
                <div className="col-md-9">
                  <span className="document-label-value-wrap">
                  {getFileDetails(
                              file,
                             'name'
                                          )}
                  </span>
                  <br />
                  <a
                    href={`${NODE_URL}`+file.substr(1)}
                    target="_blank"
                    className="incubator-details-link"
                  >
                    View
                  </a>
                  {/* /
                  <a
                    href="#"
                    className="incubator-details-link"
                    onClick={() => downloadDocument(file, file)}
                  >
                    Download
                  </a> */}
                </div>
              </div>
              :""
            ))}

{ucfilesArray.split(',').length > 2 ?
             <a
             className="incubator-details-link"
             data-toggle="modal"
             data-target="#showMoreDocument"
             onClick={() => setMeetingModal('Utilization_Certificate_Upload')}
           >
             More...
           </a>
           :""}

<br/>
<br/>
{AuditedfilesArray.split(',').map((file:any, index:any) => (
              index <2 ? 
              <div className="row">
                <div className="col-md-3">
                  {getFileDetails(
                              file,
                             'type'
                                          )== 'pdf' ? (
                    <img src={pdf} alt="SISFS Logo" />
                  ) : getFileDetails(
                    file,
                   'type'
                                ) == 'doc' ? (
                    <img src={doc} alt="SISFS Logo" />
                  ) : getFileDetails(
                    file,
                   'type'
                                )== 'xls' ? (
                    <img src={xls} alt="SISFS Logo" />
                  ) : (
                    <img src={Allround} alt="SISFS Logo" />
                  )}
                </div>
                <div className="col-md-9">
                  <span className="document-label-value-wrap">
                  {getFileDetails(
                              file,
                             'name'
                                          )}
                  </span>
                  <br />
                  <a
                    href={`${NODE_URL}`+file.substr(1)}
                    target="_blank"
                    className="incubator-details-link"
                  >
                    View
                  </a>
                  {/* /
                  <a
                    href="#"
                    className="incubator-details-link"
                    onClick={() => downloadDocument(file, file)}
                  >
                    Download
                  </a> */}
                </div>
              </div>
              :""
            ))}

            <br/>

{AuditedfilesArray.split(',').length >2 ?
             <a
             className="incubator-details-link"
             data-toggle="modal"
             data-target="#showMoreDocument"
             onClick={() => setMeetingModal('Audited_statement_expenditure_Upload')}
           >
             More...
           </a>
           :""}

          </div>
        </div>
      </div>
      ):""}

      {/* <div className="incubator-hr">
        <hr />
      </div> */}

      {/* <div className="row">
        <div
          id="q-5"
          className={
            activeIndexDoc == '5'
              ? 'tranche-heading col-sm-11 left-align'
              : 'reports-heading col-sm-11 left-align'
          }
        >
          Incubator Scheme Progress - Part 2
        </div>
        <div className="col-sm-1">
          {activeIndexDoc == '5' ? (
            <div
              onClick={changeActiveBackDoc}
              data-toggle="collapse"
              data-target="#relevant-expand"
              role="button"
              aria-expanded="false"
              aria-controls="relevant-expand"
              className="hide-icon pull-right hide-icon-details"
              id="show-5"
            >
              <img src={Minus} className="img-fluid" alt="SISFS Logo" />
            </div>
          ) : (
            <div
              onClick={() => changeActiveDoc('5')}
              id="hide-5"
              data-toggle="collapse"
              data-target="#relevant-expand"
              role="button"
              aria-expanded="false"
              aria-controls="relevant-expand"
              className="expand-icon pull-right expand-icon-details"
            >
              <img src={Plus} className="img-fluid" alt="SISFS Logo" />
            </div>
          )}
        </div>
      </div>
      {activeIndexDoc == '5' ? (
      <div className="collapse" id="relevant-expand">
        <div className="row ">
          {otherDetailModel.Current_Incubator_Team_Upload.length != 0 ? (
            ''
          ) : (
            <div className="col-md-3 left-align">
              <label className="incubator-label-value"> -</label>
            </div>
          )}

          <br />
          {otherDetailModel.Current_Incubator_Team_Upload[0].split(',').map((file:any,index:any) => (
            index<2?
            <div className="col-md-8 left-align">
              <div className="row">
                <div className="col-md-3">
                  {getFileDetails(
                              file,
                             'type'
                                          )== 'pdf' ? (
                    <img src={pdf} alt="SISFS Logo" />
                  ) : getFileDetails(
                    file,
                   'type'
                                )== 'doc' ? (
                    <img src={doc} alt="SISFS Logo" />
                  ) : getFileDetails(
                    file,
                   'type'
                                ) == 'xls' ? (
                    <img src={xls} alt="SISFS Logo" />
                  ) : (
                    <img src={Allround} alt="SISFS Logo" />
                  )}
                </div>
                <div className="col-md-9">
                  <span className="document-label-value-wrap">
                {getFileDetails(
                            file,
                            'name'
                                        )}
                  </span>
                  <br />
                  <a
                    href={`${NODE_URL}`+file.substr(1)}
                    target="_blank"
                    className="incubator-details-link"
                  >
                    View
                  </a>
                  /
                  <a
                    href="#"
                    className="incubator-details-link"
                    onClick={() => downloadDocument(file, file)}
                  >
                    Download
                  </a>
                </div>
              </div>
            </div>
            :""
          ))}

{otherDetailModel.Current_Incubator_Team_Upload[0].split(',').length >2 ?
             <a
             className="incubator-details-link"
             data-toggle="modal"
             data-target="#showMoreDocument"
             onClick={() => setMeetingModal('Current_Incubator_Team_Upload')}
           >
             More...
           </a>
           :""}

        </div>
      </div>
      ):""} */}

      {/* <div className="incubator-hr">
        <hr />
      </div> */}
            {/* Show More  */}
      <div
          className="modal fade"
          id="showMoreDocument"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header modal-header-border-none ml-18px">
                <h5 className="modal-title " id="exampleModalLongTitle">
                 {/* {popUpName}  */}
                 More Files<span className="eac-name-pop-up"></span>
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="">
           


{popUpName =="pitchdeck_upload_fpath" ?
                      
                      otherDetailModel.pitchdeck_upload_fpath != undefined ? (
                        otherDetailModel.pitchdeck_upload_fpath.split(',').map(
                          (file:any,index:any) => (
                           
                            <div className="row mt-05">
                              <div className="col-md-3">
                                {getFileDetails(
                              file,
                             'type'
                                          )== 'pdf' ? (
                                  <img src={pdf} alt="SISFS Logo" />
                                ) : getFileDetails(
                                  file,
                                 'type'
                                              )== 'doc' ? (
                                  <img src={doc} alt="SISFS Logo" />
                                ) : getFileDetails(
                                  file,
                                 'type'
                                              ) == 'xls' ? (
                                  <img src={xls} alt="SISFS Logo" />
                                ) : (
                                  <img src={Allround} alt="SISFS Logo" />
                                )}
                              </div>
                              <div className="col-md-9 left-align">
                                <span className="document-label-value-wrap">
                                  {getFileDetails(
                              file,
                             'name'
                                          )}
                                </span>
                                <br />
                                <a
                                  href={`${NODE_URL}`+file.substr(1)}
                                  target="_blank"
                                  className="incubator-details-link"
                                >
                                  View
                                </a>
                                {/* /
                                <a
                                  href="#"
                                  className="incubator-details-link"
                                  onClick={() =>
                                    downloadDocument(file, file)
                                  }
                                >
                                  Download
                                </a> */}
                              </div>
                            </div>
                           
                          )
                        )
                      ) : (
                        <span className="incubator-label-value">-</span>
                      )
                                  
           :
           ""}


{popUpName =="Performance_Upload_fpath" ? 
otherDetailModel.Performance_Upload_fpath != undefined
                  ? otherDetailModel.Performance_Upload_fpath.split(',').map(
                      (file:any,index:any) => (
                        
                        <div className="row">
                          <div className="col-md-2">
                            {getFileDetails(
                              file,
                             'type'
                                          )== 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(
                              file,
                             'type'
                                          ) == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(
                              file,
                             'type'
                                          ) == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-10 left-align">
                            <span className="document-label-value-wrap">
                              {getFileDetails(
                              file,
                             'name'
                                          )}
                            </span>
                            <br />
                            <a
                              href={`${NODE_URL}`+file.substr(1)}
                              target="_blank"
                              className="incubator-details-link"
                            >
                              View
                            </a>
                            {/* /
                            <a
                              href="#"
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file, file)
                              }
                            >
                              Download
                            </a> */}
                          </div>
                        </div>
                       
                      )
                    )
                  : ''
                :""}

{popUpName =="Other_doc_upload_fpath" ? 
otherDetailModel.Other_doc_upload_fpath != undefined
                  ? otherDetailModel.Other_doc_upload_fpath.split(',').map(
                      (file:any,index:any) => (
                        
                        <div className="row">
                          <div className="col-md-2">
                            {getFileDetails(
                              file,
                             'type'
                                          )== 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(
                              file,
                             'type'
                                          ) == 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(
                              file,
                             'type'
                                          ) == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-10 left-align">
                            <span className="document-label-value-wrap">
                              {getFileDetails(
                              file,
                             'name'
                                          )}
                            </span>
                            <br />
                            <a
                              href={`${NODE_URL}`+file.substr(1)}
                              target="_blank"
                              className="incubator-details-link"
                            >
                              View
                            </a>
                            {/* /
                            <a
                              href="#"
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file, file)
                              }
                            >
                              Download
                            </a> */}
                          </div>
                        </div>
                       
                      )
                    )
                  : ''
                :""}
                </div>
{popUpName =="Utilization_Certificate_Upload" ? ucfilesArray != undefined
                  ? ucfilesArray.split(',').map(
                      (file:any) => (
                        <div className="row">
                          <div className="col-md-2">
                            {getFileDetails(
                              file,
                             'type'
                                          ) == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(
                              file,
                             'type'
                                          )== 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(
                              file,
                             'type'
                                          ) == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-10 left-align">
                            <span className="document-label-value-wrap">
                              {getFileDetails(
                              file,
                             'name'
                                          )}
                            </span>
                            <br />
                            <a
                              href={`${NODE_URL}`+file.substr(1)}
                              target="_blank"
                              className="incubator-details-link"
                            >
                              View
                            </a>
                            {/* /
                            <a
                              href="#"
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file, file)
                              }
                            >
                              Download
                            </a> */}
                          </div>
                        </div>
                      )
                    )
                  : ''
                :""}
 {popUpName =="Audited_statement_expenditure_Upload" ? AuditedfilesArray != undefined
                  ? AuditedfilesArray.split(',').map(
                      (file:any) => (
                        <div className="row">
                          <div className="col-md-2">
                            {getFileDetails(
                              file,
                             'type'
                                          ) == 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(
                              file,
                             'type'
                                          )== 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(
                              file,
                             'type'
                                          ) == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-10 left-align">
                            <span className="document-label-value-wrap">
                              {getFileDetails(
                              file,
                             'name'
                                          )}
                            </span>
                            <br />
                            <a
                              href={`${NODE_URL}`+file.substr(1)}
                              target="_blank"
                              className="incubator-details-link"
                            >
                              View
                            </a>
                            {/* /
                            <a
                              href="#"
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file, file)
                              }
                            >
                              Download
                            </a> */}
                          </div>
                        </div>
                      )
                    )
                  : ''
                :""}


{/* {popUpName =="Current_Incubator_Team_Upload" ? otherDetailModel.Current_Incubator_Team_Upload[0] != undefined
                  ? otherDetailModel.Current_Incubator_Team_Upload[0].split(',').map(
                      (file:any) => (
                        <div className="row">
                          <div className="col-md-2">
                            {getFileDetails(
                              file,
                             'type'
                                          )== 'pdf' ? (
                              <img src={pdf} alt="SISFS Logo" />
                            ) : getFileDetails(
                              file,
                             'type'
                                          )== 'doc' ? (
                              <img src={doc} alt="SISFS Logo" />
                            ) : getFileDetails(
                              file,
                             'type'
                                          ) == 'xls' ? (
                              <img src={xls} alt="SISFS Logo" />
                            ) : (
                              <img src={Allround} alt="SISFS Logo" />
                            )}
                          </div>
                          <div className="col-md-10 left-align">
                            <span className="document-label-value-wrap">
                              {getFileDetails(
                              file,
                             'name'
                                          )}
                            </span>
                            <br />
                            <a
                              href={`${NODE_URL}`+file.substr(1)}
                              target="_blank"
                              className="incubator-details-link"
                            >
                              View
                            </a>
                            /{' '}
                            <a
                              href="#"
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file, file)
                              }
                            >
                              Download
                            </a>
                          </div>
                        </div>
                      )
                    )
                  : ''
                :""} */}

                <div className="row">
                  <div className="col-md-12 centerText">
                    <div className="btn-group mt-1-5 ">
                      <button
                        className="tertiary  btn-yellow-primary"
                        data-dismiss="modal"
                      >
                        OK
                      </button>

                   
                    </div>
                  </div>
                </div>

               
              </div>
            </div>
          </div>
      </div>

    </div>
  );
}
export default DocumentReapplyComponent;
