/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { SELECTED_STARTUP_DOWNLOAD_EXCEL,CLOSED_STARTUP_DOWNLOAD_EXCEL, STARTUP_CHECK_BOX_DATA, STARTUP_DOWNLOAD_EXCEL,File_node_url } from "../../../../constants/api"
import { post } from "../../../../effects/api/api.common";
import { downloadDocument } from "../../../../utils/CommonMethods";
import React, { FC } from "react";
import * as XLSX from 'xlsx';
import { exportToExcel } from "../../../../constants/ExcelServices";
let dataHolder: Array<string> = [];
let tracheheadings: Array<string> = [];
const STARTUP_DETAILS = 'Startup Details';
const APPLICATION_DETAILS = "Application Details";
const TRANCHE_DETAILS_1 = "Tranche Details"; 

const updateDataHolder = (isClicked: boolean, item: string) => {
  if (isClicked) {
    if (!dataHolder.find((i) => item === i)) {
      // console.log("INTO IF", item)
      // tracheheadings = []
      if (item == 'tranche_status' ||item == 'tranche_amount_approved' || item == 'tranche_proof_disbursement' || item == 'tranche_fund_released' || item == 'tranche_date_released') {
        // console.log("Item check console")
        tracheheadings.push(item);
        if (!dataHolder.includes("tranche_details")) {
          // console.log("####Inculdes Console")
          dataHolder.push("tranche_details");
        }
      } else {
        // console.log("@@@@@@@@@@")
        dataHolder.push(item);
        tracheheadings = []
      }
    } 
  } else {
    // console.log("INTO ELSE", item)
    tracheheadings = tracheheadings.filter((i) => i !== item);
    if(tracheheadings.length==0){
      dataHolder.splice(-1);
    }
    // tracheheadings = []
  }
};

interface interfaceProps {
  startup: Array<any>;
  title: any;
  item: any;
  StartUpSelected: boolean;
  setStartUpDetailFirstSelected:any;
}

const StartUpDetailItem: FC<interfaceProps> = ({
  startup,
  title,
  item,
  StartUpSelected,
  setStartUpDetailFirstSelected
}: interfaceProps) => {

  const [itemSelected, setItemSelecetd] = useState(false);

  useEffect(() => {
    /* if (itemSelected && !StartUpSelected) {
      return;
    } */
    setItemSelecetd(StartUpSelected);
    updateDataHolder(StartUpSelected, item);
    return () => { };
  }, [StartUpSelected]);

  const onClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateDataHolder(event.target.checked, item);
    setStartUpDetailFirstSelected(false)
    setItemSelecetd(event.target.checked);
  }

  // Table Data
  return (
    <div className="row hover  mb-2">
      <div className="col-10"> {startup[title][item]}</div>
      <div className="col-2">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={itemSelected}
            onChange={onClick}
            id="flexCheckDefault"
          />
        </div>
      </div>
    </div>
  );
}

interface interfaceBase {
  startup: Array<any>;
  title: any;
  items: any;
}

const StartUpDetail: FC<interfaceBase> = ({
  startup,
  title,
  items,
}: interfaceBase) => {

  const [startUpDetailSelected, setStartUpDetailSelected] = useState(false);
  const [startUpDetailFirstSelected, setStartUpDetailFirstSelected] = useState(false);

  const onClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStartUpDetailSelected(event.target.checked);
    setStartUpDetailFirstSelected(event.target.checked)
  };

  
  // Table Main Header
  return (
    <div>
      <div className="row">
        <div className="col-10">
          <p className="h4">{title}</p>
        </div>
        <div className="col-2">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={startUpDetailFirstSelected}
              id="flexCheckDefault"
              onChange={onClick}
            />
          </div>
        </div>
      </div>
      {
        items &&
        items.map((i: any, key: number) => {
          return (
            <div key={key}>
              <StartUpDetailItem
                title={title}
                startup={startup}
                item={i}
                StartUpSelected={startUpDetailSelected}
                setStartUpDetailFirstSelected={setStartUpDetailFirstSelected}
              ></StartUpDetailItem>
            </div>
          );
        })
      }
    </div >
  );
}



interface Props {
  countRef: React.MutableRefObject<number>;
}
export const StartUpReportComponent = ({ countRef }: Props) => {     

  console.log(countRef)

  const [startUp, setStartUp] = useState<any>({});
  const [loader, setLoader] = useState(false);


  const downloadExcel = async () => {
    setLoader(true);
    console.log(dataHolder);
    // return;
    const {
      data: { data, result },
    } = await post(STARTUP_DOWNLOAD_EXCEL, {"main_headings":dataHolder,"trache_headings":tracheheadings});
    setLoader(false);
    if (result === "success") {
      // downloadDocument(`${File_node_url}`+data, "startup_list");
      // console.log(data);
      tracheheadings = [];
      dataHolder = [];
      var ExcelName = "Startup_Applications.xlsx"
      exportToExcel(data,ExcelName);
    }
    else{
      tracheheadings = [];
      dataHolder = [];
      //console.log(`Column not found: 1054 Unknown column 'fund_raised' in 'field list' (SQL: select (CASE WHEN stage_id = \"1\" THEN \"Proof of concept\" WHEN stage_id = \"2\" THEN \"Progress of prototype development\" WHEN stage_id = \"3\" THEN \"Progress of product development\" WHEN stage_id = \"4\" THEN \"Progress of field trials\" WHEN stage_id = \"5\" THEN \"Progress of market launch\" END) AS stage_of_startup_reported_first,progress_in_text as progress_of_proof_of_concept_first, fund_raised as quantum_funding_raised_first, jobs_created as jobs_created_by_startup_first, turnover as turnover_of_startup_first from 'startup_progress_overview' where 'startup_application_id' = 1 order by 'id' asc)`);  
    }
  };

  const downloadExcel1 = async () => {
    setLoader(true);
    const {
      data: { data, result },
    } = await post(SELECTED_STARTUP_DOWNLOAD_EXCEL, {"main_headings":dataHolder,"trache_headings":tracheheadings});
    setLoader(false);
    if (result === "success") {
      tracheheadings = [];
      dataHolder = [];
      // downloadDocument(`${File_node_url}`+data, "startup_list");
      var ExcelName = "Startup_Applications.xlsx"
      exportToExcel(data,ExcelName);
      window.location.reload();
    }
    else{
      tracheheadings = [];
      dataHolder = [];
      //console.log(`Column not found: 1054 Unknown column 'fund_raised' in 'field list' (SQL: select (CASE WHEN stage_id = \"1\" THEN \"Proof of concept\" WHEN stage_id = \"2\" THEN \"Progress of prototype development\" WHEN stage_id = \"3\" THEN \"Progress of product development\" WHEN stage_id = \"4\" THEN \"Progress of field trials\" WHEN stage_id = \"5\" THEN \"Progress of market launch\" END) AS stage_of_startup_reported_first,progress_in_text as progress_of_proof_of_concept_first, fund_raised as quantum_funding_raised_first, jobs_created as jobs_created_by_startup_first, turnover as turnover_of_startup_first from 'startup_progress_overview' where 'startup_application_id' = 1 order by 'id' asc)`);  
    }
  };
  const downloadExcel2 = async () => {
    setLoader(true);
    const {
      data: { data, result },
    } = await post(CLOSED_STARTUP_DOWNLOAD_EXCEL, {"main_headings":dataHolder,"trache_headings":tracheheadings});
    setLoader(false);
    if (result === "success") {
      tracheheadings = [];
      dataHolder = [];
      // downloadDocument(`${File_node_url}`+data, "startup_list");
      var ExcelName = "Startup_Applications.xlsx"
      exportToExcel(data,ExcelName);
      window.location.reload();
    }
    else{
      tracheheadings = [];
      dataHolder = [];
      //console.log(`Column not found: 1054 Unknown column 'fund_raised' in 'field list' (SQL: select (CASE WHEN stage_id = \"1\" THEN \"Proof of concept\" WHEN stage_id = \"2\" THEN \"Progress of prototype development\" WHEN stage_id = \"3\" THEN \"Progress of product development\" WHEN stage_id = \"4\" THEN \"Progress of field trials\" WHEN stage_id = \"5\" THEN \"Progress of market launch\" END) AS stage_of_startup_reported_first,progress_in_text as progress_of_proof_of_concept_first, fund_raised as quantum_funding_raised_first, jobs_created as jobs_created_by_startup_first, turnover as turnover_of_startup_first from 'startup_progress_overview' where 'startup_application_id' = 1 order by 'id' asc)`);  
    }
  };

  // const exportToExcel = (jsonData:any) => {
  //   const worksheet = XLSX.utils.json_to_sheet(jsonData);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  //   const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  //   const excelBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
  //   const fileName = 'data.xlsx';
  
  //   // if (navigator.msSaveBlob) {
  //   //   // For IE11 and Edge
  //   //   navigator.msSaveBlob(excelBlob, fileName);
  //   // } 
  //   // else {
  //     // For other browsers
  //     const excelUrl = URL.createObjectURL(excelBlob);
  //     const a = document.createElement('a');
  //     a.href = excelUrl;
  //     a.download = fileName;
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //     URL.revokeObjectURL(excelUrl);
  //   // }
  // };

  const fetchData = async () => {
    const {
      data: { data, result },
    } = await post(STARTUP_CHECK_BOX_DATA, {});
    setStartUp(data);
  };

  useEffect(() => {
    fetchData();
  }, []);
  
  const getData = (title: string) => {
    return startUp[title] ? Object.keys(startUp[title]) : [];
  };

  return (
    <>
    {loader &&
        <div className="spinner-border custom-spinner" role="status">
          <span className="sr-only">Loading...</span>
        </div>}
      <fieldset disabled={loader}>
      <div>
        <div className="data1 mx-5 my-5">
          <StartUpDetail
            title={STARTUP_DETAILS}
            startup={startUp}
            items={getData(STARTUP_DETAILS)}
          ></StartUpDetail>
        </div>
        <div className="data1 mx-5 my-5">
          <StartUpDetail
            title={APPLICATION_DETAILS}
            startup={startUp}
            items={getData(APPLICATION_DETAILS)}
          ></StartUpDetail>
        </div>
        <div className="data1 mx-5 my-5">
          <StartUpDetail
            title={TRANCHE_DETAILS_1}
            startup={startUp}
            items={getData(TRANCHE_DETAILS_1)}
          ></StartUpDetail>
        </div> 

        {/* {console.log(countRef.current)} */}
        {(countRef.current ===0 || countRef.current === 2)? 
        <>
          <div className="data1 mx-5 buttonCss">
          <Button
            variant="primary"
            className="btn-yellow-primary tertiary text-uppercase"
            onClick={() => {
              //console.log(dataHolder);
              downloadExcel1();
            }}
          >
            Generate Record
          </Button>          
        </div>
        </> 
        
        : 
            countRef.current === 3 ?
            <>
          <div className="data1 mx-5 buttonCss">
          <Button
            variant="primary"
            className="btn-yellow-primary tertiary text-uppercase"
            onClick={() => {
              //console.log(dataHolder);
              downloadExcel2();
            }}
          >
            Generate Record
          </Button>          
        </div>
        </> 
        :<>

        <div className="data1 mx-5 buttonCss">  
          <Button
            variant="primary"
            className="btn-yellow-primary tertiary text-uppercase"
            onClick={() => {
              //console.log(dataHolder);
              downloadExcel();
            }}
          >
            Generate Record
          </Button>
          </div>
        </>
        
        }
        
      </div>
      </fieldset>
    </>
  )
}
