export class EACReviewDetails {
    constructor(
      public id: number = 0,
      public label: string = '',
      public desc: string = '',
      public weightage: number = 0,
      public question: Array<QuestionData> = []
    ) {
      this.id = id;
      this.label = label;
      this.desc = desc;
      this.weightage = weightage;
      this.question = question;
    }
  }
  
  export class EACReviewData {
    constructor(
      public eac_id: number = 0,
      public eac_name: string = '',
      public eac_score: number = 0,
      public incubator_id: number = 0,
      public incubator_name: string = '',
      public data: Array<EACReviewDetails> = []
    ) {
      this.eac_id = eac_id;
      this.eac_name = eac_name;
      this.eac_score = eac_score;
      this.incubator_id = incubator_id;
      this.incubator_name = incubator_name;
      this.data = data;
    }
  }
  export class QuestionData {
    constructor(
      public esb_id: number = 0,
      public esb_label: string = '',
      public esbl_label_type: string = '',
      public esb_range_desc: string = '',
      public esb_weightage: number = 0,
      public score: number = 0,
      public comments: string = '',
      public weighted_score: number = 0
    ) {
      this.esb_id = esb_id;
      this.esb_label = esb_label;
      this.esbl_label_type = esbl_label_type;
      this.esb_range_desc = esb_range_desc;
      this.esb_weightage = esb_weightage;
      this.score = score;
      this.comments = comments;
      this.weighted_score = weighted_score;
    }
  }
  
  export class MeetingDetails {
    constructor(
      public startup_application_id: number = 0,
      public meeting_type: number = 0,
      public comment: string = '',
      public user_id: number = 0,
      public startup_meeting_id:number
    ) {
      this.startup_application_id = startup_application_id;
      this.meeting_type = meeting_type;
      this.comment = comment;
      this.user_id = user_id;
      this.startup_meeting_id = startup_meeting_id;
    }
  }
  