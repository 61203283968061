import React from "react";
import { Link } from "react-router-dom";

/** Render Application Status for Incubator screen */
function ContactSectionComponent() {
    return (
        <>
            <section className="section-container section-bg-yellow section-home">
                <div className="container">
                    <div className="section-apply">
                        <div className="section-heading">
                            <h2>Need More Information?</h2>
                        </div>
                        <div className="section-content section-padding">
                            <p>If you have any questions regarding the Scheme or
                            are having issues in applying for the seed support,
                                    please feel free to contact us</p>
                        </div>
                    </div>

                    <div className="section-action pb-4">                
                        <Link className="btn btn-large btn-sisfs btn-blue-primary" to="/contact">Contact Us</Link>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactSectionComponent;