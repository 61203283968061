import pdf from '../../../images/PDF.svg';
import xls from '../../../images/xls.svg';
import xlsx from '../../../images/xls.svg';
import Allround from '../../../images/Allround.svg'
import doc from '../../../images/doc.svg';
import {StartupAccountDetailsData} from '../../../models/funddetails/StartupAccountDetailsData';
import { File_node_url } from '../../../constants/api';

interface IAccountDetailsProp {
  accountDetailsModel: StartupAccountDetailsData;
}

function AccountDetailsComponent(props: IAccountDetailsProp) {
  const { accountDetailsModel } = props;


  const getFileDetails = (file: any, key: string) => {
    let file_name = '';
    let file_ext = '';
    file_name =
      file != undefined && file.length > 1
        ? file.substr(file.lastIndexOf('/') + 1, file.length)
        : '';
    file_ext = file != undefined && file.length > 1 ? (file.substr(
      file.lastIndexOf('.') + 1,
      file.length)
    ) : "";
    if (key == 'ext') return file_ext;
    if (key == 'name') return file_name;
    // console.log("FILE CHECK",file)
    if(file != undefined){
      if (key == 'file') return file.toString();
    }
  };

  const downloadDocument = (file: any, file_name: any) => {
    fetch(file).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = file_name;
        a.click();
      });
      //window.location.href = response.url;
    });
  };

  const getAccountName = (type: number) => {
    if (type === undefined || !type) return ""
    let accountName = ""
    if (type === 1) {
      accountName = "Saving"
    } else if (type === 2) {
      accountName = "Current"
    } else if (type === 3) {
      accountName = "Salary"
    } else if (type === 4) {
      accountName = "Fixed Deposit"
    } else if (type === 5) {
      accountName = "Recurring Deposit"
    }
    return accountName
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <span className="incubator-label-value">Account Holder</span>
        </div>
      </div>
      <div className="row mt-1-5">
        <div className="col-md-4">
          <span className="incubator-label">Account Name </span>
          <br />
          <span className="incubator-label-value">
            {accountDetailsModel.bank_account_holder_name == '' ||
              accountDetailsModel.bank_account_holder_name == null
              ? '-'
              : accountDetailsModel.bank_account_holder_name}
          </span>
        </div>
        {/* <div className="col-md-4">
          <span className="incubator-label">Proof of PFMS Registration</span>
          <br />
          {accountDetailsModel.pan_card != undefined && accountDetailsModel.pan_card != null && accountDetailsModel.pan_card_name != null ?
            <div className="row">
              {console.log(accountDetailsModel,"panFile")}
              <div className="col-md-3">
                {getFileDetails(
                  accountDetailsModel.pan_card.file,
                  'ext'
                ) == 'pdf' ? (
                  <img src={pdf} alt="SISFS Logo" />
                ) : getFileDetails(
                  accountDetailsModel.pan_card.file,
                  'ext'
                ) == 'doc' ? (
                  <img src={doc} alt="SISFS Logo" />
                ) : getFileDetails(
                  accountDetailsModel.pan_card.file,
                  'ext'
                ) == 'xls' ? (
                  <img src={xls} alt="SISFS Logo" />
                ) : (
                  <img src={Allround} alt="SISFS Logo" />
                )}
              </div>
              <div className="col-md-9">
                <span className="document-label-value">
                  {getFileDetails(
                    accountDetailsModel.pan_card.file,
                    'name'
                  )}
                </span>
                <br />
                <br />
                <a
                  // href={getFileDetails(accountDetailsModel.pan_card.file, "file")}
                  href={`${File_node_url}`+ accountDetailsModel.pan_card.file}
                  target="_blank"
                //download="pan_card"
                  className="incubator-details-link"
                >
                  View
            </a>
            /
            <a
                  href="#"
                  // download="pan_card"
                  className="incubator-details-link"
                  onClick={() =>
                    downloadDocument(
                      accountDetailsModel.pan_card.file,
                      accountDetailsModel.pan_card_name
                    )
                  }
                >
                  Download
            </a>
              </div>
            </div>
            : ""}

        </div> */}
        <div className="col-md-4">
          <span className="incubator-label">
            {/* Proof of TRA Account & ECS Mandate Form */}
            ECS mandate form of startup
            </span>
          <br />
          {accountDetailsModel.cancelled_cheque_location != undefined && accountDetailsModel.cancelled_cheque_location != null ?
            <div className="row">
              <div className="col-md-3">
                {getFileDetails(
                  accountDetailsModel.cancelled_cheque.file,
                  'ext'
                ) == 'pdf' ? (
                  <img src={pdf} alt="SISFS Logo" />
                ) : getFileDetails(
                  accountDetailsModel.cancelled_cheque.file,
                  'ext'
                ) == 'doc' ? (
                  <img src={doc} alt="SISFS Logo" />
                ) : getFileDetails(
                  accountDetailsModel.cancelled_cheque.file,
                  'ext'
                ) == 'xls' ? (
                  <img src={xls} alt="SISFS Logo" />
                ) : (
                  <img src={Allround} alt="SISFS Logo" />
                )}
              </div>
              <div className="col-md-9">
                <span className="document-label-value">
                  {getFileDetails(
                    accountDetailsModel.cancelled_cheque_location,
                    'name'
                  )}
                </span>
                <br />
                <br />
                <a
                  // href={getFileDetails(accountDetailsModel.cancelled_cheque.file, "file")}
                  href={`${File_node_url}`+ accountDetailsModel.cancelled_cheque.file}
                  target="_blank"
                //download="proof_of_disbursement"
                  className="incubator-details-link"
                >
                  View
            </a>
            {/* /
            <a
                  href="#"
                  // download="proof_of_disbursement"
                  className="incubator-details-link"
                  onClick={() =>
                    downloadDocument(
                      accountDetailsModel.cancelled_cheque.file,
                      accountDetailsModel.cancelled_cheque_name
                    )
                  }
                >
                  Download
            </a> */}
              </div>
            </div>
            : ""}

        </div>
      </div>

      <div className="application-incubator-hr">

        <hr />
      </div>

      <div className="row">
        <div className="col-md-12">
          <span className="incubator-label-value">Account Details</span>
        </div>
      </div>

      <div className="row mt-1-5">
        <div className="col-md-4">
          <span className="incubator-label">Bank Name</span>
          <br />
          <span className="incubator-label-value">
            {accountDetailsModel.bank_name == '' ||
              accountDetailsModel.bank_name == null
              ? '-'
              : accountDetailsModel.bank_name}
          </span>
        </div>
        <div className="col-md-4">
          <span className="incubator-label">Branch Name/Branch Code</span>
          <br />
          <span className="incubator-label-value">
            {accountDetailsModel.branch_name == '' ||
              accountDetailsModel.branch_name == null
              ? '-'
              : accountDetailsModel.branch_name}
          </span>
        </div>
        <div className="col-md-4">
          <span className="incubator-label">IFSC Code</span>
          <br />
          <span className="incubator-label-value">
            {accountDetailsModel.bank_ifsc_code == '' ||
              accountDetailsModel.bank_ifsc_code == null
              ? '-'
              : accountDetailsModel.bank_ifsc_code}
          </span>
        </div>


      </div>

      <div className="row mt-1-5">
        <div className="col-md-4">
          <span className="incubator-label">Account Type</span>
          <br />
          <span className="incubator-label-value">


            {getAccountName(accountDetailsModel.account_type)}


          </span>
        </div><div className="col-md-4">
          <span className="incubator-label">Account Number</span>
          <br />
          <span className="incubator-label-value">
            {accountDetailsModel.account_number == '0' ||
              accountDetailsModel.account_number == null
              ? '-'
              : accountDetailsModel.account_number}
          </span>
        </div>
        <div className="col-md-4">
          <span className="incubator-label">MICR Code</span>
          <br />
          <span className="incubator-label-value">
            {accountDetailsModel.bank_micr_code == '' ||
              accountDetailsModel.bank_micr_code == null
              ? '-'
              : accountDetailsModel.bank_micr_code}
          </span>
        </div>

      </div>

      <div className="row mt-1-5">
        <div className="col-md-4">
          <span className="incubator-label">Bank Address</span>
          <br />
          <span className="incubator-label-value">
            {accountDetailsModel.branch_address == '' ||
              accountDetailsModel.branch_address == null
              ? '-'
              : accountDetailsModel.branch_address}
          </span>
        </div>
      </div>
      <div className="mt-1-5"></div>
    </div>
  );
}

export default AccountDetailsComponent;
