import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import DpiitDashboardContainer from "../components/dpiit/dashboard/DpiitContainer";
import EacDashboardContainer from "../components/eac/dashboard/EacContainer";
import IncubatorDashboardContainer from "../components/incubator/dashboard/IncubatorContainer";
import IncubatorListingContainer from "../components/dpiit/dashboard/IncubatorListingContainer";
import DashboardFooterComponent from "../components/common/footer/DashboardFooterComponent";
import DashboardHeaderComponent from "../components/dpiit/common/DashboardHeaderComponent";
import EacReviewDpiit from "../components/dpiit/dashboard/EacReviewDpiit";
import ViewIncubatorDetails from "../components/dpiit/incubator/ViewIncubatorDetails";
import ErrorContainer from "../components/error/error";
import FooterComponent from "../components/common/footer/FooterComponent";
import HeaderComponent from "../components/common/header/HeaderComponent";
import { getSessionStorageValue } from "../effects/utils/session-storage";
import { useEffect } from "react";

import { Link, useHistory } from 'react-router-dom';
import ContactUsComponent from "../components/contact/ContactUsComponent";
import ContactContainer from "../components/contact/ContactContainer";
import GrievanceListComponent from "../components/dpiit/grievances/GrievanceListComponent";
import AddUserComponent from "../components/dpiit/dashboard/AddUserComponent";
import ViewUserComponent from "../components/dpiit/dashboard/ViewUsersComponent";
import GrievanceDetailComponent from "../components/dpiit/grievances/GrievanceDetailComponent";
import DpiitStartupListingComponent from "../components/dpiit/startuplisting/DpiitStartupListingComponent";
import ViewStartupDetailsComponent from "../components/incubator/startuplisting/startupdetails/ViewStartupDetailsComponent";
import StartupListContainer from "../components/incubator/startuplisting/StartupListContainer";
import IncubatorPreferenceComponent from "../components/dpiit/IncubatorPreferenceComponent";
import IncAppliedReappliedContainer from "../components/dpiit/dashboard/IncAppliedReappliedContainer";
import MergedIncubatorDetailsComponent from "../components/dpiit/incubator/MergedIncubators/MergedIncubatorDetailsComponent";
import SecretariatHeaderComponent from "../components/dpiit/common/SecretariatHeaderComponent";
import RequireAuth from "../Routelocks/RouterHoooks";
import NoticeBoard from "../components/notice_board/NoticeBoard";

const DpiitRouterComponent = () => {

  const history = useHistory();
  const user: any = getSessionStorageValue("user");
  const userInfo = JSON.parse(user)
  useEffect(() => {
    // console.log("DPIIT page load")
    console.log("DPIIT")

    // if (user == null || userInfo.roles != "dpiit") {
    //   history.push('/error');
    // }
  }, []);
  return (
    <>

      <RequireAuth>
        <SecretariatHeaderComponent />
        <Switch>
        
          <Route path="/dpiit_notice_board" component={NoticeBoard} />
          <Route path="/dashboard_dpiit" component={DpiitDashboardContainer} />
          <Route path="/eac_review_dpiit" component={EacReviewDpiit} />
          <Route path="/grievance_detail" component={GrievanceDetailComponent} />
          <Route path="/dpiit_incubator_list" component={IncubatorListingContainer} />
          <Route path="/dpiit_startup_list" component={DpiitStartupListingComponent} />
          <Route path="/dpiit_grievances" component={GrievanceListComponent} />
          <Route path="/user_add" component={AddUserComponent} />
          <Route path="/user_view" component={ViewUserComponent} />
          <Route path="/dpiit_view_incubator" component={ViewIncubatorDetails} />
          <Route path="/dpiit_startup_list_details" component={StartupListContainer} />
          <Route path="/dpiit_startup_details" component={ViewStartupDetailsComponent} />
          <Route path="/contact_us" component={ContactContainer} />
          <Route path="/incubator_prefrence_dpit" component={IncubatorPreferenceComponent} />


        </Switch>

        {/* Dashboard Footer Component */}

        <DashboardFooterComponent />
      </RequireAuth>
    </>
  );
};

export default DpiitRouterComponent;
