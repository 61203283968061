import { AccountFileUploadModel, FileUploadModel } from "../FileUploadModel";

export class AccountDetailsData {
    constructor(
        public id: number,
        public incubator_id: number,
        public bank_account_holder_name: string,
        public bank_name: string,
        public account_number: string,
        public account_type: any,
        public bank_ifsc_code: string,
        public bank_micr_code: string,
        public branch_name: string,
        public branch_address: string,
        public created_at: string,
        public updated_at: string,
        public pan_card_name: string,
        public pan_card_location: string,

        public cancelled_cheque_name: string,
        public cancelled_cheque_location: string,
        public pan_card: AccountFileUploadModel,
        public cancelled_cheque: AccountFileUploadModel,
        public darpan_location: string,
        public darpan: AccountFileUploadModel,
        public darpan_name: string,
        public tra: AccountFileUploadModel,
        public sanction: AccountFileUploadModel,
        public tra_agreement_name: string,
        public sanction_name: string,
        public sanction_location:string,
        public tra_agreement_location:string

    ) {
        this.id = id;
        this.incubator_id = incubator_id;
        this.bank_account_holder_name = bank_account_holder_name;
        this.bank_name = bank_name;
        this.account_number = account_number;
        this.account_type = account_type;
        this.bank_ifsc_code = bank_ifsc_code;
        this.bank_micr_code = bank_micr_code;
        this.branch_name = branch_name;
        this.branch_address = branch_address;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.pan_card_name = pan_card_name;
        this.pan_card_location = pan_card_location;
        this.cancelled_cheque_name = cancelled_cheque_name;
        this.cancelled_cheque_location = cancelled_cheque_location;
        this.pan_card = pan_card;
        this.cancelled_cheque = cancelled_cheque;
        this.darpan_location = darpan_location;
        this.darpan = darpan;
        this.darpan_name = darpan_name;
        this.tra = tra;
        this.sanction = sanction;
        this.tra_agreement_name = tra_agreement_name;
        this.sanction_name = sanction_name;
        this.sanction_location = sanction_location;
        this.tra_agreement_location = tra_agreement_location;
    }

}

export class AccountDetailsData1 {
    constructor(
        public id: number,
        public incubator_id: number,
        public bank_account_holder_name: string,
        public bank_name: string,
        public account_number: string,
        public account_type: any,
        public bank_ifsc_code: string,
        public bank_micr_code: string,
        public branch_name: string,
        public branch_address: string,
        public created_at: string,
        public updated_at: string,
        public pan_card_name: string,
        public pan_card_location: string,

        public cancelled_cheque_name: string,
        public cancelled_cheque_location: string,
        //    public pan_card:any,
        // public cancelled_cheque:any,
        public darpan_location: string,
        // public darpan:any,
        public darpan_name: string

    ) {
        this.id = id;
        this.incubator_id = incubator_id;
        this.bank_account_holder_name = bank_account_holder_name;
        this.bank_name = bank_name;
        this.account_number = account_number;
        this.account_type = account_type;
        this.bank_ifsc_code = bank_ifsc_code;
        this.bank_micr_code = bank_micr_code;
        this.branch_name = branch_name;
        this.branch_address = branch_address;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.pan_card_name = pan_card_name;
        this.pan_card_location = pan_card_location;
        this.cancelled_cheque_name = cancelled_cheque_name;
        this.cancelled_cheque_location = cancelled_cheque_location;
        // this.pan_card=pan_card;

        // this.cancelled_cheque=cancelled_cheque;
        this.darpan_location = darpan_location
        // this.darpan=darpan
        this.darpan_name = darpan_name

    }

}
export class AccountDetailsData2 {
    constructor(
        public id: number,
        public incubator_id: number,
        public bank_account_holder_name: string,
        public bank_name: string,
        public account_number: string,
        public account_type: any,
        public bank_ifsc_code: string,
        public bank_micr_code: string,
        public branch_name: string,
        public branch_address: string,
        public created_at: string,
        public updated_at: string,
        public pan_card_name: string,
        public pan_card_location: string,

        public cancelled_cheque_name: string,
        public cancelled_cheque_location: string,
        //    public pan_card:any,
        // public cancelled_cheque:any,
        public darpan_location: string,
        // public darpan:any,
        public darpan_name: string

    ) {
        this.id = id;
        this.incubator_id = incubator_id;
        this.bank_account_holder_name = bank_account_holder_name;
        this.bank_name = bank_name;
        this.account_number = account_number;
        this.account_type = account_type;
        this.bank_ifsc_code = bank_ifsc_code;
        this.bank_micr_code = bank_micr_code;
        this.branch_name = branch_name;
        this.branch_address = branch_address;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.pan_card_name = pan_card_name;
        this.pan_card_location = pan_card_location;
        this.cancelled_cheque_name = cancelled_cheque_name;
        this.cancelled_cheque_location = cancelled_cheque_location;
        // this.pan_card=pan_card;

        // this.cancelled_cheque=cancelled_cheque;
        this.darpan_location = darpan_location
        // this.darpan=darpan
        this.darpan_name = darpan_name

    }

}

export class SubsequentDetails {
    constructor(
        public id : number,
        public incubator_id: number,
        public application_ind: string,
        public ECS_Mandate_file_path: string,
        public ECS_Mandate_file_path_file_name:string,
        public pfms_file_path:string,
        public pfms_file_path_file_name:string,
        public proof_Of_Darpan_file_path:string,
        public proof_Of_Darpan_file_path_file_name:string
    ){
        this.id  = id;
        this.incubator_id = incubator_id;
        this.application_ind = application_ind
        this.ECS_Mandate_file_path = ECS_Mandate_file_path
        this.ECS_Mandate_file_path_file_name =ECS_Mandate_file_path_file_name
        this.pfms_file_path = pfms_file_path
        this.pfms_file_path_file_name = pfms_file_path_file_name
        this.proof_Of_Darpan_file_path = proof_Of_Darpan_file_path
        this.proof_Of_Darpan_file_path_file_name = proof_Of_Darpan_file_path_file_name
    }
}