import search from "../../../src/images/search.svg";
import React, { Fragment, useEffect, useState } from "react";
import { CANCEL_STATUS_UPDATE_StARTUP, FETCH_ST_CANCEL_DETAILS, GET_PORTFOLIO_DETAILS, INCUBATOR_PREFRENCE_LIST, INCUBATOR_PREFRENCE_LIST_POST, NODE_URL, PORTFOLIO_REQUEST } from "../../constants/api";
import { post } from "../../effects/api/api.common";
import { getSessionStorageValue } from "../../effects/utils/session-storage";
import { filterByValue } from "../../utils/CommonMethods";
import { BsFillEyeFill } from "react-icons/bs";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import IncubatorPrefrenceInformationComponent from "./IncubatorPrefrenceInformationComponent";
import ReactPaginate from 'react-paginate';
import '../../styles/dashboard.css';
import error from "../../images/error.svg";
import { PortfolioDetailsModel } from "../../models/incubator/PortfolioDetailsModel";
import { useHistory } from "react-router-dom";
import fileupload from '../../images/fileupload.svg';
import pdfImg from '../../images/pdfimg.svg';
import view from '../../images/view.svg'
import moment from "moment";
import profile from '../../images/profile-default.svg'
declare var $: any;


export default function IncubatorPreferenceComponent() {
  const [showReport, setShowReport] = useState(false);
  const [getIncuberId, setIncuberId] = useState();
  const [getAppId, setAppId] = useState();
  const [getUserName, setUserName] = useState();
  const [incubatorList, setIncubatorList] = useState([]);
  const [sortedList,setSortedList] = useState([]);
  const [cancellationList, setCancellationList] = useState([]);
  const [viewCancellationData, setCancellationData] = useState({
    reason: '',
    mail_trail_path: '',
    ismc_file_path: '',
    incubator_comments: '',
    status_id: '',
    rejected_on: '',
    rejected_comment: '',
    cancelled_on: '',
    cancellation_status_file_path: '',
    request_date: ''
  })
  const [filteredIncubatorList, setFilteredIncubatorList] = useState<any>([]);
  const [noDataFilter, setNoDataFilter] = useState('');
  const [loggedInRole, setLoggedInRole] = useState('');
  const [loggedInId, setLoggedInId] = useState('')
  const [showToEac, setShowToEac] = useState(false);
  const [activeIndex, setActiveIndex] = useState("grievances");
  const user: any = getSessionStorageValue("user");
  const userInfo = JSON.parse(user);
  const [dataPortfolio, setDataPortfolio] = useState<any>({})
  const [showMore, setShowMore] = useState(false);
  const [get_st_name, set_st_name] = useState('')
  const [get_st_appID, set_st_aapID] = useState('')
  const [get_cancelId, set_cancelId] = useState('')
  const [loader, setLoader] = useState(false);
  const [portfolioDetails, setPortfolioDetails] = useState<PortfolioDetailsModel>(
    new PortfolioDetailsModel(
      "",
      "",
      "",
      "",
      [],
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      [],
      [],
      [],
      "",
      "",
      "",
      false,
      false,
      false,
      false,
      ""
    ))
  useEffect(() => {
    window.scrollTo(0, 0);
    if (getSessionStorageValue("user")) {
      const user: any = getSessionStorageValue("user");
      const userInfo = JSON.parse(user)
      setLoggedInRole(userInfo.roles);
      setLoggedInId(userInfo.id)
      if (userInfo.roles === 'dpiit') {
        setShowToEac(false);
      }
      else if (userInfo.roles === 'secretariat') {
        setShowToEac(false);
      }
    }
    getUserList();
  }, []);

  const changeActive = (tabs: any) => {
    setActiveIndex(tabs)
  }

  const getFileDetails = (file: any, key: string) => {
    let file_name = '';
    file_name =
      file != undefined && file != null && file.length > 1
        ? file.substr(file.lastIndexOf('/') + 1, file.length)
        : '';
    if (key == 'name') return file_name;
    if (key == 'file') return file != undefined ? file.toString() : '';
  };
  const getUserList = () => {
    let data = {
      "user_id": userInfo.id
    }
    setLoader(true)
    post(INCUBATOR_PREFRENCE_LIST, data).then((response: any) => {
      if (!response || !response.data) return;
      setLoader(false)
      setIncubatorList(response.data.Data)
      setSortedList(response.data.Data)
    });
  };
  const searchList = (e: any) => {
    setNoDataFilter(e.target.value)
    const filteredData = filterByValue(incubatorList, e.target.value);
    setFilteredIncubatorList(filteredData)
  }
  const [checkCount,setCheckCount] = useState(0)
  
  const sortClick = () => {    

    setCurrentPage(0);
    setCurrentPage1(0); 
    handlePageChange({ selected: 0 })
    const updatedCount = checkCount + 1;
    
    if(updatedCount == 1){

      const data = sortedList.filter((item:any)=>item.cancellation_status_id==1 && item.no_of_days>0 && item.dates_diff>0)      
      setIncubatorList(data.sort((a:any,b:any)=> b.no_of_days - a.no_of_days))
    }else if(updatedCount == 2){
       
      const data = sortedList.filter((item:any)=>item.cancellation_status_id==1 &&  item.no_of_days>0 && item.dates_diff>0)      
      setIncubatorList(data.sort((a:any,b:any)=>a.no_of_days - b.no_of_days))
    }
    else if(updatedCount == 3){

      const data = sortedList.filter((item:any)=>item.cancellation_status_id==1 &&  item.dates_diff <= 0)      
      setIncubatorList(data.sort((a:any,b:any)=>a.no_of_days - b.no_of_days))
    }
    else if(updatedCount == 4){

      const data = sortedList.filter((item:any)=>item.cancellation_status_id==3 )     
      setIncubatorList(data.sort((a:any,b:any)=>a.no_of_days - b.no_of_days))
    }
    else if(updatedCount == 5){

      const data = sortedList.filter((item:any)=>item.cancellation_status_id==4 )
      setIncubatorList(data.sort((a:any,b:any)=>a.no_of_days - b.no_of_days))
    }
    else{
      getUserList();
    }
    setCheckCount(updatedCount > 5 ? 0 : updatedCount);

  }
  const getDetailsOfPortfolio = (incubatorId: any) => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    post(GET_PORTFOLIO_DETAILS, { "logged_user_id": incubatorId }).then((response: any) => {

      const keyFeaturesList = response.data.data[0].key_features.split(",");
      setPortfolioDetails({
        incubator_name: response.data.data[0].incubator_name,
        site_url: response.data.data[0].site_url,
        location_state: response.data.data[0].state,
        location_city: response.data.data[0].city,
        sectors: JSON.parse(response.data.data[0].sectors),
        message: response.data.data[0].description,
        key_features: keyFeaturesList[0] != undefined ? keyFeaturesList[0].replace(/'/g, '') : "",
        key_features1: keyFeaturesList[1] != undefined ? keyFeaturesList[1].replace(/'/g, '') : "",
        key_features2: keyFeaturesList[2] != undefined ? keyFeaturesList[2].replace(/'/g, '') : "",
        key_features3: keyFeaturesList[3] != undefined ? keyFeaturesList[3].replace(/'/g, '') : "",
        key_features4: keyFeaturesList[4] != undefined ? keyFeaturesList[4].replace(/'/g, '') : "",
        incubator_email: response.data.data[0].email_id,
        logo_image: response.data.data[0].logo,
        cover_image: response.data.data[0].image,
        gallery_pictures: [],
        remaining_pictures: "",
        remaining_logo: response.data.data[0].logo,
        remaining_banner: response.data.data[0].image,
        flag: false,
        flag1: false,
        flag2: false,
        flag3: false,
        Id: response.data.data[0].Id
      });

      // setSectorValue(JSON.parse(response.data.data[0].sectors))
      // getAllDistrictsByStateId(response.data.data[0].state)
      const sectors1: any = []
      const Sectorsarray = response.data.data[0].sectorsnames.split(',');
      const ImagesArray = response.data.data[0].image_data.split(',');
      const KeyFeatresArray = response.data.data[0].key_features
      const resultArray = KeyFeatresArray.split(/,\s*'|'\s*,/)
        .filter((item: any) => item !== "'" && item !== "''")
        .map((item: any) => item.replace(/'/g, ''));
      //  console.log(resultArray);
      sectors1.push({ "Id": response.data.data[0].Id, "sectors": Sectorsarray, "state": response.data.data[0].statename, "city": response.data.data[0].cityname, "image": response.data.data[0].image, "incubator_name": response.data.data[0].incubator_name, "description": response.data.data[0].description, "image_data": ImagesArray, "key_features": resultArray, "logo": response.data.data[0].logo, "site_url": response.data.data[0].site_url })

      // console.log(sectors1)
      setDataPortfolio(sectors1[0])

    })
  }
  

  const handleAllCheck = (e: any, id: any, id1: any, application_type: any, incubator_application_no: any) => {
    var isChecked = e.target.checked;
    if (isChecked) {
      const payload = {
        "active_status": "1",
        "incubator_application_id": id,
        "incubator_id": id1,
        "application_type": application_type,
        "incubator_application_no": incubator_application_no,
        "user_id": userInfo.id
      }
      // return;
      post(INCUBATOR_PREFRENCE_LIST_POST, payload).then((response: any) => {
        if (!response || !response.data) return;
        if (response.data.result === "success") {
          getUserList()
          if (filteredIncubatorList.length != 0) {
            let index = filteredIncubatorList.findIndex((p: any) => p.incubator_id == id1);
            filteredIncubatorList[index].active_status = '0'
            setFilteredIncubatorList(filteredIncubatorList)
          }
        }
      });
    } else if (isChecked == false) {
      const payload = {
        "active_status": "0",
        "incubator_application_id": id,
        "incubator_id": id1,
        "application_type": application_type,
        "incubator_application_no": incubator_application_no,
        "user_id": userInfo.id

      }
      post(INCUBATOR_PREFRENCE_LIST_POST, payload).then((response: any) => {
        if (!response || !response.data) return;
        if (response.data.result == "success") {
          getUserList()
          if (filteredIncubatorList.length != 0) {
            let index = filteredIncubatorList.findIndex((p: any) => p.incubator_id == id1);
            filteredIncubatorList[index].active_status = '1'
            setFilteredIncubatorList(filteredIncubatorList)
          }
        }
      });
    }
  };
  const handleAllCheck1 = (e: any, id: any, id1: any, application_type: any, incubator_application_no: any) => {
    var isChecked = e.target.checked;
    if (isChecked) {
      const payload = {
        "active_status": "1",
        "incubator_application_id": id,
        "incubator_id": id1,
        "application_type": application_type,
        "incubator_application_no": incubator_application_no,
        "user_id": userInfo.id
      }
      // return;
      post(INCUBATOR_PREFRENCE_LIST_POST, payload).then((response: any) => {
        if (!response || !response.data) return;
        if (response.data.result === "success") {
          getUserList()
          if (filteredIncubatorList.length != 0) {
            let index = filteredIncubatorList.findIndex((p: any) => p.incubator_id == id1);
            filteredIncubatorList[index].active_status = '1'
            setFilteredIncubatorList(filteredIncubatorList)
          }
        }
      });
    } else if (isChecked == false) {
      const payload = {
        "active_status": "0",
        "incubator_application_id": id,
        "incubator_id": id1,
        "application_type": application_type,
        "incubator_application_no": incubator_application_no,
        "user_id": userInfo.id

      }
      post(INCUBATOR_PREFRENCE_LIST_POST, payload).then((response: any) => {
        if (!response || !response.data) return;
        if (response.data.result == "success") {
          getUserList()
          if (filteredIncubatorList.length != 0) {
            let index = filteredIncubatorList.findIndex((p: any) => p.incubator_id == id1);
            filteredIncubatorList[index].active_status = '0'
            setFilteredIncubatorList(filteredIncubatorList)
          }
        }
      });
    }
  };

  const getVal = (id: any, appId: any, userName: any) => {
    setAppId(appId);
    setIncuberId(id);
    setUserName(userName);
  }
  const FetchCAncellationData = (id: any, appId: any, userName: any) => {
    setAppId(appId);
    setIncuberId(id);
    setUserName(userName);

    post(FETCH_ST_CANCEL_DETAILS, { incubator_id: id }).then((response: any) => {
      let reverseArr = response.data.data.reverse();
      setCancellationList(reverseArr)
    })
  }
  const viewProofs = (id: any, st_name: any) => {
    $('#CancellationPopUp').modal('hide');
    set_st_name(st_name)
    let data = cancellationList.filter((item: any) => item.cancelId == id);
    setCancellationData(data[0]);
  }
  const closeSTModal = () => {
    $('#CancellationRequest').modal('hide');
    $('#CancellationPopUp').modal('show');
  }
  const cancellationAccept = (applicationID: any, cancelId: any) => {
    setRejectReasonTxt('')
    set_st_aapID(applicationID)
    set_cancelId(cancelId)
  }
  const confirmApprovePopup = () => {

    $('#confirmationPopUp').modal('hide');
    $('#ConfirmApprovePopup').modal('show');
  }
  const confirmRejectPopup = () => {
    $('#confirmationPopUp').modal('hide');
    $('#ConfirmRejectPopup').modal('show');
  }

  const approve_request = (userName: any, id: any, incbatorId: any) => {
    // console.log(userName, id, incbatorId);
    var payload = {
      "logged_user_id": userInfo.id,
      "approve_Ind": 1,
      "incubator_id": incbatorId
    }
    // console.log(payload)
    // return
    post(PORTFOLIO_REQUEST, payload).then((response) => {
      if (response.data.result == 'success') {
        getUserList()
        $('#confirmationPopUp').modal('hide');
        $('#ConfirmApprovePopup').modal('hide');
        $('#approvePopUp').modal('show');

      }
    });
  };
  const reject_request = (userName: any, id: any, incbatorId: any) => {
    // console.log(userName, id, incbatorId);
    var payload = {
      "logged_user_id": userInfo.id,
      "approve_Ind": 2,
      "incubator_id": incbatorId
    }
    // console.log(payload)
    // return
    post(PORTFOLIO_REQUEST, payload).then((response) => {
      if (response.data.result == 'success') {
        getUserList();
        setNoDataFilter(noDataFilter);
        setFilteredIncubatorList(filteredIncubatorList);
        $('#confirmationPopUp').modal('hide');
        $('#ConfirmRejectPopup').modal('hide');
        $('#rejectPopUp').modal('show');
      }
    });
  };
  const backToConformPopup = () => {
    $('#ConfirmRejectPopup').modal('hide');
    $('#ConfirmApprovePopup').modal('hide');
    $('#confirmationPopUp').modal('show');

  }
  const closeModal = () => {
    window.location.reload();
  }
  const history = useHistory();
  const preview_portfolio = (userName: any, id: any, incbatorId: any) => {
    $('#confirmationPopUp').modal('hide');
    history.push('/preview_portfolio');
    localStorage.setItem("incubatorId", incbatorId)
    // getDetailsOfPortfolio(incbatorId);
  };
  const [rejectReasonTxt, setRejectReasonTxt] = useState('')
  const [uploadReason, setUploadReason] = useState<any>();
  const [checkReason, setCheckReason] = useState(false);
  const handleChange = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;

    setRejectReasonTxt(value)
    if (name == "uploadReason") {
      const file = e.target.files[0];
      if (file.size > 3 * 1024 * 1024) {
        // setExceedFileSize(true)
        return;
      } else {
        setUploadReason(file)
      }


    }
  }
  const approve_cancellation_request = (st_appID: any, cancelId: any) => {
    if (rejectReasonTxt == '') {
      setCheckReason(true);
      return;
    }
    if (uploadReason == undefined) {
      setCheckReason(true);
      return;
    }
    const formData = new FormData();
    formData.append("id", cancelId)
    formData.append("status_id", "2")
    formData.append("roles", loggedInRole)
    formData.append("user_id", loggedInId)
    formData.append("startup_application_id", st_appID)
    formData.append("comment", 'Cancellation Request Approved by Secretariat, Reason: ' + "  " + rejectReasonTxt)
    formData.append("selected_incubator_id", JSON.stringify(getIncuberId))
    formData.append("file", uploadReason)
    // console.log(formData);
    // return;
    post(CANCEL_STATUS_UPDATE_StARTUP, formData).then((response: any) => {

      if (response.data.result == "success") {
        $('#CancellationApprovePopup').modal('hide');
        post(FETCH_ST_CANCEL_DETAILS, { incubator_id: getIncuberId }).then((response: any) => {
          window.location.reload();
          setCancellationList(response.data.data);
        })
      }
    })
  }

  const reject_cancellation_request = (st_appID: any, cancelId: any) => {

    if (rejectReasonTxt == '') {
      setCheckReason(true);
      return;
    }
    if (uploadReason == undefined || uploadReason == '') {
      setCheckReason(true);
      return;
    }
    const formData = new FormData();
    formData.append("id", cancelId)
    formData.append("status_id", "3")
    formData.append("roles", loggedInRole)
    formData.append("user_id", loggedInId)
    formData.append("startup_application_id", st_appID)
    formData.append("comment", 'Cancellation Request Rejected by Secretariat, Reason: ' + "  " + rejectReasonTxt)
    formData.append("selected_incubator_id", JSON.stringify(getIncuberId))
    formData.append("file", uploadReason)
    // console.log(formData)
    // return;
    post(CANCEL_STATUS_UPDATE_StARTUP, formData).then((response: any) => {

      if (response.data.result == "success") {
        $('#CancellationRejectPopup').modal('hide');
        post(FETCH_ST_CANCEL_DETAILS, { incubator_id: getIncuberId }).then((response: any) => {
          window.location.reload();
          setCancellationList(response.data.data);
        })
      }
    })
  }

  const dismisModal = () => {
    setCheckReason(false);
    $('CancellationApprovePopup').modal('hide');
    $('CancellationRejectPopup').modal('hide');

  }
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPage1, setCurrentPage1] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [itemsPerPage1, setItemsPerPage1] = useState(25);
  // const itemsPerPage = 10;
  const handlePageChange = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
    setCurrentPage1(selectedItem.selected);
  };
  const handleItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newItemsPerPage = parseInt(event.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setItemsPerPage1(newItemsPerPage);
    setCurrentPage(0); // Reset current page to first page when items per page changes
    setCurrentPage1(0); // Reset current page to first page when items per page changes
    handlePageChange({ selected: 0 })
  };
  const offset = currentPage * itemsPerPage;
  const offset1 = currentPage1 * itemsPerPage1;
  const currentPageData = incubatorList.slice(offset, offset + itemsPerPage);
  // const currentPageData1 = filteredIncubatorList.slice(offset1, offset1 + itemsPerPage1);
  const currentPageData1 = noDataFilter
    ? filteredIncubatorList.slice(offset1, offset1 + itemsPerPage1)
    : incubatorList.slice(offset1, offset1 + itemsPerPage1);
  const totalPages = Math.ceil((incubatorList.length / itemsPerPage));
  // const totalPages1 = Math.ceil( (filteredIncubatorList.length / itemsPerPage1));
  const totalPages1 = noDataFilter
    ? Math.ceil(filteredIncubatorList.length / itemsPerPage1)
    : Math.ceil(incubatorList.length / itemsPerPage1);

  

  return (
    <>
      <div className="app-inside app-apply-incubator">
        <div className="status-list-wrapper mt-4">
        {loader && (
          <div className="spinner-border custom-spinner" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}
        <fieldset disabled={loader}>          
          <div className="container">

            <div className="row">
              <div className="col-md-6 text-left my-auto mt-3">
                <p className="dashboard mb-0 ml-1">Incubator Activation</p>
              </div>
              <div className="col-md-6 text-right mt-3">
                <div className="text-right">
                  <div className="d-flex searchDiv align-self-end justify-content-end">
                    <div className="d-flex searchDiv align-self-end mr-3">
                      <img src={search} className="search" alt="search" />
                      <input className="searchBar" type="text" placeholder="Search.." name="search" onKeyUp={searchList} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <br />
            <div className="app-inside-content">
              <div className="tab-pane active" id="tabs-1">
                <div className="table-scroll mb-4">
                  <table className="reviewTable userListTable" >
                    <thead>
                      <tr>
                        <th className="text-center table-heading custom-td-left">Incubator Application ID</th>
                        <th className="text-center table-heading">Incubator Name <br /> </th>
                        <th className="text-center table-heading">Active <br /> </th>
                        <th className="text-center table-heading">Incubator Logs</th>
                        <th className="text-center table-heading">Portfolio Requests</th>
                        <th className="text-center table-heading" onClick={sortClick} style={{cursor:'pointer'}}>
                          Startup Cancellation Requests
                          <div >
                            {checkCount%2 == 0 ? <>&#11205;</> : <>&#11206;</>}
                          
                        </div>
                        </th>
                        {/* <th className="text-center table-heading">Spoc Details</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {incubatorList.length === 0 || (filteredIncubatorList.length === 0 && noDataFilter !== '') ? <tr><td className="text-center" colSpan={8}>No data</td></tr> :
                        (filteredIncubatorList.length == 0 ?
                          currentPageData && currentPageData.map((item: any, id: any) => (
                            <tr key={id}>
                              <td className="text-center custom-td">{item.incubator_application_no == null || '' ? item.incubator_application_id : item.incubator_application_no}</td>
                              <td className="text-center custom-td">{item.registered_name}</td>

                              <td className="text-center custom-td slider-td">
                                &nbsp;&nbsp;&nbsp; <label className="switch">
                                  <input type="checkbox" checked={item.active_status == 0 ? false : true} onChange={(e: any) => handleAllCheck(e, item.incubator_application_id, item.incubator_id, item.application_type, item.incubator_application_no)} />
                                  <span className="slider round"></span>
                                </label>
                              </td>
                              <td className="text-center custom-td">{ }
                                <BsFillEyeFill onClick={() => { setShowReport(true); getVal(item.incubator_id, item.incubator_application_id, item.registered_name) }} />
                              </td>
                              <td className="text-center custom-td">{ }
                                {
                                  item.portfolio_status_update == 'Approved' ? <>
                                    <span>
                                      <i className="fa fa-check-circle" style={{ color: 'green' }}></i>
                                    </span>
                                  </> :
                                    item.portfolio_status_update == 'Rejected' ? <>
                                      <span>
                                        <i className="fa fa-times-circle" style={{ color: 'red' }}></i>
                                      </span>
                                    </> :
                                      item.portfolio_status != undefined && item.portfolio_status == 'Draft' && item.portfolio_status_update == '' ? <>
                                        <span>
                                          Draft
                                        </span>
                                      </> :
                                        item.portfolio_status != undefined && item.portfolio_status == 'Submitted' && item.portfolio_status_update == '' ?
                                          <span style={{}}
                                            onClick={() => { getVal(item.incubator_id, item.incubator_application_id, item.registered_name) }}
                                            data-target="#confirmationPopUp" data-toggle="modal">
                                            <i className="fa fa-exclamation-circle" style={{ color: '#F6BE00', fontSize: '16px' }}></i>
                                          </span> :
                                          'NA'
                                }
                              </td>
                              <td className="text-center custom-td">{ }
                                {item.cancellation_status_id == 4 ?
                                  <span
                                    onClick={() => { FetchCAncellationData(item.incubator_id, item.incubator_application_id, item.registered_name) }}
                                    data-target="#CancellationPopUp" data-toggle="modal">
                                    <i className="fa fa-exclamation-circle" style={{ color: 'blue', fontSize: '16px' }}></i>
                                  </span> :
                                  item.cancellation_status_id == 2 ?
                                    <span
                                      onClick={() => { FetchCAncellationData(item.incubator_id, item.incubator_application_id, item.registered_name) }}
                                      data-target="#CancellationPopUp" data-toggle="modal">
                                      <i className="fa fa-check-circle" style={{ color: 'green' }}></i>
                                    </span> :
                                    item.cancellation_status_id == 3 ?
                                      <span
                                        onClick={() => { FetchCAncellationData(item.incubator_id, item.incubator_application_id, item.registered_name) }}
                                        data-target="#CancellationPopUp" data-toggle="modal">
                                        <i className="fa fa-times-circle" style={{ color: 'red' }}></i>
                                      </span> :
                                      item.cancellation_status_id == 1 ?
                                        <>
                                          <span
                                            onClick={() => { FetchCAncellationData(item.incubator_id, item.incubator_application_id, item.registered_name) }}
                                            data-target="#CancellationPopUp" data-toggle="modal">
                                            {
                                              item.dates_diff>0 ? 

                                            <OverlayTrigger
                                              placement="top"
                                              overlay={<Tooltip id="example-tooltip" style={{ fontSize: '14px' }}>More than {item.no_of_days} days since cancellation requested</Tooltip>}
                                            >
                                              <button type="button" style={{border:'none',background:'rgb(241 60 4)',borderRadius:'5px'}} >
                                                <i className="fa fa-exclamation-circle" style={{ color: 'yellow', fontSize: '16px' }}></i>
                                              </button>
                                            </OverlayTrigger> : 
                                            <i className="fa fa-exclamation-circle" style={{ color: '#F6BE00', fontSize: '16px' }}></i>
                                            }
                                          </span>

                                        </>
                                        : 'NA'
                                }
                              </td>
                              {/* <td>
                                <img src={profile} alt="profile" />
                              </td> */}
                            </tr>
                          )) :
                          currentPageData1.map((item: any, id: any) => (
                            <tr key={id}>
                              <td className="text-center custom-td">{item.incubator_application_no == null || '' ? item.incubator_application_id : item.incubator_application_no}</td>
                              <td className="text-center custom-td">{item.registered_name}</td>

                              <td className="text-center custom-td slider-td">
                                &nbsp;&nbsp;&nbsp;<label className="switch">
                                  <input type="checkbox" checked={item.active_status == '0' ? false : true} onChange={(e: any) => handleAllCheck1(e, item.incubator_application_id, item.incubator_id, item.application_type, item.incubator_application_no)} />
                                  <span className="slider round"></span>
                                </label>
                              </td>
                              <td className="text-center custom-td">
                                <BsFillEyeFill onClick={() => { setShowReport(true); getVal(item.incubator_id, item.incubator_application_id, item.registered_name) }} />
                              </td>
                              <td className="text-center custom-td">{ }
                                {
                                  item.portfolio_status_update == 'Approved' ? <>
                                    <span>
                                      <i className="fa fa-check-circle" style={{ color: 'green' }}></i>
                                    </span>
                                  </> :
                                    item.portfolio_status_update == 'Rejected' ? <>
                                      <span>
                                        <i className="fa fa-times-circle" style={{ color: 'red' }}></i>
                                      </span>
                                    </> :
                                      item.portfolio_status != undefined && item.portfolio_status == 'Draft' && item.portfolio_status_update == '' ? <>
                                        <span>
                                          Draft
                                        </span>
                                      </> :
                                        item.portfolio_status != undefined && item.portfolio_status == 'Submitted' && item.portfolio_status_update == '' ?
                                          <span style={{}}
                                            onClick={() => { getVal(item.incubator_id, item.incubator_application_id, item.registered_name) }}
                                            data-target="#confirmationPopUp" data-toggle="modal">
                                            <i className="fa fa-exclamation-circle" style={{ color: '#F6BE00', fontSize: '16px' }}></i>
                                          </span> :
                                          'NA'
                                }
                              </td>
                              <td className="text-center custom-td">{ }
                                {item.cancellation_status_id == 4 ?
                                  <span
                                    onClick={() => { FetchCAncellationData(item.incubator_id, item.incubator_application_id, item.registered_name) }}
                                    data-target="#CancellationPopUp" data-toggle="modal">
                                    <i className="fa fa-exclamation-circle" style={{ color: 'blue', fontSize: '16px' }}></i>
                                  </span> :
                                  item.cancellation_status_id == 2 ?
                                    <span
                                      onClick={() => { FetchCAncellationData(item.incubator_id, item.incubator_application_id, item.registered_name) }}
                                      data-target="#CancellationPopUp" data-toggle="modal">
                                      <i className="fa fa-check-circle" style={{ color: 'green' }}></i>
                                    </span> :
                                    item.cancellation_status_id == 3 ?
                                      <span
                                        onClick={() => { FetchCAncellationData(item.incubator_id, item.incubator_application_id, item.registered_name) }}
                                        data-target="#CancellationPopUp" data-toggle="modal">
                                        <i className="fa fa-times-circle" style={{ color: 'red' }}></i>
                                      </span> :
                                      item.cancellation_status_id == 1 ?
                                        <span style={{}}
                                          onClick={() => { FetchCAncellationData(item.incubator_id, item.incubator_application_id, item.registered_name) }}
                                          data-target="#CancellationPopUp" data-toggle="modal">
                                          {
                                              item.dates_diff>0 ? 

                                            <OverlayTrigger
                                              placement="top"
                                              overlay={<Tooltip id="example-tooltip" style={{ fontSize: '14px' }}>More than {item.no_of_days} days since cancellation requested</Tooltip>}
                                            >
                                              <button type="button" style={{border:'none',background:'rgb(241 60 4)',borderRadius:'5px'}} >
                                                <i className="fa fa-exclamation-circle" style={{ color: 'yellow', fontSize: '16px' }}></i>
                                              </button>
                                            </OverlayTrigger> : 
                                            <i className="fa fa-exclamation-circle" style={{ color: '#F6BE00', fontSize: '16px' }}></i>
                                            }
                                        </span>
                                        : 'NA'
                                }
                              </td>
                              {/* <td>
                                <img src={profile} alt="profile" />
                              </td> */}
                            </tr>
                          ))
                        )}
                    </tbody>
                  </table>
                </div>
                {incubatorList.length == 0 ? <></>
                  :

                  filteredIncubatorList.length == 0 ?
                    <div style={{ float: 'right', display: 'flex', marginRight: '20px', alignItems: 'center', justifyContent: 'center' }}>

                      <span>
                        <label htmlFor="itemsPerPageSelect" className="itemsPerPage">Items per Page:</label>
                        <select
                          className="itemsPerPageSelect"
                          id="itemsPerPageSelect"
                          value={itemsPerPage}
                          onChange={handleItemsPerPageChange}
                        >
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="75">75</option>
                          <option value="100">100</option>
                        </select>
                      </span>&nbsp;&nbsp;&nbsp;

                      <span className="pagination" style={{ fontSize: '18px', marginTop: '10px' }}>
                        <ReactPaginate
                          key={itemsPerPage}
                          pageCount={totalPages}
                          pageRangeDisplayed={0}
                          marginPagesDisplayed={0}
                          previousLabel="Prev"
                          nextLabel="Next"
                          // breakLabel="..."
                          onPageChange={handlePageChange}
                          containerClassName="pagination"
                          activeClassName="active"
                          disableInitialCallback={true}
                          forcePage={currentPage}
                        />
                      </span>&nbsp;&nbsp;&nbsp;

                      <span className="pageNum">
                        Page {(currentPage) + 1} of {totalPages}
                      </span>
                      {/* &nbsp;&nbsp;&nbsp;<select value={currentPage} onChange={handleSelectedPageChange} style={{border:'0 0 1px solid black 0'}} >
                          {renderPageNumbers()}
                          </select>&nbsp;&nbsp;&nbsp; */}
                    </div>
                    : <>
                      <div style={{ float: 'right', display: 'flex', marginRight: '20px', alignItems: 'center', justifyContent: 'center' }}>

                        <span>
                          <label htmlFor="itemsPerPageSelect" className="itemsPerPage">Items per Page:</label>
                          <select
                            className="itemsPerPageSelect"
                            id="itemsPerPageSelect"
                            value={itemsPerPage1}
                            onChange={handleItemsPerPageChange}
                          >
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="75">75</option>
                            <option value="100">100</option>
                          </select>
                        </span>&nbsp;&nbsp;&nbsp;

                        <span className="pagination" style={{ fontSize: '18px', marginTop: '10px' }}>
                          <ReactPaginate
                            key={itemsPerPage1}
                            pageCount={totalPages1}
                            pageRangeDisplayed={0}
                            marginPagesDisplayed={0}
                            previousLabel="Prev"
                            nextLabel="Next"
                            // breakLabel="..."
                            onPageChange={handlePageChange}
                            containerClassName="pagination"
                            activeClassName="active"
                            disableInitialCallback={true}
                            forcePage={currentPage1}
                          />
                        </span>&nbsp;&nbsp;&nbsp;
                        {/* previousLabel="←"
                                  nextLabel="→" */}
                        <span className="pageNum">
                          Page {currentPage1 + 1} of {totalPages1}
                        </span>
                        {/* &nbsp;&nbsp;&nbsp;<select value={currentPage} onChange={handleSelectedPageChange} style={{border:'0 0 1px solid black 0'}} >
                              {renderPageNumbers()}
                              </select>&nbsp;&nbsp;&nbsp; */}
                      </div>
                    </>
                }
              </div>

            </div>
            <br /><br /><br />
          </div>
        </fieldset>

          {/* portfolio request modal */}
          <div
            className="modal fade"
            id="confirmationPopUp"
            role="dialog"
            aria-labelledby="exampleModalLabel1"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-md"
              role="document"
            >
              <div className="modal-content">
                {/* <div className="modal-header modal-header-bottom pr-5">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div> */}

                <div className="modal-body pl-3 pr-3">
                  <div className="popup-head mb-3">
                    <div className="row">
                      <div className="col-11">
                        <div className="text-center mb-2 mt-3" style={{ fontWeight: 'bolder', fontSize: '20px', textTransform: 'uppercase' }}>{getUserName}</div>
                      </div>
                      <div className="col-1">
                        <div
                          className="close text-right"
                          data-dismiss="modal"
                          aria-label="Close"
                        >

                          <span aria-hidden="true" style={{ fontSize: '36px', cursor: 'pointer' }}>×</span>
                        </div>
                      </div>
                    </div>

                    <br />
                    <div className="row">
                      <div className="col-4 text-left">
                        <button className="approve-btn"
                          data-toggle="modal" data-target="#ConfirmApprovePopup" onClick={() => confirmApprovePopup()}
                        >
                          Approve Request
                        </button>
                      </div>
                      <div className="col-4 text-center">
                        <button className="reject-btn"
                          data-toggle="modal" data-target="#ConfirmRejectPopup" onClick={() => confirmRejectPopup()}

                        >
                          Reject Request
                        </button>
                      </div>
                      <div className="col-4 text-right">
                        <button className="portfolio-btn"
                          onClick={() => preview_portfolio(getUserName, getAppId, getIncuberId)}
                        >
                          Portfolio Preview
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Cancellation request modal */}
          <div
            className="modal fade"
            id="CancellationPopUp"
            role="dialog"
            aria-labelledby="exampleModalLabel1"
            aria-hidden="true"
            style={{ overflowY: 'scroll' }}
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">

                <div className="modal-body modal-body-bgclr pl-3 pr-3 pt-0">
                  <div className="popup-head mb-3">
                    <div className="row cancel-main-heading">
                      <div className="col-11 p-0">
                        <div className="text-center mb-2 mt-3 " >
                          <h3 className="Cancellation_heading pb-3">
                            {getUserName}
                          </h3>
                        </div>
                      </div>
                      <div className="col-1">
                        <div
                          className="text-right modal_close_btn"
                          data-dismiss="modal"
                          aria-label="Close"
                          style={{ top: '-22px', right: '-16px' }}
                        >
                          <span aria-hidden="true" className="close_Btn">×</span>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="p-3">
                      <table className="table-bordered">
                        <thead>
                          <th className="text-center" style={{ fontSize: '14px', fontWeight: 'bold', backgroundColor: '#25429C', color: '#FFFF' }} >Startup Name</th>
                          <th className="text-center" style={{ fontSize: '14px', fontWeight: 'bold', backgroundColor: '#25429C', color: '#FFFF' }} colSpan={2}>Action</th>
                          <th className="text-center" style={{ fontSize: '14px', fontWeight: 'bold', backgroundColor: '#25429C', color: '#FFFF' }} >View Proofs</th>
                        </thead>
                        <tbody style={{ overflowY: 'scroll' }}>
                          {
                            cancellationList.map((item: any, i) =>

                              <tr key={i}>
                                <td>
                                  {item.startup_name}
                                </td>
                                {
                                  item.status_id == 2 ?
                                    <td colSpan={2} className="text-center">
                                      <span style={{ fontSize: '14px', fontWeight: 'bold', color: 'green', cursor: 'pointer' }}
                                      >
                                        Request approved on {moment(item.cancelled_on).format("YYYY-MM-DD")}
                                      </span>
                                    </td> :
                                    item.status_id == 3 ?
                                      <td colSpan={2} className="text-center">
                                        <span style={{ fontSize: '14px', fontWeight: 'bold', color: 'red', cursor: 'pointer' }}
                                        >
                                          Request rejected on {moment(item.rejected_on).format("YYYY-MM-DD")}
                                        </span>
                                      </td> :
                                      <>
                                        <td>
                                          <span style={{ fontSize: '14px', fontWeight: 'bold', color: 'green', cursor: 'pointer' }}
                                            data-toggle="modal" data-target="#CancellationApprovePopup" onClick={() => cancellationAccept(item.startup_application_id, item.cancelId)}
                                          >
                                            Approve request
                                          </span>
                                        </td>
                                        <td>
                                          <span style={{ fontSize: '14px', fontWeight: 'bold', color: 'red', cursor: 'pointer' }}
                                            data-toggle="modal" data-target="#CancellationRejectPopup" onClick={() => cancellationAccept(item.startup_application_id, item.cancelId)}
                                          >
                                            Reject request
                                          </span>
                                        </td>
                                      </>
                                }
                                <td className="text-center">
                                  <span style={{ fontSize: '14px', fontWeight: 'bold', textDecoration: 'underline', cursor: 'pointer' }}
                                    data-toggle="modal"
                                    data-target="#CancellationRequest"
                                    onClick={() => viewProofs(item.cancelId, item.startup_name)}

                                  >
                                    <img src={view} alt="view" />
                                  </span>
                                </td>
                              </tr>
                            )
                          }
                        </tbody>
                      </table>

                    </div>

                    <div className="p-3 pb-0">
                      <div className="row">
                        <div className="col-12 text-right">
                          <button
                            className="btn btn-sisfs btn-yellow-primary"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            Back To Incubator Activation
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="CancellationRequest"
            role="dialog"
            aria-labelledby="exampleModalLabel1"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body pl-3 pr-3 modal-body-bgclr pt-0">
                  <div className="row cancel-main-heading">
                    <div className="col-md-12 text-center">
                      <h4 className="Cancellation_heading pb-3 pt-3">
                        {get_st_name.toLocaleUpperCase()}
                      </h4>
                    </div>
                  </div>
                  <div className="form-wrapper contact-form">
                    <div className="form-group row mb-0 mt-3">
                      <div className="col"></div>
                      <label className="col-sm-12 col-md-5 col-form-label text-left list_tags" style={{ fontSize: '14px' }}>
                        Reason for cancellation startup <span className='required'>*</span>
                      </label>
                      <div className="col-sm-12 col-md-6">
                        <textarea
                          disabled
                          name="reason"
                          id="reason"
                          className="form-control"
                          value={viewCancellationData.reason}
                        >
                        </textarea>
                      </div>
                      <div className="col"></div>
                    </div>
                    <div className="form-group row mb-0 mt-3">
                      <div className="col"></div>
                      <label className="col-sm-12 col-md-5 col-form-label text-left list_tags" style={{ fontSize: '14px' }}>
                        Attach mail trail between incubator and startup <span className='required'>*</span>
                      </label>
                      <div className="col-sm-12 col-md-6 d-flex">
                        <span className="form-control">
                          <img src={pdfImg} alt="" /> &nbsp;
                          <a href={NODE_URL + viewCancellationData.mail_trail_path} target="_blank">
                            {getFileDetails(viewCancellationData.mail_trail_path, 'name').length > 18 ?
                              getFileDetails(viewCancellationData.mail_trail_path, 'name').slice(0, 18) + "...pdf" :
                              getFileDetails(viewCancellationData.mail_trail_path, 'name')
                            }</a>
                        </span>

                      </div>
                      <div className="col"></div>
                    </div>
                    <div className="form-group row mb-0 mt-3">
                      <div className="col"></div>
                      <label className="col-sm-12 col-md-5 col-form-label text-left list_tags" style={{ fontSize: '14px' }}>
                        Attach minutes of the ISMC meeting where startup cancellation was approved<span className='required'>*</span>
                      </label>
                      <div className="col-sm-12 col-md-6 d-flex">
                        <span className="form-control">
                          <img src={pdfImg} alt="" /> &nbsp;
                          <a href={NODE_URL + viewCancellationData.ismc_file_path} target="_blank">
                            {getFileDetails(viewCancellationData.ismc_file_path, 'name').length > 18 ?
                              getFileDetails(viewCancellationData.ismc_file_path, 'name').slice(0, 18) + "...pdf" :
                              getFileDetails(viewCancellationData.ismc_file_path, 'name')
                            }
                          </a>
                        </span>
                      </div>
                      <div className="col"></div>
                    </div>

                    <div className="form-group row mb-0 mt-3">
                      <div className="col"></div>
                      <label className="col-sm-12 col-md-5 col-form-label text-left list_tags" style={{ fontSize: '14px' }}>
                        Additional comments <span className='required'>*</span>
                      </label>
                      <div className="col-sm-12 col-md-6">
                        <textarea
                          disabled
                          name="comments"
                          id="comments"
                          className="form-control"
                          value={viewCancellationData.incubator_comments}
                        >
                        </textarea>
                      </div>
                      <div className="col"></div>
                    </div>
                    <div className="form-group row mb-0 mt-3">
                      <div className="col"></div>
                      <label className="col-sm-12 col-md-5 col-form-label text-left list_tags" style={{ fontSize: '14px' }}>
                        Requested Date
                      </label>
                      <div className="col-sm-12 col-md-6">
                        <input
                          disabled
                          className="form-control"
                          value={moment(viewCancellationData.request_date).format("DD-MM-YYYY hh:mm a")}

                        />
                      </div>
                      <div className="col"></div>
                    </div>
                    {
                      (viewCancellationData.status_id == '3' || viewCancellationData.status_id == '2') &&
                      <>
                        <div className="form-group row mb-0 mt-3">
                          <div className="col"></div>
                          <label className="col-sm-12 col-md-5 col-form-label text-left list_tags" style={{ fontSize: '14px' }}>
                            File Uploaded by the Secretariat
                          </label>
                          <div className="col-sm-12 col-md-6 d-flex">
                            <span className="form-control">
                              {/* <img src={pdfImg} alt="" /> &nbsp;
                          <a href={NODE_URL + viewCancellationData.cancellation_status_file_path} target="_blank">
                            {getFileDetails(viewCancellationData.cancellation_status_file_path, 'name').length > 18 ?
                              getFileDetails(viewCancellationData.cancellation_status_file_path, 'name').slice(0, 18) + "...pdf" :
                              getFileDetails(viewCancellationData.cancellation_status_file_path, 'name')
                            }
                          </a> */}
                              {viewCancellationData.cancellation_status_file_path ? (
                                <>
                                  <img src={pdfImg} alt="" /> &nbsp;
                                  <a href={NODE_URL + viewCancellationData.cancellation_status_file_path} target="_blank">
                                    {getFileDetails(viewCancellationData.cancellation_status_file_path, 'name').length > 18 ?
                                      getFileDetails(viewCancellationData.cancellation_status_file_path, 'name').slice(0, 18) + "...pdf" :
                                      getFileDetails(viewCancellationData.cancellation_status_file_path, 'name')
                                    }
                                  </a>
                                </>
                              ) : null}

                            </span>
                          </div>
                          <div className="col"></div>
                        </div>
                      </>
                    }
                    {
                      (viewCancellationData.status_id == '3' || viewCancellationData.status_id == '2') &&
                      <>
                        <div className="form-group row mb-0 mt-3">
                          <div className="col"></div>
                          <label className="col-sm-12 col-md-5 col-form-label text-left list_tags" style={{ fontSize: '14px' }}>
                            {viewCancellationData.status_id == '3' ? 'Reason for rejecting the request' : 'Reason for approving the request'}
                          </label>
                          <div className="col-sm-12 col-md-6">
                            <textarea
                              disabled
                              name="rejectedComment"
                              id="rejectedComment"
                              className="form-control"
                              value={viewCancellationData.rejected_comment != null ?
                                viewCancellationData.rejected_comment
                                  .substring(viewCancellationData.rejected_comment
                                    .indexOf("Secretariat, Reason: ") + "Secretariat, Reason: ".length) : ''}
                            >
                            </textarea>
                          </div>
                          <div className="col"></div>
                        </div>
                      </>
                    }
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-12 text-center">
                      <button className="btn btn-sisfs btn-yellow-primary" onClick={closeSTModal}>Close</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Cancellation approve confirmation popup */}
          <div className="modal fade" id="CancellationApprovePopup" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal align-middle modal-sisfs d-block" id="FormSubmissionError">
                  <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                      <div style={{ textAlign: 'right', }}>
                        <i className="fa fa-close" data-dismiss="modal" style={{ cursor: 'pointer', fontSize: '30px', marginRight: '15px', marginTop: '15px', color: '#D3D3D3' }}></i>
                      </div>
                      <div className="modal-body text-center pt-0">
                        <h4 className="modal-message btn" style={{ color: '#2b3f88', }}>
                          {/* message-orange */}
                          Are you sure you want to approve the request?</h4>
                        <div className="form-wrapper contact-form">
                          <div className="form-group row mb-0 mt-2">
                            <label className="col-sm-12 col-md-4 col-form-label text-left list_tags" style={{ fontSize: '14px' }}>
                              Upload <span className='required'>*</span>
                              <small className="p-0"><i>(pdf only - 3Mb)</i></small>
                            </label>
                            <div className="col-sm-12 col-md-8 d-flex">
                              <input type="file" id="uploadReason" hidden accept=".pdf" className="form-control" name="uploadReason" onChange={(e) => handleChange(e)} />
                              <label htmlFor="uploadReason" style={{ marginBottom: '0px' }}><img src={fileupload} className="form-control" alt="uploadReason" style={{ cursor: 'pointer', height: '100%' }} /></label>
                              <span className="form-control">{uploadReason != undefined && uploadReason.name.length > 15 ?
                                uploadReason.name.slice(0, 15) + "...pdf" : uploadReason != undefined && uploadReason.name}</span>

                            </div>
                            <div className="col-sm-12 col-md-12 text-right">
                              {
                                checkReason && uploadReason == undefined &&
                                <small className="text-danger pt-1" style={{ textAlign: 'right' }}>
                                  <i>Please upload file</i>
                                </small>
                              }
                            </div>
                          </div>
                          <div className="form-group row mb-0 mt-4">
                            {/* <div className="col"></div> */}
                            <label className="col-sm-12 col-md-4 col-form-label text-left list_tags" style={{ fontSize: '14px' }}>
                              Reason <span className='required'>*</span>
                            </label>
                            <div className="col-sm-12 col-md-8 d-flex">
                              <textarea name="rejectReason"
                                id="rejectReason"
                                placeholder="Please enter the reason for approval"
                                className="form-control"
                                onChange={(e) => handleChange(e)}
                              ></textarea>
                            </div>
                            <div className="col-sm-12 col-md-12 text-right">
                              {(checkReason && rejectReasonTxt == '') && <small className="text-danger pt-1" style={{ textAlign: 'right' }}>
                                <i>Please enter the reason</i>
                              </small>}
                            </div>
                          </div>
                        </div>
                        <div className="modal-action" style={{ marginBottom: '0px' }}>
                          <button className="btn btn-sisfs btn-yellow-primary" style={{ marginLeft: '15px' }}
                            onClick={() => approve_cancellation_request(get_st_appID, get_cancelId)}
                          >
                            YES
                          </button>
                          <button className="btn btn-sisfs" onClick={dismisModal} data-dismiss="modal" style={{ marginLeft: '15px', color: '#2b3f88', border: "1px solid #2b3f88" }}  >
                            NO
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*Cancellation reject confirmation popup */}
          <div className="modal fade" id="CancellationRejectPopup" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal align-middle modal-sisfs d-block" id="FormSubmissionError">
                  <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                      <div style={{ textAlign: 'right', }}>
                        <i className="fa fa-close" data-dismiss="modal" style={{ cursor: 'pointer', fontSize: '30px', marginRight: '15px', marginTop: '15px', color: '#D3D3D3' }}></i>
                      </div>
                      <div className="modal-body text-center pt-0">
                        <h4 className="modal-message btn" style={{ color: '#2b3f88', }}>
                          {/* message-orange */}
                          Are you sure you want to reject the request?</h4>
                        <div className="form-wrapper contact-form">
                          <div className="form-group row mb-0 mt-2">
                            <label className="col-sm-12 col-md-4 col-form-label text-left list_tags" style={{ fontSize: '14px' }}>
                              Upload <span className='required'>*</span>
                              <small className="p-0"><i>(pdf only - 3Mb)</i></small>
                            </label>
                            <div className="col-sm-12 col-md-8 d-flex">
                              <input type="file" id="uploadReason" hidden accept=".pdf" className="form-control" name="uploadReason" onChange={(e) => handleChange(e)} />
                              <label htmlFor="uploadReason" style={{ marginBottom: '0px' }}><img src={fileupload} className="form-control" alt="uploadReason" style={{ cursor: 'pointer', height: '100%' }} /></label>
                              <span className="form-control">{uploadReason != undefined && uploadReason.name.length > 15 ?
                                uploadReason.name.slice(0, 15) + "...pdf" : uploadReason != undefined && uploadReason.name}</span>

                            </div>
                            <div className="col-sm-12 col-md-12 text-right">
                              {
                                checkReason && uploadReason == undefined &&
                                <small className="text-danger pt-1" style={{ textAlign: 'right' }}>
                                  <i>Please upload file</i>
                                </small>
                              }
                            </div>
                          </div>
                          <div className="form-group row mb-0 mt-4">
                            {/* <div className="col"></div> */}
                            <label className="col-sm-12 col-md-4 col-form-label text-left list_tags" style={{ fontSize: '14px' }}>
                              Reason <span className='required'>*</span>
                            </label>
                            <div className="col-sm-12 col-md-8 d-flex">
                              <textarea name="rejectReason"
                                id="rejectReason"
                                placeholder="Please enter the reason for rejection"
                                className="form-control"
                                onChange={(e) => handleChange(e)}
                              ></textarea>
                            </div>
                            <div className="col-sm-12 col-md-12 text-right">
                              {(checkReason && rejectReasonTxt == '') && <small className="text-danger pt-1" style={{ textAlign: 'right' }}>
                                <i>Please enter the reason</i>
                              </small>}
                            </div>
                          </div>
                        </div>
                        <div className="modal-action" style={{ marginBottom: '0px' }}>
                          <button className="btn btn-sisfs btn-yellow-primary" style={{ marginLeft: '15px', borderRadius: '0 !important' }}
                            onClick={() => reject_cancellation_request(get_st_appID, get_cancelId)}
                          >
                            YES
                          </button>
                          <button className="btn btn-sisfs" onClick={dismisModal} data-dismiss="modal" style={{ marginLeft: '15px', color: '#2b3f88', border: "1px solid #2b3f88" }}  >
                            NO
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* approved popup */}
          <div className="modal fade" id="approvePopUp" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal align-middle modal-sisfs d-block" id="FormSubmissionError">
                  <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                      <br />
                      <div style={{ textAlign: 'center' }}>
                        <i className="fa fa-check-circle-o" style={{ fontSize: '42px', color: 'green' }}></i>
                        {/* <span aria-hidden="true" style={{ cursor: 'pointer', fontSize: '30px', marginRight: '15px', marginTop: '15px' }}>×</span> */}
                        {/* <i className="fa fa-close" data-dismiss="modal" style={{ cursor: 'pointer', fontSize: '30px', marginRight: '15px', marginTop: '15px', color: '#D3D3D3' }}></i> */}
                      </div>
                      <div className="modal-body text-center pt-0">
                        <h4 className="modal-message mt-3" style={{ color: '#2b3f89', }}>
                          {/* message-orange */}
                          Request Approved Successfully
                        </h4>
                        <div className="modal-action">
                          <button className="btn btn-sisfs btn-yellow-primary" data-dismiss="modal" onClick={closeModal}>
                            OK!
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* rejected popup */}
          <div className="modal fade" id="rejectPopUp" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal align-middle modal-sisfs d-block" id="FormSubmissionError">
                  <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                      <br />
                      <div style={{ textAlign: 'center' }}>
                        <span>
                          <img src={error} alt="" />
                        </span>
                        {/* <span aria-hidden="true" style={{ cursor: 'pointer', fontSize: '30px', marginRight: '15px', marginTop: '15px' }}>×</span> */}
                        {/* <i className="fa fa-close" data-dismiss="modal" style={{ cursor: 'pointer', fontSize: '30px', marginRight: '15px', marginTop: '15px', color: '#D3D3D3' }}></i> */}
                      </div>
                      <div className="modal-body text-center pt-0">
                        <h4 className="modal-message mt-3" style={{ color: '#2b3f89', }}>
                          {/* message-orange */}
                          Portfolio Request Rejected
                        </h4>
                        <div className="modal-action">
                          <button className="btn btn-sisfs btn-yellow-primary" data-dismiss="modal" onClick={closeModal}>
                            OK!
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* approve confirmation popup */}
          <div className="modal fade" id="ConfirmApprovePopup" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal align-middle modal-sisfs d-block" id="FormSubmissionError">
                  <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                      <div style={{ textAlign: 'right', }}>
                        <i className="fa fa-close" data-dismiss="modal" style={{ cursor: 'pointer', fontSize: '30px', marginRight: '15px', marginTop: '15px', color: '#D3D3D3' }}></i>
                      </div>
                      <div className="modal-body text-center pt-0">
                        <h4 className="modal-message btn" style={{ color: '#2b3f88', }}>
                          {/* message-orange */}
                          Are you sure you want to Approve the Request?</h4>
                        <div className="modal-action" style={{ marginBottom: '0px' }}>
                          <button className="btn btn-sisfs btn-yellow-primary" style={{ marginLeft: '15px' }} onClick={() => approve_request(getUserName, getAppId, getIncuberId)}>
                            YES
                          </button>
                          <button className="btn btn-sisfs" onClick={backToConformPopup} data-dismiss="modal" style={{ marginLeft: '15px', color: '#2b3f88', border: "1px solid #2b3f88" }}  >
                            NO
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* reject confirmation popup */}
          <div className="modal fade" id="ConfirmRejectPopup" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal align-middle modal-sisfs d-block" id="FormSubmissionError">
                  <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                      <div style={{ textAlign: 'right', }}>
                        <i className="fa fa-close" data-dismiss="modal" style={{ cursor: 'pointer', fontSize: '30px', marginRight: '15px', marginTop: '15px', color: '#D3D3D3' }}></i>
                      </div>
                      <div className="modal-body text-center pt-0">
                        <h4 className="modal-message btn" style={{ color: '#2b3f88', }}>
                          {/* message-orange */}
                          Are you sure you want to Reject the Request?</h4>
                        <div className="modal-action" style={{ marginBottom: '0px' }}>
                          <button className="btn btn-sisfs btn-yellow-primary" style={{ marginLeft: '15px', borderRadius: '0 !important' }} onClick={() => reject_request(getUserName, getAppId, getIncuberId)}>
                            YES
                          </button>
                          <button className="btn btn-sisfs" onClick={backToConformPopup} data-dismiss="modal" style={{ marginLeft: '15px', color: '#2b3f88', border: "1px solid #2b3f88" }}  >
                            NO
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* preview portfolio modal                      */}
          <div
            className="modal fade"
            id="previewPageModal"
            role="dialog"
            aria-labelledby="exampleModalLabel1"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-xl"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header modal-header-bottom pr-5">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>

                <div className="modal-body pl-3 pr-5 pt-4">
                  <div className="popup-head mb-5 ml-3">
                    <p style={{ fontWeight: 'bolder', textAlign: 'center', fontSize: '22px' }}>Preview of Portfolio</p>

                  </div>

                  {/* {Object.keys(dataPortfolio).length > 0 &&
                    <div className="mt-5 mb-5 portfolio-detail-container">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-3">
                            <div className="portFolioGrid">

                              <div className="portImageBlock">
                                <img
                                  src={`${NODE_URL}${portfolioDetails.cover_image}`}
                                  alt={portfolioDetails.incubator_name}
                                  className="portImage"
                                />
                              </div>
                              <div className="portContentBlock">
                                <p className="portCity">
                                  {dataPortfolio.city},{" " + dataPortfolio.state}
                                </p>
                                <h1 className="portHeading twoLine">
                                  {portfolioDetails.incubator_name}
                                </h1>
                                <p className="portDescription twoLine">
                                  {portfolioDetails?.message}
                                </p>

                                {dataPortfolio.sectors.length > 0 && (
                                  <p className="portTag">
                                    <span>{dataPortfolio.sectors[0]}</span>
                                    {dataPortfolio.sectors.length > 1 && (
                                      <span>
                                        {" "}
                                        +{dataPortfolio.sectors.length - 1} More{" "}
                                      </span>
                                    )}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col">
                            <div className="d-flex justify-content-between mb-4">
                              <div className="logo">
                                <img src={`${NODE_URL}${dataPortfolio.logo}`} alt={dataPortfolio.incubator_name} className="portLogo" />
                              </div>
                              <div className="website">
                                <button className="btn btn-sisfs btn-transparent-primary mt-4" onClick={() => window.open(dataPortfolio.site_url)}>
                                  Visit Website
                                </button>
                              </div>
                            </div>


                            <h1 className="main-heading portDetailHeading" style={{ fontSize: '14px', lineHeight: '1.2' }}>{dataPortfolio.incubator_name}</h1>

                            <div className="col-md-12">
                              <div className="row">
                                <p className="portCity mr-1 pt-1">{dataPortfolio.city},{' ' + dataPortfolio.state}|</p>
                                {!showMore &&
                                  <p className="portTagDetail ml-4" style={{ display: 'contents', fontSize: '13px' }}>
                                    {dataPortfolio.sectors.length > 0 && dataPortfolio?.sectors.map((sector: string, index: number) => {
                                      if (index < 4) {
                                        return (
                                          <Fragment key={index}>
                                            <span className="mr-1">{sector}</span>
                                          </Fragment>
                                        )
                                      }
                                    })}

                                  </p>}
                                {showMore &&
                                  <p className="portTagDetail ml-4" style={{ display: 'contents', fontSize: '13px' }}>
                                    {dataPortfolio.sectors.length > 0 && dataPortfolio?.sectors.map((sector: string, index: number) => {
                                      return (
                                        <Fragment key={index}>
                                          <span className="mr-1">{sector}</span>
                                        </Fragment>
                                      )
                                    })}

                                  </p>}
                                {!showMore && (dataPortfolio.sectors.length > 4) && <span className="link-orange-text d-block pt-1 pl-3" style={{ fontSize: '13px' }} onClick={() => setShowMore(true)}>View More</span>}
                              </div>

                              <div className="mainImage">
                                <img src={`${NODE_URL}${dataPortfolio.image}`} alt={dataPortfolio.incubator_name} className="portImageMain" />
                              </div>
                            </div>

                            <div className="main-heading right-heading mt-1" style={{ fontSize: '20px' }}>About</div>
                            <p className="para-text mb-2" style={{ fontSize: '14px' }}>
                              {dataPortfolio.description}
                            </p>
                          </div>
                          <div className="col-4">

                            <div className="main-heading right-heading" style={{ fontSize: '20px' }}>Key Features</div>

                            <div className="key">
                              <div className="ordered-list wrapper about-ordered-list">
                                <ol>
                                  {dataPortfolio.key_features.length > 0 && dataPortfolio.key_features.map((feature: string, index: number) => {
                                    return (
                                      <Fragment key={index}>
                                        <li className="mb-0">
                                          <span style={{ fontSize: '14px', lineHeight: '25px' }}>{feature}</span>
                                        </li>
                                      </Fragment>
                                    )
                                  })}
                                </ol>
                              </div>

                            </div>

                            <div className="main-heading text-left mt-3" style={{fontSize:'20px'}}>Photo Gallery</div>
                            <div className="galleryCarousel">

                              <div id="carouselExampleIndicators" className="carousel" data-ride="carousel">
                                <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                  <span className="sr-only">Previous</span>
                                </a>
                                <ol className="carousel-indicators">
                                  {dataPortfolio.image_data.map((image: string, index: number) => {
                                    if (index == 0) {
                                      return (
                                        <li data-target="#carouselExampleIndicators" data-slide-to={index} className="active" key={index}></li>
                                      )
                                    }
                                    else {
                                      return (
                                        <li data-target="#carouselExampleIndicators" data-slide-to={index} key={index}></li>
                                      )
                                    }
                                  })}
                                </ol>
                                <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                  <span className="sr-only">Next</span>
                                </a>

                                <div className="carousel-inner carousel-overflow">
                                  {dataPortfolio.image_data.map((image: string, index: number) => {
                                    if (index == 0) {
                                      return (
                                        <div className="carousel-item active" key={index}>
                                          <img className="d-block w-100 corousel-image" src={`${NODE_URL}${image}`} alt="First slide" />
                                        </div>
                                      )
                                    }
                                    else {
                                      return (
                                        <div className="carousel-item" key={index}>
                                          <img className="d-block w-100 corousel-image" src={`${NODE_URL}${image}`} alt="First slide" />
                                        </div>
                                      )
                                    }

                                  })}



                                </div>



                              </div>

                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row mt-2">
                          <div className="col-3 text-center" style={{ fontWeight: 'bolder' }}>Tile View</div>
                          <div className="col text-center" style={{ fontWeight: 'bolder' }}>Inside Preview Part-1</div>
                          <div className="col-4 text-center" style={{ fontWeight: 'bolder' }}>Inside Preview Part-2</div>
                        </div>


                      </div>
                    </div>
                  } */}

                  <Fragment>

                    {Object.keys(dataPortfolio).length > 0 &&
                      <div className="container mt-5 mb-5 portfolio-detail-container">
                        <div className="col-md-12">

                          <div className="d-flex justify-content-between mb-4">
                            <div className="logo">
                              <img src={`${NODE_URL}${dataPortfolio.logo}`} alt={dataPortfolio.incubator_name} className="portLogo" />
                            </div>
                            <div className="website">
                              <button className="btn btn-sisfs btn-transparent-primary mt-4" onClick={() => window.open(dataPortfolio.site_url)}>
                                Visit Website
                              </button>
                            </div>
                          </div>


                          <h1 className="main-heading portDetailHeading">{dataPortfolio.incubator_name}</h1>

                          <div className="col-md-12">
                            <div className="row">
                              <p className="portCity mr-1 pt-1">{dataPortfolio.city},{' ' + dataPortfolio.state}|</p>
                              {!showMore &&
                                <p className="portTagDetail ml-4" style={{ display: 'contents' }}>
                                  {dataPortfolio.sectors.length > 0 && dataPortfolio?.sectors.map((sector: string, index: number) => {
                                    if (index < 4) {
                                      return (
                                        <Fragment key={index}>
                                          <span className="mr-1">{sector}</span>
                                        </Fragment>
                                      )
                                    }
                                  })}

                                </p>}
                              {showMore &&
                                <p className="portTagDetail ml-4" style={{ display: 'contents' }}>
                                  {dataPortfolio.sectors.length > 0 && dataPortfolio?.sectors.map((sector: string, index: number) => {
                                    return (
                                      <Fragment key={index}>
                                        <span className="mr-1">{sector}</span>
                                      </Fragment>
                                    )
                                  })}

                                </p>}
                              {!showMore && (dataPortfolio.sectors.length > 4) && <span className="link-orange-text d-block pt-1 pl-3" onClick={() => setShowMore(true)}>View More</span>}
                            </div>

                            <div className="mainImage">
                              <img src={`${NODE_URL}${dataPortfolio.image}`} alt={dataPortfolio.incubator_name} className="portImageMain" />
                            </div>
                          </div>


                          {/* <div className="portImageBlock">
                <img src="" className="portImage" />
            </div> */}

                          <div className="main-heading right-heading mt-4">About</div>
                          <p className="para-text mb-2">
                            {dataPortfolio.description}
                          </p>


                          <div className="main-heading right-heading mt-5">Key Features</div>

                          <div className="key">
                            <div className="ordered-list wrapper about-ordered-list">
                              <ol>
                                {dataPortfolio.key_features.length > 0 && dataPortfolio.key_features.map((feature: string, index: number) => {
                                  return (
                                    <Fragment key={index}>
                                      <li>
                                        {feature}
                                      </li>
                                    </Fragment>
                                  )
                                })}
                              </ol>
                            </div>

                          </div>

                          <div className="main-heading text-center mt-5 mb-4">Photo Gallery</div>
                          <div>

                            <div id="carouselExampleIndicators" className="carousel" data-ride="carousel">
                              <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                              </a>
                              <ol className="carousel-indicators">
                                {dataPortfolio.image_data.map((image: string, index: number) => {
                                  if (index == 0) {
                                    return (
                                      <li data-target="#carouselExampleIndicators" data-slide-to={index} className="active" key={index}></li>
                                    )
                                  }
                                  else {
                                    return (
                                      <li data-target="#carouselExampleIndicators" data-slide-to={index} key={index}></li>
                                    )
                                  }
                                })}
                                {/* <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li> */}
                              </ol>
                              <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                              </a>

                              <div className="carousel-inner carousel-overflow">
                                {dataPortfolio.image_data.map((image: string, index: number) => {
                                  if (index == 0) {
                                    return (
                                      <div className="carousel-item active" key={index}>
                                        <img className="d-block w-100 corousel-image" src={`${NODE_URL}${image}`} alt="First slide" />
                                      </div>
                                    )
                                  }
                                  else {
                                    return (
                                      <div className="carousel-item" key={index}>
                                        <img className="d-block w-100 corousel-image" src={`${NODE_URL}${image}`} alt="First slide" />
                                      </div>
                                    )
                                  }

                                })}
                                {/* <div className="carousel-item active">
                            <img className="d-block w-100" src={data.image} alt="First slide" />
                        </div>
                        <div className="carousel-item">
                            <img className="d-block w-100" src={data.image} alt="Second slide" />
                        </div>
                        <div className="carousel-item">
                            <img className="d-block w-100" src={data.image} alt="Third slide" />
                        </div> */}



                              </div>



                            </div>

                          </div>
                          {/* <div className="text-center">
                <button className="btn btn-sisfs btn-transparent-primary mt-4" onClick={() => window.open(data.site_url)}>
                    Visit Website
                </button>
            </div> */}

                        </div>
                      </div>
                    }

                  </Fragment>
                </div>
              </div>
            </div>
          </div>
          <Modal
            show={showReport}
            onHide={() => setShowReport(false)}
            dialogClassName="modal-lg modal-dialog-scrollable  table-hover"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header >
              <Modal.Title id="example-custom-modal-styling-title text-center">
                Incubator Information
              </Modal.Title>
              <button type="button" onClick={() => setShowReport(false)} className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </Modal.Header>

            <Modal.Body>
              <IncubatorPrefrenceInformationComponent incubatorId={getIncuberId} appId={getAppId} userName={getUserName} />
            </Modal.Body>
            {/* 
            <div className=" schedule-button-pd text-right pr-3 buttonCss">
              <button
                className="btn btn-sisfs btn-yellow-primary"
                data-toggle="modal"
                data-target="#scheduleMeeting"
                onClick={() => setShowReport(false)}
              >
                Close
              </button>
            </div> */}
          </Modal>

        </div>
      </div>
    </>
  )
}
