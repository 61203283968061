import React, { useEffect, useState } from "react";
import { AuthorizedRepresentativeModel } from "../../../models/startup/AuthorizedRepresentativeStartupModel";
import { initAuthorizedRepresentative } from "../../../models/startup/StartupModel";
import {
  requestType,
  StartupUploadTypeEnum,
  StepperStartupEnum,
  UploadTypeEnum,
} from "../../../utils/Enums";
import { useErrorStateValue } from "../../../providers/ErrorProvider";
import ValidationMessage from "../../common/controls/ValidationMessage";
import { downloadDocument } from "../../../utils/CommonMethods";
import {
  EVALUATION_FILE,
  File_base_url,
  File_node_url,
  NEW_EVALUATION_FILE,
  NODE_URL,
  STARTUP_DETAIL_UPLOAD,
  STARTUP_FILE_REMOVE,
} from "../../../constants/api";
import { get, post } from "../../../effects/api/api.common";
import FileUploader from "@amodv/react-file-uploader";
import {
  FileUploadModel,
  FileUploadTypeModel,
} from "../../../models/FileUploadModel";
import { useHistory } from "react-router-dom";
import ConfirmationDialog from "../../common/controls/ConfirmationDialog";
import { userInfo } from "os";
import { getSessionStorageValue } from "../../../effects/utils/session-storage";
import pdf from "../../../images/PDF.svg";
import xls from "../../../images/xls.svg";
import doc from "../../../images/doc.svg";
import xlsx from "../../../images/xls.svg";
import Allround from "../../../images/Allround.svg";

interface IStartupRepresentativeProps {
  stepper: any;
  authorizedRepresentativeModel: AuthorizedRepresentativeModel;
  tabIndex: number;
  onStepSave: (
    authorizedRepresentative: AuthorizedRepresentativeModel,
    stepIndex: number,
    request: number
  ) => void;
  onChildUpdate: (
    authorizedRepresentative: AuthorizedRepresentativeModel
  ) => void;
  onPreviousClick: () => void;
  onUpdateApplicationID: any;
  errors: Array<string>;
  applicationID: any;
  disabledForm: any;
}

interface IStartupRepresentativeState { }
interface IIKeyValueIndexer {
  [key: string]: boolean;
}
/** Render start-up application screen */
function AuthorizedRepresentativeComponent(props: IStartupRepresentativeProps) {
  const {
    stepper,
    onChildUpdate,
    onUpdateApplicationID,
    authorizedRepresentativeModel,
    applicationID,
    disabledForm,
  } = props;

  // @ts-ignore
  const [{ errors }, dispatchError] = useErrorStateValue();
  const [pdfOnly, setPdfOnly] = useState<IIKeyValueIndexer>();
  const [authorizedRepresentative, setAuthorizedRepresentative] =
    useState<AuthorizedRepresentativeModel>(initAuthorizedRepresentative);
  const [authorisationLetterPath, setAuthorisationLetterPath] = useState("");
  const [openUploadBlock, setOpenUploadBlock] = useState<IIKeyValueIndexer>();
  const [loader, setLoader] = useState(false);
  const [sampleFile, setSampleFile] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [failDialogData, setFailDialogData] = useState({
    header: "",
    text: "",
  });
  const [showIncubatorFailureModal1, setShowIncubatorFailureModal1] =
    useState(false);
  useEffect(() => {
    setAuthorizedRepresentative(authorizedRepresentativeModel);

    // console.log(applicationID,"applicationID53")
  }, [applicationID]);
  useEffect(() => {
    getExcelFilePath();
  }, [])
  // useEffect(()=>{
  //     setIsDisable(disabledForm)
  // },[])
  const getExcelFilePath = () => {
    // get(EVALUATION_FILE).then((response: any) => {
    //     if (!response.data) return;
    //     setAuthorisationLetterPath(response.data.startupAuthorizationLetterPath);
    // });
    get(NEW_EVALUATION_FILE).then((response: any) => {
      if (!response.data) return;
      setSampleFile(response.data.LetterOfAuthorisation);
    });
  };

  const history = useHistory();

  const applicationPage = () => {
    history.push({
      pathname: "/startup_status",
    });
  };

  // triggers whenever user makes any changes in the form for select and input type
  const handleChange = (
    evt: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const name = evt.target.name;
    let value = evt.target.value;
    let updatedAuthorizedRepresentative: AuthorizedRepresentativeModel =
      initAuthorizedRepresentative;

    if (
      evt.target.name == "representative_firstname" ||
      evt.target.name == "representative_lastname" ||
      evt.target.name == "representative_designation"
    ) {
      updatedAuthorizedRepresentative = {
        ...authorizedRepresentative,
        [name]: value.replace(/[^a-zA-Z]/gi, ""),
      };
    } else if (evt.target.name == "representative_mobile_no") {
      updatedAuthorizedRepresentative = {
        ...authorizedRepresentative,
        [name]: value.replace(/[^0-9]/gi, ""),
      };
      // value = value.replace(/[^0-9 |`~]/ig, '');
    } else if (evt.target.name == "representative_email") {
      updatedAuthorizedRepresentative = {
        ...authorizedRepresentative,
        [name]: value.replace(/[^a-zA-Z0-9._ %@!$*~ -]/gi, ""),
      };
      // value = value.replace(/[^0-9 |`~]/ig, '');
    } else {
      updatedAuthorizedRepresentative = {
        ...authorizedRepresentative,
        [name]: value,
      };
    }

    // setAuthorizedRepresentative((prevState: AuthorizedRepresentativeModel) => {
    //     return {
    //         ...prevState,
    //         [name]: value ? value : "",
    //     };
    // });

    setLocalStateAndUpdateParent(updatedAuthorizedRepresentative);
  };

  // set local state and update parent component
  const setLocalStateAndUpdateParent = (
    updatedAuthorizedRepresentative: AuthorizedRepresentativeModel
  ) => {
    setAuthorizedRepresentative(updatedAuthorizedRepresentative);

    setTimeout(() => {
      onChildUpdate(updatedAuthorizedRepresentative);
    }, 0);
  };
  const [popUpName, setPopUpName] = useState("");
  const setMeetingModal = (fieldName: string) => {
    setPopUpName(fieldName);
  };
  const getFileDetails = (file: any, key: string) => {
    let file_name = "";
    file_name =
      file != undefined && file != null && file.length > 1
        ? file.substr(file.lastIndexOf("/") + 1, file.length)
        : "";

    const file_type =
      file != undefined && file != null && file.length > 1
        ? file.substr(file.lastIndexOf(".") + 1, file.length)
        : "";

    if (key == "name") return file_name;
    if (key == "file") return file != undefined ? file.toString() : "";
    if (key == "type") return file != undefined ? file_type.toString() : "";
  };
  const onFileUploadComplete = (files: Array<File>, type: string) => {
    // console.log("testing", files)
    let indexes: Array<string> = [];
    let updatedUploadDocuments = Object.assign({}, authorizedRepresentative);
    for (const file of files) {
      // if already upladed then skip
      // console.log(file,"file")
      const fileData = authorizedRepresentative as any;
      let updatedFileData: Array<FileUploadTypeModel> = [];
      //         const fileExistsIndex  = fileData[type].findIndex(
      //             (uf: FileUploadTypeModel) => uf.file.name === file.name && uf.file_type == type
      //         );

      // if (fileExistsIndex  > -1) continue
      const user: any = getSessionStorageValue("user");
      const userInfo = JSON.parse(user);
      const formData = new FormData();
      // Update the formData object

      formData.append("file", file);
      formData.append(
        "startup_application_id",
        applicationID === "" ? "0" : applicationID
      );
      formData.append("file_type", type);
      formData.append("logged_user_id", userInfo.id);
      formData.append("user_Role", userInfo.roles);

      // let updatedOtherDetail = JSON.parse(JSON.stringify(otherDetail));

      //   console.log("updatedotherdetail2", updatedOtherDetail)

      setLoader(true);

      post(STARTUP_DETAIL_UPLOAD, formData).then((response: any) => {
        setLoader(false);

        if (!response.data) return;
        // onUpdateApplicationID(applicationID)
        //    console.log(response.data.data,"srri")

        if (
          response.data.result.message ==
          "Only single file should be uploaded." ||
          response.data.result.message == "File already exists."
        ) {
          setFailDialogData({
            header: "Oops",
            text: response.data.result.message,
          });
          setShowIncubatorFailureModal1(true);
          return;
        } else {
          updatedFileData = [
            ...updatedUploadDocuments.authorisation_letter_upload.map(
              (uf: any): FileUploadTypeModel => {
                return new FileUploadTypeModel(
                  uf.file_id,
                  uf.file_type,
                  uf.file
                );
              }
            ),
            new FileUploadTypeModel(
              response.data.data.file_id,
              StartupUploadTypeEnum.UploadLegalAgreement,
              file
            ),
          ];
          updatedUploadDocuments.authorisation_letter_upload = updatedFileData;
          // console.log(updatedFileData,"data!!!")
          // updatedOtherDetail.upload_pitch_deck = updatedFileData;
          // setOtherDetail(updatedOtherDetail);
          setAuthorizedRepresentative((prevState) => {
            const updatedFileOther = {
              ...prevState,
              relavent_document_excel: [
                ...prevState.authorisation_letter_upload.map(
                  (uf: any): FileUploadTypeModel => {
                    return new FileUploadTypeModel(
                      uf.file_id,
                      uf.file_type,
                      uf.file
                    );
                  }
                ),
                new FileUploadTypeModel(
                  response.data.data.file_id,
                  StartupUploadTypeEnum.UploadLegalAgreement,
                  file
                ),
              ],
            };
            // console.log(authorizedRepresentative,"updatedFileOther")

            return updatedFileOther;
          });
          setLocalStateAndUpdateParent(updatedUploadDocuments);

          // onStepSave(
          //   StepperEnum.OtherDetail,
          //   updatedOtherDetail,
          //   requestType.propertyChange
          // );

          // de-allocate references.
          formData.delete("file");
          formData.delete("startup_application_id");
          formData.delete("file_type");

          // setPdfOnly({ ...pdfOnly, [type]: false });
          setLoader(false);
        }
      });
    }

    if (indexes.length) {
      indexes.map((ind) => {
        const position: number = files.findIndex((file) => file.name === ind);
        files.splice(position, 1);
      });
    }
  };

  const onFileDelete = (file: File, type: string) => {
    let remainingFiles: Array<FileUploadTypeModel> = [];
    let fileToBeDeleted: any;

    // console.log(authorizedRepresentative.authorisation_letter_upload,"fileToBeDeleted")

    fileToBeDeleted = authorizedRepresentative.authorisation_letter_upload.find(
      (uploadedFile: FileUploadTypeModel) =>
        getFileDetails(uploadedFile.file, "name") === file.name
    );
    if (!fileToBeDeleted) return;
    remainingFiles =
      authorizedRepresentative.authorisation_letter_upload.filter(
        (uf: FileUploadTypeModel) => uf.file !== fileToBeDeleted.file
      );

    authorizedRepresentative.authorisation_letter_upload = remainingFiles;
    setLocalStateAndUpdateParent(authorizedRepresentative);

    // delete the file

    setLoader(true);
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    post(STARTUP_FILE_REMOVE, {
      application_id: applicationID,
      file_id: fileToBeDeleted.file_id,
      logged_user_id: userInfo.id,
    }).then((response: any) => {
      if (response.data) {
        setLoader(false);
      }
    });
    // setOtherDetail(JSON.parse(JSON.stringify(otherDetail)));
  };

  return (
    <>
      {loader && (
        <div className="spinner-border custom-spinner" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}
      <fieldset disabled={loader}>
        <div className="form-content">
          <fieldset disabled={disabledForm}>
            <div className="form-group required row">
              <label className="col-sm-4 col-form-label">Name of Authorised representative</label>
              <div className="col-sm-8">
                <div className="form-row mb-0">
                  <div className="col">
                    <input
                      type="text"
                      name="representative_firstname"
                      className={`${errors["AuthorizedRepresentative"] &&
                        errors["AuthorizedRepresentative"][
                        "representative_firstname"
                        ]
                        ? `form-control error`
                        : "form-control"
                        }`}
                      placeholder="First Name"
                      value={authorizedRepresentative.representative_firstname}
                      onChange={handleChange}
                      required={true}
                      maxLength={500}
                    />
                    {errors &&
                      errors.AuthorizedRepresentative &&
                      errors.AuthorizedRepresentative[
                        "representative_firstname"
                      ].length > 0 && (
                        <ValidationMessage
                          className="error"
                          message={
                            errors.AuthorizedRepresentative[
                            "representative_firstname"
                            ]
                          }
                        />
                      )}
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      name="representative_lastname"
                      className={`${errors["AuthorizedRepresentative"] &&
                        errors["AuthorizedRepresentative"][
                        "representative_lastname"
                        ]
                        ? `form-control error`
                        : "form-control"
                        }`}
                      placeholder="Last Name"
                      value={authorizedRepresentative.representative_lastname}
                      onChange={handleChange}
                      required={true}
                      maxLength={500}
                    />
                    {errors &&
                      errors.AuthorizedRepresentative &&
                      errors.AuthorizedRepresentative["representative_lastname"]
                        .length > 0 && (
                        <ValidationMessage
                          className="error"
                          message={
                            errors.AuthorizedRepresentative[
                            "representative_lastname"
                            ]
                          }
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group required row">
              <label className="col-sm-4 col-form-label">Designation</label>
              <div className="col-sm-4 pr-2">
                <input
                  type="text"
                  name="representative_designation"
                  className={`${errors["AuthorizedRepresentative"] &&
                    errors["AuthorizedRepresentative"][
                    "representative_designation"
                    ]
                    ? `form-control error`
                    : "form-control"
                    }`}
                  placeholder="Enter"
                  value={authorizedRepresentative.representative_designation}
                  onChange={handleChange}
                  required={true}
                  maxLength={255}
                />
                {errors &&
                  errors.AuthorizedRepresentative &&
                  errors.AuthorizedRepresentative["representative_designation"]
                    .length > 0 && (
                    <ValidationMessage
                      className="error"
                      message={
                        errors.AuthorizedRepresentative[
                        "representative_designation"
                        ]
                      }
                    />
                  )}
              </div>
            </div>

            <div className="form-group required row">
              <label className="col-sm-4 col-form-label">Mobile No.</label>
              <div className="col-sm-4 pr-2">
                <div className="input-group mb-2 mr-sm-2 mb-sm-0">
                  <span className="input-group-addon">+91</span>
                  <input
                    type="text"
                    name="representative_mobile_no"
                    className={`${errors["AuthorizedRepresentative"] &&
                      errors["AuthorizedRepresentative"][
                      "representative_mobile_no"
                      ]
                      ? `form-control error`
                      : "form-control"
                      }`}
                    placeholder="Ten Digit Mobile Number"
                    value={authorizedRepresentative.representative_mobile_no}
                    onChange={handleChange}
                    required={true}
                    maxLength={10}
                  />
                </div>
                {errors &&
                  errors.AuthorizedRepresentative &&
                  errors.AuthorizedRepresentative["representative_mobile_no"]
                    .length > 0 && (
                    <ValidationMessage
                      className="error"
                      message={
                        errors.AuthorizedRepresentative[
                        "representative_mobile_no"
                        ]
                      }
                    />
                  )}
              </div>
            </div>

            <div className="form-group required row">
              <label className="col-sm-4 col-form-label">Email ID</label>
              <div className="col-sm-8">
                <input
                  type="text"
                  name="representative_email"
                  className={`${errors["AuthorizedRepresentative"] &&
                    errors["AuthorizedRepresentative"]["representative_email"]
                    ? `form-control error`
                    : "form-control"
                    }`}
                  placeholder="Enter"
                  value={authorizedRepresentative.representative_email}
                  onChange={handleChange}
                  required={true}
                />
                {errors &&
                  errors.AuthorizedRepresentative &&
                  errors.AuthorizedRepresentative["representative_email"]
                    .length > 0 && (
                    <ValidationMessage
                      className="error"
                      message={
                        errors.AuthorizedRepresentative["representative_email"]
                      }
                    />
                  )}
              </div>
            </div>
          </fieldset>
          {/* //new modification */}
          <div className="form-group required row">
            <label className="col-sm-4 col-form-label">
              Board Resolution / Authorisation letter / PoA
            </label>
            <div className="col-sm-8">
              <div className="d-inline-flex upload-pitch">
                <span
                  className="link-orange-text link-underline"
                // onClick={() => downloadDocument(authorisationLetterPath, 'Authorisation_Letter')}
                >
                  <a
                    style={{ color: "#e26640" }}
                    href={`${NODE_URL}` + sampleFile}
                  >
                    <u>Sample Download</u>
                  </a>
                </span>
              </div>

              <div className="">
                <fieldset disabled={disabledForm}>
                  <button
                    className="btn btn-sisfs btn-transparent-primary"
                    onClick={() =>
                      setOpenUploadBlock({ AuthorisationLetterUpload: true })
                    }
                  >
                    Upload
                  </button>
                  {((openUploadBlock &&
                    openUploadBlock["AuthorisationLetterUpload"] &&
                    authorizedRepresentative.authorisation_letter_upload
                      .length === 0) ||
                    authorizedRepresentative.authorisation_letter_upload
                      .length > 0) && (
                      <>
                        <span
                          className="close"
                          onClick={() =>
                            setOpenUploadBlock({
                              AuthorisationLetterUpload: false,
                            })
                          }
                        >
                          <i className="fa fa-close"></i>
                        </span>

                        {/* {console.log(authorizedRepresentative.authorisation_letter_upload,"letter")} */}
                        <FileUploader
                          key={Math.random().toString()}
                          id={StartupUploadTypeEnum.UploadLegalAgreement}
                          information="Drag & Drop the file or Browse"
                          fileType="application/pdf"
                          buttonLabel="Browse"
                          multiple={false}
                          enablePreview
                          IconComponent={() => (
                            <span className="fa fa-cloud-upload"></span>
                          )}
                          removeIconClass=""
                          existingFiles={authorizedRepresentative.authorisation_letter_upload.map(
                            (uploadedFile: FileUploadTypeModel) => ({
                              name: getFileDetails(uploadedFile.file, "name") || uploadedFile.file.name,
                              url: uploadedFile.file,
                            })
                            // uploadedFile.file
                          )}
                          onUploadFinish={(files: Array<File>) => {
                            onFileUploadComplete(
                              files,
                              StartupUploadTypeEnum.UploadLegalAgreement
                            );
                          }}
                          onFileDelete={(deletedFile: File) =>
                            onFileDelete(
                              deletedFile,
                              StartupUploadTypeEnum.UploadLegalAgreement
                            )
                          }
                        />
                      </>
                    )}
                </fieldset>


                {(authorizedRepresentative.authorisation_letter_upload.length >
                  0 && authorizedRepresentative.authorisation_letter_upload[0].file.size == undefined) ? (
                  <a
                    className="incubator-details-link"
                    data-toggle="modal"
                    data-target="#showMoreDocument"
                    onClick={() => setMeetingModal("Authorisation Letter")}
                  >
                    View uploaded Documents
                  </a>
                ) : (
                  ""
                )}
                <small>Supported file format - PDF only</small>
                <small>
                  For the person filling this form and signing other
                  self-declaration documents from authorised signatory of the
                  applicant for filling and representing the organisation for
                  this application
                </small>
                <div
                  className={`${props.errors.length &&
                    !authorizedRepresentative.authorisation_letter_upload.length
                    ? `col-12 pl-0 error`
                    : "invalid-feedback "
                    }`}
                >
                  This is mandatory field
                </div>
                <div
                  className={`${pdfOnly?.authorisation_letter_upload
                    ? `col-12 pl-0 error`
                    : "invalid-feedback "
                    }`}
                >
                  Only PDF files are allowed
                </div>
              </div>
              {errors &&
                errors.AuthorizedRepresentative &&
                errors.AuthorizedRepresentative["authorisation_letter_upload"]
                  .length > 0 && (
                  <ValidationMessage
                    className="error"
                    message={
                      errors.AuthorizedRepresentative[
                      "authorisation_letter_upload"
                      ]
                    }
                  />
                )}
            </div>
          </div>
          {/* new modification end */}
        </div>
      </fieldset>
      <fieldset disabled={disabledForm}>
        <div className="stepper-footer-section">
          <div
            className="btn-toolbar justify-content-between"
            role="toolbar"
            aria-label="Toolbar with button groups"
          >
            <button
              className="btn btn-sisfs btn-transparent-primary"
              onClick={() => applicationPage()}
            >
              Cancel
            </button>
            <span className="stepper-page-count">
              Step {props.tabIndex} / 7{" "}
            </span>
            <div className="btn-group">
              {props.tabIndex != 1 && (
                <button
                  className="btn btn-sisfs btn-transparent-primary"
                  onClick={props.onPreviousClick}
                >
                  Previous
                </button>
              )}
              <button
                type="button"
                className="btn btn-sisfs btn-yellow-primary"
                onClick={() =>
                  props.onStepSave(
                    authorizedRepresentative,
                    StepperStartupEnum.AuthorizedRepresentative,
                    requestType.save
                  )
                }
              >
                {props.tabIndex == 7 ? "Save" : "Save And Next"}
              </button>
              {props.tabIndex == 7 && (
                <button
                  className="btn btn-sisfs btn-yellow-primary"
                // onClick={() => props.onSubmit}
                >
                  Review & Submit
                </button>
              )}
            </div>
          </div>
        </div>
      </fieldset>
      {/* duplicate file  */}
      {showIncubatorFailureModal1 && (
        <ConfirmationDialog
          headerText={failDialogData.header}
          bodyText={failDialogData.text}
          // bodyNote={failDialogData.note}
          onDialogClose={() => setShowIncubatorFailureModal1(false)}
          iconClass="showImage"
        />
      )}

      {/* Show More  */}
      <div
        className="modal fade"
        id="showMoreDocument"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none ml-18px">
              <h5 className="modal-title " id="exampleModalLongTitle">
                {popUpName} <span className="eac-name-pop-up"></span>
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {/* <div className=""> */}
              {/* 1St Application Documents */}

              {/* General Details documents */}
              {popUpName == "Authorisation Letter" ? (
                authorizedRepresentative.authorisation_letter_upload !=
                  undefined ? (
                  authorizedRepresentative.authorisation_letter_upload.map(
                    (file: any, index: any) => (
                      <div className="row">
                        <div className="col-md-2">
                          {getFileDetails(file.file, "type") == "pdf" ? (
                            <img src={pdf} alt="SISFS Logo" />
                          ) : getFileDetails(file.file, "type") == "doc" ? (
                            <img src={doc} alt="SISFS Logo" />
                          ) : getFileDetails(file.file, "type") == "xls" ? (
                            <img src={xls} alt="SISFS Logo" />
                          ) : (
                            <img src={Allround} alt="SISFS Logo" />
                          )}
                        </div>
                        <div className="col-md-9 left-align">
                          <span className="document-label-value-wrap">
                            {getFileDetails(file.file, "name") || file.file.name}
                          </span>
                          <br />

                          <a
                            // href={file.file}
                            href={file.file}
                            target="_blank"
                            //download="annual_report"
                            className="incubator-details-link"
                          >
                            View
                          </a>
                          {/* /
                            <a
                              href="#"
                              //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <span className="incubator-label-value">-</span>
                )
              ) : (
                ""
              )}

              <div className="row">
                <div className="col-md-12 centerText">
                  <div className="btn-group mt-1-5 ">
                    <button
                      className="tertiary  btn-yellow-primary"
                      data-dismiss="modal"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AuthorizedRepresentativeComponent;
