import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { GET_ALL_DISTRICTS, GET_ALL_INDUSTRIES, GET_ALL_INDUSTRY_SECTORS, GET_ALL_STATES,STARTUP_GET_DETAILS } from "../../../constants/api";
import { get,post } from "../../../effects/api/api.common";
import { DropdownModel } from "../../../models/DropdownModel";
import { EntityDetailModel } from "../../../models/startup/EntityDetailModel";
import { initEntityDetail } from "../../../models/startup/StartupModel";
import { requestType, StepperStartupEnum } from "../../../utils/Enums";
import Calendar from "../../common/controls/Calendar";
import MultiSelect from "../../common/controls/MultiSelect";
import ValidationMessage from "../../common/controls/ValidationMessage";
import { useErrorStateValue } from "../../../providers/ErrorProvider";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { getSessionStorageValue } from "../../../effects/utils/session-storage";

interface IEntityDetailsProps {
    stepper: any;
    entityDetailModel: EntityDetailModel;
    tabIndex: number;
    onStepSave: (entityDetails: EntityDetailModel, stepIndex: number, request: number) => void;
    onChildUpdate: (entityDetails: EntityDetailModel) => void;
    onPreviousClick: () => void;
    errors: Array<string>;
    apiCalled: Boolean;
    disabledForm:any;
}

interface IEntityDetailsState { }

/** Render start-up application screen */
function EntityDetailsComponent(props: IEntityDetailsProps) {
    const {
        stepper,
        onChildUpdate,
        entityDetailModel,
        apiCalled,
        disabledForm
    } = props;

    // @ts-ignore
    const [{ errors }, dispatchError] = useErrorStateValue();

    const [entityDetails, setEntityDetails] = useState<EntityDetailModel>(
        initEntityDetail
    );

    const [industries, setIndustries] = useState([]);
    const [industriesOther, setIndustriesOther] = useState(false);
    const [industrySectors, setIndustrySectors] = useState<Array<DropdownModel>>(
        []
    );
    const [countryStates, setCountryStates] = useState([]);
    const [stateDistricts, setStateDistricts] = useState([]);
    const [selectedDateControl, setSelectedDateControl] = useState('');

    useEffect(()=>{
        getAllIndustries();
        getStartupDetails();
    },[])
    useEffect(()=>{
        getAllIndustrySectors(entityDetailModel.industry_ids)
    },[entityDetailModel.industry_ids])
    useEffect(() => { 
        setSelectedDateControl(moment(new Date(entityDetails.registration_date).setFullYear(new Date(entityDetails.registration_date).getFullYear() - 2)).format(
            "YYYY-MM-DD"
        ));
        // console.log(selectedDateControl);
    }, [apiCalled, entityDetailModel.registration_date]);

    // get all the industries on component load
    const getAllIndustries = () => {
        get(GET_ALL_INDUSTRIES).then((response: any) => {
            if (!response || !response.data) return;

            const industries = response.data.data.map(
                (item: any): DropdownModel => {
                    return {
                        text: item.attribute_value,
                        id: item.id.toString(),
                        translation_text: "",
                    };
                }
            );
            setIndustries(industries);
            getAllStates();
        });
    };
    const getStartupDetails = () => {
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user)
        post(STARTUP_GET_DETAILS, { "logged_user_id": userInfo.id }).then(async (response: any) => {
          if (!response || !response.data) return;
          if (response.data.data != "") {
            console.log(response.data.data)
            setEntityDetails(response.data.data); 
            console.log("response.data.data",response.data.data.state_id)
            getAllDistrictsByStateId(response.data.data.state_id);
    
          }else{
            setEntityDetails(entityDetailModel);
            console.log("entityDetailModel",entityDetailModel.state_id);
            console.log("entityDetails",entityDetails.state_id)
            getAllDistrictsByStateId(entityDetails.state_id);
    
          }
        });
      };
    // get all the states on component load
    const getAllStates = () => {
        get(GET_ALL_STATES).then((response: any) => {
            if (!response || !response.data) return;

            const states = response.data.data.map((item: any) => ({
                label: item.name,
                value: item.id,
            }));
            setCountryStates(states);
        });
    };

    const history = useHistory();

    const applicationPage = () => {
        history.push({
            pathname: "/startup_status",
        });
    }
    
    // fetch all the sectors based on industries
    // const getAllIndustrySectors = (industryId: string) => {
    //     if (!industryId) {
    //         setIndustrySectors([]);
    //         return;
    //     }
    //     get(`${GET_ALL_INDUSTRY_SECTORS}/${industryId}`).then((response: any) => {
    //         if (!response || !response?.data?.data) {
    //             setIndustrySectors([]);
    //             return;
    //         }

    //         const industrySectors: Array<DropdownModel> = response.data.data.map(
    //             (item: any): DropdownModel => {
    //                 return {
    //                     text: item?.attribute_value,
    //                     id: item?.id.toString(),
    //                     translation_text: "",
    //                 };
    //             }
    //         );
    //         setIndustrySectors(industrySectors);
    //     });
    // };

    // bind the sector based on industries selected
    const getAllIndustrySectors = (industryIds: Array<string>) => {
        if (industryIds?.length > 0) {
            get(`${GET_ALL_INDUSTRY_SECTORS}/${industryIds.join(",")}`).then(
                (response: any) => {
                    if (!response || !response?.data?.data) {
                        setIndustrySectors([]);
                        return;
                    }
                    const industrySectors: Array<DropdownModel> = response.data.data.map(
                        (item: any): DropdownModel => {
                            return {
                                text: item?.attribute_value,
                                id: item?.id.toString(),
                                translation_text: "",
                                // selected: false,
                            };
                        }
                    );
                    setIndustrySectors(industrySectors);
                }
            );
        } else {
            setIndustrySectors([]);
        }
    };

    // fetch all the districts by state id
    const getAllDistrictsByStateId = (stateId: string) => {
        console.log("getAllDistrictsByStateId", stateId)
        if (!stateId) {
            setEntityDetails((prevState: EntityDetailModel) => {
                return {
                    ...prevState,
                    city_id: "",
                };
            });
            setStateDistricts([]);
            return;
        }

        get(`${GET_ALL_DISTRICTS}/${stateId}`).then((response: any) => {
            if (!response || !response?.data?.data) {
                setStateDistricts([]);
                return;
            }
            const districts = response?.data?.data.map((item: any) => ({
                label: item?.name,
                value: item?.id,
            }));
            console.log("response",districts)

            setStateDistricts(districts);
        });
    };

    // triggers whenever user makes any changes in the form for select and input type
    const handleChange = (
        evt: React.ChangeEvent<
            HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
        >
    ) => {
        const name = evt.target.name;
        const value = evt.target.value;

        let updatedEntityDetail: EntityDetailModel = initEntityDetail;

        updatedEntityDetail = {
            ...entityDetails,
            [name]: value,
        };

        // setEntityDetails((prevState: EntityDetailModel) => {
        //     return {
        //         ...prevState,
        //         [name]: value ? value : "",
        //     };
        // });

        // cascaded dropdown for district on state change
        if (name === "state_id") {
            getAllDistrictsByStateId(value);
        }

        setLocalStateAndUpdateParent(updatedEntityDetail);
    };

    // trrigers on date change
    const handleDateChange = (date: Date, name: string) => {
        // setSelectedDateControl(date);
        const updatedEntityDetail = {
            ...entityDetails,
            [name]: new Date(moment(date).format(
                "YYYY-MM-DD"
            )),
        };

        // console.log('date changed',updatedEntityDetail)

        setLocalStateAndUpdateParent(updatedEntityDetail);
    };

    // triggers whenever multi-select values are changed
    const onMultiSelectChange = (selectedIds: Array<string>, name: string) => {
        // setEntityDetails((prevState: EntityDetailModel) => {
        //     return {
        //         ...prevState,
        //         [name]: selectedIds,
        //     };
        // });


        if (selectedIds.includes("55")) {
            entityDetails.industry_id_other_show = true;
            var updatedEntityDetail = {
                ...entityDetails,
                [name]: selectedIds,
            };
        } else {
            entityDetails.industry_id_other_show = false;

            var updatedEntityDetail = {
                ...entityDetails,
                [name]: selectedIds,
                industry_id_other: "",
            };
        }

        // cascaded multi-select for sectors based on industry select
        if (name === "industry_ids") {
            // getAllIndustrySectors(selectedIds.join(","));
            getAllIndustrySectors(selectedIds);
        }

        setLocalStateAndUpdateParent(updatedEntityDetail);
    };

    // set local state and update parent component
    const setLocalStateAndUpdateParent = (
        updatedEntityDetail: EntityDetailModel
    ) => {
        setEntityDetails(updatedEntityDetail);

        setTimeout(() => {
            onChildUpdate(updatedEntityDetail);
        }, 0);
    };

    return (
        <>
        <fieldset disabled={true}>
            <div className="form-content">

                <div className="form-group row">
                    <label className="col-sm-4 col-form-label">DPIIT Recognition Number</label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            name="recognition_number"
                            className={`${errors["EntityDetail"] &&
                                errors["EntityDetail"]["recognition_number"]
                                ? `form-control error`
                                : "form-control"
                                }`}
                            placeholder="Enter"
                            value={entityDetails.recognition_number}
                            onChange={handleChange}
                            required={true}
                            maxLength={255}
                        />

                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-sm-4 col-form-label">Name of the Entity</label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            name="name_of_entity"
                            className="form-control"
                            placeholder="Enter"
                            value={entityDetails.name_of_entity}
                            onChange={handleChange}
                            required={true}
                            maxLength={255}
                        />

                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-sm-4 col-form-label">Nature of Entity</label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            name="nature_of_entity"
                            className="form-control"
                            placeholder="Enter"
                            value={entityDetails.nature_of_entity}
                            onChange={handleChange}
                            required={true}
                            maxLength={255}
                        />

                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-sm-4 col-form-label">Industry</label>
                    <div className="col-sm-8">
                        <div className="form-row mb-0">
                            <div className="col">
                                <MultiSelect
                                    id="industries"
                                    className="multi-select"
                                    options={industries}
                                    defaultText="Select Industry"
                                    onClose={(selectedIds: string[]) =>
                                        onMultiSelectChange(selectedIds, "industry_ids")
                                    }
                                    selectedValues={
                                        entityDetails.industry_ids ? entityDetails.industry_ids : []
                                    }
                                    hasError
                                    errorText=""
                                    searchable
                                />
                            </div>
                        </div>

                        {
                            entityDetails.industry_id_other_show &&
                            <div className="form-row mb-0">
                                <div className="col">
                                    <label className="col-label mb-0 pb-2">Others</label>
                                    <input
                                        type="text"
                                        name="industry_id_other"
                                        className="form-control"
                                        placeholder="Please Specify"
                                        value={entityDetails.industry_id_other}
                                        onChange={handleChange}
                                        required={true}
                                        maxLength={255}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-sm-4 col-form-label">Sector</label>
                    <div className="col-sm-8">
                        <MultiSelect
                            id="industrySectors"
                            className="multi-select"
                            options={industrySectors}
                            defaultText="Select Sector"
                            onClose={(selectedIds: string[]) =>
                                onMultiSelectChange(selectedIds, "sector_ids")
                            }
                            selectedValues={
                                entityDetailModel.sector_ids ? entityDetailModel.sector_ids : []
                            }
                            hasError
                            errorText=""
                            searchable
                        />
                    </div>
                </div>

                <hr />

                <div className="form-group row">
                    <label className="col-sm-4 col-form-label">Corporate Identification Number</label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            name="company_information_number"
                            className="form-control"
                            placeholder="Enter"
                            value={entityDetails.company_information_number}
                            onChange={handleChange}
                            required={true}
                            maxLength={255}
                        />

                    </div>
                </div>

                <div className="form-group required row">
                    <label className="col-sm-4 col-form-label">Incorporation/Registration Date</label>
                    <div className="col-sm-8">
                        <div className="form-row mb-0">
                            <div className="col-6">
                                {/* <Calendar
                                    id="registration_date"
                                    name="registration_date"
                                    className={
                                        errors &&
                                            errors.EntityDetail &&
                                            errors.EntityDetail["registration_date"].length > 0
                                            ? "form-control error"
                                            : "form-control"
                                    }
                                    format="dd/MM/yyyy"
                                    value={entityDetailModel.registration_date}
                                    onDateChange={(date: Date, field: string) =>
                                        handleDateChange(date, field)
                                    }
                                /> */}
                                <ReactDatePicker
                                    id="registration_date"
                                    name="registration_date"
                                    className={`${props.errors.indexOf("registrationDate") > -1
                                        ? `form-control error`
                                        : "form-control"
                                        }`}
                                    dateFormat="yyyy-MM-dd"
                                    selected={entityDetails.registration_date!=""?new Date(moment(entityDetails.registration_date).format(
                                        "YYYY-MM-DD"
                                    )):new Date()}
                                    value={entityDetails.registration_date!=""?moment(entityDetails.registration_date).format(
                                        "YYYY-MM-DD"
                                    ):''}
                                    showMonthDropdown
                                    showYearDropdown
                                    onChange={(date: Date) =>
                                        handleDateChange(date, "registration_date")
                                    }
                                    // maxDate={new Date(selectedDateControl)}

                                />
                                {errors &&
                                    errors.EntityDetail &&
                                    errors.EntityDetail["registration_date"].length > 0 && (
                                        <ValidationMessage
                                            className="error"
                                            message={errors.EntityDetail["registration_date"]}
                                        />
                                    )}
                            </div>
                            <div className="col-12">
                                <small>Date of application should not be more than 2 years from incorporation</small>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-sm-4 col-form-label">PAN Number</label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            name="pan_number"
                            className={
                                errors &&
                                    errors.EntityDetail &&
                                    errors.EntityDetail["pan_number"].length > 0
                                    ? "form-control error"
                                    : "form-control"
                            }
                            placeholder="Enter"
                            value={entityDetails.pan_number}
                            onChange={handleChange}
                            required={true}
                            maxLength={255}
                        />
                        {errors &&
                            errors.EntityDetail &&
                            errors.EntityDetail["pan_number"].length > 0 && (
                                <ValidationMessage
                                    className="error"
                                    message={errors.EntityDetail["pan_number"]}
                                />
                            )}
                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                        Startup Address
                    </label>
                    <div className="col-sm-8">
                        <div className="form-row">
                            <div className="col">
                                <div className="select-custom">
                                    <select
                                        className="form-control"
                                        aria-label="Select State"
                                        id="State"
                                        name="state_id"
                                        value={entityDetails?.state_id}
                                        onChange={handleChange}
                                    >
                                         <option value="">Select State</option>

                                        {countryStates.map(({ label, value }) => (
                                            <option key={label} value={value}>
                                                {label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col">
                                <div className="select-custom">
                                    <select
                                        className="form-control"
                                        aria-label="Select City"
                                        id="City"
                                        name="city_id"
                                        value={entityDetails?.city_id}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select City</option>
                                        {stateDistricts.map(({ label, value }) => (
                                            <option key={value} value={value}>
                                                {label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>

                        {/* New Addition */}
                        <div className="form-row mb-0">
                            <div className="col">
                                <textarea
                                    className="form-control"
                                    rows={4}
                                    placeholder="Full Address (max. 1000 characters)"
                                    name="startup_address"
                                    value={entityDetails.startup_address}
                                    onChange={handleChange}
                                    required={true}
                                    maxLength={1000}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            </fieldset>
            <fieldset disabled={disabledForm}>
                <div className="stepper-footer-section">
                    <div
                        className="btn-toolbar justify-content-between"
                        role="toolbar"
                        aria-label="Toolbar with button groups"
                    >
                        <button className="btn btn-sisfs btn-transparent-primary" onClick={() => applicationPage()}>
                            Cancel
                                                </button>
                        <span className="stepper-page-count">Step {props.tabIndex} / 7 </span>
                        <div className="btn-group">
                            {props.tabIndex != 1 && (
                                <button
                                    className="btn btn-sisfs btn-transparent-primary"
                                    onClick={props.onPreviousClick}
                                >
                                    Previous
                                </button>
                            )}
                            <button
                                type="button"
                                className="btn btn-sisfs btn-yellow-primary"
                                // onClick={props.onSave}
                                onClick={() =>
                                    props.onStepSave(entityDetails, StepperStartupEnum.EntityDetail, requestType.save)
                                }
                            >
                                {props.tabIndex == 7 ? "Save" : "Save And Next"}
                            </button>
                            {props.tabIndex == 7 && (
                                <button
                                    className="btn btn-sisfs btn-yellow-primary"
                                // onClick={() => props.onSubmit}
                                >
                                    Review & Submit
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </fieldset>
        </>
    )
}

export default EntityDetailsComponent;
