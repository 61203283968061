import { useEffect, useRef, useState } from "react";
import {
  GET_APPLICATION_LIST,
  GET_APPLICATION_VIEW,
  REPLACE_FILE,
  DELETE_APPLICATION,
  EVALUATION_FILE,
  STARTUP_ALL_APPLICATIONS,
  GET_GRIEVANCE_LIST,
  USER_LIST,
  USER_ACTIVE_INACTIVE,
  GET_STARTUP_LISTING,
  GET_STARTUP_LISTING_DUPLICATE_API,
} from "../../../constants/api";
import { get, post } from "../../../effects/api/api.common";
// import ContactSectionComponent from "./ContactSectionComponent";
// import GetApplicationInfoComponent from "./GetApplicationInfoComponenet";
import { useHistory, NavLink } from "react-router-dom";
import search from "../../../images/search.svg";
import { getSessionStorageValue } from "../../../effects/utils/session-storage";
import {
  changeNumberFormat,
  filterByValue,
} from "../../../utils/CommonMethods";
// import { FormatDate } from "../../../../utils/DateFormat";
// import { downloadDocument } from "../../../../utils/CommonMethods";
/** Render Application Status for Incubator screen */

import { Modal } from 'react-bootstrap'
import { StartUpReportComponent } from "../../incubator/startuplisting/startupdetails/StartUpReportComponent";
import { userInfo } from "os";
import ReactPaginate from 'react-paginate';
import '../../../styles/dashboard.css';

function DpiitStartupListingComponent() {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [activeIndex, setActiveIndex] = useState("grievances");
  const [activeTab, setActiveTab] = useState("grievances");
  const [noDataFilter, setNoDataFilter] = useState("");
  const [showToEac, setShowToEac] = useState(false);
  const [userList, setUserList] = useState([]);
  const [userListSelected, setUserListSelected] = useState([]);
  const [userListClosed, setUserListClosed] = useState([])
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [filteredUserListSelected, setFilteredUserListSelected] = useState([]);
  const [filteredUserListClosed, setFilteredUserListClosed] = useState([])
  const [loggedInRole, setLoggedInRole] = useState("");
  const [showReport, setShowReport] = useState(false);
  const [showReport1, setShowReport1] = useState(false);
  const user: any = getSessionStorageValue("user");
  const userInfo = JSON.parse(user);


  useEffect(() => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    setLoggedInRole(userInfo.roles);
    window.scrollTo(0, 0);
    // getIncubatorCount();
    getIncubatorCountSelected();
    // getUserList();
  }, []);
  const changeActive = (tabs: any) => {
    setActiveIndex(tabs);
  };
  const closesetShowReport = () => {
    setShowReport(false);
    // window.location.reload();
  };
  const [isTabActive, setIsTabActive] = useState<any>("")
  const count = useRef(0);
  const tabActive = ((val: any) => {
    count.current = val;
    //console.log(val)
    setIsTabActive(count.current)
  })
  // get initial count of incubators
  const getIncubatorCountSelected = () => {
    setLoader(true);
    post(GET_STARTUP_LISTING_DUPLICATE_API, { status_id: [7], logged_user_id: userInfo.id, userRole: userInfo.roles }).then((response: any) => {
      // console.log("Selected Startups", response.data.applications)
      if (!response || !response.data) return;
      if (response.data.applications.length != 0) {
        setUserListSelected(response.data.applications);
      } else {
        setUserListSelected([]);
      }
      setLoader(false);
      getIncubatorCount();
    });
  };
  // get initial count of incubators
  const getIncubatorCount = () => {
    //console.log("All startups Method calling")
    setLoader(true);
    post(GET_STARTUP_LISTING_DUPLICATE_API, { status_id: "0", logged_user_id: userInfo.id, userRole: userInfo.roles }).then((response: any) => {
      if (!response || !response.data) return;
      
      if (response.data.applications.length != 0) {
        setUserList(response.data.applications);
        console.log("All Startups", response.data.applications)
        const closedApps = response.data.applications.filter((item: any) => item.startup_status_id == 8 )
        console.log(closedApps,"&&&")
        setUserListClosed(closedApps)

      } else {
        setUserList([]);
      }
      setLoader(false);
    });
  };
  // const getUserList = () => {
  //     post(USER_LIST, '').then((response: any) => {
  //         if (!response || !response.data) return;
  //         setUserList(response.data.data)
  //     });
  // };

  const searchList = (e: any) => {
    setNoDataFilter(e.target.value);
    const filteredData = filterByValue(userList, e.target.value);
    const filteredClosedData = filterByValue(userListClosed, e.target.value);
    const filteredSelectedDate = filterByValue(
      userListSelected,
      e.target.value
    );
    //console.log(filteredData);
    setFilteredUserList(filteredData);
    setFilteredUserListSelected(filteredSelectedDate);
    setFilteredUserListClosed(filteredClosedData)

    setCurrentPage(0);
    setCurrentPage1(0);
    setCurrentPage2(0)
    handlePageChange({ selected: 0 });
    // //console.log(filteredData)
    setSelectedCurrentPage(0);
    setFilteredCurrentPage(0)
    setFilteredCurrentPage(0);
    selectedhandlePageChange({ selected: 0 });
  };

  const handleRowChange = (id: any) => {
    let flag = false;
    if (activeIndex == "tranche") {
      flag = true;
    } else {
      flag = false;
    }
    if (loggedInRole == "dpiit") {
      history.replace({
        pathname: "/secretariat_startup_details",
        state: { application_id: id, logged_in_role: "dpiit" },
      });
    } else if (loggedInRole == "secretariat") {
      history.replace({
        pathname: "/secretariat_startup_details",
        state: { application_id: id, logged_in_role: "secretariat" },
      });
    }
  };
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPage1, setCurrentPage1] = useState(0);
  const [currentPage2, setCurrentPage2] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [itemsPerPage1, setItemsPerPage1] = useState(25);
  const [itemsPerPage2, setItemsPerPage2] = useState(25);
  // const itemsPerPage = 10;
  const handlePageChange = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
    setCurrentPage1(selectedItem.selected);
  };
  const handleItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newItemsPerPage = parseInt(event.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setItemsPerPage1(newItemsPerPage);
    setCurrentPage(0); // Reset current page to first page when items per page changes
    setCurrentPage1(0); // Reset current page to first page when items per page changes
    handlePageChange({ selected: 0 });
  };
  const offset = currentPage * itemsPerPage;
  const offset1 = currentPage1 * itemsPerPage1;
  const offset2 = currentPage2 * itemsPerPage2;
  const currentPageData = userList.slice(offset, offset + itemsPerPage);
  // const currentPageData1 = filteredUserList.slice(offset1, offset1 + itemsPerPage1);
  var currentPageData1 = noDataFilter
    ? filteredUserList.slice(offset1, offset1 + itemsPerPage1)
    : userListSelected.slice(offset1, offset1 + itemsPerPage1);

  const totalPages = Math.ceil((userList.length / itemsPerPage));
  // const totalPages1 = Math.ceil( (filteredUserList.length / itemsPerPage1));
  const totalPages1 = noDataFilter
    ? Math.ceil(filteredUserList.length / itemsPerPage1)
    : Math.ceil(userListSelected.length / itemsPerPage1);

  
  ///////////////////////////////////////////SELECTED//////////////////
  const [selectedCurrentPage, setSelectedCurrentPage] = useState(0);
  const [filteredCurrentPage, setFilteredCurrentPage] = useState(0);
  const [selectedItemsPerPage, setSelectedItemsPerPage] = useState(25);
  const [filteredItemsPerPage, setFilteredItemsPerPage] = useState(25);
  // const itemsPerPage = 10;
  const selectedhandlePageChange = (selectedItem: { selected: number }) => {
    setSelectedCurrentPage(selectedItem.selected);
    setFilteredCurrentPage(selectedItem.selected);
  };
  const selectedhandleItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newItemsPerPage = parseInt(event.target.value, 10);
    setSelectedItemsPerPage(newItemsPerPage);
    setFilteredItemsPerPage(newItemsPerPage);
    setSelectedCurrentPage(0); // Reset current page to first page when items per page changes
    setFilteredCurrentPage(0); // Reset current page to first page when items per page changes
    selectedhandlePageChange({ selected: 0 });
  };
  const selectedoffset = selectedCurrentPage * selectedItemsPerPage;
  const filteredoffset = filteredCurrentPage * filteredItemsPerPage;
  const selectedcurrentPageData = userListSelected.slice(selectedoffset, selectedoffset + selectedItemsPerPage);
  // const filteredcurrentPageData = filteredUserListSelected.slice(filteredoffset, filteredoffset + filteredItemsPerPage);
  var filteredcurrentPageData = noDataFilter
    ? filteredUserListSelected.slice(filteredoffset, filteredoffset + filteredItemsPerPage)
    : userListSelected.slice(filteredoffset, filteredoffset + filteredItemsPerPage);
  const selectedTotalPages = Math.ceil((userListSelected.length / selectedItemsPerPage));
  // const filteredTotalPages = Math.ceil( (filteredUserListSelected.length / filteredItemsPerPage));
  const filteredTotalPages = noDataFilter
    ? Math.ceil(filteredUserListSelected.length / filteredItemsPerPage)
    : Math.ceil(userListSelected.length / filteredItemsPerPage);


  ///////////////////////////////////////////Closed//////////////////
  const [closedCurrentPage, setclosedCurrentPage] = useState(0);
  const [filteredCurrentPage2, setFilteredCurrentPage2] = useState(0);
  const [closedItemsPerPage, setclosedItemsPerPage] = useState(25);
  const [filteredItemsPerPage2, setFilteredItemsPerPage2] = useState(25);
  // const itemsPerPage = 10;
  const closedhandlePageChange = (selectedItem: { selected: number }) => {
    setclosedCurrentPage(selectedItem.selected);
    setFilteredCurrentPage2(selectedItem.selected);
  };
  const closedhandleItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newItemsPerPage = parseInt(event.target.value, 10);
    setclosedItemsPerPage(newItemsPerPage);
    setFilteredItemsPerPage2(newItemsPerPage);
    setclosedCurrentPage(0); // Reset current page to first page when items per page changes
    setFilteredCurrentPage(0); // Reset current page to first page when items per page changes
    closedhandlePageChange({ selected: 0 });
  };
  const closedoffset = closedCurrentPage * closedItemsPerPage;
  const filteredoffset2 = filteredCurrentPage2 * filteredItemsPerPage2;
  const closedcurrentPageData = userListClosed.slice(closedoffset, closedoffset + closedItemsPerPage);
  // const filteredcurrentPageData = filteredUserListSelected.slice(filteredoffset, filteredoffset + filteredItemsPerPage);
  var filteredcurrentPageData2 = noDataFilter
    ? filteredUserListClosed.slice(filteredoffset2, filteredoffset2 + filteredItemsPerPage2)
    : userListClosed.slice(filteredoffset2, filteredoffset2 + filteredItemsPerPage2);
  const closedTotalPages = Math.ceil((userListClosed.length / closedItemsPerPage));
  // const filteredTotalPages = Math.ceil( (filteredUserListSelected.length / filteredItemsPerPage));
  const filteredTotalPages2 = noDataFilter
    ? Math.ceil(filteredUserListClosed.length / filteredItemsPerPage2)
    : Math.ceil(userListClosed.length / filteredItemsPerPage2);

  return (
    <div className="app-inside app-apply-incubator">
      <div className="status-list-wrapper mt-4">
        {loader && (
          <div className="spinner-border custom-spinner" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}
        <fieldset disabled={loader}>
          <div className="container-fluid">
            <div className="row px-3">
              <div className="col-12 text-left pt-4 d-flex align-items-center justify-content-space-between">
                {/* <p className="dashboard">Startups</p> */}
                <ul className="nav nav-tabs chart-nav" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#tabs-2"
                      role="tab"
                      onClick={() => tabActive(2)}
                    >
                      Selected Startups 
                    </a>
                  </li>
                  <div className="vertical-dash"></div>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#tabs-3"
                      role="tab"
                      onClick={() => tabActive(3)}
                    >
                      Closed Startups 
                    </a>
                  </li>
                  <div className="vertical-dash"></div>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#tabs-1"
                      role="tab"
                      onClick={() => tabActive(1)}
                    >
                      All Startups 
                    </a>
                  </li>
                  {/* <li>
                    <div>
                      <button
                        className="tertiary btn-yellow-primary"
                        onClick={() => {
                          setShowReport(true);
                        }}
                      >
                        Download Report
                      </button>
                    </div>
                  </li> */}
                </ul>
                <div className="ml-auto text-right">
                <div className="d-flex searchDiv align-self-end mt-3 justify-content-end">
                  {" "}
                  <img src={search} className="search" alt="search" />
                  <input
                    className="searchBar"
                    type="text"
                    placeholder="Search.."
                    name="search"
                    onKeyUp={searchList}
                  />
                </div>
              </div>
              </div>
              
            </div>
            {/* <div className="row text-right">

                        <div className="ml-auto text-right">
                            <div className="d-flex searchDiv align-self-end mr-3 mt-3"> <img
                                src={search}
                                className="search"
                                alt="search"

                            />
                                <input
                                    className="searchBar"
                                    type="text"
                                    placeholder="Search.."
                                    name="search"
                                    onKeyUp={searchList}
                                /></div>

                        </div>

                    </div> */}

            <div className="app-inside-content">
              <div className="tab-content mb-4">
                <div className="tab-pane" id="tabs-1">
                  {/* <div className="row incubator-heading-review mt-3">
                                        <div className="col-md-6 text-left">
                                            
                                        </div>
                                        
                                    </div>    */}
                  {/* <div className="text-right">
                        <button
                          className="tertiary btn-yellow-primary"
                          onClick={() => {
                            setShowReport(true);
                          }}
                        >
                          Download Report
                        </button>
                      </div>                   */}

                  <div className="table-scroll mb-4">
                    <table className="reviewTable userListTable">
                      <thead>
                        <tr>
                          <th className="text-left table-heading custom-td-left">
                            DIPP Number
                          </th>
                          <th className="text-left table-heading">
                            Startup Name
                          </th>
                          {/* <th className="text-left table-heading">Instrument Type</th> */}
                          <th className="text-left table-heading">Sector </th>
                          <th className="text-left table-heading">
                            Associated Incubator
                          </th>
                          <th className="text-center table-heading">
                            {" "}
                            Funds Approved (&#8377;)
                          </th>
                          <th className="text-center table-heading">
                            {" "}
                            Funds Disbursed (&#8377;)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {userList.length == 0 ||
                          (filteredUserList.length == 0 && noDataFilter != "") ? (
                          <tr>
                            <td className="text-center" colSpan={8}>
                              No data
                            </td>
                          </tr>
                        ) : filteredUserList.length == 0 ? (
                          currentPageData.map((item: any) => (
                            <tr key={item.id} onClick={() => handleRowChange(item.id)}>
                              <td className="text-left custom-td">
                                {item.recognition_number}
                              </td>
                              <td className="text-left custom-td">
                                {item.name_of_entity}
                              </td>
                              {/* <td className="text-left custom-td">
                                {item.instrument_approved}
                                
                              </td> */}
                              <td className="text-left custom-td">
                                {" "}
                                {JSON.parse(item.sectors).map(
                                  (industry: any) => industry.sector_name + " "
                                )}{" "}
                              </td>
                              <td className="text-left custom-td">
                                {/* {console.log(item,"itemmmm")}
                                {console.log(item.associated_incubators)} */}
                                {/* { */}

                                {item.associated_incubators.lenth != 0 ?
                                  (JSON.parse(item.associated_incubators).map(
                                    (industry: any) => industry.registered_name + " "
                                  ))
                                  : "-"}

                                {/* {item.associated_incubator != null
                                  ? item.associated_incubator.registered_name !=
                                    null
                                    ? item.associated_incubator.registered_name
                                    : "-"
                                  : "-"} */}
                              </td>
                              <td className="text-center custom-td">
                                {item.total_applroved_amount != null
                                  ? changeNumberFormat(
                                    Number(item.total_applroved_amount),
                                    1,
                                    0
                                  )
                                  : 0}
                              </td>
                              <td className="text-center custom-td">
                                {item.total_disbursed_amount != ""
                                  ? changeNumberFormat(
                                    Number(item.total_disbursed_amount),
                                    1,
                                    0
                                  )
                                  : 0}
                              </td>
                            </tr>
                          ))
                        ) : (
                          currentPageData1.map((item: any) => (
                            <tr key={item.id} onClick={() => handleRowChange(item.id)}>
                              <td className="text-left custom-td">
                                {item.recognition_number}
                              </td>
                              <td className="text-left custom-td">
                                {item.name_of_entity}
                              </td>
                              {/* <td className="text-left custom-td">
                              {item.instrument_approved}
                                
                              </td> */}
                              <td className="text-left custom-td">
                                {" "}
                                {JSON.parse(item.sectors).map(
                                  (industry: any) => industry.sector_name + " "
                                )}{" "}
                              </td>
                              <td className="text-left custom-td">

                                {item.associated_incubators.lenth != 0 ?
                                  (JSON.parse(item.associated_incubators).map(
                                    (industry: any) => industry.registered_name + " "
                                  ))
                                  : "-"}

                                {/* {item.associated_incubator != null
                                  ? item.associated_incubator.registered_name !=
                                    null
                                    ? item.associated_incubator.registered_name
                                    : "-"
                                  : "-"} */}
                              </td>
                              <td className="text-center custom-td">
                                {item.total_applroved_amount != null
                                  ? changeNumberFormat(
                                    Number(item.total_applroved_amount),
                                    1,
                                    0
                                  )
                                  : 0}
                              </td>
                              <td className="text-center custom-td">
                                {item.total_disbursed_amount != ""
                                  ? changeNumberFormat(
                                    Number(item.total_disbursed_amount),
                                    1,
                                    0
                                  )
                                  : 0}
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                  {userList.length == 0 ? <></>
                    :
                    filteredUserList.length == 0 ?
                      <div style={{ float: 'right', display: 'flex', marginRight: '20px', alignItems: 'center', justifyContent: 'center' }}>

                        <span>
                          <label htmlFor="itemsPerPageSelect" className="itemsPerPage">Items per Page:</label>
                          <select
                            className="itemsPerPageSelect"
                            id="itemsPerPageSelect"
                            value={itemsPerPage}
                            onChange={handleItemsPerPageChange}
                          >
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="75">75</option>
                            <option value="100">100</option>
                            <option value={userList.length}>All</option>
                          </select>
                        </span>&nbsp;&nbsp;&nbsp;

                        <span className="pagination" style={{ fontSize: '18px', marginTop: '10px' }}>
                          <ReactPaginate
                            key={itemsPerPage}
                            pageCount={totalPages}
                            pageRangeDisplayed={0}
                            marginPagesDisplayed={0}
                            previousLabel="Prev"
                            nextLabel="Next"
                            // breakLabel="..."
                            onPageChange={handlePageChange}
                            containerClassName="pagination"
                            activeClassName="active"
                            disableInitialCallback={true}
                            forcePage={currentPage}
                          />
                        </span>&nbsp;&nbsp;&nbsp;

                        <span className="pageNum">
                          Page {(currentPage) + 1} of {totalPages}
                        </span>
                        {/* &nbsp;&nbsp;&nbsp;<select value={currentPage} onChange={handleSelectedPageChange} style={{border:'0 0 1px solid black 0'}} >
                        {renderPageNumbers()}
                      </select>&nbsp;&nbsp;&nbsp; */}
                      </div>

                      : <>
                        <div style={{ float: 'right', display: 'flex', marginRight: '20px', alignItems: 'center', justifyContent: 'center' }}>

                          <span>
                            <label htmlFor="itemsPerPageSelect" className="itemsPerPage">Items per Page:</label>
                            <select
                              className="itemsPerPageSelect"
                              id="itemsPerPageSelect"
                              value={itemsPerPage1}
                              onChange={handleItemsPerPageChange}
                            >
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="75">75</option>
                              <option value="100">100</option>
                              <option value={filteredUserList}>All</option>
                            </select>
                          </span>&nbsp;&nbsp;&nbsp;

                          <span className="pagination" style={{ fontSize: '18px', marginTop: '10px' }}>
                            <ReactPaginate
                              key={itemsPerPage1}
                              pageCount={totalPages1}
                              pageRangeDisplayed={0}
                              marginPagesDisplayed={0}
                              previousLabel="Prev"
                              nextLabel="Next"
                              // breakLabel="..."
                              onPageChange={handlePageChange}
                              containerClassName="pagination"
                              activeClassName="active"
                              disableInitialCallback={true}
                              forcePage={currentPage1}
                            />
                          </span>&nbsp;&nbsp;&nbsp;

                          <span className="pageNum">
                            Page {currentPage1 + 1} of {totalPages1}
                          </span>
                          {/* &nbsp;&nbsp;&nbsp;<select value={currentPage} onChange={handleSelectedPageChange} style={{border:'0 0 1px solid black 0'}} >
                                {renderPageNumbers()}
                                </select>&nbsp;&nbsp;&nbsp; */}
                        </div>
                      </>
                  }
                </div>

                <div className="tab-pane" id="tabs-3">

                  <div className="table-scroll mb-4">
                    <table className="reviewTable userListTable">
                      <thead>
                        <tr>
                          <th className="text-left table-heading custom-td-left">
                            DIPP Number
                          </th>
                          <th className="text-left table-heading">
                            Startup Name
                          </th>
                          <th className="text-left table-heading">Sector </th>
                          <th className="text-left table-heading">
                            Associated Incubator
                          </th>
                          <th className="text-left table-heading">Instrument Approved</th>
                          <th className="text-center table-heading">
                            {" "}
                            Funds Approved (&#8377;)
                          </th>
                          <th className="text-center table-heading">
                            {" "}
                            Funds Disbursed (&#8377;)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {userListClosed.length == 0 ||
                          (filteredUserListClosed.length == 0 && noDataFilter != "") ? (
                          <tr>
                            <td className="text-center" colSpan={8}>
                              No data
                            </td>
                          </tr>
                        ) : filteredUserListClosed.length == 0 ? (
                          closedcurrentPageData.map((item: any) => (
                            <tr key={item.id} onClick={() => handleRowChange(item.id)}>
                                <td className="text-left custom-td">
                                  {item.recognition_number}
                                </td>
                                <td className="text-left custom-td">
                                  {item.name_of_entity}
                                </td>
                                <td className="text-left custom-td">
                                  {" "}
                                  {JSON.parse(item.sectors).map(
                                    (industry: any) => industry.sector_name + " "
                                  )}{" "}
                                </td>
                                <td className="text-left custom-td">
                                  {/* { */}

                                  {item.associated_incubators.lenth != 0 ?
                                    (JSON.parse(item.associated_incubators).map(
                                      (industry: any) => industry.registered_name + " "
                                    ))
                                    : "-"}

                                </td>
                                <td className="text-left custom-td">
                                {item.instrument_approved}
                              </td>
                                <td className="text-center custom-td">
                                  {item.total_applroved_amount != null
                                    ? changeNumberFormat(
                                      Number(item.total_applroved_amount),
                                      1,
                                      0
                                    )
                                    : 0}
                                </td>
                                <td className="text-center custom-td">
                                  {item.total_disbursed_amount != ""
                                    ? changeNumberFormat(
                                      Number(item.total_disbursed_amount),
                                      1,
                                      0
                                    )
                                    : 0}
                                </td>
                              </tr>
                          ))
                        ) : (
                          filteredcurrentPageData2.map((item: any) => (
                          <tr key={item.id} onClick={() => handleRowChange(item.id)}>
                                <td className="text-left custom-td">
                                  {item.recognition_number}
                                </td>
                                <td className="text-left custom-td">
                                  {item.name_of_entity}
                                </td>
                                <td className="text-left custom-td">
                                  {" "}
                                  {JSON.parse(item.sectors).map(
                                    (industry: any) => industry.sector_name + " "
                                  )}{" "}
                                </td>
                                <td className="text-left custom-td">

                                  {item.associated_incubators.lenth != 0 ?
                                    (JSON.parse(item.associated_incubators).map(
                                      (industry: any) => industry.registered_name + " "
                                    ))
                                    : "-"}
                                </td>
                                <td className="text-left custom-td">
                                {item.instrument_approved}
                              </td>
                                <td className="text-center custom-td">
                                  {item.total_applroved_amount != null
                                    ? changeNumberFormat(
                                      Number(item.total_applroved_amount),
                                      1,
                                      0
                                    )
                                    : 0}
                                </td>
                                <td className="text-center custom-td">
                                  {item.total_disbursed_amount != ""
                                    ? changeNumberFormat(
                                      Number(item.total_disbursed_amount),
                                      1,
                                      0
                                    )
                                    : 0}
                                </td>
                              </tr>
                            

                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                  {userListClosed.length == 0 ? <></>
                    :
                    filteredUserListClosed.length == 0 ?
                      <div style={{ float: 'right', display: 'flex', marginRight: '20px', alignItems: 'center', justifyContent: 'center' }}>

                        <span>
                          <label htmlFor="itemsPerPageSelect" className="itemsPerPage">Items per Page:</label>
                          <select
                            className="itemsPerPageSelect"
                            id="itemsPerPageSelect"
                            value={closedItemsPerPage}
                            onChange={closedhandleItemsPerPageChange}
                          >
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="75">75</option>
                            <option value="100">100</option>
                            <option value={userListClosed.length}>All</option>
                          </select>
                        </span>&nbsp;&nbsp;&nbsp;

                        <span className="pagination" style={{ fontSize: '18px', marginTop: '10px' }}>
                          <ReactPaginate
                            key={closedItemsPerPage}
                            pageCount={closedTotalPages}
                            pageRangeDisplayed={0}
                            marginPagesDisplayed={0}
                            previousLabel="Prev"
                            nextLabel="Next"
                            // breakLabel="..."
                            onPageChange={closedhandlePageChange}
                            containerClassName="pagination"
                            activeClassName="active"
                            disableInitialCallback={true}
                            forcePage={closedCurrentPage}
                          />
                        </span>&nbsp;&nbsp;&nbsp;

                        <span className="pageNum">
                          Page {(closedCurrentPage) + 1} of {closedTotalPages}
                        </span>
                        {/* &nbsp;&nbsp;&nbsp;<select value={currentPage} onChange={handleSelectedPageChange} style={{border:'0 0 1px solid black 0'}} >
                        {renderPageNumbers()}
                      </select>&nbsp;&nbsp;&nbsp; */}
                      </div>

                      : <>
                        <div style={{ float: 'right', display: 'flex', marginRight: '20px', alignItems: 'center', justifyContent: 'center' }}>

                          <span>
                            <label htmlFor="itemsPerPageSelect" className="itemsPerPage">Items per Page:</label>
                            <select
                              className="itemsPerPageSelect"
                              id="itemsPerPageSelect"
                              value={filteredItemsPerPage2}
                              onChange={closedhandleItemsPerPageChange}
                            >
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="75">75</option>
                              <option value="100">100</option>
                              <option value={filteredUserList}>All</option>
                            </select>
                          </span>&nbsp;&nbsp;&nbsp;

                          <span className="pagination" style={{ fontSize: '18px', marginTop: '10px' }}>
                            <ReactPaginate
                              key={filteredItemsPerPage2}
                              pageCount={filteredTotalPages2}
                              pageRangeDisplayed={0}
                              marginPagesDisplayed={0}
                              previousLabel="Prev"
                              nextLabel="Next"
                              // breakLabel="..."
                              onPageChange={closedhandlePageChange}
                              containerClassName="pagination"
                              activeClassName="active"
                              disableInitialCallback={true}
                              forcePage={filteredCurrentPage2}
                            />
                          </span>&nbsp;&nbsp;&nbsp;

                          <span className="pageNum">
                            Page {filteredCurrentPage2 + 1} of {filteredTotalPages2}
                          </span>
                          {/* &nbsp;&nbsp;&nbsp;<select value={currentPage} onChange={handleSelectedPageChange} style={{border:'0 0 1px solid black 0'}} >
                                {renderPageNumbers()}
                                </select>&nbsp;&nbsp;&nbsp; */}
                        </div>
                      </>
                  }
                </div>

                <div className="tab-pane active" id="tabs-2">
                  {/* <div className="text-right">
                        <button
                          className="tertiary btn-yellow-primary"
                          onClick={() => {
                            setShowReport(true);
                          }}
                        >
                          Download Report1
                        </button>
                      </div>   */}
                  <div className="table-scroll mb-4">
                    <table className="reviewTable userListTable">
                      <thead>
                        <tr>
                          <th className="text-left table-heading custom-td-left">
                            DIPP Number
                          </th>
                          <th className="text-left table-heading">
                            Startup Name
                          </th>

                          <th className="text-left table-heading">Sector</th>
                          <th className="text-left table-heading">
                            Associated Incubator
                          </th>
                          <th className="text-left table-heading">Instrument Approved</th>
                          <th className="text-center table-heading">
                            {" "}
                            Funds Approved (&#8377;)
                          </th>
                          <th className="text-center table-heading">
                            {" "}
                            Funds Disbursed (&#8377;)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {userListSelected.length == 0 ||
                          (filteredUserListSelected.length == 0 &&
                            noDataFilter != "") ? (
                          <tr>
                            <td className="text-center" colSpan={8}>
                              No data
                            </td>
                          </tr>
                        ) : filteredUserListSelected.length == 0 ? (
                          selectedcurrentPageData.map((item: any) => (
                            <tr key={item.id} onClick={() => handleRowChange(item.id)}>
                              <td className="text-left custom-td">
                                {item.recognition_number}
                              </td>
                              <td className="text-left custom-td">
                                {item.name_of_entity}
                              </td>

                              <td className="text-left custom-td">
                                {" "}
                                {JSON.parse(item.sectors).map(
                                  (industry: any) => industry.sector_name + " "
                                )}{" "}
                              </td>
                              <td className="text-left custom-td">

                                {item.associated_incubators.lenth != 0 ?
                                  (JSON.parse(item.associated_incubators).map(
                                    (industry: any) => industry.registered_name + " "
                                  ))
                                  : "-"}
                              </td>
                              <td className="text-left custom-td">
                                {item.instrument_approved}
                              </td>
                              <td className="text-center custom-td">
                                {item.total_applroved_amount != null
                                  ? changeNumberFormat(
                                    Number(item.total_applroved_amount),
                                    1,
                                    0
                                  )
                                  : 0}
                              </td>
                              <td className="text-center custom-td">
                                {item.total_disbursed_amount != ""
                                  ? changeNumberFormat(
                                    Number(item.total_disbursed_amount),
                                    1,
                                    0
                                  )
                                  : 0}
                              </td>
                            </tr>
                          ))
                        ) : (
                          filteredcurrentPageData.map((item: any) => (
                            <tr key={item.id} onClick={() => handleRowChange(item.id)}>
                              <td className="text-left custom-td">
                                {item.recognition_number}
                              </td>
                              <td className="text-left custom-td">
                                {item.name_of_entity}
                              </td>

                              <td className="text-left custom-td">
                                {" "}
                                {JSON.parse(item.sectors).map(
                                  (industry: any) => industry.sector_name + " "
                                )}{" "}
                              </td>
                              <td className="text-left custom-td">

                                {item.associated_incubators.lenth != 0 ?
                                  (JSON.parse(item.associated_incubators).map(
                                    (industry: any) => industry.registered_name + " "
                                  ))
                                  : "-"}
                              </td>
                              <td className="text-left custom-td">
                                {item.instrument_approved}
                              </td>
                              <td className="text-center custom-td">
                                {item.total_applroved_amount != null
                                  ? changeNumberFormat(
                                    Number(item.total_applroved_amount),
                                    1,
                                    0
                                  )
                                  : 0}
                              </td>
                              <td className="text-center custom-td">
                                {item.total_disbursed_amount != ""
                                  ? changeNumberFormat(
                                    Number(item.total_disbursed_amount),
                                    1,
                                    0
                                  )
                                  : 0}
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                  {userListSelected.length == 0 ? <></>
                    :
                    filteredUserListSelected.length == 0 ?
                      <div style={{ float: 'right', display: 'flex', marginRight: '20px', alignItems: 'center', justifyContent: 'center' }}>

                        <span>
                          <label htmlFor="itemsPerPageSelect" className="itemsPerPage">Items per Page:</label>
                          <select
                            className="itemsPerPageSelect"
                            id="itemsPerPageSelect"
                            value={selectedItemsPerPage}
                            onChange={selectedhandleItemsPerPageChange}
                          >
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="75">75</option>
                            <option value="100">100</option>
                            <option value={userListSelected.length}>All</option>

                          </select>
                        </span>&nbsp;&nbsp;&nbsp;

                        <span className="pagination" style={{ fontSize: '18px', marginTop: '10px' }}>
                          <ReactPaginate
                            key={selectedItemsPerPage}
                            pageCount={selectedTotalPages}
                            pageRangeDisplayed={0}
                            marginPagesDisplayed={0}
                            previousLabel="Prev"
                            nextLabel="Next"
                            // breakLabel="..."
                            onPageChange={selectedhandlePageChange}
                            containerClassName="pagination"
                            activeClassName="active"
                            disableInitialCallback={true}
                            forcePage={selectedCurrentPage}
                          />
                        </span>&nbsp;&nbsp;&nbsp;

                        <span className="pageNum">
                          Page {(selectedCurrentPage) + 1} of {selectedTotalPages}
                        </span>
                        {/* &nbsp;&nbsp;&nbsp;<select value={currentPage} onChange={handleSelectedPageChange} style={{border:'0 0 1px solid black 0'}} >
                        {renderPageNumbers()}
                      </select>&nbsp;&nbsp;&nbsp; */}
                      </div>

                      : <>
                        <div style={{ float: 'right', display: 'flex', marginRight: '20px', alignItems: 'center', justifyContent: 'center' }}>

                          <span>
                            <label htmlFor="itemsPerPageSelect" className="itemsPerPage">Items per Page:</label>
                            <select
                              className="itemsPerPageSelect"
                              id="itemsPerPageSelect"
                              value={filteredItemsPerPage}
                              onChange={selectedhandleItemsPerPageChange}
                            >
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="75">75</option>
                              <option value="100">100</option>
                              <option value={filteredUserListSelected.length}>All</option>
                            </select>
                          </span>&nbsp;&nbsp;&nbsp;

                          <span className="pagination" style={{ fontSize: '18px', marginTop: '10px' }}>
                            <ReactPaginate
                              key={filteredItemsPerPage}
                              pageCount={filteredTotalPages}
                              pageRangeDisplayed={0}
                              marginPagesDisplayed={0}
                              previousLabel="Prev"
                              nextLabel="Next"
                              // breakLabel="..."
                              onPageChange={selectedhandlePageChange}
                              containerClassName="pagination"
                              activeClassName="active"
                              disableInitialCallback={true}
                              forcePage={filteredCurrentPage}
                            />
                          </span>&nbsp;&nbsp;&nbsp;

                          <span className="pageNum">
                            Page {filteredCurrentPage + 1} of {filteredTotalPages}
                          </span>
                          {/* &nbsp;&nbsp;&nbsp;<select value={currentPage} onChange={handleSelectedPageChange} style={{border:'0 0 1px solid black 0'}} >
                                {renderPageNumbers()}
                                </select>&nbsp;&nbsp;&nbsp; */}
                        </div>
                      </>
                  }
                </div>

                <Modal
                  show={showReport}
                  onHide={() => setShowReport(false)}
                  dialogClassName="modal-90w modal-xl modal-dialog-scrollable table-bordered table-hover"
                  aria-labelledby="example-custom-modal-styling-title"
                >
                  <Modal.Header>
                    <Modal.Title id="example-custom-modal-styling-title">
                      Download StartUp Excel Report
                    </Modal.Title>
                    <button type="button" onClick={() => closesetShowReport()} className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </Modal.Header>
                  <Modal.Body>
                    <StartUpReportComponent countRef={count} />
                  </Modal.Body>
                </Modal>


              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  );
}

export default DpiitStartupListingComponent;
