
export class IncubatorPreferenceModel {
    constructor(
        public incubator_preference_1: any,
        public incubator_preference_2: any,
        public incubator_preference_3: any
    ) {
        this.incubator_preference_1 = incubator_preference_1;
        this.incubator_preference_2 = incubator_preference_2;
        this.incubator_preference_3 = incubator_preference_3;
    }
}