import { useEffect, useState } from 'react';
import { NODE_URL } from '../../../../constants/api';
import { useHistory } from 'react-router-dom';

import {
  GET_APPLCAITON_COMMENT,
  GET_APPLCAITON_COMMENT_NODE,
  GET_EAC_QUESTION_DETAILS,
  SAVE_APPLCAITON_COMMENT,
  SCHEDULE_MEETING_COUNT,
} from '../../../../constants/api';
import { post } from '../../../../effects/api/api.common';
import {
  EACReviewData,
  EACReviewDetails,
  MeetingDetails,
} from '../EACReviewDetails';
import { getSessionStorageValue } from '../../../../effects/utils/session-storage';


interface EACCommentsDataProps {
  EACCommentsData: any;
}
function MergedEACDetailsComponent(props: EACCommentsDataProps) {
  const history = useHistory();
  const { EACCommentsData } = props;
  const user: any = getSessionStorageValue('user');
  const userInfo = JSON.parse(user);
  //console.log("EACCommentsData",EACCommentsData);
  const formatDateWithMonth = (date: Date) => {
    var month = new Array();
    month[0] = 'Jan';
    month[1] = 'Feb';
    month[2] = 'Mar';
    month[3] = 'April';
    month[4] = 'May';
    month[5] = 'Jun';
    month[6] = 'Jul';
    month[7] = 'Aug';
    month[8] = 'Sep';
    month[9] = 'Oct';
    month[10] = 'Nov';
    month[11] = 'Dec';

    var d = new Date(date),
      monthn = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (monthn.length < 2) monthn = '0' + monthn;
    if (day.length < 2) day = '0' + day;

    return [day, month[d.getMonth()], year].join(' ');
  };
  const checkMeetingDate = (meeting_date: string) => {
    if (meeting_date != '') {
      var meet_date = new Date(meeting_date);
      var date1 = new Date();
      if (meet_date <= date1) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };
  const addUpdateComment = (comment: any) => {


    const new_Comment = {
      application_id: EACCommentsData.First_Application_EAC_Comments[0].incubator_application_id,
      meeting_type: meetingType,
      comment: comment,
      meeting_id: meetingId,
      logged_user_id:userInfo.id
    };
    post(SAVE_APPLCAITON_COMMENT, new_Comment).then((response: any) => {
      if (!response || !response.data || response.data == '') return;
      window.location.reload();
      // if (response.data != undefined && response.data != '') {
      //   history.push({
      //     pathname: '/dpiit_view_incubator',
      //     state: { application_id: generalDetail.id, isFundDetails: false },
      //   });
      //   window.location.reload();
      // }
    });
    setComments('');
  };
  const addUpdateComment1 = (comment: any) => {
    const new_Comment = {
      application_id: EACCommentsData.Second_Application_EAC_Comments[0].incubator_application_id,
      meeting_type: meetingType,
      comment: comment,
      meeting_id: meetingId,
      logged_user_id : userInfo.id
    };
    post(GET_APPLCAITON_COMMENT_NODE, new_Comment).then((response: any) => {
      if (!response || !response.data || response.data == '') return;
      if (response.data != undefined && response.data != '') {
        window.location.reload();
      }
    });
    setComments('');
  };
  const [comments, setComments] = useState('');
  const [meetingType, setMeetingType] = useState('');
  const [meetingId, setMeetingId] = useState('');
  const [application_comments, setApplicationComment] = useState<Array<MeetingDetails>>([new MeetingDetails(0, 0, '', 0, 0)]);
  const setMeetingModal = (
    meetingType: any,
    meetingId: number,
    comment: string
  ) => {
    setComments(comment);
    setMeetingId(meetingId.toString());
    setMeetingType(meetingType);
    // getMeetingData(meetingType, meetingId); 
  };
  const setMeetingModal1 = (
    meetingType: any,
    meetingId: number,
    comment: string
  ) => {
    setComments(comment);
    setMeetingId(meetingId.toString());
    setMeetingType(meetingType); 
    // SecgetMeetingData(meetingType, meetingId);
  };
  const handleOnChange = (e: any /*index:number*/) => {
    var value = e.target.value;
    setComments(value);
  };
  const getMeetingData = (meeting_type: any, meeting_id: any) => {
    const data_input = {
      application_id: EACCommentsData.First_Application_EAC_Comments[0].incubator_application_id,
      meeting_type: meeting_type,
      meeting_id: meeting_id,
      comment: "0"
    };
    post(GET_APPLCAITON_COMMENT_NODE, data_input).then((response: any) => {
      //console.log(response.data.data.comment);
      if (!response || !response.data || response.data == '') return;
      if (response.data != undefined && response.data != '') {
        if (response.data.data.comment != undefined) {
          setComments(response.data.data.comment);

          let updatedList = EACCommentsData.map((item: any) => {
            if (item.meeting_id == Number(meetingId)) {
              return { ...item, comment: response.data.data[0].comment }; //gets everything that was already in item, and updates "comments"
            }
            return item; // else return unmodified item
          });

          setApplicationComment(updatedList);
        } else {
          setComments('');
        }
      }
    });
  };
  const SecgetMeetingData = (meeting_type: any, meeting_id: any) => {
    const data_input = {
      application_id: EACCommentsData.Second_Application_EAC_Comments[0].incubator_application_id,
      meeting_type: meeting_type,
      meeting_id: meeting_id,
      comment: "0"
    };
    post(GET_APPLCAITON_COMMENT_NODE, data_input).then((response: any) => {
      //console.log(response.data.data.comment);
      if (!response || !response.data || response.data == '') return;
      if (response.data != undefined && response.data != '') {
        if (response.data.data.comment != undefined) {
          setComments(response.data.data.comment);

          let updatedList = EACCommentsData.map((item: any) => {
            if (item.meeting_id == Number(meetingId)) {
              return { ...item, comment: response.data.data[0].comment }; //gets everything that was already in item, and updates "comments"
            }
            return item; // else return unmodified item
          });

          setApplicationComment(updatedList);
        } else {
          setComments('');
        }
      }
    });
  };
  useEffect(() => {
    //console.log("@@@@########@@@@@@!!!!!!!!", EACCommentsData);
  }, []);


  return (
    <div className="container-fluid">
      {!EACCommentsData.Second_Application_EAC_Comments && !EACCommentsData.First_Application_EAC_Comments ? "" : ((EACCommentsData.Second_Application_EAC_Comments.length || EACCommentsData.First_Application_EAC_Comments.length) ? "" : (<h6 style={{ textAlign: 'center' }}>No Data</h6>))}

      {!EACCommentsData.Second_Application_EAC_Comments ? null :
    (EACCommentsData.Second_Application_EAC_Comments.length ?
      <div>
        <div className="row">
          <div className="col-md-12 left-align" style={{ backgroundColor: '#f8f7f7', padding: '5px' }}>
            <span className="eac-heading-title">2<sup>nd</sup> Application EAC Review</span>
          </div>
        </div>
        <div className="incubator-hr">
          <hr />
        </div>
        <div> 
          <div className="row">
            <div className="col-md left-align">
              <span className="eac-review-title">
                Incubator Performance Evaluation
              </span>
              <div className="incubator-hr">
          <hr />
        </div>
              <br />

              {EACCommentsData.Second_Application_EAC_Comments == undefined ||
                EACCommentsData.Second_Application_EAC_Comments.length == 0 ? (
                <span className="incubator-label">-</span>
              ) : (
                EACCommentsData.Second_Application_EAC_Comments.map((meeting: any) =>
                  meeting.meeting_type == 2 ? (
                    <div>
                      <div className="incubator-label">
                        {formatDateWithMonth(new Date(meeting.meeting_date))} |{' '}
                        {meeting.meeting_from_time} {'-'} {meeting.meeting_to_time}
                      </div>
                      <div className="row  mt-15-px">
                        <div className="col-md-10 left-align">
                          {checkMeetingDate(meeting.meeting_date) == true ? (
                            <div>
                              <span className="eac-review-subtitle">
                                EAC Comments/Feedback
                              </span>
                              <br />
                            </div>
                          ) : (
                            ''
                          )}
                          {EACCommentsData.Second_Application_EAC_Comments != undefined &&
                            EACCommentsData.Second_Application_EAC_Comments.length > 0
                            ? EACCommentsData.Second_Application_EAC_Comments
                              .filter(function (item :any) {
                                return item.meeting_type == 2; //&& item.meeting_id ==meeting.meeting_id
                              })
                              .map((item: any) =>
                                item.meeting_id === meeting.meeting_id ? (
                                  <div>
                                    <span className="eac-p"> {item.comment}</span>
                                    <br />
                                  </div>
                                ) : (
                                  ''
                                )
                              )
                            : ''}
                          {checkMeetingDate(meeting.meeting_date) == true ? (
                            <div>
                              <a
                                className="incubator-details-link"
                                data-toggle="modal"
                                data-target="#SecaddEditComment"
                                onClick={() =>
                                  setMeetingModal1(
                                    '2',
                                    meeting.meeting_id,
                                    meeting.comment
                                  )
                                }
                              >
                                Add Comment
                              </a>
                              <br />
                              <br />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )
                )
              )}

              {/* <span className="incubator-label">
        15 Jan 2021 | 10:30am - 11:30am
      </span> */}
            </div>
            {/* <div className="col-md-5"> */}
              {/* <label className="incubator-label">Funds Approved</label>
      <label className="eac-review-title">&#8377;2.5 L</label> */}
            {/* </div> */}
          </div>
          <div className="row">
            <div className="col-md-7 left-align">
              <span className="eac-review-title">Incubator Selection</span>
              {/* <div className="incubator-hr">
                <hr />
              </div> */}
              <br />
              {EACCommentsData.Second_Application_EAC_Comments == undefined ||
                EACCommentsData.Second_Application_EAC_Comments.length == 0 ? (
                <span className="incubator-label">-</span>
              ) : (
                EACCommentsData.Second_Application_EAC_Comments.map((meeting: any) =>
                  meeting.meeting_type == 1 ? (
                    <div>
                      <div className="incubator-label">
                        {formatDateWithMonth(new Date(meeting.meeting_date))} |{' '}
                        {meeting.meeting_from_time} {'-'} {meeting.meeting_to_time}
                      </div>
                      <div className="row  mt-15-px">
                        <div className="col-md-10 left-align">
                          {checkMeetingDate(meeting.meeting_date) == true ? (
                            <div>
                              <span className="eac-review-subtitle">
                                EAC Comments/Feedback
                              </span>
                              <br />
                            </div>
                          ) : (
                            ''
                          )}

                          {EACCommentsData.Second_Application_EAC_Comments != undefined &&
                            EACCommentsData.Second_Application_EAC_Comments.length > 0
                            ? EACCommentsData.Second_Application_EAC_Comments
                              .map((item: any) =>
                                item.meeting_id === meeting.meeting_id ? (
                                  <div>
                                    <span className="eac-p"> {item.comment}</span>
                                    <br />
                                  </div>
                                ) : (
                                  ''
                                )
                              )
                            : ''}
                          {checkMeetingDate(meeting.meeting_date) == true ? (
                            <div>
                              <a
                                className="incubator-details-link"
                                data-toggle="modal"
                                data-target="#SecaddEditComment"
                                onClick={() =>
                                  setMeetingModal1(
                                    '1',
                                    meeting.meeting_id,
                                    meeting.comment
                                  )
                                }
                              >
                                Add Comment
                              </a>
                              <br />
                              <br />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )
                )
              )}
            </div>
          </div>
          <div
            className="modal fade"
            id="SecaddEditComment"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-md"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header modal-header-border-none">
                  <h5 className="modal-title " id="exampleModalLongTitle">
                    {/* {comments == ""  && comments.length==0 ? 'Add' : 'Edit'}  */}
                    Add Meeting Comment<span className="eac-name-pop-up"></span>
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12 left">
                      <textarea
                        className="form-control"
                        name="comment"
                        value={comments}
                        rows={3}
                        placeholder="Enter Comment"
                        onChange={handleOnChange}
                      ></textarea>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 right">
                      <div className="btn-group mt-1-5 ">
                        <button
                          className="tertiary   btn-transparent-primary"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>

                        <button
                          type="button"
                          className="btn btn-sisfs btn-yellow-primary ml-2"
                          onClick={() => addUpdateComment1(comments)}
                          data-dismiss="modal"
                        >
                          {/* {comments == ""  && comments.length==0  ? 'Add' : 'Edit'} */}
                          Add Comment{' '}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="row pt-3 mb-3">
                    <div className="col-md-12 centerText"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
        </div>
      </div>
      : null)}<br></br>
      {!EACCommentsData.First_Application_EAC_Comments ? null :
        (EACCommentsData.First_Application_EAC_Comments.length ?
          <div>
            <div className="row">
              <div className="col-md-12 left-align" style={{ backgroundColor: '#f8f7f7', padding: '5px' }}>
                <span className="eac-heading-title">1<sup>st</sup> Application EAC Review</span>
              </div>
            </div>
            <div className="incubator-hr">
              <hr />
            </div>
            <div> 
              <div className="row">
                <div className="col-md left-align">
                  <span className="eac-review-title">
                    Incubator Performance Evaluation
                  </span>
                  <div className="incubator-hr">
              <hr />
            </div>
                  <br />

                  {EACCommentsData.First_Application_EAC_Comments == undefined ||
                    EACCommentsData.First_Application_EAC_Comments.length == 0 ? (
                    <span className="incubator-label">-</span>
                  ) : (
                    EACCommentsData.First_Application_EAC_Comments.map((meeting: any) =>
                      meeting.meeting_type == 2 ? (
                        <div>
                          <div className="incubator-label">
                            {formatDateWithMonth(new Date(meeting.meeting_date))} |{' '}
                            {meeting.meeting_from_time} {'-'} {meeting.meeting_to_time}
                          </div>
                          <div className="row  mt-15-px">
                            <div className="col-md-10 left-align">
                              {checkMeetingDate(meeting.meeting_date) == true ? (
                                <div>
                                  <span className="eac-review-subtitle">
                                    EAC Comments/Feedback
                                  </span>
                                  <br />
                                </div>
                              ) : (
                                ''
                              )}
                              {EACCommentsData.First_Application_EAC_Comments != undefined &&
                                EACCommentsData.First_Application_EAC_Comments.length > 0
                                ? EACCommentsData.First_Application_EAC_Comments
                                  .filter(function (item :any) {
                                    return item.meeting_type == 2; //&& item.meeting_id ==meeting.meeting_id
                                  })
                                  .map((item: any) =>
                                    item.meeting_id === meeting.meeting_id ? (
                                      <div>
                                        <span className="eac-p"> {item.comment}</span>
                                        <br />
                                      </div>
                                    ) : (
                                      ''
                                    )
                                  )
                                : ''}
                              {checkMeetingDate(meeting.meeting_date) == true ? (
                                <div>
                                  <a
                                    className="incubator-details-link"
                                    data-toggle="modal"
                                    data-target="#addEditComment"
                                    onClick={() =>
                                      setMeetingModal(
                                        '2',
                                        meeting.meeting_id,
                                        meeting.comment
                                      )
                                    }
                                  >
                                    Add Comment
                                  </a>
                                  <br />
                                  <br />
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )
                    )
                  )}

                  {/* <span className="incubator-label">
            15 Jan 2021 | 10:30am - 11:30am
          </span> */}
                </div>
                {/* <div className="col-md-5"> */}
                  {/* <label className="incubator-label">Funds Approved</label>
          <label className="eac-review-title">&#8377;2.5 L</label> */}
                {/* </div> */}
              </div>
              <div className="row">
                <div className="col-md-7 left-align">
                  <span className="eac-review-title">Incubator Selection</span>
                  {/* <div className="incubator-hr">
                    <hr />
                  </div> */}
                  <br />
                  {EACCommentsData.First_Application_EAC_Comments == undefined ||
                    EACCommentsData.First_Application_EAC_Comments.length == 0 ? (
                    <span className="incubator-label">-</span>
                  ) : (
                    EACCommentsData.First_Application_EAC_Comments.map((meeting: any) =>
                      meeting.meeting_type == 1 ? (
                        <div>
                          <div className="incubator-label">
                            {formatDateWithMonth(new Date(meeting.meeting_date))} |{' '}
                            {meeting.meeting_from_time} {'-'} {meeting.meeting_to_time}
                          </div>
                          <div className="row  mt-15-px">
                            <div className="col-md-10 left-align">
                              {checkMeetingDate(meeting.meeting_date) == true ? (
                                <div>
                                  <span className="eac-review-subtitle">
                                    EAC Comments/Feedback
                                  </span>
                                  <br />
                                </div>
                              ) : (
                                ''
                              )}

                              {EACCommentsData.First_Application_EAC_Comments != undefined &&
                                EACCommentsData.First_Application_EAC_Comments.length > 0
                                ? EACCommentsData.First_Application_EAC_Comments
                                  .map((item: any) =>
                                    item.meeting_id === meeting.meeting_id ? (
                                      <div>
                                        <span className="eac-p"> {item.comment}</span>
                                        <br />
                                      </div>
                                    ) : (
                                      ''
                                    )
                                  )
                                : ''}
                              {checkMeetingDate(meeting.meeting_date) == true ? (
                                <div>
                                  <a
                                    className="incubator-details-link"
                                    data-toggle="modal"
                                    data-target="#addEditComment"
                                    onClick={() =>
                                      setMeetingModal(
                                        '1',
                                        meeting.meeting_id,
                                        meeting.comment
                                      )
                                    }
                                  >
                                    Add Comment
                                  </a>
                                  <br />
                                  <br />
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )
                    )
                  )}
                </div>
              </div>
              <div
                className="modal fade"
                id="addEditComment"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered modal-md"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header modal-header-border-none">
                      <h5 className="modal-title " id="exampleModalLongTitle">
                        {/* {comments == ""  && comments.length==0 ? 'Add' : 'Edit'}  */}
                        Add Meeting Comment <span className="eac-name-pop-up"></span>
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12 left">
                          <textarea
                            className="form-control"
                            name="comment"
                            value={comments}
                            rows={3}
                            placeholder="Enter Comment"
                            onChange={handleOnChange}
                          ></textarea>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12 right">
                          <div className="btn-group mt-1-5 ">
                            <button
                              className="tertiary   btn-transparent-primary"
                              data-dismiss="modal"
                            >
                              Cancel
                            </button>

                            <button
                              type="button"
                              className="btn btn-sisfs btn-yellow-primary ml-2"
                              onClick={() => addUpdateComment(comments)}
                              data-dismiss="modal"
                            >
                              {/* {comments == ""  && comments.length==0  ? 'Add' : 'Edit'} */}
                              Add Comment{' '}
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="row pt-3 mb-3">
                        <div className="col-md-12 centerText"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            
            </div>
          </div>
          : null)}
    </div>
  )
}
export default MergedEACDetailsComponent;