import { useEffect, useState } from "react";
import {
    GET_ALL_STATES, GET_ALL_DISTRICTS,GRIEVANCE_STORE} from "../../constants/api";
import { get, post } from "../../effects/api/api.common";
import FileUploader from '@amodv/react-file-uploader';

import ConfirmationDialog from '../common/controls/ConfirmationDialog';
import { getSessionStorageValue } from "../../effects/utils/session-storage";

function GrievancesComponent() {
    const [countryStates, setCountryStates] = useState([]);
    const [stateDistricts, setStateDistricts] = useState([]);
    const [openUploadBlock, setOpenUploadBlock] = useState(false);    
    const [dialogData, setDialogData] = useState({
        "header": '',
        "text": ''
    });
    const [uploadedFile, setUploadedFile] = useState<Array<File>>([]);
    
    const [showIncubatorFailureModal, setShowIncubatorFailureModal] = useState(false);    
    const [showIncubatorSuccessModal, setShowIncubatorSuccessModal] = useState(false);
    const [contactData, setContactData] = useState({
        entity_type: '',
        name_of_entity: '',
        name_of_user: '',
        email_id: '',
        contact_number: '',
        state_id: '',
        city_id: '',
        grievance_type: '',
        grievance_against: '',
        grievance_description: ''
    });
    const [validationFlag, setValidationFlag] = useState({
        entity_type: false,
        name_of_entity: false,
        name_of_user: false,
        email_id: false,
        contact_number: false,
        state_id: false,
        city_id: false,
        grievance_type: false,
        grievance_against: false,
        grievance_description: false,
    });
    const [failDialogData, setFailDialogData] = useState({
        "header": '',
        "text": ''
    });

    useEffect(() => {
        window.scrollTo(0, 0)
        getAllStates();
    }, []);
    const getAllStates = () => {
        get(GET_ALL_STATES).then((response: any) => {
            if (!response || !response.data) return;

            const states = response.data.data.map((item: any) => ({
                label: item.name,
                value: item.id,
            }));
            setCountryStates(states);
        });
    };
    const handleChange = (
        evt: React.ChangeEvent<
            HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
        >
    ) => {
        const name = evt.target.name;
        const value = evt.target.value;
        if (name == "stateId") {
            getAllDistrictsByStateId(value);
            setContactData((values) => ({
                ...values,
                state_id: value,
            }));
        }
        else if (name == "entity_type") {
            setContactData((values) => ({
                ...values,
                entity_type: value,
            }));

        }
        else if (name == "name_of_entity") {
            setContactData((values) => ({
                ...values,
                name_of_entity: value,
            }));

        }
        else if (name == 'name_of_user') {
            setContactData((values) => ({
                ...values,
                name_of_user: value,
            }));
        }
        else if (name == 'contact_number') {
            setContactData((values) => ({
                ...values,
                contact_number: value,
            }));
        }
        else if (name == "cityId") {
            setContactData((values) => ({
                ...values,
                city_id: value,
            }));
        }
        else if (name == 'grievance_type') {
            setContactData((values) => ({
                ...values,
                grievance_type: value,
            }));
        }
        else if (name == 'grievance_against') {
            setContactData((values) => ({
                ...values,
                grievance_against: value,
            }));
        }
        else if (name == 'grievance_description') {
            setContactData((values) => ({
                ...values,
                grievance_description: value,
            }));
        }
        else if (name == 'email_id') {
            setContactData((values) => ({
                ...values,
                email_id: value,
            }));
        }
    }
    const handleValidate = (evt: React.ChangeEvent<
        HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
    ) => {
        const name = evt.target.name;
        const value = evt.target.value;
        if (name == "stateId") {
            if (contactData.state_id == "") {
                setValidationFlag((values) => ({
                    ...values,
                    state_id: true,
                }));
            }
            else {
                setValidationFlag((values) => ({
                    ...values,
                    state_id: false,
                }));
            }
        }
        else if (name == "entity_type") {
            if (contactData.entity_type == "") {
                setValidationFlag((values) => ({
                    ...values,
                    entity_type: true,
                }));
            }
            else {
                setValidationFlag((values) => ({
                    ...values,
                    entity_type: false,
                }));
            }

        }
        else if (name == "name_of_entity") {
            if (contactData.name_of_entity == "") {
                setValidationFlag((values) => ({
                    ...values,
                    name_of_entity: true,
                }));
            }
            else {
                setValidationFlag((values) => ({
                    ...values,
                    name_of_entity: false,
                }));
            }
        }
        else if (name == 'name_of_user') {
            if (contactData.name_of_user == "") {
                setValidationFlag((values) => ({
                    ...values,
                    name_of_user: true,
                }));
            }
            else {
                setValidationFlag((values) => ({
                    ...values,
                    name_of_user: false,
                }));
            }
        }
        else if (name == 'contact_number') {
            if (contactData.contact_number == "" || value.length != 10) {
                setValidationFlag((values) => ({
                    ...values,
                    contact_number: true,
                }));
            }
            else {
                setValidationFlag((values) => ({
                    ...values,
                    contact_number: false,
                }));
            }
        }
        else if (name == "cityId") {
            if (contactData.city_id == "") {
                setValidationFlag((values) => ({
                    ...values,
                    city_id: true,
                }));
            }
            else {
                setValidationFlag((values) => ({
                    ...values,
                    city_id: false,
                }));
            }
        }
        else if (name == 'grievance_type') {
            if (contactData.grievance_type == "") {
                setValidationFlag((values) => ({
                    ...values,
                    grievance_type: true,
                }));
            }
            else {
                setValidationFlag((values) => ({
                    ...values,
                    grievance_type: false,
                }));
            }
        }
        else if (name == 'grievance_against') {
            if (contactData.grievance_against == "") {
                setValidationFlag((values) => ({
                    ...values,
                    grievance_against: true,
                }));
            }
            else {
                setValidationFlag((values) => ({
                    ...values,
                    grievance_against: false,
                }));
            }
        }
        else if (name == 'grievance_description') {
            if (contactData.grievance_description == "") {
                setValidationFlag((values) => ({
                    ...values,
                    grievance_description: true,
                }));
            }
            else {
                setValidationFlag((values) => ({
                    ...values,
                    grievance_description: false,
                }));
            }
        }
        else if (name == 'email_id') {
            if (contactData.email_id == "" || validateEmail(value) == false) {
                setValidationFlag((values) => ({
                    ...values,
                    email_id: true,
                }));
            }
            else {
                setValidationFlag((values) => ({
                    ...values,
                    email_id: false,
                }));
            }
        }

    }

    function validateEmail(emailField: string) {
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

        if (reg.test(emailField) == false) {
            return false;
        }

        return true;

    }

    const dismissModal = () => {
        setShowIncubatorSuccessModal(false);
        setShowIncubatorFailureModal(false);        
        window.location.reload()
    }

    const getAllDistrictsByStateId = (stateId: string) => {


        get(`${GET_ALL_DISTRICTS}/${stateId}`).then((response: any) => {
            if (!response || !response?.data?.data) {
                setStateDistricts([]);
                return;
            }

            const districts = response?.data?.data.map((item: any) => ({
                label: item?.name,
                value: item?.id,
            }));
            setStateDistricts(districts);
        });
    };
    const submitGrievances = () => {
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user)
        if (contactData.city_id != "" && validationFlag.email_id == false && validationFlag.contact_number == false && contactData.contact_number != "" && contactData.email_id != "" && contactData.entity_type != "" && contactData.grievance_against != "" && contactData.grievance_description != "" && contactData.grievance_type != "" && contactData.name_of_entity != "" && contactData.name_of_user != "" && contactData.state_id != "") {
            // console.log(contactData)
            const formData = new FormData();
            formData.append("entity_type",contactData.entity_type);
            formData.append("name_of_entity", contactData.name_of_entity);
            formData.append("name_of_user", contactData.name_of_user);
            formData.append("email_id", contactData.email_id);
            formData.append("contact_number", contactData.contact_number);
            formData.append("state_id", contactData.state_id);
            formData.append("city_id", contactData.city_id);
            formData.append("grievance_type", contactData.grievance_type);
            formData.append("grievance_against", contactData.grievance_against);
            formData.append("grievance_description", contactData.grievance_description);
            formData.append("logged_user_id", userInfo.id)
            uploadedFile.length!=0&&formData.append("file_with_location", uploadedFile[0]);
            post(GRIEVANCE_STORE, formData).then((response: any) => {
                if (!response.data) return;
                if (response.data.grievanceFormId) {
                    setDialogData({
                        "header": "Thank You!",
                        "text": `${response.data.message} !!!`
                        // "Form Submitted Successfully !!!"
                    })
                    setShowIncubatorSuccessModal(true);
                }else if(!response.data.grievanceFormId){
                    setFailDialogData({
                        "header": 'Oops !',
                        "text": `${response.data.message}`
                    })
                    setShowIncubatorFailureModal(true);
                }
                else {
                    setFailDialogData({
                        "header": 'Oops ! Something went wrong,',
                        "text": 'Please Try Again !!'
                    })
                    setShowIncubatorFailureModal(true);
                }

            });

            
        }
        else {
            if (contactData.state_id == "") {
                setValidationFlag((values) => ({
                    ...values,
                    state_id: true,
                }));
            }
            if (contactData.entity_type == "") {
                setValidationFlag((values) => ({
                    ...values,
                    entity_type: true,
                }));
            }
            if (contactData.name_of_entity == "") {
                setValidationFlag((values) => ({
                    ...values,
                    name_of_entity: true,
                }));
            }
            if (contactData.name_of_user == "") {
                setValidationFlag((values) => ({
                    ...values,
                    name_of_user: true,
                }));
            }
            if (contactData.contact_number == "" || contactData.contact_number.length != 10) {
                setValidationFlag((values) => ({
                    ...values,
                    contact_number: true,
                }));
            }
            if (contactData.city_id == "") {
                setValidationFlag((values) => ({
                    ...values,
                    city_id: true,
                }));
            }
            if (contactData.grievance_type == "") {
                setValidationFlag((values) => ({
                    ...values,
                    grievance_type: true,
                }));
            }
            if (contactData.grievance_against == "") {
                setValidationFlag((values) => ({
                    ...values,
                    grievance_against: true,
                }));
            }
            if (contactData.grievance_description == "") {
                setValidationFlag((values) => ({
                    ...values,
                    grievance_description: true,
                }));
            }
            if (contactData.email_id == "" || validateEmail(contactData.email_id) == false) {
                setValidationFlag((values) => ({
                    ...values,
                    email_id: true,
                }));
            }
        }
    }

    const onFileUpload = (files: Array<File>) => {
       
        setUploadedFile(files);
    }
    const onFileDelete = () => {
        setUploadedFile([]);
       
    }
    return (
        <>
            <div className="container inside-container">
                <div className="app-inside-content app-inside-full">
                    <div className="row justify-content-center">
                        <div className="col-sm-10">

                            <div className="form-wrapper grievance-form">
                                <div className="row text-center">
                                    <label className="col-form-label">This form is only for startups and incubators. Please fill below form if you are facing issues during your evaluation or after your selection under the scheme</label>
                                </div>
                                <div className="form-group row mt-5">
                                    <label className="col-sm-12 col-md-4 col-form-label">
                                        Entity Type
                        </label>
                                    <div className="col-sm-12 col-md-8">
                                        <div className="select-custom">
                                            <select className="form-control" onChange={handleChange} onBlur={handleValidate} name="entity_type" value={contactData.entity_type} aria-label="Select Entity Type">
                                                <option value="" hidden>Select</option>
                                                {/* selected */}
                                                <option value="1">Startups</option>
                                                <option value="2">Incubators</option>
                                                {/* <option value="3">Three</option> */}
                                            </select>
                                        </div>
                                        {validationFlag.entity_type == true ? <label className="error-message">Please select Entity Type</label> : null}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-12 col-md-4 col-form-label">
                                        Name of the Entity
                        </label>
                                    <div className="col-sm-12 col-md-8">
                                        <input type="text" onChange={handleChange} onBlur={handleValidate} name="name_of_entity" value={contactData.name_of_entity} className="form-control" placeholder="Enter" />
                                        {validationFlag.name_of_entity == true ? <label className="error-message">Please select entity name</label> : null}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-12 col-md-4 col-form-label">
                                        Name
                        </label>
                                    <div className="col-sm-12 col-md-8">
                                        <input type="text" onChange={handleChange} onBlur={handleValidate} name="name_of_user" value={contactData.name_of_user} className="form-control" placeholder="Enter" />
                                        {validationFlag.name_of_user == true ? <label className="error-message">Please enter user name</label> : null}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-12 col-md-4 col-form-label">
                                        Email ID
                        </label>
                                    <div className="col-sm-12 col-md-8">
                                        <input type="text" onChange={handleChange} onBlur={handleValidate} name="email_id" className="form-control" value={contactData.email_id} placeholder="Enter" />
                                        {validationFlag.email_id == true ? <label className="error-message">Please enter valid email</label> : null}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-12 col-md-4 col-form-label">
                                        Mobile No.
                        </label>
                                    <div className="col-sm-8 col-md-5">
                                        <div className="input-group mb-2 mr-sm-2 mb-sm-0">
                                            <span className="input-group-addon">+91</span>
                                            <input type="text" onChange={handleChange} onBlur={handleValidate} name="contact_number" className="form-control" value={contactData.contact_number} placeholder="Ten digit mobile number" />

                                        </div>
                                        {validationFlag.contact_number == true ? <label className="error-message">Please enter valid number</label> : null}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-12 col-md-4 col-form-label">
                                        Location
                        </label>
                                    <div className="col-sm-12 col-md-8">
                                        <div className="form-row">
                                            <div className="col select-custom">
                                                <select
                                                    className="form-control"
                                                    aria-label="Select State"
                                                    id="State"
                                                    value={contactData.state_id}
                                                    name="stateId"
                                                    onChange={handleChange}
                                                    onBlur={handleValidate}
                                                >
                                                    <option value=""  hidden>Select State</option>
                                                    {/* selected */}
                                                    {countryStates.map(({ label, value }) => (
                                                        <option key={label} value={value}>
                                                            {label}
                                                        </option>
                                                    ))}
                                                </select>
                                                {validationFlag.state_id == true ? <label className="error-message">Please select state</label> : null}
                                            </div>

                                            <div className="col select-custom">
                                                <select
                                                    className="form-control"
                                                    aria-label="Select City"
                                                    value={contactData.city_id}
                                                    id="City"
                                                    name="cityId"
                                                    onChange={handleChange}
                                                    onBlur={handleValidate}
                                                >
                                                    <option value=""  hidden>Select City</option>
                                                    {/* selected */}
                                                    {stateDistricts.map(({ label, value }) => (
                                                        <option key={value} value={value}>
                                                            {label}
                                                        </option>
                                                    ))}
                                                </select>
                                                {validationFlag.city_id == true ? <label className="error-message">Please select city</label> : null}
                                            </div>


                                        </div>
                                    </div>
                                </div>

                                <hr />

                                <div className="form-group row">
                                    <label className="col-sm-12 col-md-4 col-form-label">
                                        Grievance Type
                                </label>
                                    <div className="col-sm-12 col-md-8">
                                        <div className="select-custom">
                                            <select className="form-control" onBlur={handleValidate} onChange={handleChange} name="grievance_type" value={contactData.grievance_type} aria-label="Select Entity Type">
                                                <option  hidden>Select</option>
                                                {/* selected */}
                                                <option value="1">Delay or issue in evaluation of application</option>
                                                <option value="2">Delay or issue in legal agreement</option>
                                                <option value="3">Delay or issue in the disbursement of funds</option>
                                                <option value="4">Violation of guidelines of the scheme</option>
                                                <option value="5">Trouble with incubator or startup/ Others</option>
                                            </select>
                                        </div>
                                        {validationFlag.grievance_type == true ? <label className="error-message">Please select grievance type</label> : null}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-12 col-md-4 col-form-label">
                                        Grievance Against
                                </label>
                                    <div className="col-sm-12 col-md-8">
                                        <div className="select-custom">
                                            <select className="form-control" onBlur={handleValidate} onChange={handleChange} name="grievance_against" value={contactData.grievance_against} aria-label="Select Entity Type">
                                                <option  hidden>Select</option>
                                                {/* selected */}
                                                <option value="1">Incubator</option>
                                                <option value="2">DPIIT</option>
                                                <option value="3">EAC</option>
                                                <option value="4">Startup</option>
                                            </select>
                                        </div>
                                        {validationFlag.grievance_against == true ? <label className="error-message">Please select grievance_against</label> : null}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-12 col-md-4 col-form-label">
                                        Grievance Description
                                </label>
                                    <div className="col-sm-12 col-md-8">
                                        <textarea className="form-control" onBlur={handleValidate} onChange={handleChange} name="grievance_description" value={contactData.grievance_description} rows={3} placeholder="Describe in detail" maxLength={500}></textarea>
                                        <small>Max. 500 characters</small>
                                        {validationFlag.grievance_description == true ? <label className="error-message">Please Enter Grievance Description</label> : null}
                                    </div>

                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-12 col-md-4 col-form-label">
                                        Upload Document (Optional)
                                </label>
                                    {/* <div className="col-sm-12 col-md-8">
                                        <button className="btn btn-sisfs btn-transparent-primary">Upload</button>
                                        <small>Please collate all relevant documents into a single .pdf file </small>
                                    </div> */}
                                     <div className="col-sm-12 col-md-8">
                                        <div className="">
                                            {/* <input type="file" className="form-control-file btn btn-sisfs btn-transparent-primary" id="exampleFormControlFile1" /> */}
                                            <button
                                                className="btn btn-sisfs btn-transparent-primary"
                                                onClick={() => setOpenUploadBlock(true)}
                                                disabled={false}
                                            >
                                                Upload
                </button>
                                            {(openUploadBlock || uploadedFile.length != 0) && (
                                                <>
                                                    <span
                                                        className="close"
                                                        onClick={() => setOpenUploadBlock(false)}
                                                    >
                                                        <i className="fa fa-close"></i>
                                                    </span>
                                                    <FileUploader
                                                        key={"grievance_doc"}
                                                        id={"grievance_doc"}
                                                        information="Drag & Drop the file or Browse"
                                                        fileType="application/pdf"
                                                        buttonLabel="Browse"
                                                        multiple={false}
                                                        enablePreview
                                                        IconComponent={() => (
                                                            <span className="fa fa-cloud-upload"></span>
                                                        )}
                                                        removeIconClass=""
                                                        existingFiles={uploadedFile}
                                                        onUploadFinish={(files: Array<File>) => {
                                                            onFileUpload(files);
                                                        }}
                                                        onFileDelete={(deletedFile: File) =>
                                                            onFileDelete()
                                                        }
                                                    />
                                                    <small>Please collate all relevant documents into a single .pdf file</small>

                                                </>
                                            )}
                                        </div>
                                        {/* {validationFlag.uploaded_doc == true ? <label className="error-message">Please select a document</label> : null} */}
                                    </div>
                                   
                                </div>

                                <div className="form-group row action-section">
                                    <label className="col-sm-12 col-md-4 col-form-label">

                                    </label>
                                    <div className="col-sm-12 col-md-8">
                                        <div className="btn-group">
                                            <button className="btn btn-sisfs btn-yellow-primary" onClick={submitGrievances} >Submit</button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {showIncubatorSuccessModal && (
                    // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
                    // <>
                    //     <div className="modal-backdrop fade show"></div>

                    //     <div
                    //         className="modal align-middle modal-sisfs d-block"
                    //         id="FormSubmissionSuccess"
                    //     >
                    //         <div className="modal-dialog modal-dialog-centered modal-md">
                    //             <div className="modal-content">
                    //                 <div className="modal-body text-center">
                    //                     <i className="fa fa-check-circle-o"></i>
                    //                     <h3 className="modal-message">Thank You!</h3>
                    //                     <div className="modal-text">
                    //                         Your Meeting Has been Scheduled
                    //             </div>
                    //                     <div className="modal-action">
                    //                         <button className="btn btn-sisfs btn-yellow-primary" onClick={dismissModal}>
                    //                             Ok!!
                    //             </button>
                    //                     </div>
                    //                 </div>
                    //             </div>
                    //         </div>
                    //     </div>
                    // </>
                    <ConfirmationDialog
                        headerText={dialogData.header}
                        bodyText={dialogData.text}
                        onDialogClose={dismissModal}
                    />
                )}
                 {showIncubatorFailureModal && (
                    // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
                    // <>
                    //     <div className="modal-backdrop fade show"></div>
                    //     <div
                    //         className="modal align-middle modal-sisfs d-block"
                    //         id="FormSubmissionError"
                    //     >
                    //         <div className="modal-dialog modal-dialog-centered modal-md">
                    //             <div className="modal-content">
                    //                 <div className="modal-body text-center">
                    //                     <img
                    //                         src={error}
                    //                         className="search mb-2"
                    //                         alt="search"

                    //                     />
                    //                     <h3 className="modal-message">
                    //                         {failDialogData.header}
                    //                     </h3>
                    //                     <h3 className="modal-text">
                    //                         {failDialogData.text}
                    //                     </h3>
                    //                     <div className="modal-action">
                    //                         <button className="btn btn-sisfs btn-yellow-primary" onClick={() => setShowIncubatorFailureModal(false)}>
                    //                             OK!
                    //           </button>
                    //                     </div>
                    //                 </div>
                    //             </div>
                    //         </div>
                    //     </div>
                    // </>
                    <ConfirmationDialog
                        headerText={failDialogData.header}
                        bodyText={failDialogData.text}
                        onDialogClose={() => setShowIncubatorFailureModal(false)}
                        iconClass="test"
                    />
                )}

       </>
    )
};

export default GrievancesComponent;