import { useEffect, useState } from "react";
import { STORE_STARTUP_APPLICATION, VIEW_STARTUP_APPLICATION } from "../../../../constants/api";
import { get } from "../../../../effects/api/api.common";
import { FileUploadTypeModel } from "../../../../models/FileUploadModel";
import { TeamDetails } from "../../../../models/incubator/IncubatorTeamModel";
import { FundingDetailsModel, PriorDetails } from "../../../../models/startup/FundingDetailsModel";
import { initAuthorizedRepresentative, initEntityDetail, initFundingDetails, initIncubatorPreference, initStartupDetail, initStartupTeam, initUploadDocuments, StartupModel } from "../../../../models/startup/StartupModel";
import { PromoterDetails } from "../../../../models/startup/StartupTeamModel";
import { createFile, downloadDocument } from "../../../../utils/CommonMethods";
import { FormatDate } from "../../../../utils/DateFormat";
import { File_node_url } from "../../../../constants/api";
import moment from "moment";

interface IStartupApplicationDetailsProps {
    applicationDetails: any;
    documentPath: any
}

interface IStartupApplicationDetailsState {

}

function StartupApplicationDetailsComponent(props: IStartupApplicationDetailsProps) {
    const {
        applicationDetails, documentPath
    } = props;

    const [startup, setStartup] = useState<StartupModel>(
        new StartupModel(
            "",
            initEntityDetail,
            initStartupDetail,
            initAuthorizedRepresentative,
            initStartupTeam,
            new FundingDetailsModel(
                "", "", 1, [{ expense_bucket: "", expense_amount: "", show_other: false, expense_bucket_other: '', deployment_start_date: '', deployment_end_date: '' }], "1", [new PriorDetails(0, moment(new Date()).format("YYYY-MM-DD"), null, "", "", "")]
            ),
            initIncubatorPreference,
            initUploadDocuments
        )
    );
    const [stateName, setStateName] = useState('');
    const [cityName, setCityName] = useState('');
    const [industryName, setIndustryName] = useState([]);
    const [sectorName, setSectorName] = useState([]);
    const [popUpName, setPopUpName] = useState("");
    const setMeetingModal = (fieldName: string) => {
        setPopUpName(fieldName);
    }

    useEffect(() => {

        // if (typeof props.location.state != "undefined") {
        //     setStartup(startup);
        //     viewActiveApplication(props.location.state.application_id);
        // }
        if (Object.keys(applicationDetails).length !== 0) {
            viewActiveApplication(applicationDetails);
        }

    }, [applicationDetails]);

    //view active application
    const viewActiveApplication = (response: any) => {
        // 1st Step
        bindEntityDetail(response, startup);

        // 2nd Step
        bindStartupDetail(response, startup);

        // 3rd Step
        bindAuthorizedRepresentative(response, startup);

        // 4th Step
        bindStartupTeam(response, startup);

        // 5th Step
        bindFundingDetails(response, startup);

        // 6th Step
        bindIncubatorPreference(response, startup);

        // 7th Step
        bindUploadDocuments(response, startup);

        setStateName(response.data.data.state_name)

        setCityName(response.data.data.city_name)

        setIndustryName(response.data.data.industries)

        setSectorName(response.data.data.sectors)

        setStartup((prevState: StartupModel) => {
            const updatedState = { ...prevState, startup };
            return updatedState;
        });


    };

    // Bind EntityDetail Step 1 response
    const bindEntityDetail = (response: any, startup: StartupModel) => {
        startup.EntityDetail.recognition_number =
            response.data.data.Application_Details.EntityDetail.recognition_number !== null ? response.data.data.Application_Details.EntityDetail.recognition_number : "";
        startup.EntityDetail.name_of_entity =
            response.data.data.Application_Details.EntityDetail.name_of_entity !== null ? response.data.data.Application_Details.EntityDetail.name_of_entity : "";
        startup.EntityDetail.nature_of_entity =
            response.data.data.Application_Details.EntityDetail.nature_of_entity !== null ? response.data.data.Application_Details.EntityDetail.nature_of_entity : "";
        startup.EntityDetail.industry_ids =
            response.data.data.Application_Details.EntityDetail.industry_ids;
        startup.EntityDetail.industry_id_other =
            response.data.data.Application_Details.EntityDetail.industry_id_other !== null ? response.data.data.Application_Details.EntityDetail.industry_id_other : "";
        startup.EntityDetail.industry_id_other_show =
            response.data.data.Application_Details.EntityDetail.industry_id_other_show;
        startup.EntityDetail.sector_ids =
            response.data.data.Application_Details.EntityDetail.sector_ids;
        startup.EntityDetail.company_information_number =
            response.data.data.Application_Details.EntityDetail.company_information_number !== null ? response.data.data.Application_Details.EntityDetail.company_information_number : "";
        startup.EntityDetail.registration_date =
            response.data.data.Application_Details.EntityDetail.registration_date ? response.data.data.Application_Details.EntityDetail.registration_date
                : '';
        startup.EntityDetail.pan_number =
            response.data.data.Application_Details.EntityDetail.pan_number !== null ? response.data.data.Application_Details.EntityDetail.pan_number : "";
        startup.EntityDetail.state_id =
            response.data.data.Application_Details.EntityDetail.state_id;
        startup.EntityDetail.city_id =
            response.data.data.Application_Details.EntityDetail.city_id;
        startup.EntityDetail.startup_address =
            response.data.data.Application_Details.EntityDetail.startup_address !== null ? response.data.data.Application_Details.EntityDetail.startup_address : "";
        // setStartup(startup);
    }

    // Bind StartupDetail Step 2 response
    const bindStartupDetail = (response: any, startup: StartupModel) => {
        startup.StartupDetail.is_technology_startup =
            response.data.data.Application_Details.StartupDetail.is_technology_startup !== null ? response.data.data.Application_Details.StartupDetail.is_technology_startup : "";
        startup.StartupDetail.solving_problem_for =
            response.data.data.Application_Details.StartupDetail.solving_problem_for !== null ? response.data.data.Application_Details.StartupDetail.solving_problem_for : "";
        startup.StartupDetail.value_proposition_for_problem =
            response.data.data.Application_Details.StartupDetail.value_proposition_for_problem !== null ? response.data.data.Application_Details.StartupDetail.value_proposition_for_problem : "";
        startup.StartupDetail.unique_selling_point =
            response.data.data.Application_Details.StartupDetail.unique_selling_point !== null ? response.data.data.Application_Details.StartupDetail.unique_selling_point : "";
        startup.StartupDetail.target_customer_segment =
            response.data.data.Application_Details.StartupDetail.target_customer_segment !== null ? response.data.data.Application_Details.StartupDetail.target_customer_segment : "";
        startup.StartupDetail.market_size_opportunity =
            response.data.data.Application_Details.StartupDetail.market_size_opportunity !== null ? response.data.data.Application_Details.StartupDetail.market_size_opportunity : "";
        startup.StartupDetail.aim_to_scale_up =
            response.data.data.Application_Details.StartupDetail.aim_to_scale_up !== null ? response.data.data.Application_Details.StartupDetail.aim_to_scale_up : "";
        startup.StartupDetail.revenue_model =
            response.data.data.Application_Details.StartupDetail.revenue_model !== null ? response.data.data.Application_Details.StartupDetail.revenue_model : "";
        // startup.StartupDetail.key_competitors =
        //     response.data.data.Application_Details.StartupDetail.key_competitors;
        startup.StartupDetail.competitors_startup_desc =
            response.data.data.Application_Details.StartupDetail.competitors_startup_desc !== null ? response.data.data.Application_Details.StartupDetail.competitors_startup_desc : "";
        startup.StartupDetail.competitors_msme_desc =
            response.data.data.Application_Details.StartupDetail.competitors_msme_desc !== null ? response.data.data.Application_Details.StartupDetail.competitors_msme_desc : "";
        startup.StartupDetail.competitors_corporate_desc =
            response.data.data.Application_Details.StartupDetail.competitors_corporate_desc !== null ? response.data.data.Application_Details.StartupDetail.competitors_corporate_desc : "";
        startup.StartupDetail.competitors_other_desc =
            response.data.data.Application_Details.StartupDetail.competitors_other_desc !== null ? response.data.data.Application_Details.StartupDetail.competitors_other_desc : "";
        startup.StartupDetail.competitors_startup_enable =
            response.data.data.Application_Details.StartupDetail.competitors_startup_enable;
        startup.StartupDetail.competitors_msme_enable =
            response.data.data.Application_Details.StartupDetail.competitors_msme_enable;
        startup.StartupDetail.competitors_corporate_enable =
            response.data.data.Application_Details.StartupDetail.competitors_corporate_enable;
        startup.StartupDetail.competitors_other_enable =
            response.data.data.Application_Details.StartupDetail.competitors_other_enable;
        startup.StartupDetail.website_url =
            response.data.data.Application_Details.StartupDetail.website_url !== null ? response.data.data.Application_Details.StartupDetail.website_url : "";
        startup.StartupDetail.facebook_link =
            response.data.data.Application_Details.StartupDetail.facebook_link !== null ? response.data.data.Application_Details.StartupDetail.facebook_link : "";
        startup.StartupDetail.facebook_enable =
            response.data.data.Application_Details.StartupDetail.facebook_enable;
        startup.StartupDetail.twitter_link =
            response.data.data.Application_Details.StartupDetail.twitter_link !== null ? response.data.data.Application_Details.StartupDetail.twitter_link : "";
        startup.StartupDetail.twitter_enable =
            response.data.data.Application_Details.StartupDetail.twitter_enable;
        startup.StartupDetail.linkedin_link =
            response.data.data.Application_Details.StartupDetail.linkedin_link !== null ? response.data.data.Application_Details.StartupDetail.linkedin_link : "";
        startup.StartupDetail.linkedin_enable =
            response.data.data.Application_Details.StartupDetail.linkedin_enable;
        startup.StartupDetail.youtube_link =
            response.data.data.Application_Details.StartupDetail.youtube_link !== null ? response.data.data.Application_Details.StartupDetail.youtube_link : "";
        startup.StartupDetail.youtube_enable =
            response.data.data.Application_Details.StartupDetail.youtube_enable;
        startup.StartupDetail.other_link =
            response.data.data.Application_Details.StartupDetail.other_link !== null ? response.data.data.Application_Details.StartupDetail.other_link : "";
        startup.StartupDetail.other_enable =
            response.data.data.Application_Details.StartupDetail.other_enable;
    }

    // Bind StartupDetail Step 3 response
    const bindAuthorizedRepresentative = (response: any, startup: StartupModel) => {
        startup.AuthorizedRepresentative.representative_firstname =
            response.data.data.Application_Details.AuthorizedRepresentative.representative_firstname !== null ? response.data.data.Application_Details.AuthorizedRepresentative.representative_firstname : "";
        startup.AuthorizedRepresentative.representative_lastname =
            response.data.data.Application_Details.AuthorizedRepresentative.representative_lastname !== null ? response.data.data.Application_Details.AuthorizedRepresentative.representative_lastname : "";
        startup.AuthorizedRepresentative.representative_designation =
            response.data.data.Application_Details.AuthorizedRepresentative.representative_designation !== null ? response.data.data.Application_Details.AuthorizedRepresentative.representative_designation : "";
        startup.AuthorizedRepresentative.representative_mobile_no =
            response.data.data.Application_Details.AuthorizedRepresentative.representative_mobile_no !== null ? response.data.data.Application_Details.AuthorizedRepresentative.representative_mobile_no : "";
        startup.AuthorizedRepresentative.representative_email =
            response.data.data.Application_Details.AuthorizedRepresentative.representative_email !== null ? response.data.data.Application_Details.AuthorizedRepresentative.representative_email : "";
        startup.AuthorizedRepresentative.authorisation_letter_upload =
            response.data.data.Application_Details.AuthorizedRepresentative.authorisation_letter_upload.length != 0
                ? response.data.data.Application_Details.AuthorizedRepresentative.authorisation_letter_upload
                : [];
    }

    // Bind StartupDetail Step 4 response
    const bindStartupTeam = (response: any, startup: StartupModel) => {
        startup.StartupTeam.ceo_firstname =
            response.data.data.Application_Details.StartupTeam.ceo_firstname !== null ? response.data.data.Application_Details.StartupTeam.ceo_firstname : "";
        startup.StartupTeam.ceo_lastname =
            response.data.data.Application_Details.StartupTeam.ceo_lastname !== null ? response.data.data.Application_Details.StartupTeam.ceo_lastname : "";
        startup.StartupTeam.ceo_description =
            response.data.data.Application_Details.StartupTeam.ceo_description !== null ? response.data.data.Application_Details.StartupTeam.ceo_description : "";
        startup.StartupTeam.ceo_linkedin_link =
            response.data.data.Application_Details.StartupTeam.ceo_linkedin_link !== null ? response.data.data.Application_Details.StartupTeam.ceo_linkedin_link : "";
        startup.StartupTeam.fulltime_employees_no =
            response.data.data.Application_Details.StartupTeam.fulltime_employees_no;
        startup.StartupTeam.team_data =
            response.data.data.Application_Details.StartupTeam.team_data.length
                ? response.data.data.Application_Details.StartupTeam.team_data
                : [new TeamDetails(0, "", 0)];
        startup.StartupTeam.promoter_data =
            response.data.data.Application_Details.StartupTeam.promoter_data.length != 0
                ? response.data.data.Application_Details.StartupTeam.promoter_data
                : [];
        startup.StartupTeam.promoters_aadhar_cards =
            response.data.data.Application_Details.StartupTeam.promoters_aadhar_cards.length != 0
                ? response.data.data.Application_Details.StartupTeam.promoters_aadhar_cards
                : [];
        // response.data.data.Application_Details.StartupTeam.team_data;
    }

    // Bind StartupDetail Step 5 response
    const bindFundingDetails = (response: any, startup: StartupModel) => {
        startup.FundingDetails.received_monetary_support_under_scheme =
            response.data.data.Application_Details.FundingDetails.received_monetary_support_under_scheme !== null ? response.data.data.Application_Details.FundingDetails.received_monetary_support_under_scheme : "";
        startup.FundingDetails.instrument_applying_for =
            response.data.data.Application_Details.FundingDetails.instrument_applying_for !== null ? response.data.data.Application_Details.FundingDetails.instrument_applying_for : "";
        startup.FundingDetails.quantum_funds_required =
            response.data.data.Application_Details.FundingDetails.quantum_funds_required;
        startup.FundingDetails.fund_expense_data =
            response.data.data.Application_Details.FundingDetails.fund_expense_data.length != 0 ? response.data.data.Application_Details.FundingDetails.fund_expense_data : [{ expense_bucket: "", expense_amount: "", show_other: false, expense_bucket_other: '', deployment_start_date: '', deployment_end_date: '' }]
        startup.FundingDetails.prior_funds_applicable = response.data.data.Application_Details.FundingDetails.prior_funds_applicable;
        startup.FundingDetails.prior_fund_data = response.data.data.Application_Details.FundingDetails.prior_fund_data.length != 0
            ? response.data.data.Application_Details.FundingDetails.prior_fund_data
            : [new PriorDetails(0, moment(new Date()).format("YYYY-MM-DD"), null, "", "", "")]
        //  response.data.data.Application_Details.FundingDetails.fund_expense_data;
    }

    // Bind StartupDetail Step 6 response
    const bindIncubatorPreference = (response: any, startup: StartupModel) => {
        startup.IncubatorPreference.incubator_preference_1 =
            response.data.data.Application_Details.IncubatorPreference.incubator_preference_1 == null ? "" : response.data.data.Application_Details.IncubatorPreference.incubator_preference_1;
        startup.IncubatorPreference.incubator_preference_2 =
            response.data.data.Application_Details.IncubatorPreference.incubator_preference_2 == null ? "" : response.data.data.Application_Details.IncubatorPreference.incubator_preference_2;
        startup.IncubatorPreference.incubator_preference_3 =
            response.data.data.Application_Details.IncubatorPreference.incubator_preference_3 == null ? "" : response.data.data.Application_Details.IncubatorPreference.incubator_preference_3;
    }

    // Bind StartupDetail Step 7 response
    const bindUploadDocuments = (response: any, startup: StartupModel) => {
        startup.UploadDocuments.upload_pitch_deck =
            response.data.data.Application_Details.UploadDocuments.upload_pitch_deck;
        startup.UploadDocuments.video_url_for_product =
            response.data.data.Application_Details.UploadDocuments.video_url_for_product !== null ? response.data.data.Application_Details.UploadDocuments.video_url_for_product : "";
        startup.UploadDocuments.upload_video_for_product =
            response.data.data.Application_Details.UploadDocuments.upload_video_for_product;
        startup.UploadDocuments.upload_relavent_document_other =
            response.data.data.Application_Details.UploadDocuments.upload_relavent_document_other;
        // const file_data = response.data.data.UploadDocuments
        // if (file_data) {
        //     startup.UploadDocuments.upload_pitch_deck = [];
        //     startup.UploadDocuments.upload_video_for_product = [];
        //     startup.UploadDocuments.upload_relavent_document_other = [];
        //     setStartup((prevState: StartupModel) => {
        //         const updatedState = { ...prevState, startup };
        //         return updatedState;
        //     });
        //     Object.keys(file_data).length &&
        //         Object.keys(file_data).map((key: string) => {

        //             switch (key) {

        //                 case "upload_pitch_deck":
        //                     // if(file_data[key]?.length){
        //                     //   tempIncubator.GeneralDetail.annual_report = file_data[key]
        //                     // }
        //                     file_data[key].map((data: any) => {
        //                         createFile(data.file).then((blob: any) => {
        //                             blob.lastModifiedDate = new Date();
        //                             const src = data.file as string;
        //                             blob.name = src.substr(src.lastIndexOf("/") + 1, src.length);
        //                             const file: FileUploadTypeModel = new FileUploadTypeModel(
        //                                 data.file_id,
        //                                 data.file_type,
        //                                 blob as File
        //                             );
        //                             startup.UploadDocuments.upload_pitch_deck = [
        //                                 ...startup.UploadDocuments.upload_pitch_deck,
        //                                 file,
        //                             ];
        //                             setStartup((prevState: StartupModel) => {
        //                                 const updatedState = { ...prevState, startup };
        //                                 return updatedState;
        //                             });
        //                         });
        //                     });

        //                     break;
        //                 case "upload_video_for_product":
        //                     // if(file_data[key]?.length){
        //                     //   tempIncubator.GeneralDetail.annual_report = file_data[key]
        //                     // }
        //                     file_data[key].map((data: any) => {
        //                         createFile(data.file).then((blob: any) => {
        //                             blob.lastModifiedDate = new Date();
        //                             const src = data.file as string;
        //                             blob.name = src.substr(src.lastIndexOf("/") + 1, src.length);
        //                             // const file: FileUploadModel = new FileUploadModel(
        //                             //     data.file_id,
        //                             //     data.file_type_id,
        //                             //     blob as File
        //                             // );
        //                             const file: FileUploadTypeModel = new FileUploadTypeModel(
        //                                 data.file_id,
        //                                 data.file_type,
        //                                 blob as File
        //                             );
        //                             startup.UploadDocuments.upload_video_for_product = [
        //                                 ...startup.UploadDocuments.upload_video_for_product,
        //                                 file,
        //                             ];
        //                             setStartup((prevState: StartupModel) => {
        //                                 const updatedState = { ...prevState, startup };
        //                                 return updatedState;
        //                             });
        //                         });
        //                     });

        //                     break;
        //                 case "upload_relavent_document_other":
        //                     // if(file_data[key]?.length){
        //                     //   tempIncubator.GeneralDetail.annual_report = file_data[key]
        //                     // }
        //                     file_data[key].map((data: any) => {
        //                         createFile(data.file).then((blob: any) => {
        //                             blob.lastModifiedDate = new Date();
        //                             const src = data.file as string;
        //                             blob.name = src.substr(src.lastIndexOf("/") + 1, src.length);
        //                             // const file: FileUploadModel = new FileUploadModel(
        //                             //     data.file_id,
        //                             //     data.file_type_id,
        //                             //     blob as File
        //                             // );
        //                             const file: FileUploadTypeModel = new FileUploadTypeModel(
        //                                 data.file_id,
        //                                 data.file_type,
        //                                 blob as File
        //                             );
        //                             startup.UploadDocuments.upload_relavent_document_other = [
        //                                 ...startup.UploadDocuments.upload_relavent_document_other,
        //                                 file,
        //                             ];
        //                             setStartup((prevState: StartupModel) => {
        //                                 const updatedState = { ...prevState, startup };
        //                                 return updatedState;
        //                             });
        //                         });
        //                     });

        //                     break;

        //             }
        //         });
        // }
    }

    return (
        <>

            {/* APPLICATION DETAILS ACCORDION START*/}
            <div className="accordion accordion-details-wrap" id="accordion-application-details">
                <div className="card accordion-card">
                    <div className="card-header accordion-card-header" data-toggle="collapse" data-target="#application_collapse_1" aria-expanded="true">
                        Entity Details
                    </div>
                    <div id="application_collapse_1" className="collapse show" data-parent="#accordion-application-details">
                        <div className="card-body accordion-card-body">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            DPIIT Recognition Number
                                        </div>
                                        <div className="startup-info-group-value">
                                            {startup.EntityDetail.recognition_number != "" ?
                                                startup.EntityDetail.recognition_number :
                                                '-'
                                            }
                                        </div>
                                    </div>
                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            Nature of Industry
                                        </div>
                                        <div className="startup-info-group-value">
                                            {startup.EntityDetail.nature_of_entity != "" ?
                                                startup.EntityDetail.nature_of_entity :
                                                '-'
                                            }
                                        </div>
                                    </div>
                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            Date of Incorporation/Registration
                                        </div>
                                        <div className="startup-info-group-value">
                                            {startup.EntityDetail.registration_date != "" ?
                                                FormatDate(startup.EntityDetail.registration_date) :
                                                '-'
                                            }
                                        </div>
                                    </div>
                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            Industry
                                        </div>
                                        <div className="startup-info-group-value">
                                            <ul className="startup-info-group-value-list">
                                                {industryName.map((industry: any, index: any) => (
                                                    index <= 4 &&
                                                    <li key={index}>{
                                                        industry.industry_name != "" ?
                                                            industry.industry_name :
                                                            '-'
                                                    }</li>
                                                ))}

                                            </ul>
                                            {startup.EntityDetail.industry_ids.length > 5 ?
                                                <a
                                                    className="incubator-details-link"
                                                    data-toggle="modal"
                                                    data-target="#showMore"
                                                    onClick={() => setMeetingModal('Industry')}
                                                >
                                                    More...
                                                </a>
                                                : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            Name of the Entity
                                        </div>
                                        <div className="startup-info-group-value">
                                            {startup.EntityDetail.name_of_entity != "" ?
                                                startup.EntityDetail.name_of_entity :
                                                '-'
                                            }
                                        </div>
                                    </div>
                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            Industry (Others)
                                        </div>
                                        <div className="startup-info-group-value">
                                            {startup.EntityDetail.industry_id_other != "" ?
                                                startup.EntityDetail.industry_id_other :
                                                '-'
                                            }
                                        </div>
                                    </div>
                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            Sector
                                        </div>
                                        <div className="startup-info-group-value">
                                            <ul className="startup-info-group-value-list">
                                                {sectorName.map((sector: any, index: any) => (
                                                    index <= 4 &&
                                                    <li key={index}>
                                                        {sector.sector_name}
                                                    </li>
                                                ))}
                                            </ul>
                                            {startup.EntityDetail.sector_ids.length > 5 ?
                                                <a
                                                    className="incubator-details-link"
                                                    data-toggle="modal"
                                                    data-target="#showMore"
                                                    onClick={() => setMeetingModal('Sector')}
                                                >
                                                    More...
                                                </a>
                                                : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="row mt-4">
                                <div className="col-lg-6">
                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">Corporate Identification Number</div>
                                        <div className="startup-info-group-value">
                                            {startup.EntityDetail.company_information_number != "" ?
                                                startup.EntityDetail.company_information_number :
                                                '-'
                                            }
                                        </div>
                                    </div>
                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">City</div>
                                        <div className="startup-info-group-value"> {cityName != "" ?
                                            cityName :
                                            '-'
                                        }</div>
                                    </div>
                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">PAN</div>
                                        <div className="startup-info-group-value">
                                            {startup.EntityDetail.pan_number != "" ?
                                                startup.EntityDetail.pan_number :
                                                '-'
                                            }</div>
                                        {/* <div className="startup-info-group-value-document">
                                            <div className="clearfix">
                                                <div className="pull-left document-img">
                                                    <img src="https://via.placeholder.com/70x50" className="img-fluid" />
                                                </div>
                                                <div className="pull-left">
                                                    <div className="document-name"> {startup.EntityDetail.pan_number}</div>
                                                    <a className="tab-pane-link tab-pane-link-view">View Card Details</a>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="startup-info-group">
                                        {/* <div className="startup-info-group-label">Legal Agreement Signed by Incubator</div>
                                        <div className="startup-info-group-value">
                                            <button className="btn btn-sisfs btn-transparent-primary mt-2">Upload Document</button>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    {/* <div className="startup-info-group">
                                        <div className="startup-info-group-label">Incorporation Certificate</div>
                                        <div className="document-info document-pdf-icon">
                                            <div className="document-info-name">Application-No.422copy2final.pdf</div>
                                            <a className="tab-pane-link tab-pane-link-view">View | Upload</a>
                                        </div>
                                    </div> */}
                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">State</div>
                                        <div className="startup-info-group-value"> {stateName != "" ?
                                            stateName :
                                            '-'
                                        }</div>
                                    </div>
                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">Registered Address</div>
                                        <div className="startup-info-group-value">
                                            {startup.EntityDetail.startup_address != "" ?
                                                startup.EntityDetail.startup_address :
                                                '-'
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card accordion-card">
                    <div className="card-header accordion-card-header collapsed" data-toggle="collapse" data-target="#application_collapse_2" aria-expanded="false">
                        Startup Details
                    </div>
                    <div id="application_collapse_2" className="collapse" data-parent="#accordion-application-details">
                        <div className="card-body accordion-card-body">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            Is it a Technology Startup ?
                                        </div>
                                        <div className="startup-info-group-value">
                                            {startup.StartupDetail.is_technology_startup != "" ?
                                                startup.StartupDetail.is_technology_startup :
                                                '-'
                                            }
                                        </div>
                                    </div>
                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            What is the problem you are solving?
                                        </div>
                                        <div className="startup-info-group-value">
                                            {startup.StartupDetail.solving_problem_for != "" ?
                                                startup.StartupDetail.solving_problem_for :
                                                '-'
                                            }
                                        </div>
                                    </div>
                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            What is your value proposition for this problem?
                                        </div>
                                        <div className="startup-info-group-value">
                                            {startup.StartupDetail.value_proposition_for_problem != "" ?
                                                startup.StartupDetail.value_proposition_for_problem :
                                                '-'
                                            }
                                        </div>
                                    </div>
                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            What is your unique selling point?
                                        </div>
                                        <div className="startup-info-group-value">
                                            {startup.StartupDetail.unique_selling_point != "" ?
                                                startup.StartupDetail.unique_selling_point :
                                                '-'
                                            }
                                        </div>
                                    </div>
                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            What is your target customer segment?
                                        </div>
                                        <div className="startup-info-group-value">
                                            {startup.StartupDetail.target_customer_segment != "" ?
                                                startup.StartupDetail.target_customer_segment :
                                                '-'
                                            }
                                        </div>
                                    </div>
                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            Who are your key competitors?
                                        </div>
                                        <div className="startup-info-group-value">
                                            <ul className="startup-info-group-value-list">
                                                {startup.StartupDetail.competitors_startup_desc &&
                                                    <li>{startup.StartupDetail.competitors_startup_desc}</li>
                                                }
                                                {startup.StartupDetail.competitors_msme_desc &&
                                                    <li>{startup.StartupDetail.competitors_msme_desc}</li>
                                                }
                                                {startup.StartupDetail.competitors_corporate_desc &&
                                                    <li>{startup.StartupDetail.competitors_corporate_desc}</li>
                                                }
                                                {startup.StartupDetail.competitors_other_desc &&
                                                    <li>{startup.StartupDetail.competitors_other_desc}</li>
                                                }
                                            </ul>
                                        </div>
                                    </div>


                                </div>

                                <div className="col-lg-6">
                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            What is the market size of the  opportunity?
                                        </div>
                                        <div className="startup-info-group-value">
                                            &#8377;{' '}
                                            {startup.StartupDetail.market_size_opportunity != 0 ?
                                                + startup.StartupDetail.market_size_opportunity :
                                                '0'
                                            }
                                            {/* {startup.StartupDetail.market_size_opportunity != 0 ?
                                                startup.StartupDetail.market_size_opportunity :
                                                '-'
                                            } */}
                                        </div>
                                    </div>
                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            How do you aim to scale-up?
                                        </div>
                                        <div className="startup-info-group-value">
                                            {startup.StartupDetail.aim_to_scale_up != "" ?
                                                startup.StartupDetail.aim_to_scale_up :
                                                '-'
                                            }
                                        </div>
                                    </div>
                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            What will be the revenue model?
                                        </div>
                                        <div className="startup-info-group-value">
                                            {startup.StartupDetail.revenue_model != "" ?
                                                startup.StartupDetail.revenue_model :
                                                '-'
                                            }
                                        </div>
                                    </div>
                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            Website URL
                                        </div>
                                        <div className="startup-info-group-value">
                                            {startup.StartupDetail.website_url != "" ?
                                                startup.StartupDetail.website_url :
                                                '-'
                                            }
                                        </div>
                                    </div>
                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            Social Media
                                        </div>
                                        <div className="startup-info-group-value">
                                            <ul className="startup-info-group-value-list">
                                                {startup.StartupDetail.facebook_enable &&
                                                    <li>{startup.StartupDetail.facebook_link}</li>
                                                }
                                                {startup.StartupDetail.linkedin_enable &&
                                                    <li>{startup.StartupDetail.linkedin_link}</li>
                                                }
                                                {startup.StartupDetail.twitter_enable &&
                                                    <li>{startup.StartupDetail.twitter_link}</li>
                                                }
                                                {startup.StartupDetail.youtube_enable &&
                                                    <li>{startup.StartupDetail.youtube_link}</li>
                                                }
                                                {startup.StartupDetail.other_enable &&
                                                    <li>{startup.StartupDetail.other_link}</li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card accordion-card">
                    <div className="card-header accordion-card-header collapsed" data-toggle="collapse" data-target="#application_collapse_3" aria-expanded="false">
                        Authorized Representative
                    </div>
                    <div id="application_collapse_3" className="collapse" data-parent="#accordion-application-details">
                        <div className="card-body accordion-card-body">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            Name of Representative
                                        </div>
                                        <div className="startup-info-group-value">
                                            {startup.AuthorizedRepresentative.representative_firstname != "" ?
                                                startup.AuthorizedRepresentative.representative_firstname +
                                                ' ' + startup.AuthorizedRepresentative.representative_lastname :
                                                '-'
                                            }
                                        </div>
                                    </div>

                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            Designation
                                        </div>
                                        <div className="startup-info-group-value">
                                            {startup.AuthorizedRepresentative.representative_designation != "" ?
                                                startup.AuthorizedRepresentative.representative_designation :
                                                '-'
                                            }
                                        </div>
                                    </div>


                                </div>

                                <div className="col-lg-6">
                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            Mobile No.
                                        </div>
                                        <div className="startup-info-group-value">
                                            {startup.AuthorizedRepresentative.representative_mobile_no != "" ?
                                                startup.AuthorizedRepresentative.representative_mobile_no :
                                                '-'
                                            }
                                        </div>
                                    </div>

                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            Email ID
                                        </div>
                                        <div className="startup-info-group-value">
                                            {startup.AuthorizedRepresentative.representative_email != "" ?
                                                startup.AuthorizedRepresentative.representative_email :
                                                '-'
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">

                                <div className="col-lg-6">

                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            Board Resolution / Authorisation letter / PoA
                                        </div>
                                        {startup.AuthorizedRepresentative.authorisation_letter_upload.length != 0 &&
                                            startup.AuthorizedRepresentative.authorisation_letter_upload.map((item: any, index) => (
                                                <div className="startup-info-group-value" key={index}>
                                                    <div className="document-info document-pdf-icon">
                                                        <div className="document-info-name">{item.file.substr(item.file.lastIndexOf("/") + 1, item.file.length)}</div>
                                                        <a className="tab-pane-link tab-pane-link-view" href={item.file} target='_blank'>View  </a>
                                                        {/* <a className="tab-pane-link tab-pane-link-view" onClick={() => downloadDocument(item.file, item.file.substr(item.file.lastIndexOf("/") + 1, item.file.length))}>Download</a> */}
                                                    </div>
                                                </div>
                                            ))}


                                        {/* {startup.AuthorizedRepresentative.authorisation_letter_upload.length != 0 &&
                                            startup.AuthorizedRepresentative.authorisation_letter_upload.map((item: any,index) => (
                                                <div className="startup-info-group-value cancellation-comment-tab" key={index}>
                                                    <div className="document-info document-pdf-icon">
                                                        <div className="document-info-name">{item.file.substr(item.file.lastIndexOf("/") + 1, item.file.length)}</div>
                                                        <a className="tab-pane-link tab-pane-link-view orange-link-text" href={item.file} target='_blank'>View  </a>
                                                        <a className="tab-pane-link tab-pane-link-view" onClick={() => downloadDocument(item.file, item.file.substr(item.file.lastIndexOf("/") + 1, item.file.length))}>Download</a>
                                                    </div>
                                                </div>
                                            ))} */}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card accordion-card">
                    <div className="card-header accordion-card-header collapsed" data-toggle="collapse" data-target="#application_collapse_4" aria-expanded="false">
                        Startup Team
                    </div>
                    <div id="application_collapse_4" className="collapse" data-parent="#accordion-application-details">
                        <div className="card-body accordion-card-body">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            Name of the CEO
                                        </div>
                                        <div className="startup-info-group-value">
                                            {startup.StartupTeam.ceo_firstname != "" ?
                                                startup.StartupTeam.ceo_firstname +
                                                ' ' + startup.StartupTeam.ceo_lastname :
                                                '-'
                                            }
                                        </div>
                                    </div>

                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            LinkedIn Profile
                                        </div>
                                        <div className="startup-info-group-value">
                                            {startup.StartupTeam.ceo_linkedin_link != "" ?
                                                startup.StartupTeam.ceo_linkedin_link :
                                                '-'
                                            }
                                        </div>
                                    </div>

                                </div>

                                <div className="col-lg-6">
                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            Background of the CEO
                                        </div>
                                        <div className="startup-info-group-value">
                                            {startup.StartupTeam.ceo_description != "" ?
                                                startup.StartupTeam.ceo_description :
                                                '-'
                                            }
                                        </div>
                                    </div>

                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            No. of full-time employees
                                        </div>
                                        <div className="startup-info-group-value">
                                            {startup.StartupTeam.fulltime_employees_no != 0 ?
                                                startup.StartupTeam.fulltime_employees_no :
                                                '-'
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="startup-info-group-label">
                                        List of all teams along with the number of full-time employees in each team
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="startup-info-group">
                                                <div className="startup-info-group-label">
                                                    Team Name
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="startup-info-group">
                                                <div className="startup-info-group-label">
                                                    No. of full time employees
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    {
                                        startup.StartupTeam.team_data.map((team: any, index: number) => (
                                            <div key={`team_name_${index}`} className="row">

                                                <div className="col-lg-6">
                                                    <div className="startup-info-group">
                                                        <div className="startup-info-group-value">
                                                            {team.team_name != "0" ?
                                                                team.team_name :
                                                                '-'
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="startup-info-group">
                                                        <div className="startup-info-group-value">
                                                            {team.no_employee != 0 ?
                                                                team.no_employee :
                                                                '-'
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>

                                <br />
                                <div className="col-lg-12">
                                    <div className="startup-info-group-label bold-label">
                                        Promoter's Details
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="startup-info-group">
                                                <div className="startup-info-group-label">
                                                    Promoter's Name
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="startup-info-group">
                                                <div className="startup-info-group-label">
                                                    Aadhaar Number
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        startup.StartupTeam.promoter_data.length == 0 &&
                                        <div className="row">

                                            <div className="col-lg-6">
                                                <div className="startup-info-group">
                                                    <div className="startup-info-group-value">
                                                        -
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="startup-info-group">
                                                    <div className="startup-info-group-value">
                                                        -
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    }
                                    {startup.StartupTeam.promoter_data.length != 0 &&
                                        startup.StartupTeam.promoter_data.map((team: any, index: number) => (
                                            <div key={`team_name_${index}`} className="row">

                                                <div className="col-lg-6">
                                                    <div className="startup-info-group">
                                                        <div className="startup-info-group-value">
                                                            {team.name != "0" ?
                                                                team.name :
                                                                '-'
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="startup-info-group">
                                                        <div className="startup-info-group-value">
                                                            {team.aadhar_number != 0 ?
                                                                team.aadhar_number :
                                                                '-'
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>

                                <div className="col-lg-12">



                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            Promoters Aadhaar Cards
                                        </div>
                                        {startup.StartupTeam.promoters_aadhar_cards.length == 0 &&
                                            <div>-</div>
                                        }
                                        {startup.StartupTeam.promoters_aadhar_cards.length != 0 &&
                                            startup.StartupTeam.promoters_aadhar_cards.map((item: any, index) => (
                                                <div className="startup-info-group-value cancellation-comment-tab" key={index}>
                                                    <div className="document-info document-pdf-icon">
                                                        <div className="document-info-name">{item.file.substr(item.file.lastIndexOf("/") + 1, item.file.length)}</div>
                                                        <a className="tab-pane-link tab-pane-link-view orange-link-text" href={item.file} target='_blank'>View  </a>
                                                        {/* <a className="tab-pane-link tab-pane-link-view" onClick={() => downloadDocument(item.file, item.file.substr(item.file.lastIndexOf("/") + 1, item.file.length))}>Download</a> */}
                                                    </div>
                                                </div>
                                            ))}
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="card accordion-card">
                    <div className="card-header accordion-card-header collapsed" data-toggle="collapse" data-target="#application_collapse_5" aria-expanded="false">
                        Funding Details
                    </div>
                    <div id="application_collapse_5" className="collapse" data-parent="#accordion-application-details">
                        <div className="card-body accordion-card-body">
                            <div className="row">
                                <div className="col-lg-11">
                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            Have you cumulatively received more than ₹10 lakhs of monetary support under any Central or State government scheme?
                                        </div>
                                        <div className="startup-info-group-value">
                                            {startup.FundingDetails.received_monetary_support_under_scheme != "0" ? "Yes" : "No"
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            Current Funding Requirement
                                        </div>
                                        <div className="startup-info-group-value">
                                            {startup.FundingDetails.instrument_applying_for != "" ?
                                                (startup.FundingDetails.instrument_applying_for == "funding_debt" && 'Debt') || (startup.FundingDetails.instrument_applying_for == "funding_grant" && 'Grant') || (startup.FundingDetails.instrument_applying_for == "funding_debenture" && 'Debenture') :
                                                '-'
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            Quantum of Funds Required
                                        </div>
                                        <div className="startup-info-group-value">&#8377;{' '}
                                            {startup.FundingDetails.quantum_funds_required != 0 ?
                                                + startup.FundingDetails.quantum_funds_required :
                                                '0'
                                            }
                                        </div>
                                    </div>
                                </div>


                                <div className="col-lg-12">
                                    <div className="startup-info-group-label bold-label mb-3">
                                        Funds Deployment Plan with Broad Expense Categories
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-3">
                                            <div className="startup-info-group">
                                                <div className="startup-info-group-label">
                                                    Expense Bucket
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-3">
                                            <div className="startup-info-group">
                                                <div className="startup-info-group-label">
                                                    Expense Amount
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-3">
                                            <div className="startup-info-group">
                                                <div className="startup-info-group-label">
                                                    Deployment Start Date
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-3">
                                            <div className="startup-info-group">
                                                <div className="startup-info-group-label">
                                                    Deployment End Date
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        startup.FundingDetails.fund_expense_data.map((fund_expense: any, index: number) => (
                                            <div key={`fund_expense_${index}`} className="row">

                                                <div className="col-lg-3">
                                                    <div className="startup-info-group">
                                                        <div className="startup-info-group-value">
                                                            {fund_expense.expense_bucket_name != "Others" ? fund_expense.expense_bucket_name : fund_expense.expense_bucket_other}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-3">
                                                    <div className="startup-info-group">
                                                        <div className="startup-info-group-value">
                                                            &#8377;{' '}
                                                            {fund_expense.expense_amount != 0 ?
                                                                + fund_expense.expense_amount :
                                                                '0'
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-3">
                                                    <div className="startup-info-group">
                                                        <div className="startup-info-group-value">
                                                            {moment(fund_expense.deployment_start_date).format('YYYY-MM-DD')}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-3">
                                                    <div className="startup-info-group">
                                                        <div className="startup-info-group-value">
                                                            {moment(fund_expense.deployment_end_date).format('YYYY-MM-DD')}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    <hr />
                                </div>


                                <div className="col-lg-12">
                                    <div className="startup-info-group-label bold-label mb-3">
                                        Prior Funding Details
                                    </div>
                                    {startup.FundingDetails.prior_funds_applicable == "0" && <div className="row">
                                        <div className="col">
                                            <div className="startup-info-group">
                                                <div className="startup-info-group-label">
                                                    Date
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col">
                                            <div className="startup-info-group">
                                                <div className="startup-info-group-label">
                                                    Amount (in &#8377;)
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col">
                                            <div className="startup-info-group">
                                                <div className="startup-info-group-label">
                                                    Financial Instrument
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col">
                                            <div className="startup-info-group">
                                                <div className="startup-info-group-label">
                                                    Name of Funding Agency
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="startup-info-group">
                                                <div className="startup-info-group-label">
                                                    Funding Agency Type
                                                </div>
                                            </div>
                                        </div>

                                    </div>}


                                    {startup.FundingDetails.prior_funds_applicable == "0" &&
                                        startup.FundingDetails.prior_fund_data.map((prior: any, index: number) => (
                                            <>

                                                <div key={`fund_expense_${index}`} className="row">

                                                    <div className="col">
                                                        <div className="startup-info-group">
                                                            <div className="startup-info-group-value">
                                                                {prior.fund_date != "" ? prior.fund_date : '-'}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col">
                                                        <div className="startup-info-group">
                                                            <div className="startup-info-group-value">
                                                                &#8377;{' '}
                                                                {prior.amount != '0' ?
                                                                    + prior.amount :
                                                                    '0'
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col">
                                                        <div className="startup-info-group">
                                                            <div className="startup-info-group-value">
                                                                {prior.financial_instrument != '' ? prior.financial_instrument : '-'}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col">
                                                        <div className="startup-info-group">
                                                            <div className="startup-info-group-value">
                                                                {prior.funding_agency_name != '' ? prior.funding_agency_name : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="startup-info-group">
                                                            <div className="startup-info-group-value">
                                                                {prior.funding_agency_type != '' ? prior.funding_agency_type : '-'}
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>

                                            </>
                                        ))
                                    }
                                    {
                                        startup.FundingDetails.prior_funds_applicable == "1" &&
                                        <div className="row">
                                            <div className="col">
                                                <div className="startup-info-group">
                                                    <div className="startup-info-group-value">
                                                        No prior funding available.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="card accordion-card">
                    <div className="card-header accordion-card-header collapsed" data-toggle="collapse" data-target="#application_collapse_6" aria-expanded="false">
                        Incubator Preference
                    </div>
                    <div id="application_collapse_6" className="collapse" data-parent="#accordion-application-details">
                        <div className="card-body accordion-card-body">

                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            Incubator Preference # 1
                                        </div>
                                        <div className="startup-info-group-value">
                                            {startup.IncubatorPreference.incubator_preference_1 != "" ?
                                                startup.IncubatorPreference.incubator_preference_1 :
                                                '-'
                                            }
                                        </div>
                                    </div>

                                    {
                                        startup.IncubatorPreference.incubator_preference_2 &&
                                        <div className="startup-info-group">
                                            <div className="startup-info-group-label">
                                                Incubator Preference # 2
                                        </div>
                                            <div className="startup-info-group-value">
                                                {startup.IncubatorPreference.incubator_preference_2 != "" ?
                                                    startup.IncubatorPreference.incubator_preference_2 :
                                                    '-'
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className="col-lg-6">
                                    {
                                        startup.IncubatorPreference.incubator_preference_3 &&
                                        <div className="startup-info-group">
                                            <div className="startup-info-group-label">
                                                Incubator Preference # 3
                                        </div>
                                            <div className="startup-info-group-value">
                                                {startup.IncubatorPreference.incubator_preference_3 != "" ?
                                                    startup.IncubatorPreference.incubator_preference_3 :
                                                    '-'
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div> */}

                <div className="card accordion-card">
                    <div className="card-header accordion-card-header collapsed" data-toggle="collapse" data-target="#application_collapse_7" aria-expanded="false">
                        Upload Documents
                    </div>
                    <div id="application_collapse_7" className="collapse" data-parent="#accordion-application-details">
                        <div className="card-body accordion-card-body">

                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            Uploaded Pitch deck of Business Idea
                                        </div>
                                        {startup.UploadDocuments.upload_pitch_deck.length != 0 &&
                                            startup.UploadDocuments.upload_pitch_deck.map((item: any, index) => (
                                                <div className="startup-info-group-value" key={index}>
                                                    <div className="document-info document-pdf-icon">
                                                        <div className="document-info-name">{item.file.substr(item.file.lastIndexOf("/") + 1, item.file.length)}</div>
                                                        <a className="tab-pane-link tab-pane-link-view" href={item.file} target='_blank'>View  </a>
                                                        {/* <a className="tab-pane-link tab-pane-link-view" onClick={() => downloadDocument(item.file, item.file.substr(item.file.lastIndexOf("/") + 1, item.file.length))}>Download</a> */}
                                                    </div>
                                                </div>
                                            ))}

                                    </div>

                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            Video URL showcasing the product and/or business model
                                        </div>
                                        <div className="startup-info-group-value">
                                            {startup.UploadDocuments.video_url_for_product != "" ?
                                                startup.UploadDocuments.video_url_for_product :
                                                '-'
                                            }
                                        </div>
                                    </div>

                                </div>

                                <div className="col-lg-6">

                                    <div className="startup-info-group">
                                        <div className="startup-info-group-label">
                                            Uploaded any other relevant document
                                        </div>
                                        {startup.UploadDocuments.upload_relavent_document_other.length != 0 &&
                                            startup.UploadDocuments.upload_relavent_document_other.map((item: any, index) => (
                                                <div className="startup-info-group-value cancellation-comment-tab" key={index}>
                                                    <div className="document-info document-pdf-icon">
                                                        <div className="document-info-name">{item.file.substr(item.file.lastIndexOf("/") + 1, item.file.length)}</div>
                                                        <a className="tab-pane-link tab-pane-link-view orange-link-text" href={item.file} target='_blank'>View  </a>
                                                        {/* <a className="tab-pane-link tab-pane-link-view" onClick={() => downloadDocument(item.file, item.file.substr(item.file.lastIndexOf("/") + 1, item.file.length))}>Download</a> */}
                                                    </div>
                                                </div>
                                            ))}
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                {/* <div className="card accordion-card">
                    <div className="card-header accordion-card-header collapsed" data-toggle="collapse" data-target="#application_collapse_5" aria-expanded="false">
                        Funding Details
                    </div>
                    <div id="application_collapse_5" className="collapse" data-parent="#accordion-application-details">
                        <div className="card-body accordion-card-body">

                            <div className="row">
                                <div className="col-lg-6">
                                </div>

                                <div className="col-lg-6">
                                </div>
                            </div>

                        </div>
                    </div>
                </div> */}

            </div>
            {/*APPLICATION DETAILS ACCORDION END */}

            {/* Show More  */}
            <div
                className="modal fade"
                id="showMore"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-centered modal-lg"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header modal-header-border-none">
                            <h5 className="modal-title " id="exampleModalLongTitle">
                                {popUpName} <span className="eac-name-pop-up"></span>
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className={popUpName == "Industry" || popUpName == "Sector" ? "row" : ""}>
                                {popUpName == "Industry" ?
                                    <>
                                        {industryName.map((industry: any, index) => (
                                            <div className="col-md-6">
                                                <ul className="ul-ml">
                                                    <li key={index}>{
                                                        industry.industry_name != "" ?
                                                            industry.industry_name :
                                                            '-'
                                                    }</li>
                                                </ul>
                                            </div>
                                        ))}
                                    </>
                                    : ""
                                }

                                {popUpName == "Sector" ?
                                    <>
                                        {sectorName.map((sector: any) => (
                                            <div className="col-md-6">
                                                <ul className="ul-ml">
                                                    <li>{
                                                        sector.sector_name != "" ?
                                                            sector.sector_name :
                                                            '-'
                                                    }</li>
                                                </ul>
                                            </div>
                                        ))}
                                    </>
                                    : ""
                                }
                            </div>

                            <div className="row">
                                <div className="col-md-12 centerText">
                                    <div className="btn-group mt-1-5 ">
                                        <button
                                            className="tertiary  btn-yellow-primary"
                                            data-dismiss="modal"
                                        >
                                            OK
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );

}

export default StartupApplicationDetailsComponent;