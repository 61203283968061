export class ErrorModel {
    constructor(
      public field: string,
      public pattern: string,
      public value: string
    ) {
      this.field = field;
      this.value = value;
    }
  
    public isMandatory = (): boolean => {
      return this.value.length === 0;
    };
  
    public isValidPattern = (): boolean => {
      const regEx = new RegExp(this.pattern);
      return regEx.test(this.value);
    };
  }
  