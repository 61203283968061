import { Action, IAction } from "./reducer.action";

export interface IErrorInitialState {
  errors: any;
}

const errorReducer = (state: IErrorInitialState, action: IAction) => {
  //console.log(action, "action");
  switch (action.type) {
    case Action.SET_ERROR:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};

export default errorReducer;
