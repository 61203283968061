import React, { Fragment, useEffect, useState } from "react";
import { get, post } from "../../effects/api/api.common";
import {
  Node_GET_PORTFOLIO_DETAIL,
  NODE_URL,
  GET_PORTFOLIO_LIST_DESC,
  MERGED_FUND_DETAILS,
  GRANT_BALANCE,
  GET_ASSOCIATION_DATE,
} from "../../constants/api";
import LeftArrow from "../../images/LeftArrow.svg";
import RightArrow from "../../images/RightArrow.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import back from "../../images/back.svg";
import { Link } from "react-router-dom";
const PortfolioDetail = (props: any) => {
  const [data, setData] = useState<any>({});
  const [portfolioId, sePortfolioID] = useState("");
  const [evaluationRate, setEvaluationRate] = useState("");
  const [isPopoverVisible, setPopoverVisible] = useState(false);
  const [availableBalance, setAvailableBalance] = useState<any>("");
  const [grantBalance, setGrantBalance] = useState<any>("");
  const [associationDate, setAssociationDate] = useState<any>("");
  const [approvedGrantAmount, setGrantAmount] = useState<any>("");
  const [grant_calculation, setCalculation] = useState<any>("");
  const [loader, setLoader] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [showbutton, setshowbutton] = useState(false);
  const [startupID, setstartupID] = useState("");
  const [startupAppID, setstartupAppID] = useState("");
  const [IncID, setIncID] = useState("");
  const [proofofconcept, setproofofconcept] = useState<any>("");
  const [prototypedevelopement, setprototypedevelopement] = useState<any>("");
  const [productdevelopement, setproductdevelopement] = useState<any>("");
  const [fieldtrials, setfieldtrials] = useState<any>("");
  const [marketlaunch, setmarketlaunch] = useState<any>("");
  const [noofemployee, setnoofemployee] = useState<any>("");
  const [noofipregistration, setnoofipregistration] = useState<any>("");
  const [Funding_Status, setFunding_Status] = useState<any>("");
  const [angel_funding, setangel_funding] = useState<any>("");
  const [loans, setloans] = useState<any>("");
  const [vc_funding, setvc_funding] = useState<any>("");
  const [others, setothers] = useState<any>("");
  useEffect(() => {
    console.log(props.location.state);
    if (
      typeof props.location.state !== "undefined" &&
      props.location.state != null
    ) {
      if (props.location.state.check == "frominc") {
        getFundDetails(props.location.state.incubatorID);
        getGrantBalance(props.location.state.incubatorID);
        // setstartupID(props.location.state.Startup_user_id);
        // setstartupAppID(props.location.state.Startup_application_id);
      }
      if (props.location.state.check == "fromstartp") {
        setshowbutton(true);
        getFundDetails(props.location.state.incubatorID);
        getGrantBalance(props.location.state.incubatorID);
        setstartupID(props.location.state.Startup_user_id);
        setstartupAppID(props.location.state.Startup_application_id);
        setproofofconcept(props.location.state.proofofconcept);
        setprototypedevelopement(props.location.state.prototypedevelopement);
        setproductdevelopement(props.location.state.productdevelopement);
        setfieldtrials(props.location.state.fieldtrials);
        setmarketlaunch(props.location.state.marketlaunch);
        setnoofemployee(props.location.state.noofemployee);
        setnoofipregistration(props.location.state.noofipregistration);
        setFunding_Status(props.location.state.Funding_Status);
        setangel_funding(props.location.state.angel_funding);
        setloans(props.location.state.loans);
        setvc_funding(props.location.state.vc_funding);
        setothers(props.location.state.others);
      }
      setIncID(props.location.state.incubator_user_id);
    }
  }, [props.location.state.incubatorID]);
  useEffect(() => {
    setEvaluationRate(props.location.state.evalutionRate);
  }, [props.location.state.evalutionRate]);
  useEffect(() => {
    if (
      typeof props.location.state !== "undefined" &&
      props.location.state != null
    ) {
      if (props.location.state.portfolioId) {
        sePortfolioID(props.location.state.portfolioId);
        // setIncubatorId(props.location.state.userId)
        getPortFolioDetail(props.location.state.portfolioId);
        getAssociationDate(props.location.state.portfolioId);
      }
    }
  }, []);
  const getPortFolioDetail = (Id: any) => {
    // setLoader(true);
    const url = GET_PORTFOLIO_LIST_DESC + Id;
    get(url).then((response: any) => {
      // setLoader(false);
      // console.log(response.data.data[0]);
      if (!response || !response.data) return;
      if (response?.data?.result === "success") {
        const APIData = response.data.data;
        const sectors: any = [];
        // const KeyFeatresArray :any = []
        APIData.forEach((element: any) => {
          const Sectorsarray = element.sectors.split(",");
          const ImagesArray = element.image_data.split(",");
          const KeyFeatresArray = element.key_features;
          const resultArray = KeyFeatresArray.split(/,\s*'|'\s*,/)
            .filter((item: any) => item !== "'" && item !== "''")
            .map((item: any) => item.replace(/'/g, ""));
          //  console.log(resultArray);
          sectors.push({
            Id: element.Id,
            sectors: Sectorsarray,
            state: element.state,
            city: element.city,
            image: element.image,
            incubator_name: element.incubator_name,
            description: element.description,
            image_data: ImagesArray,
            key_features: resultArray,
            logo: element.logo,
            site_url: element.site_url,
            email: element.email_id,
          });
        });
        setData(sectors[0]);
      } else {
        setData({});
      }
    });
  };

  const getAssociationDate = (id: any) => {
    // setLoader(true);
    get(GET_ASSOCIATION_DATE + "/" + id).then((response: any) => {
      // setLoader(false);
      let date =
        response.data.data[0].ApprovedDate == (null || "")
          ? ""
          : response.data.data[0].ApprovedDate;
      setAssociationDate(date);
    });
  };
  const getFundDetails = (id: any) => {
    let Payload = {
      USER_ID: id || IncID,
    };
    setLoader(true);
    post(MERGED_FUND_DETAILS, Payload).then(async (response: any) => {
      setLoader(false);
      // console.log(response,"responseeeeeeeeeee")
      let firstTotalAmount =
        response.data.first_totalAmount.total_approved_amt != null
          ? response.data.first_totalAmount.total_approved_amt
          : 0;
      let secondTotalAmount =
        response.data.second_totalAmount.total_approved_amt != null
          ? response.data.second_totalAmount.total_approved_amt
          : 0;
      let approvedReturns =
        response.data.approved_returns != null
          ? response.data.approved_returns
          : 0;
      let selectedAppsAmount =
        response.data.selectedAppsAmount != null
          ? response.data.selectedAppsAmount
          : 0;
      let closedAppsAmount =
        response.data.closedAppsAmount != null
          ? response.data.closedAppsAmount
          : 0;

      const Available_Balance_Incubator =
        firstTotalAmount +
        secondTotalAmount +
        approvedReturns -
        (selectedAppsAmount + closedAppsAmount);
      setAvailableBalance(Available_Balance_Incubator.toLocaleString("en-IN"));
      // const Available_Balance_Incubator = ((response.data.first_totalAmount.total_approved_amt + response.data.second_totalAmount.total_approved_amt + response.data.approved_returns) - (response.data.selectedAppsAmount + response.data.closedAppsAmount))
      // const total_approved_amt = ((response.data.first_totalAmount.total_approved_amt + response.data.second_totalAmount.total_approved_amt))
      // const Approved_return = ((response.data.approved_returns))

      // let grant_amount = ((20 * (Number(total_approved_amt) + Number(Approved_return))) / 100) - (Number(approvedGrantAmount))
      let grant_amount = (20 * (firstTotalAmount + secondTotalAmount)) / 100;
      setCalculation(grant_amount);
    });
  };
  const getGrantBalance = (id: any) => {
    let Payload = {
      USER_ID: id,
    };
    // setLoader(true);
    post(GRANT_BALANCE, Payload).then((response: any) => {
      // setLoader(false);
      const data =
        response.data.data[0].ApprovedGrantAmount == null
          ? 0
          : response.data.data[0].ApprovedGrantAmount;
      setGrantAmount(data);
    });
  };
  useEffect(() => {}, [approvedGrantAmount]);
  useEffect(() => {
    let totalGrantAmount = grant_calculation - approvedGrantAmount;
    setGrantBalance(totalGrantAmount.toLocaleString("en-IN"));
  }, [grant_calculation]);
  const ccEmailAddress = data.email || "";
  var mailtoLink = `mailto:?to=${encodeURIComponent(ccEmailAddress)}`;
  const handleCopyClick = (textToCopy: any) => {
    // Create a textarea element
    const textArea = document.createElement("textarea");

    // Set its value to the text you want to copy
    textArea.value = textToCopy;

    // Append the textarea element to the document
    document.body.appendChild(textArea);

    // Select the text inside the textarea
    textArea.select();

    // Execute the copy command
    document.execCommand("copy");

    // Remove the textarea element
    document.body.removeChild(textArea);

    setPopoverVisible(true);
    setTimeout(() => {
      setPopoverVisible(false);
    }, 2000);
  };
  return (
    <Fragment>
      {Object.keys(data).length > 0 && (
        <div className="container mt-5 mb-5 portfolio-detail-container">
          {loader && (
            <div className="spinner-border custom-spinner" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          )}
          <fieldset disabled={loader}>
            <div className="col-md-12">
              <div className="">
                {showbutton && (
                  <div className="row mt-4" style={{ float: "left" }}>
                    <Link
                      to={{
                        pathname: "/startup_portfolio_detail",
                        state: {
                          Startup_application_id: startupAppID,
                          Startup_user_id: startupID,
                          proofofconcept: proofofconcept,
                          prototypedevelopement: prototypedevelopement,
                          productdevelopement: productdevelopement,
                          fieldtrials: fieldtrials,
                          marketlaunch: marketlaunch,
                          noofemployee: noofemployee,
                          noofipregistration: noofipregistration,
                          Funding_Status: Funding_Status,
                          angel_funding: angel_funding,
                          loans: loans,
                          vc_funding: vc_funding,
                          others: others,
                        },
                      }}
                    >
                      <img
                        src={back}
                        alt="DIPP"
                        className="portImage"
                        style={{
                          height: "30px",
                        }}
                      />
                    </Link>{" "}
                  </div>
                )}
              </div>{" "}
              <br></br>
              <br></br>
              <br></br>
              <div className="d-flex justify-content-between mb-4 mt-4">
                <div className="logo">
                  <img
                    src={`${NODE_URL}${data.logo}`}
                    alt={data.incubator_name}
                    className="portLogo"
                  />
                </div>
                <div className="d-flex justify-content-between">
                  {data?.email != null ? (
                    <div className="website mr-3">
                      <button className="btn btn-sisfs btn-transparent-primary mt-4">
                        <a
                          href={mailtoLink}
                          style={{
                            color: "#e26640 ",
                            textTransform: "lowercase",
                          }}
                        >
                          {data.email}
                        </a>{" "}
                        &nbsp;
                        <i
                          className="fa fa-copy"
                          onClick={() => handleCopyClick(data.email)}
                        ></i>
                      </button>
                      {isPopoverVisible && (
                        <div
                          style={{
                            position: "absolute",
                            top: "60px",
                            left: "80%",
                            transform: "translateX(-50%)",
                            background: "#fff",
                            padding: "10px",
                            fontSize: "14px",
                            boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                            borderRadius: "5px",
                          }}
                        >
                          Copied to clipboard!
                        </div>
                      )}
                      <br />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="website">
                    <button
                      className="btn btn-sisfs btn-transparent-primary mt-4"
                      onClick={() => window.open(data.site_url)}
                    >
                      Visit Website
                    </button>
                  </div>
                </div>
              </div>
              <span className="main-heading portDetailHeading">
                <span style={{ fontSize: "28px", fontWeight: "500" }}>
                  {data.incubator_name}{" "}
                  <sup>
                    <span style={{ fontSize: "16px" }}>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip
                            id="example-tooltip"
                            style={{ fontSize: "14px" }}
                          >
                            The information displayed on the incubator’s
                            portfolio page may not be latest, it is recommended
                            to reach out to the concerned incubation center for
                            any clarifications.
                          </Tooltip>
                        }
                      >
                        <button
                          type="button"
                          style={{
                            borderRadius: "16px",
                            backgroundColor: "#000000",
                            color: "#ffffff",
                            border: "none",
                            width: "18px",
                            height: "18px",
                          }}
                        >
                          !
                        </button>
                      </OverlayTrigger>
                    </span>
                  </sup>
                </span>
              </span>
              <div className="col-md-12">
                <div className="row">
                  <p className="portCity mr-1 pt-1 py-2">
                    {data.city},{" " + data.state}
                    {/* <div className="row mt-1">
                  {!showMore && (
                  <p
                    className="portTagDetail ml-4"
                    style={{ display: "contents" }}
                  >
                    {data.sectors.length > 0 &&
                      data?.sectors.map((sector: string, index: number) => {
                        if (index < 4) {
                          return (
                            <Fragment key={index}>
                              <span className="mr-1">{sector}</span>
                            </Fragment>
                          );
                        }
                      })}
                  </p>
                )}
                {showMore && (
                  <p
                    className="portTagDetail ml-4"
                    style={{ display: "contents" }}
                  >
                    {data.sectors.length > 0 &&
                      data?.sectors.map((sector: string, index: number) => {
                        return (
                          <Fragment key={index}>
                            <span className="mr-1">{sector}</span>
                          </Fragment>
                        );
                      })}
                  </p>
                )}
                {!showMore && data.sectors.length > 4 && (
                  <span
                    className="link-orange-text d-block pt-1 pl-3"
                    onClick={() => setShowMore(true)}
                  >
                    View More
                  </span>
                )}
                  </div> */}
                  </p>
                </div>
                <div className="row mt-3">
                  <ul
                    style={{
                      fontFamily: "Roboto Slab",
                      fontWeight: "500",
                      lineHeight: "2",
                    }}
                  >
                    <li>
                      Total Available Balance with the Incubator :-{" "}
                      <span style={{ color: "#25429c" }}>
                        &#x20B9; {availableBalance}
                      </span>{" "}
                    </li>
                    <li>
                      Available Grant Balance with the Incubator :-{" "}
                      <span style={{ color: "#25429c" }}>
                        &#x20B9; {grantBalance}
                      </span>
                    </li>
                    <li>
                      Incubator Associated with us since:-{" "}
                      <span style={{ color: "#25429c" }}>
                        &#128197; {associationDate}
                      </span>{" "}
                    </li>
                    <li>
                      Evaluation Rate :-{" "}
                      <span style={{ color: "#25429c" }}>
                        {" "}
                        {evaluationRate}%
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="mainImage">
                  <img
                    src={`${NODE_URL}${data.image}`}
                    alt={data.incubator_name}
                    className="portImageMain"
                  />
                </div>
                <div className="main-heading right-heading mt-3">Sectors</div>
                <div className="row">
                  {!showMore && (
                    <p
                      className="portTagDetail ml-4"
                      style={{ display: "contents" }}
                    >
                      {data.sectors.length > 0 &&
                        data?.sectors.map((sector: string, index: number) => {
                          if (index < 4) {
                            return (
                              <Fragment key={index}>
                                <span className="mr-1">{sector}</span>
                              </Fragment>
                            );
                          }
                        })}
                    </p>
                  )}
                  {showMore && (
                    <p
                      className="portTagDetail ml-4"
                      style={{ display: "contents" }}
                    >
                      {data.sectors.length > 0 &&
                        data?.sectors.map((sector: string, index: number) => {
                          return (
                            <Fragment key={index}>
                              <span className="mr-1">{sector}</span>
                            </Fragment>
                          );
                        })}
                    </p>
                  )}
                  {!showMore && data.sectors.length > 4 && (
                    <span
                      className="link-orange-text d-block pt-1 pl-3"
                      onClick={() => setShowMore(true)}
                    >
                      View More
                    </span>
                  )}
                </div>
              </div>
              {/* <div className="portImageBlock">
                            <img src="" className="portImage" />
                        </div> */}
              <div className="main-heading right-heading mt-4">About</div>
              <p className="para-text mb-2">{data.description}</p>
              <div className="main-heading right-heading mt-5">
                Key Features
              </div>
              <div className="key">
                <div className="ordered-list wrapper about-ordered-list">
                  <ol>
                    {data.key_features.length > 0 &&
                      data.key_features.map(
                        (feature: string, index: number) => {
                          return (
                            <Fragment key={index}>
                              <li>{feature}</li>
                            </Fragment>
                          );
                        }
                      )}
                  </ol>
                </div>
              </div>
              <div className="main-heading text-center mt-5 mb-4">
                Photo Gallery
              </div>
              <div>
                <div
                  id="carouselExampleIndicators"
                  className="carousel"
                  data-ride="carousel"
                >
                  <a
                    className="carousel-control-prev"
                    href="#carouselExampleIndicators"
                    role="button"
                    data-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Previous</span>
                  </a>
                  <ol className="carousel-indicators">
                    {data.image_data.map((image: string, index: number) => {
                      if (index == 0) {
                        return (
                          <li
                            data-target="#carouselExampleIndicators"
                            data-slide-to={index}
                            className="active"
                            key={index}
                          ></li>
                        );
                      } else {
                        return (
                          <li
                            data-target="#carouselExampleIndicators"
                            data-slide-to={index}
                            key={index}
                          ></li>
                        );
                      }
                    })}
                    {/* <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li> */}
                  </ol>
                  <a
                    className="carousel-control-next"
                    href="#carouselExampleIndicators"
                    role="button"
                    data-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Next</span>
                  </a>

                  <div className="carousel-inner carousel-overflow">
                    {data.image_data.map((image: string, index: number) => {
                      if (index == 0) {
                        return (
                          <div className="carousel-item active" key={index}>
                            <img
                              className="d-block w-100 corousel-image"
                              src={`${NODE_URL}${image}`}
                              alt="First slide"
                            />
                          </div>
                        );
                      } else {
                        return (
                          <div className="carousel-item" key={index}>
                            <img
                              className="d-block w-100 corousel-image"
                              src={`${NODE_URL}${image}`}
                              alt="First slide"
                            />
                          </div>
                        );
                      }
                    })}
                    {/* <div className="carousel-item active">
                                        <img className="d-block w-100" src={data.image} alt="First slide" />
                                    </div>
                                    <div className="carousel-item">
                                        <img className="d-block w-100" src={data.image} alt="Second slide" />
                                    </div>
                                    <div className="carousel-item">
                                        <img className="d-block w-100" src={data.image} alt="Third slide" />
                                    </div> */}
                  </div>
                </div>
              </div>
              {/* <div className="text-center">
                            <button className="btn btn-sisfs btn-transparent-primary mt-4" onClick={() => window.open(data.site_url)}>
                                Visit Website
                            </button>
                        </div> */}
            </div>
          </fieldset>
        </div>
      )}
    </Fragment>
  );
};

export default PortfolioDetail;
