import React, { useEffect, useState } from "react";
import {
  LOGIN,
  GET_APPLICATION_LIST,
  ALLOW_INCUBATOR_CREATION,
  LOGIN_STARTUP,
  FORGOT_PASSWORD,
} from "../../../constants/api";
import { get, post } from "../../../effects/api/api.common";
import Lock from "../../../images/lock.svg";
import {
  DATA_SECRET,
  setSessionStorageValue,
} from "../../../effects/utils/session-storage";
import { LoginModel } from "../../../models/LoginModel";
import { useAuthStateValue } from "../../../providers/AuthStateProvider";
import { Action } from "../../../reducers/reducer.action";
import { useHistory } from "react-router-dom";
import UserInfoModel from "../../../models/UserInfoModel";
import { validateEmail } from "../../../effects/utils/validations";
import ConfirmationDialog from "../controls/ConfirmationDialog";
import { getSessionStorageValue } from "../../../effects/utils/session-storage";
interface IIndexer {
  [key: string]: string;
}
function ForgotPasswordComponent() {
  const [email, setEmail] = useState("");
  const [showIncubatorSuccessModal, setShowIncubatorSuccessModal] =
    useState(false);
  const [showIncubatorFailureModal, setShowIncubatorFailureModal] =
    useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [loggedInRole, setLoggedInRole] = useState("");
  const user: any = getSessionStorageValue("user");
  const [dialogData, setDialogData] = useState({
    header: "",
    text: "",
  });
  const [failDialogData, setFailDialogData] = useState({
    header: "",
    text: "",
  });

  useEffect(() => {
    if (getSessionStorageValue("user")) {
      const user: any = getSessionStorageValue("user");
      const userInfo = JSON.parse(user);
      setLoggedInRole(userInfo.roles);
    }
  }, []);
  const forgotPasswordEvent = () => {
    const payload = {
      email: email,
    };
    setDisabled(true);
    post(FORGOT_PASSWORD, payload).then((response: any) => {
      if (!response || !response.data) return;
      // console.log(response.data)
      setDisabled(false);
      if (response.data.result == "success") {
        setDialogData({
          header: "Thank You!",
          text: response.data.message,
        });
        setShowIncubatorSuccessModal(true);
      } else {
        setFailDialogData({
          header: "",
          text: response.data.message,
        });
        setShowIncubatorFailureModal(true);
      }
    });
  };
  const dismissModal = () => {
    setShowIncubatorSuccessModal(false);
    setShowIncubatorFailureModal(false);
    window.location.reload();
  };
  return (
    <div
      className="modal fade"
      id="forgotPassword"
      role="dialog"
      aria-labelledby="forgotPasswordModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-slideout" role="document">
        <div className="modal-content">
          <div className="login py-3">
            <div className="login-wrapper">
              <div className="form-signin">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <h1 className="login-heading text-center mb-4">
                  Forgot your password?
                </h1>
                <div className="form-group row text-center">
                  <div className="col-sm-12">
                    <span>
                      Please enter the email address you'd like your password
                      reset information to be sent to
                    </span>
                  </div>
                </div>
                <div className="form-group row  mt-5">
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control input-height"
                      value={email}
                      onChange={(e: any) => setEmail(e.target.value)}
                      placeholder="Email Address"
                    />
                  </div>
                </div>
                <div className="form-group row mt-5 mb-3">
                  <div className="col-sm-12">
                    <button
                      id="btnLogIn"
                      className={
                        isDisabled
                          ? "btn w-100 btn-sisfs btn-yellow-primary custom-disabled"
                          : "btn w-100 btn-sisfs btn-yellow-primary"
                      }
                      disabled={isDisabled}
                      name="button"
                      value="login"
                      type="button"
                      onClick={forgotPasswordEvent}
                    >
                      {" "}
                      REQUEST RESET LINK
                    </button>
                  </div>
                </div>
                <div className="form-group row mt-5 text-center">
                  <div className="col-sm-12">
                    <span
                      className="link-orange-text ml-2"
                      data-dismiss="modal"
                      data-toggle="modal"
                      data-target="#LoginCollapse"
                    >
                      Back To Login
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showIncubatorSuccessModal && (
        // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
        // <>
        //   <div className="modal-backdrop fade show"></div>

        //   <div
        //     className="modal align-middle modal-sisfs d-block"
        //     id="FormSubmissionSuccess"
        //   >
        //     <div className="modal-dialog modal-dialog-centered modal-md">
        //       <div className="modal-content">
        //         <div className="modal-body text-center">
        //           <i className="fa fa-check-circle-o"></i>
        //           <h3 className="modal-message">Thank You!</h3>
        //           <div className="modal-text">
        //             Your Meeting Has been Scheduled
        //                         </div>
        //           <div className="modal-action">
        //             <button className="btn btn-sisfs btn-yellow-primary" onClick={dismissModal}>
        //               Ok!!
        //                         </button>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // </>
        <ConfirmationDialog
          headerText={dialogData.header}
          bodyText={dialogData.text}
          onDialogClose={dismissModal}
        />
      )}
      {showIncubatorFailureModal && (
        // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
        // <>
        //   <div className="modal-backdrop fade show"></div>
        //   <div
        //     className="modal align-middle modal-sisfs d-block"
        //     id="FormSubmissionError"
        //   >
        //     <div className="modal-dialog modal-dialog-centered modal-md">
        //       <div className="modal-content">
        //         <div className="modal-body text-center">
        //         <img
        //       src={error}
        //       className="search mb-2"
        //       alt="search"

        //     />
        //           <h3 className="modal-message">
        //             {failDialogData.header}
        //                       </h3>
        //           <h3 className="modal-text">
        //           {failDialogData.text}
        //                       </h3>
        //           <div className="modal-action">
        //             <button className="btn btn-sisfs btn-yellow-primary" onClick={() => setShowIncubatorFailureModal(false)}>
        //               OK!
        //                       </button>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // </>
        <ConfirmationDialog
          headerText={failDialogData.header}
          bodyText={failDialogData.text}
          onDialogClose={() => setShowIncubatorFailureModal(false)}
          iconClass="showImage"
        />
      )}
    </div>
  );
}

export default ForgotPasswordComponent;
