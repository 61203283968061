import React, { useEffect, useState } from "react";
import { Question } from "../../../models/eac/QuestionModel";
import { IReviewStepProps } from "./ReviewStepWrapperComponent";
import ScoreRangeComponent from "./ScoreRangeComponent";
interface IQuestionReviewProps {
  index: number;
  question: Question;
  onUpdate: (question: Question) => void;
}
interface IAttribue {
  [key: string]: string;
}
function WeightageDetailsComponent(props: IQuestionReviewProps) {
  const [score, setScore] = useState("");
  const [comment, setComment] = useState<IAttribue>();

  useEffect(() => {
    const comment = { [`comment_${props.index}`]: props.question?.comments };
    setComment(comment);
  }, []);

  const onScoreClick = (score: string) => {
    setScore(score);
    // update parent
    let updatedQuestion = props.question;
    updatedQuestion.score = score;

    let weightScore =
      (parseInt(score) * parseFloat(props.question.esb_weightage)) / 100;
    updatedQuestion.weighted_score = isNaN(weightScore)
      ? "0.0"
      : weightScore.toFixed(2).toString();

    setTimeout(() => props.onUpdate(updatedQuestion), 0);
  };

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.currentTarget.value;
    const comment = { [`comment_${props.index}`]: value };

    setComment(comment);
    // update parent
    let updatedQuestion = props.question;
    updatedQuestion.comments = value;
    setTimeout(() => props.onUpdate(updatedQuestion), 0);
  };
  return (
    <>
      <div className="col-md-10 offset-1">
        <div className="row no-gutters review-top">
          <div className="col-md-4">
            <p className="review-head">{props?.question?.esb_label}</p>
          </div>
          <div className="col-md-4 text-center">
            {props?.question?.esbl_label_type}
          </div>
          <div className="col-md-4 text-right pr-2">
            Weightage of Criteria: <b>{`${props?.question?.esb_weightage}%`}</b>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-8 score-range">
            <span className="review-head">Score</span>
            <ul className="nav score-tab">
              {Array(5)
                .fill(null)
                .map((_, index: number) => {
                  const currentScore = ++index;
                  return (
                    <li
                      className={
                        props?.question?.score === currentScore.toString()
                          ? "nav-item middle-link active"
                          : "nav-item middle-link"
                      }
                    >
                      <a
                        className={
                          props?.question?.score === currentScore.toString()
                            ? "nav-link active-text"
                            : "nav-link link-text"
                        }
                        onClick={() => onScoreClick(currentScore.toString())}
                      >
                        {currentScore.toString()}
                      </a>
                    </li>
                  );
                })}
            </ul>
          </div>

          <div className="col-md-4 p-4 pr-2 weighted-score">
            <span className="weighed-score">Weighted Score</span>
            <br />
            <span className="weighed-score-count">
              {props?.question.weighted_score
                ? props?.question.weighted_score
                : "0.0"}
            </span>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p className="review-head">Comments, if any</p>
            <textarea
              className="form-control text-area-review"
              rows={4}
              placeholder="Describe in max. 500 words"
              value={comment ? comment[`comment_${props.index}`] : ""}
              onChange={onChange}
            ></textarea>
          </div>
        </div>
      </div>
    </>
  );
}

export default WeightageDetailsComponent;
