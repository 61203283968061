import React from "react";
import { Link } from "react-router-dom";
import error from "../../../images/error.svg";
export interface IConfirmationDialog {
  headerText: string;
  bodyText: any;
  navigateTo?: string;
  onDialogClose: () => void;
  buttonText?: string;
  iconClass?: string;
}
function ConfirmationDialog(props: IConfirmationDialog) {
  const { headerText, bodyText, navigateTo, buttonText, iconClass } = props;
  return (
    <>
      <div className="modal-backdrop fade show"></div>
      <div
        className="modal align-middle modal-sisfs d-block"
        id="FormSubmissionSuccess"
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-header" style={{ border: "white" }}>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={props.onDialogClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body text-center">
              {iconClass ? (
                <img src={error} className="search mb-2" alt="search" />
              ) : (
                <i className="fa fa-check-circle-o"></i>
              )}
              <h3 className="modal-message">{headerText}</h3>
              <div className="modal-text">{bodyText}</div>
              <div className="modal-action">
                {navigateTo ? (
                  <button
                    className="btn btn-sisfs btn-yellow-primary"
                    onClick={props.onDialogClose}
                  >
                    <Link to={navigateTo} data-dismiss="modal">
                      Continue
                    </Link>
                  </button>
                ) : (
                  <button
                    className="btn btn-sisfs btn-yellow-primary"
                    onClick={props.onDialogClose}
                  >
                    <a>{buttonText ? buttonText : "Close"}</a>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfirmationDialog;
