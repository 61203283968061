import React, { Fragment, useEffect, useState } from "react";
import {
  Documents,
  FileUploadModelTranche,
} from "../../../models/funddetails/TrancheDetails";
import Plus from "../../../images/Plus.svg";
import Minus from "../../../images/Minus.svg";
import pdf from "../../../images/PDF.svg";
import xls from "../../../images/xls.svg";
import xlsx from "../../../images/xls.svg";
import Allround from "../../../images/Allround.svg";
import doc from "../../../images/doc.svg";
import { UploadTypeEnum } from "../../../utils/Enums";
import { ApplicationStatus } from "../../dpiit/incubator/ApplicationGeneralDetails";
import { changeNumberFormat, createFile } from "../../../utils/CommonMethods";
import { get, post } from "../../../effects/api/api.common";
import {
  File_node_url,
  GET_ALL_STARTUP_TRANCHES,
  STARTUP_GET_TRANCHE_STATUS,
  STARTUP_STORE_TRANCHE_REQUEST,
  STARTUP_TRANCHE_REMOVE_DOCUMENT,
  STARTUP_TRANCHE_UPLOAD_DOCUMENT,
  STARTUP_UPDATE_TRANCHE,
} from "../../../constants/api";
import { FileUploadModel } from "../../../models/FileUploadModel";
import FileUploader from "@amodv/react-file-uploader";
import { getSessionStorageValue } from "../../../effects/utils/session-storage";
import UserInfoModel from "../../../models/UserInfoModel";
import Calendar from "../../common/controls/Calendar";
import { useHistory } from "react-router-dom";
import {
  StartupTrancheDetail,
  TrancheRequest,
  UpdateTranche,
} from "../../../models/funddetails/StartupTrancheDetails";
import ConfirmationDialog from "../../common/controls/ConfirmationDialog";
import ConfirmationDialogNote from "../../common/controls/ConfirmationDialogNote";

import moment from "moment";
import { type, userInfo } from "os";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface ITrancheProps {
  trancheDetailsModel: Array<StartupTrancheDetail>;

  total_grant_approved: number;
  loggedInRole?: string;
  instrumentStatus?: any;
  statusId2: any;
  evDate2: any;
  isPrefer2: any;
  sel1: any;
  applicationStatusId: any;
  applicationIDDD: any;
  onTrancheDataChange: any;
  disbursed_to_startups: any;
  disbursed_to_inc: any;
}
interface IIKeyValueIndexer {
  [key: string]: boolean;
}

function TrancheWiseDetails(props: ITrancheProps) {
  const {
    trancheDetailsModel,
    total_grant_approved,
    loggedInRole,
    instrumentStatus,
    statusId2,
    evDate2,
    isPrefer2,
    sel1,
    applicationStatusId,
    applicationIDDD,
    onTrancheDataChange,
    disbursed_to_startups,
    disbursed_to_inc,
  } = props;
  //console.log(trancheDetailsModel, 'trancheDetailsModel');
  const [activeIndex, setActiveIndex] = useState(0);
  var currentdatenew = moment().format("YYYY-MM-DD");
  const [amount_disbursed_startups, setDisbursedToStartups] = useState<any>("");
  const [amount_disbursed_incs, setDisbursedToIncs] = useState<any>("");
  const [loggedInRoleName, setLoggedInRoleName] = useState("");
  const changeActive = (id: number) => {
    setActiveIndex(id);
  };
  const changeActiveBack = () => {
    setActiveIndex(0);
  };
  const [openUploadBlock, setOpenUploadBlock] = useState<IIKeyValueIndexer>();
  const [showfundvalidation, setshowfundvalidation] = useState(false);
  const [validation,setValidation] = useState(false);
  const [showValidationFailureModal1, setshowValidationFailureModal1] =
    useState(false);
  const [showValidationFailureModal, setshowValidationFailureModal] =
    useState(false);
  const user: any = getSessionStorageValue("user");
  const userInfo = JSON.parse(user);
  const [forceRender, setForceRender] = useState(0);
  const [failDialogData, setFailDialogData] = useState({
    header: "",
    text: "",
    note: "",
  });
  const [flag,setFlag] = useState(0)
  const [showIncubatorFailureModal, setShowIncubatorFailureModal] =
    useState(false);
  const [isOpenUploadBlock, setIsOpenUploadBlock] = useState<
    Array<ApplicationStatus>
  >([]);
  const [dateEmptyPopup, setdateEmptyPopup] = useState(false);
  const [disbursedDate,setDisbursedDate] = useState(false);
  const [disbursedAmountPopup, setDisbursedAmountPopup] = useState(false);
  const [checkStatus, setCheckStatus] = useState<any>("0");
  const formatDate = (date: string) => {
    var month = new Array();
    month[0] = "Jan";
    month[1] = "Feb";
    month[2] = "Mar";
    month[3] = "April";
    month[4] = "May";
    month[5] = "Jun";
    month[6] = "Jul";
    month[7] = "Aug";
    month[8] = "Sep";
    month[9] = "Oct";
    month[10] = "Nov";
    month[11] = "Dec";

    var d = new Date(date),
      monthn = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (monthn.length < 2) monthn = "0" + monthn;
    if (day.length < 2) day = "0" + day;

    return [day, month[d.getMonth()], year].join(" ");
  };

  const [trancheData, setTrancheData] = useState<Array<StartupTrancheDetail>>([
    new StartupTrancheDetail(
      0,
      0,
      "",
      0,
      0,
      "",
      "",
      new Documents([], [], []),
      "",
      0,
      0,
      "",
      "",
      "",
      "",
      "",
      "",
      false,
      false,
      false,
      "",
      "",
      ""
    ),
  ]);
  const [trancheRequest, setTrancheRequest] = useState<TrancheRequest>(
    new TrancheRequest(0, 0, "", 0, "", [], [])
  );
  const [updateTranche, setUpdateTranche] = useState<UpdateTranche>(
    new UpdateTranche(0, 0, 0, 0, 0, "", "", 0)
  );
  const handleChangeTrancheRequest = (
    evt: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const name = evt.target.name;
    const value = evt.target.value;

    let trancheDetails = JSON.parse(JSON.stringify(trancheRequest));
    trancheDetails[name] = evt.target.value;
    setTrancheRequest(trancheDetails);
  };

  useEffect(() => {
    setDisbursedToStartups(props.disbursed_to_startups);
    setDisbursedToIncs(props.disbursed_to_inc);
    // console.log(applicationStatus,loggedInRole,statusId2,isPrefer2,"143")
    // console.log(trancheDetailsModel,!instrumentStatus,"143")
    // console.log(applicationStatusId,"143")
    setTrancheData(trancheDetailsModel);
    // getTrancheRequest(application_id)
    getUserRoleAccess();
    setApprovedAmountLimit();
    // if (typeof trancheDetailsModel != "undefined") {
    //   setManualTrancheDetails();
    // }
    // setManualTrancheDetails();

    if (typeof trancheDetailsModel != "undefined") {
      setTimeout(() => {
        setManualTrancheDetails();
      }, 1200);
      return;
    }
  }, [trancheDetailsModel, instrumentStatus]);
  useEffect(() => {}, [updateTranche]);
  useEffect(() => {
    getTrancheStatus();
  }, []);
  const [applicationStatus, setApplicationStatus] = useState<
    Array<ApplicationStatus>
  >([]);

  const [is_Startup_user, setIsStartup] = useState(false);
  const [is_incubator_user, setIsIncubator] = useState(false);

  const [loader, setLoader] = useState(false);
  const [updateLoder, setUpdateLoader] = useState(false);
  const [tranche1_approved_limit, setApprovedLimit] = useState(0);
  const [showLimitMessage, setLimitMessage] = useState(false);
  const [uploadMsg, setUploadMsg] = useState({ result: false, message: "" });
  const setApprovedAmountLimit = () => {
    if (total_grant_approved > 0) {
      setApprovedLimit(total_grant_approved);
    }
  };

  const getUserRoleAccess = () => {
    const user = getSessionStorageValue("user");
    if (user) {
      const userInfo = JSON.parse(user) as UserInfoModel;
      setLoggedInRoleName(userInfo.roles);
      // console.log(userInfo.roles,"%%")
      if (userInfo.roles == "incubator") {
        setIsIncubator(true);
      }
      if (userInfo.roles == "startup") {
        setIsStartup(true);
      }
    }
  };

  const setManualTrancheDetails = () => {
    var trancheDetailsData: Array<StartupTrancheDetail> = trancheDetailsModel;

    //isNextDisbursed
    for (var i = trancheDetailsData.length - 1; i >= 0; i--) {
      var tranche_details = trancheDetailsData[i];
      // console.log(tranche_details,"tranche_details")
      tranche_details.proofDisbursementFlag = false;

      if (i == trancheDetailsData.length - 1) {
        tranche_details.isNextDisbursed = false;
      }
      // console.log(i,"i")
      if (
        i - 1 >= 0 &&
        (trancheDetailsData[i - 1].startup_tranches_statuses_label.trimEnd() ==
          "Disbursed" ||
          trancheDetailsData[i - 1].startup_tranches_statuses_label.trimEnd() ==
            "Disbursement in Process") &&
        (trancheDetailsData[i].startup_tranches_statuses_label.trimEnd() ==
          "Disbursed" ||
          trancheDetailsData[i + 1].startup_tranches_statuses_label.trimEnd() ==
            "Disbursement in Process")
      ) {
        // console.log(i-1,"i-1")
        // console.log(trancheDetailsData[i - 1].startup_tranches_statuses_label.trimEnd(),"sri@@")
        tranche_details.isNextDisbursed = true;
      } else {
        tranche_details.isNextDisbursed = false;
      }

      trancheDetailsData[i] = tranche_details;
    }

    //isPrevDisbursed
    for (var i = trancheDetailsData.length - 1; i >= 0; i--) {
      var tranche_details = trancheDetailsData[i];
      if (
        i + 1 <= trancheDetailsData.length - 1 &&
        (trancheDetailsData[i + 1].startup_tranches_statuses_label.trimEnd() ==
          "Disbursed" ||
          trancheDetailsData[i + 1].startup_tranches_statuses_label.trimEnd() ==
            "Disbursement in Process") &&
        trancheDetailsData[i].startup_tranches_statuses_label.trimEnd() !=
          "Not Requested"
      ) {
        tranche_details.isPrevDisbursed = true;
      } else {
        tranche_details.isPrevDisbursed = false;
      }
      if (
        i == trancheDetailsData.length - 1 &&
        (trancheDetailsData[i].startup_tranches_statuses_label.trimEnd() ==
          "Disbursed" ||
          trancheDetailsData[i].startup_tranches_statuses_label.trimEnd() ==
            "Requested" ||
          trancheDetailsData[i].startup_tranches_statuses_label.trimEnd() ==
            "Disbursement in Process" ||
          trancheDetailsData[i].startup_tranches_statuses_label.trimEnd() ==
            "Incomplete" ||
          trancheDetailsData[i].startup_tranches_statuses_label.trimEnd() ==
            "Verification of Documents in Process")
      ) {
        tranche_details.isPrevDisbursed = true;
      }

      trancheDetailsData[i] = tranche_details;
    }

    setTrancheData(trancheDetailsData);
  };
  // trrigers on date change
  const handleDateChange = (date: Date, field: string) => {
    // const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
    // const isValidDate = dateRegex.test('17/10/2023');
    
    let trancheDetails = JSON.parse(JSON.stringify(updateTranche));
    trancheDetails[field] = date;
    setUpdateTranche(trancheDetails);

    // console.log(trancheDetails,'11','trancheDetails')
  };

  //get application status
  const getTrancheStatus = () => {
    get(STARTUP_GET_TRANCHE_STATUS).then((response: any) => {
      if (!response || !response.data) return;
      if (response.data.data != undefined) {
        const status = response.data.data.map((item: any) => ({
          label: item.label,
          value: item.id,
        }));
        setApplicationStatus(status);
      }
    });
  };

  const downloadDocument = (file: any, file_name: any) => {
    fetch(file).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = file_name;
        a.click();
      });
    });
  };

  const getDisburseentDate = (requested_date: string, key: string) => {
    if (key == "date" && requested_date != "") {
      let req_date = new Date(requested_date);
      req_date.setDate(new Date(requested_date).getDate() + 30);

      return formatDate(new Date(req_date).toLocaleDateString());
    }

    if (key == "day") {
      let day = 30;
      if (requested_date != "") {
        let req_date = new Date(requested_date);
        req_date.setDate(new Date(requested_date).getDate() + 30);

        let disbursementDue = formatDate(
          new Date(req_date).toLocaleDateString()
        );

        var date1 = new Date();
        var date2 = req_date;

        var Difference_In_Time = date2.getTime() - date1.getTime();

        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        day = Difference_In_Days;
      }
      return Number(day).toFixed(0);
    }
  };
  // const getFileDetails = (file: any, key: string) => {
  //   let file_name = '';
  //   file_name =
  //     file != undefined && file != null && file.length > 1
  //       ? file.substr(file.lastIndexOf('/') + 1, file.length)
  //       : '';
  //   if (key == 'name') return file_name;
  //   if (key == 'file') return file != undefined ? file.toString() : '';
  // };

  const getFileDetails = (file: any, key: string) => {
    // console.log(file,"file@@@@@@@@@@@@")
    let file_name = "";
    file_name =
      file != undefined && file != null && file.length > 1
        ? file.substr(file.lastIndexOf("/") + 1, file.length)
        : "";

    const file_type =
      file != undefined && file != null && file.length > 1
        ? file.substr(file.lastIndexOf(".") + 1, file.length)
        : "";

    if (key == "name") return file_name;
    if (key == "file") return file != undefined ? file.toString() : "";
    if (key == "type") return file != undefined ? file_type.toString() : "";
  };

  const setDocumentFlag = (index: number, flag: boolean) => {
    setLoader(true);
    var trancheDetailsData = trancheData;
    for (var i = 0; i < trancheDetailsData.length; i++) {
      //setTrancheDetails(trancheDetailsData[i]);
      var tranche_details = trancheDetailsData[i];
      trancheDetailsData[i].proofDisbursementFlag = flag;
      trancheDetailsData[i] = tranche_details;
    }
    setTrancheData(trancheData);
    setTimeout(() => {
      setLoader(false);
    }, 4000);
  };

  const [tranche_id, setTrancheId] = useState(0);
  const [dateRelease, setDateRelease] = useState<any>("");
  const [fundDisbursed, setFundDisbursed] = useState<any>("");
  const [tranche_status, setTrancheStatus] = useState(0);
  const [application_id, setApplicationId] = useState(0);
  const [fundApproved, setFundApproved] = useState<any>("");
  const getDocumentFlag = (index: number, flag: boolean) => {
    var key = "ProofDisbursement" + index;
    return key;
  };
  const [editName, setEditName] = useState("");
  const [nameProp, setNameProp] = useState("");
  const [prevComment, setPrevComment] = useState("");
  const [prevStatus, setPrevStatus] = useState("");
  const [showData, setShowData] = useState(false);
  const setModalDetails = (
    field_name: string,
    tranche_id: number,
    application_id: number,
    value: string,
    status?: string,
    comment?: string,
    trancheStatus?: number,
    dateOfRelease?: any,
    fundReleased?: any,
    fundApproved?: any
  ) => {
    // console.log(field_name);
    // console.log(tranche_id);
    // console.log(application_id);
    // console.log(value);
    // console.log(status);
    // console.log(comment);
    // console.log(trancheStatus);
    if (field_name == "approved_amount") {
      // console.log(updateTranche,"tranche!!!!")
      setEditName("Fund Approved");
      setNameProp("approved_amount");
      // setLimitMessage(true);
      setApprovedAmountLimit();

      if (status == "Tranche 1") {
        if (tranche1_approved_limit == 0) {
          setApprovedAmountLimit();
        }
        setLimitMessage(true);
      } else {
        setLimitMessage(false);
      }
      if(value=='0' || value == ''){
        setFlag(2);
      }
    }
    if (field_name == "fund_released") {
      setEditName("Fund Disbursed");
      setNameProp("fund_released");
      if(value=='0' || value == ''){
        setFlag(2);
      }
    }
    if (field_name == "tranch_comment") {
      setEditName("Tranche Comments");
      setNameProp("tranch_comment");
    }
    if (field_name == "tranche_requested_date") {
      setEditName("Tranche Requested Date");
      setNameProp("tranche_requested_date");
    }
    if (field_name == "funds_requested") {
      setEditName("Fund Requested");
      setNameProp("funds_requested");
    }
    if (field_name == "date_of_release") {
      setEditName("Date of Fund Disbursed");
      setNameProp("date_of_release");
    }

    if (field_name == "cash_in_hand") {
      setEditName("Cash in hand");
      setNameProp("cash_in_hand");
    }

    if (field_name == "startup_tranches_statuses_label") {
      setEditName("Tranche Status");
      setNameProp("startup_tranches_statuses_label");
    }

    setTrancheId(tranche_id);
    setApplicationId(application_id);
    setDateRelease(dateOfRelease);
    setFundDisbursed(fundReleased);
    setFundApproved(fundApproved);
    // setTrancheStatus(trancheStatus)
    let trancheDetails = JSON.parse(JSON.stringify(updateTranche));
    if (
      field_name == "funds_requested" ||
      field_name == "fund_released" ||
      field_name == "approved_amount"
    ) {
      trancheDetails[field_name] = Number(value).toFixed();
    } else {
      trancheDetails[field_name] = value;
    }
    if (field_name == "tranch_comment") {
      comment = value;
    }

    if (field_name == "date_of_release") {
      trancheDetails["date_of_release"] = new Date();
    }

    trancheDetails["startup_tranche_id"] = tranche_id;
    trancheDetails["startup_application_id"] = application_id;
    // trancheDetails['tranch_comment'] = comment;
    // trancheDetails['tranche_status_id'] = trancheStatus
    // console.log(trancheDetails,"Datataaa")

    if (status != null) {
      // console.log(applicationStatus,"Datataaa")
      // console.log(status,"Datataaa")
      trancheDetails["tranche_status_id"] = applicationStatus.find(
        (option) => option.value == trancheStatus
      )?.value;
      // console.log(trancheDetails['tranche_status_id'],"value!!")
      trancheDetails["tranch_comment"] = comment;
      setPrevStatus(trancheDetails["tranche_status_id"]);
      setPrevComment(comment != undefined ? comment : "");
    }
    // console.log(trancheDetails);

    setUpdateTranche(trancheDetails);
    // console.log(trancheDetails,'22','trancheDetails')

    let trancheRequestDetails = JSON.parse(JSON.stringify(trancheRequest));
    trancheRequestDetails[field_name] = value;
    trancheRequestDetails["startup_tranche_id"] = tranche_id;
    trancheRequestDetails["startup_application_id"] = application_id;
    setTrancheRequest(trancheRequestDetails);
  };

  const updateTrancheDetails = (updateProp: string) => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    if (checkStatus == "1") {
      setDisbursedAmountPopup(true);
      return;
    }
    if(flag == 1){
      if((updateTranche.approved_amount == 0 || updateTranche.approved_amount == null)){
        setValidation(true)
        return;
      }else{
        setValidation(false)
      }
    }
    if(flag ==2){
      if((updateTranche.fund_released == 0 || updateTranche.fund_released == null)){
        setValidation(true)
        return;
      }
      else{
        setValidation(false)
      }
    }
    
    if(updateTranche.date_of_release == null){
      setDisbursedDate(true)
      return;
    }
    // return;
    if (updateProp == "cash_in_hand") {
      const data_input = {
        startup_application_id: trancheRequest.startup_application_id,
        startup_tranche_id: trancheRequest.startup_tranche_id,
        funds_requested: trancheRequest.amount_required,
        cash_in_hand: trancheRequest.cash_in_hand,
      };
      post(STARTUP_STORE_TRANCHE_REQUEST, data_input).then((response: any) => {
        if (!response || !response.data || response.data == "") return;
        if (response.data != undefined && response.data != "") {
          if (response.data.result == "success") {
            //getTrancheRequest(application_id);
            setShowSuccessModal(true);
            getTrancheRequest(application_id);

            // setTrancheData([...trancheData])
            // console.log(trancheData,"TrancheData")
          } else {
            setShowFailureModal(true);
          }
        }
      });
    } else {
      let trancheDetails = JSON.parse(JSON.stringify(updateTranche));
      trancheDetails["startup_tranche_id"] = tranche_id;
      trancheDetails["startup_application_id"] = application_id;
      trancheDetails["tranch_comment"] = "";
      // console.log(trancheDetails);
      // return;
      setUpdateTranche(trancheDetails);
      props.applicationIDDD(application_id);
      let update_tranche = {
        startup_tranche_id: tranche_id,

        startup_application_id: application_id,

        tranche_status_id: updateTranche.tranche_status_id,

        tranch_comment:
          updateTranche.tranch_comment == null || ""
            ? 0
            : updateTranche.tranch_comment,

        approved_amount:
          updateTranche.approved_amount == 0
            ? 0
            : updateTranche.approved_amount,

        fund_released:
          updateTranche.fund_released == 0 ? 0 : updateTranche.fund_released,

        date_of_release:
          updateTranche.date_of_release == ""
            ? 0
            : updateTranche.date_of_release,

        logged_user_id: userInfo.id,
      };
      
      // console.log(update_tranche);
        
      // return
      //     console.log(updateProp,updateTranche.tranch_comment,"updatesss")
      // if(updateProp == 'tranche_status_id' && (updateTranche.tranch_comment == null || updateTranche.tranch_comment == '')){
      //   setFailDialogData({
      //     "header":"Oops",
      //     "text":"Please Enter Tranche Comment",
      //     "note":''
      //   })
      //   setShowIncubatorFailureModal(true);
      //   return;
      // }

      // if (updateProp == 'tranche_status_id'){
      //   update_tranche = {
      //     startup_tranche_id: tranche_id,
      //     startup_application_id: application_id,
      //     tranche_status_id: updateTranche.tranche_status_id,
      //     tranch_comment: updateTranche.tranch_comment,
      //   };
      // }

      // if (updateProp == 'date_of_release')
      //   update_tranche = {
      //     startup_tranche_id: tranche_id,
      //     startup_application_id: application_id,
      //     date_of_release: updateTranche.date_of_release,
      //   };

      // if (updateProp == 'fund_released')
      //   update_tranche = {
      //     startup_tranche_id: tranche_id,
      //     startup_application_id: application_id,
      //     fund_released: updateTranche.fund_released,
      //   };

      // if (updateProp == 'approved_amount')
      //   update_tranche = {
      //     startup_tranche_id: tranche_id,
      //     startup_application_id: application_id,
      //     approved_amount: updateTranche.approved_amount,
      //   };

      // if (updateProp == 'tranch_comment')
      //   update_tranche = {
      //     startup_tranche_id: tranche_id,
      //     startup_application_id: application_id,
      //     tranch_comment: updateTranche.tranch_comment,
      //   };

      // if (updateProp == 'funds_requested')
      //   update_tranche = {
      //     startup_tranche_id: tranche_id,
      //     startup_application_id: application_id,
      //     funds_requested: updateTranche.funds_requested,
      //   };

      let gettrancheData: any = trancheData.find(
        (person) => person.startup_tranche_id === tranche_id
      );
      //   console.log("!!!!!!!!!!!!!",gettrancheData);
      //  console.log(updateTranche.approved_amount , "@@@@@@@@@@@@@@@ " , gettrancheData.fund_released)
      let totalfunds = total_grant_approved;
      let sumApprove = trancheData.reduce(function (prev: any, current: any) {
        return prev + +current.approved_amount;
      }, 0);
      // console.log(updateTranche.approved_amount,updateTranche.fund_released,gettrancheData.fund_released,"approved_amount")
      // console.log(updateTranche.approved_amount<gettrancheData.fund_released,"approved_amount")

      if (typeof updateTranche.approved_amount === "string") {
        updateTranche.approved_amount = Number(updateTranche.approved_amount);
      }
      if (typeof updateTranche.fund_released === "string") {
        updateTranche.fund_released = Number(updateTranche.fund_released);
      }
      //  if ((updateTranche.fund_released > totalfunds)) {
      //    setshowfundvalidation(true);
      //    return;
      //  }
      if (
        updateProp == "approved_amount" &&
        updateTranche.approved_amount < gettrancheData.fund_released
      ) {
        //  setshowValidationFailureModal1(true);
        //  return;
        setFailDialogData({
          header: "Oops",
          text: "Amount Disbursed should be less than or equal to Approved Amount",
          note: "",
        });
        setShowIncubatorFailureModal(true);
        return;
      }
      if (
        updateProp == "approved_amount" &&
        sumApprove -
          gettrancheData.approved_amount +
          updateTranche.approved_amount >
          totalfunds
      ) {
        setFailDialogData({
          header: "Oops",
          text: "Entered Amount should be less than or equal to Total Funds Approved",
          note: "",
        });
        setShowIncubatorFailureModal(true);
        return;
      }
      //  console.log("Totalllllll ", totalfunds)
      if (
        updateProp == "fund_released" &&
        updateTranche.fund_released > gettrancheData.approved_amount
      ) {
        setFailDialogData({
          header: "Oops",
          text: "Amount Disbursed should be less than or equal to Approved Amount",
          note: "",
        });
        setShowIncubatorFailureModal(true);
        return;
      }
      setUpdateLoader(true);
      if (
        updateTranche.date_of_release != null ||
        updateTranche.date_of_release != ""
      ) {
      }
      //  return;
      post(`${STARTUP_UPDATE_TRANCHE}`, update_tranche).then(
        (response: any) => {
          if (!response || !response.data) return;

          if (response.data.result == "success") {
            //
            setShowSuccessModal(true);
            // setTrancheData([...trancheData])
            getTrancheRequest(application_id);
            setUpdateLoader(false);
          } else {
            setUpdateLoader(false);
            setShowFailureModal(true);
          }
          // getTrancheRequest(application_id);
          // history.push({
          //   pathname: '/dpiit_view_incubator',
          //   state: { application_id: application_id ,isFundDetails:true},
          // });
          // window.location.reload();
        }
      );
    }
  };

  //   API: startup/tranche/{trancheId}/addComment
  // Method: Post
  // Params: {'startup_application_id', 'tranche_status_id', 'tranche_comment'}
  const updateTrancheCommentByStatus = () => {
    let trancheDetails = JSON.parse(JSON.stringify(updateTranche));
    // console.log(trancheDetails);
    let addCommentsByStatus = {
      // logged_user_id: userInfo.id,
      // startup_application_id: application_id,
      // tranch_comment: trancheDetails.tranch_comment,
      // startup_tranche_id: trancheDetails.startup_tranche_id,
      // tranche_status_id: trancheDetails.tranche_status_id,
      startup_tranche_id: tranche_id,

      startup_application_id: application_id,

      tranche_status_id: trancheDetails.tranche_status_id,

      tranch_comment:
        trancheDetails.tranch_comment == null || ""
          ? 0
          : trancheDetails.tranch_comment,

      approved_amount:
        trancheDetails.approved_amount == 0
          ? 0
          : trancheDetails.approved_amount,

      fund_released:
        trancheDetails.fund_released == 0 ? 0 : trancheDetails.fund_released,

      date_of_release:
        trancheDetails.date_of_release == ""
          ? 0
          : trancheDetails.date_of_release,

      logged_user_id: userInfo.id,
    };

    trancheDetails["startup_tranche_id"] = tranche_id;
    trancheDetails["startup_application_id"] = application_id;
    //trancheDetails['tranch_comment'] = application_id;
    setUpdateTranche(trancheDetails);
    // console.log(trancheDetails,'33','trancheDetails')
    // post(`${STARTUP_ADD_COMMENT}/${tranche_id}/addComment`, addCommentsByStatus).then(
    post(`${STARTUP_UPDATE_TRANCHE}`, addCommentsByStatus).then(
      (response: any) => {
        if (!response || !response.data) return;
        if (response.data.result == "success") {
          setShowSuccessModal(true);
          getTrancheRequest(application_id);
        } else {
          setShowFailureModal(true);
        }
        // getTrancheRequest(application_id);
      }
    );
  };

  const getTrancheRequest = (app_id: number) => {
    let tranche_parameters = {
      startup_application_id: app_id,
      order_by: "DESC",
    };

    // { console.log("GEt ALL TRANCHES",) }

    post(`${GET_ALL_STARTUP_TRANCHES}`, tranche_parameters).then(
      (response: any) => {
        if (!response || !response.data) return;
        var trancheDetailsData: Array<StartupTrancheDetail> =
          response.data.data;
        setTrancheData(trancheDetailsData);
        props.onTrancheDataChange();
        for (var i = 0; i < trancheDetailsData.length; i++) {
          //setTrancheDetails(trancheDetailsData[i]);
          var tranche_details = trancheDetailsData[i];

          const file_data = tranche_details.documents;

          if (file_data) {
            Object.keys(file_data).length &&
              Object.keys(file_data).map((key: string, index) => {
                switch (key) {
                  case UploadTypeEnum.ProofDisbursement:
                    file_data[key].map((data: any) => {
                      createFile(data.file).then((blob: any) => {
                        blob.lastModifiedDate = new Date();
                        const src = data.file as string;
                        blob.name = src.substr(
                          src.lastIndexOf("/") + 1,
                          src.length
                        );
                        const file: FileUploadModelTranche =
                          new FileUploadModelTranche(
                            data.file_id,
                            data.file_type_id,
                            blob as File,
                            data.file.ext,
                            data.file
                          );

                        // file_data.proof_of_disbursement[index]=file;

                        const index = file_data.proof_of_disbursement.findIndex(
                          (el) => el.file_id === data.file_id
                        );
                        file_data.proof_of_disbursement[index] = {
                          file_id: data.file_id,
                          file_type_id: data.file_type_id,
                          file: blob as File,
                          ext: data.ext,
                          fileName: blob.name,
                        };
                      });
                    });

                    break;
                }
              });
          }
          tranche_details.documents = file_data;
          trancheDetailsData[i] = tranche_details;
        }

        for (var i = trancheDetailsData.length - 1; i >= 0; i--) {
          var tranche_details = trancheDetailsData[i];

          tranche_details.proofDisbursementFlag = false;
          //isNextDisbursed
          if (i == trancheDetailsData.length - 1) {
            tranche_details.isNextDisbursed = false;
          }
          if (
            i - 1 >= 0 &&
            (trancheDetailsData[
              i - 1
            ].startup_tranches_statuses_label.trimEnd() == "Disbursed" ||
              trancheDetailsData[
                i - 1
              ].startup_tranches_statuses_label.trimEnd() ==
                "Disbursement in Process") &&
            (trancheDetailsData[i].startup_tranches_statuses_label.trimEnd() ==
              "Disbursed" ||
              trancheDetailsData[
                i + 1
              ].startup_tranches_statuses_label.trimEnd() ==
                "Disbursement in Process")
          ) {
            tranche_details.isNextDisbursed = true;
          } else {
            tranche_details.isNextDisbursed = false;
          }
          trancheDetailsData[i] = tranche_details;
        }

        //isPrevDisbursed

        for (var i = trancheDetailsData.length - 1; i >= 0; i--) {
          var tranche_details = trancheDetailsData[i];

          if (
            i + 1 <= trancheDetailsData.length - 1 &&
            (trancheDetailsData[
              i + 1
            ].startup_tranches_statuses_label.trimEnd() == "Disbursed" ||
              trancheDetailsData[
                i + 1
              ].startup_tranches_statuses_label.trimEnd() ==
                "Disbursement in Process") &&
            trancheDetailsData[i].startup_tranches_statuses_label.trimEnd() !=
              "Not Requested"
          ) {
            tranche_details.isPrevDisbursed = true;
          } else {
            tranche_details.isPrevDisbursed = false;
          }
          if (
            i == trancheDetailsData.length - 1 &&
            (trancheDetailsData[i].startup_tranches_statuses_label.trimEnd() ==
              "Disbursed" ||
              trancheDetailsData[i].startup_tranches_statuses_label.trimEnd() ==
                "Requested" ||
              trancheDetailsData[i].startup_tranches_statuses_label.trimEnd() ==
                "Disbursement in Process" ||
              trancheDetailsData[i].startup_tranches_statuses_label.trimEnd() ==
                "Incomplete" ||
              trancheDetailsData[i].startup_tranches_statuses_label.trimEnd() ==
                "Verification of Documents in Process")
          ) {
            tranche_details.isPrevDisbursed = true;
          }

          trancheDetailsData[i] = tranche_details;
        }

        // setManualTrancheDetails();
      }
    );
  };

  const handleChange = (
    evt: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const name = evt.target.name;
    const value = evt.target.value;   
    if(name== 'approved_amount'){
      setFlag(1)
      // console.log(flag)
    }else if (name == 'fund_released'){
      setFlag(2)
      // console.log(flag)
    }
    if (name == "tranche_status_id") {
      // console.log( prevStatus,amount_disbursed_incs,fundDisbursed,amount_disbursed_startups,"responseeeeee")
      // console.log(evt.target.value,typeof evt.target.value,"responseeeeee")
      // console.log(amount_disbursed_startups,fundDisbursed,amount_disbursed_incs,"responseeeeee")
      // console.log(typeof amount_disbursed_startups,typeof fundDisbursed,typeof amount_disbursed_incs,"responseeeeee")
      // if(prevStatus == '6' &&
      //   ((amount_disbursed_startups-fundDisbursed) < amount_disbursed_incs)
      // ){
      //   setCheckStatus('1')
      // }
      // else
      if (
        evt.target.value == "6" &&
        parseInt(amount_disbursed_startups) + parseInt(fundDisbursed) >
          parseInt(amount_disbursed_incs)
      ) {
        setCheckStatus("1");
      } else {
        setCheckStatus("0");
      }
      let trancheDetails = JSON.parse(JSON.stringify(updateTranche));
      if (prevStatus == evt.target.value) {
        trancheDetails["tranch_comment"] = prevComment;
      } else {
        trancheDetails["tranch_comment"] = "";
      }
      trancheDetails[name] = evt.target.value;
      setUpdateTranche(trancheDetails);
      // console.log(trancheDetails,'44','trancheDetails')
    } else {
      let trancheDetails = JSON.parse(JSON.stringify(updateTranche));
      if (name == "fund_released" || name == "approved_amount") {
        
        const numStr = String(evt.target.value);
        var count = 0;
        if (numStr.includes(".")) {
          count = numStr.split(".")[1].length;
        }
        if (count > 2) {
          trancheDetails[name] = Number(evt.target.value).toFixed(2);
        } else {
          trancheDetails[name] = evt.target.value;
        }
      } else {
        trancheDetails[name] = evt.target.value;
      }

      setUpdateTranche(trancheDetails);
    }
  };
  const history = useHistory();
  /** upload the files */
  const onFileUpload = (
    files: Array<File>,
    type: string,
    item: StartupTrancheDetail,
    index: number
  ) => {
    let indexes: Array<string> = [];

    for (const file of files) {
      let updatedOtherDetail = Object.assign(
        {},
        item.documents.proof_of_disbursement
      );
      const fileData = item.documents.proof_of_disbursement;

      const index = fileData.findIndex((el: any) => el.file.name === file.name);

      if (index == -1) {
        if (file.type === "application/pdf") {
          const user: any = getSessionStorageValue("user");
          const userInfo = JSON.parse(user);
          const formData = new FormData();
          // Update the formData object
          formData.append("file", file, file.name);
          formData.append(
            "startup_tranche_id",
            item.startup_tranche_id.toString()
          );
          formData.append("file_type", type);
          formData.append("logged_user_id", userInfo.id);
          formData.append("user_Role", userInfo.roles);
          setUploadMsg({ result: false, message: "" });
          post(STARTUP_TRANCHE_UPLOAD_DOCUMENT, formData)
            .then((response: any) => {
              if (!response.data) return;

              setApplicationId(item.startup_application_id);
              if (response.data.data.status === "true") {
                setUploadMsg({
                  result: true,
                  message: "Document Successfully Uploaded",
                });
              } else {
                // console.log("error", response.data.message)
                setUploadMsg({ result: false, message: response.data.message });
              }

              getTrancheRequest(item.startup_application_id);
            })
            .catch((err) => {
              setUploadMsg({
                result: false,
                message: err?.response?.data?.message,
              });
            });
        }
      }
    }
  };

  const [showEditSuccessModal, setShowSuccessModal] = useState(false);
  const [showEditFailureModal, setShowFailureModal] = useState(false);
  const dismissModal = () => {
    setDisbursedDate(false);
    setShowSuccessModal(false);
    setShowFailureModal(false);
    setdateEmptyPopup(false);
    setDisbursedAmountPopup(false);
    setValidation(false)
    window.location.reload();
  };

  // Triggers when delete file is clicked
  const onFileDelete = (
    file: File,
    type: string,
    item: StartupTrancheDetail
  ) => {
    const { proof_of_disbursement, quarterly_reports, supporting_documents } =
      item.documents;
    let remainingFiles: Array<FileUploadModelTranche> = [];
    let fileToBeDeleted: any;

    switch (type) {
      case UploadTypeEnum.ProofDisbursement:
        fileToBeDeleted = proof_of_disbursement.find(
          (uploadedFile: FileUploadModelTranche) => uploadedFile.file === file
        );

        if (!fileToBeDeleted) return;
        remainingFiles = proof_of_disbursement.filter(
          (uf: FileUploadModelTranche) => uf.file !== file
        );

        break;
      case UploadTypeEnum.QuarterlyReport:
        fileToBeDeleted = quarterly_reports.find(
          (uploadedFile: FileUploadModelTranche) => uploadedFile.file === file
        );

        if (!fileToBeDeleted) return;
        remainingFiles = quarterly_reports.filter(
          (uf: FileUploadModelTranche) => uf.file !== file
        );

        break;

      case UploadTypeEnum.SupportingDocuments:
        fileToBeDeleted = supporting_documents.find(
          (uploadedFile: FileUploadModelTranche) => uploadedFile.file === file
        );

        if (!fileToBeDeleted) return;
        remainingFiles = proof_of_disbursement.filter(
          (uf: FileUploadModelTranche) => uf.file !== file
        );

        break;
    }
    // delete the file
    setUploadMsg({ result: false, message: "" });
    post(STARTUP_TRANCHE_REMOVE_DOCUMENT, {
      // startup_application_id: item.startup_application_id,
      file_id: fileToBeDeleted.file_id,
      startup_tranche_id: item.startup_tranche_id,
    }).then((response: any) => {
      if (response.data.data.status === "true") {
        setUploadMsg({ result: true, message: "successfully pdf removed" });
      } else {
        setUploadMsg({ result: false, message: "Failed to remove file" });
      }
      getTrancheRequest(item.startup_application_id);
    });
  };
  const abc = () => {};
  return (
    <div>
      {updateLoder && (
        <div className="spinner-border custom-spinner" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}
      <fieldset disabled={updateLoder}>
        <div className="row">
          <div className="col-md-12">
            {trancheData != undefined && trancheData.length > 0
              ? trancheData.map((item, index) => (
                  <div key={index}>
                    <div className="row">
                      <div
                        id={`q-${index + 1}`}
                        className={
                          activeIndex == index + 1
                            ? "tranche-heading col-sm-10 left-align"
                            : "reports-heading col-sm-10 left-align"
                        }
                      >
                        <span
                          className={
                            activeIndex == index + 1
                              ? "tranche-heading "
                              : "reports-heading "
                          }
                        >
                          {item.tranche_number}
                        </span>
                        &nbsp;&nbsp; &nbsp;&nbsp;
                        {item.tranche_requested_date != null &&
                        (is_Startup_user == true ||
                          is_Startup_user == false) ? (
                          <span
                            className={
                              activeIndex == index + 1
                                ? "tranche-subheading "
                                : "reports-subheading "
                            }
                          >
                            {item.startup_tranches_statuses_label ===
                            "Disbursed"
                              ? "Disbursed"
                              : "Disbursement due within"}{" "}
                            {item.startup_tranches_statuses_label != "Disbursed"
                              ? getDisburseentDate(
                                  item.tranche_requested_date,
                                  "day by "
                                )
                              : ""}
                            {item.startup_tranches_statuses_label != "Disbursed"
                              ? " days by "
                              : ""}
                            {item.startup_tranches_statuses_label != "Disbursed"
                              ? getDisburseentDate(
                                  item.tranche_requested_date,
                                  "date"
                                )
                              : ""}
                          </span>
                        ) : (
                          <>
                            {is_Startup_user == true ||
                            is_Startup_user == false ? (
                              <span
                                className={
                                  activeIndex == index + 1
                                    ? "tranche-subheading "
                                    : "reports-subheading "
                                }
                              >
                                {item.startup_tranches_statuses_label}
                              </span>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                        {/* {is_Startup_user === false ? (
                      <span
                        className={
                          activeIndex == index + 1
                            ? 'tranche-subheading '
                            : 'reports-subheading '
                        }
                      >
                        {item.startup_tranches_statuses_label}
                      </span>
                    ) : (
                      ''
                    )} */}
                        {/* &nbsp;&nbsp;
                        <span>
                          { item.startup_tranches_statuses_label == 'Disbursed' &&
                          (item.date_of_release == null || item.date_of_release == '') ?
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip
                                id="example-tooltip"
                                style={{ fontSize: "14px" }}
                              >
                              Disbursement Details missing in Tranche
                              </Tooltip>
                            }
                          >
                            <i className="fa fa-exclamation-circle" style={{ color: '#F6BE00', fontSize: '16px' }}></i>                            
                          </OverlayTrigger>
                        : ''  
                        }
                        </span> */}
                      </div>
                      <div className="col-sm-1">
                        {/* {is_Startup_user == true &&
                      item.startup_tranches_statuses_label == 'Incomplete' && !instrumentStatus ? (
                      <a
                        className="incubator-details-link "
                        data-target="#requestTranche"
                        data-toggle="modal"
                      >
                        Edit Tranche
                      </a>
                    ) : (
                      ''
                    )} */}
                      </div>
                      <div className="col-sm-1">
                        {activeIndex == index + 1 ? (
                          <div
                            onClick={changeActiveBack}
                            data-toggle="collapse"
                            data-target={`#tranche-expand${index + 1}`}
                            role="button"
                            aria-expanded="false"
                            aria-controls={`tranche-expand${index + 1}`}
                            className="hide-icon pull-right hide-icon-details"
                            id={`show-${index + 1}`}
                          >
                            <img
                              src={Minus}
                              className="img-fluid"
                              alt="SISFS Logo"
                            />
                          </div>
                        ) : (
                          <div
                            onClick={() => changeActive(index + 1)}
                            id={`hide-${index + 1}`}
                            data-toggle="collapse"
                            data-target={`#tranche-expand${index + 1}`}
                            role="button"
                            aria-expanded="false"
                            aria-controls={`tranche-expand${index + 1}`}
                            className="expand-icon pull-right expand-icon-details"
                          >
                            <img
                              src={Plus}
                              className="img-fluid"
                              alt="SISFS Logo"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {activeIndex == index + 1 ? (
                      <div
                        className="collapse" //{activeIndex == index + 1 ? "collapse show" :"collapse"}
                        id={`tranche-expand${index + 1}`}
                      >
                        <div className="">
                          <div className="row mt-05">
                            {/* {console.log(item)} */}
                            <div className="col-md-6 left-align">
                              <span className="incubator-label">
                                Status
                                {/* {is_incubator_user}
                          {item.isNextDisbursed}
                          {item.isPrevDisbursed}
                          {instrumentStatus} */}
                              </span>
                              <br />
                              {item.startup_tranches_statuses_label != null ? (
                                <span className="incubator-label-value-wrap">
                                  {" "}
                                  {item.startup_tranches_statuses_label}{" "}
                                </span>
                              ) : (
                                <span className="incubator-label-value-wrap">
                                  {" "}
                                  {"-"}{" "}
                                </span>
                              )}{" "}
                              {/* {console.log(is_incubator_user,isPrefer2)} */}
                              {/* {console.log(evDate2,isPrefer2,statusId2,sel1,"sel1")} */}
                              {is_incubator_user == true &&
                              evDate2 < currentdatenew &&
                              isPrefer2 == "7" &&
                              statusId2 == "7" &&
                              sel1 == "0" ? (
                                //  &&
                                //            ((isPrefer2 !== '7'&& statusId2 !== '7')||(statusId2 !== '2' ||statusId2 !== '3' ||statusId2 !== '4'||statusId2 !== '5'))

                                //  &&
                                // item.isNextDisbursed == false &&
                                // item.isPrevDisbursed == true && !instrumentStatus
                                // ?

                                applicationStatusId == "Closed" ||
                                applicationStatusId == "Cancelled" ? (
                                  <></>
                                ) : (
                                  <a
                                    className="incubator-details-link "
                                    onClick={() =>
                                      setModalDetails(
                                        "tranches_status_label",
                                        // item.startup_tranche_id,
                                        // item.startup_application_id,
                                        // item.startup_tranches_statuses_label,
                                        // item.tranche_number.trimEnd(),
                                        // item.tranche_status_comment,
                                        // item.tranche_status_id

                                        item.startup_tranche_id,
                                        item.startup_application_id,
                                        item.approved_amount == null
                                          ? ""
                                          : item.approved_amount.toString(),
                                        item.tranche_number.trimEnd(),
                                        item.tranche_status_comment,
                                        item.tranche_status_id,
                                        item.date_of_release,
                                        item.fund_released,
                                        item.approved_amount
                                      )
                                    }
                                    data-target="#editTrancheStatus"
                                    data-toggle="modal"
                                  >
                                    Update
                                  </a>
                                )
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="col-md-6 left-align">
                              <span className="incubator-label">
                                Status updated Date
                              </span>
                              <br />
                              <span className="incubator-label-value">
                                {item.updated_at != null
                                  ? formatDate(item.updated_at)
                                  : "-"}
                              </span>
                            </div>
                          </div>
                          <div className="row mt-05">
                            {/* {console.log(item, '999999999999999999')} */}
                            <div className="col-md-6 left-align">
                              <span className="incubator-label">
                                Fund Approved
                              </span>
                              <br />
                              {item.approved_amount != null ? (
                                <span className="incubator-label-value">
                                  &#8377;{" "}
                                  {changeNumberFormat(
                                    Number(item.approved_amount),
                                    2,
                                    1
                                  )}
                                </span>
                              ) : (
                                <span className="incubator-label-value">
                                  {"-"}
                                </span>
                              )}
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              {/* {(is_incubator_user == true &&
                            item.isNextDisbursed == false && !instrumentStatus)&&(isPrefer2 == '7'&& statusId2 == '7' && sel1 == '0')
                            // (evDate2 > currentdatenew)&&((isPrefer2 !== '7'&& statusId2 !== '7')||(statusId2 !== '2' ||statusId2 !== '3' ||statusId2 !== '4'||statusId2 !== '5'))
 
                            ? ( */}
                              {
                                // item.startup_tranches_statuses_label == 'Disbursed' ||
                                is_incubator_user == true &&
                                item.startup_tranches_statuses_label ==
                                  "Disbursement in Process" &&
                                isPrefer2 == "7" &&
                                statusId2 == "7" &&
                                sel1 == "0" ? (
                                  applicationStatusId == "Closed" ||
                                  applicationStatusId == "Cancelled" ? (
                                    <></>
                                  ) : (
                                    <a
                                      className="incubator-details-link "
                                      onClick={() =>
                                        setModalDetails(
                                          "approved_amount",
                                          item.startup_tranche_id,
                                          item.startup_application_id,
                                          item.approved_amount == null
                                            ? ""
                                            : item.approved_amount.toString(),
                                          item.tranche_number.trimEnd(),
                                          item.tranche_status_comment,
                                          item.tranche_status_id
                                        )
                                      }
                                      data-target="#editTranche"
                                      data-toggle="modal"
                                    >
                                      Edit
                                      {/* {console.log(item,"itemmmm")} */}
                                    </a>
                                  )
                                ) : (
                                  ""
                                )
                              }
                              <br />
                              {/* <span className="incubator-label">
                            Date of Requested
                          </span>
                          <br />
                          <span className="incubator-label-value">
                            {item.tranche_requested_date != null
                              ? formatDate(item.tranche_requested_date)
                              : '-'}
                          </span> */}
                              {/* {is_incubator_user == true ?
                          <a
                          className="incubator-details-link "
                          onClick={() =>
                            setModalDetails(
                              'tranche_requested_date',
                              item.tranch_id,
                              item.application_id,
                              item.tranche_requested_date.toString()
                            )
                          }
                          data-target="#editTranche"
                          data-toggle="modal"
                        >
                          Update
                        </a>
                          :""} */}
                            </div>
                            {/* {console.log(item)} */}
                            <div className="col-md-6 left-align">
                              <span className="incubator-label">
                                Fund Approved Date
                              </span>
                              <br />
                              <span className="incubator-label-value">
                                {item.approved_amount_date != null
                                  ? formatDate(item.approved_amount_date)
                                  : "-"}
                              </span>
                            </div>
                          </div>
                          <div className="row mt-05">
                            <div className="col-md-6 left-align">
                              <span className="incubator-label">
                                Fund Disbursed
                              </span>
                              <br />
                              {item.fund_released != null ? (
                                <span className="incubator-label-value">
                                  &#8377;{" "}
                                  {changeNumberFormat(
                                    Number(item.fund_released),
                                    2,
                                    1
                                  )}
                                </span>
                              ) : (
                                <span className="incubator-label-value">
                                  {"-"}
                                </span>
                              )}
                              {/* <span className="incubator-label-value">NA</span> */}
                              {/* item.startup_tranches_statuses_label == 'Disbursed' ||  */}
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              {is_incubator_user == true &&
                              item.startup_tranches_statuses_label ==
                                "Disbursement in Process" &&
                              isPrefer2 == "7" &&
                              statusId2 == "7" &&
                              sel1 == "0" ? (
                                //  &&
                                // item.isNextDisbursed == false && !instrumentStatus) &&
                                // (evDate2 > currentdatenew)&&((isPrefer2 !== '7'&& statusId2 !== '7')||(statusId2 !== '2' ||statusId2 !== '3' ||statusId2 !== '4'||statusId2 !== '5'))
                                applicationStatusId == "Closed" ||
                                applicationStatusId == "Cancelled" ? (
                                  <> </>
                                ) : (
                                  <>
                                    <a
                                      className="incubator-details-link "
                                      onClick={() =>
                                        setModalDetails(
                                          "fund_released",
                                          // item.startup_tranche_id,
                                          // item.startup_application_id,
                                          // item.fund_released == null
                                          //   ? ''
                                          //   : item.fund_released.toString(),
                                          // item.tranche_number.trimEnd(),
                                          // item.tranche_status_comment,
                                          // item.tranche_status_id

                                          item.startup_tranche_id,
                                          item.startup_application_id,
                                          item.fund_released == null
                                            ? ""
                                            : item.fund_released.toString(),
                                          item.tranche_number.trimEnd(),
                                          item.tranche_status_comment,
                                          item.tranche_status_id
                                        )
                                      }
                                      data-target="#editTranche"
                                      data-toggle="modal"
                                    >
                                      Edit
                                    </a>
                                  </>
                                )
                              ) : (
                                ""
                              )}
                              {/* <span className="incubator-label">
                            Fund Requested
                          </span>
                          <br />
                          {item.funds_requested != null ? (
                            <span className="incubator-label-value">
                              &#8377;{' '}
                              {changeNumberFormat(
                                Number(item.funds_requested),
                                2,
                                1
                              )}
                            </span>
                          ) : (
                            <span className="incubator-label-value">
                              {'-'}
                            </span>
                          )} */}
                              {/* {is_incubator_user == true &&
                            item.tranches_status_label == 'Incomplete' ? (
                              <a
                                className="incubator-details-link "
                                // onClick={() =>
                                //   setModalDetails(
                                //     'funds_requested',
                                //     item.tranch_id,
                                //     item.application_id,
                                //     item.funds_requested.toString()
                                //   )
                                // }
                                data-target="#requestTranche"
                                data-toggle="modal"
                              >
                                Edit
                              </a>
                            ) : (
                              ''
                            )} */}
                            </div>
                            <div className="col-md-6 left-align">
                              <span className="incubator-label">
                                Date of Fund Disbursed
                              </span>
                              <br />
                              {item.date_of_release != null && (
                                <span className="incubator-label-value">
                                  {formatDate(item.date_of_release)}
                                </span>
                              )}
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              {is_incubator_user == true &&
                              item.startup_tranches_statuses_label ==
                                "Disbursement in Process" &&
                              isPrefer2 == "7" &&
                              statusId2 == "7" &&
                              sel1 == "0" ? (
                                // (item.startup_tranches_statuses_label == 'Disbursed' || item.startup_tranches_statuses_label == 'Disbursement in Process') &&
                                // item.isNextDisbursed == false && !instrumentStatus)&&(isPrefer2 == '7'&& statusId2 == '7' && sel1 == '0')

                                // &&
                                // (evDate2 > currentdatenew)&&((isPrefer2 !== '7'&& statusId2 !== '7')||(statusId2 !== '2' ||statusId2 !== '3' ||statusId2 !== '4'||statusId2 !== '5'))
                                applicationStatusId == "Closed" ||
                                applicationStatusId == "Cancelled" ? (
                                  <> </>
                                ) : (
                                  <>
                                    <a
                                      className="incubator-details-link "
                                      onClick={() =>
                                        setModalDetails(
                                          "date_of_release",
                                          // item.startup_tranche_id,
                                          // item.startup_application_id,
                                          // item.date_of_release,
                                          // item.tranche_number.trimEnd(),
                                          // item.tranche_status_comment,
                                          // item.tranche_status_id

                                          item.startup_tranche_id,
                                          item.startup_application_id,
                                          item.approved_amount == null
                                            ? ""
                                            : item.approved_amount.toString(),
                                          item.tranche_number.trimEnd(),
                                          item.tranche_status_comment,
                                          item.tranche_status_id
                                        )
                                      }
                                      data-target="#editTranche"
                                      data-toggle="modal"
                                    >
                                      Edit
                                    </a>
                                  </>
                                )
                              ) : (
                                ""
                              )}
                              {/* <span className="incubator-label">
                            Interest Accumulated on Funds
                          </span>
                          <br />
                          {item.interest_accumulated_on_funds != null &&
                            item.interest_accumulated_on_funds.toString() !=
                            '' ? (
                            <span className="incubator-label-value">
                              &#8377;{' '}
                              {changeNumberFormat(
                                Number(item.interest_accumulated_on_funds),
                                2,
                                1
                              )}
                            </span>
                          ) : (
                            <span className="incubator-label-value">
                              {'-'}
                            </span>
                          )} */}
                            </div>
                          </div>

                          <div className="row mt-05">
                            <div className="col-md-6 left-align"></div>
                            <div className="col-md-6 left-align"></div>
                          </div>

                          <div className="row mt-05">
                            <div className="col-md-6 left-align"></div>
                            {/* <div className="col-md-6 left-align">
                            <span className="fund-label">
                              Is cash-in-hand of the incubator below 10% of the
                              total commitment by EAC?
                            </span>
                            <br />
                            <span className="incubator-label-value">
                              {item.cash_in_hand == '1'
                                ? 'Yes'
                                : item.cash_in_hand == '0'
                                ? 'No'
                                : '-'}
                            </span>

                          </div> */}
                          </div>

                          <div className="row mt-05">
                            <div className="col-md-6 left-align">
                              <span className="eac-review-subtitle">
                                Comments/Feedback
                              </span>
                              <br />
                              {/* {console.log(item)} */}
                              <span className="fund-label">
                                {item.tranche_status_comment}
                              </span>

                              <br />
                              {is_incubator_user == true &&
                              item.isNextDisbursed == false &&
                              item.isPrevDisbursed == true &&
                              !instrumentStatus &&
                              isPrefer2 == "7" &&
                              statusId2 == "7" &&
                              sel1 == "0" ? (
                                // (evDate2 > currentdatenew)&&((isPrefer2 !== '7'&& statusId2 !== '7')||(statusId2 !== '2' ||statusId2 !== '3' ||statusId2 !== '4'||statusId2 !== '5'))

                                <a
                                  className="incubator-details-link margin-5"
                                  // data-toggle="modal"
                                  // data-target="#addEditCommentTranche"
                                  onClick={() =>
                                    setModalDetails(
                                      "tranch_comment",
                                      // item.startup_tranche_id,
                                      // item.startup_application_id,
                                      // item.tranche_number.trimEnd(),
                                      // item.startup_tranches_statuses_label,
                                      // item.tranche_status_comment,
                                      // item.tranche_status_id

                                      item.startup_tranche_id,
                                      item.startup_application_id,
                                      item.approved_amount == null
                                        ? ""
                                        : item.approved_amount.toString(),
                                      item.tranche_number.trimEnd(),
                                      item.tranche_status_comment,
                                      item.tranche_status_id
                                    )
                                  }
                                  data-target="#editTranche"
                                  data-toggle="modal"
                                >
                                  Add Comment
                                </a>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="col-md-6 left-align">
                              <span className="incubator-label">
                                Proof of Disbursement
                              </span>
                              <br />
                              <div className="row">
                                <div className="col-md-8 left-align">
                                  {item.documents.proof_of_disbursement !=
                                    undefined &&
                                  item.documents.proof_of_disbursement.length !=
                                    0 ? (
                                    ""
                                  ) : (
                                    <label className="document-label-value">
                                      {" "}
                                      -
                                    </label>
                                  )}
                                  <br />
                                  {/* {console.log(item.documents.proof_of_disbursement)} */}
                                  {item.documents.proof_of_disbursement !=
                                    undefined &&
                                    item.documents.proof_of_disbursement
                                      .length != 0 &&
                                    item.documents.proof_of_disbursement
                                      .slice(-1)
                                      .map((fileObject: any, index) => (
                                        <div className="row">
                                          <div className="col-md-4">
                                            {fileObject.ext == "pdf" ? (
                                              <img src={pdf} alt="SISFS Logo" />
                                            ) : fileObject.ext == "doc" ? (
                                              <img src={doc} alt="SISFS Logo" />
                                            ) : fileObject.ext == "xls" ? (
                                              <img src={xls} alt="SISFS Logo" />
                                            ) : (
                                              <img
                                                src={Allround}
                                                alt="SISFS Logo"
                                              />
                                            )}
                                          </div>
                                          <div className="col-md-8">
                                            <span className="document-label-value">
                                              {/* {console.log(fileObject,"filename")} */}
                                              {getFileDetails(
                                                fileObject.fileName,
                                                "name"
                                              )}
                                            </span>
                                            <br />
                                            <br />
                                            <a
                                              href={getFileDetails(
                                                `${File_node_url}` +
                                                  fileObject.fileName,
                                                "file"
                                              )}
                                              target="_blank"
                                              //download="proof_of_disbursement"
                                              className="incubator-details-link"
                                            >
                                              View
                                            </a>
                                          </div>
                                        </div>

                                        // })
                                      ))}
                                </div>
                              </div>
                              {is_incubator_user == true &&
                              (item.startup_tranches_statuses_label ==
                                "Disbursed" ||
                                item.startup_tranches_statuses_label ==
                                  "Disbursement in Process") &&
                              isPrefer2 == "7" &&
                              statusId2 == "7" &&
                              sel1 == "0" ? (
                                //  &&
                                // (evDate2 > currentdatenew)&&((isPrefer2 !== '7'&& statusId2 !== '7')||(statusId2 !== '2' ||statusId2 !== '3' ||statusId2 !== '4'||statusId2 !== '5'))

                                // ?

                                applicationStatusId == "Closed" ||
                                applicationStatusId == "Cancelled" ? (
                                  <> </>
                                ) : (
                                  <a
                                    className="incubator-details-link "
                                    onClick={
                                      () => setDocumentFlag(index, true)
                                      // setOpenUploadBlock({ ProofDisbursement: true })
                                    }
                                  >
                                    Upload Proof
                                  </a>
                                )
                              ) : (
                                ""
                              )}
                              <br />
                              {loader ? (
                                <div
                                  className="spinner-border custom-spinner loader-position"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              ) : (
                                item &&
                                item.proofDisbursementFlag == true && (
                                  // item.documents.proof_of_disbursement.length ===
                                  //   0) ||
                                  //   (item.documents.proof_of_disbursement.length >
                                  //   0) && (
                                  <>
                                    <span
                                      className="close"
                                      onClick={
                                        () => setDocumentFlag(index, false)
                                        // setOpenUploadBlock({
                                        //   ProofDisbursement: false,
                                        // })
                                      }
                                    >
                                      <i className="fa fa-close"></i>
                                    </span>

                                    <FileUploader
                                      key={Math.random().toString()}
                                      id={`ProofDisbursement${index}`}
                                      information="Drag & Drop the file or Browse"
                                      fileType="application/pdf"
                                      buttonLabel="Browse"
                                      multiple
                                      enablePreview
                                      IconComponent={() => (
                                        <span className="fa fa-cloud-upload"></span>
                                      )}
                                      removeIconClass=""
                                      existingFiles={
                                        []
                                        //   item.documents.proof_of_disbursement.map(
                                        //   (uploadedFile: FileUploadModelTranche) =>
                                        //     uploadedFile.file
                                        // )
                                      }
                                      onUploadFinish={(files: Array<File>) => {
                                        onFileUpload(
                                          files,
                                          UploadTypeEnum.ProofDisbursement,
                                          item,
                                          index
                                        );
                                      }}
                                      onFileDelete={(deletedFile: File) =>
                                        onFileDelete(
                                          deletedFile,
                                          UploadTypeEnum.ProofDisbursement,
                                          item
                                        )
                                      }
                                    />
                                  </>
                                )
                              )}
                            </div>
                          </div>
                          <div className="row mt-05">
                            <div className="col-md-6 left-align">
                              {/* <span className="incubator-label">
                            Supporting Documents
                          </span> */}
                              {/* <div className="row">
                            <div className="col-md-8 left-align">
                              {item.documents.supporting_documents !=
                                undefined &&
                                item.documents.supporting_documents.length !=
                                0 ? (
                                ''
                              ) : (
                                <label className="document-label-value">
                                  {' '}
                                  -
                                </label>
                              )}
                              <br />
                              {item.documents.supporting_documents !=
                                undefined &&
                                item.documents.supporting_documents.map(
                                  (fileObject, index) => (
                                    <div className="row" key={index}>
                                      <div className="col-md-4">
                                        {fileObject.ext == 'pdf' ? (
                                          <img src={pdf} alt="SISFS Logo" />
                                        ) : fileObject.ext == 'doc' ? (
                                          <img src={doc} alt="SISFS Logo" />
                                        ) : fileObject.ext == 'xls' ? (
                                          <img src={xls} alt="SISFS Logo" />
                                        ) : (
                                          <img src={Allround} alt="SISFS Logo" />
                                        )}
                                      </div>
                                      <div className="col-md-8">
                                        <span className="document-label-value">
                                          {getFileDetails(
                                            fileObject.fileName,
                                            'name'
                                          )}
                                        </span>
                                        <br />
                                        <br />
                                        <a
                                          // href={getFileDetails(
                                          //   fileObject.fileName,
                                          //   'name'
                                          // )}
                                          href={
                                            getFileDetails(
                                            fileObject.fileName,
                                            'file'
                                          )}
                                          target="_blank"
                                          // download="supporting_documents"
                                          className="incubator-details-link"
                                        >
                                          View
                                        </a>

                                      </div>
                                    </div>

                                    // })
                                  )
                                )}

                              {
                              // is_incubator_user == true &&
                              //   item.tranches_status_label == 'Incomplete'
                              //    ? (
                              //     <a
                              //       className="incubator-details-link"
                              //       // onClick={() =>
                              //       //   setOpenUploadBlock({ SupportingDocuments: true })
                              //       // }
                              //       data-target="#requestTranche"
                              //       data-toggle="modal"
                              //     >
                              //       Upload
                              //     </a>
                              //   ) : (
                              //     ''
                              //   )
                                }
                              {((openUploadBlock &&
                                openUploadBlock['SupportingDocuments'] &&
                                trancheRequest.supporting_documents.length ===
                                0) ||
                                trancheRequest.supporting_documents.length >
                                0) && (
                                  <>
                                    <span
                                      className="close"
                                      onClick={() =>
                                        setOpenUploadBlock({
                                          SupportingDocuments: false,
                                        })
                                      }
                                    >
                                      <i className="fa fa-close"></i>
                                    </span>
                                    <FileUploader
                                      key={UploadTypeEnum.SupportingDocuments}
                                      id={`supporting_documents_${index + 1}`}
                                      information="Drag & Drop the file or Browse"
                                      fileType="application/pdf"
                                      buttonLabel="Browse"
                                      multiple
                                      enablePreview
                                      IconComponent={() => (
                                        <span className="fa fa-cloud-upload"></span>
                                      )}
                                      removeIconClass=""
                                      existingFiles={trancheRequest.supporting_documents.map(
                                        (uploadedFile: FileUploadModel) =>
                                          uploadedFile.file
                                      )}
                                      onUploadFinish={(files: Array<File>) => {
                                        onFileUpload(
                                          files,
                                          UploadTypeEnum.SupportingDocuments,
                                          item,
                                          index
                                        );
                                      }}
                                      onFileDelete={(deletedFile: File) =>
                                        onFileDelete(
                                          deletedFile,
                                          UploadTypeEnum.SupportingDocuments,
                                          item
                                        )
                                      }
                                    />
                                  </>
                                )}
                            </div>
                          </div> */}
                            </div>
                            <div className="col-md-6 left-align">
                              {/* <span className="incubator-label">
                            Quarterly Report
                          </span>
                          <div className="row">
                            <div className="col-md-8 left-align">
                              {item.documents.quarterly_reports !=
                                undefined &&
                                item.documents.quarterly_reports.length != 0 ? (
                                ''
                              ) : (
                                <label className="document-label-value">
                                  {' '}
                                  -
                                </label>
                              )}
                              <br />
                              {item.documents.quarterly_reports !=
                                undefined &&
                                item.documents.quarterly_reports.map(
                                  (fileObject, index) => (
                                    <div className="row">
                                      <div className="col-md-4">
                                        {fileObject.ext == 'pdf' ? (
                                          <img src={pdf} alt="SISFS Logo" />
                                        ) : fileObject.ext == 'doc' ? (
                                          <img src={doc} alt="SISFS Logo" />
                                        ) : fileObject.ext == 'xls' ? (
                                          <img src={xls} alt="SISFS Logo" />
                                        ) : (
                                          <img src={Allround} alt="SISFS Logo" />
                                        )}
                                      </div>
                                      <div className="col-md-8">
                                        <span className="document-label-value">
                                          {getFileDetails(
                                            fileObject.fileName,
                                            'name'
                                          )}
                                          {console.log("File Object", fileObject.fileName)}
                                        </span>
                                        <br />
                                        <br />
                                        <a
                                          // href={getFileDetails(
                                          //   fileObject.fileName,
                                          //   'name'
                                          // )}
                                          href={
                                            getFileDetails(
                                            fileObject.fileName,
                                            'file'
                                          )}
                                          target="_blank"
                                          // download="quarterly_report"
                                          className="incubator-details-link"
                                        >
                                          View
                                        </a>
                                       
                                      </div>
                                    </div>

                                    // })
                                  )
                                )}

                              {
                              // is_incubator_user == true &&
                              //   item.tranches_status_label == 'Incomplete' ? (
                              //     <a
                              //       className="incubator-details-link"
                              //       // onClick={() =>
                              //       //   setOpenUploadBlock({ QuarterlyReport: true })
                              //       // }
                              //       data-target="#requestTranche"
                              //       data-toggle="modal"
                              //     >
                              //       Upload
                              //     </a>
                              //   ) : (
                              //     ''
                              //   )
                                }

                              {((openUploadBlock &&
                                openUploadBlock['QuarterlyReport'] &&
                                trancheRequest.quarterly_report.length ===
                                0) ||
                                trancheRequest.quarterly_report.length >
                                0) && (
                                  <>
                                    <span
                                      className="close"
                                      onClick={() =>
                                        setOpenUploadBlock({
                                          QuarterlyReport: false,
                                        })
                                      }
                                    >
                                      <i className="fa fa-close"></i>
                                    </span>
                                    <FileUploader
                                      key={UploadTypeEnum.QuarterlyReport}
                                      id={`quarterly_report_${index + 1}`}
                                      information="Drag & Drop the file or Browse"
                                      fileType="application/pdf"
                                      buttonLabel="Browse"
                                      multiple
                                      enablePreview
                                      IconComponent={() => (
                                        <span className="fa fa-cloud-upload"></span>
                                      )}
                                      removeIconClass=""
                                      existingFiles={trancheRequest.quarterly_report.map(
                                        (uploadedFile: FileUploadModel) =>
                                          uploadedFile.file
                                      )}
                                      onUploadFinish={(files: Array<File>) => {
                                        onFileUpload(
                                          files,
                                          UploadTypeEnum.QuarterlyReport,
                                          item,
                                          index
                                        );
                                      }}
                                      onFileDelete={(deletedFile: File) =>
                                        onFileDelete(
                                          deletedFile,
                                          UploadTypeEnum.QuarterlyReport,
                                          item
                                        )
                                      }
                                    />
                                  </>
                                )}
                            </div>
                          </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="application-incubator-hr">
                      <hr />
                    </div>
                  </div>
                ))
              : ""}
          </div>
        </div>
      </fieldset>
      {/* Add Comment Pop Up */}
      <div
        className="modal fade"
        id="addEditCommentTranche"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none">
              <h5 className="modal-title " id="exampleModalLongTitle">
                Add Tranche Comment <span className="eac-name-pop-up"></span>
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12 left">
                  <textarea
                    className="form-control"
                    name="comment"
                    value={updateTranche.tranch_comment}
                    rows={3}
                    placeholder="Enter Comment"
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 text-right">
                  <div className="btn-group mt-1-5 ">
                    <button
                      className="tertiary   btn-transparent-primary"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>

                    <button
                      type="button"
                      className="btn btn-sisfs btn-yellow-primary ml-2"
                      onClick={() => updateTrancheCommentByStatus()}
                      data-dismiss="modal"
                    >
                      Add Comment{" "}
                    </button>
                  </div>
                </div>
              </div>

              <div className="row pt-3 mb-3">
                <div className="col-md-12 centerText"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Amount Pop Up*/}
      <div
        className="modal fade"
        id="editTranche"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none">
              <h5 className="modal-title " id="exampleModalLongTitle">
                {editName} <span className="eac-name-pop-up"></span>
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {nameProp == "date_of_release" ||
              nameProp == "tranche_requested_date" ? (
                <div>
                  <div className="row">
                    <div className="col-md-12 text-left">
                      <span className="col-form-head">Select Date</span>
                    </div>
                  </div>
                  <div className="row mt-1-5">
                    <div className="col-md-12 text-left">
                      <Calendar
                        id="date_of_release"
                        name={
                          nameProp == "date_of_release" ? "date_of_release" : ""
                        }
                        className="form-control input-height"
                        format="dd/MM/yyyy"
                        selectedDate={
                          updateTranche.date_of_release != null
                            ? updateTranche.date_of_release
                            : new Date()
                        }
                        onDateChange={(date: Date, field: string) =>
                          handleDateChange(date, field)
                        }
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-md-12 center">
                    {nameProp == "funds_requested" ? (
                      <input
                        type="number"
                        className="form-control"
                        name={nameProp}
                        value={updateTranche.funds_requested}
                        placeholder={editName}
                        onChange={handleChange}
                      ></input>
                    ) : (
                      ""
                    )}

                    {nameProp == "approved_amount" ||
                    nameProp == "fund_released" ? (
                      <input
                        type="number"
                        className="form-control"
                        name={nameProp}
                        value={
                          nameProp == "approved_amount"
                            ? updateTranche.approved_amount
                            : nameProp == "fund_released"
                            ? updateTranche.fund_released
                            : updateTranche.tranch_comment
                        }
                        placeholder={editName}
                        onChange={handleChange}
                      ></input>
                    ) : nameProp == "tranch_comment" ? (
                      <textarea
                        className="form-control"
                        name={nameProp}
                        value={updateTranche.tranch_comment}
                        rows={4}
                        placeholder={editName}
                        onChange={handleChange}
                      ></textarea>
                    ) : (
                      ""
                    )}
                    {nameProp == "cash_in_hand" ? (
                      <div>
                        <label className="col-form-head">
                          Is your Cash-in -Hand less than 10% of the total
                          commitment by EAC(including the interest accumulated
                          and net returns)?
                        </label>
                        <div className="toggle-btn-group">
                          <div className="btn-group btn-group-toggle ">
                            <label
                              className={
                                trancheRequest.cash_in_hand === "1"
                                  ? "btn active"
                                  : "btn"
                              }
                            >
                              <input
                                type="radio"
                                name="cash_in_hand"
                                id="cash_in_hand_1"
                                value="1"
                                onChange={handleChangeTrancheRequest}
                                checked={trancheRequest.cash_in_hand === "1"}
                              />
                              Yes
                            </label>
                            <label
                              className={
                                trancheRequest.cash_in_hand === "0"
                                  ? "btn active"
                                  : "btn"
                              }
                            >
                              <input
                                type="radio"
                                name="cash_in_hand"
                                id="cash_in_hand_2"
                                value="0"
                                onChange={handleChangeTrancheRequest}
                                checked={trancheRequest.cash_in_hand === "0"}
                              />
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
              {showLimitMessage == true &&
              updateTranche.approved_amount > tranche1_approved_limit &&
              (loggedInRoleName == "incubator" ||
                loggedInRoleName == "startup") ? (
                <div className="row">
                  <div className="col-md-12 text-left">
                    <span className="error-message">
                      You cannot approve more than total funds approved
                      amount(that is more than {tranche1_approved_limit}).
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="row">
                <div className="col-md-12 text-right">
                  <div className="btn-group mt-1-5 ">
                    <button
                      className="tertiary   btn-transparent-primary"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>

                    {showLimitMessage == true &&
                    updateTranche.approved_amount > tranche1_approved_limit &&
                    (loggedInRoleName == "incubator" ||
                      loggedInRoleName == "startup") ? (
                      <button
                        type="button"
                        disabled
                        className="btn btn-sisfs btn-yellow-primary ml-2"
                        onClick={() =>
                          nameProp == "tranch_comment"
                            ? updateTrancheCommentByStatus()
                            : updateTrancheDetails(nameProp)
                        }
                        data-dismiss="modal"
                      >
                        Update{" "}
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-sisfs btn-yellow-primary ml-2"
                        onClick={() =>
                          nameProp == "tranch_comment"
                            ? updateTrancheCommentByStatus()
                            : updateTrancheDetails(nameProp)
                        }

                        data-dismiss="modal"
                      >
                        Update{" "}
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className="row pt-3 mb-3">
                <div className="col-md-12 centerText"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Status Pop Up*/}
      <div
        className="modal fade"
        id="editTrancheStatus"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none">
              <h5 className="modal-title " id="exampleModalLongTitle">
                Update Tranches Status <span className="eac-name-pop-up"></span>
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12 text-left">
                  <span className="col-form-head">Tranche Status</span>
                </div>
              </div>
              <div className="row mt-1-5">
                <div className="col-md-12 text-left">
                  <div className="select-custom">
                    <select
                      className="form-control input-height"
                      aria-label="Select Status"
                      id="tranche_status_id"
                      name="tranche_status_id"
                      value={updateTranche.tranche_status_id}
                      onChange={handleChange}
                    >
                      {applicationStatus.map((item) =>
                        updateTranche.tranche_status_id == 1
                          ? item.label != "Requested" && (
                              <option key={item.label} value={item.value}>
                                {item.label}
                              </option>
                            )
                          : item.label != "Requested" &&
                            item.value != 1 && (
                              <option key={item.label} value={item.value}>
                                {item.label}
                              </option>
                            )
                      )}
                    </select>
                  </div>
                </div>
              </div>

              <div className="row  mt-1-5">
                <div className="col-md-12 text-left">
                  <span className="col-form-head">Tranche Comment</span>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <textarea
                    className="form-control"
                    name="tranch_comment"
                    value={updateTranche.tranch_comment}
                    rows={4}
                    placeholder="Type Comment"
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-right">
                  <div className="btn-group mt-1-5 ">
                    <button
                      className="tertiary   btn-transparent-primary"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>

                    <button
                      type="button"
                      className="btn btn-sisfs btn-yellow-primary ml-2"
                      onClick={() =>
                        updateTranche.tranche_status_id == 6 &&
                        (dateRelease == null ||
                          fundDisbursed == null ||
                          fundApproved == null)
                          ? setdateEmptyPopup(true)
                          : updateTrancheDetails("tranche_status_id")
                      }
                      data-dismiss="modal"
                    >
                      Update{" "}
                    </button>
                  </div>
                </div>
              </div>

              <div className="row pt-3 mb-3">
                <div className="col-md-12 centerText"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {dateEmptyPopup && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionError"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  {/* <i className="fa fa-times-circle-o"></i> */}
                  <h3 className="modal-message message-orange">Oops!</h3>
                  <br />
                  <h4 className="modal-message message-orange">
                    Please provide Approved amount, Disbursed amount and Date of
                    Disbursal
                  </h4>
                  <div className="modal-action">
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={dismissModal}
                    >
                      OK !
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {disbursedDate && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionError"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  {/* <i className="fa fa-times-circle-o"></i> */}
                  <h3 className="modal-message message-orange">Oops!</h3>
                  <br />
                  <h4 className="modal-message message-orange">
                    Date should not be empty.
                  </h4>
                  <div className="modal-action">
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={dismissModal}
                    >
                      OK !
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {validation && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionError"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  {/* <i className="fa fa-times-circle-o"></i> */}
                  <h3 className="modal-message message-orange">Oops!</h3>
                  <br />
                  <h4 className="modal-message message-orange">
                    Amount should be greater than 0
                  </h4>
                  <div className="modal-action">
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={dismissModal}
                    >
                      OK !
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {disbursedAmountPopup && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionError"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  {/* <i className="fa fa-times-circle-o"></i> */}
                  <h3 className="modal-message">Oops!</h3>
                  <br />
                  <h6 className="message-orange" style={{ lineHeight: "1.5" }}>
                    You can't change the status of the tranche as total amount
                    disbursed to the incubator cannot be less than the total
                    amount disbursed to the startups.
                  </h6>
                  <div className="modal-action">
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={dismissModal}
                    >
                      OK !
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showEditSuccessModal && (
        <>
          <div className="modal-backdrop fade show"></div>

          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionSuccess"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <i className="fa fa-check-circle-o"></i>
                  <h3 className="modal-message">Thank You !</h3>
                  <div className="modal-text">
                    Tranche Details Updated Successfully !!
                  </div>
                  <div className="modal-action">
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={dismissModal}
                    >
                      Ok !!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {showValidationFailureModal && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionError"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <h4 className="modal-message message-orange">
                    Entered Amount should be less than or equal to Approved
                    Amount
                  </h4>
                  <div className="modal-action">
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={dismissModal}
                    >
                      OK !
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showValidationFailureModal1 && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionError"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <h4 className="modal-message message-orange">
                    Entered Amount is less than Fund Released Amount
                  </h4>
                  <div className="modal-action">
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={dismissModal}
                    >
                      OK !
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showfundvalidation && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionError"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <h4 className="modal-message message-orange">
                    Entered Amount should be lesser than Total Funds Approved
                  </h4>
                  <div className="modal-action">
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={dismissModal}
                    >
                      OK !
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showEditFailureModal && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionError"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  {/* <i className="fa fa-times-circle-o"></i> */}
                  <h3 className="modal-message message-orange">
                    Oops! Something is Wrong
                  </h3>
                  <br />
                  <h3 className="modal-message message-orange">
                    Please Try Again !!!
                  </h3>
                  <div className="modal-action">
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={dismissModal}
                    >
                      OK !
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* setUploadMsg({ result: false, message: '' }) */}
      {!uploadMsg.result && uploadMsg.message && (
        <ConfirmationDialog
          headerText={"Failed"}
          bodyText={uploadMsg.message}
          onDialogClose={() => setUploadMsg({ result: false, message: "" })}
          iconClass="test"
        />
      )}

      {uploadMsg.result && uploadMsg.message && (
        <ConfirmationDialog
          headerText="Success"
          bodyText={uploadMsg.message}
          onDialogClose={() => setUploadMsg({ result: false, message: "" })}
        />
      )}
      {showIncubatorFailureModal && (
        <ConfirmationDialogNote
          headerText={failDialogData.header}
          bodyText={failDialogData.text}
          bodyNote={failDialogData.note}
          onDialogClose={() => setShowIncubatorFailureModal(false)}
          iconClass="showImage"
        />
      )}
    </div>
  );
}

export default TrancheWiseDetails;
