import React, { useEffect, useRef, useState } from 'react'
import fileupload from '../../images/fileupload.svg';
import error from '../../images/error.svg';
import MultiSelect from '../common/controls/MultiSelect';
import { DropdownModel } from '../../models/DropdownModel';
import { post } from '../../effects/api/api.common';
import { DELETE_NOTIFICATIONS, GET_NOTIFICATIONS, NODE_URL, POST_NOTIFICATIONS, UPDATE_NOTIFICATIONS } from '../../constants/api';
import { getSessionStorageValue } from '../../effects/utils/session-storage';
import moment from 'moment';
import edit from '../../images/edit.svg';
import remove from '../../images/ReturnsIcons/trash-img.svg'
import newIcon from '../../images/New.png'
const categories = [
  { value: 2, label: 'Incubators' },
  { value: 1, label: 'Startups' },
  { value: 5, label: 'DPIIT' },
  { value: 4, label: 'EAC' }
]

const notice_data = [
  {
    TitleHead: "Minutes of Experts Advisory Committee Meetings",
    Category: "Minutes"
  },
  {
    TitleHead: "Quarterly Performance Review",
    Category: "Quarterly"
  },
  {
    TitleHead: "Advisories",
    Category: "Advisories"
  },
  {
    TitleHead: "Others",
    Category: "Others"
  }
];
const generateVisibilityString = (visibility: any) => {
  return visibility
    .map((item: any) =>
      item == 1
        ? '<span style="cursor: pointer;" title="Startups">S</span>'
        : item == 2
          ? '<span style="cursor: pointer;" title="Incubators">I</span>'
          : item == 4
            ? '<span style="cursor: pointer;" title="EAC">E</span>'
            : item == 5
              ? '<span style="cursor: pointer;" title="DPIIT">D</span>'
              : ''
    )
    .filter((item: any) => item)
    .join(', ');
};
const NoticeBoard = () => {

  const [getfileUpload, setfileUpload] = useState<any>()
  const [getFilePath, setFilePath] = useState()
  const [exceedFileSize, setExceedFileSize] = useState(false);
  const [showDeletePopup, setDeletePopup] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState<any>([]);
  const [selectedRoleeValue, setRoleValue] = useState<any>([]);
  const [getNotificationsData, setNotificationsData] = useState<any>();
  const [noNotifications, setNoNotifications] = useState(false);
  const [renderIssue, setRenderIssue] = useState(false)
  const [getUpdatedData, setUpdatedData] = useState({
    Category: "",
    Date: "",
    Title: "",
    creator_name: ""
  });
  const [checkField, setCheckField] = useState(false);
  const user: any = getSessionStorageValue("user");
  const userInfo = JSON.parse(user);
  const userRoles = userInfo.roles == "startup" ? 1 :
    userInfo.roles == "incubator" ? 2 :
      userInfo.roles == "secretariat" ? 3 :
        userInfo.roles == "eac" ? 4 : 5
  const [logged_user_role] = useState(userRoles)
  const [logged_user_id] = useState(userInfo.id);
  const [getNoticeId, setNoticeId] = useState(0)
  const updateRef = useRef(0);
  const [showSuccessmsg, setSuccessMsg] = useState('')
  const [getDeleteRecord, setDeleteRecord] = useState({
    id: ''
  })
  useEffect(() => {
    const roles = categories.map((item: any): DropdownModel => {
      return {
        text: item.label,
        id: item.value.toString(),
        translation_text: "",
      };
    });
    setSelectedCategories(roles)
    getNoticeData();
  }, [])

  const uploadNotice = () => {
    setUpdatedData({
      Category: "",
      Date: "",
      Title: "",
      creator_name: ""
    });
    setfileUpload(null);
    setRoleValue([]);
    setFilePath(undefined);
    updateRef.current = 1;
  }
  const getNoticeData = () => {
    const payload = {
      "logged_user_role": logged_user_role.toString()
    }
    post(GET_NOTIFICATIONS, payload).then((response: any) => {
      if (!response || !response.data.data) return;

      if (
        (response.data.data.length == 0) ||
        (response.data.data[0].Advisories.length == 0 &&
          response.data.data[0].Minutes.length == 0 &&
          response.data.data[0].Others.length == 0 &&
          response.data.data[0].Quarterly.length == 0)
      ) {
        setNoNotifications(true);
      } else {
        setNoNotifications(false);
      }
      const mergedData = notice_data.map((category: any) => {
        return { notices: response.data.data[0][category.Category], categoryName: category.TitleHead }
      });
      setRenderIssue(!renderIssue);
      setNotificationsData(mergedData);
    })

  }
  const getFileDetails = (file: any, key: string) => {
    let file_name = '';
    file_name =
      file != undefined && file != null && file.length > 1
        ? file.substr(file.lastIndexOf('/') + 1, file.length)
        : '';
    if (key == 'name') return file_name;
    if (key == 'file') return file != undefined ? file.toString() : '';
  };
  const onMultiSelectChange = (selectedIds: Array<string>) => {
    setRoleValue(selectedIds);
  };
  const handleChange = (evt: any) => {
    const name = evt.target.name;
    const value = evt.target.value;
    if (name == "file_path") {
      const file = evt.target.files[0];
      if (file.size > 5 * 1024 * 1024) {
        setExceedFileSize(true)
        return;
      } else {
        setFilePath(undefined);
        setfileUpload(file)
      }
    }
    setUpdatedData((prevState: any) => ({
      ...prevState,
      [name]: value
    }));

  }
  const dismissModal = () => {
    setExceedFileSize(false);
    setDeletePopup(false);
  }
  const submit = () => {


    if (
      getUpdatedData.Category == "" ||
      getUpdatedData.Title == "" ||
      getUpdatedData.Title.length > 200 ||
      (updateRef.current == 1 && getfileUpload == null) ||
      selectedRoleeValue.length == 0
    ) {
      setCheckField(true);
      return;
    }
    const formData = new FormData();
    formData.append('Category', getUpdatedData.Category);

    const currentDate = new Date();
    const formatDate = moment(currentDate).format("YYYY-MM-DD")
    formData.append('Date', formatDate);
    formData.append('Title', getUpdatedData.Title);
    let sec_role = ["3"]
    console.log(selectedRoleeValue)
    if (!selectedRoleeValue.includes("3")) {
      selectedRoleeValue && selectedRoleeValue.push("3")
    }
    // let concatArr = selectedRoleeValue.concat(sec_role)
    // console.log(concatArr)
    // let removeDuplicates = concatArr.filter((item: any, index: any, self: any) => self.indexOf(item) === index);
    formData.append('visibility', JSON.stringify(selectedRoleeValue));
    formData.append('logged_user_id', logged_user_id);
    formData.append('file_path', getfileUpload);
    if (updateRef.current == 2) {
      formData.append('id', getNoticeId != undefined ? JSON.stringify(getNoticeId) : '')
    }

    // return;
    const callAPI = updateRef.current == 1 ? POST_NOTIFICATIONS : UPDATE_NOTIFICATIONS;
    post(callAPI, formData).then((response: any) => {
      if (!response || !response.data) return;
      setCheckField(false)
      if (response.data.result) {
        let showMessage = updateRef.current == 1 ?
          'New Notification added successfully!' : 'Notification updated successfully!'
        setSuccessMsg(showMessage);
      }
      getNoticeData();
    })

  }
  const editOrDelete = (item: any, id: string) => {

    updateRef.current = 2;
    const str = item.Category.split(' ')[0];
    setUpdatedData({
      Category: item.Category,
      Date: new Date(item.Date).toISOString().split('T')[0],
      Title: item.Title,
      creator_name: item.creator_name
    });
    setFilePath(item.file_path);
    setRoleValue(item.visibility);
    setNoticeId(item.id)
    if (id === '2') {
      setDeletePopup(true)
      setDeleteRecord({
        id: item.id
      })
    }

  }
  const deleteRecord = () => {

    // return;
    const payload = {
      id: getDeleteRecord.id
    }
    post(DELETE_NOTIFICATIONS, payload).then((response: any) => {
      if (!response || !response.data) return;
      setSuccessMsg('Record deleted successfully!');
      getNoticeData();
    })

  }
  const [activeIndexApp, setActiveIndexApp] = useState('');
  const changeActiveApp = (id: string) => {
    setActiveIndexApp(id);
  };

  const changeActiveBackApp = () => {
    setActiveIndexApp('');
  };
  return (
    <>

      <div className="row container-fluid">
        <div className="col-md col-sm-1"></div>
        <div className="col-md-8 col-sm">
          <div className='text-center p-3'>
            <h3 className='dashboard' style={{ textTransform: 'uppercase' }}>
              Notifications
            </h3>
          </div>
          {logged_user_role == 3 && <div className='text-right'>
            <button
              data-toggle="modal"
              data-target="#UpdateNotification"
              className="btn btn-sisfs btn-yellow-primary"
              onClick={uploadNotice}
            >
              Upload new notification
            </button>
          </div>}
          <br />
          {
            noNotifications ?
              <div style={{ minHeight: logged_user_role != 3 ? '55vh' : '50vh' }}>

                <h6 className='text-center mt-5'>No new notifications!</h6>
              </div> :
              getNotificationsData && getNotificationsData.map((item: any, index: any) => (
                <>
                  <div
                    className="accordion accordion-details-wrap text-left"

                    id="accordion-application-details">
                    <div>
                      <div
                        className="col-md-12 left-align"
                        style={{ padding: '8px 15px', backgroundColor: 'lightgrey', borderRadius: '25px' }}>
                        <span className="eac-heading-title" style={{ fontSize: '15px' }}>
                          {item.notices.length == 0 ? item.categoryName : item.notices[0].Category}
                        </span>
                        {
                          item.notices.length > 3 &&
                          <span>
                            {activeIndexApp == index + 1 ? (
                              <div
                                onClick={changeActiveBackApp}
                                data-toggle="collapse"
                                data-target={`#${index + 1}comments-expand`}
                                role="button"
                                aria-expanded="false"
                                aria-controls={`${index + 1}comments-expand`}
                                style={{ fontSize: '24px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}
                                className="hide-icon pull-right hide-icon-details mr-4"
                                id={`show-${index + 1}`}>
                                <span className='m-0'>&minus;</span>
                                {/* <img src={minus} className="img-fluid" alt="SISFS Logo" /> */}
                              </div>
                            ) : (
                              <div
                                onClick={() => changeActiveApp(index + 1)}
                                id={`hide-${index + 1}`}
                                data-toggle="collapse"
                                data-target={`#${index + 1}comments-expand`}
                                role="button"
                                aria-expanded="false"
                                aria-controls={`${index + 1}comments-expand`}
                                style={{ fontSize: '24px', display: 'flex', alignItems: 'center' }}
                                className="expand-icon pull-right expand-icon-details mr-4">
                                &#128931;
                                {/* <img src={plus} className="img-fluid" alt="SISFS Logo" /> */}
                              </div>
                            )}
                          </span>
                        }
                      </div>
                    </div>
                    <div className="card accordion-card" style={{ borderBottom: '0' }}>
                      <div className="card-body p-0" >
                        <ul className='pl-0 mb-0'>
                          {
                            item.notices.length == 0 ?
                              <h6 className='text-center mt-3'>No new notifications!</h6> :
                              item.notices.map((data: any, index: any) => (
                                <>
                                  {
                                    index <= 2 &&
                                    <li className='p-3 d-flex align-items-start justify-content-between'>
                                      <span style={{ fontSize: '14px', fontWeight: '550', display: 'flex', alignItems: 'start' }}>
                                        <span className='mr-3' style={{ fontSize: '22px', fontWeight: '900',marginTop:'-5px' }}>&#11162;</span>
                                        <span>
                                          <span>{moment(data.Date).format("DD/MM/YYYY")}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                          <a target='_blank' href={`${NODE_URL + '/' + data.file_path}`} style={{ color: 'blue' }} >{data.Title}</a>
                                          {data.date_diff <= 10 && <img src={newIcon} width="30px" alt="" className='ml-3 newIcon' />}
                                        </span>
                                      </span>
                                      <span>

                                        {logged_user_role == 3 &&
                                          <span className='d-flex'>

                                            {/* <span style={{fontWeight:'bold',marginRight:'20px'}}>
                                          {
                                            `(
                                            ${data.visibility.map((item:any)=>
                                              item == 1 ? "S" :
                                                  item == 2 ? "I" :
                                                  item == 4 ? "E" :
                                                  item == 5 ? "D" : ""
                                            ).filter((item:any) => item)
                                            .join(", ")}
                                            )`
                                          }
                                         </span> */}
                                            <span
                                              style={{ fontWeight: 'bold', marginRight: '20px',display:'flex' }}
                                              dangerouslySetInnerHTML={{
                                                __html: `(${generateVisibilityString(data.visibility)})`,
                                              }}
                                            />
                                            <span>
                                              <img
                                                src={edit}
                                                alt="edit"
                                                style={{ cursor: 'pointer', borderRight: '1px solid grey', paddingRight: '10px' }}
                                                data-toggle="modal"
                                                data-target="#UpdateNotification"
                                                onClick={() => { editOrDelete(data, '1') }}
                                              />
                                            </span>&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span>
                                              <img
                                                src={remove}
                                                width="17px"
                                                alt="remove"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => { editOrDelete(data, '2') }}
                                              />
                                            </span>
                                          </span>
                                        }
                                      </span>
                                    </li>
                                  }

                                </>
                              ))
                          }
                        </ul>
                      </div>
                    </div>
                    {activeIndexApp == index + 1 ? (
                      <div className="collapse " id={`${index + 1}comments-expand`}>

                        <div className="card accordion-card" style={{ borderBottom: '0' }}>
                          <div className="card-body p-0" >
                            <ul className='pl-0 mb-0'>
                              {
                                item.notices.map((data: any, index: any) => (
                                  <>
                                    {
                                      index > 2 &&

                                      <li className='p-3 d-flex align-items-center justify-content-between'>
                                        <span style={{ fontSize: '14px', fontWeight: '550', display: 'flex', alignItems: 'baseline' }}>

                                          <span className='mr-3' style={{ fontSize: '22px', fontWeight: '900' }}>&#11162;</span>
                                          <span>
                                            <span>{moment(data.Date).format("DD/MM/YYYY")}</span>&nbsp;&nbsp;&nbsp;&nbsp;

                                            <a target='_blank' href={`${NODE_URL + '/' + data.file_path}`} style={{ color: 'blue' }} >{data.Title}</a>
                                          </span>

                                          {data.date_diff < 2 && <img src={newIcon} width="30px" alt="" className='ml-3 newIcon' />}
                                        </span>
                                        {logged_user_role == 3 &&
                                          <span className='d-flex'>
                                            {/* <span style={{ fontWeight: 'bold', marginRight: '20px' }}>
                                              {
                                                `(
                                            ${data.visibility.map((item: any) =>
                                                  item == 1 ? "S" :
                                                    item == 2 ? "I" :
                                                      item == 4 ? "E" :
                                                        item == 5 ? "D" : ""
                                                ).filter((item: any) => item)
                                                  .join(", ")}
                                            )`
                                              }
                                            </span> */}
                                            <span
                                              style={{ fontWeight: 'bold', marginRight: '20px',display:'flex' }}
                                              dangerouslySetInnerHTML={{
                                                __html: `(${generateVisibilityString(data.visibility)})`,
                                              }}
                                            />
                                            <span>
                                              <img
                                                src={edit}
                                                alt="edit"
                                                style={{ cursor: 'pointer', borderRight: '1px solid grey', paddingRight: '10px' }}
                                                data-toggle="modal"
                                                data-target="#UpdateNotification"
                                                onClick={() => { editOrDelete(data, '1') }}
                                              />
                                            </span>&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span>
                                              <img
                                                src={remove}
                                                alt="remove"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => { editOrDelete(data, '2') }}
                                              />
                                            </span>
                                          </span>
                                        }
                                      </li>
                                    }

                                  </>
                                ))
                              }
                            </ul>
                          </div>
                        </div>


                      </div>
                    ) : (
                      ''
                    )}
                    <hr />
                  </div>
                </>
              ))
          }

        </div>
        <div className="col-md col-sm-1"></div>
      </div>

      <div
        className="modal fade"
        id="UpdateNotification"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className={`modal-dialog modal-dialog-centered ${showSuccessmsg ? 'modal-md' : 'modal-lg'} `}
          role="document"
        >
          <div className="modal-content">
            {!showSuccessmsg && <div className="modal-header modal-header-border-none py-0" style={{ backgroundColor: '#25429C' }}>

              <h5
                className="modal-title pl-2 m-auto text-white"
                id="exampleModalLongTitle"
                style={{ color: '#25429c' }}
              >
                {updateRef.current == 2 ? 'Update Notification' : 'Upload New Notification'}
              </h5>
              <button
                type="button"
                className="close m-0"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" className='text-white' onClick={() => setCheckField(false)} >&times;</span>
              </button>
            </div>}
            <div className="modal-body" style={{ backgroundColor: '#D2EAFF70' }}>

              {
                showSuccessmsg ?

                  <div className='text-center'>
                    <br />
                    <div className='text-center mb-3'><i className='fa fa-check-circle-o' style={{ fontSize: '36px', color: 'green' }}></i></div>
                    <h4 className='text-center' style={{ color: '#2b408b', fontWeight: '600' }} >{showSuccessmsg}</h4>
                    <br />
                    <div className='d-flex align-items-center justify-content-center'>
                      <div className="col-md-4 text-center">
                        <button className="btn btn-sisfs btn-yellow-primary" style={{ padding: '15px' }}
                          data-dismiss="modal" onClick={() => setSuccessMsg('')}
                        >
                          OK!
                        </button>
                      </div>
                    </div>
                    <br />
                  </div>
                  :
                  <>
                    <div className="row justify-content-center mt-3">
                      <div className="col-sm-12">
                        <div className="form-wrapper contact-form">
                          {updateRef.current == 2 &&
                            <div className="form-group row">
                              <div className="col-md-1"></div>
                              <label className="col-sm-12 col-md-3 col-form-label text-left" style={{ color: '#25429C' }}>
                                Uploaded by:
                              </label>
                              <div className="col-sm-12 col-md-7">
                                <input
                                  type="text"
                                  name='uploadedBy'
                                  className='form-control'
                                  disabled
                                  value={getUpdatedData.creator_name}
                                  onChange={handleChange}
                                />
                              </div>
                              {getUpdatedData.Date == "" && checkField && <div className="col-11 text-right text-danger pr-4">
                                <i>Please enter date</i>
                              </div>}
                            </div>
                          }
                          <div className="form-group row">
                            <div className="col-md-1"></div>
                            <label className="col-sm-12 col-md-3 col-form-label text-left" style={{ color: '#25429C' }}>
                              Category <span className='text-danger'>*</span>
                            </label>
                            <div className="col-sm-12 col-md-7">
                              <select
                                name="Category"
                                className='form-control'
                                id="Category"
                                value={getUpdatedData.Category}
                                onChange={handleChange}
                              >
                                <option value="">Select Category</option>
                                {
                                  notice_data.map((response: any) => (
                                    <option value={response.TitleHead}>{response.TitleHead}</option>
                                  ))
                                }
                              </select>
                            </div>
                            {getUpdatedData.Category == "" && checkField && <div className="col-11 text-right text-danger pr-4">
                              <i>Please select category</i>
                            </div>}
                          </div>
                          {
                            updateRef.current == 1 ? "" :

                              <div className="form-group row">
                                <div className="col-md-1"></div>
                                <label className="col-sm-12 col-md-3 col-form-label text-left" style={{ color: '#25429C' }}>
                                  Date<span className='text-danger'>*</span>
                                </label>
                                <div className="col-sm-12 col-md-7">
                                  <input
                                    type="date"
                                    name='Date'
                                    className='form-control'
                                    placeholder='Enter Date'
                                    disabled
                                    value={new Date().toISOString().split('T')[0]}
                                    onChange={handleChange}
                                  />
                                </div>
                                {getUpdatedData.Date == "" && checkField && <div className="col-11 text-right text-danger pr-4">
                                  <i>Please enter date</i>
                                </div>}
                              </div>
                          }

                          <div className="form-group row">
                            <div className="col-md-1"></div>
                            <label className="col-sm-12 col-md-3 col-form-label text-left" style={{ color: '#25429C' }}>
                              Title Text<span className='text-danger'>*</span>
                            </label>
                            <div className="col-sm-12 col-md-7">
                              <textarea
                                className='form-control'
                                placeholder='Enter Title'
                                name="Title"
                                id="Title"
                                value={getUpdatedData.Title}
                                onChange={handleChange}
                              >

                              </textarea>

                            </div>
                            {getUpdatedData.Title == "" && checkField ? <div className="col-11 text-right text-danger pr-4">
                              <i>Please enter title of notification,Max. characters - 200 only</i>
                            </div> :
                              getUpdatedData.Title.length > 200 && checkField ?
                                <div className="col-11 text-right text-danger pr-4">
                                  <i>Max. characters - 200 only</i>
                                </div> :
                                <div className="col-11 text-right pr-4">
                                  <i>Max. characters - 200 only</i>
                                </div>}
                          </div>
                          <div className="form-group row">
                            <div className="col-md-1"></div>
                            <label className="col-sm-12 col-md-3 col-form-label text-left" style={{ color: '#25429C' }}>
                              PDF File Upload<span className='text-danger'>*</span>
                            </label>
                            <div className="col-sm-12 col-md-7 d-flex">
                              <input type="file" id="file_path" hidden accept=".pdf" className="form-control" name="file_path" onChange={(e) => handleChange(e)} />
                              <label htmlFor="file_path" style={{ marginBottom: '0px', }}>
                                <img src={fileupload} className="form-control" alt="file_path" style={{ cursor: 'pointer', height: '100%', minWidth: '50px' }} /></label>


                              {
                                getFilePath ?
                                  <>
                                    <span className="form-control">
                                      {getFileDetails(getFilePath, 'name').length > 18 ?
                                        getFileDetails(getFilePath, 'name').slice(0, 18) + "...pdf" :
                                        getFileDetails(getFilePath, 'name')
                                      }
                                    </span>

                                  </> :

                                  <span className="form-control">{getfileUpload != undefined &&
                                    getfileUpload.name.length > 15 ? getfileUpload.name.slice(0, 15) + "...pdf" : getfileUpload != undefined && getfileUpload.name

                                  }</span>
                              }

                            </div>
                            {(updateRef.current == 1 && getfileUpload == null && checkField) ?
                              <div className="col-11 text-right text-danger pr-4">
                                <i>Please upload a file, max.size - 5MB</i>
                              </div> :
                              <div className="col-11 text-right pr-4">
                                <i>File - PDF, max.size - 5MB</i>
                              </div>
                            }
                          </div>

                          <div className="form-group row">
                            <div className="col-md-1"></div>
                            <label className="col-sm-12 col-md-3 col-form-label text-left" style={{ color: '#25429C' }}>
                              Visible To<span className='text-danger'>*</span>
                            </label>
                            <div className="col-sm-12 col-md-7">

                              <MultiSelect
                                id="roles"
                                options={selectedCategories}
                                defaultText="Select who can visible"
                                onClose={(selectedIds: string[]) =>
                                  onMultiSelectChange(selectedIds)
                                }
                                selectedValues={selectedRoleeValue ? selectedRoleeValue : []}
                                hasError
                                errorText=""
                              />
                            </div>
                            {selectedRoleeValue.length == 0 && checkField && <div className="col-11 text-right text-danger pr-4">
                              <i>Please select atleast one user role to visit this notification</i>
                            </div>}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8"></div>
                      <div className="col-md-4">
                        <div className="text-center">
                          <button
                            className="btn btn-sisfs btn-yellow-primary"
                            onClick={submit}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
              }

            </div>
          </div>
        </div>
      </div>
      {
        exceedFileSize && (
          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionError"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <img
                    src={error}
                    className="search mb-2"
                    alt="search"
                    style={{ width: '2rem' }}
                  />
                  <h4 className="message-orange" style={{ fontFamily: "Roboto Slab" }}>
                    Oops!!!
                  </h4>
                  <h5 className="message-orange" style={{ fontFamily: "Roboto Slab" }}>
                    File size should not exceed 5Mb<br /><br />
                    Please upload again.
                  </h5>
                  <div className="modal-action">
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={dismissModal}
                    >
                      OK !
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      {
        showDeletePopup && (
          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionError"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center" >
                  {
                    showSuccessmsg ?
                      <div className='text-center'>
                        <br />
                        <div className='text-center mb-3'><i className='fa fa-check-circle-o' style={{ fontSize: '36px', color: 'green' }}></i></div>
                        <h4 className='text-center' style={{ color: '#2b408b', fontWeight: '600' }} >{showSuccessmsg}</h4>
                        <br />
                        <div className='d-flex align-items-center justify-content-center'>
                          <div className="col-md-4 text-center">
                            <button className="btn btn-sisfs btn-yellow-primary" style={{ padding: '15px' }}
                              data-dismiss="modal" onClick={() => {
                                setSuccessMsg('')
                                setDeletePopup(false)
                              }}
                            >
                              OK!
                            </button>
                          </div>
                        </div>
                        <br />
                      </div> :
                      <>

                        <h4 className="message-orange" style={{ fontFamily: "Roboto Slab" }}>
                          Are you sure you want to delete the notification?
                        </h4>
                        <div className="modal-action">
                          <button
                            className="btn btn-sisfs mr-2"
                            style={{ color: '#2b3f88', border: "1px solid #2b3f88" }}
                            onClick={dismissModal}
                          >
                            no
                          </button>
                          <button
                            className="btn btn-sisfs btn-yellow-primary ml-2"
                            onClick={deleteRecord}
                          >
                            yes
                          </button>
                        </div>
                      </>
                  }
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  )
}

export default NoticeBoard