import * as React from "react";
import "../../../styles/component.css";
import {
  GET_MEETINGS,
  GET_ALL_INCUBATORS,
  REMOVE_INCUBATOR,
  REMOVE_INCUBATOR_ADD_NODE,
  GET_STARTUP_MEETINGS,
  STARTUP_MEETING_GET_BY_ID,
  STARTUP_MEETING_UPDATE_BY_ID,
  GET_ALL_STARTUPS,
  STARTUP_SEARCH,
  STARTUP_DOCUMENT_UPLOAD,
  STARTUP_DOCUMENT_REMOVE,
  REVIEW_UPDATE,
  File_node_url,
  NODE_URL,
} from "../../../constants/api";
import { get, post } from "../../../effects/api/api.common";
import { useEffect, useState } from "react";
import Calendar from "../../common/controls/Calendar";
import { Link, useHistory } from "react-router-dom";
import CalendarIcon from "../../../images/calendar.svg";
import download from "../../../images/download.svg";
import search from "../../../images/search.svg";
import { compareTime, FormatDate } from "../../../utils/DateFormat";
import { formatTime } from "../../../utils/DateFormat";
import {
  downloadDocument,
  filterByValue,
  showEllipsisText,
} from "../../../utils/CommonMethods";

import edit from "../../../images/edit.svg";
import FileUploader from "@amodv/react-file-uploader";
import ConfirmationDialog from "../../common/controls/ConfirmationDialog";
import moment from "moment";
import { getSessionStorageValue } from "../../../effects/utils/session-storage";
declare var $: any;
function IncubatorMeetingComponent() {
  const history = useHistory();
  const [upcomingMeetingData, setupcomingMeetingData] = useState([]);
  const [filteredUpcomingMeetingData, setFilteredUpcomingMeetingData] =
    useState([]);
  const [pastMeetingData, setPastMeetingData] = useState([]);
  const [loader, setLoader] = useState(false)
  const [filteredPastMeetingData, setFilteredPastMeetingData] = useState([]);
  const [startupUpcomingMeetingData, setStartupUpcomingMeetingData] = useState(
    []
  );

  // {
  //     "meeting_date": "2021-05-23",
  //     "meeting_type": 1,
  //     "meeting_from_time": "16:17:18",
  //     "meeting_to_time": "17:18:19",
  //     "id": 1,
  //     "startups_evaluated": 0,
  //     "startups_selected": 0,
  //     "funds_approved": "0.00",
  //     "created_at": "2021-05-21T06:49:25.000000Z",
  //     "file_path": "",
  //     "startup_count": 1,
  //     "meeting_type_name": "Startup Selection"
  // }
  // ]);
  const [
    filteredStartupUpcomingMeetingData,
    setFilteredStartupUpcomingMeetingData,
  ] = useState([]);
  const [startupPastMeetingData, setStartupPastMeetingData] = useState([]);
  //     {
  //         "meeting_date": "2021-05-20",
  //         "meeting_type": 1,
  //         "meeting_from_time": "16:17:18",
  //         "meeting_to_time": "17:18:19",
  //         "id": 1,
  //         "startups_evaluated": 0,
  //         "startups_selected": 0,
  //         "funds_approved": "0.00",
  //         "created_at": "2021-05-21T06:49:25.000000Z",
  //         "file_path": "",
  //         "startup_count": 1,
  //         "meeting_type_name": "Startup Selection",
  //         "next_meeting_date": "2021-08-18"
  //     }
  // ]);
  const [showAddConfirmationBox, setShowAddConfirmationBox] = useState(false);
  const [editCountMeeting, setEditCountMeeting] = useState({
    meeting_id: "",
    incubator_evaluated: "",
    incubator_selected: "",
    funds_approved: "",
    meeting_date: "",
    meeting_from_time: "",
    meeting_to_time: "",
    meeting_type: "",
  });

  const [editMeeting, setEditMeeting] = useState({
    meeting_type: "",
    meeting_date: "",
    meeting_from_time: "",
    meeting_to_time: "",
    meeting_id: "",
    created_at: "",
  });
  const [timeValidation, setTimeValidation] = useState(false);
  const [filteredStartupPastMeetingData, setFilteredStartupPastMeetingData] =
    useState([]);
  const [timeValidationCurrentTime, setTimeValidationCurrentTime] =
    useState(false);
  const [incubatorDetails, setIncubatorDetails] = useState([]);
  const [showViewMore, setShowViewMore] = useState("");
  const [modalDate, setModalDate] = useState();
  const [incuabtorApplicationID, setIncubatorApplicationID] = useState("");
  const [modalTime, setModalTime] = useState();
  const [meetingID, setMeetingID] = useState();
  const [noDataFilter, setNoDataFilter] = useState("");
  const [noDataStartupFilter, setNoDataStartupFilter] = useState("");
  const [activeIndex, setActiveIndex] = useState("incubator");
  const [isEditable, setIsEditable] = useState(true);
  const [showIncubatorSuccessModal, setShowIncubatorSuccessModal] =
    useState(false);
  const [activeTab, setActiveTab] = useState("incubator");
  const [showIncubatorFailureModal, setShowIncubatorFailureModal] =
    useState(false);
  const [openUploadBlock, setOpenUploadBlock] = useState(false);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [meetingIdFilter, setMeetingIdFilter] = useState("");
  const [meetingPath, setMeetingPath] = useState("");
  const [isDisabled, setDisabled] = useState(false);
  const [meetingTab, setMeetingTab] = useState("upcoming");
  const [showMeetingSuccessModal, setShowMeetingSuccessModal] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [dialogData, setDialogData] = useState({
    header: "",
    text: "",
  });
  const [confirmationDialogData, setConfirmationDialogData] = useState({
    header: "",
    text: "",
  });
  const [failDialogData, setFailDialogData] = useState({
    header: "",
    text: "",
  });

  const applyIncubatorFilter = () => {
    const payload = {
      meeting_id: meetingIdFilter,
      search: filterText,
    };
    console.log(filterText,"11111111111","valueeeeeeeeeeeeeeeeeee")
    post(STARTUP_SEARCH, payload).then((response: any) => {
      if (!response || !response.data) return;
      // //console.log(response.data.data);
      setFilteredData(response.data.Data);
      if (response.data.Data.length == 0) {
        setNoDataStartupFilter("test");
      }
    });
  };

  useEffect(() => {
    getMeetingDetails();
    // getStartupMeetingDetails();
  }, []);
  // get states in dropdown
  const getMeetingDetails = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)

    var payload = {
      "logged_user_id": userInfo.id,
      "userRole": userInfo.roles
    }
    post(GET_MEETINGS, payload).then((response: any) => {
      if (!response || !response.data) return;
      setupcomingMeetingData(response.data.UpcomingMeetings);
      setFilteredUpcomingMeetingData([]);
      setPastMeetingData(response.data.PastMeetings);
      setFilteredPastMeetingData([]);
      getStartupMeetingDetails();
    });
  };
  const [isMeetingId, setIsMeetingId] = useState<any>()

  const getStartupMeetingDetails = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    var payload = {
      "userRole": userInfo.roles,
      "logged_user_id": userInfo.id
    }
    post(GET_STARTUP_MEETINGS, payload).then((response: any) => {
      // console.log(response.data.data.past,"checkkkk")
      if (!response || !response.data) return;
      setStartupUpcomingMeetingData(response.data.data.upcoming);
      setStartupPastMeetingData(response.data.data.past);
      // setupcomingMeetingData(response.data.result.data.upcoming);
      // setFilteredUpcomingMeetingData([]);
      // setPastMeetingData(response.data.result.data.past);
      // setFilteredPastMeetingData([])
    });
  };
  // const getIncubatorDetails = (id: any, date: any, time: any) => {
  //     get(`${GET_ALL_INCUBATORS}?meeting_id=${id}`).then((response: any) => {
  //         if (!response || !response.data) return;
  //         //console.log(response.data)
  //         setIncubatorDetails(response.data.result.data)
  //         setModalDate(date);
  //         setModalTime(time);
  //         setMeetingID(id);
  //     });
  // };

  // function to format date
  function formatDate(date: Date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const handleRowChange = (id: any) => {
    history.replace({
      pathname: "/view_startup_details",
      state: { application_id: id },
    });
  };
  const handleEditDateChange = (date: Date, field: string) => {
    setEditMeeting((prevState) => ({
      ...prevState,
      meeting_date: formatDate(new Date(date)),
    }));
  };

  // const evaluate = (id: any, count: any, date: any, time: any) => {
  //     history.replace({
  //         pathname: '/view_selection_meeting',
  //         state: { meeting_id: id, incubator_count: count, time_string: time, date_string: date }
  //     });
  // };
  const handleDateChange = (date: Date, field: string) => { };

  const onEditTimeChange = (e: any) => {
    var value = e.target.value;
    var name = e.target.name;

    if (name == "fromTime") {
      setEditMeeting((prevState) => ({
        ...prevState,
        meeting_from_time: value + ":00",
      }));
      if (
        compareTime(
          editMeeting.meeting_from_time,
          editMeeting.meeting_to_time
        ) &&
        editMeeting.meeting_to_time != ""
      ) {
        setTimeValidation(false);
      }
    }
    if (name == "toTime") {
      setEditMeeting((prevState) => ({
        ...prevState,
        meeting_to_time: value + ":00",
      }));
      if (
        compareTime(
          editMeeting.meeting_from_time,
          editMeeting.meeting_to_time
        ) &&
        editMeeting.meeting_to_time != ""
      ) {
        setTimeValidation(false);
      }
    }
    if (name == "incubatorSelected") {
      setEditCountMeeting((prevState) => ({
        ...prevState,
        incubator_selected: value,
      }));
    }
    if (name == "incubatorEvaluated") {
      setEditCountMeeting((prevState) => ({
        ...prevState,
        incubator_evaluated: value,
      }));
    }
    if (name == "fundsApproved") {
      setEditCountMeeting((prevState) => ({
        ...prevState,
        funds_approved: value,
      }));
    }
  };

  const saveMeetingInfo = () => {
    const payload = {
      meeting_id: editCountMeeting.meeting_id,
      startups_evaluated: editCountMeeting.incubator_evaluated,
      startups_selected: editCountMeeting.incubator_selected,
      funds_approved: editCountMeeting.funds_approved,
    };
    post(REVIEW_UPDATE, payload).then((response: any) => {

      if (!response || !response.data) return;
      //console.log(response.data);
      if (response.data.result == "success") {
        setDialogData({
          header: "Thank You!",
          text: "Meeting Details Updated Successfully !!!",
        });
        setShowIncubatorSuccessModal(true);
        getStartupMeetingDetails();
        setIsEditable(true);
      } else {
        setFailDialogData({
          header: "Oops ! Something went wrong,",
          text: "Please Try Again !!",
        });
        setShowIncubatorFailureModal(true);
      }
    });
  };
  const addIncubator = (id: any) => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    setIncubatorApplicationID(id);
    post(`${REMOVE_INCUBATOR_ADD_NODE}/${meetingIdFilter}/addStartup`, {
      startup_application_id: id,
      warning_ignore: 0,
      meetingId: meetingIdFilter,
      "logged_user_id": userInfo.id

    }).then((response: any) => {
      // console.log(response,"ressss")
      if (!response || !response.data) return;
      setFilteredData([]);
      setFilterText("")
      if (response.data.result == "success") {
        setDialogData({
          header: "Thank You!",
          text: "Startup Added Successfully!!!",
        });
        setShowIncubatorSuccessModal(true);
        setFilteredData([]);
        // ?meeting_id=
        get(`${GET_ALL_STARTUPS}/${id}`).then((response: any) => {
          if (!response || !response.data) return;
          //console.log(response.data);
          setIncubatorDetails(response.data.data);
          setMeetingID(id);
          getMeetingDetails();
          getIncubatorDetails(meetingID, modalDate, modalTime)
          // window.location.reload()
        });

      } else if (response.data.result == "warning") {
        setConfirmationDialogData({
          header: "Meeting Already Exist",
          text: response.data.message,
        });
        setShowAddConfirmationBox(true);
      } else {
        setFilteredData([]);
        setFailDialogData({
          header: "Oops ! Something went wrong,",
          text: response.data.message,
        });
        setShowIncubatorFailureModal(true);
      }
    });
  };

  const finalAddIncubator = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    post(`${REMOVE_INCUBATOR_ADD_NODE}/${meetingIdFilter}/addStartup`, {
      startup_application_id: incuabtorApplicationID,
      warning_ignore: 1,
      meetingId: meetingIdFilter,
      "logged_user_id": userInfo.id
    }).then((response: any) => {
      if (!response || !response.data) return;
      setShowAddConfirmationBox(false);
      if (response.data.result == "success") {
        setDialogData({
          header: "Thank You!",
          text: "Startup Added Successfully!!!",
        });
        setShowIncubatorSuccessModal(true);
        setFilteredData([]);
        // ?meeting_id=
        get(`${GET_ALL_STARTUPS}/${meetingIdFilter}`).then(
          (response: any) => {
            if (!response || !response.data) return;
            //console.log(response.data);
            setIncubatorDetails(response.data.data);
          }
        );
      } else {
        setFilteredData([]);
        setFailDialogData({
          header: "Oops ! Something went wrong,",
          text: response.data.message,
        });

        setShowIncubatorFailureModal(true);
      }
    });
  };

  const onFileUpload = (files: Array<File>) => {
    let indexes: Array<string> = [];
    setUploadedFile([]);
    for (let file of files) {
      if (file.type === "application/pdf") {
        const formData = new FormData();
        // Update the formData object
        formData.append("file", file, file.name);
        formData.append("meeting_id", meetingIdFilter);
        post(STARTUP_DOCUMENT_UPLOAD, formData).then((response: any) => {
          if (!response.data) return;
          if (response.data.result.status == true) {
            setDialogData({
              header: "Thank You!",
              text: "Document Uploaded Successfully !!!",
            });
            setShowIncubatorSuccessModal(true);
            getStartupMeetingDetails();
          } else {
            setFailDialogData({
              header: "Oops ! Something went wrong,",
              text: "Please Try Again !!",
            });
            setShowIncubatorFailureModal(true);
          }
        });
      } else {
        indexes.push(file.name);
      }
    }
  };
  const onFileDelete = () => {
    post(STARTUP_DOCUMENT_REMOVE, { meeting_id: meetingIdFilter }).then(
      (response: any) => {
        if (!response.data) return;
        if (response.data.result.status == true) {
          setDialogData({
            header: "Thank You!",
            text: "Document Deleted Successfully !!!",
          });
          setShowIncubatorSuccessModal(true);
          getStartupMeetingDetails();
          setMeetingPath("");
        } else {
          setFailDialogData({
            header: "Oops ! Something went wrong,",
            text: "Please Try Again !!",
          });
          setShowIncubatorFailureModal(true);
        }
      }
    );
  };

  const getMeetingByID = (id: any, item: any) => {
    setOpenUploadBlock(false);
    setUploadedFile([]);
    setMeetingIdFilter(id);
    setEditCountMeeting({
      meeting_id: item.id,
      incubator_evaluated: item.startups_evaluated,
      incubator_selected: item.startups_selected,
      funds_approved: item.funds_approved,
      meeting_date: item.meeting_date,
      meeting_from_time: item.meeting_from_time,
      meeting_to_time: item.meeting_to_time,
      meeting_type: item.meeting_type,
    });

    setEditMeeting({
      meeting_type: item.meeting_type,
      meeting_date: moment(item.meeting_date).format("YYYY-MM-DD"),
      meeting_from_time: item.meeting_from_time,
      meeting_to_time: item.meeting_to_time,
      meeting_id: item.id,
      created_at: item.created_at,
    });
    if (item.data.data.file_path != "") {
      setMeetingPath(item.file_location);
    } else {
      setMeetingPath("");
    }

    // post(STARTUP_MEETING_GET_BY_ID, { "id": id }).then(
    //   (response: any) => {
    //       console.log(response,"dattaaaa")
    //     if (!response || !response.data) return;
    //     setEditMeeting({
    //       meeting_type: response.data.search[0].meeting_type,
    //       meeting_date: moment(response.data.search[0].meeting_date).format("YYYY-MM-DD"),
    //       meeting_from_time: response.data.search[0].meeting_from_time,
    //       meeting_to_time: response.data.search[0].meeting_to_time,
    //       meeting_id: response.data.search[0].id,
    //       created_at: response.data.search[0].created_at,
    //     });
    //     if (response.data.data.file_path != "") {
    //       setMeetingPath(response.data.search[0].file_location);
    //     } else {
    //       setMeetingPath("");
    //     }
    //     setEditCountMeeting({
    //       meeting_id: response.data.search[0].id,
    //       incubator_evaluated: response.data.search[0].startups_evaluated,
    //       incubator_selected: response.data.search[0].startups_selected,
    //       funds_approved: response.data.search[0].funds_approved,
    //       meeting_date: response.data.search[0].meeting_date,
    //       meeting_from_time: response.data.search[0].meeting_from_time,
    //       meeting_to_time: response.data.search[0].meeting_to_time,
    //       meeting_type: response.data.search[0].meeting_type,
    //     });
    //   }
    // );
  };
  const dismissModal = () => {
    setShowIncubatorSuccessModal(false);
    setShowIncubatorFailureModal(false);
  };

  const dismissMeetingModal = () => {
    setShowMeetingSuccessModal(false);
    // getMeetingDetails();
    $('#editMeeting').modal('hide');
    // window.location.reload();
  };

  const searchList = (e: any) => {
    setNoDataFilter(e.target.value);
    const filteredData = filterByValue(upcomingMeetingData, e.target.value);
    const filteredPastData = filterByValue(pastMeetingData, e.target.value);
    const filteredstartupData = filterByValue(
      startupUpcomingMeetingData,
      e.target.value
    );
    const filteredstartuppastData = filterByValue(
      startupPastMeetingData,
      e.target.value
    );
    setFilteredUpcomingMeetingData(filteredData);
    setFilteredPastMeetingData(filteredPastData);
    setFilteredStartupUpcomingMeetingData(filteredstartupData);
    setFilteredStartupPastMeetingData(filteredstartuppastData);
  };
  const changeActive = (tabs: any) => {
    setActiveIndex(tabs);
  };

  const filterValue = (e: any /*index:number*/) => {
    var value = e.target.value;
    console.log(value,"valueeeeeeeeeeeeeeeeeee")
    if (e.target.value == "" || e.target.value == null) {
      setFilteredData([]);
      setNoDataStartupFilter("");
    }
    setFilterText(value);
  };

  const meetingReschedule = () => {
    var time = new Date();
    var curTime = moment(time, "HH:mm").format("HH:mm");
    if (
      !compareTime(editMeeting.meeting_from_time, editMeeting.meeting_to_time)
    ) {
      setTimeValidation(true);
      setTimeValidationCurrentTime(false);
      return false;
    }
    if (
      formatDate(new Date()) == editMeeting.meeting_date &&
      compareTime(editMeeting.meeting_from_time, curTime)
    ) {
      setTimeValidationCurrentTime(true);
      setTimeValidation(false);
      return false;
    }

    setDisabled(true);
    setTimeValidationCurrentTime(false);
    setTimeValidation(false);
    var payload = {
      "meeting_date": editMeeting.meeting_date,
      "meeting_from_time": editMeeting.meeting_from_time,
      "meeting_to_time": editMeeting.meeting_to_time,
      "meeting_id": editMeeting.meeting_id
    }
    post(STARTUP_MEETING_UPDATE_BY_ID, payload).then((response: any) => {
      if (!response || !response.data) return;
      //console.log(response.data);
      if (response.data.result == "success") {
        setDialogData({
          header: "Thank You!",
          text: "Meeting Details Updated Successfully !!!",
        });
        setShowMeetingSuccessModal(true);
        getStartupMeetingDetails();
        setIsEditable(true);
        setDisabled(false);
      } else {
        setFailDialogData({
          header: "Oops!",
          text: response.data.message,
        });
        setShowIncubatorFailureModal(true);
        getStartupMeetingDetails();
        setDisabled(false);
      }
    });
  };

  const getIncubatorDetails = (id: any, date: any, time: any) => {
    setMeetingIdFilter(id);
    setIncubatorDetails([]);
    // ?meeting_id=
    setLoader(true)
    get(`${GET_ALL_STARTUPS}/${id}`).then((response: any) => {
      setLoader(false)
      if (!response || !response.data) return;
      //console.log(response.data);
      if (typeof response.data.data != "undefined") {
        setIncubatorDetails(response.data.data);
      }
      setModalDate(date);
      setModalTime(time);
      setMeetingID(id);
    });
  };

  const removeIncubator = (id: any) => {
    setLoader(true);
    post(`${REMOVE_INCUBATOR}/${meetingID}/removeStartup`, {
      startup_application_id: id,
    }).then((response: any) => {
      setLoader(false);
      if (!response || !response.data) return;
      // ?meeting_id=
      
      setLoader(true)
      get(`${GET_ALL_STARTUPS}/${meetingIdFilter}`).then(
        (response: any) => {
          setLoader(false)
          if (!response || !response.data) return;
          //console.log(response.data);
          setIncubatorDetails(response.data.data);
          setMeetingID(id);
          getStartupMeetingDetails();
        }
      );
      if (response.result == "success") {
        get(`${GET_ALL_INCUBATORS}/${meetingIdFilter}`).then(
          (response: any) => {
            if (!response || !response.data) return;
            setIncubatorDetails(response.data.data);
            getMeetingDetails();
          }
        );
      }
    });
  };
  return (
    <>
      <div className="container-fluid pl-5 pr-5 pt-2">
        <div className="app-inside-content">
          <div className="objectives-nav">
            <ul className="nav nav-tabs">
              <li className={activeIndex == "incubator" ? "active" : ""}>
                <a
                  href="#tab-incubator"
                  onClick={() => changeActive("incubator")}
                  data-toggle="tab"
                >
                  My Applications
                </a>
              </li>
              <li className={activeIndex == "startup" ? "active" : ""}>
                <a
                  href="#tab-startup"
                  onClick={() => changeActive("startup")}
                  data-toggle="tab"
                >
                  Startup Applications
                </a>
              </li>
            </ul>
          </div>
          <div className="tab-content">
            {/* submitted tab */}
            <div
              className={
                activeTab == "incubator" ? "tab-pane active" : "tab-pane"
              }
              id="tab-incubator"
            >
              {/* start */}
              <div className="row incubator-heading-review">
                <div className="col-md-6 text-left">
                  <ul className="nav nav-tabs chart-nav" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-toggle="tab"
                        href="#tabs-1"
                        role="tab"
                      >
                        Upcoming Meetings
                      </a>
                    </li>
                    <div className="vertical-dash"></div>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#tabs-2"
                        role="tab"
                      >
                        Past Meetings
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 text-right search-left">
                  <div className="d-flex searchDiv">
                    {" "}
                    <img src={search} className="search" alt="search" />
                    <input
                      className="searchBar"
                      type="text"
                      placeholder="Search.."
                      name="search"
                      onKeyUp={searchList}
                    />
                  </div>
                </div>
              </div>
              <div className="tab-content mb-4 mt-4">
                <div className="tab-pane active" id="tabs-1">
                  <div className="table-scroll">
                    <table className="reviewTable">
                      <thead>
                        <tr>
                          <th className="text-left table-heading custom-td-left table-heading ">
                            Meeting Type
                          </th>
                          <th className="text-left table-heading">
                            Date & Time
                          </th>
                          <th className="text-left table-heading">
                            Meeting Link
                          </th>
                          <th className="text-left table-heading">
                            Date Of Application Submitted
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {upcomingMeetingData == null || upcomingMeetingData == undefined || upcomingMeetingData.length == 0 ||
                          (filteredUpcomingMeetingData.length == 0 &&
                            noDataFilter != "") ? (
                          <tr>
                            <td className="text-center" colSpan={5}>
                              No data
                            </td>
                          </tr>
                        ) : filteredUpcomingMeetingData.length == 0 ? (
                          upcomingMeetingData && upcomingMeetingData.map((item: any, index) => (
                            <tr key={index}>
                              <td className="text-left custom-td-left">
                                {item.meeting_type == 1
                                  ? "Incubator Selection"
                                  : "Performance Evaluation"}
                              </td>
                              <td className="text-left custom-td">
                                <div>{FormatDate(item.meeting_date)}</div>
                                <span className="tableDataApplication">
                                  {formatTime(item.meeting_from_time)} -{" "}
                                  {formatTime(item.meeting_to_time)}
                                </span>
                              </td>
                              <td>
                                {item.link != null ? (
                                  <>
                                    {showViewMore != "show_" + index && (
                                      <>
                                        <a
                                          href={item.link}
                                          target="_blank"
                                          className="link-orange-text"
                                        >
                                          {showEllipsisText(item.link, 15)}
                                        </a>
                                        {item.link.length >= 15 && (
                                          <span
                                            className="link-orange-text d-block"
                                            onClick={() =>
                                              setShowViewMore("show_" + index)
                                            }
                                          >
                                            View More
                                          </span>
                                        )}
                                      </>
                                    )}
                                    {showViewMore == "show_" + index && (
                                      <>
                                        <a
                                          href={item.link}
                                          target="_blank"
                                          className="link-orange-text"
                                        >
                                          {item.link}
                                        </a>
                                        <span
                                          className="link-orange-text d-block"
                                          onClick={() => setShowViewMore("")}
                                        >
                                          View Less
                                        </span>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td className="text-left custom-td">
                                {FormatDate(item.first_submitted_date)}
                              </td>
                            </tr>
                          ))
                        ) : (
                          filteredUpcomingMeetingData.map(
                            (item: any, index) => (
                              <tr key={index}>
                                <td className="text-left custom-td-left">
                                  {item.meeting_type == 1
                                    ? "Incubator Selection"
                                    : "Performance Evaluation"}
                                </td>
                                <td className="text-left custom-td">
                                  <div>{FormatDate(item.meeting_date)}</div>
                                  <span className="tableDataApplication">
                                    {formatTime(item.meeting_from_time)} -{" "}
                                    {formatTime(item.meeting_to_time)}
                                  </span>
                                </td>
                                <td>
                                  {item.link != null ? (
                                    <>
                                      {showViewMore != "show_" + index && (
                                        <>
                                          <a
                                            href={item.link}
                                            target="_blank"
                                            className="link-orange-text"
                                          >
                                            {showEllipsisText(item.link, 15)}
                                          </a>
                                          {item.link.length >= 15 && (
                                            <span
                                              className="link-orange-text d-block"
                                              onClick={() =>
                                                setShowViewMore("show_" + index)
                                              }
                                            >
                                              View More
                                            </span>
                                          )}
                                        </>
                                      )}
                                      {showViewMore == "show_" + index && (
                                        <>
                                          <a
                                            href={item.link}
                                            target="_blank"
                                            className="link-orange-text"
                                          >
                                            {item.link}
                                          </a>
                                          <span
                                            className="link-orange-text d-block"
                                            onClick={() => setShowViewMore("")}
                                          >
                                            View Less
                                          </span>
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                                <td className="text-left custom-td">
                                  {FormatDate(item.first_submitted_date)}
                                </td>
                              </tr>
                            )
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="tab-pane" id="tabs-2">
                  <div className="table-scroll mb-5">
                    <table className="reviewTable">
                      <thead>
                        <tr>
                          <th className="text-left table-heading custom-td-left table-heading">
                            Meeting Type
                          </th>
                          <th className="text-left table-heading">
                            Date & Time
                          </th>

                          <th className="text-left table-heading">
                            EAC Performance Review Date
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {pastMeetingData == undefined || pastMeetingData==null || pastMeetingData.length == 0 ||
                          (filteredPastMeetingData.length == 0 &&
                            noDataFilter != "") ? (
                          <tr>
                            <td className="text-center" colSpan={5}>
                              No data
                            </td>
                          </tr>
                        ) : filteredPastMeetingData.length == 0 ? (
                          pastMeetingData && pastMeetingData.map((item: any, index: any) => (
                            <tr key={index}>
                              <td className="text-left custom-td-left">
                                {item.meeting_type == 1
                                  ? "Incubator Selection"
                                  : "Performance Evaluation"}
                              </td>
                              <td className="text-left custom-td">
                                <div>{FormatDate(item.meeting_date)}</div>
                                <span className="tableDataApplication">
                                  {formatTime(item.meeting_from_time)} -{" "}
                                  {formatTime(item.meeting_to_time)}
                                </span>
                              </td>

                              <td className="text-left custom-td">
                                {FormatDate(item.due_review_date)}
                              </td>
                            </tr>
                          ))
                        ) : (
                          filteredPastMeetingData.map((item: any, index: any) => (
                            <tr key={index}>
                              <td className="text-left custom-td-left">
                                {item.meeting_type == 1
                                  ? "Incubator Selection"
                                  : "Performance Evaluation"}
                              </td>
                              <td className="text-left custom-td">
                                <div>{FormatDate(item.meeting_date)}</div>
                                <span className="tableDataApplication">
                                  {formatTime(item.meeting_from_time)} -{" "}
                                  {formatTime(item.meeting_to_time)}
                                </span>
                              </td>

                              <td className="text-left custom-td">
                                {FormatDate(item.due_review_date)}
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* end */}
            </div>
            {/* selected tab */}

            <div
              className={
                activeTab == "startup" ? "tab-pane active" : "tab-pane"
              }
              id="tab-startup"
            >
              {/* start */}
              <div className="row incubator-heading-review">
                <div className="col-md-6 text-left">
                  <ul className="nav nav-tabs chart-nav" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-toggle="tab"
                        href="#tabs-3"
                        role="tab"
                        onClick={() => setMeetingTab("upcoming")}
                      >
                        Upcoming Meetings
                      </a>
                    </li>
                    <div className="vertical-dash"></div>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#tabs-4"
                        role="tab"
                        onClick={() => setMeetingTab("past")}
                      >
                        Past Meetings
                      </a>
                    </li>
                  </ul>
                </div>
                {/* <div className="col-md-6 search-left-meeting">
                        <div className="row">
                            <div className="d-flex searchDiv col-12"> <img
                                src={search}
                                className="search"
                                alt="search"

                            />
                                <input
                                    className="searchBar"
                                    type="text"
                                    placeholder="Search.."
                                    name="search"
                                    onKeyUp={searchList}
                                /></div>
                           <div className="col-6 schedule-button-pd text-right pr-3">
                                <button className="btn btn-sisfs  btn-yellow-primary" data-toggle="modal" data-target="#scheduleMeeting">
                                    SCHEDULE A MEETING
                </button>
                            </div> 
                        </div>
                    </div> */}
                <div className="col-md-6 text-right search-left">
                  <div className="d-flex searchDiv" style={{ float: 'right' }}>
                    {" "}
                    <img src={search} className="search" alt="search" />
                    <input
                      className="searchBar"
                      type="text"
                      placeholder="Search.."
                      name="search"
                      onKeyUp={searchList}
                    />
                  </div>
                </div>
              </div>
              <div className="tab-content mb-4">
                <div className="tab-pane active" id="tabs-3">
                  <div className="table-scroll">
                    <table className="reviewTable">
                      <thead>
                        <tr>
                          <th className="text-left table-heading custom-td-left table-heading ">
                            Meeting Type
                          </th>
                          <th className="text-left table-heading">
                            Date & Time
                          </th>
                          <th className="text-left table-heading">
                            No.of Startups
                          </th>
                          <th className="table-heading">Meeting Link</th>
                          <th className="table-heading"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {startupUpcomingMeetingData.length == 0 ||
                          (filteredStartupUpcomingMeetingData.length == 0 &&
                            noDataFilter != "") ? (
                          <tr>
                            <td className="text-center" colSpan={5}>
                              No data
                            </td>
                          </tr>
                        ) : filteredStartupUpcomingMeetingData.length == 0 ? (
                          startupUpcomingMeetingData.map((item: any, index) => (
                            <tr key={index}>
                              <td className="text-left custom-td-left">
                                {item.meeting_type == 1
                                  ? "Startup Selection"
                                  : "Performance Evaluation"}
                              </td>
                              <td className="text-left custom-td">
                                <div>{FormatDate(item.meeting_date)}</div>
                                <span className="tableDataApplication">
                                  {formatTime(item.meeting_from_time)} -{" "}
                                  {formatTime(item.meeting_to_time)}
                                </span>
                              </td>
                              <td className="text-left">
                                {item.incubator_count}{" "}
                                <span
                                  className="view-all"
                                  onClick={() =>
                                    getIncubatorDetails(
                                      item.id,
                                      item.meeting_date,
                                      formatTime(item.meeting_from_time) +
                                      "-" +
                                      formatTime(item.meeting_to_time)
                                    )
                                  }
                                  data-toggle="modal"
                                  data-target="#viewIncubator"
                                >
                                  View
                                </span>
                                <span className="view-all back-slash">Add</span>
                              </td>
                              {/* <td className="text-left">{item.startup_count} <span className="view-all" data-toggle="modal" data-target="#viewIncubator">View</span><span className="view-all back-slash">Add</span></td> */}
                              <td>
                                {item.link != null ? (
                                  <>
                                    {showViewMore != "show_" + index && (
                                      <>
                                        <a
                                          href={item.link}
                                          target="_blank"
                                          className="link-orange-text"
                                        >
                                          {showEllipsisText(item.link, 15)}
                                        </a>
                                        {item.link.length >= 15 && (
                                          <span
                                            className="link-orange-text d-block"
                                            onClick={() =>
                                              setShowViewMore("show_" + index)
                                            }
                                          >
                                            View More
                                          </span>
                                        )}
                                      </>
                                    )}
                                    {showViewMore == "show_" + index && (
                                      <>
                                        <a
                                          href={item.link}
                                          target="_blank"
                                          className="link-orange-text"
                                        >
                                          {item.link}
                                        </a>
                                        <span
                                          className="link-orange-text d-block"
                                          onClick={() => setShowViewMore("")}
                                        >
                                          View Less
                                        </span>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  "-"
                                )}
                              </td>
                              {/* <td className="text-right"><button className="btn btn-sisfs btn-transparent-primary" onClick={() => getMeetingByID(item.id)} data-toggle="modal" data-target="#editMeeting">Reschedule</button></td> */}
                              <td className="text-right">
                                <button
                                  className="btn btn-sisfs btn-transparent-primary"
                                  onClick={() => getMeetingByID(item.id, item)}
                                  data-toggle="modal"
                                  data-target="#editMeeting"
                                >
                                  Reschedule
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          filteredStartupUpcomingMeetingData.map(
                            (item: any, index) => (
                              <tr key={index}>
                                <td className="text-left custom-td-left">
                                  {item.meeting_type == 1
                                    ? "Startup Selection"
                                    : "Performance Evaluation"}
                                </td>
                                <td className="text-left custom-td">
                                  <div>{FormatDate(item.meeting_date)}</div>
                                  <span className="tableDataApplication">
                                    {formatTime(item.meeting_from_time)} -{" "}
                                    {formatTime(item.meeting_to_time)}
                                  </span>
                                </td>
                                <td className="text-left">
                                  {item.incubator_count}{" "}
                                  <span
                                    className="view-all"
                                    onClick={() =>
                                      getIncubatorDetails(
                                        item.id,
                                        item.meeting_date,
                                        formatTime(item.meeting_from_time) +
                                        "-" +
                                        formatTime(item.meeting_to_time)
                                      )
                                    }
                                    data-toggle="modal"
                                    data-target="#viewIncubator"
                                  >
                                    View
                                  </span>
                                  <span className="view-all back-slash">
                                    Add
                                  </span>
                                </td>
                                {/* <td className="text-left">{item.startup_count} <span className="view-all" data-toggle="modal" data-target="#viewIncubator">View</span><span className="view-all back-slash">Add</span></td> */}
                                <td>
                                  {item.link != null ? (
                                    <>
                                      {showViewMore != "show_" + index && (
                                        <>
                                          <a
                                            href={item.link}
                                            target="_blank"
                                            className="link-orange-text"
                                          >
                                            {showEllipsisText(item.link, 15)}
                                          </a>
                                          {item.link.length >= 15 && (
                                            <span
                                              className="link-orange-text d-block"
                                              onClick={() =>
                                                setShowViewMore("show_" + index)
                                              }
                                            >
                                              View More
                                            </span>
                                          )}
                                        </>
                                      )}
                                      {showViewMore == "show_" + index && (
                                        <>
                                          <a
                                            href={item.link}
                                            target="_blank"
                                            className="link-orange-text"
                                          >
                                            {item.link}
                                          </a>
                                          <span
                                            className="link-orange-text d-block"
                                            onClick={() => setShowViewMore("")}
                                          >
                                            View Less
                                          </span>
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                                {/* <td className="text-right"><button className="btn btn-sisfs btn-transparent-primary" onClick={() => getMeetingByID(item.id)} data-toggle="modal" data-target="#editMeeting">Reschedule</button></td> */}
                                <td className="text-right">
                                  <button
                                    className="btn btn-sisfs btn-transparent-primary"
                                    onClick={() => getMeetingByID(item.id, item)}
                                    data-toggle="modal"
                                    data-target="#editMeeting"
                                  >
                                    Reschedule
                                  </button>
                                </td>
                              </tr>
                            )
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="tab-pane" id="tabs-4">
                  <div className="table-scroll mb-5">
                    <table className="reviewTable">
                      <thead>
                        <tr>
                          <th className="text-left table-heading custom-td-left">
                            Meeting Type
                          </th>
                          <th className="text-left table-heading">
                            Date & Time
                          </th>
                          <th className="text-left table-heading">
                            No.of Startups Evaluated
                          </th>
                          <th className="text-left table-heading">
                            No.of Startups Selected
                          </th>
                          <th className="text-left table-heading">
                            Funds Approved(&#8377;)
                          </th>
                          <th className="text-left table-heading">
                            Meeting Notes
                          </th>
                          <th className="text-center table-heading">Edit</th>
                        </tr>
                        {/* {console.log(startupPastMeetingData, 'Startupreview')} */}
                      </thead>
                      <tbody>
                        {startupPastMeetingData.length == 0 ||
                          (filteredStartupPastMeetingData.length == 0 &&
                            noDataFilter != "") ? (
                          <tr>
                            <td className="text-center" colSpan={5}>
                              No data
                            </td>
                          </tr>
                        ) : filteredStartupPastMeetingData.length == 0 ? (
                          startupPastMeetingData.map((item: any, index) => (
                            <tr key={index}>
                              <td className="text-left custom-td-left">
                                {item.meeting_type == 1
                                  ? "Startup Selection"
                                  : "Performance Evaluation"}
                              </td>
                              <td className="text-left custom-td">
                                <div>{FormatDate(item.meeting_date)}</div>
                                <span className="tableDataApplication">
                                  {formatTime(item.meeting_from_time)} -{" "}
                                  {formatTime(item.meeting_to_time)}
                                </span>
                              </td>
                              <td className="text-left">
                                {item.startups_evaluated}{" "}
                                <span
                                  className="view-all"
                                  onClick={() =>
                                    getIncubatorDetails(
                                      item.id,
                                      item.meeting_date,
                                      formatTime(item.meeting_from_time) +
                                      "-" +
                                      formatTime(item.meeting_to_time)
                                    )
                                  }
                                  data-toggle="modal"
                                  data-target="#viewIncubator"
                                >
                                  View
                                </span>
                              </td>
                              {/* <td className="text-left">{item.startups_evaluated} <span className="view-all" data-toggle="modal" data-target="#viewIncubator">View</span></td> */}
                              <td className="text-left">
                                {item.startups_selected}
                              </td>
                              <td className="text-left custom-td-right">
                                {item.funds_approved}
                              </td>
                              <td className="text-left">
                                {item.file_path != "" ? (
                                  <a href={`${NODE_URL}` + item.file_path}
                                    target="_blank"
                                  >
                                    <img
                                      src={download}
                                      alt="download"
                                      onClick={() =>
                                        window.open('http://35.154.181.26:8585' + item.file_path)
                                        // downloadDocument(
                                        //  `${File_node_url}`+ item.file_path,
                                        //   item.meeting_type == 1
                                        //     ? "Startup-Selection-Meeting" +
                                        //         item.meeting_date
                                        //     : "Performance-Evaluation-Meeting-" +
                                        //         item.meeting_date
                                        // )

                                      }
                                    />
                                  </a>

                                ) : (
                                  "N/A"
                                )}
                              </td>
                              {/* <td className="text-left">{item.file_path != "" ? <img
                                                                    src={download}
                                                                    alt="download"

                                                                /> : "N/A"}</td> */}
                              <td
                                className="text-center"
                                data-toggle="modal"
                                onClick={() => getMeetingByID(item.id, item)}
                                data-target="#editPastMeeting"
                              >
                                <img
                                  src={edit}
                                  className="search"
                                  alt="search"
                                />
                              </td>
                              {/* <td className="text-center"
                                                                    data-toggle="modal" data-target="#editPastMeeting"><img
                                                                        src={edit}
                                                                        className="search"
                                                                        alt="search"
                                                                    /></td> */}
                            </tr>
                          ))
                        ) : (
                          filteredStartupPastMeetingData.map((item: any, index) => (
                            <tr key={index}>
                              <td className="text-left custom-td-left">
                                {item.meeting_type == 1
                                  ? "Startup Selection"
                                  : "Performance Evaluation"}
                              </td>
                              <td className="text-left custom-td">
                                <div>{FormatDate(item.meeting_date)}</div>
                                <span className="tableDataApplication">
                                  {formatTime(item.meeting_from_time)} -{" "}
                                  {formatTime(item.meeting_to_time)}
                                </span>
                              </td>
                              <td className="text-left">
                                {item.startups_evaluated}{" "}
                                <span
                                  className="view-all"
                                  onClick={() =>
                                    getIncubatorDetails(
                                      item.id,
                                      item.meeting_date,
                                      formatTime(item.meeting_from_time) +
                                      "-" +
                                      formatTime(item.meeting_to_time)
                                    )
                                  }
                                  data-toggle="modal"
                                  data-target="#viewIncubator"
                                >
                                  View
                                </span>
                              </td>
                              {/* <td className="text-left">{item.startups_evaluated} <span className="view-all" data-toggle="modal" data-target="#viewIncubator">View</span></td> */}
                              <td className="text-left">
                                {item.startups_selected}
                              </td>
                              <td className="text-left custom-td-right">
                                {item.funds_approved}
                              </td>
                              <td className="text-left">
                                {item.file_path != "" ? (
                                  <img
                                    src={download}
                                    alt="download"
                                    onClick={() =>
                                      downloadDocument(
                                        item.file_path,
                                        item.meeting_type == 1
                                          ? "Startup-Selection-Meeting" +
                                          item.meeting_date
                                          : "Performance-Evaluation-Meeting-" +
                                          item.meeting_date
                                      )
                                    }
                                  />
                                ) : (
                                  "N/A"
                                )}
                              </td>
                              {/* <td className="text-left">{item.file_path != "" ? <img
                                                                    src={download}
                                                                    alt="download"

                                                                /> : "N/A"}</td> */}
                              <td
                                className="text-center"
                                data-toggle="modal"
                                onClick={() => getMeetingByID(item.id, item)}
                                data-target="#editPastMeeting"
                              >
                                <img
                                  src={edit}
                                  className="search"
                                  alt="search"
                                />
                              </td>
                              {/* <td className="text-center"
                                                                    data-toggle="modal" data-target="#editPastMeeting"><img
                                                                        src={edit}
                                                                        className="search"
                                                                        alt="search"
                                                                    /></td> */}
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* end */}
            </div>
          </div>
        </div>
      </div>

      {/* quick meeting popop */}
      <div
        className="modal fade"
        id="editPastMeeting"
        role="dialog"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-slideout modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-bottom pr-5">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => setIsEditable(true)}
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pl-5 pr-5 pt-5">
              <div className="popup-head mb-5 ml-3">
                <div className="main-heading">
                  {editCountMeeting.meeting_type == "1"
                    ? "Startup Selection"
                    : "Performance Review Meeting"}
                </div>
              </div>

              <label className="col-sm-4 col-form-head">Date</label>
              <fieldset disabled={true}>
                <div className="col-md-6 mb-4">
                  <Calendar
                    id="registrationDate"
                    name="registrationDate"
                    className="form-control input-height"
                    format="dd/MM/yyyy"
                    selectedDate={
                      editCountMeeting.meeting_date
                        ? moment(editCountMeeting.meeting_date).format("DD-MM-YYYY")
                        : new Date()
                    }
                    onDateChange={(date: Date, field: string) =>
                      handleEditDateChange(date, field)
                    }
                  />
                </div>
              </fieldset>
              <div className="row pl-3 mb-5">
                <div className="col-md-6">
                  <label className="col-form-head">From Time</label>
                  <input
                    type="time"
                    disabled
                    value={editCountMeeting.meeting_from_time}
                    name="fromTime"
                    className="form-control input-height"
                  />
                </div>
                <div className="col-md-6">
                  <label className="col-form-head">To Time</label>
                  <input
                    type="time"
                    disabled
                    name="toTime"
                    value={editCountMeeting.meeting_to_time}
                    className="form-control input-height"
                  />
                </div>
              </div>
              <fieldset disabled={isEditable}>
                <div className="col-md-12 mb-5">
                  <label className="col-form-head">
                    No. of Startups Evaluated
                  </label>
                  <input
                    disabled
                    type="number"
                    value={editCountMeeting.incubator_evaluated}
                    name="incubatorEvaluated"
                    className="form-control input-height"
                    onChange={onEditTimeChange}
                  />
                </div>
                {editCountMeeting.meeting_type == "1" ? (
                  <div className="col-md-12 mb-5">
                    <label className="col-form-head">
                      No. of Startups Selected
                    </label>
                    <input
                      disabled
                      type="number"
                      value={editCountMeeting.incubator_selected}
                      name="incubatorSelected"
                      className="form-control input-height"
                      onChange={onEditTimeChange}
                    />
                  </div>
                ) : null}

                <div className="col-md-12 mb-4">
                  <label className="col-form-head">Funds Approved</label>
                  <input
                    type="number"
                    disabled
                    value={editCountMeeting.funds_approved}
                    name="fundsApproved"
                    className="form-control input-height"
                    onChange={onEditTimeChange}
                  />
                </div>

                <div className="col-md-12">
                  <div className="">
                    {/* <input type="file" className="form-control-file btn btn-sisfs btn-transparent-primary" id="exampleFormControlFile1" /> */}
                    <button
                      className="btn btn-sisfs btn-transparent-primary"
                      onClick={() => setOpenUploadBlock(true)}
                      disabled={false}
                    >
                      Upload Meeting Notes
                    </button>
                    {(openUploadBlock || uploadedFile.length != 0) && (
                      <>
                        <span
                          className="close"
                          onClick={() => setOpenUploadBlock(false)}
                        >
                          <i className="fa fa-close"></i>
                        </span>
                        <FileUploader
                          key={"meeting_file" + meetingIdFilter}
                          id={"meeting_file" + meetingIdFilter}
                          information="Drag & Drop the file or Browse"
                          fileType="application/pdf"
                          buttonLabel="Browse"
                          multiple={false}
                          enablePreview
                          IconComponent={() => (
                            <span className="fa fa-cloud-upload"></span>
                          )}
                          removeIconClass=""
                          existingFiles={uploadedFile}
                          onUploadFinish={(files: Array<File>) => {
                            onFileUpload(files);
                          }}
                          onFileDelete={(deletedFile: File) => onFileDelete()}
                        />
                        <small>Supported file format - PDF only</small>
                      </>
                    )}
                  </div>
                </div>
              </fieldset>
              {meetingPath != "" ? (
                <>
                  <label className="col-form-head mt-5 pl-4">
                    Uploaded Document
                  </label>
                  <br />
                  <div className="doc-div  text-center">
                    <span className="view-all">
                      <a
                        href={`${File_node_url}` + meetingPath}
                        className="view-all"
                        target="_blank"
                      >
                        View
                      </a>
                    </span>
                    &nbsp;&nbsp;
                    <span className="view-all" onClick={onFileDelete}>
                      Remove
                    </span>
                    <br />
                    <iframe scrolling="no" src={meetingPath} />
                  </div>
                </>
              ) : null}
            </div>
            <div className="modal-footer">
              <div className="row modal-row">
                <div className="col-md-6 text-center">
                  <button
                    className="btn btn-sisfs btn-yellow-primary full-width"
                    onClick={() => setIsEditable(false)}
                  >
                    EDIT
                  </button>
                </div>
                <div className="col-md-6 text-left">
                  <button
                    className="tertiary  btn-transparent-primary full-width"
                    onClick={saveMeetingInfo}
                    data-dismiss="modal"
                  >
                    SAVE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit meeting modal  */}
      <div
        className="modal fade"
        id="editMeeting"
        role="dialog"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-slideout modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-bottom pr-5">
              <button
                type="button"
                className="close"
                onClick={() => (
                  setIsEditable(true),
                  setTimeValidation(false),
                  setTimeValidationCurrentTime(false)
                )}
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pl-5 pr-5 pt-5">
              <div className="popup-head mb-5 ml-3">
                <div className="main-heading">Reschedule Meeting</div>
              </div>
              <label className="col-sm-4 col-form-head">Meeting Type</label>
              <div className="col-md-12 mb-4">
                <input
                  type="text"
                  disabled
                  className="form-control input-height"
                  aria-label="Select Entity Type"
                  value={
                    editMeeting.meeting_type == "1"
                      ? "Startup Selection"
                      : "Performance Review Meeting"
                  }
                />
              </div>

              <fieldset disabled={isEditable}>
                <div className="col-md-6 mb-4">
                  <label className="col-form-head">Date</label>
                  <Calendar
                    id="registrationDate"
                    name="registrationDate"
                    className="form-control input-height"
                    format="dd/MM/yyyy"
                    selectedDate={
                      editMeeting.meeting_date
                        ? editMeeting.meeting_date
                        : new Date()
                    }
                    onDateChange={(date: Date, field: string) =>
                      handleEditDateChange(date, field)
                    }
                    minDate={new Date()}
                  />
                </div>

                <div className="row pl-3">
                  <div className="col-md-6">
                    <label className="col-form-head">From Time</label>
                    <input
                      type="time"
                      value={editMeeting.meeting_from_time}
                      name="fromTime"
                      className="form-control input-height"
                      onChange={onEditTimeChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="col-form-head">To Time</label>
                    <input
                      type="time"
                      value={editMeeting.meeting_to_time}
                      name="toTime"
                      className="form-control input-height"
                      onChange={onEditTimeChange}
                    />
                  </div>
                </div>
              </fieldset>

              <div className="row text-left pl-4 mb-5">
                {timeValidation == true ? (
                  <label className="error-message">
                    From time greater than To time
                  </label>
                ) : null}
                {timeValidationCurrentTime == true ? (
                  <label className="error-message">
                    Meeting can't be scheduled for past time
                  </label>
                ) : null}
              </div>
              <div className="row pl-3">
                <div className="col-md-6">
                  <button
                    className="btn btn-sisfs btn-transparent-primary full-width"
                    onClick={() => setIsEditable(false)}
                  >
                    EDIT
                  </button>
                </div>
                <div className="col-md-6">
                  <button
                    className={
                      isDisabled
                        ? "tertiary  btn-yellow-primary full-width custom-disabled"
                        : "tertiary  btn-yellow-primary full-width"
                    }
                    disabled={isDisabled}
                    onClick={meetingReschedule}
                  >
                    RESCHEDULE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="viewIncubator"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-bottom mt-3 mb-3">
              <h5 className="modal-title pl-2" id="exampleModalLongTitle">
                Startups
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={()=>{setFilterText("");setFilteredData([])}}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {loader && (
                <div className="spinner-border custom-spinner" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )}
              <fieldset disabled={loader}>
                <div className="container">
                  <div className="row">
                    <div className="col-md-5 text-left">
                      <span className="modal-text">Date & Time</span>
                      <br />
                      <span className="modal-text">
                        {FormatDate(modalDate)} | {modalTime}
                      </span>
                    </div>

                    {meetingTab == "upcoming" && (
                      <div className="col-md-7 text-right">
                        <div className="row">
                          <div className="d-flex searchDiv col-7">
                            {" "}
                            <img src={search} className="search" alt="search" />
                            <input
                              className="searchBar"
                              type="text"
                              placeholder="Search.."
                              value={filterText}
                              name="search"
                              onChange={filterValue}
                            />
                          </div>
                          <div className="col-1  schedule-button-pd">
                            <button
                              className="tertiary  btn-yellow-primary"
                              onClick={applyIncubatorFilter}
                            >
                              Search
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="table-scroll mb-5">

                      <table id="example">
                        <thead>
                          <tr>
                            <th className="custom-td-left table-heading">
                              Startup
                            </th>
                            <th className="text-left table-heading">
                              Application Date
                            </th>
                            {/* <th className="text-center table-heading">No. Of Statrtup Supported</th> */}
                            {meetingTab == "upcoming" && (
                              <th className="table-heading"></th>
                            )}
                          </tr>
                        </thead>
                        <tbody>

                          {

                            (incubatorDetails.length == 0 && (filteredData.length == 0 || filteredData.length == undefined) &&
                              noDataStartupFilter != "") ? (
                              <tr>
                                <td className="text-center" colSpan={4}>
                                  No data
                                </td>
                              </tr>
                            ) : filteredData.length != 0 ? (
                              filteredData.map((item: any, index) => (
                                <tr key={index}>
                                  <td
                                    className="text-left custom-td-left"
                                    onClick={() =>
                                      handleRowChange(item.startup_application_id)
                                    }
                                    data-dismiss="modal"
                                  >
                                    <u className="link-orange-text">
                                      {item.startup_application_name}
                                    </u>
                                  </td>
                                  <td className="text-left custom-td-left">
                                    {item.first_submitted_date != null
                                      ? moment(item.first_submitted_date).format("YYYY-MM-DD")
                                      : moment(item.startup_application_submitted_date).format("YYYY-MM-DD")}
                                  </td>
                                  {/* <td className="text-center">-</td> */}

                                  <td className="text-left">
                                    <span
                                      className="orange-link"
                                      onClick={() =>
                                        addIncubator(item.startup_application_id)
                                      }
                                    >
                                      Add
                                    </span>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              incubatorDetails.map((item: any, index) => (
                                <tr key={index}>
                                  <td
                                    className="text-left custom-td-left"
                                    onClick={() =>
                                      handleRowChange(item.startup_application_id)
                                    }
                                    data-dismiss="modal"
                                  >
                                    <u className="link-orange-text">
                                      {item.startup_name}
                                    </u>
                                  </td>
                                  <td className="text-left custom-td-left">
                                    {item.first_submitted_date != null
                                      ? moment(item.first_submitted_date).format("YYYY-MM-DD")
                                      : moment(item.startup_application_submitted_date).format("YYYY-MM-DD")}

                                  </td>
                                  {/* <td className="text-center">-</td> */}

                                  {meetingTab == "upcoming" && (
                                    <td className="text-left">
                                      <span
                                        className="orange-link"
                                        onClick={() =>
                                          removeIncubator(
                                            item.startup_application_id
                                          )
                                        }
                                      >
                                        Remove
                                      </span>
                                    </td>
                                  )}
                                </tr>
                              ))
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </fieldset>
              <div className="row pt-3 mb-3">
                <div className="col-md-12 centerText">
                  <button
                    className="tertiary  btn-yellow-primary"
                    data-dismiss="modal"
                    onClick={()=>{setFilterText("");setFilteredData([])}}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showIncubatorFailureModal && (
        // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
        // <>
        //     <div className="modal-backdrop fade show"></div>
        //     <div
        //         className="modal align-middle modal-sisfs d-block"
        //         id="FormSubmissionError"
        //     >
        //         <div className="modal-dialog modal-dialog-centered modal-md">
        //             <div className="modal-content">
        //                 <div className="modal-body text-center">
        //                     <img
        //                         src={error}
        //                         className="search mb-2"
        //                         alt="search"

        //                     />
        //                     <h3 className="modal-message">
        //                         {failDialogData.header}
        //                     </h3>
        //                     <h3 className="modal-text">
        //                         {failDialogData.text}
        //                     </h3>
        //                     <div className="modal-action">
        //                         <button className="btn btn-sisfs btn-yellow-primary" onClick={() => setShowIncubatorFailureModal(false)}>
        //                             OK!
        //           </button>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </>
        <ConfirmationDialog
          headerText={failDialogData.header}
          bodyText={failDialogData.text}
          onDialogClose={() => setShowIncubatorFailureModal(false)}
          iconClass="test"
        />
      )}
      {showIncubatorSuccessModal && (
        // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
        // <>
        //     <div className="modal-backdrop fade show"></div>

        //     <div
        //         className="modal align-middle modal-sisfs d-block"
        //         id="FormSubmissionSuccess"
        //     >
        //         <div className="modal-dialog modal-dialog-centered modal-md">
        //             <div className="modal-content">
        //                 <div className="modal-body text-center">
        //                     <i className="fa fa-check-circle-o"></i>
        //                     <h3 className="modal-message">Thank You!</h3>
        //                     <div className="modal-text">
        //                         Your Meeting Has been Scheduled
        //             </div>
        //                     <div className="modal-action">
        //                         <button className="btn btn-sisfs btn-yellow-primary" onClick={dismissModal}>
        //                             Ok!!
        //             </button>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </>
        <ConfirmationDialog
          headerText={dialogData.header}
          bodyText={dialogData.text}
          onDialogClose={dismissModal}
        />
      )}
      {showMeetingSuccessModal && (
        // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
        // <>
        //     <div className="modal-backdrop fade show"></div>

        //     <div
        //         className="modal align-middle modal-sisfs d-block"
        //         id="FormSubmissionSuccess"
        //     >
        //         <div className="modal-dialog modal-dialog-centered modal-md">
        //             <div className="modal-content">
        //                 <div className="modal-body text-center">
        //                     <i className="fa fa-check-circle-o"></i>
        //                     <h3 className="modal-message">Thank You!</h3>
        //                     <div className="modal-text">
        //                         Your Meeting Has been Scheduled
        //             </div>
        //                     <div className="modal-action">
        //                         <button className="btn btn-sisfs btn-yellow-primary" onClick={dismissModal}>
        //                             Ok!!
        //             </button>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </>
        <ConfirmationDialog
          headerText={dialogData.header}
          bodyText={dialogData.text}
          onDialogClose={dismissMeetingModal}
        />
      )}
      {showAddConfirmationBox && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionError"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  {/* <img
               src={error}
               className="search mb-2"
               alt="search"

             /> */}
                  <h3 className="modal-message mt-2">
                    {confirmationDialogData.header}
                  </h3>
                  <h3 className="modal-text">{confirmationDialogData.text}</h3>
                  <div className="modal-action mt-2">
                    <button
                      className="btn btn-sisfs btn-transparent-primary"
                      onClick={() => setShowAddConfirmationBox(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-sisfs btn-yellow-primary ml-2"
                      onClick={finalAddIncubator}
                    >
                      Schedule Anyway
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* gfusdfiuhsklfjskld


            <div className="container container-min pt-5">
                <div className="row mb-4">
                    <div className="col-md-6">
                        <p className="dashboard pt-2 pl-3">Meetings</p>
                    </div>
                </div>

                <div className="row incubator-heading-review">
                    <div className="col-md-6 text-left">
                        <ul className="nav nav-tabs chart-nav" role="tablist">
                            <li className="nav-item">
                                <a
                                    className="nav-link active"
                                    data-toggle="tab"
                                    href="#tabs-1"
                                    role="tab"
                                >
                                    Upcoming Meetings
                </a>
                            </li>
                            <div className="vertical-dash"></div>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    data-toggle="tab"
                                    href="#tabs-2"
                                    role="tab"
                                >
                                    Past Meetings
                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-6 text-right search-left">
                        <div className="d-flex searchDiv"> <img
                            src={search}
                            className="search"
                            alt="search"

                        />
                            <input
                                className="searchBar"
                                type="text"
                                placeholder="Search.."
                                name="search"
                                onKeyUp={searchList}
                            />
                        </div>

                    </div>
                </div>
                <div className="tab-content mb-4 mt-4">
                    <div className="tab-pane active" id="tabs-1">
                        <div className="table-scroll">
                            <table className="reviewTable">
                                <thead>
                                    <tr>
                                        <th className="text-left table-heading custom-td-left table-heading ">Meeting Type</th>
                                        <th className="text-left table-heading">Date & Time</th>
                                        <th className="text-left table-heading">Date Of Application Submitted</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {upcomingMeetingData.length == 0 || (filteredUpcomingMeetingData.length == 0 && noDataFilter != '') ? <tr><td className="text-center" colSpan={5}>No data</td></tr> :
                                        (filteredUpcomingMeetingData.length == 0 ?
                                            upcomingMeetingData.map((item: any) => (
                                                <tr>
                                                    <td className="text-left custom-td-left">{item.meeting_type == 1 ? "Startup Selection" : "Performance Evaluation"}</td>
                                                    <td className="text-left custom-td"><div >{FormatDate(item.meeting_date)}</div><span className="tableDataApplication">{formatTime(item.meeting_from_time)} - {formatTime(item.meeting_to_time)}</span></td>
                                                    <td className="text-left custom-td">{FormatDate(item.submitted_date)}</td>

                                                </tr>
                                            )) :
                                            filteredUpcomingMeetingData.map((item: any) => (
                                                <tr>
                                                    <td className="text-left custom-td-left">{item.meeting_type == 1 ? "Startup Selection" : "Performance Evaluation"}</td>
                                                    <td className="text-left custom-td"><div >{FormatDate(item.meeting_date)}</div><span className="tableDataApplication">{formatTime(item.meeting_from_time)} - {formatTime(item.meeting_to_time)}</span></td>
                                                    <td className="text-left custom-td">{FormatDate(item.submitted_date)}</td>

                                                </tr>
                                            ))
                                        )}



                                </tbody>

                            </table>
                        </div>


                    </div>
                    <div className="tab-pane" id="tabs-2">
                        <div className="table-scroll mb-5">
                            <table className="reviewTable">
                                <thead>
                                    <tr>
                                        <th className="text-left table-heading custom-td-left table-heading">Meeting Type</th>
                                        <th className="text-left table-heading">Date & Time</th>

                                        <th className="text-left table-heading">EAC Performance Review Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pastMeetingData.length == 0 || (filteredPastMeetingData.length == 0 && noDataFilter != '') ? <tr><td className="text-center" colSpan={5}>No data</td></tr> :
                                        (filteredPastMeetingData.length == 0 ?
                                            pastMeetingData.map((item: any) => (
                                                <tr>
                                                    <td className="text-left custom-td-left">{item.meeting_type == 1 ? "Startup Selection" : "Performance Evaluation"}</td>
                                                    <td className="text-left custom-td"><div >{FormatDate(item.meeting_date)}</div><span className="tableDataApplication">{formatTime(item.meeting_from_time)} - {formatTime(item.meeting_to_time)}</span></td>

                                                    <td className="text-left custom-td">{FormatDate(item.due_review_date)}</td>
                                                </tr>
                                            )) :
                                            filteredPastMeetingData.map((item: any) => (
                                                <tr>
                                                    <td className="text-left custom-td-left">{item.meeting_type == 1 ? "Startup Selection" : "Performance Evaluation"}</td>
                                                    <td className="text-left custom-td"><div >{FormatDate(item.meeting_date)}</div><span className="tableDataApplication">{formatTime(item.meeting_from_time)} - {formatTime(item.meeting_to_time)}</span></td>

                                                    <td className="text-left custom-td">{FormatDate(item.due_review_date)}</td>
                                                </tr>
                                            ))

                                        )}



                                </tbody>

                            </table>
                        </div>

                    </div>
                </div>


                <div className="modal fade" id="scheduleMeeting" role="dialog" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-slideout modal-sm" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body pl-5 pr-5 pt-5">
                                <div className="popup-head mb-5 ml-3">
                                    <div className="main-heading">Schedule a Meeting</div>
                                    <div className="count-text">2 Incubators Selected</div>
                                </div>

                                <label className="col-sm-4 col-form-head">
                                    Meeting Type
                        </label>
                                <div className="col-md-12 mb-4">
                                    <select className="form-control" aria-label="Select Entity Type" >
                                        <option value="1">Startup Selection</option>
                                        <option value="2">Performance Review Meeting</option>
                                    </select>
                                </div>
                                <label className="col-sm-4 col-form-head">
                                    Date & Time
                        </label>
                                <div className="col-md-6 mb-4">
                                    <Calendar
                                        id="registrationDate"
                                        name="registrationDate"
                                        className="form-control"
                                        format="dd/MM/yyyy"
                                        selectedDate={new Date()}
                                        onDateChange={(date: Date, field: string) =>
                                            handleDateChange(date, field)
                                        }
                                    />
                                </div>
                                <div className="row pl-3 mb-5">
                                    <div className="col-md-6">
                                        <label className="col-form-head">
                                            From Time
                        </label>
                                        <input type="time" name="fromTime" className="form-control" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="col-form-head">
                                            To Time
                        </label>
                                        <input type="time" name="toTime" className="form-control" />
                                    </div>
                                </div>
                                <div className="row pl-5">
                                    <button className="btn btn-sisfs btn-transparent-primary" data-dismiss="modal">
                                        CANCEL
              </button>
                                    <button className="tertiary  btn-yellow-primary ml-3" >
                                        SCHEDULE
                </button>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
    </>
  );
}
export default IncubatorMeetingComponent;
