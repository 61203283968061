import "../../../dpiit/incubator/incubator.css";
import Plus from "../../../../images/Plus.svg";
import Minus from "../../../../images/Minus.svg";
import React, { useEffect, useState, useRef } from "react";
import pdf from "../../../../images/PDF.svg";
import xls from "../../../../images/xls.svg";
import doc from "../../../../images/doc.svg";
import xlsx from "../../../../images/xls.svg";
import Allround from "../../../../images/Allround.svg";
// import TrancheStepperComponent from './TrancheStepperComponent';
import Stepper from "bs-stepper";
import "bs-stepper/dist/css/bs-stepper.min.css";
import {
  AddTranche,
  Documents,
  TrancheDetail,
} from "../../../../models/funddetails/TrancheDetails";
import {
  ADD_MORE_TRANCHE,
  ADD_TRANCHE_WITH_AMOUNT,
  DELETE_STARTUP_TRANCHE,
  File_node_url,
  GET_ALL_TRANCHES,
  GET_BANK_DETAILS,
  GET_TRANCHE_REQUEST,
  QUATERLY_REVIEW_FUNDDETAILS,
  QUICK_EDIT,
  STARTUP_ADD_TRANCHE_WITH_AMOUNT,
  STARTUP_GET_INCUBATORS,
  STARTUP_GET_INCUBATORS_BASE,
  TRANCHES_STARTUP_EDIT,
} from "../../../../constants/api";
import { get, post } from "../../../../effects/api/api.common";
import TrancheInfoComponent from "../../../startups/common/TrancheInfoComponent";
import TrancheWiseDetails from "../../../startups/common/TrancheWiseDetails";
// import { UploadTypeEnum } from '../../../../utils/Enums';
import {
  changeNumberFormat,
  createFile,
} from "../../../../utils/CommonMethods";
// import { FileDownloadModel } from './ApplicationGeneralDetails';
// import AccountDetailsComponent from '../../incubator/dashboard/AccountDetailsComponent';
import { StartupAccountDetailsData } from "../../../../models/funddetails/StartupAccountDetailsData";
import { useHistory } from "react-router-dom";
import moment from "moment";
import ConfirmationDialogNote from "../../../common/controls/ConfirmationDialogNote";
import { getSessionStorageValue } from "../../../../effects/utils/session-storage";
import SuccessCheck from "../../../../images/ReturnsIcons/check (2).svg";
declare var $: any;
interface IFundDetailsProps {
  application_id: number;
  total_grant_approved: number;
  remainingAmount: number;
  trancheData: any;
  trancheDataASC: any;
  accountDetailsData: StartupAccountDetailsData;
  application_status: string;
  total_grant_disbursed: number;
  trancheNumber?: Number;
  loggedInRole?: string;
  applicationDetails: any;
  statusId: any;
  evDate: any;
  isPrefer: any;
  flag: any;
  quaterlyReports: any;
  qrDetails: any;
  onChangeData: any;
  disbursedToStartup: any;
  disbursedToInc: any
}

function StartupFundDetailsComponent(props: IFundDetailsProps) {
  const {
    application_id,
    total_grant_approved,
    remainingAmount,
    trancheData,
    trancheDataASC,
    accountDetailsData,
    application_status,
    total_grant_disbursed,
    trancheNumber,
    loggedInRole,
    applicationDetails,
    statusId,
    evDate,
    isPrefer,
    flag,
    quaterlyReports,
    qrDetails,
    onChangeData,
    disbursedToStartup,
    disbursedToInc
  } = props;

  ////console.log(accountDetailsData, 'accountDetailsData');
  const [isTrancheStepper, setStepper] = useState<boolean>(true);
  const [QuaterlyBTN, setQuaterlyBTN] = useState(false);
  const [instrumentApproved, setInstrumentApproved] = useState<any>(false);
  const [activeIndex, setActiveIndex] = useState("");
  const [DisbusedAmount_Pending, setDisbusedAmount_Pending] = useState<any>(false);
  const [showIncubatorFailureModal, setShowIncubatorFailureModal] =
    useState(false);
  const approved = useRef(0);
  const funds = useRef(0);

  const user: any = getSessionStorageValue("user");
  const userInfo = JSON.parse(user);
  // const [sumOfSecond,setsumOfSecond]=useState(0)

  const [failDialogData, setFailDialogData] = useState({
    header: "",
    text: "",
    note: "",
  });
  const changeActive = (id: string) => {
    setActiveIndex(id);
  };
  const changeActiveBack = () => {
    setActiveIndex("");
  };
  const history = useHistory();
  var currentdatenew = moment().format("YYYY-MM-DD");

  const [triggerFunction, setTriggerfunction] = useState(false);
  const [startupAppId, setStartupAppID] = useState("");
  useEffect(() => {
    setStepper(true);

    //console.log(total_grant_approved,"108")

    // setRemainingAmount(remainingAmount == null ? "0" : remainingAmount.toString());
    // console.log(remainingAmount, "total_remaining_amount")
    //console.log(props,"sri@@")

    setEmployeeData(trancheData);
    props.onChangeData(startupAppId);
    // let sumSecond = employeeData.reduce(function(prev :any, current:any) {
    //   return prev + +current.approved_amount
    // }, 0);
    // setsumOfSecond(sumSecond)
    // {console.log("AMITHA",quaterlyReports)}
    if (qrDetails && qrDetails.length > 0 && statusId != 9) {
      setQuaterlyBTN(true);
    }
    // console.log(remainingAmount, "remainingAmount")
  }, [

    remainingAmount,

    triggerFunction,
  ]);
  useEffect(() => {
    setGrantApproved(
      total_grant_approved == null ? "0" : total_grant_approved.toString()
    );
  }, [total_grant_approved]);
  useEffect(() => {
    if (
      typeof applicationDetails?.data?.data?.Application_Details?.FundingDetails
        ?.instrument_approved != "undefined"
    ) {
      if (
        applicationDetails?.data?.data?.Application_Details?.FundingDetails
          ?.instrument_approved == 0
      ) {
        setInstrumentApproved(true);
      } else {
        setInstrumentApproved(false);
      }
    }
  }, [applicationDetails])
  const [total_grand_approved_amount, setGrantApproved] = useState("");
  // const [total_remaining_amount, setRemainingAmount] = useState("");

  const [addMoreTrancheData, setAddMoreTrancheData] = useState<AddTranche>(
    new AddTranche(0, "", 0, 0)
  );
  const [isAddTrancheRowVisible, setIsAddTrancheRowVisible] = useState(true);

  const [showEditSuccessModal, setShowSuccessModal] = useState(false);
  const [showEditFailureModal, setShowFailureModal] = useState(false);
  const [employeeData, setEmployeeData] = useState<any>([]);
  const [disbursedAmountPopup, setDisbursedAmountPopup] = useState(false)
  const [checkStatus, setCheckStatus] = useState<any>('0')

  const dismissModal = () => {
    setShowSuccessModal(false);
    setShowFailureModal(false);
    setdateEmptyPopup(false);
    setDisbursedAmountPopup(false)
    window.location.reload();
  };
  const handleTrancheDataChange = (updatedTrancheData: any) => {
    setEmployeeData(updatedTrancheData);
  };
  const addMoreTranche = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    // console.log(checkStatus,"responseeeeee")
    if (checkStatus == '1') {
      setDisbursedAmountPopup(true)
      return;
    }

    // return;
    let addTranche = JSON.parse(JSON.stringify(addMoreTrancheData));
    addTranche["application_id"] = application_id;
    setAddMoreTrancheData(addTranche);

    setIsAddTrancheRowVisible(false);
    let sum = employeeData.reduce(function (prev: any, current: any) {
      return prev + +current.approved_amount;
    }, 0);
    if (typeof addMoreTrancheData.amount_approved === "string") {
      addMoreTrancheData.amount_approved = Number(
        addMoreTrancheData.amount_approved
      );
    }
    if (addMoreTrancheData.amount_approved == 0) {
      setShowFailureModal(true);
    } else if (
      sum + addMoreTrancheData.amount_approved >
      total_grand_approved_amount
    ) {
      setShowFailureModal(false);

      setFailDialogData({
        header: "Oops",
        text: "Can’t create new tranche, approved amount is equal to total funds approved",
        note: "Note : If you still want to create a new tranche please check Total Funds Approved & Total Funds Disbursed (If Total Funds Disbursed is lesser than Total Funds Approved in existing tranches please decrease Total Funds Approved)",
      });
      setShowIncubatorFailureModal(true);
    } else {
      let addMoreTrancheDetails = {
        logged_user_id: userInfo.id,
        startup_application_id: application_id,
        approved_amount: addMoreTrancheData.amount_approved,
      };

      post(`${STARTUP_ADD_TRANCHE_WITH_AMOUNT}`, addMoreTrancheDetails).then(
        (response: any) => {
          if (!response || !response.data) return;
          if (response.data.result == "success") {
            props.onChangeData(application_id);
            setShowSuccessModal(true);
          } else {
            setShowFailureModal(true);
          }
          ////console.log(addMoreTrancheData, 'setaddMoreTrancheData');
          //getAllTranches();
        }
      );
    }
  };
  const cancel = () => {
    window.location.reload();
  };
  const [tranchIndex, setTrancheIndex] = useState("");
  const [trancheeId, setTrancheeId] = useState("");
  const [tranchApplication, setTranchApplication] = useState("");

  const deleteRowModal = (tranch_id: any, application_id: any, i: any) => {
    setTrancheeId(tranch_id);
    setTranchApplication(application_id);
    setTrancheIndex(i);
  };
  const deleteRow = (trancheeId: any, tranchApplication: any) => {
    var payload = {
      startup_tranche_id: trancheeId,
      startup_application_id: tranchApplication,
      logged_user_id: userInfo.id,
    };
    post(DELETE_STARTUP_TRANCHE, payload).then((res: any) => {
      if (!res || !res.data) return;
      window.location.reload();
    });
  };

  const handleChange = (
    evt: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const name = evt.target.name;
    const value = evt.target.value;
    // console.log(trancheData,"mohan")

    let trancheDetails = JSON.parse(JSON.stringify(trancheData));
    if (name == "amount_approved") {
      // console.log(evt.target.value,disbursedToStartup,disbursedToInc,"responseeeeee")
      // console.log((Number(evt.target.value)+ disbursedToStartup)>disbursedToInc,"responseeeeee")
      // if(
      //   (disbursedToStartup + Number(evt.target.value)) >= disbursedToInc
      // ){
      //   setCheckStatus('1')

      // }else{
      //   setCheckStatus('0')
      // }
      const numStr = String(evt.target.value);
      var count = 0;
      if (numStr.includes(".")) {
        count = numStr.split(".")[1].length;
      }
      if (count > 2) {
        trancheDetails[name] = Number(evt.target.value).toFixed(2);
      } else {
        trancheDetails[name] = evt.target.value.replace(/[^0-9.]/gi, "");
      }
    } else {
      trancheDetails[name] = evt.target.value.replace(/[^0-9.]/gi, "");
    }

    setAddMoreTrancheData(trancheDetails);
  };

  const handleChangeTotalGrantApproved = (
    evt: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const name = evt.target.name;
    const value = evt.target.value;

    let quickEdit = JSON.parse(JSON.stringify(quickEditPopupData));

    const numStr = String(evt.target.value);
    var count = 0;
    if (numStr.includes(".")) {
      count = numStr.split(".")[1].length;
    }
    if (count > 2) {
      quickEdit[name] = Number(evt.target.value).toFixed(2);
    } else {
      quickEdit[name] = evt.target.value.replace(/[^0-9.]/gi, "");
    }

    //quickEdit["total_approved_amt"] = value;
    setQuickEditPopupData(quickEdit);
  };

  const formatDate = (date: string) => {
    var month = new Array();
    month[0] = "Jan";
    month[1] = "Feb";
    month[2] = "Mar";
    month[3] = "April";
    month[4] = "May";
    month[5] = "Jun";
    month[6] = "Jul";
    month[7] = "Aug";
    month[8] = "Sep";
    month[9] = "Oct";
    month[10] = "Nov";
    month[11] = "Dec";

    var d = new Date(date),
      monthn = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (monthn.length < 2) monthn = "0" + monthn;
    if (day.length < 2) day = "0" + day;

    return [day, month[d.getMonth()], year].join(" ");
  };

  const [quickEditPopupData, setQuickEditPopupData] = useState({
    application_id: "",
    total_approved_amt: "",
    application_status: "",
    total_remaining_amount: "",
  });
  const updateTotalGrantApproved = () => {
    // console.log(remainingAmount,quickEditPopupData.total_approved_amt,"total@@@@")
    let ApprovedAmount: any = parseInt(quickEditPopupData.total_approved_amt);
    if (quickEditPopupData.total_remaining_amount < ApprovedAmount) {
      setFailDialogData({
        header: "Oops",
        text: "Insufficient Funds",
        note: "",
      });

      setShowIncubatorFailureModal(true);
    } else {
      var payload = {
        total_approved_amount: quickEditPopupData.total_approved_amt,
        logged_user_id: userInfo.id,
      };

      post(
        `${STARTUP_GET_INCUBATORS_BASE}/${application_id}/updateApprovedAmount`,
        payload
      ).then((response: any) => {
        if (!response || !response.data) return;
        // console.log(response.data,"dataaa")

        if (response.data.result == true) {
          setGrantApproved(quickEditPopupData.total_approved_amt);
        } else {
        }
        //console.log(response.data)
      });
    }
  };

  const setQuickEdit = (value: string, value1: any) => {
    let quickEdit = JSON.parse(JSON.stringify(quickEditPopupData));
    // console.log(value, value1, "quickEditPopupData")
    quickEdit["total_approved_amt"] = Number(value).toFixed();
    quickEdit["total_remaining_amount"] = Number(value1).toFixed();
    // console.log(quickEdit, "quic")
    setQuickEditPopupData(quickEdit);
    // console.log(quickEditPopupData, "quicke")
  };
  const checkInstrumentType = () => {
    // console.log(instrumentApproved,"instrumentApproved");
    // console.log(trancheData);
    const isEveryDisbursed = trancheData.some((item:any) => {
      return item.startup_tranches_statuses_label === "Disbursed" &&
             (item.approved_amount === null ||
              item.fund_released === null ||
              item.date_of_release === null);
  });
  // console.log(isEveryDisbursed);
      if (isEveryDisbursed) {
          console.log('Adiiiiiiiii');
          $("#Fill_Details").modal("show");
      }else{
        if (instrumentApproved) {
          setFailDialogData({
              header: "Oops",
              text: "Please Select Instrument Type",
              note: "",
          });
          setShowIncubatorFailureModal(true);
        } else {
            $("#YesOrNoOne").modal("show");
        }
      }
// console.log(DisbusedAmount_Pending);
  };

  const getFileDetails = (file: any, key: string) => {
    let file_name = "";
    let file_ext = "";
    file_name =
      file != undefined && file.length > 1
        ? file.substr(file.lastIndexOf("/") + 1, file.length)
        : "";
    file_ext =
      file != undefined && file.length > 1
        ? file.substr(file.lastIndexOf(".") + 1, file.length)
        : "";
    if (key == "ext") return file_ext;
    if (key == "name") return file_name;
    if (key == "file") return file;
  };

  const downloadDocument = (file: any, file_name: any) => {
    fetch(file).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = file_name;
        a.click();
      });
      //window.location.href = response.url;
    });
  };

  const [dateEmptyPopup, setdateEmptyPopup] = useState(false);
  //2nd Edit button clicked
  const addMoreTranche2Edit = (
    startup_tranche_id: any,
    startup_tranches_statuses_label: any,
    date_of_release: any
  ) => {
    // console.log(startup_tranche_id, "tranch_iddd")
    // if(date_of_release == null || date_of_release == ''){
    //   setdateEmptyPopup(true)
    // }
    if (startup_tranches_statuses_label !== "Disbursement in Process") {
      setFailDialogData({
        header: "Oops",
        text: "Tranche Status should be in Disbursement in Process",
        note: "",
      });
      setShowIncubatorFailureModal(true);
    } else {
      trancheData.forEach((element: any, index: any) => {
        if (element.startup_tranche_id == startup_tranche_id) {
          element.editchecked = "No";
        } else {
          element.editchecked = "Yes";
        }
      });
    }

    // setEmployeeData([...trancheData[0],trancheData[0]])
    setEmployeeData([...trancheData, trancheData]);
    const newArray = employeeData.splice(0, employeeData.length);
    setEmployeeData(newArray);
    //console.log(employeeData,"emp")
  };

  //2nd Handle change
  const handleChangeEdit2 = (e: any, startup_tranche_id: any) => {
    const { name, value } = e.target;
    var editData: any = [];
    editData = employeeData.map((item: any) =>
      item.startup_tranche_id === startup_tranche_id && name
        ? { ...item, [name]: value.replace(/[^0-9.]/gi, "") }
        : item
    );
    setEmployeeData(editData);
  };

  const addMoreTranche2Save = (item: any, i: any) => {
    // setChecked(false)
    // seteditChecked("No")
    // console.log(item,i,"addMoreTrancheData2")
    let totalfunds = total_grand_approved_amount;
    // let sum = employeeData.reduce(function(prev :any, current:any) {
    //   return prev + +current.fund_released
    // }, 0);
    let Approvedsum = employeeData.reduce(function (prev: any, current: any) {
      return prev + +current.approved_amount;
    }, 0);
    approved.current = parseInt(item.approved_amount);
    funds.current = parseInt(item.fund_released);
    // if (item.startup_tranches_statuses_label !== 'Disbursement in Process') {
    //   setFailDialogData({
    //     "header": "Oops",
    //     "text": "Tranche Status should be in Disbursement in Process",
    //     "note": ""
    //   })
    //   setShowIncubatorFailureModal(true);
    // }
    if (approved.current == 0) {
      setShowFailureModal(true);
    } else if (Approvedsum > totalfunds) {
      setFailDialogData({
        header: "Oops",
        text: "Entered Amount should be less than or equal to Total Funds Approved",
        note: "",
      });
      setShowIncubatorFailureModal(true);
    } else if (approved.current < funds.current) {
      setFailDialogData({
        header: "Oops",
        text: "Amount Disbursed should be less than or equal to Approved Amount",
        note: "",
      });
      setShowIncubatorFailureModal(true);
      // }
      // else if(sum > totalfunds){
      // setFailDialogData({
      //   "header":"Oops",
      //   "text":"Entered Amount should be lesser than Total Grant Approved & Total Management Fees"
      // })
      // setShowIncubatorFailureModal(true);
    } else {

      const payload = {
        application_id: item.startup_application_id,
        tranch_id: item.id,
        approved_amount: item.approved_amount,
        amount_disbursed: item.fund_released,
        logged_user_id: userInfo.id,
      };

      post(TRANCHES_STARTUP_EDIT, payload).then((response: any) => {
        if (!response || !response.data) return;
        //console.log(response.data,"resulttttt")
        if (response.data.result == "success") {
          props.onChangeData(item.startup_application_id);
          setShowSuccessModal(true);
        } else {
          setShowFailureModal(true);
        }
      });
    }
  };
  const ConformationClick = () => {
    // return;
    let payload = {
      status: 1,

      user_id: userInfo.id,

      startup_application_id: application_id,

      QRID: quaterlyReports.length != 0 ? quaterlyReports[0].id : "",

      quaterly_review_id: qrDetails[0].id,

      total_disbursed_amt: total_grant_disbursed,

      total_approved_amount: total_grand_approved_amount,

      no_of_tranches: trancheData.length,

      instrument_approved:
        applicationDetails.data.data.Application_Details.FundingDetails
          .instrument_approved,

      agreement_date:
        quaterlyReports.length != 0 ? quaterlyReports[0].agreement_date : "",
    };
    post(QUATERLY_REVIEW_FUNDDETAILS, payload).then((res: any) => {
      // console.log(res.data);
      if (res.data.result == "success") {
        window.location.reload();
      }
    });
  };
  const getAccountName = (type: number) => {
    if (type === undefined || !type) return "";
    let accountName = "";
    if (type === 1) {
      accountName = "Saving";
    } else if (type === 2) {
      accountName = "Current";
    } else if (type === 3) {
      accountName = "Salary";
    } else if (type === 4) {
      accountName = "Fixed Deposit";
    } else if (type === 5) {
      accountName = "Recurring Deposit";
    }
    return accountName;
  };

  return (
    <div className="container-fluid">
      <div className="row text-center">
        <div className="col-md-4">
          <span className="fund-amount-cur">
            &#8377;
            <label className="fund-amount-label-value">
              {/* {console.log(remainingAmount.toString(),"remain")} */}{" "}
              {total_grand_approved_amount != null &&
                total_grand_approved_amount != "0"
                ? changeNumberFormat(Number(total_grand_approved_amount), 2, 1)
                : 0}
            </label>
            {/* Cr */}
          </span>
          <br />
          <span className="fund-amount-label">Total Funds Approved</span>
          <br />
          {application_status == "Selected" &&
            loggedInRole == "incubator" &&
            evDate < currentdatenew &&
            ((isPrefer == "7" && statusId == "7" && flag == "1") ||
              statusId == "2" ||
              statusId == "3" ||
              statusId == "4" ||
              statusId == "5") ? (
            <a className="incubator-details-link">Edit</a>
          ) : application_status == "Closed" ||
            application_status == "Cancelled" ||
            loggedInRole == "secretariat" ? (
            <>
              {/* <a className="incubator-details-link"
            
            >Edit</a> */}
            </>
          ) : (
            <a
              className="incubator-details-link"
              data-target="#editTotalGrantApproved"
              data-toggle="modal"
              onClick={() => {
                setQuickEdit(
                  total_grand_approved_amount.toString(),
                  remainingAmount.toString()
                );
              }}
            >
              Edit
            </a>
          )}
        </div>
        <div className="vl vl-mr"></div>
        <div className="col-md-3">
          <span className="fund-amount-cur">
            <label className="fund-amount-label-value">
              {/* {trancheNumber} */}
              {trancheData != undefined && trancheData.length > 0
                ? trancheData.length
                : 0}
            </label>
          </span>
          <br />

          <span className="fund-amount-label">Total No. of Tranches</span>
          <br />
          {loggedInRole == "incubator" &&
            evDate < currentdatenew &&
            ((isPrefer == "7" && statusId == "7" && flag == "1") ||
              statusId == "2" ||
              statusId == "3" ||
              statusId == "4" ||
              statusId == "5") ? (
            <a className="incubator-details-link">Add More Tranche</a>
          ) : application_status == "Closed" ||
            application_status == "Cancelled" ||
            loggedInRole == "secretariat" ? (
            <>
              {/* <a className="incubator-details-link"
            
            >Edit</a> */}
            </>
          ) : (
            <a
              className="incubator-details-link"
              data-target="#addMoreTranchePopUpStart"
              data-toggle="modal"
              onClick={() => {
                // console.log(trancheData.length,"725")
                if (trancheData.length < 5) {
                  setIsAddTrancheRowVisible(true);
                } else {
                  setIsAddTrancheRowVisible(false); 
                }
              }}
            >
              Add More Tranche
            </a>
          )}
        </div>

        <div className="vl vl-mr"></div>
        <div className="col-md-4">
          <span className="fund-amount-cur">
            &#8377;
            <label className="fund-amount-label-value">
              {" "}
              {total_grant_disbursed != null
                ? changeNumberFormat(
                  Number(total_grant_disbursed.toString()),
                  2,
                  1
                )
                : 0}{" "}
            </label>
          </span>
          <br />
          <span className="fund-amount-label">Total Funds Disbursed</span>
          <br />
          {(loggedInRole == "incubator" || loggedInRole == "secretariat") && (
            <a
              className="incubator-details-link"
              data-target="#viewBankDetails"
              data-toggle="modal"
            >
              View Bank Details
            </a>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {/* {console.log("@@@@@@@@@@ Am", trancheDataASC)} */}
          {/* {trancheDataASC != undefined &&
            trancheDataASC.length > 0
            ?  */}
          <TrancheInfoComponent
            applicationDetails={applicationDetails}
            trancheDetailsModel={trancheDataASC}
            statusId1={statusId}
            evDate1={evDate}
            isPrefer1={isPrefer}
            sel={flag}
            applicationStatusId={application_status}
            userRoles={loggedInRole}
          />
          {/* : null} */}
        </div>
      </div>

      <div className="row ">
        <div className="col-md-12 left-align">
          <span className="eac-heading-title">No. of Tranches</span>
        </div>
      </div>
      <div className="application-incubator-hr">
        <hr />
      </div>

      <div className="row">
        <div className="col-md-12">
          <TrancheWiseDetails
            loggedInRole={loggedInRole}
            trancheDetailsModel={trancheData}
            total_grant_approved={total_grant_approved}
            instrumentStatus={instrumentApproved}
            statusId2={statusId}
            evDate2={evDate}
            isPrefer2={isPrefer}
            sel1={flag}
            applicationStatusId={application_status}
            applicationIDDD={(id: any) => setStartupAppID(id)}
            onTrancheDataChange={() => {
              setTriggerfunction((prev) => !prev);
            }}
            disbursed_to_startups={props.disbursedToStartup}
            disbursed_to_inc={props.disbursedToInc}
          // sumOfSecond={sumOfSecond}
          />
        </div>
      </div>
      {loggedInRole == "secretariat" ? (
        <></>
      ) : (
        <>
          {QuaterlyBTN == true && (
            <div className="row mb-3">
              <div className="col-6"></div>
              <div className="col-6 d-flex justify-content-end">
                {/* disabled={quaterlyReports.length == 0 || (quaterlyReports.length && (quaterlyReports[0].fund_details_review == "1" || quaterlyReports[0].progress_overview_review == "0"))} */}
                {/* {console.log("AMITHA",quaterlyReports)} */}
                {(quaterlyReports.length &&
                  quaterlyReports[0].fund_details_review == "0") ||
                  quaterlyReports.length == 0 ? (
                  <button
                    onClick={checkInstrumentType}
                    className="btn btn-sisfs btn-yellow-primary"
                    type="submit"
                    style={{ float: "right" }}
                  >
                    {/* data-target="#YesOrNoOne" data-toggle="modal" */}
                    {/* {console.log("AMITHA1",quaterlyReports[0].fund_details_review)} */}
                    <div className="d-flex flex-row justify-content-between">
                      <span>Confirm Quarterly Review</span>
                    </div>
                  </button>
                ) : (
                  <button
                    className="btn btn-sisfs btn-yellow-primary"
                    type="submit"
                    style={{ float: "right" }}
                    disabled={
                      quaterlyReports.length &&
                      quaterlyReports[0].fund_details_review == "1"
                    }
                  >
                    <div className="d-flex flex-row justify-content-between">
                      <span>Quarterly Review Confirmed</span>
                      {quaterlyReports.length &&
                        quaterlyReports[0].fund_details_review == "1" &&
                        true ? (
                        <img
                          src={SuccessCheck}
                          alt="SISFS Logo"
                          style={{ marginRight: "15px", cursor: "pointer" }}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </button>
                )}
              </div>
            </div>
          )}
        </>
      )}
      {/* Total Grant Approved Edit pop up */}
      <div
        className="modal fade"
        id="editTotalGrantApproved"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none">
              <h5 className="modal-title " id="exampleModalLongTitle">
                Update Total Funds Approved{" "}
                <span className="eac-name-pop-up"></span>
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12 text-left">
                  <span className="col-form-head">Total Funds Approved</span>
                </div>
              </div>
              <div className="row mt-1-5">
                <div className="col-md-12 text-left">
                  <input
                    // type="number"
                    className="form-control"
                    name="total_approved_amt"
                    value={quickEditPopupData.total_approved_amt}
                    placeholder="Enter total funds approved"
                    onChange={handleChangeTotalGrantApproved}
                  ></input>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 text-right">
                  <div className="btn-group mt-1-5 ">
                    <button
                      className="tertiary   btn-transparent-primary"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>

                    <button
                      type="button"
                      className="btn btn-sisfs btn-yellow-primary ml-2"
                      onClick={() => updateTotalGrantApproved()}
                      data-dismiss="modal"
                    >
                      Update{" "}
                    </button>
                  </div>
                </div>
              </div>

              <div className="row pt-3 mb-3">
                <div className="col-md-12 centerText"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* //Add more tranche */}

      <div
        className="modal fade"
        id="addMoreTranchePopUpStart"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none ml-18px">
              <h5 className="modal-title pl-2" id="exampleModalLongTitle">
                Add More Tranche<span></span>
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  &times;
                </span>
              </button>
            </div>
            <div className="modal-body mt-15-px">
              <div className="container">
                <div className="row">
                  <div className="col-md-3 left-align">
                    <span className="fund-amount-cur">
                      &#8377;
                      <span className="fund-amount-label-value">
                        {" "}
                        {/* {total_grand_approved} */}
                        {total_grand_approved_amount != null
                          ? changeNumberFormat(
                            Number(total_grand_approved_amount),
                            2,
                            1
                          )
                          : 0}{" "}
                      </span>
                    </span>
                    <br />
                    <span className="fund-amount-label">
                      Total Funds Approved
                    </span>
                  </div>

                  <div className="col-md-3 left-align">
                    <span className="fund-amount-cur">
                      <span className="fund-amount-label-value">
                        {/* {trancheData.length}{' '} */}
                        {trancheData != undefined &&
                          trancheData.length > 0 &&
                          trancheData.length}
                      </span>
                    </span>
                    <br />
                    <span className="fund-amount-label">
                      Total No. of Tranches
                    </span>
                  </div>

                  <div className="col-md-4 right">
                    <br />
                    <a
                      className="incubator-details-link"
                      // onClick={() => setIsAddTrancheRowVisible(true)}
                      onClick={() => {                      
                        if (trancheData.length < 5) {
                          setIsAddTrancheRowVisible(true); 
                        } else {
                          setIsAddTrancheRowVisible(false); 
                        }
                      }}
                    >
                      +Add More Tranches{" "}
                    </a>
                  </div>
                </div>

                <div className="row">
                  <div className="table-scroll ">
                    <table id="example">
                      <thead>
                        <tr>
                          <th className="text-left custom-td-left table-heading">
                            Tranche Request
                          </th>
                          <th className="text-left custom-td-left table-heading">
                            Funds Approved
                          </th>
                          <th className="text-left table-heading">
                            Funds Disbursed
                          </th>
                          <th className="text-left table-heading"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {isAddTrancheRowVisible == true ? (
                          <tr>
                            <td className="text-left ">
                              <span className="fund-amount-label">
                                {" "}
                                {/* Tranche {trancheData.length + 1} */}Tranche{" "}
                                {trancheData != undefined &&
                                  trancheData.length > 0 &&
                                  trancheData.length + 1}
                              </span>
                            </td>
                            <td className="text-left custom-td-left">
                              {/* {console.log(addMoreTrancheData.amount_approved,"approveddd")} */}
                              <input
                                type="input"
                                placeholder="Enter"
                                name="amount_approved"
                                className="form-control input-height"
                                onChange={handleChange}
                                value={addMoreTrancheData.amount_approved}
                              />
                            </td>
                            <td className="text-left custom-td-left"></td>
                            <td>
                              <a
                                className="incubator-details-link"
                                href="#"
                                // data-dismiss="modal"
                                onClick={() => addMoreTranche()}
                              >
                                Save
                              </a>
                              |
                              <a
                                className="incubator-details-link"
                                href=""
                                onClick={() => cancel()}
                                data-dismiss="modal"
                              >
                                Cancel
                              </a>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {/* {console.log(employeeData,"employeeeDataaaaaaaaaaa")} */}
                        {employeeData != undefined && employeeData.length > 0
                          ? employeeData.map((item: any, i: any) => (
                            <tr key={i}>
                              <td className="text-left">
                                <span className="incubator-label-value">
                                  {item.tranche_number}
                                </span>
                                <br />
                                <span className="incubator-label">
                                  {" "}
                                  {item.tranche_requested_date != null
                                    ? formatDate(item.tranche_requested_date)
                                    : "-"}
                                </span>
                              </td>
                              <td className="text-left ">
                                <span className="incubator-label-value">
                                  {" "}
                                  <input
                                    type="input"
                                    placeholder="Enter"
                                    name="approved_amount"
                                    className="form-control input-height"
                                    onChange={(e) =>
                                      handleChangeEdit2(
                                        e,
                                        item.startup_tranche_id
                                      )
                                    }
                                    value={
                                      item.editchecked == "Yes"
                                        ? changeNumberFormat(
                                          Number(item.approved_amount),
                                          2,
                                          1
                                        )
                                        : item.approved_amount
                                    }
                                    disabled={item.editchecked == "Yes"}
                                  />
                                  {/* {changeNumberFormat(Number(item.approved_amount), 2, 1)} */}
                                </span>
                                <br />
                                {item.approved_amount != null ? (
                                  <span className="incubator-label">
                                    {" "}
                                    {item.approved_amount_date != null
                                      ? formatDate(item.approved_amount_date)
                                      : "-"}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td className="text-left ">
                                <span className="incubator-label-value">
                                  {" "}
                                  <input
                                    type="input"
                                    placeholder="Enter"
                                    name="fund_released"
                                    className="form-control input-height"
                                    onChange={(e) =>
                                      handleChangeEdit2(
                                        e,
                                        item.startup_tranche_id
                                      )
                                    }
                                    value={
                                      item.editchecked == "Yes"
                                        ? changeNumberFormat(
                                          Number(item.fund_released),
                                          2,
                                          1
                                        )
                                        : item.fund_released
                                    }
                                    disabled={item.editchecked == "Yes"}
                                  />
                                  {/* {changeNumberFormat(Number(item.fund_released), 2, 1)} */}
                                </span>
                                <br />
                                {item.fund_released != null ? (
                                  <span className="incubator-label">
                                    {" "}
                                    {item.date_of_release != null
                                      ? formatDate(item.date_of_release)
                                      : "-"}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td className="text-left custom-td-left">
                                {item.editchecked == "Yes" ? (
                                  <a
                                    className="incubator-details-link"
                                    // href="#"
                                    onClick={() =>
                                      addMoreTranche2Edit(
                                        item.startup_tranche_id,
                                        item.startup_tranches_statuses_label,
                                        item.date_of_release
                                      )
                                    }
                                  >
                                    Edit
                                  </a>
                                ) : null}
                                {/* {console.log(item.editchecked,"editttttttttt")} */}
                                {item.editchecked == "No" ? (
                                  <a
                                    className="incubator-details-link"
                                    // href="#"
                                    onClick={() =>
                                      addMoreTranche2Save(item, i)
                                    }
                                  >
                                    Save
                                  </a>
                                ) : null}
                                |
                                {item.startup_tranches_statuses_label ==
                                  "Disbursed" ? (
                                  <>
                                    <a
                                      className="incubator-details-link"
                                      onClick={() => cancel()}
                                      data-dismiss="modal"
                                    >
                                      Cancel
                                    </a>
                                  </>
                                ) : item.editchecked == "Yes" ? (
                                  <a
                                    className="incubator-details-link"
                                    onClick={() =>
                                      deleteRowModal(
                                        item.startup_tranche_id,
                                        item.startup_application_id,
                                        i
                                      )
                                    }
                                    data-target="#deleteReapplyTranch"
                                    data-toggle="modal"
                                  // data-dismiss="modal"
                                  >
                                    Delete
                                  </a>
                                ) : item.editchecked == "No" ? (
                                  <a
                                    className="incubator-details-link"
                                    onClick={() => cancel()}
                                    data-dismiss="modal"
                                  >
                                    Cancel
                                  </a>
                                ) : null}
                                {/* <a
                                  className="incubator-details-link"
                                  href=""
                                  onClick={() => cancel()}
                                  data-dismiss="modal"
                                >
                                  Cancel
                                </a> */}
                                {/* <a
                                      className="incubator-details-link"
                                      href=""
                                      onClick={() =>
                                        handleRowChange(item.application_id)
                                      }
                                      data-dismiss="modal"
                                    >
                                      View
                                    </a> */}
                              </td>
                            </tr>
                          ))
                          : ""}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row pt-3 mb-3">
                <div className="col-md-12 centerText">
                  <button
                    className="tertiary  btn-yellow-primary"
                    data-dismiss="modal"
                    onClick={cancel}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {delete reapply tranch modal} */}
      <div
        className="modal fade"
        id="deleteReapplyTranch"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h5
                className="modal-title text-center"
                id="exampleModalLongTitle"
                style={{ color: "#E26640" }}
              >
                Are you sure you want to delete Tranche
              </h5>
              <br />
              <div className="row">
                <div className="col-md-12 text-center">
                  <div className="btn-group mt-1-5 ">
                    <button
                      className="tertiary btn-transparent-primary"
                      data-dismiss="modal"
                      style={{ width: "107.17px" }}
                    >
                      Cancel
                    </button>

                    <button
                      type="button"
                      className="btn btn-sisfs btn-yellow-primary ml-2"
                      // data-dismiss="modal"
                      onClick={() => deleteRow(trancheeId, tranchApplication)}
                    >
                      Delete{" "}
                    </button>
                  </div>
                </div>
              </div>

              <div className="row pt-3 mb-3">
                <div className="col-md-12 centerText"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* //View Bank Details */}

      <div
        className="modal fade"
        id="viewBankDetails"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none ml-18px">
              <h5 className="modal-title pl-2" id="exampleModalLongTitle">
                View Bank Details<span></span>
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body text-left">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <span className="incubator-label-value">
                      Account Holder
                    </span>
                  </div>
                </div>

                <div className="row mt-1-5">
                  <div className="col-md-6 left">
                    <span className="incubator-label">Account Name </span>
                  </div>
                  <div className="col-md-6 left">
                    <span className="incubator-label">
                      Proof of PFMS Registration
                    </span>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-6 left">
                    <span className="incubator-label-value">
                      {accountDetailsData.bank_account_holder_name == "" ||
                        accountDetailsData.bank_account_holder_name == null
                        ? "-"
                        : accountDetailsData.bank_account_holder_name}
                    </span>
                  </div>
                  <div className="col-md-6 left">
                    {accountDetailsData.pan_card != undefined &&
                      accountDetailsData.pan_card != null &&
                      accountDetailsData.pan_card_name != null ? (
                      <div className="row">
                        <div className="col-md-3">
                          {getFileDetails(
                            accountDetailsData.pan_card.file,
                            "ext"
                          ) == "pdf" ? (
                            <img src={pdf} alt="SISFS Logo" />
                          ) : getFileDetails(
                            accountDetailsData.pan_card.file,
                            "ext"
                          ) == "doc" ? (
                            <img src={doc} alt="SISFS Logo" />
                          ) : getFileDetails(
                            accountDetailsData.pan_card.file,
                            "ext"
                          ) == "xls" ? (
                            <img src={xls} alt="SISFS Logo" />
                          ) : (
                            <img src={Allround} alt="SISFS Logo" />
                          )}
                        </div>
                        <div className="col-md-9">
                          <span className="document-label-value">
                            {getFileDetails(
                              accountDetailsData.pan_card.file,
                              "name"
                            )}
                          </span>
                          <br />
                          <br />
                          <a
                            href={getFileDetails(
                              File_node_url + accountDetailsData.pan_card.file,
                              "file"
                            )}
                            target="_blank"
                            //download="pan_card"
                            className="incubator-details-link"
                          >
                            View
                          </a>
                          {/* /
                          <a
                            href="#"
                            //download="pan_card"
                            className="incubator-details-link"
                            onClick={() =>
                              downloadDocument(
                                accountDetailsData.pan_card.file,
                                accountDetailsData.pan_card_name
                              )
                            }
                          >
                            Download
                          </a> */}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="row mt-1-5">
                  <div className="col-md-6 left">
                    <span className="incubator-label">
                      Proof of TRA Account & ECS Mandate Form
                    </span>
                    <br />
                    {accountDetailsData.cancelled_cheque_location !=
                      undefined &&
                      accountDetailsData.cancelled_cheque_location != null ? (
                      <div className="row">
                        <div className="col-md-3">
                          {getFileDetails(
                            accountDetailsData.cancelled_cheque.file,
                            "ext"
                          ) == "pdf" ? (
                            <img src={pdf} alt="SISFS Logo" />
                          ) : getFileDetails(
                            accountDetailsData.cancelled_cheque.file,
                            "ext"
                          ) == "doc" ? (
                            <img src={doc} alt="SISFS Logo" />
                          ) : getFileDetails(
                            accountDetailsData.cancelled_cheque.file,
                            "ext"
                          ) == "xls" ? (
                            <img src={xls} alt="SISFS Logo" />
                          ) : (
                            <img src={Allround} alt="SISFS Logo" />
                          )}
                        </div>
                        <div className="col-md-9">
                          <span className="document-label-value">
                            {getFileDetails(
                              accountDetailsData.cancelled_cheque.file,
                              "name"
                            )}
                          </span>
                          <br />
                          <br />
                          <a
                            href={getFileDetails(
                              File_node_url +
                              accountDetailsData.cancelled_cheque.file,
                              "file"
                            )}
                            target="_blank"
                            //download="proof_of_disbursement"
                            className="incubator-details-link"
                          >
                            View
                          </a>
                          {/* /
                          <a
                            href="#"
                            //download="proof_of_disbursement"
                            className="incubator-details-link"
                            onClick={() =>
                              downloadDocument(
                                accountDetailsData.cancelled_cheque.file,
                                accountDetailsData.cancelled_cheque_location
                              )
                            }
                          >
                            Download
                          </a> */}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-4"></div>
                </div>
                <div className="application-incubator-hr">
                  <hr />
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <span className="incubator-label-value">
                      Account Details
                    </span>
                  </div>
                </div>

                <div className="row mt-1-5">
                  <div className="col-md-6 left">
                    <span className="incubator-label">Bank Name</span>
                  </div>
                  <div className="col-md-4">
                    <span className="incubator-label">Account Number</span>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-6 left">
                    <span className="incubator-label-value">
                      {accountDetailsData.bank_name == "" ||
                        accountDetailsData.bank_name == null
                        ? "-"
                        : accountDetailsData.bank_name}
                    </span>
                  </div>
                  <div className="col-md-6">
                    <span className="incubator-label-value">
                      {accountDetailsData.account_number == "0" ||
                        accountDetailsData.account_number == null
                        ? "-"
                        : accountDetailsData.account_number}
                    </span>
                  </div>
                </div>

                <div className="row mt-1-5">
                  <div className="col-md-6 left">
                    <span className="incubator-label">Account Type</span>
                  </div>
                  <div className="col-md-6 left">
                    <span className="incubator-label">
                      Branch Name/Branch Code
                    </span>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-6 left">
                    <span className="incubator-label-value">
                      {accountDetailsData.account_type == 0 ||
                        accountDetailsData.account_type == null
                        ? "-"
                        : getAccountName(accountDetailsData.account_type)}
                    </span>
                  </div>
                  <div className="col-md-6 left">
                    <span className="incubator-label-value">
                      {accountDetailsData.branch_name == "" ||
                        accountDetailsData.branch_name == null
                        ? "-"
                        : accountDetailsData.branch_name}
                    </span>
                  </div>
                </div>

                <div className="row mt-1-5">
                  <div className="col-md-6 left">
                    <span className="incubator-label">IFSC Code</span>
                  </div>
                  <div className="col-md-6 left">
                    <span className="incubator-label">MICR Code</span>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-6 left">
                    <span className="incubator-label-value">
                      {accountDetailsData.bank_ifsc_code == "" ||
                        accountDetailsData.bank_ifsc_code == null
                        ? "-"
                        : accountDetailsData.bank_ifsc_code}
                    </span>
                  </div>
                  <div className="col-md-6 left">
                    <span className="incubator-label-value">
                      {accountDetailsData.bank_micr_code == "" ||
                        accountDetailsData.bank_micr_code == null
                        ? "-"
                        : accountDetailsData.bank_micr_code}
                    </span>
                  </div>
                </div>

                <div className="row mt-1-5">
                  <div className="col-md-6 left">
                    <span className="incubator-label">Bank Address</span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 left">
                    <span className="incubator-label-value">
                      {accountDetailsData.branch_address == "" ||
                        accountDetailsData.branch_address == null
                        ? "-"
                        : accountDetailsData.branch_address}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row pt-3 mb-3">
                <div className="col-md-12 centerText">
                  <button
                    className="tertiary  btn-yellow-primary"
                    data-dismiss="modal"
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showEditSuccessModal && (
        <>
          <div className="modal-backdrop fade show"></div>

          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionSuccess"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <i className="fa fa-check-circle-o"></i>
                  <h3 className="modal-message">Thank You !</h3>
                  <div className="modal-text">
                    Tranche Details Updated Successfully !!
                  </div>
                  <div className="modal-action">
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={dismissModal}
                    // onClick={setShowSuccessModal(false)}
                    >
                      Ok !!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showEditFailureModal && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionError"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  {/* <i className="fa fa-times-circle-o"></i> */}
                  <h3 className="modal-message message-orange">
                    Oops! The Amount Should be Greater than 0
                  </h3>
                  <br />
                  <h3 className="modal-message message-orange">
                    Please Try Again !!!
                  </h3>
                  <div className="modal-action">
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={dismissModal}
                    >
                      OK !
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {dateEmptyPopup && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionError"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  {/* <i className="fa fa-times-circle-o"></i> */}
                  <h3 className="modal-message message-orange">Oops!</h3>
                  <br />
                  <h3 className="modal-message message-orange">
                    Please update the Date of Disbursed
                  </h3>
                  <div className="modal-action">
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={dismissModal}
                    >
                      OK !
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {disbursedAmountPopup && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal align-middle modal-sisfs d-block"
            id="FormSubmissionError"
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content">
                <div className="modal-body text-center">
                  {/* <i className="fa fa-times-circle-o"></i> */}
                  <h3 className="modal-message">
                    Oops!
                  </h3>
                  <br />
                  <h6 className="message-orange" style={{ lineHeight: '1.5' }}>
                    You can't change the status of the tranche as total amount disbursed
                    to the incubator cannot be less than the total amount disbursed to the startups.
                  </h6>
                  <div className="modal-action">
                    <button
                      className="btn btn-sisfs btn-yellow-primary"
                      onClick={dismissModal}
                    >
                      OK !
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* Fill the Details */}
      <div
        className="modal fade"
        id="Fill_Details"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div
              className="modal align-middle modal-sisfs d-block"
              id="FormSubmissionError"
            >
              <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                  <div style={{ textAlign: "right" }}>
                    <i
                      className="fa fa-close"
                      data-dismiss="modal"
                      style={{
                        cursor: "pointer",
                        fontSize: "30px",
                        marginRight: "15px",
                        marginTop: "15px",
                        color: "#D3D3D3",
                      }}
                    ></i>
                  </div>
                  <div className="modal-body text-center pt-0">
                    <h4 className="modal-message" style={{ color: "#2b3f88" }}>
                      Please Fill the Disbursed Details
                    </h4>
                    <div className="modal-action">
                      <button
                        className="btn btn-sisfs btn-yellow-primary ml-3"
                        data-dismiss="modal"
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Conformation Poppup */}
      <div
        className="modal fade"
        id="YesOrNoOne"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div
              className="modal align-middle modal-sisfs d-block"
              id="FormSubmissionError"
            >
              <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                  <div style={{ textAlign: "right" }}>
                    <i
                      className="fa fa-close"
                      data-dismiss="modal"
                      style={{
                        cursor: "pointer",
                        fontSize: "30px",
                        marginRight: "15px",
                        marginTop: "15px",
                        color: "#D3D3D3",
                      }}
                    ></i>
                  </div>
                  <div className="modal-body text-center pt-0">
                    <h4 className="modal-message" style={{ color: "#2b3f88" }}>
                      Are You Sure You Want To Continue
                    </h4>
                    <div className="modal-action">
                      <button
                        className="btn btn-sisfs btn-yellow-primary"
                        onClick={() => ConformationClick()}
                      >
                        Yes
                      </button>
                      <button
                        className="btn btn-sisfs btn-yellow-primary ml-3"
                        data-dismiss="modal"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showIncubatorFailureModal && (
        <ConfirmationDialogNote
          headerText={failDialogData.header}
          bodyText={failDialogData.text}
          bodyNote={failDialogData.note}
          onDialogClose={() => setShowIncubatorFailureModal(false)}
          iconClass="showImage"
        />
      )}
    </div>
  );
}
export default StartupFundDetailsComponent;
