import { FileUploadModel, FileUploadTypeModel } from "../FileUploadModel";

export class UploadDocumentsModel {
  constructor(
    public action: string,
    public upload_pitch_deck: Array<FileUploadTypeModel>,
    public video_url_for_product: string,
    public upload_video_for_product: Array<FileUploadTypeModel>,
    public upload_relavent_document_other: Array<FileUploadTypeModel>,  
  ) {
    this.action = action;
    this.upload_pitch_deck = upload_pitch_deck;
    this.video_url_for_product = video_url_for_product;
    this.upload_video_for_product = upload_video_for_product;
    this.upload_relavent_document_other = upload_relavent_document_other;
  }
}
