import { FileUploadModel } from "../FileUploadModel";

export class PortfolioDetailsModel {
    constructor(
        public incubator_name: string, 
        public site_url: string,
        public location_state: any,
        public location_city: any,
        public sectors: any,
        public message: any,
        public key_features: any,
        public key_features1: any,
        public key_features2: any,
        public key_features3: any,
        public key_features4: any,
        public incubator_email: any,
        public logo_image : Array<FileUploadModel>,
        public cover_image : Array<FileUploadModel>,
        public gallery_pictures : Array<FileUploadModel>,
        public remaining_pictures :"",
        public remaining_logo : "",
        public remaining_banner : "",
        public flag:any,
        public flag1: any,
        public flag2:any,
        public flag3:any,
        public Id:any
    ){
        this.incubator_name = incubator_name;
        this.site_url= site_url;
        this.location_state= location_state;
        this.location_city= location_city;
        this.sectors= sectors;
        this.message= message;
        this.key_features= key_features;
        this.key_features1= key_features1;
        this.key_features2= key_features2;
        this.key_features3= key_features3;
        this.key_features4= key_features4;
        this.incubator_email= incubator_email;
        this.logo_image = logo_image;
        this.cover_image = cover_image;
        this.gallery_pictures = gallery_pictures;
        this.flag= false;
        this.flag1=false;
        this.flag2=false;
        this.flag3=false;
        this.Id = Id;
    }
}