import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import About from "../components/about/About_us";
import FooterComponent from "../components/common/footer/FooterComponent";
import HeaderComponent from "../components/common/header/HeaderComponent";
import LoginComponent from "../components/common/login/LoginComponent";
import ContactContainer from "../components/contact/ContactContainer";
import ErrorContainer from "../components/error/error";
import Faq from "../components/faq/faq";
import GlossaryContainer from "../components/glossary/GlossaryContainer";
import HomeContainer from "../components/home/HomeContainer";
import Portfolio from "../components/portfolio/portfolio";
import PortfolioDetail from "../components/portfolio/portolioDetail";
import StartupPortfolio from "../components/portfolio/startupPortfolio";
import StartupPortfolioDetail from "../components/portfolio/startupPortfolioDesc";
const LayoutRouterComponent = () => {
  return (
    <>
      {/* App Header */}
      <HeaderComponent />

      <Switch>
        <Route path="/about" component={About} />

        <Route exact path="/portfolio_detail" component={PortfolioDetail} />

        <Route path="/portfolio" component={Portfolio} />
        <Route path="/startup_portfolio" component={StartupPortfolio} />

        <Route
          path="/startup_portfolio_detail"
          component={StartupPortfolioDetail}
        />
        <Route path="/faq" component={Faq} />

        <Route path="/contact" component={ContactContainer} />

        {/* <Route path="/incubator" component={IncubatorContainer} /> */}

        <Route path="/glossary" component={GlossaryContainer} />

        <Route path="/error" component={ErrorContainer} />

        <Route path="/" component={HomeContainer} />
      </Switch>

      {/* App Footer */}
      <FooterComponent />
    </>
  );
};

export default LayoutRouterComponent;
