export class FileUploadModel {
  constructor(
    public file_id: number,
    public file_type_id: number,
    public file: File

  ) {
    this.file_id = file_id;
    this.file_type_id = file_type_id;
    this.file = file;

  }
}

export class FileUploadTypeModel {
  constructor(
    public file_id: number,
    public file_type: string,
    public file: File

  ) {
    this.file_id = file_id;
    this.file_type = file_type;
    this.file = file;

  }
}

export class TrancheFileUploadModel {
  constructor(
    public file_id: number,
    public file_type_id: number,
    public file: File,
    public ext: string
  ) {
    this.file_id = file_id;
    this.file_type_id = file_type_id;
    this.file = file;
    this.ext = ext;
  }
}

export class AccountFileUploadModel {
  constructor(
    public file: File
  ) {

    this.file = file;

  }
}

export class FileModel {
  constructor(
    public file_id: number,
    public file_name: string,
    public file_byte_array: ArrayBuffer,
    public path: string,
    public type: string
  ) {
    this.file_id = file_id;
    this.file_name = file_name;
    this.file_byte_array = file_byte_array;
    this.path = path;
    this.type = type;
  }
}