import React, { useState, useEffect } from 'react';
import SeedFundSchemeLogo from '../../../images/SeedFundSchemeLogo.svg';
import { BrowserRouter as Router, Route, Link, NavLink } from 'react-router-dom';
import LoginComponent from '../../common/login/LoginComponent';
import ProfileContainer from '../../common/header/ProfileContainer';
import { getSessionStorageValue } from '../../../effects/utils/session-storage';
import { useAuthStateValue } from '../../../providers/AuthStateProvider';
import NotificationLogo from '../../../images/notifactions.svg';
import '../../../styles/dashboard.css';
import ApplyNowModalComponent from '../../common/header/ApplyNowModalComponent';
import { post } from '../../../effects/api/api.common';
import { NOTIFICATION_LIST, NOTIFICATION_LIST_STATUS_CHANGE, NOTIFICATION_LIST_STATUS_CHANGE_ALL } from '../../../constants/api';
import { timeSince } from '../../../utils/CommonMethods';

function EacHeaderComponent() {
  // @ts-ignore: Unreachable code error
  const [{ user }, dispatch] = useAuthStateValue();
  const [notificationList, setNotificationList] = useState([])

  useEffect(() => {
    showNotifications();
    if (user.accessToken) {
      const overlayElement = document.getElementById('overlay');
      if (!overlayElement) return;

      overlayElement.style.display = 'none';
    }
  }, [user]);
  const showNotifications = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    var payload = {
      "logged_user_id": userInfo.id,
    }
    post(NOTIFICATION_LIST, payload).then((response: any) => {
      if (!response || !response.data) return;
      setNotificationList(response.data.data)
    })
  }

  const statusChange = (id: any) => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)
    post(NOTIFICATION_LIST_STATUS_CHANGE, { "id": id, "logged_user_id": userInfo.id }).then((response: any) => {
      if (!response || !response.data) return;
      if (response.data.result == "success") {
        showNotifications()
      }
    })
  }
  const statusChangeAll = () => {
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user)

    var payload = {
      "logged_user_id": userInfo.id
    }
    post(NOTIFICATION_LIST_STATUS_CHANGE_ALL, payload).then((response: any) => {
      if (!response || !response.data) return;
      if (response.data.result == "success") {
        showNotifications()
      }
    })
  }
  return (
    <header className="app-header header-sec">
      <nav className="navbar navbar-expand-lg navbar-dark fixed-top">
        <div className="container-fluid">
          <Link className="navbar-brand-dashboard mr-md-auto" to="/">
            <span aria-label="SISFS Logo" />
          </Link>

          {/* @* Hamburger menu code for responsive smaller screens *@ */}
          <button
            className="navbar-toggler"
            id="ChangeToggle"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <div id="navbar-hamburger">
              <span className="navbar-toggler-icon">
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </span>
            </div>
            <div id="navbar-close" className="hidden">
              <span className="fa fa-close"></span>
            </div>
          </button>

          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav m-auto">
              {/* <Link role="menuitem" className="nav-link" to="/eac_dashboard">
                  Dashboard
                </Link> */}
              
              {/* <li className="nav-item">
                <NavLink exact={true} className="nav-link" activeClassName='active' to='/eac_dashboard'>Dashboard</NavLink>
              </li> */}
              <li className="nav-item">
                <NavLink exact={true} className="nav-link" activeClassName='active' to='/eac_notice_board'>Notifications</NavLink>
              </li>
              <li role="none" className="nav-item dropdown">
                {/* <Link role="menuitem" className="nav-link" to="/view_incubator_eac">
                  Incubators
                </Link> */}
                <NavLink exact={true} className="nav-link" activeClassName='active' to='/merged_list'>Incubators</NavLink>
              </li>

              <li className="nav-item">
                {/* <Link role="menuitem" className="nav-link" to="/eac_Startup_dashboard">
                  Dashboard
                </Link> */}
                <NavLink exact={true} className="nav-link" activeClassName='active' to='/eac_startup_list'>Startup</NavLink>
              </li>


              {/* <li role="none" className="nav-item">
                <a role="menuitem" className="nav-link">
                  Startups
                </a>
              </li> */}
              <li role="none" className="nav-item">
                {/* <Link role="menuitem" className="nav-link" to="/view_eac_review">
                  Reviews
                </Link> */}
                <NavLink exact={true} className="nav-link" activeClassName='active' to='/view_eac_review'>EAC Review</NavLink>
              </li>
              {/* <li role="none" className="nav-item">
                <NavLink exact={true} className="nav-link" activeClassName='active' to='/user_view_eac'>Users</NavLink>
              </li> */}
            </ul>

            {/* Notifications Code */}
            <div className="dropdown">
              <div className="media align-items-center pl-4" data-toggle="dropdown">
                <span className="avatar avatar-sm rounded-circle notification-bell" >
                  <img
                    src={NotificationLogo}
                    className="img-fluid"
                    alt="Notification Logo"
                  />
                </span>
                {notificationList.some((o: any) => o.read_status === 0) && <mark></mark>}
              </div>

              <ul className="dropdown-menu dropdown-menu-right notifications" role="menu" aria-labelledby="dLabel">

                <div className="notification-heading">
                  <h4 className="menu-title">Notifications</h4>
                  {notificationList.length != 0 && <span className="link-orange-text  pull-right" onClick={statusChangeAll}><u>Mark all as read</u></span>}
                </div>
                <li className="divider"></li>
                <div className="notifications-wrapper">
                  {notificationList.length == 0 && <a className="content">

                    <div className="notification-item">
                      <h4 className="item-title">No Notifications</h4>
                    </div>

                  </a>}
                  {notificationList.map((item: any) => (
                    <a className={item.read_status == 0 ? "content unread" : "content"} onClick={() => statusChange(item.id)}>

                      <div className="notification-item">
                        <h4 className="item-title">{item.message}</h4>
                        <p className="item-info">{timeSince(new Date(item.created_at))}</p>
                      </div>

                    </a>
                  ))}

                </div>

              </ul>
            </div>

            {/* <div className="media align-items-center pl-4">
              <span className="avatar avatar-sm rounded-circle">
                <img
                  src={NotificationLogo}
                  className="img-fluid"
                  alt="Notification Logo"
                />
              </span>
            </div> */}
            <div
              className="btn-group header-action-section"
              hidden={user?.accessToken ? true : false}
            >
              <Link
                className="btn btn-sisfs btn-transparent-primary"
                data-toggle="offcanvas"
                to={
                  !user.accessToken ? '/login' : '/'
                } /*onToggle={toggleOffcanvas} /*to="/login"*/
              >
                Login
              </Link>
            </div>
          </div>

          {/* <!-- Profile Section --> */}
          {user.accessToken && <ProfileContainer user={user} />}
        </div>
      </nav>

      {/* Offcanvas for Login screen */}
      {!user.accessToken && <LoginComponent />}
      {/* Offcanvas for Login screen */}
      <div className="hr">
        <hr />
      </div>
    </header>
  );
}

export default EacHeaderComponent;
