import { IncubatorDetails } from './IncubatorDetails';
import '../incubator/incubator.css';
import {
  ApplicationGeneralDetails,
  ApplicationStatus,
  QuickEditPopupData,
} from './ApplicationGeneralDetails';
import { Link, useHistory } from 'react-router-dom';
import { getSessionStorageValue } from '../../../effects/utils/session-storage';
import UserInfoModel from '../../../models/UserInfoModel';
import React, { useEffect, useState } from 'react';
import {
  GET_APPLICATION_STATUS,
  GET_QUICK_EDIT,
  QUICK_EDIT,
} from '../../../constants/api';
import { get, post } from '../../../effects/api/api.common';

interface IIncubatorProps {
  generalDetailsModel: IncubatorDetails;
}

function IncubatorLeftPanelComponent(props: IIncubatorProps) {
  const { generalDetailsModel } = props;
  // console.log('asdadsas',generalDetailsModel);
  
  
  const[loggedInRole,setLoggedInRole]=useState('')
  //console.log(generalDetailsModel, 'IContactProps');

  const user: any = getSessionStorageValue('user');
  const userInfo = JSON.parse(user);

  useEffect(() => {
    setLoggedInRole(userInfo.roles)
    quickEditData(generalDetailsModel.id.toString());
  }, [user,generalDetailsModel]);

  const history = useHistory();

  const handleRowChange = (id: any) => {
    if(loggedInRole=='dpiit'){
    history.push({
      pathname: '/dpiit_view_incubator',
      state: { application_id: id },
    });
  }
  else{
    history.push({
      pathname: '/secretariat_view_incubator',
      state: { application_id: id },
    });
  }
    window.location.reload();
  };
  const [quickEditPopupData, setQuickEditPopUp] = useState(
    new QuickEditPopupData('', '', '', '', '','')
  );

  const [selectFlag, setFlag] = useState(false);
  const setSelectFlag = (flag: boolean) => {
    setFlag(flag);
  };

  const [showEditSuccessModal,setShowEditSuccessModal]=useState(false);

  const [showEditFailureModal,setShowEditFailureModal]=useState(false);
  const quickEditCall = () => {

    setSelectFlag(true);

    post(QUICK_EDIT, quickEditPopupData).then((response: any) => {
      if (!response || !response.data) return;
      // console.log(response.data);
      if (response.data.result == 'success') {
        setShowEditSuccessModal(true);
if(loggedInRole=='dpiit'){
        history.push({
          pathname: '/dpiit_view_incubator',
          state: { application_id: quickEditPopupData.application_id },
        });
      }
      else{
        history.push({
          pathname: '/secretariat_view_incubator',
          state: { application_id: quickEditPopupData.application_id },
        });
      }
        window.location.reload();

      } else {
        setShowEditFailureModal(true);
      }
    });


    let app_data = {
      application_comments: '',
      application_id: '',
      application_status: '',
      corporate_id_no: '',
      incubator_name: '',
      user_id:'',
      incubator_application_no:''
    };
    setQuickEditPopUp(app_data);
  };

  // popup change
  const handlePopupChange = (e: any /*index:number*/) => {
    var value = e.target.value;
    var name = e.target.name;

    let quickEdit = JSON.parse(JSON.stringify(quickEditPopupData));
    if(name=="application_status"){
      quickEdit["application_comments"]='';
    }
    quickEdit[name] = e.target.value;
    setQuickEditPopUp(quickEdit);
  };

  //check if string has space
  function hasSpaces(str:any) {
    if (str.indexOf(' ') !== -1) {
      return true
    } else {
      return false
    }
  }

  const [applicationStatus, setApplicationStatus] = useState<
    Array<ApplicationStatus>
  >([]);

  //get application status
  const getApplicationStatus = () => {
    get(GET_APPLICATION_STATUS).then((response: any) => {
      if (!response || !response.data) return;
      const status = response.data.data.map((item: any) => ({
        label: item.label,
        value: item.id,
      }));
      setApplicationStatus(status);
    });
  };

  const quickEditData = (id: string) => {
    getApplicationStatus();
    post(GET_QUICK_EDIT, { application_id: id }).then((response: any) => {
      if (!response || !response.data) return;
      setQuickEditPopUp(response.data.data);

    });
  };

  const dismissModal = () => {
   setShowEditSuccessModal( false);
   setShowEditFailureModal(false);
  };

  return (
    <div className="container">
      <div className="row margin-16">
        <div className="col-md-12">
          <div className="margin-5"></div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <span className="incubator-name">
            {generalDetailsModel.registered_name == ''
              ? '-'
              : generalDetailsModel.registered_name}
          </span>
          <br />
          <span className="incubator-address">
            {generalDetailsModel.city == '' ? '-' : generalDetailsModel.city},
            &nbsp;
            {generalDetailsModel.state == '' ? '-' : generalDetailsModel.state}
          </span>
        </div>
      </div>

      <div className="eac-incubator-hr">
        <hr />
      </div>
      <div className="group-11">
        <div className="row ">
          <div className="col-md-12 ">
            <span className="incubator-label">Application Number</span>
            <br />
            {generalDetailsModel.id == null || generalDetailsModel.id == 0 ? (
              '-'
            ) : (
              <span className="incubator-label-value">
                No: &nbsp;
                {/* {generalDetailsModel.incubator_application_no != null || generalDetailsModel.incubator_application_no != 0 ? generalDetailsModel.incubator_application_no : generalDetailsModel.id} */}
                
                {generalDetailsModel.incubator_application_no === null || '' ? generalDetailsModel.id : generalDetailsModel.incubator_application_no }
              </span>
            )}
            &nbsp;&nbsp;&nbsp;&nbsp;
            {generalDetailsModel.otherApplications != undefined &&
            generalDetailsModel.otherApplications.length > 1 ? (
              <a
                className="incubator-details-link"
                data-toggle="modal"
                data-target="#otherApplications"
              >
                + {generalDetailsModel.otherApplications.length} Applications
              </a>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="row mt-05">
          <div className="col-md-12 ">
            <span className="incubator-label">Status</span>
            <br />
            <span className="incubator-label-value">
              {generalDetailsModel.application_status != ''
                ? generalDetailsModel.application_status
                : '-'}
            </span>
            &nbsp;&nbsp; &nbsp;&nbsp;
            {generalDetailsModel.application_status != '' &&
            (userInfo.roles == 'dpiit'||userInfo.roles == 'secretariat') ? (
              <a
                className="incubator-details-link"
                data-toggle="modal"
                data-target="#quickEdit"
              >
                Edit
              </a>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="row mt-05">
          <div className="col-md-12 ">
            <span className="incubator-label">Application Date</span>
            <br />
            <span className="incubator-label-value">
              {generalDetailsModel.first_submitted_date != null
                ? generalDetailsModel.first_submitted_date
                : generalDetailsModel.submitted_at}
            </span>
          </div>
        </div>
        {/* <div className="row mt-05">
          <div className="col-md-12 ">
            <span className="incubator-label">Next Review Date</span>
            <br /> <span className="incubator-label-value">-</span>
          </div> 
        </div> */}
        <div className="row mt-05">
          <div className="col-md-12">
            <span className="incubator-label">Incubator Email</span>
            <br />
            {generalDetailsModel.representative_email == '' ||
            generalDetailsModel.representative_email == null ? (
              <span className="incubator-label-value">-</span>
            ) : (
              hasSpaces(generalDetailsModel.representative_email)===true?generalDetailsModel.representative_email.split(', ').map(
                (item: any) => (
                  <>
                   <a
                   className="incubator-email-value"
                   href={`mailto:${item}`}
                 >
                   {item}
                 </a><br />
                 </>
                )):
              <a
                className="incubator-email-value"
                href={`mailto:${generalDetailsModel.representative_email}`}
              >
                {generalDetailsModel.representative_email}
              </a>
            )
            }
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="otherApplications"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none ml-18px">
              <h5 className="modal-title pl-2" id="exampleModalLongTitle">
                Other Applications <span></span>
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container">
                <div className="row">
                  <div className="mt-3-px">
                    <table id="example" className="mt-3-px">
                      <thead>
                        <tr>
                          <th className="text-left custom-td-left table-heading">
                            Application Number
                          </th>
                          <th className="text-left custom-td-left table-heading">
                            Registered Name
                          </th>
                          <th className="text-left table-heading">Status</th>
                          <th className="text-left table-heading"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {generalDetailsModel.otherApplications != undefined &&
                        generalDetailsModel.otherApplications.length > 0
                          ? generalDetailsModel.otherApplications.map(
                              (item: any) => (
                                <tr>
                                  <td className="text-left custom-td-left">
                                    {item.application_id}
                                  </td>
                                  <td className="text-left custom-td-left">
                                    {item.registered_name}
                                  </td>
                                  <td className="text-left custom-td-left">
                                    {item.application_status}
                                    &nbsp;&nbsp; &nbsp;&nbsp;
                                  </td>
                                  <td className="text-left custom-td-left">
                                    <a
                                      className="incubator-details-link"
                                      href=""
                                      onClick={() =>
                                        handleRowChange(item.application_id)
                                      }
                                      data-dismiss="modal"
                                    >
                                      View
                                    </a>
                                  </td>
                                </tr>
                              )
                            )
                          : ''}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row pt-3 mb-3">
                <div className="col-md-12 centerText">
                  <button
                    className="tertiary  btn-yellow-primary"
                    data-dismiss="modal"
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="quickEdit"
        role="dialog"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-slideout modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none modal-header-bottom pr-5">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setSelectFlag(true)}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pl-5 pr-5 pt-5">
              <div className="popup-head mb-5 ml-2">
                <div className="main-heading">
                  {quickEditPopupData.incubator_name}
                </div>
                <div className="count-text">
                  {quickEditPopupData.incubator_name == null
                    ? 'No: ' + generalDetailsModel.id
                    : 'No: ' +
                      quickEditPopupData.incubator_name +
                      '_' +
                      generalDetailsModel.id}
                </div>
              </div>

              <label className="col-sm-8 col-form-head">
                Application Status
              </label>
              <div className="row pl-3">
                <div className="col-md-8 mb-4">
                  <div className="select-custom">
                    <select
                      className="form-control"
                      aria-label="Select State"
                      id="application_status"
                      name="application_status"
                      value={quickEditPopupData.application_status}
                      onChange={handlePopupChange}
                      disabled={selectFlag}
                    >

                      {applicationStatus.map((item) =>
                        item.label !== 'Draft' ? (
                          <option key={item.label} value={item.value}>
                            {item.label}
                          </option>
                        ) : null
                      )}
                    </select>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <span
                    className="view-all"
                    onClick={() => setSelectFlag(false)}
                  >
                    Edit
                  </span>
                </div>
              </div>

              <label className="col-sm-8 col-form-head">Comments, if any</label>
              <div className="col-md-12 mb-4">
                <textarea
                  value={quickEditPopupData.application_comments}
                  className="form-control textarea-height"
                  name="application_comments"
                  onChange={handlePopupChange}
                />
              </div>
              <div className="row pl-3">
                <div className="col-md-6">
                  <button
                    className="btn btn-sisfs btn-transparent-primary full-width"
                    onClick={() => setSelectFlag(true)}
                    data-dismiss="modal"
                  >
                    CANCEL
                  </button>
                </div>
                <div className="col-md-6">
                  <button
                    className="tertiary  btn-yellow-primary full-width"
                    onClick={quickEditCall}
                    data-dismiss="modal"
                  >
                    UPDATE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showEditSuccessModal && (
            // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
            <>
              <div className="modal-backdrop fade show"></div>

              <div
                className="modal align-middle modal-sisfs d-block"
                id="FormSubmissionSuccess"
              >
                <div className="modal-dialog modal-dialog-centered modal-md">
                  <div className="modal-content">
                    <div className="modal-body text-center">
                      <i className="fa fa-check-circle-o"></i>
                      <h3 className="modal-message">Thank You !</h3>
                      <div className="modal-text">
                        Application Status updated Successfully !!
                      </div>
                      <div className="modal-action">
                        <button
                          className="btn btn-sisfs btn-yellow-primary"
                          onClick={dismissModal}
                        >
                          Ok !!
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {showEditFailureModal && (
            // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
            <>
              <div className="modal-backdrop fade show"></div>
              <div
                className="modal align-middle modal-sisfs d-block"
                id="FormSubmissionError"
              >
                <div className="modal-dialog modal-dialog-centered modal-md">
                  <div className="modal-content">
                    <div className="modal-body text-center">
                      {/* <i className="fa fa-times-circle-o"></i> */}
                      <h3 className="modal-message message-orange">
                        Oops! Something is Wrong
                      </h3>
                      <br />
                      <h3 className="modal-message message-orange">
                        Please Try Again !!!
                      </h3>
                      <div className="modal-action">
                        <button
                          className="btn btn-sisfs btn-yellow-primary"
                          onClick={dismissModal}
                        >
                          OK !
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

    </div>
  );
}
export default IncubatorLeftPanelComponent;
