import React, { createContext, useContext, useReducer } from "react";
import { IUserIniitalState } from "../reducers/auth.reducer";

// Prepares the dataLayer
export const StateContext = createContext(null as unknown);

export interface IAuthStateProvider{
    reducer: any,
    initialUserState: IUserIniitalState
    children: any
}

// Wrap our app and provide the Data layer
export const StateProvider = (props: IAuthStateProvider) => {
  
  const {reducer, initialUserState, children} = props;

  return (
    <StateContext.Provider value={useReducer(reducer, initialUserState)}>
      {children}
    </StateContext.Provider>
  );
};

// Pull information from the data layer
export const useAuthStateValue = () => useContext(StateContext);