import React from 'react';
import '../../../styles/dashboard.css';

function DashboardFooterComponent() {
  return (
    <>
      <footer className="app-footer footer-sec">
        <div className="container-fluid">
          <div className="row footer-bottom">
            <div className="col-xs-12 col-sm-6 col-lg-6">
              <div className="footer-copyright">
                Copyright © 2021 Startup India Seed Fund Scheme. All rights reserved.
              </div>
            </div>

            <div className="col-xs-12 col-sm-6 col-lg-6">
              <div className="footer-privacylinks clearfix">
                {/* <ul className="pull-right">
                  <li><a href="">Privacy Policy</a></li>
                  <li><a href="">Terms of Use</a></li>
                  <li><a href="">Cookie Notice</a></li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>

  );
}

export default DashboardFooterComponent;
