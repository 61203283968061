import { IncubatorDetails } from "./../IncubatorDetails";
import "../../incubator/incubator.css";
import {
  ApplicationGeneralDetails,
  ApplicationStatus,
  QuickEditPopupData,
} from "./../ApplicationGeneralDetails";
import { Link, useHistory } from "react-router-dom";
import { getSessionStorageValue } from "../../../../effects/utils/session-storage";
import UserInfoModel from "../../../../models/UserInfoModel";
import React, { useEffect, useState } from "react";
import {
  GET_APPLICATION_STATUS,
  GET_QUICK_EDIT,
  GET_Re_QUICK_EDIT,
  QUICK_EDIT,
  QUICK_Re_EDIT,
} from "../../../../constants/api";
import { get, post } from "../../../../effects/api/api.common";
import error from "../../../../images/error.svg";
import moment from "moment";

interface IIncubatorProps {
  remainingObjest: any;
  mainObject: any;
}

function MergedLeftSidebarDetailsComponent(props: IIncubatorProps) {
  const { remainingObjest, mainObject } = props;
  console.log("asdadsas", remainingObjest);

  const [loggedInRole, setLoggedInRole] = useState("");
  ////console.log(generalDetailsModel, 'IContactProps');

  const user: any = getSessionStorageValue("user");
  const userInfo = JSON.parse(user);
  console.log("userInfo", userInfo.id);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setLoggedInRole(userInfo.roles);
    // quickEditData(mainObject.id.toString());
    //console.log(mainObject);
    //console.log(quickEditPopupData);

    if (mainObject.application_index == "R1") {
      //console.log('R1');
      setTimeout(() => {
        ReapplyquickEditData(mainObject.application_id);
      }, 1000);
      // ReapplyquickEditData(mainObject.application_id);
    } else {
      //console.log('f1');
      quickEditData(mainObject.application_id);
    }
  }, [user, mainObject]);

  const history = useHistory();

  const handleRowChange = (
    userId: any,
    application_id: any,
    status_id: any,
    id: any
  ) => {
    // console.log(userId,application_id,status_id,id,"itemsuuuuu")
    if (loggedInRole == "dpiit") {
      history.push({
        pathname: "/merged_Incubator_Details_list",
        state: {
          userId: userId,
          application_id: application_id,
          status_id: status_id,
          flag: "status",
          id: id,
        },
      });
    } else {
      history.push({
        pathname: "/merged_Incubator_Details_list",
        state: {
          userId: userId,
          application_id: application_id,
          status_id: status_id,
          flag: "status",
          id: id,
        },
      });
    }
    window.location.reload();
  };
  const [quickEditPopupData, setQuickEditPopUp] = useState<any>("");

  const [selectFlag, setFlag] = useState(false);
  const setSelectFlag = (flag: boolean) => {
    setFlag(flag);
  };

  const [showEditSuccessModal, setShowEditSuccessModal] = useState(false);
  const [DontMoveToSubmit, setDontMoveToSubmit] = useState(false);

  const [showEditFailureModal, setShowEditFailureModal] = useState(false);

  const quickEditCall = () => {
    quickEditPopupData.logged_user_id = userInfo.id;
    console.log("Apply", quickEditPopupData);
    if (
      quickEditPopupData.application_comments === null ||
      quickEditPopupData.application_comments === ""
    ) {
      return;
    } else if (quickEditPopupData.application_status == "2") {
      setDontMoveToSubmit(true);
      return;
    } else {
      setLoader(true);
      post(QUICK_EDIT, quickEditPopupData).then((response: any) => {
        setLoader(false);
        if (!response || !response.data) return;
        //console.log(response.data);
        if (response.data.result == "success") {
          // console.log("hello")
          setShowEditSuccessModal(true);
          if (loggedInRole == "dpiit") {
            history.push({
              pathname: "/merged_Incubator_Details_list",
              state: {
                application_id: quickEditPopupData.incubator_application_no,
                userId: quickEditPopupData.user_id,
                status_id: quickEditPopupData.application_status,
                flag: "status",
              },
            });
          } else {
            history.push({
              pathname: "/merged_Incubator_Details_list",
              state: {
                application_id: quickEditPopupData.incubator_application_no,
                userId: quickEditPopupData.user_id,
                status_id: quickEditPopupData.application_status,
                flag: "status",
              },
            });
          }
          // window.location.reload();
        } else {
          setShowEditFailureModal(true);
        }
      });
      // }
      setSelectFlag(true);
      // let app_data = {
      //   application_comments: '',
      //   application_id: '',
      //   application_status: '',
      //   corporate_id_no: '',
      //   incubator_name: '',
      //   user_id:'',
      //   incubator_application_no:''
      // };
      // setQuickEditPopUp(app_data);
    }
  };

  const ReapplyquickEditCall = () => {
    //console.log('Reapply')
    // //console.log(quickEditPopupData.application_comments);
    // if (quickEditPopupData.application_comments == "" || quickEditPopupData.application_comments == null) {

    //     setValidationpFlag((values) => ({
    //         ...values,
    //         application_comments:"true",
    //     }));
    // }
    // else {
    //     setValidationpFlag((values) => ({
    //         ...values,
    //         application_comments: "false",
    //     }));
    // }
    quickEditPopupData.logged_user_id = userInfo.id;
    console.log("ReApply", quickEditPopupData);
    if (
      quickEditPopupData.application_comments === null ||
      quickEditPopupData.application_comments === ""
    ) {
      return;
    } else if (quickEditPopupData.application_status == "2") {
      setDontMoveToSubmit(true);
      return;
    } else {
      post(QUICK_Re_EDIT, quickEditPopupData).then((response: any) => {
        if (!response || !response.data) return;
        //console.log(response.data,"ppp");
        if (response.data.result == "success") {
          setShowEditSuccessModal(true);
          if (loggedInRole == "dpiit") {
            history.push({
              pathname: "/merged_Incubator_Details_list",
              state: {
                application_id: quickEditPopupData.incubator_application_no,
                userId: quickEditPopupData.user_id,
                status_id: quickEditPopupData.application_status,
                flag: "status",
              },
            });
          } else {
            history.push({
              pathname: "/merged_Incubator_Details_list",
              state: {
                application_id: quickEditPopupData.incubator_application_no,
                userId: quickEditPopupData.user_id,
                status_id: quickEditPopupData.application_status,
                flag: "status",
              },
            });
          }
          // window.location.reload();
        } else {
          setShowEditFailureModal(true);
        }
      });

      setSelectFlag(true);
      // let app_data = {
      //   application_comments: '',
      //   application_id: '',
      //   application_status: '',
      //   corporate_id_no: '',
      //   incubator_name: '',
      //   user_id:'',
      //   incubator_application_no:''
      // };
      // setQuickEditPopUp(app_data);
    }
  };

  // popup change
  const handlePopupChange = (e: any /*index:number*/) => {
    var value = e.target.value;
    var name = e.target.name;
    console.log(name);
    let quickEdit = JSON.parse(JSON.stringify(quickEditPopupData));
    console.log("quickEdit", quickEdit);
    if (name == "application_status") {
      quickEdit["application_comments"] = "";
    }
    quickEdit[name] = e.target.value;
    setQuickEditPopUp(quickEdit);
  };

  //check if string has space
  function hasSpaces(str: any) {
    if (str.indexOf(" ") !== -1) {
      return true;
    } else {
      return false;
    }
  }

  const [applicationStatus, setApplicationStatus] = useState<
    Array<ApplicationStatus>
  >([]);

  //get application status
  const getApplicationStatus = () => {
    get(GET_APPLICATION_STATUS).then((response: any) => {
      // console.log(response,"jhdkjadsk")
      if (!response || !response.data) return;
      const status = response.data.data.map((item: any) => ({
        label: item.label,
        value: item.id,
      }));
      setApplicationStatus(status);
    });
  };

  const quickEditData = (id: string) => {
    if (!id) {
      return;
    }
    getApplicationStatus();
    post(GET_QUICK_EDIT, { application_id: id }).then((response: any) => {
      if (!response || !response.data) return;
      setQuickEditPopUp(response.data.data);
      // console.log(quickEditPopupData, 'Apply');
    });
  };

  const ReapplyquickEditData = (id: string) => {
    if (!id) {
      return;
    }
    getApplicationStatus();
    post(GET_Re_QUICK_EDIT, { application_id: id }).then((response: any) => {
      if (!response || !response.data) return;
      setQuickEditPopUp(response.data.data[0]);
      //console.log(quickEditPopupData,"Reapply");
    });
  };

  const dismissModal = () => {
    setShowEditSuccessModal(false);
    setShowEditFailureModal(false);
    window.location.reload();
  };

  return (
    <>
      {/* {loader &&
        <div className="spinner-border custom-spinner loader-position" role="status">
          <span className="sr-only">Loading...</span>
        </div>} */}
      <fieldset disabled={loader}>
        <div className="container">
          <div className="row margin-16">
            {/* {console.log(mainObject)} */}
            <div className="col-md-12">
              <div className="margin-5"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <span className="incubator-name">
                {mainObject.registered_name == ""
                  ? "-"
                  : mainObject.registered_name}
              </span>
              <br />
              <span className="incubator-address">
                {mainObject.city_name == "" ? "-" : mainObject.city_name},
                &nbsp;
                {mainObject.state == "" ? "-" : mainObject.state}
              </span>
            </div>
          </div>

          <div className="eac-incubator-hr">
            <hr />
          </div>
          <div className="group-11">
            <div className="row ">
              <div className="col-md-12 ">
                <span className="incubator-label">Application Number</span>
                <br />
                {mainObject.application_id == null ||
                mainObject.application_id == 0 ? (
                  "-"
                ) : (
                  <span className="incubator-label-value">
                    No: &nbsp;
                    {/* {mainObject.incubator_application_no != null || mainObject.incubator_application_no != 0 ? mainObject.incubator_application_no : mainObject.application_id} */}
                    {mainObject.incubator_application_no === null || ""
                      ? mainObject.application_id
                      : mainObject.incubator_application_no}
                  </span>
                )}
                &nbsp;&nbsp;&nbsp;&nbsp;
                <br />
                {/* {remainingObjest != undefined &&  remainingObjest.length > 0 ? (
                  <a
                    className="incubator-details-link"
                    data-toggle="modal"
                    data-target="#otherApplications"
                  >
                    + {remainingObjest.length} Applications
                  </a>
                ) : (
                  ""
                )} */}
                {remainingObjest !== undefined &&
                remainingObjest.filter(
                  (obj: any) => obj.application_status !== "Draft"
                ).length > 0 ? (
                  <a
                    className="incubator-details-link"
                    data-toggle="modal"
                    data-target="#otherApplications"
                  >
                    +{" "}
                    {
                      remainingObjest.filter(
                        (obj: any) => obj.application_status !== "Draft"
                      ).length
                    }{" "}
                    Applications
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row mt-05">
              <div className="col-md-12 ">
                <span className="incubator-label">Status</span>
                <br />
                <span className="incubator-label-value">
                  {mainObject.application_status != ""
                    ? mainObject.application_status
                    : "-"}
                </span>
                &nbsp;&nbsp; &nbsp;&nbsp;
                {mainObject.application_status != "" &&
                (userInfo.roles == "dpiit" ||
                  userInfo.roles == "secretariat") ? (
                  <a
                    className="incubator-details-link"
                    data-toggle="modal"
                    data-target="#quickEdit"
                  >
                    Edit
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row mt-05">
              <div className="col-md-12 ">
                <span className="incubator-label">Application Date</span>
                <br />
                <span className="incubator-label-value">
                  {mainObject.first_submitted_date != null
                    ? mainObject.first_submitted_date
                    : moment(mainObject.submitted_at).format("YYYY-MM-DD")}
                </span>
              </div>
            </div>
            {/* <div className="row mt-05">
          <div className="col-md-12 ">
            <span className="incubator-label">Next Review Date</span>
            <br /> <span className="incubator-label-value">-</span>
          </div> 
        </div> */}
            <div className="row mt-05">
              <div className="col-md-12">
                <span className="incubator-label">Incubator Email</span>
                <br />
                {mainObject.representative_email == "" ||
                mainObject.representative_email == null ? (
                  <span className="incubator-label-value">-</span>
                ) : hasSpaces(mainObject.representative_email) === true ? (
                  mainObject.representative_email
                    .split(", ")
                    .map((item: any) => (
                      <>
                        <a
                          className="incubator-email-value"
                          href={`mailto:${item}`}
                        >
                          {item}
                        </a>
                        <br />
                      </>
                    ))
                ) : (
                  <a
                    className="incubator-email-value"
                    href={`mailto:${mainObject.representative_email}`}
                  >
                    {mainObject.representative_email}
                  </a>
                )}
              </div>
            </div>
          </div>

          {/* Remaining Objects Model */}
          <div
            className="modal fade"
            id="otherApplications"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header modal-header-border-none ml-18px">
                  <h5 className="modal-title pl-2" id="exampleModalLongTitle">
                    Other Applications <span></span>
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="container">
                    <div className="row">
                      <div className="mt-3-px">
                        <table id="example" className="mt-3-px">
                          <thead>
                            <tr>
                              <th className="text-left custom-td-left table-heading">
                                Application Number
                              </th>
                              <th className="text-left custom-td-left table-heading">
                                Registered Name
                              </th>
                              <th className="text-left table-heading">
                                Status
                              </th>
                              <th className="text-left table-heading"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {remainingObjest != undefined &&
                            remainingObjest.length > 0
                              ? remainingObjest.map((item: any) => (
                                  <tr>
                                    <td className="text-left custom-td-left">
                                      {item.incubator_application_no}
                                    </td>
                                    <td className="text-left custom-td-left">
                                      {item.registered_name}
                                    </td>
                                    <td className="text-left custom-td-left">
                                      {item.application_status}
                                      &nbsp;&nbsp; &nbsp;&nbsp;
                                    </td>
                                    <td className="text-left custom-td-left">
                                      <a
                                        className="incubator-details-link"
                                        href=""
                                        onClick={() =>
                                          handleRowChange(
                                            item.user_id,
                                            item.incubator_application_no,
                                            item.status_id,
                                            item.application_id
                                          )
                                        }
                                        data-dismiss="modal"
                                      >
                                        {/* {console.log(item,"itemsuuuu")} */}
                                        View
                                      </a>
                                    </td>
                                  </tr>
                                ))
                              : ""}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row pt-3 mb-3">
                    <div className="col-md-12 centerText">
                      <button
                        className="tertiary  btn-yellow-primary"
                        data-dismiss="modal"
                      >
                        OK
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Edit Button Model */}
          <div
            className="modal fade"
            id="quickEdit"
            role="dialog"
            aria-labelledby="exampleModalLabel1"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-slideout modal-md"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header modal-header-border-none modal-header-bottom pr-5">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setSelectFlag(true)}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                {/* {console.log(Object.keys(quickEditPopupData).length)} */}
                {
                  <div className="modal-body pl-5 pr-5 pt-5">
                    <div className="popup-head mb-5 ml-2">
                      <div className="main-heading">
                        {quickEditPopupData &&
                          quickEditPopupData.incubator_name}
                      </div>
                      <div className="count-text">
                        {quickEditPopupData &&
                        quickEditPopupData.incubator_name == null
                          ? "No: " + mainObject.application_id
                          : "No: " + quickEditPopupData &&
                            quickEditPopupData.incubator_name +
                              "_" +
                              mainObject.application_id}
                      </div>
                    </div>

                    <label className="col-sm-8 col-form-head">
                      Application Status
                    </label>
                    <div className="row pl-3">
                      <div className="col-md-8 mb-4">
                        <div className="select-custom">
                          <select
                            className="form-control"
                            aria-label="Select State"
                            id="application_status"
                            name="application_status"
                            value={
                              quickEditPopupData &&
                              quickEditPopupData.application_status
                            }
                            onChange={handlePopupChange}
                            disabled={selectFlag}
                          >
                            {applicationStatus.map((item) =>
                              item.label !== "Draft" ? (
                                <option key={item.label} value={item.value}>
                                  {item.label}
                                </option>
                              ) : null
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4 mb-4">
                        {/* <span
                    className="view-all"
                    onClick={() => setSelectFlag(false)}
                  >
                    Edit
                  </span> */}
                      </div>
                    </div>

                    <label className="col-sm-8 col-form-head">
                      Comments, if any
                    </label>
                    <div className="col-md-12 mb-4">
                      <textarea
                        value={
                          quickEditPopupData &&
                          quickEditPopupData.application_comments
                        }
                        className="form-control textarea-height"
                        name="application_comments"
                        onChange={handlePopupChange}
                        disabled={selectFlag}
                      />
                    </div>
                    <div className="row text-left pl-4 mb-4">
                      {quickEditPopupData &&
                      quickEditPopupData.application_comments == "" ? (
                        <label className="error-message">
                          Comment is Required
                        </label>
                      ) : null}
                    </div>
                    <div className="row pl-3">
                      <div className="col-md-6">
                        <button
                          className="btn btn-sisfs btn-transparent-primary full-width"
                          onClick={() => setSelectFlag(true)}
                          data-dismiss="modal"
                        >
                          CANCEL
                        </button>
                      </div>
                      <div className="col-md-6">
                        {mainObject.application_index == "R1" ? (
                          <button
                            className="tertiary  btn-yellow-primary full-width"
                            onClick={ReapplyquickEditCall}
                            // data-dismiss="modal"
                          >
                            UPDATE
                          </button>
                        ) : (
                          <button
                            className="tertiary  btn-yellow-primary full-width"
                            onClick={quickEditCall}
                            // data-dismiss="modal"
                          >
                            UPDATE
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>

          {showEditSuccessModal && (
            // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
            <>
              <div className="modal-backdrop fade show"></div>

              <div
                className="modal align-middle modal-sisfs d-block"
                id="FormSubmissionSuccess"
              >
                <div className="modal-dialog modal-dialog-centered modal-md">
                  <div className="modal-content">
                    <div className="modal-body text-center">
                      <i className="fa fa-check-circle-o"></i>
                      <h3 className="modal-message">Thank You !</h3>
                      <div className="modal-text">
                        Application Status updated Successfully !!
                      </div>
                      <div className="modal-action">
                        <button
                          className="btn btn-sisfs btn-yellow-primary"
                          onClick={dismissModal}
                        >
                          Ok !!
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* Don't Move To Submit Model*/}
          {DontMoveToSubmit && (
            <>
              <div className="modal-backdrop fade show"></div>

              <div
                className="modal align-middle modal-sisfs d-block"
                id="FormSubmissionSuccess"
              >
                <div className="modal-dialog modal-dialog-centered modal-md">
                  <div className="modal-content">
                    <div className="modal-body text-center">
                      <div className="modal-text">
                        <img src={error} className="search mb-2" alt="search" />
                        <br />
                        <h3 className="modal-message message-orange">Oops!</h3>
                        <h1 className="modal-message message-orange">
                          Application status cannot be changed to Submitted
                        </h1>
                      </div>
                      <div className="modal-action">
                        <button
                          className="btn btn-sisfs btn-yellow-primary"
                          onClick={() => {
                            setDontMoveToSubmit(false);
                          }}
                        >
                          Ok!!
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {showEditFailureModal && (
            // <FormSubmissionSuccess data-toggle="modal" data-target="#FormSubmissionSuccess"// />
            <>
              <div className="modal-backdrop fade show"></div>
              <div
                className="modal align-middle modal-sisfs d-block"
                id="FormSubmissionError"
              >
                <div className="modal-dialog modal-dialog-centered modal-md">
                  <div className="modal-content">
                    <div className="modal-body text-center">
                      {/* <i className="fa fa-times-circle-o"></i> */}
                      <h3 className="modal-message message-orange">
                        Oops! Something is Wrong
                      </h3>
                      <br />
                      <h3 className="modal-message message-orange">
                        Please Try Again !!!
                      </h3>
                      <div className="modal-action">
                        <button
                          className="btn btn-sisfs btn-yellow-primary"
                          onClick={dismissModal}
                        >
                          OK !
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </fieldset>
    </>
  );
}
export default MergedLeftSidebarDetailsComponent;
