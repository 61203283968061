import React, { Component, createRef, useEffect, useRef, useState } from 'react';
import homeseedfundneed from '../../images/seedfund-need.svg';
import whatsstartupindia from '../../images/whatsstartupindia.svg';
import seedfund from '../../images/seedfund.svg';
import objectives from '../../images/objectives-lady.svg';
import Toparrow from '../../images/Toparrow.svg';
// import HkMittal from '../../images/eac_members/hk_mittal.jpg';
// import Shashank from '../../images/eac_members/shashank.jpg';
// import Anil from '../../images/eac_members/anil.png';
// import Alka from '../../images/eac_members/alka.jpg';
// import Anita from '../../images/eac_members/anita.png';
// import Jeet from '../../images/eac_members/jeet.jpg';
// import Srinivas from '../../images/eac_members/srinivas.png';
// import Raman from '../../images/eac_members/raman.jpg';
// import Gopinath from '../../images/eac_members/gopinath.jpg';
// import Karthik from '../../images/eac_members/Karthik.jpg';
// import Padmaja from '../../images/eac_members/padmaja.png';
// import Anjali from '../../images/eac_members/anjali.jpg';
// import Shruti from '../../images/eac_members/shruti.jpg';
// import Apoorv from '../../images/eac_members/apoorv.jpeg';
import HkMittal from '../../images/EAC Members/HK Mittal.png';
import Chintan from '../../images/EAC Members/chintan.jpeg';
import Shashank from '../../images/EAC Members/Shashank Priya.png';
import Anil from '../../images/EAC Members/Anil Agrawal.png';
import Alka from '../../images/EAC Members/Alka Sharma.png';
import Anita from '../../images/EAC Members/Anita Gupta.png';
import Praveen from '../../images/EAC Members/Shri Praveen Roy.jpeg';
import Jeet from '../../images/EAC Members/bhuvnesh.jpeg';
import Akash from '../../images/EAC Members/Shri Akash Tripathi.png';
import Srinivas from '../../images/EAC Members/K Srinivas.png';
import Raman from '../../images/EAC Members/Ramanan.png';
import Bhatnaga from '../../images/EAC Members/Bhatnagar.png';
import Gopinath from '../../images/EAC Members/Gopinath.png';
import Karthik from '../../images/EAC Members/Karthik.jpg';
import Padmaja from '../../images/EAC Members/Ruparel.png';
import Anjali from '../../images/EAC Members/Bansal.png';
import Shruti from '../../images/EAC Members/Kannan.png';
import Shruti_Singh from '../../images/eac_members/shruti_singh.jpg';
// import Manmeet from '../../images/eac_members/Manmeet_K_Nanda.jpg'
import ShriSanjiv from '../../images/eac_members/ShriSanjiv.jpg';
import Apoorv from '../../images/EAC Members/Apoorva Sharma.png';
import Gaur from '../../images/EAC Members/Gaur.png';
import Bhooshan from '../../images/EAC Members/Dr_Neeru_Bhooshan_latest.jpg'
import { Link } from 'react-scroll'
import logo from './logo.svg';
import '../../styles/component.css';
import { useHistory } from 'react-router-dom';
import { getSessionStorageValue } from '../../effects/utils/session-storage';
import { scroller } from "react-scroll";

const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
};

function About(props: any) {
    const [activeIndex, setActiveIndex] = useState("startup");
    const [activeTab, setActiveTab] = useState("startup");
    const history = useHistory();
    const checkLogin = () => {
        const user: any = getSessionStorageValue("user");
        if (user != null) {
            history.replace("/incubator");
        }
    }
    const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);
    const startupRef = useRef(null)
    const expertRef = useRef(null)
    const objectiveRef = useRef(null)
    const sfsRef = useRef(null)
    const whatStartupRef = useRef(null)
    useEffect(() => {
        window.scrollTo(0, 0)
        if ((typeof props.location.state !== 'undefined') && (props.location.state != null)) {
            if (props.location.state.selected_tab == "startup") {
                // scrollToRef(startupRef);
                scroller.scrollTo("eligibilityScroll", {
                    duration: 800,
                    delay: 0,
                    offset: -130,
                    smooth: "easeInOutQuart",
                });
                setActiveTab("startup");
                setActiveIndex("startup");
            }
            else if (props.location.state.selected_tab == "incubator") {
                // scrollToRef(startupRef);
                scroller.scrollTo("eligibilityScroll", {
                    duration: 800,
                    delay: 0,
                    offset: -130,
                    smooth: "easeInOutQuart",
                });
                setActiveTab("incubator");
                setActiveIndex("incubator");
            }
            else if (props.location.state.selected_tab == "expert") {
                // scrollToRef(expertRef);
                scroller.scrollTo("expertScroll", {
                    duration: 800,
                    delay: 0,
                    offset: -130,
                    smooth: "easeInOutQuart",
                });
            }
            else if (props.location.state.selected_tab == "objective") {
                // scrollToRef(objectiveRef);

                scroller.scrollTo("objectiveScroll", {
                    duration: 800,
                    delay: 0,
                    offset: -130,
                    smooth: "easeInOutQuart",
                });

            }
            else if (props.location.state.selected_tab == "sfs") {
                // scrollToRef(sfsRef);
                scroller.scrollTo("sisfsScroll", {
                    duration: 800,
                    delay: 0,
                    offset: -130,
                    smooth: "easeInOutQuart",
                });
            }
            else if (props.location.state.selected_tab == "whatstartup") {
                // scrollToRef(whatStartupRef);
                scroller.scrollTo("whatStartupScroll", {
                    duration: 800,
                    delay: 0,
                    offset: -130,
                    smooth: "easeInOutQuart",
                });
            }


        }




    }, []);

    const changeActive = (tabs: any) => {
        setActiveIndex(tabs)
    }
    return (
        <main role="main">
            <div className="about-container" >
                <div className="container-fluid about-heading">
                    <div className="text-center main-heading">About Startup India Seed Fund Scheme</div>
                </div>

                <div className="container">
                    <div className="row about-content sticky-content">
                        <div className="col-sm-12 col-md-4 sticky-dropdown-section">

                            <div className="d-block d-sm-block d-md-none">
                                <div className="sticky-dropdown">
                                    <div className="dropdown">
                                        <button className="btn btn-large btn-sisfs btn-transparent-primary dropdown-toggle"
                                            type="button" data-toggle="dropdown">
                                            Startup India
                                            <span className="caret"></span>
                                        </button>
                                        <ul className="about-list dropdown-menu">
                                            <li><Link activeClass="active" to="what-startup" offset={-120} spy={true} smooth={true}>Startup India</Link></li>
                                            <li><Link activeClass="active" to="what-startup-2" offset={-120} spy={true} smooth={true}>Seed Fund Scheme (SISFS)</Link></li>
                                            <li><Link activeClass="active" to="what-startup-3" offset={-120} spy={true} smooth={true}>Objectives</Link></li>
                                            <li><Link activeClass="active" to="what-startup-4" offset={-120} spy={true} smooth={true}>Experts Advisory Committee (EAC)</Link></li>
                                            <li><Link activeClass="active" to="what-startup-5" offset={-120} spy={true} smooth={true}>Eligibility Criteria</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <ul className="about-list d-none d-sm-none d-md-block">
                                <li><Link activeClass="active" to="what-startup" offset={-130} spy={true} smooth={true}>Startup India</Link></li>
                                <li><Link activeClass="active" to="what-startup-2" offset={-130} spy={true} smooth={true}>Seed Fund Scheme (SISFS)</Link></li>
                                <li><Link activeClass="active" to="what-startup-3" offset={-130} spy={true} smooth={true}>Objectives</Link></li>
                                <li><Link activeClass="active" to="what-startup-4" offset={-130} spy={true} smooth={true}>Experts Advisory Committee (EAC)</Link></li>
                                <li ><Link activeClass="active" to="what-startup-5" offset={-130} spy={true} smooth={true}>Eligibility Criteria</Link></li>
                            </ul>
                        </div>
                        <div className="right-cont col-xs-12 col-sm-12 col-md-8">
                            <div id="what-startup" ref={whatStartupRef} className="whatStartupScroll">
                                <div className="main-heading right-heading">What is Startup India?</div>
                                <div className="col-xs-12 text-center">
                                    <img src={whatsstartupindia} className="img-fluid" alt="SISFS Logo" />
                                </div>
                                <p className="para-text">The Startup India initiative of the Government of India envisages building a robust Start-up ecosystem in the country for nurturing innovation and providing opportunities to budding entrepreneurs. </p>
                                <p className="para-orange">An Action Plan with 19 action points for the Startup India initiative was unveiled by the Hon’ble Prime Minister on January 16, 2016. </p>
                                <p className="para-text">This Action Plan laid down a roadmap for the creation of a conducive ecosystem for Startups in India. Subsequently, many activities have been undertaken to encourage Startups. Startup India Seed Fund Scheme (SISFS) is one such scheme which/that provides provides financial assistance to early-stage startups.</p>
                            </div>
                            <div id="what-startup-2" ref={sfsRef} className="right-cols sisfsScroll">
                                <div className="main-heading right-heading">What is Startup India Seed Fund Scheme?</div>
                                <p className="para-orange">Easy availability of capital is essential for entrepreneurs at the early stages of growth of an enterprise.  </p>
                                <p className="para-text">Funding from angel investors and venture capital firms becomes available to startups only after the proof of concept has been provided. Similarly, banks provide loans only to asset-backed applicants. It is essential to provide seed funding to startups with an innovative idea to conduct proof of concept trials.</p>
                                <div className="col-xs-12 text-center">
                                    <img src={seedfund} className="img-fluid" alt="SISFS Logo" />
                                </div>
                                <p className="para-text">DPIIT has created Startup India Seed Fund Scheme (SISFS) with an outlay of INR 945 Crore to provide financial assistance to startups for Proof of Concept, prototype development, product trials, market entry, and commercialization.It will support an estimated 3,600 entrepreneurs through 300 incubators in the next 4 years.</p>
                                <p className="para-orange">The Hon’ble Prime Minister of India announced the scheme in his Grand Plenary address of Prarambh: Startup India International Summit on 16th January 2021. After approval of EFC and Hon’ble Finance Minister, the scheme has been notified on 21.01.2021.</p>
                                <p className="para-text">The Seed Fund will be disbursed to eligible startups through eligible incubators across India.</p>
                            </div>
                            <div id="what-startup-3" ref={objectiveRef} className="right-cols objectiveScroll">
                                <div className="main-heading right-heading">Objectives of SISFS</div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <img src={objectives} className="img-fluid about-left-img" alt="SISFS Logo" />
                                    </div>
                                    <div className="col-sm-8 about-right-img">
                                        <p className="para-orange">The Indian startup ecosystem suffers from capital inadequacy in the seed and ‘Proof of Concept’ development stage. </p>
                                        <p className="para-orange">The capital required at this stage often presents a make or break situation for startups with good business ideas.</p>
                                    </div>
                                </div>
                                <p className="para-text">Many innovative business ideas fail to take off due to the absence of this critical capital required at an early stage for proof of concept, prototype development, product trials, market entry and commercialization. </p>
                                <p className="para-text">Seed Fund offered to such promising cases can have a multiplier effect in validation of business ideas of many startups, leading to employment generation.</p>
                            </div>
                            <div id="what-startup-4" ref={expertRef} className="right-cols expertScroll">
                                <div className="main-heading right-heading">An Experts Advisory Committee </div>
                                <p className="para-text">An Experts Advisory Committee (EAC) has been constituted by Department for Promotion of Industry and Internal Trade (DPIIT) , which will be responsible for the overall execution and monitoring of the Startup India Seed Fund Scheme. </p>
                                <p className="para-text">The EAC will evaluate and select incubators for allotment of Seed Funds, monitor progress, and take all necessary measures for efficient utilization of funds towards fulfilment of objectives of Startup India Seed Fund Scheme.</p>
                                <p className="text-left para-text"><b>The EAC comprises of the following members</b></p>
                                <div className="row d-block">
                                    <div className="img-div-row">
                                        {/* <div className="img-div"><img src={HkMittal} className="img-fluid img-members" alt="SISFS Logo" /><p className="card-heading"><b>Shri H.K. Mittal</b></p><p className="card-text">Chairman</p></div> */}
                                        {/* Additional Secretary & Financial Adviser */}
                                        <div className="img-div"><img src={Bhatnaga} className="img-fluid img-members" alt="SISFS Logo" /><p className="card-heading"><b>Ms. Arti Bhatnagar</b></p><p className="card-text">Chairman
                                        </p></div>
                                        <div className="img-div"><img src={ShriSanjiv} className="img-fluid img-members" alt="SISFS Logo" /><p className="card-heading"><b>Shri Sanjiv</b></p><p className="card-text">Joint Secretary, DPIIT (Convenor)
                                        </p></div>
                                        <div className="img-div"><img src={Alka} className="img-fluid img-members" alt="SISFS Logo" /><p className="card-heading"><b>Dr Alka Sharma</b></p><p className="card-text">Advisor DBT
                                        </p></div>
                                        <div className="img-div"><img src={Praveen} className="img-fluid img-members" alt="SISFS Logo" /><p className="card-heading"><b>Shri Praveen Roy</b></p><p className="card-text">Head, NEB Division
                                        </p></div>
                                    </div>
                                </div>
                                <div className="row d-block">
                                    <div className="img-div-row">
                                        
                                        {/* <div className="img-div"><img src={Jeet} className="img-fluid img-members" alt="SISFS Logo" /><p className="card-heading"><b>Shri Bhuvnesh Kumar</b></p><p className="card-text">Joint Secretary (Startups), MeitY</p></div> */}
                                        <div className="img-div"><img src={Akash} className="img-fluid img-members" alt="SISFS Logo" /><p className="card-heading"><b>Shri Akash Tripathi</b></p><p className="card-text"> CEO MyGov and Joint Secretary (Start Ups), MeitY </p></div>
                                        <div className="img-div"><img src={Chintan} className="img-fluid img-members" alt="SISFS Logo" /><p className="card-heading"><b>Dr Chintan Vaishnav</b></p><p className="card-text">Mission Director, Atal Innovation Mission (AIM), NITI Aayog</p></div>
                                        <div className="img-div"><img src={Bhooshan} className="img-fluid img-members" alt="SISFS Logo" /><p className="card-heading"><b>Dr Neeru Bhooshan</b></p><p className="card-text">Assistant Director General (IP &TM), ICAR, Department of Agricultural Research and Education
                                        </p></div>
                                        <div className="img-div"><img src={Gopinath} className="img-fluid img-members" alt="SISFS Logo" /><p className="card-heading"><b>Shri Saji Gopinath</b></p><p className="card-text">Professor, IIM Kozhikode
                                        </p></div>
                                        {/* <div className="img-div"><img src={Raman} className="img-fluid img-members" alt="SISFS Logo" /><p className="card-heading"><b>Shri Ramanathan Ramanan</b></p><p className="card-text">Mission Director AIM & AS NITI Aayog */}
                                        {/* </p></div> */}



                                    </div>
                                </div>
                                <div className="row d-block">
                                    <div className="img-div-row">
                                      
                                        <div className="img-div"><img src={Karthik} className="img-fluid img-members" alt="SISFS Logo" /><p className="card-heading"><b>Shri Karthik Reddy</b></p><p className="card-text">Chairperson, IVCA, Co-founder and Managing Partner, Blume Ventures)
                                        </p></div>
                                        <div className="img-div"><img src={Padmaja} className="img-fluid img-members" alt="SISFS Logo" /><p className="card-heading"><b>Smt. Padmaja Ruparel</b></p><p className="card-text">Co-founder IAN & Founding Partner IAN Fund
                                        </p></div>
                                        <div className="img-div"><img src={Anjali} className="img-fluid img-members" alt="SISFS Logo" /><p className="card-heading"><b>Smt. Anjali Bansal</b></p><p className="card-text">Founder, Avaana Capital</p></div>
                                        <div className="img-div"><img src={Shruti} className="img-fluid img-members" alt="SISFS Logo" /><p className="card-heading"><b>Smt. Sruthi Kannan</b></p><p className="card-text">Head, Cisco Launchpad</p></div>



                                    </div>
                                </div>
                                <div className="row d-block">
                                    <div className="img-div-row">
                                        <div className="img-div"><img src={Apoorv} className="img-fluid img-members" alt="SISFS Logo" /><p className="card-heading"><b>Dr Apoorva Ranjan Sharma</b></p><p className="card-text">Co-founder Venture Catalysts & 9 Unicorns</p></div>
                                    </div>
                                </div>
                            </div>
                            <div id="what-startup-5" ref={startupRef} className="right-cols eligibilityScroll" >
                                <div className="main-heading right-heading">Eligibility Criteria</div>
                                <div className="objectives-nav">
                                    <ul className="nav nav-tabs" >
                                        <li className={activeIndex == "startup" ? "active" : ""} onClick={() => changeActive('startup')}>
                                            <a href="#tab-startup" data-toggle="tab">Startups</a>
                                        </li>
                                        <li className={activeIndex == "incubator" ? "active" : ""} onClick={() => changeActive('incubator')}><a href="#tab-incubator" data-toggle="tab">Incubators</a>
                                        </li>
                                    </ul>
                                    {/* <ul className="nav nav-tabs">
                                            <li className="active">
                                                <a href="#tab-startup" data-toggle="tab">Startups</a>
                                            </li>
                                            <li><a href="#tab-incubator" data-toggle="tab">Incubators</a>
                                            </li>
                                        </ul> */}
                                </div>
                                <div className="tab-content">
                                    <div className={activeTab == "startup" ? "tab-pane active" : "tab-pane"} id="tab-startup">
                                        <div className="ordered-list wrapper about-ordered-list">
                                            <ol>
                                                <li>A startup, recognized by DPIIT, incorporated not more than 2 years ago at the time of application.<br />To get DPIIT-recognized, please visit <a target="_blank" href="https://www.startupindia.gov.in/content/sih/en/startupgov/startup_recognition_page.html"><u className="link-orange-text">https://www.startupindia.gov.in/content/sih/en/startupgov/startup_recognition_page.html</u></a></li>
                                                <li>The startup must have a business idea to develop a product or a service with a market fit, viable commercialization, and scope of scaling.</li>
                                                <li>The startup should be using technology in its core product or service, or business model, or distribution model, or methodology to solve the problem being targeted.</li>
                                                <li>Preference would be given to startups creating innovative solutions in sectors such as social impact, waste management, water management, financial inclusion, education, agriculture, food processing, biotechnology, healthcare, energy, mobility, defence, space, railways, oil and gas, textiles, etc.</li>
                                                <li>Startup should not have received more than Rs 10 lakh of monetary support under any other Central or State Government scheme. This does not include prize money from competitions and grand challenges, subsidized working space, founder monthly allowance, access to labs, or access to prototyping facility.</li>
                                                <li>Shareholding by Indian promoters in the startup should be at least 51% at the time of application to the incubator for the scheme, as per Companies Act, 2013 and SEBI (ICDR) Regulations, 2018.</li>
                                                <li>A startup applicant can avail seed support in the form of grant and debt/convertible debentures each once as per the guidelines of the scheme.</li>
                                            </ol>
                                        </div>
                                    </div>
                                    <div className={activeTab == "incubator" ? "tab-pane active" : "tab-pane"} id="tab-incubator">
                                        <div className="ordered-list wrapper about-ordered-list">
                                            <ol>
                                                <li>The incubator must be a legal entity:<br />
                                                    - A society registered under the Societies Registration Act 1860, or<br />
                                                    - A Trust registered under the Indian Trusts Act 1882, or<br />
                                                    - A Private Limited company registered under the Companies Act 1956 or the Companies Act 2013, or<br />
                                                    - A statutory body created through an Act of the legislature
                                                </li>
                                                <li>The incubator should be operational for at least two years on the date of application to the scheme</li>
                                                <li>The incubator must have facilities to seat at least 25 individuals</li>
                                                <li>The incubator must have at least 5 startups undergoing incubation physically on the date of application</li>
                                                <li>The incubator must have a full-time Chief Executive Officer, experienced in business development and entrepreneurship, supported by a capable team responsible for mentoring startups in testing and validating ideas, as well as in finance, legal, and human resources functions </li>
                                                <li>The incubator should not be disbursing seed fund to incubatees using funding from any third-party private entity</li>
                                                <li>The incubator must have been assisted by the Central/State Government(s)</li>
                                                <li>In case the incubator has not been assisted by the Central or State Government(s):<br />
                                                    - The incubator must be operational for at least three years<br />
                                                    - Must have at least 10 separate startups undergoing incubation in the incubator physically on the date of application<br />
                                                    - Must present audited annual reports for the last 2 years
                                                </li>
                                                <li>Any additional criteria as may be decided by the Experts Advisory Committee (EAC)</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>

                                <a className="btn btn-large btn-sisfs btn-yellow-primary" data-toggle="modal" data-target="#ApplyNowModal" id="apply">Apply Now</a>
                            </div>


                        </div>
                    </div>
                </div>

                <div className="top-arrow">
                    <div id="myBtn" onClick={scrollTop}>
                        <img src={Toparrow} className="img-fluid" alt="SISFS Logo" /> TOP
                    </div>
                </div>

            </div>
        </main>
    );
}


export default About;
