import React, { useEffect, useState } from "react";
import {
  PromoterDetails,
  StartupTeamModel,
  TeamDetails,
} from "../../../models/startup/StartupTeamModel";
import IncrementDecrement from "../../common/controls/IncrementDecrement";
import DeleteRow from "../../../images/delete-row.svg";
import { initStartupTeam } from "../../../models/startup/StartupModel";
import {
  requestType,
  StartupUploadTypeEnum,
  StepperStartupEnum,
} from "../../../utils/Enums";
import { useErrorStateValue } from "../../../providers/ErrorProvider";
import ValidationMessage from "../../common/controls/ValidationMessage";
import FileUploader from "@amodv/react-file-uploader";
import { FileUploadTypeModel } from "../../../models/FileUploadModel";
import { post } from "../../../effects/api/api.common";
import {
  STARTUP_DETAIL_UPLOAD,
  STARTUP_FILE_REMOVE,
  STARTUP_FILE_UPLOAD,
} from "../../../constants/api";
import { useHistory } from "react-router-dom";
import ConfirmationDialog from "../../common/controls/ConfirmationDialog";
import { getSessionStorageValue } from "../../../effects/utils/session-storage";
import pdf from "../../../images/PDF.svg";
import xls from "../../../images/xls.svg";
import doc from "../../../images/doc.svg";
import Allround from "../../../images/Allround.svg";
import error from '../../../images/error.svg';
interface IStartupTeamProps {
  stepper: any;
  startupTeamModel: StartupTeamModel;
  tabIndex: number;
  onStepSave: (
    startupTeam: StartupTeamModel,
    stepIndex: number,
    request: number
  ) => void;
  onChildUpdate: (startupTeam: StartupTeamModel) => void;
  onPreviousClick: () => void;
  errors: Array<string>;
  apiCalled: Boolean;
  applicationID: any;
  disabledForm: any;
}
interface IIKeyValueIndexer {
  [key: string]: boolean;
}

interface IStartupTeamState { }

/** Render start-up application screen */
function StartupTeamComponent(props: IStartupTeamProps) {
  const {
    stepper,
    onChildUpdate,
    startupTeamModel,
    apiCalled,
    applicationID,
    disabledForm,
  } = props;

  // @ts-ignore
  const [{ errors }, dispatchError] = useErrorStateValue();

  const [startupTeam, setStartupTeam] =
    useState<StartupTeamModel>(initStartupTeam);

  const [teams, setTeams] = useState<Array<TeamDetails>>([
    new TeamDetails(0, "", 0),
  ]);
  const [openUploadBlock, setOpenUploadBlock] = useState<IIKeyValueIndexer>();
  const [loader, setLoader] = useState(false);
  const [failDialogData, setFailDialogData] = useState({
    header: "",
    text: "",
  });
  const [showIncubatorFailureModal1, setShowIncubatorFailureModal1] =
    useState(false);
  useEffect(() => {
    startupTeam.team_data = teams;
    setStartupTeam(startupTeamModel);
    // props.onStepSave(
    //   startupTeam,
    //   StepperStartupEnum.StartupTeam,
    //   requestType.propertyChange
    // );
  }, []);

  useEffect(() => {
    setStartupTeam(startupTeamModel);
    if (startupTeamModel.team_data.length > 0) {
      setTeams(startupTeamModel.team_data);
    }
  }, [startupTeamModel.team_data]);

  // triggers whenever user makes any changes in the form for select and input type
  const handleChange = (
    evt: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const name = evt.target.name;
    const value = evt.target.value;

    let updatedStartupTeam: StartupTeamModel = initStartupTeam;

    if (
      evt.target.name == "ceo_firstname" ||
      evt.target.name == "ceo_lastname"
    ) {
      updatedStartupTeam = {
        ...startupTeam,
        [name]: value.replace(/[^a-zA-Z ]/gi, ""),
      };
    } else {
      updatedStartupTeam = {
        ...startupTeam,
        [name]: value,
      };
    }

    // setStartupTeam((prevState: StartupTeamModel) => {
    //     return {
    //         ...prevState,
    //         [name]: value.length ? value : "",
    //     };
    // });

    setLocalStateAndUpdateParent(updatedStartupTeam);
  };

  // handle input change for team rows - team name
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    // const teamDetail = new TeamDetails(0, e.target.value, 1);
    // teams[index] = teamDetail;
    // const updatedTeams = JSON.stringify(teams);
    // setTeams(JSON.parse(updatedTeams));
    // startupTeam.team_data = teams;
    // let updatedStartupTeam: StartupTeamModel = initStartupTeam;

    // updatedStartupTeam = {
    //     ...startupTeam,
    //     team_data: teams,
    // };
    // setLocalStateAndUpdateParent(updatedStartupTeam);

    const name = event.currentTarget.name;
    const value = event.currentTarget.value;

    let updatedStartupTeam: StartupTeamModel = initStartupTeam;

    updatedStartupTeam = {
      ...startupTeam,
      [name]: value,
    };

    setLocalStateAndUpdateParent(updatedStartupTeam);
  };
  const [sumOfEmpVal, setSumOfEmpVal] = useState(false);
  const [fullempval, setfullempval] = useState<any>("");
  const initialValue = startupTeam.fulltime_employees_no
  const [maxValue, setMaxValue] = useState(initialValue);
  const [exceedFileSize, setExceedFileSize] = useState(false)
  const [totalInputValue, setTotalInputValue] = useState<number>(0);
  useEffect(() => {
    console.log(totalInputValue, startupTeam.fulltime_employees_no, "inputval"); // don't remove the console
    if (totalInputValue == startupTeam.fulltime_employees_no) {
      setSumOfEmpVal(true);
      // setMaxValue(0);
    } else {
      setSumOfEmpVal(false);
    }
    // console.log(maxValue, "maxValue")
  }, [totalInputValue]);
  // handle input change for team rows- no of employee
  const handleIncrementDecrementChangeInput = (
    name: string,
    value: number,
    index: number
  ) => {
    // const teamDetail = teams[index];
    // teamDetail.no_employee = value;
    // teams[index] = teamDetail;
    // const updatedTeams = JSON.stringify(teams);
    // setTeams(JSON.parse(updatedTeams));
    // let updatedStartupTeam: StartupTeamModel = initStartupTeam;

    // updatedStartupTeam = {
    //     ...startupTeam,
    //     team_data: JSON.parse(updatedTeams),
    // };
    // setLocalStateAndUpdateParent(updatedStartupTeam);

    // let updatedStartupTeam: StartupTeamModel = initStartupTeam;

    const teamDetail = teams[index];
    if (typeof teamDetail.no_employee === "string") {
      value = Number(teamDetail.no_employee);
    }
    teamDetail.no_employee = value;
    setfullempval(value);
    const sum = teams.reduce((acc, team, i) => {
      if (i !== index) {
        return acc + team.no_employee;
      }
      return acc;
    }, 0);

    const maxValuee = startupTeam.fulltime_employees_no - sum;
    setMaxValue(maxValuee);
    teamDetail.no_employee = value;
    teams[index] = teamDetail;
    const updatedTeams = JSON.stringify(teams);
    setTeams(JSON.parse(updatedTeams));
    let updatedStartupTeam: StartupTeamModel = initStartupTeam;

    updatedStartupTeam = {
      ...startupTeam,
      team_data: JSON.parse(updatedTeams),
    };
    setLocalStateAndUpdateParent(updatedStartupTeam);

    // if (index !== undefined && index > -1) {
    //     startupTeam.team_data[index]["no_employee"] = value;
    // }

    // setLocalStateAndUpdateParent(startupTeam);
    let add = 0;
    teams.forEach((team) => {
      add += team.no_employee;
    });
    setTotalInputValue(add);
  };
  const [show, setShow] = useState(0);
  // add and removes the teams
  const addRemoveTeam = (rowIndex?: number) => {
    // if (rowIndex || rowIndex === 0) {
    //     const teamRows = teams.filter((_, index: number) => index !== rowIndex);
    //     setTeams(teamRows);
    //     startupTeam.team_data = teamRows;
    // } else {
    //     const team = new TeamDetails(teams.length, "", 1);
    //     setTeams([...teams, team]);
    //     startupTeam.team_data = [...teams, team];
    // }

    // let updatedStartupTeam: StartupTeamModel = initStartupTeam;

    var fullvalue = startupTeam.fulltime_employees_no;
    let sum = 0;
    let key = "no_employee";

    startupTeam.team_data.map((obj: any) => {
      if (obj.hasOwnProperty(key)) {
        let val = (sum += parseInt(obj[key]));

        setShow(val);

        if (rowIndex || rowIndex === 0) {
          const teamRows = teams.filter(
            (_, index: number) => index !== rowIndex
          );
          setTeams(teamRows);
          startupTeam.team_data = teamRows;
          if (val != fullvalue) {
            setSumOfEmpVal(false);
          }
        } else if (fullempval == fullvalue || val == fullvalue) {
          const teamRows = teams.filter(
            (_, index: number) => index !== rowIndex
          );
          setTeams(teamRows);
          startupTeam.team_data = teamRows;
        } else if (val < fullvalue) {
          const team = new TeamDetails(teams.length, "", 0);
          //console.log(team,"new")
          setTeams([...teams, team]);
          startupTeam.team_data = [...teams, team];
          //console.log(fullempval,fullvalue,"team")
        } else {
          return;
        }
      }
    });
    const total = teams.reduce((sum, team) => sum + team.no_employee, 0);
    // if (rowIndex || rowIndex === 0) {
    //     const teamRows = startupTeam.team_data.filter(
    //         (_, index: number) => index !== rowIndex
    //     );
    //     startupTeam.team_data = teamRows;
    // } else {
    //     const team = new TeamDetails(startupTeam.team_data.length, "", 1);
    //     startupTeam.team_data = [
    //         ...startupTeam.team_data,
    //         team,
    //     ];
    // }
    // setLocalStateAndUpdateParent(startupTeam);
  };

  // on increament decrement control change (not for teams[])
  const handleIncrementDecrementChange = (name: string, value: number) => {
    if (!isNaN(value)) {
      // incubator[name] = value ? value : "";
      // setStartupTeam((prevState: StartupTeamModel) => {
      //     return {
      //         ...prevState,
      //         [name]: value ? value : "",
      //     };
      // });

      let updatedStartupTeam: StartupTeamModel = initStartupTeam;

      updatedStartupTeam = {
        ...startupTeam,
        [name]: value ? value : "",
      };
      setLocalStateAndUpdateParent(updatedStartupTeam);
    }
  };
  // triggers when team is updated
  const onTeamChange = (
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >,
    index: number
  ) => {
    // const value = event.currentTarget.value;
    // let updatedStartupTeam: StartupTeamModel = initStartupTeam;

    teams[index].team_name = event.target.value.replace(/[^a-zA-Z ]/gi, "");
    const updatedTeams = JSON.stringify(teams);
    setTeams(JSON.parse(updatedTeams));
    startupTeam.team_data = teams;

    // startupTeam.team_data[index]["team_name"] = value;
    // setLocalStateAndUpdateParent(startupTeam);
  };

  const history = useHistory();
  const [popUpName, setPopUpName] = useState("");
  const setMeetingModal = (fieldName: string) => {
    setPopUpName(fieldName);
  };
  const applicationPage = () => {
    history.push({
      pathname: "/startup_status",
    });
  };
  // triggers when promoter is updated
  const onPromoterChange = (
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >,
    index: number
  ) => {
    const value = event.currentTarget.value;
    const nameVal = event.currentTarget.name;
    // let updatedStartupTeam: StartupTeamModel = initStartupTeam;
    if (nameVal == "promoter_name") {
      startupTeam.promoter_data[index].name = value.replace(/[^a-zA-Z ]/gi, "");
    } else if (nameVal == "aadhar_number") {
      if (value.length <= 12) {
        startupTeam.promoter_data[index].aadhar_number = value
          .replace(/[^0-9]/gi, "")
          .toString();
      } else {
        return;
      }
    }

    setLocalStateAndUpdateParent(startupTeam);
  };

  // add and removes the teams
  const addRemovePromoter = (rowIndex?: number) => {
    // if (rowIndex || rowIndex === 0) {
    //     const teamRows = teams.filter((_, index: number) => index !== rowIndex);
    //     setTeams(teamRows);
    //     startupTeam.team_data = teamRows;
    // } else {
    //     const team = new TeamDetails(teams.length, "", 1);
    //     setTeams([...teams, team]);
    //     startupTeam.team_data = [...teams, team];
    // }

    // let updatedStartupTeam: StartupTeamModel = initStartupTeam;
    if (rowIndex || rowIndex === 0) {
      const teamRows = startupTeam.promoter_data.filter(
        (_, index: number) => index !== rowIndex
      );
      startupTeam.promoter_data = teamRows;
    } else {
      const team = new PromoterDetails(
        startupTeam.promoter_data.length,
        "",
        ""
      );
      startupTeam.promoter_data = [...startupTeam.promoter_data, team];
    }
    setLocalStateAndUpdateParent(startupTeam);
  };

  const setLocalStateAndUpdateParent = (
    updatedStartupTeam: StartupTeamModel
  ) => {
    setStartupTeam(updatedStartupTeam);

    setTimeout(() => {
      onChildUpdate(updatedStartupTeam);
    }, 0);
  };
  const dismissModal = () => {
    setExceedFileSize(false)
  };
  const getFileDetails = (file: any, key: string) => {
    let file_name = "";
    file_name =
      file != undefined && file != null && file.length > 1
        ? file.substr(file.lastIndexOf("/") + 1, file.length)
        : "";

    const file_type =
      file != undefined && file != null && file.length > 1
        ? file.substr(file.lastIndexOf(".") + 1, file.length)
        : "";

    if (key == "name") return file_name;
    if (key == "file") return file != undefined ? file.toString() : "";
    if (key == "type") return file != undefined ? file_type.toString() : "";
  };

  const onFileUploadComplete = (files: Array<File>, type: string) => {
    let indexes: Array<string> = [];
    let updatedUploadDocuments = Object.assign({}, startupTeam);
    for (const file of files) {
      // if already upladed then skip
      const fileData = startupTeam as any;
      let updatedFileData: Array<FileUploadTypeModel> = [];
      // const fileExists = fileData[type].findIndex(
      //     (uf: FileUploadTypeModel) => uf.file.name === file.name && uf.file_type == type
      // );

      // if (fileExists > 1) continue;
      // const fileExists = fileData.map(
      //     (uf: FileUploadTypeModel) => uf.file.name !== file.name && uf.file_type !== type
      // );
      // console.log(fileExists,"fileExists")
      // const fileExists:any = fileData[type].some((uf: FileUploadTypeModel) => uf.file.name === file.name && uf.file_type === type);
      // if (fileExists) continue;

      const formData = new FormData();
      // Update the formData object
      // console.log(fileData,"filefile")

      // if (applicationID == "") {
      //     formData.append("file", file);
      //     formData.append("application_id", '0');
      //     formData.append("file_type", type);
      // }
      // else {
      const user: any = getSessionStorageValue("user");
      const userInfo = JSON.parse(user);
      formData.append("file", file);
      formData.append(
        "application_id",
        applicationID === "" ? "0" : applicationID
      );
      formData.append("file_type", type);
      formData.append("logged_user_id", userInfo.id);
      // }
      // let updatedOtherDetail = JSON.parse(JSON.stringify(otherDetail));

      //   console.log("updatedotherdetail2", updatedOtherDetail)

      setLoader(true);

      post(STARTUP_FILE_UPLOAD, formData).then((response: any) => {
        setLoader(false);

        if (!response.data) return;
        // onUpdateApplicationID(response.data.data.applicationId)
        if (response.data.result.message == "File already exists.") {
          // setFailDialogData({
          //     "header":"Oops",
          //     "text":response.data.result.message
          //   })
          //   setShowIncubatorFailureModal1(true);
          //   return;
        } else {
          updatedFileData = [
            ...updatedUploadDocuments.promoters_aadhar_cards.map(
              (uf: any): FileUploadTypeModel => {
                return new FileUploadTypeModel(
                  uf.file_id,
                  uf.file_type,
                  uf.file
                );
              }
            ),
            new FileUploadTypeModel(
              response.data.result.file_id,
              StartupUploadTypeEnum.PromotersAadhar,
              file
            ),
          ];
          updatedUploadDocuments.promoters_aadhar_cards = updatedFileData;
          // updatedOtherDetail.upload_pitch_deck = updatedFileData;
          // setOtherDetail(updatedOtherDetail);
          setStartupTeam((prevState) => {
            const updatedFileOther = {
              ...prevState,
              relavent_document_excel: [
                ...prevState.promoters_aadhar_cards.map(
                  (uf: any): FileUploadTypeModel => {
                    return new FileUploadTypeModel(
                      uf.file_id,
                      uf.file_type,
                      uf.file
                    );
                  }
                ),
                new FileUploadTypeModel(
                  response.data.result.file_id,
                  StartupUploadTypeEnum.PromotersAadhar,
                  file
                ),
              ],
            };
            return updatedFileOther;
          });
          setLocalStateAndUpdateParent(updatedUploadDocuments);

          // onStepSave(
          //   StepperEnum.OtherDetail,
          //   updatedOtherDetail,
          //   requestType.propertyChange
          // );

          // de-allocate references.
          formData.delete("file");
          formData.delete("startup_application_id");
          formData.delete("file_type");

          // setPdfOnly({ ...pdfOnly, [type]: false });
          setLoader(false);
        }
      });
    }

    if (indexes.length) {
      indexes.map((ind) => {
        const position: number = files.findIndex((file) => file.name === ind);
        files.splice(position, 1);
      });
    }
  };

  const onFileDelete = (file: File, type: string) => {
    let remainingFiles: Array<FileUploadTypeModel> = [];
    let fileToBeDeleted: any;

    fileToBeDeleted = startupTeam.promoters_aadhar_cards.find(
      (uploadedFile: FileUploadTypeModel) =>
        getFileDetails(uploadedFile.file, "name") === file.name
    );

    if (!fileToBeDeleted) return;
    remainingFiles = startupTeam.promoters_aadhar_cards.filter(
      (uf: FileUploadTypeModel) => uf.file !== fileToBeDeleted.file
    );

    startupTeam.promoters_aadhar_cards = remainingFiles;
    setLocalStateAndUpdateParent(startupTeam);

    // delete the file
    const user: any = getSessionStorageValue("user");
    const userInfo = JSON.parse(user);
    setLoader(true);
    post(STARTUP_FILE_REMOVE, {
      application_id: applicationID,
      file_id: fileToBeDeleted.file_id,
      logged_user_id: userInfo.id,
    }).then((response: any) => {
      if (response.data) {
        setLoader(false);
      }
    });
    // setOtherDetail(JSON.parse(JSON.stringify(otherDetail)));
  };
  return (
    <>
      {loader && (
        <div className="spinner-border custom-spinner" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}
      <fieldset disabled={loader}>
        <div className="form-content">
          <fieldset disabled={disabledForm}>
            <div className="form-group required row">
              <label className="col-sm-4 col-form-label">
                Name & Background of the CEO
              </label>
              <div className="col-sm-8">
                <div className="form-row">
                  <div className="col">
                    <input
                      type="text"
                      name="ceo_firstname"
                      className={`${errors["StartupTeam"] &&
                        errors["StartupTeam"]["ceo_firstname"]
                        ? `form-control error`
                        : "form-control"
                        }`}
                      placeholder="First Name"
                      value={startupTeam.ceo_firstname}
                      onChange={handleChange}
                      required={true}
                      maxLength={255}
                    />
                    {errors &&
                      errors.StartupTeam &&
                      errors.StartupTeam["ceo_firstname"].length > 0 && (
                        <ValidationMessage
                          className="error"
                          message={errors.StartupTeam["ceo_firstname"]}
                        />
                      )}
                  </div>

                  <div className="col">
                    <input
                      type="text"
                      name="ceo_lastname"
                      className={`${errors["StartupTeam"] &&
                        errors["StartupTeam"]["ceo_lastname"]
                        ? `form-control error`
                        : "form-control"
                        }`}
                      placeholder="Last Name"
                      value={startupTeam.ceo_lastname}
                      onChange={handleChange}
                      required={true}
                      maxLength={255}
                    />
                    {errors &&
                      errors.StartupTeam &&
                      errors.StartupTeam["ceo_lastname"].length > 0 && (
                        <ValidationMessage
                          className="error"
                          message={errors.StartupTeam["ceo_lastname"]}
                        />
                      )}
                  </div>
                </div>

                <div>
                  <textarea
                    className={`${errors["StartupTeam"] &&
                      errors["StartupTeam"]["ceo_description"]
                      ? `form-control error`
                      : "form-control"
                      }`}
                    rows={3}
                    placeholder="Describe Background"
                    name="ceo_description"
                    value={startupTeam.ceo_description}
                    onChange={handleChange}
                    required={true}
                    maxLength={2000}
                  ></textarea>
                  <small>Max. 2000 characters</small>
                  {errors &&
                    errors.StartupTeam &&
                    errors.StartupTeam["ceo_description"].length > 0 && (
                      <ValidationMessage
                        className="error"
                        message={errors.StartupTeam["ceo_description"]}
                      />
                    )}
                </div>
              </div>
            </div>

            <div className="form-group row">
              <div className="col-sm-4">
                <label className="col-form-label">LinkedIn Profile</label>
                <small className="pt-0">( Optional )</small>
              </div>

              <div className="col-sm-8">
                <input
                  type="text"
                  name="ceo_linkedin_link"
                  className={
                    errors &&
                      errors.StartupTeam &&
                      errors.StartupTeam["ceo_linkedin_link"].length > 0
                      ? "form-control error"
                      : "form-control"
                  }
                  placeholder="Enter Profile URL"
                  value={startupTeam.ceo_linkedin_link}
                  onChange={handleChange}
                  required={true}
                  maxLength={255}
                />
                {errors &&
                  errors.StartupTeam &&
                  errors.StartupTeam["ceo_linkedin_link"].length > 0 && (
                    <ValidationMessage
                      className="error"
                      message={errors.StartupTeam["ceo_linkedin_link"]}
                    />
                  )}
              </div>
            </div>

            {/* only for wireframe */}
            {/* <div className="form-group required row">
                    <label className="col-sm-4 col-form-label">
                        Promoter Details
                    </label>
                    <div className="col-sm-8">
                        <div className="form-row">
                            <div className="col-5">
                                <span className="col-label">Name of Promoter</span>
                            </div>
                            <div className="col-5 text-left">
                                <span className="col-label">Aadhaar Card Number</span>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="col-5">
                                <input type="hidden" value="team.team_id"></input>
                                <input
                                    type="text"

                                    className="form-control"
                                    placeholder="Enter Name"

                                />

                            </div>
                            <div className="col-5 text-center">
                                <input
                                    type="text"

                                    className="form-control"
                                    placeholder="Enter Aadhaar Number"

                                />
                            </div>
                            <div className="col-2">
                                <div className="number">
                                    {startupTeam.team_data.length > 1 && (
                                        <img
                                            src={DeleteRow}
                                            className="img-fluid margin-top-15"
                                            alt="Delete Logo"

                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="col">
                                <span
                                    className="link-orange-text"

                                >
                                    <u>
                                        <i className="fa fa-plus"></i> Add Promoter
                                    </u>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                        Upload Promoter's Aadhaar Details
                    </label>
                    <div className="col-sm-8">
                        <div className="form-row">
                            <div className="col">

                                <div className="">
                                    <button
                                        className="btn btn-sisfs btn-transparent-primary" >
                                        Upload
                                    </button>

                                    <small>Supported file format - PDF only  |   Max. size: 15 MB</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            {/* testfdfg */}

            <div className="form-group row">
              <label className="col-sm-4 col-form-label">
                Promoter Details
              </label>
              <div className="col-sm-8">
                <small>Fill in details of each promoter of the startup.</small>
                <div className="form-row">
                  <div className="col-5">
                    <span className="col-label">Name of Promoter</span>
                  </div>
                  <div className="col-5 text-left">
                    <span className="col-label">Aadhaar Card Number</span>
                  </div>
                </div>
                {startupTeam.promoter_data.map(
                  (promoter: PromoterDetails, index: number) => (
                    <div key={`promoter_name_${index}`} className="form-row">
                      <div className="col-5">
                        <input
                          type="hidden"
                          value="promoter.promoter_id"
                        ></input>
                        <input
                          type="text"
                          name={`promoter_name`}
                          className="form-control"
                          placeholder="Enter Name"
                          value={promoter.name}
                          onChange={(
                            event: React.ChangeEvent<
                              | HTMLSelectElement
                              | HTMLInputElement
                              | HTMLTextAreaElement
                            >
                          ) => onPromoterChange(event, index)}
                          required={true}
                          maxLength={255}
                        />
                      </div>
                      <div className="col-5 text-center">
                        <input
                          type="text"
                          name="aadhar_number"
                          className="form-control"
                          placeholder="Enter Aadhaar Number"
                          value={promoter.aadhar_number}
                          onChange={(
                            event: React.ChangeEvent<
                              | HTMLSelectElement
                              | HTMLInputElement
                              | HTMLTextAreaElement
                            >
                          ) => onPromoterChange(event, index)}
                          required={true}
                          maxLength={12}
                        />
                      </div>
                      <div className="col-2">
                        <div className="number">
                          {startupTeam.promoter_data.length > 1 && (
                            <img
                              src={DeleteRow}
                              className="img-fluid margin-top-15"
                              alt="Delete Logo"
                              onClick={() => addRemovePromoter(index)}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )
                )}

                <div className="form-row">
                  <div className="col">
                    <span
                      className="link-orange-text"
                      onClick={() => addRemovePromoter()}
                    >
                      <u>
                        <i className="fa fa-plus"></i> Add Promoter
                      </u>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <div className="form-group required row">
            <label className="col-sm-4 col-form-label">
              Upload Aadhaar Details
              {/* <small className="pt-1">( Optional )</small> */}
            </label>
            <div className="col-sm-8">
              <div className="form-row">
                <div className="col">
                  <div className="">
                    <fieldset disabled={disabledForm}>
                      <button
                        className="btn btn-sisfs btn-transparent-primary"
                        onClick={() =>
                          setOpenUploadBlock({ PromotersAadhaar: true })
                        }
                      >
                        Upload
                      </button>

                      {((openUploadBlock &&
                        openUploadBlock["PromotersAadhaar"] &&
                        startupTeam.promoters_aadhar_cards.length === 0) ||
                        startupTeam.promoters_aadhar_cards.length > 0) && (
                          <>
                            <span
                              className="close"
                              onClick={() =>
                                setOpenUploadBlock({ PromotersAadhaar: false })
                              }
                            >
                              <i className="fa fa-close"></i>
                            </span>

                            <FileUploader
                              key={Math.random().toString()}
                              id={StartupUploadTypeEnum.PromotersAadhar}
                              information="Drag & Drop the file or Browse"
                              fileType="application/pdf"
                              buttonLabel="Browse"
                              multiple={true}
                              enablePreview
                              IconComponent={() => (
                                <span className="fa fa-cloud-upload"></span>
                              )}
                              removeIconClass=""
                              existingFiles={startupTeam.promoters_aadhar_cards.filter((item) => (item.file.size) <= 15 * 1024 * 1024).map(
                                (uploadedFile: FileUploadTypeModel) => ({
                                  name: getFileDetails(uploadedFile.file, "name") || uploadedFile.file.name,
                                  url: uploadedFile.file,
                                })
                                // uploadedFile.file
                              )}
                              onUploadFinish={(files: Array<File>) => {
                                for (const file of files) {
                                  if (file.size <= 15 * 1024 * 1024) {
                                    onFileUploadComplete(
                                      files,
                                      StartupUploadTypeEnum.PromotersAadhar
                                    )
                                  } else {
                                    setExceedFileSize(true);
                                    return;
                                  }
                                }

                              }}
                              onFileDelete={(deletedFile: File) =>
                                onFileDelete(
                                  deletedFile,
                                  StartupUploadTypeEnum.PromotersAadhar
                                )
                              }
                            />
                          </>
                        )}
                    </fieldset>
                    {(startupTeam.promoters_aadhar_cards.length > 0 && startupTeam.promoters_aadhar_cards[0].file.size == undefined) ? (
                      <a
                        className="incubator-details-link"
                        data-toggle="modal"
                        data-target="#showMoreDocuments"
                        onClick={() => setMeetingModal("Upload Aadhaar")}
                      >
                        View uploaded Documents
                      </a>
                    ) : (
                      ""
                    )}
                    <small>
                      Supported file format - PDF only | Max. size: 15 MB
                    </small>
                    {errors &&
                      errors.StartupTeam &&
                      errors.StartupTeam["promoters_aadhar_cards"].length >
                      0 && (
                        <ValidationMessage
                          className="error"
                          message={errors.StartupTeam["promoters_aadhar_cards"]}
                        />
                      )}
                  </div>
                </div>
              </div>
              <small>Upload copy of Aadhaar card for each promoter.</small>
            </div>
          </div>
          <hr />

          <fieldset disabled={disabledForm}>
            <div className="form-group required row">
              <label className="col-sm-4 col-form-label">
                No. of full-time employees
              </label>
              <div className="col-sm-8">
                <IncrementDecrement
                  name="fulltime_employees_no"
                  value={
                    startupTeam.fulltime_employees_no > 0
                      ? startupTeam.fulltime_employees_no
                      : 0
                  }
                  onChange={(name: string, value: number) =>
                    handleIncrementDecrementChange(
                      "fulltime_employees_no",
                      value
                    )
                  }
                  minValue={0}
                />
              </div>
            </div>
            {/* only for wireframe end */}

            <div className="form-group required row">
              <label className="col-sm-4 col-form-label">
                List of all teams along with the number of full-time employees
                in each team
              </label>
              <div className="col-sm-8">
                <div className="form-row">
                  <div className="col">
                    <span className="col-label">Team Name</span>
                  </div>
                  <div className="col">
                    <span className="col-label">
                      No. of full time employees
                    </span>
                  </div>
                </div>
                {/* {console.log("Startup Teams", teams)} */}
                {startupTeam.team_data.map(
                  (team: TeamDetails, index: number) => (
                    <div key={`team_name_${index}`} className="form-row">
                      <div className="col-5">
                        <input type="hidden" value="team.team_id"></input>
                        <input
                          type="text"
                          name={`team_name_${index}`}
                          className={`${errors["StartupTeam"] &&
                            errors["StartupTeam"][`team_data`] &&
                            team.team_name.length === 0
                            ? `form-control error`
                            : "form-control"
                            }`}
                          placeholder="Enter"
                          value={
                            startupTeam.fulltime_employees_no == 0
                              ? ""
                              : team.team_name
                          }
                          disabled={
                            startupTeam.fulltime_employees_no == 0 ||
                            startupTeam.fulltime_employees_no == null
                          }
                          // onChange={(e) => handleInputChange(e, index)}
                          onChange={(
                            event: React.ChangeEvent<
                              | HTMLSelectElement
                              | HTMLInputElement
                              | HTMLTextAreaElement
                            >
                          ) => onTeamChange(event, index)}
                          required={true}
                          maxLength={255}
                        />
                        {errors["StartupTeam"] &&
                          errors["StartupTeam"][`team_data`] &&
                          team.team_name.length === 0 && (
                            <ValidationMessage
                              className="col-12 pl-0 error"
                              message={errors["StartupTeam"]["team_data"]}
                            />
                          )}
                      </div>
                      <div className="col-5 text-center">
                        <IncrementDecrement
                          name={`no_employee${index}`}
                          value={
                            startupTeam.fulltime_employees_no == 0 ||
                              startupTeam.fulltime_employees_no < totalInputValue
                              ? 0
                              : team.no_employee
                          }
                          onChange={(name: string, value: number) =>
                            handleIncrementDecrementChangeInput(
                              name,
                              value,
                              index
                            )
                          }
                          disabled={
                            startupTeam.fulltime_employees_no == 0 ||
                            startupTeam.fulltime_employees_no == null ||
                            (team.team_name != undefined &&
                              team.team_name.length == 0)
                          }
                          minValue={0}
                          maxValue={maxValue}
                        />
                      </div>
                      <div className="col-2">
                        <div className="number">
                          {startupTeam.team_data.length > 1 && (
                            <img
                              src={DeleteRow}
                              className="img-fluid margin-top-15"
                              alt="Delete Logo"
                              onClick={() => addRemoveTeam(index)}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )
                )}
                {sumOfEmpVal == true ? (
                  <> </>
                ) : (
                  <>
                    <div className="form-row">
                      <div className="col">
                        <span
                          className="link-orange-text"
                          onClick={() => addRemoveTeam()}
                        >
                          <u>
                            <i className="fa fa-plus"></i> Add Team
                          </u>
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </fieldset>
        </div>
      </fieldset>
      <fieldset disabled={disabledForm}>
        <div className="stepper-footer-section">
          <div
            className="btn-toolbar justify-content-between"
            role="toolbar"
            aria-label="Toolbar with button groups"
          >
            <button
              className="btn btn-sisfs btn-transparent-primary"
              onClick={() => applicationPage()}
            >
              Cancel
            </button>
            <span className="stepper-page-count">
              Step {props.tabIndex} / 7{" "}
            </span>
            <div className="btn-group">
              {props.tabIndex != 1 && (
                <button
                  className="btn btn-sisfs btn-transparent-primary"
                  onClick={props.onPreviousClick}
                >
                  Previous
                </button>
              )}
              <button
                type="button"
                className="btn btn-sisfs btn-yellow-primary"
                onClick={() =>
                  props.onStepSave(
                    startupTeam,
                    StepperStartupEnum.StartupTeam,
                    requestType.save
                  )
                }
              >
                {props.tabIndex == 7 ? "Save" : "Save And Next"}
              </button>
              {props.tabIndex == 7 && (
                <button
                  className="btn btn-sisfs btn-yellow-primary"
                // onClick={() => props.onSubmit}
                >
                  Review & Submit
                </button>
              )}
            </div>
          </div>
        </div>
      </fieldset>
      {/* Show More  */}
      <div
        className="modal fade"
        id="showMoreDocuments"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-border-none ml-18px">
              <h5 className="modal-title " id="exampleModalLongTitle">
                {popUpName} <span className="eac-name-pop-up"></span>
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {/* <div className=""> */}
              {/* 1St Application Documents */}

              {/* General Details documents */}
              {popUpName == "Upload Aadhaar" ? (
                startupTeam.promoters_aadhar_cards != undefined ? (
                  startupTeam.promoters_aadhar_cards.map(
                    (file: any, index: any) => (
                      <div className="row">
                        <div className="col-md-2">
                          {getFileDetails(file.file, "type") == "pdf" ? (
                            <img src={pdf} alt="SISFS Logo" />
                          ) : getFileDetails(file.file, "type") == "doc" ? (
                            <img src={doc} alt="SISFS Logo" />
                          ) : getFileDetails(file.file, "type") == "xls" ? (
                            <img src={xls} alt="SISFS Logo" />
                          ) : (
                            <img src={Allround} alt="SISFS Logo" />
                          )}
                        </div>
                        <div className="col-md-9 left-align">
                          <span className="document-label-value-wrap">
                            {getFileDetails(file.file, "name") || file.file.name}
                          </span>
                          <br />
                          <a
                            // href={file.file}
                            href={file.file}
                            target="_blank"
                            //download="annual_report"
                            className="incubator-details-link"
                          >
                            View
                          </a>
                          {/* /
                            <a
                              href="#"
                              //download={file.file}
                              className="incubator-details-link"
                              onClick={() =>
                                downloadDocument(file.file, file.file)
                              }
                            >
                              Download
                            </a> */}
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <span className="incubator-label-value">-</span>
                )
              ) : (
                ""
              )}

              <div className="row">
                <div className="col-md-12 centerText">
                  <div className="btn-group mt-1-5 ">
                    <button
                      className="tertiary  btn-yellow-primary"
                      data-dismiss="modal"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {exceedFileSize && (
        <div
          className="modal align-middle modal-sisfs d-block"
          id="FormSubmissionError"
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img
                  src={error}
                  className="search mb-2"
                  alt="search"
                  style={{ width: '2rem' }}
                />
                <h4 className="message-orange" style={{ fontFamily: "Roboto Slab" }}>
                  Oops!!!
                </h4>
                <h5 className="message-orange" style={{ fontFamily: "Roboto Slab" }}>
                  File size should not exceed 15Mb<br /><br />
                  Please upload again.
                </h5>
                <div className="modal-action">
                  <button
                    className="btn btn-sisfs btn-yellow-primary"
                    onClick={dismissModal}
                  >
                    OK !
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* duplicate file  */}
      {showIncubatorFailureModal1 && (
        <ConfirmationDialog
          headerText={failDialogData.header}
          bodyText={failDialogData.text}
          // bodyNote={failDialogData.note}
          onDialogClose={() => setShowIncubatorFailureModal1(false)}
          iconClass="showImage"
        />
      )}
    </>
  );
}

export default StartupTeamComponent;
