/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import {  GENERATE_REPORT_RE_EXCEL,CHECK_BOX_Re_DATA } from "../../../constants/api";
import { Button } from "react-bootstrap";
import { post } from "../../../effects/api/api.common";
import { downloadDocument } from "../../../utils/CommonMethods";
import React, { FC } from "react";

let dataHolder: Array<string> = [];

const INCUBATOR_DETAILS = "Incubator Details";
// const APPLICATION_DETAILS = "Application Details";
const BANK_ACCOUNT = "Bank Account/Documentation Details";
const STARTUP_DETAILS = "Startup Details";
const TRANCHE_DETAILS_1 = "1st Tranche Details";
const TRANCHE_DETAILS_2 = "2nd Tranche Details";
const TRANCHE_DETAILS_3 = "3rd Tranche Details";
const TRANCHE_DETAILS_4 = "4th Tranche Details";

const updateDataHolder = (isClicked: boolean, item: string) => {
  if (isClicked) {
    if (!dataHolder.find((i) => item === i)) {
      dataHolder.push(item);
    }
  } else {
    dataHolder = dataHolder.filter((i) => i !== item);
  }
};

interface interfaceProps {
  incubator: Array<any>;
  title: any;
  item: any;
  incubatorSelected: boolean;
  setIncubatorDetailSelected:any;
  setIncubatorFirstDetailSelected:any;
}

const IncubatorDetailItem: FC<interfaceProps> = ({
  incubator,
  title,
   item,
  incubatorSelected,
  setIncubatorDetailSelected,
  setIncubatorFirstDetailSelected
}: interfaceProps) => {
  const [itemSelected, setItemSelecetd] = useState(false);

  useEffect(() => {
    /* if (itemSelected && !incubatorSelected) {
      return;
    } */
    setItemSelecetd(incubatorSelected);
    updateDataHolder(incubatorSelected, item);
    return () => { };
  }, [incubatorSelected]);

  const onClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateDataHolder(event.target.checked, item);
    setIncubatorFirstDetailSelected(false)
    setItemSelecetd(event.target.checked);
  };

  // Table Data
  return (
    <div className="row hover  mb-2">
      <div className="col-10"> {incubator[title][item]}</div>
      <div className="col-2">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={itemSelected}
            onChange={onClick}
            id="flexCheckDefault"
          />
        </div>
      </div>
    </div>
  );
};

interface interfaceBase {
  incubator: Array<any>;
  title: any;
  items: any;
}

const IncubatorDetail: FC<interfaceBase> = ({
  incubator,
  title,
  items,
}: interfaceBase) => {
  const [incubatorDetailSelected, setIncubatorDetailSelected] = useState(false);
  const [incubatorFirstDetailSelected, setIncubatorFirstDetailSelected] = useState(false);

  const onClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIncubatorDetailSelected(event.target.checked);
    setIncubatorFirstDetailSelected(event.target.checked)
  };

  // Table Main Header
  return (
    <div>
      <div className="row">
        <div className="col-10">
          <p className="h4">{title}</p>
        </div>
        <div className="col-2">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={incubatorFirstDetailSelected}
              id="flexCheckDefault"
              onChange={onClick}
            />
          </div>
        </div>
      </div>
      {
        items &&
        items.map((i: any, key: number) => {
          return (
            <div key={key}>
              <IncubatorDetailItem
                title={title}
                incubator={incubator}
                item={i}
                incubatorSelected={incubatorDetailSelected}
                setIncubatorDetailSelected={setIncubatorDetailSelected}
                setIncubatorFirstDetailSelected={setIncubatorFirstDetailSelected}

                
              ></IncubatorDetailItem>
            </div>
          );
        })
      }
    </div >
  );
};

export default function IncubatorReportReapplyComponent() {
  const [incubator, setIncubator] = useState<any>({});

  const downloadExcel = async () => {
    const {
      data: { data, result },
    } = await post(GENERATE_REPORT_RE_EXCEL, dataHolder);
    if (result === "success") {
      downloadDocument(data, "incubator_list");
    }
  };

  const fetchData = async () => {
    const {
      data: { data, result },
    } = await post(CHECK_BOX_Re_DATA, {});
    setIncubator(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getData = (title: string) => {
    return incubator[title] ? Object.keys(incubator[title]) : [];
  };

  return (
    <>
      <div>
        <div className="data1 mx-5 my-5">
          <IncubatorDetail
            title={INCUBATOR_DETAILS}
            incubator={incubator}
            items={getData(INCUBATOR_DETAILS)}
          ></IncubatorDetail>
        </div>
        {/* <div className="data1 mx-5 my-5">
          <IncubatorDetail
            title={APPLICATION_DETAILS}
            incubator={incubator}
            items={getData(APPLICATION_DETAILS)}
          ></IncubatorDetail>
        </div> */}
        <div className="data1 mx-5 my-5">
          <IncubatorDetail
            title={BANK_ACCOUNT}
            incubator={incubator}
            items={getData(BANK_ACCOUNT)}
          ></IncubatorDetail>
        </div>
        <div className="data1 mx-5 my-5">
          <IncubatorDetail
            title={STARTUP_DETAILS}
            incubator={incubator}
            items={getData(STARTUP_DETAILS)}
          ></IncubatorDetail>
        </div>
        <div className="data1 mx-5 my-5">
          <IncubatorDetail
            title={TRANCHE_DETAILS_1}
            incubator={incubator}
            items={getData(TRANCHE_DETAILS_1)}
          ></IncubatorDetail>
        </div>
        <div className="data1 mx-5 my-5">
          <IncubatorDetail
            title={TRANCHE_DETAILS_2}
            incubator={incubator}
            items={getData(TRANCHE_DETAILS_2)}
          ></IncubatorDetail>
        </div>

        <div className="data1 mx-5 my-5">
          <IncubatorDetail
            title={TRANCHE_DETAILS_3}
            incubator={incubator}
            items={getData(TRANCHE_DETAILS_3)}
          ></IncubatorDetail>
        </div>
        <div className="data1 mx-5 my-5">
          <IncubatorDetail
            title={TRANCHE_DETAILS_4}
            incubator={incubator}
            items={getData(TRANCHE_DETAILS_4)}
          ></IncubatorDetail>
        </div>
       

        <div className="data1 buttonCss ">
          <Button
            variant="primary"
            className="buttonCss  btn-yellow-primary tertiary text-uppercase"
            onClick={() => {
              console.log(dataHolder);
              downloadExcel();
            }}
          >
            Generate Record
          </Button>
        </div>
      </div>

    </>
  );
}
