import { AccountFileUploadModel, FileUploadModel } from "../FileUploadModel";

export class StartupAccountDetailsData {
    constructor(
        public id: number,
        public startup_id: number,
        public bank_account_holder_name: string,
        public bank_name: string,
        public account_number: string,
        public account_type: number,
        public bank_ifsc_code: string,
        public bank_micr_code: string,
        public branch_name: string,
        public branch_address: string,
        public created_at: string,
        public updated_at: string,
        public pan_card_name: string,
        public pan_card_location: string,
        public cancelled_cheque_name: string,
        public cancelled_cheque_location: string,
        public pan_card: AccountFileUploadModel,
        public cancelled_cheque: AccountFileUploadModel
    ) {
        this.id = id;
        this.startup_id = startup_id;
        this.bank_account_holder_name = bank_account_holder_name;
        this.bank_name = bank_name;
        this.account_number = account_number;
        this.account_type = account_type;
        this.bank_ifsc_code = bank_ifsc_code;
        this.bank_micr_code = bank_micr_code;
        this.branch_name = branch_name;
        this.branch_address = branch_address;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.pan_card_name = pan_card_name;
        this.pan_card_location = pan_card_location;
        this.cancelled_cheque_name = cancelled_cheque_name;
        this.cancelled_cheque_location = cancelled_cheque_location;
        this.pan_card = pan_card;
        this.cancelled_cheque = cancelled_cheque;
    }

}