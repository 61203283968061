import React, { useEffect, useState, useRef } from 'react'
import { post } from '../../../../effects/api/api.common';
import { SPOC_DETAILS, UPDATE_SPOC_DETAILS } from '../../../../constants/api';
import { getSessionStorageValue } from '../../../../effects/utils/session-storage';
import edit from '../../../../images/edit.svg';
import remove from '../../../../images/ReturnsIcons/trash-img.svg';
declare var $: any;
const ContactDetails = () => {
    const [contactId, setContactId] = useState("1");
    const [otherEmaiId, setOtherEmailId] = useState("0");
    const [checkUpdate, setUpdate] = useState(false)
    const [spocDetails, setSpocDetails] = useState({
        representative_name: "",
        representative_designation: "",
        representative_mobile_no: "",
        representative_email: "",
        representative_name_1: "",
        representative_designation_1: "",
        representative_mobile_no_1: "",
        representative_email_1: "",
        other_email: ""
    })
    const [validation, setValidation] = useState({
        representative_name: false,
        representative_designation: false,
        representative_mobile_no: false,
        representative_email: false,
        representative_name_1: false,
        representative_designation_1: false,
        representative_mobile_no_1: false,
        representative_email_1: false,
        other_email: false
    });

    useEffect(() => {
        getSpocDetails();
    }, [])
    const [deleteRecord, setDeleteRecord] = useState('0')
    const [enableInput, setEnableInput] = useState('0')
    const [checkSpoc2Fields, setCheckSpoc2Fields] = useState([])
    const [checkOtherEmail, setCheckOtherEmail] = useState([])
    const checkSpoc2 = [
        "representative_name_1",
        "representative_designation_1",
        "representative_mobile_no_1",
        "representative_email_1"
    ];
    const checkOtherEmailField = ["other_email"]
    const getSpocDetails = () => {
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user)
        const userId = userInfo.id
        const payload = {
            "incubator_id": userId
        }
        post(SPOC_DETAILS, payload).then((response: any) => {
            if (!response || !response.data.data) return;
            console.log(response.data.data[0]);
            setSpocDetails(response.data.data[0]);
            setEnableInput('0');
            setCheckOtherEmail(checkSpoc2Values(response.data.data[0], checkOtherEmailField));
            setCheckSpoc2Fields(checkSpoc2Values(response.data.data[0], checkSpoc2))
        })
    }
    const checkSpoc2Values = (data: any, fields: any) => {
        let missingValues: any = [];
        fields.forEach((field: any) => {
            if (!data[field]) {
                missingValues.push(field);
            }
        });
        return missingValues;
    }
    const editSpoc = (id: any) => {
        console.log(id)
        setEnableInput(id)
    }
    const addEmail = (id: any) => {
        setOtherEmailId(id)
        if (id == "2") {
            setSpocDetails(prevState => ({
                ...prevState,
                other_email: ""
            }));
            const payload: any = {
                ...spocDetails,
                other_email: "",
            };
            spocDetails.other_email?.length > 0 && updateSpocDetails(payload, "addEmail")
        }
    }
    const addContact = (id: any) => {
        setContactId(id)
        if (id == "1") {

            setSpocDetails((prevState) => ({
                ...prevState,
                representative_name_1: "",
                representative_designation_1: "",
                representative_mobile_no_1: "",
                representative_email_1: "",
                other_email: prevState.other_email
            }));

            const payload = {
                ...spocDetails,
                representative_name_1: "",
                representative_designation_1: "",
                representative_mobile_no_1: "",
                representative_email_1: "",
            }
            updateSpocDetails(payload, "addContact")
        }
        setValidation((prevState) => ({
            ...prevState,
            representative_name_1: false,
            representative_designation_1: false,
            representative_mobile_no_1: false,
            representative_email_1: false,
        }))

    }

    useEffect(() => {

    }, [contactId, setContactId])
    const handleChange = (
        evt: React.ChangeEvent<
            HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
        >, id: any
    ) => {
        const { name, value } = evt.target;
        editSpoc(id)
        if (name == "representative_mobile_no" || name == "representative_mobile_no_1") {
            setSpocDetails(prevState => ({
                ...prevState,
                [name]: value.replace(/[^0-9]/ig, '')
            }));
        } else {
            setSpocDetails(prevState => ({
                ...prevState,
                [name]: value
            }));
        }

        setValidation(values => ({
            ...values,
            [name]: false
        }));
    };
    const validateEmail = (email: string) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    const validateMobile = (mobile: string) => {
        const mobileRegex = /^\d{10}$/;
        return mobileRegex.test(mobile);
    };

    const validateFields = (contactId: string) => {
        const newValidationState = { ...validation };
        let isValid = true;
        if (otherEmaiId == "1") {
            if (!spocDetails.other_email || !validateEmail(spocDetails.other_email)) {
                newValidationState.other_email = true;
                isValid = false;
            } else {
                newValidationState.other_email = false;
            }
        }

        if (contactId === "2") {
            if (!spocDetails.representative_name_1 || spocDetails.representative_name_1.trim() === "") {
                newValidationState.representative_name_1 = true;
                isValid = false;
            }
            if (!spocDetails.representative_designation_1 || spocDetails.representative_designation_1.trim() === "") {
                newValidationState.representative_designation_1 = true;
                isValid = false;
            }
            if (!spocDetails.representative_mobile_no_1 || !validateMobile(spocDetails.representative_mobile_no_1)) {
                newValidationState.representative_mobile_no_1 = true;
                isValid = false;
            }
            if (!spocDetails.representative_email_1 || !validateEmail(spocDetails.representative_email_1)) {
                newValidationState.representative_email_1 = true;
                isValid = false;
            }

            // Validate representative fields
            if (!spocDetails.representative_name || spocDetails.representative_name.trim() === "") {
                newValidationState.representative_name = true;
                isValid = false;
            }
            if (!spocDetails.representative_designation || spocDetails.representative_designation.trim() === "") {
                newValidationState.representative_designation = true;
                isValid = false;
            }
            if (!spocDetails.representative_mobile_no || !validateMobile(spocDetails.representative_mobile_no)) {
                newValidationState.representative_mobile_no = true;
                isValid = false;
            }
            if (!spocDetails.representative_email || !validateEmail(spocDetails.representative_email)) {
                newValidationState.representative_email = true;
                isValid = false;
            }
        } else {
            if (!spocDetails.representative_name || spocDetails.representative_name.trim() === "") {
                newValidationState.representative_name = true;
                isValid = false;
            }
            if (!spocDetails.representative_designation || spocDetails.representative_designation.trim() === "") {
                newValidationState.representative_designation = true;
                isValid = false;
            }
            if (!spocDetails.representative_mobile_no || !validateMobile(spocDetails.representative_mobile_no)) {
                newValidationState.representative_mobile_no = true;
                isValid = false;
            }
            if (!spocDetails.representative_email || !validateEmail(spocDetails.representative_email)) {
                newValidationState.representative_email = true;
                isValid = false;
            }
        }

        setValidation(newValidationState);
        return isValid;
    };
    const submitDetails = () => {
        if (!validateFields(contactId)) {
            console.log("Validation failed");
            return;
        }
        const payload: any = {
            ...spocDetails
        };
        updateSpocDetails(payload, "submit");
    }
    const updateSpocDetails = (getPayload: any, type: string) => {
        const user: any = getSessionStorageValue("user");
        const userInfo = JSON.parse(user)
        const userId = userInfo.id

        const payload = {
            ...getPayload,
            "incubator_id": userId
        }
        post(UPDATE_SPOC_DETAILS, payload).then((response: any) => {
            if (!response || !response.data) return;
            getSpocDetails();
            if (type == "submit") setUpdate(true);
        });
    }

    const closeAlert = () => {
        $('#exampleModal').modal('hide');
    }
    return (
        <>
            <div className="modal-content">
                <div className="modal-header modal-header-bottom pr-3 pb-0 pt-2">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" onClick={() => setUpdate(false)} >×</span>
                    </button>
                </div>
                {
                    checkUpdate ? <div className='text-center'>
                        <div className='text-center mb-3'><i className='fa fa-check-circle-o' style={{ fontSize: '36px', color: 'green' }}></i></div>
                        <h4 className='text-center' style={{ color: '#2b408b', fontWeight: '600' }} >SPOC Details Updated Successfully!!</h4>
                        <br />
                        <div className='d-flex align-items-center justify-content-center'>
                            <div className="col-md-4 text-center">
                                <button className="btn btn-sisfs btn-yellow-primary" style={{ padding: '15px' }}
                                    data-dismiss="modal" onClick={() => setUpdate(false)}
                                >
                                    OK!
                                </button>
                            </div>
                        </div>
                        <br />
                    </div> :
                        <>
                            <div className="modal-body pl-4 pr-4 pt-0">
                                <span className="text-center contact-head">
                                    <p className="mb-2" style={{ color: '#e26640' }}>SPOC Details:</p>
                                </span>

                                <div className="card p-3">
                                    <div className="row">
                                        <div className="col-md-1 col-sm-1" style={{ display: 'flex', alignItems: 'end', fontWeight: 'bold' }}>
                                            <p>SPOC 1</p>
                                        </div>
                                        <div className="col-md col-sm">
                                            <div className="form-group row" style={{ display: 'flex', alignItems: 'center' }}>
                                                <label className="col-sm-12 col-md-12 col-form-label" style={{ fontSize: '14px' }}>
                                                    Name <span style={{ color: 'red' }}><sup>*</sup></span>
                                                </label>
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="">
                                                        <input
                                                            className="form-control"
                                                            placeholder="Name of Representative"
                                                            name="representative_name"
                                                            onChange={(e) => handleChange(e, '1')}
                                                            type="text"
                                                            disabled={enableInput != '1'}
                                                            value={spocDetails.representative_name}
                                                            style={{ border: validation.representative_name ? '2px solid red' : '', width: '100%' }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md col-sm">
                                            <div className="form-group row" style={{ display: 'flex', alignItems: 'center' }}>
                                                <label className="col-sm-12 col-md-12 col-form-label" style={{ fontSize: '14px' }}>
                                                    Designation <span style={{ color: 'red' }}><sup>*</sup></span>
                                                </label>
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="">
                                                        <input
                                                            className="form-control"
                                                            placeholder="Designation"
                                                            name="representative_designation"
                                                            onChange={(e) => handleChange(e, '1')}
                                                            type="text"
                                                            disabled={enableInput != '1'}
                                                            value={spocDetails.representative_designation}
                                                            style={{ border: validation.representative_designation ? '2px solid red' : '', width: '100%' }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md col-sm">
                                            <div className="form-group row" style={{ display: 'flex', alignItems: 'center' }}>
                                                <label className="col-sm-12 col-md-12 col-form-label" style={{ fontSize: '14px' }}>
                                                    Email <span style={{ color: 'red' }}><sup>*</sup></span>
                                                </label>
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="">
                                                        <input
                                                            className="form-control"
                                                            placeholder="Email"
                                                            name="representative_email"
                                                            onChange={(e) => handleChange(e, '1')}
                                                            type="text"
                                                            disabled={enableInput != '1'}
                                                            value={spocDetails.representative_email}
                                                            style={{ border: validation.representative_email ? '2px solid red' : '', width: '100%' }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md col-sm">
                                            <div className="form-group row" style={{ display: 'flex', alignItems: 'center' }}>
                                                <label className="col-sm-12 col-md-12 col-form-label" style={{ fontSize: '14px' }}>
                                                    Phone Number<span style={{ color: 'red' }}><sup>*</sup></span>
                                                </label>
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="">
                                                        <input
                                                            className="form-control"
                                                            placeholder="Mobile Number"
                                                            name="representative_mobile_no"
                                                            onChange={(e) => handleChange(e, '1')}
                                                            type="text"
                                                            disabled={enableInput != '1'}
                                                            maxLength={10}
                                                            value={spocDetails.representative_mobile_no}
                                                            style={{ border: validation.representative_mobile_no ? '2px solid red' : '', width: '100%' }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-1 col-sm-1 pt-4" style={{ display: 'flex', alignItems: 'center' }}>
                                            <img src={edit} alt="" onClick={() => editSpoc('1')} style={{ cursor: 'pointer' }} />
                                        </div>
                                    </div>

                                </div>
                                {console.log(enableInput)}
                                {(contactId == "2" || spocDetails.representative_name_1?.length > 0) &&
                                    <div className="card p-3 mt-3">
                                        <div className="row">
                                            <div className="col-md-1 col-sm-1" style={{ display: 'flex', alignItems: 'end', fontWeight: 'bold' }}>
                                                <p>SPOC 2</p>
                                            </div>
                                            <div className="col-md col-sm">
                                                <div className="form-group row" style={{ display: 'flex', alignItems: 'center' }}>
                                                    <label className="col-sm-12 col-md-12 col-form-label" style={{ fontSize: '14px' }}>
                                                        Name <span style={{ color: 'red' }}><sup>*</sup></span>
                                                    </label>
                                                    <div className="col-sm-12 col-md-12">
                                                        <div className="">
                                                            <input
                                                                className="form-control"
                                                                placeholder="Name of Representative"
                                                                name="representative_name_1"
                                                                onChange={(e) => handleChange(e, '2')}
                                                                type="text"
                                                                disabled={spocDetails.representative_name_1?.length > 0 && enableInput != '2'}
                                                                value={spocDetails.representative_name_1 == null ? "" : spocDetails.representative_name_1}
                                                                style={{ border: validation.representative_name_1 ? '2px solid red' : '', width: '100%' }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md col-sm">
                                                <div className="form-group row" style={{ display: 'flex', alignItems: 'center' }}>
                                                    <label className="col-sm-12 col-md-12 col-form-label" style={{ fontSize: '14px' }}>
                                                        Designation <span style={{ color: 'red' }}><sup>*</sup></span>
                                                    </label>
                                                    <div className="col-sm-12 col-md-12">
                                                        <div className="">
                                                            <input
                                                                className="form-control"
                                                                placeholder="Desgnation"
                                                                name="representative_designation_1"
                                                                onChange={(e) => handleChange(e, '2')}
                                                                type="text"
                                                                disabled={spocDetails.representative_designation_1?.length > 0 && enableInput != '2'}
                                                                value={spocDetails.representative_designation_1 == null ? "" : spocDetails.representative_designation_1}
                                                                style={{ border: validation.representative_designation_1 ? '2px solid red' : '', width: '100%' }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md col-sm">
                                                <div className="form-group row" style={{ display: 'flex', alignItems: 'center' }}>
                                                    <label className="col-sm-12 col-md-12 col-form-label" style={{ fontSize: '14px' }}>
                                                        Email <span style={{ color: 'red' }}><sup>*</sup></span>
                                                    </label>
                                                    <div className="col-sm-12 col-md-12">
                                                        <div className="">
                                                            <input
                                                                className="form-control"
                                                                placeholder="Email"
                                                                name="representative_email_1"
                                                                type="text"
                                                                disabled={spocDetails.representative_email_1?.length > 0 && enableInput != '2'}
                                                                value={spocDetails.representative_email_1 == null ? "" : spocDetails.representative_email_1}
                                                                style={{ border: validation.representative_email_1 == true ? '2px solid red' : '', width: '100%' }}
                                                                onChange={(e) => handleChange(e, '2')}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md col-sm">
                                                <div className="form-group row" style={{ display: 'flex', alignItems: 'center' }}>
                                                    <label className="col-sm-12 col-md-12 col-form-label" style={{ fontSize: '14px' }}>
                                                        Phone Number<span style={{ color: 'red' }}><sup>*</sup></span>
                                                    </label>
                                                    <div className="col-sm-12 col-md-12">
                                                        <div className="">
                                                            <input
                                                                className="form-control"
                                                                placeholder="Mobile Number"
                                                                type="text"
                                                                disabled={spocDetails.representative_mobile_no_1?.length > 0 && enableInput != '2'}
                                                                style={{ border: validation.representative_mobile_no_1 ? '2px solid red' : '', width: '100%' }}
                                                                name="representative_mobile_no_1"
                                                                maxLength={10}
                                                                value={spocDetails.representative_mobile_no_1 == null ? "" : spocDetails.representative_mobile_no_1}
                                                                onChange={(e) => handleChange(e, '2')}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-1 col-sm-1 pt-4" style={{ display: 'flex', alignItems: 'center' }}>
                                                {

                                                    <>
                                                        <img src={edit} alt="" style={{ borderRight: '2px solid grey', padding: '5px 5px', cursor: 'pointer' }} onClick={() => editSpoc('2')} />
                                                        {
                                                            checkSpoc2Fields?.length == 0 ?
                                                                <img width="15px" className='ml-1' src={remove} alt="" data-toggle="modal" data-target="#exampleModal" onClick={() => setDeleteRecord('1')} style={{ cursor: 'pointer' }} /> :

                                                                <img width="15px" className='ml-1' src={remove} alt="" onClick={() => { addContact("1") }} style={{ cursor: 'pointer' }} />
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }

                                {(otherEmaiId == "1" || spocDetails.other_email?.length > 0) &&
                                    <div className="card p-3 mt-3 mb-3">
                                        <div className="row">
                                            <div className="col-md-2 col-sm-4" style={{ display: 'flex', alignItems: 'end', fontWeight: 'bold' }}>
                                                <p>Another Email</p>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="form-group row" style={{ display: 'flex', alignItems: 'center' }}>
                                                    <label className="col-sm-12 col-md-12 col-form-label" style={{ fontSize: '16px' }}>
                                                        Email <span style={{ color: 'red' }}><sup>*</sup></span>
                                                    </label>
                                                    <div className="col-sm-12 col-md-12">
                                                        <div className="">
                                                            <input
                                                                className="form-control"
                                                                placeholder="Enter Email"
                                                                name="other_email"
                                                                onChange={(e) => handleChange(e, '3')}
                                                                type="text"
                                                                disabled={spocDetails.other_email?.length > 0 && enableInput != '3'}
                                                                value={spocDetails.other_email == null ? "" : spocDetails.other_email}
                                                                style={{ border: validation.other_email ? '2px solid red' : '', width: '100%' }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4 pt-4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                                                {
                                                    <>
                                                        <img src={edit} alt="" style={{ borderRight: '2px solid grey', padding: '5px 5px', cursor: 'pointer' }} onClick={() => editSpoc('3')} />
                                                        {
                                                            checkOtherEmail?.length == 0 ?
                                                            <img width="15px" className='ml-1' src={remove} alt="" data-toggle="modal" data-target="#exampleModal" onClick={() => setDeleteRecord('2')} style={{ cursor: 'pointer' }} /> :
                                                            <img width="15px" className='ml-1' src={remove} alt="" onClick={() => { addEmail("2") }} style={{ cursor: 'pointer' }} />
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                }
                                <div className="row">
                                    <div className="col-12 text-center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {
                                            (otherEmaiId != "1" && !spocDetails.other_email?.length) &&
                                            <button className="btn btn-sisfs contactBtn mt-3" style={{ color: '#2b3f88', border: "1px solid #2b3f88" }}
                                                onClick={() => { addEmail("1") }}
                                            >
                                                <span>Add Another Email</span>
                                            </button>
                                        }
                                        {
                                            (otherEmaiId != "1" && !spocDetails.other_email?.length) && <div style={{ width: '30px' }}></div>
                                        }
                                        {
                                            contactId != '2' && checkSpoc2Fields.length != 0 &&
                                            <button className="btn btn-sisfs mt-3" style={{ color: '#2b3f88', border: "1px solid #2b3f88" }}
                                                onClick={() => { addContact("2") }}
                                            >
                                                <span>Add SPOC Details</span>
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="row modal-row">
                                    <div className="col-md"></div>

                                    <div className="col-md-4 text-center">
                                        <button className="btn btn-sisfs btn-yellow-primary" style={{ padding: '15px' }}
                                            onClick={submitDetails}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                    <div className="col-md"></div>
                                </div>

                            </div>

                            <div className="modal fade" data-backdrop="static" data-keyboard="false" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content" style={{ backgroundColor: '#f6f6f6' }}>

                                        <button type="button" className="close text-right mr-3" onClick={closeAlert} aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        <div className="modal-body">
                                            <h4 className='text-center' style={{ color: '#2b3f88', fontWeight: '550' }}>Are you sure you want to delete the record?</h4>
                                            <br />
                                            <div className='d-flex align-items-center justify-content-center'>

                                                <button className="btn btn-sisfs" style={{ color: '#2b3f88', border: "1px solid #2b3f88" }}
                                                    onClick={closeAlert}
                                                >
                                                    No
                                                </button>
                                                <button className="btn btn-sisfs btn-yellow-primary ml-3"
                                                    onClick={() => {
                                                        if (deleteRecord === "1") {
                                                          addContact('1');
                                                        } else {
                                                          addEmail("2");
                                                        }
                                                        closeAlert();
                                                      }}
                                                >
                                                    Yes
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </div>


        </>
    )
}

export default ContactDetails